import { getUnitOfMeasure } from '../../../uom/UnitOfMeasureRegistry.js'
import { Log } from '../../../util/Log.js'
import { BodySymbolizer } from '../model/symbolizer/BodySymbolizer.js'
const GEOSPATIAL_URL = 'http://www.opengeospatial.org/se/units/'
const dashOrPointPattern = /-(.)/g
const colorMatcher = /^#([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})/
export function sanitizeString(e) {
  return e
    .replace(/\\/g, '\\\\')
    .replace(/\n/g, '\\n')
    .replace(/\t/g, '\\t')
    .replace(/'/g, "\\'")
}
export function trimString(e) {
  return e.replace(/^[\s\t\n]*/, '').replace(/[\s]*$/, '')
}
export function isPrimitive(e) {
  return null !== e && Object(e) !== e
}
export function getUom(e) {
  if (!e) return null
  if ('string' !== typeof e) throw 'incorrect uom definition  ' + e
  else {
    let r = e
    if (0 === e.indexOf(GEOSPATIAL_URL)) r = e.substring(GEOSPATIAL_URL.length)
    if (!r) throw 'unrecognized uom definition  ' + e
    r = r.charAt(0).toUpperCase() + r.slice(1).toLowerCase()
    if ('Metre' === r) r = 'Meter'
    else if ('Pixel' === r) r = 'Pixels'
    getUnitOfMeasure(r)
    return r
  }
}
export function mapToUpperCase(e, r) {
  return r.toUpperCase()
}
export function toCamelCase(e) {
  return e.replace(dashOrPointPattern, mapToUpperCase)
}
export function toBoolean(e, r) {
  if ('boolean' === typeof e) return e
  if ('string' === typeof e)
    return 'true' === e ? true : 'false' === e ? false : r
  return r
}
export function toFloat(e) {
  if ('number' === typeof e) return e
  else {
    const r = parseFloat(e)
    if (!isNaN(r)) return r
  }
  return null
}
export function createCanvasColorString(e, r) {
  const t = e.trim().match(colorMatcher)
  if (!t) {
    Log.error('encountered invalid color value: ' + e)
    return
  }
  if (r === Number.NaN) {
    Log.error('encountered invalid color opacity: ' + r)
    return
  }
  return 1 === r
    ? e
    : 'rgba(' +
        parseInt(t[1], 16) +
        ',' +
        parseInt(t[2], 16) +
        ',' +
        parseInt(t[3], 16) +
        ',' +
        r +
        ')'
}
export function getVendorOptions(e) {
  if (e instanceof BodySymbolizer) return e.vendorOptions
  return null
}
export function isVendorOptionEnabled(e, r, t) {
  const n = getVendorOptions(e)
  if (null != n)
    for (let e = 0; e < n.length; e++)
      if (n[e].name === r) return 'true' === n[e].value
  return t
}
