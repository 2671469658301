import { ShapeType } from '../../../shape/ShapeType.js'
import { EVENT_HANDLED } from '../../controller/HandleEventResult.js'
import { ShapeTouchHandle } from './ShapeTouchHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { viewToModel } from './HandleUtil.js'
import { getInteractionRadius } from '../../controller/EditSettings.js'
import { interactsWithControllerShape } from '../EditHandle.js'
export class ShapeTranslateHandle extends ShapeTouchHandle {
  constructor(t) {
    super()
    this._shape = t
    this._startPoint = null
    this._endPoint = null
  }
  get shape() {
    return this._shape
  }
  get startPoint() {
    return this._startPoint
  }
  set startPoint(t) {
    this._startPoint = t
  }
  get endPoint() {
    return this._endPoint
  }
  set endPoint(t) {
    this._endPoint = t
  }
  activate(t, e) {
    this._startPoint = viewToModel(t.viewPoint, e, true)
    this._endPoint = this._startPoint
    return EVENT_HANDLED
  }
  getCursor(t, e) {
    if (this.interacts(t, e) || this.active) return 'move'
    return null
  }
  interacts(t, e) {
    const i = getInteractionRadius(t, e)
    const { x: s, y: n } = t.viewPoint
    return interactsWithControllerShape(e.map, s, n, i, this.shape)
  }
  process(t, e) {
    this._startPoint = this._endPoint
    this._endPoint = viewToModel(t.viewPoint, e, true)
    if (!this._startPoint || !this._endPoint) return EVENT_HANDLED
    const i = this._endPoint.x - this._startPoint.x
    const s = this._endPoint.y - this._startPoint.y
    if (0 !== i || 0 !== s) {
      this.translate(t, e, i, s)
      this.emitEditShapeEvent(this.shape, EditShapeStatus.IN_PROGRESS)
    }
    return EVENT_HANDLED
  }
  translate(t, e, i, s) {
    if (this.shape.type === ShapeType.GEO_BUFFER) this.shape._translate2D(i, s)
    else this.shape.translate2D(i, s)
  }
}
