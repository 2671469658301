import { Controller } from './Controller.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { HandleEventResult } from './HandleEventResult.js'
export class PickController extends Controller {
  constructor() {
    super()
  }
  onGestureEvent(t) {
    if (!this.map) return HandleEventResult.EVENT_IGNORED
    if (this.isPickEvent(t)) {
      const e = this.getPickPoint(t)
      const n = this.getSensitivity(t)
      const i = this.getPaintRepresentations(t)
      const s = this.getCandidates(e, n, i)
      return this.handleCandidates(t, s)
    }
    return HandleEventResult.EVENT_IGNORED
  }
  getCandidates(t, e, n) {
    if (!this.map || !t) return []
    const i = this.map._pickClosestObject(t.x, t.y, e, false, n)
    return i ? [i] : []
  }
  getPickPoint(t) {
    return t.viewPoint
  }
  getSensitivity(t) {
    return 'mouse' === t.inputType ? 1 : 'touch' === t.inputType ? 30 : 0
  }
  getPaintRepresentations(t) {
    return [PaintRepresentation.LABEL, PaintRepresentation.BODY]
  }
}
