import { Controller } from './Controller.js'
import { EVENT_IGNORED, isHandled } from './HandleEventResult.js'
export class CompositeController extends Controller {
  constructor() {
    super()
    this._delegates = []
    this._invalidationListeners = []
    this._invalidating = false
  }
  get delegates() {
    return this._delegates
  }
  onActivate(e) {
    super.onActivate(e)
    for (const t of this._delegates) t.onActivate(e)
  }
  onDeactivate(e) {
    super.onDeactivate(e)
    const t = []
    for (const n of this._delegates) t.push(n.onDeactivate(e))
    return Promise.all(t)
  }
  onGestureEvent(e) {
    let t = EVENT_IGNORED
    for (const n of this._delegates) {
      t = n.onGestureEvent(e)
      if (isHandled(t)) return t
    }
    return t
  }
  onKeyEvent(e) {
    let t = EVENT_IGNORED
    for (const n of this._delegates) {
      t = n.onKeyEvent(e)
      if (isHandled(t)) return t
    }
    return t
  }
  onDraw(e) {
    for (const t of this._delegates) t.onDraw(e)
  }
  onDrawLabel(e) {
    for (const t of this._delegates) t.onDrawLabel(e)
  }
  invalidate() {
    if (!this._invalidating) {
      this._invalidating = true
      super.invalidate()
      for (const e of this._delegates) e.invalidate()
      this._invalidating = false
    }
  }
  appendController(e) {
    if (this.map)
      throw new Error(
        'Cannot append new controller while current controller is active on the map.' +
          'Remove the controller from map before appending a controller to it.'
      )
    this._delegates.push(e)
    this._invalidationListeners.push(
      e.on('Invalidated', () => {
        if (!this._invalidating) super.invalidate()
      })
    )
  }
}
