import { XYZPoint } from '../shape/XYZPoint.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { GeodeticReference } from '../reference/GeodeticReference.js'
import { ProjectionType } from '../projection/ProjectionType.js'
import { createTransformation } from './TransformationFactory.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { NotImplementedError } from '../error/NotImplementedError.js'
var intervalsPerSide = 200,
  boundary,
  geo2gridTfx,
  grid2geoTfx,
  worldCenter,
  worldCenterCopy,
  tmpModelCopy
var tmpModelPoint = new LLHPoint()
function xComparator(e, o) {
  var r = e.worldPoint.x
  var t = o.worldPoint.x
  return r < t ? -1 : r > t ? 1 : 0
}
function yComparator(e, o) {
  var r = e.worldPoint.y
  var t = o.worldPoint.y
  return r < t ? -1 : r > t ? 1 : 0
}
var KdTree = function (e, o, r) {
  var t
  if ('undefined' == typeof r) r = 0
  this._axis = r % 2
  if (e.length < o) {
    this._points = e
    this._location = null
    this._leftChild = null
    this._rightChild = null
  } else {
    this._points = null
    e.sort(0 == this._axis ? xComparator : yComparator)
    t = Math.floor(e.length / 2)
    this._location = e[t].worldPoint
    this._leftChild = new KdTree(e.slice(0, t), o, r + 1)
    this._rightChild = new KdTree(e.slice(t), o, r + 1)
  }
}
KdTree.prototype.getNearest = function (e) {
  var o = Number.MAX_VALUE
  var r = null
  var t = function () {
    var n, i, d, s, a, l, u, y, f, p, c
    if (null != this._points)
      for (n = 0, i = this._points.length; n < i; n++) {
        l = this._points[n].worldPoint
        if ((a = (d = e.x - l.x) * d + (s = e.y - l.y) * s) < o) {
          o = a
          r = this._points[n]
        }
      }
    else {
      p =
        (f =
          (u = this.get(e)) < (y = this.get(this._location))
            ? this._leftChild
            : this._rightChild) === this._leftChild
          ? this._rightChild
          : this._leftChild
      f._processTree(t, e)
      if (o >= (c = u - y) * c) p._processTree(t, e)
    }
  }
  this._processTree(t, e)
  return r
}
KdTree.prototype._processTree = function (e, o) {
  e.call(this, o)
}
KdTree.prototype.get = function (e) {
  return 0 == this._axis ? e.x : e.y
}
var WorldBoundary = function (e, o) {
  this._boundary = e
  this._geoReference = o
  this._worldTree = new KdTree(e.slice(), 10)
}
WorldBoundary.prototype.getBoundary = function () {
  return this._boundary
}
WorldBoundary.prototype.getClosestBoundaryPosition = function (e) {
  return this._worldTree.getNearest(e)
}
WorldBoundary.prototype.getGeoReference = function () {
  return this._geoReference
}
function WorldBoundaryPoint(e, o, r) {
  this.index = e
  this.worldPoint = o
  this.modelPoint = r
}
function worldBoundaryBuilder(e) {
  var o, r, t, n, i, d, s, a, l, u, y, f
  if (e.referenceType !== ReferenceType.GRID) return null
  t = e.projection
  n = new XYZPoint(null, [t.getOrigin().x, t.getOrigin().y])
  r = new GeodeticReference()
  geo2gridTfx = createTransformation(r, e)
  grid2geoTfx = geo2gridTfx.inverseTransformation
  if (t.TYPE == ProjectionType.POLYCONIC) boundary = getPolyconicBoundary(n.x)
  else if (t.TYPE == ProjectionType.CASSINI) boundary = getCassiniBoundary(n.x)
  else {
    getCartesianBounds((d = e.bounds), n.x, n.y)
    worldCenter = new XYZPoint(e, [d.x + d.width / 2, d.y + d.height / 2])
    worldCenterCopy = worldCenter.copy()
    s = true
    if (
      t.TYPE == ProjectionType.EQUIDISTANT_CYLINDRICAL ||
      t.TYPE == ProjectionType.MERCATOR ||
      t.TYPE == ProjectionType.MILLER_CYLINDRICAL ||
      t.TYPE == ProjectionType.GEODETIC
    )
      s = false
    a = getAsPolygon(d, s)
    boundary = []
    for (l = 0, u = a.length; l < u; l++) {
      y = a[l]
      f = new LLHPoint(r)
      try {
        grid2geoTfx.transform(y, f)
        boundary.push(new WorldBoundaryPoint(l, y, f))
      } catch (e) {
        if (!(e instanceof OutOfBoundsError)) throw e
      }
    }
  }
  return (o = null == boundary ? null : new WorldBoundary(boundary, r))
}
function inside(e) {
  try {
    grid2geoTfx.transform(e, tmpModelPoint)
    return true
  } catch (e) {
    if (e instanceof OutOfBoundsError) return false
    else throw e
  }
}
function adaptPointToBoundary(e, o) {
  if (o[0]) return true
  tmpModelCopy.move2D(
    e[1].x + 0.5 * (e[0].x - e[1].x),
    e[1].y + 0.5 * (e[0].y - e[1].y)
  )
  if (inside(tmpModelCopy)) {
    e[1].move2D(tmpModelCopy.x, tmpModelCopy.y)
    o[1] = true
  } else {
    e[0].move2D(tmpModelCopy.x, tmpModelCopy.y)
    o[0] = false
  }
  return false
}
function adaptToBoundary(e) {
  worldCenterCopy.move2D(worldCenter)
  var o = [inside(e), inside(worldCenterCopy)],
    r = [e, worldCenterCopy],
    t,
    n
  for (t = 0; t < 20; t++) if ((n = adaptPointToBoundary(r, o))) return
  if (!o[0]) e.move2D(worldCenterCopy)
}
function addPoints(e, o, r, t) {
  var n = (o.x - e.x) / intervalsPerSide,
    i = (o.y - e.y) / intervalsPerSide,
    d = 0,
    s,
    a = e.reference
  tmpModelCopy = e.copy()
  for (; d < intervalsPerSide; d++) {
    s = new XYZPoint(a, [e.x + n * d, e.y + i * d])
    if (t) adaptToBoundary(s)
    r.push(s)
  }
}
function getAsPolygon(e, o) {
  var r = [],
    t = [],
    n
  r.push(new XYZPoint(e.reference, [e.x, e.y]))
  r.push(new XYZPoint(e.reference, [e.x + e.width, e.y]))
  r.push(new XYZPoint(e.reference, [e.x + e.width, e.y + e.height]))
  r.push(new XYZPoint(e.reference, [e.x, e.y + e.height]))
  for (n = 0; n < 4; n++) addPoints(r[n], r[(n + 1) % 4], t, o)
  return t
}
function getCartesianBounds(e, o, r) {
  var t = e.copy(),
    n = new XYZPoint(),
    i = new XYZPoint(t.reference),
    d,
    s,
    a,
    l
  for (d = -180; d <= 180; d += 5)
    for (s = -90; s <= 90; s += 5) {
      n.x = d
      n.y = s
      setToIncludeCartesianPosition(n, i, t)
    }
  n.x = o - 179.999999
  n.y = r
  setToIncludeCartesianPosition(n, i, t)
  n.x = o - 179.999999
  n.y = r - 89.999999
  setToIncludeCartesianPosition(n, i, t)
  n.x = o - 179.999999
  n.y = r + 89.999999
  setToIncludeCartesianPosition(n, i, t)
  n.x = o + 179.999999
  n.y = r
  setToIncludeCartesianPosition(n, i, t)
  n.x = o + 179.999999
  n.y = r - 89.999999
  setToIncludeCartesianPosition(n, i, t)
  n.x = o + 179.999999
  n.y = r + 89.999999
  setToIncludeCartesianPosition(n, i, t)
  if (!(t.equals && t.equals(e)) && 0 !== e.width) {
    a = t.width
    l = t.height
    t.setTo2D(t.x - 0.05 * a, 1.1 * a, t.y - 0.05 * l, 1.1 * l)
  }
  return t
}
function setToIncludeCartesianPosition(e, o, r) {
  try {
    geo2gridTfx.transform(e, o)
    r.setToIncludePoint2D(o)
  } catch (e) {
    if (!(e instanceof OutOfBoundsError)) throw e
  }
}
function getPolyconicBoundary(e) {
  throw new NotImplementedError(
    'WorldBoundaryBuilder: Cannot get PolyconicBoundary:  not yet implemented'
  )
}
function getCassiniBoundary(e) {
  throw new NotImplementedError(
    'WorldBoundaryBuilder: Cannon get CassiniBoundary: not yet implemented'
  )
}
export var WorldBoundaryBuilder = { build: worldBoundaryBuilder }
