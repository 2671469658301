import { ComplexPolygon } from '../../shape/ComplexPolygon.js'
import { PolygonOrientation } from '../../shape/PolygonOrientation.js'
import { ShapeList } from '../../shape/ShapeList.js'
import { ShapeType } from '../../shape/ShapeType.js'
import { CGVertex } from './CGVertex.js'
import { ComplexPolygonTree } from './ComplexPolygonTree.js'
import { FlattenComplexPolygonUtil } from './FlattenComplexPolygonUtil.js'
function pointListToVertices(e, o, t, n) {
  var i = null
  var l = null
  var r = null
  for (var s = 0; s < e.length; s++) {
    var p = new CGVertex(o, e[s])
    p.setPointLocationThis(true)
    p.setSegmentLocationsOfThis(true, false, false)
    if (0 == s) l = p
    if (s == e.length - 1) r = p
    if (null !== i) {
      p.previous = i
      i.next = p
    }
    i = p
  }
  if (null !== l && null !== r)
    if (t || n) {
      if (t) r = r.previous
      r.next = l
      l.previous = r
    }
  return l
}
function verticesToPointCoordinates(e, o) {
  const t = []
  let n = true
  for (let o = e; o && (n || o !== e); o = o.next) {
    n = false
    t.push(o.getVertexPointCoordinates())
  }
  if (o && null !== e) t.push(e.getVertexPointCoordinates())
  return t
}
function polylineToVertices(e, o, t, n) {
  var i = removeDuplicatePoints(e, t, false)
  var l
  var r = pointListToVertices(i, o, t.pointsEqual(i[0], i[i.length - 1]), false)
  if (null !== r) n.elements.push(r)
}
function polygonToVertices2(e, o, t, n) {
  var i = removeDuplicatePoints(e, o, true)
  if (i.length < 3) return null
  var l = null
  var r = null
  var s = null
  for (var p = 0; p < i.length; p++) {
    var a = new CGVertex(t, i[p])
    a.setPointLocationThis(true)
    if (n) a.setSegmentLocationsOfThis(true, true, false)
    else a.setSegmentLocationsOfThis(true, false, true)
    if (0 === p) r = a
    if (p === i.length - 1) s = a
    if (null !== l) {
      a.previous = l
      l.next = a
    }
    l = a
  }
  if (null !== r && null !== s) {
    s.next = r
    r.previous = s
  }
  return r
}
function polygonToVertices(e, o, t, n) {
  n.addOriginalFilledShape(e)
  var i = true
  if (e.pointCount > 1)
    i = e.orientation === PolygonOrientation.COUNTER_CLOCKWISE
  var l = polygonToVertices2(e, t, o, i)
  if (null !== l) n.elements.push(l)
}
function complexPolygonToVertices(e, o, t, n) {
  n.addOriginalFilledShape(e)
  var i = []
  markInnerPolygons(e, i, t)
  for (var l = 0; l < e.polygonCount; l++) {
    var r = i[l]
    var s
    var p =
      (e.getPolygon(l).orientation === PolygonOrientation.COUNTER_CLOCKWISE) !=
      r
    var a = polygonToVertices2(e.getPolygon(l), t, o, p)
    if (null !== a) n.elements.push(a)
  }
}
function markInnerPolygons(e, o, t) {
  for (var n = 0; n < e.polygonCount; n++) o.push(false)
  var i
  new ComplexPolygonTree(e, t.advancedBinaryTopologyUtil).getResultInList(o)
}
function shapeToCgShape(e, o, t, n) {
  if (ShapeType.contains(ShapeType.POLYLINE, e.type))
    polylineToVertices(e, o, t, n)
  else if (ShapeType.contains(ShapeType.POLYGON, e.type))
    polygonToVertices(e, o, t, n)
  else if (ShapeType.contains(ShapeType.POINT, e.type))
    n.elements.push(new CGVertex(o, t.getNewEditablePoint(e)))
  else if (ShapeType.contains(ShapeType.COMPLEX_POLYGON, e.type))
    complexPolygonToVertices(e, o, t, n)
}
function cgShapeToShape(e, o) {
  const t = []
  const n = []
  for (let i = 0; i < e.elements.length; i++) {
    const l = e.elements[i]
    if (null === l.previous)
      if (null === l.next) t.push(o.getNewEditablePoint(l.pos))
      else {
        const e = verticesToPointCoordinates(l, false)
        if (e.length > 1) t.push(o.getNewEditablePolyline(l.pos.reference, e))
      }
    else if (l.isFilled()) {
      const e = verticesToPointCoordinates(l, false)
      if (e.length > 2) n.push(o.getNewEditablePolygon(l.pos.reference, e))
    } else {
      const e = verticesToPointCoordinates(l, true)
      if (e.length > 1) t.push(o.getNewEditablePolyline(l.pos.reference, e))
    }
  }
  var i
  if (n.length > 1) {
    var l = new ComplexPolygon(n[0].reference, n)
    i = FlattenComplexPolygonUtil.convertComplexPolygon(l)
  } else if (1 === n.length) t.push(n[0])
  if (t.length > 0)
    if (i) {
      t.push(i)
      return new ShapeList(t[0].reference, t)
    } else if (1 === t.length) return t[0]
    else return new ShapeList(t[0].reference, t)
  else if (i)
    if (i.type === ShapeType.SHAPE_LIST && 1 === i.shapeCount)
      return i.getShape(0)
    else return i
  else return new ShapeList()
}
function removeDuplicatePoints(e, o, t) {
  var n = []
  if (0 === e.pointCount) return n
  n.push(e.getPoint(0).copy())
  for (var i = 0; i < e.pointCount; ++i) {
    var l = e.getPoint(i)
    n.push(l.copy())
  }
  return n
}
export const CGOperatorConvertShape = {
  pointListToVertices: pointListToVertices,
  shapeToCgShape: shapeToCgShape,
  cgShapeToShape: cgShapeToShape,
}
