import { ShapeType } from '../../../shape/ShapeType.js'
import { PointListCreateHandle } from '../handles/PointListCreateHandle.js'
import { PointListInsertHandle } from '../handles/PointListInsertHandle.js'
import { PointListTranslateHandle } from '../handles/PointListTranslateHandle.js'
import { Editor } from '../Editor.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import { PointListDeleteHandle } from '../handles/PointListDeleteHandle.js'
import { PointListHelperHandle } from '../handles/helper/PointListHelperHandle.js'
export class PointListEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return (
      e.shape.type === ShapeType.POLYGON || e.shape.type === ShapeType.POLYLINE
    )
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createPointListTranslateHandle(e),
      this.createPointListInsertHandle(e),
      this.createPointListDeleteHandle(e),
      this.createHelperHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createPointListTranslateHandle(e) {
    const t = e.shape
    return new PointListTranslateHandle(t)
  }
  createPointListInsertHandle(e) {
    const t = e.shape
    return new PointListInsertHandle(t, e.settings.maximumPointCount)
  }
  createPointListDeleteHandle(e) {
    const t = e.shape
    return new PointListDeleteHandle(t, e.settings.minimumPointCount)
  }
  createHelperHandle(e) {
    const t = e.shape
    return new PointListHelperHandle(t)
  }
  getCreateHandle(e) {
    const t = e.shape
    return new PointListCreateHandle(t, {
      minimumPointCount: e.settings.minimumPointCount,
      maximumPointCount: e.settings.maximumPointCount,
      freehand: e.settings.freehand,
    })
  }
}
