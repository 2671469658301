import { ProgrammingError } from '../../error/ProgrammingError.js'
function TopologyUtil(o) {
  Object.defineProperty(this, 'toleranceEqualPoints', {
    configurable: false,
    enumerable: true,
    value: o,
    writable: true,
  })
}
TopologyUtil.prototype = Object.create(Object.prototype)
TopologyUtil.prototype.constructor = TopologyUtil
TopologyUtil.prototype.certainlyNoInteraction4Points = function (o, t, e, n) {
  throw new ProgrammingError('Must implement certainlyNoInteraction4Points')
}
TopologyUtil.prototype.certainlyNoInteraction3Points = function (o, t, e) {
  throw new ProgrammingError('Must implement certainlyNoInteraction3Points')
}
TopologyUtil.prototype.getToleranceX = function (o, t) {
  throw new ProgrammingError('Must implement getToleranceX')
}
TopologyUtil.prototype.getToleranceY = function (o, t) {
  throw new ProgrammingError('Must implement getToleranceY')
}
TopologyUtil.prototype.isPointOnLineSegment = function (o, t, e) {
  throw new ProgrammingError('Must implement isPointOnLineSegment')
}
TopologyUtil.prototype.closestPointOnLineSegment = function (o, t, e, n) {
  throw new ProgrammingError('Must implement closestPointOnLineSegment')
}
TopologyUtil.prototype.distancePointPoint = function (o, t) {
  throw new ProgrammingError('Must implement distancePointPoint')
}
TopologyUtil.prototype.distancePointLineSegment = function (o, t, e) {
  throw new ProgrammingError('Must implement distancePointLineSegment')
}
TopologyUtil.prototype.pointsEqual = function (o, t) {
  throw new ProgrammingError('Must implement PointsEqual')
}
TopologyUtil.prototype.lineSegmentIntersection = function (o, t, e, n, r) {
  throw new ProgrammingError('Must implement lineSegmentIntersection')
}
TopologyUtil.prototype.growBounds = function (o, t) {
  throw new ProgrammingError('Must implement growBounds')
}
TopologyUtil.prototype.getAveragePoint = function (o, t) {
  throw new ProgrammingError('Must implement getAveragePoint')
}
TopologyUtil.prototype.getAngle = function (o, t, e, n) {
  throw new ProgrammingError('Must implement getAngle')
}
TopologyUtil.prototype.boundsDontIntersectSegment = function (o, t, e) {
  throw new ProgrammingError('Must implement boundsDontIntersectSegment')
}
TopologyUtil.prototype.contains2D = function (o, t) {
  return o.contains2DPoint(t)
}
Object.defineProperty(TopologyUtil.prototype, 'advancedBinaryTopologyUtil', {
  configurable: false,
  enumerable: true,
  get: function () {
    throw new ProgrammingError('Must define advancedBinaryTopologyUtil')
  },
  set: function () {
    throw new ProgrammingError(
      'advancedBinaryTopologyUtil property is not mutable'
    )
  },
})
TopologyUtil.prototype.getNewEditablePoint = function (o) {
  return o.copy()
}
TopologyUtil.prototype.getNewEditablePolyline = function (o, t) {
  throw new ProgrammingError('Must implement getNewEditablePolyline')
}
TopologyUtil.prototype.getNewEditablePolygon = function (o, t) {
  throw new ProgrammingError('Must implement getNewEditablePolygon')
}
TopologyUtil.prototype.getNewEditableBoundsFromPoint = function (o) {
  throw new ProgrammingError('Must implement getNewEditableBoundsFromPoint')
}
TopologyUtil.prototype.getNewEditableBoundsFromBounds = function (o) {
  throw new ProgrammingError('Must implement getNewEditableBoundsFromBounds')
}
TopologyUtil.prototype.getNewEditableBoundsFromCoordinates = function (
  o,
  t,
  e,
  n,
  r
) {
  throw new ProgrammingError(
    'Must implement getNewEditableBoundsFromCoordinates'
  )
}
export { TopologyUtil }
