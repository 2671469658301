import { isNumber } from '../../util/Lang.js'
import { RotatedBox } from '../../util/RotatedBox.js'
import { PickInfoImpl } from '../PickInfo.js'
import { ConflictType } from './LabelConflictChecker.js'
import { LabelPositions } from './LabelPositions.js'
import { INVALID_LAYER_CLIP } from '../LayerStyle.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { calculateBounds } from './HTMLMetrics.js'
import { VisibilityType } from './VisibilityType.js'
import { OcclusionMode } from './OcclusionMode.js'
const tmpBounds = []
export class DrawBaseLabelHtmlContents extends RotatedBox {
  _zOrder = null
  constructor(t, e, i, s, n, o, r) {
    super()
    this._html = e
    this._handle = null
    this._index = 0
    this._clip = INVALID_LAYER_CLIP
    this.drawCommand = t
    this.labelContext = i
    this.group = s
    this._padding = o
    this.priority = n
    this.selected = r
    this._previousPosition = LabelPositions.INVALID_POSITION
    this._boundMetrics = null
    this._alwaysVisible =
      this.drawCommand?.getOcclusionHintForLabel() ===
      OcclusionMode.ALWAYS_VISIBLE
  }
  get handle() {
    return this._handle
  }
  set handle(t) {
    this._handle = t
  }
  get index() {
    return this._index
  }
  set index(t) {
    this._index = t
  }
  get clip() {
    return this._clip
  }
  set clip(t) {
    this._clip = t
  }
  get previousPosition() {
    return this._previousPosition
  }
  set previousPosition(t) {
    this._previousPosition = t
  }
  set zOrder(t) {
    if (isNumber(t)) this._zOrder = t
  }
  get html() {
    return this._html
  }
  setPickInfoContext(t, e) {
    this._layer = t
    this._feature = e
  }
  isReady() {
    return !!this.drawCommand?.isReady()
  }
  pickTouched(t, e, i, s, n, o, r) {
    if (this.handle?.div && (r ? !!this._layer?.selectable : true))
      return this.pickSoftRotated(t, i, s) || this.pickCssRotated(e, i, s, n, o)
    return null
  }
  pickSoftRotated(t, e, i) {
    return this._layer && this._feature && t.intersects(this)
      ? new PickInfoImpl(
          this._layer,
          this._feature,
          this.squaredDistanceToEdge(e, i),
          this.squaredDistanceToCenter(e, i),
          0,
          0,
          false,
          false
        )
      : null
  }
  pickCssRotated(t, e, i, s, n) {
    const o = this.handle?.div?.firstElementChild || null
    if (this._layer && this._feature && o && !this.getRotation()) {
      const r = o.getBoundingClientRect()
      const l = r.left
      const h = r.top
      const a = t.left + e
      const c = t.top + i
      const d = s / 2
      const u = n / 2
      const f = Math.max(edgeDifference(a, l), edgeDifference(l + r.width, a))
      const m = Math.max(edgeDifference(c, h), edgeDifference(h + r.height, c))
      if (f <= d && m <= u) {
        const t = f * f + m * m
        return new PickInfoImpl(
          this._layer,
          this._feature,
          t,
          t,
          0,
          0,
          false,
          false
        )
      }
    }
    return null
  }
  canBeReused() {
    return true
  }
  isValidLocation(t, e) {
    if (this._alwaysVisible) return true
    const i = this.labelContext.getVisibilityType(t)
    const s = !!this.drawCommand?.isValidLocation(t, i)
    if (e && i === VisibilityType.FOREGROUND)
      return s && this.labelContext.distanceToSurfaceVisibility(t)
    return s
  }
  findLabelPosition(t, e, i, s, n) {
    let o = LabelPositions.INVALID_POSITION
    if (e !== LabelPositions.INVALID_POSITION) {
      this.resetLabelBounds(t, e)
      const s = i.isValidPosition(this, this.clip)
      if (s === ConflictType.NO_CONFLICT) return e
      else if (s === ConflictType.PARTIALLY_OUTSIDE_VIEW) o = e
    }
    let r = false
    const l = []
    if (isNumber(s))
      for (let t = 0; t < n; t++) {
        const e = 1 << t
        if ((e & s) === e) l.push(t)
      }
    else
      for (let t = 0; t < s.length; t++) {
        const e = s[t]
        l.push(0 | LabelPositions.MASK[e])
      }
    s = l
    for (let e = 0; e < s.length; e++) {
      const n = s[e]
      this.resetLabelBounds(t, n)
      const l = i.isValidPosition(this, this.clip)
      if (l === ConflictType.NO_CONFLICT) return n
      else if (l === ConflictType.PARTIALLY_OUTSIDE_VIEW) {
        r = true
        o = o === LabelPositions.INVALID_POSITION ? n : o
      }
    }
    if (r) {
      this.resetLabelBounds(t, o)
      return o
    }
    return LabelPositions.INVALID_POSITION
  }
  getBoundMetrics() {
    if (!this._boundMetrics) this.computeMetrics()
    if (this._boundMetrics) return this._boundMetrics
    throw new ProgrammingError('Error while placing label: missing metrics')
  }
  setMetrics(t) {
    this._boundMetrics = {
      boundsWidth: t[2] - t[0],
      boundsHeight: t[3] - t[1],
      ascent: Math.abs(t[1]),
      descent: t[3],
      boundsX: t[0],
      boundsViewX: 0,
      boundsViewY: 0,
    }
    return this.getBoundMetrics()
  }
  reuseMetrics(t) {
    this._boundMetrics = {
      boundsWidth: t.labelWidth,
      boundsHeight: t.labelHeight,
      ascent: t.ascent,
      descent: t.descent,
      boundsX: 0,
      boundsViewX: 0,
      boundsViewY: 0,
    }
  }
  findLabelDrawCommand(t, e) {
    return this.drawCommand?.isEquivalent(t) && this instanceof e ? this : null
  }
  toScreenBase(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    let i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0
    let s = arguments.length > 3 ? arguments[3] : void 0
    const { boundsWidth: n, boundsHeight: o } = this.getBoundMetrics()
    const r = this.getLeft()
    const l = this.getTop()
    const h = this.getAngleInDegrees()
    if (null === this.handle)
      this.handle = t.placeHtmlInDom(
        this,
        this.html,
        r,
        l,
        h,
        n,
        o,
        e,
        i,
        s,
        this._zOrder
      )
    else
      t.modifyHtmlPositionInDom(this.handle, r, l, h, n, o, e, i, this._zOrder)
  }
  computeMetricsBase() {
    let t =
      arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : false
    calculateBounds(this.html, 'top', 'left', tmpBounds, t)
    return this.setMetrics(tmpBounds)
  }
}
function edgeDifference(t, e) {
  const i = e - t
  return i >= 0 ? i : 0
}
