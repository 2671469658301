import { getReference } from '../../reference/ReferenceProvider.js'
import { createBounds } from '../../shape/ShapeFactory.js'
export class WFSCapabilitiesFeatureType {
  constructor(e) {
    this.title = e.title
    this.name = e.name
    this.defaultReference = e.defaultReference
    this.otherReferences = e.otherReferences
    this.keywords = e.keywords
    this.outputFormats = e.outputFormats
    this.qName = e.qName
    this._wgs84Bounds = e.wgs84Bounds
  }
  getWGS84Bounds() {
    return this._wgs84Bounds.map((e) =>
      createBounds(getReference(e.reference), [
        e.minX,
        e.width,
        e.minY,
        e.height,
      ])
    )
  }
}
