import { isArray } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { MemorySpatialStore } from './MemorySpatialStore.js'
import { MemoryLinearStore } from './MemoryLinearStore.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
export class MemoryStore {
  constructor(e) {
    let r = !!(e = e || {}).spatialIndex
    if (r) {
      const t = isArray(e.data) ? e.data : []
      const o =
        t[0] && t[0].shape && t[0].shape.reference ? t[0].shape.reference : null
      if (o && e.reference && o.coordinateType !== e.reference.coordinateType) {
        r = false
        Log.warn(
          'MemoryStore cannot determine a reference for spatial cache - contradictory references provided'
        )
      }
      const a = o || e.reference
      if (!a) {
        r = false
        Log.warn(
          'MemoryStore cannot determine a reference for spatial cache - spatial capabilities will not be supported'
        )
      }
      if (a && a.referenceType === ReferenceType.GEOCENTRIC) {
        r = false
        Log.warn(
          'MemoryStore with spatial index does not support a geocentric reference - spatial capabilities will not be supported'
        )
      }
    }
    this._withSpatialIndex = r
    if (!r) this.spatialQuery = void 0
    this._delegateStore = r
      ? new MemorySpatialStore(e)
      : new MemoryLinearStore(e)
  }
  get(e) {
    return this._delegateStore.get(e)
  }
  put(e, r) {
    if (!e)
      throw new ProgrammingError(
        `MemoryStore::put : feature to store does not exist.`
      )
    return this._delegateStore.put(e)
  }
  add(e, r) {
    if (!e)
      throw new ProgrammingError(
        `MemoryStore::add : feature to store does not exist.`
      )
    return this._delegateStore.add(e)
  }
  remove(e) {
    return this._delegateStore.remove(e)
  }
  clear() {
    return this._delegateStore.clear()
  }
  query(e) {
    return this._delegateStore.query(e)
  }
  spatialQuery(e, r) {
    if (this._delegateStore instanceof MemorySpatialStore)
      return this._delegateStore.spatialQuery(e, r)
    throw new ProgrammingError('MemoryStore.spatialQuery is unsupported')
  }
  reload(e) {
    return this._delegateStore.reload(e)
  }
  on(e, r, t, o) {
    return this._delegateStore.on(e, r, t, o)
  }
  get spatialIndex() {
    return this._withSpatialIndex
  }
  get size() {
    return this._delegateStore.size
  }
}
