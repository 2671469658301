import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { isNumber } from '../util/Lang.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Conic } from './Conic.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('LambertConformal')
export class LambertConformal extends Conic {
  constructor(t, s, i, h) {
    super()
    this._circleN = -1
    this._circleF = -1
    this._circleRho0 = -1
    this._originSinLat = -1
    this._firstParallelCos = -1
    this._firstParallelSin = -1
    this._firstParallelSin2 = -1
    this._secondParallelSin = -1
    this._secondParallelSin2 = -1
    this._cosPar1overCosPar2 = -1
    this._tanPar1_p = -1
    this._tan1overTan2 = -1
    this._A = -1
    this._E = -1
    this._AtimesF = -1
    this._Rho0 = -1
    this._N = -1
    this._B1 = -1
    this._B2 = -1
    this._B3 = -1
    this._B4 = -1
    this._northLatLimit = 80
    this._southLatLimit = -65
    this._hash = 0
    this.setFirstParallel(isNumber(t) ? t : 42)
    this.setSecondParallel(isNumber(s) ? s : 57)
    this.setOriginLon(isNumber(i) ? i : 13)
    this.setOriginLat(isNumber(h) ? h : 50)
    this.calculateCachedValues()
  }
  setNorthLatitudeLimit(t) {
    this._northLatLimit = t
  }
  setSouthLatitudeLimit(t) {
    this._southLatLimit = t
  }
  getNorthLatitudeLimit() {
    return this._northLatLimit
  }
  getSouthLatitudeLimit() {
    return this._southLatLimit
  }
  calculateCachedValues() {
    const t = this.getOriginLat() * Constants.DEG2RAD
    const s = this.getFirstParallel() * Constants.DEG2RAD
    const i = this.getSecondParallel() * Constants.DEG2RAD
    const h = Math.cos(s)
    const n = Math.cos(i)
    const o = Math.tan(Math.PI / 4 + s / 2)
    const a = Math.tan(Math.PI / 4 + i / 2)
    if (s == i) this._circleN = Math.sin(s)
    else this._circleN = Math.log(h / n) / Math.log(a / o)
    this._circleF = (h * Math.pow(o, this._circleN)) / this._circleN
    this._circleRho0 =
      this._circleF / Math.pow(Math.tan(Math.PI / 4 + t / 2), this._circleN)
    this._originSinLat = Math.sin(t)
    this._firstParallelCos = h
    this._firstParallelSin = Math.sin(s)
    this._firstParallelSin2 = this._firstParallelSin * this._firstParallelSin
    this._secondParallelSin = Math.sin(i)
    this._secondParallelSin2 = this._secondParallelSin * this._secondParallelSin
    this._cosPar1overCosPar2 = h / n
    this._tanPar1_p = Math.tan(Math.PI / 4 - s / 2)
    this._tan1overTan2 = this._tanPar1_p / Math.tan(Math.PI / 4 - i / 2)
    this._A = -1
    this._E = -1
    const e = new Hash()
    this._hash = e
      .appendDouble(this.getOriginLon())
      .appendDouble(this.getOriginLat())
      .appendDouble(this.getFirstParallel())
      .appendDouble(this.getSecondParallel())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  updateCachedValues(t) {
    if (t.a != this._A || t.e != this._E) {
      this._A = t.a
      this._E = t.e
      const s = this._A
      const i = this._E
      const h = this._E * this._E
      const n = i * this._originSinLat
      const o = i * this._firstParallelSin
      const a = i * this._secondParallelSin
      const e = this._tanPar1_p * Math.pow((1 + o) / (1 - o), i / 2)
      const r = ((1 - a) * (1 + o)) / ((1 + a) * (1 - o))
      const l = this._tan1overTan2 * Math.pow(r, i / 2)
      const c = Math.sqrt(
        ((1 - this._originSinLat) / (1 + this._originSinLat)) *
          Math.pow((1 + n) / (1 - n), i)
      )
      const _ = Math.sqrt(1 - h * this._firstParallelSin2)
      const L = Math.sqrt(1 - h * this._secondParallelSin2)
      const u = this._firstParallelCos / _
      const d = (this._cosPar1overCosPar2 * L) / _
      let M
      if (this.getFirstParallel() == this.getSecondParallel())
        M = this._firstParallelSin
      else M = Math.log(d) / Math.log(l)
      const P = (s * u) / (M * Math.pow(e, M))
      const m = P * Math.pow(c, M)
      this._AtimesF = P
      this._Rho0 = m
      this._N = M
      const p = h * h
      const f = p * h
      const g = p * p
      const O = h / 2 + (5 / 24) * p + (1 / 12) * f + (13 / 360) * g
      const E = (7 / 48) * p + (29 / 240) * f + (811 / 11520) * g
      const N = (7 / 120) * f + (81 / 1120) * g
      const C = (4279 / 161280) * g
      this._B1 = 2 * O + 4 * E + 6 * N + 8 * C
      this._B2 = -8 * E - 32 * N - 80 * C
      this._B3 = 32 * N + 192 * C
      this._B4 = -128 * C
    }
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, s, i) {
    if (this.inLonLatBounds(t)) {
      const h = t.y * Constants.DEG2RAD
      const n =
        this._circleN *
        normalizeLon(t.x - this.getOriginLon()) *
        Constants.DEG2RAD
      const o = undefined
      const a =
        (s * this._circleF) /
        Math.pow(Math.tan(Math.PI / 4 + h / 2), this._circleN)
      const e = a * Math.sin(n)
      const r = s * this._circleRho0 - a * Math.cos(n)
      i.x = e
      i.y = r
    } else throw sharedOutOfBoundsError
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, i) {
    if (this.inLonLatBounds(t)) {
      this.updateCachedValues(s)
      const h = s.e
      const n = Math.sin(t.y * Constants.DEG2RAD)
      const o = h * n
      const a = Math.sqrt(((1 - n) / (1 + n)) * Math.pow((1 + o) / (1 - o), h))
      const e =
        this._N * normalizeLon(t.x - this.getOriginLon()) * Constants.DEG2RAD
      const r = this._AtimesF * Math.pow(a, this._N)
      const l = r * Math.sin(e)
      const c = this._Rho0 - r * Math.cos(e)
      i.x = l
      i.y = c
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereSFCT(t, s, i) {
    if (this.inWorldBoundsOnSphere(t, s)) {
      let h = this._circleRho0 * s
      let n = t.x
      let o = t.y
      let a = Math.sqrt(n * n + (h - o) * (h - o))
      if (this._circleN < 0) {
        a = -a
        h = -h
        o = -o
        n = -n
      }
      const e = Math.atan2(n, h - o)
      i.x = normalizeLon(
        (e / this._circleN) * Constants.RAD2DEG + this.getOriginLon()
      )
      i.y =
        (2 * Math.atan(Math.pow((s * this._circleF) / a, 1 / this._circleN)) -
          Math.PI / 2) *
        Constants.RAD2DEG
      if (!this.inLonLatBounds(i)) throw sharedOutOfBoundsError
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, i) {
    if (this.inWorldBoundsOnEllipsoid(t, s)) {
      this.updateCachedValues(s)
      let h = t.x
      const n = t.y
      const o = undefined
      let a = this._Rho0 - n
      let e = Math.sqrt(h * h + a * a)
      if (this._N < 0) {
        h = -h
        e = -e
        a = -a
      }
      const r = Math.pow(e / this._AtimesF, 1 / this._N)
      const l = Math.PI / 2 - 2 * Math.atan(r)
      const c = Math.cos(l)
      const _ = Math.sin(l)
      const L = _ * _
      const u =
        (l +
          _ * c * (this._B1 + L * (this._B2 + L * (this._B3 + L * this._B4)))) *
        Constants.RAD2DEG
      const d = Constants.RAD2DEG * Math.atan2(h, a)
      i.x = normalizeLon(d / this._N + this.getOriginLon())
      i.y = u
      if (!this.inLonLatBounds(i)) throw sharedOutOfBoundsError
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    if (this._circleN > 0) return t.y >= this._southLatLimit * (1 + 1e-15)
    else if (this._circleN < 0) return t.y <= this._northLatLimit * (1 + 1e-15)
    else
      return (
        t.y >= this._southLatLimit * (1 + 1e-15) &&
        t.y <= this._northLatLimit * (1 + 1e-15)
      )
  }
  inWorldBoundsOnSphere(t, s) {
    const i = s * this._circleRho0 - t.y
    const h = t.x
    const n = h * h + i * i
    if (n < 1e-8) return true
    const o = Math.atan2(h, i)
    let a = this._circleN * Math.PI * (1 + 1e-15)
    const e =
      Constants.DEG2RAD *
      (this._circleN > 0 ? this._southLatLimit : this._northLatLimit)
    let r =
      (s * this._circleF) /
      Math.pow(Math.tan(Math.PI / 4 + e / 2), this._circleN)
    r *= 1 + 1e-14
    if (this._circleN < 0) {
      a = Math.PI + a
      return n <= r * r && !(o < a && o > -a)
    } else return n <= r * r && o <= a && o >= -a
  }
  boundaryLons(t) {
    return t >= this._southLatLimit && t <= this._northLatLimit
      ? [
          [
            normalizeLon(-180 + this.getOriginLon() + this.EPSILON / 4),
            normalizeLon(180 + this.getOriginLon() - this.EPSILON / 4),
          ],
        ]
      : []
  }
  boundaryLats(t) {
    return this._circleN > 0
      ? [[this._southLatLimit + this.EPSILON, 90]]
      : this._circleN < 0
      ? [[-90, this._northLatLimit - this.EPSILON]]
      : [
          [
            this._southLatLimit + this.EPSILON,
            this._northLatLimit - this.EPSILON,
          ],
        ]
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    const i =
      Constants.DEG2RAD *
      (this._circleN > 0 ? this._southLatLimit : this._northLatLimit)
    const h = Math.abs(
      (t * this._circleF) /
        Math.pow(Math.tan(Math.PI / 4 + i / 2), this._circleN)
    )
    s.setTo2D(-h, 2 * h, this._circleRho0 * t - h, 2 * h)
  }
  cartesianBoundsOnEllipsoidSFCT(t, s) {
    this.updateCachedValues(t)
    const i =
      Constants.DEG2RAD *
      (this._N > 0 ? this._southLatLimit : this._northLatLimit)
    const h = Math.sin(i)
    const n = this._E * h
    const o = Math.sqrt(
      ((1 - h) / (1 + h)) * Math.pow((1 + n) / (1 - n), this._E)
    )
    const a = Math.abs(this._AtimesF * Math.pow(o, this._N))
    s.setTo2D(-a, 2 * a, this._Rho0 - a, 2 * a)
  }
  inWorldBoundsOnEllipsoid(t, s) {
    this.updateCachedValues(s)
    const i = this._Rho0 - t.y
    const h = t.x
    const n = h * h + i * i
    if (n < 1e-8) return true
    const o = Math.atan2(h, i)
    let a = this._N * Math.PI * (1 + 1e-15)
    const e =
      Constants.DEG2RAD *
      (this._N > 0 ? this._southLatLimit : this._northLatLimit)
    const r = Math.sin(e)
    const l = this._E * r
    const c = Math.sqrt(
      ((1 - r) / (1 + r)) * Math.pow((1 + l) / (1 - l), this._E)
    )
    let _ = this._AtimesF * Math.pow(c, this._N)
    _ *= 1 + 1e-14
    if (this._N < 0) {
      a = Math.PI + a
      return n <= _ * _ && !(o < a && o > -a)
    } else return n <= _ * _ && o <= a && o >= -a
  }
  encode() {
    return {
      type: 'LambertConformal',
      firstParallel: this.getFirstParallel(),
      secondParallel: this.getSecondParallel(),
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.LAMBERT_CONFORMAL + ProjectionType.CONIC
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
