import { isDefined, isUndefined } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { mimeType } from '../../util/mimeType.js'
import { WithHttpRequestOptions } from '../../util/WithHttpRequestOptions.js'
import { GeoJsonCodec } from '../codec/GeoJsonCodec.js'
import { performRequestAndConvertToCursorPromise } from './StoreUtil.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
export class UrlStore {
  constructor(e) {
    e = e || {}
    const { target: t, reference: r, handleAs: s, accepts: o, codec: i } = e
    if (!t)
      throw new ProgrammingError(
        'UrlStore: options.target is a required parameter'
      )
    this._target = t
    this._accepts = o || `${mimeType.js}, ${mimeType.json}`
    this._handleAs = s || 'string'
    this._reference = r
    this._codec = i || new GeoJsonCodec({ reference: r })
    if (isDefined(e.withCredentials)) {
      Log.deprecated('options.withCredentials', 'options.credentials')
      if (isUndefined(e.credentials)) e.credentials = e.withCredentials
    }
    this._support = new WithHttpRequestOptions(e)
  }
  get target() {
    return this._target
  }
  get codec() {
    return this._codec
  }
  get reference() {
    return this._reference
  }
  get accepts() {
    return this._accepts
  }
  get handleAs() {
    return this._handleAs
  }
  get credentials() {
    return this._support.credentials
  }
  set credentials(e) {
    this._support.credentials = e
  }
  get requestHeaders() {
    return this._support.requestHeaders
  }
  set requestHeaders(e) {
    this._support.requestHeaders = e
  }
  get requestParameters() {
    return this._support.requestParameters
  }
  set requestParameters(e) {
    this._support.requestParameters = e
  }
  query(e, t) {
    t = t || {}
    const r = { Accept: this.accepts }
    const s = this._support.addHttpRequestOptions({
      method: 'GET',
      headers: r,
      signal: t?.abortSignal,
    })
    const { target: o, codec: i, handleAs: n } = this
    const a = t.reference || this.reference
    const c = this._support.getUrlWithRequestParameters(o)
    return performRequestAndConvertToCursorPromise(c, s, i, a, n)
  }
}
