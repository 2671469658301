import { createPhotonColorFromString } from '../Color.js'
import { UUID } from '../UUID.js'
import { VecMath } from '../VecMath.js'
import { PhotonWeakMap } from '../../view/feature/photon/PhotonWeakMap.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { isDefined } from '../Lang.js'
class Parameter {
  constructor(e, n, t) {
    this._parameterMap = new PhotonWeakMap()
    this._key = 0
    this._name = this._generateName()
    this._value = e
    this._resolve = n
    this._setter = t
    this._photonReady = false
    Photon.whenReady(() => {
      this._photonReady = true
      this.updatePhoton()
      if (this._invalidator) this._invalidator.invalidate()
    })
  }
  _generateName() {
    const e = undefined
    return `p${UUID.timeUUID().replace(/-/g, '')}`
  }
  resolve(e, n, t, o, r) {
    this._invalidator = n
    const i = this._resolve(e, this._name, this._value)
    this._parameterMap.set(this._key, i)
    this._key++
    return i
  }
  get value() {
    return this._value
  }
  set value(e) {
    this._value = e
    this.updatePhoton()
    if (this._invalidator) this._invalidator.invalidate()
  }
  updatePhoton() {
    if (!this._photonReady) return
    this._parameterMap.forEach((e, n, t) => {
      this._setter(e, this._value)
      e.release()
    })
  }
}
class CaseExpressionImpl {
  constructor(e) {
    this._resolve = e
    this._whens = []
    this._thens = []
  }
  resolve(e, n, t, o, r) {
    const i = this._resolve(e, n, t, o, r)
    for (let u = 0; u < this._whens.length; u++) {
      const s = e.resolve(this._whens[u], n, t, o, r)
      const l = e.resolve(this._thens[u], n, t, o, r)
      i.when(s)
      i.then(l)
      s.release()
      l.release()
    }
    return i
  }
  when(e) {
    this._whens.push(e)
    return this
  }
  then(e) {
    this._thens.push(e)
    return this
  }
}
class OrientedBoxExpressionImpl {
  constructor(e) {
    this._value = e
    const n = e.getCorners()
    this._localOriginLow = pointParameter({ x: 0, y: 0, z: 0 })
    this._localOriginHigh = pointParameter({ x: 0, y: 0, z: 0 })
    this._corners = [
      pointParameter(n[0]),
      pointParameter(n[1]),
      pointParameter(n[2]),
      pointParameter(n[3]),
      pointParameter(n[4]),
      pointParameter(n[5]),
      pointParameter(n[6]),
      pointParameter(n[7]),
    ]
    this._invalidator = null
    this._photonReady = false
    Photon.whenReady(() => {
      this._photonReady = true
      this.updatePhoton()
      if (this._invalidator) this._invalidator.invalidate()
    })
  }
  resolve(e, n, t, o, r) {
    this._invalidator = n
    const i = []
    for (let u = 0; u < this._corners.length; u++)
      i.push(e.resolve(this._corners[u], n, t, o, r))
    return e.orientedBox(
      e.resolve(this._localOriginLow, n, t, o, r),
      e.resolve(this._localOriginHigh, n, t, o, r),
      i
    )
  }
  get value() {
    return this._value.copy()
  }
  set value(e) {
    this._value = e.copy()
    this.updatePhoton()
    if (this._invalidator) this._invalidator.invalidate()
  }
  updatePhoton() {
    if (!this._photonReady) return
    const e = this._value.getCorners()
    const n = e[0]
    const t = Photon.DoubleFloat.encodeJs(n)
    this._localOriginLow.value = { x: t.lowX, y: t.lowY, z: t.lowZ }
    this._localOriginHigh.value = { x: t.highX, y: t.highY, z: t.highZ }
    for (let t = 0; t < e.length; t++)
      this._corners[t].value = VecMath.sub([e[t], n])
  }
}
export function attribute(e) {
  let n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1
  return { resolve: (t, o, r, i, u) => t.attribute(e, n) }
}
export function positionAttribute() {
  const e = 1 == arguments.length ? !!arguments[0] : false
  return {
    resolve: function (n, t, o, r, i) {
      return n.positionAttribute(isDefined(e) ? e : false)
    },
  }
}
export function booleanParameter(e) {
  const n = undefined
  const t = undefined
  return new Parameter(
    e,
    (e, n, t) => e.booleanParameter(n, t),
    (e, n) => {
      e.setBoolean(n)
    }
  )
}
export function numberParameter(e) {
  const n = undefined
  const t = undefined
  return new Parameter(
    e,
    (e, n, t) => e.numberParameter(n, t),
    (e, n) => {
      e.setFloat(n)
    }
  )
}
export function pointParameter(e) {
  const n = undefined
  const t = undefined
  return new Parameter(
    e,
    (e, n, t) => e.pointParameter(n, t),
    (e, n) => {
      e.setPoint(n)
    }
  )
}
export function colorParameter(e) {
  const n = undefined
  const t = undefined
  return new Parameter(
    e,
    (e, n, t) => e.colorParameter(n, createPhotonColorFromString(t)),
    (e, n) => {
      const t = createPhotonColorFromString(n)
      if (null === t) throw new Error(`Could not parse color value "${n}."`)
      e.setColor(t)
    }
  )
}
export function boolean(e) {
  return { resolve: (n, t, o, r, i) => n.booleanConstant(e) }
}
export function number(e) {
  return { resolve: (n, t, o, r, i) => n.numberConstant(e) }
}
export function point(e) {
  return { resolve: (n, t, o, r, i) => n.pointConstant(e) }
}
export function color(e) {
  return {
    resolve: (n, t, o, r, i) => n.colorConstant(createPhotonColorFromString(e)),
  }
}
export function orientedBox(e) {
  return new OrientedBoxExpressionImpl(e)
}
export function eq(e, n, t) {
  if (!isDefined(t) || null == t)
    return {
      resolve: function (t, o, r, i, u) {
        return t.binaryFunction(
          Photon.FunctionType.Eq,
          t.resolve(e, o, r, i, u),
          t.resolve(n, o, r, i, u)
        )
      },
    }
  else {
    const o = undefined
    const r = undefined
    return lt(abs(subtract(e, n)), t)
  }
}
export function areClose(e, n, t) {
  return {
    resolve: function (o, r, i, u, s) {
      return o.areClose(
        o.resolve(e, r, i, u, s),
        o.resolve(n, r, i, u, s),
        o.resolve(t, r, i, u, s)
      )
    },
  }
}
export function neq(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Neq,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function lt(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Lt,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function abs(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Abs, n.resolve(e, t, o, r, i))
    },
  }
}
export function lte(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Lte,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function gt(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Gt,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function gte(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Gte,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function between(e, n, t) {
  return and(gte(e, n), lte(e, t))
}
export function and() {
  for (var e = arguments.length, n = new Array(e), t = 0; t < e; t++)
    n[t] = arguments[t]
  return {
    resolve: function (e, t, o, r, i) {
      let u = e.resolve(n[0], t, o, r, i)
      for (let s = 1; s < n.length; s++)
        u = e.binaryFunction(
          Photon.FunctionType.And,
          u,
          e.resolve(n[s], t, o, r, i)
        )
      return u
    },
  }
}
export function or() {
  for (var e = arguments.length, n = new Array(e), t = 0; t < e; t++)
    n[t] = arguments[t]
  return {
    resolve: function (e, t, o, r, i) {
      let u = e.resolve(n[0], t, o, r, i)
      for (let s = 1; s < n.length; s++)
        u = e.binaryFunction(
          Photon.FunctionType.Or,
          u,
          e.resolve(n[s], t, o, r, i)
        )
      return u
    },
  }
}
export function not(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Not, n.resolve(e, t, o, r, i))
    },
  }
}
export function add(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Add,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function subtract(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Subtract,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function multiply(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Multiply,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function divide(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Divide,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function fraction(e, n, t) {
  return divide(subtract(e, n), subtract(t, n))
}
export function min(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Min,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function max(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Max,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function pow(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Pow,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function clamp(e, n, t) {
  return {
    resolve: function (o, r, i, u, s) {
      return o.ternaryFunction(
        Photon.FunctionType.Clamp,
        o.resolve(e, r, i, u, s),
        o.resolve(n, r, i, u, s),
        o.resolve(t, r, i, u, s)
      )
    },
  }
}
export function mix(e, n, t) {
  return {
    resolve: function (o, r, i, u, s) {
      return o.ternaryFunction(
        Photon.FunctionType.Mix,
        o.resolve(e, r, i, u, s),
        o.resolve(n, r, i, u, s),
        o.resolve(t, r, i, u, s)
      )
    },
  }
}
export function sin(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Sin, n.resolve(e, t, o, r, i))
    },
  }
}
export function asin(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Asin, n.resolve(e, t, o, r, i))
    },
  }
}
export function cos(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Cos, n.resolve(e, t, o, r, i))
    },
  }
}
export function acos(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Acos, n.resolve(e, t, o, r, i))
    },
  }
}
export function tan(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Tan, n.resolve(e, t, o, r, i))
    },
  }
}
export function atan(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Atan, n.resolve(e, t, o, r, i))
    },
  }
}
export function log(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(Photon.FunctionType.Log, n.resolve(e, t, o, r, i))
    },
  }
}
export function dotProduct(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Dot,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function crossProduct(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Cross,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function distance(e, n) {
  return {
    resolve: function (t, o, r, i, u) {
      return t.binaryFunction(
        Photon.FunctionType.Distance,
        t.resolve(e, o, r, i, u),
        t.resolve(n, o, r, i, u)
      )
    },
  }
}
export function normalize(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(
        Photon.FunctionType.Normalize,
        n.resolve(e, t, o, r, i)
      )
    },
  }
}
export function length(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.unaryFunction(
        Photon.FunctionType.Length,
        n.resolve(e, t, o, r, i)
      )
    },
  }
}
export function ifThenElse(e, n, t) {
  return {
    resolve: function (o, r, i, u, s) {
      return o.ifThenElse(
        o.resolve(e, r, i, u, s),
        o.resolve(n, r, i, u, s),
        o.resolve(t, r, i, u, s)
      )
    },
  }
}
export function cases(e) {
  return new CaseExpressionImpl((n, t, o, r, i) =>
    n.cases(n.resolve(e, t, o, r, i))
  )
}
export function icon(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.icon(e, o, r, i)
    },
  }
}
export function map(e, n, t) {
  return {
    resolve: function (o, r, i, u, s) {
      const l = []
      for (let e = 0; e < n.length; e++) l.push(o.resolve(n[e], r, i, u, s))
      return o.map(o.resolve(e, r, i, u, s), l, o.resolve(t, r, i, u, s))
    },
  }
}
export function mixmap(e, n) {
  const t = n.length - 1
  const o = cases(n[t])
  for (let r = 1; r < n.length; r++) {
    const i = n[r - 1]
    const u = n[r]
    const s = number((r - 1) / t)
    const l = number(r / t)
    o.when(lte(e, l))
    o.then(mix(i, u, fraction(e, s, l)))
  }
  return o
}
export function isInside(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.isInside(n.resolve(e, t, o, r, i))
    },
  }
}
export function pushDown(e) {
  return {
    resolve: function (n, t, o, r, i) {
      return n.pushDown(n.resolve(e, t, o, r, i))
    },
  }
}
export function defaultColor() {
  return {
    resolve: function (e, n, t, o, r) {
      return e.nullaryFunction(Photon.FunctionType.DefaultColor)
    },
  }
}
export function frontFacing() {
  return {
    resolve: function (e, n, t, o, r) {
      return e.nullaryFunction(Photon.FunctionType.FrontFacingFragment)
    },
  }
}
