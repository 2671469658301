import { ProgrammingError } from '../../../../error/ProgrammingError.js'
import { Stroked } from './util/Stroked.js'
import { Filled } from './util/Filled.js'
import { Pattern } from '../Pattern.js'
import { PatternType } from './util/PatternType.js'
import { Length } from './util/Length.js'
const DEF_X = [0, 10]
const DEF_Y = [0, 0]
export class PolylinePattern extends Pattern {
  constructor(t) {
    super(PatternType.POLYLINE)
    const e = t.points || []
    const i = []
    const s = []
    const h = e.length
    for (let t = 0; t < h; t++) {
      i.push(e[t][0])
      s.push(e[t][1])
    }
    const r = t.line || {}
    const n = t.fill || {}
    const l = i || DEF_X
    const o = s || DEF_Y
    if (!l || !o)
      throw new ProgrammingError(
        'The coordinates of the polyline should not be null.'
      )
    if (l.length !== o.length)
      throw new ProgrammingError(
        'Polyline x and y coordinate arrays do not match'
      )
    if (l.length < 2)
      throw new ProgrammingError('Polyline should have at least two points')
    this._stroked = new Stroked(r)
    this._filled = new Filled(n)
    this._relative = t.relative ?? false
    this._xs = l
    this._ys = o
    this._nrOfPoints = this._xs.length
    this._closed =
      this._xs[0] === this._xs[this._nrOfPoints - 1] &&
      this._ys[0] === this._ys[this._nrOfPoints - 1]
    this._minHeight = 0
    this._maxHeight = 0
    this._minX = 0
    this._maxX = 0
    this.calculateMinMaxHeight()
    const a = t.relative ? 0 : this._maxX - this._minX
    const _ = t.relative ? this._maxX - this._minX : 0
    this._length = new Length({ fixedLength: a, relativeLength: _ })
  }
  calculateMinMaxHeight() {
    let t = Number.NEGATIVE_INFINITY,
      e = Number.POSITIVE_INFINITY
    let i = Number.NEGATIVE_INFINITY,
      s = Number.POSITIVE_INFINITY
    for (let h = 0; h < this._nrOfPoints; h++) {
      t = Math.max(t, this._ys[h])
      e = Math.min(e, this._ys[h])
      i = Math.max(i, this._xs[h])
      s = Math.min(s, this._xs[h])
    }
    this._minHeight = e
    this._maxHeight = t
    this._minX = s
    this._maxX = i
  }
  get relative() {
    return this._relative
  }
  get xs() {
    return this._xs
  }
  get ys() {
    return this._ys
  }
  getMinHeight() {
    return this._minHeight - this.lineWidth / 2
  }
  getMaxHeight() {
    return this._maxHeight + this.lineWidth / 2
  }
  getWidth() {
    let t = 0
    for (let e = 0; e < this._nrOfPoints; e += 1) t = Math.max(t, this._xs[e])
    return t
  }
  strokePath(t) {
    this._stroked.strokePath(t)
  }
  get lineColor() {
    return this._stroked.lineColor
  }
  set lineColor(t) {
    this._stroked.lineColor = t
  }
  get lineWidth() {
    return this._stroked.lineWidth
  }
  set lineWidth(t) {
    this._stroked.lineWidth = t
  }
  get dashed() {
    return this._stroked.dashed
  }
  set dashed(t) {
    this._stroked.dashed = t
  }
  fillPath(t) {
    this._filled.fillPath(t)
  }
  get fillColor() {
    return this._filled.fillColor
  }
  set fillColor(t) {
    this._filled.fillColor = t
  }
  get worldSizeContext() {
    return this._length.worldSizeContext
  }
  set worldSizeContext(t) {
    this._length.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._length.getUomSizeToPixels(t)
  }
  isRelativeLength() {
    return this._length.isRelativeLength()
  }
  get fixedLength() {
    return this._length.fixedLength
  }
  set fixedLength(t) {
    this._length.fixedLength = t
  }
  get relativeLength() {
    return this._length.relativeLength
  }
  set relativeLength(t) {
    this._length.relativeLength = t
  }
  getPolylineWidth(t, e) {
    return this._length.getWidth(e)
  }
  paint(t, e, i, s) {
    t.scale(1, -1)
    t.beginPath()
    t.moveTo(this._xs[0], this._ys[0])
    for (let e = 1; e < this._nrOfPoints; e += 1)
      t.lineTo(this._xs[e], this._ys[e])
    if (this._closed) t.closePath()
    this.fillPath(t)
    this.strokePath(t)
    t.scale(1, -1)
  }
  appendHash(t) {
    for (let e = 0; e < this._nrOfPoints; e++) {
      t.appendDouble(this._xs[e])
      t.appendDouble(this._ys[e])
    }
    t.appendBoolean(this._relative)
    this._stroked.appendHash(t)
  }
}
