import { hasProperty, isString } from '../Lang.js'
import { xmlReduceChildren } from './KMLInternalUtil.js'
import {
  isAliasNode,
  isAltitudeValue,
  isHeadingValue,
  isLatitudeValue,
  isLongitudeValue,
  isRollValue,
  isSourceHrefValue,
  isTargetHrefValue,
  isTiltValue,
  isXValue,
  isYValue,
  isZValue,
} from './KMLTypes.js'
const BOOLEAN_MAP = { 1: true, true: true, 0: false, false: false }
function kmlBooleanFactory(e) {
  return kmlValueMapFactory(e, BOOLEAN_MAP)
}
export function kmlNumberFactory(e, t) {
  const l = function (t) {
    let l = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e
    const r = isString(t) ? t : t && getKmlValue(t)
    if (null !== r) {
      const e = +r
      if (!isNaN(e) && isFinite(e)) return e
    }
    return l
  }
  if (t) return (e, r) => t(l(e, r))
  return l
}
export function kmlValueFactory(e, t) {
  const l = new XMLSerializer()
  const r = function (t) {
    let r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e
    if (isString(t)) return t
    if (!t || null === t.firstChild) return r
    if (0 === t.childElementCount) return t.textContent
    const n = new Array(t.childNodes.length)
    for (let e = t.firstChild, r = 0; null !== e; e = e.nextSibling)
      n[r++] = l.serializeToString(e)
    return n.join('')
  }
  return t
    ? function (l) {
        let n =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e
        return t(r(l, n), n)
      }
    : r
}
export function kmlValueMapFactory(e, t) {
  return function (l) {
    let r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e
    if (null === l) return r
    const n = isString(l) ? l : getKmlValue(l)
    if (null === n) return r
    if (hasProperty(t, n)) return t[n]
    return r
  }
}
function kmlAngleFactory() {
  for (var e = arguments.length, t = new Array(e), l = 0; l < e; l++)
    t[l] = arguments[l]
  return kmlNumberFactory(0, (e) => {
    e %= 360
    for (const l of t) {
      if (e > l) e -= 2 * l
      if (e < -l) e += 2 * l
    }
    return e
  })
}
function kmlAngle90Factory() {
  return kmlAngleFactory(180, 90)
}
function kmlAngle180Factory() {
  return kmlAngleFactory(180)
}
export const getKmlAngle90 = kmlAngle90Factory()
export const getKmlAngle180 = kmlAngle180Factory()
export const getKmlAngle360 = kmlAngleFactory()
export const getKmlAnglePos90 = (e) => Math.abs(getKmlAngle90(e))
export const getKmlBoolean = kmlBooleanFactory(false)
export const getKmlNumber = kmlNumberFactory(0)
export const getKmlValue = kmlValueFactory(null)
export const getRefreshVisibilityValue = kmlBooleanFactory(false)
export const getVisibilityValue = kmlBooleanFactory(true)
export function getLocationValue(e) {
  let t =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { lat: 0, lon: 0, alt: 0 }
  return xmlReduceChildren(
    e,
    (e, t) => {
      if (isLongitudeValue(t)) e.lon = getLongitudeValue(t)
      if (isLatitudeValue(t)) e.lat = getLatitudeValue(t)
      if (isAltitudeValue(t)) e.alt = getAltitudeValue(t)
      return e
    },
    t
  )
}
export function getOrientationValue(e) {
  let t =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { heading: 0, roll: 0, pitch: 0 }
  return xmlReduceChildren(
    e,
    (e, t) => {
      if (isHeadingValue(t)) e.heading = getHeadingValue(t)
      if (isTiltValue(t)) e.pitch = getTiltValue(t)
      if (isRollValue(t)) e.roll = getRollValue(t)
      return e
    },
    t
  )
}
export function getResourceMapValue(e) {
  let t =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { alias: [] }
  return xmlReduceChildren(
    e,
    (e, t) => {
      if (isAliasNode(t)) e.alias.push(getAliasValue(t))
      return e
    },
    t
  )
}
export function getAliasValue(e) {
  let t =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { sourceHref: null, targetHref: null }
  return xmlReduceChildren(
    e,
    (e, t) => {
      if (isSourceHrefValue(t)) e.sourceHref = getKmlValue(t)
      if (isTargetHrefValue(t)) e.targetHref = getKmlValue(t)
      return e
    },
    t
  )
}
export function getScaleValue(e) {
  let t =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { x: 1, y: 1, z: 1 }
  return xmlReduceChildren(
    e,
    (e, t) => {
      if (isXValue(t)) e.x = getXValue(t)
      if (isYValue(t)) e.y = getYValue(t)
      if (isZValue(t)) e.z = getZValue(t)
      return e
    },
    t
  )
}
export const getHeadingValue = kmlAngleFactory()
export const getAltitudeValue = kmlNumberFactory(0)
export const getLatitudeValue = kmlAngle180Factory()
export const getLongitudeValue = kmlAngle90Factory()
export const getTargetHrefValue = kmlValueFactory(null)
export const getSourceHrefValue = kmlValueFactory(null)
export const getTiltValue = (e) => Math.abs(getKmlAngle90(e))
export const getRollValue = kmlAngle180Factory()
export const getXValue = kmlNumberFactory(0)
export const getYValue = kmlNumberFactory(0)
export const getZValue = kmlNumberFactory(0)
export function attributeLookupFactory(e, t) {
  return t ? (l) => t(l.getAttribute(e)) : (t) => t.getAttribute(e)
}
export const getKmlId = attributeLookupFactory('id')
export const getSnippetMaxLines = attributeLookupFactory(
  'maxLines',
  kmlNumberFactory(2)
)
export const getKmlTag = (e) => e.tagName
