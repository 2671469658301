import { DummyGeoCanvas } from './DummyGeoCanvas.js'
import { createDrawNull } from './HTML5DrawCommands.js'
export class DelegatingEditingGeoCanvas {
  static REGISTERED_EDITING_GEOCANVAS = null
  constructor() {
    this.geoCanvas = new DummyGeoCanvas()
  }
  get worldReference() {
    return this.geoCanvas.worldReference
  }
  static registerEditingGeoCanvas(e) {
    DelegatingEditingGeoCanvas.REGISTERED_EDITING_GEOCANVAS = e
  }
  static getEditingGeoCanvas() {
    return DelegatingEditingGeoCanvas.REGISTERED_EDITING_GEOCANVAS
  }
  static getEditingGeometry() {
    const e = DelegatingEditingGeoCanvas.REGISTERED_EDITING_GEOCANVAS
    if (e) {
      const a = e.getEditingGeometry()
      if (a) return a
    }
    return null
  }
  static resetEditingGeometry() {
    const e = DelegatingEditingGeoCanvas.REGISTERED_EDITING_GEOCANVAS
    if (e) e.resetEditingGeometry()
  }
  drawIcon(e, a) {
    this.geoCanvas.drawIcon(e, a)
  }
  drawIcon3D(e, a) {
    this.geoCanvas.drawIcon3D(e, a)
  }
  drawPanorama(e, a, t) {
    this.geoCanvas.drawPanorama(e, a, t)
  }
  drawShape(e, a) {
    this.geoCanvas.drawShape(e, a)
  }
  drawText(e, a, t) {
    this.geoCanvas.drawText(e, a, t)
  }
  _didProcessing() {
    return false
  }
  buildDrawCommand() {
    return createDrawNull()
  }
  reset() {}
}
