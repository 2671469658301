import { Log } from '../../../util/Log.js'
import { Vector3 } from './Vector3.js'
import { isUndefined } from '../../../util/Lang.js'
const DEG2RAD = Math.PI / 180
const EPSILON = 1e-6
export class Matrix4 {
  constructor() {
    this._elements = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
    if (arguments.length > 0)
      Log.error(
        'Matrix4: the constructor no longer reads arguments. use .set() instead.'
      )
  }
  get elements() {
    return this._elements
  }
  set(t, e, s, n, r, o, i, c, a, l, h, m, u, f, y, M) {
    const d = this._elements
    d[0] = t
    d[4] = e
    d[8] = s
    d[12] = n
    d[1] = r
    d[5] = o
    d[9] = i
    d[13] = c
    d[2] = a
    d[6] = l
    d[10] = h
    d[14] = m
    d[3] = u
    d[7] = f
    d[11] = y
    d[15] = M
    return this
  }
  identity() {
    this.set(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
    return this
  }
  clone() {
    return new Matrix4().fromArray(this._elements)
  }
  copy(t) {
    for (let e = 0; e < 16; e++) this._elements[e] = t._elements[e]
    return this
  }
  copyPosition(t) {
    const e = this._elements
    const s = t._elements
    e[12] = s[12]
    e[13] = s[13]
    e[14] = s[14]
    return this
  }
  extractBasis(t, e, s) {
    t.setFromMatrixColumn(this, 0)
    e.setFromMatrixColumn(this, 1)
    s.setFromMatrixColumn(this, 2)
    return this
  }
  makeBasis(t, e, s) {
    this.set(t.x, e.x, s.x, 0, t.y, e.y, s.y, 0, t.z, e.z, s.z, 0, 0, 0, 0, 1)
    return this
  }
  makeRotationFromEuler(t, e) {
    const s = this._elements
    const n = t.x,
      r = t.y,
      o = t.z
    const i = Math.cos(n),
      c = Math.sin(n)
    const a = Math.cos(r),
      l = Math.sin(r)
    const h = Math.cos(o),
      m = Math.sin(o)
    let u, f, y, M, d, x, p, g, _, k, z, w
    switch (e) {
      case 'XYZ':
        ;(u = i * h), (f = i * m), (y = c * h), (M = c * m)
        s[0] = a * h
        s[4] = -a * m
        s[8] = l
        s[1] = f + y * l
        s[5] = u - M * l
        s[9] = -c * a
        s[2] = M - u * l
        s[6] = y + f * l
        s[10] = i * a
        break
      case 'YXZ':
        ;(d = a * h), (x = a * m), (p = l * h), (g = l * m)
        s[0] = d + g * c
        s[4] = p * c - x
        s[8] = i * l
        s[1] = i * m
        s[5] = i * h
        s[9] = -c
        s[2] = x * c - p
        s[6] = g + d * c
        s[10] = i * a
        break
      case 'ZXY':
        ;(d = a * h), (x = a * m), (p = l * h), (g = l * m)
        s[0] = d - g * c
        s[4] = -i * m
        s[8] = p + x * c
        s[1] = x + p * c
        s[5] = i * h
        s[9] = g - d * c
        s[2] = -i * l
        s[6] = c
        s[10] = i * a
        break
      case 'ZYX':
        ;(u = i * h), (f = i * m), (y = c * h), (M = c * m)
        s[0] = a * h
        s[4] = y * l - f
        s[8] = u * l + M
        s[1] = a * m
        s[5] = M * l + u
        s[9] = f * l - y
        s[2] = -l
        s[6] = c * a
        s[10] = i * a
        break
      case 'YZX':
        ;(_ = i * a), (k = i * l), (z = c * a), (w = c * l)
        s[0] = a * h
        s[4] = w - _ * m
        s[8] = z * m + k
        s[1] = m
        s[5] = i * h
        s[9] = -c * h
        s[2] = -l * h
        s[6] = k * m + z
        s[10] = _ - w * m
        break
      case 'XZY':
        ;(_ = i * a), (k = i * l), (z = c * a), (w = c * l)
        s[0] = a * h
        s[4] = -m
        s[8] = l * h
        s[1] = _ * m + w
        s[5] = i * h
        s[9] = k * m - z
        s[2] = z * m - k
        s[6] = c * h
        s[10] = w * m + _
        break
    }
    s[3] = 0
    s[7] = 0
    s[11] = 0
    s[12] = 0
    s[13] = 0
    s[14] = 0
    s[15] = 1
    return this
  }
  makeRotationFromQuaternion(t) {
    const e = this._elements
    const s = t.x,
      n = t.y,
      r = t.z,
      o = t.w
    const i = s + s,
      c = n + n,
      a = r + r
    const l = s * i,
      h = s * c,
      m = s * a
    const u = n * c,
      f = n * a,
      y = r * a
    const M = o * i,
      d = o * c,
      x = o * a
    e[0] = 1 - (u + y)
    e[4] = h - x
    e[8] = m + d
    e[1] = h + x
    e[5] = 1 - (l + y)
    e[9] = f - M
    e[2] = m - d
    e[6] = f + M
    e[10] = 1 - (l + u)
    e[3] = 0
    e[7] = 0
    e[11] = 0
    e[12] = 0
    e[13] = 0
    e[14] = 0
    e[15] = 1
    return this
  }
  orthoGLMatrixJS(t, e, s, n, r, o) {
    const i = 1 / (t - e)
    const c = 1 / (n - s)
    const a = 1 / (r - o)
    const l = this._elements
    l[0] = -2 * i
    l[1] = 0
    l[2] = 0
    l[3] = 0
    l[4] = 0
    l[5] = -2 * c
    l[6] = 0
    l[7] = 0
    l[8] = 0
    l[9] = 0
    l[10] = 2 * a
    l[11] = 0
    l[12] = (t + e) * i
    l[13] = (s + n) * c
    l[14] = (o + r) * a
    l[15] = 1
    return this
  }
  perspectiveGLMatrixJS(t, e, s, n) {
    const r = 1 / Math.tan(t / 2)
    let o
    const i = this._elements
    i[0] = r / e
    i[1] = 0
    i[2] = 0
    i[3] = 0
    i[4] = 0
    i[5] = r
    i[6] = 0
    i[7] = 0
    i[8] = 0
    i[9] = 0
    i[11] = -1
    i[12] = 0
    i[13] = 0
    i[15] = 0
    if (null != n && n !== 1 / 0) {
      o = 1 / (s - n)
      i[10] = (n + s) * o
      i[14] = 2 * n * s * o
    } else {
      i[10] = -1
      i[14] = -2 * s
    }
    return this
  }
  multiply(t, e) {
    if (!isUndefined(e)) {
      Log.warn(
        'Matrix4: .multiply() now only accepts one argument. Use .multiplyMatrices( a, b ) instead.'
      )
      return this.multiplyMatrices(t, e)
    }
    return this.multiplyMatrices(this, t)
  }
  premultiply(t) {
    return this.multiplyMatrices(t, this)
  }
  multiplyMatrices(t, e) {
    const s = t._elements
    const n = e._elements
    const r = this._elements
    const o = s[0],
      i = s[4],
      c = s[8],
      a = s[12]
    const l = s[1],
      h = s[5],
      m = s[9],
      u = s[13]
    const f = s[2],
      y = s[6],
      M = s[10],
      d = s[14]
    const x = s[3],
      p = s[7],
      g = s[11],
      _ = s[15]
    const k = n[0],
      z = n[4],
      w = n[8],
      A = n[12]
    const L = n[1],
      V = n[5],
      S = n[9],
      U = n[13]
    const b = n[2],
      F = n[6],
      P = n[10],
      R = n[14]
    const O = n[3],
      X = n[7],
      Y = n[11],
      Z = n[15]
    r[0] = o * k + i * L + c * b + a * O
    r[4] = o * z + i * V + c * F + a * X
    r[8] = o * w + i * S + c * P + a * Y
    r[12] = o * A + i * U + c * R + a * Z
    r[1] = l * k + h * L + m * b + u * O
    r[5] = l * z + h * V + m * F + u * X
    r[9] = l * w + h * S + m * P + u * Y
    r[13] = l * A + h * U + m * R + u * Z
    r[2] = f * k + y * L + M * b + d * O
    r[6] = f * z + y * V + M * F + d * X
    r[10] = f * w + y * S + M * P + d * Y
    r[14] = f * A + y * U + M * R + d * Z
    r[3] = x * k + p * L + g * b + _ * O
    r[7] = x * z + p * V + g * F + _ * X
    r[11] = x * w + p * S + g * P + _ * Y
    r[15] = x * A + p * U + g * R + _ * Z
    return this
  }
  multiplyToArray(t, e, s) {
    const n = this._elements
    this.multiplyMatrices(t, e)
    s[0] = n[0]
    s[1] = n[1]
    s[2] = n[2]
    s[3] = n[3]
    s[4] = n[4]
    s[5] = n[5]
    s[6] = n[6]
    s[7] = n[7]
    s[8] = n[8]
    s[9] = n[9]
    s[10] = n[10]
    s[11] = n[11]
    s[12] = n[12]
    s[13] = n[13]
    s[14] = n[14]
    s[15] = n[15]
    return this
  }
  multiplyScalar(t) {
    const e = this._elements
    e[0] *= t
    e[4] *= t
    e[8] *= t
    e[12] *= t
    e[1] *= t
    e[5] *= t
    e[9] *= t
    e[13] *= t
    e[2] *= t
    e[6] *= t
    e[10] *= t
    e[14] *= t
    e[3] *= t
    e[7] *= t
    e[11] *= t
    e[15] *= t
    return this
  }
  determinant() {
    const t = this._elements
    const e = t[0],
      s = t[4],
      n = t[8],
      r = t[12]
    const o = t[1],
      i = t[5],
      c = t[9],
      a = t[13]
    const l = t[2],
      h = t[6],
      m = t[10],
      u = t[14]
    const f = undefined,
      y = undefined,
      M = undefined,
      d = undefined
    return (
      t[3] *
        (+r * c * h -
          n * a * h -
          r * i * m +
          s * a * m +
          n * i * u -
          s * c * u) +
      t[7] *
        (+e * c * u -
          e * a * m +
          r * o * m -
          n * o * u +
          n * a * l -
          r * c * l) +
      t[11] *
        (+e * a * h -
          e * i * u -
          r * o * h +
          s * o * u +
          r * i * l -
          s * a * l) +
      t[15] *
        (-n * i * l - e * c * h + e * i * m + n * o * h - s * o * m + s * c * l)
    )
  }
  transpose() {
    const t = this._elements
    let e = t[1]
    t[1] = t[4]
    t[4] = e
    e = t[2]
    t[2] = t[8]
    t[8] = e
    e = t[6]
    t[6] = t[9]
    t[9] = e
    e = t[3]
    t[3] = t[12]
    t[12] = e
    e = t[7]
    t[7] = t[13]
    t[13] = e
    e = t[11]
    t[11] = t[14]
    t[14] = e
    return this
  }
  flattenToArrayOffset(t, e) {
    Log.warn(
      'Matrix3: .flattenToArrayOffset is deprecated - just use .toArray instead.'
    )
    return this.toArray(t, e)
  }
  setPosition(t) {
    const e = this._elements
    e[12] = t.x
    e[13] = t.y
    e[14] = t.z
    return this
  }
  getInverse(t, e) {
    const s = this._elements,
      n = t._elements,
      r = n[0],
      o = n[1],
      i = n[2],
      c = n[3],
      a = n[4],
      l = n[5],
      h = n[6],
      m = n[7],
      u = n[8],
      f = n[9],
      y = n[10],
      M = n[11],
      d = n[12],
      x = n[13],
      p = n[14],
      g = n[15],
      _ = f * p * m - x * y * m + x * h * M - l * p * M - f * h * g + l * y * g,
      k = d * y * m - u * p * m - d * h * M + a * p * M + u * h * g - a * y * g,
      z = u * x * m - d * f * m + d * l * M - a * x * M - u * l * g + a * f * g,
      w = d * f * h - u * x * h - d * l * y + a * x * y + u * l * p - a * f * p
    const A = r * _ + o * k + i * z + c * w
    if (0 === A) {
      const t = "Matrix4.getInverse(): can't invert matrix, determinant is 0"
      if (e || false) throw new Error(t)
      else Log.warn(t)
      return this.identity()
    }
    s[0] = _
    s[1] = x * y * c - f * p * c - x * i * M + o * p * M + f * i * g - o * y * g
    s[2] = l * p * c - x * h * c + x * i * m - o * p * m - l * i * g + o * h * g
    s[3] = f * h * c - l * y * c - f * i * m + o * y * m + l * i * M - o * h * M
    s[4] = k
    s[5] = u * p * c - d * y * c + d * i * M - r * p * M - u * i * g + r * y * g
    s[6] = d * h * c - a * p * c - d * i * m + r * p * m + a * i * g - r * h * g
    s[7] = a * y * c - u * h * c + u * i * m - r * y * m - a * i * M + r * h * M
    s[8] = z
    s[9] = d * f * c - u * x * c - d * o * M + r * x * M + u * o * g - r * f * g
    s[10] =
      a * x * c - d * l * c + d * o * m - r * x * m - a * o * g + r * l * g
    s[11] =
      u * l * c - a * f * c - u * o * m + r * f * m + a * o * M - r * l * M
    s[12] = w
    s[13] =
      u * x * i - d * f * i + d * o * y - r * x * y - u * o * p + r * f * p
    s[14] =
      d * l * i - a * x * i - d * o * h + r * x * h + a * o * p - r * l * p
    s[15] =
      a * f * i - u * l * i + u * o * h - r * f * h - a * o * y + r * l * y
    return this.multiplyScalar(1 / A)
  }
  scale(t) {
    const e = this._elements
    const s = t.x,
      n = t.y,
      r = t.z
    e[0] *= s
    e[4] *= n
    e[8] *= r
    e[1] *= s
    e[5] *= n
    e[9] *= r
    e[2] *= s
    e[6] *= n
    e[10] *= r
    e[3] *= s
    e[7] *= n
    e[11] *= r
    return this
  }
  getMaxScaleOnAxis() {
    const t = this._elements
    const e = t[0] * t[0] + t[1] * t[1] + t[2] * t[2]
    const s = t[4] * t[4] + t[5] * t[5] + t[6] * t[6]
    const n = t[8] * t[8] + t[9] * t[9] + t[10] * t[10]
    return Math.sqrt(Math.max(e, s, n))
  }
  makeTranslation(t, e, s) {
    this.set(1, 0, 0, t, 0, 1, 0, e, 0, 0, 1, s, 0, 0, 0, 1)
    return this
  }
  makeRotationX(t) {
    const e = Math.cos(t),
      s = Math.sin(t)
    this.set(1, 0, 0, 0, 0, e, -s, 0, 0, s, e, 0, 0, 0, 0, 1)
    return this
  }
  makeRotationY(t) {
    const e = Math.cos(t),
      s = Math.sin(t)
    this.set(e, 0, s, 0, 0, 1, 0, 0, -s, 0, e, 0, 0, 0, 0, 1)
    return this
  }
  makeRotationZ(t) {
    const e = Math.cos(t),
      s = Math.sin(t)
    this.set(e, -s, 0, 0, s, e, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
    return this
  }
  makeRotationAxis(t, e) {
    const s = Math.cos(e)
    const n = Math.sin(e)
    const r = 1 - s
    const o = t.x,
      i = t.y,
      c = t.z
    const a = r * o,
      l = r * i
    this.set(
      a * o + s,
      a * i - n * c,
      a * c + n * i,
      0,
      a * i + n * c,
      l * i + s,
      l * c - n * o,
      0,
      a * c - n * i,
      l * c + n * o,
      r * c * c + s,
      0,
      0,
      0,
      0,
      1
    )
    return this
  }
  makeScale(t, e, s) {
    this.set(t, 0, 0, 0, 0, e, 0, 0, 0, 0, s, 0, 0, 0, 0, 1)
    return this
  }
  compose(t, e, s) {
    this.makeRotationFromQuaternion(e)
    this.scale(s)
    this.setPosition(t)
    return this
  }
  makeFrustum(t, e, s, n, r, o) {
    const i = this._elements
    const c = (2 * r) / (e - t)
    const a = (2 * r) / (n - s)
    const l = (e + t) / (e - t)
    const h = (n + s) / (n - s)
    const m = -(o + r) / (o - r)
    const u = (-2 * o * r) / (o - r)
    i[0] = c
    i[4] = 0
    i[8] = l
    i[12] = 0
    i[1] = 0
    i[5] = a
    i[9] = h
    i[13] = 0
    i[2] = 0
    i[6] = 0
    i[10] = m
    i[14] = u
    i[3] = 0
    i[7] = 0
    i[11] = -1
    i[15] = 0
    return this
  }
  makePerspective(t, e, s, n) {
    const r = s * Math.tan(DEG2RAD * t * 0.5)
    const o = -r
    const i = o * e
    const c = r * e
    return this.makeFrustum(i, c, o, r, s, n)
  }
  makeOrthographic(t, e, s, n, r, o) {
    const i = this._elements
    const c = 1 / (e - t)
    const a = 1 / (s - n)
    const l = 1 / (o - r)
    const h = (e + t) * c
    const m = (s + n) * a
    const u = (o + r) * l
    i[0] = 2 * c
    i[4] = 0
    i[8] = 0
    i[12] = -h
    i[1] = 0
    i[5] = 2 * a
    i[9] = 0
    i[13] = -m
    i[2] = 0
    i[6] = 0
    i[10] = -2 * l
    i[14] = -u
    i[3] = 0
    i[7] = 0
    i[11] = 0
    i[15] = 1
    return this
  }
  makeOrthographicLSP(t, e, s, n, r, o) {
    const i = this._elements
    this.identity()
    const c = -(e + t) / (e - t)
    const a = -(s + n) / (s - n)
    const l = -(o + r) / (o - r)
    i[0] = 2 / (e - t)
    i[5] = 2 / (s - n)
    i[10] = -2 / (o - r)
    i[12] = c
    i[13] = a
    i[14] = l
    return this
  }
  equals(t) {
    const e = this._elements
    const s = t._elements
    for (let t = 0; t < 16; t++) if (e[t] !== s[t]) return false
    return true
  }
  fromArray(t) {
    for (let e = 0; e < 16; e++) this._elements[e] = t[e]
    return this
  }
  toArray(t, e) {
    if (isUndefined(t)) t = []
    if (isUndefined(e)) e = 0
    const s = this._elements
    t[e] = s[0]
    t[e + 1] = s[1]
    t[e + 2] = s[2]
    t[e + 3] = s[3]
    t[e + 4] = s[4]
    t[e + 5] = s[5]
    t[e + 6] = s[6]
    t[e + 7] = s[7]
    t[e + 8] = s[8]
    t[e + 9] = s[9]
    t[e + 10] = s[10]
    t[e + 11] = s[11]
    t[e + 12] = s[12]
    t[e + 13] = s[13]
    t[e + 14] = s[14]
    t[e + 15] = s[15]
    return t
  }
  extractRotation = (() => {
    let t
    return (e) => {
      if (isUndefined(t)) t = new Vector3()
      const s = this.elements
      const n = e.elements
      const r = 1 / t.setFromMatrixColumn(e, 0).length()
      const o = 1 / t.setFromMatrixColumn(e, 1).length()
      const i = 1 / t.setFromMatrixColumn(e, 2).length()
      s[0] = n[0] * r
      s[1] = n[1] * r
      s[2] = n[2] * r
      s[4] = n[4] * o
      s[5] = n[5] * o
      s[6] = n[6] * o
      s[8] = n[8] * i
      s[9] = n[9] * i
      s[10] = n[10] * i
      return this
    }
  })()
  lookAt = (() => {
    const t = new Vector3()
    const e = new Vector3()
    const s = new Vector3()
    return (n, r, o) => {
      const i = this.elements
      s.subVectors(n, r).normalize()
      if (0 === s.lengthSq()) s.z = 1
      t.crossVectors(o, s).normalize()
      if (0 === t.lengthSq()) {
        s.x += 1e-4
        t.crossVectors(o, s).normalize()
      }
      e.crossVectors(s, t)
      i[0] = t.x
      i[4] = e.x
      i[8] = s.x
      i[1] = t.y
      i[5] = e.y
      i[9] = s.y
      i[2] = t.z
      i[6] = e.z
      i[10] = s.z
      return this
    }
  })()
  lookAtGLMatrixJS = (() => {
    let t, e, s, n, r, o, i, c, a, l
    return (h, m, u) => {
      const f = h.x
      const y = h.y
      const M = h.z
      const d = u.x
      const x = u.y
      const p = u.z
      const g = m.x
      const _ = m.y
      const k = m.z
      if (
        Math.abs(f - g) < EPSILON &&
        Math.abs(y - _) < EPSILON &&
        Math.abs(M - k) < EPSILON
      )
        return this.identity()
      i = f - g
      c = y - _
      a = M - k
      l = 1 / hypot(i, c, a)
      i *= l
      c *= l
      a *= l
      t = x * a - p * c
      e = p * i - d * a
      s = d * c - x * i
      l = hypot(t, e, s)
      if (!l) {
        t = 0
        e = 0
        s = 0
      } else {
        l = 1 / l
        t *= l
        e *= l
        s *= l
      }
      n = c * s - a * e
      r = a * t - i * s
      o = i * e - c * t
      l = hypot(n, r, o)
      if (!l) {
        n = 0
        r = 0
        o = 0
      } else {
        l = 1 / l
        n *= l
        r *= l
        o *= l
      }
      const z = this.elements
      z[0] = t
      z[1] = n
      z[2] = i
      z[3] = 0
      z[4] = e
      z[5] = r
      z[6] = c
      z[7] = 0
      z[8] = s
      z[9] = o
      z[10] = a
      z[11] = 0
      z[12] = -(t * f + e * y + s * M)
      z[13] = -(n * f + r * y + o * M)
      z[14] = -(i * f + c * y + a * M)
      z[15] = 1
      return this
    }
  })()
  applyToVector3Array = (() => {
    let t
    return (e, s, n) => {
      if (isUndefined(t)) t = new Vector3()
      if (isUndefined(s)) s = 0
      if (isUndefined(n)) n = e.length
      for (let r = 0, o = s; r < n; r += 3, o += 3) {
        t.fromArray(e, o)
        t.applyMatrix4(this)
        t.toArray(e, o)
      }
      return e
    }
  })()
  applyToBuffer = (() => {
    let t
    return (e, s, n) => {
      if (isUndefined(t)) t = new Vector3()
      if (isUndefined(s)) s = 0
      if (isUndefined(n)) n = e.length / e.itemSize
      for (let r = 0, o = s; r < n; r++, o++) {
        t.x = e.getX(o)
        t.y = e.getY(o)
        t.z = e.getZ(o)
        t.applyMatrix4(this)
        e.setXYZ(t.x, t.y, t.z)
      }
      return e
    }
  })()
  getPosition = (() => {
    let t
    return () => {
      if (isUndefined(t)) t = new Vector3()
      Log.warn(
        'Matrix4: .getPosition() has been removed. Use Vector3.setFromMatrixPosition( matrix ) instead.'
      )
      return t.setFromMatrixColumn(this, 3)
    }
  })()
  decompose = (() => {
    let t
    let e
    return (s, n, r) => {
      if (isUndefined(t)) {
        t = new Vector3()
        e = new Matrix4()
      }
      const o = this.elements
      let i = t.set(o[0], o[1], o[2]).length()
      const c = t.set(o[4], o[5], o[6]).length()
      const a = t.set(o[8], o[9], o[10]).length()
      const l = undefined
      if (this.determinant() < 0) i = -i
      s.x = o[12]
      s.y = o[13]
      s.z = o[14]
      for (let t = 0; t < 16; t++) e.elements[t] = this.elements[t]
      const h = 1 / i
      const m = 1 / c
      const u = 1 / a
      e.elements[0] *= h
      e.elements[1] *= h
      e.elements[2] *= h
      e.elements[4] *= m
      e.elements[5] *= m
      e.elements[6] *= m
      e.elements[8] *= u
      e.elements[9] *= u
      e.elements[10] *= u
      n.setFromRotationMatrix(e)
      r.x = i
      r.y = c
      r.z = a
      return this
    }
  })()
}
const hypot =
  'function' === typeof Math.hypot
    ? Math.hypot
    : function (t, e) {
        let s = 0
        let n = 0
        for (let t = 0; t < arguments.length; t += 1) {
          const e = Math.abs(Number(arguments[t]))
          if (e > s) {
            n *= (s / e) * (s / e)
            s = e
          }
          n += 0 === e && 0 === s ? 0 : (e / s) * (e / s)
        }
        return s === 1 / 0 ? 1 / 0 : s * Math.sqrt(n)
      }
