import { PhotonReferenceFactory } from '../../../reference/photon/PhotonReferenceFactory.js'
import { addHttpRequestOptions } from '../../../util/HttpRequestOptions.js'
import { request } from '../../../util/request.js'
function PhotonImageDecoder(e, t) {
  this._photon = e
  this._webWorkerPool = t
  this._PhotonReferenceFactory = new PhotonReferenceFactory(this._photon)
}
PhotonImageDecoder.prototype = Object.create(Object.prototype)
PhotonImageDecoder.prototype.constructor = PhotonImageDecoder
PhotonImageDecoder.prototype._createImageFromDecodedData = function (
  e,
  t,
  r,
  o,
  a
) {
  var n
  try {
    n = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(o))
    if (a) this._photon.ImageUtils.preMultiplyAlpha(r, n)
    return this._photon.Image.create(e, t, a, r, n)
  } finally {
    if (n) n.release()
  }
}
PhotonImageDecoder.prototype._createTileGeometryFromDecodedData = function (
  e,
  t,
  r,
  o,
  a,
  n,
  i,
  c,
  f,
  h,
  s,
  u,
  d,
  m
) {
  var p
  var y
  var l
  var _
  var g
  var F
  try {
    p = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(r))
    y = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(o))
    l = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(a))
    _ = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(n))
    g = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(i))
    F = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(c))
    return this._photon.GridTileGeometry.create(
      p,
      y,
      l,
      _,
      g,
      F,
      false,
      e,
      t,
      f,
      h,
      s,
      u,
      d,
      m
    )
  } finally {
    if (p) p.release()
    if (y) y.release()
    if (l) l.release()
    if (_) _.release()
    if (g) g.release()
    if (F) F.release()
  }
}
PhotonImageDecoder.prototype._createTileGeometryFromMeshData = function (
  e,
  t,
  r,
  o,
  a
) {
  var n = e.mesh
  var i
  var c
  try {
    var f = this._PhotonReferenceFactory.getReference(t)
    var h = this._PhotonReferenceFactory.getReference(r)
    ;(i = this._photon.BufferFactory.createFloat32BufferFromLength(
      n.vertices.length
    )).typedArray.set(n.vertices)
    ;(c = this._photon.BufferFactory.createUint16BufferFromLength(
      n.faces.length
    )).typedArray.set(n.faces)
    var s
    return this._photon.TileDiscretizer.createTileGeometryFromMesh(
      i,
      c,
      n.origin,
      n.minimumElevation,
      n.maximumElevation,
      n.averageElevation,
      f,
      h,
      o,
      a
    )
  } finally {
    if (i) i.release()
    if (c) c.release()
  }
}
PhotonImageDecoder.prototype._createImageFromEncodedData = function (e, t, r) {
  var o
  try {
    o = this._photon.BufferFactory.createUint8BufferFromData(new Uint8Array(e))
    var a = this._photon.ImageDecoder.read(o, r)
    if (a === this._photon.NULL)
      throw new Error('Could not decode image with mime ' + t)
    return a
  } finally {
    if (o) o.release()
  }
}
PhotonImageDecoder.prototype._createBufferFromEncodedData = function (e, t, r) {
  var o
  var a
  try {
    a = (o = this._createImageFromEncodedData(e, t, r)).bytes
    return {
      width: o.width,
      height: o.height,
      data: new Uint8Array(a.typedArray).buffer,
      pixelFormat: o.pixelFormat,
    }
  } finally {
    if (a) a.release()
    if (o) o.release()
  }
}
PhotonImageDecoder.prototype._requestUrlAsEncodedBuffer = function (
  e,
  t,
  r,
  o
) {
  const a = addHttpRequestOptions(
    { signal: o },
    { credentials: t, requestHeaders: r }
  )
  return request(e, a).then((e) => {
    const t = e.headers.get('Content-Type') || ''
    return e.arrayBuffer().then((e) => ({ data: e, contentType: t }))
  })
}
PhotonImageDecoder.prototype._requestUrlAsImage = function (e, t, r, o, a) {
  var n = this
  return this._requestUrlAsEncodedBuffer(e, r, o, a).then(function (e) {
    return n._createImageFromEncodedData(e.data, e.contentType, t)
  })
}
PhotonImageDecoder.prototype._requestUrlAsImageWithWebWorker = function (
  e,
  t,
  r,
  o,
  a,
  n
) {
  var i = this
  return this._requestUrlAsEncodedBuffer(e, o, a, n).then(function (e) {
    return i._decodeEncodedBufferWithWebWorker(e.data, e.contentType, t, r, n)
  })
}
PhotonImageDecoder.prototype._decodeEncodedBufferWithWebWorker = function (
  e,
  t,
  r,
  o,
  a
) {
  var n = this
  return r
    .enqueue(
      { command: 'decodeImage', data: { data: e, contentType: t } },
      [e],
      a
    )
    .then(function (e) {
      return n._createImageFromDecodedData(
        e.width,
        e.height,
        e.pixelFormat,
        e.data,
        o
      )
    })
}
PhotonImageDecoder.prototype.decodeUrl = function (e, t, r, o, a) {
  if (this._webWorkerPool)
    return this._requestUrlAsImageWithWebWorker(
      e,
      this._webWorkerPool,
      t,
      r,
      o,
      a
    )
  else return this._requestUrlAsImage(e, t, r, o, a)
}
PhotonImageDecoder.prototype.decodeArrayBuffer = function (e, t, r, o) {
  if (this._webWorkerPool)
    return this._decodeEncodedBufferWithWebWorker(
      e,
      t,
      this._webWorkerPool,
      r,
      o
    )
  return this._createImageFromEncodedData(e, t, r)
}
export { PhotonImageDecoder }
