import { Log } from '../../util/Log.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { AbortError } from '../../error/AbortError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
class WorkerThread {
  constructor(r, e) {
    this._workerFactory = r
    this._pool = e
    this._taskCount = 0
    this._isInitialized = false
    this._worker = null
  }
  startup() {
    this._worker = this._workerFactory()
    this._worker.onmessage = this.onWorkerMessage
    this._worker.onerror = this.onWorkerError
  }
  onWorkerMessage = (r) => {
    switch (r.data.type) {
      case 'init':
        this._isInitialized = true
        break
      case 'result':
        this._taskCount--
        this._pool._resolveTask(r.data.id, r.data.data)
        break
      case 'error':
        this._taskCount--
        this._pool.failTask(r.data.id, r.data.error)
        break
      case 'fatalError':
        console.log(r)
        this.onWorkerError(r.data.error)
        break
    }
    this._pool._freeWorkerThread(this)
  }
  onWorkerError = (r) => {
    Log.error('Could not start Web Worker')
    Log.error(
      'The following error occurred in the inlined-webworker on line number: ' +
        r.lineno
    )
    Log.error(r.message)
    if (this._pool) this._pool._deleteWorkerThread(this)
  }
  run(r) {
    if (!this._worker)
      throw new ProgrammingError('Cannot run task if worker is not ready')
    this._taskCount++
    this._worker.postMessage(
      { type: 'run', id: r.id, data: r.data },
      r.transferList
    )
  }
  release() {
    if (this._worker) {
      this._worker.onmessage = null
      this._worker.onerror = null
      this._worker.terminate()
      this._worker = null
      this._taskCount = 0
    }
  }
  get isInitialized() {
    return this._isInitialized
  }
  get taskCount() {
    return this._taskCount
  }
  sendPhoton(r) {
    if (this._worker)
      this._worker.postMessage({ type: 'photonWasmModule', photonModule: r })
    else
      Log.error(
        'Error while sending photon module to worker',
        new ProgrammingError('Message posted before the worker was ready')
      )
  }
}
const concurrentTasksPerWorker = 10
let taskIdCounter = 0
class WorkerTask {
  constructor(r, e, s, t) {
    this._id = taskIdCounter++
    this._pool = r
    this._data = e
    this._transferList = s
    this._deferred = null
    this._abortSignal = t || null
    this._promise = new Promise((r, e) => {
      this._deferred = { resolve: r, reject: e }
      if (this._abortSignal) {
        const r = () => {
          e(new AbortError('Worker task aborted'))
          this._deferred = null
          this._promise = null
          if (this._pool) {
            this._pool._cancelWorkerTask(this)
            this._pool = null
          }
        }
        if (this._abortSignal.aborted) {
          r()
          return
        }
        this._abortSignal.addEventListener('abort', r)
      }
    })
  }
  cancel() {
    if (this._deferred)
      this._deferred.reject(new AbortError('WorkerTask cancelled'))
  }
  reject(r) {
    if (this._deferred) this._deferred.reject(r)
  }
  resolve(r) {
    if (this._deferred) this._deferred.resolve(r)
  }
  get id() {
    return this._id
  }
  set id(r) {
    this._id = r
  }
  get promise() {
    return this._promise
  }
  get transferList() {
    return this._transferList
  }
  get data() {
    return this._data
  }
}
export class WebWorkerPool {
  constructor(r, e) {
    this._workerFactory = e.bind(this)
    this._runningTasks = new Map()
    this._pendingTasks = []
    this._allWorkers = []
    for (let e = 0; e < r; e++) this._createWorkerThread()
    Photon.onReady(() => {
      this.bootPhoton(Photon.lcdWasmModule)
    })
  }
  _resolveTask(r, e) {
    const s = this._runningTasks.get(r)
    this._runningTasks.delete(r)
    if (!s)
      Log.error(
        'No task ' + r + ' was running, but received result for it: ',
        e
      )
    else s.resolve(e)
  }
  failTask(r, e) {
    const s = this._runningTasks.get(r)
    this._runningTasks.delete(r)
    const t = e && e instanceof ErrorEvent ? e.error : e
    if (!s)
      Log.error('No task ' + r + ' was running, but received error for it: ', t)
    else s.reject(t)
  }
  _cancelWorkerTask(r) {
    const e = this._pendingTasks.indexOf(r)
    if (e > -1) this._pendingTasks.splice(e, 1)
  }
  _freeWorkerThread(r) {
    if (this._pendingTasks.length > 0) {
      const e = this._pendingTasks.shift()
      this._runningTasks.set(e.id, e)
      r.run(e)
    }
  }
  _createWorkerThread() {
    const r = new WorkerThread(this._workerFactory, this)
    this._allWorkers.push(r)
    r.startup()
  }
  _deleteWorkerThread(r) {
    const e = this._allWorkers.indexOf(r)
    if (e > -1) this._allWorkers.splice(e, 1)
    r.release()
    if (0 === this._allWorkers.length) {
      const r = (r) => {
        r.reject(new Error('All Web Workers failed to start'))
      }
      this._runningTasks.forEach(r)
      this._pendingTasks.forEach(r)
      this._runningTasks.clear()
      this._pendingTasks = []
    }
  }
  enqueue(r, e, s) {
    if (0 === this._allWorkers.length)
      return Promise.reject(new Error('All Web Workers failed to start'))
    const t = new WorkerTask(this, r, e, s)
    let o = null
    for (let r = 0; r < this._allWorkers.length; r++) {
      const e = this._allWorkers[r]
      if (e.isInitialized && (!o || e.taskCount < o.taskCount)) o = e
    }
    if (o && o.taskCount < concurrentTasksPerWorker) {
      this._runningTasks.set(t.id, t)
      o.run(t)
    } else this._pendingTasks.push(t)
    return t.promise
  }
  enqueueOnAllWorkers(r, e, s) {
    return Promise.all(
      this._allWorkers.map((t) => {
        if (t.isInitialized) {
          let o = new WorkerTask(this, r, e, s)
          this._runningTasks.set(o.id, o)
          t.run(o)
          return o.promise
        } else return false
      })
    )
  }
  getStatus() {
    return {
      workers: {
        total: this._allWorkers.length,
        initializing:
          this._allWorkers.length -
          this._allWorkers.filter((r) => r.isInitialized).length,
        busy: this._allWorkers.filter((r) => r.taskCount > 0).length,
      },
      tasks: {
        total: this._runningTasks.size + this._pendingTasks.length,
        running: this._runningTasks.size,
        pending: this._pendingTasks.length,
      },
    }
  }
  bootPhoton(r) {
    this._allWorkers.forEach((e) => {
      e.sendPhoton(r)
    })
  }
  release() {
    const r = (r) => {
      r.cancel()
    }
    this._pendingTasks.forEach(r)
    this._runningTasks.forEach(r)
    this._pendingTasks = []
    this._runningTasks = new Map()
    this._allWorkers.forEach(function (r) {
      r.release()
    })
    this._allWorkers = []
  }
}
