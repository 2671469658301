import { shouldSwapAxes } from '../../reference/AxisInformationUtil.js'
import { createBounds } from '../../shape/ShapeFactory.js'
import { XYZBounds } from '../../shape/XYZPointBounds.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Lang } from '../../util/Lang.js'
function isGridReference(e) {
  return 'undefined' !== typeof e.unitOfMeasure
}
function isWMTSOptions(e) {
  return void 0 !== e.structure
}
const PIXEL_RENDERING_SIZE = 28e-5
function getWmtsQuadTreeCompatibleLevelOffset(e) {
  const t = 1e-8
  const r = e.tileMatrices
  const i = r[r.length - 1]
  for (let e = r.length - 2; e >= 0; e--) {
    const n = r[e]
    const o = 1 << (r.length - e - 1)
    const s = n.scaleDenominator * t
    if (Math.abs(n.scaleDenominator - i.scaleDenominator * o) > s) return e + 1
    if (n.topLeftCorner !== i.topLeftCorner) return e + 1
    if (n.tileWidth !== i.tileWidth || n.tileHeight !== i.tileHeight)
      return e + 1
    if (
      n.matrixWidth !== i.matrixWidth / o ||
      n.matrixHeight !== i.matrixHeight / o
    )
      return e + 1
  }
  return 0
}
function getWmtsTopLeftCoordinateAlignedCompatibleLevelOffset(e) {
  const t = e.tileMatrices
  const r = t[t.length - 1]
  for (let e = t.length - 2; e >= 0; e--) {
    const i = undefined
    if (t[e].topLeftCorner !== r.topLeftCorner) return e + 1
  }
  return 0
}
export function getWmtsCompatibleLevelOffset(e, t, r) {
  if (t) return getWmtsQuadTreeCompatibleLevelOffset(e)
  if (r) return getWmtsTopLeftCoordinateAlignedCompatibleLevelOffset(e)
  return 0
}
function deriveQuadTreeStructure(e, t) {
  const r = e.tileMatrices
  const i = r.map((e) => e.identifier).slice(t)
  const n = r[t]
  let o = e.getBounds()
  if (!o) {
    const t = n.topLeftCorner.split(/\s+/).map(function (e) {
      return parseFloat(e)
    })
    const r = e.getReference()
    let i
    if (isGridReference(r)) i = r.unitOfMeasure
    else i = (r.geodeticDatum.ellipsoid.a * Math.PI) / 180
    const s =
      ((n.scaleDenominator * PIXEL_RENDERING_SIZE) / i) *
      n.tileHeight *
      n.matrixHeight
    const l =
      ((n.scaleDenominator * PIXEL_RENDERING_SIZE) / i) *
      n.tileWidth *
      n.matrixWidth
    let a
    let u
    if (shouldSwapAxes(r, [])) {
      a = t[1]
      u = t[0] - s
    } else {
      a = t[0]
      u = t[1] - s
    }
    o = createBounds(r, [a, l, u, s])
  }
  return {
    level0Columns: n.matrixWidth,
    level0Rows: n.matrixHeight,
    reference: e.getReference(),
    tileWidth: n.tileWidth,
    tileHeight: n.tileHeight,
    levelCount: i.length,
    bounds: o,
  }
}
function deriveTileMatrixStructure(e, t) {
  const r = undefined
  if (getWmtsQuadTreeCompatibleLevelOffset(e) === t)
    return deriveQuadTreeStructure(e, t)
  const i = e.tileMatrices
  const n = i.map((e) => e.identifier).slice(t)
  const o = new Array(n.length)
  let s = e.getBounds()
  const l = !s
  const a = e.getReference()
  let u
  if (isGridReference(a)) u = a.unitOfMeasure
  else u = (a.geodeticDatum.ellipsoid.a * Math.PI) / 180
  for (let e = 0; e < n.length; e++) {
    const r = i[t + e]
    const n = r.topLeftCorner.split(/\s+/).map(function (e) {
      return parseFloat(e)
    })
    const c =
      ((r.scaleDenominator * PIXEL_RENDERING_SIZE) / u) *
      r.tileHeight *
      r.matrixHeight
    const f =
      ((r.scaleDenominator * PIXEL_RENDERING_SIZE) / u) *
      r.tileWidth *
      r.matrixWidth
    let d
    let m
    if (shouldSwapAxes(a, [])) {
      d = n[1]
      m = n[0] - c
    } else {
      d = n[0]
      m = n[1] - c
    }
    const g = new XYZBounds(a, [d, f, m, c])
    o[e] = {
      bounds: g,
      tileColumnCount: r.matrixWidth,
      tileRowCount: r.matrixHeight,
      tileWidth: r.tileWidth,
      tileHeight: r.tileHeight,
    }
    if (l)
      if (!s) s = g.copy()
      else s.setTo2DUnion(g)
  }
  if (!s)
    throw new Error(`Cannot derive the model bounds of the TileMatrixSet.`)
  return { reference: a, bounds: s, tileMatrix: o }
}
export function deriveWmtsStructure(e, t, r) {
  return r ? deriveQuadTreeStructure(e, t) : deriveTileMatrixStructure(e, t)
}
function convertDeprecatedOptions(e, t, r) {
  const i =
    'WMTSTileSetModel::cannot construct WMTS model without valid mandatory parameters'
  if (void 0 === e.bounds) throw new ProgrammingError(i)
  const n = e.reference ? e.reference : e.bounds.reference
  if (null == n) throw new ProgrammingError(i)
  const o = {
    bounds: e.bounds,
    reference: n,
    levelCount: e.levelCount,
    level0Columns: e.level0Columns,
    level0Rows: e.level0Rows,
    tileWidth: e.tileWidth,
    tileHeight: e.tileHeight,
  }
  e.baseURL = createWmtsGetTileUrl(e.url, e.layer, t, r, e.tileMatrixSet)
  const s = {
    baseURL: e.baseURL,
    url: e.url,
    layer: e.layer,
    style: e.style,
    format: e.format,
    tileMatrixSet: e.tileMatrixSet,
    tileMatrices: e.tileMatrices,
    dimensions: e.dimensions,
    structure: o,
  }
  return { ...e, ...e, ...s }
}
export function createWmtsGetTileUrl(e, t, r, i, n) {
  const o = e.includes('?') ? '&' : '?'
  return `${e}${o}SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&LAYER=${t}&STYLE=${r}&FORMAT=${i}&TILEMATRIXSET=${n}`
}
export function validateWMTSOptions(e) {
  const t =
    'WMTSTileSetModel::cannot construct WMTS model without valid mandatory parameters'
  if (!e) throw new ProgrammingError(t)
  const r = e.format || 'image/jpeg'
  const i = Lang.isDefined(e.style) ? e.style : 'default'
  e.format = r
  e.style = i
  if (!isWMTSOptions(e)) e = convertDeprecatedOptions(e, e.style, e.format)
  if (!Lang.isDefined(e.baseURL))
    e.baseURL = createWmtsGetTileUrl(e.url, e.layer, i, r, e.tileMatrixSet)
  if (!Lang.isDefined(e.structure)) throw new ProgrammingError(t)
  if (!Array.isArray(e.tileMatrices)) throw new ProgrammingError(t)
  return e
}
