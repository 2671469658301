import { WrapperPattern } from './WrapperPattern.js'
import { PatternType } from './util/PatternType.js'
export class AllowOverlapPattern extends WrapperPattern {
  constructor(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    super(t, PatternType.ALLOW_OVERLAP)
    this._overlapLeft = e.overlapLeft ?? 0
    this._overlapRight = e.overlapRight ?? 0
  }
  getWidth(t) {
    return super.getWidth(t) - this._overlapLeft - this._overlapRight
  }
  get overlapLeft() {
    return this._overlapLeft
  }
  get overlapRight() {
    return this._overlapRight
  }
  paint(t, e, r, p) {
    t.translate(-this._overlapLeft, 0)
    super.paint(t, e, r, p)
    t.translate(+this._overlapLeft, 0)
  }
  appendHash(t) {
    super.appendHash(t)
    t.appendDouble(this._overlapLeft)
    t.appendDouble(this._overlapRight)
  }
}
