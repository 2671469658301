import { StackTrace } from './StackTrace.js'
const log = (e, r, a) => {
  console[e](r + (a ? ' - ' + StackTrace.getStackTrace(a) : ''))
}
const createDeprecatedMessage = (e, r) => {
  if (r)
    return `${e} is deprecated and may be removed in a future release. Please use ${r} instead.`
  return `${e} is deprecated and may be removed in a future release.`
}
export class Log {
  constructor(e) {
    this._avoidDuplicateMessages = !!e?.avoidDuplicateMessages
    this._messages = { log: [], debug: [], warn: [], error: [], deprecated: [] }
  }
  _log(e, r, a) {
    if (this._avoidDuplicateMessages)
      if (-1 === this._messages[e].indexOf(r)) this._messages[e].push(r)
      else return
    Log[e].call(void 0, r, a)
  }
  static log(e, r) {
    log('log', e, r)
  }
  log(e, r) {
    return this._log('log', e, r)
  }
  static debug(e, r) {
    log('debug', e, r)
  }
  debug(e, r) {
    return this._log('debug', e, r)
  }
  static warn(e, r) {
    log('warn', e, r)
  }
  warn(e, r) {
    return this._log('warn', e, r)
  }
  static error(e, r) {
    log('error', e, r)
  }
  error(e, r) {
    return this._log('error', e, r)
  }
  static deprecated(e, r) {
    log('warn', createDeprecatedMessage(e, r))
  }
  deprecated(e, r) {
    return this._log('warn', createDeprecatedMessage(e, r))
  }
}
