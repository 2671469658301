import { DrawOnPathLabelHtmlContents } from './DrawOnPathLabelHtmlContents.js'
import { drawNullCommand } from './NullDrawCommand.js'
export class DrawOnPathLabelHtmlRepeatingContents extends DrawOnPathLabelHtmlContents {
  constructor(t, r, n, e, o, a, s, i, h) {
    super(t, r, n, e, o, a, s)
    this.setAnchor(i)
    this.reuseMetrics(h)
  }
  setAnchor(t) {
    updateAnchor(this.previousAnchorPoint, t, 0)
    updateAnchor(this.previousAnchorOrientationPoint, t, 3)
  }
  placeHtml(t, r, n, e) {
    if (!this.drawCommand || this.drawCommand === drawNullCommand) return false
    this.zOrder = e
    if (this.tryPreviousLabelAnchor(r, n))
      if (this.isValidLocation(this.previousAnchorPoint)) {
        this.toScreen(t)
        return true
      }
    return false
  }
}
function updateAnchor(t, r, n) {
  t.x = r[n]
  t.y = r[n + 1]
  t.z = r[n + 2]
}
