import { Vector4 } from '../../geometry/mesh/math/Vector4.js'
import { Matrix4 } from '../../geometry/mesh/math/Matrix4.js'
import { Vector3 as Vector3Int } from '../../geometry/mesh/math/Vector3.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { isNumber } from '../../util/Lang.js'
function isVector3(t) {
  return isNumber(t.x) && isNumber(t.y) && isNumber(t.z)
}
export class Camera {
  constructor(t, e, r, i, o, s, n, a) {
    if (!isVector3(t))
      throw new ProgrammingError(
        'Camera eye is not a Vector3 (x, y and z are not all numbers)'
      )
    if (!isVector3(e))
      throw new ProgrammingError(
        'Camera forward is not a Vector3 (x, y and z are not all numbers)'
      )
    if (!isVector3(r))
      throw new ProgrammingError(
        'Camera up is not Vector3 (x, y and z are not all numbers)'
      )
    this._eye = new Vector3Int(t.x, t.y, t.z)
    this._forward = new Vector3Int(e.x, e.y, e.z)
    this._up = new Vector3Int(r.x, r.y, r.z)
    if (!isNumber(i)) throw new ProgrammingError('Camera near is not a number')
    this._near = i
    if (!isNumber(o)) throw new ProgrammingError('Camera far is not a number')
    this._far = o
    if (!isNumber(s)) throw new ProgrammingError('Camera width is not a number')
    this._width = s
    if (!isNumber(n))
      throw new ProgrammingError('Camera height is not a number')
    this._height = n
    this.__matricesDirty = true
    this._lookAt = new Vector3Int()
    this._viewMatrix = new Matrix4()
    this._projectionMatrix = new Matrix4()
    this._viewProjectionMatrix = new Matrix4()
    this._viewProjectionMatrixInverse = new Matrix4()
    this._tempXYZ = new Vector4()
    this._worldReference = a
    this._tempWorldPoint = createPoint(a, [0, 0, 0])
  }
  get eye() {
    return this._eye.clone()
  }
  get eyePoint() {
    return createPoint(this._worldReference, [
      this._eye.x,
      this._eye.y,
      this._eye.z,
    ])
  }
  get forward() {
    return this._forward.clone()
  }
  get up() {
    return this._up.clone()
  }
  get near() {
    return this._near
  }
  get far() {
    return this._far
  }
  get aspectRatio() {
    if (0 === this._width || 0 === this._height) return 1
    return this._width / this._height
  }
  get width() {
    return this._width
  }
  get height() {
    return this._height
  }
  get worldReference() {
    return this._worldReference
  }
  get viewProjectionMatrix() {
    this._updateMatrices()
    return this._viewProjectionMatrix
  }
  toView(t, e) {
    this._updateMatrices()
    this._tempXYZ.set(t.x || 0, t.y || 0, t.z || 0, 1)
    this._tempXYZ.applyMatrix4(this._viewProjectionMatrix)
    if (this._tempXYZ.w <= 0) return { x: NaN, y: NaN, z: NaN }
    this._tempXYZ.multiplyScalar(1 / this._tempXYZ.w)
    this._toPixelsSFCT(this._tempXYZ)
    if (!e)
      return { x: this._tempXYZ.x, y: this._tempXYZ.y, z: this._tempXYZ.z }
    e.x = this._tempXYZ.x
    e.y = this._tempXYZ.y
    e.z = this._tempXYZ.z
    return e
  }
  toViewPoint(t, e) {
    if (null != t.reference && !this._worldReference.equals(t.reference)) {
      const e = undefined
      createTransformation(t.reference, this._worldReference).transform(
        t,
        this._tempWorldPoint
      )
    } else this._tempWorldPoint.move3DToPoint(t)
    if (!e) e = createPoint(null, [])
    this.toView(this._tempWorldPoint, e)
    return e
  }
  toWorld(t, e) {
    this._updateMatrices()
    this._tempXYZ.set(t.x || 0, t.y || 0, t.z || 0, 1)
    this._toNDCSFCT(this._tempXYZ)
    this._tempXYZ.applyMatrix4(this._viewProjectionMatrixInverse)
    this._tempXYZ.multiplyScalar(1 / this._tempXYZ.w)
    if (!e)
      return { x: this._tempXYZ.x, y: this._tempXYZ.y, z: this._tempXYZ.z }
    e.x = this._tempXYZ.x
    e.y = this._tempXYZ.y
    e.z = this._tempXYZ.z
    return e
  }
  toWorldPoint(t, e) {
    if (!e) e = createPoint(this._worldReference, [0, 0, 0])
    this.toWorld(t, this._tempWorldPoint)
    if (null != e.reference && !this._worldReference.equals(e.reference)) {
      const t = undefined
      createTransformation(this._worldReference, e.reference).transform(
        this._tempWorldPoint,
        e
      )
    } else e.move3DToPoint(this._tempWorldPoint)
    return e
  }
  computeViewMatrix(t) {
    this._lookAt.addVectors(this._eye, this._forward)
    t.lookAtGLMatrixJS(this._eye, this._lookAt, this._up)
  }
  _updateMatrices() {
    if (!this.__matricesDirty) return
    this.__matricesDirty = false
    this.computeViewMatrix(this._viewMatrix)
    this.computeProjectionMatrix(this._projectionMatrix)
    this._viewProjectionMatrix.multiplyMatrices(
      this._projectionMatrix,
      this._viewMatrix
    )
    this._viewProjectionMatrixInverse.getInverse(this._viewProjectionMatrix)
  }
  _toPixelsSFCT(t) {
    const e = (t.x + 1) * (this._width / 2)
    const r = (-t.y + 1) * (this._height / 2)
    const i = 0.5 * (t.z + 1)
    t.set(e, r, i, t.w)
  }
  _toNDCSFCT(t) {
    const e = t.x / (this._width / 2) - 1
    const r = -(t.y / (this._height / 2) - 1)
    const i = 2 * t.z - 1
    t.set(e, r, i, t.w)
  }
}
