import { Log } from '../../../util/Log.js'
import { isUndefined } from '../../../util/Lang.js'
export class Vector4 {
  constructor(t, s, i, h) {
    this._x = t || 0
    this._y = s || 0
    this._z = i || 0
    this._w = isUndefined(h) ? 1 : h
  }
  get w() {
    return this._w
  }
  get z() {
    return this._z
  }
  get y() {
    return this._y
  }
  get x() {
    return this._x
  }
  set w(t) {
    this._w = t
  }
  set z(t) {
    this._z = t
  }
  set y(t) {
    this._y = t
  }
  set x(t) {
    this._x = t
  }
  set(t, s, i, h) {
    this._x = t
    this._y = s
    this._z = i
    this._w = h
    return this
  }
  setScalar(t) {
    this._x = t
    this._y = t
    this._z = t
    this._w = t
    return this
  }
  setX(t) {
    this._x = t
    return this
  }
  setY(t) {
    this._y = t
    return this
  }
  setZ(t) {
    this._z = t
    return this
  }
  setW(t) {
    this._w = t
    return this
  }
  setComponent(t, s) {
    switch (t) {
      case 0:
        this._x = s
        break
      case 1:
        this._y = s
        break
      case 2:
        this._z = s
        break
      case 3:
        this._w = s
        break
      default:
        throw new Error(`index is out of range: ${t}`)
    }
  }
  getComponent(t) {
    switch (t) {
      case 0:
        return this._x
      case 1:
        return this._y
      case 2:
        return this._z
      case 3:
        return this._w
      default:
        throw new Error(`index is out of range: ${t}`)
    }
  }
  clone() {
    return new Vector4(this._x, this._y, this._z, this._w)
  }
  copy(t) {
    this._x = t._x
    this._y = t._y
    this._z = t._z
    this._w = void 0 !== t._w ? t._w : 1
    return this
  }
  add(t, s) {
    if (!isUndefined(s)) {
      Log.warn(
        'Vector4: .add() now only accepts one argument. Use .addVectors( a, b ) instead.'
      )
      return this.addVectors(t, s)
    }
    this._x += t._x
    this._y += t._y
    this._z += t._z
    this._w += t._w
    return this
  }
  addScalar(t) {
    this._x += t
    this._y += t
    this._z += t
    this._w += t
    return this
  }
  addVectors(t, s) {
    this._x = t._x + s._x
    this._y = t._y + s._y
    this._z = t._z + s._z
    this._w = t._w + s._w
    return this
  }
  addScaledVector(t, s) {
    this._x += t._x * s
    this._y += t._y * s
    this._z += t._z * s
    this._w += t._w * s
    return this
  }
  sub(t, s) {
    if (!isUndefined(s)) {
      Log.warn(
        'Vector4: .sub() now only accepts one argument. Use .subVectors( a, b ) instead.'
      )
      return this.subVectors(t, s)
    }
    this._x -= t._x
    this._y -= t._y
    this._z -= t._z
    this._w -= t._w
    return this
  }
  subScalar(t) {
    this._x -= t
    this._y -= t
    this._z -= t
    this._w -= t
    return this
  }
  subVectors(t, s) {
    this._x = t._x - s._x
    this._y = t._y - s._y
    this._z = t._z - s._z
    this._w = t._w - s._w
    return this
  }
  multiplyScalar(t) {
    if (isFinite(t)) {
      this._x *= t
      this._y *= t
      this._z *= t
      this._w *= t
    } else {
      this._x = 0
      this._y = 0
      this._z = 0
      this._w = 0
    }
    return this
  }
  applyMatrix4(t) {
    const s = this._x
    const i = this._y
    const h = this._z
    const _ = this._w
    const e = t.elements
    this._x = e[0] * s + e[4] * i + e[8] * h + e[12] * _
    this._y = e[1] * s + e[5] * i + e[9] * h + e[13] * _
    this._z = e[2] * s + e[6] * i + e[10] * h + e[14] * _
    this._w = e[3] * s + e[7] * i + e[11] * h + e[15] * _
    return this
  }
  divideScalar(t) {
    return this.multiplyScalar(1 / t)
  }
  setAxisAngleFromQuaternion(t) {
    this._w = 2 * Math.acos(t.w)
    const s = Math.sqrt(1 - t.w * t.w)
    if (s < 1e-4) {
      this._x = 1
      this._y = 0
      this._z = 0
    } else {
      this._x = t.x / s
      this._y = t.y / s
      this._z = t.z / s
    }
    return this
  }
  setAxisAngleFromRotationMatrix(t) {
    let s
    let i
    let h
    let _
    const e = 0.01
    const r = 0.1
    const a = t.elements
    const n = a[0]
    const o = a[4]
    const x = a[8]
    const w = a[1]
    const y = a[5]
    const c = a[9]
    const u = a[2]
    const l = a[6]
    const z = a[10]
    if (Math.abs(o - w) < e && Math.abs(x - u) < e && Math.abs(c - l) < e) {
      if (
        Math.abs(o + w) < r &&
        Math.abs(x + u) < r &&
        Math.abs(c + l) < r &&
        Math.abs(n + y + z - 3) < r
      ) {
        this.set(1, 0, 0, 0)
        return this
      }
      s = Math.PI
      const t = (n + 1) / 2
      const a = (y + 1) / 2
      const M = (z + 1) / 2
      const d = (o + w) / 4
      const f = (x + u) / 4
      const m = (c + l) / 4
      if (t > a && t > M)
        if (t < e) {
          i = 0
          h = 0.707106781
          _ = 0.707106781
        } else {
          i = Math.sqrt(t)
          h = d / i
          _ = f / i
        }
      else if (a > M)
        if (a < e) {
          i = 0.707106781
          h = 0
          _ = 0.707106781
        } else {
          h = Math.sqrt(a)
          i = d / h
          _ = m / h
        }
      else if (M < e) {
        i = 0.707106781
        h = 0.707106781
        _ = 0
      } else {
        _ = Math.sqrt(M)
        i = f / _
        h = m / _
      }
      this.set(i, h, _, s)
      return this
    }
    let M = Math.sqrt((l - c) * (l - c) + (x - u) * (x - u) + (w - o) * (w - o))
    if (Math.abs(M) < 0.001) M = 1
    this._x = (l - c) / M
    this._y = (x - u) / M
    this._z = (w - o) / M
    this._w = Math.acos((n + y + z - 1) / 2)
    return this
  }
  min(t) {
    this._x = Math.min(this._x, t._x)
    this._y = Math.min(this._y, t._y)
    this._z = Math.min(this._z, t._z)
    this._w = Math.min(this._w, t._w)
    return this
  }
  max(t) {
    this._x = Math.max(this._x, t._x)
    this._y = Math.max(this._y, t._y)
    this._z = Math.max(this._z, t._z)
    this._w = Math.max(this._w, t._w)
    return this
  }
  clamp(t, s) {
    this._x = Math.max(t._x, Math.min(s._x, this._x))
    this._y = Math.max(t._y, Math.min(s._y, this._y))
    this._z = Math.max(t._z, Math.min(s._z, this._z))
    this._w = Math.max(t._w, Math.min(s._w, this._w))
    return this
  }
  floor() {
    this._x = Math.floor(this._x)
    this._y = Math.floor(this._y)
    this._z = Math.floor(this._z)
    this._w = Math.floor(this._w)
    return this
  }
  ceil() {
    this._x = Math.ceil(this._x)
    this._y = Math.ceil(this._y)
    this._z = Math.ceil(this._z)
    this._w = Math.ceil(this._w)
    return this
  }
  round() {
    this._x = Math.round(this._x)
    this._y = Math.round(this._y)
    this._z = Math.round(this._z)
    this._w = Math.round(this._w)
    return this
  }
  roundToZero() {
    this._x = this._x < 0 ? Math.ceil(this._x) : Math.floor(this._x)
    this._y = this._y < 0 ? Math.ceil(this._y) : Math.floor(this._y)
    this._z = this._z < 0 ? Math.ceil(this._z) : Math.floor(this._z)
    this._w = this._w < 0 ? Math.ceil(this._w) : Math.floor(this._w)
    return this
  }
  negate() {
    this._x = -this._x
    this._y = -this._y
    this._z = -this._z
    this._w = -this._w
    return this
  }
  dot(t) {
    return this._x * t._x + this._y * t._y + this._z * t._z + this._w * t._w
  }
  lengthSq() {
    return (
      this._x * this._x +
      this._y * this._y +
      this._z * this._z +
      this._w * this._w
    )
  }
  length() {
    return Math.sqrt(
      this._x * this._x +
        this._y * this._y +
        this._z * this._z +
        this._w * this._w
    )
  }
  lengthManhattan() {
    return (
      Math.abs(this._x) +
      Math.abs(this._y) +
      Math.abs(this._z) +
      Math.abs(this._w)
    )
  }
  normalize() {
    return this.divideScalar(this.length())
  }
  setLength(t) {
    return this.multiplyScalar(t / this.length())
  }
  lerp(t, s) {
    this._x += (t._x - this._x) * s
    this._y += (t._y - this._y) * s
    this._z += (t._z - this._z) * s
    this._w += (t._w - this._w) * s
    return this
  }
  lerpVectors(t, s, i) {
    this.subVectors(s, t).multiplyScalar(i).add(t)
    return this
  }
  equals(t) {
    return (
      t._x === this._x &&
      t._y === this._y &&
      t._z === this._z &&
      t._w === this._w
    )
  }
  fromArray(t, s) {
    if (isUndefined(s)) s = 0
    this._x = t[s]
    this._y = t[s + 1]
    this._z = t[s + 2]
    this._w = t[s + 3]
    return this
  }
  toArray(t, s) {
    if (isUndefined(t)) t = []
    if (isUndefined(s)) s = 0
    t[s] = this._x
    t[s + 1] = this._y
    t[s + 2] = this._z
    t[s + 3] = this._w
    return t
  }
  fromAttribute(t, s, i) {
    if (isUndefined(i)) i = 0
    s = s * t.itemSize + i
    this._x = t.array[s]
    this._y = t.array[s + 1]
    this._z = t.array[s + 2]
    this._w = t.array[s + 3]
    return this
  }
  clampScalar = (() => {
    let t
    let s
    return (i, h) => {
      if (isUndefined(t)) {
        t = new Vector4()
        s = new Vector4()
      }
      t.set(i, i, i, i)
      s.set(h, h, h, h)
      return this.clamp(t, s)
    }
  })()
}
