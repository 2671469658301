import { isDefined, isNumber } from '../util/Lang.js'
import { CubeMapFace } from '../model/tileset/CubeMapFace.js'
import { WithHttpRequestOptions } from '../util/WithHttpRequestOptions.js'
export let EnvironmentMapImageryType = (function (e) {
  e[(e['CUBE_MAP'] = 0)] = 'CUBE_MAP'
  e[(e['EQUIRECTANGULAR'] = 1)] = 'EQUIRECTANGULAR'
  return e
})({})
export class EnvironmentMapImagery {
  constructor(e) {
    this._type = e.type
    this._httpRequestOptions = new WithHttpRequestOptions(e)
  }
  get type() {
    return this._type
  }
  equals(e) {
    return false
  }
  get credentials() {
    return this._httpRequestOptions.credentials
  }
  set credentials(e) {
    this._httpRequestOptions.credentials = e
  }
  get requestHeaders() {
    return this._httpRequestOptions.requestHeaders
  }
  set requestHeaders(e) {
    this._httpRequestOptions.requestHeaders = e
  }
}
export class CubeMapImagery extends EnvironmentMapImagery {
  constructor(e) {
    super({ ...e, type: EnvironmentMapImageryType.CUBE_MAP })
  }
}
class URLCubeMapImagery extends CubeMapImagery {
  constructor(e, t, r, a, i, s, n) {
    super(n)
    this._faceURLMap = new Map([
      [CubeMapFace.FRONT, e],
      [CubeMapFace.BACK, t],
      [CubeMapFace.LEFT, r],
      [CubeMapFace.RIGHT, a],
      [CubeMapFace.BOTTOM, i],
      [CubeMapFace.TOP, s],
    ])
  }
  getImage(e, t, r) {
    t(e, this._faceURLMap.get(e))
  }
  equals(e) {
    if (!(e instanceof URLCubeMapImagery)) return false
    if (e._faceURLMap.size !== this._faceURLMap.size) return false
    for (const [t, r] of e._faceURLMap)
      if (r !== this._faceURLMap.get(t)) return false
    return true
  }
}
export function createCubeMapImagery(e, t, r, a, i, s, n) {
  return new URLCubeMapImagery(e, t, r, a, i, s, n)
}
export class EquirectangularImagery extends EnvironmentMapImagery {
  constructor(e) {
    super({ ...e, type: EnvironmentMapImageryType.EQUIRECTANGULAR })
  }
}
class URLEquirectangularImagery extends EquirectangularImagery {
  constructor(e, t) {
    super(t)
    this._imageURL = e
  }
  getImage(e, t) {
    e(this._imageURL)
  }
  equals(e) {
    return (
      e instanceof URLEquirectangularImagery && e._imageURL === this._imageURL
    )
  }
}
export function createEquirectangularImagery(e, t) {
  return new URLEquirectangularImagery(e, t)
}
export class EnvironmentMapImpl {
  constructor(e) {
    this.setToLiteral(e)
  }
  setToLiteral(e) {
    if (!isDefined(e)) return
    this.imagery = e.imagery ?? null
    this.orientation = e.orientation ?? 0
  }
  get imagery() {
    return this._imagery
  }
  set imagery(e) {
    if (e) this._imagery = e
    else throw new Error(`Invalid imagery: ${e}`)
  }
  get orientation() {
    return this._orientation
  }
  set orientation(e) {
    if (isNumber(e, false)) this._orientation = e
    else throw new Error(`Invalid orientation: ${e}`)
  }
  equals(e) {
    return (
      e &&
      !!e.imagery &&
      this.imagery.equals(e.imagery) &&
      e.orientation === this.orientation
    )
  }
}
export class EnvironmentMapEffectImpl {
  constructor(e) {
    this.setToLiteral(e)
  }
  setToLiteral(e) {
    if (!isDefined(e)) return
    this.skybox = e.skybox ?? null
    this.reflectionMap = e.reflectionMap ?? null
  }
  get skybox() {
    return this._skybox
  }
  set skybox(e) {
    if (e) this._skybox = new EnvironmentMapImpl(e)
    else this._skybox = null
  }
  get reflectionMap() {
    return this._reflectionMap
  }
  set reflectionMap(e) {
    if (e) this._reflectionMap = new EnvironmentMapImpl(e)
    else this._reflectionMap = null
  }
}
