export class PickInfoImpl {
  constructor(e, t, r, s, c, n, o, i) {
    this.layer = e
    this.object = t
    this.squaredDistanceToEdge = r
    this.squaredDistanceToCenter = s
    this.depth = c
    this.zOrder = n
    this.selected = o
    this.draped = i
  }
  static comparePickInfo2D(e, t) {
    let r = Math.round(
      Math.abs(e.squaredDistanceToEdge) - Math.abs(t.squaredDistanceToEdge)
    )
    if (0 === r || isNaN(r))
      r = e.squaredDistanceToEdge - t.squaredDistanceToEdge
    if (0 === r || isNaN(r))
      r = e.squaredDistanceToCenter - t.squaredDistanceToCenter
    if (0 === r || isNaN(r))
      if (e.selected && !t.selected) r = -1
      else if (!e.selected && t.selected) r = 1
      else r = e.zOrder > t.zOrder ? -1 : e.zOrder === t.zOrder ? 0 : 1
    return r
  }
  static comparePickInfo3D(e, t) {
    const r = e.depth
    const s = t.depth
    if (e.draped && t.draped) return PickInfoImpl.comparePickInfo2D(e, t)
    const c = Math.abs(r) - Math.abs(s)
    if (0 === c) return PickInfoImpl.comparePickInfo2D(e, t)
    return c
  }
  static singleOut(e) {
    if (_isPickInfoArrayValid(e)) {
      const t = e[0]
      return { layer: t.layer, objects: t.objects.slice(0, 1) }
    }
    return null
  }
  static uniqueFeatures(e) {
    if (e.length <= 1) return e
    const t = new Set()
    const r = []
    e.forEach((e) => {
      if (!t.has(e)) {
        t.add(e)
        r.push(e)
      }
    })
    return r
  }
  static mergePickInfoArrays() {
    for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++)
      t[r] = arguments[r]
    const s = new Map()
    for (let e = 0; e < arguments.length; e++) groupByLayer(s, arguments[e])
    const c = []
    s.forEach((e) => {
      e.objects = PickInfoImpl.uniqueFeatures(e.objects)
      c.push(e)
    })
    return c
  }
  static pickBestAsPublicPickInfos(e) {
    const t = e.length
    if (0 === t) return []
    if (t > 1) e.sort(PickInfoImpl.comparePickInfo2D)
    const r = e[0]
    return [{ layer: r.layer, objects: [r.object] }]
  }
  static convertToPublicPickInfos(e, t) {
    e.sort(PickInfoImpl.comparePickInfo2D)
    const r = new Map()
    let s, c
    e.forEach((e) => {
      s = e.layer.id
      c = r.get(s)
      if (!c) r.set(s, { layer: e.layer, objects: [e.object] })
      else c.objects.push(e.object)
    })
    const n = []
    r.forEach((e) => {
      n.push(e)
    })
    return n
  }
}
function _isPickInfoArrayValid(e) {
  const t = undefined
  return !!(e && e.length && _isPickInfoValid(e[0]))
}
function _isPickInfoValid(e) {
  const t = undefined
  return !!(e && e.layer && e.objects && e.objects.length)
}
function groupByLayer(e, t) {
  if (!_isPickInfoArrayValid(t)) return
  let r, s
  t.forEach((t) => {
    r = t.layer.id
    s = e.get(r)
    if (!s) e.set(r, { layer: t.layer, objects: t.objects })
    else s.objects = s.objects.concat(t.objects)
  })
}
