import { GeodeticDatum } from '../geodesy/GeodeticDatum.js'
import { TransverseMercator } from '../projection/TransverseMercator.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { GridReference } from './GridReference.js'
import { isNumber } from '../util/Lang.js'
const FALSE_NORTHING_NORTHERN_HEMISPHERE = 0
const FALSE_NORTHING_SOUTHERN_HEMISPHERE = 1e7
export class UTMGrid extends GridReference {
  constructor(e, r, t, n) {
    const o = t || new GeodeticDatum()
    let i
    if ('undefined' === typeof r || null === r) i = 0
    else
      i = r
        ? FALSE_NORTHING_NORTHERN_HEMISPHERE
        : FALSE_NORTHING_SOUTHERN_HEMISPHERE
    super({
      name: 'UTM grid',
      geodeticDatum: o,
      projection: new TransverseMercator(6 * (e - 30) - 3),
      falseEasting: 5e5,
      falseNorthing: i,
      scale: 0.9996,
      unitOfMeasure: 1,
      rotation: 0,
      axisInformation: n,
    })
    if (!e) this._zoneID = 30
    else if (!isNumber(e) || e < 1 || e > 60)
      throw new ProgrammingError(`Invalid zone [${e}]`)
    else this._zoneID = e
  }
  isNorthernHemisphere() {
    return super.falseNorthing === FALSE_NORTHING_NORTHERN_HEMISPHERE
  }
  setNorthernHemisphere(e) {
    this.falseNorthing = e
      ? FALSE_NORTHING_NORTHERN_HEMISPHERE
      : FALSE_NORTHING_SOUTHERN_HEMISPHERE
  }
  toString() {
    return `UTMGrid[zone ${this.zoneID}, ${
      this.isNorthernHemisphere() ? 'north' : 'south'
    }]`
  }
  get projection() {
    return super.projection
  }
  get zoneID() {
    return this._zoneID
  }
  set zoneID(e) {
    if (e < 1 || e > 60)
      throw new ProgrammingError(`Invalid zone number [${e}]`)
    if (this._zoneID !== e) {
      this._zoneID = e
      this.projection.setCentralMeridian(6 * (e - 30) - 3)
    }
  }
  get centralMeridian() {
    return this.projection.getCentralMeridian()
  }
  set falseNorthing(e) {
    if (
      !(
        e === FALSE_NORTHING_NORTHERN_HEMISPHERE ||
        e === FALSE_NORTHING_SOUTHERN_HEMISPHERE
      )
    )
      throw new ProgrammingError(
        `False northing ${e} must be ${FALSE_NORTHING_NORTHERN_HEMISPHERE} or ${FALSE_NORTHING_SOUTHERN_HEMISPHERE}`
      )
    this.setFalseNorthing(e)
  }
  get name() {
    if (null != super.name) return super.name
    else
      return `UTM ${this.zoneID}${this.isNorthernHemisphere() ? 'N' : 'S'} (${
        this.geodeticDatum.name
      })`
  }
}
