import { createCartesianGeodesy } from '../../geodesy/GeodesyFactory.js'
import { GeodeticDatum } from '../../geodesy/GeodeticDatum.js'
import { LineType } from '../../geodesy/LineType.js'
import { create3DMesh } from '../../geometry/mesh/MeshFactory.js'
import { FeatureModel } from '../../model/feature/FeatureModel.js'
import { MemoryStore } from '../../model/store/MemoryStore.js'
import { RasterTileSetModel } from '../../model/tileset/RasterTileSetModel.js'
import { PseudoMercator } from '../../projection/PseudoMercator.js'
import { createGridReferenceAxisInformation } from '../../reference/AxisInformationUtil.js'
import { GeocentricReference } from '../../reference/GeocentricReference.js'
import { GridReference } from '../../reference/GridReference.js'
import {
  createTopocentricReference,
  getHeightAboveTerrainReference,
  getReference,
} from '../../reference/ReferenceProvider.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import {
  createBounds,
  createPoint,
  createPolygon,
} from '../../shape/ShapeFactory.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { FeatureLayer } from '../../view/feature/FeatureLayer.js'
import { FeaturePainter } from '../../view/feature/FeaturePainter.js'
import { RasterTileSetLayer } from '../../view/tileset/RasterTileSetLayer.js'
import { Ajax } from '../Ajax.js'
import { clamp } from '../Cartesian.js'
import { Constants } from '../Constants.js'
import { RotatedBox } from '../RotatedBox.js'
import { URL } from '../URL.js'
import { KMLAltitudeMode } from './KMLAltitudeMode.js'
import { DrapeTarget } from '../../view/style/DrapeTarget.js'
export const WGS84_MSL = getReference('EPSG:4326')
export const WGS84_AGL = getHeightAboveTerrainReference('EPSG:4326')
export function getCoordinateReference(e) {
  const t = undefined
  return e &
    (KMLAltitudeMode.CLAMP_TO_GROUND | KMLAltitudeMode.RELATIVE_TO_GROUND)
    ? WGS84_AGL
    : WGS84_MSL
}
const shiftWebMercatorDateLineBounds = (e) => {
  const t = getReference('CRS:84')
  const r = undefined
  const o = createTransformation(e.reference, t).transformBounds(e)
  const n = undefined
  return shiftWebMercatorDateLineTransformation(e).transformBounds(o)
}
export const shiftWebMercatorDateLineTransformation = (e) => {
  const t = undefined
  let r
  if (e.x + e.width > 180) {
    const t = createGridReferenceAxisInformation(false)
    const o = new PseudoMercator()
    o.setCentralMeridian(e.x + e.width / 2)
    r = new GridReference({
      name: 'Shifted Web Mercator',
      projection: o,
      geodeticDatum: new GeodeticDatum(),
      axisInformation: t,
    })
  } else r = getReference('EPSG:900913')
  return createTransformation(e.reference, r)
}
class KMLGroundOverlayRasterModel extends RasterTileSetModel {
  constructor(e, t, r) {
    const o = shiftWebMercatorDateLineBounds(t)
    const n = o.focusPoint
    const s = r * Constants.DEG2RAD
    const a = o.width / o.height
    let i, c
    if (a >= 1) {
      i = e.width
      c = e.width / a
    } else {
      i = e.height * a
      c = e.height
    }
    const d = Math.abs(o.width * Math.cos(s)) + Math.abs(o.height * Math.sin(s))
    const f = Math.abs(o.width * Math.sin(s)) + Math.abs(o.height * Math.cos(s))
    const m = createBounds(o.reference, [n.x - d / 2, d, n.y - f / 2, f])
    const h = Math.abs(i * Math.cos(s)) + Math.abs(c * Math.sin(s))
    const l = Math.abs(i * Math.sin(s)) + Math.abs(c * Math.cos(s))
    const u = undefined
    super({
      structure: {
        level0Columns: 1,
        level0Rows: 1,
        levelCount: 1,
        reference: o.reference,
        bounds: m,
        tileWidth: h,
        tileHeight: l,
      },
    })
    const p = document.createElement('canvas')
    p.width = h
    p.height = l
    const M = p.getContext('2d', { willReadFrequently: true })
    M.save()
    M.clearRect(0, 0, p.width, p.height)
    M.translate(p.width / 2, p.height / 2)
    M.rotate(-r * Constants.DEG2RAD)
    M.drawImage(e, 0, 0, e.width, e.height, -i / 2, -c / 2, i, c)
    M.restore()
    this._imagePromise = new Promise((e, t) => {
      const r = new Image()
      r.onload = () => {
        e(r)
      }
      r.onerror = () => {
        t(new Error('Could not convert KML GroundOverlay canvas to image'))
      }
      r.src = p.toDataURL()
    })
  }
  getImage(e, t, r, o) {
    this._imagePromise
      .then((r) => {
        t(e, r)
      })
      .catch((e) => {
        r(e)
      })
  }
}
export function createGroundOverlayShape(e, t, r) {
  const o = shiftWebMercatorDateLineBounds(e)
  const n = o.reference
  const s = t * Constants.DEG2RAD
  const a = new RotatedBox()
  a.configure(o.x, o.y, o.width, o.height, s, o.width / 2, o.height / 2, 0)
  const i = a.getCorners()
  return createPolygon(n, [
    [...i[0], r],
    [...i[1], r],
    [...i[2], r],
    [...i[3], r],
  ])
}
function createKMLGroundOverlayMesh(e, t, r, o, n, s) {
  if (s._isSoftwareMap() && s.reference instanceof GeocentricReference)
    throw new Error('Can only create mesh ground overlays for a 3D WebGLMap')
  const a = createGroundOverlayShape(t, r, o)
  const i = a.reference
  const c = getReference('CRS:84')
  const d = createTransformation(i, c)
  const f = undefined
  const m = s.viewPaintingStrategy.techContext.shapeDiscretizer.discretize(
    a,
    s.reference,
    false,
    true,
    { lineType: LineType.CONSTANT_BEARING }
  )
  if (!m || !m.fillMesh)
    throw new Error('Could not discretize a mesh for this ground overlay')
  const h = createTransformation(s.reference, i)
  const l = createCartesianGeodesy(i)
  const u = m.fillMesh
  const p = u.getPositions()
  const M = u.getIndices()
  const y = []
  const g = []
  const R = d.transform(a.focusPoint)
  const L = createTopocentricReference({ origin: R })
  const T = createTransformation(s.reference, L)
  const G = createPoint(i, [0, 0])
  const w = a.getPoint(0)
  const P = a.getPoint(1)
  const D = a.getPoint(2)
  const v = a.getPoint(3)
  for (let e = 0; e < p.typedArray.length; e += 3) {
    const t = [p.typedArray[e], p.typedArray[e + 1], p.typedArray[e + 2]]
    const r = createPoint(s.reference, t)
    const o = h.transform(r)
    const n = l.shortestDistanceToLine(o, v, D, {}, G)
    const a = l.shortestDistanceToLine(o, w, P, {}, G)
    const i = l.shortestDistanceToLine(o, v, w, {}, G)
    const c = l.shortestDistanceToLine(o, D, P, {}, G)
    const d = clamp(i / (i + c), 0, 1)
    const f = clamp(n / (n + a), 0, 1)
    g.push(d, f)
    const m = T.transform(r)
    y.push(m.x, m.y, m.z)
  }
  const S = []
  for (let e = 0; e < M.typedArray.length; e++) S[e] = M.typedArray[e]
  const j = undefined
  return {
    point: R,
    drapeTarget: n,
    mesh: create3DMesh(y, S, { texCoords: g, image: e }),
  }
}
class KMLGroundOverlayMeshPainter extends FeaturePainter {
  constructor(e) {
    super()
    this._meshResult = e
  }
  paintBody(e, t, r, o, n, s) {
    const { mesh: a, drapeTarget: i, point: c } = this._meshResult
    e.drawIcon3D(c, { mesh: a, draped: i, orientation: { heading: 90 } })
  }
}
export function createGroundOverlayLayer(e, t, r) {
  return e.viewPaintingStrategy
    .whenInitialized()
    .then(() => {
      const e = t.properties.icon.href
      const o = Ajax.getImage(
        e,
        false,
        !URL.isLocalURL(e),
        r?.requestOptions?.credentials,
        r?.requestOptions?.requestHeaders,
        r?.abortSignal
      )
      return Promise.resolve(o)
    })
    .then((o) => {
      const {
        rotation: n,
        altitude: s,
        altitudeMode: a,
        bounds: i,
        visibility: c,
      } = t.properties
      const d = !e._isSoftwareMap()
      if (
        !d &&
        !(
          e.reference.referenceType === ReferenceType.GRID &&
          e.reference.projection instanceof PseudoMercator
        )
      )
        throw new Error(
          'KML Ground overlays on non-WebGLMap can only be visualized on PseudoMercator maps. (map.reference is EPSG:900913 or EPSG:3857)'
        )
      const f = {
        visible: c,
        label: t.properties.name || void 0,
        ...r?.layerOptions,
      }
      const m = undefined
      const h =
        a === KMLAltitudeMode.CLAMP_TO_GROUND
          ? r?.drapeTarget ?? DrapeTarget.TERRAIN
          : DrapeTarget.NOT_DRAPED
      if (d) {
        const r = new MemoryStore({ data: [t] })
        const a = new FeatureModel(r, { reference: getReference('CRS:84') })
        const c = createKMLGroundOverlayMesh(o, i, n, s, h, e)
        return new FeatureLayer(a, {
          ...f,
          painter: new KMLGroundOverlayMeshPainter(c),
        })
      } else {
        const e = new KMLGroundOverlayRasterModel(o, i, n)
        const t = new RasterTileSetLayer(e, f)
        t.rasterStyle.drapeTarget = h
        return t
      }
    })
}
