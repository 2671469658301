import { HTML5DrawCommandProto } from './HTML5DrawCommandProto.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { OcclusionMode } from './OcclusionMode.js'
class NullDrawCommand extends HTML5DrawCommandProto {
  constructor() {
    super(
      getReference('CRS:84'),
      createPoint(getReference('CRS:84'), [0, 0]),
      0
    )
  }
  static createNullDrawCommand() {
    return new NullDrawCommand()
  }
  interacts() {
    return false
  }
  strokeInteracts() {
    return false
  }
  mapAnchorPointSFCT(e, r) {
    return false
  }
  findDrawCommand(e) {
    return null
  }
  bindDomainShape(e) {}
  bindWorldBounds(e) {}
  isEquivalent(e) {
    return false
  }
  getMaximumPadding(e) {
    return 0
  }
  getMapDistanceSquared() {
    return 0
  }
  viewPointSFCT(e, r, n) {
    return false
  }
  getOcclusionHintForLabel() {
    return OcclusionMode.VISIBLE_ONLY
  }
}
export const drawNullCommand = NullDrawCommand.createNullDrawCommand()
