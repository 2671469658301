import { ProgrammingError } from '../../error/ProgrammingError.js'
import { polygonContains2D } from '../../geodesy/EllipsoidPolygonUtil.js'
import { closestPointOnGeodesic as closestPointOnGeodesicSphere } from '../../geodesy/SphereUtil.js'
import { ShapeType } from '../../shape/ShapeType.js'
import { calculate2DBoundsPoints } from '../../shape/GeodesicBoundsUtil.js'
import { LLHBounds } from '../../shape/LLHBounds.js'
import { LLHPolygon } from '../../shape/LLHPolygon.js'
import { LLHPolyline } from '../../shape/LLHPolyline.js'
import { Ellipsoid } from '../../geodesy/Ellipsoid.js'
import {
  closestPointOnGeodesic,
  intersection2DLineSegments,
} from '../../geodesy/EllipsoidUtil.js'
import { LLHPoint } from '../../shape/LLHPoint.js'
import { EllipsoidalAdvancedBinaryTopologyUtil } from './EllipsoidalAdvancedBinaryTopologyUtil.js'
import { TopologyUtil } from './TopologyUtil.js'
const EPSILON = 1e-12
const sphericalEllipsoid = new Ellipsoid()
sphericalEllipsoid.initializeAB(1, 1)
class TopologyUtilEllipsoidal extends TopologyUtil {
  constructor(e, t) {
    super(e)
    Object.defineProperty(this, '_ellipsoid', {
      configurable: false,
      enumerable: true,
      value: t,
      writable: false,
    })
    this._advancedBinaryTopologyUtil =
      new EllipsoidalAdvancedBinaryTopologyUtil(t)
    this._tempPointArray = [new LLHPoint(), new LLHPoint()]
  }
  get advancedBinaryTopologyUtil() {
    return this._advancedBinaryTopologyUtil
  }
  set advancedBinaryTopologyUtil(e) {
    throw new ProgrammingError(
      'advancedBinaryTopologyUtil property is not mutable'
    )
  }
  growBounds(e, t) {
    let n, i
    n = new LLHPoint(t.reference, [t.x, t.y])
    n.translate(
      -getToleranceLongitude(e, this._ellipsoid.auxRadius, n.y),
      -getToleranceLatitude(e, this._ellipsoid.auxRadius)
    )
    i = new LLHPoint(t.reference, [t.x, t.y])
    i.translate(t.width, t.height)
    i.translate(
      getToleranceLongitude(e, this._ellipsoid.auxRadius, i.y),
      getToleranceLatitude(e, this._ellipsoid.auxRadius)
    )
    t.setToIncludePoint2D(n)
    t.setToIncludePoint2D(i)
  }
  pointsEqual(e, t) {
    return pointsEqualSpherical(
      e.x,
      e.y,
      t.x,
      t.y,
      this.toleranceEqualPoints,
      this._ellipsoid.auxRadius
    )
  }
  boundsDontIntersectSegment(e, t, n) {
    if (null != t && e.contains2DPoint(t)) return false
    if (null != n && e.contains2DPoint(n)) return false
    const i = e.copy()
    if (this.toleranceEqualPoints > 0)
      this.growBounds(this.toleranceEqualPoints, i)
    if (null == n) return !i.contains2DPoint(t)
    const o = new LLHBounds(t.reference)
    calculate2DBoundsPoints(t, n, this._ellipsoid, o)
    return !i.interacts2D(o)
  }
  distancePointPoint(e, t) {
    return this._ellipsoid.geodesicDistance(e, t)
  }
  certainlyNoInteraction3Points(e, t, n) {
    const i = e.reference
    const o = getToleranceLongitude(
      this.toleranceEqualPoints,
      this._ellipsoid.auxRadius,
      Math.max(Math.abs(n.y), Math.max(Math.abs(e.y), Math.abs(t.y)))
    )
    const s = undefined
    if (!isAngleBetween(e.x, t.x, n.x, o)) return true
    const l = new LLHBounds(e.reference)
    calculate2DBoundsPoints(
      new LLHPoint(i, [e.x, e.y]),
      new LLHPoint(i, [t.x, t.y]),
      this._ellipsoid,
      l
    )
    if (this.toleranceEqualPoints > 0)
      this.growBounds(this.toleranceEqualPoints, l)
    return !l.contains2DCoordinates(n.x, n.y)
  }
  certainlyNoInteraction4Points(e, t, n, i) {
    let o, s, l
    const r = e.reference
    const a = Math.max(
      Math.max(Math.abs(e.y), Math.abs(t.y)),
      Math.max(Math.abs(n.y), Math.abs(i.y))
    )
    const c = getToleranceLongitude(
      this.toleranceEqualPoints,
      this._ellipsoid.auxRadius,
      a
    )
    o = isAngleBetween(n.x, i.x, e.x, c)
    s = isAngleBetween(n.x, i.x, t.x, c)
    l = isAngleBetween(e.x, t.x, n.x, c)
    if (!o && !s && !l) return true
    const u = new LLHBounds(e.reference)
    const d = new LLHBounds(e.reference)
    calculate2DBoundsPoints(
      new LLHPoint(r, [e.x, e.y]),
      new LLHPoint(r, [t.x, t.y]),
      this._ellipsoid,
      u
    )
    calculate2DBoundsPoints(
      new LLHPoint(r, [n.x, n.y]),
      new LLHPoint(r, [i.x, i.y]),
      this._ellipsoid,
      d
    )
    if (this.toleranceEqualPoints > 0)
      this.growBounds(this.toleranceEqualPoints, u)
    return !u.interacts2D(d)
  }
  isPointOnLineSegment(e, t, n) {
    return (
      this.closestPointOnLineSegment(e, t, n, e.copy()) <
      this.toleranceEqualPoints
    )
  }
  closestPointOnLineSegment(e, t, n, i) {
    if (this._ellipsoid.isSphere()) {
      closestPointOnGeodesicSphere(e, t, n, true, i)
      return this.distancePointPoint(i, n)
    } else
      return closestPointOnGeodesic(
        e,
        t,
        n,
        this._ellipsoid,
        0,
        this.toleranceEqualPoints / 4,
        i
      )
  }
  getAveragePoint(e, t) {
    const n = e.copy()
    this._ellipsoid.geodesicPositionSFCT(e, t, 0.5, n)
    return n
  }
  getAngle(e, t) {
    const n = sphericalEllipsoid.forwardAzimuth2D(e, t)
    let i = Math.PI / 2 - n
    if (i < -Math.PI) i += 2 * Math.PI
    else if (i > Math.PI) i -= 2 * Math.PI
    return i
  }
  lineSegmentIntersection(e, t, n, i, o) {
    let s,
      l = intersection2DLineSegments(
        e,
        t,
        n,
        i,
        this._ellipsoid,
        this.toleranceEqualPoints / 4,
        this._tempPointArray[0],
        this._tempPointArray[1]
      )
    if (-1 === l) l = 2
    for (s = 0; s < l; s++)
      o[s] = new LLHPoint(e.reference, [
        this._tempPointArray[s].x,
        this._tempPointArray[s].y,
      ])
    return l
  }
  getNewEditablePolyline(e, t) {
    if (t.length > 0) return new LLHPolyline(e, t)
    return new LLHPolyline(e)
  }
  getNewEditablePolygon(e, t) {
    if (t.length > 0) return new LLHPolygon(e, t)
    return new LLHPolygon(e)
  }
  getNewEditableBoundsFromPoint(e) {
    return new LLHBounds(e.reference, [e.x, 0, e.y, 0])
  }
  getNewEditableBoundsFromBounds(e) {
    return new LLHBounds(e.reference, [e.x, e.width, e.y, e.height])
  }
  getNewEditableBoundsFromCoordinates(e, t, n, i, o) {
    return new LLHBounds(e, [t, i, n, o])
  }
  contains2D(e, t) {
    if (ShapeType.contains(ShapeType.POLYGON, e.type))
      if (!this._ellipsoid.isSphere())
        return polygonContains2D(this._ellipsoid, e, t)
    return e.contains2DPoint(t)
  }
}
function pointsEqualSpherical(e, t, n, i, o, s) {
  const l = Math.abs(t - i)
  let r = Math.abs(e - n)
  if (r > 180) r = 360 - r
  return (
    (l <= EPSILON || l <= getToleranceLatitude(o, s)) &&
    (r <= EPSILON || r <= getToleranceLongitude(o, s, t))
  )
}
function getToleranceLatitude(e, t) {
  return (e / ((Math.PI * t) / 2)) * 90
}
function getToleranceLongitude(e, t, n) {
  const i = t * (1 - Math.abs(n) / 90)
  const o = 0 === i ? 360 : (e / ((Math.PI * i) / 2)) * 90
  return o > 360 ? 360 : o
}
function isAngleBetween(e, t, n, i) {
  const o = Math.min(e, t)
  const s = Math.max(e, t)
  if (s - o < 180) return n >= o - i && n < s + i
  else return n > s - i || n < o + i
}
export { TopologyUtilEllipsoidal }
