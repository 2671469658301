import { RTree } from '../view/feature/RTree.js'
import { createSimpleBoundsFromBounds } from '../view/feature/SimpleBounds.js'
export class ZOrderCalculator {
  constructor(e) {
    this._prevShapes = new RTree(e)
  }
  getZOrder(e) {
    if (!e.bounds) return 0
    let r = -1
    const s = createSimpleBoundsFromBounds(e.bounds)
    this._prevShapes.applyOnInteract2DBounds(s, (e) => {
      if (e.zOrder > r) r = e.zOrder
    })
    const t = r + 1
    this._prevShapes.addElement({ bounds: s, zOrder: t })
    return t
  }
}
