const DEFAULT_WIDTH = 64
const DEFAULT_HEIGHT = 64
const DEFAULT_STROKESTYLE = 'rgba(155,167,23,1)'
const DEFAULT_FILLSTYLE = 'rgba(155,167,23,1)'
const DEFAULT_FADINGSTYLE = 'rgba(155,167,23,0.1)'
const DEFAULT_STROKEWIDTH = 1
function makeContext(t) {
  const e = document.createElement('canvas')
  const n = e.getContext('2d', { willReadFrequently: true })
  e.width = t.width
  e.height = t.height
  n.strokeStyle = t.stroke
  n.fillStyle = t.fill
  n.lineWidth = t.strokeWidth
  return { canvas: e, context: n }
}
function normalizeOptions(t) {
  const e = t || {}
  if (e.width && !e.height) e.height = e.width
  else if (!e.width && e.height) e.width = e.height
  e.width = e.width ?? DEFAULT_WIDTH
  e.height = e.height ?? DEFAULT_HEIGHT
  e.strokeWidth = e.strokeWidth ?? DEFAULT_STROKEWIDTH
  e.stroke = e.stroke ?? DEFAULT_STROKESTYLE
  e.fill = e.fill ?? DEFAULT_FILLSTYLE
  return e
}
function getDomain(t) {
  const e = null == t ? false : !!t.fill
  const n = normalizeOptions(t)
  const { canvas: o, context: r } = makeContext(n)
  const a = n.strokeWidth / 2
  const c = (o.width - n.strokeWidth) / 2
  return Object.assign(n, {
    canvas: o,
    context: r,
    offset: a,
    radius: c,
    xCenter: o.width / 2,
    yCenter: o.height / 2,
    xRight: o.width - a,
    yBottom: o.height - a,
    solid: e,
  })
}
function drawPath(t, e, n) {
  t.beginPath()
  let o = true
  e.forEach(function (e) {
    if (o) {
      t.moveTo(e[0], e[1])
      o = false
    } else t.lineTo(e[0], e[1])
  })
  t.closePath()
  if (n) t.fill()
  t.stroke()
}
function regularPolygon(t, e, n) {
  const o = getDomain(t)
  const r = getPointRegularPolygon(o.xCenter, o.yCenter, o.radius, e, n)
  drawPath(o.context, r, o.solid)
  return o.canvas
}
function getPointRegularPolygon(t, e, n, o, r) {
  const a = undefined
  const c = (r = r ? r * (Math.PI / 180) : 0) - Math.PI / 2
  let i = 0
  const s = []
  s.push([t + n * Math.cos(c), e + n * Math.sin(c)])
  for (let r = 1; r <= o; r += 1) {
    i = (2 * r * Math.PI) / o + c
    s.push([t + n * Math.cos(i), e + n * Math.sin(i)])
  }
  return s
}
export function createStar(t) {
  const e = getDomain(t)
  const n = 5
  const o = 360 / n / 2
  const r = getPointRegularPolygon(e.xCenter, e.yCenter, 0.5 * e.radius, n, o)
  const a = getPointRegularPolygon(e.xCenter, e.yCenter, e.radius, n, 0)
  const c = []
  for (let t = 0; t < n; t++) {
    c.push(a[t])
    c.push(r[t])
  }
  c.push(a[0])
  drawPath(e.context, c, e.solid)
  return e.canvas
}
function createCross(t, e) {
  const n = getDomain(t)
  const o = getPointRegularPolygon(n.xCenter, n.yCenter, n.radius, 4, e)
  drawPath(n.context, [o[0], o[2]])
  drawPath(n.context, [o[1], o[3]])
  return n.canvas
}
export function createCircle(t) {
  const e = getDomain(t)
  const n = e.context
  const o = e.xCenter
  let r = e.radius
  if (r <= 0) r = 1
  n.arc(o, o, r, 0, 2 * Math.PI, false)
  if (e.solid) n.fill()
  n.stroke()
  return e.canvas
}
export function createGradientCircle(t) {
  const e = normalizeOptions(t)
  const { canvas: n, context: o } = makeContext(e)
  const r = 1,
    a = n.width / 2
  let c = a - r
  if (c <= 4) c = 4
  const i = 0.25 * c
  const s = 0.75 * c
  const l = o.createRadialGradient(a, a, i, a, a, s)
  l.addColorStop(0, t.fill || DEFAULT_FILLSTYLE)
  l.addColorStop(1, t.fading || DEFAULT_FADINGSTYLE)
  o.fillStyle = l
  o.arc(a, a, c, 0, 2 * Math.PI, false)
  o.fill()
  return n
}
export function createRectangle(t) {
  const e = getDomain(t)
  const n = e.context
  const o = e.canvas
  const r = e.offset
  if (e.solid) n.fillRect(r, r, o.width - 2 * r, o.height - 2 * r)
  n.strokeRect(r, r, o.width - 2 * r, o.height - 2 * r)
  return o
}
export function createSquare(t) {
  return regularPolygon(t || {}, 4, 45)
}
export function createTriangle(t) {
  return regularPolygon(t || {}, 3)
}
export function createPentagon(t) {
  return regularPolygon(t || {}, 5)
}
export function createHexagon(t) {
  return regularPolygon(t || {}, 6)
}
export function createX(t) {
  return createCross(t, 45)
}
const IconFactoryMap = {
  circle: createCircle,
  gradientCircle: createGradientCircle,
  rectangle: createRectangle,
  square: createSquare,
  triangle: createTriangle,
  pentagon: createPentagon,
  hexagon: createHexagon,
  star: createStar,
  cross: createCross,
  x: createX,
}
export function createByWellKnownName(t, e) {
  return IconFactoryMap[t](e || {})
}
