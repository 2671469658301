import { isUndefined } from '../../util/Lang.js'
import { Layer } from '../Layer.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
export class GridLayer extends Layer {
  constructor(e, i) {
    const t = i || {}
    if (isUndefined(t.label)) t.label = e.name
    super(null, t)
    this._grid = e
    this._grid.on('change', this._handleGridChange.bind(this))
    this._isVisiblePaintRepresentation = {}
    this._isVisiblePaintRepresentation[PaintRepresentation.BODY] = true
    this._isVisiblePaintRepresentation[PaintRepresentation.LABEL] = true
    this._gridRenderer = null
    this.on('VisibilityChanged', () => this._gridRenderer?.invalidate())
  }
  isReady() {
    return true
  }
  _handleGridChange() {
    if (this._map) this._map.invalidate()
    if (this._gridRenderer) this._gridRenderer.invalidate()
  }
  _addedToMap(e) {
    super._addedToMap(e)
    e.onReady(() => {
      this._updateGridRenderer(this._map)
    })
  }
  _removedFromMap(e) {
    super._removedFromMap(e)
    this._updateGridRenderer(null)
  }
  _updateGridRenderer(e) {
    if (this._gridRenderer) {
      this._gridRenderer.release()
      this._gridRenderer = null
    }
    if (!e || !this._grid) return
    const i = e.viewPaintingStrategy.techContext
    this._gridRenderer = this._grid.createRenderer({
      map: e,
      techContext: i,
      grid: this._grid,
      layer: this,
      paintBodies: this._isVisiblePaintRepresentation[PaintRepresentation.BODY],
      paintLabels:
        this._isVisiblePaintRepresentation[PaintRepresentation.LABEL],
    })
    if (!this._gridRenderer) this.visibleSupported = false
  }
  update() {
    if (!this._map || !this._gridRenderer) return true
    return this._gridRenderer.update()
  }
  isPaintRepresentationSupported(e) {
    return e === PaintRepresentation.BODY || e === PaintRepresentation.LABEL
  }
  _paintBodyLabels(e, i, t) {
    if (this._gridRenderer && !i) return this._gridRenderer.render(e, i, t)
    else return true
  }
  inAllowedScaleRange() {
    return true
  }
  setPaintRepresentationVisible(e, i) {
    const t = this._isVisiblePaintRepresentation[e]
    this._isVisiblePaintRepresentation[e] = i
    if (this._map) {
      this._updateGridRenderer(this._map)
      this._map.invalidate()
    }
    if (t !== i) this.emit('PaintRepresentationVisibilityChanged', i, e)
  }
  _collectLabels(e, i, t) {
    if (this._gridRenderer) this._gridRenderer.collectLabels(t)
  }
  get model() {
    return null
  }
  get grid() {
    return this._grid
  }
  get bounds() {
    if (this._gridRenderer) return this._gridRenderer.bounds
    return null
  }
}
