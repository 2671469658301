import { ProgrammingError } from '../error/ProgrammingError.js'
import { Constants } from '../util/Constants.js'
import { buildCache } from '../util/Cacher.js'
import { LLHPoint } from './LLHPoint.js'
import { LLHBounds } from './LLHBounds.js'
import { ShapeType } from './ShapeType.js'
import { CircularArcByCenterPoint } from './CircularArcByCenterPoint.js'
import * as LLHCircularArcUtil from './LLHCircularArcUtil.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { ellipticalDistance2D } from '../util/Cartesian.js'
export class LLHCircularArcByCenterPoint extends CircularArcByCenterPoint {
  constructor(e, r, t, i, n) {
    super()
    this._reference = this.validateReference(e)
    if (r && r.type === ShapeType.POINT) {
      this._compareReferenceStrict(
        r.reference,
        'LLHCircularArcByCenterPoint::the arc reference must match the center point reference'
      )
      this._center = r.copy()
    } else
      throw new ProgrammingError(
        'LLHCircularArcByCenterPoint::cannot create an arc without a center point'
      )
    if ('number' !== typeof t)
      throw new ProgrammingError(
        'LLHCircularArcByCenterPoint::cannot create an arc without a radius'
      )
    this._radius = t
    if ('number' !== typeof i)
      throw new ProgrammingError(
        'LLHCircularArcByCenterPoint::cannot create an arc without a start azimuth'
      )
    this._startAzimuth = i
    if ('number' !== typeof n)
      throw new ProgrammingError(
        'LLHCircularArcByCenterPoint::cannot create an arc without an arc angle'
      )
    this._sweepAngle = n
    this._cache = buildCache()
  }
  get isGeodetic() {
    return true
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  get center() {
    return this._center
  }
  get focusPoint() {
    return this._center
  }
  get radius() {
    return this._radius
  }
  set radius(e) {
    if (e >= 0) {
      this._radius = e
      this.invalidate()
    } else
      throw new ProgrammingError(
        `Radius should be positive number, but was: ${e}`
      )
  }
  get startAzimuth() {
    return this._startAzimuth
  }
  set startAzimuth(e) {
    this._startAzimuth = e
    this.invalidate()
  }
  get sweepAngle() {
    return this._sweepAngle
  }
  set sweepAngle(e) {
    this._sweepAngle = e
    this.invalidate()
  }
  get bounds() {
    return this._cache.memoize('bounds', () => {
      const e = new LLHBounds(this.reference)
      LLHCircularArcUtil.boundsSFCT(this, e)
      return e
    })
  }
  get startPoint() {
    return this._cache.memoize('start-point', () =>
      calculatePointOnCircle(
        this.center,
        this.radius,
        this.startAzimuth,
        this.reference
      )
    )
  }
  get endPoint() {
    return this._cache.memoize('end-point', () =>
      calculatePointOnCircle(
        this.center,
        this.radius,
        this.startAzimuth + this.sweepAngle,
        this.reference
      )
    )
  }
  contains2DCoordinates(e, r) {
    return LLHCircularArcUtil.contains2D(this, e, r)
  }
  invalidate() {
    this._cache.invalidate()
  }
  copy() {
    return new LLHCircularArcByCenterPoint(
      this._reference,
      this._center.copy(),
      this._radius,
      this._startAzimuth,
      this._sweepAngle
    )
  }
  forwardAzimuth(e, r) {
    return (
      Constants.RAD2DEG *
      this._reference.geodeticDatum.ellipsoid.forwardAzimuth2D(e, r)
    )
  }
  contains3DCoordinates(e, r, t) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape LLHCircularArcByCenterPoint'
    )
  }
}
function calculatePointOnCircle(e, r, t, i) {
  const n = new LLHPoint(i),
    o = ellipticalDistance2D(r, r, t)
  i.geodeticDatum.ellipsoid.geodesicPositionSFCT(e, o, t, n)
  return n
}
