import { ProgrammingError } from '../error/ProgrammingError.js'
import { getUnitOfMeasure } from '../uom/UnitOfMeasureRegistry.js'
import { Hash } from '../util/Hash.js'
import { Axis } from './Axis.js'
import { CoordinateType } from './CoordinateType.js'
import { GeoReference } from './GeoReference.js'
import { ReferenceType } from './ReferenceType.js'
const meterUom = getUnitOfMeasure('Meter')
export class TopocentricReference extends GeoReference {
  constructor(e) {
    let r
    if (!e.axisInformation)
      r = [
        {
          name: Axis.Name.X,
          axis: new Axis(
            'X',
            Axis.Direction.EAST,
            meterUom,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
        {
          name: Axis.Name.Y,
          axis: new Axis(
            'Y',
            Axis.Direction.NORTH,
            meterUom,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
        {
          name: Axis.Name.Z,
          axis: new Axis(
            'Z',
            Axis.Direction.UP,
            meterUom,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
      ]
    else r = e.axisInformation
    if ('object' !== typeof e.origin)
      throw new ProgrammingError(
        'No origin specified for Topocentric reference.'
      )
    if (!(e.origin.reference instanceof GeoReference))
      throw new ProgrammingError(
        'Origin for Topocentric reference is not georeferenced.'
      )
    super({
      coordinateType: CoordinateType.CARTESIAN,
      referenceType: ReferenceType.TOPOCENTRIC,
      name: e.name ?? 'Topocentric reference',
      geodeticDatum: e.origin.reference.geodeticDatum,
      axisInformation: r,
    })
    this._origin = e.origin
    this._unitOfMeasure = (e ? e.uom : void 0) || 1
    this._hash = 0
  }
  equals(e) {
    if (e === this) return true
    if (e instanceof TopocentricReference)
      return (
        this.geodeticDatum.equals(e.geodeticDatum) &&
        this.origin.equals(e.origin) &&
        this.unitOfMeasure === e.unitOfMeasure
      )
    else return false
  }
  copy() {
    return new TopocentricReference({ name: this.name, origin: this.origin })
  }
  get origin() {
    return this._origin
  }
  get unitOfMeasure() {
    return this._unitOfMeasure
  }
  set unitOfMeasure(e) {
    this._unitOfMeasure = e
    this._hash = 0
  }
  get TYPE() {
    return ReferenceType.TOPOCENTRIC
  }
  hashCode(e) {
    if (0 === this._hash) {
      const e = new Hash()
      super.hashCode(e)
      this.origin.hashCode(e)
      e.appendDouble(this._unitOfMeasure)
      this._hash = e.getHashCode()
    }
    e.appendUInt32(this._hash)
  }
}
