import { kmlValueFactory, kmlValueMapFactory } from './KMLValues.js'
import { createPhotonColorFromString } from '../Color.js'
import { isString } from '../Lang.js'
export let KMLColorMode = (function (t) {
  t[(t['NORMAL'] = 0)] = 'NORMAL'
  t[(t['RANDOM'] = 1)] = 'RANDOM'
  return t
})({})
const COLOR_MAP = { normal: KMLColorMode.NORMAL, random: KMLColorMode.RANDOM }
export const getKmlColorMode = kmlValueMapFactory(
  KMLColorMode.NORMAL,
  COLOR_MAP
)
export const getKmlColor = kmlValueFactory(null, (t, r) => {
  const o = r || { r: 1, g: 1, b: 1, a: 1 }
  if (null === t) return o
  const e = isString(t) ? parseInt(t.substr(t.indexOf('#') + 1), 16) : NaN
  if (isNaN(e) || !isFinite(e)) return o
  const s = 0
  const n = 8
  const a = 16
  const i = 24
  const l = 255
  o.r = ((e >> s) & l) / l
  o.g = ((e >> n) & l) / l
  o.b = ((e >> a) & l) / l
  o.a = ((e >> i) & l) / l
  return o
})
export class KMLColor {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1
    let r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1
    let o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1
    let e = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 1
    this._r = t
    this._g = r
    this._b = o
    this._a = e
  }
  get a() {
    return this._a
  }
  set a(t) {
    this._a = t
  }
  get b() {
    return this._b
  }
  set b(t) {
    this._b = t
  }
  get g() {
    return this._g
  }
  set g(t) {
    this._g = t
  }
  get r() {
    return this._r
  }
  set r(t) {
    this._r = t
  }
  get rgba() {
    const { _r: t, _g: r, _b: o, _a: e } = this
    return `rgba(${(255 * t) | 0},${(255 * r) | 0},${(255 * o) | 0},${e})`
  }
  get rgb() {
    const { _r: t, _g: r, _b: o } = this
    return `rgb(${(255 * t) | 0},${(255 * r) | 0},${(255 * o) | 0})`
  }
  get isTransparent() {
    return 0 === this._a
  }
  get opacity() {
    return this._a
  }
  random() {
    const t = Math.random() * this._r
    const r = Math.random() * this._g
    const o = Math.random() * this._b
    return new KMLColor(t, r, o, this._a)
  }
  replace(t) {
    const {
      r: r,
      g: o,
      b: e,
      a: s,
    } = createPhotonColorFromString(t) || { r: 1, g: 1, b: 1, a: 1 }
    this._r = r
    this._g = o
    this._b = e
    this._a = s
  }
  clone() {
    const { _r: t, _g: r, _b: o, _a: e } = this
    return new KMLColor(t, r, o, e)
  }
}
