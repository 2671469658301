import { ShapeType } from '../../../shape/ShapeType.js'
import { EditContext } from '../../controller/EditContext.js'
import { EditHandle } from '../EditHandle.js'
import { Editor } from '../Editor.js'
import { CompositeEditHandle } from '../handles/CompositeEditHandle.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { GeoBufferInvalidateHandle } from '../handles/GeoBufferInvalidateHandle.js'
import { mergeMinMaxPointCount } from '../../controller/EditSettings.js'
import { ShapeTranslateHandle } from '../handles/ShapeTranslateHandle.js'
class SubShapeInvalidatingHandleDecorator extends EditHandle {
  constructor(e, t) {
    super()
    this._delegate = e
    this._delegate.on('EditShape', (e) => {
      this.emitEditShapeEvent(t, e.status)
    })
    this._parentShape = t
  }
  getCursor(e, t) {
    return this._delegate.getCursor(e, t)
  }
  onGestureEvent(e, t) {
    const a = this._delegate.onGestureEvent(e, t)
    if (a) this._parentShape.invalidate()
    return a
  }
  onDraw(e, t) {
    this._delegate.onDraw(e, t)
  }
  update() {
    this._delegate.update()
  }
  onCreateContextMenu(e, t, a, n) {
    this._delegate.onCreateContextMenu(e, t, a, n)
  }
}
export class ComposedShapeEditor extends Editor {
  constructor(e, t) {
    super()
    this._shapeType = e
    this._delegate = t
  }
  get shapeType() {
    return this._shapeType
  }
  get delegate() {
    return this._delegate
  }
  canEdit(e) {
    const t = e.shape
    if (!t || t.type !== this._shapeType) return false
    let a = true
    for (let n = 0; n < this.getSubShapeCount(t); n++) {
      const s = this.getSubShape(t, n)
      const i = new EditContext(e.map, e.layer, e.feature, s, e.settings)
      a = this._delegate.canEdit(i) && a
    }
    return a
  }
  createTranslateHandle(e) {
    const t = []
    const a = e.shape
    for (let e = 0; e < this.getSubShapeCount(a); e++) {
      const n = this.getSubShape(a, e)
      if (n && ShapeType.contains(n.type, ShapeType.GEO_BUFFER)) t.push(n)
    }
    let n = super.createTranslateHandle(e)
    if (n && t.length > 0)
      n = new GeoBufferInvalidateHandle(a, {
        handleDelegate: n,
        geoBuffersToInvalidate: t,
        invalidateOnDeactivate: true,
        invalidateOnHandled: false,
      })
    return n
  }
  getEditHandles(e) {
    const t = []
    const a = e.shape
    for (let n = 0; n < this.getSubShapeCount(a); n++) {
      const s = this.getSubShape(a, n)
      const i = mergeMinMaxPointCount(e.settings, s)
      const o = new EditContext(e.map, e.layer, e.feature, s, i)
      if (this._delegate.canEdit(o)) {
        let e = this._delegate.getEditHandles(o)
        e = e.filter((e) => !(e instanceof ShapeTranslateHandle))
        if (s.type === ShapeType.POINT) {
          const e = s
          const n = new PointDragHandle(
            () => e,
            (t) => e.move2DToPoint(t)
          )
          t.push(new SubShapeInvalidatingHandleDecorator(n, a))
        } else if (e.length > 0) {
          const n = new CompositeEditHandle(e, { cascading: false })
          t.push(new SubShapeInvalidatingHandleDecorator(n, a))
        }
      }
    }
    const n = this.createTranslateHandle(e)
    if (n) t.push(n)
    return t
  }
  getCreateHandle(e) {
    return null
  }
}
