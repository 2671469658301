import { EventedSupport } from '../../util/EventedSupport.js'
import { WGS84_MSL } from '../../util/kml/KMLUtil.js'
import { FeatureModel } from '../feature/FeatureModel.js'
import { UrlStore } from '../store/UrlStore.js'
import { KMLCodec } from './KMLCodec.js'
import {
  KMLFatalErrorEvent,
  KMLGroundOverlayEvent,
  KMLIsHoverableEvent,
  KMLModelGeometryEvent,
  KMLNetworkLinkEvent,
  KMLScreenOverlayEvent,
  KMLTreeEvent,
} from './KMLEvented.js'
export const KML_VERSION = 'KML 2.3'
export class KMLModel extends FeatureModel {
  constructor(e) {
    const t = new KMLCodec({ origin: e })
    const n = undefined
    const r = new UrlStore({ target: e, codec: t, handleAs: 'arraybuffer' })
    const o = undefined
    super(r, {
      reference: WGS84_MSL,
      modelDescriptor: {
        description: KML_VERSION,
        name: KML_VERSION,
        source: r.target,
        type: KML_VERSION,
      },
    })
    this._evented = new EventedSupport()
    t.on(KMLFatalErrorEvent, (e) => this.onKMLFatalErrorEvent(e))
    t.on(KMLScreenOverlayEvent, (e) => this.onKMLScreenOverlayEvent(e))
    t.on(KMLGroundOverlayEvent, (e) => this.onKMLGroundOverlayEvent(e))
    t.on(KMLNetworkLinkEvent, (e) => this.onKMLNetworkLinkEvent(e))
    t.on(KMLTreeEvent, (e) => this.onKMLTreeEvent(e))
    t.on(KMLModelGeometryEvent, (e) => this.onKMLModelGeometryEvent(e))
    t.on(KMLIsHoverableEvent, (e) => this.onKMLIsHoverableEvent(e))
  }
  query(e, t) {
    return super.query(e, t)
  }
  on(e, t, n) {
    return this._evented.on(e, t, n)
  }
  onKMLFatalErrorEvent(e) {
    this._evented.emit(KMLFatalErrorEvent, e)
  }
  onKMLGroundOverlayEvent(e) {
    this._evented.emit(KMLGroundOverlayEvent, e)
  }
  onKMLScreenOverlayEvent(e) {
    this._evented.emit(KMLScreenOverlayEvent, e)
  }
  onKMLNetworkLinkEvent(e) {
    this._evented.emit(KMLNetworkLinkEvent, e)
  }
  onKMLTreeEvent(e) {
    this._evented.emit(KMLTreeEvent, e)
  }
  onKMLModelGeometryEvent(e) {
    this._evented.emit(KMLModelGeometryEvent, e)
  }
  onKMLIsHoverableEvent(e) {
    this._evented.emit(KMLIsHoverableEvent, e)
  }
}
