import { isOfShapeType } from '../../../shape/Shape.js'
import { createPolygon, createShapeList } from '../../../shape/ShapeFactory.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { isNumber } from '../../../util/Lang.js'
import { createDrawNull } from '../../style/HTML5DrawCommands.js'
import {
  isProjectionTargetClosestSurfaceOptions,
  ProjectionTarget,
} from '../../style/PanoramaStyle.js'
import { URL } from '../../../util/URL.js'
export class CollectingGeoAndLabelCanvas {
  constructor(e) {
    this._drawCalls = []
    this._worldReference = e && e.worldReference
  }
  drawIcon(e, t) {
    if (e) this._drawCalls.push({ type: 'icon', shape: e, style: t || {} })
  }
  drawText(e, t) {
    let a = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {}
    if (e) {
      const r = { ...a }
      this._drawCalls.push({ type: 'text', shape: e, text: t, style: r })
    }
  }
  drawShape(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    if (!e) return
    if (isOfShapeType(e, ShapeType.ORIENTED_BOX)) e = decomposeOrientedBox(e)
    this._drawCalls.push({ type: 'shape', shape: e, style: t })
  }
  drawIcon3D(e, t) {
    if (e) this._drawCalls.push({ type: 'icon3d', shape: e, style: t })
  }
  drawLabel(e, t, a) {
    if (t) this._drawCalls.push({ type: 'label', text: e, shape: t, style: a })
  }
  drawLabelInPath(e, t, a) {
    if (t)
      this._drawCalls.push({ type: 'labelInPath', text: e, shape: t, style: a })
  }
  drawLabelOnPath(e, t, a) {
    if (t)
      this._drawCalls.push({ type: 'labelOnPath', text: e, shape: t, style: a })
  }
  drawPanorama(e, t, a) {
    const r = {
      type: 'panorama',
      location: e,
      context: a || {},
      style: normalizePanoramaStyle(t),
      shape: e,
    }
    this._drawCalls.push(r)
  }
  build() {
    const e = this._drawCalls
    this._drawCalls = []
    return e
  }
  handleLabelWithoutBody(e, t) {
    if (e && !this.isShapeWithBodyCall(e))
      if (t) this.drawIcon(e, INVISIBLE_POINT_STYLE)
      else this.drawShape(e, INVISIBLE_SHAPE_STYLE)
  }
  isShapeWithBodyCall(e) {
    const t = this._drawCalls.length
    for (let a = 0; a < t; a++) if (e === this._drawCalls[a].shape) return true
    return false
  }
  get worldReference() {
    return this._worldReference
  }
  _didProcessing() {
    return false
  }
  buildDrawCommand() {
    return createDrawNull()
  }
  reset() {}
}
function normalizePanoramaStyle(e) {
  const t =
    e && isProjectionTargetClosestSurfaceOptions(e.projection)
      ? {
          target: ProjectionTarget.CLOSEST_SURFACE,
          baseOpacity: isNumber(e.projection.baseOpacity)
            ? e.projection.baseOpacity
            : 0,
        }
      : { target: ProjectionTarget.ALL_SURFACES }
  return {
    opacity: e && isNumber(e.opacity, false) ? e.opacity : 1,
    skyOpacity: e && isNumber(e.skyOpacity, false) ? e.skyOpacity : 1,
    orientation: e && isNumber(e.orientation, false) ? e.orientation : 0,
    projection: t,
  }
}
function makePolygon(e, t, a) {
  const r = a.map((e) => [t[e].x, t[e].y, t[e].z])
  return createPolygon(e, r)
}
function decomposeOrientedBox(e) {
  const t = e.reference
  const a = makePolygon(t, e.getCorners(), [0, 1, 3, 2])
  const r = makePolygon(t, e.getCorners(), [4, 5, 7, 6])
  const o = makePolygon(t, e.getCorners(), [0, 1, 5, 4])
  const s = makePolygon(t, e.getCorners(), [2, 3, 7, 6])
  const n = makePolygon(t, e.getCorners(), [1, 3, 7, 5])
  const i = makePolygon(t, e.getCorners(), [0, 2, 6, 4])
  return createShapeList(t, [a, r, n, i, s, o])
}
const INVISIBLE_POINT_STYLE = {
  url: URL.fromData(
    'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
  ),
}
const INVISIBLE_SHAPE_STYLE = { stroke: { width: 0.1, color: 'rgba(0,0,0,0)' } }
