import { GeodeticDatum } from '../geodesy/GeodeticDatum.js'
import { LLHBounds } from '../shape/LLHBounds.js'
import { Hash } from '../util/Hash.js'
import { isString } from '../util/Lang.js'
import { Axis } from './Axis.js'
import { createGeodeticReferenceAxisInformation } from './AxisInformationUtil.js'
import { CoordinateType } from './CoordinateType.js'
import { GeoReference } from './GeoReference.js'
import { ReferenceType } from './ReferenceType.js'
export class GeodeticReference extends GeoReference {
  constructor(e) {
    const t = e || {}
    if (!t.axisInformation)
      t.axisInformation = createGeodeticReferenceAxisInformation(false, false)
    t.coordinateType = CoordinateType.GEODETIC
    t.referenceType = ReferenceType.GEODETIC
    let i, o
    if ('object' !== typeof e || null == e) {
      i = new GeodeticDatum()
      o = 'WGS_1984'
    } else if (
      'object' !== typeof e.geodeticDatum ||
      null === e.geodeticDatum
    ) {
      i = new GeodeticDatum()
      o = 'WGS_1984'
    } else {
      i = e.geodeticDatum
      if (isString(e.name)) o = e.name
      else o = i.name
    }
    t.name = o
    t.geodeticDatum = i
    super(t)
    this._hash = 0
  }
  equals(e) {
    if (e === this) return true
    if (e instanceof GeodeticReference) {
      const t = this.getAxis(Axis.Name.Z)
      const i = e.getAxis(Axis.Name.Z)
      return (
        this.geodeticDatum.equals(e.geodeticDatum) &&
        this.getAxis(Axis.Name.X).equals(e.getAxis(Axis.Name.X)) &&
        this.getAxis(Axis.Name.Y).equals(e.getAxis(Axis.Name.Y)) &&
        (t ? t.equals(i) : !i)
      )
    } else return false
  }
  toString() {
    return `GeodeticReference[${this.name}]`
  }
  copy() {
    return new GeodeticReference({
      authorityName: this.authorityName,
      authorityCode: this.authorityCode,
      name: this.name,
      geodeticDatum: this.geodeticDatum,
      heightReference: this.heightReference,
      axisInformation: this.axisInformation,
    })
  }
  get bounds() {
    return new LLHBounds(this, [-180, 360, -90, 180])
  }
  get TYPE() {
    return ReferenceType.GEODETIC
  }
  hashCode(e) {
    if (0 === this._hash) {
      const e = new Hash()
      super.hashCode(e)
      this._hash = e.getHashCode()
    }
    e.appendUInt32(this._hash)
  }
}
