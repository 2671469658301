import { isPolyline } from '../shape/Polyline.js'
import { isPolygon } from '../shape/Polygon.js'
import { createBounds, createShapeList } from '../shape/ShapeFactory.js'
import { isPoint } from '../shape/Point.js'
import { getReference } from '../reference/ReferenceProvider.js'
import {
  createTransformation,
  isTransformationRequired,
} from '../transformation/TransformationFactory.js'
import { Log } from '../util/Log.js'
import { interpolateColor } from '../util/Color.js'
import { clamp } from '../util/Cartesian.js'
import { isShapeList } from '../shape/ShapeList.js'
import { isComplexPolygon } from '../shape/ComplexPolygon.js'
const logger = new Log({ avoidDuplicateMessages: true })
export function createEvaluationFunction(e) {
  return new Function(
    FUNCTION_EVALUATORS_PARAMETER_NAME,
    FEATURE_PARAMETER_NAME,
    SHAPE_PARAMETER_NAME,
    e
  )
}
export function defaultFunctionNameForExpectedOutput(e) {
  if ('string' === e) return 'defaultStringEvaluator'
  else if ('number' === e) return 'defaultNumberEvaluator'
  else if ('Shape | null' === e) return 'defaultShapeEvaluator'
  else if ('boolean' === e) return 'defaultBooleanEvaluator'
  return 'defaultBooleanEvaluator'
}
export function returnValueFromDefault(e) {
  if ('defaultStringEvaluator' === e) return ''
  else if ('defaultNumberEvaluator' === e) return '0'
  else if ('defaultBooleanEvaluator' === e) return 'false'
  else if ('defaultShapeEvaluator' === e)
    return `${FEATURE_PARAMETER_NAME}[${SHAPE_PARAMETER_NAME}]`
  return ''
}
export const PRE_DEFINED_FUNCTION_EVALUATORS = {
  defaultStringEvaluator: () => '',
  defaultNumberEvaluator: () => 0,
  defaultBooleanEvaluator: () => false,
  defaultShapeEvaluator: (e) => e.shape,
  interactsWithBbox: interactsWithBbox,
  vertices: vertices,
  Recode: recode,
  Categorize: categorize,
  Interpolate: interpolate,
  strTrim: strTrim,
}
function interactsWithBbox(e, t, o, r, n, i) {
  const l = e.reference
  const s = e.bounds
  if (l && s) {
    let e
    if (t) e = getReference(t)
    else e = l
    const a = createBounds(e, [o, n - o, r, i - r])
    let u
    if (!isTransformationRequired(e, l)) u = a
    else {
      const t = createTransformation(e, l)
      u = createBounds(l)
      t.transformBounds(a, u)
    }
    return u.interacts2D(s)
  } else {
    Log.error('Symbology Encoding Painter: ignoring spatial filter')
    return true
  }
}
function vertices() {
  for (var e = arguments.length, t = new Array(e), o = 0; o < e; o++)
    t[o] = arguments[o]
  const r = t[0].type ? t[0] : t[0].shape
  if (null === r) return null
  const n = []
  addVertices(n, r)
  if (0 === n.length) return null
  else if (1 === n.length) return n[0]
  else return createShapeList(r.reference, n)
}
function addVertices(e, t) {
  if (isShapeList(t))
    for (let o = 0; o < t.shapeCount; o++) addVertices(e, t.getShape(o))
  else if (isComplexPolygon(t))
    for (let o = 0; o < t.polygonCount; o++) addVertices(e, t.getPolygon(o))
  else if (isPolyline(t) || isPolygon(t))
    for (let o = 0; o < t.pointCount; o++) e.push(t.getPoint(o))
  else if (isPoint(t)) e.push(t)
  else if (null !== t.focusPoint) e.push(t.focusPoint)
}
function recode() {
  const e = arguments.length <= 0 ? void 0 : arguments[0]
  for (let t = 1; t < arguments.length; t += 2) {
    const o = t < 0 || arguments.length <= t ? void 0 : arguments[t]
    const r = t + 1 < 0 || arguments.length <= t + 1 ? void 0 : arguments[t + 1]
    if (e === o) return r
  }
  return ''
}
function categorize() {
  for (var e = arguments.length, t = new Array(e), o = 0; o < e; o++)
    t[o] = arguments[o]
  const r = t[0]
  let n = Number.MIN_VALUE
  let i = Number.MIN_VALUE
  for (let e = 1; e < t.length; e += 2) {
    const o = t[e]
    n = i
    i = e + 1 < t.length ? t[e + 1] : Number.MAX_VALUE
    if (r >= n && r <= i) return o
  }
  return ''
}
const possibleInterpolationModes = ['linear', 'cubic', 'cosine']
const possibleInterpolationMethods = ['color', 'numeric']
function interpolate() {
  var e, t
  const o = arguments.length <= 0 ? void 0 : arguments[0]
  const r =
    (e = arguments.length - 1) < 0 || arguments.length <= e
      ? void 0
      : arguments[e]
  const n = possibleInterpolationModes.indexOf(r) > -1
  const i = possibleInterpolationMethods.indexOf(r) > -1
  const l =
    (t = arguments.length - 2) < 0 || arguments.length <= t
      ? void 0
      : arguments[t]
  const s = possibleInterpolationModes.indexOf(l) > -1
  const a = possibleInterpolationMethods.indexOf(l) > -1
  const u = 'linear'
  let f = 'linear'
  if (n) f = r
  else if (s) f = l
  if (f !== u)
    logger.warn(
      `The interpolation mode ${f} that you provided is not supported, it will be replaced with ${u}.`
    )
  let c = 'color'
  if (i) c = r
  else if (a) c = l
  let p = arguments.length - 1
  if (n || i) p--
  if (a || s) p--
  const d = []
  const E = []
  for (let e = 1; e < p; e += 2) {
    d.push(
      Number.parseFloat(e < 0 || arguments.length <= e ? void 0 : arguments[e])
    )
    E.push(e + 1 < 0 || arguments.length <= e + 1 ? void 0 : arguments[e + 1])
  }
  let h = 0
  let g = 0
  for (let e = 0; e < d.length - 1; e++)
    if (o >= d[e]) {
      h = e
      g = (o - d[e]) / (d[e + 1] - d[e])
    }
  g = clamp(g, 0, 1)
  switch (c) {
    case 'numeric': {
      const e = Number.parseFloat(E[h])
      const t = undefined
      return e + g * (Number.parseFloat(E[h + 1]) - e)
    }
    case 'color':
    default:
      return interpolateColor(E[h], E[h + 1], g)
  }
}
function strTrim() {
  const e = undefined
  return (arguments.length <= 0 ? void 0 : arguments[0]).replaceAll(
    '^\\s+|\\s+$',
    ''
  )
}
export const FEATURE_PARAMETER_NAME = 'feature'
export const SHAPE_PARAMETER_NAME = 'shape'
export const FUNCTION_EVALUATORS_PARAMETER_NAME = 'functionEvaluators'
