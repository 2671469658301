import { EditHandle } from '../../EditHandle.js'
import { isDefined, isUndefined } from '../../../../util/Lang.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
export class HelperHandle extends EditHandle {
  constructor(e) {
    super()
    this._useDefaultHelperStyle = isUndefined(e)
    this._helperStyle = isUndefined(e) ? null : e
  }
  getDefaultHelperStyle(e) {
    return e.settings.styles.helperStyle
  }
  getHelperStyle(e) {
    if (this._useDefaultHelperStyle) return this.getDefaultHelperStyle(e)
    return this._helperStyle
  }
  getDrapeTarget(e, t) {
    const r = this.getHelperStyle(e)
    if (r && isDefined(r.drapeTarget)) return r.drapeTarget
    if (r && isDefined(r.draped))
      return r.draped ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
    const l = t.bounds
    const s = undefined
    if ((l && (0 != l.depth || 0 != l.z)) ?? false)
      return DrapeTarget.NOT_DRAPED
    return DrapeTarget.ALL
  }
}
