import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Ellipse } from './Ellipse.js'
import { XYZBounds } from './XYZBounds.js'
import * as XYEllipseUtil from './XYEllipseUtil.js'
import { normalizeAngle } from '../util/Cartesian.js'
export class XYZEllipse extends Ellipse {
  constructor(e, t, r, i, o) {
    super()
    this._reference = this.validateReference(e)
    this._compareReferenceStrict(
      t.reference,
      'XYZEllipse::the center point reference must be equal to the ellipse reference'
    )
    this._center = t.copy()
    if ('number' !== typeof r)
      throw new ProgrammingError('XYZEllipse::cannot create a without a')
    this._semiMajorAxis = r
    if ('number' !== typeof i)
      throw new ProgrammingError('XYZEllipse::cannot create a without b')
    this._semiMinorAxis = i
    if ('number' !== typeof o)
      throw new ProgrammingError(
        'XYZEllipse::cannot create a without rotationAzimuth'
      )
    this._rotationAzimuth = normalizeAngle(o)
  }
  get isGeodetic() {
    return false
  }
  set isGeodetic(e) {
    throw new ProgrammingError('isGeodetic property is not mutable')
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  set coordinateType(e) {
    throw new ProgrammingError('coordinateType property is not mutable')
  }
  get center() {
    return this._center
  }
  set center(e) {
    throw new ProgrammingError('center property is not mutable')
  }
  get a() {
    return this._semiMajorAxis
  }
  set a(e) {
    if (e < 0)
      throw new ProgrammingError(
        'a length should be positive number, but was: ' + e
      )
    this._semiMajorAxis = e
    this._cache.invalidate()
  }
  get b() {
    return this._semiMinorAxis
  }
  set b(e) {
    if (e < 0)
      throw new ProgrammingError(
        'b length should be positive number, but was: ' + e
      )
    this._semiMinorAxis = e
    this._cache.invalidate()
  }
  get rotationAzimuth() {
    return this._rotationAzimuth
  }
  set rotationAzimuth(e) {
    this._rotationAzimuth = normalizeAngle(e)
    this._cache.invalidate()
  }
  copy() {
    return new XYZEllipse(
      this.reference,
      this.center,
      this.a,
      this.b,
      this.rotationAzimuth
    )
  }
  get focusPoint() {
    return this.center
  }
  set focusPoint(e) {
    throw new ProgrammingError('focusPoint property is not mutable')
  }
  get bounds() {
    const e = this
    return this._cache.memoize('cached-bounding-box', function () {
      const t = new XYZBounds(e.reference, [0, 0, 0, 0, 0, 0])
      XYEllipseUtil.calculateBounds(
        e.center,
        e.a,
        e.b,
        90 - e.rotationAzimuth,
        t
      )
      return t
    })
  }
  set bounds(e) {
    throw new ProgrammingError('bounds property is not mutable')
  }
  contains2DCoordinates(e, t) {
    return (
      this.bounds.contains2DCoordinates(e, t) &&
      XYEllipseUtil.contains2D(
        this.center,
        this.a,
        this.b,
        90 - this.rotationAzimuth,
        e,
        t
      )
    )
  }
  interpolate(e, t) {
    return XYEllipseUtil.interpolate(this, e, t)
  }
  updatePoint(e, t, r) {
    return XYEllipseUtil.updatePoint(this, e, t, r)
  }
}
