import { isNumber, isString } from '../util/Lang.js'
import {
  getUnitOfMeasure,
  getUnitOfMeasureBySymbol,
} from './UnitOfMeasureRegistry.js'
import { getQuantityKind } from './QuantityKindRegistry.js'
export const PIXEL_UOM = getUnitOfMeasure('Pixels')
export const METER_UOM = getUnitOfMeasure('Meter')
export const CM_UOM = getUnitOfMeasure('Centimeter')
const VALUE_UNIT_REGEX = /^\s*([+-]?\d+([.,]\d+)?)\s*([^\d\s]+)$/
const IS_ONLY_NUMBER_REGEX = /^\s*(\d+([.,]\d+)?)\s*$/
const LENGTH_KIND = getQuantityKind('Length')
export function isWorldSize(t) {
  return !!t && t !== PIXEL_UOM && isLengthKind(t)
}
export function isLengthKind(t) {
  return (
    !!t && !!t.quantityKind && t.quantityKind.baseQuantityKind === LENGTH_KIND
  )
}
export function parseValueAndUom(t) {
  if (isString(t)) {
    const n = getValueAndUnitSymbolFromString(t)
    if (n) {
      const { symbol: t, value: e } = n
      try {
        const n = undefined
        return { value: e, uom: getUnitOfMeasureBySymbol(t) }
      } catch (n) {
        throw new Error(`Unknown unit symbol "${t}" in string "${e}".`)
      }
    }
    const e = parseFloat(t)
    if (!isNaN(e)) return { value: e, uom: null }
    throw new Error(`Could not parse numeric value or unit from string ${t}`)
  } else return { value: t, uom: null }
}
export function isLengthUom(t) {
  return !!t && t.quantityKind.baseQuantityKind === getQuantityKind('Length')
}
export function isPixelValue(t, n, e) {
  const i = isString(t) && t.indexOf('px') > 0
  const r = e && isString(t) && t.indexOf('%') > 0
  const o = isString(t) && !!t.match(IS_ONLY_NUMBER_REGEX)
  const s = (isNumber(t) || o) && !n
  const u = (isNumber(t) || o) && isPixelUom(n)
  return i || r || s || u
}
function getValueAndUnitSymbolFromString(t) {
  if (isString(t)) {
    const n = t.match(VALUE_UNIT_REGEX)
    if (n && n[1] && n[3]) return { value: Number(n[1]), symbol: n[3] }
  }
  return null
}
function isPixelUom(t) {
  return t === PIXEL_UOM
}
