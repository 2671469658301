import { Photon } from '../../../../gen/photon/photon_painter.js'
import { ShapeType } from '../../../../shape/ShapeType.js'
import { Hash } from '../../../../util/Hash.js'
import { Log } from '../../../../util/Log.js'
import { ObjectReleaseTracker } from '../../../../util/ObjectReleaseTracker.js'
import { PenThreshold } from '../../../shape/generalpath/PenThreshold.js'
import { PhotonCommandUtil } from './PhotonCommandUtil.js'
import { PhotonShapeDiscretizer } from '../PhotonShapeDiscretizer.js'
import { getZStyle } from './ZStyleUtil.js'
function PhotonTrajectoryCommand(e, t, r, o, a, n) {
  this._is3d = e
  this._worldReference = t
  this._shapePainter = r
  this._label = o
  this._maxGLLineWidth = +a
  this._invalidator = n
  this._hash = new Hash()
  this._penThreshold = new PenThreshold(5e4, 5)
}
PhotonTrajectoryCommand.prototype = Object.create(
  ObjectReleaseTracker.prototype
)
PhotonTrajectoryCommand.prototype.constructor = PhotonTrajectoryCommand
PhotonTrajectoryCommand.prototype.canHandle = function (e) {
  return 'shape' === e.type && e.style.isTrajectoryStyle
}
PhotonTrajectoryCommand.prototype.add = function (e) {
  var t = e.feature
  var r = e.shape
  var o = e.style
  var a = e.zStyle
  o.syncWithShapePainter(this._shapePainter, Photon, this._invalidator)
  if (r.type !== ShapeType.POLYLINE) {
    Log.error('Only polylines are supported by the trajectory painter.')
    return
  }
  var n, i, h
  try {
    var s = 0 !== o.getAttributeCount()
    if (
      !(n = PhotonShapeDiscretizer.discretizeLine(r, this._worldReference, {
        lineType: o.lineType,
        zToZero: a.zToZero,
        addFractions: s,
      }))
    )
      return
    var p = (h = n.getPositions()).size / 3
    i = Photon.BufferFactory.createFloat32BufferFromLength(
      p * o.getAttributeCount()
    )
    if (s) o.fillAttributes(t, r, p, n.getFractions()[0], i.typedArray)
    this._shapePainter.addTrajectory(
      e.objectId,
      e.geometryId,
      e.styleId,
      o.renderPassId,
      n,
      i
    )
  } finally {
    h?.release()
    n?.release()
    i?.release()
  }
  return true
}
PhotonTrajectoryCommand.prototype.updateStyle = function (e, t) {
  var r = e.style
  r.syncWithShapePainter(this._shapePainter, Photon, this._invalidator)
  this._shapePainter.updateTrajectoryStyle(
    e.objectId,
    e.geometryId,
    t,
    e.styleId,
    r.renderPassId
  )
}
PhotonTrajectoryCommand.prototype.updateGeometry = function (e, t) {
  var r = e.feature
  var o = e.shape
  var a = e.style
  var n = e.zStyle
  a.syncWithShapePainter(this._shapePainter, Photon, this._invalidator)
  var i, h, s
  try {
    var p = 0 !== a.getAttributeCount()
    if (
      !(i = PhotonShapeDiscretizer.discretizeLine(o, this._worldReference, {
        lineType: a.lineType,
        zToZero: n.zToZero,
        addFractions: p,
      }))
    ) {
      this._shapePainter.removeGeometry(e.objectId, t, e.styleId)
      return
    }
    var y = (s = i.getPositions()).size / 3
    h = Photon.BufferFactory.createFloat32BufferFromLength(
      y * a.getAttributeCount()
    )
    if (p) a.fillAttributes(r, o, y, i.getFractions()[0], h.typedArray)
    this._shapePainter.updateTrajectoryGeometry(
      e.objectId,
      t,
      e.geometryId,
      i,
      h
    )
  } finally {
    s?.release()
    i?.release()
    h?.release()
  }
  return true
}
var uniqueCounter = 0
PhotonTrajectoryCommand.prototype._getGeometryId = function (e, t, r) {
  this._hash.reset()
  e.hashCode(this._hash)
  if (e.reference) e.reference.hashCode(this._hash)
  this._hash.append(uniqueCounter)
  uniqueCounter += r ? 0 : 1
  this._hash.appendUInt32(t.lineType)
  this._hash.appendUInt32(t.drapeTarget)
  this._hash.appendBoolean(t.lineWidth > this._maxGLLineWidth)
  this._hash.append(t.id)
  return this._hash.getHashCode()
}
PhotonTrajectoryCommand.prototype.resolveStyle = function (e) {}
PhotonTrajectoryCommand.prototype.getDrawItems = function (e) {
  var t = []
  for (var r = 0; r < e.length; r++) {
    var o = e[r].shape
    var a = e[r].style
    t.push({
      shape: o,
      style: a,
      geometryId: this._getGeometryId(o, a),
      styleId: this._getStyleId(a),
      zStyle: getZStyle(o, a, this._is3d, this._worldReference),
    })
  }
  return t
}
PhotonTrajectoryCommand.prototype._getStyleId = function (e) {
  return e.id
}
PhotonTrajectoryCommand.prototype.usesPhotonShapePainter = function () {
  return true
}
export { PhotonTrajectoryCommand }
