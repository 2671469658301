import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { WFSVersions } from '../../WFSVersions.js'
import { OGCEncoder110 } from './OGCEncoder110.js'
export class OGCEncoder200 extends OGCEncoder110 {
  constructor() {
    let r
    super(
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : WFSVersions.V200,
      'http://www.opengis.net/fes/2.0',
      'http://www.opengis.net/gml/3.2'
    )
  }
  visitPropertyName(r) {
    this.xmlWriter.beginNode('ValueReference')
    this.xmlWriter.value(r.namePath.toXPathString())
    for (const t in r.nameSpaces)
      if (r.nameSpaces.hasOwnProperty(t))
        this.nameSpaces.set(t, r.nameSpaces[t])
    this.xmlWriter.endNode()
  }
  visitIdentifiers(r) {
    const t = this.xmlWriter
    e(r.gmlObjectIds)
    e(r.featureIds)
    function e(r) {
      if (r && r.length) r.forEach(i)
    }
    function i(r) {
      t.beginNode('ResourceId')
      t.attribute('rid', r)
      t.endNode()
    }
  }
  visitAddOperator(r) {
    failOnArithmeticOperator(r)
  }
  visitSubOperator(r) {
    failOnArithmeticOperator(r)
  }
  visitMulOperator(r) {
    failOnArithmeticOperator(r)
  }
  visitDivOperator(r) {
    failOnArithmeticOperator(r)
  }
  encodeMatchAction(r) {
    if (r) this.xmlWriter.attribute('matchAction', String(r))
  }
}
function failOnArithmeticOperator(r) {
  throw new ProgrammingError(
    'Cannot use condition with a binary operator (' +
      r.TYPE +
      ') in OGC Filter 2.0: these are only supported in OGC Filter 1.1.'
  )
}
