import { Label } from '../style/Label.js'
import { isNumber } from '../../util/Lang.js'
export class LabelNode extends Label {
  constructor(t, i, s, h, r, e, o) {
    super(h, r, e, o)
    this.x = t
    this.y = i
    this.rotation = 0
    this.backpointer = s
    this.valid = true
    this.nx = 0
    this.ny = 0
    this.ax = 0
    this.ay = 0
    this.w = 0
    this.h = 0
    this.next = null
    this.previous = null
    this.zOrder = null
  }
  modifyPosition(t, i, s, h, r, e, o, n) {
    this.nx = t
    this.ny = i
    this.rotation = s
    this.ax = e
    this.ay = o
    this.w = h
    this.h = r
    if (isNumber(n)) this.zOrder = n
  }
}
