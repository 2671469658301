import { WMSCapabilitiesParser } from './WMSCapabilitiesParser.js'
import { Lang } from '../../util/Lang.js'
import { XMLSchema } from '../../util/XMLSchema.js'
import {
  getReference,
  isValidReferenceIdentifier,
} from '../../reference/ReferenceProvider.js'
import { WMSCapabilitiesSchemaV111 } from './WMSCapabilitiesSchemaV111.js'
const DEFAULT_REF = getReference('EPSG:4326')
class WMSCapabilitiesParserV111 extends WMSCapabilitiesParser {
  constructor(e) {
    const i = undefined
    super(new XMLSchema(WMSCapabilitiesSchemaV111).parse(e), [
      'WMT_MS_Capabilities',
      'WMS_Capabilities',
    ])
  }
  _parseLayerScaleDenominator(e) {
    if (!Lang.isDefined(e.ScaleHint)) return null
    return { min: e.ScaleHint.min, max: e.ScaleHint.max }
  }
  _hasWGS84Bounds(e) {
    return Lang.isDefined(e.LatLonBoundingBox)
  }
  _parseWGS84Bounds(e) {
    return this._parseBounds(
      Object.assign({ SRS: 'EPSG:4326' }, e.LatLonBoundingBox)
    )
  }
  _hasBoundsList(e) {
    return Lang.isDefined(e.LatLonBoundingBox) || Lang.isDefined(e.BoundingBox)
  }
  _parseBoundsList(e) {
    let i = []
    const n =
      (e.BoundingBox || []).filter(function (e) {
        return (
          isValidReferenceIdentifier(e.SRS) &&
          getReference(e.SRS).equals(DEFAULT_REF)
        )
      }).length > 0
    if (Lang.isDefined(e.LatLonBoundingBox) && !n)
      i.push(Object.assign({ SRS: 'EPSG:4326' }, e.LatLonBoundingBox))
    if (Lang.isDefined(e.BoundingBox)) i = i.concat(e.BoundingBox)
    return i.map(this._parseBounds, this).filter(Lang.isDefined)
  }
}
WMSCapabilitiesParserV111.prototype._referenceFieldName = 'SRS'
WMSCapabilitiesParserV111.prototype._swapAxes = false
export { WMSCapabilitiesParserV111 }
