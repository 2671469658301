import { NotImplementedError } from '../error/NotImplementedError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { CartesianReference } from '../reference/CartesianReference.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { GeoReference } from '../reference/GeoReference.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
import { isNumber, isUndefined } from '../util/Lang.js'
export class ShapeList extends Shape {
  constructor(e, t) {
    super()
    if ('undefined' === typeof e || null === e) this._reference = null
    else if (e instanceof GeoReference || e instanceof CartesianReference)
      this._reference = e
    else
      throw new ProgrammingError(
        `Shapelist::may not create a shapelist with this reference: ${e}`
      )
    this.geometries = []
    this._bounds = null
    t &&
      t.forEach((e, t) => {
        this.addShape(t, e)
      })
  }
  get type() {
    return ShapeType.SHAPE_LIST
  }
  removeShape(e) {
    ShapeUtil.validateIndexForRemovalOrBounce(e, this.geometries)
    this.geometries.splice(e, 1)
    this.invalidate()
  }
  copy() {
    const e = new ShapeList(this.reference)
    this.geometries.forEach((t, r) => {
      e.addShape(r, t.copy())
    })
    return e
  }
  getShape(e) {
    return this.geometries[e]
  }
  get shapeCount() {
    return this.geometries.length
  }
  get focusPoint() {
    if (0 === this.geometries.length) return null
    if (isUndefined(this._focus))
      this._focus = this.bounds ? this.bounds.focusPoint : null
    return this._focus
  }
  get bounds() {
    const e = undefined
    if (0 === this.geometries.length) return null
    if (isUndefined(this._bounds))
      this._bounds = this.geometries.reduce((e, t) => {
        if (!t.bounds) return e
        if (!e) return t.bounds.copy()
        e.setTo3DUnion(t.bounds)
        return e
      }, null)
    return this._bounds
  }
  addShape(e, t) {
    let r, i
    if (2 === arguments.length && isNumber(e)) {
      r = e
      i = t
    } else {
      r = this.shapeCount
      i = e
    }
    this._compareReferenceStrict(i.reference)
    ShapeUtil.validateIndexForInsertionOrBounce(r, this.geometries)
    this.geometries.splice(r, 0, i)
    this.invalidate()
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  equals(e) {
    if (e === this) return true
    if (e.type != this.type) return false
    if (
      e.shapeCount !== this.shapeCount ||
      !ShapeUtil.referencesEqual(this.reference, e.reference)
    )
      return false
    return this.geometries.every((t, r) => e.getShape(r).equals(t))
  }
  contains2DCoordinates(e, t) {
    if (!this.bounds || !this.bounds.contains2DCoordinates(e, t)) return false
    let r
    let i
    const s = this.shapeCount
    for (r = 0; r < s; r++) {
      i = this.getShape(r)
      if (i.contains2DCoordinates(e, t)) return true
    }
    return false
  }
  invalidate() {
    this._bounds = void 0
    this._focus = void 0
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    for (let t = 0, r = this.shapeCount; t < r; t++)
      this.getShape(t).hashCode(e)
  }
  toString() {
    let e = 'ShapeList['
    for (let t = 0, r = this.shapeCount; t < r; t++) {
      e += this.getShape(t).toString()
      if (t + 1 < r) e += ','
    }
    e += ']'
    return e
  }
  translate2D(e, t) {
    for (let r = 0, i = this.shapeCount; r < i; r++)
      this.getShape(r).translate2D(e, t)
    this.invalidate()
  }
  contains3DCoordinates(e, t, r) {
    throw new NotImplementedError()
  }
  get isGeodetic() {
    throw new NotImplementedError()
  }
}
export function isShapeList(e) {
  return e && e.type === ShapeType.SHAPE_LIST
}
