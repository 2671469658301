import { FeaturePainter } from './FeaturePainter.js'
import { TrajectoryPainterImpl } from './photon/TrajectoryPainterImpl.js'
class ParameterizedLinePainter extends FeaturePainter {
  constructor(e) {
    super()
    this._impl = new TrajectoryPainterImpl(e)
  }
  get propertyColorExpressions() {
    return this._impl.propertyColorExpressions
  }
  set propertyColorExpressions(e) {
    this._impl.propertyColorExpressions = e
  }
  get defaultColor() {
    return this._impl.defaultColor
  }
  set defaultColor(e) {
    this._impl.defaultColor = e
  }
  get selectionColor() {
    return this._impl.selectionColor
  }
  set selectionColor(e) {
    this._impl.selectionColor = e
  }
  get rangeColorMap() {
    return this._impl.rangeColorMap
  }
  set rangeColorMap(e) {
    this._impl.rangeColorMap = e
  }
  get rangeWindow() {
    return this._impl.rangeWindow
  }
  set rangeWindow(e) {
    this._impl.rangeWindow = e
  }
  get density() {
    return super.density
  }
  set density(e) {
    super.density = e
    this._impl.density = e
  }
  paintBody(e, r, t, o, i, s) {
    this._impl.paintBody(e, r, t, o, i, s)
  }
}
export { ParameterizedLinePainter }
