import { ProgrammingError } from '../../../error/ProgrammingError.js'
var INITIAL_SIZE = 128
function DoubleEndedQueue() {
  this._data = new Array(INITIAL_SIZE)
  this._offset = Math.round(this._data.length / 4)
  this._length = 0
}
DoubleEndedQueue.prototype = Object.create(Object.prototype)
DoubleEndedQueue.prototype.constructor = DoubleEndedQueue
DoubleEndedQueue.prototype._reallocate = function () {
  var t = new Array(Math.max(2 * this._length, INITIAL_SIZE))
  var e = Math.round(t.length / 4)
  for (var o = 0; o < this._length; o++) t[e + o] = this._data[this._offset + o]
  this._offset = e
  this._data = t
}
Object.defineProperty(DoubleEndedQueue.prototype, 'length', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._length
  },
  set: function () {
    throw new ProgrammingError('length property is not mutable')
  },
})
Object.defineProperty(DoubleEndedQueue.prototype, 'capacity', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._data.length
  },
  set: function () {
    throw new ProgrammingError('capacity property is not mutable')
  },
})
DoubleEndedQueue.prototype.unshift = function (t) {
  if (0 === this._offset) this._reallocate()
  this._offset--
  this._length++
  this._data[this._offset] = t
  return this._length
}
DoubleEndedQueue.prototype.shift = function () {
  var t = this._data[this._offset]
  this._offset++
  this._length--
  if (0 === this._length) this._offset = Math.round(this._data.length / 4)
  return t
}
DoubleEndedQueue.prototype.push = function (t) {
  if (this._offset + this._length === this._data.length) this._reallocate()
  this._data[this._offset + this._length] = t
  this._length++
  return this._length
}
DoubleEndedQueue.prototype.pop = function () {
  var t = this._data[this._offset + this._length - 1]
  this._length--
  if (0 === this._length) this._offset = Math.round(this._data.length / 4)
  return t
}
DoubleEndedQueue.prototype.set = function (t, e) {
  this._data[t + this._offset] = e
}
DoubleEndedQueue.prototype.get = function (t) {
  return this._data[t + this._offset]
}
DoubleEndedQueue.prototype.splice = function (t, e) {
  var o = this._data.splice(t + this._offset, e)
  this._length -= e
  return o
}
DoubleEndedQueue.prototype.indexOf = function (t) {
  for (var e = this._offset; e < this._offset + this._length; e++)
    if (this._data[e] === t) return e - this._offset
  return -1
}
if ([].copyWithin)
  DoubleEndedQueue.prototype.copyWithin = function (t, e, o) {
    this._data.copyWithin(t + this._offset, e + this._offset, o + this._offset)
  }
DoubleEndedQueue.prototype.forEach = function (t, e) {
  for (var o = 0; o < this._length; o++)
    t.call(e, this._data[this._offset + o], this._offset + o, this)
}
DoubleEndedQueue.prototype.toString = function () {
  var t = '['
  for (var e = 0; e < this._length; e++) {
    t += this._data[this._offset + e]
    if (e !== this._length - 1) t += ','
  }
  return (t += ']')
}
DoubleEndedQueue.prototype.toArray = function () {
  var t = new Array(this._length)
  for (var e = 0; e < this._length; e++) t[e] = this._data[this._offset + e]
  return t
}
DoubleEndedQueue.prototype.toFront = function (t) {
  var e = this.indexOf(t)
  if (0 === e) return
  if (e < 0) throw new Error('toFront: element ' + t + ' not in list!')
  if (this.copyWithin) this.copyWithin(1, 0, e)
  else for (var o = e; o > 0; o--) this.set(o, this.get(o - 1))
  this.set(0, t)
}
export { DoubleEndedQueue }
