const INSIDE = 0,
  TOP = 1,
  RIGHT = 2,
  BOTTOM = 4,
  LEFT = 8,
  TOP_LEFT = TOP | LEFT,
  TOP_RIGHT = TOP | RIGHT,
  BOTTOM_LEFT = BOTTOM | LEFT,
  BOTTOM_RIGHT = BOTTOM | RIGHT
export const Clipping = {
  MASK: { INNER: 1, OUTER: 2 },
  INSIDE: INSIDE,
  TOP: TOP,
  RIGHT: RIGHT,
  LEFT: LEFT,
  BOTTOM: BOTTOM,
  TOP_LEFT: TOP_LEFT,
  TOP_RIGHT: TOP_RIGHT,
  BOTTOM_LEFT: BOTTOM_LEFT,
  BOTTOM_RIGHT: BOTTOM_RIGHT,
  computeRegion: function (T, O, n, t, i, e) {
    if (T < n) {
      if (O < e) return BOTTOM_LEFT
      if (O > t) return TOP_LEFT
      return LEFT
    }
    if (T > i) {
      if (O < e) return BOTTOM_RIGHT
      if (O > t) return TOP_RIGHT
      return RIGHT
    }
    if (O < e) return BOTTOM
    if (O > t) return TOP
    return INSIDE
  },
  isOutside: function (T) {
    return 0 !== T
  },
  isInside: function (T) {
    return 0 === T
  },
  computeClipMaskBoundsArrays: function (T, O) {
    const n = T.x,
      t = T.y,
      i = n + T.width,
      e = t + T.height,
      r = O.x,
      u = O.y,
      I = r + O.width,
      E = u + O.height
    return this.computeClipMask(n, t, i, e, r, u, I, E)
  },
  computeClipMask: function (T, O, n, t, i, e, r, u) {
    const I = undefined
    const E = undefined
    const o = undefined
    const M = undefined
    return (
      (((n - (i - 5)) >>> 31) & 1) |
      (((r + 5 - T) >>> 30) & 2) |
      (((t - (e - 5)) >>> 29) & 4) |
      (((u + 5 - O) >>> 28) & 8)
    )
  },
}
