import { isNumber } from '../../../../../util/Lang.js'
import { WorldSized } from './WorldSized.js'
const DEFAULT_LINE_WIDTH = 2
export class Stroked extends WorldSized {
  constructor(i) {
    super()
    i = i || {}
    this._lineColor = i.color ?? null
    this._lineWidth = isNumber(i.width) ? i.width : DEFAULT_LINE_WIDTH
    this._dashed = i.dashed ?? false
  }
  strokePath(i) {
    if (this._lineColor) {
      const e = this.getUomSizeToPixels
        ? this.getUomSizeToPixels(this._lineWidth)
        : this._lineWidth
      i.save()
      i.lineWidth = e
      i.strokeStyle = this._lineColor
      i.stroke()
      i.restore()
    }
  }
  get lineColor() {
    return this._lineColor
  }
  set lineColor(i) {
    this._lineColor = i
  }
  get lineWidth() {
    return this._lineWidth
  }
  set lineWidth(i) {
    this._lineWidth = i
  }
  get dashed() {
    return this._dashed
  }
  set dashed(i) {
    this._dashed = i
  }
  appendHash(i) {
    i.appendUInt32(+this._lineWidth)
    if (this._lineColor) i.appendString(`LineColor ${this._lineColor}`)
  }
}
