import { Axis } from '../reference/Axis.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Transformation } from './Transformation.js'
import { TransformationType } from './TransformationType.js'
function getBaseQuantityKind(t) {
  return t.unitOfMeasure.quantityKind.baseQuantityKind
}
function sameBaseQuantityKind(t, n) {
  return getBaseQuantityKind(t) === getBaseQuantityKind(n)
}
function sameAxisDirection(t, n) {
  return t.direction === n.direction
}
function identity(t) {
  return t
}
export function areCompatibleForScaleTransformation(t, n) {
  if (t.equals(n)) return true
  const e = t.getAxis(Axis.Name.X)
  const i = t.getAxis(Axis.Name.Y)
  const s = t.getAxis(Axis.Name.Z)
  const r = n.getAxis(Axis.Name.X)
  const o = n.getAxis(Axis.Name.Y)
  const a = n.getAxis(Axis.Name.Z)
  return (
    sameBaseQuantityKind(e, r) &&
    sameBaseQuantityKind(i, o) &&
    sameAxisDirection(e, r) &&
    sameAxisDirection(i, o) &&
    ((!s && !a) || sameBaseQuantityKind(s, a)) &&
    ((!s && !a) || sameAxisDirection(s, a))
  )
}
function createAxisConversionFunction(t, n) {
  if ('undefined' === typeof t || null === t) return identity
  const e = t.unitOfMeasure
  const i = n.unitOfMeasure
  if (e.equals(i)) return identity
  else return (t) => e.convertToUnit(t, i)
}
function createConversionFunction(t, n) {
  const e = createAxisConversionFunction(
    t.getAxis(Axis.Name.X),
    n.getAxis(Axis.Name.X)
  )
  const i = createAxisConversionFunction(
    t.getAxis(Axis.Name.Y),
    n.getAxis(Axis.Name.Y)
  )
  const s = createAxisConversionFunction(
    t.getAxis(Axis.Name.Z),
    n.getAxis(Axis.Name.Z)
  )
  return (t, n, r, o) => {
    o.x = e(t)
    o.y = i(n)
    o.z = s(r)
    return o
  }
}
export class ScaledTransformation extends Transformation {
  constructor(t, n) {
    super(t, n)
    if (!areCompatibleForScaleTransformation(t, n))
      throw new ProgrammingError('References are not compatible.')
    this.__forwardConvert = createConversionFunction(t, n)
    this.__inverseConvert = createConversionFunction(n, t)
    this._tmpIn = createPoint(this._inputReference, [0, 0, 0])
    this._tmpOut = createPoint(this._outputReference, [0, 0, 0])
  }
  _forward(t, n) {
    return this.__forwardConvert(t.x, t.y, t.z, n)
  }
  _inverse(t, n) {
    return this.__inverseConvert(t.x, t.y, t.z, n)
  }
  _forwardBoundsCoords(t, n) {
    const e = this._tmpIn
    const i = this._tmpOut
    e.x = t.x
    e.y = t.y
    e.z = t.z
    this._forward(e, i)
    const s = i.x
    const r = i.y
    const o = i.z
    e.x = t.x + t.width
    e.y = t.y + t.height
    e.z = t.z + t.depth
    this._forward(e, i)
    const a = i.x
    const c = i.y
    const m = i.z
    const u = Math.min(s, a)
    const x = Math.min(r, c)
    const f = Math.min(o, m)
    n.setTo3D(
      u,
      Math.max(s, a) - u,
      x,
      Math.max(r, c) - x,
      f,
      Math.max(o, m) - f
    )
  }
  _inverseBoundsCoords(t, n) {
    const e = this._tmpOut
    const i = this._tmpIn
    e.x = t.x
    e.y = t.y
    e.z = t.z
    this._inverse(e, i)
    const s = i.x
    const r = i.y
    const o = i.z
    e.x = t.x + t.width
    e.y = t.y + t.height
    e.z = t.z + t.depth
    this._inverse(e, i)
    const a = i.x
    const c = i.y
    const m = i.z
    const u = Math.min(s, a)
    const x = Math.min(r, c)
    const f = Math.min(o, m)
    n.setTo3D(
      u,
      Math.max(s, a) - u,
      x,
      Math.max(r, c) - x,
      f,
      Math.max(o, m) - f
    )
  }
  _getType() {
    return TransformationType.TYPE_SCALE
  }
}
