import { DrawingContext } from './DrawingContext.js'
export class CompositeDrawingContext extends DrawingContext {
  constructor(t, e, n, o, s, i) {
    super(t, e, n, o, s)
    this._contexts = i
  }
  paintDrawingContext(t) {
    const e = this.surface.getContext2d()
    if (this.valid)
      for (const n of this._contexts) n.collectLabelsInContext(e, t)
    else {
      this.valid = true
      this.surface.clear()
      for (const n of this._contexts) n.paintOnDifferentContext(e, t)
    }
  }
}
