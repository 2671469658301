import { XYZPoint } from '../shape/XYZPoint.js'
import { distanceToGeodesic } from '../geodesy/SphereUtil.js'
import { Constants } from '../util/Constants.js'
import { distance2D } from '../util/Cartesian.js'
export function geodesicPointOnPositiveSide(n, t, e, o) {
  const i = Constants.RAD2DEG * o.forwardAzimuth2D(t, e)
  let s = Constants.RAD2DEG * o.forwardAzimuth2D(t, n)
  s -= i
  if (s < 0) s += 360
  return s < 180
}
export function geodesicSignedDistanceToLine(n, t, e, o) {
  const i =
    Constants.EARTH_RADIUS * Constants.DEG2RAD * distanceToGeodesic(t, e, n, 90)
  if (geodesicPointOnPositiveSide(n, t, e, o)) return isNaN(i) ? 0 : i
  else return isNaN(i) ? 0 : -1 * i
}
export function cartesianClosestPointOnLineSFCT(n, t, e, o) {
  const i = n.x
  const s = n.y
  const r = n.z
  const c = t.x
  const a = t.y
  const d = t.z
  const u = undefined
  const f = undefined
  const x = undefined
  const D = undefined
  const P =
    ((e.x - i) * (c - i) + (e.y - s) * (a - s) + (e.z - r) * (d - r)) /
    ((c - i) * (c - i) + (a - s) * (a - s) + (d - r) * (d - r))
  o.move3DToCoordinates(i + P * (c - i), s + P * (a - s), r + P * (d - r))
  return P
}
export function cartesianPointOnPositiveSide(n, t, e) {
  const o = t.y - e.y
  const i = e.x - t.x
  const s = (e.y - t.y) * t.x - (e.x - t.x) * t.y
  return o * n.x + i * n.y + s < 0
}
export function cartesianSignedDistanceToLine(n, t, e) {
  const o = new XYZPoint(n.reference)
  cartesianClosestPointOnLineSFCT(t, e, n, o)
  const i = distance2D(n, o)
  if (cartesianPointOnPositiveSide(n, t, e)) return i
  else return -1 * i
}
