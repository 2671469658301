import { Controller } from './Controller.js'
import { HandleEventResult } from './HandleEventResult.js'
import { ModifierType } from '../input/ModifierType.js'
import { expOut } from '../../util/Easing.js'
import { GestureEventType } from '../input/GestureEventType.js'
import { AnimationManager } from '../animation/AnimationManager.js'
import { isDefined, isNumber } from '../../util/Lang.js'
const DEFAULT_ZOOM_ANIM_DURATION = 250
const BASE_ZOOM_FACTOR = 2
const MIN_SCALE = 5291666666666667e-26
const ZOOM_PRECISION_MODIFIER = 0.1
const RIGHT_MOUSE_BUTTON = 2
const DOUBLE_CLICK_ZOOM_FACTOR = 2
export class ZoomController extends Controller {
  constructor() {
    super()
    this._wheelRotationWithinZoom = 0
    this._startScale = [0, 0]
    this._incrementalZooming = false
  }
  onGestureEvent(t) {
    if (!this.map) return HandleEventResult.EVENT_IGNORED
    const e = this.isZoomEvent(t)
    const n = this.isZoomEndEvent(t)
    const o = e && !n
    const i = e && n && this._incrementalZooming
    const r = undefined
    if (e && n && !this._incrementalZooming) {
      const e = this.getCurrentZoomAnimation()
      if (!e) this._startScale = this.map.mapScale
      const n = this.normalizeZoomFactor(this.getZoomFactor(t))
      if (1 === n.x && 1 === n.y) return HandleEventResult.EVENT_HANDLED
      const o = {
        x: Math.max(MIN_SCALE, this._startScale[0] * n.x),
        y: Math.max(MIN_SCALE, this._startScale[1] * n.y),
      }
      if (e && this.map.mapNavigator._extendZoomAnimation)
        this.map.mapNavigator._extendZoomAnimation(e, n.x)
      else {
        const e = this.getZoomTarget(t)
        if (!e) return HandleEventResult.EVENT_IGNORED
        const i = this.isSnapToScaleLevels(t)
        const r = n.x > 1 ? Math.ceil : Math.floor
        const s = this.getZoomAnimationOptions(t)
        this.map.mapNavigator
          .zoom({
            targetScale: o,
            location: e,
            snapToScaleLevels: i,
            snapRoundFunction: r,
            animate: s,
          })
          .catch(() => {})
      }
      return HandleEventResult.EVENT_HANDLED
    }
    if (o) {
      if (!this._incrementalZooming) {
        const e = this.getZoomTarget(t)
        if (!e) return HandleEventResult.EVENT_IGNORED
        this._incrementalZooming = true
        if (null === e.reference) this.map.mapNavigator.beginZoom(e.x, e.y)
        else this.map.mapNavigator.beginZoomWorld(e)
      }
      const e = this.normalizeZoomFactor(this.getZoomFactor(t))
      this.map.mapNavigator.incrementalZoom(e.x, e.y)
      return HandleEventResult.EVENT_HANDLED
    }
    if (i) {
      this.map.mapNavigator.endZoom()
      this._incrementalZooming = false
      return HandleEventResult.EVENT_HANDLED
    }
    return HandleEventResult.EVENT_IGNORED
  }
  getZoomTarget(t) {
    return t.viewPoint
  }
  getZoomFactor(t) {
    let e = 1
    if (t.type === GestureEventType.SCROLL) {
      const n = undefined
      let o = t.amount
      const i = undefined
      o *= t.modifier === ModifierType.SHIFT ? ZOOM_PRECISION_MODIFIER : 1
      if (this.getCurrentZoomAnimation()) this._wheelRotationWithinZoom += o
      else this._wheelRotationWithinZoom = o
      e = Math.pow(BASE_ZOOM_FACTOR, this._wheelRotationWithinZoom)
    }
    if (t.type === GestureEventType.DOUBLE_CLICK) {
      e = DOUBLE_CLICK_ZOOM_FACTOR
      if ('mouse' === t.inputType) {
        const n = undefined
        if (t.domEvent.button === RIGHT_MOUSE_BUTTON) e = 1 / e
      }
    }
    if (
      t.type === GestureEventType.PINCH ||
      t.type === GestureEventType.PINCH_END
    ) {
      const n = undefined
      const o = t.scaleFactorFromStart
      if (
        o > 0 &&
        !isNaN(o) &&
        !(o === Number.POSITIVE_INFINITY || o === Number.NEGATIVE_INFINITY)
      )
        e = o
    }
    return { x: e, y: e }
  }
  getZoomAnimationOptions(t) {
    if (
      t.type === GestureEventType.PINCH ||
      t.type == GestureEventType.PINCH_END
    )
      return false
    return { duration: DEFAULT_ZOOM_ANIM_DURATION, ease: expOut }
  }
  isZoomEvent(t) {
    return (
      t.type === GestureEventType.SCROLL ||
      t.type === GestureEventType.DOUBLE_CLICK ||
      t.type === GestureEventType.PINCH ||
      t.type === GestureEventType.PINCH_END
    )
  }
  isZoomEndEvent(t) {
    return (
      t.type === GestureEventType.SCROLL ||
      t.type === GestureEventType.DOUBLE_CLICK ||
      t.type === GestureEventType.PINCH_END
    )
  }
  isSnapToScaleLevels(t) {
    return t.modifier === ModifierType.CTRL
  }
  getCurrentZoomAnimation() {
    if (!this.map) return null
    const t = AnimationManager.getAnimation(this.map.cameraAnimationKey)
    if (isDefined(t) && t._id && 'zoom' === t._id) return t
    return null
  }
  normalizeZoomFactor(t) {
    return { x: isNumber(t) ? t : t.x, y: isNumber(t) ? t : t.y }
  }
}
