import { NoBoundsError } from '../../error/NoBoundsError.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { CoordinateType } from '../../reference/CoordinateType.js'
import { XYZBounds } from '../../shape/XYZBounds.js'
import { Log } from '../../util/Log.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { PickInfoImpl } from '../PickInfo.js'
import { HTML5GeoCanvas } from '../style/HTML5GeoCanvas.js'
import { HTML5LabelCanvas } from '../style/HTML5LabelCanvas.js'
import { TouchDistance } from '../style/TouchDistance.js'
import { getDetailLevel } from './FeatureLayerRendererUtils.js'
import { NonSelectedFeatureRenderer } from './NonSelectedFeatureRenderer.js'
import { SelectedFeatureRenderer } from './SelectedFeatureRenderer.js'
const paddingInWorldOut = [0, 0]
const tmpPixelPadding = [0, 0]
const bndsCoordsOut = [0, 0, 0, 0]
function geoCanvasDrawShapeDisabled() {
  throw new ProgrammingError(
    'Drawing shapes in a border with drawShape is not supported'
  )
}
function labelCanvasDrawLabelInPathDisabled() {
  throw new ProgrammingError(
    'Drawing labels in a border with drawLabelInPath is not supported'
  )
}
function labelCanvasDrawLabelOnPathDisabled() {
  throw new ProgrammingError(
    'Drawing labels in a border with drawLabelOnPath is not supported'
  )
}
const tempStateObject = { selected: false, level: 0 }
export class FeatureLayerRenderer {
  constructor(e, t, n) {
    this._bodyPaintRepresentation = t
    this._labelPaintRepresentation = n
    this._map = e.map
    this._layer = e.layer
    this._incrementalDrawing = !!e.incremental
    this._setPainter(e.painter)
    this._layer.workingSet.on('WorkingSetChanged', (e, t, n) => {
      if ('update' === e) this._invalidateFeature(t)
    })
  }
  isReady() {
    return (
      this._nonSelectedRenderer.isReady() && this._selectedRenderer.isReady()
    )
  }
  _setPainter(e) {
    this._featurePainter = e
    this._setupRenderers()
  }
  getMaxWorldMargin() {
    return Math.max(
      0,
      this._selectedRenderer.getMaxWorldMargin(),
      this._nonSelectedRenderer.getMaxWorldMargin()
    )
  }
  getMaxPixelPadding() {
    return Math.max(
      0,
      this._selectedRenderer.getMaxPixelPadding(),
      this._nonSelectedRenderer.getMaxPixelPadding()
    )
  }
  _setupRenderers() {
    if (!this._map)
      throw new ProgrammingError('Layer should be attached to Map')
    const e = new HTML5GeoCanvas({
      invalidateAction: this._layer._invalidate.bind(this._layer),
      worldReference: this._map.reference,
      worldSizeSupport: this._map.worldSizeSupport,
    })
    const t = new HTML5LabelCanvas({
      invalidateAction: this._layer._invalidate.bind(this._layer),
    })
    if (
      this._bodyPaintRepresentation ===
        PaintRepresentation.BOTTOM_BORDER_BODY ||
      this._bodyPaintRepresentation === PaintRepresentation.LEFT_BORDER_BODY
    )
      e.drawShape = geoCanvasDrawShapeDisabled
    if (
      this._labelPaintRepresentation ===
        PaintRepresentation.BOTTOM_BORDER_LABEL ||
      this._labelPaintRepresentation === PaintRepresentation.LEFT_BORDER_LABEL
    ) {
      t.drawLabelInPath = labelCanvasDrawLabelInPathDisabled
      t.drawLabelOnPath = labelCanvasDrawLabelOnPathDisabled
    }
    this._nonSelectedRenderer = new NonSelectedFeatureRenderer(
      this._layer,
      e,
      t,
      this._featurePainter,
      this._incrementalDrawing,
      this._bodyPaintRepresentation,
      this._labelPaintRepresentation
    )
    this._selectedRenderer = new SelectedFeatureRenderer(
      this._layer,
      e,
      t,
      this._featurePainter,
      this._incrementalDrawing,
      this._bodyPaintRepresentation,
      this._labelPaintRepresentation
    )
  }
  _padWorldBounds(e) {
    const t = this.getMaxPixelPadding()
    tmpPixelPadding[0] = t
    tmpPixelPadding[1] = t
    this._map.mapToViewTransformationInternal.toWorldDistance(
      tmpPixelPadding,
      paddingInWorldOut
    )
    const n = this.getMaxWorldMargin()
    paddingInWorldOut[0] += n
    paddingInWorldOut[1] -= n
    bndsCoordsOut[0] = e.x - paddingInWorldOut[0]
    bndsCoordsOut[1] = e.width + 2 * paddingInWorldOut[0]
    bndsCoordsOut[2] = e.y - Math.abs(paddingInWorldOut[1])
    bndsCoordsOut[3] = e.height + 2 * Math.abs(paddingInWorldOut[1])
    return new XYZBounds(e.reference, bndsCoordsOut)
  }
  pick(e) {
    const t = e.getWorldBounds()
    const n = this._padWorldBounds(t)
    const r = !e.intersectGeometry
    const a = n.x + n.width / 2
    const i = n.y + n.height / 2
    const s = this
    const o = []
    let d
    try {
      d =
        this._layer.modelToWorldTransformation.inverseTransformation.transformBounds(
          n
        )
    } catch (e) {
      if (e instanceof NoBoundsError) return o
      else throw e
    }
    function l(e) {
      const n = new TouchDistance()
      const d = e.getDrawCommandForPaintRepresentation(PaintRepresentation.BODY)
      if (r || s.interacts(e, t)) {
        try {
          if (null !== d) d.getMapDistanceSquared(s._map, a, i, n)
        } catch (e) {}
        if (isNaN(n.sqrDistanceToCenter)) return
        const r = s.getMaximumInteractionZOrder(e, t)
        const l = new PickInfoImpl(
          s._layer,
          e.feature,
          n.sqrDistanceToEdge,
          n.sqrDistanceToCenter,
          0,
          r,
          e.selected,
          false
        )
        o.push(l)
      }
    }
    this.search(d, l)
    return o
  }
  search(e, t) {
    return this._nonSelectedRenderer.search(e, t)
  }
  paint(e, t) {
    const n = this._layer.getModelBoundsVisibleOnMap()
    if (!this._map || !n) return
    const r = this._layer.canDrawLabels(this._labelPaintRepresentation)
    const a = undefined
    if (
      !this._layer.isPaintRepresentationVisibleInTree(
        this._bodyPaintRepresentation
      ) &&
      !r
    )
      return
    const i = getDetailLevel(this._map, this._layer, this._featurePainter)
    const s = t ? this._selectedRenderer : this._nonSelectedRenderer
    s.resetRendererState(i)
    if (r) {
      s.labelContext.reset()
      s.paintBodiesAndLabels(e, n)
    } else s.paintBodies(e, n)
  }
  collectLabels(e, t) {
    if (
      !this._map ||
      !this._layer.isPaintRepresentationVisibleInTree(
        this._labelPaintRepresentation
      )
    )
      return
    const n = this._layer.getModelBoundsVisibleOnMap()
    if (!n) return
    const r = t ? this._selectedRenderer : this._nonSelectedRenderer
    r.labelContext.reset()
    const a = getDetailLevel(this._map, this._layer, this._featurePainter)
    r.resetRendererState(a)
    r.paintLabels(e, n)
  }
  interacts(e, t) {
    if (!this._map || !this._nonSelectedRenderer || !this._selectedRenderer)
      return false
    const n = getDetailLevel(this._map, this._layer, this._featurePainter)
    return (
      this._nonSelectedRenderer.interacts(n, e, t) ||
      this._selectedRenderer.interacts(n, e, t)
    )
  }
  getMaximumInteractionZOrder(e, t) {
    if (!this._map || (!this._nonSelectedRenderer && !this._selectedRenderer))
      return -1 / 0
    const n = getDetailLevel(this._map, this._layer, this._featurePainter)
    let r = -1 / 0
    if (this._nonSelectedRenderer)
      r = this._nonSelectedRenderer.getMaximumInteractionZOrder(n, e, t)
    if (this._selectedRenderer)
      r = Math.max(
        r,
        this._selectedRenderer.getMaximumInteractionZOrder(n, e, t)
      )
    return r
  }
  _invalidateFeature(e) {
    const t = this._layer.workingSet.getNode(e.id)
    if (t) t.invalidateStates()
  }
  _invalidateAllFeatures() {
    if (
      this._featurePainter &&
      this._featurePainter.density &&
      this._map?._isSoftwareMap()
    )
      Log.warn(
        'LuciadRIA non-WebGLMap does not support density painting (see FeaturePainter.density)'
      )
    this._layer.workingSet.forEachVisibleNode((e) => e.invalidateStates())
  }
  setSelected(e, t) {
    return this._layer.workingSet.setSelected(e, t)
  }
  setEdited(e, t) {
    return this._layer.workingSet.setEdited(e.id, t)
  }
  resetPainter(e) {
    this._setPainter(e)
    this._invalidateAllFeatures()
  }
  filterChanged(e) {
    this._nonSelectedRenderer.setFilter(e)
  }
  static paintFeature(e, t, n, r, a, i) {
    tempStateObject.selected = i.selected
    if (n.isPaintRepresentationVisibleInTree(a)) {
      const i = n.getPainterForPaintRepresentation(a)
      tempStateObject.level = getDetailLevel(r, n, i)
      const s = i ? i.getPaintFunction(a) : null
      if (s) s.call(i, e, t, n.fetchProvidedShape(t), n, r, tempStateObject)
    }
  }
  static create(e, t, n, r) {
    if (n === PaintRepresentation.BODY && r === PaintRepresentation.LABEL)
      return new FeatureLayerRenderer(t, n, r)
    const { painter: a, map: i } = t
    if (i.reference.coordinateType !== CoordinateType.CARTESIAN) return null
    const s = undefined
    return a.isSupportedPaintRepresentation(n) ||
      a.isSupportedPaintRepresentation(r)
      ? new FeatureLayerRenderer(t, n, r)
      : null
  }
}
