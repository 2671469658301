import { XYZPoint } from '../shape/XYZPoint.js'
import { Transformation } from './Transformation.js'
const scratchCoordIn = new XYZPoint()
const scratchCoordOut = new XYZPoint()
export class Affine2D extends Transformation {
  constructor(t, e, s, r) {
    super(null, null)
    this._xScale = 1
    this._yScale = -1
    this._xTrans = 0
    this._yTrans = 0
    if (4 === arguments.length) this.pinToPositions(t, e, s, r)
  }
  _getTransformationMatrix() {
    return [
      [this._xScale, 0, this._xTrans],
      [0, this._yScale, this._yTrans],
      [0, 0, 1],
    ]
  }
  getScaleSFCT(t) {
    t[0] = this._xScale
    t[1] = this._yScale
  }
  get xScale() {
    return this._xScale
  }
  get yScale() {
    return this._yScale
  }
  set xScale(t) {
    this._xScale = t
  }
  set yScale(t) {
    this._yScale = t
  }
  getScaleX() {
    return this._xScale
  }
  getScaleY() {
    return -this._yScale
  }
  translate(t, e) {
    this._xTrans += t
    this._yTrans += e
  }
  isInsideRectangleForward(t, e, s, r, i, a) {
    scratchCoordIn.x = t
    scratchCoordIn.y = e
    this._forward(scratchCoordIn, scratchCoordOut)
    const n = scratchCoordOut.x
    const o = scratchCoordOut.y
    return n >= s && n <= i && o >= r && o <= a
  }
  pinToPositions(t, e, s, r) {
    let i, a
    if (s[0] - t[0] !== 0 || s[1] - t[1] !== 0) {
      if (s[0] - t[0] === 0) {
        i = this._xScale / this._yScale
        this._yScale = (r[1] - e[1]) / (s[1] - t[1])
        this._xScale = i * this._yScale
      } else if (s[1] - t[1] === 0) {
        a = this._yScale / this._xScale
        this._xScale = (r[0] - e[0]) / (s[0] - t[0])
        this._yScale = a * this._yScale
      } else {
        this._xScale = (r[0] - e[0]) / (s[0] - t[0])
        this._yScale = (r[1] - e[1]) / (s[1] - t[1])
      }
      this._xTrans = e[0] - this._xScale * t[0]
      this._yTrans = e[1] - this._yScale * t[1]
    }
    this._updateInverse()
  }
  toString() {
    return `Affine2D \n |${this._xScale}\t0\t0|\n|0\t${this._yScale}\t0|\n|${this._xTrans}\t${this._yTrans}\t0|\n`
  }
  _forward(t, e) {
    e.x = this.forwardX(t.x, t.y)
    e.y = this.forwardY(t.x, t.y)
    return e
  }
  forwardX(t, e) {
    return this._xScale * t + this._xTrans
  }
  forwardY(t, e) {
    return this._yScale * e + this._yTrans
  }
  inverseX(t, e) {
    return (t - this._xTrans) / this._xScale
  }
  inverseY(t, e) {
    return (e - this._yTrans) / this._yScale
  }
  _forwardBatch(t, e) {
    const s = t.length
    if (s < 3) return 0
    if (s > e.length) e.length = s
    const r = this._xScale
    const i = this._yScale
    const a = this._xTrans
    const n = this._yTrans
    let o
    let c
    e[0] = o = r * t[0] + a
    e[1] = c = i * t[1] + n
    let _ = 3
    let h = 2
    while (_ < s) {
      const s = r * t[_] + a
      const l = i * t[_ + 1] + n
      if (Math.abs(o - s) > 0.5 || Math.abs(c - l) > 0.5) {
        e[h] = o = s
        e[h + 1] = c = l
        h += 2
      }
      _ += 3
    }
    return h
  }
  _inverse(t, e) {
    e.x = (t.x - this._xTrans) / this._xScale
    e.y = (t.y - this._yTrans) / this._yScale
    return e
  }
  forwardDistance(t) {
    return [t[0] * this._xScale, t[1] * this._yScale]
  }
  _inverseXDistance(t) {
    return t / this._xScale
  }
  _inverseYDistance(t) {
    return t / this._yScale
  }
  _forwardXDistance(t) {
    return t * this._xScale
  }
  _forwardYDistance(t) {
    return t * this._yScale
  }
  inverseDistance(t, e) {
    e[0] = t[0] / this._xScale
    e[1] = t[1] / this._yScale
  }
  setScale(t, e) {
    this._xScale = t
    this._yScale = e
    this._updateInverse()
  }
  setParameterValues(t, e, s, r) {
    this._xScale = s
    this._yScale = r
    this._xTrans = t
    this._yTrans = e
    this._updateInverse()
  }
  setTo(t) {
    this._xScale = t._xScale
    this._yScale = t._yScale
    this._xTrans = t._xTrans
    this._yTrans = t._yTrans
    this._updateInverse()
  }
  copy(t) {
    if (!t) t = new Affine2D([0, 0], [0, 0], [0, 0], [0, 0])
    t._inputReference = this._inputReference
    t._outputReference = this._outputReference
    t.setTo(this)
    return t
  }
  _updateInverse() {
    const t = this._inverseTransformation
    if (t) {
      t._xScale = 1 / this._xScale
      t._yScale = 1 / this._yScale
      t._xTrans = -this._xTrans / this._xScale
      t._yTrans = -this._yTrans / this._yScale
      t._outputReference = this._inputReference
      t._inputReference = this._outputReference
    }
  }
  get inverseTransformation() {
    let t = this._inverseTransformation
    if (!t) {
      t = this.copy()
      t._inverseTransformation = this
      this._inverseTransformation = t
      this._updateInverse()
    }
    return t
  }
  static viewParamsToAffineParams(t, e, s, r, i, a, n) {
    n._xTrans = t - i * s
    n._yTrans = e - -a * r
    n._xScale = i
    n._yScale = -a
  }
  static cameraPosition2DToAffineTransformation(t, e) {
    Affine2D.viewParamsToAffineParams(
      t.viewOriginX,
      t.viewOriginY,
      t.worldOriginX,
      t.worldOriginY,
      t.scaleX,
      t.scaleY,
      e
    )
  }
  static affineTransformationToCameraPosition2D(t, e, s, r) {
    Affine2D.affineTransformationValuesToCameraPosition2D(
      t,
      e,
      s._xTrans,
      s._yTrans,
      s._xScale,
      s._yScale,
      r
    )
  }
  static translationXFromOrigins(t, e, s) {
    return t - e * s
  }
  static translationYFromOrigins(t, e, s) {
    return t - e * -s
  }
  static affineTransformationValuesToCameraPosition2D(t, e, s, r, i, a, n) {
    n.reset(t, e, (t - s) / i, (e - r) / -a, i, a)
  }
  static shiftViewOrigin(t, e, s) {
    let r, i
    if (t._xScale - e._xScale !== 0) {
      r = -(t._xTrans - e._xTrans) / (t._xScale - e._xScale)
      i = e.forwardX(r)
    } else i = s.viewOriginX
    let a, n
    if (t._yScale - e._yScale !== 0) {
      n = -(t._yTrans - e._yTrans) / (t._yScale - e._yScale)
      a = e.forwardY(r, n)
    } else a = s.viewOriginY
    Affine2D.affineTransformationToCameraPosition2D(i, a, e, s)
  }
  _forwardBoundsCoords(t, e) {
    return this.forwardBoundsCoordsImpl(t, e)
  }
  _inverseBoundsCoords(t, e) {
    return this.inverseBoundsCoordsImpl(t, e)
  }
  _getType() {
    throw new Error('Method not implemented.')
  }
  forwardBoundsCoordsImpl = (() => {
    const t = new XYZPoint()
    const e = new XYZPoint()
    const s = new XYZPoint()
    return (r, i) => {
      s.x = r.x
      s.y = r.y
      this._forward(s, t)
      s.x = r.x + r.width
      s.y = r.y + r.height
      this._forward(s, e)
      const a = Math.min(t.x, e.x)
      const n = Math.min(t.y, e.y)
      i.setTo2D(a, Math.max(t.x, e.x) - a, n, Math.max(t.y, e.y) - n)
    }
  })()
  inverseBoundsCoordsImpl = (() => {
    const t = new XYZPoint()
    const e = new XYZPoint()
    const s = new XYZPoint()
    return (r, i) => {
      s.x = r.x
      s.y = r.y
      this._inverse(s, t)
      s.x = r.x + r.width
      s.y = r.y + r.height
      this._inverse(s, e)
      const a = Math.min(t.x, e.x)
      const n = Math.min(t.y, e.y)
      i.setTo2D(a, Math.max(t.x, e.x) - a, n, Math.max(t.y, e.y) - n)
    }
  })()
}
