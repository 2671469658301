import { ProgrammingError } from '../error/ProgrammingError.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { isArray, isNumber, isUndefined } from '../util/Lang.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
export class ComplexPolygon extends Shape {
  constructor(e, t) {
    super()
    this._reference = e
    this._polygonList = []
    this._coordinates = []
    if (isArray(t)) this.addPolygons(t)
  }
  get type() {
    return ShapeType.COMPLEX_POLYGON
  }
  get coordinates() {
    return this._coordinates
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  get polygonCount() {
    return this._polygonList.length
  }
  get focusPoint() {
    if (isUndefined(this._focus))
      this._focus = this.bounds ? this.bounds.focusPoint : null
    return this._focus
  }
  get bounds() {
    if (isUndefined(this._bounds)) {
      const e = this._polygonList[0]
      this._bounds = e ? e.bounds.copy() : null
    }
    return this._bounds
  }
  contains2DCoordinates(e, t) {
    const o = this._polygonList[0]
    let n
    if (!(o && o.contains2DCoordinates(e, t))) return false
    for (n = 1; n < this._polygonList.length; n++)
      if (this._polygonList[n].contains2DCoordinates(e, t)) return false
    return true
  }
  getPolygon(e) {
    return this._polygonList[e]
  }
  addPolygon(e, t) {
    if (1 === arguments.length || 'undefined' === typeof t) {
      ShapeUtil.validateReferencesOrBounce(this.reference, e.reference)
      this._polygonList.push(e)
      this._coordinates.push(e.coordinates[0])
    } else {
      ShapeUtil.validateReferencesOrBounce(this.reference, t.reference)
      this._polygonList.splice(e, 0, t)
      this._coordinates.splice(e, 0, t.coordinates[0])
    }
    this.invalidate()
  }
  addPolygons(e) {
    const t = e.length
    for (let o = 0; o < t; o++)
      ShapeUtil.validateReferencesOrBounce(this.reference, e[o].reference)
    Array.prototype.push.apply(this._polygonList, e)
    Array.prototype.push.apply(
      this._coordinates,
      e.map((e) => e.coordinates[0])
    )
    this.invalidate()
  }
  clearPolygons() {
    this._polygonList = []
    this._coordinates = []
    this.invalidate()
  }
  removePolygon(e) {
    if (isNumber(e)) {
      this.invalidate()
      this._coordinates.splice(e, 1)
      return this._polygonList.splice(e, 1)[0]
    } else {
      let t = 0
      const o = this._polygonList.length
      for (; t < o; t++) if (this._polygonList[t].equals(e)) break
      if (t < o) {
        this._coordinates.splice(t, 1)
        this._polygonList.splice(t, 1)
        return e
      } else return null
    }
  }
  removePolygons(e) {
    for (let t = 0, o = e.length; t < o; t++) this.removePolygon(e[t])
    this.invalidate()
  }
  setPolygon(e, t) {
    if (e >= this._polygonList.length)
      throw new ProgrammingError(`setPolygon: no polygon at index ${e}`)
    ShapeUtil.validateReferencesOrBounce(this.reference, t.reference)
    this._coordinates.splice(e, 1, t.coordinates[0])
    const o = this._polygonList.splice(e, 1, t)[0]
    this.invalidate()
    return o
  }
  polygonChanged(e) {
    this.invalidate()
  }
  invalidate() {
    this._bounds = void 0
    this._focus = void 0
  }
  copy() {
    const e = new ComplexPolygon(this.reference)
    const t = this._polygonList
    let o
    const n = t.length
    for (o = 0; o < n; o++) e.addPolygon(t[o].copy())
    return e
  }
  equals(e) {
    if (e === this) return true
    if (!(e instanceof ComplexPolygon)) return false
    if (
      e.polygonCount !== this.polygonCount ||
      !ShapeUtil.referencesEqual(this.reference, e.reference)
    )
      return false
    return this._polygonList.every((t, o) => e.getPolygon(o).equals(t))
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    for (let t = 0, o = this.polygonCount; t < o; t++)
      this.getPolygon(t).hashCode(e)
  }
  toString() {
    let e = 'ComplexPolygon['
    for (let t = 0, o = this.polygonCount; t < o; t++) {
      e += this.getPolygon(t).toString()
      if (t + 1 < o) e += ','
    }
    e += ']'
    return e
  }
  translate2D(e, t) {
    for (let o = 0, n = this.polygonCount; o < n; o++)
      this.getPolygon(o).translate2D(e, t)
    this.invalidate()
  }
  get isGeodetic() {
    return false
  }
  contains3DCoordinates(e, t, o) {
    throw new Error('Method not implemented.')
  }
}
export function isComplexPolygon(e) {
  return e && e.type === ShapeType.COMPLEX_POLYGON
}
