import { TileRecordType } from './TileRecordType.js'
import { getPath, zoom } from './TileUtil.js'
export class DefaultTileRecord {
  constructor(e) {
    this._coordinate = e.coordinate
    this._leaf = false
    this._entireAreaCovered = true
    this._proxy = false
    this._baseTileCoordinate = e.coordinate
    this._tile = e
    this._fallBack = false
  }
  get tile() {
    return this._tile
  }
  get baseTileCoordinate() {
    return this._baseTileCoordinate
  }
  get proxy() {
    return this._proxy
  }
  get entireAreaCovered() {
    return this._entireAreaCovered
  }
  get leaf() {
    return this._leaf
  }
  set leaf(e) {
    this._leaf = e
  }
  get coordinate() {
    return this._coordinate
  }
  get type() {
    return TileRecordType.DEFAULT
  }
  get fallback() {
    return this._fallBack
  }
  set fallback(e) {
    this._fallBack = e
  }
  draw(e, t) {
    const i = t.getViewBounds(this._coordinate)
    if (null !== i) this._tile.draw(e, null, i)
  }
  deriveTile(e, t, i) {
    const r = this._coordinate
    if (r.level === e.level)
      if (e.x === r.x && e.y === r.y) return new DefaultTileRecord(this._tile)
      else return null
    const l = getPath(r, e, t, i)
    if (null == l) return null
    if (r.level < e.level) {
      const r = this._tile.bitmapBounds.copy()
      for (let e = 0; e < l.length; e++) zoom(r, l[e], t, i)
      if (r.width < 1) r.setTo2D(r.x, 1, r.y, r.height)
      if (r.height < 1) r.setTo2D(r.x, r.width, r.y, 1)
      const o = new ZoomedTileRecord(e, this._tile, r, true)
      o.leaf = this._leaf
      return o
    } else
      return new ZoomedTileRecord(
        e,
        this._tile,
        this._tile.bitmapBounds.copy(),
        false
      )
  }
}
export class ZoomedTileRecord {
  constructor(e, t, i, r) {
    this._coordinate = e
    this._tile = t
    this._bitmapBounds = i
    this._entireAreaCovered = r
    this._proxy = true
    this._leaf = false
    this._baseTileCoordinate = t.coordinate
    this._fallBack = false
  }
  get fallback() {
    return this._fallBack
  }
  set fallback(e) {
    this._fallBack = e
  }
  get baseTileCoordinate() {
    return this._baseTileCoordinate
  }
  get leaf() {
    return this._leaf
  }
  set leaf(e) {
    this._leaf = e
  }
  get proxy() {
    return this._proxy
  }
  get entireAreaCovered() {
    return this._entireAreaCovered
  }
  get bitmapBounds() {
    return this._bitmapBounds
  }
  get tile() {
    return this._tile
  }
  get coordinate() {
    return this._coordinate
  }
  draw(e, t) {
    let i
    const r = this._baseTileCoordinate
    if (this._coordinate.level < r.level) i = t.getViewBounds(r)
    else i = t.getViewBounds(this._coordinate)
    if (null != i) this._tile.draw(e, this._bitmapBounds, i)
  }
  deriveTile(e, t, i) {
    const r = this._coordinate
    if (r.level === e.level)
      if (e.x === r.x && e.y === r.y) {
        const t = new ZoomedTileRecord(
          e,
          this._tile,
          this._bitmapBounds.copy(),
          this._entireAreaCovered
        )
        t._leaf = this._leaf
        return t
      } else return null
    const l = new DefaultTileRecord(this._tile)
    l.leaf = this._leaf
    const o = l.coordinate
    if (e.level === o.level && e.x === o.x && e.y === o.y) return l
    else return l.deriveTile(e, t, i)
  }
  get type() {
    return TileRecordType.ZOOMED
  }
}
