import { CoordinateType } from '../reference/CoordinateType.js'
import { ShapeType } from './ShapeType.js'
import { XYZBounds } from './XYZBounds.js'
export class SimpleXYZPoint {
  constructor(t, e, i, r) {
    this.reference = t
    this.x = e
    this.y = i
    this.z = r
  }
  move2DToCoordinates(t, e) {
    this.x = t
    this.y = e
  }
  move2DToPoint(t) {
    this.x = t.x
    this.y = t.y
  }
  move3DToCoordinates(t, e, i) {
    this.x = t
    this.y = e
    this.z = i
  }
  move3DToPoint(t) {
    this.x = t.x
    this.y = t.y
    this.z = t.z
  }
  translate2D(t, e) {
    this.x += t
    this.y += e
  }
  translate3D(t, e, i) {
    this.x += t
    this.y += e
    this.z += i
  }
  contains2DCoordinates(t, e) {
    return this.x === t && this.y === e
  }
  contains2DPoint(t) {
    return this.x === t.x && this.y === t.y
  }
  copy() {
    return new SimpleXYZPoint(this.reference, this.x, this.y, this.z)
  }
  equals(t) {
    return this.x === t.x && this.y === t.y && this.z === t.z
  }
  toString() {
    return `SimpleXYZPoint(${this.x}, ${this.y}, ${this.z})`
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  get bounds() {
    return new XYZBounds(null, [this.x, 0, this.y, 0])
  }
  get focusPoint() {
    return this
  }
  get type() {
    return ShapeType.POINT
  }
  static create(t, e, i, r) {
    return new SimpleXYZPoint(t, e, i, r)
  }
  static createFromPoint(t) {
    return new SimpleXYZPoint(t.reference, t.x, t.y, t.z)
  }
  static createFromArray(t, e) {
    return new SimpleXYZPoint(t, e[0], e[1], e[2])
  }
}
