import { Hash } from '../util/Hash.js'
import { Axis } from './Axis.js'
import { CoordinateReference } from './CoordinateReference.js'
import { CoordinateType } from './CoordinateType.js'
import { ReferenceType } from './ReferenceType.js'
const AxisType = 'AXIS'
export class CartesianReference extends CoordinateReference {
  constructor(e, i, t, r, s) {
    if (!t || t.TYPE !== AxisType)
      throw new Error('Cannot create a cartesian reference without X axis')
    if (!r || r.TYPE !== AxisType)
      throw new Error('Cannot create a cartesian reference without Y axis')
    if (s && s.TYPE !== AxisType)
      throw new Error(
        'Given Z Axis parameter is invalid. Either leave it out (for 2D references) or make sure it is a valid type.'
      )
    const n = [
      { name: Axis.Name.X, axis: t },
      { name: Axis.Name.Y, axis: r },
    ]
    if (s) n.push({ name: Axis.Name.Z, axis: s })
    super({
      name: e,
      identifier: i,
      axisInformation: n,
      coordinateType: CoordinateType.CARTESIAN,
      referenceType: ReferenceType.CARTESIAN,
    })
    const a = new Hash()
      .appendString(this.name)
      .appendString(this.identifier)
      .appendDouble(t.unitOfMeasure.conversionMultiplier)
      .appendDouble(r.unitOfMeasure.conversionMultiplier)
    if (s) a.appendDouble(s.unitOfMeasure.conversionMultiplier)
    this._hash = a.getHashCode()
  }
  equals(e) {
    if (!e) return false
    if (e.referenceType !== ReferenceType.CARTESIAN) return false
    if (this === e) return true
    const i = Axis.Name.X
    const t = Axis.Name.Y
    const r = Axis.Name.Z
    const s = this.getAxis(i).equals(e.getAxis(i))
    const n = this.getAxis(t).equals(e.getAxis(t))
    const a = !this.getAxis(r)
      ? !e.getAxis(r)
      : this.getAxis(r).equals(e.getAxis(r))
    return s && n && a
  }
  copy() {
    return new CartesianReference(
      this.name,
      this.identifier,
      this.getAxis(Axis.Name.X),
      this.getAxis(Axis.Name.Y),
      this.getAxis(Axis.Name.Z)
    )
  }
  get TYPE() {
    return ReferenceType.CARTESIAN
  }
  get unitOfMeasure() {
    return 1
  }
  hashCode(e) {
    e.appendUInt32(this._hash)
  }
}
