import { ModifierType } from './ModifierType.js'
const EVENT_TYPE = 'KEY'
export class KeyEvent {
  constructor(t, e, i) {
    this._node = t
    this._inputType = i
    this._type = e
    this._modifier = ModifierType.NO_MOD
    this._domEvent = null
  }
  get modifier() {
    return this._modifier
  }
  set modifier(t) {
    this._modifier = t
  }
  get eventType() {
    return EVENT_TYPE
  }
  get domEvent() {
    return this._domEvent
  }
  set domEvent(t) {
    this._domEvent = t
  }
  get inputType() {
    return this._inputType
  }
  set inputType(t) {
    this._inputType = t
  }
  get type() {
    return this._type
  }
  set type(t) {
    this._type = t
  }
}
