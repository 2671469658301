import { GestureEventImpl } from './GestureEvent.js'
import { GestureEventType } from './GestureEventType.js'
export class ScrollEventImpl extends GestureEventImpl {
  _amount = 0
  constructor(t, e) {
    super(t, GestureEventType.SCROLL, e)
  }
  get amount() {
    return this._amount
  }
  set amount(t) {
    this._amount = t
  }
  copy() {
    const t = new ScrollEventImpl(super.map, this.inputType)
    for (const e in this) if (this.hasOwnProperty(e)) t[e] = this[e]
    return t
  }
}
