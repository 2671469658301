import { LayerTreeNode } from '../LayerTreeNode.js'
import { LayerTreeVisitor } from '../LayerTreeVisitor.js'
export class SubTreeInvalidatorVisitor {
  constructor() {
    this._bodyContexts = []
    this._borderContext = null
  }
  get borderContext() {
    return this._borderContext
  }
  get bodyContexts() {
    return this._bodyContexts
  }
  visitLayer(e) {
    let r, t
    for (r = 0; r < this._bodyContexts.length; r++) {
      t = this._bodyContexts[r]
      if (-1 !== t.layers.indexOf(e)) {
        t.valid = false
        break
      }
    }
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
  visitLayerGroup(e) {
    e.visitChildren(this, LayerTreeNode.VisitOrder.BOTTOM_UP)
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
  reset(e, r) {
    this._bodyContexts = e
    this._borderContext = r
  }
}
