import { EventedSupport } from './EventedSupport.js'
import { isNumber } from './Lang.js'
const DEFAULT_LOOP_ID = -1
const DEFAULT_INTERVAL = 0
export const TimerTriggeredEvent = 'TimerTriggeredEvent'
export const TimerStartedEvent = 'TimerStartedEvent'
export const TimerStoppedEvent = 'TimerStoppedEvent'
function boundInterval(e) {
  if (!isNumber(e) || isNaN(e)) return DEFAULT_INTERVAL
  return Math.max(0 | e, 0)
}
export class Timer {
  constructor(e) {
    let t =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1 / 0
    this._interval = boundInterval(e)
    this._loopId = DEFAULT_LOOP_ID
    this._evented = new EventedSupport()
    this._counter = t
  }
  get isRunning() {
    return this._loopId !== DEFAULT_LOOP_ID
  }
  get interval() {
    return this._interval
  }
  set interval(e) {
    this._interval = boundInterval(e)
    this.reset()
  }
  get evented() {
    return this._evented
  }
  on(e, t, r) {
    return this.evented.on(e, t, r)
  }
  start() {
    this._evented.emit(TimerStartedEvent)
    this.trigger()
  }
  stop() {
    this.unschedule()
    this._evented.emit(TimerStoppedEvent)
  }
  reset() {
    this.unschedule()
    this.schedule()
  }
  trigger() {
    this.schedule()
    this._evented.emit(TimerTriggeredEvent)
  }
  unschedule() {
    window.clearTimeout(this._loopId)
    this._loopId = DEFAULT_LOOP_ID
  }
  schedule() {
    if (this._counter-- < 0) return this.stop()
    this._loopId = window.setTimeout(() => this.trigger(), this._interval)
  }
}
