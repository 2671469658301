import { NotImplementedError } from '../error/NotImplementedError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { Arc } from '../shape/Arc.js'
import { XYZBounds } from '../shape/XYZBounds.js'
import { forwardAzimuth2D } from '../util/Cartesian.js'
import { Constants } from '../util/Constants.js'
import { isNumber } from '../util/Lang.js'
import * as XYArcUtil from './XYArcUtil.js'
import * as XYEllipseUtil from './XYEllipseUtil.js'
export class XYZArc extends Arc {
  constructor(r, t, e, o, i, n, s) {
    super(r, t, e, o, i, n, s % 360)
    this._compareReferenceStrict(
      t.reference,
      'XYZArc::the center point reference must be equal to the arc reference'
    )
    if (!isNumber(e))
      throw new ProgrammingError('XYZArc::cannot create a without a')
    if (!isNumber(o))
      throw new ProgrammingError('XYZArc::cannot create a without b')
    if (!isNumber(i))
      throw new ProgrammingError(
        'XYZArc::cannot create a without rotationAzimuth'
      )
    if (!isNumber(n))
      throw new ProgrammingError('XYZArc::cannot create a without startAzimuth')
    if (!isNumber(s))
      throw new ProgrammingError('XYZArc::cannot create a without sweepAngle')
  }
  get isGeodetic() {
    return false
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  copy() {
    return new XYZArc(
      this.reference,
      this.center.copy(),
      this.a,
      this.b,
      this.rotationAzimuth,
      this.startAzimuth,
      this.sweepAngle
    )
  }
  createBounds(r) {
    const t = new XYZBounds(r.reference, [0, 0, 0, 0, 0, 0])
    XYArcUtil.calculateBounds(r, t)
    return t
  }
  computeArcPoint(r) {
    const t = (r - this.rotationAzimuth) / 360
    const e = this.center.copy()
    XYEllipseUtil.interpolate(this, t, e)
    return e
  }
  interpolate(r, t) {
    return XYEllipseUtil.interpolate(this, r, t)
  }
  updatePoint(r, t, e) {
    XYEllipseUtil.updatePoint(this, r, t, e)
  }
  _forwardAzimuth(r, t) {
    return Constants.RAD2DEG * forwardAzimuth2D(r, t)
  }
  contains2DCoordinates(r, t) {
    return XYArcUtil.arcContains2D(
      this.center.x,
      this.center.y,
      this.a,
      this.b,
      this.rotationAzimuth,
      this.startAzimuth,
      this.sweepAngle,
      r,
      t
    )
  }
  contains3DCoordinates(r, t, e) {
    throw new NotImplementedError(
      'Arc implementations must provide function interpolate'
    )
  }
}
