import { clamp } from './Cartesian.js'
import { splineDistanceAndVelocity } from './Easing.js'
const DECELERATION_RATE = Math.log(0.78) / Math.log(0.9)
const INFLEXION = 0.15
const GRAVITY_EARTH = 9.80665
const DPI = 96
const decelerationFriction = 6 / DPI
function computeDeceleration(t, i) {
  return 39.37 * GRAVITY_EARTH * i * t
}
export class Scroller {
  _currVelocity = 0
  _distance = 0
  _duration = 0
  _directionX = 0
  _directionY = 0
  _deltaX = 0
  _deltaY = 0
  _prevX = 0
  _prevY = 0
  constructor(t) {
    this._finished = true
    this._flingFriction = 0.04 * (t ?? 1)
    this._physicalCoeff = computeDeceleration(decelerationFriction, DPI)
  }
  get duration() {
    return this._duration
  }
  get deltaY() {
    return this._deltaY
  }
  get deltaX() {
    return this._deltaX
  }
  update(t) {
    if (this._finished) return false
    const i = clamp(t, 0, 1)
    const { distanceCoef: e, velocityCoef: n } = splineDistanceAndVelocity(i)
    this._currVelocity = ((n * this._distance) / this._duration) * 1e3
    const s = e * this._directionX
    const o = e * this._directionY
    this._deltaX = s - this._prevX
    this._deltaY = o - this._prevY
    this._prevX = s
    this._prevY = o
    if (i >= 1) this._finished = true
    return true
  }
  fling(t, i) {
    if (!this._finished) {
      const e = this._currVelocity
      const n = this._directionX
      const s = this._directionY
      const o = Math.sqrt(n * n + s * s)
      const r = undefined
      const c = undefined
      const h = (n / o) * e
      const a = (s / o) * e
      if (Math.sign(t) === Math.sign(h) && Math.sign(i) === Math.sign(a)) {
        t += h
        i += a
      }
    }
    this._finished = false
    const e = Math.sqrt(t * t + i * i)
    this._duration = this.getSplineFlingDuration(e)
    const n = this.getSplineFlingDistance(e)
    this._distance = n * Math.sign(e)
    const s = 0 === e ? 1 : t / e
    const o = 0 === e ? 1 : i / e
    this._prevX = 0
    this._prevY = 0
    this._deltaX = 0
    this._deltaY = 0
    this._directionX = n * s
    this._directionY = n * o
    return this._duration
  }
  getSplineDeceleration(t) {
    return Math.log(
      (INFLEXION * Math.abs(t)) / (this._flingFriction * this._physicalCoeff)
    )
  }
  getSplineFlingDuration(t) {
    const i = this.getSplineDeceleration(t)
    const e = DECELERATION_RATE - 1
    return 1e3 * Math.exp(i / e)
  }
  getSplineFlingDistance(t) {
    const i = this.getSplineDeceleration(t)
    const e = DECELERATION_RATE - 1
    return (
      this._flingFriction *
      this._physicalCoeff *
      Math.exp((DECELERATION_RATE / e) * i)
    )
  }
}
