import { Log } from '../../../util/Log.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
const LINE_BREAK = 1
const POLYGON_BREAK = 2
const COORDSPERVERTEX = 3
const POLYGON_CLOSURE = 4
export class GeneralPath {
  constructor() {
    this._currentSubPathIdx = 0
    this._nextPointIdx = 0
    this._subPathStartArray = []
    this._subPathLengthArray = []
    this._subPathBreakArray = []
    this._coordArray = []
    this._fractions = null
    this.reset()
  }
  get fractions() {
    return this._fractions
  }
  set fractions(t) {
    this._fractions = t
  }
  reset() {
    this._coordArray = []
    this._currentSubPathIdx = 0
    this._nextPointIdx = 0
    this._subPathStartArray[0] = 0
    this._subPathLengthArray[0] = 0
    this._subPathBreakArray[0] = 0
  }
  moveTo(t, r, s) {
    s = s || 0
    let h, a
    if (
      this._subPathStartArray[this._currentSubPathIdx] == this._nextPointIdx
    ) {
      this.breakLine()
      this.addPoint(t, r, s)
      return true
    } else {
      h = this._nextPointIdx
      a = this._coordArray
      if (h <= 3 || !(a[h - 3] == t && a[h - 2] == r && a[h - 1] == s)) {
        this.breakLine()
        this.addPoint(t, r, s)
        return true
      }
    }
    return false
  }
  lineTo(t, r, s) {
    s = s || 0
    if (
      this._subPathStartArray[this._currentSubPathIdx] == this._nextPointIdx
    ) {
      this.addPoint(t, r, s)
      return true
    } else {
      const h = this._nextPointIdx,
        a = this._coordArray
      if (h <= 3 || !(a[h - 3] == t && a[h - 2] == r && a[h - 1] == s)) {
        this.addPoint(t, r, s)
        return true
      }
    }
    return false
  }
  breakLine() {
    if (this._subPathLengthArray[this._currentSubPathIdx] > 0) {
      this._subPathBreakArray[this._currentSubPathIdx] |= LINE_BREAK
      this.startNewSubPath()
    }
  }
  breakPolygon() {
    const t = this._currentSubPathIdx
    if (this._subPathLengthArray[t] > 0 || 0 == t) {
      this._subPathBreakArray[t] |= POLYGON_BREAK
      this.startNewSubPath()
    } else this._subPathBreakArray[t - 1] |= POLYGON_BREAK
  }
  closePolygon() {
    const t = this._currentSubPathIdx
    if (this._subPathLengthArray[t] > 0 || 0 == t) {
      this._subPathBreakArray[t] |= POLYGON_CLOSURE
      this.startNewSubPath()
    } else this._subPathBreakArray[t - 1] |= POLYGON_CLOSURE
  }
  addPoint(t, r, s) {
    s = s || 0
    this._coordArray.push(t)
    this._coordArray.push(r)
    this._coordArray.push(s)
    this._nextPointIdx += COORDSPERVERTEX
    this._subPathLengthArray[this._currentSubPathIdx]++
  }
  startNewSubPath() {
    const t = ++this._currentSubPathIdx
    this._subPathStartArray[t] = this._nextPointIdx
    this._subPathLengthArray[t] = 0
    this._subPathBreakArray[t] = 0
  }
  subPathCount() {
    return (
      this._currentSubPathIdx +
      (0 == this._subPathLengthArray[this._currentSubPathIdx] ? 0 : 1)
    )
  }
  subPathLength(t) {
    return this._subPathLengthArray[t]
  }
  subPathBreaksLine(t) {
    return 0 != (this._subPathBreakArray[t] & LINE_BREAK)
  }
  subPathBreaksPolygon(t) {
    return 0 != (this._subPathBreakArray[t] & POLYGON_BREAK)
  }
  subPathClosesPolygon(t) {
    return 0 != (this._subPathBreakArray[t] & POLYGON_CLOSURE)
  }
  canRetrievePoint(t, r) {
    if (t > this._currentSubPathIdx) return false
    return r < this.subPathLength(t)
  }
  getX(t, r) {
    if (!this.canRetrievePoint(t, r))
      throw new ProgrammingError(
        'Cannot retrieve x-coordinate of given point: incorrect indices!'
      )
    return this._coordArray[this._subPathStartArray[t] + r * COORDSPERVERTEX]
  }
  getY(t, r) {
    if (!this.canRetrievePoint(t, r))
      throw new ProgrammingError(
        'Cannot retrieve y-coordinate of given point: incorrect indices!'
      )
    return this._coordArray[
      this._subPathStartArray[t] + r * COORDSPERVERTEX + 1
    ]
  }
  getZ(t, r) {
    if (!this.canRetrievePoint(t, r))
      throw new ProgrammingError(
        'Cannot retrieve z-coordinate of given point: incorrect indices!'
      )
    return this._coordArray[
      this._subPathStartArray[t] + r * COORDSPERVERTEX + 2
    ]
  }
  dump() {
    Log.debug(`GeneralPath:: subpathcount${this.subPathCount()}`)
    for (let t = 0, r = this.subPathCount(); t < r; t++)
      Log.debug(
        `SubPath ${t}: length: ${this.subPathLength(t)} ${
          this.subPathBreaksLine(t) ? 'LINE_BREAK, ' : ''
        }${this.subPathBreaksPolygon(t) ? 'POLYGON_BREAK, ' : ''}${
          this.subPathClosesPolygon(t) ? 'POLYGON_CLOSURE' : ''
        }`
      )
  }
  toStringDebug() {
    const t = []
    t.push('SUMMARY')
    t.push(`GeneralPath:: subpathcount${this.subPathCount()}`)
    let r, s, h, a
    for (r = 0, s = this.subPathCount(); r < s; r++)
      t.push(
        `SubPath ${r}: length: ${this.subPathLength(r)} ${
          this.subPathBreaksLine(r) ? 'LINE_BREAK, ' : ''
        }${this.subPathBreaksPolygon(r) ? 'POLYGON_BREAK, ' : ''}${
          this.subPathClosesPolygon(r) ? 'POLYGON_CLOSURE' : ''
        }`
      )
    t.push('POINTS')
    for (r = 0, s = this.subPathCount(); r < s; r++) {
      t.push(`PATH: ${r}`)
      t.push(
        `SubPath ${r}: length: ${this.subPathLength(r)} ${
          this.subPathBreaksLine(r) ? 'LINE_BREAK, ' : ''
        }${this.subPathBreaksPolygon(r) ? 'POLYGON_BREAK, ' : ''}${
          this.subPathClosesPolygon(r) ? 'POLYGON_CLOSURE' : ''
        }`
      )
      for (h = 0, a = this.subPathLength(r); h < a; h++)
        t.push([this.getX(r, h), this.getY(r, h), this.getZ(r, h)].join(','))
    }
    return t.join('\n')
  }
}
