import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { Feature } from '../../model/feature/Feature.js'
import { HSPCTilesModel } from '../../model/tileset/HSPCTilesModel.js'
import { OGC3DTilesModel } from '../../model/tileset/OGC3DTilesModel.js'
import { PropertiesCallback } from '../../model/tileset/PropertiesCallback.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { Affine3DTransformation } from '../../transformation/Affine3DTransformation.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import {
  isBoolean,
  isDefined,
  isEmpty,
  isNumber,
  isObject,
  isString,
} from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { LRUCache } from '../../util/LRUCache.js'
import { releaseObject } from '../../util/ObjectReleaseTracker.js'
import { Layer } from '../Layer.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { PhotonScenePainterCallback } from '../photon/scenepainter/PhotonScenePainterCallback.js'
import { PickInfoImpl } from '../PickInfo.js'
import { SelectionSupport } from '../SelectionSupport.js'
import { MeshStyleImpl } from '../style/MeshStyleImpl.js'
import { OutlineOcclusionStyleImpl } from '../style/OutlineOcclusionStyleImpl.js'
import { PointCloudStyleImpl } from '../style/PointCloudStyleImpl.js'
import { PhotonDataLoader } from './PhotonDataLoader.js'
import { ScalingMode } from '../style/ScalingMode.js'
import { PointCloudPointShape } from '../style/PointCloudPointShape.js'
import { TileSet3DLayerPerformanceHintsImpl } from './TileSet3DLayerPerformanceHintsImpl.js'
const REF_LUCIAD_XYZ = getReference('LUCIAD:XYZ')
export function isQualityFactorAdaptiveFalloffOptions(e) {
  return (
    isObject(e) &&
    isNumber(e.percentage, false) &&
    isNumber(e.distanceInterpolationMultiplier, false) &&
    isNumber(e.farQualityFactorMultiplier, false)
  )
}
export let TileLoadingStrategy = (function (e) {
  e[(e['DETAIL_FIRST'] = Photon.LoadingStrategy.DetailFirst)] = 'DETAIL_FIRST'
  e[(e['OVERVIEW_FIRST'] = Photon.LoadingStrategy.OverviewFirst)] =
    'OVERVIEW_FIRST'
  return e
})({})
class TileSet3DLayer extends Layer {
  _propertiesDescriptor = null
  _propertiesCallback = null
  _selectedFeature = null
  constructor(e, t) {
    super(e, t)
    t = t || {}
    this._eventedSupport = new EventedSupport([
      'FeaturesInViewChange',
      'PerformanceHintsChanged',
      'LoadingStatusChanged',
    ])
    this._idPropertyMismatchLogged = false
    this._featureCache = new LRUCache(1e4)
    this._is3D = false
    this._idProperty = t.idProperty
    this._properties = t.properties
    this._selectionSupport = new SelectionSupport(
      this,
      (e, t) => {
        const i = this._featureCache.get(e)
        if (i) return i
        else return new Feature(null, {}, e)
      },
      null
    )
    this.selectable = !!t.selectable
    this._fadingTime =
      isDefined(t.fadingTime) && isNumber(t.fadingTime, false)
        ? t.fadingTime
        : 200
    const i = e.url
    this._rootUrl = i.substring(0, i.lastIndexOf('/') + 1)
    this._relativeUrlToRootMetadataFile = i.substring(
      i.lastIndexOf('/') + 1,
      i.length
    )
    this._urlParams = e.urlParams
    this._meshStyle = MeshStyleImpl.create(t.meshStyle || {}, this)
    this._meshStyleAttributes = new Set(t.attributes || [])
    if (this._idProperty && isDefined(this._idProperty))
      this._meshStyleAttributes.add(this._idProperty)
    const r = t.pointCloudStyle || {}
    if (!isDefined(r.pointSize) && !isDefined(r.scalingMode))
      r.pointSize =
        e instanceof HSPCTilesModel
          ? { mode: ScalingMode.WORLD_SIZE, worldSize: 1 }
          : { mode: ScalingMode.ADAPTIVE_WORLD_SIZE }
    if (!isDefined(r.pointShape))
      r.pointShape =
        e instanceof HSPCTilesModel
          ? PointCloudPointShape.DISC
          : PointCloudPointShape.SPHERE
    this._pointCloudStyle = PointCloudStyleImpl.create(r, this)
    const s = this._invalidate.bind(this)
    this._outlineOcclusionStyle = new OutlineOcclusionStyleImpl(
      this.setOutlineOcclusionStyle.bind(this),
      { invalidate: () => s() },
      t.occlusionStyle,
      t.outlineStyle
    )
    this._qualityFactor = t.qualityFactor || 1
    this._performanceHints = new TileSet3DLayerPerformanceHintsImpl(
      t.performanceHints ?? {}
    )
    this._performanceHints.on('Changed', () => {
      this._scenePainter?.setMaximumNumberOfPoints(
        this._performanceHints.maxPointCount ?? 0
      )
      this._scenePainter?.setOcclusionCulling(
        this._performanceHints.occlusionCulling
      )
      this._invalidate()
      this._eventedSupport.emit('PerformanceHintsChanged')
    })
    this._qualityFactorFalloff = t.qualityFactorDistanceFalloff || {
      nearDistance: 0,
      farDistance: 1,
      farQualityFactorMultiplier: 1,
    }
    this._loadingStrategy =
      t.loadingStrategy ??
      (e instanceof HSPCTilesModel
        ? TileLoadingStrategy.DETAIL_FIRST
        : TileLoadingStrategy.OVERVIEW_FIRST)
    this._terrainMaskEnabled = false
    this._terrainMaskPrecise = false
    this._terrainMaskOffset = 20
    if (isBoolean(t.offsetTerrain)) this._terrainMaskEnabled = t.offsetTerrain
    else if (isObject(t.offsetTerrain)) {
      this._terrainMaskEnabled = isBoolean(t.offsetTerrain.enabled)
        ? t.offsetTerrain.enabled
        : false
      this._terrainMaskPrecise = isBoolean(t.offsetTerrain.precise)
        ? t.offsetTerrain.precise
        : false
      this._terrainMaskOffset = isNumber(t.offsetTerrain.offset)
        ? t.offsetTerrain.offset
        : this._terrainMaskOffset
    }
    this._isPartOfTerrain = t.isPartOfTerrain ?? true
    this._isDrapeTarget = t.isDrapeTarget ?? false
    this._drapeSlopeAngle = isNumber(t.drapeSlopeAngle)
      ? t.drapeSlopeAngle >= 0 && t.drapeSlopeAngle <= 90
        ? t.drapeSlopeAngle
        : 90
      : 90
    this._updateComplete = false
    this._sceneReadyState = false
    this._warningsEnabled = isBoolean(t.warningsEnabled)
      ? !!t.warningsEnabled
      : false
    this._transformation = t.transformation || null
    this._scenePainter = null
    this._dataLoader = null
    this._scenePainterCallback = null
    this._transparency = t.transparency ?? false
    this._textureCompression = t.textureCompression ?? true
    this._debugTileBounds = location?.search?.indexOf('debug=tiles') > 0
    this._debugCameraUpdate = true
    this._loadingStatus = { tilesLoaded: 0, tilesPending: 0 }
  }
  set sceneReadyState(e) {
    this._sceneReadyState = e
  }
  updatePhotonTerrainMask() {
    this._scenePainter?.setTerrainMaskEnabled(
      this._terrainMaskEnabled &&
        this._is3D &&
        this.visible &&
        this.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY)
    )
  }
  scenePainterEnabledUpdate() {
    if (this._scenePainter)
      this._scenePainter.setEnabled(
        this.visible &&
          this.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY)
      )
  }
  isPaintRepresentationSupported(e) {
    return PaintRepresentation.BODY === e
  }
  setDetailFactor(e) {
    this._scenePainter?.setDetailFactor(e)
    this._invalidate()
  }
  isReady() {
    return this._updateComplete && this._sceneReadyState
  }
  _addedToMap(e) {
    if (!e || !e.viewPaintingStrategy) return
    if ('Photon' !== e.viewPaintingStrategy.techContext.type)
      throw new ProgrammingError('A MeshLayer can only be added to a WebGLMap')
    super._addedToMap(e)
    this._is3D = e.is3D()
    e.onReady(() => {
      const t = e.viewPaintingStrategy.techContext
      this._initializePhotonScenePainter(t)
      this._map = e
      this._updateModelWorldTransformation(this.orientedBox.reference)
      if (!this._mapSelectionListener)
        this._mapSelectionListener = e.on('SelectionChanged', (e) => {
          const t = this.getSelectedFeatures().map((e) => e.id)
          this._meshStyle.setSelectedIds(t)
        })
      this._invalidate()
    })
  }
  _removedFromMap(e) {
    super._removedFromMap(e)
    this._releasePhotonScenePainter()
    if (this._mapSelectionListener) {
      this._mapSelectionListener.remove()
      this._mapSelectionListener = null
    }
    this._map = null
  }
  update(e) {
    this._updateComplete = this._scenePainter
      ? this._scenePainter.update()
      : false
    return this._updateComplete
  }
  _paintBodyLabels(e, t, i) {
    return !t && this._scenePainter
      ? this._scenePainter.paint(
          e.photonGraphics,
          false,
          i.paintOpacity,
          i.paintDraping,
          i.paintOutput,
          i.clip
        )
      : true
  }
  _packAttributes() {
    return Array.from(this._meshStyleAttributes).join(',')
  }
  _releasePhotonScenePainter() {
    this._scenePainter = releaseObject(this._scenePainter)
    this._dataLoader = releaseObject(this._dataLoader)
    this._scenePainterCallback = releaseObject(this._scenePainterCallback)
    this._propertiesCallback = releaseObject(this._propertiesCallback)
    this._propertiesDescriptor = releaseObject(this._propertiesDescriptor)
  }
  updatePhotonPointCloudStyle() {
    if (this._scenePainter)
      this._pointCloudStyle.updatePhotonScenePainter(this._scenePainter)
  }
  updatePhotonMeshStyle() {
    if (this._scenePainter)
      this._meshStyle.updatePhotonScenePainter(
        this._scenePainter,
        this._idProperty
      )
  }
  setOutlineOcclusionStyle() {
    if (this._scenePainter && this._outlineOcclusionStyle)
      this._outlineOcclusionStyle.setOutlineOcclusionStyle(this._scenePainter)
  }
  _collectLabels(e, t) {}
  pick(e) {
    const t = []
    if (!this._map || !this._scenePainter) return t
    const i = this._scenePainter.pick(e.viewBounds)
    const r = JSON.parse(i)
    this._selectedFeature = null
    if (!isEmpty(r))
      for (const e in r) {
        if (!r.hasOwnProperty(e)) continue
        const i = r[e]
        let s = null
        let a = 'unknown'
        if (isDefined(i.properties) && isDefined(this._idProperty)) {
          s = i.properties
          if (this._idProperty && isDefined(s[this._idProperty]))
            a = s[this._idProperty]
          else if (!this._idPropertyMismatchLogged) {
            const e = this.model
            Log.warn(
              `The model '${e.url}' doest not have the idProperty '${this._idProperty}' configured for selection on TileSet3DLayer '${this.label}'. Selection will not work properly.`
            )
            this._idPropertyMismatchLogged = true
          }
        } else if (
          isDefined(this._idProperty) &&
          !this._idPropertyMismatchLogged
        ) {
          Log.warn(
            `TileSet3DLayer '${this.label}' has an idProperty set for picking (${this._idProperty}), but some picked features have no properties, making it impossible to use it. While this could be intentional, it can also point to a mismatch between expectations and what the dataset actually gives.`
          )
          this._idPropertyMismatchLogged = true
        }
        const n = i.pickInfo.pickPoint
        const o = createPoint(this._map.reference, [n.x, n.y, n.z])
        const l = new Feature(o, s, a)
        this._selectedFeature = l
        this._featureCache.put(a, l)
        t.push(
          new PickInfoImpl(this, l, 0, 0, i.pickInfo.depth, 0, true, false)
        )
        if (!isDefined(this._idProperty)) return t
      }
    return t
  }
  _initializePhotonScenePainter(e) {
    this._releasePhotonScenePainter()
    let t = e.photonGraphics.isTextureFormatSupported('Dxt1')
    let i = e.photonGraphics.isTextureFormatSupported('Etc1')
    if (this._textureCompression && !t && !i)
      Log.warn(
        "TileSet3DLayer has textureCompression on but there's no hardware support. Compression " +
          ' will fallback to rgb565.'
      )
    this._dataLoader = new PhotonDataLoader(
      this.model,
      e.webWorkerPool,
      e.imageDecoder,
      e.textureDecoder,
      {
        forceCompression: this._textureCompression,
        acceptDxt: t,
        acceptEtc1: i,
        minSize: 33,
      }
    )
    this._scenePainterCallback = new PhotonScenePainterCallback(Photon, this)
    const r = undefined
    const s =
      this.model instanceof OGC3DTilesModel
        ? Photon.SceneDataType.Cesium
        : Photon.SceneDataType.Hspc
    if (this._idProperty && this._properties) {
      this._propertiesCallback = new PropertiesCallback(this)
      this._propertiesDescriptor = createPropertiesDescriptor(
        this._idProperty,
        this._properties,
        this._propertiesCallback
      )
    }
    const a = this.model.reference
      ? e.referenceProvider.getReference(this.model.reference)
      : null
    this._scenePainter = Photon.ScenePainterWrapper.create(
      e.photonView,
      e.photonGraphics,
      this._dataLoader,
      a,
      this._label,
      this._rootUrl,
      this._relativeUrlToRootMetadataFile,
      s,
      this._packAttributes(),
      this._scenePainterCallback,
      this._propertiesDescriptor
    )
    a.release()
    this._scenePainter.setTransparency(this._transparency)
    this._scenePainter.setDebugTileBounds(this._debugTileBounds)
    this._scenePainter.setDebugCameraUpdate(this._debugCameraUpdate)
    this._scenePainter.setDetailFactor(this._qualityFactor)
    this._scenePainter.setMaximumNumberOfPoints(
      this._performanceHints.maxPointCount || 0
    )
    this._scenePainter.setOcclusionCulling(
      this._performanceHints.occlusionCulling
    )
    this.setQualityFactorDistanceFalloff(this._qualityFactorFalloff)
    this._scenePainter.setLoadingStrategy(this._loadingStrategy)
    this._scenePainter.setIsPartOfTerrain(this._isPartOfTerrain)
    this._scenePainter.setIsDrapeTarget(this._isDrapeTarget)
    this._scenePainter.setSlopeAngle(this._drapeSlopeAngle / 90)
    this._scenePainter.setFadingTime(this._fadingTime)
    this._scenePainter.setElevationGenerationPrecision(this._terrainMaskPrecise)
    this._scenePainter.setElevationOffset(this._terrainMaskOffset)
    this.updatePhotonTransformation()
    const n = this.map.isGeospatial()
    const o =
      this.model.reference && !this.model.reference.equals(REF_LUCIAD_XYZ)
    const l =
      this.transformation &&
      this.transformation.outputReference &&
      this.transformation.outputReference.equals(this.map.reference)
    if (n && !o && !l)
      throw new Error(
        'Given model for Tileset3DLayer is not referenced, and therefore can not be visualized directly on a map. ' +
          'Please provide a valid transformation to Tileset3DLayer.transformation before adding this layer to the map.'
      )
    this.updatePhotonTerrainMask()
    this.updatePhotonPointCloudStyle()
    this.updatePhotonMeshStyle()
    this.setOutlineOcclusionStyle()
    this.on(
      'PaintRepresentationVisibilityChanged',
      this.updatePhotonTerrainMask.bind(this)
    )
    this.on('VisibilityChanged', this.updatePhotonTerrainMask.bind(this))
    this.on(
      'PaintRepresentationVisibilityChanged',
      this.scenePainterEnabledUpdate.bind(this)
    )
    this.on('VisibilityChanged', this.scenePainterEnabledUpdate.bind(this))
  }
  eraseProperties(e) {
    if (
      !validateNumericalArray(
        e,
        'Only numbers are allowed as id for your eraseProperties, no erase will follow.'
      )
    )
      return
    const t = Photon.BufferFactory.createUint32BufferFromData(
      new Uint32Array(e)
    )
    this._scenePainter?.eraseClientDataInfo(t)
    t.release()
  }
  updateProperties(e) {
    if (!validateUpdates(e)) return
    const t = Object.keys(e).length
    if (t > 0) {
      const i = Object.getOwnPropertyNames(e)
      for (let r = 0; r < t; r++) {
        const t = i[r]
        const s = e[t].ids
        const a = Photon.BufferFactory.createUint32BufferFromData(
          new Uint32Array(s)
        )
        let n = []
        let o
        if (Object.getOwnPropertyNames(e[t]).indexOf('value') >= 0) {
          n = [e[t].value]
          o = Photon.BufferFactory.createFloat32BufferFromData(
            new Float32Array(n)
          )
          this._scenePainter?.resetClientDataInfo(t)
          this._scenePainter?.updateClientDataInfo(a, t, o, true)
        } else if (Object.getOwnPropertyNames(e[t]).indexOf('values') >= 0) {
          n = e[t].values
          o = Photon.BufferFactory.createFloat32BufferFromData(
            new Float32Array(n)
          )
          this._scenePainter?.updateClientDataInfo(a, t, o, false)
        }
        a.release()
        o.release()
        this.actOnPropertiesUpdate(s, t, n)
      }
    }
  }
  actOnFeaturesInViewChanged(e, t) {
    this._eventedSupport.emit('FeaturesInViewChange', e, t)
  }
  actOnPropertiesUpdate(e, t, i) {
    if (null !== this._selectedFeature && this._propertiesDescriptor) {
      const r = this._idProperty
      if (this._selectedFeature.properties.hasOwnProperty(r)) {
        const s = this._selectedFeature.properties[r]
        const a = e.indexOf(s)
        if (-1 !== a) {
          if (1 === i.length) this._selectedFeature.properties[t] = i[0]
          else this._selectedFeature.properties[t] = i[a]
          this._map?.updateBalloon()
        }
      }
    }
    this._map?.invalidate()
  }
  get model() {
    return super.model
  }
  get transformation() {
    return this._transformation
  }
  set transformation(e) {
    if (null === e) {
      if (null !== this._transformation) {
        this._transformation = null
        this._invalidate()
        this.emit('TransformationChanged', this._transformation)
      }
      return
    }
    if (
      e instanceof Affine3DTransformation &&
      isDefined(e.getTransformationMatrix)
    ) {
      this._transformation = e
      if (this._terrainMaskEnabled) {
        Log.debug(
          `offsetTerrain has been disabled for layer ${this.label}, because a custom transformation was set.`
        )
        this._terrainMaskEnabled = false
      }
      this.updatePhotonTransformation()
      this._invalidate()
      this.emit('TransformationChanged', this._transformation)
    } else
      Log.warn(
        'Incorrect argument given for TileSet3DLayer.transformation. Should be an Affine3DTransformation.'
      )
  }
  updatePhotonTransformation() {
    if (this._scenePainter && this._transformation) {
      let e
      try {
        e = Photon.BufferFactory.createFloat64BufferFromData(
          new Float64Array(
            this._transformation.getTransformationMatrix().toArray()
          )
        )
        Photon.ScenePainterWrapper.setTransformation(e, this._scenePainter)
      } finally {
        if (e) e.release()
      }
    }
  }
  get bounds() {
    return this.orientedBox.bounds
  }
  set bounds(e) {
    throw new ProgrammingError('bounds property is not mutable')
  }
  get meshStyle() {
    return this._meshStyle
  }
  set meshStyle(e) {
    if (this._meshStyle) this._meshStyle.release()
    e = e || {}
    this._meshStyle =
      e instanceof MeshStyleImpl && e.layer === this
        ? e
        : MeshStyleImpl.create(e, this)
    this.updatePhotonMeshStyle()
  }
  get fadingTime() {
    return this._fadingTime
  }
  set fadingTime(e) {
    if (this._scenePainter) this._scenePainter.setFadingTime(e)
    this._fadingTime = e
  }
  get pointCloudStyle() {
    return this._pointCloudStyle
  }
  set pointCloudStyle(e) {
    if (this._pointCloudStyle) this._pointCloudStyle.release()
    e = e || {}
    this._pointCloudStyle =
      e instanceof PointCloudStyleImpl && e.layer === this
        ? e
        : PointCloudStyleImpl.create(e, this)
    this.updatePhotonPointCloudStyle()
  }
  get outlineStyle() {
    return this._outlineOcclusionStyle.outlineStyle
  }
  set outlineStyle(e) {
    this._outlineOcclusionStyle.outlineStyle = e
  }
  get occlusionStyle() {
    return this._outlineOcclusionStyle.occlusionStyle
  }
  set occlusionStyle(e) {
    this._outlineOcclusionStyle.occlusionStyle = e
  }
  get transparency() {
    return this._transparency
  }
  set transparency(e) {
    if (!isBoolean(e))
      throw new ProgrammingError(
        'TileSet3DLayer.transparency should be a boolean.'
      )
    this._transparency = e
    this._scenePainter?.setTransparency(this._transparency)
    this._invalidate()
  }
  get orientedBox() {
    const e = this.model.orientedBox
    if (!this.transformation) return e
    else {
      const t = e.copy()
      t.transform(this.transformation)
      return t
    }
  }
  get isPartOfTerrain() {
    return this._isPartOfTerrain
  }
  set isPartOfTerrain(e) {
    this._isPartOfTerrain = e
    this._scenePainter?.setIsPartOfTerrain(e)
    this._invalidate()
  }
  get isDrapeTarget() {
    return this._isDrapeTarget
  }
  set isDrapeTarget(e) {
    if (this._isDrapeTarget != e) {
      this._isDrapeTarget = e
      this._scenePainter?.setIsDrapeTarget(e)
      this._invalidate()
    }
  }
  get drapeSlopeAngle() {
    return this._drapeSlopeAngle
  }
  set drapeSlopeAngle(e) {
    if (e >= 0 && e <= 90 && this._drapeSlopeAngle !== e) {
      this._drapeSlopeAngle = e
      this._scenePainter?.setSlopeAngle(e / 90)
      this._invalidate()
    }
  }
  get qualityFactor() {
    return this._qualityFactor
  }
  set qualityFactor(e) {
    if (e <= 0)
      throw new ProgrammingError(
        'qualityFactor should be positive non-zero number.'
      )
    this._qualityFactor = e
    this._scenePainter?.setDetailFactor(this._qualityFactor)
    this._invalidate()
  }
  get performanceHints() {
    return this._performanceHints
  }
  set performanceHints(e) {
    this._performanceHints.setFrom(e)
  }
  get qualityFactorDistanceFalloff() {
    return this._qualityFactorFalloff
  }
  set qualityFactorDistanceFalloff(e) {
    this.setQualityFactorDistanceFalloff(e)
    this._invalidate()
  }
  setQualityFactorDistanceFalloff(e) {
    this._qualityFactorFalloff = e
    if (e)
      if (isQualityFactorAdaptiveFalloffOptions(e))
        this._scenePainter?.setDepthPercentageFalloff(
          true,
          e.percentage,
          e.distanceInterpolationMultiplier,
          e.farQualityFactorMultiplier
        )
      else
        this._scenePainter?.setDistanceFalloff(
          e.nearDistance,
          e.farDistance,
          e.farQualityFactorMultiplier
        )
    else {
      this._scenePainter?.setDistanceFalloff(0, 1, 1)
      this._scenePainter?.setDepthPercentageFalloff(false, 1, 1, 1)
    }
  }
  get loadingStrategy() {
    return this._loadingStrategy
  }
  set loadingStrategy(e) {
    if (
      e != TileLoadingStrategy.DETAIL_FIRST &&
      e != TileLoadingStrategy.OVERVIEW_FIRST
    )
      throw new ProgrammingError(
        'TileSet3DLayer.loadingStrategy property should be either TileLoadingStrategy.DETAIL_FIRST (0) or TileLoadingStrategy.OVERVIEW_FIRST (1).'
      )
    this._loadingStrategy = e
    this._scenePainter?.setLoadingStrategy(this._loadingStrategy)
    this._invalidate()
  }
  get idProperty() {
    return this._idProperty
  }
  set idProperty(e) {
    throw new ProgrammingError('idProperty is not mutable')
  }
  get debugTileBounds() {
    return this._debugTileBounds
  }
  set debugTileBounds(e) {
    this._debugTileBounds = e
    this._scenePainter?.setDebugTileBounds(e)
    this._invalidate()
  }
  get debugCameraUpdate() {
    return this._debugCameraUpdate
  }
  set debugCameraUpdate(e) {
    this._debugCameraUpdate = e
    this._scenePainter?.setDebugCameraUpdate(e)
    this._invalidate()
  }
  get supportedPaintRepresentations() {
    return [PaintRepresentation.BODY]
  }
  get selectable() {
    return this._selectionSupport.selectable
  }
  set selectable(e) {
    if (e && !isString(this._idProperty)) {
      Log.warn(
        'Layer is set to be selectable, but no ID property was specified. Selection will not work, and has been disabled.'
      )
      this._selectionSupport.selectable = false
    } else this._selectionSupport.selectable = e
  }
  get loadingStatus() {
    return this._loadingStatus
  }
  set loadingStatus(e) {
    if (
      this._loadingStatus.tilesLoaded !== e.tilesLoaded ||
      this._loadingStatus.tilesPending !== e.tilesPending
    ) {
      this._loadingStatus = e
      this._eventedSupport.emit('LoadingStatusChanged', e)
    }
  }
  getSelectedFeatures() {
    return this._selectionSupport.getSelectedFeatures()
  }
  clearSelection() {
    return this._selectionSupport.clearSelection()
  }
  isSelected(e) {
    return this._selectionSupport.isSelected(e)
  }
  selectFeatures(e) {
    return this._selectionSupport.selectFeatures(e)
  }
  on(e, t, i) {
    if ('FeaturesInViewChange' === e || 'LoadingStatusChanged' === e)
      return this._eventedSupport.on(e, t, i)
    else if ('PerformanceHintsChanged' === e)
      this._performanceHints.on('Changed', t)
    return super.on(e, t, i)
  }
}
export { TileSet3DLayer }
export function createPropertiesDescriptor(e, t, i) {
  const r = []
  const s = []
  const a = Object.keys(t).length
  if (a > 0) {
    const e = Object.getOwnPropertyNames(t)
    for (let i = 0; i < a; i++) {
      const a = e[i]
      const n = t[a].default
      if ('number' !== typeof n || Number.isNaN(n)) {
        Log.warn(
          'Only numerical values are allowed as default value for your properties, your properties will not be used.'
        )
        return null
      }
      s.push(a)
      r.push(n)
    }
  }
  const n = Photon.BufferFactory.createFloat32BufferFromData(
    new Float32Array(r)
  )
  const o = Photon.BufferFactory.createUint8BufferFromData(
    new Uint8Array(createByteSizesArrayFromFallbackValues(r))
  )
  const l = Photon.ScenePainterWrapper.createClientAttributeDescriptor(
    e,
    i,
    n,
    o,
    createCommaSeparatedStringFromStringList(s)
  )
  n.release()
  o.release()
  return l
}
function createByteSizesArrayFromFallbackValues(e) {
  const t = new Array(e.length)
  for (let i = 0; i < e.length; i++) t[i] = 4
  return t
}
function createCommaSeparatedStringFromStringList(e) {
  let t = ''
  for (let i = 0; i < e.length; i++) {
    if (0 !== i) t += ','
    t += e[i]
  }
  return t
}
function validateUpdates(e) {
  const t = Object.keys(e).length
  let i = true
  if (t > 0) {
    const r = Object.getOwnPropertyNames(e)
    for (let s = 0; s < t; s++)
      if (i) {
        const t = r[s]
        const a = undefined
        i = validateNumericalArray(
          e[t].ids,
          'Only numbers are allowed as id for your propertiesUpdate, no update will follow.'
        )
        if (i) {
          let r = []
          if (Object.getOwnPropertyNames(e[t]).indexOf('value') >= 0)
            r = [e[t].value]
          else if (Object.getOwnPropertyNames(e[t]).indexOf('values') >= 0)
            r = e[t].values
          i = validateNumericalArray(
            r,
            'Only numbers are allowed as value for your propertiesUpdate, no update will follow.'
          )
        }
      }
  }
  return i
}
function validateNumericalArray(e, t) {
  for (const i of e)
    if ('number' !== typeof i || Number.isNaN(i)) {
      Log.warn(t)
      return false
    }
  return true
}
