import { WMSCapabilitiesUtil } from './WMSCapabilitiesUtil.js'
import { WMSCapabilitiesParserV130 } from './WMSCapabilitiesParserV130.js'
import { WMSCapabilitiesParserV111 } from './WMSCapabilitiesParserV111.js'
const SUPPORTED_VERSIONS = ['1.3.0', '1.1.1']
function createParser(e, t) {
  switch (e) {
    case '1.3.0':
      return new WMSCapabilitiesParserV130(t)
    case '1.1.1':
      return new WMSCapabilitiesParserV111(t)
    default:
      throw new Error(`Can not parse unsupported WMS version: ${e}`)
  }
}
export class WMSCapabilities {
  constructor(e, t) {
    this._version = e
    this._capabilitiesXML = t
    const i = createParser(e, t)
    this._parser = i
    this._service = i.parseServiceMetadata()
    this._updateSequence = i.parseUpdateSequence()
    this._layers = i.parseLayers()
    this._operations = i.parseOperations()
    this._exceptionFormats = i.parseExceptionFormats()
    this._userDefinedSymbolization = i.parseUserDefinedSymbolization()
  }
  static fromURL(e, t) {
    const i = {
      credentials: (t = t || {}).credentials,
      requestHeaders: t.requestHeaders,
      requestParameters: t.requestParameters,
    }
    const r = undefined
    return WMSCapabilitiesUtil.createVersionNegotiator(
      e,
      SUPPORTED_VERSIONS,
      t.allowedVersions,
      i
    )
      .negotiateVersion()
      .then((e) => new WMSCapabilities(e.version, e.response))
  }
  static fromXML(e) {
    const t = WMSCapabilitiesUtil.parseVersion(e)
    const i = new WMSCapabilities(t, e)
    return Promise.resolve(i)
  }
  getRawCapabilities() {
    return this._capabilitiesXML
  }
  get version() {
    return this._version
  }
  get service() {
    return this._service
  }
  get updateSequence() {
    return this._updateSequence
  }
  get layers() {
    return this._layers
  }
  get operations() {
    return this._operations
  }
  get exceptionFormats() {
    return this._exceptionFormats
  }
  get userDefinedSymbolization() {
    return this._userDefinedSymbolization
  }
}
