import { ReferenceType } from '../../../reference/ReferenceType.js'
import { squaredDistance2D_flat } from '../../../util/Cartesian.js'
import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
import { getHeightAboveTerrainReference } from '../../../reference/ReferenceProvider.js'
import { createPoint } from '../../../shape/ShapeFactory.js'
import { VecMath } from '../../../util/VecMath.js'
import { PerspectiveCamera } from '../../camera/PerspectiveCamera.js'
import { createTransformation } from '../../../transformation/TransformationFactory.js'
import { LayerTreeVisitor } from '../../LayerTreeVisitor.js'
import { isBoolean } from '../../../util/Lang.js'
import { LayerTreeNode } from '../../LayerTreeNode.js'
import { rayPlaneIntersection } from '../../../util/Intersection.js'
export function viewToModel(e, r, t) {
  try {
    const n = r.onTerrainViewModelTransformation.transform(e)
    const o = t ? 0 : n.z
    n.move3D(n.x, n.y, o)
    return n
  } catch (e) {
    OutOfBoundsError.isOrThrow(e)
    return null
  }
}
export function viewToModelOnHeightAxis(e, r, t) {
  if (!(r.map.camera instanceof PerspectiveCamera)) return null
  const n = r.map.camera.toWorld(e)
  const o = createTransformation(t.reference, r.map.reference)
  const a = t.copy()
  const i = a.copy()
  i.translate3D(0, 0, 1e3)
  const s = o.transform(a)
  const c = o.transform(i)
  const f = r.map.camera.eyePoint
  const u = closestPointOnLine(s, c, f)
  const m = VecMath.normalize({ x: f.x - u.x, y: f.y - u.y, z: f.z - u.z })
  const y = VecMath.normalize({ x: n.x - f.x, y: n.y - f.y, z: n.z - f.z })
  const T = rayPlaneIntersection(m, u, f, y)
  if (!T) return null
  const p = closestPointOnLine(s, c, T)
  const l = createPoint(r.map.reference, [p.x, p.y, p.z])
  const O = createTransformation(r.map.reference, t.reference)
  try {
    return O.transform(l)
  } catch (e) {
    OutOfBoundsError.isOrThrow(e)
    return null
  }
}
export function closestPointOnLine(e, r, t) {
  const n =
    (r.x - e.x) * (r.x - e.x) +
    (r.y - e.y) * (r.y - e.y) +
    (r.z - e.z) * (r.z - e.z)
  const o =
    ((t.x - e.x) * (r.x - e.x) +
      (t.y - e.y) * (r.y - e.y) +
      (t.z - e.z) * (r.z - e.z)) /
    n
  return {
    x: e.x + o * (r.x - e.x),
    y: e.y + o * (r.y - e.y),
    z: e.z + o * (r.z - e.z),
  }
}
export function convertToAboveGround(e, r) {
  const t = undefined
  if (!(e.reference.referenceType === ReferenceType.GEOCENTRIC)) return r
  const n = e.getOnTerrainModelWorldTransformation(r.reference)
  const o =
    e.reference.referenceType === ReferenceType.CARTESIAN
      ? n.inputReference
      : getHeightAboveTerrainReference(n.inputReference.identifier)
  return createPoint(o, [r.x, r.y, 0])
}
export function removeNullHandles(e) {
  return e.filter((e) => null !== e)
}
export function squaredViewDistance(e, r, t) {
  try {
    const n = e.onTerrainModelMapTransformation.transform(r)
    const o = e.map.mapToViewTransformation.transform(n)
    return squaredDistance2D_flat(o, t)
  } catch (e) {
    OutOfBoundsError.isOrThrow(e)
    return Number.POSITIVE_INFINITY
  }
}
export function isDrapableMeshOnMap(e) {
  let r = false
  const t = {
    visitLayer(e) {
      const t = e
      if (isBoolean(t.isDrapeTarget) && t.isDrapeTarget) {
        r = true
        return LayerTreeVisitor.ReturnValue.ABORT
      }
      return LayerTreeVisitor.ReturnValue.CONTINUE
    },
    visitLayerGroup(e) {
      e.visitChildren(t, LayerTreeNode.VisitOrder.TOP_DOWN)
      return r
        ? LayerTreeVisitor.ReturnValue.ABORT
        : LayerTreeVisitor.ReturnValue.CONTINUE
    },
  }
  e.layerTree.visitChildren(t, LayerTreeNode.VisitOrder.TOP_DOWN)
  return r
}
