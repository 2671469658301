import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { interpolateLLHCoordinate } from './LLHEllipseUtil.js'
import { interpolateXYZCoordinate } from './XYEllipseUtil.js'
export class Circle extends Shape {
  get type() {
    return ShapeType.CIRCLE
  }
  move2DToPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    this.move2DToCoordinates(e.x, e.y)
  }
  invalidate() {
    this._cache.invalidate()
  }
  interpolateCoordinate(e) {
    const t = {
      b: this.radius,
      a: this.radius,
      rotationAzimuth: 0,
      center: this.center,
      reference: this.reference,
    }
    return this.coordinateType === CoordinateType.GEODETIC
      ? interpolateLLHCoordinate(t, e)
      : interpolateXYZCoordinate(t, e)
  }
  interpolateToCoordinates() {
    let e
    const t =
      1 / (arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 40)
    const o = []
    for (let e = 0; e < 1; e += t) o.push(this.interpolateCoordinate(e))
    o.push(this.interpolateCoordinate(1))
    return o
  }
}
export function isCircle(e) {
  return ShapeType.contains(ShapeType.CIRCLE, e.type)
}
