export class DecimalFormat {
  constructor(i) {
    this._minFracDigits = i && void 0 !== i.minFracDigits ? i.minFracDigits : 0
    this._maxFracDigits = i && void 0 !== i.maxFracDigits ? i.maxFracDigits : 2
    this._minIntegerDigits =
      i && void 0 !== i.minIntegerDigits ? i.minIntegerDigits : 0
    this._prefix = i && i.prefix ? i.prefix : ''
    this._suffix = i && i.suffix ? i.suffix : ''
    this._decimalSeparator = i && i.decimalSeparator ? i.decimalSeparator : '.'
    this._groupingSeparator =
      i && i.groupingSeparator ? i.groupingSeparator : ''
    this._groupingLength =
      i && void 0 !== i.groupingLength ? i.groupingLength : 3
  }
  format(i) {
    if (isNaN(i) || 0 === i.toString().length) return 'NaN'
    const t = i < 0
    i = Math.abs(i)
    let r = Math.floor(i)
    let n = i - r
    n = roundFractionalPart(n, this._maxFracDigits)
    if (n >= 1) {
      r++
      n -= 1
    }
    let a = this._prefix
    const e = undefined
    if (t && !(0 === r && 0 === n)) a += '-'
    let s = r.toString()
    s = addLeadingZeros(s, this._minIntegerDigits)
    s = addGroupingSeparators(s, this._groupingSeparator, this._groupingLength)
    a += s
    if (this._minFracDigits > 0 || (n > 0 && this._maxFracDigits > 0)) {
      a += this._decimalSeparator
      const i = undefined
      a += addTrailingZerosAndTrimExcessiveDigits(
        n.toString().substring(2),
        this._minFracDigits,
        this._maxFracDigits
      )
    }
    a += this._suffix
    return a
  }
}
function addGroupingSeparators(i, t, r) {
  if (t && 0 === t.length) return i
  let n = 0
  for (let a = i.length; a > 0; a--) {
    if (0 !== n && n % r === 0) i = i.substring(0, a) + t + i.substring(a)
    n++
  }
  return i
}
function roundFractionalPart(i, t) {
  const r = Math.pow(10, t)
  i *= r
  i = Math.round(i)
  return (i /= r)
}
function addLeadingZeros(i, t) {
  while (i.length < t) i = `0${i}`
  return i
}
function addTrailingZerosAndTrimExcessiveDigits(i, t, r) {
  while (i.length < t) i = `${i}0`
  return i.length > r ? i.substring(0, r) : i
}
