export class DrawCompositeLabel {
  constructor(e) {
    this.commands = e
    this.index = 0
    this.handle = null
  }
  isReady() {
    for (let e = 0; e < this.commands.length; e++)
      if (!this.commands[e].isReady()) return false
    return true
  }
  requestToDrawLabel(e) {
    const t = this.commands
    for (let s = 0, n = t.length; s < n; s++) t[s].requestToDrawLabel(e)
  }
  findLabelDrawCommand(e, t) {
    const s = this.commands
    for (let n = 0, o = s.length; n < o; n++) {
      const o = s[n].findLabelDrawCommand(e, t)
      if (o) return o
    }
    return null
  }
  reuseStateFrom(e) {
    const t = this.commands
    for (let s = 0, n = t.length; s < n; s++) t[s].reuseStateFrom(e)
  }
  setPickInfoContext(e, t) {
    this.commands.forEach((s) => {
      s.setPickInfoContext(e, t)
    })
  }
  canBeReused() {
    for (let e = 0, t = this.commands.length; e < t; e++)
      if (!this.commands[e].canBeReused()) return false
    return true
  }
}
