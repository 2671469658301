import { GestureEventType } from '../input/GestureEventType.js'
import { PickController } from './PickController.js'
import { HandleEventResult } from './HandleEventResult.js'
export class ContextMenuController extends PickController {
  constructor() {
    super()
  }
  isPickEvent(e) {
    return (
      e.type === GestureEventType.CONTEXT_MENU ||
      e.type === GestureEventType.LONG_PRESS
    )
  }
  getCandidates(e, t, n) {
    if (!this.map || !e) return []
    const r = this.map._pickClosestObject(e.x, e.y, t, true, n)
    return r ? [r] : []
  }
  handleCandidates(e, t) {
    if (!this.map || 0 === t.length) return HandleEventResult.EVENT_IGNORED
    const n = t[0]
    const r = undefined
    return this.map.showContextMenu(e.pagePosition, n)
      ? HandleEventResult.EVENT_HANDLED
      : HandleEventResult.EVENT_IGNORED
  }
}
