import { isDefined, isNumber } from '../util/Lang.js'
export class DepthOfFieldEffectImpl {
  constructor(e) {
    this.setToLiteral(e)
  }
  setToLiteral(e) {
    if (!isDefined(e)) return
    if (isNumber(e.scale)) this.scale = e.scale
    else this.scale = 1
    if (isNumber(e.focalDepth)) this.focalDepth = e.focalDepth
    else this.focalDepth = 200
    if (isNumber(e.focusRange)) this.focusRange = e.focusRange
    else
      this.focusRange =
        e.noFocusRange && isDefined(e.noFocusRange) ? e.noFocusRange / 2 : 200
    if (isNumber(e.noFocusRange)) this.noFocusRange = e.noFocusRange
    else
      this.noFocusRange =
        e.focusRange && isDefined(e.focusRange) ? 2 * e.focusRange : 400
  }
  get scale() {
    return this._scale
  }
  set scale(e) {
    if (isNumber(e) && e >= 0) this._scale = e
    else throw new Error(`Invalid depth of field scale: ${e}`)
  }
  get focalDepth() {
    return this._focalDepth
  }
  set focalDepth(e) {
    if (isNumber(e) && e >= 0) this._focalDepth = e
    else throw new Error(`Invalid depth of field focal depth: ${e}`)
  }
  get focusRange() {
    return this._focusRange
  }
  set focusRange(e) {
    if (isNumber(e) && e >= 0) this._focusRange = e
    else throw new Error(`Invalid depth of field focus range: ${e}`)
  }
  get noFocusRange() {
    return this._noFocusRange
  }
  set noFocusRange(e) {
    if (isNumber(e) && e >= 0) this._noFocusRange = e
    else throw new Error(`Invalid depth of field no focus range: ${e}`)
  }
}
