import { LLHPoint } from '../shape/LLHPoint.js'
import { Hash } from '../util/Hash.js'
import { ProjectionType } from './ProjectionType.js'
import { BaseProjection } from './BaseProjection.js'
export class TransverseCylindrical extends BaseProjection {
  constructor() {
    super()
    this._origin = new LLHPoint()
    this._hash = 0
    this.calculateCachedValues()
  }
  setCentralMeridian(e) {
    this._origin.x = e
    this.calculateCachedValues()
  }
  setOriginLat(e) {
    this._origin.y = e
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    const e = new Hash()
    this._hash = e
      .appendDouble(this.getCentralMeridian())
      .appendDouble(this.getOriginLat())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  getOrigin() {
    return this._origin
  }
  getOriginLat() {
    return this._origin.y
  }
  getCentralMeridian() {
    return this._origin.x
  }
  isContinuous() {
    return true
  }
  equals(e) {
    if (!super.equals(e)) return false
    const t = e
    return (
      this.getCentralMeridian() == t.getCentralMeridian() &&
      this.getOriginLat() == t.getOriginLat()
    )
  }
  get TYPE() {
    return ProjectionType.TRANSVERSECYLINDRICAL
  }
  hashCode(e) {
    e.appendUInt32(this._hash)
  }
}
