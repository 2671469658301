import { isString } from '../../util/Lang.js'
import { createHTML5Canvas } from '../HTML5Canvas.js'
import { LRUCache } from '../../util/LRUCache.js'
const MAX_FONT_SIZE = 200
const MAX_CANVAS_SIZE = 2.5 * MAX_FONT_SIZE
const canvas = createHTML5Canvas(1, MAX_CANVAS_SIZE)
const context = createCanvasContext()
const PX_REGEX = /(\d+(\.\d+)?)px/
function getFontSize(t) {
  const e = t.match(PX_REGEX)
  if (e) return parseFloat(e[1])
  return 1
}
const replaceFontSize = (t, e) => t.replace(PX_REGEX, `${e}px`)
const roundToHalf = (t) => Math.round(2 * t) / 2
const roundToDecimal = (t) => Math.round(10 * t) / 10
export function getFontScale(t) {
  const e = getFontSize(t)
  const n = e > MAX_FONT_SIZE ? MAX_FONT_SIZE / e : 1
  const o = undefined
  return {
    fontScale: n,
    scaledFont: 1 !== n ? replaceFontSize(t, MAX_FONT_SIZE) : t,
  }
}
const HEIGHT_TEST = '_'
const TOP = 'top'
const ALPHABETIC = 'alphabetic'
const IDEOGRAPHIC = 'ideographic'
const HANGING = 'hanging'
const BOTTOM = 'bottom'
const MIDDLE = 'middle'
const START = 'start'
const LEFT = 'left'
const END = 'end'
const RIGHT = 'right'
const CENTER = 'center'
const cache = new LRUCache(200)
function createCanvasContext() {
  const t = canvas.getContext('2d', { willReadFrequently: true })
  t.textAlign = 'center'
  t.fillStyle = 'rgb(77,77,77)'
  return t
}
function isPixelFilledAt(t, e) {
  return t[4 * e] > 0
}
function measure() {
  let t =
    arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : ALPHABETIC
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.beginPath()
  context.textBaseline = t
  context.fillText(HEIGHT_TEST, 1, canvas.height / 2)
  const { height: e, data: n } = context.getImageData(0, 0, 1, canvas.height)
  let o = 0
  while (o < e && !isPixelFilledAt(n, o)) o++
  if (0 === o || o === e)
    throw new Error('FontMetrics: could not determine font metrics.')
  return 0 | o
}
export class FontMetrics {
  constructor(t) {
    const { fontScale: e, scaledFont: n } = getFontScale(t)
    this._scaledFont = n
    this._fontScale = e
    context.font = n
    const o = (t) => measure(t) / e
    const c = o(TOP)
    const s = o(BOTTOM)
    const i = o(ALPHABETIC)
    const a = o(HANGING)
    const r = o(MIDDLE)
    const h = o(IDEOGRAPHIC)
    this._font = t
    this._top = roundToDecimal(c - i)
    this._hanging = roundToDecimal(a - i)
    this._middle = roundToDecimal(r - i)
    this._alphabetic = 0
    this._ideographic = roundToDecimal(h - i)
    this._bottom = roundToDecimal(s - i)
    this._ascent = Math.abs(this._top)
    this._descent = Math.abs(this._bottom)
    this._lineHeight = Math.abs(this.top - this.bottom)
  }
  get ascent() {
    return this._ascent
  }
  get descent() {
    return this._descent
  }
  get top() {
    return this._top
  }
  get hanging() {
    return this._hanging
  }
  get middle() {
    return this._middle
  }
  get alphabetic() {
    return this._alphabetic
  }
  get ideographic() {
    return this._ideographic
  }
  get bottom() {
    return this._bottom
  }
  get lineHeight() {
    return this._lineHeight
  }
  measureText(t, e, n) {
    context.clearRect(0, 0, canvas.width, canvas.height)
    context.font = this._scaledFont
    let o = 0
    let c = 0
    let s
    let i
    if (t) {
      if (isString(t)) {
        i = t.split('\n')
        o = i.length
      } else {
        i = [t]
        o = 1
      }
      for (let t = 0; t < o; t++) {
        s = context.measureText(i[t]).width / this._fontScale
        if (s > c) c = s
      }
    }
    let a
    switch (e) {
      case START:
      case LEFT:
      default:
        a = 0
        break
      case END:
      case RIGHT:
        a = -c
        break
      case CENTER:
        a = (-c / 2) | 0
    }
    const r = a
    const h = c + a
    let l
    switch (n) {
      case TOP:
        l = this._top
        break
      case IDEOGRAPHIC:
        l = this._ideographic
        break
      case HANGING:
        l = this._hanging
        break
      case BOTTOM:
        l = this._bottom
        break
      case MIDDLE:
        l = this._middle
        break
      case ALPHABETIC:
      default:
        l = this._alphabetic
    }
    const d = -this._ascent + l
    const _ = undefined
    return { bounds: [r, d, h, d + this._lineHeight * o], linesOfText: o }
  }
}
export function createFontMetrics(t) {
  const e = getFontSize(t)
  const n = roundToHalf(e)
  const o = e === n ? t : replaceFontSize(t, n)
  let c = cache.get(o)
  if (!c) {
    c = new FontMetrics(o)
    cache.put(o, c)
  }
  return c
}
