import { EventedSupport } from '../../util/EventedSupport.js'
export const SHAPE_PROVIDER_INVALIDATE_SHAPE_EVENT = 'InvalidateShape'
export const SHAPE_PROVIDER_INVALIDATE_SHAPE_BY_ID_EVENT = 'InvalidateShapeById'
export const SHAPE_PROVIDER_INVALIDATE_ALL_SHAPES_EVENT = 'InvalidateAllShapes'
class ShapeProvider {
  constructor() {
    this._reference = null
  }
  get eventedSupport() {
    if (!this._eventedSupport) this._eventedSupport = new EventedSupport()
    return this._eventedSupport
  }
  provideShape(e) {
    return e.shape
  }
  invalidate(e) {
    if (e) this.eventedSupport.emit(SHAPE_PROVIDER_INVALIDATE_SHAPE_EVENT, e)
    else this.eventedSupport.emit(SHAPE_PROVIDER_INVALIDATE_ALL_SHAPES_EVENT)
  }
  invalidateById(e) {
    this.eventedSupport.emit(SHAPE_PROVIDER_INVALIDATE_SHAPE_BY_ID_EVENT, e)
  }
  invalidateAll() {
    this.eventedSupport.emit(SHAPE_PROVIDER_INVALIDATE_ALL_SHAPES_EVENT)
  }
  on(e, t, E) {
    return this.eventedSupport.on(e, t, E)
  }
  get reference() {
    return this._reference
  }
  set reference(e) {
    this._reference = e
  }
}
export { ShapeProvider }
