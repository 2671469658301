import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Ellipsoid } from '../geodesy/Ellipsoid.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { ProjectionType } from './ProjectionType.js'
import { TransverseCylindrical } from './TransverseCylindrical.js'
const EQUAL_POINTS_EPSILON = 1e-10
const EAST_LON_LIMIT = 84
const WEST_LON_LIMIT = -84
const DELTA_LON = 5
const sharedOutOfBoundsError = new OutOfBoundsError('TransverseMercator')
const _deltaX = []
const _deltaY = []
function _lonlat2worldSnyder(t, n, s, o, e, i, a) {
  let r, c
  let h = t.x - o
  if (h > 180) h -= 360
  else if (h < -180) h += 360
  const l = n.e2
  const d = n.meridionalArcDistance(t.y)
  const f = Math.cos(t.y * Constants.DEG2RAD)
  if (Math.abs(f) < 1e-10) {
    c = 0
    r = d
  } else {
    const s = Math.sin(t.y * Constants.DEG2RAD)
    const o = h * f * Constants.DEG2RAD
    const e = o * o
    const i = Math.tan(t.y * Constants.DEG2RAD)
    const a = i * i
    const M = l / (1 - l)
    const u = M * f * f
    const C = n.radiusVertical(s)
    const L = undefined
    const T = undefined
    const S = undefined
    c =
      C *
      o *
      (1 +
        e *
          ((1 - a + u) / 6 + e * ((5 - a * (18 - a) + 72 * u - 58 * M) / 120)))
    const p = undefined
    const O = undefined
    const E = undefined
    r =
      d +
      C *
        i *
        e *
        (1 / 2 +
          e *
            ((5 - a + u * (9 + 4 * u)) / 24 +
              e * ((61 - a * (58 - a) + 600 * u - 330 * M) / 720)))
  }
  const M = null !== a ? a.fDy : n.meridionalArcDistance(e)
  s.x = c
  s.y = r - M
}
;(() => {
  const t = 0.5
  const n = new LLHPoint()
  const s = new LLHPoint()
  const o = new XYZPoint()
  const e = new Ellipsoid()
  const i = Math.round(90 / t)
  for (let a = 0; a <= i; a++) {
    n.x = DELTA_LON
    n.y = a * t
    _lonlat2worldSnyder(n, e, o, 0, 0, s, null)
    _deltaX[a] = o.x
    _deltaY[a] = o.y
  }
})()
class CachedValues {
  constructor(t, n) {
    const s = t.conformalRadius
    const o = t.n
    const e = o * o
    const i = o * e
    const a = o / 2 - (2 * e) / 3 + (15 * i) / 16
    const r = (13 * e) / 48 - (3 * i) / 5
    const c = t.meridionalArcDistance(n.getOriginLat())
    const h = t.e2
    const l = Math.sqrt(1 - h)
    const d = (1 - l) / (1 + l)
    const f = d * d
    const M = h / (1 - h)
    const u = d * (3 / 2 - (27 / 32) * f)
    const C = f * (21 / 16 - (55 / 32) * f)
    const L = d * f * (151 / 96)
    const T = f * f * (1097 / 512)
    const S = 1 - (1 / 4 + (3 / 64 + (5 / 256) * h) * h) * h
    this.fA = t.a
    this.fE = t.e
    this.fDy = c
    this.fCr = s
    this.fAlpha2 = a
    this.fAlpha4 = r
    this.fEP2 = M
    this.fM2 = u
    this.fM4 = C
    this.fM6 = L
    this.fM8 = T
    this.fDenom = S
    const p = n.tempLL
    const O = n.tempXY
    p.move2DToCoordinates(n.getCentralMeridian() + WEST_LON_LIMIT, 0)
    n._geodetic2cartesianOnEllipsoidSFCT(p, t, O, this)
    this.fMinX = O.x * (1 + 1e-12)
    p.move2DToCoordinates(n.getCentralMeridian() + EAST_LON_LIMIT, 0)
    n._geodetic2cartesianOnEllipsoidSFCT(p, t, O, this)
    this.fMaxX = O.x * (1 + 1e-12)
    p.move2DToCoordinates(n.getCentralMeridian(), -90)
    n._geodetic2cartesianOnEllipsoidSFCT(p, t, O, this)
    this.fMinY = O.y * (1 + 1e-12)
    p.move2DToCoordinates(n.getCentralMeridian(), 90)
    n._geodetic2cartesianOnEllipsoidSFCT(p, t, O, this)
    this.fMaxY = O.y * (1 + 1e-12)
  }
}
export class TransverseMercator extends TransverseCylindrical {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this.fMinX = 0
    this.fMaxX = 0
    this.fMinY = 0
    this.fMaxY = 0
    this.fCachedValues = null
    this.tempXY = new XYZPoint(null, [0, 0, 0])
    this.tempLL = new LLHPoint(null, [0, 0, 0])
    this.setCentralMeridian(t)
    this.setOriginLat(n)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    this.fCachedValues = null
    this.tempXY = this.tempXY ?? new XYZPoint(null, [0, 0, 0])
    this.tempLL = this.tempLL ?? new LLHPoint(null, [0, 0, 0])
    const t = this.tempLL
    const n = this.tempXY
    t.move2DToCoordinates(this.getCentralMeridian() + WEST_LON_LIMIT, 0)
    this.geodetic2cartesianOnSphereSFCT(t, 1, n)
    this.fMinX = n.x
    t.move2DToCoordinates(this.getCentralMeridian() + EAST_LON_LIMIT, 0)
    this.geodetic2cartesianOnSphereSFCT(t, 1, n)
    this.fMaxX = n.x
    t.move2DToCoordinates(this.getCentralMeridian(), -90)
    this.geodetic2cartesianOnSphereSFCT(t, 1, n)
    this.fMinY = n.y
    t.move2DToCoordinates(this.getCentralMeridian(), 90)
    this.geodetic2cartesianOnSphereSFCT(t, 1, n)
    this.fMaxY = n.y
  }
  isAllInBounds() {
    return false
  }
  isContinuous() {
    return true
  }
  geodetic2cartesianOnSphereSFCT(t, n, s) {
    this._geodetic2cartesianOnSphereSFCT(t, n, this.getOriginLat(), s)
  }
  _geodetic2cartesianOnSphereSFCT(t, n, s, o) {
    if (!this.inLonLatBounds(t)) throw sharedOutOfBoundsError
    const e = undefined
    const i =
      Math.abs(Math.abs(t.y) - 90) < 1e-10 ? 0 : t.x - this.getCentralMeridian()
    const a = Math.sin(i * Constants.DEG2RAD)
    const r = Math.cos(i * Constants.DEG2RAD)
    const c = Math.cos(t.y * Constants.DEG2RAD) * a
    const h = (n * Math.log((1 + c) / (1 - c))) / 2
    const l = s * Constants.DEG2RAD
    const d = n * (Math.atan(Math.tan(t.y * Constants.DEG2RAD) / r) - l)
    o.x = h
    o.y = d
  }
  geodetic2cartesianOnEllipsoidSFCT(t, n, s) {
    const o = this.getCachedValues(n)
    this._geodetic2cartesianOnEllipsoidSFCT(t, n, s, o)
  }
  _geodetic2cartesianOnEllipsoidSFCT(t, n, s, o) {
    const e = undefined
    if (Math.abs(t.x - this.getCentralMeridian()) <= DELTA_LON)
      _lonlat2worldSnyder(
        t,
        n,
        s,
        this.getCentralMeridian(),
        this.getOriginLat(),
        this.getOrigin(),
        o
      )
    else this._lonlat2worldTripleProjectionSFCT(t, n, o, s)
  }
  _lonlat2worldTripleProjectionSFCT(t, n, s, o) {
    const e = new LLHPoint()
    const i = new LLHPoint()
    n.conformalSphericalLonLatPointSFCT(t, e)
    this._geodetic2cartesianOnSphereSFCT(e, 1, 0, i)
    const a = i.x
    const r = i.y
    const c = Math.cos(2 * r)
    const h = Math.cos(4 * r)
    const l = Math.sin(2 * r)
    const d = Math.sin(4 * r)
    const f =
      Math.cos(e.y * Constants.DEG2RAD) *
      Math.sin((e.x - this.getCentralMeridian()) * Constants.DEG2RAD)
    const M = f * f
    const u = 1 - M
    const C = (2 * f) / u
    const L = (1 + M) / u
    const T = 2 * C * L
    const S = 2 * C * C + 1
    const p = s.fCr * (a + s.fAlpha2 * c * C + s.fAlpha4 * h * T)
    const O = s.fCr * (r + s.fAlpha2 * l * L + s.fAlpha4 * d * S)
    o.x = p
    o.y = O - s.fDy
  }
  cartesian2geodeticOnSphereSFCT(t, n, s) {
    if (!this.inXYBounds(t, n)) throw sharedOutOfBoundsError
    this.world2lonlatOnSphereSFCT(t, n, this.getOriginLat(), s)
  }
  world2lonlatOnSphereSFCT(t, n, s, o) {
    const e = new LLHPoint()
    this.world2lonlatOnSphereNoExceptionSFCT(t, n, s, e)
    if (!this.inLonLatBounds(e)) throw sharedOutOfBoundsError
    o.x = e.x
    o.y = e.y
  }
  world2lonlatOnSphereNoExceptionSFCT(t, n, s, o) {
    const e = n * s * Constants.DEG2RAD
    const i = Math.exp((2 * t.x) / n)
    const a = Math.tan((t.y + e) / n)
    const r = i * i
    const c = a * a
    const h = (c + 1) * (r - 1)
    const l = Math.sqrt((c + 1) * (i + 1) * (i + 1))
    const d = Math.sqrt((c + 1) * (r + 1) - 2 * i * (c - 1))
    const f = d / l
    let M =
      Math.abs(f) < 1 ? Math.acos(f) * Constants.RAD2DEG : f >= 0 ? 0 : 180
    const u = h / (l * d)
    let C =
      Math.abs(u) < 1 ? Math.asin(u) * Constants.RAD2DEG : u >= 0 ? 90 : -90
    if (t.y + e < 0) M = -M
    C = normalizeLon(C + this.getCentralMeridian())
    o.x = C
    o.y = M
  }
  cartesian2geodeticOnEllipsoidSFCT(t, n, s) {
    const o = this.getCachedValues(n)
    if (!inXYBoundsEllipsoid(t, o)) throw sharedOutOfBoundsError
    const e = o.fDy
    if (isWithinZone(t.x, t.y + e)) this.world2lonlatSnyderSFCT(t, n, o, s, e)
    else this.world2lonlatTripleProjectionSFCT(t, n, o, s)
    if (!this.inLonLatBounds(s)) throw sharedOutOfBoundsError
  }
  world2lonlatSnyderSFCT(t, n, s, o, e) {
    const i = n.a
    const a = n.e2
    const r = t.x
    const c = undefined
    const h = undefined
    const l = undefined
    const d = (0 + (t.y + e)) / (i * s.fDenom)
    const f = Math.cos(d)
    const M = Math.sin(d)
    const u = f * f - M * M
    const C = 2 * f * M
    const L = u * u - C * C
    const T = 2 * u * C
    const S = T * u + L * C
    const p = 2 * L * T
    const O = d + s.fM2 * C + s.fM4 * T + s.fM6 * S + s.fM8 * p
    const E = Math.cos(O)
    const g = Math.sin(O)
    const D = g / E
    const _ = g * g
    const m = D * D
    const y = s.fEP2
    const A = y * E * E
    const x = 1 - a * _
    const Y = i / Math.sqrt(x)
    const I = (Y * (1 - a)) / x
    const P = r / Y
    const X = P * P
    const w = 1 / 2
    const F = (5 + 3 * m + A * (10 - 4 * A) - 9 * y) / -24
    const N = (61 + m * (90 + 45 * m) + A * (298 - 3 * A) - 252 * y) / 720
    const B = (1 + 2 * m + A) / -6
    const R = (5 - A * (2 + 3 * A) + m * (28 + 24 * m) + 8 * y) / 120
    const v =
      Constants.RAD2DEG * (O - ((Y * D) / I) * X * (w + X * (F + X * N)))
    const V = (P / E) * (1 + X * (B + X * R))
    o.x = this.getCentralMeridian() + Constants.RAD2DEG * V
    o.y = v
  }
  world2lonlatTripleProjectionSFCT(t, n, s, o) {
    const e = n.conformalRadius
    const i = t.x / e
    const a = (t.y + n.meridionalArcDistance(this.getOriginLat())) / e
    const r = s.fAlpha2
    const c = s.fAlpha4
    let h = i
    let l = a
    for (let t = 0; t < 4; t++) {
      const t = Math.cos(2 * l)
      const n = Math.sin(2 * l)
      const s = t * t - n * n
      const o = 2 * t * n
      const e = Math.exp(2 * h)
      const d = (e - 1 / e) / 2
      const f = (e + 1 / e) / 2
      const M = undefined
      const u = undefined
      h = i - (r * t * d + c * s * (2 * d * f))
      l = a - (r * n * f + c * o * (2 * d * d + 1))
    }
    const d = this.tempLL
    const f = this.tempXY
    f.move2DToCoordinates(h, l)
    if (!inXYBoundsUnitySphere(f)) throw sharedOutOfBoundsError
    this.world2lonlatOnSphereSFCT(f, 1, 0, d)
    n.inverseConformalSphericalLonLatPointSFCT(d, o)
  }
  inLonLatBounds(t) {
    const n = undefined
    if (Math.abs(Math.abs(t.y) - 90) < 1e-10) return true
    const s = normalizeLon(t.x - this.getCentralMeridian())
    return (
      s >= WEST_LON_LIMIT - EQUAL_POINTS_EPSILON &&
      s <= EAST_LON_LIMIT + EQUAL_POINTS_EPSILON
    )
  }
  inWorldBoundsOnSphere(t, n) {
    return this.inXYBounds(t, n)
  }
  cartesianBoundsOnEllipsoidSFCT(t, n) {
    const s = this.getCachedValues(t)
    n.setTo2D(s.fMinX, s.fMaxX - s.fMinX, s.fMinY, s.fMaxY - s.fMinY)
  }
  inWorldBoundsOnEllipsoid(t, n) {
    const s = undefined
    return inXYBoundsEllipsoid(t, this.getCachedValues(n))
  }
  inXYBounds(t, n) {
    const s = t.x
    const o = t.y
    return (
      this.fMinX * n <= s &&
      s <= this.fMaxX * n &&
      this.fMinY * n <= o &&
      o <= this.fMaxY * n
    )
  }
  boundaryLons(t) {
    return [
      [
        normalizeLon(WEST_LON_LIMIT + this.getCentralMeridian() + this.EPSILON),
        normalizeLon(EAST_LON_LIMIT + this.getCentralMeridian() - this.EPSILON),
      ],
    ]
  }
  boundaryLats(t) {
    const n = normalizeLon(t - this.getCentralMeridian())
    return n >= WEST_LON_LIMIT && n <= EAST_LON_LIMIT
      ? [[-90 + this.EPSILON, 90 - this.EPSILON]]
      : []
  }
  cartesianBoundsOnSphereSFCT(t, n) {
    n.setTo2D(
      this.fMinX * t,
      (this.fMaxX - this.fMinX) * t,
      this.fMinY * t,
      (this.fMaxY - this.fMinY) * t
    )
  }
  getCachedValues(t) {
    let n = this.fCachedValues
    if (null === n || t.a !== n.fA || t.e !== n.fE) {
      n = new CachedValues(t, this)
      this.fCachedValues = n
    }
    return n
  }
  encode() {
    return {
      type: 'TransverseMercator',
      centralMeridian: this.getCentralMeridian(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return (
      ProjectionType.TRANSVERSE_MERCATOR + ProjectionType.TRANSVERSECYLINDRICAL
    )
  }
}
function inXYBoundsUnitySphere(t) {
  const n = t.y
  const s = Math.PI / 2
  return n >= -s && n <= s
}
function inXYBoundsEllipsoid(t, n) {
  const s = t.x
  const o = t.y
  return n.fMinX <= s && s <= n.fMaxX && n.fMinY <= o && o <= n.fMaxY
}
function isWithinZone(t, n) {
  const s = Math.abs(n)
  if (s <= _deltaY[_deltaY.length - 1])
    for (let n = 1; n < _deltaY.length; n++)
      if (s <= _deltaY[n]) if (Math.abs(t) <= _deltaX[n]) return true
  return false
}
