import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
import { Constants } from '../../../util/Constants.js'
import { distance3D } from '../../../util/Cartesian.js'
import { SimpleXYZPoint } from '../../../shape/SimpleXYZPoint.js'
const SKIP_ANGLE = 1
const COS_SKIP_ANGLE = Math.cos(SKIP_ANGLE * Constants.DEG2RAD)
const SQR_COS_SKIP_ANGLE = COS_SKIP_ANGLE * COS_SKIP_ANGLE
const SQR_DISTANCE_THRESHOLD_FACTOR_FOR_BREAK_ANGLE = 10 * 10
const BREAK_ANGLE = 10
const COS_BREAK_ANGLE = Math.cos(BREAK_ANGLE * Constants.DEG2RAD)
const SQR_COS_BREAK_ANGLE = COS_BREAK_ANGLE * COS_BREAK_ANGLE
export class LinkedModelWorldPoint {
  constructor(t, i, e, s) {
    this._fLeftNeighbor = null
    this._fRightNeighbor = null
    this._fSqrDistLeft = 0
    this._fSqrDistRight = 0
    this._fTooLargeAngle = false
    this._fTooSmallAngle = false
    this._fBadConnection = false
    this._shouldSubdivideLeftEdge = false
    this._hasBeenAppended = false
    this._fDirtyDotAndCross = true
    this._fDirtyValidCorners = true
    this._fValidCorner = false
    this._fBadConnection = false
    this._fWorldPoint = new SimpleXYZPoint(null, 0, 0, 0)
    this._fFraction = i
    this._fThreshold = s
    t.getPointAtSFCT(this._fFraction, this._fWorldPoint)
    try {
      e._forward(this._fWorldPoint, this._fWorldPoint)
      this._fWorldPointValid = true
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
      this._fWorldPointValid = false
    }
  }
  get sqrDistRight() {
    return this._fSqrDistRight
  }
  get sqrDistLeft() {
    return this._fSqrDistLeft
  }
  get worldPoint() {
    return this._fWorldPoint
  }
  get hasBeenAppended() {
    return this._hasBeenAppended
  }
  set hasBeenAppended(t) {
    this._hasBeenAppended = t
  }
  get fraction() {
    return this._fFraction
  }
  get leftNeighbor() {
    return this._fLeftNeighbor
  }
  get rightNeighbor() {
    return this._fRightNeighbor
  }
  get shouldSubdivideLeftEdge() {
    return this._shouldSubdivideLeftEdge
  }
  set shouldSubdivideLeftEdge(t) {
    this._shouldSubdivideLeftEdge = t
  }
  reInterpret(t) {
    this._fFraction = t
    this.setLeftNeighbor(null)
    this.setRightNeighbor(null)
  }
  setLeftNeighbor(t) {
    this._fLeftNeighbor = t
    this.setDirty()
  }
  setRightNeighbor(t) {
    this._fRightNeighbor = t
    this.setDirty()
  }
  setDirty() {
    this._fDirtyDotAndCross = true
    this._fDirtyValidCorners = true
  }
  toString() {
    return `LMWP: left: ${
      this._fLeftNeighbor && this._fLeftNeighbor._fWorldPoint
    } current world: + ${this._fWorldPoint} right: + ${
      this._fRightNeighbor && this._fRightNeighbor._fWorldPoint
    }`
  }
  isValidPosition() {
    return this._fWorldPointValid
  }
  shouldSubdivideOutgoingEdges() {
    let t = false
    let i = false
    let e = false
    let s = false
    const o = !this._fWorldPointValid
    if (null !== this._fLeftNeighbor)
      if (this._fLeftNeighbor._fWorldPointValid) t = true
      else e = true
    if (null !== this._fRightNeighbor)
      if (this._fRightNeighbor._fWorldPointValid) i = true
      else s = true
    if (o && !t && !i) return false
    return (
      o || e || s || (t && i && (this.tooLargeAngle() || this.tooLargeEdge()))
    )
  }
  tooLargeAngle() {
    if (this._fDirtyDotAndCross) {
      this.setAngleAndDistanceProperties()
      this._fDirtyDotAndCross = false
    }
    return this._fTooLargeAngle
  }
  tooSmallAngle() {
    if (this._fDirtyDotAndCross) {
      this.setAngleAndDistanceProperties()
      this._fDirtyDotAndCross = false
    }
    return this._fTooSmallAngle
  }
  tooLargeEdge() {
    const t =
      null !== this._fLeftNeighbor && this._fLeftNeighbor._fWorldPointValid
    const i =
      null !== this._fRightNeighbor && this._fRightNeighbor._fWorldPointValid
    if (
      this._fWorldPointValid &&
      t &&
      i &&
      this._fRightNeighbor &&
      this._fLeftNeighbor
    ) {
      const t = distance3D(this._fWorldPoint, this._fLeftNeighbor._fWorldPoint)
      const i = distance3D(this._fWorldPoint, this._fRightNeighbor._fWorldPoint)
      return (
        t > this._fThreshold.MAX_EDGE_LENGTH ||
        i > this._fThreshold.MAX_EDGE_LENGTH
      )
    }
    return false
  }
  validCorner() {
    if (this._fDirtyValidCorners) {
      this.setValidCorner()
      this._fDirtyValidCorners = false
    }
    return this._fValidCorner
  }
  setValidCorner() {
    this._fValidCorner =
      this._fWorldPointValid &&
      null !== this._fLeftNeighbor &&
      this._fLeftNeighbor._fWorldPointValid &&
      null !== this._fRightNeighbor &&
      this._fRightNeighbor._fWorldPointValid &&
      !this.tooLargeAngle() &&
      !this._fLeftNeighbor.tooLargeAngle() &&
      !this._fRightNeighbor.tooLargeAngle() &&
      !this.isBadConnection() &&
      !this._fLeftNeighbor.isBadConnection() &&
      !this._fRightNeighbor.isBadConnection()
  }
  isBadConnection() {
    if (this._fDirtyDotAndCross) {
      this.setAngleAndDistanceProperties()
      this._fDirtyDotAndCross = false
    }
    return this._fBadConnection
  }
  setAngleAndDistanceProperties() {
    const t =
      null !== this._fLeftNeighbor && this._fLeftNeighbor._fWorldPointValid
    const i =
      null !== this._fRightNeighbor && this._fRightNeighbor._fWorldPointValid
    let e, s, o, r, h, f, n, _, l, d, g, a, A
    if (this._fWorldPointValid && t && i) {
      e = this._fWorldPoint
      s = this._fLeftNeighbor._fWorldPoint
      o = this._fRightNeighbor._fWorldPoint
      r = e.x - s.x
      h = e.y - s.y
      f = e.z - s.z
      n = o.x - e.x
      _ = o.y - e.y
      l = o.z - e.z
      this._fSqrDistLeft = r * r + h * h + f * f
      this._fSqrDistRight = n * n + _ * _ + l * l
      d = n * r + _ * h + l * f
      g = d * d
      this._fTooLargeAngle =
        d < 0 ||
        g <
          this._fSqrDistLeft *
            this._fSqrDistRight *
            this._fThreshold.SQR_COS_THRESHOLD_ANGLE
      this._fTooSmallAngle =
        d > 0 &&
        g > this._fSqrDistLeft * this._fSqrDistRight * SQR_COS_SKIP_ANGLE
      a = Math.min(this._fSqrDistLeft, this._fSqrDistRight)
      A = Math.max(this._fSqrDistLeft, this._fSqrDistRight)
      this._fBadConnection =
        (d < 0 ||
          g < this._fSqrDistLeft * this._fSqrDistRight * SQR_COS_BREAK_ANGLE) &&
        a * SQR_DISTANCE_THRESHOLD_FACTOR_FOR_BREAK_ANGLE < A
    } else {
      this._fTooLargeAngle = false
      this._fTooSmallAngle = false
      this._fBadConnection = false
      this._fSqrDistLeft = 0
      this._fSqrDistRight = 0
    }
  }
  distanceTo(t) {
    return distance3D(this._fWorldPoint, t._fWorldPoint)
  }
}
