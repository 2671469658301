import { WMSCapabilitiesParser } from './WMSCapabilitiesParser.js'
import { Lang } from '../../util/Lang.js'
import { XMLSchema } from '../../util/XMLSchema.js'
import { WMSCapabilitiesSchemaV130 } from './WMSCapabilitiesSchemaV130.js'
class WMSCapabilitiesParserV130 extends WMSCapabilitiesParser {
  constructor(e) {
    const i = undefined
    super(new XMLSchema(WMSCapabilitiesSchemaV130).parse(e), [
      'WMS_Capabilities',
    ])
  }
  _parseLayerScaleDenominator(e) {
    if (
      !Lang.isDefined(e.MinScaleDenominator) &&
      !Lang.isDefined(e.MaxScaleDenominator)
    )
      return null
    return {
      min: isNaN(+e.MinScaleDenominator)
        ? e.MinScaleDenominator
        : +e.MinScaleDenominator,
      max: isNaN(+e.MaxScaleDenominator)
        ? e.MaxScaleDenominator
        : +e.MaxScaleDenominator,
    }
  }
  _hasWGS84Bounds(e) {
    return Lang.isDefined(e.EX_GeographicBoundingBox)
  }
  _parseWGS84Bounds(e) {
    const i = e.EX_GeographicBoundingBox
    return {
      reference: 'CRS:84',
      x: i.westBoundLongitude,
      y: i.northBoundLatitude,
      width: i.eastBoundLongitude - i.westBoundLongitude,
      height: i.southBoundLatitude - i.northBoundLatitude,
      resolutionX: null,
      resolutionY: null,
    }
  }
  _hasBoundsList(e) {
    return Lang.isDefined(e.BoundingBox)
  }
  _parseBoundsList(e) {
    return (e.BoundingBox || [])
      .map(this._parseBounds, this)
      .filter(Lang.isDefined)
  }
}
WMSCapabilitiesParserV130.prototype._referenceFieldName = 'CRS'
WMSCapabilitiesParserV130.prototype._swapAxes = true
export { WMSCapabilitiesParserV130 }
