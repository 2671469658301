import { NoBoundsError } from '../error/NoBoundsError.js'
import { Pole } from '../projection/PolarStereographic.js'
import { ProjectionType } from '../projection/ProjectionType.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { createBounds, createPoint } from '../shape/ShapeFactory.js'
import {
  closestPointOnSegment,
  rectangleCircleIntersection,
  squaredDistance2D_flat,
} from '../util/Cartesian.js'
import { GeodeticGridTransformation } from './GeodeticGridTransformation.js'
import { TransformationType } from './TransformationType.js'
const RADIUS_EPSILON = 500
const DEGREE_EPSILON = 0.02
export class GeodeticToPolarStereographicTransformation extends GeodeticGridTransformation {
  constructor(t, e, o) {
    super(t, e, o)
    this._projection = e.projection
    this._isNorthPole = this._projection.getPole() === Pole.NORTH_POLE
    this._origin = this._projection.getOrigin()
    this._ellipsoid = t.geodeticDatum.ellipsoid
    this._scale = e.scale
    this.uom = e.unitOfMeasure
    this._geodeticRef = t
    this._tempXYZPoint1 = createPoint(e, [0, 0, 0])
    this._tempXYZPoint2 = createPoint(e, [0, 0, 0])
    this._tempXYZPoint3 = createPoint(e, [0, 0, 0])
    this._tempXYZPoint4 = createPoint(e, [0, 0, 0])
    this._tempXYZPoint5 = createPoint(e, [0, 0, 0])
    this._tempLLHPoint = createPoint(this._geodeticRef, [0, 0, 0])
    this._tempLLHBounds = createBounds(this._geodeticRef)
    this._projection.updateCachedValues(this._ellipsoid)
    this._radius = this._projection.A * this._projection.ellipsoidFactor
    this._worldPole = createPoint(e, [0, 0])
  }
  getFurthestFromPole(t, e, o, i) {
    const s = this._worldPole
    let n = t
    let r = squaredDistance2D_flat(s, t)
    let c = squaredDistance2D_flat(s, e)
    if (c > r) {
      r = c
      n = e
    }
    c = squaredDistance2D_flat(s, o)
    if (c > r) {
      r = c
      n = o
    }
    c = squaredDistance2D_flat(s, i)
    if (c > r) n = i
    return n
  }
  inverseBoundsPoleIncluded(t, e, o, i, s) {
    const n = this.getFurthestFromPole(t, e, o, i)
    let r
    try {
      r = this._inverse(n, this._tempLLHPoint).y
    } catch (t) {
      r = 0
    }
    if (this._isNorthPole) s.setTo2D(-180, 360, r, 90 - r)
    else s.setTo2D(-180, 360, -90, 90 + r)
  }
  getPointOnSegmentClosestToPole(t, e, o) {
    closestPointOnSegment(this._worldPole, t, e, o)
  }
  _inverseBoundsCoords(t, e) {
    const o = t.x
    const i = o + t.width
    const s = t.y
    const n = s + t.height
    const r = this._tempXYZPoint1
    const c = this._tempXYZPoint2
    const h = this._tempXYZPoint3
    const a = this._tempXYZPoint4
    r.x = o
    r.y = s
    c.x = o
    c.y = n
    h.x = i
    h.y = n
    a.x = i
    a.y = s
    const l = this._worldPole
    if (t.contains2DPoint(l)) {
      this.inverseBoundsPoleIncluded(r, c, h, a, e)
      e.z = t.z * this.uom
      e.depth = t.depth * this.uom
      return
    }
    const P = (o >= 0 || i >= 0) && (s >= 0 || n >= 0)
    const p = (o >= 0 || i >= 0) && (s <= 0 || n <= 0)
    const _ = (o <= 0 || i <= 0) && (s <= 0 || n <= 0)
    const d = (o <= 0 || i <= 0) && (s >= 0 || n >= 0)
    let m = this._tempXYZPoint5
    if (_ && d) this.getPointOnSegmentClosestToPole(a, h, m)
    else if (_ && p) this.getPointOnSegmentClosestToPole(c, h, m)
    else if (p && P) this.getPointOnSegmentClosestToPole(r, c, m)
    else if (d && P) this.getPointOnSegmentClosestToPole(r, a, m)
    else if (P) m = r
    else if (d) m = a
    else if (p) m = c
    else if (_) m = h
    const f = this._tempLLHPoint
    try {
      this._inverse(m, f)
    } catch (t) {
      throw new NoBoundsError(
        'Bounding box does not cover any surface of the earth'
      )
    }
    const u = this._tempLLHBounds
    u.setTo2D(f.x, 0, f.y, 0)
    try {
      this._inverse(r, f)
      u.setToIncludePoint2D(f)
    } catch (t) {}
    try {
      this._inverse(c, f)
      u.setToIncludePoint2D(f)
    } catch (t) {}
    try {
      this._inverse(h, f)
      u.setToIncludePoint2D(f)
    } catch (t) {}
    try {
      this._inverse(a, f)
      u.setToIncludePoint2D(f)
    } catch (t) {}
    const T = rectangleCircleIntersection(o, s, i, n, this._radius)
    let E, g, D
    for (D = 0; D < T.length; D += 2) {
      E = T[D]
      g = T[D + 1]
      E = shrink(E)
      g = shrink(g)
      try {
        this._tempXYZPoint5.x = E
        this._tempXYZPoint5.y = g
        this._inverse(this._tempXYZPoint5, f)
        u.setToIncludePoint2D(f)
      } catch (t) {
        f.x = u.x
        f.y = 0
      }
    }
    let y, O, S, j
    if (T.length > 0) {
      E = u.x - DEGREE_EPSILON
      S = u.width + 2 * DEGREE_EPSILON
      g = u.y - DEGREE_EPSILON
      j = u.height + 2 * DEGREE_EPSILON
      if (this._isNorthPole) y = Math.max(0, g)
      else y = Math.max(-90, g)
      O = Math.abs(g - y)
      g = y
      j -= O
      u.setTo2D(E, S, g, j)
    }
    e.setToBounds2D(u)
    e.z = t.z * this.uom
    e.depth = t.depth * this.uom
  }
  _getType() {
    return TransformationType.TYPE_GENERAL
  }
}
export function isCompatibleGeodeticPolarStereo(t, e) {
  if (
    t.referenceType !== ReferenceType.GEODETIC ||
    !t.geodeticDatum.equals(e.geodeticDatum)
  )
    return false
  if (
    (e.projection.TYPE & ProjectionType.POLAR_STEREOGRAPHIC) !==
    ProjectionType.POLAR_STEREOGRAPHIC
  )
    return false
  const o = e.projection
  return (
    0 === e.falseEasting &&
    0 === e.falseNorthing &&
    0 === e.rotation &&
    90 === o.getLatitudeExtent()
  )
}
function shrink(t) {
  return t > 0 ? t - RADIUS_EPSILON : t + RADIUS_EPSILON
}
