import { PatternType } from '../pattern/util/PatternType.js'
import { Hash } from '../../../../util/Hash.js'
import { isPromise, whenInternal } from '../../../../util/PromiseUtil.js'
import { PhotonNormalizedIconStyle } from '../../../feature/photon/command/PhotonNormalisedIconStyle.js'
import { getIconStyleValuesInPx, normalizeIconStyle } from '../../StyleUtil.js'
import { URL } from '../../../../util/URL.js'
import { Ajax } from '../../../../util/Ajax.js'
import { PHOTON_STATE_MAP } from './PhotonStateContainer.js'
import { isDefined } from '../../../../util/Lang.js'
export function resolvePhotonState(e, t, n) {
  let o
  let r = PHOTON_STATE_MAP.get(n)
  if (isDefined(r)) o = r
  else {
    o = new Map()
    PHOTON_STATE_MAP.set(n, o)
  }
  const a = n.decorations
  const s = n.regular
  const i = n.fallback
  const l = []
  if (a)
    for (let n = 0; n < a.length; n++) {
      const r = resolvePhotonStatePattern(e, t, o, a[n].pattern)
      if (r instanceof Promise) l.push(r)
    }
  if (s) {
    const n = resolvePhotonStatePattern(e, t, o, s)
    if (n instanceof Promise) l.push(n)
  }
  if (i) {
    const n = resolvePhotonStatePattern(e, t, o, i)
    if (n instanceof Promise) l.push(n)
  }
  if (l.length >= 1) return Promise.all(l).then(() => n)
  return n
}
function resolvePhotonStatePattern(e, t, n, o) {
  switch (o.patternType) {
    case PatternType.GAP:
    case PatternType.LINE:
    case PatternType.PARALLEL_LINE:
    case PatternType.RECTANGLE:
    case PatternType.TRIANGLE:
    case PatternType.POLYLINE:
    case PatternType.ARC:
    case PatternType.WAVE:
      return null
    case PatternType.ICON: {
      const e = o
      if (n.has(e)) return n.get(e)
      const r = e.crudeIconPromise
        .then((n) => {
          e.setResolvedImage(n)
          return resolveIconStyle(t, new Hash(), { image: n }, false)
        })
        .then((t) => {
          n.set(e, t)
          return t
        })
      n.set(e, r)
      return r
    }
    case PatternType.TEXT: {
      const e = o
      if (n.has(e)) return n.get(e)
      const r = t.getPhotonImage(
        e.getImageCanvas(),
        true,
        false,
        false,
        false,
        false
      )
      n.set(e, { photonImage: r })
      return { photonImage: r }
    }
    case PatternType.COMBINE_WITH_FALLBACK:
    case PatternType.COMBINE_WITH_REGULAR:
    case PatternType.ATOMIC:
    case PatternType.ARROW:
    case PatternType.ALLOW_OVERLAP:
    case PatternType.REPEAT_COUNT:
    case PatternType.REPEAT_OVER_LENGTH: {
      const r = undefined
      return resolvePhotonStatePattern(e, t, n, o.pattern)
    }
    case PatternType.APPEND:
    case PatternType.COMPOSE: {
      const r = o
      let a = null
      for (let o = 0, s = r.patterns.length; o < s; o++) {
        const s = resolvePhotonStatePattern(e, t, n, r.patterns[o])
        if (isPromise(s)) {
          if (!a) a = []
          a.push(s)
        }
      }
      return null != a ? Promise.all(a) : null
    }
  }
}
const MAX_WIDTH = 2046
const MAX_HEIGHT = 510
export function resolveIconStyle(e, t, n, o, r, a) {
  const s = n.hasOwnProperty('isWorldSized') ? n : normalizeIconStyle(n)
  const i = s.image ? n.image : n.url
  const l = 'string' === typeof i && !URL.isLocalURL(i)
  let c
  if (Ajax.isSVGImage(i) && (s.width || s.height)) {
    c = e.getPhotonImage(
      i,
      true,
      o,
      l,
      s.credentials,
      a,
      s.width,
      s.height,
      MAX_WIDTH,
      r ?? MAX_HEIGHT
    )
    s.width = '100%'
    s.height = '100%'
  } else
    c = e.getPhotonImage(
      i,
      true,
      o,
      l,
      s.credentials,
      a,
      0,
      0,
      MAX_WIDTH,
      r ?? MAX_HEIGHT
    )
  return whenInternal(c, (e) => {
    const t = new PhotonNormalizedIconStyle(s)
    const n = getIconStyleValuesInPx(
      s.width,
      s.height,
      s.anchorX,
      s.anchorY,
      e.width,
      e.height
    )
    t.photonImage = e
    t.anchorX = n.anchorX
    t.anchorY = n.anchorY
    t.width = n.width
    t.height = n.height
    return t
  })
}
