import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { BaseProjection } from './BaseProjection.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('Polyconic')
const EPSILON = 1e-10
const MAX_ITERATIONS = 20
const LON_LIMIT = 80
class CachedValuesForEllipsoid {
  constructor(t, s) {
    this._A = t.a
    this._E = t.e
    this._E2 = t.e2
    const n = this._E2 * this._E2
    const o = this._E2 * n
    const i = Math.sin(s.getOrigin().y * Constants.DEG2RAD)
    const a = Math.cos(s.getOrigin().y * Constants.DEG2RAD)
    this._T1 = 1 - this._E2 / 4 - (3 * n) / 64 - (5 * o) / 256
    const r = -((3 * this._E2) / 8 + (3 * n) / 32 + (45 * o) / 1024)
    const e = (15 * n) / 256 + (45 * o) / 1024
    const h = (-35 * o) / 3072
    this._A2 = r - h
    this._B2 = 2 * e
    this._C2 = 4 * h
    this._A3 = this._T1 - 4 * e
    this._B3 = 2 * r - 18 * h
    this._C3 = 8 * e
    this._D3 = 24 * h
    const c = 2 * i * a
    const l = a * a - i * i
    this._M0 =
      this._A *
      (this._T1 * s._phi0 + c * (this._A2 + l * (this._B2 + l * this._C2)))
  }
}
export class Polyconic extends BaseProjection {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._phi0 = -1
    this._lambda0 = -1
    this._cachedValuesForEllipsoid = null
    this._origin = new LLHPoint(null, [t, s])
    this._hash = 0
    this.calculateCachedValues()
  }
  getOrigin() {
    return this._origin
  }
  setOrigin(t) {
    this._origin.x = t.x
    this._origin.y = t.y
    this.calculateCachedValues()
  }
  isAllInBounds() {
    return true
  }
  isContinuous() {
    return false
  }
  inLonLatBounds(t) {
    return Math.abs(normalizeLon(t.x - this._origin.x)) < 90
  }
  boundaryLons(t) {
    const s = this.getOrigin().x
    return [[normalizeLon(s - LON_LIMIT), normalizeLon(s + LON_LIMIT)]]
  }
  boundaryLats(t) {
    const s = undefined
    return Math.abs(normalizeLon(t - this.getOrigin().x)) <= LON_LIMIT
      ? [[-90, 90]]
      : []
  }
  inWorldBoundsOnSphere(t, s) {
    const n = s * Constants.DEG2RAD * (-90 - this.getOrigin().y) * (1 + 1e-15)
    const o = s * Constants.DEG2RAD * (90 - this.getOrigin().y) * (1 + 1e-15)
    const i = 0.5 * s * Math.PI * (1 + 1e-15)
    const a = t.x
    const r = t.y
    return Math.abs(a) < i && r >= n && r <= o
  }
  inWorldBoundsOnEllipsoid(t, s) {
    const n = this.getCachedValuesForEllipsoid(s)
    const o = n._A * ((n._T1 * Math.PI) / 2) * (1 + 1e-15)
    const i = -o - n._M0
    const a = o - n._M0
    const r = 0.5 * s.a * Math.PI * (1 + 1e-15)
    const e = t.x
    const h = t.y
    return Math.abs(e) < r && h >= i && h <= a
  }
  geodetic2cartesianOnSphereSFCT(t, s, n) {
    if (!this.inLonLatBounds(t)) throw sharedOutOfBoundsError
    const o = normalizeLon(t.x - this._origin.x) * Constants.DEG2RAD
    const i = Math.sin(t.y * Constants.DEG2RAD)
    if (Math.abs(i) < EPSILON) {
      n.x = s * o
      n.y = -s * this._phi0
      return
    }
    const a = o * i
    const r = Math.cos(t.y * Constants.DEG2RAD) / i
    const e = (t.y - this._origin.y) * Constants.DEG2RAD
    n.x = s * r * Math.sin(a)
    n.y = s * (e + r * (1 - Math.cos(a)))
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, n) {
    if (!this.inLonLatBounds(t)) throw sharedOutOfBoundsError
    const o = normalizeLon(t.x - this._origin.x) * Constants.DEG2RAD
    const i = this.getCachedValuesForEllipsoid(s)
    if (Math.abs(Math.sin(t.y * Constants.DEG2RAD)) < EPSILON) {
      n.x = s.a * o
      n.y = -i._M0
      return
    }
    const a = t.y * Constants.DEG2RAD
    const r = Math.sin(t.y * Constants.DEG2RAD)
    const e = Math.cos(t.y * Constants.DEG2RAD)
    const h = 2 * r * e
    const c = e * e - r * r
    const l = i._A * (i._T1 * a + h * (i._A2 + c * (i._B2 + c * i._C2)))
    const _ = i._A / Math.sqrt(1 - i._E2 * r * r)
    const u = o * r
    const d = e / r
    n.x = _ * d * Math.sin(u)
    n.y = l - i._M0 + _ * d * (1 - Math.cos(u))
  }
  cartesian2geodeticOnSphereSFCT(t, s, n) {
    if (!this.inWorldBoundsOnSphere(t, s)) throw sharedOutOfBoundsError
    const o = this._phi0 + t.y / s
    if (Math.abs(o) < EPSILON) {
      n.x = (t.x / s + this._lambda0) * Constants.RAD2DEG
      n.y = 0
      return
    }
    const i = t.x
    const a = (i * i) / (s * s) + o * o
    let r = o
    let e = 0
    let h
    for (h = 0; h < MAX_ITERATIONS; h++) {
      const t = r
      e = Math.tan(t)
      if (!isFinite(e)) {
        n.x = this._origin.x
        n.y = t * Constants.RAD2DEG
        return
      }
      r = t - (o * (t * e + 1) - t - 0.5 * (t * t + a) * e) / ((t - o) / e - 1)
      if (Math.abs(r - t) < EPSILON) break
    }
    if (h >= MAX_ITERATIONS) throw sharedOutOfBoundsError
    const c = Math.sin(r)
    if (Math.abs(c) < EPSILON) {
      n.x = (t.x / s) * Constants.RAD2DEG
      n.y = r * Constants.RAD2DEG
      return
    }
    n.x = (Math.asin((t.x * e) / s) / c + this._lambda0) * Constants.RAD2DEG
    n.y = r * Constants.RAD2DEG
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, n) {
    if (!this.inWorldBoundsOnEllipsoid(t, s)) throw sharedOutOfBoundsError
    const o = this.getCachedValuesForEllipsoid(s)
    const i = t.x
    const a = t.y
    if (Math.abs(a + o._M0) < EPSILON) {
      n.x = (i / o._A + this._lambda0) * Constants.RAD2DEG
      n.y = 0
      return
    }
    const r = (o._M0 + a) / o._A
    const e = (i * i) / (o._A * o._A) + r * r
    let h = r
    let c = 0
    let l
    for (l = 0; l < MAX_ITERATIONS; l++) {
      const t = h
      const s = Math.sin(t)
      const n = Math.cos(t)
      const i = 2 * s * n
      const a = n * n - s * s
      const l = s / n
      c = Math.sqrt(1 - o._E2 * s * s) * l
      const _ = o._A3 + a * (o._B3 + a * (o._C3 + a * o._D3))
      const u = undefined
      const d =
        (o._A * (o._T1 * t + i * (o._A2 + a * (o._B2 + a * o._C2)))) / o._A
      const E = d * d + e
      h =
        t -
        (r * (c * d + 1) - d - 0.5 * E * c) /
          ((o._E2 * i * (E - 2 * r * d)) / (4 * c) +
            (r - d) * (c * _ - 2 / i) -
            _)
      if (Math.abs(h - t) < EPSILON) break
    }
    if (l >= MAX_ITERATIONS) throw sharedOutOfBoundsError
    if (Math.abs(Math.sin(h)) < EPSILON) {
      n.x = this._origin.x
      n.y = h * Constants.RAD2DEG
      return
    }
    n.x =
      (Math.asin((i * c) / o._A) / Math.sin(h) + this._lambda0) *
      Constants.RAD2DEG
    n.y = h * Constants.RAD2DEG
  }
  equals(t) {
    if (!super.equals(t)) return false
    return t.getOrigin().equals(this.getOrigin())
  }
  getCachedValuesForEllipsoid(t) {
    let s = this._cachedValuesForEllipsoid
    if (null === s || s._A !== t.a || s._E !== t.e) {
      s = new CachedValuesForEllipsoid(t, this)
      this._cachedValuesForEllipsoid = s
    }
    return s
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    const n = t * Constants.DEG2RAD * (-90 - this.getOrigin().y) * (1 + 1e-15)
    const o = t * Constants.DEG2RAD * (90 - this.getOrigin().y) * (1 + 1e-15)
    const i = 0.5 * t * Math.PI * (1 + 1e-15)
    s.setTo2D(-i, 2 * i, n, o - n)
  }
  cartesianBoundsOnEllipsoidSFCT(t, s) {
    const n = this.getCachedValuesForEllipsoid(t)
    const o = n._A * ((n._T1 * Math.PI) / 2) * (1 + 1e-15)
    const i = -o - n._M0
    const a = o - n._M0
    const r = 0.5 * t.a * Math.PI * (1 + 1e-15)
    s.setTo2D(-r, 2 * r, i, a - i)
  }
  encode() {
    return {
      type: 'Polyconic',
      originLon: this.getOrigin().x,
      originLat: this.getOrigin().y,
    }
  }
  get TYPE() {
    return ProjectionType.POLYCONIC
  }
  calculateCachedValues() {
    this._phi0 = this._origin.y * Constants.DEG2RAD
    this._lambda0 = this._origin.x * Constants.DEG2RAD
    this._cachedValuesForEllipsoid = null
    const t = new Hash()
    this._origin.hashCode(t)
    this._hash = t.appendDouble(this.TYPE).getHashCode()
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
