import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isFunction } from '../../util/Lang.js'
const NO_NEXT_INDEX = -2
export class ArrayCursor {
  constructor(r) {
    this._nextIndex = -1
    this._array = r
    if (2 === arguments.length) {
      const r = arguments[1]
      this._filter = isFunction(r) ? r : void 0
    }
    this.prepareNext()
  }
  hasNext() {
    return this._nextIndex !== NO_NEXT_INDEX
  }
  next() {
    if (!this.hasNext())
      throw new ProgrammingError(
        'Cursor: next should only be called when hasNext returns true'
      )
    const r = this._array[this._nextIndex]
    this.prepareNext()
    return r
  }
  prepareNext() {
    if (this._nextIndex === NO_NEXT_INDEX) return
    do {
      this._nextIndex++
    } while (
      this._nextIndex < this._array.length &&
      this._filter &&
      !this._filter(this._array[this._nextIndex])
    )
    if (this._nextIndex >= this._array.length) this._nextIndex = NO_NEXT_INDEX
  }
}
