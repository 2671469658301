import { createBounds, createPoint } from '../shape/ShapeFactory.js'
import { Transformation } from './Transformation.js'
export class ChainedTransformation extends Transformation {
  _inverseTransformation = null
  constructor(r, n) {
    super(r.inputReference, n.outputReference)
    const t = r.outputReference
    const e = n.inputReference
    if (!t.equals(e))
      throw new Error(
        `ChainedTransformation: intermediate reference should be the same: ${t.identifier} !== ${e.identifier}`
      )
    this._transformation1 = r
    this._transformation2 = n
    this._intermediatePoint = createPoint(t, [0, 0, 0])
    this._intermediateBounds = createBounds(t, [0, 0, 0, 0, 0, 0])
  }
  get inverseTransformation() {
    if (!this._inverseTransformation) {
      this._inverseTransformation = new ChainedTransformation(
        this._transformation2.inverseTransformation,
        this._transformation1.inverseTransformation
      )
      this._inverseTransformation._inverseTransformation = this
    }
    return this._inverseTransformation
  }
  _forward(r, n) {
    this._transformation1._forward(r, this._intermediatePoint)
    return this._transformation2._forward(this._intermediatePoint, n)
  }
  _inverse(r, n) {
    this._transformation2._inverse(r, this._intermediatePoint)
    return this._transformation1._inverse(this._intermediatePoint, n)
  }
  _forwardBoundsCoords(r, n) {
    this._transformation1._forwardBoundsCoords(r, this._intermediateBounds)
    return this._transformation2._forwardBoundsCoords(
      this._intermediateBounds,
      n
    )
  }
  _inverseBoundsCoords(r, n) {
    this._transformation2._inverseBoundsCoords(r, this._intermediateBounds)
    return this._transformation1._inverseBoundsCoords(
      this._intermediateBounds,
      n
    )
  }
}
