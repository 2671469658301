import { SELECTED_FEATURE } from './FeatureRenderNode.js'
import { AbstractFeatureRenderer } from './AbstractFeatureRenderer.js'
export class SelectedFeatureRenderer extends AbstractFeatureRenderer {
  constructor(e, t, r, s, o, n, a) {
    super(e, t, r, s, o, n, a, { selected: true, flags: SELECTED_FEATURE })
  }
  runActionForFeaturesInBounds(e, t) {
    const r = this._layer.getSelectedFeatures()
    const { workingSet: s } = this._layer
    for (let e = 0; e < r.length; e += 1) {
      const o = s.getNode(r[e].id)
      if (o?.shape) t(this, o)
    }
  }
}
