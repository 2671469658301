import { LineSegmentChooser } from './LineSegmentChooser.js'
function LineSegmentChooserUnite() {}
LineSegmentChooserUnite.prototype = Object.create(LineSegmentChooser.prototype)
LineSegmentChooserUnite.prototype.constructor = LineSegmentChooserUnite
LineSegmentChooserUnite.prototype.isSegmentOkAbstract = function (e) {
  return !e.isFilled() && !e.isPoint()
    ? !e.segmentLabel.locationLeft[e.otherShapeIndex] &&
        !e.segmentLabel.locationRight[e.otherShapeIndex]
    : false
}
export { LineSegmentChooserUnite }
