import { createCircularArcByCenterPoint } from '../../../shape/ShapeFactory.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { Editor } from '../Editor.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { CircularArcHelperHandle } from '../handles/helper/CircularArcHelperHandle.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultPoint,
} from '../handles/CreateByTemplateHandle.js'
export class CircularArcBy3PointsEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.CIRCULAR_ARC_BY_3_POINTS, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createStartPointHandle(e),
      this.createIntermediatePointHandle(e),
      this.createEndPointHandle(e),
      this.createHelperHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.center,
      (e) => t.translate2D(e.x - t.center.x, e.y - t.center.y)
    )
  }
  createStartPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.startPoint,
      (e) => t.moveStartPoint2DToPoint(e)
    )
  }
  createIntermediatePointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.intermediatePoint,
      (e) => t.moveIntermediatePoint2DToPoint(e)
    )
  }
  createEndPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.endPoint,
      (e) => t.moveEndPoint2DToPoint(e)
    )
  }
  createHelperHandle(e) {
    const t = e.shape
    const n = undefined
    return new CircularArcHelperHandle(() => {
      const e = (t.sweepAngle > 0 ? -360 : 360) - -t.sweepAngle
      return createCircularArcByCenterPoint(
        t.reference,
        t.center,
        t.radius,
        t.startAzimuth,
        e
      )
    })
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, t) => {
      const { map: n } = e
      const r = e.shape
      r.move2DToPoint(t)
      r.moveStartPoint2DToPoint(getDefaultPoint(n, t, 0))
      r.moveIntermediatePoint2DToPoint(getDefaultPoint(n, t, 120))
      r.moveEndPoint2DToPoint(getDefaultPoint(n, t, 240))
    })
  }
}
