import { isUpsideDown } from '../../../../util/Cartesian.js'
import {
  instanceOfImageIconStyle,
  instanceOfUrlIconStyle,
} from '../../IconStyle.js'
import { resolveIconImage } from '../../StyleUtil.js'
import { Pattern } from '../Pattern.js'
import { WorldSized } from './util/WorldSized.js'
import { PatternType } from './util/PatternType.js'
import { isNumber } from '../../../../util/Lang.js'
import { appendImageHashCode } from './util/HashUtil.js'
export class IconPattern extends Pattern {
  constructor(t) {
    super(PatternType.ICON, 15)
    this._worldSized = new WorldSized()
    const e = { credentials: !!t.credentials }
    const i = t.icon
    if (i)
      if (i instanceof HTMLCanvasElement || i instanceof HTMLImageElement)
        e.image = i
      else e.url = i
    this._iconStyle = e
    const s = instanceOfUrlIconStyle(e) ? e.url : void 0
    const o = instanceOfUrlIconStyle(e) ? e.credentials ?? false : false
    const r = instanceOfImageIconStyle(e) ? e.image : void 0
    this._crudeIconPromise = Promise.resolve(resolveIconImage(s, r, o))
    this._offset = t.offset || 0
    this._rotateUp = !!t.rotateUp || false
    this._imagePromise = null
    this._image = null
    this._opacity = t.opacity || 1
    this._rotation = t.rotation ? (t.rotation * Math.PI) / 180 : 0
    this._size = t.size || null
    this._width = 0
    this._height = 0
  }
  get iconStyle() {
    return this._iconStyle
  }
  get crudeIconPromise() {
    return this._crudeIconPromise
  }
  get offset() {
    return this._offset
  }
  get rotateUp() {
    return this._rotateUp
  }
  get opacity() {
    return this._opacity
  }
  get rotation() {
    return this._rotation
  }
  get size() {
    return this._size
  }
  serialize() {
    const t = super.serialize()
    delete t._offset
    return t
  }
  get worldSizeContext() {
    return this._worldSized.worldSizeContext
  }
  set worldSizeContext(t) {
    this._worldSized.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._worldSized.getUomSizeToPixels(t)
  }
  resolveState() {
    if (this._image) return Promise.resolve()
    if (this._imagePromise) return this._imagePromise
    this._imagePromise = this._crudeIconPromise.then((t) =>
      this.setResolvedImage(t)
    )
    return this._imagePromise
  }
  getWidth(t) {
    return this.getUomSizeToPixels(this._width)
  }
  getHeight() {
    return this.getUomSizeToPixels(this._height)
  }
  getMinHeight() {
    return this.getUomSizeToPixels(this._offset - this._height / 2)
  }
  getMaxHeight() {
    return this.getUomSizeToPixels(this._offset + this._height / 2)
  }
  getOffset() {
    return this.getUomSizeToPixels(this._offset)
  }
  getSizeFactor(t) {
    const e = t ?? this._image
    return this._size && e && isNumber(e.height) ? this._size / e.height : 1
  }
  setResolvedImage(t) {
    this._imagePromise = null
    this._image = t
    if (t) {
      const e = this.getSizeFactor()
      const i = calculatePatternWidth(t.width, t.height, this._rotation, e)
      this._width = i.width
      this._height = i.height
    } else this._width = this._height = 0
  }
  paint(t, e, i) {
    if (this._image) {
      const s = this.getWidth(e)
      const o = this.getHeight()
      const r = -this.getOffset()
      const n = this._rotateUp && isUpsideDown(i)
      t.save()
      if (this._opacity) t.globalAlpha = this._opacity
      t.translate(s / 2, 0)
      if (n) t.rotate(Math.PI)
      t.translate(0, r)
      if (this._rotation) t.rotate(this._rotation)
      t.drawImage(this._image, -s / 2, -o / 2, s, o)
      t.restore()
    } else this.resolveState()
  }
  appendHash(t) {
    t.appendDouble(this._offset)
    t.appendBoolean(this._rotateUp)
    t.appendDouble(this._opacity)
    t.appendDouble(this._rotation)
    const e = this._iconStyle
    const i = e.url ?? e.image
    if (i) appendImageHashCode(i, t)
    if (e.image) t.appendDouble(this.getSizeFactor(e.image))
  }
}
function calculatePatternWidth(t, e, i, s) {
  let o = t * s
  let r = e * s
  if (i) {
    const t = Math.sin(i)
    const e = Math.cos(i)
    const s = undefined
    const n = r * e + o * t
    o = r * t + o * e
    r = n
  }
  return { width: o, height: r }
}
