import { ShapeType } from './ShapeType.js'
export class Meridian {
  constructor(t, e, i) {
    this._longitude = t
    this._fromLatitude = e
    this._toLatitude = i
    this._type = ShapeType.MERIDIAN
  }
  get longitude() {
    return this._longitude
  }
  get fromLatitude() {
    return this._fromLatitude
  }
  get toLatitude() {
    return this._toLatitude
  }
  get type() {
    return this._type
  }
  get reference() {
    return this._reference
  }
  set reference(t) {
    this._reference = t
  }
  hashCode(t) {
    t.appendUInt32(this._type)
    t.appendDouble(this._longitude)
    t.appendDouble(this._fromLatitude)
    t.appendDouble(this._toLatitude)
  }
  toString() {
    return `MERIDIAN: ${this._longitude} -from/to: ${this._fromLatitude},${this._toLatitude}`
  }
  height() {
    return this._toLatitude - this._fromLatitude
  }
  getDegrees() {
    return this._longitude
  }
}
