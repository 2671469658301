import { ProgrammingError } from '../error/ProgrammingError.js'
import { InvalidReferenceError } from '../error/InvalidReferenceError.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { ShapeType } from './ShapeType.js'
import { Log } from '../util/Log.js'
import { getData as getLicenseData } from '../util/License.js'
import { request } from '../util/request.js'
import { toUrlFromRoot } from '../util/Config.js'
import {
  flipOrientationSFCT_flat,
  orientation2D_flat,
} from '../util/Cartesian.js'
var CLOSED_SHAPES = [
  ShapeType.POLYGON,
  ShapeType.ELLIPSE,
  ShapeType.CIRCLE,
  ShapeType.GEO_BUFFER,
  ShapeType.SECTOR,
  ShapeType.BOUNDS,
  ShapeType.ARC_BAND,
  ShapeType.COMPLEX_POLYGON,
]
function isSimpleShapeClosed(r) {
  var e = r.type
  return CLOSED_SHAPES.some(function (r) {
    return e === r || ShapeType.contains(r, e)
  })
}
function isShapeClosed(r) {
  if (isSimpleShapeClosed(r)) return true
  if (r.type === ShapeType.SHAPE_LIST) {
    for (var e = 0; e < r.shapeCount; e++)
      if (!isShapeClosed(r.getShape(e))) return false
    return true
  }
  return isExtrudedShape(r) && isShapeClosed(r.baseShape)
}
function isExtrudedShape(r) {
  return ShapeType.contains(r.type, ShapeType.EXTRUDED_SHAPE)
}
export var ShapeUtil = {
  fixOrientationSFCT_flat: function (r) {
    var e, t
    if (r.length > 0) {
      e = orientation2D_flat(r[0])
      for (t = r.length - 1; t > 0; t--)
        if (e === orientation2D_flat(r[t])) flipOrientationSFCT_flat(r[t])
    }
  },
  validateIndexForInsertionOrBounce: function (r, e, t) {
    if (r < 0 || r > e.length)
      throw new ProgrammingError(
        t || "Invalid index: '" + r + "', must be between 0 and " + e.length
      )
  },
  validateIndexForRemovalOrBounce: function (r, e, t) {
    if (r < 0 || r > e.length - 1)
      throw new ProgrammingError(
        t ||
          "Invalid index: '" + r + "', must be between 0 and " + (e.length - 1)
      )
  },
  referencesEqual: function (r, e) {
    return r ? r.equals(e) : !e
  },
  validateReferencesOrBounce: function (r, e) {
    if (this.referencesEqual(r, e)) return true
    throw new InvalidReferenceError('References are not equal')
  },
  forEachPointFromIndex: function (r, e, t) {
    var a = e.pointCount,
      n = r
    while (n < a) {
      t(e.getPoint(n))
      n += 1
    }
  },
  forEachShapeInShapeList: function (r, e) {
    var t = r.shapeCount,
      a = 0
    while (a < t) {
      e(r.getShape(a))
      a += 1
    }
  },
  isGeodeticReference: function (r) {
    return r && r.coordinateType === ReferenceType.GEODETIC
  },
  isGeodeticCoordinateType: function (r) {
    return r && r === ReferenceType.GEODETIC
  },
  isShapeClosed: isShapeClosed,
  isExtrudedShape: isExtrudedShape,
}
var decrypt = (function () {
  var r = function (r) {
    var e = U.call(r)
    return {
      m: r,
      mp: e,
      mpl: 32767 & e,
      mph: e >> 15,
      um: (1 << (T - 15)) - 1,
      mt2: 2 * r.t,
    }
  }
  var e = function (r, e) {
    var t
    for (t = this.t - 1; t >= 0; --t) e[t + r] = this[t]
    for (t = r - 1; t >= 0; --t) e[t] = 0
    e.t = this.t + r
    e.s = this.s
  }
  var t = function (r, e) {
    var t = r % T
    var a = T - t
    var n = (1 << a) - 1
    var i = Math.floor(r / T),
      l = (this.s << t) & L,
      f
    for (f = this.t - 1; f >= 0; --f) {
      e[f + i + 1] = (this[f] >> a) | l
      l = (this[f] & n) << t
    }
    for (f = i - 1; f >= 0; --f) e[f] = 0
    e[i] = l
    e.t = this.t + i + 1
    e.s = this.s
    j.call(e)
  }
  var a = function (r, e) {
    for (var t = r; t < this.t; ++t) e[t - r] = this[t]
    e.t = Math.max(this.t - r, 0)
    e.s = this.s
  }
  var n = function (r, e) {
    e.s = this.s
    var t = Math.floor(r / T)
    if (t >= this.t) {
      e.t = 0
      return
    }
    var a = r % T
    var n = T - a
    var i = (1 << a) - 1
    e[0] = this[t] >> a
    for (var l = t + 1; l < this.t; ++l) {
      e[l - t - 1] |= (this[l] & i) << n
      e[l - t] = this[l] >> a
    }
    if (a > 0) e[this.t - t - 1] |= (this.s & i) << n
    e.t = this.t - t
    j.call(e)
  }
  var i = function (r, i, l) {
    var f = M.call(r)
    if (f.t <= 0) return
    var o = M.call(this)
    if (o.t < f.t) {
      if (null != i) V(0)
      if (null != l) P.call(this, l)
      return
    }
    if (null == l) l = V(0)
    var c = V(0),
      s = this.s,
      v = r.s
    var h = T - O(f[f.t - 1])
    if (h > 0) {
      t.call(f, h, c)
      t.call(o, h, l)
    } else {
      P.call(f, c)
      P.call(o, l)
    }
    var u = c.t
    var p = c[u - 1]
    if (0 == p) return
    var d = p * (1 << k) + (u > 1 ? c[u - 2] >> I : 0)
    var g = x / d,
      y = (1 << k) / d,
      b = 1 << I
    var m = l.t,
      S = m - u,
      w = null == i ? V(0) : i
    e.call(c, S, w)
    if (z.call(l, w) >= 0) {
      l[l.t++] = 1
      F.call(l, w, l)
    }
    e.call(H, u, w)
    F.call(w, c, c)
    while (c.t < u) c[c.t++] = 0
    while (--S >= 0) {
      var E = l[--m] == p ? L : Math.floor(l[m] * g + (l[m - 1] + b) * y)
      if ((l[m] += q.call(c, 0, E, l, S, 0, u)) < E) {
        e.call(c, S, w)
        F.call(l, w, l)
        while (l[m] < --E) F.call(l, w, l)
      }
    }
    if (null != i) {
      a.call(l, u, i)
      if (s != v) F.call(B, i, i)
    }
    l.t = u
    j.call(l)
    if (h > 0) n.call(l, h, l)
    if (s < 0) F.call(B, l, l)
  }
  var l = function (r) {
    var t = V(0)
    e.call(M.call(r), this.m.t, t)
    i.call(t, this.m, null, t)
    if (r.s < 0 && z.call(t, B) > 0) F.call(this.m, t, t)
    return t
  }
  var f = function (r) {
    var e = V(0)
    P.call(r, e)
    o.call(this, e)
    return e
  }
  var o = function (r) {
    while (r.t <= this.mt2) r[r.t++] = 0
    for (var e = 0; e < this.m.t; ++e) {
      var t = 32767 & r[e]
      var n =
        (t * this.mpl +
          (((t * this.mph + (r[e] >> 15) * this.mpl) & this.um) << 15)) &
        L
      r[(t = e + this.m.t)] += q.call(this.m, 0, n, r, e, 0, this.m.t)
      while (r[t] >= A) {
        r[t] -= A
        r[++t]++
      }
    }
    j.call(r)
    a.call(r, this.m.t, r)
    if (z.call(r, this.m) >= 0) F.call(r, this.m, r)
  }
  var c = function (r, e) {
    _.call(r, e)
    o.call(this, e)
  }
  var s = function (r, e, t) {
    N.call(r, e, t)
    o.call(this, t)
  }
  var v = 52
  var h = '0123456789abcdefghijklmnopqrstuvwxyz'
  var u = []
  var p, d
  p = '0'.charCodeAt(0)
  for (d = 0; d <= 9; ++d) u[p++] = d
  p = 'a'.charCodeAt(0)
  for (d = 10; d < 36; ++d) u[p++] = d
  p = 'A'.charCodeAt(0)
  for (d = 10; d < 36; ++d) u[p++] = d
  var g = function (r) {
    return h.charAt(r)
  }
  var y = function (r, e) {
    var t = u[r.charCodeAt(e)]
    return null == t ? -1 : t
  }
  var b
  var m
  var S = 15715070 == (16777215 & 0xdeadbeefcafe)
  var w = function (r, e, t, a, n, i) {
    while (--i >= 0) {
      var l = e * this[r++] + t[a] + n
      n = Math.floor(l / 67108864)
      t[a++] = 67108863 & l
    }
    return n
  }
  var E = function (r, e, t, a, n, i) {
    var l = 32767 & e,
      f = e >> 15
    while (--i >= 0) {
      var o = 32767 & this[r]
      var c = this[r++] >> 15
      var s = f * o + c * l
      n =
        ((o = l * o + ((32767 & s) << 15) + t[a] + (1073741823 & n)) >>> 30) +
        (s >>> 15) +
        f * c +
        (n >>> 30)
      t[a++] = 1073741823 & o
    }
    return n
  }
  var C = function (r, e, t, a, n, i) {
    var l = 16383 & e,
      f = e >> 14
    while (--i >= 0) {
      var o = 16383 & this[r]
      var c = this[r++] >> 14
      var s = f * o + c * l
      n =
        ((o = l * o + ((16383 & s) << 14) + t[a] + n) >> 28) + (s >> 14) + f * c
      t[a++] = 268435455 & o
    }
    return n
  }
  var q
  if (
    S &&
    'object' === typeof navigator &&
    'Microsoft Internet Explorer' == navigator.appName
  ) {
    q = E
    b = 30
  } else if (
    S &&
    'object' === typeof navigator &&
    'Netscape' != navigator.appName
  ) {
    q = w
    b = 26
  } else {
    q = C
    b = 28
  }
  var T = b
  var L = (1 << b) - 1
  var A = 1 << b
  var x = Math.pow(2, v)
  var k = v - b
  var I = 2 * b - v
  var O = function (r) {
    var e = 1,
      t
    if ((t = r >>> 16)) {
      r = t
      e += 16
    }
    if ((t = r >> 8)) {
      r = t
      e += 8
    }
    if ((t = r >> 4)) {
      r = t
      e += 4
    }
    if ((t = r >> 2)) {
      r = t
      e += 2
    }
    if ((t = r >> 1)) {
      r = t
      e += 1
    }
    return e
  }
  var R = function () {
    if (this.t <= 0) return 0
    return T * (this.t - 1) + O(this[this.t - 1] ^ (this.s & L))
  }
  var j = function () {
    var r = this.s & L
    while (this.t > 0 && this[this.t - 1] == r) --this.t
  }
  var F = function (r, e) {
    var t = 0,
      a = 0,
      n = Math.min(r.t, this.t)
    while (t < n) {
      a += this[t] - r[t]
      e[t++] = a & L
      a >>= T
    }
    if (r.t < this.t) {
      a -= r.s
      while (t < this.t) {
        a += this[t]
        e[t++] = a & L
        a >>= T
      }
      a += this.s
    } else {
      a += this.s
      while (t < r.t) {
        a -= r[t]
        e[t++] = a & L
        a >>= T
      }
      a -= r.s
    }
    e.s = a < 0 ? -1 : 0
    if (a < -1) e[t++] = A + a
    else if (a > 0) e[t++] = a
    e.t = t
    j.call(e)
  }
  var D = function () {
    var r = V(0)
    F.call(V.ZERO, this, r)
    return r
  }
  var P = function (r) {
    for (var e = this.t - 1; e >= 0; --e) r[e] = this[e]
    r.t = this.t
    r.s = this.s
  }
  var M = function () {
    return this.s < 0 ? D.call(this) : this
  }
  var _ = function (r) {
    var e = M.call(this)
    var t = (r.t = 2 * e.t)
    while (--t >= 0) r[t] = 0
    for (t = 0; t < e.t - 1; ++t) {
      var a = q.call(e, t, e[t], r, 2 * t, 0, 1)
      if (
        (r[t + e.t] += q.call(
          e,
          t + 1,
          2 * e[t],
          r,
          2 * t + 1,
          a,
          e.t - t - 1
        )) >= A
      ) {
        r[t + e.t] -= A
        r[t + e.t + 1] = 1
      }
    }
    if (r.t > 0) r[r.t - 1] += q.call(e, t, e[t], r, 2 * t, 0, 1)
    r.s = 0
    j.call(r)
  }
  var N = function (r, e) {
    var t = M.call(this),
      a = M.call(r)
    var n = t.t
    e.t = n + a.t
    while (--n >= 0) e[n] = 0
    for (n = 0; n < a.t; ++n) e[n + t.t] = q.call(t, 0, a[n], e, n, 0, t.t)
    e.s = 0
    j.call(e)
    if (this.s != r.s) F.call(B, e, e)
  }
  var z = function (r) {
    var e = this.s - r.s
    if (e) return e
    var t = this.t
    if ((e = t - r.t)) return e
    while (--t >= 0) if ((e = this[t] - r[t])) return e
    return 0
  }
  var U = function () {
    if (this.t < 1) return 0
    var r = this[0]
    if (0 == (1 & r)) return 0
    var e = 3 & r
    return (e =
      ((e =
        ((e =
          ((e = (e * (2 - (15 & r) * e)) & 15) * (2 - (255 & r) * e)) & 255) *
          (2 - (((65535 & r) * e) & 65535))) &
        65535) *
        (2 - ((r * e) % A))) %
      A) > 0
      ? A - e
      : -e
  }
  var G = function (e, t) {
    var a = R.call(e),
      n,
      i = V(1),
      o
    n = 5
    o = new r(t)
    var v = [],
      h = 3,
      u = n - 1,
      p = (1 << n) - 1
    v[1] = l.call(o, this)
    if (n > 1) {
      var d = V(0)
      c.call(o, v[1], d)
      while (h <= p) {
        v[h] = V(0)
        s.call(o, d, v[h - 2], v[h])
        h += 2
      }
    }
    var g = e.t - 1,
      y,
      b = true,
      m = V(0),
      S
    a = O(e[g]) - 1
    while (g >= 0) {
      if (a >= u) y = (e[g] >> (a - u)) & p
      else {
        y = (e[g] & ((1 << (a + 1)) - 1)) << (u - a)
        if (g > 0) y |= e[g - 1] >> (T + a - u)
      }
      h = n
      while (0 == (1 & y)) {
        y >>= 1
        --h
      }
      if ((a -= h) < 0) {
        a += T
        --g
      }
      if (b) {
        P.call(v[y], i)
        b = false
      } else {
        while (h > 1) {
          c.call(o, i, m)
          c.call(o, m, i)
          h -= 2
        }
        if (h > 0) c.call(o, i, m)
        else {
          S = i
          i = m
          m = S
        }
        s.call(o, m, v[y], i)
      }
      while (g >= 0 && 0 == (e[g] & (1 << a))) {
        c.call(o, i, m)
        S = i
        i = m
        m = S
        if (--a < 0) {
          a = T - 1
          --g
        }
      }
    }
    return f.call(o, i)
  }
  var Y = function (r) {
    if (this.s < 0) return '-' + D.call(this).toString(r)
    var e
    var t = (1 << (e = 4)) - 1,
      a,
      n = false,
      i = '',
      l = this.t
    var f = T - ((l * T) % e)
    if (l-- > 0) {
      if (f < T && (a = this[l] >> f) > 0) {
        n = true
        i = g(a)
      }
      while (l >= 0) {
        if (f < e) {
          a = (this[l] & ((1 << f) - 1)) << (e - f)
          a |= this[--l] >> (f += T - e)
        } else {
          a = (this[l] >> (f -= e)) & t
          if (f <= 0) {
            f += T
            --l
          }
        }
        if (a > 0) n = true
        if (n) i += g(a)
      }
    }
    return n ? i : '0'
  }
  var V = function (r) {
    var e = new Object()
    if ('number' == typeof r) {
      e.t = 1
      e.s = r < 0 ? -1 : 0
      if (r > 0) e[0] = r
      else if (r < -1) e[0] = r + A
      else e.t = 0
    } else {
      var t = 4
      e.t = 0
      e.s = 0
      var a = r.length,
        n = false,
        i = 0
      while (--a >= 0) {
        var l = y(r, a)
        if (l < 0) {
          if ('-' == r.charAt(a)) n = true
          continue
        }
        n = false
        if (0 == i) e[e.t++] = l
        else if (i + t > T) {
          e[e.t - 1] |= (l & ((1 << (T - i)) - 1)) << i
          e[e.t++] = l >> (T - i)
        } else e[e.t - 1] |= l << i
        if ((i += t) >= T) i -= T
      }
      j.call(e)
      if (n) F.call(B, e, e)
    }
    e.toString = Y
    return e
  }
  var B = V(0)
  var H = V(1)
  return function (r, e, t) {
    return G.call(V(r), V(e), V(t)).toString()
  }
})()
var hash = (function () {
  var r = 8
  var e = (1 << r) - 1
  var t = function (r, e) {
    var t = (65535 & r) + (65535 & e)
    var a
    return (((r >> 16) + (e >> 16) + (t >> 16)) << 16) | (65535 & t)
  }
  var a = function (t) {
    var a = []
    for (var n = 0, i = t.length * r; n < i; n += r)
      a[n >> 5] |= (t.charCodeAt(n / r) & e) << n % 32
    return a
  }
  var n = function (r) {
    var e
    return new TextEncoder().encode(r)
  }
  var i = function (e) {
    var t = []
    for (var a = 0, n = e.length * r; a < n; a += r)
      t[a >> 5] |= e[a / r] << a % 32
    return t
  }
  var l = function (r, e) {
    return (r << e) | (r >>> (32 - e))
  }
  var f = function (r, e, a, n, i, f) {
    return t(l(t(t(e, r), t(n, f)), i), a)
  }
  var o = function (r, e, t, a, n, i, l) {
    return f((e & t) | (~e & a), r, e, n, i, l)
  }
  var c = function (r, e, t, a, n, i, l) {
    return f((e & a) | (t & ~a), r, e, n, i, l)
  }
  var s = function (r, e, t, a, n, i, l) {
    return f(e ^ t ^ a, r, e, n, i, l)
  }
  var v = function (r, e, t, a, n, i, l) {
    return f(t ^ (e | ~a), r, e, n, i, l)
  }
  var h = function (r, e) {
    r[e >> 5] |= 128 << e % 32
    r[(((e + 64) >>> 9) << 4) + 14] = e
    var a = 1732584193
    var n = -271733879
    var i = -1732584194
    var l = 271733878
    for (var f = 0; f < r.length; f += 16) {
      var h = a
      var u = n
      var p = i
      var d = l
      a = o(a, n, i, l, r[f + 0], 7, -680876936)
      l = o(l, a, n, i, r[f + 1], 12, -389564586)
      i = o(i, l, a, n, r[f + 2], 17, 606105819)
      n = o(n, i, l, a, r[f + 3], 22, -1044525330)
      a = o(a, n, i, l, r[f + 4], 7, -176418897)
      l = o(l, a, n, i, r[f + 5], 12, 1200080426)
      i = o(i, l, a, n, r[f + 6], 17, -1473231341)
      n = o(n, i, l, a, r[f + 7], 22, -45705983)
      a = o(a, n, i, l, r[f + 8], 7, 1770035416)
      l = o(l, a, n, i, r[f + 9], 12, -1958414417)
      i = o(i, l, a, n, r[f + 10], 17, -42063)
      n = o(n, i, l, a, r[f + 11], 22, -1990404162)
      a = o(a, n, i, l, r[f + 12], 7, 1804603682)
      l = o(l, a, n, i, r[f + 13], 12, -40341101)
      i = o(i, l, a, n, r[f + 14], 17, -1502002290)
      n = o(n, i, l, a, r[f + 15], 22, 1236535329)
      a = c(a, n, i, l, r[f + 1], 5, -165796510)
      l = c(l, a, n, i, r[f + 6], 9, -1069501632)
      i = c(i, l, a, n, r[f + 11], 14, 643717713)
      n = c(n, i, l, a, r[f + 0], 20, -373897302)
      a = c(a, n, i, l, r[f + 5], 5, -701558691)
      l = c(l, a, n, i, r[f + 10], 9, 38016083)
      i = c(i, l, a, n, r[f + 15], 14, -660478335)
      n = c(n, i, l, a, r[f + 4], 20, -405537848)
      a = c(a, n, i, l, r[f + 9], 5, 568446438)
      l = c(l, a, n, i, r[f + 14], 9, -1019803690)
      i = c(i, l, a, n, r[f + 3], 14, -187363961)
      n = c(n, i, l, a, r[f + 8], 20, 1163531501)
      a = c(a, n, i, l, r[f + 13], 5, -1444681467)
      l = c(l, a, n, i, r[f + 2], 9, -51403784)
      i = c(i, l, a, n, r[f + 7], 14, 1735328473)
      n = c(n, i, l, a, r[f + 12], 20, -1926607734)
      a = s(a, n, i, l, r[f + 5], 4, -378558)
      l = s(l, a, n, i, r[f + 8], 11, -2022574463)
      i = s(i, l, a, n, r[f + 11], 16, 1839030562)
      n = s(n, i, l, a, r[f + 14], 23, -35309556)
      a = s(a, n, i, l, r[f + 1], 4, -1530992060)
      l = s(l, a, n, i, r[f + 4], 11, 1272893353)
      i = s(i, l, a, n, r[f + 7], 16, -155497632)
      n = s(n, i, l, a, r[f + 10], 23, -1094730640)
      a = s(a, n, i, l, r[f + 13], 4, 681279174)
      l = s(l, a, n, i, r[f + 0], 11, -358537222)
      i = s(i, l, a, n, r[f + 3], 16, -722521979)
      n = s(n, i, l, a, r[f + 6], 23, 76029189)
      a = s(a, n, i, l, r[f + 9], 4, -640364487)
      l = s(l, a, n, i, r[f + 12], 11, -421815835)
      i = s(i, l, a, n, r[f + 15], 16, 530742520)
      n = s(n, i, l, a, r[f + 2], 23, -995338651)
      a = v(a, n, i, l, r[f + 0], 6, -198630844)
      l = v(l, a, n, i, r[f + 7], 10, 1126891415)
      i = v(i, l, a, n, r[f + 14], 15, -1416354905)
      n = v(n, i, l, a, r[f + 5], 21, -57434055)
      a = v(a, n, i, l, r[f + 12], 6, 1700485571)
      l = v(l, a, n, i, r[f + 3], 10, -1894986606)
      i = v(i, l, a, n, r[f + 10], 15, -1051523)
      n = v(n, i, l, a, r[f + 1], 21, -2054922799)
      a = v(a, n, i, l, r[f + 8], 6, 1873313359)
      l = v(l, a, n, i, r[f + 15], 10, -30611744)
      i = v(i, l, a, n, r[f + 6], 15, -1560198380)
      n = v(n, i, l, a, r[f + 13], 21, 1309151649)
      a = v(a, n, i, l, r[f + 4], 6, -145523070)
      l = v(l, a, n, i, r[f + 11], 10, -1120210379)
      i = v(i, l, a, n, r[f + 2], 15, 718787259)
      n = v(n, i, l, a, r[f + 9], 21, -343485551)
      a = t(a, h)
      n = t(n, u)
      i = t(i, p)
      l = t(l, d)
    }
    return [a, n, i, l]
  }
  var u = function (r) {
    var e = '0123456789abcdef'
    var t = []
    for (var a = 0, n = 4 * r.length; a < n; a++)
      t.push(
        e.charAt((r[a >> 2] >> ((a % 4) * 8 + 4)) & 15) +
          e.charAt((r[a >> 2] >> ((a % 4) * 8)) & 15)
      )
    return t.join('').replace(/^0+/, '')
  }
  return function (e, t) {
    var l
    var f
    var o
    if ((t || {}).useUTF8Encoding || false) {
      var c = n(e)
      var s = i(c)
      o = h(s, c.length * r)
    } else o = h(a(e), e.length * r)
    var v = [o[0] ^ o[2], o[1] ^ o[3]]
    return u(v)
  }
})()
ShapeUtil.unfu = hash
var obfuscateString = function (r) {
  return r
}
var rot13 = function (r) {
  return r.replace(/[a-zA-Z]/g, function (r) {
    return String.fromCharCode(
      (r <= 'Z' ? 90 : 122) >= (r = r.charCodeAt(0) + 13) ? r : r - 26
    )
  })
}
var annoy = function (r, e, t) {
  var a = function () {
    if (false === t) Log.warn(r)
    else {
      Log.error(r)
      if (e) {
        Log.error(rot13('Yvprafr grkg:'))
        Log.error(e)
        alert(
          r +
            '\n' +
            rot13(
              "Ybbx ng gur pbafbyr bs lbhe oebjfre'f qrirybcre gbbyf sbe zber vasbezngvba."
            )
        )
      } else alert(r)
    }
  }
  setTimeout(function () {
    a()
    if (false !== t) setInterval(a, 3e5)
  }, 0)
}
var moduleCheckFunction
var requestedModules = []
var checkLicense = function (r) {
  var e = parse(r)
  var t = rot13('pn9000')
  var a = e[rot13('cebqhpgAnzr')]
  var n = e[rot13('irefvba')]
  var i = e[rot13('pbclevtug')]
  var l = e[rot13('zrffntr')]
  var f = e[rot13('yvprafrr')]
  var o = e[rot13('yvprafrGlcr')]
  var c = e[rot13('rkcvelQngr')]
  var s = e[rot13('frevnyAhzore')]
  var v = e[rot13('bcgvbanyZbqhyrf')]
  var h = v ? v.split(/[ ,]+/) : new Array()
  ;(moduleCheckFunction = function () {
    var e, t, a, n, i
    while (requestedModules.length > 0) {
      var l = requestedModules.pop()
      a = l.module
      i = l.callbacks
      if (l.checked) return l.valid
      else {
        for (e = 0; e < h.length; e++) if ((t = h[e]) == a) n = true
        if (!n)
          annoy(
            rot13('Lbhe YhpvnqEVN yvprafr vf abg inyvq sbe guvf pbzcbarag: ') +
              a,
            r
          )
        l.callbacks = []
        l.checked = true
        l.valid = n
        for (const r of i) r && r(l.valid)
        n = false
      }
    }
  })()
  var u = function (e) {
    annoy(e, r, true)
  }
  var p = e[rot13('xrl')]
  if (!(n && a && i && l && f && o && s))
    u(rot13('Vapbzcyrgr YhpvnqEVN yvprafr svyr.'))
  if ('' !== t && t !== s)
    u(
      rot13(
        'Gur yvprafr svyr vf abg vagraqrq sbe guvf irefvba bs YhpvnqEVN. Frevny bs gur fbsgjner: '
      ) +
        t +
        rot13('. Frevny va gur yvprafr svyr: ') +
        s
    )
  var d = ''
  d += n
  d += a
  d += i
  d += l
  d += f
  d += o
  d += v ? v : ''
  d += c ? c : ''
  d += s
  var g = decrypt(
    p,
    obfuscateString(
      '73e3d4d54c315dd465600a02e2777345e828c0400eb73b4c79ff3802dba4f0cbe6c46cedc2dcdc312a9382761991a11835240d65650863cc0bda7d2eb52634a1'
    ),
    obfuscateString(
      'a65704f66df903a49b65859ca159abe2a3ee5ffd9276df585e41e69470a01b312d37acb6e118930c8c457c8ae0f2f37e2718bea49f76a68e2fc7ead613ca2303'
    )
  )
  var y
  if (hash(d) !== g) {
    var b
    if (hash(d, { useUTF8Encoding: true }) !== g)
      u(rot13('Vainyvq YhpvnqEVN yvprafr xrl (') + p + ')')
  }
  if (c) {
    var m = new Date().getTime()
    var S = c.split('/')
    var w = new Date(S[0], S[1] - 1, S[2]).getTime()
    if (w < m) u(rot13('YhpvnqEVN yvprafr rkcverq ba ') + c)
    else {
      var E = w - m
      if (E < 12096e5) {
        var C = Math.floor(E / 864e5)
        if (0 === C) annoy(rot13('Lbhe yvprafr jvyy rkcver gbqnl.'), r, false)
        else if (1 === C)
          annoy(rot13('Lbhe yvprafr jvyy rkcver va bar qnl.'), r, false)
        else
          annoy(
            rot13('Lbhe yvprafr jvyy rkcver va ') + C + rot13(' qnlf'),
            r,
            false
          )
      }
    }
  }
}
function parse(r) {
  if (null == r)
    throw new ReferenceError('Unabled to parse properties - parameter was null')
  var e = []
  var t = {}
  var a
  var n = -1
  var i
  var l = false
  var f = String.fromCharCode(13)
  var o = String.fromCharCode(10)
  var c = r.indexOf(f) > -1
  var s = r.indexOf(o) > -1
  a = (r = r.replace(new RegExp(String.fromCharCode(3), 'g'), ' ')).split(
    c && s ? f + o : c ? f : o
  )
  while (++n < a.length)
    if (
      (i = stripWhitespace(String(a[n]))).length > 1 &&
      '#' != i.charAt(0) &&
      '!' != i.charAt(0)
    ) {
      if (l) e[e.length - 1] = String(e[e.length - 1]).substr(0, -1) + i
      else e.push(i)
      l = '\\' == i.charAt(i.length - 1)
    }
  n = e.length
  var v
  var h
  while (--n > -1) {
    if (-1 == (v = getSplitIndex((i = e[n])))) {
      e.splice(n, 1)
      continue
    }
    h = stripWhitespace((h = i.substring(0, v)))
    r = stripWhitespace((r = i.substring(v + 1)))
    t[h] = r
  }
  return t
}
function getSplitIndex(r) {
  var e = ['=', ':']
  var t = 2
  var a
  var n = r.length
  while (--t > -1) if ((a = r.indexOf(e[t])) > -1 && a < n) n = a
  return n == r.length - 1 ? -1 : n
}
function stripWhitespace(r) {
  return r.replace(/^[ \s]+|[ \s]+$/g, '')
}
var checkLicenseAndAnnoyIfInvalid = function (r) {
  try {
    checkLicense(r)
  } catch (e) {
    annoy(rot13('Pbhyq abg purpx yvprafr'), r)
  }
}
var tryLicense = function (r) {
  var e = toUrlFromRoot(r)
  return request(e, { method: 'GET' })
    .then((r) => r.text())
    .then(checkLicenseAndAnnoyIfInvalid)
}
ShapeUtil.purpxYvprafr = function (r, e) {
  var t = rot13(r)
  var a = requestedModules.find((r) => r.module === t)
  if (!a) {
    a = { module: t, callbacks: [], checked: false, valid: false }
    requestedModules.push(a)
  }
  if (e)
    if (a.checked) {
      e(a.valid)
      return
    } else a.callbacks.push(e)
  if ('function' === typeof moduleCheckFunction) moduleCheckFunction()
}
getLicenseData()
  .then(checkLicenseAndAnnoyIfInvalid)
  .catch(function () {
    return tryLicense(rot13('yvprafr/yhpvnqevn_qrirybczrag.gkg'))
  })
  .catch(function () {
    return tryLicense(rot13('yvprafr/yhpvnqevn_qrcyblzrag.gkg'))
  })
  .catch(function () {
    annoy(rot13('Pbhyq abg ergevrir yvprafr'))
  })
