import { GeodeticReference } from '../reference/GeodeticReference.js'
import { Geodetic } from '../projection/Geodetic.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Transformation } from './Transformation.js'
import { TransformationType } from './TransformationType.js'
export class GeodeticGeodeticPlateCarreeTransformation extends Transformation {
  constructor(e, r) {
    super(e, r)
    if (!e.geodeticDatum.equals(r.geodeticDatum))
      throw new ProgrammingError(
        'GeodeticToGeodeticPlateCarree::constructor - source and destinationreference need to share same datum'
      )
    else if (!(e instanceof GeodeticReference))
      throw new ProgrammingError(
        'GeodeticToGeodeticPlateCarree::constructor - sourcereference needs to be geodetic'
      )
    else if (!(r.projection instanceof Geodetic))
      throw new ProgrammingError(
        'GeodeticToGeodeticPlateCarree::constructor - destination reference needs to have geodetic projection'
      )
    this._dstProjection = r.projection
  }
  _forward(e, r) {
    r.x = normalizeLon(e.x)
    r.y = e.y
    r.z = e.z
    return r
  }
  _inverse(e, r) {
    this._dstProjection.cartesian2geodeticOnSphereSFCT(e, 0, r)
    r.z = e.z
    return r
  }
  _forwardBoundsCoords(e, r) {
    r.setTo3D(e.x, e.width, e.y, e.height, e.z, e.depth)
  }
  _inverseBoundsCoords(e, r) {
    r.setTo3D(e.x, e.width, e.y, e.height, e.z, e.depth)
  }
  _getType() {
    return TransformationType.TYPE_SCALE
  }
  getScale() {
    return { x: 1, y: 1, z: 1 }
  }
}
