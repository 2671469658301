import { Hash } from '../../util/Hash.js'
import { isDefined, isString } from '../../util/Lang.js'
import { Photon } from '../../gen/photon/photon_painter.js'
const paintContextMap = new Map()
const hash = new Hash()
const FAST_READ_OUT = ''
export function decodePaintContext(t, e) {
  const n =
    Number(t) === Photon.PaintOutput.DepthOnly ||
    Number(t) === Photon.PaintOutput.ShadowDepthOnly
  let o
  let r = false
  let a
  if (n && e) {
    const t = paintContextMap.get(e)
    o = t?.depthExcludedLayers
    r = !!t?.depthIncludeController
    a = t?.panoramicLayer
  }
  return {
    depthPass: n,
    depthExcludedLayers: o,
    depthIncludeController: r,
    panoramicLayer: a,
  }
}
export function createPaintContextHashPanoramic(t) {
  if (!isString(t)) return ''
  return hashPaintContext({ panoramicLayer: t })
}
export function createPaintContextHashClosestSurface() {
  let {
    excludedLayers: t,
    includeControllerOnDraw: e,
    includeTransparent: n,
  } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
  const o = {}
  let r = false
  if (t?.length) {
    o.depthExcludedLayers = [...t].sort()
    r = true
  }
  if (n) {
    o.includeTransparent = true
    r = true
  }
  const a = r ? hashPaintContext(o) : FAST_READ_OUT
  if (r) o.depthIncludeController = !!e
  else if (!e) o.depthIncludeController = false
  const s = undefined
  return {
    depthContextHash: isDefined(o.depthIncludeController)
      ? hashPaintContext(o)
      : a,
    depthContextWithoutControllerHash: a,
  }
}
function hashPaintContext(t) {
  const e = { ...t }
  hash.reset()
  hash.appendString(JSON.stringify(e))
  const n = `${hash.getHashCode()}`
  paintContextMap.set(n, e)
  return n
}
