import { ProgrammingError } from '../../error/ProgrammingError.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import { isBoolean, isNumber } from '../../util/Lang.js'
export class TileSet3DLayerPerformanceHintsImpl {
  constructor(o) {
    this.assertPointCountValid(o.maxPointCount ?? null)
    this._eventedSupport = new EventedSupport(['Changed'])
    this._maxPointCount = o.maxPointCount ?? null
    this.occlusionCulling = o.occlusionCulling ?? false
  }
  set maxPointCount(o) {
    this.assertPointCountValid(o)
    this._maxPointCount = o
    this._eventedSupport.emit('Changed')
  }
  get maxPointCount() {
    return this._maxPointCount
  }
  get occlusionCulling() {
    return this._occlusionCulling
  }
  set occlusionCulling(o) {
    if (!isBoolean(o))
      throw new ProgrammingError(
        'occlusionCulling should be a boolean. Got ' + o + '.'
      )
    this._occlusionCulling = o
    this._eventedSupport.emit('Changed')
  }
  isPointCountValid(o) {
    return null === o || (isNumber(o, false) && o > 0)
  }
  assertPointCountValid(o) {
    if (!this.isPointCountValid(o))
      throw new ProgrammingError(
        'maxPointCount should be null or a positive non-zero number. Got ' +
          o +
          '.'
      )
  }
  setFrom(o) {
    this.maxPointCount = o.maxPointCount
    this.occlusionCulling = o.occlusionCulling ?? false
  }
  on(o, t) {
    return this._eventedSupport.on(o, t)
  }
}
