import { cloneDeep } from '../../util/JSON.js'
import { KML_PLACEMARK } from '../../util/kml/KMLTypes.js'
import { KMLFeature } from './KMLFeature.js'
export class KMLPlacemarkFeature extends KMLFeature {
  constructor(e, t, s, r) {
    super(e, t, s, r)
    this._meshes = []
  }
  get meshes() {
    return this._meshes
  }
  get type() {
    return KML_PLACEMARK
  }
  copy() {
    const e = this.shape ? this.shape.copy() : null
    if (e && this.shape) e.altitudeMode = this.shape.altitudeMode
    return new KMLPlacemarkFeature(
      e,
      cloneDeep(this.properties),
      this.id,
      this.options
    )
  }
}
