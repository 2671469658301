import { FixedSizeClusteringAlgorithm } from './FixedSizeClusteringAlgorithm.js'
import { GridAlgorithm } from './GridAlgorithm.js'
import { shouldSplit } from './Clustered.js'
export class HierarchicalClusteringAlgorithm {
  constructor() {
    this._gridAlgorithm = new GridAlgorithm()
    this._delegate = new FixedSizeClusteringAlgorithm()
  }
  cluster(t, e, l) {
    const i = []
    for (let t = 0; t < e.length; t++) {
      const r = e[t]
      if (0 === r.getPointCount()) continue
      if (shouldSplit(r, l.clusterSize)) {
        const t = r.getPoints().slice()
        for (let e = 0; e < t.length; e++) t[e].cluster = null
        const e = this.clusterImpl(t, [], l)
        for (let t = 0; t < e.length; t++) i.push(e[t])
      } else i.push(r)
    }
    const r = undefined
    return removeTooSmallClusters(this.clusterImpl(t, i, l), l.minimumPoints)
  }
  clusterImpl(t, e, l) {
    const i = { clusterSize: l.clusterSize / 20, minimumPoints: 2 }
    const r = this._gridAlgorithm.cluster(t, e, i)
    return this._delegate.cluster(t, r, l)
  }
}
function removeTooSmallClusters(t, e) {
  const l = []
  for (let i = 0; i < t.length; i++) {
    const r = t[i]
    const s = r.getPoints()
    if (s.length < e) for (let t = 0; t < s.length; t++) s[t].cluster = null
    else l.push(r)
  }
  return l
}
