import { CompositeEditHandle } from './CompositeEditHandle.js'
import { PointCreateHandle } from './PointCreateHandle.js'
import { Constants } from '../../../util/Constants.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { getUnitOfMeasure } from '../../../uom/UnitOfMeasureRegistry.js'
import { GeoReference } from '../../../reference/GeoReference.js'
import { Axis } from '../../../reference/Axis.js'
import { createPoint } from '../../../shape/ShapeFactory.js'
export class CreateByTemplateHandle extends CompositeEditHandle {
  constructor(e, t, n) {
    const r = createPoint(e.shape.reference, [0, 0])
    const o = undefined
    const i = undefined
    super(
      [
        new PointCreateHandle(r, (t) => {
          n(e, t)
        }),
        new CompositeEditHandle(t, { cascading: false }),
      ],
      { cascading: true }
    )
  }
}
export function getDefaultSize(e) {
  let t = 1
  if (e.reference.referenceType === ReferenceType.GEOCENTRIC)
    t = 1 / e.mapScale[0] / 30
  else if (e.reference.referenceType === ReferenceType.CARTESIAN)
    t = e.mapBounds.width / 10
  else {
    const n = e.reference.getAxis(Axis.Name.X).unitOfMeasure
    const r = getUnitOfMeasure('Meter')
    t = n.convertToUnit(e.mapBounds.width / 10, r)
  }
  return t < 2e6 ? t : 2e6
}
export function getDefaultPoint(e, t, n) {
  let r =
    arguments.length > 3 && void 0 !== arguments[3]
      ? arguments[3]
      : getDefaultSize(e)
  if (null === t.reference)
    throw new Error(
      'Point reference cannot be null. Transform the point to a model reference first.'
    )
  const o = t.copy()
  if (t.reference instanceof GeoReference)
    t.reference.geodeticDatum.ellipsoid.geodesicPositionSFCT(t, r, n, o)
  else
    o.translate2D(
      r * Math.sin(n * Constants.RAD2DEG),
      r * Math.cos(n * Constants.RAD2DEG)
    )
  return o
}
