import { Photon } from '../../gen/photon/photon_painter.js'
export class PhotonMeshVideoTexture {
  _level = 0
  constructor(t) {
    this._environmentSet = false
    this._video = t
    this._glTexture = null
    this.initializeEventListeners()
    this._previousState = -1
  }
  initializeEventListeners() {
    this._video.addEventListener('play', () => this.updateTexture())
    this._video.addEventListener('seeked', () => {
      if (this._video.paused) {
        this._previousState = 0
        this.updateTexture()
      }
    })
  }
  getFrameAsTexture(t, e) {
    if (this._video) {
      this._photonTexture = t.createTexture2D(
        this._video.videoWidth,
        this._video.videoHeight
      )
      this._glTexture = Photon.GL.textures[this._photonTexture.getGLName()]
      this._gl = Photon.GL.currentContext.GLctx
      this._level = 0
      this._invalidator = e
      this._environmentSet = true
      this._gl.bindTexture(this._gl.TEXTURE_2D, this._glTexture)
      this._gl.texParameteri(
        this._gl.TEXTURE_2D,
        this._gl.TEXTURE_WRAP_S,
        this._gl.CLAMP_TO_EDGE
      )
      this._gl.texParameteri(
        this._gl.TEXTURE_2D,
        this._gl.TEXTURE_WRAP_T,
        this._gl.CLAMP_TO_EDGE
      )
      this._gl.texParameteri(
        this._gl.TEXTURE_2D,
        this._gl.TEXTURE_MIN_FILTER,
        this._gl.LINEAR
      )
      this.updateTexture()
      return this._photonTexture
    } else return null
  }
  updateTexture() {
    const t = this._video.readyState
    if (
      this._environmentSet &&
      this._video &&
      (!this._video.paused || t < 2 || t != this._previousState) &&
      this._gl
    ) {
      this._previousState = t
      if (t >= 4) {
        const t = this._gl.RGBA
        const e = this._gl.RGBA
        const i = this._gl.UNSIGNED_BYTE
        this._gl.bindTexture(this._gl.TEXTURE_2D, this._glTexture)
        this._gl.texImage2D(
          this._gl.TEXTURE_2D,
          this._level,
          t,
          e,
          i,
          this._video
        )
      }
      this._invalidator?.invalidate()
    }
  }
  isValid() {
    return false
  }
  release() {
    this._video.removeEventListener('play', this.updateTexture)
    this._photonTexture?.release()
    this._glTexture = null
  }
}
