import { AbortError } from '../../error/AbortError.js'
import { EmptyTileError } from '../../error/EmptyTileError.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { PixelFormat } from '../../model/tileset/PixelFormat.js'
import { RasterDataType } from '../../model/tileset/RasterDataType.js'
import { Log } from '../../util/Log.js'
import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import * as TileUtil from './TileUtil.js'
export class PhotonTileSetModelAdapter extends Photon.TileSet {
  constructor(e, t, r, i) {
    super()
    this._tileSet = e
    this._textureDecoder = t
    this._imageDecoder = r
    this._pendingTiles = new Map()
    this._objectReleaseTracker = new ObjectReleaseTracker()
    this._objectReleaseTracker.track({
      release: this.clearPendingTiles.bind(this),
    })
    this._reference = this._objectReleaseTracker.track(
      i.getReference(this._tileSet.reference)
    )
    if (!this._reference)
      throw new ProgrammingError(
        'Invalid tile set reference identifier: ' +
          this._tileSet.reference.identifier
      )
    this._errorCount = 0
    const o = 1 << 30
    let n = e.levelCount
    for (let t = 0; t < e.levelCount; t++) {
      const r = (e.getTileColumnCount(t) || 1) * (e.getTileWidth(t) || 256)
      const i = (e.getTileRowCount(t) || 1) * (e.getTileHeight(t) || 256)
      if (r >= o || i >= o) {
        n = t
        break
      }
    }
    this._levelCount = n
  }
  release() {
    super.release()
    this._objectReleaseTracker.release()
  }
  _createTileKey(e) {
    return e.level + '-' + e.x + '-' + e.y
  }
  setImageDecoder(e) {
    this._imageDecoder = e
  }
  clearPendingTiles() {
    this._pendingTiles.forEach((e) => {
      e.callbacks.forEach((e) => e.release?.())
      e.abortController?.abort()
    })
    this._pendingTiles.clear()
  }
  getAndLogErrorDetails(e, t) {
    if (this._errorCount > 0) return Promise.resolve()
    this._errorCount++
    if (!this._tileSet.getTileURL) {
      Log.warn(
        'Error retrieving images for ' +
          this._tileSet.modelDescriptor.source +
          ' (' +
          this._tileSet.modelDescriptor.name +
          ')'
      )
      Log.warn('First error:')
      Log.warn(t)
      return Promise.resolve()
    }
    return Promise.resolve(
      this._tileSet.getTileURL(this._tileSet.baseURL, e)
    ).then((t) => {
      const r = (e) => {
        const { source: r, name: i } = this._tileSet.modelDescriptor
        Log.warn(`Error retrieving tiles for ${r} (${i})`)
        Log.warn(`URL is ${t}`)
        Log.warn(e)
      }
      if (null === t) {
        r('URL is null')
        return
      }
      return new Promise((i) => {
        try {
          const o = new XMLHttpRequest()
          o.timeout = 2e3
          o.onerror = (e) => {
            r('Connection error')
            i()
          }
          o.ontimeout = (e) => {
            r('Timeout after 2 seconds')
            i()
          }
          o.onload = (t) => {
            if (200 !== o.status && (404 !== o.status || 0 === e.level))
              r('HTTP status ' + o.status + ': ' + o.statusText)
            return i()
          }
          o.open('GET', t)
          o.responseType = 'arraybuffer'
          o.send()
        } catch (e) {
          r('Cannot determine failure type')
        }
      })
    })
  }
  convertToPhotonPixelFormat(e) {
    switch (e) {
      case PixelFormat.RGBA_8888:
        return Photon.PixelFormat.Rgba8888
      case PixelFormat.RGB_888:
        return Photon.PixelFormat.Rgb888
      case PixelFormat.FLOAT_32:
        return Photon.PixelFormat.Float32
      default:
        throw new Error('Unknown PixelFormat')
    }
  }
  requestTile(e, t) {
    const r = this._createTileKey(e)
    const i = this._pendingTiles.get(r)
    if (i) {
      i.callbacks.push(t)
      return
    }
    const o = new AbortController()
    const n =
      this._tileSet.modelDescriptor.type === RasterDataType.TIN_ELEVATION
        ? this.requestTinTerrainTile(e, o)
        : this.requestImageTile(e, o)
    const l = new PendingTile(n, o)
    this._pendingTiles.set(r, l)
    l.callbacks.push(t)
    l.promise.then(
      (t) => this.tileAvailable(e, t),
      (t) => this.tileUnavailable(e, t)
    )
  }
  requestTinTerrainTile(e, t) {
    return new Promise((r, i) => {
      let o = false
      const n = (e) => {
        if (o) return
        const t = this._imageDecoder.decodeMesh(e, e.tile)
        r(t)
      }
      t.signal.addEventListener('abort', () => {
        o = true
        i(new AbortError('Tile request cancelled'))
      })
      return this._tileSet.getMesh(e, n, i, t.signal)
    })
  }
  requestImageTile(e, t) {
    var r = this
    let i = false
    return new Promise((o, n) => {
      t.signal.addEventListener('abort', () => {
        i = true
        return n(new AbortError('Tile request cancelled'))
      })
      const l = function (e, t) {
        let l =
          arguments.length > 2 && void 0 !== arguments[2]
            ? arguments[2]
            : t.mimeType || 'unknown'
        if (i) return
        try {
          TileUtil.validateTileData(t)
        } catch (e) {
          Log.error(
            'TileData validation error.',
            e instanceof Error ? e : void 0
          )
          return
        }
        const s = t.data
        if (s instanceof Image) {
          const e = r._textureDecoder.createPhotonTexture(s)
          return e
            ? o(e)
            : n(Error('Could not decode texture from tile Image.'))
        }
        if (s instanceof ArrayBuffer) {
          if (void 0 !== t.pixelFormat) {
            if (isElevation(r._tileSet) || isTinElevation(r._tileSet)) {
              const i = r.convertToPhotonPixelFormat(t.pixelFormat)
              return o(r._imageDecoder.decodeRaw({ ...t, pixelFormat: i }, e))
            }
            if (
              t.pixelFormat !== PixelFormat.RGB_888 &&
              t.pixelFormat !== PixelFormat.RGBA_8888
            ) {
              const e = PixelFormat.RGB_888
              t.data = TileUtil.convertImageData(t, e).buffer
              t.pixelFormat = e
            }
            const i = r.convertToPhotonPixelFormat(t.pixelFormat)
            return o(
              r._imageDecoder._createImageFromDecodedData(
                t.width,
                t.height,
                i,
                t.data
              )
            )
          }
          if (t.mimeType)
            return o(r._imageDecoder.decodeArrayBuffer(s, t.mimeType, e))
        }
        return n(
          new Error(
            `UrlTileSetModel#getImage - onSuccess callback: Could not decode tileData: ${t.data}, contentType: ${l}`
          )
        )
      }
      this._tileSet.getTileData(e, l, n, t.signal)
    })
  }
  tileAvailable(e, t) {
    const r = this._createTileKey(e)
    const i = this._pendingTiles.get(r)
    this._pendingTiles.delete(r)
    if (i)
      for (let r = 0; r < i.callbacks.length; r++) {
        const o = i.callbacks[r]
        if (isTexture(t)) o.tileTextureAvailable(e, t)
        else if (isGeometry(t)) o.tileGeometryAvailable(e, t)
        else o.tileImageAvailable(e, t)
        if (o.release) o.release()
      }
    t.release()
  }
  tileUnavailable(e, t) {
    if ('AbortError' === t.name) return Promise.resolve()
    return this.getAndLogErrorDetails(e, t.toString()).then(() => {
      const r = this._createTileKey(e)
      const i = this._pendingTiles.get(r)
      this._pendingTiles.delete(r)
      if (i)
        for (let r = 0; r < i.callbacks.length; r++) {
          const o = i.callbacks[r]
          if (t instanceof EmptyTileError) o.nonLeafTileUnavailable(e)
          else o.tileUnavailable(e)
          if (o.release) o.release()
        }
    })
  }
  cancelTile(e) {
    const t = this._createTileKey(e)
    const r = this._pendingTiles.get(t)
    this._pendingTiles.delete(t)
    if (r) {
      if (r.abortController && !r.abortController.signal.aborted)
        r.abortController.abort()
      for (let e = 0; e < r.callbacks.length; e++) {
        const t = r.callbacks[e]
        if (t.release) t.release()
      }
    }
  }
  get geoReference() {
    return this._reference
  }
  get jsGeoReference() {
    return this._tileSet.reference
  }
  set jsGeoReference(e) {
    throw new ProgrammingError('jsGeoReference property is not mutable')
  }
  get tileSetBounds() {
    if (null == this._tileSet.bounds)
      throw new Error('tileSetBounds not initialized')
    return this._tileSet.bounds
  }
  set tileSetBounds(e) {
    throw new ProgrammingError('tileSetBounds property is not mutable')
  }
  get levelCount() {
    return this._levelCount
  }
  set levelCount(e) {
    throw new ProgrammingError('levelCount property is not mutable')
  }
  get tileSet() {
    return this._tileSet
  }
  getBounds(e) {
    const t = this._tileSet.getBounds(e)
    if (null == t)
      throw new Error('tileSetBounds not initialized at level ${level}')
    return t
  }
  getTileWidth(e) {
    return this._tileSet.getTileWidth(e) ?? 0
  }
  getTileHeight(e) {
    return this._tileSet.getTileHeight(e) ?? 0
  }
  getTileCountX(e) {
    return this._tileSet.getTileColumnCount(e) ?? 0
  }
  getTileCountY(e) {
    return this._tileSet.getTileRowCount(e) ?? 0
  }
  getTileBounds(e) {
    const t = this._tileSet.getBounds(e.level)
    const r = this.getTileCountX(e.level)
    const i = this.getTileCountY(e.level)
    if (null === t || null === r || null === i) return null
    const o = t.width / r
    const n = t.height / i
    const l = undefined
    const s = undefined
    return { x: t.x + e.x * o, y: t.y + e.y * n, width: o, height: n }
  }
}
class PendingTile {
  constructor(e, t) {
    this.promise = e
    this.callbacks = []
    this.abortController = t
  }
}
function isTexture(e) {
  return 'undefined' !== typeof e.getGLName
}
function isGeometry(e) {
  return 'undefined' !== typeof e.localOrigin
}
function isElevation(e) {
  return e.dataType === RasterDataType.ELEVATION
}
function isTinElevation(e) {
  return e.dataType === RasterDataType.TIN_ELEVATION
}
