import { isFunction } from './Lang.js'
function isReleasable(e) {
  return e && isFunction(e.release)
}
function isDestroyable(e) {
  return e && isFunction(e.destroy)
}
function isDisposable(e) {
  return isReleasable(e) || isDestroyable(e)
}
export class ObjectReleaseTracker {
  constructor() {
    this._objectsToRelease = null
    this._released = false
  }
  track(e) {
    if (!this._objectsToRelease) this._objectsToRelease = []
    if (isDisposable(e)) this._objectsToRelease.push(e)
    return e
  }
  untrack(e) {
    if (this._objectsToRelease && isDisposable(e)) {
      const s = this._objectsToRelease.indexOf(e)
      if (s > -1) {
        this._objectsToRelease.splice(s, 1)
        dispose(e)
      }
    }
    return null
  }
  retrack(e, s) {
    this.untrack(e)
    return this.track(s)
  }
  release() {
    if (this._objectsToRelease) {
      this._objectsToRelease.reverse()
      this._objectsToRelease.forEach(dispose)
    }
    this._objectsToRelease = null
    this._released = true
  }
  isReleased() {
    return this._released
  }
}
function dispose(e) {
  if (isReleasable(e)) e.release()
  else if (isDestroyable(e)) e.destroy()
}
export function releaseObject(e) {
  if (isDisposable(e)) {
    dispose(e)
    return null
  }
  return e
}
