import { ProgrammingError } from '../../../../error/ProgrammingError.js'
import { ArrowType } from '../ArrowType.js'
import { WrapperPattern } from './WrapperPattern.js'
import { Stroked } from './util/Stroked.js'
import { Filled } from './util/Filled.js'
import { PatternType } from './util/PatternType.js'
import { PolylinePattern } from './PolylinePattern.js'
import { AllowOverlapPattern } from './AllowOverlapPattern.js'
import { LinePattern } from './LinePattern.js'
import { AtomicPattern } from './AtomicPattern.js'
import { AppendPattern } from './AppendPattern.js'
import { isBoolean, isNumber } from '../../../../util/Lang.js'
export class ArrowPattern extends WrapperPattern {
  constructor(e) {
    super(makeArrowPattern(e), PatternType.ARROW)
    this._arrowOptions = e
    this._stroked = new Stroked({ color: e.lineColor, width: e.lineWidth })
    this._filled = new Filled({ color: e.fillColor })
  }
  get arrowOptions() {
    return this._arrowOptions
  }
  get lineColor() {
    return this._stroked.lineColor
  }
  set lineColor(e) {
    this._stroked.lineColor = e
  }
  get lineWidth() {
    return this._stroked.lineWidth
  }
  set lineWidth(e) {
    this._stroked.lineWidth = e
  }
  get dashed() {
    return this._stroked.dashed
  }
  set dashed(e) {
    this._stroked.dashed = e
  }
  strokePath(e) {}
  get fillColor() {
    return this._filled.fillColor
  }
  set fillColor(e) {
    this._filled.fillColor = e
  }
  fillPath(e) {}
  paint(e, r, t, o) {
    e.translate(this.arrowOptions.shiftX, this.arrowOptions.shiftY)
    super.paint(e, r, t, o)
    e.translate(-this.arrowOptions.shiftX, -this.arrowOptions.shiftY)
  }
  appendHash(e) {
    e.appendString(this.arrowOptions.arrowType)
    e.appendDouble(this.arrowOptions.size)
    e.appendDouble(this.arrowOptions.height)
    e.appendDouble(this.arrowOptions.offset)
    e.appendBoolean(this.arrowOptions.forward)
    this._stroked.appendHash(e)
    this._filled.appendHash(e)
  }
}
export function normalizeArrowOptions(e) {
  const r = (e = e || {}).fill || {}
  const t = e.line || {}
  return {
    arrowType: e.type || ArrowType.REGULAR_FILLED,
    size: isNumber(e.size) ? e.size : 5,
    height: isNumber(e.height) ? e.height : -1,
    offset: isNumber(e.offset) ? e.offset : 0,
    forward: isBoolean(e.forward) ? e.forward : true,
    lineWidth: isNumber(t.width) ? t.width : 2,
    lineColor: t.color || 'rgb(0,0,0)',
    fillColor: r.color || 'rgb(255,255,255)',
    shiftX: isNumber(e.shiftX) ? e.shiftX : 0,
    shiftY: isNumber(e.shiftY) ? e.shiftY : 0,
  }
}
function makeArrowPattern(e) {
  const r = e.arrowType
  const t = e.size
  const o = e.height
  const n = e.offset
  const i = e.forward
  const l = e.lineWidth
  const s = e.lineColor
  const a = e.fillColor
  switch (r) {
    case ArrowType.REGULAR_FILLED:
      return new PolylinePattern({
        points: createRegularArrowhead(o, t, i, n),
        line: { width: l, color: s },
        fill: { color: a },
      })
    case ArrowType.REGULAR_OUTLINED:
      return new PolylinePattern({
        points: createRegularArrowhead(o, t, i, n),
        line: { width: l, color: s },
      })
    case ArrowType.PLAIN:
      return new PolylinePattern({
        points: createPlainArrowhead(o, t, i, n, false),
        line: { width: l, color: s },
      })
    case ArrowType.PLAIN_OUTLINED:
      return new PolylinePattern({
        points: createPlainArrowhead(o, t, i, n, true),
        line: { width: l, color: s },
      })
    case ArrowType.PLAIN_FILLED:
      return new PolylinePattern({
        points: createPlainArrowhead(o, t, i, n, true),
        line: { width: l, color: s },
        fill: { color: a },
      })
    case ArrowType.LEFT_HALF:
      return new AllowOverlapPattern(
        new LinePattern({
          length: t,
          offset0: n + (i ? (o < 0 ? t : o) : 0),
          offset1: n + (i ? 0 : o < 0 ? t : o),
          line: { width: l, color: s },
        }),
        { overlapLeft: 0, overlapRight: t - 1 }
      )
    case ArrowType.RIGHT_HALF:
      return new AllowOverlapPattern(
        new LinePattern({
          length: t,
          offset0: n - (i ? (o < 0 ? t : o) : 0),
          offset1: n - (i ? 0 : o < 0 ? t : o),
          line: { width: l, color: s },
        }),
        { overlapLeft: t - 1, overlapRight: 0 }
      )
    case ArrowType.DOUBLE_LEFT_HALF:
      return new AllowOverlapPattern(
        new AtomicPattern(
          new AppendPattern([
            new LinePattern({
              length: t,
              offset0: n + (i ? (o < 0 ? t : o) : 0),
              offset1: n + (i ? 0 : o < 0 ? t : o),
              line: { width: l, color: s },
            }),
            new LinePattern({
              length: t,
              offset0: n + (i ? (o < 0 ? t : o) : 0),
              offset1: n + (i ? 0 : o < 0 ? t : o),
              line: { width: l, color: s },
            }),
          ])
        ),
        { overlapLeft: 0, overlapRight: 2 * t - 1 }
      )
    case ArrowType.DOUBLE_RIGHT_HALF:
      return new AllowOverlapPattern(
        new AtomicPattern(
          new AppendPattern([
            new LinePattern({
              length: t,
              offset0: n - (i ? (o < 0 ? t : o) : 0),
              offset1: n - (i ? 0 : o < 0 ? t : o),
              line: { width: l, color: s },
            }),
            new LinePattern({
              length: t,
              offset0: n - (i ? (o < 0 ? t : o) : 0),
              offset1: n - (i ? 0 : o < 0 ? t : o),
              line: { width: l, color: s },
            }),
          ])
        ),
        { overlapLeft: 0, overlapRight: 2 * t - 1 }
      )
    case ArrowType.BLUNT:
      return new AllowOverlapPattern(
        new LinePattern({
          length: t,
          offset0:
            n +
            (i ? (o < 0 ? 0.5 * t : 0.5 * t) : -(o < 0 ? 0.5 * t : 0.5 * t)),
          offset1:
            n + (i ? -(o < 0 ? 0.5 * t : 0.5 * t) : o < 0 ? 0.5 * t : 0.5 * t),
          line: { width: l, color: s },
        }),
        { overlapLeft: 0, overlapRight: 0.5 * t }
      )
    default:
      throw new ProgrammingError(`Unknown arrow type: ${r}`)
  }
}
function createRegularArrowhead(e, r, t, o) {
  const n = e < 0 ? 0.25 * r : 0.5 * e
  return t
    ? [
        [r, o],
        [0, o + n],
        [0.25 * r, o],
        [0, o - n],
        [r, o],
      ]
    : [
        [0, o],
        [r, o + n],
        [0.75 * r, o],
        [r, o - n],
        [0, o],
      ]
}
function createPlainArrowhead(e, r, t, o, n) {
  const i = e < 0 ? r : 0.5 * e
  const l = t
    ? [
        [0, o + i],
        [r, o],
        [0, o - i],
      ]
    : [
        [r, o + i],
        [0, o],
        [r, o - i],
      ]
  if (n) l.push([t ? 0 : r, o + i])
  return l
}
