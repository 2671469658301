import { Log } from '../util/Log.js'
import {
  isDefined,
  isFunction,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from '../util/Lang.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { ShapeType } from '../shape/ShapeType.js'
import { linear, quadInOut } from '../util/Easing.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { AnimationManager } from './animation/AnimationManager.js'
import { distance3D } from '../util/Cartesian.js'
const DEFAULT_PAN_DURATION = 1e3
const DEFAULT_ZOOM_DURATION = 250
const DEFAULT_ROTATE_DURATION = 1e3
const DEFAULT_FIT_DURATION = 4e3
const DEFAULT_FIT_MARGIN = '2%'
const DEFAULT_LOOKFROM_DURATION = 4e3
const DEFAULT_MAPNAVIGATOR_SNAP_TO_SCALE_LEVELS = false
const ENABLE_ALL = 16777215
class AnimationOptions {
  constructor(t, e) {
    this._duration = t
    this._ease = e
  }
  setToLiteral(t) {
    if (isUndefined(t)) return
    if (!isUndefined(t.duration) && null !== t.duration)
      this.duration = t.duration
    if (!isUndefined(t.ease)) this.ease = t.ease
  }
  get duration() {
    return this._duration
  }
  set duration(t) {
    if (isNumber(t) && t >= 0) this._duration = t
    else throw new Error(`invalid animation duration: ${t}`)
  }
  get ease() {
    return this._ease
  }
  set ease(t) {
    if (isFunction(t)) this._ease = t
    else throw new Error(`invalid animation ease: ${t}`)
  }
}
export let EnabledOperations = (function (t) {
  t[(t['ALL'] = 16777215)] = 'ALL'
  t[(t['NONE'] = 0)] = 'NONE'
  return t
})({})
class MapNavigatorDefaults {
  constructor() {
    this._snapToScaleLevels = DEFAULT_MAPNAVIGATOR_SNAP_TO_SCALE_LEVELS
    this._pan = new AnimationOptions(DEFAULT_PAN_DURATION, linear)
    this._rotate = new AnimationOptions(DEFAULT_ROTATE_DURATION, linear)
    this._zoom = new AnimationOptions(DEFAULT_ZOOM_DURATION, linear)
    this._fit = new FitOptions(DEFAULT_FIT_DURATION, linear, DEFAULT_FIT_MARGIN)
    this._lookFrom = new AnimationOptions(DEFAULT_LOOKFROM_DURATION, linear)
  }
  setToLiteral(t) {
    if (!isDefined(t)) return
    if (isDefined(t.pan)) this.pan = t.pan
    if (isDefined(t.rotate)) this.rotate = t.rotate
    if (isDefined(t.zoom)) this.zoom = t.zoom
    if (isDefined(t.fit)) this.fit = t.fit
    if (isDefined(t.lookFrom)) this.lookFrom = t.lookFrom
    if (isDefined(t.snapToScaleLevels))
      this.snapToScaleLevels = t.snapToScaleLevels
  }
  get pan() {
    return this._pan
  }
  set pan(t) {
    this._pan.setToLiteral(t)
  }
  get zoom() {
    return this._zoom
  }
  set zoom(t) {
    this._zoom.setToLiteral(t)
  }
  get rotate() {
    return this._rotate
  }
  set rotate(t) {
    this._rotate.setToLiteral(t)
  }
  get fit() {
    return this._fit
  }
  set fit(t) {
    this._fit.setToLiteral(t)
  }
  get lookFrom() {
    return this._lookFrom
  }
  set lookFrom(t) {
    this._lookFrom.setToLiteral(t)
  }
  get snapToScaleLevels() {
    return this._snapToScaleLevels
  }
  set snapToScaleLevels(t) {
    this._snapToScaleLevels = t
  }
}
class FitOptions extends AnimationOptions {
  constructor(t, e, a) {
    super(t, e)
    this._fitMargin = a
  }
  get fitMargin() {
    return this._fitMargin
  }
  set fitMargin(t) {
    parseFitMargin(t)
    this._fitMargin = t
  }
  setToLiteral(t) {
    if (!isDefined(t)) return
    super.setToLiteral(t)
    if (!isUndefined(t.fitMargin)) this.fitMargin = t.fitMargin
  }
}
export class MapNavigatorBase {
  constructor(t) {
    if (!t) throw new Error('MapNavigator::constructor - required map argument')
    this._defaults = new MapNavigatorDefaults()
    this._map = t
    this._enabledOperations = ENABLE_ALL
  }
  parseZoomOptions(t, e) {
    if (!isDefined(t))
      throw new Error(`MapNavigator.zoom called with invalid zoomOptions: ${t}`)
    const a = isDefined(t.factor)
    const i = isDefined(t.targetScale)
    if (!a && !i)
      throw new Error(
        `MapNavigator.zoom: invalid zoomOptions. No factor or targetScale. zoomOptions: ${t}`
      )
    if (a && i)
      throw new Error(
        `MapNavigator.zoom: invalid zoomOptions. Both factor and targetScale are defined: ${t}`
      )
    const o = t.location || getViewCenter(e)
    if (!o.type || !ShapeType.contains(o.type, ShapeType.POINT))
      throw new Error(
        `MapNavigator.zoom: invalid location: expected to be a Point, but location is: ${o}`
      )
    const n = parseBasicAnimOptions(
      this.defaults.zoom.duration,
      this.defaults.zoom.ease,
      t.animate
    )
    if (e.is3D()) {
      let r = null
      if (isNumber(t.factor)) r = t.factor
      else if (isObject(t.factor))
        if (t.factor.x === t.factor.y) r = t.factor.x
        else
          throw new Error(
            `MapNavigator.zoom: invalid zoomOptions. Non-uniform zooming is not supported in 3D: ${t}`
          )
      if (a)
        if (!isNumber(r) || r <= 0 || isNaN(r))
          throw new Error(`MapNavigator.zoom: invalid value for factor:${r}`)
      let s = null
      if (isNumber(t.targetScale)) s = t.targetScale
      else if (isObject(t.targetScale))
        if (t.targetScale.x === t.targetScale.y) s = t.targetScale.x
        else
          throw new Error(
            `MapNavigator.zoom: invalid zoomOptions. Non-uniform zooming is not supported in 3D: ${t}`
          )
      if (i)
        if (!isNumber(s) || s <= 0 || isNaN(s))
          throw new Error(`MapNavigator.zoom: invalid value for  scale :${s}`)
      return {
        location: o,
        factor: null !== r ? r : s / e.mapScale[0],
        animate: n,
      }
    }
    let r = null
    let s = null
    if (isNumber(t.factor)) {
      r = t.factor
      s = t.factor
    } else if (isObject(t.factor)) {
      if (!isNumber(t.factor.x) && !isNumber(t.factor.y))
        throw new Error(
          `MapNavigator.zoom: invalid object literal for zoomOptions.factor: ${t.factor}`
        )
      r = isNumber(t.factor.x) ? t.factor.x : 1
      s = isNumber(t.factor.y) ? t.factor.y : 1
    }
    if (a) {
      if (!isNumber(r) || r <= 0 || isNaN(r))
        throw new Error(
          `MapNavigator.zoom: invalid value for x-axis factor:${r}`
        )
      if (!isNumber(s) || s <= 0 || isNaN(s))
        throw new Error(
          `MapNavigator.zoom: invalid value for y-axis factor: ${s}`
        )
    }
    let l = null
    let c = null
    if (isNumber(t.targetScale)) {
      l = t.targetScale
      c = t.targetScale
    } else if (isObject(t.targetScale)) {
      if (!isNumber(t.targetScale.x) && !isNumber(t.targetScale.y))
        throw new Error(
          `MapNavigator.zoom: invalid object literal for zoomOptions.targetScale: ${t.targetScale}`
        )
      l = isNumber(t.targetScale.x) ? t.targetScale.x : e.mapScale[0]
      c = isNumber(t.targetScale.y) ? t.targetScale.y : e.mapScale[1]
    }
    if (i) {
      if (!isNumber(l) || l <= 0 || isNaN(l))
        throw new Error(
          `MapNavigator.zoom: invalid value for x-axis scale :${l}`
        )
      if (!isNumber(c) || c <= 0 || isNaN(c))
        throw new Error(
          `MapNavigator.zoom: invalid value for y-axis scale ${c}`
        )
    }
    const p = isDefined(t.snapToScaleLevels)
      ? !!t.snapToScaleLevels
      : this.defaults.snapToScaleLevels
    const f = e.camera.asLook2D()
    return {
      location: o,
      targetScaleX:
        null !== r ? r * f.scaleX : e.convertXComponentMapToPixel(l),
      targetScaleY:
        null !== s ? s * f.scaleY : e.convertYComponentMapToPixel(c),
      snapToScaleLevels: p,
      snapRoundFunction: t.snapRoundFunction,
      animate: n,
    }
  }
  parsePanOptions(t, e) {
    if (!t)
      throw new Error(`MapNavigator.pan called with invalid panOptions: ${t}`)
    const a = t.targetLocation
    if (!a) throw new Error(`MapNavigator.pan: invalid targetLocation: ${a}`)
    if (!a.type || !ShapeType.contains(a.type, ShapeType.POINT))
      throw new Error(
        `MapNavigator.pan: invalid targetLocation: expected to be a Point, but targetLocation.type is: ${a}`
      )
    const i = t.toViewLocation || getViewCenter(e)
    if (!i.type || !ShapeType.contains(i.type, ShapeType.POINT))
      throw new Error(
        `MapNavigator.pan: invalid toViewLocation: expected to be a Point, but toViewLocation is: ${i}`
      )
    if (null !== i.reference)
      throw new Error(
        `MapNavigator.pan: invalid toViewLocation: expected to be in view reference (null), toViewLocation.reference is: ${i.reference}`
      )
    const o = undefined
    return {
      toViewLocation: i,
      targetLocation: a,
      animate: parseBasicAnimOptions(
        this.defaults.pan.duration,
        this.defaults.pan.ease,
        t.animate
      ),
      constantYaw: t?.constantYaw ?? false,
      keepAnimationRunning: t?.keepAnimationRunning ?? false,
    }
  }
  parseRotateOptions(t, e) {
    if (!isDefined(t))
      throw new Error(
        `MapNavigator.rotate called with invalid rotateOptions: ${t}`
      )
    const a =
      isDefined(t.targetRotation) ||
      isDefined(t.targetYaw) ||
      isDefined(t.targetPitch)
    const i =
      isDefined(t.deltaRotation) ||
      isDefined(t.deltaYaw) ||
      isDefined(t.deltaPitch)
    if (!a && !i)
      throw new Error(
        `MapNavigator.rotate: invalid rotateOptions - without a target rotation or a rotation delta: ${t}`
      )
    if (a && i)
      throw new Error(
        `MapNavigator.rotate: invalid rotateOptions - with both a target rotation and a rotation delta: ${t}`
      )
    const o = t.center || getViewCenter(e)
    if (!o.type || !ShapeType.contains(o.type, ShapeType.POINT))
      throw new Error(
        `MapNavigator.rotate: invalid toViewLocation: expected to be a Point, but center is: ${o}`
      )
    const n = parseBasicAnimOptions(
      this.defaults.rotate.duration,
      this.defaults.rotate.ease,
      t.animate
    )
    if (!e.is3D()) {
      const i = e.camera
      const n = normalizeRotationAngle(
        'targetRotation',
        i.asLook2D().rotation,
        t.targetRotation
      )
      const r = normalizeRotationAngle('deltaRotation', 0, t.deltaRotation)
      return { center: o, targetRotation: a ? n : i.asLook2D().rotation + r }
    }
    let r, s
    const l = e.camera
    try {
      const t = createPoint(null, [e.viewSize[0] / 2, e.viewSize[1] / 2])
      const a = this._toMap(t)
      const i = distance3D(l.eye, a)
      const o = l.asLookAt(i)
      r = o.yaw
      s = o.pitch
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
      const e = l.asLookFrom()
      r = e.yaw
      s = e.pitch
    }
    const c = normalizeRotationAngle('targetYaw', r, t.targetYaw)
    const p = normalizeRotationAngle('targetPitch', s, t.targetPitch)
    const f = normalizeRotationAngle('deltaYaw', 0, t.deltaYaw)
    const u = normalizeRotationAngle('deltaPitch', 0, t.deltaPitch)
    return {
      center: o,
      currentYaw: r,
      currentPitch: s,
      targetYaw: a ? c : r + f,
      targetPitch: a ? p : s + u,
      animate: n,
    }
  }
  parseFitOptions(t, e) {
    if (!t) throw new Error(`MapNavigator.fit: invalid fitOptions: ${t}`)
    if (t.animate && !t.animate.ease) {
      if (true === t.animate) t.animate = {}
      t.animate.ease = quadInOut
    }
    const a = parseBasicAnimOptions(
      this.defaults.fit.duration,
      this.defaults.fit.ease,
      t.animate
    )
    const i = undefined
    const o = parseFitMargin(
      isDefined(t.fitMargin) ? t.fitMargin : this.defaults.fit.fitMargin
    )
    const n = t.bounds
    if (!n) throw new Error(`MapNavigator.fit: invalid bounds passed: ${n}`)
    if (!n.reference && null !== n.reference)
      throw new Error(
        `MapNavigator.fit: invalid bounds, must have a reference, but bounds.reference is: ${n.reference}`
      )
    if (!ShapeType.contains(n.type, ShapeType.BOUNDS))
      throw new Error(
        `MapNavigator.fit: invalid bounds, bounds.type must be ShapeType.Bounds, but is: ${n.type}`
      )
    let r = t.allowWarpXYAxis || false
    if (r && e.reference.referenceType !== ReferenceType.CARTESIAN) {
      Log.warn(
        'Cannot set allowWarpXYAxis to true if the map shows geographic data. AllowWarpXYAxis can only be used' +
          ' on maps with a cartesian reference.'
      )
      r = false
    }
    const s = undefined
    return {
      bounds: n,
      fitMargin: o,
      allowWarpXYAxis: r,
      snapToScaleLevels: isDefined(t.snapToScaleLevels)
        ? !!t.snapToScaleLevels
        : this.defaults.snapToScaleLevels,
      animate: a,
    }
  }
  get defaults() {
    return this._defaults
  }
  set defaults(t) {
    this._defaults.setToLiteral(t)
  }
  get map() {
    return this._map
  }
  set map(t) {
    this._map = t
  }
  isAnimating() {
    const t = undefined
    return !!AnimationManager.getAnimation(this._map.cameraAnimationKey)
  }
  _killCurrentAnimation() {
    AnimationManager.removeAnimation(this._map.cameraAnimationKey)
  }
  get animationPromise() {
    const t = AnimationManager.getAnimationPromise(this._map.cameraAnimationKey)
    if (t) return t
    return null
  }
  get enabledOperations() {
    return this._enabledOperations
  }
  set enabledOperations(t) {
    if (t === EnabledOperations.ALL || t === EnabledOperations.NONE) {
      this._enabledOperations = t
      if (t === EnabledOperations.NONE) this._killCurrentAnimation()
    } else
      throw new ProgrammingError(`Illegal value for 'enabledOperations':${t}`)
  }
  get constraints() {
    return this._constraint
  }
  set constraints(t) {
    this._constraint.setToLiteral(t)
  }
}
export const FIT_MARGIN_TYPE = { PERCENTAGE: '%', PIXEL: 'px' }
export const LEGACY_FIT_MARGIN = '2%'
export const LEGACY_FIT_DURATION = 4e3
function getViewCenter(t) {
  return t.mapToViewTransformationInternal.getViewCenter()
}
function parseBasicAnimOptions(t, e, a) {
  if (!a) return null
  const i = new AnimationOptions(t, e)
  if ('object' === typeof a) i.setToLiteral(a)
  return i
}
function normalizeRotationAngle(t, e, a) {
  if (isDefined(a)) {
    if (!isNumber(a) || isNaN(a))
      throw new Error(
        `Can not perform MapNavigator.rotate operation: invalid value for ${t}:${a}`
      )
    return a
  }
  return e
}
const pixelRegex = /^(\d+(\.\d*)?)px$/
const percentageRegex = /^(\d+(\.\d*)?)%$/
function parseFitMargin(t) {
  if (!isString(t))
    throw new Error(
      `invalid value for fitMargin. Expected a string, but got: ${t}`
    )
  if ('0' === t) return { value: 0, type: FIT_MARGIN_TYPE.PERCENTAGE }
  const e = t.match(percentageRegex)
  if (e && e.length > 1)
    return { value: +e[1], type: FIT_MARGIN_TYPE.PERCENTAGE }
  const a = t.match(pixelRegex)
  if (a && a.length > 1) return { value: +a[1], type: FIT_MARGIN_TYPE.PIXEL }
  throw new Error(`invalid fit margin: ${t}`)
}
