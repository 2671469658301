import { isNumber, isString } from '../../../util/Lang.js'
import { HTML5TextUtil } from '../../style/HTML5TextUtil.js'
import { normalizeTextStyle, StyleUtil } from '../../style/StyleUtil.js'
import { isWorldSize } from '../../../uom/UnitOfMeasureUtil.js'
function numberOfLines(t) {
  return ((t.match && t.match(/\r?\n/g)) || '').length + 1
}
export class TextIcon {
  constructor(t, e) {
    this.text = t
    this.style = e
  }
}
function isLeftAligned(t) {
  return 'left' === t || 'start' === t || 0 === StyleUtil.parsePercentage(t)
}
function isRightAligned(t) {
  return 'right' === t || 'end' === t || 1 === StyleUtil.parsePercentage(t)
}
function isBottomAligned(t) {
  return (
    'bottom' === t ||
    'ideographic' === t ||
    'alphabetic' === t ||
    1 === StyleUtil.parsePercentage(t)
  )
}
function isTopAligned(t) {
  return 'top' === t || 'hanging' === t || 0 === StyleUtil.parsePercentage(t)
}
export function convertTextStyleToIconStyle(t, e) {
  const n = normalizeTextStyle(e)
  const {
    fill: o,
    font: i,
    halo: r,
    haloWidth: s,
    stroke: l,
    strokeWidth: a,
    textAnchor: c,
    alignmentBaseline: g,
    drapeTarget: d,
    uom: f,
    height: u,
  } = n
  const p = {
    angle: 0,
    offsetX: 0,
    offsetY: 0,
    fill: o,
    font: i,
    halo: r,
    haloWidth: s,
    stroke: l,
    strokeWidth: a,
    textAnchor: c,
    alignmentBaseline: g,
    uom: f,
    height: u,
  }
  const { zOrder: h, offsetX: m, offsetY: S, angle: T, occlusionMode: x } = n
  const y = numberOfLines(t)
  const U = StyleUtil.parsePercentage(c)
  let L = isNumber(U) ? U : isLeftAligned(c) ? 0 : isRightAligned(c) ? 100 : 50
  const A = StyleUtil.parsePercentage(g)
  let M = isNumber(A)
    ? A
    : isTopAligned(g)
    ? 0
    : isBottomAligned(g)
    ? 100 / y
    : 50 / y
  const b = HTML5TextUtil.calculateBounds(t, n, true, false)
  const W = HTML5TextUtil.calculateBounds(t, n, false, false)
  const z = W[2] - W[0]
  const H = W[3] - W[1]
  const P = b[2] - b[0]
  const k = b[3] - b[1]
  const B = HTML5TextUtil.calculatePadding(p)
  const v = undefined
  const C = undefined
  L = ((B + (L / 100) * z) / P) * 100
  M = ((B + (M / 100) * H) / k) * 100
  const I = {
    zOrder: h,
    offsetX: m,
    offsetY: S,
    rotation: T,
    drapeTarget: d,
    anchorX: `${L}%`,
    anchorY: `${M}%`,
    image: new TextIcon(t, p),
    occlusionMode: x,
    uom: f,
  }
  if (isWorldSize(f)) {
    const t = P / k
    I.height = u * (k / H)
    I.width = I.height * t
  }
  return I
}
export function isConvertedFromTextStyle(t) {
  return t && t instanceof TextIcon
}
export function createCanvasFromConvertedTextStyle(t) {
  const e = t.text
  const n = normalizeTextStyle(t.style)
  return HTML5TextUtil.createCanvas(e, n)
}
export function createTextIconHashCode(t, e) {
  const { text: n, style: o } = t
  const {
    halo: i,
    fill: r,
    font: s,
    stroke: l,
    haloWidth: a,
    strokeWidth: c,
    height: g,
    uom: d,
  } = o
  e.appendString('' + n)
  a && e.appendString(isString(a) ? a : a.toString())
  c && e.appendString(isString(c) ? c : c.toString())
  r && e.appendString(r)
  s && e.appendString(s)
  i && e.appendString(i)
  l && e.appendString(l)
  g && e.appendDouble(g)
  d && e.appendString(d.name)
  return e
}
