import { XMLSchema } from '../../util/XMLSchema.js'
import { WFSCapabilitiesSchemaCommon } from './WFSCapabilitiesSchemaCommon.js'
const attribute = XMLSchema.attribute
const content = XMLSchema.content
const CodeType = { '#text': content(), codeSpace: attribute() }
const KeywordsType = [
  { Keyword: [{ '#text_only': content() }], Type: CodeType },
]
const Operations = { Operation: [{ '#text_only': content() }] }
const GeometryOperands = { GeometryOperand: [{ '#text_only': content() }] }
const OnlineResourceType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
}
const MetadataType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
  about: attribute(),
}
const RequestMethodType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
  Constraint: [
    {
      name: attribute(),
      Value: [{ '#text_only': content() }],
      Metadata: [MetadataType],
    },
  ],
}
const DomainType = {
  name: attribute(),
  Value: [{ '#text_only': content() }],
  Metadata: [MetadataType],
}
const OutputFormatListType = { Format: [{ '#text_only': content() }] }
const GMLObjectTypeListType = {
  GMLObjectType: [
    {
      Name: content(),
      Title: content(),
      Abstract: content(),
      Keywords: [KeywordsType],
      OutputFormats: OutputFormatListType,
    },
  ],
}
export const WFSCapabilitiesSchemaV110 = {
  WFS_Capabilities: {
    version: attribute(),
    updateSequence: attribute(),
    ServiceIdentification: {
      Title: content(),
      Abstract: content(),
      Keywords: KeywordsType,
      ServiceType: CodeType,
      ServiceTypeVersion: [{ '#text_only': content() }],
      Fees: content(),
      AccessConstraints: [{ '#text_only': content() }],
    },
    ServiceProvider: {
      ProviderName: content(),
      ProviderSite: OnlineResourceType,
      ServiceContact: {
        IndividualName: content(),
        PositionName: content(),
        ContactInfo: {
          Phone: {
            Voice: [{ '#text_only': content() }],
            Facsimile: [{ '#text_only': content() }],
          },
          Address: {
            DeliveryPoint: [{ '#text_only': content() }],
            City: content(),
            AdministrativeArea: content(),
            PostalCode: content(),
            Country: content(),
            ElectronicMailAddress: [{ '#text_only': content() }],
          },
          OnlineResource: OnlineResourceType,
          HoursOfService: content(),
          ContactInstructions: content(),
        },
        Role: CodeType,
      },
    },
    OperationsMetadata: {
      Operation: [
        {
          name: attribute(),
          DCP: [{ HTTP: { Get: RequestMethodType, Post: RequestMethodType } }],
          Parameter: [DomainType],
          Constraint: [DomainType],
          Metadata: [MetadataType],
        },
      ],
      Parameter: [DomainType],
      Constraint: [DomainType],
    },
    FeatureTypeList: {
      Operations: Operations,
      FeatureType: [
        {
          Namespace: WFSCapabilitiesSchemaCommon.FeatureTypeNamespaceSchema,
          Name: content(),
          Title: content(),
          Abstract: content(),
          Keywords: KeywordsType,
          DefaultSRS: content(),
          OtherSRS: [{ '#text_only': content() }],
          NoSRS: {},
          Operations: Operations,
          OutputFormats: OutputFormatListType,
          WGS84BoundingBox: [
            {
              LowerCorner: content(),
              UpperCorner: content(),
              crs: attribute(),
              dimensions: attribute(Number),
            },
          ],
          MetadataURL: [
            { type: attribute(), format: attribute(), '#text': content() },
          ],
        },
      ],
    },
    ServesGMLObjectTypeList: GMLObjectTypeListType,
    SupportsGMLObjectTypeList: GMLObjectTypeListType,
    Filter_Capabilities: {
      Spatial_Capabilities: {
        GeometryOperands: GeometryOperands,
        SpatialOperators: {
          SpatialOperator: [
            { GeometryOperands: GeometryOperands, name: attribute() },
          ],
        },
      },
      Scalar_Capabilities: {
        LogicalOperators: {},
        ComparisonOperators: {
          ComparisonOperator: [{ '#text_only': content() }],
        },
        ArithmeticOperators: {
          SimpleArithmetic: {},
          Functions: {
            FunctionNames: {
              FunctionName: [{ '#text': content(), nArgs: attribute(Number) }],
            },
          },
        },
      },
      Id_Capabilities: { EID: {}, FID: {} },
    },
  },
}
