import { GestureEventType } from '../../input/GestureEventType.js'
import { ThreeStepEditHandle } from './ThreeStepEditHandle.js'
export class ShapeTouchHandle extends ThreeStepEditHandle {
  constructor() {
    super()
  }
  shouldActivate(e, t) {
    return e.type === GestureEventType.DOWN && this.interacts(e, t)
  }
  shouldDeactivate(e) {
    return (
      e.type === GestureEventType.UP ||
      e.type === GestureEventType.DRAG_END ||
      e.type === GestureEventType.CONTEXT_MENU
    )
  }
  shouldProcess(e) {
    return e.type === GestureEventType.DRAG
  }
}
