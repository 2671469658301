import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Cassini } from './Cassini.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('CassiniSoldner')
const DEFAULT_LONGITUDE_LIMIT = 5
export class CassiniSoldner extends Cassini {
  constructor(t, s) {
    super(t, s)
    this._longitudeLimit = DEFAULT_LONGITUDE_LIMIT
    this._cachedValues = null
    this.calculateCachedValues()
  }
  setLongitudeLimit(t) {
    this._longitudeLimit = t
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    this.resetCachedValues()
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, i) {
    const n = this.getCachedValues(s)
    const e = normalizeLon(t.x - this.getCentralMeridian())
    if (Math.abs(e) > this._longitudeLimit) throw sharedOutOfBoundsError
    const o = Math.sin(t.y * Constants.DEG2RAD)
    const r = Math.cos(t.y * Constants.DEG2RAD)
    const a = e * Constants.DEG2RAD * r
    const h = a * a
    const c = h * a
    const u = c * a
    const d = u * a
    const l = o / r
    const L = l * l
    const g = (n.Es * r * r) / (1 - n.Es)
    const C = n.A / Math.sqrt(1 - n.Es * o * o)
    const E = undefined
    const m = C * (a - (L * c) / 6 - ((8 - L + 8 * g) * L * d) / 120)
    const M =
      s.meridionalArcDistanceOptimized(t.y, r, o) -
      n.M0 +
      C * l * (h / 2 + ((5 - L + 6 * g) * u) / 24)
    i.move2D(m, M)
  }
  isAllInBounds() {
    return false
  }
  getLongitudeLimit() {
    return this._longitudeLimit
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, i) {
    const n = this.getCachedValues(s)
    const e = n.inverseMeridionalArcDistance(t.y)
    const o = Math.sin(e)
    const r = Math.cos(e)
    const a = o / r
    const h = n.A / Math.sqrt(1 - n.Es * o * o)
    const c = a * a
    const u = t.x / h
    const d = u * u
    const l = d * u
    const L = l * u
    const g = L * u
    const C = 1 - n.Es * o * o
    const E = (n.A * (1 - n.Es)) / Math.sqrt(C * C * C)
    const m =
      Constants.RAD2DEG *
      (e - ((h * a) / E) * (0.5 * d - ((1 + 3 * c) * L) / 24))
    const M =
      Constants.RAD2DEG * ((u - (c * l) / 3 + ((1 + 3 * c) * c * g) / 15) / r)
    if (Math.abs(M) > this._longitudeLimit + 1e-6) throw sharedOutOfBoundsError
    const D = normalizeLon(this.getCentralMeridian() + M)
    i.move2D(D, m)
  }
  cartesianBoundsOnEllipsoidSFCT(t, s) {
    const i = this.getCachedValues(t)
    s.move2D(-i.East, -i.M0 - i.North)
    s.width = 2 * i.East
    s.height = 2 * i.North
  }
  inWorldBoundsOnEllipsoid(t, s) {
    const i = this.getCachedValues(s)
    const n = t.y + i.M0
    return n <= i.North && n >= -i.North && t.x <= i.East && t.x >= -i.East
  }
  inLonLatBounds(t) {
    return this.inLonBounds(t.x)
  }
  boundaryLons(t) {
    return [
      [
        normalizeLon(this.getCentralMeridian() - this._longitudeLimit),
        normalizeLon(this.getCentralMeridian() + this._longitudeLimit),
      ],
    ]
  }
  boundaryLats(t) {
    if (this.inLonBounds(t)) return super.boundaryLats(t)
    else return []
  }
  inLonBounds(t) {
    const s = normalizeLon(this.getCentralMeridian() - t)
    return Math.abs(s) <= this._longitudeLimit
  }
  toString() {
    return `CassiniSoldner_${this.getCentralMeridian().toFixed(
      5
    )}_${this.getOrigin().y.toFixed(5)}`
  }
  equals(t) {
    if (this === t) return true
    if (null == t) return false
    if (!Cassini.prototype.equals.call(this, t)) return false
    return t._longitudeLimit === this._longitudeLimit
  }
  getCachedValues(t) {
    let s = this._cachedValues
    if (null == s || !s.isValid(t)) {
      s = new CachedValues(this, t)
      this._cachedValues = s
    }
    return s
  }
  resetCachedValues() {
    this._cachedValues = null
  }
  encode() {
    return {
      type: 'CassiniSoldner',
      centralMeridian: this.getCentralMeridian(),
      originLat: this.getOriginLat(),
      longitudeLimit: this.getLongitudeLimit(),
    }
  }
  get TYPE() {
    return ProjectionType.CASSINI_SOLDNER + ProjectionType.TRANSVERSECYLINDRICAL
  }
  hashCode(t) {
    super.hashCode(t)
    t.appendUInt32(this.getLongitudeLimit())
  }
}
class CachedValues {
  constructor(t, s) {
    this.A = s.a
    this.E = s.e
    this.projection = t
    this.Es = s.e2
    this.M0 = s.meridionalArcDistanceOptimized(
      this.projection.getOriginLat(),
      Math.cos(this.projection.getOrigin().y * Constants.DEG2RAD),
      Math.sin(this.projection.getOrigin().y * Constants.DEG2RAD)
    )
    const i = this.Es * this.Es
    const n = i * this.Es
    this.Mu1Div = this.A * (1 - this.Es / 4 - (3 * i) / 64 - (5 * n) / 256)
    const e = Math.sqrt(1 - this.Es)
    const o = (1 - e) / (1 + e)
    const r = o * o
    const a = o * r
    const h = r * r
    this.T0 = (3 * o) / 2 - (27 * a) / 32
    this.T1 = (21 * r) / 16 - (55 * h) / 32
    this.T2 = (151 * a) / 96
    this.T3 = (1097 * h) / 512
    this.North = s.meridionalArcDistanceOptimized(90, 0, 1)
    this.East =
      this.A * (this.projection.getLongitudeLimit() * Constants.DEG2RAD)
  }
  isValid(t) {
    return this.A === t.a && this.E === t.e
  }
  inverseMeridionalArcDistance(t) {
    const s = undefined
    const i = (this.M0 + t) / this.Mu1Div
    return (
      i +
      this.T0 * Math.sin(2 * i) +
      this.T1 * Math.sin(4 * i) +
      this.T2 * Math.sin(6 * i) +
      this.T3 * Math.sin(8 * i)
    )
  }
}
