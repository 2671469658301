import { Pattern } from '../Pattern.js'
export class WrapperPattern extends Pattern {
  constructor(t, e) {
    super(e)
    this._pattern = t
  }
  get pattern() {
    return this._pattern
  }
  set lineJoin(t) {
    this._pattern.lineJoin = t
  }
  resolveState() {
    return this.pattern.resolveState()
  }
  getMinHeight() {
    return this.pattern.getMinHeight()
  }
  getMaxHeight() {
    return this.pattern.getMaxHeight()
  }
  getWidth(t) {
    return this.pattern.getWidth(t)
  }
  paint(t, e, n, r) {
    return this.pattern.paint(t, e, n, r)
  }
  paintOnceOnLine(t, e, n) {
    return this.pattern.paintOnceOnLine(t, e, n)
  }
  isRelativeLength() {
    return this.pattern.isRelativeLength()
  }
  appendHash(t) {
    this.pattern.appendHash(t)
  }
  setFallbackPattern(t) {
    super.setFallbackPattern(t)
    this.pattern.setFallbackPattern(t)
  }
}
