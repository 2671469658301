import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { isDefined } from '../../../util/Lang.js'
import {
  simplePointCreate,
  simplePointMove3DToCoordinates,
} from '../../../shape/SimplePoint.js'
const RHUMB_INTERPOLATION_THRESHOLD = 10
export class RhumbLinePath {
  constructor() {
    this._ellipsoid = null
    this._p1 = simplePointCreate(0, 0, 0)
    this._p2 = simplePointCreate(0, 0, 0)
    this._minimumRecursionDepth = 0
    this._distance = 0
    this._azimuth = 0
  }
  initializePath(i, t, s) {
    simplePointMove3DToCoordinates(this._p1, i.x, i.y, i.z)
    simplePointMove3DToCoordinates(this._p2, t.x, t.y, t.z)
    try {
      this._ellipsoid = s.geodeticDatum.ellipsoid
    } catch (i) {
      throw new ProgrammingError(
        'RhumbLinePath::may not initalize Rhumb line path with non-geodetic model reference'
      )
    }
    this._distance = this._ellipsoid.rhumblineDistance(this._p1, this._p2)
    this._azimuth = this._ellipsoid.rhumblineAzimuth2D(this._p1, this._p2)
    const e = Math.abs(this._p1.x - this._p2.x)
    const h = Math.abs((this._p1.y + this._p2.y) / 2)
    if (e > 1 && h < 5) this._minimumRecursionDepth = 2
    else this._minimumRecursionDepth = 0
  }
  toString() {
    return `${this._p1} and ${this._p2}`
  }
  getStartPosition() {
    return this._p1
  }
  getEndPosition() {
    return this._p2
  }
  getMinimumRecursionDepth() {
    return this._minimumRecursionDepth
  }
  getPointAtSFCT(i, t) {
    if (0 === i) {
      t.x = this._p1.x
      t.y = this._p1.y
      t.z = this._p1.z
    } else if (1 === i) {
      t.x = this._p2.x
      t.y = this._p2.y
      t.z = this._p2.z
    } else if (this._distance < RHUMB_INTERPOLATION_THRESHOLD) {
      t.x = this._p1.x + i * (this._p2.x - this._p1.x)
      t.y = this._p1.y + i * (this._p2.y - this._p1.y)
      if (isDefined(this._p1.z) && isDefined(this._p2.z))
        t.z = this._p1.z + i * (this._p2.z - this._p1.z)
    } else {
      if (null === this._ellipsoid)
        throw new ProgrammingError(
          'RhumbLinePath:: Not correctly initialized, missing ellipsoid.'
        )
      this._ellipsoid.rhumblinePositionSFCT(
        this._p1,
        this._distance * i,
        this._azimuth,
        t
      )
      if (isDefined(this._p1.z) && isDefined(this._p2.z))
        t.z = this._p1.z + i * (this._p2.z - this._p1.z)
    }
    return t
  }
}
