import { createBounds as createShapeBounds } from '../../../shape/ShapeFactory.js'
import {
  getReference,
  isValidReferenceIdentifier,
} from '../../../reference/ReferenceProvider.js'
import { Lang } from '../../../util/Lang.js'
import { createTransformation } from '../../../transformation/TransformationFactory.js'
import { shouldSwapAxes } from '../../../reference/AxisInformationUtil.js'
function freeze(e) {
  for (var r in e) if ('undefined' === typeof e[r]) e[r] = null
  return Object.freeze(e)
}
function parseCornerValues(e) {
  var r = [],
    n,
    t,
    i
  if (e)
    if (2 === (n = e.trim().split(/\s+/)).length) {
      t = parseFloat(n[0])
      i = parseFloat(n[1])
      if (!isNaN(t) && !isNaN(i)) r.push(t, i)
    }
  return r
}
function getBounds(e, r, n) {
  var t = findBounds(e, r)
  if (Lang.isDefined(t)) return createBounds(t, n)
  return _transformFirstKnownBounds(e, r, n)
}
function _transformFirstKnownBounds(e, r, n) {
  for (var t = 0; t < e.length; t++) {
    var i = e[t]
    if (
      !Lang.isDefined(i.reference) ||
      !isValidReferenceIdentifier(i.reference)
    )
      continue
    var o = getReference(i.reference)
    var s
    return createTransformation(o, r).transformBounds(createBounds(i, n))
  }
  return null
}
function createBounds(e, r) {
  var n = getReference(e.reference)
  var t
  if (r && shouldSwapAxes(n, [])) t = [e.y, e.height, e.x, e.width]
  else t = [e.x, e.width, e.y, e.height]
  return createShapeBounds(n, t)
}
function findBounds(e, r) {
  for (var n = 0; n < e.length; n++) {
    var t = e[n]
    if (
      !Lang.isDefined(t.reference) ||
      !isValidReferenceIdentifier(t.reference)
    )
      continue
    if (getReference(t.reference).equals(r)) return t
  }
}
function processServiceUrl(e) {
  return '?' === e[e.length - 1] ? e.substring(0, e.length - 1) : e
}
export var CapabilitiesParserUtil = {
  freeze: freeze,
  parseCornerValues: parseCornerValues,
  getBounds: getBounds,
  findBounds: findBounds,
  processServiceUrl: processServiceUrl,
}
