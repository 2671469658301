import { ShapeType } from '../../shape/ShapeType.js'
import { CGUtil } from './CGUtil.js'
import { CGOperatorConvertShape } from './CGOperatorConvertShape.js'
import { CGShape } from './CGShape.js'
import { CGSetOperation } from './CGSetOperation.js'
import { ConstructiveGeometryPointListPointList } from './ConstructiveGeometryPointListPointList.js'
import { ConstructiveGeometryOperator } from './ConstructiveGeometryOperator.js'
class ConstructiveGeometryShapeListShapeList extends ConstructiveGeometryOperator {
  constructor(e, t) {
    super(e)
    this._compositeOperator = t
    this._pointListPointList = new ConstructiveGeometryPointListPointList(e)
  }
  _uniteFilledShapes(e) {
    let t, p
    const o = this.topologyUtil.toleranceEqualPoints
    this.topologyUtil.toleranceEqualPoints = 1e-10
    const s = []
    const i = []
    for (t = 0; t < e.length; t++) {
      const p = undefined
      ;(ShapeType.contains(ShapeType.POLYGON, e[t].type) ||
      ShapeType.contains(ShapeType.COMPLEX_POLYGON, e[t].type)
        ? s
        : i
      ).push(e[t])
    }
    if (0 === s.length) return i
    let r
    if (0 === i.length) r = s
    else {
      const e = this._compositeOperator.operateOnMultipleShapes(
        CGSetOperation.UNION,
        s
      )
      r = this._shapeListToArray(e)
    }
    for (t = 0; t < i.length; ++t) {
      let e = null
      for (p = 0; p < r.length; ++p)
        if (
          ShapeType.contains(ShapeType.POLYGON, r[p].type) ||
          ShapeType.contains(ShapeType.COMPLEX_POLYGON, r[p].type)
        ) {
          e = r.splice(p, 1)[0]
          break
        }
      if (null === e) return r
      const o = this._pointListPointList.operate(e, i[t], CGSetOperation.UNION)
      const s = this._shapeListToArray(o)
      for (p = 0; p < s.length; p++) r.push(s[p])
    }
    this.topologyUtil.toleranceEqualPoints = o
    return r
  }
  operate(e, t, p) {
    let o
    const s = new CGShape()
    const i = new CGShape()
    let r = this._shapeListToArray(e)
    let n = this._shapeListToArray(t)
    r = this._uniteFilledShapes(r)
    n = this._uniteFilledShapes(n)
    for (o = 0; o < r.length; o++)
      CGOperatorConvertShape.shapeToCgShape(r[o], 0, this.topologyUtil, s)
    for (o = 0; o < n.length; o++)
      CGOperatorConvertShape.shapeToCgShape(n[o], 1, this.topologyUtil, i)
    CGUtil.prepareShapes(this.intersector, this.topologyUtil, s, i)
    return this._pointListPointList.operateOnCGShapes(s, i, p)
  }
  _shapeListToArray(e) {
    const t = []
    if (ShapeType.contains(ShapeType.SHAPE_LIST, e.type)) {
      const p = []
      p.push(e)
      while (p.length > 0) {
        const e = p.splice(p.length - 1, 1)[0]
        for (let o = 0; o < e.shapeCount; o++)
          if (ShapeType.contains(ShapeType.SHAPE_LIST, e.getShape(o).type))
            p.push(e.getShape(o))
          else t.push(e.getShape(o))
      }
    } else t.push(e)
    return t
  }
  supportsShapes(e) {
    for (let t = 0; t < e.length; t++) {
      const p = this._shapeListToArray(e[t])
      for (let e = 0; e < p.length; e++)
        if (!this._supportsSubShape(p[e])) return false
    }
    return true
  }
  _supportsSubShape(e) {
    return (
      ShapeType.contains(ShapeType.POINT, e.type) ||
      ShapeType.contains(ShapeType.POLYLINE, e.type) ||
      ShapeType.contains(ShapeType.POLYGON, e.type) ||
      ShapeType.contains(ShapeType.COMPLEX_POLYGON, e.type)
    )
  }
}
export { ConstructiveGeometryShapeListShapeList }
