import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { Hash } from '../../../util/Hash.js'
import { Log } from '../../../util/Log.js'
import {
  createCanvasFromConvertedTextStyle,
  isConvertedFromTextStyle,
} from './TextToIconSupport.js'
import { PhotonWeakMap } from './PhotonWeakMap.js'
import {
  createDefaultPointStyle,
  createDensityPointStyle,
} from '../DefaultStyles.js'
import { isPromise, whenInternal } from '../../../util/PromiseUtil.js'
import { appendImageHashCode } from '../../style/complexstroke/pattern/util/HashUtil.js'
export class PhotonImageProvider {
  _imageCache = new PhotonWeakMap()
  _promiseCache = new Map()
  _hash = new Hash()
  constructor(e, t) {
    this._imageDecoder = e
    this._getFallbackIconUrl = t
  }
  release() {
    this._imageCache.release()
    this._imageCache = null
  }
  _isReleased() {
    return null === this._imageCache
  }
  getPhotonImage(e, t, a, r, o, i, s, n, l, m) {
    if (!t)
      throw new ProgrammingError(
        'Can only get images with alpha pre-multiplied'
      )
    const h = this
    this._hash.reset()
    const c = this.imageHashCode(
      e,
      s ?? 0,
      n ?? 0,
      a ?? false,
      this._hash
    ).getHashCode()
    if (!e)
      e = a ? createDensityPointStyle().image : createDefaultPointStyle().image
    const g = this._imageCache.get(c)
    if (g) return g
    const d = this._promiseCache.get(c)
    if (d)
      return d.then((l) => {
        if (h._isReleased()) return new Promise((e, t) => {})
        return h.getPhotonImage(e, t, a, r, o, i, s, n)
      })
    let p
    if (isConvertedFromTextStyle(e))
      p = this._imageDecoder.decodeCanvas(
        createCanvasFromConvertedTextStyle(e),
        true,
        0,
        0,
        l,
        m
      )
    else if (e instanceof HTMLCanvasElement)
      p = this._imageDecoder.decodeCanvas(e, true, s, n, l, m)
    else if (e instanceof HTMLImageElement)
      p = this._imageDecoder.decodeImage(e, true, s, n, l, m)
    else p = this._imageDecoder.decodeUrl(e, true, r, o, void 0, s, n, l, m)
    function f(e) {
      h._promiseCache.delete(c)
      if (h._isReleased()) {
        e.release()
        return new Promise((e, t) => {})
      } else {
        h._imageCache.set(c, e)
        return e
      }
    }
    const u = (a) => {
      this._promiseCache.delete(c)
      if (i) {
        Log.warn(
          'Could not load image' + (a && a.message ? ': ' + a.message : '.')
        )
        throw new ProgrammingError('Image request failed: ' + a)
      }
      if (e !== this._getFallbackIconUrl()) {
        if (this._isReleased()) throw a
        return whenInternal(
          this.getPhotonImage(
            this._getFallbackIconUrl(),
            t,
            false,
            false,
            false,
            i
          ),
          f
        )
      } else {
        Log.warn(
          'Could not load fallback icon ' +
            this._getFallbackIconUrl() +
            ': ' +
            a
        )
        return this._imageDecoder.decodeCanvas(
          createDefaultPointStyle().image,
          true
        )
      }
    }
    if (isPromise(p)) {
      p = p.then(f).catch(u)
      this._promiseCache.set(c, p)
    } else this._imageCache.set(c, p)
    return p
  }
  imageHashCode(e, t, a, r, o) {
    appendImageHashCode(e, o)
    o.append(t)
    o.append(a)
    o.appendBoolean(r)
    return o
  }
  clean() {
    this._imageCache.purge()
  }
}
