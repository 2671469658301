import { ProgrammingError } from '../error/ProgrammingError.js'
import { buildCache } from '../util/Cacher.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
import { normalizeAngle } from '../util/Cartesian.js'
class Arc extends Shape {
  constructor(t, e, i, r, s, h, n) {
    super()
    this._reference = this.validateReference(t)
    this._center = e.copy()
    this._a = i
    this._b = r
    this._rotationAzimuth = normalizeAngle(s)
    this._startAzimuth = normalizeAngle(h)
    this._sweepAngle = n
    this._cache = buildCache()
  }
  get type() {
    return ShapeType.ARC
  }
  get a() {
    return this._a
  }
  set a(t) {
    if (t < 0)
      throw new ProgrammingError(
        'a length should be positive number, but was: ' + this._a
      )
    this._a = t
    this._cache.invalidate()
  }
  get b() {
    return this._b
  }
  set b(t) {
    if (t < 0)
      throw new ProgrammingError(
        'b length should be positive number, but was: ' + this._b
      )
    this._b = t
    this._cache.invalidate()
  }
  get bounds() {
    return this._cache.memoize('cached-bounding-box', () =>
      this.createBounds(this)
    )
  }
  get center() {
    return this._center
  }
  get rotationAzimuth() {
    return this._rotationAzimuth
  }
  set rotationAzimuth(t) {
    this._rotationAzimuth = normalizeAngle(t)
    this._cache.invalidate()
  }
  get startAzimuth() {
    return this._startAzimuth
  }
  set startAzimuth(t) {
    this._startAzimuth = t
    this._cache.invalidate()
  }
  get sweepAngle() {
    return this._sweepAngle
  }
  set sweepAngle(t) {
    this._sweepAngle = t
    this._cache.invalidate()
  }
  get startPoint() {
    return this._cache.memoize('start-point', () =>
      this.computeArcPoint(this._startAzimuth)
    )
  }
  get endPoint() {
    return this._cache.memoize('end-point', () =>
      this.computeArcPoint(this._startAzimuth + this._sweepAngle)
    )
  }
  get focusPoint() {
    return this._center
  }
  invalidate() {
    this._cache.invalidate()
  }
  equals(t) {
    if (!ShapeUtil.referencesEqual(this.reference, t.reference)) return false
    if (!(t instanceof Arc)) return false
    if (this === t) return true
    return (
      this.type === t.type &&
      this.a === t.a &&
      this.b === t.b &&
      this.rotationAzimuth === t.rotationAzimuth &&
      this.startAzimuth === t.startAzimuth &&
      this.sweepAngle === t.sweepAngle &&
      this._center.equals(t._center)
    )
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    this._center.hashCode(t)
    t.appendDouble(this.a)
    t.appendDouble(this.b)
    t.appendDouble(this.rotationAzimuth)
    t.appendDouble(this.startAzimuth)
    t.appendDouble(this.sweepAngle)
  }
  toString() {
    return `Arc[center${this._center.toString(true)}, a[${this._a}], b[${
      this.b
    }], rotationAzimuth[${this.rotationAzimuth}], startAzimuth[${
      this.startAzimuth
    }], sweepAngle[${this.sweepAngle}]]`
  }
  _normalizeAngle360(t) {
    while (t < 0) t += 360
    while (t > 360) t -= 360
    return t
  }
  move2DToCoordinates(t, e) {
    const i = t - this.focusPoint.x
    const r = e - this.focusPoint.y
    this.translate2D(i, r)
  }
  move2DToPoint(t) {
    this._compareReference(t.reference, t.coordinateType)
    this.move2DToCoordinates(t.x, t.y)
  }
  translate2D(t, e) {
    this._center.translate2D(t, e)
    this._cache.invalidate()
  }
  updateForStartPoint(t) {
    let e = this._forwardAzimuth(this._center, t)
    let i = this._forwardAzimuth(this._center, this.endPoint)
    e = this._normalizeAngle360(e)
    i = this._normalizeAngle360(i)
    let r = i - e
    while (r <= 0) r += 360
    this.startAzimuth = e
    this.sweepAngle = r
  }
  updateForEndPoint(t) {
    const e = this._normalizeAngle360(this.startAzimuth)
    const i = this._forwardAzimuth(this._center, t)
    const r = undefined
    let s = this._normalizeAngle360(i) - e
    while (s <= 0) s += 360
    this.sweepAngle = s
  }
}
export { Arc }
export function isArc(t) {
  return ShapeType.contains(ShapeType.ARC, t.type)
}
