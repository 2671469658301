import { NotImplementedError } from '../../error/NotImplementedError.js'
import { linear } from '../../util/Easing.js'
import { isDefined, isFunction, isNumber } from '../../util/Lang.js'
class Animation {
  constructor(e, t) {
    this._startTime = 0
    this._duration = isDefined(e) ? e : 1e3
    this._ease = t || linear
    this._hasFinished = false
    this._elapsedTime = 0
  }
  get startTime() {
    return this._startTime
  }
  set startTime(e) {
    this._startTime = e
  }
  get hasFinished() {
    return this._hasFinished
  }
  set hasFinished(e) {
    this._hasFinished = e
  }
  get duration() {
    return this._duration
  }
  set duration(e) {
    if (!isNumber(e) && e <= 0)
      throw Error(
        `Cannot set animation duration. Should be strictly positive, but is: ${e}`
      )
    this._duration = e
  }
  get ease() {
    return this._ease
  }
  set ease(e) {
    if (e && !isFunction(e))
      throw new Error(`Expected function to set Animation.ease, but got: ${e}`)
    this._ease = e
  }
  get elapsedTime() {
    return this._elapsedTime
  }
  set elapsedTime(e) {
    this._elapsedTime = e
  }
  onStart() {}
  onStop() {}
  update(e) {
    throw new NotImplementedError('Animation#update needs to be implemented')
  }
}
export { Animation }
