import { createBounds } from '../../../shape/ShapeFactory.js'
function PhotonSpatialQueryProcess(e, t, s, i, o) {
  this._layer = e
  this._layerVisible = t
  this._map = s
  this._workingSet = i
  this._painterProcess = o
  this._spatialUpdateScheduled = false
  this._mapBounds = createBounds(e.model.reference)
  var r = this
  this._workingSet.forEachVisibleFeature(function (e) {
    r._processWorkingSetChangedEvent('add', e)
  })
  this._workingSetListener = this._workingSet.on(
    'WorkingSetChanged',
    this._processWorkingSetChangedEvent.bind(this)
  )
}
PhotonSpatialQueryProcess.prototype = Object.create(Object.prototype)
PhotonSpatialQueryProcess.prototype.constructor = PhotonSpatialQueryProcess
function canProceed(e, t) {
  return e && t
}
PhotonSpatialQueryProcess.prototype.isReady = function () {
  return !this._spatialUpdateScheduled
}
PhotonSpatialQueryProcess.prototype.release = function () {
  if (this._workingSetListener) {
    this._workingSetListener.remove()
    this._workingSetListener = null
  }
  this._painterProcess.clear()
}
PhotonSpatialQueryProcess.prototype.onMapIdle = function () {
  this._scheduleSpatialUpdate()
}
PhotonSpatialQueryProcess.prototype.onLayerVisibilityChanged = function (e) {
  this._layerVisible = e
  this._scheduleSpatialUpdate()
}
PhotonSpatialQueryProcess.prototype._getMapBounds = function () {
  try {
    this._map.getMapBoundsSFCT(this._mapBounds)
  } catch (e) {
    return
  }
  return this._mapBounds
}
PhotonSpatialQueryProcess.prototype._processWorkingSetChangedEvent = function (
  e,
  t
) {
  var s = this._getMapBounds()
  if (!canProceed(s, this._layerVisible)) return
  if ('update' === e || 'add' === e) {
    var i = this._layer.fetchProvidedShape(t)
    if (i && s.interacts2D(i.bounds)) this._painterProcess[e](t)
    else this._painterProcess.remove(t)
  } else if ('remove' === e) this._painterProcess.remove(t)
  else if ('clear' === e) this._painterProcess.clear()
}
PhotonSpatialQueryProcess.prototype._scheduleSpatialUpdate = function () {
  if (!this._spatialUpdateScheduled) {
    this._spatialUpdateScheduled = true
    setTimeout(this._spatialUpdate.bind(this), 1)
  }
}
PhotonSpatialQueryProcess.prototype._spatialUpdate = function () {
  var e = this._getMapBounds()
  if (!canProceed(e, this._layerVisible)) {
    this._spatialUpdateScheduled = false
    return
  }
  var t = []
  this._workingSet.search(e, function (e) {
    t.push(e.feature)
  })
  this._painterProcess.reset(t)
  this._spatialUpdateScheduled = false
}
export { PhotonSpatialQueryProcess }
