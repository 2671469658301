import { findCenter } from '../geodesy/EllipsoidCircleUtil.js'
import { buildCache } from '../util/Cacher.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { CircularArcBy3Points } from './CircularArcBy3Points.js'
import { LLHBounds } from './LLHBounds.js'
import { LLHPoint } from './LLHPoint.js'
import * as LLHCircularArcUtil from './LLHCircularArcUtil.js'
import { CoordinateType } from '../reference/CoordinateType.js'
export class LLHCircularArcBy3Points extends CircularArcBy3Points {
  constructor(t, e, i, r) {
    super()
    this._reference = this.validateReference(t)
    this._compareReferenceStrict(
      e.reference,
      'LLHCircularArcBy3Points::the arc points reference must match the arc reference'
    )
    this._compareReferenceStrict(
      i.reference,
      'LLHCircularArcBy3Points::the arc points reference must match the arc reference'
    )
    this._compareReferenceStrict(
      r.reference,
      'LLHCircularArcBy3Points::the arc points reference must match the arc reference'
    )
    this._startPoint = e.copy()
    this._intermediatePoint = i.copy()
    this._endPoint = r.copy()
    this._cache = buildCache()
  }
  get isGeodetic() {
    return true
  }
  get startPoint() {
    return this._startPoint
  }
  get intermediatePoint() {
    return this._intermediatePoint
  }
  get endPoint() {
    return this._endPoint
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  get focusPoint() {
    return this._cache.memoize(
      'cached-focus-point',
      () =>
        new LLHPoint(this._reference, [
          (this.startPoint.x + this.endPoint.x) / 2,
          (this.startPoint.y + this.endPoint.y) / 2,
          (this.startPoint.z + this.endPoint.z) / 2,
        ])
    )
  }
  get center() {
    const t = this._cache
    return this._cache.memoize('cached-center-point', () => {
      const e = new LLHPoint(this.reference)
      const i = findCenter(
        this.startPoint,
        this.intermediatePoint,
        this.endPoint,
        this.reference.geodeticDatum.ellipsoid,
        e,
        null
      )
      t.cache('cached-radius', i)
      return e
    })
  }
  get radius() {
    const t = this._cache
    return this._cache.memoize('cached-radius', () => {
      const e = new LLHPoint(this.reference)
      const i = findCenter(
        this.startPoint,
        this.intermediatePoint,
        this.endPoint,
        this.reference.geodeticDatum.ellipsoid,
        e,
        null
      )
      t.cache('cached-center-point', e)
      return i
    })
  }
  get startAzimuth() {
    return this._cache.memoize('cached-start-angle', () =>
      LLHCircularArcUtil.getStartAngle3Points(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.startPoint
      )
    )
  }
  get sweepAngle() {
    return this._cache.memoize('cached-sweep-angle', () =>
      LLHCircularArcUtil.getArcAngle3Points(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.startPoint,
        this.intermediatePoint,
        this.endPoint
      )
    )
  }
  get bounds() {
    return this._cache.memoize('bounds', () => {
      const t = new LLHBounds(this.reference)
      LLHCircularArcUtil.boundsSFCT(this, t)
      return t
    })
  }
  contains2DCoordinates(t, e) {
    return LLHCircularArcUtil.contains2D(this, t, e)
  }
  moveStartPoint2DToCoordinates(t, e) {
    this._startPoint.move2DToCoordinates(t, e)
    this.invalidate()
  }
  moveIntermediatePoint2DToCoordinates(t, e) {
    this._intermediatePoint.move2DToCoordinates(t, e)
    this.invalidate()
  }
  moveEndPoint2DToCoordinates(t, e) {
    this._endPoint.move2DToCoordinates(t, e)
    this.invalidate()
  }
  translateStartPoint2D(t, e) {
    this.moveStartPoint2DToCoordinates(
      this._startPoint.x + t,
      this._startPoint.y + e
    )
  }
  translateIntermediatePoint2D(t, e) {
    this.moveIntermediatePoint2DToCoordinates(
      this._intermediatePoint.x + t,
      this._intermediatePoint.y + e
    )
  }
  translateEndPoint2D(t, e) {
    this.moveEndPoint2DToCoordinates(this._endPoint.x + t, this._endPoint.y + e)
  }
  invalidate() {
    this._cache.invalidate()
  }
  copy() {
    return new LLHCircularArcBy3Points(
      this._reference,
      this._startPoint.copy(),
      this._intermediatePoint.copy(),
      this._endPoint.copy()
    )
  }
  contains3DCoordinates(t, e, i) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape LLHCircularArcBy3Points'
    )
  }
}
