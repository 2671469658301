import { createPoint } from '../shape/ShapeFactory.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { GoogleLayer } from './google/GoogleLayer.js'
import { isUndefined } from '../util/Lang.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { clamp } from '../util/Cartesian.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { Log } from '../util/Log.js'
function applyLayerConstraints(t, e) {
  e.correctWorldViewTransformation2D(t, t)
  return t
}
function snapToAnyLayer(t, e) {
  if (t.snapToLayer_stop) return t
  e.snapScale(t.scalex, t.scaley, t.rounding, t)
  if (e instanceof GoogleLayer) t.snapToLayer_stop = true
  return t
}
function snapToGoogleLayer(t, e) {
  if (t.snapToLayer_stop) return t
  if (e instanceof GoogleLayer) {
    if (isUndefined(t.rounding))
      throw new ProgrammingError('Missing round function.')
    e.snapScale(t.scalex, t.scaley, t.rounding, t)
    t.snapToLayer_stop = true
  }
  return t
}
export class MapNavigatorConstraints2D {
  constructor(t) {
    this._map = t
  }
  violatesRotation() {
    return this.violatesRotationImpl()
  }
  violatesLeftEdge() {
    if (this._map.is3D()) return false
    return this.violatesLeftEdgeImpl(this._map.mapToViewTransformationInternal)
  }
  violatesRightEdge() {
    if (this._map.is3D()) return false
    return this.violatesRightEdgeImpl(this._map.mapToViewTransformationInternal)
  }
  violatesTopEdge() {
    if (this._map.is3D()) return false
    return this.violatesTopEdgeImpl(this._map.mapToViewTransformationInternal)
  }
  violatesBottomEdge() {
    if (this._map.is3D()) return false
    return this.violatesBottomEdgeImpl(
      this._map.mapToViewTransformationInternal
    )
  }
  violatesRotationImpl() {
    if (this._map.is3D()) return false
    if (this._map.reference.coordinateType === CoordinateType.CARTESIAN)
      if (this._map.axisRenderer) {
        Log.warn('MapNavigatorConstraints2D: Cannot rotate map with axes')
        return true
      } else if (!this._map.reference.hasUniformAxes2D()) {
        Log.log(
          'MapNavigatorConstraints2D: Cannot combine rotation with non-uniform scaling'
        )
        return true
      }
    return false
  }
  violatesLeftEdgeImpl(t) {
    if (!this._map.constraintBounds) return false
    return (
      Math.round(t.forwardX(this._map.constraintBounds.x, 0)) -
        this._map.constraintPadding.left >=
      Math.round(this._map.viewBounds.x)
    )
  }
  violatesRightEdgeImpl(t) {
    if (!this._map.constraintBounds) return false
    const e = this._map.constraintBounds.x + this._map.constraintBounds.width
    return (
      Math.round(t.forwardX(e, 0)) + this._map.constraintPadding.right <=
      Math.round(this._map.viewBounds.x + this._map.viewBounds.width) +
        this._map.border.left
    )
  }
  violatesBottomEdgeImpl(t) {
    if (!this._map.constraintBounds) return false
    return (
      Math.round(t.forwardY(0, this._map.constraintBounds.y)) +
        this._map.constraintPadding.bottom <=
      Math.round(this._map.viewBounds.y + this._map.viewBounds.height)
    )
  }
  violatesTopEdgeImpl(t) {
    if (!this._map.constraintBounds) return false
    const e = this._map.constraintBounds.y + this._map.constraintBounds.height
    return (
      Math.round(t.forwardY(0, e)) - this._map.constraintPadding.top >=
      Math.round(this._map.viewBounds.y) - this._map.border.bottom
    )
  }
  getSnappedScale(t, e, o, i, n) {
    if (this._map.is3D()) {
      n.scalex = t
      n.scaley = e
      return
    }
    n.rounding = o
    n.scalex = t
    n.scaley = e
    n.snapToLayer_stop = false
    this._map.layerTree._reduceLeaves(i ? snapToGoogleLayer : snapToAnyLayer, n)
  }
  hasBoundsConstraint() {
    if (this._map.is3D()) return false
    return null !== this._map.getBoundsNavigationRestriction()
  }
  correctWorldViewTransformation2D(t, e) {
    if (this._map.getViewWidth() < 1 || this._map.getViewHeight() < 1) {
      e.setToWVT(this._map.mapToViewTransformationInternal)
      return
    }
    e.setToWVT(t)
    if (this._map.axisRenderer) correctWVTToZeroRotation(e.copy(), e)
    this.correctWVTToLayerConstraints(e.copy(), e)
    this.correctWVTToMinMaxScaleConstraint(e.copy(), e)
    this.correctWVTToBoundsConstraint(e.copy(), e)
  }
  correctWVTToLayerConstraints(t, e) {
    e.setToWVT(t)
    this._map.layerTree._reduceLeaves(applyLayerConstraints, e)
  }
  correctWVTToBoundsConstraint(t, e) {
    e.setToWVT(t)
    if (!this.hasBoundsConstraint()) return
    correctWVTToZeroRotation(e.copy(), e)
    if (
      !(
        this.violatesBottomEdgeImpl(t) ||
        this.violatesTopEdgeImpl(t) ||
        this.violatesLeftEdgeImpl(t) ||
        this.violatesRightEdgeImpl(t)
      )
    )
      return
    const o = e.inverseTransformation.transformBounds(this._map.viewBounds)
    const i = e._inverseXDistance(this._map.constraintPadding.left)
    const n = e._inverseXDistance(this._map.constraintPadding.right)
    const a = -e._inverseYDistance(this._map.constraintPadding.bottom)
    const r = -e._inverseYDistance(this._map.constraintPadding.top)
    const s = this._map.constraintBounds
    const p = Math.min(s.width + (i + n), o.width)
    const h = Math.min(s.height + (a + r), o.height)
    let m = o.width / Math.max(p, 1)
    let c = o.height / Math.max(h, 1)
    if (
      this._map.reference.referenceType === ReferenceType.GRID ||
      this._map.reference.referenceType === ReferenceType.GEODETIC
    ) {
      m = Math.max(m, c)
      c = Math.max(m, c)
    }
    o.scaleAroundCenter(m, c)
    const d =
      Math.min(0, o.x - (s.x - i)) +
      Math.max(0, o.x + o.width - (s.x + s.width + n))
    const l =
      Math.min(0, o.y - (s.y - a)) +
      Math.max(0, o.y + o.height - (s.y + s.height + r))
    o.x -= d
    o.y -= l
    const g = e.getViewCenter()
    const u = createPoint(null, [g.x + this._map.border.left, g.y])
    e.setTo(
      o.focusPoint,
      u,
      this._map.viewBounds.width / o.width,
      this._map.viewBounds.height / o.height,
      e.getRotation()
    )
    const f = t.getViewOrigin()
    const T = e.toWorld(f)
    e.setTo(T, f, e.getScaleX(), e.getScaleY(), e.getRotation())
  }
  correctWVTToMinMaxScaleConstraint(t, e) {
    const o = this._map.getAdjustedMinScale()
    const i = this._map.getAdjustedMaxScale()
    const n = clamp(t.getScaleX(), o[0], i[0])
    const a = clamp(t.getScaleY(), o[1], i[1])
    e.setTo(t.getWorldOrigin(), t.getViewOrigin(), n, a, t.getRotation())
  }
  static create(t) {
    return new MapNavigatorConstraints2D(t)
  }
}
function correctWVTToZeroRotation(t, e) {
  e.setTo(
    t.getWorldOrigin(),
    t.getViewOrigin(),
    t.getScaleX(),
    t.getScaleY(),
    0
  )
}
