import { WFSCapabilitiesParser } from './WFSCapabilitiesParser.js'
import { XMLSchema } from '../../util/XMLSchema.js'
import { WFSCapabilitiesSchemaV200 } from './WFSCapabilitiesSchemaV200.js'
import { WFSCapabilitiesFeatureType } from './WFSCapabilitiesFeatureType.js'
import { isDefined } from '../../util/Lang.js'
export class WFSCapabilitiesParserV200 extends WFSCapabilitiesParser {
  constructor(e) {
    const t = undefined
    super(new XMLSchema(WFSCapabilitiesSchemaV200).parse(e))
  }
  getFeatureType(e, t) {
    if (!isDefined(e)) return null
    const i = e.DefaultCRS
    const a = e.OutputFormats && e.OutputFormats.Format
    return new WFSCapabilitiesFeatureType({
      title: e.Title[0],
      name: e.Name,
      qName: this.getQualifiedFeatureTypeName(e),
      defaultReference: i,
      otherReferences: e.OtherCRS || [],
      keywords: this.getKeywords(e),
      outputFormats: a || t,
      wgs84Bounds: this.getWGS84Bounds(e.WGS84BoundingBox),
    })
  }
  getParametersValues(e, t) {
    if (!isDefined(e)) return null
    const i = e.Parameter.filter((e) => e.name === t)[0]
    if (!isDefined(i)) return null
    return i.AllowedValues.Value
  }
}
