import { ReferenceType } from '../reference/ReferenceType.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Transformation } from './Transformation.js'
import { TransformationType } from './TransformationType.js'
import { BoundsCalculationUtil } from './BoundsCalculationUtil.js'
export class GeodeticGridTransformation extends Transformation {
  constructor(t, e, i) {
    super(t, e)
    if (null == t || t.referenceType !== ReferenceType.GEODETIC)
      throw new ProgrammingError(
        'GeodeticGridTransformation::setSourceReference - expected geodetic reference'
      )
    if (null == e || e.referenceType !== ReferenceType.GRID)
      throw new ProgrammingError(
        'GeodeticGridTransformation::setDestinationReference - expected grid reference'
      )
    this._srcGeodeticDatum = t.geodeticDatum
    this._dstGeodeticDatum = e.geodeticDatum
    this._equalDatums = this._srcGeodeticDatum.equals(this._dstGeodeticDatum)
    this._equalHorizontalDatums = this._srcGeodeticDatum.equals2D(
      this._dstGeodeticDatum
    )
    this._dstProjection = e.projection
    this._dstEllipsoid = this._dstGeodeticDatum.ellipsoid
    this._UOM = e.unitOfMeasure
    if (
      null === this._srcGeodeticDatum ||
      null === this._dstProjection ||
      null === this._dstEllipsoid ||
      null === this.outputReference
    )
      throw new ProgrammingError('Transformation not properly initialized')
    this._tmpLLHPoint = createPoint(t, [0, 0, 0])
    this._tmpXYZPoint1 = createPoint(e, [0, 0, 0])
    this._tmpXYZPoint2 = createPoint(e, [0, 0, 0])
    this._tmpXYPoint = createPoint(e, [0, 0])
    this._bboxCalculationUtil = new BoundsCalculationUtil({
      modelReference: t,
      worldReference: e,
      transformation: this,
      TransformationFactory: i,
    })
  }
  get inputReference() {
    return null === super.inputReference ? null : super.inputReference
  }
  get outputReference() {
    return null === super.outputReference ? null : super.outputReference
  }
  get uom() {
    return this._UOM
  }
  set uom(t) {
    this._UOM = t
  }
  _forward(t, e) {
    let i
    let o
    let s
    if (this._equalDatums) {
      i = t.x
      o = t.y
      s = t.z || 0
      this._tmpLLHPoint.x = i
      this._tmpLLHPoint.y = o
      this._tmpLLHPoint.z = s + this._srcGeodeticDatum.getHeight(i, o)
      e.z = s / this._UOM
    } else if (this._equalHorizontalDatums) {
      i = t.x
      o = t.y
      s = t.z || 0
      this._tmpLLHPoint.x = i
      this._tmpLLHPoint.y = o
      this._tmpLLHPoint.z = s + this._srcGeodeticDatum.getHeight(i, o)
      e.z =
        (this._tmpLLHPoint.z - this._dstGeodeticDatum.getHeight(i, o)) /
        this._UOM
    } else {
      this._srcGeodeticDatum.geod2geocSFCT(t, this._tmpXYZPoint1)
      this._srcGeodeticDatum.datum2refGeocSFCT(
        this._tmpXYZPoint1,
        this._tmpXYZPoint2
      )
      this._dstGeodeticDatum.ref2datumGeocSFCT(
        this._tmpXYZPoint2,
        this._tmpXYZPoint1
      )
      this._dstEllipsoid.geoc2geodSFCT(this._tmpXYZPoint1, this._tmpLLHPoint)
      const i = this._tmpLLHPoint
      e.z = (i.z - this._dstGeodeticDatum.getHeight(i.x, i.y)) / this._UOM
    }
    this._dstProjection.geodetic2cartesianOnEllipsoidSFCT(
      this._tmpLLHPoint,
      this._dstEllipsoid,
      this._tmpXYZPoint1
    )
    this._outputReference.projected2gridSFCT(this._tmpXYZPoint1, e)
    return e
  }
  _inverse(t, e) {
    let i
    let o
    const s = t.z || 0
    this._outputReference.grid2projectedSFCT(t, this._tmpXYPoint)
    e.z = this._UOM * s
    this._dstProjection.cartesian2geodeticOnEllipsoidSFCT(
      this._tmpXYPoint,
      this._dstEllipsoid,
      e
    )
    if (!this._equalDatums)
      if (this._equalHorizontalDatums) {
        i = e.x
        o = e.y
        e.z +=
          this._dstGeodeticDatum.getHeight(i, o) -
          this._srcGeodeticDatum.getHeight(i, o)
      } else {
        this._dstGeodeticDatum.geod2geocSFCT(e, this._tmpXYZPoint1)
        this._dstGeodeticDatum.datum2refGeocSFCT(
          this._tmpXYZPoint1,
          this._tmpXYZPoint2
        )
        this._srcGeodeticDatum.ref2datumGeocSFCT(
          this._tmpXYZPoint2,
          this._tmpXYZPoint1
        )
        this._srcGeodeticDatum.geoc2geodSFCT(this._tmpXYZPoint1, e)
      }
    return e
  }
  _forwardBoundsCoords(t, e) {
    return this._bboxCalculationUtil.forwardBounds2dSFCT(t, e)
  }
  _inverseBoundsCoords(t, e) {
    return this._bboxCalculationUtil.inverseBounds2dSFCT(t, e)
  }
  _getType() {
    return TransformationType.TYPE_GENERAL
  }
}
