import { kmlValueMapFactory } from './KMLValues.js'
export let KMLAltitudeMode = (function (t) {
  t[(t['ABSOLUTE'] = 1)] = 'ABSOLUTE'
  t[(t['CLAMP_TO_GROUND'] = 2)] = 'CLAMP_TO_GROUND'
  t[(t['RELATIVE_TO_GROUND'] = 4)] = 'RELATIVE_TO_GROUND'
  return t
})({})
const ALTITUDE_MODE_MAP = {
  absolute: KMLAltitudeMode.ABSOLUTE,
  clampToGround: KMLAltitudeMode.CLAMP_TO_GROUND,
  relativeToGround: KMLAltitudeMode.RELATIVE_TO_GROUND,
}
export const getKmlAltitudeMode = kmlValueMapFactory(
  KMLAltitudeMode.RELATIVE_TO_GROUND,
  ALTITUDE_MODE_MAP
)
