import { XMLSchema } from '../../util/XMLSchema.js'
import { VersionNegotiator } from './common/VersionNegotiator.js'
const attribute = XMLSchema.attribute
const version_schema = { WFS_Capabilities: { version: attribute() } }
function parseVersion(e) {
  const r = undefined
  const t = new XMLSchema(version_schema).parse(e)
  try {
    return t.WFS_Capabilities.version
  } catch (e) {}
  throw new Error(
    `Can not parse unsupported WFS version ${t?.WFS_Capabilities?.version}`
  )
}
function createVersionNegotiator(e, r, t, o) {
  return new VersionNegotiator(
    e,
    'WFS',
    parseVersion,
    r || ['2.0.0', '1.1.0'],
    t || null,
    o || {}
  )
}
export const WFSCapabilitiesUtil = {
  parseVersion: parseVersion,
  createVersionNegotiator: createVersionNegotiator,
}
