import {
  isKeyValue,
  isPairNode,
  isStyleMapNode,
  isStyleNode,
  isStyleSelectorNode,
  isStyleUrlValue,
} from '../../util/kml/KMLTypes.js'
import {
  xmlNodeContains,
  xmlParentNode,
  xmlReduceChildren,
} from '../../util/kml/KMLInternalUtil.js'
import { Log } from '../../util/Log.js'
import { KMLStyleBuilder } from './KMLStyleBuilder.js'
import { KMLStyle } from '../../view/kml/KMLStyle.js'
import { isString } from '../../util/Lang.js'
import { getKmlId, getKmlValue } from '../../util/kml/KMLValues.js'
export class KMLStyleCache {
  constructor() {
    this._entries = Object.create(null)
  }
  pushStyle(e) {
    const t = getKmlId(e)
    if (!t) return
    const l = undefined
    if (!xmlParentNode(e)) return
    const r = { node: e, style: null }
    const n = this._entries[t]
    if (!n) {
      this._entries[t] = [r]
      return
    }
    n.push(r)
  }
  getStyle(e) {
    const t = this.getDirectStyle(e)
    if (!t) return toKmlStyles()
    if (isString(t)) return this.getNodeStyleEntry(e, t) || toKmlStyles()
    if (isStyleNode(t)) return toKmlStyles(new KMLStyleBuilder(t).build())
    if (isStyleMapNode(t)) return this.processStyleMap(t)
    return toKmlStyles()
  }
  getDirectStyle(e) {
    return xmlReduceChildren(
      e,
      (e, t) => {
        if (isStyleSelectorNode(t)) return t
        if (isStyleUrlValue(t)) return getKmlValue(t)
        return e
      },
      null
    )
  }
  processStyleMap(e) {
    const t = xmlReduceChildren(e, styleReducer, {
      highlight: null,
      normal: null,
    })
    const l = undefined
    const r = undefined
    return toKmlStyles(
      this.processReducedStyleMap(e, t, false),
      this.processReducedStyleMap(e, t, true)
    )
  }
  processReducedStyleMap(e, t, l) {
    const r = l ? 'highlight' : 'normal'
    const n = t[r]
    if (isString(n)) {
      const t = this.getNodeStyleEntry(e, n)
      if (t && t[r]) return t[r]
      return null
    }
    if (n && isStyleNode(n)) return new KMLStyleBuilder(n).build()
    return null
  }
  getNodeStyleEntry(e, t) {
    if (!t) return null
    const l = normalizeStyleUrlId(t)
    if (!l) return null
    const r = this._entries[l]
    if (!r) return null
    const n = r.reduce((t, l) => {
      const r = xmlParentNode(l.node)
      const n = r && xmlNodeContains(r, e)
      const i = t && r && xmlNodeContains(r, t.node)
      if (n && !i) return l
      return t
    }, null)
    if (!n) return null
    if (n.style) return n.style
    if (isStyleNode(n.node))
      return (n.style = toKmlStyles(new KMLStyleBuilder(n.node).build()))
    if (isStyleMapNode(n.node)) return (n.style = this.processStyleMap(n.node))
    return null
  }
}
function normalizeStyleUrlId(e) {
  if (!e) return null
  const t = e.indexOf('#')
  if (-1 === t) return null
  if (0 !== t) {
    Log.error(
      'Remote KML Styles are not currently supported. Using the default KML Style instead.'
    )
    return null
  }
  return e.substring(t + 1)
}
export function toKmlStyles() {
  let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null
  let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null
  const l = new KMLStyle()
  return { normal: e || t || l, highlight: t || e || l }
}
function styleReducer(e, t) {
  if (!isPairNode(t)) return e
  const l = xmlReduceChildren(t, pairReducer, { key: null, style: null })
  if ('highlight' === l.key) e.highlight = l.style
  if (null === l.key || 'normal' === l.key) e.normal = l.style
  return e
}
function pairReducer(e, t) {
  if (isKeyValue(t)) e.key = getKmlValue(t)
  if (isStyleNode(t)) e.style = t
  if (isStyleUrlValue(t)) e.style = getKmlValue(t)
  return e
}
