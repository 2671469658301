import { Polyline } from './Polyline.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { ConstrainedPointList } from './ConstrainedPointList.js'
export function isConstrainedPolyline(n) {
  return n instanceof ConstrainedPolyline
}
export class ConstrainedPolyline extends Polyline {
  constructor(n, t) {
    super()
    this._constrainedPointList = new ConstrainedPointList(n, t)
    this._reference = n.reference
    this._coordinates = n._coordinates ?? []
    this._focus = n.focusPoint ?? void 0
    this._bounds = n.bounds ?? void 0
  }
  get shape() {
    return this._constrainedPointList.shape
  }
  get constraint() {
    return this._constrainedPointList.constraint
  }
  get minPointCount() {
    return this._constrainedPointList.minPointCount
  }
  get maxPointCount() {
    return this._constrainedPointList.maxPointCount
  }
  get pointCount() {
    return this._constrainedPointList.pointCount
  }
  get isGeodetic() {
    return this._constrainedPointList.isGeodetic
  }
  get bounds() {
    return this._constrainedPointList.bounds
  }
  get coordinateType() {
    return this._constrainedPointList.coordinateType
  }
  insertPoint(n, t) {
    this._constrainedPointList.insertPoint(n, t)
  }
  removePoint(n) {
    this._constrainedPointList.removePoint(n)
  }
  move2DPoint(n, t, i) {
    this._constrainedPointList.move2DPoint(n, t, i)
  }
  translatePoint(n, t, i, o) {
    this._constrainedPointList.translatePoint(n, t, i, o)
  }
  translate2D(n, t) {
    this._constrainedPointList.translate2D(n, t)
  }
  getPoint(n) {
    return this._constrainedPointList.getPoint(n)
  }
  contains2D(n, t) {
    return this._constrainedPointList.contains2D(n, t)
  }
  contains2DPoint(n) {
    return this._constrainedPointList.contains2DPoint(n)
  }
  contains2DCoordinates(n, t) {
    return this._constrainedPointList.contains2DCoordinates(n, t)
  }
  copy() {
    return new ConstrainedPolyline(
      this._constrainedPointList.shape.copy(),
      this._constrainedPointList.constraint
    )
  }
  implMove(n, t, i, o) {
    if (this._constrainedPointList.shape instanceof Polyline)
      this._constrainedPointList.shape.implMove(n, t, i, o)
    else throw new ProgrammingError('Cannot implMove for ConstrainedPolyline')
  }
  implTranslate(n, t, i, o) {
    if (this._constrainedPointList.shape instanceof Polyline)
      this._constrainedPointList.shape.implTranslate(n, t, i, o)
    else
      throw new ProgrammingError('Cannot implTranslate for ConstrainedPolyline')
  }
  lineContainsXY(n, t, i) {
    if (this._constrainedPointList.shape instanceof Polyline)
      return this._constrainedPointList.shape.lineContainsXY(n, t, i)
    else
      throw new ProgrammingError(
        'Cannot lineContainsXY for ConstrainedPolyline'
      )
  }
  calculateInterpolationPoint(n) {
    if (this._constrainedPointList.shape instanceof Polyline)
      return this._constrainedPointList.shape.calculateInterpolationPoint(n)
    else
      throw new ProgrammingError(
        'Cannot calculateInterpolationPoint for ConstrainedPolyline'
      )
  }
}
