import { isValidFeatureId } from './Feature.js'
import { isFunction } from '../../util/Lang.js'
import { Hash } from '../../util/Hash.js'
export function getIdGenerator(t) {
  let e =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : getNextIdGenerator()
  const r = isFunction(t) ? t : e
  return (t) => {
    let n = null
    try {
      n = r(t)
    } catch (t) {}
    return isValidFeatureId(n) ? n : `F-${e(t)}`
  }
}
export const naturalFeatureIdGenerator = (t) => {
  const e = new Hash()
  e.reset()
  const { properties: r, shape: n } = t
  for (const t in r) if (r.hasOwnProperty(t)) e.append(r[t])
  n?.hashCode(e)
  return e.getHashCode()
}
export function getNextIdGenerator() {
  let t = 1
  return () => t++
}
