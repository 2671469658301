import { LayerTreeNode } from '../LayerTreeNode.js'
import { LayerTreeVisitor } from '../LayerTreeVisitor.js'
export class RemoveSubTreeVisitor {
  constructor(e, r) {
    this._stopListeningToEventsCallback = e
    this._removeLayerNodeCallback = r
  }
  visitLayer(e) {
    this._stopListeningToEventsCallback(e)
    this._removeLayerNodeCallback(e)
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
  visitLayerGroup(e) {
    this._stopListeningToEventsCallback(e)
    e.visitChildren(this, LayerTreeNode.VisitOrder.BOTTOM_UP)
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
}
