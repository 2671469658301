import {
  angleInRadians,
  distance2D_xy,
  lineCircleIntersectionWithCenterPointSFCT,
  orientation2D,
  pointAtDistanceAndAngle,
  squaredDistanceToSegment,
} from '../../../util/Cartesian.js'
import { isNumber } from '../../../util/Lang.js'
import { PolygonOrientation } from '../../../shape/PolygonOrientation.js'
import { SimpleXYZPoint } from '../../../shape/SimpleXYZPoint.js'
const EPSILON = 1e-7
function getTotalLength(t, i, s) {
  let e = 0
  for (let n = i; n < s - 3; n += 2)
    e += distance2D_xy(t[n], t[n + 1], t[n + 2], t[n + 3])
  return e
}
function between(t, i, s) {
  return t >= Math.min(i, s) && t <= Math.max(i, s)
}
const TEMP_OUT = { x: 0, y: 0, z: 0 }
const COORDS_OUT = []
export class PathIterator {
  constructor(t, i, s, e) {
    this.init(t, i, s, e)
  }
  init(t, i, s, e) {
    this._coordinates = t
    this._from = i
    this._to = this._from + s
    if (t.length < 4 || this._to - this._from < 2)
      throw new Error('coordinates must have at least 2 points')
    this._totalLength = e || getTotalLength(t, this._from, this._to)
    this._closed =
      s > 2 &&
      this._coordinates[0] === this._coordinates[this._to - 2] &&
      this._coordinates[1] === this._coordinates[this._to - 1]
    if (this._stateStack) this._stateStack.length = 0
    else this._stateStack = []
    this.rewind()
    this._bisectors = this.calculateBisectors()
    this.rewind()
  }
  calculateBisectors() {
    const t = []
    while (!this.atEnd()) {
      t.push(this.bisectorStartOfSegment())
      t.push(this.bisectorEndOfSegment())
      this.advanceToNextVertex()
    }
    return t
  }
  bisectorStartOfSegment() {
    let t = Math.PI / 2
    const i = this.angle()
    let s = i
    if (this.atStart()) {
      if (this._closed) {
        const e = this._coordinates[this._to - 1]
        const n = this._coordinates[this._to - 2]
        const r = this._coordinates[this._to - 3]
        const h = this._coordinates[this._to - 4]
        s = angleInRadians(h, r, n, e)
        t -= (s - i) / 2
      }
    } else {
      const e = this._coordinates[this._i + 1]
      const n = this._coordinates[this._i]
      const r = this._coordinates[this._i - 1]
      const h = this._coordinates[this._i - 2]
      s = angleInRadians(h, r, n, e)
      t -= (s - i) / 2
    }
    return this.correctBisector(t)
  }
  bisectorEndOfSegment() {
    let t = Math.PI / 2
    const i = this.angle()
    let s = i
    if (this._i + 2 === this._to - 2) {
      if (this._closed) {
        const e = this._coordinates[3]
        const n = this._coordinates[2]
        const r = this._coordinates[1]
        const h = this._coordinates[0]
        s = angleInRadians(h, r, n, e)
        t -= (s - i) / 2
      }
    } else {
      const e = this._coordinates[this._i + 5]
      const n = this._coordinates[this._i + 4]
      const r = this._coordinates[this._i + 3]
      const h = this._coordinates[this._i + 2]
      s = angleInRadians(h, r, n, e)
      t -= (s - i) / 2
    }
    return this.correctBisector(t)
  }
  correctBisector(t) {
    if (t < 0) t += 2 * Math.PI
    if (t > Math.PI) t -= Math.PI
    return t
  }
  getFrom() {
    return this._from
  }
  getTo() {
    return this._to
  }
  getCount() {
    return this._to - this._from
  }
  getCoordinates() {
    return this._coordinates
  }
  moveToEnd() {
    this._i = this._to - 2
    this._x = this._coordinates[this._i]
    this._y = this._coordinates[this._i + 1]
    this._dist = this._totalLength
  }
  reload(t, i, s) {
    this.init(t, i, s)
  }
  reloadWithCalculatedDistance(t, i, s, e) {
    this.init(t, i, s, e)
  }
  reloadFrom(t) {
    this.init(t.getCoordinates(), t.getFrom(), t.getCount())
  }
  rewind() {
    this._i = this._from
    this._dist = 0
    this._x = this._coordinates[this._i]
    this._y = this._coordinates[this._i + 1]
    this._clearJumpState()
  }
  totalLength() {
    return this._totalLength
  }
  _clearJumpState() {
    this._jumpd = null
    this._jumpa = null
    this._jumpe = null
    this._jumpx = null
    this._jumpy = null
    this._jumpi = null
    this._jumptd = null
  }
  _jumpIntersection(t) {
    let i, s, e, n
    let r, h, o, a
    for (a = this._i; a < this._to - 4; a += 2) {
      i = this._coordinates[a + 2]
      s = this._coordinates[a + 3]
      e = this._coordinates[a + 4]
      n = this._coordinates[a + 5]
      COORDS_OUT.length = 0
      COORDS_OUT.intersections = 0
      lineCircleIntersectionWithCenterPointSFCT(
        i,
        s,
        e,
        n,
        this._x,
        this._y,
        t,
        COORDS_OUT
      )
      for (o = 0; o < 2 * COORDS_OUT.intersections; o += 2) {
        r = COORDS_OUT[o]
        h = COORDS_OUT[o + 1]
        if (between(r, i, e) && between(h, s, n)) {
          this._jumpi = a + 2
          this._jumpx = r
          this._jumpy = h
          return true
        }
      }
    }
    this._jumpx = null
    this._jumpy = null
    return false
  }
  _approximationError() {
    let t = -1 / 0
    if (!this._jumpi) return t
    const i = this._x
    const s = this._y
    const e = this._jumpx
    const n = this._jumpy
    for (let r = this._i + 2; r <= this._jumpi; r += 2) {
      const h = this._coordinates[r]
      const o = this._coordinates[r + 1]
      t = Math.max(t, squaredDistanceToSegment(i, s, e, n, h, o))
    }
    return t
  }
  _traveledDistance() {
    let t = this._x
    let i = this._y
    this._jumptd = 0
    if (this._jumpi) {
      let s, e
      for (let n = this._i + 2; n <= this._jumpi; n += 2) {
        s = this._coordinates[n]
        e = this._coordinates[n + 1]
        this._jumptd += distance2D_xy(t, i, s, e)
        t = s
        i = e
      }
    }
    this._jumptd += distance2D_xy(t, i, this._jumpx, this._jumpy)
  }
  jumpError(t) {
    if (this.atEnd()) return 1 / 0
    if (isNumber(this._jumpe) && this._jumpd === t) return this._jumpe
    this._jumpd = t
    if (this._jumpIntersection(t)) {
      this._jumpe = this._approximationError()
      this._traveledDistance()
      return this._jumpe
    } else return 1 / 0
  }
  jumpDistance(t) {
    if (this.atEnd()) return
    if (
      !isNumber(this._jumptd) ||
      !isNumber(this._jumpx) ||
      !isNumber(this._jumpy) ||
      !isNumber(this._jumpi) ||
      this._jumpd !== t
    )
      throw new Error('Should call jumpError first')
    this._x = this._jumpx
    this._y = this._jumpy
    this._i = this._jumpi
    this._dist += this._jumptd
    this._clearJumpState()
  }
  jumpAngle(t) {
    if (this.atEnd()) return 0
    if (this._jumpd !== t) throw new Error('Should call jumpError first')
    return angleInRadians(this._x, this._y, this._jumpx, this._jumpy)
  }
  saveState() {
    this._stateStack.push({
      x: this._x,
      y: this._y,
      i: this._i,
      dist: this._dist,
      jumpd: this._jumpd,
      jumpa: this._jumpa,
      jumpe: this._jumpe,
      jumpx: this._jumpx,
      jumpy: this._jumpy,
      jumpi: this._jumpi,
      jumptd: this._jumptd,
    })
  }
  restoreState() {
    const t = this._stateStack.pop()
    if (!t) throw new Error('No state to restore')
    this._i = t.i
    this._dist = t.dist
    this._x = t.x
    this._y = t.y
    this._jumpd = t.jumpd
    this._jumpa = t.jumpa
    this._jumpe = t.jumpe
    this._jumpx = t.jumpx
    this._jumpy = t.jumpy
    this._jumpi = t.jumpi
    this._jumptd = t.jumptd
  }
  advanceToNextVertex() {
    if (this.atEnd()) {
      this._clearJumpState()
      return
    }
    const t = this.distanceToNextVertex()
    this._i += 2
    this._x = this._coordinates[this._i]
    this._y = this._coordinates[this._i + 1]
    this._dist += t
    this._clearJumpState()
  }
  atStart() {
    return 0 == this._i
  }
  atEnd() {
    return this._i >= this._to - 2
  }
  advanceDistance(t) {
    if (t < 0 || this.atEnd()) return false
    this._clearJumpState()
    const i = this.distanceToNextVertex()
    if (t < i) {
      pointAtDistanceAndAngle(this._x, this._y, this.angleToNext(), t, TEMP_OUT)
      this._dist += t
      this._x = TEMP_OUT.x
      this._y = TEMP_OUT.y
      return true
    }
    this.advanceToNextVertex()
    if ((t -= i) > 0 && this._i < this._to) return this.advanceDistance(t)
    else return false
  }
  advanceFraction(t) {
    if (0 === t) return
    this.advanceDistance(t * this._totalLength)
    if (
      !this.atEnd() &&
      (this.fractionFromStart() >= 1 - EPSILON ||
        this.fractionFromStart() <= +EPSILON)
    )
      this.advanceToNextVertex()
  }
  angle() {
    return this.atEnd() ? this.angleAtEnd() : this.angleToNext()
  }
  angleOverDistance(t) {
    if (t <= 0) return this.angle()
    this.saveState()
    const i = this._x
    const s = this._y
    this.advanceDistance(t)
    const e = angleInRadians(i, s, this._x, this._y)
    this.restoreState()
    return e
  }
  angleAtEnd() {
    return angleInRadians(
      this._coordinates[this._to - 4],
      this._coordinates[this._to - 3],
      this._coordinates[this._to - 2],
      this._coordinates[this._to - 1]
    )
  }
  angleToNext() {
    if (this.atEnd()) throw new Error('line at end')
    return angleInRadians(this._x, this._y, this.nextX(), this.nextY())
  }
  x() {
    return this._x
  }
  y() {
    return this._y
  }
  previousX() {
    return this._coordinates[this._i]
  }
  previousY() {
    return this._coordinates[this._i + 1]
  }
  nextX() {
    if (this.atEnd()) throw new Error('line at end')
    return this._coordinates[this._i + 2]
  }
  nextY() {
    if (this.atEnd()) throw new Error('line at end')
    return this._coordinates[this._i + 3]
  }
  isClosed() {
    return this._closed
  }
  fractionFromStart() {
    return this._dist / this._totalLength
  }
  fractionToNext() {
    return this.distanceToNextVertex() / this._totalLength
  }
  distanceFromStart() {
    return this._dist
  }
  distanceToEnd() {
    return this._totalLength - this._dist
  }
  distanceToPreviousVertex() {
    return distance2D_xy(this.x(), this.y(), this.previousX(), this.previousY())
  }
  distanceToNextVertex() {
    return distance2D_xy(this.x(), this.y(), this.nextX(), this.nextY())
  }
  isInJump() {
    return isNumber(this._jumpi)
  }
  absoluteDistanceToNextVertex() {
    return this.distanceFromStart() + this.distanceToNextVertex()
  }
  bisector() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let i = Math.PI / 2
    if (0 === t) {
      if (
        this.x() === this.getCoordinates()[this._i] &&
        this.y() === this.getCoordinates()[this._i + 1]
      )
        i = this._bisectors[this._i]
    } else if (Math.abs(this.distanceToNextVertex() - t) < EPSILON)
      i = this._bisectors[this._i + 1]
    return i
  }
  bisectorAtStartOfCurrentSegment() {
    return this._bisectors[this._i]
  }
  bisectorAtEndOfCurrentSegment() {
    return this._bisectors[this._i + 1]
  }
  needToInvertOffset() {
    return (
      this.isClosed() &&
      this.calculateOrientation() === PolygonOrientation.CLOCKWISE
    )
  }
  calculateOrientation() {
    const t = []
    for (let i = 0; i < this._to; i += 2)
      t.push(
        new SimpleXYZPoint(
          null,
          this._coordinates[i],
          this._coordinates[i + 1],
          0
        )
      )
    return orientation2D(t)
  }
}
