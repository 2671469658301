import { PhotonImageDecoder } from './PhotonImageDecoder.js'
function PhotonGeometryDecoder(e, o, t) {
  this._photon = e
  this._webWorkerPool = o
  this._imageDecoder = new PhotonImageDecoder(e, o)
  this._photonReferenceProvider = t
}
PhotonGeometryDecoder.prototype.decodeUrl = function (
  e,
  o,
  t,
  r,
  a,
  n,
  i,
  c,
  d
) {
  var m = this._imageDecoder
  var s = this
  return m._requestUrlAsEncodedBuffer(e, void 0, void 0, c).then(function (e) {
    return s.decodeArrayBuffer(e.data, e.contentType, o, t, r, a, n, i, d)
  })
}
PhotonGeometryDecoder.prototype.decodeArrayBuffer = function (
  e,
  o,
  t,
  r,
  a,
  n,
  i,
  c,
  d
) {
  var m = this._imageDecoder
  var s = this._webWorkerPool
  var h = this._photonReferenceProvider
  var l = {
    data: e,
    contentType: o,
    sourceReference: h.encodeReference(t),
    destinationReference: h.encodeReference(r),
    tileBounds: a,
    tileDiscretizationParameters: n,
    isTopTile: i,
    isBottomTile: c,
    tileLevel: d,
  }
  return s
    .enqueue({ command: 'decodeImageAndDiscretizeTile', data: l }, [l.data])
    .then(function (e) {
      return m._createTileGeometryFromDecodedData(
        e.width,
        e.height,
        e.positionData,
        e.geocentricNormalData,
        e.elevationData,
        e.normalData,
        e.uvData,
        e.discardedTrianglesData,
        e.localOrigin,
        e.hasCurtains,
        e.minimumElevation,
        e.maximumElevation,
        e.averageElevation,
        e.level
      )
    })
}
PhotonGeometryDecoder.prototype.decodeRaw = function (e, o, t, r, a, n, i, c) {
  var d = this._imageDecoder
  var m = this._webWorkerPool
  var s = {
    data: e,
    contentType: 'raw',
    sourceReference: this._photonReferenceProvider.encodeReference(o),
    destinationReference: this._photonReferenceProvider.encodeReference(t),
    tileBounds: r,
    tileDiscretizationParameters: a,
    isTopTile: n,
    isBottomTile: i,
    tileLevel: c,
  }
  return m
    .enqueue({ command: 'decodeImageAndDiscretizeTile', data: s }, [
      s.data.data,
    ])
    .then(function (e) {
      return d._createTileGeometryFromDecodedData(
        e.width,
        e.height,
        e.positionData,
        e.geocentricNormalData,
        e.elevationData,
        e.normalData,
        e.uvData,
        e.discardedTrianglesData,
        e.localOrigin,
        e.hasCurtains,
        e.minimumElevation,
        e.maximumElevation,
        e.averageElevation,
        e.level
      )
    })
}
PhotonGeometryDecoder.prototype.decodeMesh = function (e, o, t, r, a) {
  var n = this._photonReferenceProvider.encodeReference(o)
  var i = this._photonReferenceProvider.encodeReference(t)
  return this._imageDecoder._createTileGeometryFromMeshData(e, n, i, r, a)
}
export { PhotonGeometryDecoder }
