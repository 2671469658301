import { Lang } from './Lang.js'
import { XML } from './XML.js'
const isDefined = Lang.isDefined
function identity(e) {
  return e
}
function ignoreNamespaceCompare(e, t) {
  return t.localName && t.localName.toLowerCase() === e.toLowerCase()
}
function namespacedCompare(e, t) {
  return t.nodeName && t.nodeName.toLowerCase() === e.toLowerCase()
}
function findMap(e, t, n, r, s) {
  if (!isDefined(r)) r = true
  s = s || ignoreNamespaceCompare
  for (let o = 0; o < e.length; o++)
    if (s(t, e[o])) {
      n(e[o])
      if (r) return
    }
}
class ContentNode {
  constructor(e) {
    this.parser = e
  }
}
class AttributeNode {
  constructor(e) {
    this.parser = e
  }
}
class XMLSchema {
  constructor(e) {
    this._schema = e
  }
  static content(e) {
    if (!isDefined(e)) e = identity
    return new ContentNode(e)
  }
  static attribute(e) {
    if (!isDefined(e)) e = identity
    return new AttributeNode(e)
  }
  static boolean(e) {
    return 'true' === e || '1' === e
  }
  static orderedAll(e) {
    return new OrderedAllNode(e)
  }
  static orderedSome(e) {
    return new OrderedSomeNode(e)
  }
  static inheritedNamespace(e) {
    return new InheritedNamespaceNode(e)
  }
  parse(e) {
    return this.parseDocument(XML.stringToXml(e), this._schema)
  }
  parseDocument(e, t) {
    t = t || this._schema
    const n = this
    let r
    const s = e.children || e.childNodes
    if (t instanceof OrderedAllNode) r = n._parseOrdered(s, t)
    else {
      r = {}
      Object.keys(t).forEach(function (o) {
        let i
        const a = t[o]
        if (a instanceof OrderedSomeNode) i = n._parseOrdered(s, a)
        else if (a instanceof ContentNode)
          if ('#text' === o || '#text_only' === o) i = a.parser(e.textContent)
          else
            findMap(s, o, function (e) {
              i = a.parser(e.textContent)
            })
        else if (a instanceof AttributeNode && e instanceof Element)
          findMap(e.attributes, o, function (e) {
            i = a.parser(e.textContent)
          })
        else if (Array.isArray(a)) {
          i = []
          findMap(
            s,
            o,
            function (e) {
              i.push(n.parseDocument(e, a[0]))
            },
            false
          )
        } else if (a instanceof InheritedNamespaceNode)
          i = n._findNamespace(e, a)
        else
          findMap(s, o, function (e) {
            i = n.parseDocument(e, a)
          })
        if (isDefined(i) && !(Array.isArray(i) && 0 === i.length))
          if ('#text_only' === o) r = i
          else r[o] = i
      })
    }
    return r
  }
  _findNamespace(e, t) {
    let n
    const r = t.getQualifiedNamespacePrefix(e)
    if (!Lang.isDefined(r)) return null
    do {
      findMap(
        e.attributes || [],
        r,
        function (e) {
          n = e.textContent
        },
        true,
        namespacedCompare
      )
      e = e.parentNode
    } while (Lang.isDefined(e) && !Lang.isDefined(n))
    return n
  }
  _parseOrdered(e, t) {
    const n = []
    for (let r = 0; r < e.length; r++) {
      const s = e[r]
      if (!Lang.isDefined(s.localName)) continue
      const o = s.localName.toLowerCase()
      const i = t.schema[o]
      const a = t.originalKeys[o]
      if (i) {
        const e = this.parseDocument(s, i)
        e['#element'] = a
        n.push(e)
      }
    }
    return n
  }
}
XMLSchema.number = Number
class OrderedNode {
  constructor(e) {
    const t = this
    this.schema = {}
    this.originalKeys = {}
    Object.keys(e).forEach(function (n) {
      t.originalKeys[n.toLowerCase()] = n
      t.schema[n.toLowerCase()] = e[n]
    })
  }
}
class OrderedAllNode extends OrderedNode {
  constructor(e) {
    super(e)
  }
}
class OrderedSomeNode extends OrderedNode {
  constructor(e) {
    super(e)
  }
}
class InheritedNamespaceNode {
  constructor(e) {
    this.namespacePrefix = e
  }
  getQualifiedNamespacePrefix(e) {
    let t
    if ('function' === typeof this.namespacePrefix) t = this.namespacePrefix(e)
    else t = this.namespacePrefix
    return Lang.isDefined(t) ? 'xmlns:' + t : null
  }
}
InheritedNamespaceNode.prototype.constructor = InheritedNamespaceNode
export { XMLSchema }
