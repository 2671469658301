import { Log } from '../../util/Log.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { RTreeIndex } from './RTreeIndex.js'
import { LinearIndex } from './LinearIndex.js'
import { FeatureRenderNode } from './FeatureRenderNode.js'
import { QueryStatus } from './QueryStatus.js'
import { WorkingSetUpdater } from './WorkingSetUpdater.js'
import { createOptimizedEventedSupport } from '../../util/EventedSupport.js'
import { isFunction, isOfType } from '../../util/Lang.js'
export class MemoryWorkingSet {
  get workingSetUpdater() {
    return this._workingSetUpdater
  }
  setDelegateWorkingSet(e) {
    throw new Error('Method not implemented.')
  }
  isReady() {
    throw new Error('Method not implemented.')
  }
  constructor(e, t) {
    this._eventedSupport = createOptimizedEventedSupport([
      'QueryError',
      'QueryFinished',
      'QueryStarted',
      'QuerySuccess',
      'WorkingSetChanged',
      'QueryInterrupted',
    ])
    this._shapeProvider = e.shapeProvider
    this._shapeReference = e.getWorkingSetReference()
    this._editedFeatureId = null
    this._index = t.filterSpatially
      ? new RTreeIndex(this._shapeReference)
      : new LinearIndex(this._shapeReference)
    this._queryStatus = QueryStatus.QUERY_PENDING
    this._workingSetUpdater = new WorkingSetUpdater(e, this, t)
  }
  add(e, t) {
    if (this.canDelegate(this.workingSetUpdater, 'add'))
      return this.workingSetUpdater.add(e, t)
  }
  put(e, t) {
    if (this.canDelegate(this.workingSetUpdater, 'put'))
      return this.workingSetUpdater.put(e, t)
  }
  remove(e) {
    if (this.canDelegate(this.workingSetUpdater, 'remove'))
      return this.workingSetUpdater.remove(e)
  }
  canDelegate(e, t) {
    return isFunction(this.workingSetUpdater[t])
  }
  refreshWorkingSet(e) {
    return this.workingSetUpdater.refreshWorkingSet(e)
  }
  cancelPending() {
    this.workingSetUpdater.cancelPending()
  }
  _getShape(e) {
    const t = this._shapeProvider.provideShape(e)
    if (t && !this._shapeReference.equals(t.reference))
      throw new ProgrammingError(
        'The reference of the shape and the model reference is not identical. ' +
          'All shapes must share the reference of the model'
      )
    return t
  }
  getIdMapSnapshot() {
    return this._index.getIdMapSnapshot()
  }
  setSelected(e, t) {
    const r = this.getNode(e)
    if (r) {
      r.selected = t
      return r.feature
    } else return null
  }
  setEdited(e, t) {
    this._editedFeatureId = t ? e : null
    const r = this.getNode(e)
    if (r && r.feature.shape) {
      r.edited = t
      return r.feature
    } else return null
  }
  _registerPending() {
    this._queryStatus = QueryStatus.QUERY_PENDING
  }
  _registerStart() {
    this._queryStatus = QueryStatus.QUERY_STARTED
    this._eventedSupport.emit('QueryStarted')
  }
  _registerSuccess() {
    this._queryStatus = QueryStatus.QUERY_SUCCESS
    this._eventedSupport.emit('QuerySuccess')
  }
  _registerError(e) {
    this._queryStatus = QueryStatus.QUERY_ERROR
    const t = e && isOfType(e, 'message') ? e.message : e || ''
    const r = e && isOfType(e, 'stack') ? e.stack : ''
    Log.warn(`QueryError event: ${t} ${r}`)
    this._eventedSupport.emit('QueryError', e)
  }
  _registerInterruption() {
    this._queryStatus = QueryStatus.QUERY_INTERRUPTED
    this._eventedSupport.emit('QueryInterrupted')
  }
  _registerFinish() {
    this._queryStatus = QueryStatus.QUERY_FINISHED
    this._eventedSupport.emit('QueryFinished')
  }
  _clearData() {
    if (this._index) this._index.clear()
    this._eventedSupport.emit('WorkingSetChanged', 'clear')
  }
  clearAll() {
    this._clearData()
    this._registerPending()
  }
  get() {
    const e = []
    this._forEachFeature((t) => e.push(t))
    return e
  }
  getNode(e) {
    return this._index.getNode(e)
  }
  getFeature(e) {
    const t = this.getNode(e)
    return t ? t.feature : null
  }
  _forEachFeature(e) {
    this.forEachNode((t) => e(t.feature))
  }
  forEachVisibleFeature(e) {
    this.forEachVisibleNode((t) => e(t.feature))
  }
  search(e, t) {
    this._index.search(e, t)
  }
  forEachVisibleNode(e) {
    this._index.forEachVisibleNode(e)
  }
  forEachNode(e) {
    this._index.forEachNode(e)
  }
  _addObject(e, t) {
    this._addOrUpdateObject(e, t)
  }
  _updateObject(e, t) {
    this._addOrUpdateObject(e, t)
  }
  _addOrUpdateObject(e, t) {
    let r = this._index.getNode(t)
    if (!r) {
      r = new FeatureRenderNode(e, this._getShape(e))
      r.edited = t === this._editedFeatureId
      this._index.insert(r)
      this._eventedSupport.emitWorkingSetChangedEvent('add', e, e.id)
    } else {
      r = this._index.remove(t)
      r.reset(e, this._getShape(e))
      this._index.insert(r)
      this._eventedSupport.emitWorkingSetChangedEvent('update', r.feature, t)
    }
  }
  _removeObject(e, t) {
    const r = this._index.remove(t)
    if (r)
      this._eventedSupport.emitWorkingSetChangedEvent('remove', r.feature, t)
  }
  on(e, t, r) {
    return this._eventedSupport.on(e, t, r)
  }
  get bounds() {
    return this._index.getBounds()
  }
  get queryStatus() {
    return this._queryStatus
  }
}
