import { GeodeticDatum } from '../geodesy/GeodeticDatum.js'
import { getUnitOfMeasure } from '../uom/UnitOfMeasureRegistry.js'
import { Hash } from '../util/Hash.js'
import { isString } from '../util/Lang.js'
import { Axis } from './Axis.js'
import { CoordinateType } from './CoordinateType.js'
import { GeoReference } from './GeoReference.js'
import { ReferenceType } from './ReferenceType.js'
const meterUom = getUnitOfMeasure('Meter')
export class GeocentricReference extends GeoReference {
  constructor(e) {
    const t = e || {}
    t.coordinateType = CoordinateType.CARTESIAN
    t.referenceType = ReferenceType.GEOCENTRIC
    if (!t.axisInformation)
      t.axisInformation = [
        {
          name: Axis.Name.X,
          axis: new Axis(
            'X',
            Axis.Direction.GEOCENTRIC_X,
            meterUom,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
        {
          name: Axis.Name.Y,
          axis: new Axis(
            'Y',
            Axis.Direction.GEOCENTRIC_Y,
            meterUom,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
        {
          name: Axis.Name.Z,
          axis: new Axis(
            'Z',
            Axis.Direction.GEOCENTRIC_Z,
            meterUom,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
      ]
    let i, s
    if ('object' !== typeof e || null == e) {
      s = new GeodeticDatum()
      i = 'WGS 84 Geocentric'
    } else if (
      'object' !== typeof e.geodeticDatum ||
      null === e.geodeticDatum
    ) {
      s = new GeodeticDatum()
      i = 'WGS 84 Geocentric'
    } else {
      s = e.geodeticDatum
      if (isString(e.name)) i = e.name
      else i = s.name
    }
    t.name = i
    t.geodeticDatum = s
    super(t)
    this._unitOfMeasure = (e ? e.uom : void 0) || 1
    this._hash = 0
  }
  equals(e) {
    if (e === this) return true
    if (e instanceof GeocentricReference)
      return (
        this.geodeticDatum.equals(e.geodeticDatum) &&
        this.getAxis(Axis.Name.X).equals(e.getAxis(Axis.Name.X)) &&
        this.getAxis(Axis.Name.Y).equals(e.getAxis(Axis.Name.Y)) &&
        this.getAxis(Axis.Name.Z).equals(e.getAxis(Axis.Name.Z)) &&
        this.unitOfMeasure === e.unitOfMeasure
      )
    else return false
  }
  toString() {
    return `GeocentricReference[${this.name}]`
  }
  copy() {
    return new GeocentricReference({
      coordinateType: this.coordinateType,
      referenceType: this.referenceType,
      axisInformation: this.axisInformation,
      authorityName: this.authorityName,
      authorityCode: this.authorityCode,
      name: this.name,
      geodeticDatum: this.geodeticDatum,
      heightReference: this.heightReference,
      uom: this._unitOfMeasure,
    })
  }
  get unitOfMeasure() {
    return this._unitOfMeasure
  }
  set unitOfMeasure(e) {
    this._unitOfMeasure = e
  }
  get TYPE() {
    return ReferenceType.GEOCENTRIC
  }
  hashCode(e) {
    if (0 === this._hash) {
      const e = new Hash()
      super.hashCode(e)
      e.appendDouble(this.unitOfMeasure)
      this._hash = e.getHashCode()
    }
    e.appendUInt32(this._hash)
  }
}
