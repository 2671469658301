import { AreaSegmentChooser } from './AreaSegmentChooser.js'
class AreaSegmentChooserIntersect extends AreaSegmentChooser {
  constructor() {
    super()
  }
  isSegmentOkAbstract(e) {
    if (e.segmentLabel.locationOn[e.otherShapeIndex]) {
      if (
        e.segmentLabel.locationLeft[e.otherShapeIndex] &&
        e.segmentLabel.locationLeft[e.shapeIndex]
      )
        return true
      if (
        e.segmentLabel.locationRight[e.otherShapeIndex] &&
        e.segmentLabel.locationRight[e.shapeIndex]
      )
        return true
    }
    return false
  }
}
export { AreaSegmentChooserIntersect }
