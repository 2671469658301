import { createPolyline } from '../../../../shape/ShapeFactory.js'
import { HelperHandle } from './HelperHandle.js'
export class ArcBandHelperHandle extends HelperHandle {
  constructor(e, r) {
    super(r)
    this._arcBand = e
  }
  get arcBand() {
    return this._arcBand
  }
  onDraw(e, r) {
    const a = this.getHelperStyle(r)
    if (a) {
      const t = createPolyline(this.arcBand.reference, [
        this.arcBand.center,
        this.arcBand.minRadiusStartCorner,
      ])
      const n = createPolyline(this.arcBand.reference, [
        this.arcBand.center,
        this.arcBand.minRadiusEndCorner,
      ])
      const c = { ...a, drapeTarget: this.getDrapeTarget(r, t) }
      e.drawShape(t, c)
      e.drawShape(n, c)
    }
  }
}
