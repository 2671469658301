import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import {
  attribute,
  color,
  defaultColor,
  eq,
  ifThenElse,
  number,
  numberParameter,
  or,
} from '../../util/expression/ExpressionFactory.js'
import { ExpressionResolver } from '../../util/expression/ExpressionResolver.js'
import { ExpressionValidator } from '../../util/expression/ExpressionValidator.js'
import { isUndefined } from '../../util/Lang.js'
import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import { FacetCullingType } from './FacetCullingType.js'
import { PBRSettingsImpl } from './PBRSettings.js'
import { toPhotonPBRSettings } from './util/PBRUtil.js'
export class MeshStyleImpl {
  _facetCulling = FacetCullingType.NO_CULLING
  constructor(e, t) {
    e = e || {}
    this._layer = t
    this._expressionResolver = ExpressionResolver
    this._expressionValidator = new ExpressionValidator(
      this._expressionResolver
    )
    this._colorExpression = e.colorExpression ?? null
    this._selectedColorExpression =
      e.selectedColorExpression ?? color('rgb(255,148,76)')
    this._shouldValidateSelectedColor = false
    this._visibilityExpression = e.visibilityExpression ?? null
    this._displacementExpression = e.displacementExpression ?? null
    this._lighting = e.lighting ?? true
    this._pbrSettings = e.pbrSettings
      ? new PBRSettingsImpl(e.pbrSettings)
      : null
    this.facetCulling = e.facetCulling ?? FacetCullingType.NO_CULLING
    this._selectionSlots = []
    for (let e = 0; e < 2; e++)
      this._selectionSlots.push(numberParameter(Number.NaN))
  }
  get layer() {
    return this._layer
  }
  release() {
    this._layer = null
  }
  invalidate() {
    if (this._layer) this._layer._invalidate()
  }
  get colorExpression() {
    return this._colorExpression
  }
  set colorExpression(e) {
    this._colorExpression = e
    this._styleChanged()
  }
  get selectedColorExpression() {
    return this._selectedColorExpression
  }
  set selectedColorExpression(e) {
    this._selectedColorExpression = e
    this._shouldValidateSelectedColor = true
    this._styleChanged()
  }
  get visibilityExpression() {
    return this._visibilityExpression
  }
  set visibilityExpression(e) {
    this._visibilityExpression = e
    this._styleChanged()
  }
  get displacementExpression() {
    return this._displacementExpression
  }
  set displacementExpression(e) {
    this._displacementExpression = e
    this._styleChanged()
  }
  get lighting() {
    return this._lighting
  }
  set lighting(e) {
    const t = undefined
    if (this._lighting !== e) {
      this._lighting = e
      this._styleChanged()
    }
  }
  get pbrSettings() {
    return this._pbrSettings
  }
  set pbrSettings(e) {
    const t = undefined
    if (this._pbrSettings !== e) {
      this._pbrSettings = e ? new PBRSettingsImpl(e) : null
      this._styleChanged()
    }
  }
  get facetCulling() {
    return this._facetCulling
  }
  set facetCulling(e) {
    const t = undefined
    if (this._facetCulling !== e) {
      this._facetCulling = e
      this._styleChanged()
    }
  }
  updatePhotonScenePainter(e, t) {
    if (!e) return
    const s = new ObjectReleaseTracker()
    try {
      const i = this._colorExpression
      if (i) this._validateColorExpression(i)
      const r = this._selectedColorExpression
      if (r && this._shouldValidateSelectedColor) {
        this._validateColorExpression(r)
        this._shouldValidateSelectedColor = false
      }
      const l = this._visibilityExpression
      if (l) this._validateVisibilityExpression(l)
      const o = this._displacementExpression
      if (o) this._validateDisplacementExpression(o)
      const n = this._createMixedColorExpression(i, r, t)
      const a = s.track(this._resolveExpression(n))
      const h = l ? s.track(this._resolveExpression(l)) : null
      const p = o ? s.track(this._resolveExpression(o)) : null
      e.setFacetCullingType(this._toPhotonFacetCullingType(this._facetCulling))
      Photon.ScenePainterWrapper.setMeshStyle(
        a,
        h,
        p,
        this._lighting,
        toPhotonPBRSettings(this._pbrSettings),
        e
      )
    } finally {
      s.release()
    }
  }
  _toPhotonFacetCullingType(e) {
    switch (e) {
      case FacetCullingType.NO_CULLING:
        return Photon.FacetCullingType.NoCulling
      case FacetCullingType.BACKFACE_CULLING:
        return Photon.FacetCullingType.BackfaceCulling
      case FacetCullingType.FRONTFACE_CULLING:
        return Photon.FacetCullingType.FrontfaceCulling
      default:
        return Photon.FacetCullingType.NoCulling
    }
  }
  _createMixedColorExpression(e, t, s) {
    if (!e) e = defaultColor()
    if (isUndefined(s)) return e
    if (!t) return e
    const i = attribute(s, 1)
    const r = this._selectionSlots.map(function (e) {
      return eq(i, e, number(0.45))
    })
    const l = or.apply(null, r)
    return ifThenElse(l, t, e)
  }
  setSelectedIds(e) {
    const t = e.length > this._selectionSlots.length
    if (t)
      for (let t = this._selectionSlots.length; t < 2 * e.length; t++)
        this._selectionSlots.push(numberParameter(Number.NaN))
    for (let t = 0; t < e.length; t++) this._selectionSlots[t].value = e[t]
    for (let t = e.length; t < this._selectionSlots.length; t++)
      this._selectionSlots[t].value = Number.NaN
    if (t) this._styleChanged()
  }
  _styleChanged() {
    if (this._layer) {
      this._layer.updatePhotonMeshStyle()
      this._layer._invalidate()
    }
  }
  _resolveExpression(e, t) {
    const s = t || function () {}
    return this._expressionResolver.resolve(e, this, null, void 0, s)
  }
  _validateColorExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForColorExpression(e),
      'color'
    )
  }
  _validateVisibilityExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForVisibilityExpression(e),
      'visibility'
    )
  }
  _validateDisplacementExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForDisplacementExpression(e),
      'displacement'
    )
  }
  static create(e, t) {
    return new MeshStyleImpl(e, t)
  }
}
function handleErrorMessage(e, t) {
  if (e)
    throw new ProgrammingError(
      `There is a problem with the ${t}  expression:\n ${e}`
    )
}
