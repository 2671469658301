import {
  appendPath,
  appendViewPathNoClose,
  virtualClipAndAppendViewPath,
  virtualClipAndAppendWorldPath,
} from './HTML5CanvasUtil.js'
import { Clipping } from '../../util/Clipping.js'
function getPathTracer(e) {
  return isDash(e) ? virtualClipAndAppendWorldPath : appendPath
}
function isDash(e) {
  return e.dash.length > 0
}
function wrapShapeCounterClockwise(e) {
  const t = 1e3
  const s = e.canvas.height + t
  const r = e.canvas.width + t
  e.moveTo(-t, -t)
  e.lineTo(-t, s)
  e.lineTo(r, s)
  e.lineTo(r, -t)
  e.closePath()
}
export class HTML5DrawStrokeCommandProto {
  constructor(e) {
    if (e) this._storeStrokeSettings(e)
    this.rescaledSizes = {
      forMapScale: null,
      width: 1,
      dash: [],
      dashOffset: 0,
      patternLength: 0,
    }
  }
  get worldSizeSupport() {
    return this._worldSizeSupport
  }
  set worldSizeSupport(e) {
    this._worldSizeSupport = e
  }
  get uom() {
    return this.strokeStyle.uom
  }
  get isWorldSize() {
    return this.strokeStyle.isWorldSize
  }
  get color() {
    return this.strokeStyle.color
  }
  set color(e) {
    this.strokeStyle.color = e
  }
  get width() {
    return this.strokeStyle.width
  }
  set width(e) {
    this.strokeStyle.width = e
  }
  get dash() {
    return this.strokeStyle.dash
  }
  set dash(e) {
    this.strokeStyle.dash = e
  }
  get dashOffset() {
    return this.strokeStyle.dashOffset
  }
  set dashOffset(e) {
    this.strokeStyle.dashOffset = e
  }
  get beginMarker() {
    return this.strokeStyle.beginMarker
  }
  set beginMarker(e) {
    this.strokeStyle.beginMarker = e
  }
  get endMarker() {
    return this.strokeStyle.endMarker
  }
  set endMarker(e) {
    this.strokeStyle.endMarker = e
  }
  _storeStrokeSettings(e) {
    this.strokeStyle = e
  }
  _traceAndStrokePaths(e, t, s) {
    let r =
      arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : false
    const i = t.length
    const o = getPathTracer(this.strokeStyle)
    const n = undefined
    const l = this.getPixelSizes().patternLength
    const h = this.strokeStyle.clip
    if (!h) {
      e.beginPath()
      for (let n = 0; n < i; n++) o(t[n], e, s, r, l)
      this._stroke(e)
    } else {
      e.save()
      e.beginPath()
      if (h === Clipping.MASK.OUTER) wrapShapeCounterClockwise(e)
      for (let n = 0; n < i; n++) o(t[n], e, s, r, l)
      e.clip('evenodd')
      e.lineCap = 'round'
      this._stroke(e)
      e.restore()
    }
  }
  _traceAndStrokePath(e, t, s) {
    let r =
      arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : false
    const i = getPathTracer(this.strokeStyle)
    const o = undefined
    const n = this.getPixelSizes().patternLength
    const l = this.strokeStyle.clip
    if (!l) {
      e.beginPath()
      i(t, e, s, r, n)
      this._stroke(e)
    } else {
      e.save()
      e.beginPath()
      if (l === Clipping.MASK.OUTER) wrapShapeCounterClockwise(e)
      i(t, e, s, r, n)
      e.clip('evenodd')
      e.lineCap = 'round'
      this._stroke(e)
      e.restore()
    }
  }
  _strokeViewPath(e, t, s) {
    if (isDash(this.strokeStyle)) {
      const r = undefined
      const i = this.getPixelSizes().patternLength
      e.beginPath()
      virtualClipAndAppendViewPath(e, t, s, i)
    }
    this._stroke(e)
  }
  _stroke(e) {
    const t = this.getPixelSizes()
    e.save()
    e.strokeStyle = this.strokeStyle.color
    e.lineWidth = t.width
    e.setLineDash(t.dash)
    e.lineDashOffset = t.dashOffset
    e.stroke()
    e.restore()
  }
  getPixelSizes() {
    if (this.strokeStyle.isWorldSize) {
      this._rescaleSizes()
      return this.rescaledSizes
    }
    return this.strokeStyle
  }
  _rescaleSizes() {
    if (
      this.worldSizeSupport &&
      this.worldSizeSupport.getCurrentScale() !== this.rescaledSizes.forMapScale
    )
      this.rescaledSizes = this.worldSizeSupport.getLineStyleRescaledSizes(
        this.strokeStyle
      )
  }
  _tracePathNoClose(e, t) {
    appendViewPathNoClose(e, t)
  }
}
