import { On } from '../../util/On.js'
import { KeyEvent } from './KeyEvent.js'
import { KeyEventType } from './KeyEventType.js'
import { ModifierType } from './ModifierType.js'
const KEY_TYPES = [
  { name: 'keyup', eventType: KeyEventType.UP },
  { name: 'keydown', eventType: KeyEventType.DOWN },
]
export class KeyboardHandler {
  constructor(e, t) {
    this._domListeners = []
    KEY_TYPES.forEach((o) => {
      const i = new KeyEvent(e, o.eventType, 'keyboard')
      const n = On(e, o.name, (e) => {
        i.domEvent = e
        i.modifier = modifier(e)
        const o = undefined
        if (t.onKeyEvent(i)) {
          e.preventDefault()
          e.stopPropagation()
        }
      })
      this._domListeners.push(n)
    })
  }
  destroy() {
    for (const e of this._domListeners) e.remove()
    this._domListeners = []
  }
}
function modifier(e) {
  let t = ModifierType.NO_MOD
  if (e.altKey) t += ModifierType.ALT
  if (e.ctrlKey) t += ModifierType.CTRL
  if (e.shiftKey) t += ModifierType.SHIFT
  return t
}
