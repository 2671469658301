import { buildCache } from '../util/Cacher.js'
import { Constants } from '../util/Constants.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { XYZPoint } from './XYZPoint.js'
import { XYZBounds } from './XYZBounds.js'
import * as XYZCircularArcUtil from './XYZCircularArcUtil.js'
import { CircularArcByCenterPoint } from './CircularArcByCenterPoint.js'
import { ShapeType } from './ShapeType.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { ellipticalDistance2D, forwardAzimuth2D } from '../util/Cartesian.js'
export class XYZCircularArcByCenterPoint extends CircularArcByCenterPoint {
  constructor(r, t, e, i, n) {
    super()
    this._reference = this.validateReference(r)
    if (t && t.type === ShapeType.POINT) {
      this._compareReferenceStrict(
        t.reference,
        'XYZCircularArcByCenterPoint::the arc reference must match the center point reference'
      )
      this._center = t.copy()
    } else
      throw new ProgrammingError(
        'XYZCircularArcByCenterPoint::cannot create an arc without a center point'
      )
    if ('number' !== typeof e)
      throw new ProgrammingError(
        'XYZCircularArcByCenterPoint::cannot create an arc without a radius'
      )
    this._radius = e
    if ('number' !== typeof i)
      throw new ProgrammingError(
        'XYZCircularArcByCenterPoint::cannot create an arc without a start azimuth'
      )
    this._startAzimuth = i
    if ('number' !== typeof n)
      throw new ProgrammingError(
        'XYZCircularArcByCenterPoint::cannot create an arc without an arc angle'
      )
    this._sweepAngle = n
    this._cache = buildCache()
  }
  get isGeodetic() {
    return false
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  get center() {
    return this._center
  }
  get focusPoint() {
    return this._center
  }
  get radius() {
    return this._radius
  }
  set radius(r) {
    if (r >= 0) {
      this._radius = r
      this.invalidate()
    } else
      throw new ProgrammingError(
        `Radius should be positive number, but was: ${r}`
      )
  }
  get startAzimuth() {
    return this._startAzimuth
  }
  set startAzimuth(r) {
    this._startAzimuth = r
    this.invalidate()
  }
  get sweepAngle() {
    return this._sweepAngle
  }
  set sweepAngle(r) {
    this._sweepAngle = r
    this.invalidate()
  }
  get bounds() {
    return this._cache.memoize('bounds', () => {
      const r = new XYZBounds(this.reference)
      XYZCircularArcUtil.boundsSFCT(this, r)
      return r
    })
  }
  get startPoint() {
    return this._cache.memoize('start-point', () =>
      calculatePointOnCircle(
        this.center,
        this.radius,
        this.startAzimuth,
        this.reference
      )
    )
  }
  get endPoint() {
    return this._cache.memoize('end-point', () =>
      calculatePointOnCircle(
        this.center,
        this.radius,
        this.startAzimuth + this.sweepAngle,
        this.reference
      )
    )
  }
  contains2DCoordinates(r, t) {
    return XYZCircularArcUtil.contains2D(this, r, t)
  }
  invalidate() {
    this._cache.invalidate()
  }
  copy() {
    return new XYZCircularArcByCenterPoint(
      this._reference,
      this._center.copy(),
      this._radius,
      this._startAzimuth,
      this._sweepAngle
    )
  }
  forwardAzimuth(r, t) {
    return Constants.RAD2DEG * forwardAzimuth2D(r, t)
  }
  contains3DCoordinates(r, t, e) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape XYZCircularArcByCenterPoint'
    )
  }
}
function calculatePointOnCircle(r, t, e, i) {
  const n = new XYZPoint(i)
  const o = ellipticalDistance2D(t, t, 90 - e)
  const a = (90 - e) * Constants.DEG2RAD
  n.move2DToCoordinates(r.x + o * Math.cos(a), r.y + o * Math.sin(a))
  return n
}
