import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { ExpressionValidator } from '../../util/expression/ExpressionValidator.js'
import { ExpressionResolver } from '../../util/expression/ExpressionResolver.js'
import { isNumber } from '../../util/Lang.js'
export class OutlineOcclusionStyleImpl {
  constructor(e, t, o, i) {
    this._expressionResolver = ExpressionResolver
    this._expressionValidator = new ExpressionValidator(
      this._expressionResolver
    )
    this._styleChangeHandler = e
    this._invalidator = t
    this._occlusionStyle = o || null
    if (this._occlusionStyle && this._occlusionStyle.bodyColorExpression)
      this.validateColorExpression(this._occlusionStyle.bodyColorExpression)
    this._outlineStyle = i || null
    this.validateOutlineStyle(this._outlineStyle)
    this.validateOutlineStyle(this._occlusionStyle?.outlineStyle)
  }
  validateOutlineStyle(e) {
    if (e) {
      if (e.outlineWidth) verifyOutlineWidth(e.outlineWidth)
      if (e.outlineColorExpression)
        this.validateColorExpression(e.outlineColorExpression)
    }
  }
  set outlineStyle(e) {
    this.validateOutlineStyle(e)
    this._outlineStyle = e
    this._styleChangeHandler()
  }
  get outlineStyle() {
    return this._outlineStyle
      ? this.getOutlineStyleWithStyleChange(this._outlineStyle)
      : null
  }
  get occlusionStyle() {
    if (this._occlusionStyle) {
      const e = this._occlusionStyle
      const t = this._occlusionStyle.outlineStyle
        ? this.getOutlineStyleWithStyleChange(this._occlusionStyle.outlineStyle)
        : null
      const o = this._styleChangeHandler
      const i = this.validateColorExpression.bind(this)
      const l = this.validateOutlineStyle.bind(this)
      return {
        get bodyColorExpression() {
          return e.bodyColorExpression || null
        },
        set bodyColorExpression(t) {
          if (t) i(t)
          e.bodyColorExpression = t
          o()
        },
        get outlineStyle() {
          return t
        },
        set outlineStyle(t) {
          l(t)
          e.outlineStyle = t
          o()
        },
      }
    }
    return null
  }
  set occlusionStyle(e) {
    this._occlusionStyle = e
    if (this._occlusionStyle && this._occlusionStyle.bodyColorExpression)
      this.validateColorExpression(this._occlusionStyle.bodyColorExpression)
    this._styleChangeHandler()
  }
  getOutlineStyleWithStyleChange(e) {
    const t = this._styleChangeHandler
    const o = this.validateColorExpression.bind(this)
    return {
      get outlineColorExpression() {
        return e.outlineColorExpression || null
      },
      set outlineColorExpression(i) {
        if (i) o(i)
        e.outlineColorExpression = i
        t()
      },
      get outlineWidth() {
        return e.outlineWidth || 1
      },
      set outlineWidth(o) {
        verifyOutlineWidth(o)
        e.outlineWidth = o
        t()
      },
    }
  }
  resolveExpression(e) {
    return this._expressionResolver.resolve(
      e,
      this._invalidator,
      null,
      void 0,
      () => {}
    )
  }
  setOutlineOcclusionStyle(e) {
    if (!e) return
    const t = new ObjectReleaseTracker()
    try {
      const o = this._outlineStyle?.outlineColorExpression || null
      const i =
        this._occlusionStyle?.outlineStyle?.outlineColorExpression || null
      const l = this._occlusionStyle?.bodyColorExpression || null
      const s = o ? t.track(this.resolveExpression(o)) : null
      const n = i ? t.track(this.resolveExpression(i)) : null
      const r = l ? t.track(this.resolveExpression(l)) : null
      const u = this._outlineStyle?.outlineWidth || 1
      const h = this._occlusionStyle?.outlineStyle?.outlineWidth || 1
      e.setOutlineOcclusionStyle(s, n, r, u, h)
    } finally {
      t.release()
    }
  }
  validateColorExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForColorExpression(e),
      'color'
    )
  }
}
function verifyOutlineWidth(e) {
  if (!isNumber(e) || e < 0)
    throw new ProgrammingError('The outline width should be a number >= 0.')
}
function handleErrorMessage(e, t) {
  if (e)
    throw new ProgrammingError(
      `There is a problem with the ${t}  expression:\n ${e}`
    )
}
