export class Deferred {
  constructor() {
    this._isResolved = false
    this._isRejected = false
    this._promise = new Promise((e, s) => {
      this._resolve = e
      this._reject = s
    })
  }
  get promise() {
    return this._promise
  }
  isResolved() {
    return this._isResolved
  }
  isRejected() {
    return this._isRejected
  }
  isSettled() {
    return this._isResolved || this._isRejected
  }
  resolve(e) {
    if (this.isSettled()) throw new Error('Promise is already settled')
    this._resolve && this._resolve(e)
  }
  reject(e) {
    if (this.isSettled()) throw new Error('Promise is already rejected')
    this._reject && this._reject(e)
  }
}
