import { XMLSchema } from '../../util/XMLSchema.js'
var attribute = XMLSchema.attribute,
  boolean = XMLSchema.boolean,
  number = XMLSchema.number,
  content = XMLSchema.content
var boundingBoxSchema = {
  X: content(Number),
  Y: content(Number),
  Width: content(Number),
  Height: content(Number),
}
export var LTSQuerySchema = {
  Metadata: {
    RasterCoverageMetadata: [
      {
        id: attribute(),
        updateSequence: attribute(),
        globalUpdateSequence: attribute(),
        Name: content(),
        Abstract: content(),
        Type: content(),
        Level0RowCount: content(number),
        Level0ColumnCount: content(number),
        BoundingBox: boundingBoxSchema,
        GeoReference: content(),
        Format: content(),
        AssetInfo: [{ Id: content(), Status: content() }],
        ScaleDenominator: [{ '#text_only': content(number) }],
        DataDensity: content(),
        MessageDigestAlgorithm: content(),
        IgnoreNonFatalFailures: content(boolean),
        AssetSourcesInTileStore: content(boolean),
        TileWidth: content(number),
        TileHeight: content(number),
        Parameter: [{ Id: content(), Name: content(), Unit: content() }],
        PreserveAssetOrder: content(boolean),
        SamplingMode: content(),
        MeasurementBand: {
          DataType2: content(),
          SignificantBits: content(number),
          UnitOfMeasure: { measureTypeCode: attribute() },
        },
      },
    ],
  },
}
