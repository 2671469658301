import { ShapeType } from '../../../shape/ShapeType.js'
import { EditContext } from '../../controller/EditContext.js'
import { Editor } from '../Editor.js'
import { CompositeEditHandle } from '../handles/CompositeEditHandle.js'
import { GeoBufferInvalidateHandle } from '../handles/GeoBufferInvalidateHandle.js'
import { GeoBufferWidthHandle } from '../handles/GeoBufferWidthHandle.js'
import { ShapeTranslateHandle } from '../handles/ShapeTranslateHandle.js'
import { getDefaultSize } from '../handles/CreateByTemplateHandle.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import { GeoBufferHelperHandle } from '../handles/helper/GeoBufferHelperHandle.js'
import { mergeMinMaxPointCount } from '../../controller/EditSettings.js'
import { HelperHandle } from '../handles/helper/HelperHandle.js'
class GeoBufferCreationHelperHandle extends HelperHandle {
  constructor(e) {
    super(e)
  }
  onDraw(e, a) {
    const t = this.getHelperStyle(a)
    if (t) {
      const n = a.shape
      e.drawShape(n.baseShape, t)
    }
  }
}
export class GeoBufferEditor extends Editor {
  constructor(e) {
    super()
    this._baseShapeEditor = e
  }
  get baseShapeEditor() {
    return this._baseShapeEditor
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.GEO_BUFFER, e.shape.type)
  }
  getEditHandles(e) {
    const a = e.shape
    const t = this.createWidthHandle(e)
    const n = this.createBaseShapeHandles(e)
    const r = new CompositeEditHandle(n, { cascading: false })
    const s = undefined
    const o = new GeoBufferInvalidateHandle(a, {
      handleDelegate: r,
      geoBuffersToInvalidate: [a],
      invalidateOnDeactivate: true,
      invalidateOnHandled: false,
    })
    const d = this.createHelperHandle(e)
    return removeNullHandles([o, t, d, this.createTranslateHandle(e)])
  }
  createTranslateHandle(e) {
    const a = e.shape
    const t = new ShapeTranslateHandle(a)
    const n = undefined
    return new GeoBufferInvalidateHandle(a, {
      handleDelegate: t,
      geoBuffersToInvalidate: [a],
      invalidateOnDeactivate: true,
      invalidateOnHandled: false,
    })
  }
  createBaseShapeHandles(e) {
    const a = e.shape
    const t = mergeMinMaxPointCount(e.settings, a.baseShape)
    const n = new EditContext(e.map, e.layer, e.feature, a.baseShape, t)
    if (this.baseShapeEditor.canEdit(n))
      return this.baseShapeEditor.getEditHandles(n)
    return []
  }
  createWidthHandle(e) {
    const a = e.shape
    return new GeoBufferWidthHandle(a)
  }
  createHelperHandle(e) {
    const a = e.shape
    return new GeoBufferHelperHandle(a)
  }
  getCreateHandle(e) {
    const a = { ...e.settings, freehand: false }
    const t = e.shape
    t.width = getDefaultSize(e.map) / 7.5
    const n = new EditContext(e.map, e.layer, e.feature, t.baseShape, a)
    const r = this.baseShapeEditor.getCreateHandle(n)
    const s = new GeoBufferCreationHelperHandle()
    const o = undefined
    return new CompositeEditHandle([r, s], { cascading: false })
  }
}
