import { normalizeAngle0To360 } from '../util/Cartesian.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { getReference } from '../reference/ReferenceProvider.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { Constants } from '../util/Constants.js'
const CRS84 = getReference('CRS:84')
const PREDICT_DISTANCE = 1e3
function pointAndAzimuthToWorldPointsSFCT(e, r, t, o, n) {
  const i = t.inputReference
  t.inverseTransformation.transform(e, o)
  const a = createTransformation(i, CRS84)
  const s = createPoint(CRS84, [0, 0])
  a.transform(o, s)
  const c = CRS84.geodeticDatum.ellipsoid
  const f = createPoint(CRS84, [0, 0])
  c.geodesicPositionSFCT(s, PREDICT_DISTANCE, r, f)
  f.move3D(f.x, f.y, s.z)
  a.inverseTransformation.transform(s, o)
  a.inverseTransformation.transform(f, n)
}
export function getMapAzimuth(e) {
  if (
    e.reference.referenceType === ReferenceType.CARTESIAN ||
    e.reference.referenceType === ReferenceType.GEOCENTRIC
  )
    return getMapRotation(e)
  const r = e.mapToViewTransformation
  let t = createPoint(e.reference, [])
  if (!e.is3D()) {
    t = r.toWorld(r.getViewCenter())
    t.z = 0
  } else {
    const r = createPoint(null, [e.viewSize[0] / 2, e.viewSize[1] / 2])
    try {
      t = e.viewToMapTransformation.transform(r)
    } catch (r) {
      OutOfBoundsError.isOrThrow(r)
      return getMapRotation(e)
    }
  }
  try {
    return getMapAzimuthAtOrientedPoint(t, 0, e)
  } catch (r) {
    OutOfBoundsError.isOrThrow(r)
    return getMapRotation(e)
  }
}
export function getMapAzimuthAtOrientedPoint(e, r, t) {
  return getAzimuthAtOrientedPoint(e, r, t.mapToViewTransformation, t.reference)
}
export function getAzimuthAtOrientedPoint(e, r, t, o) {
  const n = createTransformation(o, e.reference)
  const i = createPoint(o, [0, 0])
  const a = createPoint(o, [0, 0])
  pointAndAzimuthToWorldPointsSFCT(e, r, n, i, a)
  const s = t.transform(i)
  const c = t.transform(a)
  const f = undefined
  const m =
    (Math.atan2(c.y - s.y, c.x - s.x) + Constants.PI_DIV_2) * Constants.RAD2DEG
  return normalizeAngle0To360(m)
}
export function getMapRotation(e) {
  if (e.is3D()) return normalizeAngle0To360(-e.camera.asLookFrom().yaw)
  else return normalizeAngle0To360(-e.camera.asLook2D().rotation)
}
