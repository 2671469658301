import { Log } from '../../util/Log.js'
import { LRUCache } from '../../util/LRUCache.js'
import { encodeReference } from '../EncodedCoordinateReference.js'
import { PhotonReferenceFactory } from './PhotonReferenceFactory.js'
import { ReferenceType } from '../ReferenceType.js'
function PhotonReferenceProvider(e) {
  this._photon = e
  this._cache = new LRUCache(100)
  this._cache.onRemove = function (e, r) {
    r.photon.release()
  }
  this._photonReferenceFactory = new PhotonReferenceFactory(e)
}
PhotonReferenceProvider.prototype = Object.create(Object.prototype)
PhotonReferenceProvider.prototype.constructor = PhotonReferenceProvider
PhotonReferenceProvider.prototype.getReference = function (e) {
  var r = e.identifier
  var o = r ? r : e
  var t = this._cache.get(o)
  if (t && !e.equals(t.js)) {
    Log.warn(
      'Warning: reference identifier [' +
        r +
        '] or equality is not correct. ' +
        'The id is used by ' +
        e +
        ' and ' +
        t.js +
        ' but they are not equal.'
    )
    t = null
  }
  if (!t) {
    var n
    t = { js: e, photon: this._createReference(e) }
    this._cache.put(o, t)
  }
  return t.photon.copy()
}
PhotonReferenceProvider.prototype.isReferenceCached = function (e) {
  var r = e.identifier
  var o = r ? r : e
  var t = this._cache.get(o)
  return t && e.equals(t.js)
}
PhotonReferenceProvider.prototype._createReference = function (e) {
  if (!e)
    throw new Error(
      'PhotonReferenceProvider: Requested unsupported reference: ' + e
    )
  var r = this.encodeReference(e)
  return this._photonReferenceFactory.getReference(r)
}
PhotonReferenceProvider.prototype.encodeReference = function (e) {
  if (!e)
    throw new Error(
      'PhotonReferenceProvider: Requested unsupported reference: ' + e
    )
  var r = e.referenceType
  if (
    r !== ReferenceType.GEODETIC &&
    r !== ReferenceType.GEOCENTRIC &&
    r !== ReferenceType.GRID &&
    r !== ReferenceType.CARTESIAN
  )
    throw new Error(
      'PhotonReferenceProvider: Requested unsupported reference: ' +
        e +
        ' (id=' +
        e.identifier +
        ')'
    )
  return encodeReference(e)
}
PhotonReferenceProvider.prototype.isSupported = function (e) {
  var r
  try {
    return null !== (r = this.getReference(e))
  } catch (e) {
    return false
  } finally {
    if (r) r.release()
  }
}
PhotonReferenceProvider.prototype.release = function () {
  this._cache.clear()
  if (this._photonReferenceFactory) {
    this._photonReferenceFactory.release()
    this._photonReferenceFactory = null
  }
}
export { PhotonReferenceProvider }
