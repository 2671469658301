import { Constants } from '../util/Constants.js'
import { LLHPoint } from './LLHPoint.js'
import { ellipticalDistance2D, normalizeAngle } from '../util/Cartesian.js'
export function calculateBounds(t, o, e, n, i, c) {
  const s = 48
  const l = 360 / s
  let r = 0
  let a
  const D = t.meridionalArcDistance(o.y)
  const d = new LLHPoint()
  const u = 10001965.729312126
  let P = ellipticalDistance2D(e, n, 90 - i)
  t.geodesicPositionSFCT(o, P, r, d)
  c.move2DToPoint(d)
  c.width = 0
  c.height = 0
  if (P + D > u) {
    c.width = 360
    c.setToIncludePoint2D(new LLHPoint(null, [0, 90]))
  }
  r -= l
  for (a = 1; a < s / 2; a++) {
    P = ellipticalDistance2D(e, n, 90 - r - i)
    t.geodesicPositionSFCT(o, P, r, d)
    c.setToIncludePoint2D(d)
    r -= l
  }
  P = ellipticalDistance2D(e, n, 90 - r - i)
  t.geodesicPositionSFCT(o, P, r, d)
  c.setToIncludePoint2D(d)
  if (P - D < -u) {
    c.width = 360
    c.setToIncludePoint2D(new LLHPoint(null, [0, -90]))
  }
  r -= l
  for (a = s / 2 + 1; a <= s; a++) {
    P = ellipticalDistance2D(e, n, 90 - r - i)
    t.geodesicPositionSFCT(o, P, r, d)
    c.setToIncludePoint2D(d)
    r -= l
  }
  if (i % l != 0) {
    t.geodesicPositionSFCT(o, e, 90 - i, d)
    c.setToIncludePoint2D(d)
    t.geodesicPositionSFCT(o, n, 90 - i - 90, d)
    c.setToIncludePoint2D(d)
    t.geodesicPositionSFCT(o, e, 90 - i - 180, d)
    c.setToIncludePoint2D(d)
    t.geodesicPositionSFCT(o, n, 90 - i - 270, d)
    c.setToIncludePoint2D(d)
  }
}
export function contains2D(t, o, e, n, i, c, s) {
  const l = new LLHPoint(null, [c, s])
  const r = undefined
  const a = 90 - t.forwardAzimuth2D(o, l) * Constants.RAD2DEG
  return t.geodesicDistance(o, l) <= ellipticalDistance2D(e, n, a - i)
}
export function updatePoint(t, o, e, n) {
  const i = t.reference.geodeticDatum.ellipsoid
  const c = 360 * o
  const s = t.center.copy()
  s.move2D(e, n)
  const l = i.forwardAzimuth2D(t.center, s) * Constants.RAD2DEG
  t.rotationAzimuth = l - c
  const r = i.geodesicDistance(t.center, s)
  if (normalizeAngle(c) % 180 === 0) t.a = r
  else t.b = r
}
export function interpolate(t, o, e) {
  if (!e) e = t.center.copy()
  const [n, i] = interpolateLLHCoordinate(t, o)
  e.move2D(n, i)
  return e
}
export function interpolateLLHCoordinate(t, o) {
  let { center: e, a: n, b: i, reference: c, rotationAzimuth: s } = t
  const l = 360 * o
  const r = ellipticalDistance2D(n, i, l)
  const a = { x: 0, y: 0, z: e.z }
  c.geodeticDatum.ellipsoid.geodesicPositionSFCT(e, r, s + l, a)
  return [a.x, a.y, a.z]
}
