import {
  getPinEndPositionHugBorderNoRotationSFCT,
  getPinEndPositionMiddleNoRotationSFCT,
  pinPointMiddleOfBoundsOnEdgeUnrotatedSFCT,
} from './pin/PinUtil.js'
import { PinEndPosition } from './PinEndPosition.js'
import { Constants } from '../../util/Constants.js'
import { isDefined, isNumber } from '../../util/Lang.js'
import { CSSProps } from '../../util/CSSProps.js'
import { toPolar } from '../../util/Cartesian.js'
const TRANSFORMATION = CSSProps.transformation
const TRANSFORMATION_ORIGIN = CSSProps.transformationOrigin
export class Label {
  constructor(i, t, s, n) {
    const o = document.createElement('div')
    o.style.position = 'absolute'
    o.style.width = `${t}px`
    o.style.height = `${s}px`
    o.style[TRANSFORMATION_ORIGIN] = 'top left'
    if ('string' === typeof i) o.innerHTML = i
    else o.appendChild(i)
    this.div = o
    this.rdirty = true
    this.lrotation = 0
    this.pdirty = true
    this.px = 0
    this.py = 0
    this.pin = false
    if (isDefined(n)) {
      const i = document.createElement('div')
      i.style[TRANSFORMATION_ORIGIN] = 'top left'
      const t = i.style
      t.backgroundColor = n.color
      t.position = 'absolute'
      t.top = `${0}px`
      t.left = `${0}px`
      t.height = `${n.width}px`
      if (n.haloWidth > 0) t.border = `${n.haloWidth}px solid ${n.haloColor}`
      const s = document.createElement('div')
      s.style.position = 'absolute'
      s.style.zIndex = '-1'
      s.appendChild(i)
      this._topOffset = (n.width + (n.haloWidth || 0)) / 2
      this.pdirty = true
      this.pr = 0
      this.pa = 0
      this.cdirty = true
      this.cx = 0
      this.cy = 0
      this.pinInfo = {
        pinConnectDiv: s,
        pinDiv: i,
        isPinVisible: true,
        funcPinEnd:
          n.endPosition === PinEndPosition.MIDDLE
            ? getPinEndPositionMiddleNoRotationSFCT
            : n.endPosition === PinEndPosition.MIDDLE_BORDER
            ? pinPointMiddleOfBoundsOnEdgeUnrotatedSFCT
            : getPinEndPositionHugBorderNoRotationSFCT,
      }
      this.placePinInside(o)
      this.pin = true
    }
    this.scratch = {}
  }
  removeAndDestroy(i) {
    if (this.div) {
      try {
        i.removeChild(this.div)
      } catch (i) {}
      this.div.innerHTML = ''
      this.div = null
    }
  }
  placeLabelIn(i) {
    if (this.div) i.appendChild(this.div)
  }
  changePosition(i, t, s, n, o, e, h, r) {
    if (!this.div) return
    const p = this.div.style
    i = Math.round(i)
    t = Math.round(t)
    this.pdirty = this.pdirty || this.px !== i || this.py !== t
    if (this.pdirty) {
      p.left = `${i}px`
      p.top = `${t}px`
      this.pdirty = false
      this.px = i
      this.py = t
    }
    this.rdirty = this.rdirty || this.lrotation !== s
    if (this.rdirty) {
      p[TRANSFORMATION] = `rotate(${Math.round(s)}deg)`
      this.rdirty = false
      this.lrotation = s
    }
    if (isNumber(r)) p.zIndex = `${r}`
    if (this.pin && 0 === s) this.changePinPosition(i, t, n, o, e, h)
  }
  placePinInside(i) {
    if (this.pinInfo) i.appendChild(this.pinInfo.pinConnectDiv)
  }
  changePinPosition(i, t, s, n, o, e) {
    if (!this.pinInfo) return
    this.pinInfo.funcPinEnd(o, e, i, t, s, n, this.scratch)
    const h = this.scratch.success
    if (!h) {
      this.pinInfo.isPinVisible = false
      this.pinInfo.pinConnectDiv.style.visibility = 'hidden'
      return
    }
    if (!this.pinInfo.isPinVisible && h) {
      this.pinInfo.isPinVisible = true
      this.pinInfo.pinConnectDiv.style.visibility = 'visible'
    }
    const r = this.scratch.x - i
    const p = this.scratch.y - t
    this.cdirty = this.cdirty || this.cx !== r || this.cy !== p
    let d
    if (this.cdirty) {
      d = this.pinInfo.pinConnectDiv.style
      d.left = `${Math.round(r)}px`
      d.top = `${Math.round(p)}px`
      this.cx = r
      this.cy = p
      this.cdirty = false
    }
    const l = o - this.scratch.x
    const a = e - this.scratch.y
    toPolar(l, a, this.scratch)
    const c = this._topOffset
    this.pdirty =
      this.pdirty || this.pr !== this.scratch.r || this.pa !== this.scratch.a
    if (this.pdirty) {
      this.pinInfo.pinDiv.style.width = `${Math.round(this.scratch.r)}px`
      this.pinInfo.pinDiv.style[TRANSFORMATION] = `rotate(${Math.round(
        this.scratch.a * Constants.RAD2DEG
      )}deg)`
      if (l <= 0 && a <= 0) {
        this.pinInfo.pinDiv.style.top = `${c}px`
        this.pinInfo.pinDiv.style.left = `${-c}px`
      } else if (l <= 0 && a > 0) this.pinInfo.pinDiv.style.top = `${c}px`
      else if (l > 0 && a > 0) this.pinInfo.pinDiv.style.top = `${-c}px`
      else if (l > 0 && a <= 0) {
        this.pinInfo.pinDiv.style.top = `${-c}px`
        this.pinInfo.pinDiv.style.left = `${-c}px`
      }
      this.pr = this.scratch.r
      this.pa = this.scratch.a
      this.pdirty = false
    }
  }
}
