import { WKTName } from './WKTName.js'
export const SPACES = new RegExp(' ', 'g')
export const DASHES = new RegExp('-', 'g')
export const UNDERSCORES = new RegExp('_', 'g')
export const LINE_ENDINGS = new RegExp('[\r\n]', 'g')
export const LEFT_DELIMITER = '['
export const RIGHT_DELIMITER = ']'
export const COMMA = ','
export const QUOTE = '"'
export const TAG_GEOGCS = 'GEOGCS'
export const TAG_PROJCS = 'PROJCS'
export const TAG_GEOCCS = 'GEOCCS'
export const TAG_VERT_CS = 'VERT_CS'
export const TAG_COMPD_CS = 'COMPD_CS'
export const TAG_FITTED_CS = 'FITTED_CS'
export const TAG_LOCAL_CS = 'LOCAL_CS'
export const TAG_DATUM = 'DATUM'
export const TAG_PROJECTION = 'PROJECTION'
export const TAG_PARAMETER = 'PARAMETER'
export const TAG_SPHEROID = 'SPHEROID'
export const TAG_PRIMEM = 'PRIMEM'
export const TAG_AUTHORITY = 'AUTHORITY'
export const TAG_AXIS = 'AXIS'
export const TAG_TOWGS84 = 'TOWGS84'
export const TAG_UNIT = 'UNIT'
export const TAG_PARAM_MT = 'PARAM_MT'
export const TAG_VERT_DATUM = 'VERT_DATUM'
export const NAME_CONVERSION_FACTOR_METER = 'METER'
export const NAME_CONVERSION_FACTOR_DEGREE = 'DEGREE'
export const NAME_CONVERSION_FACTOR_DECIMAL_DEGREE = 'DECIMAL DEGREE'
export const NAME_PRIMEM_GREENWICH = 'GREENWICH'
export const VALUE_CONVERSION_FACTOR_METER = 1
export const VALUE_CONVERSION_FACTOR_DEGREE = 0.017453292519943295
export const VALUE_PRIMEM_GREENWICH = 0
export const REFERENCE_LAMBERT_BELGE_1972 = new WKTName([
  'Belge_Lambert_1972',
  'Belge_1972_/_Belge_Lambert_72',
  'Belge_1972_/_Belgian_Lambert_72',
])
export const REFERENCE_AMERSFOORT_RD_NEW = new WKTName([
  'Amersfoort_/_RD_New',
  'Amersfoort/RD_New',
])
export const PROJ_ALBERS_CONIC_EQUAL_AREA = new WKTName([
  'Albers_Equal_Area',
  'Albers_conic_equal-area',
  'Albers_conic_equal_area',
  'Albers',
])
export const PROJ_CASSINI_SOLDNER = new WKTName([
  'Cassini_Soldner',
  'Cassini',
  'Cassini-Soldner',
])
export const PROJ_EQUIDISTANT_CYLINDRICAL = new WKTName([
  'Equidistant_Cylindrical_(Spherical)',
])
export const PROJ_EQUIDISTANT_CYLINDRICAL_ELLIPSOIDAL = new WKTName([
  'Equirectangular',
  'Equidistant_Cylindrical',
])
export const PROJ_GAUSS_KRUGER = new WKTName(['Gauss_Kruger', 'Gauss-Kruger'])
export const PROJ_KROVAK = new WKTName([
  'Krovak',
  'KrovakNorthOrientated',
  'Krovak_(North_Orientated)',
])
export const PROJ_LAMBERT_AZIMUTHAL_EQUAL_AREA = new WKTName([
  'Lambert_Azimuthal_Equal_Area',
  'Lambert_Azimuthal_Equal_Area_(Spherical)',
])
export const PROJ_LAMBERT_CONFORMAL_CONIC_1SP = new WKTName([
  'Lambert_Conic_Conformal_1SP',
  'Lambert_Conic_Conformal_(1SP)',
])
export const PROJ_LAMBERT_CONFORMAL_CONIC_2SP = new WKTName([
  'Lambert_Conformal_Conic_2SP',
  'Lambert_ConformalConic',
  'Lambert_Conic_Conformal',
  'Lambert_Conic_Conformal_(2SP)',
  'Lambert_Conic_Conformal_2SP',
])
export const PROJ_LAMBERT_CONFORMAL_CONIC_2SP_BELGIUM = new WKTName([
  'Lambert_Conformal_Conic_2SP_Belgium',
  'Lambert_Conic_Conformal_(2SP_Belgium)',
  'LambertConicConformal2SPBelgium',
])
export const PROJ_MERCATOR_A = new WKTName([
  'Mercator_1SP',
  'Mercator',
  'Mercator_(1SP)',
  'Mercator_(variant_A)',
])
export const PROJ_MERCATOR_B = new WKTName([
  'Mercator_(variant_B)',
  'MercatorVariantB',
  'Mercator2SP',
])
export const PROJ_PSEUDOMERCATOR = new WKTName([
  'Mercator_(1SP)_(Spherical)',
  'Popular_Visualisation_Pseudo_Mercator',
])
export const PROJ_OBLIQUE_MERCATOR_A = new WKTName([
  'Hotine_Oblique_Mercator',
  'Hotine_Oblique_Mercator_Azimuth_Natural_Origin',
  'Hotine_Oblique_Mercator_(variant_A)',
])
export const PROJ_OBLIQUE_MERCATOR_B = new WKTName([
  'Oblique_Mercator',
  'Hotine_Oblique_Mercator_Azimuth_Center',
  'Hotine_Oblique_Mercator_(variant_B)',
])
export const PROJ_SWISS_OBLIQUE_MERCATOR = new WKTName([
  'Swiss_Oblique_Mercator',
])
export const PROJ_ORTHOGRAPHIC = new WKTName(['Orthographic', 'Local'])
export const PROJ_POLAR_STEREOGRAPHIC = new WKTName(['Polar_Stereographic'])
export const PROJ_POLAR_STEREOGRAPHIC_A = new WKTName([
  'Polar_Stereographic_(variant_A)',
])
export const PROJ_POLAR_STEREOGRAPHIC_B = new WKTName([
  'Polar_Stereographic_(variant_B)',
])
export const PROJ_POLAR_STEREOGRAPHIC_C = new WKTName([
  'Polar_Stereographic_(variant_C)',
])
export const PROJ_STEREOGRAPHIC_NORTH_POLE = new WKTName([
  'Stereographic_North_Pole',
])
export const PROJ_STEREOGRAPHIC_SOUTH_POLE = new WKTName([
  'Stereographic_South_Pole',
])
export const PROJ_POLYCONIC = new WKTName(['Polyconic', 'American_Polyconic'])
export const PROJ_STEREOGRAPHIC = new WKTName([
  'Stereographic',
  'Oblique_Stereographic',
])
export const PROJ_TRANSVERSE_MERCATOR = new WKTName(['Transverse_Mercator'])
export const PROJ_GNOMONIC = new WKTName(['Gnomonic'])
export const PROJ_AZIMUTHAL_EQUIDISTANT = new WKTName(['Azimuthal_Equidistant'])
export const PROJECTIONS = [
  PROJ_ALBERS_CONIC_EQUAL_AREA,
  PROJ_CASSINI_SOLDNER,
  PROJ_EQUIDISTANT_CYLINDRICAL,
  PROJ_EQUIDISTANT_CYLINDRICAL_ELLIPSOIDAL,
  PROJ_GAUSS_KRUGER,
  PROJ_KROVAK,
  PROJ_LAMBERT_AZIMUTHAL_EQUAL_AREA,
  PROJ_LAMBERT_CONFORMAL_CONIC_1SP,
  PROJ_LAMBERT_CONFORMAL_CONIC_2SP,
  PROJ_LAMBERT_CONFORMAL_CONIC_2SP_BELGIUM,
  PROJ_MERCATOR_A,
  PROJ_MERCATOR_B,
  PROJ_PSEUDOMERCATOR,
  PROJ_OBLIQUE_MERCATOR_A,
  PROJ_OBLIQUE_MERCATOR_B,
  PROJ_SWISS_OBLIQUE_MERCATOR,
  PROJ_ORTHOGRAPHIC,
  PROJ_POLAR_STEREOGRAPHIC,
  PROJ_POLAR_STEREOGRAPHIC_B,
  PROJ_POLAR_STEREOGRAPHIC_C,
  PROJ_STEREOGRAPHIC_NORTH_POLE,
  PROJ_STEREOGRAPHIC_SOUTH_POLE,
  PROJ_POLYCONIC,
  PROJ_STEREOGRAPHIC,
  PROJ_TRANSVERSE_MERCATOR,
  PROJ_GNOMONIC,
  PROJ_AZIMUTHAL_EQUIDISTANT,
]
export const PARAM_STANDARD_PARALLEL_1 = new WKTName([
  'standard_parallel_1',
  'latitude_of_1st_standard_parallel',
  'latitude_of_standard_parallel',
])
export const PARAM_STANDARD_PARALLEL_2 = new WKTName([
  'standard_parallel_2',
  'latitude_of_2nd_standard_parallel',
])
export const PARAM_PSEUDO_STANDARD_PARALLEL_1 = new WKTName([
  'pseudo_standard_parallel_1',
  'latitude_of_pseudo_standard_parallel',
  'PseudoStandardParallel',
  'LatitudeOfPseudoStandardParallel',
])
export const PARAM_LATITUDE_OF_ORIGIN = new WKTName([
  'latitude_of_origin',
  'latitude_of_natural_origin',
  'latitude_of_false_origin',
  'spherical_latitude_of_origin',
])
export const PARAM_CENTRAL_MERIDIAN = new WKTName([
  'longitude_of_origin',
  'longitude_of_natural_origin',
  'longitude_of_false_origin',
  'spherical_longitude_of_origin',
  'central_meridian',
  'initial_longitude',
])
export const PARAM_FALSE_EASTING = new WKTName([
  'false_easting',
  'easting_at_false_origin',
])
export const PARAM_FALSE_NORTHING = new WKTName([
  'false_northing',
  'northing_at_false_origin',
])
export const PARAM_EASTING_AT_PROJECTION_CENTER = new WKTName([
  'easting_at_projection_centre',
])
export const PARAM_NORTHING_AT_PROJECTION_CENTER = new WKTName([
  'northing_at_projection_centre',
])
export const PARAM_PARAMETER_COLATITUDE_OF_CONE_AXIS = new WKTName([
  'co-latitude_of_cone_axis',
  'co-latitude of cone axis',
  'CoLatitudeOfConeAxis',
])
export const PARAM_AZIMUTH = new WKTName(['azimuth', 'azimuth_of_initial_line'])
export const PARAM_LATITUDE_OF_CENTER = new WKTName([
  'latitude_of_center',
  'latitude_of_projection_centre',
])
export const PARAM_LONGITUDE_OF_CENTER = new WKTName([
  'longitude_of_center',
  'longitude_of_projection_centre',
])
export const PARAM_SCALE_FACTOR = new WKTName([
  'scale_factor',
  'scale_factor_at_natural_origin',
  'scale_factor_on_initial_line',
  'scale_factor_on_pseudo_standard_parallel',
])
export const PARAM_LONGITUDE_OF_POINT_1 = new WKTName(['longitude_of_point_1'])
export const PARAM_LATITUDE_OF_POINT_1 = new WKTName(['latitude_of_point_1'])
export const PARAM_LONGITUDE_OF_POINT_2 = new WKTName(['longitude_of_point_2'])
export const PARAM_LATITUDE_OF_POINT_2 = new WKTName(['latitude_of_point_2'])
export const PARAM_LINEAR_UNIT = new WKTName(['linear_unit'])
export const PARAM_ANGULAR_UNIT = new WKTName(['angular_unit'])
export const PARAM_PRIMEM_NAME = new WKTName(['prime_meridian_name'])
export const PARAM_PRIMEM_LONGITUDE = new WKTName(['prime_meridian_longitude'])
export const PARAM_RECTIFIED_GRID_ANGLE = new WKTName([
  'rectified_grid_angle',
  'recitified_grid_angle',
  'angle_from_rectified_to_skew_grid',
])
export const PARAM_ZONE_WIDTH = new WKTName(['zone_width'])
export const PARAM_NUM_ROW = new WKTName(['num_row'])
export const PARAM_NUM_COL = new WKTName(['num_col'])
export const PARAMETERS = [
  PARAM_STANDARD_PARALLEL_1,
  PARAM_STANDARD_PARALLEL_2,
  PARAM_PSEUDO_STANDARD_PARALLEL_1,
  PARAM_LATITUDE_OF_ORIGIN,
  PARAM_CENTRAL_MERIDIAN,
  PARAM_FALSE_EASTING,
  PARAM_FALSE_NORTHING,
  PARAM_EASTING_AT_PROJECTION_CENTER,
  PARAM_NORTHING_AT_PROJECTION_CENTER,
  PARAM_AZIMUTH,
  PARAM_LATITUDE_OF_CENTER,
  PARAM_LONGITUDE_OF_CENTER,
  PARAM_SCALE_FACTOR,
  PARAM_LONGITUDE_OF_POINT_1,
  PARAM_LATITUDE_OF_POINT_1,
  PARAM_LONGITUDE_OF_POINT_2,
  PARAM_LATITUDE_OF_POINT_2,
  PARAM_LINEAR_UNIT,
  PARAM_ANGULAR_UNIT,
  PARAM_PRIMEM_NAME,
  PARAM_PRIMEM_LONGITUDE,
  PARAM_PARAMETER_COLATITUDE_OF_CONE_AXIS,
  PARAM_RECTIFIED_GRID_ANGLE,
  PARAM_ZONE_WIDTH,
  PARAM_NUM_ROW,
  PARAM_NUM_COL,
]
export const EGM96_ALIASES = ['Egm96', 'Egm_96', 'Egm 96']
export function getParameterName(_) {
  for (let e = 0; e < PARAMETERS.length; e++) {
    const t = PARAMETERS[e]
    if (t.isEquivalentTo(_)) return t
  }
  return new WKTName([_])
}
export function getProjectionName(_) {
  for (let e = 0; e < PROJECTIONS.length; e++) {
    const t = PROJECTIONS[e]
    if (t.isEquivalentTo(_)) return t
  }
  return new WKTName([_])
}
