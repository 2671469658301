import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { SphericalObliqueMercator } from './SphericalObliqueMercator.js'
import { ProjectionType } from './ProjectionType.js'
import { ObliqueCylindrical } from './ObliqueCylindrical.js'
const sharedOutOfBoundsError = new OutOfBoundsError('SwissObliqueMercator')
class CachedValues {
  constructor(t, a) {
    this.fR = 0
    this.fAlpha = 0
    this.fBeta0 = 0
    this.fSinBeta0 = 0
    this.fCosBeta0 = 0
    this.fK = 0
    this.fA = t.a
    this.fE = t.e
    const e = this.fE * this.fE
    const s = 1 - e
    const n = a.getStandardParallel() * Constants.DEG2RAD
    const i = Math.sin(n)
    const o = Math.cos(n)
    const r = o * o
    const h = this.fE * i
    this.fR = (this.fA * Math.sqrt(s)) / (1 - h * h)
    this.fAlpha = Math.sqrt(1 + (e * r * r) / s)
    this.fSinBeta0 = i / this.fAlpha
    this.fBeta0 = Math.asin(this.fSinBeta0)
    this.fCosBeta0 = Math.cos(this.fBeta0)
    this.fK =
      Math.log(Math.tan(Math.PI / 4 + this.fBeta0 / 2)) -
      this.fAlpha * Math.log(Math.tan(Math.PI / 4 + n / 2)) +
      (this.fAlpha * this.fE * Math.log((1 + h) / (1 - h))) / 2
  }
}
export class SwissObliqueMercator extends ObliqueCylindrical {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let a = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this.fSphericalObliqueMercator = new SphericalObliqueMercator(t, a, 90)
    this.setCentralMeridian(t)
    this.setStandardParallel(a)
    this.setAzimuth(90)
    this.fCachedValues = null
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    this.fCachedValues = null
  }
  isAllInBounds() {
    return this.fSphericalObliqueMercator.isAllInBounds()
  }
  geodetic2cartesianOnSphereSFCT(t, a, e) {
    return this.fSphericalObliqueMercator.geodetic2cartesianOnSphereSFCT(
      t,
      a,
      e
    )
  }
  cartesian2geodeticOnSphereSFCT(t, a, e) {
    this.fSphericalObliqueMercator.cartesian2geodeticOnSphereSFCT(t, a, e)
  }
  inLonLatBounds(t) {
    return this.fSphericalObliqueMercator.inLonLatBounds(t)
  }
  inWorldBoundsOnSphere(t, a) {
    return this.fSphericalObliqueMercator.inWorldBoundsOnSphere(t, a)
  }
  cartesianBoundsOnSphereSFCT(t, a) {
    this.fSphericalObliqueMercator.cartesianBoundsOnSphereSFCT(t, a)
  }
  boundaryLons(t) {
    return this.fSphericalObliqueMercator.boundaryLons(t)
  }
  boundaryLats(t) {
    return this.fSphericalObliqueMercator.boundaryLats(t)
  }
  geodetic2cartesianOnEllipsoidSFCT(t, a, e) {
    if (!this.inLonLatBounds(t)) throw sharedOutOfBoundsError
    const s = a.e
    const n = this.getCachedValues(a)
    const i = t.y * Constants.DEG2RAD
    const o = undefined
    const r = s * Math.sin(t.y * Constants.DEG2RAD)
    const h =
      -n.fAlpha * Math.log(Math.tan(Math.PI / 4 - i / 2)) -
      (n.fAlpha * s * Math.log((1 + r) / (1 - r))) / 2 +
      n.fK
    const l = 2 * (Math.atan(Math.exp(h)) - Math.PI / 4)
    const c =
      n.fAlpha *
      normalizeLon(t.x - this.getCentralMeridian()) *
      Constants.DEG2RAD
    const u = Math.sin(c)
    const f = Math.cos(c)
    const M = Math.sin(l)
    const d = Math.cos(l)
    const p = Math.atan2(u, n.fSinBeta0 * Math.tan(l) + n.fCosBeta0 * f)
    const O = Math.asin(n.fCosBeta0 * M - n.fSinBeta0 * d * f)
    const S = Math.sin(O)
    if (Math.abs(Math.abs(M) - 1) < 1e-6) throw sharedOutOfBoundsError
    const C = n.fR * p
    const B = (n.fR * Math.log((1 + S) / (1 - S))) / 2
    e.move2D(C, B)
  }
  cartesian2geodeticOnEllipsoidSFCT(t, a, e) {
    if (!this.inWorldBoundsOnEllipsoid(t, a)) throw sharedOutOfBoundsError
    const s = this.getCachedValues(a)
    const n = t.y
    const i = undefined
    const o = t.x / s.fR
    const r = 2 * (Math.atan(Math.exp(n / s.fR)) - Math.PI / 4)
    const h = Math.sin(r)
    const l = Math.cos(r)
    const c = Math.cos(o)
    const u = Math.sin(o)
    const f = Math.asin(s.fCosBeta0 * h + s.fSinBeta0 * l * c)
    const M = Math.atan2(u, s.fCosBeta0 * c - (s.fSinBeta0 * h) / l)
    let d = f
    const p = (Math.log(Math.tan(Math.PI / 4 + f / 2)) - s.fK) / s.fAlpha
    let O = Number.MAX_VALUE
    let S = 0
    do {
      const t = s.fE * Math.sin(d)
      const a = p + s.fE * Math.log(Math.tan(Math.PI / 4 + Math.asin(t) / 2))
      const e = 2 * Math.atan(Math.exp(a)) - Math.PI / 2
      O = e - d
      d = e
      S++
    } while (Math.abs(O) > 1e-12 && S <= 10)
    const C = normalizeLon(
      this.getCentralMeridian() + (M / s.fAlpha) * Constants.RAD2DEG
    )
    const B = d * Constants.RAD2DEG
    e.move2D(C, B)
  }
  toString() {
    return `Swiss_Oblique_Mercator_${this.getCentralMeridian()}_${this.getStandardParallel()}_${this.getAzimuth()}`
  }
  getCachedValues(t) {
    let a = this.fCachedValues
    if (null == a || a.fA !== t.a || a.fE !== t.e) {
      a = new CachedValues(t, this)
      this.fCachedValues = a
    }
    return a
  }
  encode() {
    return {
      type: 'SwissObliqueMercator',
      centralMeridian: this.getCentralMeridian(),
      standardParallel: this.getStandardParallel(),
      azimuth: this.getAzimuth(),
    }
  }
  get TYPE() {
    return (
      ProjectionType.SWISS_OBLIQUE_MERCATOR +
      ProjectionType.OBLIQUECYLINDRICAL +
      ProjectionType.MERCATOR
    )
  }
}
