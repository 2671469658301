import { GestureEventImpl } from './GestureEvent.js'
import { GestureEventType } from './GestureEventType.js'
export class PinchEventImpl extends GestureEventImpl {
  _scaleFactor = 1
  _scaleFactorFromStart = 1
  constructor(t, e) {
    super(t, GestureEventType.PINCH, e)
  }
  get scaleFactor() {
    return this._scaleFactor
  }
  set scaleFactor(t) {
    this._scaleFactor = t
  }
  get scaleFactorFromStart() {
    return this._scaleFactorFromStart
  }
  set scaleFactorFromStart(t) {
    this._scaleFactorFromStart = t
  }
  copy() {
    const t = new PinchEventImpl(super.map, this.inputType)
    for (const e in this) if (this.hasOwnProperty(e)) t[e] = this[e]
    return t
  }
}
