import { NotImplementedError } from '../../error/NotImplementedError.js'
function AdvancedBinaryTopologyUtil() {}
AdvancedBinaryTopologyUtil.prototype = Object.create(Object.prototype)
AdvancedBinaryTopologyUtil.prototype.constructor = AdvancedBinaryTopologyUtil
AdvancedBinaryTopologyUtil.prototype.insidePointPolygon = function (o, t) {
  throw new NotImplementedError()
}
AdvancedBinaryTopologyUtil.prototype.insidePolygonPolygon = function (o, t) {
  var n = t.bounds
  var r
  for (r = 0; r < o.pointCount; r++)
    if (!o.getPoint(r).bounds.interacts2D(n)) return false
  var e = false
  var i = null
  r = 0
  while (!e && r < o.pointCount) {
    e = true
    i = o.getPoint(r++)
    for (var l = 0; l < t.pointCount; l++)
      if (t.getPoint(l).x === i.x && t.getPoint(l).y === i.y) {
        e = false
        break
      }
  }
  if (!e) return true
  return this.insidePointPolygon(i, t)
}
export { AdvancedBinaryTopologyUtil }
