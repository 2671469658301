export function quadInOut(t) {
  if ((t *= 2) < 1) return Math.pow(t, 2) / 2
  return (-1 * (--t * (t - 2) - 1)) / 2
}
export function linear(t) {
  return t
}
export function smoothStep(t) {
  return t * t * (3 - 2 * t)
}
export function easeInQuad(t) {
  return t * t
}
export function easeOutQuad(t) {
  return t * (2 - t)
}
export function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
}
export function easeInCubic(t) {
  return t * t * t
}
export function easeOutCubic(t) {
  return --t * t * t + 1
}
export function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}
export function easeInQuart(t) {
  return t * t * t * t
}
export function easeOutQuart(t) {
  return 1 - --t * t * t * t
}
export function easeInOutQuart(t) {
  return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
}
export function easeInQuint(t) {
  return t * t * t * t * t
}
export function easeOutQuint(t) {
  return 1 + --t * t * t * t * t
}
export function easeInOutQuint(t) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
}
export function expOut(t) {
  return 1 === t ? 1 : -Math.exp(-3 * t + 0.03) + 1.03
}
const NB_SAMPLES = 200
const SPLINE_POSITION = new Array(NB_SAMPLES + 1)
const START_TENSION = 0.5
const INFLEXION = 0.15
const END_TENSION = 1
const P1 = START_TENSION * INFLEXION
const P2 = 1 - END_TENSION * (1 - INFLEXION)
let x_min = 0
for (let t = 0; t < NB_SAMPLES; t++) {
  const n = t / NB_SAMPLES
  let e = 1
  let r, o, u
  while (true) {
    r = x_min + (e - x_min) / 2
    u = 3 * r * (1 - r)
    o = u * ((1 - r) * P1 + r * P2) + r * r * r
    if (Math.abs(o - n) < 1e-5) break
    if (o > n) e = r
    else x_min = r
  }
  SPLINE_POSITION[t] = u * ((1 - r) * START_TENSION + r) + r * r * r
}
SPLINE_POSITION[NB_SAMPLES] = 1
export function splineDistanceAndVelocity(t) {
  const n = Math.floor(NB_SAMPLES * t)
  if (n < NB_SAMPLES) {
    const e = n / NB_SAMPLES
    const r = (n + 1) / NB_SAMPLES
    const o = SPLINE_POSITION[n]
    const u = undefined
    const i = (SPLINE_POSITION[n + 1] - o) / (r - e)
    const c = undefined
    return { velocityCoef: i, distanceCoef: o + (t - e) * i }
  }
  return { velocityCoef: t, distanceCoef: t }
}
export function spline(t) {
  return splineDistanceAndVelocity(t).distanceCoef
}
