export class ClipPolygonUtil {
  constructor() {
    this.paths = [[]]
    this.pathData = { length: 0, coordsPerPoint: 3, pathLengths: [] }
    this._left = 1 / 0
    this._right = -1 / 0
    this._bottom = 1 / 0
    this._top = -1 / 0
    this._edge = 'top'
    this._lastX = 0
    this._lastY = 0
    this._createNewSubPath = false
    this._indexSubPath = -1
    this._lastInside = false
  }
  _addPoint(t, s) {
    if (this._createNewSubPath) {
      if ('undefined' === typeof this.paths[this._indexSubPath])
        this.paths[this._indexSubPath] = []
      this.pathData.length += 1
      this.pathData.pathLengths[this._indexSubPath] = 0
      this._createNewSubPath = false
    }
    const i = this.pathData.pathLengths[this._indexSubPath]
    this.paths[this._indexSubPath][i] = t
    this.paths[this._indexSubPath][i + 1] = s
    this.paths[this._indexSubPath][i + 2] = 0
    this.pathData.pathLengths[this._indexSubPath] += 3
  }
  rewind() {
    this.pathData.length = 0
    this.pathData.pathLengths = []
    this._indexSubPath = -1
    this._createNewSubPath = false
    this.paths = []
  }
  clearPath() {
    this._lastX = 0
    this._lastY = 0
    this._requestedMoveTo = true
  }
  getPaths() {
    return this.paths
  }
  closePolygon() {
    if (0 === this.pathData.length) return
    const t = this.pathData.pathLengths[this._indexSubPath]
    if (t < 6) return
    const s = t - 3
    const i = this.paths[this._indexSubPath]
    if (i[0] != i[s] || i[1] != i[s + 1]) this._addPoint(i[0], i[1])
  }
  moveTo(t, s) {
    this._lastX = t
    this._lastY = s
    this._lastInside = this._isInside(t, s)
    this._requestedMoveTo = true
  }
  _moveTo(t, s) {
    this._indexSubPath += 1
    this._createNewSubPath = true
    this._requestedMoveTo = false
    this._addPoint(t, s)
  }
  lineTo(t, s) {
    const i = this._isInside(t, s)
    if (i)
      if (this._lastInside) {
        if (this._requestedMoveTo) this._moveTo(this._lastX, this._lastY)
        this._addPoint(t, s)
      } else {
        this._intersectWithEdgeSFCT(
          this._lastX,
          this._lastY,
          t,
          s,
          intersection
        )
        if (this._requestedMoveTo) this._moveTo(intersection.x, intersection.y)
        else this._addPoint(intersection.x, intersection.y)
        this._addPoint(t, s)
      }
    else if (this._lastInside) {
      if (this._requestedMoveTo) this._moveTo(this._lastX, this._lastY)
      this._intersectWithEdgeSFCT(this._lastX, this._lastY, t, s, intersection)
      this._addPoint(intersection.x, intersection.y)
    }
    this._lastInside = i
    this._lastX = t
    this._lastY = s
  }
  setEdge(t) {
    this._edge = t
  }
  setClippingBounds(t) {
    this.setClippingBoundsXWidthYHeight(t.x, t.width, t.y, t.height)
  }
  setClippingBoundsXWidthYHeight(t, s, i, h) {
    this._left = t
    this._bottom = i
    this._top = this._bottom + h
    this._right = this._left + s
  }
  _isInside(t, s) {
    switch (this._edge) {
      case 'left':
        return t > this._left
      case 'right':
        return t <= this._right
      case 'bottom':
        return s > this._bottom
      case 'top':
        return s <= this._top
    }
    return false
  }
  _intersectWithEdgeSFCT(t, s, i, h, e) {
    const a = (h - s) / (i - t)
    const n = (i - t) / (h - s)
    let _ = 0
    let o = 0
    let r = 0
    switch (this._edge) {
      case 'left':
        r++
        _ = this._left
        o = a * (this._left - t) + s
        break
      case 'right':
        r++
        _ = this._right
        o = a * (this._right - t) + s
        break
      case 'bottom':
        r++
        o = this._bottom
        _ = n * (this._bottom - s) + t
        break
      case 'top':
        r++
        o = this._top
        _ = n * (this._top - s) + t
        break
    }
    e.x = _
    e.y = o
  }
}
const intersection = { x: 0, y: 0 }
