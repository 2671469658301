import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
import { Photon } from '../../../gen/photon/photon_painter.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { createBounds } from '../../../shape/ShapeFactory.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { isIE } from '../../../util/Browser.js'
import { isNumber } from '../../../util/Lang.js'
import { Log } from '../../../util/Log.js'
import { ObjectReleaseTracker } from '../../../util/ObjectReleaseTracker.js'
import { createTransformation } from '../../../transformation/TransformationFactory.js'
import * as LayerUtil from '../../LayerUtil.js'
import { PickInfoImpl } from '../../PickInfo.js'
import { PickRequest } from '../../PickRequest.js'
import { PhotonShapePainterUtil } from './PhotonShapePainterUtil.js'
import { PhotonIconCommand } from './command/PhotonIconCommand.js'
import { PhotonLineAndAreaCommand } from './command/PhotonLineAndAreaCommand.js'
import { PhotonTrajectoryCommand } from './command/PhotonTrajectoryCommand.js'
import { PhotonPlotCommand } from './command/PhotonPlotCommand.js'
import { PhotonDrawCommandProxy } from './PhotonDrawCommandProxy.js'
import { CollectingGeoAndLabelCanvas } from './CollectingGeoAndLabelCanvas.js'
import { PhotonImageProvider } from './PhotonImageProvider.js'
import { Photon3DIconCommand } from './command/Photon3DIconCommand.js'
import { PhotonMeshProvider } from './PhotonMeshProvider.js'
import { PhotonPanoramaCommand } from './command/PhotonPanoramaCommand.js'
import { isPromise } from '../../../util/PromiseUtil.js'
import { StyleUtil } from '../../style/StyleUtil.js'
import { PaintRepresentation } from '../../PaintRepresentation.js'
import { isOfShapeType } from '../../../shape/Shape.js'
import { DrawNullLabel } from '../../style/DrawNullLabel.js'
var AnchorSettings = Photon.AnchorSettings
var OptionalRiaBox = Photon.OptionalRiaBox
const PICKINFO_RECORD_SIZE = 8
const REGULAR_RENDER_PASS_ID = 0
const DENSITY_RENDER_PASS_ID = 1
const SELECTED_RENDER_PASS_ID = 2
const WhenPromiseScheduler = {
  when: function (e, t) {
    return Promise.resolve(e).then(t)
  },
  schedule: () => {},
  start: () => {},
  isBusy: () => false,
  release: () => {},
}
function asPickInfoBuffer(e) {
  return {
    objectId: (t) => e[t * PICKINFO_RECORD_SIZE],
    geometryId: (t) => e[t * PICKINFO_RECORD_SIZE + 1],
    styleId: (t) => e[t * PICKINFO_RECORD_SIZE + 2],
    distanceToEdge: (t) => e[t * PICKINFO_RECORD_SIZE + 3],
    distanceToCenter: (t) => e[t * PICKINFO_RECORD_SIZE + 4],
    depth: (t) => e[t * PICKINFO_RECORD_SIZE + 5],
    zOrder: (t) => e[t * PICKINFO_RECORD_SIZE + 6],
    draped: (t) => 1 === e[t * PICKINFO_RECORD_SIZE + 7],
  }
}
const DIFFWANTEDITEM_RECORD_SIZE = 2
function asDiffWantedItemBuffer(e) {
  return {
    set: (t, r, n) => {
      e[t * DIFFWANTEDITEM_RECORD_SIZE] = r
      e[t * DIFFWANTEDITEM_RECORD_SIZE + 1] = n
    },
  }
}
const DIFFITEM_RECORD_SIZE = 6
const DIFF_ACTION_NOOP = 0
const DIFF_ACTION_REMOVE = 1
const DIFF_ACTION_STYLE = 2
const DIFF_ACTION_REPLACE = 3
const DIFF_ACTION_GEOMETRY = 4
const DIFF_ACTION_ADD = 5
function asDiffItemBuffer(e) {
  return {
    oldGeometryId: (t) => e[t * DIFFITEM_RECORD_SIZE],
    oldStyleId: (t) => e[t * DIFFITEM_RECORD_SIZE + 1],
    newGeometryId: (t) => e[t * DIFFITEM_RECORD_SIZE + 2],
    newStyleId: (t) => e[t * DIFFITEM_RECORD_SIZE + 3],
    action: (t) => e[t * DIFFITEM_RECORD_SIZE + 4],
    wantedItemIdx: (t) => e[t * DIFFITEM_RECORD_SIZE + 5],
  }
}
class ShareBuffer {
  _share = new Map()
  constructor() {
    this._share.set('diffwanted', { buffer: null, counter: 0 })
    this._share.set('diffitems', { buffer: null, counter: 0 })
    this._share.set('pickinfos', { buffer: null, counter: 0 })
  }
  getOrMake(e, t) {
    const r = this._share.get(e)
    if (r.buffer) {
      r.counter++
      return r.buffer
    }
    r.buffer = Photon.BufferFactory.createUint32BufferFromLength(t)
    const n = r.buffer.release
    r.buffer.release = () => {
      const t = this._share.get(e)
      if (0 === --t.counter) {
        n.call(t.buffer)
        t.buffer = null
      }
    }
    r.counter = 1
    return r.buffer
  }
}
const SHARED_BUFFERS = new ShareBuffer()
const getDiffWantedBuffer = () =>
  SHARED_BUFFERS.getOrMake('diffwanted', 1e4 * DIFFWANTEDITEM_RECORD_SIZE)
const getDiffItemsBuffer = () =>
  SHARED_BUFFERS.getOrMake('diffitems', 1e4 * DIFFITEM_RECORD_SIZE)
const getPickInfosBuffer = () =>
  SHARED_BUFFERS.getOrMake('pickinfos', 3e4 * PICKINFO_RECORD_SIZE)
function inlineAsync(e, t, r) {
  if (isPromise(e)) return t.when(e, r)
  else return r(e)
}
export class PhotonShapePainter extends ObjectReleaseTracker {
  constructor(e, t, r, n, o, s, i, a, h) {
    let l = arguments.length > 9 && void 0 !== arguments[9] ? arguments[9] : {}
    super()
    this._layer = e
    this._photonView = t
    this._photonGraphics = n
    this._label = LayerUtil.getLabel(e, '')
    this._shapePainter =
      l.shapePainter ||
      this.track(
        Photon.ShapePainter.create(
          t,
          n,
          LayerUtil.formatLabelForPhoton(this._label)
        )
      )
    if (isNumber(l.maxGLLineWidth, true))
      this._shapePainter.setMaxGLLineWidth(0 | l.maxGLLineWidth)
    this._shapePainter.setRegularRenderPass(REGULAR_RENDER_PASS_ID)
    this._shapePainter.setRegularRenderPass(SELECTED_RENDER_PASS_ID)
    this._objectIdCounter = 1
    this._objectIdMap = new Map()
    this._featureMap = new Map()
    this._editedFeatureID = null
    this._worldReference = r
    this._featurePainter = null
    this._density = false
    this._photonGeoCanvas = new CollectingGeoAndLabelCanvas({
      worldReference: r,
    })
    this._geoCanvas = this._photonGeoCanvas
    this._stateObject = { selected: false, hovered: false, level: 0 }
    this._imageProvider =
      l.imageProvider ||
      this.track(new PhotonImageProvider(o, StyleUtil.getFallbackIconUrl))
    this._meshProvider =
      l.meshProvider ||
      this.track(
        new PhotonMeshProvider(this._shapePainter, this._imageProvider, a, n, i)
      )
    const d = this._worldReference.referenceType === ReferenceType.GEOCENTRIC
    this._is3d = d
    const c = this._shapePainter.getMaxGLLineWidth()
    const f = true === l?.canUseEarcut
    const u = true === l?.noDiscretization
    this._commands = [
      this.track(
        new PhotonIconCommand(
          d,
          r,
          this._shapePainter,
          this._imageProvider,
          e._map.worldSizeSupport
        )
      ),
      this.track(
        new PhotonPlotCommand(d, r, this._shapePainter, i, this._imageProvider)
      ),
      this.track(
        new PhotonLineAndAreaCommand(
          d,
          r,
          this._shapePainter,
          this._label,
          c,
          s,
          this._imageProvider,
          e._map.worldSizeSupport,
          h,
          f,
          u
        )
      ),
      this.track(
        new Photon3DIconCommand(d, r, this._shapePainter, this._meshProvider)
      ),
      this.track(
        new PhotonTrajectoryCommand(d, r, this._shapePainter, this._label, c, i)
      ),
      this.track(
        new PhotonPanoramaCommand(d, r, t, n, this._imageProvider, e, i)
      ),
    ]
    this._noShapePainterCommands = this._commands.filter(
      (e) => !e.usesPhotonShapePainter()
    )
    this._diffWantedItemsBuffer = this.track(getDiffWantedBuffer())
    this._diffItemsBuffer = this.track(getDiffItemsBuffer())
    this._pickInfosBuffer = this.track(getPickInfosBuffer())
    this._labelWithoutBodyCanvas = {
      drawLabel: (e, t) =>
        this._photonGeoCanvas.handleLabelWithoutBody(t, true),
      drawLabelInPath: (e, t) =>
        this._photonGeoCanvas.handleLabelWithoutBody(t, false),
      drawLabelOnPath: (e, t) =>
        this._photonGeoCanvas.handleLabelWithoutBody(t, false),
    }
  }
  release() {
    super.release()
    this._shapePainter = null
    this._imageProvider = null
    this._meshProvider = null
  }
  getLabel() {
    return this._label
  }
  isSelected(e) {
    return this._layer.isSelected(e)
  }
  isEdited(e) {
    return this._editedFeatureID === e.id
  }
  setEdited(e, t) {
    if (t) this._editedFeatureID = e.id
    else this._editedFeatureID = null
  }
  setPainter(e) {
    this._featurePainter = e
    this._density = !!this._featurePainter.density
  }
  setDetailLevel(e) {
    if (e !== this._stateObject.level) {
      this._stateObject.level = e
      this._featurePainter?.invalidateAll()
    }
  }
  getObjectId(e) {
    return this._objectIdMap.get(e.id)
  }
  registerFeature(e) {
    let t = this._objectIdMap.get(e.id)
    if (!t) {
      t = this._objectIdCounter++
      this._objectIdMap.set(e.id, t)
    }
    this._featureMap.set(t, e)
    return t
  }
  unregisterFeature(e) {
    const t = this._objectIdMap.get(e.id)
    this._featureMap.delete(t)
    this._objectIdMap.delete(e.id)
    return t
  }
  getFeature(e) {
    const t = this._objectIdMap.get(e)
    return this._featureMap.get(t)
  }
  forEachElement(e) {
    this._featureMap.forEach(e)
  }
  buildLabelDrawCommands(e, t, r) {
    const n = this._layer
    const o = n._map
    const s = this.isSelected(e)
    const i = !n.filter || n.filter(e)
    const a = this._editedFeatureID !== e.id
    const h = n.fetchProvidedShape(e)
    if (!(i && h && a && n.canDrawLabels() && this._featurePainter))
      return DrawNullLabel
    const l = []
    const d = {
      drawLabel: (e, t, r) =>
        void l.push({ type: 'drawLabel', html: e, shape: t, labelStyle: r }),
      drawLabelInPath: (e, t, r) =>
        void l.push({
          type: 'drawLabelInPath',
          html: e,
          shape: t,
          labelStyle: r,
        }),
      drawLabelOnPath: (e, t, r) =>
        void l.push({
          type: 'drawLabelOnPath',
          html: e,
          shape: t,
          labelStyle: r,
        }),
    }
    this._stateObject.selected = s
    this._stateObject.hovered = n.isHovered(e)
    this._featurePainter.paintLabel(d, e, h, n, o, this._stateObject)
    if (0 === l.length) return DrawNullLabel
    this._featurePainter.paintBody(
      this._geoCanvas,
      e,
      h,
      n,
      o,
      this._stateObject
    )
    l.forEach((e) => {
      let { type: t, shape: r } = e
      return this._labelWithoutBodyCanvas[t]('', r, {})
    })
    const c = this._photonGeoCanvas.build()
    const f = undefined
    const u = this.getFeatureDrawItemHandler(e, -1, s).bind(this)(c)
    const m = new PhotonDrawCommandProxy(this, e, h, u)
    t.resetLabelContext(r, m, this._stateObject.hovered)
    l.forEach((e) => {
      let { type: r, html: n, shape: o, labelStyle: s } = e
      return t[r](n, o, s)
    })
    return t.getDrawCommand()
  }
  getCurrentDrawItems(e, t, r) {
    const n = this.isSelected(e)
    const o = !this._layer.filter || this._layer.filter(e)
    const s = this._editedFeatureID !== e.id
    if (o && s && this._featurePainter) {
      const t = this._layer.fetchProvidedShape(e)
      if (t) {
        this._stateObject.selected = n
        this._stateObject.hovered = this._layer.isHovered(e)
        const r = this._layer
        const o = r._map
        this._featurePainter.paintBody(
          this._geoCanvas,
          e,
          t,
          r,
          o,
          this._stateObject
        )
        if (r.canDrawLabels())
          this._featurePainter.paintLabel(
            this._labelWithoutBodyCanvas,
            e,
            t,
            r,
            o,
            this._stateObject
          )
      }
    }
    const i = this._photonGeoCanvas.build()
    const a = this.getFeatureDrawItemHandler(e, t, n).bind(this)
    return inlineAsync(resolveStylePromises(i), r, a)
  }
  getFeatureDrawItemHandler(e, t, r) {
    return (n) => {
      const o = !r && this._density
      const s = r
        ? SELECTED_RENDER_PASS_ID
        : o
        ? DENSITY_RENDER_PASS_ID
        : REGULAR_RENDER_PASS_ID
      for (let i = 0; i < n.length; i++) {
        const a = n[i]
        a.feature = e
        a.objectId = t
        a.renderPassId = s
        a.selected = r
        a.density = o
        a.command = this._commands.find((e) => e.canHandle(n[i]))
      }
      n = PhotonShapePainterUtil.unrollGeoCanvasCommands(n, this._is3d)
      const i = []
      for (let a = 0; a < this._commands.length; a++) {
        const h = this._commands[a]
        const l = _filterByCommand(n, h)
        if (0 === l.length) continue
        const d = h.getDrawItems(l)
        for (let n = 0; n < d.length; n++) {
          const a = d[n]
          if (!hasDrawItem(i, a)) {
            a.feature = e
            a.objectId = t
            a.renderPassId = s
            a.selected = r
            a.density = o
            a.command = h
            i.push(a)
          }
        }
      }
      return i
    }
  }
  getSubshapeDrawItems(e, t) {
    const r = []
    for (let n = 0; n < e.length; n++)
      if (isSubShapeOf(e[n].shape, t)) r.push(e[n])
    return r
  }
  createDrawCommand(e) {
    return new PhotonDrawCommandProxy(
      this,
      e,
      e.shape,
      this.getCurrentDrawItems(e, -1, WhenPromiseScheduler)
    )
  }
  fastPick(e, t) {
    const r = this._shapePainter.pickObjects(e, this._pickInfosBuffer)
    const n = this._pickInfosBuffer.typedArray
    for (let e = 0; e < r; e++) {
      const r = n[e]
      const o = this._featureMap.get(r)
      if (o) t(o)
      else Log.error('No feature found for ' + r + '!')
    }
    return r
  }
  painterChanged() {
    this._density = !!this._featurePainter?.density
    if (this._density) {
      let e = { levels: null, colors: null, release: () => {} }
      const t = this._featurePainter?.density?.colorMap
      if (t) e = t.toPhotonBuffers(Photon)
      try {
        this._shapePainter.setDensityRenderPass(
          DENSITY_RENDER_PASS_ID,
          e.levels,
          e.colors
        )
      } finally {
        e.release()
      }
    }
  }
  add(e, t) {
    const r = this.registerFeature(e)
    const n = this.getCurrentDrawItems(e, r, t)
    return t.when(n, (n) =>
      t.when(resolveStyles(n), (t) => {
        t = this.updateNoShapePainterCommands(e, r, t)
        for (let e = 0; e < t.length; e++) {
          const r = t[e]
          r.command.add(r)
        }
        releaseStyles(t)
      })
    )
  }
  updateNoShapePainterCommands(e, t, r) {
    for (let n = 0; n < this._noShapePainterCommands.length; n++) {
      const o = this._noShapePainterCommands[n]
      if (r.find((e) => e.command === o)) {
        const n = r.filter((e) => e.command === o)
        r = r.filter((e) => e.command !== o)
        o.updateState(e, t, n)
      } else o.updateState(e, t, [])
    }
    return r
  }
  update(e, t) {
    const r = this.registerFeature(e)
    const n = this.getCurrentDrawItems(e, r, t)
    const o = this
    return t.when(n, (n) => {
      n = this.updateNoShapePainterCommands(e, r, n)
      const s = asDiffWantedItemBuffer(o._diffWantedItemsBuffer.typedArray)
      const i = asDiffItemBuffer(o._diffItemsBuffer.typedArray)
      let a = 0
      for (let e = 0; e < n.length; e++)
        s.set(a++, n[e].geometryId, n[e].styleId)
      const h = o._shapePainter.calculateDiff(
        r,
        a,
        o._diffWantedItemsBuffer,
        o._diffItemsBuffer
      )
      const l = []
      const d = []
      const c = []
      for (let e = 0; e < h; e++) {
        const t = i.action(e)
        const o =
          t !== DIFF_ACTION_REMOVE
            ? n[i.wantedItemIdx(e)]
            : { objectId: r, geometryId: 0, styleId: 0 }
        o.oldGeometryId = i.oldGeometryId(e)
        o.oldStyleId = i.oldStyleId(e)
        o.action = t
        l.push(o)
        if (
          t === DIFF_ACTION_ADD ||
          t === DIFF_ACTION_STYLE ||
          t === DIFF_ACTION_REPLACE
        )
          d.push(o)
        if (
          o.zStyle &&
          o.zStyle.viewDisplacement &&
          (t === DIFF_ACTION_NOOP || t === DIFF_ACTION_GEOMETRY)
        )
          c.push(o)
      }
      if (0 !== d.length && 0 !== c.length)
        for (let e = 0; e < c.length; e++) d.push(c[e])
      n = l
      function f(e) {
        for (let t = 0; t < e.length; t++) {
          const n = e[t]
          const s = n.action
          const i = n.command
          if (s === DIFF_ACTION_NOOP);
          else if (s === DIFF_ACTION_REMOVE)
            o._shapePainter.removeGeometry(
              n.objectId,
              n.oldGeometryId,
              n.oldStyleId
            )
          else if (s === DIFF_ACTION_ADD) i.add(n)
          else if (s === DIFF_ACTION_STYLE) i.updateStyle(n, n.oldStyleId)
          else if (s === DIFF_ACTION_GEOMETRY)
            i.updateGeometry(n, n.oldGeometryId)
          else if (s === DIFF_ACTION_REPLACE) {
            o._shapePainter.removeGeometry(r, n.oldGeometryId, n.oldStyleId)
            i.add(n)
          } else throw new Error('Unknown action ' + s)
        }
        releaseStyles(e)
      }
      if (d.length > 0)
        return t.when(resolveStyles(d), function () {
          f(n)
        })
      else f(l)
    })
  }
  remove(e, t) {
    const r = this.unregisterFeature(e)
    this._shapePainter.removeObject(r)
    this.updateNoShapePainterCommands(e, r, [])
  }
  clean() {
    this._imageProvider.clean()
    this._meshProvider.clean()
    this._shapePainter.garbageCollect()
  }
  getBounds() {
    const e = this._shapePainter.getGeometryBoundsRia()
    if (!e.valid) return null
    const t = [e.x, e.width, e.y, e.height, e.z, e.depth]
    for (let e = 0; e < t.length; e++)
      if (isNaN(t[e]) || t[e] === -1 / 0 || t[e] === 1 / 0) return null
    return createBounds(this._worldReference, t)
  }
  paint(e, t, r, n, o) {
    this._meshProvider.refreshVideoTexture()
    let s = true
    for (let i = 0; i < this._noShapePainterCommands.length; i++) {
      const a = undefined
      s =
        this._noShapePainterCommands[i].paint(
          this._photonGraphics,
          e,
          t,
          r,
          n,
          o
        ) && s
    }
    this._shapePainter.paint(this._photonGraphics, e, t, r, n, o)
    return s
  }
  updateViewDependentAttributes(e) {
    return this._shapePainter.updateViewDependentAttributes(e)
  }
  pick(e) {
    const t = this._shapePainter.pick(e.viewBounds, this._pickInfosBuffer, true)
    const r = 1e6
    const n = []
    const o = asPickInfoBuffer(this._pickInfosBuffer.typedArray)
    for (let e = 0; e < t; e++) {
      const t = o.objectId(e)
      const s = this._featureMap.get(t)
      if (s)
        n.push(
          new PickInfoImpl(
            this._layer,
            s,
            o.distanceToEdge(e),
            o.distanceToCenter(e),
            o.depth(e) / r,
            o.zOrder(e),
            this.isSelected(s),
            o.draped(e)
          )
        )
      else Log.error('No feature found for ' + t + '!')
    }
    return n
  }
  interacts(e, t, r, n) {
    return this.interactsDrawItems(
      e,
      this.getSubshapeDrawItems(
        this.getCurrentDrawItems(e, -1, WhenPromiseScheduler),
        t
      ),
      r,
      n
    )
  }
  interactsDrawItems(e, t, r, n) {
    const o = PickRequest.fromWorldBounds(
      r,
      false,
      true,
      this._layer.getMapToViewTransformation(PaintRepresentation.BODY)
    )
    return this.interactsDrawItemsInner(e, t, n, o)
  }
  interactsDrawItemsView(e, t, r, n, o, s) {
    const i = PickRequest.fromViewPoint(
      r,
      n,
      o,
      o,
      false,
      true,
      this._layer.getMapToViewTransformation(PaintRepresentation.BODY)
    )
    return this.interactsDrawItemsInner(e, t, s, i)
  }
  findInteractingShapes(e, t, r, n, o) {
    const s = PickRequest.fromViewPoint(
      t,
      r,
      n,
      n,
      false,
      true,
      this._layer.getMapToViewTransformation(PaintRepresentation.BODY)
    )
    const i = []
    for (let t = 0; t < e.length; t++) {
      const r = e[t]
      if (!o || r.metadata.isLine) i.push(r.geometryId)
    }
    if (i.length <= 0) return []
    let a = 0
    try {
      a = this._shapePainter.pick(s.viewBounds, this._pickInfosBuffer, true)
    } catch (e) {
      OutOfBoundsError.isOrThrow(e)
    }
    const h = []
    const l = asPickInfoBuffer(this._pickInfosBuffer.typedArray)
    for (let t = 0; t < a; t++) {
      const r = l.geometryId(t)
      if (i.indexOf(r) >= 0) {
        const t = e.find((e) => e.geometryId === r)
        if (t) h.push(t.shape)
      }
    }
    return h
  }
  interactsDrawItemsInner(e, t, r, n) {
    const o = []
    for (let e = 0; e < t.length; e++) {
      const n = t[e]
      if (!r || n.metadata.isLine) o.push(n.geometryId)
    }
    if (o.length <= 0) return false
    let s = 0
    try {
      s = this._shapePainter.pick(n.viewBounds, this._pickInfosBuffer, true)
    } catch (e) {
      OutOfBoundsError.isOrThrow(e)
    }
    const i = asPickInfoBuffer(this._pickInfosBuffer.typedArray)
    for (let e = 0; e < s; e++) {
      const t = i.geometryId(e)
      if (o.indexOf(t) >= 0) return true
    }
    return false
  }
  getShapeAnchor(e, t, r, n) {
    const o = this.getObjectId(e)
    if (!o) return null
    const s = getGeometryIdForBiggestShape(t, r)
    if (!s) return null
    return this._shapePainter.getShapeAnchor(o, s, {
      mode: r,
      minPathLength: n,
    })
  }
  getShapeOnPathAnchorsSFCT(e, t, r, n) {
    const o = this.getObjectId(e)
    if (!o) return
    const s = getGeometryIdForBiggestShape(t, Photon.AnchorMode.OnPath)
    if (!s) return
    const i = { mode: Photon.AnchorMode.OnPath, minPathLength: n }
    this.doGetShapeAnchorsSFCT(o, s, i, r, ON_PATH_POSITION_FRACTIONS)
    for (let e = 0; e < t.length; e++) {
      const n = t[e].geometryId
      if (n !== s) {
        this.doGetShapeAnchorsSFCT(o, n, i, r, ON_PATH_POSITION_CENTRAL)
        if (r.anchorPointsLength >= MAX_ANCHORS) return
      }
    }
  }
  doGetShapeAnchorsSFCT(e, t, r, n, o) {
    for (let s = 0; s < o.length; s++) {
      r.distanceAt = o[s]
      const i = this._shapePainter.getShapeAnchor(e, t, r)
      if (isValidAnchor(i, r.mode) && !n.exists(i.x, i.y, i.z))
        addToAnchors(n, i)
    }
  }
  getRepeatingAnchorListSFCT(e, t, r, n, o) {
    const s = o.mapWorldBounds
    const i = getClipWorldBounds(s, 5)
    const a = o.w2v
    const h = o.labelWidth
    if (!r) return
    const l = this.getObjectId(e)
    if (!l) return
    const d = this._shapePainter
    const c = r.initialGap ?? 0
    const f = r.minimumGap ?? 0
    const u = Photon.AnchorMode.OnPath
    for (let e = 0; e < t.length; e++) {
      const r = t[e].geometryId
      const h = shouldClipPath(t[e].shape.bounds, i, a)
      n.updateRepeatingAnchorContext(h, h ? s : i)
      m(r, h)
      if (n.anchorPointsLength >= o.maxCount) break
    }
    function m(e, t) {
      const r = {
        distanceAt: c + h / 2,
        distanceType: Photon.DistanceType.Pixel,
        mode: u,
        minPathLength: 0,
        clipType: t ? Photon.ClipType.ClipToView : Photon.ClipType.NoClip,
      }
      let s = d.getShapeAnchor(l, e, r)
      let i = false
      if (isValidAnchor(s, u)) {
        addToAnchors(n, s)
        i = true
      }
      while (i) {
        r.distanceAt += f + h
        s = d.getShapeAnchor(l, e, r)
        if (isValidAnchor(s, u)) {
          addToAnchors(n, s)
          if (n.anchorPointsLength >= o.maxCount) i = false
        } else i = false
      }
    }
  }
  labelAnchorToView(e, t) {
    const r = this.getObjectId(e)
    if (!r) return null
    const n = this._shapePainter.labelAnchorToView(r, t)
    if (!n.valid) return null
    return n
  }
  isPointVisible(e, t) {
    if (isIE())
      return this._photonView.isPointVisible(e, Photon.VisibilityMode.Terrain)
    const r = t ? Photon.VisibilityMode[t] : Photon.VisibilityMode.Terrain
    return this._photonView.isPointVisible(e, r)
  }
}
function _filterByCommand(e, t) {
  return e.filter((e) => e.command === t)
}
function hasDrawItem(e, t) {
  for (let r = 0; r < e.length; r++)
    if (t.geometryId === e[r].geometryId && t.styleId === e[r].styleId)
      return true
  return false
}
function resolveStylePromises(e) {
  const t = []
  let r = false
  for (let n = 0; n < e.length; n++) {
    const o = e[n]
    if (isPromise(o.style)) {
      r = true
      t.push(
        o.style.then(function (e) {
          o.style = e
          return o
        })
      )
    } else t.push(o)
  }
  return r ? Promise.all(t) : t
}
function releaseStyles(e) {
  for (let t = 0; t < e.length; t++)
    if (e[t].style && e[t].style.release) e[t].style.release()
}
function resolveStyles(e) {
  for (let t = 0; t < e.length; t++) e[t].command.resolveStyle(e[t])
  return resolveStylePromises(e)
}
function isSubShapeOf(e, t) {
  if (e === t) return true
  if (equalShapes(e, t)) return true
  if (isOfShapeType(t, ShapeType.SHAPE_LIST))
    for (let r = 0; r < t.shapeCount; r++)
      if (isSubShapeOf(e, t.getShape(r))) return true
  return false
}
function equalShapes(e, t) {
  return e && t && e.type === t.type && e.equals(t)
}
const ON_PATH_POSITION_FRACTIONS = [0.5, 0.25, 0.75]
const ON_PATH_POSITION_CENTRAL = [0.5]
const MAX_ANCHORS = 10
function shouldClipPath(e, t, r) {
  try {
    const n = undefined
    const o = createTransformation(
      e.reference,
      r.inputReference
    ).transformBounds(e)
    return !t.contains2DBounds(o)
  } catch (e) {
    return true
  }
}
function getClipWorldBounds(e, t) {
  try {
    return e.copy().enlarge2D(t)
  } catch (e) {}
  return null
}
function addToAnchors(e, t) {
  e.add3D(t.x, t.y, t.z, t.x + t.dx, t.y + t.dy, t.z + t.dz)
}
function isValidAnchor(e, t) {
  const r = !isNaN(e.x) && !isNaN(e.y) && !isNaN(e.z)
  if (
    r &&
    t === Photon.AnchorMode.OnPath &&
    0 === e.dx &&
    0 === e.dy &&
    0 === e.dz
  )
    return false
  return r
}
function getGeometryIdForBiggestShape(e, t) {
  let r = null
  let n = -1
  for (let o = 0; o < e.length; o++) {
    const s = e[o]
    if (canDrawLabelModeForItem(s, t)) {
      const e = s.shape.bounds
      const t = e.width * e.height
      if (t > n) {
        r = s.geometryId
        n = t
      }
    }
  }
  return r
}
function canDrawLabelModeForItem(e, t) {
  const r = e.metadata
  if (t === Photon.AnchorMode.OnPath) return r.isLine
  else if (t === Photon.AnchorMode.InPath)
    return !!(r && (r.isOutlineForClosedShape || r.isFill))
  else return true
}
