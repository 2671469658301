import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { greatCircleDistance } from '../geodesy/SphereUtil.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Azimuthal } from './Azimuthal.js'
import { ProjectionType } from './ProjectionType.js'
const tempPoint = new LLHPoint()
const sharedOutOfBoundsError = new OutOfBoundsError('Gnomonic')
const MAX_DISTANCE = 60.000001
const COS_MAX_DISTANCE = Math.cos(Constants.DEG2RAD * MAX_DISTANCE)
const TAN_MAX_DISTANCE = Math.tan(Constants.DEG2RAD * MAX_DISTANCE)
const TAN_MAX_DISTANCE_2 = TAN_MAX_DISTANCE * TAN_MAX_DISTANCE
export class Gnomonic extends Azimuthal {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._originSinLat = -1
    this._originSinLon = -1
    this._originCosLat = -1
    this._originCosLon = -1
    this.setOriginLon(t)
    this.setOriginLat(n)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    const t = this.getOriginLat() * Constants.DEG2RAD
    const n = this.getOriginLon() * Constants.DEG2RAD
    this._originSinLat = Math.sin(t)
    this._originSinLon = Math.sin(n)
    this._originCosLat = Math.cos(t)
    this._originCosLon = Math.cos(n)
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, n, s) {
    if (this.inLonLatBounds(t)) {
      const i = Math.sin(((t.y - this.getOriginLat()) * Constants.DEG2RAD) / 2)
      const o = Math.sin(((t.x - this.getOriginLon()) * Constants.DEG2RAD) / 2)
      const r = Math.cos(t.y * Constants.DEG2RAD)
      const a = i * i + this._originCosLat * r * o * o
      const e = Math.sqrt(a)
      const h = Math.asin(e)
      const c = Math.cos(2 * h)
      if (c <= 0) throw sharedOutOfBoundsError
      const g = Math.cos(t.x * Constants.DEG2RAD)
      const L = Math.sin(t.x * Constants.DEG2RAD)
      const C = Math.sin(t.y * Constants.DEG2RAD)
      const A = L * this._originCosLon - g * this._originSinLon
      const u = g * this._originCosLon + L * this._originSinLon
      const D = r * A
      const M = this._originCosLat * C - this._originSinLat * r * u
      s.x = (D * n) / c
      s.y = (M * n) / c
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereSFCT(t, n, s) {
    if (this.inWorldBoundsOnSphere(t, n)) {
      const i = t.x
      const o = t.y
      const r = Math.sqrt(i * i + o * o)
      const a = Math.atan2(r, n)
      if (r < 1e-6) {
        s.x = this.getOrigin().x
        s.y = this.getOrigin().y
        return
      }
      const e = Math.cos(a)
      const h = Math.sin(a)
      const c = e * this._originSinLat + (o * h * this._originCosLat) / r
      let g, L
      if (Math.abs(this.getOriginLat() - 90) < 1e-7) {
        L = i
        g = -o
      } else if (Math.abs(this.getOriginLat() - -90) < 1e-7) {
        L = i
        g = o
      } else {
        L = i * h
        g = r * this._originCosLat * e - o * this._originSinLat * h
      }
      s.x = normalizeLon(
        this.getOriginLon() + Math.atan2(L, g) * Constants.RAD2DEG
      )
      s.y =
        Math.abs(c) <= 1 ? Math.asin(c) * Constants.RAD2DEG : c >= 0 ? 90 : -90
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    return greatCircleDistance(this.getOrigin(), t) <= MAX_DISTANCE
  }
  inWorldBoundsOnSphere(t, n) {
    const s = t.x / n
    const i = t.y / n
    return s * s + i * i <= TAN_MAX_DISTANCE_2
  }
  boundaryLons(t) {
    tempPoint.move2DToCoordinates(this.getOriginLon(), t)
    if (!(greatCircleDistance(this.getOrigin(), tempPoint) <= MAX_DISTANCE))
      return []
    if (
      Math.abs(this.getOriginLat() - 90) < 1e-7 ||
      Math.abs(this.getOriginLat() - -90) < 1e-7
    )
      return [[-180, 180]]
    const n = undefined
    const s = undefined
    const i =
      (COS_MAX_DISTANCE -
        Math.sin(t * Constants.DEG2RAD) * this._originSinLat) /
      (Math.cos(t * Constants.DEG2RAD) * this._originCosLat)
    if (Math.abs(i) >= 1) return [[-180, 180]]
    const o = Math.acos(i) * Constants.RAD2DEG - this.EPSILON
    const r = undefined
    const a = undefined
    return [
      [
        normalizeLon(this.getOriginLon() - o),
        normalizeLon(this.getOriginLon() + o),
      ],
    ]
  }
  boundaryLats(t) {
    const n = this._originSinLat
    const s =
      this._originCosLat *
      Math.cos((t - this.getOriginLon()) * Constants.DEG2RAD)
    const i = undefined
    const o = undefined
    const r = COS_MAX_DISTANCE / Math.sqrt(n * n + s * s)
    if (r > 1) return []
    const a = Constants.RAD2DEG * Math.acos(r) - 10 * this.EPSILON
    const e = Constants.RAD2DEG * Math.atan2(n, s)
    const h = Math.max(e - a, -90)
    const c = Math.min(e + a, 90)
    if (h > 90 || c < -90) return []
    return [[h, c]]
  }
  cartesianBoundsOnSphereSFCT(t, n) {
    const s = TAN_MAX_DISTANCE * t
    n.setTo2D(-s, 2 * s, -s, 2 * s)
  }
  encode() {
    return {
      type: 'Gnomonic',
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.GNOMONIC + ProjectionType.AZIMUTHAL
  }
}
