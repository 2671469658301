import { NotImplementedError } from '../error/NotImplementedError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { isArray, isUndefined } from '../util/Lang.js'
import { Axis } from './Axis.js'
function find(e, r) {
  return r.name === e
}
class CoordinateReference {
  constructor(e) {
    e = e || {}
    this._axes = []
    this._axisInformation = []
    if (isUndefined(e.coordinateType) || isUndefined(e.referenceType))
      throw new ProgrammingError(
        'Coordinate and reference type need to be specified.'
      )
    this._coordinateType = e.coordinateType
    this._referenceType = e.referenceType
    this._name = e.name || ''
    this._identifier = null
    if (e.identifier) this._identifier = e.identifier
    else
      this.setIdentifier(e.prefix, e.authorityName, e.authorityCode, e.version)
    if (isUndefined(e.axisInformation))
      throw new ProgrammingError(
        'CoordinateReference::CoordinateReference - Must pass axes information at construction time.'
      )
    this._setAxisInformation(e.axisInformation)
    this._wkt = null
  }
  get name() {
    return this._name
  }
  get coordinateType() {
    return this._coordinateType
  }
  get referenceType() {
    return this._referenceType
  }
  equals(e) {
    throw new ProgrammingError('Equals is not implemented for this reference.')
  }
  getAxis(e) {
    return this._axes[e]
  }
  hasUniformAxes2D() {
    const [e, r] = this._axes
    return e && r && e.unitOfMeasure === r.unitOfMeasure
  }
  get identifier() {
    return null === this._identifier ? '' : this._identifier
  }
  get axisInformation() {
    return this._axisInformation
  }
  _setAxisInformation(e) {
    if (!isArray(e))
      throw new ProgrammingError(
        'CoordinateReference::setAxisInformation only accepts arrays'
      )
    let r
    let i = true
    for (r = 0; r < e.length && i; r++) {
      const t = e[r].name
      const n = e[r].axis
      i =
        !isUndefined(t) &&
        t >= Axis.Name.X &&
        t <= Axis.Name.Z &&
        'AXIS' === n.TYPE
    }
    if (!i)
      throw new ProgrammingError(
        'CoordinateReference::setAxisInformation - Encountered invalid Axis Information object'
      )
    this._axisInformation = []
    for (r = 0; r < e.length; r++) {
      const i = e[r].axis.copy()
      this._axes[e[r].name] = i
      this._axisInformation[r] = Object.freeze({
        name: e[r].name,
        axis: Object.freeze(i),
      })
    }
    Object.freeze(this._axisInformation)
  }
  setIdentifier(e, r, i, t) {
    let n = null
    if (r && i)
      if (e) n = e + ':' + r + (t ? ':' + t : '') + ':' + i
      else n = r + ':' + i
    this._identifier = n
  }
  copy() {
    throw new NotImplementedError(
      'Sub classes of CoordinateReference must implement copy'
    )
  }
  get wkt() {
    return this._wkt
  }
  set wkt(e) {
    this._wkt = e
  }
  hashCode(e) {
    throw new NotImplementedError('Child must implement hashCode')
  }
}
export { CoordinateReference }
