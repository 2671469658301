import { ProgrammingError } from '../error/ProgrammingError.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { EllipsoidalGeodesy } from './EllipsoidalGeodesy.js'
import { SphericalGeodesy } from './SphericalGeodesy.js'
import { CartesianGeodesy } from './CartesianGeodesy.js'
import { GeoReference } from '../reference/GeoReference.js'
function createCartesianGeodesy(e) {
  if (!e) throw new ProgrammingError('Reference may not be undefined')
  const r = getReferenceType(e)
  if (
    r !== ReferenceType.GRID &&
    r !== ReferenceType.GEODETIC &&
    r !== ReferenceType.CARTESIAN &&
    r !== ReferenceType.GEOCENTRIC &&
    r !== ReferenceType.TOPOCENTRIC
  )
    throw new ProgrammingError('Reference must be a GeoReference')
  return new CartesianGeodesy(e)
}
function createEllipsoidalGeodesy(e) {
  if (!e) throw new ProgrammingError('Reference may not be undefined')
  const r = getReferenceType(e)
  if (
    r !== ReferenceType.GRID &&
    r !== ReferenceType.GEOCENTRIC &&
    r !== ReferenceType.GEODETIC
  )
    throw new ProgrammingError('Reference must be a GeoReference')
  return new EllipsoidalGeodesy(e)
}
function createSphericalGeodesy(e, r) {
  if (!e) throw new ProgrammingError('Reference may not be undefined')
  const n = getReferenceType(e)
  if (
    n !== ReferenceType.GRID &&
    n !== ReferenceType.GEOCENTRIC &&
    n !== ReferenceType.GEODETIC
  )
    throw new ProgrammingError('Reference must be a GeoReference')
  return new SphericalGeodesy(e, r)
}
const getReferenceType = (e) => {
  if (e instanceof GeoReference) return e.TYPE
  return ReferenceType.CARTESIAN
}
export {
  createCartesianGeodesy,
  createEllipsoidalGeodesy,
  createSphericalGeodesy,
}
