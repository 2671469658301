import { Constants } from '../../../../util/Constants.js'
import { isNumber } from '../../../../util/Lang.js'
import { Filled } from './util/Filled.js'
import { Length } from './util/Length.js'
import { Stroked } from './util/Stroked.js'
import { PatternType } from './util/PatternType.js'
import { Pattern } from '../Pattern.js'
const DEFAULT_FIXED_LENGTH = 10
const DEFAULT_RELATIVE_LENGTH = 1
export class ArcPattern extends Pattern {
  _minHeight = 0
  _maxHeight = 0
  _actualFixedStart = 0
  _actualFixedEnd = 0
  _actualRelativeStart = 0
  _actualRelativeEnd = 0
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    super(PatternType.ARC)
    const e = t.fill ?? {}
    const i = t.line ?? {}
    if (!i.color && !e.color) {
      i.width = 1
      i.color = 'rgb(0,0,0)'
    }
    const s = !isNumber(t.length)
      ? t.relative
        ? DEFAULT_RELATIVE_LENGTH
        : DEFAULT_FIXED_LENGTH
      : t.length
    const n = t.relative ? 0 : s
    const l = t.relative ? s : 0
    this._startAngle = t.startAngle ?? 0
    this._angle = t.angle ?? 360
    this._offset = t.offset ?? 0
    this._minorRadius = t.minorRadius ?? 10
    this._length = new Length({ fixedLength: n, relativeLength: l })
    this._stroked = new Stroked(i)
    this._filled = new Filled(e)
    this.calculateDimensions()
    this._startAngleRadians = Constants.DEG2RAD * this._startAngle
    this._endAngleRadians = Constants.DEG2RAD * (this._startAngle + this._angle)
  }
  calculateDimensions() {
    this.calculateActualWidths()
    this.calculateMinMaxHeight()
  }
  calculateMinMaxHeight() {
    let t = this._startAngle
    while (t > 90) t -= 360
    while (t <= -270) t += 360
    const e = t <= 90 && 90 <= t + this._angle
    t = this._startAngle
    while (t > 270) t -= 360
    while (t <= -90) t += 360
    const i = t <= 270 && 270 <= t + this._angle
    const s = Math.sin(this._startAngle * Constants.DEG2RAD)
    const n = Math.sin((this._startAngle + this._angle) * Constants.DEG2RAD)
    const l = i ? -1 : Math.min(s, n)
    const h = e ? 1 : Math.max(s, n)
    this._minHeight = l * this._minorRadius + this._offset
    this._maxHeight = h * this._minorRadius + this._offset
  }
  calculateActualWidths() {
    let t = this._startAngle
    while (t > 180) t -= 360
    while (t <= -180) t += 360
    const e = t <= 180 && 180 <= t + this._angle
    t = this._startAngle
    while (t > 0) t -= 360
    while (t <= -360) t += 360
    const i = t <= 0 && 0 <= t + this._angle
    const s = Math.cos(this._startAngle * Constants.DEG2RAD)
    const n = Math.cos((this._startAngle + this._angle) * Constants.DEG2RAD)
    const l = e ? -1 : Math.min(s, n)
    const h = i ? 1 : Math.max(s, n)
    this._actualFixedStart = 0.5 * (l + 1) * this.fixedLength
    this._actualFixedEnd = 0.5 * (h + 1) * this.fixedLength
    this._actualRelativeStart = 0.5 * (l + 1) * this.relativeLength
    this._actualRelativeEnd = 0.5 * (h + 1) * this.relativeLength
  }
  getWidth(t) {
    const e = this._length.getUomSizeToPixels(
      this._actualFixedEnd - this._actualFixedStart
    )
    const i = this._length.getUomSizeToPixels(
      this._actualRelativeEnd - this._actualRelativeStart
    )
    return this.isRelativeLength() ? i * t : e
  }
  getEllipseWidth(t, e) {
    return this._length.getWidth(e)
  }
  getMinHeight() {
    return Math.min(0, this._minHeight) - this.lineWidth / 2
  }
  getMaxHeight() {
    return Math.max(0, this._maxHeight) + this.lineWidth / 2
  }
  get worldSizeContext() {
    return this._length.worldSizeContext
  }
  set worldSizeContext(t) {
    this._length.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._length.getUomSizeToPixels(t)
  }
  isRelativeLength() {
    return this._length.isRelativeLength()
  }
  get fixedLength() {
    return this._length.fixedLength
  }
  set fixedLength(t) {
    this._length.fixedLength = t
  }
  get relativeLength() {
    return this._length.relativeLength
  }
  set relativeLength(t) {
    this._length.relativeLength = t
  }
  strokePath(t) {
    this._stroked.strokePath(t)
  }
  get lineColor() {
    return this._stroked.lineColor
  }
  set lineColor(t) {
    this._stroked.lineColor = t
  }
  get lineWidth() {
    return this._stroked.lineWidth
  }
  set lineWidth(t) {
    this._stroked.lineWidth = t
  }
  get dashed() {
    return this._stroked.dashed
  }
  set dashed(t) {
    this._stroked.dashed = t
  }
  fillPath(t) {
    this._filled.fillPath(t)
  }
  get fillColor() {
    return this._filled.fillColor
  }
  set fillColor(t) {
    this._filled.fillColor = t
  }
  get startAngle() {
    return this._startAngle
  }
  get angle() {
    return this._angle
  }
  get minorRadius() {
    return this._minorRadius
  }
  get offset() {
    return this._offset
  }
  paint(t, e, i, s) {
    t.scale(1, -1)
    if (0 === this.relativeLength) {
      const e = this._length.getUomSizeToPixels(this._actualFixedStart)
      t.translate(-e, 0)
    } else {
      const e = this._length.getUomSizeToPixels(this._actualRelativeStart)
      t.translate(-e, 0)
    }
    const n = this.getEllipseWidth(t, e) / 2
    const l = this._length.getUomSizeToPixels(this._minorRadius)
    const h = n
    const a = this._length.getUomSizeToPixels(this._offset)
    const r = this._startAngleRadians
    const o = this._endAngleRadians
    const g = false
    const _ = 0
    t.beginPath()
    t.ellipse(h, a, n, l, _, r, o, g)
    this.fillPath(t)
    this.strokePath(t)
    if (0 === this.relativeLength) t.translate(this._actualFixedStart, 0)
    else t.translate(this._actualRelativeStart, 0)
    t.scale(1, -1)
  }
  appendHash(t) {
    t.appendDouble(this._startAngle)
    t.appendDouble(this._angle)
    t.appendDouble(this._minorRadius)
    t.appendDouble(this._offset)
    this._length.appendHash(t)
    this._stroked.appendHash(t)
    this._filled.appendHash(t)
  }
}
