import { isUndefined } from '../util/Lang.js'
export class ConstrainedPointList {
  constructor(t, n) {
    this._shape = t
    this._constraint = n
  }
  get shape() {
    return this._shape
  }
  get constraint() {
    return this._constraint
  }
  get minPointCount() {
    return this._constraint.minPointCount ?? 0
  }
  get maxPointCount() {
    return this._constraint.maxPointCount ?? Number.MAX_VALUE
  }
  get pointCount() {
    const t = this._shape.pointCount ?? 1
    return Math.min(t, this.maxPointCount)
  }
  get isGeodetic() {
    return this._shape.isGeodetic
  }
  get bounds() {
    return this._shape.bounds
  }
  get coordinateType() {
    return this._shape.coordinateType
  }
  copy() {
    return new ConstrainedPointList(this._shape.copy(), this._constraint)
  }
  insertPoint(t, n) {
    this._shape.insertPoint(t, n)
    this._constrain(t, void 0, this.getPoint(t))
  }
  removePoint(t) {
    const n = this._getOldPointForConstraint(t)
    this._shape.removePoint(t)
    this._constrain(t, n ?? void 0, void 0)
  }
  move2DPoint(t, n, i) {
    const s = this._getOldPointForConstraint(t)
    this._shape.move2DPoint(t, n, i)
    this._constrain(t, s ?? void 0, this.getPoint(t))
  }
  translatePoint(t, n, i, s) {
    const o = this._getOldPointForConstraint(t)
    this._shape.translatePoint(t, n, i)
    this._constrain(t, o ?? void 0, this.getPoint(t))
  }
  translate2D(t, n) {
    this._shape.translate2D(t, n)
    this._simpleConstrain()
  }
  getPoint(t) {
    return this._shape.getPoint(t)
  }
  contains2D(t, n) {
    if (isUndefined(n)) return this.contains2DPoint(t)
    return this.contains2DCoordinates(t, n)
  }
  contains2DPoint(t) {
    return this._shape.contains2DPoint(t)
  }
  contains2DCoordinates(t, n) {
    return this._shape.contains2DCoordinates(t, n)
  }
  _getOldPointForConstraint(t) {
    return this.constraint ? this.getPoint(t).copy() : null
  }
  _simpleConstrain() {
    if (this.constraint) this.constraint.constrainEditable(this._shape)
  }
  _constrain(t, n, i) {
    if (this.constraint) this.constraint.constrainEdit(this._shape, t, n, i)
  }
}
