import { ReferenceType } from '../../reference/ReferenceType.js'
import { XYZBounds } from '../../shape/XYZBounds.js'
import { distance2D_xy } from '../../util/Cartesian.js'
import { Clipping } from '../../util/Clipping.js'
import { isNumber } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { ClipPolygonUtil } from './ClipPolygonUtil.js'
import { ClipPolylineUtil } from './ClipPolylineUtil.js'
import { PointLocationUtil } from './PointLocationUtil.js'
import { computeRepeatingAnchors } from './util/OnPathAnchorFinder.js'
function clipSFCT(t, n) {
  const e = t.length
  let o = 0
  while (o < e) {
    const e = t[o]
    n.moveTo(e[0], e[1], e[2])
    let i = 3
    const l = e.length
    while (i < l) {
      n.lineTo(e[i], e[i + 1], e[i + 2])
      i += 3
    }
    o += 1
  }
}
export function clipPolygon(t, n) {
  const e = ['top', 'left', 'bottom', 'right']
  for (let o = 0; o < e.length; o++) {
    n.rewind()
    n.setEdge(e[o])
    const i = t.length
    let l = 0
    while (l < i) {
      const e = t[l]
      const o = e.length
      if (o > 2) {
        n.moveTo(e[0], e[1], e[2])
        let t = 3
        while (t < o) {
          n.lineTo(e[t], e[t + 1], e[t + 2])
          t += 3
        }
        n.closePolygon()
      }
      l += 1
    }
    t = n.getPaths()
  }
  return t
}
const pointLocationUtil = new PointLocationUtil()
export function centerPointSFCT(t, n) {
  const e = t.length
  let o = []
  let i
  if (0 == e) return
  if (e > 0) o = t[0]
  if (e > 1) i = t.slice(1)
  const l = pointLocationUtil.findLocation(o, i)
  if (l && isNumber(l.x) && isNumber(l.y)) n.add3D(l.x, l.y, 0, l.x, l.y, 0)
}
function getDistanceCheckpoints(t) {
  CHECKPOINTS.forEach((n) => {
    n.distance = t * n.rate
  })
  return CHECKPOINTS
}
const CHECKPOINTS = [
  { rate: 0.15, importanceIndex: 5 },
  { rate: 0.3, importanceIndex: 3 },
  { rate: 0.4, importanceIndex: 1 },
  { rate: 0.5, importanceIndex: 0 },
  { rate: 0.6, importanceIndex: 2 },
  { rate: 0.7, importanceIndex: 4 },
  { rate: 0.85, importanceIndex: 6 },
]
function getSegmentsAtDistance(t) {
  const n = t.length
  const e = undefined
  const o = getDistanceCheckpoints(t[n - 1])
  const i = o.length
  const l = []
  for (let e = 0, s = 0; e < n && s < i; e++) {
    const n = t[e]
    if (n > (o[s].distance || 0)) {
      l[o[s].importanceIndex] = e
      do {
        s += 1
      } while (s < i && n > (o[s].distance || 0))
    }
  }
  return l
}
export function anchorPointsSFCT(t, n, e, o, i) {
  if (o.anchorPointsLength > CHECKPOINTS.length) return
  const l = i ? i.length : t.length
  const s = []
  let c = 0
  let r
  let a
  let p
  for (let o = 0; o < l; o++) {
    r = t[o]
    const l = i ? i.pathLengths[o] : r.length
    p = i ? i.coordsPerPoint : 3
    a = -1
    let d = e.forwardX(r[0], r[1])
    let h = e.forwardY(r[0], r[1])
    let m
    for (let t = p; t < l; t += p) {
      const n = e.forwardX(r[t], r[t + 1])
      const o = e.forwardY(r[t], r[t + 1])
      m = distance2D_xy(d, h, n, o)
      if (t === p) c = m
      else c = s[a] + m
      a += 1
      s[a] = c
      d = n
      h = o
    }
    if (c >= n) {
      const t = undefined
      getSegmentsAtDistance(s).forEach(u)
      return
    }
  }
  function u(t) {
    if (null != t && t >= 0 && t <= a) {
      const n = t * p
      const e = r[n]
      const i = r[n + 1]
      const l = r[n + p]
      const s = r[n + p + 1]
      o.add(0.5 * (e + l), 0.5 * (i + s), l, s)
    }
  }
}
const tmpBounds = new XYZBounds(null)
export function calculateBoundsMultiSFCT(t, n) {
  const e = t.length
  let o = 0
  let i = 1 / 0,
    l = 1 / 0,
    s = 1 / 0,
    c = -1 / 0,
    r = -1 / 0,
    a = -1 / 0
  while (o < e) {
    const n = t[o]
    if (n.length >= 3) {
      calculateBoundsSFCT(n, tmpBounds)
      const t = tmpBounds.x
      const e = tmpBounds.y
      const o = tmpBounds.z
      const p = t + tmpBounds.width
      const u = e + tmpBounds.height
      const d = o + tmpBounds.depth
      i = Math.min(t, i)
      l = Math.min(e, l)
      s = Math.min(o, s)
      c = Math.max(p, c)
      r = Math.max(u, r)
      a = Math.max(d, a)
    }
    o += 1
  }
  n.setTo3D(i, c - i, l, r - l, s, a - s)
}
export function calculateBoundsSFCT(t, n) {
  let e = 0
  const o = t.length
  let i = 1 / 0
  let l = -1 / 0
  let s = 1 / 0
  let c = -1 / 0
  let r = 1 / 0
  let a = -1 / 0
  let p
  let u
  let d
  if (o < 3) {
    Log.warn('Cannot calculate bounds of list containing no points.')
    return
  }
  while (e < o) {
    p = t[e]
    u = t[e + 1]
    d = t[e + 2]
    if (p < i) i = p
    if (p > l) l = p
    if (u < s) s = u
    if (u > c) c = u
    if (d < r) r = d
    if (d > a) a = d
    e += 3
  }
  n.setTo3D(i, l - i, s, c - s, r, a - r)
}
const tempViewBoundsCoordinates1 = new XYZBounds(null),
  tempWorldBounds = new XYZBounds(null)
const clippedPath = new ClipPolylineUtil()
const tempClippingPolygon = new ClipPolygonUtil()
export function mapAnchorPointsSFCT(t, n, e, o, i, l) {
  let s, c, r
  if (
    o.inputReference &&
    o.inputReference.referenceType === ReferenceType.GEOCENTRIC
  )
    return
  if (l.preferredCentroid)
    if (l.inView) {
      s = tempViewBoundsCoordinates1
      s = o.transformBounds(n, s)
      c = Clipping.computeClipMaskBoundsArrays(s, e)
      if (Clipping.isInside(c)) {
        r = tempWorldBounds
        r = o.inverseTransformation.transformBounds(e, r)
        tempClippingPolygon.setClippingBounds(r)
        centerPointSFCT((t = clipPolygon(t, tempClippingPolygon)), l)
      }
    } else centerPointSFCT(t, l)
  else {
    s = tempViewBoundsCoordinates1
    s = o.transformBounds(n, s)
    c = Clipping.computeClipMaskBoundsArrays(s, e)
    if (Clipping.isInside(c)) {
      r = tempWorldBounds
      r = o.inverseTransformation.transformBounds(e, r)
      clippedPath.setClippingBounds(r)
      clippedPath.rewind()
      clipSFCT(t, clippedPath)
      anchorPointsSFCT(clippedPath.paths, i, o, l, clippedPath.pathData)
    }
  }
}
const tempBounds = new XYZBounds(null)
function calculateMiddleSFCT(t, n) {
  calculateBoundsMultiSFCT(t, tempBounds)
  n.move3D(
    tempBounds.x + tempBounds.width / 2,
    tempBounds.y + tempBounds.height / 2,
    tempBounds.z + tempBounds.depth / 2
  )
  return true
}
export function calculateOnLineSFCT(t, n) {
  const e = Math.floor(t.length / 2)
  const o = t[e].length / 3
  const i = []
  let l = 0
  let s = t[e][0]
  let c = t[e][1]
  let r
  for (r = 1; r < o; r++) {
    const n = t[e][3 * r]
    const o = t[e][3 * r + 1]
    const a = n - s
    const p = o - c
    l += Math.sqrt(a * a + p * p)
    i[r - 1] = l
    s = n
    c = o
  }
  const a = l / 2
  let p = binarySearchNumber(i, a)
  if (p > 0) n.move3D(t[e][3 * p], t[e][3 * p + 1], t[e][3 * p + 2])
  else {
    p = -p - 1
    if (p <= 0) n.move3D(t[e][0], t[e][1], t[e][2])
    else if (p >= i.length) n.move3D(t[e][o - 1], t[e][o - 2], t[e][o - 3])
    else {
      const o = (a - i[p - 1]) / (i[p] - i[p - 1])
      const l = t[e][3 * p]
      const s = t[e][3 * p + 1]
      const c = t[e][3 * p + 2]
      const r = t[e][3 * p + 3]
      const u = t[e][3 * p + 4]
      const d = t[e][3 * p + 5]
      n.move3D(l + (r - l) * o, s + (u - s) * o, c + (d - c) * o)
    }
  }
  return true
}
export function mapAnchorPointSFCT(t, n, e) {
  return n ? calculateMiddleSFCT(t, e) : calculateOnLineSFCT(t, e)
}
export function binarySearchNumber(t, n) {
  let e = 0
  let o = t.length - 1
  let i = 0,
    l
  while (e <= o) {
    i = (o + e) >> 1
    l = t[i]
    if (l === n) return i
    else if (l < n) e = i + 1
    else if (l > n) o = i - 1
  }
  return -(e + 1)
}
function getClipViewBounds(t, n) {
  try {
    return n.transformBounds(t)
  } catch (t) {
    return null
  }
}
function getClipWorldBounds(t, n) {
  try {
    return t.copy().enlarge2D(n)
  } catch (t) {
    return null
  }
}
export function getRepeatingAnchorListSFCT(t, n, e, o, i) {
  const l = getClipWorldBounds(i.mapWorldBounds, 4)
  const s = i.w2v
  const c = i.labelWidth
  const r = !!l && !l.contains2DBounds(n)
  e.updateRepeatingAnchorContext(r, l)
  for (let n = 0; n < t.length; n++) {
    if (e.anchorPointsLength >= i.maxCount) break
    const a = undefined
    const p = undefined
    collectRepeatingAnchorsOnPath(
      worldPathToViewPath(t[n], s),
      r ? getClipViewBounds(l, s) : null,
      c,
      o,
      e,
      s
    )
  }
}
function collectRepeatingAnchorsOnPath(t, n, e, o, i, l) {
  const s = o.minimumGap
  const c = o.initialGap + e / 2
  const r = s + e
  computeRepeatingAnchors(t.viewCoordinates, t.pathLength, r, n, c, i, l)
}
function worldPathToViewPath(t, n) {
  const e = []
  const o = undefined
  return { viewCoordinates: e, pathLength: n._forwardBatch(t, e) }
}
export const CoordinateArrayUtil = {
  calculateBoundsMultiSFCT: calculateBoundsMultiSFCT,
  calculateBoundsSFCT: calculateBoundsSFCT,
  mapAnchorPointsSFCT: mapAnchorPointsSFCT,
  mapAnchorPointSFCT: mapAnchorPointSFCT,
  getRepeatingAnchorListSFCT: getRepeatingAnchorListSFCT,
  anchorPointsSFCT: anchorPointsSFCT,
  binarySearchNumber: binarySearchNumber,
}
