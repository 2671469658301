import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isDefined } from '../../util/Lang.js'
import { RasterTileSetLayer } from './RasterTileSetLayer.js'
export function fetchResponseToWMSGetFeatureInfoResponse(e) {
  return e
    .text()
    .then((r) => ({
      text: r,
      status: String(e.status),
      getHeader: (r) => e.headers.get(r) || null,
    }))
}
class WMSTileSetLayer extends RasterTileSetLayer {
  constructor(e, r) {
    super(e, r)
  }
  get model() {
    return super.model
  }
  get queryable() {
    return this.model.queryable
  }
  getFeatureInfo(e, r, t) {
    const i = this._map
    if (!isDefined(i))
      throw new ProgrammingError(
        'A layer must first be added to a map before feature info can be retrieved.' +
          ' Otherwise the visualization info necessary for the request cannot be determined.'
      )
    if (!isDefined(e) || !isDefined(r))
      throw new ProgrammingError(
        `The x- and y- view coordinates should be defined, but were [${e},${r}]`
      )
    const o = this.calculateTileCoordinateAndTileLocation(e, r)
    if (null === o) return null
    return this.model._getFeatureInfoInTile(o.tile, o.tileX, o.tileY, t)
  }
}
export { WMSTileSetLayer }
