import { Log } from '../../util/Log.js'
import { LRUCache } from '../../util/LRUCache.js'
import {
  areEqual,
  ObliqueMercatorVariant,
  Pole,
} from '../EncodedCoordinateReference.js'
import { ReferenceType } from '../ReferenceType.js'
function PhotonReferenceFactory(e) {
  this._photon = e
  this._cache = new LRUCache(100)
  this._cache.onRemove = function (e, r) {
    r.photon.release()
  }
}
PhotonReferenceFactory.prototype = Object.create(Object.prototype)
PhotonReferenceFactory.prototype.constructor = PhotonReferenceFactory
PhotonReferenceFactory.prototype.getReference = function (e) {
  var r = e.identifier
  var t = r ? r : e
  var a = this._cache.get(t)
  if (a && !areEqual(e, a.encoded)) {
    Log.warn(
      'Warning: reference identifier [' +
        r +
        '] or equality is not correct. ' +
        'The id is used by ' +
        e +
        ' and ' +
        a.encoded +
        ' but they are not equal.'
    )
    a = null
  }
  if (!a) {
    var n = this._createReference(e)
    a = { encoded: e, photon: n }
    if (n) this._cache.put(t, a)
  }
  return a.photon.copy()
}
PhotonReferenceFactory.prototype._createReference = function (e) {
  if (!e)
    throw new Error(
      'PhotonReferenceFactory: Requested unsupported reference: ' + e
    )
  var r = e.type
  var t = e.name
  var a = e.identifier
  if (r === ReferenceType.CARTESIAN)
    return this._photon.ReferenceFactory.createCartesianReference(
      t,
      a,
      e.isYFlipped
    )
  var n = e.geodeticDatum
  if (r === ReferenceType.GEODETIC)
    return this._photon.ReferenceFactory.createGeodeticReference(t, a, n)
  else if (r === ReferenceType.GEOCENTRIC)
    return this._photon.ReferenceFactory.createGeocentricReference(
      t,
      a,
      n,
      e.unitOfMeasure
    )
  else if (r === ReferenceType.GRID) {
    var i = e.projection
    var o = i.type
    if ('AlbersEqualAreaConic' === o)
      return this._photon.ReferenceFactory.createAlbersEqualAreaConicGridReference(
        t,
        a,
        n,
        i.firstParallel,
        i.secondParallel,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('AzimuthalEquidistant' === o)
      return this._photon.ReferenceFactory.createAzimuthalEquidistantGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('Cassini' === o)
      return this._photon.ReferenceFactory.createCassiniGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('CassiniSoldner' === o)
      return this._photon.ReferenceFactory.createCassiniSoldnerGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.originLat,
        i.longitudeLimit,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('DutchStereographic' === o)
      return this._photon.ReferenceFactory.createDutchStereographicGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('EckertIV' === o)
      return this._photon.ReferenceFactory.createEckertIVGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('EckertVI' === o)
      return this._photon.ReferenceFactory.createEckertVIGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('EllipsoidalEquidistantCylindrical' === o)
      return this._photon.ReferenceFactory.createEllipsoidalEquidistantCylindricalGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.standardParallel,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('EquidistantCylindrical' === o)
      return this._photon.ReferenceFactory.createEquidistantCylindricalGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.standardParallel,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('Geodetic' === o)
      return this._photon.ReferenceFactory.createGeodeticGridReference(
        t,
        a,
        n,
        i.scaleX,
        i.scaleY,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('Gnomonic' === o)
      return this._photon.ReferenceFactory.createGnomonicGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('Krovak' === o)
      return this._photon.ReferenceFactory.createKrovakGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        i.azimuth,
        i.pseudoStandardParallel,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('LambertAzimuthalEqualArea' === o)
      return this._photon.ReferenceFactory.createLambertAzimuthalEqualAreaGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('LambertConformal' === o)
      return this._photon.ReferenceFactory.createLambertConformalGridReference(
        t,
        a,
        n,
        i.firstParallel,
        i.secondParallel,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('PseudoMercator' === o)
      return this._photon.ReferenceFactory.createPseudoMercatorGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.trueScaleLatitude,
        i.latitudeMin,
        i.latitudeMax,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('Mercator' === o)
      return this._photon.ReferenceFactory.createMercatorGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.trueScaleLatitude,
        i.latitudeMin,
        i.latitudeMax,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('MillerCylindrical' === o)
      return this._photon.ReferenceFactory.createMillerCylindricalGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('Mollweide' === o)
      return this._photon.ReferenceFactory.createMollweideGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('ObliqueMercator' === o) {
      var s = i.variant
      var c
      if (s == ObliqueMercatorVariant.A)
        c = this._photon.ObliqueMercatorVariant.A
      else if (s == ObliqueMercatorVariant.B)
        c = this._photon.ObliqueMercatorVariant.B
      else
        throw new Error(
          'PhotonReferenceFactory: unknown variant in ObliqueMercator reference: ' +
            s
        )
      return this._photon.ReferenceFactory.createObliqueMercatorGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.standardParallel,
        i.azimuth,
        c,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    } else if ('Orthographic' === o)
      return this._photon.ReferenceFactory.createOrthographicGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('PolarStereographic' === o) {
      var l = i.pole
      var f
      if (l == Pole.NORTH_POLE) f = this._photon.Pole.NorthPole
      else if (l == Pole.SOUTH_POLE) f = this._photon.Pole.SouthPole
      else
        throw new Error(
          'PhotonReferenceFactory: unknown pole in PolarStereographic reference: ' +
            l
        )
      return this._photon.ReferenceFactory.createPolarStereographicGridReference(
        t,
        a,
        n,
        f,
        i.centralMeridian,
        i.trueScaleLatitude,
        i.latitudeExtent,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    } else if ('Polyconic' === o)
      return this._photon.ReferenceFactory.createPolyconicGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('SphericalObliqueMercator' === o)
      return this._photon.ReferenceFactory.createSphericalObliqueMercatorGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.standardParallel,
        i.azimuth,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('Stereographic' === o)
      return this._photon.ReferenceFactory.createStereographicGridReference(
        t,
        a,
        n,
        i.originLon,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('SwissObliqueMercator' === o)
      return this._photon.ReferenceFactory.createSwissObliqueMercatorGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.standardParallel,
        i.azimuth,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
    else if ('TransverseMercator' === o)
      return this._photon.ReferenceFactory.createTransverseMercatorGridReference(
        t,
        a,
        n,
        i.centralMeridian,
        i.originLat,
        e.falseEasting,
        e.falseNorthing,
        e.scale,
        e.unitOfMeasure,
        e.rotation,
        e.negateXAxis,
        e.negateYAxis
      )
  }
  throw new Error(
    'PhotonReferenceFactory: Requested unsupported reference: ' +
      e +
      ' (id=' +
      a +
      ')'
  )
}
PhotonReferenceFactory.prototype.release = function () {
  this._cache.clear()
}
export { PhotonReferenceFactory }
