import { ReferenceType } from './ReferenceType.js'
import { Axis } from './Axis.js'
import { hasWestingAxis } from './AxisInformationUtil.js'
import { hasSouthingAxis } from './AxisInformationUtil.js'
export function encodeReference(e) {
  if (!e)
    throw new Error(
      `PhotonReferenceProvider: Requested unsupported reference: ${e}`
    )
  if (e.referenceType === ReferenceType.CARTESIAN) {
    const t = undefined
    return {
      type: ReferenceType.CARTESIAN,
      name: e.name,
      identifier: e.identifier,
      isYFlipped:
        e.getAxis(Axis.Name.Y).direction === Axis.Direction.DISPLAY_DOWN,
    }
  }
  const t = e.name || ''
  const i = e.identifier || ''
  const r = e.geodeticDatum
  const n = r.ellipsoid
  const o = {
    x: r.x,
    y: r.y,
    z: r.z,
    rotX: r.rotX,
    rotY: r.rotY,
    rotZ: r.rotZ,
    scale: r.scale,
    primeMeridian: r.primeMeridian,
    ellipsoidA: n.a,
    ellipsoidB: n.b,
    ellipsoid1OverF: n.oneOverF,
    ellipsoidE2: n.e2,
  }
  if (e.referenceType === ReferenceType.GEODETIC) {
    const e = undefined
    return {
      type: ReferenceType.GEODETIC,
      name: t,
      identifier: i,
      geodeticDatum: o,
    }
  } else if (e.referenceType === ReferenceType.GEOCENTRIC) {
    const r = undefined
    return {
      type: ReferenceType.GEOCENTRIC,
      name: t,
      identifier: i,
      geodeticDatum: o,
      unitOfMeasure: e.unitOfMeasure,
    }
  } else if (e.referenceType === ReferenceType.GRID) {
    const r = e
    const n = undefined
    return {
      type: ReferenceType.GRID,
      name: t,
      identifier: i,
      geodeticDatum: o,
      projection: r.projection.encode(),
      falseEasting: r.falseEasting,
      falseNorthing: r.falseNorthing,
      scale: r.scale,
      unitOfMeasure: r.unitOfMeasure,
      rotation: r.rotation,
      negateXAxis: hasWestingAxis(r),
      negateYAxis: hasSouthingAxis(r),
    }
  }
  throw new Error(`Unsupported reference: ${e} (id=${i})`)
}
export function areEqual(e, t) {
  if (e.type !== t.type || e.name !== t.name || e.identifier !== t.identifier)
    return false
  if (e.type === ReferenceType.CARTESIAN) return e.isYFlipped === t.isYFlipped
  const i = e
  const r = t
  if (
    i.geodeticDatum.x !== r.geodeticDatum.x ||
    i.geodeticDatum.y !== r.geodeticDatum.y ||
    i.geodeticDatum.z !== r.geodeticDatum.z ||
    i.geodeticDatum.rotX !== r.geodeticDatum.rotX ||
    i.geodeticDatum.rotY !== r.geodeticDatum.rotY ||
    i.geodeticDatum.rotZ !== r.geodeticDatum.rotZ ||
    i.geodeticDatum.primeMeridian !== r.geodeticDatum.primeMeridian ||
    i.geodeticDatum.ellipsoidA !== r.geodeticDatum.ellipsoidA ||
    i.geodeticDatum.ellipsoidB !== r.geodeticDatum.ellipsoidB ||
    i.geodeticDatum.ellipsoid1OverF !== r.geodeticDatum.ellipsoid1OverF
  )
    return false
  if (e.type == ReferenceType.GEODETIC) return true
  else if (e.type == ReferenceType.GEOCENTRIC)
    return e.unitOfMeasure === t.unitOfMeasure
  else if (e.type == ReferenceType.GRID) {
    const i = e.projection
    const r = t.projection
    if (i.type !== r.type) return false
    const n = Object.getOwnPropertyNames(i)
    const o = Object.getOwnPropertyNames(r)
    if (n.length !== o.length) return false
    for (let e = 0; e < n.length; e++) {
      if (n[e] !== o[e]) return false
      if (i[n[e]] !== i[o[e]]) return false
    }
    return true
  } else return false
}
export let Pole = (function (e) {
  e[(e['NORTH_POLE'] = 0)] = 'NORTH_POLE'
  e[(e['SOUTH_POLE'] = 1)] = 'SOUTH_POLE'
  return e
})({})
export let ObliqueMercatorVariant = (function (e) {
  e[(e['A'] = 1)] = 'A'
  e[(e['B'] = 2)] = 'B'
  return e
})({})
