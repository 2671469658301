import { ProgrammingError } from '../../error/ProgrammingError.js'
import { CGUtil } from './CGUtil.js'
import { CGBundleStar } from './CGBundleStar.js'
import { CGVertex } from './CGVertex.js'
function CGSegmentIntersector(t) {
  this._topologyUtil = t
  this._tempPointArray = []
}
CGSegmentIntersector.prototype = Object.create(Object.prototype)
CGSegmentIntersector.prototype.constructor = CGSegmentIntersector
CGSegmentIntersector.prototype._sortSegmentIntersections = function (t, e) {
  var o = t.pos
  var n = this._topologyUtil
  e.sort(function (t, e) {
    var i = n.distancePointPoint(o, t._point)
    var r = n.distancePointPoint(o, e._point)
    return i < r ? -1 : i > r ? 1 : 0
  })
}
CGSegmentIntersector.prototype._segmentsIntersect = function (t, e, o) {
  var n = 0
  if (null !== t.next && null !== e.next) {
    if (
      this._topologyUtil.certainlyNoInteraction4Points(
        t.pos,
        t.next.pos,
        e.pos,
        e.next.pos
      )
    )
      return 0
    var i = t.pos
    var r = t.next.pos
    var s = e.pos
    var l = e.next.pos
    var p
    var a = t.next.pos
    var u = e.next.pos
    if (i.y > r.y || (i.y == r.y && i.x > r.x)) {
      p = i
      i = r
      r = p
    }
    if (s.y > l.y || (s.y == l.y && s.x > l.x)) {
      p = s
      s = l
      l = p
    }
    if (i.y > s.y || (i.y == s.y && i.x > s.x)) {
      p = i
      i = s
      s = p
      p = r
      r = l
      l = p
      p = a
      a = u
      u = p
    }
    if (
      0 ==
      (n = this._topologyUtil.lineSegmentIntersection(
        i,
        r,
        s,
        l,
        this._tempPointArray
      ))
    )
      if (this._topologyUtil.pointsEqual(t.pos, e.pos)) {
        n = 1
        this._tempPointArray[0] = this._topologyUtil.getNewEditablePoint(t.pos)
      } else if (
        null !== t.next &&
        !this._topologyUtil.certainlyNoInteraction3Points(
          t.pos,
          t.next.pos,
          e.pos
        ) &&
        this._topologyUtil.isPointOnLineSegment(t.pos, t.next.pos, e.pos)
      ) {
        n = 1
        this._tempPointArray[0] = this._topologyUtil.getNewEditablePoint(e.pos)
      } else if (
        null !== e.next &&
        !this._topologyUtil.certainlyNoInteraction3Points(
          e.pos,
          e.next.pos,
          t.pos
        ) &&
        this._topologyUtil.isPointOnLineSegment(e.pos, e.next.pos, t.pos)
      ) {
        n = 1
        this._tempPointArray[0] = this._topologyUtil.getNewEditablePoint(t.pos)
      }
  } else if (null !== t.next) {
    if (
      this._topologyUtil.certainlyNoInteraction3Points(t.pos, t.next.pos, e.pos)
    )
      return 0
    if (this._topologyUtil.isPointOnLineSegment(t.pos, t.next.pos, e.pos)) {
      n = 1
      this._tempPointArray[0] = this._topologyUtil.getNewEditablePoint(e.pos)
    }
  } else if (null !== e.next) {
    if (
      this._topologyUtil.certainlyNoInteraction3Points(e.pos, e.next.pos, t.pos)
    )
      return 0
    if (this._topologyUtil.isPointOnLineSegment(e.pos, e.next.pos, t.pos)) {
      n = 1
      this._tempPointArray[0] = this._topologyUtil.getNewEditablePoint(t.pos)
    }
  } else if (this._topologyUtil.pointsEqual(t.pos, e.pos)) {
    n = 1
    this._tempPointArray[0] = this._topologyUtil.getNewEditablePoint(t.pos)
  }
  for (var g = 0; g < n; g++) o[g] = this._tempPointArray[g]
  return n
}
CGSegmentIntersector.prototype._segmentShapeIntersection = function (
  t,
  e,
  o,
  n
) {
  var i = []
  var r = []
  for (var s = 0; s < t.elements.length; s++) {
    var l = t.elements[s]
    var p = true
    for (var a = l; null !== a && (p || a != l); a = a.next) {
      p = false
      if (!e.subBounds.interacts(a.subBounds)) {
        var u = a.subBounds.nextDiff
        if (null !== u) {
          if (null == u.previous)
            throw new ProgrammingError(
              'something brought the vertices in inconsistent state'
            )
          a = u.previous
        }
        continue
      }
      var g = this._segmentsIntersect(a, e, r)
      for (var y = 0; y < g; y++) {
        var f = {}
        f._segment = a
        f._point = r[y].copy()
        i.push(f)
      }
    }
  }
  this._sortSegmentIntersections(e, i)
  for (var s = 0; s < i.length; s++) {
    o.push(i[s]._segment)
    n.push(i[s]._point)
  }
}
CGSegmentIntersector.prototype._findStar = function (t, e) {
  if (
    null !== e.bundleStar &&
    this._topologyUtil.pointsEqual(e.bundleStar.pos, t)
  )
    return e.bundleStar
  if (
    null !== e.next &&
    null !== e.next.bundleStar &&
    this._topologyUtil.pointsEqual(e.next.bundleStar.pos, t)
  )
    return e.next.bundleStar
  if (
    null != e.previous &&
    null !== e.previous.bundleStar &&
    this._topologyUtil.pointsEqual(e.previous.bundleStar.pos, t)
  )
    return e.previous.bundleStar
  return null
}
CGSegmentIntersector.prototype._findStarArray = function (t, e) {
  for (var o = 0; o < e.length; o++) {
    var n = this._findStar(t, e[o])
    if (null !== n) return n
  }
  return null
}
CGSegmentIntersector.prototype.solveIntersections = function (t, e) {
  if (0 === t.elements.length || 0 == e.elements.length) return
  var o = e.getBounds(this._topologyUtil)
  for (var n = 0; n < t.elements.length; n++) {
    var i = true
    var r = t.elements[n]
    while (null !== r && (i || r != t.elements[n])) {
      var s = true
      if (CGUtil.boundsDontIntersectSegment(o, r, this._topologyUtil)) {
        r = r.next
        i = false
        continue
      }
      var l = []
      var p = []
      this._segmentShapeIntersection(e, r, l, p)
      for (var a = 0; a < l.length; a++) {
        var u = p[a].copy()
        var g = l[a]
        var y = this._topologyUtil.pointsEqual(u, r.pos)
        var f = this._topologyUtil.pointsEqual(u, g.pos)
        var h = null !== r.next && this._topologyUtil.pointsEqual(u, r.next.pos)
        var v = null != g.next && this._topologyUtil.pointsEqual(u, g.next.pos)
        var _
        if (y) _ = r
        else if (h) _ = r.next
        else _ = new CGVertex(r.shapeIndex, u)
        var m
        if (f) m = g
        else if (v) m = g.next
        else m = new CGVertex(g.shapeIndex, u)
        var c = _.bundleStar
        var d = m.bundleStar
        var x = null === c ? _.pos : c.pos
        var S = null === d ? m.pos : d.pos
        var U = null
        if (this._topologyUtil.pointsEqual(x, S))
          U = CGBundleStar.mergeBundleStars(c, d, this._topologyUtil)
        if (null === U)
          if (null == (U = this._findStarArray(u, l))) U = this._findStar(u, r)
        if (null === U) U = new CGBundleStar(u)
        var P = y || h
        var I = f || v
        _.setPointLocationOther(true)
        _.bundleStar = U
        if (!P) CGUtil.insertVertexAfter(r, _)
        m.setPointLocationOther(true)
        m.bundleStar = U
        if (!I) CGUtil.insertVertexAfter(g, m)
        U.addEdgeIfNotAlreadyExists(_, +1, this._topologyUtil)
        if (null != _.previous)
          U.addEdgeIfNotAlreadyExists(_.previous, -1, this._topologyUtil)
        U.addEdgeIfNotAlreadyExists(m, +1, this._topologyUtil)
        if (null != m.previous)
          U.addEdgeIfNotAlreadyExists(m.previous, -1, this._topologyUtil)
        if (y && !h && !I) {
          s = false
          break
        }
        r = h && !y ? _.previous : _
        s = true
      }
      if (s) {
        r = r.next
        i = false
      }
      s = true
    }
  }
}
export { CGSegmentIntersector }
