import { isOutsideLayerClip } from '../LayerStyle.js'
const CELL_SIZE = 64
export const LABEL_CONFLICT_PADDING = 3
export let ConflictType = (function (t) {
  t[(t['NO_CONFLICT'] = 0)] = 'NO_CONFLICT'
  t[(t['OVERLAP_WITH_LABEL'] = 1)] = 'OVERLAP_WITH_LABEL'
  t[(t['OUTSIDE_VIEW'] = 2)] = 'OUTSIDE_VIEW'
  t[(t['PARTIALLY_OUTSIDE_VIEW'] = 3)] = 'PARTIALLY_OUTSIDE_VIEW'
  return t
})({})
class Bucket {
  constructor() {
    this._extent = []
    this._elements = []
    this.size = 0
  }
  clear() {
    this.size = 0
  }
  add(t) {
    this._elements[this.size++] = t
  }
  get extent() {
    return this._extent
  }
  set extent(t) {
    this._extent = t
  }
  get elements() {
    return this._elements
  }
}
export class LabelConflictChecker {
  constructor() {
    this._viewBottom = 0
    this._viewTop = 0
    this._viewLeft = 0
    this._viewRight = 0
    this.mapHeight = 0
    this.mapWidth = 0
    this.cellSize = 0
    this.buckets = []
  }
  resetViewWidthHeight(t, e, i, s) {
    if (this._viewRight !== i || this._viewBottom !== s) {
      this._viewRight = i
      this._viewBottom = s
      this._viewLeft = t
      this._viewTop = e
      const h = undefined
      const o = (CELL_SIZE * 1) | 0
      this.cellSize = o
      this.mapWidth = Math.ceil(i / o)
      this.mapHeight = Math.ceil(s / o)
      this.buckets = new Array(this.mapWidth * this.mapHeight)
      for (let t = 0; t < this.mapWidth; t++)
        for (let e = 0; e < this.mapHeight; e++) {
          const i = t * o
          const s = e * o
          const h = new Bucket()
          h.extent = [i, s, i + o, s + o]
          this.buckets[t + e * this.mapWidth] = h
        }
    }
  }
  clear() {
    for (let t = this.buckets.length - 1; t >= 0; t--) this.buckets[t].clear()
  }
  isValidPosition(t, e) {
    const i = t.getOLeft(true)
    const s = t.getORight(true)
    const h = t.getOTop(true)
    const o = t.getOBottom(true)
    if (isOutsideLayerClip(t, this._viewBottom, e))
      return ConflictType.OUTSIDE_VIEW
    if (
      this._viewLeft < this._viewRight &&
      this._viewTop < this._viewBottom &&
      this._viewLeft <= i &&
      this._viewTop <= h &&
      this._viewRight >= s &&
      this._viewBottom >= o
    )
      return this.intersects(t)
        ? ConflictType.OVERLAP_WITH_LABEL
        : ConflictType.NO_CONFLICT
    if (
      this._viewLeft < s &&
      i < this._viewRight &&
      this._viewTop < o &&
      h < this._viewBottom
    )
      return this.intersects(t)
        ? ConflictType.OVERLAP_WITH_LABEL
        : ConflictType.PARTIALLY_OUTSIDE_VIEW
    return ConflictType.OUTSIDE_VIEW
  }
  addPosition(t) {
    const e = this.buckets
    const i = this.cellSize
    const s = this.mapWidth
    const h = this.mapHeight
    let o = (t.getOLeft(true) / i) | 0
    if (o < 0) o = 0
    let n = (t.getOTop(true) / i) | 0
    if (n < 0) n = 0
    let c = ((t.getORight(true) / i) | 0) + 1
    if (c > s) c = s
    let r = ((t.getOBottom(true) / i) | 0) + 1
    if (r > h) r = h
    const l = c - o
    const _ = undefined
    for (let i = n * s, h = i + (r - n) * s; i < h; i += s)
      for (let s = i + o, h = s + l; s < h; s++) e[s].add(t)
  }
  intersects(t) {
    let e = (t.getOLeft(true) / this.cellSize) | 0
    if (e < 0) e = 0
    let i = (t.getOTop(true) / this.cellSize) | 0
    if (i < 0) i = 0
    let s = ((t.getORight(true) / this.cellSize) | 0) + 1
    if (s > this.mapWidth) s = this.mapWidth
    let h = ((t.getOBottom(true) / this.cellSize) | 0) + 1
    if (h > this.mapHeight) h = this.mapHeight
    const o = s - e
    const n = h - i
    const c = i * this.mapWidth + n * this.mapWidth
    for (let s = i * this.mapWidth; s < c; s += this.mapWidth) {
      const i = s + e + o
      for (let h = s + e; h < i; h++) {
        const e = this.buckets[h]
        const i = e.elements
        for (let s = 0, h = e.size; s < h; s += 1)
          if (i[s].intersects(t, true)) return true
      }
    }
    return false
  }
  __draw(t) {
    const e = undefined
    this.buckets.forEach((e) => {
      const i = e.extent[0]
      const s = e.extent[1]
      const h = e.extent[2] - i
      const o = e.extent[3] - s
      if (0 === e.size) {
        t.strokeStyle = 'rgba(0,255,0,1)'
        t.lineWidth = 0.5
      } else {
        t.strokeStyle = 'rgba(255,0,0,1)'
        t.lineWidth = 3
      }
      t.strokeRect(i, s, h, o)
    })
  }
  isLabelAllowed(t) {
    const e = this.isValidPosition(t)
    return (
      e === ConflictType.NO_CONFLICT ||
      e === ConflictType.PARTIALLY_OUTSIDE_VIEW ||
      e === ConflictType.OUTSIDE_VIEW
    )
  }
}
