import { Log } from '../../../util/Log.js'
import { isUndefined } from '../../../util/Lang.js'
export class Quaternion {
  constructor(t, s, i, h) {
    this._x = t || 0
    this._y = s || 0
    this._z = i || 0
    this._w = isUndefined(h) ? 1 : h
  }
  set(t, s, i, h) {
    this._x = t
    this._y = s
    this._z = i
    this._w = h
    return this
  }
  clone() {
    return new Quaternion(this._x, this._y, this._z, this._w)
  }
  copy(t) {
    this._x = t.x
    this._y = t.y
    this._z = t.z
    this._w = t.w
    return this
  }
  setFromAxisAngle(t, s) {
    const i = s / 2
    const h = Math.sin(i)
    this._x = t.x * h
    this._y = t.y * h
    this._z = t.z * h
    this._w = Math.cos(i)
    return this
  }
  setFromRotationMatrix(t) {
    const s = t.elements
    const i = s[0]
    const h = s[4]
    const _ = s[8]
    const n = s[1]
    const e = s[5]
    const r = s[9]
    const o = s[2]
    const y = s[6]
    const u = s[10]
    const x = i + e + u
    let z
    if (x > 0) {
      z = 0.5 / Math.sqrt(x + 1)
      this._w = 0.25 / z
      this._x = (y - r) * z
      this._y = (_ - o) * z
      this._z = (n - h) * z
    } else if (i > e && i > u) {
      z = 2 * Math.sqrt(1 + i - e - u)
      this._w = (y - r) / z
      this._x = 0.25 * z
      this._y = (h + n) / z
      this._z = (_ + o) / z
    } else if (e > u) {
      z = 2 * Math.sqrt(1 + e - i - u)
      this._w = (_ - o) / z
      this._x = (h + n) / z
      this._y = 0.25 * z
      this._z = (r + y) / z
    } else {
      z = 2 * Math.sqrt(1 + u - i - e)
      this._w = (n - h) / z
      this._x = (_ + o) / z
      this._y = (r + y) / z
      this._z = 0.25 * z
    }
    return this
  }
  inverse() {
    this.conjugate().normalize()
    return this
  }
  conjugate() {
    this._x *= -1
    this._y *= -1
    this._z *= -1
    return this
  }
  dot(t) {
    return this._x * t.x + this.y * t.y + this._z * t.z + this._w * t.w
  }
  lengthSq() {
    return (
      this._x * this._x +
      this._y * this._y +
      this._z * this._z +
      this._w * this._w
    )
  }
  length() {
    return Math.sqrt(
      this._x * this._x +
        this._y * this._y +
        this._z * this._z +
        this._w * this._w
    )
  }
  normalize() {
    let t = this.length()
    if (0 === t) {
      this._x = 0
      this._y = 0
      this._z = 0
      this._w = 1
    } else {
      t = 1 / t
      this._x = this._x * t
      this._y = this._y * t
      this._z = this._z * t
      this._w = this._w * t
    }
    return this
  }
  multiply(t, s) {
    if (!isUndefined(s)) {
      Log.warn(
        'Quaternion: .multiply() now only accepts one argument. Use .multiplyQuaternions( a, b ) instead.'
      )
      return this.multiplyQuaternions(t, s)
    }
    return this.multiplyQuaternions(this, t)
  }
  multiplyQuaternions(t, s) {
    const i = t._x
    const h = t._y
    const _ = t._z
    const n = t._w
    const e = s._x
    const r = s._y
    const o = s._z
    const y = s._w
    this._x = i * y + n * e + h * o - _ * r
    this._y = h * y + n * r + _ * e - i * o
    this._z = _ * y + n * o + i * r - h * e
    this._w = n * y - i * e - h * r - _ * o
    return this
  }
  slerp(t, s) {
    if (0 === s) return this
    if (1 === s) return this.copy(t)
    const i = this._x
    const h = this._y
    const _ = this._z
    const n = this._w
    let e = n * t._w + i * t._x + h * t._y + _ * t._z
    if (e < 0) {
      this._w = -t._w
      this._x = -t._x
      this._y = -t._y
      this._z = -t._z
      e = -e
    } else this.copy(t)
    if (e >= 1) {
      this._w = n
      this._x = i
      this._y = h
      this._z = _
      return this
    }
    const r = Math.sqrt(1 - e * e)
    if (Math.abs(r) < 0.001) {
      this._w = 0.5 * (n + this._w)
      this._x = 0.5 * (i + this._x)
      this._y = 0.5 * (h + this._y)
      this._z = 0.5 * (_ + this._z)
      return this
    }
    const o = Math.atan2(r, e)
    const y = Math.sin((1 - s) * o) / r,
      u = Math.sin(s * o) / r
    this._w = n * y + this._w * u
    this._x = i * y + this._x * u
    this._y = h * y + this._y * u
    this._z = _ * y + this._z * u
    return this
  }
  equals(t) {
    return (
      t._x === this._x &&
      t._y === this._y &&
      t._z === this._z &&
      t._w === this._w
    )
  }
  fromArray(t, s) {
    if (isUndefined(s)) s = 0
    this._x = t[s]
    this._y = t[s + 1]
    this._z = t[s + 2]
    this._w = t[s + 3]
    return this
  }
  toArray(t, s) {
    if (isUndefined(t)) t = []
    if (isUndefined(s)) s = 0
    t[s] = this._x
    t[s + 1] = this._y
    t[s + 2] = this._z
    t[s + 3] = this._w
    return t
  }
  get x() {
    return this._x
  }
  set x(t) {
    this._x = t
  }
  get y() {
    return this._y
  }
  set y(t) {
    this._y = t
  }
  get z() {
    return this._z
  }
  set z(t) {
    this._z = t
  }
  get w() {
    return this._w
  }
  set w(t) {
    this._w = t
  }
  setFromUnitVectors = (() => {
    let t
    let s
    const i = 1e-6
    return (h, _) => {
      if (isUndefined(t)) {
        t = h.clone()
        t.set(0, 0, 0)
      }
      s = h.dot(_) + 1
      if (s < i) {
        s = 0
        if (Math.abs(h.x) > Math.abs(h.z)) t.set(-h.y, h.x, 0)
        else t.set(0, -h.z, h.y)
      } else t.crossVectors(h, _)
      this._x = t.x
      this._y = t.y
      this._z = t.z
      this._w = s
      this.normalize()
      return this
    }
  })()
}
