import { ringContainsXY2DFlatCoordList } from '../../../util/Cartesian.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
const $tempArray1 = []
function generalPathToArray(e, t) {
  let r = 0
  const n = e.subPathLength(t)
  const o = []
  let a
  o.length = 3 * n
  while (r < n) {
    a = 3 * r
    o[a] = e.getX(t, r)
    o[a + 1] = e.getY(t, r)
    o[a + 2] = e.getZ(t, r)
    r += 1
  }
  return o
}
function makeLineArray(e) {
  const t = generalPathToArray(e, 0)
  t.type = ShapeType.POLYLINE
  return t
}
function makeMultiLineArray(e) {
  const t = []
  const r = e.subPathCount()
  for (let n = 0; n < r; n++)
    if (0 !== e.subPathLength(n)) {
      const r = generalPathToArray(e, n)
      r.type = ShapeType.POLYLINE
      t.push(r)
    }
  t.type = ShapeType.SHAPE_LIST
  return t
}
function closeSFCT(e) {
  const t = e.length - 3
  if (e[0] !== e[t] || e[1] !== e[t + 1] || e[2] !== e[t + 2]) {
    e[t + 3] = e[0]
    e[t + 4] = e[1]
    e[t + 5] = e[2]
  }
}
function makeSimplePolygonArray(e) {
  const t = generalPathToArray(e, 0)
  closeSFCT(t)
  t.type = ShapeType.POLYGON
  return t
}
function isHoleInRing(e, t) {
  for (let r = 0; r < t.length; r += 3)
    if (!ringContainsXY2DFlatCoordList(e, t[r], t[r + 1])) return false
  return true
}
function isHoleInPoly(e, t) {
  let r = e.length,
    n = false
  while (r--)
    if (isHoleInRing(e[r], t)) {
      n = true
      r = 0
    }
  return n
}
function _makePolygonArraysFromMultiPath(e) {
  let t = []
  const r = []
  let n = []
  r.type = ShapeType.SHAPE_LIST
  n.type = ShapeType.COMPLEX_POLYGON
  const o = e.subPathCount()
  for (let a = 0; a < o; a++)
    if (e.subPathLength(a) > 0) {
      $tempArray1.length = 0
      t = $tempArray1
      t.length = 2 * e.subPathLength(a)
      const o = e.subPathLength(a)
      for (let r = 0; r < o; r++) {
        const n = 3 * r
        t[n] = e.getX(a, r)
        t[n + 1] = e.getY(a, r)
        t[n + 2] = e.getZ(a, r)
      }
      closeSFCT(t)
      const l = t.slice()
      l.type = ShapeType.POLYGON
      if (e.subPathClosesPolygon(a)) {
        n.push(l)
        r.push(n)
        n = []
        n.type = ShapeType.COMPLEX_POLYGON
      } else {
        let e = false
        const t = r.length
        for (let n = 0; n < t; n++)
          if (isHoleInPoly(r[n], l)) {
            r[n].push(l)
            e = true
            n = t
          }
        if (!e) n.push(l)
      }
    }
  if (n.length > 0) r.push(n)
  return r
}
function makeComplexPolygonArrays(e) {
  const t = _makePolygonArraysFromMultiPath(e)
  return 1 === t.length ? t[0] : t
}
export const GeometryBuilder = {
  buildStrokeGeometry: function (e) {
    const t = e.subPathCount()
    let r
    if (1 === t) r = makeLineArray(e)
    else if (t > 1) r = makeMultiLineArray(e)
    else
      throw new ProgrammingError(
        `GeometryBuilder::buildStrokeGeometry - the subpath count of the generalpath is invalid. count =${t}`
      )
    return r
  },
  buildFillGeometry: function (e) {
    const t = e.subPathCount()
    if (1 === t) return makeSimplePolygonArray(e)
    else if (t > 1) return makeComplexPolygonArrays(e)
    else
      throw new ProgrammingError(
        `GeometryBuilder::buildFillGeometry - the subpath count of the generalpath is invalid. count =${t}`
      )
  },
}
