import { Sector } from './Sector.js'
import { XYZArcBandUtil } from './XYZArcBandUtil.js'
import { LLHSector } from './LLHSector.js'
export class XYZSector extends Sector {
  constructor(t, r, e, c, n) {
    super(t, r, e, c, n)
    this.validateReference(t)
  }
  get isGeodetic() {
    return false
  }
  copy() {
    return new LLHSector(
      this.reference,
      this.center.copy(),
      this.radius,
      this.startAzimuth,
      this.sweepAngle
    )
  }
  get coordinateType() {
    return XYZArcBandUtil.coordinateType
  }
  distance2D(t, r) {
    return XYZArcBandUtil.distance2D(t, r)
  }
  forwardAzimuth2D(t, r) {
    return XYZArcBandUtil.forwardAzimuth2D(t, r)
  }
  calculatePoint(t, r) {
    return XYZArcBandUtil.calculatePoint(t, r, this.center)
  }
  contains2DCoordinates(t, r) {
    return XYZArcBandUtil.contains2DCoordinates(t, r, this)
  }
  calculateBounds() {
    return XYZArcBandUtil.calculateBounds(this)
  }
}
