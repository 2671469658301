import { XMLSchema } from '../../util/XMLSchema.js'
import { RasterDataType } from '../../model/tileset/RasterDataType.js'
import { RasterSamplingMode } from '../../model/tileset/RasterSamplingMode.js'
import { CapabilitiesParserUtil } from './common/CapabilitiesParserUtil.js'
import { LTSQuerySchema } from './LTSQuerySchema.js'
import { LTSCapabilitiesCoverage } from './LTSCapabilitiesCoverage.js'
import { Lang } from '../../util/Lang.js'
function LTSQueryParser(e) {
  var a
  var r = new XMLSchema(LTSQuerySchema).parse(e)
  this._rawMetadata = r['Metadata']
}
LTSQueryParser.prototype = Object.create(Object.prototype)
LTSQueryParser.prototype.parseCoverages = function () {
  var e = this._rawMetadata.RasterCoverageMetadata.map(
    this._parseRasterCoverage,
    this
  )
  return CapabilitiesParserUtil.freeze(e)
}
LTSQueryParser.prototype._parseRasterCoverage = function (e) {
  var a = e.BoundingBox
  return new LTSCapabilitiesCoverage({
    id: e.id,
    type: this._parseRasterCoverageType(e),
    name: e.Name,
    abstract: e.Abstract,
    level0Rows: e.Level0RowCount,
    level0Columns: e.Level0ColumnCount,
    tileWidth: e.TileWidth,
    tileHeight: e.TileHeight,
    samplingMode: this._parseRasterSamplingMode(e.SamplingMode),
    referenceName: e.GeoReference,
    bounds: { x: a.X, y: a.Y, width: a.Width, height: a.Height },
  })
}
LTSQueryParser.prototype._parseRasterCoverageType = function (e) {
  var a
  switch (e.Type.toUpperCase()) {
    case 'ELEVATION':
      return RasterDataType.ELEVATION
    case 'IMAGE':
      return RasterDataType.IMAGE
    case 'RASTER':
      if (
        Lang.isDefined(e.MeasurementBand) &&
        ((Lang.isDefined(e.MeasurementBand.UnitOfMeasure) &&
          'terrain height' ===
            e.MeasurementBand.UnitOfMeasure.measureTypeCode) ||
          ('SIGNED_SHORT' === e.MeasurementBand.DataType2 &&
            16 === e.MeasurementBand.SignificantBits))
      )
        return RasterDataType.ELEVATION
      else return null
    default:
      return null
  }
}
LTSQueryParser.prototype._parseRasterSamplingMode = function (e) {
  switch ((e = e.toUpperCase())) {
    case 'AREA':
      return RasterSamplingMode.AREA
    case 'POINT':
      return RasterSamplingMode.POINT
    default:
      return null
  }
}
export { LTSQueryParser }
