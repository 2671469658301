import { ProgrammingError } from '../error/ProgrammingError.js'
import { Geodetic } from '../projection/Geodetic.js'
import { CartesianReference } from '../reference/CartesianReference.js'
import { GeocentricReference } from '../reference/GeocentricReference.js'
import { GeodeticReference } from '../reference/GeodeticReference.js'
import { GeoReference } from '../reference/GeoReference.js'
import { GridReference } from '../reference/GridReference.js'
import { HeightReferenceType } from '../reference/HeightReference.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { TopocentricReference } from '../reference/TopocentricReference.js'
import { AboveTerrainTransformation } from './AboveTerrainTransformation.js'
import { ChainedTransformation } from './ChainedTransformation.js'
import { GeocentricGridTransformation } from './GeocentricGridTransformation.js'
import {
  GeodeticEquidistantTransformation,
  isCompatibleGeodeticEq,
} from './GeodeticEquidistantTransformation.js'
import { GeodeticGeocentricTransformation } from './GeodeticGeocentricTransformation.js'
import { GeodeticGeodeticPlateCarreeTransformation } from './GeodeticGeodeticPlateCarreeTransformation.js'
import { GeodeticGeodeticTransformation } from './GeodeticGeodeticTransformation.js'
import { GeodeticGridTransformation } from './GeodeticGridTransformation.js'
import {
  GeodeticMercatorTransformation,
  isCompatibleGeodeticMercator,
} from './GeodeticMercatorTransformation.js'
import {
  GeodeticToPolarStereographicTransformation,
  isCompatibleGeodeticPolarStereo,
} from './GeodeticToPolarStereographicTransformation.js'
import { GridGridTransformation } from './GridGridTransformation.js'
import { IdentityTransformation } from './IdentityTransformation.js'
import {
  areCompatibleForScaleTransformation,
  ScaledTransformation,
} from './ScaledTransformation.js'
import { TopocentricGeocentricTransformation } from './TopocentricGeocentricTransformation.js'
function getTransformation(e, r, n) {
  let o = null
  let t = false
  const i = {
    createTransformation: createTransformation,
    isTransformationRequired: isTransformationRequired,
    createTransformationWithOptions: createTransformationWithOptions,
  }
  function a() {
    t = !t
    const n = e
    e = r
    r = n
  }
  if (r instanceof GeodeticReference) a()
  if (r instanceof TopocentricReference) a()
  if (e instanceof TopocentricReference) {
    const n = getOrigin(e)
    if (r instanceof GeocentricReference)
      o = new TopocentricGeocentricTransformation(e, n.llh, n.geocentric, r, i)
    else if (r instanceof GeodeticReference) {
      let t = new GeocentricReference({ geodeticDatum: r.geodeticDatum })
      let a = new TopocentricGeocentricTransformation(
        e,
        n.llh,
        n.geocentric,
        t,
        i
      )
      let c = new GeodeticGeocentricTransformation(r, t, i)
        .inverseTransformation
      o = new ChainedTransformation(a, c)
    } else if (r instanceof GridReference) {
      let t = new GeocentricReference({ geodeticDatum: r.geodeticDatum })
      let a = new TopocentricGeocentricTransformation(
        e,
        n.llh,
        n.geocentric,
        t,
        i
      )
      let c = new GeocentricGridTransformation(t, r, i)
      o = new ChainedTransformation(a, c)
    } else if (r instanceof TopocentricReference) {
      const t = getOrigin(r)
      let a = new GeocentricReference({ geodeticDatum: e.geodeticDatum })
      let c = new TopocentricGeocentricTransformation(
        e,
        n.llh,
        n.geocentric,
        a,
        i
      )
      let f = new TopocentricGeocentricTransformation(
        r,
        t.llh,
        t.geocentric,
        a,
        i
      ).inverseTransformation
      o = new ChainedTransformation(c, f)
    }
  }
  if (e instanceof GeocentricReference && r instanceof GridReference)
    o = new GeocentricGridTransformation(e, r, i)
  else if (r instanceof GeocentricReference && e instanceof GridReference) {
    t = true
    o = new GeocentricGridTransformation(r, e, i)
  } else if (e instanceof GridReference && r instanceof GridReference)
    o = new GridGridTransformation(e, r, i)
  else if (e instanceof GeodeticReference)
    if (r instanceof GridReference)
      if (
        e.geodeticDatum.equals(r.geodeticDatum) &&
        r.projection instanceof Geodetic
      )
        o = new GeodeticGeodeticPlateCarreeTransformation(e, r)
      else if (isCompatibleGeodeticEq(e, r))
        o = new GeodeticEquidistantTransformation(e, r, i)
      else if (isCompatibleGeodeticMercator(e, r))
        o = new GeodeticMercatorTransformation(e, r, i)
      else if (n.usePolarOptimization && isCompatibleGeodeticPolarStereo(e, r))
        o = new GeodeticToPolarStereographicTransformation(e, r, i)
      else o = new GeodeticGridTransformation(e, r, i)
    else if (r instanceof GeodeticReference)
      o = new GeodeticGeodeticTransformation(e, r, i)
    else if (r instanceof GeocentricReference)
      o = new GeodeticGeocentricTransformation(e, r, i)
  if (null != o && t) o = o.inverseTransformation
  if (null !== o) {
    const n =
      e instanceof GeoReference &&
      e.heightReference &&
      e.heightReference.identifier === HeightReferenceType.ABOVE_TERRAIN
    const t =
      r instanceof GeoReference &&
      r.heightReference &&
      r.heightReference.identifier === HeightReferenceType.ABOVE_TERRAIN
    const a =
      e instanceof GeoReference &&
      r instanceof GeoReference &&
      !e.geodeticDatum.equals(r.geodeticDatum)
    const c = undefined
    if ((n || t) && a) o = new AboveTerrainTransformation(o, i)
  }
  return o
}
function isEquivalent(e, r) {
  if (e === r || e.equals(r)) return true
  if (!e.geodeticDatum.equals(r.geodeticDatum)) return false
  if (e instanceof GeodeticReference && r instanceof GridReference)
    return isGeodetic(r)
  else if (e instanceof GridReference && r instanceof GeodeticReference)
    return isGeodetic(e)
  else return false
}
function isGeodetic(e) {
  if (
    1 === e.scale &&
    1 === e.unitOfMeasure &&
    0 === e.falseEasting &&
    0 === e.falseNorthing &&
    e.projection instanceof Geodetic
  ) {
    const r = e.projection
    if (
      0 === r.getOrigin().x &&
      0 === r.getOrigin().y &&
      1 === r.getScaleFactorX() &&
      1 === r.getScaleFactorY()
    )
      return true
  }
  return false
}
function getCartesianTransformation(e, r) {
  if (!(e instanceof CartesianReference) || !(r instanceof CartesianReference))
    throw new ProgrammingError(
      'Cannot create a transformation between a cartesian reference and a non-cartesian reference'
    )
  if (!areCompatibleForScaleTransformation(e, r)) return null
  return new ScaledTransformation(e, r)
}
function createTransformation(e, r) {
  return createTransformationWithOptions(e, r, { usePolarOptimization: false })
}
export function createTransformationWithOptions(e, r, n) {
  if (null === e || 'undefined' === typeof e)
    throw new ProgrammingError(
      'Cannot create a transformation without a source reference'
    )
  if (null === r || 'undefined' === typeof r)
    throw new ProgrammingError(
      'Cannot create a transformation without a destination reference'
    )
  if (e instanceof CartesianReference || r instanceof CartesianReference)
    if (e instanceof CartesianReference && r instanceof CartesianReference) {
      const n = getCartesianTransformation(e, r)
      if (null === n)
        throw new ProgrammingError(
          'References are not compatible. cannot transform between the two'
        )
      else return n
    } else
      throw new ProgrammingError(
        'Cannot create a transformation between a Cartesian Reference and a non-Cartesian Reference'
      )
  if (isEquivalent(e, r)) return new IdentityTransformation(e, r)
  const o = getTransformation(e, r, n)
  if (null === o)
    throw new ProgrammingError(
      `Cannot create transformation from ${e.name} to ${r.name}`
    )
  return o
}
function isTransformationRequired(e, r) {
  return !isEquivalent(e, r)
}
function getOrigin(e) {
  let r, n
  const o = e.origin
  if (o.reference?.referenceType === ReferenceType.GEODETIC) r = o
  else if (o.reference?.referenceType === ReferenceType.GEOCENTRIC) n = o
  const t = e.geodeticDatum
  if (!r) {
    const e = undefined
    r = createTransformation(
      o.reference,
      new GeodeticReference({ geodeticDatum: t })
    ).transform(o)
  }
  if (!n) {
    const e = undefined
    n = createTransformation(
      o.reference,
      new GeocentricReference({ geodeticDatum: t })
    ).transform(o)
  }
  return { llh: r, geocentric: n }
}
export { createTransformation, isTransformationRequired }
