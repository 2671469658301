import { Photon } from '../../gen/photon/photon_painter.js'
import { createHTML5Canvas } from '../HTML5Canvas.js'
export class PhotonSurface {
  constructor(t, e) {
    const n = (e = e || {}).width
    const o = e.height
    this._canvas = createHTML5Canvas(n, o)
    t.appendChild(this._canvas)
    this._canvas.style.position = 'absolute'
    this._canvas.style.top = '0px'
    this._canvas.style.left = '0px'
    let s = 2
    if (window.location.search.indexOf('webgl=1') > 0) {
      console.log('Forcing WebGL1 context.')
      s = 1
    }
    if (window.location.search.indexOf('webgl=2') > 0) {
      console.log('Forcing WebGL2 context.')
      s = 2
    }
    const i = Photon.GL.createContext(this._canvas, {
      enableExtensionsByDefault: true,
      majorVersion: s,
      minorVersion: 0,
      antialias: true,
      stencil: true,
      preserveDrawingBuffer: true,
      alpha: true,
      premultipliedAlpha: true,
    })
    if (i <= 0)
      throw new Error(
        'Error while creating PhotonSurface. WebGL disabled or unsupported?'
      )
    this._glContext = Photon.GL.getContext(i)
    this._glContext.GLctx.lcd_context_id = i
    Photon.GL.makeContextCurrent(this._glContext.handle)
    this._photonGraphics = Photon.Graphics.create()
  }
  release() {
    const t = this._glContext.GLctx
    this._photonGraphics.release()
    Photon.GL.deleteContext(this._glContext.handle)
    this._glContext = null
    const e = this._canvas.parentNode
    if (e) e.removeChild(this._canvas)
    const n = t.getExtension('WEBGL_lose_context')
    if (n) n.loseContext()
  }
  resize(t, e) {
    this._canvas.setAttribute('width', `${t}px`)
    this._canvas.setAttribute('height', `${e}px`)
    this._photonGraphics.setViewPort(0, 0, t, e)
  }
  getCanvasNode() {
    return this._canvas
  }
  getGLContext() {
    return this._glContext.GLctx
  }
  getContextHandle() {
    return this._glContext.handle
  }
  getGraphics() {
    return this._photonGraphics
  }
  setZIndex(t) {
    this._canvas.style.zIndex = t.toString()
  }
}
export function isSupported() {
  try {
    const t = document.createElement('div')
    const e = undefined
    new PhotonSurface(t, { width: 10, height: 10 }).release()
    return true
  } catch (t) {
    return false
  }
}
