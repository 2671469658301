import { Photon } from '../../../../gen/photon/photon_painter.js'
import { isUndefined } from '../../../../util/Lang.js'
import { LineMarkerType } from '../../LineMarkerType.js'
import { ArrowType } from '../ArrowType.js'
import { normalizeDashes } from '../DashedLineUtil.js'
import { ParallelLinePattern } from '../pattern/ParallelLinePattern.js'
import { GapPattern } from '../pattern/GapPattern.js'
import {
  createPhotonAppendPattern,
  createPhotonArrowPattern,
  createPhotonComposePattern,
  createPhotonCSPattern,
  createPhotonGapPattern,
  createPhotonParallelLinePattern,
  releaseAllPhotonPatterns,
  DEFAULT_STROKE_STYLE_COLOR,
} from './PhotonPatternBuilder.js'
import { arrow } from '../PatternFactory.js'
import { PHOTON_STATE_MAP } from './PhotonStateContainer.js'
import { getOffset } from '../../OffsetsUtil.js'
import { PIXEL_UOM } from '../../../../uom/UnitOfMeasureUtil.js'
const DEFAULT_ARROW_SIZE = 20
export const DEFAULT_STROKE_STYLE = {
  color: DEFAULT_STROKE_STYLE_COLOR,
  width: 2,
}
export function createPhotonComplexStrokeStyle(e, t, o, r, n) {
  const a = e.ComplexStrokeBuilderFactory.createStyleBuilder(t)
  const i = isComplexStrokedLineStyle(o.stroke)
    ? createComplexStrokedLineStyle(e, a, o.stroke, n)
    : createComplexStrokeStyleForSimpleLineStyle(e, a, o.stroke, n)
  a.release()
  return i
}
function createPhotonFallbackPattern(e, t) {
  const o = e.ComplexStrokeBuilderFactory.createCombineBuilder()
  o.reuseFallback()
  o.lengthFixed(t)
  const r = o.build()
  o.release()
  return r
}
function createPhotonDashPattern(e, t, o, r) {
  const n = t.length
  let a
  if (n > 0) {
    const i = []
    for (let a = 0; a < n; a++)
      if (t[a].isGap) {
        const o = new GapPattern(t[a].length, false)
        i.push(createPhotonGapPattern(e, o))
      } else {
        const n = new ParallelLinePattern({
          length: t[a].length,
          relative: false,
          line: { width: o, color: r ?? void 0 },
        })
        i.push(createPhotonParallelLinePattern(e, n))
      }
    a = createPhotonAppendPattern(e, i)
    releaseAllPhotonPatterns(i)
  } else {
    const t = new ParallelLinePattern({
      length: 1,
      relative: false,
      line: { width: o, color: r ?? void 0 },
      offset: 0,
    })
    a = createPhotonParallelLinePattern(e, t)
  }
  return a
}
function isComplexStrokedLineStyle(e) {
  return (
    !isUndefined(e.decorations) &&
    !isUndefined(e.regular) &&
    !isUndefined(e.fallback)
  )
}
function createComplexStrokedLineStyle(e, t, o, r) {
  const n = o.decorations
  const a = o.regular
  const i = o.fallback
  const l = o.sharpAngleThreshold
  const s = o.lineJoin
  const c = PHOTON_STATE_MAP.get(o)
  if (n)
    for (let o = 0; o < n.length; o++) {
      const r = n[o].location
      const a = createPhotonCSPattern(e, c, n[o].pattern)
      const i = toPhotonAlignment(e, n[o].alignment)
      t.decoration(r, a, i)
      a.release()
    }
  if (a) {
    const o = 0
    const r = createPhotonCSPattern(e, c, a)
    t.regular(r, o)
    r.release()
  }
  if (i) {
    const o = 0
    const r = createPhotonCSPattern(e, c, i)
    t.fallback(r, o)
    r.release()
  }
  t.sharpAngleThreshold(l)
  t.lineJoin(toPhotonLineJoin(e, s ?? 'round'))
  t.isWorldSize(o.isWorldSize)
  const { worldSizedOffset: P, offsetX: h, offsetY: d } = getOffset(o, r)
  t.offset(
    P
      ? Photon.ShapeScalingMode.WorldScaling
      : Photon.ShapeScalingMode.ViewScaling,
    h,
    d
  )
  if (o.isWorldSize) t.scaleFactor(o.scaleFactor)
  t.screenOriented(!o.isWorldSize)
  return t.build()
}
function createComplexStrokeStyleForSimpleLineStyle(e, t, o, r) {
  const n = o.strokeStyle || DEFAULT_STROKE_STYLE
  const a = undefined
  const i = !!o.isWorldSize ? r.getPhotonLineStyleWorldSizes(o) : o
  const l = i.width || 1
  const s = n.color
  if (
    n.beginMarker &&
    n.beginMarker.size &&
    n.beginMarker.type === LineMarkerType.ARROW
  ) {
    const o = getMarkerSize(r, n, n.beginMarker.size)
    const a = arrow({
      type: ArrowType.PLAIN,
      size: o,
      offset: 0,
      forward: false,
      line: { width: l, color: s },
    })
    const i = createPhotonArrowPattern(e, a)
    const c = createPhotonFallbackPattern(e, o)
    const P = createPhotonComposePattern(e, [i, c])
    i.release()
    c.release()
    t.decoration(0, P, e.HorizontalAlignment.Left)
    P.release()
  }
  if (
    n.endMarker &&
    n.endMarker.size &&
    n.endMarker.type === LineMarkerType.ARROW
  ) {
    const o = getMarkerSize(r, n, n.endMarker.size)
    const a = arrow({
      type: ArrowType.PLAIN,
      size: o,
      offset: 0,
      forward: true,
      line: { width: l, color: s },
    })
    const i = createPhotonArrowPattern(e, a)
    const c = createPhotonFallbackPattern(e, o)
    const P = createPhotonComposePattern(e, [i, c])
    i.release()
    c.release()
    t.decoration(1, P, e.HorizontalAlignment.Right)
    P.release()
  }
  const c = i.dashOffset || 0
  const P = undefined
  const h = createPhotonDashPattern(
    e,
    i.dash ? normalizeDashes(i.dash, c) : [],
    l,
    n.color
  )
  t.fallback(h, 0)
  t.sharpAngleThreshold(0)
  t.lineJoin(toPhotonLineJoin(e, 'round'))
  t.isWorldSize(n.isWorldSize)
  t.screenOriented(true)
  h.release()
  return t.build()
}
function getMarkerSize(e, t, o) {
  o = o || DEFAULT_ARROW_SIZE
  return !!t.isWorldSize ? e.toMapUnits(o, t.uom || PIXEL_UOM) : o
}
function toPhotonAlignment(e, t) {
  switch (t) {
    case 'left':
      return e.HorizontalAlignment.Left
    case 'center':
      return e.HorizontalAlignment.Center
    case 'right':
      return e.HorizontalAlignment.Right
  }
  throw new Error(`Could not determine Photon PatternAlignment for value: ${t}`)
}
function toPhotonLineJoin(e, t) {
  if (!t) return e.LineJoin.Round
  switch (t) {
    case 'round':
      return e.LineJoin.Round
    case 'bevel':
      return e.LineJoin.Bevel
    case 'miter':
      return e.LineJoin.Miter
    default:
      return e.LineJoin.Round
  }
}
