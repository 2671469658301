import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { LLHBounds } from '../../shape/LLHBounds.js'
import { XYZBounds } from '../../shape/XYZBounds.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
import { Transformation } from '../../transformation/Transformation.js'
import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import { CameraPosition2D } from '../CameraPosition2D.js'
import { distance2D_xy } from '../../util/Cartesian.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { NotImplementedError } from '../../error/NotImplementedError.js'
import {
  rayEllipsoidIntersection,
  rayPlaneIntersection,
} from '../../util/Intersection.js'
import { PerspectiveCamera } from '../camera/PerspectiveCamera.js'
import { IdentityTransformation } from '../../transformation/IdentityTransformation.js'
import { createPaintContextHashClosestSurface } from './PaintContextUtil.js'
import { isBoolean } from '../../util/Lang.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
const sharedOOBENoSurface = new OutOfBoundsError(
  'View point does not touch any surface'
)
const sharedOOBENoEllipsoid = new OutOfBoundsError(
  'View point does not touch the ellipsoid'
)
const sharedOOBENoView = new OutOfBoundsError(
  'Cannot transform point to view coordinates'
)
const sharedOOBENoWorld = new OutOfBoundsError(
  'Cannot transform point to world coordinates'
)
const sharedOOBENoGlobe = new OutOfBoundsError(
  'View point currently does not touch the globe'
)
const sharedOOBENotVisible = new OutOfBoundsError(
  'World point is currently not visible in view'
)
class BasePhotonTransformation extends Transformation {
  constructor(e, t, r) {
    super(t, r)
    this._photonW2V = e
  }
  get inverseTransformation() {
    return this._inverseTransformation
  }
  set inverseTransformation(e) {
    this._inverseTransformation = e
  }
  get photonW2V() {
    return this._photonW2V
  }
  get photonView() {
    return this._photonW2V.view
  }
  _forwardBoundsCoords(e, t) {
    throw new Error('Method not implemented.')
  }
  _inverse(e, t) {
    throw new Error('Method not implemented.')
  }
  _inverseBoundsCoords(e, t) {
    throw new Error('Method not implemented.')
  }
}
class VisibleSurfaceWorldViewTransformation extends BasePhotonTransformation {
  constructor(e) {
    super(e, e.inputReference, e.outputReference)
  }
  getScaleX() {
    return this.photonW2V.getScaleX()
  }
  _forward(e, t) {
    if (
      null !== this.photonView &&
      !this.photonView.isPointVisible(e, Photon.VisibilityMode.Foreground3D)
    )
      throw sharedOOBENotVisible
    return this.photonW2V.transform(e, t)
  }
  _forwardBoundsCoords(e, t) {
    return this.photonW2V.transformBounds(e, t)
  }
}
class OnTerrainViewWorldTransformation extends BasePhotonTransformation {
  constructor(e) {
    super(e, e.outputReference, e.inputReference)
  }
  _forwardBoundsCoords(e, t) {
    return this.photonW2V.inverseTransformation.transformBounds(e, t)
  }
  _forward(e, t) {
    if (null === this.photonView) {
      t.move3DToPoint(this.photonW2V.getPointOnEllipsoid(e))
      return t
    }
    const r = this.photonView.getPointOnTerrain({
      x: this.photonW2V.normalizeX(e.x),
      y: this.photonW2V.normalizeY(e.y),
      z: 0.5,
    })
    if (!r.valid) throw sharedOOBENoSurface
    else {
      t.move3DToCoordinates(r.x, r.y, r.z)
      return t
    }
  }
}
class OnTerrainWorldWorldTransformation extends BasePhotonTransformation {
  constructor(e) {
    super(e, e.inputReference, e.inputReference)
  }
  _forward(e, t) {
    return this.photonW2V.projectPointOnTerrain(e, t)
  }
}
class ClosestSurfaceViewWorldTransformation extends BasePhotonTransformation {
  constructor(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    super(e, e.outputReference, e.inputReference)
    this._includeTransparent = !!t.includeTransparent
    const { depthContextHash: r, depthContextWithoutControllerHash: i } =
      createPaintContextHashClosestSurface(t)
    this._depthContextHash = r
    this._depthContextWithoutControllerHash = i
  }
  _forward(e, t) {
    if (null === this.photonView) {
      t.move3DToPoint(this.photonW2V.getPointOnEllipsoid(e))
      return t
    }
    const r = this.photonW2V.hasMapController()
      ? this._depthContextHash
      : this._depthContextWithoutControllerHash
    const i = this.photonView.getPointOnClosestSurface(
      {
        x: this.photonW2V.normalizeX(e.x),
        y: this.photonW2V.normalizeY(e.y),
        z: 0.01,
      },
      false,
      this._includeTransparent,
      r
    )
    if (!i.valid) throw sharedOOBENoSurface
    else {
      t.move3DToCoordinates(i.x, i.y, i.z)
      return t
    }
  }
}
class OnEllipsoidViewWorldTransformation extends BasePhotonTransformation {
  constructor(e, t) {
    super(e, e.outputReference, e.inputReference)
    this._heightOffset = t
  }
  _forward(e, t) {
    if (null === this.photonView) {
      t.move3DToPoint(this.photonW2V.getPointOnEllipsoid(e))
      return t
    }
    const r = this.photonView.getPointOnEllipsoid(
      {
        x: this.photonW2V.normalizeX(e.x),
        y: this.photonW2V.normalizeY(e.y),
        z: 0.01,
      },
      this._heightOffset
    )
    if (!r.valid) throw sharedOOBENoEllipsoid
    else {
      t.move3DToCoordinates(r.x, r.y, r.z)
      return t
    }
  }
}
export class PhotonWorldViewTransformation extends Transformation {
  constructor(e) {
    super(e.reference, null)
    this._objectTracker = new ObjectReleaseTracker()
    this._map = e
    this._view = null
    this._camera = e.camera
    this._onSurfaceReference = null
    this._onSurfacePhotonReference = null
    this._tmpWorldPt = new XYZPoint()
    this._tmpViewPt = new XYZPoint()
    this._inverseTransformation = null
    this._visibleSurfaceWorldViewTransformation =
      new VisibleSurfaceWorldViewTransformation(this)
    this._onTerrainViewWorldTransformation =
      new OnTerrainViewWorldTransformation(this)
    this._onEllipsoidViewWorldTransformation =
      new OnEllipsoidViewWorldTransformation(this, 0)
    this._onTerrainWorldWorldTransformation =
      new OnTerrainWorldWorldTransformation(this)
    this._closestSurfaceViewWorldTransformation =
      new ClosestSurfaceViewWorldTransformation(this)
    this._visibleSurfaceWorldViewTransformation.inverseTransformation =
      this._onTerrainViewWorldTransformation
    this._onTerrainViewWorldTransformation.inverseTransformation =
      this._visibleSurfaceWorldViewTransformation
    this._onEllipsoidViewWorldTransformation.inverseTransformation = this
    this._onTerrainWorldWorldTransformation.inverseTransformation =
      new IdentityTransformation(e.reference, e.reference)
    this._closestSurfaceViewWorldTransformation.inverseTransformation = this
  }
  get view() {
    const e = undefined
    return (
      this._map.webGLContext ? this._map.webGLContext?.isContextLost() : false
    )
      ? null
      : this._view
  }
  initialize(e, t, r) {
    this._view = t
    this._camera = this._objectTracker.track(
      this._map.camera instanceof PerspectiveCamera
        ? this._view.perspectiveCamera
        : this._view.orthographicCamera
    )
    if (e) {
      this._onSurfaceReference = this._view.is3D()
        ? getReference('CRS:84')
        : this._map.reference
      if (null !== r)
        this._onSurfacePhotonReference = this._objectTracker.track(
          r.getReference(this._onSurfaceReference)
        )
    }
  }
  release() {
    this._objectTracker.release()
  }
  normalizeX(e) {
    const t = undefined
    return e / (this._map.getViewWidth() / 2) - 1
  }
  normalizeY(e) {
    const t = undefined
    return -(e / (this._map.getViewHeight() / 2) - 1)
  }
  denormalizeX(e) {
    const t = undefined
    return (e + 1) * (this._map.getViewWidth() / 2)
  }
  denormalizeY(e) {
    const t = undefined
    return (-e + 1) * (this._map.getViewHeight() / 2)
  }
  denormalizeZ(e) {
    return (e + 1) / 2
  }
  isPointVisible(e) {
    if (null === this._view) return false
    return this._view.isPointVisible(e)
  }
  isPixelPointBehindCamera(e) {
    const t = this._camera.near
    const r = this._camera.far
    return e.z > r / (r - t)
  }
  _forward(e, t) {
    const r = this._camera.toView(e)
    if (!isFinite(r.x) || !isFinite(r.y) || !isFinite(r.z))
      throw sharedOOBENoView
    t.x = this.denormalizeX(r.x)
    t.y = this.denormalizeY(r.y)
    t.z = this.denormalizeZ(r.z)
    if (this.isPixelPointBehindCamera(t)) throw sharedOOBENoView
    return t
  }
  _inverse(e, t) {
    const r = this._camera.toWorld({
      x: this.normalizeX(e.x),
      y: this.normalizeY(e.y),
      z: 0,
    })
    if (isNaN(r.x) || isNaN(r.y) || isNaN(r.z)) throw sharedOOBENoWorld
    t.x = r.x
    t.y = r.y
    t.z = r.z
    return t
  }
  forwardX(e, t, r) {
    this._tmpWorldPt.move3DToCoordinates(e, t, r || 0)
    this._forward(this._tmpWorldPt, this._tmpViewPt)
    return this._tmpViewPt.x
  }
  forwardY(e, t, r) {
    this._tmpWorldPt.move3DToCoordinates(e, t, r || 0)
    this._forward(this._tmpWorldPt, this._tmpViewPt)
    return this._tmpViewPt.y
  }
  inverseX(e, t, r) {
    this._tmpViewPt.move3DToCoordinates(e, t, r || 0)
    this._inverse(this._tmpViewPt, this._tmpWorldPt)
    return this._tmpWorldPt.x
  }
  inverseY(e, t, r) {
    this._tmpViewPt.move3DToCoordinates(e, t, r || 0)
    this._inverse(this._tmpViewPt, this._tmpWorldPt)
    return this._tmpWorldPt.y
  }
  toWorldDistance(e, t) {
    const r = this._camera.toWorld({ x: 0, y: 0, z: 0 })
    const i = this._camera.toWorld({
      x: this.normalizeX(this._map.getViewWidth() / 2 + e[0]),
      y: 0,
      z: 0,
    })
    const o = this._camera.toWorld({
      x: 0,
      y: this.normalizeY(this._map.getViewHeight() / 2 + e[1]),
      z: 0,
    })
    t[0] = distance2D_xy(r.x, r.y, i.x, i.y)
    t[1] = distance2D_xy(r.x, r.y, o.x, o.y)
  }
  _forwardBoundsCoords(e, t) {
    transformBounds(
      e,
      t,
      this._forward.bind(this),
      this._tmpViewPt,
      this._tmpWorldPt
    )
  }
  _inverseBoundsCoords(e, t) {
    transformBounds(
      e,
      t,
      this._inverse.bind(this),
      this._tmpViewPt,
      this._tmpWorldPt
    )
  }
  getBoundsOnSurface(e) {
    if (null === this._view) return null
    const t = this._view.getBoundsOnSurface(e, this._onSurfacePhotonReference)
    if (!t.valid) return null
    if (this._view.is3D())
      return new LLHBounds(this._onSurfaceReference, [
        t.x,
        t.width,
        t.y,
        t.height,
        t.z,
        t.depth,
      ])
    else
      return new XYZBounds(this._map.reference, [
        t.x,
        t.width,
        t.y,
        t.height,
        t.z,
        t.depth,
      ])
  }
  getPointOnEllipsoid(e) {
    if (this._view) {
      const t = this._view.getPointOnEllipsoid(
        { x: this.normalizeX(e.x), y: this.normalizeY(e.y), z: 0.5 },
        0
      )
      return createPoint(this._map.reference, [t.x, t.y, t.z])
    } else {
      const t = this._map.camera
      const r = t.eye
      const i = createPoint(this._map.reference, [])
      const o = t.toWorldPoint(e)
      if (this._map.reference.referenceType == ReferenceType.GEOCENTRIC) {
        const e = this._map.reference.geodeticDatum.ellipsoid
        if (!rayEllipsoidIntersection(r, o, e, 1, Number.MAX_VALUE, i))
          throw sharedOOBENoGlobe
      } else {
        const e = rayPlaneIntersection(
          { x: 0, y: 0, z: 1 },
          { x: 0, y: 0, z: 0 },
          r,
          { x: o.x - r.x, y: o.y - r.y, z: o.z - r.z }
        )
        if (!e) throw sharedOOBENoGlobe
        i.move3DToCoordinates(e.x, e.y, e.z)
      }
      return i
    }
  }
  getViewOriginX() {
    if (this._map.is3D()) throw new ProgrammingError('Must be 2D map')
    return this._map.getViewWidth() >> 1
  }
  getViewOriginY() {
    if (this._map.is3D()) throw new ProgrammingError('Must be 2D map')
    return this._map.getViewHeight() >> 1
  }
  getWorldOriginX() {
    if (this._map.is3D()) throw new ProgrammingError('Must be 2D map')
    return this.inverseX(this.getViewOriginX(), this.getViewOriginY()) || 0
  }
  getWorldOriginY() {
    if (this._map.is3D()) throw new ProgrammingError('Must be 2D map')
    return this.inverseY(this.getViewOriginX(), this.getViewOriginY()) || 0
  }
  getScaleX() {
    if (null === this._view)
      if (this._map.reference.referenceType == ReferenceType.CARTESIAN)
        return this._map.camera.width / this._map.camera.worldWidth
      else {
        const e = this._map.reference.geodeticDatum.ellipsoid
        const t = createPoint(null, [])
        e.geoc2geodSFCT(this._map.camera.eye, t)
        t.z = 0
        e.geod2geocSFCT(t, t)
        return this._map.camera.getScaleAt(t)
      }
    return this._view.getScaleNearCamera()
  }
  getScaleY() {
    return this.getScaleX()
  }
  toCameraPosition() {
    return new CameraPosition2D(
      this.getViewOriginX(),
      this.getViewOriginY(),
      this.getWorldOriginX(),
      this.getWorldOriginY(),
      this.getScaleX() || 1,
      this.getScaleY() || 1
    )
  }
  updateCamera(e, t) {
    if (t)
      if (this._view)
        this._camera = this._objectTracker.retrack(
          this._camera,
          e instanceof PerspectiveCamera
            ? this._view.perspectiveCamera
            : this._view.orthographicCamera
        )
      else this._camera = e
  }
  get inverseTransformation() {
    let e = this._inverseTransformation
    if (!e) {
      e = new PhotonWorldViewTransformation(this._map)
      e._forward = this._inverse
      e._inverse = this._forward
      e._inverseTransformation = this
      this._inverseTransformation = this._objectTracker.track(e)
      e._inputReference = this._outputReference
      e._outputReference = this._inputReference
      this._map.onReady(() => e.initialize(null, this._view, null))
    }
    return e
  }
  getVisibleSurfaceWorldViewTransformation() {
    return this._visibleSurfaceWorldViewTransformation
  }
  getOnTerrainViewWorldTransformation() {
    return this._onTerrainViewWorldTransformation
  }
  getOnTerrainWorldWorldTransformation() {
    return this._onTerrainWorldWorldTransformation
  }
  getOnEllipsoidViewWorldTransformation(e) {
    if (0 == e) return this._onEllipsoidViewWorldTransformation
    else return new OnEllipsoidViewWorldTransformation(this, e)
  }
  getClosestSurfaceViewWorldTransformation() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    const {
      excludedLayers: t,
      includeControllerOnDraw: r,
      includeTransparent: i,
    } = e
    if (t?.length || isBoolean(r) || isBoolean(i))
      return new ClosestSurfaceViewWorldTransformation(this, e)
    return this._closestSurfaceViewWorldTransformation
  }
  projectPointOnTerrain(e, t) {
    if (!t) t = createPoint(e.reference, [e.x, e.y, e.z])
    t = t || e.copy()
    if (null === this._view) {
      const r = this._map.reference.geodeticDatum.ellipsoid
      if (
        !rayEllipsoidIntersection(
          this._map.camera.eye,
          e,
          r,
          1,
          Number.MAX_VALUE,
          t
        )
      )
        t.move3D(e)
      return t
    }
    const r = this._view.projectPointOnTerrain(e)
    if (!r.valid) {
      t.x = e.x
      t.y = e.y
      t.z = e.z
    } else {
      t.x = r.x
      t.y = r.y
      t.z = r.z
    }
    return t
  }
  intersectTerrain(e, t) {
    if (null === this._view) return false
    const r = undefined
    return !!this._view.intersectTerrain(e, t).valid
  }
  getViewCenter() {
    const e = this._map.getViewWidth()
    const t = this._map.getViewHeight()
    return createPoint(null, [e / 2, t / 2])
  }
  _getType() {
    throw new NotImplementedError()
  }
  hasMapController() {
    return !!this._map.controller
  }
}
function transformBounds(e, t, r, i, o) {
  const n = e.width
  const s = e.height
  const a = e.depth
  t.width = 0
  t.height = 0
  t.depth = 0
  i.move3D(e.x, e.y, e.z)
  r(i, o)
  t.move3D(o)
  i.translate3D(n, 0, 0)
  r(i, o)
  t.setToIncludePoint3D(o)
  i.translate3D(0, s, 0)
  r(i, o)
  t.setToIncludePoint3D(o)
  i.translate3D(-n, 0, 0)
  r(i, o)
  t.setToIncludePoint3D(o)
  if (a > 0) {
    i.translate3D(0, -s, a)
    r(i, o)
    t.setToIncludePoint3D(o)
    i.translate3D(n, 0, 0)
    r(i, o)
    t.setToIncludePoint3D(o)
    i.translate3D(0, s, 0)
    r(i, o)
    t.setToIncludePoint3D(o)
    i.translate3D(-n, 0, 0)
    r(i, o)
    t.setToIncludePoint3D(o)
  }
}
