import { XMLSchema } from '../../util/XMLSchema.js'
import { WMSCapabilitiesSchemaCommon } from './WMSCapabilitiesSchemaCommon.js'
var attribute = XMLSchema.attribute,
  boolean = XMLSchema.boolean,
  number = XMLSchema.number,
  content = XMLSchema.content
var requestSchema = {
  GetCapabilities: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  GetMap: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  GetFeatureInfo: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  _ExtendedOperation: WMSCapabilitiesSchemaCommon.operationTypeSchema,
}
var userDefinedSymbolizationSchema = {
  supportSLD: attribute(boolean),
  userLayer: attribute(boolean),
  userStyle: attribute(boolean),
  remoteWFS: attribute(boolean),
}
var layerSchema = Object.assign(
  {},
  WMSCapabilitiesSchemaCommon.layerSchemaCommon,
  {
    CRS: [{ '#text_only': content() }],
    EX_GeographicBoundingBox: {
      westBoundLongitude: content(number),
      eastBoundLongitude: content(number),
      southBoundLatitude: content(number),
      northBoundLatitude: content(number),
    },
    BoundingBox: [
      {
        CRS: attribute(),
        minx: attribute(number),
        miny: attribute(number),
        maxx: attribute(number),
        maxy: attribute(number),
        resx: attribute(number),
        resy: attribute(number),
      },
    ],
    MinScaleDenominator: content(),
    MaxScaleDenominator: content(),
  }
)
layerSchema.Layer = [layerSchema]
export var WMSCapabilitiesSchemaV130 = {
  WMS_Capabilities: {
    version: attribute(),
    updateSequence: attribute(),
    Service: WMSCapabilitiesSchemaCommon.serviceSchema,
    Capability: {
      Request: requestSchema,
      Exception: WMSCapabilitiesSchemaCommon.exceptionSchema,
      UserDefinedSymbolization: userDefinedSymbolizationSchema,
      Layer: [layerSchema],
    },
  },
}
