import { QueryProvider } from './QueryProvider.js'
export class SingleQueryProvider extends QueryProvider {
  constructor(e) {
    super()
    super._query = e
    this._scales = []
  }
  getQueryLevelScales() {
    return this._scales
  }
  getQueryForLevel() {
    return this._query
  }
}
