import { ProgrammingError } from '../../error/ProgrammingError.js'
import { SimpleBounds } from './SimpleBounds.js'
export const SELECTED_FEATURE = 1
const EDITED_FEATURE = 2
export class FeatureRenderNode {
  constructor(t, s) {
    this._feature = t
    this._shape = s
    this._flags = 0
    this.state = 0
    this.states = {}
    this._passesFilter = false
    this._dirtyFilter = true
    this.drawCommands = {}
    this._bounds = null
  }
  reset(t, s) {
    if (t.id !== this._feature.id)
      throw new ProgrammingError(
        'FeatureRenderNode::reset used with a feature that is not associated'
      )
    this._feature = t
    this._shape = s
    this.state = 0
    this.states = {}
    this._passesFilter = false
    this._dirtyFilter = true
    this.drawCommands = {}
    if (this._bounds) this.updateBounds()
  }
  invalidateStates() {
    this.states = {}
  }
  setStateForBodyPaintRepresentation(t, s) {
    this.states[t] = s
  }
  getStateForBodyPaintRepresentation(t) {
    return this.states[t]
  }
  setDrawCommandForPaintRepresentation(t, s) {
    this.drawCommands[t] = s
  }
  getDrawCommandForPaintRepresentation(t) {
    return this.drawCommands[t]
  }
  get bounds() {
    if (!this._bounds) this.updateBounds()
    return this._bounds
  }
  updateBounds() {
    const t = this._bounds || new SimpleBounds()
    const s = this._shape && this._shape.bounds
    if (s) {
      t.left = s.x
      t.right = t.left + s.width
      t.bottom = s.y
      t.top = t.bottom + s.height
    } else t.reset()
    this._bounds = t
  }
  get selected() {
    return 0 !== (this._flags & SELECTED_FEATURE)
  }
  set selected(t) {
    if (t) this._flags |= SELECTED_FEATURE
    else this._flags &= ~SELECTED_FEATURE
  }
  get edited() {
    return 0 !== (this._flags & EDITED_FEATURE)
  }
  set edited(t) {
    if (t) this._flags |= EDITED_FEATURE
    else this._flags &= ~EDITED_FEATURE
  }
  get flags() {
    return this._flags
  }
  get passesFilter() {
    return this._passesFilter
  }
  set passesFilter(t) {
    this._passesFilter = t
  }
  get dirtyFilter() {
    return this._dirtyFilter
  }
  set dirtyFilter(t) {
    this._dirtyFilter = t
  }
  get shape() {
    return this._shape
  }
  get feature() {
    return this._feature
  }
}
