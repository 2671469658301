export class WKTName {
  constructor(e) {
    this._names = e
  }
  getName() {
    return this._names[0]
  }
  isEquivalentTo(e) {
    for (const t of this._names) if (t == e) return true
    return false
  }
}
