import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { isNumber } from '../util/Lang.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Cylindrical } from './Cylindrical.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('Mercator')
const LATITUDE_LIMIT = 85.06
function lat2yOnSphere(e, t, i) {
  return (i * t * Math.log((1 + e) / (1 - e))) / 2
}
function lat2yOnEllipsoid(e, t, i) {
  const s = (1 + e) / (1 - e)
  const a = t.e * e
  const r = Math.pow((1 - a) / (1 + a), t.e)
  return (i * t.a * Math.log(s * r)) / 2
}
class CachedValuesForSphere {
  constructor(e, t) {
    if ('undefined' == typeof e) {
      this.radius = -1
      this.sphereNorthLimit = 0
      this.sphereEastLimit = 0
      this.sphereScaleFactor = 0
    } else {
      this.radius = e
      if ('undefined' == typeof t || 0 == t) this.sphereScaleFactor = 1
      else this.sphereScaleFactor = Math.cos(t * Constants.DEG2RAD)
      this.sphereNorthLimit = lat2yOnSphere(
        Math.sin(LATITUDE_LIMIT * Constants.DEG2RAD),
        this.radius,
        this.sphereScaleFactor
      )
      this.sphereEastLimit = this.sphereScaleFactor * this.radius * Math.PI
    }
  }
}
class CachedValuesForEllipsoid {
  constructor(e, t) {
    let i, s
    let a, r, l, o, h, n, d, c
    if ('undefined' == typeof e) {
      this.E = -1
      this.A = -1
      this.ellipsoidScaleFactor = 0
      this.ellipsoidNorthLimit = 0
      this.ellipsoidEastLimit = 0
      this.B1 = 0
      this.B2 = 0
      this.B3 = 0
      this.B4 = 0
    } else {
      this.E = e.e
      this.A = e.a
      if ('undefined' == typeof t || 0 == t) this.ellipsoidScaleFactor = 1
      else {
        i = t * Constants.DEG2RAD
        s = Math.sin(i)
        this.ellipsoidScaleFactor =
          Math.cos(i) / Math.sqrt(1 - this.E * this.E * s * s)
      }
      this.ellipsoidNorthLimit = lat2yOnEllipsoid(
        Math.sin(LATITUDE_LIMIT * Constants.DEG2RAD),
        e,
        this.ellipsoidScaleFactor
      )
      this.ellipsoidEastLimit = this.ellipsoidScaleFactor * this.A * Math.PI
      a = e.e2
      r = a * a
      l = r * a
      o = r * r
      h = a / 2 + (5 / 24) * r + (1 / 12) * l + (13 / 360) * o
      n = (7 / 48) * r + (29 / 240) * l + (811 / 11520) * o
      d = (7 / 120) * l + (81 / 1120) * o
      c = (4279 / 161280) * o
      this.B1 = 2 * h + 4 * n + 6 * d + 8 * c
      this.B2 = -8 * n - 32 * d - 80 * c
      this.B3 = 32 * d + 192 * c
      this.B4 = -128 * c
    }
  }
}
export class Mercator extends Cylindrical {
  constructor(e) {
    super()
    if ('object' == typeof e && null != e && isNumber(e.standardParallel))
      this.setCentralMeridian(e.standardParallel)
    else this.setCentralMeridian(0)
    this._trueScaleLatitude = 0
    this._cachedValuesForEllipsoid = null
    this._cachedValuesForSphere = null
    this._hash = 0
    this.calculateCachedValues()
  }
  getTrueScaleLatitude() {
    return this._trueScaleLatitude
  }
  getLatitudeMin() {
    return -LATITUDE_LIMIT
  }
  getLatitudeMax() {
    return LATITUDE_LIMIT
  }
  isAllInBounds() {
    return false
  }
  boundaryLons(e) {
    return e <= LATITUDE_LIMIT && e >= -LATITUDE_LIMIT
      ? [
          [
            normalizeLon(-180 + this.getCentralMeridian() + this.EPSILON),
            normalizeLon(180 + this.getCentralMeridian() - this.EPSILON),
          ],
        ]
      : []
  }
  boundaryLats(e) {
    return [[-LATITUDE_LIMIT, LATITUDE_LIMIT]]
  }
  setTrueScaleLatitude(e) {
    if ('undefined' === typeof e) this._trueScaleLatitude = 0
    else this._trueScaleLatitude = e
    this.resetCache()
    this.calculateCachedValues()
  }
  resetCache() {
    this._cachedValuesForEllipsoid = null
    this._cachedValuesForSphere = null
  }
  getCachedValuesForSphere(e) {
    if (null == this._cachedValuesForSphere)
      this._cachedValuesForSphere = new CachedValuesForSphere(
        e,
        this._trueScaleLatitude
      )
    else if (e != this._cachedValuesForSphere.radius)
      this._cachedValuesForSphere = new CachedValuesForSphere(
        e,
        this._trueScaleLatitude
      )
    return this._cachedValuesForSphere
  }
  getCachedValuesForEllipsoid(e) {
    if (null == this._cachedValuesForEllipsoid)
      this._cachedValuesForEllipsoid = new CachedValuesForEllipsoid(
        e,
        this._trueScaleLatitude
      )
    else if (
      e.a != this._cachedValuesForEllipsoid.A ||
      e.e != this._cachedValuesForEllipsoid.E
    )
      this._cachedValuesForEllipsoid = new CachedValuesForEllipsoid(
        e,
        this._trueScaleLatitude
      )
    return this._cachedValuesForEllipsoid
  }
  lon2xOnSphere(e, t, i) {
    return (
      i * t * normalizeLon(e - this.getCentralMeridian()) * Constants.DEG2RAD
    )
  }
  lon2xOnEllipsoid(e, t, i) {
    return (
      i * t.a * normalizeLon(e - this.getCentralMeridian()) * Constants.DEG2RAD
    )
  }
  geodetic2cartesianOnSphereSFCT(e, t, i) {
    let s, a
    if (!this.inLonLatBounds(e)) throw sharedOutOfBoundsError
    else {
      if (0 == this._trueScaleLatitude) s = 1
      else {
        a = this.getCachedValuesForSphere(t)
        s = a.sphereScaleFactor
      }
      i.x = this.lon2xOnSphere(e.x, t, s)
      i.y = lat2yOnSphere(Math.sin(e.y * Constants.DEG2RAD), t, s)
    }
  }
  geodetic2cartesianOnEllipsoidSFCT(e, t, i) {
    let s, a
    if (!this.inLonLatBounds(e)) throw sharedOutOfBoundsError
    else {
      if (0 == this._trueScaleLatitude) s = 1
      else {
        a = this.getCachedValuesForEllipsoid(t)
        s = a.ellipsoidScaleFactor
      }
      i.x = this.lon2xOnEllipsoid(e.x, t, s)
      i.y = lat2yOnEllipsoid(Math.sin(e.y * Constants.DEG2RAD), t, s)
    }
  }
  cartesian2geodeticOnSphereSFCT(e, t, i) {
    let s, a
    if (!this.inWorldBoundsOnSphere(e, t)) throw sharedOutOfBoundsError
    else {
      if (0 == this._trueScaleLatitude) s = t
      else {
        a = this.getCachedValuesForSphere(t)
        s = a.sphereScaleFactor * t
      }
      i.x = normalizeLon(
        (e.x / s) * Constants.RAD2DEG + this.getCentralMeridian()
      )
      i.y = 2 * Math.atan(Math.exp(e.y / s)) * Constants.RAD2DEG - 90
    }
  }
  cartesian2geodeticOnEllipsoidSFCT(e, t, i) {
    let s, a
    let r, l, o, h, n, d
    if (!this.inWorldBoundsOnEllipsoid(e, t)) throw sharedOutOfBoundsError
    else {
      a = this.getCachedValuesForEllipsoid(t)
      if (0 == this._trueScaleLatitude) s = t.a
      else s = a.ellipsoidScaleFactor * a.A
      i.x = normalizeLon(
        (e.x / s) * Constants.RAD2DEG + this.getCentralMeridian()
      )
      r = Math.exp(-e.y / s)
      l = 2 * Math.atan(r)
      o = Math.PI / 2 - l
      h = Math.sin(o)
      n = Math.cos(o)
      d = h * h
      i.y =
        (o + h * n * (a.B1 + d * (a.B2 + d * (a.B3 + d * a.B4)))) *
        Constants.RAD2DEG
    }
  }
  inLonLatBounds(e) {
    return e.y >= -LATITUDE_LIMIT && e.y <= LATITUDE_LIMIT
  }
  inWorldBoundsOnSphere(e, t) {
    const i = this.getCachedValuesForSphere(t)
    const s = i.sphereNorthLimit
    const a = i.sphereEastLimit
    return !(e.x > a || e.x < -a || e.y > s || e.y < -s)
  }
  inWorldBoundsOnEllipsoid(e, t) {
    const i = this.getCachedValuesForEllipsoid(t)
    const s = i.ellipsoidNorthLimit
    const a = i.ellipsoidEastLimit
    return !(e.x > a || e.x < -a || e.y > s || e.y < -s)
  }
  cartesianBoundsOnSphereSFCT(e, t) {
    const i = this.getCachedValuesForSphere(e)
    const s = i.sphereNorthLimit
    const a = i.sphereEastLimit
    t.setTo2D(-a, 2 * a, -s, 2 * s)
  }
  cartesianBoundsOnEllipsoidSFCT(e, t) {
    const i = this.getCachedValuesForEllipsoid(e)
    const s = i.ellipsoidNorthLimit
    const a = i.ellipsoidEastLimit
    t.setTo2D(-a, 2 * a, -s, 2 * s)
  }
  encode() {
    return {
      type: 'Mercator',
      centralMeridian: this.getCentralMeridian(),
      trueScaleLatitude: this.getTrueScaleLatitude(),
      latitudeMin: this.getLatitudeMin(),
      latitudeMax: this.getLatitudeMax(),
    }
  }
  get TYPE() {
    return ProjectionType.MERCATOR + ProjectionType.CYLINDRICAL
  }
  calculateCachedValues() {
    const e = new Hash()
    this._hash = e
      .appendDouble(this.getCentralMeridian())
      .appendDouble(this.getTrueScaleLatitude())
      .appendDouble(this.getLatitudeMin())
      .appendDouble(this.getLatitudeMax())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  hashCode(e) {
    e.appendUInt32(this._hash)
  }
}
