import { canCreatePhotonColorFromString } from '../util/Color.js'
import { isDefined, isNumber, isString } from '../util/Lang.js'
export class EyeDomeLightingEffectImpl {
  constructor(t) {
    this.setToLiteral(t)
  }
  setToLiteral(t) {
    if (!isDefined(t)) return
    if (isNumber(t.window)) this.window = t.window
    else this.window = 2
    if (isNumber(t.strength)) this.strength = t.strength
    else this.strength = 1
    if (isDefined(t.color) && isString(t.color)) this.color = t.color
    else this.color = 'rgb(0, 0, 0)'
  }
  get window() {
    return this._window
  }
  set window(t) {
    if (isNumber(t) && t > 0) this._window = t
    else throw new Error(`Invalid eye dome lighting window: ${t}`)
  }
  get strength() {
    return this._strength
  }
  set strength(t) {
    if (isNumber(t) && t > 0) this._strength = t
    else throw new Error(`Invalid eye dome lighting strength: ${t}`)
  }
  get color() {
    return this._color
  }
  set color(t) {
    if (canCreatePhotonColorFromString(t)) this._color = t
    else throw new Error(`Invalid eye dome lighting color: ${t}`)
  }
}
