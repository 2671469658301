import { isDefined } from '../../util/Lang.js'
import { XMLSchema } from '../../util/XMLSchema.js'
import { CapabilitiesParserUtil } from './common/CapabilitiesParserUtil.js'
import { LTSCapabilitiesOperations } from './LTSCapabilitiesOperations.js'
import { LTSCapabilitiesSchema } from './LTSCapabilitiesSchema.js'
export class LTSCapabilitiesParser {
  constructor(e, i) {
    this._url = i
    const a = undefined
    const t = new XMLSchema(LTSCapabilitiesSchema).parse(e)
    this._rawCapabilities = t['Capabilities']
  }
  parseServiceMetadata() {
    const e = this._rawCapabilities.ServiceMetadata
    if (!isDefined(e)) return null
    return CapabilitiesParserUtil.freeze({
      id: e.Id,
      name: e.Name,
      abstract: e.Abstract,
      keywords: e.Keyword || [],
    })
  }
  parseUpdateSequence() {
    return this._rawCapabilities.updateSequence
  }
  parseSupportedOperations() {
    const e =
      this._rawCapabilities.OperationsMetadata &&
      this._rawCapabilities.OperationsMetadata.Operation
    const i = new LTSCapabilitiesOperations()
    e.forEach((e) => {
      const a = e.HTTPRequest || []
      const t = this._parseRequests(a)
      i.updateOperations(e.name, CapabilitiesParserUtil.freeze(t))
    }, this)
    return CapabilitiesParserUtil.freeze(i)
  }
  _parseRequests(e) {
    return e.map(
      (e) =>
        CapabilitiesParserUtil.freeze({
          endPoint: (e.OnlineResource && e.OnlineResource.href) || this._url,
          method: e.Method,
        }),
      this
    )
  }
}
