import { cloneDeep } from '../../util/JSON.js'
import { Shape } from '../../shape/Shape.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isNumber, isString, isUndefined } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
function validateShape(e) {
  if (e instanceof Shape || null === e) return e
  else
    throw new ProgrammingError(
      `luciad.model.feature.Feature::constructor - 1st argument should be a valid shape or null. Received: ${e}`
    )
}
export function isValidFeatureId(e) {
  return isNumber(e) || (e && isString(e))
}
export class Feature {
  constructor(e, r, t) {
    this._shape = validateShape(e)
    if (isNumber(t) || isString(t)) this._id = t
    else if (!isUndefined(t))
      Log.warn(
        'Feature ID should be a string or a number. Defaulted to undefined'
      )
    this._properties = r || {}
  }
  get geometry() {
    return this._shape
  }
  get id() {
    return this._id
  }
  set id(e) {
    this._id = e
  }
  get properties() {
    return this._properties
  }
  set properties(e) {
    this._properties = e
  }
  copy() {
    const e = this.shape ? this.shape.copy() : null
    return new Feature(e, cloneDeep(this.properties), this.id)
  }
  get shape() {
    return this._shape
  }
  set shape(e) {
    this._shape = validateShape(e)
  }
  toString() {
    return `Feature{id: ${this.id}}`
  }
}
