import { findCenter } from '../geodesy/EllipsoidCircleUtil.js'
import { buildCache } from '../util/Cacher.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import * as DistanceUtil from '../geometry/DistanceUtil.js'
import { ShapeType } from './ShapeType.js'
import { CircularArcByBulge } from './CircularArcByBulge.js'
import { LLHBounds } from './LLHBounds.js'
import { LLHPoint } from './LLHPoint.js'
import * as LLHCircularArcUtil from './LLHCircularArcUtil.js'
import { CoordinateType } from '../reference/CoordinateType.js'
export class LLHCircularArcByBulge extends CircularArcByBulge {
  constructor(e, t, r, i) {
    super()
    this._reference = this.validateReference(e)
    if (t && t.type === ShapeType.POINT) {
      this._compareReferenceStrict(
        t.reference,
        'LLHCircularArcByBulge::the arc reference must match the start point reference'
      )
      this._startPoint = t.copy()
    } else
      throw new ProgrammingError(
        'LLHCircularArcByBulge::cannot create an arc without a start point'
      )
    if (r && r.type === ShapeType.POINT) {
      this._compareReferenceStrict(
        r.reference,
        'LLHCircularArcByBulge::the arc reference must match the end point reference'
      )
      this._endPoint = r.copy()
    } else
      throw new ProgrammingError(
        'LLHCircularArcByBulge::cannot create an arc without an end point'
      )
    if ('number' !== typeof i)
      throw new ProgrammingError(
        'LLHCircularArcByBulge::cannot create an arc without a bulge'
      )
    this._bulge = i
    this._cache = buildCache()
  }
  get isGeodetic() {
    return true
  }
  get startPoint() {
    return this._startPoint
  }
  get endPoint() {
    return this._endPoint
  }
  get bulge() {
    return this._bulge
  }
  set bulge(e) {
    this._bulge = e
    this.invalidate()
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  get focusPoint() {
    return this._cache.memoize(
      'cached-focus-point',
      () =>
        new LLHPoint(this._reference, [
          (this.startPoint.x + this.endPoint.x) / 2,
          (this.startPoint.y + this.endPoint.y) / 2,
          (this.startPoint.z + this.endPoint.z) / 2,
        ])
    )
  }
  get center() {
    const e = this._cache
    return this._cache.memoize('cached-center-point', () => {
      const t = new LLHPoint(this.reference)
      LLHCircularArcUtil.getMidPoint(this, t)
      const r = new LLHPoint(this.reference)
      const i = findCenter(
        this.startPoint,
        t,
        this.endPoint,
        this.reference.geodeticDatum.ellipsoid,
        r,
        null
      )
      e.cache('cached-radius', i)
      return r
    })
  }
  get radius() {
    const e = this._cache
    return this._cache.memoize('cached-radius', () => {
      const t = new LLHPoint(this.reference)
      LLHCircularArcUtil.getMidPoint(this, t)
      const r = new LLHPoint(this.reference)
      const i = findCenter(
        this.startPoint,
        t,
        this.endPoint,
        this.reference.geodeticDatum.ellipsoid,
        r,
        null
      )
      e.cache('cached-center-point', r)
      return i
    })
  }
  get startAzimuth() {
    return this._cache.memoize('cached-start-azimuth', () =>
      LLHCircularArcUtil.getStartAngle3Points(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.startPoint
      )
    )
  }
  get sweepAngle() {
    return this._cache.memoize('cached-sweep-angle', () => {
      const e = new LLHPoint(this.reference)
      LLHCircularArcUtil.getMidPoint(this, e)
      return LLHCircularArcUtil.getArcAngle3Points(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.startPoint,
        e,
        this.endPoint
      )
    })
  }
  get bounds() {
    return this._cache.memoize('bounds', () => {
      const e = new LLHBounds(this.reference)
      LLHCircularArcUtil.boundsSFCT(this, e)
      return e
    })
  }
  contains2DCoordinates(e, t) {
    return LLHCircularArcUtil.contains2D(this, e, t)
  }
  moveStartPoint2DToCoordinates(e, t) {
    this.startPoint.move2DToCoordinates(e, t)
    this.invalidate()
  }
  moveEndPoint2DToCoordinates(e, t) {
    this.endPoint.move2DToCoordinates(e, t)
    this.invalidate()
  }
  translateStartPoint2D(e, t) {
    this.moveStartPoint2DToCoordinates(
      this.startPoint.x + e,
      this.startPoint.y + t
    )
  }
  translateEndPoint2D(e, t) {
    this.moveEndPoint2DToCoordinates(this.endPoint.x + e, this.endPoint.y + t)
  }
  invalidate() {
    this._cache.invalidate()
  }
  copy() {
    return new LLHCircularArcByBulge(
      this.reference,
      this.startPoint.copy(),
      this.endPoint.copy(),
      this.bulge
    )
  }
  calculateBulge(e) {
    const t = this.startPoint
    const r = this.endPoint
    const i = this.reference.geodeticDatum.ellipsoid
    const n = DistanceUtil.geodesicSignedDistanceToLine(e, t, r, i)
    const o = new LLHPoint(this.reference)
    o.move2DToCoordinates(t.x + 0.5 * (r.x - t.x), t.y + 0.5 * (r.y - t.y))
    return (-1 * n) / i.geodesicDistance(t, o)
  }
  contains3DCoordinates(e, t, r) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape LLHCircularArcByBulge'
    )
  }
}
