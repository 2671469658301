import { LLHPoint } from '../shape/LLHPoint.js'
import { Projection } from './Projection.js'
const BISECT_DELTA = 1e-5
export class BaseProjection extends Projection {
  geodetic2cartesianOnEllipsoidSFCT(t, n, e) {
    this.geodetic2cartesianOnSphereSFCT(t, n.auxRadius, e)
  }
  cartesian2geodeticOnEllipsoidSFCT(t, n, e) {
    this.cartesian2geodeticOnSphereSFCT(t, n.auxRadius, e)
  }
  inWorldBoundsOnEllipsoid(t, n) {
    return this.inWorldBoundsOnSphere(t, n.auxRadius)
  }
  boundaryLons(t) {
    const n = []
    let e = []
    const s = this.inLonLatBounds(new LLHPoint(null, [-180, t]))
    const i = this.inLonLatBounds(new LLHPoint(null, [180, t]))
    const o = [-180, 180, s, i]
    if (s != i) {
      e = this.bisectLonInterval(t, o, BISECT_DELTA)
      n[0][0] = e[0]
      n[0][1] = e[1]
    } else {
      const s = []
      s.push(o)
      const i = this.findBisectLonIntervals(t, s, BISECT_DELTA)
      for (let s = 0; s < i.length; s++) {
        e = this.bisectLonInterval(t, i[s], BISECT_DELTA)
        n[s][0] = e[0]
        n[s][1] = e[1]
      }
    }
    return n
  }
  boundaryLats(t) {
    const n = []
    let e = []
    const s = this.inLonLatBounds(new LLHPoint(null, [t, -90]))
    const i = this.inLonLatBounds(new LLHPoint(null, [t, 90]))
    const o = [-90, 9, s, i]
    if (s != i) {
      e = this.bisectLatInterval(t, o, BISECT_DELTA)
      n[0][0] = e[0]
      n[0][1] = e[1]
    } else {
      const s = []
      s.push(o)
      const i = this.findBisectLatIntervals(t, s, BISECT_DELTA)
      for (let s = 0; s < i.length; s++) {
        e = this.bisectLatInterval(t, i[s], BISECT_DELTA)
        n[s][0] = e[0]
        n[s][1] = e[1]
      }
    }
    return n
  }
  findBisectLonIntervals(t, n, e) {
    let s = n.shift()
    while (s[1] - s[0] < e && n.length > 0) s = n.shift()
    if (0 == n.length) return []
    else {
      const i = (s[1] - s[0]) / 2
      const o = this.inLonLatBounds(new LLHPoint(null, [i, t]))
      const r = [s[0], i, s[2], o]
      const l = [i, s[1], o, s[3]]
      if (o != s[2]) {
        const t = []
        t[0] = r
        t[1] = l
        return t
      } else {
        n.push(r)
        n.push(l)
        return this.findBisectLonIntervals(t, n, e)
      }
    }
  }
  findBisectLatIntervals(t, n, e) {
    let s = n.shift()
    while (s[1] - s[0] < e && n.length > 0) s = n.shift()
    if (0 == n.length) return []
    else {
      const i = (s[1] - s[0]) / 2
      const o = this.inLonLatBounds(new LLHPoint(null, [t, i]))
      const r = [s[0], i, s[2], o]
      const l = [i, s[1], o, s[3]]
      if (o != s[2]) {
        const t = []
        t[0] = r
        t[1] = l
        return t
      } else {
        n.push(r)
        n.push(l)
        return this.findBisectLatIntervals(t, n, e)
      }
    }
  }
  bisectLonInterval(t, n, e) {
    if (n[1] - n[0] < e) {
      const t = [0, 0]
      if (n[2]) {
        t[0] = n[0]
        t[1] = (n[1] - n[0]) / 2
        return t
      } else {
        t[1] = n[1]
        t[0] = (n[1] - n[0]) / 2
        return t
      }
    } else {
      const s = (n[1] - n[0]) / 2
      const i = this.inLonLatBounds(new LLHPoint(null, [s, t]))
      if (i == n[3]) return this.bisectLonInterval(t, [n[0], s, n[2], i], e)
      else return this.bisectLonInterval(t, [s, n[1], i, n[3]], e)
    }
  }
  bisectLatInterval(t, n, e) {
    if (n[1] - n[0] < e) {
      const t = [0, 0]
      if (n[2]) {
        t[0] = n[0]
        t[1] = (n[1] - n[0]) / 2
        return t
      } else {
        t[1] = n[1]
        t[0] = (n[1] - n[0]) / 2
        return t
      }
    } else {
      const s = (n[1] - n[0]) / 2
      const i = this.inLonLatBounds(new LLHPoint(null, [t, s]))
      if (i == n[3]) return this.bisectLonInterval(t, [n[0], s, n[2], i], e)
      else return this.bisectLonInterval(t, [s, n[1], i, n[3]], e)
    }
  }
  equals(t) {
    if (null == t || 'undefined' == typeof t) return false
    else if (t == this) return true
    else return t.TYPE == this.TYPE
  }
}
