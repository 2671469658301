import { XMLSchema } from '../../util/XMLSchema.js'
var attribute = XMLSchema.attribute,
  boolean = XMLSchema.boolean,
  number = XMLSchema.number,
  content = XMLSchema.content
var CodeType = { '#text': content(), codeSpace: attribute() }
var KeywordsType = { Keyword: [{ '#text_only': content() }], Type: CodeType }
var OnlineResourceType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
}
var MetadataType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
  about: attribute(),
}
var DomainType = {
  name: attribute(),
  AllowedValues: {
    Value: [{ '#text_only': content() }],
    Range: [
      {
        rangeClosure: attribute(),
        MinimumValue: content(),
        MaximumValue: content(),
        Spacing: content(),
      },
    ],
  },
  AnyValue: {},
  NoValues: {},
  ValuesReference: { reference: attribute(), '#text': content() },
  DefaultValue: content(),
  Meaning: { reference: attribute(), '#text': content() },
  DataType: { reference: attribute(), '#text': content() },
  UOM: { reference: attribute(), '#text': content() },
  ReferenceSystem: { reference: attribute(), '#text': content() },
  Metadata: [MetadataType],
}
var RequestMethodType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
  Constraint: [DomainType],
}
var LanguageStringType = { '#text': content(), lang: attribute() }
var BoundingBoxType = {
  LowerCorner: content(),
  UpperCorner: content(),
  crs: attribute(),
  dimensions: attribute(Number),
}
var DatasetDescriptionSummaryType = {
  Title: [LanguageStringType],
  Abstract: [LanguageStringType],
  Keywords: [KeywordsType],
  WGS84BoundingBox: [BoundingBoxType],
  Identifier: { name: attribute(), codeSpace: attribute(), '#text': content() },
  BoundingBox: [BoundingBoxType],
  Metadata: [MetadataType],
}
DatasetDescriptionSummaryType.DatasetDescriptionSummary = [
  DatasetDescriptionSummaryType,
]
var LayerType = Object.assign(
  {
    Style: [
      {
        Title: [LanguageStringType],
        Abstract: [LanguageStringType],
        Keywords: [KeywordsType],
        Identifier: {
          name: attribute(),
          codeSpace: attribute(),
          '#text': content(),
        },
        isDefault: attribute(boolean),
        LegendURL: [
          {
            type: attribute(),
            href: attribute(),
            role: attribute(),
            arcrole: attribute(),
            title: attribute(),
            show: attribute(),
            actuate: attribute(),
            format: attribute(),
            minScaleDenominator: attribute(number),
            maxScaleDenominator: attribute(number),
            width: attribute(number),
            height: attribute(number),
          },
        ],
      },
    ],
    Format: [{ '#text_only': content() }],
    InfoFormat: [{ '#text_only': content() }],
    Dimension: [
      {
        Title: [LanguageStringType],
        Abstract: [LanguageStringType],
        Keywords: [KeywordsType],
        Identifier: {
          name: attribute(),
          codeSpace: attribute(),
          '#text': content(),
        },
        UOM: { reference: attribute(), '#text': content() },
        UnitSymbol: content(),
        Default: content(),
        Current: content(boolean),
        Value: [{ '#text_only': content() }],
      },
    ],
    TileMatrixSetLink: [
      {
        TileMatrixSet: content(),
        TileMatrixSetLimits: {
          TileMatrixLimits: [
            {
              TileMatrix: content(),
              MinTileRow: content(number),
              MaxTileRow: content(number),
              MinTileCol: content(number),
              MaxTileCol: content(number),
            },
          ],
        },
      },
    ],
    ResourceURL: [
      { format: attribute(), resourceType: attribute(), template: attribute() },
    ],
  },
  DatasetDescriptionSummaryType
)
var ThemeType = {
  Title: [LanguageStringType],
  Abstract: [LanguageStringType],
  Keywords: [KeywordsType],
  Identifier: { name: attribute(), codeSpace: attribute(), '#text': content() },
  LayerRef: [{ '#text_only': content() }],
}
ThemeType.Theme = [ThemeType]
export var WMTSCapabilitiesSchema = {
  Capabilities: {
    version: attribute(),
    updateSequence: attribute(),
    ServiceIdentification: {
      Title: content(),
      Abstract: content(),
      Keywords: [KeywordsType],
      ServiceType: CodeType,
      ServiceTypeVersion: [{ '#text_only': content() }],
      Fees: content(),
      AccessConstraints: [{ '#text_only': content() }],
    },
    ServiceProvider: {
      ProviderName: content(),
      ProviderSite: OnlineResourceType,
      ServiceContact: {
        IndividualName: content(),
        PositionName: content(),
        ContactInfo: {
          Phone: {
            Voice: [{ '#text_only': content() }],
            Facsimile: [{ '#text_only': content() }],
          },
          Address: {
            DeliveryPoint: [{ '#text_only': content() }],
            City: content(),
            AdministrativeArea: content(),
            PostalCode: content(),
            Country: content(),
            ElectronicMailAddress: [{ '#text_only': content() }],
          },
          OnlineResource: OnlineResourceType,
          HoursOfService: content(),
          ContactInstructions: content(),
        },
        Role: CodeType,
      },
    },
    OperationsMetadata: {
      Operation: [
        {
          name: attribute(),
          DCP: [
            { HTTP: { Get: [RequestMethodType], Post: [RequestMethodType] } },
          ],
          Parameter: [DomainType],
          Constraint: [DomainType],
          Metadata: [MetadataType],
        },
      ],
      Parameter: [DomainType],
      Constraint: [DomainType],
    },
    Contents: {
      DatasetDescriptionSummary: [DatasetDescriptionSummaryType],
      Layer: [LayerType],
      OtherSource: [MetadataType],
      TileMatrixSet: [
        {
          Title: [LanguageStringType],
          Abstract: [LanguageStringType],
          Keywords: [KeywordsType],
          Identifier: {
            name: attribute(),
            codeSpace: attribute(),
            '#text': content(),
          },
          BoundingBox: BoundingBoxType,
          SupportedCRS: content(),
          WellKnownScaleSet: content(),
          TileMatrix: [
            {
              Title: [LanguageStringType],
              Abstract: [LanguageStringType],
              Keywords: [KeywordsType],
              Identifier: {
                name: attribute(),
                codeSpace: attribute(),
                '#text': content(),
              },
              ScaleDenominator: content(number),
              TopLeftCorner: content(),
              TileWidth: content(number),
              TileHeight: content(number),
              MatrixWidth: content(number),
              MatrixHeight: content(number),
            },
          ],
        },
      ],
    },
    Themes: [ThemeType],
    WSDL: [OnlineResourceType],
    ServiceMetadataURL: [OnlineResourceType],
  },
}
