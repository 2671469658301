import { ProgrammingError } from '../error/ProgrammingError.js'
import { PolygonOrientation } from '../shape/PolygonOrientation.js'
import { Clipping } from './Clipping.js'
import { Constants } from './Constants.js'
const TOLERANCE = 1e-9
export function distance2D_xy(t, n, e, o) {
  return Math.sqrt(Math.pow(e - t, 2) + Math.pow(o - n, 2))
}
export function contains2D(t, n, e) {
  const o = n.y - t.y
  const i = e.y - t.y
  const r = n.x - t.x
  const s = e.x - t.x
  if (
    (Math.abs(o) <= TOLERANCE && Math.abs(i) > TOLERANCE) ||
    (Math.abs(r) <= TOLERANCE && Math.abs(s) > TOLERANCE)
  )
    return false
  if (Math.abs(o) <= TOLERANCE && Math.abs(i) <= TOLERANCE)
    if (Math.abs(r) <= TOLERANCE && Math.abs(s) <= TOLERANCE) return true
    else return Math.abs(s) <= Math.abs(r) && s * r >= 0
  else if (Math.abs(r) <= TOLERANCE && Math.abs(s) <= TOLERANCE)
    return Math.abs(i) <= Math.abs(o) && i * o >= 0
  const a = i / o
  const c = s / r
  return Math.abs(c - a) <= TOLERANCE && a >= 0 && a <= 1 && c >= 0 && c <= 1
}
export function distance2D(t, n) {
  return distance2D_xy(t.x, t.y, n.x, n.y)
}
export function distance2DFlat(t, n) {
  return distance2D_xy(t.x, t.y, n.x, n.y)
}
export function squaredDistance2D_flat(t, n) {
  return Math.pow(n.x - t.x, 2) + Math.pow(n.y - t.y, 2)
}
export function squaredDistance2D(t, n, e, o) {
  return Math.pow(e - t, 2) + Math.pow(o - n, 2)
}
export function distance3D(t, n) {
  return distance3D_xyz(t.x, t.y, t.z, n.x, n.y, n.z)
}
export function distance3D_xyz(t, n, e, o, i, r) {
  return Math.sqrt(squaredDistance3D_xyz(t, n, e, o, i, r))
}
export function squaredDistance3D(t, n) {
  return squaredDistance3D_xyz(t.x, t.y, t.z, n.x, n.y, n.z)
}
export function squaredDistance3D_xyz(t, n, e, o, i, r) {
  return (
    Math.pow(o - t, 2) + Math.pow(i - n, 2) + Math.pow((r || 0) - (e || 0), 2)
  )
}
export function ringContainsXY2DFlatCoordList(t, n, e) {
  if (t.length <= 9) return false
  let o, i, r, s, a, c, l, u
  for (o = false, i = -3, s = t.length, r = s - 3; i < s; r = i) {
    i += 3
    a = t[i]
    c = t[i + 1]
    l = t[r]
    u = t[r + 1]
    if (
      ((c <= e && e < u) || (u <= e && e < c)) &&
      n < ((l - a) * (e - c)) / (u - c) + a
    )
      o = !o
  }
  return o
}
export function segmentContainsXY2D(t, n, e, o, i, r) {
  const s = o - n
  const a = r - n
  const c = e - t
  const l = i - t
  if (
    (Math.abs(s) <= TOLERANCE && Math.abs(a) > TOLERANCE) ||
    (Math.abs(c) <= TOLERANCE && Math.abs(l) > TOLERANCE)
  )
    return false
  if (Math.abs(s) <= TOLERANCE && Math.abs(a) <= TOLERANCE)
    if (Math.abs(c) <= TOLERANCE && Math.abs(l) <= TOLERANCE) return true
    else return Math.abs(l) <= Math.abs(c) && l * c >= 0
  else if (Math.abs(c) <= TOLERANCE && Math.abs(l) <= TOLERANCE)
    return Math.abs(a) <= Math.abs(s) && a * s >= 0
  const u = a / s
  const f = l / c
  return Math.abs(f - u) <= TOLERANCE && u >= 0 && u <= 1 && f >= 0 && f <= 1
}
export function closestPointOnSegment(t, n, e, o) {
  return closestPointOnLine(t, n, e, true, o)
}
export function closestPointOnLine(t, n, e, o, i) {
  const r = n.x
  const s = n.y
  const a = e.x
  const c = e.y
  const l = t.x
  const u = t.y
  const f = undefined
  const p = a - r
  const h = undefined
  const x = c - s
  const C = p * p + x * x
  let g
  if (0 !== C) g = ((l - r) * p + (u - s) * x) / C
  else g = 0
  if (o)
    if (g < 0) g = 0
    else if (g > 1) g = 1
  const E = r + g * p
  const M = s + g * x
  const d = l - E
  const T = u - M
  i.x = E
  i.y = M
  return { distance: Math.sqrt(d * d + T * T), position: i }
}
export function squaredDistanceToSegment(t, n, e, o, i, r) {
  const s = undefined
  const a = e - t
  const c = undefined
  const l = o - n
  const u = a * a + l * l
  let f
  if (0 !== u) f = ((i - t) * a + (r - n) * l) / u
  else f = 0
  if (f < 0) f = 0
  else if (f > 1) f = 1
  const p = undefined
  const h = undefined
  const x = i - (t + f * a)
  const C = r - (n + f * l)
  return x * x + C * C
}
function minSegmentDistanceTo(t, n) {
  const e = t
  const o = n
  function i(t, n, i, r, s) {
    let a
    if (r > 1) {
      a = squaredDistanceToSegment(s[r - 3], s[r - 2], n, i, e, o)
      if (a < t) return a
    }
    return t
  }
  return i
}
export function squaredDistanceToLineFlat(t, n, e) {
  const o = t.length
  if (o < 3) return Number.POSITIVE_INFINITY
  else if (3 === o) return squaredDistance2D(t[0], t[1], n, e)
  else return reduceByPair(t, minSegmentDistanceTo(n, e), 1 / 0)
}
export function angleInRadians(t, n, e, o) {
  return Math.atan2(o - n, e - t)
}
export function forwardAzimuth2D_xy(t, n, e, o) {
  const i = angleInRadians(t, n, e, o),
    r = Math.PI / 2 - i
  return r < 0 ? r + 2 * Math.PI : r
}
export function forwardAzimuth2D(t, n) {
  return forwardAzimuth2D_xy(t.x, t.y, n.x, n.y)
}
export function normalizeAngle(t) {
  while (t > 180) t -= 360
  while (t <= -180) t += 360
  return t
}
export function normalizeAngle0To360(t, n) {
  n = n || 0
  while (t + n < 0) t += 360
  while (t + n >= 360) t -= 360
  return t
}
export function containsAnglePragmatic(t, n, e) {
  return containsAngleWithTolerance(
    t,
    n,
    e,
    Constants.ABSOLUTE_ANGLE_TOLERANCE,
    Constants.RELATIVE_ANGLE_TOLERANCE
  )
}
function containsAngleWithTolerance(t, n, e, o, i) {
  const r = 0 === n ? o : (n < 0 ? -1 : 1) * (o + i * Math.abs(n))
  return containsAngle(t - r, n + 2 * r, e)
}
export function containsAngle(t, n, e) {
  if (n >= 360 || n <= -360) return true
  const o = (t = normalizeAngle(t)) + n
  const i = normalizeAngle(e)
  if (n >= 0)
    if (o > 180 && i < t) return i + 360 <= o
    else return i >= t && i <= o
  else if (o <= -180 && i >= t) return i - 360 >= o
  else return i >= o && i <= t
}
export function intersection2DLSSFCT(t, n, e, o, i, r, s, a, c) {
  const l = (o - n) * (s - i) - (e - t) * (a - r)
  c.move2D(t, n)
  if (Math.abs(l) < 1e-10) c.move2D((e + i) / 2, (o + r) / 2)
  else {
    const u = ((t - i) * (a - r) - (n - r) * (s - i)) / l
    c.move2D(t + u * (e - t), n + u * (o - n))
  }
}
export function _intersection2DSFCT(t, n, e, o, i, r, s, a, c) {
  const l = (t - e) * (r - a) - (n - o) * (i - s)
  if (0 === l) {
    c.x = null
    c.y = null
    c.intersects = false
    return
  }
  const u = ((t * o - n * e) * (i - s) - (t - e) * (i * a - r * s)) / l
  const f = ((t * o - n * e) * (r - a) - (n - o) * (i * a - r * s)) / l
  c.x = u
  c.y = f
  c.intersects = true
}
export function segmentIntersectionSFCT(t, n, e, o, i, r, s, a, c) {
  const l = { x: null, y: null, intersects: false }
  _intersection2DSFCT(t, n, e, o, i, r, s, a, l)
  if (!l.intersects) {
    c.intersects = false
    c.x = null
    c.y = null
    return false
  }
  const u = Math.min(t, e) - TOLERANCE
  const f = Math.max(t, e) + TOLERANCE
  const p = Math.min(n, o) - TOLERANCE
  const h = Math.max(n, o) + TOLERANCE
  const x = Math.min(i, s) - TOLERANCE
  const C = Math.max(i, s) + TOLERANCE
  const g = Math.min(r, a) - TOLERANCE
  const E = Math.max(r, a) + TOLERANCE
  const M = l.x
  const d = l.y
  const T = undefined
  const y = undefined
  if (
    u <= M &&
    M <= f &&
    p <= d &&
    d <= h &&
    x <= M &&
    M <= C &&
    g <= d &&
    d <= E
  ) {
    c.intersects = true
    c.x = M
    c.y = d
  } else {
    c.intersects = false
    c.x = null
    c.y = null
  }
  return c.intersects
}
export function rectangleSegmentIntersectionsSFCT(t, n, e, o, i, r, s, a, c) {
  const l = { x: null, y: null, intersects: false }
  let u = false
  let f
  let p = 0
  const h = Math.min(i, s)
  const x = Math.max(i, s)
  const C = Math.min(r, a)
  const g = Math.max(r, a)
  c.length = 0
  _intersection2DSFCT(t, n, e, n, i, r, s, a, l)
  if (l.intersects) {
    f = l.x
    u = t <= f && f <= e
    f = l.y
    u = u && C <= f && f <= g
    if (u) {
      p += 1
      c.push(l.x, l.y)
    }
  }
  _intersection2DSFCT(t, o, e, o, i, r, s, a, l)
  if (l.intersects) {
    f = l.x
    u = t <= f && f <= e
    f = l.y
    u = u && C <= f && f <= g
    if (u) {
      p += 1
      c.push(l.x, l.y)
    }
  }
  _intersection2DSFCT(t, o, t, n, i, r, s, a, l)
  if (l.intersects) {
    f = l.y
    u = o <= f && f <= n
    f = l.x
    u = u && h <= f && f <= x
    if (u) {
      p += 1
      c.push(l.x, l.y)
    }
  }
  _intersection2DSFCT(e, o, e, n, i, r, s, a, l)
  if (l.intersects) {
    f = l.y
    u = o <= f && f <= n
    f = l.x
    u = u && h <= f && f <= x
    if (u) {
      p += 1
      c.push(l.x, l.y)
    }
  }
  c.intersections = p
}
function sign(t) {
  return t < 0 ? -1 : 1
}
export function rotatePointOnOriginCW(t, n, e, o) {
  const i = Math.sin(e)
  const r = Math.cos(e)
  o.x = t * r + n * i
  o.y = n * r - t * i
}
export function rotatePointOnOriginCCW(t, n, e, o) {
  const i = Math.sin(e)
  const r = Math.cos(e)
  o.x = t * r - n * i
  o.y = n * r + t * i
}
export function rotatePointCCW(t, n, e, o, i, r) {
  const s = undefined
  const a = undefined
  rotatePointOnOriginCW(t - o, n - i, e, r)
  r.x += o
  r.y += i
}
export function rotatePointCW(t, n, e, o, i, r) {
  const s = undefined
  const a = undefined
  rotatePointOnOriginCCW(t - o, n - i, e, r)
  r.x += o
  r.y += i
}
export function lineCircleIntersectionSFCT(t, n, e, o, i, r) {
  const s = e - t
  const a = o - n
  const c = t * o - e * n
  const l = Math.pow(c, 2)
  const u = squaredDistance2D(t, n, e, o)
  const f = undefined
  const p = Math.pow(i, 2) * u - l
  if (p < 0) {
    r.length = 0
    r.intersections = 0
    return r
  }
  const h = Math.sqrt(p)
  const x = (c * a + sign(a) * s * h) / u
  const C = (-c * s + Math.abs(a) * h) / u
  r[0] = x
  r[1] = C
  if (0 === p) {
    r.length = 2
    r.intersections = 1
    return r
  }
  r.length = 4
  r.intersections = 2
  const g = (c * a - sign(a) * s * h) / u
  const E = (-c * s - Math.abs(a) * h) / u
  r[2] = g
  r[3] = E
}
export function lineCircleIntersectionWithCenterPointSFCT(
  t,
  n,
  e,
  o,
  i,
  r,
  s,
  a
) {
  lineCircleIntersectionSFCT((t -= i), (n -= r), (e -= i), (o -= r), s, a)
  for (let t = 0; t < 2 * a.intersections; t += 2) {
    a[t] += i
    a[t + 1] += r
  }
}
function parallelAxisSegmentCircleIntersectionSFCT(t, n, e, o, i, r) {
  const s = []
  function a(t, n, e, o, i, r) {
    if (t === e) return n <= o ? n <= r && r <= o : o <= r && r <= n
    else if (n === o) return t <= e ? t <= i && i <= e : e <= i && i <= t
    else
      throw new ProgrammingError(
        'Cannot transform Bounds in non rectangular view (should never occur)'
      )
  }
  r.length = 0
  r.intersections = 0
  lineCircleIntersectionSFCT(t, n, e, o, i, s)
  const c = s.intersections
  let l, u, f
  if (1 === c) {
    l = s[0]
    u = s[1]
    f = a(t, n, e, o, l, u)
    if (f) {
      r.intersections = 1
      r.push(l)
      r.push(u)
    }
  } else if (2 === c) {
    l = s[0]
    u = s[1]
    f = a(t, n, e, o, l, u)
    if (f) {
      r.intersections += 1
      r.push(l)
      r.push(u)
    }
    l = s[2]
    u = s[3]
    f = a(t, n, e, o, l, u)
    if (f) {
      r.intersections += 1
      r.push(l)
      r.push(u)
    }
  }
}
export function rectangleCircleIntersection(t, n, e, o, i) {
  const r = []
  function s(t, n) {
    t.intersections += n.intersections
    t.push.apply(t, n)
  }
  const a = []
  a.intersections = 0
  parallelAxisSegmentCircleIntersectionSFCT(t, n, t, o, i, r)
  s(a, r)
  parallelAxisSegmentCircleIntersectionSFCT(t, n, e, n, i, r)
  s(a, r)
  parallelAxisSegmentCircleIntersectionSFCT(e, n, e, o, i, r)
  s(a, r)
  parallelAxisSegmentCircleIntersectionSFCT(t, o, e, o, i, r)
  s(a, r)
  return a
}
export function clamp(t, n, e) {
  if (t > e) t = e
  if (t < n) t = n
  return t
}
export function toPolar(t, n, e) {
  e.r = Math.sqrt(t * t + n * n)
  e.a = Math.atan2(n, t)
}
export function ellipticalDistance2D(t, n, e) {
  if (t === n) return t
  else if (0 === t) return 90 === Math.abs(e % 180) ? n : 0
  else if (0 === n) return e % 180 === 0 ? t : 0
  else {
    const o = e * Constants.DEG2RAD
    const i = Math.cos(o)
    const r = undefined
    const s = t * Math.sin(o)
    const a = n * i
    return (t * n) / Math.sqrt(s * s + a * a)
  }
}
export function orientation2D(t) {
  if (!t || t.length < 1)
    throw new ProgrammingError(
      'Cannot calculate orientation. You should provide a proper array with at least 1 point'
    )
  let n = t.length - 1
  const e = t[0]
  const o = t[n]
  if (e.x === o.x && e.y === o.y && e.z === o.z) n--
  let i = 0
  for (let e = 0; e < n; e++) i += t[e].x * t[e + 1].y - t[e].y * t[e + 1].x
  i += t[n].x * t[0].y - t[n].y * t[0].x
  return i > 0
    ? PolygonOrientation.COUNTER_CLOCKWISE
    : PolygonOrientation.CLOCKWISE
}
export function orientation2D_flat(t) {
  let n = t.length - 3
  if (t[0] === t[n] && t[1] === t[n + 1]) n -= 3
  let e = 0
  for (let o = 0; o < n; o += 3) e += t[o] * t[o + 4] - t[o + 1] * t[o + 3]
  e += t[n] * t[1] - t[n + 1] * t[0]
  return e > 0
    ? PolygonOrientation.COUNTER_CLOCKWISE
    : PolygonOrientation.CLOCKWISE
}
export function pointAtDistanceAndAngle(t, n, e, o, i) {
  i.x = t + o * Math.cos(e)
  i.y = n + o * Math.sin(e)
}
export function flipOrientationSFCT_flat(t) {
  const n = t.length
  let e, o, i
  for (e = 0, i = 3 * Math.floor(n / 6); e < i; e += 3) {
    o = t[e]
    t[e] = t[n - 3 - e]
    t[n - 3 - e] = o
    o = t[e + 1]
    t[e + 1] = t[n - 2 - e]
    t[n - 2 - e] = o
    o = t[e + 2]
    t[e + 2] = t[n - 1 - e]
    t[n - 1 - e] = o
  }
}
export function interpolate(t, n, e) {
  return t + (n - t) * e
}
export function isUpsideDown(t) {
  return (
    (Constants.d360InRadians + Constants.d90InRadians + t) %
      Constants.d360InRadians >=
    Constants.d180InRadians
  )
}
export function clipLineWithRectangle(t, n, e, o, i, r, s, a, c) {
  let l = t,
    u = n,
    f = e,
    p = o,
    h,
    x,
    C,
    g,
    E,
    M,
    d
  h = Clipping.computeRegion(l, u, i, a, s, r)
  x = Clipping.computeRegion(f, p, i, a, s, r)
  C = false
  g = false
  while (!g)
    if (0 === (h | x)) {
      C = true
      g = true
      break
    } else if (0 !== (h & x)) {
      g = true
      break
    } else {
      E = 0 === h ? x : h
      M = 0 === h ? f : l
      d = 0 === h ? p : u
      if (0 !== (E & Clipping.TOP)) {
        M = l + ((f - l) * (a - u)) / (p - u)
        d = a
      } else if (0 !== (E & Clipping.BOTTOM)) {
        M = l + ((f - l) * (r - u)) / (p - u)
        d = r
      } else if (0 !== (E & Clipping.RIGHT)) {
        d = u + ((p - u) * (s - l)) / (f - l)
        M = s
      } else if (0 !== (E & Clipping.LEFT)) {
        d = u + ((p - u) * (i - l)) / (f - l)
        M = i
      }
      if (E === h) {
        l = M
        u = d
        h = Clipping.computeRegion(l, u, i, a, s, r)
      } else {
        f = M
        p = d
        x = Clipping.computeRegion(f, p, i, a, s, r)
      }
    }
  c.clippedStartX = l
  c.clippedStartY = u
  c.clippedEndX = f
  c.clippedEndY = p
  return C
}
export function area(t) {
  const n = t.pointCount
  let e = 0,
    o = 0
  for (let i = 0; i < n; i++) {
    e += t.getPoint(i).x
    o += t.getPoint(i).y
  }
  e /= n
  o /= n
  let i = 0
  let r, s, a, c
  a = t.getPoint(0).x - e
  c = t.getPoint(0).y - o
  for (let l = 0; l < n - 1; l++) {
    r = a
    s = c
    a = t.getPoint(l + 1).x - e
    c = t.getPoint(l + 1).y - o
    i += r * c - a * s
  }
  r = a
  s = c
  a = t.getPoint(0).x - e
  c = t.getPoint(0).y - o
  i += r * c - a * s
  i /= 2
  return i
}
function reduceByPair(t, n, e) {
  let o = 0
  const i = t.length
  let r = e
  while (o < i) {
    r = n(r, t[o], t[o + 1], o, t)
    o += 3
  }
  return r
}
export function angleDifference(t, n) {
  t = normalizeAngle0To360(t)
  n = normalizeAngle0To360(n)
  return Math.min(Math.abs(t - n), Math.abs(t - (360 - n)))
}
export function orientedAngleOnPlane(t, n, e) {
  function o(t, n, e) {
    const o = Math.acos(clamp(t.dot(n), -1, 1))
    return e.dot(t.clone().cross(n)) < 0 ? -o : o
  }
  function i(t, n) {
    return n.clone().multiplyScalar(t.dot(n) / n.dot(n))
  }
  function r(t, n) {
    return t.clone().sub(i(t, n))
  }
  e = e.clone().normalize()
  t = t.clone().normalize()
  n = n.clone().normalize()
  const s = r(t, e)
  const a = r(n, e)
  if (s.length() < 1e-5 || a.length() < 1e-5) return null
  return o(s.normalize(), a.normalize(), e)
}
