import { ModelTransformer } from './ModelTransformer.js'
export class IdentityTransformer extends ModelTransformer {
  constructor() {
    super()
  }
  resetCaches() {}
  needsTransformation() {
    return false
  }
  doTransformation(e, r) {
    return e
  }
  handleSourceModelChange(e, r, o, n) {
    this._eventedSupport.emitModelChangedEvent(r, o, n)
  }
  put(e, r, o) {
    return e.put(r, o)
  }
  add(e, r, o) {
    return e.add(r, o)
  }
  remove(e, r) {
    return e.remove(r)
  }
  prepareForSelection(e, r) {}
}
