import { Matrix4 } from '../geometry/mesh/math/Matrix4.js'
import { Vector3 as Vector3Internal } from '../geometry/mesh/math/Vector3.js'
import { getReference } from '../reference/ReferenceProvider.js'
import { createBounds, createPoint } from '../shape/ShapeFactory.js'
import { isNumber } from '../util/Lang.js'
import { Transformation } from './Transformation.js'
import { createTransformation } from './TransformationFactory.js'
import { Quaternion } from '../geometry/mesh/math/Quaternion.js'
import { Matrix3 } from '../geometry/mesh/math/Matrix3.js'
import { Constants } from '../util/Constants.js'
export class Affine3DTransformation extends Transformation {
  constructor(e, t, n) {
    super(t, n)
    this._sourceToTargetMatrix4d = e
    this._targetToSourceMatrix4d = new Matrix4()
    this._targetToSourceMatrix4d = this._targetToSourceMatrix4d.getInverse(
      this._sourceToTargetMatrix4d
    )
  }
  _forward(e, t) {
    return this._transform(
      e,
      t,
      this._sourceToTargetMatrix4d,
      this._outputReference
    )
  }
  _inverse(e, t) {
    return this._transform(
      e,
      t,
      this._targetToSourceMatrix4d,
      this._inputReference
    )
  }
  _forwardBoundsCoords(e, t) {
    return this._transformBounds(
      e,
      t,
      this._sourceToTargetMatrix4d,
      this.outputReference
    )
  }
  _inverseBoundsCoords(e, t) {
    return this._transformBounds(
      e,
      t,
      this._targetToSourceMatrix4d,
      this.inputReference
    )
  }
  getTransformationMatrix() {
    return this._sourceToTargetMatrix4d
  }
  getInverseTransformationMatrix() {
    return this._targetToSourceMatrix4d
  }
  get inverseTransformation() {
    return new Affine3DTransformation(
      this._targetToSourceMatrix4d,
      this.outputReference,
      this.inputReference
    )
  }
  _transform = (e, t, n, r) => {
    const o = undefined
    let a = new Vector3Internal(e.x, e.y, e.z).clone()
    a = a.applyMatrix4(n)
    if (t) {
      t.move3D(a.x, a.y, a.z)
      return t
    } else return createPoint(r, [a.x, a.y, a.z])
  }
  _transformBounds = (e, t, n, r) => {
    const o = []
    const a = createPoint(e.reference, [e.x, e.y, e.z])
    o.push(this._transform(a, null, n))
    a.move3D(e.x, e.y, e.z + e.depth)
    o.push(this._transform(a, null, n))
    a.move3D(e.x, e.y + e.height, e.z)
    o.push(this._transform(a, null, n))
    a.move3D(e.x, e.y + e.height, e.z + e.depth)
    o.push(this._transform(a, null, n))
    a.move3D(e.x + e.width, e.y, e.z)
    o.push(this._transform(a, null, n))
    a.move3D(e.x + e.width, e.y, e.z + e.depth)
    o.push(this._transform(a, null, n))
    a.move3D(e.x + e.width, e.y + e.height, e.z)
    o.push(this._transform(a, null, n))
    a.move3D(e.x + e.width, e.y + e.height, e.z + e.depth)
    o.push(this._transform(a, null, n))
    if (!t) t = createBounds(r, [o[0].x, 0, o[0].y, 0, o[0].z, 0])
    for (let e = 1; e < o.length; e++)
      t.setToIncludePoint3D(createPoint(t.reference, [o[e].x, o[e].y, o[e].z]))
    return t
  }
  static _createChainedTransformation(e) {
    if (0 === e.length)
      throw new Error('No arguments given for createdChainedTransformation.')
    let t = e[0].outputReference
    for (const n of e.slice(1)) {
      if (!t.equals(n.inputReference))
        throw new Error(
          'createChainedTransformation: Given chain of Affine3DTransformation objects has inconsistent input/output references,' +
            'making this transformation chain impossible to create. Please ensure the output reference of each Affine3DTransformation' +
            'matches the input reference of the next Affine3DTransformation in the chain.'
        )
      t = n.outputReference
    }
    const n = e.map((e) => e._sourceToTargetMatrix4d).reverse()
    const r = new Matrix4()
    for (const e of n) r.multiply(e)
    return new Affine3DTransformation(
      r,
      e[0].inputReference,
      e[e.length - 1].outputReference
    )
  }
}
function getRotationBetweenDirectionVectors(e, t) {
  ;(e = e.clone()).normalize()
  ;(t = t.clone()).normalize()
  const n = e.dot(t)
  let r
  let o
  if (n < -1 + 0.001) {
    r = new Vector3Internal(0, 0, 1).cross(e)
    if (r.length() < 0.01) r = new Vector3Internal(1, 0, 0).cross(e)
    r.normalize()
    const t = 180 * Constants.DEG2RAD
    const n = Math.sin(0.5 * t)
    o = new Quaternion(r.x * n, r.y * n, r.z * n, Math.cos(0.5 * t))
  } else {
    r = e.cross(t)
    const a = Math.sqrt(2 * (1 + n))
    const s = 1 / a
    o = new Quaternion(r.x * s, r.y * s, r.z * s, 0.5 * a)
  }
  const a = new Matrix4()
  a.makeRotationFromQuaternion(o)
  return a
}
function angle(e, t) {
  const n = e.clone().cross(t)
  return Math.abs(Math.atan2(n.length(), e.dot(t)))
}
function orientedAngle(e, t, n) {
  const r = angle(e, t)
  return n.dot(e.clone().cross(t)) < 0 ? -r : r
}
export function createTransformationFromGeoLocation(e, t) {
  if (!e || !e.reference || !isNumber(e.x) || !isNumber(e.y))
    throw new Error(
      'Affine3DTransformation:createFromGeoLocation first argument should be a valid point'
    )
  const n = getReference('CRS:84')
  const r = undefined
  const o = createTransformation(e.reference, n).transform(e)
  const a = (t = t || {}).azimuth || 0
  const s = t.anchorPoint || createPoint(null, [0, 0, 0])
  const c = t.sourceReference || getReference('LUCIAD:XYZ')
  const i = t.destinationReference || getReference('EPSG:4978')
  const f = createTransformation(n, i)
  const m = new Matrix4()
  m.makeTranslation(-s.x, -s.y, -s.z)
  const u = f.transform(o)
  const l = new Vector3Internal(u.x, u.y, u.z)
  const h = new Matrix4()
  h.makeTranslation(l.x, l.y, l.z)
  const x = o.copy()
  x.translate3D(0, 0, 1e3)
  const d = f.transform(x)
  const p = new Vector3Internal(d.x, d.y, d.z)
  p.sub(l)
  p.normalize()
  const T = undefined
  const w = getRotationBetweenDirectionVectors(new Vector3Internal(0, 0, 1), p)
  const y = createPoint(n, [0, 0, 0])
  n.geodeticDatum.ellipsoid.geodesicPositionSFCT(o, 1e3, a, y)
  y.z = o.z
  const g = f.transform(y)
  const R = new Vector3Internal(g.x, g.y, g.z)
  R.sub(l)
  R.normalize()
  const z = new Vector3Internal(0, 1, 0)
  const M = new Matrix3()
  M.setFromMatrix4(w)
  z.applyMatrix3(M)
  z.normalize()
  const D = orientedAngle(z, R, p)
  const V = new Matrix4()
  V.makeRotationAxis(p, D)
  const I = new Matrix4()
  I.multiply(h)
  I.multiply(V)
  I.multiply(w)
  I.multiply(m)
  return new Affine3DTransformation(I, c, i)
}
export function createRotationTransformation(e, t) {
  t = t || {}
  let n = e.x || 0
  let r = e.y || 0
  let o = e.z || 0
  n *= Constants.DEG2RAD
  r *= Constants.DEG2RAD
  o *= Constants.DEG2RAD
  const a = t.sourceReference || getReference('LUCIAD:XYZ')
  const s = new Matrix4()
  s.makeRotationFromEuler(new Vector3Internal(n, r, o), 'YXZ')
  return new Affine3DTransformation(s, a, a)
}
export function createTranslationTransformation(e, t) {
  t = t || {}
  const n = e.x || 0
  const r = e.y || 0
  const o = e.z || 0
  const a = t.sourceReference || getReference('LUCIAD:XYZ')
  const s = new Matrix4()
  s.makeTranslation(n, r, o)
  return new Affine3DTransformation(s, a, a)
}
export function createScaleTransformation(e) {
  const t = (e = e || {}).scaleX || 1
  const n = e.scaleY || 1
  const r = e.scaleZ || 1
  const o = e.centerX || 0
  const a = e.centerY || 0
  const s = e.centerZ || 0
  const c = e.sourceReference || getReference('LUCIAD:XYZ')
  const i = e.destinationReference || getReference('LUCIAD:XYZ')
  const f = new Matrix4()
  f.makeTranslation(-o, -a, -s)
  const m = new Matrix4()
  m.makeScale(t, n, r)
  const u = new Matrix4()
  u.makeTranslation(o, a, s)
  const l = new Matrix4()
  l.multiply(u)
  l.multiply(m)
  l.multiply(f)
  return new Affine3DTransformation(l, c, i)
}
export function createOffsetTransformation(e, t, n) {
  if (!t || !t.reference || !isNumber(t.x) || !isNumber(t.y))
    throw new Error(
      'Affine3DTransformation:createOffsetTransform anchor point should be a valid point'
    )
  const r = (n = n || {}).sourceReference || getReference('EPSG:4978')
  const o = getReference('CRS:84')
  const a = getReference('EPSG:4978')
  const s = createTransformation(t.reference, a)
  const c = createTransformation(a, o)
  const i = createTransformation(o, a)
  const f = createPoint(a, [0, 0, 0])
  s.transform(t, f)
  const m = createPoint(o, [0, 0, 0])
  c.transform(f, m)
  const u = 0.01
  const l = 1
  const h = createPoint(o, [m.x + u, m.y, m.z])
  const x = createPoint(a, [0, 0, 0])
  i.transform(h, x)
  const d = createPoint(o, [m.x, m.y + u, m.z])
  const p = createPoint(a, [0, 0, 0])
  i.transform(d, p)
  const T = createPoint(o, [m.x, m.y, m.z + l])
  const w = createPoint(a, [0, 0, 0])
  i.transform(T, w)
  const y = new Vector3Internal().copy(x)
  const g = new Vector3Internal().subVectors(y, f).normalize()
  const R = new Vector3Internal().copy(p)
  const z = new Vector3Internal().subVectors(R, f).normalize()
  const M = new Vector3Internal().copy(w)
  const D = new Vector3Internal().subVectors(M, f).normalize()
  const V = new Vector3Internal(e.x * g.x, e.x * g.y, e.x * g.z)
  const I = new Vector3Internal(e.y * z.x, e.y * z.y, e.y * z.z)
  const _ = new Vector3Internal(e.z * D.x, e.z * D.y, e.z * D.z)
  const A = new Vector3Internal().addVectors(
    new Vector3Internal().addVectors(V, I),
    _
  )
  const P = new Vector3Internal().addVectors(A, f)
  const C = createTransformation(a, r)
  const v = createPoint(r, [0, 0, 0])
  C.transform(f, v)
  const b = createPoint(r, [0, 0, 0])
  C.transform(P, b)
  const S = new Vector3Internal().subVectors(
    new Vector3Internal(b.x, b.y, b.z),
    new Vector3Internal(v.x, v.y, v.z)
  )
  const E = new Matrix4()
  E.makeTranslation(S.x, S.y, S.z)
  return new Affine3DTransformation(E, r, r)
}
export function createChainedTransformation() {
  for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
    t[n] = arguments[n]
  return Affine3DTransformation._createChainedTransformation(t)
}
export function createIdentityTransformation(e) {
  const t = getReference('LUCIAD:XYZ')
  e = e || {}
  return new Affine3DTransformation(
    new Matrix4(),
    e.sourceReference ? e.sourceReference : t,
    e.destinationReference ? e.destinationReference : t
  )
}
