import { Clustered } from './Clustered.js'
import { ClusteredPoint } from './ClusteredPoint.js'
import { XYZBounds } from '../../../shape/XYZBounds.js'
import { clamp } from '../../../util/Cartesian.js'
const MAX_CELL_COUNT = Math.pow(2, 31) - 1
export class GridAlgorithm {
  constructor() {
    this._grid = new Map()
    this._gridBounds = new XYZBounds(null, [0, 0, 0, 0])
    this._gridCellWidth = 0
    this._gridCellHeight = 0
    this._cellCountX = 0
  }
  cluster(t, i, s) {
    const e = removeTooSmallClusters(i, s)
    this._grid.clear()
    const o = getBounds(t)
    this._gridBounds = new XYZBounds(null, [
      o.x,
      1.00001 * o.width,
      o.y,
      1.00001 * o.height,
    ])
    const n = s.clusterSize || 1
    const r = s.clusterSize || 1
    this._cellCountX = Math.floor(clamp(o.width / n, 1, MAX_CELL_COUNT))
    const l = Math.floor(clamp(o.height / r, 1, MAX_CELL_COUNT))
    this._gridCellWidth = this._gridBounds.width / this._cellCountX
    this._gridCellHeight = this._gridBounds.height / l
    for (const i of t) if (!i.cluster) this.add(i)
    return this.getClusters(e, s)
  }
  add(t) {
    const i = this.index(
      this.gridX(t.viewLocation.x),
      this.gridY(t.viewLocation.y)
    )
    const s = this._grid.get(i)
    if (s instanceof Clustered) s.addPoint(t)
    else if (s instanceof ClusteredPoint) {
      const e = new Clustered()
      this._grid.set(i, e)
      e.addPoint(s)
      e.addPoint(t)
    } else this._grid.set(i, t)
  }
  gridX(t) {
    if (0 === this._gridCellWidth) return 0
    const i = t - this._gridBounds.x
    return Math.floor(i / this._gridCellWidth)
  }
  gridY(t) {
    if (0 === this._gridCellHeight) return 0
    const i = t - this._gridBounds.y
    return Math.floor(i / this._gridCellHeight)
  }
  index(t, i) {
    return i * this._cellCountX + t
  }
  getClusters(t, i) {
    const s = []
    this._grid.forEach((t) => {
      if (t instanceof Clustered) {
        const e = t.getPoints()
        if (e.length >= i.minimumPoints) s.push(t)
        else for (const t of e) t.cluster = null
      }
    })
    s.push(...t)
    return s
  }
}
function removeTooSmallClusters(t, i) {
  const s = []
  for (let e = 0; e < t.length; e++) {
    const o = t[e]
    if (o.getPointCount() < i.minimumPoints) {
      const t = o.getPoints()
      for (let i = 0; i < t.length; i++) t[i].cluster = null
    } else s.push(o)
  }
  return s
}
function getBounds(t) {
  const i = new XYZBounds()
  if (t.length > 0) {
    const { viewLocation: s } = t[0]
    i.setTo2D(s.x, 0, s.y, 0)
  }
  for (let s = 1; s < t.length; s++) {
    const e = t[s]
    i.setToIncludeSimplePoint2D(e.viewLocation)
  }
  return i
}
