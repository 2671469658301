import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { QueryProvider } from '../QueryProvider.js'
import { SingleQueryProvider } from '../SingleQueryProvider.js'
import { LoadingStrategy } from './LoadingStrategy.js'
import { isDeepEqual } from '../../../util/Lang.js'
export class LoadEverything extends LoadingStrategy {
  constructor(r) {
    super(r)
    if ((r = r || {}).queryProvider && r.query)
      throw new ProgrammingError(
        'Cannot specify both a query and a query-provider '
      )
    if (r.queryProvider) this.queryProvider = r.queryProvider
    else if (r.query) this.setQuery(r.query)
    else this.setQuery(null)
  }
  shouldClear() {
    return this.isMapLevelChanged()
  }
  isNothingToShow() {
    const { level: r, query: e } = this.getLevelQueryParams()
    const i = e === QueryProvider.QUERY_NONE
    if (i) {
      this.activeQueryLevel = r
      this.activeQuery = e
    }
    return i
  }
  setQuery = (r) => {
    this.queryProvider = new SingleQueryProvider(r)
  }
  getQuery = () => (this.queryProvider ? this.queryProvider._query : null)
  shouldQuery() {
    return !isDeepEqual(this.activeQuery, this.getLevelQueryParams().query)
  }
  queryModel(r, e) {
    const { level: i, query: t } = this.getLevelQueryParams()
    this.activeQueryLevel = i
    this.activeQuery = t
    return r.query(this.activeQuery, { abortSignal: e })
  }
}
