import { TileRecordType } from './TileRecordType.js'
import { hasPath } from './TileUtil.js'
export class CombinedTileRecord {
  constructor(e, r) {
    this._coordinate = e
    this._records = r
    this._proxy = true
    this._entireAreaCovered = false
    this._baseTileCoordinate = e
    this._fallBack = false
    this._complete = false
  }
  get fallback() {
    return this._fallBack
  }
  set fallback(e) {
    this._fallBack = e
  }
  get baseTileCoordinate() {
    return this._baseTileCoordinate
  }
  get entireAreaCovered() {
    return this._entireAreaCovered
  }
  get proxy() {
    return this._proxy
  }
  get leaf() {
    return false
  }
  get coordinate() {
    return this._coordinate
  }
  get type() {
    return TileRecordType.COMBINED
  }
  get complete() {
    return this._complete
  }
  set complete(e) {
    this._complete = e
  }
  draw(e, r) {
    const t = this._records
    for (const i of t) i.draw(e, r)
  }
  deriveTile(e, r, t) {
    const i = this._coordinate
    if (e.level === i.level && e.x === i.x && e.y === i.y)
      return new CombinedTileRecord(i, this._records)
    if (!hasPath(i, e)) return null
    if (e.level < i.level) return new CombinedTileRecord(e, this._records)
    else {
      let i = null
      for (const l of this._records) {
        const o = l.deriveTile(e, r, t)
        if (null != o)
          if (o.entireAreaCovered) return o
          else {
            if (null == i) i = []
            i.push(o)
          }
      }
      if (null != i) return new CombinedTileRecord(e, i)
      else return null
    }
  }
}
