import { isUndefined } from './Lang.js'
import { LRUCache } from './LRUCache.js'
const NAMED_COLORS = {
  black: { r: 0 / 255, g: 0 / 255, b: 0 / 255, a: 1 },
  silver: { r: 192 / 255, g: 192 / 255, b: 192 / 255, a: 1 },
  gray: { r: 128 / 255, g: 128 / 255, b: 128 / 255, a: 1 },
  white: { r: 255 / 255, g: 255 / 255, b: 255 / 255, a: 1 },
  maroon: { r: 128 / 255, g: 0 / 255, b: 0 / 255, a: 1 },
  red: { r: 255 / 255, g: 0 / 255, b: 0 / 255, a: 1 },
  purple: { r: 128 / 255, g: 0 / 255, b: 128 / 255, a: 1 },
  fuchsia: { r: 255 / 255, g: 0 / 255, b: 255 / 255, a: 1 },
  green: { r: 0 / 255, g: 128 / 255, b: 0 / 255, a: 1 },
  lime: { r: 0 / 255, g: 255 / 255, b: 0 / 255, a: 1 },
  olive: { r: 128 / 255, g: 128 / 255, b: 0 / 255, a: 1 },
  yellow: { r: 255 / 255, g: 255 / 255, b: 0 / 255, a: 1 },
  navy: { r: 0 / 255, g: 0 / 255, b: 128 / 255, a: 1 },
  blue: { r: 0 / 255, g: 0 / 255, b: 255 / 255, a: 1 },
  teal: { r: 0 / 255, g: 128 / 255, b: 128 / 255, a: 1 },
  aqua: { r: 0 / 255, g: 255 / 255, b: 255 / 255, a: 1 },
}
export const RED = 'rgb(255,0,0)'
export const GREEN = 'rgb(0,255,0)'
export const BLUE = 'rgb(0,0,255)'
export const INVISIBLE = 'rgba(0,0,0,0)'
function parseColor(r) {
  if ('#' == r.substr(0, 1)) {
    const t = 4 == r.length || 5 == r.length
    const o = 5 == r.length || 9 == r.length
    const e = t ? 1 : 2
    const n = t ? 15 : 255
    return {
      r: parseInt(r.substr(1, e), 16) / n,
      g: parseInt(r.substr(1 + e, e), 16) / n,
      b: parseInt(r.substr(1 + 2 * e, e), 16) / n,
      a: o ? parseInt(r.substr(1 + 3 * e, e), 16) / n : 1,
    }
  } else if ('rgb' == r.substr(0, 3) || 'RGB' == r.substr(0, 3)) {
    const t = r.split('(')[1].split(')')[0].split(',')
    return {
      r: +t[0] / 255,
      g: +t[1] / 255,
      b: +t[2] / 255,
      a: t.length > 3 ? +t[3] : 1,
    }
  } else throw new Error('String color cannot be parsed: ' + r)
}
export function interpolateColor(r, t, o) {
  const e = parseColor(r)
  const n = parseColor(t)
  const a = Math.floor(255 * (e.r + o * (n.r - e.r)))
  const s = Math.floor(255 * (e.g + o * (n.g - e.g)))
  const g = Math.floor(255 * (e.b + o * (n.b - e.b)))
  const i = Math.floor(255 * (e.a + o * (n.a - e.a)))
  return `#${addLeadingZero(a.toString(16))}${addLeadingZero(
    s.toString(16)
  )}${addLeadingZero(g.toString(16))}${addLeadingZero(i.toString(16))}`
}
function addLeadingZero(r) {
  if (r.length < 2) return `0${r}`
  else return r
}
export function canCreatePhotonColorFromString(r) {
  try {
    createPhotonColorFromString(r)
    return true
  } catch (r) {
    return false
  }
}
const PHOTON_COLOR_CACHE = new LRUCache(100)
export function createPhotonColorFromString(r) {
  if (!r) throw new Error('Should submit a string color')
  let t = PHOTON_COLOR_CACHE.get(r)
  if (isUndefined(t)) {
    t = NAMED_COLORS[r.toLowerCase()] || parseColor(r)
    PHOTON_COLOR_CACHE.put(r, t)
  }
  return t
}
