import { Controller } from './Controller.js'
import { HandleEventResult } from './HandleEventResult.js'
import { GestureEventType } from '../input/GestureEventType.js'
import { PanFlingAnimation } from '../animation/PanFlingAnimation.js'
import { AnimationManager } from '../animation/AnimationManager.js'
import { LocationMode } from '../../transformation/LocationMode.js'
import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
const NUMBER_OF_POSITIONS_TO_TRACK = 5
const INERTIA_DRAG_RELEASE_THRESHOLD = 25
const RIGHT_MOUSE_BUTTON = 2
const DEFAULT_VELOCITY_MULTIPLIER = 20
const DEFAULT_INERTIA_OPTIONS = {
  frictionMultiplier: 1,
  speedMultiplier: 1,
  maxSpeed: 5e3,
}
export class PanController extends Controller {
  constructor() {
    super()
    this._isPanning = false
    this._dragPoints = []
    this._sideGlowRenderer = null
  }
  onActivate(t) {
    super.onActivate(t)
    t.layerTree.whenReady().then(() => {
      if (this.map === t)
        this._sideGlowRenderer = t.viewPaintingStrategy?.sideGlowRenderer
    })
  }
  onDeactivate(t) {
    this._sideGlowRenderer?.updateDragPoint(t, null)
    this._sideGlowRenderer = null
    return super.onDeactivate(t)
  }
  onGestureEvent(t) {
    if (!this.map) return HandleEventResult.EVENT_IGNORED
    const n = this.isPanEvent(t)
    const e = this.isPanEndEvent(t)
    if (n && !e) {
      const n = this.isInertia(t)
      const e = t
      const { x: i, y: s } = e.viewPoint
      const { x: r, y: o } = e.downEvent.viewPoint
      const a = i - r
      const l = s - o
      if (!this._isPanning) {
        this.map.mapNavigator.beginPan(i, s, this.isConstantYaw(t))
        this._isPanning = true
        if (n) this._dragPoints = []
      } else this.map.mapNavigator.incrementalPan(a, l, i, s)
      if (n) {
        this._dragPoints.push({ x: i, y: s, time: performance.now() })
        if (this._dragPoints.length > NUMBER_OF_POSITIONS_TO_TRACK)
          this._dragPoints.splice(0, 1)
      }
      this._sideGlowRenderer?.updateDragPoint(this.map, e.viewPoint)
      return HandleEventResult.EVENT_HANDLED
    } else if (n && e && this._isPanning) {
      const n = this.isInertia(t)
      const e = { ...DEFAULT_INERTIA_OPTIONS, ...this.getInertiaOptions(t) }
      const i = this.isConstantYaw(t)
      this.map.mapNavigator.endPan()
      this._isPanning = false
      this._sideGlowRenderer?.updateDragPoint(this.map, null)
      const s = performance.now()
      if (
        n &&
        this._dragPoints.length > 3 &&
        s - this._dragPoints[this._dragPoints.length - 1].time <
          INERTIA_DRAG_RELEASE_THRESHOLD
      ) {
        let n = 0
        let s = 0
        let r = 0
        const o = 'touch' === t.inputType ? 1 : 3
        for (
          let t = this._dragPoints.length - 1;
          t >= this._dragPoints.length - o;
          t--
        ) {
          const e = this._dragPoints[t]
          const i = this._dragPoints[t - 1]
          n += i.x - e.x
          s += i.y - e.y
          r++
        }
        const a = n / r
        const l = s / r
        const u = DEFAULT_VELOCITY_MULTIPLIER * e.speedMultiplier
        let E = -a * u
        let d = -l * u
        E = Math.sign(E) * Math.min(Math.abs(E), e.maxSpeed)
        d = Math.sign(d) * Math.min(Math.abs(d), e.maxSpeed)
        let p = true
        try {
          this.map
            .getViewToMapTransformation(LocationMode.TERRAIN)
            .transform(t.viewPoint)
        } catch (t) {
          p = false
          OutOfBoundsError.isOrThrow(t)
        }
        if (p) {
          const n = new PanFlingAnimation(
            this.map,
            t.viewPoint,
            E,
            d,
            e.frictionMultiplier,
            i
          )
          AnimationManager.putAnimation(
            this.map.cameraAnimationKey,
            n,
            false
          ).catch((t) => {
            if ('AbortError' !== t.name) throw t
          })
        }
      }
      return HandleEventResult.EVENT_HANDLED
    }
    return HandleEventResult.EVENT_IGNORED
  }
  isInertia(t) {
    return this.map ? !this.map.is3D() : true
  }
  getInertiaOptions(t) {
    return { frictionMultiplier: 1, speedMultiplier: 1, maxSpeed: 5e3 }
  }
  isConstantYaw(t) {
    return false
  }
  isPanEvent(t) {
    if (
      t.type === GestureEventType.DRAG ||
      t.type === GestureEventType.DRAG_END
    ) {
      if ('touch' === t.inputType) return true
      const n = undefined
      const e = t.downEvent.domEvent
      const i = undefined
      return (e ? e.button : 0) !== RIGHT_MOUSE_BUTTON
    }
    return false
  }
  isPanEndEvent(t) {
    if (t.type === GestureEventType.DRAG_END) {
      if ('touch' === t.inputType) return true
      const n = undefined
      const e = t.downEvent.domEvent
      const i = undefined
      return (e ? e.button : 0) !== RIGHT_MOUSE_BUTTON
    }
    return false
  }
}
