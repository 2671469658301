import * as TileUtil from '../../view/tileset/TileUtil.js'
import { createHTML5Canvas } from '../../view/HTML5Canvas.js'
import { PixelFormat } from './PixelFormat.js'
var html5Canvas = createHTML5Canvas(0, 0)
var canvas2dContext = html5Canvas.getContext('2d', { willReadFrequently: true })
var createImageData
if ('function' === typeof ImageData)
  createImageData = function (e, t) {
    return new ImageData(t, e.width, e.height)
  }
else
  createImageData = function (e, t) {
    var a = canvas2dContext.createImageData(e.width, e.height)
    for (var r = 0; r < t.length; r++) a.data[r] = t[r]
    return a
  }
function RasterTileSetModelDecoderDecorator() {}
RasterTileSetModelDecoderDecorator.create = function (e) {
  if (!e.originalGetTileDataFunction) {
    var t = e.getTileData.bind(e)
    var a = Object.assign(e, RasterTileSetModelDecoderDecorator.prototype)
    a.originalGetTileDataFunction = t
    return a
  }
  return e
}
RasterTileSetModelDecoderDecorator.prototype.getTileData = function (e, t, a) {
  var r = function (e, r) {
    TileUtil.validateTileData(r)
    var i = r.data
    var o
    if (i instanceof Image) t(e, i)
    else if (i instanceof ArrayBuffer)
      if (r.mimeType) {
        o = new Image()
        var n = new Blob([i], { type: r.mimeType })
        o.src = (URL || webkitURL).createObjectURL(n)
        o.onload = function () {
          t(e, o)
        }
        o.onerror = function (t) {
          a(e, new Error('Could not load tile'))
        }
      } else if (r.pixelFormat) {
        var l = createHTML5Canvas(r.width, r.height)
        var c = l.getContext('2d', { willReadFrequently: true })
        var m = TileUtil.convertImageData(r, PixelFormat.RGBA_8888)
        var s = createImageData(r, m)
        c.putImageData(s, 0, 0)
        t(e, l)
      }
  }
  this.originalGetTileDataFunction(e, r, a)
}
export { RasterTileSetModelDecoderDecorator }
