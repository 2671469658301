let LayerTreeVisitor
;(function (e) {
  let r = (function (e) {
    e[(e['ABORT'] = 1)] = 'ABORT'
    e[(e['CONTINUE'] = 2)] = 'CONTINUE'
    return e
  })({})
  e.ReturnValue = r
})(LayerTreeVisitor || (LayerTreeVisitor = {}))
export { LayerTreeVisitor }
