import { closestPointOnGeodesic } from '../../../geodesy/EllipsoidUtil.js'
import {
  createCartesianGeodesy,
  createEllipsoidalGeodesy,
} from '../../../geodesy/GeodesyFactory.js'
import { LLHGeoBufferHelper } from '../../../geometry/constructivegeometry/geobuffer/LLHGeoBufferHelper.js'
import { XYZGeoBufferHelper } from '../../../geometry/constructivegeometry/geobuffer/XYZGeoBufferHelper.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { createGeoBuffer, createPolyline } from '../../../shape/ShapeFactory.js'
import { closestPointOnSegment } from '../../../util/Cartesian.js'
import { EVENT_HANDLED } from '../../controller/HandleEventResult.js'
import * as SnapUtil from '../snapping/SnapUtil.js'
import { ShapeTouchHandle } from './ShapeTouchHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { viewToModel } from './HandleUtil.js'
import { HelperHandle } from './helper/HelperHandle.js'
import { getInteractionRadius } from '../../controller/EditSettings.js'
import { interactsWithControllerShape } from '../EditHandle.js'
export class GeoBufferWidthHandle extends ShapeTouchHandle {
  constructor(e, t) {
    super()
    this._geoBuffer = e
    this._geodesy = null
    this._ellipsoid = null
    this._helper = null
    this._segment = null
    this._segmentGeoBuffer = null
    this._dragEndPoint = null
    this._snapPoint = null
    this._helperHandleDelegate = new HelperHandle(t)
    const i = e.reference
    if (i && i.referenceType === ReferenceType.GEODETIC) {
      const e = i
      this._geodesy = createEllipsoidalGeodesy(e)
      this._ellipsoid = e.geodeticDatum.ellipsoid
      this._helper = new LLHGeoBufferHelper(e)
    } else if (i) {
      const e = i
      this._geodesy = createCartesianGeodesy(e)
      this._helper = new XYZGeoBufferHelper(e)
      this._ellipsoid = null
    }
  }
  get geoBuffer() {
    return this._geoBuffer
  }
  getHelperStyle(e) {
    return this._helperHandleDelegate.getHelperStyle(e)
  }
  getDrapeTarget(e, t) {
    return this._helperHandleDelegate.getDrapeTarget(e, t)
  }
  interacts(e, t) {
    const i = getInteractionRadius(e, t)
    const { x: s, y: r } = e.viewPoint
    const o = interactsWithControllerShape(t.map, s, r, i, this.geoBuffer, true)
    const n = interactsWithControllerShape(
      t.map,
      s,
      r,
      i,
      this.geoBuffer.baseShape,
      true
    )
    return o && !n
  }
  getCursor(e, t) {
    if (this.interacts(e, t) || this.active) return 'ns-resize'
    return null
  }
  deactivate(e, t) {
    this._snapPoint = null
    if (this._segmentGeoBuffer)
      this.geoBuffer.width = this._segmentGeoBuffer.width
    this._segment = null
    this._segmentGeoBuffer = null
    return super.deactivate(e, t)
  }
  onDraw(e, t) {
    if (this._segmentGeoBuffer && this._helper) {
      const i = this._helper.getBufferContour2D(this._segmentGeoBuffer)
      const s = this.getHelperStyle(t)
      if (s) e.drawShape(i, s)
      SnapUtil.paintSnapIcon(e, this._snapPoint)
    }
  }
  process(e, t) {
    this._dragEndPoint = viewToModel(e.viewPoint, t, true)
    if (!this._dragEndPoint) return EVENT_HANDLED
    if (!this._segment) {
      this._segment = this.findClosestSegment(
        this._dragEndPoint,
        this.geoBuffer.baseShape
      )
      if (!this._segment) return EVENT_HANDLED
      const e = createPolyline(this.geoBuffer.reference, this._segment)
      this._segmentGeoBuffer = createGeoBuffer(
        this.geoBuffer.reference,
        e,
        this.geoBuffer.width
      )
      this._segmentGeoBuffer.endCapStyle = this.geoBuffer.endCapStyle
    }
    const i = SnapUtil.findClosestPoint(
      e,
      this._dragEndPoint,
      this.geoBuffer,
      t.map
    )
    if (i) {
      this._dragEndPoint.move2D(i)
      this._snapPoint = i.copy()
    } else this._snapPoint = null
    const s = this.findDistance(this._dragEndPoint, this._segment)
    if (this._segmentGeoBuffer && s > 0) {
      this.emitEditShapeEvent(this.geoBuffer, EditShapeStatus.IN_PROGRESS)
      this._segmentGeoBuffer.width = s
    }
    return EVENT_HANDLED
  }
  findDistance(e, t) {
    const i = t[0]
    const s = t[1]
    const r = e.copy()
    if (this._ellipsoid)
      return closestPointOnGeodesic(i, s, e, this._ellipsoid, 1e-5, 0.01, r)
    return closestPointOnSegment(e, i, s, r).distance
  }
  findClosestSegment(e, t) {
    let i = Number.POSITIVE_INFINITY
    let s = null
    let r
    const o = e.copy()
    for (r = 0; r < t.pointCount - 1; ++r) {
      const n = t.getPoint(r)
      const l = t.getPoint(r + 1)
      let f
      if (this._ellipsoid)
        f = closestPointOnGeodesic(n, l, e, this._ellipsoid, 1e-5, 0.01, o)
      else {
        f = closestPointOnSegment(e, n, l, o)
        f = f.distance
      }
      if (f < i) {
        i = f
        if (null == s) s = [n.copy(), l.copy()]
        s[0].move2D(n)
        s[1].move2D(l)
      }
    }
    return s
  }
}
