import { createShapeList } from '../../shape/ShapeFactory.js'
import { Log } from '../../util/Log.js'
import { XML } from '../../util/XML.js'
import { Feature, isValidFeatureId } from '../feature/Feature.js'
import { GMLGeometryParser } from './GMLGeometryParser.js'
import { isString } from '../../util/Lang.js'
import {
  getIdGenerator,
  naturalFeatureIdGenerator,
} from '../feature/FeatureIDProvider.js'
export class GMLFeatureParser {
  _tmpOut = { name: null, value: null }
  reset() {
    this._tmpOut.name = null
    this._tmpOut.value = null
  }
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    this._failOnUnsupportedGeometry = !!e.failOnUnsupportedGeometry
    this._geometryParser = new GMLGeometryParser(
      this._failOnUnsupportedGeometry
    )
    this._stack = []
    this._featureName = e.featureName
    this._geometryName = e.geometryName
    this._dateNames = e.dateNames || []
    this._namespace = 'gml'
    this._idGenerator = getIdGenerator(e.idProvider, naturalFeatureIdGenerator)
  }
  setGMLNamespace(e) {
    this._namespace = e
  }
  isProperty(e) {
    return nonText(e)
  }
  isDate(e) {
    return this._dateNames.indexOf(e.nodeName) > -1
  }
  isGeometry(e) {
    return this._geometryName
      ? e.nodeName === this._geometryName
      : this._geometryParser.isGeometryNode(e)
  }
  decodeProperty(e, t) {
    this.reset()
    XML.parseNameAndTextValue(e, this._tmpOut)
    const { name: r, value: i } = this._tmpOut
    if (!isString(r)) return
    if (this.isDate(e)) t[r] = new Date(i)
    else t[r] = i
  }
  findUserDefinedFeatureNode(e) {
    let t = XML.matchesName(e, 'featureMember', 'http://www.opengis.net/gml')
      ? e.firstChild
      : e
    while (t) {
      if (
        nonText(t) &&
        (this._featureName ? t.nodeName === this._featureName : true)
      )
        break
      t = t.nextSibling
    }
    return t
  }
  retrieveId(e) {
    if (!e) return null
    const t = e.getAttribute(this._namespace ? this._namespace + ':id' : 'id')
    if (t) return t
    const r = e.attributes
    for (let e = 0; e < r.length; e++)
      if (r[e] && r[e].name && r[e].name.toLowerCase().indexOf('id') > -1)
        if (r[e].value) return r[e].value
    return null
  }
  decodeFeature(e, t, r) {
    let i = this.findUserDefinedFeatureNode(e)
    if (!i) {
      Log.warn('Cannot find feature node')
      return null
    }
    this._stack.length = 0
    this._stack.push(i.firstChild)
    const s = []
    const a = this.retrieveId(i)
    const n = {}
    while (this._stack.length) {
      i = this._stack.pop()
      if (i.nextSibling) this._stack.push(i.nextSibling)
      if (this.isGeometry(i)) {
        s.push(
          this._geometryParser.parseGeometryNode(i.firstElementChild, t, r)
        )
        continue
      } else if (this.isProperty(i)) {
        this.decodeProperty(i, n)
        continue
      }
      if (i.firstChild) this._stack.push(i.firstChild)
    }
    if (0 === s.length)
      Log.warn(
        'Could not decode geometry of feature with id: ' +
          a +
          '. ' +
          (this._failOnUnsupportedGeometry
            ? 'This is because it does not contain any geometry elements.'
            : "This is either because it does not contain any geometry elements, or because none of it's geometry components are part of the simple feature GML profile.") +
          ' This feature will contain an empty ShapeList instead.'
      )
    const o = isValidFeatureId(a) ? a : void 0
    const u =
      1 === s.length
        ? new Feature(s[0], n, o)
        : new Feature(createShapeList(t, s), n, o)
    if (!isValidFeatureId(u.id)) u.id = this._idGenerator(u)
    return u
  }
}
function nonText(e) {
  return !XML.isTextDOM(e)
}
