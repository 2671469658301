import { Shape } from '../../../../shape/Shape.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
import { isDefined } from '../../../../util/Lang.js'
import { ReferenceType } from '../../../../reference/ReferenceType.js'
import { GeoReference } from '../../../../reference/GeoReference.js'
import { HeightReferenceType } from '../../../../reference/HeightReference.js'
const zStyle2D = {
  drapeTarget: DrapeTarget.NOT_DRAPED,
  aboveGround: false,
  viewDisplacement: false,
  hasDepth: false,
  zToZero: false,
}
export function getZStyle(e, r, n, t, f) {
  if (!n) return zStyle2D
  else {
    let n
    if (e instanceof Shape) n = e.bounds
    const o = undefined
    const a = undefined
    return getZStyle3D(
      e,
      e.reference?.referenceType === ReferenceType.GEOCENTRIC ||
        ((n && (0 != n.depth || 0 != n.z)) ?? false),
      r.draped,
      r.drapeTarget,
      t,
      f
    )
  }
}
export function getZStyleForPoint(e, r, n, t, f) {
  if (!t) return zStyle2D
  else {
    const t = undefined
    const o = undefined
    return getZStyle3D(
      e,
      e.reference?.referenceType === ReferenceType.GEOCENTRIC || 0 != e.z,
      r,
      n,
      f,
      false
    )
  }
}
export function isAboveGroundReference(e) {
  return (
    e.reference instanceof GeoReference &&
    null !== e.reference.heightReference &&
    e.reference.heightReference.identifier === HeightReferenceType.ABOVE_TERRAIN
  )
}
function getZStyle3D(e, r, n, t, f, o) {
  let a
  if (o) {
    const e = undefined
    a = isDefined(t) && t !== DrapeTarget.NOT_DRAPED ? t : DrapeTarget.TERRAIN
  } else if (isDefined(t)) a = t
  else if (isDefined(n)) a = n ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
  else a = r ? DrapeTarget.NOT_DRAPED : DrapeTarget.TERRAIN
  const c = a !== DrapeTarget.NOT_DRAPED
  const i = undefined
  const s = undefined
  const p = undefined
  return {
    drapeTarget: a,
    aboveGround: c ? false : isAboveGroundReference(e) ? true : !r,
    viewDisplacement: c ? false : !r,
    hasDepth: r,
    zToZero:
      (c &&
        e.reference &&
        e.reference.referenceType !== ReferenceType.GEOCENTRIC &&
        (r ||
          (f instanceof GeoReference &&
            e.reference instanceof GeoReference &&
            !f.geodeticDatum.equals(e.reference.geodeticDatum)))) ??
      false,
  }
}
