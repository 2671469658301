import { Photon } from '../../gen/photon/photon_painter.js'
import { addHttpRequestOptions } from '../../util/HttpRequestOptions.js'
import { request } from '../../util/request.js'
import { URL as RIAUrl } from '../../util/URL.js'
export class PhotonDataLoader extends Photon.DataLoader {
  constructor(e, r, t, s, a) {
    super()
    this._webWorkerPool = r
    this._model = e
    this._imageDecoder = t
    this._textureDecoder = s
    this._textureCompressionOptions = a
    this._released = false
  }
  release() {
    this._released = true
    super.release()
  }
  requestAsBlob(e, r) {
    const t = addHttpRequestOptions({}, this._model)
    request(e, t)
      .then((e) => e.arrayBuffer())
      .then((t) => {
        const s = Photon.BufferFactory.createUint8BufferFromData(
          new Uint8Array(t)
        )
        try {
          if (!this._released) r.handleBlob(e, s)
        } finally {
          s.release()
          r.release()
        }
      })
      .catch((t) => {
        this.handleError(e, r, t)
      })
  }
  requestAsDecodedBlob(e, r) {
    this._webWorkerPool
      .enqueue(
        {
          command: 'xhrAndParse3DTileData',
          data: {
            url: e,
            credentials: this._model.credentials,
            requestHeaders: this._model.requestHeaders,
            options: this._textureCompressionOptions,
            urlParams: this._model.requestParameters
              ? RIAUrl.buildQueryString(this._model.requestParameters)
              : '',
          },
        },
        []
      )
      .then((t) => {
        if (t.error) {
          this.handleError(e, r, t.error)
          return
        }
        const s = Photon.BufferFactory.createUint8BufferFromData(
          new Uint8Array(t.arrayBuffer)
        )
        try {
          if (!this._released) r.handleDecodedBlob(e, s)
        } finally {
          s.release()
          r.release()
        }
      }, this.handleError.bind(this, e, r))
  }
  requestAsImage(e, r) {
    this._imageDecoder
      .decodeUrl(
        e,
        false,
        true,
        this._model.credentials,
        this._model.requestHeaders
      )
      .then((t) => {
        try {
          if (!this._released) r.handleImage(e, t)
        } finally {
          t.release()
          r.release()
        }
      }, this.handleError.bind(this, e, r))
  }
  requestAsTexture(e, r) {
    this._textureDecoder
      .decodeUrl(e, true, this._model.credentials, this._model.requestHeaders)
      .then((t) => {
        try {
          if (!this._released) r.handleTexture(e, t)
        } finally {
          t.release()
          r.release()
        }
      }, this.handleError.bind(this, e, r))
  }
  handleError(e, r, t) {
    try {
      if (!this._released)
        r.handleError(
          e,
          (t && t.message) || 'PhotonDataLoader: failed to fetch or decode data'
        )
    } finally {
      r.release()
    }
  }
}
