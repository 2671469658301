import { createPoint } from '../../../shape/ShapeFactory.js'
import { Constants } from '../../../util/Constants.js'
import {
  EVENT_HANDLED,
  EVENT_IGNORED,
  HandleEventResult,
  REQUEST_DEACTIVATION,
} from '../../controller/HandleEventResult.js'
import { GestureEventType } from '../../input/GestureEventType.js'
import { EditHandle } from '../EditHandle.js'
import * as SnapUtil from '../snapping/SnapUtil.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { viewToModel } from './HandleUtil.js'
export class PointListCreateHandle extends EditHandle {
  constructor(t, i) {
    super()
    this._pointList = t
    this._minimumPointCount = i?.minimumPointCount ?? 0
    this._maximumPointCount = i?.maximumPointCount ?? -1
    const n = 72 / 160
    this._panThreshold = 60 * n
    const e = 10 * n
    this._squaredMinimumDistance = e * e
    const s = 40 * n
    this._squaredMaximumDistance = s * s
    this._lastWorldPoint = null
    this._lastInsertedWorldPoint = null
    this._lastDX = 0
    this._lastDY = 0
    this._freehand = i?.freehand ?? true
    this._currentFreehand = null
    this._pointIndex = 0
    this._squaredPanThreshold = this._panThreshold * this._panThreshold
    this._tanMaxAngle = Math.tan(7.5 * Constants.DEG2RAD)
    this._snapPoint = null
    this._waitForSingleClickConfirmed = null
  }
  get pointList() {
    return this._pointList
  }
  get minimumPointCount() {
    return this._minimumPointCount
  }
  get maximumPointCount() {
    return this._maximumPointCount
  }
  get freehand() {
    return this._freehand
  }
  getCursor(t, i) {
    return 'crosshair'
  }
  onDraw(t, i) {
    SnapUtil.paintSnapIcon(t, this._snapPoint)
  }
  onGestureEvent(t, i) {
    const n = t.type
    if (this.shouldFinishWithDoubleClick(t, i)) {
      if (this.pointList.pointCount > 0) {
        this.pointList.removePoint(this.pointList.pointCount - 1)
        this.emitEditShapeEvent(this.pointList, EditShapeStatus.FINISHED)
      }
      return (
        HandleEventResult.EVENT_HANDLED |
        HandleEventResult.REQUEST_FINISH |
        HandleEventResult.REQUEST_DEACTIVATION
      )
    }
    if (null != this._waitForSingleClickConfirmed) {
      if (n === GestureEventType.SINGLE_CLICK_CONFIRMED) {
        clearTimeout(this._waitForSingleClickConfirmed)
        this._waitForSingleClickConfirmed = null
      }
      if (this._waitForSingleClickConfirmed)
        return HandleEventResult.EVENT_HANDLED
      else
        return (
          HandleEventResult.EVENT_HANDLED | HandleEventResult.REQUEST_FINISH
        )
    }
    const e = t.viewPosition
    if (n == GestureEventType.DOWN) {
      this._currentFreehand =
        this._freehand &&
        (null == this._lastWorldPoint ||
          this.isInsideSelectionArea(e[0], e[1], i, this._lastWorldPoint))
      return EVENT_IGNORED
    }
    const s = this._freehand && n === GestureEventType.DRAG
    const o = n === GestureEventType.SINGLE_CLICK_UP
    const r = n === GestureEventType.MOVE
    if (!(s || o || r)) return EVENT_IGNORED
    if (s && !this._currentFreehand) return EVENT_IGNORED
    const a = i.map
    const h = createPoint(null, [0, 0])
    const l = i.map.viewToMapTransformation
    const E = createPoint(a.reference, [0, 0, 0])
    const u = i.layer.model.reference
    let p = createPoint(u, [0, 0, 0])
    if (s)
      if (0 === this._pointIndex) {
        try {
          const n = t.downEvent.viewPosition
          h.move2D(n[0], n[1])
          l.transform(h, E)
          p = viewToModel(h, i, true)
          this.pointList.insertPoint(this._pointIndex, p)
          this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
          this._pointIndex++
        } catch (t) {
          return EVENT_HANDLED
        }
        try {
          p = createPoint(u, [0, 0, 0])
          h.move2D(e[0], e[1])
          l.transform(h, E)
          p = viewToModel(h, i, true)
          this._lastWorldPoint = E
          this._lastInsertedWorldPoint = E
          this.pointList.insertPoint(this._pointIndex, p)
          this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
          this._pointIndex++
        } catch (t) {
          return EVENT_HANDLED
        }
        return this.getHandleEventResult(i)
      } else {
        try {
          h.move2D(e[0], e[1])
          l.transform(h, E)
          p = viewToModel(h, i, true)
        } catch (t) {
          return EVENT_HANDLED
        }
        const t = createPoint(null, [0, 0])
        i.map.mapToViewTransformation.transform(this._lastInsertedWorldPoint, t)
        const n = Math.abs(e[0] - t.x)
        const s = Math.abs(e[1] - t.y)
        const o = n * n + s * s
        if (o < this._squaredMinimumDistance) {
          this._lastDX = n
          this._lastDY = s
          return EVENT_HANDLED
        }
        if (this._pointIndex === this.pointList.pointCount) {
          this._lastWorldPoint = E
          this.pointList.insertPoint(this._pointIndex, p)
          this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
          this._pointIndex++
          return this.getHandleEventResult(i)
        }
        const r = n * this._lastDY - this._lastDX * s
        const a = n * this._lastDX + s * this._lastDY
        const u = undefined
        if (
          (a < 1e-7 ? Number.POSITIVE_INFINITY : Math.abs(r / a)) >
          this._tanMaxAngle
        ) {
          this._lastInsertedWorldPoint = this._lastWorldPoint
          this._lastDX = n
          this._lastDY = s
          this.pointList.insertPoint(this._pointIndex, p)
          this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
          this._pointIndex++
          this._lastWorldPoint = E
          return this.getHandleEventResult(i)
        }
        this.pointList.move2DPoint(this._pointIndex, p.x, p.y)
        this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
        this._lastWorldPoint = E
        if (o > this._squaredMaximumDistance) {
          this._lastInsertedWorldPoint = E
          return EVENT_HANDLED
        } else return EVENT_HANDLED
      }
    else if (o) {
      try {
        h.move2D(e[0], e[1])
        l.transform(h, E)
        p = viewToModel(h, i, true)
      } catch (t) {
        return EVENT_HANDLED
      }
      this._lastWorldPoint = E
      this._lastInsertedWorldPoint = E
      this.moveToClosestSnapPointIfAny(t, i, p)
      if (0 == this.pointList.pointCount) {
        this.pointList.insertPoint(0, p)
        this.pointList.insertPoint(1, p.copy())
        this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
        this._pointIndex++
      } else {
        this.pointList.removePoint(this.pointList.pointCount - 1)
        this.pointList.insertPoint(this.pointList.pointCount, p)
        this.pointList.insertPoint(this.pointList.pointCount, p.copy())
        this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
        this._pointIndex++
      }
      return this.getHandleEventResult(i)
    } else if (r && this._pointIndex > 0) {
      try {
        h.move2D(e[0], e[1])
        l.transform(h, E)
        p = viewToModel(h, i, true)
      } catch (t) {
        return EVENT_HANDLED
      }
      this.moveToClosestSnapPointIfAny(t, i, p)
      this.pointList.move2DPoint(this.pointList.pointCount - 1, p.x, p.y)
      return EVENT_HANDLED
    }
    return EVENT_IGNORED
  }
  moveToClosestSnapPointIfAny(t, i, n) {
    const e = SnapUtil.findClosestPoint(
      t,
      this.pointList.getPoint(this._pointIndex),
      this.pointList,
      i.map
    )
    if (null !== e) {
      n.move2D(e)
      this._snapPoint = e.copy()
    } else this._snapPoint = null
  }
  shouldFinishWithDoubleClick(t, i) {
    const n = this.pointList.pointCount > this.minimumPointCount
    return t.type === GestureEventType.DOUBLE_CLICK && n
  }
  getHandleEventResult(t) {
    const i = this.maximumPointCount
    if (-1 != i && this.pointList.pointCount > i) {
      this.pointList.removePoint(this.pointList.pointCount - 1)
      if (this._currentFreehand)
        return (
          HandleEventResult.EVENT_HANDLED | HandleEventResult.REQUEST_FINISH
        )
      this._waitForSingleClickConfirmed = setTimeout(() => {
        this._waitForSingleClickConfirmed = null
      }, 310)
      return EVENT_HANDLED
    } else return EVENT_HANDLED | REQUEST_DEACTIVATION
  }
  isInsideSelectionArea(t, i, n, e) {
    let s
    if (null != e) {
      let o
      try {
        o = n.map.mapToViewTransformation.transform(e)
        const r = o.x
        const a = o.y
        s = this.isBelowPanTreshold(t, i, r, a)
      } catch (t) {
        s = false
      }
    } else s = true
    return s
  }
  isBelowPanTreshold(t, i, n, e) {
    const s = Math.abs(t - n)
    const o = Math.abs(i - e)
    const r = undefined
    return s * s + o * o < this._squaredPanThreshold
  }
}
