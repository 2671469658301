import { TileRecordType } from './TileRecordType.js'
import { hasPath } from './TileUtil.js'
export class EmptyTileRecord {
  constructor(e) {
    this._coordinate = e
    this._entireAreaCovered = false
    this._leaf = false
    this._fallBack = false
  }
  get coordinate() {
    return this._coordinate
  }
  get baseTileCoordinate() {
    return this._coordinate
  }
  get entireAreaCovered() {
    return this._entireAreaCovered
  }
  get fallback() {
    return this._fallBack
  }
  set fallback(e) {
    this._fallBack = e
  }
  deriveTile(e, t, r) {
    const l = this._coordinate
    if (e.level === l.level)
      if (e.x === l.x && e.y === l.y) {
        const t = new EmptyTileRecord(e)
        t.leaf = this.leaf
        return t
      } else return null
    if (!hasPath(l, e)) return null
    const i = new EmptyTileRecord(e)
    i.leaf = this.leaf
    return i
  }
  draw(e, t) {}
  get leaf() {
    return this._leaf
  }
  set leaf(e) {
    this._leaf = e
  }
  get proxy() {
    return !this.leaf
  }
  get type() {
    return TileRecordType.EMPTY
  }
}
