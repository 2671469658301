import { Constants } from '../util/Constants.js'
import {
  containsAngle,
  containsAnglePragmatic,
  ellipticalDistance2D,
} from '../util/Cartesian.js'
export function calculateBounds(t, n) {
  const o = t.a
  const s = t.b
  const c = t.startPoint
  const a = t.endPoint
  const e = 90 - t.rotationAzimuth
  const i = t.startAzimuth
  const r = t.sweepAngle
  n.move2DToPoint(c)
  n.setToIncludePoint2D(a)
  const h = Constants.DEG2RAD * e
  const l = Math.cos(h)
  const D = Math.sin(h)
  const u = o * l
  const M = o * D
  const m = s * l
  const A = s * D
  const g = Math.atan2(-s * D, o * l)
  const P = Math.atan2(s * l, o * D)
  const f = g + Math.PI
  const p = P + Math.PI
  const C = t.center.copy()
  I(g, g)
  I(f, f)
  I(P, P)
  I(p, p)
  const d = 1e-8
  n.translate2D(-d, -d)
  n.width = n.width + 2 * d
  n.height = n.height + 2 * d
  function I(o, s) {
    const c = Math.sin(o)
    const a = Math.cos(s)
    const e = u * a - A * c
    const h = M * a + m * c
    const l = Math.atan2(h, e) * Constants.RAD2DEG
    if (containsAngle(i, r, 90 - l)) {
      C.move2D(t.center.x + e, t.center.y + h)
      n.setToIncludePoint2D(C)
    }
  }
}
export function arcContains2D(t, n, o, s, c, a, e, i, r) {
  const h = i - t
  const l = r - n
  const D = Math.atan2(l, h) * Constants.RAD2DEG
  const u = 90 - c
  if (!containsAnglePragmatic(a, e, 90 - D)) return false
  const M = ellipticalDistance2D(o, s, u - D)
  return Math.abs(Math.sqrt(h * h + l * l) - M) <= 1e-8 + 1e-10 * M
}
