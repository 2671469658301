import { ShapeList } from '../shape/ShapeList.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { CGSetOperation } from './constructivegeometry/CGSetOperation.js'
export class ConstructiveGeometry {
  constructor(e) {
    this._cgShapeShape = e
  }
  union(e) {
    return this._doBoolean(CGSetOperation.UNION, e)
  }
  intersection(e) {
    return this._doBoolean(CGSetOperation.INTERSECTION, e)
  }
  difference(e) {
    return this._doBoolean(CGSetOperation.DIFFERENCE, e)
  }
  symmetricDifference(e) {
    return this._doBoolean(CGSetOperation.SYMMETRIC_DIFFERENCE, e)
  }
  _doBoolean(e, r) {
    if (r.length < 2)
      throw new ProgrammingError(
        'Need at least two input shapes for boolean operation.'
      )
    if (!this._cgShapeShape.supportsShapes(r))
      throw new ProgrammingError(
        'Operation currently not supported for the provided shapes'
      )
    let o = this._cgShapeShape.operateOnMultipleShapes(e, r)
    if (!o.reference) o = new ShapeList(r[0].reference, [])
    return o
  }
}
