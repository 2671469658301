import { clipLineWithRectangle } from '../../util/Cartesian.js'
const EPSILON = 0.01
const clipOut = {}
export class ClipPolylineUtil {
  get paths() {
    return this._paths
  }
  get pathData() {
    return this._pathData
  }
  constructor() {
    this._paths = [[]]
    this._pathData = { length: 0, coordsPerPoint: 2, pathLengths: [] }
    this._left = 1 / 0
    this._right = -1 / 0
    this._bottom = 1 / 0
    this._top = -1 / 0
    this._lastX = 0
    this._lastY = 0
    this._requestedMoveTo = false
    this._createNewSubPath = false
    this._indexSubPath = 0
    this.rewind()
    this.clearPath()
  }
  rewind() {
    this._pathData.length = 0
    this._indexSubPath = -1
    this._createNewSubPath = false
  }
  clearPath() {
    this._lastX = 0
    this._lastY = 0
    this._requestedMoveTo = true
  }
  setClippingBounds(t) {
    this.setClippingBoundsXWidthYHeight(t.x, t.width, t.y, t.height)
  }
  setClippingBoundsXWidthYHeight(t, i, h, s) {
    this._left = t
    this._bottom = h
    this._top = this._bottom + s
    this._right = this._left + i
  }
  rewindAndClipCoordinates(t, i, h, s, e, a) {
    this.rewind()
    this.setClippingBoundsXWidthYHeight(h, s, e, a)
    this.moveTo(t[0], t[1])
    let _
    for (_ = 2; _ < i; _ += 2) this.lineTo(t[_], t[_ + 1])
  }
  _addPoint(t, i) {
    if (this._createNewSubPath) {
      if ('undefined' === typeof this._paths[this._indexSubPath])
        this._paths[this._indexSubPath] = []
      this._pathData.length += 1
      this._pathData.pathLengths[this._indexSubPath] = 0
      this._createNewSubPath = false
    }
    const h = this._pathData.pathLengths[this._indexSubPath]
    this._paths[this._indexSubPath][h] = t
    this._paths[this._indexSubPath][h + 1] = i
    this._pathData.pathLengths[this._indexSubPath] += 2
  }
  _moveTo(t, i) {
    this._indexSubPath += 1
    this._createNewSubPath = true
    this._addPoint(t, i)
  }
  _lineTo(t, i) {
    this._addPoint(t, i)
  }
  moveTo(t, i, h) {
    this._lastX = t
    this._lastY = i
    this._requestedMoveTo = true
  }
  lineTo(t, i, h) {
    this._addLine(this._lastX, this._lastY, t, i)
    this._lastX = t
    this._lastY = i
  }
  _addLine(t, i, h, s) {
    const e = undefined
    if (
      clipLineWithRectangle(
        t,
        i,
        h,
        s,
        this._left,
        this._bottom,
        this._right,
        this._top,
        clipOut
      )
    ) {
      if (this._requestedMoveTo) {
        this._moveTo(clipOut.clippedStartX, clipOut.clippedStartY)
        this._requestedMoveTo = false
      } else if (
        !(
          Math.abs(t - clipOut.clippedStartX) < EPSILON &&
          Math.abs(i - clipOut.clippedStartY) < EPSILON
        )
      )
        this._moveTo(clipOut.clippedStartX, clipOut.clippedStartY)
      this._lineTo(clipOut.clippedEndX, clipOut.clippedEndY)
    }
  }
}
