import { ProgrammingError } from '../error/ProgrammingError.js'
import { PolygonOrientation } from '../shape/PolygonOrientation.js'
import { simplePointMove2D } from '../shape/SimplePoint.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { containsAngle } from '../util/Cartesian.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { forwardAzimuth2D, forwardAzimuth2DLL } from './AzimuthUtil.js'
import { geodesicArea as externalGeodesicArea } from './GeodesicAreaUtil.js'
const d90InRad = Math.PI / 2
function sign(t) {
  return t >= 0 ? 1 : -1
}
const fTemp4XYPointArray1 = [
  new XYZPoint(),
  new XYZPoint(),
  new XYZPoint(),
  new XYZPoint(),
]
const fTemp4XYPointArray2 = [
  new XYZPoint(),
  new XYZPoint(),
  new XYZPoint(),
  new XYZPoint(),
]
export function greatCircleDistance(t, n) {
  const o = ((n.x - t.x) * Constants.DEG2RAD) / 2
  const s = ((n.y - t.y) * Constants.DEG2RAD) / 2
  const e = Math.sin(o)
  const i = Math.sin(s)
  const a = Math.cos(t.y * Constants.DEG2RAD)
  const r = Math.cos(n.y * Constants.DEG2RAD)
  const c = Math.sqrt(i * i + a * r * e * e)
  return 2 * Constants.RAD2DEG * Math.asin(c)
}
export function greatCircleDistanceLL(t, n, o, s, e, i) {
  const a = ((s - t) * Constants.DEG2RAD) / 2
  const r = ((e - n) * Constants.DEG2RAD) / 2
  const c = Math.sin(a)
  const D = Math.sin(r)
  const l = Math.sqrt(D * D + o * i * c * c)
  return 2 * Constants.RAD2DEG * Math.asin(l)
}
export function closestPointOnGeodesic(t, n, o, s, e) {
  if (o.x === t.x && o.y === t.y) {
    e.move2DToCoordinates(t.x, t.y)
    return 0
  }
  if (o.x === n.x && o.y === n.y) {
    e.move2DToCoordinates(n.x, n.y)
    return 0
  }
  const i = (n.x - t.x) * Constants.DEG2RAD
  const a = (o.x - n.x) * Constants.DEG2RAD
  const r = (o.x - t.x) * Constants.DEG2RAD
  const c = Math.sin(t.y * Constants.DEG2RAD)
  const D = Math.cos(t.y * Constants.DEG2RAD)
  const l = Math.sin(n.y * Constants.DEG2RAD)
  const f = Math.cos(n.y * Constants.DEG2RAD)
  const u = Math.sin(o.y * Constants.DEG2RAD)
  const h = Math.cos(o.y * Constants.DEG2RAD)
  const M = Math.cos(i)
  let m = c * l + D * f * M
  let C = l * u + f * h * Math.cos(a)
  let A = c * u + D * h * Math.cos(r)
  m = m > 1 ? 1 : m < -1 ? -1 : m
  C = C > 1 ? 1 : C < -1 ? -1 : C
  A = A > 1 ? 1 : A < -1 ? -1 : A
  const y = Math.acos(m)
  const d = Math.acos(C)
  const P = Math.acos(A)
  const E = 1e-10
  if (P < E) {
    e.move2DToCoordinates(t.x, t.y)
    return P
  }
  if (d < E) {
    e.move2DToCoordinates(n.x, n.y)
    return d
  }
  let x = (A - C * m) / (Math.sin(d) * Math.sin(y))
  x = x > 1 ? 1 : x < -1 ? -1 : x
  const p = Math.acos(x)
  const g = Math.sin(d) * Math.sin(p)
  const G = Math.asin(g)
  let R = Math.cos(G)
  const L = Math.acos(C / R)
  let T = (C - A * m) / (Math.sin(P) * Math.sin(y))
  T = T > 1 ? 1 : T < -1 ? -1 : T
  const Y = Math.acos(T)
  R = Math.cos(Math.asin(Math.sin(P) * Math.sin(Y)))
  const v = Math.acos(A / R)
  if (Math.abs(y - v - L) <= 1e-10) {
    if (v > L) {
      const n = Math.atan2(c * M - D * (l / f), Math.sin(i))
      let o = Math.PI / 2 + n
      if (o < 0) o += 2 * Math.PI
      o *= Constants.RAD2DEG
      const s = undefined
      greatCirclePointSFCT(t, v * Constants.RAD2DEG, o, e)
    } else {
      const t = Math.atan2(l * M - f * (c / D), Math.sin(-i))
      let o = Math.PI / 2 + t
      if (o < 0) o += 2 * Math.PI
      o *= Constants.RAD2DEG
      const s = undefined
      greatCirclePointSFCT(n, L * Constants.RAD2DEG, o, e)
    }
    return Constants.RAD2DEG * G
  } else if (s)
    if (L <= v) {
      e.move2DToCoordinates(n.x, n.y)
      return d * Constants.RAD2DEG
    } else {
      e.move2DToCoordinates(t.x, t.y)
      return P * Constants.RAD2DEG
    }
  else {
    if (L <= v) {
      const n = Math.atan2(c * M - D * (l / f), Math.sin(i))
      let o = Math.PI / 2 + n
      if (o < 0) o += 2 * Math.PI
      o *= Constants.RAD2DEG
      const s = undefined
      greatCirclePointSFCT(t, (L + y) * Constants.RAD2DEG, o, e)
    } else {
      const t = Math.atan2(l * M - f * (c / D), Math.sin(-i))
      let o = Math.PI / 2 + t
      if (o < 0) o += 2 * Math.PI
      o *= Constants.RAD2DEG
      const s = undefined
      greatCirclePointSFCT(n, (v + y) * Constants.RAD2DEG, o, e)
    }
    return Constants.RAD2DEG * G
  }
}
export function orientation2D(t) {
  const n = undefined
  return externalGeodesicArea(t, null) > 0
    ? PolygonOrientation.COUNTER_CLOCKWISE
    : PolygonOrientation.CLOCKWISE
}
export function greatCirclePointSFCT(t, n, o, s) {
  const e = o * Constants.DEG2RAD
  const i = n * Constants.DEG2RAD
  const a = Math.sin(i)
  const r = Math.cos(i)
  const c = Math.cos(t.y * Constants.DEG2RAD)
  const D = Math.sin(t.y * Constants.DEG2RAD)
  const l = Math.cos(e) * c * a + r * D
  const f = Math.asin(l)
  const u = Math.sin(e) * a
  const h = (r - D * l) / c
  const M = Math.atan2(u, h) * Constants.RAD2DEG
  s.x = normalizeLon(t.x + M)
  s.y = f * Constants.RAD2DEG
}
export function greatCirclePointAtFractionSFCT(t, n, o, s) {
  let e
  let i
  if (Math.abs(o) < 1e-12) {
    i = t.y
    e = t.x
    simplePointMove2D(s, e, i)
  } else if (Math.abs(o - 1) < 1e-12) {
    i = n.y
    e = n.x
    simplePointMove2D(s, e, i)
  } else {
    const e = undefined
    const i = undefined
    greatCirclePointSFCT(
      t,
      o * greatCircleDistance(t, n),
      forwardAzimuth2D(t, n) * Constants.RAD2DEG,
      s
    )
  }
  return s
}
export function rhumblinePointSFCT(t, n, o, s) {
  const e = o * Constants.DEG2RAD
  if (0 === n) {
    s.x = t.x
    s.y = t.y
  } else {
    const o = Constants.DEG2RAD * t.x
    const i = Constants.DEG2RAD * t.y
    const a = n * Constants.DEG2RAD
    let r = i + a * Math.cos(e)
    if (Math.abs(r) > d90InRad) r = d90InRad * sign(r)
    const c = Math.log(
      Math.tan(r / 2 + Math.PI / 4) / Math.tan(i / 2 + Math.PI / 4)
    )
    let D
    if (Math.abs(i - r) < 1e-6) D = Math.cos(i)
    else D = (r - i) / c
    const l = undefined
    const f = ((o + (a * Math.sin(e)) / D + Math.PI) % (2 * Math.PI)) - Math.PI
    s.x = Constants.RAD2DEG * f
    s.y = Constants.RAD2DEG * r
  }
}
export function contains2D(t, n, o) {
  const s = greatCircleDistance(t, n),
    e = forwardAzimuth2D(t, n),
    i = greatCircleDistance(t, o),
    a = forwardAzimuth2D(t, o),
    r = Math.abs(a - e)
  return ((r < 1e-8 || Math.abs(r - 2 * Math.PI) < 1e-8) && i <= s) || i < 1e-10
}
export function complexPolygonContains2D(t, n) {
  const o = t.bounds
  if (null == o || !o.contains2DPoint(n)) return false
  const s = n.x
  const e = n.y
  const i = s * Constants.DEG2RAD
  const a = e * Constants.DEG2RAD
  const r = Math.sin(i)
  const c = Math.cos(i)
  const D = Math.sin(a)
  const l = Math.cos(a)
  const f = undefined
  let u = ringContains2D(t.getPolygon(0), s, r, c, e, D, l)
  let h
  const M = t.polygonCount
  let m
  if (u && M > 1)
    for (h = 1; h < M; h++) {
      m = ringContains2D(t.getPolygon(h), s, r, c, e, D, l)
      u = m ? !u : u
    }
  return u
}
export function polygonContains2D(t, n, o) {
  const s = n * Constants.DEG2RAD
  const e = o * Constants.DEG2RAD
  const i = undefined
  const a = undefined
  const r = undefined
  const c = undefined
  return ringContains2D(
    t,
    n,
    Math.sin(s),
    Math.cos(s),
    o,
    Math.sin(e),
    Math.cos(e)
  )
}
function ringContains2D(t, n, o, s, e, i, a) {
  const r = t.pointCount
  let c = 0
  const D = 1e-10
  const l = []
  let f = t.getSimplePoint(0)
  for (let o = 1; o <= r; o++) {
    const s = t.getSimplePoint(o % r)
    if (isInsideInterval(n, f.x, s.x, 1e-10)) {
      const t = Math.abs(n - f.x)
      if (Math.abs(f.x - s.x) < D && t < D) if (e < f.y != e < s.y) return true
      const o = Math.abs(e - f.y)
      if (Math.abs(f.y) < D && Math.abs(f.y - s.y) < D && o < D) return true
      if (t < D && o < D) return true
      l.push(f)
      l.push(s)
    }
    c += f.y
    f = s
  }
  const u = l.length / 2
  if (0 == u) return false
  c /= r
  let h = 0
  let M = 0
  let m = 0
  while (m < u && l[2 * m].x === n) m++
  if (m >= u) m = 0
  const C = c < 0
  for (let t = 0; t < u; t++) {
    const t = l[2 * m]
    const r = l[2 * m + 1]
    const c = r.x - t.x
    if (0 != c && 180 != c && -180 != c) {
      const c = 1e-15
      let D = false
      if (Math.abs(r.x - n) < c) {
        const n = undefined
        if (r.y >= e === C) {
          M = t.x
          D = true
        }
      }
      if (Math.abs(t.x - n) < c) {
        const o = undefined
        if (t.y >= e === C && M > n != r.x > n) h += increasing(t.x, r.x)
        D = true
      }
      if (!D) {
        const c = t.x
        const D = t.y
        const l = Math.sin(t.x * Constants.DEG2RAD)
        const f = Math.cos(t.x * Constants.DEG2RAD)
        const u = Math.sin(t.y * Constants.DEG2RAD)
        const M = Math.cos(t.y * Constants.DEG2RAD)
        const m = r.x
        const A = r.y
        const y = undefined
        const d = undefined
        const P = undefined
        const E = undefined
        h += meridionalIntersectionCount(
          c,
          l,
          f,
          D,
          u,
          M,
          m,
          Math.sin(r.x * Constants.DEG2RAD),
          Math.cos(r.x * Constants.DEG2RAD),
          A,
          Math.sin(r.y * Constants.DEG2RAD),
          Math.cos(r.y * Constants.DEG2RAD),
          n,
          o,
          s,
          e,
          i,
          a,
          C
        )
      }
    }
    m = (m + 1) % u
  }
  return h % 2 !== 0
}
function increasing(t, n) {
  return (t + 360) % 360 < (n + 360) % 360 ? 1 : -1
}
export function meridionalIntersects(
  t,
  n,
  o,
  s,
  e,
  i,
  a,
  r,
  c,
  D,
  l,
  f,
  u,
  h,
  M,
  m,
  C,
  A,
  y
) {
  return (
    0 !=
    meridionalIntersectionCount(
      t,
      n,
      o,
      s,
      e,
      i,
      a,
      r,
      c,
      D,
      l,
      f,
      u,
      h,
      M,
      m,
      C,
      A,
      y
    )
  )
}
function meridionalIntersectionCount(
  t,
  n,
  o,
  s,
  e,
  i,
  a,
  r,
  c,
  D,
  l,
  f,
  u,
  h,
  M,
  m,
  C,
  A,
  y
) {
  const d = normalizeLon(a - t)
  u = normalizeLon(u)
  if (containsAngle(t, d, u))
    if (0 === s && 0 === D) return m <= 0 == y ? increasing(t, a) : 0
    else {
      const s = i * o
      const D = i * n
      const u = f * c
      const m = f * r
      const d = undefined
      const P = undefined
      const E = undefined
      const x = A * M
      const p = A * h
      const g = -x
      const G =
        (-(D * u - s * m) * g) / ((D * l - m * e) * g - -(s * l - u * e) * p)
      const R = p / g
      const L = Math.sqrt(1 / (G * G * (1 + R * R) + 1))
      const T = -G * L
      const Y = -R * T
      if (T * x >= 0 && Y * p >= 0) return L >= C == y ? increasing(t, a) : 0
      else if (T * x <= 0 && Y * p <= 0)
        return -L >= C == y ? increasing(t, a) : 0
    }
  return 0
}
export function rhumblineAzimuth2D(t, n) {
  const o = undefined
  return rhumbLineDistanceAndAzimuth(t, n)[1]
}
export function rhumblineDistance(t, n) {
  const o = undefined
  return rhumbLineDistanceAndAzimuth(t, n)[0]
}
export function rhumbLineDistanceAndAzimuth(t, n) {
  const o = t.x * Constants.DEG2RAD
  const s = t.y * Constants.DEG2RAD
  const e = n.x * Constants.DEG2RAD
  const i = n.y * Constants.DEG2RAD
  const a = (o - e) % (2 * Math.PI)
  const r = (e - o) % (2 * Math.PI)
  const c = Math.log(
    Math.tan(i / 2 + Math.PI / 4) / Math.tan(s / 2 + Math.PI / 4)
  )
  let D
  const l = i - s
  if (Math.abs(l) < 1e-6) D = Math.cos(s)
  else D = l / c
  let f, u
  if (a < r) {
    f = Math.sqrt(D * D * a * a + l * l)
    u = Math.atan2(-a, c) % (2 * Math.PI)
  } else {
    f = Math.sqrt(D * D * r * r + l * l)
    u = Math.atan2(r, c) % (2 * Math.PI)
  }
  return [f * Constants.RAD2DEG, u]
}
function bufferContour2DOfSegmentSFCT(t, n, o, s) {
  const e = forwardAzimuth2D(t, n) * Constants.RAD2DEG
  greatCirclePointSFCT(t, o, e + 90, s[0])
  greatCirclePointSFCT(t, o, e - 90, s[1])
  const i = forwardAzimuth2D(n, t) * Constants.RAD2DEG
  greatCirclePointSFCT(n, o, i + 90, s[2])
  greatCirclePointSFCT(n, o, i - 90, s[3])
}
function allOnEquator(t, n, o, s) {
  const e = 1e-10
  return (
    Math.abs(t) < e && Math.abs(n) < e && Math.abs(o) < e && Math.abs(s) < e
  )
}
function allOnMeridian(t, n, o, s) {
  const e = 1e-10
  const i = Math.abs(t - n)
  const a = Math.abs(o - s)
  const r = Math.abs(n - o)
  const c = Math.abs(t - s)
  return i < e && a < e && r < e && c < e
}
function isEqual(t, n, o, s) {
  const e = 1e-10
  return Math.abs(o - t) < e && Math.abs(s - n) < e
}
function intersection2DLineSegmentsHelper(
  t,
  n,
  o,
  s,
  e,
  i,
  a,
  r,
  c,
  D,
  l,
  f,
  u,
  h,
  M,
  m,
  C,
  A,
  y,
  d,
  P,
  E,
  x,
  p,
  g,
  G,
  R,
  L,
  T
) {
  const Y = forwardAzimuth2DLL(t, e, i, a, l, f)
  const v = greatCircleDistanceLL(t, n, i, a, r, f)
  const b = forwardAzimuth2DLL(u, C, A, y, x, p)
  const X = greatCircleDistanceLL(u, h, A, y, d, p)
  const I = i * s
  const S = i * o
  const q = e
  const z = f * D
  const w = f * c
  const F = l
  const O = A * m
  const Z = A * M
  const j = C
  const U = p * E
  const H = p * P
  const K = x
  let N = S * l - w * e
  let W = Z * x - H * C
  let $ = -(I * l - z * e)
  let _ = -(O * x - U * C)
  let k = -(S * z - I * w)
  let B = -(Z * U - O * H)
  const J = 1 / Math.sqrt(N * N + $ * $ + k * k)
  const Q = 1 / Math.sqrt(W * W + _ * _ + B * B)
  N *= J
  $ *= J
  k *= J
  W *= Q
  _ *= Q
  B *= Q
  if (N * W < 0 || $ * _ < 0 || k * B < 0) {
    W = -W
    _ = -_
    B = -B
  }
  const V = 1e-8
  if (
    allOnEquator(n, r, h, d) ||
    allOnMeridian(t, a, u, y) ||
    (Math.abs(W - N) < V && Math.abs(_ - $) < V && Math.abs(B - k) < V)
  ) {
    if (!g && !G) return -1
    const o = 1e-13
    const s = forwardAzimuth2DLL(t, e, i, u, C, A)
    const c = greatCircleDistanceLL(t, n, i, u, h, A)
    const D = Math.abs(s - Y) < 1e-8 && c < v + o
    const M = forwardAzimuth2DLL(t, e, i, y, x, p)
    const m = greatCircleDistanceLL(t, n, i, y, d, p)
    const P = Math.abs(M - Y) < 1e-8 && m < v + o
    if (D && P) {
      if (null !== R) R.move2D(u, h)
      if (null !== L) L.move2D(y, d)
      if (isEqual(u, h, y, d)) return 1
      return -1
    }
    const E = forwardAzimuth2DLL(u, C, A, t, e, i)
    const T = c
    const I = Math.abs(E - b) < 1e-8 && T < X + o
    if (D && I) {
      if (null !== R) R.move2D(t, n)
      if (null !== L) L.move2D(u, h)
      if (isEqual(t, n, u, h)) return 1
      return -1
    }
    if (P && I) {
      if (null !== R) R.move2D(t, n)
      if (null !== L) L.move2D(y, d)
      if (isEqual(t, n, y, d)) return 1
      return -1
    }
    const S = forwardAzimuth2DLL(u, C, A, a, l, f)
    const q = greatCircleDistanceLL(u, h, A, a, r, f)
    const z = Math.abs(S - b) < 1e-8 && q < X + o
    if (z && I) {
      if (null !== R) R.move2D(t, n)
      if (null !== L) L.move2D(a, r)
      if (isEqual(t, n, a, r)) return 1
      return -1
    }
    if (z && D) {
      if (null !== R) R.move2D(a, r)
      if (null !== L) L.move2D(u, h)
      if (isEqual(a, r, u, h)) return 1
      return -1
    }
    if (z && P) {
      if (null !== R) R.move2D(a, r)
      if (null !== L) L.move2D(y, d)
      if (isEqual(a, r, y, d)) return 1
      return -1
    }
    if (I) {
      if (null !== R) R.move2D(t, n)
      return 1
    }
    if (z) {
      if (null !== R) R.move2D(a, r)
      return 1
    }
    if (D) {
      if (null !== R) R.move2D(u, h)
      return 1
    }
    if (P) {
      if (null !== R) R.move2D(y, d)
      return 1
    }
    return 0
  }
  const tt = k * _ - $ * B
  const nt = k * W - N * B
  const ot = N * _ - $ * W
  const st = 1 / Math.sqrt(tt * tt + nt * nt + ot * ot)
  const et = tt * st
  const it = -nt * st
  const at = -ot * st
  const rt = Math.sqrt(et * et + it * it)
  const ct = Math.atan2(it, et)
  const Dt = Math.atan(at / rt)
  const lt = ct * Constants.RAD2DEG
  const ft = Dt * Constants.RAD2DEG
  const ut = Math.cos(Dt)
  let ht = greatCircleDistanceLL(t, n, i, lt, ft, ut)
  const Mt = greatCircleDistanceLL(a, r, f, lt, ft, ut)
  const mt = greatCircleDistanceLL(u, h, A, lt, ft, ut)
  const Ct = undefined
  const At = 1e-8
  const yt = ht + Mt < v + At
  const dt = mt + greatCircleDistanceLL(y, d, p, lt, ft, ut) < X + At
  if (g && yt && dt) {
    if (isEqual(t, n, lt, ft)) {
      if (null !== R) R.move2D(t, n)
      ht = 0
    } else if (isEqual(a, r, lt, ft)) {
      if (null !== R) R.move2D(a, r)
      ht = v
    } else if (isEqual(u, h, lt, ft)) {
      if (null !== R) R.move2D(u, h)
    } else if (isEqual(y, d, lt, ft)) {
      if (null !== R) R.move2D(y, d)
    } else if (null !== R) R.move2D(lt, ft)
    if (null !== T) T[0] = ht / v
    return 1
  }
  const Pt = Math.atan2(-it, -et)
  const Et = Math.atan(-at / rt)
  const xt = Pt * Constants.RAD2DEG
  const pt = Et * Constants.RAD2DEG
  const gt = Math.cos(Et)
  let Gt = greatCircleDistanceLL(t, n, i, xt, pt, gt)
  const Rt = greatCircleDistanceLL(a, r, f, xt, pt, gt)
  const Lt = greatCircleDistanceLL(u, h, A, xt, pt, gt)
  const Tt = undefined
  const Yt = Gt + Rt < v + At
  const vt = Lt + greatCircleDistanceLL(y, d, p, xt, pt, gt) < X + At
  if (g && Yt && vt) {
    if (isEqual(t, n, xt, pt)) {
      if (null !== R) R.move2D(t, n)
      Gt = 0
    } else if (isEqual(a, r, xt, pt)) {
      if (null !== R) R.move2D(a, r)
      Gt = v
    } else if (isEqual(u, h, xt, pt)) {
      if (null !== R) R.move2D(u, h)
    } else if (isEqual(y, d, xt, pt)) {
      if (null !== R) R.move2D(y, d)
    } else if (null !== R) R.move2D(xt, pt)
    if (null !== T) T[0] = Gt / v
    return 1
  }
  if (!g) {
    if (G) {
      if (yt || dt) {
        if (null !== R) R.move2D(lt, ft)
        return 1
      }
      if (Yt || vt) {
        if (null !== R) R.move2D(xt, pt)
        return 1
      }
      let t = Math.min(ht, mt)
      let n = Math.min(Gt, Lt)
      t = Math.min(t, greatCircleDistanceLL(a, r, f, lt, ft, ut))
      t = Math.min(t, greatCircleDistanceLL(y, d, p, lt, ft, ut))
      n = Math.min(n, greatCircleDistanceLL(a, r, f, xt, pt, gt))
      n = Math.min(n, greatCircleDistanceLL(y, d, p, xt, pt, gt))
      if (Math.abs(n - t) > 1e-8)
        if (t < n) {
          if (null !== R) R.move2D(lt, ft)
          return 1
        } else {
          if (null !== R) R.move2D(xt, pt)
          return 1
        }
    }
    if (null !== R) R.move2D(lt, ft)
    if (null !== L) L.move2D(xt, pt)
    return 2
  }
  return 0
}
function intersection2DLineSegments(t, n, o, s, e, i, a, r, c) {
  const D = t.x
  const l = t.y
  const f = n.x
  const u = n.y
  const h = o.x
  const M = o.y
  const m = s.x
  const C = s.y
  const A = cos(l)
  const y = sin(l)
  const d = cos(D)
  const P = sin(D)
  const E = cos(u)
  const x = sin(u)
  const p = cos(f)
  const g = sin(f)
  const G = cos(M)
  const R = sin(M)
  const L = cos(h)
  const T = sin(h)
  const Y = cos(C)
  const v = sin(C)
  const b = cos(m)
  const X = undefined
  return intersection2DLineSegmentsHelper(
    D,
    l,
    P,
    d,
    y,
    A,
    f,
    u,
    g,
    p,
    x,
    E,
    h,
    M,
    T,
    L,
    R,
    G,
    m,
    C,
    sin(m),
    b,
    v,
    Y,
    e,
    i,
    a,
    r,
    c
  )
}
function cos(t) {
  return Math.cos(t * Constants.DEG2RAD)
}
function sin(t) {
  return Math.sin(t * Constants.DEG2RAD)
}
export function intersection2DLSSFCT(t, n, o, s, e) {
  const i = intersection2DLineSegments(t, n, o, s, false, true, e, null, null)
  if (1 != i)
    throw new ProgrammingError(
      `Number of intersection points should be 1 instead of ${i}`
    )
}
export function bufferContour2DOf2DPolyline(t, n, o) {
  const s = t.pointCount
  const e = 2 * s
  bufferContour2DOfSegmentSFCT(
    t.getPoint(0),
    t.getPoint(1),
    n,
    fTemp4XYPointArray2
  )
  o[0].move2D(fTemp4XYPointArray2[1].x, fTemp4XYPointArray2[1].y)
  o[e - 1].move2D(fTemp4XYPointArray2[0].x, fTemp4XYPointArray2[0].y)
  for (let i = 1; i < s - 1; i++) {
    fTemp4XYPointArray1[0].move2D(
      fTemp4XYPointArray2[0].x,
      fTemp4XYPointArray2[0].y
    )
    fTemp4XYPointArray1[1].move2D(
      fTemp4XYPointArray2[1].x,
      fTemp4XYPointArray2[1].y
    )
    fTemp4XYPointArray1[2].move2D(
      fTemp4XYPointArray2[2].x,
      fTemp4XYPointArray2[2].y
    )
    fTemp4XYPointArray1[3].move2D(
      fTemp4XYPointArray2[3].x,
      fTemp4XYPointArray2[3].y
    )
    bufferContour2DOfSegmentSFCT(
      t.getPoint(i),
      t.getPoint(i + 1),
      n,
      fTemp4XYPointArray2
    )
    try {
      intersection2DLSSFCT(
        fTemp4XYPointArray1[1],
        fTemp4XYPointArray1[2],
        fTemp4XYPointArray2[1],
        fTemp4XYPointArray2[2],
        o[i]
      )
    } catch (t) {
      o[i].move2D(fTemp4XYPointArray1[2].x, fTemp4XYPointArray1[2].y)
    }
    try {
      intersection2DLSSFCT(
        fTemp4XYPointArray1[0],
        fTemp4XYPointArray1[3],
        fTemp4XYPointArray2[0],
        fTemp4XYPointArray2[3],
        o[e - i - 1]
      )
    } catch (t) {
      o[e - i - 1].move2D(fTemp4XYPointArray1[3].x, fTemp4XYPointArray1[3].y)
    }
  }
  o[s - 1].move2D(fTemp4XYPointArray2[2].x, fTemp4XYPointArray2[2].y)
  o[s].move2D(fTemp4XYPointArray2[3].x, fTemp4XYPointArray2[3].y)
}
function sinY(t) {
  return Math.sin(t.y * Constants.DEG2RAD)
}
function cosY(t) {
  return Math.cos(t.y * Constants.DEG2RAD)
}
export function distanceToGeodesic(t, n, o, s) {
  const e = (n.x - t.x) * Constants.DEG2RAD
  const i = (o.x - n.x) * Constants.DEG2RAD
  const a = (o.x - t.x) * Constants.DEG2RAD
  let r = sinY(t) * sinY(n) + cosY(t) * cosY(n) * Math.cos(e)
  let c = sinY(n) * sinY(o) + cosY(n) * cosY(o) * Math.cos(i)
  const D = sinY(t) * sinY(o) + cosY(t) * cosY(o) * Math.cos(a)
  r = r > 1 ? 1 : r < -1 ? -1 : r
  c = c > 1 ? 1 : c < -1 ? -1 : c
  const l = Math.acos(r)
  const f = Math.acos(c)
  let u = (D - c * r) / (Math.sin(f) * Math.sin(l))
  u = u > 1 ? 1 : u < -1 ? -1 : u
  const h = Math.acos(u)
  let M = (Math.sin(f) * Math.sin(h)) / Math.sin(s * Constants.DEG2RAD)
  M = M > 1 ? 1 : M < -1 ? -1 : M
  return Constants.RAD2DEG * Math.asin(M)
}
export function isInsideInterval(t, n, o, s) {
  t = normalizeLon(t)
  n = normalizeLon(n)
  if ((o = normalizeLon(o)) - n > 180) {
    n += 360
    if (t < 0) t += 360
  } else if (n - o > 180) {
    o += 360
    if (t < 0) t += 360
  }
  let e = n
  let i = o
  if (n > o) {
    i = n
    e = o
  }
  return t > e - s && t < i + s
}
export function geodesicArea(t, n) {
  return n * n * externalGeodesicArea(t, null)
}
