export let ProjectionType = (function (I) {
  I[(I['ALBERS_EQUAL_AREA_CONIC'] = 1)] = 'ALBERS_EQUAL_AREA_CONIC'
  I[(I['AZIMUTHAL'] = 2)] = 'AZIMUTHAL'
  I[(I['AZIMUTHAL_EQUIDISTANT'] = 4)] = 'AZIMUTHAL_EQUIDISTANT'
  I[(I['CASSINI'] = 8)] = 'CASSINI'
  I[(I['CONIC'] = 16)] = 'CONIC'
  I[(I['CYLINDRICAL'] = 32)] = 'CYLINDRICAL'
  I[(I['DUTCH_STEREOGRAPHIC'] = 64)] = 'DUTCH_STEREOGRAPHIC'
  I[(I['ECKERT_IV'] = 128)] = 'ECKERT_IV'
  I[(I['ECKERT_VI'] = 256)] = 'ECKERT_VI'
  I[(I['ELLIPSOIDAL_EQUIDISTANT_CYLINDRICAL'] = 512)] =
    'ELLIPSOIDAL_EQUIDISTANT_CYLINDRICAL'
  I[(I['EQUIDISTANT_CYLINDRICAL'] = 1024)] = 'EQUIDISTANT_CYLINDRICAL'
  I[(I['GEODETIC'] = 2048)] = 'GEODETIC'
  I[(I['GNOMONIC'] = 4096)] = 'GNOMONIC'
  I[(I['LAMBERT_AZIMUTHAL_EQUAL_AREA'] = 8192)] = 'LAMBERT_AZIMUTHAL_EQUAL_AREA'
  I[(I['LAMBERT_CONFORMAL'] = 16384)] = 'LAMBERT_CONFORMAL'
  I[(I['MERCATOR'] = 32768)] = 'MERCATOR'
  I[(I['MILLER_CYLINDRICAL'] = 65536)] = 'MILLER_CYLINDRICAL'
  I[(I['MOLLWEIDE'] = 131072)] = 'MOLLWEIDE'
  I[(I['OBLIQUECYLINDRICAL'] = 262144)] = 'OBLIQUECYLINDRICAL'
  I[(I['OBLIQUE_MERCATOR'] = 524288)] = 'OBLIQUE_MERCATOR'
  I[(I['ORTHOGRAPHIC'] = 1048576)] = 'ORTHOGRAPHIC'
  I[(I['POLAR_STEREOGRAPHIC'] = 2097152)] = 'POLAR_STEREOGRAPHIC'
  I[(I['POLYCONIC'] = 4194304)] = 'POLYCONIC'
  I[(I['PSEUDO_MERCATOR'] = 8388608)] = 'PSEUDO_MERCATOR'
  I[(I['STEREOGRAPHIC'] = 16777216)] = 'STEREOGRAPHIC'
  I[(I['SWISS_OBLIQUE_MERCATOR'] = 33554432)] = 'SWISS_OBLIQUE_MERCATOR'
  I[(I['TRANSVERSECYLINDRICAL'] = 67108864)] = 'TRANSVERSECYLINDRICAL'
  I[(I['TRANSVERSE_MERCATOR'] = 134217728)] = 'TRANSVERSE_MERCATOR'
  I[(I['CASSINI_SOLDNER'] = 268435456)] = 'CASSINI_SOLDNER'
  I[(I['KROVAK'] = 536870912)] = 'KROVAK'
  return I
})({})
