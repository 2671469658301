import { GridArcPath } from './GridArcPath.js'
import { GridLinePath } from './GridLinePath.js'
import { IterativePen } from './IterativePen.js'
export class CartesianPen extends IterativePen {
  constructor() {
    super()
    this._linePath = new GridLinePath()
    this._arcPath = new GridArcPath()
  }
  getLinePath(t, i, r, e) {
    this._linePath.initializePath(t, i)
    return this._linePath
  }
  getArcPath(t, i, r, e, a, h, n, P) {
    this._arcPath.initializeArc(t, i, r, e, a, h, n)
    return this._arcPath
  }
}
