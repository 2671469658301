import { XYZBounds } from '../../shape/XYZBounds.js'
import { CommandUtil } from './CommandUtil.js'
import { CoordinateArrayUtil } from './CoordinateArrayUtil.js'
import { appendPathSFCT } from './HTML5CanvasUtil.js'
import { HTML5DrawFillCommandProto } from './HTML5DrawFillCommandProto.js'
import { ringContainsXY2DFlatCoordList } from '../../util/Cartesian.js'
import {
  DEFAULT_NORMALIZED_OFFSETS,
  getPerpendicularOffsetInPixels,
  saveAndOffsetContext,
} from './OffsetsUtil.js'
const DEFAULT_FILL_STYLE = { color: 'rgb(255,255,255)' }
const tempBounds = new XYZBounds()
export class HTML5DrawFillCommandMulti extends HTML5DrawFillCommandProto {
  constructor(t, o, r, s, i, n) {
    super(t, o, n, s ?? DEFAULT_FILL_STYLE, i)
    let e
    this._normalizedOffsets = s
      ? s.normalizedOffsets
      : DEFAULT_NORMALIZED_OFFSETS
    this._coordinates = r
    this._coordinatesArray = new Array(2)
    if (this._coordinates) {
      for (let t = 0, o = this._coordinates.length; t < o; t++) {
        CoordinateArrayUtil.calculateBoundsMultiSFCT(
          this._coordinates[t],
          tempBounds
        )
        if (0 === t) e = tempBounds.copy()
        else CommandUtil.expandBoundsSFCT(tempBounds, e)
      }
      this.bounds = e
    }
  }
  draw(t, o, r) {
    let s, i, n, e
    const a = this._coordinates.length
    saveAndOffsetContext(t, this._normalizedOffsets, r)
    const l = getPerpendicularOffsetInPixels(
      this.fillStyle.normalizedPerpendicularOffset,
      r
    )
    t.beginPath()
    for (s = 0; s < a; s++) {
      e = this._coordinates[s]
      i = 0
      n = e.length
      while (i < n) {
        appendPathSFCT(e[i], t, o, this._coordinatesArray, l)
        t.closePath()
        i += 1
      }
    }
    t.restore()
    this._fill(t, this._coordinatesArray[0], this._coordinatesArray[1], r)
  }
  interacts(t, o, r) {
    if (!this.hasFill()) return false
    let s = false
    const i = t.focusPoint
    let n, e
    for (n = 0; n < this._coordinates.length; n++)
      if (ringContainsXY2DFlatCoordList(this._coordinates[n][0], i.x, i.y)) {
        s = true
        for (e = 1; e < this._coordinates[n].length; e++)
          if (ringContainsXY2DFlatCoordList(this._coordinates[n][e], i.x, i.y))
            s = !s
        if (s) return true
      }
    return false
  }
  strokeInteracts(t, o) {
    return false
  }
  mapAnchorPointSFCT(t, o) {
    return CoordinateArrayUtil.mapAnchorPointSFCT(this._coordinates[0], true, o)
  }
  mapAnchorPointsSFCT(t, o, r) {
    t.getViewBounds(tempBounds)
    CoordinateArrayUtil.mapAnchorPointsSFCT(
      this._coordinates[0],
      this.bounds,
      tempBounds,
      t.mapToViewTransformationInternal,
      r,
      o
    )
  }
}
