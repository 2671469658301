import { Matrix4 } from './math/Matrix4.js'
import { Vector3 } from './math/Vector3.js'
import { isNumber } from '../../util/Lang.js'
let uniqueId = 0
function addChildToParent(o, e, r, n) {
  let t = r
  let s
  if (n && n.length && 16 === n.length) {
    s = new Matrix4()
    s.fromArray(n)
  } else s = new Matrix4().identity()
  const i = e.positions
  const a = e.indices
  for (let e = 0; e < i.length; e += 3) {
    const r = new Vector3(i[e], i[e + 1], i[e + 2])
    r.applyMatrix4(s)
    o.positions.push(r.x)
    o.positions.push(r.y)
    o.positions.push(r.z)
  }
  if (!a) throw new Error('Indices is mandatory')
  a.forEach((e, n, s) => {
    const i = r
    if (e + i > t) t = e + i
    o.indices.push(e + i)
  })
  if (e.texCoords) {
    if (e.texCoords.length / 2 !== i.length / 3)
      throw new Error(
        'Positions (X,Y,Z) and texcoords (U,V) should be of equal length'
      )
    if (!o.texCoords) o.texCoords = []
    for (let r = 0; r < e.texCoords.length; r += 2) {
      o.texCoords.push(e.texCoords[r])
      o.texCoords.push(e.texCoords[r + 1])
      o.texCoords.push(0)
    }
  } else if (o.texCoords)
    o.texCoords = o.texCoords.concat(
      Array.apply(null, new Array(i.length)).map(
        Number.prototype.valueOf,
        Number.NaN
      )
    )
  if (e.colors) {
    if (e.colors.length / 4 !== i.length / 3)
      throw new Error(
        'Positions (X,Y,Z) and colors (R,G,B,A) should be of equal length'
      )
    o.colors = o.colors ? o.colors.concat(e.colors) : [...e.colors]
  } else if (o.colors)
    o.colors = o.colors.concat(
      Array.apply(null, new Array((i.length / 3) * 4)).map(
        Number.prototype.valueOf,
        Number.NaN
      )
    )
  if (e.normals) {
    if (e.normals.length !== i.length)
      throw new Error(
        'Positions (X,Y,Z) and normals (X,Y,Z) should be of equal length'
      )
    o.normals = o.normals ? o.normals.concat(e.normals) : [...e.normals]
  }
  return t
}
const getMaxIndex = (o) => {
  let e = 0
  for (const r of o) if (r > e) e = r
  return e
}
const generateUniqueId = () => {
  const o = `MeshFactory_mesh_${uniqueId}`
  uniqueId++
  return o
}
function create3DMesh(o, e, r) {
  const n = { id: generateUniqueId(), positions: [], indices: [], images: [] }
  if (!o || !o.length)
    throw new Error(
      'Positions argument is mandatory and must be an array of Numbers.'
    )
  if (!(o.length % 3 === 0))
    throw new Error('Positions must be a set of (X,Y,Z) coordinates.')
  n.positions = o
  if (!e) throw new Error('Indices is mandatory')
  n.indices = e
  if (r) {
    if (r.texCoords) {
      if (r.texCoords.length / 2 !== o.length / 3)
        throw new Error(
          'Positions (X,Y,Z) and texcoords (U,V) should be of equal length'
        )
      if (!r.image)
        throw new Error(
          'texCoords was given, but no image. 3D Mesh can not be created.'
        )
      if (isNumber(r.image))
        throw new Error(
          'The given image for the mesh created by MeshFactory must not be a number'
        )
      if (n.images) n.images.push(r.image)
      const e = 0
      let t = 0
      n.texCoords = new Array(n.positions.length)
      for (let o = 0; o < r.texCoords.length; o += 2) {
        n.texCoords[t] = r.texCoords[o]
        n.texCoords[t + 1] = r.texCoords[o + 1]
        n.texCoords[t + 2] = e
        t += 3
      }
    }
    if (r.colors) {
      if (r.colors.length / 4 !== o.length / 3)
        throw new Error(
          'Positions (X,Y,Z) and colors (R,G,B,A) should be of equivalent length. Each (X,Y,Z) coordinate should match exactly one (R,G,B,A) pair.'
        )
      n.colors = r.colors
    }
    if (r.normals) {
      if (r.normals.length !== o.length)
        throw new Error(
          'Positions (X,Y,Z) and normals (X,Y,Z) should be of equivalent length. Each (X,Y,Z) coordinate should match exactly one (X,Y,Z) normal.'
        )
      n.normals = r.normals
    }
  }
  return n
}
export const createHierarchicalMesh = (o, e) => {
  if (!o || !o.node)
    throw new Error('Parent should be given to construct hierarchical mesh')
  if (!e)
    throw new Error('Children should be given to construct hierarchical mesh')
  let r = getMaxIndex(o.node.indices ? o.node.indices : []) + 1
  const n = generateUniqueId()
  const t = { id: '', indices: [], positions: [] }
  t.id = n
  t.positions = o.node.positions ? [...o.node.positions] : []
  t.indices = o.node.indices ? [...o.node.indices] : []
  if (o.node.texCoords) t.texCoords = [...o.node.texCoords]
  if (o.node.images) t.images = [...o.node.images]
  if (o.node.colors) t.colors = [...o.node.colors]
  if (o.node.normals) t.normals = [...o.node.normals]
  for (const o of e) {
    if (!o.node)
      throw new Error(
        'Found child element without node. All children in the createHierarchicalMesh() array should contain at least a node reference.'
      )
    r = addChildToParent(t, o.node, r, o.transformation) + 1
  }
  return t
}
export { create3DMesh }
