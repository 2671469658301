import { Constants } from '../util/Constants.js'
import { ShapeType } from '../shape/ShapeType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import {
  closestPointOnGeodesic as closestPointOnGeodesicSphere,
  geodesicArea as geodesicAreaSphere,
} from './SphereUtil.js'
import { closestPointOnGeodesic, geodesicArea } from './EllipsoidUtil.js'
import { GeodeticGeodesy } from './GeodeticGeodesy.js'
import { LineType } from './LineType.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { distance3D as cartesianDistance3D } from '../util/Cartesian.js'
export class EllipsoidalGeodesy extends GeodeticGeodesy {
  constructor(e) {
    super(e)
    this._EPSILON = 0.01
  }
  distanceImpl(e, t, i, s) {
    switch (s) {
      default:
      case LineType.SHORTEST_DISTANCE:
        return e.geodesicDistance(t, i)
      case LineType.CONSTANT_BEARING:
        return e.rhumblineDistance(t, i)
    }
  }
  forwardAzimuthImpl(e, t, i, s) {
    switch (s) {
      default:
      case LineType.SHORTEST_DISTANCE:
        return Constants.RAD2DEG * e.forwardAzimuth2D(t, i)
      case LineType.CONSTANT_BEARING:
        return e.rhumblineAzimuth2D(t, i)
    }
  }
  interpolateDistanceAzimuthImpl(e, t, i, s, o, n) {
    switch (o) {
      default:
      case LineType.SHORTEST_DISTANCE:
        e.geodesicPositionSFCT(t, i, s, n)
        break
      case LineType.CONSTANT_BEARING:
        e.rhumblinePositionSFCT(t, i, s, n)
        break
    }
    return n
  }
  interpolateFractionImpl(e, t, i, s, o, n) {
    switch (o) {
      default:
      case LineType.SHORTEST_DISTANCE:
        e.geodesicPositionAtFractionSFCT(t, i, s, n)
        break
      case LineType.CONSTANT_BEARING: {
        const o = e.rhumblineAzimuth2D(t, i)
        const r = e.rhumblineDistance(t, i)
        e.rhumblinePositionSFCT(t, s * r, o, n)
        break
      }
    }
    return n
  }
  shortestDistanceToLine(e, t, i, s, o) {
    if (s && s.clipToSegment)
      return closestPointOnGeodesic(t, i, e, this.ellipsoid, 1e-10, 0.01, o)
    else {
      closestPointOnGeodesicSphere(t, i, e, false, o)
      return this.ellipsoid.geodesicDistance(e, o)
    }
  }
  calculateDistance3D(e, t, i, s, o, n, r) {
    const a = this.interpolate(t, i, 0.5, e)
    const c = createPoint(null, [])
    this.ellipsoid.geod2geocSFCT(a, c)
    const l = cartesianDistance3D(s, c)
    const p = cartesianDistance3D(c, o)
    const h = l + p
    const d = h - n
    if (Math.abs(d) < r) return h
    else {
      const n = undefined
      const h = undefined
      return (
        this.calculateDistance3D(e, t, a, s, c, l, 0.5 * r) +
        this.calculateDistance3D(e, a, i, c, o, p, 0.5 * r)
      )
    }
  }
  distance3D(e, t, i) {
    const s = this.refToCalcRef(e)
    const o = this.calcRefToGeodetic(s)
    const n = this.refToCalcRef(t)
    const r = this.calcRefToGeodetic(n)
    if (Math.abs(o.z) < 1e-8 && Math.abs(r.z) < 1e-8)
      return this.distanceImpl(this.ellipsoid, o, r, i)
    const a = createPoint(null, [])
    const c = createPoint(null, [])
    this.ellipsoid.geod2geocSFCT(o, a)
    this.ellipsoid.geod2geocSFCT(r, c)
    const l = cartesianDistance3D(a, c)
    const p = Math.max(0.001, 1e-6 * l)
    return this.calculateDistance3D(i, o, r, a, c, l, p)
  }
  area(e) {
    if (!ShapeType.contains(e.type, ShapeType.POLYGON))
      throw new ProgrammingError(
        'Only the calculation of the area of a polygon is supported at the moment'
      )
    if (this.ellipsoid.isSphere())
      return Math.abs(geodesicAreaSphere(e, this.ellipsoid.a))
    else return Math.abs(geodesicArea(e, this.ellipsoid))
  }
}
