import { isNumber } from '../../../../util/Lang.js'
import { Filled } from './util/Filled.js'
import { Length } from './util/Length.js'
import { Stroked } from './util/Stroked.js'
import { PatternType } from './util/PatternType.js'
import { Pattern } from '../Pattern.js'
import { Constants } from '../../../../util/Constants.js'
import { clamp } from '../../../../util/Cartesian.js'
const DEFAULT_FIXED_LINE_LENGTH = 10
const DEFAULT_RELATIVE_LINE_LENGTH = 1
const DEBUGGING = false
export class LinePattern extends Pattern {
  _lineJoin = null
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    let e
    super((arguments.length > 1 ? arguments[1] : void 0) ?? PatternType.LINE)
    const i = isNumber(t.length)
      ? t.length
      : t.relative
      ? DEFAULT_RELATIVE_LINE_LENGTH
      : DEFAULT_FIXED_LINE_LENGTH
    const s = t.relative ? 0 : i
    const o = t.relative ? i : 0
    this._length = new Length({ fixedLength: s, relativeLength: o })
    this._stroked = new Stroked(t.line)
    this._filled = new Filled(t.fill)
    this._o1 = isNumber(t.offset0) ? t.offset0 : 0
    this._o2 = isNumber(t.offset1) ? t.offset1 : 0
    if (!isNumber(this._o1) || isNaN(this._o1))
      throw new Error('Invalid value for LinePattern offset0: ' + this._o1)
    if (!isNumber(this._o2) || isNaN(this._o2))
      throw new Error('Invalid value for LinePattern offset1: ' + this._o2)
    this.flexible = this._o1 === this._o2
    this.canBend = this.flexible
  }
  get lineJoin() {
    return this._lineJoin
  }
  set lineJoin(t) {
    this._lineJoin = t
  }
  get worldSizeContext() {
    return this._length.worldSizeContext
  }
  set worldSizeContext(t) {
    this._length.worldSizeContext = t
    this._stroked.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._length.getUomSizeToPixels(t)
  }
  getWidth(t) {
    return this._length.getWidth(t)
  }
  isRelativeLength() {
    return this._length.isRelativeLength()
  }
  get fixedLength() {
    return this._length.fixedLength
  }
  set fixedLength(t) {
    this._length.fixedLength = t
  }
  get relativeLength() {
    return this._length.relativeLength
  }
  set relativeLength(t) {
    this._length.relativeLength = t
  }
  strokePath(t) {
    this._stroked.strokePath(t)
  }
  get lineColor() {
    return this._stroked.lineColor
  }
  set lineColor(t) {
    this._stroked.lineColor = t
  }
  get lineWidth() {
    return this._stroked.lineWidth
  }
  set lineWidth(t) {
    this._stroked.lineWidth = t
  }
  get o1() {
    return this._o1
  }
  get o2() {
    return this._o2
  }
  get dashed() {
    return this._stroked.dashed
  }
  set dashed(t) {
    this._stroked.dashed = t
  }
  get parallelOffset() {
    return this._o1
  }
  fillPath(t) {
    this._filled.fillPath(t)
  }
  get fillColor() {
    return this._filled.fillColor
  }
  set fillColor(t) {
    this._filled.fillColor = t
  }
  getMinHeight() {
    const t = Math.min(this._o1, this._o2) - this.lineWidth / 2
    return this.getUomSizeToPixels(t)
  }
  getMaxHeight() {
    const t = Math.max(this._o1, this._o2) + this.lineWidth / 2
    return this.getUomSizeToPixels(t)
  }
  paint(t, e, i, s) {
    const o = s || this.getUomSizeToPixels(this.getWidth(e))
    if (!this.lineColor && !this.fillColor) return
    t.scale(1, -1)
    const l = this.getUomSizeToPixels(this._o1)
    const n = this.getUomSizeToPixels(this._o2)
    if (this.fillColor) {
      t.beginPath()
      t.moveTo(0, l)
      t.lineTo(o, n)
      t.lineTo(o, 0)
      t.lineTo(0, 0)
      this.fillPath(t)
    }
    if (this.lineColor) {
      t.beginPath()
      t.moveTo(0, l)
      t.lineTo(o, n)
      this.strokePath(t)
    }
    t.scale(1, -1)
  }
  paintFlexible(t, e, i) {
    if (0 === this.parallelOffset && this.lineWidth < 3) this.paint(t, i, 0, i)
    else {
      let s = this.getUomSizeToPixels(this.parallelOffset)
      if (e.needToInvertOffset()) s *= -1
      const o = e.bisector()
      const l = e.bisector(i)
      this.paintOuterBisector(t, 0, 0, o, true)
      this.paintOuterBisector(t, i, 0, l, false)
      t.save()
      const n = this.createClip(t, 0, i, s, o, l)
      this.paintFromTo(t, n.minX, n.maxX, s, i)
      t.restore()
    }
  }
  createClip(t, e, i, s, o, l) {
    const n = 0.5
    e -= n
    i += n
    const h = this.getUomSizeToPixels(this.lineWidth)
    let r = s + h / 2
    let a = s - h / 2
    let c = true
    let f = true
    if (this.fillColor) {
      if (s > 0) {
        a = 0
        c = false
      }
      if (s < 0) {
        r = 0
        f = false
      }
    }
    r += n
    a -= n
    const d = e + a / Math.tan(o)
    const g = e + s / Math.tan(o)
    const T = e + r / Math.tan(o)
    const _ = i + a / Math.tan(l)
    const u = i + s / Math.tan(l)
    const m = i + r / Math.tan(l)
    const M = Math.min(d, T)
    const p = Math.max(_, m)
    t.scale(1, -1)
    t.beginPath()
    t.moveTo(g, s)
    if (T === M && f)
      switch (this.lineJoin) {
        case 'miter':
          t.lineTo(T, r)
          break
        case 'bevel': {
          const e = o - Math.PI / 2
          const i = h / 2
          t.lineTo(
            g + i * Math.cos(e) * Math.cos(o),
            s + i * Math.cos(e) * Math.sin(o)
          )
          t.lineTo(g, r)
          break
        }
        case 'round':
        default:
          t.lineTo(g + (h / 2) * Math.cos(o), s + (h / 2) * Math.sin(o))
          t.ellipse(g, s, h / 2, h / 2, 0, o, Math.PI / 2, true)
      }
    else t.lineTo(T, r)
    if (m === p && f)
      switch (this.lineJoin) {
        case 'miter':
          t.lineTo(m, r)
          break
        case 'bevel': {
          const e = l - Math.PI / 2
          const i = h / 2
          t.lineTo(u, r)
          t.lineTo(
            u + i * Math.cos(e) * Math.cos(l),
            s + i * Math.cos(e) * Math.sin(l)
          )
          break
        }
        case 'round':
        default:
          t.lineTo(u, r)
          t.ellipse(u, s, h / 2, h / 2, 0, Math.PI / 2, l, true)
      }
    else t.lineTo(m, r)
    t.lineTo(u, s)
    if (_ === p && c)
      switch (this.lineJoin) {
        case 'miter':
          t.lineTo(_, a)
          break
        case 'bevel': {
          const e = l - Math.PI / 2
          const i = -h / 2
          t.lineTo(
            u + i * Math.cos(e) * Math.cos(l),
            s + i * Math.cos(e) * Math.sin(l)
          )
          t.lineTo(u, a)
          break
        }
        case 'round':
        default:
          t.lineTo(
            u + (h / 2) * Math.cos(l + Math.PI),
            s + (h / 2) * Math.sin(l + Math.PI)
          )
          t.ellipse(u, s, h / 2, h / 2, 0, l + Math.PI, (3 * Math.PI) / 2, true)
      }
    else t.lineTo(_, a)
    if (d === M && c)
      switch (this.lineJoin) {
        case 'miter':
          t.lineTo(d, a)
          break
        case 'bevel': {
          const e = o - Math.PI / 2
          const i = -h / 2
          t.lineTo(g, a)
          t.lineTo(
            g + i * Math.cos(e) * Math.cos(o),
            s + i * Math.cos(e) * Math.sin(o)
          )
          break
        }
        case 'round':
        default:
          t.lineTo(g, a)
          t.ellipse(g, s, h / 2, h / 2, 0, (3 * Math.PI) / 2, o + Math.PI, true)
      }
    else t.lineTo(d, a)
    t.lineTo(g, s)
    if (DEBUGGING) {
      t.lineWidth = 0.1
      t.strokeStyle = this.fillColor ?? this.lineColor
      t.stroke()
    }
    t.clip()
    t.scale(1, -1)
    return {
      minX: clamp(M, e - 2 * (Math.abs(s) + h), e),
      maxX: clamp(p, i, i + 2 * (Math.abs(s) + h)),
    }
  }
  paintFromTo(t, e, i, s, o) {
    if (!this.lineColor && !this.fillColor) return
    t.scale(1, -1)
    if (this.fillColor) {
      t.beginPath()
      t.moveTo(e, s)
      t.lineTo(i, s)
      t.lineTo(o, 0)
      t.lineTo(0, 0)
      this.fillPath(t)
    }
    if (this.lineColor) {
      t.beginPath()
      t.moveTo(e, s)
      t.lineTo(i, s)
      this.strokePath(t)
    }
    t.scale(1, -1)
  }
  paintOuterBisector(t, e, i, s, o) {
    if (!DEBUGGING) return
    t.scale(1, -1)
    const l = o ? 40 : 60
    const n = e
    const h = i
    const r = n + l * Math.cos(s)
    const a = h + l * Math.sin(s)
    t.beginPath()
    t.moveTo(n, h)
    t.lineTo(r, a)
    t.lineWidth = 3
    t.strokeStyle = o ? 'rgb(255,0,0)' : 'rgb(0,0,255)'
    t.stroke()
    t.scale(1, -1)
    t.lineWidth = 1
    t.strokeText('' + Math.round(s * Constants.RAD2DEG), r, -a)
  }
  appendHash(t) {
    this._length.appendHash(t)
    this._stroked.appendHash(t)
    this._filled.appendHash(t)
    t.appendDouble(this._o1)
    t.appendDouble(this._o2)
  }
}
