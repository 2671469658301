import { normalizeLon } from '../util/LonLatCoord.js'
const MAX_LATITUDE_DIFF = [[], [], [], []]
MAX_LATITUDE_DIFF[0][0] = 0
MAX_LATITUDE_DIFF[0][1] = 0.0012039724981455892
MAX_LATITUDE_DIFF[0][2] = 0.004816072748760548
MAX_LATITUDE_DIFF[0][3] = 0.010836849025874073
MAX_LATITUDE_DIFF[0][4] = 0.019267215137803363
MAX_LATITUDE_DIFF[0][5] = 0.03010845045421249
MAX_LATITUDE_DIFF[0][6] = 0.04336219994356654
MAX_LATITUDE_DIFF[0][7] = 0.059030474220160306
MAX_LATITUDE_DIFF[0][8] = 0.0771156496002135
MAX_LATITUDE_DIFF[0][9] = 0.09762046816607112
MAX_LATITUDE_DIFF[0][10] = 0.12054803783735225
MAX_LATITUDE_DIFF[0][11] = 0.14590183244797647
MAX_LATITUDE_DIFF[0][12] = 0.17368569182759047
MAX_LATITUDE_DIFF[0][13] = 0.20390382188578543
MAX_LATITUDE_DIFF[0][14] = 0.23656079469744712
MAX_LATITUDE_DIFF[0][15] = 0.27166154858723884
MAX_LATITUDE_DIFF[0][16] = 0.30921138821113775
MAX_LATITUDE_DIFF[0][17] = 0.34921598463279657
MAX_LATITUDE_DIFF[0][18] = 0.39168137539207426
MAX_LATITUDE_DIFF[0][19] = 0.4366139645632494
MAX_LATITUDE_DIFF[0][20] = 0.48402052279990715
MAX_LATITUDE_DIFF[0][21] = 0.5339081873634755
MAX_LATITUDE_DIFF[0][22] = 0.5862844621321195
MAX_LATITUDE_DIFF[0][23] = 0.6411572175864385
MAX_LATITUDE_DIFF[0][24] = 0.6985346907682997
MAX_LATITUDE_DIFF[0][25] = 0.7584254852088045
MAX_LATITUDE_DIFF[0][26] = 0.8208385708212589
MAX_LATITUDE_DIFF[0][27] = 0.8857832837546306
MAX_LATITUDE_DIFF[0][28] = 0.9533047344668703
MAX_LATITUDE_DIFF[0][29] = 1.023384578340525
MAX_LATITUDE_DIFF[0][30] = 1.0960335572420874
MAX_LATITUDE_DIFF[0][31] = 1.1712630988265227
MAX_LATITUDE_DIFF[0][32] = 1.2490850214473106
MAX_LATITUDE_DIFF[0][33] = 1.3295115341302788
MAX_LATITUDE_DIFF[0][34] = 1.4125552364845313
MAX_LATITUDE_DIFF[0][35] = 1.498229118543783
MAX_LATITUDE_DIFF[0][36] = 1.586546560530797
MAX_LATITUDE_DIFF[0][37] = 1.6775213325377318
MAX_LATITUDE_DIFF[0][38] = 1.7711675941145546
MAX_LATITUDE_DIFF[0][39] = 1.86749989375718
MAX_LATITUDE_DIFF[0][40] = 1.966533168287032
MAX_LATITUDE_DIFF[0][41] = 2.0682827421128853
MAX_LATITUDE_DIFF[0][42] = 2.172764326365804
MAX_LATITUDE_DIFF[0][43] = 2.2799940178972355
MAX_LATITUDE_DIFF[0][44] = 2.389988298130298
MAX_LATITUDE_DIFF[0][45] = 2.5027640317533124
MAX_LATITUDE_DIFF[0][46] = 2.6183384652447987
MAX_LATITUDE_DIFF[0][47] = 2.7367292252180415
MAX_LATITUDE_DIFF[0][48] = 2.8579543165735197
MAX_LATITUDE_DIFF[0][49] = 2.982032120446175
MAX_LATITUDE_DIFF[0][50] = 3.1090288979954352
MAX_LATITUDE_DIFF[0][51] = 3.2391045702452606
MAX_LATITUDE_DIFF[0][52] = 3.3721150076077677
MAX_LATITUDE_DIFF[0][53] = 3.508081402446036
MAX_LATITUDE_DIFF[0][54] = 3.6470253503240264
MAX_LATITUDE_DIFF[0][55] = 3.78896884725672
MAX_LATITUDE_DIFF[0][56] = 3.933934286660352
MAX_LATITUDE_DIFF[0][57] = 4.081944455983993
MAX_LATITUDE_DIFF[0][58] = 4.233022533003925
MAX_LATITUDE_DIFF[0][59] = 4.387192081761035
MAX_LATITUDE_DIFF[0][60] = 4.544477048121002
MAX_LATITUDE_DIFF[0][61] = 4.704901754936005
MAX_LATITUDE_DIFF[0][62] = 4.868490896786103
MAX_LATITUDE_DIFF[0][63] = 5.03526953427789
MAX_LATITUDE_DIFF[0][64] = 5.20526308787651
MAX_LATITUDE_DIFF[0][65] = 5.378802460063625
MAX_LATITUDE_DIFF[0][66] = 5.555859771610304
MAX_LATITUDE_DIFF[0][67] = 5.736256400033403
MAX_LATITUDE_DIFF[0][68] = 5.9200211974079116
MAX_LATITUDE_DIFF[0][69] = 6.107183394008015
MAX_LATITUDE_DIFF[0][70] = 6.297772589771898
MAX_LATITUDE_DIFF[0][71] = 6.491818745096428
MAX_LATITUDE_DIFF[0][72] = 6.6893521709284425
MAX_LATITUDE_DIFF[0][73] = 6.890403518118124
MAX_LATITUDE_DIFF[0][74] = 7.09500376599927
MAX_LATITUDE_DIFF[0][75] = 7.3031842101598645
MAX_LATITUDE_DIFF[0][76] = 7.515157304566284
MAX_LATITUDE_DIFF[0][77] = 7.731528860962651
MAX_LATITUDE_DIFF[0][78] = 7.951645303972199
MAX_LATITUDE_DIFF[0][79] = 8.175541873285937
MAX_LATITUDE_DIFF[0][80] = 8.403254122859673
MAX_LATITUDE_DIFF[0][81] = 8.634817903862276
MAX_LATITUDE_DIFF[0][82] = 8.870269346470687
MAX_LATITUDE_DIFF[0][83] = 9.1096448404615
MAX_LATITUDE_DIFF[0][84] = 9.352981014547389
MAX_LATITUDE_DIFF[0][85] = 9.600314714406242
MAX_LATITUDE_DIFF[0][86] = 9.852702110736296
MAX_LATITUDE_DIFF[0][87] = 10.109615988724501
MAX_LATITUDE_DIFF[0][88] = 10.370737233806315
MAX_LATITUDE_DIFF[0][89] = 10.636107036727937
MAX_LATITUDE_DIFF[0][90] = 10.905766761573146
MAX_LATITUDE_DIFF[0][91] = 11.179757915070851
MAX_LATITUDE_DIFF[0][92] = 11.458122114060957
MAX_LATITUDE_DIFF[0][93] = 11.740901051050074
MAX_LATITUDE_DIFF[0][94] = 12.02925819881915
MAX_LATITUDE_DIFF[0][95] = 12.323042846003005
MAX_LATITUDE_DIFF[0][96] = 12.621496519129654
MAX_LATITUDE_DIFF[0][97] = 12.924665493645628
MAX_LATITUDE_DIFF[0][98] = 13.232596016879581
MAX_LATITUDE_DIFF[0][99] = 13.54533425986114
MAX_LATITUDE_DIFF[0][100] = 13.862926266483056
MAX_LATITUDE_DIFF[0][101] = 14.186441416488341
MAX_LATITUDE_DIFF[0][102] = 14.516591918603366
MAX_LATITUDE_DIFF[0][103] = 14.851896862262418
MAX_LATITUDE_DIFF[0][104] = 15.192406911855375
MAX_LATITUDE_DIFF[0][105] = 15.538172407744709
MAX_LATITUDE_DIFF[0][106] = 15.889243304321527
MAX_LATITUDE_DIFF[0][107] = 16.24566909275642
MAX_LATITUDE_DIFF[0][108] = 16.610847678432943
MAX_LATITUDE_DIFF[0][109] = 16.981752442834004
MAX_LATITUDE_DIFF[0][110] = 17.3583650976731
MAX_LATITUDE_DIFF[0][111] = 17.740739338025996
MAX_LATITUDE_DIFF[0][112] = 18.128928067244967
MAX_LATITUDE_DIFF[0][113] = 18.523768533980764
MAX_LATITUDE_DIFF[0][114] = 18.92809933296945
MAX_LATITUDE_DIFF[0][115] = 19.338645471753324
MAX_LATITUDE_DIFF[0][116] = 19.755463600577873
MAX_LATITUDE_DIFF[0][117] = 20.178609101414477
MAX_LATITUDE_DIFF[0][118] = 20.60813595146906
MAX_LATITUDE_DIFF[0][119] = 21.048955559729066
MAX_LATITUDE_DIFF[0][120] = 21.496993211546272
MAX_LATITUDE_DIFF[0][121] = 21.95186990932397
MAX_LATITUDE_DIFF[0][122] = 22.413641830350432
MAX_LATITUDE_DIFF[0][123] = 22.882921348707143
MAX_LATITUDE_DIFF[0][124] = 23.36513632828199
MAX_LATITUDE_DIFF[0][125] = 23.854756694706275
MAX_LATITUDE_DIFF[0][126] = 24.351839508175164
MAX_LATITUDE_DIFF[0][127] = 24.856438907943335
MAX_LATITUDE_DIFF[0][128] = 25.373571683317337
MAX_LATITUDE_DIFF[0][129] = 25.901372445956547
MAX_LATITUDE_DIFF[0][130] = 26.437258308501498
MAX_LATITUDE_DIFF[0][131] = 26.981281101424386
MAX_LATITUDE_DIFF[0][132] = 27.538427652751423
MAX_LATITUDE_DIFF[0][133] = 28.10832660419275
MAX_LATITUDE_DIFF[0][134] = 28.686988469664556
MAX_LATITUDE_DIFF[0][135] = 29.274459084574303
MAX_LATITUDE_DIFF[0][136] = 29.878742851360634
MAX_LATITUDE_DIFF[0][137] = 30.49519703358188
MAX_LATITUDE_DIFF[0][138] = 31.121142747343292
MAX_LATITUDE_DIFF[0][139] = 31.75917650955676
MAX_LATITUDE_DIFF[0][140] = 32.417001063884186
MAX_LATITUDE_DIFF[0][141] = 33.08506085539533
MAX_LATITUDE_DIFF[0][142] = 33.76338043905408
MAX_LATITUDE_DIFF[0][143] = 34.465436317472985
MAX_LATITUDE_DIFF[0][144] = 35.17973607305457
MAX_LATITUDE_DIFF[0][145] = 35.9050851118047
MAX_LATITUDE_DIFF[0][146] = 36.655861922138094
MAX_LATITUDE_DIFF[0][147] = 37.42107893199533
MAX_LATITUDE_DIFF[0][148] = 38.198184578762316
MAX_LATITUDE_DIFF[0][149] = 39.00641908743213
MAX_LATITUDE_DIFF[0][150] = 39.82784855293855
MAX_LATITUDE_DIFF[0][151] = 40.66871577645403
MAX_LATITUDE_DIFF[0][152] = 41.53857679903406
MAX_LATITUDE_DIFF[0][153] = 42.42219463934789
MAX_LATITUDE_DIFF[0][154] = 43.33995373251434
MAX_LATITUDE_DIFF[0][155] = 44.277770264962115
MAX_LATITUDE_DIFF[0][156] = 45.2433715998829
MAX_LATITUDE_DIFF[0][157] = 46.24084409704571
MAX_LATITUDE_DIFF[0][158] = 47.262445154714
MAX_LATITUDE_DIFF[0][159] = 48.32582707170247
MAX_LATITUDE_DIFF[0][160] = 49.41353739520019
MAX_LATITUDE_DIFF[0][161] = 50.55001083826622
MAX_LATITUDE_DIFF[0][162] = 51.71642844891475
MAX_LATITUDE_DIFF[0][163] = 52.934254355412406
MAX_LATITUDE_DIFF[0][164] = 54.19516819501083
MAX_LATITUDE_DIFF[0][165] = 55.503850631375144
MAX_LATITUDE_DIFF[0][166] = 56.87916235023402
MAX_LATITUDE_DIFF[0][167] = 58.303778507176986
MAX_LATITUDE_DIFF[0][168] = 59.8045492442233
MAX_LATITUDE_DIFF[0][169] = 61.38753649601645
MAX_LATITUDE_DIFF[0][170] = 63.05050744477706
MAX_LATITUDE_DIFF[0][171] = 64.80864167028643
MAX_LATITUDE_DIFF[0][172] = 66.68737566307826
MAX_LATITUDE_DIFF[0][173] = 68.71319367461153
MAX_LATITUDE_DIFF[0][174] = 70.9037115913045
MAX_LATITUDE_DIFF[0][175] = 73.29926553297211
MAX_LATITUDE_DIFF[0][176] = 75.99009877920435
MAX_LATITUDE_DIFF[0][177] = 79.06832675047693
MAX_LATITUDE_DIFF[0][178] = 82.73326472142118
MAX_LATITUDE_DIFF[0][179] = 87.56869228518067
MAX_LATITUDE_DIFF[0][180] = 97.89999999999134
MAX_LATITUDE_DIFF[1][0] = 0
MAX_LATITUDE_DIFF[1][1] = 15477405543917744e-21
MAX_LATITUDE_DIFF[1][2] = 154790558558382e-19
MAX_LATITUDE_DIFF[1][3] = 1548180813552014e-20
MAX_LATITUDE_DIFF[1][4] = 1548566467121093e-20
MAX_LATITUDE_DIFF[1][5] = 1549062817712832e-20
MAX_LATITUDE_DIFF[1][6] = 15496701618700207e-21
MAX_LATITUDE_DIFF[1][7] = 15503888187284876e-21
MAX_LATITUDE_DIFF[1][8] = 1551219136245452e-20
MAX_LATITUDE_DIFF[1][9] = 15521615010549716e-21
MAX_LATITUDE_DIFF[1][10] = 1553216347089936e-20
MAX_LATITUDE_DIFF[1][11] = 1554384161590594e-20
MAX_LATITUDE_DIFF[1][12] = 15556654888782e-18
MAX_LATITUDE_DIFF[1][13] = 15570609323822818e-21
MAX_LATITUDE_DIFF[1][14] = 15585711551047156e-21
MAX_LATITUDE_DIFF[1][15] = 15601968804623837e-21
MAX_LATITUDE_DIFF[1][16] = 15619388920673496e-21
MAX_LATITUDE_DIFF[1][17] = 1563798034239783e-20
MAX_LATITUDE_DIFF[1][18] = 15657752123376945e-21
MAX_LATITUDE_DIFF[1][19] = 1567871393147735e-20
MAX_LATITUDE_DIFF[1][20] = 15700876057278545e-21
MAX_LATITUDE_DIFF[1][21] = 15724249420667748e-21
MAX_LATITUDE_DIFF[1][22] = 15748845580609847e-21
MAX_LATITUDE_DIFF[1][23] = 15774676746749265e-21
MAX_LATITUDE_DIFF[1][24] = 15801755788935613e-21
MAX_LATITUDE_DIFF[1][25] = 15830096251390206e-21
MAX_LATITUDE_DIFF[1][26] = 15859712367849447e-21
MAX_LATITUDE_DIFF[1][27] = 1589061907182332e-20
MAX_LATITUDE_DIFF[1][28] = 1592283201637956e-20
MAX_LATITUDE_DIFF[1][29] = 1595636758867647e-20
MAX_LATITUDE_DIFF[1][30] = 15862872980676454e-21
MAX_LATITUDE_DIFF[1][31] = 15937787944153575e-21
MAX_LATITUDE_DIFF[1][32] = 15890052672773436e-21
MAX_LATITUDE_DIFF[1][33] = 15931233268418054e-21
MAX_LATITUDE_DIFF[1][34] = 15864186898983946e-21
MAX_LATITUDE_DIFF[1][35] = 1592857372827439e-20
MAX_LATITUDE_DIFF[1][36] = 1598170604424354e-20
MAX_LATITUDE_DIFF[1][37] = 15977936894273628e-21
MAX_LATITUDE_DIFF[1][38] = 15968112153430528e-21
MAX_LATITUDE_DIFF[1][39] = 15775076161261838e-21
MAX_LATITUDE_DIFF[1][40] = 1584582809286417e-20
MAX_LATITUDE_DIFF[1][41] = 15985717332966942e-21
MAX_LATITUDE_DIFF[1][42] = 1594265497999459e-20
MAX_LATITUDE_DIFF[1][43] = 15945031456965356e-21
MAX_LATITUDE_DIFF[1][44] = 15973936696411785e-21
MAX_LATITUDE_DIFF[1][45] = 1592207766840659e-20
MAX_LATITUDE_DIFF[1][46] = 0.003197215408666666
MAX_LATITUDE_DIFF[1][47] = 0.015343465577811344
MAX_LATITUDE_DIFF[1][48] = 0.036145320355144867
MAX_LATITUDE_DIFF[1][49] = 0.0652561989978267
MAX_LATITUDE_DIFF[1][50] = 0.10235338235817296
MAX_LATITUDE_DIFF[1][51] = 0.14706811808180903
MAX_LATITUDE_DIFF[1][52] = 0.19909888857193395
MAX_LATITUDE_DIFF[1][53] = 0.2581705778015412
MAX_LATITUDE_DIFF[1][54] = 0.32403203115738677
MAX_LATITUDE_DIFF[1][55] = 0.39645388210345023
MAX_LATITUDE_DIFF[1][56] = 0.47522657497274423
MAX_LATITUDE_DIFF[1][57] = 0.560158619653393
MAX_LATITUDE_DIFF[1][58] = 0.6510750039686201
MAX_LATITUDE_DIFF[1][59] = 0.7481343382426624
MAX_LATITUDE_DIFF[1][60] = 0.8509324562877425
MAX_LATITUDE_DIFF[1][61] = 0.9593246868730029
MAX_LATITUDE_DIFF[1][62] = 1.0731899499269488
MAX_LATITUDE_DIFF[1][63] = 1.1924178962278518
MAX_LATITUDE_DIFF[1][64] = 1.316908039650933
MAX_LATITUDE_DIFF[1][65] = 1.4465689697497548
MAX_LATITUDE_DIFF[1][66] = 1.581317636257564
MAX_LATITUDE_DIFF[1][67] = 1.7210786980405972
MAX_LATITUDE_DIFF[1][68] = 1.8658015274295565
MAX_LATITUDE_DIFF[1][69] = 2.015996776958844
MAX_LATITUDE_DIFF[1][70] = 2.1710790901406223
MAX_LATITUDE_DIFF[1][71] = 2.3310003534525863
MAX_LATITUDE_DIFF[1][72] = 2.495717756666107
MAX_LATITUDE_DIFF[1][73] = 2.6651934099099277
MAX_LATITUDE_DIFF[1][74] = 2.83939397469726
MAX_LATITUDE_DIFF[1][75] = 3.0182904142924936
MAX_LATITUDE_DIFF[1][76] = 3.2018575728536964
MAX_LATITUDE_DIFF[1][77] = 3.390073988502148
MAX_LATITUDE_DIFF[1][78] = 3.5835448290630985
MAX_LATITUDE_DIFF[1][79] = 3.7820233941112305
MAX_LATITUDE_DIFF[1][80] = 3.985186797355007
MAX_LATITUDE_DIFF[1][81] = 4.193028603811536
MAX_LATITUDE_DIFF[1][82] = 4.405545023030772
MAX_LATITUDE_DIFF[1][83] = 4.62273474089693
MAX_LATITUDE_DIFF[1][84] = 4.844598727004323
MAX_LATITUDE_DIFF[1][85] = 5.071140099374022
MAX_LATITUDE_DIFF[1][86] = 5.302548762964862
MAX_LATITUDE_DIFF[1][87] = 5.539888849540272
MAX_LATITUDE_DIFF[1][88] = 5.782030125442086
MAX_LATITUDE_DIFF[1][89] = 6.028986389852716
MAX_LATITUDE_DIFF[1][90] = 6.2807729992007
MAX_LATITUDE_DIFF[1][91] = 6.537406749207084
MAX_LATITUDE_DIFF[1][92] = 6.798905761318138
MAX_LATITUDE_DIFF[1][93] = 7.06528937296232
MAX_LATITUDE_DIFF[1][94] = 7.337457549231668
MAX_LATITUDE_DIFF[1][95] = 7.615690843323813
MAX_LATITUDE_DIFF[1][96] = 7.899006331818339
MAX_LATITUDE_DIFF[1][97] = 8.187431700981104
MAX_LATITUDE_DIFF[1][98] = 8.480995448779813
MAX_LATITUDE_DIFF[1][99] = 8.779726787154125
MAX_LATITUDE_DIFF[1][100] = 9.083655544957269
MAX_LATITUDE_DIFF[1][101] = 9.394168613826125
MAX_LATITUDE_DIFF[1][102] = 9.71127395789446
MAX_LATITUDE_DIFF[1][103] = 10.033836572457458
MAX_LATITUDE_DIFF[1][104] = 10.361893346747856
MAX_LATITUDE_DIFF[1][105] = 10.695481403741283
MAX_LATITUDE_DIFF[1][106] = 11.034638006291575
MAX_LATITUDE_DIFF[1][107] = 11.38021209089566
MAX_LATITUDE_DIFF[1][108] = 11.734021723822702
MAX_LATITUDE_DIFF[1][109] = 12.093716249706713
MAX_LATITUDE_DIFF[1][110] = 12.459339062192164
MAX_LATITUDE_DIFF[1][111] = 12.830933262268212
MAX_LATITUDE_DIFF[1][112] = 13.208541537355991
MAX_LATITUDE_DIFF[1][113] = 13.594385143316277
MAX_LATITUDE_DIFF[1][114] = 13.988493590956809
MAX_LATITUDE_DIFF[1][115] = 14.38899144195612
MAX_LATITUDE_DIFF[1][116] = 14.795926316495459
MAX_LATITUDE_DIFF[1][117] = 15.209344858112736
MAX_LATITUDE_DIFF[1][118] = 15.630736519630991
MAX_LATITUDE_DIFF[1][119] = 16.06254412299245
MAX_LATITUDE_DIFF[1][120] = 16.501267034461886
MAX_LATITUDE_DIFF[1][121] = 16.946955515593885
MAX_LATITUDE_DIFF[1][122] = 17.39965810959159
MAX_LATITUDE_DIFF[1][123] = 17.862314612680294
MAX_LATITUDE_DIFF[1][124] = 18.335667980697572
MAX_LATITUDE_DIFF[1][125] = 18.816526237818152
MAX_LATITUDE_DIFF[1][126] = 19.30493945446416
MAX_LATITUDE_DIFF[1][127] = 19.80108400243768
MAX_LATITUDE_DIFF[1][128] = 20.312341154217176
MAX_LATITUDE_DIFF[1][129] = 20.831702054229954
MAX_LATITUDE_DIFF[1][130] = 21.359216034870926
MAX_LATITUDE_DIFF[1][131] = 21.89492860339928
MAX_LATITUDE_DIFF[1][132] = 22.44698108877225
MAX_LATITUDE_DIFF[1][133] = 23.008664183237862
MAX_LATITUDE_DIFF[1][134] = 23.579154885390775
MAX_LATITUDE_DIFF[1][135] = 24.15932215006792
MAX_LATITUDE_DIFF[1][136] = 24.758211269070674
MAX_LATITUDE_DIFF[1][137] = 25.366578162164483
MAX_LATITUDE_DIFF[1][138] = 25.984458751024093
MAX_LATITUDE_DIFF[1][139] = 26.618340412374096
MAX_LATITUDE_DIFF[1][140] = 27.26810558723263
MAX_LATITUDE_DIFF[1][141] = 27.928111486444106
MAX_LATITUDE_DIFF[1][142] = 28.601410858027304
MAX_LATITUDE_DIFF[1][143] = 29.29658104971928
MAX_LATITUDE_DIFF[1][144] = 30.002779964840258
MAX_LATITUDE_DIFF[1][145] = 30.722028610579443
MAX_LATITUDE_DIFF[1][146] = 31.467162102986965
MAX_LATITUDE_DIFF[1][147] = 32.22416985722979
MAX_LATITUDE_DIFF[1][148] = 32.99749525769632
MAX_LATITUDE_DIFF[1][149] = 33.797773101972865
MAX_LATITUDE_DIFF[1][150] = 34.61081896018212
MAX_LATITUDE_DIFF[1][151] = 35.448347428359476
MAX_LATITUDE_DIFF[1][152] = 36.30964455551617
MAX_LATITUDE_DIFF[1][153] = 37.18480581412693
MAX_LATITUDE_DIFF[1][154] = 38.098996776638074
MAX_LATITUDE_DIFF[1][155] = 39.02794436885996
MAX_LATITUDE_DIFF[1][156] = 39.99015915015012
MAX_LATITUDE_DIFF[1][157] = 40.97847265236973
MAX_LATITUDE_DIFF[1][158] = 41.996622167934184
MAX_LATITUDE_DIFF[1][159] = 43.05049776912743
MAX_LATITUDE_DIFF[1][160] = 44.134604328920574
MAX_LATITUDE_DIFF[1][161] = 45.261158984838175
MAX_LATITUDE_DIFF[1][162] = 46.423715410877186
MAX_LATITUDE_DIFF[1][163] = 47.631135734517116
MAX_LATITUDE_DIFF[1][164] = 48.887802138306796
MAX_LATITUDE_DIFF[1][165] = 50.18550716188993
MAX_LATITUDE_DIFF[1][166] = 51.55602526850754
MAX_LATITUDE_DIFF[1][167] = 52.97566823285223
MAX_LATITUDE_DIFF[1][168] = 54.46422512117411
MAX_LATITUDE_DIFF[1][169] = 56.04134654247219
MAX_LATITUDE_DIFF[1][170] = 57.698033040341535
MAX_LATITUDE_DIFF[1][171] = 59.4493470347167
MAX_LATITUDE_DIFF[1][172] = 61.31473537863662
MAX_LATITUDE_DIFF[1][173] = 63.330565179044065
MAX_LATITUDE_DIFF[1][174] = 65.51146556506426
MAX_LATITUDE_DIFF[1][175] = 67.89728326546765
MAX_LATITUDE_DIFF[1][176] = 70.58139270618653
MAX_LATITUDE_DIFF[1][177] = 73.64397930337493
MAX_LATITUDE_DIFF[1][178] = 77.29670271299356
MAX_LATITUDE_DIFF[1][179] = 82.11162865402056
MAX_LATITUDE_DIFF[1][180] = 92.39591059885089
MAX_LATITUDE_DIFF[2][0] = 0
MAX_LATITUDE_DIFF[2][1] = 11826936979419945e-21
MAX_LATITUDE_DIFF[2][2] = 11828438104011242e-21
MAX_LATITUDE_DIFF[2][3] = 11830940750157116e-21
MAX_LATITUDE_DIFF[2][4] = 11834446071812277e-21
MAX_LATITUDE_DIFF[2][5] = 11838955675036456e-21
MAX_LATITUDE_DIFF[2][6] = 11844471610056306e-21
MAX_LATITUDE_DIFF[2][7] = 11850996366502555e-21
MAX_LATITUDE_DIFF[2][8] = 11858532866448892e-21
MAX_LATITUDE_DIFF[2][9] = 11867084461725242e-21
MAX_LATITUDE_DIFF[2][10] = 11876654933917764e-21
MAX_LATITUDE_DIFF[2][11] = 11887248496078586e-21
MAX_LATITUDE_DIFF[2][12] = 11898869794801925e-21
MAX_LATITUDE_DIFF[2][13] = 11911523918528568e-21
MAX_LATITUDE_DIFF[2][14] = 11925216402797202e-21
MAX_LATITUDE_DIFF[2][15] = 11939953238182531e-21
MAX_LATITUDE_DIFF[2][16] = 11955740876767874e-21
MAX_LATITUDE_DIFF[2][17] = 11972586244968221e-21
MAX_LATITUDE_DIFF[2][18] = 11990496745484248e-21
MAX_LATITUDE_DIFF[2][19] = 12009480272567875e-21
MAX_LATITUDE_DIFF[2][20] = 12029545217517868e-21
MAX_LATITUDE_DIFF[2][21] = 12050700477106437e-21
MAX_LATITUDE_DIFF[2][22] = 12072955463593439e-21
MAX_LATITUDE_DIFF[2][23] = 12096320116083973e-21
MAX_LATITUDE_DIFF[2][24] = 12120804907611583e-21
MAX_LATITUDE_DIFF[2][25] = 15979550079237017e-21
MAX_LATITUDE_DIFF[2][26] = 159619286698387e-19
MAX_LATITUDE_DIFF[2][27] = 15970916316376818e-21
MAX_LATITUDE_DIFF[2][28] = 15974476740865382e-21
MAX_LATITUDE_DIFF[2][29] = 15933809818591273e-21
MAX_LATITUDE_DIFF[2][30] = 15924098956432433e-21
MAX_LATITUDE_DIFF[2][31] = 159483449163389e-19
MAX_LATITUDE_DIFF[2][32] = 15949871152542985e-21
MAX_LATITUDE_DIFF[2][33] = 1595795710187531e-20
MAX_LATITUDE_DIFF[2][34] = 15921330594537154e-21
MAX_LATITUDE_DIFF[2][35] = 1590073256281244e-20
MAX_LATITUDE_DIFF[2][36] = 15897365935302334e-21
MAX_LATITUDE_DIFF[2][37] = 1591242508212076e-20
MAX_LATITUDE_DIFF[2][38] = 159470916059945e-19
MAX_LATITUDE_DIFF[2][39] = 15773112766126477e-21
MAX_LATITUDE_DIFF[2][40] = 15835714938816638e-21
MAX_LATITUDE_DIFF[2][41] = 15921040129640576e-21
MAX_LATITUDE_DIFF[2][42] = 15985245858018972e-21
MAX_LATITUDE_DIFF[2][43] = 15932095745085918e-21
MAX_LATITUDE_DIFF[2][44] = 15970935418607723e-21
MAX_LATITUDE_DIFF[2][45] = 15946429608959534e-21
MAX_LATITUDE_DIFF[2][46] = 15954913750704235e-21
MAX_LATITUDE_DIFF[2][47] = 159796030324344e-19
MAX_LATITUDE_DIFF[2][48] = 1596901115021865e-20
MAX_LATITUDE_DIFF[2][49] = 15904865858828997e-21
MAX_LATITUDE_DIFF[2][50] = 15925120218440726e-21
MAX_LATITUDE_DIFF[2][51] = 15931894186849152e-21
MAX_LATITUDE_DIFF[2][52] = 15869330644591174e-21
MAX_LATITUDE_DIFF[2][53] = 15903177681586556e-21
MAX_LATITUDE_DIFF[2][54] = 15867701546312676e-21
MAX_LATITUDE_DIFF[2][55] = 1593143776545958e-20
MAX_LATITUDE_DIFF[2][56] = 15640643675851605e-21
MAX_LATITUDE_DIFF[2][57] = 1590312508206182e-20
MAX_LATITUDE_DIFF[2][58] = 15790262024495406e-21
MAX_LATITUDE_DIFF[2][59] = 15736152555945185e-21
MAX_LATITUDE_DIFF[2][60] = 15978060050514388e-21
MAX_LATITUDE_DIFF[2][61] = 0.0009013713247952639
MAX_LATITUDE_DIFF[2][62] = 0.009339259695941139
MAX_LATITUDE_DIFF[2][63] = 0.026520950773512197
MAX_LATITUDE_DIFF[2][64] = 0.05227859476768231
MAX_LATITUDE_DIFF[2][65] = 0.08634968910474967
MAX_LATITUDE_DIFF[2][66] = 0.1285211741822323
MAX_LATITUDE_DIFF[2][67] = 0.17859255936717208
MAX_LATITUDE_DIFF[2][68] = 0.23638595783100413
MAX_LATITUDE_DIFF[2][69] = 0.30212529535616856
MAX_LATITUDE_DIFF[2][70] = 0.37532890404572683
MAX_LATITUDE_DIFF[2][71] = 0.4558396513243225
MAX_LATITUDE_DIFF[2][72] = 0.5435100266865157
MAX_LATITUDE_DIFF[2][73] = 0.6382015946347692
MAX_LATITUDE_DIFF[2][74] = 0.7397844800987535
MAX_LATITUDE_DIFF[2][75] = 0.8487259974921196
MAX_LATITUDE_DIFF[2][76] = 0.9645181096282159
MAX_LATITUDE_DIFF[2][77] = 1.086957539885637
MAX_LATITUDE_DIFF[2][78] = 1.2159453070712638
MAX_LATITUDE_DIFF[2][79] = 1.3513889803566967
MAX_LATITUDE_DIFF[2][80] = 1.493202305257436
MAX_LATITUDE_DIFF[2][81] = 1.6415814253108993
MAX_LATITUDE_DIFF[2][82] = 1.7969561530354605
MAX_LATITUDE_DIFF[2][83] = 1.958583059055937
MAX_LATITUDE_DIFF[2][84] = 2.126399488969246
MAX_LATITUDE_DIFF[2][85] = 2.3003475236137283
MAX_LATITUDE_DIFF[2][86] = 2.4803737069681326
MAX_LATITUDE_DIFF[2][87] = 2.666428781272369
MAX_LATITUDE_DIFF[2][88] = 2.859333180743934
MAX_LATITUDE_DIFF[2][89] = 3.058813484136206
MAX_LATITUDE_DIFF[2][90] = 3.2643210722639724
MAX_LATITUDE_DIFF[2][91] = 3.4758282920541763
MAX_LATITUDE_DIFF[2][92] = 3.6933044794254997
MAX_LATITUDE_DIFF[2][93] = 3.91672426367019
MAX_LATITUDE_DIFF[2][94] = 4.146249463721637
MAX_LATITUDE_DIFF[2][95] = 4.383410537815838
MAX_LATITUDE_DIFF[2][96] = 4.62660073497236
MAX_LATITUDE_DIFF[2][97] = 4.875809444347444
MAX_LATITUDE_DIFF[2][98] = 5.131027746780698
MAX_LATITUDE_DIFF[2][99] = 5.39224878030485
MAX_LATITUDE_DIFF[2][100] = 5.659467579247892
MAX_LATITUDE_DIFF[2][101] = 5.934827251060422
MAX_LATITUDE_DIFF[2][102] = 6.216665456611781
MAX_LATITUDE_DIFF[2][103] = 6.504674745864894
MAX_LATITUDE_DIFF[2][104] = 6.798861380247814
MAX_LATITUDE_DIFF[2][105] = 7.09923287428073
MAX_LATITUDE_DIFF[2][106] = 7.405797844167159
MAX_LATITUDE_DIFF[2][107] = 7.72170469858274
MAX_LATITUDE_DIFF[2][108] = 8.044055520015704
MAX_LATITUDE_DIFF[2][109] = 8.372845033135702
MAX_LATITUDE_DIFF[2][110] = 8.708091056194649
MAX_LATITUDE_DIFF[2][111] = 9.049811878499272
MAX_LATITUDE_DIFF[2][112] = 9.39994123111385
MAX_LATITUDE_DIFF[2][113] = 9.758746907960177
MAX_LATITUDE_DIFF[2][114] = 10.124335289304446
MAX_LATITUDE_DIFF[2][115] = 10.496732353163074
MAX_LATITUDE_DIFF[2][116] = 10.875963868271274
MAX_LATITUDE_DIFF[2][117] = 11.263777455210947
MAX_LATITUDE_DIFF[2][118] = 11.661735035620218
MAX_LATITUDE_DIFF[2][119] = 12.066898845887502
MAX_LATITUDE_DIFF[2][120] = 12.479300602379109
MAX_LATITUDE_DIFF[2][121] = 12.898970984564583
MAX_LATITUDE_DIFF[2][122] = 13.329334462175371
MAX_LATITUDE_DIFF[2][123] = 13.769757951486445
MAX_LATITUDE_DIFF[2][124] = 14.217886954844781
MAX_LATITUDE_DIFF[2][125] = 14.673755776994843
MAX_LATITUDE_DIFF[2][126] = 15.138280694803239
MAX_LATITUDE_DIFF[2][127] = 15.616902314303541
MAX_LATITUDE_DIFF[2][128] = 16.103762336094363
MAX_LATITUDE_DIFF[2][129] = 16.59889638660889
MAX_LATITUDE_DIFF[2][130] = 17.102336945181545
MAX_LATITUDE_DIFF[2][131] = 22.000002874763798
MAX_LATITUDE_DIFF[2][132] = 18.151498788855502
MAX_LATITUDE_DIFF[2][133] = 18.689459310242153
MAX_LATITUDE_DIFF[2][134] = 19.23737839147114
MAX_LATITUDE_DIFF[2][135] = 19.803248886793128
MAX_LATITUDE_DIFF[2][136] = 20.378616570111888
MAX_LATITUDE_DIFF[2][137] = 20.963508363482287
MAX_LATITUDE_DIFF[2][138] = 21.563704055865973
MAX_LATITUDE_DIFF[2][139] = 22.17978280389131
MAX_LATITUDE_DIFF[2][140] = 22.80607409951877
MAX_LATITUDE_DIFF[2][141] = 23.444219938651113
MAX_LATITUDE_DIFF[2][142] = 24.104789548083005
MAX_LATITUDE_DIFF[2][143] = 24.776324940243622
MAX_LATITUDE_DIFF[2][144] = 25.458826890716278
MAX_LATITUDE_DIFF[2][145] = 26.16768332039196
MAX_LATITUDE_DIFF[2][146] = 26.88884818647528
MAX_LATITUDE_DIFF[2][147] = 27.622025693747286
MAX_LATITUDE_DIFF[2][148] = 28.385159239789107
MAX_LATITUDE_DIFF[2][149] = 29.16094757640035
MAX_LATITUDE_DIFF[2][150] = 29.95457004031978
MAX_LATITUDE_DIFF[2][151] = 30.777082711617055
MAX_LATITUDE_DIFF[2][152] = 31.613164783861762
MAX_LATITUDE_DIFF[2][153] = 32.47882584392303
MAX_LATITUDE_DIFF[2][154] = 33.36710871776003
MAX_LATITUDE_DIFF[2][155] = 34.276483876253636
MAX_LATITUDE_DIFF[2][156] = 35.22213852866648
MAX_LATITUDE_DIFF[2][157] = 36.183400736911814
MAX_LATITUDE_DIFF[2][158] = 37.19137881721482
MAX_LATITUDE_DIFF[2][159] = 38.217149438315865
MAX_LATITUDE_DIFF[2][160] = 39.29062216442122
MAX_LATITUDE_DIFF[2][161] = 40.387826132317926
MAX_LATITUDE_DIFF[2][162] = 41.53897502270913
MAX_LATITUDE_DIFF[2][163] = 42.71557933106999
MAX_LATITUDE_DIFF[2][164] = 43.959682700124006
MAX_LATITUDE_DIFF[2][165] = 45.2389029165806
MAX_LATITUDE_DIFF[2][166] = 46.581179836804935
MAX_LATITUDE_DIFF[2][167] = 47.98609386399242
MAX_LATITUDE_DIFF[2][168] = 49.44713946561554
MAX_LATITUDE_DIFF[2][169] = 50.99815673013496
MAX_LATITUDE_DIFF[2][170] = 52.63621011209748
MAX_LATITUDE_DIFF[2][171] = 54.367297532998776
MAX_LATITUDE_DIFF[2][172] = 56.21042120217504
MAX_LATITUDE_DIFF[2][173] = 58.19052017542547
MAX_LATITUDE_DIFF[2][174] = 60.34092855981163
MAX_LATITUDE_DIFF[2][175] = 62.70713455870073
MAX_LATITUDE_DIFF[2][176] = 65.35269357682527
MAX_LATITUDE_DIFF[2][177] = 68.36882547010573
MAX_LATITUDE_DIFF[2][178] = 71.98529901388599
MAX_LATITUDE_DIFF[2][179] = 76.73933931274317
MAX_LATITUDE_DIFF[2][180] = 86.89930807068455
MAX_LATITUDE_DIFF[3][0] = 0
MAX_LATITUDE_DIFF[3][1] = 1096907111132195e-20
MAX_LATITUDE_DIFF[3][2] = 10970486319716866e-21
MAX_LATITUDE_DIFF[3][3] = 109728456282987e-19
MAX_LATITUDE_DIFF[3][4] = 10976149978891847e-21
MAX_LATITUDE_DIFF[3][5] = 10980400691906755e-21
MAX_LATITUDE_DIFF[3][6] = 10985599463653185e-21
MAX_LATITUDE_DIFF[3][7] = 1099174837000394e-20
MAX_LATITUDE_DIFF[3][8] = 10998849865784256e-21
MAX_LATITUDE_DIFF[3][9] = 11006906785504534e-21
MAX_LATITUDE_DIFF[3][10] = 11015922346413466e-21
MAX_LATITUDE_DIFF[3][11] = 11025900148742274e-21
MAX_LATITUDE_DIFF[3][12] = 11036844180467575e-21
MAX_LATITUDE_DIFF[3][13] = 11048758816945006e-21
MAX_LATITUDE_DIFF[3][14] = 11061648826404814e-21
MAX_LATITUDE_DIFF[3][15] = 11075519372638621e-21
MAX_LATITUDE_DIFF[3][16] = 11090376020617133e-21
MAX_LATITUDE_DIFF[3][17] = 11106224739054762e-21
MAX_LATITUDE_DIFF[3][18] = 11123071906760098e-21
MAX_LATITUDE_DIFF[3][19] = 11140924317887267e-21
MAX_LATITUDE_DIFF[3][20] = 11159789186576675e-21
MAX_LATITUDE_DIFF[3][21] = 11179674156236441e-21
MAX_LATITUDE_DIFF[3][22] = 15986456528338522e-21
MAX_LATITUDE_DIFF[3][23] = 1598374634070865e-20
MAX_LATITUDE_DIFF[3][24] = 1598535768891907e-20
MAX_LATITUDE_DIFF[3][25] = 1596691321879007e-20
MAX_LATITUDE_DIFF[3][26] = 15953931549006483e-21
MAX_LATITUDE_DIFF[3][27] = 1593329717692882e-20
MAX_LATITUDE_DIFF[3][28] = 15930946969433534e-21
MAX_LATITUDE_DIFF[3][29] = 1594854772513088e-20
MAX_LATITUDE_DIFF[3][30] = 15987656881577552e-21
MAX_LATITUDE_DIFF[3][31] = 15958916652891733e-21
MAX_LATITUDE_DIFF[3][32] = 1593906989221239e-20
MAX_LATITUDE_DIFF[3][33] = 15928821330390974e-21
MAX_LATITUDE_DIFF[3][34] = 15928867026460126e-21
MAX_LATITUDE_DIFF[3][35] = 15939891624228152e-21
MAX_LATITUDE_DIFF[3][36] = 1596256570266519e-20
MAX_LATITUDE_DIFF[3][37] = 15861601822564358e-21
MAX_LATITUDE_DIFF[3][38] = 15901137637186478e-21
MAX_LATITUDE_DIFF[3][39] = 1595409568828643e-20
MAX_LATITUDE_DIFF[3][40] = 15860102711684478e-21
MAX_LATITUDE_DIFF[3][41] = 15932844354793477e-21
MAX_LATITUDE_DIFF[3][42] = 158421661350161e-19
MAX_LATITUDE_DIFF[3][43] = 15936490469670162e-21
MAX_LATITUDE_DIFF[3][44] = 15850055778443563e-21
MAX_LATITUDE_DIFF[3][45] = 15967751278367362e-21
MAX_LATITUDE_DIFF[3][46] = 15886515946306193e-21
MAX_LATITUDE_DIFF[3][47] = 15982021379556956e-21
MAX_LATITUDE_DIFF[3][48] = 15956199602840113e-21
MAX_LATITUDE_DIFF[3][49] = 15919632156879972e-21
MAX_LATITUDE_DIFF[3][50] = 1591091096457831e-20
MAX_LATITUDE_DIFF[3][51] = 15986758670294423e-21
MAX_LATITUDE_DIFF[3][52] = 1591580474666898e-20
MAX_LATITUDE_DIFF[3][53] = 15875527364528355e-21
MAX_LATITUDE_DIFF[3][54] = 15874683974459458e-21
MAX_LATITUDE_DIFF[3][55] = 1595626870383171e-20
MAX_LATITUDE_DIFF[3][56] = 1594347959024134e-20
MAX_LATITUDE_DIFF[3][57] = 15948919261177254e-21
MAX_LATITUDE_DIFF[3][58] = 15956066192046593e-21
MAX_LATITUDE_DIFF[3][59] = 15891057908135055e-21
MAX_LATITUDE_DIFF[3][60] = 1596280958438001e-20
MAX_LATITUDE_DIFF[3][61] = 15980510790924998e-21
MAX_LATITUDE_DIFF[3][62] = 15934280017582127e-21
MAX_LATITUDE_DIFF[3][63] = 15986411305135566e-21
MAX_LATITUDE_DIFF[3][64] = 1586792958505612e-20
MAX_LATITUDE_DIFF[3][65] = 1593105018713459e-20
MAX_LATITUDE_DIFF[3][66] = 1593496405760675e-20
MAX_LATITUDE_DIFF[3][67] = 15978597218690996e-21
MAX_LATITUDE_DIFF[3][68] = 15986093202968732e-21
MAX_LATITUDE_DIFF[3][69] = 15925820337869825e-21
MAX_LATITUDE_DIFF[3][70] = 15962217264764435e-21
MAX_LATITUDE_DIFF[3][71] = 0.0013162718564203148
MAX_LATITUDE_DIFF[3][72] = 0.01011571867826362
MAX_LATITUDE_DIFF[3][73] = 0.027201361129740323
MAX_LATITUDE_DIFF[3][74] = 0.052396221135163054
MAX_LATITUDE_DIFF[3][75] = 0.08556113360892753
MAX_LATITUDE_DIFF[3][76] = 0.12681101073750228
MAX_LATITUDE_DIFF[3][77] = 0.17606351743284354
MAX_LATITUDE_DIFF[3][78] = 0.23305123706552494
MAX_LATITUDE_DIFF[3][79] = 0.29765592289314285
MAX_LATITUDE_DIFF[3][80] = 0.37002955757413647
MAX_LATITUDE_DIFF[3][81] = 0.4503284541304701
MAX_LATITUDE_DIFF[3][82] = 0.5380651926948198
MAX_LATITUDE_DIFF[3][83] = 0.6331414256674592
MAX_LATITUDE_DIFF[3][84] = 0.7354634068175621
MAX_LATITUDE_DIFF[3][85] = 0.8457026876092323
MAX_LATITUDE_DIFF[3][86] = 0.9634109577846758
MAX_LATITUDE_DIFF[3][87] = 1.0882571974923358
MAX_LATITUDE_DIFF[3][88] = 1.2201657001520823
MAX_LATITUDE_DIFF[3][89] = 1.359064597334811
MAX_LATITUDE_DIFF[3][90] = 1.5059293709431054
MAX_LATITUDE_DIFF[3][91] = 1.66012924633546
MAX_LATITUDE_DIFF[3][92] = 1.8212816132930436
MAX_LATITUDE_DIFF[3][93] = 1.9893307765268438
MAX_LATITUDE_DIFF[3][94] = 2.2
MAX_LATITUDE_DIFF[3][95] = 2.3469052146733875
MAX_LATITUDE_DIFF[3][96] = 3.3000000000000003
MAX_LATITUDE_DIFF[3][97] = 3.3000000000000003
MAX_LATITUDE_DIFF[3][98] = 4.400000000000001
MAX_LATITUDE_DIFF[3][99] = 4.4
MAX_LATITUDE_DIFF[3][100] = 4.4
MAX_LATITUDE_DIFF[3][101] = 5.500000000000002
MAX_LATITUDE_DIFF[3][102] = 5.5
MAX_LATITUDE_DIFF[3][103] = 5.500000000000001
MAX_LATITUDE_DIFF[3][104] = 6.600000000000001
MAX_LATITUDE_DIFF[3][105] = 6.6000000000000005
MAX_LATITUDE_DIFF[3][106] = 6.6000000000000005
MAX_LATITUDE_DIFF[3][107] = 7.7
MAX_LATITUDE_DIFF[3][108] = 7.700000000000002
MAX_LATITUDE_DIFF[3][109] = 7.7
MAX_LATITUDE_DIFF[3][110] = 7.700000000000001
MAX_LATITUDE_DIFF[3][111] = 8.799999999999999
MAX_LATITUDE_DIFF[3][112] = 8.8
MAX_LATITUDE_DIFF[3][113] = 8.8
MAX_LATITUDE_DIFF[3][114] = 8.8
MAX_LATITUDE_DIFF[3][115] = 9.900000000000002
MAX_LATITUDE_DIFF[3][116] = 9.9
MAX_LATITUDE_DIFF[3][117] = 9.9
MAX_LATITUDE_DIFF[3][118] = 9.9
MAX_LATITUDE_DIFF[3][119] = 11
MAX_LATITUDE_DIFF[3][120] = 11
MAX_LATITUDE_DIFF[3][121] = 11.000000000000002
MAX_LATITUDE_DIFF[3][122] = 11.000000000000002
MAX_LATITUDE_DIFF[3][123] = 11
MAX_LATITUDE_DIFF[3][124] = 33.00000240250901
MAX_LATITUDE_DIFF[3][125] = 12.100000000000001
MAX_LATITUDE_DIFF[3][126] = 12.100000000000001
MAX_LATITUDE_DIFF[3][127] = 12.228837360069157
MAX_LATITUDE_DIFF[3][128] = 12.678439766373595
MAX_LATITUDE_DIFF[3][129] = 13.142439852085992
MAX_LATITUDE_DIFF[3][130] = 13.616913276667207
MAX_LATITUDE_DIFF[3][131] = 14.100281447682988
MAX_LATITUDE_DIFF[3][132] = 14.592560694225602
MAX_LATITUDE_DIFF[3][133] = 15.101472543646734
MAX_LATITUDE_DIFF[3][134] = 15.62116473533988
MAX_LATITUDE_DIFF[3][135] = 16.15033352078941
MAX_LATITUDE_DIFF[3][136] = 16.690579488722282
MAX_LATITUDE_DIFF[3][137] = 17.24955087729306
MAX_LATITUDE_DIFF[3][138] = 17.81863141133955
MAX_LATITUDE_DIFF[3][139] = 18.397828974688505
MAX_LATITUDE_DIFF[3][140] = 18.995594948041173
MAX_LATITUDE_DIFF[3][141] = 19.608066809561166
MAX_LATITUDE_DIFF[3][142] = 20.23135044220073
MAX_LATITUDE_DIFF[3][143] = 20.87147086266978
MAX_LATITUDE_DIFF[3][144] = 21.531335475828847
MAX_LATITUDE_DIFF[3][145] = 22.20277210901829
MAX_LATITUDE_DIFF[3][146] = 22.891770559271787
MAX_LATITUDE_DIFF[3][147] = 23.603622928094868
MAX_LATITUDE_DIFF[3][148] = 24.327868730765516
MAX_LATITUDE_DIFF[3][149] = 25.07399149829156
MAX_LATITUDE_DIFF[3][150] = 33.00000854422253
MAX_LATITUDE_DIFF[3][151] = 26.625467806163357
MAX_LATITUDE_DIFF[3][152] = 27.439128080166668
MAX_LATITUDE_DIFF[3][153] = 28.271499956942545
MAX_LATITUDE_DIFF[3][154] = 29.12516126843148
MAX_LATITUDE_DIFF[3][155] = 30.012379382016924
MAX_LATITUDE_DIFF[3][156] = 30.914845721550304
MAX_LATITUDE_DIFF[3][157] = 31.859903872428117
MAX_LATITUDE_DIFF[3][158] = 32.82397719076337
MAX_LATITUDE_DIFF[3][159] = 33.8283102192766
MAX_LATITUDE_DIFF[3][160] = 34.86053699440117
MAX_LATITUDE_DIFF[3][161] = 35.93487279512039
MAX_LATITUDE_DIFF[3][162] = 37.04281716200527
MAX_LATITUDE_DIFF[3][163] = 38.20067305109718
MAX_LATITUDE_DIFF[3][164] = 39.39308669960194
MAX_LATITUDE_DIFF[3][165] = 40.65159085331283
MAX_LATITUDE_DIFF[3][166] = 41.9494317699237
MAX_LATITUDE_DIFF[3][167] = 43.31958077922102
MAX_LATITUDE_DIFF[3][168] = 44.75550763407128
MAX_LATITUDE_DIFF[3][169] = 46.25548905029691
MAX_LATITUDE_DIFF[3][170] = 47.85202068621067
MAX_LATITUDE_DIFF[3][171] = 49.5501593480065
MAX_LATITUDE_DIFF[3][172] = 51.35699821991024
MAX_LATITUDE_DIFF[3][173] = 53.29653069746327
MAX_LATITUDE_DIFF[3][174] = 55.40083608315534
MAX_LATITUDE_DIFF[3][175] = 57.71370134346485
MAX_LATITUDE_DIFF[3][176] = 60.296325780972985
MAX_LATITUDE_DIFF[3][177] = 63.23658178044802
MAX_LATITUDE_DIFF[3][178] = 66.79393339529481
MAX_LATITUDE_DIFF[3][179] = 71.448540038695
MAX_LATITUDE_DIFF[3][180] = 81.3969385515753
export function shouldExtend(A, D, F, I, T) {
  if (F.f >= 0.005) return true
  let _ = A.x - D.x
  _ = normalizeLon(_)
  _ = Math.abs(_)
  const M = Math.abs(A.y - D.y)
  const L = Math.floor(Math.max(0, Math.min(3, M / 10)))
  const E = Math.floor(Math.max(0, Math.min(180, _ + 0.5)))
  const U = MAX_LATITUDE_DIFF[L][E]
  if (T) {
    let F = Math.max(A.y, D.y)
    F = Math.min(90, F + U)
    return I.x + I.height < F
  } else {
    let F = Math.min(A.y, D.y)
    F = Math.max(-90, F - U)
    return I.y > F
  }
}
