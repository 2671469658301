import { PixelFormat } from './PixelFormat.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
export function getByteCount(r) {
  switch (r) {
    case PixelFormat.RGB_888:
      return 3
    case PixelFormat.RGBA_8888:
      return 4
    case PixelFormat.USHORT:
      return 2
    case PixelFormat.UINT_32:
      return 4
    case PixelFormat.FLOAT_32:
      return 4
    default:
      throw new ProgrammingError(`Unsupported pixel format ${r}`)
  }
}
