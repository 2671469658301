import { GeoReference } from '../../reference/GeoReference.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { Animation } from './Animation.js'
import { linear, quadInOut } from '../../util/Easing.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
const DEFAULT_ANIM_DURATION = 1e3
class ZoomAnimation extends Animation {
  constructor(t, i, n, a, o) {
    super(
      'number' === typeof a.duration ? a.duration : DEFAULT_ANIM_DURATION,
      a.ease || linear
    )
    this._startViewOrigin = t
    this._startWorldOrigin = o.viewToMapTransformation.transform(t)
    this._map = o
    this._startRotation = 0
    this._startScaleX = 1
    this._startScaleY = 1
    this._targetScaleX = i
    this._targetScaleY = n
    this.__id = 'zoom'
  }
  get _id() {
    return this.__id
  }
  onStart() {
    const t = this._map.camera
    const i = t.asLook2D()
    this._startRotation = i.rotation
    this._startScaleX = i.scaleX
    this._startScaleY = i.scaleY
    i.worldOrigin = this._startWorldOrigin
    i.viewOrigin = this._startViewOrigin
    const n = t.look2D(i)
    this._map.camera = this._map.mapNavigator.getCameraWithConstraints(n)
  }
  update(t) {
    const i = this.ease ? this.ease(t) : t
    const n = 1 / this._startScaleX
    const a = 1 / this._targetScaleX
    const o = 1 / this._startScaleY
    const r = 1 / this._targetScaleY
    let e, s
    if (i >= 1) {
      e = this._targetScaleX
      s = this._targetScaleY
    } else {
      const t = undefined
      const c = undefined
      e = 1 / ((1 - i) * n + i * a)
      s = 1 / ((1 - i) * o + i * r)
    }
    const c = this._map.camera
    const h = c.asLook2D()
    h.worldOrigin = this._startWorldOrigin
    h.viewOrigin = this._startViewOrigin
    h.scaleX = e
    h.scaleY = s
    h.rotation = this._startRotation
    const l = c.look2D(h)
    this._map.camera = this._map.mapNavigator.getCameraWithConstraints(l)
  }
}
class PanAnimation extends Animation {
  constructor(t, i, n, a) {
    super(
      'number' === typeof n.duration ? n.duration : DEFAULT_ANIM_DURATION,
      n.ease || linear
    )
    this._map = a
    this._worldOriginStart = { x: 0, y: 0, z: 0 }
    this._worldOriginTarget = new XYZPoint()
    this._toViewLocation = i
    this._targetLocationView = t
  }
  onStart() {
    const t = this._map.camera.asLook2D()
    this._worldOriginStart = t.worldOrigin
    const i = t.viewOrigin
    const n = this._toViewLocation.x - this._targetLocationView.x
    const a = this._toViewLocation.y - this._targetLocationView.y
    const o = createPoint(null, [i.x - n, i.y - a])
    this._worldOriginTarget = this._map.viewToMapTransformation.transform(o)
  }
  update(t) {
    t = this.ease ? this.ease(t) : t
    const i =
      this._worldOriginStart.x +
      t * (this._worldOriginTarget.x - this._worldOriginStart.x)
    const n =
      this._worldOriginStart.y +
      t * (this._worldOriginTarget.y - this._worldOriginStart.y)
    const a =
      this._worldOriginStart.z +
      t * (this._worldOriginTarget.z - this._worldOriginStart.z)
    const o = createPoint(this._map.reference, [i, n, a])
    const r = this._map.camera
    const e = r.asLook2D()
    e.worldOrigin = o
    const s = r.look2D(e)
    this._map.camera = this._map.mapNavigator.getCameraWithConstraints(s)
  }
}
class RotateAnimation extends Animation {
  constructor(t, i, n, a) {
    super(
      'number' === typeof n.duration ? n.duration : DEFAULT_ANIM_DURATION,
      n.ease || linear
    )
    this._rotateCenterView = t
    this._viewOriginStart = t
    this._worldOriginStart = new XYZPoint()
    this._map = a
    this._rotationStart = 0
    this._targetAngle = i
  }
  onStart() {
    this._worldOriginStart = this._map.viewToMapTransformation.transform(
      this._rotateCenterView
    )
    this._viewOriginStart = this._rotateCenterView
    const t = this._map.camera.asLook2D()
    this._rotationStart = t.rotation
  }
  update(t) {
    t = this.ease ? this.ease(t) : t
    const i = lerpAngle(this._rotationStart, this._targetAngle, t)
    const n = this._map.camera
    const a = n.asLook2D()
    a.worldOrigin = this._worldOriginStart
    a.viewOrigin = this._viewOriginStart
    a.rotation = i
    const o = n.look2D(a)
    this._map.camera = this._map.mapNavigator.getCameraWithConstraints(o)
  }
}
function split(t, i, n) {
  return (a) => {
    if (a < n) return t(a / n)
    return i((a - n) / (1 - n))
  }
}
function mirror(t) {
  return (i) => t(1 - i)
}
function easeInOut(t) {
  return quadInOut(Math.max(0, Math.min(1, t)))
}
const originFunc = easeInOut
const heightFunc1 = split(easeInOut, mirror(easeInOut), 0.5)
const heightFunc2 = easeInOut
const rotationFunc = easeInOut
function lerpAngle(t, i, n) {
  if (Math.abs(t - 360 - i) < Math.abs(t - i)) t -= 360
  else if (Math.abs(t + 360 - i) < Math.abs(t - i)) t += 360
  return t + n * (i - t)
}
export function createZoomAnimation(t, i, n, a, o) {
  return new ZoomAnimation(
    t,
    i,
    n,
    (a = a || { duration: DEFAULT_ANIM_DURATION, ease: linear }),
    o
  )
}
export function createPanAnimation(t, i, n, a) {
  return new PanAnimation(
    t,
    i,
    (n = n || { duration: DEFAULT_ANIM_DURATION, ease: linear }),
    a
  )
}
export function createRotateAnimation(t, i, n, a) {
  return new RotateAnimation(
    t,
    i,
    (n = n || { duration: DEFAULT_ANIM_DURATION, ease: linear }),
    a
  )
}
function calculateDurationEllipsoid(t, i, n, a) {
  const o = undefined
  const r = t / (2 * i.a * Math.PI)
  const e = undefined
  return n + Math.max(0, Math.min(1, r)) * (a - n)
}
function calculateDuration(t, i, n, a) {
  if (i instanceof GeoReference)
    return calculateDurationEllipsoid(t, i.geodeticDatum.ellipsoid, n, a)
  return 0.5 * (n + a)
}
export function createFlyToAnimation(t, i, n) {
  const a = i.duration / 2
  const o = i.duration
  const r = t.asLook2D()
  const e = undefined
  const s = n.camera.asLook2D()
  const c = r.worldOrigin.x - s.worldOrigin.x
  const h = r.worldOrigin.y - s.worldOrigin.y
  const l = Math.sqrt(c * c + h * h)
  const m = calculateDuration(l, n.reference, a, o)
  const _ = undefined
  const g = l / (Math.max(n.viewSize[0], n.viewSize[1]) || 1)
  const u = new Animation(m, i.ease)
  const d = 1 / s.scaleX
  const w = 1 / s.scaleY
  const O = 1 / r.scaleX
  const p = 1 / r.scaleY
  const f = s.viewOrigin
  const A = r.viewOrigin
  const D = createPoint(null, [s.viewOrigin.x, s.viewOrigin.y])
  const S = s.worldOrigin
  const x = r.worldOrigin
  const T = createPoint(n.reference, [s.worldOrigin.x, s.worldOrigin.y])
  const v = s.rotation
  const I = r.rotation
  u.update = function (t) {
    let i
    let a
    let o
    const r = this.ease ? this.ease(t) : t
    const e = Math.max(d, O)
    if (g <= e + 1e-10) {
      o = heightFunc2(r)
      i = d + (O - d) * o
      a = w + (p - w) * o
    } else {
      o = heightFunc1(r)
      if (r < 0.5) {
        i = d + (g - d) * o
        a = w + (g - w) * o
      } else {
        o = 1 - o
        i = g + (O - g) * o
        a = g + (p - g) * o
      }
    }
    const s = originFunc(r)
    D.move2D(f.x + s * (A.x - f.x), f.y + s * (A.y - f.y))
    T.move2D(S.x + s * (x.x - S.x), S.y + s * (x.y - S.y))
    const c = lerpAngle(v, I, rotationFunc(r))
    const h = n.camera
    const l = h.asLook2D()
    l.worldOrigin = T
    l.viewOrigin = D
    l.scaleX = 1 / i
    l.scaleY = 1 / a
    l.rotation = c
    const m = h.look2D(l)
    n.camera = n.mapNavigator.getCameraWithConstraints(m)
  }
  return u
}
