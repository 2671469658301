import { Photon } from '../gen/photon/photon_painter.js'
import { isIE } from '../util/Browser.js'
import { EventedSupport } from '../util/EventedSupport.js'
import { Log } from '../util/Log.js'
import { Map } from './Map.js'
import { PhotonWorldViewTransformation } from './photon/PhotonWorldViewTransformation.js'
import { PhotonMapNavigator } from './PhotonMapNavigator.js'
import { PhotonViewPaintingStrategy } from './strategy/PhotonViewPaintingStrategy.js'
const DEFAULT_CPU_HINT = 500
const DEFAULT_GPU_HINT = 1e3
export class WebGLMap extends Map {
  constructor(t, e) {
    if (isIE())
      Log.error(
        'LuciadRIA WebGLMap is not supported by any version of Internet Explorer'
      )
    delete (e = Object.assign(
      {},
      {
        viewPaintingStrategyConstructor: PhotonViewPaintingStrategy,
        mapNavigatorConstructor: PhotonMapNavigator,
        onPhotonReady: Photon.onReady,
        v2w: PhotonWorldViewTransformation,
      },
      e
    )).webgl
    super(t, e)
    this._adjustDepthRange = e.adjustDepthRange ?? true
    this._webGLMapEventedSupport = new EventedSupport([
      'PostRender',
      'WebGLContextChanged',
    ])
    const i = () => {
      if (this.viewPaintingStrategy) {
        this._webGLMapEventedSupport.onFirstListenerAdded('PostRender', () => {
          if (this.viewPaintingStrategy instanceof PhotonViewPaintingStrategy) {
            const t = this.viewPaintingStrategy._photonView
            t?.setFlushDepth(true)
          }
        })
        this._webGLMapEventedSupport.onLastListenerRemoved('PostRender', () => {
          if (this.viewPaintingStrategy instanceof PhotonViewPaintingStrategy) {
            const t = this.viewPaintingStrategy._photonView
            t?.setFlushDepth(false)
          }
        })
      } else
        this.onReady(() => {
          i()
        })
    }
    i()
    this.onReady(() => {
      if (this.viewPaintingStrategy instanceof PhotonViewPaintingStrategy) {
        const t = undefined
        this.viewPaintingStrategy._photonView.setAutoAdjustCamera(
          this._adjustDepthRange
        )
        this.viewPaintingStrategy.on('WebGLContextChanged', () => {
          this._webGLMapEventedSupport.emit('WebGLContextChanged')
        })
      }
    })
    this.maxMemoryUsageHint = e.maxMemoryUsageHint
      ? e.maxMemoryUsageHint
      : { cpuMB: DEFAULT_CPU_HINT, gpuMB: DEFAULT_GPU_HINT }
  }
  get maxMemoryUsageHint() {
    return this.viewPaintingStrategy.getMaxMemoryUsageHint()
  }
  set maxMemoryUsageHint(t) {
    if (!t) t = {}
    if (!t.cpuMB) t.cpuMB = DEFAULT_CPU_HINT
    if (!t.gpuMB) t.gpuMB = DEFAULT_GPU_HINT
    if (t.cpuMB < 50) {
      console.log(
        'WebGLMap.maxMemoryUsageHint called with cpuMB < 50.  Limiting to 50 MB.'
      )
      t.cpuMB = 50
    }
    if (t.gpuMB < 50) {
      console.log(
        'WebGLMap.maxMemoryUsageHint called with gpuMB < 50.  Limiting to 50 MB.'
      )
      t.gpuMB = 50
    }
    if (t.cpuMB > 4e3)
      console.log(
        'WebGLMap.maxMemoryUsageHint set cpuMB larger than 4000 MB. ' +
          'This can cause instability on machines with low amounts of RAM.'
      )
    if (t.gpuMB > 4e3)
      console.log(
        'WebGLMap.maxMemoryUsageHint set with gpuMB larger than 4000 MB. ' +
          'This can cause instability on machines with low amounts of video memory.'
      )
    this.onReady(() => {
      this.viewPaintingStrategy.setMaxMemoryUsageHint(t)
    })
  }
  set adjustDepthRange(t) {
    this._adjustDepthRange = t
    if (this.viewPaintingStrategy) {
      const e = undefined
      this.viewPaintingStrategy._photonView.setAutoAdjustCamera(t)
    }
  }
  get adjustDepthRange() {
    return this._adjustDepthRange
  }
  get webGLContext() {
    if (this.viewPaintingStrategy) return this.viewPaintingStrategy.webGLContext
    return null
  }
  reboot() {
    if (this.webGLContext && this.webGLContext.isContextLost()) {
      if (this.mapNavigator instanceof PhotonMapNavigator)
        this.mapNavigator.preReboot(this)
      this.viewPaintingStrategy.reboot()
      if (this.mapNavigator instanceof PhotonMapNavigator)
        this.mapNavigator.postReboot(this)
      if (this.controller) this.controller.invalidate()
      this.invalidate()
    }
  }
  enableLayerClipping(t, e, i, n) {
    if (this.viewPaintingStrategy)
      this.viewPaintingStrategy.enableLayerClipping(t, e, i, n)
  }
  disableLayerClipping() {
    if (this.viewPaintingStrategy)
      this.viewPaintingStrategy.disableLayerClipping()
  }
  enableLayerFlickering(t) {
    if (this.viewPaintingStrategy)
      this.viewPaintingStrategy.enableLayerFlickering(t)
  }
  firePostRenderEvent(t) {
    this._webGLMapEventedSupport?.emit('PostRender', t)
  }
  on(t, e, i) {
    if ('idle' === t) return super.on(t, e, i)
    if ('SelectionChanged' === t) return super.on(t, e, i)
    if ('HoverChanged' === t) return super.on(t, e, i)
    if ('MapChange' === t) return super.on(t, e, i)
    if ('ShowBalloon' === t) return super.on(t, e, i)
    if ('HideBalloon' === t) return super.on(t, e, i)
    if ('ControllerChanged' === t) return super.on(t, e, i)
    return this._webGLMapEventedSupport.on(t, e, i)
  }
}
