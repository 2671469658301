import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isValidReferenceIdentifier } from '../../reference/ReferenceProvider.js'
import { isDefined } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { CapabilitiesParserUtil } from '../capabilities/common/CapabilitiesParserUtil.js'
import { WMTSCapabilities } from '../capabilities/WMTSCapabilities.js'
import { buildDimensionsQueryString, validateDimensions } from '../OGCUtil.js'
import { UrlTileSetModel } from './UrlTileSetModel.js'
import {
  createWmtsGetTileUrl,
  deriveWmtsStructure,
  getWmtsCompatibleLevelOffset,
  validateWMTSOptions,
} from './WMTSUtils.js'
class WMTSTileSetModel extends UrlTileSetModel {
  constructor(e) {
    super((e = validateWMTSOptions(e)))
    this.modelDescriptor = {
      source: e.url,
      name: e.layer,
      description: 'OGC WMTS Model',
      type: super.dataType,
    }
    this._dimensions = null
    this.tileMatrixSet = e.tileMatrixSet
    this.tileMatrices = e.tileMatrices
    this.dimensions = e.dimensions || null
  }
  static createFromCapabilities(e, i, t) {
    const r = t || {}
    const s = e.layers.filter((e) => e.identifier === i.layer)[0]
    const n = isDefined(r.useQuadTreeOnly) && r.useQuadTreeOnly
    if (!isDefined(s))
      throw new ProgrammingError(
        `there is no layer "${i.layer}" in capabilities`
      )
    let l, a
    if (r.tileMatrixSet && isDefined(r.tileMatrixSet)) {
      l = s.tileMatrixSets.filter((e) => e.identifier === r.tileMatrixSet)[0]
      if (isDefined(l)) a = getWmtsCompatibleLevelOffset(l, n, false)
    } else {
      let e, i
      let t
      for (let l = 0; l < s.tileMatrixSets.length; l++) {
        if (!isValidReferenceIdentifier(s.tileMatrixSets[l].referenceName)) {
          Log.warn(
            'Reference ' +
              s.tileMatrixSets[l].referenceName +
              ' in GetCapabilities response is not known by the ReferenceProvider. Consider adding it.'
          )
          continue
        }
        if (
          !r.reference ||
          !isDefined(r.reference) ||
          r.reference.equals(s.tileMatrixSets[l].getReference())
        ) {
          const r = getWmtsCompatibleLevelOffset(s.tileMatrixSets[l], n, true)
          if (
            (n &&
              s.tileMatrixSets[l].tileMatrices[r].matrixWidth <= 8 &&
              s.tileMatrixSets[l].tileMatrices[r].matrixHeight <= 8) ||
            (!n && 0 === r)
          ) {
            i = r
            e = s.tileMatrixSets[l]
            break
          }
          if (!n && !isDefined(t)) t = s.tileMatrixSets[l]
        }
      }
      if (isDefined(e) && isDefined(i)) {
        l = e
        a = i
      } else if (isDefined(t)) {
        l = t
        a = 0
      }
    }
    if (!l || !isDefined(l) || !isDefined(a))
      if (r.reference && isDefined(r.reference))
        throw new ProgrammingError(
          `Could not find a WMTS tile matrix set that matches the specified reference [${r.reference.name}]`
        )
      else if (isDefined(r.tileMatrixSet))
        throw new ProgrammingError(
          `Could not find a WMTS tile matrix set that matches the specified identifier [${r.tileMatrixSet}]`
        )
      else
        throw new ProgrammingError(
          'Could not find a supported WMTS tile matrix in the capabilities'
        )
    const o = s.styles.filter((e) => e.isDefault)[0] || s.styles[0]
    const d = o && o.identifier
    const f = e.requests.GetTile.filter(
      (e) => 'GET' === e.requestMethod && e.encodings.indexOf('KVP') > -1
    )[0]
    let m
    if (isDefined(f)) {
      m = CapabilitiesParserUtil.processServiceUrl(f.url)
      const e = undefined
      const t = l.tileMatrices.map((e) => e.identifier).slice(a)
      const o = deriveWmtsStructure(l, a, n)
      const c = r.url || m
      const u = s.identifier
      const M = i.style || d
      const S = r.format || s.formats[0]
      const p = l.identifier
      const T = createWmtsGetTileUrl(c, u, M, S, p)
      return new WMTSTileSetModel({
        url: c,
        baseURL: T,
        layer: s.identifier,
        tileMatrixSet: l.identifier,
        format: S,
        tileMatrices: t,
        structure: o,
        style: M,
        credentials: r.credentials,
        requestHeaders: r.requestHeaders,
        dimensions: r.dimensions,
        requestParameters: r.requestParameters,
      })
    } else
      throw new ProgrammingError(
        'WMTSTileSetModel::cannot create WMTSTileSetModel, the service does not support KVP encoded GetTile requests.'
      )
  }
  static createFromURL(e, i, t) {
    return WMTSCapabilities.fromURL(e, t).then((e) =>
      WMTSTileSetModel.createFromCapabilities(e, i, t)
    )
  }
  getTileBounds(e) {
    return super.getTileBounds(e)
  }
  getTileURL(e, i) {
    const t = this.tileMatrices[i.level]
    if (!isDefined(t)) return null
    const r = this.getTileRowCount(i.level)
    if (null == r) return null
    let s = `${e}&TILEMATRIX=${t}&TILEROW=${r - 1 - i.y}&TILECOL=${i.x}`
    if (this._dimensions) s += buildDimensionsQueryString(this._dimensions)
    return super.getTileURL(s, i)
  }
  get requestParameters() {
    return super.requestParameters
  }
  set requestParameters(e) {
    super.requestParameters = e
  }
  get dimensions() {
    return this._dimensions
  }
  set dimensions(e) {
    this._dimensions = validateDimensions(e)
    this.invalidate()
  }
}
WMTSTileSetModel.constructor = WMTSTileSetModel
export { WMTSTileSetModel }
