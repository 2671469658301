import { LineSegmentChooser } from './LineSegmentChooser.js'
function LineSegmentChooserIntersect() {}
LineSegmentChooserIntersect.prototype = Object.create(
  LineSegmentChooser.prototype
)
LineSegmentChooserIntersect.prototype.constructor = LineSegmentChooserIntersect
LineSegmentChooserIntersect.prototype.isSegmentOkAbstract = function (e) {
  if (e.isFilled()) {
    if (e.segmentLabel.locationOn[e.otherShapeIndex])
      if (
        e.segmentLabel.locationLeft[e.otherShapeIndex] ==
        e.segmentLabel.locationRight[e.shapeIndex]
      )
        return true
  } else return e.segmentLabel.locationOn[e.otherShapeIndex]
  return false
}
export { LineSegmentChooserIntersect }
