import { ProgrammingError } from '../../error/ProgrammingError.js'
function CGSubBounds() {
  this._flagsBottomRight = 65535
  this._flagsBottomLeft = 65535
  this._flagsUpperRight = 65535
  this._flagsUpperLeft = 65535
  this._nextDiff = null
}
CGSubBounds.prototype = Object.create(Object.prototype)
CGSubBounds.prototype.constructor = CGSubBounds
Object.defineProperty(CGSubBounds.prototype, 'nextDiff', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._nextDiff
  },
  set: function () {
    throw new ProgrammingError('nextDiff property is not mutable')
  },
})
CGSubBounds.prototype.interacts = function (t) {
  return (
    0 !== (this._flagsBottomRight & t._flagsBottomRight) ||
    0 !== (this._flagsBottomLeft & t._flagsBottomLeft) ||
    0 !== (this._flagsUpperRight & t._flagsUpperRight) ||
    0 !== (this._flagsUpperLeft & t._flagsUpperLeft)
  )
}
CGSubBounds.calculate = function (t, e, o) {
  if (null == t.getBounds(o) || null == e.getBounds(o)) return
  var r = o.getNewEditableBoundsFromBounds(t.getBounds(o))
  if (null == r) return
  var s = o.getNewEditableBoundsFromBounds(e.getBounds(o))
  if (null == s) return
  o.growBounds(o.toleranceEqualPoints, r)
  o.growBounds(o.toleranceEqualPoints, s)
  r.setTo2DIntersection(s)
  var n = Math.floor(Math.sqrt(Math.max(t.vertexCount, e.vertexCount)))
  var a = Math.max(1, Math.min(n, 8))
  var u = a
  this._calculateWithBounds(t, r, o, a, u)
  this._calculateWithBounds(e, r, o, a, u)
}
CGSubBounds.prototype._setBit = function (t) {
  var e
  if (t >= 48) {
    e = t - 48
    this._flagsUpperLeft |= 1 << e
  } else if (t < 48 && t >= 32) {
    e = t - 32
    this._flagsUpperRight |= 1 << e
  } else if (t < 32 && t >= 16) {
    e = t - 16
    this._flagsBottomLeft |= 1 << e
  } else if (t < 16) this._flagsBottomRight |= 1 << t
}
CGSubBounds._calculateWithBounds = function (t, e, o, r, s) {
  var n = r
  var a = s
  var u = e.x
  var i = e.y
  var f = e.x + e.width
  var l = e.y + e.height
  var g = o.getNewEditableBoundsFromCoordinates(
    e.reference,
    u,
    i,
    e.width,
    e.height
  )
  o.growBounds(o.toleranceEqualPoints, g)
  var B = []
  for (var d = 0; d < a; d++)
    for (var h = 0; h < n; h++) {
      var p = u + (h / n) * (f - u)
      var _ = i + (d / a) * (l - i)
      var m = e.width / n
      var v = e.height / a
      var b = d * n + h
      B[b] = o.getNewEditableBoundsFromCoordinates(e.reference, p, _, m, v)
      o.growBounds(o.toleranceEqualPoints, B[b])
    }
  for (var c = 0; c < t.elements.length; c++) {
    var x = true
    for (
      var C = t.elements[c];
      null != C && (x || C != t.elements[c]);
      C = C.next
    ) {
      x = false
      C.subBounds._flagsBottomRight = 0
      C.subBounds._flagsBottomLeft = 0
      C.subBounds._flagsUpperRight = 0
      C.subBounds._flagsUpperLeft = 0
      C.subBounds._nextDiff = null
      var L = this._getVertexBounds(C, o)
      if (!L.interacts2D(g)) continue
      for (var R = 0; R < B.length; R++)
        if (L.interacts2D(B[R])) C.subBounds._setBit(R)
    }
  }
  for (var c = 0; c < t.elements.length; c++) {
    var U = t.elements[c]
    var w = U.subBounds._flagsBottomRight
    var y = U.subBounds._flagsBottomLeft
    var E = U.subBounds._flagsUpperRight
    var G = U.subBounds._flagsUpperLeft
    var x = true
    for (
      var C = t.elements[c];
      null !== C && (x || C != t.elements[c]);
      C = C.next
    ) {
      x = false
      var S = C.subBounds._flagsBottomRight
      var P = C.subBounds._flagsBottomLeft
      var D = C.subBounds._flagsUpperRight
      var N = C.subBounds._flagsUpperLeft
      if (S != w || P != y || D != E || N != G) {
        for (var q = U; q !== C; q = q.next) q.subBounds._nextDiff = C
        U = C
        w = S
        y = P
        E = D
        G = N
      }
    }
  }
}
CGSubBounds._getVertexBounds = function (t, e) {
  if (null === t.next) return e.getNewEditableBoundsFromPoint(t.pos)
  else {
    const o = undefined
    return e.getNewEditablePolyline(t.pos.reference, [
      t.getVertexPointCoordinates(),
      t.next.getVertexPointCoordinates(),
    ]).bounds
  }
}
CGSubBounds.prototype.copy = function () {
  var t = new CGSubBounds()
  t._flagsBottomRight = this._flagsBottomRight
  t._flagsBottomLeft = this._flagsBottomLeft
  t._flagsUpperRight = this._flagsUpperRight
  t._flagsUpperLeft = this._flagsUpperLeft
  return t
}
export { CGSubBounds }
