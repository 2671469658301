import { TileCoordinate } from './TileCoordinate.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { PixelFormat } from '../../model/tileset/PixelFormat.js'
import { getByteCount } from '../../model/tileset/PixelFormatUtil.js'
const TOP = 1
const LEFT = 2
const RIGHT = 4
const BOTTOM = 8
const IN = 16
export function zoom(e, t, r, o) {
  const i = 0 !== (t & IN)
  const n = e.width
  const l = e.height
  let a = i ? ((n - (r ? 1 : 0)) / 2) | 0 : 2 * n
  let m = i ? ((l - (o ? 1 : 0)) / 2) | 0 : 2 * l
  let s
  if (0 !== (t & LEFT)) {
    s = e.x
    a += 1 & n
  } else s = e.x + n - a
  let f
  if (0 !== (t & TOP)) {
    f = e.y
    m += 1 & l
  } else f = e.y + l - m
  e.setTo2D(s, a, f, m)
}
export function hasPath(e, t) {
  if (t.level === e.level) return t.y === e.y && t.x === e.x
  else if (t.level > e.level) return hasPath(t, e)
  const r = e.level - t.level
  let o = e.y
  let i = e.x
  for (let e = 0; e < r; e++) {
    o = (o / 2) | 0
    i = (i / 2) | 0
  }
  return o === t.y && i === t.x
}
export function getPath(e, t, r, o) {
  if (t.level === e.level)
    if (t.y === e.y && t.x === e.x) return []
    else return null
  else if (t.level > e.level) {
    const i = getPath(t, e, r, o)
    if (null != i) {
      const e = i.length / 2
      for (let t = 0, r = i.length - 1; t < e; t++, r--) {
        const e = i[r] | IN
        i[r] = i[t] | IN
        i[t] = e
      }
      return i
    } else return null
  }
  const i = new Array(e.level - t.level)
  let n = e.y
  let l = e.x
  for (let e = 0; e < i.length; e++) {
    const t = (n / 2) | 0
    const a = (l / 2) | 0
    const m = undefined
    const s = undefined
    let f = 0
    if (0 === n - 2 * t) f |= o ? TOP : BOTTOM
    else f |= o ? BOTTOM : TOP
    if (0 === l - 2 * a) f |= r ? RIGHT : LEFT
    else f |= r ? LEFT : RIGHT
    i[e] = f
    n = t
    l = a
  }
  if (n !== t.y || l !== t.x) return null
  return i
}
export function mapToLowerLevel(e, t) {
  if (t < e.level) {
    let r = e.y
    let o = e.x
    let i = t
    while (i < e.level) {
      r = (r / 2) | 0
      o = (o / 2) | 0
      i++
    }
    return new TileCoordinate(t, o, r)
  } else return null
}
export function coordKeyLXY(e, t, r) {
  return `${e}.${t}.${r}`
}
export function validateTileData(e) {
  if (!e) throw new ProgrammingError('Tile data must be defined.')
  if ('object' !== typeof e)
    throw new ProgrammingError('Tile data must be an object.')
  if (!e.data) throw new ProgrammingError('Tile data must must contain a data.')
  if (!(e.data instanceof Image) && !(e.data instanceof ArrayBuffer))
    throw new ProgrammingError(
      'Tile data must either contain an Image or an ArrayBuffer.'
    )
  if (e.data instanceof ArrayBuffer) {
    if (e.pixelFormat && e.mimeType)
      throw new ProgrammingError(
        'Either the pixel format or the mime type of the tile data must be defined.'
      )
    if (!e.pixelFormat && !e.mimeType)
      throw new ProgrammingError(
        'Tile data must contain either mimeType or pixelFormat, not both.'
      )
    if (e.pixelFormat) {
      if (!PixelFormat[e.pixelFormat])
        throw new ProgrammingError(
          `Unknown pixel format '${e.pixelFormat}', please use a value from the PixelFormat class.`
        )
      if (!e.width || !e.height)
        throw new ProgrammingError(
          'Width and height properties must be defined if the tile data consist of pixel values.'
        )
      const t = undefined
      const r = undefined
      const o = e.width * e.height * getByteCount(e.pixelFormat)
      if (e.data.byteLength !== o)
        throw new ProgrammingError(
          `The length of the tile data array is not the same as the imagesize. The array length is ${e.data.byteLength}, while the imagesize is ${e.width}x${e.height}.`
        )
    }
  }
}
export function convertImageData(e, t) {
  if (t !== PixelFormat.RGB_888 && t !== PixelFormat.RGBA_8888)
    throw new ProgrammingError('Can only convert to RGB or RGBA images.')
  const r = e.width * e.height
  const o = getByteCount(e.pixelFormat)
  const i = new DataView(e.data)
  let n = (e) => e
  let l = true,
    a = true
  if (e.pixelFormat === t) return new Uint8ClampedArray(e.data)
  else if (
    e.pixelFormat === PixelFormat.RGB_888 ||
    e.pixelFormat === PixelFormat.RGBA_8888
  ) {
    n = i.getUint8.bind(i)
    l = false
  } else if (e.pixelFormat === PixelFormat.USHORT) n = i.getUint16.bind(i)
  else if (e.pixelFormat === PixelFormat.UINT_32) n = i.getUint32.bind(i)
  else if (e.pixelFormat === PixelFormat.FLOAT_32) {
    n = i.getFloat32.bind(i)
    a = false
  }
  const m = 8 * o
  const s = a ? m : m - 1
  const f = undefined
  const h = (Math.pow(2, s) - 1) / 255
  const d = getByteCount(t)
  const u = undefined
  const g = new ArrayBuffer(r * d)
  const c = new Uint8ClampedArray(g)
  let x, p
  for (let e = 0; e < r; e++) {
    x = e * d
    p = e * o
    if (l) {
      const e = Math.round(n(p) / h)
      c[x] = c[x + 1] = c[x + 2] = e
      c[x + 3] = 255
    } else {
      c[x] = n(p)
      c[x + 1] = n(p + 1)
      c[x + 2] = n(p + 2)
      if (t === PixelFormat.RGBA_8888) c[x + 3] = 255
    }
  }
  return c
}
