import { ShapeType } from '../../../shape/ShapeType.js'
import { Editor } from '../Editor.js'
import { BoundsCreateHandle } from '../handles/BoundsCreateHandle.js'
import { BoundsResizeHandle } from '../handles/BoundsResizeHandle.js'
import { BoundsResizeHandleIdentifier } from '../handles/BoundsResizeHandleIdentifier.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
export class BoundsEditor extends Editor {
  _resizeHandles = []
  constructor() {
    super()
    this._resizeHandles = []
  }
  canEdit(e) {
    return e.shape.type === ShapeType.BOUNDS
  }
  getEditHandles(e) {
    this._resizeHandles = removeNullHandles([
      this.createBoundsResizeHandle(e, BoundsResizeHandleIdentifier.LOWER_LEFT),
      this.createBoundsResizeHandle(
        e,
        BoundsResizeHandleIdentifier.LOWER_RIGHT
      ),
      this.createBoundsResizeHandle(
        e,
        BoundsResizeHandleIdentifier.UPPER_RIGHT
      ),
      this.createBoundsResizeHandle(e, BoundsResizeHandleIdentifier.UPPER_LEFT),
    ])
    for (const e of this._resizeHandles)
      if (e instanceof BoundsResizeHandle)
        e.on('Flipped', (e, s) => {
          this.flipResizeHandles(e, s)
        })
    return removeNullHandles([
      ...this._resizeHandles,
      this.createTranslateHandle(e),
    ])
  }
  createBoundsResizeHandle(e, s) {
    const n = e.shape
    return new BoundsResizeHandle(n, s)
  }
  flipResizeHandles(e, s) {
    for (const n of this._resizeHandles)
      if (n instanceof BoundsResizeHandle) n.flip(e, s)
  }
  getCreateHandle(e) {
    return new BoundsCreateHandle(e.shape)
  }
}
