import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { buildCache } from '../util/Cacher.js'
import {
  contains2D as contains2DImpl,
  findCenter,
  getBounds as getBoundsImpl,
  getFocusPoint,
  translateCircleBy3Points2D,
} from '../geodesy/EllipsoidCircleUtil.js'
import { CircleBy3Points } from './CircleBy3Points.js'
import { LLHBounds } from './LLHBounds.js'
import { LLHPoint } from './LLHPoint.js'
export class LLHCircleBy3Points extends CircleBy3Points {
  constructor(e, t, i, r) {
    super()
    this._reference = this.validateReference(e)
    this._compareReferenceStrict(
      t.reference,
      'LLHCircleBy3Points::the circle points reference must match the circle reference'
    )
    this._compareReferenceStrict(
      i.reference,
      'LLHCircleBy3Points::the circle points reference must match the circle reference'
    )
    this._compareReferenceStrict(
      r.reference,
      'LLHCircleBy3Points::the circle points reference must match the circle reference'
    )
    this._coordinates = [t.copy(), i.copy(), r.copy()]
    this._cache = buildCache()
  }
  get isGeodetic() {
    return true
  }
  get firstPoint() {
    return this._coordinates[0]
  }
  get secondPoint() {
    return this._coordinates[1]
  }
  get thirdPoint() {
    return this._coordinates[2]
  }
  moveFirstPoint2DToCoordinates(e, t) {
    this._coordinates[0].move2DToCoordinates(e, t)
    this._cache.invalidate()
  }
  moveSecondPoint2DToCoordinates(e, t) {
    this._coordinates[1].move2DToCoordinates(e, t)
    this._cache.invalidate()
  }
  moveThirdPoint2DToCoordinates(e, t) {
    this._coordinates[2].move2DToCoordinates(e, t)
    this._cache.invalidate()
  }
  translateFirstPoint2D(e, t) {
    this._coordinates[0].translate2D(e, t)
    this._cache.invalidate()
  }
  translateSecondPoint2D(e, t) {
    this._coordinates[1].translate2D(e, t)
    this._cache.invalidate()
  }
  translateThirdPoint2D(e, t) {
    this._coordinates[2].translate2D(e, t)
    this._cache.invalidate()
  }
  translate2D(e, t) {
    translateCircleBy3Points2D(
      this,
      this.reference.geodeticDatum.ellipsoid,
      e,
      t
    )
    this._cache.invalidate()
  }
  get bounds() {
    return this._cache.memoize('cached-bounding-box', () => {
      const e = new LLHBounds(this.reference, [0, 0, 0, 0, 0, 0])
      getBoundsImpl(this, this.reference.geodeticDatum.ellipsoid, e)
      return e
    })
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  get focusPoint() {
    return getFocusPoint(this, this.reference.geodeticDatum.ellipsoid)
  }
  get center() {
    const e = this._cache
    return this._cache.memoize('cached-center-point', () => {
      const t = new LLHPoint(this.reference)
      const i = findCenter(
        this.firstPoint,
        this.secondPoint,
        this.thirdPoint,
        this.reference.geodeticDatum.ellipsoid,
        t,
        null
      )
      e.cache('cached-radius', i)
      return t
    })
  }
  get radius() {
    const e = this._cache
    return this._cache.memoize('cached-radius', () => {
      const t = new LLHPoint(this.reference)
      const i = findCenter(
        this.firstPoint,
        this.secondPoint,
        this.thirdPoint,
        this.reference.geodeticDatum.ellipsoid,
        t,
        null
      )
      e.cache('cached-center-point', t)
      return i
    })
  }
  copy() {
    return new LLHCircleBy3Points(
      this.reference,
      this.firstPoint,
      this.secondPoint,
      this.thirdPoint
    )
  }
  contains2DCoordinates(e, t) {
    return (
      this.bounds.contains2DCoordinates(e, t) &&
      contains2DImpl(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.radius,
        e,
        t
      )
    )
  }
  contains3DCoordinates(e, t, i) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape LLHCircleBy3Points'
    )
  }
}
