import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isPolyline } from '../../shape/Polyline.js'
import {
  createCircleBy3Points,
  createCircleByCenterPoint,
  createComplexPolygon,
  createPoint,
  createPolygon,
  createPolyline,
  createShapeList,
} from '../../shape/ShapeFactory.js'
import { isArray } from '../../util/Lang.js'
import { parseGeometryToPointCoordinates } from './GMLCoordinatesParser.js'
import { isCircle } from '../../shape/Circle.js'
import {
  getUnitOfMeasure,
  getUnitOfMeasureBySymbol,
} from '../../uom/UnitOfMeasureRegistry.js'
import { getQuantityKind } from '../../uom/QuantityKindRegistry.js'
import { Log } from '../../util/Log.js'
import { isShapeList } from '../../shape/ShapeList.js'
const SUPPORTED_GEOMETRY_NODES = [
  'Point',
  'LineString',
  'LinearRing',
  'Curve',
  'Ring',
  'Polygon',
  'PolygonPatch',
  'Surface',
  'MultiGeometry',
  'MultiLineString',
  'MultiCurve',
  'MultiPoint',
  'MultiPolygon',
  'MultiSurface',
]
const SUPPORTED_CURVE_SEGMENTS = [
  'GeodesicString',
  'LineStringSegment',
  'Circle',
  'CircleByCenterPoint',
]
const NAMESPACE_GML = 'http://www.opengis.net/gml'
const DEFAULT_LENGTH_UNIT = getUnitOfMeasure('Meter')
const LENGTH_KIND = getQuantityKind('Length')
export class GMLGeometryParser {
  constructor(e) {
    this._failOnUnsupportedGeometry = e
  }
  isGeometryNode(e) {
    return this.isSupportedGMLGeometryType(e.firstElementChild)
  }
  isSupportedGMLGeometryType(e) {
    return !!e && !!this.getGeometryType(e)
  }
  getGeometryType(e) {
    if (
      !e ||
      !e.localName ||
      !e.namespaceURI ||
      e.namespaceURI.indexOf(NAMESPACE_GML) < 0
    )
      return null
    if (SUPPORTED_GEOMETRY_NODES.indexOf(e.localName) >= 0) return e.localName
    if (this._failOnUnsupportedGeometry)
      throw new ProgrammingError(
        `Could not decode unsupported geometry '${e.localName}'.`
      )
    return null
  }
  parseGeometryNode(e, t, n) {
    const r = this.getGeometryType(e)
    if (!r) return null
    if ('Point' === r) {
      const r = undefined
      return this.parseToPoints(e, t, n)[0]
    } else if ('LineString' === r) {
      const r = parseToPointCoordinates(e, t, n)
      return createPolyline(t, r)
    } else if ('LinearRing' === r) {
      const r = parseToPointCoordinates(e, t, n)
      return createPolygon(t, r)
    } else if ('Ring' === r) {
      const r = findChildElementWithName(e, 'curveMember')
      if (r && r.firstElementChild) {
        const e = undefined
        return processRing(this.parseGeometryNode(r.firstElementChild, t, n), t)
      }
    } else if ('Curve' === r) {
      const r = undefined
      const i = findChildCurveSegments(
        findChildElementWithName(e, 'segments'),
        t,
        n
      )
      return this.processCurve(i, t)
    } else if ('Surface' === r) {
      const r = findChildElementWithName(e, 'patches')
      const i = this.findChildGeometries(r, t, n)
      if (i.length > 0) return createShapeList(t, i)
    } else if ('Polygon' === r || 'PolygonPatch' === r) {
      let r = findChildElementWithName(e, 'exterior')
      if (!r || 0 === r.childNodes.length)
        r = findChildElementWithName(e, 'outerBoundaryIs')
      let i = findAllChildElementWithName(e, 'interior')
      if (0 === i.length) i = findAllChildElementWithName(e, 'innerBoundaryIs')
      const o = this.findChildGeometries(r, t, n)
      const s = this.findChildGeometries(i, t, n)
      if (o[0]) {
        if (s.length > 0) {
          const e = [o[0], ...s]
          return createComplexPolygon(t, e)
        }
        return o[0]
      }
    } else if (MULTI_GEOMETRY_TO_MEMBER[r]) {
      const i = MULTI_GEOMETRY_TO_MEMBER[r]
      const o = findAllChildElementWithName(e, i)
      const s = this.findChildGeometries(o, t, n)
      const l = findChildElementWithName(e, `${i}s`)
      const a = this.findChildGeometries(l, t, n)
      return createShapeList(t, [...s, ...a])
    }
    return null
  }
  processCurve(e, t) {
    const n = []
    const r = []
    function i() {
      if (n.length > 0) {
        r.push(createPolyline(t, n))
        n.length = 0
      }
    }
    for (let t = 0; t < e.length; t++) {
      const o = e[t]
      if (isCircle(o)) {
        i()
        r.push(o)
      } else n.push(...o.coordinates)
    }
    i()
    const o = r.length
    return 0 === o ? null : 1 === o ? r[0] : createShapeList(t, r)
  }
  findChildGeometries(e, t, n) {
    if (!e) return []
    if (isArray(e)) {
      const r = []
      e.forEach((e) => {
        r.push(...this.findNodeChildGeometries(e, t, n))
      })
      return r
    } else return this.findNodeChildGeometries(e, t, n)
  }
  findNodeChildGeometries(e, t, n) {
    const r = []
    if (e && e.childNodes) {
      const i = e.childNodes
      for (let e = 0; e < i.length; e++) {
        const o = i[e]
        if (this.isSupportedGMLGeometryType(o)) {
          const e = this.parseGeometryNode(o, t, n)
          if (e) r.push(e)
        }
      }
    }
    return r
  }
  parseToPoints(e, t, n) {
    const r = undefined
    return parseToPointCoordinates(e, t, n).map((e) => createPoint(t, e))
  }
}
const MULTI_GEOMETRY_TO_MEMBER = {
  MultiGeometry: 'geometryMember',
  MultiCurve: 'curveMember',
  MultiPoint: 'pointMember',
  MultiPolygon: 'polygonMember',
  MultiSurface: 'surfaceMember',
  MultiLineString: 'lineStringMember',
}
function findChildElementWithName(e, t) {
  const n = e.childNodes
  for (let e = 0; e < n.length; e++) {
    const r = n[e]
    if (r.localName === t) return r
  }
  return null
}
function findAllChildElementWithName(e, t) {
  const n = []
  const r = e.childNodes
  for (let e = 0; e < r.length; e++) {
    const i = r[e]
    if (i.localName === t) n.push(i)
  }
  return n
}
function getCurveSegmentType(e) {
  if (
    !e ||
    !e.localName ||
    !e.namespaceURI ||
    e.namespaceURI.indexOf(NAMESPACE_GML) < 0
  )
    return null
  return SUPPORTED_CURVE_SEGMENTS.indexOf(e.localName) >= 0 ? e.localName : null
}
function parseLengthType(e, t) {
  const n = findChildElementWithName(e, t)
  const r = parseFloat(n?.textContent ?? '0')
  if (r <= 0)
    throw new ProgrammingError(`Cannot parse circle geometry with radius 0`)
  const i = n?.getAttribute('uom')
  let o = DEFAULT_LENGTH_UNIT
  if (i) {
    try {
      o = getUnitOfMeasureBySymbol(i)
    } catch (e) {
      const t = e instanceof Error ? e.message : '' + e
      Log.warn(`${t}, defaulting to meter unit`)
    }
    if (!isLengthKind(o)) {
      o = DEFAULT_LENGTH_UNIT
      Log.warn(`Uom "${i}" is not of length kind, defaulting to meter unit`)
    }
  }
  return o.convertToUnit(r, DEFAULT_LENGTH_UNIT)
}
function isLengthKind(e) {
  return (
    !!e && !!e.quantityKind && e.quantityKind.baseQuantityKind === LENGTH_KIND
  )
}
function isSupportedCurveSegmentType(e) {
  return !!e && !!getCurveSegmentType(e)
}
function findChildCurveSegments(e, t, n) {
  const r = []
  if (e && e.childNodes) {
    const i = e.childNodes
    for (let e = 0; e < i.length; e++) {
      const o = i[e]
      if (isSupportedCurveSegmentType(o)) {
        const e = parseCurveSegmentNode(o, t, n)
        if (e) r.push(e)
      }
    }
  }
  return r
}
function parseCurveSegmentNode(e, t, n) {
  const r = getCurveSegmentType(e)
  const i = parseToPointCoordinates(e, t, n)
  if ('GeodesicString' === r || 'LineStringSegment' === r)
    return createPolyline(t, i)
  else if ('Circle' === r)
    if (i && i.length >= 3)
      return createCircleBy3Points(
        t,
        createPoint(t, i[0]),
        createPoint(t, i[1]),
        createPoint(t, i[2])
      )
    else throw new ProgrammingError(`Cannot parse circle geometry: ${e}`)
  else if ('CircleByCenterPoint' === r) {
    const n = parseLengthType(e, 'radius')
    if (i.length > 0 && n)
      return createCircleByCenterPoint(t, createPoint(t, i[0]), n)
    else throw new ProgrammingError(`Cannot parse circle geometry: ${e}`)
  }
  return null
}
function processRing(e, t) {
  const n = e && isShapeList(e) ? e.getShape(0) : e
  if (n) {
    if (isPolyline(n)) return createPolygon(t, n.coordinates)
    if (isCircle(n)) return createPolygon(t, n.interpolateToCoordinates())
  }
  return null
}
function parseToPointCoordinates(e, t, n) {
  const r = parseGeometryToPointCoordinates(e, t, n)
  if (!r || 0 === r.length)
    throw new ProgrammingError(
      `Could not find coordinates for the geometry: ${e.textContent}`
    )
  return r
}
