import { createPoint } from '../../shape/ShapeFactory.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { KMLAltitudeMode } from '../../util/kml/KMLAltitudeMode.js'
import { WGS84_AGL, WGS84_MSL } from '../../util/kml/KMLUtil.js'
export class KMLModelGeometry {
  constructor(t) {
    const e =
      t.altitudeMode === KMLAltitudeMode.RELATIVE_TO_GROUND
        ? WGS84_AGL
        : WGS84_MSL
    this._location = createPoint(e, [
      t.location.lon,
      t.location.lat,
      t.location.alt,
    ])
    this._id = t.id
    this._link = t.link
    this._resourceMap = t.resourceMap
    this._altitudeMode = t.altitudeMode
    this._orientation = t.orientation
    this._scale = t.scale
    this._mesh = null
  }
  get id() {
    return this._id
  }
  get location() {
    return this._location
  }
  get altitudeMode() {
    return this._altitudeMode
  }
  set altitudeMode(t) {
    if (this._altitudeMode === t) return
    const e = this._location.reference ?? WGS84_MSL
    const i = t === KMLAltitudeMode.RELATIVE_TO_GROUND ? WGS84_AGL : WGS84_MSL
    const o = createTransformation(e, i)
    this._location = o.transform(this._location)
    this._altitudeMode = t
  }
  get link() {
    return this._link
  }
  get resourceMap() {
    return this._resourceMap
  }
  get mesh() {
    return this._mesh
  }
  set mesh(t) {
    this._mesh = t
  }
  get orientation() {
    return this._orientation
  }
  set orientation(t) {
    this._orientation = t
  }
  get scale() {
    return this._scale
  }
  set scale(t) {
    this._scale = t
  }
}
