import { ReferenceType } from '../../../reference/ReferenceType.js'
import { isCircle } from '../../../shape/Circle.js'
import {
  ComplexPolygon,
  isComplexPolygon,
} from '../../../shape/ComplexPolygon.js'
import { EndCapStyle } from '../../../shape/EndCapStyle.js'
import { LLHCircularArcByCenterPoint } from '../../../shape/LLHCircularArcByCenterPoint.js'
import { isPoint } from '../../../shape/Point.js'
import { isPolygon } from '../../../shape/Polygon.js'
import { PolygonOrientation } from '../../../shape/PolygonOrientation.js'
import { isShapeList, ShapeList } from '../../../shape/ShapeList.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { simplePointCopy } from '../../../shape/SimplePoint.js'
import { XYZCircularArcByCenterPoint } from '../../../shape/XYZCircularArcByCenterPoint.js'
import { normalizeAngle } from '../../../util/Cartesian.js'
import { Constants } from '../../../util/Constants.js'
import { isPolyline } from '../../../shape/Polyline.js'
const EPSILON_REDUCTION = 1e-4
export class GeoBufferHelper {
  constructor(t) {
    this.REF = t
    this.MAX_DISCRETIZATION_POINTS = 64
    this.MIN_DISCRETIZATION_POINTS = 4
    this.MAX_RELATIVE_DISCRETIZATION_ERROR = 0.01
    this.LEFT = -1
    this.RIGHT = 1
    this.EPSILON = 1e-10
  }
  getBufferContour2D(t) {
    const e = this.getShapeContour(t.baseShape, t.width, t.endCapStyle)
    return this.removeDuplicatePointsInComplexPolygon(
      this.convertToComplexPolygon(e)
    )
  }
  discretizeShape(t) {
    if (ShapeType.contains(ShapeType.CIRCULAR_ARC, t.type))
      return this._discretizeCircularArc(t)
    if (isCircle(t)) return this._discretizeCircle(t)
    return null
  }
  debugContour(t) {
    const e = new ShapeList(this.REF, [])
    const i = t.baseShape
    const s = t.width
    let n
    let o
    let r
    if (isPoint(i)) {
      const t = this.createCircle(i, s)
      e.addShape(this.createPolygon(this.discretizeShape(t)))
    } else if (isPolyline(i)) {
      const n = this.removeExtraPoints(
        this._removeDuplicatePoints(this.getSimplePoints(i), false),
        false
      )
      const o = this._debugPointListContour(n, s, true, false)
      const r = this._debugPointListContour(n, s, false, false)
      this._copyIfShapeListSFCT(o, e)
      this._copyIfShapeListSFCT(r, e)
      if (i.pointCount > 1 && t.endCapStyle === EndCapStyle.CAP_ROUND) {
        const t = i.pointCount
        const n = this.roundCap(i.getPoint(1), i.getPoint(0), s)
        const o = this.roundCap(i.getPoint(t - 2), i.getPoint(t - 1), s)
        e.addShape(n)
        e.addShape(o)
      }
    } else if (isPolygon(i)) {
      r = this.cleanup(i)
      n = r.orientation === PolygonOrientation.CLOCKWISE
      o = this._debugPointListContour(r.getSimplePoints(), s, n, true)
      e.addShape(r)
      this._copyIfShapeListSFCT(o, e)
    }
    return e
  }
  cleanup(t) {
    const e = this.getSimplePoints(t)
    return this.createPolygon(
      this.removeExtraPoints(this._removeDuplicatePoints(e, true), true)
    )
  }
  offsetPointsLeft(t, e, i) {
    return this.offsetPoints(t, e, i, Math.PI / 2)
  }
  offsetPointsRight(t, e, i) {
    return this.offsetPoints(t, e, i, -Math.PI / 2)
  }
  offsetPoints(t, e, i, s) {
    const n = this.azimuth(t, e)
    const o = this.azimuth2angle(n) + s
    const r = undefined
    const h = undefined
    return [this.pointAtDistance(t, i, o), this.pointAtDistance(e, i, o)]
  }
  offsetPointsLeft2(t, e, i, s, n) {
    return this.offsetPoints2(t, e, i, s, n, Math.PI / 2)
  }
  offsetPointsRight2(t, e, i, s, n) {
    return this.offsetPoints2(t, e, i, s, n, -Math.PI / 2)
  }
  offsetPoints2(t, e, i, s, n, o) {
    return [i, s]
  }
  insideCornerPoint(t, e, i, s) {
    const n = this.turn(t, e, i)
    if (0 === n || 2 === n) return null
    const o = n === this.LEFT
    const r = o
      ? this.offsetPointsLeft(t, e, s)
      : this.offsetPointsRight(t, e, s)
    const h = o
      ? this.offsetPointsRight(i, e, s)
      : this.offsetPointsLeft(i, e, s)
    let l = 0
    while (l < r.length && this.distance(e, i, r[l]) > s) ++l
    let a = 0
    while (a < h.length && this.distance(e, t, h[a]) > s) ++a
    if (0 === l || 0 === a || l >= r.length || a >= h.length) return null
    const c = r[l - 1]
    const u = r[l]
    const p = h[a - 1]
    const f = h[a]
    return this.intersection(c, u, p, f)
  }
  segmentPoints(t, e, i, s, n, o) {
    const r = o ? this.LEFT : this.RIGHT
    const h = t && this.turn(t, e, i) === r
    const l = s && this.turn(e, i, s) === r
    const a = o
      ? this.offsetPointsLeft(e, i, n)
      : this.offsetPointsRight(e, i, n)
    let c = null
    if (h) c = this.insideCornerPoint(t, e, i, n)
    if (null == c) c = a[0]
    let u = null
    if (l) u = this.insideCornerPoint(e, i, s, n)
    if (null == u) u = a[a.length - 1]
    return o
      ? this.offsetPointsLeft2(e, i, c, u, n)
      : this.offsetPointsRight2(e, i, c, u, n)
  }
  roundCap(t, e, i) {
    const s = Constants.RAD2DEG * this.azimuth2angle(this.azimuth(e, t)) + 180
    const n = this.createPoint(e)
    const o =
      this.REF.TYPE === ReferenceType.GEODETIC
        ? new LLHCircularArcByCenterPoint(this.REF, n, i, s - 90, 180)
        : new XYZCircularArcByCenterPoint(this.REF, n, i, s - 90, 180)
    const r = this.discretizeShape(o)
    if (r) r.unshift(simplePointCopy(e))
    return this.cleanup(this.createPolygon(r))
  }
  cornerPoints(t, e, i, s, n) {
    let o = this.turn(t, e, i)
    let r = false
    if (2 === o) {
      o = s ? this.RIGHT : 0
      r = true
    }
    if (0 === o) return []
    const h = o === this.RIGHT
    const l =
      Constants.RAD2DEG *
      (this.azimuth2angle(this.azimuth(e, t)) +
        (h ? -Math.PI / 2 : Math.PI / 2))
    const a =
      Constants.RAD2DEG *
      (this.azimuth2angle(this.azimuth(e, i)) +
        (h ? Math.PI / 2 : -Math.PI / 2))
    const c = normalizeAngle(a - l + (r ? this.EPSILON : 0))
    const u = this.createCircularArc(e, n, l, c)
    return this.discretizeShape(u) || []
  }
  azimuth2angle(t) {
    let e = Math.PI / 2 - t
    if (e < 0) e += 2 * Math.PI
    return e
  }
  getAngleLimit() {
    return 180
  }
  _copyIfShapeListSFCT(t, e) {
    if (isShapeList(t))
      for (let i = 0; i < t.shapeCount; ++i) e.addShape(t.getShape(i))
  }
  getSimplePoints(t) {
    if (isPolyline(t) || isPolygon(t)) return t.getSimplePoints()
    return []
  }
  _debugPointListContour(t, e, i, s) {
    const n = new ShapeList(this.REF, [])
    const o = t.length - 1
    let r = 0
    let h
    let l
    while (r < o) {
      const s = []
      const o = this.contourSection(t, r, e, i, s)
      for (h = o; h >= r; --h) s.push(t[h])
      l = this.cleanup(this.createPolygon(s))
      if (l.pointCount > 1) n.addShape(l)
      r = o
    }
    if (s) {
      const s = t[0],
        r = t[o],
        l = i ? this.LEFT : this.RIGHT
      const a =
        this.turn(t[o - 1], r, s) === l
          ? []
          : this.cornerPoints(t[o - 1], r, s, i, e)
      const c =
        this.turn(r, s, t[1]) === l ? [] : this.cornerPoints(r, s, t[1], i, e)
      const u = i
        ? this.offsetPointsLeft(r, s, e)
        : this.offsetPointsRight(r, s, e)
      const p = []
      for (h = 0; h < a.length; ++h) p.push(a[h])
      for (h = 0; h < u.length; ++h) p.push(u[h])
      for (h = 0; h < c.length; ++h) p.push(c[h])
      p.push(s)
      p.push(r)
      n.addShape(this.cleanup(this.createPolygon(p)))
    }
    return n
  }
  getShapeContour(t, e, i) {
    const s = this.getConstructiveGeometry()
    let n = new ShapeList(this.REF, [])
    let o, r, h, l
    let a
    if (isPoint(t)) {
      o = this.createCircle(t, e)
      n = this.createPolygon(this.discretizeShape(o))
    } else if (isPolyline(t)) {
      const r = this.removeExtraPoints(
        this._removeDuplicatePoints(this.getSimplePoints(t), false),
        false
      )
      const h = this._contour(r, e, true, false)
      const l = this._contour(r, e, false, false)
      n = s.union([h, l])
      const a = r.length
      if (a > 1 && i === EndCapStyle.CAP_ROUND)
        if (2 === a && this._pointsEqual(r[0], r[1])) {
          o = this.createCircle(r[0], e)
          n = this.createPolygon(this.discretizeShape(o))
        } else {
          const t = this.roundCap(r[1], r[0], e)
          const i = this.roundCap(r[a - 2], r[a - 1], e)
          n = s.union([n, t, i])
        }
    } else if (isPolygon(t)) {
      r = this.cleanup(t)
      const i = this.getSimplePoints(r)
      l = r.pointCount <= 0 || r.orientation === PolygonOrientation.CLOCKWISE
      h = this._contour(i, e, l, true)
      n = s.union([r, h])
    } else if (isShapeList(t)) {
      const o = t
      n = o
      for (a = 0; a < o.shapeCount; a++) {
        const t = o.getShape(a)
        h = this.getShapeContour(t, e, i)
        n = s.union([n, h])
      }
    } else if (
      t.type === ShapeType.CIRCLE ||
      t.type === ShapeType.CIRCULAR_ARC
    ) {
      r = this.createPolygon(this.discretizeShape(t))
      n = this.getShapeContour(r, e, i)
    }
    return n
  }
  _contour(t, e, i, s) {
    const n = this.getConstructiveGeometry()
    let o = new ShapeList(this.REF, [])
    const r = t.length - 1
    let h = 0
    let l, a
    while (h < r) {
      const s = []
      const r = this.contourSection(t, h, e, i, s)
      for (l = r; l >= h; l--) s.push(t[l])
      a = this.cleanup(this.createPolygon(s))
      if (a.pointCount > 1) o = n.union([o, a])
      h = r
    }
    if (s && t.length > 0) {
      const s = t[0]
      const h = t[r]
      const c = i ? this.LEFT : this.RIGHT
      const u =
        this.turn(t[r - 1], h, s) === c
          ? []
          : this.cornerPoints(t[r - 1], h, s, i, e)
      const p =
        this.turn(h, s, t[1]) === c ? [] : this.cornerPoints(h, s, t[1], i, e)
      const f = this.segmentPoints(t[r - 1], h, s, t[1], e, i)
      const P = []
      for (l = 0; l < u.length; l++) P.push(u[l])
      for (l = 0; l < f.length; l++) P.push(f[l])
      for (l = 0; l < p.length; l++) P.push(p[l])
      P.push(s)
      P.push(h)
      a = this.cleanup(this.createPolygon(P))
      o = n.union([o, a])
    }
    return o
  }
  contourSection(t, e, i, s, n) {
    const o = t.length - 1
    if (e >= o) return e
    const r = s ? this.LEFT : this.RIGHT
    let h = 0
    const l = this.createBounds(t[e])
    let a = e
    while (a <= o - 1) {
      const c = a === e ? null : t[a - 1]
      const u = t[a]
      const p = t[a + 1]
      const f = a >= o - 1 ? null : t[a + 2]
      l.includeCoordinate2D(p.x, p.y)
      if (c) {
        if (this.boundsTooLarge(l)) return a
        h += this.angle(c, u, p)
        if (Math.abs(h) >= this.getAngleLimit()) return a
        if (this.turn(c, u, p) === r) return a
      }
      const P = this.segmentPoints(c, u, p, f, i, s)
      for (let t = 0; t < P.length; ++t) n.push(P[t])
      if (f && this.turn(u, p, f) !== r) {
        const t = this.cornerPoints(u, p, f, s, i)
        for (let e = 0; e < t.length; ++e) n.push(t[e])
      }
      a++
    }
    return a
  }
  _discretizeCircularArc(t) {
    const e = t.startAzimuth * Constants.DEG2RAD,
      i = t.sweepAngle * Constants.DEG2RAD
    return this.discretizeCircularArc(t.center, t.radius, e, i)
  }
  _discretizeCircle(t) {
    const e = this.discretizeCircularArc(t.center, t.radius, 0, 2 * Math.PI)
    e.pop()
    return e
  }
  removeExtraPoints(t, e) {
    const i = this.getTopologyUtil()
    let s, n, o
    const r = []
    let h = t.length
    if (0 === h) return r
    r[0] = t[0]
    const l = e ? h : h - 1
    for (let e = 1; e < l; ++e) r.push(t[e])
    if (e) {
      while (r.length > 2) {
        h = r.length
        n = r[h - 2]
        s = r[h - 1]
        o = r[0]
        const t = Math.abs(this.angle(n, s, o))
        if (
          Math.abs(t - 180) <= this.EPSILON &&
          i.isPointOnLineSegment(this.createPoint(n), s, o)
        )
          r.pop()
        else break
      }
      while (r.length > 2) {
        n = r[r.length - 1]
        s = r[0]
        o = r[1]
        const t = Math.abs(this.angle(n, s, o))
        if (
          Math.abs(t - 180) <= this.EPSILON &&
          i.isPointOnLineSegment(this.createPoint(n), s, o)
        )
          r.shift()
        else break
      }
      if (r.length < 3) return []
    } else r.push(t[t.length - 1])
    return r
  }
  convertToComplexPolygon(t) {
    if (isComplexPolygon(t)) return t
    const e = new ComplexPolygon(this.REF, [])
    if (isPolygon(t)) e.addPolygon(t)
    else if (isShapeList(t)) {
      const i = t
      for (let t = 0; t < i.shapeCount; ++t) {
        const s = this.convertToComplexPolygon(i.getShape(t))
        for (let t = 0; t < s.polygonCount; ++t) e.addPolygon(s.getPolygon(t))
      }
    }
    return e
  }
  removeDuplicatePointsInComplexPolygon(t) {
    const e = new ComplexPolygon(this.REF, [])
    for (let i = 0; i < t.polygonCount; i++) {
      const s = this.getSimplePoints(t.getPolygon(i))
      e.addPolygon(this.createPolygon(this._removeDuplicatePoints(s, true)))
    }
    return e
  }
  _removeDuplicatePoints(t, e) {
    const i = []
    const s = t.length
    if (0 === s) return i
    i.push(t[0])
    for (let e = 1; e < s; ++e) {
      const s = t[e]
      if (!this._pointsEqual(i[i.length - 1], s)) i.push(s)
    }
    if (e && this._pointsEqual(i[0], i[i.length - 1])) i.pop()
    return i
  }
  _pointsEqual(t, e) {
    return this.distance(t, e) <= EPSILON_REDUCTION
  }
}
