import { QueryProvider } from '../QueryProvider.js'
import { ScaleLevelCursor } from './ScaleLevelCursor.js'
import { ScaleZoneUtil } from './ScaleZoneUtil.js'
const BOUNDS_DIFFERENCE_RATE = 0.07
const MAX_FEATURE_BOUNDS_DIFFERENCE_THRESHOLD = 0.2
export class LoadSpatiallySupport {
  constructor() {}
  multiScaleShouldQuery(e, t, r, n) {
    if (!this._queryParams) return true
    const o = this._queryParams
    const s = this.getParamsByScaleLevel(e, t, r)
    if (s.length !== o.length) return true
    let u = false
    for (let e = 0; e < s.length; e++) {
      const t = o[e]
      const r = s[e]
      if (t.level !== r.level) u = true
      else if (!t.bounds.contains2DBounds(r.bounds)) {
        r.bounds.setTo2DUnion(t.bounds)
        const e = undefined
        if (
          getBoundsAreaDifferenceFactor(r.bounds, t.bounds) >
          BOUNDS_DIFFERENCE_RATE
        )
          u = true
      }
      if (u) break
    }
    if (!u && n && 1 === s.length) {
      const e = getBoundsAreaDifferenceFactor(o[0].bounds, s[0].bounds)
      u = shouldQueryBecauseOfMaxFeatures(s[0].query, n, e)
    }
    return u
  }
  multiScaleQueryModel(e, t, r) {
    const n = e.model
    const o = this.getParamsByScaleLevel(e, t, r)
    this._queryParams = o
    return this.executeQuery(n.store, o)
  }
  executeQuery(e, t) {
    const r = t.map((t) => {
      const r = e.spatialQuery(t.bounds, t.query)
      return Promise.resolve(r)
    })
    return Promise.all(r).then((e) => new ScaleLevelCursor(e))
  }
  getParamsByScaleLevel(e, t, r) {
    const n = e.map
    const o = r.getQueryLevelScales(e, n)
    const s = getScaleRanges(e.scaleRange, o)
    const u = ScaleZoneUtil.getScaleZones(n, s, t)
    const a = []
    u.forEach((e) => {
      let { level: t, bounds: n } = e
      if (n) {
        const e = r.getQueryForLevel(t)
        if (e !== QueryProvider.QUERY_NONE)
          a.push({ level: t, query: e, bounds: n })
      }
    })
    return a
  }
}
function getScaleRanges(e, t) {
  const r = Math.max(e.min, 0)
  const n = Math.min(e.max, 1)
  if (0 === t.length) return [r, n]
  const o = t.filter((e) => e > r && e < n)
  return [r].concat(o).concat([n])
}
export function getBoundsAreaDifferenceFactor(e, t) {
  const r = e.width * e.height
  const n = t.width * t.height
  return n ? r / n - 1 : 0
}
export function shouldQueryBecauseOfMaxFeatures(e, t, r) {
  const n = e?.maxFeatures
  if (n) {
    if (r >= 0 && t < n) return false
    if (Math.abs(r) > MAX_FEATURE_BOUNDS_DIFFERENCE_THRESHOLD) return true
  }
  return false
}
