import { Log } from '../../../util/Log.js'
import { isUndefined } from '../../../util/Lang.js'
import { Quaternion } from './Quaternion.js'
export class Vector3 {
  constructor(t, s, i) {
    this._x = t || 0
    this._y = s || 0
    this._z = i || 0
  }
  get z() {
    return this._z
  }
  get y() {
    return this._y
  }
  get x() {
    return this._x
  }
  set z(t) {
    this._z = t
  }
  set y(t) {
    this._y = t
  }
  set x(t) {
    this._x = t
  }
  set(t, s, i) {
    this._x = t
    this._y = s
    this._z = i
    return this
  }
  setScalar(t) {
    this._x = t
    this._y = t
    this._z = t
    return this
  }
  setX(t) {
    this._x = t
    return this
  }
  setY(t) {
    this._y = t
    return this
  }
  setZ(t) {
    this._z = t
    return this
  }
  setComponent(t, s) {
    switch (t) {
      case 0:
        this._x = s
        break
      case 1:
        this._y = s
        break
      case 2:
        this._z = s
        break
      default:
        throw new Error(`index is out of range: ${t}`)
    }
  }
  getComponent(t) {
    switch (t) {
      case 0:
        return this._x
      case 1:
        return this._y
      case 2:
        return this._z
      default:
        throw new Error(`index is out of range: ${t}`)
    }
  }
  clone() {
    return new Vector3(this._x, this._y, this._z)
  }
  copy(t) {
    this._x = t.x
    this._y = t.y
    this._z = t.z
    return this
  }
  add(t, s) {
    if (!isUndefined(s)) {
      Log.warn(
        'Vector3: .add() now only accepts one argument. Use .addVectors( a, b ) instead.'
      )
      return this.addVectors(t, s)
    }
    this._x += t._x
    this._y += t._y
    this._z += t._z
    return this
  }
  addScalar(t) {
    this._x += t
    this._y += t
    this._z += t
    return this
  }
  addVectors(t, s) {
    this._x = t.x + s.x
    this._y = t.y + s.y
    this._z = t.z + s.z
    return this
  }
  addScaledVector(t, s) {
    this._x += t.x * s
    this._y += t.y * s
    this._z += t.z * s
    return this
  }
  sub(t, s) {
    if (void 0 !== s) {
      Log.warn(
        'Vector3: .sub() now only accepts one argument. Use .subVectors( a, b ) instead.'
      )
      return this.subVectors(t, s)
    }
    this._x -= t._x
    this._y -= t._y
    this._z -= t._z
    return this
  }
  subScalar(t) {
    this._x -= t
    this._y -= t
    this._z -= t
    return this
  }
  subVectors(t, s) {
    this._x = t.x - s.x
    this._y = t.y - s.y
    this._z = t.z - s.z
    return this
  }
  multiply(t, s) {
    if (!isUndefined(s)) {
      Log.warn(
        'Vector3: .multiply() now only accepts one argument. Use .multiplyVectors( a, b ) instead.'
      )
      return this.multiplyVectors(t, s)
    }
    this._x *= t._x
    this._y *= t._y
    this._z *= t._z
    return this
  }
  multiplyScalar(t) {
    if (isFinite(t)) {
      this._x *= t
      this._y *= t
      this._z *= t
    } else {
      this._x = 0
      this._y = 0
      this._z = 0
    }
    return this
  }
  multiplyVectors(t, s) {
    this._x = t.x * s.x
    this._y = t.y * s.y
    this._z = t.z * s.z
    return this
  }
  applyMatrix3(t) {
    const s = this._x
    const i = this._y
    const h = this._z
    const r = t.elements
    this._x = r[0] * s + r[3] * i + r[6] * h
    this._y = r[1] * s + r[4] * i + r[7] * h
    this._z = r[2] * s + r[5] * i + r[8] * h
    return this
  }
  applyMatrix4(t) {
    const s = this._x,
      i = this._y,
      h = this._z
    const r = t.elements
    this._x = r[0] * s + r[4] * i + r[8] * h + r[12]
    this._y = r[1] * s + r[5] * i + r[9] * h + r[13]
    this._z = r[2] * s + r[6] * i + r[10] * h + r[14]
    return this
  }
  applyProjection(t) {
    const s = this._x,
      i = this._y,
      h = this._z
    const r = t.elements
    const e = 1 / (r[3] * s + r[7] * i + r[11] * h + r[15])
    this._x = (r[0] * s + r[4] * i + r[8] * h + r[12]) * e
    this._y = (r[1] * s + r[5] * i + r[9] * h + r[13]) * e
    this._z = (r[2] * s + r[6] * i + r[10] * h + r[14]) * e
    return this
  }
  applyQuaternion(t) {
    const s = this._x
    const i = this._y
    const h = this._z
    const r = t.x
    const e = t.y
    const n = t.z
    const _ = t.w
    const o = _ * s + e * h - n * i
    const a = _ * i + n * s - r * h
    const c = _ * h + r * i - e * s
    const u = -r * s - e * i - n * h
    this._x = o * _ + u * -r + a * -n - c * -e
    this._y = a * _ + u * -e + c * -r - o * -n
    this._z = c * _ + u * -n + o * -e - a * -r
    return this
  }
  transformDirection(t) {
    const s = this._x,
      i = this._y,
      h = this._z
    const r = t.elements
    this._x = r[0] * s + r[4] * i + r[8] * h
    this._y = r[1] * s + r[5] * i + r[9] * h
    this._z = r[2] * s + r[6] * i + r[10] * h
    this.normalize()
    return this
  }
  divide(t) {
    this._x /= t._x
    this._y /= t._y
    this._z /= t._z
    return this
  }
  divideScalar(t) {
    return this.multiplyScalar(1 / t)
  }
  min(t) {
    this._x = Math.min(this._x, t._x)
    this._y = Math.min(this._y, t._y)
    this._z = Math.min(this._z, t._z)
    return this
  }
  max(t) {
    this._x = Math.max(this._x, t._x)
    this._y = Math.max(this._y, t._y)
    this._z = Math.max(this._z, t._z)
    return this
  }
  clamp(t, s) {
    this._x = Math.max(t._x, Math.min(s._x, this._x))
    this._y = Math.max(t._y, Math.min(s._y, this._y))
    this._z = Math.max(t._z, Math.min(s._z, this._z))
    return this
  }
  clampLength(t, s) {
    const i = this.length()
    this.multiplyScalar(Math.max(t, Math.min(s, i)) / i)
    return this
  }
  floor() {
    this._x = Math.floor(this._x)
    this._y = Math.floor(this._y)
    this._z = Math.floor(this._z)
    return this
  }
  ceil() {
    this._x = Math.ceil(this._x)
    this._y = Math.ceil(this._y)
    this._z = Math.ceil(this._z)
    return this
  }
  round() {
    this._x = Math.round(this._x)
    this._y = Math.round(this._y)
    this._z = Math.round(this._z)
    return this
  }
  roundToZero() {
    this._x = this._x < 0 ? Math.ceil(this._x) : Math.floor(this._x)
    this._y = this._y < 0 ? Math.ceil(this._y) : Math.floor(this._y)
    this._z = this._z < 0 ? Math.ceil(this._z) : Math.floor(this._z)
    return this
  }
  negate() {
    this._x = -this._x
    this._y = -this._y
    this._z = -this._z
    return this
  }
  dot(t) {
    return this._x * t._x + this._y * t._y + this._z * t._z
  }
  lengthSq() {
    return this._x * this._x + this._y * this._y + this._z * this._z
  }
  length() {
    return Math.sqrt(this._x * this._x + this._y * this._y + this._z * this._z)
  }
  lengthManhattan() {
    return Math.abs(this._x) + Math.abs(this._y) + Math.abs(this._z)
  }
  normalize() {
    return this.divideScalar(this.length())
  }
  setLength(t) {
    return this.multiplyScalar(t / this.length())
  }
  lerp(t, s) {
    this._x += (t._x - this._x) * s
    this._y += (t._y - this._y) * s
    this._z += (t._z - this._z) * s
    return this
  }
  lerpVectors(t, s, i) {
    this.subVectors(s, t).multiplyScalar(i).add(t)
    return this
  }
  cross(t, s) {
    if (!isUndefined(s)) {
      Log.warn(
        'Vector3: .cross() now only accepts one argument. Use .crossVectors( a, b ) instead.'
      )
      return this.crossVectors(t, s)
    }
    const i = this._x,
      h = this._y,
      r = this._z
    this._x = h * t._z - r * t._y
    this._y = r * t._x - i * t._z
    this._z = i * t._y - h * t._x
    return this
  }
  crossVectors(t, s) {
    const i = t._x,
      h = t._y,
      r = t._z
    const e = s._x,
      n = s._y,
      _ = s._z
    this._x = h * _ - r * n
    this._y = r * e - i * _
    this._z = i * n - h * e
    return this
  }
  angleTo(t) {
    const s = this.dot(t) / Math.sqrt(this.lengthSq() * t.lengthSq())
    return Math.acos(clamp(s, -1, 1))
  }
  distanceTo(t) {
    return Math.sqrt(this.distanceToSquared(t))
  }
  distanceToSquared(t) {
    const s = this._x - t._x
    const i = this._y - t._y
    const h = this._z - t._z
    return s * s + i * i + h * h
  }
  setFromSpherical(t) {
    const s = Math.sin(t.phi) * t.radius
    this._x = s * Math.sin(t.theta)
    this._y = Math.cos(t.phi) * t.radius
    this._z = s * Math.cos(t.theta)
    return this
  }
  setFromMatrixPosition(t) {
    return this.setFromMatrixColumn(t, 3)
  }
  setFromMatrixScale(t) {
    const s = this.setFromMatrixColumn(t, 0).length()
    const i = this.setFromMatrixColumn(t, 1).length()
    const h = this.setFromMatrixColumn(t, 2).length()
    this._x = s
    this._y = i
    this._z = h
    return this
  }
  setFromMatrixColumn(t, s) {
    if ('number' === typeof t) {
      Log.warn('Vector3: setFromMatrixColumn now expects ( matrix, index ).')
      t = arguments[1]
      s = arguments[0]
    }
    return this.fromArray(t.elements, 4 * s)
  }
  equals(t) {
    return t._x === this._x && t._y === this._y && t._z === this._z
  }
  fromArray(t, s) {
    if (isUndefined(s)) s = 0
    this._x = t[s]
    this._y = t[s + 1]
    this._z = t[s + 2]
    return this
  }
  toArray(t, s) {
    if (isUndefined(t)) t = []
    if (isUndefined(s)) s = 0
    t[s] = this._x
    t[s + 1] = this._y
    t[s + 2] = this._z
    return t
  }
  fromAttribute(t, s, i) {
    if (isUndefined(i)) i = 0
    s = s * t.itemSize + i
    this._x = t.array[s]
    this._y = t.array[s + 1]
    this._z = t.array[s + 2]
    return this
  }
  applyAxisAngle = (() => {
    let t
    return (s, i) => {
      if (isUndefined(t)) t = new Quaternion()
      this.applyQuaternion(t.setFromAxisAngle(s, i))
      return this
    }
  })()
  clampScalar = (() => {
    let t
    let s
    return (i, h) => {
      if (isUndefined(t)) t = new Vector3()
      t.set(i, i, i)
      s.set(h, h, h)
      return this.clamp(t, s)
    }
  })()
  projectOnVector = (() => {
    let t
    let s
    return (i) => {
      if (isUndefined(t)) t = new Vector3()
      t.copy(i).normalize()
      s = this.dot(t)
      return this.copy(t).multiplyScalar(s)
    }
  })()
  projectOnPlane = (() => {
    let t
    return (s) => {
      if (isUndefined(t)) t = new Vector3()
      t.copy(this).projectOnVector(s)
      return this.sub(t)
    }
  })()
  reflect = (() => {
    let t
    return (s) => {
      if (isUndefined(t)) t = new Vector3()
      return this.sub(t.copy(s).multiplyScalar(2 * this.dot(s)))
    }
  })()
}
function clamp(t, s, i) {
  return t <= s ? s : t >= i ? i : t
}
