const TYPE = 'QUANTITYKIND'
export class QuantityKind {
  constructor(t, n) {
    let e, i, a
    this._name = t
    if (n) {
      if (n.TYPE !== TYPE)
        throw new Error('QuantityKind::constructor - invalid generalization')
      i = n
      e = n
      while (e.baseQuantityKind !== e) e = e.baseQuantityKind
      a = e
    } else {
      i = null
      a = this
    }
    this._baseQuantityKind = a
    this._generalization = i
  }
  get TYPE() {
    return TYPE
  }
  get name() {
    return this._name
  }
  get baseQuantityKind() {
    return this._baseQuantityKind
  }
  get generalization() {
    return this._generalization
  }
  isSubTypeOf(t) {
    let n = this.generalization
    while (null !== n) {
      if (t.name === n.name) return true
      n = n.generalization
    }
    return false
  }
}
