import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { LineType } from '../../../geodesy/LineType.js'
import { EllipsoidalEquidistantCylindrical } from '../../../projection/EllipsoidalEquidistantCylindrical.js'
import { EquidistantCylindrical } from '../../../projection/EquidistantCylindrical.js'
import { GeocentricReference } from '../../../reference/GeocentricReference.js'
import { GridReference } from '../../../reference/GridReference.js'
import { getReference } from '../../../reference/ReferenceProvider.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { ComplexPolygon } from '../../../shape/ComplexPolygon.js'
import { LLHPoint } from '../../../shape/LLHPoint.js'
import { Polygon } from '../../../shape/Polygon.js'
import { createPoint, createPolygon } from '../../../shape/ShapeFactory.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { ShapeUtil } from '../../../shape/ShapeUtil.js'
import { IdentityTransformation } from '../../../transformation/IdentityTransformation.js'
import { getTransformation } from '../../../util/CacheUtil.js'
import {
  clamp,
  distance3D,
  flipOrientationSFCT_flat,
  orientation2D_flat,
} from '../../../util/Cartesian.js'
import { isBoolean, isUndefined } from '../../../util/Lang.js'
import { Log } from '../../../util/Log.js'
import { normalizeLon } from '../../../util/LonLatCoord.js'
import { CartesianPen } from '../generalpath/CartesianPen.js'
import { GeneralPath } from '../generalpath/GeneralPath.js'
import { GeodeticPen } from '../generalpath/GeodeticPen.js'
import { GeometryBuilder } from '../generalpath/GeometryBuilder.js'
import { DEFAULT_THRESHOLD, PenThreshold } from '../generalpath/PenThreshold.js'
import { WorldBoundaryUtil } from './WorldBoundaryUtil.js'
import { SimpleXYZPoint } from '../../../shape/SimpleXYZPoint.js'
const THRESHOLD_ARC = new PenThreshold(5e5, 5)
const GRID_REF = getReference('EPSG:32662')
const generalPathSFCT = new GeneralPath()
const temporaryPoint1 = new SimpleXYZPoint(null, 0, 0, 0)
const temporaryPoint2 = new SimpleXYZPoint(null, 0, 0, 0)
const GEODETIC_PEN = new GeodeticPen()
const CARTESIAN_PEN = new CartesianPen()
function getPen(e) {
  return e.referenceType === ReferenceType.GEODETIC
    ? GEODETIC_PEN
    : CARTESIAN_PEN
}
function validateLine(e) {
  if (e.type !== ShapeType.POLYLINE)
    throw new ProgrammingError(
      'GeoDiscretizer:: the outline may only contains lines'
    )
  return e.slice()
}
function validatePoly(e) {
  if (e.type !== ShapeType.POLYGON && e.type !== ShapeType.COMPLEX_POLYGON)
    throw new ProgrammingError(
      `GeoDiscretizer:: the fill may only contains polygons: ${e.type}`
    )
  let t
  if (isPolygonPath(e)) t = [e]
  else {
    t = e.slice()
    ShapeUtil.fixOrientationSFCT_flat(t)
  }
  return t
}
function discretizePointFast(e, t, n) {
  try {
    return t.transform(e, n[0])
  } catch (e) {
    OutOfBoundsError.isOrThrow(e)
    return null
  }
}
function discretizeGridLineFast(e, t, n, r, o, i) {
  o = Math.max(2, Math.round(o))
  const s = new Array(3 * o)
  const a = { x: 0, y: 0, z: 0 }
  const l = { x: 0, y: 0, z: 0 }
  for (let c = 0; c < o; c++) {
    a.x = e + (n - e) * (c / (o - 1))
    a.y = t + (r - t) * (c / (o - 1))
    i.transform(a, l)
    const p = 3 * c
    s[p] = l.x
    s[p + 1] = l.y
    s[p + 2] = l.z
  }
  return { lines: [s] }
}
function discretizeGridLineSlow(e, t, n, r, o, i, s, a, l, c, p) {
  let f
  if (o) {
    const o = t + (r - t) / 2
    let i
    if (e >= n) i = normalizeLon(e + (360 + n - e) / 2)
    else i = e + (n - e) / 2
    f = [
      new LLHPoint(a, [e, t]),
      new LLHPoint(a, [i, o]),
      new LLHPoint(a, [n, r]),
    ]
  } else f = [new LLHPoint(a, [e, t]), new LLHPoint(a, [n, r])]
  generalPathSFCT.reset()
  i.appendLine(f, s, a, l, generalPathSFCT, true, LineType.CONSTANT_BEARING, p)
  if (generalPathSFCT.subPathCount() < 1) return null
  const d = GeometryBuilder.buildStrokeGeometry(generalPathSFCT)
  let y = []
  if (d.type === ShapeType.POLYLINE) y = [d]
  else if (d.type === ShapeType.SHAPE_LIST) y = d.map(validateLine)
  return { lines: y }
}
function addDecorations(e, t, n) {
  let r
  if (t) e[0].beginDecoration = t
  if (n) {
    r = e.length - 1
    e[r].endDecoration = n
  }
}
function convertLinePathsToVertexArrays(e) {
  let t
  const n = GeometryBuilder.buildStrokeGeometry(e)
  if (isPolylinePath(n)) t = { lines: [n] }
  else if (n.type === ShapeType.SHAPE_LIST) t = { lines: n.map(validateLine) }
  else
    throw new ProgrammingError(
      `GeoDiscretizer::discretize line - does not recognize line-type. Must be either Polyline or ShapeList. ${n.type}`
    )
  return t
}
function isPolylinePath(e) {
  return e.type === ShapeType.POLYLINE
}
function isPolygonPath(e) {
  return e.type === ShapeType.POLYGON
}
function isComplexPolygonPath(e) {
  return e.type === ShapeType.COMPLEX_POLYGON
}
function convertFillAndOutlinePathsToVertexArrays(e, t, n, r) {
  const o = GeometryBuilder.buildFillGeometry(e)
  const i = GeometryBuilder.buildStrokeGeometry(t)
  const s = {}
  let a
  let l
  let c
  let p
  let f
  let d
  let y
  let u
  if (isPolylinePath(i)) {
    const e = r ? h(i) : i.slice()
    s.lines = [e]
    l = ShapeType.POLYLINE
    c = e[0]
    p = e[1]
    f = e.length
  } else if (i.type === ShapeType.SHAPE_LIST) {
    if (o.type === ShapeType.COMPLEX_POLYGON) s.lines = r ? P(i) : i.map(m)
    else s.lines = r ? i.map(h) : i.map(m)
    l = ShapeType.SHAPE_LIST
  } else
    throw new ProgrammingError(
      `GeoDiscretizer::does not recognize line-type. Must be either Polyline or ShapeList. ${i.type}`
    )
  if (isPolygonPath(o)) {
    s.fills = [[o]]
    a = ShapeType.POLYGON
    d = o[o.length - 3]
    y = o[o.length - 2]
    u = o.length
  } else if (isComplexPolygonPath(o)) {
    s.fills = [r ? P(o) : o.slice()]
    a = ShapeType.COMPLEX_POLYGON
  } else if (o.type === ShapeType.SHAPE_LIST) {
    s.fills = o.map(validatePoly)
    a = ShapeType.SHAPE_LIST
  } else
    throw new ProgrammingError(
      `GeoDiscretizer::does not recognize polygon-type. Must be either Polygon or GeometryCollection. ${o.type}`
    )
  s.canUseFillForStroke =
    a === ShapeType.POLYGON &&
    l === ShapeType.POLYLINE &&
    f === u &&
    p === y &&
    c === d
  return s
  function h(e) {
    return T(e, true)
  }
  function m(e) {
    return e.slice()
  }
  function P(e) {
    const t = []
    for (let n = 0; n < e.length; n++) {
      const r = e[n]
      const o = 0 === n
      t.push(T(r, o))
    }
    return t
  }
  function T(e, t) {
    const n = e.slice()
    const r = S(e)
    if (e.length && r !== t) flipOrientationSFCT_flat(n)
    return n
  }
  function S(e) {
    if (n.referenceType !== ReferenceType.GEOCENTRIC)
      return !orientation2D_flat(e)
    const t = createPoint(n, [0, 0, 0])
    const r = createPoint(GRID_REF, [0, 0, 0])
    const o = getTransformation(n, GRID_REF)
    const i = []
    const s = e.length / 3
    for (let n = 0, a; n < s; n += 3) {
      a = 3 * n
      t.move3DToCoordinates(e[a], e[a + 1], e[a + 2])
      o.transform(t, r)
      i.push(r.x)
      i.push(r.y)
      i.push(0)
    }
    return !orientation2D_flat(i)
  }
}
function fixForWorldBoundary(e, t, n, r) {
  const o = undefined
  return WorldBoundaryUtil.getInstance(r).fixPathForFillAndStroke(e, t, n)
}
function discretizePoint(e, t, n) {
  const { transformation: r, coordinatesPool: o } = n
  let i
  try {
    const e = o && o.length >= 1 ? o[0] : void 0
    i = [r.transform(t, e)]
  } catch (e) {
    i = null
  }
  return { points: i }
}
function discretizeComposite(e, t, n) {
  const r = { fills: [], lines: [], points: [] }
  ShapeUtil.forEachShapeInShapeList(t, (e) => {
    let t
    try {
      t = GeoDiscretizerStatic.discretize(e, n) || {}
      if (t.fills) r.fills = r.fills.concat(t.fills)
      if (t.lines) r.lines = r.lines.concat(t.lines)
      if (t.points) r.points = r.points.concat(t.points)
    } catch (e) {
      Log.error(
        'GeoDiscretizer:: could not discretize geometry',
        e instanceof Error ? e : void 0
      )
    }
  })
  return r
}
function attemptSimplePointListDiscretization(e, t, n, r) {
  if (t instanceof IdentityTransformation) return e.getCoordinateArray(n)
  try {
    const o = temporaryPoint2
    const i = e.pointCount + (n ? 1 : 0)
    const s = new Array(3 * i)
    for (let n = 0; n < i; n++) {
      e.getSimplePointSFCT(n % e.pointCount, temporaryPoint1)
      t.transform(temporaryPoint1, temporaryPoint1)
      const i = 3 * n
      s[i] = temporaryPoint1.x
      s[i + 1] = temporaryPoint1.y
      s[i + 2] = temporaryPoint1.z
      if (0 != n) {
        const e = undefined
        if (distance3D(o, temporaryPoint1) > r / 2) return null
      }
      o.move3DToCoordinates(
        temporaryPoint1.x,
        temporaryPoint1.y,
        temporaryPoint1.z
      )
    }
    return s
  } catch (e) {
    return null
  }
}
function attemptSimplePointListsDiscretization(e, t, n, r) {
  const o = e.map((e) => attemptSimplePointListDiscretization(e, t, n, r))
  return o.indexOf(null) >= 0 ? null : o
}
function discretizeLine(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
    beginDecoration: l,
    endDecoration: c,
    coordinatesPool: p,
    fraction: f,
  } = n
  if (n.noDiscretization && !f) {
    const e = attemptSimplePointListsDiscretization(
      [t],
      r,
      false,
      Number.MAX_VALUE
    )
    if (e) return { lines: e }
  }
  const d = t.pointCount
  generalPathSFCT.reset()
  if (0 === d) return { lines: [] }
  else if (1 === d) {
    const n = discretizePoint(e, t.getPoint(0), {
      transformation: r,
      coordinatesPool: p,
    })
    if (n && n.points && n.points.length > 0) {
      const { x: e, y: t, z: r } = n.points[0]
      return { lines: [[e, t, r]] }
    } else return { lines: [] }
  }
  const y = t.getSimplePoints()
  e.appendLine(y, r, o, i, generalPathSFCT, true, s, a, f)
  if (0 === generalPathSFCT.subPathCount()) return { lines: [] }
  const u = convertLinePathsToVertexArrays(generalPathSFCT)
  addDecorations(u.lines, l, c)
  if (generalPathSFCT.fractions) u.fractions = [generalPathSFCT.fractions]
  return u
}
function discretizeCircularArc(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
    beginDecoration: l,
    endDecoration: c,
  } = n
  generalPathSFCT.reset()
  e.appendArc(
    t.center,
    t.radius,
    t.radius,
    0,
    t.startAzimuth,
    t.sweepAngle,
    r,
    o,
    i,
    generalPathSFCT,
    false,
    s,
    a
  )
  if (0 === generalPathSFCT.subPathCount()) return { lines: [] }
  const p = convertLinePathsToVertexArrays(generalPathSFCT)
  addDecorations(p.lines, l, c)
  return p
}
function discretizeArc(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    beginDecoration: a,
    endDecoration: l,
  } = n
  generalPathSFCT.reset()
  e.appendArc(
    t.center,
    t.a,
    t.b,
    t.rotationAzimuth,
    t.startAzimuth,
    t.sweepAngle,
    r,
    o,
    i,
    generalPathSFCT,
    false,
    s,
    THRESHOLD_ARC
  )
  if (0 === generalPathSFCT.subPathCount()) return { lines: [] }
  const c = convertLinePathsToVertexArrays(generalPathSFCT)
  addDecorations(c.lines, a, l)
  return c
}
function discretizePolygon(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
    noDiscretization: l,
  } = n
  if (l) {
    const e = []
    if (t instanceof Polygon) e.push(t)
    else for (let n = 0; n < t.polygonCount; n++) e.push(t.getPolygon(n))
    const n = attemptSimplePointListsDiscretization(
      e,
      r,
      true,
      Number.MAX_VALUE
    )
    if (n) return { lines: n, fills: [n], canUseFillForStroke: true }
  }
  generalPathSFCT.reset()
  if (t instanceof ComplexPolygon) {
    const n = []
    for (let e = 0; e < t.polygonCount; e++) {
      const r = undefined
      const o = t.getPolygon(e).getSimplePoints()
      n.push(o)
    }
    e.appendComplexPolygon(n, r, o, i, generalPathSFCT, s, a)
  } else {
    if (t.pointCount < 3) {
      const r = discretizeLine(e, t, n)
      r.fills = []
      return r
    }
    const l = t.getSimplePoints()
    e.appendPolygon(l, r, o, i, generalPathSFCT, true, s, a)
  }
  const [c, p] = fixForWorldBoundary(generalPathSFCT, t, o, i)
  if (0 === c.subPathCount()) return { fills: [], lines: [] }
  return convertFillAndOutlinePathsToVertexArrays(
    c,
    p,
    i,
    n.normalizeOrientation
  )
}
function discretizeCircle(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
  } = n
  generalPathSFCT.reset()
  e.appendCircle(t, r, o, i, generalPathSFCT, s, a)
  const [l, c] = fixForWorldBoundary(generalPathSFCT, t, o, i)
  if (0 === l.subPathCount()) return { fills: [], lines: [] }
  return convertFillAndOutlinePathsToVertexArrays(l, c, i)
}
function discretizeEllipse(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
  } = n
  generalPathSFCT.reset()
  e.appendEllipse(t, r, o, i, generalPathSFCT, s, a)
  const [l, c] = fixForWorldBoundary(generalPathSFCT, t, o, i)
  if (0 === l.subPathCount()) return { fills: [], lines: [] }
  return convertFillAndOutlinePathsToVertexArrays(l, c, i)
}
function discretizeArcBand(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
  } = n
  generalPathSFCT.reset()
  e.appendArcBand(t, r, o, i, generalPathSFCT, s, a)
  const [l, c] = fixForWorldBoundary(generalPathSFCT, t, o, i)
  if (0 === l.subPathCount()) return { fills: [], lines: [] }
  return convertFillAndOutlinePathsToVertexArrays(l, c, i)
}
function discretizeSector(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
  } = n
  generalPathSFCT.reset()
  e.appendSector(t, r, o, i, generalPathSFCT, s, a)
  const [l, c] = fixForWorldBoundary(generalPathSFCT, t, o, i)
  if (0 === l.subPathCount()) return { fills: [], lines: [] }
  return convertFillAndOutlinePathsToVertexArrays(l, c, i)
}
function discretizeGeoBuffer(e, t, n) {
  return discretizePolygon(e, t._contour, n)
}
function discretizeParallel(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    lineType: s,
    penThreshold: a,
  } = n
  const l = t.fromLongitude
  let c = t.toLongitude
  const p = t.latitude
  try {
    const e = r.outputReference
    if (e instanceof GeocentricReference) {
      while (l >= c) c += 360
      const e = undefined
      return discretizeGridLineFast(l, p, c, p, clamp((c - l) / 3, 20, 100), r)
    } else if (
      e instanceof GridReference &&
      (e.projection instanceof EquidistantCylindrical ||
        e.projection instanceof EllipsoidalEquidistantCylindrical)
    )
      if (l >= c) {
        const e = discretizeGridLineFast(l, p, 180, p, 2, r).lines[0]
        const t = undefined
        return {
          lines: [e, discretizeGridLineFast(-180, p, c, p, 2, r).lines[0]],
        }
      } else return discretizeGridLineFast(l, p, c, p, 2, r)
  } catch (e) {}
  return discretizeGridLineSlow(l, p, c, p, true, e, r, o, i, s, a)
}
function discretizeMeridian(e, t, n) {
  const {
    transformation: r,
    modelReference: o,
    worldReference: i,
    penThreshold: s,
  } = n
  try {
    const e = r.outputReference
    if (e instanceof GeocentricReference) {
      const e = clamp((t.toLatitude - t.fromLatitude) / 3, 20, 100)
      return discretizeGridLineFast(
        t.longitude,
        t.fromLatitude,
        t.longitude,
        t.toLatitude,
        e,
        r
      )
    } else if (
      e instanceof GridReference &&
      (e.projection instanceof EquidistantCylindrical ||
        e.projection instanceof EllipsoidalEquidistantCylindrical)
    )
      return discretizeGridLineFast(
        t.longitude,
        t.fromLatitude,
        t.longitude,
        t.toLatitude,
        2,
        r
      )
  } catch (e) {}
  return discretizeGridLineSlow(
    t.longitude,
    t.fromLatitude,
    t.longitude,
    t.toLatitude,
    false,
    e,
    r,
    o,
    i,
    null,
    s
  )
}
function discretizeBounds(e, t, n) {
  const { modelReference: r } = n
  const o = t.x
  const i = t.y
  const s = t.x + t.width
  const a = t.y + t.height
  const l = t.x + t.width / 2
  const c = undefined
  const p =
    r.referenceType === ReferenceType.GEODETIC && t.width >= 180
      ? createPolygon(r, [
          [o, i],
          [o, a],
          [l, a],
          [s, a],
          [s, i],
          [l, i],
        ])
      : createPolygon(r, [
          [o, i],
          [o, a],
          [s, a],
          [s, i],
        ])
  n.lineType = LineType.CONSTANT_BEARING
  return discretizePolygon(e, p, n)
}
function discretizeOrientedBox(e, t, n) {
  const r = (e, t, n) => {
    const r = { x: 0, y: 0, z: 0 }
    return n.flatMap((n) => {
      e.transform(t[n], r)
      return [r.x, r.y, r.z]
    })
  }
  const o = r(n.transformation, t.getCorners(), [0, 1, 3, 2, 0])
  const i = r(n.transformation, t.getCorners(), [4, 5, 7, 6, 4])
  const s = r(n.transformation, t.getCorners(), [0, 1, 5, 4, 0])
  const a = r(n.transformation, t.getCorners(), [2, 3, 7, 6, 2])
  const l = undefined
  const c = undefined
  return {
    fills: [
      [o],
      [i],
      [s],
      [a],
      [r(n.transformation, t.getCorners(), [1, 3, 7, 5, 1])],
      [r(n.transformation, t.getCorners(), [0, 2, 6, 4, 0])],
    ],
    lines: [o, i, s, a],
    canUseFillForStroke: true,
  }
}
export const GeoDiscretizerStatic = {
  discretize: function (e, t) {
    const n = t
    if (isUndefined(n.lineType)) n.lineType = LineType.SHORTEST_DISTANCE
    let r = !!t.normalizeOrientation
    let o
    if (ShapeType.contains(e.type, ShapeType.POINT)) o = discretizePoint
    else if (ShapeType.contains(e.type, ShapeType.POLYLINE)) o = discretizeLine
    else if (ShapeType.contains(e.type, ShapeType.POLYGON))
      o = discretizePolygon
    else if (ShapeType.contains(e.type, ShapeType.COMPLEX_POLYGON)) {
      o = discretizePolygon
      r = isBoolean(t.normalizeOrientation)
        ? t.normalizeOrientation
        : n.worldReference.referenceType !== ReferenceType.GEOCENTRIC
    } else if (ShapeType.contains(e.type, ShapeType.SHAPE_LIST))
      o = discretizeComposite
    else if (ShapeType.contains(e.type, ShapeType.CIRCLE)) o = discretizeCircle
    else if (ShapeType.contains(e.type, ShapeType.ELLIPSE))
      o = discretizeEllipse
    else if (ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC))
      o = discretizeCircularArc
    else if (ShapeType.contains(e.type, ShapeType.ARC)) o = discretizeArc
    else if (ShapeType.contains(e.type, ShapeType.ARC_BAND))
      o = discretizeArcBand
    else if (ShapeType.contains(e.type, ShapeType.SECTOR)) o = discretizeSector
    else if (ShapeType.contains(e.type, ShapeType.PARALLEL))
      o = discretizeParallel
    else if (ShapeType.contains(e.type, ShapeType.MERIDIAN))
      o = discretizeMeridian
    else if (ShapeType.contains(e.type, ShapeType.BOUNDS)) o = discretizeBounds
    else if (ShapeType.contains(e.type, ShapeType.GEO_BUFFER))
      o = discretizeGeoBuffer
    else if (ShapeType.contains(e.type, ShapeType.EXTRUDED_SHAPE))
      return this.discretize(e.baseShape, n)
    else if (ShapeType.contains(e.type, ShapeType.ORIENTED_BOX))
      o = discretizeOrientedBox
    const i = getTransformation(n.modelReference, n.worldReference, n.zToZero)
    if (!o || !i)
      throw new ProgrammingError(
        `GeoDiscretizer::discretize. cannot discretize this geometry type for the linetype: ${e.type} ${n.lineType}`
      )
    const s = getPen(n.modelReference)
    n.penThreshold = n.penThreshold || DEFAULT_THRESHOLD
    return o(s, e, {
      transformation: i,
      modelReference: n.modelReference,
      worldReference: n.worldReference,
      lineType: n.lineType,
      penThreshold: n.penThreshold,
      beginDecoration: n.beginDecoration,
      endDecoration: n.endDecoration,
      coordinatesPool: n.coordinatesPool,
      fraction: n.fraction,
      noDiscretization: n.noDiscretization,
      normalizeOrientation: r,
    })
  },
  discretizePoint: function (e, t) {
    const n = t.modelReference
    const r = t.worldReference
    const o = t.coordinatesPool
    const i = t.zToZero
    const s = getTransformation(n, r, i)
    if (!s)
      throw new ProgrammingError(
        `GeoDiscretizer::discretize. cannot discretize this geometry type: ${e.type}`
      )
    return discretizePointFast(e, s, o)
  },
}
