import { createOGCEncoder } from '../../ogc/filter/encoders/OGCEncoderFactory.js'
import { mimeType } from '../../util/mimeType.js'
import { XMLWriter } from '../../util/XMLWriter.js'
import { WFSVersions } from '../../ogc/WFSVersions.js'
import { isArray, isString, isUndefined } from '../../util/Lang.js'
import { urlWithParams } from '../../util/request.js'
const MAX_URL_LENGTH = 255
const WFSV200_XML_NAMESPACES = [
  { prefix: 'xsd', namespace: 'http://www.w3.org/2001/XMLSchema' },
  { prefix: 'wfs', namespace: 'http://www.opengis.net/wfs/2.0' },
  { prefix: 'fes', namespace: 'http://www.opengis.net/fes/2.0' },
  { prefix: 'ows', namespace: 'http://www.opengis.net/ows/1.1' },
  { prefix: 'gml', namespace: 'http://www.opengis.net/gml/3.2' },
  { prefix: 'xlink', namespace: 'http://www.w3.org/1999/xlink' },
]
const XML_NAMESPACES = {
  [WFSVersions.V100]: [
    { prefix: 'xsd', namespace: 'http://www.w3.org/2001/XMLSchema' },
    { prefix: 'wfs', namespace: 'http://www.opengis.net/wfs' },
    { prefix: 'ogc', namespace: 'http://www.opengis.net/ogc' },
    { prefix: 'gml', namespace: 'http://www.opengis.net/gml' },
    { prefix: 'xlink', namespace: 'http://www.w3.org/1999/xlink' },
  ],
  [WFSVersions.V110]: [
    { prefix: 'xsd', namespace: 'http://www.w3.org/2001/XMLSchema' },
    { prefix: 'wfs', namespace: 'http://www.opengis.net/wfs' },
    { prefix: 'ogc', namespace: 'http://www.opengis.net/ogc' },
    { prefix: 'ows', namespace: 'http://www.opengis.net/ows' },
    { prefix: 'gml', namespace: 'http://www.opengis.net/gml' },
    { prefix: 'xlink', namespace: 'http://www.w3.org/1999/xlink' },
  ],
  [WFSVersions.V200]: WFSV200_XML_NAMESPACES,
  [WFSVersions.V202]: WFSV200_XML_NAMESPACES,
}
var SortOrder = (function (e) {
  e[(e['Ascending'] = 0)] = 'Ascending'
  e[(e['Descending'] = 1)] = 'Descending'
  return e
})(SortOrder || {})
const WFS_V1_FILTER_ENCODER_OPTS = { filterPrefix: 'ogc', gmlPrefix: 'gml' }
const WFS_V2_FILTER_ENCODER_OPTS = { filterPrefix: 'fes', gmlPrefix: 'gml' }
const FILTER_ENCODER_OPTIONS = {
  [WFSVersions.V100]: WFS_V1_FILTER_ENCODER_OPTS,
  [WFSVersions.V110]: WFS_V1_FILTER_ENCODER_OPTS,
  [WFSVersions.V200]: WFS_V2_FILTER_ENCODER_OPTS,
  [WFSVersions.V202]: WFS_V2_FILTER_ENCODER_OPTS,
}
const getMajorVersion = (e) => +e.substring(0, e.indexOf('.'))
const isWFSMajorVersion2 = (e) => getMajorVersion(e) >= 2
function isQueryNamePredicate(e) {
  return (
    e && isString(e.namespace) && isString(e.localPart) && isString(e.prefix)
  )
}
function appendGetFeatureOpenTag(e, t) {
  const r = undefined
  const n = [...XML_NAMESPACES[t.version]]
  if (isArray(t.typeNames)) {
    const e = undefined
    t.typeNames.filter(isQueryNamePredicate).forEach((e) => {
      n.push({ prefix: e.prefix, namespace: e.namespace })
    })
  }
  e.beginNode('GetFeature', 'wfs', n)
  e.attribute('service', t.service)
  e.attribute(
    'outputFormat',
    'JSON' === t.outputFormat ? mimeType.json : t.outputFormat
  )
  if (!isUndefined(t.maxFeatures)) {
    const r = getCountLimitAttributeName(t.version)
    e.attribute(r, t.maxFeatures)
  }
  e.attribute('version', t.version)
}
function appendGetFeatureCloseTag(e) {
  e.endNode()
}
function appendQueryElements(e, t) {
  const r = t.version
  const n = t.typeNames
  const s = t.filters
  const i = new Array(s.length)
  const o = FILTER_ENCODER_OPTIONS[r]
  for (let e = 0, t = s.length; e < t; e++) {
    if (!s[e]) throw new Error('Filter should be defined for every typeName')
    i[e] = createOGCEncoder(r).encode(s[e], o)
  }
  for (let o = 0, a = n.length; o < a; o++) {
    const a = n[o]
    let p
    if (isQueryNamePredicate(a)) p = `${a.prefix}:${a.localPart}`
    else if (isString(a)) p = a
    else continue
    const c = isWFSMajorVersion2(r) ? 'typeNames' : 'typeName'
    e.beginNode('Query', 'wfs')
    e.attribute(c, p)
    if (t.propertyNames)
      t.propertyNames.forEach((t) => {
        e.node('wfs:PropertyName', t)
      })
    if (s[o]) e.rawValue(i[o])
    appendSortBy(e, t)
    e.endNode()
  }
}
function appendSortBy(e, t) {
  const r = t.sortBy
  if (!isArray(r) || 0 === r.length) return
  e.beginNode('SortBy', 'fes')
  for (let t = 0; t < r.length; t++) {
    const { sortProperty: n, sortOrder: s } = r[t]
    const i = s === SortOrder.Descending ? 'DESC' : 'ASC'
    e.beginNode('SortProperty', 'fes')
    e.node('fes:ValueReference', n)
    e.node('fes:SortOrder', i)
    e.endNode()
  }
  e.endNode()
}
function buildPOSTParams(e, t) {
  const r = new XMLWriter(true)
  appendGetFeatureOpenTag(r, t)
  appendQueryElements(r, t)
  appendGetFeatureCloseTag(r)
  const n = undefined
  return {
    url: e,
    handleAs: 'json',
    requestOptions: {
      method: 'POST',
      body: `<?xml version="1.0" encoding="UTF-8"?>\n${r.toString()}`,
      headers: { 'Content-Type': mimeType.xml },
    },
  }
}
function getCountLimitAttributeName(e) {
  return isWFSMajorVersion2(e) ? 'count' : 'maxFeatures'
}
function buildGETParams(e, t) {
  const r = t.version
  const n = t.typeNames
  const s = t.filters
  const i = new Array(n.length)
  for (let e = 0, t = n.length; e < t; e++) {
    const t = n[e]
    if (isQueryNamePredicate(t)) i[e] = `${t.prefix}:${t.localPart}`
    else if (isString(n[e])) i[e] = n[e]
  }
  const o = {
    service: t.service,
    request: t.request,
    version: r,
    outputFormat: t.outputFormat,
  }
  const a = undefined
  o[isWFSMajorVersion2(r) ? 'typeNames' : 'typeName'] = i.join(',')
  if (!isUndefined(t.maxFeatures)) {
    const e = undefined
    o[getCountLimitAttributeName(r)] = `${t.maxFeatures}`
  }
  if (isArray(t.sortBy)) {
    const e = isWFSMajorVersion2(r) ? ['DESC', 'ASC'] : ['D', 'A']
    o.sortBy = t.sortBy
      .map((t) => {
        let { sortProperty: r, sortOrder: n } = t
        return `${r} ${n === SortOrder.Descending ? e[0] : e[1]}`
      })
      .join(',')
  }
  if (isArray(t.propertyNames)) o.propertyName = t.propertyNames.join(',')
  const p = s.length
  if (p > 0) {
    const e = { includeNamespaces: true, ...FILTER_ENCODER_OPTIONS[r] }
    if (1 === s.length) o.filter = createOGCEncoder(r).encode(s[0], e)
    else {
      let t = ''
      for (let n = 0; n < p; n++) {
        if (!s[n])
          throw new Error('Filter should be defined for every typeName')
        t += `(${createOGCEncoder(r).encode(s[n], e)})`
      }
      o.filter = t
    }
  }
  return {
    url: urlWithParams(e, o),
    handleAs: 'json',
    requestOptions: { method: 'GET' },
  }
}
function buildPOSTorGETParams(e, t, r) {
  if (r.filters.length > 0) return buildPOSTParams(t, r)
  const n = buildGETParams(e, r)
  if (n.url.length < MAX_URL_LENGTH) return n
  else return buildPOSTParams(t, r)
}
export const WFSRequestBuilderUtil = {
  buildPOSTParams: buildPOSTParams,
  buildGETParams: buildGETParams,
  buildPOSTorGETParams: buildPOSTorGETParams,
}
