import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Invalidation } from '../../util/Invalidation.js'
import { whenInternal } from '../../util/PromiseUtil.js'
import { KeyboardHandler } from '../input/KeyboardHandler.js'
import { MouseHandler } from '../input/MouseHandler.js'
import { MSGestureHandler } from '../input/MSGestureHandler.js'
import { MSPointerHandler } from '../input/MSPointerHandler.js'
import { TouchHandler } from '../input/TouchHandler.js'
import { Controller } from './Controller.js'
import {
  isHandled,
  isRequestDeactivation,
  isRequestFinish,
} from './HandleEventResult.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import { PanController } from './PanController.js'
import { RotateController } from './RotateController.js'
import { CompositeController } from './CompositeController.js'
import { ZoomController } from './ZoomController.js'
import { SelectController } from './SelectController.js'
import { HoverController } from './HoverController.js'
import { ContextMenuController } from './ContextMenuController.js'
import { ControllerInteractionUtil } from '../ControllerInteractionUtil.js'
const hasMSGestureForMultiTouch =
  window.MSGestureEvent && window.navigator.msMaxTouchPoints > 1
const hasMSPointer = window.navigator.msPointerEnabled
export class ControllerManager extends Invalidation {
  _controllerListener = null
  _deactivatingControllerListener = null
  _activatingControllerListener = null
  constructor(t, e) {
    super()
    let o = false
    t.onReady(() => {
      o = true
    })
    this._mapReady = o
    if (!this._mapReady)
      t.onReady(() => {
        this._mapReady = true
      })
    this._map = t
    this._node = e
    this._eventedSupport = new EventedSupport(['ControllerChanged'])
    this._eventHandlers = []
    this._interactionUtil = new ControllerInteractionUtil(t)
    if (hasMSGestureForMultiTouch || hasMSPointer) {
      if (hasMSGestureForMultiTouch)
        this._eventHandlers.push(new MSGestureHandler(t, e, this, hasMSPointer))
      this._eventHandlers.push(
        new MSPointerHandler(t, e, this, hasMSGestureForMultiTouch)
      )
    } else {
      if (this.isTouchDevice())
        this._eventHandlers.push(new TouchHandler(t, e, this))
      this._eventHandlers.push(new MouseHandler(t, e, this))
    }
    this._eventHandlers.push(new KeyboardHandler(document, this))
    this._controller = null
    this._activatingController = null
    this._deactivatingController = null
    this._drawCommand = null
    this._domainObject = null
    this._domainLayer = null
    this._defaultController = new CompositeController()
    this._defaultController.appendController(new SelectController())
    this._defaultController.appendController(new ContextMenuController())
    this._defaultController.appendController(new PanController())
    this._defaultController.appendController(new RotateController())
    this._defaultController.appendController(new ZoomController())
    this._defaultController.appendController(new HoverController())
    this._defaultController.onActivate(t)
  }
  getDomainObject() {
    return this._domainObject
  }
  setDomainObject(t) {
    this._domainObject = t
  }
  getDomainLayer() {
    return this._domainLayer
  }
  setDomainLayer(t) {
    this._domainLayer = t
  }
  onDraw(t) {
    if (this._deactivatingController) this._deactivatingController.onDraw(t)
    if (this._controller) this._controller.onDraw(t)
  }
  onDrawLabel(t) {
    if (this._deactivatingController)
      this._deactivatingController.onDrawLabel(t)
    if (this._controller) this._controller.onDrawLabel(t)
  }
  setDrawCommand(t) {
    this._drawCommand = t
  }
  getDrawCommand() {
    return this._drawCommand
  }
  getInteractionUtil() {
    return this._interactionUtil
  }
  destroy() {
    for (let t = 0; t < this._eventHandlers.length; t++)
      this._eventHandlers[t].destroy()
    this._controllerListener?.remove()
    this._activatingControllerListener?.remove()
    this._eventHandlers = []
    this._interactionUtil = null
    this._map = null
    this._node = null
  }
  onKeyEvent(t) {
    if (!this._mapReady) return false
    let e = false
    const o = this._controller
    let r
    if (null !== o && o.onKeyEvent) {
      r = o.onKeyEvent(t)
      if (isRequestDeactivation(r) || isRequestFinish(r))
        this.setController(null)
      e = isHandled(r)
    }
    return e
  }
  onGestureEvent(t) {
    if (!this._mapReady) return false
    let e = false
    if (null !== this._controller && this._controller.onGestureEvent) {
      const o = this._controller.onGestureEvent(t)
      if (isRequestDeactivation(o) || isRequestFinish(o))
        this.setController(null)
      e = isHandled(o)
    } else if (null !== this._deactivatingController) e = true
    if (!e) {
      const o = this._defaultController.onGestureEvent(t)
      e = isHandled(o)
    }
    return e
  }
  _deactivationComplete(t) {
    if (this._deactivatingController === t) {
      this._deactivatingController = null
      if (this._deactivatingControllerListener) {
        this._deactivatingControllerListener.remove()
        this._deactivatingControllerListener = null
      }
      this._controller = this._activatingController
      this._controllerListener = this._activatingControllerListener
      this._activatingController = null
      this._activatingControllerListener = null
      if (this._controller && this._controller.onActivate)
        this._controller.onActivate(this._map)
      this._eventedSupport.emit('ControllerChanged', this._controller, t)
    }
    this.invalidate()
  }
  setController(t) {
    if ('undefined' === typeof t) t = null
    if (t === this._controller) return
    if (this._controller) {
      this._activatingController = t
      if (this._activatingController)
        this._activatingControllerListener = this._activatingController.on(
          'Invalidated',
          this.invalidate.bind(this)
        )
      else this._activatingControllerListener = null
      this._deactivatingController = this._controller
      this._deactivatingControllerListener = this._controllerListener
      this._controller = null
      this._controllerListener = null
      const e = this._deactivatingController.onDeactivate(this._map, this)
      const o = this._deactivationComplete.bind(
        this,
        this._deactivatingController
      )
      whenInternal(e, o, o)
    } else if (this._deactivatingController) {
      this._activatingController = t
      if (this._activatingController)
        this._activatingControllerListener = this._activatingController.on(
          'Invalidated',
          this.invalidate.bind(this)
        )
      else this._activatingControllerListener = null
    } else {
      this._activatingController = null
      if (this._activatingControllerListener) {
        this._activatingControllerListener.remove()
        this._activatingControllerListener = null
      }
      this._controller = t
      if (this._controller && this._controller.onActivate) {
        this._controller.onActivate(this._map)
        this._controllerListener = this._controller.on(
          'Invalidated',
          this.invalidate.bind(this)
        )
      }
      this._eventedSupport.emit('ControllerChanged', this._controller, null)
    }
    this.invalidate()
  }
  on(t, e) {
    if ('ControllerChanged' === t) return this._eventedSupport.on(t, e)
    return super.on(t, e)
  }
  isTouchDevice() {
    const t = window.DocumentTouch && document instanceof DocumentTouch
    return !!(
      'ontouchstart' in window ||
      window.TouchEvent ||
      t ||
      (navigator.maxTouchPoints && navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 0)
    )
  }
  get controller() {
    return this._controller
  }
  set controller(t) {
    if (t && !(t instanceof Controller))
      throw new ProgrammingError(
        'Controllers must have luciad.view.controller.Controller in their prototype chain'
      )
    this.setController(t)
  }
}
