const EVENT_IGNORED_INTERNAL = 0
const EVENT_HANDLED_INTERNAL = 1
const REQUEST_FINISH_INTERNAL = 2
const REQUEST_DEACTIVATION_INTERNAL = 4
var HandleEventResult = (function (E) {
  E[(E['EVENT_IGNORED'] = 0)] = 'EVENT_IGNORED'
  E[(E['EVENT_HANDLED'] = 1)] = 'EVENT_HANDLED'
  E[(E['REQUEST_FINISH'] = 2)] = 'REQUEST_FINISH'
  E[(E['REQUEST_DEACTIVATION'] = 4)] = 'REQUEST_DEACTIVATION'
  return E
})(HandleEventResult || {})
export const EVENT_IGNORED = HandleEventResult.EVENT_IGNORED
export const EVENT_HANDLED = HandleEventResult.EVENT_HANDLED
export const REQUEST_FINISH = HandleEventResult.REQUEST_FINISH
export const REQUEST_DEACTIVATION = HandleEventResult.REQUEST_DEACTIVATION
export function setRequestDeactivation(E) {
  return E | REQUEST_DEACTIVATION_INTERNAL
}
export function isRequestDeactivation(E) {
  return 0 !== (E & REQUEST_DEACTIVATION_INTERNAL)
}
export function clearRequestDeactivation(E) {
  return E & ~REQUEST_DEACTIVATION_INTERNAL
}
export function setRequestFinish(E) {
  return E | REQUEST_FINISH_INTERNAL
}
export function isRequestFinish(E) {
  return 0 !== (E & REQUEST_FINISH_INTERNAL)
}
export function clearRequestFinish(E) {
  return E & ~REQUEST_FINISH_INTERNAL
}
export function setHandled(E) {
  return E | EVENT_HANDLED_INTERNAL
}
export function isHandled(E) {
  return 0 !== (E & EVENT_HANDLED_INTERNAL)
}
export function clearHandled(E) {
  return E & ~EVENT_HANDLED_INTERNAL
}
export { HandleEventResult }
