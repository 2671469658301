import { LLHPoint } from '../shape/LLHPoint.js'
import { Hash } from '../util/Hash.js'
import { ProjectionType } from './ProjectionType.js'
import { BaseProjection } from './BaseProjection.js'
export class Azimuthal extends BaseProjection {
  constructor() {
    super()
    this._origin = new LLHPoint()
    this._hash = 0
  }
  setOriginLon(t) {
    this._origin.x = t
    this.calculateCachedValues()
  }
  setOriginLat(t) {
    this._origin.y = t
    this.calculateCachedValues()
  }
  getOrigin() {
    return this._origin
  }
  getOriginLat() {
    return this._origin.y
  }
  getOriginLon() {
    return this._origin.x
  }
  isContinuous() {
    return true
  }
  equals(t) {
    if (!super.equals(t)) return false
    const i = t
    return (
      this.getOriginLon() == i.getOriginLon() &&
      this.getOriginLat() == i.getOriginLat()
    )
  }
  calculateCachedValues() {
    const t = new Hash()
    this._hash = t
      .appendDouble(this.getOriginLon())
      .appendDouble(this.getOriginLat())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  get TYPE() {
    return ProjectionType.AZIMUTHAL
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
