import { RequestError } from '../error/RequestError.js'
import { mimeType } from './mimeType.js'
import { URL } from './URL.js'
export function request(e, r, t) {
  const o = undefined
  return (t ? t.scheduleRequest().then(() => fetch(e, r)) : fetch(e, r))
    .then((r) => {
      t?.finishRequest()
      if (!r.ok) {
        let t = `Unable to load URL ${e}`
        if (r.status >= 400) t += ', statusText: ' + r.statusText
        throw new RequestError(t, r.status, r)
      }
      return r
    })
    .catch((r) => {
      if (r instanceof RequestError || 'AbortError' === r.name) throw r
      const t = `Unable to load URL ${e}. Error message: ${r.message}`
      throw new RequestError(t)
    })
}
export function urlWithParams(e, r) {
  if ('object' === typeof r) r = URL.buildQueryString(r)
  if (r)
    if (-1 === e.indexOf('?')) return e + '?' + r
    else if (-1 !== e.indexOf('?', e.length - 1)) return e + r
    else return e + '&' + r
  return e
}
export function handleResponseAs(e, r) {
  if ('arraybuffer' === r) return e.arrayBuffer()
  else if ('blob' === r) return e.blob()
  else if ('json' === r) return e.json()
  return e.text()
}
export function createFormPOSTRequest(e) {
  const r = {}
  const t = []
  for (const r in e)
    if (e.hasOwnProperty(r)) {
      const o = encodeURIComponent(r)
      const n = encodeURIComponent(e[r])
      t.push(o + '=' + n)
    }
  r.headers = { 'Content-Type': mimeType.form }
  r.body = t.join('&')
  r.method = 'POST'
  return r
}
