import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { getReference } from '../reference/ReferenceProvider.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { createBounds } from '../shape/ShapeFactory.js'
import { ShapeType } from '../shape/ShapeType.js'
import { ChainedTransformation } from '../transformation/ChainedTransformation.js'
import { LocationMode } from '../transformation/LocationMode.js'
import {
  getMapToBottomBorderTransformation,
  getMapToLeftBorderTransformation,
} from '../transformation/MapToBorderTransformationUtil.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { WorldViewTransformation2D } from '../transformation/WorldViewTransformation2D.js'
import { isIELT } from '../util/Browser.js'
import {
  canCreatePhotonColorFromString,
  createPhotonColorFromString,
} from '../util/Color.js'
import { linear } from '../util/Easing.js'
import { EventedSupport } from '../util/EventedSupport.js'
import {
  isArray,
  isDefined,
  isFunction,
  isObject,
  isString,
} from '../util/Lang.js'
import { Log } from '../util/Log.js'
import { UUID } from '../util/UUID.js'
import { AnimationManager } from './animation/AnimationManager.js'
import { AxisRenderer } from './axis/AxisRenderer.js'
import { OrthographicCamera } from './camera/OrthographicCamera.js'
import { PerspectiveCamera } from './camera/PerspectiveCamera.js'
import { CameraFactory } from './CameraFactory.js'
import { ContextMenu } from './ContextMenu.js'
import { ControllerManager } from './controller/ControllerManager.js'
import { CursorManager } from './CursorManager.js'
import { GoogleLayer } from './google/GoogleLayer.js'
import { GraphicsEffects } from './GraphicsEffects.js'
import { HoverChangedEvent } from './HoverSupport.js'
import { isViewBoundsOutsideLayerClip } from './LayerStyle.js'
import { LayerTree } from './LayerTree.js'
import { LayerTreeNode } from './LayerTreeNode.js'
import { LayerTreeVisitor } from './LayerTreeVisitor.js'
import { MapNavigator } from './MapNavigator.js'
import {
  deserializeMapState,
  findMatchingCameraPosition,
  serializeMapState,
} from './MapStateSupport.js'
import { PaintRepresentation } from './PaintRepresentation.js'
import { PickInfoImpl } from './PickInfo.js'
import { PickRequest } from './PickRequest.js'
import { SelectionType } from './SelectionType.js'
import { DefaultViewPaintingStrategy } from './strategy/DefaultViewPaintingStrategy.js'
import { cleanupCache } from './style/HTMLMetrics.js'
import { createUOMSupport } from './style/WorldSizeUtil.js'
const $N = {}
const DEFAULT_GRID_REFERENCE = 'EPSG:32662'
const CENTIMETER_TO_INCH = 2.54
let DPI = NaN
let DOTS_PER_CENTIMETER = NaN
const IDLE_TIMING = 66
function calculateDPI() {
  if (isNaN(DPI)) {
    const e = document
      .getElementsByTagName('body')[0]
      .appendChild(document.createElement('div'))
    e.style.width = '1in'
    e.style.padding = '0'
    e.style.margin = '0'
    e.style.border = '0'
    DPI = e.offsetWidth || 96
    DOTS_PER_CENTIMETER = DPI / CENTIMETER_TO_INCH
    e.parentNode.removeChild(e)
  }
  return DPI
}
function getSpatialReference(e) {
  let t = isString(e) ? getReference(e) : e
  const r = t.referenceType
  if (
    !(
      r === ReferenceType.GRID ||
      r === ReferenceType.GEOCENTRIC ||
      r === ReferenceType.CARTESIAN
    )
  )
    t = getSpatialReference(DEFAULT_GRID_REFERENCE)
  return t
}
function foldSelectionChanges(e, t) {
  let r, i, o
  const n = t
  if (n.selectable) {
    const t = e.selection.find((e) => e.layer === n)
    if (t) {
      if (e.editSelection === SelectionType.NEW) r = t.objects || []
      else {
        i = t.objects || []
        r = n.getSelectedFeatures()
        for (let t = 0; t < i.length; t++)
          if (n.isSelected(i[t])) {
            if (e.editSelection !== SelectionType.ADD) {
              let e = -1
              for (let o = 0; o < r.length; o++)
                if (r[o].id === i[t].id) {
                  e = o
                  break
                }
              if (-1 !== e) r.splice(e, 1)
            }
          } else if (
            e.editSelection === SelectionType.ADD ||
            e.editSelection === SelectionType.TOGGLE
          )
            r.push(i[t])
      }
      o = n.selectFeatures(r)
    } else if (e.editSelection === SelectionType.NEW) o = n.clearSelection()
    if (o) e.selectionChanges.push(o)
  }
  return e
}
function mapPickInfoToObject(e) {
  return e.object
}
class Map {
  __domSetupComplete = false
  __borderSetupComplete = false
  _mapBoundsDirty = true
  _scaleX = 0
  _scaleY = 0
  _scaleDirty = true
  constructor(e, t) {
    this._destroyed = false
    this._eventedSupport = new EventedSupport([
      'MapChange',
      'SelectionChanged',
      'HoverChanged',
      'idle',
      'ShowBalloon',
      'HideBalloon',
      'ControllerChanged',
    ])
    if (isIELT(11))
      Log.error('LuciadRIA Map is not supported by Internet Explorer < 11')
    if (isDefined((t = t || $N).webgl))
      Log.warn(
        'The "webgl" option to luciad/view/Map is not supported anymore.  Use luciad/view/WebGLMap instead.'
      )
    this._mapChangeScheduled = false
    this._constraintPadding = { left: 0, right: 0, bottom: 0, top: 0 }
    this._nodePosition = {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    }
    this._displayedBalloonFor = null
    calculateDPI()
    this.__setupLayers()
    this.__setupBorderAndAxes(t.border, t.axes)
    this._reference = getSpatialReference(
      isDefined(t.reference)
        ? t.reference
        : t.is3D
        ? 'EPSG:4978'
        : DEFAULT_GRID_REFERENCE
    )
    this._3d = isDefined(t.is3D)
      ? t.is3D
      : this.reference.referenceType === ReferenceType.GEOCENTRIC
    if (this._reference.referenceType === ReferenceType.GEOCENTRIC && !this._3d)
      throw new ProgrammingError(
        'Map: you cannot use a geocentric reference in 2D'
      )
    Log.debug(
      'Map: deduced ' +
        (this._3d ? '3D' : '2D') +
        ' with ' +
        this._reference.identifier +
        ' from config ' +
        t.is3D +
        ' and ' +
        t.reference
    )
    this.__setupDOM(e)
    this._worldSizeSupport = createUOMSupport(this)
    this.__setupViewWorldBounds()
    this.__setupCamera()
    this.__setupControllers()
    this._effects = new GraphicsEffects()
    this.globeColor = t.globeColor || 'rgba(204,204,204,1.0)'
    this._viewPaintingStrategyConstructor =
      t.viewPaintingStrategyConstructor || DefaultViewPaintingStrategy
    this._onPhotonReady = t.onPhotonReady || null
    this.onReady(() => {
      this.__setupViewPaintingStrategy()
    })
    this.__setupMapNavigator(t.mapNavigatorConstructor)
    this.__setupConversionHelpers()
    this.__setupWorldViewTransformation(t.v2w)
    this.onReady(() => {
      if (t.v2w)
        this.viewPaintingStrategy.setWorldViewTransformation(
          this._worldViewTransformation
        )
      this.globeColor = this.globeColor
      this._effects.listener =
        this.viewPaintingStrategy.createGraphicsEffectsListener()
      this._worldViewTransformation.updateCamera(this.camera, true)
      this.viewPaintingStrategy.updateCamera(this.camera, true)
      this.transformationChanged()
    })
    this.__createIdleEvent()
    this._cursorManager = new CursorManager(this._domNode)
    if (this.reference.bounds)
      this.mapNavigator.fit({ bounds: this.reference.bounds })
    const r = this
    this._hideBalloonDeselectionListener = this._eventedSupport.on(
      'SelectionChanged',
      (e) => {
        if (r._displayedBalloonFor)
          for (const t of e.selectionChanges)
            if (
              t.layer === r._displayedBalloonFor.layer &&
              t.deselected.indexOf(r._displayedBalloonFor.object) >= 0
            ) {
              r.hideBalloon()
              return
            }
      }
    )
    this._cameraAnimationKey = (1e6 * Math.random()).toString()
  }
  onReady(e) {
    if (this._onPhotonReady)
      return this._onPhotonReady(() => {
        if (!this.destroyed) e()
      })
    else if (!this.destroyed) e()
  }
  get axisRenderer() {
    return this._axisRenderer
  }
  get cursorManager() {
    return this._cursorManager
  }
  idleEventScheduled() {
    return !!this._idleHandle
  }
  __createIdleEvent() {
    this._idleHandle = null
    const e = () => {
      this._idleHandle = null
      this._eventedSupport.emit('idle', self)
    }
    this._mapIdleListener = this._eventedSupport.on('MapChange', () => {
      clearTimeout(null === this._idleHandle ? void 0 : this._idleHandle)
      this._idleHandle = setTimeout(e, IDLE_TIMING)
    })
  }
  getAdjustedMinScale() {
    return this.mapNavigator.constraints.scale._minScale
  }
  getAdjustedMaxScale() {
    if (!this.constraintBounds)
      return this.mapNavigator.constraints.scale._maxScale
    const e = this.getViewWidth() / this.constraintBounds.width
    const t = this.getViewHeight() / this.constraintBounds.height
    if (
      this.mapNavigator.constraints.scale._maxScale[0] < e ||
      this.mapNavigator.constraints.scale._maxScale[1] < t
    )
      return [e, t]
    else return this.mapNavigator.constraints.scale._maxScale
  }
  __setupLayers() {
    this._layerTree = new LayerTree(this, { label: 'root' })
    const e = this
    this._snapHandle = this._layerTree.on('NodeAdded', (t) => {
      const r = e.mapNavigator && e.mapNavigator.defaults.snapToScaleLevels
      const i = undefined
      if (
        !(t.node instanceof GoogleLayer) &&
        !(isFunction(t.node.snapScale) && r)
      )
        return
      const o = t.node
      function n() {
        const t = {}
        o.snapScale(e.getScaleX(), e.getScaleY(), Math.round, t)
        if (t.scalex !== e.getScaleX())
          e.mapNavigator.setScale(
            e.mapNavigator.constraints.scale._convertXComponentPixelToMap(
              t.scalex
            )
          )
      }
      if (e.mapNavigator.isAnimating())
        e.mapNavigator.animationPromise.then(n, () => {})
      else n()
    })
  }
  __setupMapNavigator(e) {
    this._mapNavigator = this.is3D() ? new e(this) : new MapNavigator(this)
  }
  __setupControllers() {
    if (!this.__domSetupComplete)
      throw new Error(
        'Cannot setup the controllers before the DOM and the scale conversions have been setup'
      )
    this._controllerManager = new ControllerManager(this, this._containerNode)
    this._controllerListener = this._controllerManager.on(
      'ControllerChanged',
      (e, t) => {
        this._eventedSupport.emit('ControllerChanged', e, t)
      }
    )
  }
  __setupConversionHelpers() {
    this._convertXComponentPixelToMap =
      this.mapNavigator.constraints.scale._convertXComponentPixelToMap
    this._convertYComponentPixelToMap =
      this.mapNavigator.constraints.scale._convertYComponentPixelToMap
    this._convertXComponentMapToPixel =
      this.mapNavigator.constraints.scale._convertXComponentMapToPixel
    this._convertYComponentMapToPixel =
      this.mapNavigator.constraints.scale._convertYComponentMapToPixel
  }
  convertXComponentMapToPixel(e) {
    return this._convertXComponentMapToPixel(e)
  }
  convertYComponentMapToPixel(e) {
    return this._convertYComponentMapToPixel(e)
  }
  __setupWrapperDivStyleSettings(e) {
    e.style.padding = 0
    e.style.margin = 0
    e.style.overflow = 'hidden'
    e.style.position = 'relative'
    e.style.width = '100%'
    e.style.height = '100%'
    e.style.zIndex = 0
    e.style.msTouchAction = 'none'
    e.style.touchAction = 'none'
  }
  __setupBorderAndAxes(e, t) {
    this._border = { left: 0, right: 0, top: 0, bottom: 0 }
    if (e) {
      if (e.left && e.left > 0) this._border.left = e.left
      if (e.bottom && e.bottom > 0) this._border.bottom = e.bottom
    }
    if (t) this._axisRenderer = new AxisRenderer(t, this)
    this.__borderSetupComplete = true
  }
  __setupDOM(e) {
    this._domNode = isString(e) ? document.getElementById(e) : e
    if (!isObject(this._domNode) || null === this._domNode)
      throw new ProgrammingError(
        'Map::constructor - cannot find node by that id.'
      )
    this._domNode.classList.add('luciad')
    this._inner = document.createElement('div')
    this._inner.id = `innerNode${Math.random()}`
    this.__setupWrapperDivStyleSettings(this._inner)
    this._domNode.appendChild(this._inner)
    this._containerNode = document.createElement('div')
    this._containerNode.id = UUID.randomUUID()
    this.__setupWrapperDivStyleSettings(this._containerNode)
    this._inner.appendChild(this._containerNode)
    this._resizeHandler = this.resize.bind(this)
    window.addEventListener('resize', this._resizeHandler, false)
    if ('ResizeObserver' in window) {
      const e = undefined
      new ResizeObserver((e) => {
        if (e.length >= 0) this.resize()
      }).observe(this._domNode)
    }
    this.__domSetupComplete = true
  }
  __setupViewPaintingStrategy() {
    if (
      !this.__domSetupComplete ||
      !this.__borderSetupComplete ||
      !this._layerTree
    )
      throw new Error(
        'Cannot setup the VPS without the DOM, border, and layertree, ' +
          'configured on the map'
      )
    this.viewPaintingStrategy = new this._viewPaintingStrategyConstructor(this)
    this.viewPaintingStrategy.registerLayerTree(this._layerTree)
  }
  __setupViewWorldBounds() {
    if (!this.__domSetupComplete)
      throw new Error(
        'Cannot setup the map reference if the Map has not configured the DOM yet.'
      )
    this._viewBounds = createBounds(null, [])
    this._mapBounds = null
    this._mapBoundsDirty = true
    this._scaleX = 0
    this._scaleY = 0
    this._scaleDirty = true
    this._updateViewBounds()
  }
  __setupWorldViewTransformation(e) {
    if (e)
      if (this.is3D()) this._worldViewTransformation = new e(this)
      else
        this._worldViewTransformation = new WorldViewTransformation2D(
          this.reference,
          this.camera
        )
    else
      this._worldViewTransformation =
        e || this.viewPaintingStrategy.createWorldViewTransformation()
    this.transformationChanged()
  }
  __setupCamera() {
    this._camera = this.is3D()
      ? PerspectiveCamera.createDefaultCamera(
          this.reference,
          this.getViewWidth(),
          this.getViewHeight()
        )
      : OrthographicCamera.createDefault2DCamera(
          this.reference,
          this.getViewWidth(),
          this.getViewHeight()
        )
  }
  is3D() {
    return this._3d
  }
  isGeospatial() {
    return this.reference.referenceType != ReferenceType.CARTESIAN
  }
  _isSoftwareMap() {
    return this._viewPaintingStrategyConstructor === DefaultViewPaintingStrategy
  }
  _updateViewBounds() {
    const e = this._viewBounds.width
    const t = this._viewBounds.height
    const r = this._containerNode.getBoundingClientRect()
    this._nodePosition.left = Math.round(r.left)
    this._nodePosition.right = Math.round(r.right)
    this._nodePosition.top = Math.round(r.top)
    this._nodePosition.bottom = Math.round(r.bottom)
    this._nodePosition.width = Math.round(r.width)
    this._nodePosition.height = Math.round(r.height)
    const i = this._nodePosition.width - this._border.left - this._border.right
    const o = this._nodePosition.height - this._border.top - this._border.bottom
    this._viewBounds.setTo2D(this._border.left, i, this._border.top, o)
    if (i !== e || o !== t) this._handleViewSizeChanged()
  }
  _getMapBounds() {
    if (this._mapBoundsDirty) {
      this._mapBounds = this._worldViewTransformation.getBoundsOnSurface(
        this._viewBounds
      )
      this._mapBoundsDirty = false
    }
    return this._mapBounds
  }
  transformationChanged() {
    if (!this._worldViewTransformation) return
    this._mapBoundsDirty = true
    this._scaleDirty = true
    for (let e = 0, t = this._layerTree.children.length; e < t; e++)
      this._layerTree.children[e]._worldBoundsChanged(this)
    if (!this._mapChangeScheduled) {
      this._schedule(() => {
        this._mapChangeScheduled = false
        if (this._worldViewTransformation)
          this._eventedSupport.emit('MapChange', null)
      }, true)
      this._mapChangeScheduled = true
    }
    this.invalidate()
  }
  resize() {
    this._updateViewBounds()
    this.invalidate()
  }
  _handleViewSizeChanged() {
    const e = this.getViewWidth()
    const t = this.getViewHeight()
    if (this._camera)
      if (e !== this._camera.width || t !== this._camera.height)
        if (!this.is3D()) {
          const r = this.camera.asLook2D()
          this.camera = this.camera
            .copyAndSet({ width: e, height: t })
            .look2D(r)
          if (this._worldViewTransformation)
            this._worldViewTransformation.setViewSize(e, t)
        } else this.camera = this.camera.copyAndSet({ width: e, height: t })
    if (this.viewPaintingStrategy)
      this.viewPaintingStrategy.resize(
        this.totalSize[0],
        this.totalSize[1],
        this.border
      )
    if (this._mapNavigator)
      if (!this._mapNavigator.isAnimating()) this._mapNavigator.invalidate()
  }
  _getContainerNode() {
    return this._containerNode
  }
  _getInnerNode() {
    return this._inner
  }
  getViewBounds(e) {
    e.setToBounds2D(this._viewBounds)
  }
  getMapBoundsSFCT(e) {
    const t = this._getMapBounds()
    if (!t || isNaN(t.x)) throw new OutOfBoundsError('Map not visible')
    else if (t.reference.equals(e.reference)) e.setToBounds3D(t)
    else {
      const r = undefined
      createTransformation(t.reference, e.reference).transformBounds(t, e)
    }
  }
  pickClosestObject(e, t, r, i) {
    return this._pickClosestObject(e, t, r, false, i)
  }
  _pickClosestObject(e, t, r, i, o) {
    return this._pickClosestObjectRectangle(e, t, 1 + 2 * r, 1 + 2 * r, i, o)
  }
  pickClosestObjectRectangle(e, t, r, i, o) {
    return this._pickClosestObjectRectangle(e, t, r, i, false, o)
  }
  _pickClosestObjectRectangle(e, t, r, i, o, n) {
    return this._pickRect(e, t, r, i, true, false, o, n)
  }
  pickAt(e, t, r, i) {
    return this.pickAtRectangle(e, t, 1 + 2 * r, 1 + 2 * r, i)
  }
  pickAtRectangle(e, t, r, i, o) {
    return this._pickRect(e, t, r, i, false, false, false, o)
  }
  get selectedObjects() {
    return this.layerTree._reduceLeaves(collectSelectedObjects, [])
  }
  _pickRect(e, t, r, i, o, n, a, s) {
    let l = [],
      h = [],
      c
    if (
      (s = s || [PaintRepresentation.BODY]).indexOf(
        PaintRepresentation.LABEL
      ) >= 0
    ) {
      l = this._pickByLabelInRect(e, t, r, i, o, a)
      if (o && l.length) return PickInfoImpl.singleOut(l)
    }
    if (s.indexOf(PaintRepresentation.BODY) >= 0)
      h = this._pickByBodyInRect(e, t, r, i, o, n, a)
    c = PickInfoImpl.mergePickInfoArrays(l, h)
    return o ? PickInfoImpl.singleOut(c) : c
  }
  _pickByLabelInRect(e, t, r, i, o, n) {
    if (this.viewPaintingStrategy)
      return this.viewPaintingStrategy.pickClosestLabels(e, t, r, i, o, n)
    return null
  }
  get hoveredObjects() {
    return this.layerTree._reduceLeaves(collectHoveredObjects, [])
  }
  selectObjects(e, t) {
    this.selectObjectsInternal(e, t)
  }
  selectObjectsInternal(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    const r = isDefined(t.editSelection) ? t.editSelection : SelectionType.NEW
    if (!isArray(e))
      throw new ProgrammingError(
        'Map: must use an array of pickObjects to make a selection'
      )
    const i = this.layerTree._reduceLeaves(foldSelectionChanges, {
      selection: e,
      selectionChanges: [],
      editSelection: r,
    })
    const { selectionChanges: o } = i
    const n = { selectionChanges: o }
    if (o.length > 0) this._eventedSupport.emit('SelectionChanged', n)
    return n
  }
  hoverObjects(e) {
    if (!isArray(e))
      throw new ProgrammingError('hoverObjects: must use an array of PickInfo')
    this.updateHovered(e, true)
  }
  updateHovered(e, t) {
    const r = this.layerTree._reduceLeaves(
      (e, r) => {
        if ('setHovered' in r && r.hoverable) {
          const i = e.currentHovered.find((e) => e.layer === r)
          const o = i ? i.objects : []
          const n = r.setHovered(o, t)
          if (n) e.hoverChanges.push(n)
        }
        return e
      },
      { currentHovered: e, hoverChanges: [] }
    )
    if (r.hoverChanges.length)
      this._eventedSupport.emit(HoverChangedEvent, {
        hoverChanges: r.hoverChanges,
      })
  }
  clearHovered() {
    this.hoverObjects([])
  }
  findLayerOrThrow(e) {
    const t = this.layerTree.findLayerById(e)
    if (!t) throw new ProgrammingError(`layer ${e} is not added to this map`)
    return t
  }
  _pickClosest3DObject(e, t) {
    let r = []
    const i = {
      visitLayer: (i) => {
        if (!i.visibleInTree || !i.pick || (t && !i.selectable))
          return LayerTreeVisitor.ReturnValue.CONTINUE
        if (this.viewPaintingStrategy?.isOfPhotonType()) {
          const t = this.viewPaintingStrategy.getLayerStyle(i)
          if (
            t.flicker ||
            isViewBoundsOutsideLayerClip(e.viewBounds, this.viewSize[1], t.clip)
          )
            return LayerTreeVisitor.ReturnValue.CONTINUE
        }
        const o = i.pick(e)
        r = r.concat(o)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
      visitLayerGroup: function (e) {
        e.visitChildren(this, LayerTreeNode.VisitOrder.TOP_DOWN)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
    }
    this.layerTree.accept(i)
    if (0 === r.length) return []
    r.sort(PickInfoImpl.comparePickInfo3D)
    const o = r[0]
    return [{ layer: o.layer, objects: [o.object] }]
  }
  clearSelection() {
    this.selectObjects([])
  }
  _pickByBodyInRect(e, t, r, i, o, n, a) {
    const s = !n
    const l = PickRequest.fromViewPoint(
      e,
      t,
      r,
      i,
      o,
      s,
      this.viewToMapTransformation
    )
    if (this.is3D() && o) return this._pickClosest3DObject(l, a)
    const h = []
    const c = {
      visitLayer: (e) => {
        if (
          !e.visibleInTree ||
          !e.pick ||
          (a && !e.selectable) ||
          (n && true !== e.isSnapTarget)
        )
          return LayerTreeVisitor.ReturnValue.CONTINUE
        if (this.viewPaintingStrategy?.isOfPhotonType()) {
          const t = this.viewPaintingStrategy.getLayerStyle(e)
          if (
            t.flicker ||
            isViewBoundsOutsideLayerClip(l.viewBounds, this.viewSize[1], t.clip)
          )
            return LayerTreeVisitor.ReturnValue.CONTINUE
        }
        const t = e.pick(l)
        t.sort(PickInfoImpl.comparePickInfo2D)
        const r = t.map(mapPickInfoToObject)
        if (0 === r.length) return LayerTreeVisitor.ReturnValue.CONTINUE
        else if (o) {
          h.push({ layer: e, objects: r.slice(0, 1) })
          return LayerTreeVisitor.ReturnValue.ABORT
        } else {
          h.push({ layer: e, objects: PickInfoImpl.uniqueFeatures(r) })
          return LayerTreeVisitor.ReturnValue.CONTINUE
        }
      },
      visitLayerGroup: function (e) {
        e.visitChildren(this, LayerTreeNode.VisitOrder.TOP_DOWN)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
    }
    this.layerTree.accept(c)
    return h
  }
  updateSelectionFromLayer(e) {
    this._eventedSupport.emit('SelectionChanged', { selectionChanges: [e] })
  }
  updateHoverFromLayer(e) {
    this._eventedSupport.emit(HoverChangedEvent, { hoverChanges: [e] })
  }
  invalidate() {
    if (this.viewPaintingStrategy) this.viewPaintingStrategy.invalidate()
  }
  invalidateLayerPostRender(e) {
    const t = this
    this._schedule(() => {
      t.invalidateLayerTreeNode(e)
    })
  }
  invalidateLayerTreeNode(e) {
    if (this.viewPaintingStrategy)
      this.viewPaintingStrategy.invalidateLayerTreeNode(e)
  }
  _schedule(e) {
    let t =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : false
    if (this.viewPaintingStrategy) this.viewPaintingStrategy.schedule(e, t)
    else setTimeout(e, 1)
  }
  get destroyed() {
    return this._destroyed
  }
  destroy() {
    this._destroyed = true
    this._hideBalloonDeselectionListener.remove()
    this._controllerListener.remove()
    this._mapIdleListener.remove()
    AnimationManager.removeAnimation(this.cameraAnimationKey)
    this._snapHandle.remove()
    this._layerTree._forEachLeaf((e) => {
      if (e.destroy) e.destroy()
    })
    if (this._controllerManager && this._controllerManager.destroy) {
      this._controllerManager.destroy()
      this._controllerManager = null
    }
    this._layerTree._removeAll()
    if (this._mapNavigator && this._mapNavigator.destroy) {
      this._mapNavigator.destroy()
      this._mapNavigator = null
    }
    if (
      this._worldViewTransformation &&
      this._worldViewTransformation.release
    ) {
      this._worldViewTransformation.release()
      this._worldViewTransformation = null
    }
    if (this.viewPaintingStrategy && this.viewPaintingStrategy.destroy)
      this.viewPaintingStrategy.destroy()
    this.viewPaintingStrategy = null
    window.removeEventListener('resize', this._resizeHandler)
    this._inner.parentNode?.removeChild(this._inner)
    this._domNode?.classList.remove('luciad')
    cleanupCache()
  }
  showBalloon(e) {
    if (!isDefined((e = e || $N).object))
      throw new ProgrammingError('Must pass an object in showBalloon()')
    this.onReady(() => {
      const t = this.viewPaintingStrategy.balloonStrategy
      this._displayedBalloonFor = { object: e.object, layer: e.layer ?? null }
      t.showBalloon(
        e.object,
        e.contentProvider || null,
        e.layer || null,
        e.panTo || false
      )
    })
  }
  hideBalloon() {
    this.onReady(() => {
      const e = undefined
      this.viewPaintingStrategy.balloonStrategy.hideBalloon()
      this._displayedBalloonFor = null
    })
  }
  updateBalloon() {
    this.onReady(() => {
      const e = undefined
      this.viewPaintingStrategy.balloonStrategy.updateContents()
    })
  }
  saveState() {
    if (!this.reference.identifier)
      throw new ProgrammingError(
        "Could not save map state: the map's reference needs to have an identifier"
      )
    return serializeMapState(this)
  }
  restoreState(e, t) {
    const r = new CameraFactory(this)
    const i = deserializeMapState(e, this, r)
    if (!i)
      throw new Error(
        'Could not restore saved map state: could not deserialize saved map state.'
      )
    let o = findMatchingCameraPosition(
      this,
      i,
      this.reference,
      this.getViewWidth(),
      this.getViewHeight(),
      r
    )
    if (o) {
      const e = this.reference.referenceType
      const r = undefined
      if (
        this._camera instanceof OrthographicCamera &&
        (e === ReferenceType.CARTESIAN || e === ReferenceType.GRID)
      ) {
        let e = o
        const t = {}
        const r = Math.floor
        let i = e.asLook2D()
        this._mapNavigator.snapToScalesIfNeeded(i.scaleX, i.scaleY, r, t)
        i = e.asLook2D()
        i.scaleX = t.scalex
        i.scaleY = t.scaley
        e = e.look2D(i)
        o = e
      }
      if (t && t.animate) {
        const e = 'boolean' === typeof t.animate ? 4e3 : t.animate.duration
        const r = 'boolean' === typeof t.animate ? linear : t.animate.ease
        return this.mapNavigator._flyToAnimated(o, { duration: e, ease: r })
      } else {
        this.camera = o
        this.transformationChanged()
        return Promise.resolve()
      }
    } else
      throw new Error(
        'Could not restore saved map state: could not find matching camera position.'
      )
  }
  showContextMenu(e, t) {
    const r = new ContextMenu()
    this.onCreateContextMenu(r, t)
    if (r.items && r.items.length) {
      this.onShowContextMenu(e, r)
      return true
    } else return false
  }
  onCreateContextMenu(e, t) {
    if (t && t.layer) t.layer.onCreateContextMenu(e, this, t)
  }
  onShowContextMenu(e, t) {
    return
  }
  onClick(e) {
    return false
  }
  getViewWidth() {
    return 0 | this._viewBounds.width
  }
  getViewHeight() {
    return 0 | this._viewBounds.height
  }
  getViewOriginX() {
    return this.getViewWidth() / 2
  }
  getViewOriginY() {
    return this.getViewHeight() / 2
  }
  _ensureScaleValid() {
    if (this._scaleDirty) {
      if (this._mapNavigator) {
        this._scaleX = this._mapNavigator.computeScaleX()
        this._scaleY = this._mapNavigator.computeScaleY()
      } else this._scaleX = this._scaleY = 1
      this._scaleDirty = false
    }
  }
  getScaleAt(e) {
    const t = this._camera.getScaleAt(e)
    return this.mapNavigator.constraints.scale._convertYComponentPixelToMap(t)
  }
  getScaleX() {
    this._ensureScaleValid()
    return this._scaleX
  }
  getScaleY() {
    this._ensureScaleValid()
    return this._scaleY
  }
  isInBorder(e, t, r) {
    let i = false
    switch (e) {
      case Map.Border.BOTTOM:
        i =
          0 <= t &&
          t <= this._nodePosition.width &&
          this._nodePosition.height - this._border.bottom - this._border.top <=
            r &&
          r <= this._nodePosition.height
        break
      case Map.Border.LEFT:
        i =
          0 <= t &&
          t <= this._border.left &&
          0 <= r &&
          r <= this._nodePosition.width
        break
    }
    return i
  }
  getLeftBorderSize() {
    return this._border.left
  }
  getBottomBorderSize() {
    return this._border.bottom
  }
  isViewPointInView(e) {
    return (
      e.x >= this.getLeftBorderSize() &&
      e.x <= this.getLeftBorderSize() + this.getViewWidth() &&
      e.y >= 0 &&
      e.y <= this.getViewHeight()
    )
  }
  restrictNavigationToBounds(e, t) {
    if (this.is3D()) {
      Log.warn(
        'LuciadRIA WebGLMap currently does not support navigation restriction'
      )
      return
    }
    if (null === e) {
      this.mapNavigator.constraints.limitBounds = null
      return
    }
    if (!e || e.type !== ShapeType.BOUNDS)
      throw new ProgrammingError('constraint must be a bounds')
    this.mapNavigator.constraints.limitBounds.padding =
      t && t.padding ? t.padding : {}
    this.mapNavigator.constraints.limitBounds.bounds = e
  }
  getBoundsNavigationRestriction() {
    return this.mapNavigator.constraints.limitBounds.bounds
  }
  get constraintPadding() {
    return this.mapNavigator.constraints.limitBounds.padding
  }
  set constraintPadding(e) {
    throw new ProgrammingError('constraintPadding property is not mutable')
  }
  get constraintBounds() {
    if (
      !this.mapNavigator.constraints ||
      !this.mapNavigator.constraints.limitBounds.bounds
    )
      return null
    else return this.mapNavigator.constraints.limitBounds._boundsWorld
  }
  set constraintBounds(e) {
    throw new ProgrammingError('constraintBounds property is not mutable')
  }
  get viewBounds() {
    return this._viewBounds
  }
  set viewBounds(e) {
    throw new ProgrammingError('viewBounds property is not mutable')
  }
  getViewToMapTransformation() {
    let e =
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : LocationMode.TERRAIN
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    if (!this.is3D()) e = LocationMode.TERRAIN
    if (e === LocationMode.CLOSEST_SURFACE)
      return this._worldViewTransformation.getClosestSurfaceViewWorldTransformation(
        t
      )
    if (e === LocationMode.TERRAIN)
      return this._worldViewTransformation.getOnTerrainViewWorldTransformation()
    if (e === LocationMode.ELLIPSOID) {
      const e = t.heightOffset || 0
      return this._worldViewTransformation.getOnEllipsoidViewWorldTransformation(
        e
      )
    }
    throw new ProgrammingError(
      `Map#getViewToMapTransformation: Unrecognized LocationMode - ${e}`
    )
  }
  get mapToViewTransformation() {
    if (this._worldViewTransformation)
      return this._worldViewTransformation.getVisibleSurfaceWorldViewTransformation()
    return null
  }
  set mapToViewTransformation(e) {
    throw new ProgrammingError(
      'mapToViewTransformation property is not mutable'
    )
  }
  get viewToMapTransformation() {
    return this.getViewToMapTransformation(LocationMode.TERRAIN)
  }
  set viewToMapTransformation(e) {
    throw new ProgrammingError(
      'viewToMapTransformation property is not mutable'
    )
  }
  get worldSizeSupport() {
    return this._worldSizeSupport
  }
  getOnTerrainModelWorldTransformation(e) {
    const t = createTransformation(e, this.reference)
    if (this.is3D())
      return new ChainedTransformation(
        t,
        this._worldViewTransformation.getOnTerrainWorldWorldTransformation()
      )
    else return t
  }
  getMapToBorderViewTransformation(e) {
    if (e === PaintRepresentation.BODY || e === PaintRepresentation.LABEL)
      return this.mapToViewTransformation
    if (
      e === PaintRepresentation.LEFT_BORDER_BODY ||
      e === PaintRepresentation.LEFT_BORDER_LABEL
    ) {
      if (!this.MapToLeftBorderTransformation)
        this.MapToLeftBorderTransformation =
          getMapToLeftBorderTransformation(this)
      return this.MapToLeftBorderTransformation
    }
    if (
      e === PaintRepresentation.BOTTOM_BORDER_BODY ||
      e === PaintRepresentation.BOTTOM_BORDER_LABEL
    ) {
      if (!this.MapToBottomBorderTransformation)
        this.MapToBottomBorderTransformation =
          getMapToBottomBorderTransformation(this)
      return this.MapToBottomBorderTransformation
    }
    throw new Error(
      'Could not find transformation for paintrepresentation: ' + e
    )
  }
  get mapToViewTransformationInternal() {
    return this._worldViewTransformation
  }
  set mapToViewTransformationInternal(e) {
    throw new ProgrammingError(
      'mapToViewTransformationInternal property is not mutable'
    )
  }
  get viewToMapTransformationInternal() {
    return this._worldViewTransformation.inverseTransformation
  }
  set viewToMapTransformationInternal(e) {
    throw new ProgrammingError(
      'viewToMapTransformationInternal property is not mutable'
    )
  }
  get mapScale() {
    return [this.xScale, this.yScale]
  }
  set mapScale(e) {
    throw new ProgrammingError('mapScale property is not mutable')
  }
  get xScale() {
    if (this.destroyed) return 1
    return this.mapNavigator.constraints.scale._convertXComponentPixelToMap(
      this.getScaleX()
    )
  }
  set xScale(e) {
    throw new ProgrammingError('xScale property is not mutable')
  }
  get yScale() {
    if (this.destroyed) return 1
    return this.mapNavigator.constraints.scale._convertYComponentPixelToMap(
      this.getScaleY()
    )
  }
  set yScale(e) {
    throw new ProgrammingError('yScale property is not mutable')
  }
  get dotsPerCentimeter() {
    return DOTS_PER_CENTIMETER
  }
  set dotsPerCentimeter(e) {
    throw new ProgrammingError('dotsPerCentimeter property is not mutable')
  }
  get dotsPerInch() {
    return DPI
  }
  set dotsPerInch(e) {
    throw new ProgrammingError('dotsPerInch property is not mutable')
  }
  get viewSize() {
    return [this.getViewWidth(), this.getViewHeight()]
  }
  set viewSize(e) {
    throw new ProgrammingError('viewSize property is not mutable')
  }
  get totalSize() {
    return [
      this.getViewWidth() + this._border.left + this._border.right,
      this.getViewHeight() + this._border.bottom + this._border.top,
    ]
  }
  set totalSize(e) {
    throw new ProgrammingError('totalSize property is not mutable')
  }
  get border() {
    return {
      top: this._border.top,
      bottom: this._border.bottom,
      left: this._border.left,
      right: this._border.right,
    }
  }
  set border(e) {
    throw new ProgrammingError('border property is not mutable')
  }
  get mapBounds() {
    const e = createBounds(this._reference, [])
    this.getMapBoundsSFCT(e)
    return e
  }
  set mapBounds(e) {
    throw new ProgrammingError('mapBounds property is not mutable')
  }
  set controller(e) {
    this._controllerManager.controller = e
  }
  get controller() {
    return this._controllerManager.controller
  }
  get mapNavigator() {
    return this._mapNavigator
  }
  set mapNavigator(e) {
    throw new ProgrammingError('mapNavigator property is not mutable')
  }
  get reference() {
    return this._reference
  }
  set reference(e) {
    throw new ProgrammingError('reference property is not mutable')
  }
  isHovered(e, t) {
    const r = this.findLayerOrThrow(e.id)
    if ('function' !== typeof r.isHovered) return false
    return r.isHovered(t)
  }
  isSelected(e, t) {
    const r = this.findLayerOrThrow(e.id)
    if ('function' !== typeof r.isSelected) return false
    return r.isSelected(t)
  }
  set selectedObjects(e) {
    throw new ProgrammingError('selectedObjects property is not mutable')
  }
  get layerTree() {
    return this._layerTree
  }
  set layerTree(e) {
    throw new ProgrammingError('layerTree property is not mutable')
  }
  get domNode() {
    return this._domNode
  }
  set domNode(e) {
    throw new ProgrammingError('domNode property is not mutable')
  }
  get minMapScale() {
    return this.mapNavigator.constraints.scale.minScale
  }
  set minMapScale(e) {
    this.mapNavigator.constraints.scale.minScale = e
  }
  get maxMapScale() {
    return this.mapNavigator.constraints.scale.maxScale
  }
  set maxMapScale(e) {
    this.mapNavigator.constraints.scale.maxScale = e
  }
  get controllerManager() {
    return this._controllerManager
  }
  set controllerManager(e) {
    throw new ProgrammingError('controllerManager property is not mutable')
  }
  get effects() {
    return this._effects
  }
  set effects(e) {
    throw new ProgrammingError('effects property is not mutable')
  }
  get globeColor() {
    return this._globeColor
  }
  set globeColor(e) {
    if (canCreatePhotonColorFromString(e)) {
      const t = createPhotonColorFromString(e)
      this._globeColor = e
      if (
        this.viewPaintingStrategy &&
        this.viewPaintingStrategy.isOfPhotonType() &&
        !this.viewPaintingStrategy.webGLContextLost &&
        this.viewPaintingStrategy.terrainPainter
      ) {
        this.viewPaintingStrategy.terrainPainter.globeColor = t
        this.viewPaintingStrategy.terrainPainter.reset()
      }
    } else
      throw new Error(
        `Could not change globeColor to the requested ${e}. Please use a CSS color with the #aabbcc or rgba(255,255,255,1.0) notation. The globeColor will remain unmodified.`
      )
  }
  get camera() {
    return this._camera
  }
  set camera(e) {
    checkIsCameraValid(e, this.is3D())
    const t = e.constructor != this._camera.constructor
    this._camera = e
    this.viewPaintingStrategy?.updateCamera(e, t)
    this._worldViewTransformation?.updateCamera(e, t)
    this._mapNavigator?.updateCamera(e, t)
    this.transformationChanged()
  }
  get cameraAnimationKey() {
    return this._cameraAnimationKey
  }
  emit(e) {
    for (
      var t = arguments.length, r = new Array(t > 1 ? t - 1 : 0), i = 1;
      i < t;
      i++
    )
      r[i - 1] = arguments[i]
    this._eventedSupport.emit(e, r)
  }
  on(e, t, r) {
    return this._eventedSupport.on(e, t, r)
  }
}
function collectSelectedObjects(e, t) {
  if (isFunction(t.getSelectedFeatures)) {
    const r = t.getSelectedFeatures()
    if (r.length > 0) e.push({ layer: t, selected: r })
  }
  return e
}
function collectHoveredObjects(e, t) {
  if ('getHoveredFeatures' in t) {
    const r = t.getHoveredFeatures()
    if (r.length > 0) e.push({ layer: t, hovered: r })
  }
  return e
}
function checkIsCameraValid(e, t) {
  if (e instanceof PerspectiveCamera && !t)
    throw new ProgrammingError('You cannot use a PerspectiveCamera in 2D')
}
;(function (e) {
  let t = (function (e) {
    e[(e['BOTTOM'] = 1)] = 'BOTTOM'
    e[(e['LEFT'] = 0)] = 'LEFT'
    return e
  })({})
  e.Border = t
})(Map || (Map = {}))
export { Map }
