const KMLAliasNodeExport = nodeConstants('Alias')
export const KML_ALIAS = KMLAliasNodeExport.nm
export const isAliasNode = KMLAliasNodeExport.fn
const KMLBalloonStyleNodeExport = nodeConstants('BalloonStyle')
export const KML_BALLOON_STYLE = KMLBalloonStyleNodeExport.nm
export const isBalloonStyleNode = KMLBalloonStyleNodeExport.fn
const KMLCameraNodeExport = nodeConstants('Camera')
export const KML_CAMERA = KMLCameraNodeExport.nm
export const isCameraNode = KMLCameraNodeExport.fn
const KMLDocumentNodeExport = nodeConstants('Document')
export const KML_DOCUMENT = KMLDocumentNodeExport.nm
export const isDocumentNode = KMLDocumentNodeExport.fn
const KMLFolderNodeExport = nodeConstants('Folder')
export const KML_FOLDER = KMLFolderNodeExport.nm
export const isFolderNode = KMLFolderNodeExport.fn
const KMLGroundOverlayNodeExport = nodeConstants('GroundOverlay')
export const KML_GROUND_OVERLAY = KMLGroundOverlayNodeExport.nm
export const isGroundOverlayNode = KMLGroundOverlayNodeExport.fn
const KMLIconNodeExport = nodeConstants('Icon')
export const KML_ICON = KMLIconNodeExport.nm
export const isIconNode = KMLIconNodeExport.fn
const KMLIconStyleNodeExport = nodeConstants('IconStyle')
export const KML_ICON_STYLE = KMLIconStyleNodeExport.nm
export const isIconStyleNode = KMLIconStyleNodeExport.fn
const KMLLabelStyleNodeExport = nodeConstants('LabelStyle')
export const KML_LABEL_STYLE = KMLLabelStyleNodeExport.nm
export const isLabelStyleNode = KMLLabelStyleNodeExport.fn
const KMLLatLonAltBoxNodeExport = nodeConstants('LatLonAltBox')
export const KML_LAT_LON_ALT_BOX = KMLLatLonAltBoxNodeExport.nm
export const isLatLonAltBoxNode = KMLLatLonAltBoxNodeExport.fn
const KMLLatLonBoxNodeExport = nodeConstants('LatLonBox')
export const KML_LAT_LON_BOX = KMLLatLonBoxNodeExport.nm
export const isLatLonBoxNode = KMLLatLonBoxNodeExport.fn
const KMLLatLonQuadNodeExport = nodeConstants('gx:LatLonQuad')
export const KML_LAT_LON_QUAD = KMLLatLonQuadNodeExport.nm
export const isLatLonQuadNode = KMLLatLonQuadNodeExport.fn
const KMLLinearRingNodeExport = nodeConstants('LinearRing')
export const KML_LINEAR_RING = KMLLinearRingNodeExport.nm
export const isLinearRingNode = KMLLinearRingNodeExport.fn
const KMLLineStringNodeExport = nodeConstants('LineString')
export const KML_LINE_STRING = KMLLineStringNodeExport.nm
export const isLineStringNode = KMLLineStringNodeExport.fn
const KMLLineStyleNodeExport = nodeConstants('LineStyle')
export const KML_LINE_STYLE = KMLLineStyleNodeExport.nm
export const isLineStyleNode = KMLLineStyleNodeExport.fn
const KMLLinkNodeExport = nodeConstants('Link')
export const KML_LINK_BASE = KMLLinkNodeExport.nm
export const isLinkNodeBase = KMLLinkNodeExport.fn
const KMLListStyleNodeExport = nodeConstants('ListStyle')
export const KML_LIST_STYLE = KMLListStyleNodeExport.nm
export const isListStyleNode = KMLListStyleNodeExport.fn
const KMLLocationNodeExport = nodeConstants('Location')
export const KML_LOCATION = KMLLocationNodeExport.nm
export const isLocationNode = KMLLocationNodeExport.fn
const KMLLodNodeExport = nodeConstants('Lod')
export const KML_LOD = KMLLodNodeExport.nm
export const isLodNode = KMLLodNodeExport.fn
const KMLLookAtNodeExport = nodeConstants('LookAt')
export const KML_LOOK_AT = KMLLookAtNodeExport.nm
export const isLookAtNode = KMLLookAtNodeExport.fn
const KMLModelNodeExport = nodeConstants('Model')
export const KML_MODEL = KMLModelNodeExport.nm
export const isModelNode = KMLModelNodeExport.fn
const KMLMultiGeometryNodeExport = nodeConstants('MultiGeometry')
export const KML_MULTI_GEOMETRY = KMLMultiGeometryNodeExport.nm
export const isMultiGeometryNode = KMLMultiGeometryNodeExport.fn
const KMLNetworkLinkNodeExport = nodeConstants('NetworkLink')
export const KML_NETWORK_LINK = KMLNetworkLinkNodeExport.nm
export const isNetworkLinkNode = KMLNetworkLinkNodeExport.fn
const KMLOrientationNodeExport = nodeConstants('Orientation')
export const KML_ORIENTATION = KMLOrientationNodeExport.nm
export const isOrientationNode = KMLOrientationNodeExport.fn
const KMLPairNodeExport = nodeConstants('Pair')
export const KML_PAIR = KMLPairNodeExport.nm
export const isPairNode = KMLPairNodeExport.fn
const KMLPhotoOverlayNodeExport = nodeConstants('PhotoOverlay')
export const KML_PHOTO_OVERLAY = KMLPhotoOverlayNodeExport.nm
export const isPhotoOverlayNode = KMLPhotoOverlayNodeExport.fn
const KMLPlacemarkNodeExport = nodeConstants('Placemark')
export const KML_PLACEMARK = KMLPlacemarkNodeExport.nm
export const isPlacemarkNode = KMLPlacemarkNodeExport.fn
const KMLPointNodeExport = nodeConstants('Point')
export const KML_POINT = KMLPointNodeExport.nm
export const isPointNode = KMLPointNodeExport.fn
const KMLPolygonNodeExport = nodeConstants('Polygon')
export const KML_POLYGON = KMLPolygonNodeExport.nm
export const isPolygonNode = KMLPolygonNodeExport.fn
const KMLPolyStyleNodeExport = nodeConstants('PolyStyle')
export const KML_POLY_STYLE = KMLPolyStyleNodeExport.nm
export const isPolyStyleNode = KMLPolyStyleNodeExport.fn
const KMLRegionNodeExport = nodeConstants('Region')
export const KML_REGION = KMLRegionNodeExport.nm
export const isRegionNode = KMLRegionNodeExport.fn
const KMLResourceMapNodeExport = nodeConstants('ResourceMap')
export const KML_RESOURCE_MAP = KMLResourceMapNodeExport.nm
export const isResourceMapNode = KMLResourceMapNodeExport.fn
const KMLScaleNodeExport = nodeConstants('Scale')
export const KML_SCALE = KMLScaleNodeExport.nm
export const isScaleNode = KMLScaleNodeExport.fn
const KMLScreenOverlayNodeExport = nodeConstants('ScreenOverlay')
export const KML_SCREEN_OVERLAY = KMLScreenOverlayNodeExport.nm
export const isScreenOverlayNode = KMLScreenOverlayNodeExport.fn
const KMLStyleNodeExport = nodeConstants('Style')
export const KML_STYLE = KMLStyleNodeExport.nm
export const isStyleNode = KMLStyleNodeExport.fn
const KMLStyleMapNodeExport = nodeConstants('StyleMap')
export const KML_STYLE_MAP = KMLStyleMapNodeExport.nm
export const isStyleMapNode = KMLStyleMapNodeExport.fn
const KMLTimeSpanNodeExport = nodeConstants('TimeSpan')
export const KML_TIME_SPAN = KMLTimeSpanNodeExport.nm
export const isTimeSpanNode = KMLTimeSpanNodeExport.fn
const KMLTimeStampNodeExport = nodeConstants('TimeStamp')
export const KML_TIME_STAMP = KMLTimeStampNodeExport.nm
export const isTimeStampNode = KMLTimeStampNodeExport.fn
const KMLAddressValueExport = nodeConstants('address')
export const KML_ADDRESS = KMLAddressValueExport.nm
export const isAddressValue = KMLAddressValueExport.fn
const KMLAltitudeValueExport = nodeConstants('altitude')
export const KML_ALTITUDE = KMLAltitudeValueExport.nm
export const isAltitudeValue = KMLAltitudeValueExport.fn
const KMLAltitudeModeValueExport = nodeConstants('altitudeMode')
export const KML_ALTITUDE_MODE = KMLAltitudeModeValueExport.nm
export const isAltitudeModeValue = KMLAltitudeModeValueExport.fn
const KMLAtomAuthorValueExport = nodeConstants('atom:author')
export const KML_ATOM_AUTHOR = KMLAtomAuthorValueExport.nm
export const isAtomAuthorValue = KMLAtomAuthorValueExport.fn
const KMLAtomLinkValueExport = nodeConstants('atom:link')
export const KML_ATOM_LINK = KMLAtomLinkValueExport.nm
export const isAtomLinkValue = KMLAtomLinkValueExport.fn
const KMLBgColorValueExport = nodeConstants('bgColor')
export const KML_BG_COLOR = KMLBgColorValueExport.nm
export const isBgColorValue = KMLBgColorValueExport.fn
const KMLColorValueExport = nodeConstants('color')
export const KML_COLOR = KMLColorValueExport.nm
export const isColorValue = KMLColorValueExport.fn
const KMLColorModeValueExport = nodeConstants('colorMode')
export const KML_COLOR_MODE = KMLColorModeValueExport.nm
export const isColorModeValue = KMLColorModeValueExport.fn
const KMLCoordinatesValueExport = nodeConstants('coordinates')
export const KML_COORDINATES = KMLCoordinatesValueExport.nm
export const isCoordinatesValue = KMLCoordinatesValueExport.fn
const KMLDescriptionValueExport = nodeConstants('description')
export const KML_DESCRIPTION = KMLDescriptionValueExport.nm
export const isDescriptionValue = KMLDescriptionValueExport.fn
const KMLDisplayModeValueExport = nodeConstants('displayMode')
export const KML_DISPLAY_MODE = KMLDisplayModeValueExport.nm
export const isDisplayModeValue = KMLDisplayModeValueExport.fn
const KMLDrawOrderValueExport = nodeConstants('drawOrder')
export const KML_DRAW_ORDER = KMLDrawOrderValueExport.nm
export const isDrawOrderValue = KMLDrawOrderValueExport.fn
const KMLExtendedDataNodeExport = nodeConstants('ExtendedData')
export const KML_EXTENDED_DATA = KMLExtendedDataNodeExport.nm
export const isExtendedDataNode = KMLExtendedDataNodeExport.fn
const KMLExtrudeValueExport = nodeConstants('extrude')
export const KML_EXTRUDE = KMLExtrudeValueExport.nm
export const isExtrudeValue = KMLExtrudeValueExport.fn
const KMLFillValueExport = nodeConstants('fill')
export const KML_FILL = KMLFillValueExport.nm
export const isFillValue = KMLFillValueExport.fn
const KMLFlyToViewValueExport = nodeConstants('flyToView')
export const KML_FLY_TO_VIEW = KMLFlyToViewValueExport.nm
export const isFlyToViewValue = KMLFlyToViewValueExport.fn
const KMLHeadingValueExport = nodeConstants('heading')
export const KML_HEADING = KMLHeadingValueExport.nm
export const isHeadingValue = KMLHeadingValueExport.fn
const KMLHotSpotValueExport = nodeConstants('hotSpot')
export const KML_HOT_SPOT = KMLHotSpotValueExport.nm
export const isHotSpotValue = KMLHotSpotValueExport.fn
const KMLHttpQueryValueExport = nodeConstants('httpQuery')
export const KML_HTTP_QUERY = KMLHttpQueryValueExport.nm
export const isHttpQueryValue = KMLHttpQueryValueExport.fn
const KMLHrefValueExport = nodeConstants('href')
export const KML_ICON_HREF = KMLHrefValueExport.nm
export const isHrefValue = KMLHrefValueExport.fn
const KMLInnerBoundaryIsNodeExport = nodeConstants('innerBoundaryIs')
export const KML_INNER_BOUNDARY_IS = KMLInnerBoundaryIsNodeExport.nm
export const isInnerBoundaryIsNode = KMLInnerBoundaryIsNodeExport.fn
const KMLItemIconValueExport = nodeConstants('ItemIcon')
export const KML_ITEM_ICON = KMLItemIconValueExport.nm
export const isItemIcon = KMLItemIconValueExport.fn
const KMLKeyValueExport = nodeConstants('key')
export const KML_KEY = KMLKeyValueExport.nm
export const isKeyValue = KMLKeyValueExport.fn
const KMLLatitudeValueExport = nodeConstants('latitude')
export const KML_LATITUDE = KMLLatitudeValueExport.nm
export const isLatitudeValue = KMLLatitudeValueExport.fn
const KMLListItemTypeValueExport = nodeConstants('listItemType')
export const KML_LIST_ITEM_TYPE = KMLListItemTypeValueExport.nm
export const isListItemTypeValue = KMLListItemTypeValueExport.fn
const KMLLongitudeValueExport = nodeConstants('longitude')
export const KML_LONGITUDE = KMLLongitudeValueExport.nm
export const isLongitudeValue = KMLLongitudeValueExport.fn
const KMLNameValueExport = nodeConstants('name')
export const KML_NAME = KMLNameValueExport.nm
export const isNameValue = KMLNameValueExport.fn
const KMLOpenValueExport = nodeConstants('open')
export const KML_OPEN = KMLOpenValueExport.nm
export const isOpenValue = KMLOpenValueExport.fn
const KMLOuterBoundaryIsNodeExport = nodeConstants('outerBoundaryIs')
export const KML_OUTER_BOUNDARY_IS = KMLOuterBoundaryIsNodeExport.nm
export const isOuterBoundaryIsNode = KMLOuterBoundaryIsNodeExport.fn
const KMLOutlineValueExport = nodeConstants('outline')
export const KML_OUTLINE = KMLOutlineValueExport.nm
export const isOutlineValue = KMLOutlineValueExport.fn
const KMLOverlayXYValueExport = nodeConstants('overlayXY')
export const KML_OVERLAY_XY = KMLOverlayXYValueExport.nm
export const isOverlayXYValue = KMLOverlayXYValueExport.fn
const KMLPhoneNumberValueExport = nodeConstants('phoneNumber')
export const KML_PHONE_NUMBER = KMLPhoneNumberValueExport.nm
export const isPhoneNumberValue = KMLPhoneNumberValueExport.fn
const KMLRefreshIntervalValueExport = nodeConstants('refreshInterval')
export const KML_REFRESH_INTERVAL = KMLRefreshIntervalValueExport.nm
export const isRefreshIntervalValue = KMLRefreshIntervalValueExport.fn
const KMLRefreshModeValueExport = nodeConstants('refreshMode')
export const KML_REFRESH_MODE = KMLRefreshModeValueExport.nm
export const isRefreshModeValue = KMLRefreshModeValueExport.fn
const KMLRefreshVisibilityValueExport = nodeConstants('refreshVisibility')
export const KML_REFRESH_VISIBILITY = KMLRefreshVisibilityValueExport.nm
export const isRefreshVisibilityValue = KMLRefreshVisibilityValueExport.fn
const KMLRotationValueExport = nodeConstants('rotation')
export const KML_ROTATION = KMLRotationValueExport.nm
export const isRotationValue = KMLRotationValueExport.fn
const KMLRotationXYValueExport = nodeConstants('rotationXY')
export const KML_ROTATION_XY = KMLRotationXYValueExport.nm
export const isRotationXYValue = KMLRotationXYValueExport.fn
const KMLRollValueExport = nodeConstants('roll')
export const KML_ROLL = KMLRollValueExport.nm
export const isRollValue = KMLRollValueExport.fn
const KMLScaleValueExport = nodeConstants('scale')
export const KML_SCALE_VAL = KMLScaleValueExport.nm
export const isScaleValue = KMLScaleValueExport.fn
const KMLScreenXYValueExport = nodeConstants('screenXY')
export const KML_SCREEN_XY = KMLScreenXYValueExport.nm
export const isScreenXYValue = KMLScreenXYValueExport.fn
const KMLSizeValueExport = nodeConstants('size')
export const KML_SIZE = KMLSizeValueExport.nm
export const isSizeValue = KMLSizeValueExport.fn
const KMLSnippetValueExport = nodeConstants('Snippet')
export const KML_SNIPPET = KMLSnippetValueExport.nm
export const isSnippetValue = KMLSnippetValueExport.fn
const KMLSourceHrefValueExport = nodeConstants('sourceHref')
export const KML_SOURCE_HREF = KMLSourceHrefValueExport.nm
export const isSourceHrefValue = KMLSourceHrefValueExport.fn
const KMLStateValueExport = nodeConstants('state')
export const KML_STATE = KMLStateValueExport.nm
export const isStateValue = KMLStateValueExport.fn
const KMLStyleUrlValueExport = nodeConstants('styleUrl')
export const KML_STYLE_URL = KMLStyleUrlValueExport.nm
export const isStyleUrlValue = KMLStyleUrlValueExport.fn
const KMLTargetHrefValueExport = nodeConstants('targetHref')
export const KML_TARGET_HREF = KMLTargetHrefValueExport.nm
export const isTargetHrefValue = KMLTargetHrefValueExport.fn
const KMLTextValueExport = nodeConstants('text')
export const KML_TEXT = KMLTextValueExport.nm
export const isTextValue = KMLTextValueExport.fn
const KMLTextColorValueExport = nodeConstants('textColor')
export const KML_TEXT_COLOR = KMLTextColorValueExport.nm
export const isTextColorValue = KMLTextColorValueExport.fn
const KMLTiltValueExport = nodeConstants('tilt')
export const KML_TILT = KMLTiltValueExport.nm
export const isTiltValue = KMLTiltValueExport.fn
const KMLViewBoundScaleValueExport = nodeConstants('viewBoundScale')
export const KML_VIEW_BOUND_SCALE = KMLViewBoundScaleValueExport.nm
export const isViewBoundScaleValue = KMLViewBoundScaleValueExport.fn
const KMLViewFormatValueExport = nodeConstants('viewFormat')
export const KML_VIEW_FORMAT = KMLViewFormatValueExport.nm
export const isViewFormatValue = KMLViewFormatValueExport.fn
const KMLViewRefreshModeValueExport = nodeConstants('viewRefreshMode')
export const KML_VIEW_REFRESH_MODE = KMLViewRefreshModeValueExport.nm
export const isViewRefreshModeValue = KMLViewRefreshModeValueExport.fn
const KMLViewRefreshTimeValueExport = nodeConstants('viewRefreshTime')
export const KML_VIEW_REFRESH_TIME = KMLViewRefreshTimeValueExport.nm
export const isViewRefreshTimeValue = KMLViewRefreshTimeValueExport.fn
const KMLVisibilityValueExport = nodeConstants('visibility')
export const KML_VISIBILITY = KMLVisibilityValueExport.nm
export const isVisibilityValue = KMLVisibilityValueExport.fn
const KMLWidthValueExport = nodeConstants('width')
export const KML_WIDTH = KMLWidthValueExport.nm
export const isWidthValue = KMLWidthValueExport.fn
const KMLXalAddressDetailsNodeExport = nodeConstants('xal:AddressDetails')
export const KML_XAL_ADDRESS_DETAILS = KMLXalAddressDetailsNodeExport.nm
export const isXalAddressDetailsNode = KMLXalAddressDetailsNodeExport.fn
const KMLXValueExport = nodeConstants('x')
export const KML_X = KMLXValueExport.nm
export const isXValue = KMLXValueExport.fn
const KMLYValueExport = nodeConstants('y')
export const KML_Y = KMLYValueExport.nm
export const isYValue = KMLYValueExport.fn
const KMLZValueExport = nodeConstants('z')
export const KML_Z = KMLZValueExport.nm
export const isZValue = KMLZValueExport.fn
const KMLEastValueExport = nodeConstants('east')
export const KML_EAST = KMLEastValueExport.nm
export const isEastValue = KMLEastValueExport.fn
const KMLWestValueExport = nodeConstants('west')
export const KML_WEST = KMLWestValueExport.nm
export const isWestValue = KMLWestValueExport.fn
const KMLNorthValueExport = nodeConstants('north')
export const KML_NORTH = KMLNorthValueExport.nm
export const isNorthValue = KMLNorthValueExport.fn
const KMLSouthValueExport = nodeConstants('south')
export const KML_SOUTH = KMLSouthValueExport.nm
export const isSouthValue = KMLSouthValueExport.fn
const KMLBeginValueExport = nodeConstants('begin')
export const KML_BEGIN = KMLBeginValueExport.nm
export const isBeginValue = KMLBeginValueExport.fn
const KMLEndValueExport = nodeConstants('end')
export const KML_END = KMLEndValueExport.nm
export const isEndValue = KMLEndValueExport.fn
const KMLAbstractViewNode = nodeConstants(KML_CAMERA, KML_LOOK_AT)
export const KML_ABSTRACT_VIEW = KMLAbstractViewNode.nm
export const isAbstractViewNode = KMLAbstractViewNode.fn
const KMLOverlayNodeExport = nodeConstants(
  KML_PHOTO_OVERLAY,
  KML_GROUND_OVERLAY,
  KML_SCREEN_OVERLAY
)
export const KML_OVERLAY = KMLOverlayNodeExport.nm
export const isOverlayNode = KMLOverlayNodeExport.fn
const KMLColorStyleNodeExport = nodeConstants(
  KML_ICON_STYLE,
  KML_LABEL_STYLE,
  KML_LINE_STYLE,
  KML_POLY_STYLE
)
export const KML_COLOR_STYLE = KMLColorStyleNodeExport.nm
export const isColorStyleNode = KMLColorStyleNodeExport.fn
const KMLContainerNodeExport = nodeConstants(KML_DOCUMENT, KML_FOLDER)
export const KML_CONTAINER = KMLContainerNodeExport.nm
export const isContainerNode = KMLContainerNodeExport.fn
const KMLFeatureNodeExport = nodeConstants(
  KML_NETWORK_LINK,
  KML_PLACEMARK,
  KML_GROUND_OVERLAY,
  ...KML_CONTAINER,
  ...KML_OVERLAY
)
export const KML_FEATURE = KMLFeatureNodeExport.nm
export const isFeatureNode = KMLFeatureNodeExport.fn
const KMLGeometryNodeExport = nodeConstants(
  KML_LINEAR_RING,
  KML_LINE_STRING,
  KML_MODEL,
  KML_MULTI_GEOMETRY,
  KML_POINT,
  KML_POLYGON
)
export const KML_GEOMETRY = KMLGeometryNodeExport.nm
export const isGeometryNode = KMLGeometryNodeExport.fn
const KMLGroundOverlayGeometryExport = nodeConstants(
  KML_LAT_LON_BOX,
  KML_LAT_LON_QUAD
)
export const KML_GROUND_OVERLAY_GEOMETRY = KMLGroundOverlayGeometryExport.nm
export const isGroundOverlayGeometryNode = KMLGroundOverlayGeometryExport.fn
const KMLLinkIconNodeExport = nodeConstants(KML_LINK_BASE, KML_ICON)
export const KML_LINK = KMLLinkIconNodeExport.nm
export const isLinkNode = KMLLinkIconNodeExport.fn
const KMLStyleSelectorNodeExport = nodeConstants(KML_STYLE, KML_STYLE_MAP)
export const KML_STYLE_SELECTOR = KMLStyleSelectorNodeExport.nm
export const isStyleSelectorNode = KMLStyleSelectorNodeExport.fn
const KMLSubStyleNodeExport = nodeConstants(
  KML_BALLOON_STYLE,
  KML_LIST_STYLE,
  ...KML_COLOR_STYLE
)
export const KML_SUB_STYLE = KMLSubStyleNodeExport.nm
export const isSubStyleNode = KMLSubStyleNodeExport.fn
const KMLTimePrimitiveNodeExport = nodeConstants(KML_TIME_SPAN, KML_TIME_STAMP)
export const KML_TIME_PRIMITIVE = KMLTimePrimitiveNodeExport.nm
export const isTimePrimitiveNode = KMLTimePrimitiveNodeExport.fn
function nodeIdentFactory() {
  for (var o = arguments.length, t = new Array(o), e = 0; e < o; e++)
    t[e] = arguments[e]
  if (1 === t.length) {
    const o = t[0]
    return function (t) {
      return t.nodeName === o
    }
  }
  return function (o) {
    const { nodeName: e } = o
    for (const o of t) if (e === o) return true
    return false
  }
}
function nodeConstants(o) {
  for (
    var t = arguments.length, e = new Array(t > 1 ? t - 1 : 0), n = 1;
    n < t;
    n++
  )
    e[n - 1] = arguments[n]
  const r = undefined
  const s = undefined
  return { fn: nodeIdentFactory(o, ...e), nm: e.length ? [o, ...e] : o }
}
