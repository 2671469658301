import { ArrayCursor } from './ArrayCursor.js'
import { SimpleBounds } from '../../view/feature/SimpleBounds.js'
import { isArray, isFunction } from '../../util/Lang.js'
import { RTreeIndex } from '../../view/feature/RTreeIndex.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { MemoryStoreBase } from './MemoryStoreBase.js'
export class MemorySpatialStore extends MemoryStoreBase {
  constructor(e) {
    super()
    const r = isArray(e.data) ? e.data : []
    const t = e.reference || (r[0] && r[0].shape ? r[0].shape.reference : null)
    if (t && t.referenceType === ReferenceType.GEOCENTRIC)
      throw new ProgrammingError(
        'MemorySpatialStore cannot be created to store data with a geocentric reference'
      )
    this._rTree = new RTreeIndex(t)
    r.forEach((e) => {
      e.id = this.getValidFeatureId(e.id)
      this._rTree.insert(wrapWithSimpleBounds(e))
    })
  }
  getIterable() {
    return unboxFeatureIterator(this._rTree.getNodeIterator())
  }
  spatialQuery(e, r, t) {
    const o = []
    this._rTree.search(e, getCollector(o, r))
    return new ArrayCursor(o)
  }
  get(e) {
    const r = this._rTree.getNode(e)
    return r ? r.feature : void 0
  }
  clear() {
    this._rTree.getIdMapSnapshot().forEach((e) => {
      const r = e.feature
      this._eventedSupport.emitStoreChangedEvent('remove', r, r.id)
    })
    this._rTree.clear()
    return true
  }
  has(e) {
    return !!this.get(e)
  }
  internalAdd(e) {
    e.id = this.getValidFeatureId(e.id)
    this._rTree.insert(wrapWithSimpleBounds(e))
    this._eventedSupport.emitStoreChangedEvent('add', e, e.id)
    return e.id
  }
  internalUpdate(e) {
    this._rTree.remove(e.id)
    this._rTree.insert(wrapWithSimpleBounds(e))
    this._eventedSupport.emitStoreChangedEvent('update', e, e.id)
    return e.id
  }
  internalRemove(e) {
    const r = this._rTree.remove(e)
    if (r) return r.feature
    return null
  }
  get size() {
    return this._rTree.size()
  }
}
function wrapWithSimpleBounds(e) {
  const r = new SimpleBounds()
  if (e.shape && e.shape.bounds) {
    const { x: t, y: o, width: n, height: i } = e.shape.bounds
    r.left = t
    r.right = t + n
    r.bottom = o
    r.top = o + i
  }
  return { feature: e, shape: e.shape, bounds: r }
}
function getCollector(e, r) {
  return (t) => {
    const o = t.feature
    if (isFunction(r)) {
      if (r(o)) e.push(o)
    } else e.push(o)
  }
}
function unboxFeatureIterator(e) {
  return {
    next: function () {
      const r = e.next()
      return { done: r.done, value: r.done ? r.value : r.value.feature }
    },
    [Symbol.iterator]: function () {
      return this
    },
  }
}
