import { XYZPoint } from '../../shape/XYZPoint.js'
import { XYZBounds } from '../../shape/XYZBounds.js'
import { DrawBaseLabelHtmlContents } from './DrawBaseLabelHtmlContents.js'
import { LabelPositions } from './LabelPositions.js'
import { AnchorPoints } from './AnchorPoints.js'
import { drawNullCommand } from './NullDrawCommand.js'
import { INVALID_LAYER_CLIP } from '../LayerStyle.js'
const anchorPoints = new AnchorPoints()
const anchorPoint = new XYZPoint()
const anchorViewPoint = new XYZPoint()
const bodyViewBoundsSFCT = new XYZBounds(null)
const labelBoundsSFCT = new XYZBounds(null)
const ALLOWED_POSITIONS = [0]
export class DrawInPathLabelHtmlContents extends DrawBaseLabelHtmlContents {
  _tmpPos = { x: Number.NaN, y: Number.NaN, z: Number.NaN }
  _tmpPosDef = { x: Number.NaN, y: Number.NaN, z: Number.NaN }
  constructor(t, s, i, o, n, e, r) {
    super(i, s, t, o, n, r.padding, e)
    this._inView = r.inView
    this._restrictToBounds = r.restrictToBounds
  }
  get tmpPos() {
    return this._tmpPos
  }
  requestToDrawLabel(t) {
    this.clip = t ?? INVALID_LAYER_CLIP
    this.group.addLabel(this)
  }
  placeHtml(t, s, i, o) {
    if (!this.drawCommand || this.drawCommand === drawNullCommand) return false
    this.zOrder = o
    if (isNaN(this._tmpPos.x)) this.getAnchor(s, this._tmpPos, this._inView)
    if (this._inView) {
      if (!this.getAnchorInView(s)) return false
    } else if (!this.setViewPoint(this._tmpPos)) return false
    if (!isNaN(this._tmpPos.x)) {
      const o = undefined
      if (
        this.findLabelPosition(
          anchorViewPoint,
          LabelPositions.INVALID_POSITION,
          i,
          ALLOWED_POSITIONS,
          1
        ) !== LabelPositions.INVALID_POSITION &&
        this.checkRestrictToBounds(anchorViewPoint, s)
      )
        if (this.isValidLocation(anchorPoint)) {
          this.toScreen(t)
          return true
        }
    }
    return false
  }
  checkRestrictToBounds(t, s) {
    if (!this._restrictToBounds) return true
    return this.isLabelInBodyBox(t, s)
  }
  getAnchor(t, s, i) {
    anchorPoints.reset()
    anchorPoints.preferredCentroid = true
    anchorPoints.inView = i
    this.drawCommand?.mapAnchorPointsSFCT(t, anchorPoints, 0)
    if (anchorPoints.anchorPointsLength > 0) {
      s.x = anchorPoints.anchorPoints[0]
      s.y = anchorPoints.anchorPoints[1]
      s.z = anchorPoints.anchorPoints[2]
    }
  }
  isLabelInBodyBox(t, s) {
    if (!this.drawCommand?.bounds) return true
    try {
      s.mapToViewTransformation.transformBounds(
        this.drawCommand.bounds,
        bodyViewBoundsSFCT
      )
    } catch (t) {
      return false
    }
    if (!this._halfWidth || !this._halfHeight) return false
    labelBoundsSFCT.setTo2D(
      t.x - this._halfWidth,
      2 * this._halfWidth,
      t.y - this._halfHeight,
      2 * this._halfHeight
    )
    const i = bodyViewBoundsSFCT.contains2DBounds(labelBoundsSFCT)
    if (!i && this._inView) clear(this._tmpPos)
    return i
  }
  isWorldPointInView(t) {
    if (!this.setViewPoint(t)) return false
    return this.labelContext.isViewPointInView(anchorViewPoint)
  }
  getAnchorInView(t) {
    if (isNaN(this._tmpPosDef.x)) this.getAnchor(t, this._tmpPosDef, false)
    if (this.isWorldPointInView(this._tmpPosDef)) {
      assignXyz(this._tmpPos, this._tmpPosDef)
      return true
    }
    if (this.isWorldPointInView(this._tmpPos)) return true
    this.getAnchor(t, this._tmpPos, true)
    return this.isWorldPointInView(this._tmpPos)
  }
  setViewPoint(t) {
    assignXyz(anchorPoint, t)
    return !!this.drawCommand?.viewPointSFCT(
      anchorPoint,
      anchorViewPoint,
      this.labelContext.mapToViewTransformation
    )
  }
  resetLabelBounds(t, s) {
    const {
      boundsWidth: i,
      ascent: o,
      descent: n,
      boundsX: e,
    } = this.getBoundMetrics()
    const r = i
    const a = t.x - (r >> 1)
    const h = t.y - (o >> 1)
    this.setLTRBAnchorXYAngleDeg(
      a,
      h,
      a + r,
      h + o + n,
      a - e,
      h + o,
      0,
      this._padding
    )
  }
  reuseStateFrom(t) {
    if (this.drawCommand) {
      const s = t.findLabelDrawCommand(
        this.drawCommand,
        DrawInPathLabelHtmlContents
      )
      if (s) assignXyz(this._tmpPos, s.tmpPos)
    }
  }
  computeMetrics() {
    const { boundsWidth: t, boundsHeight: s } = this.computeMetricsBase(
      this._restrictToBounds
    )
    if (this._restrictToBounds) {
      this._halfWidth = t / 2
      this._halfHeight = s / 2
    }
  }
  toScreen(t) {
    this.toScreenBase(t)
  }
}
function clear(t) {
  t.x = Number.NaN
  t.y = Number.NaN
  t.z = Number.NaN
}
function assignXyz(t, s) {
  t.x = s.x
  t.y = s.y
  t.z = s.z
}
