import { ProjectionType } from './ProjectionType.js'
import { Mercator } from './Mercator.js'
export class PseudoMercator extends Mercator {
  constructor() {
    super()
  }
  geodetic2cartesianOnEllipsoidSFCT(e, t, r) {
    return this.geodetic2cartesianOnSphereSFCT(e, t.a, r)
  }
  cartesian2geodeticOnEllipsoidSFCT(e, t, r) {
    return this.cartesian2geodeticOnSphereSFCT(e, t.a, r)
  }
  inWorldBoundsOnEllipsoid(e, t) {
    return this.inWorldBoundsOnSphere(e, t.a)
  }
  cartesianBoundsOnEllipsoidSFCT(e, t) {
    this.cartesianBoundsOnSphereSFCT(e.auxRadius, t)
  }
  encode() {
    return {
      type: 'PseudoMercator',
      centralMeridian: this.getCentralMeridian(),
      trueScaleLatitude: this.getTrueScaleLatitude(),
      latitudeMin: this.getLatitudeMin(),
      latitudeMax: this.getLatitudeMax(),
    }
  }
  get TYPE() {
    return (
      ProjectionType.PSEUDO_MERCATOR +
      ProjectionType.MERCATOR +
      ProjectionType.CYLINDRICAL
    )
  }
}
