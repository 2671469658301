import { CapabilitiesParserUtil } from './common/CapabilitiesParserUtil.js'
class WMTSCapabilitiesLayer {
  constructor(t) {
    this._title = t['title']
    this._abstract = t['abstract']
    this._keywords = t['keywords']
    this._formats = t['formats']
    this._identifier = t['identifier']
    this._styles = t['styles']
    this._infoFormats = t['infoFormats']
    this._dimensions = t['dimensions']
    this._tileMatrixSets = t['tileMatrixSets']
    this._resourceUrls = t['resourceUrls']
    this._themes = t['themes']
    this._bounds = t.bounds.concat(t.wgs84Bounds)
  }
  get title() {
    return this._title
  }
  get abstract() {
    return this._abstract
  }
  get keywords() {
    return this._keywords
  }
  get formats() {
    return this._formats
  }
  get identifier() {
    return this._identifier
  }
  get styles() {
    return this._styles
  }
  get infoFormats() {
    return this._infoFormats
  }
  get dimensions() {
    return this._dimensions
  }
  get tileMatrixSets() {
    return this._tileMatrixSets
  }
  get resourceUrls() {
    return this._resourceUrls
  }
  get themes() {
    return this._themes
  }
  getBounds(t) {
    return CapabilitiesParserUtil.getBounds(this._bounds, t)
  }
}
export { WMTSCapabilitiesLayer }
