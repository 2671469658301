import { ProgrammingError } from '../error/ProgrammingError.js'
import { isNumber } from '../util/Lang.js'
import { isOfShapeType, Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { simplePointCopy } from './SimplePoint.js'
import { ShapeUtil } from './ShapeUtil.js'
import {
  coordinateToSimplePoint,
  pointListHashCode,
  unwrapCoordinateFromPoint,
  wrapCoordinateInPoint,
} from './ShapeWithPointList.js'
import { coordinate_equals } from './PointCoordinate.js'
export class Polyline extends Shape {
  get type() {
    return ShapeType.POLYLINE
  }
  get focusPoint() {
    if (!this._focus) this._focus = this.calculateInterpolationPoint(0.5)
    return this._focus
  }
  get coordinates() {
    return this._coordinates
  }
  get pointCount() {
    return this._coordinates.length
  }
  getPoint(t) {
    const e = undefined
    if (this._coordinates[t])
      return wrapCoordinateInPoint(this.reference, this._coordinates[t])
    return
  }
  contains2D(t, e) {
    if (e && isNumber(t) && isNumber(e))
      return this.lineContainsXY(this._coordinates, arguments[0], arguments[1])
    if (!e && !isNumber(t) && isNumber(t.x) && isNumber(t.y)) {
      this._compareReference(t.reference, t.coordinateType)
      return this.lineContainsXY(this._coordinates, t.x, t.y)
    }
    throw new ProgrammingError(
      'XYZPolyline2D::contains2D - invalid arguments. use point argument, or use 2 numbers (x param and y param)'
    )
  }
  insertPoint(t, e) {
    this._compareReferenceStrict(e.reference)
    ShapeUtil.validateIndexForInsertionOrBounce(t, this._coordinates)
    this._coordinates.splice(t, 0, unwrapCoordinateFromPoint(e))
    this.invalidate()
  }
  removePoint(t) {
    ShapeUtil.validateIndexForRemovalOrBounce(t, this._coordinates)
    this._coordinates.splice(t, 1)
    this.invalidate()
  }
  translate(t, e, i) {
    this.translate3D(t, e, i || 0)
  }
  translate2D(t, e) {
    this.translate3D(t, e, 0)
  }
  translate3D(t, e, i) {
    this._coordinates.forEach((o) => {
      this.implTranslate(o, t, e, i)
    })
    this.invalidate()
  }
  translatePoint(t, e, i, o) {
    ShapeUtil.validateIndexForRemovalOrBounce(t, this._coordinates)
    this.implTranslate(this.getPointCoordinates(t), e, i, o)
    this.invalidate()
  }
  move2DPoint(t, e, i) {
    ShapeUtil.validateIndexForRemovalOrBounce(t, this._coordinates)
    this.implMove(this.getPointCoordinates(t), e, i)
    this.invalidate()
  }
  move3DPoint(t, e, i, o) {
    ShapeUtil.validateIndexForRemovalOrBounce(t, this._coordinates)
    this.implMove(this.getPointCoordinates(t), e, i, o)
    this.invalidate()
  }
  equals(t) {
    if (t === this) return true
    if (
      !isOfShapeType(t, ShapeType.POLYLINE) ||
      !this._referencesEqual(this._reference, t.reference) ||
      this.pointCount !== t.pointCount
    )
      return false
    const e = this.pointCount
    for (let i = 0; i < e; i++)
      if (
        !coordinate_equals(
          this.getPointCoordinates(i),
          t.getPointCoordinates(i)
        )
      )
        return false
    return true
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    pointListHashCode(this._coordinates, t)
  }
  toString() {
    let t = 'Polyline['
    this._coordinates.forEach((e) => {
      t += `[${e[0]},${e[1]},${e[2]}]`
    })
    return `${t}]`
  }
  invalidate() {
    this._bounds = void 0
    this._focus = void 0
  }
  contains3DCoordinates(t, e, i) {
    throw new ProgrammingError('Method not implemented.')
  }
  getPointCoordinates(t) {
    return this._coordinates[t]
  }
  getCoordinateArray() {
    const t = []
    this._coordinates.forEach((e) => {
      t.push(...e)
    })
    return t
  }
  getSimplePoint(t) {
    return coordinateToSimplePoint(this.getPointCoordinates(t))
  }
  getSimplePointSFCT(t, e) {
    const i = this._coordinates[t]
    e.x = i[0]
    e.y = i[1]
    e.z = i[2]
  }
  getSimplePoints() {
    return this._coordinates.map(coordinateToSimplePoint)
  }
}
export function onSomeSegments(t, e) {
  const i = t.length
  if (0 === i) return false
  if (1 === i) return e(t[0], t[0])
  let o = 1
  let n = false
  while (o < i && !n) {
    n = e(t[o - 1], t[o])
    o += 1
  }
  return n
}
export function calculate2DCoordinateAtFractionOnLine(t, e, i, o) {
  const n = e.length - 1
  let r = null
  if (n < 0) return null
  if (0 === n) return simplePointCopy(e[0])
  else if (1 === t) return simplePointCopy(e[1])
  else if (0 === t) return simplePointCopy(e[0])
  else {
    const s = getTotalLength(e, i)
    if (0 === s) return simplePointCopy(e[0])
    else {
      const a = t * s
      let l = 0
      let h = 0
      while (h < n) {
        const t = i(e[h], e[h + 1])
        if (0 !== t && l + t >= a) {
          r = o(e[h], e[h + 1], (a - l) / t)
          r.z = r.z || e[h].z
          h = n
        } else l += t
        h += 1
      }
    }
  }
  return r
}
function getTotalLength(t, e) {
  let i = 0
  t.reduce(function (t, o) {
    i += e(t, o)
    return o
  })
  return i
}
export function isPolyline(t) {
  return t && t.type === ShapeType.POLYLINE
}
