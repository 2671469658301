import { LineSegmentChooser } from './LineSegmentChooser.js'
function LineSegmentChooserSubtract() {}
LineSegmentChooserSubtract.prototype = Object.create(
  LineSegmentChooser.prototype
)
LineSegmentChooserSubtract.prototype.constructor = LineSegmentChooserSubtract
LineSegmentChooserSubtract.prototype.isSegmentOkAbstract = function (e) {
  return !e.isFilled()
    ? 0 === e.shapeIndex && !e.segmentLabel.locationOn[e.otherShapeIndex]
    : false
}
export { LineSegmentChooserSubtract }
