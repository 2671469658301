import { Log } from '../../util/Log.js'
import { AnchorPoints } from './AnchorPoints.js'
import { DrawCompositeLabel } from './DrawCompositeLabel.js'
import { DrawOnPathLabelHtmlRepeatingContents } from './DrawOnPathLabelHtmlRepeatingContents.js'
import { calculateBounds } from './HTMLMetrics.js'
const anchorPoints = new AnchorPoints()
const tmpBounds = []
export class DrawOnPathRepeatingLabelCommand extends DrawCompositeLabel {
  constructor(t, e, n, o, s, a, i) {
    super([])
    this._labelContext = t
    this._labelHtml = e
    this._drawCommand = n
    this._labelGroup = o
    this._priority = s
    this._selected = a
    this._style = i
    this._metrics = computeLabelMetrics(e)
    this.computeRepeatingLabels()
  }
  computeRepeatingLabels() {
    anchorPoints.reset()
    const t = {
      w2v: this._labelContext.mapToViewTransformation,
      labelWidth: this._metrics.labelWidth,
      mapWorldBounds: this._labelContext.getMapBounds(),
      maxCount: 80,
    }
    try {
      this._drawCommand.getRepeatingAnchorListSFCT(
        this._style.repeat,
        anchorPoints,
        t
      )
      if (anchorPoints.anchorPointsLength === t.maxCount)
        anchorPoints.repeatingAnchorContext.cannotBeReused = true
    } catch (t) {
      Log.warn(`Error when calculating fixed label anchor ${t}`)
      anchorPoints.repeatingAnchorContext.cannotBeReused = true
    }
    if (anchorPoints.anchorPointsLength) {
      if (this.commands.length > anchorPoints.anchorPointsLength)
        this.commands.length = anchorPoints.anchorPointsLength
      for (let t = 0; t < anchorPoints.anchorPointsLength; t++) {
        const e = anchorPoints.coordinatesAt(t)
        if (e) this.createOrReuse(t, e)
      }
    }
    this._mapScale = this._labelContext.mapScale()
    this._computedOnClippedPath =
      anchorPoints.repeatingAnchorContext.computedOnClippedPath
    this._cannotBeReused = anchorPoints.repeatingAnchorContext.cannotBeReused
    this._restrictedToWorldBounds = anchorPoints.repeatingAnchorContext
      .restrictedToWorldBounds
      ? anchorPoints.repeatingAnchorContext.restrictedToWorldBounds.copy()
      : null
  }
  createOrReuse(t, e) {
    const n = this.commands[t]
    if (n) n.setAnchor(e)
    else
      this.commands.push(
        new DrawOnPathLabelHtmlRepeatingContents(
          this._labelContext,
          this._labelHtml,
          this._drawCommand,
          this._labelGroup,
          this._priority,
          this._selected,
          this._style,
          e,
          this._metrics
        )
      )
  }
  reuseStateFrom(t) {
    if (!(t instanceof DrawOnPathRepeatingLabelCommand)) return
    const e = this.commands.length
    if (0 === e) return
    const n = t.commands.length
    const o = undefined
    if (Math.abs(e - n) / e > 0.25) return
    for (let n = 0; n < e; n++) {
      const e = t.commands[n]
      if (e) this.commands[n].reuseStateFrom(e)
      else break
    }
  }
  canBeReused() {
    const t = this._labelContext.mapScale()
    const e = this._labelContext.getMapBounds()
    let n = !this._cannotBeReused && isScaleChangeAccepted(this._mapScale, t)
    if (n && this._computedOnClippedPath)
      n = isBoundsAccepted(this._restrictedToWorldBounds, e)
    if (!n) this.computeRepeatingLabels()
    return true
  }
}
function computeLabelMetrics(t) {
  calculateBounds(t, 'top', 'left', tmpBounds)
  return {
    labelWidth: tmpBounds[2] - tmpBounds[0],
    labelHeight: tmpBounds[3] - tmpBounds[1],
    ascent: Math.abs(tmpBounds[1]),
    descent: tmpBounds[3],
  }
}
function isScaleChangeAccepted(t, e) {
  const n = t && e ? (t - e) / t : 0
  return n <= 0.1 && n >= -0.4
}
function isBoundsAccepted(t, e) {
  return t && e ? t.contains2DBounds(e) : false
}
