import { XMLWriter } from '../../util/XMLWriter.js'
var namespaces = [
  { prefix: 'lts', namespace: 'http://www.luciad.com/lts/1.1.1' },
  { prefix: 'gml', namespace: 'http://www.opengis.net/gml' },
  { prefix: 'xsd', namespace: 'http://www.w3.org/2001/XMLSchema' },
  { prefix: 'ogc', namespace: 'http://www.opengis.net/ogc' },
  { prefix: 'xlink', namespace: 'http://www.w3.org/1999/xlink' },
]
function LTSQuery(e) {
  this._xml = e
  Object.defineProperty(this, 'xml', {
    configurable: false,
    enumerable: true,
    get: function () {
      return this._xml
    },
  })
}
LTSQuery.prototype = Object.create(Object.prototype)
LTSQuery.prototype.constructor = LTSQuery
LTSQuery.empty = function () {
  return new LTSQueryBuilder().build()
}
function LTSQueryBuilder() {}
LTSQueryBuilder.prototype = Object.create(Object.prototype)
LTSQueryBuilder.prototype.constructor = LTSQueryBuilder
LTSQueryBuilder.prototype.build = function () {
  this._xmlWriter = new XMLWriter(true)
  this._xmlWriter.beginNode('Query', 'lts', namespaces)
  this._xmlWriter.beginNode('Query', 'lts')
  this._xmlWriter.endNode()
  this._xmlWriter.endNode()
  var e
  return new LTSQuery(
    "<?xml version='1.0' encoding='UTF-8'?>\n" + this._xmlWriter.toString()
  )
}
export { LTSQuery }
