export class PhotonNormalizedIconStyle {
  constructor(t, o) {
    this.offsetX = t.offsetX
    this.offsetY = t.offsetY
    this.anchorX = 0
    this.anchorY = 0
    this.maxViewDisplacementX = 0
    this.maxViewDisplacementY = 0
    this.width = 0
    this.height = 0
    this.rotation = t.rotation
    this.heading = t.heading
    this.zOrder = t.zOrder
    this.drapeTarget = t.drapeTarget
    this.opacity = t.opacity
    this.modulationColor = t.modulationColor
    this.bloom = t.bloom
    this.occlusionMode = t.occlusionMode
    this.uom = t.uom
    this.isWorldSized = t.isWorldSized
    this.worldWidth = t.worldWidth
    this.worldHeight = t.worldHeight
    this.isWorldOffset = t.isWorldOffsetX && t.isWorldOffsetY
    this.offsetXUom = t.offsetXUom
    this.worldOffsetX = t.worldOffsetX
    this.offsetYUom = t.offsetYUom
    this.worldOffsetY = t.worldOffsetY
    this.photonImage = null
    this.photonImagePromise = null
    this.transparency = false
  }
  release() {
    if (this.photonImage) this.photonImage.release()
  }
}
