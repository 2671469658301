import {
  EVENT_HANDLED,
  EVENT_IGNORED,
  isRequestDeactivation,
  REQUEST_DEACTIVATION,
} from '../../controller/HandleEventResult.js'
import { EditHandle } from '../EditHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
export class ThreeStepEditHandle extends EditHandle {
  constructor() {
    super()
    this._active = false
  }
  get active() {
    return this._active
  }
  set active(t) {
    this._active = t
  }
  onGestureEvent(t, e) {
    let i = EVENT_IGNORED
    if (!this._active && this.shouldActivate(t, e)) {
      this._active = true
      i |= this.activate(t, e)
    }
    if (this._active) if (this.shouldProcess(t)) i |= this.process(t, e)
    if (
      (this._active && this.shouldDeactivate(t)) ||
      isRequestDeactivation(i)
    ) {
      i = this.deactivate(t, e)
      this._active = false
    }
    return i
  }
  activate(t, e) {
    return EVENT_HANDLED
  }
  deactivate(t, e) {
    this.emitEditShapeEvent(e.shape, EditShapeStatus.FINISHED)
    return EVENT_HANDLED | REQUEST_DEACTIVATION
  }
}
