import { QueryStatus } from './QueryStatus.js'
import { createOptimizedEventedSupport } from '../../util/EventedSupport.js'
export class ProxyWorkingSet {
  constructor() {
    this._eventSupport = createOptimizedEventedSupport(
      [
        'QueryError',
        'QueryFinished',
        'QueryStarted',
        'QuerySuccess',
        'WorkingSetChanged',
        'QueryInterrupted',
      ],
      true
    )
    this._delegateWorkingSet = null
    this._delayedOperations = []
    this._delegateListeners = []
  }
  on(e, t, r) {
    return this._eventSupport.on(e, t, r)
  }
  setDelegateWorkingSet(e) {
    var t = this
    this._delegateListeners.forEach((e) => {
      e.remove()
    })
    this._delegateListeners = []
    this.cancelPending()
    if (this._delegateWorkingSet && this._delegateWorkingSet.workingSetUpdater)
      this._delegateWorkingSet.workingSetUpdater.release()
    this._delegateWorkingSet = e
    if (e) {
      ;[
        'QueryError',
        'QueryFinished',
        'QueryStarted',
        'QuerySuccess',
        'QueryInterrupted',
      ].forEach((r) => {
        this._delegateListeners.push(
          e.on(r, function () {
            for (var e = arguments.length, i = new Array(e), g = 0; g < e; g++)
              i[g] = arguments[g]
            t._eventSupport.emit(r, ...i)
          })
        )
      })
      this._delegateListeners.push(
        e.on('WorkingSetChanged', (e, t, r) => {
          this._eventSupport.emitWorkingSetChangedEvent(e, t, r)
        })
      )
    }
    this._delayedOperations.forEach((e) => e())
    this._delayedOperations = []
  }
  isReady() {
    return (
      this.queryStatus === QueryStatus.QUERY_FINISHED ||
      this.queryStatus === QueryStatus.QUERY_ERROR
    )
  }
  add(e, t) {
    if (this._delegateWorkingSet) return this._delegateWorkingSet.add(e, t)
    return new Promise((r) => {
      this._delayedOperations.push(() => {
        const i = this._delegateWorkingSet.add(e, t)
        r(i)
      })
    })
  }
  cancelPending() {
    if (this._delegateWorkingSet)
      return this._delegateWorkingSet.cancelPending()
  }
  put(e, t) {
    if (this._delegateWorkingSet) return this._delegateWorkingSet.put(e, t)
    return new Promise((r) => {
      this._delayedOperations.push(() => {
        const i = this._delegateWorkingSet.put(e, t)
        r(i)
      })
    })
  }
  remove(e) {
    if (this._delegateWorkingSet) return this._delegateWorkingSet.remove(e)
    return new Promise((t) => {
      this._delayedOperations.push(() => {
        const r = this._delegateWorkingSet.remove(e)
        t(r)
      })
    })
  }
  refreshWorkingSet(e) {
    if (this._delegateWorkingSet) this._delegateWorkingSet.refreshWorkingSet(e)
  }
  getIdMapSnapshot() {
    if (this._delegateWorkingSet)
      return this._delegateWorkingSet.getIdMapSnapshot()
    return new Map()
  }
  setSelected(e, t) {
    if (this._delegateWorkingSet)
      return this._delegateWorkingSet.setSelected(e, t)
    return null
  }
  setEdited(e, t) {
    if (this._delegateWorkingSet)
      return this._delegateWorkingSet.setEdited(e, t)
    return null
  }
  _registerPending() {
    if (this._delegateWorkingSet) this._delegateWorkingSet._registerPending()
  }
  _registerStart() {
    if (this._delegateWorkingSet) this._delegateWorkingSet._registerStart()
  }
  _registerSuccess() {
    if (this._delegateWorkingSet) this._delegateWorkingSet._registerSuccess()
  }
  _registerError(e) {
    if (this._delegateWorkingSet) this._delegateWorkingSet._registerError(e)
  }
  _registerInterruption() {
    if (this._delegateWorkingSet)
      this._delegateWorkingSet._registerInterruption()
  }
  _registerFinish() {
    if (this._delegateWorkingSet)
      return this._delegateWorkingSet._registerFinish()
  }
  _clearData() {
    if (this._delegateWorkingSet) return this._delegateWorkingSet._clearData()
  }
  clearAll() {
    if (this._delegateWorkingSet) this._delegateWorkingSet.clearAll()
  }
  get() {
    if (this._delegateWorkingSet) return this._delegateWorkingSet.get()
    return []
  }
  getNode(e) {
    if (this._delegateWorkingSet) return this._delegateWorkingSet.getNode(e)
    return null
  }
  getFeature(e) {
    if (this._delegateWorkingSet) return this._delegateWorkingSet.getFeature(e)
    return null
  }
  search(e, t) {
    if (this._delegateWorkingSet) this._delegateWorkingSet.search(e, t)
  }
  forEachVisibleFeature(e) {
    if (this._delegateWorkingSet)
      this._delegateWorkingSet.forEachVisibleFeature(e)
  }
  forEachVisibleNode(e) {
    if (this._delegateWorkingSet) this._delegateWorkingSet.forEachVisibleNode(e)
  }
  forEachNode(e) {
    if (this._delegateWorkingSet) this._delegateWorkingSet.forEachNode(e)
  }
  _addObject(e, t) {
    if (this._delegateWorkingSet) this._delegateWorkingSet._addObject(e, t)
  }
  _updateObject(e, t) {
    if (this._delegateWorkingSet) this._delegateWorkingSet._updateObject(e, t)
  }
  _removeObject(e, t) {
    if (this._delegateWorkingSet) this._delegateWorkingSet._removeObject(e, t)
  }
  get bounds() {
    return this._delegateWorkingSet ? this._delegateWorkingSet.bounds : null
  }
  get queryStatus() {
    return this._delegateWorkingSet
      ? this._delegateWorkingSet.queryStatus
      : QueryStatus.QUERY_PENDING
  }
}
