import { PhotonWorkerPoolFactory } from '../../gen/inlined-webworker/PhotonWorkerPoolFactory.js'
import { Log } from '../../util/Log.js'
import { isString } from '../../util/Lang.js'
import { TileSet3DModelSupport } from './TileSet3DModelSupport.js'
import { addHttpRequestOptions } from '../../util/HttpRequestOptions.js'
import { request } from '../../util/request.js'
import { URL as RIAUrl } from '../../util/URL.js'
const STRING_DECODE_CHUNK_SIZE = 1024
export class OGC3DTilesModel {
  constructor(e) {
    this.delegate = new TileSet3DModelSupport(e)
  }
  get credentials() {
    return this.delegate.credentials
  }
  set credentials(e) {
    this.delegate.credentials = e
  }
  get requestHeaders() {
    return this.delegate.requestHeaders
  }
  set requestHeaders(e) {
    this.delegate.requestHeaders = e
  }
  addHttpRequestOptions(e) {
    return this.delegate.addHttpRequestOptions(e)
  }
  get url() {
    return this.delegate.url
  }
  get urlParams() {
    return this.delegate.urlParams
  }
  get requestParameters() {
    return this.delegate.requestParameters
  }
  set requestParameters(e) {
    this.delegate.requestParameters = e
  }
  get bounds() {
    return this.delegate.bounds
  }
  get orientedBox() {
    return this.delegate.orientedBox
  }
  get coordinateType() {
    return this.delegate.coordinateType
  }
  get reference() {
    return this.delegate.reference
  }
  get modelDescriptor() {
    return this.delegate.modelDescriptor
  }
  static create(e, t) {
    return TileSet3DModelSupport.create(
      e,
      createModelDescriptor,
      (e) => new OGC3DTilesModel(e),
      t
    )
  }
}
function logUnsupportedWarnings(e, t) {
  if (e)
    if (e.asset && e.asset.gltfUpAxis)
      if (
        !isString(e.asset.gltfUpAxis) ||
        ('y' !== e.asset.gltfUpAxis.toLowerCase() &&
          'z' !== e.asset.gltfUpAxis.toLowerCase())
      )
        Log.warn(
          `${t}: Unsupported gltfUpAxis value: ${e.asset.gltfUpAxis}. Only 'Y' and 'Z' are supported. This could result in the 3D mesh to be incorrectly oriented.`
        )
}
function getTilesetJSON(e, t) {
  let r
  let s
  try {
    r = ''
    let t = 0
    while (t * STRING_DECODE_CHUNK_SIZE < e.length) {
      const s = t * STRING_DECODE_CHUNK_SIZE
      const o = Math.min((t + 1) * STRING_DECODE_CHUNK_SIZE, e.length)
      r += String.fromCharCode.apply(null, Array.from(e.subarray(s, o)))
      t++
    }
    s = JSON.parse(r)
  } catch (e) {
    Log.error('OGC3DTilesModel could not parse metadata information')
  }
  logUnsupportedWarnings(s, t)
  return s
}
function createModelDescriptor(e, t, r, s, o) {
  let i = t
  let l = null
  if (o) {
    l = { ...RIAUrl.parseQueryString(t), ...o }
    i = t.split('?')[0] + '?' + RIAUrl.buildQueryString(l)
  }
  const n = getTilesetJSON(e, i)
  const a = {
    source: i,
    name: 'OGC 3D Tiles Tile Set Model',
    type: 'OGC3D',
    description: 'Model for Mesh and PointCloud OGC 3D tiles',
  }
  Object.assign(a, parseTilesMetaData(n))
  if (Object.keys(a.properties).length > 0)
    return Promise.resolve({ modelDescriptor: a })
  let u = ''
  let d = [n.root]
  while (0 === u.length && d.length > 0) {
    const e = d.pop()
    if (e) {
      if (e.content) u = e.content.url || e.content.uri
      d = d.concat(e.children)
    }
  }
  u = new URL(u, t).href
  const p = addHttpRequestOptions({}, { credentials: r, requestHeaders: s })
  u = l ? u.split('?')[0] + '?' + RIAUrl.buildQueryString(l) : u
  return request(u, p)
    .then((e) => e.arrayBuffer())
    .then((e) => {
      let t
      try {
        t = PhotonWorkerPoolFactory.getGLTFWorkerPoolHandle()
        return t.webWorkerPool.enqueue(
          { command: 'parse3DTilesProperties', data: e },
          [e]
        )
      } finally {
        t.release()
      }
    })
    .then((e) => {
      const t = JSON.parse(e)
      const r = { minimum: void 0, maximum: void 0 }
      for (let e = 0; e < t.length; e++) a.properties[t[e]] = r
      return { modelDescriptor: a }
    })
}
function parseTilesMetaData(e) {
  if (!e) return { asset: {}, properties: {} }
  return { asset: e.asset || {}, properties: e.properties || {} }
}
export function createOGC3DTilesModel(e, t) {
  return OGC3DTilesModel.create(e, t)
}
