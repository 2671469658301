import { isObject } from '../../util/Lang.js'
export function isProjectionTargetClosestSurfaceOptions(t) {
  return isObject(t) && t.target === ProjectionTarget.CLOSEST_SURFACE
}
export let ProjectionTarget = (function (t) {
  t['ALL_SURFACES'] = 'ALL_SURFACES'
  t['CLOSEST_SURFACE'] = 'CLOSEST_SURFACE'
  return t
})({})
export function isProjectionTarget(t) {
  for (const r in ProjectionTarget) if (t === r) return true
  return false
}
