import { getReference } from '../../reference/ReferenceProvider.js'
import { Lang } from '../../util/Lang.js'
import { CapabilitiesParserUtil } from '../capabilities/common/CapabilitiesParserUtil.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
var wgs84Reference = getReference('CRS:84')
var isDefined = Lang.isDefined
var isString = Lang.isString
var isFunction = Lang.isFunction
function findServiceUrl(e, r) {
  if (!isDefined(e)) return null
  var n = e.supportedRequests.filter(function (e) {
    return e.method === r
  })[0]
  if (isDefined(n)) return CapabilitiesParserUtil.processServiceUrl(n.url)
}
function findLayer(e, r) {
  var n = null
  for (var i = 0; null == n && i < e.length; i++)
    if (e[i].name === r) n = e[i]
    else n = findLayer(e[i].children, r)
  return n
}
function calculateBounds(e, r) {
  if (isDefined(r.bounds)) return r.bounds
  else {
    var n = null
    e.forEach(function (e, i) {
      var a = e.getBounds(isDefined(r.reference) ? r.reference : wgs84Reference)
      if (0 === i) n = a
      else n.setTo2DUnion(a)
    })
    return n
  }
}
export var WMSImageModelOptionsUtil = {
  createImageModelCreatorOptions: function (e, r, n) {
    var i = []
    var a = []
    var t = []
    var s = []
    r.forEach(function (r) {
      var n = findLayer(e.layers, r.layer)
      if (!isDefined(n))
        throw new ProgrammingError(
          'Layer "' + r.layer + '" is not defined in the WMS capabilities'
        )
      i.push(n)
      a.push(n.name)
      if (n.queryable) t.push(n.name)
      s.push(isString(r.style) ? r.style : '')
    })
    var o = calculateBounds(i, n)
    var f = findServiceUrl(
      e.operations.filter(function (e) {
        return 'GetMap' === e.name
      })[0],
      'GET'
    )
    var l = findServiceUrl(
      e.operations.filter(function (e) {
        return 'GetFeatureInfo' === e.name
      })[0],
      'GET'
    )
    return {
      getMapRoot: n.getMapRoot || f,
      getFeatureInfoRoot: l,
      version: n.version || e.version,
      transparent: isDefined(n.transparent) ? n.transparent : true,
      backgroundColor: n.backgroundColor,
      bounds: o,
      reference: n.reference,
      layers: a,
      queryLayers: t,
      credentials: n.credentials,
      styles: s,
      sld: n.sld,
      sldBody: n.sldBody,
      requestParameters: n.requestParameters,
      dimensions: n.dimensions,
      imageFormat: n.imageFormat,
      infoFormat: n.infoFormat,
      requestHeaders: n.requestHeaders,
      swapAxes: n.swapAxes,
    }
  },
}
