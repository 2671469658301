import { Photon } from '../../../../gen/photon/photon_painter.js'
import { ShapeType } from '../../../../shape/ShapeType.js'
import { XYZPoint } from '../../../../shape/XYZPoint.js'
import { createPhotonColorFromString } from '../../../../util/Color.js'
import { Hash } from '../../../../util/Hash.js'
import { hasProperty, isNumber } from '../../../../util/Lang.js'
import { ObjectReleaseTracker } from '../../../../util/ObjectReleaseTracker.js'
import { GeoDiscretizerStatic } from '../../../shape/discretization/GeoDiscretizerStatic.js'
import { convertTextStyleToIconStyle } from '../TextToIconSupport.js'
import { BloomStyleImpl } from '../../../style/BloomStyle.js'
import { OcclusionMode } from '../../../style/OcclusionMode.js'
import { toPhotonDrapeTarget } from './PhotonCommandUtil.js'
import { appendImageHashCode } from '../../../style/complexstroke/pattern/util/HashUtil.js'
import { resolveIconStyle } from '../../../style/complexstroke/photon/PhotonStateResolver.js'
import { getZStyleForPoint, isAboveGroundReference } from './ZStyleUtil.js'
const MAX_ICON_HEIGHT = 2046
export class PhotonIconCommand extends ObjectReleaseTracker {
  constructor(e, t, o, s, i) {
    super()
    this._is3d = e
    this._worldReference = t
    this._shapePainter = o
    this._hash = new Hash()
    this._imageProvider = s
    this._worldSizeSupport = i
    this._coordinatesPool = [new XYZPoint(t, [0, 0, 0])]
  }
  canHandle(e) {
    const { type: t, shape: o, style: s } = e
    return (
      'icon' === t ||
      'text' === t ||
      ('shape' === t &&
        ShapeType.contains(o.type, ShapeType.POINT) &&
        !(s && s.isPlotStyle))
    )
  }
  add(e) {
    const { shape: t, style: o, zStyle: s, metadata: i } = e
    const a = t.focusPoint
    this.computeSharedMaxViewDisplacement(i)
    const n = GeoDiscretizerStatic.discretizePoint(a, {
      modelReference: a.reference,
      worldReference: this._worldReference,
      coordinatesPool: this._coordinatesPool,
      zToZero: s.zToZero,
    })
    if (null === n) return
    this._shapePainter.addIcon(
      e.objectId,
      e.geometryId,
      e.styleId,
      n,
      o.isWorldOffset
        ? Photon.ShapeScalingMode.WorldScaling
        : Photon.ShapeScalingMode.ViewScaling,
      o.isWorldOffset
        ? this._worldSizeSupport.toMapUnits(o.worldOffsetX, o.offsetXUom)
        : o.offsetX,
      o.isWorldOffset
        ? this._worldSizeSupport.toMapUnits(o.worldOffsetY, o.offsetYUom)
        : o.offsetY,
      o.width,
      o.height,
      o.isWorldSized
        ? Photon.ShapeScalingMode.WorldScaling
        : Photon.ShapeScalingMode.ViewScaling,
      this._worldSizeSupport.toMapUnits(o.worldWidth, o.uom),
      this._worldSizeSupport.toMapUnits(o.worldHeight, o.uom),
      o.anchorX,
      o.anchorY,
      i.maxViewDisplacementX,
      i.maxViewDisplacementY,
      o.rotation,
      o.heading,
      !isNaN(o.heading),
      o.opacity,
      createPhotonColorFromString(o.modulationColor),
      toPhotonDrapeTarget(s.drapeTarget),
      s.aboveGround,
      s.viewDisplacement,
      o.photonImage,
      o.zOrder,
      e.renderPassId,
      e.selected,
      toPhotonOcclusionMode(o.occlusionMode || OcclusionMode.VISIBLE_ONLY),
      o.bloom
    )
  }
  updateStyle(e, t) {
    const o = e.style
    const s = e.zStyle
    const i = e.metadata
    this.computeSharedMaxViewDisplacement(i)
    this._shapePainter.updateIconStyle(
      e.objectId,
      e.geometryId,
      t,
      e.styleId,
      o.isWorldOffset
        ? Photon.ShapeScalingMode.WorldScaling
        : Photon.ShapeScalingMode.ViewScaling,
      o.isWorldOffset
        ? this._worldSizeSupport.toMapUnits(o.worldOffsetX, o.offsetXUom)
        : o.offsetX,
      o.isWorldOffset
        ? this._worldSizeSupport.toMapUnits(o.worldOffsetY, o.offsetYUom)
        : o.offsetY,
      o.width,
      o.height,
      o.isWorldSized
        ? Photon.ShapeScalingMode.WorldScaling
        : Photon.ShapeScalingMode.ViewScaling,
      this._worldSizeSupport.toMapUnits(o.worldWidth, o.uom),
      this._worldSizeSupport.toMapUnits(o.worldHeight, o.uom),
      o.anchorX,
      o.anchorY,
      i.maxViewDisplacementX,
      i.maxViewDisplacementY,
      o.rotation,
      !isNaN(o.heading),
      o.opacity,
      createPhotonColorFromString(o.modulationColor),
      toPhotonDrapeTarget(s.drapeTarget),
      s.aboveGround,
      s.viewDisplacement,
      o.photonImage,
      o.zOrder,
      e.renderPassId,
      e.selected,
      toPhotonOcclusionMode(o.occlusionMode),
      o.bloom
    )
  }
  updateGeometry(e, t) {
    const o = e.shape.focusPoint
    const s = e.style
    const i = e.zStyle
    const a = GeoDiscretizerStatic.discretizePoint(o, {
      modelReference: o.reference,
      worldReference: this._worldReference,
      coordinatesPool: this._coordinatesPool,
      zToZero: i.zToZero,
    })
    if (null === a) {
      this._shapePainter.removeGeometry(e.objectId, t, e.styleId)
      return
    }
    this._shapePainter.updateIconGeometry(
      e.objectId,
      t,
      e.geometryId,
      a,
      s.heading ?? NaN
    )
  }
  resolveStyle(e) {
    if (isTextStyle(e.style))
      e.style = convertTextStyleToIconStyle(e.style.text, e.style)
    e.style = resolveIconStyle(
      this._imageProvider,
      this._hash,
      e.style,
      e.density,
      MAX_ICON_HEIGHT
    )
  }
  computeSharedMaxViewDisplacement(e) {
    if (null !== e.maxViewDisplacementX) return
    e.maxViewDisplacementX = 0
    e.maxViewDisplacementY = 0
    const t = e.siblingDrawItems
    for (let o = 0; o < t.length; o++) {
      const s = t[o]
      const i = s.style
      const a = undefined
      if (s.zStyle.viewDisplacement) {
        const t = Math.max(0, Math.max(i.width - i.anchorX, i.anchorX))
        const o = Math.max(0, i.height - i.anchorY)
        e.maxViewDisplacementX = Math.max(e.maxViewDisplacementX, t)
        e.maxViewDisplacementY = Math.max(e.maxViewDisplacementY, o)
      }
    }
  }
  getDrawItems(e) {
    const t = []
    const o = {
      maxViewDisplacementX: null,
      maxViewDisplacementY: null,
      siblingDrawItems: t,
    }
    for (let s = 0; s < e.length; s++) {
      const { shape: i, style: a, renderPassId: n, selected: r } = e[s]
      const h = getZStyleForPoint(
        i.focusPoint,
        a.draped,
        a.drapeTarget,
        this._is3d,
        this._worldReference
      )
      if ('text' === e[s].type) a.text = e[s].text
      const l = {
        shape: i,
        style: a,
        geometryId: this._getGeometryId(i, a, h),
        styleId: this._getStyleId(i, a, h, r, n),
        zStyle: h,
        metadata: o,
      }
      t.push(l)
    }
    return t
  }
  _getGeometryId(e, t, o) {
    this._hash.reset()
    e.hashCode(this._hash)
    if (e.reference) e.reference.hashCode(this._hash)
    this._hash.appendDouble(t.heading)
    this._hash.appendBoolean(isAboveGroundReference(e))
    this._hash.appendUInt32(o.drapeTarget)
    this._hash.appendBoolean(o.aboveGround)
    return this._hash.getHashCode()
  }
  _getStyleId(e, t, o, s, i) {
    this._hash.reset()
    this._hash.appendUInt32(o.drapeTarget)
    this._hash.appendBoolean(o.aboveGround)
    this._hash.appendUInt32(i)
    this._hash.appendBoolean(s)
    if (isTextStyle(t)) {
      this._hash.appendString(t.text)
      this._hash.appendString(t.alignmentBaseline)
      this._hash.appendDouble(t.angle)
      this._hash.appendString(t.fill)
      this._hash.appendString(t.font)
      this._hash.appendString(t.halo)
      this._hash.appendDouble(t.haloWidth)
      this._hash.appendString(t.stroke)
      this._hash.appendDouble(t.strokeWidth)
      this._hash.appendString(t.textAnchor)
      this._hash.appendString(t.uom?.name)
      this._hash.appendDouble(t.height)
    } else {
      this._hash.appendString(t.anchorX)
      this._hash.appendString(t.anchorY)
      this._hash.appendBoolean(!isNaN(t.heading))
      this._hash.appendString('' + t.height)
      this._hash.appendString('' + t.width)
      this._hash.appendString(t.uom?.name)
      this._hash.appendDouble(t.opacity)
      this._hash.appendString(t.modulationColor)
      if (t.bloom)
        this._hash.appendDouble(
          isNumber(t.bloom.intensity, false)
            ? t.bloom.intensity
            : new BloomStyleImpl({}).intensity
        )
      this._hash.appendDouble(t.rotation)
      const e = t.url ?? t.image
      if (e) appendImageHashCode(e, this._hash)
    }
    this._hash.appendUInt32(t.drapeTarget)
    this._hash.appendString('' + t.offsetX)
    this._hash.appendString('' + t.offsetY)
    this._hash.appendDouble(t.occlusionMode)
    this._hash.appendDouble(t.zOrder)
    return this._hash.getHashCode()
  }
  usesPhotonShapePainter() {
    return true
  }
  release() {
    super.release()
  }
}
function isTextStyle(e) {
  return hasProperty(e, 'text')
}
function toPhotonOcclusionMode(e) {
  switch (e) {
    case OcclusionMode.VISIBLE_ONLY:
      return Photon.OcclusionMode.VisibleOnly
    case OcclusionMode.OCCLUDED_ONLY:
      return Photon.OcclusionMode.OccludedOnly
    case OcclusionMode.ALWAYS_VISIBLE:
      return Photon.OcclusionMode.AlwaysVisible
    default:
      return Photon.OcclusionMode.VisibleOnly
  }
}
