import { PaintRepresentation } from '../PaintRepresentation.js'
import { DrawingContext } from './DrawingContext.js'
export class BorderDrawingContext extends DrawingContext {
  constructor(e, t, r, s, i) {
    super(
      e,
      t,
      r,
      s,
      i,
      PaintRepresentation.BOTTOM_BORDER_BODY,
      PaintRepresentation.BOTTOM_BORDER_LABEL
    )
    this.surface.clip = (e, t) => {}
  }
  paintBodyLabels(e, t, r) {
    e._paintBorderBodyLabels(t, r)
  }
  paintDrawingContext(e) {
    if (super.valid) {
      this.collectLabelsInContext(this.surface.getContext2d(), e)
      return
    }
    super.valid = true
    this.surface.clear()
    this.clipBordersFromSurface()
    this._paintlayersOnContext2dAndCollectLabels(this.surface.getContext2d(), e)
    this.removeBorderClip()
  }
  paintDrawingContextSelectedAndUnSelected() {
    const e = this.surface.getContext2d()
    if (super.valid) {
      this.collectLabelsInContext(e, false)
      this.collectLabelsInContext(e, true)
      return
    }
    this.surface.clear()
    super.valid = true
    this.clipBordersFromSurface()
    this._paintlayersOnContext2dAndCollectLabels(e, false)
    this._paintlayersOnContext2dAndCollectLabels(e, true)
    this.removeBorderClip()
  }
  clipBordersFromSurface() {
    const e = this.surface.getContext2d()
    e.save()
    e.beginPath()
    e.moveTo(0, 0)
    e.lineTo(e.canvas.width - this.surface.right, 0)
    e.lineTo(e.canvas.width - this.surface.right, e.canvas.height)
    e.lineTo(0, e.canvas.height)
    e.lineTo(0, 0)
    e.clip()
  }
  removeBorderClip() {
    this.surface.getContext2d().restore()
  }
}
