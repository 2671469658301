import { HandleEventResult } from './HandleEventResult.js'
import { PickRequest } from '../PickRequest.js'
import { LayerTreeVisitor } from '../LayerTreeVisitor.js'
import { PickInfoImpl } from '../PickInfo.js'
import { isViewBoundsOutsideLayerClip } from '../LayerStyle.js'
import { LayerTreeNode } from '../LayerTreeNode.js'
import { FeatureLayer } from '../feature/FeatureLayer.js'
import { GestureEventType } from '../input/GestureEventType.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { PickController } from './PickController.js'
export class HoverController extends PickController {
  constructor() {
    super()
  }
  isPickEvent(e) {
    return e.type === GestureEventType.MOVE && 'mouse' === e.inputType
  }
  getCandidates(e, t, r) {
    if (!this.map || !e) return []
    const i = PickRequest.fromViewPoint(
      e.x,
      e.y,
      t,
      t,
      false,
      true,
      this.map.viewToMapTransformation
    )
    const n = []
    const s = {
      visitLayer: (s) => {
        if (
          !(s instanceof FeatureLayer) ||
          !s.hoverable ||
          !s.visibleInTree ||
          !s.pick ||
          !this.map
        )
          return LayerTreeVisitor.ReturnValue.CONTINUE
        if (
          this.map.viewPaintingStrategy &&
          r.indexOf(PaintRepresentation.LABEL) >= 0
        ) {
          const r = this.map.viewPaintingStrategy.pickClosestLabels(
            e.x,
            e.y,
            t,
            t,
            true,
            false
          )
          if (r.length) {
            const e = PickInfoImpl.singleOut(r)
            if (e && e.layer === s) {
              n.push({ layer: s, objects: e.objects })
              return LayerTreeVisitor.ReturnValue.ABORT
            }
          }
        }
        if (this.map.viewPaintingStrategy?.isOfPhotonType()) {
          const e = this.map.viewPaintingStrategy.getLayerStyle(s)
          if (
            e.flicker ||
            isViewBoundsOutsideLayerClip(
              i.viewBounds,
              this.map.viewSize[1],
              e.clip
            )
          )
            return LayerTreeVisitor.ReturnValue.CONTINUE
        }
        if (r.indexOf(PaintRepresentation.BODY) >= 0) {
          const e = s.pick(i)
          if (e.length) {
            if ('EPSG:4978' === this.map.reference.identifier)
              e.sort(PickInfoImpl.comparePickInfo3D)
            else e.sort(PickInfoImpl.comparePickInfo2D)
            n.push({ layer: s, objects: [e[0].object] })
            return LayerTreeVisitor.ReturnValue.ABORT
          }
        }
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
      visitLayerGroup: (e) => {
        e.visitChildren(s, LayerTreeNode.VisitOrder.TOP_DOWN)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
    }
    this.map.layerTree.accept(s)
    return n
  }
  handleCandidates(e, t) {
    if (!this.map) return HandleEventResult.EVENT_IGNORED
    this.map.updateHovered(t, false)
    return HandleEventResult.EVENT_HANDLED
  }
}
