import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Feature } from '../feature/Feature.js'
export class IterableCursor {
  constructor(e, t) {
    this._iterator = e[Symbol.iterator]()
    this._nextItem = { value: new Feature(null), done: false }
    this._filter = t
    this._prepareNext()
  }
  _prepareNext() {
    if (this._nextItem.done) return
    do {
      this._nextItem = this._iterator.next()
    } while (
      !this._nextItem.done &&
      this._filter &&
      !this._filter(this._nextItem.value)
    )
  }
  hasNext() {
    return !this._nextItem.done
  }
  next() {
    if (!this.hasNext())
      throw new ProgrammingError(
        'Cursor: next should only be called when hasNext returns true'
      )
    const e = this._nextItem.value
    this._prepareNext()
    return e
  }
}
