import { cloneDeep } from '../../util/JSON.js'
import { KML_GROUND_OVERLAY } from '../../util/kml/KMLTypes.js'
import { KMLFeature } from './KMLFeature.js'
export class KMLGroundOverlayFeature extends KMLFeature {
  constructor(e, t, s, o) {
    super(e, t, s, o)
  }
  get type() {
    return KML_GROUND_OVERLAY
  }
  copy() {
    const e = this.shape ? this.shape.copy() : null
    if (e && this.shape) e.altitudeMode = this.shape.altitudeMode
    const { bounds: t, ...s } = this.properties
    const o = t.copy()
    return new KMLGroundOverlayFeature(
      e,
      { bounds: o, ...cloneDeep(s) },
      this.id,
      this.options
    )
  }
}
