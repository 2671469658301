import { LLHPoint } from './LLHPoint.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { Constants } from '../util/Constants.js'
import { LLHBounds } from './LLHBounds.js'
import { containsAnglePragmatic } from '../util/Cartesian.js'
const helperPoint = new LLHPoint()
export class LLHArcBandUtil {
  constructor() {}
  static coordinateType = CoordinateType.GEODETIC
  static distance2D(e, t, n) {
    return n._reference.geodeticDatum.ellipsoid.geodesicDistance(e, t)
  }
  static forwardAzimuth2D(e, t, n) {
    return (
      Constants.RAD2DEG *
      n._reference.geodeticDatum.ellipsoid.forwardAzimuth2D(e, t)
    )
  }
  static calculatePoint(e, t, n) {
    const o = n.center.copy()
    n.center.reference.geodeticDatum.ellipsoid.geodesicPositionSFCT(
      n.center,
      e,
      t,
      o
    )
    return o
  }
  static contains2DCoordinates(e, t, n) {
    const o = n._reference.geodeticDatum.ellipsoid
    const i = new LLHPoint(n._reference)
    i.move2D(e, t)
    const r = o.geodesicDistance(n.center, i)
    const s =
      Constants.ABSOLUTE_DISTANCE_TOLERANCE +
      Constants.RELATIVE_DISTANCE_TOLERANCE * r
    if (0 === n.minRadius && r <= s) return true
    const a = o.forwardAzimuth2D(n.center, i) * Constants.RAD2DEG
    if (
      containsAnglePragmatic(n.startAzimuth, n.sweepAngle, a) &&
      r >= n.minRadius - s &&
      r <= n.maxRadius + s
    )
      return true
    return false
  }
  static calculateBounds(e) {
    const t = new LLHBounds(e._reference, [0, 0, 0, 0, 0, 0]),
      n = e._reference.geodeticDatum.ellipsoid,
      o = e.center,
      i = e.maxRadius,
      r = e.startAzimuth,
      s = e.sweepAngle
    if (0 === e.minRadius) t.move2D(o)
    else {
      t.move2D(e.minRadiusStartCorner)
      t.setToIncludePoint2D(e.minRadiusEndCorner)
    }
    t.setToIncludePoint2D(e.maxRadiusStartCorner)
    t.setToIncludePoint2D(e.maxRadiusEndCorner)
    const a = 1 + Math.floor(48 * (s / 360))
    const c = s / a
    let d = r
    for (let e = 0; e < a; e++) {
      d += c
      u(i, d)
    }
    t.enlarge2D(1 + 2e-8)
    if (e.contains2DCoordinates(0, 90)) {
      t.width = 360
      t.height = 90 - t.y
    } else if (e.contains2DCoordinates(0, -90)) {
      t.width = 360
      t.height = t.y + t.height + 90
      t.move2DToCoordinates(t.x, -90)
    }
    function u(e, i) {
      n.geodesicPositionSFCT(o, e, i, helperPoint)
      t.setToIncludePoint2D(helperPoint)
    }
    return t
  }
}
