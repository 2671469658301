import { orientation2D as orientation2DSphere } from '../geodesy/SphereUtil.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { orientation2D } from '../geodesy/EllipsoidUtil.js'
import { PolygonOrientation } from './PolygonOrientation.js'
import { LLHBounds } from './LLHBounds.js'
import { Polygon } from './Polygon.js'
import { calculate3DPolygonBounds } from './GeodesicBoundsUtil.js'
import { polygonContains2D } from '../geodesy/SphereUtil.js'
import { validatePointRepresentationForCreations } from './ShapeWithPointList.js'
import { normalizeLat, normalizeLon } from '../util/LonLatCoord.js'
import { isNumber } from '../util/Lang.js'
export class LLHPolygon extends Polygon {
  constructor(o, t) {
    super()
    this._reference = this.validateReference(o)
    this._coordinates = [validatePointRepresentationForCreations(t, this)]
    this._orientation = PolygonOrientation.INVALID_ORIENTATION
  }
  get isGeodetic() {
    return true
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  get bounds() {
    if (0 === this.pointCount) return null
    if (!this._bounds) {
      const o = new LLHBounds(this.reference, [0, 0, 0, 0, 0, 0])
      calculate3DPolygonBounds(this, this.reference.geodeticDatum.ellipsoid, o)
      this._bounds = o
    }
    return this._bounds
  }
  get orientation() {
    if (this._orientation === PolygonOrientation.INVALID_ORIENTATION) {
      const o = this.reference.geodeticDatum.ellipsoid
      if (o.isSphere()) this._orientation = orientation2DSphere(this)
      else this._orientation = orientation2D(this, o)
    }
    return this._orientation
  }
  set orientation(o) {
    this._orientation = o
  }
  copy() {
    return new LLHPolygon(this.reference, super.copyRing())
  }
  contains2DCoordinates(o, t) {
    return (
      this.bounds.contains2DCoordinates(o, t) && polygonContains2D(this, o, t)
    )
  }
  implMove(o, t, e, i) {
    o[0] = normalizeLon(t)
    o[1] = normalizeLat(e)
    if (isNumber(i)) o[2] = i
  }
  implTranslate(o, t, e) {
    let i = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0
    o[0] = normalizeLon(o[0] + t)
    o[1] = normalizeLat(o[1] + e)
    o[2] += i
  }
}
