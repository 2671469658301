import { WorldSized } from './WorldSized.js'
export class Length extends WorldSized {
  constructor(e) {
    super()
    e = e || {}
    this._fixedLength = e.fixedLength || 0
    this._relativeLength = e.relativeLength || 0
  }
  getWidth(e) {
    const t =
      0 === this.relativeLength ? this.fixedLength : this.relativeLength * e
    return this.relativeLength ? t : this.getUomSizeToPixels(t)
  }
  isRelativeLength() {
    return !!this._relativeLength
  }
  get fixedLength() {
    return this._fixedLength
  }
  set fixedLength(e) {
    this._fixedLength = e
  }
  get relativeLength() {
    return this._relativeLength
  }
  set relativeLength(e) {
    this._relativeLength = e
  }
  appendHash(e) {
    e.appendDouble(this.fixedLength)
    e.appendDouble(this.relativeLength)
  }
}
