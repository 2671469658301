import { Ajax } from '../../util/Ajax.js'
import { addHttpRequestOptions } from '../../util/HttpRequestOptions.js'
import { whenInternal } from '../../util/PromiseUtil.js'
import { request } from '../../util/request.js'
import { URL } from '../../util/URL.js'
import { WithHttpRequestOptions } from '../../util/WithHttpRequestOptions.js'
import { RasterDataType } from './RasterDataType.js'
import { RasterTileSetModel } from './RasterTileSetModel.js'
import { URLPatternUtil } from './URLPatternUtil.js'
class UrlTileSetModel extends RasterTileSetModel {
  constructor(t) {
    ;(t = t || {}).modelDescriptor =
      t.modelDescriptor ||
      Object.freeze({
        source: t.baseURL,
        name: 'URL tileset',
        description: 'URL Tileset Model',
        type: t.dataType ? t.dataType : RasterDataType.IMAGE,
      })
    super(t)
    this._httpSupport = new WithHttpRequestOptions(t)
    this.urlPatternUtil = new URLPatternUtil(t)
    this.useCors = !URL.isLocalURL(this.urlPatternUtil.baseURL)
  }
  get credentials() {
    return this._httpSupport.credentials
  }
  set credentials(t) {
    this._httpSupport.credentials = t
  }
  get requestHeaders() {
    return this._httpSupport.requestHeaders
  }
  set requestHeaders(t) {
    this._httpSupport.requestHeaders = t
  }
  get requestParameters() {
    return this._httpSupport.requestParameters
  }
  set requestParameters(t) {
    this._httpSupport.requestParameters = t
  }
  getImage(t, e, r, s) {
    let i
    try {
      i = this.getTileURL(this.baseURL, t)
    } catch (e) {
      if (r) r(t, e)
      return
    }
    if (!i) {
      if (r) r(t, new Error('getTileURL returned no URL'))
      return
    }
    const a = (e) => {
      if (r) r(t, e)
    }
    if (this.dataType === RasterDataType.IMAGE) {
      const r = Ajax.getImage(
        i,
        false,
        this.useCors,
        this.credentials,
        this.requestHeaders,
        s
      )
      const n = undefined
      whenInternal(
        r,
        (r) => {
          if (e) e(t, r)
        },
        a
      )
    } else {
      const r = addHttpRequestOptions({ signal: s }, this)
      const n = request(i, r).then((t) => {
        const e = t.headers.get('Content-Type') || 'image/png'
        return t.arrayBuffer().then((t) => ({ arrayBuffer: t, contentType: e }))
      })
      const o = undefined
      whenInternal(
        n,
        (r) => {
          if (e) e(t, r.arrayBuffer, r.contentType)
        },
        a
      )
    }
  }
  getTileURL(t, e) {
    let r = this.urlPatternUtil.replaceURLPatterns(e, this, t)
    if (null !== r) {
      const t = this.requestParameters
      if (t && 0 !== Object.keys(t).length)
        r += (r.includes('?') ? '&' : '?') + URL.buildQueryString(t)
    }
    return r
  }
  get baseURL() {
    return this.urlPatternUtil.baseURL
  }
  set baseURL(t) {
    this.urlPatternUtil.baseURL = t
    this.invalidate()
  }
  get subdomains() {
    return this.urlPatternUtil.subdomains
  }
  set subdomains(t) {
    this.urlPatternUtil.subdomains = t
  }
}
export { UrlTileSetModel }
