import { ProgrammingError } from '../../error/ProgrammingError.js'
function ComplexPolygonTree(e, o) {
  this._complexPolygon = e
  this._advancedBinaryTopologyUtil = o
  this._root = new TreeNode(-1)
  this._createTree()
}
ComplexPolygonTree.prototype = Object.create(Object.prototype)
ComplexPolygonTree.prototype.constructor = ComplexPolygonTree
Object.defineProperty(ComplexPolygonTree.prototype, 'root', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._root
  },
  set: function () {
    throw new ProgrammingError('root property is not mutable')
  },
})
function TreeNode(e) {
  this._polygonIndex = e
  this._children = []
}
Object.defineProperty(TreeNode.prototype, 'polygonIndex', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._polygonIndex
  },
  set: function () {
    throw new ProgrammingError('polygonIndex property is not mutable')
  },
})
Object.defineProperty(TreeNode.prototype, 'children', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._children
  },
  set: function () {
    throw new ProgrammingError('children property is not mutable')
  },
})
TreeNode.prototype.addChild = function (e) {
  this._children.push(e)
}
ComplexPolygonTree.prototype._createTree = function () {
  for (var e = 0; e < this._complexPolygon.polygonCount; e++)
    this._insert(this._root, e)
}
ComplexPolygonTree.prototype._insert = function (e, o) {
  var r,
    t = e.children
  for (r = 0; r < t.length; r++)
    if (this._inside(o, t[r].polygonIndex)) {
      this._insert(t[r], o)
      return
    }
  var n = new TreeNode(o)
  var i = []
  for (r = 0; r < t.length; r++) {
    var l = t[r]
    if (this._inside(l.polygonIndex, o)) {
      n.addChild(l)
      i.push(r)
    }
  }
  i.sort(function (e, o) {
    return o - e
  })
  for (r = 0; r < i.length; r++) e.children.splice(i[r], 1)
  e.addChild(n)
}
ComplexPolygonTree.prototype._inside = function (e, o) {
  return this._advancedBinaryTopologyUtil.insidePolygonPolygon(
    this._complexPolygon.getPolygon(e),
    this._complexPolygon.getPolygon(o)
  )
}
ComplexPolygonTree.prototype.getResultInList = function (e) {
  this._getResultInList(this._root, false, e)
}
ComplexPolygonTree.prototype._getResultInList = function (e, o, r) {
  var t = e.children
  for (var n = 0; n < t.length; n++) {
    var i = t[n]
    r[i.polygonIndex] = o
    this._getResultInList(i, !o, r)
  }
}
export { ComplexPolygonTree }
