import { ProgrammingError } from '../../error/ProgrammingError.js'
import { ShapeType } from '../../shape/ShapeType.js'
import { ShapeUtil } from '../../shape/ShapeUtil.js'
import { isDefined } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { createHTML5Canvas } from '../HTML5Canvas.js'
import { GeoDiscretizerStatic } from '../shape/discretization/GeoDiscretizerStatic.js'
import { ComplexStrokedLineStyleImpl } from './complexstroke/ComplexStrokedLineStyleImpl.js'
import { CompositeDrawCommand } from './CompositeDrawCommand.js'
import { HTML5DrawArrowMarkerCommand } from './HTML5DrawArrowMarkerCommand.js'
import { HTML5DrawFillCommand } from './HTML5DrawFillCommand.js'
import { HTML5DrawFillCommandDefault } from './HTML5DrawFillCommandDefault.js'
import { HTML5DrawFillCommandMulti } from './HTML5DrawFillCommandMulti.js'
import { HTML5DrawIconCommand } from './HTML5DrawIconCommand.js'
import { HTML5DrawStrokeCommand } from './HTML5DrawStrokeCommand.js'
import { HTML5DrawStrokeCommandMulti } from './HTML5DrawStrokeCommandMulti.js'
import { HTML5DrawStrokeFillCommand } from './HTML5DrawStrokeFillCommand.js'
import { HTML5DrawTextCommand } from './HTML5DrawTextCommand.js'
import { LineMarkerType } from './LineMarkerType.js'
import { drawNullCommand } from './NullDrawCommand.js'
import {
  normalizeIconStyle,
  normalizeShapeStyle as styleUtilNormalizeShapeStyle,
  normalizeTextStyle,
} from './StyleUtil.js'
const PLACE_HOLDER_ICON = (() => {
  const e = 3
  const n = 3
  const o = createHTML5Canvas(e, n)
  const r = o.getContext('2d')
  if (r) {
    r.strokeStyle = 'rgb(255,255,255)'
    r.fillStyle = 'rgb(0,0,0)'
    r.lineWidth = 1
    r.fillRect(0, 0, e, n)
    r.strokeRect(0, 0, e, n)
  }
  return o
})()
export function createDrawNull() {
  return drawNullCommand
}
export function createDrawComposite(e) {
  return new CompositeDrawCommand(e.slice())
}
export function createDrawIcon(e, n, o, r) {
  const t = n.hasOwnProperty('isWorldSized') ? n : normalizeIconStyle(n)
  const i = new HTML5DrawIconCommand(
    o.worldReference,
    e,
    e,
    t,
    o.invalidateAction
  )
  r.push(i)
}
export function createDrawShape(e, n, o, r) {
  if (!o.worldReference)
    throw new ProgrammingError(
      'Geocanvas:: world reference must be provided when drawing a shape'
    )
  if (ShapeType.contains(e.type, ShapeType.POINT)) createDrawIcon(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.POLYLINE)) drawLine(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.POLYGON))
    drawPolygon(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.COMPLEX_POLYGON))
    if (1 === e.polygonCount) drawPolygon(e.getPolygon(0), n, o, r)
    else drawComplexPolygon(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.BOUNDS)) drawBounds(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.CIRCLE)) drawCircle(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.ELLIPSE))
    drawEllipse(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC))
    drawArc(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.ARC)) drawArc(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.ARC_BAND))
    drawArcBand(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.SECTOR)) drawSector(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.GEO_BUFFER))
    drawGeoBuffer(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.EXTRUDED_SHAPE))
    createDrawShape(e.baseShape, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.ORIENTED_BOX))
    drawOrientedBox(e, n, o, r)
  else if (ShapeType.contains(e.type, ShapeType.SHAPE_LIST)) {
    const t = e
    const i = t.shapeCount
    if (i > 0) {
      const a = []
      for (let e = 0; e < i; e++) createDrawShape(t.getShape(e), n, o, a)
      const l = new CompositeDrawCommand(a)
      l.bindDomainShape(e)
      r.push(l)
    }
  } else Log.warn(`GeoCanvas::does not recognize shape of type ${e.type}: ${e}`)
}
export function createDrawText(e, n, o, r, t, i) {
  let a, l, s, m
  const p = normalizeTextStyle(o)
  if (!isDefined(e.type)) {
    a = e[0]
    l = e[1]
  } else if ('object' === typeof arguments[0]) {
    s = GeoDiscretizerStatic.discretize(e, {
      modelReference: e.reference,
      worldReference: r,
    })
    if (s?.points) {
      m = s.points[0]
      a = m.x
      l = m.y
    } else return
  } else throw new ProgrammingError('Cannot draw text. please check arguments')
  if (!isDefined(a) && !isDefined(l)) return
  t.push(new HTML5DrawTextCommand(r, e, a, l, n, p, p.zOrder))
}
function normalizeShapeStyle(e, n) {
  const o = styleUtilNormalizeShapeStyle(e)
  r(o.stroke)
  r(o.innerBorder)
  r(o.outerBorder)
  return o
  function r(e) {
    if (e) {
      e.worldSizeSupport = n.worldSizeSupport
      if (e instanceof ComplexStrokedLineStyleImpl && n.invalidateAction)
        e.resolveState(n.invalidateAction)
    }
  }
}
function generateDrawCommandForLineShape(e, n, o, r, t) {
  const i = e.lines
  if (!i)
    throw new ProgrammingError(
      'Geocanvas:: unexpected response from discretizer: should get array of line coordinates'
    )
  const a = i.length
  let l
  const s = r.stroke
  const m = r.zOrder
  const p = undefined
  if (!!s)
    if (1 === a) l = new HTML5DrawStrokeCommand(o, n, i[0], s, m, false)
    else if (a > 1) l = new HTML5DrawStrokeCommandMulti(o, n, i, s, m, false)
  if (l) t.push(l)
  let c, d, f
  for (d = 0, f = i.length; d < f; d++) {
    c = i[d]
    const e = r.stroke
    if (
      c.endDecoration &&
      c.length > 1 &&
      e &&
      e.strokeStyle &&
      e.strokeStyle.endMarker &&
      e.strokeStyle.endMarker.type === LineMarkerType.ARROW
    )
      t.push(
        new HTML5DrawArrowMarkerCommand(
          o,
          c[c.length - 6],
          c[c.length - 5],
          c[c.length - 3],
          c[c.length - 2],
          e,
          e.strokeStyle.endMarker.size,
          r.zOrder
        )
      )
    if (
      c.beginDecoration &&
      c.length > 1 &&
      r.stroke &&
      e.strokeStyle &&
      e.strokeStyle.beginMarker &&
      e.strokeStyle.beginMarker.type === LineMarkerType.ARROW
    )
      t.push(
        new HTML5DrawArrowMarkerCommand(
          o,
          c[3],
          c[4],
          c[0],
          c[1],
          e,
          e.strokeStyle.beginMarker.size,
          r.zOrder
        )
      )
  }
}
function drawLine(e, n, o, r) {
  const t = o.worldReference
  const i = o.invalidateAction
  if (0 === e.pointCount) {
    r.push(drawNullCommand)
    return
  }
  if (1 === e.pointCount) {
    const n = normalizeIconStyle({
      image: PLACE_HOLDER_ICON,
      width: '3px',
      height: '3px',
    })
    r.push(new HTML5DrawIconCommand(t, e, e.getPoint(0), n, i))
    return
  }
  const a = normalizeShapeStyle(n, o)
  const l = a.stroke
  const s = l && 'undefined' !== typeof l.strokeStyle
  const m = undefined
  generateDrawCommandForLineShape(
    GeoDiscretizerStatic.discretize(e, {
      modelReference: e.reference,
      worldReference: t,
      lineType: a.lineType,
      beginDecoration: s ? l.strokeStyle.beginMarker : void 0,
      endDecoration: s ? l.strokeStyle.endMarker : void 0,
    }),
    e,
    t,
    a,
    r
  )
}
function drawArc(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function drawArcBand(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function drawSector(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function drawBounds(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function drawOrientedBox(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function drawCircle(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function drawEllipse(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function drawGeoBuffer(e, n, o, r) {
  generateLineCommand(e, n, o, r)
}
function generateLineCommand(e, n, o, r) {
  const t = normalizeShapeStyle(n, o)
  const i = GeoDiscretizerStatic.discretize(e, {
    modelReference: e.reference,
    worldReference: o.worldReference,
    lineType: t.lineType,
  })
  if (i) generateDrawCommandForSingleRingShape(r, i, e, t, o)
}
function generateDrawCommandForSingleRingShape(e, n, o, r, t) {
  const i = n.lines
  const a = n.fills
  const l = r.stroke
  const s = r.fill
  const m = r.zOrder || 0
  const p = !!l
  const c = !!s && a && a.length > 0
  const d = t.worldReference
  const f = t.invalidateAction
  const w = ShapeUtil.isShapeClosed(o)
  const h = r.innerBorder
  const S = r.outerBorder
  const u = w && !!h
  const y = w && !!S
  const C = e.length
  const T = i ? i.length : 0
  const g = T > 0 && i && i[0].length > 3
  if (y) D(S)
  if (p && !c) {
    if (w && u) D(h)
    D(l)
  } else if (!p && c)
    if (u) {
      M()
      D(h)
    } else M()
  else if (p && c)
    if (u) {
      M()
      D(h)
      D(l)
    } else L()
  else if (!p && !c) if (u) D(h)
  if (C === e.length) e.push(drawNullCommand)
  function D(n) {
    if (i)
      if (1 === i.length && i[0].length > 3)
        e.push(new HTML5DrawStrokeCommand(d, o, i[0], n, m, true))
      else if (i.length > 1)
        e.push(new HTML5DrawStrokeCommandMulti(d, o, i, n, m, false))
  }
  function L() {
    if (!!l && i && !!s && a && a.length > 0)
      if (1 === i.length && i[0].length > 3)
        if (n.canUseFillForStroke)
          e.push(new HTML5DrawStrokeFillCommand(d, o, m, s, f, i[0], l))
        else e.push(new HTML5DrawFillCommandDefault(d, o, a, i, l, s, f, m))
      else if (i.length > 1)
        e.push(new HTML5DrawFillCommandDefault(d, o, a, i, l, s, f, m))
  }
  function M() {
    if (!!s)
      if (1 === T && g) e.push(new HTML5DrawFillCommand(d, o, a[0][0], s, f, m))
      else if (T > 1) e.push(new HTML5DrawFillCommandMulti(d, o, a, s, f, m))
  }
}
function drawPolygon(e, n, o, r) {
  if (0 === e.pointCount) {
    r.push(drawNullCommand)
    return
  }
  if (1 === e.pointCount) {
    const n = normalizeIconStyle({
      image: PLACE_HOLDER_ICON,
      width: '3px',
      height: '3px',
    })
    const t = new HTML5DrawIconCommand(
      o.worldReference,
      e,
      e.getPoint(0),
      n,
      o.invalidateAction
    )
    r.push(t)
    return
  }
  const t = normalizeShapeStyle(n, o)
  const i = GeoDiscretizerStatic.discretize(e, {
    modelReference: e.reference,
    worldReference: o.worldReference,
    lineType: t.lineType,
  })
  if (!i?.lines)
    throw new ProgrammingError(
      'Geocanvas:: unexpected response from discretizer: should get fills and lines for polygon'
    )
  generateDrawCommandForSingleRingShape(r, i, e, t, o)
}
function drawComplexPolygon(e, n, o, r) {
  const t = normalizeShapeStyle(n, o)
  const i = t.stroke
  const a = t.fill
  const l = t.zOrder
  const s = o.worldReference
  const m = o.invalidateAction
  const p = GeoDiscretizerStatic.discretize(e, {
    modelReference: e.reference,
    worldReference: s,
    lineType: t.lineType,
  })
  const c = p?.lines
  const d = p?.fills
  const f = !!i && 'object' === typeof i
  const w = !!a && d && d.length > 0
  if (!d || !c)
    throw new ProgrammingError(
      'Geocanvas:: unexpected response from discretizer: should get fills and lines for complex polygon'
    )
  const h = ShapeUtil.isShapeClosed(e)
  const S = t.innerBorder
  const u = t.outerBorder
  const y = h && !!S
  const C = h && !!u
  const T = c.length
  const g = T > 0 && c[0].length > 3
  const D = r.length
  if (C) L(u)
  if (f && !w) {
    if (y) L(S)
    L(i)
  } else if (!f && w) {
    M()
    if (y) L(S)
  } else if (f && w)
    if (y) {
      M()
      L(S)
      L(i)
    } else k()
  else if (!f && !w) if (y) L(S)
  if (D === r.length) r.push(drawNullCommand)
  function L(n) {
    if (1 === T && g) r.push(new HTML5DrawStrokeCommand(s, e, c[0], n, l, true))
    else if (T > 1) r.push(new HTML5DrawStrokeCommandMulti(s, e, c, n, l, true))
  }
  function M() {
    if (1 === T && g) r.push(new HTML5DrawFillCommand(s, e, d[0][0], a, m, l))
    else if (T > 1) r.push(new HTML5DrawFillCommandMulti(s, e, d, a, m, l))
  }
  function k() {
    r.push(new HTML5DrawFillCommandDefault(s, e, d, c, i, a, m, l))
  }
}
