import { CoordinateType } from '../reference/CoordinateType.js'
import { ShapeType } from './ShapeType.js'
import { Bounds } from './Bounds.js'
import { Point, getValidatedPointRepresentation } from './Point.js'
import { isNumber, isUndefined } from '../util/Lang.js'
export class XYZBounds extends Bounds {
  constructor(t, e) {
    super()
    this._reference = this.validateReference(t)
    if (!e) e = [0, 0, 0, 0]
    const s = []
    s.push.apply(s, e)
    this._coords = s
  }
  get isGeodetic() {
    return false
  }
  getLocation() {
    return new XYZPoint(this.reference, [this.x, this.y, this.z])
  }
  get x() {
    return this._coords[0]
  }
  set x(t) {
    this._coords[0] = t
  }
  get width() {
    return this._coords[1]
  }
  set width(t) {
    if (t < 0) {
      this._coords[0] = this._coords[0] + t
      this._coords[1] = -t
    } else this._coords[1] = t
  }
  get y() {
    return this._coords[2]
  }
  set y(t) {
    this._coords[2] = t
  }
  get height() {
    return this._coords[3]
  }
  set height(t) {
    if (t < 0) {
      this._coords[2] = this._coords[2] + t
      this._coords[3] = -t
    } else this._coords[3] = t
  }
  get z() {
    return this._coords[4] || 0
  }
  set z(t) {
    if (this._coords.length > 4) this._coords[4] = t
    else this._coords.push(t, 0)
  }
  get depth() {
    return this._coords[5] || 0
  }
  set depth(t) {
    if (this._coords.length > 4) this._coords[5] = t
    else this._coords.push(0, t)
  }
  get coordinates() {
    return this._coords
  }
  get bounds() {
    return this
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  get focusPoint() {
    return new XYZPoint(this._reference, [
      this.x + this.width / 2,
      this.y + this.height / 2,
      this.z + this.depth / 2,
    ])
  }
  intersection(t) {
    if (!this.intersects2D(t)) return null
    let e = this.x
    let s = this.y
    const i = t.x
    const o = t.y
    let n = e + this.width
    let r = s + this.height
    const h = i + t.width
    const c = o + t.height
    if (e < i) e = i
    if (s < o) s = o
    if (n > h) n = h
    if (r > c) r = c
    n -= e
    r -= s
    const d = new XYZBounds(this.reference)
    d.setTo2D(e, n, s, r)
    return d
  }
  move2DToCoordinates(t, e) {
    const s = this._coords
    s[0] = t
    s[2] = e
  }
  move3DToCoordinates(t, e, s) {
    const i = this._coords
    i[0] = t
    i[2] = e
    if (i.length > 4) i[4] = s
    else i.push(s, 0)
  }
  toString() {
    if (void 0 !== this._coords[4] && void 0 !== this._coords[5])
      return `Bounds[${this.x},${this.width},${this.y},${this.height},${this.z},${this.depth}]`
    else return `Bounds[${this.x},${this.width},${this.y},${this.height}]`
  }
  translate2D(t, e) {
    this.move2DToCoordinates(t + this._coords[0], e + this._coords[2])
  }
  translate3D(t, e, s) {
    this.move3DToCoordinates(
      t + this._coords[0],
      e + this._coords[2],
      s + this.z
    )
  }
  contains2DCoordinates(t, e) {
    return (
      t >= this.x &&
      t <= this.x + this.width &&
      e >= this.y &&
      e <= this.y + this.height
    )
  }
  contains2D(t, e) {
    if (isNumber(t) && !isUndefined(e)) return this.contains2DCoordinates(t, e)
    else if ('undefined' !== typeof t.width && 'undefined' !== typeof t.height)
      return this.contains2DBounds(t)
    else return this.contains2DPoint(t)
  }
  contains3DCoordinates(t, e, s) {
    if (!this.contains2D(t, e)) return false
    let i = 0,
      o = 0
    if (this._coords.length > 4) {
      i = this._coords[4]
      o = this._coords[5]
    }
    return i <= s && s <= i + o
  }
  contains3DBounds(t) {
    if (!this.contains2D(t)) return false
    let e = 0,
      s = 0
    if (this._coords.length > 4) {
      e = this._coords[4]
      s = this._coords[5]
    }
    const i = t.coordinates
    let o = 0,
      n = 0
    if (i.length > 4) {
      o = i[4]
      n = i[5]
    }
    return e <= o && o + n <= e + s
  }
  intersects2D(t) {
    const e = this.x
    const s = this.y
    return !(
      e + this.width < t.x ||
      e > t.x + t.width ||
      s + this.height < t.y ||
      s > t.y + t.height
    )
  }
  setTo2DUnion(t) {
    if (!t) return
    this._compareReference(t.reference, t.coordinateType)
    this.implSetTo2DUnion(t.coordinates)
  }
  implSetTo2DUnion(t) {
    const e = this._coords
    let s = e[0]
    let i = s + e[1]
    let o = e[2]
    let n = o + e[3]
    const r = t[0]
    const h = r + t[1]
    const c = t[2]
    const d = c + t[3]
    if (r < s) s = r
    if (h > i) i = h
    if (c < o) o = c
    if (d > n) n = d
    e[0] = s
    e[1] = i - s
    e[2] = o
    e[3] = n - o
  }
  setTo3DUnion(t) {
    if (!t) return
    this._compareReference(t.reference, t.coordinateType)
    const e = t.coordinates
    this.implSetTo3DUnion(e)
  }
  implSetTo3DUnion(t) {
    this.implSetTo2DUnion(t)
    const e = this._coords
    const s = e.length > 4
    const i = undefined
    let o, n
    if (!(t.length > 4))
      if (!s) return
      else {
        o = 0
        n = 0
      }
    else {
      o = t[4]
      n = o + t[5]
    }
    let r, h
    if (s) {
      r = e[4]
      h = r + e[5]
      if (o < r) r = o
      if (n > h) h = n
    } else {
      r = o
      h = n
    }
    if (s) {
      e[4] = r
      e[5] = h - r
    } else e.push(r, h - r)
  }
  setTo2DIntersection(t) {
    this._compareReference(t.reference, t.coordinateType)
    const e = this.coordinates
    const s = e[0]
    const i = e[1]
    const o = e[2]
    const n = e[3]
    const r = t.coordinates
    const h = r[0]
    const c = r[1]
    const d = r[2]
    const a = r[3]
    let f = s
    let u = o
    let l = f + i
    let _ = u + n
    if (f < h) f = h
    if (u < d) u = d
    if (l > h + c) l = h + c
    if (_ > d + a) _ = d + a
    let p = l - f
    let g = _ - u
    if (p < 0 || g < 0) {
      p = 0
      g = 0
    }
    this.setTo2D(f, p, u, g)
  }
  setTo3DIntersection(t) {
    this._compareReference(t.reference, t.coordinateType)
    const e = this.coordinates
    const s = e[0]
    const i = e[1]
    const o = e[2]
    const n = e[3]
    const r = e[4]
    const h = e[5]
    const c = t.coordinates
    const d = c[0]
    const a = c[1]
    const f = c[2]
    const u = c[3]
    const l = c[4]
    const _ = c[5]
    let p = s
    let g = o
    let y = r
    let D = p + i
    let T = g + n
    let x = y + h
    if (p < d) p = d
    if (g < f) g = f
    if (y < l) y = l
    if (D > d + a) D = d + a
    if (T > f + u) T = f + u
    if (x > l + _) x = l + _
    let m = D - p
    let w = T - g
    let C = x - y
    if (m < 0 || w < 0 || C < 0) {
      m = 0
      w = 0
      C = 0
    }
    this.setTo3D(p, m, g, w, y, C)
  }
  setToIncludePoint2D(t) {
    const e = t.reference
    this._compareReference(e, t.coordinateType)
    const s = t.coordinates
    this.setTo2DUnion(new XYZBounds(e, [s[0], 0, s[1], 0]))
  }
  setToIncludeSimplePoint2D(t) {
    let { x: e, y: s } = t
    this.implSetTo2DUnion([e, 0, s, 0])
  }
  setToIncludePoint3D(t) {
    const e = t.reference
    this._compareReference(e, t.coordinateType)
    const s = t.coordinates
    if (s.length > 2)
      this.setTo3DUnion(new XYZBounds(e, [s[0], 0, s[1], 0, s[2], 0]))
    else this.setTo3DUnion(new XYZBounds(e, [s[0], 0, s[1], 0]))
  }
  setTo2D(t, e, s, i) {
    this._coords[0] = t
    this._coords[1] = e
    this._coords[2] = s
    this._coords[3] = i
  }
  setToBounds2D(t) {
    this._compareReference(t.reference, t.coordinateType)
    this._coords[0] = t.x
    this._coords[1] = t.width
    this._coords[2] = t.y
    this._coords[3] = t.height
  }
  setTo3D(t, e, s, i, o, n) {
    this._coords[0] = t
    this._coords[1] = e
    this._coords[2] = s
    this._coords[3] = i
    this._coords[4] = o
    this._coords[5] = n
  }
  setToBounds3D(t) {
    this._compareReference(t.reference, t.coordinateType)
    this._coords[0] = t.x
    this._coords[1] = t.width
    this._coords[2] = t.y
    this._coords[3] = t.height
    this._coords[4] = t.z
    this._coords[5] = t.depth
  }
  copy() {
    return new XYZBounds(this._reference, this._coords)
  }
  equals(t) {
    if (this === t) return true
    if (!this._referencesEqual(this.reference, t.reference)) return false
    return (
      this.x === t.x &&
      this.y === t.y &&
      this.z === t.z &&
      this.width === t.width &&
      this.height === t.height &&
      this.depth === t.depth
    )
  }
  squaredDistance2D(t, e) {
    let s, i, o, n
    if (this.contains2DCoordinates(t, e)) {
      s = this.x + 0.5 * this.width
      i = this.y + 0.5 * this.height
      if (s < t) o = this.x + this.width - t
      else if (s > t) o = this.x - t
      else o = 0.5 * this.width
      if (i < e) n = this.y + this.height - e
      else if (i > e) n = this.y - e
      else n = 0.5 * this.height
      return -(o * o + n * n)
    } else {
      if (this.x + this.width < t) o = this.x + this.width - t
      else if (this.x > t) o = this.x - t
      else o = 0
      if (this.y + this.height < e) n = this.y + this.height - e
      else if (this.y > e) n = this.y - e
      else n = 0
      return o * o + n * n
    }
  }
  maximumMargin(t) {
    const e = this.x - t.x
    const s = t.x + t.width - (this.x + this.width)
    const i = this.y - t.y
    const o = t.y + t.height - (this.y + this.height)
    return Math.max(e, s, i, o, 0)
  }
  scaleAroundCenter(t, e) {
    const s = this.width / t
    const i = this.height / e
    this.x += (this.width - s) / 2
    this.y += (this.height - i) / 2
    this.width = s
    this.height = i
  }
  get left() {
    return this.x
  }
  get bottom() {
    return this.y
  }
  get right() {
    return this.x + this.width
  }
  get top() {
    return this.y + this.height
  }
  interacts2D(t) {
    return this.intersects2D(t)
  }
  includeCoordinate2D(t, e) {
    this.implSetTo2DUnion([t, 0, e, 0])
  }
  includeCoordinate3D(t, e, s) {
    this.implSetTo3DUnion([t, 0, e, 0, s || 0, 0])
  }
}
export class XYZPoint extends Point {
  constructor(t, e, s) {
    super()
    this._reference = this.validateReference(t)
    this._cachedBounds = null
    this._coords = getValidatedPointRepresentation(e, s)
  }
  get isGeodetic() {
    return false
  }
  invalidate() {
    this._cachedBounds = null
  }
  copy() {
    const t = [this._coords[0], this._coords[1], this._coords[2]]
    return new XYZPoint(this._reference, t)
  }
  contains2D(t, e) {
    if ('undefined' === typeof e) return this.contains2DPoint(t)
    return this.contains2DCoordinates(t, e)
  }
  contains2DPoint(t) {
    this._compareReference(t.reference, t.coordinateType)
    return this.contains2DCoordinates(t.x, t.y)
  }
  contains2DCoordinates(t, e) {
    return this._coords[0] === t && this._coords[1] === e
  }
  contains3DCoordinates(t, e, s) {
    const i = this._coords
    return i[0] === t && i[1] === e && i[2] === s
  }
  move2DToCoordinates(t, e) {
    const s = this._coords
    s[0] = t
    s[1] = e
    this.invalidate()
  }
  move3DToCoordinates(t, e, s) {
    const i = this._coords
    i[0] = t
    i[1] = e
    i[2] = s
    this.invalidate()
  }
  translate2D(t, e) {
    const s = this._coords
    s[0] += t
    s[1] += e
    this.invalidate()
  }
  translate3D(t, e, s) {
    const i = this._coords
    i[0] += t
    i[1] += e
    i[2] += s
    this.invalidate()
  }
  equals(t) {
    if (
      !t ||
      (t.reference
        ? !t.reference.equals(this.reference)
        : t.reference !== this.reference) ||
      t.type !== ShapeType.POINT
    )
      return false
    return t === this || (t.x === this.x && t.y === this.y && t.z === this.z)
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    t.appendDouble(this.x)
    t.appendDouble(this.y)
    t.appendDouble(this.z)
  }
  toString(t) {
    return `${t ? '' : 'Point'}[${this._coords[0]},${this._coords[1]},${
      this._coords[2]
    }]`
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  set x(t) {
    this._coords[0] = t
    this.invalidate()
  }
  get x() {
    return this._coords[0]
  }
  set y(t) {
    this._coords[1] = t
    this.invalidate()
  }
  get y() {
    return this._coords[1]
  }
  set z(t) {
    this._coords[2] = t
    this.invalidate()
  }
  get z() {
    return this._coords[2]
  }
  get coordinates() {
    return this._coords
  }
  get focusPoint() {
    return this
  }
  get bounds() {
    if (!this._cachedBounds) {
      const t = this._coords
      this._cachedBounds = new XYZBounds(this._reference, [
        t[0],
        0,
        t[1],
        0,
        t[2],
        0,
      ])
    }
    return this._cachedBounds
  }
  get type() {
    return ShapeType.POINT
  }
}
