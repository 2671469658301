import { MultiPattern } from './MultiPattern.js'
import { instanceOfIFlexible } from './util/PatternInterfaces.js'
import { PatternType } from './util/PatternType.js'
export class ComposePattern extends MultiPattern {
  constructor(t) {
    super(t, PatternType.COMPOSE)
    let e = true
    let n = true
    const s = 100
    const a = this.patterns.length ? this.patterns[0].getWidth(s) : 0
    for (let t = 0; t < this.patterns.length; t++) {
      if (
        (e && !this.patterns[t].flexible) ||
        a !== this.patterns[t].getWidth(s)
      )
        e = false
      if (n && !this.patterns[t].canBend) n = false
    }
    this.flexible = e
    this.canBend = n
  }
  getWidth(t) {
    let e = 0
    let n
    for (let s = 0; s < this.patterns.length; s++) {
      n = this.patterns[s].getWidth(t)
      e = n < e ? e : n
    }
    return e
  }
  paintPartial(t, e, n, s) {
    if (!this.canBend) return
    const a = e.totalLength()
    const i = n - e.distanceFromStart()
    let r = 0
    const l = this.getWidth(a)
    while (r < this.patterns.length && !e.atEnd()) {
      const p = this.patterns[r]
      const h = p.getWidth(a)
      e.saveState()
      t.save()
      t.rotate(e.angle())
      t.translate((l - h) / 2, 0)
      t.rotate(-e.angle())
      if (h > i) p.paintPartial(t, e, n, s)
      else p.paintOnceOnLine(t, e, s)
      t.restore()
      e.restoreState()
      r += 1
    }
    e.advanceDistance(i)
  }
  paint(t, e, n, s) {
    const a = this.getWidth(e)
    let i, r, l
    for (i = 0; i < this.patterns.length; i += 1) {
      l = this.patterns[i]
      r = l.getWidth(e)
      t.save()
      t.translate((a - r) / 2, 0)
      l.paint(t, e, n, void 0)
      t.restore()
    }
  }
  paintFlexible(t, e, n) {
    for (let s = 0; s < this.patterns.length; s++) {
      const a = this.patterns[s]
      if (instanceOfIFlexible(a)) {
        t.save()
        a.paintFlexible(t, e, n)
        t.restore()
      }
    }
  }
  appendHash(t) {
    t.appendString('compose')
    super.appendHash(t)
  }
}
