export class AnchorPoints {
  constructor() {
    this.preferredCentroid = false
    this.anchorPoints = []
    this.anchorOrientationPoints = []
    this.anchorPointsLength = 0
    this.reset()
  }
  reset() {
    this.anchorPoints.length = 0
    this.anchorOrientationPoints.length = 0
    this.anchorPointsLength = 0
    this.preferredCentroid = false
    this.repeatingAnchorContext = {
      computedOnClippedPath: false,
      restrictedToWorldBounds: null,
      cannotBeReused: false,
    }
  }
  updateRepeatingAnchorContext(t, n) {
    const o = this.repeatingAnchorContext
    if (o && t) o.computedOnClippedPath = true
    if (o && o.restrictedToWorldBounds && n)
      if (o.restrictedToWorldBounds && !o.restrictedToWorldBounds.equals(n))
        o.restrictedToWorldBounds.setTo2DUnion(n)
      else o.restrictedToWorldBounds = n
  }
  add(t, n, o, i) {
    this.add3D(t, n, 0, o, i, 0)
  }
  add3D(t, n, o, i, s, r) {
    const e = undefined
    const h = 3 * this.anchorPointsLength
    this.anchorPoints[h] = t
    this.anchorPoints[h + 1] = n
    this.anchorPoints[h + 2] = o
    this.anchorOrientationPoints[h] = i
    this.anchorOrientationPoints[h + 1] = s
    this.anchorOrientationPoints[h + 2] = r
    this.anchorPointsLength += 1
  }
  exists(t, n, o) {
    for (let i = 0; i < this.anchorPointsLength; i++) {
      const s = 3 * i
      if (
        this.anchorPoints[s] === t &&
        this.anchorPoints[s + 1] === n &&
        this.anchorPoints[s + 2] === o
      )
        return true
    }
    return false
  }
  coordinatesAt(t) {
    if (t < 0 || t >= this.anchorPointsLength) return null
    return [
      this.anchorPoints[3 * t],
      this.anchorPoints[3 * t + 1],
      this.anchorPoints[3 * t + 2],
      this.anchorOrientationPoints[3 * t],
      this.anchorOrientationPoints[3 * t + 1],
      this.anchorOrientationPoints[3 * t + 2],
    ]
  }
}
