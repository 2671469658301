import { isNumber } from '../util/Lang.js'
export function coordinate_equals(r, t) {
  if (!r) return false
  return (
    r === t || (r[0] === t[0] && r[1] === t[1] && (r[2] ?? 0) === (t[2] ?? 0))
  )
}
export function coordinate_fromSimplePoint(r) {
  const { x: t, y: e, z: n } = r
  return [t, e, n ?? 0]
}
export function coordinate_validate(r) {
  const [t, e, n] = r
  if (!isNumber(t) || !isNumber(e)) return [0, 0, 0]
  return [t, e, n ?? 0]
}
