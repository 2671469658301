import { getKmlColor, getKmlColorMode } from '../../util/kml/KMLColor.js'
import { getKmlDisplayMode } from '../../util/kml/KMLDisplayMode.js'
import { getHotSpotValue } from '../../util/kml/KMLHotSpot.js'
import { getKmlItemIcon } from '../../util/kml/KMLItemIcon.js'
import { getListItemTypeValue } from '../../util/kml/KMLListItemType.js'
import {
  isBalloonStyleNode,
  isBgColorValue,
  isColorModeValue,
  isColorValue,
  isDisplayModeValue,
  isFillValue,
  isHeadingValue,
  isHotSpotValue,
  isHrefValue,
  isIconNode,
  isIconStyleNode,
  isItemIcon,
  isLabelStyleNode,
  isLineStyleNode,
  isListItemTypeValue,
  isListStyleNode,
  isOutlineValue,
  isPolyStyleNode,
  isScaleValue,
  isTextColorValue,
  isTextValue,
  isWidthValue,
} from '../../util/kml/KMLTypes.js'
import {
  xmlFindFirstChild,
  xmlReduceChildren,
  xmlWalkChildren,
} from '../../util/kml/KMLInternalUtil.js'
import {
  getKmlAngle360,
  getKmlBoolean,
  getKmlNumber,
  getKmlValue,
} from '../../util/kml/KMLValues.js'
import { KMLColorStyle, KMLStyle } from '../../view/kml/KMLStyle.js'
export class KMLStyleBuilder {
  constructor(l) {
    this._balloonStyleNode = null
    this._iconStyleNode = null
    this._labelStyleNode = null
    this._lineStyleNode = null
    this._polyStyleNode = null
    this._listStyleNode = null
    xmlWalkChildren(l, (l) => {
      if (isBalloonStyleNode(l)) return (this._balloonStyleNode = l)
      if (isIconStyleNode(l)) return (this._iconStyleNode = l)
      if (isLabelStyleNode(l)) return (this._labelStyleNode = l)
      if (isLineStyleNode(l)) return (this._lineStyleNode = l)
      if (isPolyStyleNode(l)) return (this._polyStyleNode = l)
      if (isListStyleNode(l)) return (this._listStyleNode = l)
    })
  }
  build() {
    let l =
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : new KMLStyle()
    this.buildBalloonStyle(l)
    this.buildIconStyle(l)
    this.buildLabelStyle(l)
    this.buildLineStyle(l)
    this.buildPolyStyle(l)
    this.buildListStyle(l)
    return l
  }
  buildBalloonStyle(l) {
    if (this._balloonStyleNode)
      xmlReduceChildren(
        this._balloonStyleNode,
        processKmlBalloonStyle,
        l.balloonStyle
      )
  }
  buildIconStyle(l) {
    if (this._iconStyleNode) {
      getKmlColorStyle(this._iconStyleNode, l.iconStyle)
      xmlReduceChildren(this._iconStyleNode, processKmlIconStyle, l.iconStyle)
    }
  }
  buildLabelStyle(l) {
    if (this._labelStyleNode) {
      getKmlColorStyle(this._labelStyleNode, l.labelStyle)
      xmlReduceChildren(
        this._labelStyleNode,
        processKmlLabelStyle,
        l.labelStyle
      )
    }
  }
  buildLineStyle(l) {
    if (this._lineStyleNode) {
      getKmlColorStyle(this._lineStyleNode, l.lineStyle)
      xmlReduceChildren(this._lineStyleNode, processKmlLineStyle, l.lineStyle)
    }
  }
  buildPolyStyle(l) {
    if (this._polyStyleNode) {
      getKmlColorStyle(this._polyStyleNode, l.polyStyle)
      xmlReduceChildren(this._polyStyleNode, processKmlPolyStyle, l.polyStyle)
    }
  }
  buildListStyle(l) {
    if (this._listStyleNode)
      xmlReduceChildren(this._listStyleNode, processKmlListStyle, l.listStyle)
  }
}
function processKmlBalloonStyle(l, e) {
  if (isBgColorValue(e)) {
    getKmlColor(e, l.rawBgColor)
    return l
  }
  if (isTextColorValue(e)) {
    getKmlColor(e, l.rawTextColor)
    return l
  }
  if (isTextValue(e)) {
    l.text = getKmlValue(e)
    return l
  }
  if (isDisplayModeValue(e)) {
    l.displayMode = getKmlDisplayMode(e, l.displayMode)
    return l
  }
  return l
}
function processKmlLabelStyle(l, e) {
  if (isScaleValue(e)) l.scale = getKmlNumber(e, l.scale)
  return l
}
function processKmlLineStyle(l, e) {
  if (isWidthValue(e)) l.width = getKmlNumber(e, l.width)
  return l
}
function processKmlListStyle(l, e) {
  if (isBgColorValue(e)) {
    getKmlColor(e, l.rawBgColor)
    return l
  }
  if (isItemIcon(e)) {
    getKmlItemIcon(e, l.itemIcon)
    return l
  }
  if (isListItemTypeValue(e)) {
    l.listItemType = getListItemTypeValue(e, l.listItemType)
    return l
  }
  return l
}
function processKmlPolyStyle(l, e) {
  if (isFillValue(e)) {
    l.fill = getKmlBoolean(e, l.fill)
    return l
  }
  if (isOutlineValue(e)) {
    l.outline = getKmlBoolean(e, l.outline)
    return l
  }
  return l
}
function processKmlIconStyle(l, e) {
  if (isHeadingValue(e)) {
    l.heading = getKmlAngle360(e, l.heading)
    return l
  }
  if (isIconNode(e)) {
    l.icon = { href: getKmlValue(xmlFindFirstChild(e, isHrefValue), null) }
    return l
  }
  if (isScaleValue(e)) {
    l.scale = getKmlNumber(e, l.scale)
    return l
  }
  if (isHotSpotValue(e)) {
    getKmlHotSpot(e, l.hotSpot)
    return l
  }
  return l
}
function getKmlColorStyle(l, e) {
  return xmlReduceChildren(l, processKmlColorStyle, e)
}
function processKmlColorStyle(l, e) {
  if (isColorValue(e)) {
    getKmlColor(e, KMLColorStyle.getRawColor(l))
    return l
  }
  if (isColorModeValue(e)) {
    l.colorMode = getKmlColorMode(e, l.colorMode)
    return l
  }
  return l
}
function getKmlHotSpot(l, e) {
  const t = getHotSpotValue(l)
  e.x = t.x
  e.y = t.y
  e.xUnits = t.xUnits
  e.yUnits = t.yUnits
  return e
}
