export let TokenType = (function (t) {
  t[(t['EOF'] = -1)] = 'EOF'
  t[(t['SCALAR'] = 0)] = 'SCALAR'
  t[(t['START_OBJECT'] = 1)] = 'START_OBJECT'
  t[(t['END_OBJECT'] = 2)] = 'END_OBJECT'
  t[(t['COMMA'] = 3)] = 'COMMA'
  return t
})({})
export class WKTTokenizer {
  constructor(t, i) {
    this._string = t
    this._position = i
    this._start = this._position.index
    this._length = 0
  }
  nextToken() {
    let t
    do {
      t = this.read()
    } while (-1 != t && this.isWhiteSpace(t))
    if (-1 == t) return TokenType.EOF
    if (']' == t || ')' == t) {
      this._start = this._position.index - 1
      this._length = 1
      return TokenType.END_OBJECT
    } else if (',' == t) return TokenType.COMMA
    else if ('"' == t || "'" == t) {
      const i = t
      this._start = this._position.index
      let e = -1
      t = this.read()
      while (-1 != t && !(t == i && e != i)) {
        e = t
        t = this.read()
      }
      if (-1 == t) return TokenType.EOF
      this._length = this._position.index - this._start - 1
      return TokenType.SCALAR
    } else {
      this._start = this._position.index - 1
      do {
        t = this.read()
      } while (
        -1 != t &&
        ((t >= 'a' && t <= 'z') ||
          (t >= 'A' && t <= 'Z') ||
          (t >= '0' && t <= '9') ||
          '-' == t ||
          '_' == t ||
          '+' == t ||
          '.' == t)
      )
      this._length = this._position.index - this._start
      if (-1 != t) this._length -= 1
      while (-1 != t && this.isWhiteSpace(t)) t = this.read()
      if ('[' == t || '(' == t) return TokenType.START_OBJECT
      else {
        if ("'" != t && '"' != t && -1 != t)
          this._position.index = this._position.index - 1
        return TokenType.SCALAR
      }
    }
  }
  isWhiteSpace(t) {
    return ' ' == t || '\t' == t
  }
  getStart() {
    return this._start
  }
  getValue() {
    return this._string
      .substring(this._start, this._start + this._length)
      .trim()
  }
  read() {
    if (this._position.index == this._string.length) return -1
    else {
      const t = this._position.index
      this._position.index = t + 1
      return this._string[t]
    }
  }
}
