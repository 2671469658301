import { ShapeType } from '../../../shape/ShapeType.js'
import { Editor } from '../Editor.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultSize,
} from '../handles/CreateByTemplateHandle.js'
import { ArcBandHelperHandle } from '../handles/helper/ArcBandHelperHandle.js'
export class ArcBandEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.ARC_BAND, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createMinRadiusStartHandle(e),
      this.createMaxRadiusStartHandle(e),
      this.createMinRadiusEndHandle(e),
      this.createMaxRadiusEndHandle(e),
      this.createHelperHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const a = e.shape
    return new PointDragHandle(
      () => a.center,
      (e) => a.move2DToPoint(e)
    )
  }
  createMinRadiusStartHandle(e) {
    const a = e.shape
    return new PointDragHandle(
      () => a.minRadiusStartCorner,
      (e) => {
        a.updateMinRadius(e)
        a.updateStartAzimuth(e)
      }
    )
  }
  createMinRadiusEndHandle(e) {
    const a = e.shape
    return new PointDragHandle(
      () => a.minRadiusEndCorner,
      (e) => {
        a.updateMinRadius(e)
        a.updateEndAngle(e)
      }
    )
  }
  createMaxRadiusStartHandle(e) {
    const a = e.shape
    return new PointDragHandle(
      () => a.maxRadiusStartCorner,
      (e) => {
        a.updateMaxRadius(e)
        a.updateStartAzimuth(e)
      }
    )
  }
  createMaxRadiusEndHandle(e) {
    const a = e.shape
    return new PointDragHandle(
      () => a.maxRadiusEndCorner,
      (e) => {
        a.updateMaxRadius(e)
        a.updateEndAngle(e)
      }
    )
  }
  createHelperHandle(e) {
    const a = e.shape
    return new ArcBandHelperHandle(a)
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, a) => {
      const t = e.shape
      t.move2DToPoint(a)
      const n = getDefaultSize(e.map)
      t.minRadius = 0
      t.maxRadius = n
      t.minRadius = n / 2
    })
  }
}
