export class WorldSized {
  constructor() {
    this._worldSizeContext = {}
  }
  get worldSizeContext() {
    return this._worldSizeContext
  }
  set worldSizeContext(t) {
    this._worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    if (!t) return 0
    if (
      this.worldSizeContext &&
      this.worldSizeContext.isWorldSize &&
      this.worldSizeContext.worldSizeSupport
    )
      return this.worldSizeContext.worldSizeSupport.toPixels(
        t,
        this.worldSizeContext.uom
      )
    return t
  }
}
