import { LayerTreeNode } from '../LayerTreeNode.js'
import { LayerTreeVisitor } from '../LayerTreeVisitor.js'
export class AddSubTreeVisitor {
  constructor(e, r) {
    this._startListeningToLayersEventsCallback = e
    this._addLayerCallback = r
  }
  visitLayer(e) {
    this._addLayerCallback(e)
    this._startListeningToLayersEventsCallback(e)
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
  visitLayerGroup(e) {
    e.visitChildren(this, LayerTreeNode.VisitOrder.BOTTOM_UP)
    this._startListeningToLayersEventsCallback(e)
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
}
