import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { Feature } from '../../../model/feature/Feature.js'
import { isUndefined } from '../../../util/Lang.js'
export const NULL_FEATURE = new Feature(null, void 0, '')
export class ScaleLevelCursor {
  constructor(e) {
    this._cursors = e
    this._index = e.length - 1
    this._finished = !e || 0 === e.length
    this._cacheHandled = new Set()
    this.prepareNext()
  }
  prepareNext() {
    if (this._finished) return
    if (this._cursors[this._index].hasNext()) return true
    do {
      this._index--
    } while (this._index >= 0 && !this._cursors[this._index].hasNext())
    if (this._index < 0) this._finished = true
  }
  acceptValue(e) {
    const r = e.id
    if (isUndefined(r)) return true
    const t = !this._cacheHandled.has(r)
    this._cacheHandled.add(r)
    return t
  }
  hasNext() {
    return !this._finished
  }
  next() {
    if (!this.hasNext())
      throw new ProgrammingError(
        'Cursor: next should only be called when hasNext returns true'
      )
    const e = this._cursors[this._index].next()
    this.prepareNext()
    return this.acceptValue(e) ? e : NULL_FEATURE
  }
}
