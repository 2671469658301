import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ProjectionType } from './ProjectionType.js'
import { Azimuthal } from './Azimuthal.js'
const sharedOutOfBoundsError = new OutOfBoundsError('LambertAzimuthalEqualArea')
export class LambertAzimuthalEqualArea extends Azimuthal {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._originSinLat = -1
    this._originSinLon = -1
    this._originCosLat = -1
    this._originCosLon = -1
    this.setOriginLon(t)
    this.setOriginLat(n)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    this._originSinLat = Math.sin(this.getOriginLat() * Constants.DEG2RAD)
    this._originSinLon = Math.sin(this.getOriginLon() * Constants.DEG2RAD)
    this._originCosLat = Math.cos(this.getOriginLat() * Constants.DEG2RAD)
    this._originCosLon = Math.cos(this.getOriginLon() * Constants.DEG2RAD)
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, n, i) {
    if (this.inLonLatBounds(t)) {
      const s = Math.sin(t.x * Constants.DEG2RAD)
      const o = Math.cos(t.x * Constants.DEG2RAD)
      const r = s * this._originCosLon - o * this._originSinLon
      const a = o * this._originCosLon + s * this._originSinLon
      const e = Math.sin(t.y * Constants.DEG2RAD)
      const h = Math.cos(t.y * Constants.DEG2RAD)
      const L = h * r
      const c = this._originCosLat * e - this._originSinLat * h * a
      const g =
        n *
        Math.sqrt(2 / (1 + this._originSinLat * e + this._originCosLat * h * a))
      i.x = L * g
      i.y = c * g
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereSFCT(t, n, i) {
    if (this.inWorldBoundsOnSphere(t, n)) {
      const s = t.x
      const o = t.y
      const r = Math.sqrt(s * s + o * o)
      if (r < 1e-10) {
        i.x = this.getOriginLon()
        i.y = this.getOriginLat()
        return
      }
      const a = 2 * Math.asin(r / (2 * n))
      const e = Math.sin(a)
      const h = Math.cos(a)
      const L = h * this._originSinLat + (o * e * this._originCosLat) / r
      const c = undefined
      const g =
        Math.atan2(
          s * e,
          r * this._originCosLat * h - o * this._originSinLat * e
        ) * Constants.RAD2DEG
      i.x = normalizeLon(this.getOriginLon() + g)
      i.y = Math.asin(L) * Constants.RAD2DEG
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    const n = normalizeLon(t.x - this.getOriginLon())
    if (
      Math.abs(t.y + this.getOriginLat()) < this.EPSILON &&
      Math.abs(n) > 180 - this.EPSILON
    )
      return false
    else {
      const n = undefined
      return Math.abs(t.y - this.getOriginLat()) < 180 - this.EPSILON
    }
  }
  inWorldBoundsOnSphere(t, n) {
    const i = t.x / n
    const s = t.y / n
    return i * i + s * s < 4
  }
  boundaryLons(t) {
    return [
      [
        normalizeLon(-180 + this.getOriginLon() + this.EPSILON),
        normalizeLon(180 + this.getOriginLon() - this.EPSILON),
      ],
    ]
  }
  boundaryLats(t) {
    const n = this._originSinLat
    const i =
      this._originCosLat *
      Math.cos((t - this.getOriginLon()) * Constants.DEG2RAD)
    const s = undefined
    const o = undefined
    const r = -1 / Math.sqrt(n * n + i * i)
    if (Math.abs(r) < 1) {
      const t = Constants.RAD2DEG * Math.acos(r) - this.EPSILON
      const s = Constants.RAD2DEG * Math.atan2(n, i)
      return [[Math.max(s - t, -90), Math.min(s + t, 90)]]
    } else {
      const t = Math.abs(90 - this.getOriginLat())
      const n = t > 180 - this.EPSILON
      const i = t < this.EPSILON
      const s = this.EPSILON + 1e-12
      return [[i ? -90 + s : -90, n ? 90 - s : 90]]
    }
  }
  cartesianBoundsOnSphereSFCT(t, n) {
    const i = 2 * t
    n.setTo2D(-i, 2 * i, -i, 2 * i)
  }
  encode() {
    return {
      type: 'LambertAzimuthalEqualArea',
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return (
      ProjectionType.LAMBERT_AZIMUTHAL_EQUAL_AREA + ProjectionType.AZIMUTHAL
    )
  }
}
