import { InvalidReferenceError } from '../error/InvalidReferenceError.js'
import { NotImplementedError } from '../error/NotImplementedError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { CartesianReference } from '../reference/CartesianReference.js'
import { GeoReference } from '../reference/GeoReference.js'
import { isNumber } from '../util/Lang.js'
export class Shape {
  get reference() {
    return this._reference
  }
  set reference(e) {
    throw new ProgrammingError('reference property is not mutable')
  }
  contains2D(e, r) {
    if ('undefined' === typeof r) return this.contains2DPoint(e)
    return this.contains2DCoordinates(e, r)
  }
  contains2DPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    return this.contains2DCoordinates(e.x, e.y)
  }
  contains3DPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    return this.contains3DCoordinates(e.x, e.y, e.z)
  }
  _compareReference(e, r) {
    if (this.reference && e) {
      if (this.reference.equals(e)) return true
    } else if (this.coordinateType === r) return true
    throw new InvalidReferenceError(
      `Shape: Reference does not match ${this.reference} - ${e}`
    )
  }
  _compareReferenceStrict(e, r) {
    let n = true
    if (this.reference) {
      if (!this.reference.equals(e)) n = false
    } else if (e) n = false
    if (!n) {
      const n = `${this.reference} <> ${e}`
      throw new InvalidReferenceError(
        (r = r ? `${r} (${n})` : `References do not match: ${n}`)
      )
    }
    return e
  }
  _referencesEqual(e, r) {
    return e ? e.equals(r) : !r
  }
  invalidate() {
    throw new NotImplementedError('Child must implement invalidate')
  }
  hashCode(e) {
    throw new NotImplementedError('Child must implement hashCode')
  }
  validateReference(e) {
    if ('undefined' === typeof e || null === e) return null
    if (e instanceof GeoReference || e instanceof CartesianReference) return e
    throw new InvalidReferenceError(
      `${this.constructor.name} doesn't support this reference: ${e}`
    )
  }
}
export function isShape(e) {
  return e && isNumber(e.type)
}
export function isOfShapeType(e, r) {
  return e.type === r
}
