import { XYZBounds } from '../../shape/XYZBounds.js'
import { CoordinateArrayUtil } from './CoordinateArrayUtil.js'
import { appendPathSFCT } from './HTML5CanvasUtil.js'
import { HTML5DrawFillCommandProto } from './HTML5DrawFillCommandProto.js'
import { ringContainsXY2DFlatCoordList } from '../../util/Cartesian.js'
import {
  getPerpendicularOffsetInPixels,
  saveAndOffsetContext,
} from './OffsetsUtil.js'
const tempBounds = new XYZBounds(null)
export class HTML5DrawFillCommand extends HTML5DrawFillCommandProto {
  constructor(t, o, r, s, i, n) {
    super(t, o, n, s, i)
    this._coordinates = r
    this._normalizedOffsets = s.normalizedOffsets
    this._coordinatesArray = new Array(2)
    this._coordinatesGp = [r]
    if (this._coordinates.length >= 2) {
      const t = tempBounds.copy()
      CoordinateArrayUtil.calculateBoundsSFCT(this._coordinates, t)
      this.bounds = t
    }
  }
  draw(t, o, r) {
    saveAndOffsetContext(t, this._normalizedOffsets, r)
    const s = getPerpendicularOffsetInPixels(
      this.fillStyle.normalizedPerpendicularOffset,
      r
    )
    t.beginPath()
    appendPathSFCT(this._coordinates, t, o, this._coordinatesArray, s)
    t.closePath()
    t.restore()
    this._fill(t, this._coordinatesArray[0], this._coordinatesArray[1], r)
  }
  interacts(t, o, r) {
    const s = t.focusPoint
    return ringContainsXY2DFlatCoordList(this._coordinates, s.x, s.y)
  }
  strokeInteracts(t, o, r) {
    return false
  }
  mapAnchorPointSFCT(t, o) {
    return CoordinateArrayUtil.mapAnchorPointSFCT(this._coordinatesGp, true, o)
  }
  mapAnchorPointsSFCT(t, o, r) {
    if (!this.bounds) return
    t.getViewBounds(tempBounds)
    CoordinateArrayUtil.mapAnchorPointsSFCT(
      this._coordinatesGp,
      this.bounds,
      tempBounds,
      t.mapToViewTransformation,
      r,
      o
    )
  }
}
