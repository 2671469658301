import { NULL_FEATURE } from './ScaleLevelCursor.js'
import { isCluster } from '../transformation/ClusteringTransformer.js'
export class CursorProcessor {
  constructor(t, e, s) {
    let { shouldUpdateFunc: i, incremental: r, clearFlag: h } = s
    this._cursor = t
    this._workingSet = e
    this._shouldUpdateFunc = i
    this._clearRequired = h
    this._idMapSnapshot = h ? null : e.getIdMapSnapshot()
    this._incremental = r
    this._shouldDelete = true
  }
  _processObject(t) {
    if (!t || t === NULL_FEATURE) return
    if (!this._idMapSnapshot) {
      this._workingSet._addObject(t, t.id)
      return
    }
    if (!this._idMapSnapshot.has(t.id)) this._workingSet._addObject(t, t.id)
    else if (!this._clearRequired) {
      const e = this._idMapSnapshot.get(t.id)
      const s = e?.feature
      if (s && (isCluster(t) || this._shouldUpdateFunc(s, t)))
        this._workingSet._updateObject(t, t.id)
      this._idMapSnapshot.delete(t.id)
    }
  }
  finished() {
    return !this._cursor.hasNext()
  }
  processCursor(t) {
    if (this._clearRequired) {
      this._workingSet._clearData()
      this._clearRequired = false
    }
    while (this._cursor.hasNext()) {
      const e = this._cursor.next()
      this._processObject(e)
      if (this._incremental && Date.now() >= t) break
    }
    if (this._cursor.hasNext()) return
    if (this._shouldDelete && this._idMapSnapshot) {
      this._idMapSnapshot.forEach((t, e) =>
        this._workingSet._removeObject(t.feature, e)
      )
      this._idMapSnapshot.clear()
    }
  }
  set shouldDelete(t) {
    this._shouldDelete = t
  }
}
