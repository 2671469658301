import {
  createCartesianGeodesy,
  createEllipsoidalGeodesy,
} from '../../../geodesy/GeodesyFactory.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import * as LLHCircularArcUtil from '../../../shape/LLHCircularArcUtil.js'
import { createPolyline, createShapeList } from '../../../shape/ShapeFactory.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import * as XYZCircularArcUtil from '../../../shape/XYZCircularArcUtil.js'
import { Editor } from '../Editor.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import { CircularArcHelperHandle } from '../handles/helper/CircularArcHelperHandle.js'
import {
  CreateByTemplateHandle,
  getDefaultPoint,
} from '../handles/CreateByTemplateHandle.js'
export class CircularArcByBulgeEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.CIRCULAR_ARC_BY_BULGE, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createStartPointHandle(e),
      this.createBulgeHandle(e),
      this.createEndPointHandle(e),
      this.createHelperHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.center,
      (e) => t.translate2D(e.x - t.center.x, e.y - t.center.y)
    )
  }
  createStartPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.startPoint,
      (e) => t.moveStartPoint2DToPoint(e)
    )
  }
  createBulgeHandle(e) {
    const t = e.shape
    const r = t.center.copy()
    const n = undefined
    const a =
      t.reference.referenceType === ReferenceType.GEODETIC
        ? LLHCircularArcUtil.interpolate
        : XYZCircularArcUtil.interpolate
    return new PointDragHandle(
      () => {
        a(t, 0.5, r)
        return r
      },
      (e) => {
        t.bulge = t.calculateBulge(e)
      }
    )
  }
  createEndPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.endPoint,
      (e) => t.moveEndPoint2DToPoint(e)
    )
  }
  createHelperHandle(e) {
    const t = e.shape
    const r = t.reference
    const n =
      r.referenceType === ReferenceType.GEODETIC
        ? LLHCircularArcUtil.interpolate
        : XYZCircularArcUtil.interpolate
    const a =
      r.referenceType === ReferenceType.GEODETIC
        ? createEllipsoidalGeodesy(r)
        : createCartesianGeodesy(r)
    const o = t.center.copy()
    const l = undefined
    return new CircularArcHelperHandle(() => {
      n(t, 0.5, o)
      const e = a.interpolate(t.startPoint, t.endPoint, 0.5)
      const l = createPolyline(r, [t.startPoint, t.endPoint])
      const i = createPolyline(r, [e, o])
      return createShapeList(r, [l, i])
    })
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, t) => {
      const r = e.shape
      r.move2DToPoint(t)
      r.moveStartPoint2DToPoint(getDefaultPoint(e.map, t, 0))
      r.moveEndPoint2DToPoint(getDefaultPoint(e.map, t, 120))
    })
  }
}
