import { ProgrammingError } from '../error/ProgrammingError.js'
import { Constants } from '../util/Constants.js'
import { GeodeticDatum } from './GeodeticDatum.js'
import { Ellipsoid } from './Ellipsoid.js'
const ELLIPSOIDS = new Map()
const DATUMS = new Map()
const AIRY = 'AIRY'
const AIRY_MODIFIED_1849 = 'Airy_Modified_1849'
const MICRO = 1e-6
const AUSTRALIAN_NATIONAL = 'AUSTRALIAN_NATIONAL'
const BESSEL_1841 = 'BESSEL_1841'
const BESSEL_1841_NAMBIA = 'BESSEL_1841_NAMBIA'
const CLARKE_1866 = 'CLARKE_1866'
const CLARKE_1880 = 'CLARKE_1880'
const EVEREST = 'EVEREST'
const EVEREST_1830_MODIFIED = 'Everest_1830_Modified'
const FISCHER_1960_MERCURY = 'FISCHER_1960_MERCURY'
const FISCHER_1968 = 'FISCHER_1968'
const GRS_1967 = 'GRS_1967'
const GRS_1967_MODIFIED = 'GRS_1967_Modifued'
const GRS_1980 = 'GRS_1980'
const HELMERT_1906 = 'HELMERT_1906'
const HOUGH = 'HOUGH'
const INTERNATIONAL = 'INTERNATIONAL'
const KRASSOVSKY = 'KRASSOVSKY'
const MODIFIED_AIRY = 'MODIFIED_AIRY'
const MODIFIED_EVEREST = 'MODIFIED_EVEREST'
const FISCHER_MODIFIED = 'FISCHER_MODIFIED'
const SOUTH_AMERICAN = 'SOUTH_AMERICAN'
const WGS_1960 = 'WGS_1960'
const WGS_1966 = 'WGS_1966'
const WGS_1972 = 'WGS_1972'
const WGS_1984 = 'WGS_1984'
const BESSEL_1841_MODIFIED = 'Bessel_Modified'
const CLARKE_1858 = 'Clarke_1858'
const EVEREST_1830_1937_ADJUSTMENT = 'Everest_1830_(1937 Adjustment)'
const AVERAGE_TERRESTRIAL = 'Average_Terrestrial_System_1977'
const SPHERE = 'Sphere'
const OSU91A = 'OSU91A'
const OSU86F = 'OSU86F'
const GEM_10C = 'GEM_10C'
const WAR_OFFICE = 'War_Office'
const STRUVE_1860 = 'Struve_1860'
const PLESSIS_1817 = 'Plessis_1817'
const NWL_10D = 'NWL_10D'
const NWL_9D = 'NWL_9D'
const INTERNATIONAL_1967 = 'International_1967'
const INTERNATIONAL_1924 = 'International_1924'
const INDONESIAN_NATIONAL_SPHEROID = 'Indonesian_National_Spheroid'
const EVEREST_1830_1975_DEFINITION = 'Everest_1830_(1975 Definition)'
const EVEREST_1830_1962_DEFINITION = 'Everest_1830_(1962 Definition)'
const EVEREST_1830_1967_DEFINITION = 'Everest_1830_(1967 Definition)'
const CLARKE_1880_SGA_1922 = 'Clarke_1880 (SGA 1922)'
const CLARKE_1880_ARC = 'Clarke_1880 (Arc)'
const CLARKE_1880_RGS = 'Clarke_1880 (RGS)'
const CLARKE_1880_IGN = 'Clarke_1880 (IGN)'
const CLARKE_1880_BENOIT = 'Clarke_1880 (Benoit)'
const CLARKE_1866_MICHIGAN = 'Clarke_1880 Michigan'
const WGS_84 = 'WGS_84'
const ADINDAN = 'ADINDAN'
const AFG = 'AFG'
const AIN_EL_ABD_1970 = 'AIN_EL_ABD_1970'
const ALASKA_NAD_1927 = 'ALASKA_NAD_1927'
const ALASKA_CANADA_NAD_1927 = 'ALASKA_CANADA_NAD_1927'
const EUROPEAN_1950 = 'EUROPEAN_1950'
const EUROPEAN_1950_MEAN = 'EUROPEAN_1950_MEAN'
const EUROPEAN_1979_MEAN = 'EUROPEAN_1979_MEAN'
const NORTH_AMERICA_1983 = 'NORTH_AMERICA_1983'
const NORTH_AMERICA_1927_MEAN = 'NORTH_AMERICA_1927_MEAN'
const NORTH_AMERICA_1983_HARN = 'NORTH_AMERICA_1983_HARN'
const AUSTRALIAN_GEODETIC_DATUM_1966 = 'Australian_Geodetic_Datum_1966'
function addEllipsoid(_, a, S) {
  const d = _.toUpperCase()
  const t = new Ellipsoid()
  if (S > 0) t.initializeA1OverF(a, S)
  else t.initializeAB(a, a)
  t.name = d
  ELLIPSOIDS.set(d, t)
}
function addEllipsoidAlias(_, a) {
  addAlias(ELLIPSOIDS, _.toUpperCase(), [a])
}
function addDatum(_, a, S, d, t, A, o, E, G, i) {
  const D = normalizeObjectName(_.toUpperCase())
  const N = getDatum(a)
  if (!N)
    throw new ProgrammingError(
      `Could not find reference datum with name '${a}'`
    )
  const W = getEllipsoid(S)
  if (!W)
    throw new ProgrammingError(`Could not find ellipsoid with name '${S}'`)
  addDatumInstance(
    D,
    new GeodeticDatum({
      x: d,
      y: t,
      z: A,
      rotX: (o / 3600) * Constants.DEG2RAD,
      rotY: (E / 3600) * Constants.DEG2RAD,
      rotZ: (G / 3600) * Constants.DEG2RAD,
      scale: 1 + 1e-6 * i,
      ellipsoid: W,
      referenceDatum: N,
      name: _,
    })
  )
}
function addDatumInstance(_, a) {
  DATUMS.set(_, a)
}
function addDatumAlias(_, a) {
  addAlias(DATUMS, normalizeObjectName(_), a)
}
function addAlias(_, a, S) {
  for (let d = 0; d < S.length; d++) {
    const t = normalizeObjectName(S[d])
    if (t in _)
      throw new ProgrammingError(`Object already registered for key ${t}`)
    const A = _.get(a)
    if (!A)
      throw new ProgrammingError(
        `Making alias '${S}' for '${a}', but the original could not be found`
      )
    _.set(t, A)
  }
}
export function getEllipsoid(_) {
  const a = ELLIPSOIDS.get(normalizeObjectName(_))
  if (a) return a
  else return null
}
export function getDatum(_) {
  const a = DATUMS.get(normalizeObjectName(_))
  if (null != a)
    return new GeodeticDatum({
      x: a.x,
      y: a.y,
      z: a.z,
      rotX: a.rotX,
      rotY: a.rotY,
      rotZ: a.rotZ,
      scale: a.scale,
      primeMeridian: a.primeMeridian,
      ellipsoid: a.ellipsoid,
      referenceDatum: a.referenceDatum,
      name: a.name,
    })
  else return null
}
function normalizeObjectName(_) {
  return _.toUpperCase().replace(new RegExp(' ', 'g'), '_')
}
addEllipsoid(AIRY, 6377563.396, 299.3249646)
addEllipsoid(AIRY_MODIFIED_1849, 6377340.189, 299.3249646)
addEllipsoid(AUSTRALIAN_NATIONAL, 6378160, 298.25)
addEllipsoid(BESSEL_1841, 6377397.155, 299.1528128)
addEllipsoid(BESSEL_1841_MODIFIED, 6377492.0176, 299.15281)
addEllipsoid(BESSEL_1841_NAMBIA, 6377483.865, 299.1528128)
addEllipsoid(CLARKE_1858, 6378293.639, 294.2606764)
addEllipsoid(CLARKE_1866, 6378206.4, 294.9786982)
addEllipsoid(CLARKE_1866_MICHIGAN, 6378450.0472441, 294.978684676652)
addEllipsoid(CLARKE_1880_BENOIT, 6378300.789, 293.46631553898015)
addEllipsoid(CLARKE_1880_IGN, 6378249.2, 293.4660212936265)
addEllipsoid(CLARKE_1880_RGS, 6378249.145, 293.465)
addEllipsoid(CLARKE_1880_ARC, 6378249.145, 293.4663077)
addEllipsoid(CLARKE_1880_SGA_1922, 6378249.2, 293.46598)
addEllipsoid(EVEREST_1830_1937_ADJUSTMENT, 6377276.345, 300.8017)
addEllipsoid(EVEREST_1830_1967_DEFINITION, 6377298.556, 300.8017)
addEllipsoid(EVEREST_1830_1975_DEFINITION, 6377299.151, 300.8017255)
addEllipsoid(EVEREST_1830_MODIFIED, 6377304.063, 300.8017)
addEllipsoid(GRS_1967, 6378160, 298.247167427)
addEllipsoid(GRS_1980, 6378137, 298.257222101)
addEllipsoid(HELMERT_1906, 6378200, 298.3)
addEllipsoid(HOUGH, 6378270, 297)
addEllipsoid(INDONESIAN_NATIONAL_SPHEROID, 6378160, 298.247)
addEllipsoid(INTERNATIONAL_1924, 6378388, 297)
addEllipsoid(INTERNATIONAL_1967, 6378160, 298.2471674)
addEllipsoid(KRASSOVSKY, 6378245, 298.3)
addEllipsoid(NWL_9D, 6378145, 298.25)
addEllipsoid(NWL_10D, 6378135, 298.26)
addEllipsoid(PLESSIS_1817, 6376523, 308.64)
addEllipsoid(STRUVE_1860, 6378297, 294.73)
addEllipsoid(WAR_OFFICE, 6378300.583, 296)
addEllipsoid(WGS_1984, 6378137, 298.257223563)
addEllipsoid(GEM_10C, 6378137, 298.2572236)
addEllipsoid(OSU86F, 6378136.2, 298.2572236)
addEllipsoid(OSU91A, 6378136.3, 298.2572236)
addEllipsoid(CLARKE_1880, 6378264.035, 293.4663077)
addEllipsoid(SPHERE, 6371e3, -1)
addEllipsoid(GRS_1967_MODIFIED, 6378160, 298.25)
addEllipsoid(SOUTH_AMERICAN, 6378160, 298.25)
addEllipsoid(FISCHER_1960_MERCURY, 6378166, 298.3)
addEllipsoid(FISCHER_1968, 6378150, 298.3)
addEllipsoid(FISCHER_MODIFIED, 6378155, 298.3)
addEllipsoid(AVERAGE_TERRESTRIAL, 6378135, 298.257)
addEllipsoid(WGS_1960, 6378165, 298.3)
addEllipsoid(WGS_1966, 6378145, 298.25)
addEllipsoid(WGS_1972, 6378135, 298.26)
addEllipsoidAlias(CLARKE_1866_MICHIGAN, CLARKE_1866_MICHIGAN.replace(/ /g, '_'))
addEllipsoidAlias(CLARKE_1880_BENOIT, CLARKE_1880_BENOIT.replace(/ /g, '_'))
addEllipsoidAlias(CLARKE_1880_IGN, CLARKE_1880_IGN.replace(/ /g, '_'))
addEllipsoidAlias(CLARKE_1880_RGS, CLARKE_1880_RGS.replace(/ /g, '_'))
addEllipsoidAlias(CLARKE_1880_ARC, CLARKE_1880_ARC.replace(/ /g, '_'))
addEllipsoidAlias(CLARKE_1880_SGA_1922, CLARKE_1880_SGA_1922.replace(/ /g, '_'))
addEllipsoidAlias(
  EVEREST_1830_1937_ADJUSTMENT,
  EVEREST_1830_1937_ADJUSTMENT.replace(/ /g, '_')
)
addEllipsoidAlias(
  EVEREST_1830_1967_DEFINITION,
  EVEREST_1830_1967_DEFINITION.replace(/ /g, '_')
)
addEllipsoidAlias(
  EVEREST_1830_1975_DEFINITION,
  EVEREST_1830_1975_DEFINITION.replace(/ /g, '_')
)
addEllipsoidAlias(AIRY_MODIFIED_1849, MODIFIED_AIRY)
addEllipsoidAlias(BESSEL_1841, 'BESSEL')
addEllipsoidAlias(EVEREST_1830_MODIFIED, MODIFIED_EVEREST)
addEllipsoidAlias(EVEREST_1830_1937_ADJUSTMENT, EVEREST)
addEllipsoidAlias(FISCHER_1960_MERCURY, 'FISCHER_1960')
addEllipsoidAlias(FISCHER_1960_MERCURY, 'FISCHER_60')
addEllipsoidAlias(FISCHER_1968, 'FISCHER_68')
addEllipsoidAlias(INTERNATIONAL_1924, INTERNATIONAL)
addEllipsoidAlias(GRS_1967, 'GRS_67')
addEllipsoidAlias(GRS_1967, 'IUGG_1967')
addEllipsoidAlias(GRS_1967, 'IUGG_67')
addEllipsoidAlias(GRS_1980, 'GRS_80')
addEllipsoidAlias(GRS_1980, 'IUGG_1980')
addEllipsoidAlias(GRS_1980, 'IUGG_80')
addEllipsoidAlias(KRASSOVSKY, 'KRASSOVSKI')
addEllipsoidAlias(KRASSOVSKY, 'KRASOVSKY')
addEllipsoidAlias(KRASSOVSKY, 'KRASOVSKI')
addEllipsoidAlias(WGS_1960, 'WGS_60')
addEllipsoidAlias(WGS_1966, 'WGS_66')
addEllipsoidAlias(WGS_1972, 'WGS_72')
addEllipsoidAlias(WGS_1984, WGS_84)
addDatumInstance(
  normalizeObjectName(WGS_84),
  new GeodeticDatum({ ellipsoid: getEllipsoid(WGS_84), name: WGS_84 })
)
addDatum(
  'Adindan_to_WGS84_MEAN',
  WGS_84,
  CLARKE_1880_RGS,
  -166,
  -15,
  204,
  0,
  0,
  0,
  0
)
addDatum(
  'Adindan_to_WGS84_Burkina_Faso',
  WGS_84,
  CLARKE_1880_RGS,
  -118,
  -14,
  218,
  0,
  0,
  0,
  0
)
addDatum(
  'Adindan_to_WGS84_Cameroon',
  WGS_84,
  CLARKE_1880_RGS,
  -134,
  -2,
  210,
  0,
  0,
  0,
  0
)
addDatum(
  'Adindan_to_WGS84_Ethiopia',
  WGS_84,
  CLARKE_1880_RGS,
  -165,
  -11,
  206,
  0,
  0,
  0,
  0
)
addDatum(
  'Adindan_to_WGS84_Mali',
  WGS_84,
  CLARKE_1880_RGS,
  -123,
  -20,
  220,
  0,
  0,
  0,
  0
)
addDatum(
  'Adindan_to_WGS84_Senegal',
  WGS_84,
  CLARKE_1880_RGS,
  -128,
  -18,
  224,
  0,
  0,
  0,
  0
)
addDatum(
  'Adindan_to_WGS84_Sudan',
  WGS_84,
  CLARKE_1880_RGS,
  -161,
  -14,
  205,
  0,
  0,
  0,
  0
)
addDatum(
  'Afgooye_to_WGS84_Somalia',
  WGS_84,
  KRASSOVSKY,
  -43,
  -163,
  45,
  0,
  0,
  0,
  0
)
addDatum(
  'AGD66_to_WGS84_Australia',
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -133,
  -48,
  148,
  0,
  0,
  0,
  0
)
addDatum(
  'AGD84_to_WGS84_Australia',
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -134,
  -48,
  149,
  0,
  0,
  0,
  0
)
addDatum(
  'Ain_el_Abd_to_WGS84_Bahrain',
  WGS_84,
  INTERNATIONAL_1924,
  -150,
  -250,
  -1,
  0,
  0,
  0,
  0
)
addDatum(
  'Ain_el_Abd_to_WGS84_Saudi_Arabia',
  WGS_84,
  INTERNATIONAL_1924,
  -143,
  -236,
  7,
  0,
  0,
  0,
  0
)
addDatum(
  'Amersfoort_to_WGS84_Netherlands',
  WGS_84,
  BESSEL_1841,
  593.16,
  26.15,
  478.54,
  -6.3239 * MICRO,
  -0.5008 * MICRO,
  -5.5487 * MICRO,
  4.0775
)
addDatum(
  'Arc_1950_to_WGS84_MEAN',
  WGS_84,
  CLARKE_1880_ARC,
  -143,
  -90,
  -294,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Botswana',
  WGS_84,
  CLARKE_1880_ARC,
  -138,
  -105,
  -289,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Burundi',
  WGS_84,
  CLARKE_1880_ARC,
  -153,
  -5,
  -292,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Lesotho',
  WGS_84,
  CLARKE_1880_ARC,
  -125,
  -108,
  -295,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Malawi',
  WGS_84,
  CLARKE_1880_ARC,
  -161,
  -73,
  -317,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Swaziland',
  WGS_84,
  CLARKE_1880_ARC,
  -134,
  -105,
  -295,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Zaire',
  WGS_84,
  CLARKE_1880_ARC,
  -169,
  -19,
  -278,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Zambia',
  WGS_84,
  CLARKE_1880_ARC,
  -147,
  -74,
  -283,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1950_to_WGS84_Zimbabwe',
  WGS_84,
  CLARKE_1880_ARC,
  -142,
  -96,
  -293,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1960_to_WGS84_MEAN',
  WGS_84,
  CLARKE_1880_RGS,
  -160,
  -6,
  -302,
  0,
  0,
  0,
  0
)
addDatum(
  'Batavia_to_WGS84_Indonesia_Sum',
  WGS_84,
  BESSEL_1841,
  -377,
  681,
  -50,
  0,
  0,
  0,
  0
)
addDatum(
  'Bermuda_1957_to_WGS84_Bermuda',
  WGS_84,
  CLARKE_1866,
  -73,
  213,
  296,
  0,
  0,
  0,
  0
)
addDatum(
  'Bogota_to_WGS84_Colombia',
  WGS_84,
  INTERNATIONAL_1924,
  307,
  304,
  -318,
  0,
  0,
  0,
  0
)
addDatum(
  'Bukit_Rimpah_to_WGS84_Indonesia_Bangka_Belitung',
  WGS_84,
  BESSEL_1841,
  -384,
  664,
  -48,
  0,
  0,
  0,
  0
)
addDatum(
  'Campo_Inchauspe_to_WGS84_Argentina',
  WGS_84,
  INTERNATIONAL_1924,
  -148,
  136,
  90,
  0,
  0,
  0,
  0
)
addDatum(
  'Cape_to_WGS84_South_Africa',
  WGS_84,
  CLARKE_1880_ARC,
  -136,
  -108,
  -292,
  0,
  0,
  0,
  0
)
addDatum(
  'Cape_to_WGS84_South_Africa1',
  WGS_84,
  CLARKE_1880_ARC,
  -134.73,
  -110.92,
  -292.66,
  0,
  0,
  0,
  0
)
addDatum(
  'Carthage_to_WGS84_Tunisia',
  WGS_84,
  CLARKE_1880_IGN,
  -263,
  6,
  431,
  0,
  0,
  0,
  0
)
addDatum(
  'Chua_to_WGS84_Paraguay',
  WGS_84,
  INTERNATIONAL_1924,
  -134,
  229,
  -29,
  0,
  0,
  0,
  0
)
addDatum(
  'Corrego_Alegre_to_WGS84_Brazil',
  WGS_84,
  INTERNATIONAL_1924,
  -206,
  172,
  -6,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_MEAN',
  WGS_84,
  INTERNATIONAL_1924,
  -87,
  -98,
  -121,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_MEAN2',
  WGS_84,
  INTERNATIONAL_1924,
  -87,
  -96,
  -120,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_MEAN_Middle_East',
  WGS_84,
  INTERNATIONAL_1924,
  -103,
  -106,
  -141,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Cyprus',
  WGS_84,
  INTERNATIONAL_1924,
  -104,
  -101,
  -140,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Egypt',
  WGS_84,
  INTERNATIONAL_1924,
  -130,
  -117,
  -151,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Ireland_UK',
  WGS_84,
  INTERNATIONAL_1924,
  -86,
  -96,
  -120,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Finland_Norway',
  WGS_84,
  INTERNATIONAL_1924,
  -87,
  -95,
  -120,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Greece',
  WGS_84,
  INTERNATIONAL_1924,
  -84,
  -95,
  -130,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Iran',
  WGS_84,
  INTERNATIONAL_1924,
  -117,
  -132,
  -164,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Sardinia',
  WGS_84,
  INTERNATIONAL_1924,
  -97,
  -103,
  -120,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Sicily',
  WGS_84,
  INTERNATIONAL_1924,
  -97,
  -88,
  -135,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Malta',
  WGS_84,
  INTERNATIONAL_1924,
  -107,
  -88,
  -149,
  0,
  0,
  0,
  0
)
addDatum(
  'ED50_to_WGS84_Portugal_Spain',
  WGS_84,
  INTERNATIONAL_1924,
  -84,
  -107,
  -120,
  0,
  0,
  0,
  0
)
addDatum(
  'ED87_to_WGS84_Offshore_North_sea',
  WGS_84,
  INTERNATIONAL_1924,
  -82.981,
  -99.719,
  -110.709,
  -0.5076 * MICRO,
  0.1503 * MICRO,
  0.3898 * MICRO,
  -0.3143
)
addDatum(
  'ED50_to_ED87_Offshore_Norway',
  WGS_84,
  INTERNATIONAL_1924,
  -1.51,
  -0.84,
  -3.5,
  -1.893 * MICRO,
  -0.687 * MICRO,
  -2.764 * MICRO,
  0.609
)
addDatum(
  'Egypt_1907_to_WGS84',
  WGS_84,
  HELMERT_1906,
  -130,
  110,
  -13,
  0,
  0,
  0,
  1
)
addDatum('ETRS89_to_WGS84_Europe', WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum('GDA94_to_WGS84_Australia', WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  'GD49_to_WGS84_New_Zealand',
  WGS_84,
  INTERNATIONAL_1924,
  84,
  -22,
  209,
  0,
  0,
  0,
  0
)
addDatum(
  'Hu_Tzu_Shan_to_WGS84_Taiwan',
  WGS_84,
  INTERNATIONAL_1924,
  -637,
  -549,
  -203,
  0,
  0,
  0,
  0
)
addDatum(
  'Indian_1954_to_WGS84_Thailand',
  WGS_84,
  EVEREST_1830_1937_ADJUSTMENT,
  217,
  823,
  299,
  0,
  0,
  0,
  0
)
addDatum(
  'Indian_1975_to_WGS84_Thailand',
  WGS_84,
  EVEREST_1830_1937_ADJUSTMENT,
  209,
  818,
  290,
  0,
  0,
  0,
  0
)
addDatum(
  'Kalianpur_to_WGS84_Bangladesh',
  WGS_84,
  EVEREST_1830_1975_DEFINITION,
  282,
  726,
  254,
  0,
  0,
  0,
  0
)
addDatum(
  'Kalianpur_to_WGS84_India_Nepal',
  WGS_84,
  EVEREST_1830_1975_DEFINITION,
  295,
  736,
  257,
  0,
  0,
  0,
  0
)
addDatum(
  'Kandawala_to_WGS84_Sri_Lanka',
  WGS_84,
  EVEREST_1830_1937_ADJUSTMENT,
  -97,
  787,
  86,
  0,
  0,
  0,
  0
)
addDatum(
  'Kertau_to_WGS84_Malaysia',
  WGS_84,
  EVEREST_1830_MODIFIED,
  -11,
  851,
  5,
  0,
  0,
  0,
  0
)
addDatum(
  'Leigon_to_WGS84_Ghana',
  WGS_84,
  CLARKE_1880_RGS,
  -130,
  29,
  364,
  0,
  0,
  0,
  0
)
addDatum(
  'Liberia_1964_to_WGS84_Liberia',
  WGS_84,
  CLARKE_1880_RGS,
  -90,
  40,
  88,
  0,
  0,
  0,
  0
)
addDatum(
  'Luzon_1911_to_WGS84_Philippines',
  WGS_84,
  CLARKE_1866,
  -133,
  -77,
  -51,
  0,
  0,
  0,
  0
)
addDatum(
  'Luzon_1911_to_WGS84_Philippines_Mindanao',
  WGS_84,
  CLARKE_1866,
  -133,
  -79,
  -72,
  0,
  0,
  0,
  0
)
addDatum(
  'Mporaloko_to_WGS84',
  WGS_84,
  CLARKE_1880_IGN,
  -74,
  -130,
  42,
  0,
  0,
  0,
  0
)
addDatum(
  'Mahe_1971_to_WGS84_Mahe_Island',
  WGS_84,
  CLARKE_1880_RGS,
  41,
  -220,
  -134,
  0,
  0,
  0,
  0
)
addDatum(
  'Massawa_to_WGS84_Ethiopia_Eritrea',
  WGS_84,
  BESSEL_1841,
  639,
  405,
  60,
  0,
  0,
  0,
  0
)
addDatum(
  'Merchich_to_WGS84_Morocco',
  WGS_84,
  CLARKE_1880_IGN,
  31,
  146,
  47,
  0,
  0,
  0,
  0
)
addDatum(
  'Minna_to_WGS84_Cameroon',
  WGS_84,
  CLARKE_1880_RGS,
  -81,
  -84,
  115,
  0,
  0,
  0,
  0
)
addDatum(
  'Minna_to_WGS84_Nigeria',
  WGS_84,
  CLARKE_1880_RGS,
  -92,
  -93,
  122,
  0,
  0,
  0,
  0
)
addDatum(
  'Monte_Mario_to_WGS84_Sardinia',
  WGS_84,
  INTERNATIONAL_1924,
  -225,
  -65,
  9,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_MEAN_Caraib',
  WGS_84,
  CLARKE_1866,
  -3,
  142,
  183,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_MEAN_Middle_America',
  WGS_84,
  CLARKE_1866,
  0,
  125,
  194,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_MEAN_Canada',
  WGS_84,
  CLARKE_1866,
  -10,
  158,
  187,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_MEAN_USA_CONUS',
  WGS_84,
  CLARKE_1866,
  -8,
  160,
  176,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_MEAN_USA_East',
  WGS_84,
  CLARKE_1866,
  -9,
  161,
  179,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_MEAN_USA_West',
  WGS_84,
  CLARKE_1866,
  -8,
  159,
  175,
  0,
  0,
  0,
  0
)
addDatum('NAD27_to_WGS84_Alaska', WGS_84, CLARKE_1866, -5, 135, 172, 0, 0, 0, 0)
addDatum(
  'NAD27_to_WGS84_Bahamas',
  WGS_84,
  CLARKE_1866,
  -4,
  154,
  178,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Bahamas_San_Salvador_Island',
  WGS_84,
  CLARKE_1866,
  1,
  140,
  165,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Canada_Alberta_British_Columbia',
  WGS_84,
  CLARKE_1866,
  -7,
  162,
  188,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Canada_Manitoba_Ontario',
  WGS_84,
  CLARKE_1866,
  -9,
  157,
  184,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Canada_NewBrunswick_Newfoundland_NovaScotia_Quebec',
  WGS_84,
  CLARKE_1866,
  -22,
  160,
  190,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Canada_Northwest_Territories_Saskatchewan',
  WGS_84,
  CLARKE_1866,
  4,
  159,
  188,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Canada_Yukon',
  WGS_84,
  CLARKE_1866,
  -7,
  139,
  181,
  0,
  0,
  0,
  0
)
addDatum('NAD27_to_WGS84_Panama', WGS_84, CLARKE_1866, 0, 125, 201, 0, 0, 0, 0)
addDatum('NAD27_to_WGS84_Cuba', WGS_84, CLARKE_1866, -9, 152, 178, 0, 0, 0, 0)
addDatum(
  'NAD27_to_WGS84_Greenland_Hayes',
  WGS_84,
  CLARKE_1866,
  11,
  114,
  195,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Mexico',
  WGS_84,
  CLARKE_1866,
  -12,
  130,
  190,
  0,
  0,
  0,
  0
)
addDatum('NAD83_to_WGS84', WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  'Nahrwan_1967_to_WGS84_Oman',
  WGS_84,
  CLARKE_1880_RGS,
  -247,
  -148,
  369,
  0,
  0,
  0,
  0
)
addDatum(
  'Nahrwan_1967_to_WGS84_Saudi_Arabia',
  WGS_84,
  CLARKE_1880_RGS,
  -243,
  -192,
  477,
  0,
  0,
  0,
  0
)
addDatum(
  'Nahrwan_1967_to_WGS84_UAE',
  WGS_84,
  CLARKE_1880_RGS,
  -249,
  -156,
  381,
  0,
  0,
  0,
  0
)
addDatum(
  'Naparima_1972_to_WGS84_Trinidad_Tobago',
  WGS_84,
  INTERNATIONAL_1924,
  -10,
  375,
  165,
  0,
  0,
  0,
  0
)
addDatum(
  'NTF_to_WGS84_France',
  WGS_84,
  CLARKE_1880_IGN,
  -168,
  -60,
  320,
  0,
  0,
  0,
  0
)
addDatum(
  'NTF_Paris_to_NTF_France',
  WGS_84,
  CLARKE_1880_IGN,
  -2.5969213,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum('OSGB_1936_to_WGS84_MEAN', WGS_84, AIRY, 375, -111, 431, 0, 0, 0, 0)
addDatum('OSGB_1936_to_WGS84_England', WGS_84, AIRY, 371, -112, 434, 0, 0, 0, 0)
addDatum(
  'OSGB_1936_to_WGS84_England_Wales_IsleofMan',
  WGS_84,
  AIRY,
  371,
  -111,
  434,
  0,
  0,
  0,
  0
)
addDatum(
  'OSGB_1936_to_WGS84_Scotland',
  WGS_84,
  AIRY,
  384,
  -111,
  425,
  0,
  0,
  0,
  0
)
addDatum('OSGB_1936_to_WGS84_Wales', WGS_84, AIRY, 370, -108, 434, 0, 0, 0, 0)
addDatum(
  'Pointe_Noire_to_WGS84_Congo',
  WGS_84,
  CLARKE_1880_IGN,
  -148,
  51,
  -291,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_MEAN',
  WGS_84,
  INTERNATIONAL_1924,
  -288,
  175,
  -376,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Bolivia',
  WGS_84,
  INTERNATIONAL_1924,
  -270,
  188,
  -388,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Chile_N',
  WGS_84,
  INTERNATIONAL_1924,
  -270,
  183,
  -390,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Chile_S',
  WGS_84,
  INTERNATIONAL_1924,
  -305,
  243,
  -442,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Colombia',
  WGS_84,
  INTERNATIONAL_1924,
  -282,
  169,
  -371,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Ecuador',
  WGS_84,
  INTERNATIONAL_1924,
  -278,
  171,
  -367,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Guyana',
  WGS_84,
  INTERNATIONAL_1924,
  -298,
  159,
  -369,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Peru',
  WGS_84,
  INTERNATIONAL_1924,
  -279,
  175,
  -379,
  0,
  0,
  0,
  0
)
addDatum(
  'PSAD56_to_WGS84_Venezuela',
  WGS_84,
  INTERNATIONAL_1924,
  -295,
  173,
  -371,
  0,
  0,
  0,
  0
)
addDatum(
  'Qatar_to_WGS84_Qatar',
  WGS_84,
  INTERNATIONAL_1924,
  -128,
  -283,
  22,
  0,
  0,
  0,
  0
)
addDatum(
  'Qornoq_to_WGS84_Greenland_S',
  WGS_84,
  INTERNATIONAL_1924,
  164,
  138,
  -189,
  0,
  0,
  0,
  0
)
addDatum('SAD69_to_WGS84_MEAN', WGS_84, GRS_1967, -57, 1, -41, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Argentina', WGS_84, GRS_1967, -62, -1, -37, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Bolivia', WGS_84, GRS_1967, -61, 2, -48, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Brazil', WGS_84, GRS_1967, -60, -2, -41, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Chile', WGS_84, GRS_1967, -75, -1, -44, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Colombia', WGS_84, GRS_1967, -44, 6, -36, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Ecuador', WGS_84, GRS_1967, -48, 3, -44, 0, 0, 0, 0)
addDatum(
  'SAD69_to_WGS84_Ecuador_Baltra_Galapagos',
  WGS_84,
  GRS_1967,
  -47,
  26,
  -42,
  0,
  0,
  0,
  0
)
addDatum('SAD69_to_WGS84_Guyana', WGS_84, GRS_1967, -53, 3, -47, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Paraguay', WGS_84, GRS_1967, -61, 2, -33, 0, 0, 0, 0)
addDatum('SAD69_to_WGS84_Peru', WGS_84, GRS_1967, -58, 0, -44, 0, 0, 0, 0)
addDatum(
  'SAD69_to_WGS84_Trinidad_Tobago',
  WGS_84,
  GRS_1967,
  -45,
  12,
  -33,
  0,
  0,
  0,
  0
)
addDatum('SAD69_to_WGS84_Venezuela', WGS_84, GRS_1967, -45, 8, -33, 0, 0, 0, 0)
addDatum(
  'Sapper_Hill_1943_to_WGS84_Falkland',
  WGS_84,
  INTERNATIONAL_1924,
  -355,
  21,
  72,
  0,
  0,
  0,
  0
)
addDatum(
  'Schwarzeck_to_WGS84_Namibia',
  WGS_84,
  BESSEL_1841_NAMBIA,
  616,
  97,
  -251,
  0,
  0,
  0,
  0
)
addDatum(
  'Tananarive_to_WGS84_Madagascar',
  WGS_84,
  INTERNATIONAL_1924,
  -189,
  -242,
  -91,
  0,
  0,
  0,
  0
)
addDatum(
  'Timbalai_1948_to_WGS84_Brunei_Malaysia',
  WGS_84,
  EVEREST_1830_1967_DEFINITION,
  -679,
  669,
  -48,
  0,
  0,
  0,
  0
)
addDatum(
  'TM65_to_WGS84_Ireland',
  WGS_84,
  AIRY_MODIFIED_1849,
  506,
  -122,
  611,
  0,
  0,
  0,
  0
)
addDatum('Tokyo_to_WGS84_MEAN', WGS_84, BESSEL_1841, -148, 507, 685, 0, 0, 0, 0)
addDatum(
  'Tokyo_to_WGS84_Japan',
  WGS_84,
  BESSEL_1841,
  -148,
  507,
  685,
  0,
  0,
  0,
  0
)
addDatum(
  'Tokyo_to_WGS84_South_Korea',
  WGS_84,
  BESSEL_1841,
  -146,
  507,
  687,
  0,
  0,
  0,
  0
)
addDatum(
  'Tokyo_to_WGS84_Okinawa',
  WGS_84,
  BESSEL_1841,
  -158,
  507,
  676,
  0,
  0,
  0,
  0
)
addDatum(
  'Yacare_to_WGS84_Uruguay',
  WGS_84,
  INTERNATIONAL_1924,
  -155,
  171,
  37,
  0,
  0,
  0,
  0
)
addDatum(
  'Zanderij_to_WGS84_Suriname',
  WGS_84,
  INTERNATIONAL_1924,
  -265,
  120,
  -358,
  0,
  0,
  0,
  0
)
addDatum(
  'AGD84_to_WGS84_Australia2',
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -116,
  -50.47,
  141.69,
  -0.23,
  -0.39,
  -0.344,
  0.0983
)
addDatum(
  'WGS_72_to_WGS84_World',
  WGS_84,
  NWL_10D,
  0,
  0,
  4.5,
  0,
  0,
  0.554,
  0.2263
)
addDatum(
  'WGS_72_to_WGS84_2World',
  WGS_84,
  NWL_10D,
  0,
  0,
  4.5,
  0,
  0,
  0.554,
  0.219
)
addDatum(
  'WGS_72BE_to_WGS_72_World',
  WGS_84,
  NWL_10D,
  0,
  0,
  -2.6,
  0,
  0,
  0.26,
  -0.6063
)
addDatum(
  'WGS_72BE_to_WGS84_World',
  WGS_84,
  NWL_10D,
  0,
  0,
  1.9,
  0,
  0,
  0.814,
  -0.38
)
addDatum(
  'NAD27_to_NAD83_USA_Lower_States',
  WGS_84,
  CLARKE_1866,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum('DUMMY_2', WGS_84, CLARKE_1866, 0, 0, 0, 0, 0, 0, 0)
addDatum('NAD27_to_NAD83_Alaska', WGS_84, CLARKE_1866, 0, 0, 0, 0, 0, 0, 0)
addDatum('DUMMY_1', WGS_84, CLARKE_1866, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  'ED50_to_WGS84_Tunisia',
  WGS_84,
  INTERNATIONAL_1924,
  -112,
  -77,
  -145,
  0,
  0,
  0,
  0
)
addDatum(
  'Herat_North_to_WGS84_Afghanistan',
  WGS_84,
  INTERNATIONAL_1924,
  -333,
  -222,
  114,
  0,
  0,
  0,
  0
)
addDatum(
  'Kalianpur_to_WGS84_Pakistan',
  WGS_84,
  EVEREST_1830_1975_DEFINITION,
  283,
  682,
  231,
  0,
  0,
  0,
  0
)
addDatum(
  'ID74_to_WGS84_Indonesia',
  WGS_84,
  INDONESIAN_NATIONAL_SPHEROID,
  -24,
  -15,
  5,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Alaska_Aleutian_E180',
  WGS_84,
  CLARKE_1866,
  -2,
  152,
  149,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD27_to_WGS84_Alaska_Aleutian_W180',
  WGS_84,
  CLARKE_1866,
  2,
  204,
  105,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD83_to_WGS84_Alaska_Aleutian',
  WGS_84,
  GRS_1980,
  -2,
  0,
  4,
  0,
  0,
  0,
  0
)
addDatum('NAD83_to_WGS84_Hawaii', WGS_84, GRS_1980, 1, 1, -1, 0, 0, 0, 0)
addDatum(
  'Nord_Sahara_1959_to_WGS84_Algeria',
  WGS_84,
  CLARKE_1880_IGN,
  -186,
  -93,
  310,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Russia',
  WGS_84,
  KRASSOVSKY,
  28,
  -130,
  -95,
  0,
  0,
  0,
  0
)
addDatum(
  'Voirol_Unifie_to_WGS84_Algeria',
  WGS_84,
  CLARKE_1880_IGN,
  -123,
  -206,
  219,
  0,
  0,
  0,
  0
)
addDatum(
  'Fahud_to_WGS84_Oman',
  WGS_84,
  CLARKE_1880_RGS,
  -346,
  -1,
  224,
  0,
  0,
  0,
  0
)
addDatum(
  'Bern_1898_Bernto_Bern_1898',
  WGS_84,
  BESSEL_1841,
  -7.26225,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Bogota_Bogota_to_Bogota_Colombia',
  WGS_84,
  INTERNATIONAL_1924,
  74.04513,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Lisbon_Lisbon_to_Lisbon_Portugal_onshore',
  WGS_84,
  INTERNATIONAL_1924,
  9.0754862,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Makassar_Jakarta_to_Makassar_Indonesia_south_west_Sulawesi',
  WGS_84,
  BESSEL_1841,
  -106.482779,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'MGI_Ferro_to_MGI_Austria',
  WGS_84,
  BESSEL_1841,
  17.4,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Monte_Mario_Rome_to_Monte_Mario_Italy',
  WGS_84,
  INTERNATIONAL_1924,
  -12.27084,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Padang_Jakarta_to_Padang_Indonesia_Sumatra',
  WGS_84,
  BESSEL_1841,
  -106.482779,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Belge_1950_Brussels_to_Belge_1950_Belgium',
  WGS_84,
  INTERNATIONAL_1924,
  -4.220471,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Tananarive_Paris_to_Tananarive_Madagascar',
  WGS_84,
  INTERNATIONAL_1924,
  -2.5969213,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Voirol_1875_Paris_to_Voirol_1875_Algeria',
  WGS_84,
  CLARKE_1880_IGN,
  -2.5969213,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Voirol_Unifie_Paris_to_Voirol_Unifie_Algeria',
  WGS_84,
  CLARKE_1880_IGN,
  -2.5969213,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Batavia_Jakarta_to_Batavia_Indonesia',
  WGS_84,
  BESSEL_1841,
  -106.482779,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'RT38_Stockholm_to_RT38_Sweden',
  WGS_84,
  BESSEL_1841,
  -18.03298,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Greek_Athens_to_Greek_Greece',
  WGS_84,
  BESSEL_1841,
  -23.4258815,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Schwarzeck_to_WGS84_Namibia2',
  WGS_84,
  BESSEL_1841_NAMBIA,
  615.64,
  102.08,
  -255.81,
  0,
  0,
  0,
  0
)
addDatum(
  'GGRS87_to_WGS84_Greece',
  WGS_84,
  GRS_1980,
  -199.87,
  74.79,
  246.62,
  0,
  0,
  0,
  0
)
addDatum(
  'HD72_to_ETRS89_ungary',
  WGS_84,
  GRS_1967,
  -56,
  75.77,
  15.31,
  -0.37,
  -0.2,
  -0.21,
  -1.01
)
addDatum(
  'Pulkovo_1942_to_LKS94_ETRS89_Lithuania',
  WGS_84,
  KRASSOVSKY,
  -40.595,
  -18.55,
  -69.339,
  -2.508,
  -1.832,
  2.611,
  -4.299
)
addDatum(
  'ED50_to_WGS84_France',
  WGS_84,
  INTERNATIONAL_1924,
  -84,
  -97,
  -117,
  0,
  0,
  0,
  0
)
addDatum(
  'NTF_to_ED50_France',
  WGS_84,
  CLARKE_1880_IGN,
  -84,
  37,
  437,
  0,
  0,
  0,
  0
)
addDatum(
  'NTF_to_WGS72_France',
  WGS_84,
  CLARKE_1880_IGN,
  -168,
  -72,
  314,
  0,
  0,
  0,
  0
)
addDatum(
  'AGD66_to_GDA94_Australia',
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -127.8,
  -52.3,
  152.9,
  0,
  0,
  0,
  0
)
addDatum(
  'AGD84_to_GDA94_Australia',
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -128.5,
  -53,
  153.4,
  0,
  0,
  0,
  0
)
addDatum(
  'AGD84_to_GDA94_Australia2',
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -117.763,
  -51.51,
  139.061,
  -0.292,
  -0.443,
  -0.277,
  -0.191
)
addDatum(
  'RT90_to_WGS84_Sweden',
  WGS_84,
  BESSEL_1841,
  419.3836,
  99.3335,
  591.3451,
  -0.850389,
  -1.817277,
  7.862238,
  -0.99496
)
addDatum(
  'Samboja_to_WGS84_',
  WGS_84,
  BESSEL_1841,
  -404.78,
  -685.68,
  -45.47,
  0,
  0,
  0,
  0
)
addDatum(
  'LKS94_ETRS89_to_WGS84_Lithuania',
  WGS_84,
  GRS_1980,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1960_to_WGS84_Kenya',
  WGS_84,
  CLARKE_1880_RGS,
  -157,
  -2,
  -299,
  0,
  0,
  0,
  0
)
addDatum(
  'Arc_1960_to_WGS84_Tanzania',
  WGS_84,
  CLARKE_1880_RGS,
  -175,
  -23,
  -303,
  0,
  0,
  0,
  0
)
addDatum(
  'Segora_to_WGS84_Indonesia',
  WGS_84,
  BESSEL_1841,
  -403,
  684,
  41,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Hungary',
  WGS_84,
  KRASSOVSKY,
  28,
  -121,
  -77,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Poland',
  WGS_84,
  KRASSOVSKY,
  23,
  -124,
  -82,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Czech_Republic',
  WGS_84,
  KRASSOVSKY,
  26,
  -121,
  -78,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Latvia',
  WGS_84,
  KRASSOVSKY,
  24,
  -124,
  -82,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Kazakstan',
  WGS_84,
  KRASSOVSKY,
  15,
  -130,
  -84,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Albania',
  WGS_84,
  KRASSOVSKY,
  24,
  -130,
  -92,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942_to_WGS84_Romania',
  WGS_84,
  KRASSOVSKY,
  28,
  -121,
  -77,
  0,
  0,
  0,
  0
)
addDatum(
  'Voirol_1875_to_WGS84_Algeria',
  WGS_84,
  CLARKE_1880_IGN,
  -73,
  -247,
  227,
  0,
  0,
  0,
  0
)
addDatum(
  'Naparima_1972_to_WGS84',
  WGS_84,
  INTERNATIONAL_1924,
  -0.465,
  372.095,
  171.736,
  0,
  0,
  0,
  0
)
addDatum(
  'Trinidad_1903_to_WGS84',
  WGS_84,
  CLARKE_1858,
  -61.702,
  284.488,
  472.052,
  0,
  0,
  0,
  0
)
addDatum(
  'Tete_to_Moznet_Mozambique',
  WGS_84,
  CLARKE_1866,
  -115.064,
  -87.39,
  -101.716,
  0.058,
  -4.001,
  2.062,
  9.366
)
addDatum(
  'Tete_to_Moznet_MozambiqueS',
  WGS_84,
  CLARKE_1866,
  -82.875,
  -57.097,
  -156.768,
  2.158,
  -1.524,
  0.982,
  -0.359
)
addDatum(
  'Tete_to_Moznet_MozambiqueS2',
  WGS_84,
  CLARKE_1866,
  -138.527,
  -91.999,
  -114.591,
  0.14,
  -3.363,
  2.217,
  11.748
)
addDatum(
  'Tete_to_Moznet_MozambiqueN',
  WGS_84,
  CLARKE_1866,
  -73.472,
  -51.66,
  -112.482,
  -0.953,
  -4.6,
  2.368,
  0.586
)
addDatum(
  'Tete_to_Moznet_MozambiqueN2',
  WGS_84,
  CLARKE_1866,
  219.315,
  168.975,
  -166.145,
  -0.198,
  -5.926,
  2.356,
  -57.104
)
addDatum('Moznet_to_WGS84_Mozambique', WGS_84, WGS_1984, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  'Pulkovo_1942_to_WGS84_Kazakstan_Caspian',
  WGS_84,
  KRASSOVSKY,
  43.822,
  -108.842,
  -119.585,
  1.455,
  -0.761,
  0.737,
  0.549
)
addDatum(
  'Indian_1975_to_WGS84_Thailand2',
  WGS_84,
  EVEREST_1830_1937_ADJUSTMENT,
  210,
  814,
  289,
  0,
  0,
  0,
  0
)
addDatum(
  'Tokyo_to_WGS84_South_Korea2',
  WGS_84,
  BESSEL_1841,
  -147,
  506,
  687,
  0,
  0,
  0,
  0
)
addDatum('MGI_to_WGS84_MEAN', WGS_84, BESSEL_1841, 682, -203, 480, 0, 0, 0, 0)
addDatum(
  'Naparima_1972_to_WGS84_Trinidad_Tobago2',
  WGS_84,
  INTERNATIONAL_1924,
  -2,
  374,
  172,
  0,
  0,
  0,
  0
)
addDatum(
  'NAD83_to_WGS84_USA',
  WGS_84,
  GRS_1980,
  -0.9738,
  1.9453,
  0.5486,
  -1.3357e-7,
  -4.872e-8,
  -5.507e-8,
  0
)
addDatum(
  'DHDN_to_ETRS89_Germany',
  WGS_84,
  BESSEL_1841,
  582,
  105,
  414,
  -1.04,
  -0.35,
  3.08,
  8.3
)
addDatum(
  'Pulkovo_1942_to_ETRS89_Germany',
  WGS_84,
  KRASSOVSKY,
  24,
  -123,
  -94,
  -0.02,
  0.25,
  0.13,
  1.1
)
addDatum(
  'ED50_to_WGS84_UKCS',
  WGS_84,
  INTERNATIONAL_1924,
  -89.5,
  -93.8,
  -123.1,
  0,
  0,
  -0.156,
  1.2
)
addDatum('NAD27_to_NAD83_Canada', WGS_84, CLARKE_1866, 0, 0, 0, 0, 0, 0, 0)
addDatum('NAD27_to_NAD83_Canada2', WGS_84, CLARKE_1866, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  'OSGB_1936_to_WGS84_Petroleum_UKCS',
  WGS_84,
  AIRY,
  446.448,
  -125.157,
  542.06,
  0.15,
  0.247,
  0.842,
  -20.489
)
addDatum(
  'OSGB_1936_to_ED50_UKOOA',
  WGS_84,
  AIRY,
  535.948,
  -31.357,
  665.16,
  0.15,
  0.247,
  0.998,
  -21.689
)
addDatum(
  'Manoca_to_WGS84_Cameroon',
  WGS_84,
  CLARKE_1880_RGS,
  -70.9,
  -151.8,
  -41.4,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS_72BE_Angola',
  WGS_84,
  CLARKE_1880_RGS,
  -37.2,
  -370.6,
  -228.5,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl5',
  WGS_84,
  CLARKE_1880_RGS,
  -42.01,
  -332.21,
  -229.75,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl2',
  WGS_84,
  CLARKE_1880_RGS,
  -40,
  -354,
  -224,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl1_16',
  WGS_84,
  CLARKE_1880_RGS,
  -37.2,
  -370.6,
  -224,
  0,
  0,
  0.554,
  0.219
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl7_8',
  WGS_84,
  CLARKE_1880_RGS,
  -41.8,
  -342.2,
  -228.2,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl3',
  WGS_84,
  CLARKE_1880_RGS,
  -55.5,
  -348,
  -229.2,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl7',
  WGS_84,
  CLARKE_1880_RGS,
  -43,
  -337,
  -233,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl3_7_15_17',
  WGS_84,
  CLARKE_1880_RGS,
  -48,
  -345,
  -231,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl22',
  WGS_84,
  CLARKE_1880_RGS,
  -48.6,
  -345.1,
  -230.8,
  0,
  0,
  0,
  0
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl1_16II',
  WGS_84,
  CLARKE_1880_RGS,
  -41.057,
  -374.564,
  -226.287,
  0,
  0,
  0.554,
  0.219
)
addDatum(
  'Camacupa_to_WGS84_Angola_bl2_3_17',
  WGS_84,
  CLARKE_1880_RGS,
  -50.9,
  -347.6,
  -231,
  0,
  0,
  0,
  0
)
addDatum(
  'Malongo_1987_to_Mhast_Angola_Cabinda',
  WGS_84,
  INTERNATIONAL_1924,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Mhast_to_WGS84_Angola_Cabinda',
  WGS_84,
  INTERNATIONAL_1924,
  -252.95,
  -4.11,
  -96.38,
  0,
  0,
  0,
  0
)
addDatum(
  'Malongo_1987_to_WGS84_Angola_Cabinda',
  WGS_84,
  INTERNATIONAL_1924,
  -252.95,
  -4.11,
  -96.38,
  0,
  0,
  0,
  0
)
addDatum(
  'WGS72',
  WGS_84,
  WGS_1972,
  0,
  0,
  4.5,
  0,
  0,
  0.554,
  0.045171992568114105
)
addDatum('RGF93_to_WGS84_France', WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum(ADINDAN, WGS_84, CLARKE_1880, -166, -15, 204, 0, 0, 0, 0)
addDatum(AFG, WGS_84, KRASSOVSKY, -43, -163, 45, 0, 0, 0, 0)
addDatum(AIN_EL_ABD_1970, WGS_84, INTERNATIONAL, -150, -250, -1, 0, 0, 0, 0)
addDatum(ALASKA_NAD_1927, WGS_84, CLARKE_1866, -5, 135, 172, 0, 0, 0, 0)
addDatum(ALASKA_CANADA_NAD_1927, WGS_84, CLARKE_1866, -9, 151, 185, 0, 0, 0, 0)
addDatum(EUROPEAN_1950, WGS_84, INTERNATIONAL, -87, -96, -120, 0, 0, 0, 0)
addDatum(EUROPEAN_1950_MEAN, WGS_84, INTERNATIONAL, -87, -98, -121, 0, 0, 0, 0)
addDatum(EUROPEAN_1979_MEAN, WGS_84, INTERNATIONAL, -86, -98, -119, 0, 0, 0, 0)
addDatum(NORTH_AMERICA_1983, WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum(NORTH_AMERICA_1927_MEAN, WGS_84, CLARKE_1866, -8, 160, 176, 0, 0, 0, 0)
addDatum(NORTH_AMERICA_1983_HARN, WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  AUSTRALIAN_GEODETIC_DATUM_1966,
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -117.808,
  -51.536,
  137.784,
  0.303,
  0.446,
  0.234,
  -0.29
)
addDatum('Arc_1960', WGS_84, CLARKE_1880_RGS, -160, -6, -302, 0, 0, 0, 0)
addDatum('Beijing_1954', WGS_84, KRASSOVSKY, 15.8, -154.4, -82.3, 0, 0, 0, 0)
addDatum(
  'Corrego_Alegre_1970-72',
  WGS_84,
  KRASSOVSKY,
  15.8,
  -154.4,
  -82.3,
  0,
  0,
  0,
  0
)
addDatum(
  'Amersfoort',
  WGS_84,
  BESSEL_1841,
  565.4171,
  50.3319,
  465.5524,
  -0.3989573882431342,
  0.34398781737828293,
  -1.8774016399804483,
  4.0725
)
addDatum('Arc_1960', WGS_84, CLARKE_1880_RGS, -160, -6, -302, 0, 0, 0, 0)
addDatum(
  'Australian_Geodetic_Datum_1966',
  WGS_84,
  AUSTRALIAN_NATIONAL,
  -117.808,
  -51.536,
  137.784,
  0.303,
  0.446,
  0.234,
  -0.29
)
addDatum('Beijing_1954', WGS_84, KRASSOVSKY, 15.8, -154.4, -82.3, 0, 0, 0, 0)
addDatum('Costa_Rica_2005', WGS_84, WGS_84, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  'Datum_73',
  WGS_84,
  INTERNATIONAL_1924,
  -239.749,
  88.181,
  30.488,
  0.263,
  0.082,
  1.211,
  2.229
)
addDatum(
  'Deutsches_Hauptdreiecksnetz',
  WGS_84,
  BESSEL_1841,
  598.1,
  73.7,
  418.2,
  0.202,
  0.045,
  -2.455,
  6.7
)
addDatum(
  'DEUTSCHES_HAUPTDREIECKSNETZ',
  WGS_84,
  BESSEL_1841,
  598.1,
  73.7,
  418.2,
  0.202,
  0.045,
  -2.455,
  6.7
)
addDatum(
  'European_Datum_1950',
  WGS_84,
  INTERNATIONAL_1924,
  -87,
  -98,
  -121,
  0,
  0,
  0,
  0
)
addDatum(
  'European_Terrestrial_Reference_System_1989',
  WGS_84,
  GRS_1980,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Geocentric_Datum_of_Australia_1994',
  WGS_84,
  GRS_1980,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum('Guam_1963', WGS_84, CLARKE_1866, -100, -248, 259, 0, 0, 0, 0)
addDatum(
  'Gulshan_303',
  WGS_84,
  EVEREST_1830_1937_ADJUSTMENT,
  283.7,
  735.9,
  261.1,
  0,
  0,
  0,
  0
)
addDatum(
  'Indian_1954',
  WGS_84,
  EVEREST_1830_1937_ADJUSTMENT,
  217,
  823,
  299,
  0,
  0,
  0,
  0
)
addDatum(
  'Kandawala',
  WGS_84,
  EVEREST_1830_1937_ADJUSTMENT,
  -97,
  787,
  86,
  0,
  0,
  0,
  0
)
addDatum('Kuwait_Utility', WGS_84, GRS_1980, -20.8, 11.3, 2.4, 0, 0, 0, 0)
addDatum('Mauritania_1999', WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum('Merchich', WGS_84, CLARKE_1880_IGN, 31, 146, 47, 0, 0, 0, 0)
addDatum(
  'MONTE_MARIO',
  WGS_84,
  INTERNATIONAL_1924,
  -104.1,
  -49.1,
  -9.9,
  0.971,
  -2.917,
  0.714,
  -11.68
)
addDatum(
  'NAD83_Canadian_Spatial_Reference_System',
  WGS_84,
  GRS_1980,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum('Pulkovo_1942', WGS_84, KRASSOVSKY, 25, -141, -78.5, 0, 0.35, 0.736, 0)
addDatum('South_American_Datum_1969', WGS_84, GRS_1967, -57, 1, -41, 0, 0, 0, 0)
addDatum('Tokyo', WGS_84, BESSEL_1841, -146.414, 507.337, 680.507, 0, 0, 0, 0)
addDatum(
  'Vietnam_2000',
  WGS_84,
  WGS_84,
  -192.873,
  -39.382,
  -111.202,
  -0.00205,
  -5e-4,
  0.00335,
  0.0188
)
addDatum(
  'OSGB_1936',
  WGS_84,
  AIRY,
  446.448,
  -125.157,
  542.06,
  0.15,
  0.247,
  0.842,
  -20.489
)
addDatum(
  'South_American_Datum_1969',
  WGS_84,
  GRS_1967_MODIFIED,
  -57,
  1,
  -41,
  0,
  0,
  0,
  0
)
addDatum(
  'Centre_Spatial_Guyanais_1967',
  WGS_84,
  INTERNATIONAL_1924,
  -193.066,
  236.993,
  105.447,
  0.4814,
  -0.8074,
  0.1276,
  1.5649
)
addDatum(
  'Kartastokoordinaattijarjestelma_(1966)',
  WGS_84,
  INTERNATIONAL_1924,
  -96.062,
  -82.428,
  -121.753,
  4.801,
  0.345,
  -1.376,
  1.496
)
addDatum(
  'Militar-Geographische_Institut',
  WGS_84,
  BESSEL_1841,
  577.326,
  90.129,
  463.919,
  5.137,
  1.474,
  5.297,
  2.4232
)
addDatum(
  'NAD83 (High Accuracy Reference Network)',
  WGS_84,
  GRS_1980,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Nouvelle_Triangulation_Francaise',
  WGS_84,
  CLARKE_1880_IGN,
  -168,
  -60,
  320,
  0,
  0,
  0,
  0
)
addDatum(
  'Pulkovo_1942(58)',
  WGS_84,
  KRASSOVSKY,
  33.4,
  -146.6,
  -76.3,
  -0.359,
  -0.053,
  0.844,
  -0.84
)
addDatum(
  'Reseau_Geodesique_Francais_1993',
  WGS_84,
  GRS_1980,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum(
  'Sistema_de_Referencia_Geocentrico_para_las_AmericaS_2000',
  WGS_84,
  GRS_1980,
  0,
  0,
  0,
  0,
  0,
  0,
  0
)
addDatum('Taiwan_Datum_1997', WGS_84, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatum(
  'Vietnam_2000',
  WGS_84,
  WGS_84,
  -192.873,
  -39.382,
  -111.202,
  -0.00205,
  -5e-4,
  0.00335,
  0.0188
)
addDatumAlias('ALASKA_NAD_1927', [
  'NAD1927_ALASKA',
  'NAD_1927_ALASKA',
  'NAD27_ALASKA',
  'NAD_27_ALASKA',
])
addDatumAlias('ALASKA_CANADA_NAD_1927', [
  'NAD1927_CANADA',
  'NAD_1927_CANADA',
  'NAD27_CANADA',
  'NAD_27_CANADA',
])
addDatumAlias('EUROPEAN_1950_MEAN', [
  'ED1950',
  'ED_1950',
  'ED-1950',
  'ED50',
  'ED_50',
  'ED-50',
])
addDatumAlias('NORTH_AMERICA_1983', [
  'NAD1983',
  'NAD_1983',
  'NAD-1983',
  'NAD83',
  'NAD_83',
  'NAD-83',
  'NORTH_AMERICAN_1983',
  'NORTH_AMERICAN_DATUM_1983',
  'NAD_1983_CONTINENTAL_US',
  'NAD_83_CONTINENTAL_US',
])
addDatumAlias('NORTH_AMERICA_1927_MEAN', [
  'NAD1927',
  'NAD_1927',
  'NAD-1927',
  'NAD27',
  'NAD_27',
  'NAD-27',
  'NORTH_AMERICAN_1927',
  'NORTH_AMERICAN_DATUM_1927',
  'NAD_1927_CONTINENTAL_US',
  'NAD_27_CONTINENTAL_US',
])
addDatumAlias('WGS72', ['WGS1972', 'WGS_1972', 'WGS-1972', 'WGS_72', 'WGS-72'])
addDatumAlias('Centre_Spatial_Guyanais_1967', ['Guyane_Francaise'])
addDatumAlias('Kartastokoordinaattijarjestelma_(1966)', ['KKJ'])
addDatumAlias('Militar-Geographische_Institut', ['MGI'])
addDatumAlias('NAD83 (High Accuracy Reference Network)', [
  'NAD83_High_Accuracy_Reference_Network',
])
addDatumAlias('Nouvelle_Triangulation_Francaise', [
  'NTF',
  'Nouvelle_Triangulation_Francaise_(Paris)',
])
addDatumAlias('Pulkovo_1942(58)', ['Pulkovo_1942_Adj_1958'])
addDatumAlias('Reseau_Geodesique_Francais_1993', ['RGF93'])
addDatumAlias('Sistema_de_Referencia_Geocentrico_para_las_AmericaS_2000', [
  'SIRGAS_2000',
  'SIRGAS2000',
])
addDatumAlias('European_Terrestrial_Reference_System_1989', [
  'European Terrestrial Reference Frame 1989',
  'ETRF_1989',
  'ETRS_1989',
  'ETRF89',
  'ETRS89',
])
addDatumAlias('Geocentric Datum of Australia 1994', ['GDA_1994', 'GDA94'])
addDatumAlias('Corrego_Alegre_1970-72', ['Corrego_Alegre'])
addDatumAlias('Taiwan_Datum_1997', ['TWD97'])
addDatumAlias(NORTH_AMERICA_1983_HARN, ['North_American_1983_HARN'])
addDatumAlias(WGS_84, [
  'WGS1984',
  'WGS_1984',
  'WGS-1984',
  'WGS84',
  'WGS-84',
  'World Geodetic System 1984',
])
addDatumAlias('Vietnam_2000', ['VN-2000'])
addDatum(
  'New_Zealand_1949',
  WGS_1984,
  INTERNATIONAL_1924,
  59.47,
  -5.04,
  187.44,
  0.47,
  -0.1,
  1.024,
  -4.5993
)
addDatum('SWEREF99', WGS_1984, GRS_1980, 0, 0, 0, 0, 0, 0, 0)
addDatumAlias('SWEREF99', ['SWEREF_99'])
addDatum(
  'Mhast',
  WGS_1984,
  INTERNATIONAL_1924,
  -252.95,
  -4.11,
  -96.38,
  0,
  0,
  0,
  0
)
