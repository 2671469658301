import { PolarStereographic, Pole } from '../projection/PolarStereographic.js'
import { GeodeticDatum } from '../geodesy/GeodeticDatum.js'
import { GridReference } from './GridReference.js'
export class UPSGrid extends GridReference {
  constructor(e, o, t) {
    super({
      name: 'UPS grid',
      geodeticDatum: o || new GeodeticDatum(),
      projection: new PolarStereographic(e ? Pole.NORTH_POLE : Pole.SOUTH_POLE),
      falseEasting: 2e6,
      falseNorthing: 2e6,
      scale: 0.994,
      unitOfMeasure: 1,
      rotation: 0,
      axisInformation: t,
    })
  }
  get projection() {
    return super.projection
  }
  isNorthPole() {
    return this.projection.getPole() === Pole.NORTH_POLE
  }
  setNorthPole(e) {
    this.projection.setPole(e ? Pole.NORTH_POLE : Pole.SOUTH_POLE)
  }
  toString() {
    return `UPSGrid[${this.isNorthPole() ? 'North pole' : 'South pole'}]`
  }
  get name() {
    return `UPS ${this.isNorthPole() ? 'North pole' : 'South pole'} (${
      this.geodeticDatum.name
    })`
  }
  set name(e) {}
}
