import { OGCFilterBuilderVisitor } from './OGCFilterBuilderVisitor.js'
import {
  createEvaluationFunction,
  PRE_DEFINED_FUNCTION_EVALUATORS,
} from '../FunctionEvaluatorUtil.js'
export const FilterToFunction = {
  createFeatureFilterPredicate: createFeatureFilterPredicate,
}
function createFeatureFilterPredicate(e) {
  const t = undefined
  const r = undefined
  return new FeatureFilterBuilderVisitor()
    .buildFF(e)
    .bind(null, PRE_DEFINED_FUNCTION_EVALUATORS)
}
class FeatureFilterBuilderVisitor {
  constructor() {}
  buildFF(e) {
    const t = new OGCFilterBuilderVisitor({
      functionEvaluators: PRE_DEFINED_FUNCTION_EVALUATORS,
      expectedFunctionOutput: 'boolean',
    })
    t.reset()
    e.accept(t)
    const r = ' return !!(' + t.expression + ')'
    return createEvaluationFunction(r)
  }
}
