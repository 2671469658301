export class LinearIndex {
  constructor(e) {
    this.reference = e ? e : null
    this.clear()
  }
  clear() {
    this.idMap = new Map()
  }
  getBounds() {
    let e = null
    this.forEachVisibleNode((t) => {
      const i = t.shape && t.shape.bounds
      if (i)
        if (!e) e = i.copy()
        else e.setTo3DUnion(i)
    })
    return e
  }
  getEntryCount() {
    return this.idMap.size
  }
  getNode(e) {
    return this.idMap.get(e) || null
  }
  insert(e) {
    const t = e.feature.id
    this.idMap.set(t, e)
  }
  remove(e) {
    const t = this.idMap.get(e)
    if (t) this.idMap.delete(e)
    return t || null
  }
  getIdMapSnapshot() {
    return new Map(this.idMap)
  }
  search(e, t) {
    this.forEachVisibleNode(t)
  }
  forEachVisibleNode(e) {
    this.idMap.forEach((t) => {
      if (t.shape) e(t)
    })
  }
  forEachNode(e) {
    this.idMap.forEach((t) => e(t))
  }
}
