import { InvalidReferenceError } from '../error/InvalidReferenceError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Vector3 as Vector3Internal } from '../geometry/mesh/math/Vector3.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { XYZBounds } from './XYZBounds.js'
import { XYZPoint } from './XYZPoint.js'
export class OrientedBox extends Shape {
  constructor(e, r) {
    super()
    this._reference = e
    this._boxCorners = r
    this._cache = {}
    this._clearCache()
  }
  contains2DPoint(e) {
    if (this.reference && !this.reference.equals(e.reference))
      throw new InvalidReferenceError(
        'OrientedBox.contains2DPoint: given reference does not equal oriented box reference. Please transform it first.'
      )
    if (null === this.reference && null !== e.reference)
      throw new InvalidReferenceError(
        'OrientedBox.contains2DPoint: given reference does not equal oriented box reference. Please transform it first.'
      )
    return super.contains2DPoint(e)
  }
  contains2DCoordinates(e, r) {
    const t = [
      [0, 1, 2],
      [0, 2, 3],
      [4, 5, 6],
      [4, 6, 7],
      [1, 2, 6],
      [1, 6, 5],
      [0, 3, 7],
      [0, 7, 4],
      [0, 1, 5],
      [0, 5, 4],
      [3, 2, 6],
      [3, 6, 7],
    ]
    for (const n of t) {
      const t = this._boxCorners[n[0]]
      const o = this._boxCorners[n[1]]
      const s = this._boxCorners[n[2]]
      const i = new Vector3Internal(t.x, t.y, 0)
      const c = new Vector3Internal(o.x, o.y, 0)
      const a = new Vector3Internal(s.x, s.y, 0)
      if (pointInTriangle2D(new Vector3Internal(e, r, 0), i, c, a)) return true
    }
    return false
  }
  contains3DPoint(e) {
    throw new ProgrammingError('Not implemented.')
  }
  contains3DCoordinates(e, r, t) {
    throw new ProgrammingError('Not implemented.')
  }
  copy() {
    const e = []
    for (let r = 0; r < this._boxCorners.length; r++)
      e.push({
        x: this._boxCorners[r].x,
        y: this._boxCorners[r].y,
        z: this._boxCorners[r].z,
      })
    return new OrientedBox(this._reference, e)
  }
  equals(e) {
    if (!e) return false
    if (e.type !== ShapeType.ORIENTED_BOX) return false
    if (!this._referencesEqual(this._reference, e.reference)) return false
    const r = e.getCorners()
    for (let e = 0; e < this._boxCorners.length; e++) {
      if (this._boxCorners[e].x !== r[e].x) return false
      if (this._boxCorners[e].y !== r[e].y) return false
      if (this._boxCorners[e].z !== r[e].z) return false
    }
    return true
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    for (let r = 0; r < this._boxCorners.length; r++) {
      e.appendDouble(this._boxCorners[r].x)
      e.appendDouble(this._boxCorners[r].y)
      e.appendDouble(this._boxCorners[r].z)
    }
  }
  getCorners() {
    return this._boxCorners
  }
  getCornerPoints() {
    const e = []
    for (let r = 0; r < this._boxCorners.length; r++)
      e.push(
        new XYZPoint(this.reference, [
          this._boxCorners[r].x,
          this._boxCorners[r].y,
          this._boxCorners[r].z,
        ])
      )
    return e
  }
  toString() {
    let e = 'OrientedBox['
    for (let r = 0; r < this._boxCorners.length; r++) {
      e += `(${this._boxCorners[r].x},${this._boxCorners[r].y},${this._boxCorners[r].z})`
      if (r !== this._boxCorners.length - 1) e += ','
    }
    e += ']'
    return e
  }
  translate2D(e, r) {
    this.translate3D(e, r, 0)
  }
  translate3D(e, r, t) {
    for (const n of this._boxCorners) {
      n.x += e
      n.y += r
      n.z += t
    }
    this._clearCache()
  }
  transform(e) {
    const r = []
    for (let t = 0; t < this._boxCorners.length; t++)
      r.push(e.transform(this._boxCorners[t]))
    this._boxCorners = r
    if (e.outputReference) this._reference = e.outputReference
    this._clearCache()
  }
  _clearCache() {
    this._cache = { bounds: null, centerPoint: null, bottomCenterPoint: null }
  }
  invalidate() {
    this._clearCache()
  }
  get focusPoint() {
    if (!this._cache || !this._cache.focusPoint)
      this._cache.focusPoint = findCenter(this.reference, this._boxCorners)
    return this._cache.focusPoint
  }
  set focusPoint(e) {
    throw new ProgrammingError('focusPoint property is not mutable')
  }
  get bounds() {
    if (!this._cache || !this._cache.bounds)
      this._cache.bounds = calculateBounds(this.reference, this._boxCorners)
    return this._cache.bounds
  }
  set bounds(e) {
    throw new ProgrammingError('bounds property is not mutable')
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  get type() {
    return ShapeType.ORIENTED_BOX
  }
  get isGeodetic() {
    return false
  }
}
function findCenter(e, r) {
  let t = r[0].x
  let n = r[0].y
  let o = r[0].z
  for (let e = 1; e < r.length; e++) {
    t += r[e].x
    n += r[e].y
    o += r[e].z
  }
  t /= r.length
  n /= r.length
  o /= r.length
  return new XYZPoint(e, [t, n, o])
}
function calculateBounds(e, r) {
  const t = r[0].x
  const n = r[0].y
  const o = r[0].z
  const s = new XYZPoint(e, [t, n, o])
  const i = new XYZBounds(e, [t, 0, n, 0, o, 0])
  for (let e = 1; e < r.length; e++) {
    s.move3DToCoordinates(r[e].x, r[e].y, r[e].z)
    i.setToIncludePoint3D(s)
  }
  return i
}
function pointInTriangle2D(e, r, t, n) {
  const o = new Vector3Internal(e.x, e.y, 0)
  const s = new Vector3Internal(r.x, r.y, 0)
  const i = new Vector3Internal(t.x, t.y, 0)
  const c = new Vector3Internal(n.x, n.y, 0)
  const a = new Vector3Internal().subVectors(s, c)
  const h = new Vector3Internal().subVectors(s, i)
  const l = new Vector3Internal().subVectors(s, o)
  const f = a.dot(a)
  const u = a.dot(h)
  const x = a.dot(l)
  const _ = h.dot(h)
  const p = h.dot(l)
  const C = 1 / (f * _ - u * u)
  const b = (_ * x - u * p) * C
  const d = (f * p - u * x) * C
  return b >= 0 && d >= 0 && b + d < 1
}
