import { createPhotonColorFromString, INVISIBLE } from './Color.js'
class _ColorMap {
  constructor(o, r, t) {
    this._levels = o
    this._colors = r
    this._colorStrings = t
  }
  toPhotonBuffers(o) {
    o.assertReady()
    const r = this._levels
    const t = o.BufferFactory.createFloat32BufferFromLength(r.length)
    for (let o = 0; o < r.length; o++) t.typedArray[o] = r[o]
    const e = this._colors
    let l = 0
    const s = o.BufferFactory.createFloat32BufferFromLength(4 * e.length)
    for (const o of e) {
      s.typedArray[l++] = o.r
      s.typedArray[l++] = o.g
      s.typedArray[l++] = o.b
      s.typedArray[l++] = o.a
    }
    return {
      levels: t,
      colors: s,
      release: function () {
        this.levels.release()
        this.colors.release()
        this.levels = null
        this.colors = null
      },
    }
  }
}
function createGradientColorMap(o) {
  const r = []
  const t = []
  const e = []
  for (const l of o) {
    const o = createPhotonColorFromString(l.color || INVISIBLE)
    if (null === o)
      throw new Error(`Could not parse color "${l.color}" from color map.`)
    r.push(l.level)
    t.push(o)
    e.push(l.color || INVISIBLE)
  }
  return new _ColorMap(r, t, e)
}
function createPiecewiseConstantColorMap(o) {
  const r = []
  const t = []
  const e = []
  for (let l = 0; l < o.length; l += 2) {
    if (l < o.length - 1) {
      const r = o[l + 1]
      const s = createPhotonColorFromString(r)
      if (null === s)
        throw new Error(`Could not parse color "${r}" from color map.`)
      t.push(s)
      e.push(o[l + 1])
    }
    r.push(o[l])
  }
  return new _ColorMap(r, t, e)
}
export { createGradientColorMap, createPiecewiseConstantColorMap }
