import { ComplexInterval } from './ComplexInterval.js'
export class PointLocationUtil {
  constructor() {
    this._tmpPoint = { x: 0, y: 0 }
    this._tmpPointVertical = [0, 0]
    this._tmpPointHorizontal = [0, 0]
    this._complexInterval1 = new ComplexInterval()
    this._complexInterval2 = new ComplexInterval()
  }
  findLocation(t, n) {
    if (t.length < 3) return null
    let e = t[0]
    let s = e
    let o = t[1]
    let i = o
    const l = 3
    const r = t.length / l - 1
    for (let n = 1; n < r; n++) {
      e = Math.min(e, t[n * l])
      s = Math.max(s, t[n * l])
      o = Math.min(o, t[n * l + 1])
      i = Math.max(i, t[n * l + 1])
    }
    const a = this._findPointVerticalFirst(e, s, o, i, t, n)
    const c = this._findPointHorizontalFirst(e, s, o, i, t, n)
    if (a < 0 && c < 0) return null
    else if (a >= c)
      return { x: this._tmpPointVertical[0], y: this._tmpPointVertical[1] }
    else
      return { x: this._tmpPointHorizontal[0], y: this._tmpPointHorizontal[1] }
  }
  _findPointVerticalFirst(t, n, e, s, o, i) {
    let l = t + 0.5 * (n - t)
    let r = this._findInterval(false, l, e, s, o, i)
    if (!r) return -1
    const a = r[1] - r[0]
    const c = 0.5 * (r[0] + r[1])
    r = this._findInterval(true, c, t, n, o, i)
    if (!r) return -1
    const h = r[1] - r[0]
    l = 0.5 * (r[0] + r[1])
    this._tmpPointVertical[0] = l
    this._tmpPointVertical[1] = c
    return h * a
  }
  _findPointHorizontalFirst(t, n, e, s, o, i) {
    let l = e + 0.5 * (s - e)
    let r = this._findInterval(true, l, t, n, o, i)
    if (!r) return -1
    const a = r[1] - r[0]
    const c = 0.5 * (r[0] + r[1])
    r = this._findInterval(false, c, e, s, o, i)
    if (!r) return -1
    const h = r[1] - r[0]
    l = 0.5 * (r[0] + r[1])
    this._tmpPointHorizontal[0] = c
    this._tmpPointHorizontal[1] = l
    return a * h
  }
  _findInterval(t, n, e, s, o, i) {
    this._complexInterval1.reset()
    this._createComplexInterval(o, t, n, e, s, this._complexInterval1)
    if (i) {
      const o = i.length
      for (let l = 0; l < o; l++) {
        this._complexInterval2.reset()
        this._createComplexInterval(
          i[l],
          t,
          n,
          e - 1,
          s + 1,
          this._complexInterval2
        )
        this._complexInterval1.subtractComplexInterval(this._complexInterval2)
      }
    }
    return this._complexInterval1.findLargestInterval()
  }
  _createComplexInterval(t, n, e, s, o, i) {
    const l = n ? s : e
    const r = n ? e : s
    const a = n ? o : e
    const c = n ? e : o
    let h = -1
    let f = true
    const m = 3
    const u = t.length / m - 1
    for (let s = 0; s < u; s++) {
      const o = t[m * s]
      const i = t[m * s + 1]
      const l = n ? i : o
      if (l != e) {
        h = s
        if (l < e) f = false
        break
      }
    }
    if (-1 == h) {
      i.addSubInterval(s - 1, o + 1)
      return
    }
    const p = []
    const _ = []
    let x = h % u
    let v = t[m * x]
    let I = t[m * x + 1]
    for (let s = 0; s < u; s++) {
      x = (h + s + 1) % u
      const o = v
      const i = I
      v = t[m * x]
      I = t[m * x + 1]
      if (Math.abs(o - v) < TOLERANCE && Math.abs(i - I) < TOLERANCE) continue
      const E = {}
      intersectSegments2DSFCT(o, i, v, I, l, r, a, c, E)
      if (E.intersects) {
        const t = E.x
        const s = E.y
        const l = Math.abs(o - t) < TOLERANCE && Math.abs(i - s) < TOLERANCE
        const r = Math.abs(v - t) < TOLERANCE && Math.abs(I - s) < TOLERANCE
        const a = n ? t : s
        const c = n ? I > e : v > e
        if (l && r);
        else if (r);
        else if (l) {
          if (c === f) {
            _.push(a)
            _.push(a)
          } else p.push(a)
          f = c
        } else {
          p.push(a)
          f = c
        }
      } else {
        const t = (o - v) * (r - c) - (i - I) * (a - l)
        if (Math.abs(t) < TOLERANCE) {
          const t = (o - l) * (c - r) - (i - r) * (a - l)
          const e = (l - o) * (I - i) - (r - i) * (v - o)
          if (Math.abs(t) < TOLERANCE && Math.abs(e) < TOLERANCE) {
            const t = Math.min(n ? o : i, n ? v : I)
            const e = Math.max(n ? o : i, n ? v : I)
            _.push(t)
            _.push(e)
          }
        }
      }
    }
    p.sort((t, n) => (t < n ? -1 : t == n ? 0 : 1))
    for (let t = 0; t < Math.floor(p.length / 2); t++)
      i.addSubInterval(p[2 * t], p[2 * t + 1])
    for (let t = 0; t < Math.floor(_.length / 2); t++)
      i.subtractSubInterval(_[2 * t], _[2 * t + 1])
  }
}
const TOLERANCE = 1e-10
function intersectSegments2DSFCT(t, n, e, s, o, i, l, r, a) {
  const c = (s - n) * (l - o) - (e - t) * (r - i)
  if (Math.abs(c) > TOLERANCE) {
    const h = undefined
    const f = ((t - o) * (r - i) - (n - i) * (l - o)) / c
    const m = ((i - n) * (e - t) - (o - t) * (s - n)) / c
    if (f >= 0 && f <= 1 && m >= 0 && m <= 1) {
      const o = t + f * (e - t)
      const i = n + f * (s - n)
      a.x = o
      a.y = i
      a.intersects = true
      return true
    }
  }
  a.x = null
  a.y = null
  a.intersects = false
  return false
}
