import { ProgrammingError } from '../../error/ProgrammingError.js'
import { EdgeEnd } from './EdgeEnd.js'
function CGBundleStar(e) {
  this._pos = e
  this._edges = []
  this._singlePoints = []
}
CGBundleStar.prototype = Object.create(Object.prototype)
CGBundleStar.prototype.constructor = CGBundleStar
Object.defineProperty(CGBundleStar.prototype, 'pos', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._pos
  },
  set: function () {
    throw new ProgrammingError('pos property is not mutable')
  },
})
CGBundleStar.prototype.onSegmentSplit = function (e, t) {
  for (var n = 0; n < this._edges.length; n++) {
    var r = this._edges[n]
    if (r.segment === e) r.segment = t
  }
}
CGBundleStar.prototype.addEdge = function (e, t, n) {
  if (e.isPoint()) {
    this._singlePoints.push(e)
    return
  }
  if (null === e.next) return
  var r = new EdgeEnd(e, t, n)
  var i = r.angle
  if (0 === this._edges.length) this._edges.push(r)
  else
    for (var s = 0; s <= this._edges.length; s++)
      if (s == this._edges.length) {
        this._edges.push(r)
        break
      } else if (this._edges[s].angle >= i) {
        this._edges.splice(s, 0, r)
        break
      }
}
CGBundleStar.prototype.addEdgeIfNotAlreadyExists = function (e, t, n) {
  if (this.getIndex(e, t) < 0 && this.getPointIndex(e) < 0)
    this.addEdge(e, t, n)
}
CGBundleStar.prototype.getEdgeEnd = function (e) {
  return this._edges[e]
}
CGBundleStar.prototype.getPoint = function (e) {
  return this._singlePoints[e]
}
CGBundleStar.prototype.getIndex = function (e, t) {
  for (var n = 0; n < this._edges.length; n++) {
    var r = this._edges[n]
    if (r.segment === e && r.dir === t) return n
  }
  return -1
}
CGBundleStar.prototype.getPointIndex = function (e) {
  for (var t = 0; t < this._singlePoints.length; t++)
    if (this._singlePoints[t] === e) return t
  return -1
}
CGBundleStar.prototype.getNumEdgeEnds = function () {
  return this._edges.length
}
CGBundleStar.prototype.getNumPoints = function () {
  return this._singlePoints.length
}
CGBundleStar.prototype.wrap = function (e) {
  if (e >= this._edges.length) e -= this._edges.length
  else if (e < 0) e += this._edges.length
  return e
}
CGBundleStar.prototype.merge = function (e, t) {
  if (e == this) return
  for (var n = 0; n < e.getNumEdgeEnds(); n++) {
    var r = e.getEdgeEnd(n)
    this.addEdgeIfNotAlreadyExists(r.segment, r.dir, t)
    if (r.segment.bundleStar === e) r.segment.bundleStar = this
  }
  for (var n = 0; n < e.getNumPoints(); n++) {
    var r = e.getPoint(n)
    this.addEdgeIfNotAlreadyExists(r, 1, t)
    if (r.bundleStar === e) r.bundleStar = this
  }
}
CGBundleStar.mergeBundleStars = function (e, t, n) {
  if (null === e) return t
  if (null === t) return e
  e.merge(t, n)
  return e
}
CGBundleStar.prototype.getLocationToShape = function (e, t, n) {
  var r = 2 * Math.PI
  var i = -1
  for (var s = 0; s < this.getNumEdgeEnds(); s++) {
    var o
    if ((o = this.getEdgeEnd(s)).segment.shapeIndex !== e) continue
    var g
    var a = t - (g = o.angle)
    if (a < 0) a += 2 * Math.PI
    if (a < r) {
      r = a
      i = s
    }
  }
  if (i < 0)
    throw new ProgrammingError('no edge found for angle for shape test')
  var o
  var d = (o = this.getEdgeEnd(i)).segment
  var l = o.dir
  var g
  if ((g = o.angle) == t)
    if (+1 == l) {
      n[0] = d.segmentLabel.locationLeft[d.shapeIndex]
      n[1] = d.segmentLabel.locationOn[d.shapeIndex]
      n[2] = d.segmentLabel.locationRight[d.shapeIndex]
    } else {
      n[0] = d.segmentLabel.locationRight[d.shapeIndex]
      n[1] = d.segmentLabel.locationOn[d.shapeIndex]
      n[2] = d.segmentLabel.locationLeft[d.shapeIndex]
    }
  else if (+1 == l)
    n[0] = n[1] = n[2] = d.segmentLabel.locationLeft[d.shapeIndex]
  else n[0] = n[1] = n[2] = d.segmentLabel.locationRight[d.shapeIndex]
}
export { CGBundleStar }
