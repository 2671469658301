import { isUndefined } from '../../util/Lang.js'
import { UUID } from '../../util/UUID.js'
import { KMLFeature } from './KMLFeature.js'
export class KMLContainer extends KMLFeature {
  constructor(e, r, n) {
    super(null, e, isUndefined(r) ? UUID.randomUUID() : r, n)
    this._children = n.children
  }
  get shape() {
    return null
  }
  get children() {
    return this._children.slice()
  }
  get options() {
    return { ...super.options, children: this.children.map((e) => e.copy()) }
  }
  addChild(e) {
    this._children.push(e)
  }
}
export function isKMLContainer(e) {
  return e instanceof KMLContainer
}
