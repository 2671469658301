import { cloneDeep } from '../../util/JSON.js'
import { KMLColor, KMLColorMode } from '../../util/kml/KMLColor.js'
import { KMLDisplayMode } from '../../util/kml/KMLDisplayMode.js'
import { KMLItemIconState } from '../../util/kml/KMLItemIcon.js'
import { KMLListItemType } from '../../util/kml/KMLListItemType.js'
import { KMLUnits } from '../../util/kml/KMLXYVector.js'
export class KMLStyle {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    this._balloonStyle = t.balloonStyle || new KMLBalloonStyle()
    this._iconStyle = t.iconStyle || new KMLIconStyle()
    this._labelStyle = t.labelStyle || new KMLLabelStyle()
    this._lineStyle = t.lineStyle || new KMLLineStyle()
    this._listStyle = t.listStyle || new KMLListStyle()
    this._polyStyle = t.polyStyle || new KMLPolyStyle()
  }
  get balloonStyle() {
    return this._balloonStyle
  }
  get iconStyle() {
    return this._iconStyle
  }
  get labelStyle() {
    return this._labelStyle
  }
  get lineStyle() {
    return this._lineStyle
  }
  get listStyle() {
    return this._listStyle
  }
  get polyStyle() {
    return this._polyStyle
  }
  copy() {
    return new KMLStyle({
      balloonStyle: this._balloonStyle.copy(),
      iconStyle: this._iconStyle.copy(),
      labelStyle: this._labelStyle.copy(),
      lineStyle: this._lineStyle.copy(),
      listStyle: this._listStyle.copy(),
      polyStyle: this._polyStyle.copy(),
    })
  }
}
export class KMLBalloonStyle {
  constructor() {
    this._bgColor = new KMLColor()
    this._textColor = new KMLColor()
    this._text = null
    this._displayMode = KMLDisplayMode.DEFAULT
  }
  get bgColor() {
    return this._bgColor.rgba
  }
  set bgColor(t) {
    this._bgColor.replace(t)
  }
  get textColor() {
    return this._textColor.rgba
  }
  set textColor(t) {
    this._textColor.replace(t)
  }
  get rawBgColor() {
    return this._bgColor
  }
  get rawTextColor() {
    return this._textColor
  }
  get text() {
    return this._text
  }
  set text(t) {
    this._text = t
  }
  get displayMode() {
    return this._displayMode
  }
  set displayMode(t) {
    this._displayMode = t
  }
  copy() {
    const t = new KMLBalloonStyle()
    t.bgColor = this.bgColor
    t.textColor = this.textColor
    t.text = this.text
    t.displayMode = this.displayMode
    return t
  }
}
export class KMLColorStyle {
  constructor() {
    this._color = new KMLColor()
    this._colorMode = KMLColorMode.NORMAL
  }
  get color() {
    return this._color.rgba
  }
  set color(t) {
    this._color.replace(t)
  }
  get colorMode() {
    return this._colorMode
  }
  set colorMode(t) {
    this._colorMode = t
  }
  static getRawColor(t) {
    return t._color
  }
  static getComputedColor(t) {
    return t.colorMode === KMLColorMode.RANDOM
      ? t._color.random()
      : t._color.clone()
  }
}
export class KMLLabelStyle extends KMLColorStyle {
  constructor() {
    super()
    this._scale = 1
  }
  get scale() {
    return this._scale
  }
  set scale(t) {
    this._scale = t
  }
  copy() {
    const t = new KMLLabelStyle()
    t.color = this.color
    t.colorMode = this.colorMode
    t.scale = this.scale
    return t
  }
}
export class KMLLineStyle extends KMLColorStyle {
  constructor() {
    super()
    this._width = 1
  }
  get width() {
    return this._width
  }
  set width(t) {
    this._width = t
  }
  copy() {
    const t = new KMLLineStyle()
    t.color = this.color
    t.colorMode = this.colorMode
    t.width = this.width
    return t
  }
}
const DEFAULT_KML_ITEM_ICON = { state: KMLItemIconState.NONE, href: null }
export class KMLListStyle {
  constructor() {
    this._bgColor = new KMLColor()
    this._itemIcon = { ...DEFAULT_KML_ITEM_ICON }
    this._listItemType = KMLListItemType.CHECK
  }
  get itemIcon() {
    return this._itemIcon
  }
  set itemIcon(t) {
    this._itemIcon = { ...DEFAULT_KML_ITEM_ICON, ...t }
  }
  get bgColor() {
    return this._bgColor.rgba
  }
  set bgColor(t) {
    this._bgColor.replace(t)
  }
  get rawBgColor() {
    return this._bgColor
  }
  get listItemType() {
    return this._listItemType
  }
  set listItemType(t) {
    this._listItemType = t
  }
  copy() {
    const t = new KMLListStyle()
    t.bgColor = this.bgColor
    t.itemIcon = cloneDeep(this.itemIcon)
    t.listItemType = this.listItemType
    return t
  }
}
export class KMLPolyStyle extends KMLColorStyle {
  constructor() {
    super()
    this._fill = true
    this._outline = true
  }
  get fill() {
    return this._fill
  }
  set fill(t) {
    this._fill = t
  }
  get outline() {
    return this._outline
  }
  set outline(t) {
    this._outline = t
  }
  copy() {
    const t = new KMLPolyStyle()
    t.color = this.color
    t.colorMode = this.colorMode
    t.fill = this.fill
    t.outline = this.outline
    return t
  }
}
const DEFAULT_KML_ICON = { href: null }
const DEFAULT_KML_HOTSPOT = {
  x: 0.5,
  y: 0.5,
  xUnits: KMLUnits.FRACTION,
  yUnits: KMLUnits.FRACTION,
}
export class KMLIconStyle extends KMLColorStyle {
  constructor() {
    super()
    this._heading = 0
    this._icon = null
    this._scale = 1
    this._hotSpot = { ...DEFAULT_KML_HOTSPOT }
  }
  get hotSpot() {
    return this._hotSpot
  }
  set hotSpot(t) {
    this._hotSpot = { ...DEFAULT_KML_HOTSPOT, ...(t || {}) }
  }
  get scale() {
    return this._scale
  }
  set scale(t) {
    this._scale = t
  }
  get heading() {
    return this._heading
  }
  set heading(t) {
    this._heading = t
  }
  get icon() {
    return this._icon
  }
  set icon(t) {
    this._icon = t && { ...DEFAULT_KML_ICON, ...t }
  }
  copy() {
    const t = new KMLIconStyle()
    t.color = this.color
    t.colorMode = this.colorMode
    t.heading = this.heading
    t.icon = this.icon
    t.scale = this.scale
    t.hotSpot = this.hotSpot
    return t
  }
}
