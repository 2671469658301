import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { ObliqueCylindrical } from './ObliqueCylindrical.js'
const sharedOutOfBoundsError = new OutOfBoundsError('SphericalObliqueMercator')
const DISTANCE_FROM_POLE = 1
const NORTH_LAT_LIMIT = 90 - DISTANCE_FROM_POLE
const SOUTH_LAT_LIMIT = -90 + DISTANCE_FROM_POLE
const fSinNorthLimit = Math.sin(Constants.DEG2RAD * NORTH_LAT_LIMIT)
const fSinSouthLimit = Math.sin(Constants.DEG2RAD * SOUTH_LAT_LIMIT)
export class SphericalObliqueMercator extends ObliqueCylindrical {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    let n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0
    super()
    this.fCosPhiPole = 0
    this.fSinPhiPole = 0
    this.fLambda0 = 0
    this.fMatrix = { m20: 0, m21: 0, m22: 1 }
    this.setCentralMeridian(t)
    this.setStandardParallel(s)
    this.setAzimuth(n)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    const t = Math.cos(Constants.DEG2RAD * this.getStandardParallel())
    const s = Math.sin(Constants.DEG2RAD * this.getStandardParallel())
    const n = Math.cos(Constants.DEG2RAD * this.getAzimuth())
    const o = Math.sin(Constants.DEG2RAD * this.getAzimuth())
    const a = Constants.RAD2DEG * Math.asin(t * o)
    const i = normalizeLon(
      Constants.RAD2DEG * Math.atan2(-n, -s * o) + this.getCentralMeridian()
    )
    this.fCosPhiPole = Math.cos(a)
    this.fSinPhiPole = Math.sin(a)
    this.fLambda0 = normalizeLon(i + 90)
    const e = -this.getAzimuth() * Constants.DEG2RAD
    const h = -this.getStandardParallel() * Constants.DEG2RAD
    const r = this.getCentralMeridian() * Constants.DEG2RAD
    const c = Math.cos(e)
    const l = Math.sin(e)
    const M = Math.cos(h)
    const d = Math.sin(h)
    const u = Math.cos(r)
    const D = Math.sin(r)
    const L = l * D - c * d * u
    const C = l * u + c * d * D
    const f = c * M
    this.fMatrix = this.fMatrix ?? { m20: 0, m21: 0, m22: 1 }
    this.fMatrix.m20 = L
    this.fMatrix.m21 = C
    this.fMatrix.m22 = f
  }
  geodetic2cartesianOnSphereSFCT(t, s, n) {
    if (this.inLonLatBounds(t)) {
      const o = (t.x - this.fLambda0) * Constants.DEG2RAD
      const a = Math.sin(o)
      const i = Math.cos(o)
      const e = Math.sin(t.y * Constants.DEG2RAD)
      const h = Math.cos(t.y * Constants.DEG2RAD)
      const r = Math.tan(t.y * Constants.DEG2RAD)
      const c = s * Math.atan2(r * this.fCosPhiPole + this.fSinPhiPole * a, i)
      const l = this.fSinPhiPole * e - this.fCosPhiPole * h * a
      const M = (s / 2) * Math.log((1 + l) / (1 - l))
      n.move2D(c, M)
      if (!this.inWorldBoundsOnSphere(n, s)) throw sharedOutOfBoundsError
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereSFCT(t, s, n) {
    if (this.inWorldBoundsOnSphere(t, s))
      this.cartesian2geodeticOnSphereNoExceptionsSFCT(t, s, n)
    else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereNoExceptionsSFCT(t, s, n) {
    const o = t.x
    const a = t.y
    const i = Math.exp(a / s)
    const e = (i + 1 / i) / 2
    const h = (i - 1 / i) / 2
    const r = h / e
    const c = o / s
    const l = Math.sin(c)
    const M = Math.cos(c)
    const d = Math.asin(this.fSinPhiPole * r + (this.fCosPhiPole * l) / e)
    const u = Math.atan2(this.fSinPhiPole * l - this.fCosPhiPole * h, M)
    const D = d * Constants.RAD2DEG
    const L = normalizeLon(this.fLambda0 + u * Constants.RAD2DEG)
    n.move2D(L, D)
  }
  isAllInBounds() {
    return false
  }
  isContinuous() {
    return true
  }
  inLonLatBounds(t) {
    return true
  }
  boundaryLons(t) {
    let s, n
    const o = new LLHPoint()
    o.move2D(this.getCentralMeridian(), t)
    if (this.inLonLatBounds(o)) {
      const o = this.fMatrix
      let a, i, e, h
      const r = Math.cos(Constants.DEG2RAD * t)
      const c = Math.sin(Constants.DEG2RAD * t)
      const l = o.m21 * r
      const M = o.m20 * r
      const d = fSinNorthLimit - o.m22 * c
      const u = Math.sqrt(l * l + M * M)
      if (Math.abs(d / u) < 1) {
        s = Constants.RAD2DEG * Math.acos(d / u) - this.EPSILON
        n = Constants.RAD2DEG * Math.atan2(l, M)
        e = normalizeLon(n - s)
        h = normalizeLon(n + s)
      } else {
        e = normalizeLon(180 + this.getCentralMeridian() - this.EPSILON)
        h = e
      }
      const D = fSinSouthLimit - o.m22 * c
      if (Math.abs(D / u) < 1) {
        s = Constants.RAD2DEG * Math.acos(D / u) - this.EPSILON
        n = Constants.RAD2DEG * Math.atan2(l, M)
        a = normalizeLon(n + s)
        i = normalizeLon(n - s)
      } else {
        a = normalizeLon(-180 + this.getCentralMeridian() + this.EPSILON)
        i = a
      }
      return [
        [a, e],
        [h, i],
      ]
    } else return []
  }
  boundaryLats(t) {
    const s = this.fMatrix
    let n, o
    const a = Math.cos(Constants.DEG2RAD * t)
    const i = Math.sin(Constants.DEG2RAD * t)
    const e = s.m22
    const h = s.m20 * a + s.m21 * i
    const r = fSinNorthLimit
    const c = fSinSouthLimit
    const l = Math.sqrt(e * e + h * h)
    if (Math.abs(r / l) < 1) {
      let t, s
      t = Constants.RAD2DEG * Math.acos(r / l) - 1e-6
      s = Constants.RAD2DEG * Math.asin(e / l)
      if (t < 0) {
        n = s + t
        o = s - t
      } else {
        n = s - t
        o = s + t
      }
      let a, i
      t = Constants.RAD2DEG * Math.acos(c / l) - 1e-6
      if (t < 0) {
        a = s + t
        i = s - t
      } else {
        a = s - t
        i = s + t
      }
      if (o - n >= 180) {
        n = -100
        o = -100
      }
      if (i - a >= 180) {
        a = -100
        i = -100
      }
      if (a < n) {
        let t
        t = n
        n = a
        a = t
        t = o
        o = i
        i = t
      }
      const h = [-Number.MAX_VALUE, n, o, a, i, Number.MAX_VALUE]
      const M = [SOUTH_LAT_LIMIT, NORTH_LAT_LIMIT]
      const d = [0, 0, 0, 0, 0, 0]
      let u = 0
      let D = 0
      let L = 0
      do {
        if (h[u] > M[D])
          if (M[D + 1] < h[u]) D += 2
          else if (M[D + 1] < h[u + 1]) {
            d[L++] = h[u]
            d[L++] = M[D + 1]
            D += 2
          } else {
            d[L++] = h[u]
            d[L++] = h[u + 1]
            u += 2
          }
        else if (M[D] < h[u + 1])
          if (M[D + 1] < h[u + 1]) {
            d[L++] = M[D]
            d[L++] = M[D + 1]
            D += 2
          } else {
            d[L++] = M[D]
            d[L++] = h[u + 1]
            u += 2
          }
        else u += 2
      } while (u < h.length && D < M.length)
      const C = []
      for (let t = 0; t < L / 2; t++) {
        C[t][0] = d[2 * t]
        C[t][1] = d[2 * t + 1]
      }
      return C
    }
    return [
      [SOUTH_LAT_LIMIT, -1e-8],
      [1e-8, NORTH_LAT_LIMIT],
    ]
  }
  inWorldBoundsOnSphere(t, s) {
    const n = Math.sin(NORTH_LAT_LIMIT * Constants.DEG2RAD)
    const o = Math.abs((s / 2) * Math.log((1 + n) / (1 - n)))
    return t.x <= s * Math.PI && t.x >= -s * Math.PI && t.y <= o && t.y >= -o
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    const n = Math.sin(NORTH_LAT_LIMIT * Constants.DEG2RAD)
    const o = Math.abs((t / 2) * Math.log((1 + n) / (1 - n)))
    s.move2D(-t * Math.PI, -o)
    s.width = 2 * t * Math.PI
    s.height = 2 * o
  }
  toString() {
    return `Spherical_Oblique_Mercator_${this.getCentralMeridian()}_${this.getStandardParallel()}_${this.getAzimuth()}`
  }
  encode() {
    return {
      type: 'SphericalObliqueMercator',
      centralMeridian: this.getCentralMeridian(),
      standardParallel: this.getStandardParallel(),
      azimuth: this.getAzimuth(),
    }
  }
}
