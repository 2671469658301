import { LLHPoint } from '../shape/LLHPoint.js'
import { Hash } from '../util/Hash.js'
import { ProjectionType } from './ProjectionType.js'
import { BaseProjection } from './BaseProjection.js'
export class ObliqueCylindrical extends BaseProjection {
  constructor() {
    super()
    this._standardParallel = 0
    this._centralMeridian = 0
    this._azimuth = 0
    this._origin = new LLHPoint(null, [0, 0])
    this._hash = 0
    this.calculateCachedValues()
  }
  setStandardParallel(t) {
    this._standardParallel = t
    this._origin.y = this._standardParallel
    this.calculateCachedValues()
  }
  setCentralMeridian(t) {
    this._centralMeridian = t
    this._origin.x = this._centralMeridian
    this.calculateCachedValues()
  }
  setAzimuth(t) {
    this._azimuth = t
    this.calculateCachedValues()
  }
  getOrigin() {
    return this._origin
  }
  getStandardParallel() {
    return this._standardParallel
  }
  getCentralMeridian() {
    return this._centralMeridian
  }
  getAzimuth() {
    return this._azimuth
  }
  isContinuous() {
    return true
  }
  equals(t) {
    if (!super.equals(t)) return false
    const e = t
    return (
      this.getCentralMeridian() === e.getCentralMeridian() &&
      this.getStandardParallel() === e.getStandardParallel() &&
      this.getAzimuth() === e.getAzimuth()
    )
  }
  calculateCachedValues() {
    const t = new Hash()
    this._hash = t
      .appendDouble(this.getCentralMeridian())
      .appendDouble(this.getStandardParallel())
      .appendDouble(this.getAzimuth())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  get TYPE() {
    return ProjectionType.OBLIQUECYLINDRICAL
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
