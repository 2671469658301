import { createPhotonColorFromString } from '../../../../util/Color.js'
import { ArrowType } from '../ArrowType.js'
import { PatternType } from '../pattern/util/PatternType.js'
import { ProgrammingError } from '../../../../error/ProgrammingError.js'
import { isPromise } from '../../../../util/PromiseUtil.js'
export const DEFAULT_STROKE_STYLE_COLOR = 'rgb(0,0,255)'
export function createPhotonCSPattern(e, t, r) {
  switch (r.patternType) {
    case PatternType.GAP:
      return createPhotonGapPattern(e, r)
    case PatternType.LINE:
      return createPhotonLinePattern(e, r)
    case PatternType.PARALLEL_LINE:
      return createPhotonParallelLinePattern(e, r)
    case PatternType.RECTANGLE:
      return createPhotonRectanglePattern(e, r)
    case PatternType.TRIANGLE:
      return createPhotonTrianglePattern(e, r)
    case PatternType.POLYLINE:
      return createPhotonPolylinePattern(e, r)
    case PatternType.ARC:
      return createPhotonArcPattern(e, r)
    case PatternType.WAVE:
      return createPhotonWavePattern(e, r)
    case PatternType.ICON:
      return createPhotonIconPattern(e, t, r)
    case PatternType.TEXT:
      return createPhotonTextPattern(e, t, r)
    case PatternType.ARROW:
      return createPhotonArrowPattern(e, r)
    case PatternType.ATOMIC:
    case PatternType.ALLOW_OVERLAP:
    case PatternType.COMBINE_WITH_REGULAR:
    case PatternType.COMBINE_WITH_FALLBACK:
    case PatternType.REPEAT_COUNT:
    case PatternType.REPEAT_OVER_LENGTH:
      return createPhotonCSWrapperPattern(e, t, r)
    case PatternType.APPEND:
    case PatternType.COMPOSE:
      return createPhotonCSMultiPattern(e, t, r)
    default:
      throw new ProgrammingError(
        `No photon pattern can be created for this pattern ${r.patternType}`
      )
  }
}
function createPhotonCSWrapperPattern(e, t, r) {
  const o = undefined
  const n = createPhotonCSPattern(e, t, r.pattern)
  let a
  switch (r.patternType) {
    case PatternType.ATOMIC:
      a = createPhotonAtomicPattern(e, n)
      break
    case PatternType.ALLOW_OVERLAP:
      a = createPhotonAllowOverlapPattern(e, n, r)
      break
    case PatternType.COMBINE_WITH_REGULAR:
      a = createPhotonCombineWithRegularPattern(e, n)
      break
    case PatternType.COMBINE_WITH_FALLBACK:
      a = createPhotonCombineWithFallbackPattern(e, n)
      break
    case PatternType.REPEAT_COUNT:
      a = createPhotonRepeatCountPattern(e, n, r)
      break
    case PatternType.REPEAT_OVER_LENGTH:
      a = createPhotonRepeatOverLengthPattern(e, n, r)
      break
    default:
      throw new ProgrammingError(
        `Provided pattern is not a wrapperPattern ${r.patternType}`
      )
  }
  n.release()
  return a
}
function createPhotonCSMultiPattern(e, t, r) {
  let o
  const n = []
  for (let o = 0; o < r.patterns.length; o++) {
    const a = createPhotonCSPattern(e, t, r.patterns[o])
    n.push(a)
  }
  switch (r.patternType) {
    case PatternType.APPEND:
      o = createPhotonAppendPattern(e, n)
      break
    case PatternType.COMPOSE:
      o = createPhotonComposePattern(e, n)
      break
    default:
      throw new ProgrammingError(
        `Provided pattern is not a multiPattern ${r.patternType}`
      )
  }
  releaseAllPhotonPatterns(n)
  return o
}
export function createPhotonGapPattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createGapBuilder()
  if (t.relativeLength > 0) r.lengthRelative(t.relativeLength)
  else if (t.fixedLength >= 0) r.lengthFixed(t.fixedLength)
  const o = r.build()
  r.release()
  return o
}
function createPhotonLinePattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createLineBuilder()
  if (t.relativeLength > 0) r.lengthRelative(t.relativeLength)
  else if (t.fixedLength >= 0) r.lengthFixed(t.fixedLength)
  if (t.lineColor) {
    const e = createPhotonColorFromString(t.lineColor)
    if (e) r.lineColor(e.r, e.g, e.b, e.a)
  }
  if (t.fillColor) {
    const e = createPhotonColorFromString(t.fillColor)
    if (e) r.fillColor(e.r, e.g, e.b, e.a)
  }
  r.lineWidth(t.lineWidth)
  r.offset0(t.o1)
  r.offset1(t.o2)
  const o = r.build()
  r.release()
  return o
}
export function createPhotonParallelLinePattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createParallelLineBuilder()
  r.lineWidth(t.lineWidth)
  r.offset(t.o1)
  if (t.relativeLength > 0) r.lengthRelative(t.relativeLength)
  else if (t.fixedLength >= 0) r.lengthFixed(t.fixedLength)
  if (t.lineColor) {
    const e = createPhotonColorFromString(t.lineColor)
    if (e) r.lineColor(e.r, e.g, e.b, e.a)
  }
  const o = r.build()
  r.release()
  return o
}
function createPhotonRectanglePattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createRectangleBuilder()
  if (t.relativeLength > 0) r.lengthRelative(t.relativeLength)
  else if (t.fixedLength > 0) r.lengthFixed(t.fixedLength)
  else
    throw new Error(
      'CS: Must define either relative or pixel length for rectangle pattern'
    )
  if (t.lineColor) {
    const e = createPhotonColorFromString(t.lineColor)
    r.lineColor(e.r, e.g, e.b, e.a)
  }
  if (t.fillColor) {
    const e = createPhotonColorFromString(t.fillColor)
    r.fillColor(e.r, e.g, e.b, e.a)
  }
  r.minHeight(t.getMinHeight())
  r.maxHeight(t.getMaxHeight())
  r.lineWidth(t.lineWidth)
  const o = r.build()
  r.release()
  return o
}
function createPhotonTrianglePattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createTriangleBuilder()
  r.p0(t.x0, t.y0)
  r.p1(t.x1, t.y1)
  r.p2(t.x2, t.y2)
  r.relative(t.relative)
  r.lineWidth(t.lineWidth)
  if (t.lineColor) {
    const e = createPhotonColorFromString(t.lineColor)
    if (e) r.lineColor(e.r, e.g, e.b, e.a)
  }
  if (t.fillColor) {
    const e = createPhotonColorFromString(t.fillColor)
    if (e) r.fillColor(e.r, e.g, e.b, e.a)
  }
  const o = r.build()
  r.release()
  return o
}
function createPhotonPolylinePattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createPolylineBuilder()
  const o = e.BufferFactory.createFloat64BufferFromData(t.xs)
  const n = e.BufferFactory.createFloat64BufferFromData(t.ys)
  if (t.lineColor) {
    const e = createPhotonColorFromString(t.lineColor)
    if (e) r.lineColor(e.r, e.g, e.b, e.a)
  }
  r.points(o, n)
  r.relative(t.relative)
  r.lineWidth(t.lineWidth)
  const a = r.build()
  r.release()
  o.release()
  n.release()
  return a
}
function createPhotonArcPattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createArcBuilder()
  if (t.relativeLength > 0) r.lengthRelative(t.relativeLength)
  else if (t.fixedLength > 0) r.lengthFixed(t.fixedLength)
  else
    throw new Error(
      'CS: Must define either relative or pixel length for arc pattern'
    )
  if (t.lineColor) {
    const e = createPhotonColorFromString(t.lineColor)
    r.lineColor(e.r, e.g, e.b, e.a)
  }
  if (t.fillColor) {
    const e = createPhotonColorFromString(t.fillColor)
    r.fillColor(e.r, e.g, e.b, e.a)
  }
  r.startAngle(t.startAngle)
  r.angle(t.angle)
  r.minorRadius(t.minorRadius)
  r.offset(t.offset)
  r.lineWidth(t.lineWidth)
  const o = r.build()
  r.release()
  return o
}
function createPhotonWavePattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createWaveBuilder()
  if (t.relativeLength > 0) r.lengthRelative(t.relativeLength)
  else if (t.fixedLength > 0) r.lengthFixed(t.fixedLength)
  else
    throw new Error(
      'CS: Must define either relative or pixel length for wave pattern'
    )
  if (t.lineColor) {
    const e = createPhotonColorFromString(t.lineColor)
    r.lineColor(e.r, e.g, e.b, e.a)
  }
  if (t.fillColor) {
    const e = createPhotonColorFromString(t.fillColor)
    r.fillColor(e.r, e.g, e.b, e.a)
  }
  r.amplitude(t.amplitude)
  r.startAngle(t.startAngle)
  r.angle(t.angle)
  r.lineWidth(t.lineWidth)
  const o = r.build()
  r.release()
  return o
}
export function createPhotonIconPattern(e, t, r) {
  const o = e.ComplexStrokeBuilderFactory.createIconBuilder()
  const n = t.get(r)
  if (isPromise(n))
    throw new ProgrammingError(
      'All styles should be resolved before photon patterns are created'
    )
  const a = undefined
  const l = n.photonImage
  o.icon(l)
  o.sizeFactor(r.getSizeFactor())
  o.offset(r.offset)
  o.rotateUp(r.rotateUp)
  o.opacity(r.opacity)
  o.rotation(r.rotation)
  const i = o.build()
  o.release()
  if (l) l.release()
  return i
}
export function createPhotonTextPattern(e, t, r) {
  const o = e.ComplexStrokeBuilderFactory.createIconBuilder()
  const n = t.get(r)
  if (isPromise(n))
    throw new ProgrammingError(
      'All styles should be resolved before photon patterns are created'
    )
  const a = undefined
  const l = n.photonImage
  o.icon(l)
  const i = r.getScaleFactor()
  if (1 !== i) o.sizeFactor(i)
  o.offset(r.offset)
  o.rotateUp(r.rotateUp)
  const c = o.build()
  o.release()
  if (l) l.release()
  return c
}
function toPhotonArrowType(e, t) {
  switch (t) {
    case ArrowType.REGULAR_OUTLINED:
      return e.ComplexStrokeStylePatternArrowType.RegularOutlined
    case ArrowType.REGULAR_FILLED:
      return e.ComplexStrokeStylePatternArrowType.RegularFilled
    case ArrowType.PLAIN:
      return e.ComplexStrokeStylePatternArrowType.Plain
    case ArrowType.PLAIN_OUTLINED:
      return e.ComplexStrokeStylePatternArrowType.PlainOutlined
    case ArrowType.PLAIN_FILLED:
      return e.ComplexStrokeStylePatternArrowType.PlainFilled
    case ArrowType.BLUNT:
      return e.ComplexStrokeStylePatternArrowType.Blunt
    case ArrowType.LEFT_HALF:
      return e.ComplexStrokeStylePatternArrowType.LeftHalf
    case ArrowType.RIGHT_HALF:
      return e.ComplexStrokeStylePatternArrowType.RightHalf
    case ArrowType.DOUBLE_LEFT_HALF:
      return e.ComplexStrokeStylePatternArrowType.DoubleLeftHalf
    case ArrowType.DOUBLE_RIGHT_HALF:
      return e.ComplexStrokeStylePatternArrowType.DoubleRightHalf
  }
}
export function createPhotonArrowPattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createArrowBuilder()
  const o = t.arrowOptions
  const n = toPhotonArrowType(e, o.arrowType)
  r.type(n)
  r.size(o.size)
  r.height(o.height)
  r.offset(o.offset)
  r.forward(o.forward)
  r.lineWidth(o.lineWidth)
  const a = createPhotonColorFromString(
    o.lineColor || DEFAULT_STROKE_STYLE_COLOR
  )
  if (a) r.lineColor(a.r, a.g, a.b, a.a)
  if (o.fillColor) {
    const e = createPhotonColorFromString(o.fillColor)
    if (e) r.fillColor(e.r, e.g, e.b, e.a)
  }
  const l = r.build()
  r.release()
  return l
}
export function createPhotonAllowOverlapPattern(e, t, r) {
  return e.ComplexStrokeBuildingUtils.overlap(r.overlapLeft, r.overlapRight, t)
}
export function createPhotonAtomicPattern(e, t) {
  return e.ComplexStrokeBuildingUtils.atomic(t)
}
export function createPhotonCombineWithRegularPattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createCombineBuilder()
  r.combineWithRegularStroking(t)
  const o = r.build()
  r.release()
  return o
}
export function createPhotonCombineWithFallbackPattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createCombineBuilder()
  r.combineWithFallbackStroking(t)
  const o = r.build()
  r.release()
  return o
}
export function createPhotonRepeatCountPattern(e, t, r) {
  return e.ComplexStrokeBuildingUtils.repeat(r.count, t)
}
export function createPhotonRepeatOverLengthPattern(e, t, r) {
  return r.relative
    ? e.ComplexStrokeBuildingUtils.repeatOverLengthRelative(r.distance, t)
    : e.ComplexStrokeBuildingUtils.repeatOverLengthFixed(r.distance, t)
}
export function createPhotonAppendPattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createAppendBuilder()
  for (let e = 0; e < t.length; e++) r.append(t[e])
  const o = r.build()
  r.release()
  return o
}
export function createPhotonComposePattern(e, t) {
  const r = e.ComplexStrokeBuilderFactory.createComposeBuilder()
  for (let e = 0; e < t.length; e++) r.compose(t[e])
  const o = r.build()
  r.release()
  return o
}
export function releaseAllPhotonPatterns(e) {
  for (const t of e) if (t) t.release()
}
