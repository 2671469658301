import { PathIterator } from './PathIterator.js'
import {
  drawRegularOnLine,
  drawStyledLine,
  isCombineWithPattern,
} from './pattern/util/PatternUtil.js'
import { ClipSectionUtil } from '../util/ClipSectionUtil.js'
import { VirtualClipPolylineUtil } from '../util/VirtualClipPolylineUtil.js'
import { isArray } from '../../../util/Lang.js'
import {
  isMultiType,
  isWrapperType,
  PatternType,
} from './pattern/util/PatternType.js'
const pathIterator = new PathIterator([0, 0, 0, 0], 0, 4)
const clippingVirtual = new VirtualClipPolylineUtil()
const ALIGNMENT = { LEFT: 'left', CENTER: 'center', RIGHT: 'right' }
export function paintComplexStrokeCanvasPath(t, e, n, r) {
  const a = r.lineJoin ?? 'round'
  const i = r.regular ?? r.fallback
  i.lineJoin = a
  const o = r.fallback
  o.lineJoin = a
  pathIterator.reload(e, 0, n)
  const l = pathIterator.totalLength()
  if (l < 1) return
  const s = normalizeDecorations(t, l, r.decorations)
  for (const t of s) t.pattern.lineJoin = a
  const c = i.getWidth(l)
  const p = {
    x: -c,
    width: t.canvas.width + 2 * c,
    y: -c,
    height: t.canvas.height + 2 * c,
  }
  const u = clippingVirtual.run(e, n, p)
  if (!u || 0 === u.clippedSections.length) return
  drawRegularParts(pathIterator, t, s, i, o, u.clippedSections)
  for (let r = 0; r < s.length; r += 1) {
    const a = s[r]
    if (a.width > l) continue
    pathIterator.reload(e, 0, n)
    if (a.startFraction > 0 && a.startFraction <= 1)
      pathIterator.advanceFraction(a.startFraction)
    drawDecoration(pathIterator, t, a, i, o)
  }
}
function normalizeDecorations(t, e, n) {
  const r = n.slice().sort((t, e) => t.location - e.location)
  let a = Number.NEGATIVE_INFINITY
  let i = false
  return r.reduce((t, n) => {
    const r = n.pattern.getWidth(e)
    const s = n.alignment || l(n.location)
    const c = o(s) * r
    const p = n.location * e + c
    const u = n.pattern.patternType === PatternType.GAP
    const f =
      (n.location > 0 && p < 0 && !u) ||
      (p < a && !u && !i) ||
      (n.location < 1 && p + r > e && !u)
    a = p + r
    i = u
    if (!f)
      t.push({
        pattern: n.pattern,
        location: n.location,
        width: r,
        startOffset: c,
        startFraction: n.location + c / e,
        startDistance: p,
        withRegular: isCombineWithPattern(
          n.pattern,
          PatternType.COMBINE_WITH_REGULAR
        ),
        withFallback: isCombineWithPattern(
          n.pattern,
          PatternType.COMBINE_WITH_FALLBACK
        ),
        alignment: s,
      })
    return t
  }, [])
  function o(t) {
    return t === ALIGNMENT.LEFT ? 0 : t === ALIGNMENT.RIGHT ? -1 : -1 / 2
  }
  function l(t) {
    return 0 === t
      ? ALIGNMENT.LEFT
      : 1 === t
      ? ALIGNMENT.RIGHT
      : ALIGNMENT.CENTER
  }
}
function drawRegularParts(t, e, n, r, a, i) {
  const o = getRegularParts(t, n)
  r.setFallbackPattern(a)
  const l = r.getWidth(t.totalLength())
  const s = ClipSectionUtil.getVisibleParts(i)
  for (let n = 0; n < o.length; n++) {
    const i = ClipSectionUtil.getMutualVisibleParts(s, o[n], l)
    for (let n = 0; n < i.length; n++) {
      const o = i[n]
      advanceToVisiblePart(t, r, o)
      drawRegular(t, e, r, a, o)
    }
  }
}
function advanceToVisiblePart(t, e, n) {
  while (!t.atEnd() && t.absoluteDistanceToNextVertex() <= n[0])
    t.advanceToNextVertex()
  const r = e.getWidth(t.totalLength())
  if (r > 0)
    while (!t.atEnd() && t.distanceFromStart() + r <= n[0]) t.advanceDistance(r)
  const a = n[0] - t.distanceFromStart()
  if (a > 0 && !t.atEnd() && !e.isRelativeLength()) t.advanceDistance(a)
}
function drawRegular(t, e, n, r, a) {
  const i = a[1]
  if (!t.atEnd() && i > 0) drawRegularOnLine(t, e, n, i, a)
  if (!t.atEnd() && t.distanceFromStart() + 0.5 < i)
    drawStyledLine(t, e, r, i, a)
}
function getRegularParts(t, e) {
  const n = t.totalLength()
  const r = []
  let a = 0
  for (let n = 0; n < e.length; n += 1) {
    const i = e[n]
    const o = getRegularLimitsInDecoration(t, i)
    if (isArray(o)) {
      if (o[0] !== a) r.push([a, o[0]])
      a = o[1]
      i.regularOverDeco = false
    } else i.regularOverDeco = true
  }
  if (a < n) r.push([a, n])
  return r
}
const FLY_OVER = null
function getRegularLimitsInDecoration(t, e) {
  const n = t.totalLength()
  if (!e.pattern.canBend && t.distanceFromStart() + e.width > n) return FLY_OVER
  if (!e.withRegular) return [e.startDistance, e.startDistance + e.width]
  const r = marginsOverDecoration(t, e.pattern)
  if (r[0] >= e.width || r[0] >= r[1]) return FLY_OVER
  return [e.startDistance + r[0], e.startDistance + r[1]]
}
function marginsOverDecoration(t, e) {
  const n = t.totalLength()
  const r = e.getWidth(n)
  let a = 0
  let i = r
  o(a, e)
  if (a < r) l(i, e)
  return [a, i]
  function o(t, e) {
    let r
    if (e.patternType === PatternType.COMBINE_WITH_REGULAR) {
      const r = t + e.getWidth(n)
      if (r > a) a = r
      return true
    }
    if (isWrapperType(e)) return o(t, e.pattern)
    else if (isMultiType(e))
      if (e.patternType === PatternType.COMPOSE)
        for (r = 0; r < e.patterns.length; r++) o(t, e.patterns[r])
      else
        for (r = 0; r < e.patterns.length; r++) {
          if (!o(t, e.patterns[r])) return false
          t += e.patterns[r].getWidth(n)
        }
    return false
  }
  function l(t, e) {
    if (isWrapperType(e)) {
      if (e.patternType === PatternType.COMBINE_WITH_REGULAR) {
        const r = t - e.getWidth(n)
        if (r < i) i = r
        return true
      }
      return l(t, e.pattern)
    } else if (isMultiType(e))
      if (e.patternType === PatternType.COMPOSE)
        for (let n = e.patterns.length - 1; n >= 0; n--) l(t, e.patterns[n])
      else
        for (let r = e.patterns.length - 1; r >= 0; r--) {
          if (!l(t, e.patterns[r])) return false
          t -= e.patterns[r].getWidth(n)
        }
    return false
  }
}
const NO_CLIP_LIMITS = [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
function drawDecoration(t, e, n, r, a) {
  if (n.withFallback) n.pattern.setFallbackPattern(a)
  else if (!n.withRegular || (n.withRegular && !n.regularOverDeco)) {
    r.setFallbackPattern(a)
    n.pattern.setFallbackPattern(r)
  }
  if (0 === n.location && n.startOffset < 0)
    return n.pattern.paintBack(e, t, n.startOffset)
  if (1 === n.location && n.startDistance >= 0) {
    if (n.alignment === ALIGNMENT.RIGHT) {
      n.pattern.paintOnceOnLine(e, t, NO_CLIP_LIMITS)
      return
    }
    const r = t.angleOverDistance(-n.startOffset)
    t.advanceDistance(t.distanceToEnd())
    return n.pattern.paintForward(e, t, r, n.startOffset)
  }
  n.pattern.paintOnceOnLine(e, t, NO_CLIP_LIMITS)
  return
}
