import { Constants } from '../../util/Constants.js'
import { normalizeLat, normalizeLon } from '../../util/LonLatCoord.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { CachingTransformation } from '../../transformation/CachingTransformation.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { createPoint } from '../ShapeFactory.js'
import { DecimalFormat } from './DecimalFormat.js'
import { Shape } from '../Shape.js'
import { isNumber } from '../../util/Lang.js'
const CONSTANTS = (() => {
  const t = {
    AXIS_LON: 8,
    AXIS_LAT: 9,
    FIELD_SIGN: 1,
    FIELD_DEGREES: 2,
    FIELD_MINUTES: 4,
    FIELD_SECONDS: 8,
    FIELD_AXIS: 16,
    FIELD_DECDEGREES: 2,
    FIELD_DECMINUTES: 4,
    FIELD_DECSECONDS: 6,
    ROUND_TRUNCATE: 0,
    ROUND_HALF_EVEN: 1,
    DEGREES: '°',
    MINUTES: "'",
    SECONDS: '"',
    LONLATPATTERN: (() => {
      const t = '(lat|lon)\\((\\+?(?:[DdMmSsa][0-9]?)+)\\)'
      return `${t}(.*)${t}`
    })(),
  }
  Object.assign(t, {
    ORDINATE_MASK: t.FIELD_DEGREES | t.FIELD_MINUTES | t.FIELD_SECONDS,
    ORDINATE_DMS: t.FIELD_DEGREES | t.FIELD_MINUTES | t.FIELD_SECONDS,
    ORDINATE_MS: t.FIELD_MINUTES | t.FIELD_SECONDS,
    ORDINATE_S: t.FIELD_SECONDS,
    ORDINATE_D: t.FIELD_DEGREES,
    ORDINATE_DM: t.FIELD_DEGREES | t.FIELD_MINUTES,
  })
  Object.assign(t, {
    VALID_ORDINATE_VALUES: [
      t.ORDINATE_DMS,
      t.ORDINATE_MS,
      t.ORDINATE_S,
      t.ORDINATE_D,
      t.ORDINATE_DM,
    ],
  })
  return t
})()
const isDigit = (() => {
  const t = /^\d$/
  return (e) => t.test(e)
})()
function createDecimalFormat(t, e, r, i) {
  return new DecimalFormat({
    minFracDigits: e,
    maxFracDigits: e,
    minIntegerDigits: t,
    prefix: '',
    suffix: '',
    decimalSeparator: r,
    groupingSeparator: i,
    groupingLength: 3,
  })
}
function parseFields(t, e, r, i) {
  const n = []
  let o = 0
  let s = -1
  const a = Array.prototype.slice.call(e)
  let l
  let S
  let _
  let N
  for (let e = 0; e < a.length; e++) {
    l = a[e]
    if (e + 1 < a.length && isDigit(a[e + 1])) {
      _ = e + 1
      N = e + 2
      while (N < a.length && isDigit(a[N])) N++
      S = parseInt(a.slice(_, N).join(''), 10)
      e = N - 1
    } else S = -1
    if ('+' === l) {
      if (0 !== e)
        throw new ProgrammingError(
          `Invalid pattern: sign may only occur as first character [${r}]`
        )
      if (0 !== (o & CONSTANTS.FIELD_SIGN))
        throw new ProgrammingError(
          `Invalid pattern: sign may only occur once [${r}]`
        )
      if (S >= 0)
        throw new ProgrammingError(
          `Invalid pattern: sign may not have fraction digits modifier [${r}]`
        )
      o |= CONSTANTS.FIELD_SIGN
      n.push(
        new OrdinateField(t, CONSTANTS.FIELD_SIGN, createDecimalFormat(0, 0, i))
      )
    } else if ('a' === l) {
      if (S >= 0)
        throw new ProgrammingError(
          `Invalid pattern: axis may not have fraction digits modifier [${r}]`
        )
      if (0 !== (o & CONSTANTS.FIELD_AXIS))
        throw new ProgrammingError(
          `Invalid pattern: sign may only occur once [${r}]`
        )
      o |= CONSTANTS.FIELD_AXIS
      n.push(new OrdinateField(t, CONSTANTS.FIELD_AXIS, null))
    } else if ('d' === l || 'D' === l) {
      if (0 !== (o & CONSTANTS.FIELD_DEGREES))
        throw new ProgrammingError(
          `Invalid pattern: degrees may only occur once [${r}]`
        )
      if (0 !== (o & (CONSTANTS.FIELD_MINUTES | CONSTANTS.FIELD_SECONDS)))
        throw new ProgrammingError(
          `Invalid pattern: degrees cannot occur after minutes or seconds [${r}]`
        )
      if (S >= 0)
        if (s >= 0)
          throw new ProgrammingError(
            `Invalid pattern: precision must be specified after ordinate fields [${r}]`
          )
        else s = S
      o |= CONSTANTS.FIELD_DEGREES
      n.push(
        new OrdinateField(
          t,
          CONSTANTS.FIELD_DEGREES,
          createDecimalFormat(t === CONSTANTS.AXIS_LAT ? 2 : 3, S, i)
        )
      )
      if ('D' === l) n.push(new ConstantField(CONSTANTS.DEGREES))
    } else if ('m' === l || 'M' === l) {
      if (0 !== (o & CONSTANTS.FIELD_MINUTES))
        throw new ProgrammingError(
          `Invalid pattern: minutes may only occur once [${r}]`
        )
      if (0 !== (o & CONSTANTS.FIELD_SECONDS))
        throw new ProgrammingError(
          `Invalid pattern: minutes cannot occur after seconds [${r}]`
        )
      if (S >= 0)
        if (s >= 0)
          throw new ProgrammingError(
            `Invalid pattern: precision must be specified after ordinate fields [${r}]`
          )
        else s = S
      o |= CONSTANTS.FIELD_MINUTES
      n.push(
        new OrdinateField(
          t,
          CONSTANTS.FIELD_MINUTES,
          createDecimalFormat(2, S, i)
        )
      )
      if ('M' === l) n.push(new ConstantField(CONSTANTS.MINUTES))
    } else if ('s' === l || 'S' === l) {
      if (0 !== (o & CONSTANTS.FIELD_SECONDS))
        throw new ProgrammingError(
          `Invalid pattern: seconds may only occur once [${r}]`
        )
      if (S >= 0)
        if (s >= 0)
          throw new ProgrammingError(
            `Invalid pattern: precision must be specified after ordinate fields [${r}]`
          )
        else s = S
      o |= CONSTANTS.FIELD_SECONDS
      n.push(
        new OrdinateField(
          t,
          CONSTANTS.FIELD_SECONDS,
          createDecimalFormat(2, S, i)
        )
      )
      if ('S' === l) n.push(new ConstantField(CONSTANTS.SECONDS))
    } else
      throw new ProgrammingError(
        `Invalid pattern: invalid character ${l} [${r}]`
      )
  }
  const E = o & CONSTANTS.ORDINATE_MASK
  let h = false
  for (let t = 0; t < CONSTANTS.VALID_ORDINATE_VALUES.length; t++)
    if (E === CONSTANTS.VALID_ORDINATE_VALUES[t]) {
      h = true
      break
    }
  if (!h)
    throw new ProgrammingError(
      `Invalid pattern: invalid ordinate specification [${r}]`
    )
  return new FormatFields(n, o, Math.max(0, s))
}
function roundSmallestOrdinate(t) {
  let e
  let r
  let i
  let n = null
  e = t.getFields()
  for (let t = 0, o = e.length; t < o; t++) {
    r = e[t]
    if (r instanceof OrdinateField) {
      i = r._field
      if (
        null === n ||
        ((i === CONSTANTS.FIELD_DEGREES ||
          i === CONSTANTS.FIELD_MINUTES ||
          i === CONSTANTS.FIELD_SECONDS) &&
          n._field < i)
      )
        n = r
    }
  }
  if (null !== n) n.setRoundingMode(CONSTANTS.ROUND_HALF_EVEN)
}
class FormattedOrdinate {
  constructor(t, e, r, i) {
    this._degrees = 0
    this._minutes = 0
    this._seconds = 0
    this._axis = null
    this._positive = true
    this.update(t, e, r, i)
  }
  update(t, e, r, i) {
    let n
    let o
    let s
    const a = e
    const l = Math.abs(t) < Constants.ABSOLUTE_ANGLE_TOLERANCE ? true : t >= 0
    let S = 1
    for (let t = 0; t < i; t++) S *= 10
    let _
    let N
    let E
    let h
    let T
    switch (r & CONSTANTS.ORDINATE_MASK) {
      default:
      case CONSTANTS.ORDINATE_DMS:
        _ = Math.floor(3600 * Math.abs(t) * S + 0.5)
        n = Math.floor(_ / (3600 * S))
        o = Math.floor((_ / (60 * S)) % 60)
        s = (_ % (60 * S)) / S
        break
      case CONSTANTS.ORDINATE_DM:
        N = Math.floor(60 * Math.abs(t) * S + 0.5)
        n = Math.floor(N / (60 * S))
        o = (N % (60 * S)) / S
        s = 0
        break
      case CONSTANTS.ORDINATE_D:
        E = Math.floor(Math.abs(t) * S + 0.5)
        n = E / S
        o = 0
        s = 0
        break
      case CONSTANTS.ORDINATE_MS:
        h = Math.floor(3600 * Math.abs(t) * S + 0.5)
        n = 0
        o = Math.floor(h / (60 * S))
        s = (h % (60 * S)) / S
        break
      case CONSTANTS.ORDINATE_S:
        T = Math.floor(3600 * Math.abs(t) * S + 0.5)
        n = 0
        o = 0
        s = T / S
        break
    }
    this._degrees = n
    this._minutes = o
    this._seconds = s
    this._axis = a
    this._positive = l
  }
  getAxis() {
    return this._axis
  }
  getField(t, e) {
    switch (t) {
      case CONSTANTS.FIELD_SIGN:
        return this.getSign(e)
      case CONSTANTS.FIELD_AXIS:
        return this.getAxisChar()
      case CONSTANTS.FIELD_DEGREES:
        return e.format(this.getDegrees())
      case CONSTANTS.FIELD_MINUTES:
        return e.format(this.getMinutes())
      case CONSTANTS.FIELD_SECONDS:
        return e.format(this.getSeconds())
      default:
        throw new ProgrammingError(`Unknown field code: ${t}`)
    }
  }
  getSign(t) {
    return this._positive ? '' : '-'
  }
  getAxisChar() {
    if (this._axis === CONSTANTS.AXIS_LAT) return this._positive ? 'N' : 'S'
    else return this._positive ? 'E' : 'W'
  }
  getDegrees() {
    return this._degrees
  }
  getMinutes() {
    return this._minutes
  }
  getSeconds() {
    return this._seconds
  }
}
class OrdinateField {
  constructor(t, e, r) {
    this._ordinate = t
    this._field = e
    this._format = r
    this._roundingMode = CONSTANTS.ROUND_TRUNCATE
  }
  setRoundingMode(t) {
    this._roundingMode = t
  }
  getOrdinateValue(t) {
    if (t.getAxis() === this._ordinate)
      return t.getField(this._field, this._format, this._roundingMode)
    else return ''
  }
  getValue(t, e) {
    if (this._ordinate === CONSTANTS.AXIS_LAT)
      return e.getField(this._field, this._format, this._roundingMode)
    else return t.getField(this._field, this._format, this._roundingMode)
  }
}
class ConstantField {
  constructor(t) {
    this._value = t
  }
  getOrdinateValue() {
    return this._value
  }
  getValue(t, e) {
    return this._value
  }
}
ConstantField.prototype._value = null
class FormatFields {
  constructor(t, e, r) {
    this._fields = t
    this._content = e
    this._precision = r
  }
  getContent() {
    return this._content
  }
  getPrecision() {
    return this._precision
  }
  getFields() {
    return this._fields
  }
}
class LonLatPointFormat {
  static DEFAULT = 'lat(+DMS),lon(+DMS)'
  constructor(t) {
    t = t || {}
    this._decimalSymbols = null
    this._pattern = null
    this._fields = null
    this._defaultAxis1 = null
    this._defaultAxis2 = null
    this._lonFields = null
    this._lonContent = null
    this._lonPrecision = null
    this._latFields = null
    this._latContent = null
    this._latPrecision = null
    this._cachedTransformation = new CachingTransformation()
    this._groupingSeparator = ''
    this._decimalSeparator = t.decimalSeparator ? t.decimalSeparator : '.'
    this._setPattern(t.pattern)
  }
  _setPattern(t) {
    t = t || LonLatPointFormat.DEFAULT
    const e = new RegExp(CONSTANTS.LONLATPATTERN)
    if (!e.test(t)) throw new ProgrammingError(`Invalid pattern string: ${t}`)
    this._pattern = t
    const r = e.exec(t)
    const i =
      null !== r && 'lat' === r[1] ? CONSTANTS.AXIS_LAT : CONSTANTS.AXIS_LON
    const n = r[2]
    const o = r[3]
    const s =
      null !== r && 'lat' === r[4] ? CONSTANTS.AXIS_LAT : CONSTANTS.AXIS_LON
    const a = r[5]
    if (i === s) throw new ProgrammingError(`Invalid pattern string: ${t}`)
    const l = parseFields(i, n, t, this._decimalSeparator)
    const S = parseFields(s, a, t, this._decimalSeparator)
    this._separator = null
    let _ = []
    _ = _.concat(l.getFields())
    if (null !== o && o.length > 0) {
      this._separator = new ConstantField(o)
      _.push(this._separator)
    }
    _ = _.concat(S.getFields())
    let N
    let E
    if (i === CONSTANTS.AXIS_LON) {
      N = l
      E = S
    } else {
      N = S
      E = l
    }
    this._lonFields = N.getFields()
    this._lonContent = N.getContent()
    this._lonPrecision = N.getPrecision()
    this._latFields = E.getFields()
    this._latContent = E.getContent()
    this._latPrecision = E.getPrecision()
    this._fields = _
    this._defaultAxis1 = i
    this._defaultAxis2 = s
  }
  format(t, e) {
    return this._format(t, e)
  }
  _formatInternal() {
    let t = []
    let e = getReference('CRS:84')
    let r = createPoint(e, [])
    let i
    let n
    i = new FormattedOrdinate()
    n = new FormattedOrdinate()
    return (o, s) => {
      let a, l, S, _
      let N = 0
      if (o instanceof Shape) {
        _ = o.focusPoint
        this._cachedTransformation.transformToSFCT(_, e, r)
        N = r.x
        s = r.y
      } else if (!isNumber(o) || !isNumber(s))
        throw new ProgrammingError(
          'incorrect type of arguments: must be two numbers or single shape'
        )
      else N = o
      N = normalizeLon(N)
      s = normalizeLat(s)
      i.update(N, CONSTANTS.AXIS_LON, this._lonContent, this._lonPrecision)
      n.update(s, CONSTANTS.AXIS_LAT, this._latContent, this._latPrecision)
      t.length = 0
      const E = this._fields
      for (a = 0, l = this._fields.length; a < l; a++) {
        S = E[a].getValue(i, n)
        t.push(S)
      }
      return t.join('')
    }
  }
  _format = this._formatInternal()
  formatLat(t) {
    return this._formatLat(t)
  }
  _formatLat = (() => {
    const t = []
    const e = new FormattedOrdinate()
    return (r) => {
      let i, n, o
      r = normalizeLat(r)
      e.update(r, CONSTANTS.AXIS_LAT, this._latContent, this._latPrecision)
      t.length = 0
      i = this._latFields
      for (n = 0, o = i.length; n < o; n++) t.push(i[n].getOrdinateValue(e))
      return t.join('')
    }
  })()
  formatLon(t) {
    return this._formatLon(t)
  }
  _formatLon = (() => {
    const t = []
    const e = new FormattedOrdinate()
    return (r) => {
      let i, n, o
      r = normalizeLon(r)
      e.update(r, CONSTANTS.AXIS_LON, this._lonContent, this._lonPrecision)
      t.length = 0
      i = this._lonFields
      for (n = 0, o = i.length; n < o; n++) t.push(i[n].getOrdinateValue(e))
      return t.join('')
    }
  })()
}
export { LonLatPointFormat }
