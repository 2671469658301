import { Feature } from '../../model/feature/Feature.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import { FeaturePainter } from '../feature/FeaturePainter.js'
import { CollectingGeoAndLabelCanvas } from '../feature/photon/CollectingGeoAndLabelCanvas.js'
import { PhotonShapePainter } from '../feature/photon/PhotonShapePainter.js'
import { createLabelContext } from '../feature/LabelDrawingContext.js'
import { HTML5ControllerLabelCanvas } from '../style/HTML5ControllerLabelCanvas.js'
import { whenInternal } from '../../util/PromiseUtil.js'
const DummyScheduler = { when: (e, t, a) => whenInternal(e, t, a) }
function PhotonControllerRenderer(e, t, a, n, r) {
  this._valid = false
  this._isUpdating = false
  this._invalidator = n.invalidator
  this._controllerLayer = {
    _map: a,
    getMapToViewTransformation: () => a.mapToViewTransformation,
    fetchProvidedShape: (e) => e.shape,
    isSelected: () => false,
    isHovered: () => false,
    visible: true,
    visibleInTree: true,
    canDrawLabels: () => true,
    on: () => ({ remove: () => {} }),
    label: 'controller',
    id: 'controller',
  }
  this._shapePainter =
    r ||
    this.track(
      new PhotonShapePainter(
        this._controllerLayer,
        n.photonView,
        a.reference,
        n.photonGraphics,
        n.imageDecoder,
        n.referenceProvider,
        this._invalidator,
        n.gltfDecoder,
        n.shapeDiscretizer
      )
    )
  this._geoAndLabelCanvas = new CollectingGeoAndLabelCanvas()
  this._labelContext = createLabelContext(a, t)
  this._labelCanvas = new HTML5ControllerLabelCanvas({
    invalidateAction: this._invalidator.invalidate.bind(this._invalidator),
  })
  this._labelDrawCommand = null
  this._dummyFeature = new Feature(new XYZPoint(a.reference), {}, 1)
  this._lastFeature = this._dummyFeature
  this._controllerManager = e
  this._controllerManagerInvalidateListener = this._controllerManager.on(
    'Invalidated',
    () => {
      this._valid = false
      this._labelDrawCommand = null
      this._invalidator.invalidate()
    }
  )
}
PhotonControllerRenderer.prototype = Object.create(
  ObjectReleaseTracker.prototype
)
PhotonControllerRenderer.prototype.constructor = PhotonControllerRenderer
PhotonControllerRenderer.prototype._update = function () {
  if (this._isUpdating) return
  this._isUpdating = true
  this._controllerManager?.getInteractionUtil()?.invalidate()
  const e = this._controllerManager.getDomainLayer()
  if (e && e.panoramaModel)
    this._controllerLayer.panoramaModel = e.panoramaModel
  this._controllerManager.onDraw(this._geoAndLabelCanvas)
  this._controllerManager.onDrawLabel(this._geoAndLabelCanvas)
  const t = this._geoAndLabelCanvas.build()
  if (!t || 0 === t.length) {
    this._shapePainter.remove(this._dummyFeature, Promise)
    this._labelDrawCommand = null
    this._isUpdating = false
    return
  }
  const a = new FeaturePainter()
  a.paintBody = function (e, a, n, r, o, l) {
    for (let a = 0; a < t.length; a++) {
      const n = t[a]
      if ('icon' === n.type) e.drawIcon(n.shape, n.style)
      else if ('icon3d' === n.type) e.drawIcon3D(n.shape, n.style)
      else if ('shape' === n.type) e.drawShape(n.shape, n.style)
      else if ('text' === n.type) e.drawText(n.shape, n.text, n.style)
      else if ('panorama' === n.type)
        e.drawPanorama(n.location, n.style, n.context)
    }
  }
  a.paintLabel = function (e, a, n, r, o, l) {
    for (let a = 0; a < t.length; a++) {
      const n = t[a]
      if ('label' === n.type) e.drawLabel(n.text, n.shape, n.style)
      else if ('labelInPath' === n.type)
        e.drawLabelInPath(n.text, n.shape, n.style)
      else if ('labelOnPath' === n.type)
        e.drawLabelOnPath(n.text, n.shape, n.style)
    }
  }
  this._shapePainter.setPainter(a)
  const n = this._shapePainter.update(this._dummyFeature, DummyScheduler)
  whenInternal(n, () => {
    const e = this._shapePainter.createDrawCommand(this._dummyFeature)
    this._controllerManager.setDrawCommand(e)
    this._labelCanvas.resetLabelContext(this._labelContext, e, false)
    a.paintLabel(this._labelCanvas, null, null, null, null, null)
    const t = this._labelDrawCommand
    this._labelDrawCommand = this._labelCanvas.getDrawCommand()
    if (t) this._labelDrawCommand.reuseStateFrom(t)
    this._invalidator.invalidate()
    this._isUpdating = false
  })
}
PhotonControllerRenderer.prototype.paint = function (e, t, a, n, r) {
  if (!this._valid) {
    this._valid = true
    this._update()
  }
  const o =
    t !== Photon.PaintOpacity.Transparent &&
    a !== Photon.PaintDraping.OnTerrain &&
    a !== Photon.PaintDraping.OnMeshes &&
    !e &&
    n !== Photon.PaintOutput.DepthOnly &&
    n !== Photon.PaintOutput.ShadowDepthOnly
  if (this._labelDrawCommand && o) {
    this._labelContext.reset()
    this._labelDrawCommand.requestToDrawLabel()
  }
  this._shapePainter.clean()
  this._shapePainter.paint(e, t, a, n, r)
  return true
}
PhotonControllerRenderer.prototype.release = function () {
  ObjectReleaseTracker.prototype.release.call(this)
  if (this._controllerManagerInvalidateListener)
    this._controllerManagerInvalidateListener.remove()
}
export { PhotonControllerRenderer }
