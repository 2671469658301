import { GeoReference } from '../reference/GeoReference.js'
import { HeightReferenceType } from '../reference/HeightReference.js'
import { createTransformation as createActualTransformation } from '../transformation/TransformationFactory.js'
import { FlattenTransformation } from '../transformation/FlattenTransformation.js'
import {
  createCartesianGeodesy,
  createEllipsoidalGeodesy,
} from '../geodesy/GeodesyFactory.js'
import { CoordinateType } from '../reference/CoordinateType.js'
const txCache = {}
let lastTx = null
function createKey(e) {
  let t = e.identifier || e.wkt
  if (null !== t) {
    const r = undefined
    if (
      e instanceof GeoReference &&
      e.heightReference &&
      e.heightReference.identifier === HeightReferenceType.ABOVE_TERRAIN
    )
      t += ' (above terrain)'
  }
  return t
}
export function getTransformation(e, t) {
  let r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : false
  if (
    lastTx &&
    lastTx.tx &&
    lastTx.fromRef === e &&
    lastTx.toRef === t &&
    lastTx.zToZero === r
  )
    return lastTx.tx
  const n = createKey(e)
  const o = createKey(t)
  const a = undefined
  const i = getAndCache(
    txCache,
    n && o ? `${n}->${o}${r ? '(z=0)' : ''}` : null,
    function () {
      return createTransformation(e, t, r)
    }
  )
  lastTx = { fromRef: e, toRef: t, zToZero: r, tx: i }
  return lastTx.tx
}
function createTransformation(e, t, r) {
  const n = createActualTransformation(e, t)
  return r ? new FlattenTransformation(n, 0) : n
}
const txGeodesy = {}
export function getGeodesy(e) {
  const t = e.identifier || e.wkt
  return getAndCache(txGeodesy, t, function () {
    return createGeodesy(e)
  })
}
function createGeodesy(e) {
  return e.coordinateType === CoordinateType.CARTESIAN
    ? createCartesianGeodesy(e)
    : createEllipsoidalGeodesy(e)
}
function getAndCache(e, t, r) {
  if (!t) return r()
  let n = e[t]
  if (!n) {
    n = r()
    e[t] = n
  }
  return n
}
