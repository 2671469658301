import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { createZeroElevation } from '../../model/tileset/DummyTileSetModel.js'
import { Grid } from '../../model/tileset/Grid.js'
import { RasterDataType } from '../../model/tileset/RasterDataType.js'
import { RasterSamplingMode } from '../../model/tileset/RasterSamplingMode.js'
import { RasterTileSetModel } from '../../model/tileset/RasterTileSetModel.js'
import { isQuadTreeRasterTileSetModel } from '../../model/tileset/RasterTileSetModelUtil.js'
import { ExpressionResolver } from '../../util/expression/ExpressionResolver.js'
import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import * as LayerUtil from '../LayerUtil.js'
import { PhotonTileSetModelAdapter } from './PhotonTileSetModelAdapter.js'
import { DrapeTarget } from '../style/DrapeTarget.js'
import { PhotonCommandUtil } from '../feature/photon/command/PhotonCommandUtil.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { Log } from '../../util/Log.js'
export class PhotonTerrainPainter {
  _objectTracker = new ObjectReleaseTracker()
  _opacity = 1
  _displacementExpression = null
  _activeDisplacementExpression = null
  _resolvedDisplacementExpression = null
  _aboveTerrain = true
  _imageModel = null
  _elevationModel = null
  _imageTileSetAdapter = null
  _elevationTileSetAdapter = null
  _terrainPainter = null
  _ready = true
  _globeColor = { r: 0.8, g: 0.8, b: 0.8, a: 1 }
  _levelSwitchFactorInternal = 0.5
  _drapeTarget = DrapeTarget.NOT_DRAPED
  constructor(e, t, r, i, a, s, o, n, l) {
    this._photonView = t
    this._photonGraphics = r
    this._textureDecoder = i
    this._imageDecoder = a
    this._geometryDecoder = s
    this._photonReferenceProvider = o
    this.isBaseTerrain = n
    this._layer = l
    this._worldReference = e
    this._isBaseTerrain = n
    this._label = LayerUtil.getLabel(this._layer, 'base')
    this._invalidClip = {
      x: 0,
      y: 0,
      z: 0,
      width: 0,
      height: 0,
      depth: 0,
      valid: 0,
    }
    if (this._label && this._label._levelSwitchFactorInternal)
      this._levelSwitchFactorInternal = this._layer._levelSwitchFactorInternal
    this.reset()
  }
  release() {
    this._objectTracker.untrack(this._terrainPainter)
    this._objectTracker.release()
    this._terrainPainter = null
  }
  set enabled(e) {
    if (this._terrainPainter) this._terrainPainter.setEnabled(e)
  }
  set globeColor(e) {
    this._globeColor = e
  }
  get globeColor() {
    return this._globeColor
  }
  get ready() {
    return this._ready
  }
  set opacity(e) {
    const t = this._opacity
    this._opacity = e
    if (this._terrainPainter && t !== e) this._terrainPainter.setOpacity(e)
  }
  set displacementExpression(e) {
    this._displacementExpression = e
  }
  get drapeTarget() {
    return this._drapeTarget
  }
  set drapeTarget(e) {
    const t = this._drapeTarget
    this._drapeTarget = e
    if (this._terrainPainter && e !== t)
      this._terrainPainter.setDrapeTarget(
        PhotonCommandUtil.toPhotonDrapeTarget(e)
      )
  }
  get imageModel() {
    return this._imageModel
  }
  set imageModel(e) {
    if (this._imageModel == e) return
    if (e) this._assertCompatible(e)
    this._imageModel = e
    if (this._terrainPainter) {
      const t = e
        ? new PhotonTileSetModelAdapter(
            e,
            this._textureDecoder,
            this._imageDecoder,
            this._photonReferenceProvider
          )
        : null
      if (this._terrainPainter.resetImageTileSet(t)) {
        this._imageTileSetAdapter = this._objectTracker.untrack(
          this._imageTileSetAdapter
        )
        this._imageTileSetAdapter = this._objectTracker.track(t)
        return
      }
    }
    this.reset()
  }
  get elevationModel() {
    return this._elevationModel
  }
  set elevationModel(e) {
    if (this._elevationModel == e) return
    if (e) this._assertCompatible(e)
    this._elevationModel = e
    this.reset()
  }
  isReady() {
    return this._ready
  }
  startResolutionFactorIsNotHit() {
    if (this._terrainPainter)
      return this._terrainPainter.startResolutionFactorIsNotHit()
    return false
  }
  invalidate(e, t) {
    if (this._terrainPainter) this._terrainPainter.invalidate(e, t)
    if (e) this._imageTileSetAdapter?.clearPendingTiles()
    if (t) this._elevationTileSetAdapter?.clearPendingTiles()
    return true
  }
  isCompatible(e) {
    if (!(e instanceof RasterTileSetModel))
      return 'LuciadRIA WebGLMap only supports RasterTileSetModels'
    const t = e
    if (t.reference.equals(this._worldReference)) return true
    if (e.dataType !== RasterDataType.IMAGE) {
      const e = undefined
      if (!isQuadTreeRasterTileSetModel(t))
        return 'LuciadRIA WebGLMap currently only supports power-of-two quad-tree tile sets for non-image data types.'
    }
    return true
  }
  setEnabled(e) {
    this.enabled = e
  }
  getGridCoordinates(e) {
    const t = []
    if (!this._terrainPainter) return []
    let r
    try {
      if (this._imageModel == e)
        r = this._terrainPainter.getTileCoordinates(false)
      else if (this._elevationModel == e)
        r = this._terrainPainter.getTileCoordinates(true)
      else return []
      const i = r.size / 3
      for (let e = 0; e < i; e++) {
        const i = new Grid()
        i.level = r.typedArray[3 * e]
        i.x = r.typedArray[3 * e + 1]
        i.y = r.typedArray[3 * e + 2]
        i.width = 1
        i.height = 1
        t.push(i)
      }
    } finally {
      if (r) r.release()
    }
    return t
  }
  update(e) {
    if (!this._terrainPainter) {
      this._ready = true
      return this._ready
    }
    if (this._activeDisplacementExpression !== this._displacementExpression) {
      this._activeDisplacementExpression = this._displacementExpression
      this._objectTracker.untrack(this._resolvedDisplacementExpression)
      if (null != this._activeDisplacementExpression) {
        this._resolvedDisplacementExpression = this._objectTracker.track(
          ExpressionResolver.resolve(
            this._activeDisplacementExpression,
            null,
            null,
            void 0,
            () => {}
          )
        )
        this._terrainPainter.setDisplacementExpression(
          this._resolvedDisplacementExpression
        )
      } else this._terrainPainter.setDisplacementExpression(null)
    }
    const t = this._aboveTerrain
    this._aboveTerrain = true == e.mapNavigator.constraints.above?.terrain
    if (this._aboveTerrain !== t)
      this._terrainPainter.setAboveTerrainConstraintEnabled(this._aboveTerrain)
    this._ready = this._terrainPainter.update(this._photonGraphics)
    return this._ready
  }
  paint(e, t, r, i) {
    if (this._terrainPainter)
      this._terrainPainter.paint(this._photonGraphics, false, e, t, r, i)
    return true
  }
  _assertCompatible(e) {
    const t = this.isCompatible(e)
    if (true !== t) throw new ProgrammingError(t)
  }
  reset() {
    this._ready = false
    this._terrainPainter = this._objectTracker.untrack(this._terrainPainter)
    this._imageTileSetAdapter = this._objectTracker.untrack(
      this._imageTileSetAdapter
    )
    this._elevationTileSetAdapter = this._objectTracker.untrack(
      this._elevationTileSetAdapter
    )
    if (!this._imageModel && !this._photonView.is3D()) return
    if (this._worldReference.referenceType == ReferenceType.CARTESIAN)
      if (!this._imageModel) return
      else if (!this._worldReference.equals(this._imageModel.reference)) {
        Log.warn(
          'To visualize imagery in a cartesian view both the world reference and reference must be equal.'
        )
        return
      }
    if (this._imageModel)
      this._imageTileSetAdapter = this._objectTracker.track(
        new PhotonTileSetModelAdapter(
          this._imageModel,
          this._textureDecoder,
          this._imageDecoder,
          this._photonReferenceProvider
        )
      )
    let e = false
    if (this._elevationModel && this._photonView.is3D())
      this._elevationTileSetAdapter = this._objectTracker.track(
        new PhotonTileSetModelAdapter(
          this._elevationModel,
          this._textureDecoder,
          this._imageDecoder,
          this._photonReferenceProvider
        )
      )
    else {
      const t = createZeroElevation(this._imageTileSetAdapter?.tileSet)
      this._elevationTileSetAdapter = this._objectTracker.track(
        new PhotonTileSetModelAdapter(
          t,
          this._textureDecoder,
          this._imageDecoder,
          this._photonReferenceProvider
        )
      )
      e = true
    }
    const t = this._elevationModel?.samplingMode == RasterSamplingMode.AREA
    const r =
      this._elevationModel?.modelDescriptor?.type ===
      RasterDataType.TIN_ELEVATION
    const i = this._elevationTileSetAdapter
    const a = this._imageTileSetAdapter
    const s = undefined
    const o =
      this._photonView.is3D() && this._isBaseTerrain && null != this.globeColor
        ? this.globeColor
        : { r: 0, g: 0, b: 0, a: 0 }
    if (!this._isBaseTerrain || o.a > 0) {
      this._terrainPainter = this._objectTracker.track(
        Photon.TerrainPainter.create(
          this._photonView,
          this._photonGraphics,
          i,
          a,
          o,
          this._isBaseTerrain,
          e,
          !t,
          r,
          LayerUtil.formatLabelForPhoton(this._label)
        )
      )
      this._terrainPainter.setDetailFactor(2 * this._levelSwitchFactorInternal)
      this._terrainPainter.setOpacity(this._opacity)
      this._terrainPainter.setDisplacementExpression(
        this._resolvedDisplacementExpression
      )
      this._terrainPainter.setAboveTerrainConstraintEnabled(this._aboveTerrain)
      const s = this._terrainPainter.getTileDiscretizationParameters()
      if (this._terrainPainter.canHandleGeometryTiles()) {
        const e = i.jsGeoReference
        const t = this._worldReference
        i.setImageDecoder({
          decodeUrl: (r, a) => {
            const o = i.getTileBounds(a)
            const n = i.getTileCountY(a.level) ?? 0
            const l = a.y === n - 1
            const h = 0 === a.y
            return this._geometryDecoder.decodeUrl(r, e, t, o, s, l, h, a.level)
          },
          decodeArrayBuffer: (r, a, o) => {
            const n = i.getTileBounds(o)
            const l = i.getTileCountY(o.level) ?? 0
            const h = o.y === l - 1
            const d = 0 === o.y
            return this._geometryDecoder.decodeArrayBuffer(
              r,
              a,
              e,
              t,
              n,
              s,
              h,
              d,
              o.level
            )
          },
          decodeRaw: (r, a) => {
            const o = i.getTileBounds(a)
            const n = i.getTileCountY(a.level) ?? 0
            const l = a.y === n - 1
            const h = 0 === a.y
            return this._geometryDecoder.decodeRaw(r, e, t, o, s, l, h, a.level)
          },
          decodeMesh: (r, a) => {
            const s = i.getTileBounds(a)
            return this._geometryDecoder.decodeMesh(r, e, t, s, a.level)
          },
        })
      } else if (s.resampleAreaToPoint)
        i.setImageDecoder({
          decodeUrl: (e, t, r, i) =>
            this._imageDecoder.decode(e, t, r, i).then(convertToPointImage),
          decodeArrayBuffer: (e, t, r) => {
            const i = undefined
            return convertToPointImage(
              this._imageDecoder.decodeArrayBuffer(e, t, r)
            )
          },
        })
      else i.setImageDecoder(this._imageDecoder)
    }
  }
}
function convertToPointImage(e) {
  const t =
    Photon.TileDiscretizer.createPointSampledImageFromAreaSampledImage(e)
  e.release()
  return t
}
