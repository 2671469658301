import { ComposedShapeEditor } from './ComposedShapeEditor.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { EditContext } from '../../controller/EditContext.js'
import { interactsWithControllerShape } from '../EditHandle.js'
import {
  removeNullHandles,
  viewToModelOnHeightAxis,
} from '../handles/HandleUtil.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { createPoint } from '../../../shape/ShapeFactory.js'
import { ExtrudedShapeHelperHandle } from '../handles/helper/ExtrudedShapeHelperHandle.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { HandleEventResult } from '../../controller/HandleEventResult.js'
import { ShapeTouchHandle } from '../handles/ShapeTouchHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { EditMoveConstraint } from '../handles/EditMoveConstraint.js'
import { getInteractionRadius } from '../../controller/EditSettings.js'
export class ExtrudedShapeEditor extends ComposedShapeEditor {
  constructor(e) {
    super(ShapeType.EXTRUDED_SHAPE, e)
  }
  getSubShapeCount(e) {
    return 1
  }
  getSubShape(e, t) {
    return e.baseShape
  }
  getEditHandles(e) {
    const t = this.createBaseShapeHandles(e)
    return removeNullHandles([
      ...t,
      this.createMinHeightHandle(e),
      this.createMaxHeightHandle(e),
      this.createHeightHandle(e),
      this.createHelperHandle(e),
    ])
  }
  createMinHeightHandle(e) {
    if (is3DMap(e)) {
      const t = e.shape
      return new PointDragHandle(
        () => getPointOnHeightAxis(t, 0),
        (e) => {
          if (e.z < t.maximumHeight) t.minimumHeight = e.z
        },
        { moveConstraint: EditMoveConstraint.Z }
      )
    }
    return null
  }
  createMaxHeightHandle(e) {
    if (is3DMap(e)) {
      const t = e.shape
      return new PointDragHandle(
        () => getPointOnHeightAxis(t, 1),
        (e) => {
          if (e.z > t.minimumHeight) t.maximumHeight = e.z
        },
        { moveConstraint: EditMoveConstraint.Z }
      )
    }
    return null
  }
  createHeightHandle(e) {
    if (is3DMap(e)) return new ExtrudedShapeHeightBodyHandle(e.shape)
    return null
  }
  createHelperHandle(e) {
    if (is3DMap(e)) return new ExtrudedShapeHelperHandle(e.shape)
    return null
  }
  createBaseShapeHandles(e) {
    return super.getEditHandles(e)
  }
  createTranslateHandle(e) {
    const t = e.shape
    const r = is3DMap(e)
      ? new EditContext(e.map, e.layer, e.feature, t.baseShape, e.settings)
      : e
    return super.createTranslateHandle(r)
  }
  getCreateHandle(e) {
    return null
  }
}
class ExtrudedShapeHeightBodyHandle extends ShapeTouchHandle {
  constructor(e) {
    super()
    this._extrudedShape = e
    this._dragStartPoint = null
    this._minHeightAtStart = 0
    this._maxHeightAtStart = 0
  }
  get extrudedShape() {
    return this._extrudedShape
  }
  getCursor(e, t) {
    if (this.active || this.interacts(e, t)) return 'ns-resize'
    return null
  }
  interacts(e, t) {
    const r = getInteractionRadius(e, t)
    const { x: i, y: a } = e.viewPoint
    return (
      interactsWithControllerShape(t.map, i, a, r, this.extrudedShape) &&
      !interactsWithControllerShape(
        t.map,
        i,
        a,
        r,
        this.extrudedShape.baseShape
      )
    )
  }
  process(e, t) {
    const r = viewToModelOnHeightAxis(
      e.viewPoint,
      t,
      this.extrudedShape.baseShape.focusPoint
    )
    if (r) {
      this.drag(r, e, t)
      this.emitEditShapeEvent(this.extrudedShape, EditShapeStatus.IN_PROGRESS)
    }
    return HandleEventResult.EVENT_HANDLED
  }
  drag(e, t, r) {
    if (!this._dragStartPoint) {
      this._dragStartPoint = e
      this._minHeightAtStart = this.extrudedShape.minimumHeight
      this._maxHeightAtStart = this.extrudedShape.maximumHeight
    }
    const i = e.z - this._dragStartPoint.z
    this.extrudedShape.minimumHeight = Math.max(0, this._minHeightAtStart + i)
    const a =
      this.extrudedShape.maximumHeight - this.extrudedShape.minimumHeight
    this.extrudedShape.maximumHeight = Math.max(a, this._maxHeightAtStart + i)
  }
  deactivate(e, t) {
    this._dragStartPoint = null
    this._minHeightAtStart = 0
    this._maxHeightAtStart = 0
    return super.deactivate(e, t)
  }
}
function getPointOnHeightAxis(e, t) {
  const r = e.baseShape.focusPoint
  const i = lerp(e.minimumHeight, e.maximumHeight, t)
  return createPoint(e.reference, [r.x, r.y, i])
}
function lerp(e, t, r) {
  if (1 === r) return t
  else if (0 === r) return e
  return e + r * (t - e)
}
function is3DMap(e) {
  return e.map.reference.referenceType === ReferenceType.GEOCENTRIC
}
