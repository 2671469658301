import { ProgrammingError } from '../../error/ProgrammingError.js'
function CGShape(e, t, n) {
  this._elements = []
  this._originalFilledShapes = []
  this._bounds = null
}
CGShape.prototype = Object.create(Object.prototype)
CGShape.prototype.constructor = CGShape
Object.defineProperty(CGShape.prototype, 'elements', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._elements
  },
  set: function () {
    throw new ProgrammingError('elements property is not mutable')
  },
})
Object.defineProperty(CGShape.prototype, 'vertexCount', {
  configurable: false,
  enumerable: true,
  get: function () {
    var e = 0
    for (var t = 0; t < this._elements.length; t++) {
      var n = true
      for (
        var r = this._elements[t];
        null != r && (n || r != this._elements[t]);
        r = r.next
      ) {
        e++
        n = false
      }
    }
    return e
  },
  set: function () {
    throw new ProgrammingError('vertexCount property is not mutable')
  },
})
CGShape.prototype.addOriginalFilledShape = function (e) {
  this._originalFilledShapes.push(e)
  this._bounds = null
}
CGShape.prototype.filledContains2D = function (e, t) {
  for (var n = 0; n < this._originalFilledShapes.length; n++)
    if (t.contains2D(this._originalFilledShapes[n], e)) return true
  return false
}
CGShape.prototype.calculateBounds = function (e) {
  var t = null
  for (var n = 0; n < this._elements.length; n++) {
    var r = true
    for (
      var o = this._elements[n];
      null != o && (r || o != this._elements[n]);
      o = o.next
    ) {
      r = false
      if (null == t) t = e.getNewEditableBoundsFromPoint(o.pos)
      if (null === o.next) t.setToIncludePoint2D(o.pos)
      else {
        const n = e.getNewEditablePolyline(o.pos.reference, [
          o.getVertexPointCoordinates(),
          o.next.getVertexPointCoordinates(),
        ])
        t.setTo2DUnion(n.bounds)
      }
    }
  }
  return t
}
CGShape.prototype.getBounds = function (e) {
  if (null === this._bounds) this._bounds = this.calculateBounds(e)
  return this._bounds
}
CGShape.prototype.resetBounds = function () {
  this._bounds = null
}
export { CGShape }
