import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
export class CircularArc extends Shape {
  get type() {
    return ShapeType.CIRCULAR_ARC
  }
  move2DToCoordinates(e, t) {
    const r = e - this.focusPoint.x
    const o = t - this.focusPoint.y
    this.translate2D(r, o)
  }
  move2DToPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    this.move2DToCoordinates(e.x, e.y)
  }
  toString() {
    return `CircularArc[center[${this.center.toString()}],radius[${
      this.radius
    }],startAzimuth[${this.startAzimuth}],sweepAngle[${this.sweepAngle}]]`
  }
}
export function isCircularArc(e) {
  return ShapeType.contains(ShapeType.CIRCULAR_ARC, e.type)
}
