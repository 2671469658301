import { KMLFeature } from '../../model/kml/KMLFeature.js'
import { resolveHref } from '../../util/kml/KMLInternalUtil.js'
import { isString, isUndefined } from '../../util/Lang.js'
import { FeatureLayer } from '../feature/FeatureLayer.js'
import { KMLPainter } from './KMLPainter.js'
import { KMLIsHoverableEvent } from '../../model/kml/KMLEvented.js'
import { KMLModel } from '../../model/kml/KMLModel.js'
import { DrapeTarget } from '../style/DrapeTarget.js'
const IMG_REGEX = /<img[^>]*>/gi
const SRC_REGEX = /src=["'](.*?)["']/gi
export class KMLLayer extends FeatureLayer {
  constructor(e) {
    let r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    const t = { ...r }
    if (!r.painter) t.painter = new KMLPainter()
    super(e, t)
    this._shouldTraceHoverability = isUndefined(r.hoverable)
    if (this._shouldTraceHoverability && e instanceof KMLModel) {
      const r = e.on(KMLIsHoverableEvent, (e) => {
        if (this._shouldTraceHoverability) this.hoverable = e
        else r.remove()
      })
    }
    this.balloonContentProvider = balloonContentProviderFactory(e)
    this._drapeTarget = r.drapeTarget ?? DrapeTarget.TERRAIN
  }
  get model() {
    return super.model
  }
  get drapeTarget() {
    return this._drapeTarget
  }
  get hoverable() {
    return super.hoverable
  }
  set hoverable(e) {
    this._shouldTraceHoverability = false
    super.hoverable = e
  }
}
function balloonContentProviderFactory(e) {
  const r = (r) => r.replace(SRC_REGEX, replaceSrcFactory(e))
  return (e) => {
    if (!(e instanceof KMLFeature)) return ''
    const t = getDescription(e)
    return isString(t) ? t.replace(IMG_REGEX, r) : ''
  }
}
function getDescription(e) {
  if (e instanceof KMLFeature) return e.properties.description
  if (!e) return null
  const { properties: r } = e
  if (r) {
    const { description: e } = r
    if (isString(e)) return r.description
  }
  return null
}
function replaceSrcFactory(e) {
  return (r, t) => {
    const { target: o } = e.store
    if (!o) return ''
    return `src="${resolveHref(o, t, false)}"`
  }
}
