import { ProgrammingError } from '../../error/ProgrammingError.js'
import { NotImplementedError } from '../../error/NotImplementedError.js'
import { CGSegmentIntersector } from './CGSegmentIntersector.js'
function ConstructiveGeometryOperator(t) {
  this._topologyUtil = t
  this._intersector = new CGSegmentIntersector(this._topologyUtil)
}
ConstructiveGeometryOperator.prototype = Object.create(Object.prototype)
ConstructiveGeometryOperator.prototype.constructor =
  ConstructiveGeometryOperator
Object.defineProperty(ConstructiveGeometryOperator.prototype, 'intersector', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._intersector
  },
  set: function () {
    throw new ProgrammingError('intersector property is not mutable')
  },
})
Object.defineProperty(ConstructiveGeometryOperator.prototype, 'topologyUtil', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._topologyUtil
  },
  set: function () {
    throw new ProgrammingError('topologyUtil property is not mutable')
  },
})
ConstructiveGeometryOperator.prototype.operate = function (t, r, e) {
  throw new NotImplementedError()
}
ConstructiveGeometryOperator.prototype.supportsShapes = function (t) {
  throw new NotImplementedError()
}
export { ConstructiveGeometryOperator }
