import { ProgrammingError } from '../../error/ProgrammingError.js'
import { DecimalFormat } from '../../shape/format/DecimalFormat.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
import { hasProperty, isArray, isObject } from '../../util/Lang.js'
import { HTML5DrawStrokeCommandProto } from '../style/HTML5DrawStrokeCommandProto.js'
import { HTML5TextUtil } from '../style/HTML5TextUtil.js'
import { normalizeLineStyle, normalizeTextStyle } from '../style/StyleUtil.js'
const tmpCoordinatesAxisX = [1, 1, 1, 1]
const tmpCoordinatesAxisY = [1, 1, 1, 1]
const tmpCoordinates = [1, 1, 1, 1]
const decimalFormat = new DecimalFormat({})
const tmpOut = new XYZPoint()
const xyzPoint = new XYZPoint()
const AXIS_X = 0
const AXIS_Y = 1
const transformX = (t, i) => {
  xyzPoint.x = i
  t.transform(xyzPoint, tmpOut)
  return tmpOut.x
}
const transformY = (t, i) => {
  xyzPoint.y = i
  t.transform(xyzPoint, tmpOut)
  return tmpOut.y
}
function powerOfTenArray(t) {
  const i = []
  for (let s = 0; s < t; s += 1) i[s] = Math.pow(10, s)
  return i
}
const powerOfTenSpacings = powerOfTenArray(10)
const DEFAULT_X_AXIS = {
  axisLineStyle: { color: 'rgb(0,0,0)', width: 1 },
  gridLine: false,
  spacing: { minimumTickSpacing: 50, mapSpacing: powerOfTenSpacings },
  tickLength: 8,
  subTicks: 4,
  subTickLength: 4,
  tickLineStyle: { width: 1, color: 'rgb(0,0,0)' },
  labelFormatter: (t) => decimalFormat.format(t),
  labelStyle: {
    fill: 'rgb(0,0,0)',
    strokeWidth: 1,
    font: '10px sans-serif',
    offsetX: 0,
    offsetY: 10,
    textAnchor: 'center',
    alignmentBaseline: 'top',
  },
  labelRotation: 0,
}
const DEFAULT_Y_AXIS = copyAxisProps(
  {
    labelRotation: 0,
    spacing: { minimumTickSpacing: 20 },
    labelStyle: {
      offsetX: -10,
      offsetY: 0,
      textAnchor: 'end',
      alignmentBaseline: 'middle',
    },
  },
  DEFAULT_X_AXIS
)
function copyAxisProps() {
  let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
  let i = arguments.length > 1 ? arguments[1] : void 0
  for (const s in i) {
    const e = hasProperty(i, s) ? i[s] : void 0
    const o = hasProperty(t, s) ? t[s] : void 0
    if (isObject(e) && !isArray(e)) t[s] = copyAxisProps(o, e)
    else if (void 0 === o) t[s] = i[s]
  }
  return t
}
function normalizeAxisDefinition(t, i) {
  t = copyAxisProps({}, t)
  return copyAxisProps(t, i)
}
function normalizeQuantityArray(t) {
  ;(t = t.filter((t) => t > 0)).sort((t, i) => t - i)
  if (t.length < 1)
    throw new ProgrammingError(
      'Invalid map spacings. Should at least have 1 strictly positive value'
    )
  return t
}
function traceSubTick(t, i, s, e, o) {
  t.moveTo(i, s)
  t.lineTo(e, o)
}
function clampValueForSharpLine(t, i) {
  return (i || 1) % 2 ? clampValueForOddWidth(t) : clampValueForEvenWidth(t)
}
function clampValueForOddWidth(t) {
  return Math.round(t + 0.5) - 0.5
}
function clampValueForEvenWidth(t) {
  return Math.round(t)
}
function findTickSpacing(t, i, s, e) {
  let o = 0
  let n = true
  for (let r = 0, a = e.length; r < a && n; r += 1) {
    const a = e[r]
    o = a
    const c = i === AXIS_X ? t._toViewXDistance(a) : t._toViewYDistance(a)
    n = Math.abs(c) < s
  }
  return o
}
export class AxisRenderer extends HTML5DrawStrokeCommandProto {
  constructor(t, i) {
    super()
    t = t || {}
    this._xAxis = normalizeAxisDefinition(t.xAxis || {}, DEFAULT_X_AXIS)
    this._yAxis = normalizeAxisDefinition(t.yAxis || {}, DEFAULT_Y_AXIS)
    this.__xGridLine = this._xAxis.gridLine || false
    this.__xLabelFormat = { format: this._xAxis.labelFormatter }
    this.__xTickLength = this._xAxis.tickLength
    this.__xSubTickLength = this._xAxis.subTickLength
    this.__xLabelRotation = this._xAxis.labelRotation
    this.__xLabelStyle = normalizeTextStyle(this._xAxis.labelStyle)
    this.__yGridLine = this._yAxis.gridLine
    this.__yLabelFormat = { format: this._yAxis.labelFormatter }
    this.__yTickLength = this._yAxis.tickLength
    this.__ySubTickLength = this._yAxis.subTickLength
    this.__yLabelRotation = this._yAxis.labelRotation
    this.__yLabelStyle = normalizeTextStyle(this._yAxis.labelStyle)
    this.__xAxisLineStyle = normalizeLineStyle(this._xAxis.axisLineStyle)
    this.__yAxisLineStyle = normalizeLineStyle(this._yAxis.axisLineStyle)
    this.__xTickLineStyle = normalizeLineStyle(this._xAxis.tickLineStyle)
    this.__yTickLineStyle = normalizeLineStyle(this._yAxis.tickLineStyle)
    this.__xSpacings = normalizeQuantityArray(this._xAxis.spacing.mapSpacing)
    this.__ySpacings = normalizeQuantityArray(this._yAxis.spacing.mapSpacing)
    this._map = i
  }
  __traceYTick(t, i, s, e, o) {
    const n = clampValueForSharpLine(i, this.__yTickLineStyle.width)
    const r = this.__yLabelFormat.format(s)
    this._traceTick(
      t,
      r,
      e,
      n,
      e - this.__yTickLength,
      n,
      this.__yLabelRotation,
      this.__yLabelStyle,
      o + e,
      n,
      this.__yGridLine
    )
  }
  __traceYSubTick(t, i, s) {
    const e = clampValueForSharpLine(i, this.__yTickLineStyle.width)
    traceSubTick(t, s, e, s - this.__ySubTickLength, e)
  }
  __traceXTick(t, i, s, e) {
    const o = clampValueForSharpLine(i, this.__xTickLineStyle.width)
    const n = this.__xLabelFormat.format(s)
    this._traceTick(
      t,
      n,
      o,
      e,
      o,
      e + this.__xTickLength,
      this.__xLabelRotation,
      this.__xLabelStyle,
      o,
      0,
      this.__xGridLine
    )
  }
  __traceXSubTick(t, i, s) {
    const e = clampValueForSharpLine(i, this.__xTickLineStyle.width)
    traceSubTick(t, e, s, e, s + this.__xSubTickLength)
  }
  __drawAxis(t, i, s, e, o, n, r, a) {
    const c = e - n - (e % n)
    const _ = o + n - (o % n)
    t.beginPath()
    for (let t = c; t < _; t += n) {
      i(t)
      const e = r + 1
      const o = n / e
      for (let i = 0, n = t; i < e; i += 1) {
        n = t + i * o
        s(n)
      }
    }
    this._storeStrokeSettings(a)
    this._stroke(t)
  }
  __drawYAxis(t, i, s, e, o, n) {
    const r = o.coordinates[2]
    const a = r + o.coordinates[3]
    const c = (o) => {
      const r = transformY(n, o)
      if (r <= i) this.__traceYTick(t, r, o, s, e[0])
    }
    const _ = (e) => {
      const o = transformY(n, e)
      if (o <= i) this.__traceYSubTick(t, o, s)
    }
    const { spacing: l, subTicks: h } = this._yAxis
    const m = findTickSpacing(n, AXIS_Y, l.minimumTickSpacing, this.__ySpacings)
    this.__drawAxis(t, c, _, r, a, m, h, this.__yTickLineStyle)
  }
  __drawXAxis(t, i, s, e, o, n) {
    const r = o.coordinates[0]
    const a = r + o.coordinates[1]
    const c = (e) => {
      const o = transformX(n, e)
      if (o >= s) this.__traceXTick(t, o, e, i)
    }
    const _ = (e) => {
      const o = transformX(n, e)
      if (o >= s) this.__traceXSubTick(t, o, i)
    }
    const { spacing: l, subTicks: h } = this._xAxis
    const m = findTickSpacing(n, AXIS_X, l.minimumTickSpacing, this.__xSpacings)
    this.__drawAxis(t, c, _, r, a, m, h, this.__xTickLineStyle)
  }
  _paint(t) {
    const i = this._map.mapBounds
    const { totalSize: s, border: e, viewSize: o } = this._map
    const n = s[1] - e.bottom
    const r = e.left
    const a = s[0]
    const c = e.top
    this.__drawAxisLines(t, r, c, a, n)
    const _ = this._map.mapToViewTransformationInternal
    this.__drawXAxis(t, n, r, o, i, _)
    this.__drawYAxis(t, n, r, o, i, _)
    return true
  }
  __drawAxisLines(t, i, s, e, o) {
    t.beginPath()
    let n = clampValueForSharpLine(o, this.__xAxisLineStyle.width)
    tmpCoordinatesAxisX[0] = i
    tmpCoordinatesAxisX[1] = n
    tmpCoordinatesAxisX[2] = e
    tmpCoordinatesAxisX[3] = n
    this._storeStrokeSettings(this.__xAxisLineStyle)
    this._tracePathNoClose(t, tmpCoordinatesAxisX)
    this._stroke(t)
    t.beginPath()
    n = clampValueForSharpLine(i, this.__yAxisLineStyle.width)
    tmpCoordinatesAxisY[0] = n
    tmpCoordinatesAxisY[1] = s
    tmpCoordinatesAxisY[2] = n
    tmpCoordinatesAxisY[3] = o
    this._storeStrokeSettings(this.__yAxisLineStyle)
    this._tracePathNoClose(t, tmpCoordinatesAxisY)
    this._stroke(t)
  }
  _traceTick(t, i, s, e, o, n, r, a, c, _, l) {
    tmpCoordinates[0] = s
    tmpCoordinates[1] = e
    tmpCoordinates[2] = o
    tmpCoordinates[3] = n
    this._tracePathNoClose(t, tmpCoordinates)
    if (l) {
      tmpCoordinates[0] = s
      tmpCoordinates[1] = e
      tmpCoordinates[2] = c
      tmpCoordinates[3] = _
      this._tracePathNoClose(t, tmpCoordinates)
    }
    HTML5TextUtil.drawText(t, i, s, e, r, a, 1)
  }
}
