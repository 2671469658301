import { ShapeType } from '../../../shape/ShapeType.js'
import { Editor } from '../Editor.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultSize,
} from '../handles/CreateByTemplateHandle.js'
import { CompositeEditHandle } from '../handles/CompositeEditHandle.js'
import { EllipseAxisHelperHandle } from '../handles/helper/EllipseAxisHelperHandle.js'
import { ArcHelperHandle } from '../handles/helper/ArcHelperHandle.js'
export class ArcEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.ARC, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createMajorAxisHandle(e),
      this.createMajorAxisOppositeHandle(e),
      this.createMinorAxisHandle(e),
      this.createMinorAxisOppositeHandle(e),
      this.createStartPointHandle(e),
      this.createEndPointHandle(e),
      this.createHelperHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.center,
      (e) => t.move2DToPoint(e)
    )
  }
  createMajorAxisHandle(e) {
    return createParamHandle(e, 0)
  }
  createMajorAxisOppositeHandle(e) {
    return createParamHandle(e, 0.5)
  }
  createMinorAxisHandle(e) {
    return createParamHandle(e, 0.25)
  }
  createMinorAxisOppositeHandle(e) {
    return createParamHandle(e, 0.75)
  }
  createStartPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.startPoint,
      (e) => {
        t.updateForStartPoint(e)
      }
    )
  }
  createEndPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.endPoint,
      (e) => t.updateForEndPoint(e)
    )
  }
  createHelperHandle(e) {
    const t = e.shape
    return new CompositeEditHandle([
      new EllipseAxisHelperHandle(t),
      new ArcHelperHandle(t),
    ])
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, t) => {
      const a = e.shape
      a.move2DToPoint(t)
      const r = getDefaultSize(e.map)
      a.a = r
      a.b = r / 2
    })
  }
}
function createParamHandle(e, t) {
  const a = e.shape
  return new PointDragHandle(
    () => a.interpolate(t),
    (e) => a.updatePoint(t, e.x, e.y)
  )
}
