import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { BaseProjection } from './BaseProjection.js'
import { ProjectionType } from './ProjectionType.js'
const outOfBounds = new OutOfBoundsError('OutofBounds in PolarStereographic.')
const EPSILON = 1e-5
export class PolarStereographic extends BaseProjection {
  constructor(t, s) {
    super()
    this._pole = -1
    this._latitudeExtent = s ?? 90
    this._centralMeridian = 0
    this._sinCentralMeridian = 0
    this._cosCentralMeridian = 1
    this._trueScaleLatitude = -1
    this._sphereScaleFactor = -1
    this._sign = 1
    this._E = -1
    this._A = -1
    this._ellipsoidFactor = -1
    this._ellipsoidFactorForTrueScaleAtPole = -1
    this._B1 = -1
    this._B2 = -1
    this._B3 = -1
    this._B4 = -1
    this._origin = new LLHPoint(null, [0, 0])
    this._hash = 0
    this.setPole(t)
    if (this._pole === Pole.NORTH_POLE) this.setTrueScaleLatitude(90)
    else this.setTrueScaleLatitude(-90)
  }
  setPole() {
    let t =
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : Pole.NORTH_POLE
    if (t !== this._pole) {
      this._pole = t
      if (this._pole === Pole.NORTH_POLE) {
        this._origin.x = 0
        this._origin.y = 90
        this._sign = 1
      } else {
        this._origin.x = 0
        this._origin.y = -90
        this._sign = -1
      }
    }
    this.calculateCachedValues()
  }
  get A() {
    return this._A
  }
  get ellipsoidFactor() {
    return this._ellipsoidFactor
  }
  getPole() {
    return this._pole
  }
  getOrigin() {
    return this._origin
  }
  getCentralMeridian() {
    return this._centralMeridian
  }
  setCentralMeridian(t) {
    this._centralMeridian = t
    this._cosCentralMeridian = Math.cos(
      this._centralMeridian * Constants.DEG2RAD
    )
    this._sinCentralMeridian = Math.sin(
      this._centralMeridian * Constants.DEG2RAD
    )
    this.calculateCachedValues()
  }
  getTrueScaleLatitude() {
    return this._trueScaleLatitude
  }
  setTrueScaleLatitude(t) {
    this._trueScaleLatitude = t
    this._sphereScaleFactor =
      (1 + this._sign * Math.sin(this._trueScaleLatitude * Constants.DEG2RAD)) /
      2
    this._A = -1
    this._E = -1
    this.calculateCachedValues()
  }
  setLatitudeExtent(t) {
    this._latitudeExtent = t
  }
  getLatitudeExtent() {
    return this._latitudeExtent
  }
  isAllInBounds() {
    return false
  }
  isContinuous() {
    return true
  }
  cosX(t) {
    return (
      Math.cos(t.x * Constants.DEG2RAD) * this._cosCentralMeridian +
      Math.sin(t.x * Constants.DEG2RAD) * this._sinCentralMeridian
    )
  }
  sinX(t) {
    return (
      Math.sin(t.x * Constants.DEG2RAD) * this._cosCentralMeridian -
      Math.cos(t.x * Constants.DEG2RAD) * this._sinCentralMeridian
    )
  }
  inLonLatBounds(t) {
    return (
      (this._pole === Pole.NORTH_POLE && t.y >= 90 - this._latitudeExtent) ||
      (this._pole !== Pole.NORTH_POLE && t.y <= -90 + this._latitudeExtent)
    )
  }
  boundaryLons(t) {
    return (this._pole === Pole.NORTH_POLE && t >= 90 - this._latitudeExtent) ||
      (this._pole !== Pole.NORTH_POLE && t <= -90 + this._latitudeExtent)
      ? [[-180, 180]]
      : []
  }
  boundaryLats(t) {
    return this._pole === Pole.NORTH_POLE
      ? [[90 - this._latitudeExtent, 90]]
      : [[-90, -90 + this._latitudeExtent]]
  }
  geodetic2cartesianOnSphereSFCT(t, s, e) {
    if (this.inLonLatBounds(t)) {
      const i =
        2 *
        this._sphereScaleFactor *
        s *
        Math.tan(Math.PI / 4 - ((this._sign * t.y) / 2) * Constants.DEG2RAD)
      e.x = i * this.sinX(t)
      e.y = -i * this._sign * this.cosX(t)
    } else throw outOfBounds
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, e) {
    this.updateCachedValues(s)
    if (this.inLonLatBounds(t)) {
      const s = this._E
      const i = this._sign * Math.sin(t.y * Constants.DEG2RAD)
      const n = s * i
      const a = Math.sqrt(((1 - i) / (1 + i)) * Math.pow((1 + n) / (1 - n), s))
      const o = 2 * this.calculateRadius() * a
      e.x = o * this.sinX(t)
      e.y = -this._sign * o * this.cosX(t)
    } else throw outOfBounds
  }
  cartesian2geodeticOnSphereSFCT(t, s, e) {
    if (this.inWorldBoundsOnSphere(t, s)) {
      const i = t.x / this._sphereScaleFactor
      const n = t.y / this._sphereScaleFactor
      const a = Math.sqrt(i * i + n * n)
      e.x = normalizeLon(
        this.getCentralMeridian() +
          normalizeLon(Math.atan2(i, -this._sign * n) * Constants.RAD2DEG)
      )
      e.y =
        Constants.RAD2DEG *
        this._sign *
        (Math.PI / 2 - 2 * Math.atan2(a, 2 * s))
    } else throw outOfBounds
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, e) {
    if (this.inWorldBoundsOnEllipsoid(t, s)) {
      const i = this._sign * t.x
      const n = this._sign * t.y
      const a = Math.sqrt(i * i + n * n)
      this.updateCachedValues(s)
      try {
        const t = a / (2 * this.calculateRadius())
        const s = Math.PI / 2 - 2 * Math.atan(t)
        const o = Math.cos(s)
        const h = Math.sin(s)
        const r = h * h
        const l =
          (s +
            h *
              o *
              (this._B1 + r * (this._B2 + r * (this._B3 + r * this._B4)))) *
          Constants.RAD2DEG
        e.x = normalizeLon(
          this.getCentralMeridian() +
            normalizeLon(Constants.RAD2DEG * this._sign * Math.atan2(i, -n))
        )
        e.y = this._sign * l
      } catch (t) {
        throw outOfBounds
      }
    } else throw outOfBounds
  }
  inWorldBoundsOnEllipsoid(t, s) {
    this.updateCachedValues(s)
    const e = this.calculateRadius()
    const i = t.x / e
    const n = t.y / e
    const a = undefined
    return (
      i * i + n * n <=
      (90 === this._latitudeExtent
        ? 4
        : Math.pow(
            2 * Math.tan((this._latitudeExtent * Constants.DEG2RAD) / 2),
            2
          )) *
        (1 + 1e-15)
    )
  }
  inWorldBoundsOnSphere(t, s) {
    const e = s * this._sphereScaleFactor
    const i = t.x / e
    const n = t.y / e
    const a = undefined
    return (
      i * i + n * n <=
      (90 === this._latitudeExtent
        ? 2 * s
        : Math.pow(
            2 * Math.tan((this._latitudeExtent * Constants.DEG2RAD) / 2),
            2
          )) *
        (1 + 1e-15)
    )
  }
  cartesianBoundsOnEllipsoidSFCT(t, s) {
    this.updateCachedValues(t)
    const e = this.calculateRadius()
    const i =
      90 === this._latitudeExtent
        ? 2 * e
        : 2 * e * Math.tan((this._latitudeExtent * Constants.DEG2RAD) / 2)
    s.setTo2D(-i, 2 * i, -i, 2 * i)
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    const e =
      90 === this._latitudeExtent
        ? 2 * t
        : 2 * t * Math.tan((this._latitudeExtent * Constants.DEG2RAD) / 2)
    s.setTo2D(-e, 2 * e, -e, 2 * e)
  }
  calculateRadius() {
    return Math.abs(Math.abs(this._trueScaleLatitude) - 90) < EPSILON
      ? this._ellipsoidFactorForTrueScaleAtPole
      : 0.5 * this._A * this._ellipsoidFactor
  }
  equals(t) {
    if (!super.equals(t)) return false
    const s = t
    return (
      s.getPole() === this.getPole() &&
      s.getCentralMeridian() === this.getCentralMeridian() &&
      s.getTrueScaleLatitude() === this.getTrueScaleLatitude()
    )
  }
  updateCachedValues(t) {
    if (t.a !== this._A || t.e !== this._E) {
      this._A = t.a
      this._E = t.e
      const s = this._trueScaleLatitude * Constants.DEG2RAD
      const e = Math.cos(s)
      const i = Math.sin(s)
      const n = this._E * i
      const a = i * this._sign
      const o = n * this._sign
      const h = e / Math.sqrt(1 - n * n)
      const r = ((1 - a) / (1 + a)) * Math.pow((1 + o) / (1 - o), this._E)
      this._ellipsoidFactor = h / Math.sqrt(r)
      const l = 1 + this._E
      const c = 1 - this._E
      const u = Math.pow(l, l) * Math.pow(c, c)
      this._ellipsoidFactorForTrueScaleAtPole = this._A / Math.sqrt(u)
      const d = t.e2
      const _ = d * d
      const p = _ * d
      const E = _ * _
      const M = d / 2 + (5 / 24) * _ + (1 / 12) * p + (13 / 360) * E
      const C = (7 / 48) * _ + (29 / 240) * p + (811 / 11520) * E
      const g = (7 / 120) * p + (81 / 1120) * E
      const L = (4279 / 161280) * E
      this._B1 = 2 * M + 4 * C + 6 * g + 8 * L
      this._B2 = -8 * C - 32 * g - 80 * L
      this._B3 = 32 * g + 192 * L
      this._B4 = -128 * L
    }
  }
  encode() {
    return {
      type: 'PolarStereographic',
      pole: this.getPole(),
      centralMeridian: this.getCentralMeridian(),
      trueScaleLatitude: this.getTrueScaleLatitude(),
      latitudeExtent: this.getLatitudeExtent(),
    }
  }
  calculateCachedValues() {
    const t = new Hash()
    this._hash = t
      .appendDouble(this.getCentralMeridian())
      .appendDouble(this.getTrueScaleLatitude())
      .appendUInt32(this.getPole())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  get TYPE() {
    return ProjectionType.POLAR_STEREOGRAPHIC
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
export let Pole = (function (t) {
  t[(t['NORTH_POLE'] = 0)] = 'NORTH_POLE'
  t[(t['SOUTH_POLE'] = 1)] = 'SOUTH_POLE'
  return t
})({})
