import { CoordinateType } from '../../reference/CoordinateType.js'
import { createBounds } from '../../shape/ShapeFactory.js'
import { SimpleBounds } from './SimpleBounds.js'
import { RTree } from './RTree.js'
export class RTreeIndex {
  constructor(e) {
    this.reference = e ? e : null
    this.clear()
  }
  clear() {
    this.rtree = new RTree(
      this.reference ? this.reference.coordinateType : CoordinateType.CARTESIAN
    )
    this.idMap = new Map()
    this.tempBounds = new SimpleBounds()
  }
  getBounds() {
    const e = this.rtree.root ? this.rtree.root.bounds : null
    return e
      ? createBounds(this.reference, [
          e.left,
          e.right - e.left,
          e.bottom,
          e.top - e.bottom,
        ])
      : null
  }
  getEntryCount() {
    return this.rtree.size
  }
  getNode(e) {
    return this.idMap.get(e) || null
  }
  insert(e) {
    const t = e.feature.id
    if (e.shape) this.rtree.addElement(e)
    this.idMap.set(t, e)
  }
  remove(e) {
    const t = this.idMap.get(e)
    if (!t) return null
    this.rtree.removeElement(t)
    this.idMap.delete(e)
    return t
  }
  getIdMapSnapshot() {
    return new Map(this.idMap)
  }
  size() {
    return this.idMap.size
  }
  search(e, t) {
    const r = this.tempBounds
    r.left = e.x
    r.right = e.x + e.width
    r.bottom = e.y
    r.top = e.y + e.height
    this.rtree.applyOnInteract2DBounds(r, t)
  }
  forEachVisibleNode(e) {
    this.rtree.applyToElements(e)
  }
  forEachNode(e) {
    this.idMap.forEach((t) => {
      e(t)
    })
  }
  getNodeIterator() {
    return this.idMap.values()
  }
}
