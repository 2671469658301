import { createBounds } from '../../shape/ShapeFactory.js'
export class Tile {
  constructor(t, i) {
    this._coordinate = t
    this._image = i
    this._bitmapBounds = createBounds(null, [0, i.width, 0, i.height])
  }
  get bitmapBounds() {
    return this._bitmapBounds
  }
  get image() {
    return this._image
  }
  get coordinate() {
    return this._coordinate
  }
  draw(t, i, e) {
    if (!i) i = this._bitmapBounds
    t.drawImage(
      this._image,
      i.x,
      i.y,
      i.width,
      i.height,
      e.x,
      e.y,
      e.width,
      e.height
    )
  }
}
