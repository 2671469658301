import { Log } from '../../util/Log.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { CanvasDrawingSurface } from './CanvasDrawingSurface.js'
export class DrawingContext {
  constructor(e, t, i, a, r) {
    let s =
      arguments.length > 5 && void 0 !== arguments[5]
        ? arguments[5]
        : PaintRepresentation.BODY
    let n =
      arguments.length > 6 && void 0 !== arguments[6]
        ? arguments[6]
        : PaintRepresentation.LABEL
    this._valid = false
    this._layers = []
    this._type = e
    this._paintMethodName = '_paintBodyLabels'
    this._bodyRepresentation = s
    this._labelRepresentation = n
    this._surface = new CanvasDrawingSurface(t, {
      width: i,
      height: a,
      border: r,
    })
  }
  get surface() {
    return this._surface
  }
  get type() {
    return this._type
  }
  get valid() {
    return this._valid
  }
  set valid(e) {
    this._valid = e
  }
  get layers() {
    return this._layers
  }
  set layers(e) {
    this._layers = e
  }
  _paintlayersOnContext2dAndCollectLabels(e, t) {
    let i
    for (let a = 0; a < this._layers.length; a++) {
      i = this._layers[a]
      try {
        if (i.visibleInTree && i.inAllowedScaleRange())
          if (i.isPaintRepresentationVisibleInTree(this._bodyRepresentation))
            this.paintBodyLabels(i, e, t)
          else if (
            i.isPaintRepresentationVisibleInTree(this._labelRepresentation)
          )
            i._collectLabels(e, t)
      } catch (e) {
        Log.error(
          `Error while painting layer (layer drawing)${i.label}`,
          e instanceof Error ? e : void 0
        )
      }
    }
  }
  paintBodyLabels(e, t, i) {
    e._paintBodyLabels(t, i)
  }
  paintOnDifferentContext(e, t) {
    this._paintlayersOnContext2dAndCollectLabels(e, t)
  }
  collectLabelsInContext(e, t) {
    let i
    for (let a = 0; a < this._layers.length; a++) {
      i = this._layers[a]
      try {
        if (
          i.visibleInTree &&
          i.isPaintRepresentationVisibleInTree(this._labelRepresentation) &&
          i.inAllowedScaleRange()
        )
          i._collectLabels(e, t)
      } catch (e) {
        Log.error(
          `Error while painting layer (label collection)${i.label}`,
          e instanceof Error ? e : void 0
        )
      }
    }
  }
  paintDrawingContext(e) {
    const t = this._surface.getContext2d()
    if (this._valid) {
      this.collectLabelsInContext(t, e)
      return
    }
    this._valid = true
    this._surface.clear()
    this._paintlayersOnContext2dAndCollectLabels(t, e)
  }
  clear() {
    this._surface.clear()
  }
  setZIndex(e) {
    this._surface.setZIndex(e)
  }
  resizeIgnoreBorder(e, t) {
    this._surface.resizeIgnoreBorder(e, t)
  }
  destroy() {
    this._surface.destroy()
  }
}
