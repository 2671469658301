import { ShapeType } from '../../shape/ShapeType.js'
import { CGUtil } from './CGUtil.js'
import { CGOperatorConvertShape } from './CGOperatorConvertShape.js'
import { CGShape } from './CGShape.js'
import { CGOperatorSet } from './operatorset/CGOperatorSet.js'
import { ConstructiveGeometryOperator } from './ConstructiveGeometryOperator.js'
export class ConstructiveGeometryPointListPointList extends ConstructiveGeometryOperator {
  constructor(e) {
    super(e)
    this._operator = new CGOperatorSet()
  }
  operate(e, t, r) {
    const p = new CGShape()
    const o = new CGShape()
    CGOperatorConvertShape.shapeToCgShape(e, 0, this.topologyUtil, p)
    CGOperatorConvertShape.shapeToCgShape(t, 1, this.topologyUtil, o)
    CGUtil.prepareShapes(this.intersector, this.topologyUtil, p, o)
    return this.operateOnCGShapes(p, o, r)
  }
  operateOnCGShapes(e, t, r) {
    const p = this._operator.operate(e, t, r)
    return CGOperatorConvertShape.cgShapeToShape(p, this.topologyUtil)
  }
  _supportsShape(e) {
    if (ShapeType.contains(ShapeType.POINT, e.type)) return true
    if (ShapeType.contains(ShapeType.POLYLINE, e.type)) return true
    if (ShapeType.contains(ShapeType.POLYGON, e.type)) return true
    return ShapeType.contains(ShapeType.COMPLEX_POLYGON, e.type)
  }
  supportsShapes(e) {
    for (let t = 0; t < e.length; t++)
      if (!this._supportsShape(e[t])) return false
    return true
  }
}
