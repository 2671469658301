import { getReference } from '../../reference/ReferenceProvider.js'
import { createBounds } from '../../shape/ShapeFactory.js'
import { PixelFormat } from './PixelFormat.js'
import { RasterDataType } from './RasterDataType.js'
import { RasterSamplingMode } from './RasterSamplingMode.js'
import { RasterTileSetModel } from './RasterTileSetModel.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isQuadTreeRasterTileSetModel } from './RasterTileSetModelUtil.js'
const defaultElevationTileSize = 17
function createQuadTreeStructure(e) {
  return {
    reference: e ? e.reference : getReference('EPSG:4326'),
    bounds:
      e?.bounds ??
      createBounds(getReference('EPSG:4326'), [-180, 360, -90, 180]),
    levelCount: 30,
    level0Columns: e?.getTileColumnCount(0) ?? 4,
    level0Rows: e?.getTileRowCount(0) ?? 2,
    tileWidth: defaultElevationTileSize,
    tileHeight: defaultElevationTileSize,
  }
}
function createTileSetStructure(e) {
  const t = e.bounds
  if (!t) throw new ProgrammingError('RasterTileSetModel must have bounds.')
  const r = new Array(e.levelCount)
  for (let t = 0; t < e.levelCount; t++) {
    const o = e.getBounds(t)
    const i = e.getTileColumnCount(t)
    const l = e.getTileRowCount(t)
    if (!o || !i || !l)
      throw new ProgrammingError(
        'RasterTileSetModel must have for each level: bounds, tile column count, tile row count.'
      )
    r[t] = {
      bounds: o,
      tileColumnCount: i,
      tileRowCount: l,
      tileWidth: defaultElevationTileSize,
      tileHeight: defaultElevationTileSize,
    }
  }
  return { reference: e.reference, bounds: t, tileMatrix: r }
}
export function createZeroElevation(e) {
  const t = undefined
  const r = {
    structure:
      !e || isQuadTreeRasterTileSetModel(e)
        ? createQuadTreeStructure(e)
        : createTileSetStructure(e),
    dataType: RasterDataType.ELEVATION,
    samplingMode: RasterSamplingMode.POINT,
    modelDescriptor: {
      source: 'Dummy',
      name: 'Dummy elevation',
      description: 'Elevation tile set with heights at the ellipsoid',
    },
  }
  return new DummyTileSetModel(r)
}
class DummyTileSetModel extends RasterTileSetModel {
  constructor(e) {
    super(e)
  }
  verifyElevationHasQuadTreeStructure(e) {}
  getTileData(e, t, r, o) {
    const i = this.getTileWidth(e.level)
    const l = this.getTileHeight(e.level)
    if (null == i || null == l) r(e, 'Invalid tile coordinate')
    else
      t(e, {
        width: i,
        height: l,
        pixelFormat: PixelFormat.FLOAT_32,
        data: new ArrayBuffer(i * l * 4),
      })
  }
  getImage(e, t, r, o) {}
}
