import { ProgrammingError } from '../error/ProgrammingError.js'
import { XYZBounds } from '../shape/XYZBounds.js'
import { getUnitOfMeasure } from '../uom/UnitOfMeasureRegistry.js'
import { Hash } from '../util/Hash.js'
import { isNumber, isUndefined } from '../util/Lang.js'
import { Axis } from './Axis.js'
import { hasSouthingAxis, hasWestingAxis } from './AxisInformationUtil.js'
import { CoordinateType } from './CoordinateType.js'
import { GeoReference } from './GeoReference.js'
import { ReferenceType } from './ReferenceType.js'
const meterUOM = getUnitOfMeasure('Meter')
export class GridReference extends GeoReference {
  constructor(t) {
    const i = t || {}
    i.coordinateType = CoordinateType.CARTESIAN
    i.referenceType = ReferenceType.GRID
    if (!i.axisInformation)
      i.axisInformation = [
        {
          name: Axis.Name.X,
          axis: new Axis('X', Axis.Direction.EAST, meterUOM, null, null, null),
        },
        {
          name: Axis.Name.Y,
          axis: new Axis('Y', Axis.Direction.NORTH, meterUOM, null, null, null),
        },
      ]
    super(i)
    if (isUndefined(t))
      throw new ProgrammingError(
        'Options to create a grid reference must be defined.'
      )
    if (isUndefined(t.projection))
      throw new ProgrammingError(
        'Options to create a grid reference must contain a projection.'
      )
    this._projection = t.projection
    this._falseEasting = isNumber(t.falseEasting) ? t.falseEasting : 0
    this._falseNorthing = isNumber(t.falseNorthing)
      ? t.falseNorthing
      : (this._falseNorthing = 0)
    this._scale = isNumber(t.scale) ? t.scale : 1
    this._rotation = isNumber(t.rotation) ? t.rotation : 0
    this._unitOfMeasure = isNumber(t.unitOfMeasure) ? t.unitOfMeasure : 1
    this._cosRotation = Math.cos(this._rotation)
    this._sinRotation = Math.sin(this._rotation)
    this._negateX = hasWestingAxis(this)
    this._negateY = hasSouthingAxis(this)
    this._hash = 0
  }
  grid2projectedSFCT(t, i) {
    const e = (this._negateX ? -t.x : t.x) - this._falseEasting
    const s = (this._negateY ? -t.y : t.y) - this._falseNorthing
    i.x =
      (this._unitOfMeasure * (e * this._cosRotation - s * this._sinRotation)) /
      this._scale
    i.y =
      (this._unitOfMeasure * (e * this._sinRotation + s * this._cosRotation)) /
      this._scale
  }
  projected2gridSFCT(t, i) {
    const e = t.x
    const s = t.y
    i.x =
      ((e * this._cosRotation + s * this._sinRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseEasting
    i.y =
      ((-e * this._sinRotation + s * this._cosRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseNorthing
    if (this._negateX) i.x = -i.x
    if (this._negateY) i.y = -i.y
  }
  equals(t) {
    let i
    if (t === this) return true
    if (t instanceof GridReference)
      return (
        this.geodeticDatum.equals(t.geodeticDatum) &&
        this.projection.equals(t.projection) &&
        this.falseEasting === t.falseEasting &&
        this.falseNorthing === t.falseNorthing &&
        this.scale === t.scale &&
        this.rotation === t.rotation &&
        this.unitOfMeasure === t.unitOfMeasure &&
        this.getAxis(Axis.Name.X).equals(t.getAxis(Axis.Name.X)) &&
        this.getAxis(Axis.Name.Y).equals(t.getAxis(Axis.Name.Y)) &&
        this._negateX === t._negateX &&
        this._negateY === t._negateY &&
        ((i = this.getAxis(Axis.Name.Z))
          ? i.equals(t.getAxis(Axis.Name.Z))
          : true)
      )
    else return false
  }
  toString() {
    return `GridReference[${this.name}]`
  }
  copy() {
    return new GridReference({
      authorityName: this.authorityName,
      authorityCode: this.authorityCode,
      name: this.name,
      geodeticDatum: this.geodeticDatum,
      heightReference: this.heightReference,
      projection: this.projection,
      falseEasting: this.falseEasting,
      falseNorthing: this.falseNorthing,
      scale: this.scale,
      rotation: this.rotation,
      unitOfMeasure: this.unitOfMeasure,
      axisInformation: this.axisInformation,
    })
  }
  get projection() {
    return this._projection
  }
  get falseEasting() {
    return this._falseEasting
  }
  get falseNorthing() {
    return this._falseNorthing
  }
  setFalseNorthing(t) {
    this._falseNorthing = t
  }
  get scale() {
    return this._scale
  }
  get rotation() {
    return this._rotation
  }
  get cosRotation() {
    return this._cosRotation
  }
  get sinRotation() {
    return this._sinRotation
  }
  get unitOfMeasure() {
    return this._unitOfMeasure
  }
  get bounds() {
    const t = new XYZBounds(this, [0, 0, 0, 0])
    this._projection.cartesianBoundsOnEllipsoidSFCT(
      this.geodeticDatum.ellipsoid,
      t
    )
    let i, e, s, n
    let a, o, r, h
    i = t.x
    e = t.y
    s =
      ((i * this._cosRotation + e * this._sinRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseEasting
    n =
      ((-i * this._sinRotation + e * this._cosRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseNorthing
    a = s
    o = s
    r = n
    h = n
    i = t.x + t.width
    e = t.y
    s =
      ((i * this._cosRotation + e * this._sinRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseEasting
    n =
      ((-i * this._sinRotation + e * this._cosRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseNorthing
    a = Math.min(s, a)
    o = Math.max(s, o)
    r = Math.min(n, r)
    h = Math.max(n, h)
    i = t.x
    e = t.y + t.height
    s =
      ((i * this._cosRotation + e * this._sinRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseEasting
    n =
      ((-i * this._sinRotation + e * this._cosRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseNorthing
    a = Math.min(s, a)
    o = Math.max(s, o)
    r = Math.min(n, r)
    h = Math.max(n, h)
    i = t.x + t.width
    e = t.y + t.height
    s =
      ((i * this._cosRotation + e * this._sinRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseEasting
    n =
      ((-i * this._sinRotation + e * this._cosRotation) * this._scale) /
        this._unitOfMeasure +
      this._falseNorthing
    a = Math.min(s, a)
    o = Math.max(s, o)
    r = Math.min(n, r)
    h = Math.max(n, h)
    if (this._negateX) {
      const t = a
      a = -o
      o = -t
    }
    if (this._negateY) {
      const t = r
      r = -h
      h = -t
    }
    t.setTo2D(a, o - a, r, h - r)
    return t
  }
  get TYPE() {
    return ReferenceType.GRID
  }
  hashCode(t) {
    super.hashCode(t)
    if (0 === this._hash) {
      const t = new Hash()
      this.projection.hashCode(t)
      this._hash = t
        .appendDouble(this.falseEasting)
        .appendDouble(this.falseNorthing)
        .appendDouble(this.scale)
        .appendDouble(this.unitOfMeasure)
        .appendDouble(this.rotation)
        .getHashCode()
    }
    t.appendUInt32(this._hash)
  }
}
