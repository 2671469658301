import { ProgrammingError } from '../../error/ProgrammingError.js'
import { GoogleTileSetModel } from '../../model/google/GoogleTileSetModel.js'
import { WebMercatorBounds } from '../../model/WebMercatorBounds.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { createBounds, createPoint } from '../../shape/ShapeFactory.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { clamp } from '../../util/Cartesian.js'
import { isDefined } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { Layer } from '../Layer.js'
import { LayerType } from '../LayerType.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import {
  calculateTilesetLevelForMapScale,
  snapScaleToLevel,
} from '../tileset/TileSetScaleUtil.js'
const GEO_REF = getReference('CRS:84')
const GOOG_REF = getReference('EPSG:900913')
const GOOG_TO_GEO = createTransformation(GOOG_REF, GEO_REF)
const GEO_TO_GOOG = GOOG_TO_GEO.inverseTransformation
const b = createBounds(GEO_REF, [-179.9, 359.8, -84, 168])
const GOOGLE_NAVIGATION_RESTRICTION = GEO_TO_GOOG.transformBounds(b)
const WEBMERC = WebMercatorBounds.create()
let MAP_TYPE_MAP
function enforceSupportAndBootstrap() {
  if (!window.google || !window.google.maps || !window.google.maps.MapTypeId)
    throw new ProgrammingError(
      'Must include the Google Maps API. Use a script tag in the <head> of the document'
    )
  if (!MAP_TYPE_MAP)
    MAP_TYPE_MAP = {
      satellite: google.maps.MapTypeId.SATELLITE,
      hybrid: google.maps.MapTypeId.HYBRID,
      roadmap: google.maps.MapTypeId.ROADMAP,
      terrain: google.maps.MapTypeId.TERRAIN,
    }
}
function toGoogPoint(e) {
  return new google.maps.LatLng(e.y, e.x)
}
export class GoogleLayer extends Layer {
  constructor(e) {
    e = e || {}
    const t = undefined
    super(
      new GoogleTileSetModel(),
      Object.assign({}, e, { layerType: LayerType.DYNAMIC })
    )
    enforceSupportAndBootstrap()
    this._mapType = e.mapType || 'roadmap'
    this._styles = e.styles ? e.styles : null
    this._isDarkened = false
    this._waitingForIdle = false
    this._ready = true
    this._stylesChanged = false
    this._lastConfiguredZoomLevel = -1 / 0
  }
  isPaintRepresentationSupported(e) {
    return PaintRepresentation.BODY === e
  }
  getGoogleMinScale() {
    return (512 / WEBMERC.width) * Math.pow(2, 0)
  }
  getGoogleMaxScale() {
    const e = 'terrain' === this.mapType ? 15 : 20
    return (256 / WEBMERC.height) * Math.pow(2, e)
  }
  _updateVisibility(e) {
    if (!this.visible) e.style.visibility = 'hidden'
    else {
      e.style.visibility = 'visible'
      if (this._isDarkened && !this._waitingForIdle)
        this.darkenGoogleMap(false, e)
    }
  }
  _updateMapType(e) {
    if (this._mapType !== e.getMapTypeId()) e.setMapTypeId(this._mapType)
  }
  darkenGoogleMap(e, t) {
    t.style.opacity = e ? '0.25' : '1'
    this._isDarkened = e
  }
  _updateMapCenter(e, t) {
    const o = this
    if (!this._idleMarker)
      o._idleMarker = google.maps.event.addListener(e, 'idle', () => {
        o._ready = true
        if (o.visible) o.darkenGoogleMap(false, t)
        o._invalidate()
      })
    if (null === this._map) return
    const s = this._map.mapBounds
    let a, i
    try {
      a = GOOG_TO_GEO.transform(s.focusPoint)
      i = toGoogPoint(a)
    } catch (e) {
      return
    }
    let r
    try {
      r = calculateTilesetLevelForMapScale(this, this._map)
    } catch (e) {
      Log.warn('Cannot render google map. Zoom level cannot be determined.')
      this.darkenGoogleMap(true, t)
      return
    }
    if (Math.abs(Math.round(r) - r) < 1e-8) {
      r = Math.round(r)
      if (r !== this._lastConfiguredZoomLevel) {
        this._lastConfiguredZoomLevel = r
        t.style.display = 'block'
        this._ready = false
        this.darkenGoogleMap(true, t)
        this._waitingForIdle = true
        e.setOptions({ center: i, zoom: r })
      } else if (r !== e.getZoom()) t.style.display = 'none'
      else if (!i.equals(e.getCenter())) {
        o._ready = false
        e.setOptions({ center: i })
      }
    } else {
      this._ready = false
      this.darkenGoogleMap(true, t)
    }
  }
  isReady() {
    return this._ready
  }
  _updateStyles(e) {
    if (this._stylesChanged) {
      e.setOptions({ styles: this._styles })
      this._stylesChanged = false
    }
  }
  updateGoogleMap(e, t) {
    this._updateVisibility(t)
    if (!this.visible) return
    this._updateMapType(e)
    this._updateStyles(e)
    this._updateMapCenter(e, t)
  }
  _addedToMap(e) {
    const t = undefined
    if ('Photon' === e.viewPaintingStrategy.techContext.type) {
      Log.warn('LuciadRIA WebGLMap currently does not support GoogleLayer')
      this.visibleSupported = false
    }
    if (!e.reference.equals(this.model.reference))
      throw new ProgrammingError(
        'The reference of the map is not compatible with the Google Layer (use EPSG:900913)'
      )
    super._addedToMap(e)
  }
  _removedFromMap(e) {
    if (isDefined(this._idleMarker))
      google.maps.event.clearInstanceListeners(this._idleMarker)
    super._removedFromMap(e)
  }
  _keepCenterInBounds(e, t) {
    if (null === this._map) return
    const o = e.inverseTransformation.transformBounds(this._map.viewBounds)
    const s = GOOGLE_NAVIGATION_RESTRICTION
    const a = Math.min(s.width, o.width)
    const i = Math.min(s.height, o.height)
    let r = o.width / Math.max(a, 1)
    let n = o.height / Math.max(i, 1)
    r = Math.max(r, n)
    n = Math.max(r, n)
    o.scaleAroundCenter(r, n)
    const l = Math.min(0, o.left - s.left) + Math.max(0, o.right - s.right)
    const p = Math.min(0, o.bottom - s.bottom) + Math.max(0, o.top - s.top)
    o.x -= l
    o.y -= p
    const d = t.getViewCenter()
    const h = createPoint(null, [d.x + this._map.border.left, d.y])
    t.setTo(o.focusPoint, h, e.getScaleX(), e.getScaleY(), e.getRotation())
    const g = e.getViewOrigin()
    const m = t.toWorld(g)
    t.setTo(m, g, t.getScaleX(), t.getScaleY(), t.getRotation())
  }
  correctWorldViewTransformation2D(e, t) {
    const o = e.copy()
    o.setTo(
      o.getWorldOrigin(),
      o.getViewOrigin(),
      clamp(o.getScaleX(), this.getGoogleMinScale(), this.getGoogleMaxScale()),
      clamp(o.getScaleY(), this.getGoogleMinScale(), this.getGoogleMaxScale()),
      0
    )
    this._keepCenterInBounds(o, t)
  }
  get model() {
    return super.model
  }
  snapScale(e, t, o, s) {
    if (null === this.model || null === this._map) return
    snapScaleToLevel(this.model, this._map, o, e, t, s)
  }
  get mapType() {
    return this._mapType
  }
  set mapType(e) {
    const t = MAP_TYPE_MAP[e]
    if (!isDefined(t))
      throw new ProgrammingError(
        `map type not recognized. Choose from ${Object.keys(MAP_TYPE_MAP).join(
          ','
        )}`
      )
    this._mapType = t
    this._invalidate()
  }
  get styles() {
    return this._styles
  }
  set styles(e) {
    this._styles = e || null
    this._stylesChanged = null !== this._styles
    if (this._map) this._map.invalidate()
  }
  get isGoogle() {
    return true
  }
  get stylesChanged() {
    return this._stylesChanged
  }
}
