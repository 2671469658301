import { Photon } from '../gen/photon/photon_painter.js'
import { Constants } from '../util/Constants.js'
import { PerspectiveCamera } from './camera/PerspectiveCamera.js'
import { OrthographicCamera } from './camera/OrthographicCamera.js'
const EPSILON = 1e-5
export class PhotonCameraCallback extends Photon.PhotonCameraCallback {
  constructor(a, e) {
    super()
    this._map = a
    this._view = e
  }
  updateCamera(a, e) {
    const t = e.enableCameraCallback(false)
    if (
      a instanceof PerspectiveCamera &&
      e instanceof Photon.PerspectiveCamera
    ) {
      if (!this.perspectiveCameraEqual(a, e))
        e.set(
          a.eye,
          a.forward,
          a.up,
          a.near,
          a.far,
          a.width,
          a.height,
          a.fovY * Constants.DEG2RAD
        )
    } else if (
      a instanceof OrthographicCamera &&
      e instanceof Photon.OrthographicCamera
    ) {
      if (!this.orthographicCameraEqual(a, e)) {
        e.set(a.eye, a.forward, a.up, a.near, a.far, a.width, a.height, {
          x: -a.worldWidth / 2,
          y: -a.worldHeight / 2,
          width: a.worldWidth,
          height: a.worldHeight,
          z: 0,
          depth: 0,
        })
        if (this._map.border.left) {
          const t = a.width + this._map.border.left
          const r = a.worldWidth * (t / a.width)
          const s = a.eye
          s.x += this._map.border.left / (t / r) / 2
          e.set(s, a.forward, a.up, a.near, a.far, t, a.height, {
            x: -r / 2,
            y: -a.worldHeight / 2,
            width: r,
            height: a.worldHeight,
            z: 0,
            depth: 0,
          })
        }
      }
    } else throw 'Invalid camera'
    e.enableCameraCallback(t)
  }
  onCameraChange() {
    let a
    let e
    if (this._map.camera instanceof PerspectiveCamera) {
      a = this._view.perspectiveCamera
      e = this._map.camera
      if (!('undefined' === typeof a) && !this.perspectiveCameraEqual(e, a))
        this._map.camera = new PerspectiveCamera(
          a.eye,
          a.forward,
          a.up,
          a.near,
          a.far,
          e.width,
          e.height,
          a.FOVy * Constants.RAD2DEG,
          this._map.reference
        )
      a.release()
    } else if (this._map.camera instanceof OrthographicCamera) {
      a = this._view.orthographicCamera
      e = this._map.camera
      if (!('undefined' === typeof a) && !this.orthographicCameraEqual(e, a))
        this._map.camera = new OrthographicCamera(
          a.eye,
          a.forward,
          a.up,
          a.near,
          a.far,
          e.width,
          e.height,
          a.worldBounds.width,
          a.worldBounds.height,
          this._map.reference
        )
      a.release()
    } else throw 'Invalid camera'
  }
  perspectiveCameraEqual(a, e) {
    return (
      Math.abs(a.eye.x - e.eye.x) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.eye.y - e.eye.y) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.eye.z - e.eye.z) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.forward.x - e.forward.x) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.forward.y - e.forward.y) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.forward.z - e.forward.z) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.up.x - e.up.x) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.up.y - e.up.y) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.up.z - e.up.z) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.aspectRatio - e.aspectRatio) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      a.near === e.near &&
      a.far === e.far &&
      a.fovY * Constants.DEG2RAD === e.FOVy
    )
  }
  orthographicCameraEqual(a, e) {
    return (
      Math.abs(a.eye.x - e.eye.x) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.eye.y - e.eye.y) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.eye.z - e.eye.z) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.forward.x - e.forward.x) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.forward.y - e.forward.y) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.forward.z - e.forward.z) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.up.x - e.up.x) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.up.y - e.up.y) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.up.z - e.up.z) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(a.aspectRatio - e.aspectRatio) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      a.near === e.near &&
      a.far === e.far &&
      a.worldWidth == e.worldBounds.width &&
      a.worldHeight == e.worldBounds.height
    )
  }
  pointsEqual(a, e, t) {
    t = 'undefined' !== typeof t ? t : EPSILON
    if (Math.abs(a.x - e.x) > t) return false
    if (Math.abs(a.y - e.y) > t) return false
    return Math.abs(a.z - e.z) <= t
  }
}
