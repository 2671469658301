import { squaredDistance2D } from '../../../util/Cartesian.js'
export class Clustered {
  constructor() {
    this._points = []
    this._clusters = new Set()
    this._parent = null
    this._center = null
    this._radius = -1
  }
  addPoint(t) {
    this._points.push(t)
    t.cluster = this
    this.invalidate()
  }
  addCluster(t) {
    this._clusters.add(t)
    t.parent = this
    this.invalidate()
  }
  removeCluster(t) {
    this._clusters.delete(t)
    t.parent = null
    this.invalidate()
  }
  getPoints() {
    if (0 === this._clusters.size) return this._points
    const t = []
    this.getPointsImpl(t)
    return t
  }
  getPointsImpl(t) {
    for (let s = 0; s < this._points.length; s++) t.push(this._points[s])
    for (const s of this._clusters) s.getPointsImpl(t)
  }
  getPointCount() {
    let t = this._points.length
    for (const s of this._clusters) t += s.getPointCount()
    return t
  }
  getCenter() {
    if (!this._center) this._center = this.recalculateCenter()
    return this._center
  }
  get parent() {
    return this._parent
  }
  set parent(t) {
    this._parent = t
  }
  invalidate() {
    this._center = null
    this._radius = -1
  }
  recalculateCenter() {
    let t = 0
    let s = 0
    let e = 0
    for (let n = 0; n < this._points.length; n++) {
      const i = this._points[n]
      s += i.viewLocation.x
      e += i.viewLocation.y
      t++
    }
    for (const n of this._clusters) {
      const i = n.getCenter()
      const _ = n.getPointCount()
      if (i) {
        s += i.x * _
        e += i.y * _
        t += _
      }
    }
    return { x: s / t, y: e / t, z: 0 }
  }
  getRadius() {
    if (this._radius < 0) this._radius = this.calculateRadius()
    return this._radius
  }
  calculateRadius() {
    const t = this.getCenter()
    const s = this.calculateMaxSquaredDistance(t)
    return Math.sqrt(s)
  }
  calculateMaxSquaredDistance(t) {
    let s = 0
    for (let e = 0; e < this._points.length; e++) {
      const { x: n, y: i } = this._points[e].viewLocation
      const _ = squaredDistance2D(t.x, t.y, n, i)
      s = Math.max(s, _)
    }
    for (const e of this._clusters) {
      const n = e.calculateMaxSquaredDistance(t)
      s = Math.max(s, n)
    }
    return s
  }
}
const MIN_SPLIT_RADIUS_FACTOR = 0.333
const MAX_SPLIT_RADIUS_FACTOR = 1.5
const MIN_HALF_SPLIT_RADIUS_FACTOR = 0.5 * MIN_SPLIT_RADIUS_FACTOR
const MAX_HALF_SPLIT_RADIUS_FACTOR = 0.5 * MAX_SPLIT_RADIUS_FACTOR
const MIN_SPLIT_RADIUS_POINT_COUNT = 2
const MAX_SPLIT_RADIUS_POINT_COUNT = 50
export function shouldSplit(t, s) {
  const e = t.getRadius()
  const n = s * MIN_HALF_SPLIT_RADIUS_FACTOR
  const i = s * MAX_HALF_SPLIT_RADIUS_FACTOR
  const _ = t.getPointCount()
  const r = undefined
  const o = undefined
  return (
    e >
    n +
      Math.sqrt(
        Math.min(
          MAX_SPLIT_RADIUS_POINT_COUNT,
          _ - MIN_SPLIT_RADIUS_POINT_COUNT
        ) / MAX_SPLIT_RADIUS_POINT_COUNT
      ) *
        (i - n)
  )
}
