import { UUID } from '../util/UUID.js'
import { Log } from '../util/Log.js'
import { isDefined, isString } from '../util/Lang.js'
import { NotImplementedError } from '../error/NotImplementedError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { LayerType } from './LayerType.js'
import { PaintRepresentation } from './PaintRepresentation.js'
import { LayerTreeNodeType } from './LayerTreeNodeType.js'
import { LayerTreeVisitor } from './LayerTreeVisitor.js'
import { EventedSupport } from '../util/EventedSupport.js'
import { ReadyResult } from './strategy/AbstractViewPaintingStrategy.js'
function baseLayerPredicate(e) {
  return (
    e && e.treeNodeType === LayerTreeNodeType.LAYER && e.type === LayerType.BASE
  )
}
function notBaseLayerPredicate(e) {
  return (
    e && e.treeNodeType === LayerTreeNodeType.LAYER && e.type !== LayerType.BASE
  )
}
class LayerTreeNode {
  constructor(e) {
    this._eventedDelegate = new EventedSupport([
      'PaintRepresentationVisibilityChanged',
      'VisibilityChanged',
      'LabelChanged',
    ])
    e = e || {}
    this._visibleSupported = true
    this._id = isString(e.id) ? e.id : UUID.randomUUID()
    this._label = isString(e.label) ? e.label : this._id
    this.visible = isDefined(e.visible, true) ? !!e.visible : true
    this._parent = null
    this._map = null
    this._isVisiblePaintRepresentation = {}
    for (const e in PaintRepresentation)
      if (PaintRepresentation.hasOwnProperty(e)) {
        const t = e
        this._isVisiblePaintRepresentation[t] =
          t !== PaintRepresentation.LEFT_BORDER_BODY &&
          t !== PaintRepresentation.LEFT_BORDER_LABEL
      }
  }
  isReady() {
    throw new NotImplementedError(
      'All LayerTreeNode subclasses must implement isReady'
    )
  }
  whenReady(e) {
    const t = e || 5 * 6e4
    const r = this
    return new Promise((e, i) => {
      const n = undefined
      if (!r.map) {
        e(r)
        return
      }
      let a = setTimeout(() => {
        a = 0
        i(`Timeout while waiting for layer "${r.label}" to become ready`)
      }, t)
      function s(t) {
        if (!a) return
        const i = r._map
        if (!i || i.destroyed) {
          clearTimeout(a)
          a = 0
          e(r)
          return
        }
        if (!t) i.invalidate()
        window.requestAnimationFrame(() => {
          const t = r._map
          if (!t || t.destroyed) {
            clearTimeout(a)
            a = 0
            e(r)
            return
          }
          if (!t.idleEventScheduled()) {
            const i = t.viewPaintingStrategy?.isReady()
            if (i === ReadyResult.READY && r.isNodeReady()) {
              t.invalidate()
              window.requestAnimationFrame(() => {
                if (r.isNodeReady()) {
                  clearTimeout(a)
                  a = 0
                  e(r)
                } else s(false)
              })
            } else s(i === ReadyResult.NOT_READY_BECAUSE_OF_DEPTH)
          } else s(false)
        })
      }
      s(false)
    })
  }
  isNodeReady() {
    const e = undefined
    return (
      !(
        this.visibleInTree &&
        this.inAllowedScaleRange() &&
        (this.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY) ||
          this.isPaintRepresentationVisibleInTree(PaintRepresentation.LABEL))
      ) || this.isReady()
    )
  }
  addToParent(e) {
    this._parent = e
  }
  removeFromParent(e) {
    this._parent = null
  }
  calculatePath(e) {
    e.unshift(this)
    return this.parent.calculatePath(e)
  }
  isPaintRepresentationSupported(e) {
    return false
  }
  isPaintRepresentationVisible(e) {
    return (
      this.isPaintRepresentationSupported(e) &&
      this._isVisiblePaintRepresentation[e]
    )
  }
  setPaintRepresentationVisible(e, t) {
    e = validatePaintRepresentation(e)
    if (!this.isPaintRepresentationSupported(e))
      throw new ProgrammingError(`Unsupported paint representation: ${e}`)
    t = !!t
    if (this._isVisiblePaintRepresentation[e] !== t) {
      this._isVisiblePaintRepresentation[e] = t
      this._map?.invalidateLayerTreeNode(this)
      this.emit('PaintRepresentationVisibilityChanged', t, e)
    }
  }
  _worldBoundsChanged(e) {
    throw new NotImplementedError(
      'LayerTreeNodes must implement _worldBoundsChanged callback'
    )
  }
  _addedToMap(e) {
    throw new NotImplementedError(
      'LayerTreeNodes must implement _addedToMap callback'
    )
  }
  _removedFromMap(e) {
    throw new NotImplementedError(
      'LayerTreeNodes must implement _removedFromMap callback'
    )
  }
  _setVisible(e) {
    if (e && !this._visibleSupported) e = false
    const t = !!e
    let r
    if (this._isVisible !== t) {
      this._isVisible = t
      r = this._map
      if (r) r.invalidateLayerTreeNode(this)
      this.emit('VisibilityChanged', e)
    }
  }
  _getRoot() {
    let e = this
    while (e._parent) e = e._parent
    return e
  }
  getNextLeafNode() {
    let e = this
    let t = this.parent
    let r = null
    let i = null
    while (!i && t) {
      r = t.getNextChildNode(e)
      while (!i && r) {
        i = r.getFirstLeafNode()
        e = r
        r = t.getNextChildNode(e)
      }
      e = t
      t = e.parent
    }
    return i
  }
  getPreviousLeafNode() {
    let e = this
    let t = this.parent
    let r = null
    let i = null
    while (!i && t) {
      r = t.getPreviousChildNode(e)
      while (!i && r) {
        i = r.getLastLeafNode()
        e = r
        r = t.getPreviousChildNode(e)
      }
      e = t
      t = e.parent
    }
    return i
  }
  _findNodeById(e) {
    let t
    function r(r) {
      if (r.id === e) {
        t = r
        return true
      }
      return false
    }
    this.accept({
      visitLayer: function (e) {
        return r(e)
          ? LayerTreeVisitor.ReturnValue.ABORT
          : LayerTreeVisitor.ReturnValue.CONTINUE
      },
      visitLayerGroup: function (e) {
        if (r(e)) return LayerTreeVisitor.ReturnValue.ABORT
        e.visitChildren(this, LayerTreeNode.VisitOrder.BOTTOM_UP)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
    })
    return t
  }
  getNextChildNode(e) {
    return null
  }
  getPreviousChildNode(e) {
    return null
  }
  getFirstLeafNode() {
    return this
  }
  getLastLeafNode() {
    return this
  }
  _constructClone() {
    throw new NotImplementedError(
      'LayerTreeNodes must implement _constructClone method'
    )
  }
  _hasBaseLayersAfter() {
    return this._layerTypeInDirection('getNextLeafNode', baseLayerPredicate)
  }
  _hasBaseLayersBefore() {
    return this._layerTypeInDirection('getPreviousLeafNode', baseLayerPredicate)
  }
  _hasNonBaseLayersAfter() {
    return this._layerTypeInDirection('getNextLeafNode', notBaseLayerPredicate)
  }
  _hasNonBaseLayersBefore() {
    return this._layerTypeInDirection(
      'getPreviousLeafNode',
      notBaseLayerPredicate
    )
  }
  _layerTypeInDirection(e, t) {
    let r = this
    do {
      r = r[e]()
      if (t(r)) return true
    } while (r)
    return false
  }
  _shadowClone() {
    const e = this._constructClone()
    e._addedToMap = Function.prototype
    e._removedfromMap = Function.prototype
    return e
  }
  accept(e) {
    throw new ProgrammingError('accept() not implemented')
  }
  isPaintRepresentationVisibleInTree(e) {
    return (
      this.isPaintRepresentationVisible(e) &&
      (null === this._parent ||
        this._parent.isPaintRepresentationVisibleInTree(e))
    )
  }
  setPaintRepresentationVisibleInTree(e, t) {
    e = validatePaintRepresentation(e)
    this.setPaintRepresentationVisible(e, t)
    if (
      this.isPaintRepresentationVisible(e) &&
      null !== this._parent &&
      !this._parent.isPaintRepresentationVisibleInTree(e)
    )
      this._parent.setPaintRepresentationVisibleInTree(e, true)
  }
  visitChildren(e, t) {}
  _findLayerTreeNodeById(e) {
    let t
    this.accept({
      visitLayer: function (r) {
        if (e(r)) {
          t = r
          return LayerTreeVisitor.ReturnValue.ABORT
        }
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
      visitLayerGroup: function (r) {
        if (e(r)) {
          t = r
          return LayerTreeVisitor.ReturnValue.ABORT
        }
        r.visitChildren(this, LayerTreeNode.VisitOrder.BOTTOM_UP)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
    })
    return t
  }
  findLayerTreeNodeById(e) {
    return this._findLayerTreeNodeById((t) => t.id === e)
  }
  findLayerGroupById(e) {
    return this._findLayerTreeNodeById(
      (t) => t.treeNodeType === LayerTreeNodeType.LAYER_GROUP && t.id === e
    )
  }
  findLayerById(e) {
    return this._findLayerTreeNodeById(
      (t) => t.treeNodeType === LayerTreeNodeType.LAYER && t.id === e
    )
  }
  get id() {
    return this._id
  }
  set id(e) {
    throw new ProgrammingError('id property is not mutable')
  }
  get label() {
    return this._label
  }
  set label(e) {
    if (this._label !== e) {
      this._label = e
      this.emit('LabelChanged', this._label)
    }
  }
  get visible() {
    return this._isVisible
  }
  set visible(e) {
    this._setVisible(e)
  }
  get visibleInTree() {
    return this.visible && (!this._parent || this._parent.visibleInTree)
  }
  set visibleInTree(e) {
    this.visible = e
    if (this.visible && this._parent && !this._parent.visibleInTree)
      this._parent.visibleInTree = true
  }
  get visibleSupported() {
    return this._visibleSupported
  }
  set visibleSupported(e) {
    this._visibleSupported = e
    if (!e) this.visible = false
  }
  get parent() {
    return this._parent
  }
  set parent(e) {
    throw new ProgrammingError('parent property is not mutable')
  }
  get map() {
    return this._map
  }
  set map(e) {
    throw new ProgrammingError('map property is not mutable')
  }
  get children() {
    return []
  }
  set children(e) {
    throw new ProgrammingError('children property is not mutable')
  }
  get supportedPaintRepresentations() {
    return []
  }
  inAllowedScaleRange() {
    return true
  }
  on(e, t, r) {
    return this._eventedDelegate.on(e, t, r)
  }
  emit(e) {
    for (
      var t = arguments.length, r = new Array(t > 1 ? t - 1 : 0), i = 1;
      i < t;
      i++
    )
      r[i - 1] = arguments[i]
    return this._eventedDelegate.emit(e, ...r)
  }
}
let alreadyWarned = false
function validatePaintRepresentation(e) {
  if (
    e === PaintRepresentation.BORDER_BODY ||
    e === PaintRepresentation.BORDER_LABEL
  ) {
    if (!alreadyWarned) {
      Log.warn(
        'PaintRepresentation.BORDER_BODY and PaintRepresentation.BORDER_LABEL are deprecated. ' +
          'Please consult PaintRepresentation API.'
      )
      alreadyWarned = true
    }
    return e === PaintRepresentation.BORDER_BODY
      ? PaintRepresentation.BOTTOM_BORDER_BODY
      : PaintRepresentation.BOTTOM_BORDER_LABEL
  }
  return e
}
;(function (e) {
  let t = (function (e) {
    e[(e['BOTTOM_UP'] = 1)] = 'BOTTOM_UP'
    e[(e['TOP_DOWN'] = 2)] = 'TOP_DOWN'
    return e
  })({})
  e.VisitOrder = t
})(LayerTreeNode || (LayerTreeNode = {}))
export { LayerTreeNode }
