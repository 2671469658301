import { squaredDistance2D } from '../../util/Cartesian.js'
export class TouchDistance {
  constructor() {
    this._sqrDistanceToEdge = 0
    this._sqrDistanceToCenter = 0
  }
  get sqrDistanceToCenter() {
    return this._sqrDistanceToCenter
  }
  set sqrDistanceToCenter(e) {
    this._sqrDistanceToCenter = e
  }
  get sqrDistanceToEdge() {
    return this._sqrDistanceToEdge
  }
  set sqrDistanceToEdge(e) {
    this._sqrDistanceToEdge = e
  }
  clear() {
    this._sqrDistanceToCenter = NaN
    this._sqrDistanceToEdge = NaN
  }
  setDistanceToEdge(e) {
    this._sqrDistanceToEdge = e
  }
  setDistanceToBounds(e, s, t) {
    this._sqrDistanceToCenter = squaredDistance2D(
      e.x + e.width / 2,
      e.y + e.height / 2,
      s,
      t
    )
  }
}
