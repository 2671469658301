import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
import { LineType } from '../../geodesy/LineType.js'
import { createBounds, createPoint } from '../../shape/ShapeFactory.js'
import { XYZBounds } from '../../shape/XYZBounds.js'
import { Constants } from '../../util/Constants.js'
import { Lang } from '../../util/Lang.js'
import { RotatedBox } from '../../util/RotatedBox.js'
import { GeoDiscretizerStatic } from '../shape/discretization/GeoDiscretizerStatic.js'
import * as AzimuthUtil from '../AzimuthUtil.js'
import { HTML5DrawCommandProto } from './HTML5DrawCommandProto.js'
import { StyleUtil } from './StyleUtil.js'
import { Ajax } from '../../util/Ajax.js'
import { squaredDistance2D } from '../../util/Cartesian.js'
import { isPromise, whenInternal } from '../../util/PromiseUtil.js'
import { isBorderTransformation } from '../../transformation/MapToBorderTransformationUtil.js'
import { PIXEL_UOM } from '../../uom/UnitOfMeasureUtil.js'
const isDefined = Lang.isDefined
const coord = createPoint(null, [0, 0])
const toCoord = createPoint(null, [0, 0])
const dropPath = new Array(6)
const tempCoord = createPoint(null, [0, 0])
const tempPoint = createPoint(null, [0, 0])
const tempBounds1 = createBounds(null, [0, 0, 0, 0])
const tempBounds2 = createBounds(null, [0, 0, 0, 0])
const rotatedBox1 = new RotatedBox()
const rotatedBox2 = new RotatedBox()
export class HTML5DrawIconCommand extends HTML5DrawCommandProto {
  constructor(t, i, o, s, e) {
    super(t, i, s?.zOrder ?? 0)
    this._maxPadding = 0
    this._stemStrokeStyle = null
    this._image = null
    this._coordinate = null
    this._width = '0px'
    this._height = '0px'
    this._ox = 0
    this._oy = 0
    this._rotationRad = 0
    this._heading = Number.NaN
    this._anchorX = '0px'
    this._anchorY = '0px'
    this._opacity = 1
    this._widthPX = 0
    this._heightPX = 0
    this._anchorXPX = 0
    this._anchorYPX = 0
    this._isWorldSized = false
    this._uom = PIXEL_UOM
    this._worldWidth = 0
    this._worldHeight = 0
    this._isWorldOffsetX = false
    this._worldOffsetX = 0
    this._offsetXUom = PIXEL_UOM
    this._isWorldOffsetY = false
    this._worldOffsetY = 0
    this._offsetYUom = PIXEL_UOM
    this._initialize(t, o)
    if (s) this._initializeStyle(s, e)
  }
  _initialize(t, i) {
    const o = GeoDiscretizerStatic.discretize(i, {
      modelReference: i.reference,
      worldReference: t,
      lineType: LineType.SHORTEST_DISTANCE,
    })
    if (!o?.points) return
    this._coordinate = createPoint(t, [
      o.points[0].x,
      o.points[0].y,
      o.points[0].z,
    ])
    this.bounds = new XYZBounds(t)
    this.bounds.setTo3D(o.points[0].x, 0, o.points[0].y, 0, o.points[0].z, 0)
  }
  _initializeStyle(t, i) {
    this._width = t.width
    this._height = t.height
    this._ox = t.offsetX
    this._oy = t.offsetY
    this._rotationRad = t.rotation * Constants.DEG2RAD
    this._heading = t.heading
    this._anchorX = isDefined(t.anchorX) ? t.anchorX : '50%'
    this._anchorY = isDefined(t.anchorY) ? t.anchorY : '50%'
    this._opacity = t.opacity
    this._uom = t.uom
    this._isWorldSized = t.isWorldSized
    this._worldWidth = t.worldWidth
    this._worldHeight = t.worldHeight
    this._isWorldOffsetX = t.isWorldOffsetX
    this._worldOffsetX = t.worldOffsetX
    this._offsetXUom = t.offsetXUom
    this._isWorldOffsetY = t.isWorldOffsetY
    this._worldOffsetY = t.worldOffsetY
    this._offsetYUom = t.offsetYUom
    whenInternal(
      StyleUtil.resolveIconImage(t.url, t.image, t.credentials),
      (o) => {
        this.configureWidthAndAnchor(o)
        const s = (t) => {
          this._image = t
          if (i) i()
        }
        if (Ajax.isSVGImage(t.url)) {
          const i = StyleUtil.resolveStyleImagePixels(
            this._width,
            this._height,
            o.width,
            o.height
          )
          if ((o && i.width !== o.width) || (o && i.height !== o.height)) {
            const i = StyleUtil.resolveIconImage(
              t.url,
              t.image,
              t.credentials,
              this._widthPX,
              this._heightPX
            )
            if (isPromise(i)) return i.then(s)
            s(i)
          } else s(o)
        } else s(o)
      }
    )
    this._stemStrokeStyle = t.stem
  }
  isReady() {
    return !!this._image
  }
  configureWidthAndAnchor(t) {
    const i = StyleUtil.getIconStyleValuesInPx(
      this._width,
      this._height,
      this._anchorX,
      this._anchorY,
      t.width,
      t.height
    )
    this._widthPX = i.width
    this._heightPX = i.height
    this._anchorXPX = i.anchorX
    this._anchorYPX = i.anchorY
    const o = Math.max(this._widthPX - this._anchorXPX, this._anchorXPX)
    const s = Math.max(this._heightPX - this._anchorYPX, this._anchorYPX)
    const e = Math.abs(Math.sin(this._rotationRad))
    const h = Math.abs(Math.cos(this._rotationRad))
    const r = s * e + o * h
    const n = s * h + o * e
    this._maxPadding = Math.ceil(Math.max(r, n))
  }
  getMaximumPadding(t) {
    const i = this.getOffsetXInPixels(t)
    const o = this.getOffsetYInPixels(t)
    if (this._isWorldSized) {
      const s = t.toPixels(this._worldWidth, this._uom)
      const e = t.toPixels(this._worldHeight, this._uom)
      const h = s / this._widthPX
      const r = e / this._heightPX
      return Math.ceil(
        Math.max(
          this._maxPadding * h + Math.abs(i),
          this._maxPadding * r + Math.abs(o)
        )
      )
    }
    return this._maxPadding + Math.max(Math.abs(i), Math.abs(o))
  }
  interacts(t, i, o) {
    if (!this._image || !this._coordinate) return false
    i._forwardBoundsCoords(t, tempBounds1)
    rotatedBox1.configure(
      tempBounds1.x,
      tempBounds1.y,
      tempBounds1.width,
      tempBounds1.height,
      0
    )
    let s = 1
    let e = 1
    if (this._isWorldSized) {
      const t = o.toPixels(this._worldWidth, this._uom)
      const i = o.toPixels(this._worldHeight, this._uom)
      s = t / this._widthPX
      e = i / this._heightPX
    }
    const h = this.getOffsetXInPixels(o)
    const r = this.getOffsetYInPixels(o)
    i._forward(this._coordinate, tempCoord)
    tempBounds2.setTo2D(
      tempCoord.x + s * -this._anchorXPX + h,
      s * this._widthPX,
      tempCoord.y + e * -this._anchorYPX + r,
      e * this._heightPX
    )
    rotatedBox2.configure(
      tempBounds2.x,
      tempBounds2.y,
      tempBounds2.width,
      tempBounds2.height,
      this._rotationRad + this._getMapHeadingAngle(i),
      s * this._anchorXPX,
      e * this._anchorYPX
    )
    return rotatedBox1.intersects(rotatedBox2)
  }
  strokeInteracts(t, i, o) {
    return false
  }
  mapAnchorPointSFCT(t, i) {
    if (!this._coordinate) return false
    i.move3DToPoint(this._coordinate)
    return true
  }
  mapAnchorPointsSFCT(t, i) {
    if (!this._coordinate) return
    this.mapAnchorPointSFCT(t, tempPoint)
    i.add3D(
      tempPoint.x,
      tempPoint.y,
      tempPoint.z,
      tempPoint.x,
      tempPoint.y,
      tempPoint.z
    )
  }
  _getMapHeadingAngle(t) {
    if (isNaN(this._heading) || null === this._coordinate) return 0
    try {
      const i = undefined
      return (
        AzimuthUtil.getAzimuthAtOrientedPoint(
          this._coordinate,
          this._heading,
          t,
          t.inputReference
        ) * Constants.DEG2RAD
      )
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
      return 0
    }
  }
  draw(t, i, o) {
    if (!this._image || !this._coordinate) return
    if (isBorderTransformation(i)) if (!i.canDraw(this._coordinate)) return
    i._forward(this._coordinate, coord)
    if (this._stemStrokeStyle) {
      i._inverse(coord, toCoord)
      if (
        isBorderTransformation(i) &&
        i.canDrawStem(this._coordinate, toCoord)
      ) {
        dropPath[0] = toCoord.x
        dropPath[1] = toCoord.y
        dropPath[2] = 0
        dropPath[3] = this._coordinate.x
        dropPath[4] = this._coordinate.y
        dropPath[5] = 0
        this._stemStrokeStyle.paintPathMapCoords(t, dropPath, i, false)
      }
    }
    let s, e
    t.save()
    if (this._opacity < 1) t.globalAlpha = this._opacity
    let h = 1
    let r = 1
    if (this._isWorldSized) {
      const t = o.toPixels(this._worldWidth, this._uom)
      const i = o.toPixels(this._worldHeight, this._uom)
      h = t / this._widthPX
      r = i / this._heightPX
    }
    const n = this.getOffsetXInPixels(o)
    const a = this.getOffsetYInPixels(o)
    if (0 !== this._rotationRad || !isNaN(this._heading)) {
      const o = this._rotationRad + this._getMapHeadingAngle(i)
      t.translate(coord.x + n, coord.y + a)
      t.rotate(o)
      t.translate(h * -this._anchorXPX, r * -this._anchorYPX)
      s = 0
      e = 0
    } else {
      s = Math.round(coord.x + h * -this._anchorXPX + n)
      e = Math.round(coord.y + r * -this._anchorYPX + a)
    }
    t.drawImage(this._image, s, e, h * this._widthPX, r * this._heightPX)
    t.restore()
  }
  getMapDistanceSquared(t, i, o, s) {
    t.mapToViewTransformationInternal._forward(this._coordinate, tempCoord)
    let e = 1
    let h = 1
    if (this._isWorldSized) {
      const i = t.worldSizeSupport.toPixels(this._worldWidth, this._uom)
      const o = t.worldSizeSupport.toPixels(this._worldHeight, this._uom)
      e = i / this._widthPX
      h = o / this._heightPX
    }
    const r = this.getOffsetXInPixels(t.worldSizeSupport)
    const n = this.getOffsetYInPixels(t.worldSizeSupport)
    tempBounds2.setTo2D(
      tempCoord.x + e * -this._anchorXPX + r,
      e * this._widthPX,
      tempCoord.y + h * -this._anchorYPX + n,
      h * this._heightPX
    )
    rotatedBox2.configure(
      tempBounds2.x,
      tempBounds2.y,
      tempBounds2.width,
      tempBounds2.height,
      this._rotationRad,
      e * this._anchorXPX,
      h * this._anchorYPX
    )
    tempBounds2.setTo2D(
      rotatedBox2.getLeft(),
      rotatedBox2.getOBBWidth(),
      rotatedBox2.getTop(),
      rotatedBox2.getOBBHeight()
    )
    t.mapToViewTransformationInternal._inverseBoundsCoords(
      tempBounds2,
      tempBounds1
    )
    s.setDistanceToBounds(tempBounds1, i, o)
    s.setDistanceToEdge(
      squaredDistance2D(this._coordinate.x, this._coordinate.y, i, o)
    )
  }
  get width() {
    return this._width
  }
  get height() {
    return this._height
  }
  getOffsetXInPixels(t) {
    return this._isWorldOffsetX
      ? t.toPixels(this._worldOffsetX, this._offsetXUom)
      : this._ox
  }
  getOffsetYInPixels(t) {
    return this._isWorldOffsetY
      ? t.toPixels(this._worldOffsetY, this._offsetYUom)
      : this._oy
  }
}
