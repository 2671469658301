import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import { PhotonLineAndAreaCommand } from '../feature/photon/command/PhotonLineAndAreaCommand.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { PhotonImageProvider } from '../feature/photon/PhotonImageProvider.js'
import { StyleUtil } from '../style/StyleUtil.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { HatchedImageBuilder } from '../style/HatchedImageBuilder.js'
import { isPromise } from '../../util/PromiseUtil.js'
import { formatLabelForPhoton } from '../LayerUtil.js'
import { isDefined } from '../../util/Lang.js'
const EXTENT_PAINT_SUPPORT_RENDER_PASS_ID = 1
const redHatchedRectangleLineStyle = { color: 'rgb(255,0,0)', width: 2 }
const redHatchedRectangleFillStyle = {
  image: new HatchedImageBuilder()
    .patterns([HatchedImageBuilder.Pattern.SLASH])
    .backgroundColor('rgba(255,0,0,191)')
    .lineWidth(1.5)
    .lineColor('rgba(255,0,0)')
    .patternSize(5, 5)
    .build(),
}
export class PhotonTileSetLayerExtentPaintSupport {
  _objectTracker = new ObjectReleaseTracker()
  _canvasCommands = []
  constructor(e, t, o, a, r, n) {
    this._photonGraphics = e.photonGraphics
    this._shapePainter = this._objectTracker.track(
      Photon.ShapePainter.create(
        e.photonView,
        e.photonGraphics,
        formatLabelForPhoton('eps_'.concat(a))
      )
    )
    this._shapePainter.setRegularRenderPass(EXTENT_PAINT_SUPPORT_RENDER_PASS_ID)
    const s = t.reference
    const i = s.referenceType === ReferenceType.GEOCENTRIC
    const h = this._shapePainter.getMaxGLLineWidth()
    this._imageProvider = this._objectTracker.track(
      new PhotonImageProvider(e.imageDecoder, StyleUtil.getFallbackIconUrl)
    )
    this._photonLineAndAreaCommand = this._objectTracker.track(
      new PhotonLineAndAreaCommand(
        i,
        s,
        this._shapePainter,
        '',
        h,
        e.referenceProvider,
        this._imageProvider,
        t.worldSizeSupport,
        e.shapeDiscretizer,
        false,
        false
      )
    )
    if (!isDefined(r) || (isDefined(r) && Object.keys(r).length > 0)) {
      const e = {
        type: 'shape',
        shape: o,
        style: { stroke: r ?? redHatchedRectangleLineStyle },
      }
      e.objectId = 0
      e.selected = false
      e.renderPassId = EXTENT_PAINT_SUPPORT_RENDER_PASS_ID
      this._canvasCommands.push(e)
    }
    if (!isDefined(n) || (isDefined(r) && Object.keys(n).length > 0)) {
      const e = {
        type: 'shape',
        shape: o,
        style: { fill: n ?? redHatchedRectangleFillStyle },
      }
      e.objectId = 1
      e.selected = false
      e.renderPassId = EXTENT_PAINT_SUPPORT_RENDER_PASS_ID
      this._canvasCommands.push(e)
    }
    this.addGeoCanvasCommandsToPhotonCommand(
      this._canvasCommands,
      EXTENT_PAINT_SUPPORT_RENDER_PASS_ID
    )
  }
  paint(e, t, o, a) {
    if (this._canvasCommands.length > 0)
      return this._shapePainter.paint(this._photonGraphics, false, e, t, o, a)
    return true
  }
  release() {
    this._objectTracker.release()
  }
  addGeoCanvasCommandsToPhotonCommand(e, t) {
    const o = this._photonLineAndAreaCommand.getDrawItems(e)
    for (let e = 0; e < o.length; e++) {
      const a = o[e]
      a.renderPassId = t
      this._photonLineAndAreaCommand.resolveStyle(a)
      if (isPromise(a.style))
        a.style.then((e) => {
          a.style = e
          this._photonLineAndAreaCommand.add(a)
        })
      else this._photonLineAndAreaCommand.add(a)
    }
  }
}
