import { createHTML5Canvas, HTML5_CANVAS_SUPPORTED } from '../HTML5Canvas.js'
const NO_BORDER = { left: 0, right: 0, top: 0, bottom: 0 }
export class CanvasDrawingSurface {
  constructor(t, s) {
    this._width = 0
    this._height = 0
    this._containerNode = t
    const i = s.width
    const e = s.height
    const h = s.border || NO_BORDER
    const n = h.left
    const o = h.bottom
    const a = h.top
    const _ = h.right
    const c = i + n + _
    const r = e + o + a
    this._left = n
    this._right = _
    this._top = a
    this._bottom = o
    this._clipped = n > 0 || _ > 0 || a > 0 || o > 0
    this._canvas = createHTML5Canvas(i, e)
    this._containerNode.appendChild(this._canvas)
    this._canvas.style.position = 'absolute'
    this._canvas.style.top = '0'
    this._canvas.style.left = '0'
    if (s?.disallowInteraction) this._canvas.style.pointerEvents = 'none'
    this._context = this._canvas.getContext('2d')
    this.resetDefaultState()
    this.resizeIgnoreBorder(c, r)
  }
  get right() {
    return this._right
  }
  clip(t, s) {
    if (!this._clipped) return
    if (null === this._context) return
    this._context.save()
    this._context.beginPath()
    this._context.moveTo(this._left, this._top)
    this._context.lineTo(t - this._right, this._top)
    this._context.lineTo(t - this._right, s - this._bottom)
    this._context.lineTo(this._left, s - this._bottom)
    this._context.lineTo(this._left, this._top)
    this._context.clip()
  }
  destroy() {
    this._context = null
    this._canvas.parentNode?.removeChild(this._canvas)
  }
  resizeIgnoreBorder(t, s) {
    this._width = t
    this._height = s
    this._canvas.setAttribute('width', `${this._width}px`)
    this._canvas.setAttribute('height', `${this._height}px`)
    this.clip(this._canvas.width, this._canvas.height)
    this.resetDefaultState()
  }
  resetDefaultState() {
    this._context.lineJoin = 'round'
  }
  getContext2d() {
    return this._context
  }
  getCanvasNode() {
    return this._canvas
  }
  setZIndex(t) {
    this._canvas.style.zIndex = t.toString()
  }
  static isSupported() {
    return HTML5_CANVAS_SUPPORTED
  }
  clear() {
    this._context?.clearRect(0, 0, this._canvas.width, this._canvas.height)
  }
}
