import {
  SimpleBounds,
  simpleBoundsContains2D,
  simpleBoundsInteracts2D,
} from './SimpleBounds.js'
const BRANCH_FACTOR = 20
const NODE = 0
const LEAF = 2
const TYPE_MASK = 2
const COORD_MASK = 1
export class RTreeNode {
  constructor(t) {
    this.fType = 0
    this.fChildren = new Array(BRANCH_FACTOR)
    this.fChildCount = 0
    this.bounds = new SimpleBounds()
    if (t.firstChild) {
      this.fType = NODE | t.coordType
      this.bounds.setTo(t.firstChild.bounds)
      this.bounds.setTo(t.firstChild.bounds)
      this.fChildren[this.fChildCount++] = t.firstChild
      if (t.secondChild) this.addChild(t.secondChild)
    } else if (t.firstBounds) {
      this.fType = LEAF | t.coordType
      this.bounds.setTo(t.firstBounds.bounds)
      this.fChildren[this.fChildCount++] = t.firstBounds
    }
  }
  add(t) {
    const s = this.fChildren
    let n
    if ((this.fType & TYPE_MASK) === NODE) {
      const e = undefined
      const i = s[bestInsertionIndex(s, this.fChildCount, t)].add(t)
      if (null != i)
        if (this.fChildCount < s.length) s[this.fChildCount++] = i
        else {
          n = new RTreeNode({
            firstChild: i,
            coordType: this.fType & COORD_MASK,
          })
          this.redistributeChildren(n)
          return n
        }
      this.bounds.setTo2DUnion(t.bounds, this.fType & COORD_MASK)
      return null
    } else if (this.fChildCount < s.length) {
      s[this.fChildCount++] = t
      this.bounds.setTo2DUnion(t.bounds, this.fType & COORD_MASK)
      return null
    } else {
      n = new RTreeNode({ firstBounds: t, coordType: this.fType & COORD_MASK })
      this.redistributeChildren(n)
      return n
    }
  }
  remove(t) {
    let s =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : false
    const n = this.fChildren
    let e
    const i = this.fChildCount
    if ((this.fType & TYPE_MASK) === NODE) {
      if (
        s ||
        i <= 1 ||
        simpleBoundsContains2D(this.bounds, t.bounds, this.fType & COORD_MASK)
      )
        for (e = 0; e < i; e++) {
          const i = n[e]
          if (i.remove(t, s)) {
            this.recomputeBounds()
            if (0 === i.getChildCount()) {
              n[e--] = n[--this.fChildCount]
              n[this.fChildCount] = null
            }
            return true
          }
        }
      return false
    } else {
      if (
        s ||
        i <= 1 ||
        simpleBoundsInteracts2D(this.bounds, t.bounds, this.fType & COORD_MASK)
      )
        for (let s = 0; s < i; s++)
          if (n[s] === t) {
            n[s--] = n[--this.fChildCount]
            n[this.fChildCount] = null
            this.recomputeBounds()
            return true
          }
      return false
    }
  }
  getChildCount() {
    return this.fChildCount
  }
  getChild(t) {
    return this.fChildren[t]
  }
  setFirstChild(t) {
    this.fChildCount = 0
    this.bounds.setTo(t.bounds)
    this.fChildren[this.fChildCount++] = t
  }
  addChild(t) {
    this.bounds.setTo2DUnion(t.bounds, this.fType & COORD_MASK)
    this.fChildren[this.fChildCount++] = t
  }
  recomputeBounds() {
    const t = this.fChildCount
    if (0 === t) return
    const s = this.fChildren
    const n = this.bounds
    n.setTo(s[0].bounds)
    for (let e = 1; e < t; e++)
      n.setTo2DUnion(s[e].bounds, this.fType & COORD_MASK)
  }
  redistributeChildren(t) {
    const s = undefined
    const n = t.fChildren[0]
    const e = n.bounds
    let i = e.left
    let o = e.bottom
    let d = e.right
    let l = e.top
    let h = -1
    let u = -1
    let f = -1
    let r = -1
    const C = this.fChildren
    const c = this.fChildCount
    let p
    let b
    for (b = 0; b < c; b++) {
      p = C[b]
      const t = p.bounds
      let s = t.left
      if (s < i) {
        i = s
        h = b
      }
      let n = t.bottom
      if (n < o) {
        o = n
        u = b
      }
      s = t.right
      if (s > d) {
        d = s
        f = b
      }
      n = t.top
      if (n > l) {
        l = n
        r = b
      }
    }
    let T
    let a
    if (d - i > l - o && h !== f) {
      T = h
      a = f
    } else if (u !== r) {
      T = u
      a = r
    } else if (h !== u) {
      T = h
      a = u
    } else {
      if (-1 !== h) {
        t.setFirstChild(C[h])
        C[h] = n
        this.recomputeBounds()
      }
      return
    }
    if (-1 === T) {
      p = C[a]
      C[a] = C[0]
      this.setFirstChild(p)
    } else if (-1 === a) {
      p = C[T]
      C[T] = C[0]
      this.setFirstChild(p)
    } else {
      const s = C[T]
      C[T] = n
      const e = C[a]
      C[a] = C[0]
      t.setFirstChild(s)
      this.setFirstChild(e)
    }
    for (b = 1; b < c; b++) {
      p = C[b]
      if (boundsExtensionCost(this, p) < boundsExtensionCost(t, p))
        this.addChild(p)
      else t.addChild(p)
    }
    for (b = this.fChildCount; b < c; b++) C[b] = null
  }
  static applyToElements = applyToElements
  static applyOnInteract2DBounds = applyOnInteract2DBounds
}
function applyToElements(t, s) {
  const n = t.fChildCount
  const e = t.fChildren
  let i
  if ((t.fType & TYPE_MASK) === NODE)
    for (i = 0; i < n; i += 1) applyToElements(e[i], s)
  else for (i = 0; i < n; i += 1) s(e[i])
}
function applyOnInteract2DBounds(t, s, n) {
  const e = t.fType & COORD_MASK
  const i = t.fChildren
  const o = t.fChildCount
  let d
  let l
  if ((t.fType & TYPE_MASK) === NODE)
    for (d = 0; d < o; d += 1) {
      l = i[d]
      if (simpleBoundsContains2D(s, l.bounds, e)) applyToElements(l, n)
      else if (simpleBoundsInteracts2D(s, l.bounds, e))
        applyOnInteract2DBounds(l, s, n)
    }
  else
    for (d = 0; d < o; d += 1) {
      l = i[d]
      if (simpleBoundsInteracts2D(l.bounds, s, e)) n(l)
    }
}
function bestInsertionIndex(t, s, n) {
  if (1 === s) return 0
  let e = 0
  let i = Number.MAX_VALUE
  let o = Number.MAX_VALUE
  for (let d = 0; d < s; d++) {
    const s = t[d]
    const l = boundsExtensionCost(s.bounds, n.bounds)
    const h = s.fChildCount
    if (l < i || (l === i && h < o)) {
      i = l
      o = h
      e = d
    }
  }
  return e
}
function boundsExtensionCost(t, s) {
  const n = t.left
  const e = t.bottom
  const i = t.right
  const o = t.top
  const d = i - n
  const l = o - e
  const h = s.left
  const u = s.bottom
  const f = s.right
  const r = s.top
  const C = undefined
  const c = undefined
  const p = undefined
  const b = undefined
  const T = undefined
  const a = undefined
  let O = d + l
  O *= O
  O *= O
  let D =
    (i > f ? i : f) - (n < h ? n : h) + ((o > r ? o : r) - (e < u ? e : u))
  D *= D
  D *= D
  return 1.01 * D - O
}
