import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { greatCircleDistance } from '../geodesy/SphereUtil.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Azimuthal } from './Azimuthal.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('DutchStereoGraphic')
export class DutchStereographic extends Azimuthal {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._originSinLat = -1
    this._originSinLon = -1
    this._originCosLat = -1
    this._originCosLon = -1
    this._tempLLH = new LLHPoint()
    this.setOriginLon(t)
    this.setOriginLat(n)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    const t = this.getOriginLat() * Constants.DEG2RAD
    const n = this.getOriginLon() * Constants.DEG2RAD
    this._originSinLat = Math.sin(t)
    this._originSinLon = Math.sin(n)
    this._originCosLat = Math.cos(t)
    this._originCosLon = Math.cos(n)
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, n, s) {
    if (this.inLonLatBounds(t)) {
      const i = Math.cos(t.x * Constants.DEG2RAD)
      const o = Math.cos(t.y * Constants.DEG2RAD)
      const a = Math.sin(t.x * Constants.DEG2RAD)
      const r = Math.sin(t.y * Constants.DEG2RAD)
      const e = o * (a * this._originCosLon - i * this._originSinLon)
      const h =
        this._originCosLat * r -
        this._originSinLat *
          o *
          (i * this._originCosLon + a * this._originSinLon)
      const c =
        (2 * n) /
        (1 +
          this._originSinLat * r +
          this._originCosLat *
            o *
            (i * this._originCosLon + a * this._originSinLon))
      s.x = e * c
      s.y = h * c
    } else throw sharedOutOfBoundsError
  }
  geodetic2cartesianOnEllipsoidSFCT(t, n, s) {
    if (this.inLonLatBounds(t)) {
      if (
        Math.abs(t.x - this.getOriginLon()) < 1e-6 &&
        Math.abs(t.y - this.getOriginLat()) < 1e-6
      ) {
        s.x = 0
        s.y = 0
        return
      }
      const i = 1.00047585668
      const o = i * (t.x - this.getOriginLon())
      if (Math.abs(o) > 90) throw sharedOutOfBoundsError
      const a = Math.sin(o * Constants.DEG2RAD)
      const r = this.conformalSphericalLatitude(n, this.getOriginLat())
      const e = this.conformalSphericalLatitude(n, t.y)
      const h = Math.cos(r * Constants.DEG2RAD)
      const c = Math.cos(e * Constants.DEG2RAD)
      const L = Math.sin(r * Constants.DEG2RAD)
      const g = Math.sin(e * Constants.DEG2RAD)
      const u = Math.sin(((e - r) * Constants.DEG2RAD) / 2)
      const d = Math.sin(
        Constants.DEG2RAD * (i / 2) * (t.x - this.getOriginLon())
      )
      const M = u * u + d * d * c * h
      const l = Math.sqrt(M)
      const D = Math.sqrt(1 - M)
      const O = 2 * l * D
      const C = 1 - 2 * M
      const p = undefined
      const E =
        (2 *
          ((n.a * Math.sqrt(1 - n.e2)) /
            (1 - n.e2 * this._originSinLat * this._originSinLat)) *
          l) /
        D
      s.x = (E * a * c) / O
      s.y = (E * (g - L * C)) / (h * O)
    } else throw sharedOutOfBoundsError
  }
  conformalSphericalLatitude(t, n) {
    const s = t.e
    const i = n * Constants.DEG2RAD
    const o = s * Math.sin(i)
    const a = 0.003773953832
    const r = 1.00047585668
    const e = Math.tan(i / 2 + Math.PI / 4)
    const h = Math.pow((1 - o) / (1 + o), s / 2)
    const c = undefined
    return (
      (2 * Math.atan(Math.pow(e * h, r) * Math.exp(a)) - Math.PI / 2) *
      Constants.RAD2DEG
    )
  }
  cartesian2geodeticOnSphereSFCT(t, n, s) {
    if (this.inWorldBoundsOnSphere(t, n)) {
      const i = t.x
      const o = t.y
      const a = Math.sqrt(i * i + o * o)
      if (a < 0.001) {
        s.x = this.getOriginLon()
        s.y = this.getOriginLat()
        return
      }
      const r = 2 * Math.atan2(a, 2 * n)
      const e = Math.sin(r)
      const h = Math.cos(r)
      const c = h * this._originSinLat + (o * e * this._originCosLat) / a
      const L = undefined
      const g =
        Math.atan2(
          i * e,
          a * this._originCosLat * h - o * this._originSinLat * e
        ) * Constants.RAD2DEG
      s.x = normalizeLon(this.getOriginLon() + g)
      s.y = Math.asin(c) * Constants.RAD2DEG
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnEllipsoidSFCT(t, n, s) {
    if (this.inWorldBoundsOnEllipsoid(t, n)) {
      const i =
        (n.a * Math.sqrt(1 - n.e2)) /
        (1 - n.e2 * this._originSinLat * this._originSinLat)
      const o = 1.00047585668
      const a = t.x
      const r = t.y
      const e = Math.sqrt(a * a + r * r)
      if (e < 0.001) {
        s.x = this.getOriginLon()
        s.y = this.getOriginLat()
        return
      }
      const h = 2 * Math.atan2(e, 2 * i)
      const c = Math.sin(h)
      const L = Math.cos(h)
      const g = this.conformalSphericalLatitude(n, this.getOriginLat())
      const u = Math.cos(g * Constants.DEG2RAD)
      const d = undefined
      const M = L * Math.sin(g * Constants.DEG2RAD) + (r * c * u) / e
      const l = Math.asin(M) * Constants.RAD2DEG
      const D = Math.sqrt(1 - M * M)
      let O = (1 / o) * Math.asin((a * c) / (e * D))
      if (isNaN(O)) O = 0
      const C = O * Constants.RAD2DEG
      const p = normalizeLon(this.getOriginLon() + C)
      const E = this.inverseConformalSphericalLatitude(n, l)
      const S = Math.sin(((l - g) * Constants.DEG2RAD) / 2)
      const f = Math.sin(
        Constants.DEG2RAD * (o / 2) * (p - this.getOriginLon())
      )
      const m = S * S + f * f * D * u
      const _ = undefined
      const A = undefined
      const G = (2 * i * Math.sqrt(m)) / Math.sqrt(1 - m)
      if (Math.abs(e - G) > 0.1) throw sharedOutOfBoundsError
      s.x = p
      s.y = E
    } else throw sharedOutOfBoundsError
  }
  inverseConformalSphericalLatitude(t, n) {
    const s = t.e
    const i = n * Constants.DEG2RAD
    const o = 0.003773953832
    const a = 1.00047585668
    let r =
      2 *
        Math.atan(
          Math.pow(Math.tan(i / 2 + Math.PI / 4), 1 / a) / Math.exp(o / a)
        ) -
      Math.PI / 2
    let e, h, c
    for (let t = 0; t < 4; t++) {
      e = s * Math.sin(r)
      h = Math.pow(Math.tan(i / 2 + Math.PI / 4), 1 / a)
      c = Math.pow((1 + e) / (1 - e), s / 2)
      r = 2 * Math.atan((h * c) / Math.exp(o / a)) - Math.PI / 2
    }
    return r * Constants.RAD2DEG
  }
  inLonLatBounds(t) {
    return greatCircleDistance(this.getOrigin(), t) < 90 + 1e-7
  }
  inWorldBoundsOnSphere(t, n) {
    const s = 2 * n * (1 + 1e-15)
    const i = t.x / s
    const o = t.y / s
    return i * i + o * o < 4
  }
  boundaryLons(t) {
    if (
      (this.getOriginLat() > 89.9999999 && t >= 0) ||
      (this.getOriginLat() < -89.9999999 && t < 0)
    )
      return [[-180, 180]]
    else if (
      (this.getOriginLat() > 89.9999999 && t < 0) ||
      (this.getOriginLat() < -89.9999999 && t >= 0)
    )
      return []
    else {
      const n =
        (-Math.tan(t * Constants.DEG2RAD) * this._originSinLat) /
        this._originCosLat
      if (n <= -0.9999999 || n >= 0.9999999) {
        this._tempLLH.move2D(this.getOriginLon(), t)
        return this.inLonLatBounds(this._tempLLH) ? [[-180, 180]] : []
      } else {
        let s = Math.acos(n) * Constants.RAD2DEG
        const i = normalizeLon(this.getOriginLon() + s)
        const o = normalizeLon(this.getOriginLon() - s)
        s = (i + o) / 2
        this._tempLLH.move2D(s, t)
        return this.inLonLatBounds(this._tempLLH)
          ? [[Math.min(i, o), Math.max(i, o)]]
          : [[Math.max(i, o), Math.min(i, o)]]
      }
    }
  }
  boundaryLats(t) {
    if (this.getOriginLat() < 1e-7 && this.getOriginLat() > -1e-7) {
      this._tempLLH.move2D(t, 0)
      return this.inLonLatBounds(this._tempLLH) ? [[-90, 90]] : []
    } else {
      const n =
        Math.atan(
          (-Math.cos((t - this.getOriginLon()) * Constants.DEG2RAD) *
            this._originCosLat) /
            this._originSinLat
        ) * Constants.RAD2DEG
      return this.getOriginLat() > 0 ? [[n, 90]] : [[-90, n]]
    }
  }
  cartesianBoundsOnSphereSFCT(t, n) {
    const s = 2 * t * (1 + 1e-15)
    n.setTo2D(-s, 2 * s, -s, 2 * s)
  }
  cartesianBoundsOnEllipsoidSFCT(t, n) {
    const s =
      (t.a * Math.sqrt(1 - t.e2)) /
      (1 - t.e2 * this._originSinLat * this._originSinLat)
    this.cartesianBoundsOnSphereSFCT(s, n)
  }
  inWorldBoundsOnEllipsoid(t, n) {
    const s =
      (n.a * Math.sqrt(1 - n.e2)) /
      (1 - n.e2 * this._originSinLat * this._originSinLat)
    return this.inWorldBoundsOnSphere(t, s)
  }
  encode() {
    return {
      type: 'DutchStereographic',
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.DUTCH_STEREOGRAPHIC + ProjectionType.AZIMUTHAL
  }
}
