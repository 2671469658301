import { Lang } from '../../../util/Lang.js'
import { getReference } from '../../../reference/ReferenceProvider.js'
import { WFSVersions } from '../../WFSVersions.js'
import { FilterElementType } from '../FilterElementType.js'
import { shouldSwapAxes } from '../../../reference/AxisInformationUtil.js'
import { OGCEncoder } from './OGCEncoder.js'
const EPSG_URL_REGEX = /epsg.xml#(4326)$/
export class OGCEncoder100 extends OGCEncoder {
  constructor() {
    let e
    let t
    let r
    super(
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : WFSVersions.V100,
      arguments.length > 1 && void 0 !== arguments[1]
        ? arguments[1]
        : 'http://www.opengis.net/ogc',
      arguments.length > 2 && void 0 !== arguments[2]
        ? arguments[2]
        : 'http://www.opengis.net/gml'
    )
  }
  visitLiteral(e) {
    this.xmlWriter.beginNode('Literal')
    this.xmlWriter.value(e.value)
    this.xmlWriter.endNode()
  }
  visitIdentifiers(e) {
    const t = e.featureIds
    const r = e.gmlObjectIds
    let i
    let s
    for (i = 0, s = r.length; i < s; i++) {
      this.xmlWriter.beginNode('GmlObjectId')
      this.xmlWriter.attribute('id', r[i], this.gmlPrefix)
      this.xmlWriter.endNode()
    }
    for (i = 0, s = t.length; i < s; i++) {
      this.xmlWriter.beginNode('FeatureId')
      this.xmlWriter.attribute('fid', t[i])
      this.xmlWriter.endNode()
    }
  }
  visitOGCFunction(e) {
    const t = e.name
    const r = e.args
    this.xmlWriter.beginNode('Function')
    this.xmlWriter.attribute('name', t)
    for (let e = 0; e < r.length; e++) r[e].accept(this)
    this.xmlWriter.endNode()
  }
  visitBboxOperator(e) {
    this.xmlWriter.beginNode('BBOX')
    const t = e.geometryName
    if (t) t.accept(this)
    this.encodeBboxAsGML(e.minX, e.minY, e.maxX, e.maxY, e.srsName, e.swapAxes)
    this.xmlWriter.endNode()
  }
  visitNullOperator(e) {
    this.encodeNullNil('PropertyIsNull', e.expression)
  }
  visitExistsOperator(e) {
    this.encodeNullNil('PropertyExists', e.expression)
  }
  visitIsLikeOperator(e) {
    this.xmlWriter.beginNode('PropertyIsLike')
    this.xmlWriter.attribute('wildCard', e.wildCard)
    this.xmlWriter.attribute('singleChar', e.singleChar)
    this.xmlWriter.attribute('escapeChar', e.escapeChar)
    this.encodeMatchCase(e.matchCase)
    e.property.accept(this)
    e.literal.accept(this)
    this.xmlWriter.endNode()
  }
  visitAndOperator(e) {
    this.encodeBinaryLogic('And', e.conditions)
  }
  visitOrOperator(e) {
    this.encodeBinaryLogic('Or', e.conditions)
  }
  visitNotOperator(e) {
    const t = e.condition
    if (!t) return
    this.xmlWriter.beginNode('Not')
    t.accept(this)
    this.xmlWriter.endNode()
  }
  visitBinaryComparisonOperator(e) {
    const t = FilterElementType.Comparison.Binary
    let r = ''
    switch (e.TYPE) {
      case t.EqualTo:
        r = 'PropertyIsEqualTo'
        break
      case t.NotEqualTo:
        r = 'PropertyIsNotEqualTo'
        break
      case t.GreaterThan:
        r = 'PropertyIsGreaterThan'
        break
      case t.GreaterThanOrEqualTo:
        r = 'PropertyIsGreaterThanOrEqualTo'
        break
      case t.LessThanOrEqualTo:
        r = 'PropertyIsLessThanOrEqualTo'
        break
      case t.LessThan:
        r = 'PropertyIsLessThan'
        break
      default:
        throw new Error('Not implemented: ' + e.TYPE)
    }
    this.xmlWriter.beginNode(r)
    this.encodeMatchCase(e.matchCase)
    this.encodeMatchAction(e.matchAction)
    e.args[0].accept(this)
    e.args[1].accept(this)
    this.xmlWriter.endNode()
  }
  visitBetweenOperator(e) {
    this.xmlWriter.beginNode('PropertyIsBetween')
    e.expression.accept(this)
    this.xmlWriter.beginNode('LowerBoundary')
    e.lower.accept(this)
    this.xmlWriter.endNode()
    this.xmlWriter.beginNode('UpperBoundary')
    e.upper.accept(this)
    this.xmlWriter.endNode()
    this.xmlWriter.endNode()
  }
  visitPropertyName(e) {
    this.xmlWriter.beginNode('PropertyName')
    this.xmlWriter.value(e.namePath.toXPathString())
    for (const t in e.nameSpaces)
      if (e.nameSpaces.hasOwnProperty(t))
        this.nameSpaces.set(t, e.nameSpaces[t])
    this.xmlWriter.endNode()
  }
  visitAddOperator(e) {
    this.writeBinaryOperationNode('Add', e.args)
  }
  visitSubOperator(e) {
    this.writeBinaryOperationNode('Sub', e.args)
  }
  visitMulOperator(e) {
    this.writeBinaryOperationNode('Mul', e.args)
  }
  visitDivOperator(e) {
    this.writeBinaryOperationNode('Div', e.args)
  }
  encodeMatchAction(e) {
    if (e)
      throw new Error(
        'Match Action parameter is not supported in the OGC Filter specification 1.x'
      )
  }
  encodeBboxAsGML(e, t, r, i, s, o) {
    this.xmlWriter.beginNode('Box', this.gmlPrefix)
    this.xmlWriter.attribute('srsName', s)
    const n = this.shouldSwapAxes(s, o)
    this.xmlWriter.beginNode('coordinates', this.gmlPrefix)
    if (n) this.xmlWriter.value(t + ' ' + e + ' ' + i + ' ' + r)
    else this.xmlWriter.value(e + ' ' + t + ' ' + r + ' ' + i)
    this.xmlWriter.endNode()
    this.xmlWriter.endNode()
  }
  shouldSwapAxes(e, t) {
    let r = false
    if ('string' === typeof e)
      try {
        let i = e
        const s = e.match(EPSG_URL_REGEX)
        if (s) i = 'EPSG:' + s[1]
        const o = getReference(i)
        r = shouldSwapAxes(o, t)
      } catch (e) {}
    return r
  }
  encodeNullNil(e, t) {
    this.xmlWriter.beginNode(e)
    t.accept(this)
    this.xmlWriter.endNode()
  }
  encodeBinaryLogic(e, t) {
    if (0 === t.length) return
    if (1 === t.length) {
      t[0].accept(this)
      return
    }
    this.xmlWriter.beginNode(e)
    const r = t.length
    for (let e = 0; e < r; e++) t[e].accept(this)
    this.xmlWriter.endNode()
  }
  writeBinaryOperationNode(e, t) {
    this.xmlWriter.beginNode(e)
    const r = t.length
    for (let e = 0; e < r; e++) t[e].accept(this)
    this.xmlWriter.endNode()
  }
  encodeMatchCase(e) {
    if (Lang.isBoolean(e)) this.xmlWriter.attribute('matchCase', String(e))
  }
}
