import { DrawingContext } from '../strategy/DrawingContext.js'
function makeLabelContainerDiv() {
  const e = document.createElement('div')
  e.style.position = 'absolute'
  e.style.top = '0'
  e.style.left = '0'
  e.style.overflow = 'hidden'
  return e
}
function killHandler(e) {
  e.remove()
}
export class GoogleDrawingContext extends DrawingContext {
  constructor(e, t, i, n, o) {
    super(e, t, i, n, o)
    this._handles = []
    this._containerDiv = makeLabelContainerDiv()
    t.appendChild(this._containerDiv)
    this._googMap = new google.maps.Map(this._containerDiv, {
      zoom: 2,
      disableDefaultUI: true,
    })
    this.resize(i, n, o)
  }
  resize(e, t, i) {
    this.resizeIgnoreBorder(e, t)
  }
  setZIndex(e) {
    this._containerDiv.style.zIndex = e.toString()
  }
  paintDrawingContext() {
    this.layers[0].updateGoogleMap(this._googMap, this._containerDiv)
  }
  clear() {}
  resizeIgnoreBorder(e, t) {
    this._containerDiv.style.width = `${e}px`
    this._containerDiv.style.height = `${t}px`
    this._containerDiv.style.top = '0'
    this._containerDiv.style.left = '0'
    google.maps.event.trigger(this._googMap, 'resize')
  }
  destroy() {
    this._googMap = null
    this.killHandles()
    this._containerDiv.parentNode.removeChild(this._containerDiv)
  }
  paintOnDifferentContext(e, t) {}
  _collectLabels(e) {}
  killHandles() {
    this._handles.forEach(killHandler)
    this._handles.length = 0
  }
}
