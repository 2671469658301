import { XYZBounds } from '../../shape/XYZBounds.js'
import { CommandUtil } from './CommandUtil.js'
import {
  CoordinateArrayUtil,
  getRepeatingAnchorListSFCT,
} from './CoordinateArrayUtil.js'
import { HTML5DrawCommandProto } from './HTML5DrawCommandProto.js'
import { squaredDistanceToLineFlat } from '../../util/Cartesian.js'
const viewBounds = new XYZBounds(null)
export class HTML5DrawStrokeCommand extends HTML5DrawCommandProto {
  constructor(t, o, s, r, e, i) {
    super(t, o, e)
    this._strokeStyle = r
    this._coordinates = s
    this._coordinatesGp = [s]
    if (s.length >= 2) {
      this.bounds = new XYZBounds(null)
      CoordinateArrayUtil.calculateBoundsSFCT(s, this.bounds)
    }
    this._close = i
  }
  get close() {
    return this._close
  }
  draw(t, o, s) {
    this._strokeStyle.paintPathMapCoords(t, this._coordinates, o, this._close)
  }
  interacts(t, o, s) {
    return this.strokeInteracts(t, o, s)
  }
  strokeInteracts(t, o, s) {
    return CommandUtil.lineInteractsFlat(
      this._coordinates,
      t,
      this._strokeStyle.getStrokeWidth(),
      o
    )
  }
  mapAnchorPointSFCT(t, o) {
    return CoordinateArrayUtil.mapAnchorPointSFCT(
      this._coordinatesGp,
      this._close,
      o
    )
  }
  mapAnchorPointsSFCT(t, o, s) {
    if (!this.bounds) return
    t.getViewBounds(viewBounds)
    CoordinateArrayUtil.mapAnchorPointsSFCT(
      this._coordinatesGp,
      this.bounds,
      viewBounds,
      t.mapToViewTransformationInternal,
      s,
      o
    )
  }
  getMapDistanceSquared(t, o, s, r) {
    r.clear()
    if (this.worldBounds) r.setDistanceToBounds(this.worldBounds, o, s)
    r.setDistanceToEdge(squaredDistanceToLineFlat(this._coordinates, o, s))
  }
  getMaximumPadding(t) {
    return Math.ceil(this._strokeStyle.getStrokeWidth() / 2)
  }
  getRepeatingAnchorListSFCT(t, o, s) {
    if (!this.bounds) return
    getRepeatingAnchorListSFCT(this._coordinatesGp, this.bounds, o, t, s)
  }
}
