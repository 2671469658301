import { RasterDataType } from './RasterDataType.js'
import { RasterSamplingMode } from './RasterSamplingMode.js'
function normalizeTilesAndLevels(e) {
  e.levelCount =
    'number' === typeof e.levelCount && e.levelCount >= 0 ? e.levelCount : 22
  e.level0Columns = e.level0Columns || 1
  e.level0Rows = e.level0Rows || 1
  e.tileWidth = e.tileWidth || 256
  e.tileHeight = e.tileHeight || 256
}
function validateRasterModelOptions(e) {
  normalizeTilesAndLevels(e)
  e.dataType = e.dataType || RasterDataType.IMAGE
  e.samplingMode =
    e.samplingMode ||
    (e.dataType === RasterDataType.IMAGE
      ? RasterSamplingMode.AREA
      : RasterSamplingMode.POINT)
  return e
}
export var ModelOptionsUtil = {
  validateRasterModelOptions: validateRasterModelOptions,
}
