import { ProgrammingError } from '../error/ProgrammingError.js'
import { isDefined, isFunction, isObject } from '../util/Lang.js'
import { Log } from '../util/Log.js'
import { LayerTreeNode } from './LayerTreeNode.js'
import { LayerTreeNodeType } from './LayerTreeNodeType.js'
import { LayerTreeVisitor } from './LayerTreeVisitor.js'
import { LayerType } from './LayerType.js'
import { PaintRepresentation } from './PaintRepresentation.js'
function accumulateInArray(e, r) {
  e.push(r)
  return e
}
function justifyPosition(e) {
  if ('last' === (e = e || 'top')) {
    Log.deprecated('last', 'bottom')
    e = 'bottom'
  } else if ('first' === e) {
    Log.deprecated('first', 'top')
    e = 'top'
  } else if ('after' === e) {
    Log.deprecated('after', 'below')
    e = 'below'
  } else if ('before' === e) {
    Log.deprecated('before', 'above')
    e = 'above'
  }
  if ('bottom' !== e && 'top' !== e && 'below' !== e && 'above' !== e)
    throw new ProgrammingError(`position ${e} is not a valid value to addChild`)
  return e
}
class LayerGroup extends LayerTreeNode {
  constructor(e) {
    super(e)
    this._children = []
    this._isVisiblePaintRepresentation[
      PaintRepresentation.LEFT_BORDER_BODY
    ] = true
    this._isVisiblePaintRepresentation[
      PaintRepresentation.LEFT_BORDER_LABEL
    ] = true
  }
  isReady() {
    for (let e = 0; e < this._children.length; e++)
      if (!this._children[e].isNodeReady()) return false
    return true
  }
  _constructClone() {
    return new LayerGroup({ id: `${this.id}_clone` })
  }
  _worldBoundsChanged(e) {
    this._children.forEach((r) => {
      r._worldBoundsChanged(e)
    })
  }
  _addedToMap(e) {
    this._children.forEach((r) => {
      r._addedToMap(e)
    })
    this._map = e
  }
  _removedFromMap(e) {
    this._map = null
    this._children.forEach((r) => {
      r._removedFromMap(e)
    })
  }
  fireEvent(e, r) {
    r.path.unshift(this)
    if (this.parent) this.parent.fireEvent(e, r)
  }
  _removeAll() {
    const e = this._children.slice(0)
    const r = e.length
    for (let t = 0; t < r; t++) this.removeChild(e[t], false)
  }
  removeAllChildren() {
    this._removeAll()
  }
  addChild(e, r, t, i) {
    if (!e || !isObject(e))
      throw new ProgrammingError(
        'A valid layer object must be passed to LayerGroup::addChild'
      )
    r = justifyPosition(r)
    if (e.parent) {
      Log.warn(
        'LayerGroup::addChild: Moving a node - It is better to use .moveChild instead.'
      )
      this.moveChild(e, r, t, i)
    } else if (this._canMoveNode(e, r, t)) this._addChild(e, r, t, i, true)
    else throw new ProgrammingError('Cannot add child at this position.')
  }
  _addChild(e, r, t, i, o) {
    const n = this.getOverlappingIds(e)
    if (n.length > 0)
      throw new Error(
        `The given node can not be added as child of this layerGroup because of non-unique ID's: [${n}]`
      )
    const s = this._addToNodeList(e, r, t)
    if (o && this._map) e._addedToMap(this._map)
    e.addToParent(this)
    if (true !== i) {
      const r = () => {
        try {
          this.fireEvent('NodeAdded', { node: e, path: [], index: s })
        } catch (r) {
          this._removeFromNodeList(e)
          throw r
        }
      }
      if (this._map) this._map.onReady(r)
      else r()
    }
  }
  removeChild(e, r) {
    if (!e || !isObject(e))
      throw new ProgrammingError(
        'A valid layer object must be passed to LayerGroup::removeChild'
      )
    this._removeChild(e, r)
  }
  _removeChild(e, r) {
    const t = this._removeFromNodeList(e)
    if (t >= 0) {
      if (this._map) e._removedFromMap(this._map)
      e.removeFromParent(this)
      if (true !== r)
        this.fireEvent('NodeRemoved', { node: e, path: [], index: t })
    }
  }
  moveChild(e, r, t, i) {
    if (!e)
      throw new ProgrammingError('Must at least pass a layer to moveChild()')
    if (!isObject(e))
      throw new ProgrammingError('Trying to move an invalid layer object')
    if (t && t === e) return
    r = justifyPosition(r)
    if (this._canMoveNode(e, r, t)) this._moveChild(e, r, t, i)
    else throw new ProgrammingError('Cannot move child to this position.')
  }
  _moveChild(e, r, t, i) {
    const o = e.parent._children.indexOf(e)
    const n = e.parent
    if (o > -1) e.parent._children.splice(o, 1)
    e._parent = t ? t._parent : this
    e._parent._addToNodeList(e, r, t)
    const s = e.parent._children.indexOf(e)
    if (true !== i)
      if (n === e._parent)
        n.fireEvent('NodeMoved', {
          node: e,
          path: [],
          originalPath: n.calculatePath([]),
          index: s,
          originalIndex: o,
        })
      else {
        n.fireEvent('NodeRemoved', { node: e, path: [], index: o })
        e._parent.fireEvent('NodeAdded', { node: e, path: [], index: s })
      }
  }
  _getIndexOfPositionReference(e) {
    if (!isDefined(e))
      throw new ProgrammingError(
        'Must pass the reference layer when adding or moving a node, using position "below" or "above"'
      )
    const r = this._children.indexOf(e)
    if (r < 0) throw new ProgrammingError('Reference layer not found')
    return r
  }
  _addToNodeList(e, r, t) {
    let i
    switch (r) {
      case 'bottom':
        i = 0
        this._children.unshift(e)
        break
      case 'top':
        i = this._children.length
        this._children.push(e)
        break
      case 'below':
        i = this._getIndexOfPositionReference(t)
        this._children.splice(i, 0, e)
        break
      case 'above':
        i = this._getIndexOfPositionReference(t) + 1
        this._children.splice(i, 0, e)
        break
    }
    return i
  }
  _removeFromNodeList(e) {
    const r = this._children.indexOf(e)
    switch (r) {
      case -1:
        break
      case 0:
        this._children.shift()
        break
      case this._children.length - 1:
        this._children.pop()
        break
      default:
        this._children.splice(r, 1)
        break
    }
    return r
  }
  getFirstLeafNode() {
    const e = this._reduceLeaves(accumulateInArray, [])
    return 0 === e.length ? null : e[0]
  }
  getLastLeafNode() {
    const e = this._reduceLeaves(accumulateInArray, [])
    return 0 === e.length ? null : e[e.length - 1]
  }
  accept(e) {
    return e.visitLayerGroup(this)
  }
  _childIsAncestorOfParent(e, r) {
    let t = r || this
    while (t) {
      if (e === t) return true
      t = t._parent
    }
    return false
  }
  _reduceLeaves(e, r) {
    this._forEachLeaf((t, i) => {
      r = e(r, t, i)
    })
    return r
  }
  _forEachLeaf(e) {
    let r = 0
    this.accept({
      visitLayer: function (t) {
        e(t, r)
        r += 1
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
      visitLayerGroup: function (e) {
        e.visitChildren(this, LayerTreeNode.VisitOrder.BOTTOM_UP)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
    })
  }
  canMoveChild(e, r, t) {
    r = justifyPosition(r)
    return this._canMoveNode(e, r, t)
  }
  canAddChild(e, r, t) {
    if (this.getOverlappingIds(e).length > 0) return false
    r = justifyPosition(r)
    return this._canMoveNode(e, r, t)
  }
  _canMoveNode(e, r, t) {
    if (this._childIsAncestorOfParent(e, t)) return false
    const i = e._map
    const o = t ? t._map : this._map
    if (i && o && i !== o) return false
    const n = this._getRoot()._shadowClone()
    const s = e._shadowClone()
    s._parent = n._findNodeById(`${this.id}_clone`)
    let a
    if (t) {
      a = n._findNodeById(`${t.id}_clone`)
      if (!a)
        throw new ProgrammingError(
          "The reference layer doesn't belong to this layer tree"
        )
    }
    s._parent._moveChild(s, r, a, true)
    return n._isBaseLayerSemanticsValid()
  }
  _isBaseLayerSemanticsValid() {
    const e = this._reduceLeaves(accumulateInArray, [])
    let r = false
    return !e.some(function e(t) {
      const i =
        t &&
        t.treeNodeType === LayerTreeNodeType.LAYER &&
        t.type === LayerType.BASE
      if (r && i) return true
      r =
        r ||
        (t &&
          t.treeNodeType === LayerTreeNodeType.LAYER &&
          t.type !== LayerType.BASE)
      return false
    })
  }
  isPaintRepresentationSupported(e) {
    return true
  }
  getSelectedFeatures() {
    return this._reduceLeaves(collectSelectedFeatures, [])
  }
  getPreviousChildNode(e) {
    const r = this._children.indexOf(e)
    return 0 < r ? this._children[r - 1] : null
  }
  getNextChildNode(e) {
    const r = this._children.indexOf(e)
    return 0 <= r && r < this._children.length - 1
      ? this._children[r + 1]
      : null
  }
  visitChildren(e, r) {
    let t = LayerTreeVisitor.ReturnValue.CONTINUE
    const i = this._children.length
    let o
    switch (r) {
      case LayerTreeNode.VisitOrder.BOTTOM_UP:
        o = 0
        while (o < i && t === LayerTreeVisitor.ReturnValue.CONTINUE)
          t = this._children[o++].accept(e)
        return
      case LayerTreeNode.VisitOrder.TOP_DOWN:
        o = i - 1
        while (o >= 0 && t === LayerTreeVisitor.ReturnValue.CONTINUE)
          t = this._children[o--].accept(e)
        return
    }
    throw new ProgrammingError('LayerGroup::visitChildren - Invalid VisitOrder')
  }
  _shadowClone() {
    let e
    const r = super._shadowClone()
    for (let t = 0; t < this._children.length; t++) {
      e = this._children[t]._shadowClone()
      e._parent = r
      r._children.push(e)
    }
    return r
  }
  get children() {
    return this._children.slice()
  }
  set children(e) {
    throw new ProgrammingError('children property is not mutable')
  }
  get treeNodeType() {
    return LayerTreeNodeType.LAYER_GROUP
  }
  set treeNodeType(e) {
    throw new ProgrammingError('treeNodeType property is not mutable')
  }
  getOverlappingIds(e) {
    const r = this._getRoot()
    const t = []
    e.accept({
      visitLayer: function (e) {
        if (r.findLayerTreeNodeById(e.id)) t.push(e.id)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
      visitLayerGroup: function (e) {
        if (r.findLayerTreeNodeById(e.id)) t.push(e.id)
        e.visitChildren(this, LayerTreeNode.VisitOrder.TOP_DOWN)
        return LayerTreeVisitor.ReturnValue.CONTINUE
      },
    })
    return t
  }
}
function collectSelectedFeatures(e, r) {
  if (isFunction(r.getSelectedFeatures)) e.push(r.getSelectedFeatures())
  return e
}
export { LayerGroup }
