import { kmlValueMapFactory } from '../../util/kml/KMLValues.js'
export let KMLRefreshMode = (function (e) {
  e[(e['ON_CHANGE'] = 0)] = 'ON_CHANGE'
  e[(e['ON_INTERVAL'] = 1)] = 'ON_INTERVAL'
  e[(e['ON_EXPIRE'] = 2)] = 'ON_EXPIRE'
  return e
})({})
export let KMLViewRefreshMode = (function (e) {
  e[(e['NEVER'] = 0)] = 'NEVER'
  e[(e['ON_STOP'] = 1)] = 'ON_STOP'
  e[(e['ON_REQUEST'] = 2)] = 'ON_REQUEST'
  e[(e['ON_REGION'] = 3)] = 'ON_REGION'
  return e
})({})
export const getRefreshModeValue = kmlValueMapFactory(
  KMLRefreshMode.ON_CHANGE,
  {
    onChange: KMLRefreshMode.ON_CHANGE,
    onExpire: KMLRefreshMode.ON_EXPIRE,
    onInterval: KMLRefreshMode.ON_INTERVAL,
  }
)
export const getViewRefreshModeValue = kmlValueMapFactory(
  KMLViewRefreshMode.NEVER,
  {
    never: KMLViewRefreshMode.NEVER,
    onRegion: KMLViewRefreshMode.ON_REGION,
    onRequest: KMLViewRefreshMode.ON_REQUEST,
    onStop: KMLViewRefreshMode.ON_STOP,
  }
)
