import { Constants } from '../../../util/Constants.js'
const DEFAULT_MAX_EDGE_LENGTH = 5e5
const DEFAULT_THRESHOLD_ANGLE = 6
export class PenThreshold {
  constructor(t, s) {
    this._maxEdgeSize = t
    this._thresholdBreakAngle = s
    const e = Math.cos(this.THRESHOLD_ANGLE * Constants.DEG2RAD)
    this._sqrCosBreakAngle = e * e
  }
  get MAX_EDGE_LENGTH() {
    return this._maxEdgeSize
  }
  get THRESHOLD_ANGLE() {
    return this._thresholdBreakAngle
  }
  get SQR_COS_THRESHOLD_ANGLE() {
    return this._sqrCosBreakAngle
  }
}
export const DEFAULT_THRESHOLD = new PenThreshold(
  DEFAULT_MAX_EDGE_LENGTH,
  DEFAULT_THRESHOLD_ANGLE
)
