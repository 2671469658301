import { isBoolean, isNumber, isUndefined } from '../util/Lang.js'
export class LightScatteringAtmosphere {
  constructor(e) {
    if (isUndefined((e = e || {}).affectsTerrain)) this.affectsTerrain = false
    else this.affectsTerrain = e.affectsTerrain
    if (isUndefined(e.brightness)) this.brightness = 2
    else this.brightness = e.brightness
    if (isUndefined(e.rayleighScatteringFactor))
      this.rayleighScatteringFactor = 1
    else this.rayleighScatteringFactor = e.rayleighScatteringFactor
    if (isUndefined(e.mieScatteringFactor)) this.mieScatteringFactor = 1
    else this.mieScatteringFactor = e.mieScatteringFactor
  }
  get affectsTerrain() {
    return this._affectsTerrain
  }
  set affectsTerrain(e) {
    if (isBoolean(e)) this._affectsTerrain = e
    else throw new Error(`Invalid atmosphere affectsTerrain: ${e}`)
  }
  get brightness() {
    return this._brightness
  }
  set brightness(e) {
    if (isNumber(e) && e > 0) this._brightness = e
    else throw new Error(`Invalid atmosphere brightness: ${e}`)
  }
  get rayleighScatteringFactor() {
    return this._rayleighScatteringFactor
  }
  set rayleighScatteringFactor(e) {
    if (isNumber(e) && e > 0) this._rayleighScatteringFactor = e
    else throw new Error(`Invalid atmosphere rayleighScatteringFactor: ${e}`)
  }
  get mieScatteringFactor() {
    return this._mieScatteringFactor
  }
  set mieScatteringFactor(e) {
    if (isNumber(e) && e > 0) this._mieScatteringFactor = e
    else throw new Error(`Invalid atmosphere mieScatteringFactor: ${e}`)
  }
}
