import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { CGOperatorConvertShape } from '../CGOperatorConvertShape.js'
import { CGShape } from '../CGShape.js'
import { CGSetOperation } from '../CGSetOperation.js'
import { CGVertex } from '../CGVertex.js'
import { CGUtil } from '../CGUtil.js'
import { LineSegmentChooserSymmetricSubtract } from './LineSegmentChooserSymmetricSubtract.js'
import { AreaSegmentChooserSymmetricSubtract } from './AreaSegmentChooserSymmetricSubtract.js'
import { LineSegmentChooserSubtract } from './LineSegmentChooserSubtract.js'
import { AreaSegmentChooserSubtract } from './AreaSegmentChooserSubtract.js'
import { LineSegmentChooserUnite } from './LineSegmentChooserUnite.js'
import { AreaSegmentChooserUnite } from './AreaSegmentChooserUnite.js'
import { LineSegmentChooserIntersect } from './LineSegmentChooserIntersect.js'
import { AreaSegmentChooserIntersect } from './AreaSegmentChooserIntersect.js'
function CGOperatorSet() {}
CGOperatorSet.prototype = Object.create(Object.prototype)
CGOperatorSet.prototype.constructor = CGOperatorSet
CGOperatorSet.prototype._getAreas = function (e, t, r, n) {
  var o = []
  var i = r.findUnusedSegment(e)
  if (null === i) i = r.findUnusedSegment(t)
  var s = i
  var a = 1
  if (null !== i) {
    CGUtil.appendPointIfNotEqual(i.pos, o)
    i.used = true
    i = i.next
  }
  var u = CGUtil.getNumPointsShape(e) + CGUtil.getNumPointsShape(t)
  var p = 0
  while (null !== i) {
    CGUtil.appendPointIfNotEqual(i.pos, o)
    if (++p > 2 * u)
      throw new ProgrammingError('Infinite loop detected. Bailing out.')
    if (null !== i.bundleStar) {
      var m = +1 == a ? i.previous : i
      var S = i.bundleStar
      var l = r.chooseSegment(m, -a, S)
      if (null === l) {
        o = []
        i = null
      } else {
        a = l.dir
        i = l.segment
        if (-1 === a) i = i.next
      }
    }
    if (null !== i) {
      var C = i.getTouchingSegment(a)
      if (null != C) C.used = true
      i = +1 == a ? i.next : i.previous
    }
    if (
      null === i ||
      i === s ||
      (null !== i.bundleStar && i.bundleStar === s.bundleStar)
    ) {
      var g = CGOperatorConvertShape.pointListToVertices(o, 0, false, true)
      o = []
      if (null !== g) {
        g.setSegmentLocationsOfThis(true, true, true)
        n.elements.push(g)
      }
      if (null === (i = r.findUnusedSegment(e))) i = r.findUnusedSegment(t)
      if (null === i) break
      else {
        s = i
        if (!i.used) {
          a = 1
          CGUtil.appendPointIfNotEqual(i.pos, o)
          i.used = true
          i = i.next
        } else if (!i.previous.used) {
          a = -1
          CGUtil.appendPointIfNotEqual(i.pos, o)
          ;(i = i.previous).used = true
        } else throw new ProgrammingError('Wrong unused point chosen')
      }
    }
  }
}
CGOperatorSet.prototype._getLines = function (e, t, r, n) {
  for (var o = 0; o < e.elements.length; o++) r.walk(e.elements[o], n)
  for (var o = 0; o < t.elements.length; o++) r.walk(t.elements[o], n)
}
CGOperatorSet.prototype.pointHasNoUsedSegmentsTouching = function (e) {
  if (null !== e.bundleStar) {
    var t = e.bundleStar
    for (var r = 0; r < t.getNumEdgeEnds(); r++) {
      var n
      if (t.getEdgeEnd(r).segment.used) return false
    }
    return true
  } else return !e.used && !(null !== e.previous && e.previous.used)
}
CGOperatorSet.prototype._addLoneIntersectionPoints = function (e, t) {
  var r = true
  for (var n = e; null !== n && (r || n !== e); n = n.next) {
    r = false
    var o = !n.used && this.pointHasNoUsedSegmentsTouching(n)
    if (o && null !== n.otherLineSegment && n.otherLineSegment.used) o = false
    if (o)
      if (n.pointLabel.locationOn[n.otherShapeIndex])
        t.elements.push(new CGVertex(0, n.pos))
  }
}
CGOperatorSet.prototype.getLoneIntersectionPoints = function (e, t, r) {
  for (var n = 0; n < e.elements.length; n++)
    this._addLoneIntersectionPoints(e.elements[n], r)
}
CGOperatorSet.prototype._addPoint = function (e, t, r) {
  if (e.isPoint() && !e.used) {
    var n = e.bundleStar
    var o = false
    var i = false
    var s = false
    if (null !== n) {
      if (n.getNumEdgeEnds() > 0) o = true
      for (var a = 0; a < n.getNumPoints(); a++) {
        var u = n.getPoint(a)
        u.used = true
        if (u.shapeIndex !== e.shapeIndex) i = true
      }
    }
    var p = e.getPointLocationOther()
    switch (r) {
      case CGSetOperation.INTERSECTION:
        s = p
        break
      case CGSetOperation.UNION:
        if (!p) s = true
        else s = i && !o
        break
      case CGSetOperation.DIFFERENCE:
        s = 0 === e.shapeIndex && !p
        break
      case CGSetOperation.SYMMETRIC_DIFFERENCE:
        s = !p
        break
    }
    if (s) t.elements.push(new CGVertex(0, e.pos))
  }
}
CGOperatorSet.prototype._getPoints = function (e, t, r, n) {
  if (n === CGSetOperation.INTERSECTION) this.getLoneIntersectionPoints(e, t, r)
  else {
    for (var o = 0; o < e.elements.length; o++)
      this._addPoint(e.elements[o], r, n)
    for (var o = 0; o < t.elements.length; o++)
      this._addPoint(t.elements[o], r, n)
  }
}
CGOperatorSet.prototype.operate = function (e, t, r) {
  var n = new CGShape()
  var o
  var i
  switch (r) {
    case CGSetOperation.INTERSECTION:
      o = new AreaSegmentChooserIntersect()
      i = new LineSegmentChooserIntersect()
      break
    case CGSetOperation.UNION:
      o = new AreaSegmentChooserUnite()
      i = new LineSegmentChooserUnite()
      break
    case CGSetOperation.DIFFERENCE:
      o = new AreaSegmentChooserSubtract()
      i = new LineSegmentChooserSubtract()
      break
    case CGSetOperation.SYMMETRIC_DIFFERENCE:
      o = new AreaSegmentChooserSymmetricSubtract()
      i = new LineSegmentChooserSymmetricSubtract()
      break
    default:
      throw new ProgrammingError('Unknown Operation ' + r)
  }
  this._getAreas(e, t, o, n)
  this._getLines(e, t, i, n)
  this._getPoints(e, t, n, r)
  return n
}
export { CGOperatorSet }
