import { isDefined } from '../../util/Lang.js'
import { Handler } from './Handler.js'
import { GestureEventImpl } from './GestureEvent.js'
import { GestureEventType } from './GestureEventType.js'
import { ScrollEventImpl } from './ScrollEvent.js'
import { DragEventImpl } from './DragEvent.js'
const LONGPRESS_TIMEOUT = 500
const TAP_TIMEOUT = 180
const DOUBLE_TAP_TIMEOUT = 300
const TOUCH_SLOP = Math.sqrt(2)
const DOUBLE_TAP_SLOP = 100
const MIN_PIXELS_IN_SCROLL = 48
const SHOW_PRESS = 'SHOW_PRESS'
const LONG_PRESS = 'LONG_PRESS'
const TAP = 'TAP'
function generateGestureEvents(e) {
  const t = [
    GestureEventType.DOWN,
    GestureEventType.UP,
    GestureEventType.DRAG_END,
    GestureEventType.MOVE,
    GestureEventType.SINGLE_CLICK_UP,
    GestureEventType.SINGLE_CLICK_CONFIRMED,
    GestureEventType.DOUBLE_CLICK,
    GestureEventType.DOUBLE_CLICK_EVENT,
    GestureEventType.LONG_PRESS,
    GestureEventType.SHOW_PRESS,
    GestureEventType.CONTEXT_MENU,
  ]
  const n = new Map()
  let s
  for (s = 0; s < t.length; s += 1)
    n.set(t[s], new GestureEventImpl(e, t[s], 'mouse'))
  n.set(GestureEventType.SCROLL, new ScrollEventImpl(e, 'mouse'))
  n.set(GestureEventType.DRAG, new DragEventImpl(e, 'mouse'))
  return n
}
let lowestDelta = null
let nullLowestDeltaTimeout = null
const lineHeight = 16
const pageHeight = 800
const isMac = /mac/i.test(navigator.platform)
function nullLowestDelta() {
  lowestDelta = null
}
const getScrollAmount = (e) => {
  let t = e
  let n
  let s = 0
  let i = 0
  let o = 0
  if ('detail' in t) i = -1 * t.detail
  if ('wheelDelta' in t) i = t.wheelDelta
  if ('wheelDeltaY' in t) i = t.wheelDeltaY
  if ('wheelDeltaX' in t) s = -1 * t.wheelDeltaX
  if ('axis' in t && t.axis === t.HORIZONTAL_AXIS) {
    s = -1 * i
    i = 0
  }
  n = 0 === i ? s : i
  if ('deltaY' in t) {
    i = -1 * t.deltaY
    n = i
  }
  if ('deltaX' in t) {
    s = t.deltaX
    if (0 === i) n = -1 * s
  }
  if (0 === i && 0 === s) return { deltaX: 0, deltaY: 0 }
  if (1 === t.deltaMode) {
    n *= lineHeight
    i *= lineHeight
    s *= lineHeight
  } else if (2 === t.deltaMode) {
    n *= pageHeight
    i *= pageHeight
    s *= pageHeight
  }
  o = Math.max(Math.abs(i), Math.abs(s))
  if (!lowestDelta || o < lowestDelta) {
    lowestDelta = Math.max(o, MIN_PIXELS_IN_SCROLL)
    if (shouldAdjustOldDeltas(t, o)) lowestDelta /= 40
  }
  if (shouldAdjustOldDeltas(t, o)) {
    n /= 40
    s /= 40
    i /= 40
  }
  if (isMac) {
    n /= MIN_PIXELS_IN_SCROLL
    s /= MIN_PIXELS_IN_SCROLL
    i /= MIN_PIXELS_IN_SCROLL
  } else {
    n /= lowestDelta
    s /= lowestDelta
    i /= lowestDelta
  }
  if (nullLowestDeltaTimeout) clearTimeout(nullLowestDeltaTimeout)
  nullLowestDeltaTimeout = window.setTimeout(nullLowestDelta, 200)
  return { deltaX: s, deltaY: i }
}
function shouldAdjustOldDeltas(e, t) {
  return 'mousewheel' === e.type && t % 120 === 0
}
const DOM_EVENT_NAME_2_METHOD_NAME = {
  blur: 'onblur',
  contextmenu: 'oncontextmenu',
  mousedown: 'onmousedown',
  mouseup: 'onmouseup',
  mousemove: 'onmousemove',
  wheel: 'onmousewheel',
}
export class MouseHandler extends Handler {
  constructor(e, t, n) {
    super()
    this._map = e
    this._node = t
    this._gestureEvents = generateGestureEvents(e)
    this._listener = n
    this._inLongPress = false
    this._alwaysInTapRegion = false
    this._currentDownDOMEvent = null
    this._previousUpEvent = null
    this._isDoubleTapping = false
    this._lastMotionY = 0
    this._lastMotionX = 0
    this._isLongpressEnabled = true
    this._touchSlopSquare = TOUCH_SLOP * TOUCH_SLOP
    this._doubleTapSlopSquare = DOUBLE_TAP_SLOP * DOUBLE_TAP_SLOP
    this.downButton = -1
    this._listeningToWindow = false
    this._currentContextMenuEvent = null
    this._domListeners = this.wireDomListeners(t)
    this._currentDownEvent = null
    this._currentDownX = -1
    this._currentDownY = -1
    this._dragging = false
    this._currentDragEvent = null
    this._currentUpEvent = null
    this._onmousemove_bound = this.onmousemove.bind(this)
    this._onmouseup_bound = this.onmouseup.bind(this)
    this._onblur_bound = this.onblur.bind(this)
    this._oncontextmenu_bound = this.oncontextmenu.bind(this)
  }
  wireDomListeners(e) {
    const t = []
    for (const n in DOM_EVENT_NAME_2_METHOD_NAME)
      if (DOM_EVENT_NAME_2_METHOD_NAME.hasOwnProperty(n)) {
        const s = DOM_EVENT_NAME_2_METHOD_NAME[n]
        t.push(this.wrapEventHandler(e, n, s))
      }
    return t
  }
  wrapEventHandler(e, t, n) {
    const s = (e) => {
      this._map._updateViewBounds()
      const t = undefined
      if (this[n](e) && e && e.preventDefault) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
    e.addEventListener(t, s, { passive: false })
    return { remove: () => e.removeEventListener(t, s) }
  }
  initGestureEvent(e, t) {
    const n = this._gestureEvents.get(e)
    n.domEvent = t
    n.location = t
    n.modifier = this.modifier(t)
    return n
  }
  destroy() {
    this.cancel()
    this._domListeners.forEach((e) => e.remove())
    this._domListeners = []
  }
  onmousewheel(e) {
    const t = this.initGestureEvent(GestureEventType.SCROLL, e)
    const n = getScrollAmount(e)
    const s = -n.deltaX
    const i = n.deltaY
    t.amount = 0 !== i ? i : s
    return this._listener.onGestureEvent(t)
  }
  registerWindowListeners() {
    if (!this._listeningToWindow) {
      window.addEventListener('mousemove', this._onmousemove_bound, false)
      window.addEventListener('mouseup', this._onmouseup_bound, false)
      window.addEventListener('blur', this._onblur_bound, false)
      window.addEventListener('contextmenu', this._oncontextmenu_bound, false)
      this._listeningToWindow = true
    }
  }
  unregisterWindowListeners() {
    if (this._listeningToWindow) {
      window.removeEventListener('mousemove', this._onmousemove_bound)
      window.removeEventListener('mouseup', this._onmouseup_bound)
      window.removeEventListener('blur', this._onblur_bound)
      window.removeEventListener('contextmenu', this._oncontextmenu_bound)
      this._listeningToWindow = false
    }
  }
  onmousedown(e) {
    if (-1 !== this.downButton) return true
    this.downButton = e.button
    this.registerWindowListeners()
    const t = e.pageX
    const n = e.pageY
    this._currentContextMenuEvent = null
    const s = this.hasMessages(TAP)
    if (s) this.removeMessages(TAP)
    if (
      isDefined(this._currentDownDOMEvent) &&
      null !== this._currentDownDOMEvent &&
      isDefined(this._previousUpEvent) &&
      null !== this._previousUpEvent &&
      s &&
      this.isConsideredDoubleTap(
        this._currentDownDOMEvent,
        this._previousUpEvent,
        e
      )
    ) {
      this._isDoubleTapping = true
      const t = this.initGestureEvent(
        GestureEventType.DOUBLE_CLICK,
        this._currentDownDOMEvent
      )
      this._listener.onGestureEvent(t)
      const n = this.initGestureEvent(GestureEventType.DOUBLE_CLICK_EVENT, e)
      this._listener.onGestureEvent(n)
    } else this.sendEmptyMessageDelayed(TAP, DOUBLE_TAP_TIMEOUT)
    this._lastMotionX = t
    this._lastMotionY = n
    this._currentDownDOMEvent = e
    this._currentDownEvent = new GestureEventImpl(
      this._map,
      GestureEventType.DOWN,
      'mouse'
    )
    this._currentDownX = t
    this._currentDownY = n
    this._alwaysInTapRegion = true
    this._inLongPress = false
    if (this._isLongpressEnabled && !this._isDoubleTapping) {
      this.removeMessages(LONG_PRESS)
      this.sendEmptyMessageDelayed(LONG_PRESS, TAP_TIMEOUT + LONGPRESS_TIMEOUT)
    }
    this.sendEmptyMessageDelayed(SHOW_PRESS, TAP_TIMEOUT)
    const i = this.initGestureEvent(GestureEventType.DOWN, e)
    this._currentDownEvent = i.copy()
    this._listener.onGestureEvent(i)
    return e.currentTarget === this._node
  }
  onmousemove(e) {
    if (this._inLongPress) return false
    const t = -1 !== this.downButton
    const n = e.pageX
    const s = e.pageY
    const i = n - this._lastMotionX
    const o = s - this._lastMotionY
    if (this._isDoubleTapping) {
      const t = this.initGestureEvent(GestureEventType.DOUBLE_CLICK_EVENT, e)
      this._listener.onGestureEvent(t)
    } else if (this._alwaysInTapRegion) {
      const r = n - this._currentDownX
      const u = s - this._currentDownY
      const l = undefined
      if (r * r + u * u > this._touchSlopSquare) {
        if (t) {
          this._dragging = true
          this.emitDragEvent(e, i, o)
          this.removeMessages(TAP)
          this.removeMessages(SHOW_PRESS)
          this.removeMessages(LONG_PRESS)
        } else this.emitMoveEvent(e)
        this._lastMotionX = n
        this._lastMotionY = s
        this._alwaysInTapRegion = false
      }
    } else if (Math.abs(i) >= 1 || Math.abs(o) >= 1) {
      if (t) {
        this._dragging = true
        this.emitDragEvent(e, i, o)
      } else this.emitMoveEvent(e)
      this._lastMotionX = n
      this._lastMotionY = s
    }
    return false
  }
  emitDragEvent(e, t, n) {
    const s = this.initGestureEvent(GestureEventType.DRAG, e)
    s.downEvent = this._currentDownEvent
    s.dragX = t
    s.dragY = n
    this._listener.onGestureEvent(s)
    this._currentDragEvent = e
  }
  emitDragEndEvent(e) {
    const t = this.initGestureEvent(GestureEventType.DRAG_END, e)
    t.downEvent = this._currentDownEvent
    this._listener.onGestureEvent(t)
  }
  emitMoveEvent(e) {
    const t = this.initGestureEvent(GestureEventType.MOVE, e)
    this._listener.onGestureEvent(t)
  }
  onmouseup(e) {
    if (this.downButton !== e.button) return false
    this.unregisterWindowListeners()
    const t = this.initGestureEvent(GestureEventType.UP, e)
    this._listener.onGestureEvent(t)
    this._currentUpEvent = t
    this.downButton = -1
    if (this._dragging) {
      this._dragging = false
      this.emitDragEndEvent(e)
    }
    if (this._isDoubleTapping) {
      const t = this.initGestureEvent(GestureEventType.DOUBLE_CLICK_EVENT, e)
      this._listener.onGestureEvent(t)
    } else if (this._inLongPress) {
      this.removeMessages(TAP)
      this._inLongPress = false
    } else if (this._alwaysInTapRegion) {
      const t = this.initGestureEvent(GestureEventType.SINGLE_CLICK_UP, e)
      this._listener.onGestureEvent(t)
    }
    this._previousUpEvent = e
    this._isDoubleTapping = false
    this.removeMessages(SHOW_PRESS)
    this.removeMessages(LONG_PRESS)
    return e.currentTarget === this._node
  }
  onblur() {
    this.cancel()
  }
  oncontextmenu(e) {
    this._currentContextMenuEvent = e
    return true
  }
  triggerContextMenu(e) {
    if (
      Math.abs(this._currentDownEvent.clientPosition[0] - e.clientX) <= 1 &&
      Math.abs(this._currentDownEvent.clientPosition[1] - e.clientY) <= 1 &&
      Math.abs(this._currentUpEvent.clientPosition[0] - e.clientX) <= 1 &&
      Math.abs(this._currentUpEvent.clientPosition[1] - e.clientY) <= 1
    ) {
      this.cancel()
      const e = this.initGestureEvent(
        GestureEventType.CONTEXT_MENU,
        this._currentContextMenuEvent
      )
      this._listener.onGestureEvent(e)
      this._currentContextMenuEvent = null
    }
    return true
  }
  cancel() {
    this.unregisterWindowListeners()
    this.downButton = -1
    if (this._dragging) {
      this._dragging = false
      const e = this.initGestureEvent(
        GestureEventType.DRAG_END,
        this._currentDragEvent
      )
      this._listener.onGestureEvent(e)
    }
    this.removeMessages(SHOW_PRESS)
    this.removeMessages(LONG_PRESS)
    this.removeMessages(TAP)
    this._isDoubleTapping = false
    this._alwaysInTapRegion = false
    if (this._inLongPress) this._inLongPress = false
  }
  isConsideredDoubleTap(e, t, n) {
    if (n.timeStamp - t.timeStamp > DOUBLE_TAP_TIMEOUT) return false
    const s = e.pageX - n.pageX
    const i = e.pageY - n.pageY
    return s * s + i * i < this._doubleTapSlopSquare
  }
  dispatchLongPress() {
    this.removeMessages(TAP)
    this._inLongPress = true
    const e = this.initGestureEvent(
      GestureEventType.LONG_PRESS,
      this._currentDownDOMEvent
    )
    this._listener.onGestureEvent(e)
  }
  handleMessage(e) {
    switch (e) {
      case SHOW_PRESS: {
        const e = this.initGestureEvent(
          GestureEventType.SHOW_PRESS,
          this._currentDownDOMEvent
        )
        this._listener.onGestureEvent(e)
        break
      }
      case LONG_PRESS:
        this.dispatchLongPress()
        break
      case TAP:
        if (-1 === this.downButton)
          if (this._currentContextMenuEvent) {
            this.triggerContextMenu(this._currentContextMenuEvent)
            this._currentContextMenuEvent = null
          } else {
            const e = this.initGestureEvent(
              GestureEventType.SINGLE_CLICK_CONFIRMED,
              this._currentDownDOMEvent
            )
            this._listener.onGestureEvent(e)
          }
        break
    }
  }
}
