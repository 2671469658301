import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Cylindrical } from './Cylindrical.js'
import { EquidistantCylindrical } from './EquidistantCylindrical.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError(
  'EllipsoidalEquidistantCylindrical'
)
class CachedValuesForEllipsoid {
  constructor(t, s) {
    this.fA = t.a
    this.fE = t.e
    const n = t.e2
    const i = n * n
    const a = i * n
    const e = a * n
    const o = e * n
    const r = o * n
    const l = o * n
    this.fV1 =
      this.fA / Math.sqrt(1 - n * s.sinStandardParallel * s.sinStandardParallel)
    this.fM1 =
      1 -
      (1 / 4) * n -
      (3 / 64) * i -
      (5 / 256) * a -
      (175 / 16384) * e -
      (441 / 1048576) * o -
      (4851 / 1048576) * r -
      (14157 / 4194304) * l
    this.fM2 =
      (-3 / 8) * n -
      (3 / 32) * i -
      (45 / 1024) * a -
      (105 / 4096) * e -
      (2205 / 131072) * o -
      (6237 / 524288) * r -
      (297297 / 33554432) * l
    this.fM3 =
      (15 / 256) * i +
      (45 / 1024) * a +
      (525 / 16384) * e +
      (1575 / 65536) * o +
      (155925 / 8388608) * r +
      (495495 / 33554432) * l
    this.fM4 =
      (-35 / 3072) * a -
      (175 / 12288) * e -
      (3675 / 262144) * o -
      (13475 / 1048576) * r -
      (385385 / 33554432) * l
    this.fM5 =
      (315 / 131072) * e +
      (2205 / 524288) * o +
      (43659 / 8388608) * r +
      (189189 / 33554432) * l
    this.fM6 =
      (-693 / 1310720) * o - (6237 / 5242880) * r - (297297 / 167772160) * l
    this.fM7 = (1001 / 8388608) * r + (11011 / 33554432) * l
    this.fM8 = (-6435 / 234881024) * l
    this.fMu =
      1 -
      (1 / 4) * n -
      (3 / 64) * i -
      (5 / 256) * a -
      (175 / 16384) * e -
      (441 / 65536) * o -
      (4851 / 1048576) * r -
      (14157 / 4194304) * l
    const c = t.n
    const d = c * c
    const h = d * c
    const u = h * c
    const p = u * c
    const f = p * c
    const C = f * c
    this.fMu1 = (3 / 2) * c - (27 / 32) * h + (269 / 512) * p - 6607 * 24576 * C
    this.fMu2 = (21 / 16) * d - (55 / 32) * u + (6759 / 4096) * f
    this.fMu3 = (151 / 96) * h - (417 / 128) * p + (87963 / 20480) * C
    this.fMu4 = (1097 / 512) * u - (15543 / 2560) * f
    this.fMu5 = (8011 / 2560) * p - (69119 / 6144) * C
    this.fMu6 = (293393 / 61440) * f
    this.fMu7 = (6845701 / 860160) * C
  }
  isValid(t) {
    return this.fA == t.a && this.fE == t.e
  }
}
export class EllipsoidalEquidistantCylindrical extends Cylindrical {
  constructor(t, s) {
    super()
    this._standardParallel = 0
    this._cosStandardParallel = 0
    this._sinStandardParallel = 0
    this._sphericalProjection = null
    this._cachedValuesForEllipsoid = null
    this._hash = 0
    this.setCentralMeridian(t || 0)
    this.setStandardParallel(s || 0)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    const t = new Hash()
    this._hash = t
      .appendDouble(this.getCentralMeridian())
      .appendDouble(this.getStandardParallel())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  getStandardParallel() {
    return this._standardParallel
  }
  get sinStandardParallel() {
    return this._sinStandardParallel
  }
  setStandardParallel(t) {
    this._standardParallel = t
    this._cosStandardParallel = Math.cos(t * Constants.DEG2RAD)
    this._sinStandardParallel = Math.sin(t * Constants.DEG2RAD)
    this.resetCache()
    this.calculateCachedValues()
  }
  setCentralMeridian(t) {
    super.setCentralMeridian(t)
    this.resetSphericalProjection()
    this.calculateCachedValues()
  }
  isAllInBounds() {
    return true
  }
  geodetic2cartesianOnSphereSFCT(t, s, n) {
    this.getSphericalProjection().geodetic2cartesianOnSphereSFCT(t, s, n)
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, n) {
    const i = normalizeLon(t.x - this.getCentralMeridian())
    const a = t.y * Constants.DEG2RAD
    const e = Math.sin(t.y * Constants.DEG2RAD)
    const o = Math.cos(t.y * Constants.DEG2RAD)
    const r = 2 * e * o
    const l = o * o - e * e
    const c = 2 * r * l
    const d = l * l - r * r
    const h = r * d + l * c
    const u = l * d - r * c
    const p = 2 * c * d
    const f = undefined
    const C = c * u + d * h
    const M = 2 * h * u
    const P = h * (d * d - c * c) + u * p
    const S = this.getCachedValuesForEllipsoid(s)
    const E = a * S.fM1
    const g = r * S.fM2
    const y = c * S.fM3
    const _ = h * S.fM4
    const j = p * S.fM5
    const O = C * S.fM6
    const D = M * S.fM7
    const F = P * S.fM8
    const V = S.fV1 * this._cosStandardParallel * i * Constants.DEG2RAD
    const A = S.fA * (E + g + y + _ + j + O + D + F)
    n.x = V
    n.y = A
  }
  cartesian2geodeticOnSphereSFCT(t, s, n) {
    this.getSphericalProjection().cartesian2geodeticOnSphereSFCT(t, s, n)
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, n) {
    const i = this.getCachedValuesForEllipsoid(s)
    if (this.inWorldBoundsOnEllipsoid(t, s)) {
      const s = t.x
      const a = undefined
      const e = t.y / (i.fA * i.fMu)
      const o = Math.sin(e)
      const r = Math.cos(e)
      const l = 2 * o * r
      const c = r * r - o * o
      const d = 2 * l * c
      const h = c * c - l * l
      const u = l * h + c * d
      const p = c * h - l * d
      const f = 2 * d * h
      const C = undefined
      const M = d * p + h * u
      const P = 2 * u * p
      const S = u * (h * h - d * d) + p * f
      const E = l * i.fMu1
      const g = d * i.fMu2
      const y = u * i.fMu3
      const _ = f * i.fMu4
      const j = M * i.fMu5
      const O = P * i.fMu6
      const D = S * i.fMu7
      const F = normalizeLon(
        this.getCentralMeridian() +
          (s / (i.fV1 * this._cosStandardParallel)) * Constants.RAD2DEG
      )
      const V = Constants.RAD2DEG * (e + E + g + y + _ + j + O + D)
      n.x = F
      n.y = V
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    return this.getSphericalProjection().inLonLatBounds(t)
  }
  inWorldBoundsOnSphere(t, s) {
    return this.getSphericalProjection().inWorldBoundsOnSphere(t, s)
  }
  cartesianBoundsOnEllipsoidSFCT(t, s) {
    const n = this.getCachedValuesForEllipsoid(t)
    const i = n.fV1 * this._cosStandardParallel * Math.PI
    const a = n.fA * n.fM1 * 0.5 * Math.PI
    s.setTo2D(-i, 2 * i, -a, 2 * a)
  }
  inWorldBoundsOnEllipsoid(t, s) {
    return this.inWorldBoundsOnEllipsoidForParams(
      t,
      this.getCachedValuesForEllipsoid(s)
    )
  }
  boundaryLons(t) {
    return this.getSphericalProjection().boundaryLons(t)
  }
  boundaryLats(t) {
    return this.getSphericalProjection().boundaryLats(t)
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    this.getSphericalProjection().cartesianBoundsOnSphereSFCT(t, s)
  }
  inWorldBoundsOnEllipsoidForParams(t, s) {
    let n = s.fV1 * this._cosStandardParallel * Math.PI
    n *= 1 + 1e-15
    let i = s.fA * s.fM1 * 0.5 * Math.PI
    i *= 1 + 1e-15
    return t.x >= -n && t.x <= n && t.y >= -i && t.y <= i
  }
  toString() {
    return `EllipsoidalEquidistantCylindrical_${this.getCentralMeridian()}_${this.getStandardParallel()}`
  }
  getCachedValuesForEllipsoid(t) {
    let s = this._cachedValuesForEllipsoid
    if (null == s || !s.isValid(t)) {
      s = new CachedValuesForEllipsoid(t, this)
      this._cachedValuesForEllipsoid = s
    }
    return s
  }
  resetCache() {
    this._cachedValuesForEllipsoid = null
    this.resetSphericalProjection()
  }
  resetSphericalProjection() {
    this._sphericalProjection = null
  }
  getSphericalProjection() {
    let t = this._sphericalProjection
    if (null == t) {
      t = new EquidistantCylindrical(
        this.getCentralMeridian(),
        this.getStandardParallel()
      )
      this._sphericalProjection = t
    }
    return t
  }
  encode() {
    return {
      type: 'EllipsoidalEquidistantCylindrical',
      centralMeridian: this.getCentralMeridian(),
      standardParallel: this.getStandardParallel(),
    }
  }
  get TYPE() {
    return (
      ProjectionType.ELLIPSOIDAL_EQUIDISTANT_CYLINDRICAL +
      ProjectionType.CYLINDRICAL
    )
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
