import { Ajax } from '../../util/Ajax.js'
import { URL } from '../../util/URL.js'
import { Constants } from '../../util/Constants.js'
import { StyleUtil } from './StyleUtil.js'
import { HTML5DrawCommandProto } from './HTML5DrawCommandProto.js'
import { isDefined, isString } from '../../util/Lang.js'
import { resizeImageOrCanvas } from '../../util/ImageUtil.js'
import { whenInternal } from '../../util/PromiseUtil.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { PIXEL_UOM } from '../../uom/UnitOfMeasureUtil.js'
export class HTML5DrawFillCommandProto extends HTML5DrawCommandProto {
  _scaleX = 1
  _scaleY = 1
  _styleIsReady = false
  _fillColor = null
  _canvasPattern = null
  _resolvedImage = null
  constructor(t, i, l, e, s) {
    super(t, i, l)
    this._fillStyle = e
    this._isWorldSized = false
    this._invalidateAction = s
    this.initFillSettings()
  }
  get fillStyle() {
    return this._fillStyle
  }
  isReady() {
    return this._styleIsReady
  }
  hasFill() {
    return null !== this._canvasPattern || null !== this._fillColor
  }
  _fill(t, i, l, e) {
    if (this._fillStyle)
      if (null !== this._canvasPattern) this.fillWithPattern(t, i, l, e)
      else if (null !== this._fillColor) this.fillWithColor(t)
  }
  initFillSettings() {
    this._fillColor =
      this._fillStyle && this._fillStyle.color ? this._fillStyle.color : null
    this._isWorldSized =
      isDefined(this._fillStyle.uom) && !PIXEL_UOM.equals(this._fillStyle.uom)
    this._scaleX = 1
    this._scaleY = 1
    const t = !!(
      this._fillStyle &&
      ('string' === typeof this._fillStyle.url || this._fillStyle.image)
    )
    this._styleIsReady = !t
    if (this._styleIsReady) return
    const { image: i, url: l } = this._fillStyle
    const e = i || l
    if (!e) return
    const s = isString(l) && !URL.isLocalURL(l)
    const r = () => {
      this._styleIsReady = true
    }
    const a = e instanceof HTMLCanvasElement ? e : Ajax.getImage(e, true, s)
    const o = whenInternal(a, (t) => this.resizeIfNeeded(t), r)
    whenInternal(
      o,
      (t) => {
        this._resolvedImage = !t ? null : t
        this.resolvePattern(t)
      },
      r
    )
  }
  resizeIfNeeded(t) {
    const { width: i, height: l } = StyleUtil.resolveStyleImagePixels(
      this._fillStyle.width,
      this._fillStyle.height,
      t.width,
      t.height
    )
    const e = undefined
    return Ajax.isSVGImage(t) && !!t && (i !== t.width || l !== t.height)
      ? resizeImageOrCanvas(t, i, l)
      : t
  }
  resolvePattern(t) {
    if (!t) throw new ProgrammingError('No image to pattern')
    const { width: i, height: l } = StyleUtil.resolveStyleImagePixels(
      this._fillStyle.width,
      this._fillStyle.height,
      t.width,
      t.height
    )
    if (i && t.width) this._scaleX = i / t.width || 1
    if (l && t.height) this._scaleY = l / t.height || 1
    this._canvasPattern = Ajax.getPattern(t, i, l)
    this._styleIsReady = true
    if (this._canvasPattern && this._invalidateAction) this._invalidateAction()
  }
  fillWithColor(t) {
    if (!this._fillColor) return
    t.fillStyle = this._fillColor
    t.fill()
  }
  fillWithPattern(t, i, l, e) {
    if (!this._canvasPattern) return
    const s = 0 !== i || 0 !== l
    const {
      opacity: r,
      rotation: a,
      worldHeight: o,
      worldWidth: n,
    } = this._fillStyle
    if (this._isWorldSized && e) {
      if (!this._resolvedImage)
        throw new ProgrammingError('No image to pattern')
      const t = e.toPixels(o, this._fillStyle.uom)
      const i = e.toPixels(n, this._fillStyle.uom)
      const l = document.createElement('canvas')
      l.width = i
      l.height = t
      const s = l.getContext('2d')
      s.save()
      s.drawImage(this._resolvedImage, 0, 0, i, t)
      const r = s.canvas
      s.restore()
      this._canvasPattern = Ajax.getPattern(r, i, t)
    }
    t.save()
    if (s) t.translate(i, l)
    if (r && r < 1) t.globalAlpha = r
    if (a && 0 !== a) t.rotate(a * Constants.DEG2RAD)
    if (1 !== this._scaleX || 1 !== this._scaleY)
      t.scale(this._scaleX, this._scaleY)
    t.fillStyle = this._canvasPattern
    t.fill()
    t.restore()
  }
}
