function getVisibleSections(t, e, i) {
  const n = 0
  const s = i
  let r, o, c, l
  const a = []
  const f = t.length - 1
  for (r = 0; r <= f; r++) {
    o = t[r]
    if (o.visibility) {
      const i = r > 0 ? t[r - 1].endDistance : n
      c = e ? getFirstBefore(n, i, e) : i
      l = e ? getFirstAfter(n, s, o.endDistance, e) : o.endDistance
      a.push([Math.max(c, n), Math.min(l, s)])
    }
  }
  return mergeOverlappingParts(a)
}
function getVisibleSectionsAtStart(t, e, i, n) {
  const s = n
  let r, o, c, l
  const a = []
  const f = t.length - 1
  for (r = 0; r <= f; r++) {
    o = t[r]
    if (o.visibility) {
      const n = undefined
      c = getFirstBefore(e, r > 0 ? t[r - 1].endDistance : e, i)
      l = getFirstAfter(e, s, o.endDistance, i)
      a.push([Math.max(c, e), Math.min(l, s)])
    }
  }
  return mergeOverlappingParts(a)
}
function getVisibleParts(t) {
  const e = []
  const i = t.length - 1
  for (let n = 0; n <= i; n++) {
    const i = t[n]
    if (i.visibility) {
      const s = n > 0 ? t[n - 1].endDistance : 0
      e.push([s, i.endDistance])
    }
  }
  return e
}
function getMutualVisibleParts(t, e, i) {
  const n = []
  const s = t.length
  const r = e[0]
  const o = e[1]
  for (let e = 0; e < s; e++) {
    const i = t[e]
    const s = i[0]
    const l = i[1]
    if (r <= s && l <= o) c(s, l)
    else if (s <= r && o <= l) n.push([r, o])
    else if (isIn(s, r, o) && o <= l) c(s, o)
    else if (isIn(r, s, l) && l <= o) c(r, l)
  }
  return mergeOverlappingParts(n)
  function c(t, e) {
    t = getFirstBefore(r, t, i)
    e = getFirstAfter(r, o, e, i)
    n.push([t, e])
  }
}
function mergeOverlappingParts(t) {
  const e = t.length
  if (e <= 1) return t
  const i = []
  i.push(t[0])
  let n, s
  for (let r = 1; r < e; r++) {
    n = t[r - 1]
    s = t[r]
    if (n[1] >= s[0]) n[1] = s[1]
    else i.push(s)
  }
  return i
}
function isIn(t, e, i) {
  return t >= e && t <= i
}
function getFirstBefore(t, e, i) {
  const n = Math.floor((e - t) / i)
  return n > 0 ? t + n * i : t
}
function getFirstAfter(t, e, i, n) {
  const s = getFirstBefore(t, i, n)
  return Math.min(e, s + n)
}
export const ClipSectionUtil = {
  getVisibleSections: getVisibleSections,
  getVisibleSectionsWithLimit: getVisibleSectionsAtStart,
  getVisibleParts: getVisibleParts,
  getMutualVisibleParts: getMutualVisibleParts,
}
