import { CapabilitiesParserUtil } from './common/CapabilitiesParserUtil.js'
export class WMSCapabilitiesLayer {
  constructor(t) {
    this._children = t['children']
    this._title = t['title']
    this._name = t['name']
    this._styles = t['styles']
    this._supportedReferences = t['supportedReferences']
    this._queryable = t['queryable']
    this._cascaded = t['cascaded']
    this._opaque = t['opaque']
    this._noSubsets = t['noSubsets']
    this._fixedWidth = t['fixedWidth']
    this._fixedHeight = t['fixedHeight']
    this._abstract = t['abstract']
    this._keywords = t['keywords']
    this._attribution = t['attribution']
    this._metadata = t['metadata']
    this._identifiers = t['identifiers']
    this._featureListUrl = t['featureListUrl']
    this._dataUrl = t['dataUrl']
    this._dimensions = t['dimensions']
    this._minScaleDenominator = t['minScaleDenominator']
    this._maxScaleDenominator = t['maxScaleDenominator']
    this._bounds = t.bounds.concat(t.wgs84Bounds)
    this._swapAxes = t.swapAxes
  }
  setParent(t) {
    this._parent = t
  }
  get parent() {
    return this._parent
  }
  get minScaleDenominator() {
    return this._minScaleDenominator
  }
  get maxScaleDenominator() {
    return this._maxScaleDenominator
  }
  get featureListUrl() {
    return this._featureListUrl
  }
  get dataUrl() {
    return this._dataUrl
  }
  get dimensions() {
    return this._dimensions
  }
  get supportedReferences() {
    return this._supportedReferences
  }
  get queryable() {
    return this._queryable
  }
  get cascaded() {
    return this._cascaded
  }
  get opaque() {
    return this._opaque
  }
  get noSubsets() {
    return this._noSubsets
  }
  get fixedWidth() {
    return this._fixedWidth
  }
  get fixedHeight() {
    return this._fixedHeight
  }
  get abstract() {
    return this._abstract
  }
  get keywords() {
    return this._keywords
  }
  get attribution() {
    return this._attribution
  }
  get metadata() {
    return this._metadata
  }
  get children() {
    return this._children
  }
  get title() {
    return this._title
  }
  get name() {
    return this._name
  }
  get styles() {
    return this._styles
  }
  get identifiers() {
    return this._identifiers
  }
  getBounds(t) {
    return CapabilitiesParserUtil.getBounds(this._bounds, t, this._swapAxes)
  }
}
