import { WFSVersions } from '../../WFSVersions.js'
import { OGCEncoder100 } from './OGCEncoder100.js'
export class OGCEncoder110 extends OGCEncoder100 {
  constructor() {
    let e
    let r
    let t
    super(
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : WFSVersions.V110,
      arguments.length > 1 && void 0 !== arguments[1]
        ? arguments[1]
        : 'http://www.opengis.net/ogc',
      arguments.length > 2 && void 0 !== arguments[2]
        ? arguments[2]
        : 'http://www.opengis.net/gml'
    )
  }
  encodeBboxAsGML(e, r, t, i, o, s) {
    this.xmlWriter.beginNode('Envelope', this.gmlPrefix)
    this.xmlWriter.attribute('srsName', o)
    const n = this.shouldSwapAxes(o, s)
    this.xmlWriter.beginNode('lowerCorner', this.gmlPrefix)
    this.xmlWriter.value(n ? r + ' ' + e : e + ' ' + r)
    this.xmlWriter.endNode()
    this.xmlWriter.beginNode('upperCorner', this.gmlPrefix)
    this.xmlWriter.value(n ? i + ' ' + t : t + ' ' + i)
    this.xmlWriter.endNode()
    this.xmlWriter.endNode()
  }
}
