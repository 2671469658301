import { rectangleSegmentIntersectionsSFCT } from '../../util/Cartesian.js'
function sumInteractions(t, e, n, i, s, o, r) {
  if (
    isBetween(t, r.left, r.right) &&
    isBetween(e, r.bottom, r.top) &&
    isBetween(i, r.left, r.right) &&
    isBetween(s, r.bottom, r.top)
  ) {
    r.interactsCounts += 1
    return r
  }
  const a = []
  rectangleSegmentIntersectionsSFCT(
    r.left,
    r.top,
    r.right,
    r.bottom,
    t,
    e,
    i,
    s,
    a
  )
  if (a.intersections) r.interactsCounts += a.intersections
  return r
}
function isBetween(t, e, n) {
  return t >= e && t <= n
}
const tmpOut = [0, 0]
const tmpIn = [0, 0]
export const CommandUtil = {
  lineInteractsFlat(t, e, n, i) {
    const s = n / 2
    tmpIn[0] = s
    tmpIn[1] = s
    i.toWorldDistance(tmpIn, tmpOut)
    const o = Math.abs(tmpOut[0])
    const r = Math.abs(tmpOut[1])
    let a = {
      left: Math.min(e.x - o, e.x + e.width + o),
      top: Math.max(e.y - r, e.y + e.height + r),
      right: Math.max(e.x - o, e.x + e.width + o),
      bottom: Math.min(e.y - r, e.y + e.height + r),
      interactsCounts: 0,
    }
    let c = false
    for (let e = 5, n = t.length; e < n; e += 3) {
      a = sumInteractions(
        t[e - 5],
        t[e - 4],
        t[e - 3],
        t[e - 2],
        t[e - 1],
        t[e],
        a
      )
      if (a.interactsCounts > 0) {
        c = true
        break
      }
    }
    return c
  },
  expandBoundsSFCT(t, e) {
    let n = e.x
    let i = n + e.width
    let s = e.y
    let o = s + e.height
    const r = t.x
    const a = r + t.width
    const c = t.y
    const m = c + t.height
    if (r < n) n = r
    if (a > i) i = a
    if (c < s) s = c
    if (m > o) o = m
    e.setTo2D(n, i - n, s, o - s)
  },
}
