import { Constants } from '../util/Constants.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import {
  containsAngle,
  containsAnglePragmatic,
  ellipticalDistance2D,
} from '../util/Cartesian.js'
export function calculateBounds(t, n) {
  const o = t.reference.geodeticDatum.ellipsoid
  const s = t.center
  const e = t.a
  const c = t.b
  const a = t.startPoint
  const i = t.endPoint
  const r = 90 - t.rotationAzimuth
  const l = t.startAzimuth
  const h = t.sweepAngle
  n.move2DToPoint(a)
  n.setToIncludePoint2D(i)
  const D = new LLHPoint(t.reference)
  const A = Constants.DEG2RAD * r
  const u = Math.cos(A)
  const m = Math.sin(A)
  const d = e * u
  const f = e * m
  const g = c * u
  const C = c * m
  const P = Math.atan2(-c * m, e * u)
  const p = Math.atan2(c * u, e * m)
  const E = P + Math.PI
  const L = p + Math.PI
  T(P, P)
  T(E, E)
  T(p, p)
  T(L, L)
  const M = 1e-8
  n.translate2D(-M, -M)
  n.width = n.width + 2 * M
  n.height = n.height + 2 * M
  function T(t, e) {
    const c = Math.sin(t)
    const a = Math.cos(e)
    const i = d * a - C * c
    const r = f * a + g * c
    const A = undefined
    const u = 90 - Math.atan2(r, i) * Constants.RAD2DEG
    if (containsAngle(l, h, u)) {
      const t = Math.sqrt(i * i + r * r)
      o.geodesicPositionSFCT(s, t, u, D)
      n.setToIncludePoint2D(D)
    }
  }
}
export function arcContains2D(t, n, o) {
  const s = t.reference.geodeticDatum.ellipsoid
  const e = new LLHPoint(t.reference)
  e.move2D(n, o)
  const c = s.forwardAzimuth2D(t.center, e) * Constants.RAD2DEG
  if (!containsAnglePragmatic(t.startAzimuth, t.sweepAngle, c)) return false
  const a = ellipticalDistance2D(t.a, t.b, t.rotationAzimuth - c)
  const i = s.geodesicDistance(t.center, e)
  return (
    Math.abs(i - a) <=
    Constants.ABSOLUTE_DISTANCE_TOLERANCE +
      Constants.RELATIVE_DISTANCE_TOLERANCE * a
  )
}
