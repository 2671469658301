import * as SnapUtil from '../snapping/SnapUtil.js'
import { ThreeStepEditHandle } from './ThreeStepEditHandle.js'
import { convertToAboveGround, isDrapableMeshOnMap } from './HandleUtil.js'
import { isDefined, isUndefined } from '../../../util/Lang.js'
import { getInteractionRadius } from '../../controller/EditSettings.js'
import { interactsWithControllerShape } from '../EditHandle.js'
import { DrapeTarget } from '../../style/DrapeTarget.js'
export class PointHandle extends ThreeStepEditHandle {
  constructor(e) {
    super()
    this._useDefaultHandleIconStyle = isUndefined(e)
    this._handleIconStyle = isUndefined(e) ? null : e
    this._snapPoint = null
  }
  getDefaultHandleIconStyle(e) {
    return e.settings.styles.handleIconStyle
  }
  getHandleIconStyle(e) {
    if (this._useDefaultHandleIconStyle)
      return this.getDefaultHandleIconStyle(e)
    return this._handleIconStyle
  }
  get snapPoint() {
    return this._snapPoint
  }
  set snapPoint(e) {
    this._snapPoint = e
  }
  onDraw(e, t) {
    const n = this.getHandleIconStyle(t)
    if (!n) return
    let r = this.getPoint()
    if (0 === r.z) r = convertToAboveGround(t.map, r)
    e.drawIcon(r, { ...n, drapeTarget: this.getDrapeTarget(t) })
    SnapUtil.paintSnapIcon(e, this._snapPoint)
  }
  getDrapeTarget(e) {
    const t = this.getHandleIconStyle(e)
    if (t && isDefined(t.drapeTarget)) return t.drapeTarget
    if (t && isDefined(t.draped))
      return t.draped ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
    const n = undefined
    if (0 !== this.getPoint().z) return DrapeTarget.NOT_DRAPED
    return isDrapableMeshOnMap(e.map) ? DrapeTarget.ALL : DrapeTarget.NOT_DRAPED
  }
  getCursor(e, t) {
    if (this.active) return 'grab'
    else if (this.interacts(e, t)) return 'pointer'
    return null
  }
  interacts(e, t) {
    const n = getInteractionRadius(e, t)
    const { x: r, y: a } = e.viewPoint
    return interactsWithControllerShape(t.map, r, a, n, this.getPoint())
  }
}
export function scaleIconStyle(e, t) {
  if (!e) return null
  return {
    image: e.image,
    url: e.url,
    rotation: e.rotation,
    anchorX: e.anchorX,
    anchorY: e.anchorY,
    offsetX: 'number' === typeof e.offsetX ? e.offsetX * t : void 0,
    offsetY: 'number' === typeof e.offsetY ? e.offsetY * t : void 0,
    stem: e.stem,
    width: `${100 * t}%`,
    height: `${100 * t}%`,
    drapeTarget: e.drapeTarget,
    zOrder: 10,
  }
}
