import { isFunction } from './Lang.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
export function toUrlFromRoot(r) {
  const o = window
  let e = 'string' === typeof o.__LUCIAD_ROOT__ ? o.__LUCIAD_ROOT__ : null
  if (e) {
    e = e.replace(/\/$/, '')
    return e + '/' + r
  } else if (
    'object' === typeof o &&
    isFunction(o.require) &&
    isFunction(o.require.toUrl)
  )
    return o.require.toUrl('luciad/' + r)
  else
    throw new ProgrammingError(
      'The root location of the LuciadRIA module could not be determined. Please use' +
        'the __LUCIAD_ROOT__ global variable.'
    )
}
