import { Constants } from '../util/Constants.js'
import { buildCache, Cacher } from '../util/Cacher.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { XYZGeoBufferHelper } from '../geometry/constructivegeometry/geobuffer/XYZGeoBufferHelper.js'
import { ShapeType } from './ShapeType.js'
import { GeoBuffer } from './GeoBuffer.js'
import { XYZPoint } from './XYZPoint.js'
import { EndCapStyle } from './EndCapStyle.js'
import { closestPointOnSegment, distance2D } from '../util/Cartesian.js'
const ABS_TOLERANCE = 0.01
class XYZGeoBuffer extends GeoBuffer {
  constructor(t, e, i) {
    super()
    this._reference = this.validateReference(t)
    if ('number' === !typeof i)
      throw new ProgrammingError(
        'XYZGeoBuffer::cannot create a geoBuffer without a proper width'
      )
    if (i >= 0) this._width = i
    else
      throw new ProgrammingError(
        'Width should be positive number, but was: ' + i
      )
    if (!GeoBuffer.isSupportedBaseShape(e))
      throw new ProgrammingError(
        'Invalid base shape: It must be a polyline or a polygon'
      )
    this._baseShape = e
    this._endCapStyle = EndCapStyle.CAP_ROUND
    this._cache = buildCache()
    this._tempPoint = new XYZPoint(this._reference)
    this._bufferHelper = new XYZGeoBufferHelper(this._reference)
  }
  get isGeodetic() {
    return false
  }
  set isGeodetic(t) {
    throw new ProgrammingError('isGeodetic property is not mutable')
  }
  get bounds() {
    const t = this
    return this._cache.memoize('buffer-bounds', function () {
      let e = t.baseShape.bounds
      if (null === e) return null
      e = e.copy()
      e.translate2D(-t.width, -t.width)
      e.width += 2 * t.width
      e.height += 2 * t.width
      return e
    })
  }
  set bounds(t) {
    throw new ProgrammingError('bounds property is not mutable')
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  set coordinateType(t) {
    throw new ProgrammingError('coordinateType property is not mutable')
  }
  copy() {
    var t = new XYZGeoBuffer(
      this._reference,
      this._baseShape.copy(),
      this._width
    )
    t.endCapStyle = this._endCapStyle
    return t
  }
  contains2DPoint(t) {
    let e, i, r, n, o, s, a
    if (!this.bounds.contains2DPoint(t)) return false
    if (this._baseShape.contains2DPoint(t)) return true
    if (ShapeType.contains(this._baseShape.type, ShapeType.POLYGON))
      return this._nearPolygonBounds(this._baseShape, t)
    const h = this._baseShape.pointCount
    if (0 == h) return false
    if (1 == h) return this._baseShape.getPoint(0).contains2DPoint(t)
    const f = this._baseShape.getPoint(0)
    const p = this._baseShape.getPoint(1)
    if (2 == h) {
      a = closestPointOnSegment(f, p, t, this._tempPoint)
      i = a.distance
      if (i <= this._width) {
        if (this._endCapStyle === EndCapStyle.CAP_ROUND) return true
        if (distance2D(f, this._tempPoint) <= ABS_TOLERANCE) {
          r = forwardAzimuth2D(f, p) * Constants.RAD2DEG
          n = forwardAzimuth2D(this._tempPoint, t) * Constants.RAD2DEG
          s = r - n
          if (s < 0) s += 360
          if (s > 90 && s < 270) return false
        }
        if (distance2D(p, this._tempPoint) <= ABS_TOLERANCE) {
          o = forwardAzimuth2D(p, f) * Constants.RAD2DEG
          n = forwardAzimuth2D(this._tempPoint, t) * Constants.RAD2DEG
          s = o - n
          if (s < 0) s += 360
          if (s > 90 && s < 270) return false
        }
        return true
      }
      return false
    }
    if (h > 2)
      for (e = 1; e < h - 2; e++) {
        a = closestPointOnSegment(
          this._baseShape.getPoint(e),
          getPoint(e + 1),
          t,
          this._tempPoint
        )
        i = a.distance
        if (i <= this._width) return true
      }
    a = closestPointOnSegment(f, p, t, this._tempPoint)
    i = a.distance
    if (i <= this._width)
      if (this._endCapStyle === EndCapStyle.CAP_ROUND) return true
      else {
        if (distance2D(f, this._tempPoint) <= ABS_TOLERANCE) {
          r = forwardAzimuth2D(f, p) * Constants.RAD2DEG
          n = forwardAzimuth2D(this._tempPoint, t) * Constants.RAD2DEG
          s = r - n
          if (s < 0) s += 360
          if (s > 90 && s < 270) return false
        }
        return true
      }
    a = closestPointOnSegment(
      this._baseShape.getPoint(h - 2),
      this._baseShape.getPoint(h - 1),
      t,
      this._tempPoint
    )
    i = a.distance
    if (i <= this._width) {
      if (this._endCapStyle === EndCapStyle.CAP_ROUND) return true
      else if (
        distance2D(this._baseShape.getPoint(h - 1), this._tempPoint) <=
        ABS_TOLERANCE
      ) {
        o =
          forwardAzimuth2D(
            this._baseShape.getPoint(h - 1),
            this._baseShape.getPoint(h - 2)
          ) * Constants.RAD2DEG
        n = forwardAzimuth2D(this._tempPoint, t) * Constants.RAD2DEG
        s = o - n
        if (s < 0) s += 360
        if (s > 90 && s < 270) return false
      }
      return true
    }
    return false
  }
  contains2DCoordinates(t, e) {
    return this.contains2DPoint(new XYZPoint(this._reference, [t, e]))
  }
  _nearPolygonBounds(t, e) {
    let i, r, n
    const o = t.pointCount
    if (0 === o) return false
    if (1 === o) return distance2D(t.getPoint(0), e) <= this._width
    for (n = 0; n < o - 1; ++n) {
      i = closestPointOnSegment(
        t.getPoint(n),
        t.getPoint(n + 1),
        e,
        this._tempPoint
      )
      r = i.distance
      if (r <= this._width) return true
    }
    if (o > 2) {
      i = closestPointOnSegment(
        t.getPoint(o - 1),
        t.getPoint(0),
        e,
        this._tempPoint
      )
      r = i.distance
      if (r <= this._width) return true
    }
    return false
  }
}
export { XYZGeoBuffer }
