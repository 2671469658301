import { ProgrammingError } from '../error/ProgrammingError.js'
import { ShapeType } from './ShapeType.js'
import { ArcBandSupport } from './ArcBandSupport.js'
import { Shape } from './Shape.js'
import { NotImplementedError } from '../error/NotImplementedError.js'
import { ShapeUtil } from './ShapeUtil.js'
export class Sector extends Shape {
  constructor(e, t, r, a, n) {
    super()
    this._reference = e ? e : null
    this._compareReferenceStrict(
      t.reference,
      'Sector::the center point reference must be equal to the arc reference'
    )
    this._checkParams(0, r)
    this.delegate = new ArcBandSupport(e, t, 0, r, a, n, 'Sector', this)
  }
  get type() {
    return ShapeType.SECTOR
  }
  toString() {
    return `Sector[center${this.center.toString(true)}, radius[${
      this.radius
    }], startAzimuth[${this.startAzimuth}], sweepAngle[${this.sweepAngle}]]`
  }
  _checkParams(e, t) {
    if (0 !== e)
      throw new ProgrammingError(
        `The _minRadius property of a Sector should always be 0, but was: ${e}`
      )
    if (t < 0)
      throw new ProgrammingError(
        `Radius length should be positive number, but was: ${t}`
      )
  }
  get maxRadius() {
    return this.radius
  }
  get radius() {
    return this.delegate.maxRadius
  }
  set radius(e) {
    this.delegate.maxRadius = e
  }
  get minRadius() {
    return 0
  }
  get minRadiusStartCorner() {
    return this.delegate.minRadiusStartCorner
  }
  get minRadiusEndCorner() {
    return this.delegate.minRadiusEndCorner
  }
  get maxRadiusStartCorner() {
    return this.radiusStartCorner
  }
  get maxRadiusEndCorner() {
    return this.radiusEndCorner
  }
  get radiusStartCorner() {
    return this.delegate.maxRadiusStartCorner
  }
  get radiusEndCorner() {
    return this.delegate.maxRadiusEndCorner
  }
  _updateMinRadius(e) {
    throw new ProgrammingError(
      'The _minRadius property of a Sector cannot be updated'
    )
  }
  updateStartAzimuth(e) {
    this.delegate.updateStartAzimuth(e)
  }
  updateEndAngle(e) {
    this.delegate.updateEndAngle(e)
  }
  updateRadius(e) {
    this.delegate.updateMaxRadius(e)
  }
  get center() {
    return this.delegate.center
  }
  get focusPoint() {
    return this.delegate.focusPoint
  }
  get startAzimuth() {
    return this.delegate.startAzimuth
  }
  set startAzimuth(e) {
    this.delegate.startAzimuth = e
  }
  get sweepAngle() {
    return this.delegate.sweepAngle
  }
  set sweepAngle(e) {
    this.delegate.sweepAngle = e
  }
  get bounds() {
    return this.delegate.bounds
  }
  move2DToCoordinates(e, t) {
    this.delegate.move2DToCoordinates(e, t)
  }
  move2DToPoint(e) {
    this.delegate.move2DToPoint(e)
  }
  hashCode(e) {
    this.delegate.hashCode(e)
  }
  equals(e) {
    if (this === e) return true
    if (!ShapeUtil.referencesEqual(this.reference, e.reference)) return false
    return (
      e instanceof Sector &&
      this.type === e.type &&
      this.minRadius === e.minRadius &&
      this.radius === e.radius &&
      this.startAzimuth === e.startAzimuth &&
      this.sweepAngle === e.sweepAngle &&
      this.center.equals(e.center)
    )
  }
  distance2D(e, t) {
    throw new NotImplementedError('Descendant must implement distance2D method')
  }
  forwardAzimuth2D(e, t) {
    throw new NotImplementedError(
      'Descendant must implement forwardAzimuth2D method'
    )
  }
  calculatePoint(e, t) {
    throw new NotImplementedError(
      'Descendant must implement calculatePoint method'
    )
  }
  contains2DCoordinates(e, t) {
    throw new NotImplementedError(
      'Descendant must implement contains2DCoordinates method'
    )
  }
  calculateBounds() {
    throw new NotImplementedError(
      'Descendant must implement calculateBounds method'
    )
  }
  contains3DCoordinates(e, t, r) {
    throw new ProgrammingError(
      'contains3DCoordinates can not be called on a 2D ArcBand or Sector'
    )
  }
  translate2D(e, t) {
    this.delegate.translate2D(e, t)
  }
  invalidate() {
    this.delegate.invalidate()
  }
}
