import { isDefined, isNumber } from '../util/Lang.js'
export class BloomEffectImpl {
  constructor(t) {
    this.setToLiteral(t)
  }
  setToLiteral(t) {
    if (!isDefined(t)) return
    this.strength = t.strength ?? 1
    this.radius = t.radius ?? 0.5
    this.threshold = t.threshold ?? null
  }
  get strength() {
    return this._strength
  }
  set strength(t) {
    if (isNumber(t, false) && t >= 0) this._strength = t
    else throw new Error(`Invalid strength: ${t}`)
  }
  get radius() {
    return this._radius
  }
  set radius(t) {
    if (isNumber(t, false) && t >= 0 && t <= 1) this._radius = t
    else throw new Error(`Invalid radius: ${t}`)
  }
  get threshold() {
    return this._threshold
  }
  set threshold(t) {
    if (null === t || (isNumber(t, false) && t >= 0)) this._threshold = t
    else throw new Error(`Invalid threshold: ${t}`)
  }
}
