import { Constants } from '../../util/Constants.js'
var PRESSURE_THRESHOLD = 0
function identifiedTouch(t, e) {
  var i = t.length
  for (var r = 0; r < i; r++) {
    var s = t[r]
    if (s.identifier === e) return s
  }
  return null
}
function removeTouches(t, e) {
  for (var i = 0, r = e.length; i < r; i++) {
    var s = e[i].identifier
    for (var o = t.length - 1; o >= 0; o--) if (t[o] === s) t.splice(o, 1)
  }
}
function angle(t, e, i, r) {
  var s = t - i
  var o = e - r
  var u
  return -Math.atan2(o, s) * Constants.RAD2DEG + 180
}
function MultitouchGestureDetector(t) {
  this._gestureInProgress = null
  this._focusX = null
  this._focusY = null
  this._prevFingerDiffX = null
  this._prevFingerDiffY = null
  this._currFingerDiffX = null
  this._currFingerDiffY = null
  this._currLen = null
  this._prevLen = null
  this._scaleFactor = null
  this._scaleFactorFromStart = null
  this._currPressure = null
  this._prevPressure = null
  this._timeDelta = null
  this._listener = t
  this._pointerIDs = []
}
MultitouchGestureDetector.prototype = Object.create(Object.prototype)
MultitouchGestureDetector.prototype.constructor = MultitouchGestureDetector
MultitouchGestureDetector.prototype.ontouchstart = function (t) {
  var e = this._pointerIDs
  var i = t.changedTouches
  for (var r = 0, s = i.length; r < s; r++) e.push(i[r].identifier)
  return this._processEvent(t, 'down', e)
}
MultitouchGestureDetector.prototype.ontouchmove = function (t) {
  var e = this._pointerIDs
  if (0 == e.length) {
    var i = t.targetTouches
    for (var r = 0, s = i.length; r < s; r++) e.push(i[r].identifier)
  }
  return this._processEvent(t, 'move', e)
}
MultitouchGestureDetector.prototype.ontouchend = function (t) {
  removeTouches(this._pointerIDs, t.changedTouches)
  var e
  return this._processEvent(t, 'up', this._pointerIDs)
}
MultitouchGestureDetector.prototype.ontouchcancel = function (t) {
  removeTouches(this._pointerIDs, t.changedTouches)
  var e
  return this._processEvent(t, 'cancel', this._pointerIDs)
}
MultitouchGestureDetector.prototype.onblur = function (t) {
  this._pointerIDs = []
  this._processEvent(t, 'cancel', this._pointerIDs)
  return false
}
MultitouchGestureDetector.prototype.oncontextmenu = function (t) {
  this._pointerIDs = []
  this._processEvent(t, 'cancel', this._pointerIDs)
  return false
}
MultitouchGestureDetector.prototype._processEvent = function (t, e, i) {
  var r = false
  var s = t.targetTouches
  var o = s ? s.length : 0
  if (!this._gestureInProgress) {
    if (('down' == e || 'move' == e) && o >= 2) {
      this.reset()
      if (i.length >= 2) {
        this.savePrevEvent(t, i)
        this._timeDelta = 0
        this.setContext(t, i, true)
        this._listener.onMultiTouchGestureBegin(this)
        this._gestureInProgress = true
        r = true
      }
    }
  } else if ('up' == e || 'cancel' == e) {
    if (1 == i.length) {
      var u =
        identifiedTouch(t.touches, i[0]) ||
        identifiedTouch(t.changedTouches, i[0])
      if (u) {
        this._focusX = u.pageX
        this._focusY = u.pageY
      }
    }
    this._listener.onMultiTouchGestureEnd(this, t)
    this.reset()
    r = true
  } else if ('move' == e) {
    this.setContext(t, i)
    if (this._currPressure / this._prevPressure > PRESSURE_THRESHOLD) {
      this._listener.onMultiTouchGesture(this, t)
      this.savePrevEvent(t, i)
      r = true
    }
  }
  return r
}
MultitouchGestureDetector.prototype.savePrevEvent = function (t, e) {
  var i = e[0]
  var r = e[1]
  var s = t.touches
  var o = t.changedTouches
  var u = identifiedTouch(s, i) || identifiedTouch(o, i)
  var n = identifiedTouch(s, r) || identifiedTouch(o, r)
  this.px0 = u.pageX
  this.py0 = u.pageY
  this.px1 = n.pageX
  this.py1 = n.pageY
  this.prevForce0 = u.force || 0.5
  this.prevForce1 = n.force || 0.5
  this.prevTimeStamp = t.timeStamp
}
MultitouchGestureDetector.prototype.setContext = function (t, e, i) {
  this._timeStamp = t.timeStamp
  this._initLen = -1
  this._currLen = -1
  this._prevLen = -1
  this._scaleFactor = -1
  this._scaleFactorFromStart = -1
  var r = e[0]
  var s = e[1]
  var o = t.touches
  var u = t.changedTouches
  this.curr1 = identifiedTouch(o, s) || identifiedTouch(u, s)
  this.curr0 = identifiedTouch(o, r) || identifiedTouch(u, r)
  this.cx0 = this.curr0.pageX
  this.cy0 = this.curr0.pageY
  this.cx1 = this.curr1.pageX
  this.cy1 = this.curr1.pageY
  var n = this.px1 - this.px0
  var c = this.py1 - this.py0
  var h = this.cx1 - this.cx0
  var a = this.cy1 - this.cy0
  this._prevFingerDiffX = n
  this._prevFingerDiffY = c
  this._currFingerDiffX = h
  this._currFingerDiffY = a
  this._focusX = this.cx0 + h / 2
  this._focusY = this.cy0 + a / 2
  this._timeDelta = t.timeStamp - this.prevTimeStamp
  this._currPressure = (this.curr0.force || 0.5) + (this.curr1.force || 0.5)
  this._prevPressure = this.prevForce0 + this.prevForce1
  if (i) {
    this.initialTouch0 = this.curr0
    this.initialTouch1 = this.curr1
    this.ix0 = this.cx0
    this.iy0 = this.cy0
    this.ix1 = this.cx1
    this.iy1 = this.cy1
    this._initFingerDiffX = this._currFingerDiffX
    this._initFingerDiffY = this._currFingerDiffY
    this._initFocusX = this._focusX
    this._initFocusY = this._focusY
  }
}
MultitouchGestureDetector.prototype.reset = function () {
  this._gestureInProgress = false
}
MultitouchGestureDetector.prototype.isInProgress = function () {
  return this._gestureInProgress
}
MultitouchGestureDetector.prototype.getFocusX = function () {
  return this._focusX
}
MultitouchGestureDetector.prototype.getInitialFocusX = function () {
  return this._initFocusX
}
MultitouchGestureDetector.prototype.getInitialFocusY = function () {
  return this._initFocusY
}
MultitouchGestureDetector.prototype.getPreviousFocusX = function () {
  return this._previousFocusX
}
MultitouchGestureDetector.prototype.getPreviousFocusY = function () {
  return this._previousFocusY
}
MultitouchGestureDetector.prototype.getFocusY = function () {
  return this._focusY
}
MultitouchGestureDetector.prototype.getInitialSpan = function () {
  if (-1 == this._initLen) {
    var t = this._initFingerDiffX
    var e = this._initFingerDiffY
    this._initLen = Math.sqrt(t * t + e * e)
  }
  return this._initLen
}
MultitouchGestureDetector.prototype.getCurrentSpan = function () {
  if (-1 == this._currLen) {
    var t = this._currFingerDiffX
    var e = this._currFingerDiffY
    this._currLen = Math.sqrt(t * t + e * e)
  }
  return this._currLen
}
MultitouchGestureDetector.prototype.getPreviousSpan = function () {
  if (-1 == this._prevLen) {
    var t = this._prevFingerDiffX
    var e = this._prevFingerDiffY
    this._prevLen = Math.sqrt(t * t + e * e)
  }
  return this._prevLen
}
MultitouchGestureDetector.prototype.getInitialAngle = function () {
  return angle(this.ix0, this.iy0, this.ix1, this.iy1)
}
MultitouchGestureDetector.prototype.getPreviousAngle = function () {
  return angle(this.px0, this.py0, this.px1, this.py1)
}
MultitouchGestureDetector.prototype.getCurrentAngle = function () {
  return angle(this.cx0, this.cy0, this.cx1, this.cy1)
}
MultitouchGestureDetector.prototype.getScaleFactor = function () {
  if (-1 == this._scaleFactor)
    this._scaleFactor = this.getCurrentSpan() / this.getPreviousSpan()
  return this._scaleFactor
}
MultitouchGestureDetector.prototype.getScaleFactorFromStart = function () {
  if (-1 == this._scaleFactorFromStart)
    this._scaleFactorFromStart = this.getCurrentSpan() / this.getInitialSpan()
  return this._scaleFactorFromStart
}
MultitouchGestureDetector.prototype.getTimeDelta = function () {
  return this._timeDelta
}
MultitouchGestureDetector.prototype.getEventTime = function () {
  return this._timeStamp
}
export { MultitouchGestureDetector }
