import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
import { UTMGrid } from '../../reference/UTMGrid.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
export const NORTH_LAT_LIMIT = 84
export const SOUTH_LAT_LIMIT = -80
export class UTMGridSystem {
  constructor() {
    this._northGrids = []
    this._southGrids = []
  }
  getUTMGrid(r, t) {
    if (r < 1 || r > 60)
      throw new ProgrammingError(
        `The given UTM zone (${r}) should lie in the interval [1,60]`
      )
    if (t) {
      let o = this._northGrids[r - 1]
      if (null == o) {
        o = new UTMGrid(r, t)
        this._northGrids[r - 1] = o
      }
      return o
    } else {
      let o = this._southGrids[r - 1]
      if (null == o) {
        o = new UTMGrid(r, t)
        this._southGrids[r - 1] = o
      }
      return o
    }
  }
  getUTMGridForPoint(r) {
    const t = this.retrieveZone(r)
    return this.getUTMGrid(t, r.y >= 0)
  }
  retrieveZone(r) {
    if (!this.inLonLatBounds(r))
      throw new OutOfBoundsError(
        'The given lon lat point is outside the bounds of the UTM grid system.'
      )
    else {
      const t = r.y
      const o = r.x
      let e = Math.floor((o + 186) / 6)
      if (0 == e || 61 == e) e = 1
      if (t < 64 && t >= 56 && o >= 3 && o < 12) e = 32
      if (t >= 72 && o >= 0)
        if (o < 9) e = 31
        else if (o < 21) e = 33
        else if (o < 33) e = 35
        else if (o < 42) e = 37
      return e
    }
  }
  inLonLatBounds(r) {
    const t = r.y
    return t >= SOUTH_LAT_LIMIT && t <= NORTH_LAT_LIMIT
  }
}
