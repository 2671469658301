import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ProjectionType } from './ProjectionType.js'
import { Azimuthal } from './Azimuthal.js'
import { LLHPoint } from '../shape/LLHPoint.js'
const sharedOutOfBoundsError = new OutOfBoundsError('Orthographic')
export class Orthographic extends Azimuthal {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._isConsiderHeight = false
    this._originSinLat = -1
    this._originSinLon = -1
    this._originCosLat = -1
    this._originCosLon = -1
    this._origNormalX = -1
    this._origNormalY = -1
    this._origNormalZ = -1
    this._tangent0X = -1
    this._tangent0Y = -1
    this._tangent0Z = -1
    this._tangent1X = -1
    this._tangent1Y = -1
    this._tangent1Z = -1
    this._E = -1
    this._A = -1
    this._originX = -1
    this._originY = -1
    this._originZ = -1
    this.setOriginLon(t)
    this.setOriginLat(i)
    this.calculateCachedValues()
  }
  setConsiderHeight(t) {
    this._isConsiderHeight = t
  }
  isConsiderHeight() {
    return this._isConsiderHeight
  }
  isAllInBounds() {
    return false
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    const t = this.getOriginLat()
    const i = this.getOriginLon()
    this._originSinLat = Math.sin(t * Constants.DEG2RAD)
    this._originSinLon = Math.sin(i * Constants.DEG2RAD)
    this._originCosLat = Math.cos(t * Constants.DEG2RAD)
    this._originCosLon = Math.cos(i * Constants.DEG2RAD)
    this._origNormalX = this._originCosLat * this._originCosLon
    this._origNormalY = this._originCosLat * this._originSinLon
    this._origNormalZ = this._originSinLat
    this._tangent0X = -this._originSinLon
    this._tangent0Y = this._originCosLon
    this._tangent0Z = 0
    this._tangent1X = -this._originSinLat * this._originCosLon
    this._tangent1Y = -this._originSinLat * this._originSinLon
    this._tangent1Z = this._originCosLat
  }
  updateCachedValues(t) {
    if (t.a != this._A || t.e != this._E) {
      this._A = t.a
      this._E = t.e
      const i = t.radiusVertical(this._originSinLat)
      this._originX = i * this._originCosLat * this._originCosLon
      this._originY = i * this._originCosLat * this._originSinLon
      this._originZ = i * (1 - t.e2) * this._originSinLat
    }
  }
  geodetic2cartesianOnSphereSFCT(t, i, n) {
    if (this.inLonLatBounds(t)) {
      const s = Math.cos(t.x * Constants.DEG2RAD)
      const o = Math.sin(t.x * Constants.DEG2RAD)
      const r = Math.cos(t.y * Constants.DEG2RAD)
      const a = Math.sin(t.y * Constants.DEG2RAD)
      const h = o * this._originCosLon - s * this._originSinLon
      const e = s * this._originCosLon + o * this._originSinLon
      const g = r * h
      const _ = this._originCosLat * a - this._originSinLat * r * e
      if (this.isConsiderHeight()) i += t.z
      n.x = g * i
      n.y = _ * i
    } else throw sharedOutOfBoundsError
  }
  geodetic2cartesianOnEllipsoidSFCT(t, i, n) {
    if (this.inLonLatBounds(t)) {
      this.updateCachedValues(i)
      const s = Math.cos(t.x * Constants.DEG2RAD)
      const o = Math.sin(t.x * Constants.DEG2RAD)
      const r = Math.cos(t.y * Constants.DEG2RAD)
      const a = Math.sin(t.y * Constants.DEG2RAD)
      const h = i.radiusVertical(a)
      const e = this.isConsiderHeight() ? t.z : 0
      const g = (h + e) * r * s
      const _ = (h + e) * r * o
      const c = (h * (1 - i.e2) + e) * a
      const L = g - this._originX
      const l = _ - this._originY
      const d = c - this._originZ
      n.x = this._tangent0X * L + this._tangent0Y * l + this._tangent0Z * d
      n.y = this._tangent1X * L + this._tangent1Y * l + this._tangent1Z * d
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereSFCT(t, i, n) {
    const s = t.x
    const o = t.y
    const r = s * s + o * o
    if (r > i * i * (1 + 1e-15)) throw sharedOutOfBoundsError
    if (this.isConsiderHeight()) i += t.z
    const a = 1 / i
    const h = undefined
    let e = Math.sqrt(r) / i
    e = Math.max(-1, e)
    e = Math.min(1, e)
    const g = Math.sqrt(1 - e * e)
    let _ = g * this._originSinLat + o * a * this._originCosLat
    _ = _ < -1 ? -1 : _ > 1 ? 1 : _
    const c = Math.atan2(
      s * a,
      this._originCosLat * g - o * this._originSinLat * a
    )
    n.x = normalizeLon(this.getOriginLon() + c * Constants.RAD2DEG)
    n.y = Math.asin(_) * Constants.RAD2DEG
  }
  cartesian2geodeticOnEllipsoidSFCT(t, i, n) {
    this.updateCachedValues(i)
    const s = this._originX + t.x * this._tangent0X + t.y * this._tangent1X
    const o = this._originY + t.x * this._tangent0Y + t.y * this._tangent1Y
    const r = this._originZ + t.x * this._tangent0Z + t.y * this._tangent1Z
    const a = this.isConsiderHeight() ? i.a + t.z : i.a
    const h = this.isConsiderHeight() ? i.b + t.z : i.b
    const e = this.isConsiderHeight() ? (a * a) / (h * h) : 1 / (1 - i.e2)
    const g =
      this._origNormalX * this._origNormalX +
      this._origNormalY * this._origNormalY +
      this._origNormalZ * this._origNormalZ * e
    const _ =
      2 *
      (s * this._origNormalX +
        o * this._origNormalY +
        r * this._origNormalZ * e)
    const c = undefined
    const L = _ * _
    let l = L - 4 * g * (s * s + o * o + r * r * e - a * a)
    if (l < 0 && Math.abs(l) / L < this.EPSILON) l = 0
    if (l < 0) throw sharedOutOfBoundsError
    const d = (-_ + Math.sqrt(l)) / (2 * g)
    const C = s + d * this._origNormalX
    const u = o + d * this._origNormalY
    const m = r + d * this._origNormalZ
    const D = Math.sqrt(C * C + u * u)
    const E = D / i.a
    const O = m / i.b
    const M = Math.atan2(
      m + i.eMinor2 * i.b * O * O * O,
      D - i.e2 * i.a * E * E * E
    )
    n.x = Math.atan2(u, C) * Constants.RAD2DEG
    n.y = M * Constants.RAD2DEG
  }
  inLonLatBounds(t) {
    const i = Math.cos(t.x * Constants.DEG2RAD)
    const n = Math.sin(t.x * Constants.DEG2RAD)
    const s = Math.cos(t.y * Constants.DEG2RAD)
    const o = Math.sin(t.y * Constants.DEG2RAD)
    return (
      this._origNormalX * s * i +
        this._origNormalY * s * n +
        this._origNormalZ * o >
      -this.EPSILON
    )
  }
  inWorldBoundsOnEllipsoid(t, i) {
    this.updateCachedValues(i)
    const n = this._originX + t.x * this._tangent0X + t.y * this._tangent1X
    const s = this._originY + t.x * this._tangent0Y + t.y * this._tangent1Y
    const o = this._originZ + t.x * this._tangent0Z + t.y * this._tangent1Z
    const r = this.isConsiderHeight() ? i.a + t.z : i.a
    const a = this.isConsiderHeight() ? i.b + t.z : i.b
    const h = this.isConsiderHeight() ? (r * r) / (a * a) : 1 / (1 - i.e2)
    const e =
      this._origNormalX * this._origNormalX +
      this._origNormalY * this._origNormalY +
      this._origNormalZ * this._origNormalZ * h
    const g =
      2 *
      (n * this._origNormalX +
        s * this._origNormalY +
        o * this._origNormalZ * h)
    const _ = undefined
    const c = g * g
    const L = undefined
    return (c - 4 * e * (n * n + s * s + o * o * h - r * r)) / c > -this.EPSILON
  }
  inWorldBoundsOnSphere(t, i) {
    const n = t.x
    const s = t.y
    return n * n + s * s < i * i * (1 + 1e-15)
  }
  boundaryLons(t) {
    const i = this.getOriginLat()
    const n = this.getOriginLon()
    if ((i > 89.9999999 && t >= 0) || (i < -89.9999999 && t < 0))
      return [[-180, 180]]
    if ((i > 89.9999999 && t < 0) || (i < -89.9999999 && t >= 0)) return []
    const s =
      (-Math.tan(t * Constants.DEG2RAD) * this._originSinLat) /
      this._originCosLat
    if (s <= -0.9999999 || s >= 0.9999999) {
      const i = new LLHPoint(null, [n, t])
      return this.inLonLatBounds(i) ? [[-180, 180]] : []
    }
    let o = Math.acos(s) * Constants.RAD2DEG
    const r = normalizeLon(n + o)
    const a = normalizeLon(n - o)
    o = (r + a) / 2
    const h = new LLHPoint(null, [o, t])
    return this.inLonLatBounds(h)
      ? [[Math.min(r, a), Math.max(r, a)]]
      : [[Math.max(r, a), Math.min(r, a)]]
  }
  boundaryLats(t) {
    const i = this.getOriginLat()
    if (i < 1e-7 && i > -1e-7) {
      const i = new LLHPoint(null, [t, 0])
      return this.inLonLatBounds(i) ? [[-90, 90]] : []
    }
    const n =
      Math.atan(
        (-Math.cos((t - this.getOriginLon()) * Constants.DEG2RAD) *
          this._originCosLat) /
          this._originSinLat
      ) * Constants.RAD2DEG
    return i > 0 ? [[n, 90]] : [[-90, n]]
  }
  cartesianBoundsOnSphereSFCT(t, i) {
    i.setTo2D(-t, 2 * t, -t, 2 * t)
  }
  cartesianBoundsOnEllipsoidSFCT(t, i) {
    const n = t.a
    i.setTo2D(-n, 2 * n, -n, 2 * n)
  }
  encode() {
    return {
      type: 'Orthographic',
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.ORTHOGRAPHIC + ProjectionType.AZIMUTHAL
  }
  get EPSILON() {
    return 5e-16
  }
}
