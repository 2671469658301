import { ChainedTransformation } from '../../transformation/ChainedTransformation.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { LocationMode } from '../../transformation/LocationMode.js'
export class EditContext {
  constructor(r, o, n, a, e) {
    this._map = r
    this._layer = o
    this._feature = n
    this._shape = a
    this._settings = e
    this._onTerrainModelMapTransformation =
      r.getOnTerrainModelWorldTransformation(o.model.reference)
    this._mapModelTransformation = createTransformation(
      r.reference,
      o.model.reference
    )
    this._modelViewTransformation = new ChainedTransformation(
      this._onTerrainModelMapTransformation,
      r.mapToViewTransformation
    )
    this._onTerrainViewModelTransformation = new ChainedTransformation(
      r.getViewToMapTransformation(LocationMode.TERRAIN),
      this._mapModelTransformation
    )
  }
  get onTerrainViewModelTransformation() {
    return this._onTerrainViewModelTransformation
  }
  get onTerrainModelMapTransformation() {
    return this._onTerrainModelMapTransformation
  }
  get layer() {
    return this._layer
  }
  get map() {
    return this._map
  }
  get feature() {
    return this._feature
  }
  get shape() {
    return this._shape
  }
  get settings() {
    return this._settings
  }
}
