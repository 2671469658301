import { WrapperPattern } from './WrapperPattern.js'
import { PatternType } from './util/PatternType.js'
export class CombineWithFallbackPattern extends WrapperPattern {
  constructor(t) {
    super(t, PatternType.COMBINE_WITH_FALLBACK)
  }
  paintOnceOnLine(t, e, a) {
    e.saveState()
    const n = this.pattern.getWidth(e.totalLength())
    const r = Math.min(e.distanceFromStart() + n, a[1])
    this.drawFallback(t, e, r, a)
    e.restoreState()
    e.saveState()
    this.pattern.paintOnceOnLine(t, e, a)
    e.restoreState()
    e.advanceDistance(n)
    return true
  }
  paint(t, e, a, n) {
    const r = n || this.pattern.getWidth(e)
    t.beginPath()
    t.moveTo(0, 0)
    t.lineTo(r, 0)
    this.simpleStroke(t)
    return this.pattern.paint(t, e, a, n)
  }
  appendHash(t) {
    t.appendString('cwf')
    super.appendHash(t)
  }
}
