import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
import { isDefined } from '../../../util/Lang.js'
import { URL } from '../../../util/URL.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { createPoint } from '../../../shape/ShapeFactory.js'
import { createTransformation } from '../../../transformation/TransformationFactory.js'
import { ModifierType } from '../../input/ModifierType.js'
import { distance2D_xy } from '../../../util/Cartesian.js'
const tempViewPoint = createPoint(null, [])
const ELLIPSE_SNAP_TARGET_COUNT = 5
function getClosestPointFromShapeToMouseCoordinatesSFCT(
  e,
  t,
  n,
  o,
  i,
  a,
  r,
  s,
  c
) {
  let p, l, T
  if (e.type === ShapeType.POINT)
    buildClosestDistanceAndPointObject(
      e,
      e,
      t,
      n,
      o,
      i,
      c.distance,
      a,
      r.mapToViewTransformation,
      s,
      c
    )
  else
    switch (e.type) {
      case ShapeType.POLYLINE:
      case ShapeType.POLYGON: {
        const T = e
        for (p = 0; p < T.pointCount; p++) {
          l = T.getPoint(p)
          buildClosestDistanceAndPointObject(
            l,
            e,
            t,
            n,
            o,
            i,
            c.distance,
            a,
            r.mapToViewTransformation,
            s,
            c
          )
        }
        break
      }
      case ShapeType.GEO_BUFFER: {
        const T = e.baseShape
        for (p = 0; p < T.pointCount; p++) {
          l = T.getPoint(p)
          buildClosestDistanceAndPointObject(
            l,
            e,
            t,
            n,
            o,
            i,
            c.distance,
            a,
            r.mapToViewTransformation,
            s,
            c
          )
        }
        break
      }
      case ShapeType.EXTRUDED_SHAPE:
        getClosestPointFromShapeToMouseCoordinatesSFCT(
          e.baseShape,
          t,
          n,
          o,
          i,
          a,
          r,
          s,
          c
        )
        break
      case ShapeType.SHAPE_LIST: {
        const l = e
        for (p = 0; p < l.shapeCount; p++)
          getClosestPointFromShapeToMouseCoordinatesSFCT(
            l.getShape(p),
            t,
            n,
            o,
            i,
            a,
            r,
            s,
            c
          )
        break
      }
      case ShapeType.ELLIPSE:
        for (p = 0; p < ELLIPSE_SNAP_TARGET_COUNT; p++) {
          l = getEllipseSnapTarget(e, p)
          buildClosestDistanceAndPointObject(
            l,
            e,
            t,
            n,
            o,
            i,
            c.distance,
            a,
            r.mapToViewTransformation,
            s,
            c
          )
        }
        break
      case ShapeType.ARC: {
        const l = e
        T = [l.startPoint, l.endPoint, l.center]
        for (p = 0; p < T.length; p++)
          buildClosestDistanceAndPointObject(
            T[p],
            e,
            t,
            n,
            o,
            i,
            c.distance,
            a,
            r.mapToViewTransformation,
            s,
            c
          )
        break
      }
      case ShapeType.ARC_BAND: {
        const l = e
        T = [
          l.center,
          l.maxRadiusStartCorner,
          l.maxRadiusEndCorner,
          l.minRadiusStartCorner,
          l.minRadiusEndCorner,
        ]
        for (p = 0; p < T.length; p++)
          buildClosestDistanceAndPointObject(
            T[p],
            e,
            t,
            n,
            o,
            i,
            c.distance,
            a,
            r.mapToViewTransformation,
            s,
            c
          )
        break
      }
      case ShapeType.SECTOR: {
        const l = e
        T = [l.center, l.radiusStartCorner, l.radiusEndCorner]
        for (p = 0; p < T.length; p++)
          buildClosestDistanceAndPointObject(
            T[p],
            e,
            t,
            n,
            o,
            i,
            c.distance,
            a,
            r.mapToViewTransformation,
            s,
            c
          )
        break
      }
      case ShapeType.BOUNDS: {
        const l = e
        T = [
          createPoint(e.reference, [l.x, l.y]),
          createPoint(e.reference, [l.x + l.width, l.y]),
          createPoint(e.reference, [l.x, l.y + l.height]),
          createPoint(e.reference, [l.x + l.width, l.y + l.height]),
          createPoint(e.reference, [l.x + l.width / 2, l.y + l.height / 2]),
        ]
        for (p = 0; p < T.length; p++)
          buildClosestDistanceAndPointObject(
            T[p],
            e,
            t,
            n,
            o,
            i,
            c.distance,
            a,
            r.mapToViewTransformation,
            s,
            c
          )
        break
      }
      default:
        getClosestPointFromComplexShapeToMouseCoordinatesSFCT(
          e,
          t,
          n,
          o,
          i,
          a,
          r,
          s,
          c
        )
    }
}
function getClosestPointFromComplexShapeToMouseCoordinatesSFCT(
  e,
  t,
  n,
  o,
  i,
  a,
  r,
  s,
  c
) {
  if (ShapeType.contains(ShapeType.CIRCLE, e.type)) {
    buildClosestDistanceAndPointObject(
      e.center,
      e,
      t,
      n,
      o,
      i,
      c.distance,
      a,
      r.mapToViewTransformation,
      s,
      c
    )
    if (ShapeType.contains(ShapeType.CIRCLE_BY_3_POINTS, e.type)) {
      const p = e
      buildClosestDistanceAndPointObject(
        p.firstPoint,
        e,
        t,
        n,
        o,
        i,
        c.distance,
        a,
        r.mapToViewTransformation,
        s,
        c
      )
      buildClosestDistanceAndPointObject(
        p.secondPoint,
        e,
        t,
        n,
        o,
        i,
        c.distance,
        a,
        r.mapToViewTransformation,
        s,
        c
      )
      buildClosestDistanceAndPointObject(
        p.thirdPoint,
        e,
        t,
        n,
        o,
        i,
        c.distance,
        a,
        r.mapToViewTransformation,
        s,
        c
      )
    }
  } else if (ShapeType.contains(ShapeType.CIRCULAR_ARC, e.type)) {
    const p = e
    buildClosestDistanceAndPointObject(
      p.startPoint,
      e,
      t,
      n,
      o,
      i,
      c.distance,
      a,
      r.mapToViewTransformation,
      s,
      c
    )
    buildClosestDistanceAndPointObject(
      p.endPoint,
      e,
      t,
      n,
      o,
      i,
      c.distance,
      a,
      r.mapToViewTransformation,
      s,
      c
    )
    if (ShapeType.contains(ShapeType.CIRCULAR_ARC_BY_3_POINTS, e.type))
      buildClosestDistanceAndPointObject(
        p.intermediatePoint,
        e,
        t,
        n,
        o,
        i,
        c.distance,
        a,
        r.mapToViewTransformation,
        s,
        c
      )
  }
}
function buildClosestDistanceAndPointObject(e, t, n, o, i, a, r, s, c, p, l) {
  if (!isDefined(o) || !t.equals(o) || canSnapToItsOwnPoints(t))
    if (!(e === n)) {
      const t = isCloserThanDistance(e, i, a, r, s, c, p)
      if (null !== t) {
        l.distance = t
        l.point = e
      }
    }
}
function canSnapToItsOwnPoints(e) {
  return (
    ShapeType.contains(ShapeType.POLYLINE, e.type) ||
    ShapeType.contains(ShapeType.POLYGON, e.type) ||
    ShapeType.contains(ShapeType.GEO_BUFFER, e.type)
  )
}
function isCloserThanDistance(e, t, n, o, i, a, r) {
  try {
    i.transform(e, r)
    a.transform(r, tempViewPoint)
    const s = distance2D_xy(t, n, tempViewPoint.x, tempViewPoint.y)
    return s < o ? s : null
  } catch (e) {
    OutOfBoundsError.isOrThrow(e)
    return null
  }
}
function getEllipseSnapTarget(e, t) {
  switch (t) {
    case 0:
      return e.center
    case 1:
      return e.interpolate(0)
    case 2:
      return e.interpolate(0.25)
    case 3:
      return e.interpolate(0.5)
    default:
      return e.interpolate(0.75)
  }
}
export function findClosestPoint(e, t, n, o) {
  const i = e.viewPosition[0]
  const a = e.viewPosition[1]
  const r = 'touch' === e.inputType ? 20 : 10
  const s = o._pickRect(i, a, r, r, false, true)
  const c = createPoint(o.reference, [])
  const p = { distance: 2 * r, point: null }
  let l
  let T
  let d
  let f
  let S
  let m, C
  if (null !== s && s.length > 0 && e.modifier !== ModifierType.CTRL)
    for (m = 0; m < s.length; m++) {
      l = s[m]
      if (l.layer.isSnapTarget) {
        T = l.layer
        f = o.getOnTerrainModelWorldTransformation(T.model.reference)
        for (C = 0; C < l.objects.length; C++) {
          d = l.objects[C]
          if (d.shape)
            getClosestPointFromShapeToMouseCoordinatesSFCT(
              d.shape,
              t,
              n,
              i,
              a,
              f,
              o,
              c,
              p
            )
        }
      }
    }
  let u = p.point
  if (
    null !== u &&
    isDefined(n) &&
    null !== n &&
    !n.reference.equals(u.reference)
  ) {
    S = createTransformation(u.reference, n.reference)
    const e = createPoint(n.reference, [])
    S.transform(u, e)
    u = e
  }
  return u
}
function getSnapIconUrl() {
  return URL.fromData(
    'iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABHNCSVQICAgIfAhkiAAAACZJREFUKJFj/J/F8' +
      'J+BFECKhv9ZDP+ZSDKdgYFhVMPg0MBIatIAALfyCbUjQhoaAAAAAElFTkSuQmCC'
  )
}
const SNAP_ICON_SIZE = 12
const SNAP_ICON_IMAGE = new Image()
SNAP_ICON_IMAGE.src = getSnapIconUrl()
let SNAP_STYLE = null
function makeSnapStyle() {
  SNAP_STYLE = {
    image: SNAP_ICON_IMAGE,
    width: `${SNAP_ICON_SIZE}px`,
    height: `${SNAP_ICON_SIZE}px`,
    draped: false,
    zOrder: 10,
  }
}
export function paintSnapIcon(e, t) {
  if (t) {
    if (!SNAP_STYLE) makeSnapStyle()
    e.drawIcon(t, SNAP_STYLE)
  }
}
