export class SingleStateCollection {
  constructor(e, t, i, l) {
    this._layer = e
    this._enabled = t
    this._collection = new Map()
    this._filterPredicate = i
    this._cacheLookup = l
  }
  get enabled() {
    return this._enabled
  }
  enable() {
    this._enabled = true
  }
  disable() {
    this._enabled = false
    return this.replaceIncluded([])
  }
  isIncluded(e) {
    if (!this.validId(e.id)) return false
    return this._collection.has(e.id)
  }
  getIncludedItems() {
    return Array.from(this._collection.values())
  }
  updateItem(e) {
    if (this.isIncluded(e)) this._collection.set(e.id, e)
  }
  removeItem(e) {
    if (this._collection.has(e)) {
      const t = Array.from(this._collection.values()).filter((t) => t.id !== e)
      return this.replaceIncluded(t)
    }
    return { layer: this._layer, added: [], removed: [], validation: {} }
  }
  removeAll() {
    return this.replaceIncluded([])
  }
  updateFilter(e) {
    this._filterPredicate = e
    const t = Array.from(this._collection.values())
    return this.replaceIncluded(t)
  }
  getAdded(e) {
    return e.filter((e) => !this._collection.has(e.id))
  }
  getMissing(e) {
    const t = undefined
    return Array.from(this._collection.values()).filter(
      (t) => !e.some((e) => e.id === t.id)
    )
  }
  split(e, t) {
    const i = []
    const l = []
    for (const s of e) {
      const e = t(s)
      if (e) i.push(e)
      else l.push(s)
    }
    return [i, l]
  }
  validId(e) {
    const t = typeof e
    return 'number' === t || 'string' === t
  }
  replaceIncluded(e) {
    const [t, i] = this.split(e, (e) => (this.validId(e.id) ? e : null))
    let l = this._filterPredicate ? t.filter(this._filterPredicate) : t
    let s = this.getAdded(l)
    if (null !== this._filterPredicate) s = s.filter(this._filterPredicate)
    const [r, d] = this.split(s, (e) => this._cacheLookup(e, true))
    for (const e of r) this._collection.set(e.id, e)
    const n = undefined
    const o = this.getMissing(l).map((e) => this._cacheLookup(e, false) || e)
    for (const e of o) this._collection.delete(e.id)
    return {
      layer: this._layer,
      added: r,
      removed: o,
      validation: {
        badIds: i.length > 0 ? i.map((e) => e.id) : void 0,
        failedToAdd: d.length > 0 ? d.map((e) => e.id) : void 0,
      },
    }
  }
}
