import { NotImplementedError } from '../../error/NotImplementedError.js'
import { ModifierType } from './ModifierType.js'
class CallBack {
  constructor(e, s, t) {
    this._type = e
    this._removeHandle = s
    this._messageHandle = t
  }
  get id() {
    return this._id
  }
  set id(e) {
    this._id = e
  }
  get callBack() {
    return this._callBackFunction
  }
  set callBack(e) {
    this._callBackFunction = e
  }
}
export class Handler {
  constructor() {
    this._callbacks = new Map()
  }
  handleMessage(e) {
    throw new NotImplementedError('Should Implement _handleMessage')
  }
  hasMessages(e) {
    const s = this._callbacks.get(e)
    return !!s && 0 !== s.length
  }
  removeMessages(e) {
    const s = this._callbacks.get(e)
    if (s) {
      const e = s.length
      for (let t = 0; t < e; t++) {
        const e = s[t]
        clearTimeout(e.id)
      }
      s.length = 0
    }
  }
  removeMessage(e, s) {
    const t = this._callbacks.get(e)
    if (t) {
      const e = t.length
      for (let i = 0; i < e; i++)
        if (t[i].id === s) {
          t.splice(i, 1)
          break
        }
    }
  }
  sendEmptyMessageDelayed(e, s) {
    const t = () => {
      this.removeMessage(e, i.id)
      this.handleMessage(e)
    }
    const i = new CallBack(e, this.removeMessage, this.handleMessage)
    i.callBack = t
    i.id = window.setTimeout(i.callBack, s)
    const l = this._callbacks.get(e)
    if (!l) this._callbacks.set(e, [i])
    else l.push(i)
  }
  modifier(e) {
    let s = ModifierType.NO_MOD
    if (e.altKey) s += ModifierType.ALT
    if (e.ctrlKey) s += ModifierType.CTRL
    if (e.shiftKey) s += ModifierType.SHIFT
    return s
  }
}
