import { CoordinateType } from '../reference/CoordinateType.js'
import {
  normalizeLat,
  normalizeLon,
  pointLLH_equalLonLat,
} from '../util/LonLatCoord.js'
import { ShapeType } from './ShapeType.js'
import { Bounds } from './Bounds.js'
import { Point, getValidatedPointRepresentation } from './Point.js'
import { isNumber, isUndefined } from '../util/Lang.js'
const EPSILON = 1e-6
export class LLHBounds extends Bounds {
  constructor(t, e) {
    super()
    this._reference = this.validateReference(t)
    if (!e) e = [0, 0, 0, 0]
    const s = []
    s.push.apply(s, e)
    this._coords = s
    this._setLon(e[0], e[1])
    this._setLat(e[2], e[3])
  }
  get isGeodetic() {
    return true
  }
  setTo2D(t, e, s, i) {
    this._setLon(t, e)
    this._setLat(s, i)
  }
  setToBounds2D(t) {
    this._compareReference(t.reference, t.coordinateType)
    this._setLon(t.x, t.width)
    this._setLat(t.y, t.height)
  }
  setTo3D(t, e, s, i, o, n) {
    this._setLon(t, e)
    this._setLat(s, i)
    this._setZ(o, n)
  }
  setToBounds3D(t) {
    this._compareReference(t.reference, t.coordinateType)
    this._setLon(t.x, t.width)
    this._setLat(t.y, t.height)
    this._setZ(t.z, t.depth)
  }
  _setLon(t, e) {
    let s, i
    if (e <= -360 || e >= 360) {
      s = t
      i = 360
    } else if (e < 0) {
      s = normalizeLon(t + e)
      i = -e
    } else {
      s = normalizeLon(t)
      i = e
    }
    const o = this.coordinates
    o[0] = s
    o[1] = i
  }
  _setLat(t, e) {
    let s
    let i
    if (e < 0) {
      s = normalizeLat(t + e)
      i = -e
    } else {
      s = normalizeLat(t)
      i = e
    }
    if (s + i > 90) i = 90 - s
    const o = this.coordinates
    o[2] = s
    o[3] = i
  }
  _setZ(t, e) {
    const s = this.coordinates
    if (e < 0) {
      t += e
      e = -e
    }
    if (s.length > 4) {
      s[4] = t
      s[5] = e
    } else s.push(t, e)
  }
  getLocation() {
    return new LLHPoint(this.reference, [this.x, this.y, this.z])
  }
  get x() {
    return this._coords[0]
  }
  set x(t) {
    this._setLon(t, this._coords[1])
  }
  get width() {
    return this._coords[1]
  }
  set width(t) {
    this._setLon(this._coords[0], t)
  }
  get y() {
    return this._coords[2]
  }
  set y(t) {
    this._setLat(t, this._coords[3])
  }
  get height() {
    return this._coords[3]
  }
  set height(t) {
    this._setLat(this._coords[2], t)
  }
  get z() {
    return this._coords[4] || 0
  }
  set z(t) {
    this._setZ(t, this.depth)
  }
  get depth() {
    return this._coords[5] || 0
  }
  set depth(t) {
    this._setZ(this.z, t)
  }
  get coordinates() {
    return this._coords
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  get focusPoint() {
    return new LLHPoint(this._reference, [
      this.x + this.width / 2,
      this.y + this.height / 2,
      this.z + this.depth / 2,
    ])
  }
  get bounds() {
    return this
  }
  move2DToCoordinates(t, e) {
    this.setTo2D(t, this.width, e, this.height)
  }
  move3DToCoordinates(t, e, s) {
    this.setTo3D(t, this.width, e, this.height, s, this.depth)
  }
  toString() {
    if (void 0 !== this._coords[4] && void 0 !== this._coords[5])
      return `Bounds[${this.x},${this.width},${this.y},${this.height},${this.z},${this.depth}]`
    else return `Bounds[${this.x},${this.width},${this.y},${this.height}]`
  }
  translate2D(t, e) {
    this.setTo2D(
      t + this._coords[0],
      this.width,
      e + this._coords[2],
      this.height
    )
  }
  translate3D(t, e, s) {
    this.setTo3D(
      t + this._coords[0],
      this.width,
      e + this._coords[2],
      this.height,
      s + this.z,
      this.depth
    )
  }
  enlarge2D(t) {
    super.enlarge2D(t)
    this.normalize()
    return this
  }
  normalize() {
    let t = this.x
    let e = this.y
    let s = this.width
    let i = this.height
    if (s >= 360) {
      t = -180
      s = 360
    }
    t = normalizeLon(t)
    if (i >= 180) {
      e = -90
      i = 180
    }
    e = normalizeLat(e)
    this.setTo2D(t, s, e, i)
    return this
  }
  contains2DCoordinates(t, e) {
    const s = this.x,
      i = this.y,
      o = this.width,
      n = this.height
    return (
      e >= i - EPSILON &&
      e <= i + n + EPSILON &&
      (t >= s - EPSILON ? t <= s + o + EPSILON : t <= s + o - 360 + EPSILON)
    )
  }
  contains2D(t, e) {
    if (isNumber(t) && !isUndefined(e)) return this.contains2DCoordinates(t, e)
    else if ('undefined' !== typeof t.width && 'undefined' !== typeof t.height)
      return this.contains2DBounds(t)
    else return this.contains2DPoint(t)
  }
  contains3DCoordinates(t, e, s) {
    if (!this.contains2D(t, e)) return false
    return this.z <= s && s <= this.z + this.depth
  }
  contains2DBounds(t) {
    return this.contains2DBoundsWithEps(t, 0)
  }
  contains2DBoundsWithEps(t, e) {
    this._compareReference(t.reference, t.coordinateType)
    const s = t.x,
      i = t.y,
      o = t.width,
      n = t.height,
      r = this.x,
      h = this.y,
      c = this.width,
      d = this.height
    const a = e
    return (
      i >= h - e &&
      i + n <= h + d + e &&
      (c >= 360 ||
        (s >= r - e && s + o <= r + c + e) ||
        s + o <= r - 360 + c + e)
    )
  }
  contains3DBounds(t) {
    this._compareReference(t.reference, t.coordinateType)
    if (!this.contains2D(t)) return false
    return this.z <= t.z && t.z + t.depth <= this.z + this.depth
  }
  intersects2D(t) {
    const e = this.x
    const s = this.y
    return !(
      t.y > s + this.height + EPSILON ||
      t.y + t.height < s - EPSILON ||
      ((t.x > e + this.width + EPSILON || t.x + t.width < e - EPSILON) &&
        t.x > e - 360 + this.width + EPSILON &&
        t.x + t.width < e + 360 - EPSILON)
    )
  }
  setTo2DUnion(t) {
    this._compareReference(t.reference, t.coordinateType)
    this.implSetTo2DUnion(t.coordinates)
  }
  setTo3DUnion(t) {
    this._compareReference(t.reference, t.coordinateType)
    const e = t.coordinates
    this.implSetTo3DUnion(e)
  }
  implSetTo2DUnion(t) {
    const e = this.coordinates
    const s = e[0]
    const i = e[1]
    const o = e[2]
    const n = e[3]
    const r = t[0]
    const h = t[1]
    const c = t[2]
    const d = t[3]
    let a, l
    if (
      h >= 360 - EPSILON ||
      i >= 360 - EPSILON ||
      (h + i >= 360 - EPSILON &&
        r + h >= s - EPSILON &&
        s + i - 360 >= r - EPSILON) ||
      (s + i >= r - EPSILON && r + h - 360 >= s - EPSILON)
    ) {
      a = -180
      l = 360
    } else {
      const t = Math.min(s, r)
      const e = Math.max(s + i, r + h)
      const o = Math.min(s, r + 360)
      const n = Math.max(s + i, r + 360 + h)
      const c = Math.min(s + 360, r)
      const d = undefined
      const u = e - t
      const f = n - o
      const p = Math.max(s + i + 360, r + h) - c
      if (u <= f)
        if (u <= p) {
          a = t
          l = u
        } else {
          a = c
          l = p
        }
      else if (f <= p) {
        a = o
        l = f
      } else {
        a = c
        l = p
      }
    }
    const u = Math.min(o, c)
    const f = Math.max(o + n, c + d)
    this.setTo2D(a, l, u, f - u)
  }
  implSetTo3DUnion(t) {
    this.implSetTo2DUnion(t)
    const e = t[4] || 0
    const s = t[5] || 0
    const i = this.z
    const o = Math.min(i, e)
    const n = Math.max(i + this.depth, e + s)
    this._setZ(o, n - o)
  }
  setTo2DIntersection(t) {
    this._compareReference(t.reference, t.coordinateType)
    const e = this.coordinates
    let s = e[0]
    const i = e[1]
    let o = e[2]
    const n = e[3]
    const r = t.coordinates
    let h = r[0]
    const c = r[1]
    const d = r[2]
    const a = undefined
    if (h > 180) h = ((h + 180) % 360) - 180
    else if (h < -180) h = ((h - 180) % 360) + 180
    let l
    let u
    let f = s + i
    let p = h + c
    const _ = f < p ? f : p
    if (s < h) {
      l = _ - h
      p -= 360
      u = (f < p ? f : p) - s
      if (l < 0) l = u
      else if (u < 0) s = h
      else if (i < c) l = i
      else {
        s = h
        l = c
      }
    } else {
      l = _ - s
      f -= 360
      u = (f < p ? f : p) - h
      if (l < 0) {
        s = h
        l = u
      } else if (u < 0);
      else if (i < c) l = i
      else {
        s = h
        l = c
      }
    }
    const L = o + n
    const D = d + r[3]
    if (o < d) o = d
    let m = (L < D ? L : D) - o
    if (l < 0 || m < 0) {
      l = 0
      m = 0
    }
    this.setTo2D(s, l, o, m)
  }
  setTo3DIntersection(t) {
    this._compareReference(t.reference, t.coordinateType)
    const e = this.coordinates
    const s = e[4]
    const i = e[5]
    const o = t.coordinates
    const n = o[4]
    const r = o[5]
    let h = s
    let c = h + i
    if (h < n) h = n
    if (c > n + r) c = n + r
    const d = c - h
    if (d < 0) {
      this.width = 0
      this.height = 0
      this.depth = 0
      return
    }
    this.setTo2DIntersection(t)
    this.z = h
    this.depth = d
  }
  setToIncludePoint2D(t) {
    const e = t.reference
    this._compareReference(e, t.coordinateType)
    const s = t.coordinates
    this.setTo2DUnion(new LLHBounds(e, [s[0], 0, s[1], 0]))
  }
  includeCoordinate2D(t, e) {
    this.implSetTo2DUnion([t, 0, e, 0])
  }
  includeCoordinate3D(t, e, s) {
    this.implSetTo3DUnion([t, 0, e, 0, s || 0, 0])
  }
  setToIncludePoint3D(t) {
    const e = t.reference
    this._compareReference(e, t.coordinateType)
    const s = t.coordinates
    if (s.length > 2)
      this.setTo3DUnion(new LLHBounds(e, [s[0], 0, s[1], 0, s[2], 0]))
    else this.setTo3DUnion(new LLHBounds(e, [s[0], 0, s[1], 0]))
  }
  copy() {
    return new LLHBounds(this._reference, this._coords)
  }
  equals(t) {
    if (this === t) return true
    if (!this._referencesEqual(this.reference, t.reference)) return false
    return (
      this.x === t.x &&
      this.y === t.y &&
      this.z === t.z &&
      this.width === t.width &&
      this.height === t.height &&
      this.depth === t.depth
    )
  }
  interacts2D(t) {
    return this.intersects2D(t)
  }
}
export class LLHPoint extends Point {
  constructor(t, e) {
    let s =
      arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : false
    super()
    this._reference = this.validateReference(t)
    this._cachedBounds = null
    this._coords = getValidatedPointRepresentation(e, s)
  }
  get type() {
    return ShapeType.POINT
  }
  get isGeodetic() {
    return true
  }
  invalidate() {
    this._cachedBounds = null
  }
  copy() {
    const t = [this._coords[0], this._coords[1], this._coords[2]]
    return new LLHPoint(this._reference, t)
  }
  contains3DCoordinates(t, e, s) {
    const i = this._coords
    return i[0] === t && i[1] === e && i[2] === s
  }
  move2DToCoordinates(t, e) {
    const s = this._coords
    s[0] = normalizeLon(t)
    s[1] = normalizeLat(e)
    this.invalidate()
  }
  move3DToCoordinates(t, e, s) {
    const i = this._coords
    i[0] = normalizeLon(t)
    i[1] = normalizeLat(e)
    i[2] = s
    this.invalidate()
  }
  translate2D(t, e) {
    const s = this._coords
    s[0] = normalizeLon(s[0] + t)
    s[1] = normalizeLat(s[1] + e)
    this.invalidate()
  }
  translate3D(t, e, s) {
    const i = this._coords
    i[0] = normalizeLon(i[0] + t)
    i[1] = normalizeLat(i[1] + e)
    i[2] += s
    this.invalidate()
  }
  equals(t) {
    if (
      !t ||
      (t.reference
        ? !t.reference.equals(this.reference)
        : t.reference !== this.reference) ||
      t.type !== ShapeType.POINT
    )
      return false
    return t === this || (t.x === this.x && t.y === this.y && t.z === this.z)
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    t.appendDouble(this.x)
    t.appendDouble(this.y)
    t.appendDouble(this.z)
  }
  toString(t) {
    return `${t ? '' : 'Point'}[${this._coords[0]},${this._coords[1]},${
      this._coords[2]
    }]`
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  set x(t) {
    this._coords[0] = normalizeLon(t)
    this.invalidate()
  }
  get x() {
    return this._coords[0]
  }
  set y(t) {
    this._coords[1] = normalizeLat(t)
    this.invalidate()
  }
  get y() {
    return this._coords[1]
  }
  set z(t) {
    this._coords[2] = t
    this.invalidate()
  }
  get z() {
    return this._coords[2]
  }
  get coordinates() {
    return this._coords
  }
  get focusPoint() {
    return this
  }
  get bounds() {
    if (!this._cachedBounds) {
      const t = this._coords
      this._cachedBounds = new LLHBounds(this._reference, [
        t[0],
        0,
        t[1],
        0,
        t[2],
        0,
      ])
    }
    return this._cachedBounds
  }
  contains2D(t, e) {
    if (isUndefined(e)) return this.contains2DPoint(t)
    return this.contains2DCoordinates(t, e)
  }
  contains2DPoint(t) {
    this._compareReference(t.reference, t.coordinateType)
    return this.contains2DCoordinates(t.x, t.y)
  }
  contains2DCoordinates(t, e) {
    return pointLLH_equalLonLat(this.x, this.y, t, e)
  }
}
