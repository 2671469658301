import { ShapeType } from '../../../shape/ShapeType.js'
import { Editor } from '../Editor.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultSize,
} from '../handles/CreateByTemplateHandle.js'
import { EllipseAxisHelperHandle } from '../handles/helper/EllipseAxisHelperHandle.js'
export class EllipseEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.ELLIPSE, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createMajorAxisHandle(e),
      this.createMajorAxisOppositeHandle(e),
      this.createMinorAxisHandle(e),
      this.createMinorAxisOppositeHandle(e),
      this.createHelperHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const a = e.shape
    return new PointDragHandle(
      () => a.center,
      (e) => a.move2DToPoint(e)
    )
  }
  createMajorAxisHandle(e) {
    return createParamHandle(e, 0)
  }
  createMajorAxisOppositeHandle(e) {
    return createParamHandle(e, 0.5)
  }
  createMinorAxisHandle(e) {
    return createParamHandle(e, 0.25)
  }
  createMinorAxisOppositeHandle(e) {
    return createParamHandle(e, 0.75)
  }
  createHelperHandle(e) {
    const a = e.shape
    return new EllipseAxisHelperHandle(a)
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, a) => {
      const t = e.shape
      t.move2DToPoint(a)
      const r = getDefaultSize(e.map)
      t.a = r
      t.b = r / 2
    })
  }
}
function createParamHandle(e, a) {
  const t = e.shape
  return new PointDragHandle(
    () => t.interpolate(a),
    (e) => t.updatePoint(a, e.x, e.y)
  )
}
