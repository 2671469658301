import {
  getRefreshModeValue,
  getViewRefreshModeValue,
  KMLRefreshMode,
  KMLViewRefreshMode,
} from '../../model/kml/KMLLink.js'
import { getKmlAltitudeMode, KMLAltitudeMode } from './KMLAltitudeMode.js'
import { xmlReduceChildren } from './KMLInternalUtil.js'
import {
  getOverlayXYValue,
  getRotationXYValue,
  getScreenXYValue,
  getSizeValue,
} from './KMLScreenOverlay.js'
import {
  isAddressValue,
  isAltitudeModeValue,
  isAltitudeValue,
  isBeginValue,
  isColorValue,
  isDescriptionValue,
  isDrawOrderValue,
  isFlyToViewValue,
  isHrefValue,
  isHttpQueryValue,
  isIconNode,
  isLatLonBoxNode,
  isLinkNodeBase,
  isLocationNode,
  isNameValue,
  isOpenValue,
  isOrientationNode,
  isOverlayXYValue,
  isPhoneNumberValue,
  isRefreshIntervalValue,
  isRefreshModeValue,
  isRefreshVisibilityValue,
  isResourceMapNode,
  isRotationValue,
  isRotationXYValue,
  isScaleNode,
  isScreenXYValue,
  isSizeValue,
  isSnippetValue,
  isTimeSpanNode,
  isTimeStampNode,
  isViewBoundScaleValue,
  isViewFormatValue,
  isViewRefreshModeValue,
  isViewRefreshTimeValue,
  isVisibilityValue,
} from './KMLTypes.js'
import {
  getKmlBoolean,
  getKmlNumber,
  getKmlValue,
  getLocationValue,
  getOrientationValue,
  getResourceMapValue,
  getScaleValue,
  getSnippetMaxLines,
  getVisibilityValue,
} from './KMLValues.js'
import { DEFAULT_XY_VECTOR, KMLUnits } from './KMLXYVector.js'
import { createBounds } from '../../shape/ShapeFactory.js'
import { getReference } from '../../reference/ReferenceProvider.js'
export function getFeatureProperties(e) {
  return xmlReduceChildren(
    e,
    injectFeatureProperty,
    getDefaultFeatureProperties()
  )
}
function getDefaultFeatureProperties() {
  return {
    address: null,
    description: null,
    errorMessages: null,
    name: null,
    open: false,
    phoneNumber: null,
    snippet: null,
    timeSpan: null,
    timeStamp: null,
    visibility: true,
  }
}
function injectFeatureProperty(e, t) {
  if (
    isNameValue(t) ||
    isDescriptionValue(t) ||
    isAddressValue(t) ||
    isPhoneNumberValue(t)
  ) {
    e[t.nodeName] = getKmlValue(t)
    return e
  }
  if (isVisibilityValue(t)) {
    e.visibility = getVisibilityValue(t)
    return e
  }
  if (isSnippetValue(t)) {
    e.snippet = { maxLines: getSnippetMaxLines(t), text: getKmlValue(t) }
    return e
  }
  if (isOpenValue(t)) {
    e.open = getKmlBoolean(t, e.open)
    return e
  }
  if (isTimeStampNode(t)) {
    e.timeStamp = getKmlValue(t.firstElementChild, e.timeStamp)
    return e
  }
  if (isTimeSpanNode(t)) {
    const r = { begin: null, end: null }
    for (let e = 0; e < t.children.length; e++) {
      const i = t.children[e]
      if (isBeginValue(i)) r.begin = getKmlValue(i)
      else r.end = getKmlValue(i)
    }
    e.timeSpan = r
    return e
  }
  return e
}
export function getNetworkLinkProperties(e) {
  return xmlReduceChildren(
    e,
    injectNetworkLinkProperty,
    getDefaultNetworkLinkProperties()
  )
}
function getDefaultNetworkLinkProperties() {
  return {
    ...getDefaultFeatureProperties(),
    flyToView: false,
    refreshVisibility: false,
    link: getDefaultLinkProperties(),
  }
}
function getDefaultLinkProperties() {
  return {
    href: null,
    httpQuery: null,
    refreshInterval: 4,
    refreshMode: KMLRefreshMode.ON_CHANGE,
    viewBoundScale: 1,
    viewFormat: null,
    viewRefreshMode: KMLViewRefreshMode.NEVER,
    viewRefreshTime: 4,
  }
}
function injectNetworkLinkProperty(e, t) {
  if (isLinkNodeBase(t)) {
    e.link = xmlReduceChildren(t, injectLinkProperty, e.link)
    return e
  }
  if (isFlyToViewValue(t)) {
    e.flyToView = getKmlBoolean(t)
    return e
  }
  if (isRefreshVisibilityValue(t)) {
    e.refreshVisibility = getKmlBoolean(t)
    return e
  }
  return injectFeatureProperty(e, t)
}
function getDefaultOverlayProperties() {
  return {
    ...getDefaultFeatureProperties(),
    icon: getDefaultLinkProperties(),
    drawOrder: null,
    color: null,
  }
}
function injectGroundOverlayRotation(e, t) {
  if (isRotationValue(t)) {
    e.rotation = getKmlNumber(t)
    return e
  }
  return e
}
export function getGroundOverlayProperties(e, t, r) {
  const i = xmlReduceChildren(
    e,
    injectGroundOverlayProperty,
    getDefaultGroundOverlayProperties(t)
  )
  if (r) i.bounds = r
  return i
}
function getDefaultGroundOverlayProperties(e) {
  return {
    ...getDefaultOverlayProperties(),
    altitudeMode: KMLAltitudeMode.CLAMP_TO_GROUND,
    altitude: 0,
    rotation: e,
    bounds: createBounds(getReference('CRS:84'), [0, 0, 0, 0]),
  }
}
function injectGroundOverlayProperty(e, t) {
  if (isIconNode(t)) {
    e.icon = xmlReduceChildren(t, injectLinkProperty, e.icon)
    return e
  }
  if (isAltitudeValue(t)) {
    e.altitude = getKmlNumber(t)
    return e
  }
  if (isAltitudeModeValue(t)) {
    e.altitudeMode = getKmlAltitudeMode(t)
    return e
  }
  if (isLatLonBoxNode(t)) {
    xmlReduceChildren(t, injectGroundOverlayRotation, e)
    return e
  }
  return injectFeatureProperty(e, t)
}
function injectLinkProperty(e, t) {
  if (isHrefValue(t)) {
    e.href = getKmlValue(t)
    return e
  }
  if (isHttpQueryValue(t)) {
    e.httpQuery = getKmlValue(t)
    return e
  }
  if (isRefreshIntervalValue(t)) {
    e.refreshInterval = getKmlNumber(t)
    return e
  }
  if (isRefreshModeValue(t)) {
    e.refreshMode = getRefreshModeValue(t)
    return e
  }
  if (isViewBoundScaleValue(t)) {
    e.viewBoundScale = getKmlNumber(t)
    return e
  }
  if (isViewFormatValue(t)) {
    e.viewFormat = getKmlValue(t)
    return e
  }
  if (isViewRefreshModeValue(t)) {
    e.viewRefreshMode = getViewRefreshModeValue(t)
    return e
  }
  if (isViewRefreshTimeValue(t)) {
    e.viewRefreshTime = getKmlNumber(t)
    return e
  }
  return e
}
export function getScreenOverlayProperties(e) {
  return xmlReduceChildren(
    e,
    injectScreenOverlayPropertySFCT,
    getDefaultScreenOverlayProperties()
  )
}
function getDefaultScreenOverlayProperties() {
  return {
    ...getDefaultOverlayProperties(),
    overlayXY: { ...DEFAULT_XY_VECTOR },
    screenXY: { ...DEFAULT_XY_VECTOR },
    rotationXY: {
      x: 0.5,
      y: 0.5,
      xUnits: KMLUnits.FRACTION,
      yUnits: KMLUnits.FRACTION,
    },
    size: {
      x: -1,
      y: -1,
      xUnits: KMLUnits.FRACTION,
      yUnits: KMLUnits.FRACTION,
    },
    rotation: 0,
  }
}
function injectScreenOverlayPropertySFCT(e, t) {
  if (isIconNode(t)) {
    e.icon = xmlReduceChildren(t, injectLinkProperty, e.icon)
    return e
  }
  if (isColorValue(t)) {
    e.color = getKmlValue(t)
    return e
  }
  if (isDrawOrderValue(t)) {
    e.drawOrder = getKmlNumber(t)
    return e
  }
  if (isOverlayXYValue(t)) {
    e.overlayXY = getOverlayXYValue(t)
    return e
  }
  if (isScreenXYValue(t)) {
    e.screenXY = getScreenXYValue(t)
    return e
  }
  if (isRotationXYValue(t)) {
    e.rotationXY = getRotationXYValue(t)
    return e
  }
  if (isSizeValue(t)) {
    e.size = getSizeValue(t)
    return e
  }
  if (isRotationValue(t)) {
    e.rotation = getKmlNumber(t)
    return e
  }
  return injectFeatureProperty(e, t)
}
export function getModelGeometryConstructorOptions(e) {
  return xmlReduceChildren(
    e,
    injectModelGeometryPropertySFCT,
    getDefaultModelGeometryProperties()
  )
}
function injectModelGeometryPropertySFCT(e, t) {
  if (isAltitudeModeValue(t)) {
    e.altitudeMode = getKmlAltitudeMode(t, e.altitudeMode)
    return e
  }
  if (isLocationNode(t)) {
    e.location = getLocationValue(t, e.location)
    return e
  }
  if (isOrientationNode(t)) {
    e.orientation = getOrientationValue(t, e.orientation)
    return e
  }
  if (isScaleNode(t)) {
    e.scale = getScaleValue(t, e.scale)
    return e
  }
  if (isLinkNodeBase(t)) {
    e.link = xmlReduceChildren(t, injectLinkProperty, e.link)
    return e
  }
  if (isResourceMapNode(t)) {
    e.resourceMap = getResourceMapValue(t, e.resourceMap)
    return e
  }
  return e
}
function getDefaultModelGeometryProperties() {
  return {
    altitudeMode: KMLAltitudeMode.RELATIVE_TO_GROUND,
    id: null,
    link: getDefaultLinkProperties(),
    location: { lat: 0, lon: 0, alt: 0 },
    orientation: { pitch: 0, roll: 0, heading: 0 },
    resourceMap: { alias: [] },
    scale: { x: 1, y: 1, z: 1 },
  }
}
