import {
  segmentIntersectionSFCT,
  squaredDistance2D,
} from '../../../util/Cartesian.js'
export function getPinEndPositionHugBorderNoRotationSFCT(t, e, n, s, o, i, c) {
  c.success = pinPointUnrotatedSFCT(t, e, n, o, s, i, c)
}
export function getPinEndPositionMiddleNoRotationSFCT(t, e, n, s, o, i, c) {
  getMiddleOfBoundsNoRotation(n, s, o, i, c)
  c.success = true
}
export function pinPointMiddleOfBoundsOnEdgeUnrotatedSFCT(t, e, n, s, o, i, c) {
  const r = n + o
  const u = s + i
  const d = n + o / 2
  const a = s + i / 2
  segmentIntersectionSFCT(n, s, r, s, t, e, d, a, c)
  if (c.intersects) {
    c.success = true
    return
  }
  segmentIntersectionSFCT(n, u, r, u, t, e, d, a, c)
  if (c.intersects) {
    c.success = true
    return
  }
  segmentIntersectionSFCT(n, s, n, u, t, e, d, a, c)
  if (c.intersects) {
    c.success = true
    return
  }
  segmentIntersectionSFCT(r, s, r, u, t, e, d, a, c)
  if (c.intersects) {
    c.success = true
    return
  }
  c.success = false
}
function getMiddleOfBoundsNoRotation(t, e, n, s, o) {
  o.x = t + n / 2
  o.y = e + s / 2
}
export function pinPointUnrotatedSFCT(t, e, n, s, o, i, c) {
  const r = n + s / 2
  const u = o + i / 2
  const d = squaredDistance2D(t, e, r, o)
  const a = squaredDistance2D(t, e, r, o + i)
  const f = squaredDistance2D(t, e, n, u)
  const l = squaredDistance2D(t, e, n + s, u)
  const g = t < n
  const C = t > n + s
  const D = e < o
  const F = e > o + i
  const S = (!g && !C) || (!D && !F)
  let T, p
  if (D && ((g && d <= f) || S || (C && d <= l))) {
    T = r
    p = o
  } else if (F && ((g && a <= f) || S || (C && a <= l))) {
    T = r
    p = o + i
  } else if (g && ((D && f <= d) || S || (F && f <= a))) {
    T = n
    p = u
  } else if (C && ((D && l <= d) || S || (F && l <= a))) {
    T = n + s
    p = u
  } else return false
  c.x = T
  c.y = p
  return true
}
