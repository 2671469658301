import { LLHPoint } from '../shape/LLHPoint.js'
import { ProjectionType } from './ProjectionType.js'
import { BaseProjection } from './BaseProjection.js'
export class Conic extends BaseProjection {
  constructor() {
    super()
    this._firstParallel = 0
    this._secondParallel = 0
    this._origin = new LLHPoint()
  }
  setOriginLat(e) {
    this._origin.y = e
    this.calculateCachedValues()
  }
  setOriginLon(e) {
    this._origin.x = e
    this.calculateCachedValues()
  }
  setFirstParallel(e) {
    this._firstParallel = e
    this.calculateCachedValues()
  }
  setSecondParallel(e) {
    this._secondParallel = e
    this.calculateCachedValues()
  }
  getOrigin() {
    return this._origin
  }
  getOriginLat() {
    return this._origin.y
  }
  getOriginLon() {
    return this._origin.x
  }
  getFirstParallel() {
    return this._firstParallel
  }
  getSecondParallel() {
    return this._secondParallel
  }
  isContinuous() {
    return true
  }
  equals(e) {
    if (!super.equals(e)) return false
    const t = e
    return (
      this.getOriginLon() == t.getOriginLon() &&
      this.getOriginLat() == t.getOriginLat() &&
      this.getFirstParallel() == t.getFirstParallel() &&
      this.getSecondParallel() == t.getSecondParallel()
    )
  }
  get TYPE() {
    return ProjectionType.CONIC
  }
}
