import { isIE } from '../../util/Browser.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { ModifierType } from './ModifierType.js'
import { isNumber } from '../../util/Lang.js'
const EVENT_TYPE = 'GESTURE'
export class GestureEventImpl {
  constructor(t, i, e) {
    this._map = t
    this._inputType = e
    this._type = i
    this._modifier = ModifierType.NO_MOD
  }
  copy() {
    const t = new GestureEventImpl(this.map, this.type, this.inputType)
    for (const i in this) if (this.hasOwnProperty(i)) t[i] = this[i]
    return t
  }
  get map() {
    return this._map
  }
  get eventType() {
    return EVENT_TYPE
  }
  isRight() {
    return 2 === this._domEvent.button
  }
  isModified() {
    return this._modifier !== ModifierType.NO_MOD
  }
  get inputType() {
    return this._inputType
  }
  get type() {
    return this._type
  }
  get modifier() {
    return this._modifier
  }
  set modifier(t) {
    this._modifier = t
  }
  get domEvent() {
    return this._domEvent
  }
  set domEvent(t) {
    this._domEvent = t
  }
  get viewPoint() {
    return createPoint(null, this.viewPosition)
  }
  get viewPosition() {
    const t = this._map._nodePosition
    const i = this.clientPosition
    return [i[0] - t.left, i[1] - t.top]
  }
  get location() {
    return this._location
  }
  set location(t) {
    this._location = t
  }
  get pagePosition() {
    if (isNumber(this.location.pageX) && !isIE())
      return [this.location.pageX, this.location.pageY]
    const t = window.document
    const i = t.parentWindow || t.defaultView
    const e = this.locationClientX
      ? this.locationClientX
      : this.location.clientX
    const o = this.locationClientY
      ? this.locationClientY
      : this.location.clientY
    const n = e + (i.pageXOffset || 0)
    const s = o + (i.pageYOffset || 0)
    this._locationPageX = n
    this._locationPageY = s
    return [n, s]
  }
  get clientPosition() {
    if (isNumber(this.location.clientX))
      return [this.location.clientX, this.location.clientY]
    const t = window.document
    const i = t.parentWindow || t.defaultView
    const e = this._locationPageX ? this._locationPageX : this.location.pageX
    const o = this._locationPageY ? this._locationPageY : this.location.pageY
    const n = e - (i.pageXOffset || 0)
    const s = o - (i.pageYOffset || 0)
    this.locationClientX = n
    this.locationClientY = s
    return [n, s]
  }
}
