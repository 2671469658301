import { isArray, isString } from '../../util/Lang.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
export class URLPatternUtil {
  constructor(s) {
    this._baseURL = s.baseURL
    this._subdomains = isArray(s.subdomains) ? s.subdomains : []
    this._domainShardIdx = 0
    this.sanityCheck()
  }
  replaceURLPatterns(s, t, i) {
    const r = 'undefined' !== typeof i ? i : this._baseURL
    if (!r) return null
    let e = r.replace(/{x}/g, s.x.toString())
    e = e.replace(/{y}/g, s.y.toString())
    const a = t.getTileRowCount(s.level)
    if (null !== a) e = e.replace(/{-y}/g, String(a - s.y - 1))
    e = e.replace(/{z}/g, s.level.toString())
    e = this.replaceSubdomain(e)
    return e
  }
  get subdomains() {
    return this._subdomains.slice()
  }
  set subdomains(s) {
    if (isArray(s)) {
      const t = this._subdomains
      this._subdomains = s.slice()
      try {
        this.sanityCheck()
      } catch (s) {
        this._subdomains = t
        const i = s instanceof Error ? s.message : '' + s
        throw new ProgrammingError(i)
      }
    }
  }
  get baseURL() {
    return this._baseURL
  }
  set baseURL(s) {
    const t = this._baseURL
    try {
      this._baseURL = s
      this.sanityCheck()
    } catch (s) {
      this._baseURL = t
      const i = s instanceof Error ? s.message : 'invalid baseURL'
      throw new ProgrammingError(i)
    }
  }
  sanityCheck() {
    if (!isString(this._baseURL))
      throw new ProgrammingError('A baseURL must be specified')
    if (this._baseURL.match(/{s}/) && 0 === this._subdomains.length)
      throw new ProgrammingError(
        'if the baseURL contains the {s} subdomain hook, subdomains cannot be empty.'
      )
  }
  replaceSubdomain(s) {
    let t
    let i = s
    if (this._subdomains.length > 0) {
      t = this._subdomains[this._domainShardIdx]
      i = i.replace(/{s}/g, t)
      this._domainShardIdx = ++this._domainShardIdx % this._subdomains.length
    }
    return i
  }
}
