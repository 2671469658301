import { HandleEventResult } from './HandleEventResult.js'
import { SelectionType } from '../SelectionType.js'
import { ModifierType } from '../input/ModifierType.js'
import { GestureEventType } from '../input/GestureEventType.js'
import { isFunction } from '../../util/Lang.js'
import { PickController } from './PickController.js'
export class SelectController extends PickController {
  constructor() {
    super()
    this._displayedBalloonFor = null
  }
  getCandidates(e, t, o) {
    if (!this.map || !e) return []
    const l = this.map._pickClosestObject(e.x, e.y, t, true, o)
    return l ? [l] : []
  }
  handleCandidates(e, t) {
    if (!this.map) return HandleEventResult.EVENT_IGNORED
    let o = false
    if (t.length > 0) {
      const l = t[0]
      o = l.layer.onClick(l.objects[0])
      if (!o) o = this.map.onClick(e)
      if (!o) {
        const t = this.getSelectionType(e)
        this.selectAndShowBalloon(e, l, t)
      }
    } else {
      o = this.map.onClick(e)
      if (!o) {
        this.map.clearSelection()
        this.map.hideBalloon()
        o = true
      }
    }
    return o ? HandleEventResult.EVENT_HANDLED : HandleEventResult.EVENT_IGNORED
  }
  isPickEvent(e) {
    return e.type === GestureEventType.SINGLE_CLICK_CONFIRMED
  }
  isShowBalloon(e, t) {
    return true
  }
  getSelectionType(e) {
    if (e.modifier === ModifierType.SHIFT) return SelectionType.TOGGLE
    return SelectionType.NEW
  }
  selectAndShowBalloon(e, t, o) {
    if (!this.map) return
    this.map.selectObjects([t], { editSelection: o })
    const l = this.map.isSelected(t.layer, t.objects[0])
    const i =
      this._displayedBalloonFor &&
      this._displayedBalloonFor.object.id !== t.objects[0].id &&
      this._displayedBalloonFor.layer !== t.layer
    if (l && i) {
      this.map.hideBalloon()
      this._displayedBalloonFor = null
    }
    if (t.objects.length >= 0 && l && this.isShowBalloon(e, t))
      this.showBalloonFor(t)
  }
  showBalloonFor(e) {
    if (this.map && isFunction(e.layer.balloonContentProvider)) {
      this._displayedBalloonFor = { object: e.objects[0], layer: e.layer }
      this.map.showBalloon(this._displayedBalloonFor)
    }
  }
}
