export class BoundsDiscretizationFunc {
  constructor(t, i, s, e) {
    this._divX = t
    this._x = e.x
    this._y = e.y
    this._z = e.z
    this._deltaX = 0 == this._divX ? 0 : e.width / this._divX
    this._deltaY = 0 == i ? 0 : e.height / i
    this._deltaZ = 0 == s ? 0 : e.depth / s
    this._index = 0
    this._xyCount = (this._divX + 1) * (i + 1)
    this._totalCount = (this._divX + 1) * (i + 1) * (s + 1)
  }
  hasMoreSamplePoints() {
    return this._index < this._totalCount
  }
  nextSamplePoint(t) {
    const i = Math.floor(this._index / this._xyCount)
    const s = this._index % this._xyCount
    const e = s % (this._divX + 1)
    const h = Math.floor(s / (this._divX + 1))
    t.x = this._x + e * this._deltaX
    t.y = this._y + h * this._deltaY
    t.z = this._z + i * this._deltaZ
    this._index++
    return 0 == e
  }
}
export class BoundsEdgeDiscretization {
  constructor(t, i, s, e) {
    this.widthDiv = t
    this.heightDiv = i
    this.depthDiv = s
    this.width = e.width
    this.height = e.height
    this.depth = e.depth
    this.x = e.x
    this.y = e.y
    this.z = e.z
    this.deltaX = 0 === t ? 0 : this.width / t
    this.deltaY = 0 === i ? 0 : this.height / i
    this.deltaZ = 0 === s ? 0 : this.depth / s
    this.index = 0
    this.edgeCountX = 1
    this.edgeCountY = 1
    this.edgeCountZ = 1
    if (this.widthDiv > 0) {
      this.edgeCountY *= 2
      this.edgeCountZ *= 2
    }
    if (this.heightDiv > 0) {
      this.edgeCountX *= 2
      this.edgeCountZ *= 2
    }
    if (this.depthDiv > 0) {
      this.edgeCountX *= 2
      this.edgeCountY *= 2
    }
    this.pointCountX = (this.widthDiv + 1) * this.edgeCountX
    this.pointCountY = Math.max(0, this.heightDiv - 1) * this.edgeCountY
    this.pointCountZ = Math.max(0, this.depthDiv - 1) * this.edgeCountZ
    this.xOffset = 0
    this.yOffset = 1
    this.zOffset = 1
    this.pointCount = this.pointCountX + this.pointCountY + this.pointCountZ
  }
  hasMoreSamplePoints() {
    return this.index < this.pointCount
  }
  nextSamplePoint(t) {
    let i, s, e, h, n, o, a, l, r, d, u
    if (this.index < this.pointCountX) {
      h = this.index
      a = this.pointCountX / this.edgeCountX
      l = Math.floor(h / a)
      r = l % 2
      d = 4 === this.edgeCountX ? Math.floor(l / 2) : r
      i = (h % a) + this.xOffset
      s = this.heightDiv > 0 ? r * this.heightDiv : 0
      e = this.depthDiv > 0 ? d * this.depthDiv : 0
      u = i === this.xOffset
    } else if (this.index < this.pointCountX + this.pointCountY) {
      n = this.index - this.pointCountX
      a = this.pointCountY / this.edgeCountY
      l = Math.floor(n / a)
      r = l % 2
      d = 4 === this.edgeCountY ? Math.floor(l / 2) : r
      i = this.widthDiv > 0 ? r * this.widthDiv : 0
      s = (n % a) + this.yOffset
      e = this.depthDiv > 0 ? d * this.depthDiv : 0
      u = s === this.yOffset
    } else {
      o = this.index - this.pointCountX - this.pointCountY
      a = this.pointCountZ / this.edgeCountZ
      l = Math.floor(o / a)
      r = l % 2
      d = 4 == this.edgeCountZ ? Math.floor(l / 2) : r
      i = this.widthDiv > 0 ? r * this.widthDiv : 0
      s = this.heightDiv > 0 ? d * this.heightDiv : 0
      e = (o % a) + this.zOffset
      u = e === this.zOffset
    }
    t.x = this.x + i * this.deltaX
    t.y = this.y + s * this.deltaY
    t.z = this.z + e * this.deltaZ
    this.index++
    return u
  }
}
export class BoundsMiddleDiscretization {
  constructor(t, i, s, e) {
    this.divX = t
    this.divY = i
    this.divZ = s
    this.width = e.width
    this.height = e.height
    this.depth = e.depth
    this.deltaX = 0 === t ? 0 : this.width / t
    this.deltaY = 0 === i ? 0 : this.height / i
    this.deltaZ = 0 === s ? 0 : this.depth / s
    this.x = e.x + this.deltaX
    this.y = e.y + this.deltaY
    this.z = e.z
    this.centerX = e.x + this.width / 2
    this.centerY = e.y + this.height / 2
    this.index = 0
    this.middlePoints = (t - 1 + (i - 1)) * (s + 1)
  }
  hasMoreSamplePoints() {
    return this.index < this.middlePoints
  }
  nextSamplePoint(t) {
    const i = Math.floor(this.index / (this.divZ + 1))
    const s = this.index % (this.divZ + 1)
    let e = false
    const h = this.z + s * this.deltaZ
    if (i < this.divX - 1) {
      if (0 === i) e = true
      t.x = this.x + i * this.deltaX
      t.y = this.centerY
      t.z = h
    } else {
      if (i - this.divX + 1 === 0) e = true
      t.x = this.centerX
      t.y = this.y + (i - this.divX + 1) * this.deltaY
      t.z = h
    }
    this.index++
    return e
  }
}
const MAX_REFINEMENT_COUNT = 6,
  MIN_INTERVAL_MAX_REFINEMENT_COUNT = 2,
  MAX_INTERVAL_MAX_REFINEMENT_COUNT = 10,
  MAX_INTERVAL_MIN_REFINEMENT_COUNT = 2,
  MAX_REFINEMENT_ADAPTIVE_STEP = 2,
  MIN_REFINEMENT_ADAPTIVE_STEP = 4,
  MIN_REFINEMENT_ADAPTIVE_STEP_INV = 0.25
function calculateOriginalSmallDelta(t, i) {
  let s,
    e = 0.05 * (i - t)
  for (s = 0; s < MAX_INTERVAL_MIN_REFINEMENT_COUNT; s++)
    e *= MIN_REFINEMENT_ADAPTIVE_STEP_INV
  return e
}
function calculateFindIntervalMaxAttempts(t, i, s) {
  let e = 0
  let h = t ? i : s
  const n = t ? s : i
  while (
    (h < 10 * n || e < MIN_INTERVAL_MAX_REFINEMENT_COUNT) &&
    e < MAX_INTERVAL_MAX_REFINEMENT_COUNT
  ) {
    h *= MAX_REFINEMENT_ADAPTIVE_STEP
    e++
  }
  return e
}
export class DestinationBoundsExtensionDiscretisation {
  constructor(t, i, s, e, h, n, o) {
    this.refinementsLeft = MAX_REFINEMENT_COUNT
    this.edge = 0
    this.largeDelta = 0
    this.intervalMin = null
    this.intervalMax = null
    this.newFeature = false
    this.transformation = i
    this.sourceBounds = s
    this.tempDestinationPosition = e
    this.tempSourcePosition = h
    this.horizontal = o
    this.sourceReference3D = n
    const a = t.width
    const l = t.height
    const r = t.depth
    this.x = t.x + a / 2
    this.y = t.y + l / 2
    this.z = t.z + r / 2
    if (this.horizontal) {
      this.min = t.x
      this.max = this.min + a
    } else {
      this.min = t.y
      this.max = this.min + l
    }
    this.originalSmallDelta = calculateOriginalSmallDelta(this.min, this.max)
    this.smallDelta = this.originalSmallDelta
    this.originalFindintervalMaxAttemptsLeft = calculateFindIntervalMaxAttempts(
      this.horizontal,
      t.width,
      t.height
    )
    this.findIntervalMaxAttempsLeft = this.originalFindintervalMaxAttemptsLeft
  }
  destinationPositionInsideSourceBounds(t, i, s) {
    try {
      this.tempDestinationPosition.x = t
      this.tempDestinationPosition.y = i
      this.tempDestinationPosition.z = s
      this.transformation._inverse(
        this.tempDestinationPosition,
        this.tempSourcePosition
      )
      const e = undefined
      if (
        this.sourceReference3D
          ? this.sourceBounds.contains3DPoint(this.tempSourcePosition)
          : this.sourceBounds.contains2DPoint(this.tempSourcePosition)
      )
        return true
    } catch (t) {}
    return false
  }
  advanceEdge() {
    this.intervalMin = null
    this.intervalMax = null
    this.edge++
    this.refinementsLeft = MAX_REFINEMENT_COUNT
    this.findIntervalMaxAttempsLeft = this.originalFindintervalMaxAttemptsLeft
    this.newFeature = true
    this.smallDelta = this.originalSmallDelta
  }
  nextSamplePointImpl() {
    let t, i, s, e, h, n, o
    if (this.smallDelta < 1e-12 || this.edge > 1) return false
    if (null === this.intervalMin) {
      s = this.smallDelta
      e = this.x
      h = this.y
      n = this.z
      t = 0 === this.edge ? this.min - s : this.max + s
      if (this.horizontal) e = t
      else h = t
      i = this.destinationPositionInsideSourceBounds(e, h, n)
      if (i) {
        for (o = 0; o < MAX_INTERVAL_MIN_REFINEMENT_COUNT; o++) {
          s *= MIN_REFINEMENT_ADAPTIVE_STEP
          t = 0 === this.edge ? this.min - s : this.max + s
          if (this.horizontal) e = t
          else h = t
          i = this.destinationPositionInsideSourceBounds(e, h, n)
          if (!i) {
            s *= MIN_REFINEMENT_ADAPTIVE_STEP_INV
            break
          }
        }
        t = 0 === this.edge ? this.min - s : this.max + s
        if (this.horizontal) this.x = t
        else this.y = t
        this.largeDelta = 2 * s
        this.intervalMin = t
        return true
      } else {
        this.advanceEdge()
        return this.nextSamplePointImpl()
      }
    } else if (null === this.intervalMax) {
      if (0 === this.findIntervalMaxAttempsLeft) {
        this.advanceEdge()
        return this.nextSamplePointImpl()
      }
      this.findIntervalMaxAttempsLeft--
      t =
        0 === this.edge
          ? this.intervalMin - this.largeDelta
          : this.intervalMin + this.largeDelta
      if (this.horizontal) this.x = t
      else this.y = t
      i = this.destinationPositionInsideSourceBounds(this.x, this.y, this.z)
      if (i) {
        this.intervalMin = t
        this.largeDelta *= MAX_REFINEMENT_ADAPTIVE_STEP
        return true
      } else {
        this.intervalMax = t
        return this.nextSamplePointImpl()
      }
    } else if (this.refinementsLeft > 0) {
      this.refinementsLeft--
      t = this.intervalMin + (this.intervalMax - this.intervalMin) / 2
      if (this.horizontal) this.x = t
      else this.y = t
      i = this.destinationPositionInsideSourceBounds(this.x, this.y, this.z)
      if (i) {
        this.intervalMin = t
        return true
      } else {
        this.intervalMax = t
        return this.nextSamplePointImpl()
      }
    } else {
      this.advanceEdge()
      return this.nextSamplePointImpl()
    }
  }
  hasMoreSamplePoints() {
    return this.nextSamplePointImpl()
  }
  nextSamplePoint(t) {
    t.x = this.x
    t.y = this.y
    t.z = this.z
    return t
  }
}
