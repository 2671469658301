import { createPoint } from '../shape/ShapeFactory.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { GeocentricReference } from '../reference/GeocentricReference.js'
import { GeodeticReference } from '../reference/GeodeticReference.js'
import { BoundsCalculationUtil } from './BoundsCalculationUtil.js'
import { Transformation } from './Transformation.js'
function isCompatible(e, t) {
  return e instanceof GeodeticReference && t instanceof GeocentricReference
}
export class GeodeticGeocentricTransformation extends Transformation {
  constructor(e, t, o) {
    super(e, t)
    if (!isCompatible(e, t))
      throw new ProgrammingError(
        'GeodeticGeocentricTransformation - references not compatible.'
      )
    this._srcGeodeticReference = e
    this._dstGeocentricReference = t
    this._srcGeodeticDatum = this._srcGeodeticReference.geodeticDatum
    this._dstGeodeticDatum = this._dstGeocentricReference.geodeticDatum
    this._equalHorizontalDatums = this._srcGeodeticDatum.equals2D(
      this._dstGeodeticDatum
    )
    this._tmpXYZPoint1 = createPoint(t, [0, 0, 0])
    this._tmpXYZPoint2 = createPoint(t, [0, 0, 0])
    this._bboxCalculationUtil = new BoundsCalculationUtil({
      modelReference: e,
      worldReference: t,
      transformation: this,
      TransformationFactory: o,
    })
  }
  _forward(e, t) {
    this._srcGeodeticDatum.geod2geocSFCT(e, t)
    if (!this._equalHorizontalDatums) {
      this._srcGeodeticDatum.datum2refGeocSFCT(t, this._tmpXYZPoint2)
      this._dstGeodeticDatum.ref2datumGeocSFCT(this._tmpXYZPoint2, t)
    }
    const o = 1 / this._dstGeocentricReference.unitOfMeasure
    t.x = t.x * o
    t.y = t.y * o
    t.z = t.z * o
    return t
  }
  _inverse(e, t) {
    const o = this._dstGeocentricReference.unitOfMeasure
    this._tmpXYZPoint1.x = e.x * o
    this._tmpXYZPoint1.y = e.y * o
    this._tmpXYZPoint1.z = e.z * o
    if (!this._equalHorizontalDatums) {
      this._dstGeodeticDatum.datum2refGeocSFCT(
        this._tmpXYZPoint1,
        this._tmpXYZPoint2
      )
      this._srcGeodeticDatum.ref2datumGeocSFCT(
        this._tmpXYZPoint2,
        this._tmpXYZPoint1
      )
    }
    this._srcGeodeticDatum.geoc2geodSFCT(this._tmpXYZPoint1, t)
    return t
  }
  _forwardBoundsCoords(e, t) {
    this._bboxCalculationUtil.forwardBounds2dSFCT(e, t)
  }
  _inverseBoundsCoords(e, t) {
    this._bboxCalculationUtil.inverseBounds2dSFCT(e, t)
  }
}
