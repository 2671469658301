import { ShapeList } from '../../shape/ShapeList.js'
import { CGSetOperation } from './CGSetOperation.js'
import { ConstructiveGeometryShapeListShapeList } from './ConstructiveGeometryShapeListShapeList.js'
import { ConstructiveGeometryPointListPointList } from './ConstructiveGeometryPointListPointList.js'
import { ConstructiveGeometryOperator } from './ConstructiveGeometryOperator.js'
import { CGUtil } from './CGUtil.js'
function CompositeConstructiveGeometryOperator(t) {
  this._topologyUtil = t
  this._operators = [
    new ConstructiveGeometryPointListPointList(t),
    new ConstructiveGeometryShapeListShapeList(t, this),
  ]
}
CompositeConstructiveGeometryOperator.prototype = Object.create(
  ConstructiveGeometryOperator.prototype
)
CompositeConstructiveGeometryOperator.prototype.constructor =
  CompositeConstructiveGeometryOperator
CompositeConstructiveGeometryOperator.prototype.operate = function (t, e, r) {
  var o = this._testBounds(t, e, r)
  if (null !== o) return CGUtil.deepCopy(o)
  for (var s = 0; s < this._operators.length; s++)
    if (this._operators[s].supportsShapes([t, e]))
      return this._operators[s].operate(t, e, r)
  return null
}
CompositeConstructiveGeometryOperator.prototype.operateOnMultipleShapes =
  function (t, e) {
    var r, o
    if (e.length <= 3 || t != CGSetOperation.UNION) {
      o = e[0]
      for (r = 1; r < e.length; r++) o = this.operate(o, e[r], t)
      return o
    } else {
      var s = [].concat(e)
      var n = []
      var i, p
      while (s.length > 1) {
        for (r = 0; r < s.length; r += 2) {
          i = s[r]
          if (r + 1 < s.length) {
            p = s[r + 1]
            o = this.operate(i, p, t)
            n.push(o)
          } else n.push(i)
        }
        s = n
        n = []
      }
      return s[0]
    }
  }
CompositeConstructiveGeometryOperator.prototype._testBounds = function (
  t,
  e,
  r
) {
  if (this._hasEmptyBounds(t) && this._hasEmptyBounds(e)) return new ShapeList()
  else if (this._hasEmptyBounds(t))
    switch (r) {
      case CGSetOperation.INTERSECTION:
        return new ShapeList()
      case CGSetOperation.UNION:
        return e
      case CGSetOperation.DIFFERENCE:
        return new ShapeList()
      case CGSetOperation.SYMMETRIC_DIFFERENCE:
        return e
      default:
        return null
    }
  else if (this._hasEmptyBounds(e))
    switch (r) {
      case CGSetOperation.INTERSECTION:
        return new ShapeList()
      case CGSetOperation.UNION:
        return t
      case CGSetOperation.DIFFERENCE:
        return t
      case CGSetOperation.SYMMETRIC_DIFFERENCE:
        return t
      default:
        return null
    }
  var o = this._operators[0].topologyUtil
  var s = o.getNewEditableBoundsFromBounds(t.bounds)
  var n = o.getNewEditableBoundsFromBounds(e.bounds)
  o.growBounds(o.toleranceEqualPoints, s)
  o.growBounds(o.toleranceEqualPoints, n)
  if (!s.interacts2D(n))
    switch (r) {
      case CGSetOperation.INTERSECTION:
        return new ShapeList()
      case CGSetOperation.UNION:
        return new ShapeList(t.reference, [t, e])
      case CGSetOperation.DIFFERENCE:
        return t
      case CGSetOperation.SYMMETRIC_DIFFERENCE:
        return new ShapeList(t.reference, [t, e])
      default:
        return null
    }
  return null
}
CompositeConstructiveGeometryOperator.prototype._hasEmptyBounds = function (t) {
  return null === t.bounds
}
CompositeConstructiveGeometryOperator.prototype.supportsShapes = function (t) {
  for (var e = 0; e < this._operators.length; e++)
    if (this._operators[e].supportsShapes(t)) return true
  return false
}
export { CompositeConstructiveGeometryOperator }
