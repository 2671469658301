import { Lang } from '../../util/Lang.js'
import { XMLSchema } from '../../util/XMLSchema.js'
import { WMTSCapabilitiesSchema } from './WMTSCapabilitiesSchema.js'
import { CapabilitiesParserUtil } from './common/CapabilitiesParserUtil.js'
import { WMTSCapabilitiesLayer } from './WMTSCapabilitiesLayer.js'
import { WMTSCapabilitiesTileMatrixSet } from './WMTSCapabilitiesTileMatrixSet.js'
function WMTSCapabilitiesParser(e) {
  var t
  var i = new XMLSchema(WMTSCapabilitiesSchema).parse(e)
  this._raw = i
  this._rawRoot = i['Capabilities']
}
WMTSCapabilitiesParser.prototype = Object.create(Object.prototype)
WMTSCapabilitiesParser.prototype.constructor = WMTSCapabilitiesParser
WMTSCapabilitiesParser.prototype.getRawCapabilities = function () {
  return this._raw
}
WMTSCapabilitiesParser.prototype.getService = function () {
  var e = this._rawRoot.ServiceIdentification
  var t = this._rawRoot.ServiceMetadataURL
  var i = this._rawRoot.ServiceProvider
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    title: e.Title,
    abstract: e.Abstract,
    keywords: e && this._getKeywords(e),
    url: t && t[0].href,
    contactInformation: this._getContactInformation(i),
    fees: 'none' !== e.Fees ? e.Fees : null,
  })
}
WMTSCapabilitiesParser.prototype.getRequests = function () {
  var e = this._rawRoot.OperationsMetadata
  return CapabilitiesParserUtil.freeze({
    GetCapabilities: this._getRequestInformationByName(
      e.Operation,
      'GetCapabilities'
    ),
    GetTile: this._getRequestInformationByName(e.Operation, 'GetTile'),
    GetFeatureInfo: this._getRequestInformationByName(
      e.Operation,
      'GetFeatureInfo'
    ),
  })
}
WMTSCapabilitiesParser.prototype._getRequestInformationByName = function (
  e,
  t
) {
  var i = e.filter(function (e) {
    return e.name === t
  })[0]
  if (!Lang.isDefined(i)) return []
  var r = []
  var a = i.DCP && i.DCP[0]
  if (a.HTTP && a.HTTP.Get)
    a.HTTP.Get.forEach(function (e) {
      if (Lang.isDefined(e.Constraint)) {
        var t = e.Constraint.filter(function (e) {
          return 'GetEncoding' === e.name
        })[0]
        if (Lang.isDefined(t))
          r.push({
            requestMethod: 'GET',
            encodings: t.AllowedValues.Value,
            url: e.href,
          })
      }
    })
  if (a.HTTP && a.HTTP.Post)
    a.HTTP.Post.forEach(function (e) {
      var t = e.Constraint.filter(function (e) {
        return 'GetEncoding' === e.name
      })[0]
      var i = e.Constraint.filter(function (e) {
        return 'PostEncoding' === e.name
      })[0]
      if (Lang.isDefined(t))
        r.push({
          requestMethod: 'POST',
          encodings: t.AllowedValues.Value,
          url: e.href,
          postEncodings: i && i.AllowedValues.Value,
        })
    })
  return r
}
WMTSCapabilitiesParser.prototype._getContactInformation = function (e) {
  if (!Lang.isDefined(e)) return null
  var t = e.ServiceContact
  var i = t.ContactInfo
  var r = i && i.Phone
  var a = i && i.Address
  var n = r && r.Voice && Object.keys(r.Voice).length > 0 && r.Voice[0]
  var s =
    a &&
    a.ElectronicMailAddress &&
    Object.keys(a.ElectronicMailAddress).length > 0 &&
    a.ElectronicMailAddress[0]
  return CapabilitiesParserUtil.freeze({
    primaryPerson: this._getPrimaryPerson(e),
    position: t.PositionName,
    address: this._getAddress(e),
    telephone: n,
    fax: r && r.Facsimile,
    email: s,
  })
}
WMTSCapabilitiesParser.prototype._getPrimaryPerson = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    person: e.ServiceContact && e.ServiceContact.IndividualName,
    organization: e.ProviderName,
  })
}
WMTSCapabilitiesParser.prototype._getAddress = function (e) {
  if (!Lang.isDefined(e)) return null
  var t = e.ServiceContact && e.ServiceContact.ContactInfo
  var i = t && t.Address
  if (!Lang.isDefined(i)) return null
  return CapabilitiesParserUtil.freeze({
    address: i.DeliveryPoint && i.DeliveryPoint[0],
    city: i.City,
    stateOrProvince: i.AdministrativeArea,
    postCode: i.PostalCode,
    country: i.Country,
  })
}
WMTSCapabilitiesParser.prototype.getUpdateSequence = function () {
  return this._rawRoot.updateSequence
}
WMTSCapabilitiesParser.prototype.getLayers = function () {
  var e = this._rawRoot.Contents.Layer
  var t = []
  var i = this._getThemes()
  e.forEach(function (e) {
    var r = this._getLayer(e, i)
    t.push(r)
  }, this)
  return t
}
WMTSCapabilitiesParser.prototype._getTileMatrixSet = function (e, t) {
  var i
  var r = (this._rawRoot.Contents.TileMatrixSet || []).filter(function (t) {
    return t.Identifier['#text'] === e
  })[0]
  return this._createTileMatrixSet(r, t)
}
WMTSCapabilitiesParser.prototype._createTileMatrixSet = function (e, t) {
  if (!Lang.isDefined(e)) return null
  return new WMTSCapabilitiesTileMatrixSet(
    CapabilitiesParserUtil.freeze({
      title: e.Title && e.Title[0]['#text'],
      abstract: e.Abstract && e.Abstract[0] && e.Abstract[0]['#text'],
      keywords: e && this._getKeywords(e),
      identifier: e.Identifier['#text'],
      wellKnownScaleSet: e.WellKnownScaleSet,
      tileMatrices: this._getTileMatrices(e.TileMatrix, t),
      bounds: this._getBounds(e.BoundingBox),
      referenceName: e.SupportedCRS,
    })
  )
}
WMTSCapabilitiesParser.prototype._getTileMatrices = function (e, t) {
  if (!Lang.isDefined(e)) return []
  return e.map(function (i) {
    var r = i.Identifier && i.Identifier['#text']
    return CapabilitiesParserUtil.freeze({
      title: i.Title && i.Title['#text'],
      abstract: i.Abstract && i.Abstract['#text'],
      keywords: i && this._getKeywords(e),
      identifier: r,
      scaleDenominator: i.ScaleDenominator,
      topLeftCorner: i.TopLeftCorner,
      tileWidth: i.TileWidth,
      tileHeight: i.TileHeight,
      matrixWidth: i.MatrixWidth,
      matrixHeight: i.MatrixHeight,
      limits: this._getTileMatrixLimits(t, r),
    })
  }, this)
}
WMTSCapabilitiesParser.prototype._getTileMatrixLimits = function (e, t) {
  var i = e.filter(function (e) {
    return e.TileMatrix === t
  })[0]
  if (!Lang.isDefined(i)) return null
  return {
    minTileRow: i.MinTileRow,
    maxTileRow: i.MaxTileRow,
    minTileCol: i.MinTileCol,
    maxTileCol: i.MaxTileCol,
  }
}
WMTSCapabilitiesParser.prototype._getLayer = function (e, t) {
  if (!Lang.isDefined(e)) return null
  var i = e.Identifier['#text']
  return new WMTSCapabilitiesLayer(
    CapabilitiesParserUtil.freeze({
      title: e.Title && e.Title[0]['#text'],
      abstract: e.Abstract && e.Abstract[0] && e.Abstract[0]['#text'],
      keywords: e && this._getKeywords(e),
      formats: e.Format || [],
      identifier: i,
      styles: this._getStyles(e.Style),
      infoFormats: e.InfoFormat || [],
      dimensions: this._getDimensions(e.Dimension),
      tileMatrixSets: this._getLayerTileMatrixSets(e.TileMatrixSetLink),
      resourceUrls: (e.ResourceURL || []).map(this._parseResourceUrl, this),
      themes: this._getLayerThemes(t, i),
      wgs84Bounds: this._getBoundsList(e.WGS84BoundingBox, 'CRS:84'),
      bounds: this._getBoundsList(e.BoundingBox),
    })
  )
}
WMTSCapabilitiesParser.prototype._parseResourceUrl = function (e) {
  return {
    format: e.format,
    resourceType: e.resourceType,
    template: e.template,
  }
}
WMTSCapabilitiesParser.prototype._getBoundsList = function (e, t) {
  if (!Lang.isDefined(e)) return []
  return e.map(function (e) {
    return this._getBounds(e, t)
  }, this)
}
WMTSCapabilitiesParser.prototype._getBounds = function (e, t) {
  if (!Lang.isDefined(e)) return null
  var i = CapabilitiesParserUtil.parseCornerValues(e.LowerCorner)
  var r = CapabilitiesParserUtil.parseCornerValues(e.UpperCorner)
  var a = i[0]
  var n = i[1]
  var s = r[0]
  var o = r[1]
  return CapabilitiesParserUtil.freeze({
    reference: Lang.isDefined(t) ? t : e.crs,
    x: a,
    y: n,
    width: s - a,
    height: o - n,
  })
}
WMTSCapabilitiesParser.prototype._getLayerTileMatrixSets = function (e) {
  if (!Lang.isDefined(e)) return []
  return e.map(function (e) {
    var t =
      (e.TileMatrixSetLimits && e.TileMatrixSetLimits.TileMatrixLimits) || []
    return this._getTileMatrixSet(e.TileMatrixSet, t)
  }, this)
}
WMTSCapabilitiesParser.prototype._getStyles = function (e) {
  if (!Lang.isDefined(e)) return []
  return e.map(function (e) {
    return CapabilitiesParserUtil.freeze({
      title: e.Title && e.Title[0]['#text'],
      abstract: e.Abstract && e.Abstract[0] && e.Abstract[0]['#text'],
      keywords: e && this._getKeywords(e),
      identifier: e.Identifier['#text'],
      isDefault: e.isDefault,
      legends: (e.LegendURL || []).map(this._getLegend, this),
    })
  }, this)
}
WMTSCapabilitiesParser.prototype._getLegend = function (e) {
  return {
    url: e.href,
    format: e.format,
    minScaleDenominator: e.minScaleDenominator,
    maxScaleDenominator: e.maxScaleDenominator,
    width: e.width,
    height: e.height,
  }
}
WMTSCapabilitiesParser.prototype._getDimensions = function (e) {
  if (!Lang.isDefined(e)) return []
  return e.map(function (e) {
    return CapabilitiesParserUtil.freeze({
      title: e.Title && e.Title[0]['#text'],
      abstract: e.Abstract && e.Abstract[0] && e.Abstract[0]['#text'],
      keywords: this._getKeywords(e),
      identifier: e.Identifier && e.Identifier['#text'],
      unitsOfMeasure: e.UOM && e.UOM['#text'],
      default: e.Default,
      unitSymbol: e.UnitSymbol,
      current: e.Current,
      values: e.Value || [],
    })
  }, this)
}
WMTSCapabilitiesParser.prototype._getLayerThemes = function (e, t) {
  var i = []
  e.filter(function (e) {
    return Array.isArray(e.layerIdentifier) && e.layerIdentifier.indexOf(t) >= 0
  }).forEach((e) => {
    var t = this._getThemeWithParents(e)
    i.push.apply(i, t)
  })
  return i
}
WMTSCapabilitiesParser.prototype._getThemeWithParents = function (e) {
  var t = []
  while (null !== e) {
    t.push(e)
    e = e.parent
  }
  return t
}
WMTSCapabilitiesParser.prototype._getThemes = function () {
  var e = this._rawRoot.Themes && this._rawRoot.Themes[0]
  if (!Lang.isDefined(e)) return []
  var t = []
  this._collectThemesSFCT(t, e, null)
  return t
}
WMTSCapabilitiesParser.prototype._collectThemesSFCT = function (e, t, i) {
  var r = {
    parent: i,
    title: t.Title && t.Title['#text'],
    abstract: t.Abstract && t.Abstract['#text'],
    keywords: t && this._getKeywords(t),
    identifier: t.Identifier && t.Identifier['#text'],
    layerIdentifier: t.LayerRef,
  }
  r.children = (t.Theme || []).map(function (t) {
    return this._collectThemesSFCT(e, t, r)
  }, this)
  e.push(r)
  return CapabilitiesParserUtil.freeze(r)
}
WMTSCapabilitiesParser.prototype._getKeywords = function (e) {
  var t = e.Keywords
  if (!Lang.isDefined(t)) return []
  return t.map(function (e) {
    var t = e.Type && e.Type['#text']
    return CapabilitiesParserUtil.freeze({ keywords: e.Keyword || [], type: t })
  }, this)
}
export { WMTSCapabilitiesParser }
