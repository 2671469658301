import { GridReference } from '../../../reference/GridReference.js'
import { GeocentricReference } from '../../../reference/GeocentricReference.js'
import { CartesianReference } from '../../../reference/CartesianReference.js'
import { LineType } from '../../../geodesy/LineType.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { NotImplementedError } from '../../../error/NotImplementedError.js'
import { LinkedModelWorldPoint } from './LinkedModelWorldPoint.js'
import { GeneralPath } from './GeneralPath.js'
import * as GeneralPathUtil from '../generalpath/GeneralPathUtil.js'
const STRAIGHT_LINE_DISTANCE_THRESHOLD = 500
const MAX_ITERATION_COUNT = 9
function iterate(e, t, n, i, r) {
  let o = true
  let a = e.rightNeighbor
  let s = e
  let d = true
  while (null !== a && (s !== e || d)) {
    a.shouldSubdivideLeftEdge =
      s.shouldSubdivideOutgoingEdges() || a.shouldSubdivideOutgoingEdges()
    s = a
    a = a.rightNeighbor
    d = false
  }
  a = e.rightNeighbor
  s = e
  d = true
  let l, h
  while (null !== a && (s !== e || d)) {
    if (i || a.shouldSubdivideLeftEdge) {
      o = false
      l = getMiddlePointFraction(s, a)
      h = new LinkedModelWorldPoint(t, l, n, r)
      h.setLeftNeighbor(s)
      h.setRightNeighbor(a)
      s.setRightNeighbor(h)
      a.setLeftNeighbor(h)
    }
    s = a
    a = a.rightNeighbor
    d = false
  }
  return o
}
function reduce(e) {
  let t = e.rightNeighbor
  let n = true
  while (null !== t && (n || t.leftNeighbor !== e)) {
    const i = t.validCorner()
    const r = t.tooSmallAngle()
    if (i && r && t.leftNeighbor && t.rightNeighbor) {
      t.leftNeighbor.setRightNeighbor(t.rightNeighbor)
      t.rightNeighbor.setLeftNeighbor(t.leftNeighbor)
      if (t === e) e = t.leftNeighbor
    }
    t = t.rightNeighbor
    n = false
  }
  return e
}
export class IterativePen {
  constructor() {
    this._nextModelWorldPointIndex = -1
  }
  append(e) {
    throw new NotImplementedError(
      'IterativePen::append - not implemented error'
    )
  }
  appendLine(e, t, n, i, r, o, a, s, d) {
    return this.appendPointList(e, t, n, i, r, false, o, a, s, d)
  }
  appendPolygon(e, t, n, i, r, o, a, s) {
    return this.appendPointList(e, t, n, i, r, true, o, a, s)
  }
  appendMultiLine(e, t, n, i, r, o, a, s) {
    for (let o = 0, d = e.length; o < d; o++) {
      this.appendPointList(e[o], t, n, i, r, false, true, a, s)
      if (o < d - 1) r.breakLine()
    }
  }
  appendComplexPolygon(e, t, n, i, r, o, a) {
    const s = e.length > 1
    for (let d = 0; d < e.length; d++) {
      let l = new GeneralPath()
      this.appendPolygon(e[d], t, n, i, l, true, o, a)
      if (s) l = GeneralPathUtil.ensurePathStartsAtBoundary(l)
      GeneralPathUtil.addToSFCT(l, r)
    }
    return r
  }
  appendCircle(e, t, n, i, r, o, a) {
    const s = e.radius
    const d = 360
    const l = e.center
    const h = 0
    const p = 0
    this.appendArc(l, s, s, h, p, d, t, n, i, r, true, o, a)
  }
  appendEllipse(e, t, n, i, r, o, a) {
    const s = e.center
    const d = e.a
    const l = e.b
    const h = e.rotationAzimuth
    const p = 0
    const c = 360
    this.appendArc(s, d, l, h, p, c, t, n, i, r, true, o, a)
  }
  appendArc(e, t, n, i, r, o, a, s, d, l, h, p, c) {
    const f = this.getArcPath(e, t, n, i, r, o, s, p)
    this.appendParameterizedPath(f, a, true, l, h, c)
  }
  appendArcBand(e, t, n, i, r, o, a) {
    const s = e.center
    const d = e.minRadius
    const l = e.maxRadius
    const h = e.startAzimuth
    const p = e.sweepAngle
    const c = 0
    const f = false
    const g = false
    function u(e, t, n) {
      if (o == LineType.SHORTEST_DISTANCE) return e
      const i = s.copy()
      s.reference.geodeticDatum.ellipsoid.rhumblinePositionSFCT(s, t, n, i)
      return i
    }
    const P = u(e.minRadiusStartCorner, d, h)
    const m = u(e.minRadiusEndCorner, d, h + p)
    const N = u(e.maxRadiusStartCorner, l, h)
    const b = u(e.maxRadiusEndCorner, l, h + p)
    this.appendArc(s, l, l, c, h, p, t, n, i, r, g, o, a)
    this.appendPointList([b, m], t, n, i, r, g, f, o, a, false)
    this.appendArc(s, d, d, c, h + p, -p, t, n, i, r, g, o, a)
    this.appendPointList([P, N], t, n, i, r, g, f, o, a, false)
  }
  appendSector(e, t, n, i, r, o, a) {
    const s = e.center
    const d = e.radius
    const l = e.startAzimuth
    const h = e.sweepAngle
    const p = 0
    const c = false
    const f = false
    function g(e, t, n) {
      if (o == LineType.SHORTEST_DISTANCE) return e
      const i = s.copy()
      s.reference.geodeticDatum.ellipsoid.rhumblinePositionSFCT(s, t, n, i)
      return i
    }
    const u = g(e.radiusStartCorner, d, l)
    const P = g(e.radiusEndCorner, d, l + h)
    this.appendArc(s, d, d, p, l, h, t, n, i, r, f, o, a)
    this.appendPointList([P, s, u], t, n, i, r, f, c, o, a, false)
  }
  getLinePath(e, t, n, i) {
    throw new NotImplementedError(
      'IterativePen::_getLinePath - child class must implement this method'
    )
  }
  getArcPath(e, t, n, i, r, o, a, s) {
    throw new NotImplementedError(
      'IterativePen::_getArcPath - child class must implement this method'
    )
  }
  appendPointList(e, t, n, i, r, o, a, s, d, l) {
    const h = e.length
    if (0 === h) return r
    let p
    let c = a
    let f = 0
    let g = 0
    if (n.equals(i)) p = 1 / 0
    else p = Math.min(getStraightLineDistance(i), d.MAX_EDGE_LENGTH)
    let u = null
    let P, m, N, b
    const L = []
    for (let i = 1; i < h; i++) {
      g = i
      P = this.getLinePath(e[f], e[g], n, s)
      if (null === u) m = new LinkedModelWorldPoint(P, 0, t, d)
      else {
        u.reInterpret(0)
        m = u
      }
      N = new LinkedModelWorldPoint(P, 1, t, d)
      b = this.appendParameterizedPathOptimized(P, m, N, t, a, r, false, d, p)
      c = !b
      f = g
      u = N
      L.push(m)
    }
    if (o) {
      P = this.getLinePath(e[e.length - 1], e[0], n, s)
      if (!equals3D(P.getStartPosition(), P.getEndPosition()))
        this.appendParameterizedPathOptimized(
          P,
          new LinkedModelWorldPoint(P, 0, t, d),
          new LinkedModelWorldPoint(P, 1, t, d),
          t,
          c,
          r,
          false,
          d,
          p
        )
    }
    if (l) r.fractions = calculateFractions(L)
    return r
  }
  appendParameterizedPath(e, t, n, i, r, o) {
    return this.appendParameterizedPathRaw(
      e,
      new LinkedModelWorldPoint(e, 0, t, o),
      new LinkedModelWorldPoint(e, 1, t, o),
      t,
      n,
      i,
      r,
      o
    )
  }
  appendParameterizedPathOptimized(e, t, n, i, r, o, a, s, d) {
    if (t.isValidPosition() && n.isValidPosition()) {
      const e = undefined
      if (t.distanceTo(n) < d) {
        t.setRightNeighbor(n)
        n.setLeftNeighbor(t)
        if (r) {
          const e = appendToPath(t, o)
          t.hasBeenAppended = t.hasBeenAppended || e
        }
        const e = appendToPath(n, o)
        n.hasBeenAppended = n.hasBeenAppended || e
        return true
      }
    }
    return this.appendParameterizedPathRaw(e, t, n, i, r, o, a, s)
  }
  appendParameterizedPathRaw(e, t, n, i, r, o, a, s) {
    const d = e.getMinimumRecursionDepth()
    if (d > MAX_ITERATION_COUNT)
      throw new ProgrammingError(
        `IterativePen::_appendParamterizedPathRaw Minimum recursion depth is greater than maximum recursion depth: ${d} > ${MAX_ITERATION_COUNT}`
      )
    this._getInitialLinkedList(e, t, n, i, a, s)
    let l = 0
    let h = false
    let p
    while ((l < d || !h) && l < MAX_ITERATION_COUNT) {
      p = l < d
      h = iterate(t, e, i, p, s)
      l++
    }
    return convertToGeneralPath((t = reduce(t)), r, o)
  }
  _getInitialLinkedList(e, t, n, i, r, o) {
    let a = t
    const s = r ? 3 : 2
    const d = r ? 0.25 : 0.5
    let l
    for (let t = 1; t < s; t++) {
      l = new LinkedModelWorldPoint(e, d * t, i, o)
      a.setRightNeighbor(l)
      l.setLeftNeighbor(a)
      a = l
    }
    a.setRightNeighbor(n)
    n.setLeftNeighbor(a)
    if (r) {
      n.setRightNeighbor(t)
      t.setLeftNeighbor(n)
    }
  }
}
function appendToPath(e, t) {
  return t.lineTo(e.worldPoint.x, e.worldPoint.y, e.worldPoint.z)
}
function calculateFractions(e) {
  const t = []
  for (let n = 0; n < e.length; n++) {
    let i = e[n].rightNeighbor
    if (e[n].isValidPosition() && e[n].hasBeenAppended) t.push(n)
    while (i && 0 !== i.fraction && i.rightNeighbor) {
      if (i.isValidPosition() && i.hasBeenAppended) t.push(i.fraction + n)
      i = i.rightNeighbor
    }
    if (n === e.length - 1 && i && i.isValidPosition() && i.hasBeenAppended)
      t.push(e.length)
  }
  return t
}
function getStraightLineDistance(e) {
  let t = STRAIGHT_LINE_DISTANCE_THRESHOLD
  if (e instanceof GridReference || e instanceof CartesianReference)
    t /= e.unitOfMeasure
  else if (e instanceof GeocentricReference) t /= e.unitOfMeasure
  else
    throw new ProgrammingError(
      'IterativePen::getStraightLineDistance - world reference type must be grid or geocentric.'
    )
  return t
}
function equals3D(e, t) {
  return e.x === t.x && t.y === e.y && e.z === t.z
}
function getMiddlePointFraction(e, t) {
  const n = e.fraction
  let i = t.fraction
  if (i < n) i += 1
  return 0.5 * (n + i)
}
function breakPath(e) {
  e.breakLine()
  e.breakPolygon()
}
function convertToGeneralPath(e, t, n) {
  let i = false
  if (e.isValidPosition() && t) {
    i = appendToPath(e, n)
    e.hasBeenAppended = e.hasBeenAppended || i
  }
  let r = e.rightNeighbor
  let o = false
  let a = true
  let s
  while (null != r && (a || r.leftNeighbor != e)) {
    if (!r.isValidPosition()) {
      s = n.subPathCount()
      if (s > 0 && n.subPathLength(s - 1) > 0) breakPath(n)
      o = false
    } else {
      if (!r.isBadConnection()) {
        i = appendToPath(r, n)
        r.hasBeenAppended = r.hasBeenAppended || i
      } else if (r.sqrDistLeft < r.sqrDistRight) {
        i = appendToPath(r, n)
        r.hasBeenAppended = r.hasBeenAppended || i
        breakPath(n)
      } else {
        s = n.subPathCount()
        if (s > 0 && n.subPathLength(s - 1) > 0) breakPath(n)
        i = appendToPath(r, n)
        r.hasBeenAppended = r.hasBeenAppended || i
      }
      o = true
    }
    a = false
    r = r.rightNeighbor
  }
  return o
}
