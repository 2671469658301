import { createPoint } from '../shape/ShapeFactory.js'
import {
  rotatePointCW,
  rotatePointOnOriginCCW,
  squaredDistance2D,
  squaredDistanceToSegment,
} from './Cartesian.js'
import { Constants } from './Constants.js'
export class RotatedBox {
  constructor() {
    this.ox = 0
    this.oy = 0
    this.ow = 0
    this.oh = 0
    this.rot = 0
    this.anx = 0
    this.any = 0
    this.minx = 0
    this.miny = 0
    this.maxx = 0
    this.maxy = 0
    this.blx = 0
    this.bly = 0
    this.brx = 0
    this.bry = 0
    this.tlx = 0
    this.tly = 0
    this.trx = 0
    this.tr_y = 0
    this._axesDirty = true
    this._ax0 = 0
    this._ay0 = 0
    this._ax1 = 0
    this._ay1 = 0
    this._sin = 0
    this._cos = 1
    this._dX = 1 / 0
    this._dY = 1 / 0
    this.paddingMargin = 0
    this.tmpOut = { x: 0, y: 0 }
    this.tmpPoints = new Array(8)
  }
  getCos() {
    return this._cos
  }
  getSin() {
    return this._sin
  }
  getMinX() {
    return this.minx
  }
  getMaxX() {
    return this.maxx
  }
  getMinY() {
    return this.miny
  }
  getMaxY() {
    return this.maxy
  }
  getX() {
    return this.ox
  }
  getY() {
    return this.oy
  }
  getRotation() {
    return this.rot
  }
  getWidth() {
    return this.ow
  }
  getHeight() {
    return this.oh
  }
  configure(t, i, s, e, h, n, r, o) {
    this.ox = t
    this.oy = i
    this.ow = s
    this.oh = e
    this.rot = h
    this.anx = n || 0
    this.any = r || 0
    this.paddingMargin = o || 0
    this._wash()
  }
  translate(t, i) {
    this.ox += t
    this.oy += i
    this._wash()
  }
  draw(t) {
    t.beginPath()
    t.moveTo(this.blx, this.bly)
    t.lineTo(this.brx, this.bry)
    t.lineTo(this.trx, this.tr_y)
    t.lineTo(this.tlx, this.tly)
    t.closePath()
    t.strokeStyle = 'rgb(0,255,255)'
    t.lineWidth = 3
    t.stroke()
    t.fillStyle = 'rgba(0,255,255,0.2)'
    t.fill()
    t.strokeStyle = 'rgba(0,0,255,1)'
    t.lineWidth = 1
    t.strokeRect(
      this.minx,
      this.miny,
      this.maxx - this.minx,
      this.maxy - this.miny
    )
    t.fillStyle = 'rgba(133,0,255,1)'
    t.fillRect(this.anx - 3 + this.ox, this.any - 3 + this.oy, 6, 6)
  }
  drawWorld(t, i) {
    const s = createPoint(i.inputReference, [this.blx, this.bly])
    const e = createPoint(i.inputReference, [this.brx, this.bry])
    const h = createPoint(i.inputReference, [this.trx, this.tr_y])
    const n = createPoint(i.inputReference, [this.tlx, this.tly])
    const r = createPoint(i.inputReference, [this.minx, this.miny])
    const o = createPoint(i.inputReference, [this.minx, this.maxy])
    const a = createPoint(i.inputReference, [this.maxx, this.maxy])
    const x = createPoint(i.inputReference, [this.maxx, this.miny])
    const l = createPoint(i.inputReference, [
      this.ox - this.anx,
      this.oy + this.any,
    ])
    const y = i.transform(e)
    const m = i.transform(s)
    const c = i.transform(n)
    const u = i.transform(h)
    const g = i.transform(r)
    const _ = i.transform(o)
    const b = i.transform(a)
    const B = i.transform(x)
    const p = i.transform(l)
    t.beginPath()
    t.moveTo(m.x, m.y)
    t.lineTo(y.x, y.y)
    t.lineTo(u.x, u.y)
    t.lineTo(c.x, c.y)
    t.closePath()
    t.strokeStyle = 'rgb(0,255,255)'
    t.lineWidth = 3
    t.stroke()
    t.fillStyle = 'rgba(0,255,255,0.2)'
    t.fill()
    t.strokeStyle = 'rgba(0,0,255,1)'
    t.lineWidth = 1
    t.moveTo(g.x, g.y)
    t.lineTo(_.x, _.y)
    t.lineTo(b.x, b.y)
    t.lineTo(B.x, B.y)
    t.closePath()
    t.stroke()
    t.fillStyle = 'rgba(133,0,255,1)'
    t.fillRect(p.x - 3, p.y - 3, 6, 6)
  }
  drawView(t) {
    const i = createPoint(null, [this.blx, this.bly])
    const s = createPoint(null, [this.brx, this.bry])
    const e = createPoint(null, [this.trx, this.tr_y])
    const h = createPoint(null, [this.tlx, this.tly])
    const n = createPoint(null, [this.minx, this.miny])
    const r = createPoint(null, [this.minx, this.maxy])
    const o = createPoint(null, [this.maxx, this.maxy])
    const a = createPoint(null, [this.maxx, this.miny])
    const x = createPoint(null, [this.ox - this.anx, this.oy + this.any])
    t.beginPath()
    t.moveTo(i.x, i.y)
    t.lineTo(s.x, s.y)
    t.lineTo(e.x, e.y)
    t.lineTo(h.x, h.y)
    t.closePath()
    t.strokeStyle = 'rgb(0,255,255)'
    t.lineWidth = 3
    t.stroke()
    t.fillStyle = 'rgba(0,255,255,0.2)'
    t.fill()
    t.strokeStyle = 'rgba(0,0,255,1)'
    t.lineWidth = 1
    t.moveTo(n.x, n.y)
    t.lineTo(r.x, r.y)
    t.lineTo(o.x, o.y)
    t.lineTo(a.x, a.y)
    t.closePath()
    t.stroke()
    t.fillStyle = 'rgba(133,0,255,1)'
    t.fillRect(x.x - 3, x.y - 3, 6, 6)
  }
  moveX(t) {
    if (Math.abs(this._dY) > 2) return
    this.ox += t
    this.oy += -this._dY * t
    this._wash()
  }
  moveY(t) {
    if (Math.abs(this._dX) > 2) return
    this.oy += t
    this.ox += -this._dX * t
    this._wash()
    rotatePointOnOriginCCW(this.ow, this.oh, this.rot, this.tmpOut)
  }
  _readCoordinatesSFCT(t, i) {
    if (i && this.paddingMargin) {
      t[0] = this.tlx - this.paddingMargin
      t[1] = this.tly + this.paddingMargin
      t[2] = this.trx + this.paddingMargin
      t[3] = this.tr_y + this.paddingMargin
      t[4] = this.brx + this.paddingMargin
      t[5] = this.bry - this.paddingMargin
      t[6] = this.blx - this.paddingMargin
      t[7] = this.bly - this.paddingMargin
    } else {
      t[0] = this.tlx
      t[1] = this.tly
      t[2] = this.trx
      t[3] = this.tr_y
      t[4] = this.brx
      t[5] = this.bry
      t[6] = this.blx
      t[7] = this.bly
    }
  }
  _readAxesSFCT(t) {
    t[0] = this._ax0
    t[1] = this._ay0
    t[2] = this._ax1
    t[3] = this._ay1
  }
  getCorners() {
    return [
      [this.blx, this.bly],
      [this.brx, this.bry],
      [this.trx, this.tr_y],
      [this.tlx, this.tly],
    ]
  }
  intersects(t, i) {
    if (!intersects_AABB_AABB(this, t, i)) return false
    if (0 === this.rot && 0 === t.rot) return true
    else if (0 === t.rot) return intersects_AABB_OBB(t, this, i)
    else if (0 === this.rot) return intersects_AABB_OBB(this, t, i)
    else return intersects_OBB_OBB(this, t, i)
  }
  squaredDistanceToEdge(t, i) {
    return Math.min(
      squaredDistanceToSegment(this.blx, this.bly, this.brx, this.bry, t, i),
      squaredDistanceToSegment(this.tlx, this.tly, this.blx, this.bly, t, i),
      squaredDistanceToSegment(this.trx, this.tr_y, this.tlx, this.tly, t, i),
      squaredDistanceToSegment(this.brx, this.bry, this.trx, this.tr_y, t, i)
    )
  }
  squaredDistanceToCenter(t, i) {
    return squaredDistance2D(this.ox, this.oy, t, i)
  }
  getLeft() {
    return this.blx
  }
  getTop() {
    return this.bly
  }
  getOBBWidth() {
    return this.trx - this.blx
  }
  getOBBHeight() {
    return this.tr_y - this.bly
  }
  getOLeft(t) {
    return t ? this.minx - this.paddingMargin : this.minx
  }
  getORight(t) {
    return t ? this.maxx + this.paddingMargin : this.maxx
  }
  getOTop(t) {
    return t ? this.miny - this.paddingMargin : this.miny
  }
  getOBottom(t) {
    return t ? this.maxy + this.paddingMargin : this.maxy
  }
  getAngleInDegrees() {
    return this.rot * Constants.RAD2DEG
  }
  getAnchorX() {
    return this.anx
  }
  getAnchorY() {
    return this.any
  }
  getPadding() {
    return this.paddingMargin
  }
  setLTRBAnchorXYAngleDeg(t, i, s, e, h, n, r, o) {
    const a = r * Constants.DEG2RAD
    const x = e - i
    const l = s - t
    const y = h - t
    const m = n - i
    this.configure(t, i, l, x, a, y, m, o)
  }
  _wash() {
    this._axesDirty = true
    this._sin = Math.sin(-this.rot)
    this._cos = Math.cos(-this.rot)
    this._dX = this._cos / this._sin
    this._dY = this._sin / this._cos
    if (0 === this.rot) {
      this.minx = this.ox
      this.miny = this.oy
      this.maxx = this.minx + this.ow
      this.maxy = this.miny + this.oh
      this.blx = this.minx
      this.bly = this.miny
      this.brx = this.maxx
      this.bry = this.miny
      this.tlx = this.minx
      this.tly = this.maxy
      this.trx = this.maxx
      this.tr_y = this.maxy
      return
    }
    const t = this.ox
    const i = this.oy
    const s = t + this.anx
    const e = i + this.any
    rotatePointCW(t, i, this.rot, s, e, this.tmpOut)
    this.blx = this.tmpOut.x
    this.bly = this.tmpOut.y
    rotatePointCW(t + this.ow, i, this.rot, s, e, this.tmpOut)
    this.brx = this.tmpOut.x
    this.bry = this.tmpOut.y
    rotatePointCW(t, i + this.oh, this.rot, s, e, this.tmpOut)
    this.tlx = this.tmpOut.x
    this.tly = this.tmpOut.y
    rotatePointCW(t + this.ow, i + this.oh, this.rot, s, e, this.tmpOut)
    this.trx = this.tmpOut.x
    this.tr_y = this.tmpOut.y
    this.minx = Math.min(this.blx, this.brx, this.tlx, this.trx)
    this.miny = Math.min(this.bly, this.bry, this.tly, this.tr_y)
    this.maxx = Math.max(this.blx, this.brx, this.tlx, this.trx)
    this.maxy = Math.max(this.bly, this.bry, this.tly, this.tr_y)
  }
  invert() {
    rotatePointOnOriginCCW(this.ow, this.oh, this.rot, this.tmpOut)
    this.ox += this.tmpOut.x
    this.oy += this.tmpOut.y
    this.rot = (this.rot + Constants.d180InRadians) % Constants.d360InRadians
    this._wash()
  }
  computeAxes(t) {
    if (!this._axesDirty) return
    this._readCoordinatesSFCT(this.tmpPoints, t)
    this._ax0 = -(this.tmpPoints[1] - this.tmpPoints[3])
    this._ay0 = this.tmpPoints[0] - this.tmpPoints[2]
    this._ax1 = -(this.tmpPoints[3] - this.tmpPoints[5])
    this._ay1 = this.tmpPoints[2] - this.tmpPoints[4]
    this._axesDirty = false
  }
}
function intersects_AABB_AABB(t, i, s) {
  return (
    t.getOLeft(s) <= i.getORight(s) &&
    i.getOLeft(s) <= t.getORight(s) &&
    t.getOTop(s) <= i.getOBottom(s) &&
    i.getOTop(s) <= t.getOBottom(s)
  )
}
const pointsOBB = new Array(8)
const axesOBB = new Array(4)
function intersects_AABB_OBB(t, i, s) {
  i._readCoordinatesSFCT(pointsOBB, s)
  let e = t.getOLeft(s)
  let h = t.getORight(s)
  let n = Number.MAX_VALUE
  let r = -Number.MAX_VALUE
  let o, a, x
  for (x = 0; x < 8; x += 2) {
    o = pointsOBB[x]
    n = n < o ? n : o
    r = r > o ? r : o
  }
  if (e > r || n > h) return false
  e = -t.getOBottom(s)
  h = -t.getOTop(s)
  n = Number.MAX_VALUE
  r = -Number.MAX_VALUE
  for (x = 1; x < 8; x += 2) {
    o = -pointsOBB[x]
    n = n < o ? n : o
    r = r > o ? r : o
  }
  if (e > r || n > h) return false
  i.computeAxes(s)
  i._readAxesSFCT(axesOBB)
  for (a = 0; a < 4; a += 2) {
    e = Number.MAX_VALUE
    h = -Number.MAX_VALUE
    o = t.getOLeft(s) * axesOBB[a] + t.getOTop(s) * axesOBB[a + 1]
    e = e < o ? e : o
    h = h > o ? h : o
    o = t.getORight(s) * axesOBB[a] + t.getOTop(s) * axesOBB[a + 1]
    e = e < o ? e : o
    h = h > o ? h : o
    o = t.getORight(s) * axesOBB[a] + t.getOBottom(s) * axesOBB[a + 1]
    e = e < o ? e : o
    h = h > o ? h : o
    o = t.getOLeft(s) * axesOBB[a] + t.getOBottom(s) * axesOBB[a + 1]
    e = e < o ? e : o
    h = h > o ? h : o
    n = Number.MAX_VALUE
    r = -Number.MAX_VALUE
    for (x = 0; x < 8; x += 2) {
      o = pointsOBB[x] * axesOBB[a] + pointsOBB[x + 1] * axesOBB[a + 1]
      n = n < o ? n : o
      r = r > o ? r : o
    }
    if (e > r || n > h) return false
  }
  return true
}
const pointsA = new Array(8)
const pointsB = new Array(8)
const axesA = new Array(4)
const axesB = new Array(4)
function intersects_OBB_OBB(t, i, s) {
  t.computeAxes(s)
  i.computeAxes(s)
  t._readAxesSFCT(axesA)
  i._readAxesSFCT(axesB)
  t._readCoordinatesSFCT(pointsA, s)
  i._readCoordinatesSFCT(pointsB, s)
  for (let t = 0; t < 4; t += 2) {
    let i = Number.MAX_VALUE
    let s = Number.MAX_VALUE
    let e = -Number.MAX_VALUE
    let h = -Number.MAX_VALUE
    for (let n = 0; n < 8; n += 2) {
      let r = pointsA[n] * axesA[t] + pointsA[n + 1] * axesA[t + 1]
      i = i < r ? i : r
      e = e > r ? e : r
      r = pointsB[n] * axesA[t] + pointsB[n + 1] * axesA[t + 1]
      s = s < r ? s : r
      h = h > r ? h : r
    }
    if (i > h || s > e) return false
  }
  for (let t = 0; t < 4; t += 2) {
    let i = Number.MAX_VALUE
    let s = Number.MAX_VALUE
    let e = -Number.MAX_VALUE
    let h = -Number.MAX_VALUE
    for (let n = 0; n < 8; n += 2) {
      let r = pointsA[n] * axesB[t] + pointsA[n + 1] * axesB[t + 1]
      i = i < r ? i : r
      e = e > r ? e : r
      r = pointsB[n] * axesB[t] + pointsB[n + 1] * axesB[t + 1]
      s = s < r ? s : r
      h = h > r ? h : r
    }
    if (i > h || s > e) return false
  }
  return true
}
