import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Azimuthal } from './Azimuthal.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('AzimuthalEquidistant')
export class AzimuthalEquidistant extends Azimuthal {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._case = this.OBLIQUE
    this._originSinLat = -1
    this._originSinLon = -1
    this._originCosLat = -1
    this._originCosLon = -1
    this.setOriginLon(t)
    this.setOriginLat(s)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    const t = this.getOriginLat() * Constants.DEG2RAD
    const s = this.getOriginLon() * Constants.DEG2RAD
    this._originSinLat = Math.sin(t)
    this._originSinLon = Math.sin(s)
    this._originCosLat = Math.cos(t)
    this._originCosLon = Math.cos(s)
    this._case = this.OBLIQUE
    if (0 == this.getOriginLat()) this._case = this.EQUATOR
    if (90 == this.getOriginLat()) this._case = this.NORTH_POLE
    if (-90 == this.getOriginLat()) this._case = this.SOUTH_POLE
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, s, i) {
    if (this.inLonLatBounds(t)) {
      let n = 0
      let o = 0
      const a = Math.cos(t.x * Constants.DEG2RAD)
      const r = Math.sin(t.x * Constants.DEG2RAD)
      const e = r * this._originCosLon - a * this._originSinLon
      const h = a * this._originCosLon + r * this._originSinLon
      let c, g, L, O, u
      switch (this._case) {
        case this.OBLIQUE:
          c = Math.cos(t.y * Constants.DEG2RAD)
          g = Math.sin(t.y * Constants.DEG2RAD)
          L = this._originSinLat * g + this._originCosLat * c * h
          if (Math.abs(L - 1) < 1e-8) break
          O = Math.acos(L)
          u = (s * O) / Math.sin(O)
          n = u * c * e
          o = u * (this._originCosLat * g - this._originSinLat * c * h)
          break
        case this.EQUATOR:
          c = Math.cos(t.y * Constants.DEG2RAD)
          g = Math.sin(t.y * Constants.DEG2RAD)
          L = c * h
          if (Math.abs(L - 1) < 1e-8) break
          O = Math.acos(L)
          u = (s * O) / Math.sin(O)
          n = u * c * e
          o = u * g
          break
        case this.NORTH_POLE: {
          const i = s * (Math.PI / 2 - t.y * Constants.DEG2RAD)
          n = i * e
          o = -i * h
          break
        }
        case this.SOUTH_POLE: {
          const i = s * (Math.PI / 2 + t.y * Constants.DEG2RAD)
          n = i * e
          o = i * h
          break
        }
      }
      i.x = n
      i.y = o
    } else throw sharedOutOfBoundsError
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, i) {
    if (this.inLonLatBounds(t)) {
      const n = s.geodesicDistance(this.getOrigin(), t)
      const o = s.forwardAzimuth2D(this.getOrigin(), t)
      const a = n * Math.sin(o)
      const r = n * Math.cos(o)
      i.x = a
      i.y = r
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereSFCT(t, s, i) {
    if (this.inWorldBoundsOnSphere(t, s)) {
      let n
      const o = t.x
      const a = t.y
      const r = Math.sqrt(o * o + a * a)
      if (r < 1e-10) {
        i.x = this.getOriginLon()
        i.y = this.getOriginLat()
        return
      }
      const e = r / s
      const h = Math.sin(e)
      const c = Math.cos(e)
      const g = c * this._originSinLat + (a * h * this._originCosLat) / r
      const L = Math.asin(g) * Constants.RAD2DEG
      switch (this._case) {
        case this.OBLIQUE:
        case this.EQUATOR:
          n = Math.atan2(
            o * h,
            r * this._originCosLat * c - a * this._originSinLat * h
          )
          break
        case this.NORTH_POLE:
          n = Math.atan2(o, -a)
          break
        case this.SOUTH_POLE:
          n = Math.atan2(o, a)
          break
        default:
          n = Math.atan2(
            o * h,
            r * this._originCosLat * c - a * this._originSinLat * h
          )
      }
      const O = n * Constants.RAD2DEG
      const u = normalizeLon(this.getOriginLon() + O)
      i.x = u
      i.y = L
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, i) {
    if (this.inWorldBoundsOnEllipsoid(t, s)) {
      const n = t.x
      const o = t.y
      const a = Math.sqrt(n * n + o * o)
      if (a < 1e-10) {
        i.x = this.getOriginLon()
        i.y = this.getOriginLat()
        return
      }
      const r = Math.atan2(n, o) * Constants.RAD2DEG
      s.geodesicPositionSFCT(this.getOrigin(), a, r, i)
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    const s = normalizeLon(t.x - this.getOriginLon())
    return !(t.y == -this.getOriginLat() && (180 == s || -180 == s))
  }
  inWorldBoundsOnSphere(t, s) {
    const i = t.x / s
    const n = t.y / s
    return i * i + n * n < Math.PI * Math.PI
  }
  boundaryLons(t) {
    return [[-180 + this.EPSILON, 180 - this.EPSILON]]
  }
  boundaryLats(t) {
    const s = this._originSinLat
    const i =
      this._originCosLat *
      Math.cos((t - this.getOriginLon()) * Constants.DEG2RAD)
    const n = undefined
    const o = undefined
    const a = -1 / Math.sqrt(s * s + i * i)
    if (Math.abs(a) < 1) {
      const t = Constants.RAD2DEG * Math.acos(a) - this.EPSILON
      const n = Constants.RAD2DEG * Math.atan2(s, i)
      const o = undefined
      const r = undefined
      return [[Math.max(n - t, -90), Math.min(n + t, 90)]]
    } else return [[-90, 90]]
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    const i = Math.PI * t
    s.setTo2D(-i, 2 * i, -i, 2 * i)
  }
  encode() {
    return {
      type: 'AzimuthalEquidistant',
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.AZIMUTHAL_EQUIDISTANT + ProjectionType.AZIMUTHAL
  }
  get OBLIQUE() {
    return 0
  }
  get EQUATOR() {
    return 1
  }
  get NORTH_POLE() {
    return 2
  }
  get SOUTH_POLE() {
    return 3
  }
}
