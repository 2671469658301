import { Photon } from '../../gen/photon/photon_painter.js'
export class PropertiesCallback extends Photon.ClientAttributesCallback {
  constructor(e) {
    super()
    this._clientDataCallbackHandler = e
  }
  handleInViewChange(e, t) {
    const a = []
    const n = []
    for (const t of e.typedArray) a.push(t)
    for (const e of t.typedArray) n.push(e)
    this._clientDataCallbackHandler.actOnFeaturesInViewChanged(a, n)
    e.release()
    t.release()
  }
}
