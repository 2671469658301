import { createOptimizedEventedSupport } from '../../../util/EventedSupport.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { whenInternal } from '../../../util/PromiseUtil.js'
function createTransformationParams(e, r) {
  const { map: t, filter: n } = e
  if (!t)
    throw new ProgrammingError('ModelTransformer: layer is not attached to map')
  const o = new Map()
  const s = e.getSelectedFeatures()
  for (const e of s) o.set(e.id, e)
  const i = e.getEditedFeature()
  if (i && i.id) o.set(i.id, i)
  return {
    reference: t.reference,
    mapToViewTransformation: t.mapToViewTransformationInternal,
    mapScale: t.mapScale,
    mapViewSize: t.viewSize,
    modelBounds: r,
    interactingFeatures: o,
    layerFilter: n,
  }
}
export class ModelTransformer {
  constructor() {
    this._modelListener = null
    this._mustRunOnce = true
    this._eventedSupport = createOptimizedEventedSupport(['ModelChanged'])
  }
  release() {
    this._modelListener?.remove()
    this._modelListener = null
  }
  runQuery(e, r, t) {
    this.release()
    const n = createTransformationParams(t, e.modelBounds)
    this._modelListener = e.on(
      'ModelChanged',
      (r, t, n) => {
        this.handleSourceModelChange(e, r, t, n)
      },
      this
    )
    return whenInternal(r, (r) => {
      this._mustRunOnce = false
      return this.doTransformation(r, e.reference, n)
    })
  }
  shouldTransform(e) {
    return this._mustRunOnce || this.needsTransformation(e)
  }
  on(e, r, t) {
    return this._eventedSupport.on(e, r, t)
  }
}
