import { On } from '../../util/On.js'
import { Handler } from './Handler.js'
import { GestureEventType } from './GestureEventType.js'
import { MultitouchGestureDetector } from './MultitouchGestureDetector.js'
import { PinchEventImpl } from './PinchEvent.js'
import { DragEventImpl } from './DragEvent.js'
import { GestureEventImpl } from './GestureEvent.js'
var LONGPRESS_TIMEOUT = 500
var TAP_TIMEOUT = 180
var DOUBLE_TAP_TIMEOUT = 300
var SHOW_PRESS = 'SHOW_PRESS'
var LONG_PRESS = 'LONG_PRESS'
var TAP = 'TAP'
var TWO_FINGER_TWIST_MIN_ANGLE = 12
var PINCH_GESTURE_SPAN_RATIO_DIFFERENCE = 0.12
var TWO_FINGER_DRAG_MIN_PIXEL_DISTANCE = 75
function TouchHandler(t, e, n) {
  this._map = t
  this._node = e
  var o = [
    'down',
    GestureEventType.DOWN,
    'up',
    GestureEventType.UP,
    'drag',
    GestureEventType.DRAG,
    'dragEnd',
    GestureEventType.DRAG_END,
    'singleClickUp',
    GestureEventType.SINGLE_CLICK_UP,
    'singleClickConfirmed',
    GestureEventType.SINGLE_CLICK_CONFIRMED,
    'doubleClick',
    GestureEventType.DOUBLE_CLICK,
    'doubleClickEvent',
    GestureEventType.DOUBLE_CLICK_EVENT,
    'longPress',
    GestureEventType.LONG_PRESS,
    'showPress',
    GestureEventType.SHOW_PRESS,
    'contextMenu',
    GestureEventType.CONTEXT_MENU,
    'pinchEnd',
    GestureEventType.PINCH_END,
    'twoFingerDrag',
    GestureEventType.TWO_FINGER_DRAG,
    'twoFingerDragEnd',
    GestureEventType.TWO_FINGER_DRAG_END,
    'twoFingerTwist',
    GestureEventType.ROTATE,
    'twoFingerTwistEnd',
    GestureEventType.ROTATE_END,
  ]
  for (var i = 0; i < o.length; i += 2) {
    var s = o[i]
    var r = o[i + 1]
    this[s + 'Event'] = new GestureEventImpl(t, r, 'touch')
  }
  this['pinchEvent'] = new PinchEventImpl(t, 'touch')
  this['dragEvent'] = new DragEventImpl(t, 'touch')
  this._biggerTouchSlopSquare = 20 * 20
  this._touchSlopSquare = 0
  this._doubleTapSlopSquare = 0
  this._handler = new Handler()
  var a = this
  this._handler.handleMessage = function (t) {
    a._handleMessage(t)
  }
  this._listener = n
  this._stillDown = false
  this._inLongPress = false
  this._alwaysInTapRegion = false
  this._alwaysInBiggerTapRegion = false
  this._currentDownDOMEvent = null
  this._previousUpEvent = null
  this._isDoubleTapping = false
  this._lastMotionY = 0
  this._lastMotionX = 0
  this._isLongpressEnabled = true
  var h = 8
  var u = 100
  this._touchSlopSquare = h * h
  this._doubleTapSlopSquare = u * u
  this._recognizerAttempts = 0
  this._gestureDetector = new MultitouchGestureDetector(this)
  this._hasPointerEventsSupport =
    'onpointerdown' in window &&
    'onpointerup' in window &&
    'onpointermove' in window
  this._domListeners = {}
  var c = { blur: 'onblur', contextmenu: 'oncontextmenu' }
  var l
  var E = 'touchmove'
  var v = 'touchend'
  var p = 'touchcancel'
  c['touchstart'] = 'ontouchstart'
  c[E] = 'ontouchmove'
  c[v] = 'ontouchend'
  c[p] = 'ontouchcancel'
  for (var _ in c)
    if (c.hasOwnProperty(_)) {
      var g = c[_]
      this._wrapEventHandler(e, _, g)
    }
  this._ontouchmove_bound = this.ontouchstart.bind(this)
  this._ontouchend_bound = this.ontouchend.bind(this)
  this._onblur_bound = this.onblur.bind(this)
  this._oncontextmenu_bound = this.oncontextmenu.bind(this)
}
TouchHandler.prototype = Object.create(Object.prototype)
TouchHandler.prototype.constructor = TouchHandler
TouchHandler.prototype._wrapEventHandler = function (t, e, n) {
  var o = this[n]
  var i = this
  var s = this._gestureDetector[n]
  this._domListeners[e] = On(
    t,
    e,
    function (t) {
      if (
        i._hasPointerEventsSupport &&
        t instanceof window.PointerEvent &&
        'touch' !== t.pointerType
      )
        return
      i._map._updateViewBounds()
      var e = o.call(i, t)
      var n = s.call(i._gestureDetector, t)
      if (e || n) {
        t.preventDefault()
        t.stopPropagation()
      }
    },
    { capture: false, passive: false }
  )
}
TouchHandler.prototype.destroy = function () {
  this.cancel()
  for (const t in this._domListeners)
    if (this._domListeners.hasOwnProperty(t)) this._domListeners[t].remove()
  this._domListeners = {}
}
TouchHandler.prototype.registerWindowListeners = function () {
  if (!this._windowListeners) {
    const t = { capture: false, passive: false }
    this._windowListeners = [
      On(window, 'touchmove', this._ontouchmove_bound, t),
      On(window, 'touchend', this._ontouchend_bound, t),
      On(window, 'blur', this, this._onblur_bound, t),
      On(window, 'contextmenu', this, this._oncontextmenu_bound, t),
    ]
  }
}
TouchHandler.prototype.unregisterWindowListeners = function () {
  var t = this._windowListeners
  if (t) {
    for (var e = 0, n = t.length; e < n; e++) t[e].remove()
    this._windowListeners = null
  }
}
TouchHandler.prototype.ontouchstart = function (t) {
  if (0 == t.targetTouches.length) return false
  else if (1 == t.targetTouches.length) {
    var e = t.targetTouches[0]
    return this.down(t, e, e.pageX, e.pageY)
  } else return this.cancel()
}
TouchHandler.prototype.ontouchmove = function (t) {
  if (1 == t.targetTouches.length) {
    var e = t.targetTouches[0]
    return this.move(t, e, e.pageX, e.pageY)
  } else return false
}
TouchHandler.prototype.ontouchend = function (t) {
  var e
  if (0 == t.targetTouches.length) {
    e = t.changedTouches[0]
    return this.up(t, e, e.pageX, e.pageY)
  } else if (1 == t.targetTouches.length) {
    e = t.targetTouches[0]
    this._lastMotionX = e.pageX
    this._lastMotionY = e.pageY
  }
  return false
}
TouchHandler.prototype.ontouchcancel = function (t) {
  this.cancel()
  return false
}
TouchHandler.prototype.down = function (t, e, n, o) {
  this.registerWindowListeners()
  var i = this._handler.hasMessages(TAP)
  if (i) this._handler.removeMessages(TAP)
  this._currentContextMenuEvent = null
  if (
    null != this._currentDownDOMEvent &&
    null != this._previousUpEvent &&
    i &&
    this.isConsideredDoubleTap(
      this._currentDownDOMEvent,
      this._previousUpEvent,
      t
    )
  ) {
    this._isDoubleTapping = true
    var s = this.doubleClickEvent
    s.location = this._currentDownEvent.location
    s.domEvent = this._currentDownEvent.domEvent
    this.dispatchGestureEvent(s)
    var r = this.doubleClickEventEvent
    r.location = e
    r.domEvent = t
    this.dispatchGestureEvent(r)
  } else this._handler.sendEmptyMessageDelayed(TAP, DOUBLE_TAP_TIMEOUT)
  this._lastMotionX = n
  this._lastMotionY = o
  this._currentDownDOMEvent = t
  this._currentDownEvent = new GestureEventImpl(
    this._map,
    GestureEventType.DOWN,
    'touch'
  )
  this._currentDownEvent.location = e
  this._currentDownEvent.domEvent = t
  this._currentDownX = n
  this._currentDownY = o
  this._alwaysInTapRegion = true
  this._alwaysInBiggerTapRegion = true
  this._alwaysInBiggerTapRegion = true
  this._stillDown = true
  this._inLongPress = false
  if (this._isLongpressEnabled && !this._isDoubleTapping) {
    this._handler.removeMessages(LONG_PRESS)
    this._handler.sendEmptyMessageDelayed(
      LONG_PRESS,
      TAP_TIMEOUT + LONGPRESS_TIMEOUT
    )
  }
  this._handler.sendEmptyMessageDelayed(SHOW_PRESS, TAP_TIMEOUT)
  var a = this.downEvent
  a.location = e
  a.domEvent = t
  this._currentDownEvent = a.copy()
  this.dispatchGestureEvent(a)
  return t.currentTarget == this._node
}
TouchHandler.prototype.move = function (t, e, n, o) {
  if (this._inLongPress) return true
  var i = n - this._lastMotionX
  var s = o - this._lastMotionY
  if (this._isDoubleTapping) {
    var r = this.doubleClickEventEvent
    r.location = e
    r.domEvent = t
    this.dispatchGestureEvent(r)
  } else if (this._alwaysInTapRegion) {
    var a = n - this._currentDownX
    var h = o - this._currentDownY
    var u = a * a + h * h
    if (u > this._touchSlopSquare) {
      this.dragging = true
      var c = this.dragEvent
      c.location = e
      c.domEvent = t
      c.downEvent = this._currentDownEvent
      c.dragX = i
      c.dragY = s
      this.dispatchGestureEvent(c)
      this._currentDragEvent = c
      this._lastMotionX = n
      this._lastMotionY = o
      this._alwaysInTapRegion = false
      this._handler.removeMessages(TAP)
      this._handler.removeMessages(SHOW_PRESS)
      this._handler.removeMessages(LONG_PRESS)
    }
    if (u > this._biggerTouchSlopSquare) this._alwaysInBiggerTapRegion = false
  } else if (Math.abs(i) >= 1 || Math.abs(s) >= 1) {
    this.dragging = true
    ;(c = this.dragEvent).location = e
    c.domEvent = t
    c.downEvent = this._currentDownEvent
    c.dragX = i
    c.dragY = s
    this.dispatchGestureEvent(c)
    this._lastMotionX = n
    this._lastMotionY = o
  }
  return t.currentTarget == this._node
}
TouchHandler.prototype.up = function (t, e, n, o) {
  this.unregisterWindowListeners()
  var i = this.upEvent
  i.location = e
  i.domEvent = t
  this.dispatchGestureEvent(i)
  if (this.dragging) {
    this.dragging = false
    var s = this.dragEndEvent
    s.location = e
    s.domEvent = t
    this.dispatchGestureEvent(s)
  }
  this._stillDown = false
  if (this._isDoubleTapping) {
    var r = this.doubleClickEventEvent
    r.location = e
    r.domEvent = t
    this.dispatchGestureEvent(r)
  } else if (this._inLongPress) {
    this._handler.removeMessages(TAP)
    this._inLongPress = false
  } else if (this._alwaysInTapRegion) {
    var a = this.singleClickUpEvent
    a.location = e
    a.domEvent = t
    this.dispatchGestureEvent(a)
  }
  this._previousUpEvent = t
  this._isDoubleTapping = false
  this._handler.removeMessages(SHOW_PRESS)
  this._handler.removeMessages(LONG_PRESS)
  return t.currentTarget == this._node
}
TouchHandler.prototype.onblur = function () {
  this.cancel()
}
TouchHandler.prototype.oncontextmenu = function (t) {
  this._currentContextMenuEvent = t
  return true
}
TouchHandler.prototype._triggerContextMenu = function (t) {
  this.cancel()
  var e = this.contextMenuEvent
  e.location = t
  e.domEvent = t
  this.dispatchGestureEvent(e)
}
TouchHandler.prototype.cancel = function () {
  this.unregisterWindowListeners()
  if (this.dragging) {
    this.dragging = false
    var t = this.dragEndEvent
    t.domEvent = this._currentDragEvent.domEvent
    t.location = this._currentDragEvent.location
    t.downEvent = this._currentDragEvent.downEvent
    t.dragX = this._currentDragEvent.dragX
    t.dragY = this._currentDragEvent.dragY
    this.dispatchGestureEvent(t)
  }
  this._handler.removeMessages(SHOW_PRESS)
  this._handler.removeMessages(LONG_PRESS)
  this._handler.removeMessages(TAP)
  this._isDoubleTapping = false
  this._stillDown = false
  this._alwaysInTapRegion = false
  this._alwaysInBiggerTapRegion = false
  if (this._inLongPress) this._inLongPress = false
}
TouchHandler.prototype.isConsideredDoubleTap = function (t, e, n) {
  if (
    !this._alwaysInBiggerTapRegion ||
    n.timeStamp - e.timeStamp > DOUBLE_TAP_TIMEOUT
  )
    return false
  var o = t.touches[0].pageX - n.touches[0].pageX
  var i = t.touches[0].pageY - n.touches[0].pageY
  return o * o + i * i < this._doubleTapSlopSquare
}
TouchHandler.prototype.dispatchLongPress = function () {
  this._handler.removeMessages(TAP)
  this._inLongPress = true
  var t = this.longPressEvent
  t.location = this._currentDownEvent.location
  t.domEvent = this._currentDownEvent.domEvent
  this.dispatchGestureEvent(t)
}
TouchHandler.prototype._handleMessage = function (t) {
  switch (t) {
    case SHOW_PRESS:
      var e = this.showPressEvent
      e.location = this._currentDownEvent.location
      e.domEvent = this._currentDownEvent.domEvent
      this.dispatchGestureEvent(e)
      break
    case LONG_PRESS:
      if (this._currentContextMenuEvent) {
        this._triggerContextMenu(this._currentContextMenuEvent)
        this._currentContextMenuEvent = null
      } else this.dispatchLongPress()
      break
    case TAP:
      if (!this._stillDown) {
        var n = this.singleClickConfirmedEvent
        n.location = this._currentDownEvent.location
        n.domEvent = this._currentDownEvent.domEvent
        this.dispatchGestureEvent(n)
      }
      break
  }
}
TouchHandler.prototype.determineMultiTouchGestureEventType = function (t) {
  var e = t.getCurrentSpan()
  var n = t.getInitialSpan()
  var o = Math.abs(1 - e / n)
  var i = t.getCurrentAngle()
  var s = t.getInitialAngle()
  var r = Math.min(Math.abs(i - s), 360 - Math.abs(i - s))
  var a = Math.abs(t.getInitialFocusX() - t.getFocusX())
  var h = Math.abs(t.getInitialFocusY() - t.getFocusY())
  var u = r > TWO_FINGER_TWIST_MIN_ANGLE
  var c = o > PINCH_GESTURE_SPAN_RATIO_DIFFERENCE
  var l
  if (
    h > TWO_FINGER_DRAG_MIN_PIXEL_DISTANCE ||
    a > TWO_FINGER_DRAG_MIN_PIXEL_DISTANCE
  ) {
    this._recognizerAttempts = 0
    return GestureEventType.TWO_FINGER_DRAG
  }
  if (u && !c) {
    this._recognizerAttempts = 0
    return GestureEventType.ROTATE
  }
  if (c && !u) {
    this._recognizerAttempts = 0
    return GestureEventType.PINCH
  }
  if (c && u) {
    this._recognizerAttempts++
    if (this._recognizerAttempts >= 3) {
      this._recognizerAttempts = 0
      if (35 * o > r) return GestureEventType.PINCH
      return GestureEventType.ROTATE
    }
  }
  return null
}
TouchHandler.prototype.onMultiTouchGesture = function (t, e) {
  var n = this._currentMultiTouchGesture
    ? this._currentMultiTouchGesture.type
    : this.determineMultiTouchGestureEventType(t, e)
  if (null !== n && !this._multiTouchDownEventInfo)
    this._multiTouchDownEventInfo = {
      domEvent: e,
      location: { pageX: t.getFocusX(), pageY: t.getFocusY() },
      span: t.getCurrentSpan(),
      previousSpan: t.getPreviousSpan(),
      scaleFactor: 1,
      scaleFactorFromStart: 1,
      angle: t.getCurrentAngle(),
    }
  if (n === GestureEventType.PINCH) {
    this.pinchEvent.domEvent = e
    this.pinchEvent.location = { pageX: t.getFocusX(), pageY: t.getFocusY() }
    this.pinchEvent.span = t.getCurrentSpan()
    this.pinchEvent.previousSpan = t.getPreviousSpan()
    this.pinchEvent.timeStamp = t.getEventTime()
    this.pinchEvent.timeDelta = t.getTimeDelta()
    this.pinchEvent.scaleFactor = t.getScaleFactor()
    this.pinchEvent.scaleFactorFromStart = t.getScaleFactorFromStart()
    const n = this.pinchEvent.copy()
    n.domEvent = this._multiTouchDownEventInfo.domEvent
    n.location = {
      pageX: this._multiTouchDownEventInfo.location.pageX,
      pageY: this._multiTouchDownEventInfo.location.pageY,
    }
    n.span = this._multiTouchDownEventInfo.span
    n.previousSpan = this._multiTouchDownEventInfo.previousSpan
    n.scaleFactor = this._multiTouchDownEventInfo.scaleFactor
    n.scaleFactorFromStart = this._multiTouchDownEventInfo.scaleFactorFromStart
    this.pinchEvent.downEvent = n
    this._currentMultiTouchGesture = this.pinchEvent.copy()
    this._listener.onGestureEvent(this._currentMultiTouchGesture)
  }
  if (n === GestureEventType.ROTATE) {
    this.twoFingerTwistEvent.domEvent = e
    this.twoFingerTwistEvent.location = {
      pageX: t.getInitialFocusX(),
      pageY: t.getInitialFocusY(),
    }
    this.twoFingerTwistEvent.angle = t.getCurrentAngle()
    this.twoFingerTwistEvent.span = t.getCurrentSpan()
    const n = this.twoFingerTwistEvent.copy()
    n.domEvent = this._multiTouchDownEventInfo.domEvent
    n.location = {
      pageX: this._multiTouchDownEventInfo.location.pageX,
      pageY: this._multiTouchDownEventInfo.location.pageY,
    }
    n.span = this._multiTouchDownEventInfo.span
    n.angle = this._multiTouchDownEventInfo.angle
    this.twoFingerTwistEvent.downEvent = n
    this._currentMultiTouchGesture = this.twoFingerTwistEvent.copy()
    this._listener.onGestureEvent(this._currentMultiTouchGesture)
  }
  if (n === GestureEventType.TWO_FINGER_DRAG) {
    this.twoFingerDragEvent.domEvent = e
    this.twoFingerDragEvent.location = {
      pageX: t.getFocusX(),
      pageY: t.getFocusY(),
    }
    const n = this.twoFingerDragEvent.copy()
    n.domEvent = this._multiTouchDownEventInfo.domEvent
    n.location = {
      pageX: this._multiTouchDownEventInfo.location.pageX,
      pageY: this._multiTouchDownEventInfo.location.pageY,
    }
    this.twoFingerDragEvent.downEvent = n
    this._currentMultiTouchGesture = this.twoFingerDragEvent.copy()
    this._listener.onGestureEvent(this._currentMultiTouchGesture)
  }
  return true
}
TouchHandler.prototype.onMultiTouchGestureBegin = function (t) {}
TouchHandler.prototype.onMultiTouchGestureEnd = function (t, e) {
  if (
    this._currentMultiTouchGesture &&
    this._currentMultiTouchGesture.type === GestureEventType.ROTATE
  )
    this.twoFingerTwistEnd(t, e)
  else if (
    this._currentMultiTouchGesture &&
    this._currentMultiTouchGesture.type === GestureEventType.TWO_FINGER_DRAG
  )
    this.twoFingerDragEnd(t, e)
  else if (
    this._currentMultiTouchGesture &&
    this._currentMultiTouchGesture.type === GestureEventType.PINCH
  )
    this.pinchEnd(t, e)
  this._currentMultiTouchGesture = null
  this._multiTouchDownEventInfo = null
}
TouchHandler.prototype.pinchEnd = function (t, e) {
  this.pinchEndEvent.domEvent = e
  this.pinchEndEvent.location = { pageX: t.getFocusX(), pageY: t.getFocusY() }
  const n = this.pinchEndEvent.copy()
  n.domEvent = this._multiTouchDownEventInfo.domEvent
  n.location = {
    pageX: this._multiTouchDownEventInfo.location.pageX,
    pageY: this._multiTouchDownEventInfo.location.pageY,
  }
  n.span = this._multiTouchDownEventInfo.span
  n.previousSpan = this._multiTouchDownEventInfo.previousSpan
  n.scaleFactor = this._multiTouchDownEventInfo.scaleFactor
  n.scaleFactorFromStart = this._multiTouchDownEventInfo.scaleFactorFromStart
  this.pinchEndEvent.downEvent = n
  this._listener.onGestureEvent(this.pinchEndEvent.copy())
}
TouchHandler.prototype.twoFingerDragEnd = function (t, e) {
  this.twoFingerDragEndEvent.domEvent = e
  this.twoFingerDragEndEvent.location = {
    pageX: t.getFocusX(),
    pageY: t.getFocusY(),
  }
  const n = this.twoFingerDragEndEvent.copy()
  n.domEvent = this._multiTouchDownEventInfo.domEvent
  n.location = {
    pageX: this._multiTouchDownEventInfo.location.pageX,
    pageY: this._multiTouchDownEventInfo.location.pageY,
  }
  this.twoFingerDragEndEvent.downEvent = n
  this._listener.onGestureEvent(this.twoFingerDragEndEvent.copy())
}
TouchHandler.prototype.twoFingerTwistEnd = function (t, e) {
  this.twoFingerTwistEndEvent.domEvent = e
  this.twoFingerTwistEndEvent.location = {
    pageX: t.getInitialFocusX(),
    pageY: t.getInitialFocusY(),
  }
  this.twoFingerTwistEndEvent.angle = t.getCurrentAngle()
  this.twoFingerTwistEndEvent.span = t.getCurrentSpan()
  const n = this.twoFingerTwistEndEvent.copy()
  n.domEvent = this._multiTouchDownEventInfo.domEvent
  n.location = {
    pageX: this._multiTouchDownEventInfo.location.pageX,
    pageY: this._multiTouchDownEventInfo.location.pageY,
  }
  n.span = this._multiTouchDownEventInfo.span
  n.angle = this._multiTouchDownEventInfo.angle
  this.twoFingerTwistEndEvent.downEvent = n
  this._listener.onGestureEvent(this.twoFingerTwistEndEvent.copy())
}
TouchHandler.prototype.dispatchGestureEvent = function (t) {
  if (this._currentMultiTouchGesture) return
  t.modifier = this._handler.modifier(t._domEvent)
  this._listener.onGestureEvent(t)
}
export { TouchHandler }
