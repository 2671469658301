export let LTSSupportedOperations = (function (t) {
  t['GetCapabilities'] = 'GetCapabilities'
  t['Query'] = 'Query'
  t['GetResourceMetadata'] = 'GetResourceMetadata'
  t['GetTile'] = 'GetTile'
  t['GetStatistics'] = 'GetStatistics'
  t['GetSize'] = 'GetSize'
  return t
})({})
export class LTSCapabilitiesOperations {
  constructor() {
    this.operations = new Map()
  }
  getOperations(t) {
    if (t) {
      let e = this.operations.get(t)
      if (!e) {
        e = new Array()
        this.operations.set(t, e)
      }
      return e
    }
  }
  updateOperations(t, e) {
    const i = this.getOperations(t)
    if (i) this.operations.set(t, i.concat(e))
  }
}
