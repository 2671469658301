import { Constants } from '../util/Constants.js'
import {
  distance2D_xy,
  ellipticalDistance2D,
  normalizeAngle,
} from '../util/Cartesian.js'
export function calculateBounds(t, n, o, a, s) {
  const e = Math.cos(a * Constants.DEG2RAD)
  const c = Math.sin(a * Constants.DEG2RAD)
  const i = a - Math.atan2(o * c, n * e) * Constants.RAD2DEG
  const r = a + Math.atan2(o * e, n * c) * Constants.RAD2DEG
  const D = (i - a) * Constants.DEG2RAD
  const l = Math.abs(n * e * Math.cos(D) - o * c * Math.sin(D))
  const h = (r - a) * Constants.DEG2RAD
  const p = Math.abs(n * c * Math.cos(h) + o * e * Math.sin(h))
  s.move2DToCoordinates(t.x - l, t.y - p)
  s.width = 2 * l
  s.height = 2 * p
}
export function contains2D(t, n, o, a, s, e) {
  const c = s - t.x
  const i = e - t.y
  const r = Math.atan2(i, c) * Constants.RAD2DEG
  return Math.sqrt(c * c + i * i) <= ellipticalDistance2D(n, o, r - a)
}
export function updatePoint(t, n, o, a) {
  const s = 360 * n
  const e = Math.atan2(a - t.center.y, o - t.center.x) * Constants.RAD2DEG
  t.rotationAzimuth = normalizeAngle(90 - (e + s))
  const c = distance2D_xy(t.center.x, t.center.y, o, a)
  if (normalizeAngle(s) % 180 === 0) t.a = c
  else t.b = c
}
export function interpolate(t, n, o) {
  if (!o) o = t.center.copy()
  const [a, s] = interpolateXYZCoordinate(t, n)
  o.move2D(a, s)
  return o
}
export function interpolateXYZCoordinate(t, n) {
  let { center: o, a: a, b: s, reference: e, rotationAzimuth: c } = t
  const i = 360 * n
  const r = ellipticalDistance2D(a, s, i)
  const D = (90 - (i + c)) * Constants.DEG2RAD
  return [o.x + r * Math.cos(D), o.y + r * Math.sin(D), o.z]
}
