export const INVALID_LAYER_CLIP = {
  x: 0,
  y: 0,
  z: 0,
  width: 0,
  height: 0,
  depth: 0,
  valid: 0,
}
export const isOutsideLayerClip = function (t, n, e) {
  let o = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0
  if (e?.valid) {
    const i = t.getOLeft()
    const s = t.getORight()
    const c = t.getOTop()
    const d = t.getOBottom()
    const h = e.x
    const f = e.x + e.width
    const u = n - e.height - e.y
    const r = u + e.height
    const l = undefined
    const g = undefined
    const L = undefined
    const x = undefined
    return s > f + o || i < h - o || c < u - o || d > r + o
  }
  return false
}
export const isViewBoundsOutsideLayerClip = function (t, n, e) {
  let o = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0
  if (e?.valid) {
    const i = t.x
    const s = t.x + t.width
    const c = t.y
    const d = t.y + t.height
    const h = e.x
    const f = e.x + e.width
    const u = n - e.height - e.y
    const r = u + e.height
    const l = undefined
    const g = undefined
    const L = undefined
    const x = undefined
    return s > f + o || i < h - o || c < u - o || d > r + o
  }
  return false
}
export const DEFAULT_LAYER_STYLE = { clip: INVALID_LAYER_CLIP, flicker: false }
