import { mimeType } from '../../util/mimeType.js'
import { createHTML5Canvas } from '../HTML5Canvas.js'
class HatchedImageBuilder {
  constructor() {
    this._patterns = [HatchedImageBuilder.Pattern.BACK_SLASH]
    this._width = 10
    this._height = 10
    this._lineWidth = 1
    this._lineColor = 'rgb(64,64,64)'
    this._backgroundColor = 'rgb(192,192,192)'
  }
  build() {
    const t = Math.max(this._width, this._lineWidth + 2)
    const e = Math.max(this._height, this._lineWidth + 2)
    const i = createHTML5Canvas(t, e)
    const n = i.getContext('2d', { willReadFrequently: true })
    if (n) {
      if (
        -1 != this._patterns.indexOf(HatchedImageBuilder.Pattern.BACKGROUND)
      ) {
        n.fillStyle = this._backgroundColor
        n.fillRect(0, 0, t, e)
      }
      n.strokeStyle = this._lineColor
      n.lineWidth = this._lineWidth
      if (
        -1 != this._patterns.indexOf(HatchedImageBuilder.Pattern.BACK_SLASH)
      ) {
        n.beginPath()
        n.moveTo(-t, -e)
        n.lineTo(2 * t - 1, 2 * e - 1)
        n.moveTo(-t, 0)
        n.lineTo(t - 1, 2 * e - 1)
        n.moveTo(0, -e)
        n.lineTo(2 * t - 1, e - 1)
        n.closePath()
        n.stroke()
      }
      if (-1 != this._patterns.indexOf(HatchedImageBuilder.Pattern.SLASH)) {
        n.beginPath()
        n.moveTo(-t, e - 1)
        n.lineTo(t - 1, -e)
        n.moveTo(-t, 2 * e - 1)
        n.lineTo(2 * t - 1, -e)
        n.moveTo(0, 2 * e - 1)
        n.lineTo(2 * t - 1, 0)
        n.closePath()
        n.stroke()
      }
      if (
        -1 != this._patterns.indexOf(HatchedImageBuilder.Pattern.HORIZONTAL)
      ) {
        const i = e / 2
        n.beginPath()
        n.moveTo(0, i)
        n.lineTo(t, i)
        n.closePath()
        n.stroke()
      }
      if (-1 != this._patterns.indexOf(HatchedImageBuilder.Pattern.VERTICAL)) {
        const i = t / 2
        n.beginPath()
        n.moveTo(i, 0)
        n.lineTo(i, e)
        n.closePath()
        n.stroke()
      }
      const r = document.createElement('img')
      r.src = i.toDataURL(mimeType.png)
      return r
    } else return new HTMLImageElement()
  }
  patterns(t) {
    this._patterns = t || this._patterns
    return this
  }
  patternSize(t, e) {
    this._width = t || this._width
    this._height = e || this._height
    return this
  }
  lineWidth(t) {
    this._lineWidth = t || this._lineWidth
    return this
  }
  lineColor(t) {
    this._lineColor = t || this._lineColor
    return this
  }
  backgroundColor(t) {
    this._backgroundColor = t || this._backgroundColor
    return this
  }
}
;(function (t) {
  let e = (function (t) {
    t['HORIZONTAL'] = 'horizontal'
    t['VERTICAL'] = 'vertical'
    t['SLASH'] = 'slash'
    t['BACK_SLASH'] = 'back_slash'
    t['BACKGROUND'] = 'background'
    return t
  })({})
  t.Pattern = e
})(HatchedImageBuilder || (HatchedImageBuilder = {}))
export { HatchedImageBuilder }
