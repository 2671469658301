import { Circle } from './Circle.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
export class CircleBy3Points extends Circle {
  get type() {
    return ShapeType.CIRCLE | ShapeType.CIRCLE_BY_3_POINTS
  }
  moveFirstPoint2DToPoint(t) {
    this.moveFirstPoint2DToCoordinates(t.x, t.y)
  }
  moveSecondPoint2DToPoint(t) {
    this.moveSecondPoint2DToCoordinates(t.x, t.y)
  }
  moveThirdPoint2DToPoint(t) {
    this.moveThirdPoint2DToCoordinates(t.x, t.y)
  }
  move2DToCoordinates(t, i) {
    const e = t - this.center.x
    const o = i - this.center.y
    this.translate2D(e, o)
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    this.firstPoint.hashCode(t)
    this.secondPoint.hashCode(t)
    this.thirdPoint.hashCode(t)
  }
  toString() {
    return `CircleBy3Points[firstPoint${this.firstPoint.toString(
      true
    )}, secondPoint${this.secondPoint.toString(
      true
    )}, thirdPoint${this.thirdPoint.toString(true)}]`
  }
  equals(t) {
    if (this === t) return true
    if (!ShapeUtil.referencesEqual(this.reference, t.reference)) return false
    return (
      this.type === t.type &&
      this.firstPoint.equals(t.firstPoint) &&
      this.secondPoint.equals(t.secondPoint) &&
      this.thirdPoint.equals(t.thirdPoint)
    )
  }
}
