import { isArray, isFunction } from '../../util/Lang.js'
import { XML } from '../../util/XML.js'
import { mimeType } from '../../util/mimeType.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { Codec } from './Codec.js'
import { GMLFeatureParser } from './GMLFeatureParser.js'
const DEFAULT_REFERENCE = getReference('CRS:84')
const OPEN_GIS_GML = 'http://www.opengis.net/gml'
const OPEN_GIS_WFS = 'http://www.opengis.net/wfs'
export class GMLCodecImpl extends Codec {
  constructor() {
    let {
      swapAxes: e,
      idProvider: r,
      failOnUnsupportedGeometry: t,
      reference: n,
    } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    super()
    this._featureParser = new GMLFeatureParser({
      failOnUnsupportedGeometry: t,
      idProvider: r,
    })
    this._swapAxes = isArray(e) ? e : []
    this._userReference = n || null
  }
  decode(e) {
    const { content: r, reference: t = this._userReference } = e
    const n = XML.stringToXml(r, mimeType.xml)
    return new GMLCursor(n, t, this._swapAxes, this._featureParser)
  }
  encode(e) {
    throw new ProgrammingError('The encode method is not supported')
  }
}
function findNamespacePrefix(e, r) {
  for (const t in e.attributes)
    if (e.attributes.hasOwnProperty(t)) {
      const n = e.attributes[t]
      if (
        n &&
        n.name &&
        0 === n.name.indexOf('xmlns:') &&
        n.value &&
        0 === n.value.indexOf(r)
      )
        return n.name.split(':')[1]
    }
  return null
}
function findGMLNamespacePrefix(e) {
  return findNamespacePrefix(e, OPEN_GIS_GML)
}
class GMLCursor {
  _nextFeature = null
  constructor(e, r, t, n) {
    if (!e) throw new ProgrammingError('Invalid xml document')
    let s
    if (9 === e.nodeType) s = e.documentElement
    else s = e
    if (!s) throw new ProgrammingError('Invalid xml document')
    this._namespace = findGMLNamespacePrefix(s)
    this._featureStack = [s]
    this._selectedReference = r || findReference(s) || DEFAULT_REFERENCE
    this._swapAxes = t
    this._featureParser = n
    this._featureParser.setGMLNamespace(this._namespace)
    this.prepareNext()
  }
  primeNext(e) {
    while (e) {
      if (shouldExplore(e)) this._featureStack.push(e)
      if (isFeature(e)) break
      e = e.nextSibling
    }
  }
  prepareNext() {
    this._nextFeature = null
    while (
      this._featureStack.length > 0 &&
      !isFeature(this._featureStack[this._featureStack.length - 1])
    ) {
      const e = this._featureStack.pop()
      this.primeNext(e.firstChild)
    }
    if (0 === this._featureStack.length) return false
    const e = this._featureStack.pop()
    this.primeNext(e.nextSibling)
    let r = e
    if (XML.matchesName(e, 'member', OPEN_GIS_WFS) && e.firstElementChild)
      r = e.firstElementChild
    const t = this.parseFeature(r)
    if (t) {
      this._nextFeature = t
      return true
    } else this.prepareNext()
    return false
  }
  hasNext() {
    return null !== this._nextFeature
  }
  next() {
    if (!this._nextFeature)
      throw new ProgrammingError('No more features in cursor')
    const e = this._nextFeature
    this.prepareNext()
    return e
  }
  parseFeature(e) {
    return this._featureParser.decodeFeature(
      e,
      this._selectedReference,
      this._swapAxes
    )
  }
}
const NodeType = { ELEMENT: 1, TEXT: 3, CDATA: 4 }
function isFeature(e) {
  return (
    XML.matchesName(e, 'member', OPEN_GIS_WFS) ||
    XML.matchesName(e, 'featureMember', OPEN_GIS_GML) ||
    (((e.parentElement &&
      XML.matchesName(e.parentElement, 'featureMembers', OPEN_GIS_GML)) ||
      (e.parentNode &&
        XML.matchesName(e.parentNode, 'featureMembers', OPEN_GIS_GML))) &&
      !XML.isTextDOM(e))
  )
}
function shouldExplore(e) {
  return !(e.nodeType === NodeType.TEXT || e.nodeType === NodeType.CDATA)
}
function findReference(e) {
  const r = [e]
  let t = null
  while (r.length) {
    const e = r.pop()
    if (isFunction(e.getAttribute)) {
      const r = e.getAttribute('srsName')
      if (r) {
        t = resolveReference(r)
        if (!t)
          throw new ProgrammingError(
            `Reference '${r}' is unsupported by default. ` +
              'Please register the reference first using ReferenceProvider API (addReference, parseWellKnownText)'
          )
        break
      }
    }
    if (e.nextSibling) r.push(e.nextSibling)
    if (e.firstChild) r.push(e.firstChild)
  }
  return t
}
function resolveReference(e) {
  let r = null
  try {
    r = getReference(e)
  } catch (e) {}
  if (!r) {
    const t = e.indexOf('#')
    if (t > -1)
      try {
        r = getReference('EPSG:' + e.substr(t + 1))
      } catch (e) {}
  }
  return r
}
