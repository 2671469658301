class Entry {
  _next = null
  _previous = null
  constructor(e, t) {
    this._key = e
    this._value = t
  }
  get key() {
    return this._key
  }
  get value() {
    return this._value
  }
  set value(e) {
    this._value = e
  }
  get next() {
    return this._next
  }
  set next(e) {
    this._next = e
  }
  get previous() {
    return this._previous
  }
  set previous(e) {
    this._previous = e
  }
}
export class LRUCache {
  _head = null
  _tail = null
  _onRemove = null
  constructor(e) {
    this._index = new Map()
    this._size = 0
    this._maxSize = e
  }
  set onRemove(e) {
    this._onRemove = e
  }
  put(e, t) {
    let i = this._index.get(e)
    if (i) i.value = t
    else {
      i = new Entry(e, t)
      this._index.set(e, i)
      this._size++
    }
    this.moveToHead(i)
    this.prune()
  }
  get(e) {
    const t = this._index.get(e)
    if (t) {
      this.moveToHead(t)
      return t.value
    } else return
  }
  clear() {
    let e = this._head
    while (e) {
      this.triggerOnRemove(e)
      e = e.next
    }
    this._head = null
    this._tail = null
    this._size = 0
    this._index = new Map()
  }
  validate() {
    let e = this._head
    while (e) e = e.next
  }
  toString() {
    let e = 'LRUCache('
    let t = this._head
    while (t) {
      e += t.key + ':' + t.value
      t = t.next
      if (t) e += ', '
    }
    e += ')'
    return e
  }
  moveToHead(e) {
    if (this._head === e) return
    const t = e.previous
    const i = e.next
    if (t) t.next = i
    if (i) i.previous = t
    e.previous = null
    const s = this._head
    e.next = s
    if (s) s.previous = e
    this._head = e
    if (null === this._tail) this._tail = e
    else if (this._tail === e) this._tail = t
  }
  prune() {
    while (null !== this._tail && this._size > this._maxSize) {
      const e = this._tail
      this._index.delete(e.key)
      this.triggerOnRemove(e)
      const t = e.previous
      if (t) t.next = null
      this._tail = t
      this._size--
    }
    if (null === this._tail) this._head = null
  }
  triggerOnRemove(e) {
    if (this._onRemove) this._onRemove(e.key, e.value)
  }
}
