import { isDefined, isNumber, isString } from '../util/Lang.js'
import { parse, stringify } from '../util/JSON.js'
import { Constants } from '../util/Constants.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { createEllipsoidalGeodesy } from '../geodesy/GeodesyFactory.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { getReference } from '../reference/ReferenceProvider.js'
import { CameraFactory } from './CameraFactory.js'
import { distance3D } from '../util/Cartesian.js'
import { OrthographicCamera } from './camera/OrthographicCamera.js'
const LLH_REF = getReference('CRS:84')
const REFERENCE_2D_FOR_3D_MAPSTATE = getReference('EPSG:32663')
const GEOCENTRIC_REFERENCE = getReference('EPSG:4978')
function is3D(e) {
  return e.equals(GEOCENTRIC_REFERENCE)
}
function createMatchedCamera(e, t, r, n, o, i, a, c) {
  const s = createTransformation(LLH_REF, o)
  let f
  try {
    f = s.transform(r)
  } catch (e) {
    throw new Error(
      'Could not match camera state. ' +
        'The reference point of the camera to match is out of projection bounds.'
    )
  }
  let l = c.createCameraForReference(o, i, a)
  if (!l)
    throw new Error(
      'Could not match camera state.' +
        'Can not create a camera for the target reference.'
    )
  let m
  if (is3D(o)) {
    let e = l
    const r = 1e3
    let o = e.asLookAt(r)
    o.ref = f
    o.distance = r
    o.yaw = n
    o.pitch = -89
    o.roll = 0
    e = e.lookAt(o)
    m = getReferenceDistance(e, f)
    if (-1 !== m) {
      const i = r * (t / m)
      o = e.asLookAt(i)
      o.ref = f
      o.distance = r * (t / m)
      o.yaw = n
      o.pitch = -89
      e = e.lookAt(o)
    } else
      throw new Error(
        'Could not match camera state. Could not determine reference distance (center of the view' +
          'does not touch the globe?).'
      )
    l = e
  } else {
    let r = l
    let o = r.asLook2D()
    o.worldOrigin = f
    o.viewOrigin = createPoint(null, [r.width / 2, r.height / 2])
    o.scaleX = 0.1
    o.scaleY = 0.1
    r = r.look2D(o)
    m = getReferenceDistance(r, f)
    if (-1 !== m) {
      o.scaleX = o.scaleX * (m / t)
      o.scaleY = o.scaleY * (m / t)
    } else
      throw new Error(
        'Could not match camera state. Failed to determine target scale.'
      )
    r = r.look2D(o)
    const i = undefined
    const a = getReferencePointAndViewAngleToNorth(e, r).viewAngleToNorth - n
    o = r.asLook2D()
    o.rotation -= a
    r = r.look2D(o)
    l = r
  }
  return l
}
export function createOrthographicCamera(e, t) {
  const r = e.getScaleAt(t)
  const n = e.width / r
  const o = e.height / r
  return new OrthographicCamera(
    e.eye,
    e.forward,
    e.up,
    -e.far,
    e.far,
    e.width,
    e.height,
    n,
    o,
    e.worldReference
  )
}
function getReferenceDistance(e, t) {
  const r = e.toView(t).z
  const n = e.height / 2
  const o = createPoint(null, [e.width / 2, n, r])
  const i = createTransformation(e.worldReference, LLH_REF)
  let a = e.toWorldPoint(o)
  const c = i.transform(a)
  let s = 0.8 * n
  const f = createPoint(null, [o.x, o.y + s, r])
  let l
  try {
    a = e.toWorldPoint(f)
    l = i.transform(a)
  } catch (t) {
    try {
      s = 5
      const t = createPoint(null, [o.x, o.y + s, r])
      a = e.toWorldPoint(t)
      l = i.transform(a)
    } catch (e) {
      throw new Error(
        'Can not find reference distance of camera.' +
          'Can not transform view points near center of view to geodetic reference.'
      )
    }
  }
  const m = undefined
  return createEllipsoidalGeodesy(LLH_REF).distance(c, l) / s
}
function getReferencePointAndViewAngleToNorth(e, t) {
  const r = createTransformation(t.worldReference, LLH_REF)
  try {
    if (is3D(t.worldReference)) {
      const n = createPoint(null, [t.width / 2, t.height / 2])
      const o = e.viewToMapTransformation.transform(n)
      const i = distance3D(o, t.eye)
      const a = t.asLookAt(i)
      return {
        referencePoint: r.transform(o),
        referencePointWorld: o,
        viewAngleToNorth: a.yaw,
      }
    }
    const n = createPoint(null, [t.width / 2, t.height / 2])
    const o = t.toWorldPoint(n)
    o.z = 0
    const i = r.transform(o)
    const a = createEllipsoidalGeodesy(LLH_REF)
    const c = 1e3
    const s = 0
    const f = a.interpolate(i, c, s)
    const l = r.inverseTransformation.transform(f)
    const m = t.toViewPoint(l)
    const g = undefined
    return {
      referencePoint: i,
      referencePointWorld: o,
      viewAngleToNorth:
        -(Math.atan2(m.y - n.y, m.x - n.x) + Math.PI / 2) * Constants.RAD2DEG,
    }
  } catch (e) {
    OutOfBoundsError.isOrThrow(e)
    const t = createPoint(LLH_REF, [0, 0])
    return {
      referencePoint: t,
      referencePointWorld: r.inverseTransformation.transform(t),
      viewAngleToNorth: 0,
    }
  }
}
function isLegacy2DMapStateInternal(e) {
  return '2d' === e.type
}
function isLegacy3DMapStateInternal(e) {
  return '3d' === e.type
}
function isAnotherLegacyMapState(e) {
  const t = e
  const r = !!t.transformation2D && isLegacyTransformation2D(t.transformation2D)
  const n = !!t.transformation3D && isLegacyTransformation3D(t.transformation3D)
  return r || n
}
function isLegacyMapState(e) {
  return (
    isLegacy2DMapStateInternal(e) ||
    isLegacy3DMapStateInternal(e) ||
    isAnotherLegacyMapState(e)
  )
}
function isLegacyTransformation3D(e) {
  return isDefined(e.distance)
}
function isLegacyTransformation2D(e) {
  return isNumber(e.scale)
}
function convertLegacy2DTransToNew(e) {
  if (!e) return null
  const { viewOrigin: t, worldOrigin: r, rotation: n } = e
  let o
  if (isNumber(e.scale)) o = [e.scale, e.scale]
  else o = e.scale
  return {
    viewOrigin: [t[0], t[1]],
    worldOrigin: [r[0], r[1]],
    scale: o,
    rotation: n,
  }
}
function convertLegacy3DTransToNew(e, t, r, n) {
  if (!e) return null
  const o = isString(r) ? getReference(r) : r
  let i = n.createPerspectiveCamera(o, t[0], t[1])
  const a = i.asLookAt(e.distance)
  a.ref.x = e.referencePoint.x
  a.ref.y = e.referencePoint.y
  a.ref.z = e.referencePoint.z
  a.distance = e.distance
  a.yaw = e.yaw
  a.pitch = e.pitch
  a.roll = e.roll
  i = i.lookAt(a)
  const c = i.asLookFrom()
  return {
    eyePointX: c.eye.x,
    eyePointY: c.eye.y,
    eyePointZ: c.eye.z,
    yaw: c.yaw,
    pitch: c.pitch,
    roll: c.roll,
  }
}
function convertLegacyToNew(e, t, r) {
  let n = null
  let o = null
  let i = [0, 0]
  const a = e.reference
  if (isLegacy2DMapStateInternal(e)) {
    n = convertLegacy2DTransToNew(e.transformation)
    i = [
      n ? 2 * n.viewOrigin[0] : t.getViewWidth(),
      n ? 2 * n.viewOrigin[1] : t.getViewHeight(),
    ]
  } else if (isAnotherLegacyMapState(e)) {
    i = e.viewSize
    n = convertLegacy2DTransToNew(e.transformation2D)
    o = convertLegacy3DTransToNew(e.transformation3D, i, a, r)
  } else if (isLegacy3DMapStateInternal(e)) {
    if (!t.is3D())
      throw new Error(
        'Cannot restore legacy 3D states on a 2D map. If you want to restore 3D state on a 2D map, ' +
          'update your state object using Map.saveState() with the current RIA version.'
      )
    i = [t.viewSize[0], t.viewSize[1]]
    o = isLegacyTransformation3D(e.transformation)
      ? convertLegacy3DTransToNew(e.transformation, i, a, r)
      : e.transformation
  }
  return { reference: a, transformation2D: n, transformation3D: o, viewSize: i }
}
export function deserializeMapState(e, t, r) {
  const n = parse(isString(e) ? e : stringify(e))
  const o = isString(n.reference) ? getReference(n.reference) : t.reference
  const i = isLegacyMapState(n) ? convertLegacyToNew(n, t, r) : n
  const a = is3D(o)
  const c = i.viewSize[0]
  const s = i.viewSize[1]
  if (a && is3D(t.reference))
    return deserializeCamera3D(i.transformation3D, o, c, s, r)
  return deserializeCamera2D(
    i.transformation2D,
    a ? REFERENCE_2D_FOR_3D_MAPSTATE : o,
    c,
    s,
    r
  )
}
export function deserializeCamera3D(e, t, r, n, o) {
  if (!e) return null
  t = isString(t) ? getReference(t) : t
  let i = o.createPerspectiveCamera(t, r, n)
  const a = i.asLookFrom()
  a.eye.x = e.eyePointX
  a.eye.y = e.eyePointY
  a.eye.z = e.eyePointZ
  a.yaw = e.yaw
  a.pitch = e.pitch
  a.roll = e.roll
  i = i.lookFrom(a)
  return i
}
export function deserializeCamera2D(e, t, r, n, o) {
  if (!e) return null
  t = isString(t) ? getReference(t) : t
  let i = o.createCameraForReference(t, r, n)
  const a = e.scale
  const c = i.asLook2D()
  c.worldOrigin = createPoint(t, e.worldOrigin)
  c.viewOrigin = createPoint(null, e.viewOrigin)
  c.scaleX = a[0]
  c.scaleY = a[1]
  c.rotation = e.rotation
  i = i.look2D(c)
  return i
}
export function findMatchingCameraPosition(e, t, r, n, o, i) {
  const a =
    is3D((r = isString(r) ? getReference(r) : r)) ||
    (t.width === n && t.height === o)
  if (t.worldReference.equals(r) && a)
    return t.copyAndSet({ width: n, height: o })
  const c = getReferencePointAndViewAngleToNorth(e, t)
  const s = undefined
  return createMatchedCamera(
    e,
    getReferenceDistance(t, c.referencePointWorld),
    c.referencePoint,
    c.viewAngleToNorth,
    r,
    n || t.width,
    o || t.height,
    i
  )
}
export function serializeMapState(e) {
  const t = is3D(e.reference)
  const r = new CameraFactory(e)
  const n = t ? REFERENCE_2D_FOR_3D_MAPSTATE : e.reference
  const o = findMatchingCameraPosition(
    e,
    e.camera,
    n,
    e.viewSize[0],
    e.viewSize[1],
    r
  )
  const i = getReference('EPSG:4978')
  const a = t
    ? findMatchingCameraPosition(
        e,
        e.camera,
        i,
        e.viewSize[0],
        e.viewSize[1],
        r
      )
    : null
  return {
    reference: e.reference.identifier,
    viewSize: [e.viewSize[0], e.viewSize[1]],
    transformation2D: serializeCamera(o),
    transformation3D: serializeCamera(a),
  }
}
export function serializeCamera(e) {
  if (!e) return null
  if (is3D(e.worldReference)) {
    const t = e.asLookFrom()
    return {
      eyePointX: t.eye.x,
      eyePointY: t.eye.y,
      eyePointZ: t.eye.z,
      yaw: t.yaw,
      pitch: t.pitch,
      roll: t.roll,
    }
  }
  const t = e.asLook2D()
  return {
    worldOrigin: [t.worldOrigin.x, t.worldOrigin.y],
    viewOrigin: [t.viewOrigin.x, t.viewOrigin.y],
    scale: [t.scaleX, t.scaleY],
    rotation: t.rotation,
  }
}
