import { Photon } from '../../gen/photon/photon_painter.js'
function ExpressionValidator(r) {
  this._expressionResolver = Object.assign({}, r)
  var e = this
  this._expressionResolver.icon = function () {
    var r
    try {
      r = e._createDummyImage()
      return Photon.Expression.icon(r, 0, 0, 1, 1, 0, 0, 0, 0, 0)
    } finally {
      if (r) r.release()
    }
  }
}
ExpressionValidator.prototype = Object.create(Object.prototype)
ExpressionValidator.prototype.constructor = ExpressionValidator
ExpressionValidator.prototype._createDummyImage = function () {
  var r
  try {
    r = Photon.BufferFactory.createUint8BufferFromData(
      new Uint8Array([0, 0, 0, 0])
    )
    return Photon.Image.create(1, 1, true, Photon.PixelFormat.Rgba8888, r)
  } finally {
    if (r) r.release()
  }
}
ExpressionValidator.prototype.getErrorMessageForColorExpression = function (r) {
  return this._getErrorMessage(r, function (r) {
    return Photon.Expression.getErrorMessageForColorExpression(r)
  })
}
ExpressionValidator.prototype.getErrorMessageForVisibilityExpression =
  function (r) {
    return this._getErrorMessage(r, function (r) {
      return Photon.Expression.getErrorMessageForVisibilityExpression(r)
    })
  }
ExpressionValidator.prototype.getErrorMessageForDisplacementExpression =
  function (r) {
    return this._getErrorMessage(r, function (r) {
      return Photon.Expression.getErrorMessageForDisplacementExpression(r)
    })
  }
ExpressionValidator.prototype.getErrorMessageForIconExpression = function (r) {
  return this._getErrorMessage(r, function (r) {
    return Photon.Expression.getErrorMessageForIconExpression(r)
  })
}
ExpressionValidator.prototype.getErrorMessageForScaleExpression = function (r) {
  return this._getErrorMessage(r, function (r) {
    return Photon.Expression.getErrorMessageForScaleExpression(r)
  })
}
ExpressionValidator.prototype._getErrorMessage = function (r, e) {
  var o
  try {
    return e((o = this._expressionResolver.resolve(r)))
  } finally {
    if (o) o.release()
  }
}
export { ExpressionValidator }
