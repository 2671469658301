import { ShapeList } from '../../shape/ShapeList.js'
import { ShapeType } from '../../shape/ShapeType.js'
import { CGOperatorShapeInteraction } from './CGOperatorShapeInteraction.js'
import { CGVertex } from './CGVertex.js'
import { CGSubBounds } from './CGSubBounds.js'
function prepareShapes(e, n, t, o) {
  var s = n.toleranceEqualPoints
  CGSubBounds.calculate(t, o, n)
  operate(t, o, n)
  t.resetBounds()
  o.resetBounds()
  n.toleranceEqualPoints = 1e-8
  CGSubBounds.calculate(t, o, n)
  CGOperatorShapeInteraction.operate(t, o, n, e)
  n.toleranceEqualPoints = s
}
function insertVertexAfter(e, n) {
  if (null !== e.next && null !== e.next.bundleStar)
    e.next.bundleStar.onSegmentSplit(e, n)
  n.next = e.next
  if (null !== n.next) n.next.previous = n
  e.next = n
  n.previous = e
  n.pointLabel.locationOn[n.shapeIndex] = e.pointLabel.locationOn[e.shapeIndex]
  n.segmentLabel.locationLeft[n.shapeIndex] =
    e.segmentLabel.locationLeft[e.shapeIndex]
  n.segmentLabel.locationOn[n.shapeIndex] =
    e.segmentLabel.locationOn[e.shapeIndex]
  n.segmentLabel.locationRight[n.shapeIndex] =
    e.segmentLabel.locationRight[e.shapeIndex]
  n.subBounds = e.subBounds.copy()
}
function appendPointIfNotEqual(e, n) {
  var t
  if (!(n.length > 0 && pointsEqual(e, n[n.length - 1]))) appendPoint(e, n)
}
function appendPoint(e, n) {
  n.push(e)
}
function pointsEqual(e, n) {
  return e.x === n.x && e.y === n.y
}
function getNumPoints(e) {
  var n = 0
  var t = true
  for (var o = e; null !== o && (t || o !== e); o = o.next) {
    t = false
    n++
  }
  return n
}
function getNumPointsShape(e) {
  var n = 0
  for (var t = 0; t < e.elements.length; t++) n += getNumPoints(e.elements[t])
  return n
}
function deepCopy(e) {
  if (ShapeType.contains(ShapeType.SHAPE_LIST, e.type)) {
    var n = new ShapeList(e.reference, [])
    var t = []
    for (var o = 0; o < e.shapeCount; o++) {
      t[o] = deepCopy(e.getShape(o))
      n.addShape(t[o])
    }
    return n
  } else return e.copy()
}
function preprocessTolerance(e, n, t) {
  if (0 === e.elements.length || 0 === n.elements.length) return
  var o = n.getBounds(t)
  var s = e.elements[0].pos.copy()
  s.move2DToCoordinates(0, 0)
  for (var l = 0; l < e.elements.length; l++) {
    var r = true
    for (
      var a = e.elements[l];
      null !== a && (r || a != e.elements[l]);
      a = a.next
    ) {
      r = false
      if (boundsDontIntersectSegment(o, a, t)) continue
      var i = false
      var u = null
      var p = t.toleranceEqualPoints
      var f = t.toleranceEqualPoints
      for (var m = 0; m < n.elements.length; m++) {
        var c = true
        for (
          var v = n.elements[m];
          null !== v && (c || v !== n.elements[m]);
          v = v.next
        ) {
          c = false
          if (!a.subBounds.interacts(v.subBounds)) {
            var x = v.subBounds.nextDiff
            if (null !== x) v = x.previous
            continue
          }
          var h = Number.POSITIVE_INFINITY
          if (t.pointsEqual(a.pos, v.pos))
            h = t.distancePointPoint(a.pos, v.pos)
          if (h < p) {
            p = h
            u = v
            i = false
            if (
              null !== v.next &&
              !t.certainlyNoInteraction3Points(v.pos, v.next.pos, a.pos) &&
              t.isPointOnLineSegment(v.pos, v.next.pos, a.pos)
            ) {
              var d
              if (
                (d = t.closestPointOnLineSegment(v.pos, v.next.pos, a.pos, s)) <
                f
              )
                f = d
            }
            if (
              null !== v.previous &&
              !t.certainlyNoInteraction3Points(v.pos, v.previous.pos, a.pos) &&
              t.isPointOnLineSegment(v.pos, v.previous.pos, a.pos)
            ) {
              var d
              if (
                (d = t.closestPointOnLineSegment(
                  v.pos,
                  v.previous.pos,
                  a.pos,
                  s
                )) < f
              )
                f = d
            }
          } else if (
            null !== v.next &&
            !t.pointsEqual(a.pos, v.next.pos) &&
            !t.certainlyNoInteraction3Points(v.pos, v.next.pos, a.pos) &&
            t.isPointOnLineSegment(v.pos, v.next.pos, a.pos)
          )
            if (
              (h = t.closestPointOnLineSegment(v.pos, v.next.pos, a.pos, s)) <
                p &&
              h < f
            ) {
              p = f = h
              u = v
              i = true
            }
        }
      }
      if (null !== u)
        if (i) {
          var g
          insertVertexAfter(u, new CGVertex(u.shapeIndex, a.pos))
        } else a.moveTo(u.pos)
    }
  }
}
function removeTooSmallAreas(e, n) {
  for (var t = 0; t < e.elements.length; t++) {
    if (null === e.elements[t].next) continue
    var o = true
    var s = true
    for (
      var l = e.elements[t];
      null !== l && (s || l != e.elements[t]);
      l = l.next
    ) {
      s = false
      var r
      if (!n.pointsEqual(l.pos, e.elements[t].pos)) {
        o = false
        break
      }
    }
    if (o) {
      e.elements.splice(t, 1)
      t--
    }
  }
}
function removeCollapsedSegments(e) {
  var n = []
  for (var t = 0; t < e.elements.length; t++) {
    if (null === e.elements[t].next) continue
    var o = true
    for (
      var s = e.elements[t];
      null !== s && (o || s != e.elements[t]);
      s = s.next
    ) {
      o = false
      if (null == s.next || null == s.previous) continue
      if (pointsEqual(s.next.pos, s.previous.pos)) {
        var l = s
        var r = s.next
        var a = s.previous
        while (true) {
          var i = r.next
          var u = a.previous
          if (null !== i && null !== u && r !== l && a !== l && r !== a) {
            if (pointsEqual(i.pos, u.pos)) {
              r = i
              a = u
              continue
            }
            break
          }
          break
        }
        if (r == a) {
          e.elements[t] = null
          a.previous = null
          l.next = null
          if (null !== a.next) n.push(a)
        } else {
          if (null !== a.previous) a.previous.next = r
          r.previous = a.previous
          var p = a
          while (p !== r) {
            if (e.elements[t] === p) {
              e.elements[t] = r
              o = true
              break
            }
            p = p.next
          }
          a.previous = null
          l.next = null
          var f = a
          while (null !== f) {
            f.setSegmentLocationsOfThis(true, false, false)
            f = f.next
          }
          if (null !== a.next) n.push(a)
        }
        s = r
      }
    }
    if (
      null === e.elements[t] ||
      null === e.elements[t].next ||
      e.elements[t].next === e.elements[t]
    ) {
      e.elements.splice(t, 1)
      t--
    }
  }
  for (var t = 0; t < n.length; t++) {
    var s = n[t]
    e.elements.push(s)
  }
}
function removeCollapsedSegments2(e) {
  for (var n = 0; n < e.elements.length; n++) {
    if (null === e.elements[n].next) continue
    if (!e.elements[n].isFilled()) continue
    var t = true
    for (
      var o = e.elements[n];
      null !== o && (t || o !== e.elements[n]);
      o = o.next
    ) {
      t = false
      if (null === o.next) continue
      for (var s = o.next; null !== s && s !== e.elements[n]; s = s.next) {
        if (!o.subBounds.interacts(s.subBounds)) continue
        if (null === s.next) continue
        var l = false
        if (pointsEqual(o.pos, s.pos) && pointsEqual(o.next.pos, s.next.pos))
          l = true
        if (pointsEqual(o.next.pos, s.pos) && pointsEqual(o.pos, s.next.pos))
          l = true
        if (l) {
          o.setSegmentLocationsOfThis(true, false, false)
          s.setSegmentLocationsOfThis(true, false, false)
        }
      }
    }
  }
}
function removeDuplicatePoints(e) {
  for (var n = 0; n < e.elements.length; n++) {
    if (null === e.elements[n].next) continue
    var t = true
    for (
      var o = e.elements[n];
      null !== o && (t || o != e.elements[n]);
      o = o.next
    ) {
      t = false
      if (null === o.next) continue
      while (true) {
        if (!pointsEqual(o.pos, o.next.pos)) break
        var s = o.next
        if (s == e.elements[n]) e.elements[n] = s.next
        o.next = o.next.next
        if (null === o.next) {
          o = null
          break
        } else if (o.next === o) {
          e.elements.splice(n, 1)
          n--
          o = null
          break
        } else o.next.previous = o
      }
      if (null == o) break
    }
  }
}
function operate(e, n, t) {
  removeTooSmallAreas(e, t)
  removeTooSmallAreas(n, t)
  var o = e.getBounds(t)
  var s = n.getBounds(t)
  if (null !== o && null !== s) {
    var l = o.copy()
    var r = s.copy()
    t.growBounds(t.toleranceEqualPoints, l)
    t.growBounds(t.toleranceEqualPoints, r)
    if (l.interacts2D(r)) {
      preprocessTolerance(e, n, t)
      preprocessTolerance(n, e, t)
    }
  } else {
    preprocessTolerance(e, n, t)
    preprocessTolerance(n, e, t)
  }
  removeTooSmallAreas(e, t)
  removeTooSmallAreas(n, t)
  removeDuplicatePoints(e)
  removeDuplicatePoints(n)
  removeCollapsedSegments(e)
  removeCollapsedSegments(n)
  removeCollapsedSegments2(e)
  removeCollapsedSegments2(n)
}
function boundsDontIntersectSegment(e, n, t) {
  return t.boundsDontIntersectSegment(
    e,
    n.pos,
    null === n.next ? null : n.next.pos
  )
}
export var CGUtil = {
  prepareShapes: prepareShapes,
  insertVertexAfter: insertVertexAfter,
  appendPointIfNotEqual: appendPointIfNotEqual,
  pointsEqual: pointsEqual,
  getNumPoints: getNumPoints,
  getNumPointsShape: getNumPointsShape,
  deepCopy: deepCopy,
  removeDuplicatePoints: removeDuplicatePoints,
  operate: operate,
  boundsDontIntersectSegment: boundsDontIntersectSegment,
}
