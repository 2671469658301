import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
function precision(e) {
  return (t) => t.toPrecision(e)
}
export class Bounds extends Shape {
  hashCode(e) {
    e.appendUInt32(this.type)
    e.appendDouble(this.x)
    e.appendDouble(this.y)
    e.appendDouble(this.z)
    e.appendDouble(this.width)
    e.appendDouble(this.height)
    e.appendDouble(this.depth)
  }
  get type() {
    return ShapeType.BOUNDS
  }
  toString() {
    return [this.x, this.width, this.height, this.height]
      .map(precision(5))
      .join(',')
  }
  move2D(e, t) {
    if ('undefined' === typeof t) this.move2DToPoint(e)
    else this.move2DToCoordinates(e, t)
  }
  move2DToPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    this.move2DToCoordinates(e.x, e.y)
  }
  move3D(e, t, i) {
    if ('undefined' === typeof t) this.move3DToPoint(e)
    else if (i) this.move3DToCoordinates(e, t, i)
  }
  move3DToPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    this.setTo3D(e.x, this.width, e.y, this.height, e.z, this.depth)
  }
  translate(e, t, i) {
    if (i) this.translate3D(e, t, i)
    else this.translate2D(e, t)
  }
  contains3D(e) {
    if (e instanceof Bounds) return this.contains3DBounds(e)
    else return this.contains3DPoint(e)
  }
  contains3DPoint(e) {
    return super.contains3DPoint(e)
  }
  contains2DBounds(e) {
    this._compareReference(e.reference, e.coordinateType)
    const t = e.x,
      i = e.y,
      n = e.width,
      o = e.height,
      s = this.x,
      h = this.y
    return (
      s <= t && s + this.width >= t + n && h <= i && h + this.height >= i + o
    )
  }
  contains2DBoundsWithEps(e, t) {
    this._compareReference(e.reference, e.coordinateType)
    const i = e.x,
      n = e.y,
      o = e.width,
      s = e.height,
      h = this.x,
      r = this.y
    return (
      h <= i + t &&
      h + this.width >= i + o - t &&
      r <= n + t &&
      r + this.height >= n + s - t
    )
  }
  enlarge2D(e) {
    const t = this.width
    const i = this.height
    const n = t * e
    const o = i * e
    const s = (n - t) / 2
    const h = (o - i) / 2
    this.setTo2D(this.x - s, n, this.y - h, o)
    return this
  }
  invalidate() {}
}
export function isBounds(e) {
  return ShapeType.contains(ShapeType.BOUNDS, e.type)
}
