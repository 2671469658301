import { CoordinateType } from '../reference/CoordinateType.js'
import { lonLatContainedOnSegment2D } from '../geodesy/EllipsoidUtil.js'
import { LLHBounds } from './LLHBounds.js'
import { LLHPoint } from './LLHPoint.js'
import { calculate3DPolylineBounds } from './GeodesicBoundsUtil.js'
import { simplePointCreate } from './SimplePoint.js'
import {
  calculate2DCoordinateAtFractionOnLine,
  onSomeSegments,
  Polyline,
} from './Polyline.js'
import { validatePointRepresentationForCreations } from './ShapeWithPointList.js'
import {
  pointLLH_equalLonLat,
  normalizeLon,
  normalizeLat,
} from '../util/LonLatCoord.js'
import { isNumber } from '../util/Lang.js'
export class LLHPolyline extends Polyline {
  constructor(e, t) {
    super()
    this._reference = this.validateReference(e)
    this._coordinates = validatePointRepresentationForCreations(t, this)
  }
  get isGeodetic() {
    return true
  }
  copy() {
    return new LLHPolyline(this._reference, this._coordinates)
  }
  calculateInterpolationPoint(e) {
    const t = this.getSimplePoints()
    if (0 === t.length) return null
    const o = this.reference.geodeticDatum.ellipsoid
    if (!o) return super.getPoint(0)
    const n = calculate2DCoordinateAtFractionOnLine(
      e,
      t,
      (e, t) => o.geodesicDistance(e, t),
      (e, t, n) => {
        const i = simplePointCreate(0, 0)
        o.geodesicPositionAtFractionSFCT(e, t, n, i)
        return i
      }
    )
    return n ? new LLHPoint(this.reference, [n.x, n.y, n.z]) : null
  }
  lineContainsXY(e, t, o) {
    const n = this.reference.geodeticDatum.ellipsoid
    return onSomeSegments(this._coordinates, (e, i) =>
      lonLatContainedOnSegment2D(n, e[0], e[1], i[0], i[1], t, o)
    )
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  get bounds() {
    if (0 === this.pointCount) return null
    if (!this._bounds) {
      const e = new LLHBounds(this.reference, [0, 0, 0, 0, 0, 0])
      const t = this.reference.geodeticDatum.ellipsoid
      calculate3DPolylineBounds(this, t, e)
      return e
    }
    return this._bounds
  }
  contains2DCoordinates(e, t) {
    const o = this.pointCount
    if (o > 0) {
      const n = this.getPointCoordinates(0)
      let i = pointLLH_equalLonLat(n[0], n[1], e, t)
      let r = 1
      while (!i && r < o) {
        const o = this.getPointCoordinates(r - 1)
        const n = this.getPointCoordinates(r)
        i = lonLatContainedOnSegment2D(
          this.reference.geodeticDatum.ellipsoid,
          o[0],
          o[1],
          n[0],
          n[1],
          e,
          t
        )
        r++
      }
      return i
    }
    return false
  }
  implMove(e, t, o, n) {
    e[0] = normalizeLon(t)
    e[1] = normalizeLat(o)
    if (isNumber(n)) e[2] = n
  }
  implTranslate(e, t, o) {
    let n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0
    e[0] = normalizeLon(e[0] + t)
    e[1] = normalizeLat(e[1] + o)
    e[2] += n
  }
}
