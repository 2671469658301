import { Sector } from './Sector.js'
import { LLHArcBandUtil } from './LLHArcBandUtil.js'
export class LLHSector extends Sector {
  constructor(t, r, e, i, n) {
    super(t, r, e, i, n)
    this.validateReference(t)
  }
  get isGeodetic() {
    return true
  }
  copy() {
    return new LLHSector(
      this.reference,
      this.center.copy(),
      this.radius,
      this.startAzimuth,
      this.sweepAngle
    )
  }
  get coordinateType() {
    return LLHArcBandUtil.coordinateType
  }
  distance2D(t, r) {
    return LLHArcBandUtil.distance2D(t, r, this)
  }
  forwardAzimuth2D(t, r) {
    return LLHArcBandUtil.forwardAzimuth2D(t, r, this)
  }
  calculatePoint(t, r) {
    return LLHArcBandUtil.calculatePoint(t, r, this)
  }
  contains2DCoordinates(t, r) {
    return LLHArcBandUtil.contains2DCoordinates(t, r, this)
  }
  calculateBounds() {
    return LLHArcBandUtil.calculateBounds(this)
  }
}
