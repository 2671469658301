import { normalizeLon } from '../util/LonLatCoord.js'
const STEPS_COUNT = 30
export class Lon2GridDiscretizer {
  constructor() {
    this._stepsCount = 0
    this._lon = 0
    this._lat = 0
    this._increment = 0
    this._currentLeftStep = 0
    this._currentRightStep = 0
  }
  init(t, e, i) {
    let r
    this._stepsCount = i || STEPS_COUNT
    this._lon = e[0]
    this._lat = t
    r = e[1] - e[0]
    if (r < 0) r += 360
    this._increment = (0.5 * r) / this._stepsCount
    this._currentLeftStep = 0
    this._currentRightStep = 2 * this._stepsCount
  }
  getBeginPointSFCT(t) {
    let e
    if (this._currentLeftStep <= this._stepsCount) {
      e = normalizeLon(this._lon + this._currentLeftStep * this._increment)
      this._currentLeftStep++
      t.x = e
      t.y = this._lat
      if ('number' === typeof e && 'number' === typeof this._lat) return true
      else return false
    }
    return false
  }
  getEndPointSFCT(t) {
    let e
    if (
      this._currentRightStep >= 0 &&
      this._currentRightStep > this._currentLeftStep
    ) {
      e = normalizeLon(this._lon + this._currentRightStep * this._increment)
      this._currentRightStep--
      t.x = e
      t.y = this._lat
      return true
    }
    return false
  }
}
export class Lat2GridDiscretizer {
  constructor() {
    this._stepsCount = 0
    this._lon = 0
    this._lat = 0
    this._increment = 0
    this._currentLeftStep = 0
    this._currentRightStep = 0
  }
  init(t, e, i) {
    this._stepsCount = i || STEPS_COUNT
    this._lon = t
    this._lat = e[0]
    this._increment = (0.5 * (e[1] - e[0])) / this._stepsCount
    this._currentLeftStep = 0
    this._currentRightStep = 2 * this._stepsCount
  }
  getBeginPointSFCT(t) {
    let e
    if (this._currentLeftStep <= this._stepsCount) {
      e = this._lat + this._currentLeftStep * this._increment
      this._currentLeftStep += 1
      t.x = this._lon
      t.y = e
      return true
    }
    return false
  }
  getEndPointSFCT(t) {
    let e
    if (
      this._currentRightStep >= 0 &&
      this._currentRightStep > this._currentLeftStep
    ) {
      e = this._lat + this._currentRightStep * this._increment
      this._currentRightStep -= 1
      t.x = this._lon
      t.y = e
      return true
    }
    return false
  }
}
