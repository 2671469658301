import { QuantityKind } from './QuantityKind.js'
const QuantityKinds = new Map()
registerKind('Dimensionless', null)
registerKind('RasterSize', null)
registerKind('Length', null)
registerKind('Mass', null)
registerKind('Time', null)
registerKind('ElectricCurrent', null)
registerKind('ThermodynamicTemperature', null)
registerKind('AmountOfSubstance', null)
registerKind('LuminousIntensity', null)
registerKind('Area', null)
registerKind('Currency', null)
registerKind('PlaneAngle', null)
registerKind('Velocity', null)
registerKind('Distance', 'Length')
function registerKind(n, t) {
  let i
  if (QuantityKinds.has(n))
    throw new Error(
      `Cannot initialize QuantityKindRegistry: trying to register QuantityKind "${n}", which already exists.`
    )
  if (!t) QuantityKinds.set(n, new QuantityKind(n))
  else {
    if (!QuantityKinds.has(t))
      throw new Error(
        `Cannot initialize QuantityKindRegistry: trying to register QuantityKind "${n}", as subtype of "${t}", which does not exist`
      )
    i = QuantityKinds.get(t)
    QuantityKinds.set(n, new QuantityKind(n, i))
  }
}
export function getQuantityKind(n) {
  if (QuantityKinds.has(n)) return QuantityKinds.get(n)
  else throw new Error(`QuantityKind "${n}" does not exist.`)
}
export function registerQuantityKind(n, t) {
  registerKind(n, t)
  return getQuantityKind(n)
}
export function getAllQuantityKinds() {
  const n = []
  for (const t of QuantityKinds.values()) n.push(t)
  return n
}
