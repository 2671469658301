import { XYZPoint } from '../shape/XYZPoint.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { ProjectionType } from '../projection/ProjectionType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { NoBoundsError } from '../error/NoBoundsError.js'
import { Constants } from '../util/Constants.js'
import { GeodeticGridTransformation } from './GeodeticGridTransformation.js'
import { TransformationType } from './TransformationType.js'
export function isCompatibleGeodeticEq(t, i) {
  const o = i.projection
  const s = i.falseEasting
  const e = i.falseNorthing
  const n = i.rotation
  if (!t.geodeticDatum.equals(i.geodeticDatum)) return false
  if (
    (o.TYPE & ProjectionType.EQUIDISTANT_CYLINDRICAL) !==
    ProjectionType.EQUIDISTANT_CYLINDRICAL
  )
    return false
  const r = o
  return !(
    0 !== s ||
    0 !== e ||
    0 !== n ||
    0 !== r.getStandardParallel() ||
    0 !== r.getCentralMeridian()
  )
}
export class GeodeticEquidistantTransformation extends GeodeticGridTransformation {
  constructor(t, i, o) {
    super(t, i, o)
    if (!isCompatibleGeodeticEq(t, i))
      throw new ProgrammingError(
        'GeodeticEquidistantTransformation:: geodetic and grid reference combo not compatible'
      )
    this._tempXYZPoint1 = new XYZPoint(i)
    this._tempXYZPoint2 = new XYZPoint(i)
    this._tempLLHPoint1 = new LLHPoint(t)
    this._tempLLHPoint2 = new LLHPoint(t)
    this._projection = i.projection
    this._ellipsoid = t.geodeticDatum.ellipsoid
    this._scale = i.scale
    this.uom = i.unitOfMeasure
    const s = this._ellipsoid.auxRadius * Math.PI
    const e = Math.cos(
      this._projection.getStandardParallel() * Constants.DEG2RAD
    )
    this._equidistantMinX = (-s / this.uom) * this._scale
    this._equidistantMaxX = -this._equidistantMinX
    this._equidistantMinY = ((-s * e) / 2 / this.uom) * this._scale
    this._equidistantMaxY = -this._equidistantMinY
    const n = undefined
    const r = undefined
    const a = undefined
    const h =
      this._ellipsoid.auxRadius *
      this._projection.cosStandardParallel *
      Constants.DEG2RAD
    this._scaleFactor = (this.uom / this._scale) * h
  }
  _forward(t, i) {
    this._projection.geodetic2cartesianOnEllipsoidSFCT(t, this._ellipsoid, i)
    i.x *= this._scale / this.uom
    i.y *= this._scale / this.uom
    i.z = t.z / this.uom
    //!! it is possible that source lats exceed 90/-90 and this method will not throw an outofbounds.
    return i
  }
  _inverse(t, i) {
    this._tempXYZPoint1.x = (t.x * this.uom) / this._scale
    this._tempXYZPoint1.y = (t.y * this.uom) / this._scale
    this._projection.cartesian2geodeticOnEllipsoidSFCT(
      this._tempXYZPoint1,
      this._ellipsoid,
      i
    )
    i.z = t.z * this.uom
    return i
  }
  _forwardBoundsCoords(t, i) {
    try {
      const o = this._tempXYZPoint1,
        s = this._tempXYZPoint2
      this._tempLLHPoint1.x = t.x
      this._tempLLHPoint1.y = t.y
      this._tempLLHPoint1.z = t.z
      this._forward(this._tempLLHPoint1, o)
      this._tempLLHPoint1.x = t.x + t.width
      this._tempLLHPoint1.y = t.y + t.height
      this._tempLLHPoint1.z = t.z + t.depth
      this._forward(this._tempLLHPoint1, s)
      if (s.x < o.x || t.width >= 360)
        i.setTo3D(
          this._equidistantMinX,
          this._equidistantMaxX - this._equidistantMinX,
          o.y,
          s.y - o.y,
          o.z,
          s.z - o.z
        )
      else i.setTo3D(o.x, s.x - o.x, o.y, s.y - o.y, o.z, s.z - o.z)
    } catch (t) {
      throw new NoBoundsError()
    }
  }
  _inverseBoundsCoords(t, i) {
    let o
    let s
    let e
    let n
    let r
    let a
    const h = this._tempLLHPoint1
    const m = this._tempLLHPoint2
    try {
      o = Math.max(t.x, this._equidistantMinX)
      s = Math.max(t.y, this._equidistantMinY)
      r = t.z
      e = Math.min(t.x + t.width, this._equidistantMaxX)
      n = Math.min(t.y + t.height, this._equidistantMaxY)
      a = t.z + t.depth
      this._tempXYZPoint1.x = o
      this._tempXYZPoint1.y = s
      this._tempXYZPoint1.z = r
      this._inverse(this._tempXYZPoint1, h)
      this._tempXYZPoint1.x = e
      this._tempXYZPoint1.y = n
      this._tempXYZPoint1.z = a
      this._inverse(this._tempXYZPoint1, m)
      i.setTo3D(h.x, m.x - h.x, h.y, m.y - h.y, h.z, m.z - h.z)
    } catch (i) {
      throw new NoBoundsError(
        `GeodeticEquidistantTransformation::_inverseBoundsCoords: could not project to lonlat: ${t}`
      )
    }
  }
  _getType() {
    return TransformationType.TYPE_SCALE
  }
  getScale() {
    return { x: this._scaleFactor, y: this._scaleFactor, z: this.uom }
  }
}
