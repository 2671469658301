import { Constants } from '../util/Constants.js'
import { greatCircleDistance } from '../geodesy/SphereUtil.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ProjectionType } from './ProjectionType.js'
import { Azimuthal } from './Azimuthal.js'
const sharedOutOfBoundsError = new OutOfBoundsError('Stereographic')
class CachedValuesForEllipsoid {
  constructor(t, i) {
    this.fA = t.a
    this.fE = t.e
    const n = new LLHPoint()
    t.conformalSphericalLonLatPointSFCT(i.getOrigin(), n)
    this.fCosChi1 = Math.cos(n.y * Constants.DEG2RAD)
    this.fSinChi1 = Math.sin(n.y * Constants.DEG2RAD)
    this.fRadiusVertical =
      t.a / Math.sqrt(1 - t.e2 * i._originSinLat * i._originSinLat)
    try {
      const n = new XYZPoint()
      const s = normalizeLon(
        i.getOriginLon() + (i.getOriginLat() >= 0 ? 180 : 0)
      )
      const o =
        i.getOriginLat() >= 0 ? 90 - i.getOriginLat() : i.getOriginLat() + 90
      i.geodetic2cartesianOnEllipsoidForCachedValuesSFCT(
        new LLHPoint(null, [s, o]),
        t,
        n,
        this
      )
      this.fMaxY = n.y
      const a = normalizeLon(
        i.getOriginLon() + (i.getOriginLat() <= 0 ? 180 : 0)
      )
      const r = -o
      i.geodetic2cartesianOnEllipsoidForCachedValuesSFCT(
        new LLHPoint(null, [a, r]),
        t,
        n,
        this
      )
      this.fMinY = n.y
    } catch (t) {
      throw t
    }
  }
}
export class Stereographic extends Azimuthal {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._originSinLat = -1
    this._originSinLon = -1
    this._originCosLat = -1
    this._originCosLon = -1
    this._tempLLH = new LLHPoint()
    this._cachedValuesForEllipsoid = null
    this.setOriginLon(t)
    this.setOriginLat(i)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    const t = this.getOriginLat() * Constants.DEG2RAD
    const i = this.getOriginLon() * Constants.DEG2RAD
    this._originSinLat = Math.sin(t)
    this._originSinLon = Math.sin(i)
    this._originCosLat = Math.cos(t)
    this._originCosLon = Math.cos(i)
    this._cachedValuesForEllipsoid = null
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, i, n) {
    if (this.inLonLatBounds(t)) {
      const s = Math.cos(t.x * Constants.DEG2RAD)
      const o = Math.cos(t.y * Constants.DEG2RAD)
      const a = Math.sin(t.x * Constants.DEG2RAD)
      const r = Math.sin(t.y * Constants.DEG2RAD)
      const e = o * (a * this._originCosLon - s * this._originSinLon)
      const h =
        this._originCosLat * r -
        this._originSinLat *
          o *
          (s * this._originCosLon + a * this._originSinLon)
      const c =
        (2 * i) /
        (1 +
          this._originSinLat * r +
          this._originCosLat *
            o *
            (s * this._originCosLon + a * this._originSinLon))
      n.x = e * c
      n.y = h * c
    } else throw sharedOutOfBoundsError
  }
  geodetic2cartesianOnEllipsoidSFCT(t, i, n) {
    if (!this.inLonLatBounds(t)) throw sharedOutOfBoundsError
    this.geodetic2cartesianOnEllipsoidForCachedValuesSFCT(
      t,
      i,
      n,
      this.getCachedValuesForEllipsoid(i)
    )
  }
  geodetic2cartesianOnEllipsoidForCachedValuesSFCT(t, i, n, s) {
    const o = t.copy()
    i.conformalSphericalLonLatPointSFCT(t, o)
    const a = Math.cos(o.y * Constants.DEG2RAD)
    const r = Math.sin(o.y * Constants.DEG2RAD)
    const e = Math.cos(t.x * Constants.DEG2RAD)
    const h = Math.sin(t.x * Constants.DEG2RAD)
    const c = e * this._originCosLon + h * this._originSinLon
    const L = h * this._originCosLon - e * this._originSinLon
    const g =
      (2 * s.fRadiusVertical * this._originCosLat) /
      (s.fCosChi1 * (1 + s.fSinChi1 * r + s.fCosChi1 * a * c))
    n.x = g * a * L
    n.y = g * (s.fCosChi1 * r - s.fSinChi1 * a * c)
  }
  cartesian2geodeticOnSphereSFCT(t, i, n) {
    if (this.inWorldBoundsOnSphere(t, i)) {
      const s = t.x
      const o = t.y
      const a = Math.sqrt(s * s + o * o)
      if (a < 1e-6) {
        n.x = this.getOriginLon()
        n.y = this.getOriginLat()
        return
      }
      const r = 2 * Math.atan2(a, 2 * i)
      const e = Math.sin(r)
      const h = Math.cos(r)
      const c = h * this._originSinLat + (o * e * this._originCosLat) / a
      const L =
        Math.atan2(
          s * e,
          a * this._originCosLat * h - o * this._originSinLat * e
        ) * Constants.RAD2DEG
      n.x = normalizeLon(this.getOriginLon() + L)
      n.y =
        Math.abs(c) <= 1 ? Math.asin(c) * Constants.RAD2DEG : c >= 0 ? 90 : -90
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnEllipsoidSFCT(t, i, n) {
    const s = this.getCachedValuesForEllipsoid(i)
    const o = s.fCosChi1
    const a = s.fSinChi1
    const r = (s.fRadiusVertical * this._originCosLat) / o
    if (!this.inWorldBoundsOnEllipsoid(t, i)) throw sharedOutOfBoundsError
    const e = t.x
    const h = t.y
    const c = Math.sqrt(e * e + h * h)
    if (c < 1e-6) {
      n.x = this.getOriginLon()
      n.y = this.getOriginLat()
      return
    }
    const L = 2 * Math.atan2(c, 2 * r)
    const g = Math.sin(L)
    const l = Math.cos(L)
    const d = l * a + (h * g * o) / c
    const u =
      Math.abs(d) <= 1 ? Math.asin(d) * Constants.RAD2DEG : d >= 0 ? 90 : -90
    const C = Math.atan2(e * g, c * o * l - h * a * g) * Constants.RAD2DEG
    const O = normalizeLon(this.getOriginLon() + C)
    n.x = O
    n.y = u
    i.inverseConformalSphericalLonLatPointSFCT(n, n)
  }
  inLonLatBounds(t) {
    return greatCircleDistance(this.getOrigin(), t) < 90.0000001
  }
  inWorldBoundsOnSphere(t, i) {
    const n = t.x / i
    const s = t.y / i
    return n * n + s * s < 4 * (1 + 1e-14)
  }
  inWorldBoundsOnEllipsoid(t, i) {
    const n = this.getCachedValuesForEllipsoid(i)
    const s = 0.5 * (n.fMaxY + n.fMinY)
    const o = 2 * i.a
    const a = 0.5 * (n.fMaxY - n.fMinY)
    const r = t.x
    const e = t.y - s
    return (r * r) / (o * o) + (e * e) / (a * a) <= 1 + 1e-8
  }
  boundaryLons(t) {
    if (
      (this.getOriginLat() > 89.9999999 && t >= 0) ||
      (this.getOriginLat() < -89.9999999 && t < 0)
    )
      return [[-180, 180]]
    if (
      (this.getOriginLat() > 89.9999999 && t < 0) ||
      (this.getOriginLat() < -89.9999999 && t >= 0)
    )
      return []
    const i =
      (-Math.tan(t * Constants.DEG2RAD) * this._originSinLat) /
      this._originCosLat
    if (i <= -0.9999999 || i >= 0.9999999) {
      this._tempLLH.move2D(this.getOriginLon(), t)
      return this.inLonLatBounds(this._tempLLH) ? [[-180, 180]] : []
    }
    let n = Math.acos(i) * Constants.RAD2DEG
    const s = normalizeLon(this.getOriginLon() + n)
    const o = normalizeLon(this.getOriginLon() - n)
    n = (s + o) / 2
    this._tempLLH.move2D(n, t)
    return this.inLonLatBounds(this._tempLLH)
      ? [[Math.min(s, o), Math.max(s, o)]]
      : [[Math.max(s, o), Math.min(s, o)]]
  }
  boundaryLats(t) {
    if (this.getOriginLat() < 1e-7 && this.getOriginLat() > -1e-7) {
      this._tempLLH.move2D(t, 0)
      return this.inLonLatBounds(this._tempLLH) ? [[-90, 80]] : []
    }
    const i =
      Math.atan(
        (-Math.cos((t - this.getOriginLon()) * Constants.DEG2RAD) *
          this._originCosLat) /
          this._originSinLat
      ) * Constants.RAD2DEG
    return this.getOriginLat() > 0 ? [[i, 90]] : [[-90, i]]
  }
  cartesianBoundsOnSphereSFCT(t, i) {
    const n = 2 * t * (1 + 1e-15)
    i.setTo2D(-n, 2 * n, -n, 2 * n)
  }
  cartesianBoundsOnEllipsoidSFCT(t, i) {
    const n = this.getCachedValuesForEllipsoid(t)
    const s = 2 * t.a
    i.setTo2D(-s, 2 * s, n.fMinY, n.fMaxY - n.fMinY)
  }
  getCachedValuesForEllipsoid(t) {
    const i = this._cachedValuesForEllipsoid
    if (null === i || i.fA != t.a || i.fE !== t.e)
      this._cachedValuesForEllipsoid = new CachedValuesForEllipsoid(t, this)
    return this._cachedValuesForEllipsoid
  }
  encode() {
    return {
      type: 'Stereographic',
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.STEREOGRAPHIC
  }
}
