import { AbstractFeatureRenderer } from './AbstractFeatureRenderer.js'
export class NonSelectedFeatureRenderer extends AbstractFeatureRenderer {
  constructor(e, t, r, s, i, a, l) {
    super(e, t, r, s, i, a, l, { selected: false, flags: 0 })
    this._filter = null
  }
  search(e, t) {
    if (this._filter) {
      const e = this._filter
      const r = t
      t = (t) => {
        if (t.dirtyFilter) {
          t.passesFilter = e(t.feature)
          t.dirtyFilter = false
        }
        if (t.passesFilter) r(t)
      }
    }
    this._layer.workingSet.search(e, t)
  }
  runActionForFeaturesInBounds(e, t) {
    const r = this
    this.search(e, (e) => {
      t(r, e)
    })
  }
  setFilter(e) {
    this._filter = e
    this._layer.workingSet.forEachVisibleNode((e) => {
      e.dirtyFilter = true
    })
  }
}
