import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Matrix4 } from '../../geometry/mesh/math/Matrix4.js'
import { Vector3 as Vector3Int } from '../../geometry/mesh/math/Vector3.js'
import { Vector4 } from '../../geometry/mesh/math/Vector4.js'
import {
  createTopocentricReference,
  getReference,
} from '../../reference/ReferenceProvider.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { TopocentricGeocentricTransformation } from '../../transformation/TopocentricGeocentricTransformation.js'
import {
  createTransformation,
  createTransformationWithOptions,
  isTransformationRequired,
} from '../../transformation/TransformationFactory.js'
import {
  clamp,
  distance3D,
  normalizeAngle0To360,
  orientedAngleOnPlane,
} from '../../util/Cartesian.js'
import { Constants } from '../../util/Constants.js'
import { isNumber } from '../../util/Lang.js'
import { Camera } from './Camera.js'
export class PerspectiveCamera extends Camera {
  constructor(t, e, o, n, r, s, c, a, i) {
    super(t, e, o, n, r, s, c, i)
    if (!isNumber(a))
      throw new ProgrammingError('PerspectiveCamera fovY is not a number')
    this._fovY = a
  }
  get fovY() {
    return this._fovY
  }
  getScaleAt(t) {
    const e = undefined
    const o =
      2 *
      distance3D(this._eye, t) *
      Math.tan((0.5 * this._fovY * Math.PI) / 180)
    return this.height / o
  }
  computeProjectionMatrix(t) {
    const e = this.width / this.height
    t.perspectiveGLMatrixJS(
      this._fovY * Constants.DEG2RAD,
      e,
      this.near,
      this.far
    )
  }
  copy() {
    const t = new PerspectiveCamera(
      this.eye,
      this.forward,
      this.up,
      this.near,
      this.far,
      this.width,
      this.height,
      this.fovY,
      this.worldReference
    )
    this._copyLookAtLookFromIfPossible(t)
    return t
  }
  copyAndSet(t) {
    let e = 'undefined' !== typeof t.eye ? t.eye : this.eye
    const o =
      'undefined' !== typeof t.worldReference
        ? t.worldReference
        : this.worldReference
    if (e.reference && !e.reference.equals(o))
      e = createTransformation(e.reference, o).transform(e)
    const n = new PerspectiveCamera(
      e,
      t.forward ?? this.forward,
      t.up ?? this.up,
      t.near ?? this.near,
      t.far ?? this.far,
      t.width ?? this.width,
      t.height ?? this.height,
      t.fovY ?? this.fovY,
      o
    )
    this._copyLookAtLookFromIfPossible(n)
    return n
  }
  equals(t) {
    if (!(t instanceof PerspectiveCamera)) return false
    return (
      Math.abs(this._eye.x - t._eye.x) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._eye.y - t._eye.y) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._eye.z - t._eye.z) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._forward.x - t._forward.x) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._forward.y - t._forward.y) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._forward.z - t._forward.z) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._up.x - t._up.x) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._up.y - t._up.y) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._up.z - t._up.z) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      this._near === t._near &&
      this._far === t._far &&
      this._width === t._width &&
      this._height === t._height &&
      this._fovY === t._fovY &&
      this.worldReference.equals(t.worldReference)
    )
  }
  toString() {
    return `PerspectiveCamera: \n\teye: [${this._eye.x}, ${this._eye.y}, ${this._eye.z}]\n\tforward: [${this._forward.x}, ${this._forward.y}, ${this._forward.z}]\n\tup: [${this._up.x}, ${this._up.y}, ${this._up.z}]\n\twidth: ${this._width}\n\theight: ${this._height}\n\tnear: ${this.near}\n\tfar: ${this.far}\n\tfovY: ${this._fovY}\n\taspectRatio: ${this.aspectRatio}\n\treference: ${this.worldReference.identifier}`
  }
  _copyLookAtLookFromIfPossible(t) {
    const e =
      t.eye.x === this.eye.x &&
      t.eye.y === this.eye.y &&
      t.eye.z === this.eye.z &&
      t.forward.x === this.forward.x &&
      t.forward.y === this.forward.y &&
      t.forward.z === this.forward.z &&
      t.up.x === this.up.x &&
      t.up.y === this.up.y &&
      t.up.z === this.up.z
    if (e && this._lookFromInternal)
      t._lookFromInternal = {
        eye: new Vector3Int(
          this._lookFromInternal.eye.x,
          this._lookFromInternal.eye.y,
          this._lookFromInternal.eye.z
        ),
        yaw: this._lookFromInternal.yaw,
        pitch: this._lookFromInternal.pitch,
        roll: this._lookFromInternal.roll,
      }
    if (e && this._lookAtInternal)
      t._lookAtInternal = {
        ref: new Vector3Int(
          this._lookAtInternal.ref.x,
          this._lookAtInternal.ref.y,
          this._lookAtInternal.ref.z
        ),
        distance: new Vector3Int(
          this._lookAtInternal.ref.x,
          this._lookAtInternal.ref.y,
          this._lookAtInternal.ref.z
        ),
        yaw: this._lookAtInternal.yaw,
        pitch: this._lookAtInternal.pitch,
        roll: this._lookAtInternal.roll,
      }
  }
  static createDefaultCamera(t, e, o) {
    if (t.TYPE === ReferenceType.GEOCENTRIC)
      return new PerspectiveCamera(
        new Vector3Int(12755302.578841701, 0, -111313.83923667668),
        new Vector3Int(-0.9998476951563913, 0, 0.017452406437283595),
        new Vector3Int(0.017452406437283595, 0, 0.9998476951563914),
        1e3,
        1e15,
        e,
        o,
        60,
        t
      )
    else if (t.TYPE === ReferenceType.GRID) {
      const n = t.bounds
      return new PerspectiveCamera(
        new Vector3Int(0, 0, n.width),
        new Vector3Int(0, 0, -1),
        new Vector3Int(0, 1, 0),
        1,
        4 * n.width,
        e,
        o,
        60,
        t
      )
    } else
      return new PerspectiveCamera(
        new Vector3Int(0, 0, 1),
        new Vector3Int(0, 1, 0),
        new Vector3Int(0, 0, 1),
        0.3,
        1e3,
        e,
        o,
        60,
        t
      )
  }
  createTopoToGeocTrans = (() => {
    const t = createPoint(LLH_REF, [0, 0, 0])
    return (e, o) => {
      const n = createPoint(o, [e.x, e.y, e.z])
      const r = undefined
      createTransformation(o, LLH_REF).transform(n, t)
      const s = createTopocentricReference({ origin: t })
      return new TopocentricGeocentricTransformation(s, t, n, o, {
        createTransformation: createTransformation,
        createTransformationWithOptions: createTransformationWithOptions,
        isTransformationRequired: isTransformationRequired,
      })
    }
  })()
  lookFrom(t) {
    return this._lookFrom(t)
  }
  get worldReference() {
    return super.worldReference
  }
  _lookFrom = (() => {
    const t = new Vector3Int()
    const e = new Vector3Int()
    const o = new Vector3Int()
    const n = new Vector3Int()
    const r = new Vector3Int()
    const s = new Vector3Int()
    const c = new Vector3Int(0, 0, 0)
    return (a) => {
      if (!a)
        throw new ProgrammingError(
          'PerspectiveCamera.lookFrom: lookFrom argument missing'
        )
      if (a.eye.reference && !a.eye.reference.equals(this.worldReference)) {
        const e = undefined
        createTransformation(a.eye.reference, this.worldReference).transform(
          a.eye,
          this._tempWorldPoint
        )
        t.copy(this._tempWorldPoint)
      } else t.copy(a.eye)
      if (this.worldReference.TYPE !== ReferenceType.GEOCENTRIC) {
        let t = new Vector3Int(0, 1, 0)
        let e = new Vector3Int(0, 0, 1)
        t = t.applyMatrix4(
          new Matrix4().makeRotationZ(-a.yaw * Constants.DEG2RAD)
        )
        e = e.applyMatrix4(
          new Matrix4().makeRotationZ(-a.yaw * Constants.DEG2RAD)
        )
        const o = t.clone().cross(s).normalize()
        t = t.applyMatrix4(
          new Matrix4().makeRotationAxis(o, a.pitch * Constants.DEG2RAD)
        )
        e = e.applyMatrix4(
          new Matrix4().makeRotationAxis(o, a.pitch * Constants.DEG2RAD)
        )
        e = e.applyMatrix4(
          new Matrix4().makeRotationAxis(t, a.roll * Constants.DEG2RAD)
        )
        s.set(e.x, e.y, e.z)
        n.set(t.x, t.y, t.z)
      } else {
        calculateRefPointsCartesian(c, 1e5, a.yaw, a.pitch, e, o)
        e.normalize()
        o.normalize()
        const i = this.createTopoToGeocTrans(t, this.worldReference)
        i.forwardDirection(e, n)
        i.forwardDirection(o, r)
        calculateUpVectorGeocentricZeroRoll(n, t, r, s)
        calculateUpVectorGeocentric(n, s, a.roll, s)
      }
      const i = new PerspectiveCamera(
        t,
        n,
        s,
        this.near,
        this.far,
        this.width,
        this.height,
        this.fovY,
        this.worldReference
      )
      i._lookFromInternal = {
        eye: new Vector3Int(t.x, t.y, t.z),
        yaw: a.yaw,
        pitch: a.pitch,
        roll: a.roll,
      }
      return i
    }
  })()
  asLookFrom() {
    return this._asLookFrom()
  }
  _asLookFrom = (() => {
    const t = new Vector3Int()
    const e = new Vector3Int()
    const o = new Vector3Int()
    const n = new Vector3Int()
    const r = new Vector3Int()
    const s = new Vector3Int()
    const c = new Vector3Int()
    const a = new Vector3Int()
    const i = new Vector3Int()
    const l = new Vector3Int(0, 0, 0)
    return () => {
      if (this._lookFromInternal)
        return {
          eye: new Vector3Int(
            this._lookFromInternal.eye.x,
            this._lookFromInternal.eye.y,
            this._lookFromInternal.eye.z
          ),
          yaw: this._lookFromInternal.yaw,
          pitch: this._lookFromInternal.pitch,
          roll: this._lookFromInternal.roll,
        }
      t.copy(this._eye)
      e.copy(this._forward)
      o.copy(this._up)
      if (this.worldReference.TYPE !== ReferenceType.GEOCENTRIC) {
        const n = orientedAngleOnPlane(
          e,
          new Vector3Int(0, 1, 0),
          new Vector3Int(0, 0, 1)
        )
        const r = (null != n ? n : 0) * Constants.RAD2DEG
        const s = e.clone().cross(o).normalize()
        const c = orientedAngleOnPlane(o, new Vector3Int(0, 0, 1), s)
        const a = orientedAngleOnPlane(e, new Vector3Int(0, 1, 0), s)
        const i = -(null != c ? c : null != a ? a : 0) * Constants.RAD2DEG
        const l = orientedAngleOnPlane(o, new Vector3Int(0, 0, 1), e)
        const h = orientedAngleOnPlane(s, new Vector3Int(1, 0, 0), e)
        const f = -(null != l ? l : null != h ? h : 0) * Constants.RAD2DEG
        return { eye: t.clone(), yaw: r, pitch: i, roll: f }
      }
      n.addVectors(t, e)
      const h = this.createTopoToGeocTrans(t, this.worldReference)
      h.inverseDirection(e, r)
      const f = calculateYawPitchTopocentric(r, o, n)
      const w = f.yaw
      const p = f.pitch
      calculateRefPointsCartesian(l, 1, w, p, s, a)
      s.normalize()
      a.normalize()
      h.forwardDirection(s, c)
      h.forwardDirection(a, i)
      const m = calculateRoll(c, t, i, o)
      return { eye: t.clone(), yaw: w, pitch: p, roll: m }
    }
  })()
  lookAt(t) {
    return this.__lookAt(t)
  }
  __lookAt = (() => {
    const t = new Vector3Int()
    const e = new Vector3Int()
    const o = new Vector3Int()
    const n = new Vector3Int()
    const r = new Vector3Int()
    const s = new Vector3Int()
    const c = new Vector3Int()
    const a = new Vector3Int()
    const i = new Vector3Int()
    const l = new Vector3Int()
    const h = new Vector3Int()
    return (f) => {
      if (!f)
        throw new ProgrammingError(
          'PerspectiveCamera.lookAt: lookAt argument missing'
        )
      if (f.ref.reference && !f.ref.reference.equals(this.worldReference)) {
        const e = undefined
        createTransformation(f.ref.reference, this.worldReference).transform(
          f.ref,
          this._tempWorldPoint
        )
        t.copy(this._tempWorldPoint)
      } else t.copy(f.ref)
      if (this.worldReference.TYPE !== ReferenceType.GEOCENTRIC) {
        const e = new Vector3Int()
        calculateEyePointCartesian_1(t, f.distance, f.yaw, f.pitch, e)
        return this.lookFrom({ eye: e, ...f })
      }
      const w = this.createTopoToGeocTrans(t, this.worldReference)
      const p = createPoint(w.srcTopocentricReference, [0, 0, 0])
      w.inversePoint(t, p)
      e.copy(p)
      calculateEyePointsCartesian(e, f.distance, f.yaw, f.pitch, o, n)
      c.subVectors(e, o)
      a.subVectors(e, n)
      w.forwardDirection(c, i)
      w.forwardDirection(a, l)
      i.normalize()
      l.normalize()
      w.transform(o, this._tempWorldPoint)
      r.copy(this._tempWorldPoint)
      w.transform(n, this._tempWorldPoint)
      s.copy(this._tempWorldPoint)
      calculateUpVectorGeocentricZeroRoll(i, s, l, h)
      calculateUpVectorGeocentric(i, h, f.roll, h)
      const m = new PerspectiveCamera(
        r,
        i,
        h,
        this.near,
        this.far,
        this.width,
        this.height,
        this.fovY,
        this.worldReference
      )
      m._lookAtInternal = {
        ref: new Vector3Int(t.x, t.y, t.z),
        distance: f.distance,
        yaw: f.yaw,
        pitch: f.pitch,
        roll: f.roll,
      }
      return m
    }
  })()
  asLookAt(t) {
    return this._asLookAt(t)
  }
  _asLookAt = (() => {
    const t = new Vector3Int()
    const e = new Vector3Int()
    const o = new Vector3Int()
    const n = new Vector3Int()
    const r = new Vector3Int()
    const s = new Vector3Int()
    const c = new Vector3Int()
    const a = new Vector3Int()
    const i = new Vector3Int()
    const l = new Vector3Int()
    const h = new Vector3Int()
    const f = new Vector3Int()
    const w = new Vector3Int()
    return (p) => {
      p = p || 1
      if (this._lookAtInternal && p === this._lookAtInternal.distance)
        return {
          ref: new Vector3Int(
            this._lookAtInternal.ref.x,
            this._lookAtInternal.ref.y,
            this._lookAtInternal.ref.z
          ),
          distance: this._lookAtInternal.distance,
          yaw: this._lookAtInternal.yaw,
          pitch: this._lookAtInternal.pitch,
          roll: this._lookAtInternal.roll,
        }
      t.copy(this._eye)
      e.copy(this._forward)
      o.copy(this._up)
      if (this.worldReference.TYPE !== ReferenceType.GEOCENTRIC) {
        const o = this._asLookFrom()
        const n = undefined
        return { ref: t.add(e, e.multiplyScalar(p)), distance: p, ...o }
      }
      n.copy(t)
      n.addScaledVector(e, p)
      const m = this.createTopoToGeocTrans(n, this.worldReference)
      m.inverseDirection(e, s)
      const y = calculateYawPitchTopocentric(s, o, n)
      const I = y.yaw
      const _ = y.pitch
      const u = createPoint(m.srcTopocentricReference, [0, 0, 0])
      m.inversePoint(n, u)
      r.copy(u)
      calculateEyePointsCartesian(r, p, I, _, c, a)
      l.subVectors(r, c)
      h.subVectors(r, a)
      m.forwardDirection(l, f)
      m.forwardDirection(h, w)
      f.normalize()
      w.normalize()
      m.transform(a, this._tempWorldPoint)
      i.copy(this._tempWorldPoint)
      const d = calculateRoll(f, i, w, o)
      return { ref: n.clone(), distance: p, yaw: I, pitch: _, roll: d }
    }
  })()
}
const MIN_DISTANCE = 1e-8
const MAX_DISTANCE = 5e8
const LLH_REF = getReference('CRS:84')
const PITCH_SENSITIVITY = 0.01
const calculateYawPitchTopocentric = (() => {
  const t = new Vector3Int()
  const e = new Vector3Int()
  const o = new Vector3Int()
  const n = new Vector3Int()
  const r = new Vector3Int()
  return (s, c, a) => {
    t.copy(s)
    const i = Math.atan2(t.z, Math.sqrt(t.x * t.x + t.y * t.y))
    let l
    if (
      Math.abs(i) < 0.5 * Math.PI - PITCH_SENSITIVITY ||
      Math.abs(i) > 0.5 * Math.PI - PITCH_SENSITIVITY
    )
      l = Math.atan2(t.x, t.y)
    else {
      e.copy(c)
      o.copy(a)
      o.normalize()
      n.set(o.y, -o.x, 0)
      n.normalize()
      r.crossVectors(o, n)
      const t = e.dot(o)
      e.subVectors(e, o.multiplyScalar(t))
      e.normalize()
      l = Math.acos(r.dot(e))
      n.crossVectors(r, e)
      if (o.dot(n) > 0) l = -l
      l += Math.PI
    }
    const h = undefined
    const f = undefined
    return {
      yaw: normalizeAngle0To360(l * Constants.RAD2DEG),
      pitch: i * Constants.RAD2DEG,
    }
  }
})()
const calculateRefPointsCartesian = (t, e, o, n, r, s) => {
  calculateRefPointCartesian_1(t, e, o, n, r)
  if (n < -85) calculateRefPointCartesian_1(t, e, o, -85, s)
  else if (n > 85) calculateRefPointCartesian_1(t, e, o, 85, s)
  else s.copy(r)
}
const calculateRefPointCartesian_1 = (() => {
  const t = new Vector4()
  const e = new Matrix4()
  const o = new Matrix4()
  return (n, r, s, c, a) => {
    t.set(0, 1, 0, 1)
    const i = c * Constants.DEG2RAD
    e.makeRotationX(i)
    const l = -s * Constants.DEG2RAD
    o.makeRotationZ(l)
    t.applyMatrix4(e)
    t.applyMatrix4(o)
    r = clamp(r, MIN_DISTANCE, MAX_DISTANCE)
    t.multiplyScalar(r)
    a.addVectors(n, t)
  }
})()
const calculateEyePointsCartesian = (t, e, o, n, r, s) => {
  calculateEyePointCartesian_1(t, e, o, n, r)
  if (n < -85) calculateEyePointCartesian_1(t, e, o, -85, s)
  else if (n > 85) calculateEyePointCartesian_1(t, e, o, 85, s)
  else s.copy(r)
}
const calculateEyePointCartesian_1 = (() => {
  const t = new Vector4()
  const e = new Matrix4()
  const o = new Matrix4()
  return (n, r, s, c, a) => {
    t.set(0, 1, 0, 1)
    const i = c * Constants.DEG2RAD
    e.makeRotationX(i)
    const l = -s * Constants.DEG2RAD
    o.makeRotationZ(l)
    t.applyMatrix4(e)
    t.applyMatrix4(o)
    r = clamp(r, MIN_DISTANCE, MAX_DISTANCE)
    t.multiplyScalar(r)
    a.subVectors(n, t)
  }
})()
const calculateRoll = (() => {
  const t = new Vector3Int()
  const e = new Vector3Int()
  return (o, n, r, s) => {
    calculateUpVectorGeocentricZeroRoll(o, n, r, t)
    const c = clamp(t.dot(s), -1, 1)
    let a = Math.acos(c)
    e.crossVectors(t, s)
    if (o.dot(e) < 0) a = -a
    return normalizeAngle0To360(a * Constants.RAD2DEG)
  }
})()
const calculateUpVectorGeocentricZeroRoll = (() => {
  const t = new Vector3Int()
  const e = new Vector3Int()
  const o = new Vector3Int()
  return (n, r, s, c) => {
    t.copy(r)
    t.normalize()
    e.crossVectors(s, t)
    e.normalize()
    t.crossVectors(e, s)
    e.crossVectors(n, t)
    o.crossVectors(e, n)
    o.normalize()
    o.normalize()
    c.copy(o)
  }
})()
const calculateUpVectorGeocentric = (() => {
  const t = new Matrix4()
  const e = new Vector3Int()
  return (o, n, r, s) => {
    const c = r * Constants.DEG2RAD
    t.makeRotationAxis(o, c)
    e.copy(n)
    e.applyMatrix4(t)
    e.normalize()
    s.copy(e)
  }
})()
