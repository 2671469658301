import { createCircle, createGradientCircle } from '../../util/IconFactory.js'
const DEFAULT_SIZE = 16
const DEFAULT_SELECTED_SIZE = 24
const DEFAULT_FILL_COLOR_REGULAR = 'rgba(65,105,225,0.9)'
const DEFAULT_FILL_COLOR_HOVER = 'rgba(115,143,231,0.9)'
const DEFAULT_FILL_COLOR_SELECTED = DEFAULT_FILL_COLOR_REGULAR
const DEFAULT_FILL_COLOR_SELECTED_HOVER = DEFAULT_FILL_COLOR_HOVER
const DEFAULT_LINE_COLOR_REGULAR = '#FFFFFF'
const DEFAULT_LINE_COLOR_HOVER = '#f67c2e'
const DEFAULT_LINE_COLOR_SELECTED = '#FF0000'
const DEFAULT_LINE_COLOR_SELECTED_HOVER = '#fa4b4b'
const DEFAULT_EXTRUDED_FILL_COLOR_REGULAR = 'rgba(85,135,185,0.9)'
const DEFAULT_EXTRUDED_FILL_COLOR_HOVER = 'rgba(128,165,203,0.9)'
const DEFAULT_EXTRUDED_FILL_COLOR_SELECTED = DEFAULT_EXTRUDED_FILL_COLOR_REGULAR
const DEFAULT_EXTRUDED_FILL_COLOR_SELECTED_HOVER =
  DEFAULT_EXTRUDED_FILL_COLOR_HOVER
const DEFAULT_EXTRUDED_LINE_COLOR_REGULAR = 'rgba(255, 255, 255, 0.99)'
const DEFAULT_EXTRUDED_LINE_COLOR_HOVER = 'rgba(246, 124, 46, 0.99)'
const DEFAULT_EXTRUDED_LINE_COLOR_SELECTED = 'rgba(255, 0, 0, 0.99)'
const DEFAULT_EXTRUDED_LINE_COLOR_SELECTED_HOVER = 'rgba(250, 75, 75, 0.99)'
const DEFAULT_AREA_STYLE_REGULAR = {
  stroke: { color: DEFAULT_LINE_COLOR_REGULAR },
  fill: { color: DEFAULT_FILL_COLOR_REGULAR },
}
const DEFAULT_AREA_STYLE_HOVER = {
  stroke: { color: DEFAULT_LINE_COLOR_HOVER },
  fill: { color: DEFAULT_FILL_COLOR_HOVER },
}
const DEFAULT_AREA_STYLE_SELECTED = {
  stroke: { color: DEFAULT_LINE_COLOR_SELECTED, width: 1.5 },
  fill: { color: DEFAULT_FILL_COLOR_SELECTED },
}
const DEFAULT_AREA_STYLE_SELECTED_HOVER = {
  stroke: {
    color: DEFAULT_LINE_COLOR_SELECTED_HOVER,
    width: DEFAULT_AREA_STYLE_SELECTED.stroke.width,
  },
  fill: { color: DEFAULT_FILL_COLOR_SELECTED_HOVER },
}
const DEFAULT_LINE_STYLE_REGULAR = {
  stroke: { color: DEFAULT_LINE_COLOR_REGULAR, width: 1.2 },
}
const DEFAULT_LINE_STYLE_HOVER = {
  stroke: {
    color: DEFAULT_LINE_COLOR_HOVER,
    width: DEFAULT_LINE_STYLE_REGULAR.stroke.width,
  },
}
const DEFAULT_LINE_STYLE_SELECTED = {
  stroke: {
    color: DEFAULT_LINE_COLOR_SELECTED,
    width: DEFAULT_LINE_STYLE_REGULAR.stroke.width,
  },
}
const DEFAULT_LINE_STYLE_SELECTED_HOVER = {
  stroke: {
    color: DEFAULT_LINE_COLOR_SELECTED,
    width: DEFAULT_LINE_STYLE_SELECTED.stroke.width,
  },
}
const DEFAULT_POINT_STYLE_REGULAR = {
  image: createCircle({
    stroke: 'rgba(65,105,225,1)',
    fill: DEFAULT_FILL_COLOR_REGULAR,
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  }),
  width: `${DEFAULT_SIZE}px`,
  height: `${DEFAULT_SIZE}px`,
}
const DEFAULT_POINT_STYLE_HOVER = {
  image: createCircle({
    stroke: 'rgba(65,105,225,1)',
    fill: DEFAULT_FILL_COLOR_HOVER,
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  }),
  width: `${DEFAULT_SIZE}px`,
  height: `${DEFAULT_SIZE}px`,
}
const DEFAULT_POINT_STYLE_SELECTED = {
  image: createCircle({
    stroke: DEFAULT_LINE_COLOR_SELECTED,
    fill: 'rgba(255,0,0,0.9)',
    width: DEFAULT_SELECTED_SIZE,
    height: DEFAULT_SELECTED_SIZE,
  }),
  width: `${DEFAULT_SELECTED_SIZE}px`,
  height: `${DEFAULT_SELECTED_SIZE}px`,
}
const DEFAULT_POINT_STYLE_SELECTED_HOVER = {
  image: createCircle({
    stroke: DEFAULT_LINE_COLOR_SELECTED_HOVER,
    fill: 'rgba(255,0,0,0.9)',
    width: DEFAULT_SELECTED_SIZE,
    height: DEFAULT_SELECTED_SIZE,
  }),
  width: `${DEFAULT_SELECTED_SIZE}px`,
  height: `${DEFAULT_SELECTED_SIZE}px`,
}
const DEFAULT_POINT_STYLE_DENSITY = {
  image: createGradientCircle({
    fill: 'rgba(255, 255, 225, 1.0)',
    fading: 'rgba(255, 255, 225, 0.0)',
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  }),
  width: `${DEFAULT_SIZE}px`,
  height: `${DEFAULT_SIZE}px`,
}
const DEFAULT_LINE_STYLE_HELPER = {
  stroke: { color: 'rgba(180, 180, 180, 0.65)' },
  zOrder: -1,
}
const DEFAULT_STYLE_HELPER = {
  fill: { color: 'rgba(255, 255, 255, 0.5)' },
  stroke: { color: 'rgba(180, 180, 180, 0.65)' },
  zOrder: -1,
}
const DEFAULT_POINT_STYLE_HANDLE = {
  image: createCircle({
    stroke: 'rgb(0,0,0)',
    strokeWidth: 1,
    fill: 'rgb(255,255,255)',
    width: 10,
    height: 10,
  }),
  width: '10px',
  height: '10px',
  zOrder: 10,
  anchorY: '100%',
  offsetY: 5,
}
export const createSelectedPolygonStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredPolygonStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultPolygonStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredPolygonStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedBoundsStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredBoundsStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultBoundsStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredBoundsStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedComplexPolygonStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredComplexPolygonStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultComplexPolygonStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredComplexPolygonStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedCircleStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredCircleStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultCircleStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredCircleStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedEllipseStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredEllipseStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultEllipseStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredEllipseStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedShapeListStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredShapeListStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultShapeListStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredShapeListStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedGeoBufferStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredGeoBufferStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultGeoBufferStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredGeoBufferStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedExtrudedShapeStyle = () => ({
  stroke: { color: DEFAULT_EXTRUDED_LINE_COLOR_SELECTED, width: 1 },
  fill: { color: DEFAULT_EXTRUDED_FILL_COLOR_SELECTED },
})
export const createSelectedHoveredExtrudedShapeStyle = () => ({
  stroke: { color: DEFAULT_EXTRUDED_LINE_COLOR_SELECTED_HOVER, width: 1 },
  fill: { color: DEFAULT_EXTRUDED_FILL_COLOR_SELECTED_HOVER },
})
export const createDefaultExtrudedShapeStyle = () => ({
  stroke: { color: DEFAULT_EXTRUDED_LINE_COLOR_REGULAR, width: 1 },
  fill: { color: DEFAULT_EXTRUDED_FILL_COLOR_REGULAR },
})
export const createHoveredExtrudedShapeStyle = () => ({
  stroke: { color: DEFAULT_EXTRUDED_LINE_COLOR_HOVER, width: 1 },
  fill: { color: DEFAULT_EXTRUDED_FILL_COLOR_HOVER },
})
export const createSelectedOrientedBoxStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredOrientedBoxStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultOrientedBoxStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredOrientedBoxStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedPolylineStyle = () => DEFAULT_LINE_STYLE_SELECTED
export const createSelectedHoveredPolylineStyle = () =>
  DEFAULT_LINE_STYLE_SELECTED_HOVER
export const createDefaultPolylineStyle = () => DEFAULT_LINE_STYLE_REGULAR
export const createHoveredPolylineStyle = () => DEFAULT_LINE_STYLE_HOVER
export const createSelectedCircularArcStyle = () => DEFAULT_LINE_STYLE_SELECTED
export const createSelectedHoveredCircularArcStyle = () =>
  DEFAULT_LINE_STYLE_SELECTED_HOVER
export const createDefaultCircularArcStyle = () => DEFAULT_LINE_STYLE_REGULAR
export const createHoveredCircularArcStyle = () => DEFAULT_LINE_STYLE_HOVER
export const createSelectedEllipticalArcStyle = () =>
  DEFAULT_LINE_STYLE_SELECTED
export const createSelectedHoveredEllipticalArcStyle = () =>
  DEFAULT_LINE_STYLE_SELECTED_HOVER
export const createDefaultEllipticalArcStyle = () => DEFAULT_LINE_STYLE_REGULAR
export const createHoveredEllipticalArcStyle = () => DEFAULT_LINE_STYLE_HOVER
export const createSelectedArcBandStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredArcBandStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultArcBandStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredArcBandStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedSectorStyle = () => DEFAULT_AREA_STYLE_SELECTED
export const createSelectedHoveredSectorStyle = () =>
  DEFAULT_AREA_STYLE_SELECTED_HOVER
export const createDefaultSectorStyle = () => DEFAULT_AREA_STYLE_REGULAR
export const createHoveredSectorStyle = () => DEFAULT_AREA_STYLE_HOVER
export const createSelectedPointStyle = () => DEFAULT_POINT_STYLE_SELECTED
export const createSelectedHoveredPointStyle = () =>
  DEFAULT_POINT_STYLE_SELECTED_HOVER
export const createDefaultPointStyle = () => DEFAULT_POINT_STYLE_REGULAR
export const createHoveredPointStyle = () => DEFAULT_POINT_STYLE_HOVER
export const createDensityPointStyle = () => DEFAULT_POINT_STYLE_DENSITY
export const createHandlePointStyle = () => DEFAULT_POINT_STYLE_HANDLE
export const createHelperPolylineStyle = () => DEFAULT_LINE_STYLE_HELPER
export const createHelperStyle = () => DEFAULT_STYLE_HELPER
