import { LLHPoint } from '../shape/LLHPoint.js'
import { PolygonOrientation } from '../shape/PolygonOrientation.js'
import {
  containsAngle as cartesianContainsAngle,
  normalizeAngle,
} from '../util/Cartesian.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { geodesicArea as externalGeodesicArea } from './GeodesicAreaUtil.js'
import { bufferContour2DOf2DPolyline } from './SphereUtil.js'
const EQUAL_POINTS_EPSILON = 1e-10
const EQUAL_ANGLES_EPSILON = 1e-6
function rad2Deg(n) {
  return n * Constants.RAD2DEG
}
function equalAzimuths(n, e) {
  return Math.abs(normalizeAngle(n - e)) < EQUAL_ANGLES_EPSILON
}
function containsAngle(n, e, t, o) {
  if (e >= 0) {
    n -= o
    e += 2 * o
  } else if (e < 0) {
    n += o
    e -= 2 * o
  }
  return cartesianContainsAngle(n, e, t)
}
function containsAngleTolerance(n, e, t, o, i) {
  const s = 0 == e ? o : (e < 0 ? -1 : 1) * (o + i * Math.abs(e))
  return cartesianContainsAngle(n - s, e + 2 * s, t)
}
function contains2D(n, e, t, o) {
  const i = n.geodesicDistance(e, o)
  if (i < EQUAL_POINTS_EPSILON) return true
  const s = undefined
  if (i > n.geodesicDistance(e, t) + EQUAL_POINTS_EPSILON) return false
  const r = undefined
  const a = undefined
  return equalAzimuths(
    n.forwardAzimuth2D(e, o) * Constants.RAD2DEG,
    n.forwardAzimuth2D(e, t) * Constants.RAD2DEG
  )
}
function intersection2DLineSegmentsWithCommonEndPoint(n, e, t, o, i) {
  const s = undefined
  const r = undefined
  let a
  let D
  if (
    equalAzimuths(
      Constants.RAD2DEG * o.forwardAzimuth2D(n, e),
      Constants.RAD2DEG * o.forwardAzimuth2D(n, t)
    )
  ) {
    if (isPole(n))
      if (equalLongitudes(e, t)) {
        a = o.geodesicDistance(n, e)
        D = o.geodesicDistance(n, t)
        if (a < D) i.move2D(e.x, e.y)
        else i.move2D(t.x, t.y)
        return -1
      } else return 1
    a = o.geodesicDistance(n, e)
    D = o.geodesicDistance(n, t)
    if (a < D) i.move2D(e.x, e.y)
    else i.move2D(t.x, t.y)
    return -1
  }
  return 1
}
function equal2DPoints(n, e) {
  return equalPoles(n, e) || (equalLongitudes(n, e) && equalLatitudes(n, e))
}
function equalPoles(n, e) {
  return isPole(n) && isPole(e) && Math.abs(n.y - e.y) < EQUAL_POINTS_EPSILON
}
function isPole(n) {
  return Math.abs(Math.cos(n.y)) < EQUAL_POINTS_EPSILON
}
function equalLongitudes(n, e) {
  return Math.abs(normalizeLon(n.x - e.x)) < EQUAL_POINTS_EPSILON
}
function equalLatitudes(n, e) {
  return Math.abs(n.y - e.y) < EQUAL_POINTS_EPSILON
}
export function lonLatContainedOnSegment2D(n, e, t, o, i, s, r) {
  let a
  let D
  let u
  let l
  const f = new LLHPoint(null, [e, t])
  const c = new LLHPoint(null, [o, i])
  const A = new LLHPoint(null, [s, r])
  a = n.geodesicDistance(f, A)
  if (a < EQUAL_POINTS_EPSILON) return true
  D = n.geodesicDistance(f, c)
  if (a > D + EQUAL_POINTS_EPSILON) return false
  u = rad2Deg(n.forwardAzimuth2D(f, A))
  l = rad2Deg(n.forwardAzimuth2D(f, c))
  return equalAzimuths(u, l)
}
export function closestPointOnGeodesic(n, e, t, o, i, s, r) {
  let a, D, u, l, f, c, A, m, d, E, P, g, L, h, C, S, x, y, z, G, O, _, N, R
  a = o.geodesicDistance(n, e)
  D = Math.max(s, i * a)
  if (0 === D) {
    D = o.a / Constants.EARTH_RADIUS
    if (D <= 1.1 && D >= 0.9) D = 1
  }
  if (a <= D) {
    r.move2D(n.x, n.y)
    return o.geodesicDistance(t, n)
  }
  u = o.forwardAzimuth2D(n, e) * Constants.RAD2DEG
  l = D / a
  f = 0
  c = 1
  A = (3 - Math.sqrt(5)) / 2
  m = 2 * (0 | Math.ceil(Math.abs(Math.log(l) / Math.log(A)))) + 1
  d = A
  o.geodesicPositionSFCT(n, d * a, u, r)
  E = o.geodesicDistance(t, r)
  P = d
  L = d
  g = E
  h = E
  for (C = 0; C < m; C++) {
    S = c - f
    x = (f + c) / 2
    y = 1e-12 * Math.abs(P) + l / 3
    if (Math.abs(P - x) + S / 2 <= 2 * y) return g
    z = A * (Math.abs(P) < x ? c - P : f - P)
    if (Math.abs(P - L) >= y) {
      _ = (P - L) * (g - E)
      O = (P - d) * (g - h)
      G = (P - d) * O - (P - L) * _
      O = 2 * (O - _)
      if (O > 0) G = -G
      else O = -O
      if (
        Math.abs(G) < Math.abs(z * O) &&
        G > O * (f - P + 2 * y) &&
        G < O * (c - P - 2 * y)
      )
        z = G / O
    }
    if (Math.abs(z) < y)
      if (z > 0) z = y
      else z = -y
    N = P + z
    o.geodesicPositionSFCT(n, N * a, u, r)
    R = o.geodesicDistance(t, r)
    if (R <= g) {
      if (N < P) c = P
      else f = P
      d = L
      L = P
      P = N
      E = h
      h = g
      g = R
    } else {
      if (N < P) f = N
      else c = N
      if (R <= h || L === P) {
        d = L
        L = N
        E = h
        h = R
      } else if (R <= h || d === P || d === L) {
        d = N
        E = R
      }
    }
  }
  return g
}
export function intersection2DLineSegments(n, e, t, o, i, s, r, a) {
  let D, u, l, f, c, A, m, d, E, P, g, L, h, C, S, x, y, z, G, O, _
  if (equal2DPoints(n, e))
    if (contains2D(i, t, o, n)) {
      r.move2D(n.x, n.y)
      return 1
    } else return 0
  if (equal2DPoints(t, o))
    if (contains2D(i, n, e, t)) {
      r.move2D(t.x, t.y)
      return 1
    } else return 0
  if (equal2DPoints(n, t)) {
    r.move2D((n.x + t.x) / 2, n.y)
    return intersection2DLineSegmentsWithCommonEndPoint(n, e, o, i, a)
  }
  if (equal2DPoints(n, o)) {
    r.move2D((n.x + o.x) / 2, n.y)
    return intersection2DLineSegmentsWithCommonEndPoint(n, e, t, i, a)
  }
  if (equal2DPoints(e, t)) {
    r.move2D((e.x + t.x) / 2, e.y)
    return intersection2DLineSegmentsWithCommonEndPoint(e, n, o, i, a)
  }
  if (equal2DPoints(e, o)) {
    r.move2D((e.x + o.x) / 2, e.y)
    return intersection2DLineSegmentsWithCommonEndPoint(e, n, t, i, a)
  }
  D = Constants.RAD2DEG * i.forwardAzimuth2D(n, e)
  u = Constants.RAD2DEG * i.forwardAzimuth2D(n, t)
  l = Constants.RAD2DEG * i.forwardAzimuth2D(n, o)
  f = normalizeAngle(l - u)
  if (!containsAngle(u, f, D, EQUAL_ANGLES_EPSILON)) return 0
  c = Constants.RAD2DEG * i.forwardAzimuth2D(e, t)
  A = Constants.RAD2DEG * i.forwardAzimuth2D(e, o)
  m = Constants.RAD2DEG * i.forwardAzimuth2D(e, n)
  f = normalizeAngle(A - c)
  if (!containsAngle(c, f, m, EQUAL_ANGLES_EPSILON)) return 0
  if (contains2D(i, t, o, n)) {
    r.move2D(n.x, n.y)
    if (contains2D(i, t, o, e)) {
      a.move2D(e.x, e.y)
      return -1
    } else if (contains2D(i, n, e, t)) {
      a.move2D(t.x, t.y)
      return -1
    } else if (contains2D(i, n, e, o)) {
      a.move2D(o.x, o.y)
      return -1
    } else return 1
  } else if (contains2D(i, t, o, e)) {
    r.move2D(e.x, e.y)
    if (contains2D(i, n, e, t)) {
      a.move2D(t.x, t.y)
      return -1
    } else if (contains2D(i, n, e, o)) {
      a.move2D(o.x, o.y)
      return -1
    } else return 1
  } else if (contains2D(i, n, e, t)) {
    r.move2D(t.x, t.y)
    if (contains2D(i, n, e, o)) {
      a.move2D(o.x, o.y)
      return -1
    }
    return 1
  } else if (contains2D(i, n, e, o)) {
    r.move2DToCoordinates(o.x, o.y)
    return 1
  }
  E = 0
  P = r
  g = a
  L = r.copy()
  h = i.geodesicDistance(n, e)
  C = i.geodesicDistance(t, o)
  S = s
  if (S <= 0) {
    S = i.a / Constants.EARTH_RADIUS
    if (S <= 1.1 && S >= 0.9) S = 1
  } else if (S < 0.001) S = 0.001
  x = Math.ceil(Math.log(C / S) / Math.log(2))
  if (h < C) {
    d = h
    P.move2D(n.x, n.y)
    g.move2D(e.x, e.y)
    G = Constants.RAD2DEG * i.forwardAzimuth2D(t, n)
    O = Constants.RAD2DEG * i.forwardAzimuth2D(t, o)
    for (y = 0; y < x; ++y) {
      d /= 2
      i.geodesicPositionSFCT(n, E + d, D, L)
      z = Constants.RAD2DEG * i.forwardAzimuth2D(t, L)
      f = normalizeAngle(z - G)
      if (containsAngle(G, f, O, 0)) g.move2D(L.x, L.y)
      else {
        P.move2D(L.x, L.y)
        E += d
      }
    }
    i.geodesicPositionSFCT(n, E + d / 2, D, r)
  } else {
    d = C
    P.move2D(t.x, t.y)
    g.move2D(o.x, o.y)
    O = Constants.RAD2DEG * i.forwardAzimuth2D(t, o)
    for (y = 0; y < x; ++y) {
      d /= 2
      i.geodesicPositionSFCT(t, E + d, O, L)
      _ = Constants.RAD2DEG * i.forwardAzimuth2D(n, L)
      f = normalizeAngle(_ - u)
      if (containsAngle(u, f, D, 0)) g.move2D(L.x, L.y)
      else {
        P.move2D(L.x, L.y)
        E += d
      }
    }
    i.geodesicPositionSFCT(t, E + d / 2, O, r)
  }
  return 1
}
export function intersects2DLS(n, e, t, o, i) {
  if (
    equal2DPoints(e, o) ||
    equal2DPoints(e, i) ||
    equal2DPoints(t, o) ||
    equal2DPoints(t, i)
  )
    return true
  if (equal2DPoints(e, t)) return contains2D(n, o, i, e)
  const s = Constants.RAD2DEG * n.forwardAzimuth2D(e, t)
  const r = Constants.RAD2DEG * n.forwardAzimuth2D(e, o)
  const a = Constants.RAD2DEG * n.forwardAzimuth2D(e, i)
  let D = normalizeAngle(a - r)
  if (180 === D) return true
  if (!containsAngleTolerance(r, D, s, EQUAL_ANGLES_EPSILON, 0)) return false
  const u = Constants.RAD2DEG * n.forwardAzimuth2D(t, o)
  const l = Constants.RAD2DEG * n.forwardAzimuth2D(t, i)
  const f = Constants.RAD2DEG * n.forwardAzimuth2D(t, e)
  D = normalizeAngle(l - u)
  return 180 === D || containsAngleTolerance(u, D, f, EQUAL_ANGLES_EPSILON, 0)
}
export function bufferContour2DOf2DPolylineSFCT(n, e, t, o) {
  const i = (t * Constants.RAD2DEG) / n.auxRadius
  bufferContour2DOf2DPolyline(e, i, o)
}
export function orientation2D(n, e) {
  const t = undefined
  return externalGeodesicArea(n, e) > 0
    ? PolygonOrientation.COUNTER_CLOCKWISE
    : PolygonOrientation.CLOCKWISE
}
export function geodesicArea(n, e) {
  let t = 0
  let o = 0
  const i = n.pointCount
  for (let e = 0; e < i; e++) {
    const s = n.getPoint(e)
    const r = n.getPoint((e + 1) % i)
    if (!s.equals(r)) {
      t += s.y
      o++
    }
  }
  t /= 0 == o ? 1 : o
  const s = Math.sin(t * Constants.DEG2RAD)
  const r = undefined
  const a = undefined
  return e.radiusVertical(s) * e.radiusMeridian(s) * externalGeodesicArea(n, e)
}
