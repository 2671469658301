export class ComplexInterval {
  constructor() {
    this._subInterval = []
    this._tmpSubInterval = []
  }
  reset() {
    this._subInterval = []
    this._tmpSubInterval = []
  }
  addSubInterval(t, s) {
    this._subInterval.push(t)
    this._subInterval.push(s)
  }
  subtractSubInterval(t, s) {
    this._tmpSubInterval = []
    for (let e = 0; e < this._subInterval.length / 2; e++) {
      const n = this._subInterval[2 * e]
      const r = this._subInterval[2 * e + 1]
      if (t > n && s < r) {
        this._tmpSubInterval.push(n)
        this._tmpSubInterval.push(t)
        this._tmpSubInterval.push(s)
        this._tmpSubInterval.push(r)
      } else if (t < n && s > r);
      else if (t > n && t < r) {
        this._tmpSubInterval.push(n)
        this._tmpSubInterval.push(t)
      } else if (s > n && s < r) {
        this._tmpSubInterval.push(s)
        this._tmpSubInterval.push(r)
      } else {
        this._tmpSubInterval.push(n)
        this._tmpSubInterval.push(r)
      }
    }
    const e = this._subInterval
    this._subInterval = this._tmpSubInterval
    this._tmpSubInterval = e
  }
  subtractComplexInterval(t) {
    for (let s = 0; s < t._subInterval.length / 2; s++) {
      const e = t._subInterval[2 * s]
      const n = t._subInterval[2 * s + 1]
      this.subtractSubInterval(e, n)
    }
  }
  findLargestInterval() {
    if (0 == this._subInterval.length) return null
    let t = this._subInterval[1] - this._subInterval[0]
    let s = this._subInterval[0]
    let e = this._subInterval[1]
    for (let n = 1; n < this._subInterval.length / 2; n++) {
      const r = this._subInterval[2 * n]
      const l = this._subInterval[2 * n + 1]
      const u = l - r
      if (u > t) {
        t = u
        s = r
        e = l
      }
    }
    return [s, e]
  }
  _contains(t, s) {
    for (let e = 0; e < this._subInterval.length / 2; e++) {
      const n = this._subInterval[2 * e]
      const r = this._subInterval[2 * e + 1]
      if (t >= n && s <= r) return true
    }
    return false
  }
  _getSubIntervalCount() {
    return this._subInterval.length / 2
  }
  _getSubIntervalStart(t) {
    return this._subInterval[2 * t]
  }
  _getSubIntervalEnd(t) {
    return this._subInterval[2 * t + 1]
  }
}
