import { isString } from '../../util/Lang.js'
let hiddenDiv = null
export const labelMetricsNodeId = '__ria_label_metrics'
function createHiddenDiv() {
  hiddenDiv = document.createElement('div')
  hiddenDiv.setAttribute('id', labelMetricsNodeId)
  hiddenDiv.style.visibility = 'hidden'
  hiddenDiv.style.position = 'absolute'
  hiddenDiv.style.width = 'auto'
  hiddenDiv.style.top = '0'
  hiddenDiv.style.left = '0'
  document.body.appendChild(hiddenDiv)
}
function cleanupHiddenDiv() {
  if (hiddenDiv)
    while (hiddenDiv.firstChild) hiddenDiv.removeChild(hiddenDiv.lastChild)
}
function wrapInHiddenDiv(i) {
  if (!hiddenDiv) createHiddenDiv()
  if (i instanceof HTMLElement) hiddenDiv.appendChild(i)
  else hiddenDiv.innerHTML = i
}
export function getTextNodeBounds(i, e) {
  wrapInHiddenDiv(i)
  const d = isString(i) && -1 === i.indexOf('<')
  const { firstChild: n, offsetWidth: t, offsetHeight: l } = hiddenDiv
  const o = e && n ? n.getBoundingClientRect() : { width: t, height: l }
  if (!d) cleanupHiddenDiv()
  return o
}
export function calculateBounds(i, e, d, n, t) {
  const l = getTextNodeBounds(i, t)
  const o = Math.round(l.width)
  const h = Math.round(l.height)
  let s, r, v, c
  if ('middle' === e) {
    s = -o / 2
    v = s + o
  } else if ('right' === e) {
    s = -o
    v = 0
  } else {
    s = 0
    v = o
  }
  if ('middle' === d) {
    r = -h / 2
    c = r + h
  } else if ('top' === d) {
    r = 0
    c = h
  } else {
    r = -h
    c = 0
  }
  n[0] = s - 1
  n[1] = r - 1
  n[2] = v + 1
  n[3] = c + 1
}
export function cleanupCache() {
  if (!hiddenDiv) return
  if (hiddenDiv.parentNode) hiddenDiv.parentNode.removeChild(hiddenDiv)
  hiddenDiv = null
}
