import { ProgrammingError } from '../../error/ProgrammingError.js'
import { FeatureModel } from '../../model/feature/FeatureModel.js'
import { isBoolean, isDefined, isFunction } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { releaseObject } from '../../util/ObjectReleaseTracker.js'
import { ShapeEditor } from '../editor/ShapeEditor.js'
import { Layer } from '../Layer.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { SelectionSupport } from '../SelectionSupport.js'
import { BasicFeaturePainter } from './BasicFeaturePainter.js'
import { LoadEverything } from './loadingstrategy/LoadEverything.js'
import { LoadSpatially } from './loadingstrategy/LoadSpatially.js'
import { MemoryWorkingSet } from './MemoryWorkingSet.js'
import { ProxyWorkingSet } from './ProxyWorkingSet.js'
import {
  SHAPE_PROVIDER_INVALIDATE_ALL_SHAPES_EVENT,
  SHAPE_PROVIDER_INVALIDATE_SHAPE_BY_ID_EVENT,
  SHAPE_PROVIDER_INVALIDATE_SHAPE_EVENT,
  ShapeProvider,
} from './ShapeProvider.js'
import { IdentityTransformer } from './transformation/IdentityTransformer.js'
import { HoverSupport } from '../HoverSupport.js'
import { createTransformationWithOptions } from '../../transformation/TransformationFactory.js'
import { isEditableStore } from '../../model/store/Store.js'
export let PerformanceHint = (function (e) {
  e[(e['PREFER_PERFORMANCE'] = 0)] = 'PREFER_PERFORMANCE'
  e[(e['PREFER_QUALITY'] = 1)] = 'PREFER_QUALITY'
  return e
})({})
let invalidateShapeWarningCounter = 0
const MAX_INVALIDATE_WARNINGS = 50
function warnShapeInvalidationFailed(e, t) {
  if (invalidateShapeWarningCounter < MAX_INVALIDATE_WARNINGS) {
    Log.warn(`Shape Invalidation (${e}): ${t}`)
    if (++invalidateShapeWarningCounter === MAX_INVALIDATE_WARNINGS)
      Log.warn('Shape Invalidation: logging more errors suppressed')
  }
}
export class FeatureLayer extends Layer {
  _isDefaultShapeProvider = true
  _shapeProviderHandles = []
  constructor(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    super(e, t)
    this.isSnapTarget = isBoolean(t.isSnapTarget) ? t.isSnapTarget : true
    this._selectionSupport = new SelectionSupport(
      this,
      (e, t) => {
        this._transformer.prepareForSelection(e, t)
        return this._bodyLabelRenderer?.setSelected(e, t) || null
      },
      this.filter
    )
    this.selectable = !!t.selectable
    this._rendererOptions = t.rendererOptions || {}
    this._filterSpatially = t.filterSpatially
    this.editable = isBoolean(t.editable)
      ? t.editable
      : isEditableStore(e.store)
    this.initTransformer(t.transformer)
    this.filter = t.filter || null
    this._featureEditor = new ShapeEditor()
    this._editedObject = null
    this.installLoadingStrategy(t)
    this.installShapeProvider(t.shapeProvider)
    this.validateLoadingStrategyCompatibility()
    this.initializePainter(t.painter)
    this.setupWorkingSet()
    this._bodyLabelRenderer = null
    this._bottomBorderBodyLabelRenderer = null
    this._leftBorderBodyLabelRenderer = null
    this._panoramaModel = t.panoramaModel || null
    this._hoverSupport = new HoverSupport(
      this,
      !!t.hoverable,
      (e) => {
        this.painter?.invalidateById(e.id)
        return this._workingSet.getFeature(e.id)
      },
      this.filter
    )
    this._rendererOptions.canUseEarcut =
      t.performanceHints?.tessellation === PerformanceHint.PREFER_PERFORMANCE
    this._rendererOptions.noDiscretization =
      t.performanceHints?.discretization === PerformanceHint.PREFER_PERFORMANCE
    this._rendererOptions.maxPaintUpdateTimePerFrame =
      t.performanceHints?.maxPaintUpdateTimePerFrame
  }
  installLoadingStrategy(e) {
    let { loadingStrategy: t, incrementalRendering: r, query: i } = e
    this._loadingStrategySetByUser = !!t
    if (t)
      if (t.isCompatibleWithModel(this.model)) this._loadingStrategy = t
      else
        throw new ProgrammingError(
          'Cannot use LoadSpatially if the FeatureModel does not support the spatialQuery function'
        )
    else
      this._loadingStrategy = LoadSpatially.prototype.isCompatibleWithModel(
        this.model
      )
        ? new LoadSpatially({})
        : new LoadEverything({ query: i })
    if (isDefined(r))
      Log.warn(
        `The incrementalRendering flag is deprecated. This option may be removed in future versions of the product.`
      )
    this._incremental = isBoolean(r)
      ? r
      : this._loadingStrategy.isRenderIncremental()
  }
  initializePainter(e) {
    if (!e) e = new BasicFeaturePainter()
    this._painter = e
    this._supportedPainterRepresentations = e.getSupportedPaintRepresentations()
    const t = Object.assign({}, this._isVisiblePaintRepresentation)
    this._supportedPainterRepresentations.forEach((e) => {
      let r = t[e]
      r = isDefined(r) ? r : true
      this.setPaintRepresentationVisible(e, r)
      delete t[e]
    })
    for (const e in t)
      if (t.hasOwnProperty(e)) {
        const t = e
        const r = this._isVisiblePaintRepresentation[t]
        delete this._isVisiblePaintRepresentation[t]
        if (r) this.emit('PaintRepresentationVisibilityChanged', false, t)
      }
    this._oneFeatureChanged = (e) => {
      this._bodyLabelRenderer?._invalidateFeature(e)
      this._bottomBorderBodyLabelRenderer?._invalidateFeature(e)
      this._leftBorderBodyLabelRenderer?._invalidateFeature(e)
      this._selectionSupport.featureChanged(e)
      this._hoverSupport.itemChanged(e)
      this._invalidate()
    }
    this._oneFeatureChangedById = (e) => {
      const t = this._workingSet.getFeature(e)
      if (t) this._oneFeatureChanged(t)
    }
    this._allFeaturesChanged = () => {
      this._bodyLabelRenderer?._invalidateAllFeatures()
      this._bottomBorderBodyLabelRenderer?._invalidateAllFeatures()
      this._leftBorderBodyLabelRenderer?._invalidateAllFeatures()
      this._invalidate()
    }
    this._layerVisibilityChanged = () => {
      if (this._bodyLabelRenderer?.setEnabled)
        this._bodyLabelRenderer.setEnabled(
          this.visible &&
            this.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY)
        )
    }
    this._painter.on('Invalidate', this._oneFeatureChanged)
    this._painter.on('InvalidateById', this._oneFeatureChangedById)
    this._painter.on('InvalidateAll', this._allFeaturesChanged)
    this.on(
      'PaintRepresentationVisibilityChanged',
      this._layerVisibilityChanged
    )
    this.on('VisibilityChanged', this._layerVisibilityChanged)
  }
  setupWorkingSet() {
    this._workingSet = new ProxyWorkingSet()
    this._workingSet.on('WorkingSetChanged', (e, t, r) => {
      if ('remove' === e) {
        this._selectionSupport.propagateSelectionEvent(
          this._map,
          this._selectionSupport.unselect(r)
        )
        this._hoverSupport.vacate(r)
      } else if ('clear' === e) {
        this._selectionSupport.propagateSelectionEvent(
          this._map,
          this._selectionSupport.clearSelection()
        )
        this._hoverSupport.clearHover()
      }
      this._invalidate()
    })
    this._workingSet.on('QueryError', this._invalidate.bind(this))
    this._workingSet.on('QueryFinished', this._invalidate.bind(this))
  }
  getMapToViewTransformation(e) {
    return this._map.getMapToBorderViewTransformation(e)
  }
  pick(e) {
    if (
      !this._map ||
      !this.visible ||
      !this.inAllowedScaleRange() ||
      !this._bodyLabelRenderer
    )
      return []
    return this._bodyLabelRenderer.pick(e)
  }
  _addedToMap(e) {
    super._addedToMap(e)
    if (!this._loadingStrategy.layer)
      this._loadingStrategy.registerFeatureLayer(this)
    e.onReady(() => {
      this._reboot(e)
      this._layerVisibilityChanged()
      this._loadingStrategy.onAddedToMap()
    })
  }
  releaseRenderers() {
    this._workingSet.setDelegateWorkingSet(null)
    this._bodyLabelRenderer = releaseObject(this._bodyLabelRenderer)
    this._bottomBorderBodyLabelRenderer = releaseObject(
      this._bottomBorderBodyLabelRenderer
    )
    this._leftBorderBodyLabelRenderer = releaseObject(
      this._leftBorderBodyLabelRenderer
    )
  }
  releaseShapeProvider() {
    this._shapeProviderHandles.forEach((e) => e.remove)
    this._shapeProviderHandles.length = 0
  }
  inspect_hasShapeProviderListeners() {
    return this._shapeProviderHandles.length > 0
  }
  shouldFilterSpatially() {
    if (this._map._isSoftwareMap()) return true
    if (this._loadingStrategy instanceof LoadSpatially) return false
    if (!isDefined(this._filterSpatially)) {
      const e = this._loadingStrategy.queryProvider.getQueryLevelScales()
      const t = this._painter.getDetailLevelScales()
      return !(
        this.scaleRange.min <= 1e-9 &&
        (!e || 0 === e.length) &&
        (!t || 0 === t.length)
      )
    } else return !!this._filterSpatially
  }
  _reboot(e) {
    this._updateModelWorldTransformation(this._shapeReference)
    if (!this._modelToWorldTransformation) {
      Log.warn(
        'References are incompatible. Cannot transform from one to the other. ' +
          'Perhaps you forgot to set a ShapeProvider on the layer.'
      )
      throw new ProgrammingError(
        'Cannot create a transformation between the reference of the model and the ' +
          'reference of the Map. Either adjust the reference or set a ShapeProvider on the layer.'
      )
    }
    this.releaseRenderers()
    const t = {
      schedule: this._schedule.bind(this),
      invalidate: this._invalidate.bind(this),
    }
    const r = this.shouldFilterSpatially()
    const i = {
      layer: this,
      map: e,
      painter: this._painter,
      incremental: this._incremental,
      scheduler: t,
      rendererOptions: this._rendererOptions,
      filterSpatially: r,
    }
    const a = e.viewPaintingStrategy.techContext
    this._bodyLabelRenderer = a.featureLayerRenderer.create(
      a,
      i,
      PaintRepresentation.BODY,
      PaintRepresentation.LABEL
    )
    this._bottomBorderBodyLabelRenderer = a.featureLayerBorderRenderer.create(
      a,
      i,
      PaintRepresentation.BOTTOM_BORDER_BODY,
      PaintRepresentation.BOTTOM_BORDER_LABEL
    )
    this._leftBorderBodyLabelRenderer = a.featureLayerBorderRenderer.create(
      a,
      i,
      PaintRepresentation.LEFT_BORDER_BODY,
      PaintRepresentation.LEFT_BORDER_LABEL
    )
    const n = isFunction(this._bodyLabelRenderer.getWorkingSet)
      ? this._bodyLabelRenderer.getWorkingSet()
      : new MemoryWorkingSet(this, i)
    this._workingSet.setDelegateWorkingSet(n)
    this._bodyLabelRenderer.filterChanged(this._filter)
    if (this._editedObject)
      this._bodyLabelRenderer.setEdited(this._editedObject, true)
    this._worldBoundsChanged(e)
    this.initTransformer(this._exposedTransformer)
  }
  getWorkingSetReference() {
    return this.shapeProvider ? this._shapeReference : this._map.reference
  }
  _removedFromMap(e) {
    this.releaseRenderers()
    this.releaseShapeProvider()
    this._transformer.release()
    super._removedFromMap(e)
  }
  isPaintRepresentationSupported(e) {
    return null !== this.getPainterForPaintRepresentation(e)
  }
  getPainterForPaintRepresentation(e) {
    return this._supportedPainterRepresentations.indexOf(e) > -1
      ? this._painter || null
      : null
  }
  canDrawLabels() {
    let e =
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : PaintRepresentation.LABEL
    const t = undefined
    return (
      (e === PaintRepresentation.LABEL
        ? isFunction(this.painter.paintLabel)
        : isFunction(this.painter.paintBorderLabel)) &&
      this.isPaintRepresentationVisibleInTree(e) &&
      this.visibleInTree &&
      this.inAllowedScaleRange()
    )
  }
  get editable() {
    return super.editable
  }
  set editable(e) {
    if (
      e &&
      this.model &&
      this.model instanceof FeatureModel &&
      !isEditableStore(this.model.store)
    )
      throw new ProgrammingError(
        "Cannot set FeatureLayer.editable to true. The FeatureModel's store does not support editing operations (add/put/remove)."
      )
    super.editable = e
  }
  get model() {
    return super.model
  }
  refreshQueryResults() {
    if (!this._map || !this.visible || !this.inAllowedScaleRange()) return
    this._workingSet.refreshWorkingSet(this._loadingStrategy)
  }
  _paintBodyLabels(e, t, r) {
    if (this._bodyLabelRenderer) return this._bodyLabelRenderer.paint(e, t, r)
    return false
  }
  _paintBorderBodyLabels(e, t) {
    if (this._bottomBorderBodyLabelRenderer)
      this._bottomBorderBodyLabelRenderer.paint(e, t)
    if (this._leftBorderBodyLabelRenderer)
      this._leftBorderBodyLabelRenderer.paint(e, t)
  }
  _collectLabels(e, t, r) {
    if (this._bodyLabelRenderer) this._bodyLabelRenderer.collectLabels(e, t, r)
  }
  setEditedObject(e) {
    if (null !== this._editedObject)
      if (this._bodyLabelRenderer)
        this._bodyLabelRenderer.setEdited(this._editedObject, false)
    this._editedObject = e
    if (null !== this._editedObject)
      if (this._bodyLabelRenderer)
        this._bodyLabelRenderer.setEdited(this._editedObject, true)
    this._invalidate()
  }
  getEditedFeature() {
    return this._editedObject
  }
  _filteredWorkingSetSearch(e, t) {
    this._bodyLabelRenderer?.search(e, t)
  }
  fetchProvidedShape(e) {
    const t = this._workingSet.getNode(e.id)
    return t && t.feature === e && this._editedObject !== e ? t.shape : e.shape
  }
  installShapeProvider(e) {
    this.releaseShapeProvider()
    if (e && !ShapeProvider.prototype.isPrototypeOf(e))
      throw new ProgrammingError(
        'a shapeProvider needs to be a subclass of ShapeProvider'
      )
    this._isDefaultShapeProvider = !e
    this._shapeProvider = e || new ShapeProvider()
    this._shapeReference = this.shapeProvider.reference || this.model.reference
    this._shapeProviderHandles.push(
      this._shapeProvider.on(
        SHAPE_PROVIDER_INVALIDATE_ALL_SHAPES_EVENT,
        this.invalidateAllShapes.bind(this)
      ),
      this._shapeProvider.on(
        SHAPE_PROVIDER_INVALIDATE_SHAPE_EVENT,
        this.invalidateFeatureShape.bind(this)
      ),
      this._shapeProvider.on(
        SHAPE_PROVIDER_INVALIDATE_SHAPE_BY_ID_EVENT,
        this.invalidateFeatureShapeById.bind(this)
      )
    )
  }
  invalidateFeatureShapeById(e) {
    const t = this._workingSet.getFeature(e)
    if (t) this.invalidateFeatureShape(t, true)
  }
  invalidateFeatureShape(e, t) {
    if (t || (e && this._workingSet.getFeature(e.id)))
      try {
        this._workingSet._updateObject(e, e.id)
      } catch (t) {
        warnShapeInvalidationFailed(
          e.id,
          t instanceof Error ? t.message : 'unknown error message'
        )
      }
  }
  invalidateAllShapes() {
    const e = undefined
    this._workingSet.get().forEach((e) => this.invalidateFeatureShape(e))
  }
  isReady() {
    return (
      !!this._map &&
      this._workingSet.isReady() &&
      (this._bodyLabelRenderer?.isReady() ?? true)
    )
  }
  initTransformer(e) {
    releaseObject(this._transformer)
    this._exposedTransformer = e
    this._transformer = e || new IdentityTransformer()
  }
  get query() {
    Log.deprecated('query', 'LoadingStrategy#queryProvider')
    return isFunction(this._loadingStrategy.getQuery)
      ? this._loadingStrategy.getQuery()
      : null
  }
  set query(e) {
    Log.deprecated('query', 'LoadingStrategy#queryProvider')
    if (isFunction(this._loadingStrategy.setQuery))
      this._loadingStrategy.setQuery(e)
    else throw new ProgrammingError('Cannot set query object')
  }
  get transformer() {
    return this._exposedTransformer
  }
  set transformer(e) {
    if (this._exposedTransformer !== e) {
      this.initTransformer(e)
      this._loadingStrategy.invalidate()
      this.refreshQueryResults()
    }
  }
  get supportedPaintRepresentations() {
    if (this._supportedPainterRepresentations)
      return this._supportedPainterRepresentations.slice()
    return []
  }
  get bounds() {
    return this.model.bounds || this._workingSet.bounds || void 0
  }
  get painter() {
    return this._painter
  }
  set painter(e) {
    this.initializePainter(e)
    this._bodyLabelRenderer?.resetPainter(e)
    this._invalidate()
  }
  get loadingStrategy() {
    return this._loadingStrategy
  }
  get shapeProvider() {
    return this._shapeProvider
  }
  set shapeProvider(e) {
    const t = this._shapeProvider
    this.installShapeProvider(e)
    this.validateLoadingStrategyCompatibility()
    if (this._map)
      try {
        this._reboot(this._map)
      } catch (e) {
        this.installShapeProvider(t)
        throw e
      }
    this.emit('ShapeProviderChange', {
      previousShapeProvider: t,
      newShapeProvider: this._shapeProvider,
    })
  }
  get isDefaultShapeProvider() {
    return this._isDefaultShapeProvider
  }
  get workingSet() {
    return this._workingSet
  }
  get filter() {
    return this._filter || null
  }
  set filter(e) {
    if (!isFunction(e) && null !== e)
      throw new ProgrammingError('Filter must be either a function or null.')
    this._filter = e
    if (this._map) {
      this._selectionSupport.propagateSelectionEvent(
        this._map,
        this._selectionSupport.updateFilter(this.filter)
      )
      this._hoverSupport.updateFilter(this.filter)
      this._bodyLabelRenderer?.filterChanged(e)
      if (!(this._transformer instanceof IdentityTransformer)) {
        this._transformer.resetCaches()
        this.refreshQueryResults()
      } else this._invalidate()
    } else {
      this._selectionSupport?.updateFilter(this.filter)
      this._hoverSupport?.updateFilter(this.filter)
      this._bodyLabelRenderer?.filterChanged(e)
    }
  }
  get selectable() {
    return this._selectionSupport.selectable
  }
  set selectable(e) {
    this._selectionSupport.selectable = e
  }
  get hoverable() {
    return this._hoverSupport.enabled
  }
  set hoverable(e) {
    this._hoverSupport.enabled = e
  }
  setHovered(e, t) {
    return this._hoverSupport.setHoveredItems(e, t)
  }
  isHovered(e) {
    return this._hoverSupport.isHovered(e)
  }
  get panoramaModel() {
    return this._panoramaModel
  }
  getSelectedFeatures() {
    return this._selectionSupport.getSelectedFeatures()
  }
  getHoveredFeatures() {
    return this._hoverSupport.getHoveredItems()
  }
  clearSelection() {
    return this._selectionSupport.clearSelection()
  }
  clearHovered() {
    return this._hoverSupport.clearHover()
  }
  isSelected(e) {
    return this._selectionSupport.isSelected(e)
  }
  selectFeatures(e) {
    return this._selectionSupport.selectFeatures(e)
  }
  validateLoadingStrategyCompatibility() {
    if (
      this.loadingStrategy instanceof LoadSpatially &&
      !this._shapeReference.equals(this.model.reference)
    )
      try {
        createTransformationWithOptions(
          this._shapeReference,
          this.model.reference,
          {}
        )
      } catch (e) {
        if (!this._loadingStrategySetByUser && !this._loadingStrategy.layer) {
          this._loadingStrategy = new LoadEverything()
          this._incremental = this._loadingStrategy.isRenderIncremental()
        } else {
          const t = e instanceof Error ? e.message : '' + e
          throw new ProgrammingError(
            `FeatureLayer '${this.label}' with LoadSpatially strategy cannot be used on a Cartesian map - ` +
              `the query BBOX shape cannot be converted from map reference to the model reference. (${t})`
          )
        }
      }
  }
}
