import { isUndefined } from '../../../../../util/Lang.js'
import { isMultiType, isWrapperType } from './PatternType.js'
import {
  instanceOfIStroked,
  instanceOfIWorldSized,
} from './PatternInterfaces.js'
const STOP_DISTANCE_TO = 0.1
const STOP_PROGRESS = 0.001
const DEFAULT_LINE_COLOR = 'rgb(0,0,0)'
const DEFAULT_LINE_WIDTH = 1
export const EPSILON = 1e-8
export const PIXEL_EPSILON = 0.51
export function defaultLineIfNecessary(t) {
  const e = !isUndefined((t = t || {}).line) && null !== t.line && t.line.color
  const n = !isUndefined(t.line) && null !== t.line && t.line.width
  const i = t.fill && t.fill.color
  const r = undefined
  const o = undefined
  if ((!e && !i) || (e && !n) || (n && !e))
    return {
      ...t,
      line: {
        color: !e && null !== e ? DEFAULT_LINE_COLOR : e,
        width: n || DEFAULT_LINE_WIDTH,
      },
    }
  return t
}
export function isCombineWithPattern(t, e) {
  if (t.patternType === e) return true
  if (isWrapperType(t)) return isCombineWithPattern(t.pattern, e)
  if (isMultiType(t)) {
    for (let n = 0; n < t.patterns.length; n++) {
      const i = undefined
      if (isCombineWithPattern(t.patterns[n], e)) return true
    }
    return false
  }
  return false
}
function getDistanceToNextVertex(t, e) {
  return !t.atEnd() ? e - t.absoluteDistanceToNextVertex() : 0
}
export function drawSimpleLine(t, e, n, i, r) {
  const o = Math.min(i, r[1])
  let a = t.distanceFromStart()
  let s = getDistanceToNextVertex(t, o)
  let l = true
  while (!t.atEnd() && l && s > STOP_DISTANCE_TO) {
    drawSimplifiedStyle(t, e, n, s)
    const i = t.distanceFromStart()
    l = i - a > STOP_PROGRESS
    a = i
    s = getDistanceToNextVertex(t, o)
  }
  if (!t.atEnd() && s > 0) drawSimplifiedStyle(t, e, n, s)
}
function drawSimplifiedStyle(t, e, n, i) {
  e.beginPath()
  e.moveTo(t.x(), t.y())
  t.advanceDistance(i)
  e.lineTo(t.x(), t.y())
  n.simpleStroke(e)
}
export function drawFlexibleStyleLine(t, e, n, i, r) {
  let o = t.distanceFromStart()
  let a = getDistanceToNextVertex(t, i)
  let s = true
  while (!t.atEnd() && s && a > STOP_DISTANCE_TO) {
    n.paintFlexibleWithClipLimits(e, t, t.distanceToNextVertex(), r)
    const l = t.distanceFromStart()
    s = l - o > STOP_PROGRESS
    o = l
    a = getDistanceToNextVertex(t, i)
  }
  const l = i - t.distanceFromStart()
  if (!t.atEnd() && l > 0) n.paintFlexibleWithClipLimits(e, t, l, r)
}
export function drawRegularOnLine(t, e, n, i, r) {
  if (n.flexible) drawFlexibleStyleLine(t, e, n, i, r)
  else drawFixedWidthStyleLine(t, e, n, i, r)
}
export function drawStyledLine(t, e, n, i, r) {
  drawRegularOnLine(t, e, n, i, r)
  drawSimpleLine(t, e, n, i, r)
}
function drawFixedWidthStyleLine(t, e, n, i, r) {
  let o = t.distanceFromStart()
  let a = i - o
  let s = true
  while (!t.atEnd() && s && a > STOP_DISTANCE_TO) {
    n.paintOnceOnLine(e, t, r)
    const l = t.distanceFromStart()
    s = l - o > STOP_PROGRESS
    o = l
    a = i - l
  }
  if (!t.atEnd() && !n.atomic && o < i) n.paintPartial(e, t, i, r)
}
export function findStrokedPattern(t) {
  if (instanceOfIStroked(t)) return t
  if (isWrapperType(t)) return findStrokedPattern(t.pattern)
  if (isMultiType(t))
    for (let e = 0; e < t.patterns.length; e++) {
      const n = findStrokedPattern(t.patterns[e])
      if (n) return n
    }
  return null
}
export function injectWorldSizeContext(t, e) {
  let n = false
  if (isWrapperType(t)) {
    injectWorldSizeContext(t.pattern, e)
    n = true
  }
  if (isMultiType(t)) {
    for (let n = 0; n < t.patterns.length; n++)
      injectWorldSizeContext(t.patterns[n], e)
    n = true
  }
  if (!n) if (instanceOfIWorldSized(t)) t.worldSizeContext = e
}
export function advanceWithLimit(t, e, n) {
  if (t.distanceFromStart() + e > n)
    t.advanceDistance(n - t.distanceFromStart())
  else t.advanceDistance(e)
}
export function handleClipLimits(t, e, n, i, r) {
  const o = 1e3
  const a = e.distanceFromStart()
  const s = a < i[0]
  const l = a + n > i[1] + PIXEL_EPSILON
  if (s || l) {
    const e = s ? i[0] - a : 0
    const r = l ? i[1] - a : n
    t.rect(e, -o, r, 2 * o)
    t.clip()
  }
  if (!e.isInJump()) {
    const n = 0.5
    const i = 2 * Math.max(Math.abs(r[0]), Math.abs(r[1]))
    const o = e.bisectorAtStartOfCurrentSegment()
    const a = e.bisectorAtEndOfCurrentSegment()
    const s = -e.distanceToPreviousVertex() - n
    const l = e.distanceToNextVertex() + n
    const c = -i - n
    const d = i + n
    const f = s + c / Math.tan(o)
    const S = s + d / Math.tan(o)
    const p = l + c / Math.tan(a)
    const u = l + d / Math.tan(a)
    t.scale(1, -1)
    t.beginPath()
    t.moveTo(f, c)
    t.lineTo(S, d)
    t.lineTo(u, d)
    t.lineTo(p, c)
    t.lineTo(f, c)
    const T = undefined
    if (false) {
      const e = 256 * Math.random()
      const n = 256 * Math.random()
      const i = 256 * Math.random()
      t.lineWidth = 2
      t.strokeStyle = `rgb(${e},${n},${i})`
      t.stroke()
    }
    t.clip()
    t.scale(1, -1)
  }
}
