import { Constants } from '../util/Constants.js'
import { ShapeType } from '../shape/ShapeType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { LineType } from './LineType.js'
import {
  closestPointOnGeodesic,
  geodesicArea,
  greatCircleDistance,
  greatCirclePointAtFractionSFCT,
  greatCirclePointSFCT,
  rhumblineAzimuth2D,
  rhumblineDistance,
  rhumblinePointSFCT,
} from './SphereUtil.js'
import { forwardAzimuth2D } from './AzimuthUtil.js'
import { GeodeticGeodesy } from './GeodeticGeodesy.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { Ellipsoid } from './Ellipsoid.js'
import { distance3D as cartesianDistance3D } from '../util/Cartesian.js'
export class SphericalGeodesy extends GeodeticGeodesy {
  constructor(e, t) {
    super(e)
    this._earthRadius = t ? t : null
    this._EPSILON = 0.01
  }
  distanceImpl(e, t, i, s) {
    const r = this._earthRadius || e.radiusEuler(t.y, e.forwardAzimuth2D(t, i))
    switch (s) {
      default:
      case LineType.SHORTEST_DISTANCE:
        return greatCircleDistance(t, i) * Constants.DEG2RAD * r
      case LineType.CONSTANT_BEARING:
        return rhumblineDistance(t, i) * Constants.DEG2RAD * r
    }
  }
  forwardAzimuthImpl(e, t, i, s) {
    switch (s) {
      default:
      case LineType.SHORTEST_DISTANCE:
        return forwardAzimuth2D(t, i) * Constants.RAD2DEG
      case LineType.CONSTANT_BEARING:
        return rhumblineAzimuth2D(t, i) * Constants.RAD2DEG
    }
  }
  interpolateDistanceAzimuthImpl(e, t, i, s, r, n) {
    const a = this._earthRadius || e.radiusEuler(t.y, s)
    switch (r) {
      default:
      case LineType.SHORTEST_DISTANCE:
        greatCirclePointSFCT(t, (i / a) * Constants.RAD2DEG, s, n)
        break
      case LineType.CONSTANT_BEARING:
        rhumblinePointSFCT(t, (i / a) * Constants.RAD2DEG, s, n)
        break
    }
    return n
  }
  interpolateFractionImpl(e, t, i, s, r, n) {
    switch (r) {
      default:
      case LineType.SHORTEST_DISTANCE:
        greatCirclePointAtFractionSFCT(t, i, s, n)
        break
      case LineType.CONSTANT_BEARING: {
        const e = Constants.RAD2DEG * rhumblineAzimuth2D(t, i)
        const r = rhumblineDistance(t, i)
        rhumblinePointSFCT(t, s * r, e, n)
        break
      }
    }
    return n
  }
  calculateDistance3D(e, t, i, s, r, n, a, o) {
    const c = this.interpolate(t, i, 0.5, e)
    const l = createPoint(null, [])
    a.geod2geocSFCT(c, l)
    const h = cartesianDistance3D(s, l)
    const u = cartesianDistance3D(l, r)
    const p = h + u
    const m = p - n
    if (Math.abs(m) < o) return p
    else {
      const n = undefined
      const p = undefined
      return (
        this.calculateDistance3D(e, t, c, s, l, h, a, 0.5 * o) +
        this.calculateDistance3D(e, c, i, l, r, u, a, 0.5 * o)
      )
    }
  }
  distance3D(e, t, i) {
    if (Math.abs(e.z) < 1e-8 && Math.abs(t.z) < 1e-8)
      return this.distanceImpl(this.ellipsoid, e, t, i)
    const s =
      this._earthRadius ||
      this.ellipsoid.radiusEuler(e.y, this.ellipsoid.forwardAzimuth2D(e, t))
    const r = new Ellipsoid()
    r.initializeAB(s, s)
    const n = createPoint(null, [])
    const a = createPoint(null, [])
    r.geod2geocSFCT(e, n)
    r.geod2geocSFCT(t, a)
    const o = cartesianDistance3D(n, a)
    const c = Math.max(0.001, 1e-6 * o)
    return this.calculateDistance3D(i, e, t, n, a, o, r, c)
  }
  shortestDistanceToLine(e, t, i, s, r) {
    const n = closestPointOnGeodesic(t, i, e, s && !!s.clipToSegment, r)
    const a =
      this._earthRadius ||
      this.ellipsoid.radiusEuler(e.y, this.ellipsoid.forwardAzimuth2D(e, r))
    return n * Constants.DEG2RAD * a
  }
  area(e) {
    if (!ShapeType.contains(e.type, ShapeType.POLYGON))
      throw new ProgrammingError(
        'Only the calculation of the area of a polygon is supported at the moment'
      )
    return Math.abs(geodesicArea(e, this._earthRadius))
  }
}
