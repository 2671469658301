import { ProgrammingError } from '../error/ProgrammingError.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
import { isNumber } from '../util/Lang.js'
export class ExtrudedShape extends Shape {
  constructor(e, t, i, a) {
    super()
    if (!ExtrudedShape.isSupportedBaseShape(t))
      throw new ProgrammingError(
        'ExtrudedShape: invalid base shape: it must be a 2D line or area'
      )
    this._baseShape = t
    if (e !== t.reference)
      throw new ProgrammingError(
        'ExtrudedShape: reference must be the same as the base shape reference'
      )
    if (!isNumber(i))
      throw new ProgrammingError(
        'ExtrudedShape: cannot create an ExtrudedShape without a proper min height'
      )
    this._minimumHeight = i
    if (!isNumber(a))
      throw new ProgrammingError(
        'ExtrudedShape: cannot create an ExtrudedShape without a proper max height'
      )
    this._maximumHeight = a
    if (i > a)
      throw new ProgrammingError(
        `ExtrudedShape: min height should be lower than or equal to max height: ${i} > ${a}`
      )
  }
  get type() {
    return ShapeType.EXTRUDED_SHAPE
  }
  get focusPoint() {
    if (!this._baseShape.focusPoint) return null
    const e = this._baseShape.focusPoint.copy()
    e.z = (this._minimumHeight + this._maximumHeight) / 2
    return e
  }
  set focusPoint(e) {
    throw new ProgrammingError('focusPoint property is not mutable')
  }
  get baseShape() {
    return this._baseShape
  }
  set baseShape(e) {
    if (!ExtrudedShape.isSupportedBaseShape(e))
      throw new ProgrammingError(
        'Invalid base shape: It must be a polyline or a polygon'
      )
    this._baseShape = e
  }
  get minimumHeight() {
    return this._minimumHeight
  }
  set minimumHeight(e) {
    if (e > this._maximumHeight)
      throw new ProgrammingError(
        `ExtrudedShape: min height should be lower than or equal to max height: ${e} > ${this._maximumHeight}`
      )
    this._minimumHeight = e
  }
  get maximumHeight() {
    return this._maximumHeight
  }
  set maximumHeight(e) {
    if (e < this._minimumHeight)
      throw new ProgrammingError(
        `ExtrudedShape: max height should be higher than or equal to min height: ${e} < ${this._minimumHeight}`
      )
    this._maximumHeight = e
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    this._baseShape.hashCode(e)
    e.appendDouble(this._minimumHeight)
    e.appendDouble(this._maximumHeight)
  }
  equals(e) {
    if (this === e) return true
    if (!ShapeUtil.referencesEqual(this.reference, e.reference)) return false
    return (
      this.type === e.type &&
      this._minimumHeight === e.minimumHeight &&
      this._maximumHeight === e.maximumHeight &&
      this._baseShape.equals(e.baseShape)
    )
  }
  translate2D(e, t) {
    this._baseShape.translate2D(e, t)
  }
  toString() {
    return `ExtrudedShape[base shape[${this._baseShape.toString()}], minimumHeight[${
      this._minimumHeight
    }], maximumHeight[${this._maximumHeight}]`
  }
  invalidate() {}
  static isSupportedBaseShape(e) {
    if (e.bounds && (0 !== e.bounds.depth || 0 !== e.bounds.z)) return false
    if (ShapeType.contains(e.type, ShapeType.SHAPE_LIST)) {
      for (let t = 0; t < e.shapeCount; t++)
        if (!ExtrudedShape.isSupportedBaseShape(e.getShape(t))) return false
      return true
    }
    return (
      ShapeType.contains(e.type, ShapeType.POLYLINE) ||
      ShapeType.contains(e.type, ShapeType.POLYGON) ||
      ShapeType.contains(e.type, ShapeType.COMPLEX_POLYGON) ||
      ShapeType.contains(e.type, ShapeType.CIRCLE) ||
      ShapeType.contains(e.type, ShapeType.CIRCLE_BY_CENTER_POINT) ||
      ShapeType.contains(e.type, ShapeType.CIRCLE_BY_3_POINTS) ||
      ShapeType.contains(e.type, ShapeType.BOUNDS) ||
      ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC) ||
      ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC_BY_CENTER_POINT) ||
      ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC_BY_3_POINTS) ||
      ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC_BY_BULGE) ||
      ShapeType.contains(e.type, ShapeType.ELLIPSE) ||
      ShapeType.contains(e.type, ShapeType.GEO_BUFFER) ||
      ShapeType.contains(e.type, ShapeType.ARC) ||
      ShapeType.contains(e.type, ShapeType.ARC_BAND) ||
      ShapeType.contains(e.type, ShapeType.SECTOR)
    )
  }
  get bounds() {
    let e = this.baseShape.bounds
    if (!e) return null
    e = e.copy()
    e.z = this._minimumHeight
    e.depth = this._maximumHeight - this._minimumHeight
    return e
  }
  set bounds(e) {
    throw new ProgrammingError('bounds property is not mutable')
  }
  get reference() {
    return this._baseShape.reference
  }
  get coordinateType() {
    return this._baseShape.coordinateType
  }
  copy() {
    return new ExtrudedShape(
      this._baseShape.reference,
      this._baseShape.copy(),
      this._minimumHeight,
      this._maximumHeight
    )
  }
  contains2DCoordinates(e, t) {
    return this._baseShape.contains2DCoordinates(e, t)
  }
  contains3D(e) {
    return (
      this._baseShape.contains2DCoordinates(e.x, e.y) &&
      this._minimumHeight <= e.z &&
      e.z <= this._maximumHeight
    )
  }
  get isGeodetic() {
    return this._baseShape.isGeodetic
  }
  contains3DCoordinates(e, t, i) {
    throw new Error('Method not implemented.')
  }
}
export function isExtrudedShape(e) {
  return ShapeType.contains(e.type, ShapeType.EXTRUDED_SHAPE)
}
