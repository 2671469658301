import { request } from './request.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { isArray } from './Lang.js'
let licenseData = null
export function getData() {
  if (null === licenseData)
    return Promise.reject('licenseData was not loaded through this module')
  else return licenseData
}
function setLicenseText(e) {
  licenseData = Promise.resolve(e)
}
function loadLicenseFromUrl(e) {
  licenseData = new Promise((r, t) => {
    request(e, { method: 'GET' })
      .then((e) => e.text())
      .then(r, t)
  })
  return licenseData
}
function loadLicenseFromUrls(e) {
  const r = []
  if (!isArray(e) || 0 === e.length) {
    licenseData = null
    return Promise.reject('URLs not provided')
  }
  const t = e.length
  if (1 === t) return loadLicenseFromUrl(e[0])
  let n = 0
  licenseData = new Promise((o, s) => {
    for (let i = 0; i < t; i++) {
      const a = e[i]
      request(a, { method: 'GET' })
        .then((e) => e.text())
        .then(o, (e) => {
          n += 1
          r.push(e.message || '' + e)
          if (n === t) s(new ProgrammingError(r.join('\n')))
        })
    }
  })
  return licenseData
}
export { setLicenseText, loadLicenseFromUrl, loadLicenseFromUrls }
