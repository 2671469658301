import { AreaSegmentChooser } from './AreaSegmentChooser.js'
class AreaSegmentChooserSubtract extends AreaSegmentChooser {
  constructor() {
    super()
  }
  isSegmentOkAbstract(e) {
    if (e.isOtherFilled()) {
      if (e.isSegmentOnAreaBoundaryOfOther()) {
        if (
          e.segmentLabel.locationLeft[e.otherShapeIndex] ===
          e.segmentLabel.locationRight[e.shapeIndex]
        )
          return true
      } else if (1 === e.shapeIndex && e.getSegmentLocationOther()) return true
    } else return 0 === e.shapeIndex
    return false
  }
}
export { AreaSegmentChooserSubtract }
