import { whenInternal } from '../../../util/PromiseUtil.js'
import { OcclusionMode } from '../../style/OcclusionMode.js'
import { Photon } from '../../../gen/photon/photon_painter.js'
export class PhotonDrawCommandProxy {
  constructor(t, e, r, s) {
    this._shapePainter = t
    this._feature = e
    this._subShape = r
    this._drawItems = []
    whenInternal(s, (t) => {
      this._drawItems = t
    })
    this.__paintIndex = 0
    this._maximumWorldMargin = 0
    this.bounds = null
    this.worldBounds = null
    this.zOrder = 0
  }
  getMaximumWorldMargin(t) {
    return this._maximumWorldMargin
  }
  setMaximumWorldMargin(t) {
    this._maximumWorldMargin = t
  }
  findDrawCommand(t) {
    if (!this._drawItems || 0 === this._drawItems.length) return null
    const e = this._shapePainter.getSubshapeDrawItems(this._drawItems, t)
    if (e.length > 0) {
      if (e.length === this._drawItems.length) return this
      return new PhotonDrawCommandProxy(this._shapePainter, this._feature, t, e)
    }
    return null
  }
  findInteractingShapes(t, e, r, s, i, n) {
    return this._shapePainter.findInteractingShapes(this._drawItems, t, e, r, s)
  }
  interacts(t) {
    return this._shapePainter.interactsDrawItems(
      this._feature,
      this._drawItems,
      t,
      false
    )
  }
  interactsView(t, e, r, s, i) {
    return this._shapePainter.interactsDrawItemsView(
      this._feature,
      this._drawItems,
      t,
      e,
      r,
      false
    )
  }
  strokeInteracts(t) {
    return this._shapePainter.interactsDrawItems(
      this._feature,
      this._drawItems,
      t,
      true
    )
  }
  strokeInteractsView(t, e, r, s, i) {
    return this._shapePainter.interactsDrawItemsView(
      this._feature,
      this._drawItems,
      t,
      e,
      r,
      true
    )
  }
  mapAnchorPointSFCT(t, e) {
    const r = this._shapePainter.getShapeAnchor(
      this._feature,
      this._drawItems,
      Photon.AnchorMode.Point
    )
    if (!r) return false
    else {
      e.x = r.x
      e.y = r.y
      e.z = r.z
      return true
    }
  }
  mapAnchorPointsSFCT(t, e, r) {
    const s = e.preferredCentroid
      ? Photon.AnchorMode.InPath
      : Photon.AnchorMode.OnPath
    if (s === Photon.AnchorMode.OnPath)
      this._shapePainter.getShapeOnPathAnchorsSFCT(
        this._feature,
        this._drawItems,
        e,
        r
      )
    else {
      const t = this._shapePainter.getShapeAnchor(
        this._feature,
        this._drawItems,
        s,
        r
      )
      if (t) e.add3D(t.x, t.y, t.z, t.x + t.dx, t.y + t.dy, t.z + t.dz)
    }
  }
  getRepeatingAnchorListSFCT(t, e, r) {
    this._shapePainter.getRepeatingAnchorListSFCT(
      this._feature,
      this._drawItems,
      t,
      e,
      r
    )
  }
  bindDomainShape(t) {}
  isValidLocation(t, e) {
    return this._shapePainter.isPointVisible(t, e)
  }
  viewPointSFCT(t, e) {
    const r = this._shapePainter.labelAnchorToView(this._feature, t)
    if (!r) return false
    else {
      e.x = r.x
      e.y = r.y
      e.z = r.z
      return true
    }
  }
  isEquivalent(t) {
    const e = t
    return e._feature && this._feature.id === e._feature.id
  }
  hasSameGeometry(t) {
    const e = t
    if (this._drawItems.length !== e._drawItems.length) return false
    for (let t = 0; t < this._drawItems.length; t++)
      if (this._drawItems[t].geometryId !== e._drawItems[t].geometryId)
        return false
    return true
  }
  bindWorldBounds(t) {}
  draw(t, e, r) {}
  getCommands() {
    return []
  }
  getMapDistanceSquared(t, e, r, s) {}
  getMaximumPadding() {
    return 0
  }
  isReady() {
    return false
  }
  getStyledShapeBounds() {
    return null
  }
  getOcclusionHintForLabel() {
    for (let t = 0; t < this._drawItems.length; t++) {
      const e = this._drawItems[t].style?.occlusionMode
      if (
        e === OcclusionMode.ALWAYS_VISIBLE ||
        e === OcclusionMode.OCCLUDED_ONLY
      )
        return OcclusionMode.ALWAYS_VISIBLE
    }
    return OcclusionMode.VISIBLE_ONLY
  }
}
