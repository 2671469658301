import { ProgrammingError } from '../error/ProgrammingError.js'
import { SingleStateCollection } from './SingleStateCollection.js'
export class SelectionSupport {
  constructor(e, t, l) {
    this._layer = e
    this._collection = new SingleStateCollection(e, false, l, (e, l) =>
      t(e.id, l)
    )
  }
  get selectable() {
    return this._collection.enabled
  }
  set selectable(e) {
    const t = this._collection.enabled
    if (t === e) return
    if (t) {
      const e = this._collection.disable()
      this.propagateSelectionEvent(this._layer.map, this.event(e))
    } else this._collection.enable()
    this._layer.emit('SelectableChanged', e)
  }
  getSelectedFeaturesNoClone() {
    return this.selectable ? this.getSelectedFeatures() : []
  }
  isSelected(e) {
    return this._collection.isIncluded(e)
  }
  featureChanged(e) {
    this._collection.updateItem(e)
  }
  propagateSelectionEvent(e, t) {
    if (e && t) e.updateSelectionFromLayer(t)
  }
  getSelectedFeatures() {
    return this._collection.getIncludedItems()
  }
  unselect(e) {
    const t = this._collection.removeItem(e)
    return this.event(t)
  }
  clearSelection() {
    const e = this._collection.removeAll()
    this._layer._invalidate()
    return this.event(e)
  }
  selectFeatures(e) {
    if (!this.selectable) return null
    const t = this._collection.replaceIncluded(e)
    const l = this.event(t)
    if (l) this._layer._invalidate()
    if (t.validation.failedToAdd)
      throw new ProgrammingError(
        'selectFeatures: The following items are not available on the client and could not be selected: ' +
          t.validation.failedToAdd
      )
    else if (t.validation.badIds)
      throw new ProgrammingError(
        'selectFeatures: all selectable items must have an id property'
      )
    return l
  }
  updateFilter(e) {
    const t = this._collection.updateFilter(e)
    return this.event(t)
  }
  event(e) {
    if (null !== e && (e.added.length > 0 || e.removed.length > 0))
      return { layer: this._layer, selected: e.added, deselected: e.removed }
    return null
  }
}
