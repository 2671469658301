import { URL } from '../../util/URL.js'
import { request } from '../../util/request.js'
import { mimeType } from '../../util/mimeType.js'
import { NotImplementedError } from '../../error/NotImplementedError.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { addHttpRequestOptions } from '../../util/HttpRequestOptions.js'
import { shouldSwapAxes } from '../../reference/AxisInformationUtil.js'
import { isNumber, isString } from '../../util/Lang.js'
import { buildDimensionsQueryString } from '../../model/OGCUtil.js'
import { fetchResponseToWMSGetFeatureInfoResponse } from '../../view/tileset/WMSTileSetLayer.js'
const COLOR_REGEX = /^0x[0-9abcdef]{6}$/
export class WMSClient {
  constructor(e) {
    this._version = e.version || '1.3.0'
    this._getMapRoot =
      e.getMapRoot || 'http://you/need/to/specify/a/root/for/getmap/request/wms'
    this._sanitizedGetMapRoot = this._sanitizeURL(this._getMapRoot)
    this._getFeatureInfoRoot = e.getFeatureInfoRoot || this._getMapRoot
    this._sanitizedGetFeatureInfoRoot = this._sanitizeURL(
      this._getFeatureInfoRoot
    )
    this._transparent = !!e.transparent
    this._backgroundColor = null
    if (isString(e.backgroundColor)) this.backgroundColor = e.backgroundColor
    else this.backgroundColor = null
    this._imageFormat = e.imageFormat || mimeType.png
    this._infoFormat = e.infoFormat || mimeType.json
    this._requestParameters = e.requestParameters || {}
    this._swapAxes = e.swapAxes && e.swapAxes.length ? e.swapAxes : []
  }
  get getMapRoot() {
    return this._getMapRoot
  }
  set getMapRoot(e) {
    this._getMapRoot =
      e || 'http://you/need/to/specify/a/root/for/getmap/request/wms'
    this._sanitizedGetMapRoot = this._sanitizeURL(this._getMapRoot)
  }
  get version() {
    return this._version
  }
  get transparent() {
    return this._transparent
  }
  set transparent(e) {
    this._transparent = !!e
  }
  get backgroundColor() {
    return this._backgroundColor
  }
  set backgroundColor(e) {
    if (isString(e)) {
      const t = e.trim().toLowerCase()
      if (-1 !== t.search(COLOR_REGEX))
        this._backgroundColor = '0x' + t.substr(2).toUpperCase()
      else throw new ProgrammingError('Invalid backgroundColor: ' + e)
    } else if (null === e) this._backgroundColor = null
    else
      throw new ProgrammingError(
        'Invalid backgroundColor: ' + e + ', expected a string'
      )
  }
  get requestParameters() {
    return this._requestParameters
  }
  set requestParameters(e) {
    this._requestParameters = e
  }
  _sanitizeURL(e) {
    let t = e
    if (t.indexOf('?') >= 0) {
      const e = t[t.length - 1]
      if ('&' !== e && '?' !== e) t += '&'
    } else t += '?'
    return t
  }
  _appendCommonWMSParameters(e, t) {
    let r
    let o
    if (!((r = t.bounds) && (o = t.size)))
      throw new ProgrammingError(
        'WMSClient::cannot make a WMS request Url without bbox and size parameters'
      )
    const s = [r.x, r.y, r.x + r.width, r.y + r.height]
    if (!t.layers)
      throw new ProgrammingError(
        'WMSClient::cannot make a getMap Url without a layer-string'
      )
    if (!t.srsCode)
      throw new ProgrammingError(
        'WMSClient::cannot make a getMap request when no srsCode is specified'
      )
    let i = e
    i += 'SERVICE=WMS'
    i += '&VERSION=' + this._version
    i += '&LAYERS=' + t.layers
    i += '&STYLES=' + (t.styles || '')
    i += '&FORMAT=' + (t.imageFormat || this._imageFormat)
    i += '&TRANSPARENT=' + this._transparent
    i += this.backgroundColor ? '&BGCOLOR=' + this.backgroundColor : ''
    if ('1.3.0' === this._version)
      i += '&CRS=' + t.srsCode || 'YouDidNotSpecifyCRSCode property'
    else if (this._version.match(/^1\.1/) || this._version.match(/^1\.0/))
      i += '&SRS=' + t.srsCode || 'YouDidNotSpecifySRSCode property'
    else
      throw new NotImplementedError(
        'WMSClient::cannot make requests to this server version. ' +
          this._version
      )
    const n = getReference(t.srsCode)
    const a = undefined
    if (shouldSwapAxes(n, this._swapAxes))
      i += '&BBOX=' + s[1] + ',' + s[0] + ',' + s[3] + ',' + s[2]
    else i += '&BBOX=' + s.join(',')
    i += '&WIDTH=' + o[0]
    i += '&HEIGHT=' + o[1]
    if (t.pixelSize) i += '&PIXELSIZE=' + t.pixelSize
    if (t.sld) i += '&SLD=' + encodeURIComponent(t.sld)
    if (t.sldBody) i += '&SLD_BODY=' + encodeURIComponent(t.sldBody)
    if (t.dimensions) i += buildDimensionsQueryString(t.dimensions)
    if (t.requestParameters) {
      const e = '&' + URL.buildQueryString(t.requestParameters)
      if ('&' !== e) i += e
    }
    return i
  }
  getMapUrl(e) {
    return (
      this._appendCommonWMSParameters(this._sanitizedGetMapRoot, e || {}) +
      '&REQUEST=GetMap'
    )
  }
  getFeatureInfoUrl(e) {
    if ('undefined' === typeof (e = e || {}).i || 'undefined' === typeof e.j)
      throw new ProgrammingError(
        'WMSClient::cannot make a getFeatureInfo Url without I and J parameters'
      )
    const t = Math.round(e.i)
    const r = Math.round(e.j)
    if (!e.queryLayers)
      throw new ProgrammingError(
        'WMSClient::cannot make a getFeatureInfo Url without a queryLayer-string'
      )
    if (
      (isNumber(e.featureCount) || isString(e.featureCount)) &&
      Number(e.featureCount) < 1
    )
      throw new ProgrammingError(
        'WMSClient::cannot make a getFeatureInfo Url for less than one feature. Requested ' +
          e.featureCount +
          ' features'
      )
    let o = this._appendCommonWMSParameters(
      this._sanitizedGetFeatureInfoRoot,
      e
    )
    o += '&REQUEST=GetFeatureInfo'
    if ('1.3.0' === this._version) o += '&I=' + t + '&J=' + r
    else o += '&X=' + t + '&Y=' + r
    o += '&QUERY_LAYERS=' + e.queryLayers
    o += '&INFO_FORMAT=' + (e.infoFormat || this._infoFormat)
    if (!!e.featureCount) o += '&FEATURE_COUNT=' + e.featureCount
    return o
  }
  getFeatureInfo(e, t) {
    const r = addHttpRequestOptions({}, t)
    return request(this.getFeatureInfoUrl(e), r).then(
      fetchResponseToWMSGetFeatureInfoResponse
    )
  }
}
