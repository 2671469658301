import { XYZBounds } from '../../shape/XYZBounds.js'
import { CommandUtil } from './CommandUtil.js'
import { CoordinateArrayUtil } from './CoordinateArrayUtil.js'
import { HTML5DrawCommandProto } from './HTML5DrawCommandProto.js'
const viewBounds = new XYZBounds(null)
export class HTML5DrawStrokeCommandMulti extends HTML5DrawCommandProto {
  constructor(t, o, r, s, n, i) {
    super(t, o, n)
    this._coordinates = r
    this._close = i
    this._strokeStyle = s
    this.bounds = new XYZBounds()
    CoordinateArrayUtil.calculateBoundsMultiSFCT(this._coordinates, this.bounds)
  }
  draw(t, o, r) {
    this._strokeStyle.paintMultiPathMapCoords(
      t,
      this._coordinates,
      o,
      this._close,
      r
    )
  }
  interacts(t, o, r) {
    return this.strokeInteracts(t, o, r)
  }
  strokeInteracts(t, o, r) {
    const s = this._strokeStyle.getStrokeWidth()
    const n = this._coordinates
    for (let r = 0, i = n.length; r < i; r++) {
      const i = n[r]
      if (CommandUtil.lineInteractsFlat(i, t, s, o)) return true
    }
    return false
  }
  mapAnchorPointSFCT(t, o) {
    return CoordinateArrayUtil.mapAnchorPointSFCT(this._coordinates, false, o)
  }
  mapAnchorPointsSFCT(t, o, r) {
    t.getViewBounds(viewBounds)
    CoordinateArrayUtil.mapAnchorPointsSFCT(
      this._coordinates,
      this.bounds,
      viewBounds,
      t.mapToViewTransformationInternal,
      r,
      o
    )
  }
  getMaximumPadding(t) {
    return Math.ceil(this._strokeStyle.getStrokeWidth() / 2)
  }
}
