import { AbortError } from '../error/AbortError.js'
import { NotFoundError } from '../error/NotFoundError.js'
import { createHTML5Canvas } from '../view/HTML5Canvas.js'
import { base64 } from './base64.js'
import { isIE } from './Browser.js'
import { Hash } from './Hash.js'
import { addHttpRequestOptions } from './HttpRequestOptions.js'
import { isBoolean, isObject, isString } from './Lang.js'
import { On } from './On.js'
import { isPromise } from './PromiseUtil.js'
import { request } from './request.js'
import { URL } from './URL.js'
import { resizeImageOrCanvas } from './ImageUtil.js'
let _imageCache = {}
const patternCache = new Map()
const html5Canvas = createHTML5Canvas(0, 0)
const hash = new Hash()
const endsWith = (e, t) => {
  const r = e.length - t.length
  return r >= 0 && e.lastIndexOf(t) === r
}
const onloadImage = (() => {
  if (isIE())
    return (e, t, r) => {
      const a = On(e, 'error', (t) => {
        e.onload = null
        a.remove()
        n.remove()
        r && r(t)
      })
      const n = On(e, 'abort', (t) => {
        e.onload = null
        a.remove()
        n.remove()
        r && r(t)
      })
      e.onload = () => {
        e.onload = null
        a.remove()
        n.remove()
        if (!isImageComplete(e)) {
          r && r(new Error('Image load event fired before image is complete'))
          return
        }
        t && t(e)
      }
    }
  return (e, t, r) => {
    const a = On(e, 'error', (e) => {
      o.remove()
      a.remove()
      n.remove()
      r && r(e)
    })
    const n = On(e, 'abort', (e) => {
      o.remove()
      a.remove()
      n.remove()
      r && r(e)
    })
    const o = On(e, 'load', () => {
      if (!isImageComplete(e)) {
        o.remove()
        a.remove()
        n.remove()
        r && r(new Error('Image load event fired before image is complete'))
        return
      }
      t && t(e)
    })
  }
})()
const getImage = function (e) {
  let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : false
  let r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : false
  let a = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : false
  let n = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : null
  let o = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : null
  let s = arguments.length > 6 && void 0 !== arguments[6] ? arguments[6] : 0
  let i = arguments.length > 7 && void 0 !== arguments[7] ? arguments[7] : 0
  if (!isString(e)) return getLoadedImage(e, s, i)
  const m = t
    ? getImageMemoized(e, r, a, n, o, s, i)
    : getImageFromUrl(e, t, r, a, n, o)
  let g = m
  if (!t && (s || i))
    if (isPromise(m)) g = m.then((e) => resizeImage(e, s, i))
    else g = resizeImage(m, s, i)
  return g
}
function getImageMemoized(e, t, r, a, n, o, s) {
  hash.reset()
  hash.appendString(e)
  const i = hash.getHashCode()
  if (o && s) {
    hash.appendDouble(o)
    hash.appendDouble(s)
  }
  const m = hash.getHashCode()
  if (_imageCache[m]) return _imageCache[m]
  const g = (e, t, r) => {
    if (isSVGImage(r) && t)
      return (_imageCache[e] = resizeImageOrCanvas(r, r.width, r.height).then(
        (t) => {
          _imageCache[e] = t
          return t
        }
      ))
    else {
      _imageCache[e] = r
      return r
    }
  }
  const h = (e, t) => {
    delete _imageCache[e]
    throw t
  }
  const c = (t) => {
    hash.reset()
    hash.appendString(e)
    hash.appendDouble(t.width)
    hash.appendDouble(t.height)
    const r = hash.getHashCode()
    g(i, false, t)
    return g(r, true, t)
  }
  if (o && s) {
    const l = g.bind(null, m, true)
    const u = h.bind(null, m)
    const f = (e) => {
      const t = resizeImage(e, o, s)
      if (isPromise(t)) _imageCache[m] = t.then(l, u)
      else _imageCache[m] = t
      return _imageCache[m]
    }
    const d = _imageCache[i]
    if (d)
      if (isPromise(d)) d.then(f)
      else f(d)
    else
      _imageCache[m] = getImageFromUrl(e, true, t, r, a, n).then((e) => {
        c(e)
        const t = resizeImage(e, o, s)
        if (isPromise(t)) _imageCache[m] = t.then(l, u)
        else _imageCache[m] = t
        return _imageCache[m]
      }, u)
    return _imageCache[m]
  } else if (_imageCache[i]) {
    const o = _imageCache[i]
    if (!isPromise(o) && isSVGImage(o)) {
      const s = o
      return getImageMemoized(e, t, r, a, n, s.width, s.height)
    }
    return o
  } else {
    _imageCache[i] = getImageFromUrl(e, true, t, r, a, n).then(
      (e) => c(e),
      (e) => h(i, e)
    )
    return _imageCache[i]
  }
}
function getImageWithXHR(e, t, r, a, n) {
  const o = addHttpRequestOptions(
    { signal: n },
    { requestHeaders: a, credentials: r }
  )
  return request(e, o).then((e) => {
    const a = e.headers.get('Content-Type')
    return e.arrayBuffer().then((e) => {
      const o = undefined
      return getImageFromUrl(arrayBufferToDataURI(e, a), t, false, r, null, n)
    })
  })
}
function getImageFromUrl(e, t, r, a, n, o) {
  if (n && isObject(n) && 0 !== Object.keys(n).length)
    return getImageWithXHR(e, t, a, n, o)
  return getImageWithDOM(e, r, a, o)
}
function getImageWithDOM(e, t, r, a) {
  let n
  return new Promise((o, s) => {
    n = new Image()
    if (t && 0 !== e.indexOf('data:'))
      n.crossOrigin = r ? 'use-credentials' : 'anonymous'
    n.onerror = () => {
      s(new NotFoundError(e))
    }
    n.onload = () => {
      o(n)
    }
    if (a) {
      const e = () => {
        n.onerror = null
        n.onload = null
        n.src = ''
        n = null
        s(new AbortError('DOM image request aborted'))
      }
      if (a.aborted) {
        e()
        return
      }
      a.addEventListener('abort', e)
    }
    n.src = e
  })
}
const isImageComplete = (e) => {
  if (e && isBoolean(e.complete) && false === e.complete) return false
  return (
    !(e && (e.width <= 0 || e.height <= 0)) || (e && endsWith(e.src, '.svg'))
  )
}
const getLoadedImage = (e, t, r) => {
  if (isImageComplete(e))
    if ((!t && !r) || (t && t === e.width) || (r && r === e.height)) return e
  const a = resizeImage(e, t, r)
  const n = (e) =>
    new Promise((t, r) => {
      if (isImageComplete(e)) t(e)
      onloadImage(
        e,
        (e) => {
          t(e)
        },
        (e) => {
          r(e)
        }
      )
    })
  if (isPromise(a)) return a.then(n)
  else return n(a)
}
function resizeImage(e, t, r) {
  let a = false
  if ((t && t !== e.width) || (r && r !== e.height)) a = true
  else if (isSVGImage(e)) a = true
  if (a) return resizeImageOrCanvas(e, t, r)
  return e
}
function isSVGImage(e) {
  let t = null
  if (isString(e)) t = e
  else if (e instanceof HTMLImageElement) t = e.src
  else if (e instanceof HTMLCanvasElement) return false
  if (t) return endsWith(t, '.svg') || 0 === t.indexOf('data:image/svg+xml')
  return false
}
function createImagePattern(e) {
  return html5Canvas
    .getContext('2d', { willReadFrequently: true })
    .createPattern(e, 'repeat')
}
const prunePatternCache = (e) => patternCache.delete(e)
const getImageMeta = (e, t, r) => {
  let a = 'unknown'
  if (isString(e)) a = e
  else if (e instanceof HTMLImageElement && e.src) a = e.src
  else if (e instanceof HTMLCanvasElement) a = e.toDataURL()
  hash.reset()
  hash.appendString(a)
  if (t || r) {
    hash.appendDouble(t)
    hash.appendDouble(r)
  }
  return { imageSource: a, hash: hash.getHashCode() }
}
function getPattern(e, t, r) {
  const { hash: a } = getImageMeta(e, t, r)
  if (patternCache.has(a)) return patternCache.get(a)
  const n = createImagePattern(e)
  if (!n) return null
  patternCache.set(a, n)
  setTimeout(() => {
    prunePatternCache(a)
  }, 1e3)
  return n
}
const arrayBufferToDataURI = (e, t) => {
  let r = ''
  const a = new Uint8Array(e)
  const n = a.byteLength
  for (let e = 0; e < n; e++) r += String.fromCharCode(a[e])
  return URL.fromData(base64.encode(r), { mimeType: t })
}
const clearCache = () => {
  _imageCache = {}
  patternCache.clear()
}
const inspectCaches = () => ({
  imageCacheSize: Object.keys(_imageCache).length,
  patternCacheSize: patternCache.size,
})
const getCachedImage = (e) => _imageCache[e]
export const Ajax = {
  getImage: getImage,
  getPattern: getPattern,
  prunePatternCache: prunePatternCache,
  loadImage: function (e, t) {
    return new Promise((r, a) => {
      if (null === t || void 0 === t) a('Url is invalid')
      onloadImage(e, r, a)
      e.src = t
    })
  },
  isSVGImage: isSVGImage,
  clearCache: clearCache,
  getCachedImage: getCachedImage,
  inspectCaches: inspectCaches,
}
