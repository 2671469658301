import { ProgrammingError } from '../error/ProgrammingError.js'
import { isFunction } from './Lang.js'
class _On {
  constructor(e, o, n, r) {
    if ('object' === typeof e) {
      if (isFunction(e.addEventListener)) e.addEventListener(o, n, r ?? false)
      else if (isFunction(e.on)) e.on(o, n)
      else throw new ProgrammingError("Object doesn't support adding events")
      this._obj = e
      this._event = o
      this._handler = n
    } else throw new ProgrammingError('You can only add events to objects')
  }
  remove() {
    const e = this._obj
    if ('object' === typeof e) {
      const o = this._event
      const n = this._handler
      if (isFunction(e.removeEventListener)) e.removeEventListener(o, n)
      else if (isFunction(e.off)) e.off(o, n)
      else throw new ProgrammingError("Object doesn't support removing events")
    } else throw new ProgrammingError('You can only add events to objects')
  }
}
export const On = function (e, o, n, r) {
  return new _On(e, o, n, r)
}
