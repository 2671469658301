import { releaseObject } from '../../util/ObjectReleaseTracker.js'
import { Log } from '../../util/Log.js'
import { FeatureModelQueryCache } from './FeatureModelQueryCache.js'
import { isValidFeatureId } from '../../model/feature/Feature.js'
import { isFunction } from '../../util/Lang.js'
import { IdentityTransformer } from './transformation/IdentityTransformer.js'
export class WorkingSetUpdater {
  static CRUD_TIMEOUT = 1e4
  static QUERY_EXECUTION_TIMEOUT = 3 * 60 * 1e3
  constructor(e, r, t) {
    this._layer = e
    this._workingSet = r
    this._pendingEvents = []
    this._modelListener = null
    this._processCursorOnQuerySuccessListener = null
    this._scheduler = t.scheduler
    this._incremental = !!t.incremental
    this._modelQueryCache = new FeatureModelQueryCache(e)
    this._queryPromise = null
    this._queryAbortController = null
    this._queryStartTime = null
    this._dirty = false
    this._cursorProcessor = null
    const s = e.model
    addWrapper(this, s, 'add', 'add')
    addWrapper(this, s, 'put', 'update')
    addWrapper(this, s, 'remove', 'remove')
    this._registerPending()
  }
  get dirty() {
    return this._dirty
  }
  get layer() {
    return this._layer
  }
  get workingSet() {
    return this._workingSet
  }
  _registerPending() {
    this._workingSet._registerPending()
  }
  _registerStart() {
    this.resetQueryState()
    this._queryStartTime = performance.now()
    this._workingSet._registerStart()
  }
  _registerSuccess() {
    this._queryStartTime = null
    this._workingSet._registerSuccess()
  }
  cancelPending() {
    this._registerInterruption()
  }
  _registerError(e) {
    this.resetQueryState()
    this._workingSet._registerError(e)
  }
  resetQueryState() {
    this._dirty = false
    this._queryStartTime = null
    this._queryPromise = null
    this._queryAbortController = null
  }
  _registerInterruption() {
    if (!this._queryPromise && !this._cursorProcessor) return
    if (this._queryPromise && this._queryAbortController)
      this._queryAbortController.abort()
    this.resetQueryState()
    this._cursorProcessor = null
    this._workingSet._registerInterruption()
  }
  _registerFinish() {
    this._modelQueryCache?.processDelayedModelChangesOnQueryFinish()
    this._workingSet._registerFinish()
  }
  _clearData() {
    this._workingSet._clearData()
  }
  clearAll() {
    this.removeModelListener()
    this._workingSet.clearAll()
  }
  release() {
    this.clearAll()
    this._modelQueryCache = releaseObject(this._modelQueryCache)
    this._processCursorOnQuerySuccessListener?.remove()
    this._processCursorOnQuerySuccessListener = null
  }
  refreshWorkingSet(e) {
    const r = this.layer._transformer
    const t = this._modelQueryCache
    const { map: s } = this.layer
    if (!s) return false
    const i = e.shouldQueryStore(t.getNumberOfFeaturesInCache())
    if (i && this.checkParallelQuery(e)) return false
    if (!i && this._queryPromise) return false
    const o =
      !(r instanceof IdentityTransformer) &&
      (t.isModelChanged() || r.shouldTransform(s))
    if (!i && !o) {
      debugLog('CHECK: request rejected - no need to query or run transformer')
      return false
    }
    this.removeModelListener()
    if (e.isNothingToShow()) {
      this.clearAll()
      this._registerFinish()
      return true
    }
    this._cursorProcessor = null
    this._pendingEvents.length = 0
    this._registerStart()
    if (!this._processCursorOnQuerySuccessListener) {
      const e = (r) => {
        if (this.processCursor(r)) this._scheduler.schedule(e)
      }
      this._processCursorOnQuerySuccessListener = this._workingSet.on(
        'QuerySuccess',
        () => {
          this._scheduler.schedule(e)
        }
      )
    }
    try {
      this._queryAbortController = new AbortController()
      this._queryPromise = e.runQueryProcess(
        t,
        r,
        this._workingSet,
        this._incremental,
        i,
        this._queryAbortController.signal
      )
    } catch (e) {
      this._registerError(e instanceof Error ? e : new Error('' + e))
      return true
    }
    const n = this._queryPromise
    this._modelListener = r.on('ModelChanged', this.modelChanged.bind(this))
    const u = this
    this._queryPromise.then(
      (e) => {
        if (n !== u._queryPromise) {
          debugLog('CHECK: stale query rejected to process (query interrupted)')
          return
        }
        if (u._dirty) {
          e.shouldDelete = false
          debugLog('CHECK: dirty state - skip deletes')
        }
        u._cursorProcessor = e
        u._queryPromise = null
        u._queryAbortController = null
        u._registerSuccess()
      },
      (r) => {
        u.removeModelListener()
        if (n === u._queryPromise) {
          e.needQuery = true
          u._registerError(r)
        }
      }
    )
    return true
  }
  processCursor(e) {
    if (!this._cursorProcessor) return false
    try {
      this._cursorProcessor.processCursor(e)
    } catch (e) {
      this._registerError(e instanceof Error ? e : new Error('' + e))
      return false
    }
    if (!this._cursorProcessor.finished()) return true
    this._cursorProcessor = null
    this.processPendingEvents()
    this._registerFinish()
    if (this._dirty) {
      debugLog('CHECK: dirty state - refresh request')
      setTimeout(() => this.layer.loadingStrategy.invalidate(), 0)
    }
    return false
  }
  get() {
    return this._workingSet.get()
  }
  getNode(e) {
    return this._workingSet.getNode(e)
  }
  search(e, r) {
    this._workingSet.search(e, r)
  }
  forEachVisibleNode(e) {
    this._workingSet.forEachVisibleNode(e)
  }
  removeModelListener() {
    if (this._modelListener) {
      this._modelListener.remove()
      this._modelListener = null
    }
  }
  _addObject(e, r) {
    this._workingSet._addObject(e, r)
  }
  _updateObject(e, r) {
    this._workingSet._updateObject(e, r)
  }
  _removeObject(e, r) {
    this._workingSet._removeObject(e, r)
  }
  processPendingEvents() {
    for (let e = 0; e < this._pendingEvents.length; e += 1)
      this.processModelChangedEvent.apply(this, this._pendingEvents[e])
    this._pendingEvents.length = 0
  }
  processModelChangedEvent(e, r, t) {
    if ('add' === e) this._addObject(r, t)
    else if ('update' === e) this._updateObject(r, t)
    else if ('remove' === e) this._removeObject(r, t)
  }
  modelChanged(e, r, t) {
    if (this._queryPromise || this._cursorProcessor)
      this._pendingEvents.push([e, r, t])
    else this.processModelChangedEvent(e, r, t)
  }
  checkParallelQuery(e) {
    if (this._queryPromise || this._cursorProcessor)
      if (e.needQuery) {
        this._registerInterruption()
        debugLog('CHECK: interrupt (invalidate)')
        return false
      } else {
        if (this._queryStartTime)
          if (
            performance.now() - this._queryStartTime >
            WorkingSetUpdater.QUERY_EXECUTION_TIMEOUT
          ) {
            this._registerInterruption()
            debugLog('CHECK: interrupt (timeout)')
            return false
          }
        this._dirty = true
        debugLog('CHECK: query rejected, marked as dirty')
        return true
      }
    return false
  }
}
function addWrapper(e, r, t, s) {
  if (!r || !isFunction(r[t])) return
  e[t] = function () {
    for (var i = arguments.length, o = new Array(i), n = 0; n < i; n++)
      o[n] = arguments[n]
    return new Promise((i, n) => {
      const u = []
      let l = null
      function h() {
        if (a) a.remove()
        if (null !== c) clearTimeout(c)
      }
      const a = e.workingSet.on('WorkingSetChanged', (e, r, t) => {
        if (e === s || ('update' === s && 'add' === e))
          if (null !== l && l === t) {
            h()
            i(t)
          } else u.push(t)
      })
      const c = setTimeout(() => {
        h()
        n(new Error(`Timed out waiting for confirmation of model ${t}`))
      }, WorkingSetUpdater.CRUD_TIMEOUT)
      const d = e.layer._transformer
      Promise.resolve(d[t](r, o[0], o[1]))
        .then((e) => {
          const r = 'remove' !== s ? e : o[0]
          if (!isValidFeatureId(r)) {
            const e = `WorkingSetUpdater: missing feature ID (${r}). Please check luciad.model.store.Store API`
            Log.warn(e)
            throw new Error(e)
          }
          if (-1 !== u.indexOf(r)) {
            h()
            i(r)
          } else l = r
        })
        .catch((e) => {
          h()
          n(e)
        })
    })
  }
}
const logIt = false
const debugLog = function () {
  for (var e = arguments.length, r = new Array(e), t = 0; t < e; t++)
    r[t] = arguments[t]
  return logIt && console.log(r)
}
