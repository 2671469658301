import { ProgrammingError } from '../../error/ProgrammingError.js'
import { ShapeType } from '../../shape/ShapeType.js'
import {
  resolveHref,
  xmlFindFirstChild,
  xmlParentNode,
  xmlWalkChildren,
} from '../../util/kml/KMLInternalUtil.js'
import {
  getFeatureProperties,
  getGroundOverlayProperties,
  getNetworkLinkProperties,
  getScreenOverlayProperties,
} from '../../util/kml/KMLParse.js'
import {
  isContainerNode,
  isFolderNode,
  isGeometryNode,
  isGroundOverlayNode,
  isNetworkLinkNode,
  isPhotoOverlayNode,
  isPlacemarkNode,
  isScreenOverlayNode,
  isStyleSelectorNode,
} from '../../util/kml/KMLTypes.js'
import {
  createGroundOverlayShape,
  getCoordinateReference,
  shiftWebMercatorDateLineTransformation,
  WGS84_MSL,
} from '../../util/kml/KMLUtil.js'
import { getKmlId, getKmlTag } from '../../util/kml/KMLValues.js'
import { mimeType } from '../../util/mimeType.js'
import { ZOrderCalculator } from '../../util/ZOrderCalculator.js'
import { isKMLContainer } from './KMLContainer.js'
import { KMLDocumentContainer } from './KMLDocumentContainer.js'
import {
  KMLFatalErrorEvent,
  KMLGroundOverlayEvent,
  KMLIsHoverableEvent,
  KMLModelGeometryEvent,
  KMLNetworkLinkEvent,
  KMLScreenOverlayEvent,
  KMLTreeEvent,
} from './KMLEvented.js'
import { KMLFolderContainer } from './KMLFolderContainer.js'
import { KMLGeometryBuilder } from './KMLGeometryBuilder.js'
import { KMLGroundOverlayFeature } from './KMLGroundOverlayFeature.js'
import { KMLNetworkLinkFeature } from './KMLNetworkLinkFeature.js'
import { KMLPhotoOverlayFeature } from './KMLPhotoOverlayFeature.js'
import { KMLPlacemarkFeature } from './KMLPlacemarkFeature.js'
import { KMLScreenOverlayFeature } from './KMLScreenOverlayFeature.js'
import { KMLStyleCache, toKmlStyles } from './KMLStyleCache.js'
import { createPoint, createPolygon } from '../../shape/ShapeFactory.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { rotatePointCW } from '../../util/Cartesian.js'
import { Vector3 } from '../../geometry/mesh/math/Vector3.js'
class KMLFeatureCursor {
  _isHoverable = false
  constructor(e, t, r, o) {
    this._origin = o
    this._featureNodes = e
    this._styleCache = t
    this._zOrderCalculator = new ZOrderCalculator(WGS84_MSL.coordinateType)
    this._index = 0
    this._emitter = r
    this._treeRootArray = []
    this._nodeMap = new Map()
    this._idCalculator = new KMLFeatureIdCalculator()
    this.skipToNextPlacemarkOrGroundOverlay()
  }
  hasNext() {
    return this._index !== this._featureNodes.length
  }
  next() {
    try {
      if (!this.hasNext())
        throw new ProgrammingError(
          'Cursor: next should only be called when hasNext returns true'
        )
      const e = this.takeCurrentNode()
      let t
      if (isPlacemarkNode(e)) {
        const r = this.decodePlacemarkNode(e)
        this.addToTree(e, r)
        t = r
      }
      if (isGroundOverlayNode(e)) {
        const r = this.decodeGroundOverlayNode(e)
        this.addToTree(e, r)
        this._emitter.emit(KMLGroundOverlayEvent, r)
        t = r
      }
      this.skipToNextPlacemarkOrGroundOverlay()
      return t
    } catch (e) {
      const t = e instanceof Error ? e.message : 'unknown error'
      this._emitter.emit('KMLFatalError', 'Unexpected error: ' + t)
      throw e
    }
  }
  peekCurrentNode() {
    return this._featureNodes[this._index]
  }
  takeCurrentNode() {
    return this._featureNodes[this._index++]
  }
  skipToNextPlacemarkOrGroundOverlay() {
    while (
      this.hasNext() &&
      !isPlacemarkNode(this.peekCurrentNode()) &&
      !isGroundOverlayNode(this.peekCurrentNode())
    ) {
      const e = this.takeCurrentNode()
      const t = this.decodeNode(e)
      if (t) this.addToTree(e, t)
    }
    if (!this.hasNext()) {
      this._emitter.emit(KMLTreeEvent, this._treeRootArray)
      this._emitter.emit(KMLIsHoverableEvent, this._isHoverable)
    }
  }
  decodeNode(e) {
    if (isContainerNode(e)) {
      const t = this.decodeContainerNode(e)
      this._nodeMap.set(e, t)
      return t
    }
    if (isNetworkLinkNode(e)) {
      const t = this.decodeNetworkLinkNode(e)
      this._emitter.emit(KMLNetworkLinkEvent, t)
      return t
    }
    if (isScreenOverlayNode(e)) {
      const t = this.decodeScreenOverlayNode(e)
      this._emitter.emit(KMLScreenOverlayEvent, t)
      return t
    }
    if (isPhotoOverlayNode(e)) return this.decodePhotoOverlayNode(e)
    return null
  }
  addToTree(e, t) {
    const r = xmlParentNode(e)
    if (isContainerNode(e) && isKMLContainer(t)) this._nodeMap.set(e, t)
    if (!r || !isContainerNode(r)) {
      this._treeRootArray.push(t)
      return
    }
    this._nodeMap.get(r)?.addChild(t)
  }
  decodePlacemarkNode(e) {
    const t = ShapeType.POLYGON | ShapeType.COMPLEX_POLYGON
    const r = new KMLGeometryBuilder(e, true, true)
    const o = r.build()
    const n = o?.[0] || null
    const s = getFeatureProperties(e)
    s.errorMessages = r.errors
    const i = n && n.type & t ? this._zOrderCalculator.getZOrder(n) : 0
    const a = this._styleCache ? this._styleCache.getStyle(e) : toKmlStyles()
    const l = { zOrder: i, styles: a }
    this.installStyleHrefs(a.normal)
    this.installStyleHrefs(a.highlight)
    this.refreshIsHoverable(a)
    const d = new KMLPlacemarkFeature(n, s, this._idCalculator.getId(e), l)
    r.models?.forEach((e) => {
      d.meshes.push(e)
      this._emitter.emit(KMLModelGeometryEvent, e)
    })
    return d
  }
  refreshIsHoverable(e) {
    if (!this._isHoverable) this._isHoverable = e.normal !== e.highlight
  }
  installStyleHrefs(e) {
    const {
      iconStyle: { icon: t },
      listStyle: { itemIcon: r },
    } = e
    if (t) t.href = this.resolveHref(t.href)
    r.href = this.resolveHref(r.href)
  }
  decodeContainerNode(e) {
    return isFolderNode(e)
      ? this.decodeFolderNode(e)
      : this.decodeDocumentNode(e)
  }
  decodeFolderNode(e) {
    const t = getFeatureProperties(e)
    const r = this._styleCache ? this._styleCache.getStyle(e) : toKmlStyles()
    const o = undefined
    const n = undefined
    const s = { zOrder: 0, styles: r, children: [] }
    this.refreshIsHoverable(r)
    return new KMLFolderContainer(t, this._idCalculator.getId(e), s)
  }
  decodeDocumentNode(e) {
    return new KMLDocumentContainer(
      getFeatureProperties(e),
      this._idCalculator.getId(e),
      { ...this.getOptions(e), children: [] }
    )
  }
  decodeNetworkLinkNode(e) {
    const t = new KMLNetworkLinkFeature(
      getNetworkLinkProperties(e),
      this._idCalculator.getId(e),
      this.getOptions(e)
    )
    const { link: r } = t.properties
    r.href = this.resolveHref(r.href)
    return t
  }
  decodeGroundOverlayNode(e) {
    const t = new KMLGeometryBuilder(e, true, true)
    const r = t.build()
    let o
    let n = 0
    let s = []
    if (r?.[0]?.type === ShapeType.POLYGON) {
      const e = convertLatLonQuad(r[0])
      if (e) {
        o = e.bounds
        n = e.rotation
      } else
        s.push(
          'A GroundOverlay with an area defined as a LatLonQuad can only be visualized when it is rectangular.'
        )
    } else if (r?.[0]) o = r?.[0]
    const i = getGroundOverlayProperties(e, n, o)
    if (t.errors) s = s.concat(t.errors)
    i.errorMessages = 0 === s.length ? null : s
    let a
    if (o) {
      a = createGroundOverlayShape(o, i.rotation, i.altitude)
      const e = getCoordinateReference(i.altitudeMode)
      const t = createTransformation(a.reference, e)
      a = createPolygon(e, [
        t.transform(a.getPoint(0)),
        t.transform(a.getPoint(1)),
        t.transform(a.getPoint(2)),
        t.transform(a.getPoint(3)),
      ])
    } else a = null
    const l = new KMLGroundOverlayFeature(
      a,
      i,
      this._idCalculator.getId(e),
      this.getOptions(e)
    )
    const { icon: d } = l.properties
    d.href = this.resolveHref(d.href)
    return l
  }
  decodeScreenOverlayNode(e) {
    const t = new KMLScreenOverlayFeature(
      getScreenOverlayProperties(e),
      this._idCalculator.getId(e),
      this.getOptions(e)
    )
    const { icon: r } = t.properties
    r.href = this.resolveHref(r.href)
    return t
  }
  decodePhotoOverlayNode(e) {
    const t = getFeatureProperties(e)
    t.errorMessages = ['Photo Overlays are not yet supported by LuciadRIA.']
    return new KMLPhotoOverlayFeature(
      t,
      this._idCalculator.getId(e),
      this.getOptions(e)
    )
  }
  getOptions(e) {
    return {
      zOrder: 0,
      styles: this._styleCache ? this._styleCache.getStyle(e) : toKmlStyles(),
    }
  }
  resolveHref(e) {
    if (null === e) return null
    return resolveHref(this._origin, e, false)
  }
}
class KMLFeatureIdCalculator {
  constructor() {
    this._ids = {}
    this._tags = {}
  }
  getId(e) {
    const t = getKmlTag(e)
    const r = getNextCount(this._tags, t)
    const o = getKmlId(e)
    return this.deduplicateId(o || `${t}:${r}`)
  }
  deduplicateId(e) {
    const t = getNextCount(this._ids, e)
    if (!t) return e
    return this.deduplicateId(`${e}:${t}`)
  }
}
function getNextCount(e, t) {
  const r = e[t] || 0
  e[t] = r + 1
  return r
}
export class KMLCursorBuilder {
  constructor(e, t, r) {
    this._origin = r
    this._featureNodes = []
    this._styleCache = new KMLStyleCache()
    this._withStyles = t
    this._kmlContent = e
    this._kmlRoot = null
  }
  get styleCache() {
    return this._withStyles ? this._styleCache : null
  }
  build(e) {
    if (this._kmlContent) this._kmlRoot = parseKmlString(this._kmlContent, e)
    if (this._kmlRoot) {
      this._kmlContent = null
      this.walkDescendants(this._kmlRoot)
    }
    return new KMLFeatureCursor(
      this._featureNodes,
      this.styleCache,
      e,
      this._origin
    )
  }
  walkDescendants(e) {
    const t = (e) => {
      const r = getKmlId(e)
      if (null !== r && 0 !== r.length && isStyleSelectorNode(e)) {
        this._styleCache.pushStyle(e)
        return
      }
      if (isNetworkLinkNode(e)) {
        this._featureNodes.push(e)
        return
      }
      if (isGroundOverlayNode(e)) {
        this._featureNodes.push(e)
        return
      }
      if (isPhotoOverlayNode(e)) {
        this._featureNodes.push(e)
        return
      }
      if (isScreenOverlayNode(e)) {
        this._featureNodes.push(e)
        return
      }
      if (
        isContainerNode(e) ||
        (isPlacemarkNode(e) && xmlFindFirstChild(e, isGeometryNode))
      )
        this._featureNodes.push(e)
      xmlWalkChildren(e, t)
    }
    t(e)
  }
}
function parseKmlString(e, t) {
  let r
  try {
    r = new DOMParser().parseFromString(e, mimeType.xml)
  } catch (e) {
    r = null
  }
  if (null === r || isErrorDocument(r)) {
    t.emit(KMLFatalErrorEvent, 'Input file is not valid KML.')
    return null
  } else return r.documentElement
}
function isErrorDocument(e) {
  if (e.body) return isErrorNode(e.body)
  else return isErrorNode(e)
}
function isErrorNode(e) {
  return !!e.firstElementChild && 'parsererror' === e.firstElementChild.nodeName
}
function isRectangular(e) {
  for (let t = 1; t < e.length; t++) {
    const r = e[t]
    const o = e[t - 1]
    const n = e[(t + 1) % e.length]
    const s = new Vector3(o.x - r.x, o.y - r.y)
    const i = new Vector3(n.x - r.x, n.y - r.y)
    const a = (180 * s.angleTo(i)) / Math.PI
    if (!(Math.abs(a - 90) <= 0.5)) return false
  }
  return true
}
function convertLatLonQuad(e) {
  if (4 != e.pointCount) return null
  const t = shiftWebMercatorDateLineTransformation(e.bounds)
  let r = t.transform(e.getPoint(0))
  let o = t.transform(e.getPoint(1))
  let n = t.transform(e.getPoint(2))
  let s = t.transform(e.getPoint(3))
  if (!isRectangular([r, o, n, s])) return null
  const i = createPoint(r.reference, [(r.x + n.x) / 2, (r.y + n.y) / 2])
  const a = createPoint(r.reference, [(n.x + s.x) / 2, (n.y + s.y) / 2])
  const l = { x: a.x - i.x, y: a.y - i.y }
  const d = 0,
    u = 1
  const h = Math.atan2(u, d) - Math.atan2(l.y, l.x)
  rotatePointCW(r.x, r.y, h, i.x, i.y, r)
  rotatePointCW(o.x, o.y, h, i.x, i.y, o)
  rotatePointCW(n.x, n.y, h, i.x, i.y, n)
  rotatePointCW(s.x, s.y, h, i.x, i.y, s)
  r = t.inverseTransformation.transform(r)
  o = t.inverseTransformation.transform(o)
  n = t.inverseTransformation.transform(n)
  s = t.inverseTransformation.transform(s)
  const c = undefined
  const m = undefined
  return {
    bounds: createPolygon(r.reference, [r, o, n, s]).bounds,
    rotation: (-180 * h) / Math.PI,
  }
}
