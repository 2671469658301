import { XML } from '../../util/XML.js'
import { VersionNegotiator } from './common/VersionNegotiator.js'
function parseVersion(e) {
  var t = XML.stringToXml(e)
  var r = null
  try {
    r = t.getElementsByTagName('WMS_Capabilities')[0].getAttribute('version')
  } catch (e) {
    try {
      r = t
        .getElementsByTagName('WMT_MS_Capabilities')[0]
        .getAttribute('version')
    } catch (e) {}
  }
  return r
}
function createVersionNegotiator(e, t, r, i) {
  return new VersionNegotiator(
    e,
    'WMS',
    parseVersion,
    t || ['1.3.0', '1.1.1'],
    r || null,
    i || {}
  )
}
export var WMSCapabilitiesUtil = {
  parseVersion: parseVersion,
  createVersionNegotiator: createVersionNegotiator,
}
