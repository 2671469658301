import { LineSegmentChooser } from './LineSegmentChooser.js'
function LineSegmentChooserSymmetricSubtract() {}
LineSegmentChooserSymmetricSubtract.prototype = Object.create(
  LineSegmentChooser.prototype
)
LineSegmentChooserSymmetricSubtract.prototype.constructor =
  LineSegmentChooserSymmetricSubtract
LineSegmentChooserSymmetricSubtract.prototype.isSegmentOkAbstract = function (
  e
) {
  return !e.isFilled() ? !e.segmentLabel.locationOn[e.otherShapeIndex] : false
}
export { LineSegmentChooserSymmetricSubtract }
