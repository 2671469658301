import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { isFunction, isString } from '../../util/Lang.js'
import { On } from '../../util/On.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import { DrapeTarget } from '../style/DrapeTarget.js'
import { getZStyleForPoint } from '../feature/photon/command/ZStyleUtil.js'
const tempViewPoint = createPoint(null, [0, 0])
function cleanHandle(t) {
  if (t) t.remove()
}
function onImageTags(t, e) {
  const o = t.getElementsByTagName('img')
  for (let t = 0; t < o.length; t += 1) e(o.item(t))
}
function isFeature(t) {
  return t && t.shape && t.shape.focusPoint
}
function isContentProvider(t) {
  return t && isFunction(t.getContents)
}
function justifyContentProvider(t, e, o) {
  if (isContentProvider(t)) return t
  if (isFunction(t)) t = { getContents: t }
  else if (e && null !== e.balloonContentProvider)
    t = { getContents: e.balloonContentProvider }
  else if (o && o.toString) t = { getContents: () => o.toString() }
  else t = { getContents: () => DUMMY_CONTENT }
  return t
}
function createAnchor(t, e, o) {
  const n = createPoint(e.reference, [0, 0])
  const i = new EventedSupport([], false)
  let r
  function l(t) {
    return o && o.workingSet ? o.workingSet.getNode(t) : null
  }
  function s(t) {
    return t ? t.focusPoint : null
  }
  function a(t) {
    const e = l(t)
    return e ? s(e.shape) : null
  }
  let h = null
  if (o && t) h = l(t.id)
  let c
  let d
  let f
  if (h) {
    r = s(h.shape)
    if (o && isFeature(t))
      d = o.workingSet.on('WorkingSetChanged', (e, o, n) => {
        switch (e) {
          case 'update':
          case 'add':
            if (n === t.id) {
              r = a(t.id)
              i.emit('changed')
            }
            break
          case 'remove':
            if (n === t.id) {
              r = null
              i.emit('removed')
            }
            break
          case 'clear':
            r = null
            i.emit('removed')
            break
          default:
        }
      })
  } else if (isFeature(t)) r = t.shape.focusPoint
  else if (t && t.focusPoint && t.focusPoint.reference) r = t.focusPoint
  else {
    const t = e.viewSize
    tempViewPoint.move2DToCoordinates(t[0] / 2, t[1] / 2)
    r = e.viewToMapTransformation.transform(tempViewPoint)
  }
  e.layerTree.on('NodeRemoved', (t) => {
    if (o === t.node) {
      r = null
      i.emit('removed')
    }
  })
  if (o)
    f = o.on('VisibilityChanged', (t) => {
      if (!t) e.hideBalloon()
    })
  if (r && r.reference) {
    let t = false
    const o = getZStyleForPoint(
      r,
      void 0,
      DrapeTarget.NOT_DRAPED,
      e.is3D(),
      e.reference
    )
    const n = undefined
    if (o.drapeTarget !== DrapeTarget.NOT_DRAPED || o.aboveGround) t = true
    c = t
      ? e.getOnTerrainModelWorldTransformation(r.reference)
      : createTransformation(r.reference, e.reference)
  }
  i.getPoint = () => {
    try {
      if (r && c) {
        c.transform(r, n)
        return n
      }
      return null
    } catch (t) {
      return null
    }
  }
  i.destroy = () => {
    cleanHandle(d)
    cleanHandle(f)
  }
  i.emitEvent = (t) => {
    const n = () => e.emit('ShowBalloon')
    if (t && o) o.whenReady().then(n)
    else n()
  }
  return i
}
const DUMMY_CONTENT = 'No Content'
const YOFF = 0
function stopEvent(t) {
  t.preventDefault()
  t.stopPropagation()
}
export class BalloonStrategy {
  constructor(t) {
    if (!(t = t || {}).node)
      throw new ProgrammingError(
        'BalloonStrategy::must provide node in which to place the balloon.'
      )
    if (!t.map)
      throw new ProgrammingError(
        'BalloonStrategy::must provide map on which the balloon can be anchored.'
      )
    this._node = t.node
    this._map = t.map
    this._handles = []
    this._balloonContentProvider = null
    this._balloonObject = null
    this._anchor = null
    this._balloon = null
    this._content = null
    this._frame = null
    this._balloonArrow = null
  }
  createBalloon(t) {
    const e = (this._balloon = document.createElement('div'))
    e.className = 'lcdBalloon'
    e.style.position = 'absolute'
    this._node.insertBefore(e, this._node.firstChild)
    this._frame = document.createElement('div')
    const o = this._frame
    o.className = 'lcdFrame'
    o.style.top = '-15px'
    e.appendChild(o)
    const n = document.createElement('div')
    n.className = 'lcdHeader'
    o.insertBefore(n, o.firstChild)
    const i = document.createElement('div')
    i.className = 'lcdClose'
    n.appendChild(i)
    this._content = document.createElement('div')
    const r = this._content
    r.className = 'lcdContent'
    o.appendChild(r)
    if (isString(t)) {
      const e = document.createElement('div')
      e.innerHTML = t
      r.appendChild(e)
    } else r.appendChild(t)
    this._balloonArrow = document.createElement('div')
    const l = this._balloonArrow
    l.style.display = 'block'
    l.style.position = 'relative'
    l.style.bottom = '-15px'
    l.style.width = '0'
    l.style.borderWidth = '15px 15px 0'
    l.style.borderStyle = 'solid'
    l.style.borderColor = `${
      window.getComputedStyle(o).backgroundColor
    } transparent`
    o.appendChild(l)
    this.balloonContentUpdated()
    const s = this
    this._handles.push(On(i, 'mouseup', stopEvent))
    this._handles.push(On(i, 'mousedown', stopEvent))
    this._handles.push(
      On(i, 'click', (t) => {
        stopEvent(t)
        s.hideBalloon()
      })
    )
    function a() {
      s.balloonContentUpdated()
    }
    onImageTags(r, (t) => {
      s._handles.push(On(t, 'load', a))
    })
  }
  cleanup() {
    if (
      this._balloon &&
      this._frame &&
      this._content &&
      this._balloon.parentNode
    ) {
      this._frame.removeChild(this._content)
      this._balloon.parentNode.removeChild(this._balloon)
      this._balloon = null
      this._frame = null
      this._content = null
      this._balloonArrow = null
    }
    if (this._anchor) {
      this._anchor.destroy()
      this._anchor = null
    }
    this._handles.forEach(cleanHandle)
  }
  onChange() {
    if (this._anchor && this._anchor.getPoint()) {
      this.update()
      this.updateContents()
    } else this.hideBalloon()
  }
  onRemove() {
    this.hideBalloon()
  }
  resize() {
    this.update()
  }
  getViewAnchorPoint() {
    const t = this._map.mapToViewTransformation
    const e = this._anchor ? this._anchor.getPoint() : null
    if (e && this._balloon) {
      const o = position(this._balloon)
      try {
        const n = t.transform(e)
        const i = n.x - o.w / 2
        const r = undefined
        return [i, n.y - o.h - YOFF]
      } catch (t) {
        OutOfBoundsError.isOrThrow(t)
        return null
      }
    } else return null
  }
  balloonContentUpdated() {
    this.update()
    if (this._frame && this._balloonArrow) {
      const t =
        getContentBox(this._frame).w / 2 -
        15 -
        +this._frame.style.borderLeftWidth
      this._balloonArrow.style.left = `${t}px`
    }
  }
  update() {
    if (this._balloon) {
      const t = this.getViewAnchorPoint()
      if (t) {
        this._balloon.style.left = `${t[0]}px`
        this._balloon.style.top = `${t[1]}px`
      } else {
        this._balloon.style.left = '-10000px'
        this._balloon.style.top = '-10000px'
      }
    }
  }
  updateContents() {
    if (this._balloonContentProvider && this._balloonObject && this._content) {
      const t = this._balloonContentProvider.getContents(this._balloonObject)
      let e = t
      if (isString(t)) {
        e = document.createElement('div')
        e.innerHTML = t
      }
      this._content.replaceChild(e, this._content.firstElementChild)
    }
  }
  showBalloon(t, e, o, n) {
    if (this._balloon) this.cleanup()
    if (o && o._map !== this._map)
      throw new ProgrammingError(
        'Balloon::the map of this layer does not correspond to this map. '
      )
    const i = justifyContentProvider(e, o, t)
    this._balloonContentProvider = i
    this._balloonObject = t
    this._anchor = createAnchor(t, this._map, o)
    this._anchor.on('changed', this.onChange.bind(this))
    this._anchor.on('removed', this.onRemove.bind(this))
    const r = i.getContents(this._balloonObject)
    if (r && (isString(r) || isFunction(r.appendChild))) {
      this.createBalloon(r)
      this.update()
      let t = false
      if (n) {
        const e = this._anchor.getPoint()
        if (e) {
          t = true
          this._map.mapNavigator.pan({ targetLocation: e, animate: true })
        }
      }
      this._anchor.emitEvent(t)
    }
  }
  hideBalloon() {
    this.cleanup()
    this.update()
    this._map.emit('HideBalloon')
  }
}
function toPixel(t, e) {
  if (!e) return 0
  if ('medium' === e) return 4
  if (e.slice && 'px' === e.slice(-2)) return parseFloat(e)
  const o = t.style,
    n = t.runtimeStyle,
    i = t.currentStyle
  const r = o.left,
    l = n.left
  n.left = i.left
  try {
    o.left = e
    e = o.pixelLeft
  } catch (t) {
    e = '0'
  }
  o.left = r
  n.left = l
  return +e
}
function getPadExtents(t, e) {
  const o = toPixel(t, e.paddingLeft)
  const n = toPixel(t, e.paddingTop)
  const i = toPixel(t, e.paddingRight)
  const r = toPixel(t, e.paddingBottom)
  return { l: o, t: n, r: i, b: r, w: o + i, h: n + r }
}
function getBorderExtents(t, e) {
  const o = 'none'
  const n = e.borderLeftStyle !== o ? toPixel(t, e.borderLeftWidth) : 0
  const i = e.borderTopStyle !== o ? toPixel(t, e.borderTopWidth) : 0
  const r = e.borderRightStyle !== o ? toPixel(t, e.borderRightWidth) : 0
  const l = e.borderBottomStyle !== o ? toPixel(t, e.borderBottomWidth) : 0
  return { l: n, t: i, r: r, b: l, w: n + r, h: i + l }
}
function getContentBox(t) {
  const e = window.getComputedStyle(t)
  let o = t.clientWidth
  let n
  const i = getPadExtents(t, e)
  const r = getBorderExtents(t, e)
  if (!o) {
    o = t.offsetWidth
    n = t.offsetHeight
  } else {
    n = t.clientHeight
    r.w = r.h = 0
  }
  if (-1 !== navigator.userAgent.indexOf('Opera')) {
    i.l += r.l
    i.t += r.t
  }
  return { l: i.l, t: i.t, w: o - i.w - r.w, h: n - i.h - r.h }
}
function position(t, e) {
  const o = t.getBoundingClientRect()
  const n = { x: o.left, y: o.top, w: o.right - o.left, h: o.bottom - o.top }
  if (e) {
    const t = document.defaultView
    if (t) {
      n.x += t.pageXOffset
      n.y += t.pageYOffset
    }
  }
  return n
}
