import { HTML5LabelCanvas } from './HTML5LabelCanvas.js'
import { normalizeLabelStyleForController } from './StyleUtil.js'
export class HTML5ControllerLabelCanvas extends HTML5LabelCanvas {
  constructor(a) {
    super(a || {})
  }
  drawLabel(a, e, l) {
    l = normalizeLabelStyleForController(l)
    super.drawLabel(a, e, l)
  }
  drawLabelInPath(a, e, l) {
    l = normalizeLabelStyleForController(l)
    super.drawLabelInPath(a, e, l)
  }
  drawLabelOnPath(a, e, l) {
    l = normalizeLabelStyleForController(l)
    super.drawLabelOnPath(a, e, l)
  }
}
