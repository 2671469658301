import { getReference } from '../../reference/ReferenceProvider.js'
import { createBounds } from '../../shape/ShapeFactory.js'
class LTSCapabilitiesCoverage {
  constructor(e) {
    this.id = e.id
    this.type = e.type
    this.name = e.name
    this.abstract = e.abstract
    this.referenceName = e.referenceName
    this.level0Rows = e.level0Rows
    this.level0Columns = e.level0Columns
    this.tileWidth = e.tileWidth
    this.tileHeight = e.tileHeight
    this.samplingMode = e.samplingMode
    this._bounds = e.bounds
  }
  getReference() {
    return getReference(this.referenceName)
  }
  getBounds() {
    return createBounds(this.getReference(), [
      this._bounds.x,
      this._bounds.width,
      this._bounds.y,
      this._bounds.height,
    ])
  }
}
export { LTSCapabilitiesCoverage }
