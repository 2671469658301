import { VirtualClipPolylineUtil } from './VirtualClipPolylineUtil.js'
import { ClipSectionUtil } from './ClipSectionUtil.js'
import { PathIterator } from '../complexstroke/PathIterator.js'
const pathIterator = new PathIterator([0, 0, 0, 0], 0, 4)
const clippingVirtual = new VirtualClipPolylineUtil()
export function drawClippedPath(t, e, i, a) {
  const o = Math.max(a, 10)
  const n = {
    x: -a,
    width: t.canvas.width + 2 * o,
    y: -a,
    height: t.canvas.height + 2 * o,
  }
  const r = clippingVirtual.run(e, i, n)
  if (!r || 0 === r.clippedSections.length) return
  const l = r.clippedSections
  const c = r.totalDistance
  const s = ClipSectionUtil.getVisibleSections(l, a, c)
  if (0 === s.length) return
  drawLine(t, e, i, s, c)
}
function drawLine(t, e, i, a, o) {
  pathIterator.reloadWithCalculatedDistance(e, 0, i, o)
  for (const e of a) {
    const i = e[0]
    const a = e[1]
    const o = i - pathIterator.distanceFromStart()
    pathIterator.advanceDistance(o)
    t.moveTo(pathIterator.x(), pathIterator.y())
    drawTill(t, pathIterator, a)
  }
}
function drawTill(t, e, i) {
  let a = false
  while (!a && !e.atEnd() && e.distanceFromStart() < i)
    if (e.absoluteDistanceToNextVertex() <= i) {
      e.advanceToNextVertex()
      t.lineTo(e.x(), e.y())
    } else a = true
  const o = i - e.distanceFromStart()
  if (!e.atEnd() && o > 0) {
    e.advanceDistance(o)
    t.lineTo(e.x(), e.y())
  }
}
