import { isDefined } from '../../util/Lang.js'
export const DEFAULT_TOUCH_INTERACTION_RADIUS = 32
export const DEFAULT_MOUSE_INTERACTION_RADIUS = 16
export function mergeMinMaxPointCount(n, t) {
  const i =
    -1 === n.minimumPointCount ? Number.NEGATIVE_INFINITY : n.minimumPointCount
  const o =
    -1 === n.maximumPointCount ? Number.POSITIVE_INFINITY : n.maximumPointCount
  const m = {
    minimumPointCount: isDefined(t.minPointCount) ? t.minPointCount : -1,
    maximumPointCount: isDefined(t.maxPointCount) ? t.maxPointCount : -1,
  }
  const u =
    -1 === m.minimumPointCount ? Number.NEGATIVE_INFINITY : m.minimumPointCount
  const I =
    -1 === m.maximumPointCount ? Number.POSITIVE_INFINITY : m.maximumPointCount
  const e = Math.max(i, u)
  const N = Math.min(o, I)
  return {
    ...n,
    minimumPointCount: e === Number.NEGATIVE_INFINITY ? -1 : e,
    maximumPointCount: N === Number.POSITIVE_INFINITY ? -1 : N,
  }
}
export function getInteractionRadius(n, t) {
  return 'touch' === n.inputType
    ? t.settings.touchInteractionRadius
    : t.settings.mouseInteractionRadius
}
