import { parse } from './JSON.js'
import { mimeType } from './mimeType.js'
import { base64 } from './base64.js'
var backstop = {}
function decodeBase64(e) {
  var r
  var a,
    t,
    n,
    o,
    i,
    f,
    d,
    c,
    s = [],
    u,
    p
  var l = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  p = e
  for (var m = 0; m < p.length; m += 4) {
    a =
      ((c =
        ((o = l.indexOf(p.charAt(m))) << 18) |
        ((i = l.indexOf(p.charAt(m + 1))) << 12) |
        ((f = l.indexOf(p.charAt(m + 2))) << 6) |
        (d = l.indexOf(p.charAt(m + 3)))) >>>
        16) &
      255
    t = (c >>> 8) & 255
    n = 255 & c
    s[m / 4] = String.fromCharCode(a, t, n)
    if (64 == d) s[m / 4] = String.fromCharCode(a, t)
    if (64 == f) s[m / 4] = String.fromCharCode(a)
  }
  return (u = s.join(''))
}
function decodeDataUriText(e, r) {
  var a = decodeURIComponent(r)
  if (e)
    try {
      return atob(a)
    } catch (e) {
      return decodeBase64(a)
    }
  return a
}
function decodeDataUriArrayBuffer(e, r) {
  var a = decodeDataUriText(e, r)
  var t = new ArrayBuffer(a.length)
  var n = new Uint8Array(t)
  for (var o = 0; o < a.length; o++) n[o] = a.charCodeAt(o)
  return t
}
function decodeDataUri(e, r) {
  var a =
    'undefined' !== typeof (r = r || {}).responseType ? r.responseType : ''
  var t = r.mimeType || mimeType.png
  var n = r.isBase64 ?? true
  switch (a) {
    case '':
    case 'text':
      return decodeDataUriText(n, e)
    case 'ArrayBuffer':
      return decodeDataUriArrayBuffer(n, e)
    case 'blob':
      var o = decodeDataUriArrayBuffer(n, e)
      return new Blob([o], { type: t })
    case 'document':
      return new DOMParser().parseFromString(decodeDataUriText(n, e), t)
    case 'json':
      return parse(decodeDataUriText(n, e))
    default:
      throw 'Unhandled responseType: ' + a
  }
}
let aNode
let domain
let isDefaultPort
const isLocalURL = (e) => {
  if (!aNode || !domain || !isDefaultPort) {
    aNode = document.createElement('a')
    domain = `${window.location.protocol}//${window.location.host}`
    isDefaultPort = '' === window.location.port
  }
  aNode.href = e
  const r = aNode.href
  if (0 === r.indexOf(domain))
    if (isDefaultPort) return ':' !== r[domain.length]
    else return true
  else return false
}
function parseQueryString(e) {
  var r = {}
  var a = 'string' === typeof e ? e : document.location.search
  if ('' == a) return r
  if (a.indexOf('?') >= 0) a = a.replace(/(.*)\?(.*)/, '$2')
  else if (a.indexOf('=') < 0) a = ''
  a = a.replace(/(^\?)/, '').replace(/;/g, '&')
  while (-1 != a.indexOf('&&')) a = a.replace(/&&/g, '&')
  a = (a = a.replace(/([\&]+$)/, '')).split('&')
  for (var t = 0; t < a.length; t++) {
    if ('' === a[t]) continue
    var n = a[t].split('=')
    if (
      'undefined' ===
      typeof (n = n.map(function (e) {
        return decodeURIComponent(e)
      }))[1]
    )
      n[1] = null
    if ('undefined' !== typeof r[n[0]]) {
      if ('string' == typeof r[n[0]]) {
        var o = r[n[0]]
        if ('' == n[1]) n[1] = null
        r[n[0]] = []
        r[n[0]].push(o)
        r[n[0]].push(n[1])
      } else if ('object' == typeof r[n[0]]) {
        if ('' === n[1]) n[1] = null
        r[n[0]].push(n[1])
      }
    } else {
      if ('' === n[1]) n[1] = null
      r[n[0]] = n[1]
    }
  }
  return r
}
export var URL = {
  buildQueryString: function (e) {
    var r = encodeURIComponent
    var a = []
    var t
    var n
    var o
    for (n in e)
      if ((t = e[n]) != backstop[n]) {
        o = r(n) + '='
        if (Array.isArray(t))
          for (var i = 0, f = t.length; i < f; ++i) a.push(o + r(t[i]))
        else a.push(o + r(t))
      }
    return a.join('&')
  },
  parseQueryString: parseQueryString,
  fromData: function (e, r) {
    var a
    var t
    return (
      'data:' +
      ((r = r || {}).mimeType || mimeType.png) +
      ';' +
      (r.encoding || base64.name) +
      ',' +
      e
    )
  },
  dataUriRegex: /^data:(.*?)(;base64)?,(.*)$/,
  decodeDataUri: decodeDataUri,
  isLocalURL: isLocalURL,
}
