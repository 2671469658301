import { Constants } from '../../util/Constants.js'
import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
import { DrawBaseLabelHtmlContents } from './DrawBaseLabelHtmlContents.js'
import { LabelPositions } from './LabelPositions.js'
import { AnchorPoints } from './AnchorPoints.js'
import { PathLabelRotation } from './PathLabelRotation.js'
import { PathLabelPosition } from './PathLabelPosition.js'
import { drawNullCommand } from './NullDrawCommand.js'
import { INVALID_LAYER_CLIP } from '../LayerStyle.js'
const anchorPoints = new AnchorPoints()
const tmpOutCoord1 = new XYZPoint(null, [0, 0, 0])
const tmpOutCoord2 = new XYZPoint(null, [0, 0, 0])
const tmpInCoord1 = new XYZPoint(null, [0, 0, 0])
const tmpInCoord2 = new XYZPoint(null, [0, 0, 0])
export class DrawOnPathLabelHtmlContents extends DrawBaseLabelHtmlContents {
  previousAnchorOrientationPoint = new XYZPoint(null, [0, 0, 0])
  previousAnchorPoint = new XYZPoint(null, [0, 0, 0])
  _labelWidth = 10
  constructor(t, o, i, n, s, e, r) {
    super(i, o, t, n, s, r.padding, e)
    if (i.refreshGeometryHash) i.refreshGeometryHash()
    this._fAngle = 0
    this._fSinAngle = 0
    this._fCosAngle = 0
    this._positions = r.positions || PathLabelPosition.CENTER
    this._fLabelRotation = r.rotation
    this._perpendicularOffset = r.perpendicularOffset || 0
  }
  requestToDrawLabel(t) {
    this.clip = t ?? INVALID_LAYER_CLIP
    this.group.addLabel(this)
  }
  placeHtml(t, o, i, n) {
    if (!this.drawCommand || this.drawCommand === drawNullCommand) return false
    this.zOrder = n
    let s = false
    if (this.previousPosition !== LabelPositions.INVALID_POSITION)
      s = this.tryPreviousLabelAnchor(o, i)
    if (!s) s = this.tryAllLabelAnchorsAndPaint(o, i)
    if (s) {
      if (this.isValidLocation(this.previousAnchorPoint)) {
        this.toScreen(t)
        return true
      }
      this.previousPosition = LabelPositions.INVALID_POSITION
    }
    return false
  }
  tryAllLabelAnchorsAndPaint(t, o) {
    try {
      anchorPoints.reset()
      this.drawCommand?.mapAnchorPointsSFCT(t, anchorPoints, this._labelWidth)
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
      return false
    }
    const i = this.labelContext.mapToViewTransformation
    for (let t = 0; t < anchorPoints.anchorPointsLength; t++) {
      updateAnchor(tmpInCoord1, anchorPoints.anchorPoints, 3 * t)
      updateAnchor(tmpInCoord2, anchorPoints.anchorOrientationPoints, 3 * t)
      if (
        !this.drawCommand?.viewPointSFCT(tmpInCoord1, tmpOutCoord1, i) ||
        !this.drawCommand?.viewPointSFCT(tmpInCoord2, tmpOutCoord2, i)
      )
        continue
      this.setAngle(tmpOutCoord1, tmpOutCoord2)
      const n = this.findLabelPosition(
        tmpOutCoord1,
        LabelPositions.INVALID_POSITION,
        o,
        this._positions,
        3
      )
      if (n !== LabelPositions.INVALID_POSITION) {
        this.previousPosition = n
        updateAnchor(this.previousAnchorPoint, tmpInCoord1.coordinates)
        updateAnchor(
          this.previousAnchorOrientationPoint,
          tmpInCoord2.coordinates
        )
        return true
      }
    }
    this.previousPosition = LabelPositions.INVALID_POSITION
    return false
  }
  setAngle(t, o) {
    if (
      this._fLabelRotation === PathLabelRotation.NO_ROTATION ||
      (t.x === o.x && t.y === o.y && t.z === o.z)
    ) {
      this._fAngle = 0
      this._fCosAngle = 1
      this._fSinAngle = 0
    } else {
      let i = Math.atan2(o.y - t.y, o.x - t.x) * Constants.RAD2DEG
      i = i > 90 ? i - 180 : i
      i = i < -90 ? i + 180 : i
      this._fAngle = i
      this._fCosAngle = Math.cos(Constants.DEG2RAD * i)
      this._fSinAngle = Math.sin(Constants.DEG2RAD * i)
    }
  }
  tryPreviousLabelAnchor(t, o) {
    const i = this.labelContext.mapToViewTransformation
    try {
      if (
        !this.drawCommand?.viewPointSFCT(
          this.previousAnchorPoint,
          tmpOutCoord1,
          i
        ) ||
        !this.drawCommand?.viewPointSFCT(
          this.previousAnchorOrientationPoint,
          tmpOutCoord2,
          i
        )
      ) {
        this.previousPosition = LabelPositions.INVALID_POSITION
        return false
      }
      this.setAngle(tmpOutCoord1, tmpOutCoord2)
      const t = this.findLabelPosition(
        tmpOutCoord1,
        this.previousPosition,
        o,
        this._positions,
        3
      )
      if (t !== LabelPositions.INVALID_POSITION) {
        this.previousPosition = t
        if (null !== o) o.addPosition(this)
        return true
      } else {
        this.previousPosition = LabelPositions.INVALID_POSITION
        return false
      }
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
      this.previousPosition = LabelPositions.INVALID_POSITION
      return false
    }
  }
  resetLabelBounds(t, o) {
    const { boundsWidth: i, ascent: n, descent: s } = this.getBoundMetrics()
    const e = i
    const r = e / 2
    const a = n / 2
    let h = t.x - r * this._fCosAngle
    let l = t.y - r * this._fSinAngle
    if (o === LabelPositions.ON_PATH_CENTER_SHIFT) {
      h -= a * this._fSinAngle
      l += a * this._fCosAngle
    } else if (o === LabelPositions.ON_PATH_BELOW_SHIFT) {
      h -= n * this._fSinAngle
      l += n * this._fCosAngle
    }
    this.setLTRBAnchorXYAngleDeg(
      h,
      l - n - this._perpendicularOffset,
      h + e,
      l + s - this._perpendicularOffset,
      h,
      l,
      this._fAngle,
      this._padding
    )
  }
  reuseStateFrom(t) {
    if (!this.drawCommand) return
    const o = t.findLabelDrawCommand(
      this.drawCommand,
      DrawOnPathLabelHtmlContents
    )
    if (o?.drawCommand?.hasSameGeometry(this.drawCommand)) {
      this.previousPosition = o.previousPosition
      updateAnchor(this.previousAnchorPoint, o.previousAnchorPoint.coordinates)
      updateAnchor(
        this.previousAnchorOrientationPoint,
        o.previousAnchorOrientationPoint.coordinates
      )
    }
  }
  computeMetrics() {
    const { boundsWidth: t } = this.computeMetricsBase()
    this._labelWidth = t
  }
  toScreen(t) {
    this.toScreenBase(t)
  }
}
function updateAnchor(t, o) {
  let i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0
  t.x = o[i]
  t.y = o[i + 1]
  t.z = o[i + 2]
}
