import { HelperHandle } from './HelperHandle.js'
export class GeoBufferHelperHandle extends HelperHandle {
  constructor(e, r) {
    super(r)
    this._geoBuffer = e
  }
  get geoBuffer() {
    return this._geoBuffer
  }
  onDraw(e, r) {
    const t = this.getHelperStyle(r)
    if (t) {
      const s = this.geoBuffer.baseShape
      const o = this.getDrapeTarget(r, s)
      e.drawShape(s, { stroke: t.stroke, zOrder: 9, drapeTarget: o })
      e.drawShape(this.geoBuffer, {
        stroke: t.stroke,
        zOrder: -1,
        drapeTarget: o,
      })
    }
  }
}
