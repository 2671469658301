import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Affine2D } from '../../transformation/Affine2D.js'
import { Invalidation } from '../../util/Invalidation.js'
import { hasProperty, isDefined, isString } from '../../util/Lang.js'
import { ModelOptionsUtil } from './ModelOptionsUtil.js'
import { RasterDataType } from './RasterDataType.js'
import { RasterSamplingMode } from './RasterSamplingMode.js'
import { TileCoordinateUtil } from './TileCoordinateUtil.js'
import { isQuadTreeTileMatrixSetStructure } from './RasterTileSetModelUtil.js'
const DEFAULT_MODEL_DESCRIPTOR = {
  source: 'N/A',
  name: 'Raster Tileset Model',
  type: RasterDataType.IMAGE,
  description: 'An abstract raster tileset model',
}
function isCurrentOptions(e) {
  return void 0 !== e.structure
}
function isTileMatrixSetStructure(e) {
  return void 0 !== e.tileMatrix
}
export class RasterTileSetModel extends Invalidation {
  constructor(e) {
    super()
    this._dataType = e.dataType ?? RasterDataType.IMAGE
    if (isCurrentOptions(e)) {
      const t = e.structure
      if (isTileMatrixSetStructure(t)) {
        if (this.dataType == RasterDataType.ELEVATION)
          this.verifyElevationHasQuadTreeStructure(t)
        this._rasterTileSetInfo = this.createTileMatrixSetInfo(t)
      } else {
        const e = t.levelCount ?? 22
        const r = t.level0Columns ?? 1
        const i = t.level0Rows ?? 1
        const o = t.tileWidth ?? 256
        const n = t.tileHeight ?? 256
        this._rasterTileSetInfo = this.createQuadTreeInfo(
          t.reference,
          t.bounds.copy(),
          e,
          r,
          i,
          o,
          n
        )
      }
    } else {
      let t = e
      t = ModelOptionsUtil.validateRasterModelOptions(t)
      if (!isDefined(t.reference))
        throw new TypeError(
          `Legacy quad-tree definition requires the property 'reference'.`
        )
      if (!isDefined(t.bounds))
        throw new TypeError(
          `Legacy quad-tree definition requires the property 'bounds'.`
        )
      const r = t.levelCount ?? 22
      const i = t.level0Columns ?? 1
      const o = t.level0Rows ?? 1
      const n = t.tileWidth ?? 256
      const l = t.tileHeight ?? 256
      this._rasterTileSetInfo = this.createQuadTreeInfo(
        t.reference,
        t.bounds.copy(),
        r,
        i,
        o,
        n,
        l
      )
    }
    this._samplingMode =
      e.samplingMode ||
      (this._dataType === RasterDataType.IMAGE
        ? RasterSamplingMode.AREA
        : RasterSamplingMode.POINT)
    this._modelDescriptor = e.modelDescriptor || DEFAULT_MODEL_DESCRIPTOR
  }
  verifyElevationHasQuadTreeStructure(e) {
    const t = isQuadTreeTileMatrixSetStructure(e)
    if (isString(t))
      throw new ProgrammingError(
        `RasterTileSetModel for elevation must be a quad-tree: ${t}.`
      )
  }
  createQuadTreeInfo(e, t, r, i, o, n, l) {
    const s = [n || 256, l || 256]
    const a = [(s[0] * i) / t.width, (s[1] * o) / t.height]
    const u = new Map()
    for (let e = r - 1; e >= 0; e--) {
      const r = [t.x, t.y],
        n = [t.x + t.width, t.y + t.height]
      const l = o * Math.pow(2, e)
      const d = i * Math.pow(2, e)
      const c = {
        pixelDensity: [Math.pow(2, e) * a[0], Math.pow(2, e) * a[1]],
        rows: l,
        cols: d,
        transformation: new Affine2D(r, [0, 0], n, [
          Math.pow(2, e) * i,
          Math.pow(2, e) * o,
        ]),
        grid: {
          x: 0,
          y: 0,
          width: d,
          height: l,
          tileSize: s,
          level: e,
          extent: t,
        },
      }
      u.set(e, c)
    }
    return { reference: e, bounds: t, levelCount: r, levelMap: u }
  }
  createTileMatrixSetInfo(e) {
    const t = e.reference
    const r = e.bounds.copy()
    const i = e.tileMatrix.length
    const o = new Map()
    let n = null
    for (let t = 0; t < i; t++) {
      const r = e.tileMatrix[t]
      const i = [r.bounds.x, r.bounds.y],
        l = [r.bounds.x + r.bounds.width, r.bounds.y + r.bounds.height]
      const s = [r.tileWidth || 256, r.tileHeight || 256]
      const a = {
        pixelDensity: [
          (s[0] * r.tileColumnCount) / r.bounds.width,
          (s[1] * r.tileRowCount) / r.bounds.height,
        ],
        rows: r.tileRowCount,
        cols: r.tileColumnCount,
        transformation: new Affine2D(i, [0, 0], l, [
          r.tileColumnCount,
          r.tileRowCount,
        ]),
        grid: {
          x: 0,
          y: 0,
          width: r.tileColumnCount,
          height: r.tileRowCount,
          tileSize: s,
          level: t,
          extent: r.bounds.copy(),
        },
      }
      if (t > 0) {
        if (null == n)
          throw new ProgrammingError(
            `RasterTileSetModel: TileMatrixSet2DRasterTileSetStructure. Could not calculate information for level.`
          )
        if (
          a.pixelDensity[0] < n.pixelDensity[0] ||
          a.pixelDensity[1] < n.pixelDensity[1]
        )
          throw new ProgrammingError(
            `RasterTileSetModel: incorrect TileMatrixSet2DRasterTileSetStructure. Pixel densities must be higher for more detailed levels.`
          )
      }
      n = a
      o.set(t, a)
    }
    return { reference: t, bounds: r, levelCount: i, levelMap: o }
  }
  getBounds(e) {
    const t = this._rasterTileSetInfo.levelMap.get(e)
    return e < this._rasterTileSetInfo.levelCount && t ? t.grid.extent : null
  }
  getPixelDensity(e) {
    const t = this._rasterTileSetInfo.levelMap.get(e)
    return e < this._rasterTileSetInfo.levelCount && t ? t.pixelDensity : null
  }
  getTileWidth(e) {
    const t = this._rasterTileSetInfo.levelMap.get(e)
    return e < this._rasterTileSetInfo.levelCount && t
      ? t.grid.tileSize[0]
      : null
  }
  getTileHeight(e) {
    const t = this._rasterTileSetInfo.levelMap.get(e)
    return e < this._rasterTileSetInfo.levelCount && t
      ? t.grid.tileSize[1]
      : null
  }
  getTileRowCount(e) {
    const t = this._rasterTileSetInfo.levelMap.get(e)
    return e < this._rasterTileSetInfo.levelCount && t ? t.grid.height : null
  }
  getTileColumnCount(e) {
    const t = this._rasterTileSetInfo.levelMap.get(e)
    return e < this._rasterTileSetInfo.levelCount && t ? t.grid.width : null
  }
  getTileData(e, t, r, i) {
    const o = (e, i, o) => {
      const n = { data: i }
      if (o) n.mimeType = o
      try {
        t(e, n)
      } catch (t) {
        r(e, t)
      }
    }
    this.getImage(e, o, r, i)
  }
  get dataType() {
    return this._dataType
  }
  get samplingMode() {
    return this._samplingMode
  }
  get levelCount() {
    return this._rasterTileSetInfo.levelCount
  }
  get reference() {
    return this._rasterTileSetInfo.reference
  }
  get coordinateType() {
    return this._rasterTileSetInfo.reference.coordinateType
  }
  get bounds() {
    return this._rasterTileSetInfo.bounds
  }
  getTileBounds(e) {
    if (e.level < 0 || e.level > this.levelCount - 1)
      throw new Error(
        `tile.level must be larger than 0 and smaller than ${this.levelCount}`
      )
    const t = this.getTileColumnCount(e.level)
    if (e.x < 0 || e.x > t)
      throw new Error(`tile.x must be larger than 0 and smaller than ${t}`)
    const r = this.getTileRowCount(e.level)
    if (e.y < 0 || e.y > r)
      throw new Error(`tile.y must be larger than 0 and smaller than ${r}`)
    return TileCoordinateUtil.getTileBounds(this, e)
  }
  get modelDescriptor() {
    return this._modelDescriptor
  }
  set modelDescriptor(e) {
    if (
      !e ||
      !['type', 'name', 'description', 'source'].every(
        (t) => !hasProperty(e, t) || isString(e[t])
      )
    )
      throw new ProgrammingError('RasterTileSetModel::invalid modelDescriptor')
    this._modelDescriptor = e
  }
  invalidate() {
    super.invalidate()
  }
  on(e, t, r) {
    return super.on(e, t, r)
  }
}
