import { ShapeType } from './ShapeType.js'
export class Parallel {
  constructor(t, e, i) {
    this._latitude = t
    this._fromLongitude = e
    this._toLongitude = i
    this._type = ShapeType.PARALLEL
  }
  get latitude() {
    return this._latitude
  }
  get fromLongitude() {
    return this._fromLongitude
  }
  get toLongitude() {
    return this._toLongitude
  }
  get type() {
    return this._type
  }
  get reference() {
    return this._reference
  }
  set reference(t) {
    this._reference = t
  }
  width() {
    const t = this._toLongitude - this._fromLongitude
    return t > 0 ? t : 360 - t
  }
  hashCode(t) {
    t.appendUInt32(this._type)
    t.appendDouble(this._latitude)
    t.appendDouble(this._fromLongitude)
    t.appendDouble(this._toLongitude)
  }
  toString() {
    return `PARALLEL: ${this._latitude} -from/to: ${this._fromLongitude},${this._toLongitude}`
  }
  getDegrees() {
    return this._latitude
  }
}
