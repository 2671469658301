import {
  createCartesianGeodesy,
  createEllipsoidalGeodesy,
} from '../../../geodesy/GeodesyFactory.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { createCircularArcByCenterPoint } from '../../../shape/ShapeFactory.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { Editor } from '../Editor.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { CircularArcHelperHandle } from '../handles/helper/CircularArcHelperHandle.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultSize,
} from '../handles/CreateByTemplateHandle.js'
export class CircularArcByCenterPointEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(
      ShapeType.CIRCULAR_ARC_BY_CENTER_POINT,
      e.shape.type
    )
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createStartPointHandle(e),
      this.createEndPointHandle(e),
      this.createRadiusHandle(e),
      this.createHelperHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const r = e.shape
    return new PointDragHandle(
      () => r.center,
      (e) => r.translate2D(e.x - r.center.x, e.y - r.center.y)
    )
  }
  createStartPointHandle(e) {
    const r = e.shape
    return new PointDragHandle(
      () => r.startPoint,
      (e) => r.updateForStartPoint(e)
    )
  }
  createRadiusHandle(e) {
    const r = e.shape
    const t = r.reference
    const a =
      t.referenceType === ReferenceType.GEODETIC
        ? createEllipsoidalGeodesy(t)
        : createCartesianGeodesy(t)
    return new PointDragHandle(
      () =>
        a.interpolate(r.center, r.radius, r.startAzimuth + r.sweepAngle / 2),
      (e) => {
        r.radius = a.distance(r.center, e)
      }
    )
  }
  createEndPointHandle(e) {
    const r = e.shape
    return new PointDragHandle(
      () => r.endPoint,
      (e) => r.updateForEndPoint(e)
    )
  }
  createHelperHandle(e) {
    const r = e.shape
    const t = undefined
    return new CircularArcHelperHandle(() => {
      const e = (r.sweepAngle > 0 ? -360 : 360) - -r.sweepAngle
      return createCircularArcByCenterPoint(
        r.reference,
        r.center,
        r.radius,
        r.startAzimuth,
        e
      )
    })
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, r) => {
      const t = e.shape
      t.move2DToPoint(r)
      t.radius = getDefaultSize(e.map)
    })
  }
}
