import { ProgrammingError } from '../error/ProgrammingError.js'
import { GeocentricReference } from '../reference/GeocentricReference.js'
import { TopocentricReference } from '../reference/TopocentricReference.js'
import { BoundsCalculationUtil } from './BoundsCalculationUtil.js'
import { Transformation } from './Transformation.js'
import { Matrix4 } from '../geometry/mesh/math/Matrix4.js'
import { Vector3 } from '../geometry/mesh/math/Vector3.js'
import { Constants } from '../util/Constants.js'
function isCompatible(t, e) {
  return t instanceof TopocentricReference && e instanceof GeocentricReference
}
export class TopocentricGeocentricTransformation extends Transformation {
  constructor(t, e, o, r, i) {
    super(t, r)
    if (!isCompatible(t, r))
      throw new ProgrammingError(
        'TopocentricGeocentricTransformation - references not compatible.'
      )
    this._srcTopocentricReference = t
    this._srcOriginLLH = e
    this._srcOriginGeocentric = o
    this._dstGeocentricReference = r
    this._srcGeodeticDatum = this._srcTopocentricReference.geodeticDatum
    this._dstGeodeticDatum = this._dstGeocentricReference.geodeticDatum
    this._equalHorizontalDatums = this._srcGeodeticDatum.equals2D(
      this._dstGeodeticDatum
    )
    this._bboxCalculationUtil = new BoundsCalculationUtil({
      modelReference: t,
      worldReference: r,
      transformation: this,
      TransformationFactory: i,
    })
    this._topoc2GeocMat = new Matrix4()
    this._geoc2TopocMat = new Matrix4()
    this._tmpVec1 = new Vector3()
    this.createMatrices()
  }
  get srcTopocentricReference() {
    return this._srcTopocentricReference
  }
  createMatrices() {
    const t = this._srcOriginLLH
    const e = this._srcOriginGeocentric
    const o = Math.cos(t.x * Constants.DEG2RAD)
    const r = Math.cos(t.y * Constants.DEG2RAD)
    const i = Math.sin(t.x * Constants.DEG2RAD)
    const s = Math.sin(t.y * Constants.DEG2RAD)
    const c = new Matrix4()
    c.set(-i, -o * s, o * r, 0, o, -i * s, i * r, 0, 0, r, s, 0, 0, 0, 0, 1)
    const n = new Matrix4()
    n.set(1, 0, 0, e.x, 0, 1, 0, e.y, 0, 0, 1, e.z, 0, 0, 0, 1)
    this._topoc2GeocMat.multiplyMatrices(n, c)
    c.set(-i, o, 0, 0, -o * s, -i * s, r, 0, o * r, r * i, s, 0, 0, 0, 0, 1)
    n.set(1, 0, 0, -e.x, 0, 1, 0, -e.y, 0, 0, 1, -e.z, 0, 0, 0, 1)
    this._geoc2TopocMat.multiplyMatrices(c, n)
  }
  _forward(t, e) {
    this._tmpVec1.set(t.x, t.y, t.z)
    this._tmpVec1.applyMatrix4(this._topoc2GeocMat)
    e.move3D(this._tmpVec1.x, this._tmpVec1.y, this._tmpVec1.z)
    return e
  }
  _inverse(t, e) {
    this._tmpVec1.set(t.x, t.y, t.z)
    this._tmpVec1.applyMatrix4(this._geoc2TopocMat)
    e.move3D(this._tmpVec1.x, this._tmpVec1.y, this._tmpVec1.z)
    return e
  }
  _forwardBoundsCoords(t, e) {
    return this._bboxCalculationUtil.forwardBounds2dSFCT(t, e)
  }
  _inverseBoundsCoords(t, e) {
    return this._bboxCalculationUtil.inverseBounds2dSFCT(t, e)
  }
  forwardDirection(t, e) {
    this._tmpVec1.set(t.x, t.y, t.z)
    this._tmpVec1.transformDirection(this._topoc2GeocMat)
    e.x = this._tmpVec1.x
    e.y = this._tmpVec1.y
    e.z = this._tmpVec1.z
  }
  inverseDirection(t, e) {
    this._tmpVec1.set(t.x, t.y, t.z)
    this._tmpVec1.transformDirection(this._geoc2TopocMat)
    e.x = this._tmpVec1.x
    e.y = this._tmpVec1.y
    e.z = this._tmpVec1.z
  }
  inversePoint(t, e) {
    this._inverse(t, e)
  }
}
