import { On } from '../../util/On.js'
import { MouseHandler } from './MouseHandler.js'
const DOM_EVENT_NAME_2_METHOD_NAME = {
  MSPointerDown: 'onmousedown',
  MSPointerMove: 'onmousemove',
  MSPointerUp: 'onmouseup',
  blur: 'onblur',
  contextmenu: 'oncontextmenu',
  wheel: 'onmousewheel',
}
export class MSPointerHandler extends MouseHandler {
  constructor(n, o, e, t) {
    super(n, o, e)
    this._multipleInputs = t
  }
  registerWindowListeners() {
    if (!this._windowListeners)
      this._windowListeners = [
        On(window, 'MSPointerMove', this._onmousemove_bound),
        On(window, 'MSPointerUp', this._onmouseup_bound),
        On(window, 'blur', this, this._onblur_bound),
        On(window, 'contextmenu', this, this._oncontextmenu_bound),
      ]
  }
  _wireDomListeners(n) {
    this._domListeners = {}
    for (const o in DOM_EVENT_NAME_2_METHOD_NAME)
      if (DOM_EVENT_NAME_2_METHOD_NAME.hasOwnProperty(o))
        this._wrapEventHandler(n, o)
  }
  _wrapEventHandler(n, o) {
    const e = this
    e._domListeners[o] = On(n, o, function (n) {
      if (!e._multipleInputs || 'touch' !== n.pointerType) {
        const t = e[DOM_EVENT_NAME_2_METHOD_NAME[o]](n)
        e._map._updateViewBounds()
        if (t) {
          n.preventDefault()
          n.stopPropagation()
        }
      }
    })
  }
}
