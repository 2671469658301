import { CompositeEditHandle } from './CompositeEditHandle.js'
import { PointDragHandle } from './PointDragHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { EditMoveConstraint } from './EditMoveConstraint.js'
export class SinglePointTranslateHandle extends PointDragHandle {
  constructor(t, i, n) {
    super(
      () => this.pointList.getPoint(this.index),
      (t) => {
        switch (this.moveConstraint) {
          case EditMoveConstraint.XY:
            this.pointList.move2DPoint(this.index, t.x, t.y)
            break
          case EditMoveConstraint.Z: {
            const i = this.pointList.getPoint(this.index)
            this.pointList.move3DPoint(this.index, i.x, i.y, t.z)
            break
          }
        }
        this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
      },
      n
    )
    this._pointList = t
    this._index = i
  }
  get pointList() {
    return this._pointList
  }
  get index() {
    return this._index
  }
}
export class PointListTranslateHandle extends CompositeEditHandle {
  constructor(t, i) {
    super([], { cascading: false })
    this._pointList = t
    this._handleIconStyle = i
    this.update()
  }
  get pointList() {
    return this._pointList
  }
  get handleIconStyle() {
    return this._handleIconStyle
  }
  shouldUpdateHandles() {
    return this.pointList.pointCount !== this.handles.length
  }
  createTranslateHandles() {
    const t = []
    for (let i = 0; i < this.pointList.pointCount; i++)
      t.push(
        new SinglePointTranslateHandle(this.pointList, i, {
          handleIconStyle: this.handleIconStyle,
        })
      )
    return t
  }
  update() {
    if (this.shouldUpdateHandles()) this.handles = this.createTranslateHandles()
  }
}
