import { isBoolean, isDefined, isNumber } from '../../util/Lang.js'
export class PBRMaterialImpl {
  constructor(t) {
    this.setToLiteral(t)
  }
  setToLiteral(t) {
    if (!isDefined(t)) return
    this.metallicFactor = t.metallicFactor ?? 1
    this.roughnessFactor = t.roughnessFactor ?? 1
  }
  get metallicFactor() {
    return this._metallicFactor
  }
  set metallicFactor(t) {
    if (isNumber(t, false) && t >= 0) this._metallicFactor = t
    else throw new Error(`Invalid metallicFactor: ${t}`)
  }
  get roughnessFactor() {
    return this._roughnessFactor
  }
  set roughnessFactor(t) {
    if (isNumber(t, false) && t >= 0) this._roughnessFactor = t
    else throw new Error(`Invalid roughnessFactor: ${t}`)
  }
}
export class PBRSettingsImpl {
  constructor(t) {
    this.setToLiteral(t)
  }
  setToLiteral(t) {
    if (!isDefined(t)) return
    this.directionalLighting = t.directionalLighting ?? true
    this.lightIntensity = t.lightIntensity ?? 0
    this.imageBasedLighting = t.imageBasedLighting ?? true
    this.material = t.material ?? null
  }
  get directionalLighting() {
    return this._directionalLighting
  }
  set directionalLighting(t) {
    if (isBoolean(t)) this._directionalLighting = t
    else throw new Error(`Invalid directionalLighting: ${t}`)
  }
  get lightIntensity() {
    return this._lightIntensity
  }
  set lightIntensity(t) {
    if (isNumber(t, false)) this._lightIntensity = t
    else throw new Error(`Invalid lightIntensity: ${t}`)
  }
  get imageBasedLighting() {
    return this._imageBasedLighting
  }
  set imageBasedLighting(t) {
    if (isBoolean(t)) this._imageBasedLighting = t
    else throw new Error(`Invalid imageBasedLighting: ${t}`)
  }
  get material() {
    return this._material
  }
  set material(t) {
    this._material = t ? new PBRMaterialImpl(t) : null
  }
}
