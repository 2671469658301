import { Photon } from '../../../gen/photon/photon_painter.js'
import { Log } from '../../../util/Log.js'
export class PhotonScenePainterCallback extends Photon.ScenePainterCallback {
  constructor(e, t) {
    super()
    this._photon = e
    this._tilesetLayer = t
  }
  onBoundsAvailable() {}
  onError(e, t, o) {
    if (0 === t && this._tilesetLayer._warningsEnabled) Log.warn(o)
    else if (1 === t) Log.error(o)
  }
  onSceneReadyChange(e) {
    this._tilesetLayer.sceneReadyState = !!e
  }
  onTileLoadingChange(e, t) {
    this._tilesetLayer.loadingStatus = { tilesLoaded: e, tilesPending: t }
  }
}
