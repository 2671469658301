import { ProgrammingError } from '../error/ProgrammingError.js'
class ContextMenu {
  constructor() {
    this._items = []
    this._title = ''
  }
  addItem(t) {
    this._items.push(t)
  }
  someItem(t) {
    for (let e = 0; e < this._items.length; e += 1)
      if (t(this._items[e])) return true
    return false
  }
  addSeparator() {
    this._items.push({ label: '', action: () => {}, separator: true })
  }
  get title() {
    return this._title
  }
  set title(t) {
    this._title = t
  }
  get items() {
    return this._items.slice()
  }
  set items(t) {
    throw new ProgrammingError('items property is not mutable')
  }
}
export let Identifier = (function (t) {
  t['CANCEL_ID'] = 'CANCEL_ID'
  t['DELETE_POINT_ID'] = 'DELETE_POINT_ID'
  return t
})({})
export { ContextMenu }
