import { isHrefValue, isStateValue } from './KMLTypes.js'
import { xmlFindFirstChild } from './KMLInternalUtil.js'
import { getKmlValue, kmlValueMapFactory } from './KMLValues.js'
export let KMLItemIconState = (function (t) {
  t[(t['NONE'] = 0)] = 'NONE'
  t[(t['OPEN'] = 1)] = 'OPEN'
  t[(t['CLOSED'] = 2)] = 'CLOSED'
  t[(t['ERROR'] = 4)] = 'ERROR'
  t[(t['FETCHING0'] = 8)] = 'FETCHING0'
  t[(t['FETCHING1'] = 16)] = 'FETCHING1'
  t[(t['FETCHING2'] = 32)] = 'FETCHING2'
  return t
})({})
const ICON_STATE_MAP = {
  open: KMLItemIconState.OPEN,
  closed: KMLItemIconState.CLOSED,
  error: KMLItemIconState.ERROR,
  fetching0: KMLItemIconState.FETCHING0,
  fetching1: KMLItemIconState.FETCHING1,
  fetching2: KMLItemIconState.FETCHING2,
}
const getItemIconState = kmlValueMapFactory(
  KMLItemIconState.NONE,
  ICON_STATE_MAP
)
export function getKmlItemIcon(t) {
  let e =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { state: KMLItemIconState.NONE, href: null }
  e.href = getKmlValue(t ? xmlFindFirstChild(t, isHrefValue) : null)
  const n = getKmlValue(t ? xmlFindFirstChild(t, isStateValue) : null)
  const I = (n && n.match(/\b(open|closed|error|fetching[012])\b/g)) || []
  const o = (t, e) => t | getItemIconState(e)
  e.state = I.reduce(o, KMLItemIconState.NONE)
  return e
}
