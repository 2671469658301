import { ProgrammingError } from '../../error/ProgrammingError.js'
import { URL } from '../../util/URL.js'
import { Constants } from '../../util/Constants.js'
import { isArray, isDefined } from '../../util/Lang.js'
import { WMSCapabilities } from '../capabilities/WMSCapabilities.js'
import { WMSImageModel } from '../image/WMSImageModel.js'
import { TileCoordinateUtil } from './TileCoordinateUtil.js'
import { UrlTileSetModel } from './UrlTileSetModel.js'
import {
  createTileSetModelCreatorOptions,
  createTileSetModelOptions,
  validateTileSetModelOptions,
} from './WMSTileSetModelOptionsUtil.js'
function hasRasterTileSetModelParameters(e) {
  return (
    isDefined(e.tileWidth) ||
    isDefined(e.tileHeight) ||
    isDefined(e.level0Columns) ||
    isDefined(e.level0Rows) ||
    isDefined(e.levelCount)
  )
}
export class WMSTileSetModel extends UrlTileSetModel {
  constructor(e) {
    const t = new WMSImageModel(e)
    const s = t.bounds.copy()
    let i
    if (hasRasterTileSetModelParameters(e)) {
      const t = Object.create(e)
      t.bounds = s
      i = validateTileSetModelOptions(t)
    } else i = createTileSetModelOptions(s)
    const r = {
      structure: i.structure,
      baseURL: e.getMapRoot,
      dataType: e.dataType,
      samplingMode: e.samplingMode,
    }
    if (isDefined(e.withCredentials)) r.credentials = !!e.withCredentials
    if (isDefined(e.credentials)) r.credentials = !!e.credentials
    if (isDefined(e.requestHeaders)) r.requestHeaders = e.requestHeaders
    if (isDefined(e.subdomains)) r.subdomains = e.subdomains
    super(r)
    this._wmsImageModel = t
    this._pixelSize = Constants.INCH_TO_MM / 96
    this.useCors = !URL.isLocalURL(e.getMapRoot)
    this._wmsImageModel.on('Invalidated', () => {
      this.invalidate()
    })
  }
  get queryable() {
    return this._wmsImageModel.queryable
  }
  static createFromCapabilities(e, t, s) {
    const i = createTileSetModelCreatorOptions(e, t, (s = s || {}))
    return new WMSTileSetModel(i)
  }
  static createFromURL(e, t, s) {
    return WMSCapabilities.fromURL(e, s).then((e) =>
      WMSTileSetModel.createFromCapabilities(e, t, s)
    )
  }
  getTileURL(e, t) {
    const s = TileCoordinateUtil.getTileBounds(this, t)
    const i = this.getTileWidth(t.level)
    const r = this.getTileHeight(t.level)
    if (null !== i && null !== r) {
      const e = this._wmsImageModel.getImageUrl(s, i, r, this._pixelSize)
      return this.urlPatternUtil.replaceSubdomain(e)
    }
    return null
  }
  _getFeatureInfoInTile(e, t, s, i) {
    const r = undefined
    const o = undefined
    const l = undefined
    const a = {
      bounds: TileCoordinateUtil.getTileBounds(this, e),
      size: [this.getTileWidth(e.level), this.getTileHeight(e.level)],
      i: t,
      j: s,
      pixelSize: this._pixelSize,
    }
    if (i) {
      a.infoFormat = i.infoFormat
      a.featureCount = i.featureCount
    }
    return this._wmsImageModel.getFeatureInfo(a)
  }
  get layers() {
    return this._wmsImageModel.layers
  }
  set layers(e) {
    this._wmsImageModel.layers = e
  }
  get queryLayers() {
    return this._wmsImageModel.queryLayers || []
  }
  set queryLayers(e) {
    if (!isArray(e))
      throw new ProgrammingError(
        'WMSImageModel::Need to specify the queryLayers as an array of strings'
      )
    this._wmsImageModel.queryLayers = e.slice()
  }
  get transparent() {
    return this._wmsImageModel.transparent
  }
  set transparent(e) {
    this._wmsImageModel.transparent = e
  }
  get backgroundColor() {
    return this._wmsImageModel.backgroundColor
  }
  set backgroundColor(e) {
    this._wmsImageModel.backgroundColor = e
  }
  get requestParameters() {
    return this._wmsImageModel.requestParameters
  }
  set requestParameters(e) {
    this._wmsImageModel.requestParameters = e
  }
  get styles() {
    return this._wmsImageModel.styles
  }
  set styles(e) {
    this._wmsImageModel.styles = e
  }
  get sld() {
    return this._wmsImageModel.sld
  }
  set sld(e) {
    this._wmsImageModel.sld = e
  }
  get sldBody() {
    return this._wmsImageModel.sldBody
  }
  set sldBody(e) {
    this._wmsImageModel.sldBody = e
  }
  get dimensions() {
    return this._wmsImageModel.dimensions
  }
  set dimensions(e) {
    this._wmsImageModel.dimensions = e
  }
}
