import { ProgrammingError } from '../error/ProgrammingError.js'
const chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
function btoaFallback(r) {
  const t = String(r)
  for (
    var o, a, e = 0, n = chars, c = '';
    t.charAt(0 | e) || ((n = '='), e % 1);
    c += n.charAt(63 & (o >> (8 - (e % 1) * 8)))
  ) {
    if ((a = t.charCodeAt((e += 3 / 4))) > 255)
      throw new ProgrammingError(
        "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
      )
    o = (o << 8) | a
  }
  return c
}
function atobFallback(r) {
  const t = String(r).replace(/[=]+$/, '')
  if (t.length % 4 === 1)
    throw new ProgrammingError(
      "'atob' failed: The string to be decoded is not correctly encoded."
    )
  for (
    var o = 0, a, e, n = 0, c = '';
    (e = t.charAt(n++));
    ~e && ((a = o % 4 ? 64 * a + e : e), o++ % 4)
      ? (c += String.fromCharCode(255 & (a >> ((-2 * o) & 6))))
      : 0
  )
    e = chars.indexOf(e)
  return c
}
export const base64 = {
  name: 'base64',
  encode: function (r) {
    try {
      return btoa(r)
    } catch (t) {
      return btoaFallback(r)
    }
  },
  decode: function (r) {
    try {
      return atob(r)
    } catch (t) {
      return atobFallback(r)
    }
  },
}
