export class ControllerInteractionUtil {
  constructor(t) {
    this._map = t
    this._cachedPicks = new Map()
  }
  invalidate() {
    this._cachedPicks.clear()
  }
  queryShapes(t, a, c, e) {
    const s = `${t}-${a}-${c}-${e}`
    const i = this._cachedPicks.get(s)
    if (i) return i
    const r = this._map.controllerManager.getDrawCommand()
    const n = r
      ? r.findInteractingShapes(
          t,
          a,
          c,
          e,
          this._map.mapToViewTransformation,
          this._map.worldSizeSupport
        )
      : []
    this._cachedPicks.set(s, n)
    return n
  }
}
