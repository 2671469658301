import { Grid } from '../../model/tileset/Grid.js'
export class TileWindow {
  constructor() {
    this._buffer = []
    this._grid = new Grid()
    this._width = 0
    this._height = 0
  }
  get height() {
    return this._height
  }
  get width() {
    return this._width
  }
  get grid() {
    return this._grid
  }
  resetFor(t) {
    this._grid.setTo(t)
    this.clear()
    this._height = t.height
    this._width = t.width
    const i = this._height * this._width
    if (this._buffer.length < i) this._buffer = new Array(i)
  }
  clear() {
    const t = this._buffer
    for (let i = 0, e = t.length; i < e; i++) t[i] = null
  }
  get(t, i) {
    return this._buffer[i * this._width + t]
  }
  set(t, i, e) {
    if (t > -1 && i > -1 && t < this._width && i < this._height)
      this._buffer[i * this._width + t] = e
  }
  getTileRecord(t) {
    if (-1 == t.level || t.level != this._grid.level) return null
    else {
      const i = t.x - this._grid.x
      const e = t.y - this._grid.y
      if (-1 < e && e < this._height && -1 < i && i < this._width)
        return this._buffer[e * this._width + i]
      else return null
    }
  }
  storeTileRecord(t) {
    const i = t.coordinate
    if (i.level !== this._grid.level) return false
    const e = i.y - this._grid.y
    const h = i.x - this._grid.x
    this.set(h, e, t)
    return true
  }
}
