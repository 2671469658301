import { AdvancedClusterShapeProvider } from './AdvancedClusterShapeProvider.js'
import { NotImplementedError } from '../../../error/NotImplementedError.js'
import { squaredDistance2D } from '../../../util/Cartesian.js'
function getClosestPointIdempotentMode(e) {
  let [o, t] = e
  if (o.worldLocation.x < t.worldLocation.x) return t
  else if (o.worldLocation.x === t.worldLocation.x)
    if (o.worldLocation.y < t.worldLocation.y) return t
    else if (o.worldLocation.y === t.worldLocation.y)
      return o.worldLocation.z < t.worldLocation.z ? t : o
    else return o
  else return o
}
export class ClusteredPointClosestToCenterClusterShapeProvider extends AdvancedClusterShapeProvider {
  constructor() {
    super()
  }
  getAdvancedLocation(e) {
    const o = getClosestPoint(e)
    return o?.modelLocation ?? null
  }
  getShape(e) {
    throw new NotImplementedError(
      'Should not call this method, it needs the more powerful one.'
    )
  }
}
function getClosestPoint(e) {
  if (2 === e.length) return getClosestPointIdempotentMode(e)
  let o = 0
  let t = 0
  const r = e.length
  for (let n = 0; n < r; n++) {
    const r = e[n]
    o += r.viewLocation.x
    t += r.viewLocation.y
  }
  const n = o / r
  const l = t / r
  let i = null
  let s = Number.POSITIVE_INFINITY
  for (let o = 0; o < r; o++) {
    const t = e[o]
    const { x: r, y: d } = t.viewLocation
    const c = squaredDistance2D(r, d, n, l)
    if (c < s) {
      s = c
      i = t
    }
  }
  return i
}
