import { EventedSupport } from '../../util/EventedSupport.js'
import { HandleEventResult } from '../controller/HandleEventResult.js'
import { EditShapeStatus } from '../controller/EditShapeEvent.js'
import { ShapeType } from '../../shape/ShapeType.js'
export class EditHandle {
  _eventedSupport = new EventedSupport(['EditShape'])
  _emittedProgressEvent = false
  constructor() {}
  get eventedSupport() {
    return this._eventedSupport
  }
  onGestureEvent(e, t) {
    return HandleEventResult.EVENT_IGNORED
  }
  getCursor(e, t) {
    return null
  }
  onDraw(e, t) {}
  onDrawLabel(e, t) {}
  update() {}
  onCreateContextMenu(e, t, r, n) {}
  emitEditShapeEvent(e, t) {
    if (t === EditShapeStatus.IN_PROGRESS && !this._emittedProgressEvent)
      this._emittedProgressEvent = true
    if (t === EditShapeStatus.FINISHED) {
      if (!this._emittedProgressEvent) return
      this._emittedProgressEvent = false
    }
    const r = { status: t, shape: e }
    this._eventedSupport.emit('EditShape', r)
  }
  on(e, t) {
    return this._eventedSupport.on(e, t)
  }
}
export function interactsWithControllerShape(e, t, r, n, o, s) {
  const p = queryControllerDrawings(e, t, r, n, s)
  if (!o) return 0 !== p.length
  for (const e of p) if (e.equals(o)) return true
  if (ShapeType.contains(o.type, ShapeType.SHAPE_LIST)) {
    const e = o
    for (let t = 0; t < e.shapeCount; t++) {
      const r = e.getShape(t)
      for (const e of p) if (e.equals(r)) return true
    }
  }
  return false
}
export function queryControllerDrawings(e, t, r, n, o) {
  const s = undefined
  return e.controllerManager
    .getInteractionUtil()
    .queryShapes(t, r, n, o ?? false)
}
