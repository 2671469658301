import { LLHPoint } from '../shape/LLHPoint.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Transformation } from './Transformation.js'
import { TransformationType } from './TransformationType.js'
import { BoundsCalculationUtil } from './BoundsCalculationUtil.js'
export class GridGridTransformation extends Transformation {
  constructor(t, i, e) {
    super(t, i)
    if (t.referenceType !== ReferenceType.GRID)
      throw new ProgrammingError(
        'GridGridTransformation::setSourceReference - Expected grid reference.'
      )
    if (i.referenceType !== ReferenceType.GRID)
      throw new ProgrammingError(
        'GridGridTransformation::setDestinationReference - Expected grid reference.'
      )
    this._srcGeodeticDatum = t.geodeticDatum
    this._srcEllipsoid = t.geodeticDatum.ellipsoid
    this._srcProjection = t.projection
    this._srcUOM = t.unitOfMeasure
    this._dstGeodeticDatum = i.geodeticDatum
    this._dstEllipsoid = i.geodeticDatum.ellipsoid
    this._dstProjection = i.projection
    this._dstUOM = i.unitOfMeasure
    this._equalDatums = this._srcGeodeticDatum.equals(this._dstGeodeticDatum)
    this._equalHorizontalDatums = this._srcGeodeticDatum.equals2D(
      this._dstGeodeticDatum
    )
    this._equalProjections = this._srcProjection.equals(this._dstProjection)
    this._equalGridSystems =
      this._inputReference === this._outputReference ||
      !!this._inputReference?.equals(this._outputReference)
    this._tmpLLHPoint = new LLHPoint(null, [0, 0, 0])
    this._tmpXYPoint = new XYZPoint()
    this._tmpXYZPoint1 = new XYZPoint(null, [0, 0, 0])
    this._tmpXYZPoint2 = new XYZPoint(null, [0, 0, 0])
    this._bboxCalculationUtil = new BoundsCalculationUtil({
      modelReference: t,
      worldReference: i,
      transformation: this,
      TransformationFactory: e,
    })
  }
  get inputReference() {
    return null === super.inputReference ? null : super.inputReference
  }
  get outputReference() {
    return null === super.outputReference ? null : super.outputReference
  }
  _forward(t, i) {
    const e = this._inputReference
    const s = this._outputReference
    if (this._equalGridSystems) {
      i.x = t.x
      i.y = t.y
      i.z = t.z
    } else {
      e.grid2projectedSFCT(t, this._tmpXYPoint)
      let o = t.z || 0
      o *= this._srcUOM
      if (!this._equalDatums || !this._equalProjections) {
        this._tmpLLHPoint.x = 0
        this._tmpLLHPoint.y = 0
        this._tmpLLHPoint.z = o
        this._srcProjection.cartesian2geodeticOnEllipsoidSFCT(
          this._tmpXYPoint,
          this._srcEllipsoid,
          this._tmpLLHPoint
        )
        this._tmpLLHPoint.z += this._srcGeodeticDatum.getHeight(
          this._tmpLLHPoint.x,
          this._tmpLLHPoint.x
        )
        if (!this._equalDatums) {
          if (!this._equalHorizontalDatums) {
            this._srcEllipsoid.geod2geocSFCT(
              this._tmpLLHPoint,
              this._tmpXYZPoint1
            )
            this._srcGeodeticDatum.datum2refGeocSFCT(
              this._tmpXYZPoint1,
              this._tmpXYZPoint2
            )
            this._dstGeodeticDatum.ref2datumGeocSFCT(
              this._tmpXYZPoint2,
              this._tmpXYZPoint1
            )
            this._dstEllipsoid.geoc2geodSFCT(
              this._tmpXYZPoint1,
              this._tmpLLHPoint
            )
          }
          o =
            this._tmpLLHPoint.z -
            this._dstGeodeticDatum.getHeight(
              this._tmpLLHPoint.x,
              this._tmpLLHPoint.y
            )
        }
        this._dstProjection.geodetic2cartesianOnEllipsoidSFCT(
          this._tmpLLHPoint,
          this._dstEllipsoid,
          this._tmpXYPoint
        )
      }
      s.projected2gridSFCT(this._tmpXYPoint, i)
      i.z = o / this._dstUOM
    }
    return i
  }
  _inverse(t, i) {
    const e = this._inputReference
    const s = this._outputReference
    if (this._equalGridSystems) {
      i.x = t.x
      i.y = t.y
      i.z = t.z
    } else {
      s.grid2projectedSFCT(t, this._tmpXYPoint)
      let o = t.z || 0
      o *= this._dstUOM
      if (!this._equalDatums || !this._equalProjections) {
        this._tmpLLHPoint.x = 0
        this._tmpLLHPoint.y = 0
        this._tmpLLHPoint.z = o
        this._dstProjection.cartesian2geodeticOnEllipsoidSFCT(
          this._tmpXYPoint,
          this._dstEllipsoid,
          this._tmpLLHPoint
        )
        this._tmpLLHPoint.z += this._dstGeodeticDatum.getHeight(
          this._tmpLLHPoint.x,
          this._tmpLLHPoint.y
        )
        if (!this._equalDatums) {
          if (!this._equalHorizontalDatums) {
            this._dstEllipsoid.geod2geocSFCT(
              this._tmpLLHPoint,
              this._tmpXYZPoint1
            )
            this._dstGeodeticDatum.datum2refGeocSFCT(
              this._tmpXYZPoint1,
              this._tmpXYZPoint2
            )
            this._srcGeodeticDatum.ref2datumGeocSFCT(
              this._tmpXYZPoint2,
              this._tmpXYZPoint1
            )
            this._srcEllipsoid.geoc2geodSFCT(
              this._tmpXYZPoint1,
              this._tmpLLHPoint
            )
          }
          o =
            this._tmpLLHPoint.z -
            this._srcGeodeticDatum.getHeight(
              this._tmpLLHPoint.x,
              this._tmpLLHPoint.y
            )
        }
        this._srcProjection.geodetic2cartesianOnEllipsoidSFCT(
          this._tmpLLHPoint,
          this._srcEllipsoid,
          this._tmpXYPoint
        )
      }
      e.projected2gridSFCT(this._tmpXYPoint, i)
      i.z = o / this._srcUOM
    }
    return i
  }
  _forwardBoundsCoords(t, i) {
    this._bboxCalculationUtil.forwardBounds2dSFCT(t, i)
  }
  _inverseBoundsCoords(t, i) {
    this._bboxCalculationUtil.inverseBounds2dSFCT(t, i)
  }
  _getType() {
    return TransformationType.TYPE_GENERAL
  }
}
