import { kmlValueMapFactory } from './KMLValues.js'
export let KMLListItemType = (function (e) {
  e[(e['CHECK'] = 0)] = 'CHECK'
  e[(e['RADIO_FOLDER'] = 1)] = 'RADIO_FOLDER'
  e[(e['CHECK_OFF_ONLY'] = 2)] = 'CHECK_OFF_ONLY'
  e[(e['CHECK_HIDE_CHILDREN'] = 3)] = 'CHECK_HIDE_CHILDREN'
  return e
})({})
const LIST_ITEM_TYPE_MAP = {
  check: KMLListItemType.CHECK,
  checkHideChildren: KMLListItemType.CHECK_HIDE_CHILDREN,
  checkOffOnly: KMLListItemType.CHECK_OFF_ONLY,
  radioFolder: KMLListItemType.RADIO_FOLDER,
}
export const getListItemTypeValue = kmlValueMapFactory(
  KMLListItemType.CHECK,
  LIST_ITEM_TYPE_MAP
)
