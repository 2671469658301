import { PhotonWorkerPoolFactory } from '../../gen/inlined-webworker/PhotonWorkerPoolFactory.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { GLTFDecoder } from '../../geometry/mesh/GLTFDecoder.js'
import { RasterImageModel } from '../../model/image/RasterImageModel.js'
import { RasterDataType } from '../../model/tileset/RasterDataType.js'
import { RasterTileSetModel } from '../../model/tileset/RasterTileSetModel.js'
import { PhotonReferenceProvider } from '../../reference/photon/PhotonReferenceProvider.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { WorldViewTransformation2D } from '../../transformation/WorldViewTransformation2D.js'
import { createPhotonColorFromString } from '../../util/Color.js'
import { Log } from '../../util/Log.js'
import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import { whenInternal } from '../../util/PromiseUtil.js'
import { OrthographicCamera } from '../camera/OrthographicCamera.js'
import { PerspectiveCamera } from '../camera/PerspectiveCamera.js'
import { FeatureLayer } from '../feature/FeatureLayer.js'
import { FeatureLayerRenderer } from '../feature/FeatureLayerRenderer.js'
import { PhotonFeatureLayerRenderer } from '../feature/photon/PhotonFeatureLayerRenderer.js'
import { PhotonShapeDiscretizer } from '../feature/photon/PhotonShapeDiscretizer.js'
import { GridLayer } from '../grid/GridLayer.js'
import { LightScatteringAtmosphere } from '../LightScatteringAtmosphere.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { PhotonCanvasImageDecoder } from '../photon/image/PhotonCanvasImageDecoder.js'
import { PhotonGeometryDecoder } from '../photon/image/PhotonGeometryDecoder.js'
import { PhotonImageDecoder } from '../photon/image/PhotonImageDecoder.js'
import { PhotonStarfieldImage } from '../photon/image/PhotonStarfieldImage.js'
import { PhotonTextureDecoder } from '../photon/image/PhotonTextureDecoder.js'
import { PhotonWorldViewTransformation } from '../photon/PhotonWorldViewTransformation.js'
import { PhotonCameraCallback } from '../PhotonCameraCallback.js'
import { ResourceOverlay } from '../ResourceOverlay.js'
import { PhotonTerrainPainter } from '../tileset/PhotonTerrainPainter.js'
import { PhotonTileSetLayerRenderer } from '../tileset/PhotonTileSetLayerRenderer.js'
import {
  AbstractViewPaintingStrategy,
  getParameterByName,
  ReadyResult,
} from './AbstractViewPaintingStrategy.js'
import { AddSubTreeVisitor } from './AddSubTreeVisitor.js'
import { CanvasDrawingSurface } from './CanvasDrawingSurface.js'
import { FrameScheduler } from './FrameScheduler.js'
import { PhotonControllerRenderer } from './PhotonControllerRenderer.js'
import { PhotonSurface } from './PhotonSurface.js'
import { RemoveSubTreeVisitor } from './RemoveSubTreeVisitor.js'
import { DEFAULT_LAYER_STYLE, INVALID_LAYER_CLIP } from '../LayerStyle.js'
import { EnvironmentMapImageryType } from '../EnvironmentMapEffect.js'
import { CubeMapFace } from '../../model/tileset/CubeMapFace.js'
import { parse } from '../../util/JSON.js'
import { Copyright } from '../../util/Copyright.js'
import { isArray } from '../../util/Lang.js'
import { decodePaintContext } from '../photon/PaintContextUtil.js'
import { TileSet3DLayer } from '../tileset/TileSet3DLayer.js'
import { isQuadTreeRasterTileSetModel } from '../../model/tileset/RasterTileSetModelUtil.js'
import { LayerTreeNode } from '../LayerTreeNode.js'
import { LayerTreeVisitor } from '../LayerTreeVisitor.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import { AnimationManager } from '../animation/AnimationManager.js'
performance.mark('lcd_boot')
const BODY_SURFACE_Z_INDEX = 0
function accumulateInArray(e, t) {
  e.push(t)
  return e
}
function isRaster(e) {
  return e instanceof RasterTileSetModel || e instanceof RasterImageModel
}
function isVectorLayer(e) {
  return e instanceof FeatureLayer || e instanceof GridLayer
}
function isElevation(e) {
  return e && e.dataType && e.dataType === RasterDataType.ELEVATION
}
function isTinElevation(e) {
  return e && e.dataType && e.dataType === RasterDataType.TIN_ELEVATION
}
export class PhotonViewPaintingStrategy extends AbstractViewPaintingStrategy {
  _terrainPainter
  _isContextLost
  _paintSupport
  _eventedSupport
  get terrainPainter() {
    return this._terrainPainter
  }
  constructor(e) {
    super(e)
    this._isContextLost = false
    this._eventedSupport = new EventedSupport(['WebGLContextChanged'])
    this._objectReleaseTracker = new ObjectReleaseTracker()
    this.v2w = null
    this._photonReferenceProvider = this._objectReleaseTracker.track(
      new PhotonReferenceProvider(Photon)
    )
    if (!this._photonReferenceProvider.isSupported(e.reference)) {
      this._objectReleaseTracker.untrack(this._photonReferenceProvider)
      this._photonReferenceProvider = null
      throw Error(
        `LuciadRIA WebGLMap currently does not support world reference ${e.reference.identifier}`
      )
    }
    this._layerlisteners = []
    this._createLayerTreeVisitors()
    this._createBodySurface()
    this._listenToContextLost()
    this._paintSupport = this._objectReleaseTracker.track(
      new Photon.PaintSupport()
    )
    this._paintSupport.updateLayers = (e) => {
      if (this._isContextLost) return true
      const t = this._calculateCameraPositionalVelocity()
      let r = this._updateLayers(this._linearLayers, t)
      r = this.terrainPainter.update(this.map) && r
      e.release()
      return r
    }
    this._paintSupport.paintLayers = (e, t, r, i, o, a, n) => {
      const {
        depthPass: s,
        depthExcludedLayers: h,
        depthIncludeController: l,
        panoramicLayer: c,
      } = decodePaintContext(o, a)
      const p = (e, t) =>
        e !== Photon.PaintDraping.All &&
        t instanceof TileSet3DLayer &&
        t.isDrapeTarget
      const d = this._paintLayers(this._linearLayers, {
        selected: !!t,
        paintOpacity: r,
        paintDraping: i,
        paintOutput: o,
        allowClipping: n,
        depthIncludeController: l,
        depthPass: s,
        depthExcludedLayers: h,
        panoramicLayer: c,
        shouldNotPaint: p,
      })
      e.release()
      return d
    }
    this._paintSupport.paintDrapeTargets = (e, t, r, i, o) => {
      const a = (e, t) => !(t instanceof TileSet3DLayer && t.isDrapeTarget)
      const n = Photon.PaintDraping.NonDraping
      const {
        depthPass: s,
        depthExcludedLayers: h,
        depthIncludeController: l,
        panoramicLayer: c,
      } = decodePaintContext(r, i)
      const p = this._paintLayers(this._linearLayers, {
        selected: false,
        paintOpacity: t,
        paintDraping: n,
        paintOutput: r,
        allowClipping: o,
        depthIncludeController: l,
        depthPass: s,
        depthExcludedLayers: h,
        panoramicLayer: c,
        shouldNotPaint: a,
      })
      e.release()
      return p
    }
    this._paintSupport.paintTerrain = (e, t, r, i, o) => {
      let a = DEFAULT_LAYER_STYLE
      if (o)
        for (const [e, t] of this._layerStyleMapping.entries())
          if (e.model === this.terrainPainter.imageModel) a = t
      if (a && a.flicker) {
        const e = undefined
        const t = undefined
        const r = {
          x: 0,
          y: 0,
          z: 0,
          width: this.map.viewSize[0],
          height: this.map.viewSize[1],
          depth: 0,
          valid: 1,
        }
        const i = {
          x: -1,
          y: -1,
          z: 0,
          width: 0,
          height: 0,
          depth: 0,
          valid: 1,
        }
        this._photonView.setClip(r, i)
        a.clip = i
      }
      const n = this.terrainPainter.paint(t, r, i, a.clip)
      if (a && a.flicker)
        this._photonView.setClip(INVALID_LAYER_CLIP, INVALID_LAYER_CLIP)
      e.release()
      return n
    }
    this._terrainWWPoolHandle = this._objectReleaseTracker.track(
      PhotonWorkerPoolFactory.getTerrainWorkerPoolHandle()
    )
    this._webWorkerPool = this._terrainWWPoolHandle.webWorkerPool
    this._imageDecoderWorker = this._objectReleaseTracker.track(
      new PhotonImageDecoder(Photon, this._webWorkerPool)
    )
    this._imageDecoderCanvas = this._objectReleaseTracker.track(
      new PhotonCanvasImageDecoder(Photon)
    )
    this._geometryDecoder = this._objectReleaseTracker.track(
      new PhotonGeometryDecoder(
        Photon,
        this._webWorkerPool,
        this._photonReferenceProvider
      )
    )
    this._gltfWWPoolHandle = this._objectReleaseTracker.track(
      PhotonWorkerPoolFactory.getGLTFWorkerPoolHandle()
    )
    this._gltfDecoder = new GLTFDecoder(this._gltfWWPoolHandle.webWorkerPool)
    this._shapeDiscretizer = this._objectReleaseTracker.track(
      new PhotonShapeDiscretizer(
        e.reference.referenceType === ReferenceType.GEOCENTRIC,
        this._photonReferenceProvider
      )
    )
    this._textureDecoder = this._objectReleaseTracker.track(
      new PhotonTextureDecoder(Photon, this._bodySurface)
    )
    this._photonGraphics = this._bodySurface.getGraphics()
    const t = this._photonReferenceProvider.getReference(e.reference)
    this._photonView = this._objectReleaseTracker.track(
      Photon.View.create(this._photonGraphics, t, e.is3D())
    )
    t.release()
    this.updateCamera(this.map.camera, true)
    this._photonCameraUpdateContext = {
      previousTimeStamp: performance.now(),
      previousCamera: this.map.camera,
    }
    this._photonView.setPaintSupport(this._paintSupport)
    this._lastPaintComplete = true
    this._environmentMapLoadingDone = true
    this._linearLayers = []
    this._layerStyleMapping = new Map()
    this._terrainPainter = this._objectReleaseTracker.track(
      new PhotonTerrainPainter(
        this.map.reference,
        this._photonView,
        this._photonGraphics,
        this._textureDecoder,
        this._imageDecoderWorker,
        this._geometryDecoder,
        this._photonReferenceProvider,
        true,
        null
      )
    )
    this.techContext = {
      type: 'Photon',
      photon: Photon,
      photonInstance: Photon,
      gltfDecoder: this._gltfDecoder,
      imageDecoder: this._imageDecoderCanvas,
      imageDecoderWorker: this._imageDecoderWorker,
      geometryDecoder: this._geometryDecoder,
      textureDecoder: this._textureDecoder,
      webWorkerPool: this._webWorkerPool,
      photonView: this._photonView,
      photonGraphics: this._bodySurface.getGraphics(),
      glContext: this._bodySurface.getGLContext(),
      referenceProvider: this._photonReferenceProvider,
      shapeDiscretizer: this._shapeDiscretizer,
      terrainPainter: this.terrainPainter,
      invalidator: this,
      scheduler: this,
      featureLayerRenderer: PhotonFeatureLayerRenderer,
      featureLayerBorderRenderer: FeatureLayerRenderer,
      rasterTileSetLayerRenderer: PhotonTileSetLayerRenderer,
    }
    this.createLabelHtmlDrawingSurface(1)
    this.createGlowSurface(2)
    this.createAxisRendererContext(3)
    this.createBottomAxisLabelHtmlDrawingSurface(4)
    this.createLeftAxisLabelHtmlDrawingSurface(5)
    if (this.is3D()) this.setStarfieldImage()
    this._controllerRenderer = this._objectReleaseTracker.track(
      new PhotonControllerRenderer(
        this.map.controllerManager,
        this.labelManager,
        this.map,
        this.techContext
      )
    )
    this._createRequestFrameCallback()
    this.resize(this.map.totalSize[0], this.map.totalSize[1], this.map.border)
    this._trackResources()
    this._eventedSupport.emit('WebGLContextChanged')
  }
  destroy() {
    this._layerlisteners.forEach((e) => {
      let { visibilityChangedHandle: t } = e
      return t.remove()
    })
    this._layerlisteners = []
    this.layerHandles?.forEach((e) => e.remove())
    this.layerHandles = []
    this._objectReleaseTracker.release()
    super.destroy()
  }
  setMaxMemoryUsageHint(e) {
    this._photonView.setTotalMaxMemoryUsageHintJS(e)
  }
  getMaxMemoryUsageHint() {
    return this._photonView.getTotalMaxMemoryUsageHintJS()
  }
  enableLayerClipping(e, t, r, i) {
    const o = {
      x: e.x,
      y: e.y,
      z: 0,
      width: e.width,
      height: e.height,
      depth: 0,
      valid: 1,
    }
    const a = {
      x: r.x,
      y: r.y,
      z: 0,
      width: r.width,
      height: r.height,
      depth: 0,
      valid: 1,
    }
    this._photonView.setClip(o, a)
    this._layerStyleMapping.clear()
    for (const e of t) this._layerStyleMapping.set(e, { clip: o })
    for (const e of i) this._layerStyleMapping.set(e, { clip: a })
  }
  disableLayerClipping() {
    this._layerStyleMapping.clear()
    this._photonView.setClip(INVALID_LAYER_CLIP, INVALID_LAYER_CLIP)
  }
  enableLayerFlickering(e) {
    this._layerStyleMapping.clear()
    for (const t of e) this._layerStyleMapping.set(t, { flicker: true })
  }
  isLayerClippingSupported(e) {
    let t = true
    const r = !!this.techContext.glContext.getExtension(
      'EXT_color_buffer_half_float'
    )
    const i =
      !!this.techContext.glContext.getExtension('EXT_color_buffer_float') &&
      !!this.techContext.glContext.getExtension('OES_texture_float_linear') &&
      !!this.techContext.glContext.getExtension('EXT_float_blend')
    if (!(r || i)) {
      t = false
      e.push(
        'This browser does not support float textures. It is required for swiping on a WebGLMap.'
      )
    }
    return t
  }
  setWorldViewTransformation(e) {
    this.v2w = e
    if (this.is3D())
      e.initialize(Photon, this._photonView, this._photonReferenceProvider)
  }
  createWorldViewTransformation() {
    if (isUndefined(this.v2w))
      if (this.is3D()) this.v2w = new PhotonWorldViewTransformation(this.map)
      else
        this.v2w = new WorldViewTransformation2D(
          this.map.reference,
          this.map.camera
        )
    return this.v2w
  }
  updateCamera(e, t) {
    if (t) {
      this._photonCamera?.setCameraCallback(null)
      this._photonView.enableOrthographicCamera(e instanceof OrthographicCamera)
      this._photonCamera = this._objectReleaseTracker.retrack(
        this._photonCamera,
        e instanceof PerspectiveCamera
          ? this._photonView.perspectiveCamera
          : this._photonView.orthographicCamera
      )
      this._photonCameraCallback = this._objectReleaseTracker.retrack(
        this._photonCameraCallback,
        new PhotonCameraCallback(this.map, this._photonView)
      )
      this._photonCamera.setCameraCallback(this._photonCameraCallback)
    }
    this._photonCameraCallback.updateCamera(e, this._photonCamera)
  }
  createGraphicsEffectsListener() {
    const e = this
    let t = false
    function r(r) {
      if (t !== r && !e.is3D() && e.terrainPainter) e.terrainPainter.reset()
      t = r
    }
    return {
      useNoLight: function () {
        e._photonView.useNoLight()
        r(false)
        e.invalidate()
      },
      useHeadLight: function (t, i, o, a) {
        if (!e.is3D()) return this.useNoLight()
        e._photonView.useHeadLight(
          createPhotonColorFromString(t),
          createPhotonColorFromString(i),
          o,
          a,
          false
        )
        r(true)
        e.invalidate()
      },
      useTimeLight: function (t, i, o, a) {
        e._photonView.useTimeLight(
          createPhotonColorFromString(t),
          createPhotonColorFromString(i),
          a,
          o.getTime() / 1e3
        )
        r(true)
        e.invalidate()
      },
      atmosphereChanged: function (t, r) {
        e._setPaintAtmosphereInternal(t, r)
        e.invalidate()
      },
      starfieldChanged: function (t) {
        e._photonView.setPaintStarfield(t)
        e.invalidate()
      },
      useSSAO: function (t, r, i) {
        e._photonView.useSSAO(t, r, i)
        e.invalidate()
      },
      useDOF: function (t, r, i, o, a) {
        e._photonView.useDepthOfField(t, r, i, o, a)
        e.invalidate()
      },
      useEDL: function (t, r, i, o) {
        e._photonView.useEDL(
          t,
          r || 2,
          i || 1,
          createPhotonColorFromString(o || 'rgba(0, 0, 0, 1.0)')
        )
        e.invalidate()
      },
      useFXAA: function (t) {
        e._photonView.useFXAA(t)
        e.invalidate()
      },
      useBloom: function (t, r, i, o) {
        e._photonView.useBloom(t, r, i, o ?? -1)
        e.invalidate()
      },
      environmentMapChanged: function (t) {
        if (!t) {
          e._photonView.setPaintEnvironmentMap(false)
          return
        }
        const r = (t) => {
          if (!t) return Promise.resolve()
          const r = e._photonGraphics.getMaxTextureSize()
          const i = (i) => {
            let o
            if (i instanceof HTMLCanvasElement)
              o = e._imageDecoderCanvas.decodeCanvas(i, false, null, null, r, r)
            else if (i instanceof HTMLImageElement)
              o = e._imageDecoderCanvas.decodeImage(i, false, null, null, r, r)
            else {
              const a = i.toLowerCase()
              if (
                a.endsWith('.jpg') ||
                a.endsWith('.jpeg') ||
                a.endsWith('.png') ||
                a.endsWith('.webp') ||
                a.endsWith('.svg')
              )
                o = e._imageDecoderCanvas.decodeUrl(
                  i,
                  false,
                  false,
                  t.imagery.credentials,
                  t.imagery.requestHeaders,
                  null,
                  null,
                  r,
                  r
                )
              else
                o = e._imageDecoderWorker.decodeUrl(
                  i,
                  false,
                  t.imagery.credentials,
                  t.imagery.requestHeaders
                )
            }
            return Promise.resolve(o)
          }
          e._environmentMapLoadingDone = false
          if (t.imagery.type === EnvironmentMapImageryType.EQUIRECTANGULAR)
            return new Promise((e, r) => {
              t.imagery.getImage(
                (t) => e(t),
                (e) => r(e)
              )
            })
              .then(i)
              .then((r) => {
                const i = Photon.EnvironmentMap.createSingleImage(
                  e._photonGraphics,
                  r,
                  t.orientation,
                  0
                )
                r.release()
                return i
              })
          else {
            const r = (e) =>
              new Promise((r, i) => {
                t.imagery.getImage(
                  e,
                  (e, t) => r(t),
                  (e, t) => i(t)
                )
              }).then(i)
            const o = r(CubeMapFace.FRONT)
            const a = r(CubeMapFace.BACK)
            const n = r(CubeMapFace.LEFT)
            const s = r(CubeMapFace.RIGHT)
            const h = r(CubeMapFace.BOTTOM)
            const l = r(CubeMapFace.TOP)
            return Promise.all([o, a, n, s, h, l]).then((r) => {
              const i = Photon.EnvironmentMap.createCubeMap(
                e._photonGraphics,
                r[0],
                r[1],
                r[2],
                r[3],
                r[4],
                r[5],
                t.orientation,
                0
              )
              r.forEach((e) => e?.release())
              return i
            })
          }
        }
        const i = r(t.skybox)
        let o
        if (t.skybox && t.skybox.equals(t.reflectionMap)) o = i
        else o = r(t.reflectionMap)
        Promise.all([i, o])
          .then((t) => {
            try {
              e._photonView.setEnvironmentMap(e._photonGraphics, t[0], t[1])
              e._photonView.setPaintEnvironmentMap(!!t[0])
            } finally {
              e._environmentMapLoadingDone = true
              t[0]?.release()
              t[1]?.release()
            }
            e.invalidate()
          })
          .catch((t) => {
            e._environmentMapLoadingDone = true
            console.log(t || 'Error while loading environment map.')
          })
      },
    }
  }
  _createLayerTreeVisitors() {
    this._addSubTreeVisitor = new AddSubTreeVisitor(
      this._listenToLayerEvents.bind(this),
      this._addLayerNode.bind(this)
    )
    this._removeSubTreeVisitor = new RemoveSubTreeVisitor(
      this._stopListeningToLayerEvents.bind(this),
      this._removeLayerNode.bind(this)
    )
  }
  registerLayerTree(e) {
    this.layerHandles = [
      e.on('NodeAdded', (e) => {
        this._resetTerrainPainterLayers(e.node)
        this.addLayerTreeNode(e.node)
      }),
      e.on('NodeRemoved', (e) => {
        this._resetTerrainPainterLayers()
        this.removeLayerTreeNode(e.node)
      }),
      e.on('NodeMoved', (e) => {
        this._resetTerrainPainterLayers()
        this.removeLayerTreeNode(e.node)
        this.addLayerTreeNode(e.node)
      }),
    ]
    e.accept(this._addSubTreeVisitor)
    this._resetTerrainPainterLayers()
  }
  setStarfieldImage(e) {
    this._starfieldAbortController?.abort()
    this._starfieldAbortController = new AbortController()
    if (!e) e = PhotonStarfieldImage.createDefaultStarfieldImageURL()
    return whenInternal(
      this._imageDecoderWorker.decodeUrl(
        e,
        false,
        false,
        null,
        this._starfieldAbortController.signal
      ),
      (e) => {
        if (!this._objectReleaseTracker.isReleased() && this._photonView) {
          this._photonView.setStarfieldImage(e)
          this._starfieldAbortController = null
          this.invalidate()
        }
        e.release()
      },
      (e) => {
        if ('AbortError' !== e.name) throw e
      }
    )
  }
  _createBodySurface() {
    this._bodySurface = this._objectReleaseTracker.track(
      new PhotonSurface(this.map._getContainerNode(), {
        width: this.map.viewSize[0],
        height: this.map.viewSize[1],
      })
    )
    this._bodySurface.setZIndex(BODY_SURFACE_Z_INDEX)
  }
  createGlowSurface(e) {
    this._glowSurface = this._objectReleaseTracker.track(
      new CanvasDrawingSurface(this.map._getContainerNode(), {
        width: this.map.viewSize[0],
        height: this.map.viewSize[1],
        border: this.map.border,
        disallowInteraction: true,
      })
    )
    this._glowSurface.setZIndex(e)
  }
  _createRequestFrameCallback() {
    const e = this
    this._requestFrame = FrameScheduler.createRequestFrame({
      paint: function (t) {
        if (e._objectReleaseTracker.isReleased()) return
        Photon.asyncGetPixelPackBufferSubData.setCurrentFrameId(t)
        e.paint()
        Photon.asyncGetPixelPackBufferSubData.setCurrentFrameId(0)
      },
    })
  }
  _listenToContextLost() {
    const e = this._bodySurface.getGLContext()
    const t = () => {
      if (!this._isContextLost) {
        this._isContextLost = true
        AnimationManager.removeAnimation(this._map.cameraAnimationKey)
        this.htmlLabelSurface.valid = false
        this.invalidate()
      }
    }
    e.canvas.addEventListener('webglcontextlost', t)
    this._contextLostListener = this._objectReleaseTracker.retrack(
      this._contextLostListener,
      {
        release: () => {
          e.canvas.removeEventListener('webglcontextlost', t)
        },
      }
    )
  }
  get webGLContext() {
    return this._bodySurface?.getGLContext() ?? null
  }
  get webGLContextLost() {
    return this._isContextLost
  }
  reboot() {
    if (this._isContextLost) {
      const e = {
        visitLayer: (e) => {
          e._removedFromMap(this.map)
          return LayerTreeVisitor.ReturnValue.CONTINUE
        },
        visitLayerGroup: (t) => {
          t.visitChildren(e, LayerTreeNode.VisitOrder.BOTTOM_UP)
          return LayerTreeVisitor.ReturnValue.CONTINUE
        },
      }
      this.map.layerTree.visitChildren(e, LayerTreeNode.VisitOrder.BOTTOM_UP)
      this._terrainPainter = this._objectReleaseTracker.untrack(
        this._terrainPainter
      )
      this._controllerRenderer = this._objectReleaseTracker.untrack(
        this._controllerRenderer
      )
      this._textureDecoder = this._objectReleaseTracker.untrack(
        this._textureDecoder
      )
      this._photonView = this._objectReleaseTracker.untrack(this._photonView)
      this._bodySurface = this._objectReleaseTracker.retrack(
        this._bodySurface,
        new PhotonSurface(this.map._getContainerNode(), {
          width: this.map.viewSize[0],
          height: this.map.viewSize[1],
        })
      )
      this._bodySurface.setZIndex(BODY_SURFACE_Z_INDEX)
      this._listenToContextLost()
      this._textureDecoder = this._objectReleaseTracker.track(
        new PhotonTextureDecoder(Photon, this._bodySurface)
      )
      this._photonGraphics = this._bodySurface.getGraphics()
      const t = this._photonReferenceProvider.getReference(this.map.reference)
      this._photonView = this._objectReleaseTracker.track(
        Photon.View.create(this._photonGraphics, t, this.map.is3D())
      )
      t.release()
      this.updateCamera(this.map.camera, true)
      this._photonView.setAutoAdjustCamera(this.map.adjustDepthRange)
      if (this._photonView.is3D()) {
        const e = this.map.mapNavigator.constraints.above
        const t = !!(e?.terrain || e?.mesh)
        this._photonView.setAboveGroundConstraint(t)
        this._photonView.setAboveGroundConstraintOptions(
          e.terrain,
          e.mesh,
          e.minAltitude
        )
      }
      if (this.is3D())
        this.v2w.initialize(
          Photon,
          this._photonView,
          this._photonReferenceProvider
        )
      this._photonView.setPaintSupport(this._paintSupport)
      this._photonView.setTotalMaxMemoryUsageHintJS(
        this.getMaxMemoryUsageHint()
      )
      this.map.effects.listener = this.createGraphicsEffectsListener()
      this._terrainPainter = this._objectReleaseTracker.track(
        new PhotonTerrainPainter(
          this.map.reference,
          this._photonView,
          this._photonGraphics,
          this._textureDecoder,
          this._imageDecoderWorker,
          this._geometryDecoder,
          this._photonReferenceProvider,
          true,
          null
        )
      )
      this._terrainPainter.globeColor = createPhotonColorFromString(
        this._map.globeColor
      )
      this._terrainPainter.reset()
      this.techContext = {
        type: 'Photon',
        photon: Photon,
        photonInstance: Photon,
        gltfDecoder: this._gltfDecoder,
        imageDecoder: this._imageDecoderCanvas,
        imageDecoderWorker: this._imageDecoderWorker,
        geometryDecoder: this._geometryDecoder,
        textureDecoder: this._textureDecoder,
        webWorkerPool: this._webWorkerPool,
        photonView: this._photonView,
        photonGraphics: this._bodySurface.getGraphics(),
        glContext: this._bodySurface.getGLContext(),
        referenceProvider: this._photonReferenceProvider,
        shapeDiscretizer: this._shapeDiscretizer,
        terrainPainter: this.terrainPainter,
        invalidator: this,
        scheduler: this,
        featureLayerRenderer: PhotonFeatureLayerRenderer,
        featureLayerBorderRenderer: FeatureLayerRenderer,
        rasterTileSetLayerRenderer: PhotonTileSetLayerRenderer,
      }
      if (this.is3D()) this.setStarfieldImage()
      this._controllerRenderer = this._objectReleaseTracker.track(
        new PhotonControllerRenderer(
          this.map.controllerManager,
          this.labelManager,
          this.map,
          this.techContext
        )
      )
      this.resize(this.map.totalSize[0], this.map.totalSize[1], this.map.border)
      const r = {
        visitLayer: (e) => {
          e._addedToMap(this.map)
          return LayerTreeVisitor.ReturnValue.CONTINUE
        },
        visitLayerGroup: (e) => {
          e.visitChildren(r, LayerTreeNode.VisitOrder.BOTTOM_UP)
          return LayerTreeVisitor.ReturnValue.CONTINUE
        },
      }
      this.map.layerTree.visitChildren(r, LayerTreeNode.VisitOrder.BOTTOM_UP)
      this._isContextLost = false
      this._eventedSupport.emit('WebGLContextChanged')
    }
  }
  on(e, t, r) {
    return this._eventedSupport.on(e, t, r)
  }
  _trackResources() {
    const e = getParameterByName('showperf')
    if (null !== e && !e.startsWith('fps') && !e.startsWith('true')) {
      let t = e
      this._resourceOverlay = new ResourceOverlay(this.map._getInnerNode(), t)
      this._resourceOverlay.registerSource(() =>
        parse(Photon.StatisticsTracker.getStatisticsAsJSON(t))
      )
      const [r] = performance.getEntriesByType('navigation')
      performance.measure('lcd_dom', { start: r.startTime, end: r.domComplete })
      Photon.whenReady().then(() => {
        performance.measure('lcd_photon', {
          start: 'lcd_boot',
          end: performance.now(),
        })
      })
      this.map.layerTree.whenReady().then(() => {
        performance.measure('lcd_map', {
          start: r.startTime,
          end: performance.now(),
        })
        this.invalidate()
      })
      let i = () => {
        let e = this._terrainWWPoolHandle.webWorkerPool.getStatus()
        let t = this._gltfWWPoolHandle.webWorkerPool.getStatus()
        if (e.workers.initializing + t.workers.initializing === 0)
          performance.measure('lcd_action', {
            start: r.startTime,
            end: performance.now(),
          })
        else this.schedule(i, true)
        this.invalidate()
      }
      this.schedule(i, true)
      this._resourceOverlay.registerSource(() => {
        function e(e) {
          return Math.round(
            performance
              .getEntriesByName(e)
              .map((e) => e.duration)
              .reduce((e, t, r, i) => e + t, 0)
          )
        }
        let t = []
        t['luciad.timing.dom'] = e('lcd_dom')
        t['luciad.timing.photon'] = e('lcd_photon')
        t['luciad.timing.action'] = e('lcd_action')
        t['luciad.timing.map'] = e('lcd_map')
        return t
      })
      this._resourceOverlay.registerSource(() => {
        let e = []
        let t = this._terrainWWPoolHandle.webWorkerPool.getStatus()
        e['luciad.workers[base].idle'] = t.workers.total - t.workers.busy
        e['luciad.workers[base].initializing'] = t.workers.initializing
        e['luciad.workers[base].busy'] = t.workers.busy
        e['luciad.workers[base].tasks.running'] = t.tasks.running
        e['luciad.workers[base].tasks.pending'] = t.tasks.pending
        let r = this._gltfWWPoolHandle.webWorkerPool.getStatus()
        e['luciad.workers[gltf].idle'] = r.workers.total - r.workers.busy
        e['luciad.workers[gltf].initializing'] = r.workers.initializing
        e['luciad.workers[gltf].busy'] = r.workers.busy
        e['luciad.workers[gltf].tasks.running'] = r.tasks.running
        e['luciad.workers[gltf].tasks.pending'] = r.tasks.pending
        return e
      })
      this._resourceOverlay.registerSource(() => {
        let e = []
        e['luciad.ria.version'] = Copyright.VERSION
        return e
      })
      this._resourceOverlay.registerSource(() => {
        let e = []
        if (
          performance &&
          performance.memory &&
          performance.memory.usedJSHeapSize
        ) {
          e['luciad.browser.memory.used.size'] =
            performance.memory.usedJSHeapSize
          e['luciad.browser.memory.allocated.size'] =
            performance.memory.totalJSHeapSize
        }
        if (Photon.JS_PROXY_OBJECTS) {
          e['luciad.photon.proxyObjects.count'] = Photon.JS_PROXY_OBJECTS.size
          Photon.JS_PROXY_OBJECTS.forEach(function (t, r) {
            var i = 'luciad.photon.proxyObjects.' + t.constructor.name
            e[i] = (e[i] || 0) + 1
          })
        }
        if ('function' === typeof Photon.getMemoryUsage) {
          var t = Photon.getMemoryUsage()
          e['luciad.photon.memory.dynamic.size'] = t.dynamicBytes
          e['luciad.photon.memory.used.size'] = t.usedBytes
          e['luciad.photon.memory.allocated.size'] = t.totalBytes
        }
        return e
      })
      function o(e, r) {
        let i = {}
        setInterval(() => {
          e.enqueueOnAllWorkers({
            command: 'getStatistics',
            data: { filter: t },
          }).then((e) => {
            for (var t in i) delete i[t]
            for (let o = 0; o < e.length; o++)
              for (var t in e[o]) i[r + '[' + o + '].' + t] = e[o][t]
          })
        }, 500)
        return () => i
      }
      this._resourceOverlay.registerSource(
        o(this._terrainWWPoolHandle.webWorkerPool, 'workers[base]')
      )
      this._resourceOverlay.registerSource(
        o(this._gltfWWPoolHandle.webWorkerPool, 'workers[gltf]')
      )
    }
  }
  _updateResourceOverlay() {
    if (this._resourceOverlay) this._resourceOverlay.update()
  }
  setMeshCollisionDetectionExperimental(e) {
    this._photonView.setExperimentalMeshCollisionConstraint(e)
  }
  setPaintAtmosphere(e) {
    this._setPaintAtmosphereInternal(e)
  }
  _setPaintAtmosphereInternal(e, t) {
    let r = Photon.AtmosphericScatteringMode.Off
    let i = 2
    let o = 1
    let a = 1
    if (t instanceof LightScatteringAtmosphere) {
      r = t.affectsTerrain
        ? Photon.AtmosphericScatteringMode.SkyAndTerrain
        : Photon.AtmosphericScatteringMode.Sky
      i = t.brightness
      o = t.rayleighScatteringFactor
      a = t.mieScatteringFactor
    }
    this._photonView.setPaintAtmosphere(e, r, i, o, a)
  }
  setPaintStarfield(e) {
    this._photonView.setPaintStarfield(e)
  }
  resize(e, t, r) {
    const i = e - r.left
    const o = t - r.bottom
    this._photonCamera.setFullScreenViewPort(e, o)
    this._bodySurface.resize(e, o, r.left)
    this.htmlLabelSurface.resize(i, o, r.left)
    this._glowSurface.resizeIgnoreBorder(e, t)
    if (this.borderContext) {
      this.borderContext.resizeIgnoreBorder(e, t)
      this.bottomBorderLabelHtmlSurface.resizeAndPositionOnX(e, t, r.bottom, 0)
      this.leftBorderLabelHtmlSurface.resizeAndPositionOnY(e, t, r)
    }
    if (this.map.mapToViewTransformation) this.paint()
  }
  _addLayerNode() {
    this.invalidate()
  }
  addLayerTreeNode(e) {
    e.accept(this._addSubTreeVisitor)
    this.invalidate()
  }
  _listenToLayerEvents(e) {
    const t = e.on('VisibilityChanged', () =>
      this._resetTerrainPainterLayers(e)
    )
    this._layerlisteners.push({ layer: e, visibilityChangedHandle: t })
  }
  _stopListeningToLayerEvents(e) {
    for (let t = 0; t < this._layerlisteners.length; t += 1)
      if (this._layerlisteners[t].layer === e) {
        this._layerlisteners[t].visibilityChangedHandle.remove()
        this._layerlisteners.splice(t, 1)
        break
      }
  }
  removeLayerTreeNode(e) {
    e.accept(this._removeSubTreeVisitor)
    this.invalidate()
  }
  _removeLayerNode(e) {
    this.invalidate()
  }
  _calculateCameraPositionalVelocity() {
    const e = performance.now()
    const t = this._photonCameraUpdateContext.previousCamera
    const r = this.map.camera
    let i = 0
    if (t && r) {
      const o = r.eye.x - t.eye.x
      const a = r.eye.y - t.eye.y
      const n = r.eye.z - t.eye.z
      const s = undefined
      i =
        (Math.sqrt(o * o + a * a + n * n) /
          (e - this._photonCameraUpdateContext.previousTimeStamp)) *
        1e3
    }
    this._photonCameraUpdateContext.previousTimeStamp = e
    this._photonCameraUpdateContext.previousCamera = this.map.camera
    return i
  }
  invalidate() {
    this._bodySurface.valid = false
    this.htmlLabelSurface.valid = false
    this.invalidateBorderContext()
    this.repaint()
  }
  invalidateLayerTreeNode(e) {
    this.htmlLabelSurface.valid = false
    this._bodySurface.valid = false
    if (this.borderContext && this.borderContext.layers.indexOf(e) >= -1)
      this.invalidateBorderContext()
    this.repaint()
  }
  clipOutMapBorders() {
    const e = this.map.totalSize[0]
    const t = this.map.viewSize[1]
    const r = this.map.border.left
    const i = r > 0
    if (i && !this._isContextLost) {
      this._photonGraphics.setViewPort(0, 0, e, t)
      this._photonGraphics.setScissor(i, r, 0, e - r, t)
    }
  }
  paint() {
    if (0 === this._map.viewSize[0] || 0 === this._map.viewSize[1]) return
    this.clipOutMapBorders()
    this.labelManager.clear()
    this.bottomBorderLabelManager?.clear()
    this.leftBorderLabelManager?.clear()
    this.doScheduledWork(false)
    this._paintBodies()
    this.paintBorder()
    this._collectLabels()
    this._paintLabels()
    this.updateBalloons()
    this.map.firePostRenderEvent(this.techContext.glContext)
    if (!this.is3D()) {
      this._glowSurface.clear()
      this.sideGlowRenderer.updateViolationVisualFeedback(
        this.map,
        this._glowSurface
      )
    }
    this._updateResourceOverlay()
    this.doScheduledWork(true)
    if (this.hasScheduledWork()) this.repaint()
  }
  is3D() {
    return this._photonView.is3D()
  }
  _collectLabels() {
    if (this._isContextLost) return
    const e = this.map.layerTree._reduceLeaves(accumulateInArray, [])
    for (const t of e)
      if (t.visibleInTree && t._collectLabels && t.inAllowedScaleRange()) {
        const e = this.getLayerStyle(t)
        if (e.flicker) continue
        const r = e ? e.clip || INVALID_LAYER_CLIP : INVALID_LAYER_CLIP
        t._collectLabels(this.techContext, false, r)
      }
  }
  _paintLabels() {
    if (this.htmlLabelSurface.valid) return
    this.htmlLabelSurface.valid = true
    this.htmlLabelSurface.mark()
    this.labelManager.drawLabelManager(
      this.htmlLabelSurface,
      this.map,
      this.map.getLeftBorderSize(),
      0,
      this.map.getViewWidth() + this.map.getLeftBorderSize(),
      this.map.getViewHeight()
    )
    this.htmlLabelSurface.sweep()
  }
  _resetTerrainPainterLayers(e) {
    if (!this.is3D() || this._isContextLost) return
    let t = null
    let r = null
    let i = null
    let o = null
    if (e)
      if (
        e.visible &&
        e.visibleInTree &&
        e.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY)
      )
        if (true === this.terrainPainter.isCompatible(e.model))
          if (isElevation(e.model) || isTinElevation(e.model)) {
            t = e.model
            i = e
          }
    const a = this.map.layerTree._reduceLeaves(accumulateInArray, [])
    let n = true
    for (const e of a) {
      const a = e.model
      if (!!a && 'fillMeIn' === a._webWorkerPool)
        a._webWorkerPool = this._webWorkerPool
      if (
        e.visibleInTree &&
        e.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY)
      )
        if (isRaster(a) && e._renderer) {
          const s = this._terrainPainter.isCompatible(a)
          if (true !== s) {
            Log.warn(`${s} (${e.label} => invisible)`)
            e.visibleSupported = false
            continue
          }
          if (isElevation(a) || isTinElevation(a)) {
            if (t && t !== a) {
              Log.warn(
                `LuciadRIA WebGLMap can only have one visible elevation layer (${e.label} => invisible)`
              )
              e.visible = false
              continue
            }
            t = a
            i = e
          } else if (!n || (r && r !== a)) {
            if (e._renderer) e._renderer.isInBaseTerrain = false
          } else {
            const t = undefined
            if (isQuadTreeRasterTileSetModel(a)) {
              if (e._renderer) e._renderer.isInBaseTerrain = true
              r = a
              o = e
            } else {
              if (e._renderer) e._renderer.isInBaseTerrain = false
              n = false
            }
          }
        } else if (isVectorLayer(e) && !o) n = false
    }
    this.terrainPainter.imageModel = r
    this.terrainPainter.elevationModel = t
    let s = Math.max(
      (i && i._levelSwitchFactorInternal) || 0,
      (o && o._levelSwitchFactorInternal) || 0
    )
    if (!s || s <= 0.001) s = 0.5
    this.terrainPainter._levelSwitchFactorInternal = s
  }
  _paintBodies() {
    if (this.webGLContextLost) return
    this._linearLayers = this.map.layerTree._reduceLeaves(accumulateInArray, [])
    const e = this.techContext.photonView.paint(this._photonGraphics)
    this._lastPaintComplete = e
    if (!e) this.repaint()
  }
  _updateLayers(e, t) {
    let r = true
    for (const i of e)
      try {
        if (
          i.visibleInTree &&
          i.inAllowedScaleRange() &&
          i.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY)
        )
          r = i.update(t) && r
      } catch (e) {
        Log.error(`Error while updating layer ${i.label}`, e)
      }
    return r
  }
  _paintLayers(e, t) {
    let {
      selected: r,
      paintOpacity: i,
      paintDraping: o,
      paintOutput: a,
      allowClipping: n,
      depthIncludeController: s,
      depthPass: h,
      depthExcludedLayers: l,
      panoramicLayer: c,
      shouldNotPaint: p,
    } = t
    let d = true
    for (const t of e) {
      if (p(o, t)) continue
      if (
        t.visibleInTree &&
        t.inAllowedScaleRange() &&
        t.isPaintRepresentationVisibleInTree(PaintRepresentation.BODY)
      ) {
        const e = n ? this.getLayerStyle(t) : DEFAULT_LAYER_STYLE
        if (t.id === c) break
        if (e?.flicker) continue
        if (isArray(l) && l.includes(t.id)) continue
        try {
          d =
            t._paintBodyLabels(this.techContext, r, {
              paintOpacity: i,
              paintDraping: o,
              paintOutput: a,
              clip: e?.clip || INVALID_LAYER_CLIP,
            }) && d
        } catch (e) {
          Log.error(`Error while painting layer ${t.label}`, e)
        }
      }
    }
    if (!r && (!h || s))
      try {
        d = this._controllerRenderer.paint(r, i, o, a, INVALID_LAYER_CLIP) && d
      } catch (e) {
        Log.error('Error while painting controller', e)
      }
    return d
  }
  getLayerStyle(e) {
    return this._layerStyleMapping.get(e) ?? DEFAULT_LAYER_STYLE
  }
  repaint() {
    this._requestFrame()
  }
  _isReadyInner() {
    return (
      Photon.isReady() &&
      (!this.terrainPainter || this.terrainPainter.isReady()) &&
      this._lastPaintComplete &&
      this._environmentMapLoadingDone
    )
  }
  isReady() {
    if (this._isReadyInner())
      if (this.webGLContextLost || this._photonView.isDepthReady())
        return ReadyResult.READY
      else return ReadyResult.NOT_READY_BECAUSE_OF_DEPTH
    else return ReadyResult.NOT_READY
  }
  whenInitialized() {
    return Photon.whenReady()
  }
}
