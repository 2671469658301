import { createBounds } from '../../shape/ShapeFactory.js'
import { TileCoordinateUtil } from '../../model/tileset/TileCoordinateUtil.js'
import { getPath, mapToLowerLevel, zoom } from './TileUtil.js'
export class TileDrawingFrame {
  constructor(i) {
    this._slidingTileWindow = i
    this._modelBounds = createBounds(i.tileSet.reference)
    this._tileX = new Array(20)
    this._tileY = new Array(20)
    this._windowCoordinate = null
    this._tempMapBounds = createBounds(null)
    this._tempViewBounds = createBounds(null, [0, 0, 0, 0])
  }
  updateTo(i, t) {
    const e = this._slidingTileWindow.currentWindow
    this._windowCoordinate = e.grid
    const o = TileCoordinateUtil.computeTileBounds(
      this._slidingTileWindow.tileSet,
      this._windowCoordinate.level,
      this._windowCoordinate.x,
      1,
      this._windowCoordinate.y,
      1,
      this._modelBounds
    )
    const s = this._tempViewBounds
    try {
      i.transformBounds(o, this._tempMapBounds)
      t._forwardBoundsCoords(this._tempMapBounds, s)
    } catch (i) {
      return
    }
    const l = e.width + 1
    const n = e.height + 1
    if (l > this._tileX.length) this._tileX.length = l
    if (n > this._tileY.length) this._tileY.length = n
    let d
    const h = s.x
    const r = s.width
    if (t.isXFlipped())
      for (d = 0; d < l; d++) this._tileX[d] = Math.round(h - (d - 1) * r)
    else for (d = 0; d < l; d++) this._tileX[d] = Math.round(h + d * r)
    const _ = s.y + s.height
    const w = s.height
    if (t.isYFlipped())
      for (d = 0; d < n; d++) this._tileY[d] = Math.round(_ + (d - 1) * w)
    else for (d = 0; d < n; d++) this._tileY[d] = Math.round(_ - d * w)
    this._slidingTileWindow.shouldFlipXAxis = t.isXFlipped()
    this._slidingTileWindow.shouldFlipYAxis = t.isYFlipped()
  }
  getViewBounds(i) {
    let t
    let e = null
    if (i.level > this._windowCoordinate.level) {
      t = mapToLowerLevel(i, this._windowCoordinate.level)
      e = getPath(t, i)
    } else t = i
    const o = t.x - this._windowCoordinate.x
    const s = t.y - this._windowCoordinate.y
    this._tempViewBounds.setTo2D(
      this._tileX[o],
      this._tileX[o + 1] - this._tileX[o],
      this._tileY[s + 1],
      this._tileY[s] - this._tileY[s + 1]
    )
    if (null != e)
      for (let i = 0; i < e.length; i++)
        zoom(
          this._tempViewBounds,
          e[i],
          this._slidingTileWindow.shouldFlipXAxis,
          this._slidingTileWindow.shouldFlipYAxis
        )
    return this._tempViewBounds
  }
}
