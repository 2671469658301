import { isUndefined } from '../Lang.js'
const IS_ABSOLUTE_URL = new RegExp('^(?:[a-z]+:)?//', 'i')
export function resolveHref(n, e, t) {
  const r = e.trim()
  const i = SLASH_CODE === r.charCodeAt(0)
  const l =
    BLOB_PREFIX === n.substr(0, BLOB_PREFIX.length)
      ? n.substr(BLOB_PREFIX.length)
      : n
  if (!l || IS_ABSOLUTE_URL.test(r) || (t && i)) return r
  if (i) {
    const n = '//'
    const e = l.indexOf(n)
    const t = l.indexOf('/', -1 === e ? 0 : e + n.length)
    return `${l.substring(0, t)}${r}`
  }
  const o = l.indexOf('?')
  const u = l.lastIndexOf('/', -1 === o ? void 0 : o)
  if (-1 === u) return r
  return `${l.substring(0, u)}/${r}`
}
const BLOB_PREFIX = 'blob:'
const SLASH_CODE = '/'.charCodeAt(0)
export function xmlFindFirstChild(n, e) {
  for (let t = n.firstElementChild; null !== t; t = t.nextElementSibling)
    if (e(t)) return t
  return null
}
export function xmlReduceChildren(n, e, t) {
  let r = n.firstElementChild
  let i = isUndefined(t) ? r : t
  if (null === r) return i
  let l = 0
  if (isUndefined(i)) {
    r = r.nextElementSibling
    l = 1
  }
  for (; null !== r; ++l, r = r.nextElementSibling) i = e(i, r, l)
  return i
}
export function xmlWalkChildren(n, e) {
  for (let t = 0, r = n.firstElementChild; r; ++t, r = r.nextElementSibling)
    e(r, t)
}
export function xmlFilterChildren(n, e) {
  return xmlReduceChildren(
    n,
    (n, t, r) => {
      if (e(t, r)) n.push(t)
      return n
    },
    []
  )
}
export function xmlFilterMapChildren(n, e) {
  return xmlReduceChildren(
    n,
    (n, t, r) => {
      const i = e(t, r)
      if (!isUndefined(i)) n.push(i)
      return n
    },
    []
  )
}
export function xmlFindFirstDescendant(n, e) {
  for (let t = n.firstElementChild; null !== t; t = t.nextElementSibling) {
    if (e(t)) return t
    const n = xmlFindFirstDescendant(t, e)
    if (null !== n) return n
  }
  return null
}
export function xmlNodeContains(n, e) {
  for (let t = e; t; t = xmlParentNode(t)) if (t === n) return true
  return false
}
export function xmlParentNode(n) {
  return n.parentNode
}
