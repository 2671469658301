import { ProgrammingError } from '../error/ProgrammingError.js'
class CallbackWrapper {
  constructor() {
    this.handles = []
    this.firstListenerAddedHandles = []
    this.lastListenerRemovedHandles = []
  }
  apply(e) {
    for (let t = this.handles.length - 1; t >= 0; --t) {
      const r = this.handles[t]
      if (r.callBack) r.callBack.apply(r.context, e)
    }
  }
  remove(e) {
    this.handles = this.handles.filter((t) => t != e)
    if (0 === this.handles.length)
      for (const e of this.lastListenerRemovedHandles)
        if (e.callBack) e.callBack()
  }
  addCallback(e, t) {
    const r = { callBack: e, context: t, remove: () => this.remove(r) }
    this.handles.unshift(r)
    if (1 === this.handles.length)
      for (const e of this.firstListenerAddedHandles)
        if (e.callBack) e.callBack()
    return r
  }
  addFirstListenerAddedCallback(e) {
    const t = {
      callBack: e,
      remove: () => this.removeFirstListenerAddedHandle(t),
    }
    this.firstListenerAddedHandles.push(t)
    if (this.handles.length >= 1) e()
    return t
  }
  removeFirstListenerAddedHandle(e) {
    this.firstListenerAddedHandles = this.firstListenerAddedHandles.filter(
      (t) => t != e
    )
  }
  addLastListenerRemovedCallback(e) {
    const t = {
      callBack: e,
      remove: () => this.removeLastListenerRemovedCallback(t),
    }
    this.lastListenerRemovedHandles.push(t)
    return t
  }
  removeLastListenerRemovedCallback(e) {
    this.lastListenerRemovedHandles = this.lastListenerRemovedHandles.filter(
      (t) => t != e
    )
  }
}
export class EventedSupport {
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : []
    let t =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : false
    if (t && 0 === e.length)
      throw new ProgrammingError(`No supported events are provided`)
    for (const t of e) {
      const e = new CallbackWrapper()
      this.callbacks[t] = e
      Object.assign(this, {
        ['emit' + t + 'Event']: function () {
          for (var t = arguments.length, r = new Array(t), s = 0; s < t; s++)
            r[s] = arguments[s]
          e.apply(r)
        },
      })
    }
    this._strictEventNames = e.join(', ')
    this._strictEventsMode = t
  }
  getOrCreateCallbackWrapper(e) {
    const t = this.callbacks
    let r = t[e]
    if (!r) {
      if (this._strictEventsMode)
        throw new ProgrammingError(
          `Event "${e}" not supported. Can only subscribe to following events: ${this._strictEventNames}`
        )
      r = new CallbackWrapper()
      t[e] = r
    }
    return r
  }
  on(e, t, r) {
    if (!t)
      throw new ProgrammingError(
        `No callback provided when subscribing for '${e}' events`
      )
    const s = undefined
    return this.getOrCreateCallbackWrapper(e).addCallback(t, r)
  }
  onFirstListenerAdded(e, t) {
    const r = undefined
    return this.getOrCreateCallbackWrapper(e).addFirstListenerAddedCallback(t)
  }
  onLastListenerRemoved(e, t) {
    const r = undefined
    return this.getOrCreateCallbackWrapper(e).addLastListenerRemovedCallback(t)
  }
  emit(e) {
    const t = this.callbacks[e]
    if (t) {
      for (
        var r = arguments.length, s = new Array(r > 1 ? r - 1 : 0), a = 1;
        a < r;
        a++
      )
        s[a - 1] = arguments[a]
      t.apply(s)
    }
  }
  canHandle(e) {
    if (this._strictEventsMode) return this._strictEventNames.indexOf(e) >= 0
    return true
  }
  get callbacks() {
    if (!this._callbacks) this._callbacks = {}
    return this._callbacks
  }
  static on(e, t, r, s) {
    return e.on(t, r, s)
  }
  static once(e, t, r, s) {
    const a = e.on(
      t,
      function () {
        a.remove()
        for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
          t[n] = arguments[n]
        r.apply(s, t)
      },
      s
    )
    return a
  }
  static pausable(e, t, r, s) {
    let a = true
    const n = e.on(
      t,
      () => {
        if (a) {
          const e = new Array()
          e.push(this)
          e.push(arguments)
          r.apply(e)
        }
      },
      s
    )
    n.pause = () => {
      a = false
    }
    n.resume = () => {
      a = true
    }
    return n
  }
}
export function createOptimizedEventedSupport() {
  let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : []
  let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : false
  return new EventedSupport(e, t)
}
