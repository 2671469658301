import { NotImplementedError } from '../../error/NotImplementedError.js'
import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
import { XYZPoint } from '../../shape/XYZPointBounds.js'
import { OcclusionMode } from './OcclusionMode.js'
import { createBounds } from '../../shape/ShapeFactory.js'
import { distance2D } from '../../util/Cartesian.js'
import { Hash } from '../../util/Hash.js'
const tmpHash = new Hash()
export class HTML5DrawCommandProto {
  __paintIndex = 0
  _maximumWorldMargin = 0
  _bounds = null
  _worldBounds = null
  _geometryHash = 0
  constructor(t, e, r) {
    this._worldReference = t
    this._shape = e
    this._zOrder = r
  }
  get geometryHash() {
    if (!this._geometryHash) {
      tmpHash.reset()
      this._shape.hashCode(tmpHash)
      this._geometryHash = tmpHash.getHashCode()
    }
    return this._geometryHash
  }
  get worldReference() {
    return this._worldReference
  }
  get bounds() {
    return this._bounds
  }
  set bounds(t) {
    this._bounds = t
  }
  set worldBounds(t) {
    this._worldBounds = t
  }
  get worldBounds() {
    return this._worldBounds
  }
  get zOrder() {
    return this._zOrder
  }
  get shape() {
    return this._shape
  }
  isReady() {
    return true
  }
  findInteractingShapes(t, e, r, n, s, o) {
    if (!this._shape) return []
    let i = false
    if (n) i = this.strokeInteractsView(t, e, r, s, o)
    else i = this.interactsView(t, e, r, s, o)
    return i ? [this._shape] : []
  }
  findDrawCommand(t) {
    if (!this._shape || !t) return null
    return this._shape.type === t.type && this._shape.equals(t) ? this : null
  }
  bindDomainShape(t) {
    this._domainShape = t
  }
  bindWorldBounds(t) {
    this._worldBounds = t
    this.setMaximumWorldMargin(this.bounds ? t.maximumMargin(this.bounds) : 0)
  }
  getMaximumWorldMargin(t) {
    return this._maximumWorldMargin
  }
  setMaximumWorldMargin(t) {
    this._maximumWorldMargin = t
  }
  getMaximumPadding(t) {
    return 0
  }
  getStyledShapeBounds() {
    return null
  }
  isEquivalent(t) {
    return this._shape ? null !== t.findDrawCommand(this._shape) : false
  }
  getMapDistanceSquared(t, e, r, n) {
    if (!this.worldBounds)
      throw new NotImplementedError('Should implement getMapDistanceSquared')
    n.clear()
    n.setDistanceToBounds(this.worldBounds, e, r)
  }
  isValidLocation(t, e) {
    return true
  }
  viewPointSFCT(t, e, r) {
    try {
      r.transform(t, e)
      return true
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
      return false
    }
  }
  draw(t, e, r) {}
  getRepeatingAnchorListSFCT(t, e, r) {}
  interacts(t, e, r) {
    return false
  }
  interactsView(t, e, r, n, s) {
    const o = getWorldInteractionBounds(t, e, r, n)
    return this.interacts(o, n, s)
  }
  mapAnchorPointSFCT(t, e) {
    return false
  }
  mapAnchorPointsSFCT(t, e, r) {}
  strokeInteracts(t, e, r) {
    return false
  }
  strokeInteractsView(t, e, r, n, s) {
    const o = getWorldInteractionBounds(t, e, r, n)
    return this.strokeInteracts(o, n, s)
  }
  getOcclusionHintForLabel() {
    return OcclusionMode.VISIBLE_ONLY
  }
  hasSameGeometry(t) {
    return this.geometryHash === t.geometryHash
  }
  refreshGeometryHash() {
    this._geometryHash = 0
    return this.geometryHash
  }
}
const tmpViewPoint = new XYZPoint()
const tmpMapPoint1 = new XYZPoint()
const tmpMapPoint2 = new XYZPoint()
export function getWorldInteractionBounds(t, e, r, n) {
  tmpViewPoint.move3D(t, e, 0)
  n.inverseTransformation.transform(tmpViewPoint, tmpMapPoint1)
  tmpViewPoint.move3D(t + r, e, 0)
  n.inverseTransformation.transform(tmpViewPoint, tmpMapPoint2)
  const s = distance2D(tmpMapPoint1, tmpMapPoint2)
  return createBounds(n.inputReference, [
    tmpMapPoint1.x - s,
    2 * s,
    tmpMapPoint1.y - s,
    2 * s,
    tmpMapPoint1.z - s,
    2 * s,
  ])
}
