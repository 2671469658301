import { LTSSupportedOperations } from './LTSCapabilitiesOperations.js'
import { LTSCapabilitiesParser } from './LTSCapabilitiesParser.js'
import { LTSCapabilitiesUtil } from './LTSCapabilitiesUtil.js'
import { LTSQuery } from './LTSQuery.js'
import { LTSQueryParser } from './LTSQueryParser.js'
import { addHttpRequestOptions } from '../../util/HttpRequestOptions.js'
import { request, urlWithParams } from '../../util/request.js'
const SUPPORTED_VERSIONS = ['1.1.1']
export class LTSCapabilities {
  constructor(e, t, r, s, i, a) {
    this.version = e
    this.service = r
    this.updateSequence = s
    this.coverages = a
    this.capabilitiesXML = t
    this.supportedOperations = i
  }
  static async fromURL(e, t) {
    const r = {
      credentials: (t = t || {}).credentials,
      requestHeaders: t.requestHeaders,
      requestParameters: t.requestParameters,
      url: e,
    }
    const s = LTSCapabilitiesUtil.createVersionNegotiator(
      e,
      SUPPORTED_VERSIONS,
      t.allowedVersions,
      r
    )
    const i = await s.negotiateVersion()
    const a = new LTSCapabilitiesParser(i.response, e)
    const o = a.parseServiceMetadata()
    const n = a.parseUpdateSequence()
    const p = a.parseSupportedOperations()
    try {
      const e = await LTSCapabilities.query(i.version, p, r)
      const t = undefined
      const s = new LTSQueryParser(e).parseCoverages()
      return new LTSCapabilities(i.version, i.response, o, n.toString(), p, s)
    } catch (t) {
      if (t instanceof Error) {
        const r = t?.message
        t.message = `Capabilities cannot be retrieved for '${e}'${
          r ? ': ' + r : ''
        }`
      }
      throw t
    }
  }
  static query(e, t, r) {
    const s = t.getOperations(LTSSupportedOperations.Query)
    if (!s) throw new Error(`Query object does not exist'`)
    const i = s.filter((e) => 'POST' === e.method)[0]
    const a = urlWithParams(i.endPoint, { VERSION: e, ...r.requestParameters })
    const o = addHttpRequestOptions(
      {
        method: 'POST',
        headers: { 'Content-Type': 'text/xml' },
        body: LTSQuery.empty().xml,
      },
      r
    )
    return request(a, o).then((e) => e.text())
  }
}
