import { getReference } from '../../reference/ReferenceProvider.js'
import { createBounds } from '../../shape/ShapeFactory.js'
import { isDefined } from '../../util/Lang.js'
export class WMTSCapabilitiesTileMatrixSet {
  constructor(e) {
    this._title = e['title']
    this._abstract = e['abstract']
    this._keywords = e['keywords']
    this._identifier = e['identifier']
    this._wellKnownScaleSet = e['wellKnownScaleSet']
    this._tileMatrices = e['tileMatrices']
    this._referenceName = e['referenceName']
    this._bounds = e.bounds
  }
  get title() {
    return this._title
  }
  get abstract() {
    return this._abstract
  }
  get keywords() {
    return this._keywords
  }
  get identifier() {
    return this._identifier
  }
  get wellKnownScaleSet() {
    return this._wellKnownScaleSet
  }
  get tileMatrices() {
    return this._tileMatrices
  }
  get referenceName() {
    return this._referenceName
  }
  getReference() {
    return getReference(this._referenceName)
  }
  getBounds() {
    if (!isDefined(this._bounds)) return null
    return createBounds(getReference(this._bounds.reference), [
      this._bounds.x,
      this._bounds.width,
      this._bounds.y,
      this._bounds.height,
    ])
  }
}
