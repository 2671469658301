import { MemoryStore } from '../../model/store/MemoryStore.js'
import { createOptimizedEventedSupport } from '../../util/EventedSupport.js'
import { NULL_FEATURE } from './loadingstrategy/ScaleLevelCursor.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { createBounds } from '../../shape/ShapeFactory.js'
export class FeatureModelQueryCache {
  _modelBounds = null
  _modelChanged = false
  constructor(e) {
    this._layer = e
    const s = e.model
    this._eventedSupport = createOptimizedEventedSupport(['ModelChanged'])
    this._sourceModel = s
    const t =
      s.reference.referenceType !== ReferenceType.GEOCENTRIC &&
      !!e.transformer &&
      e.isDefaultShapeProvider
    this._queryCache = new MemoryStore({
      spatialIndex: t,
      reference: s.reference,
    })
    this._spatialBbox = createBounds(s.reference, [0, 0, 0, 0])
    this._delayedModelChanges = []
    this._isQueryProcessed = false
    this._modelChangedHandle = s.on('ModelChanged', (e, s, t) => {
      if (this._isQueryProcessed) this.processModelChanges(e, s, t)
      else this._delayedModelChanges.push([e, s, t])
    })
  }
  get sourceModel() {
    return this._sourceModel
  }
  get reference() {
    return this._sourceModel.reference
  }
  get modelBounds() {
    return this._modelBounds
  }
  isModelChanged() {
    return this._modelChanged
  }
  release() {
    this._modelChangedHandle?.remove()
    this._modelChangedHandle = null
    this.clearCachedModelData()
  }
  cache(e, s) {
    this._delayedModelChanges.length = 0
    this._modelBounds = null
    return Promise.resolve(e)
      .then((e) => {
        this.clearCachedModelData()
        return {
          hasNext: () => {
            if (s.aborted) {
              this.clearCachedModelData()
              return false
            }
            return e.hasNext()
          },
          next: () => {
            const s = e.next()
            if (s === NULL_FEATURE) return s
            this._queryCache.add(s)
            this.updateBounds(s)
            return s
          },
        }
      })
      .catch((e) => {
        this.clearCachedModelData()
        throw e
      })
  }
  updateBounds(e) {
    const s = e.shape?.bounds
    if (this._queryCache.spatialIndex && s)
      if (null === this._modelBounds) this._modelBounds = s.copy()
      else this._modelBounds.setTo2DUnion(s)
  }
  query() {
    const { map: e } = this._layer
    if (this._queryCache.spatialIndex && e) {
      e.getMapBoundsSFCT(this._spatialBbox)
      this._spatialBbox.enlarge2D(3)
      return this._queryCache.spatialQuery(this._spatialBbox)
    }
    return this._queryCache.query()
  }
  processDelayedModelChangesOnQueryFinish() {
    this._isQueryProcessed = true
    this._delayedModelChanges.forEach((e) => {
      let [s, t, r] = e
      this.processModelChanges(s, t, r)
    })
    this._delayedModelChanges = []
  }
  getNumberOfFeaturesInCache() {
    return this._queryCache.size
  }
  clearCachedModelData() {
    this._queryCache.clear()
    this._modelChanged = false
    this._isQueryProcessed = false
    this._modelBounds = null
  }
  processModelChanges(e, s, t) {
    if ('remove' === e) this._queryCache.remove(t)
    else if ('update' === e || 'add' === e) this._queryCache.put(s)
    this._modelChanged = true
    this._eventedSupport.emitModelChangedEvent(e, s, t)
  }
  on(e, s, t) {
    return this._eventedSupport.on(e, s, t)
  }
}
