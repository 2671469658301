import { isDefined } from '../../util/Lang.js'
import { CapabilitiesParserUtil } from './common/CapabilitiesParserUtil.js'
export class WFSCapabilitiesParser {
  constructor(e) {
    this._raw = e
    this._rawRoot = e.WFS_Capabilities
  }
  getRawCapabilities() {
    return this._raw
  }
  getUpdateSequence() {
    return this._rawRoot.updateSequence ? this._rawRoot.updateSequence : null
  }
  getFeatureTypes() {
    const e = this._rawRoot.FeatureTypeList
    const t = this._rawRoot.OperationsMetadata
    if (!isDefined(e)) return null
    const r = this.getFeature(t)
    const i = []
    e.FeatureType.forEach((e) => {
      const t = this.getFeatureType(e, r.outputFormats)
      if (t) i.push(t)
    })
    return i
  }
  getWGS84Bounds(e) {
    if (!isDefined(e)) return []
    return e.map((e) => {
      const [t, r] = CapabilitiesParserUtil.parseCornerValues(e.LowerCorner)
      const [i, s] = CapabilitiesParserUtil.parseCornerValues(e.UpperCorner)
      return CapabilitiesParserUtil.freeze({
        reference: e.crs || 'CRS:84',
        minX: t,
        minY: r,
        maxX: i,
        maxY: s,
        width: i - t,
        height: s - r,
      })
    })
  }
  getService() {
    const e = this._rawRoot.ServiceProvider
    if (!isDefined(e)) return null
    const t = this._rawRoot.ServiceIdentification
    const r = e.ProviderSite
    return CapabilitiesParserUtil.freeze({
      title: t && t.Title,
      abstract: t && t.Abstract,
      keywords: t && this.getKeywords(t),
      url: r && r.href,
      contactInformation: getContactInformation(e),
    })
  }
  getKeywords(e) {
    const t = e.Keywords
    if (!isDefined(t)) return null
    return t.map((e) => {
      const t = e.Type && e.Type['#text']
      return CapabilitiesParserUtil.freeze({
        keywords: e.Keyword || [],
        type: t,
      })
    })
  }
  getOperations() {
    const e = []
    const t = this._rawRoot.OperationsMetadata
    const r = this.getCapabilities(t)
    const i = this.getFeature(t)
    const s = this.getDescribeFeatureType(t)
    e.push(r)
    e.push(i)
    e.push(s)
    return Object.freeze(e)
  }
  getCapabilities(e) {
    if (!isDefined(e)) return null
    const t = e.Operation.filter((e) => 'GetCapabilities' === e.name)[0]
    const r = this.getParametersValues(t, 'AcceptVersions')
    const i = this.getParametersValues(t, 'AcceptFormats')
    const s = getSupportedRequests(t)
    return CapabilitiesParserUtil.freeze({
      name: 'GetCapabilities',
      supportedVersions: r,
      supportedFormats: i,
      supportedRequests: s,
    })
  }
  getFeature(e) {
    if (!e) return null
    const t = e.Operation.filter((e) => 'GetFeature' === e.name)[0]
    const r = this.getParametersValues(t, 'outputFormat')
    const i = getSupportedRequests(t)
    return CapabilitiesParserUtil.freeze({
      name: 'GetFeature',
      supportedFormats: r,
      supportedRequests: i,
    })
  }
  getDescribeFeatureType(e) {
    if (!isDefined(e)) return null
    const t = e.Operation.filter((e) => 'DescribeFeatureType' === e.name)[0]
    const r = this.getParametersValues(t, 'outputFormat')
    const i = getSupportedRequests(t)
    return CapabilitiesParserUtil.freeze({
      name: 'DescribeFeatureType',
      supportedFormats: r,
      supportedRequests: i,
    })
  }
  getQualifiedFeatureTypeName(e) {
    const t = e.Name.split(':')
    if (isDefined(e.Namespace) && t.length > 1)
      return {
        namespace: e.Namespace,
        prefix: t[0],
        localPart: e.Name.substring(t[0].length + 1),
      }
    return null
  }
}
function getContactInformation(e) {
  const t = e.ServiceContact
  const r = t && t.ContactInfo
  const i = r && r.Phone
  const s = r && r.Address
  return CapabilitiesParserUtil.freeze({
    primaryPerson: getPrimaryPerson(e),
    position: t && t.PositionName,
    address: getAddress(e),
    telephone: i && i.Voice && i.Voice[0],
    fax: i && i.Facsimile,
    email: s && s.ElectronicMailAddress && s.ElectronicMailAddress[0],
  })
}
function getPrimaryPerson(e) {
  return CapabilitiesParserUtil.freeze({
    person: e.ServiceContact && e.ServiceContact.IndividualName,
    organization: e.ProviderName,
  })
}
function getAddress(e) {
  const t = e.ServiceContact && e.ServiceContact.ContactInfo
  const r = t && t.Address
  if (!isDefined(r)) return null
  return CapabilitiesParserUtil.freeze({
    address: r.DeliveryPoint && r.DeliveryPoint[0],
    city: r.City,
    stateOrProvince: r.AdministrativeArea,
    postCode: r.PostalCode,
    country: r.Country,
  })
}
function getSupportedRequests(e) {
  if (!isDefined(e)) return null
  const t = []
  const r = e.DCP && e.DCP[0]
  if (r.HTTP && r.HTTP.Get) t.push({ method: 'GET', url: r.HTTP.Get.href })
  if (r.HTTP && r.HTTP.Post) t.push({ method: 'POST', url: r.HTTP.Post.href })
  return t
}
