import { ObjectReleaseTracker } from '../../../util/ObjectReleaseTracker.js'
import { PaintRepresentation } from '../../PaintRepresentation.js'
import { DrawNullLabel } from '../../style/DrawNullLabel.js'
import { getDetailLevel } from '../FeatureLayerRendererUtils.js'
import { PhotonRenderSet } from './PhotonRenderSet.js'
import { PhotonShapePainter } from './PhotonShapePainter.js'
import { MemoryWorkingSet } from '../MemoryWorkingSet.js'
import { createLabelContext } from '../LabelDrawingContext.js'
import { HTML5LabelCanvas } from '../../style/HTML5LabelCanvas.js'
import { Log } from '../../../util/Log.js'
export class PhotonFeatureLayerRenderer extends ObjectReleaseTracker {
  _featurePainter = null
  _labelCanvas = null
  constructor(e, t, a, i, r, n, s, h, l) {
    super()
    this._bodyPaintRepresentation = t
    this._labelPaintRepresentation = a
    this._labelDrawCommandMap = new Map()
    this._previousLabelDrawCommandMap = new Map()
    this._level = 0
    this._photonView = i
    this._photonGraphics = r
    this._map = e.map
    this._layer = e.layer
    this._incrementalDrawing = !!e.incremental
    this._shapePainter = this.track(
      new PhotonShapePainter(
        this._layer,
        this._photonView,
        this._map.reference,
        this._photonGraphics,
        n,
        s,
        this._map,
        h,
        l,
        e.rendererOptions
      )
    )
    this._photonWorkingSet = this.track(new MemoryWorkingSet(this._layer, e))
    this._photonRenderSet = this.track(
      new PhotonRenderSet(
        this._layer,
        this._map,
        this._photonWorkingSet,
        this,
        this._shapePainter,
        e
      )
    )
    this._terrainHash = -1
    this._setPainter(e.painter)
    const o = this._layer.getLabelManager(a)
    this._labelContext = createLabelContext(this._map, o, a)
  }
  isReady() {
    return this._photonRenderSet.isReady()
  }
  setEnabled(e) {
    this._shapePainter?._shapePainter.setEnabled(e)
  }
  isEnabled() {
    return !!this._shapePainter?._shapePainter.isEnabled()
  }
  getWorkingSet() {
    return this._photonWorkingSet
  }
  _setPainter(e) {
    this._featurePainter = e
    this._shapePainter.setPainter(e)
    this._labelCanvas = new HTML5LabelCanvas({
      invalidateAction: this._layer._invalidate.bind(this._layer),
    })
  }
  paint(e, t, a) {
    const i = this._photonView.getTerrainVisibleDataHash()
    if (this._terrainHash !== i) {
      this._terrainHash = i
      this._photonRenderSet.terrainChanged()
    }
    this._level = getDetailLevel(this._map, this._layer, this._featurePainter)
    this._shapePainter.setDetailLevel(this._level)
    return this._shapePainter.paint(
      t,
      a.paintOpacity,
      a.paintDraping,
      a.paintOutput,
      a.clip
    )
  }
  collectLabels(e, t, a) {
    if (!this._layer.canDrawLabels(this._labelPaintRepresentation)) return
    this._labelContext.reset()
    const i = {
      x: 0,
      y: 0,
      width: this._map.viewSize[0],
      height: this._map.viewSize[1],
    }
    if (a?.valid) {
      i.x = a.x
      i.y = this._map.viewSize[1] - a.height - a.y
      i.width = a.width
      i.height = a.height
    }
    this._shapePainter.fastPick(i, (e) => {
      const t = this.getLabelDrawCommand(e)
      t?.requestToDrawLabel(a)
    })
  }
  getLabelDrawCommand(e) {
    const t = this._labelDrawCommandMap.get(e.id)
    if (t?.canBeReused()) {
      this._previousLabelDrawCommandMap.set(e.id, t)
      t.setPickInfoContext(this._layer, e)
      return t
    }
    const a = this.createLabelDrawCommand(e)
    a.setPickInfoContext(this._layer, e)
    this._labelDrawCommandMap.set(e.id, a)
    return a
  }
  createLabelDrawCommand(e) {
    if (!this._featurePainter?.paintLabel || !this._labelCanvas)
      return DrawNullLabel
    try {
      const t = this._shapePainter.buildLabelDrawCommands(
        e,
        this._labelCanvas,
        this._labelContext
      )
      const a = this._previousLabelDrawCommandMap.get(e.id)
      if (a) t.reuseStateFrom(a)
      else this._previousLabelDrawCommandMap.set(e.id, t)
      return t
    } catch (e) {
      const t = e instanceof Error ? e : void 0
      Log.error(`Error while collecting labels ${this._layer.label}`, t)
      return DrawNullLabel
    }
  }
  invalidateLabelCaches(e, t) {
    if (t) this._previousLabelDrawCommandMap.delete(e.id)
    else {
      const t = this._labelDrawCommandMap.get(e.id)
      if (t) this._previousLabelDrawCommandMap.set(e.id, t)
    }
    this._labelDrawCommandMap.delete(e.id)
  }
  pick(e) {
    return this._shapePainter.pick(e)
  }
  getMaximumInteractionZOrder(e, t) {
    return -1 / 0
  }
  resetPainter(e) {
    this._setPainter(e)
    this._invalidateAllFeatures()
  }
  _invalidateFeature(e) {
    this._photonRenderSet.updateStyle(e)
  }
  _invalidateAllFeatures() {
    this._photonRenderSet.updateAllStyles()
  }
  setSelected(e, t) {
    const a = this._photonWorkingSet.getFeature(e)
    if (a) this._photonRenderSet.updateStyle(a)
    return a
  }
  setEdited(e, t) {
    if (e) {
      this._shapePainter.setEdited(e, t)
      this._photonRenderSet.updateStyle(e)
      return e
    } else return null
  }
  filterChanged(e) {
    this._invalidateAllFeatures()
  }
  onAdd(e) {}
  onUpdate(e) {
    this.invalidateLabelCaches(e, false)
  }
  onRestyle(e) {
    this.invalidateLabelCaches(e, false)
  }
  onRemove(e) {
    this.invalidateLabelCaches(e, true)
  }
  static create(e, t, a, i) {
    if (a !== PaintRepresentation.BODY || i !== PaintRepresentation.LABEL)
      return null
    return new PhotonFeatureLayerRenderer(
      t,
      a,
      i,
      e.photonView,
      e.photonGraphics,
      e.imageDecoder,
      e.referenceProvider,
      e.gltfDecoder,
      e.shapeDiscretizer
    )
  }
}
