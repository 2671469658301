import { isNumber } from '../../util/Lang.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { DrapeTarget } from './DrapeTarget.js'
function validateAlpha(e) {
  if (!isNumber(e) || e < 0 || e > 1)
    throw new ProgrammingError('alpha value should be between 0 and 1')
  return e
}
export class RasterStyleImpl {
  _alpha = 1
  _displacementExpression = null
  _draped = DrapeTarget.NOT_DRAPED
  constructor(e, a) {
    this.layer = e
    if (null != a) {
      this._alpha = validateAlpha(a.alpha ?? 1)
      this._displacementExpression = a.displacementExpression ?? null
      this._draped = a.drapeTarget ?? DrapeTarget.TERRAIN
    }
  }
  get alpha() {
    return this._alpha
  }
  set alpha(e) {
    this._alpha = validateAlpha(e)
    this.layer._invalidate()
  }
  get displacementExpression() {
    return this._displacementExpression
  }
  set displacementExpression(e) {
    this._displacementExpression = e
    this.layer._invalidate()
  }
  get drapeTarget() {
    return this._draped
  }
  set drapeTarget(e) {
    this._draped = e
    this.layer._invalidate()
  }
}
