import { Constants } from '../util/Constants.js'
import { polygonContains2D } from '../geodesy/SphereUtil.js'
import { shouldExtend } from './GeodeticBoundsHeuristicsUtil.js'
import {
  simplePointCopy,
  simplePointMove2D,
  simplePointMove3DToCoordinates,
} from './SimplePoint.js'
const STEP = 1 - 2e-4
function radians(e) {
  return e * Constants.DEG2RAD
}
function calculate2DPolylineBounds(e, t, o) {
  _calculate2DBounds(e, false, t, o)
}
export function calculate3DPolylineBounds(e, t, o) {
  calculate2DPolylineBounds(e, t, o)
  _calculateBoundsDepth(e, o)
}
function calculate2DPolygonBounds(e, t, o) {
  _calculate2DBounds(e, true, t, o)
  if (o.width >= 180)
    if (o.y < 0 && polygonContains2D(e, 0, -90)) {
      o.includeCoordinate2D(0, -90)
      o.setTo2D(o.x, 360, o.y, o.height)
    } else if (o.y + o.height > 0 && polygonContains2D(e, 0, 90)) {
      o.includeCoordinate2D(0, 90)
      o.setTo2D(o.x, 360, o.y, o.height)
    }
}
export function calculate3DPolygonBounds(e, t, o) {
  calculate2DPolygonBounds(e, t, o)
  _calculateBoundsDepth(e, o)
}
function _calculateBoundsDepth(e, t) {
  const o = e.pointCount
  if (0 === o) return
  let n = 0 === o ? 0 : Number.POSITIVE_INFINITY,
    i = 0 === o ? 0 : Number.NEGATIVE_INFINITY,
    s
  for (let t = 0; t < o; t++) {
    s = e.getSimplePoint(t).z
    if (s < n) n = s
    if (s > i) i = s
  }
  t.setTo3D(t.x, t.width, t.y, t.height, n, i - n)
}
function _calculate2DBounds(e, t, o, n) {
  const i = e.pointCount
  let s
  if (i > 0) {
    calculateConnectedPointBounds(e, n)
    const l = t ? i : i - 1
    let u = simplePointCopy(e.getSimplePoint(0))
    let a = simplePointCopy(u)
    for (let t = 0; t < l; t++) {
      const l = e.getSimplePoint((t + 1) % i)
      simplePointMove3DToCoordinates(a, l.x, l.y, l.z)
      extendBounds(u, a, o, n)
      s = u
      u = a
      a = s
    }
  }
}
function calculateConnectedPointBounds(e, t) {
  const o = e.getSimplePoint(0)
  let n = o.x,
    i = n,
    s = o.y,
    l = s,
    u = n
  for (let t = 1; t < e.pointCount; t++) {
    const o = e.getSimplePoint(t).x
    const a = e.getSimplePoint(t).y
    if (a > l) l = a
    if (a < s) s = a
    let f = o - u
    if (f > 180) f -= 360
    else if (f < -180) f += 360
    if (f > 0) {
      if (u + f > i) i = u + f
    } else if (u + f < n) n = u + f
    u += f
  }
  t.setTo2D(n, i - n, s, l - s)
}
function extendBounds(e, t, o, n) {
  if (o.f < 0.005) extendBoundsFast(e, t, o, n)
  else extendBoundsSlow(e, t, o, n)
}
function extendBoundsFast(e, t, o, n) {
  const i = t.x - e.x
  let s
  if (i < 0.05 && i > -0.05) return
  else if (i < 0.15 && i > -0.15) {
    s = (t.y - e.y) / i
    if (s < -63e-5 || s > 63e-5) return
  } else if (i < 0.5 && i > -0.5) {
    s = (t.y - e.y) / i
    if (s < -0.0021 || s > 0.0021) return
  } else if (i < 1.5 && i > -1.5) {
    s = (t.y - e.y) / i
    if (s < -0.0063 || s > 0.0063) return
  } else if (i < 10 && i > -10) {
    s = (t.y - e.y) / i
    if (s < -0.045 || s > 0.045) return
  }
  extendBoundsSlow(e, t, o, n)
}
function extendBoundsSlow(e, t, o, n) {
  const i = e.y >= 0 ? e.y : -e.y,
    s = t.y >= 0 ? t.y : -t.y
  if (i > s)
    if (e.y > 0) extendBoundsNorth(t, e, o, n)
    else extendBoundsSouth(t, e, o, n)
  else if (i < s)
    if (t.y > 0) extendBoundsNorth(e, t, o, n)
    else extendBoundsSouth(e, t, o, n)
  else if (e.y * t.y > 0)
    if (e.y > 0) extendBoundsNorth(e, t, o, n)
    else extendBoundsSouth(e, t, o, n)
}
function extendBoundsNorth(e, t, o, n) {
  if (!shouldExtend(e, t, o, n, true)) return
  const i = simplePointCopy(e)
  const s = simplePointCopy(i)
  o.geodesicPositionAtFractionSFCT(e, t, STEP, i)
  let l
  if (i.y > t.y) {
    o.geodesicPositionSFCT(retrieveMaxPoint(e, t, STEP, i, o), 0.3, 0, s)
    l = s.y - n.y
    if (l > n.height) n.setTo2D(n.x, n.width, n.y, l)
  }
}
function extendBoundsSouth(e, t, o, n) {
  if (!shouldExtend(e, t, o, n, false)) return
  const i = simplePointCopy(e)
  let s
  o.geodesicPositionAtFractionSFCT(e, t, STEP, i)
  if (i.y < t.y) {
    s = simplePointCopy(i)
    o.geodesicPositionSFCT(_retrieveMinPoint(e, t, STEP, i, o), 0.3, 180, s)
    if (s.y < n.y) n.setTo2D(n.x, n.width, s.y, n.height + n.y - s.y)
  }
}
function retrieveMaxPoint(e, t, o, n, i) {
  const s = simplePointCopy(e)
  const l = simplePointCopy(s)
  const u = 1 / i.a
  let a = 0
  let f = 1
  let c = 0
  let d = 1
  let r = o
  let y = e.y
  let p = t.y
  let P = n.y
  let h = -o
  let C = h * Constants.GOLDEN_RATIO
  let D
  let x
  let m
  let T
  let B
  let S
  let g
  let M
  let E
  let F
  for (F = 1; F <= 100; F++) {
    S = 0.5 * (a + f)
    M = 1e-4 * r + 1e-10
    E = 2 * M
    if (Math.abs(r - S) <= E - 0.5 * (f - a)) {
      i.geodesicPositionAtFractionSFCT(e, t, r, l)
      return l
    }
    if (Math.abs(h) > M) {
      B = (r - d) * (P - y)
      T = (r - c) * (P - p)
      m = (r - c) * T - (r - d) * B
      T = 2 * (T - B)
      if (T > 0) m = -m
      T = Math.abs(T)
      g = h
      h = C
      if (
        Math.abs(m) >= Math.abs(0.5 * T * g) ||
        m <= T * (a - r) ||
        m >= T * (f - r)
      ) {
        h = r > S ? a - r : f - r
        C = Constants.GOLDEN_RATIO * h
      } else {
        C = m / T
        D = r + C
        if (D - a < E || f - D < E) C = S - r >= 0 ? M : -M
      }
    } else {
      h = r > S ? a - r : f - r
      C = Constants.GOLDEN_RATIO * h
    }
    if (Math.abs(C) >= M) D = r + C
    else D = C >= 0 ? r + M : r - M
    i.geodesicPositionAtFractionSFCT(e, t, D, s)
    x = s.y
    if (x >= P) {
      if (D >= r) a = r
      else f = r
      c = d
      d = r
      r = D
      y = p
      p = P
      P = x
      simplePointMove2D(l, s.x, s.y)
    } else {
      if (D < r) a = D
      else f = D
      if (x >= p || d === r) {
        c = d
        d = D
        y = p
        p = x
      } else if (x >= y || c === r || c === d) {
        c = D
        y = x
      }
    }
    if (radians(P - p) < u) return l
  }
  return l
}
function _retrieveMinPoint(e, t, o, n, i) {
  const s = simplePointCopy(e)
  const l = simplePointCopy(s)
  const u = 1 / i.a
  let a = 0
  let f = 1
  let c = 0
  let d = 1
  let r = o
  let y = e.y
  let p = t.y
  let P = n.y
  let h = -o
  let C = h * Constants.GOLDEN_RATIO
  let D
  let x
  let m
  let T
  let B
  let S
  let g
  let M
  let E
  let F
  for (F = 1; F <= 100; F++) {
    S = 0.5 * (a + f)
    M = 1e-4 * r + 1e-10
    E = 2 * M
    if (Math.abs(r - S) <= E - 0.5 * (f - a)) {
      i.geodesicPositionAtFractionSFCT(e, t, r, l)
      return l
    }
    if (Math.abs(h) > M) {
      B = (r - d) * (P - y)
      T = (r - c) * (P - p)
      m = (r - c) * T - (r - d) * B
      T = 2 * (T - B)
      if (T > 0) m = -m
      T = Math.abs(T)
      g = h
      h = C
      if (
        Math.abs(m) >= Math.abs(0.5 * T * g) ||
        m <= T * (a - r) ||
        m >= T * (f - r)
      ) {
        h = r > S ? a - r : f - r
        C = Constants.GOLDEN_RATIO * h
      } else {
        C = m / T
        D = r + C
        if (D - a < E || f - D < E) C = S - r >= 0 ? M : -M
      }
    } else {
      h = r > S ? a - r : f - r
      C = Constants.GOLDEN_RATIO * h
    }
    if (Math.abs(C) >= M) D = r + C
    else D = C >= 0 ? r + M : r - M
    i.geodesicPositionAtFractionSFCT(e, t, D, s)
    x = s.y
    if (x <= P) {
      if (D >= r) a = r
      else f = r
      c = d
      d = r
      r = D
      y = p
      p = P
      P = x
      simplePointMove2D(l, s.x, s.y)
    } else {
      if (D < r) a = D
      else f = D
      if (x <= p || d === r) {
        c = d
        d = D
        y = p
        p = x
      } else if (x <= y || c === r || c === d) {
        c = D
        y = x
      }
    }
    if (radians(p - P) < u) return l
  }
  return l
}
export function calculate2DBoundsPoints(e, t, o, n) {
  n.setTo2D(e.x, 0, e.y, 0)
  n.setToIncludePoint2D(t)
  extendBounds(e, t, o, n)
}
