import { ReferenceType } from '../../../../reference/ReferenceType.js'
import { ShapeType } from '../../../../shape/ShapeType.js'
import { HelperHandle } from './HelperHandle.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
function is3DMap(e) {
  return e.reference.referenceType === ReferenceType.GEOCENTRIC
}
function is3DPointList(e) {
  if (ShapeType.contains(e.type, ShapeType.POLYLINE)) {
    const t = e
    for (let e = 0; e < t.pointCount; e++) {
      const r = undefined
      if (0 !== t.getPoint(e).z) return true
    }
  }
  return false
}
function zToZero(e) {
  const t = e.copy()
  for (let r = 0; r < e.pointCount; r++) {
    const n = e.getPoint(r)
    t.move3DPoint(r, n.x, n.y, 0)
  }
  return t
}
export class PointListHelperHandle extends HelperHandle {
  constructor(e, t) {
    super(t)
    this._pointList = e
  }
  get pointList() {
    return this._pointList
  }
  onDraw(e, t) {
    const r = this.getHelperStyle(t)
    if (r && is3DMap(t.map) && is3DPointList(this.pointList))
      e.drawShape(zToZero(this.pointList), {
        ...r,
        drapeTarget: DrapeTarget.NOT_DRAPED,
      })
  }
}
