import { TileCoordinateUtil } from '../../model/tileset/TileCoordinateUtil.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { TransformationType } from '../../transformation/TransformationType.js'
import { isNumber, isObject } from '../../util/Lang.js'
const PRECISION = 1e10
function round(e, r) {
  const t = Math.pow(10, r)
  return Math.round(e * t) / t
}
export function _calculateScaleForTilesetLevelNoPixCorrection(e, r, t) {
  if (!isObject(e) || !e.getPixelDensity)
    throw new ProgrammingError(
      'TileSetScaleUtil::calculateScale - first argument needs to be a tileset model'
    )
  if (!isObject(r))
    throw new ProgrammingError(
      'TileSetScaleUtil::calculateScale - second argument needs to be a map'
    )
  if (!isNumber(t))
    throw new ProgrammingError(
      'TileSetScaleUtil::calculateScale - third argument needs to be a number'
    )
  const o = e.reference
  const a = r.reference
  const n = createTransformation(o, a)
  const l = n._getType()
  if (
    !(
      l === TransformationType.TYPE_SCALE ||
      l === TransformationType.TYPE_IDENTITY
    ) ||
    !n.getScale
  )
    throw new ProgrammingError(
      'TileSetScaleUtil::calculateScale - transformation between model and map reference does not allow for this type of operation'
    )
  let i = e.getPixelDensity(t)
  const c = n.getScale()
  i = i[0] || i
  return i / c.x
}
export function calculateScaleForTilesetLevel(e, r, t) {
  const o = _calculateScaleForTilesetLevelNoPixCorrection(e, r, t)
  return r._convertXComponentPixelToMap(o)
}
export function calculateTilesetLevelForMapScale(e, r) {
  if (e._map !== r)
    throw new ProgrammingError('The layer is not added to the map.')
  const t = e._renderer ? e._renderer._xScaleFactor : 1
  const o = r._worldViewTransformation.getScaleX() * t
  let a = TileCoordinateUtil._getLevel(o, e.model)
  a = Math.round(a * PRECISION) / PRECISION
  return a
}
export function snapScaleToLevel(e, r, t, o, a, n) {
  let l
  try {
    l = _calculateScaleForTilesetLevelNoPixCorrection(e, r, 0)
  } catch (e) {
    n.scalex = o
    n.scaley = a
    return
  }
  const i = e.levelCount - 1
  const c = round(t(Math.log(o / l) / Math.log(2)), 5)
  let s
  let m
  if (c <= i && c >= 0) {
    m = Math.min(Math.max(0, c), i)
    try {
      s = _calculateScaleForTilesetLevelNoPixCorrection(e, r, m)
      n.scalex = s
      n.scaley = s
      return
    } catch (e) {
      n.scalex = o
      n.scaley = a
      return
    }
  }
  n.scalex = o
  n.scaley = a
}
