import { XYZBounds } from '../../shape/XYZBounds.js'
import { CommandUtil } from './CommandUtil.js'
import { CoordinateArrayUtil } from './CoordinateArrayUtil.js'
import { appendPathSFCT } from './HTML5CanvasUtil.js'
import { HTML5DrawFillCommandProto } from './HTML5DrawFillCommandProto.js'
import {
  ringContainsXY2DFlatCoordList,
  squaredDistanceToLineFlat,
} from '../../util/Cartesian.js'
import {
  getPerpendicularOffsetInPixels,
  saveAndOffsetContext,
} from './OffsetsUtil.js'
const viewBounds = new XYZBounds(null)
export class HTML5DrawStrokeFillCommand extends HTML5DrawFillCommandProto {
  _close = true
  constructor(t, o, s, i, r, e, n) {
    super(t, o, s, i, r)
    this._coordinates = e
    this._stroke = n
    this._normalizedOffsets = i.normalizedOffsets
    this._coordinatesArray = new Array(2)
    this._coordinatesGp = [this._coordinates]
    if (this._coordinates.length >= 0) {
      this.bounds = new XYZBounds(null)
      CoordinateArrayUtil.calculateBoundsSFCT(this._coordinates, this.bounds)
    }
  }
  draw(t, o, s) {
    if (this._coordinatesArray.length < this._coordinates.length)
      this._coordinatesArray = new Array(this._coordinates.length)
    saveAndOffsetContext(t, this._normalizedOffsets, s)
    const i = getPerpendicularOffsetInPixels(
      this.fillStyle.normalizedPerpendicularOffset,
      s
    )
    t.beginPath()
    const r = appendPathSFCT(this._coordinates, t, o, this._coordinatesArray, i)
    t.closePath()
    t.restore()
    this._fill(t, this._coordinatesArray[0], this._coordinatesArray[1], s)
    if (isSimpleStroke(this._stroke))
      this._stroke._strokeViewPath(t, this._coordinatesArray, r)
    else
      this._stroke.paintPathMapCoords(t, this._coordinates, o, this._close, s)
  }
  interacts(t, o) {
    let s = false
    if (this.hasFill()) {
      const o = t.focusPoint
      s = ringContainsXY2DFlatCoordList(this._coordinates, o.x, o.y)
    }
    return s || this.strokeInteracts(t, o)
  }
  getMaximumPadding(t) {
    return Math.ceil(this._stroke.getStrokeWidth() / 2)
  }
  strokeInteracts(t, o) {
    return CommandUtil.lineInteractsFlat(
      this._coordinates,
      t,
      this._stroke.getStrokeWidth(),
      o
    )
  }
  mapAnchorPointSFCT(t, o) {
    return CoordinateArrayUtil.mapAnchorPointSFCT(this._coordinatesGp, true, o)
  }
  mapAnchorPointsSFCT(t, o, s) {
    if (!this.bounds) return
    t.getViewBounds(viewBounds)
    CoordinateArrayUtil.mapAnchorPointsSFCT(
      this._coordinatesGp,
      this.bounds,
      viewBounds,
      t.mapToViewTransformationInternal,
      s,
      o
    )
  }
  getMapDistanceSquared(t, o, s, i) {
    i.clear()
    i.setDistanceToBounds(this.worldBounds, o, s)
    i.setDistanceToEdge(squaredDistanceToLineFlat(this._coordinates, o, s))
    if (ringContainsXY2DFlatCoordList(this._coordinates, o, s))
      i.setDistanceToEdge(-i.sqrDistanceToEdge)
  }
}
function isSimpleStroke(t) {
  return void 0 !== t._strokeViewPath
}
