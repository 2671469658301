import { Lang } from '../../../../../util/Lang.js'
import { cloneDeep } from '../../../../../util/JSON.js'
export class Serializable {
  static serialize(e) {
    const i = {}
    let r
    for (const n in e)
      if (e.hasOwnProperty(n)) {
        r = serialize(e[n])
        if ('undefined' !== typeof r) i[n] = r
      }
    return i
  }
  constructor() {}
  serialize() {
    return Serializable.serialize(this)
  }
}
function serialize(e) {
  if (null === e) return null
  if ('undefined' === typeof e) return 'undefined'
  if (Lang.isFunction(e.serialize)) return e.serialize()
  if (Lang.isFunction(e.map)) return e.map(serialize)
  try {
    return cloneDeep(e)
  } catch (e) {
    const i = undefined
    return {
      message: `could not parse pattern: ${
        e instanceof Error ? e.message : ''
      }`,
    }
  }
}
