import { ShapeType } from '../../../shape/ShapeType.js'
import { PointTranslateHandle } from '../handles/PointTranslateHandle.js'
import { PointCreateHandle } from '../handles/PointCreateHandle.js'
import { Editor } from '../Editor.js'
export class PointEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return e.shape.type === ShapeType.POINT
  }
  createTranslateHandle(e) {
    return new PointTranslateHandle(e.shape)
  }
  getCreateHandle(e) {
    const t = e.shape
    return new PointCreateHandle(t, (e) => t.move2DToPoint(e))
  }
}
