import { Log } from '../../util/Log.js'
import { isNumber, isUndefined } from '../../util/Lang.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import {
  createDrawComposite,
  createDrawIcon,
  createDrawNull,
  createDrawShape,
  createDrawText,
} from './HTML5DrawCommands.js'
import { isShape } from '../../shape/Shape.js'
import { isPoint } from '../../shape/Point.js'
import { createPoint } from '../../shape/ShapeFactory.js'
export class HTML5GeoCanvas {
  constructor(r) {
    if (isUndefined((r = r || {}).worldReference))
      throw new ProgrammingError(
        'A world reference should be provided at construction'
      )
    this._drawCommandOptions = {
      worldReference: r.worldReference,
      invalidateAction: r.invalidateAction || (() => {}),
      worldSizeSupport: r.worldSizeSupport,
    }
    this._drawCommands = []
    this._shouldLog3DIconWarning = true
    this._shouldPanoramaWarning = true
    this._processed = false
  }
  reset() {
    this._drawCommands.length = 0
    this._processed = false
  }
  get worldReference() {
    return this._drawCommandOptions.worldReference
  }
  _didProcessing() {
    return this._processed
  }
  buildDrawCommand() {
    const r = this._drawCommands.length
    if (1 === r) {
      this._processed = true
      return this._drawCommands[0]
    }
    if (r > 1) {
      this._processed = true
      return createDrawComposite(this._drawCommands)
    }
    return createDrawNull()
  }
  drawIcon(r, e) {
    const o = this.getPointFromShape(r)
    if (o) createDrawIcon(o, e, this._drawCommandOptions, this._drawCommands)
  }
  drawText(r, e, o) {
    let a = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0
    const t = this.getPointFromShape(r)
    if (t)
      createDrawText(
        t,
        e,
        o,
        this._drawCommandOptions.worldReference,
        this._drawCommands,
        a
      )
  }
  getPointFromShape(r) {
    if (!r) return null
    if (isShape(r)) return isPoint(r) ? r : r.focusPoint
    else if (isNumber(r[0]) && isNumber(r[1]))
      return createPoint(this._drawCommandOptions.worldReference, r)
    return null
  }
  drawIcon3D(r, e) {
    if (this._shouldLog3DIconWarning) {
      Log.warn(
        'drawIcon3D function is not supported in a software-rendered Canvas view.'
      )
      this._shouldLog3DIconWarning = false
    }
    this.drawIcon(r, {})
  }
  drawPanorama(r, e, o) {
    if (this._shouldPanoramaWarning) {
      Log.warn(
        'drawPanorama function is not supported in a software-rendered Canvas view.'
      )
      this._shouldPanoramaWarning = false
    }
  }
  drawShape(r, e) {
    if (r) createDrawShape(r, e, this._drawCommandOptions, this._drawCommands)
  }
}
