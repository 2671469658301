import { Camera } from './Camera.js'
import { Constants } from '../../util/Constants.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Axis } from '../../reference/Axis.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
import { Vector3 as Vector3Int } from '../../geometry/mesh/math/Vector3.js'
import { Matrix4 } from '../../geometry/mesh/math/Matrix4.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { normalizeAngle0To360 } from '../../util/Cartesian.js'
import { PerspectiveCamera } from './PerspectiveCamera.js'
function shouldFlipY(t) {
  return t.getAxis(Axis.Name.Y).direction === Axis.Direction.DISPLAY_DOWN
}
class OrthographicCamera extends Camera {
  constructor(t, e, r, o, i, n, s, h, a, l) {
    super(t, e, r, o, i, n, s, l)
    h = h || n
    a = a || s
    this._worldWidth = h
    this._worldHeight = a
    this._flipY = shouldFlipY(l)
  }
  get worldWidth() {
    return this._worldWidth
  }
  set worldWidth(t) {
    throw new ProgrammingError('worldWidth property is not mutable.')
  }
  get worldHeight() {
    return this._worldHeight
  }
  set worldHeight(t) {
    throw new ProgrammingError('worldHeight property is not mutable.')
  }
  getScaleAt(t) {
    return this.height / this.worldHeight
  }
  computeProjectionMatrix(t) {
    const e = this.worldWidth
    const r = this.worldHeight
    const o = -e / 2
    const i = e / 2
    const n = r / 2
    const s = -r / 2
    t.orthoGLMatrixJS(o, i, n, s, this.near, this.far)
  }
  copy() {
    const t = new OrthographicCamera(
      this.eye,
      this.forward,
      this.up,
      this.near,
      this.far,
      this.width,
      this.height,
      this.worldWidth,
      this.worldHeight,
      this.worldReference
    )
    this._copyLook2DInternalIfPossible(t)
    return t
  }
  _copyLook2DInternalIfPossible(t) {
    if (this._look2DInternal) {
      const e = undefined
      if (
        0 === t.forward.x &&
        0 === t.forward.y &&
        (-1 === t.forward.z || 1 === t.forward.z) &&
        t.eye.x === this.eye.x &&
        t.eye.y === this.eye.y &&
        t.up.x === this.up.x &&
        t.up.y === this.up.y &&
        t.worldWidth === this.worldWidth &&
        t.worldHeight === this.worldHeight
      )
        t._look2DInternal = {
          viewOrigin: new Vector3Int(
            this._look2DInternal.viewOrigin.x,
            this._look2DInternal.viewOrigin.y,
            this._look2DInternal.viewOrigin.z
          ),
          worldOrigin: new Vector3Int(
            this._look2DInternal.worldOrigin.x,
            this._look2DInternal.worldOrigin.y,
            this._look2DInternal.worldOrigin.z
          ),
          scaleX: this._look2DInternal.scaleX,
          scaleY: this._look2DInternal.scaleY,
          rotation: this._look2DInternal.rotation,
          sinRot: this._look2DInternal.sinRot,
          cosRot: this._look2DInternal.cosRot,
          YFlip: this._look2DInternal.cosRot,
        }
    }
  }
  copyAndSet(t) {
    const e = new OrthographicCamera(
      'undefined' !== typeof t.eye ? t.eye : this.eye,
      'undefined' !== typeof t.forward ? t.forward : this.forward,
      'undefined' !== typeof t.up ? t.up : this.up,
      'undefined' !== typeof t.near ? t.near : this.near,
      'undefined' !== typeof t.far ? t.far : this.far,
      'undefined' !== typeof t.width ? t.width : this.width,
      'undefined' !== typeof t.height ? t.height : this.height,
      'undefined' !== typeof t.worldWidth ? t.worldWidth : this.worldWidth,
      'undefined' !== typeof t.worldHeight ? t.worldHeight : this.worldHeight,
      'undefined' !== typeof t.worldReference
        ? t.worldReference
        : this.worldReference
    )
    this._copyLook2DInternalIfPossible(e)
    return e
  }
  equals(t) {
    if (!(t instanceof OrthographicCamera)) return false
    return (
      Math.abs(this._eye.x - t._eye.x) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._eye.y - t._eye.y) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._eye.z - t._eye.z) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._forward.x - t._forward.x) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._forward.y - t._forward.y) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._forward.z - t._forward.z) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._up.x - t._up.x) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._up.y - t._up.y) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._up.z - t._up.z) <= Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      this._near === t._near &&
      this._far === t._far &&
      this._width === t._width &&
      this._height === t._height &&
      this._worldWidth === t._worldWidth &&
      this._worldHeight === t._worldHeight &&
      this._worldReference.equals(t._worldReference)
    )
  }
  toString() {
    return `OrthographicCamera: \n\teye: [${this._eye.x}, ${this._eye.y}, ${this._eye.z}]\n\tforward: [${this._forward.x}, ${this._forward.y}, ${this._forward.z}]\n\tup: [${this._up.x}, ${this._up.y}, ${this._up.z}]\n\tworldWidth: ${this._worldWidth}\n\tworldHeight: ${this._worldHeight}\n\twidth: ${this._width}\n\theight: ${this._height}\n\treference: ${this._worldReference.identifier}\n`
  }
  _determineWorldPointAtCenter(t, e) {
    const r = this.width / 2
    const o = this.height / 2
    const i = r - t.viewOrigin.x
    const n = o - t.viewOrigin.y
    const s = Math.cos(t.rotation * Constants.DEG2RAD)
    const h = Math.sin(t.rotation * Constants.DEG2RAD)
    let a = i
    let l = n
    if (0 !== t.rotation) {
      a = i * s + n * -h
      l = i * h + n * s
    }
    const c = t.scaleX
    let d = t.scaleY
    if (e) d *= -1
    const w = a / c
    const f = l / -d
    let _ = t.worldOrigin.x
    let g = t.worldOrigin.y
    if (t.reference && !t.reference.equals(this.worldReference)) {
      const e = createTransformation(t.reference, this.worldReference)
      const r = createPoint(t.reference, [
        t.worldOrigin.x,
        t.worldOrigin.y,
        t.worldOrigin.z,
      ])
      const o = e.transform(r)
      _ = o.x
      g = o.y
    }
    const p = undefined
    const D = undefined
    return new Vector3Int(
      w + _,
      f + g,
      (e ? -1 : 1) * GRID2D_CAM_HEIGHT_ABOVE_GRID
    )
  }
  look2D(t) {
    return this._look2D(t)
  }
  _look2D = (() => {
    const t = new Matrix4()
    const e = new Vector3Int()
    const r = new Vector3Int(0, 1, 0)
    const o = new Vector3Int(0, 0, -1)
    return (i) => {
      if (!i)
        throw new ProgrammingError(
          'OrtographicCamera.look2D: look2D argument missing'
        )
      if (
        Math.abs(i.rotation) > Constants.ABSOLUTE_ANGLE_TOLERANCE &&
        Math.abs(i.scaleX - i.scaleY) > Constants.ABSOLUTE_DISTANCE_TOLERANCE
      )
        throw new ProgrammingError(
          'OrthographicCamera.look2D: Cannot combine rotation with non-uniform scaling'
        )
      const n = shouldFlipY(this.worldReference)
      e.set(0, 0, (n ? -1 : 1) * -i.rotation * Constants.DEG2RAD)
      t.makeRotationFromEuler(e, 'ZYX')
      const s = this._determineWorldPointAtCenter(i, n)
      r.set(0, n ? -1 : 1, 0)
      r.transformDirection(t)
      o.set(0, 0, n ? 1 : -1)
      const h = this.width / i.scaleX
      const a = this.height / i.scaleY
      const l = new OrthographicCamera(
        s,
        o,
        r,
        GRID2D_CAM_NEAR,
        GRID2D_CAM_FAR,
        this.width,
        this.height,
        h,
        a,
        this.worldReference
      )
      l._look2DInternal = {
        viewOrigin: new Vector3Int(
          i.viewOrigin.x,
          i.viewOrigin.y,
          i.viewOrigin.z
        ),
        worldOrigin: new Vector3Int(
          i.worldOrigin.x,
          i.worldOrigin.y,
          i.worldOrigin.z
        ),
        scaleX: i.scaleX,
        scaleY: i.scaleY,
        rotation: i.rotation,
        cosRot: Math.cos(i.rotation * Constants.DEG2RAD),
        sinRot: Math.sin(i.rotation * Constants.DEG2RAD),
        YFlip: n ? -1 : 1,
      }
      return l
    }
  })()
  asLook2D() {
    return this._asLook2D()
  }
  _asLook2D = (() => {
    const t = new Vector3Int()
    const e = new Vector3Int()
    return () => {
      const r = this.worldReference.TYPE
      if (r !== ReferenceType.GRID && r !== ReferenceType.CARTESIAN)
        throw new ProgrammingError(
          "OrthographicCamera.asLook2D can only be called for camera's that have" +
            ' a grid (projected) reference or a cartesian reference as worldReference'
        )
      if (this._look2DInternal)
        return {
          viewOrigin: new Vector3Int(
            this._look2DInternal.viewOrigin.x,
            this._look2DInternal.viewOrigin.y,
            this._look2DInternal.viewOrigin.z
          ),
          worldOrigin: new Vector3Int(
            this._look2DInternal.worldOrigin.x,
            this._look2DInternal.worldOrigin.y,
            this._look2DInternal.worldOrigin.z
          ),
          scaleX: this._look2DInternal.scaleX,
          scaleY: this._look2DInternal.scaleY,
          rotation: this._look2DInternal.rotation,
          reference: this.worldReference,
        }
      t.copy(this.up)
      e.set(0, this._flipY ? -1 : 1, 0)
      let o
      if (this._flipY)
        o = (Math.atan2(-e.y, e.x) - Math.atan2(-t.y, t.x)) * Constants.RAD2DEG
      else o = (Math.atan2(e.y, e.x) - Math.atan2(t.y, t.x)) * Constants.RAD2DEG
      const i = this.width / 2
      const n = this.height / 2
      const s = this.eye.x
      const h = this.eye.y
      return {
        viewOrigin: new Vector3Int(i, n, 0),
        worldOrigin: new Vector3Int(s, h, 0),
        scaleX: this.width / this.worldWidth,
        scaleY: this.height / this.worldHeight,
        rotation: normalizeAngle0To360(o, Constants.ABSOLUTE_ANGLE_TOLERANCE),
        reference: this.worldReference,
      }
    }
  })()
  asLookAt(t) {
    return new PerspectiveCamera(
      this.eye,
      this.forward,
      this.up,
      1,
      10,
      this.width,
      this.height,
      60,
      this.worldReference
    ).asLookAt(t)
  }
  lookAt(t) {
    const e = new PerspectiveCamera(
      this.eye,
      this.forward,
      this.up,
      1,
      10,
      this.width,
      this.height,
      60,
      this.worldReference
    ).lookAt(t)
    return new OrthographicCamera(
      e.eye,
      e.forward,
      e.up,
      this.near,
      this.far,
      this.width,
      this.height,
      this.worldWidth,
      this.worldHeight,
      this.worldReference
    )
  }
  asLookFrom() {
    return new PerspectiveCamera(
      this.eye,
      this.forward,
      this.up,
      1,
      10,
      this.width,
      this.height,
      60,
      this.worldReference
    ).asLookFrom()
  }
  lookFrom(t) {
    const e = new PerspectiveCamera(
      this.eye,
      this.forward,
      this.up,
      1,
      10,
      this.width,
      this.height,
      60,
      this.worldReference
    ).lookFrom(t)
    return new OrthographicCamera(
      e.eye,
      e.forward,
      e.up,
      this.near,
      this.far,
      this.width,
      this.height,
      this.worldWidth,
      this.worldHeight,
      this.worldReference
    )
  }
  static createDefault2DCamera(t, e, r) {
    const o = t.TYPE
    if (o === ReferenceType.GRID || o === ReferenceType.CARTESIAN) {
      let o = 1
      if (t.bounds) o = (e || 1) / t.bounds.width
      const i = e / o
      const n = r / o
      const s = new Vector3Int(0, 0, -1)
      const h = new Vector3Int(0, 1, 0)
      if (shouldFlipY(t)) {
        h.set(0, -1, 0)
        s.set(0, 0, 1)
      }
      const a = new OrthographicCamera(
        new Vector3Int(0, 0, GRID2D_CAM_HEIGHT_ABOVE_GRID),
        s,
        h,
        GRID2D_CAM_NEAR,
        GRID2D_CAM_FAR,
        e,
        r,
        i,
        n,
        t
      )
      if (0 === e || 0 === r) {
        const e = {
          viewOrigin: { x: 0, y: 0, z: 0 },
          worldOrigin: { x: 0, y: 0, z: 0 },
          scaleX: 1,
          scaleY: 1,
          rotation: 0,
          reference: t,
        }
        return a.look2D(e)
      }
      return a
    }
    if (o === ReferenceType.GEOCENTRIC)
      return new OrthographicCamera(
        new Vector3Int(0, 0, 0),
        new Vector3Int(0, 1, 0),
        new Vector3Int(0, 0, -1),
        GRID2D_CAM_NEAR,
        GRID2D_CAM_FAR,
        e,
        r,
        e,
        r,
        t
      )
    throw new ProgrammingError(
      `Can not create OrthographicCamera for reference: ${t.identifier}`
    )
  }
}
const GRID2D_CAM_NEAR = -4e7
const GRID2D_CAM_FAR = 4e7
const GRID2D_CAM_HEIGHT_ABOVE_GRID = 9e3
export { OrthographicCamera }
