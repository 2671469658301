import { SEElementType } from './SEElementType.js'
import { SEElement } from './SEElement.js'
export class XPath extends SEElement {
  _qNames = []
  constructor(t) {
    super(SEElementType.XPATH)
    if ('string' !== typeof t)
      throw new Error(
        'XPath: must be constructed with a string as first argument'
      )
    this._origXPath = t
    this._root = this._origXPath.match(/^\/\//)
      ? '//'
      : this._origXPath.match(/^\//)
      ? '/'
      : ''
    const e = t.replace(/^\/*/, '').replace(/\/\//g, '/')
    if ('' === e) throw new Error('Invalid XPath. needs at least single part')
    const r = e.split('/')
    for (const t of r) this._qNames.push(new SEQName(t))
  }
  get qNames() {
    return this._qNames
  }
  toXPathString() {
    if (1 === this._qNames.length)
      return this._root + this._qNames[0].toFullyQualifiedString()
    return this._qNames.reduce(accumulateQNames, '/')
  }
}
export class SEQName {
  constructor(t) {
    const e = t.split(':')
    if (1 === e.length) this._localPart = e[0]
    else if (2 === e.length) {
      this._prefix = e[0]
      this._localPart = e[1]
    } else
      throw new Error(
        "Unsupported QName: must be of format 'prefix:localPart' or 'localPart'"
      )
  }
  get prefix() {
    return this._prefix
  }
  get localPart() {
    return this._localPart
  }
  toFullyQualifiedString() {
    return 'string' === typeof this._prefix
      ? this._prefix + ':' + this._localPart
      : this._localPart
  }
}
function accumulateQNames(t, e) {
  return t + '/' + e.toFullyQualifiedString()
}
