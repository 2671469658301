import { VirtualClipPolylineUtil } from './VirtualClipPolylineUtil.js'
import { ClipSectionUtil } from './ClipSectionUtil.js'
import { PathIterator } from '../complexstroke/PathIterator.js'
import { XYZPoint } from '../../../shape/XYZPoint.js'
const pathIterator = new PathIterator([0, 0, 0, 0], 0, 4)
const clippingVirtual = new VirtualClipPolylineUtil()
const tmpInPoint = new XYZPoint(null, [0, 0, 0])
const tmpOutPoint1 = new XYZPoint(null, [0, 0, 0])
const tmpOutPoint2 = new XYZPoint(null, [0, 0, 0])
const MAX_LABEL_COUNT = 100
export function computeRepeatingAnchors(t, n, o, i, r, e, a) {
  if (o <= 0) return
  pathIterator.reload(t, 0, n)
  const c = undefined
  const l = getPathSections(t, n, o, i, r, pathIterator.totalLength())
  if (!l || 0 === l.length) return
  findAnchors(t, n, l, o, e, a)
}
function getPathSections(t, n, o, i, r, e) {
  if (!i) return r < e ? [[r, e]] : null
  const a = clippingVirtual.run(t, n, i)
  if (!a || 0 === a.clippedSections.length) return null
  const c = a.clippedSections
  return ClipSectionUtil.getVisibleSectionsWithLimit(c, r, o, e)
}
function findAnchors(t, n, o, i, r, e) {
  for (const t of o) {
    const n = t[0]
    const o = t[1]
    const a = n - pathIterator.distanceFromStart()
    pathIterator.advanceDistance(a)
    addAnchor(r, e)
    while (
      !pathIterator.atEnd() &&
      pathIterator.distanceFromStart() + i < o &&
      r.anchorPointsLength < MAX_LABEL_COUNT
    ) {
      pathIterator.advanceDistance(i)
      addAnchor(r, e)
    }
  }
}
function addAnchor(t, n) {
  try {
    txViewToWorld(n, pathIterator.x(), pathIterator.y(), tmpOutPoint1)
    txViewToWorld(n, pathIterator.nextX(), pathIterator.nextY(), tmpOutPoint2)
    t.add(tmpOutPoint1.x, tmpOutPoint1.y, tmpOutPoint2.x, tmpOutPoint2.y)
  } catch (t) {}
}
function txViewToWorld(t, n, o, i) {
  tmpInPoint.x = n
  tmpInPoint.y = o
  t.inverseTransformation.transform(tmpInPoint, i)
}
