import { XML } from '../../util/XML.js'
import { VersionNegotiator } from './common/VersionNegotiator.js'
function parseVersion(e) {
  var r = XML.stringToXml(e)
  var t = null
  try {
    t = r.getElementsByTagNameNS('*', 'Capabilities')[0].getAttribute('version')
  } catch (e) {}
  return t
}
function createVersionNegotiator(e, r, t, o) {
  return new VersionNegotiator(
    e,
    'LTS',
    parseVersion,
    r || ['1.1.1'],
    t || null,
    o || {}
  )
}
export var LTSCapabilitiesUtil = {
  parseVersion: parseVersion,
  createVersionNegotiator: createVersionNegotiator,
}
