import { WrapperPattern } from './WrapperPattern.js'
import { PatternType } from './util/PatternType.js'
import { isBoolean } from '../../../../util/Lang.js'
import { EPSILON } from './util/PatternUtil.js'
export class RepeatOverLengthPattern extends WrapperPattern {
  constructor(t, e, a) {
    super(t, PatternType.REPEAT_OVER_LENGTH)
    this._distance = e
    this._relative = isBoolean(a) ? a : false
    this.atomic = false
  }
  get distance() {
    return this._distance
  }
  get relative() {
    return this._relative
  }
  getWidth(t) {
    return this._relative ? this._distance * t : this._distance
  }
  paintOnceOnLine(t, e, a) {
    const n = e.totalLength()
    const i = this.pattern.getWidth(n)
    const r = this.getWidth(n)
    if (this.atomic)
      if (!this.canPaintAtomic(t, e, a)) {
        const n = Math.min(e.distanceFromStart() + i, a[1])
        this.drawFallback(t, e, n, a)
        return true
      }
    const s = e.distanceFromStart() + r
    let o = e.distanceFromStart()
    let c = true
    let h
    while (c && !e.atEnd() && o + i <= s) {
      this.pattern.paintOnceOnLine(t, e, a)
      h = e.distanceFromStart()
      c = h > o
      o = e.distanceFromStart()
    }
    return true
  }
  canPaintAtomic(t, e, a) {
    const n = e.totalLength()
    const i = this.getWidth(n)
    const r = undefined
    if (e.distanceFromStart() + i > n + EPSILON) return false
    const s = this.pattern
    const o = s.getWidth(n)
    const c = o ? Math.floor(i / o) : 0
    e.saveState()
    let h = 0
    let p = true
    while (p && h < c) {
      p = p && s.canPaintOnce(e, a)
      if (p) e.advanceDistance(o)
      h++
    }
    e.restoreState()
    return p
  }
  appendHash(t) {
    t.appendDouble(this._distance)
    t.appendBoolean(this._relative)
    super.appendHash(t)
  }
}
