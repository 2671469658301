import { parse } from '../util/JSON.js'
import { Copyright } from '../util/Copyright.js'
function ResourceOverlay(e, t) {
  var r = document.createElement('div')
  r.style.zIndex = 5e3
  r.style.color = 'white'
  r.style.textShadow = '1px 1px #555'
  r.style.margin = 0
  r.style.border = 0
  r.style.padding = 0
  r.style.display = 'inline-block'
  r.style.position = 'absolute'
  r.style.bottom = 0
  r.style.left = 0
  e.appendChild(r)
  this._div = r
  this._filter = t
  this._regex = new RegExp(t, 'i')
  this._sources = []
}
ResourceOverlay.prototype = Object.create(Object.prototype)
ResourceOverlay.prototype.constructor = ResourceOverlay
ResourceOverlay.prototype.registerSource = function (e) {
  this._sources.push(e)
}
ResourceOverlay.prototype.update = function () {
  var e = {}
  this._sources.forEach((t) => {
    e = { ...e, ...t() }
  })
  var t = {}
  var r
  for (r in e) {
    if (!r.match(this._regex)) continue
    var o = e[r]
    var s = r.replace(/^.*\./, '')
    if ('count' === s || 'size' === s) r = r.replace(/\.[^\.]*?$/, '')
    if ('latency' === s || 'throughput' === s) r = r.replace(/\.[^\.]*?$/, '')
    if (o) {
      if ('' + o === '' + Number(o)) o = Number(o)
      var i = ''
      if ('size' === s) {
        i = 'B'
        if (Math.abs(o) > 10 * 1024 * 1024) {
          o /= 1024 * 1024
          i = 'M' + i
        } else if (Math.abs(o) > 10 * 1024) {
          o /= 1024
          i = 'K' + i
        }
        o = o.toFixed(0)
      } else if ('latency' === s) i = 'ms'
      else if ('throughput' === s) i = '/s'
      else if ('number' === typeof o && o === Math.round(o)) {
        if (Math.abs(o) > 1e7) {
          o /= 1e6
          i = 'M'
        } else if (Math.abs(o) > 1e4) {
          o /= 1e3
          i = 'K'
        }
        o = o.toFixed(0)
      }
      if (!t[r]) t[r] = ''
      t[r] = t[r] + ' &emsp; ' + o + ' ' + i
    }
  }
  var a = '<table>'
  var l = []
  for (r in t) l.push(r)
  l.sort()
  for (var p = 0; p < l.length; p++) {
    var r
    var c
    var u
    a +=
      '<tr><td>' +
      ('' + (r = l[p]).replace('luciad.', '')) +
      '</td><td>' +
      t[r] +
      '</td></tr>'
  }
  a += '</table>'
  this._div.innerHTML = a
}
export { ResourceOverlay }
