import { MultiPattern } from './MultiPattern.js'
import { PatternType } from './util/PatternType.js'
import { EPSILON } from './util/PatternUtil.js'
export class AppendPattern extends MultiPattern {
  constructor(t) {
    super(t, PatternType.APPEND)
    let e = true
    for (let n = 0; n < t.length; n++)
      if (e && !t[n].canBend) {
        e = false
        break
      }
    this.canBend = e
  }
  getWidth(t) {
    let e = 0
    for (let n = 0; n < this.patterns.length; n += 1)
      e += this.patterns[n].getWidth(t)
    return e
  }
  paint(t, e, n, a) {
    let i = 0
    for (let a = 0; a < this.patterns.length; a += 1) {
      t.save()
      t.translate(i, 0)
      this.patterns[a].paint(t, e, n, void 0)
      i += this.patterns[a].getWidth(e)
      t.restore()
    }
  }
  paintPartial(t, e, n, a) {
    const i = e.totalLength()
    let r = e.distanceFromStart()
    let s = 0
    while (s < this.patterns.length && !e.atEnd()) {
      const h = this.patterns[s]
      r += h.getWidth(i)
      if (r > n) {
        h.paintPartial(t, e, n, a)
        break
      }
      h.paintOnceOnLine(t, e, a)
      s += 1
    }
  }
  paintOnceOnLine(t, e, n) {
    const a = e.totalLength()
    const i = this.getWidth(a)
    let r = 0
    if (this.atomic)
      if (!this.canPaintAtomic(t, e, n)) {
        const a = Math.min(e.distanceFromStart() + i, n[1])
        this.drawFallback(t, e, a, n)
        return true
      }
    let s = true
    while (r < this.patterns.length && !e.atEnd()) {
      s = this.patterns[r].paintOnceOnLine(t, e, n) && s
      r += 1
    }
    return s
  }
  canPaintAtomic(t, e, n) {
    const a = e.totalLength()
    const i = undefined
    const r = undefined
    if (this.getWidth(a) > e.distanceToEnd() + EPSILON) return false
    e.saveState()
    let s = true
    let h = 0
    while (s && h < this.patterns.length) {
      const t = this.patterns[h]
      s = s && t.canPaintOnce(e, n)
      if (s) e.advanceDistance(t.getWidth(a))
      h++
    }
    e.restoreState()
    return s
  }
  appendHash(t) {
    t.appendString('append')
    super.appendHash(t)
  }
}
