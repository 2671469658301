import { ComposedShapeEditor } from './ComposedShapeEditor.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { EditContext } from '../../controller/EditContext.js'
import { isConstrainedPolygon } from '../../../shape/ConstrainedPolygon.js'
import { isConstrainedPolyline } from '../../../shape/ConstrainedPolyline.js'
import { ConstrainedPointListForCreation } from '../../../shape/ConstrainedPointListForCreation.js'
import { CompositeEditHandle } from '../handles/CompositeEditHandle.js'
import { mergeMinMaxPointCount } from '../../controller/EditSettings.js'
export class ShapeListEditor extends ComposedShapeEditor {
  constructor(e) {
    super(ShapeType.SHAPE_LIST, e)
  }
  getSubShapeCount(e) {
    return e.shapeCount
  }
  getSubShape(e, t) {
    return e.getShape(t)
  }
  getCreateHandle(e) {
    const t = []
    const o = e.shape
    let n = e.settings
    for (let i = 0; i < this.getSubShapeCount(o); i++) {
      let r = this.getSubShape(o, i)
      const a = new EditContext(e.map, e.layer, e.feature, r, n)
      if (r.type === ShapeType.POINT)
        t.unshift(this.delegate.getCreateHandle(a))
      else {
        if (isConstrainedPolygon(r) || isConstrainedPolyline(r)) {
          n = { ...mergeMinMaxPointCount(n, r), freehand: n.freehand }
          if (r.type === ShapeType.POLYLINE)
            r = ConstrainedPointListForCreation.createPolyline(r, 0)
          else if (r.type === ShapeType.POLYGON)
            r = ConstrainedPointListForCreation.createPolygon(r, 0)
        }
        const o = new EditContext(e.map, e.layer, e.feature, r, n)
        t.push(this.delegate.getCreateHandle(o))
      }
    }
    return new CompositeEditHandle(t, { cascading: true })
  }
}
