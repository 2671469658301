import { buildCache } from '../util/Cacher.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import * as DistanceUtil from '../geometry/DistanceUtil.js'
import * as XYCircleUtil from './XYCircleUtil.js'
import { ShapeType } from './ShapeType.js'
import { CircularArcByBulge } from './CircularArcByBulge.js'
import { XYZBounds } from './XYZBounds.js'
import * as XYZCircularArcUtil from './XYZCircularArcUtil.js'
import { XYZPoint } from './XYZPoint.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { distance2D } from '../util/Cartesian.js'
export class XYZCircularArcByBulge extends CircularArcByBulge {
  constructor(t, e, r, i) {
    super()
    this._reference = this.validateReference(t)
    if (e && e.type === ShapeType.POINT) {
      this._compareReferenceStrict(
        e.reference,
        'XYZCircularArcByBulge::the arc reference must match the start point reference'
      )
      this._startPoint = e.copy()
    } else
      throw new ProgrammingError(
        'XYZCircularArcByBulge::cannot create an arc without a start point'
      )
    if (r && r.type === ShapeType.POINT) {
      this._compareReferenceStrict(
        r.reference,
        'XYZCircularArcByBulge::the arc reference must match the end point reference'
      )
      this._endPoint = r.copy()
    } else
      throw new ProgrammingError(
        'XYZCircularArcByBulge::cannot create an arc without an end point'
      )
    if ('number' !== typeof i)
      throw new ProgrammingError(
        'XYZCircularArcByBulge::cannot create an arc without a bulge'
      )
    this._bulge = i
    this._cache = buildCache()
  }
  get isGeodetic() {
    return false
  }
  get startPoint() {
    return this._startPoint
  }
  get endPoint() {
    return this._endPoint
  }
  get bulge() {
    return this._bulge
  }
  set bulge(t) {
    this._bulge = t
    this.invalidate()
  }
  get coordinateType() {
    return this._reference
      ? this._reference.coordinateType
      : CoordinateType.CARTESIAN
  }
  get focusPoint() {
    return this._cache.memoize(
      'cached-focus-point',
      () =>
        new XYZPoint(this._reference, [
          (this.startPoint.x + this.endPoint.x) / 2,
          (this.startPoint.y + this.endPoint.y) / 2,
          (this.startPoint.z + this.endPoint.z) / 2,
        ])
    )
  }
  get center() {
    const t = this._cache
    return this._cache.memoize('cached-center-point', () => {
      const e = new XYZPoint(this.reference)
      XYZCircularArcUtil.getMidPoint(this, e)
      const r = new XYZPoint(this.reference)
      const i = XYCircleUtil.findCenter(this.startPoint, e, this.endPoint, r)
      t.cache('cached-radius', i)
      return r
    })
  }
  get radius() {
    const t = this._cache
    return this._cache.memoize('cached-radius', () => {
      const e = new XYZPoint(this.reference)
      XYZCircularArcUtil.getMidPoint(this, e)
      const r = new XYZPoint(this.reference)
      const i = XYCircleUtil.findCenter(this.startPoint, e, this.endPoint, r)
      t.cache('cached-center-point', r)
      return i
    })
  }
  get startAzimuth() {
    return this._cache.memoize('cached-start-azimuth', () =>
      XYZCircularArcUtil.getStartAngle3Points(this.center, this.startPoint)
    )
  }
  get sweepAngle() {
    return this._cache.memoize('cached-arc-angle', () => {
      const t = new XYZPoint(this.reference)
      XYZCircularArcUtil.getMidPoint(this, t)
      return XYZCircularArcUtil.getArcAngle3Points(
        this.center,
        this.startPoint,
        t,
        this.endPoint
      )
    })
  }
  get bounds() {
    return this._cache.memoize('bounds', () => {
      const t = new XYZBounds(this.reference)
      XYZCircularArcUtil.boundsSFCT(this, t)
      return t
    })
  }
  contains2DCoordinates(t, e) {
    return XYZCircularArcUtil.contains2D(this, t, e)
  }
  moveStartPoint2DToCoordinates(t, e) {
    this.startPoint.move2DToCoordinates(t, e)
    this.invalidate()
  }
  moveEndPoint2DToCoordinates(t, e) {
    this.endPoint.move2DToCoordinates(t, e)
    this.invalidate()
  }
  translateStartPoint2D(t, e) {
    this.moveStartPoint2DToCoordinates(
      this.startPoint.x + t,
      this.startPoint.y + e
    )
  }
  translateEndPoint2D(t, e) {
    this.moveEndPoint2DToCoordinates(this.endPoint.x + t, this.endPoint.y + e)
  }
  invalidate() {
    this._cache.invalidate()
  }
  copy() {
    return new XYZCircularArcByBulge(
      this.reference,
      this.startPoint.copy(),
      this.endPoint.copy(),
      this.bulge
    )
  }
  calculateBulge(t) {
    const e = this.startPoint
    const r = this.endPoint
    const i = DistanceUtil.cartesianSignedDistanceToLine(t, e, r)
    const n = new XYZPoint(this.reference)
    n.move2DToCoordinates(e.x + 0.5 * (r.x - e.x), e.y + 0.5 * (r.y - e.y))
    const o = undefined
    return (-1 * i) / distance2D(e, n)
  }
  contains3DCoordinates(t, e, r) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape XYZCircularArcByBulge'
    )
  }
}
