import { ReferenceType } from '../reference/ReferenceType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { GeodeticReference } from '../reference/GeodeticReference.js'
import { createPoint } from '../shape/ShapeFactory.js'
export class GeodeticGeodesy {
  constructor(e) {
    this._reference = e
    let t
    if (
      e.referenceType === ReferenceType.GRID ||
      e.referenceType === ReferenceType.GEOCENTRIC
    )
      t = new GeodeticReference({ geodeticDatum: e.geodeticDatum })
    else if (e.referenceType === ReferenceType.GEODETIC) t = e
    else
      throw new ProgrammingError(
        `Unsupported reference type: ${e.referenceType}`
      )
    this._geodeticReference = t
    this._ellipsoid = t.geodeticDatum.ellipsoid
    this._calcToGeodetic = createTransformation(this.reference, t)
  }
  get reference() {
    return this._reference
  }
  get ellipsoid() {
    return this._ellipsoid
  }
  get geodeticReference() {
    return this._geodeticReference
  }
  distance(e, t, r) {
    const o = this.refToCalcRef(e)
    const c = this.calcRefToGeodetic(o)
    const i = this.refToCalcRef(t)
    const n = this.calcRefToGeodetic(i)
    return this.distanceImpl(this._ellipsoid, c, n, r)
  }
  forwardAzimuth(e, t, r) {
    const o = this.refToCalcRef(e)
    const c = this.calcRefToGeodetic(o)
    const i = this.refToCalcRef(t)
    const n = this.calcRefToGeodetic(i)
    return this.forwardAzimuthImpl(this._ellipsoid, c, n, r)
  }
  interpolate(e, t, r, o) {
    const c = this.refToCalcRef(e)
    const i = this.calcRefToGeodetic(c)
    const n = createPoint(this._geodeticReference, [0, 0])
    if ('number' === typeof t) {
      if (t < 0) {
        t = -t
        if (r > 180) r -= 180
        else r = 180 + r
      }
      this.interpolateDistanceAzimuthImpl(this._ellipsoid, i, t, r, o, n)
      n.z = e.z
    } else {
      const c = this.refToCalcRef(t)
      const s = this.calcRefToGeodetic(c)
      this.interpolateFractionImpl(this._ellipsoid, i, s, r, o, n)
      n.z = e.z + r * (t.z - e.z)
    }
    return this.geodeticToCalcRef(n)
  }
  refToCalcRef(e) {
    const t = e.reference
    if (!t || t == this.reference || t.equals(this.reference)) return e
    else {
      const r = undefined
      return createTransformation(t, this.reference).transform(e)
    }
  }
  calcRefToGeodetic(e) {
    const t = createPoint(this._geodeticReference, [0, 0])
    this._calcToGeodetic.transform(e, t)
    return t
  }
  geodeticToCalcRef(e) {
    const t = createPoint(this.reference, [0, 0])
    this._calcToGeodetic.inverseTransformation.transform(e, t)
    return t
  }
}
