import { ShapeTranslateHandle } from './handles/ShapeTranslateHandle.js'
import { DelegatingEditingGeoCanvas } from '../style/DelegatingEditingGeoCanvas.js'
export class Editor {
  constructor() {}
  canEdit(e) {
    return false
  }
  getEditHandles(e) {
    const t = this.createTranslateHandle(e)
    if (t) return [t]
    return []
  }
  createTranslateHandle(e) {
    const t = e.shape
    return new ShapeTranslateHandle(t)
  }
  getCreateHandle(e) {
    return null
  }
}
export function getConstrainedShape(e, t, n, a) {
  DelegatingEditingGeoCanvas.resetEditingGeometry()
  const r = new DelegatingEditingGeoCanvas()
  n.painter.paintBody(r, e, t, n, a, { selected: false, editing: true })
  return DelegatingEditingGeoCanvas.getEditingGeometry()
}
