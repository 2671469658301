import { Symbolizer } from './Symbolizer.js'
export class BodySymbolizer extends Symbolizer {
  static VALID_CHILDREN = new Array().concat(Symbolizer.VALID_CHILDREN, [
    'VendorOption',
  ])
  _vendorOptions = []
  constructor(e) {
    super(e)
  }
  getPerpendicularOffset(e, r, t) {
    return 0
  }
  isWorldSized() {
    return false
  }
  getBufferWidth(e, r, t) {
    return 1
  }
  createStrokeFillStyle(e, r) {
    return
  }
  createFillStyle(e, r, t) {
    return
  }
  createStrokeStyles(e, r, t) {
    return []
  }
  createIconStyles(e, r) {
    return []
  }
  createStyledTexts(e, r) {
    return []
  }
  get vendorOptions() {
    return this._vendorOptions
  }
  addVendorOption(e) {
    this._vendorOptions.push(e)
  }
}
