import { XYZPoint } from '../../shape/XYZPoint.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { TransformationType } from '../../transformation/TransformationType.js'
import { RasterTileSetModelDecoderDecorator } from '../../model/tileset/RasterTileSetModelDecoderDecorator.js'
import { Grid } from '../../model/tileset/Grid.js'
import { TileCoordinateUtil } from '../../model/tileset/TileCoordinateUtil.js'
import { createBounds, createPoint } from '../../shape/ShapeFactory.js'
import { Log } from '../../util/Log.js'
import { LinkedList } from '../../util/LinkedList.js'
import { LRUCache } from '../../util/LRUCache.js'
import { WorldViewTransformation2D } from '../../transformation/WorldViewTransformation2D.js'
import { TileCoordinate } from './TileCoordinate.js'
import { TileRequest } from './TileRequest.js'
import { Tile } from './Tile.js'
import { TileRecordType } from './TileRecordType.js'
import { SlidingTileWindow } from './SlidingTileWindow.js'
import { TileDrawingFrame } from './TileDrawingFrame.js'
import { DefaultTileRecord } from './DefaultTileRecord.js'
import { EmptyTileRecord } from './EmptyTileRecord.js'
import { coordKeyLXY, mapToLowerLevel } from './TileUtil.js'
import { TileComparator } from './TileComparator.js'
const DEBUG_RENDER_TILES = false
const DEBUG_LOG = false
const DEBUG_INTERMEDIATE_CANVAS = false
const MAX_TILES_TO_PROCESS_PER_FRAME = 2
const MAX_TILE_REQUESTS = 8
const TILE_CACHE_SIZE = 256
const ERROR_CACHE_SIZE = 2048
function coordKey(e) {
  return coordKeyLXY(e.level, e.x, e.y)
}
function tileEquals(e, t) {
  return e.coordinate.equals(t.coordinate)
}
function logcoord(e) {
  Log.debug(`    ${coordKey(e.coordinate)}`)
}
function logtile(e) {
  Log.debug(
    `    ${coordKey(e.requestCoordinate)} for ${coordKey(e.targetCoordinate)}`
  )
}
export class RasterTileSetLayerRenderer {
  constructor(e) {
    this._layer = e.layer
    this._tileset = e.model
    const t = e.model.bounds
    this._scrtScale = [0, 0]
    this._tilesToRequest = []
    this._tilesToCancel = []
    this._pendingRequests = new LinkedList(tileEquals)
    this._tilesInFlight = []
    this._requestsInFlight = 0
    this._pendingTiles = new LinkedList()
    this._forceTileRefresh = false
    const i = e.modelWorldTransformation._getType()
    if (
      i !== TransformationType.TYPE_SCALE &&
      i !== TransformationType.TYPE_IDENTITY
    )
      throw new ProgrammingError(
        'RasterTileSetLayer: cannot add a RasterTileSetLayer when the model reference is not identical to the Map reference.'
      )
    const s = e.modelWorldTransformation.transformBounds(t)
    this._xScaleFactor = s.width / t.width
    this._yScaleFactor = s.height / t.height
    this._tileCache = new LRUCache(TILE_CACHE_SIZE)
    this._errorCache = new LRUCache(ERROR_CACHE_SIZE)
    this._topTiles = {}
    this._slidingTileWindow = new SlidingTileWindow(
      this._tileset,
      this._tileCache,
      this._topTiles
    )
    this._tileDrawingFrame = new TileDrawingFrame(this._slidingTileWindow)
    this._intermediateCanvas = document.createElement('canvas')
    this._intermediateContext = this._intermediateCanvas.getContext('2d')
    this.disableImageSmoothing()
    if (DEBUG_INTERMEDIATE_CANVAS) {
      document.body.appendChild(this._intermediateCanvas)
      this._intermediateCanvas.style.position = 'absolute'
      this._intermediateCanvas.style.top = `${0.5 * window.innerHeight}px`
      this._intermediateCanvas.style.left = `${0.5 * window.innerWidth}px`
      this._intermediateCanvas.style.zIndex = `10000`
    }
    this._currentGridCoordinate = new Grid()
    this._tileComparator = new TileComparator()
    this._tileCompareFunction = this._tileComparator.compare.bind(
      this._tileComparator
    )
    this._topRequested = false
    const o = e.modelWorldTransformation.outputReference
    this._rotationLessWvt = new WorldViewTransformation2D(o)
    this._centeredWvt = new WorldViewTransformation2D(o)
    this._tempViewPoint = createPoint(null, [0, 0])
    this._tempViewBounds = createBounds(null, [0, 0, 0, 0])
    this._tempMapBounds = createBounds(o, [0, 0, 0, 0])
  }
  get tileset() {
    return this._tileset
  }
  get layer() {
    return this._layer
  }
  get intermediateContext() {
    return this._intermediateContext
  }
  static create(e, t) {
    for (let e = 1; e < t.levelCount; e++)
      if (
        t.getTileWidth(e) !== t.getTileWidth(0) ||
        t.getTileHeight(e) !== t.getTileHeight(0) ||
        t.getTileRowCount(e) !== (t.getTileRowCount(0) ?? 0) << e ||
        t.getTileColumnCount(e) !== (t.getTileColumnCount(0) ?? 0) << e
      )
        throw new ProgrammingError(
          'RasterTileSetLayer: Canvas Map only supports power-of-two quad-tree tile sets.'
        )
    const i = RasterTileSetModelDecoderDecorator.create(t)
    return new RasterTileSetLayerRenderer({
      layer: e,
      model: i,
      modelWorldTransformation: e.modelToWorldTransformation,
    })
  }
  logOutstanding() {
    if (DEBUG_LOG) {
      Log.debug('  OUTSTANDING TILES')
      Log.debug('  Pending requests')
      this._pendingRequests.forEach(logcoord, this)
      Log.debug('  Pending tiles')
      this._pendingTiles.forEach(logtile, this)
      Log.debug('  In flight')
      this._tilesInFlight.forEach(logcoord)
    }
  }
  requestTopLevel() {
    if (this._topRequested) return
    const e = this._tileset.getTileRowCount(0)
    const t = this._tileset.getTileColumnCount(0)
    for (let i = 0; i < e; i += 1)
      for (let e = 0; e < t; e += 1) {
        const t = new TileCoordinate(0, e, i)
        this.requestTile(this._tileset, new TileRequest(t), t)
      }
    this._topRequested = true
  }
  update() {
    return true
  }
  render(e, t, i) {
    const s = this._layer.modelToWorldTransformation
    if (!this._layer.map) return
    const o = this._layer.map.mapToViewTransformation
    if (this._layer._getAlpha() < 1) {
      e.save()
      e.globalAlpha = this._layer._getAlpha()
      this.renderImpl(e, s, o)
      e.restore()
    } else this.renderImpl(e, s, o)
  }
  computeTileWindow(e, t, i, s) {
    i.getScaleSFCT(this._scrtScale)
    if (!this._scrtScale[0] || !this._scrtScale[1]) return
    this._scrtScale[0] *= this._xScaleFactor
    this._scrtScale[1] *= this._yScaleFactor
    TileCoordinateUtil.getGridWithClamping(
      this._tileset,
      e,
      this._scrtScale,
      t,
      s
    )
  }
  getCurrentGridCoordinates() {
    return [this._currentGridCoordinate]
  }
  renderImpl(e, t, i) {
    if (DEBUG_LOG)
      Log.debug(
        '/*********************************************RENDER****************************************/'
      )
    const s = this._currentGridCoordinate
    const o = this._layer.getModelBoundsVisibleOnMap()
    if (!o) return
    this.computeTileWindow(o, true, i, s)
    if (-1 === s.level) return
    this.logOutstanding()
    this.requestTopLevel()
    this.processTiles()
    this._tilesToRequest.length = 0
    this._tilesToCancel.length = 0
    this._slidingTileWindow.setTileWindowCoordinate(
      s,
      this._forceTileRefresh,
      this._tilesToRequest,
      this._tilesToCancel
    )
    this._tileComparator.configure(s)
    this._tilesToRequest.sort(this._tileCompareFunction)
    this.cancelTileRequests(this._tilesToCancel)
    this.queueTileRequests(this._tilesToRequest)
    this.requestTiles()
    this._forceTileRefresh = false
    const r = this._tileDrawingFrame
    const n = i.getRotation()
    let l = i
    let a = 0,
      d = 0
    this.updateIntermediateContext(e.canvas.width, e.canvas.height)
    if (0 !== n) {
      this._tempViewPoint.move2D(e.canvas.width / 2, e.canvas.height / 2)
      this._centeredWvt.setViewSize(e.canvas.width, e.canvas.height)
      this._centeredWvt.setTo(
        i.inverseTransformation.transform(this._tempViewPoint),
        this._tempViewPoint,
        i.getScaleX(),
        i.getScaleY(),
        i.getRotation()
      )
      this._rotationLessWvt.setViewSize(e.canvas.width, e.canvas.height)
      this._rotationLessWvt.setTo(
        this._centeredWvt.getWorldOrigin(),
        this._centeredWvt.getViewOrigin(),
        this._centeredWvt.getScaleX(),
        this._centeredWvt.getScaleY(),
        0
      )
      this._tempViewBounds.setTo2D(0, e.canvas.width, 0, e.canvas.height)
      this._rotationLessWvt.inverseTransformation.transformBounds(
        this._tempViewBounds,
        this._tempMapBounds
      )
      this._centeredWvt.transformBounds(
        this._tempMapBounds,
        this._tempViewBounds
      )
      this.updateIntermediateContext(
        this._tempViewBounds.width,
        this._tempViewBounds.height
      )
      a = (e.canvas.width - this._intermediateContext.canvas.width) / 2
      d = (e.canvas.height - this._intermediateContext.canvas.height) / 2
      this._tempViewPoint.move2D(
        this._rotationLessWvt.getViewOrigin().x - a,
        this._rotationLessWvt.getViewOrigin().y - d
      )
      this._rotationLessWvt.setTo(
        this._rotationLessWvt.getWorldOrigin(),
        this._tempViewPoint,
        this._rotationLessWvt.getScaleX(),
        this._rotationLessWvt.getScaleY(),
        this._rotationLessWvt.getRotation()
      )
      l = this._rotationLessWvt
      const t = this._centeredWvt.getViewOrigin()
      e.save()
      e.translate(t.x, t.y)
      e.rotate((-n * Math.PI) / 180)
      e.translate(-t.x, -t.y)
    }
    r.updateTo(t, l)
    this.drawTiles(this._intermediateContext, r)
    e.drawImage(
      this._intermediateContext.canvas,
      0,
      0,
      this._intermediateContext.canvas.width,
      this._intermediateContext.canvas.height,
      a,
      d,
      this._intermediateContext.canvas.width,
      this._intermediateContext.canvas.height
    )
    if (0 !== n) e.restore()
    if (DEBUG_LOG) {
      Log.debug(
        `requests in flight: ${this._requestsInFlight} <---\x3e ${this._tilesInFlight.length}`
      )
      this.logOutstanding()
      Log.debug(`pending requests ${this._pendingRequests.size}`)
      Log.debug(
        '/*???????????????????????????????????????END___________RENDER??????????????????????????????????????????*/'
      )
    }
  }
  calculateTileCoordinateAndTileLocation(e, t, i, s) {
    if (0 !== s.getRotation()) {
      this._rotationLessWvt.setTo(
        this._centeredWvt.getWorldOrigin(),
        this._centeredWvt.getViewOrigin(),
        this._centeredWvt.getScaleX(),
        this._centeredWvt.getScaleY(),
        0
      )
      const i = s.inverseTransformation.transform(new XYZPoint(null, [e, t]))
      const o = this._rotationLessWvt.transform(i)
      s = this._rotationLessWvt
      e = o.x
      t = o.y
    }
    let o
    try {
      const r = s.inverseTransformation.transform(new XYZPoint(null, [e, t]))
      o = i.inverseTransformation.transform(r)
    } catch (e) {
      return null
    }
    const r = new Grid()
    this.computeTileWindow(o.bounds, false, s, r)
    if (-1 === r.level) return null
    const n = this._tileset.getTileColumnCount(r.level)
    const l = this._tileset.getTileRowCount(r.level)
    const a = r.x
    const d = r.y
    if (a < 0 || a >= n || d < 0 || d >= l) return null
    const h = TileCoordinateUtil.getTileBounds(this._tileset, r)
    let c
    try {
      const e = i.transformBounds(h)
      c = s.transformBounds(e)
    } catch (e) {
      return null
    }
    const g = this._tileset.getTileWidth(r.level)
    const _ = this._tileset.getTileHeight(r.level)
    const u = c.width / g
    const m = c.height / _
    const T = (e - c.x) / u
    const f = (t - c.y) / m
    const p = Math.min(g - 1, T)
    const L = Math.min(_ - 1, f)
    return { tile: { level: r.level, x: a, y: d }, tileX: p, tileY: L }
  }
  queueProcessTile(e, t, i) {
    this._pendingTiles.push({
      tile: e,
      requestCoordinate: t,
      targetCoordinate: i,
    })
    this._layer._invalidate()
    if (DEBUG_LOG)
      Log.debug(`  Queued processing of ${coordKey(t)} for ${coordKey(i)}`)
  }
  processTiles() {
    if (DEBUG_LOG) Log.debug(' processTiles')
    let e = 0
    let t
    while (e < MAX_TILES_TO_PROCESS_PER_FRAME && !this._pendingTiles.isEmpty) {
      t = this._pendingTiles.shift()
      if (DEBUG_LOG)
        Log.debug(
          ` get tile from pending ${e}, ${MAX_TILES_TO_PROCESS_PER_FRAME}, ${t}`
        )
      this.processTile(t.tile, t.requestCoordinate, t.targetCoordinate)
      e++
    }
    if (!this._pendingTiles.isEmpty) this._layer._invalidate()
  }
  processTile(e, t, i) {
    if (DEBUG_LOG) Log.debug(`  Processing ${coordKey(t)} for ${coordKey(i)}`)
    const s = this._slidingTileWindow.currentWindow
    if (!s.grid.contains(i)) {
      if (DEBUG_LOG) Log.debug('    Ignore tile outside of window')
      return
    }
    if (e) {
      const o = new DefaultTileRecord(e)
      if (!(i.level === t.level && i.x === t.x && i.y === t.y)) {
        if (DEBUG_LOG) Log.debug('    Store tile as proxy')
        const e = o.deriveTile(
          i,
          this._slidingTileWindow.shouldFlipXAxis,
          this._slidingTileWindow.shouldFlipYAxis
        )
        if (e) {
          e.leaf = true
          s.storeTileRecord(e)
        }
      } else {
        if (DEBUG_LOG) Log.debug('    Store tile')
        s.storeTileRecord(o)
      }
    } else {
      const e = s.getTileRecord(i)
      if (null != e)
        if (e.entireAreaCovered && t.level - 1 === e.baseTileCoordinate.level) {
          if (DEBUG_LOG) Log.debug('    Mark existing record as leaf')
          e.leaf = true
          return
        }
      if (t.level > 0) {
        const e = mapToLowerLevel(t, t.level - 1)
        if (null != e) {
          if (DEBUG_LOG) Log.debug(`    Use ${coordKey(e)} as fallback`)
          this.requestTile(this._tileset, new TileRequest(e), i)
        }
      } else {
        if (DEBUG_LOG) Log.debug('    Store empty record')
        const e = new EmptyTileRecord(i)
        e.leaf = true
        s.storeTileRecord(e)
      }
    }
  }
  queueTileRequests(e) {
    if (DEBUG_LOG) Log.debug('queueTileRequests')
    for (const t of e) {
      this._pendingRequests.push(new TileRequest(t))
      if (DEBUG_LOG) Log.debug(`  Queued request for ${coordKey(t)}`)
    }
  }
  cancelTileRequests(e) {
    let t
    let i = false
    for (let s = 0; s < e.length; s++) {
      t = e[s]
      if (0 !== t.level) {
        i = null !== this._pendingRequests.remove(new TileRequest(t))
        if (i && DEBUG_LOG) Log.debug(`  Cancelled request for ${coordKey(t)}`)
      }
    }
  }
  requestTiles() {
    if (DEBUG_LOG) Log.debug(' requestTiles')
    let e
    while (
      this._requestsInFlight < MAX_TILE_REQUESTS &&
      !this._pendingRequests.isEmpty
    ) {
      e = this._pendingRequests.shift()
      if (DEBUG_LOG) Log.debug(`   requesting a tile ${e.coordinate}`)
      this.requestTile(this._tileset, e, e.coordinate)
    }
    if (DEBUG_LOG)
      Log.debug(` STILL PENDING REQUESTS? ${!this._pendingRequests.isEmpty}`)
  }
  requestTile(e, t, i) {
    const s = t.coordinate
    if (DEBUG_LOG) Log.debug(`  Requesting ${coordKey(s)} for ${coordKey(i)}`)
    const o = coordKey(s)
    const r = undefined
    if (this._errorCache.get(o)) {
      if (DEBUG_LOG) Log.debug('    Reuse cached error')
      this.processTile(null, s, i)
      this._layer._invalidate()
      return
    }
    const n = this._tileCache.get(o)
    if (n) {
      if (DEBUG_LOG)
        Log.debug('    Reuse cached tile (it is immediatly queued again...')
      this.queueProcessTile(n, s, i)
      return
    }
    if (DEBUG_LOG) Log.debug('    Request tile')
    this._requestsInFlight++
    const l = this._tilesInFlight
    l.push(t)
    const a = () => {
      if (DEBUG_LOG) Log.debug(`Received error for ${coordKey(s)}`)
      l.splice(l.indexOf(t), 1)
      this._requestsInFlight--
      this._errorCache.put(o, true)
      this.processTile(null, s, i)
      this._layer._invalidate()
    }
    e.getTileData(
      s,
      (e, r) => {
        try {
          if (DEBUG_LOG)
            Log.debug(`Received image for ${coordKey(t.coordinate)}`)
          l.splice(l.indexOf(t), 1)
          this._requestsInFlight--
          if (t.mustProcess) {
            const e = new Tile(s, r)
            this._tileCache.put(o, e)
            if (0 === s.level) this._topTiles[o] = e
            this.queueProcessTile(e, s, i)
          } else if (DEBUG_LOG)
            Log.debug(
              `    Not processing irrelevant tile [${t.coordinate.level}, ${t.coordinate.x},\n                     ${t.coordinate.y}`
            )
        } catch (e) {
          if (DEBUG_LOG)
            Log.error(
              e instanceof Error ? e.message : '' + e,
              e instanceof Error ? e : void 0
            )
          a()
        }
      },
      a.bind(this)
    )
  }
  drawTiles(e, t) {
    const i = this._slidingTileWindow.currentWindow
    const s = i.height
    const o = i.width
    for (let r = 0; r < s; r++)
      for (let s = 0; s < o; s++) {
        const o = i.get(s, r)
        if (null != o) o.draw(e, t)
      }
    if (DEBUG_RENDER_TILES)
      for (let r = 0; r < s; r++)
        for (let s = 0; s < o; s++) {
          const o = i.get(s, r)
          if (null != o) {
            const i = t.getViewBounds(o.coordinate)
            const n = o.type
            if (n === TileRecordType.DEFAULT) {
              e.fillStyle = 'rgb(0,255,0)'
              e.strokeStyle = 'rgb(0,255,0)'
            } else if (n === TileRecordType.EMPTY) {
              e.fillStyle = 'rgb(255,0,0)'
              e.strokeStyle = 'rgb(255,0,0)'
            } else if (n === TileRecordType.ZOOMED) {
              e.fillStyle = 'rgb(0,0,255)'
              e.strokeStyle = 'rgb(0,0,255)'
            } else if (n === TileRecordType.COMBINED) {
              e.fillStyle = 'rgb(255,0,255)'
              e.strokeStyle = 'rgb(255,0,255)'
            } else {
              e.fillStyle = 'rgb(255,255,0)'
              e.strokeStyle = 'rgb(255,255,0)'
            }
            e.strokeRect(i.x, i.y, i.width, i.height)
            e.fillText(
              `${i.x},${i.y} ${i.width}x${i.height}`,
              i.x + 10,
              i.y + 10
            )
            e.fillText(`${s},${r}`, i.x + 10, i.y + 20)
            const l = o.coordinate
            const a = this._tileset.getTileWidth(l.level)
            const d = this._tileset.getTileHeight(l.level)
            e.fillText(`${l.x},${l.y}@${l.level} ${a}x${d}`, i.x + 10, i.y + 30)
          }
        }
  }
  isReady() {
    return (
      0 === this._requestsInFlight &&
      this._pendingRequests.isEmpty &&
      this._pendingTiles.isEmpty
    )
  }
  disableImageSmoothing() {
    if ('boolean' === typeof this._intermediateContext.msImageSmoothingEnabled)
      this._intermediateContext.msImageSmoothingEnabled = false
    else this._intermediateContext.imageSmoothingEnabled = false
  }
  updateIntermediateContext(e, t) {
    let i = false
    if (this._intermediateCanvas.width < e) {
      this._intermediateCanvas.width = e
      i = true
    }
    if (this._intermediateCanvas.height < t) {
      this._intermediateCanvas.height = t
      i = true
    }
    if (!i)
      this._intermediateContext.clearRect(
        0,
        0,
        this._intermediateCanvas.width,
        this._intermediateCanvas.height
      )
    else this.disableImageSmoothing()
    return this._intermediateContext
  }
  invalidate() {
    let e
    if (DEBUG_LOG) {
      Log.debug('Tiles in flight at RasterTileSetRenderer invalidate:')
      if (0 === this._tilesInFlight.length) Log.debug('  none.')
      for (e = 0; e < this._tilesInFlight.length; e++)
        console.log(
          `  - l: ${this._tilesInFlight[e].coordinate.level}, r: ${this._tilesInFlight[e].coordinate.y},\n             c: ${this._tilesInFlight[e].coordinate.x}`
        )
      Log.debug(
        'Tiles pending for processing at RasterTileSetRenderer invalidate:'
      )
      if (0 === this._pendingTiles.size) Log.debug('  none.')
      else
        this._pendingTiles.forEach((e) => {
          const t = e.tile
          Log.debug(
            `  - l: ${t.coordinate.level}, r: ${t.coordinate.y}, c: ${t.coordinate.x}`
          )
        }, this)
    }
    this._tileCache.clear()
    this._errorCache.clear()
    for (e = 0; e < this._tilesInFlight.length; e++)
      this._tilesInFlight[e].mustProcess = false
    this._pendingRequests.empty()
    this._topRequested = false
    this._forceTileRefresh = true
    return true
  }
}
