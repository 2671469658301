import { WMTSCapabilitiesParser } from './WMTSCapabilitiesParser.js'
import { WMTSCapabilitiesUtil } from './WMTSCapabilitiesUtil.js'
import { URL as RIAUrl } from '../../util/URL.js'
const SUPPORTED_VERSIONS = ['1.0.0']
function createParser(e, s) {
  switch (s) {
    case '1.0.0':
      return new WMTSCapabilitiesParser(e)
    default:
      throw new Error(`Can not parse unsupported WMTS version: ${s}`)
  }
}
class WMTSCapabilities {
  constructor(e, s) {
    this._version = e
    this._capabilitiesXML = s
    this._parser = createParser(s, e)
    this._version = e
    this._service = this._parser.getService()
    this._updateSequence = this._parser.getUpdateSequence()
    this._layers = this._parser.getLayers()
    this._requests = this._parser.getRequests()
  }
  static fromURL(e, s) {
    ;(s = s || {}).requestParameters = Object.assign(
      s.requestParameters || {},
      RIAUrl.parseQueryString(e)
    )
    const t = e.indexOf('?')
    if (t > -1) e = e.substr(0, t)
    const r = {
      credentials: s.credentials,
      requestHeaders: s.requestHeaders,
      requestParameters: s.requestParameters,
    }
    const i = undefined
    return WMTSCapabilitiesUtil.createVersionNegotiator(
      e,
      SUPPORTED_VERSIONS,
      s.allowedVersions,
      r
    )
      .negotiateVersion()
      .then((e) => new WMTSCapabilities(e.version, e.response))
  }
  static fromXML(e) {
    const s = WMTSCapabilitiesUtil.parseVersion(e)
    const t = new WMTSCapabilities(s, e)
    return Promise.resolve(t)
  }
  getRawCapabilities() {
    return this._capabilitiesXML
  }
  get version() {
    return this._version
  }
  get service() {
    return this._service
  }
  get updateSequence() {
    return this._updateSequence
  }
  get layers() {
    return this._layers
  }
  get requests() {
    return this._requests
  }
}
export { WMTSCapabilities }
