import { NoBoundsError } from '../error/NoBoundsError.js'
import { NotImplementedError } from '../error/NotImplementedError.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { GeocentricReference } from '../reference/GeocentricReference.js'
import { GeodeticReference } from '../reference/GeodeticReference.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { LLHBounds } from '../shape/LLHBounds.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { createBounds, createPoint } from '../shape/ShapeFactory.js'
import { XYZBounds } from '../shape/XYZBounds.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { Lang } from '../util/Lang.js'
import {
  BoundsDiscretizationFunc,
  BoundsEdgeDiscretization,
  BoundsMiddleDiscretization,
  DestinationBoundsExtensionDiscretisation,
} from './BoundsDiscretization.js'
import {
  Lat2GridDiscretizer,
  Lon2GridDiscretizer,
} from './LonLat2GridDiscretizer.js'
var isNumber = Lang.isNumber
var FACTOR = 5,
  EPSILON = 1e-6,
  DELTA_LON_LAT_EPSILON = 1e-10,
  DISCRETIZATION_LON = 36,
  DISCRETIZATION_LAT = 18,
  START_LON = -180,
  START_LAT = -90,
  DELTA_LON = 360 / DISCRETIZATION_LON,
  DELTA_LAT = 180 / DISCRETIZATION_LAT,
  DEFAULT_DIV = 4,
  NO_BBOX_ERROR = new NoBoundsError()
function getUnreferencedLLHorXYZPoint(e) {
  if (e.referenceType === ReferenceType.GEODETIC) return new LLHPoint()
  else return new XYZPoint()
}
function getUnreferencedLLHorXYZBounds(e) {
  if (e.referenceType === ReferenceType.GEODETIC) return new LLHBounds(null)
  else return new XYZBounds(null)
}
function extendBoundsWithPoint(e, t) {
  if (t.__bcu_isValid) t.setToIncludePoint3D(e)
  else {
    t.setTo3D(e.x, 0, e.y, 0, e.z, 0)
    t.__bcu_isValid = true
  }
}
function isReference3D(e) {
  return e instanceof GeocentricReference
}
function BoundsCalculationUtil(e) {
  if (
    !(
      e &&
      e.transformation &&
      e.modelReference &&
      e.worldReference &&
      e.TransformationFactory
    )
  )
    throw new ProgrammingError(
      'BoundsCalculationUtil::constructor. cannot construct BoundsCalculationUtil. missing constructor parameters'
    )
  function t() {
    throw new NotImplementedError('BCU::forwardBounds2d must be implemented')
  }
  function n() {
    throw new NotImplementedError('BCU::inverseBounds2d must be implemented')
  }
  var i = e.transformation,
    r = e.modelReference,
    o = e.worldReference,
    a = false,
    s = e.div || DEFAULT_DIV,
    d,
    f,
    c,
    u,
    h = t,
    T = n,
    l = e.TransformationFactory
  function L(e, t, n) {
    var i = (e.x * t.scale) / t.unitOfMeasure,
      r = (e.y * t.scale) / t.unitOfMeasure,
      o = i * t.cosRotation + r * t.sinRotation,
      a = i * t.sinRotation + r * t.cosRotation
    o += t.falseEasting
    a += t.falseNorthing
    n.x = o
    n.y = a
    n.z = e.z
  }
  function w(e, t, n, i, r) {
    try {
      t.geodetic2cartesianOnEllipsoidSFCT(e, n, i)
      L(i, o, r)
      return true
    } catch (e) {
      return false
    }
  }
  function m(e, t, n, i, r, o, a) {
    try {
      o._forward(e, r)
      var s
      if (i ? n.contains3DPoint(r) : n.contains2DPoint(r))
        extendBoundsWithPoint(t, a)
    } catch (e) {
      return false
    }
    return true
  }
  function D(e, t, n, i) {
    try {
      t._inverse(e, n)
      extendBoundsWithPoint(n, i)
      return true
    } catch (e) {
      return false
    }
  }
  function v(e, t, n, i, r, o, a) {
    try {
      o._inverse(e, r)
      var s
      if (i ? n.contains3DPoint(r) : n.contains2DPoint(r))
        extendBoundsWithPoint(t, a)
    } catch (e) {
      return false
    }
    return true
  }
  function P(e, t, n, i, r, o, a) {
    var s
    if (i ? n.contains3DPoint(e) : n.contains2DPoint(e))
      try {
        r._forward(t, o)
        extendBoundsWithPoint(o, a)
      } catch (e) {
        return false
      }
    return true
  }
  function p(e, t, n, i, r, o, a, s, d, f, c, u, h) {
    var T, l, L, m
    var v = false
    var P = false
    var p = h.z
    for (T = 0, l = t.length; T < l; T++) {
      L = t[T]
      n.init(e, L)
      while (!!n.getBeginPointSFCT(c)) {
        u.z = p
        if (w(c, i, r, u, s)) {
          if (!(P = a ? o.contains3DPoint(s) : o.contains2DPoint(s))) break
          if (D(c, d, f, h)) {
            v = true
            break
          }
        }
      }
      while (!!n.getEndPointSFCT(c)) {
        u.z = p
        if (w(c, i, r, u, s)) {
          if (!(P = a ? o.contains3DPoint(s) : o.contains2DPoint(s))) break
          if (D(c, d, f, h)) {
            v = true
            break
          }
        }
      }
      if ((m = (m = L[1] - L[0]) < 0 ? (m += 360) : m) >= 180) {
        while (!!n.getBeginPointSFCT(c))
          if (w(c, i, r, u, s)) {
            if (!(P = a ? o.contains3DPoint(s) : o.contains2DPoint(s))) break
            if (D(c, d, f, h)) {
              v = true
              break
            }
          }
        while (!!n.getEndPointSFCT(c))
          if (w(c, i, r, u, s)) {
            if (!(P = a ? o.contains3DPoint(s) : o.contains2DPoint(s))) break
            if (D(c, d, f, h)) {
              v = true
              break
            }
          }
      }
    }
    return v
  }
  function _(e, t, n, i, r, o, a, s, d, f, c, u, h) {
    var T = h.z
    t.forEach(function (t) {
      n.init(e, t)
      while (n.getBeginPointSFCT(c)) {
        u.z = T
        if (w(c, i, r, u, f)) if (m(c, f, o, a, s, d, h)) break
      }
      while (n.getEndPointSFCT(c)) {
        u.z = T
        if (w(c, i, r, u, f)) if (m(c, f, o, a, s, d, h)) break
      }
    })
  }
  function y(e, t, n, i, r, o, a, s, d, f, c, u, h) {
    var T,
      l = t.length,
      L
    var m = h.z
    for (T = 0; T < l; T++) {
      L = t[T]
      n.init(e, L)
      while (!!n.getBeginPointSFCT(c)) {
        u.z = m
        if (!!w(c, i, r, u, f)) if (v(c, f, o, a, s, d, h)) break
      }
      while (!!n.getEndPointSFCT(c)) {
        u.z = m
        if (!!w(c, i, r, u, f)) if (v(c, f, o, a, s, d, h)) break
      }
    }
  }
  function g(e, t, n, i, r, o, a, s, d, f, c, u, h) {
    var T,
      l = t.length,
      L
    var m = h.z
    for (T = 0; T < l; T++) {
      L = t[T]
      n.init(e, L)
      while (!!n.getBeginPointSFCT(c)) {
        u.z = m
        if (!!w(c, i, r, u, s)) if (P(s, c, o, a, d, f, h)) break
      }
      while (!!n.getEndPointSFCT(c)) {
        u.z = m
        if (!!w(c, i, r, u, s)) if (P(s, c, o, a, d, f, h)) break
      }
    }
  }
  function R(e, t, n, i, r, o, a, s, d, f) {
    var c = d.geodeticDatum.ellipsoid
    var u = new XYZBounds()
    f.cartesianBoundsOnEllipsoidSFCT(c, u)
    var h = u.x
    var T = u.y
    var l =
      ((h * d.cosRotation + T * d.sinRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseEasting
    var L =
      ((-h * d.sinRotation + T * d.cosRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseNorthing
    var w = l
    var m = l
    var D = L
    var v = L
    l =
      (((h += u.width) * d.cosRotation + T * d.sinRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseEasting
    L =
      ((-h * d.sinRotation + T * d.cosRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseNorthing
    w = Math.min(w, l)
    m = Math.max(m, l)
    D = Math.min(D, L)
    v = Math.max(v, L)
    T += u.height
    l =
      ((h * d.cosRotation + T * d.sinRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseEasting
    L =
      ((-h * d.sinRotation + T * d.cosRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseNorthing
    w = Math.min(w, l)
    m = Math.max(m, l)
    D = Math.min(D, L)
    v = Math.max(v, L)
    l =
      (((h -= u.width) * d.cosRotation + T * d.sinRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseEasting
    L =
      ((-h * d.sinRotation + T * d.cosRotation) * d.scale * 1) /
        d.unitOfMeasure +
      d.falseNorthing
    w = Math.min(w, l)
    m = Math.max(m, l)
    D = Math.min(D, L)
    v = Math.max(v, L)
    w = Math.max(w, t.x)
    m = Math.min(m, t.x + t.width)
    D = Math.max(D, t.y)
    v = Math.min(v, t.y + t.height)
    u.move3D(w, D, t.z)
    u.setWidth(m - w)
    u.setHeight(v - D)
    u.setDepth(t.depth)
    var P = new BoundsDiscretizationFunc(o, a, s, u)
    while (P.hasMoreSamplePoints()) {
      P.nextSamplePoint(r)
      try {
        e._inverse(r, n)
        extendBoundsWithPoint(n, i)
      } catch (e) {}
    }
  }
  function E(e, t, n) {
    n.setTo3D(e.x, 0, e.y, 0, e.z, 0)
    n.setToIncludePoint3D(t)
  }
  function x(e, t, n) {
    if (e) i._forward(t, n)
    else i._inverse(t, n)
  }
  function B(e, t) {
    var n, a, h, T, w, D, v, P, y, g, R, E, x, B, O, N, I, A, S, b, C, z, M, Z
    ;(n = getUnreferencedLLHorXYZPoint(r)).x = t.x
    n.y = t.y
    ;(a = getUnreferencedLLHorXYZBounds(o)).setToBounds2D(e)
    h = getUnreferencedLLHorXYZBounds(r)
    T = getUnreferencedLLHorXYZPoint(o)
    w = false
    h.__bcu_isValid = false
    var G = 2 * s
    var H = s
    var X = 0 === e.depth ? 0 : 1
    D = isReference3D(r)
      ? new BoundsDiscretizationFunc(G, H, X, e)
      : new BoundsEdgeDiscretization(G, H, X, e)
    v = void 0
    P = void 0
    y = void 0
    g = void 0
    while (D.hasMoreSamplePoints()) {
      D.nextSamplePoint(T)
      try {
        i._inverse(T, n)
        extendBoundsWithPoint(n, h)
        if (!w) {
          R = T.x
          E = T.y
          if (((x = n.x) - y) * (R - v) + ((B = n.y) - g) * (E - P) < 0)
            w = true
          v = R
          P = E
          y = x
          g = B
        }
      } catch (e) {
        w = true
      }
    }
    var W = isReference3D(o)
    if (w) {
      O = new Lon2GridDiscretizer()
      N = new Lat2GridDiscretizer()
      I = new XYZPoint()
      A = new LLHPoint()
      var F = new GeodeticReference({ geodeticDatum: o.geodeticDatum })
      var Y = l.createTransformation(r, F)
      for (S = 0; S <= DISCRETIZATION_LAT; S++) {
        b = START_LAT + S * DELTA_LAT
        if (
          (C = d.boundaryLons(b))[0] &&
          isNumber(C[0][0]) &&
          isNumber(C[0][1])
        )
          p(b, C, O, d, f, a, W, T, Y, n, A, I, h)
      }
      for (S = 0; S <= DISCRETIZATION_LON; S++) {
        z = START_LON + S * DELTA_LON
        if (
          (M = d.boundaryLats(z))[0] &&
          isNumber(M[0][0]) &&
          isNumber(M[0][1])
        )
          p(z, M, N, d, f, a, W, T, Y, n, A, I, h)
      }
      if (r.referenceType === ReferenceType.GRID) {
        var U = new GeodeticReference({ geodeticDatum: r.geodeticDatum })
        var Y = l.createTransformation(U, o)
        for (S = 0; S <= DISCRETIZATION_LAT; S++) {
          b = START_LAT + S * DELTA_LAT
          if (
            (C = c.boundaryLons(b))[0] &&
            isNumber(C[0][0]) &&
            isNumber(C[0][1])
          )
            _(b, C, O, c, u, a, W, T, Y, n, A, I, h)
        }
        for (S = 0; S <= DISCRETIZATION_LON; S++) {
          z = START_LON + S * DELTA_LON
          if (
            (M = d.boundaryLats(z))[0] &&
            isNumber(M[0][0]) &&
            isNumber(M[0][1])
          )
            _(z, M, N, c, u, a, W, T, Y, n, A, I, h)
        }
      }
    }
    if (h.width < 0) throw NO_BBOX_ERROR
    if (r.referenceType === ReferenceType.GRID) {
      var U = new GeodeticReference({ geodeticDatum: r.geodeticDatum })
      var Y = l.createTransformation(U, o)
      Z = new LLHPoint()
      try {
        Z.x = 0
        Z.y = 90
        c.geodetic2cartesianOnEllipsoidSFCT(Z, u, n)
        L(n, r, n)
        m(Z, n, a, W, T, Y, h)
      } catch (e) {}
      try {
        Z.x = 0
        Z.y = -90
        c.geodetic2cartesianOnEllipsoidSFCT(Z, u, n)
        L(n, r, n)
        m(Z, n, a, W, T, Y, h)
      } catch (e) {}
    }
    t.setToBounds3D(h)
  }
  function O(e, t) {
    var n = new XYZPoint()
    var r = new XYZPoint()
    n.x = e.x
    n.y = e.y
    i._forward(n, r)
    extendBoundsWithPoint(r, t)
    if (e.width > 0) {
      n.x += e.width
      i._forward(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.height > 0) {
      n.y += e.height
      i._forward(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.width > 0) {
      n.x -= e.width
      i._forward(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.depth > 0) {
      n.z += e.depth
      i._forward(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.width > 0) {
      n.x += e.width
      i._forward(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.height > 0) {
      n.y -= e.height
      i._forward(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.width > 0) {
      n.x -= e.width
      i._forward(n, r)
      extendBoundsWithPoint(r, t)
    }
  }
  function N(e, t) {
    var n = new XYZPoint()
    var r = new XYZPoint()
    n.x = e.x
    n.y = e.y
    i._inverse(n, r)
    extendBoundsWithPoint(r, t)
    if (e.width > 0) {
      n.x += e.width
      i._inverse(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.height > 0) {
      n.y += e.height
      i._inverse(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.width > 0) {
      n.x -= e.width
      i._inverse(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.depth > 0) {
      n.z += e.depth
      i._inverse(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.width > 0) {
      n.x += e.width
      i._inverse(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.height > 0) {
      n.y -= e.height
      i._inverse(n, r)
      extendBoundsWithPoint(r, t)
    }
    if (e.width > 0) {
      n.x -= e.width
      i._inverse(n, r)
      extendBoundsWithPoint(r, t)
    }
  }
  var I =
    ((A = createPoint(r)),
    (S = createBounds(r)),
    (b = createPoint(o)),
    function (e, t) {
      var n = false,
        c,
        u,
        h,
        T,
        L,
        w,
        D,
        v,
        P,
        _,
        y,
        g,
        E,
        x,
        B,
        O,
        N,
        I
      S.__bcu_isValid = false
      var C = 0 === e.width ? 0 : 2 * s
      var z = 0 === e.height ? 0 : s
      var M = 0 === e.depth ? 0 : 1
      c = new BoundsMiddleDiscretization(C, z, M, e)
      while (c.hasMoreSamplePoints()) {
        c.nextSamplePoint(b)
        try {
          i._inverse(b, A)
          extendBoundsWithPoint(A, S)
        } catch (e) {}
      }
      h = void 0
      w = 0
      c = new BoundsEdgeDiscretization(C, z, M, e)
      while (c.hasMoreSamplePoints()) {
        var Z
        if (c.nextSamplePoint(b)) h = void 0
        try {
          i._inverse(b, A)
          extendBoundsWithPoint(A, S)
          v = A.x
          if (isNumber(h)) if (w < (P = Math.abs(v - h)) && P < 180) w = P
          h = v
        } catch (e) {
          n = true
          h = void 0
        }
      }
      if (S.__bcu_isValid && 360 - S.width < 2 * w)
        S.setTo3D(-180, 360, S.y, S.height, S.z, S.depth)
      var G = isReference3D(o)
      if (n) {
        N = new Lon2GridDiscretizer()
        I = new Lat2GridDiscretizer()
        _ = new XYZPoint()
        y = new LLHPoint()
        var H = new GeodeticReference({ geodeticDatum: o.geodeticDatum })
        var X = l.createTransformation(r, H)
        var W = 0
        var F = 0
        for (g = 0; g <= DISCRETIZATION_LAT; g++) {
          x = START_LAT + g * DELTA_LAT
          W += (E = d.boundaryLons(x)) && E.length > 0 ? 1 : 0
          if (E[0] && isNumber(E[0][0]) && isNumber(E[0][1])) {
            var Y
            F += (Y = p(x, E, N, d, f, e, G, b, X, A, y, _, S)) ? 1 : 0
          }
        }
        var U = 0
        for (g = 0; g <= DISCRETIZATION_LON; g++) {
          v = START_LON + g * DELTA_LON
          W += (B = d.boundaryLats(v)) && B.length > 0 ? 1 : 0
          if (B[0] && isNumber(B[0][0]) && isNumber(B[0][1])) {
            var Y
            U += (Y = p(v, B, I, d, f, e, G, b, X, A, y, _, S)) ? 1 : 0
          }
        }
        if (0 == F && a) {
          var D = 0 == S.height ? 0 : DELTA_LON_LAT_EPSILON
          var j = S.y + D
          var k = 1
          var V = (S.height - 2 * D) / k
          for (g = 0; g <= k; g++) {
            var x = j + g * V
            var E
            W += (E = d.boundaryLons(x)) && E.length > 0 ? 1 : 0
            p(x, E, N, d, f, e, G, b, X, A, y, _, S)
          }
        }
        if (0 == U && a) {
          var D = 0 == S.width ? 0 : 1e-10
          var q = S.x + D
          var J = 1
          var K = (S.width - D) / J
          for (g = 0; g <= DISCRETIZATION_LON; g++) {
            var v = q + g * K
            var B
            W += (B = d.boundaryLats(v)) && B.length > 0 ? 1 : 0
            p(v, B, I, d, f, e, G, b, X, A, y, _, S)
          }
        }
        if (W <= 2) R(i, e, A, S, b, 2 * C, 2 * z, M, o, d)
        if (r.referenceType === ReferenceType.GEODETIC) {
          u = new BoundsEdgeDiscretization(
            2 * DISCRETIZATION_LON,
            DISCRETIZATION_LAT,
            0,
            new LLHBounds(null, [-180, 360, -90, 180])
          )
          T = S.width
          while (u.hasMoreSamplePoints()) {
            u.nextSamplePoint(A)
            m(A, A, e, G, b, i, S)
          }
          L = S.width
          D = DELTA_LON
          if (L === T) D = 2 * DELTA_LON
          if (S.__bcu_isValid && 360 - S.width < D)
            S.setTo3D(-180, 360, S.y, S.height, S.z, S.depth)
        }
      }
      if (!S.__bcu_isValid) throw NO_BBOX_ERROR
      if (Math.abs(S.x - 180) < EPSILON && S.width > FACTOR * EPSILON)
        S.setTo3D(-180, S.width, S.y, S.height, S.z, S.depth)
      v = (O = S.width) < 0 ? 0 : S.x + O / 2
      A.x = v
      A.y = 90
      m(A, A, e, G, b, i, S)
      A.x = v
      A.y = -90
      m(A, A, e, G, b, i, S)
      t.setToBounds3D(S)
    })
  var A, S, b
  function C(e, t) {
    var n,
      h,
      T,
      w,
      m,
      D,
      P,
      p,
      _,
      R,
      E,
      x,
      B,
      O,
      N,
      I,
      A,
      S,
      b,
      C,
      z,
      M,
      Z,
      G,
      H,
      X,
      W,
      F,
      Y,
      U,
      j
    n = getUnreferencedLLHorXYZPoint(r)
    h = new XYZPoint()
    ;(T = getUnreferencedLLHorXYZBounds(r)).setTo3D(
      e.x,
      e.width,
      e.y,
      e.height,
      e.z,
      e.depth
    )
    w = new XYZPoint()
    ;(m = new XYZBounds()).__bcu_isValid = false
    D = false
    var k = 2 * s
    var V = s
    var q = r instanceof GeocentricReference ? s : 1
    if (0 === e.depth) q = 0
    P = new BoundsEdgeDiscretization(k, V, q, e)
    p = void 0
    _ = void 0
    R = void 0
    E = void 0
    while (P.hasMoreSamplePoints()) {
      P.nextSamplePoint(h)
      n.move3DToCoordinates(h.x, h.y, h.z)
      try {
        i._forward(n, w)
        extendBoundsWithPoint(w, m)
        if (!D) {
          x = h.x
          B = h.y
          if ((x - p) * ((O = w.x) - R) + (B - _) * ((N = w.y) - E) < 0)
            D = true
          p = x
          _ = B
          R = O
          E = N
        }
      } catch (e) {
        D = true
      }
    }
    if (!D) {
      I = e.width
      A = e.height
      S = m.width
      b = m.height
      if (Math.max(S / b, b / S) >= FACTOR * Math.max(I / A, A / I)) D = true
      else if (r.referenceType === ReferenceType.GEODETIC && e.x + I >= 360)
        D = true
      else if (s > 0)
        try {
          n.x = e.x
          n.y = e.y
          n.translate(I / 2, A / 2)
          i._forward(n, w)
          var J
          if (
            !(isReference3D(o) ? m.contains3DPoint(w) : m.contains2DPoint(w))
          ) {
            extendBoundsWithPoint(w, m)
            D = true
          }
        } catch (e) {
          D = true
        }
    }
    var K = isReference3D(r)
    if (D) {
      C = new Lon2GridDiscretizer()
      z = new Lat2GridDiscretizer()
      M = new XYZPoint()
      Z = new LLHPoint()
      var Q = new GeodeticReference({ geodeticDatum: o.geodeticDatum })
      var $ = l.createTransformation(r, Q)
      var ee = r.referenceType === ReferenceType.GEODETIC && a
      var te = START_LAT
      var ne = DELTA_LAT
      var ie = DISCRETIZATION_LAT
      if (ee) {
        var re = 0 === T.height ? 0 : DELTA_LON_LAT_EPSILON
        te = T.y + re
        ie = Math.max(5, Math.floor(T.height / 10))
        ne = (T.height - 2 * re) / ie
      }
      for (W = 0; W <= ie; W++)
        y(
          (F = te + W * ne),
          (Y = d.boundaryLons(F)),
          C,
          d,
          f,
          T,
          K,
          n,
          $,
          w,
          Z,
          M,
          m
        )
      var oe = START_LON
      var ae = DELTA_LON
      var se = DISCRETIZATION_LON
      if (ee) {
        var re = 0 == T.width ? 0 : DELTA_LON_LAT_EPSILON
        oe = T.x + re
        se = Math.max(5, Math.floor(T.width / 10))
        ae = (T.width - 2 * re) / se
      }
      for (W = 0; W <= se; W++)
        y(
          (U = oe + W * ae),
          (j = d.boundaryLats(U)),
          z,
          d,
          f,
          T,
          K,
          n,
          $,
          w,
          Z,
          M,
          m
        )
      if (r.referenceType === ReferenceType.GRID) {
        var de = new GeodeticReference({ geodeticDatum: r.geodeticDatum })
        var $ = l.createTransformation(de, o)
        for (W = 0; W <= DISCRETIZATION_LAT; W++)
          g(
            (F = START_LAT + W * DELTA_LAT),
            (Y = c.boundaryLons(F)),
            C,
            c,
            u,
            T,
            K,
            n,
            $,
            w,
            Z,
            M,
            m
          )
        for (W = 0; W <= DISCRETIZATION_LON; W++)
          g(
            (U = START_LON + W * DELTA_LON),
            (j = c.boundaryLats(U)),
            z,
            c,
            u,
            T,
            K,
            n,
            $,
            w,
            Z,
            M,
            m
          )
      }
    }
    G = m.width
    if (!isNumber(G, false))
      throw new NoBoundsError('BoundsCalculationUtil::forwardBounds2d')
    var Q = new GeodeticReference({ geodeticDatum: o.geodeticDatum })
    var $ = l.createTransformation(r, Q)
    H = new LLHPoint()
    try {
      H.x = 0
      H.y = 90
      d.geodetic2cartesianOnEllipsoidSFCT(H, f, w)
      L(w, o, w)
      v(H, w, T, K, n, $, m)
    } catch (e) {}
    try {
      H.x = 0
      H.y = -90
      d.geodetic2cartesianOnEllipsoidSFCT(H, f, w)
      L(w, o, w)
      v(H, w, T, K, n, $, m)
    } catch (e) {}
    X = new DestinationBoundsExtensionDiscretisation(m, i, T, w, n, K, true)
    while (X.hasMoreSamplePoints()) {
      X.nextSamplePoint(w)
      extendBoundsWithPoint(w, m)
    }
    X = new DestinationBoundsExtensionDiscretisation(m, i, T, w, n, K, false)
    while (X.hasMoreSamplePoints()) {
      X.nextSamplePoint(w)
      extendBoundsWithPoint(w, m)
    }
    t.setToBounds3D(m)
  }
  function z(e, t, n) {
    try {
      var i = new LLHPoint(),
        r = new LLHPoint(),
        o = new LLHPoint(),
        a = new LLHPoint(),
        s = new LLHPoint(),
        d = null,
        f = null,
        c = new LLHBounds(),
        u = new LLHBounds(),
        h = 1,
        T = false,
        l = 0 === t.depth ? 0 : 1,
        L = 0.01,
        w,
        m,
        D,
        v,
        P,
        p
      if (t.width > 179.95) h = 2
      if (t.width > 359.95) h = 3
      if (Math.abs(t.y + 90) < L || Math.abs(t.y + t.height - 90) < L)
        h = Math.max(2, h)
      if (Math.abs(t.y + 90) < L && Math.abs(t.y + t.height - 90) < L) {
        T = true
        d = new LLHPoint()
        f = new LLHPoint()
      }
      P = t.width / h
      D = t.y
      p = T ? 0.5 * t.height : t.height
      for (var _ = 0; _ <= l; _++) {
        v = t.z + _ * t.depth
        for (w = 0; w < h; w++) {
          m = t.x + w * P
          if (0 === w) {
            i.x = m
            i.y = D
            i.z = v
            x(e, i, r)
            i.x = m
            i.y = D + p
            i.z = v
            x(e, i, a)
            if (T) {
              i.x = m
              i.y = D + 2 * p
              i.z = v
              x(e, i, d)
            }
          }
          if (w % 2 === 1) {
            i.x = m + P
            i.y = D
            i.z = v
            x(e, i, r)
            i.x = m + P
            i.y = D + p
            i.z = v
            x(e, i, a)
            if (T) {
              i.x = m + P
              i.y = D + 2 * p
              i.z = v
              x(e, i, d)
            }
          } else if (w % 2 === 0) {
            i.x = m + P
            i.y = D
            i.z = v
            x(e, i, o)
            i.x = m + P
            i.y = D + p
            i.z = v
            x(e, i, s)
            if (T) {
              i.x = m + P
              i.y = D + 2 * p
              i.z = v
              x(e, i, f)
            }
          }
          if (0 === _ && 0 === w) E(r, o, u)
          else {
            E(r, o, c)
            u.setTo3DUnion(c)
          }
          E(o, s, c)
          u.setTo3DUnion(c)
          E(a, s, c)
          u.setTo3DUnion(c)
          E(a, r, c)
          u.setTo3DUnion(c)
          if (T) {
            E(s, f, c)
            u.setTo3DUnion(c)
            E(d, f, c)
            u.setTo3DUnion(c)
            E(a, d, c)
            u.setTo3DUnion(c)
          }
        }
      }
      n.setToBounds3D(u)
    } catch (e) {
      if (e instanceof OutOfBoundsError)
        throw new NoBoundsError('Could not transform bounds: ' + e.message)
      else throw e
    }
  }
  function M(e, t) {
    var n = new XYZPoint()
    var r = new XYZPoint()
    var a = getUnreferencedLLHorXYZBounds(o)
    a.__bcu_isValid = false
    var d = 0 == e.width ? 0 : 2 * s
    var f = 0 == e.height ? 0 : s
    var c = 0 == e.depth ? 0 : 1
    var u = new BoundsDiscretizationFunc(d, f, c, e)
    while (u.hasMoreSamplePoints()) {
      u.nextSamplePoint(n)
      try {
        i._forward(n, r)
        extendBoundsWithPoint(r, a)
      } catch (e) {}
    }
    t.setToBounds3D(a)
  }
  function Z(e, t) {
    var n
    var a = getUnreferencedLLHorXYZPoint(o)
    var d = getUnreferencedLLHorXYZPoint(r)
    var f = getUnreferencedLLHorXYZBounds(r)
    f.__bcu_isValid = false
    var c = void 0
    var u = 0
    var h = 0 == e.width ? 0 : 2 * s
    var T = 0 == e.height ? 0 : s
    var l = 0 == e.depth ? 0 : 1
    var L = new BoundsDiscretizationFunc(h, T, l, e)
    while (L.hasMoreSamplePoints()) {
      L.nextSamplePoint(a)
      try {
        i._inverse(a, d)
        extendBoundsWithPoint(d, f)
        n = d.x
        if (isNumber(c)) {
          var w = Math.abs(n - c)
          if (u < w && w < 180) u = w
        }
        c = n
      } catch (e) {}
    }
    n = f.width < 0 ? 0 : f.x + f.width / 2
    d.move2D(n, 90)
    m(d, d, e, true, a, i, f)
    d.move2D(n, -90)
    m(d, d, e, true, a, i, f)
    if (f.__bcu_isValid && 360 - f.width < 2 * u) {
      f.x = -180
      f.width = 360
    }
    t.setToBounds3D(f)
  }
  a = o.geodeticDatum ? o.geodeticDatum.equals2D(r.geodeticDatum) : false
  if (
    r.referenceType === ReferenceType.GEODETIC &&
    o.referenceType === ReferenceType.GRID
  ) {
    d = o.projection
    f = o.geodeticDatum.ellipsoid
    h = C
    T = I
  } else if (
    r.referenceType === ReferenceType.GRID &&
    o.referenceType === ReferenceType.GRID
  ) {
    d = o.projection
    f = o.geodeticDatum.ellipsoid
    c = r.projection
    u = r.geodeticDatum.ellipsoid
    h = C
    T = B
  } else if (
    r.referenceType === ReferenceType.GEODETIC &&
    o.referenceType === ReferenceType.GEODETIC
  ) {
    h = z.bind(void 0, true)
    T = z.bind(void 0, false)
  } else if (
    r.referenceType === ReferenceType.GEODETIC &&
    o.referenceType === ReferenceType.GEOCENTRIC
  ) {
    h = M
    T = Z
  } else if (
    r.referenceType === ReferenceType.GEOCENTRIC &&
    o.referenceType === ReferenceType.GRID
  ) {
    d = o.projection
    f = o.geodeticDatum.ellipsoid
    u = r.geodeticDatum.ellipsoid
    h = C
    T = B
  } else if (
    r.referenceType === ReferenceType.TOPOCENTRIC &&
    o.referenceType === ReferenceType.GEOCENTRIC
  ) {
    h = O
    T = N
  } else
    throw new NotImplementedError(
      'BCU::constructor - model/world reference combination not supported.'
    )
  this.forwardBounds2dSFCT = h
  this.inverseBounds2dSFCT = T
}
export { BoundsCalculationUtil }
