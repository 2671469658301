import { ProgrammingError } from '../../error/ProgrammingError.js'
import { RasterDataType } from '../../model/tileset/RasterDataType.js'
import { isObject } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { releaseObject } from '../../util/ObjectReleaseTracker.js'
import { Layer } from '../Layer.js'
import { LayerType } from '../LayerType.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { RasterStyleImpl } from '../style/RasterStyleImpl.js'
import { snapScaleToLevel } from './TileSetScaleUtil.js'
function isElevation(e) {
  return (
    e.modelDescriptor && e.modelDescriptor.type === RasterDataType.ELEVATION
  )
}
class RasterTileSetLayer extends Layer {
  _renderer = null
  _hasAttribution = true
  constructor(e, t) {
    super(e, t)
    if (!e.getAttribution) this._hasAttribution = false
    this._rasterStyle = new RasterStyleImpl(this)
    this._levelSwitchFactorInternal = t
      ? t.levelSwitchFactorInternal || 0.5
      : 0.5
  }
  isPaintRepresentationSupported(e) {
    return PaintRepresentation.BODY === e
  }
  isReady() {
    return !this._renderer || this._renderer.isReady()
  }
  update() {
    return !this._renderer || this._renderer.update()
  }
  get model() {
    return super.model
  }
  calculateTileCoordinateAndTileLocation(e, t) {
    return this._renderer?.calculateTileCoordinateAndTileLocation(
      e,
      t,
      this._modelToWorldTransformation,
      this._map.mapToViewTransformation
    )
  }
  _createRenderer(e, t) {
    function r(e) {
      return (
        e.modelDescriptor &&
        e.modelDescriptor.type === RasterDataType.TIN_ELEVATION
      )
    }
    if ((isElevation(this.model) || r(this.model)) && !e.is3D()) {
      Log.warn('LuciadRIA 2D maps cannot display elevation raster layers')
      this.visibleSupported = false
      return
    }
    releaseObject(this._renderer)
    this._renderer = t.rasterTileSetLayerRenderer.create(this, this.model, t)
  }
  _invalidateRenderer() {
    return this._renderer.invalidate(true, isElevation(this.model))
  }
  _addedToMap(e) {
    super._addedToMap(e)
    this._setModelToMapTransformation()
    e.onReady(() => {
      this._invalidationHandle = this._model.on('Invalidated', () => {
        if (!this._invalidateRenderer())
          this._createRenderer(e, e.viewPaintingStrategy.techContext)
        this._invalidate()
      })
      this._createRenderer(e, e.viewPaintingStrategy.techContext)
      e.mapNavigator.invalidate()
    })
  }
  _removedFromMap(e) {
    super._removedFromMap(e)
    this._renderer = releaseObject(this._renderer)
    if (this._invalidationHandle) {
      this._invalidationHandle.remove()
      this._invalidationHandle = null
    }
  }
  getAttribution() {
    if (!this._hasAttribution || !this._renderer) return null
    const e = this._renderer.getCurrentGridCoordinates()
    const t = new Set()
    for (let r = 0; r < e.length; r++) {
      const i = this.model.getAttribution(e[r])
      if (i)
        i.forEach((e) => {
          t.add(e)
        })
    }
    const r = []
    t.forEach((e) => {
      r.push(e)
    })
    return r
  }
  snapScale(e, t, r, i) {
    if (this.type !== LayerType.BASE || !this.model) {
      i.scalex = e
      i.scaley = t
      return
    }
    snapScaleToLevel(this.model, this._map, r, e, t, i)
  }
  _setModelToMapTransformation() {
    this._updateModelWorldTransformation(this.model.reference)
  }
  verifyModel(e) {
    if (!isObject(e.reference))
      throw new ProgrammingError(
        'RasterLayer::constructor - cannot create a RasterLayer when the model is not geographically referenced'
      )
  }
  _paintBodyLabels(e, t, r) {
    if (!t) this._renderer?.render(e, t, r)
    return true
  }
  _collectLabels(e, t) {}
  _getAlpha() {
    return this._rasterStyle.alpha
  }
  get supportedPaintRepresentations() {
    return [PaintRepresentation.BODY]
  }
  get bounds() {
    if (this.model) return this.model.bounds
  }
  get rasterStyle() {
    return this._rasterStyle
  }
  set rasterStyle(e) {
    if (!isObject(e))
      throw new ProgrammingError('Raster style should be object')
    this._rasterStyle = new RasterStyleImpl(this, e)
    this._invalidate()
  }
  get selectable() {
    return
  }
  set selectable(e) {}
}
export { RasterTileSetLayer }
