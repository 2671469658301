import { createPoint } from '../shape/ShapeFactory.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { GeocentricReference } from '../reference/GeocentricReference.js'
import { GridReference } from '../reference/GridReference.js'
import { BoundsCalculationUtil } from './BoundsCalculationUtil.js'
import { Transformation } from './Transformation.js'
function isCompatible(t, e) {
  return e instanceof GridReference && t instanceof GeocentricReference
}
export class GeocentricGridTransformation extends Transformation {
  constructor(t, e, i) {
    super(t, e)
    if (!isCompatible(t, e))
      throw new ProgrammingError(
        'GeocentricGridTransformation - references not compatible.'
      )
    this._srcGeocentricReference = t
    this._dstGridReference = e
    this._srcGeodeticDatum = t.geodeticDatum
    this._dstGeodeticDatum = e.geodeticDatum
    this._uom = e.unitOfMeasure
    this._projection = e.projection
    this._equalHorizontalDatums = this._srcGeodeticDatum.equals2D(
      this._dstGeodeticDatum
    )
    this._tmpXYZPoint1 = createPoint(t, [0, 0])
    this._tmpXYZPoint2 = createPoint(t, [0, 0])
    this._tmpXYZPoint3 = createPoint(e, [0, 0])
    this._tmpLLHPoint = new LLHPoint()
    this._bboxCalculationUtil = new BoundsCalculationUtil({
      modelReference: t,
      worldReference: e,
      transformation: this,
      TransformationFactory: i,
    })
  }
  _forward(t, e) {
    const i = this._srcGeocentricReference.unitOfMeasure
    this._tmpXYZPoint1.move3DToCoordinates(t.x * i, t.y * i, t.z * i)
    if (!this._equalHorizontalDatums) {
      this._srcGeodeticDatum.datum2refGeocSFCT(
        this._tmpXYZPoint1,
        this._tmpXYZPoint2
      )
      this._dstGeodeticDatum.ref2datumGeocSFCT(
        this._tmpXYZPoint2,
        this._tmpXYZPoint1
      )
    }
    this._dstGeodeticDatum.ellipsoid.geoc2geodSFCT(
      this._tmpXYZPoint1,
      this._tmpLLHPoint
    )
    e.z =
      this._tmpLLHPoint.z -
      this._dstGeodeticDatum.getHeight(this._tmpLLHPoint.x, this._tmpLLHPoint.y)
    this._projection.geodetic2cartesianOnEllipsoidSFCT(
      this._tmpLLHPoint,
      this._dstGeodeticDatum.ellipsoid,
      this._tmpXYZPoint3
    )
    this._dstGridReference.projected2gridSFCT(this._tmpXYZPoint3, e)
    return e
  }
  _inverse(t, e) {
    this._dstGridReference.grid2projectedSFCT(t, this._tmpXYZPoint3)
    this._tmpLLHPoint.move3DToCoordinates(0, 0, this._uom * t.z)
    this._projection.cartesian2geodeticOnEllipsoidSFCT(
      this._tmpXYZPoint3,
      this._dstGeodeticDatum.ellipsoid,
      this._tmpLLHPoint
    )
    this._dstGeodeticDatum.geod2geocSFCT(this._tmpLLHPoint, e)
    if (!this._equalHorizontalDatums) {
      this._dstGeodeticDatum.datum2refGeocSFCT(e, this._tmpXYZPoint2)
      this._srcGeodeticDatum.ref2datumGeocSFCT(this._tmpXYZPoint2, e)
    }
    const i = this._srcGeocentricReference.unitOfMeasure
    e.move3DToCoordinates(e.x / i, e.y / i, e.z / i)
    return e
  }
  _forwardBoundsCoords(t, e) {
    return this._bboxCalculationUtil.forwardBounds2dSFCT(t, e)
  }
  _inverseBoundsCoords(t, e) {
    return this._bboxCalculationUtil.inverseBounds2dSFCT(t, e)
  }
}
