import { ProgrammingError } from '../../error/ProgrammingError.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { isDefined, isNumber } from '../../util/Lang.js'
import { WMSImageModelOptionsUtil } from '../image/WMSImageModelOptionsUtil.js'
const EPSILON = 1e-6
export function validateTileSetModelOptions(e) {
  const t = e.bounds
  let i
  let o
  if (
    (!isDefined(e.level0Columns) && isDefined(e.level0Rows)) ||
    (isDefined(e.level0Columns) && !isDefined(e.level0Rows))
  )
    throw new Error(
      'WMSTileSetModel: if you specify one of level0Columns and level0Rows, you must also specify the other parameter'
    )
  i = e.level0Rows
  o = e.level0Columns
  if (
    (!isDefined(e.tileWidth) && isDefined(e.tileHeight)) ||
    (isDefined(e.tileWidth) && !isDefined(e.tileHeight))
  )
    throw new Error(
      'WMSTileSetModel: if you specify one of tileWidth and tileHeight, you must also specify the other parameter'
    )
  const n = e.tileWidth || 256
  const l = e.tileHeight || 256
  const r = e.levelCount || 22
  let s
  let h
  if (isNumber(i) && isNumber(o)) {
    s = t.width / (n * o)
    h = t.height / (l * i)
  } else {
    s = t.width / n
    h = t.height / l
    if (s <= h) {
      o = 2
      i = Math.min(8, Math.round(2 * (h / s)))
      s /= 2
      h /= i
    } else {
      i = 2
      o = Math.min(8, Math.round(2 * (s / h)))
      h /= 2
      s /= o
    }
  }
  const d = Math.max(s, h)
  const f = t.copy()
  f.width = o * n * d
  f.height = i * l * d
  if (f.reference && f.reference.referenceType === ReferenceType.GRID) {
    const e = f.reference.bounds
    const { ordinate: t, length: i } = normalizeBoundsDimension(
      f.x,
      f.width,
      e.x,
      e.width
    )
    const { ordinate: o, length: n } = normalizeBoundsDimension(
      f.y,
      f.height,
      e.y,
      e.height
    )
    f.setTo2D(t, i, o, n)
  }
  const c = undefined
  return {
    structure: {
      reference: f.reference,
      bounds: f,
      tileWidth: n,
      tileHeight: l,
      level0Rows: i,
      level0Columns: o,
      levelCount: r,
    },
  }
}
function normalizeBoundsDimension(e, t, i, o) {
  let n = e
  let l = t
  if (n < i) n = i
  const r = n + l
  const s = i + o
  if (r > s) {
    const e = r - s
    const t = undefined
    if (e <= n - i) n -= e + EPSILON
    else {
      n = i
      l = o
    }
  }
  return { ordinate: n, length: l }
}
export function createTileSetModelCreatorOptions(e, t, i) {
  const o = WMSImageModelOptionsUtil.createImageModelCreatorOptions(e, t, i)
  return Object.assign({}, o, {
    tileWidth: i.tileWidth,
    tileHeight: i.tileHeight,
    level0Columns: i.level0Columns,
    level0Rows: i.level0Rows,
    levelCount: i.levelCount,
  })
}
export function createTileSetModelOptions(e) {
  const t = 128
  const i = 512
  const o = 512
  if (!isNumber(e.width))
    throw new ProgrammingError(
      'The reference of the map must be a grid reference'
    )
  const n = Math.max(o / e.width, o / e.height)
  const l = undefined
  const r = e.width / e.height <= 1 ? e.width : e.height
  const s = Math.round(r * n)
  let h
  if (s >= i) h = s / Math.pow(2, s / i)
  else if (s <= t) {
    const e = t / s
    h = s * Math.pow(2, e)
  } else h = s
  return validateTileSetModelOptions({ bounds: e, tileHeight: h, tileWidth: h })
}
