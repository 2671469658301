import { GestureEventImpl } from './GestureEvent.js'
import { GestureEventType } from './GestureEventType.js'
export class DragEventImpl extends GestureEventImpl {
  constructor(e, t) {
    super(e, GestureEventType.DRAG, t)
  }
  get downEvent() {
    return this._downEvent
  }
  set downEvent(e) {
    this._downEvent = e
  }
  copy() {
    const e = new DragEventImpl(super.map, this.inputType)
    for (const t in this) if (this.hasOwnProperty(t)) e[t] = this[t]
    return e
  }
}
