import { ProgrammingError } from '../../error/ProgrammingError.js'
import { mimeType } from '../../util/mimeType.js'
import { XML } from '../../util/XML.js'
import { Codec } from '../codec/Codec.js'
import { isString } from '../../util/Lang.js'
function isErrorContentType(r) {
  return isString(r) && r.indexOf(mimeType.wms) >= 0
}
function isOWSExceptionText(r) {
  return 'ows:ExceptionText' === r.$name || 'ExceptionText' === r.$name
}
function getErrorMessage(r) {
  const e = undefined
  const o = XML.xmlStringToJson(r).searchNodes(isOWSExceptionText)
  if (o.length > 0) return o[0].$value
  else throw new Error(r)
}
function isValidOGCErrorContent(r) {
  return isErrorContentType(r.contentType)
}
export class WFSCodec extends Codec {
  constructor(r) {
    super()
    this._codec = r
  }
  decode(r) {
    if (!isString(r.content))
      throw 'Only strings are supported for WFS decoding.'
    if (isValidOGCErrorContent(r)) {
      let e
      try {
        const o = getErrorMessage(r.content)
        e = new Error(o)
      } catch (o) {
        e = new Error(r.content)
      }
      throw e
    }
    return this._codec.decode(r)
  }
  encode(r) {
    throw new ProgrammingError("WFSCodec doesn't support encoding.")
  }
}
