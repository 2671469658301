import { createArcBand, createPoint } from '../../../shape/ShapeFactory.js'
import { getReference } from '../../../reference/ReferenceProvider.js'
import { getGeodesy, getTransformation } from '../../../util/CacheUtil.js'
const CRS84 = getReference('CRS:84')
const GRID_SIZE_X = 9
const GRID_SIZE_Y = 14
function computeScaleGrid(e, n) {
  const t = e.viewToMapTransformation
  const o = e.reference.equals(n)
  const r = !o ? getTransformation(e.reference, n) : null
  const c = e.viewSize[0] / (GRID_SIZE_X - 1)
  const a = e.viewSize[1] / (GRID_SIZE_Y - 1)
  const s = []
  const i = createPoint(null, [0, 0])
  for (let n = 0; n < GRID_SIZE_Y; n++)
    for (let l = 0; l < GRID_SIZE_X; l++) {
      i.move2DToCoordinates(l * c, n * a)
      const u = transformPoint(t, i)
      const f = o ? u : transformPoint(r, u)
      if (u && f) {
        const n = e.getScaleAt(u)
        s.push({ modelPoint: f, scale: n })
      }
    }
  return s
}
function transformPoint(e, n) {
  try {
    return e && n ? e.transform(n) : null
  } catch (e) {
    return null
  }
}
function calculateLevelRadiusRange(e, n, t) {
  const o = createDistanceFunc(e)
  const r = t.length - 1
  const c = []
  let a
  let s
  for (let e = 0; e < r; e++) {
    a = t[e]
    s = t[e + 1]
    const r = n.filter((e) => e.scale >= a && e.scale <= s)
    c.push({ level: e, minMax: getMinMaxDistance(o, r) })
  }
  adjustLevelRadiusRange(c)
  return c
}
function createDistanceFunc(e) {
  const n = getGeodesy(e.reference)
  return (t) => n.distance(e, t)
}
function getMinMaxDistance(e, n) {
  const t = n.length
  if (0 === t) return null
  n.sort(function (e, n) {
    return e.scale - n.scale
  })
  const o = undefined
  const r = undefined
  return [e(n[t - 1].modelPoint), e(n[0].modelPoint)]
}
function adjustLevelRadiusRange(e) {
  const n = e.length - 1
  for (let t = 0; t < n; t++) {
    const n = e[t]
    const o = e[t + 1]
    if (n.level === o.level - 1 && n.minMax && o.minMax)
      n.minMax[0] = o.minMax[1]
  }
  return e
}
function _createArcBand(e, n) {
  return createArcBand(e.reference, e, n[0], n[1], 0, 360)
}
function _createZone(e, n) {
  const t = e.bounds.copy()
  t.setTo2DIntersection(n)
  return t
}
function calculate2DZone(e, n, t) {
  const o = e.mapScale[0]
  const r = n.length - 2
  let c = null
  for (let e = 0; e <= r; e++)
    if (n[e] <= o && o <= n[e + 1]) {
      c = e
      break
    }
  return null === c ? [] : [{ level: c, bounds: t, zone: t }]
}
function getCenterPointOnSurface(e, n) {
  const t = e.camera.eye
  const o = createPoint(e.reference, [t.x, t.y, t.z])
  const r = undefined
  const c = getTransformation(e.reference, CRS84).transform(o)
  c.z = 0
  const a = undefined
  return getTransformation(CRS84, n).transform(c)
}
function getScaleZones(e, n, t) {
  if (!e.is3D()) return calculate2DZone(e, n, t)
  const o = t.reference
  if (null === o)
    throw new Error(
      'ScaleZoneUtil: Cannot determine scale zones for null model reference'
    )
  const r = getCenterPointOnSurface(e, o)
  const c = computeScaleGrid(e, t.reference)
  const a = calculateLevelRadiusRange(r, c, n)
  a.forEach((e) => {
    if (e.minMax) {
      const n = _createArcBand(r, e.minMax)
      e.zone = n
      e.bounds = _createZone(n, t)
    }
  })
  return a
}
export const ScaleZoneUtil = {
  getScaleZones: getScaleZones,
  _computeScaleGrid: computeScaleGrid,
  _GRID_SIZE: [GRID_SIZE_X, GRID_SIZE_Y],
}
