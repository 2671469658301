import { Animation } from './Animation.js'
import { Scroller } from '../../util/Scroller.js'
import { createPoint } from '../../shape/ShapeFactory.js'
export class PanFlingAnimation extends Animation {
  constructor(t, i, o, n, a, e) {
    const r = new Scroller(a)
    r.fling(o, n)
    super(r.duration)
    this._map = t
    this._scroller = r
    this._viewPoint = i.copy()
    this._constantYaw = e
  }
  update(t) {
    if (this._scroller.update(t))
      this._map.mapNavigator.pan({
        targetLocation: this._viewPoint,
        toViewLocation: createPoint(null, [
          this._viewPoint.x + this._scroller.deltaX,
          this._viewPoint.y + this._scroller.deltaY,
        ]),
        constantYaw: this._constantYaw,
        keepAnimationRunning: true,
      })
  }
}
