import { ProgrammingError } from '../../error/ProgrammingError.js'
import { RotatedBox } from '../../util/RotatedBox.js'
import { LayerType } from '../LayerType.js'
import { PerformanceOverlay } from '../PerformanceOverlay.js'
import { PickInfoImpl } from '../PickInfo.js'
import { LabelManager } from '../style/LabelManager.js'
import { BalloonStrategy } from './BalloonStrategy.js'
import { BorderDrawingContext } from './BorderDrawingContext.js'
import { HTMLDrawingSurface } from './HTMLDrawingSurface.js'
import { SideGlowRenderer } from './SideGlowRenderer.js'
const DEFAULT_MAX_WORK_TIME_CANVAS = 4
const DEFAULT_MAX_WORK_TIME_WEBGL = 8
function accumulateInArray(e, t) {
  e.push(t)
  return e
}
export function getParameterByName(e) {
  e = e.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const t = undefined
  const r = new RegExp(`[?&]${e}=([^&#]*)`).exec(location.search)
  return null === r ? null : decodeURIComponent(r[1].replace(/\+/g, ' '))
}
export let ReadyResult = (function (e) {
  e[(e['READY'] = 1)] = 'READY'
  e[(e['NOT_READY'] = 2)] = 'NOT_READY'
  e[(e['NOT_READY_BECAUSE_OF_DEPTH'] = 3)] = 'NOT_READY_BECAUSE_OF_DEPTH'
  return e
})({})
export class AbstractViewPaintingStrategy {
  constructor(e) {
    this._map = e
    this._borderContext = null
    this._bottomBorderLabelManager = null
    this._bottomBorderLabelHtmlSurface = null
    this._leftBorderLabelManager = null
    this._leftBorderLabelHtmlSurface = null
    this._balloonStrategy = new BalloonStrategy({
      node: e._getInnerNode(),
      map: e,
    })
    this._sideGlowRenderer = new SideGlowRenderer()
    this._scheduledPrePaintCallbacks = []
    this._scheduledPostPaintCallbacks = []
    this.trackFps()
    const t = getParameterByName('debug')
    if (t && 'false' !== t) {
      console.log('Map instance available as window.lcdMap')
      window.lcdMap = e
    }
  }
  isOfPhotonType() {
    return 'Photon' === this.techContext.type
  }
  get map() {
    return this._map
  }
  get borderContext() {
    return this._borderContext
  }
  get htmlLabelSurface() {
    return this._htmlLabelSurface
  }
  get bottomBorderLabelHtmlSurface() {
    return this._bottomBorderLabelHtmlSurface
  }
  get leftBorderLabelHtmlSurface() {
    return this._leftBorderLabelHtmlSurface
  }
  get leftBorderLabelManager() {
    return this._leftBorderLabelManager
  }
  get bottomBorderLabelManager() {
    return this._bottomBorderLabelManager
  }
  get labelManager() {
    return this._labelManager
  }
  get balloonStrategy() {
    return this._balloonStrategy
  }
  get sideGlowRenderer() {
    return this._sideGlowRenderer
  }
  updateBalloons() {
    this._balloonStrategy.update()
  }
  createAxisRendererContext(e) {
    if (!this.map.axisRenderer) return
    this._borderContext = new BorderDrawingContext(
      LayerType.DYNAMIC,
      this.map._getContainerNode(),
      this.map.totalSize[0],
      this.map.totalSize[1],
      this.map.border
    )
    this._borderContext.surface.setZIndex(e)
  }
  createLabelHtmlDrawingSurface(e) {
    this._labelManager = new LabelManager()
    this._htmlLabelSurface = new HTMLDrawingSurface(
      this.map._getContainerNode(),
      {
        width: this.map.viewSize[0],
        height: this.map.viewSize[1],
        border: this.map.border,
      }
    )
    this._htmlLabelSurface.setZIndex(e)
  }
  createBottomAxisLabelHtmlDrawingSurface(e) {
    if (!this.map.axisRenderer) return
    this._bottomBorderLabelManager = new LabelManager()
    this._bottomBorderLabelHtmlSurface = new HTMLDrawingSurface(
      this.map._getContainerNode(),
      {
        width: this.map.viewSize[0],
        height: this.map.totalSize[1] - this.map.viewSize[1],
        border: this.map.border,
      }
    )
    this._bottomBorderLabelHtmlSurface.setZIndex(e)
  }
  createLeftAxisLabelHtmlDrawingSurface(e) {
    if (!this.map.axisRenderer) return
    this._leftBorderLabelManager = new LabelManager()
    this._leftBorderLabelHtmlSurface = new HTMLDrawingSurface(
      this.map._getContainerNode(),
      {
        width: this.map.totalSize[0] - this.map.viewSize[0],
        height: this.map.viewSize[1],
        border: { left: 0, bottom: 0 },
      }
    )
    this._leftBorderLabelHtmlSurface.setZIndex(e)
  }
  invalidateBorderContext() {
    if (this.borderContext) this.borderContext.valid = false
    if (this.bottomBorderLabelHtmlSurface)
      this.bottomBorderLabelHtmlSurface.valid = false
    if (this.leftBorderLabelHtmlSurface)
      this.leftBorderLabelHtmlSurface.valid = false
  }
  paintBorder() {
    if (!this.borderContext) return
    if (!this.borderContext.valid) {
      const e = !this.borderContext.valid
      this.borderContext.layers = this.map.layerTree._reduceLeaves(
        accumulateInArray,
        []
      )
      this.borderContext.paintDrawingContextSelectedAndUnSelected()
      e &&
        this.map.axisRenderer._paint(this.borderContext.surface.getContext2d())
    }
    if (
      this.bottomBorderLabelHtmlSurface &&
      this.bottomBorderLabelManager &&
      !this.bottomBorderLabelHtmlSurface.valid
    ) {
      this.bottomBorderLabelHtmlSurface.mark()
      this.bottomBorderLabelManager.drawLabelManager(
        this.bottomBorderLabelHtmlSurface,
        this.map,
        0,
        0,
        this.map.getViewWidth() + this.map.getLeftBorderSize(),
        this.map.getBottomBorderSize()
      )
      this.bottomBorderLabelHtmlSurface.sweep()
      this.bottomBorderLabelHtmlSurface.valid = true
    }
    if (
      this.leftBorderLabelHtmlSurface &&
      this.leftBorderLabelManager &&
      !this.leftBorderLabelHtmlSurface.valid
    ) {
      this.leftBorderLabelHtmlSurface.mark()
      this.leftBorderLabelManager.drawLabelManager(
        this.leftBorderLabelHtmlSurface,
        this.map,
        0,
        0,
        this.map.getLeftBorderSize(),
        this.map.getViewHeight()
      )
      this.leftBorderLabelHtmlSurface.sweep()
      this.leftBorderLabelHtmlSurface.valid = true
    }
  }
  schedule(e) {
    let t
    if (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : false)
      this._scheduledPostPaintCallbacks.push(e)
    else this._scheduledPrePaintCallbacks.push(e)
    this.repaint()
  }
  doScheduledWork(e) {
    const t = e
      ? this._scheduledPostPaintCallbacks
      : this._scheduledPrePaintCallbacks
    const r = this.isOfPhotonType()
      ? DEFAULT_MAX_WORK_TIME_WEBGL
      : DEFAULT_MAX_WORK_TIME_CANVAS
    const a = Date.now() + r
    let i = t.length
    while (i-- > 0) {
      const e = t.shift()
      if (!e) break
      e(a)
      if (Date.now() >= a) break
    }
  }
  hasScheduledWork() {
    return (
      this._scheduledPrePaintCallbacks.length > 0 ||
      this._scheduledPostPaintCallbacks.length > 0
    )
  }
  pickClosestLabels(e, t, r, a, i, o) {
    const l = this.map.domNode.getBoundingClientRect()
    const s = new RotatedBox()
    s.configure(e - r / 2, t - a / 2, r, a, 0)
    const n = this.htmlLabelSurface
      ? this.htmlLabelSurface.pickTouched(s, l, e, t, r, a, i, o)
      : []
    return i
      ? PickInfoImpl.pickBestAsPublicPickInfos(n)
      : PickInfoImpl.convertToPublicPickInfos(n)
  }
  destroy() {
    this._map = null
    this._labelManager = null
    this._bottomBorderLabelManager = null
    this._leftBorderLabelManager = null
    this._htmlLabelSurface?.destroy()
    this._bottomBorderLabelHtmlSurface?.destroy()
    this._leftBorderLabelHtmlSurface?.destroy()
    this._htmlLabelSurface = null
    this._bottomBorderLabelHtmlSurface = null
    this._leftBorderLabelHtmlSurface = null
    this._sideGlowRenderer = null
    this._balloonStrategy.cleanup()
    this._balloonStrategy = null
    this._borderContext = null
    window.lcdMap = null
  }
  isReady() {
    throw new ProgrammingError(
      'All AbstractViewPaintingStrategy subclasses must implement isReady'
    )
  }
  paint() {
    throw new ProgrammingError(
      'All AbstractViewPaintingStrategy subclasses must implement paint'
    )
  }
  repaint() {
    throw new ProgrammingError(
      'All AbstractViewPaintingStrategy subclasses must implement repaint'
    )
  }
  resize(e, t, r) {
    throw new ProgrammingError(
      'All AbstractViewPaintingStrategy subclasses must implement resize'
    )
  }
  registerLayerTree(e) {
    throw new ProgrammingError(
      'All AbstractViewPaintingStrategy subclasses must implement registerLayerTree'
    )
  }
  trackFps() {
    const e = getParameterByName('showperf')
    if (e?.startsWith('fps') || e?.startsWith('true'))
      this.paint = PerformanceOverlay.createMonitoredFunction(
        this.paint.bind(this),
        this.map._getInnerNode(),
        e?.includes('avg'),
        e?.includes('min'),
        e?.startsWith('true')
      )
    if (e?.includes('max')) {
      console.log('Continuous repaints enabled.')
      const e = () => {
        this.invalidate()
        window.requestAnimationFrame(e)
      }
      window.requestAnimationFrame(e)
    }
  }
}
