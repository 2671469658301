import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { isNumber } from '../util/Lang.js'
import { normalizeLon, subtractLon } from '../util/LonLatCoord.js'
import { Conic } from './Conic.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('AlbersEqualAreaConic')
const NORTH_LAT_LIMIT = 89
const SOUTH_LAT_LIMIT = -89
export class AlbersEqualAreaConic extends Conic {
  constructor(t, s, i, n) {
    super()
    this._n = -1
    this._c = -1
    this._rho0overR = -1
    this._originSinLat = -1
    this._firstParallelCos = -1
    this._firstParallelSin = -1
    this._secondParallelCos = -1
    this._secondParallelSin = -1
    this._A = -1
    this._E = -1
    this._Rho0 = -1
    this._N = -1
    this._C = -1
    this._Cte = -1
    this._C2 = -1
    this._C4 = -1
    this._C6 = -1
    this._Qc = -1
    this._tempLLH = new LLHPoint()
    this._hash = 0
    this.setFirstParallel(isNumber(t) ? t : 29.5)
    this.setSecondParallel(isNumber(s) ? s : 45.5)
    this.setOriginLon(isNumber(i) ? i : 3)
    this.setOriginLat(isNumber(n) ? n : 50)
    this.calculateCachedValues()
  }
  calculateCachedValues() {
    const t = this.getOriginLat() * Constants.DEG2RAD
    const s = this.getFirstParallel() * Constants.DEG2RAD
    const i = this.getSecondParallel() * Constants.DEG2RAD
    const n = Math.cos(s)
    const h = Math.cos(i)
    const o = Math.sin(s)
    const a = Math.sin(i)
    this._n = (o + a) / 2
    this._c = n * n + 2 * this._n * o
    this._rho0overR = Math.sqrt(this._c - 2 * this._n * Math.sin(t)) / this._n
    this._originSinLat = Math.sin(t)
    this._firstParallelCos = n
    this._firstParallelSin = o
    this._secondParallelCos = h
    this._secondParallelSin = a
    this._A = -1
    this._E = -1
    const e = new Hash()
    e.appendDouble(this.getOriginLon())
      .appendDouble(this.getOriginLat())
      .appendDouble(this.getFirstParallel())
      .appendDouble(this.getSecondParallel())
      .appendUInt32(this.TYPE)
    this._hash = e.getHashCode()
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, s, i) {
    if (this.inLonLatBounds(t)) {
      const n =
        this._n * subtractLon(this.getOriginLon(), t.x) * Constants.DEG2RAD
      const h =
        (s *
          Math.sqrt(
            this._c - 2 * this._n * Math.sin(t.y * Constants.DEG2RAD)
          )) /
        this._n
      const o = h * Math.sin(n)
      const a = s * this._rho0overR - h * Math.cos(n)
      i.x = o
      i.y = a
    } else throw sharedOutOfBoundsError
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, i) {
    if (this.inLonLatBounds(t)) {
      this.updateCachedValues(s)
      const n = Math.sin(t.y * Constants.DEG2RAD)
      const h = this._E * n
      const o = 1 - h * h
      const a =
        0 !== this._E
          ? (1 - s.e2) * (n / o - this._Cte * Math.log((1 - h) / (1 + h)))
          : 2 * n
      const e = (this._A * Math.sqrt(this._C - this._N * a)) / this._N
      const r =
        this._N * subtractLon(this.getOriginLon(), t.x) * Constants.DEG2RAD
      const _ = e * Math.sin(r)
      const l = this._Rho0 - e * Math.cos(r)
      i.x = _
      i.y = l
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnSphereSFCT(t, s, i) {
    if (this.inWorldBoundsOnSphere(t, s)) {
      let n = this._rho0overR * s
      let h = t.x
      let o = t.y
      const a = Math.sqrt(h * h + (n - o) * (n - o))
      if (this._n < 0) {
        n = -n
        o = -o
        h = -h
      }
      const e = Math.atan2(h, n - o)
      const r = (a * this._n) / s
      const _ = (this._c - r * r) / (2 * this._n)
      if (Math.abs(_) > 1) throw sharedOutOfBoundsError
      i.x = normalizeLon(
        (e / this._n) * Constants.RAD2DEG + this.getOriginLon()
      )
      i.y = Math.asin(_) * Constants.RAD2DEG
    } else throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, i) {
    if (this.inWorldBoundsOnEllipsoid(t, s)) {
      this.updateCachedValues(s)
      const n = this._A
      const h = this._E
      const o = s.e2
      const a = this._Cte
      const e = this._N
      let r = t.x
      let _ = this._Rho0 - t.y
      if (e < 0) {
        r = -r
        _ = -_
      }
      const l = Math.sqrt(r * r + _ * _)
      const c = Constants.RAD2DEG * Math.atan2(r, _)
      const L = (this._C - (l * l * e * e) / (n * n)) / e
      const M = normalizeLon(c / e + this.getOriginLon())
      let d
      if (Math.abs(Math.abs(L) - Math.abs(this._Qc)) < 1e-12)
        if (L >= 0) d = 90
        else d = -90
      else {
        const t = L / this._Qc
        if (Math.abs(t) > 1) throw sharedOutOfBoundsError
        const s = Math.asin(t)
        const i = undefined
        d =
          (s +
            this._C2 * Math.sin(2 * s) +
            this._C4 * Math.sin(4 * s) +
            this._C6 * Math.sin(6 * s)) *
          Constants.RAD2DEG
      }
      i.x = M
      i.y = d
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    if (this._n > 0) return t.y >= SOUTH_LAT_LIMIT
    else if (this._n < 0) return t.y <= NORTH_LAT_LIMIT
    else return t.y >= SOUTH_LAT_LIMIT && t.y <= NORTH_LAT_LIMIT
  }
  inWorldBoundsOnSphere(t, s) {
    let i = s * this._rho0overR - t.y
    let n = t.x
    const h = n * n + i * i
    let o
    if (this._n >= 0) o = SOUTH_LAT_LIMIT * Constants.DEG2RAD
    else o = NORTH_LAT_LIMIT * Constants.DEG2RAD
    const a =
      ((s * s * (this._c + 2 * Math.abs(this._n * Math.sin(o)))) /
        (this._n * this._n)) *
      (1 + 1e-13)
    const e = Math.abs(this._n * Math.PI) + 1e-14
    if (this._n < 0) {
      n = -n
      i = -i
    }
    const r = Math.atan2(n, i)
    return h <= a && r <= e && r >= -e
  }
  inWorldBoundsOnEllipsoid(t, s) {
    this.updateCachedValues(s)
    let i = this._Rho0 - t.y
    let n = t.x
    const h = n * n + i * i
    const o =
      this._N >= 0
        ? SOUTH_LAT_LIMIT * Constants.DEG2RAD
        : NORTH_LAT_LIMIT * Constants.DEG2RAD
    const a = Math.sin(o)
    const e = this._E * a
    const r = 1 - e * e
    const _ =
      0 != this._E
        ? (1 - s.e2) * (a / r - this._Cte * Math.log((1 - e) / (1 + e)))
        : 2 * a
    const l =
      ((this._A * this._A * (this._C - this._N * _)) / (this._N * this._N)) *
      (1 + 1e-13)
    const c = Math.abs(this._N * Math.PI) + 1e-14
    if (this._N < 0) {
      n = -n
      i = -i
    }
    const L = Math.atan2(n, i)
    return h <= l && L <= c && L >= -c
  }
  boundaryLons(t) {
    this._tempLLH.move2D(0, t)
    if (this.inLonLatBounds(this._tempLLH)) {
      const t = normalizeLon(-180 + this.getOriginLon() + this.EPSILON / 4)
      const s = normalizeLon(180 + this.getOriginLon() - this.EPSILON / 4)
      const i = []
      i[0][0] = t
      i[0][1] = s
      return i
    }
    return []
  }
  boundaryLats(t) {
    const s = []
    if (this._n > 0) {
      s[0][0] = SOUTH_LAT_LIMIT
      s[0][1] = 90
    } else if (this._n < 0) {
      s[0][0] = -90
      s[0][1] = NORTH_LAT_LIMIT
    } else {
      s[0][0] = SOUTH_LAT_LIMIT
      s[0][1] = NORTH_LAT_LIMIT
    }
    return s
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    let i, n, h, o
    if (this._n > 0) i = SOUTH_LAT_LIMIT * Constants.DEG2RAD
    else i = NORTH_LAT_LIMIT * Constants.DEG2RAD
    const a = Math.abs(
      (t * Math.sqrt(this._c + 2 * Math.abs(this._n * Math.sin(i)))) / this._n
    )
    const e = Math.abs(this._n * Math.PI)
    o = a
    if (e < Math.PI / 2) o = a * Math.sin(e)
    n = t * this._rho0overR - a
    h = t * this._rho0overR + a * Math.sin(Math.max(0, e - Math.PI / 2))
    if (this._n < 0) {
      h = t * this._rho0overR + a
      n = t * this._rho0overR - a * Math.sin(Math.max(0, e - Math.PI / 2))
    }
    n *= 1 + 1e-15
    h *= 1 + 1e-15
    o *= 1 + 1e-15
    s.setTo2D(-o, 2 * o, n, h - n)
  }
  cartesianBoundsOnEllipsoidSFCT(t, s) {
    this.updateCachedValues(t)
    let i
    if (this._N > 0) i = SOUTH_LAT_LIMIT * Constants.DEG2RAD
    else i = NORTH_LAT_LIMIT * Constants.DEG2RAD
    const n = Math.sin(i)
    const h = this._E * n
    const o = 1 - h * h
    const a =
      0 != this._E
        ? (1 - t.e2) * (n / o - this._Cte * Math.log((1 - h) / (1 + h)))
        : 2 * n
    const e = Math.abs((this._A * Math.sqrt(this._C - this._N * a)) / this._N)
    const r = Math.abs(this._N * Math.PI) + 1e-14
    let _ = e
    if (r < Math.PI / 2) _ = e * Math.sin(r)
    let l = this._Rho0
    let c = this._Rho0
    if (this._N < 0) {
      l -= e * Math.sin(Math.max(0, r - Math.PI / 2))
      c += e
    } else {
      l -= e
      c += e * Math.sin(Math.max(0, r - Math.PI / 2))
    }
    l *= 1 + 1e-15
    c *= 1 + 1e-15
    _ *= 1 + 1e-15
    s.setTo2D(-_, 2 * _, l, c - l)
  }
  updateCachedValues(t) {
    if (this._A === t.a && this._E === t.e) return
    this._A = t.a
    this._E = t.e
    const s = t.e2
    this._Cte = 1 / (2 * this._E)
    const i = this._E * this._originSinLat
    const n = this._E * this._firstParallelSin
    const h = this._E * this._secondParallelSin
    const o = 1 - n * n
    const a = this._firstParallelCos / Math.sqrt(o)
    const e = 1 - h * h
    const r = this._secondParallelCos / Math.sqrt(e)
    const _ = 1 - i * i
    let l, c, L
    if (0 != this._E) {
      l =
        (1 - s) *
        (this._originSinLat / _ - this._Cte * Math.log((1 - i) / (1 + i)))
      c =
        (1 - s) *
        (this._firstParallelSin / o - this._Cte * Math.log((1 - n) / (1 + n)))
      L =
        (1 - s) *
        (this._secondParallelSin / e - this._Cte * Math.log((1 - h) / (1 + h)))
    } else {
      l = 2 * this._originSinLat
      c = 2 * this._firstParallelSin
      L = 2 * this._secondParallelSin
    }
    if (this.getFirstParallel() != this.getSecondParallel())
      this._N = (a * a - r * r) / (L - c)
    else this._N = this._firstParallelSin
    this._C = a * a + this._N * c
    this._Rho0 = (this._A * Math.sqrt(this._C - this._N * l)) / this._N
    const M = s * s
    const d = M * s
    this._C2 = (1 / 3) * s + (31 / 180) * M + (517 / 5040) * d
    this._C4 = (23 / 360) * M + (251 / 3780) * d
    this._C6 = (761 / 45360) * d
    this._Qc =
      1 - ((1 - s) / (2 * this._E)) * Math.log((1 - this._E) / (1 + this._E))
  }
  encode() {
    return {
      type: 'AlbersEqualAreaConic',
      firstParallel: this.getFirstParallel(),
      secondParallel: this.getSecondParallel(),
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.ALBERS_EQUAL_AREA_CONIC + ProjectionType.CONIC
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
