import { Photon } from '../../../../gen/photon/photon_painter.js'
import { Hash } from '../../../../util/Hash.js'
import { ObjectReleaseTracker } from '../../../../util/ObjectReleaseTracker.js'
import { XYZPoint } from '../../../../shape/XYZPoint.js'
import { GeoDiscretizerStatic } from '../../../shape/discretization/GeoDiscretizerStatic.js'
import { PhotonCommandUtil } from './PhotonCommandUtil.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
import { getZStyleForPoint, isAboveGroundReference } from './ZStyleUtil.js'
function PhotonPlotCommand(t, e, o, r, i) {
  this._is3d = t
  this._worldReference = e
  this._shapePainter = o
  this._invalidator = r
  this._imageProvider = i
  this._hash = new Hash()
  this._coordinatesPool = [new XYZPoint(null)]
  this._attributesBuffer = null
}
PhotonPlotCommand.prototype = Object.create(ObjectReleaseTracker.prototype)
PhotonPlotCommand.prototype.constructor = PhotonPlotCommand
PhotonPlotCommand.prototype.canHandle = function (t) {
  return 'shape' === t.type && !!t.style.isPlotStyle
}
PhotonPlotCommand.prototype._getAttributesBuffer = function (t) {
  if (!this._attributesBuffer || this._attributesBuffer.size !== t) {
    this._attributesBuffer = this.untrack(this._attributesBuffer)
    this._attributesBuffer = this.track(
      Photon.BufferFactory.createFloat32BufferFromLength(t)
    )
  }
  return this._attributesBuffer
}
PhotonPlotCommand.prototype.add = function (t) {
  const { shape: e, style: o, zStyle: r, feature: i } = t
  const s = e.focusPoint
  o.syncWithShapePainter(
    this._shapePainter,
    Photon,
    this._invalidator,
    this._imageProvider,
    this._hash
  )
  const n = GeoDiscretizerStatic.discretizePoint(s, {
    modelReference: s.reference,
    worldReference: this._worldReference,
    coordinatesPool: this._coordinatesPool,
    zToZero: r.zToZero,
  })
  if (null === n) return
  const a = [0]
  const h =
    r.drapeTarget === DrapeTarget.NOT_DRAPED && this._is3d
      ? r.hasDepth
        ? 1
        : 0
      : -1
  const d = this._getAttributesBuffer(o.getAttributeCount())
  o.fillAttributes(i, s, 1, a, d.typedArray)
  this._shapePainter.addPlot(t.objectId, t.geometryId, t.styleId, n, a[0], h, d)
}
PhotonPlotCommand.prototype.updateStyle = function (t, e) {
  var o
  t.style.syncWithShapePainter(
    this._shapePainter,
    Photon,
    this._invalidator,
    this._imageProvider,
    this._hash
  )
  this._shapePainter.updatePlotStyle(t.objectId, t.geometryId, e, t.styleId)
}
PhotonPlotCommand.prototype.updateGeometry = function (t, e) {
  const { shape: o, style: r, zStyle: i, feature: s } = t
  const n = o.focusPoint
  r.syncWithShapePainter(
    this._shapePainter,
    Photon,
    this._invalidator,
    this._imageProvider,
    this._hash
  )
  const a = GeoDiscretizerStatic.discretizePoint(n, {
    modelReference: n.reference,
    worldReference: this._worldReference,
    coordinatesPool: this._coordinatesPool,
    zToZero: i.zToZero,
  })
  if (null === a) {
    this._shapePainter.removeGeometry(t.objectId, e, t.styleId)
    return
  }
  const h = [0]
  const d =
    i.drapeTarget === DrapeTarget.NOT_DRAPED && this._is3d
      ? i.hasDepth
        ? 1
        : 0
      : -1
  const l = this._getAttributesBuffer(r.getAttributeCount())
  r.fillAttributes(s, n, 1, h, l.typedArray)
  this._shapePainter.updatePlotGeometry(
    t.objectId,
    e,
    t.geometryId,
    a,
    h[0],
    d,
    l
  )
}
PhotonPlotCommand.prototype._getGeometryId = function (t, e, o) {
  this._hash.reset()
  t.hashCode(this._hash)
  if (t.reference) t.reference.hashCode(this._hash)
  this._hash.appendBoolean(isAboveGroundReference(t))
  this._hash.appendUInt32(o.drapeTarget)
  this._hash.appendBoolean(o.aboveGround)
  this._hash.append(e.heading)
  var r = this
  e.attributes.forEach(function (t) {
    r._hash.append(t)
  })
  return this._hash.getHashCode()
}
PhotonPlotCommand.prototype.resolveStyle = function (t) {}
PhotonPlotCommand.prototype.getDrawItems = function (t) {
  var e = []
  for (var o = 0; o < t.length; o++) {
    var r = t[o].shape
    var i = t[o].style
    var s = getZStyleForPoint(
      r.focusPoint,
      i.draped,
      i.drapeTarget,
      this._is3d,
      null
    )
    var n = {
      shape: r,
      style: i,
      geometryId: this._getGeometryId(r, i, s),
      styleId: i.id,
      zStyle: s,
    }
    e.push(n)
  }
  return e
}
PhotonPlotCommand.prototype.normalizeStyle = function (t) {
  return t
}
PhotonPlotCommand.prototype.usesPhotonShapePainter = function () {
  return true
}
export { PhotonPlotCommand }
