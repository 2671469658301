import { coordinate_fromSimplePoint } from '../../../shape/PointCoordinate.js'
import { simplePointCopy } from '../../../shape/SimplePoint.js'
import { XYZBounds } from '../../../shape/XYZBounds.js'
import { XYZCircleByCenterPoint } from '../../../shape/XYZCircleByCenterPoint.js'
import { XYZCircularArcByCenterPoint } from '../../../shape/XYZCircularArcByCenterPoint.js'
import { XYZPoint } from '../../../shape/XYZPoint.js'
import { XYZPolygon } from '../../../shape/XYZPolygon.js'
import {
  closestPointOnSegment,
  distance2D,
  forwardAzimuth2D,
  intersection2DLSSFCT,
  normalizeAngle,
} from '../../../util/Cartesian.js'
import { ConstructiveGeometry } from '../../ConstructiveGeometry.js'
import { TopologyUtilCartesian } from '../../topologyutil/TopologyUtilCartesian.js'
import { CompositeConstructiveGeometryOperator } from '../CompositeConstructiveGeometryOperator.js'
import { GeoBufferHelper } from './GeoBufferHelper.js'
const _MIN_TOLERANCE = 1e-4
export class XYZGeoBufferHelper extends GeoBufferHelper {
  constructor(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super(t)
    this.TOLERANCE = Math.max(_MIN_TOLERANCE, e) || _MIN_TOLERANCE
    this.pointSFCT = this.createPoint({ x: 0, y: 0, z: 0 })
  }
  getConstructiveGeometry() {
    return new ConstructiveGeometry(
      new CompositeConstructiveGeometryOperator(
        new TopologyUtilCartesian(this.TOLERANCE)
      )
    )
  }
  getTopologyUtil() {
    return new TopologyUtilCartesian(this.TOLERANCE)
  }
  createBounds(t) {
    return new XYZBounds(this.REF, [t.x, 0, t.y, 0])
  }
  createPoint(t) {
    return new XYZPoint(this.REF, [t.x, t.y, 0])
  }
  createPolygon(t) {
    const e = (t = t || []).map((t) => coordinate_fromSimplePoint(t))
    return new XYZPolygon(this.REF, e)
  }
  createCircle(t, e) {
    return new XYZCircleByCenterPoint(this.REF, this.createPoint(t), e)
  }
  createCircularArc(t, e, o, r) {
    return new XYZCircularArcByCenterPoint(
      this.REF,
      this.createPoint(t),
      e,
      o,
      r
    )
  }
  turn(t, e, o) {
    let r = e.x - t.x
    let n = e.y - t.y
    const i = undefined
    const s = undefined
    const a = r
    r = -n
    n = a
    const c = r * (e.x - o.x) + n * (e.y - o.y)
    if (c < 0) return this.LEFT
    else if (c > 0) return this.RIGHT
    else return 0
  }
  angle(t, e, o) {
    const r = e.x - t.x,
      n = e.y - t.y,
      i = o.x - e.x,
      s = o.y - e.y,
      a = Math.atan2(n, r),
      c = Math.atan2(s, i)
    return normalizeAngle((180 * (c - a)) / Math.PI)
  }
  pointAtDistance(t, e, o) {
    if (o < 0) o += 2 * Math.PI
    const r = this.createPoint(t)
    if (0 == o) r.translate(e, 0)
    else if (o === 0.5 * Math.PI) r.translate(0, e)
    else if (o === Math.PI) r.translate(-e, 0)
    else if (o === 1.5 * Math.PI) r.translate(0, -e)
    else r.translate(Math.cos(o) * e, Math.sin(o) * e)
    return r
  }
  azimuth(t, e) {
    return forwardAzimuth2D(t, e)
  }
  distance(t, e, o) {
    return o
      ? closestPointOnSegment(t, e, o, simplePointCopy(t)).distance
      : distance2D(t, e)
  }
  intersection(t, e, o, r) {
    intersection2DLSSFCT(t.x, t.y, e.x, e.y, o.x, o.y, r.x, r.y, this.pointSFCT)
    return simplePointCopy(this.pointSFCT)
  }
  discretizeCircularArc(t, e, o, r) {
    const n = (this.MAX_DISCRETIZATION_POINTS * Math.abs(r)) / (2 * Math.PI)
    let i = this.MIN_DISCRETIZATION_POINTS
    let s = r / i
    const a = e * this.MAX_RELATIVE_DISCRETIZATION_ERROR
    const c = simplePointCopy(t)
    while (i < n) {
      const o = this.pointAtDistance(t, e, 0)
      const n = this.pointAtDistance(t, e, s)
      const l = this.pointAtDistance(t, e, s / 2)
      if (closestPointOnSegment(o, n, l, c).distance <= a) break
      i *= 2
      s = r / i
    }
    i++
    const l = []
    for (let r = 0; r < i; r++) {
      const n = o + r * s
      l[r] = this.pointAtDistance(t, e, n)
    }
    return l
  }
  boundsTooLarge() {
    return false
  }
}
