import { ProgrammingError } from '../error/ProgrammingError.js'
import { isBoolean, isNumber, isObject, isString } from '../util/Lang.js'
import { URL } from '../util/URL.js'
export function isValidRequestParameterValue(r) {
  return isNumber(r) || isString(r) || isBoolean(r)
}
export function validateDimensions(r) {
  if (!isObject(r))
    throw new ProgrammingError('dimensions: must assign an object literal')
  if (null !== r) {
    const i = {}
    for (const e in r)
      if (r.hasOwnProperty(e) && isValidRequestParameterValue(r[e])) i[e] = r[e]
      else
        throw new ProgrammingError(
          `${e} is an invalid dimension. The dimension value is not allowed.`
        )
    return i
  } else return null
}
export function buildDimensionsQueryString(r) {
  const i = {}
  for (const e in r)
    if (r.hasOwnProperty(e)) {
      const n = e.toLowerCase()
      let o
      if (0 === n.indexOf('dim_')) o = e
      else if ('time' !== n && 'elevation' !== n) o = 'DIM_' + e
      else o = e
      i[o] = r[e]
    }
  const e = '&' + URL.buildQueryString(i)
  if ('&' !== e) return e
  else return ''
}
