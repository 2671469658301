import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Cylindrical } from './Cylindrical.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('EquidistantCylindrical')
export class EquidistantCylindrical extends Cylindrical {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let a = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._standardParallel = 0
    this._cosStandardParallel = 1
    this._hash = 0
    this.setCentralMeridian(t)
    this.setStandardParallel(a)
    this.calculateCachedValues()
  }
  get cosStandardParallel() {
    return this._cosStandardParallel
  }
  setStandardParallel(t) {
    this._standardParallel = t
    this._cosStandardParallel = Math.cos(t * Constants.DEG2RAD)
    this.calculateCachedValues()
  }
  getStandardParallel() {
    return this._standardParallel
  }
  isAllInBounds() {
    return true
  }
  geodetic2cartesianOnSphereSFCT(t, a, r) {
    const n = normalizeLon(t.x - this.getCentralMeridian())
    r.x = a * n * this._cosStandardParallel * Constants.DEG2RAD
    r.y = a * t.y * Constants.DEG2RAD
  }
  cartesian2geodeticOnSphereSFCT(t, a, r) {
    if (this.inWorldBoundsOnSphere(t, a)) {
      const n = (t.x * Constants.RAD2DEG) / (a * this._cosStandardParallel)
      r.x = normalizeLon(n + this.getCentralMeridian())
      r.y = (t.y * Constants.RAD2DEG) / a
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    return true
  }
  boundaryLons(t) {
    return [
      [
        normalizeLon(-180 + this.getCentralMeridian() + this.EPSILON / 2),
        normalizeLon(180 + this.getCentralMeridian() - this.EPSILON / 2),
      ],
    ]
  }
  boundaryLats(t) {
    return [[-90, 90]]
  }
  inWorldBoundsOnSphere(t, a) {
    const r = a * Math.PI * (1 + 1e-15)
    const n = t.x
    const e = t.y
    return (
      n >= -r * this._cosStandardParallel &&
      n <= r * this._cosStandardParallel &&
      e >= -r / 2 &&
      e <= r / 2
    )
  }
  cartesianBoundsOnSphereSFCT(t, a) {
    const r = t * Math.PI
    const n = r * this._cosStandardParallel
    a.setTo2D(-n, 2 * n, -r / 2, r)
  }
  encode() {
    return {
      type: 'EquidistantCylindrical',
      centralMeridian: this.getCentralMeridian(),
      standardParallel: this.getStandardParallel(),
    }
  }
  calculateCachedValues() {
    const t = new Hash()
    this._hash = t
      .appendDouble(this.getCentralMeridian())
      .appendDouble(this.getStandardParallel())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  get TYPE() {
    return ProjectionType.EQUIDISTANT_CYLINDRICAL + ProjectionType.CYLINDRICAL
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
