import { ConflictType, LabelConflictChecker } from './LabelConflictChecker.js'
export let LabelGroupName = (function (e) {
  e['GROUP_DEFAULT'] = 'DEFAULT'
  e['GROUP_NON_DECLUTTERED'] = 'NON_DECLUTTERED'
  e['GROUP_CONTROLLER'] = '_CONTROLLER'
  return e
})({})
function sortAscending(e, t) {
  if (e.selected !== t.selected) return e.selected ? 1 : -1
  const i = e.priority
  const r = t.priority
  return i < r ? 1 : i > r ? -1 : e.index - t.index
}
function sortDescending(e, t) {
  if (e.selected !== t.selected) return e.selected ? -1 : 1
  const i = e.priority
  const r = t.priority
  return i < r ? -1 : i > r ? 1 : e.index - t.index
}
const NON_DECLUTTERED_CONFLICT_CHECKER = {
  resetViewWidthHeight: (e, t, i, r) => null,
  clear: () => null,
  addPosition: () => null,
  isValidPosition: (e) => ConflictType.NO_CONFLICT,
}
export class HTMLLabelGroup {
  constructor(e, t) {
    this._groupName = e
    this._labelsToDraw = []
    if (t) {
      this._conflictChecker = t
      this._comparator = sortDescending
    } else if (this._groupName === LabelGroupName.GROUP_NON_DECLUTTERED) {
      this._conflictChecker = NON_DECLUTTERED_CONFLICT_CHECKER
      this._comparator = sortAscending
    } else {
      this._conflictChecker = new LabelConflictChecker()
      this._comparator = sortDescending
    }
  }
  clear() {
    this._labelsToDraw.length = 0
  }
  addLabel(e) {
    this._labelsToDraw.push(e)
  }
  drawLabelGroup(e, t, i, r, o, l) {
    this._conflictChecker.resetViewWidthHeight(i, r, o, l)
    this._conflictChecker.clear()
    let s, c
    for (let e = 0; e < this._labelsToDraw.length; e++)
      this._labelsToDraw[e].index = e
    this._labelsToDraw.sort(this._comparator)
    for (let i = 0; i < this._labelsToDraw.length; i++) {
      const r = this._labelsToDraw[i]
      c = this._groupName === LabelGroupName.GROUP_NON_DECLUTTERED ? i : null
      s = r.placeHtml(e, t, this._conflictChecker, c)
      if (s) this._conflictChecker.addPosition(r)
    }
  }
}
