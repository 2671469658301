import { ProgrammingError } from '../../error/ProgrammingError.js'
import { XYZBounds } from '../../shape/XYZBounds.js'
import { XYZPolygon } from '../../shape/XYZPolygon.js'
import { XYZPolyline } from '../../shape/XYZPolyline.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
import {
  closestPointOnSegment as CartesianClosestPointOnSegment,
  contains2D,
  distance2D,
} from '../../util/Cartesian.js'
import { CartesianAdvancedBinaryTopologyUtil } from './CartesianAdvancedBinaryTopologyUtil.js'
import { TopologyUtil } from './TopologyUtil.js'
const EPSILON = 1e-10
const dummyPoint = new XYZPoint()
class TopologyUtilCartesian extends TopologyUtil {
  constructor(t) {
    super(t)
    this._advancedBinaryTopologyUtil = new CartesianAdvancedBinaryTopologyUtil(
      t
    )
  }
  get advancedBinaryTopologyUtil() {
    return this._advancedBinaryTopologyUtil
  }
  set advancedBinaryTopologyUtil(t) {
    throw new ProgrammingError(
      'advancedBinaryTopologyUtil property is not mutable'
    )
  }
  certainlyNoInteraction4Points(t, n, e, o) {
    const i = Math.max(t.x, n.x)
    const r = Math.min(t.x, n.x)
    const a = Math.max(e.x, o.x)
    const s = Math.min(e.x, o.x)
    const l = Math.max(t.y, n.y)
    const u = Math.min(t.y, n.y)
    const y = Math.max(e.y, o.y)
    const c = Math.min(e.y, o.y)
    const h = this.toleranceEqualPoints
    if (s > i + h || a < r - h) return true
    if (c > l + h || y < u - h) return true
    return false
  }
  certainlyNoInteraction3Points(t, n, e) {
    if (
      e.x > Math.max(t.x, n.x) + this.toleranceEqualPoints ||
      e.x < Math.min(t.x, n.x) - this.toleranceEqualPoints
    )
      return true
    return (
      e.y > Math.max(t.y, n.y) + this.toleranceEqualPoints ||
      e.y < Math.min(t.y, n.y) - this.toleranceEqualPoints
    )
  }
  getToleranceX(t, n) {
    return this.toleranceEqualPoints
  }
  getToleranceY(t, n) {
    return this.toleranceEqualPoints
  }
  isPointOnLineSegment(t, n, e) {
    let o, i, r
    if (this.pointsEqual(t, n)) return this.pointsEqual(e, t)
    else if (Math.abs(t.x - n.x) < EPSILON) {
      o = (e.y - t.y) / (n.y - t.y)
      return Math.abs(e.x - t.x) < this.toleranceEqualPoints && o >= 0 && o <= 1
    } else if (Math.abs(t.y - n.y) < EPSILON) {
      i = (e.x - t.x) / (n.x - t.x)
      return Math.abs(e.y - t.y) < this.toleranceEqualPoints && i >= 0 && i <= 1
    } else
      return this.distancePointLineSegment(t, n, e) < this.toleranceEqualPoints
  }
  distancePointPoint(t, n) {
    return distance2D(t, n)
  }
  distancePointLineSegment(t, n, e) {
    return CartesianClosestPointOnSegment(e, t, n, dummyPoint).distance
  }
  pointsEqual(t, n) {
    return (
      Math.abs(t.x - n.x) <= this.toleranceEqualPoints &&
      Math.abs(t.y - n.y) <= this.toleranceEqualPoints
    )
  }
  lineSegmentIntersection(t, n, e, o, i) {
    let r = 0,
      a
    if (linesParallel(t, n, e, o)) {
      if (r < 2 && contains2D(t, n, e))
        i[r++] = new XYZPoint(e.reference, [e.x, e.y])
      if (
        r < 2 &&
        !(1 === r && this.pointsEqualWithoutTolerance(i[0], o)) &&
        contains2D(t, n, o)
      )
        i[r++] = new XYZPoint(o.reference, [o.x, o.y])
      if (
        r < 2 &&
        !(1 === r && this.pointsEqualWithoutTolerance(i[0], t)) &&
        contains2D(e, o, t)
      )
        i[r++] = new XYZPoint(t.reference, [t.x, t.y])
      if (
        r < 2 &&
        !(1 === r && this.pointsEqualWithoutTolerance(i[0], n)) &&
        contains2D(e, o, n)
      )
        i[r++] = new XYZPoint(n.reference, [n.x, n.y])
    } else {
      a = new XYZPoint(t.reference)
      if (intersection2DLSSFCT(t, n, e, o, a)) i[r++] = a
    }
    return r
  }
  growBounds(t, n) {
    const e = new XYZPoint(n.reference)
    e.move2D(n.x - t, n.y - t)
    n.setToIncludePoint2D(e)
    e.move2D(n.x + n.width + t, n.y + n.height + t)
    n.setToIncludePoint2D(e)
  }
  getAveragePoint(t, n) {
    return new XYZPoint(t.reference, [(t.x + n.x) / 2, (t.y + n.y) / 2])
  }
  getAngle(t, n) {
    let e = Math.atan2(n.y - t.y, n.x - t.x)
    if (e < 0) e += 2 * Math.PI
    return e
  }
  boundsDontIntersectSegment(t, n, e) {
    let o, i, r, a, s, l, u, y, c, h
    o = t.copy()
    this.growBounds(this.toleranceEqualPoints, o)
    if (null === e) return !o.contains2DPoint(n)
    i = n.x
    r = n.y
    a = e.x
    s = e.y
    if (i > a) {
      l = i
      i = a
      a = l
    }
    if (r > s) {
      l = r
      r = s
      s = l
    }
    u = o.x
    y = o.y
    c = u + o.width
    h = y + o.height
    if (i < u && a < u) return true
    if (i > c && a > c) return true
    if (r < y && s < y) return true
    if (r > h && s > h) return true
    return false
  }
  getNewEditablePolyline(t, n) {
    if (n.length > 0) return new XYZPolyline(t, n)
    return new XYZPolyline(t)
  }
  getNewEditablePolygon(t, n) {
    if (n.length > 0) return new XYZPolygon(t, n)
    return new XYZPolygon()
  }
  getNewEditableBoundsFromPoint(t) {
    return new XYZBounds(t.reference, [t.x, 0, t.y, 0])
  }
  getNewEditableBoundsFromBounds(t) {
    return new XYZBounds(t.reference, [t.x, t.width, t.y, t.height])
  }
  getNewEditableBoundsFromCoordinates(t, n, e, o, i) {
    return new XYZBounds(t, [n, o, e, i])
  }
}
TopologyUtil.prototype.closestPointOnLineSegment = function (t, n, e, o) {
  const i = undefined
  return CartesianClosestPointOnSegment(e, t, n, o).distance
}
TopologyUtilCartesian.prototype.pointsEqualWithoutTolerance =
  pointsEqualWithoutTolerance
TopologyUtilCartesian.pointsEqualWithoutTolerance = pointsEqualWithoutTolerance
function linesParallel(t, n, e, o) {
  const i = (n.y - t.y) * (o.x - e.x) - (n.x - t.x) * (o.y - e.y)
  return Math.abs(i) < EPSILON
}
function intersection2DLSSFCT(t, n, e, o, i) {
  const r = t.x,
    a = t.y,
    s = n.x,
    l = n.y,
    u = e.x,
    y = e.y,
    c = o.x,
    h = o.y,
    P = (l - a) * (c - u) - (s - r) * (h - y),
    x = ((c - u) * (y - a) - (h - y) * (u - r)) / P,
    g = ((s - r) * (y - a) - (l - a) * (u - r)) / P
  i.move2D(u + g * (c - u), y + g * (h - y))
  return g >= 0 && g <= 1 && x >= 0 && x <= 1
}
function pointsEqualWithoutTolerance(t, n) {
  return Math.abs(t.x - n.x) < EPSILON && Math.abs(t.y - n.y) < EPSILON
}
export { TopologyUtilCartesian }
