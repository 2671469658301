import { Hash } from '../util/Hash.js'
import { isUndefined } from '../util/Lang.js'
import { CoordinateReference } from './CoordinateReference.js'
export class GeoReference extends CoordinateReference {
  constructor(e) {
    super(e)
    this._type = null
    this._prefix = null
    this._version = null
    this._authorityName = isUndefined(e.authorityName) ? null : e.authorityName
    this._authorityCode = isUndefined(e.authorityCode) ? null : e.authorityCode
    this._heightReference = isUndefined(e.heightReference)
      ? null
      : e.heightReference
    this._geodeticDatum = e.geodeticDatum
    this._hashValue = 0
  }
  get geodeticDatum() {
    return this._geodeticDatum
  }
  get heightReference() {
    return this._heightReference
  }
  set heightReference(e) {
    this._heightReference = e
  }
  get prefix() {
    return this._prefix
  }
  set prefix(e) {
    this._prefix = e
    this.updateIdentifier()
  }
  get authorityName() {
    return this._authorityName
  }
  set authorityName(e) {
    this._authorityName = e
    this.updateIdentifier()
  }
  get version() {
    return this._version
  }
  set version(e) {
    this._version = e
    this.updateIdentifier()
  }
  get authorityCode() {
    return this._authorityCode
  }
  set authorityCode(e) {
    this._authorityCode = e
    this.updateIdentifier()
  }
  updateIdentifier() {
    this.setIdentifier(
      this.prefix,
      this.authorityName,
      this.authorityCode,
      this.version
    )
  }
  hashCode(e) {
    if (0 === this._hashValue) {
      const e = new Hash()
      e.appendString(this.identifier)
      e.appendString(this.name)
      e.appendString(this.version ?? '')
      e.appendString(this._authorityName ?? '')
      e.appendString(this._authorityCode ?? '')
      e.appendString(this._prefix ?? '')
      this._geodeticDatum.hashCode(e)
      if (this.heightReference) e.appendUInt32(this.heightReference.identifier)
      this._hashValue = e.getHashCode()
    }
    e.appendUInt32(this._hashValue)
  }
}
