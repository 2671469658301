import { Filled } from './util/Filled.js'
import { Length } from './util/Length.js'
import { Stroked } from './util/Stroked.js'
import { Pattern } from '../Pattern.js'
import { PatternType } from './util/PatternType.js'
const DEFAULT_FIXED_LENGTH = 10
const DEFAULT_RELATIVE_LENGTH = 1
export class WavePattern extends Pattern {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    super(PatternType.WAVE)
    const e =
      'number' !== typeof t.length
        ? t.relative
          ? DEFAULT_RELATIVE_LENGTH
          : DEFAULT_FIXED_LENGTH
        : t.length
    const i = t.relative ? 0 : e
    const n = t.relative ? e : 0
    const s = t.line || {}
    const l = t.fill || {}
    this._length = new Length({ fixedLength: i, relativeLength: n })
    this._filled = new Filled(l)
    this._stroked = new Stroked(s)
    this._amplitude = t.amplitude || 10
    this._startAngle = t.startAngle || 0
    this._angle = t.angle || 360
    this.canBend = true
  }
  get amplitude() {
    return this._amplitude
  }
  get startAngle() {
    return this._startAngle
  }
  get angle() {
    return this._angle
  }
  getMinHeight() {
    return -this.getMaxHeight()
  }
  getMaxHeight() {
    return this._amplitude + this.lineWidth / 2
  }
  get worldSizeContext() {
    return this._length.worldSizeContext
  }
  set worldSizeContext(t) {
    this._length.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._length.getUomSizeToPixels(t)
  }
  getWidth(t) {
    return this._length.getWidth(t)
  }
  isRelativeLength() {
    return this._length.isRelativeLength()
  }
  get fixedLength() {
    return this._length.fixedLength
  }
  set fixedLength(t) {
    this._length.fixedLength = t
  }
  get relativeLength() {
    return this._length.relativeLength
  }
  set relativeLength(t) {
    this._length.relativeLength = t
  }
  strokePath(t) {
    this._stroked.strokePath(t)
  }
  get lineColor() {
    return this._stroked.lineColor
  }
  set lineColor(t) {
    this._stroked.lineColor = t
  }
  get lineWidth() {
    return this._stroked.lineWidth
  }
  set lineWidth(t) {
    this._stroked.lineWidth = t
  }
  get dashed() {
    return this._stroked.dashed
  }
  set dashed(t) {
    this._stroked.dashed = t
  }
  fillPath(t) {
    this._filled.fillPath(t)
  }
  get fillColor() {
    return this._filled.fillColor
  }
  set fillColor(t) {
    this._filled.fillColor = t
  }
  paintPartial(t, e, i, n) {
    const s = i - e.distanceFromStart()
    const l = Math.min(s, e.distanceToNextVertex(), n[1])
    t.save()
    t.restore()
    const r = e.x()
    const h = e.y()
    const o = e.angleToNext()
    const a = this.getMaxHeight() + (this.lineWidth || 0)
    t.save()
    t.translate(r, h)
    t.rotate(o)
    t.beginPath()
    t.rect(0, 2 * a, l, 4 * -a)
    t.clip()
    this.paint(t, e.totalLength(), o)
    t.restore()
    e.advanceDistance(l)
  }
  paint(t, e, i, n) {
    t.scale(1, -1)
    const s = 1.5707963267948966 * 4
    const l = this.getWidth(e)
    const r = ((l / s) * 360) / this._angle
    const h = this._amplitude
    const o = 0.5122866232565925 * r
    const a = 0.5122866232565925 * h
    const d = 1.0023136857678987 * r
    const g = h
    const _ = 1.5707963267948966 * r
    const p = h
    t.save()
    t.beginPath()
    t.rect(
      0,
      -(this._amplitude + this.lineWidth),
      l,
      2 * (this._amplitude + this.lineWidth)
    )
    t.clip()
    const u = undefined
    const c =
      l *
      ((this._startAngle >= 0 ? this._startAngle : this._startAngle + 360) /
        this._angle)
    t.translate(-c, 0)
    t.beginPath()
    t.moveTo(0, 0)
    for (let e = 0; e < 2; e++) {
      t.bezierCurveTo(o, a, d, g, _, p)
      t.bezierCurveTo(2 * _ - d, g, 2 * _ - o, a, 2 * _, 0)
      t.bezierCurveTo(2 * _ + o, -a, 2 * _ + d, -g, 3 * _, -p)
      t.bezierCurveTo(4 * _ - d, -g, 4 * _ - o, -a, 4 * _, 0)
      t.translate(4 * _, 0)
    }
    this.strokePath(t)
    this.fillPath(t)
    t.restore()
    t.scale(1, -1)
  }
  appendHash(t) {
    this._length.appendHash(t)
    this._stroked.appendHash(t)
    this._filled.appendHash(t)
    t.appendDouble(this._startAngle)
    t.appendDouble(this._angle)
    t.appendDouble(this._amplitude)
    t.appendBoolean(this.isRelativeLength())
  }
}
