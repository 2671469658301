import { isDefined, isNumber } from '../../util/Lang.js'
export class BloomStyleImpl {
  constructor(t) {
    this.setToLiteral(t)
  }
  setToLiteral(t) {
    if (!isDefined(t)) return
    this.intensity = t.intensity ?? 1
  }
  get intensity() {
    return this._intensity
  }
  set intensity(t) {
    if (isNumber(t, false) && t >= 0) this._intensity = t
    else throw new Error(`Invalid intensity: ${t}`)
  }
}
export function equalBloomStyle(t, i) {
  if (isDefined(t) && isDefined(i)) return t.intensity === i.intensity
  return t === i
}
export const DEFAULT_BLOOM = { intensity: -1 }
