import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { buildCache } from '../util/Cacher.js'
import { CircleByCenterPoint } from './CircleByCenterPoint.js'
import { XYZBounds } from './XYZBounds.js'
import * as XYCircleUtil from './XYCircleUtil.js'
import { isNumber } from '../util/Lang.js'
export class XYZCircleByCenterPoint extends CircleByCenterPoint {
  constructor(e, r, t) {
    super()
    this._reference = this.validateReference(e)
    this._compareReferenceStrict(
      r.reference,
      'XYZCircleByCenterPoint::the center point reference must be equal to the circle reference'
    )
    this._center = r.copy()
    if (!isNumber(t))
      throw new ProgrammingError(
        'XYZCircleByCenterPoint::cannot create a circle without a radius'
      )
    this._radius = t
    this._cache = buildCache()
  }
  get isGeodetic() {
    return false
  }
  get bounds() {
    return this._cache.memoize('cached-bounding-box', () => {
      const e = new XYZBounds(this.reference, [0, 0, 0, 0, 0, 0])
      XYCircleUtil.getBounds(this, e)
      return e
    })
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  get focusPoint() {
    return XYCircleUtil.getFocusPoint(this)
  }
  get center() {
    return this._center
  }
  get radius() {
    return this._radius
  }
  set radius(e) {
    if (e >= 0) {
      this._radius = e
      this._cache.invalidate()
    } else
      throw new ProgrammingError(
        `Radius should be positive number, but was: ${e}`
      )
  }
  copy() {
    return new XYZCircleByCenterPoint(this.reference, this.center, this.radius)
  }
  contains2DCoordinates(e, r) {
    return (
      this.bounds.contains2DCoordinates(e, r) &&
      XYCircleUtil.contains2D(this.center, this.radius, e, r)
    )
  }
  contains3DCoordinates(e, r, t) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape XYZCircleByCenterPoint'
    )
  }
}
