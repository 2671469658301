import { FeatureModel } from '../../model/feature/FeatureModel.js'
import { isKMLFeature } from '../../model/kml/KMLFeature.js'
import { KMLPlacemarkFeature } from '../../model/kml/KMLPlacemarkFeature.js'
import { ShapeType } from '../../shape/ShapeType.js'
import { imageColorMultiply } from '../../util/ImageUtil.js'
import { KMLAltitudeMode } from '../../util/kml/KMLAltitudeMode.js'
import { KMLColorMode } from '../../util/kml/KMLColor.js'
import { resolveHref } from '../../util/kml/KMLInternalUtil.js'
import { KML_GROUND_OVERLAY } from '../../util/kml/KMLTypes.js'
import { KMLUnits } from '../../util/kml/KMLXYVector.js'
import { isString } from '../../util/Lang.js'
import { URL } from '../../util/URL.js'
import { BasicFeaturePainter } from '../feature/BasicFeaturePainter.js'
import { FeaturePainter } from '../feature/FeaturePainter.js'
import { PointLabelPosition } from '../style/PointLabelPosition.js'
import { KMLColorStyle } from './KMLStyle.js'
import { isShapeList } from '../../shape/ShapeList.js'
import { isExtrudedShape } from '../../shape/ExtrudedShape.js'
import { isPoint } from '../../shape/Point.js'
import { isPolyline } from '../../shape/Polyline.js'
import { DrapeTarget } from '../style/DrapeTarget.js'
const POLYGON_TYPES = ShapeType.COMPLEX_POLYGON | ShapeType.POLYGON
export class KMLPainter extends FeaturePainter {
  constructor() {
    super()
    this._fallbackPainter = null
    this._recoloredIconCache = new Map()
  }
  get fallbackPainter() {
    return (
      this._fallbackPainter ||
      (this._fallbackPainter = new BasicFeaturePainter())
    )
  }
  paintBody(e, t, r, o, i, n) {
    if (!isKMLFeature(t))
      return this.fallbackPainter.paintBody(e, t, r, o, i, n)
    if (!t.properties?.visibility) return
    if (t.type === KML_GROUND_OVERLAY) return
    const l = t.getStyle(n.selected || n.hovered)
    const s = createIconStyle(t, l, getBaseUri(o), this._recoloredIconCache)
    const a =
      l.polyStyle.outline && l.lineStyle.width > 0
        ? { width: l.lineStyle.width, color: getLineColor(t, l) }
        : void 0
    const u = l.polyStyle.fill ? { color: getPolyColor(t, l) } : void 0
    const c = {
      stroke: a,
      fill: u,
      zOrder: t.zOrder,
      drapeTarget: DrapeTarget.NOT_DRAPED,
    }
    const p = (i) => {
      if (isShapeList(i)) {
        for (let e = 0, t = i.shapeCount; e < t; ++e) p(i.getShape(e))
        return
      }
      if (isPoint(i) && s) {
        if (s instanceof Promise)
          s.then(() => {
            this.invalidate(t)
          })
        else e.drawIcon(i, s)
        return
      }
      if (isExtrudedShape(i)) {
        c.drapeTarget = DrapeTarget.NOT_DRAPED
        e.drawShape(i, c)
        return
      }
      if (0 !== (i.type & POLYGON_TYPES) && (u || a)) {
        c.drapeTarget = getDrapeTarget(r, i, o)
        e.drawShape(i, c)
        return
      }
      if (isPolyline(i) && a) {
        c.drapeTarget = getDrapeTarget(r, i, o)
        e.drawShape(i, c)
      }
    }
    p(r)
    drawMeshes(e, t).forEach(p)
  }
  paintLabel(e, t, r, o, i, n) {
    if (!isKMLFeature(t))
      return this.fallbackPainter.paintLabel?.call(
        this.fallbackPainter,
        e,
        t,
        r,
        o,
        i,
        n
      )
    if (!t.properties) return
    const { name: l, visibility: s } = t.properties
    if (!l || !s) return
    const a = findShape(r, ShapeType.POINT)
    if (0 === a.length) return
    const u = t.getStyle(n.selected)
    const c = KMLColorStyle.getRawColor(u.labelStyle).opacity
    const p = getLabelColor(t, u)
    const f = u.labelStyle.scale
    if (0 === f || 0 === c) return
    const A = `<div style="opacity:${c};color:${p};font-size:${f}${'em'};text-shadow: 0 0 5px rgb(0, 0, 0)">${l}</div>`
    for (const t of a) e.drawLabel(A, t, { positions: PointLabelPosition.ANY })
  }
}
export function drawMeshes(e, t) {
  if (!(t instanceof KMLPlacemarkFeature)) return t.shape ? [t.shape] : []
  return t.meshes.reduce((t, r) => {
    if (!r.mesh) {
      t.push(r.location)
      return t
    }
    const { location: o, mesh: i, orientation: n, scale: l } = r
    e.drawIcon3D(o, { mesh: i, orientation: n, scale: l })
    return t
  }, [])
}
const getPolyColor = getComputedColor.bind(null, 'polyStyle', false)
const getLabelColor = getComputedColor.bind(null, 'labelStyle', true)
const getLineColor = getComputedColor.bind(null, 'lineStyle', false)
function getComputedColor(e, t, r, o) {
  let { randomColors: i } = r
  const { color: n, colorMode: l } = o[e]
  if (l !== KMLColorMode.RANDOM) return n
  const s = i[e]
  if (n === s?.base) return s.override
  const a = KMLColorStyle.getRawColor(o[e]).random()[t ? 'rgb' : 'rgba']
  i[e] = { base: n, override: a }
  return a
}
function getBaseUri(e) {
  const t = e.model
  if (!(t instanceof FeatureModel)) return null
  const r = t.store.target
  if (isString(r)) return r
  return null
}
function findShape(e, t) {
  if (e.type === t) return [e]
  if (isShapeList(e)) {
    const r = []
    for (let o = 0, i = e.shapeCount; o < i; ++o) {
      const i = e.getShape(o)
      if (i.type === t) r.push(i)
      else if (i.type === ShapeType.SHAPE_LIST) r.push(...findShape(i, t))
    }
    return r
  }
  return []
}
function createIconStyle(e, t, r, o) {
  const i = 32
  const { heading: n, hotSpot: l, icon: s, scale: a } = t.iconStyle
  const { isTransparent: u } = KMLColorStyle.getRawColor(t.iconStyle)
  if (u || 0 === a) {
    const { labelStyle: r } = t
    const o = undefined
    return e.properties.name &&
      r.scale > 0 &&
      !KMLColorStyle.getRawColor(r).isTransparent
      ? { url: DUMMY_ICON }
      : null
  }
  const c = s ? s.href : null
  if (s && null === c) return null
  const { anchor: p, offset: f } = new AxisPosition(l.x, l.xUnits)
  const { anchor: A, offset: d } = new AxisPosition(l.y, l.yUnits).invert()
  const h = c ? resolveHref(r || '', c, false) : DEFAULT_ICON
  const m = KMLColorStyle.getRawColor(t.iconStyle)
  const g = h + m.rgba
  const L = {
    url: h,
    zOrder: 20,
    width: `${a * i}px`,
    height: `${a * i}px`,
    rotation: -n,
    anchorX: p,
    offsetX: f,
    anchorY: A,
    offsetY: d,
  }
  let S
  if ((1 == m.r && 1 == m.g && 1 == m.b && 1 == m.a) || 0 == m.a) S = h
  else S = o.get(g)
  if (void 0 !== S) {
    L.url = S
    return L
  }
  return imageColorMultiply(h, m).then((e) => {
    L.url = null == e ? DEFAULT_ICON : e
    o.set(g, L.url)
    return L
  })
}
function getAxisPositionProperties(e, t) {
  if (t === KMLUnits.FRACTION) return { fraction: e, pixels: 0 }
  if (t === KMLUnits.PIXELS) return { fraction: 0, pixels: e }
  if (t === KMLUnits.INSET_PIXELS) return { fraction: 1, pixels: -e }
  return { fraction: 0.5, pixels: 0 }
}
class AxisPosition {
  constructor(e, t) {
    const { fraction: r, pixels: o } = getAxisPositionProperties(e, t)
    this._fraction = r
    this._pixels = o
  }
  get anchor() {
    return 0 === this._fraction
      ? `${this._pixels}px`
      : `${100 * this._fraction}%`
  }
  get offset() {
    return 0 === this._fraction ? 0 : this._pixels
  }
  invert() {
    this._fraction = 1 - this._fraction
    this._pixels *= -1
    return this
  }
}
function getDrapeTarget(e, t, r) {
  const o = r.drapeTarget ?? DrapeTarget.TERRAIN
  const i = e.altitudeMode || t.altitudeMode
  if (!i) return DrapeTarget.NOT_DRAPED
  if (
    i & KMLAltitudeMode.RELATIVE_TO_GROUND &&
    !!t.bounds &&
    0 === t.bounds.z &&
    0 === t.bounds.depth
  )
    return o
  return !!(i & KMLAltitudeMode.CLAMP_TO_GROUND) ? o : DrapeTarget.NOT_DRAPED
}
const DUMMY_ICON = URL.fromData(
  'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
)
const DEFAULT_ICON = URL.fromData(
  'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAAK/INwWK6QAAABh0RVh0U29mdHd' +
    'hcmUAUGFpbnQuTkVUIHYzLjM2qefiJQAABBxJREFUWEfFVm1IVFkYfu85517TZm2V6kcfoCuEBf2SoCKUSleiqSF2C4L+tFBE9GsZKAj2' +
    'z247UBZG9kPaMI3YPuiDivJH4ro1lGuY1Ew1o02OtE421do0as44vnvee72rpNNcGXUHXu69c9/7vM953nOecxSY3C9z7lxxavVqZVUig' +
    'UPBIOvx+dATjyf+ABi+J6G6Jwc36Wxlk8ulIiKMhILhMMdbt1Tcu1cbXLpUvQvA90jY3ElDW/tA+fb0aY43bgD29JgkRq+JBMOmJoE7dm' +
    'gRm42flJjfWMO1nvV1SYn6T2+vopO4ehWws3M8EVKos5Phvn1qP2P8ZwmvWS+RMpNt3L1bG+jqAnzxAvD6dcCqKsAHDwAjkfFk7t8XuGi' +
    'RaJSwGSmhLSZk79rFI8+fAzY0AD57ZrSjuRnw4kXAM2cA6+sBPR7AYBCQiB45koESe4NF/FRpypYrV7he9OlTwDt3AG/fBmxpASRS4TCg' +
    'z2coQtHczHD5cvG3RJ2TCtnS+6wsURuNMmxvNwr6/UYrWlsNRWhuNDUB1tUJdDpVzM8Xf0ngQkvgFpKEw8FDfX2G9ERgbNDIAwHAUEjBB' +
    'QuYe6Qws4BrOWXV8eNCl3kiAiaZS5eY7Dn7wTKq9UT+S1cX0yX/fPTm8+vXgHa76Juyno8lt2IFbx0c/PLo/X6GmZn8gvVBpc6UbiZ+Xb' +
    'ZM1FdVqYn375MT6OgArKjgUn6lPDWshQw5EpfTqX16+VJgPM7w40fD+ZL1/80bwJUrxSsJLSzAfzmFc1bR0MD/23iiUUDqL830ZP1vaaH' +
    'Jxw+lXVxKWHr48Nhdz7BYcjYyHFr7n5Og5XfsmEry70+bQHb2rN9jMTFm2x31eJoDZLNPngB6vYYiROjhQ8N2XS5BJNKz3cJCrRFRmZCA' +
    'eRbo7zdsl1Sh0b99O3pGWLeOB9OcB8LpdquYSAAOD0+83Y4eSsa/r63VV0JpOq2YvWQJf0ymQyOj6O0F/PABkGx4YACQ/CAeBxwaMq6xG' +
    'CCp8u4dYFubQk74UzoE6NvFeXnC19jIdZnNSWcuQbP3tCmZK4PeUbjduhX/mC4B+j47I4PXHTyoShJMn2TJlqD5P5E5d44IgH0qCIxgKG' +
    'sWLlT/PHBgduzRo3lytzM8gZyPClKQEt3dNCE5ysNpu/zQNoUEdChRWmoPtbUFsKhoTnjrVq3/6FENz57leO0awxMnBO7cqQ3Nn6/elLn' +
    '5U12c8NZXVlZiTU0NybtNRo6MEtnrzTK2y/syGXnTUVjHtNlsv3m9XnQ4HFH5+NW0FUoCrJaVlYX8fj/m5uZenuniVG8tyV9dXU3yfzfj' +
    'BKT8Jz0ejzzp2COyeNaMEyguLu4IBAKYk5NzfsaLU8GCggJPeXn5gLwt+l8IyKKVMr6fjuL/AgrpMNw8qqUJAAAAAElFTkSuQmCC'
)
