import { createPoint } from '../shape/ShapeFactory.js'
import { XYZBounds } from '../shape/XYZBounds.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { Axis } from '../reference/Axis.js'
import { Transformation } from './Transformation.js'
import { OrthographicCamera } from '../view/camera/OrthographicCamera.js'
import { NotImplementedError } from '../error/NotImplementedError.js'
import { SimpleXYZPoint } from '../shape/SimpleXYZPoint.js'
export class WorldViewTransformation2D extends Transformation {
  constructor(t, e) {
    super(t, null)
    this._tmpWorldPt = new XYZPoint()
    this._tmpWorldPt2 = new XYZPoint()
    this._tmpViewPt = new XYZPoint()
    this._tmpViewPt2 = new XYZPoint()
    this._inverseTransformation = null
    if (e) this._orthoCamera = e.copy()
    else
      this._orthoCamera = OrthographicCamera.createDefault2DCamera(t, 800, 600)
    this._look2D = this._orthoCamera.asLook2D()
    this._viewCenter = createPoint(null, [
      this._orthoCamera.width / 2,
      this._orthoCamera.height / 2,
    ])
  }
  get inverseTransformation() {
    let t = this._inverseTransformation
    if (!t) {
      t = this.copy()
      t._inverseTransformation = this
      t._forward = this._inverse
      t._inverse = this._forward
      t._outputReference = this._inputReference
      t._inputReference = this._outputReference
      this._inverseTransformation = t
    }
    return t
  }
  get camera() {
    return this._orthoCamera
  }
  updateCamera(t) {
    this._orthoCamera = t
    this._look2D = this._orthoCamera.asLook2D()
    this._viewCenter = createPoint(null, [
      this._orthoCamera.width / 2,
      this._orthoCamera.height / 2,
    ])
    if (this._inverseTransformation) {
      this._inverseTransformation._orthoCamera = t
      this._inverseTransformation._look2D = this._look2D
      this._inverseTransformation._viewCenter = this._viewCenter.copy()
    }
  }
  toString() {
    return this._orthoCamera.toString()
  }
  getViewOrigin() {
    return createPoint(null, [
      this._look2D.viewOrigin.x,
      this._look2D.viewOrigin.y,
    ])
  }
  getViewCenter() {
    return this._viewCenter
  }
  getWorldOrigin() {
    return createPoint(this._inputReference, [
      this._look2D.worldOrigin.x,
      this._look2D.worldOrigin.y,
    ])
  }
  getScaleX() {
    return this._look2D.scaleX
  }
  getScaleY() {
    return this._look2D.scaleY
  }
  getScaleSFCT(t) {
    t[0] = this._look2D.scaleX
    t[1] = this._look2D.scaleY
  }
  setScale(t, e) {
    this.setTo(
      this._look2D.worldOrigin,
      this._look2D.viewOrigin,
      t,
      e,
      this._look2D.rotation
    )
  }
  getRotation() {
    return this._look2D.rotation
  }
  toWorld(t, e) {
    return this.inverseTransformation.transform(t, e)
  }
  toView(t, e) {
    return this.transform(t, e)
  }
  forwardX(t, e) {
    this._tmpWorldPt2.move2D(t, e)
    this._orthoCamera.toView(this._tmpWorldPt2, this._tmpViewPt2)
    return this._tmpViewPt2.x
  }
  forwardY(t, e) {
    this._tmpWorldPt2.move2D(t, e)
    this._orthoCamera.toView(this._tmpWorldPt2, this._tmpViewPt2)
    return this._tmpViewPt2.y
  }
  _forward(t, e) {
    e.x = this.forwardX(t.x, t.y)
    e.y = this.forwardY(t.x, t.y)
    return e
  }
  inverseX(t, e) {
    this._tmpViewPt2.move2D(t, e)
    this._orthoCamera.toWorld(this._tmpViewPt2, this._tmpWorldPt2)
    return this._tmpWorldPt2.x
  }
  inverseY(t, e) {
    this._tmpViewPt2.move2D(t, e)
    this._orthoCamera.toWorld(this._tmpViewPt2, this._tmpWorldPt2)
    return this._tmpWorldPt2.y
  }
  _inverse(t, e) {
    e.x = this.inverseX(t.x, t.y)
    e.y = this.inverseY(t.x, t.y)
    return e
  }
  _forwardBatch(t, e) {
    const r = t.length
    if (r < 3) return 0
    if (r > e.length) e.length = r
    let i
    let o
    e[0] = i = this.forwardX(t[0], t[1])
    e[1] = o = this.forwardY(t[0], t[1])
    let s = 3
    let n = 2
    while (s < r) {
      const r = this.forwardX(t[s], t[s + 1])
      const a = this.forwardY(t[s], t[s + 1])
      if (Math.abs(i - r) > 0.5 || Math.abs(o - a) > 0.5) {
        e[n] = i = r
        e[n + 1] = o = a
        n += 2
      }
      s += 3
    }
    return n
  }
  forwardDistance(t) {
    return [t[0] * this._look2D.scaleX, t[1] * -this._look2D.scaleY]
  }
  _inverseXDistance(t) {
    return t / this._look2D.scaleX
  }
  _inverseYDistance(t) {
    return t / -this._look2D.scaleY
  }
  _forwardXDistance(t) {
    return t * this._look2D.scaleX
  }
  _forwardYDistance(t) {
    return t * -this._look2D.scaleY
  }
  inverseDistance(t, e) {
    e[0] = t[0] / this._look2D.scaleX
    e[1] = t[1] / -this._look2D.scaleY
  }
  toWorldDistance(t, e) {
    this.inverseDistance(t, e)
  }
  _toWorldXDistance(t) {
    return this._inverseXDistance(t)
  }
  _toWorldYDistance(t) {
    return this._inverseYDistance(t)
  }
  _toViewXDistance(t) {
    return this._forwardXDistance(t)
  }
  _toViewYDistance(t) {
    return this._forwardYDistance(t)
  }
  toViewDistance(t) {
    return this.forwardDistance(t)
  }
  _forwardBoundsCoords(t, e) {
    transformBounds(
      t,
      e,
      this._forward.bind(this),
      this._tmpViewPt,
      this._tmpWorldPt
    )
  }
  _inverseBoundsCoords(t, e) {
    transformBounds(
      t,
      e,
      this._inverse.bind(this),
      this._tmpViewPt,
      this._tmpWorldPt
    )
  }
  getBoundsOnSurface(t) {
    const e = new XYZBounds(this._inputReference)
    this.inverseTransformation.transformBounds(t, e)
    return e
  }
  isWorldXYVisible(t, e) {
    const r = this.forwardX(t, e)
    const i = this.forwardY(t, e)
    return (
      r >= 0 && r <= this.getViewWidth() && i >= 0 && i <= this.getViewHeight()
    )
  }
  isInsideRectangleForward(t, e, r, i, o, s) {
    const n = new SimpleXYZPoint(this._inputReference, t, e, 0)
    const a = new SimpleXYZPoint(this._outputReference, 0, 0, 0)
    this._forward(n, a)
    const h = a.x
    const _ = a.y
    return h >= r && h <= o && _ >= i && _ <= s
  }
  copy(t) {
    if (!t)
      t = new WorldViewTransformation2D(this._inputReference, this._orthoCamera)
    t._inputReference = this._inputReference
    t._outputReference = this._outputReference
    t.setToWVT(this)
    t._orthoCamera = this._orthoCamera
    t._look2D = this._look2D
    t.setViewSize(this.getViewWidth(), this.getViewHeight())
    t._viewCenter.move2D(this._viewCenter.x, this._viewCenter.y)
    return t
  }
  setToWVT(t) {
    this.setTo(
      t._look2D.worldOrigin,
      t._look2D.viewOrigin,
      t._look2D.scaleX,
      t._look2D.scaleY,
      t._look2D.rotation
    )
  }
  setTo(t, e, r, i, o) {
    let s = o % 360
    if (s < 0) s += 360
    this._look2D.worldOrigin = t
    this._look2D.viewOrigin = e
    this._look2D.scaleX = r
    this._look2D.scaleY = i
    this._look2D.rotation = s
    this._orthoCamera = this._orthoCamera.look2D(this._look2D)
    this._look2D = this._orthoCamera.asLook2D()
    if (this._inverseTransformation) {
      this._inverseTransformation._orthoCamera = this._orthoCamera
      this._inverseTransformation._look2D = this._look2D
    }
  }
  setViewSize(t, e) {
    const r = this._orthoCamera.asLook2D()
    this._orthoCamera = this._orthoCamera
      .copyAndSet({ width: t, height: e })
      .look2D(r)
    this._look2D = this._orthoCamera.asLook2D()
    this._viewCenter.move2D(t / 2, e / 2)
    if (this._inverseTransformation) {
      this._inverseTransformation._orthoCamera = this._orthoCamera
      this._inverseTransformation._look2D = this._look2D
      this._inverseTransformation._viewCenter = this._viewCenter
    }
  }
  getViewWidth() {
    return this._orthoCamera.width
  }
  getViewHeight() {
    return this._orthoCamera.height
  }
  getVisibleSurfaceWorldViewTransformation() {
    return this
  }
  getOnTerrainViewWorldTransformation() {
    return this.inverseTransformation
  }
  getOnEllipsoidViewWorldTransformation() {
    return this.inverseTransformation
  }
  equals(t) {
    return (
      this._orthoCamera.equals(t._orthoCamera) &&
      (this._inputReference
        ? this._inputReference.equals(t._inputReference)
        : this._inputReference === t._inputReference) &&
      (this._outputReference
        ? this._outputReference.equals(t._outputReference)
        : this._outputReference === t._outputReference)
    )
  }
  isXFlipped() {
    return false
  }
  isYFlipped() {
    return (
      this._inputReference.getAxis(Axis.Name.Y).direction ===
      Axis.Direction.DISPLAY_DOWN
    )
  }
  _getType() {
    throw new NotImplementedError()
  }
}
function transformBounds(t, e, r, i, o) {
  const s = t.width
  const n = t.height
  e.width = 0
  e.height = 0
  e.depth = 0
  i.move3D(t.x, t.y, t.z)
  r(i, o)
  e.move3D(o)
  i.translate3D(s, 0, 0)
  r(i, o)
  e.setToIncludePoint3D(o)
  i.translate3D(0, n, 0)
  r(i, o)
  e.setToIncludePoint3D(o)
  i.translate3D(-s, 0, 0)
  r(i, o)
  e.setToIncludePoint3D(o)
}
