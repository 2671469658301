import { NotImplementedError } from '../error/NotImplementedError.js'
import { CircularArc } from './CircularArc.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
export class CircularArcByBulge extends CircularArc {
  get type() {
    return ShapeType.CIRCULAR_ARC + ShapeType.CIRCULAR_ARC_BY_BULGE
  }
  moveStartPoint2DToPoint(t) {
    this.moveStartPoint2DToCoordinates(t.x, t.y)
  }
  moveEndPoint2DToPoint(t) {
    this.moveEndPoint2DToCoordinates(t.x, t.y)
  }
  translate2D(t, e) {
    this.translateStartPoint2D(t, e)
    this.translateEndPoint2D(t, e)
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    this.startPoint.hashCode(t)
    this.endPoint.hashCode(t)
    t.appendDouble(this.bulge)
  }
  toString() {
    return `CircularArcByBulge[start${this.startPoint.toString(
      true
    )},end${this.endPoint.toString(true)},bulge[${this.bulge}]]`
  }
  equals(t) {
    if (this === t) return true
    if (!ShapeUtil.referencesEqual(this.reference, t.reference)) return false
    return (
      this.type === t.type &&
      this.bulge === t.bulge &&
      this.startPoint.equals(t.startPoint) &&
      this.endPoint.equals(t.endPoint)
    )
  }
  calculateBulge(t) {
    throw new NotImplementedError(
      'CircularArcByBulge implementations must provide function calculateBulge'
    )
  }
}
