import { closestPointOnGeodesic } from '../../../geodesy/EllipsoidUtil.js'
import { closestPointOnGeodesic as closestPointOnGeodesicSphere } from '../../../geodesy/SphereUtil.js'
import { LLHBounds } from '../../../shape/LLHBounds.js'
import { LLHCircleByCenterPoint } from '../../../shape/LLHCircleByCenterPoint.js'
import { LLHCircularArcByCenterPoint } from '../../../shape/LLHCircularArcByCenterPoint.js'
import { LLHPoint } from '../../../shape/LLHPoint.js'
import { LLHPolygon } from '../../../shape/LLHPolygon.js'
import { coordinate_fromSimplePoint } from '../../../shape/PointCoordinate.js'
import {
  simplePointCopy,
  simplePointMove2D,
} from '../../../shape/SimplePoint.js'
import { normalizeAngle } from '../../../util/Cartesian.js'
import { Constants } from '../../../util/Constants.js'
import { ConstructiveGeometry } from '../../ConstructiveGeometry.js'
import { TopologyUtilEllipsoidal } from '../../topologyutil/TopologyUtilEllipsoidal.js'
import { CompositeConstructiveGeometryOperator } from '../CompositeConstructiveGeometryOperator.js'
import { GeoBufferHelper } from './GeoBufferHelper.js'
const _MIN_TOLERANCE = 0.01
const TWO_PI = Constants.d360InRadians
const PI_OVER_TWO = Constants.d90InRadians
const SUBDIVISION_DEPTH = 4
const MAX_RELATIVE_SUBDIVISION_ERROR = 0.005
export class LLHGeoBufferHelper extends GeoBufferHelper {
  constructor(t) {
    let i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super(t)
    this.ELLIPSOID = t.geodeticDatum.ellipsoid
    this.TOLERANCE = Math.max(_MIN_TOLERANCE, i) || _MIN_TOLERANCE
    this.pointSFCT = this.createPoint({ x: 0, y: 0, z: 0 })
  }
  boundsTooLarge(t) {
    return t.width > 180
  }
  getConstructiveGeometry() {
    return new ConstructiveGeometry(
      new CompositeConstructiveGeometryOperator(
        new TopologyUtilEllipsoidal(
          this.TOLERANCE,
          this.REF.geodeticDatum.ellipsoid
        )
      )
    )
  }
  getTopologyUtil() {
    return new TopologyUtilEllipsoidal(this.TOLERANCE, this.ELLIPSOID)
  }
  createBounds(t) {
    return new LLHBounds(this.REF, [t.x, 0, t.y, 0])
  }
  createPoint(t) {
    return new LLHPoint(this.REF, [t.x, t.y, 0])
  }
  createPolygon(t) {
    const i = (t = t || []).map((t) => coordinate_fromSimplePoint(t))
    return new LLHPolygon(this.REF, i)
  }
  createCircle(t, i) {
    return new LLHCircleByCenterPoint(this.REF, this.createPoint(t), i)
  }
  createCircularArc(t, i, e, o) {
    return new LLHCircularArcByCenterPoint(
      this.REF,
      this.createPoint(t),
      i,
      e,
      o
    )
  }
  offsetPoints(t, i, e, o) {
    const s = this.azimuth(t, i),
      n = this.azimuth2angle(s) + o,
      r = this.azimuth(i, t) + Math.PI,
      h = this.azimuth2angle(r) + o,
      a = this.pointAtDistance(t, e, n),
      c = this.pointAtDistance(i, e, h)
    const l = []
    l[0] = a
    this._subdivide(t, i, a, c, e, o, l)
    l.push(c)
    return l
  }
  offsetPoints2(t, i, e, o, s, n) {
    const r = []
    r[0] = e
    this._subdivide(t, i, e, o, s, n, r)
    r.push(o)
    return r
  }
  _subdivide(t, i, e, o, s, n, r) {
    const h =
        this.ELLIPSOID.geodesicDistance(e, o) * MAX_RELATIVE_SUBDIVISION_ERROR,
      a = []
    const c = []
    let l
    let u, L, p, P, I, m
    c[0] = {
      startPoint: t,
      endPoint: i,
      startOffset: e,
      endOffset: o,
      counter: SUBDIVISION_DEPTH,
    }
    while (0 !== c.length) {
      l = c.pop()
      u = l.counter
      L = new LLHPoint(this.REF)
      p = new LLHPoint(this.REF)
      this.ELLIPSOID.geodesicPositionAtFractionSFCT(
        l.startPoint,
        l.endPoint,
        0.5,
        L
      )
      this.ELLIPSOID.geodesicPositionAtFractionSFCT(
        l.startOffset,
        l.endOffset,
        0.5,
        p
      )
      L.move2D(
        _correctLongitudeUsingRange(
          L.x,
          l.startPoint.x,
          l.endPoint.x,
          this.EPSILON
        ),
        L.y
      )
      p.move2D(
        _correctLongitudeUsingRange(
          p.x,
          l.startOffset.x,
          l.endOffset.x,
          this.EPSILON
        ),
        p.y
      )
      P = this.azimuth(L, i)
      I = this.pointAtDistance(L, s, this.azimuth2angle(P) + n)
      a.push(I)
      m = this.ELLIPSOID.geodesicDistance(L, p)
      if (Math.abs(s - m) > h && u > 0) {
        c.push({
          startPoint: t,
          endPoint: L,
          startOffset: e,
          endOffset: I,
          counter: u - 1,
        })
        c.push({
          startPoint: L,
          endPoint: i,
          startOffset: I,
          endOffset: o,
          counter: u - 1,
        })
      }
    }
    const f = e
    const d = this.ELLIPSOID
    a.sort((t, i) => {
      const e = d.geodesicDistance(f, t)
      const o = d.geodesicDistance(f, i)
      return e < o ? -1 : e == o ? 0 : 1
    })
    for (let t = 0; t < a.length; t++) r.push(a[t])
  }
  turn(t, i, e) {
    const o = this.azimuth(t, i),
      s = this.azimuth(t, e)
    let n = s + (TWO_PI - o)
    if (n > TWO_PI) n -= TWO_PI
    if (
      Math.abs(s - o) < this.EPSILON ||
      Math.abs(Math.abs(s - o) - Math.PI) < this.EPSILON
    ) {
      const o = undefined,
        s = undefined
      n = _correctLongitudeUsingRange(
        this.azimuth(i, t) - this.azimuth(i, e),
        -180,
        180,
        this.EPSILON
      )
      return Math.abs(n) < this.EPSILON ? 2 : 0
    }
    if (n < Math.PI) return this.RIGHT
    else return this.LEFT
  }
  angle(t, i, e) {
    const o = this.ELLIPSOID.forwardAzimuth2D(t, i),
      s = this.ELLIPSOID.forwardAzimuth2D(e, i) - Math.PI
    return normalizeAngle((180 * (s - o)) / Math.PI)
  }
  pointAtDistance(t, i, e) {
    const o = (PI_OVER_TWO - e) * Constants.RAD2DEG
    this.pointSFCT.move2DToCoordinates(t.x, t.y)
    this.ELLIPSOID.geodesicPositionSFCT(t, i, o, this.pointSFCT)
    simplePointMove2D(
      this.pointSFCT,
      _correctLongitudeUsingAzimuth(this.pointSFCT.x, t.x, o),
      this.pointSFCT.y
    )
    return simplePointCopy(this.pointSFCT)
  }
  azimuth(t, i) {
    return this.ELLIPSOID.forwardAzimuth2D(t, i)
  }
  distance(t, i, e) {
    if (e) return closestPointOnGeodesicSphere(t, i, e, true, this.pointSFCT)
    return this.ELLIPSOID.geodesicDistance(t, i)
  }
  intersection(t, i, e, o) {
    if (this.ELLIPSOID.intersects2DLS(t, i, e, o)) {
      this.pointSFCT.move2DToCoordinates(t.x, t.y)
      this.ELLIPSOID.intersection2DLSSFCT(t, i, e, o, this.pointSFCT)
      return simplePointCopy(this.pointSFCT)
    } else return null
  }
  discretizeCircularArc(t, i, e, o) {
    const s = (this.MAX_DISCRETIZATION_POINTS * Math.abs(o)) / (2 * Math.PI)
    let n = this.MIN_DISCRETIZATION_POINTS
    let r = o / n
    const h = this.MAX_RELATIVE_DISCRETIZATION_ERROR * i
    const a = this.createPoint(t)
    const c = []
    while (n < s) {
      const e = this.pointAtDistance(t, i, 0)
      const s = this.pointAtDistance(t, i, r)
      const c = this.pointAtDistance(t, i, r / 2)
      if (
        closestPointOnGeodesic(e, s, c, this.ELLIPSOID, 0, this.TOLERANCE, a) <=
        h
      )
        break
      n *= 2
      r = o / n
    }
    n++
    for (let o = 0; o < n; o++) {
      const s = e + o * r
      c[o] = this.pointAtDistance(t, i, s)
    }
    return c
  }
}
function _correctLongitudeUsingAzimuth(t, i, e) {
  let o = e
  if (o > 360) while (o > 360) o -= 360
  else if (o < 0) while (o < 0) o += 360
  let s = t
  if (o < 180)
    if (s < i) while (s < i) s += 360
    else while (Math.abs(i - s) > 360) s -= 360
  else if (s > i) while (s > i) s -= 360
  else while (Math.abs(i - s) > 360) s += 360
  return s
}
function _correctLongitudeUsingRange(t, i, e, o) {
  if (Math.abs(t - i) < o || Math.abs(t - e) < o) return t
  const s = Math.min(i, e)
  const n = Math.max(i, e)
  let r = t
  if (r < s) while (r < s) r += 360
  else if (r > n) while (r > n) r -= 360
  return r
}
