import { XYZBounds } from '../../shape/XYZBounds.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
import { AdvancedBinaryTopologyUtil } from './AdvancedBinaryTopologyUtil.js'
import { squaredDistance2D } from '../../util/Cartesian.js'
var dummyPoint = new XYZPoint()
function CartesianAdvancedBinaryTopologyUtil(o) {
  this._tolerance = o
  this._tolerance2 = o * o
}
CartesianAdvancedBinaryTopologyUtil.prototype = Object.create(
  AdvancedBinaryTopologyUtil.prototype
)
CartesianAdvancedBinaryTopologyUtil.prototype.constructor =
  CartesianAdvancedBinaryTopologyUtil
CartesianAdvancedBinaryTopologyUtil.prototype.setTolerance = function (o) {
  this._tolerance2 = o * o
}
CartesianAdvancedBinaryTopologyUtil.prototype.computeDistance = function (
  o,
  t
) {
  return distance2D(o, t)
}
CartesianAdvancedBinaryTopologyUtil.prototype.computePoint = function (
  o,
  t,
  n
) {
  var e = o.copy()
  e.move2DToCoordinates(o.x + n * (t.x - o.x), o.y + n * (t.y - o.y))
  return e
}
CartesianAdvancedBinaryTopologyUtil.prototype.insidePointPolygon = function (
  o,
  t
) {
  var n = t.pointCount,
    e = o.x,
    i = o.y,
    r = 0
  var a = t.getPoint(n - 1).x
  var y = t.getPoint(n - 1).y
  var c, d, l, s, p, u
  for (l = 0; l < n; a = c, y = d, l++) {
    c = t.getPoint(l).x
    if ((d = t.getPoint(l).y) == y) continue
    if (c < a) {
      if (e >= a) continue
      s = c
    } else {
      if (e >= c) continue
      s = a
    }
    if (d < y) {
      if (i < d || i >= y) continue
      if (e < s) {
        r++
        continue
      }
      p = e - c
      u = i - d
    } else {
      if (i < y || i >= d) continue
      if (e < s) {
        r++
        continue
      }
      p = e - a
      u = i - y
    }
    if (p <= (u / (y - d)) * (a - c)) r++
  }
  return 0 != (1 & r)
}
CartesianAdvancedBinaryTopologyUtil.prototype.createBoundsTolerance = function (
  o,
  t
) {
  var n = t || this._tolerance
  return new XYZBounds(o.reference, [o.x - n, 2 * n, o.y - n, 2 * n])
}
CartesianAdvancedBinaryTopologyUtil.prototype.intersectsTolerance = function (
  o,
  t
) {
  var n = o.x
  var e = o.y
  var i = t.x
  var r = t.y
  return !(
    n + o.width < i - this._tolerance ||
    n > i + t.width + this._tolerance ||
    e + o.height < r - this._tolerance ||
    e > r + t.height + this._tolerance
  )
}
CartesianAdvancedBinaryTopologyUtil.prototype.extendBounds = function (o, t) {
  dummyPoint.move2DToCoordinates(o.x, o.y)
  dummyPoint.translate2D(-t, -t)
  return new XYZBounds(o.reference, [
    dummyPoint.x,
    o.width + 2 * t,
    dummyPoint.y,
    o.height + 2 * t,
  ])
}
CartesianAdvancedBinaryTopologyUtil.prototype.equal2DTolerance = function (
  o,
  t
) {
  return squaredDistance2D(o.x, o.y, t.x, t.y) <= this._tolerance2
}
export { CartesianAdvancedBinaryTopologyUtil }
