import { ShapeType } from '../shape/ShapeType.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { Affine2D } from '../transformation/Affine2D.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { linear } from '../util/Easing.js'
import { Log } from '../util/Log.js'
import { ObjectReleaseTracker } from '../util/ObjectReleaseTracker.js'
import { Lang } from '../util/Lang.js'
import { Photon } from '../gen/photon/photon_painter.js'
import { Animation } from './animation/Animation.js'
import { AnimationManager } from './animation/AnimationManager.js'
import {
  FIT_MARGIN_TYPE,
  LEGACY_FIT_DURATION,
  LEGACY_FIT_MARGIN,
  MapNavigatorBase,
} from './MapNavigatorBase.js'
import { CameraPosition2D } from './CameraPosition2D.js'
import { MapNavigatorConstraints2D } from './MapNavigatorConstraints2D.js'
import { MapNavigatorConstraintsImpl } from './MapNavigatorConstraints.js'
import { distance3D } from '../util/Cartesian.js'
const LEGACY_DEFAULT_DURATION = 340
const DEFAULT_EASING = linear
function noop() {}
function normalizeScale(t, a) {
  let n
  if (Array.isArray(t)) n = t[0]
  else if ('number' === typeof t) n = t
  else
    throw new ProgrammingError(
      'MapNavigator:' +
        a +
        ' - invalid scale argument, must be number or array of numbers'
    )
  return n
}
class PhotonMapNavigator extends MapNavigatorBase {
  constructor(t) {
    super(t)
    this._objectReleaseTracker = new ObjectReleaseTracker()
    this._animation = null
    this._mapNavigatorConstraints = MapNavigatorConstraints2D.create(this._map)
    this.__setupConstraints(t)
    t.onReady(() => {
      this.postReboot(t)
    })
  }
  destroy() {
    this._objectReleaseTracker.release()
  }
  preReboot(t) {
    this._killCurrentAnimation()
    this._objectReleaseTracker.release()
    this._photonView = null
    this._photonMapNavigator = null
  }
  postReboot(t) {
    const a = t.viewPaintingStrategy.techContext
    this._photonView = a.photonView
    this._photonMapNavigator = this._objectReleaseTracker.track(
      this._photonView.mapNavigator
    )
    this._photonReferenceProvider = a.referenceProvider
  }
  updateCamera(t, a) {
    if (a && this._photonMapNavigator) {
      this._killCurrentAnimation()
      this._photonMapNavigator = this._objectReleaseTracker.retrack(
        this._photonMapNavigator,
        this._photonView.mapNavigator
      )
    }
  }
  __setupConstraints(t) {
    const a = Photon.whenReady(() => {
      let a = null
      if (
        t.viewPaintingStrategy &&
        t.viewPaintingStrategy.techContext &&
        t.viewPaintingStrategy.techContext.photonView
      )
        a = t.viewPaintingStrategy.techContext.photonView
      return a
    })
    this._constraint = new MapNavigatorConstraintsImpl(
      t.dotsPerCentimeter,
      t.reference,
      this.triggerRefresh.bind(this),
      a,
      true
    )
  }
  _toView(t) {
    if (null === t.reference) return t
    else if (t.reference === this._map.reference)
      return this._map.mapToViewTransformationInternal.transform(t)
    const a = undefined
    const n = createTransformation(t.reference, this._map.reference).transform(
      t
    )
    return this._map.mapToViewTransformationInternal.transform(n)
  }
  _toMap(t) {
    if (t.reference === this._map.reference) return t
    else if (null === t.reference) {
      const a = undefined
      return this._map.mapToViewTransformationInternal
        .getOnTerrainViewWorldTransformation()
        .transform(t)
    }
    const a = undefined
    return createTransformation(t.reference, this._map.reference).transform(t)
  }
  _pixelToNDC(t) {
    return createPoint(null, [this.normalizeX(t.x), this.normalizeY(t.y)])
  }
  _calculateReferencePoint(t, a) {
    let n = this._map._worldViewTransformation.intersectTerrain(t, a)
    if (isNaN(n.x) || isNaN(n.y) || isNaN(n.z))
      n = this._map._worldViewTransformation.projectPointOnTerrain(t)
    return n
  }
  normalizeX(t) {
    return this._map.mapToViewTransformationInternal.normalizeX(t)
  }
  normalizeY(t) {
    return this._map.mapToViewTransformationInternal.normalizeY(t)
  }
  denormalizeX(t) {
    return this._map.mapToViewTransformationInternal.denormalizeX(t)
  }
  denormalizeY(t) {
    return this._map.mapToViewTransformationInternal.denormalizeY(t)
  }
  _animatedZoomToLocation(t, a, n) {
    let o = 1.75
    let i = AnimationManager.getAnimation(this._map.cameraAnimationKey)
    if (i && i.factor) {
      const t = 1 - i.fraction
      let a = i.factor
      if (n > 0) a = 1 / a
      a *= t
      o += a
    }
    if (n > 0) o = 1 / o
    const e = this._animatedZoomTo(t, a, o, n >= 0 ? Math.ceil : Math.floor)
    i = AnimationManager.getAnimation(this._map.cameraAnimationKey)
    if (i) i.factor = o
    return e
  }
  _correctZoomFactorForSnap(t, a) {
    const n = this._map.getScaleX() / t
    this._mapNavigatorConstraints.getSnappedScale(
      n,
      n,
      a ? a : this._snapDirection(n),
      false,
      tmpOut
    )
    return this._map.getScaleX() / tmpOut.scalex
  }
  incrementalPan(t, a, n, o) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    if (this._isPanning) {
      this._photonMapNavigator.incrementalPan(
        this.normalizeX(n),
        this.normalizeY(o)
      )
      this._constrain()
      this._map.transformationChanged()
    }
  }
  beginPan(t, a, n) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    this._killCurrentAnimation()
    if (!this._isPanning) {
      this._isPanning = true
      this._photonMapNavigator.setPanConstantYaw(n)
      this._photonMapNavigator.beginPan(this.normalizeX(t), this.normalizeY(a))
    }
  }
  endPan() {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    this._isPanning = false
    this._photonMapNavigator.endPan()
  }
  beginRotate(t, a) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    if (this._mapNavigatorConstraints.hasBoundsConstraint()) return
    this._killCurrentAnimation()
    const n = this.normalizeX(t)
    const o = this.normalizeY(a)
    if (!this._isRotating) {
      this._isRotating = true
      this._photonMapNavigator.beginRotate(n, o)
    }
  }
  beginRotateWorld(t) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    if (this._mapNavigatorConstraints.hasBoundsConstraint()) return
    this._killCurrentAnimation()
    if (!this._isRotating) {
      this._isRotating = true
      this._photonMapNavigator.beginRotateWorld(t)
    }
  }
  incrementalRotateAngles(t, a) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    if (this._isRotating) {
      this._photonMapNavigator.incrementalRotateAngles(t, a)
      this._map.transformationChanged()
    }
  }
  endRotate() {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    if (this._isRotating) this._photonMapNavigator.endRotate()
    this._isRotating = false
  }
  _createPhotonAnimation(t, a, n, o, i) {
    const e = new Animation(o, i)
    e.onStart = t
    const r = this
    e.update = function (t) {
      t = this.ease ? this.ease(t) : t
      a(t)
      r._map.transformationChanged()
    }
    e.onStop = n
    return e
  }
  _convertToScaleNearCamera(t) {
    return t / (this._map.getViewWidth() / 2)
  }
  fit(t) {
    let a = t
    if (t && t.type && ShapeType.contains(t.type, ShapeType.BOUNDS)) {
      if (this._enabledOperations === MapNavigatorBase.NONE) return
      const n = undefined
      a = {
        bounds: t,
        allowWarpXYAxis: arguments[1],
        fitMargin: LEGACY_FIT_MARGIN,
        animate: { duration: LEGACY_FIT_DURATION },
      }
    }
    const n = this.parseFitOptions(a, this._map)
    if (n.allowWarpXYAxis)
      Log.warn(
        'Cannot set allowWarpXYAxis to true if the map shows geographic data'
      )
    const o = Photon.whenReady(() => {
      this._killCurrentAnimation()
      let t = n.bounds.copy()
      let a = null
      if (null === t.reference) {
        t = this._map.viewToMapTransformation.transformBounds(t)
        a = this._photonReferenceProvider.getReference(this._map.reference)
      } else a = this._photonReferenceProvider.getReference(t.reference)
      const o = n.fitMargin
      const i = toPhotonFitMarginType(o.type)
      if (n.animate) {
        const o = this
        const e = function () {
          o._photonMapNavigator.beginFit(t, a, n.fitMargin.value, i)
          a.release()
        }
        const r = this._photonMapNavigator.incrementalFit.bind(
          this._photonMapNavigator
        )
        const s = function () {
          o._photonMapNavigator.endFit()
        }
        const h = this._createPhotonAnimation(
          e,
          r,
          s,
          n.animate.duration,
          n.animate.ease
        )
        return AnimationManager.putAnimation(this._map.cameraAnimationKey, h)
      }
      this._photonMapNavigator.beginFit(t, a, o.value, i)
      a.release()
      this._photonMapNavigator.incrementalFit(1)
      this._photonMapNavigator.endFit()
      this._map.transformationChanged()
      return Promise.resolve()
    })
    o.then(noop, noop)
    return o
  }
  pan(t) {
    t = this.parsePanOptions(t, this._map)
    const a = Photon.whenReady(() => {
      if (!t.keepAnimationRunning) this._killCurrentAnimation()
      const a = t.targetLocation
      const n = t.toViewLocation
      const o = this._toMap(a)
      const i = this._pixelToNDC(n)
      this._photonMapNavigator.setPanConstantYaw(t.constantYaw)
      if (t.animate) {
        const a = this._photonMapNavigator.beginPanAnimation.bind(
          this._photonMapNavigator,
          o,
          i
        )
        const n = this._photonMapNavigator.incrementPanAnimation.bind(
          this._photonMapNavigator
        )
        const e = this._photonMapNavigator.endPanAnimation.bind(
          this._photonMapNavigator
        )
        const r = this._createPhotonAnimation(
          a,
          n,
          e,
          t.animate.duration,
          t.animate.ease || DEFAULT_EASING
        )
        return AnimationManager.putAnimation(this._map.cameraAnimationKey, r)
      }
      this._photonMapNavigator.panWorld(o, i)
      this._map.transformationChanged()
      return Promise.resolve()
    })
    a.then(noop, noop)
    return a
  }
  rotate(t) {
    t = this.parseRotateOptions(t, this._map)
    const a = Photon.whenReady(() => {
      this._killCurrentAnimation()
      const a = t.center
      const n = t.targetYaw
      const o = t.targetPitch
      const i = t.currentYaw
      const e = t.currentPitch
      const r = this
      function s() {
        if (null === a.reference) {
          const t = r._pixelToNDC(a)
          r._photonMapNavigator.beginRotate(t.x, t.y)
        } else {
          const t = r._toMap(a)
          r._photonMapNavigator.beginRotateWorld(t)
        }
      }
      const h = function (t) {
        const a = lerpAngle(i, n, t)
        const s = lerp(e, o, t)
        const h = a - i
        const m = s - e
        r._photonMapNavigator.incrementalRotateAngles(h, m)
      }
      if (t.animate) {
        const a = s
        const n = h
        const o = this._photonMapNavigator.endRotate.bind(
          this._photonMapNavigator
        )
        const i = this._createPhotonAnimation(
          a,
          n,
          o,
          t.animate.duration,
          t.animate.ease || DEFAULT_EASING
        )
        return AnimationManager.putAnimation(this._map.cameraAnimationKey, i)
      }
      s()
      h(1)
      this._photonMapNavigator.endRotate()
      this._map.transformationChanged()
      return Promise.resolve()
    })
    a.then(noop, noop)
    return a
  }
  panBy(t, a) {
    this._map.onReady(() => {
      this._killCurrentAnimation()
      this._photonMapNavigator.beginPan(0, 0)
      this._photonMapNavigator.incrementalPan(
        (2 * t) / this._map.getViewWidth(),
        (-2 * a) / this._map.getViewHeight()
      )
      this._photonMapNavigator.endPan()
      this._constrain()
      this._map.transformationChanged()
    })
  }
  panTo(t) {
    const a = Photon.whenReady(() => {
      const a = createPoint(null, [t.x, t.y, t.z])
      const n = this._map.mapToViewTransformationInternal.transform(a)
      const o = this.normalizeX(n.x)
      const i = this.normalizeY(n.y)
      this._killCurrentAnimation()
      const e = this
      const r = function (t) {
        const a = -t * o
        const n = -t * i
        e._photonMapNavigator.incrementalPan(a, n)
        e._constrain()
      }
      const s = this._photonMapNavigator.beginPan.bind(
        this._photonMapNavigator,
        0,
        0
      )
      const h = r
      const m = this._photonMapNavigator.endPan.bind(this._photonMapNavigator)
      const p = this._createPhotonAnimation(
        s,
        h,
        m,
        LEGACY_DEFAULT_DURATION,
        DEFAULT_EASING
      )
      return AnimationManager.putAnimation(this._map.cameraAnimationKey, p)
    })
    a.then(noop, noop)
    return a
  }
  setCenter(t, a) {
    this._map.onReady(() => {
      this._killCurrentAnimation()
      let n, o
      if (!a) o = t
      else {
        n = t
        o = a
      }
      if (!o)
        throw new ProgrammingError(
          'MapNavigator:setCenter - cannot set center without point argument'
        )
      const i = this._map.camera
      const e = this._calculateReferencePoint(i.eye, i.forward)
      const r = distance3D(i.eye, e)
      const s = i.asLookAt(r)
      const h = this._photonReferenceProvider.getReference(o.reference)
      this._photonMapNavigator.beginMoveTo(
        o,
        h,
        s.distance,
        s.yaw,
        s.pitch,
        s.roll
      )
      h.release()
      this._photonMapNavigator.incrementalMoveTo(1)
      this._photonMapNavigator.endMoveTo()
      if (n) {
        const t = this.normalizeX(n.x)
        const a = this.normalizeY(n.y)
        this._photonMapNavigator.beginPan(0, 0)
        this._photonMapNavigator.incrementalPan(t, a)
        this._photonMapNavigator.endPan()
      }
      this.triggerRefresh()
      this._map.transformationChanged()
    })
  }
  setScale(t) {
    this._map.onReady(() => {
      this._killCurrentAnimation()
      const a = undefined
      const n = normalizeScale(t, 'setScale') / this._map.mapScale[0]
      this._photonMapNavigator.beginZoom(0, 0)
      this._photonMapNavigator.incrementalZoom(1 / n)
      this._photonMapNavigator.endZoom()
      this._map.transformationChanged()
    })
  }
  setScaleFixing(t, a, n) {
    this._map.onReady(() => {
      this._killCurrentAnimation()
      const o = undefined
      const i = normalizeScale(t, 'setScaleFixing') / this._map.mapScale[0]
      this._photonMapNavigator.beginZoom(this.normalizeX(a), this.normalizeY(n))
      this._photonMapNavigator.incrementalZoom(1 / i)
      this._photonMapNavigator.endZoom()
      this._map.transformationChanged()
    })
  }
  zoom(t) {
    const a = this.parseZoomOptions(t, this._map)
    const n = null === a.location.reference
    const o = a.factor
    const i = Photon.whenReady(() => {
      this._killCurrentAnimation()
      if (a.animate) {
        const t = this
        let i = null
        if (n) {
          const t = this._toView(a.location)
          i = this._photonMapNavigator.beginZoom.bind(
            this._photonMapNavigator,
            this.normalizeX(t.x),
            this.normalizeY(t.y)
          )
        } else {
          const t = this._toMap(a.location)
          i = this._photonMapNavigator.beginZoomWorld.bind(
            this._photonMapNavigator,
            t
          )
        }
        const e = function (a) {
          s.currentFactor = lerp(s.startFactor, s.targetFactor, a)
          t._photonMapNavigator.incrementalZoom(s.currentFactor)
        }
        const r = function () {
          if (!s.continueZooming) t._photonMapNavigator.endZoom()
        }
        const s = this._createPhotonAnimation(
          i,
          e,
          r,
          a.animate.duration,
          a.animate.ease
        )
        s._id = 'zoom'
        s.startFactor = 1
        s.targetFactor = 1 / o
        s.currentFactor = s.startFactor
        return AnimationManager.putAnimation(this._map.cameraAnimationKey, s)
      }
      if (n) {
        const t = this._toView(a.location)
        this._photonMapNavigator.beginZoom(
          this.normalizeX(t.x),
          this.normalizeY(t.y)
        )
      } else {
        const t = this._toMap(a.location)
        this._photonMapNavigator.beginZoomWorld(t)
      }
      this._photonMapNavigator.incrementalZoom(1 / o)
      this._photonMapNavigator.endZoom()
      this._map.transformationChanged()
      return Promise.resolve()
    })
    i.then(noop, noop)
    return i
  }
  _extendZoomAnimation(t, a) {
    const n = this
    t.continueZooming = true
    const o = function () {}
    const i = function (t) {
      r.currentFactor = lerp(r.startFactor, r.targetFactor, t)
      n._photonMapNavigator.incrementalZoom(r.currentFactor)
    }
    const e = function () {
      if (!r.continueZooming) n._photonMapNavigator.endZoom()
    }
    const r = this._createPhotonAnimation(o, i, e, t.duration, t.ease)
    r._id = 'zoom'
    r.startFactor = t.currentFactor
    r.targetFactor = 1 / a
    r.currentFactor = r.startFactor
    AnimationManager.putAnimation(this._map.cameraAnimationKey, r).catch(
      () => {}
    )
  }
  beginZoom(t, a) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    this._killCurrentAnimation()
    if (!this._isZooming) {
      this._photonMapNavigator.beginZoom(this.normalizeX(t), this.normalizeY(a))
      this._isZooming = true
    }
  }
  beginZoomWorld(t) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    this._killCurrentAnimation()
    if (!this._isZooming) {
      this._photonMapNavigator.beginZoomWorld(t)
      this._isZooming = true
    }
  }
  incrementalZoom(t, a) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    this._map.onReady(() => {
      this._killCurrentAnimation()
      this._photonMapNavigator.incrementalZoom(1 / t)
      this._constrain()
      this._map.transformationChanged()
    })
  }
  endZoom() {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    if (this._isZooming) {
      this._photonMapNavigator.endZoom()
      this._isZooming = false
    }
  }
  zoomIn() {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    return this._animatedZoomTo(
      this._map.getViewOriginX(),
      this._map.getViewOriginY(),
      0.5
    )
  }
  zoomInFixing(t, a) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    return this._animatedZoomToLocation(t, a, 1)
  }
  zoomInFixingWithScaleFactor(t, a, n, o) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    this._killCurrentAnimation()
    return this._animatedZoomTo(t, a, 1 / n)
  }
  zoomOut() {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    return this._animatedZoomTo(
      this._map.getViewOriginX(),
      this._map.getViewOriginY(),
      2
    )
  }
  zoomOutFixing(t, a) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    return this._animatedZoomToLocation(t, a, -1)
  }
  zoomOutFixingWithScaleFactor(t, a, n, o) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    this._killCurrentAnimation()
    return this._animatedZoomTo(t, a, n)
  }
  zoomTo(t) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    return Photon.whenReady(() => {
      const a = normalizeScale(t, 'zoomTo')
      const n = this._map._convertXComponentMapToPixel(a)
      return this._animatedZoomTo(
        this._map.getViewOriginX(),
        this._map.getViewOriginY(),
        this._map.getScaleX() / n,
        this._snapDirection(n)
      )
    })
  }
  _snapDirection(t) {
    return t > this._map.getScaleX() ? Math.ceil : Math.floor
  }
  violatesBoundsRestriction() {
    return this._mapNavigatorConstraints.violatesBoundsRestriction()
  }
  lookFrom(t, a, n, o, i) {
    if (this._enabledOperations === MapNavigatorBase.NONE) return
    if (!this._map.is3D())
      throw new ProgrammingError(
        'The MapNavigator#lookFrom method is not functional for 2D maps.'
      )
    if (n < -89 || n > 89)
      throw new ProgrammingError(
        'The pitch angle needs to be between -89 and 89 degrees'
      )
    const e = createTransformation(t.reference, this._map.reference)
    let r
    try {
      r = e.transform(t)
    } catch (a) {
      throw new ProgrammingError(
        "Cannot transform eyePoint to the map's reference" + t.toString()
      )
    }
    if (i && i.animate) {
      const t =
        'number' === typeof i.animate.duration
          ? i.animate.duration
          : this.defaults.lookFrom.duration
      const e = Lang.isFunction(i.animate.ease)
        ? i.animate.ease
        : this.defaults.lookFrom.ease
      const s = this._map.camera.lookFrom({
        eye: r,
        yaw: a,
        pitch: n,
        roll: o,
        reference: this._map.reference,
      })
      return this._flyToAnimated(s, { duration: t, ease: e })
    } else {
      this._map.camera = this._map.camera.lookFrom({
        eye: r,
        yaw: a,
        pitch: n,
        roll: o,
        reference: this._map.reference,
      })
      this._map.transformationChanged()
      return Promise.resolve()
    }
  }
  _flyToAnimated(t, a) {
    const n = Photon.whenReady(() => {
      this._killCurrentAnimation()
      const n = this._photonReferenceProvider.getReference(this._map.reference)
      const o = this._calculateReferencePoint(t.eye, t.forward)
      const i = distance3D(t.eye, o)
      const e = t.asLookAt(i)
      const r = this
      const s = function () {
        r._photonMapNavigator.beginMoveTo(
          e.ref,
          n,
          e.distance,
          e.yaw,
          e.pitch,
          e.roll
        )
        n.release()
      }
      const h = this._photonMapNavigator.incrementalMoveTo.bind(
        this._photonMapNavigator
      )
      const m = function () {
        r._photonMapNavigator.endMoveTo()
      }
      const p = this._createPhotonAnimation(s, h, m, a.duration, a.ease)
      return AnimationManager.putAnimation(this._map.cameraAnimationKey, p)
    })
    n.then(noop, noop)
    return n
  }
  _correctedAnimatedZoom2dToConstraints(t, a, n, o) {
    const i = this._map.getScaleX() / n
    const e = this._map.mapToViewTransformation.inverseTransformation
    let r
    try {
      r = e.transform(createPoint(null, [t, a]))
    } catch (o) {
      if (o instanceof OutOfBoundsError) {
        tmpOriginFactor.x = t
        tmpOriginFactor.y = a
        tmpOriginFactor.factor = n
        return
      } else throw o
    }
    const s = new CameraPosition2D(
      t,
      a,
      r.x,
      r.y,
      this._map.getScaleX(),
      this._map.getScaleX()
    )
    const h = new CameraPosition2D(t, a, r.x, r.y, i, i)
    const m = new CameraPosition2D()
    this._mapNavigatorConstraints.correctCameraPosition2D(h, m)
    const p = new Affine2D()
    Affine2D.cameraPosition2DToAffineTransformation(s, p)
    const c = new Affine2D()
    Affine2D.cameraPosition2DToAffineTransformation(m, c)
    Affine2D.shiftViewOrigin(p, c, m)
    if (m.scaleX === this._map.getScaleX()) {
      o.x = t
      o.y = a
      o.factor = 1
    } else {
      o.factor = this._map.getScaleX() / m.scaleX
      o.x = m.viewOriginX
      o.y = m.viewOriginY
    }
  }
  _animatedZoomTo(t, a, n, o) {
    let i = this._correctZoomFactorForSnap(n, o)
    if (!this._map.is3D()) {
      this._correctedAnimatedZoom2dToConstraints(t, a, i, tmpOriginFactor)
      i = tmpOriginFactor.factor
      t = tmpOriginFactor.x
      a = tmpOriginFactor.y
      if (1 === i) return Promise.resolve()
    }
    const e = mapZoom.bind(null, i)
    const r = this.normalizeX(t)
    const s = this.normalizeY(a)
    const h = Photon.whenReady(() => {
      this._killCurrentAnimation()
      const t = this
      const a = t._photonMapNavigator.beginZoom.bind(
        t._photonMapNavigator,
        r,
        s
      )
      const n = function (a) {
        h.fraction = a
        t._photonMapNavigator.incrementalZoom(e(a))
      }
      const o = t._photonMapNavigator.endZoom.bind(t._photonMapNavigator)
      const h = this._createPhotonAnimation(
        a,
        n,
        o,
        LEGACY_DEFAULT_DURATION,
        DEFAULT_EASING
      )
      h.fraction = 0
      h.factor = i
      return AnimationManager.putAnimation(this._map.cameraAnimationKey, h)
    })
    h.then(noop, noop)
    return h
  }
  computeScaleX() {
    if (!this._photonView) return this.map.mapToViewTransformation.getScaleX()
    return this._photonView.getScaleNearCamera()
  }
  computeScaleY() {
    return this.computeScaleX()
  }
  triggerRefresh() {
    if (this._animation) return
    this._constrain()
    this._map.transformationChanged()
  }
  invalidate() {
    this.triggerRefresh()
  }
  _constrain() {
    if (this._map.is3D()) return
    if (0 === this._map.getViewWidth()) return
    if (!this._mapNavigatorConstraints.hasBoundsConstraint()) return
    const t = this._mapNavigatorConstraints.correctTransformation(
      this._map.mapToViewTransformationInternal
    )
  }
  violatesRotation() {
    return this._mapNavigatorConstraints.violatesRotation()
  }
  violatesLeftEdge() {
    return this._mapNavigatorConstraints.violatesLeftEdge()
  }
  violatesRightEdge() {
    return this._mapNavigatorConstraints.violatesRightEdge()
  }
  violatesBottomEdge() {
    return this._mapNavigatorConstraints.violatesBottomEdge()
  }
  violatesTopEdge() {
    return this._mapNavigatorConstraints.violatesTopEdge()
  }
  get constraints2D() {
    return this._mapNavigatorConstraints
  }
  set constraints2D(t) {
    throw new ProgrammingError('constraints2D property is not mutable')
  }
}
let tmpOut = {}
function toPhotonFitMarginType(t) {
  switch (t) {
    case FIT_MARGIN_TYPE.PIXEL:
      return Photon.FitMarginType.PIXELS
    case FIT_MARGIN_TYPE.PERCENTAGE:
    default:
      return Photon.FitMarginType.PERCENTAGE
  }
}
function lerpAngle(t, a, n) {
  if (Math.abs(t - 360 - a) < Math.abs(t - a)) t -= 360
  else if (Math.abs(t + 360 - a) < Math.abs(t - a)) t += 360
  if (0 === n) return t
  else if (1 === n) return a
  return t + n * (a - t)
}
function lerp(t, a, n) {
  if (1 === n) return a
  else if (0 === n) return t
  return t + n * (a - t)
}
function mapZoom(t, a) {
  if (0 === a) return 1
  else if (1 === a) return t
  return 1 - a * (1 - t)
}
const tmpOriginFactor = { x: 0, y: 0, factor: 0 }
export { PhotonMapNavigator }
