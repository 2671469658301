import { ShapeUtil } from './ShapeUtil.js'
import { CircularArc } from './CircularArc.js'
import { ShapeType } from './ShapeType.js'
import { normalizeAngle } from '../util/Cartesian.js'
export class CircularArcByCenterPoint extends CircularArc {
  get type() {
    return ShapeType.CIRCULAR_ARC + ShapeType.CIRCULAR_ARC_BY_CENTER_POINT
  }
  translate2D(e, t) {
    this.center.translate2D(e, t)
    this.invalidate()
  }
  equals(e) {
    if (this === e) return true
    if (!ShapeUtil.referencesEqual(this.reference, e.reference)) return false
    return (
      this.type === e.type &&
      this.radius === e.radius &&
      this.startAzimuth === e.startAzimuth &&
      this.sweepAngle === e.sweepAngle &&
      this.center.equals(e.center)
    )
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    this.center.hashCode(e)
    e.appendDouble(this.radius)
    e.appendDouble(this.startAzimuth)
    e.appendDouble(this.sweepAngle)
  }
  toString() {
    return `CircularArcByCenterPoint[center${this.center.toString(
      true
    )},radius[${this.radius}],startAzimuth[${this.startAzimuth}],sweepAngle[${
      this.sweepAngle
    }]]`
  }
  updateForStartPoint(e) {
    let t = this.forwardAzimuth(this.center, this.endPoint)
    let r = this.forwardAzimuth(this.center, e)
    r = normalizeAngle(r)
    t = normalizeAngle(t)
    let i = t - r
    while (i <= 0) i += 360
    this.sweepAngle = i
    this.startAzimuth = r
  }
  updateForEndPoint(e) {
    let t = this.startAzimuth
    let r = this.forwardAzimuth(this.center, e)
    t = normalizeAngle(t)
    r = normalizeAngle(r)
    let i = r - t
    while (i <= 0) i += 360
    this.sweepAngle = i
  }
}
