import { ReferenceType } from '../reference/ReferenceType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { LLHBounds } from '../shape/LLHBounds.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { XYZBounds } from '../shape/XYZBounds.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { TransformationType } from './TransformationType.js'
import { isObject } from '../util/Lang.js'
export class Transformation {
  constructor(e, r) {
    this._inputReference = e
    this._outputReference = r
  }
  transform(e, r) {
    if (!isObject(r) || null === r) {
      const e = this._outputReference
      if (e && e.TYPE === ReferenceType.GEODETIC) r = new LLHPoint(e)
      else r = new XYZPoint(e)
    }
    this._forward(e, r)
    return r
  }
  transformBounds(e, r) {
    if ('undefined' === typeof r || null === r) {
      const e = this._outputReference
      if (e && e.TYPE === ReferenceType.GEODETIC) r = new LLHBounds(e)
      else r = new XYZBounds(e)
    }
    this._forwardBoundsCoords(e, r)
    return r
  }
  _getType() {
    return TransformationType.TYPE_GENERAL
  }
  get inputReference() {
    return this._inputReference
  }
  set inputReference(e) {
    throw new ProgrammingError('inputReference property is not mutable')
  }
  get outputReference() {
    return this._outputReference
  }
  set outputReference(e) {
    throw new ProgrammingError('outputReference property is not mutable')
  }
  get inverseTransformation() {
    return new InverseTransformation(this)
  }
  set inverseTransformation(e) {
    throw new ProgrammingError('inverseTransformation property is not mutable')
  }
}
class InverseTransformation extends Transformation {
  constructor(e) {
    super(e.outputReference, e.inputReference)
    this._delegate = e
  }
  get inverseTransformation() {
    return this._delegate
  }
  _getType() {
    return this._delegate._getType()
  }
  _forward(e, r) {
    return this._delegate._inverse(e, r)
  }
  _inverse(e, r) {
    return this._delegate._forward(e, r)
  }
  _forwardBoundsCoords(e, r) {
    this._delegate._inverseBoundsCoords(e, r)
  }
  _inverseBoundsCoords(e, r) {
    this._delegate._forwardBoundsCoords(e, r)
  }
}
