import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { Feature } from '../../../model/feature/Feature.js'
import { MemoryStore } from '../../../model/store/MemoryStore.js'
import { createBounds, createPoint } from '../../../shape/ShapeFactory.js'
import { createTransformation } from '../../../transformation/TransformationFactory.js'
import { isArray, isBoolean, isFunction, isOfType } from '../../../util/Lang.js'
import { AutoIncrement } from './AutoIncrement.js'
import { Classifier } from './Classifier.js'
import { ClusteredPoint } from './ClusteredPoint.js'
import { ClusteredPointClosestToCenterClusterShapeProvider } from './ClusteredPointClosestToCenterClusterShapeProvider.js'
import { ClusterStore } from './ClusterStore.js'
import { HierarchicalClusteringAlgorithm } from './HierarchicalClusteringAlgorithm.js'
import { ModelTransformer } from './ModelTransformer.js'
import { NULL_FEATURE } from '../loadingstrategy/ScaleLevelCursor.js'
import { Hash } from '../../../util/Hash.js'
function _isCluster(e) {
  return !!e?.properties && isArray(e.properties.clusteredElements)
}
function _clusteredElements(e) {
  if (_isCluster(e)) return e.properties.clusteredElements
  throw new ProgrammingError(
    'The clusteredFeatures method should only be called with objects which pass the isCluster check.'
  )
}
const idGenerator = new AutoIncrement()
const DEFAULT_CLASSIFICATION = 'Default'
class DefaultClassifier extends Classifier {
  constructor() {
    super()
  }
  getClassification() {
    return DEFAULT_CLASSIFICATION
  }
}
const SCALE_CHANGE_TRANSFORMATION_THRESHOLD = 0.5
const DEFAULT_CLUSTER_SIZE = 200
const DEFAULT_MIN_PTS = 2
const DEFAULT_CLASSIFIER = new DefaultClassifier()
class ClusteringTransformerImpl extends ModelTransformer {
  get options() {
    return this._options
  }
  _classifiedObjects = new Map()
  _clusterShapeProvider =
    new ClusteredPointClosestToCenterClusterShapeProvider()
  _defaultMinimumPoints = DEFAULT_MIN_PTS
  _clusterSize = 0
  _classifier = DEFAULT_CLASSIFIER
  _defaultDisableClustering = false
  _classParameters = [
    { classification: DEFAULT_CLASSIFICATION, parameters: {} },
  ]
  _clusterScale = null
  _modelBounds = null
  constructor(e) {
    super()
    const { levelScales: t, clusteringTransformers: s } = e || {}
    if (t || s) {
      if (!t || !s)
        throw new ProgrammingError(
          'The levelScales and clusteringTransformers properties must be use together.'
        )
      if (t.length + 1 !== s.length)
        throw new ProgrammingError(
          'The length of the clusteringTransformers array should be equal to the length ' +
            'of the levelScales array + 1'
        )
      this._levelScales = t
      this._clusteringTransformers = s
    } else this.updateConfiguration(e)
    this._options = e || null
    this._queriedClustersStore = new MemoryStore()
    this._lastViewParameters = null
    this._classifiedObjects.clear()
    this._reclusterHandleTimeoutId = null
    this._updatedFeatures = []
    this._algorithm = new HierarchicalClusteringAlgorithm()
    this._previousClusters = new ClusterStore()
  }
  release() {
    super.release()
    if (this._reclusterHandleTimeoutId) {
      clearTimeout(this._reclusterHandleTimeoutId)
      this._reclusterHandleTimeoutId = null
    }
  }
  resetCaches() {
    this._previousClusters.clear()
    super._mustRunOnce = true
  }
  updateConfiguration() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    const {
      clusterShapeProvider: t,
      clusterSize: s,
      minimumPoints: r,
      noClustering: i,
    } = e.defaultParameters || {}
    this._classifiedObjects.clear()
    this._classifier = e.classifier || DEFAULT_CLASSIFIER
    this._clusterShapeProvider =
      t || new ClusteredPointClosestToCenterClusterShapeProvider()
    this._clusterSize = s || DEFAULT_CLUSTER_SIZE
    this._defaultMinimumPoints = r || DEFAULT_MIN_PTS
    this._defaultDisableClustering = !!i
    this._classParameters = e.classParameters || []
  }
  doTransformation(e, t, s) {
    this.cluster(e, t, s, [])
    return this._queriedClustersStore.query()
  }
  addToClassifiedObjects(e, t, s, r) {
    const {
      points: i,
      noiseFeatures: o,
      nonClusterableFeatures: n,
      noClustering: l,
    } = this.getFeatureClassification(e)
    const a = e.shape?.focusPoint
    if (!l && a && !t.has(e.id))
      try {
        const t = { x: 0, y: 0, z: 0 }
        s.transform(a, t)
        const n = { x: 0, y: 0, z: 0 }
        r.transform(t, n)
        i.push(new ClusteredPoint(e, a, t, n))
        o.push(e)
      } catch (t) {
        n.push(e)
      }
    else n.push(e)
  }
  updateClassificationForEvent(e, t, s, r, i) {
    if ('remove' === s) {
      this.removeFromClassifiedObjectsForEvent(e)
      this.notifyClusterStoreOfRemovedElements(e)
    } else if ('update' === s || 'add' === s)
      this.addToClassifiedObjectsForEvent(e, t, r, i)
  }
  notifyClusterStoreOfRemovedElements(e) {
    if (_isCluster(e)) {
      const t = _clusteredElements(e)
      for (let e = 0; e < t.length; e++) this._previousClusters.remove(t[e])
    } else this._previousClusters.remove(e)
  }
  addToClassifiedObjectsForEvent(e, t, s, r) {
    const {
      points: i,
      noiseFeatures: o,
      nonClusterableFeatures: n,
    } = this.getFeatureClassification(e)
    if (e.shape?.focusPoint && !t.has(e.id))
      try {
        const t = s.transform(e.shape.focusPoint)
        const n = r.transform(t)
        const l = new ClusteredPoint(e, e.shape.focusPoint, t, n)
        const a = i.filter((t) => e.id === t.modelElement.id)
        if (a.length > 0) {
          const t = i.indexOf(a[0])
          i.splice(t, 1, l)
          o.splice(t, 1, e)
        } else {
          i.push(l)
          o.push(e)
        }
      } catch (t) {
        const s = n.indexOf(e)
        if (s > -1) n.splice(s, 1, e)
        else n.push(e)
      }
    else {
      const t = n.indexOf(e)
      if (t > -1) n.splice(t, 1, e)
      else n.push(e)
    }
  }
  removeFromClassifiedObjectsForEvent(e) {
    const {
      points: t,
      noiseFeatures: s,
      nonClusterableFeatures: r,
    } = this.getFeatureClassification(e)
    if (e?.shape?.focusPoint) {
      const r = t.filter((t) => e.id === t.modelElement.id)
      if (r.length > 0) {
        const e = t.indexOf(r[0])
        t.splice(e, 1)
        s.splice(e, 1)
      }
    } else {
      const t = r.indexOf(e)
      if (t > -1) r.splice(t, 1)
    }
  }
  getFeatureClassification(e) {
    const t = this._classifier.getClassification(e)
    if (!this._classifiedObjects.has(t)) this.addNewClassification(t)
    return this._classifiedObjects.get(t)
  }
  addNewClassification(e) {
    const t = this.getClassConfig(e)
    this._classifiedObjects.set(e, {
      points: [],
      noiseFeatures: [],
      nonClusterableFeatures: [],
      clusterSize: t.clusterSize,
      clusterShapeProvider: t.clusterShapeProvider,
      minimumPoints: t.minimumPoints,
      noClustering: t.noClustering,
    })
  }
  getClassConfig(e) {
    const t = {
      clusterSize: this._clusterSize,
      clusterShapeProvider: this._clusterShapeProvider,
      minimumPoints: this._defaultMinimumPoints,
      noClustering: this._defaultDisableClustering,
    }
    let s = 0
    const r = this._classParameters.length
    let i
    while (s < r) {
      const t = this._classParameters[s]
      const r = t
      const o = t
      if (r.classification && r.classification === e) {
        i = r.parameters
        break
      } else if (o.classMatcher && o.classMatcher(e)) {
        i = o.parameters
        break
      }
      s++
    }
    if (i) {
      t.clusterSize = i.clusterSize || this._clusterSize
      t.clusterShapeProvider =
        i.clusterShapeProvider || this._clusterShapeProvider
      t.minimumPoints = i.minimumPoints || this._defaultMinimumPoints
      t.noClustering = isBoolean(i.noClustering)
        ? i.noClustering
        : this._defaultDisableClustering
    }
    return t
  }
  getConfigurationForCurrentScale(e) {
    for (let t = 0; t < this._levelScales.length; t++)
      if (e <= this._levelScales[t])
        return this._clusteringTransformers[t].options
    return this._clusteringTransformers[this._clusteringTransformers.length - 1]
      .options
  }
  cluster(e, t, s, r) {
    this._lastViewParameters = s
    const {
      reference: i,
      mapToViewTransformation: o,
      mapScale: n,
      mapViewSize: l,
      modelBounds: a,
      interactingFeatures: u,
      layerFilter: c,
    } = s
    const d = isPointInViewFactory(l)
    this._clusterScale = n[0]
    this._modelBounds = a
    const h = createTransformation(t, i)
    if (this._levelScales)
      this.updateConfiguration(
        this.getConfigurationForCurrentScale(this._clusterScale)
      )
    this._classifiedObjects.clear()
    const f = isFunction(c)
    while (e.hasNext()) {
      const t = e.next()
      if (t !== NULL_FEATURE) {
        if (f && !c(t)) u.set(t.id, t)
        this.addToClassifiedObjects(t, u, h, o)
      }
    }
    for (const { feature: e, eventType: t } of r)
      this.updateClassificationForEvent(e, u, t, h, o)
    let m = []
    for (const [e, s] of this._classifiedObjects) {
      const {
        noClustering: r,
        noiseFeatures: i,
        points: o,
        clusterSize: n,
        minimumPoints: l,
        clusterShapeProvider: a,
        nonClusterableFeatures: u,
      } = s
      m = m.concat(u)
      if (r) continue
      const c = []
      const h = []
      for (let e = 0; e < o.length; e++) {
        const t = o[e]
        if (d(t.viewLocation)) c.push(t)
        else h.push(t)
      }
      const f = new Set(i)
      const p = this.getClassClusters(c, f, n, l, a, t, e)
      const C = this.getClassClusters(h, f, n, l, a, t, e)
      m = m.concat(p, C)
      f.forEach((e) => m.push(e))
    }
    this._queriedClustersStore = new MemoryStore({ data: m })
    return m
  }
  getClassClusters(e, t, s, r, i, o, n) {
    if (0 === e.length) return []
    const l = []
    const a = this._previousClusters.restoreClusters(e)
    const u = this._algorithm.cluster(e, a, {
      clusterSize: s,
      minimumPoints: r,
    })
    this._previousClusters.storeClusters(e, u)
    for (const e of u) {
      const s = []
      const r = e.getPoints()
      for (const e of r) {
        s.push(e.modelElement)
        t.delete(e.modelElement)
      }
      const a = isOfType(i, 'getAdvancedLocation')
        ? i.getAdvancedLocation(r)
        : i.getShape(s)
      if (a) {
        const e = createPoint(o, [a.x, a.y])
        const t = new Feature(
          e,
          { clusteredElements: s, classification: n },
          getClusterId(s)
        )
        l.push(t)
      }
    }
    return l
  }
  canPut(e) {
    return !_isCluster(e)
  }
  put(e, t, s) {
    if (_isCluster(t)) throw new ProgrammingError('Clusters cannot be updated')
    else
      return Promise.resolve(e.put(t, s)).then((e) => {
        this._queriedClustersStore.put(t)
        this._eventedSupport.emitModelChangedEvent('update', t, t.id)
        return e
      })
  }
  add(e, t, s) {
    if (_isCluster(t)) {
      const r = []
      _clusteredElements(t).forEach((t) => {
        r.push(Promise.resolve(e.add(t, s)))
      })
      return Promise.all(r).then(() => {
        if (!t.id) t.id = `cluster_${idGenerator.nextKey()}`
        return t.id
      })
    } else
      return Promise.resolve(e.add(t, s)).then((e) => {
        this._queriedClustersStore.add(t)
        this._eventedSupport.emitModelChangedEvent('add', t, t.id)
        return e
      })
  }
  findTransformedFeature(e) {
    return this._queriedClustersStore.get(e)
  }
  remove(e, t) {
    const s = this.findTransformedFeature(t)
    if (!s) return false
    if (_isCluster(s)) {
      const t = _clusteredElements(s).map((t) => e.remove(t.id))
      return Promise.all(t).then((e) => e.every((e) => e))
    }
    return Promise.resolve(e.remove(s.id))
  }
  needsTransformation(e) {
    if (e.mapScale[0] < 1e-9) return false
    if (!this._clusterScale) return true
    const t = undefined
    return (
      100 * Math.abs(1 - this._clusterScale / e.mapScale[0]) >
        SCALE_CHANGE_TRANSFORMATION_THRESHOLD && this.modelBoundsInView(e)
    )
  }
  modelBoundsInView(e) {
    if (this._modelBounds) {
      const t = createBounds(this._modelBounds.reference, [0, 0, 0, 0])
      e.getMapBoundsSFCT(t)
      return t.interacts2D(this._modelBounds)
    }
    return true
  }
  reclusterForModelUpdates(e, t) {
    if (!this._lastViewParameters)
      throw new Error(
        'ClusteringTransformation: unexpected situation - missing lastViewParameters'
      )
    const s = new Map()
    const r = new Map()
    const i = this._queriedClustersStore.query()
    while (i.hasNext()) {
      const e = i.next()
      s.set(e.id, e)
    }
    const o = this.cluster(
      e,
      t,
      this._lastViewParameters,
      this._updatedFeatures
    )
    for (const e of o) r.set(e.id, e)
    s.forEach((e, t) => {
      if (!r.has(t)) this._eventedSupport.emitModelChangedEvent('remove', e, t)
    })
    const n = new Set(this._updatedFeatures.map((e) => e.id))
    r.forEach((e, t) => {
      const r = !s.has(t)
      if (r) this._eventedSupport.emitModelChangedEvent('add', e, e.id)
      if (!r && n.size)
        if (isCluster(e)) {
          let s = false
          clusteredFeatures(e).forEach((e) => {
            if (n.has(e.id)) {
              n.delete(e.id)
              s = true
            }
          })
          if (s) this._eventedSupport.emitModelChangedEvent('update', e, t)
        } else if (n.has(t)) {
          n.delete(t)
          this._eventedSupport.emitModelChangedEvent('update', e, t)
        }
    })
    this._updatedFeatures.length = 0
  }
  handleSourceModelChange(e, t, s, r) {
    this._updatedFeatures.push({ eventType: t, feature: s, id: r })
    if (null === this._reclusterHandleTimeoutId)
      this._reclusterHandleTimeoutId = window.setTimeout(() => {
        Promise.resolve(e.query()).then((t) => {
          this.reclusterForModelUpdates(t, e.reference)
          this._reclusterHandleTimeoutId = null
        })
      }, 1)
  }
  prepareForSelection(e, t) {
    if (!t) return
    if (this._queriedClustersStore.get(e));
    else {
      const t = this._queriedClustersStore.query()
      while (t.hasNext()) {
        const s = t.next()
        if (_isCluster(s)) {
          const { clusteredElements: t, classification: r } = s.properties
          const { minimumPoints: i } = this.getClassConfig(r)
          for (let r = 0; r < t.length; r++) {
            let o = t[r]
            if (o.id === e) {
              if (t.length - 1 < i) {
                this._queriedClustersStore.remove(s.id)
                this._eventedSupport.emitModelChangedEvent('remove', s, s.id)
                for (let e = 0; e < t.length; e++) {
                  o = t[e]
                  this._queriedClustersStore.add(o)
                  this._eventedSupport.emitModelChangedEvent('add', o, o.id)
                }
              } else {
                t.splice(r, 1)
                this._queriedClustersStore.put(s)
                this._eventedSupport.emitModelChangedEvent('update', s, s.id)
                this._queriedClustersStore.add(o)
                this._eventedSupport.emitModelChangedEvent('add', o, o.id)
              }
              return
            }
          }
        }
      }
    }
  }
}
function isPointInViewFactory(e) {
  let [t, s] = e
  const r = 0.2 * t
  const i = 0.2 * s
  const o = -r
  const n = t + r
  const l = -i
  const a = s + i
  return function (e) {
    let { x: t, y: s } = e
    return t > o && t < n && s > l && s < a
  }
}
export function create(e) {
  return new ClusteringTransformerImpl(e)
}
export function isCluster(e) {
  return _isCluster(e)
}
export function clusteredFeatures(e) {
  return _clusteredElements(e)
}
export function createScaleDependent(e) {
  if (e && e.clusteringTransformers)
    for (const t of e.clusteringTransformers) {
      const e = t.options
      if (e && (e.clusteringTransformers || e.levelScales))
        throw new ProgrammingError(
          'Each of the clustering transformers contained in the options of passed to the createScaleDependent method ' +
            'should have been created by the create method. ' +
            'None of those clustering transformers should be a scale dependent transformer.'
        )
    }
  return new ClusteringTransformerImpl(e)
}
function getClusterId(e) {
  const t = e.map((e) => e.id).sort()
  const s = new Hash()
  for (let e = 0; e < t.length; e++) s.append(t[e])
  return s.getHashCode()
}
