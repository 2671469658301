import { ProgrammingError } from '../../error/ProgrammingError.js'
import { createOptimizedEventedSupport } from '../../util/EventedSupport.js'
import { hasProperty, isFunction } from '../../util/Lang.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
export class FeaturePainter {
  constructor() {
    this._density = null
  }
  get eventedSupport() {
    if (!this._eventedSupport)
      this._eventedSupport = createOptimizedEventedSupport([
        'Invalidate',
        'InvalidateById',
        'InvalidateAll',
      ])
    return this._eventedSupport
  }
  paintBody(t, e, n, i, r, a) {
    throw new ProgrammingError('FeaturePainter must implement paintBody()')
  }
  invalidate(t) {
    this.eventedSupport.emitInvalidateEvent(t)
  }
  invalidateById(t) {
    this.eventedSupport.emitInvalidateByIdEvent(t)
  }
  invalidateAll() {
    this.eventedSupport.emitInvalidateAllEvent()
  }
  getDetailLevelScales(t, e) {
    return null
  }
  get density() {
    return this._density
  }
  set density(t) {
    this._density = t
    this.invalidateAll()
  }
  on(t, e, n) {
    if (this.eventedSupport.canHandle(t)) return this.eventedSupport.on(t, e, n)
    throw new ProgrammingError('Event ' + t + ' is not supported')
  }
  emit(t, e) {
    return this.eventedSupport.emit(t, e)
  }
  existsPaintFunc(t) {
    return hasProperty(this, t) && isFunction(this[t])
  }
  getSupportedPaintRepresentations() {
    const t = []
    if (this.existsPaintFunc('paintBody')) t.push(PaintRepresentation.BODY)
    if (this.existsPaintFunc('paintLabel')) t.push(PaintRepresentation.LABEL)
    if (this.existsPaintFunc('paintBorderBody')) {
      t.push(PaintRepresentation.BOTTOM_BORDER_BODY)
      t.push(PaintRepresentation.LEFT_BORDER_BODY)
    }
    if (this.existsPaintFunc('paintBorderLabel')) {
      t.push(PaintRepresentation.BOTTOM_BORDER_LABEL)
      t.push(PaintRepresentation.LEFT_BORDER_LABEL)
    }
    return t
  }
  isSupportedPaintRepresentation(t) {
    if (t === PaintRepresentation.BODY) return this.existsPaintFunc('paintBody')
    if (t === PaintRepresentation.LABEL)
      return this.existsPaintFunc('paintLabel')
    if (
      t === PaintRepresentation.BOTTOM_BORDER_BODY ||
      t === PaintRepresentation.LEFT_BORDER_BODY
    )
      return this.existsPaintFunc('paintBorderBody')
    if (
      t === PaintRepresentation.BOTTOM_BORDER_LABEL ||
      t === PaintRepresentation.LEFT_BORDER_LABEL
    )
      return this.existsPaintFunc('paintBorderLabel')
    return false
  }
  getPaintFunction(t) {
    if (-1 === this.getSupportedPaintRepresentations().indexOf(t)) return null
    if (t === PaintRepresentation.BODY) return this.paintBody
    if (t === PaintRepresentation.LABEL) return this.paintLabel
    if (
      t === PaintRepresentation.BOTTOM_BORDER_BODY ||
      t === PaintRepresentation.LEFT_BORDER_BODY
    )
      return this.paintBorderBody
    if (
      t === PaintRepresentation.BOTTOM_BORDER_LABEL ||
      t === PaintRepresentation.LEFT_BORDER_LABEL
    )
      return this.paintBorderLabel
  }
}
