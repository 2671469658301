export class SideGlowRenderer {
  constructor() {
    this._leftViolation = null
    this._rightViolation = null
    this._topViolation = null
    this._bottomViolation = null
    this._dragStart = null
    this._dragPoint = null
  }
  drawGlow(t, i, o, a, e, l, n, r) {
    const s = Math.round(200 - Math.max(Math.min(n, 140), 0))
    n = Math.min(155, n)
    const h = (55 + (n = Math.max(0, n))) / 255
    r.beginPath()
    r.moveTo(t, i)
    r.bezierCurveTo(o, a, o, a, e, l)
    r.fillStyle = `rgba(${s},${s},${s},${h})`
    r.fill()
  }
  updateDragPoint(t, i) {
    if (!this._dragStart && i) this._dragStart = i
    this._dragPoint = i
    if (null == i) this._dragStart = null
    t.invalidate()
  }
  updateViolationVisualFeedback(t, i) {
    if (null === this._dragStart || null === this._dragPoint) {
      this._leftViolation = null
      this._rightViolation = null
      this._bottomViolation = null
      this._topViolation = null
      return
    }
    const o = this._dragPoint.x - this._dragStart.x
    const a = this._dragPoint.y - this._dragStart.y
    let e
    const l = 4
    if (t.mapNavigator.violatesLeftEdge() && o >= 0) {
      if (null === this._leftViolation) this._leftViolation = this._dragPoint.x
      e = Math.abs(this._dragPoint.x - this._leftViolation) / l
      this.drawGlow(
        t.getLeftBorderSize(),
        0,
        t.getLeftBorderSize() + e,
        this._dragPoint.y,
        t.getLeftBorderSize(),
        t.viewSize[1],
        e,
        i.getContext2d()
      )
    }
    if (t.mapNavigator.violatesBottomEdge() && a <= 0) {
      if (null === this._bottomViolation)
        this._bottomViolation = this._dragPoint.y
      e = Math.abs(this._dragPoint.y - this._bottomViolation) / l
      this.drawGlow(
        t.getLeftBorderSize(),
        t.viewSize[1],
        this._dragPoint.x,
        t.viewSize[1] - e,
        t.viewSize[0],
        t.viewSize[1],
        e,
        i.getContext2d()
      )
    }
    if (t.mapNavigator.violatesRightEdge() && o <= 0) {
      if (null === this._rightViolation)
        this._rightViolation = this._dragPoint.x
      e = Math.abs(this._dragPoint.x - this._rightViolation) / l
      this.drawGlow(
        t.viewSize[0] + t.getLeftBorderSize(),
        0,
        t.viewSize[0] - e + t.getLeftBorderSize(),
        this._dragPoint.y,
        t.viewSize[0] + t.getLeftBorderSize(),
        t.viewSize[1],
        e,
        i.getContext2d()
      )
    }
    if (t.mapNavigator.violatesTopEdge() && a >= 0) {
      if (null === this._topViolation) this._topViolation = this._dragPoint.y
      e = Math.abs(this._dragPoint.y - this._topViolation) / l
      this.drawGlow(
        t.getLeftBorderSize(),
        0,
        this._dragPoint.x,
        e,
        t.viewSize[0],
        0,
        e,
        i.getContext2d()
      )
    }
  }
}
