import { createHTML5Canvas } from './HTML5Canvas.js'
const targetFrameTime = 16
const maxFrameTime = 100
function clear(e, t, o) {
  const n = e.globalCompositeOperation
  e.globalCompositeOperation = 'copy'
  e.fillStyle = 'rgba(0, 0, 0, 0.1)'
  e.fillRect(0, 0, t, o)
  e.globalCompositeOperation = n
}
function paint(e, t, o, n, l, r, a, i, s) {
  const c = l - 100
  const m = undefined
  const f = undefined
  paintGraph(e, t, o, 0, r, c, r, c / t.length, r / maxFrameTime, s)
  const p = e.shadowColor
  e.shadowColor = 'rgb(0, 0, 0)'
  e.fillStyle = 'rgb(255,255,255)'
  paintText(e, c, r, 100, r, t[o], n, a, i)
  e.shadowColor = p
  e.strokeStyle = 'rgb(0,0,0)'
  e.strokeRect(0, 0, l, r)
}
function paintGraph(e, t, o, n, l, r, a, i, s, c) {
  const m = n + r
  const f = l - a
  let p
  e.strokeStyle = 'rgb(255, 255, 255)'
  for (let t = 0; t <= maxFrameTime; t += 16) {
    e.beginPath()
    p = (l - t * s) | 0
    e.moveTo(n, p)
    e.lineTo(m, p)
    e.stroke()
  }
  e.strokeStyle = 'rgb(255, 0, 0)'
  e.beginPath()
  p = (l - targetFrameTime * s) | 0
  e.moveTo(n, p)
  e.lineTo(m, p)
  e.stroke()
  e.strokeStyle = 'rgb(0, 0, 255)'
  e.beginPath()
  const h = (n + o * i) | 0
  e.moveTo(h, l)
  e.lineTo(h, f)
  e.stroke()
  e.strokeStyle = c || 'rgb(0, 255, 0)'
  e.beginPath()
  e.moveTo(n, (l - Math.min(t[0], maxFrameTime) * s) | 0)
  for (let o = 1; o < t.length; o++) {
    const r = Math.min(t[o], maxFrameTime)
    e.lineTo((n + o * i) | 0, (l - r * s) | 0)
  }
  e.stroke()
}
function formatNumber(e, t, o) {
  let n = e.toFixed(t)
  while (n.length < o) n = ' ' + n
  return n
}
function paintText(e, t, o, n, l, r, a, i, s) {
  e.fillText('0', t, o - 2)
  e.fillText((maxFrameTime / 2) | 0, t, o - l / 2)
  e.fillText(maxFrameTime, t, o - l + 10)
  const c = t + 30
  e.fillText(s, c, o - l + 10 + i)
  e.fillText('Time', c, o - l + 20 + i)
  e.fillText(formatNumber(r, 0, 3) + ' ms', c + 30, o - l + 20 + i)
  e.fillText('Avg', c, o - l + 30 + i)
  e.fillText(formatNumber(a, 0, 3) + ' ms', c + 30, o - l + 30 + i)
}
function createDisplayNode(e, t) {
  const o = document.createElement(e)
  o.classList = t
  o.style.zIndex = 5e3
  o.style.position = 'absolute'
  o.style.top = '10px'
  o.style.right = '50%'
  o.style.fontSize = '200%'
  o.style.color = 'white'
  o.style.textShadow = '0px 0px 4px black'
  return o
}
export const PerformanceOverlay = {
  createMonitoredFunction: function (e, t, o, n, l) {
    let r = [e]
    {
      const e = createDisplayNode('div', 'showPerfFpsOverlay')
      t.appendChild(e)
      let l = performance.now()
      let a = 0
      let i = 0
      let s = 0
      let c = 0
      r.push(() => {
        const t = performance.now()
        const r = t - l
        l = t
        i += 1
        a += r
        s = 0.1 * r + 0.9 * s
        c = Math.max(c, r)
        e.innerHTML = Math.round(1e3 / s) + ' fps'
        if (o) e.innerHTML += ' ' + Math.round(i / (a / 1e3)) + ' avg'
        if (n) e.innerHTML += ' ' + Math.round(1e3 / c) + ' min'
        e.onclick = () => {
          l = performance.now()
          a = 0
          i = 0
          s = 0
          c = 0
        }
      })
    }
    if (l) {
      const e = 300
      const o = 100
      const n = createHTML5Canvas(e, o)
      n.style.zIndex = 5e3
      n.style.width = e + 'px'
      n.style.height = o + 'px'
      n.style.margin = 0
      n.style.border = 0
      n.style.padding = 0
      n.style.display = 'inline-block'
      n.style.position = 'absolute'
      n.style.top = 0
      t.appendChild(n)
      const l = n.getContext('2d')
      l.font = '10px sans-serif'
      l.shadowOffsetX = 1
      l.shadowOffsetY = 1
      l.shadowBlur = 1
      const a = new Array(500)
      let i
      for (i = 0; i < a.length; i++) a[i] = 0
      const s = new Array(500)
      for (i = 0; i < s.length; i++) s[i] = 0
      let c = 0
      let m = 0
      let f = performance.now()
      let p = 0
      r.push(() => {
        const e = performance.now()
        const t = undefined
        const o = performance.now() - e
        m = 0.05 * o + 0.95 * m
        a[c] = o
        const r = performance.now()
        const i = r - f
        f = r
        p = 0.05 * i + 0.95 * p
        s[c] = i
        clear(l, n.width, n.height)
        paint(
          l,
          s,
          c,
          0 | p,
          n.width,
          n.height,
          50,
          'Call-to-call',
          'rgb(255, 255, 0)'
        )
        paint(l, a, c, 0 | m, n.width, n.height, 0, 'Call', 'rgb(0, 255, 0)')
        c = (c + 1) % a.length
      })
    }
    return () => {
      r.forEach((e) => e())
    }
  },
}
