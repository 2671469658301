import { Lang } from '../util/Lang.js'
import { getReference } from '../reference/ReferenceProvider.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { OrthographicCamera } from './camera/OrthographicCamera.js'
import { PerspectiveCamera } from './camera/PerspectiveCamera.js'
const isString = Lang.isString
export class CameraFactory {
  constructor(e) {
    this._map = e
  }
  create2DCamera(e, r, a) {
    e = isString(e) ? getReference(e) : e
    r = r || (this._map ? this._map.camera.width : 1)
    a = a || (this._map ? this._map.camera.height : 1)
    return OrthographicCamera.createDefault2DCamera(e, r, a)
  }
  createPerspectiveCamera(e, r, a) {
    return PerspectiveCamera.createDefaultCamera(e, r, a)
  }
  createCameraForReference(e, r, a) {
    return (e = isString(e) ? getReference(e) : e).referenceType ===
      ReferenceType.GEOCENTRIC
      ? this.createPerspectiveCamera(e, r, a)
      : this.create2DCamera(e, r, a)
  }
}
