import { Clustered } from './Clustered.js'
export class ClusterStore {
  constructor() {
    this._clusterIndices = new Map()
    this._nextClusterIndex = 1
  }
  restoreClusters(e) {
    const t = []
    if (0 === this._clusterIndices.size) return t
    const s = new Map()
    for (let n = 0; n < e.length; n++) {
      const l = e[n]
      const r = this._clusterIndices.get(l.modelElement)
      if (!r) continue
      let c = s.get(r)
      if (!c) {
        c = new Clustered()
        s.set(r, c)
        t.push(c)
      }
      c.addPoint(l)
    }
    return t
  }
  storeClusters(e, t) {
    for (let e = 0; e < t.length; e++) {
      const s = undefined
      const n = t[e].getPoints()
      for (let e = 0; e < n.length; e++)
        this._clusterIndices.set(n[e].modelElement, this._nextClusterIndex)
      this._nextClusterIndex++
    }
    for (let t = 0; t < e.length; t++) {
      const s = e[t]
      if (!s.cluster) this._clusterIndices.delete(s.modelElement)
    }
  }
  clear() {
    this._clusterIndices.clear()
  }
  remove(e) {
    this._clusterIndices.delete(e)
  }
}
