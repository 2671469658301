import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isString } from '../../util/Lang.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { CompositeDrawCommand } from './CompositeDrawCommand.js'
import { DrawCompositeLabel } from './DrawCompositeLabel.js'
import { DrawInPathLabelHtmlContents } from './DrawInPathLabelHtmlContents.js'
import { DrawNullLabel } from './DrawNullLabel.js'
import { DrawOnPathLabelHtmlContents } from './DrawOnPathLabelHtmlContents.js'
import { DrawOnPathRepeatingLabelCommand } from './DrawOnPathRepeatingLabelCommand.js'
import { DrawPointLabelHtmlContents } from './DrawPointLabelHtmlContents.js'
import {
  createDrawComposite,
  createDrawNull,
  createDrawShape,
} from './HTML5DrawCommands.js'
import {
  normalizeHtmlInPathLabelStyle,
  normalizeHtmlLabelStyle,
  normalizeHtmlOnPathLabelStyle,
} from './StyleUtil.js'
const dummyStyle = { stroke: {} }
const DIRTY_CONTENT_REGEX =
  /<[\s]*script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script[\s]*>/gi
function sanitizeHTMLString(t) {
  while (DIRTY_CONTENT_REGEX.test(t)) t = t.replace(DIRTY_CONTENT_REGEX, '')
  return t
}
function sanitizeHTML(t) {
  return isString(t) ? sanitizeHTMLString(t) : t
}
export class HTML5LabelCanvas {
  _labelContext = null
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    this.commands = []
    this.bodyDrawCommand = null
    this._labelCommands = new CompositeDrawCommand([])
    this.selected = false
    this.labelPaintRepresentation = PaintRepresentation.LABEL
    this._invalidateAction = t.invalidateAction || (() => null)
  }
  resetLabelContext(t, e, a) {
    this._labelContext = t
    this.labelPaintRepresentation = t.labelPaintRepresentation
    this.bodyDrawCommand = e
    this._labelCommands.clear()
    this.commands.length = 0
    this.selected = a
  }
  _getDrawCommand(t) {
    const e = this._findDrawCommand(t)
    if (null !== e) return e
    return this._createDrawCommand(t)
  }
  _findDrawCommand(t) {
    if (!t) return null
    let e = this.bodyDrawCommand
      ? this.bodyDrawCommand.findDrawCommand(t)
      : null
    if (null !== e) return e
    e = this._labelCommands.findDrawCommand(t)
    if (null !== e) return e
    return null
  }
  _createDrawCommand(t) {
    const e = this.createDrawCommand0(t)
    if (e) this._labelCommands.add(e)
    return e
  }
  createDrawCommand0(t) {
    if (!t || !this._labelContext) return createDrawNull()
    const e = []
    createDrawShape(
      t,
      dummyStyle,
      {
        worldReference: this._labelContext.worldReference,
        worldSizeSupport: this._labelContext.worldSizeSupport,
      },
      e
    )
    if (0 === e.length) return createDrawNull()
    else if (1 === e.length) return e[0]
    else return createDrawComposite(e)
  }
  collectDrawCommandArguments(t, e) {
    let { group: a, priority: n } = e
    if (!this._labelContext?.labelManager)
      throw new ProgrammingError('Missing Label Drawing Context')
    const r = this._labelContext.labelManager
    return {
      drawCommand: this._getDrawCommand(t),
      group: r.getLabelGroup(a),
      priority: n,
      selected: this.selected,
    }
  }
  getDrawCommand() {
    const t = this.commands.length
    return 0 === t
      ? DrawNullLabel
      : 1 === t
      ? this.commands[0]
      : new DrawCompositeLabel(this.commands.slice(0))
  }
  drawLabel(t, e, a) {
    t = sanitizeHTML(t)
    const n = normalizeHtmlLabelStyle(a, this.labelPaintRepresentation)
    const {
      drawCommand: r,
      group: o,
      priority: l,
    } = this.collectDrawCommandArguments(e, n)
    this.commands.push(
      new DrawPointLabelHtmlContents(
        this._labelContext,
        t,
        r,
        o,
        l,
        this.selected,
        n
      )
    )
  }
  drawLabelInPath(t, e, a) {
    const n = normalizeHtmlInPathLabelStyle(a)
    t = sanitizeHTML(t)
    const {
      drawCommand: r,
      group: o,
      priority: l,
    } = this.collectDrawCommandArguments(e, n)
    this.commands.push(
      new DrawInPathLabelHtmlContents(
        this._labelContext,
        t,
        r,
        o,
        l,
        this.selected,
        n
      )
    )
  }
  drawLabelOnPath(t, e, a) {
    const n = normalizeHtmlOnPathLabelStyle(a)
    t = sanitizeHTML(t)
    const {
      drawCommand: r,
      group: o,
      priority: l,
    } = this.collectDrawCommandArguments(e, n)
    const i = a.repeat
      ? DrawOnPathRepeatingLabelCommand
      : DrawOnPathLabelHtmlContents
    this.commands.push(new i(this._labelContext, t, r, o, l, this.selected, n))
  }
}
