import { WrapperPattern } from './WrapperPattern.js'
import { PatternType } from './util/PatternType.js'
export class RepeatCountPattern extends WrapperPattern {
  constructor(t, n) {
    super(t, PatternType.REPEAT_COUNT)
    this._count = n
    this.atomic = false
  }
  get count() {
    return this._count
  }
  getWidth(t) {
    return this._count * this.pattern.getWidth(t)
  }
  paintOnceOnLine(t, n, e) {
    const a = this.getWidth(n.totalLength())
    if (this.atomic)
      if (!this.canPaintAtomic(t, n, e)) {
        const i = Math.min(n.distanceFromStart() + a, e[1])
        this.drawFallback(t, n, i, e)
        return true
      }
    let i = 0
    while (i < this._count && !n.atEnd()) {
      this.pattern.paintOnceOnLine(t, n, e)
      i += 1
    }
    return i === this._count
  }
  canPaintAtomic(t, n, e) {
    const a = n.totalLength()
    const i = undefined
    const r = undefined
    if (this.getWidth(a) > n.distanceToEnd()) return false
    n.saveState()
    const s = this.pattern
    const c = s.getWidth(a)
    let o = true
    let h = 0
    while (o && h < this._count) {
      o = o && s.canPaintOnce(n, e)
      if (o) n.advanceDistance(c)
      h++
    }
    n.restoreState()
    return o
  }
  paintPartial(t, n, e, a) {
    if (this.atomic) return
    const i = n.totalLength()
    let r = n.distanceFromStart()
    let s = 0
    const c = this.pattern.getWidth(i)
    while (s < this._count && !n.atEnd()) {
      r += c
      if (r > e) {
        this.pattern.paintPartial(t, n, e, a)
        break
      }
      this.pattern.paintOnceOnLine(t, n, a)
      s += 1
    }
  }
  appendHash(t) {
    t.appendUInt32(+this._count)
    super.appendHash(t)
  }
}
