import { distance2D_xy } from '../../../util/Cartesian.js'
export class ClipSectionRecorder {
  constructor() {
    this.log = []
    this.x0 = 0
    this.y0 = 0
    this.x1 = 0
    this.y1 = 0
    this.distanceToSegment = 0
    this.segmentLength = 0
    this.totalDistance = 0
  }
  start(t, s) {
    this.log.length = 0
    this.x0 = t
    this.y0 = s
    this.x1 = t
    this.y1 = s
    this.distanceToSegment = 0
    this.segmentLength = 0
    this.totalDistance = 0
  }
  segment(t, s) {
    this.x0 = this.x1
    this.y0 = this.y1
    this.x1 = t
    this.y1 = s
    this.distanceToSegment += this.segmentLength
    this.segmentLength = distance2D_xy(this.x0, this.y0, t, s)
    this.totalDistance += this.segmentLength
  }
  section(t, s, i) {
    const e = distance2D_xy(this.x0, this.y0, t, s)
    const h = this.distanceToSegment + e
    if (h) this.log.push([h, i])
  }
  report() {
    const t = this.log.length
    if (t) {
      const s = this.log[t - 1]
      if (this.totalDistance - s[0] > 0.01)
        this.log.push([this.totalDistance, false])
      let i = null
      const e = []
      for (let t = this.log.length - 1; t >= 0; t--) {
        const s = this.log[t]
        const h = s[1]
        if (h !== i) {
          e.push({ endDistance: s[0], visibility: h })
          i = h
        }
      }
      return { clippedSections: e.reverse(), totalDistance: this.totalDistance }
    }
    return null
  }
}
