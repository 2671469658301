import { PatternType } from './pattern/util/PatternType.js'
import {
  advanceWithLimit,
  drawFlexibleStyleLine,
  drawSimpleLine,
  drawStyledLine,
  handleClipLimits,
  findStrokedPattern,
  PIXEL_EPSILON,
} from './pattern/util/PatternUtil.js'
import { Serializable } from './pattern/util/Serializable.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
export class Pattern {
  _fallbackPattern = null
  _atomic = false
  _flexible = false
  _canBend = false
  constructor(t, e) {
    this._patternType = t
    this._errorTolerance = e ?? 5
  }
  get patternType() {
    return this._patternType
  }
  get errorTolerance() {
    return this._errorTolerance
  }
  get atomic() {
    return this._atomic
  }
  set atomic(t) {
    this._atomic = t
  }
  get flexible() {
    return this._flexible
  }
  set flexible(t) {
    this._flexible = t
  }
  get canBend() {
    return this._canBend
  }
  set canBend(t) {
    this._canBend = t
  }
  get lineJoin() {
    return null
  }
  set lineJoin(t) {}
  setFallbackPattern(t) {
    if (this === t) this._fallbackPattern = null
    this._fallbackPattern = t
  }
  getMinHeight() {
    return 0
  }
  getMaxHeight() {
    return 0
  }
  getWidth(t) {
    return 0
  }
  isRelativeLength() {
    return false
  }
  resolveState() {
    return null
  }
  paint(t, e, i, r) {
    throw new ProgrammingError(
      `paint should be implemented for patterns of type ${this.patternType}.`
    )
  }
  paintFlexible(t, e, i) {
    throw new ProgrammingError(
      `paintFlexible should be implemented for patterns of type ${this.patternType}.`
    )
  }
  appendHash(t) {
    throw new ProgrammingError(
      `appendHash should be implemented for patterns of type ${this.patternType}.`
    )
  }
  simpleStroke(t) {
    const e = findStrokedPattern(this._fallbackPattern ?? this)
    if (e && e.lineColor) {
      t.save()
      t.lineWidth = e.lineWidth || 1
      t.strokeStyle = e.lineColor
      t.stroke()
      t.restore()
    }
  }
  paintForward(t, e, i, r) {
    t.save()
    t.translate(e.x(), e.y())
    t.rotate(i)
    t.translate(r, 0)
    this.paint(t, e.totalLength(), i)
    t.restore()
  }
  paintBack(t, e, i) {
    if (i < 0) {
      t.save()
      t.translate(e.x(), e.y())
      const r = e.angle()
      t.rotate(r)
      t.translate(i, 0)
      this.paint(t, e.totalLength(), r)
      t.restore()
      e.advanceDistance(this.getWidth(e.totalLength()) + i)
    }
  }
  paintSimple(t, e, i) {
    const r = this.getWidth(e.totalLength())
    t.save()
    t.translate(e.x(), e.y())
    const a = e.angleToNext()
    t.rotate(a)
    handleClipLimits(t, e, r, i, [this.getMinHeight(), this.getMaxHeight()])
    this.paint(t, e.totalLength(), a, r)
    t.restore()
    advanceWithLimit(e, r, i[1])
  }
  paintFlexibleWithClipLimits(t, e, i, r) {
    t.save()
    t.translate(e.x(), e.y())
    const a = e.angleToNext()
    t.rotate(a)
    handleClipLimits(t, e, i, r, [this.getMinHeight(), this.getMaxHeight()])
    this.paintFlexible(t, e, i)
    t.restore()
    advanceWithLimit(e, i, r[1])
  }
  paintShortcut(t, e, i) {
    const r = this.getWidth(e.totalLength())
    t.save()
    t.translate(e.x(), e.y())
    const a = e.jumpAngle(r)
    t.rotate(a)
    handleClipLimits(t, e, r, i, [this.getMinHeight(), this.getMaxHeight()])
    this.paint(t, e.totalLength(), a, r)
    t.restore()
    e.jumpDistance(r)
  }
  paintOnceOnLine(t, e, i) {
    if (e.atEnd() || e.distanceFromStart() >= i[1]) return false
    const r = e.totalLength()
    const a = this.getWidth(r)
    if (this.canPaintFullPattern(e, a, i)) {
      this.paintSimple(t, e, i)
      return true
    }
    const n = Math.min(e.distanceFromStart() + a, i[1])
    if (this.canPaintOverCorner()) {
      this.paintPartial(t, e, n, i)
      return true
    }
    if (this.canPaintShortcut(e, a, i)) {
      this.paintShortcut(t, e, i)
      return true
    }
    this.drawFallback(t, e, n, i)
    return false
  }
  canPaintFullPattern(t, e, i) {
    const r = Math.min(t.absoluteDistanceToNextVertex(), i[1])
    const a = t.distanceFromStart() + e
    if (a <= r + PIXEL_EPSILON) return true
    if (this.isRelativeLength() && !this.atomic)
      return a <= t.absoluteDistanceToNextVertex() + PIXEL_EPSILON
    return false
  }
  canPaintShortcut(t, e, i) {
    return (
      t.distanceFromStart() + e <= i[1] + PIXEL_EPSILON &&
      t.jumpError(e) <= this._errorTolerance
    )
  }
  canPaintOverCorner() {
    return !this.atomic && this.canBend
  }
  canPaintOnce(t, e) {
    if (t.atEnd()) return false
    if (this.canPaintOverCorner()) return true
    const i = t.totalLength()
    const r = this.getWidth(i)
    return this.canPaintFullPattern(t, r, e) || this.canPaintShortcut(t, r, e)
  }
  paintPartial(t, e, i, r) {
    if (this.canBend) drawFlexibleStyleLine(e, t, this, i, r)
  }
  drawFallback(t, e, i, r) {
    const a = e.distanceFromStart()
    if (i < a) return
    if (this._fallbackPattern) drawStyledLine(e, t, this._fallbackPattern, i, r)
    else if (findStrokedPattern(this)) drawSimpleLine(e, t, this, i, r)
    else e.advanceDistance(i - a)
  }
  serialize() {
    const t = Object.assign({}, this)
    t._patternType = PatternType[this._patternType]
    delete t._fallbackPattern
    delete t._errorTolerance
    delete t._atomic
    delete t._flexible
    return Serializable.serialize(t)
  }
}
