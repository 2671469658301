import { GeodeticReference } from '../reference/GeodeticReference.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { createBounds, createPoint } from '../shape/ShapeFactory.js'
import { GeodeticGeocentricTransformation } from './GeodeticGeocentricTransformation.js'
import { Transformation } from './Transformation.js'
export class AboveTerrainTransformation extends Transformation {
  constructor(e, o) {
    super(e.inputReference, e.outputReference)
    if (e.inputReference?.referenceType === ReferenceType.GEOCENTRIC)
      throw new Error(
        'Cannot use geocentric reference as input for AboveTerrainTransformation'
      )
    this._trans = e
    const t = e.outputReference
    this._geocentric = t.referenceType === ReferenceType.GEOCENTRIC
    this._geodeticPoint = null
    this._geodeticBounds = null
    this._geodeticToGeocentric = null
    if (this._geocentric) {
      const e = t.geodeticDatum
      const r = new GeodeticReference({ geodeticDatum: e })
      this._geodeticPoint = createPoint(r, [0, 0, 0])
      this._geodeticBounds = createBounds(r)
      this._geodeticToGeocentric = new GeodeticGeocentricTransformation(r, t, o)
    }
  }
  _forward(e, o) {
    const t = this._trans._forward(e, o)
    if (
      this._geocentric &&
      null !== this._geodeticToGeocentric &&
      null !== this._geodeticPoint
    ) {
      this._geodeticToGeocentric.inverseTransformation.transform(
        t,
        this._geodeticPoint
      )
      this._geodeticPoint.move3DToCoordinates(
        this._geodeticPoint.x,
        this._geodeticPoint.y,
        e.z
      )
      this._geodeticToGeocentric.transform(this._geodeticPoint, t)
    } else t.z = e.z
    return t
  }
  _forwardBoundsCoords(e, o) {
    this._trans._forwardBoundsCoords(e, o)
    o.z = e.z
    o.height = e.height
  }
  _inverse(e, o) {
    const t = this._trans._inverse(e, o)
    t.z = e.z
    return t
  }
  _inverseBoundsCoords(e, o) {
    this._trans._inverseBoundsCoords(e, o)
    o.z = e.z
    o.height = e.height
  }
}
