import { ProgrammingError } from '../error/ProgrammingError.js'
import { addHttpRequestOptions } from './HttpRequestOptions.js'
import { URL } from './URL.js'
import { isBoolean, isNumber, isString } from './Lang.js'
function validateRequestHeaders(e) {
  if (null === e) return
  if ('object' === typeof e) {
    for (const t in e)
      if (e.hasOwnProperty(t)) {
        const r = e[t]
        if (!isString(r) && !isNumber(r) && !isBoolean(r))
          throw new ProgrammingError(
            `requestHeaders["${t}"] must be a string, number or boolean but got: ${r}`
          )
      }
  } else
    throw new ProgrammingError(
      'requestHeaders must be null, or an object literal with string property values, but got: ' +
        e
    )
}
export class WithHttpRequestOptions {
  constructor(e) {
    if (isBoolean((e = e || {}).credentials)) this._credentials = e.credentials
    else this._credentials = false
    this._requestHeaders = null
    if (e.requestHeaders && 'object' === typeof e.requestHeaders) {
      validateRequestHeaders(e.requestHeaders)
      this.requestHeaders = e.requestHeaders
    }
    this._requestParameters = null
    if (e.requestParameters && 'object' === typeof e.requestParameters)
      this.requestParameters = e.requestParameters
  }
  get requestHeaders() {
    return this._requestHeaders
  }
  set requestHeaders(e) {
    validateRequestHeaders(e)
    this._requestHeaders = {}
    if (e && 'object' === typeof e)
      for (const t in e) {
        const r = e[t]
        if ('object' !== typeof r) this._requestHeaders[t] = String(r)
      }
    else this._requestHeaders = null
  }
  get requestParameters() {
    return this._requestParameters
  }
  set requestParameters(e) {
    if (e && 'object' === typeof e) {
      this._requestParameters = {}
      for (const t in e) {
        const r = e[t]
        if ('object' !== typeof r) this._requestParameters[t] = r
      }
    } else this._requestParameters = null
  }
  get credentials() {
    return this._credentials
  }
  set credentials(e) {
    this._credentials = e
  }
  addHttpRequestOptions(e) {
    return addHttpRequestOptions(e, this)
  }
  getUrlWithRequestParameters(e) {
    if (this._requestParameters && Object.keys(this._requestParameters).length)
      return `${e}${e.includes('?') ? '&' : '?'}${URL.buildQueryString(
        this._requestParameters
      )}`
    return e
  }
}
