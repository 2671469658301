import { ProgrammingError } from '../../error/ProgrammingError.js'
import { TopologyUtilCartesian } from '../../geometry/topologyutil/TopologyUtilCartesian.js'
function EdgeEnd(e, t, r) {
  this._segment = e
  this._dir = t
  this._calculateAngle(r)
}
EdgeEnd.prototype = Object.create(Object.prototype)
EdgeEnd.prototype.constructor = EdgeEnd
Object.defineProperty(EdgeEnd.prototype, 'segment', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._segment
  },
  set: function (e) {
    this._segment = e
  },
})
Object.defineProperty(EdgeEnd.prototype, 'dir', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._dir
  },
  set: function () {
    throw new ProgrammingError('dir property is not mutable')
  },
})
Object.defineProperty(EdgeEnd.prototype, 'angle', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._angle
  },
  set: function () {
    throw new ProgrammingError('angle property is not mutable')
  },
})
EdgeEnd.prototype._calculateAngle = function (e) {
  if (null === this._next)
    throw new ProgrammingError(
      "segment in starbundle doesn't have actual line segment!"
    )
  var t, r
  if (+1 == this._dir) {
    var n = this._segment.pos
    var o = this._segment.next
    while (
      null != o.next &&
      TopologyUtilCartesian.pointsEqualWithoutTolerance(n, o.pos)
    )
      o = o.next
    t = n
    r = o.pos
  } else {
    var n = this._segment.next.pos
    var i = this._segment
    while (
      null != i.previous &&
      TopologyUtilCartesian.pointsEqualWithoutTolerance(n, i.pos)
    )
      i = i.previous
    t = n
    r = i.pos
  }
  this._angle = e.getAngle(t, r)
}
export { EdgeEnd }
