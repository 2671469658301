import { GeneralPath } from './GeneralPath.js'
export function ensurePathStartsAtBoundary(e) {
  const o = e.subPathCount()
  if (1 == o) return e
  const t = new GeneralPath()
  const n = undefined
  const a = o + 1
  let s, r, P, l, u, i, f
  for (s = 1; s < a; s++) {
    P = s % o
    l = e.subPathLength(P)
    for (r = 0; r < l; r++) {
      u = e.getX(P, r)
      i = e.getY(P, r)
      f = e.getZ(P, r)
      if (0 == r && 0 != P) t.moveTo(u, i, f)
      else t.lineTo(u, i, f)
    }
    if (P != o - 1) {
      if (e.subPathBreaksLine(P)) t.breakLine()
      if (e.subPathBreaksPolygon(P)) t.breakPolygon()
      if (e.subPathClosesPolygon(P)) t.closePolygon()
    }
  }
  return t
}
export function addToSFCT(e, o) {
  let t, n
  for (t = 0, n = e.subPathCount(); t < n; t++) addSubPathToSFCT(e, t, o)
}
export function addSubPathToSFCT(e, o, t) {
  const n = e.subPathLength(o)
  let a, s, r, P
  for (a = 0; a < n; a++) {
    s = e.getX(o, a)
    r = e.getY(o, a)
    P = e.getZ(o, a)
    if (0 == a) t.moveTo(s, r, P)
    else t.lineTo(s, r, P)
  }
  if (e.subPathBreaksLine(o)) t.breakLine()
  if (e.subPathBreaksPolygon(o)) t.breakPolygon()
  if (e.subPathClosesPolygon(o)) t.closePolygon()
}
