export let GestureEventType = (function (E) {
  E[(E['DOUBLE_CLICK'] = 1)] = 'DOUBLE_CLICK'
  E[(E['DOUBLE_CLICK_EVENT'] = 2)] = 'DOUBLE_CLICK_EVENT'
  E[(E['DOWN'] = 3)] = 'DOWN'
  E[(E['SCROLL'] = 4)] = 'SCROLL'
  E[(E['LONG_PRESS'] = 5)] = 'LONG_PRESS'
  E[(E['DRAG'] = 6)] = 'DRAG'
  E[(E['DRAG_END'] = 7)] = 'DRAG_END'
  E[(E['SHOW_PRESS'] = 8)] = 'SHOW_PRESS'
  E[(E['SINGLE_CLICK_CONFIRMED'] = 9)] = 'SINGLE_CLICK_CONFIRMED'
  E[(E['SINGLE_CLICK_UP'] = 10)] = 'SINGLE_CLICK_UP'
  E[(E['PINCH'] = 11)] = 'PINCH'
  E[(E['MOVE'] = 12)] = 'MOVE'
  E[(E['UP'] = 13)] = 'UP'
  E[(E['CONTEXT_MENU'] = 14)] = 'CONTEXT_MENU'
  E[(E['TWO_FINGER_DRAG'] = 15)] = 'TWO_FINGER_DRAG'
  E[(E['TWO_FINGER_DRAG_END'] = 16)] = 'TWO_FINGER_DRAG_END'
  E[(E['ROTATE'] = 17)] = 'ROTATE'
  E[(E['ROTATE_END'] = 18)] = 'ROTATE_END'
  E[(E['PINCH_END'] = 19)] = 'PINCH_END'
  return E
})({})
