import { PaintRepresentation } from '../PaintRepresentation.js'
import { VisibilityType } from '../style/VisibilityType.js'
import { createCartesianGeodesy } from '../../geodesy/GeodesyFactory.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { LocationMode } from '../../transformation/LocationMode.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
const FOREGROUND_THRESHOLD = 7e-5
const geodesy = createCartesianGeodesy(getReference('EPSG:4978'))
const viewPoint = createPoint(null, [0, 0, 0])
export class LabelDrawingContext {
  _xScale = null
  _mapBounds = null
  _surfaceTx = null
  constructor(e, t) {
    let i =
      arguments.length > 2 && void 0 !== arguments[2]
        ? arguments[2]
        : PaintRepresentation.LABEL
    this._labelManager = t
    this._map = e
    this._labelPaintRepresentation = i
  }
  reset() {
    this._xScale = this._map.xScale
    try {
      this._mapBounds = this._map.mapBounds
    } catch (e) {
      OutOfBoundsError.isOrThrow(e)
      this._mapBounds = null
    }
    if (!this._surfaceTx && this._map.is3D()) {
      const e = { includeControllerOnDraw: true }
      this._surfaceTx = this._map.getViewToMapTransformation(
        LocationMode.CLOSEST_SURFACE,
        e
      )
    }
  }
  getTopOffset() {
    return this._labelPaintRepresentation ===
      PaintRepresentation.BOTTOM_BORDER_LABEL
      ? this._map.getViewHeight()
      : 0
  }
  isViewPointInView(e) {
    return this._map.isViewPointInView(e)
  }
  mapScale() {
    return this._xScale || 0
  }
  getMapBounds() {
    return this._mapBounds
  }
  getVisibilityType(e) {
    if (this._xScale && this._map.is3D()) {
      const t = undefined
      if (this._map.getScaleAt(e) >= FOREGROUND_THRESHOLD)
        return VisibilityType.FOREGROUND
    }
    return VisibilityType.TERRAIN
  }
  distanceToSurfaceVisibility(e) {
    let t =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0.75
    if (!this._map.is3D() || !this._surfaceTx) return true
    const { eyePoint: i } = this._map.camera
    const r = geodesy.distance3D(i, e)
    if (r < 5) return true
    try {
      this._map.mapToViewTransformation.transform(e, viewPoint)
      const n = this._surfaceTx.transform(viewPoint)
      const a = undefined
      return geodesy.distance3D(i, n) / r >= t
    } catch (e) {
      return true
    }
  }
  get mapToViewTransformation() {
    return this._map.getMapToBorderViewTransformation(
      this._labelPaintRepresentation
    )
  }
  get worldReference() {
    return this._map.reference
  }
  get labelPaintRepresentation() {
    return this._labelPaintRepresentation
  }
  get worldSizeSupport() {
    return this._map.worldSizeSupport
  }
  get labelManager() {
    return this._labelManager
  }
}
export function createLabelContext(e, t, i) {
  return new LabelDrawingContext(e, t, i)
}
