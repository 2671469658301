import { ParseError } from '../../../error/ParseError.js'
import { WKTValue } from './WKTValue.js'
import { WKTScalar } from './WKTScalar.js'
import { TokenType, WKTTokenizer } from './WKTTokenizer.js'
const TAG_DUMMY = 'DUMMY'
export function parseWKTObject(e, t) {
  const r = new WKTTokenizer(e, t)
  let n = r.nextToken()
  if (n != TokenType.START_OBJECT)
    throw new ParseError(`Invalid WKT string (position: ${t.index})`)
  const s = []
  while (n == TokenType.START_OBJECT) {
    let e = new WKTObject(r.getValue(), r.getStart())
    let o
    const a = []
    while (null != e) {
      n = r.nextToken()
      switch (n) {
        case TokenType.START_OBJECT:
          a.push(e)
          e = new WKTObject(r.getValue(), r.getStart())
          break
        case TokenType.END_OBJECT:
          o = e
          if (0 != a.length) {
            e = a.pop()
            e.parameters.push(o)
          } else {
            s.push(e)
            e = null
          }
          break
        case TokenType.SCALAR:
          e.parameters.push(new WKTScalar(r.getValue(), r.getStart()))
          break
        case TokenType.COMMA:
          break
        case TokenType.EOF:
        default:
          throw new ParseError(`Invalid WKT string (position: ${t.index})`)
      }
    }
    const i = t.index
    n = r.nextToken()
    if (n == TokenType.COMMA) n = r.nextToken()
    else {
      t.index = i
      break
    }
  }
  if (1 == s.length) return s.pop()
  else {
    const e = new WKTObject(TAG_DUMMY, 0)
    const t = s.length
    for (let r = 0; r < t; r++) e.parameters.push(s[r])
    return e
  }
}
export class WKTObject extends WKTValue {
  constructor(e, t) {
    super(t)
    this._tag = e
    this._parameters = []
  }
  getTag() {
    return this._tag
  }
  hasTag(e) {
    return this._tag.toUpperCase() === e.toUpperCase()
  }
  get parameters() {
    return this._parameters
  }
  getParameterCount() {
    return this._parameters.length
  }
  getObject(e) {
    if ('number' === typeof e) {
      const t = this.optObject(e)
      if (null == t)
        throw new ParseError(
          `Error in object ${this.getTag()}: parameter ${e} is not an object value (position: ${this.getPosition()})`
        )
      else return t
    } else {
      const t = e
      const r = this.optObject(t)
      if (null == r)
        throw new ParseError(
          `Error in object ${this.getTag()}: parameter ${t} does not exist (position: ${this.getPosition()})`
        )
      else return r
    }
  }
  optObject(e) {
    if ('number' === typeof e) {
      if (e >= this._parameters.length) return null
      const t = this._parameters[e]
      if (!(t instanceof WKTObject)) return null
      return t
    } else {
      const t = e
      const r = this._parameters.length
      for (let e = 0; e < r; e++) {
        const r = this._parameters[e]
        if (r instanceof WKTObject && r.hasTag(t)) return r
      }
      return null
    }
  }
  getObjectsWithTag(e) {
    const t = this._parameters.length
    const r = []
    for (let n = 0; n < t; n++) {
      const t = this._parameters[n]
      if (t instanceof WKTObject && t.hasTag(e)) r.push(t)
    }
    return r
  }
  getDouble(e) {
    const t = this.optDouble(e)
    if (null == t)
      throw new ParseError(
        `Error in object ${this.getTag()}: parameter ${e} is not a numeric value (position: ${this.getPosition()})`
      )
    else return t
  }
  optDouble(e, t) {
    const r = this.optScalar(e)
    if (null == r) return t || null
    try {
      return parseFloat(r.getValue())
    } catch (e) {
      return null
    }
  }
  getInt(e) {
    const t = this.optInt(e)
    if (null == t)
      throw new ParseError(
        `Error in object ${this.getTag()}: parameter ${e} is not a numeric value (position: ${this.getPosition()})`
      )
    else return t
  }
  optInt(e, t) {
    const r = this.optScalar(e)
    if (null == r) return t || null
    try {
      return parseInt(r.getValue())
    } catch (e) {
      return null
    }
  }
  getString(e) {
    return this.getScalar(e).getValue()
  }
  optString(e) {
    const t = this.optScalar(e)
    if (null == t) return null
    return t.getValue()
  }
  getScalar(e) {
    const t = this.optScalar(e)
    if (null == t)
      throw new ParseError(
        `Error in object ${this.getTag()}: parameter ${e} is not a scalar value (position: ${this.getPosition()})`
      )
    else return t
  }
  optScalar(e) {
    if (e >= this._parameters.length) return null
    const t = this._parameters[e]
    if (!(t instanceof WKTScalar)) return null
    return t
  }
  indexOf(e) {
    const t = this._parameters.length
    for (let r = 0; r < t; r++) {
      const t = this._parameters[r]
      if (t instanceof WKTObject && t.hasTag(e)) return r
    }
    return -1
  }
}
