import { ProgrammingError } from '../../error/ProgrammingError.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import { JSZip } from '../../util/jszip/jszip.js'
import { isString, isUndefined } from '../../util/Lang.js'
import { Codec } from '../codec/Codec.js'
import { KMLCursorBuilder } from './KMLCursor.js'
const ZIP_HEADER = 1347093252
export class KMLCodec extends Codec {
  constructor(e) {
    super()
    this._origin = e?.origin ?? ''
    this._evented = new EventedSupport()
    this._withStyle = e && !isUndefined(e.withStyle) ? e.withStyle : true
  }
  decode(e) {
    const { content: t } = e
    if (isString(t))
      return new KMLCursorBuilder(t, this._withStyle, this._origin).build(
        this._evented
      )
    if (t instanceof Blob)
      return new Response(t)
        .arrayBuffer()
        .then((t) => this.decode({ ...e, content: t }))
    if (new DataView(t).getUint32(0) === ZIP_HEADER)
      return unzip(t).then((t) => this.decode({ ...e, content: t }))
    const r = new TextDecoder().decode(t)
    return this.decode({ ...e, content: r })
  }
  encode(e) {
    throw new ProgrammingError('KML Encoding is not currently supported.')
  }
  on(e, t, r) {
    return this._evented.on(e, t, r)
  }
}
function unzip(e) {
  return JSZip.loadAsync(e).then(function (e) {
    const t = undefined
    return e.file(/^[^/]+\.kml$/i)[0].async('text')
  })
}
