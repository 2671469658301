import { ProgrammingError } from '../../error/ProgrammingError.js'
import {
  isArray,
  isBoolean,
  isDefined,
  isFunction,
  isNumber,
  isObject,
  isString,
} from '../../util/Lang.js'
import { XPath } from '../se/model/XPath.js'
import { FilterElementType } from './FilterElementType.js'
import { FilterToFunction } from './FilterToFunction.js'
import { MatchAction } from './MatchAction.js'
const every = Array.prototype.every
export class Expression {
  constructor() {}
  accept(e) {
    if (isFunction(e.visitExpression)) e.visitExpression(this)
  }
  TYPE = 'NO-TYPE'
}
export class OGCCondition extends Expression {
  accept(e) {
    if (isFunction(e.visitOGCCondition)) e.visitOGCCondition(this)
    else super.accept(e)
  }
}
export class Identifiers {
  constructor(e, r) {
    this.gmlObjectIds = isArray(e) ? [...e] : []
    this.featureIds = isArray(r) ? [...r] : []
  }
  accept(e) {
    if (isFunction(e.visitIdentifiers)) e.visitIdentifiers(this)
  }
  TYPE = FilterElementType.Id
}
export class BinaryLogicOperator extends OGCCondition {
  constructor(e) {
    super()
    this.conditions = e
  }
  accept(e) {
    isFunction(e.visitBinaryLogicOperator)
      ? e.visitBinaryLogicOperator(this)
      : super.accept(e)
  }
}
class AndOperator extends BinaryLogicOperator {
  constructor(e) {
    super(e)
  }
  accept(e) {
    isFunction(e.visitAndOperator) ? e.visitAndOperator(this) : super.accept(e)
  }
  TYPE = FilterElementType.Logic.Binary.And
}
export class OrOperator extends BinaryLogicOperator {
  constructor(e) {
    super(e)
  }
  accept(e) {
    isFunction(e.visitOrOperator) ? e.visitOrOperator(this) : super.accept(e)
  }
  TYPE = FilterElementType.Logic.Binary.Or
}
export class NotOperator extends OGCCondition {
  constructor(e) {
    super()
    this.condition = e
  }
  accept(e) {
    isFunction(e.visitNotOperator) ? e.visitNotOperator(this) : super.accept(e)
  }
  TYPE = FilterElementType.Logic.Unary.Not
}
export class NullOperator extends OGCCondition {
  constructor(e) {
    super()
    this.expression = e
  }
  accept(e) {
    isFunction(e.visitNullOperator)
      ? e.visitNullOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Null
}
export class ExistsOperator extends OGCCondition {
  constructor(e) {
    super()
    this.expression = e
  }
  accept(e) {
    isFunction(e.visitExistsOperator)
      ? e.visitExistsOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Exists
}
export class IsLikeOperator extends OGCCondition {
  constructor(e, r, t, i, n, o) {
    super()
    this.property = e
    this.literal = r
    this.wildCard = t || '*'
    this.singleChar = i || '.'
    this.escapeChar = n || '!'
    this.matchCase = false !== o
  }
  accept(e) {
    isFunction(e.visitIsLikeOperator)
      ? e.visitIsLikeOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.IsLike
}
export class BetweenOperator extends OGCCondition {
  constructor(e, r, t) {
    super()
    this.expression = e
    this.lower = r
    this.upper = t
  }
  accept(e) {
    isFunction(e.visitBetweenOperator)
      ? e.visitBetweenOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Between
}
export class BinaryComparisonOperator extends OGCCondition {
  constructor(e, r, t, i) {
    super()
    this.args = [e, r]
    this.matchCase = t
    this.matchAction = i
  }
  accept(e) {
    isFunction(e.visitBinaryComparisonOperator)
      ? e.visitBinaryComparisonOperator(this)
      : super.accept(e)
  }
}
class EqualOperator extends BinaryComparisonOperator {
  constructor(e, r, t, i) {
    super(e, r, t, i)
  }
  accept(e) {
    isFunction(e.visitEqualOperator)
      ? e.visitEqualOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Binary.EqualTo
}
class NotEqualOperator extends BinaryComparisonOperator {
  constructor(e, r, t, i) {
    super(e, r, t, i)
  }
  accept(e) {
    isFunction(e.visitNotEqualOperator)
      ? e.visitNotEqualOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Binary.NotEqualTo
}
class LessOperator extends BinaryComparisonOperator {
  constructor(e, r, t, i) {
    super(e, r, t, i)
  }
  accept(e) {
    isFunction(e.visitLessOperator)
      ? e.visitLessOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Binary.LessThan
}
class LessOrEqualOperator extends BinaryComparisonOperator {
  constructor(e, r, t, i) {
    super(e, r, t, i)
  }
  accept(e) {
    isFunction(e.visitLessOrEqualOperator)
      ? e.visitLessOrEqualOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Binary.LessThanOrEqualTo
}
class GreaterOperator extends BinaryComparisonOperator {
  constructor(e, r, t, i) {
    super(e, r, t, i)
  }
  accept(e) {
    isFunction(e.visitGreaterOperator)
      ? e.visitGreaterOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Binary.GreaterThan
}
class GreaterOrEqualOperator extends BinaryComparisonOperator {
  constructor(e, r, t, i) {
    super(e, r, t, i)
  }
  accept(e) {
    isFunction(e.visitGreaterOrEqualOperator)
      ? e.visitGreaterOrEqualOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Comparison.Binary.GreaterThanOrEqualTo
}
export class BboxOperator extends OGCCondition {
  constructor(e, r, t, i, n, o) {
    super()
    this.minX = e
    this.minY = t
    this.maxX = r
    this.maxY = i
    this.srsName = n || null
    this.geometryName = o || null
    this.swapAxes = []
  }
  accept(e) {
    isFunction(e.visitBboxOperator)
      ? e.visitBboxOperator(this)
      : super.accept(e)
  }
  TYPE = FilterElementType.Spatial.Bbox
}
export class OGCExpression extends Expression {
  constructor() {
    super()
  }
  accept(e) {
    isFunction(e.visitOGCExpression) && e.visitOGCExpression(this)
  }
}
class BinaryOperator extends OGCExpression {
  constructor(e, r) {
    super()
    this.args = [e, r]
  }
  accept(e) {
    isFunction(e.visitBinaryOperator)
      ? e.visitBinaryOperator(this)
      : super.accept(e)
  }
}
export class AddOperator extends BinaryOperator {
  constructor(e, r) {
    super(e, r)
  }
  accept(e) {
    isFunction(e.visitAddOperator) ? e.visitAddOperator(this) : super.accept(e)
  }
  TYPE = FilterElementType.Expression.Arithmetic.Add
}
export class SubOperator extends BinaryOperator {
  constructor(e, r) {
    super(e, r)
  }
  accept(e) {
    isFunction(e.visitSubOperator) ? e.visitSubOperator(this) : super.accept(e)
  }
  TYPE = FilterElementType.Expression.Arithmetic.Sub
}
export class MulOperator extends BinaryOperator {
  constructor(e, r) {
    super(e, r)
  }
  accept(e) {
    isFunction(e.visitMulOperator) ? e.visitMulOperator(this) : super.accept(e)
  }
  TYPE = FilterElementType.Expression.Arithmetic.Mul
}
export class DivOperator extends BinaryOperator {
  constructor(e, r) {
    super(e, r)
  }
  accept(e) {
    isFunction(e.visitDivOperator) ? e.visitDivOperator(this) : super.accept(e)
  }
  TYPE = FilterElementType.Expression.Arithmetic.Div
}
export class OGCFunction extends OGCExpression {
  constructor(e, r) {
    super()
    this._name = isDefined(e) ? e : null
    this._arguments = isDefined(r) && isArray(r) ? [...r] : []
  }
  accept(e) {
    isFunction(e.visitOGCFunction) ? e.visitOGCFunction(this) : super.accept(e)
  }
  get args() {
    return this._arguments
  }
  get name() {
    return this._name
  }
  TYPE = FilterElementType.Expression.Function
}
export class PropertyName extends OGCExpression {
  constructor(e, r) {
    super()
    this.namePath = new XPath(e)
    this.nameSpaces = r
  }
  accept(e) {
    isFunction(e.visitPropertyName) && e.visitPropertyName(this)
  }
  isId() {
    const e = this.namePath.qNames
    return 1 === e.length && 'id' === e[0].toFullyQualifiedString()
  }
  isGeom() {
    const e = this.namePath.qNames
    return (
      1 === e.length &&
      ('the_geom' === e[0].toFullyQualifiedString() ||
        'GEOMETRY' === e[0].toFullyQualifiedString())
    )
  }
  get name() {
    if (this.namePath.qNames.length > 0)
      return this.namePath.qNames[0].localPart
  }
  TYPE = FilterElementType.Expression.PropertyName
}
export class Literal extends OGCExpression {
  constructor(e) {
    super()
    this.value = e
  }
  accept(e) {
    if (isFunction(e.visitLiteral)) e.visitLiteral(this)
  }
  TYPE = FilterElementType.Expression.Literal
}
const isOGCCondition = (e) => e instanceof OGCCondition
const validExpressions = (e, r) => {
  if (!e.every(FilterElementType.isExpression))
    throw new Error(`Only OGC Expressions are allowed in in ${r} operator`)
}
function validateBinaryComparisonParameters(e, r, t, i) {
  if (!r || !t) throw new Error(`${e}() expects two arguments`)
  if (!FilterElementType.isExpression(r))
    throw new Error(
      `${e}():  firstExpression argument must be an OGC Expression`
    )
  if (!FilterElementType.isExpression(t))
    throw new Error(
      `${e}():  secondExpression argument must be an OGC Expression`
    )
  if (
    i &&
    i !== MatchAction.ANY &&
    i !== MatchAction.ALL &&
    i !== MatchAction.ONE
  )
    throw new Error(`${e}():  unrecognized matchAction argument`)
}
function normalizeOptionalBoolean(e) {
  return isDefined(e) ? !!e : null
}
export function identifiers(e, r) {
  if (e && !isArray(e))
    throw new Error('identifiers():  objectIds parameter must be an array')
  if (r && !isArray(r))
    throw new Error('identifiers():  featureIds parameter must be an array')
  return new Identifiers(e, r)
}
export function and(e, r) {
  for (
    var t = arguments.length, i = new Array(t > 2 ? t - 2 : 0), n = 2;
    n < t;
    n++
  )
    i[n - 2] = arguments[n]
  if (arguments.length < 2)
    throw new Error('and(): expected at least two arguments ')
  const o = undefined
  if (
    !every.call(arguments, (e) => isOGCCondition(e) || e instanceof OGCFunction)
  )
    throw new Error(
      'and(): Only conditions and Functions are allowed in AND expression'
    )
  return new AndOperator([e, r, ...i])
}
export function or(e, r) {
  for (
    var t = arguments.length, i = new Array(t > 2 ? t - 2 : 0), n = 2;
    n < t;
    n++
  )
    i[n - 2] = arguments[n]
  if (arguments.length < 2)
    throw new Error('or(): expected at least two arguments ')
  const o = undefined
  if (
    !every.call(arguments, (e) => isOGCCondition(e) || e instanceof OGCFunction)
  )
    throw new Error(
      'or(): Only conditions and Functions are allowed in OR expression'
    )
  return new OrOperator([e, r, ...i])
}
export function add(e, r) {
  validExpressions([e, r], 'And')
  return new AddOperator(e, r)
}
export function sub(e, r) {
  validExpressions([e, r], 'Sub')
  return new SubOperator(e, r)
}
export function mul(e, r) {
  validExpressions([e, r], 'Mul')
  return new MulOperator(e, r)
}
export function div(e, r) {
  validExpressions([e, r], 'Div')
  return new DivOperator(e, r)
}
export function like(e, r) {
  let t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : '*'
  let i = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : '.'
  let n = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : '!'
  let o = arguments.length > 5 ? arguments[5] : void 0
  if (!e || !r)
    throw new Error('like():  expected at least a propertyName and a pattern')
  if (e.TYPE !== FilterElementType.Expression.PropertyName)
    throw new Error('like(): propertyName is not valid')
  if (r.TYPE !== FilterElementType.Expression.Literal)
    throw new Error('like(): pattern literal is not valid')
  if (t && !isString(t))
    throw new Error('like(): wildCard parameter should be string')
  if (i && !isString(i))
    throw new Error('like(): singleChar parameter should be string')
  if (n && !isString(n))
    throw new Error('like(): escapeChar parameter should be string')
  return new IsLikeOperator(e, r, t, i, n, normalizeOptionalBoolean(o))
}
export function not(e) {
  if (!e) throw new Error('not(): no expression was passed')
  if (!isOGCCondition(e) && !(e instanceof OGCFunction))
    throw new Error(
      'not(): Only conditions and Functions are allowed in NOT operators'
    )
  return new NotOperator(e)
}
export function isNull(e) {
  let r
  if (!e) throw new Error('isNull(): no propertyName parameter.')
  if (isString(e))
    try {
      r = property(e)
    } catch (e) {
      const r = e instanceof Error ? e.message : '' + e
      throw new Error(`isNull(): ${r}`)
    }
  else r = e
  if (r.TYPE !== FilterElementType.Expression.PropertyName)
    throw new Error('isNull(): invalid propertyName parameter')
  return new NullOperator(r)
}
export function exists(e) {
  let r
  if (!e) throw new Error('exists(): no propertyName parameter.')
  if (isString(e))
    try {
      r = property(e)
    } catch (e) {
      const r = e instanceof Error ? e.message : '' + e
      throw new Error(`exists(): ${r}`)
    }
  else r = e
  if (r.TYPE !== FilterElementType.Expression.PropertyName)
    throw new Error('exists(): invalid propertyName parameter')
  return new ExistsOperator(r)
}
export function between(e, r, t) {
  if (!e || !r || !t) throw new Error('between(): expected 3 parameters')
  if (!FilterElementType.isExpression(e))
    throw new Error('between(): expression parameter is not an OGCExpression')
  if (!FilterElementType.isExpression(r))
    throw new Error('between(): lowerBounds parameter is not an OGCExpression')
  if (!FilterElementType.isExpression(t))
    throw new Error('between(): upperBounds parameter is not an OGCExpression')
  return new BetweenOperator(e, r, t)
}
export function eq(e, r, t, i) {
  validateBinaryComparisonParameters('eq', e, r, i)
  return new EqualOperator(e, r, normalizeOptionalBoolean(t), i)
}
export function neq(e, r, t, i) {
  validateBinaryComparisonParameters('neq', e, r, i)
  return new NotEqualOperator(e, r, normalizeOptionalBoolean(t), i)
}
export function gt(e, r, t, i) {
  validateBinaryComparisonParameters('gt', e, r, i)
  return new GreaterOperator(e, r, normalizeOptionalBoolean(t), i)
}
export function lt(e, r, t, i) {
  validateBinaryComparisonParameters('lt', e, r, i)
  return new LessOperator(e, r, normalizeOptionalBoolean(t), i)
}
export function gte(e, r, t, i) {
  validateBinaryComparisonParameters('gte', e, r, i)
  return new GreaterOrEqualOperator(e, r, normalizeOptionalBoolean(t), i)
}
export function lte(e, r, t, i) {
  validateBinaryComparisonParameters('lte', e, r, i)
  return new LessOrEqualOperator(e, r, normalizeOptionalBoolean(t), i)
}
function validateGeometryName(e) {
  if (e) {
    if (isString(e)) return property(e)
    else if (e.TYPE === FilterElementType.Expression.PropertyName) return e
    throw new Error('bbox(): geometryName must be an OGC PropertyName')
  }
}
export function bbox() {
  return arguments.length <= 2
    ? bbox1(arguments[0], arguments[1])
    : bbox2(
        arguments[0],
        arguments[1],
        arguments[2],
        arguments[3],
        arguments[4],
        arguments[5]
      )
}
function bbox1(e, r) {
  let t = e.x
  const i = e.y
  let n = e.x + e.width
  const o = e.y + e.height
  const s = e.reference ? e.reference.identifier : 'CRS:84'
  if (e.isGeodetic) {
    if (e.width >= 360) {
      t = -180
      n = 180
    }
    if (n > 180) n = -360 + n
  }
  return new BboxOperator(t, n, i, o, s, validateGeometryName(r))
}
function bbox2(e, r, t, i, n, o) {
  n = isString(n) ? n : 'CRS:84'
  return new BboxOperator(e, t, r, i, n, validateGeometryName(o))
}
export function func(e) {
  if (!isString(e))
    throw new Error(
      `The name of the custom OGC function must be a string: ${e}`
    )
  for (
    var r = arguments.length, t = new Array(r > 1 ? r - 1 : 0), i = 1;
    i < r;
    i++
  )
    t[i - 1] = arguments[i]
  if (isDefined(t)) validExpressions(t, 'OGC function')
  return new OGCFunction(e, isDefined(t) ? t : [])
}
export function property(e, r) {
  if (!isString(e))
    throw new Error(`The property name (or path) must be a string: ${e}`)
  if (isDefined(r)) {
    if (!isObject(r))
      throw new Error(`The namespacesMap must be a object: ${r}`)
    for (const e in r)
      if (r.hasOwnProperty(e) && !isString(r[e]))
        throw new Error(`A namespace must be a string: ${r[e]}`)
  } else r = {}
  return new PropertyName(e, r)
}
export function literal(e) {
  if (!isString(e) && !isNumber(e) && !isBoolean(e))
    throw new Error(
      `The literal value should either be a string, or a number, or a boolean: ${e}`
    )
  return new Literal(e)
}
export function toFeaturePredicate(e) {
  if (!(e instanceof OGCCondition || e instanceof Identifiers))
    throw new ProgrammingError(
      'Argument should be a OGCCondition or a Identifiers.'
    )
  try {
    return FilterToFunction.createFeatureFilterPredicate(e)
  } catch (e) {
    throw new ProgrammingError(`Cannot convert expression to function${e}`)
  }
}
