import { isArray, isNumber, isObject, isUndefined } from '../util/Lang.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { getUnitOfMeasure } from '../uom/UnitOfMeasureRegistry.js'
import { Axis } from '../reference/Axis.js'
import { Log } from '../util/Log.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
export class MapNavigatorConstraintsImpl {
  constructor(e, t, i, n, s) {
    this._above = new AboveCameraConstraint(s, true, false, 20, n)
    this._limitBounds = new LimitBoundsConstraint(
      null,
      new PaddingConstraint(0, 0, 0, 0),
      t,
      i
    )
    this._scale = new ScaleConstraint([0, 0], [1 / 0, 1 / 0], t, e, i)
  }
  setToLiteral(e) {
    if (null === e) {
      this._above.removeConstraints()
      this._limitBounds.removeConstraints()
      this._scale.removeConstraints()
    }
    if (isUndefined(e) || null === e) return
    if (!isUndefined(e.above)) this.above = e.above
    if (!isUndefined(e.limitBounds)) this.limitBounds = e.limitBounds
    if (!isUndefined(e.scale)) this.scale = e.scale
  }
  get above() {
    return this._above
  }
  set above(e) {
    if (isObject(e)) this._above.setToLiteral(e)
    else
      throw new ProgrammingError(
        `Given above property should be object literal. Instead it was: ${e}.`
      )
  }
  get limitBounds() {
    return this._limitBounds
  }
  set limitBounds(e) {
    if (isObject(e)) this._limitBounds.setToLiteral(e)
    else
      throw new ProgrammingError(
        `Given limitBounds should be an object literal. Instead it was: ${e}.`
      )
  }
  get scale() {
    return this._scale
  }
  set scale(e) {
    if (isObject(e)) this._scale.setToLiteral(e)
    else
      throw new ProgrammingError(
        `scale property should be an object literal. Instead it was: ${e}.`
      )
  }
}
class AboveCameraConstraint {
  constructor(e, t, i, n, s) {
    this._enabled = e
    this._terrain = t
    this._mesh = i
    this._minAltitude = n
    this._photonViewPromise = s
    this.updateCameraTarget(true)
  }
  updateCameraTarget(e) {
    if (this._photonViewPromise)
      this._photonViewPromise.then((t) => {
        if (t && t.is3D()) {
          t.setAboveGroundConstraint(
            this._enabled && (this._terrain || this._mesh)
          )
          t.setAboveGroundConstraintOptions(
            this._terrain,
            this._mesh,
            this._minAltitude
          )
        } else if (!e)
          Log.warn(
            'The above camera constraint is only applicable to 3D WebGLMaps. No above camera constraint will be applied.'
          )
      })
    else if (!e)
      Log.warn(
        'The above camera constraint is only applicable to 3D WebGLMaps. No above camera constraint will be applied.'
      )
  }
  get enabled() {
    return this._enabled
  }
  set enabled(e) {
    this._enabled = e
    this.updateCameraTarget()
  }
  get terrain() {
    return this._enabled ? this._terrain : false
  }
  set terrain(e) {
    this._terrain = e
    this.updateCameraTarget()
  }
  get mesh() {
    return this._enabled ? this._mesh : false
  }
  set mesh(e) {
    this._mesh = e
    this.updateCameraTarget()
  }
  get minAltitude() {
    return this._enabled ? this._minAltitude : 0
  }
  set minAltitude(e) {
    if (isNumber(e)) {
      this._minAltitude = e
      this.updateCameraTarget()
    } else
      throw new ProgrammingError(
        `minAltitude should be a number, but instead was: ${e}.`
      )
  }
  setToLiteral(e) {
    if (null === e) {
      this.removeConstraints()
      return
    }
    if (!isUndefined(e.terrain)) this.terrain = e.terrain
    if (!isUndefined(e.mesh)) this.mesh = e.mesh
    if (!isUndefined(e.minAltitude)) {
      if (e.minAltitude < 0)
        throw new ProgrammingError(
          `minAltitude should be larger or equal to 0. Instead it was: ${e.minAltitude}.`
        )
      this.minAltitude = e.minAltitude
    }
    this.enabled = this._terrain || this._mesh
  }
  removeConstraints() {
    this.enabled = false
  }
}
class PaddingConstraint {
  constructor(e, t, i, n) {
    this._left = e
    this._right = t
    this._top = i
    this._bottom = n
  }
  setToLiteral(e) {
    if (null === e) {
      this.removeConstraints()
      return
    }
    if (!isUndefined(e.left)) this.left = e.left
    if (!isUndefined(e.right)) this.right = e.right
    if (!isUndefined(e.top)) this.top = e.top
    if (!isUndefined(e.bottom)) this.bottom = e.bottom
  }
  removeConstraints() {
    this.left = 0
    this.right = 0
    this.top = 0
    this.bottom = 0
  }
  get left() {
    return this._left
  }
  set left(e) {
    if (isNumber(e)) this._left = e
    else
      throw new ProgrammingError(
        `padding constraint left should have been number, but was: ${e}`
      )
  }
  get right() {
    return this._right
  }
  set right(e) {
    if (isNumber(e)) this._right = e
    else
      throw new ProgrammingError(
        `padding constraint right should have been number, but was: ${e}`
      )
  }
  get top() {
    return this._top
  }
  set top(e) {
    if (isNumber(e)) this._top = e
    else
      throw new ProgrammingError(
        `padding constraint top should have been number, but was: ${e}`
      )
  }
  get bottom() {
    return this._bottom
  }
  set bottom(e) {
    if (isNumber(e)) this._bottom = e
    else
      throw new ProgrammingError(
        `padding constraint bottom should have been number, but was: ${e}`
      )
  }
}
class LimitBoundsConstraint {
  constructor(e, t, i, n) {
    this._bounds = e
    this._padding = t
    this._worldReference = i
    this._boundsChangedCallbackFunction = n
    this._updateWorldConstraint()
  }
  _updateWorldConstraint() {
    if (!this._bounds) this._boundsWorld = null
    else {
      const e = createTransformation(
        this._bounds.reference,
        this._worldReference
      )
      this._boundsWorld = e.transformBounds(this._bounds)
    }
  }
  get bounds() {
    return this._bounds
  }
  set bounds(e) {
    if (
      null === e ||
      (isObject(e) &&
        !isUndefined(e.x) &&
        !isUndefined(e.y) &&
        !isUndefined(e.width) &&
        !isUndefined(e.height))
    ) {
      this._bounds = e
      this._updateWorldConstraint()
      this._boundsChangedCallbackFunction()
    } else
      throw new ProgrammingError(
        `Bounds constraint must be Bounds object or null, but instead was: ${e}`
      )
  }
  get padding() {
    return this._padding
  }
  set padding(e) {
    if (isObject(e)) this._padding.setToLiteral(e)
    else
      throw new ProgrammingError(
        `Given padding should be an object literal. Instead it was: ${e}.`
      )
  }
  setToLiteral(e) {
    if (null === e) {
      this.removeConstraints()
      return
    }
    if (isUndefined(e)) return
    if ('undefined' === typeof e) return
    if ('undefined' !== typeof e.bounds) this.bounds = e.bounds
    if ('undefined' !== typeof e.padding) this.padding = e.padding
  }
  removeConstraints() {
    this.bounds = null
    this._padding.removeConstraints()
  }
}
const centimeterUOM = getUnitOfMeasure('Centimeter')
const centimeterBaseQK = centimeterUOM.quantityKind.baseQuantityKind
class ScaleConstraint {
  constructor(e, t, i, n, s) {
    this._minScale = e
    this._maxScale = t
    this._dotsPerCentimeter = n
    this.__setupScaleConversions(i)
    this._constraintsChangedCallbackFunction = s
  }
  setToLiteral(e) {
    if (null === e) {
      this.removeConstraints()
      return
    }
    if (!!isUndefined(e)) return
    if (!isUndefined(e.minScale)) this.minScale = e.minScale
    if (!isUndefined(e.maxScale)) this.maxScale = e.maxScale
  }
  removeConstraints() {
    this.minScale = [0, 0]
    this.maxScale = [1 / 0, 1 / 0]
  }
  __setupScaleConversions(e) {
    if (!e)
      throw new ProgrammingError(
        'Cannot setup the scale conversions without the reference'
      )
    const t = e.getAxis(Axis.Name.X)
    const i = e.getAxis(Axis.Name.Y)
    this._convertXComponentPixelToMap =
      createPixelScaleToMapScaleConversionFunction(
        t,
        e,
        this._dotsPerCentimeter
      )
    this._convertYComponentPixelToMap =
      createPixelScaleToMapScaleConversionFunction(
        i,
        e,
        this._dotsPerCentimeter
      )
    this._convertXComponentMapToPixel =
      createMapScaleToPixelScaleConversionFunction(
        t,
        e,
        this._dotsPerCentimeter
      )
    this._convertYComponentMapToPixel =
      createMapScaleToPixelScaleConversionFunction(
        i,
        e,
        this._dotsPerCentimeter
      )
  }
  _normalizeScaleValue(e) {
    if (isNumber(e)) {
      if (checkScale((e = this._convertXComponentMapToPixel(e)))) return [e, e]
    } else if (isArray(e) && 2 === e.length)
      if (e.every(checkScale))
        return [
          this._convertXComponentMapToPixel(e[0]),
          this._convertYComponentMapToPixel(e[1]),
        ]
    throw new ProgrammingError(
      'Invalid scale value. Must be positive number or array of 2 strictly positive numbers.'
    )
  }
  get minScale() {
    return [
      this._convertXComponentPixelToMap(this._minScale[0]),
      this._convertYComponentPixelToMap(this._minScale[1]),
    ]
  }
  set minScale(e) {
    if (null === e) this._minScale = [0, 0]
    else {
      const t = this._normalizeScaleValue(e)
      if (t[0] > this._maxScale[0] || t[1] > this._maxScale[1])
        throw new ProgrammingError('Min scale cannot be larger than max scale')
      this._minScale = t
      this._constraintsChangedCallbackFunction()
    }
  }
  get maxScale() {
    return [
      this._convertXComponentPixelToMap(this._maxScale[0]),
      this._convertYComponentPixelToMap(this._maxScale[1]),
    ]
  }
  set maxScale(e) {
    if (null === e) this._maxScale = [1 / 0, 1 / 0]
    else {
      const t = this._normalizeScaleValue(e)
      if (t[0] < this._minScale[0] || t[1] < this._minScale[1])
        throw new ProgrammingError('Max scale cannot be smaller than min scale')
      this._maxScale = t
      this._constraintsChangedCallbackFunction()
    }
  }
}
function checkScale(e) {
  return e >= 0
}
function createPixelScaleToMapScaleConversionFunction(e, t, i) {
  let n
  const s = e.unitOfMeasure
  if (s.quantityKind.baseQuantityKind === centimeterBaseQK) {
    n = s.convertToUnit(t.unitOfMeasure, centimeterUOM)
    return (e) => e / (i * n)
  } else return (e) => e / i
}
function createMapScaleToPixelScaleConversionFunction(e, t, i) {
  let n
  const s = e.unitOfMeasure
  if (s.quantityKind.baseQuantityKind === centimeterBaseQK) {
    n = s.convertToUnit(t.unitOfMeasure, centimeterUOM)
    return (e) => e * (i * n)
  } else return (e) => e * i
}
