import { ReferenceType } from '../../../reference/ReferenceType.js'
import { GeneralPath } from '../generalpath/GeneralPath.js'
import * as GeneralPathUtil from '../generalpath/GeneralPathUtil.js'
import { WorldBoundaryBuilder } from '../../../transformation/WorldBoundaryBuilder.js'
import { createTransformation } from '../../../transformation/TransformationFactory.js'
import { createPoint } from '../../../shape/ShapeFactory.js'
import { Shape } from '../../../shape/Shape.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { buildCache, Cacher } from '../../../util/Cacher.js'
import { Lang } from '../../../util/Lang.js'
import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
var isDefined = Lang.isDefined
var EPSILON = 1e-5
function isEmptyGeneralPath(t) {
  for (var e = 0, r = t.subPathCount(); e < r; e++)
    if (t.subPathLength(e) > 0) return false
  return true
}
function Geodetic2ModelTfxMap() {
  this._refs = []
  this._tfx = []
}
Geodetic2ModelTfxMap.prototype.get = function (t) {
  for (var e = 0, r = this._refs.length; e < r; e++)
    if (this._refs[e].equals(t)) break
  return e < r ? this._tfx[e] : null
}
Geodetic2ModelTfxMap.prototype.put = function (t, e) {
  this._refs.push(t)
  this._tfx.push(e)
}
var PathBoundaryPosition = (function () {
  var t = 1
  return function (e, r, o) {
    this._ident = t++
    this._subPath = e
    this._posInSubPathIndex = r
    this._nearestPosOnWBI = o
    this._nextNeighbor = null
    this._direction = null
    this._correspondingBoundaryPosition = null
  }
})()
function pathBoundaryPositionComparator(t, e) {
  if (t._nearestPosOnWBI < e._nearestPosOnWBI) return -1
  else if (t._nearestPosOnWBI > e._nearestPosOnWBI) return 1
  else if (t._subPath < e._subPath) return -1
  else if (t._subPath > e._subPath) return 1
  else if (t._posInSubPathIndex < e._posInSubPathIndex) return -1
  else if (t._posInSubPathIndex > e._posInSubPathIndex) return 1
  return 0
}
function PathBoundaryPositionSet(t) {
  this._set = {}
  this._size = 0
  for (var e = 0, r = t.length; e < r; e++) {
    var o = t[e]
    if (!isDefined(this._set[o._ident])) this._size++
    this._set[o._ident] = o
  }
}
PathBoundaryPositionSet.prototype.remove = function (t) {
  if (this.contains(t)) {
    delete this._set[t._ident]
    this._size--
  }
}
PathBoundaryPositionSet.prototype.size = function () {
  return this._size
}
PathBoundaryPositionSet.prototype.isEmpty = function () {
  return 0 === this._size
}
PathBoundaryPositionSet.prototype.contains = function (t) {
  return isDefined(this._set[t._ident])
}
PathBoundaryPositionSet.prototype.getOne = function () {
  for (var t in this._set) if (this._set.hasOwnProperty(t)) return this._set[t]
}
function sanitizeSkips(t, e, r) {
  var o,
    n = t.length,
    i,
    a,
    s,
    u,
    d,
    h = false
  for (o = 0; o < t.length; o++)
    if (t[o] === t[(o + 1) % n]) {
      h = true
      break
    }
  if (!h) return
  u = Number.NEGATIVE_INFINITY
  a = -1
  i = e.length
  for (o = 0; o < i; o++)
    if (
      (s =
        (s = e[(o + 1) % i]._nearestPosOnWBI - e[o]._nearestPosOnWBI) < 0
          ? s + r
          : s) > u
    ) {
      a = o
      u = s
    }
  d = t[a]
  for (o = (a + 1) % n; o !== a; o = (o + 1) % n) {
    d = !d
    t[o] = d
  }
}
function _WorldBoundaryUtil(t) {
  this._boundary = t
  this._reference = t.getGeoReference()
  this._geodetic2Model = new Geodetic2ModelTfxMap()
}
_WorldBoundaryUtil.prototype = Object.create(Object.prototype)
_WorldBoundaryUtil.prototype.constructor = _WorldBoundaryUtil
_WorldBoundaryUtil.prototype.getWorldBoundary = function () {
  return this._boundary
}
_WorldBoundaryUtil.prototype.fixPathForFillAndStroke = function (t, e, r) {
  var o
  if (!this._crossesBoundary(t))
    return [(o = this._fixPathNotCrossingBoundary(t, e, r)), o]
  if (e.type === ShapeType.COMPLEX_POLYGON && e.polygonCount > 1) o = t
  else o = GeneralPathUtil.ensurePathStartsAtBoundary(t)
  return [this._closeAndMergeSubPathsWithWorldBoundary(o, e, r), o]
}
_WorldBoundaryUtil.prototype._crossesBoundary = function (t) {
  var e = t.subPathCount()
  for (var r = 0; r < e; r++)
    if (this._crossesSubPathBoundary(t, r)) return true
  return false
}
_WorldBoundaryUtil.prototype._crossesSubPathBoundary = function (t, e) {
  var r = t.subPathLength(e)
  if (r < 2) return false
  var o = t.getX(e, r - 1) - t.getX(e, 0)
  var n = t.getY(e, r - 1) - t.getY(e, 0)
  return o * o + n * n >= EPSILON * EPSILON
}
_WorldBoundaryUtil.prototype._fixPathNotCrossingBoundary = function (t, e, r) {
  if (
    ShapeType.contains(ShapeType.CIRCLE, e.type) ||
    ShapeType.contains(ShapeType.BOUNDS, e.type) ||
    ShapeType.contains(ShapeType.ELLIPSE, e.type) ||
    ShapeType.contains(ShapeType.ARC_BAND, e.type) ||
    ShapeType.contains(ShapeType.SECTOR, e.type) ||
    isEmptyGeneralPath(t)
  ) {
    var o
    if (
      1 ===
      this._getContainmentCount(
        0,
        0,
        1,
        1,
        e,
        this._getGeodetic2ModelTransformation(r),
        r
      )
    )
      return this._getWorldBoundaryGeneralPath()
  }
  return t
}
_WorldBoundaryUtil.prototype._getContainmentCount = function (
  t,
  e,
  r,
  o,
  n,
  i,
  a
) {
  var s = this._boundary.getBoundary(),
    u = Math.abs(t - e),
    d = s.length - u,
    h = u,
    l = 0,
    f = createPoint(a),
    p,
    y,
    _
  if (t > e && o > 0) h = d
  else if (t < e && o < 0) h = d
  for (y = 1; y <= r; y++) {
    if ((_ = t + o * Math.floor((y * h) / (r + 1))) < 0) _ += s.length
    else if (_ >= s.length) _ -= s.length
    p = s[_].modelPoint
    try {
      i.transform(p, f)
      if (n instanceof Shape) {
        if (n.contains2DPoint(f)) l++
      } else {
        var c = n.bounds
        if (c && c.contains2D(f)) l++
      }
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
    }
  }
  return l
}
_WorldBoundaryUtil.prototype._getGeodetic2ModelTransformation = function (t) {
  var e = this._geodetic2Model.get(t)
  if (null == e) {
    e = createTransformation(this._boundary.getGeoReference(), t)
    this._geodetic2Model.put(t, e)
  }
  return e
}
_WorldBoundaryUtil.prototype._getWorldBoundaryGeneralPath = function () {
  var t = new GeneralPath(),
    e = true,
    r = this._boundary.getBoundary(),
    o = 0,
    n = r.length,
    i
  for (; o < n; o++) {
    i = r[o].worldPoint
    e = this._addPoint(t, i.x, i.y, e)
  }
  t.breakPolygon()
  return t
}
_WorldBoundaryUtil.prototype._addPoint = function (t, e, r, o) {
  if (o) t.moveTo(e, r, 0)
  else t.lineTo(e, r, 0)
  return false
}
_WorldBoundaryUtil.prototype._closeAndMergeSubPathsWithWorldBoundary =
  function (t, e, r) {
    var o = this._getGeodetic2ModelTransformation(r)
    var n = this._getSortedBoundaryPoints(t)
    var i
    var a
    var s
    var u
    var d = []
    var h = n.length
    if (0 === h) return t
    var l = new GeneralPath()
    for (a = 0; a < h; a++) {
      s = n[a]._nearestPosOnWBI
      u = n[(a + 1) % h]._nearestPosOnWBI
      d[a] = this._skip(s, u, e, o, r)
    }
    sanitizeSkips(d, n, this._boundary.getBoundary().length)
    var f = this._robustSkip(d)
    var p
    for (a = 0; a < h; a++) {
      if ((p = f ? a - 1 : a + 1) < 0) p += h
      else if (p >= h) p -= h
      n[a]._nextNeighbor = n[p]
      n[a]._direction = f ? -1 : 1
      f = !f
    }
    var y = new PathBoundaryPositionSet(n)
    var _ = []
    for (a = 0, i = t.subPathCount(); a < i; a++) _.push(a)
    var c = n[0]
    var P = true
    var B
    var g
    var v
    var b
    var S
    var m
    var W
    var T
    var I
    while (y.size() > 0) {
      i = t.subPathLength(c._subPath)
      if (0 === c._posInSubPathIndex) {
        B = 1
        g = i
      } else {
        B = -1
        g = -1
      }
      for (a = c._posInSubPathIndex; a !== g; a += B)
        P = this._addPosition(
          l,
          t.getX(c._subPath, a),
          t.getY(c._subPath, a),
          P
        )
      v = c._correspondingBoundaryPosition
      y.remove(c)
      y.remove(v)
      _.splice(_.indexOf(c._subPath), 1)
      b = v._nextNeighbor
      S = v._direction
      m = v._nearestPosOnWBI
      W = b._nearestPosOnWBI
      T = this._boundary.getBoundary()
      if (W < m && S > 0) W += T.length
      else if (W > m && S < 0) m += T.length
      for (a = m; a !== W; a += S) {
        I = T[a % T.length].worldPoint
        P = this._addPoint(l, I.x, I.y, P)
      }
      if (!y.contains(b)) {
        l.closePolygon()
        P = true
        c = y.isEmpty() ? null : y.getOne()
      } else c = b
    }
    for (a = 0; a < _.length; a++) GeneralPathUtil.addSubPathToSFCT(t, _[a], l)
    return l
  }
_WorldBoundaryUtil.prototype._getSortedBoundaryPoints = function (t) {
  var e = []
  var r = t.subPathCount()
  for (var o = 0; o < r; o++) this._addBoundaryPoints(t, o, e)
  e.sort(pathBoundaryPositionComparator)
  return e
}
_WorldBoundaryUtil.prototype._addBoundaryPoints = function (t, e, r) {
  if (!this._crossesSubPathBoundary(t, e)) return
  var o = t.subPathLength(e),
    n = createPoint(this._reference, [t.getX(e, 0), t.getY(e, 0)]),
    i = createPoint(this._reference, [t.getX(e, o - 1), t.getY(e, o - 1)]),
    a = this._getClosestIndex(n),
    s = this._getClosestIndex(i),
    u = new PathBoundaryPosition(e, 0, a),
    d = new PathBoundaryPosition(e, o - 1, s)
  u._correspondingBoundaryPosition = d
  d._correspondingBoundaryPosition = u
  r.push(u)
  r.push(d)
}
_WorldBoundaryUtil.prototype._getClosestIndex = function (t) {
  var e = this._boundary.getClosestBoundaryPosition(t)
  return null == e ? 0 : e.index
}
_WorldBoundaryUtil.prototype._skip = function (t, e, r, o, n) {
  var i
  return this._getContainmentCount(t, e, 1, 1, r, o, n) <= 0
}
_WorldBoundaryUtil.prototype._robustSkip = function (t) {
  var e = [0, 0]
  for (var r = 0, o = t.length; r < o; r++) if (t[r]) e[r % 2]++
  return e[0] > e[1]
}
_WorldBoundaryUtil.prototype._addPosition = function (t, e, r, o) {
  if (o) t.moveTo(e, r)
  else t.lineTo(e, r)
  return false
}
var cache = buildCache()
var passThroughWorldBoundaryUtil = {
  getWorldBoundary: function () {
    return null
  },
  fixPathForFillAndStroke: function (t, e, r) {
    return [t, t]
  },
}
export var WorldBoundaryUtil = {
  getInstance: function (t) {
    var e
    var r = t.name
    if (
      t.referenceType === ReferenceType.CARTESIAN ||
      t.referenceType === ReferenceType.GEOCENTRIC
    )
      return passThroughWorldBoundaryUtil
    if ('' !== r) {
      if (!(e = cache.get(r))) {
        e = WorldBoundaryBuilder.build(t)
        cache.cache(r, e)
      }
    } else e = WorldBoundaryBuilder.build(t)
    return new _WorldBoundaryUtil(e)
  },
}
