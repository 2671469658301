import { ProgrammingError } from '../error/ProgrammingError.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { forwardAzimuth2D as forwardAzimuth2DUtil } from './AzimuthUtil.js'
import { intersection2DLineSegments, intersects2DLS } from './EllipsoidUtil.js'
import {
  greatCircleDistanceLL,
  greatCirclePointAtFractionSFCT,
} from './SphereUtil.js'
const EPSILON = 1e-10
const EMPTY_STRING = ''
export class Ellipsoid {
  constructor() {
    this._name = null
    this._A = 0
    this._B = 0
    this._1OverF = 0
    this._F = 0
    this._E2 = 0
    this._E = 0
    this._EMinor2 = 0
    this._N = 0
    this._BTimesEMinor2 = 0
    this._ATimesE2 = 0
    this._C1 = 0
    this._C2 = 0
    this._C3 = 0
    this._C4 = 0
    this._C5 = 0
    this._C6 = 0
    this._B1 = 0
    this._B2 = 0
    this._B3 = 0
    this._B4 = 0
    this.initializeA1OverF(Constants.WGS_1984_A, Constants.WGS_1984_1OverF)
  }
  initializeAB(t, s) {
    this._A = t
    this._B = s
    this._1OverF = this._A / (this._A - this._B)
    this._F = (this._A - this._B) / this._A
    this._E2 = (this._A + this._B) / (this._A * this._1OverF)
    this.initializeCoefficients()
  }
  initializeA1OverF(t, s) {
    this._A = t
    this._1OverF = s
    this._F = 1 / this._1OverF
    this._B = this._A * (1 - this._F)
    this._E2 = (2 - 1 / this._1OverF) / this._1OverF
    this.initializeCoefficients()
  }
  initializeCoefficients() {
    this._E = Math.sqrt(this._E2)
    const t = this._A * this._A,
      s = this._B * this._B
    this._EMinor2 = (t - s) / s
    this._N = (this._A - this._B) / (this._A + this._B)
    this._BTimesEMinor2 = this._B * this._EMinor2
    this._ATimesE2 = this._A * this._E2
    const n = this._E2 * this._E2
    const i = n * this._E2
    const h = n * n
    this._C1 =
      1 +
      this._E2 *
        (3 / 4 +
          this._E2 *
            (45 / 64 +
              this._E2 *
                (175 / 256 +
                  this._E2 * (11025 / 16384 + this._E2 * (43659 / 65536)))))
    this._C2 =
      this._E2 *
      (3 / 8 +
        this._E2 *
          (15 / 32 +
            this._E2 *
              (525 / 1024 +
                this._E2 * (2205 / 4096 + this._E2 * (72765 / 131072)))))
    this._C3 =
      n *
      (15 / 256 +
        this._E2 *
          (105 / 1024 + this._E2 * (2205 / 16384 + this._E2 * (10395 / 65536))))
    this._C4 =
      i * (35 / 3072 + this._E2 * (105 / 4096 + this._E2 * (10395 / 262144)))
    this._C5 = this._E2 * i * (315 / 131072 + this._E2 * (3465 / 524288))
    this._C6 = n * i * (693 / 1310720)
    const a = this._E2 / 2 + (5 / 24) * n + (1 / 12) * i + (13 / 360) * h
    const o = (7 / 48) * n + (29 / 240) * i + (811 / 11520) * h
    const e = (7 / 120) * i + (81 / 1120) * h
    const r = (4279 / 161280) * h
    this._B1 = 2 * a + 4 * o + 6 * e + 8 * r
    this._B2 = -8 * o - 32 * e - 80 * r
    this._B3 = 32 * e + 192 * r
    this._B4 = -128 * r
  }
  isSphere() {
    return this._A === this._B || this._1OverF === 1 / 0
  }
  equals(t) {
    return (
      t instanceof Ellipsoid && this._A === t._A && this._1OverF === t._1OverF
    )
  }
  radiusVertical(t) {
    return this._A / Math.sqrt(1 - this._E2 * t * t)
  }
  radiusMeridian(t) {
    return (this._A * (1 - this._E2)) / Math.pow(1 - this._E2 * t * t, 1.5)
  }
  radiusEuler(t, s) {
    return this.radiusEulerImpl(
      t,
      Math.sin(t * Constants.DEG2RAD),
      s,
      Math.cos(s),
      Math.sin(s)
    )
  }
  radiusEulerImpl(t, s, n, i, h) {
    const a = this.radiusMeridian(s)
    const o = this.radiusVertical(s)
    return (a * o) / (a * h * h + o * i * i)
  }
  meridionalArcDistance(t) {
    if (0 === t) return 0
    const s = t * Constants.DEG2RAD
    const n = Math.cos(s)
    const i = Math.sin(s)
    return this.meridionalArcDistanceOptimized(t, n, i)
  }
  meridionalArcDistanceOptimized(t, s, n) {
    const i = t * Constants.DEG2RAD
    const h = s * s - n * n
    const a = 2 * s * n
    const o = h * h - a * a
    const e = 2 * h * a
    const r = undefined
    const c = e * h + o * a
    const _ = 2 * o * e
    const M = c * o + (o * h - e * a) * e
    return (
      this._A *
      (1 - this._E2) *
      (this._C1 * i -
        this._C2 * a +
        this._C3 * e -
        this._C4 * c +
        this._C5 * _ -
        this._C6 * M)
    )
  }
  conformalSphericalLonLatPointSFCT(t, s) {
    s.x = t.x
    const n = t.y * Constants.DEG2RAD
    const i = Math.cos(n)
    const h = Math.sin(n)
    const a = i * i - h * h
    const o = 2 * i * h
    const e = undefined
    const r = 2 * a * o
    const c = r * a + (a * a - o * o) * o
    const _ = this._E2 * this._E2
    const M = this._E2 * (0.5 + this._E2 * (5 / 24 + (3 * this._E2) / 32))
    const E = _ * (5 / 48 + (7 * this._E2) / 80)
    const l = (this._E2 * _ * 13) / 480
    s.y = (n - M * o + E * r - l * c) * Constants.RAD2DEG
  }
  inverseConformalSphericalLonLatPointSFCT(t, s) {
    s.x = t.x
    const n = t.y * Constants.DEG2RAD
    const i = Math.cos(n)
    const h = Math.sin(n)
    const a = h * h
    s.y =
      (n +
        h * i * (this._B1 + a * (this._B2 + a * (this._B3 + a * this._B4)))) *
      Constants.RAD2DEG
  }
  geod2geocSFCT(t, s) {
    const n = Constants.DEG2RAD
    const i = t.z || 0
    const h = Math.cos(t.y * n)
    const a = Math.cos(t.x * n)
    const o = Math.sin(t.y * n)
    const e = Math.sin(t.x * n)
    const r = this.radiusVertical(o)
    s.x = (r + i) * h * a
    s.y = (r + i) * h * e
    s.z = (r * (1 - this._E2) + i) * o
  }
  geoc2geodSFCT(t, s) {
    const n = t.x,
      i = t.y,
      h = t.z
    let a = 0
    if (Math.abs(n) <= EPSILON)
      if (i > 0) a = (Constants.RAD2DEG * Math.PI) / 2
      else if (i < 0) a = (Constants.RAD2DEG * -Math.PI) / 2
      else {
        s.x = 0
        if (h > 0) {
          s.y = (Constants.RAD2DEG * Math.PI) / 2
          s.z = h - this._B
        } else if (h < 0) {
          s.y = Constants.RAD2DEG * (-Math.PI / 2)
          s.z = -(this._B + h)
        } else {
          s.y = (Constants.RAD2DEG * Math.PI) / 2
          s.z = -this._B
        }
        return
      }
    else a = Math.atan2(i, n) * Constants.RAD2DEG
    const o = n * n + i * i,
      e = Math.sqrt(o)
    const r = e * this._B,
      c = h * this._A
    const _ = Math.sqrt(r * r + c * c)
    let M = 1,
      E = 0
    if (_ > EPSILON) {
      M = r / _
      E = c / _
    }
    let l = h + this._BTimesEMinor2 * E * E * E,
      D = e - this._ATimesE2 * M * M * M
    const A = (1 - this._F) * (l / D)
    M = 1 / Math.sqrt(A * A + 1)
    E = A * M
    l = h + this._BTimesEMinor2 * E * E * E
    D = e - this._ATimesE2 * M * M * M
    const u = l / D,
      C = 1 / Math.sqrt(u * u + 1),
      m = u * C
    const f = Math.atan2(l, D) * Constants.RAD2DEG
    const d = this._A / Math.sqrt(1 - this._E2 * m * m)
    const R = Math.abs(C) > Math.abs(m) ? e / C - d : h / m - d * (1 - this._E2)
    s.x = a
    s.y = f
    s.z = R
  }
  isEqual(t, s, n, i, h, a) {
    return isEqualLonLat(t, s, i, h) || isEqualPoles(s, n, h, a)
  }
  geodesicDistance(t, s) {
    return this.geodesicDistanceImpl(
      t.x,
      t.y,
      Math.cos(t.y * Constants.DEG2RAD),
      s.x,
      s.y,
      Math.cos(s.y * Constants.DEG2RAD)
    )
  }
  rhumblineDistance(t, s) {
    return this.rhumblineDistanceImpl(t.x, t.y, s.x, s.y)
  }
  rhumblineDistanceImpl(t, s, n, i) {
    if (Math.abs(i - s) < 1e-8) {
      const i = Math.abs(normalizeLon(n - t)) * Constants.DEG2RAD
      const h = s * Constants.DEG2RAD
      const a = Math.cos(h)
      const o = 1 - a * a
      return (this._A * i * a) / Math.sqrt(1 - this._E2 * o)
    }
    const h = this.rhumblineAzimuthRad(t, s, n, i)
    const a = this.meridionalArcDistance(s)
    const o = undefined
    return (this.meridionalArcDistance(i) - a) / Math.cos(h)
  }
  forwardAzimuth2D(t, s) {
    if (this.isSphere()) return forwardAzimuth2DUtil(t, s)
    else return this.forwardAzimuth2DVincenty(t.x, t.y, s.x, s.y)
  }
  rhumblineAzimuth2D(t, s) {
    return Constants.RAD2DEG * this.rhumblineAzimuthRad(t.x, t.y, s.x, s.y)
  }
  rhumblineAzimuthRad(t, s, n, i) {
    if (90 - Math.abs(s) < EPSILON || 90 - Math.abs(i) < EPSILON)
      return s < i ? 0 : Math.PI
    const h = this.a
    const a = this.e
    const o = n - t
    if (o > 180) t += 360
    else if (o < -180) t -= 360
    const e = h * t * Constants.DEG2RAD
    const r = Math.sin(s * Constants.DEG2RAD)
    const c = this.mercatorLatitudeToY(s, r, a, h)
    const _ = h * n * Constants.DEG2RAD
    const M = Math.sin(i * Constants.DEG2RAD)
    const E = this.mercatorLatitudeToY(i, M, a, h)
    const l = Math.atan2(_ - e, E - c)
    if (l < 0) return l + 2 * Math.PI
    return l
  }
  mercatorLatitudeToY(t, s, n, i) {
    const h = Math.tan(Math.PI / 4 + (t * Math.PI) / 360)
    const a = n * s
    const o = Math.pow((1 - a) / (1 + a), n / 2)
    return i * Math.log(h * o)
  }
  geodesicPositionAtFractionSFCT(t, s, n, i) {
    if (this.isSphere()) return greatCirclePointAtFractionSFCT(t, s, n, i)
    else
      return this.geodesicPositionAtFractionVincentySFCT(
        t.x,
        t.y,
        s.x,
        s.y,
        n,
        i
      )
  }
  geodesicPositionSFCT(t, s, n, i) {
    if (s < 1e-4) {
      i.x = t.x
      i.y = t.y
      return i
    }
    const h =
        (this._B *
          (Math.sin(t.y * Constants.DEG2RAD) /
            Math.cos(t.y * Constants.DEG2RAD))) /
        this._A,
      a = Math.atan(h),
      o = Math.cos(a),
      e = Math.sin(a),
      r = n * Constants.DEG2RAD,
      c = Math.cos(r),
      _ = Math.sin(r)
    const M = this._F / 16
    let E, l, D, A, u
    let C, m
    let f, d, R, F
    const G = Math.atan2(h, c)
    const b = o * _
    const y = b * b
    const B = 1 - y
    const L = B * this._EMinor2
    const O = 1 + (L / 16384) * (4096 + L * (-768 + L * (320 - 175 * L)))
    const S = (L / 1024) * (256 + L * (-128 + L * (74 - 47 * L)))
    E = F = s / (this._B * O)
    l = Math.cos(E)
    D = Math.sin(E)
    let P = 0
    do {
      C = 2 * G + E
      m = Math.cos(C)
      f = l * (-1 + 2 * m * m)
      d = m * (-3 + 4 * D * D) * (-3 + 4 * m * m)
      A = S * D * (m + (S / 4) * (f - (S / 6) * d))
      u = E
      E = F + A
      l = Math.cos(E)
      D = Math.sin(E)
      if (Math.abs(E - u) < 1e-10) break
      P++
    } while (P < 4)
    f = e * l + o * D * c
    R = e * D - o * l * c
    d = (1 - this._F) * Math.sqrt(y + R * R)
    const g = Constants.RAD2DEG * Math.atan2(f, d)
    f = D * _
    d = o * l - e * D * c
    const p = Math.atan2(f, d)
    const T = M * B * (4 + this._F * (4 - 3 * B))
    f = m + T * l * (-1 + 2 * m * m)
    d = (1 - T) * this._F * b * (E + T * D * f)
    const x = p - d
    i.x = normalizeLon(t.x + Constants.RAD2DEG * x)
    i.y = g
    return i
  }
  rhumblinePositionSFCT(t, s, n, i) {
    const h = 0.1
    let a, o
    if (
      Math.abs(n - 90) < h ||
      Math.abs(n - 270) < h ||
      Math.abs(n + 90) < h ||
      Math.abs(n + 270) < h
    ) {
      if (Math.abs(n - 270) < h || Math.abs(n + 90) < h) s = -s
      const i = Math.cos(t.y * Constants.DEG2RAD)
      const e = 1 - i * i
      const r = (s * Math.sqrt(1 - this._E2 * e)) / (i * this._A)
      a = normalizeLon(t.x + r * Constants.RAD2DEG)
      o = t.y
    } else {
      const i =
        s * Math.cos(n * Constants.DEG2RAD) + this.meridionalArcDistance(t.y)
      const h = this._A
      const e = this._E
      const r = this._E2
      const c = r * r
      const _ = undefined
      const M = i / (h * (1 - (1 / 4) * r - (3 / 64) * c - (5 / 256) * (c * r)))
      const E = Math.sqrt(1 - r)
      const l = (1 - E) / (1 + E)
      const D = l * l
      const A = l * D
      const u = D * D
      const C = (3 / 2) * l - (27 / 32) * A
      const m = (21 / 16) * D - (55 / 32) * u
      const f = (151 / 96) * A
      const d = (1097 / 512) * u
      const R = Math.cos(M)
      const F = Math.sin(M)
      const G = R * R - F * F
      const b = 2 * R * F
      const y = G * G - b * b
      const B = 2 * G * b
      const L = undefined
      const O = undefined
      const S = M + C * b + m * B + f * (B * G + y * b) + d * (2 * y * B)
      o = S * Constants.RAD2DEG
      let P = 0
      let g = 0
      while (o > 90 || o < -90)
        if (o > 90) {
          o = 180 - o
          g++
          if (1 === (1 & g)) P = -180
          else P = 0
        } else if (o < -90) {
          o = -180 - o
          g++
          if (1 === (1 & g)) P = 180
          else P = 0
        }
      const p = Math.tan(n * Constants.DEG2RAD)
      const T = this.mercatorLatitudeToY(
        t.y,
        Math.sin(t.y * Constants.DEG2RAD),
        e,
        h
      )
      const x = this.mercatorLatitudeToY(o, Math.sin(S), e, h)
      a = P + t.x + ((p * (x - T)) / h) * Constants.RAD2DEG
      if (Math.abs(t.y + 90) < EPSILON || Math.abs(t.y - 90) < EPSILON) a = t.x
    }
    i.x = a
    i.y = o
  }
  geodesicDistanceImpl(t, s, n, i, h, a) {
    if (this.isEqual(t, s, n, i, h, a)) return 0
    t = isPole(n) ? 0 : t
    i = isPole(a) ? 0 : i
    if (this.isSphere())
      return (
        this._A * greatCircleDistanceLL(t, s, n, i, h, a) * Constants.DEG2RAD
      )
    else return this.geodesicDistanceVincenty(t, s, i, h)
  }
  geodesicDistanceVincenty(t, s, n, i) {
    const h = Math.atan((this._B * Math.tan(s * Constants.DEG2RAD)) / this._A)
    const a = Math.atan((this._B * Math.tan(i * Constants.DEG2RAD)) / this._A)
    const o = Math.cos(h),
      e = Math.cos(a),
      r = Math.sin(h),
      c = Math.sin(a)
    let _, M, E, l, D, A, u, C, m, f, d, R, F
    const G = this._F / 16
    _ = (n - t) * Constants.DEG2RAD
    const b = _
    let y = 0
    do {
      M = Math.sin(_)
      E = Math.cos(_)
      l = e * M
      D = o * c - r * e * E
      u = Math.sqrt(l * l + D * D)
      C = r * c + o * e * E
      m = Math.atan2(u, C)
      f = (o * e * M) / u
      d = 1 - f * f
      if (d < 1e-14) R = 0
      else R = C - (2 * r * c) / d
      F = G * d * (4 + this._F * (4 - 3 * d))
      A = _
      l = R + F * C * (-1 + 2 * R * R)
      D = (1 - F) * this._F * f * (m + F * u * l)
      _ = b + D
      if (Math.abs(_ - A) < 1e-10) break
      y++
    } while (y < 4)
    const B = d * this._EMinor2
    const L = 1 + (B / 16384) * (4096 + B * (-768 + B * (320 - 175 * B)))
    const O = (B / 1024) * (256 + B * (-128 + B * (74 - 47 * B)))
    l = C * (-1 + 2 * R * R)
    D = R * (-3 + 4 * u * u) * (-3 + 4 * R * R)
    const S = O * u * (R + (O / 4) * (l - (O / 6) * D))
    return this._B * L * (m - S)
  }
  forwardAzimuth2DVincenty(t, s, n, i) {
    if (
      this.isEqual(
        t,
        s,
        Math.cos(s * Constants.DEG2RAD),
        n,
        i,
        Math.cos(i * Constants.DEG2RAD)
      )
    )
      return 0
    const h = Math.atan((this._B * Math.tan(s * Constants.DEG2RAD)) / this._A),
      a = Math.atan((this._B * Math.tan(i * Constants.DEG2RAD)) / this._A)
    const o = Math.cos(h),
      e = Math.cos(a),
      r = Math.sin(h),
      c = Math.sin(a)
    let _, M, E, l, D, A, u, C
    let m, f, d
    let R, F, G, b
    const y = this._F / 16
    _ = (n - t) * Constants.DEG2RAD
    M = _
    E = _
    C = 2 * M
    l = Math.sin(M)
    D = Math.cos(M)
    for (let t = 0; t < 4; t++) {
      A = e * l
      u = o * c - r * e * D
      m = Math.sqrt(A * A + u * u)
      f = r * c + o * e * D
      d = Math.atan2(m, f)
      R = (o * e * l) / m
      F = 1 - R * R
      if (F < 1e-14) G = 0
      else G = f - (2 * r * c) / F
      b = y * F * (4 + this._F * (4 - 3 * F))
      C = M
      A = G + b * f * (-1 + 2 * G * G)
      u = (1 - b) * this._F * R * (d + b * m * A)
      M = E + u
      l = Math.sin(M)
      D = Math.cos(M)
      if (Math.abs(M - C) < 1e-10) break
    }
    const B = Math.atan2(e * l, o * c - r * e * D)
    if (B < 0) return B + 2 * Math.PI
    else return B
  }
  geodesicPositionAtFractionVincentySFCT(t, s, n, i, h, a) {
    if (
      this.isEqual(
        t,
        s,
        Math.cos(s * Constants.DEG2RAD),
        n,
        i,
        Math.cos(i * Constants.DEG2RAD)
      )
    ) {
      a.x = t
      a.y = s
      return a
    }
    let o, e, r
    let c, _
    let M, E
    let l, D, A, u, C
    let m, f, d
    let R, F, G, b
    const y = this._F / 16,
      B = this._B / this._A
    const L = B * Math.tan(s * Constants.DEG2RAD)
    const O = B * Math.tan(i * Constants.DEG2RAD)
    const S = Math.atan(L)
    const P = Math.atan(O)
    const g = Math.cos(S)
    const p = Math.cos(P)
    const T = Math.sin(S)
    const x = Math.sin(P)
    const z = (n - t) * Constants.DEG2RAD
    const I = z
    let q = z
    let v = 2 * q
    let N = Math.sin(q)
    let w = Math.cos(q)
    const V = 1e-10 * Math.abs(q)
    let Y = 0
    do {
      l = p * N
      D = g * x - T * p * w
      r = Math.sqrt(l * l + D * D)
      e = T * x + g * p * w
      o = Math.atan2(r, e)
      m = (g * p * N) / r
      f = m * m
      d = 1 - f
      if (d < 1e-14) E = 0
      else E = e - (2 * T * x) / d
      C = y * d * (4 + this._F * (4 - 3 * d))
      v = q
      l = E + C * e * (-1 + 2 * E * E)
      D = (1 - C) * this._F * m * (o + C * r * l)
      q = I + D
      N = Math.sin(q)
      w = Math.cos(q)
      if (Math.abs(q - v) < V) break
      Y++
    } while (Y < 4)
    const j = d * this._EMinor2
    const U = 1 + (j / 16384) * (4096 + j * (-768 + j * (320 - 175 * j)))
    const k = (j / 1024) * (256 + j * (-128 + j * (74 - 47 * j)))
    l = e * (-1 + 2 * E * E)
    D = E * (-3 + 4 * r * r) * (-3 + 4 * E * E)
    c = k * r * (E + (k / 4) * (l - (k / 6) * D))
    const W = this._B * U * (o - c)
    const H = Math.atan2(p * N, g * x - T * p * w)
    const J = h * W
    const K = Math.cos(H)
    const Q = Math.sin(H)
    const X = Math.atan2(L, K)
    o = u = J / (this._B * U)
    e = Math.cos(o)
    r = Math.sin(o)
    const Z = 1e-10 * Math.abs(o)
    let $ = 0
    do {
      M = 2 * X + o
      E = Math.cos(M)
      l = e * (-1 + 2 * E * E)
      D = E * (-3 + 4 * r * r) * (-3 + 4 * E * E)
      c = k * r * (E + (k / 4) * (l - (k / 6) * D))
      _ = o
      o = u + c
      e = Math.cos(o)
      r = Math.sin(o)
      if (Math.abs(o - _) < Z) break
      $++
    } while ($ < 4)
    l = T * e + g * r * K
    A = T * r - g * e * K
    D = (1 - this._F) * Math.sqrt(f + A * A)
    R = Constants.RAD2DEG * Math.atan2(l, D)
    l = r * Q
    D = g * e - T * r * K
    F = Math.atan2(l, D)
    C = y * d * (4 + this._F * (4 - 3 * d))
    l = E + C * e * (-1 + 2 * E * E)
    D = (1 - C) * this._F * m * (o + C * r * l)
    b = F - D
    G = normalizeLon(t + Constants.RAD2DEG * b)
    a.x = G
    a.y = R
    return a
  }
  intersects2DLS(t, s, n, i) {
    return intersects2DLS(this, t, s, n, i)
  }
  intersection2DLSSFCT(t, s, n, i, h) {
    const a = undefined
    if (1 != intersection2DLineSegments(t, s, n, i, this, 0.01, h, h.copy()))
      throw new ProgrammingError('Number of intersection points should be 1.')
  }
  get a() {
    return this._A
  }
  get b() {
    return this._B
  }
  get oneOverF() {
    return this._1OverF
  }
  get f() {
    return this._F
  }
  get e() {
    return this._E
  }
  get e2() {
    return this._E2
  }
  get eMinor2() {
    return this._EMinor2
  }
  get n() {
    return this._N
  }
  get name() {
    let t = this._name
    if (null == t)
      if (
        EPSILON >= Math.abs(this._A - Constants.WGS_1984_A) &&
        EPSILON >= Math.abs(this._1OverF - Constants.WGS_1984_1OverF)
      )
        t = 'WGS_1984'
      else t = EMPTY_STRING
    return t
  }
  set name(t) {
    this._name = t
  }
  get auxRadius() {
    return this._A
  }
  get conformalRadius() {
    const t = this.n
    const s = t * t
    return (this._A * (1 + s / 4 + (s * s) / 64)) / (1 + t)
  }
  static from(t) {
    const s = new Ellipsoid()
    s.initializeA1OverF(t.a, t.oneOverF)
    if (t.name) s.name = t.name
    return s
  }
  hashCode(t) {
    t.appendDouble(this._A)
    t.appendDouble(this._B)
  }
}
function isEqualLonLat(t, s, n, i) {
  return (
    Math.abs(n - t) < Constants.COORD_TOLERANCE &&
    Math.abs(i - s) < Constants.COORD_TOLERANCE
  )
}
function isEqualPoles(t, s, n, i) {
  return isPole(s) && isPole(i) && Math.abs(t - n) < Constants.COORD_TOLERANCE
}
function isPole(t) {
  return Math.abs(t) < Constants.COORD_TOLERANCE
}
export const DEFAULT = new Ellipsoid()
