import { ReferenceType } from '../../reference/ReferenceType.js'
function determineTolerance(e) {
  return e.referenceType === ReferenceType.GEODETIC ? 1e-8 : 0.001
}
export function isTopLeftAlignedTileMatrixSetStructure(e) {
  if (0 === e.tileMatrix.length)
    return 'TileMatrixSet2DRasterTileSetStructure does not have tile matrices defined.'
  const t = e.tileMatrix[0].bounds
  if (!t)
    return 'TileMatrixSet2DRasterTileSetStructure requires bounds to be defined for each level.'
  const r = t.x
  const i = t.y + t.height
  const n = determineTolerance(e.reference)
  for (let t = 1; t < e.tileMatrix.length; t++) {
    const l = e.tileMatrix[t].bounds
    if (!l)
      return 'TileMatrixSet2DRasterTileSetStructure requires bounds to be defined for each level.'
    const o = l.x
    const u = l.y + l.height
    if (Math.abs(r - o) > n || Math.abs(i - u) > n)
      return 'TileMatrixSet2DRasterTileSetStructure only supports tile sets where the top-left corner is aligned'
  }
  return true
}
export function isQuadTreeTileMatrixSetStructure(e) {
  const t = e.bounds
  if (!t)
    return 'TileMatrixSet2DRasterTileSetStructure must have bounds defined'
  const r = e.tileMatrix[0].bounds
  if (!r)
    return 'TileMatrixSet2DRasterTileSetStructure requires bounds to be defined for each level.'
  if (!r.equals(t))
    return 'TileMatrixSet2DRasterTileSetStructure requires bounds for each level to be equal.'
  const i = e.tileMatrix[0]
  for (let r = 1; r < e.tileMatrix.length; r++) {
    const n = e.tileMatrix[r].bounds
    if (!n)
      return 'TileMatrixSet2DRasterTileSetStructure requires bounds to be defined for each level.'
    if (!n.equals(t))
      return 'TileMatrixSet2DRasterTileSetStructure requires bounds for each level to be equal.'
    const l = e.tileMatrix[r]
    if (
      l.tileWidth !== i.tileWidth ||
      l.tileHeight !== i.tileHeight ||
      l.tileRowCount !== i.tileRowCount << r ||
      l.tileColumnCount !== i.tileColumnCount << r
    )
      return 'TileMatrixSet2DRasterTileSetStructure does not represent a quad-tree.'
  }
  return true
}
export function isTopLeftAlignedRasterTileSetModel(e) {
  if (0 === e.levelCount) return 'RasterTileSetModel has no levels.'
  const t = e.getBounds(0)
  if (!t)
    return 'RasterTileSetModel requires bounds to be defined for each level.'
  const r = t.x
  const i = t.y + t.height
  const n = determineTolerance(e.reference)
  for (let t = 1; t < e.levelCount; t++) {
    const l = e.getBounds(t)
    if (!l)
      return 'RasterTileSetModel requires bounds to be defined for each level.'
    const o = l.x
    const u = l.y + l.height
    if (Math.abs(r - o) > n || Math.abs(i - u) > n)
      return 'RasterTileSetModel only supports tile sets where the top-left corner is aligned'
  }
  return true
}
export function isQuadTreeRasterTileSetModel(e) {
  if (!e) return true
  const t = e.getBounds(0)
  if (!t) return false
  for (let r = 1; r < e.levelCount; r++) {
    if (
      e.getTileWidth(r) !== e.getTileWidth(0) ||
      e.getTileHeight(r) !== e.getTileHeight(0) ||
      e.getTileRowCount(r) !== (e.getTileRowCount(0) ?? 0) << r ||
      e.getTileColumnCount(r) !== (e.getTileColumnCount(0) ?? 0) << r
    )
      return false
    const i = e.getBounds(r)
    if (!i) return false
    if (!i.equals(t)) return false
  }
  return true
}
