import { Constants } from '../util/Constants.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { LLHBounds } from '../shape/LLHBounds.js'
import { forwardAzimuth2D } from './AzimuthUtil.js'
function calculateAreaPrivate(t, e, n, i) {
  if (3 === t.pointCount) {
    const o = t.getSimplePoint(0)
    const a = t.getSimplePoint(1)
    const r = t.getSimplePoint(2)
    if (equalPoints(o, a) || equalPoints(a, r) || equalPoints(r, o)) return 0
    return calculateSignedAreaTriangle(o, a, r, e, n, i)
  }
  const o = calculateAnchorPoint(t)
  let a = 0
  const r = t.pointCount
  let l = 1
  let u = t.getSimplePoint(l - 1)
  let s = t.getSimplePoint(l)
  while (equalPoints(o, u) || equalPoints(u, s) || equalPoints(s, o)) {
    l++
    if (l > r) return a
    u = t.getSimplePoint(l - 1)
    s = t.getSimplePoint(l % r)
  }
  while (l <= r) {
    const c = undefined
    a += calculateSignedAreaTriangle(o, u, s, e, n, i)
    l++
    if (l <= r) {
      u = t.getSimplePoint(l - 1)
      s = t.getSimplePoint(l % r)
      while (equalPoints(o, u) || equalPoints(u, s) || equalPoints(s, o)) {
        l++
        if (l > r) break
        u = t.getSimplePoint(l - 1)
        s = t.getSimplePoint(l % r)
      }
    }
  }
  return a
}
function largestSquaredLonLatDistance(t) {
  if (t.pointCount <= 2) return 0
  let e = t.getSimplePoint(0)
  let n = t.getSimplePoint(1)
  let i = n.x - e.x
  let o = n.y - e.y
  const a = t.pointCount
  let r = i * i + o * o
  for (let l = 1; l < a; l++) {
    e = t.getSimplePoint(l)
    n = t.getSimplePoint((l + 1) % a)
    i = n.x - e.x
    o = n.y - e.y
    const u = i * i + o * o
    if (u > r) r = u
  }
  return r
}
function calculateAnchorPoint(t) {
  const e = new LLHBounds(t.reference)
  const n = t.getSimplePoint(0)
  e.move2DToCoordinates(n.x, n.y)
  const i = t.getSimplePoint(1)
  e.includeCoordinate2D(i.x, i.y)
  const o = new LLHBounds(t.reference)
  for (let n = 1; n < t.pointCount; n++) {
    const i = t.getSimplePoint(n)
    const a = t.getSimplePoint((n + 1) % t.pointCount)
    o.move2DToCoordinates(i.x, i.y)
    o.width = 0
    o.height = 0
    o.includeCoordinate2D(a.x, a.y)
    e.setTo2DUnion(o)
  }
  const a = new LLHPoint(e.reference, [e.x, e.y, e.z])
  if (e.width > 270 && e.y + e.height > 0) {
    a.move2D(0, 90)
    return a
  }
  if (e.width > 270 && e.y + e.height < 0) {
    a.move2D(0, -90)
    return a
  }
  a.translate2D(e.width / 2, e.height / 2)
  return a
}
function equalPoints(t, e) {
  const n = 1e-10
  return Math.abs(t.x - e.x) < n && Math.abs(t.y - e.y) < n
}
function calculateSignedAreaTriangle(t, e, n, i, o, a) {
  const r = undefined
  if (useCartesian(t, e, n, o)) return calculateSignedAreaCartesian(t, e, n, a)
  else return calculateSignedAreaGeodetic(t, e, n, i)
}
function useCartesian(t, e, n, i) {
  const o = Math.min(t.x, Math.min(e.x, n.x))
  const a = Math.max(t.x, Math.max(e.x, n.x))
  const r = Math.min(t.y, Math.min(e.y, n.y))
  const l = undefined
  let u = a - o
  const s = Math.max(t.y, Math.max(e.y, n.y)) - r
  while (u >= 180) u -= 360
  u = Math.abs(u)
  const c = undefined
  return (u * s < 1e-5 && Math.max(u, s) < 0.01) || i
}
function calculateSignedAreaCartesian(t, e, n, i) {
  let o = t.x
  const a = t.y
  let r = e.x
  const l = e.y
  let u = n.x
  const s = n.y
  while (r - o >= 180) r -= 360
  while (r - o <= -180) r += 360
  while (u - o >= 180) u -= 360
  while (u - o <= -180) u += 360
  if (i) {
    o *= Math.cos(t.y * Constants.DEG2RAD)
    r *= Math.cos(e.y * Constants.DEG2RAD)
    u *= Math.cos(n.y * Constants.DEG2RAD)
  }
  let c = 0.5 * (-r * a + u * a + o * l - u * l - o * s + r * s)
  c *= Constants.DEG2RAD * Constants.DEG2RAD
  return c
}
function calculateSignedAreaGeodetic(t, e, n, i) {
  let o
  let a
  let r
  let l
  let u
  let s
  if (null != i) {
    o = i.forwardAzimuth2D(t, e)
    a = i.forwardAzimuth2D(t, n)
    r = i.forwardAzimuth2D(e, t)
    l = i.forwardAzimuth2D(e, n)
    u = i.forwardAzimuth2D(n, t)
    s = i.forwardAzimuth2D(n, e)
  } else {
    o = forwardAzimuth2D(t, e)
    a = forwardAzimuth2D(t, n)
    r = forwardAzimuth2D(e, t)
    l = forwardAzimuth2D(e, n)
    u = forwardAzimuth2D(n, t)
    s = forwardAzimuth2D(n, e)
  }
  let c = a - o
  let h = r - l
  let f = s - u
  if (a < o) c += 2 * Math.PI
  if (r < l) h += 2 * Math.PI
  if (s < u) f += 2 * Math.PI
  let m = Math.PI - c - h - f
  if (m < -2 * Math.PI) m += 4 * Math.PI
  return m
}
export function geodesicArea(t, e) {
  if (t.pointCount <= 2) return 0
  let n = false
  const i = undefined
  if (largestSquaredLonLatDistance(t) < 0.001) n = true
  return calculateAreaPrivate(t, e, n, true)
}
