import { XYZPoint } from '../shape/XYZPoint.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import {
  containsAnglePragmatic,
  distance2D as CartesianDistance2D,
  forwardAzimuth2D as CartesianForwardAzimuth2D,
} from '../util/Cartesian.js'
import { Constants } from '../util/Constants.js'
import { XYZBounds } from '../shape/XYZBounds.js'
export class XYZArcBandUtil {
  constructor() {}
  static coordinateType = CoordinateType.CARTESIAN
  static distance2D(t, n) {
    return CartesianDistance2D(t, n)
  }
  static forwardAzimuth2D(t, n) {
    return Constants.RAD2DEG * CartesianForwardAzimuth2D(t, n)
  }
  static calculatePoint(t, n, a) {
    const e = a.copy()
    e.move2D(
      a.x + t * Math.sin(n * Constants.DEG2RAD),
      a.y + t * Math.cos(n * Constants.DEG2RAD)
    )
    return e
  }
  static contains2DCoordinates(t, n, a) {
    const e = new XYZPoint(a.reference)
    e.move2D(t, n)
    const i = CartesianDistance2D(a.center, e)
    const s = 1e-8
    if (i < a.minRadius - s || i > a.maxRadius + s) return false
    if (i < s && a.minRadius < s) return true
    const r = Math.atan2(e.x - a.center.x, e.y - a.center.y) * Constants.RAD2DEG
    return containsAnglePragmatic(a.startAzimuth, a.sweepAngle, r)
  }
  static calculateBounds(t) {
    const n = new XYZBounds(t.reference, [0, 0, 0, 0, 0, 0]),
      a = t.center,
      e = t.maxRadius,
      i = t.startAzimuth,
      s = t.sweepAngle,
      r = a.copy()
    if (0 === t.minRadius) n.move2D(a)
    else {
      n.move2D(t.minRadiusStartCorner)
      n.setToIncludePoint2D(t.minRadiusEndCorner)
    }
    n.setToIncludePoint2D(t.maxRadiusStartCorner)
    n.setToIncludePoint2D(t.maxRadiusEndCorner)
    if (containsAnglePragmatic(i, s, 0)) c(a.x, a.y + e)
    if (containsAnglePragmatic(i, s, 90)) c(a.x + e, a.y)
    if (containsAnglePragmatic(i, s, 180)) c(a.x, a.y - e)
    if (containsAnglePragmatic(i, s, 270)) c(a.x - e, a.y)
    const o = 1e-8
    n.translate2D(-o, -o)
    n.width = n.width + 2 * o
    n.height = n.height + 2 * o
    function c(t, a) {
      r.move2D(t, a)
      n.setToIncludePoint2D(r)
    }
    return n
  }
}
