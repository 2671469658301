import { INVISIBLE } from '../../../util/Color.js'
import { isArray, isNumber, isObject, isString } from '../../../util/Lang.js'
import { getWorldUOMToMeterScale } from '../WorldSizeUtil.js'
import { paintComplexStrokeCanvasPath } from './ComplexStrokeCanvasImpl.js'
import { ParallelLinePattern } from './pattern/ParallelLinePattern.js'
import { injectWorldSizeContext } from './pattern/util/PatternUtil.js'
import { BloomStyleImpl, DEFAULT_BLOOM } from '../BloomStyle.js'
import { normalizeOffsetSizes, saveAndOffsetContext } from '../OffsetsUtil.js'
import { isWorldSize, PIXEL_UOM } from '../../../uom/UnitOfMeasureUtil.js'
const tmpCoordinates = []
const DEFAULT_SHARP_ANGLE_THRESHOLD = 155
function createDefaultFallbackPattern() {
  return new ParallelLinePattern({
    length: 1,
    relative: true,
    line: { color: INVISIBLE, width: 1 },
  })
}
function determineDefaultAlignment(t) {
  if (0 === t) return 'left'
  if (1 === t) return 'right'
  return 'center'
}
function normalizeDecorations(t) {
  const e = undefined
  t = isArray(t) && t.length > 0 ? t : []
  const r = []
  for (let e = 0; e < t.length; e++) {
    const o = t[e]
    if (!isNumber(o.location))
      throw new Error(
        'A ComplexStrokedLineStyle.decoration should always specify a location,' +
          ' but got ' +
          o.location +
          ', which is not a number.'
      )
    const i = o.alignment || determineDefaultAlignment(o.location)
    if (!isString(i) || ('left' !== i && 'center' !== i && 'right' !== i))
      throw new Error(
        "Found an invalid value for ComplexStrokedLineStyle.decoration. Got '" +
          i +
          "'," +
          ' but only "left", "center" and "right" are valid.'
      )
    r.push({ location: o.location, pattern: o.pattern, alignment: i })
  }
  return r
}
function verifyRegular(t) {
  if (!t) return null
  return t
}
function verifyFallback(t) {
  if (!(t = t || createDefaultFallbackPattern()))
    throw new Error('ComplexStrokedStyle.fallback should be a valid Pattern.')
  return t
}
export class ComplexStrokedLineStyleImpl {
  constructor(t) {
    t = t || {}
    this._normalizedOffsets = normalizeOffsetSizes(t.offsetX, t.offsetY, t.uom)
    this._decorations = t.decorations ? normalizeDecorations(t.decorations) : []
    this._regular = verifyRegular(t.regular)
    this._fallback = verifyFallback(t.fallback)
    const e = t.sharpAngleThreshold ?? DEFAULT_SHARP_ANGLE_THRESHOLD
    if (e < 0 || e > 180)
      throw new Error(
        'ComplexStrokedLineStyle.sharpAngleThreshold should be a number in range [0, 180], ' +
          "but got '" +
          e +
          "'"
      )
    this._sharpAngleThreshold = e
    this._lineJoin = t.lineJoin ?? 'round'
    this._resolved = false
    this._uom = t.uom || PIXEL_UOM
    this._isWorldSize = isWorldSize(t.uom)
    this._scaleFactor = this._isWorldSize
      ? getWorldUOMToMeterScale(this._uom)
      : 1
    this._bloom =
      t.bloom && isObject(t.bloom) ? new BloomStyleImpl(t.bloom) : DEFAULT_BLOOM
  }
  get decorations() {
    return this._decorations
  }
  set decorations(t) {
    this._decorations = t
  }
  get regular() {
    return this._regular
  }
  set regular(t) {
    this._regular = verifyRegular(t)
  }
  get fallback() {
    return this._fallback
  }
  set fallback(t) {
    this._fallback = verifyFallback(t)
  }
  get sharpAngleThreshold() {
    return this._sharpAngleThreshold
  }
  set sharpAngleThreshold(t) {
    this._sharpAngleThreshold = t
  }
  get lineJoin() {
    return this._lineJoin
  }
  set lineJoin(t) {
    this._lineJoin = t
  }
  get uom() {
    return this._uom
  }
  set uom(t) {
    this._uom = t
    this._isWorldSize = isWorldSize(t)
    this._scaleFactor = this._isWorldSize ? getWorldUOMToMeterScale(t) : 1
  }
  get isWorldSize() {
    return this._isWorldSize
  }
  get scaleFactor() {
    return this._scaleFactor
  }
  get bloom() {
    return this._bloom
  }
  set bloom(t) {
    this._bloom = t && isObject(t) ? new BloomStyleImpl(t) : DEFAULT_BLOOM
  }
  get offsetX() {
    return this._normalizedOffsets.normalizedOffsetX.pixelOffset
  }
  get offsetY() {
    return this._normalizedOffsets.normalizedOffsetY.pixelOffset
  }
  get normalizedOffsets() {
    return this._normalizedOffsets
  }
  resolveState(t) {
    if (this._resolved) {
      t()
      return
    }
    const e = []
    const r = this._decorations
    const o = this._regular
    const i = this._fallback
    function l(t) {
      if (t instanceof Promise) e.push(t)
    }
    for (let t = 0; t < r.length; t++) l(r[t].pattern.resolveState())
    if (o) l(o.resolveState())
    if (i) l(i.resolveState())
    if (0 === e.length) {
      this._resolved = true
      t()
    } else {
      const r = this
      Promise.all(e).then(function () {
        r._resolved = true
        t()
      })
    }
  }
  paintPath(t, e, r, o) {
    saveAndOffsetContext(r, this.normalizedOffsets, o)
    paintComplexStrokeCanvasPath(r, t, e, this)
    r.restore()
  }
  paintPathMapCoords(t, e, r, o, i) {
    const l = r._forwardBatch(e, tmpCoordinates)
    this.paintPath(tmpCoordinates, l, t, i)
  }
  paintMultiPathMapCoords(t, e, r, o, i) {
    let l
    for (let o = 0; o < e.length; o += 1) {
      l = r._forwardBatch(e[o], tmpCoordinates)
      this.paintPath(tmpCoordinates, l, t, i)
    }
  }
  getStrokeWidth() {
    const t = this._regular || this._fallback
    let e = t.getMaxHeight()
    let r = t.getMinHeight()
    if (this._decorations.length > 0) {
      const t = getPatternMinMax(this._decorations)
      e = Math.max(e, t.max)
      r = Math.min(r, t.min)
    }
    return 2 * Math.max(Math.abs(e), Math.abs(r))
  }
  set worldSizeSupport(t) {
    if (this._isWorldSize) {
      const e = {
        uom: this._uom,
        isWorldSize: this._isWorldSize,
        worldSizeSupport: t,
      }
      if (this._regular) injectWorldSizeContext(this._regular, e)
      injectWorldSizeContext(this._fallback, e)
      for (const t of this._decorations) injectWorldSizeContext(t.pattern, e)
    }
  }
  static create(t) {
    return new ComplexStrokedLineStyleImpl(t)
  }
}
function getPatternMinMax(t) {
  let e = Number.NEGATIVE_INFINITY
  let r = Number.POSITIVE_INFINITY
  for (let o = 0; o < t.length; o += 1) {
    e = Math.max(t[o].pattern.getMaxHeight(), e)
    r = Math.min(t[o].pattern.getMinHeight(), r)
  }
  return { max: e, min: r }
}
