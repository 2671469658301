import { ProgrammingError } from '../../error/ProgrammingError.js'
import { ShapeList } from '../../shape/ShapeList.js'
import { ComplexPolygon } from '../../shape/ComplexPolygon.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { CartesianAdvancedBinaryTopologyUtil } from '../topologyutil/CartesianAdvancedBinaryTopologyUtil.js'
import { EllipsoidalAdvancedBinaryTopologyUtil } from '../topologyutil/EllipsoidalAdvancedBinaryTopologyUtil.js'
import { ComplexPolygonTree } from './ComplexPolygonTree.js'
function _convertComplexPolygon(e, o) {
  var r = e.polygonCount
  if (0 === r)
    throw new ProgrammingError('Complex polygon contains no polygons.')
  else if (1 == r) return e.getPolygon(0)
  else {
    var n
    var l = new ComplexPolygonTree(e, o).root
    var i = new ShapeList(e.getPolygon(0).reference, [])
    var t
    processOuterChildren(e, i, l.children)
    return i
  }
}
function processOuterChildren(e, o, r) {
  for (var n = 0; n < r.length; n++) {
    var l = r[n]
    var i = e.getPolygon(l.polygonIndex)
    if (0 === l.children.length) o.addShape(i)
    else {
      var t = new ComplexPolygon(i.reference, [i])
      for (var a = 0; a < l.children.length; a++) {
        var p = l.children[a]
        var g = e.getPolygon(p.polygonIndex)
        t.addPolygon(g)
      }
      o.addShape(t)
      for (var a = 0; a < l.children.length; a++) {
        var p
        processOuterChildren(e, o, (p = l.children[a]).children)
      }
    }
  }
}
export var FlattenComplexPolygonUtil = {
  convertComplexPolygon: function (e) {
    var o = e.reference
    if (o.referenceType === ReferenceType.GEODETIC)
      return _convertComplexPolygon(
        e,
        new EllipsoidalAdvancedBinaryTopologyUtil(o.geodeticDatum.ellipsoid)
      )
    else
      return _convertComplexPolygon(
        e,
        new CartesianAdvancedBinaryTopologyUtil(1e-4)
      )
  },
}
