import { XMLSchema } from '../../util/XMLSchema.js'
const attribute = XMLSchema.attribute,
  boolean = XMLSchema.boolean,
  number = XMLSchema.number,
  content = XMLSchema.content
const xLinkSimpleLink = {
  actuate: attribute(),
  arcrole: attribute(),
  href: attribute(),
  role: attribute(),
  show: attribute(),
  title: attribute(),
  type: attribute(),
}
export const LTSCapabilitiesSchema = {
  Capabilities: {
    version: attribute(),
    updateSequence: attribute(),
    ServiceMetadata: {
      Id: content(),
      Name: content(),
      Abstract: content(),
      Keyword: [{ '#text_only': content() }],
    },
    OperationsMetadata: {
      Operation: [
        {
          name: attribute(),
          HTTPRequest: [{ Method: content(), OnlineResource: xLinkSimpleLink }],
        },
      ],
    },
  },
}
