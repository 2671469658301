import { XYZBounds } from '../../shape/XYZBounds.js'
import { CommandUtil } from './CommandUtil.js'
import { CoordinateArrayUtil } from './CoordinateArrayUtil.js'
import { appendPathSFCT } from './HTML5CanvasUtil.js'
import { HTML5DrawFillCommandProto } from './HTML5DrawFillCommandProto.js'
import {
  ringContainsXY2DFlatCoordList,
  squaredDistanceToLineFlat,
} from '../../util/Cartesian.js'
import {
  getPerpendicularOffsetInPixels,
  saveAndOffsetContext,
} from './OffsetsUtil.js'
const tempBounds = new XYZBounds(null)
export class HTML5DrawFillCommandDefault extends HTML5DrawFillCommandProto {
  constructor(t, s, e, o, i, r, n, l) {
    super(t, s, l, r, n)
    this._fills = e || []
    this._strokes = o || []
    this._coordinatesArray = new Array(2)
    this._normalizedOffsets = r.normalizedOffsets
    let a
    for (let t = 0; t < this._fills.length; t++) {
      CoordinateArrayUtil.calculateBoundsMultiSFCT(this._fills[t], tempBounds)
      if (0 === t) a = tempBounds.copy()
      CommandUtil.expandBoundsSFCT(tempBounds, a)
    }
    this.bounds = a
    this._strokeStyle = i
  }
  draw(t, s, e) {
    saveAndOffsetContext(t, this._normalizedOffsets, e)
    const o = getPerpendicularOffsetInPixels(
      this.fillStyle.normalizedPerpendicularOffset,
      e
    )
    t.beginPath()
    for (let e = 0, i = this._fills.length; e < i; e++) {
      const i = this._fills[e]
      let r = 0
      const n = i.length
      while (r < n) {
        appendPathSFCT(i[r], t, s, this._coordinatesArray, o)
        t.closePath()
        r += 1
      }
    }
    t.restore()
    this._fill(t, this._coordinatesArray[0], this._coordinatesArray[1], e)
    this._strokeStyle.paintMultiPathMapCoords(t, this._strokes, s, true)
  }
  interacts(t, s, e) {
    let o = false
    const i = t.focusPoint
    const r = i.x
    const n = i.y
    const l = this._fills
    let a
    let d
    let f
    let h
    let m
    if (this.hasFill())
      for (let t = 0; t < l.length; t++) {
        a = l[t]
        m = false
        if (ringContainsXY2DFlatCoordList(a[0], r, n)) {
          m = true
          f = a.length
          for (h = 1; h < f; h++) {
            d = a[h]
            if (ringContainsXY2DFlatCoordList(d, r, n)) m = !m
          }
          if (m) return true
        }
      }
    o = this.strokeInteracts(t, s)
    return o
  }
  strokeInteracts(t, s) {
    const e = this._strokes
    const o = e.length
    for (let i = 0; i < o; i++)
      if (
        CommandUtil.lineInteractsFlat(
          e[i],
          t,
          this._strokeStyle.getStrokeWidth(),
          s
        )
      )
        return true
    return false
  }
  mapAnchorPointSFCT(t, s) {
    return CoordinateArrayUtil.mapAnchorPointSFCT(this._fills[0], true, s)
  }
  mapAnchorPointsSFCT(t, s, e) {
    for (let o = 0; o < this._fills.length; o++) {
      t.getViewBounds(tempBounds)
      CoordinateArrayUtil.mapAnchorPointsSFCT(
        this._fills[o],
        this.bounds,
        tempBounds,
        t.mapToViewTransformationInternal,
        e,
        s
      )
    }
  }
  getMapDistanceSquared(t, s, e, o) {
    o.clear()
    o.setDistanceToBounds(this.worldBounds, s, e)
    if (this._strokes.length > 0)
      o.setDistanceToEdge(squaredDistanceToLineFlat(this._strokes[0], s, e))
    else if (this._fills.length > 0)
      o.setDistanceToEdge(squaredDistanceToLineFlat(this._fills[0][0], s, e))
  }
}
