import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { ProjectionType } from './ProjectionType.js'
import { TransverseCylindrical } from './TransverseCylindrical.js'
const sharedOutOfBoundsError = new OutOfBoundsError('Cassini')
export class Cassini extends TransverseCylindrical {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    super()
    this._case = this.OBLIQUE
    this.setCentralMeridian(t)
    this.setOriginLat(n)
    this.calculateCachedValues()
  }
  isAllInBounds() {
    return false
  }
  isContinuous() {
    return false
  }
  geodetic2cartesianOnSphereSFCT(t, n, s) {
    if (this.inLonLatBounds(t)) {
      const e = this.geodetic2xOnSphere(
        t.x,
        Math.cos(t.y * Constants.DEG2RAD),
        n
      )
      const r = this.geodetic2yOnSphere(
        t.x,
        Math.tan(t.y * Constants.DEG2RAD),
        n
      )
      s.x = e
      s.y = r
    } else throw sharedOutOfBoundsError
  }
  geodetic2xOnSphere(t, n, s) {
    const e = n * Math.sin((t - this.getCentralMeridian()) * Constants.DEG2RAD)
    return s * Math.asin(e)
  }
  geodetic2yOnSphere(t, n, s) {
    const e = Math.cos((t - this.getCentralMeridian()) * Constants.DEG2RAD)
    return s * (Math.atan2(n, e) - this.getOriginLat() * Constants.DEG2RAD)
  }
  cartesian2geodeticOnSphereSFCT(t, n, s) {
    if (this.inWorldBoundsOnSphere(t, n)) {
      const e = t.y / n + this.getOriginLat() * Constants.DEG2RAD
      let r = t.x / n
      r = Math.max(r, -Math.PI / 2)
      r = Math.min(r, Math.PI / 2)
      const o = Math.cos(e)
      const i = Math.tan(r)
      const a = normalizeLon(
        Math.atan2(i, o) * Constants.RAD2DEG + this.getCentralMeridian()
      )
      const h = Math.asin(Math.sin(e) * Math.cos(r)) * Constants.RAD2DEG
      s.x = a
      s.y = h
    } else throw sharedOutOfBoundsError
  }
  inLonLatBounds(t) {
    return true
  }
  inWorldBoundsOnSphere(t, n) {
    const s = t.y + this.getOriginLat() * n * Constants.DEG2RAD
    return (
      s <= n * Math.PI &&
      s >= -n * Math.PI &&
      t.x <= (n * Math.PI) / 2 &&
      t.x >= (-n * Math.PI) / 2
    )
  }
  boundaryLons(t) {
    return [[-180, 180]]
  }
  boundaryLats(t) {
    return [
      [-90 + this.EPSILON, 0 - this.EPSILON],
      [this.EPSILON, 90 - this.EPSILON],
    ]
  }
  cartesianBoundsOnSphereSFCT(t, n) {
    const s = (t * Math.PI) / 2
    const e = -t * this.getOriginLat() * Constants.DEG2RAD
    const r = t * Math.PI
    n.setTo2D(-s, 2 * s, e - r, 2 * r)
  }
  encode() {
    return {
      type: 'Cassini',
      centralMeridian: this.getCentralMeridian(),
      originLat: this.getOriginLat(),
    }
  }
  get TYPE() {
    return ProjectionType.CASSINI + ProjectionType.TRANSVERSECYLINDRICAL
  }
  get OBLIQUE() {
    return 0
  }
  get EQUATOR() {
    return 1
  }
  get NORTH_POLE() {
    return 2
  }
  get SOUTH_POLE() {
    return 3
  }
}
