import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { OrientedBox } from '../../shape/OrientedBox.js'
import { Constants } from '../../util/Constants.js'
import { WithHttpRequestOptions } from '../../util/WithHttpRequestOptions.js'
import { URL as RIAUrl } from '../../util/URL.js'
import { addHttpRequestOptions } from '../../util/HttpRequestOptions.js'
import { request } from '../../util/request.js'
const REF_EPSG_4978 = getReference('EPSG:4978')
const REF_LUCIAD_XYZ = getReference('LUCIAD:XYZ')
export class TileSet3DModelSupport {
  constructor(e) {
    if (!e.url) throw new ProgrammingError('Tou must specify a URL.')
    if (!e.orientedBox)
      throw new ProgrammingError('Oriented box not specified .')
    if (!e.modelDescriptor)
      throw new ProgrammingError('ModelDescriptor not specified.')
    this._httpSupport = new WithHttpRequestOptions(e)
    this._modelDescriptor = e.modelDescriptor
    this._url = e.url
    this._urlParams = getUrlParametersString(e.url)
    this._requestParameters = RIAUrl.parseQueryString(this._urlParams)
    this._reference = e.reference
    this._orientedBox = e.orientedBox
    this._requestParameters = Object.assign(
      this._requestParameters,
      e.requestParameters
    )
  }
  get credentials() {
    return this._httpSupport.credentials
  }
  set credentials(e) {
    this._httpSupport.credentials = e
  }
  get requestHeaders() {
    return this._httpSupport.requestHeaders
  }
  set requestHeaders(e) {
    this._httpSupport.requestHeaders = e
  }
  addHttpRequestOptions(e) {
    return this._httpSupport.addHttpRequestOptions(e)
  }
  get url() {
    return this._url
  }
  get urlParams() {
    return this._urlParams
  }
  get bounds() {
    return this._orientedBox.bounds
  }
  get requestParameters() {
    return this._requestParameters
  }
  set requestParameters(e) {
    this._requestParameters = e
  }
  get orientedBox() {
    return this._orientedBox.copy()
  }
  get coordinateType() {
    return this._reference.coordinateType
  }
  get reference() {
    return this._reference
  }
  get modelDescriptor() {
    return this._modelDescriptor
  }
  static create(e, r, t, o) {
    if (!e)
      return Promise.reject(new ProgrammingError('URL must be specified.'))
    const s =
      'undefined' !== typeof (o = o || {}).credentials ? !!o.credentials : false
    const n = 'undefined' !== typeof o.requestHeaders ? o.requestHeaders : null
    let i =
      'undefined' !== typeof o.requestParameters ? o.requestParameters : null
    if (e.includes('?')) {
      const r = RIAUrl.parseQueryString(e.split('?')[1])
      i = { ...i, ...r }
    }
    e = new window.URL(e, window.location.href).href
    if (i) e = e.split('?')[0] + '?' + RIAUrl.buildQueryString(i)
    const a = addHttpRequestOptions({}, { credentials: s, requestHeaders: n })
    return request(e, a)
      .then((e) => Photon.whenReady().then(() => e.arrayBuffer()))
      .then(
        (o) => {
          const a = new Uint8Array(o)
          return r(a, e, s, n, i)
            .then((r) => {
              let { modelReference: t, modelDescriptor: o } = r
              let u = createOrientedBoxFromRawData(a)
              if (!u)
                throw new ProgrammingError(
                  'Could not decode model: Bounds could not be parsed.'
                )
              if (!t) {
                const e = u.bounds
                t =
                  Math.sqrt(e.x * e.x + e.y * e.y + e.z * e.z) >
                  0.5 * Constants.EARTH_RADIUS
                    ? REF_EPSG_4978
                    : REF_LUCIAD_XYZ
              }
              return {
                url: e,
                modelDescriptor: o,
                orientedBox: new OrientedBox(t, u.getCorners()),
                reference: t,
                credentials: s,
                requestHeaders: n,
                requestParameters: i,
              }
            })
            .then((e) => t(e))
        },
        (e) => {
          throw e
        }
      )
  }
}
function getUrlParametersString(e) {
  const r = e.indexOf('?')
  return -1 === r ? '' : e.substring(r + 1)
}
function createOrientedBoxFromRawData(e) {
  const r = []
  let t
  let o
  try {
    t = Photon.BufferFactory.createUint8BufferFromData(e)
    o = Photon.ScenePainterWrapper.getFrustumFromTilesetJson(t)
    if (!o || !o.typedArray.length)
      throw new Error('Could not decode bounds of data set.')
    for (let e = 0; e < o.typedArray.length / 3; e++)
      r.push({
        x: o.typedArray[3 * e],
        y: o.typedArray[3 * e + 1],
        z: o.typedArray[3 * e + 2],
      })
    if (r.length > 0) return new OrientedBox(null, r)
    return null
  } catch (e) {
    return null
  } finally {
    if (o) o.release()
    if (t) t.release()
  }
}
export function createOrientedBoxFromBounds(e) {
  const r = undefined
  const t = [
    { x: 0, y: 0, z: 0 },
    { x: 1, y: 0, z: 0 },
    { x: 0, y: 1, z: 0 },
    { x: 1, y: 1, z: 0 },
    { x: 0, y: 0, z: 1 },
    { x: 1, y: 0, z: 1 },
    { x: 0, y: 1, z: 1 },
    { x: 1, y: 1, z: 1 },
  ].map((r) => ({
    x: e.x + r.x * e.width,
    y: e.y + r.y * e.height,
    z: e.z + r.z * e.depth,
  }))
  return new OrientedBox(e.reference, t)
}
