import { createHTML5Canvas } from '../view/HTML5Canvas.js'
export function imageColorMultiply(t, e) {
  return createImage(t).then((t) => {
    const a = document.createElement('canvas')
    a.style.display = 'none'
    a.width = t.naturalWidth
    a.height = t.naturalHeight
    const n = a.getContext('2d', { willReadFrequently: true })
    if (null == n) return null
    else {
      n.drawImage(t, 0, 0)
      const r = n.getImageData(0, 0, a.width, a.height)
      for (let t = 0; t < r.data.length; t += 4) {
        r.data[t] *= e.r
        r.data[t + 1] *= e.g
        r.data[t + 2] *= e.b
        r.data[t + 3] *= e.a
      }
      n.putImageData(r, 0, 0)
      return a.toDataURL()
    }
  })
}
function createImage(t) {
  return new Promise((e, a) => {
    const n = new Image()
    n.addEventListener('load', () => e(n))
    n.addEventListener('error', (t) => a(t))
    n.setAttribute('crossOrigin', 'anonymous')
    n.src = t
  })
}
const html5Canvas = createHTML5Canvas(0, 0)
export function resizeImageOrCanvas(t, e, a) {
  return new Promise((n, r) => {
    const o = e ? e : t.width
    const s = a ? a : t.height
    html5Canvas.width = o
    html5Canvas.height = s
    const i = html5Canvas.getContext('2d', { willReadFrequently: true })
    i.drawImage(t, 0, 0, o, s)
    const l = new Image(o, s)
    l.onerror = r
    l.onload = () => {
      i.clearRect(0, 0, o, s)
      n(l)
    }
    l.src = html5Canvas.toDataURL()
  })
}
