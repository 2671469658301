import { attributeLookupFactory, kmlNumberFactory } from './KMLValues.js'
import { DEFAULT_XY_VECTOR, getKmlUnits } from './KMLXYVector.js'
const DEFAULT_HOT_SPOT = DEFAULT_XY_VECTOR
export const getHotSpotValue = function (t) {
  let o =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { ...DEFAULT_HOT_SPOT }
  o.x = getHotSpotX(t)
  o.y = getHotSpotY(t)
  o.xUnits = getHotSpotXUnits(t)
  o.yUnits = getHotSpotYUnits(t)
  return o
}
const getHotSpotNumber = kmlNumberFactory(0.5)
const getHotSpotX = attributeLookupFactory('x', getHotSpotNumber)
const getHotSpotY = attributeLookupFactory('y', getHotSpotNumber)
const getHotSpotXUnits = attributeLookupFactory('xunits', getKmlUnits)
const getHotSpotYUnits = attributeLookupFactory('yunits', getKmlUnits)
