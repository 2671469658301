import { createPhotonColorFromString } from '../../../../util/Color.js'
import { Hash } from '../../../../util/Hash.js'
import { ObjectReleaseTracker } from '../../../../util/ObjectReleaseTracker.js'
import { Photon } from '../../../../gen/photon/photon_painter.js'
import { ShapeUtil } from '../../../../shape/ShapeUtil.js'
import { StyleUtil } from '../../../style/StyleUtil.js'
import { LineMarkerType } from '../../../style/LineMarkerType.js'
import { createPhotonComplexStrokeStyle } from '../../../style/complexstroke/photon/PhotonComplexStrokeUtil.js'
import { isPromise, whenInternal } from '../../../../util/PromiseUtil.js'
import { isNumber } from '../../../../util/Lang.js'
import { BloomStyleImpl, DEFAULT_BLOOM } from '../../../style/BloomStyle.js'
import {
  hasFillTypeShape,
  hasLineTypeShape,
  isExtrudedShape,
  toPhotonDrapeTarget,
} from './PhotonCommandUtil.js'
import { isWorldSize } from '../../../../uom/UnitOfMeasureUtil.js'
import {
  resolveIconStyle,
  resolvePhotonState,
} from '../../../style/complexstroke/photon/PhotonStateResolver.js'
import { getOffset } from '../../../style/OffsetsUtil.js'
import { getZStyle } from './ZStyleUtil.js'
const MAX_ICON_HEIGHT = 510
const PHOTON_LINE_TECH_LINE = 0
const PHOTON_LINE_TECH_QUAD = 1
const PHOTON_AREA_RENDERER = 2
const PHOTON_LINE_TECH_COMPLEXSTROKE = 3
export class PhotonLineAndAreaCommand extends ObjectReleaseTracker {
  constructor(e, t, o, s, i, r, a, n, l, h, d) {
    super()
    this._is3d = e
    this._worldReference = t
    this._shapePainter = o
    this._hash = new Hash()
    this._label = s
    this._maxGLLineWidth = +i
    this._photonImageProvider = a
    this._worldSizeSupport = n
    this._photonShapeDiscretizer = l
    this._canUseEarcut = h
    this._noDiscretization = d
  }
  canHandle(e) {
    const { type: t, style: o } = e
    return 'shape' === t && !(o && o.isTrajectoryStyle) && !(o && o.isPlotStyle)
  }
  add(e) {
    const t = e.shape
    const o = e.style
    const s = e.zStyle
    const i = e.metadata
    let r = null
    let a = null
    try {
      const n = this._shouldNormalizeClosedShapeOrientation(o, this._is3d, t)
      r = this._discretize(
        {
          geometry: t,
          worldReference: this._worldReference,
          lineType: o.lineType,
          zToZero: s.zToZero,
          normalizeOrientation: n,
        },
        i
      )
      if (!r) return
      if (i.isLine && (a = r.lineMesh))
        if (i.shouldRenderLineAsCS) {
          const t = createPhotonComplexStrokeStyle(
            Photon,
            e.styleId,
            o,
            this._photonImageProvider,
            this._worldSizeSupport
          )
          this._shapePainter.addComplexStrokeLine(
            e.objectId,
            e.geometryId,
            e.styleId,
            a,
            t,
            o.zOrder,
            e.renderPassId,
            e.selected,
            toPhotonDrapeTarget(s.drapeTarget),
            s.aboveGround,
            o.occlusionMode || 0,
            o.stroke?.bloom || o.stroke?.strokeStyle?.bloom || DEFAULT_BLOOM
          )
          t.release()
        } else
          this._shapePainter.addLine(
            e.objectId,
            e.geometryId,
            e.styleId,
            a,
            o.stroke.width,
            false,
            createPhotonColorFromString(
              o.stroke.color || 'rgba(255,255,255,1)'
            ),
            o.zOrder,
            e.renderPassId,
            e.selected,
            toPhotonDrapeTarget(s.drapeTarget),
            s.aboveGround,
            o.occlusionMode || 0,
            o.stroke.strokeStyle.bloom
          )
      if (i.isFill && (a = r.fillMesh)) {
        let t = 1
        let i = 1
        if (o.fill.photonImage) {
          t = o.fill.width / o.fill.photonImage.width
          i = o.fill.height / o.fill.photonImage.height
        }
        const {
          worldSizedOffset: r,
          offsetX: n,
          offsetY: l,
        } = getOffset(o.fill, this._worldSizeSupport)
        this._shapePainter.addArea(
          e.objectId,
          e.geometryId,
          e.styleId,
          a,
          createPhotonColorFromString(o.fill.color || 'rgba(255,255,255,1)'),
          o.fill.photonImage,
          t,
          i,
          o.fill.isWorldSized
            ? Photon.ShapeScalingMode.WorldScaling
            : Photon.ShapeScalingMode.ViewScaling,
          this._worldSizeSupport.toMapUnits(o.fill.worldWidth, o.fill.uom),
          this._worldSizeSupport.toMapUnits(o.fill.worldHeight, o.fill.uom),
          r
            ? Photon.ShapeScalingMode.WorldScaling
            : Photon.ShapeScalingMode.ViewScaling,
          n,
          l,
          o.fill.rotation || 0,
          o.fill.opacity || 1,
          o.zOrder,
          e.renderPassId,
          e.selected,
          toPhotonDrapeTarget(s.drapeTarget),
          s.aboveGround,
          o.occlusionMode || 0,
          o.fill.bloom
        )
      }
    } finally {
      a?.release()
      if (r && isNumber(r.sharedRefCount) && --r.sharedRefCount <= 0)
        r.release()
    }
  }
  updateStyle(e, t) {
    const o = e.style
    const s = e.zStyle
    const i = e.metadata
    if (i.isLine && o.stroke)
      if (i.shouldRenderLineAsCS) {
        const i = createPhotonComplexStrokeStyle(
          Photon,
          e.styleId,
          o,
          this._photonImageProvider,
          this._worldSizeSupport
        )
        this._shapePainter.updateComplexStrokeStyle(
          e.objectId,
          e.geometryId,
          t,
          e.styleId,
          i,
          o.zOrder,
          e.renderPassId,
          e.selected,
          toPhotonDrapeTarget(s.drapeTarget),
          s.aboveGround,
          o.occlusionMode || 0,
          o.stroke?.bloom || o.stroke?.strokeStyle?.bloom || DEFAULT_BLOOM
        )
        i.release()
      } else
        this._shapePainter.updateLineStyle(
          e.objectId,
          e.geometryId,
          t,
          e.styleId,
          o.stroke.width,
          false,
          createPhotonColorFromString(o.stroke.color || 'rgba(255,255,255,1)'),
          o.zOrder,
          e.renderPassId,
          e.selected,
          toPhotonDrapeTarget(s.drapeTarget),
          s.aboveGround,
          o.occlusionMode || 0,
          o.stroke.strokeStyle.bloom
        )
    if (i.isFill && o.fill) {
      let i = 1
      let r = 1
      if (o.fill.photonImage) {
        i = o.fill.width / o.fill.photonImage.width
        r = o.fill.height / o.fill.photonImage.height
      }
      const {
        worldSizedOffset: a,
        offsetX: n,
        offsetY: l,
      } = getOffset(o.fill, this._worldSizeSupport)
      this._shapePainter.updateAreaStyle(
        e.objectId,
        e.geometryId,
        t,
        e.styleId,
        createPhotonColorFromString(o.fill.color || 'rgba(255,255,255,1)'),
        o.fill.photonImage,
        i,
        r,
        o.fill.isWorldSized
          ? Photon.ShapeScalingMode.WorldScaling
          : Photon.ShapeScalingMode.ViewScaling,
        this._worldSizeSupport.toMapUnits(o.fill.worldWidth, o.fill.uom),
        this._worldSizeSupport.toMapUnits(o.fill.worldHeight, o.fill.uom),
        a
          ? Photon.ShapeScalingMode.WorldScaling
          : Photon.ShapeScalingMode.ViewScaling,
        n,
        l,
        o.fill.rotation || 0,
        o.fill.opacity || 1,
        o.zOrder,
        e.renderPassId,
        e.selected,
        toPhotonDrapeTarget(s.drapeTarget),
        s.aboveGround,
        o.occlusionMode || 0,
        o.fill.bloom
      )
    }
  }
  updateGeometry(e, t) {
    const o = e.shape
    const s = e.style
    const i = e.zStyle
    const r = e.metadata
    const a = this._shouldNormalizeClosedShapeOrientation(s, this._is3d, o)
    let n, l
    try {
      n = this._discretize(
        {
          geometry: o,
          worldReference: this._worldReference,
          lineType: s.lineType,
          zToZero: i.zToZero,
          normalizeOrientation: a,
        },
        r
      )
      if (!n) {
        this._shapePainter.removeGeometry(e.objectId, t, e.styleId)
        return
      }
      if (r.isLine && (l = n.lineMesh))
        if (r.shouldRenderLineAsCS)
          this._shapePainter.updateComplexStrokeGeometry(
            e.objectId,
            t,
            e.geometryId,
            l
          )
        else
          this._shapePainter.updateLineGeometry(e.objectId, t, e.geometryId, l)
      if (r.isFill && (l = n.fillMesh))
        this._shapePainter.updateAreaGeometry(e.objectId, t, e.geometryId, l)
    } finally {
      l?.release()
      if (n && isNumber(n.sharedRefCount) && --n.sharedRefCount <= 0)
        n.release()
    }
  }
  resolveStyle(e) {
    const t = e.style
    const o = e.density
    const s = ShapeUtil.isShapeClosed(e.shape)
    e.style = this._resolveShapeStyle(t, Photon, o, s)
  }
  getDrawItems(e) {
    const t = []
    for (let o = 0; o < e.length; o++) {
      const s = e[o].shape
      const i = e[o].style
      const r = getZStyle(s, i, this._is3d, this._worldReference, false)
      const a = e[o].renderPassId
      const n = e[o].selected
      const l = this._hasLine(s, i)
      const h = this._hasFill(s, i, this._is3d)
      const d = { needsLine: l, needsFill: h, sharedMesh: null }
      if (l) {
        const e = {
          canUseEarcut: false,
          isComplexStroke: false,
          isExtruded: false,
          isOutline: false,
          noDiscretization: false,
          isLine: true,
          isOutlineForClosedShape: hasFillTypeShape(s, this._is3d),
          shouldRenderLineAsCS: this._shouldRenderWithComplexStroke(
            i,
            this._is3d,
            s
          ),
          sharedDiscretization: d,
        }
        const o = {
          shape: s,
          style: i,
          geometryId: this._getGeometryId(s, i, r, true),
          styleId: this._getStyleId(i, n, a, true, r),
          zStyle: r,
          metadata: e,
        }
        t.push(o)
      }
      if (h) {
        const e = {
          isComplexStroke: false,
          isExtruded: false,
          isOutline: false,
          isLine: false,
          isOutlineForClosedShape: false,
          shouldRenderLineAsCS: false,
          isFill: true,
          canUseEarcut: this._canUseEarcut,
          noDiscretization: this._noDiscretization,
          sharedDiscretization: d,
        }
        const o = {
          shape: s,
          style: i,
          geometryId: this._getGeometryId(s, i, r, false),
          styleId: this._getStyleId(i, n, a, false, r),
          zStyle: r,
          metadata: e,
        }
        t.push(o)
      }
    }
    return t
  }
  usesPhotonShapePainter() {
    return true
  }
  _discretize(e, t) {
    if (!t.sharedDiscretization.sharedMesh) {
      const o = this._canUseEarcut
      const s = this._noDiscretization
      t.sharedDiscretization.sharedMesh =
        this._photonShapeDiscretizer.discretize(
          e.geometry,
          e.worldReference,
          t.sharedDiscretization.needsLine,
          t.sharedDiscretization.needsFill,
          {
            lineType: e.lineType,
            zToZero: e.zToZero,
            normalizeOrientation: e.normalizeOrientation,
            canUseEarcut: o,
            noDiscretization: s,
          }
        )
      if (t.sharedDiscretization.sharedMesh)
        t.sharedDiscretization.sharedMesh.sharedRefCount =
          (t.sharedDiscretization.needsFill ? 1 : 0) +
          (t.sharedDiscretization.needsLine ? 1 : 0)
    }
    return t.sharedDiscretization.sharedMesh
  }
  _getStyleId(e, t, o, s, i) {
    this._hash.reset()
    this._hash.appendUInt32(i.drapeTarget)
    this._hash.appendBoolean(i.aboveGround)
    this._hash.appendUInt32(e.zOrder)
    this._hash.appendUInt32(o)
    this._hash.appendBoolean(t)
    this._hash.appendUInt32(e.occlusionMode)
    this._hash.appendBoolean(s)
    if (s) {
      const t = e.stroke || {}
      if (this._isComplexStroke(e)) {
        const e = t.decorations || []
        for (let t = 0; t < e.length; t++) {
          const o = e[t]
          this._hash.appendDouble(o.location)
          this._hash.appendString(o.alignment)
          o.pattern.appendHash(this._hash)
        }
        const o = t.regular
        if (o) {
          this._hash.appendDouble(o.location)
          o.appendHash(this._hash)
        }
        const s = t.fallback
        if (s) {
          this._hash.appendDouble(s.location)
          s.appendHash(this._hash)
        }
        this._hash.appendDouble(t.sharpAngleThreshold)
        this._hash.appendString(t.lineJoin ?? 'round')
        this._hash.appendBoolean(isWorldSize(t?.uom))
        this._hash.appendString('' + t?.offsetX)
        this._hash.appendString('' + t?.offsetY)
        if (t.uom) this._hash.appendString(t.uom.name)
        if (t.bloom)
          this._hash.appendDouble(
            isNumber(t.bloom.intensity, false)
              ? t.bloom.intensity
              : new BloomStyleImpl({}).intensity
          )
      } else {
        this._hash.appendString(t.color)
        this._hash.appendDouble(t.width)
        if (t.bloom)
          this._hash.appendDouble(
            isNumber(t.bloom.intensity, false)
              ? t.bloom.intensity
              : new BloomStyleImpl({}).intensity
          )
        if (t.uom) this._hash.appendString(t.uom.name)
        if (t.dash)
          for (let e = 0; e < t.dash.length; e++)
            this._hash.appendDouble(+t.dash[e])
        this._hash.appendDouble(t.dashOffset)
        if ('object' === typeof t.beginMarker) {
          this._hash.appendUInt32(51966)
          this._hash.appendString(t.beginMarker.type)
          this._hash.appendUInt32(+t.beginMarker.size)
        }
        if ('object' === typeof t.endMarker) {
          this._hash.appendUInt32(47806)
          this._hash.appendString(t.endMarker.type)
          this._hash.appendUInt32(+t.endMarker.size)
        }
      }
    } else {
      const t = e.fill || {}
      this._hash.appendString(t.color)
      if (t.uom) this._hash.appendString(t.uom.name)
      if (t.bloom)
        this._hash.appendDouble(
          isNumber(t.bloom.intensity, false)
            ? t.bloom.intensity
            : new BloomStyleImpl({}).intensity
        )
      this._hash.appendDouble(t.opacity)
      this._photonImageProvider.imageHashCode(
        t.url || t.image || null,
        t.width,
        t.height,
        false,
        this._hash
      )
      this._hash.appendUInt32(t.rotation)
      this._hash.appendString('' + t.width)
      this._hash.appendString('' + t.height)
      this._hash.appendString('' + t.offsetX)
      this._hash.appendString('' + t.offsetY)
    }
    return this._hash.getHashCode()
  }
  _getGeometryId(e, t, o, s) {
    this._hash.reset()
    e.hashCode(this._hash)
    if (e.reference) e.reference.hashCode(this._hash)
    this._hash.appendBoolean(o.zToZero)
    this._hash.appendDouble(t.lineType)
    this._hash.appendUInt32(o.drapeTarget)
    this._hash.appendBoolean(o.aboveGround)
    if (s) {
      const o = this._shouldRenderWithComplexStroke(t, this._is3d, e)
      const s = isWorldSize(t.stroke?.uom)
      const i = this._isWideLine(t.stroke, this._maxGLLineWidth)
      this._hash.appendBoolean(s)
      this._hash.appendUInt32(
        o
          ? PHOTON_LINE_TECH_COMPLEXSTROKE
          : i || s
          ? PHOTON_LINE_TECH_QUAD
          : PHOTON_LINE_TECH_LINE
      )
    } else {
      this._hash.appendUInt32(PHOTON_AREA_RENDERER)
      this._hash.appendBoolean(t.fill?.url || t.fill?.image || false)
    }
    return this._hash.getHashCode()
  }
  _hasLine(e, t) {
    if (!t.stroke) return false
    return hasLineTypeShape(e)
  }
  _isDashedLine(e) {
    return !!(e && e.dash && e.dash.length > 0)
  }
  _isWideLine(e, t) {
    return !!(e && +e.width > t)
  }
  _isComplexStroke(e) {
    return (
      'undefined' !== typeof e.stroke?.decorations ||
      'undefined' !== typeof e.stroke?.regular ||
      'undefined' !== typeof e.stroke?.fallback
    )
  }
  _hasMarkers(e) {
    const t = e && e.beginMarker && e.beginMarker.type === LineMarkerType.ARROW
    const o = e && e.endMarker && e.endMarker.type === LineMarkerType.ARROW
    return !!(t || o)
  }
  _shouldRenderWithComplexStroke(e, t, o) {
    return (
      this._isDashedLine(e.stroke) ||
      this._isComplexStroke(e) ||
      this._hasMarkers(e.stroke) ||
      isWorldSize(e.stroke?.uom)
    )
  }
  _shouldNormalizeClosedShapeOrientation(e, t, o) {
    return (
      (e.normalizeShapeOrientation || e.innerBorder || e.outerBorder) &&
      !(t && isExtrudedShape(o))
    )
  }
  _hasFill(e, t, o) {
    if (!t.fill || (!t.fill.color && !t.fill.url && !t.fill.image)) return false
    return hasFillTypeShape(e, o)
  }
  _resolveShapeStyle(e, t, o, s) {
    const i = StyleUtil.normalizeShapeStyle(e)
    if (!s) {
      i.innerBorder = void 0
      i.outerBorder = void 0
    }
    let r = null
    if (this._isComplexStroke(e)) {
      const e = i.stroke
      e.worldSizeSupport = this._worldSizeSupport
      const o = resolvePhotonState(t, this._photonImageProvider, e)
      if (isPromise(o))
        r = o.then((e) => {
          i.stroke = e
          return
        })
    }
    let a = null
    if (i.fill && (i.fill.url || i.fill.image)) {
      const e = resolveIconStyle(
        this._photonImageProvider,
        this._hash,
        i.fill,
        o,
        MAX_ICON_HEIGHT,
        true
      )
      a = whenInternal(
        e,
        (e) => {
          i.fill.photonImage = e.photonImage
          i.fill.width = '' + e.width
          i.fill.height = '' + e.height
        },
        (e) => {}
      )
    }
    if (a || r)
      return Promise.all([r, a]).then(function () {
        return i
      })
    else return i
  }
}
