import * as ArcPathUtil from './ArcPathUtil.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { NotImplementedError } from '../../../error/NotImplementedError.js'
import { ellipticalDistance2D } from '../../../util/Cartesian.js'
import {
  simplePointCreate,
  simplePointMove3DToCoordinates,
} from '../../../shape/SimplePoint.js'
export class GeodesicArcPath {
  constructor() {
    this._center = simplePointCreate(0, 0, 0)
    this._A = 0
    this._B = 0
    this._rotAngle = 0
    this._startAzimuth = 0
    this._arcAngle = 0
    this._ellipsoid = null
    this._ratio = 0
    this._shouldReparameterize = false
  }
  getPointAtSFCT(t, i) {
    if (null === this._ellipsoid)
      throw new ProgrammingError(
        'GeodesicArcPAth:: Not correctly initialized, no ellipsoid.'
      )
    if (this._shouldReparameterize)
      t = ArcPathUtil.reparameterizeForEllipse(t, this._ratio)
    const e = this._startAzimuth + t * this._arcAngle
    const r = ellipticalDistance2D(this._A, this._B, e - this._rotAngle)
    this._ellipsoid.geodesicPositionSFCT(this._center, r, e, i)
    return i
  }
  getMinimumRecursionDepth() {
    return 0
  }
  initializeArc(t, i, e, r, s, o, l) {
    simplePointMove3DToCoordinates(this._center, t.x, t.y, t.z)
    this._A = i
    this._B = e
    this._rotAngle = r
    this._startAzimuth = s
    this._arcAngle = o
    if (Math.abs(this._B) > ArcPathUtil.EPSILON) {
      this._ratio = Math.abs(this._A / this._B)
      this._shouldReparameterize = ArcPathUtil.shouldReparameterizeForEllipse(
        90 - this._startAzimuth + this._rotAngle,
        this._arcAngle,
        this._ratio
      )
    } else {
      this._ratio = 1
      this._shouldReparameterize = false
    }
    this._ellipsoid = l.geodeticDatum.ellipsoid
  }
  getEndPosition() {
    throw new NotImplementedError()
  }
  getStartPosition() {
    throw new NotImplementedError()
  }
}
