import { isPoint } from './Point.js'
import { ShapeUtil } from './ShapeUtil.js'
import { LLHPoint } from './LLHPointBounds.js'
import { XYZPoint } from './XYZPointBounds.js'
import { isArray, isNumber } from '../util/Lang.js'
import { coordinate_validate } from './PointCoordinate.js'
export function wrapCoordinateInPoint(o, t) {
  return ShapeUtil.isGeodeticCoordinateType(o ? o.coordinateType : null)
    ? new LLHPoint(o, t, true)
    : new XYZPoint(o, t, true)
}
export function unwrapCoordinateFromPoint(o) {
  const t = o.coordinates
  if (!isNumber(t[2])) t[2] = 0
  return t
}
const isArrayOfPoints = (o) => isPoint(o[0])
export function validatePointRepresentationForCreations() {
  let o = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : []
  let t = arguments.length > 1 ? arguments[1] : void 0
  if (!isArray(o)) return []
  return isArrayOfPoints(o)
    ? o.map((o) => {
        t._compareReferenceStrict(o.reference)
        const [n, e, i] = o.coordinates
        return [n, e, i ?? 0]
      })
    : o.map(coordinate_validate)
}
export function coordinateToSimplePoint(o) {
  return { x: o[0], y: o[1], z: o[2] }
}
export function pointListHashCode(o, t) {
  t.appendUInt32(4407)
  for (let n = 0; n < o.length; n++) {
    t.appendDouble(o[n][0])
    t.appendDouble(o[n][1])
    t.appendDouble(o[n][2])
  }
}
