import { isOfShapeType, Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { ShapeUtil } from './ShapeUtil.js'
import {
  coordinateToSimplePoint,
  pointListHashCode,
  unwrapCoordinateFromPoint,
  wrapCoordinateInPoint,
} from './ShapeWithPointList.js'
import { coordinate_equals } from './PointCoordinate.js'
export class Polygon extends Shape {
  get pointCount() {
    return this._coordinates[0].length
  }
  insertPoint(t, o) {
    this._compareReferenceStrict(o.reference)
    const e = this._coordinates[0]
    ShapeUtil.validateIndexForInsertionOrBounce(t, e)
    e.splice(t, 0, unwrapCoordinateFromPoint(o))
    this.invalidate()
  }
  removePoint(t) {
    const o = this._coordinates[0]
    ShapeUtil.validateIndexForRemovalOrBounce(t, o)
    o.splice(t, 1)
    this.invalidate()
  }
  translate(t, o, e) {
    this.translate3D(t, o, e || 0)
  }
  translate2D(t, o) {
    this.translate3D(t, o, 0)
  }
  translate3D(t, o, e) {
    const i = undefined
    this._coordinates[0].forEach((i) => {
      this.implTranslate(i, t, o, e)
    })
    this.invalidate()
  }
  translatePoint(t, o, e, i) {
    ShapeUtil.validateIndexForRemovalOrBounce(t, this._coordinates[0])
    this.implTranslate(this.getPointCoordinates(t), o, e, i)
    this.invalidate()
  }
  move2DPoint(t, o, e) {
    ShapeUtil.validateIndexForRemovalOrBounce(t, this._coordinates[0])
    this.implMove(this.getPointCoordinates(t), o, e)
    this.invalidate()
  }
  move3DPoint(t, o, e, i) {
    ShapeUtil.validateIndexForRemovalOrBounce(t, this._coordinates[0])
    this.implMove(this.getPointCoordinates(t), o, e, i)
    this.invalidate()
  }
  isValid() {
    return this.pointCount >= 3
  }
  getPoint(t) {
    const o = this._coordinates[0]
    if (o[t]) return wrapCoordinateInPoint(this.reference, o[t])
    return
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    for (let o = 0; o < this._coordinates.length; o++)
      pointListHashCode(this._coordinates[o], t)
  }
  toString() {
    let t = 'Polygon['
    this._coordinates[0].forEach((o) => {
      t += `[${o[0]},${o[1]},${o[2]}]`
    })
    return `${t}]`
  }
  invalidate() {
    this._bounds = void 0
    this._focus = void 0
  }
  equals(t) {
    if (this === t) return true
    if (
      !isOfShapeType(t, ShapeType.POLYGON) ||
      !this._referencesEqual(this._reference, t.reference) ||
      this.pointCount !== t.pointCount
    )
      return false
    const o = this.pointCount
    for (let e = 0; e < o; e++)
      if (
        !coordinate_equals(
          this.getPointCoordinates(e),
          t.getPointCoordinates(e)
        )
      )
        return false
    return true
  }
  get type() {
    return ShapeType.POLYGON
  }
  get coordinates() {
    return this._coordinates
  }
  get focusPoint() {
    if (!this._focus) this._focus = this.bounds.focusPoint
    return this._focus
  }
  contains3DCoordinates(t, o, e) {
    throw new ProgrammingError('Method not implemented.')
  }
  getPointCoordinates(t) {
    return this._coordinates[0][t]
  }
  getCoordinateArray(t) {
    const o = []
    this._coordinates[0].forEach((t) => {
      o.push(...t)
    })
    if (t && this.pointCount > 1) {
      const t = this.getPointCoordinates(0)
      if (!coordinate_equals(t, this.getPointCoordinates(this.pointCount - 1)))
        o.push(...t)
    }
    return o
  }
  getSimplePoint(t) {
    return coordinateToSimplePoint(this.getPointCoordinates(t))
  }
  getSimplePointSFCT(t, o) {
    const e = this._coordinates[0][t]
    o.x = e[0]
    o.y = e[1]
    o.z = e[2]
  }
  getSimplePoints() {
    return this._coordinates[0].map(coordinateToSimplePoint)
  }
  copyRing() {
    return this._coordinates[0].map((t) => [t[0], t[1], t[2]])
  }
}
export function isPolygon(t) {
  return t && t.type === ShapeType.POLYGON
}
