import { ShapeType } from '../../../shape/ShapeType.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { Editor } from '../Editor.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultSize,
} from '../handles/CreateByTemplateHandle.js'
export class SectorEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.SECTOR, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createRadiusStartHandle(e),
      this.createRadiusEndHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.center,
      (e) => t.move2DToPoint(e)
    )
  }
  createRadiusStartHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.radiusStartCorner,
      (e) => {
        t.updateRadius(e)
        t.updateStartAzimuth(e)
      }
    )
  }
  createRadiusEndHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.radiusEndCorner,
      (e) => {
        t.updateRadius(e)
        t.updateEndAngle(e)
      }
    )
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, t) => {
      const a = e.shape
      a.move2DToPoint(t)
      a.radius = getDefaultSize(e.map)
    })
  }
}
