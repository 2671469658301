import { ObjectReleaseTracker } from '../../../util/ObjectReleaseTracker.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { Photon } from '../../../gen/photon/photon_painter.js'
import { PhotonWeakMap } from './PhotonWeakMap.js'
import { isPromise, whenInternal } from '../../../util/PromiseUtil.js'
import { PhotonMeshVideoTexture } from '../../../geometry/mesh/PhotonMeshVideoTexture.js'
function PhotonMeshProvider(e, r, o, t, n) {
  this._meshCache = this.track(new PhotonWeakMap())
  this._shapePainter = e
  this._imageProvider = r
  this._gltfDecoder = o
  this._photonGraphics = t
  this._invalidator = n
  this._videoTexturesCache = this.track(new PhotonWeakMap())
}
PhotonMeshProvider.prototype = Object.create(ObjectReleaseTracker.prototype)
PhotonMeshProvider.prototype.constructor = PhotonMeshProvider
PhotonMeshProvider.prototype.getPhotonMesh = function (e) {
  var r = this
  var o
  if (e.id) o = e.id
  else o = e
  var t = this._meshCache.get(o)
  if (t)
    if (isPromise(t))
      return whenInternal(t).then(function (o) {
        if (r.isReleased()) throw new ProgrammingError('Mesh request aborted')
        return r.getPhotonMesh(e)
      })
    else return t
  else if (e.id) {
    var n = this._getMeshPtrOrPromise(r, e, o)
    r._meshCache.set(o, n)
    return n
  } else {
    var a = this._getGLTFMeshPromise(r, e, o)
    this._meshCache.set(o, a)
    return a
  }
}
PhotonMeshProvider.prototype.refreshVideoTexture = function () {
  this._videoTexturesCache._map.forEach(function (e, r, o) {
    e.updateTexture()
  })
}
PhotonMeshProvider.prototype._getMeshPtrOrPromise = function (e, r, o) {
  var t = new ObjectReleaseTracker()
  var n = t.track(e._toPhotonPositions(r.positions))
  var a = t.track(e._toPhotonIndices(r.indices))
  var s = t.track(
    r.normals ? e._toPhotonNormals(r.normals) : e._toPhotonNormals([])
  )
  var h = Photon.PrimitiveType.Triangles
  var i = t.track(
    r.colors ? e._toPhotonColors(r.colors) : e._toPhotonColors([])
  )
  var c = t.track(
    r.texCoords
      ? e._toPhotonTextureCoordinates(r.texCoords)
      : e._toPhotonTextureCoordinates([])
  )
  var P = t.track(r.images ? r.images[0] : null)
  if (P)
    if (P instanceof HTMLVideoElement) {
      const r = new PhotonMeshVideoTexture(P)
      const u = r.getFrameAsTexture(this._photonGraphics, e._invalidator)
      const f = e._shapePainter.createMesh(n, a, s, h, i, c, null, u)
      e._meshCache.set(o, f)
      this._videoTexturesCache.set(o, r)
      t.release()
      return f
    } else {
      var u = e._imageProvider.getPhotonImage(P, true, false, true, false)
      if (isPromise(u))
        return whenInternal(u).then(function (r) {
          t.track(r)
          var P = e._shapePainter.createMesh(n, a, s, h, i, c, r)
          t.release()
          e._meshCache.set(o, P)
          return P
        })
      else {
        t.track(u)
        var f = e._shapePainter.createMesh(n, a, s, h, i, c, u)
        t.release()
        e._meshCache.set(o, f)
        return f
      }
    }
  else {
    var f = this._shapePainter.createMesh(n, a, s, h, i, c, P)
    t.release()
    return f
  }
}
PhotonMeshProvider.prototype._getGLTFMeshPromise = function (e, r, o) {
  var t = this._gltfDecoder.decode(r)
  var n
  return whenInternal(t).then(
    function (r) {
      var t = Photon.BufferFactory.createUint8BufferFromData(
        new Uint8Array(r.arrayBuffer)
      )
      var n = e._shapePainter.createMeshFromSerializedMeshData(t)
      e._meshCache.set(o, n)
      t.release()
      return n
    },
    function (e) {
      throw e
    }
  )
}
PhotonMeshProvider.prototype.meshHashCode = function (e, r) {
  if (e.id) r.append('mesh-' + e.id)
  else r.appendString(e)
}
PhotonMeshProvider.prototype.clean = function () {
  const e = undefined
  this._meshCache.purge().forEach((e) => {
    this._videoTexturesCache.remove(e)
  })
}
PhotonMeshProvider.prototype._toPhotonPositions = function (e) {
  return Photon.BufferFactory.createFloat64BufferFromData(new Float64Array(e))
}
PhotonMeshProvider.prototype._toPhotonIndices = function (e) {
  return Photon.BufferFactory.createUint32BufferFromData(new Uint32Array(e))
}
PhotonMeshProvider.prototype._toPhotonNormals = function (e) {
  return Photon.BufferFactory.createFloat32BufferFromData(new Float32Array(e))
}
PhotonMeshProvider.prototype._toPhotonColors = function (e) {
  return Photon.BufferFactory.createFloat32BufferFromData(new Float32Array(e))
}
PhotonMeshProvider.prototype._toPhotonTextureCoordinates = function (e) {
  return Photon.BufferFactory.createFloat32BufferFromData(new Float32Array(e))
}
export { PhotonMeshProvider }
