import { CoordinateType } from '../reference/CoordinateType.js'
import { PolygonOrientation } from './PolygonOrientation.js'
import { XYZBounds } from './XYZBounds.js'
import { Polygon } from './Polygon.js'
import { orientation2D } from '../util/Cartesian.js'
import { validatePointRepresentationForCreations } from './ShapeWithPointList.js'
import { isNumber } from '../util/Lang.js'
export class XYZPolygon extends Polygon {
  constructor(t, o) {
    super()
    this._reference = this.validateReference(t)
    this._orientation = PolygonOrientation.INVALID_ORIENTATION
    this._coordinates = [validatePointRepresentationForCreations(o, this)]
  }
  get isGeodetic() {
    return false
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  get bounds() {
    if (0 === this.pointCount) return null
    if (!this._bounds) {
      const t = new XYZBounds(this.reference)
      const o = this._coordinates[0]
      const n = o.length
      const e = o[0]
      t.move3DToCoordinates(...e)
      for (let e = 1; e < n; e++) t.includeCoordinate3D(...o[e])
      this._bounds = t
    }
    return this._bounds
  }
  get orientation() {
    if (this._orientation === PolygonOrientation.INVALID_ORIENTATION)
      this._orientation = orientation2D(super.getSimplePoints())
    return this._orientation
  }
  set orientation(t) {
    this._orientation = t
  }
  copy() {
    return new XYZPolygon(this.reference, super.copyRing())
  }
  contains2DCoordinates(t, o) {
    const n = this.pointCount
    if (n <= 2 || !this.bounds.contains2DCoordinates(t, o)) return false
    let e
    let i
    let r
    let s
    let a
    let l = 0
    const u = t
    const c = o
    let { x: f, y: d } = this.getPoint(n - 1)
    for (let t = 0; t < n; f = e, d = i, t++) {
      const o = this.getPoint(t)
      e = o.x
      i = o.y
      if (i === d) continue
      if (e < f) {
        if (u >= f) continue
        r = e
      } else {
        if (u >= e) continue
        r = f
      }
      if (i < d) {
        if (c < i || c >= d) continue
        if (u < r) {
          l++
          continue
        }
        s = u - e
        a = c - i
      } else {
        if (c < d || c >= i) continue
        if (u < r) {
          l++
          continue
        }
        s = u - f
        a = c - d
      }
      if (s < (a / (d - i)) * (f - e)) l++
    }
    return 0 !== (1 & l)
  }
  implMove(t, o, n, e) {
    t[0] = o
    t[1] = n
    if (isNumber(e)) t[2] = e
  }
  implTranslate(t, o, n) {
    let e = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0
    t[0] += o
    t[1] += n
    t[2] += e
  }
}
