import { isUndefined } from '../../util/Lang.js'
import {
  isLengthUom,
  isPixelValue,
  parseValueAndUom,
  PIXEL_UOM,
} from '../../uom/UnitOfMeasureUtil.js'
export const DEFAULT_NORMALIZED_OFFSETS = {
  normalizedOffsetX: {
    offsetUom: PIXEL_UOM,
    pixelOffset: 0,
    worldOffset: 0,
    isWorldOffset: false,
  },
  normalizedOffsetY: {
    offsetUom: PIXEL_UOM,
    pixelOffset: 0,
    worldOffset: 0,
    isWorldOffset: false,
  },
}
export function saveAndOffsetContext(e, f, t) {
  e.save()
  const o = getOffsetXInPixels(f, t)
  const s = getOffsetYInPixels(f, t)
  if (0 !== o || 0 !== s) e.translate(o, s)
}
function getOffsetXInPixels(e, f) {
  return f && e.normalizedOffsetX.isWorldOffset
    ? f.toPixels(e.normalizedOffsetX.worldOffset, e.normalizedOffsetX.offsetUom)
    : e.normalizedOffsetX.pixelOffset
}
function getOffsetYInPixels(e, f) {
  return f && e.normalizedOffsetY.isWorldOffset
    ? f.toPixels(e.normalizedOffsetY.worldOffset, e.normalizedOffsetY.offsetUom)
    : e.normalizedOffsetY.pixelOffset
}
export function getPerpendicularOffsetInPixels(e, f) {
  return f && e.isWorldOffset
    ? f.toPixels(e.worldOffset, e.offsetUom)
    : e.pixelOffset
}
export function normalizePerpendicularOffset(e, f) {
  return normalizeOffsetSize(e, 'perpendicularOffset', f)
}
export const getOffset = (e, f) => {
  const t = e.normalizedOffsets.normalizedOffsetX.isWorldOffset
  const o = t
    ? e.normalizedOffsets.normalizedOffsetX.worldOffset
    : e.normalizedOffsets.normalizedOffsetX.pixelOffset
  const s = t
    ? e.normalizedOffsets.normalizedOffsetY.worldOffset
    : e.normalizedOffsets.normalizedOffsetY.pixelOffset
  return {
    worldSizedOffset: t,
    offsetX: f.toMapUnits(o, e.normalizedOffsets.normalizedOffsetX.offsetUom),
    offsetY: f.toMapUnits(s, e.normalizedOffsets.normalizedOffsetY.offsetUom),
  }
}
export function normalizeOffsetSizes(e, f, t) {
  const o = normalizeOffsetSize(e, 'offsetX', t)
  const s = normalizeOffsetSize(f, 'offsetY', t)
  if (isUndefined(e) && s.isWorldOffset) {
    o.isWorldOffset = true
    o.offsetUom = s.offsetUom
    o.pixelOffset = 0
    o.worldOffset = 0
  }
  if (isUndefined(f) && o.isWorldOffset) {
    s.isWorldOffset = true
    s.offsetUom = o.offsetUom
    s.pixelOffset = 0
    s.worldOffset = 0
  }
  if (
    (!o.isWorldOffset && s.isWorldOffset) ||
    (o.isWorldOffset && !s.isWorldOffset)
  )
    throw new Error(
      `One of IconStyle.offsetX (=${e}) and IconStyle.offsetY (=${f}) is interpreted as a pixel size, while the other is not. You cannot mix pixel and world offsets in an IconStyle.`
    )
  return { normalizedOffsetX: o, normalizedOffsetY: s }
}
function normalizeOffsetSize(e, f, t) {
  const o = isPixelValue(e, t, false)
  if (isUndefined(e) || o) {
    const f = undefined
    return {
      isWorldOffset: false,
      pixelOffset: parseValueAndUom(e ?? 0).value,
      worldOffset: 0,
      offsetUom: PIXEL_UOM,
    }
  }
  const s = parseValueAndUom(e)
  if (!t && !isLengthUom(s.uom))
    throw new Error(
      `IconStyle.${f} (=${e}) has no uom of kind "Length". Set IconStyle.uom or use a length unit symbol in the ${f} string (e.g. "50m").`
    )
  const n = t ?? s.uom
  if (!n)
    throw new Error(
      `Could not determine unit of measure for IconStyle with ${f}: ${e}and uom: ${t}. Set IconStyle.uom to a "Length" uom (e.g. getUnitOfMeasure("Meter")) or define a length unit in the ${f} string (e.g. "50m")`
    )
  if (!isLengthUom(n))
    throw new Error(
      `IconStyle with ${f}: ${e} and uom: ${t} has no unit of measure of type "Length". Set IconStyle.uom to a "Length" uom (e.g. getUnitOfMeasure("Meter")) or use a length unit symbol in the ${f} string (e.g. "50m")`
    )
  let i = s.value
  if (s.uom && s.uom !== n) i = s.uom.convertToUnit(s.value, n)
  return { isWorldOffset: true, pixelOffset: 0, worldOffset: i, offsetUom: n }
}
