import { createBounds } from '../../shape/ShapeFactory.js'
import { clamp as clampNumber } from '../../util/Cartesian.js'
var LEVEL_EPSILON = 1e-6
function _getLevelForPD(e, t) {
  return Math.log(e / t) / Math.log(2)
}
function _getScaleForLevelPD(e, t) {
  return Math.pow(2, e) * t
}
function getXLevel(e, t) {
  return _getLevelForPD(e, t.getPixelDensity(0)[0])
}
export var TileCoordinateUtil = {
  _getScaleByLevelPixelDensity: _getScaleForLevelPD,
  _getLevelByScalePixelDensity: _getLevelForPD,
  _getLevel: getXLevel,
  getGrid: function (e, t, r, a) {
    this.getGridWithClamping(e, t, r, true, a)
  },
  getGridWithClamping: function (e, t, r, a, o) {
    var i = getXLevel(r[0], e)
    var n = i % 1
    if (Math.abs(n) <= LEVEL_EPSILON) i = Math.round(i)
    var l = Math.min(Math.max(0, Math.ceil(i)), e.levelCount - 1)
    var u = e.bounds
    var v = e.getTileColumnCount(l)
    var h = e.getTileRowCount(l)
    var g = t.x - u.x
    var c = t.y - u.y
    var m = g + t.width
    var f = c + t.height
    var p = u.width / v
    var s = u.height / h
    var d = Math.floor(g / p)
    var L = Math.floor(c / s)
    var M = Math.floor(m / p)
    var y = Math.floor(f / s)
    if (a) {
      var P = v - 1
      d = clampNumber(d, 0, P)
      M = clampNumber(M, 0, P)
      var C = h - 1
      L = clampNumber(L, 0, C)
      y = clampNumber(y, 0, C)
    }
    o.x = d
    o.y = L
    o.width = M - d + 1
    o.height = y - L + 1
    o.level = l
  },
  getTileBounds: function (e, t, r) {
    return this.computeTileBounds(
      e,
      t.level,
      t.x,
      t.width || 1,
      t.y,
      t.height || 1,
      r
    )
  },
  computeTileBounds: function (e, t, r, a, o, i, n) {
    var l = e.getBounds(t)
    var u = e.getTileRowCount(t)
    var v = e.getTileColumnCount(t)
    var h = l.width / v
    var g = l.height / u
    if (!n) n = l.copy()
    n.setTo2D(l.x + h * r, h * a, l.y + g * o, g * i)
    return n
  },
  getParent: function (e, t, r) {
    var a = e - 1,
      o = Math.pow(2, e - a),
      i = []
    if (a >= 0) {
      i[0] = a
      i[1] = Math.floor(t / o)
      i[2] = Math.floor(r / o)
    }
    return i
  },
  getBitmapBoundsForParent: (function () {
    var e = []
    function t(t, r, a, o) {
      var i,
        n = r - t * o,
        l = a / o
      e[0] = n * l
      e[1] = l
    }
    return function (r, a, o, i, n, l, u, v) {
      var h = i - r,
        g = Math.pow(2, h),
        c = []
      t(a, n, u, g)
      c[0] = e[0]
      c[1] = e[1]
      t(o, l, v, g)
      c[2] = e[0]
      c[3] = e[1]
      return c
    }
  })(),
}
