import { SimpleBounds } from './SimpleBounds.js'
import { RTreeNode } from './RTreeNode.js'
import { CoordinateType } from '../../reference/CoordinateType.js'
export class RTree {
  constructor(e) {
    this._root = null
    this._size = 0
    this.coordinateType =
      e === CoordinateType.CARTESIAN ? SimpleBounds.XY : SimpleBounds.LL
  }
  addElement(e) {
    const o = undefined
    if (!e.bounds.isValid()) return false
    const t = this.coordinateType
    if (!this._root)
      this._root = new RTreeNode({ firstBounds: e, coordType: t })
    else {
      const o = this._root.add(e)
      if (o)
        this._root = new RTreeNode({
          firstChild: this._root,
          secondChild: o,
          coordType: t,
        })
    }
    this._size++
    return true
  }
  removeElement(e) {
    if (!this._root) return false
    const o = this._root.remove(e, false)
    if (o) this._size--
    if (o && 0 === this._root.getChildCount()) this._root = null
    return o
  }
  applyOnInteract2DBounds(e, o) {
    if (this._root) RTreeNode.applyOnInteract2DBounds(this._root, e, o)
  }
  applyToElements(e) {
    if (this._root) RTreeNode.applyToElements(this._root, e)
  }
  get size() {
    return this._size
  }
  get root() {
    return this._root
  }
}
