import { isNumber } from '../util/Lang.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { buildCache } from '../util/Cacher.js'
import { normalizeAngle as CartesianNormalizeAngle } from '../util/Cartesian.js'
export class ArcBandSupport {
  constructor(t, e, i, a, r, s, n, h) {
    this._center = e.copy()
    if (!isNumber(i))
      throw new ProgrammingError(`${n}::cannot create a without minRadius`)
    this._minRadius = i
    if (!isNumber(a))
      throw new ProgrammingError(`${n}::cannot create a without maxRadius`)
    this._maxRadius = a
    if (!isNumber(r))
      throw new ProgrammingError(`${n}::cannot create a without startAzimuth`)
    this._startAzimuth = CartesianNormalizeAngle(r)
    if (!isNumber(s))
      throw new ProgrammingError(`${n}::cannot create a without sweepAngle`)
    this._sweepAngle = normalizeAngle(s, -360)
    this._arcBandOrSector = h
    this._cache = buildCache()
  }
  move2DToCoordinates(t, e) {
    const i = t - this.focusPoint.x
    const a = e - this.focusPoint.y
    this.translate2D(i, a)
  }
  move2DToPoint(t) {
    this._arcBandOrSector._compareReference(t.reference, t.coordinateType)
    this.move2DToCoordinates(t.x, t.y)
  }
  translate2D(t, e) {
    this.center.translate2D(t, e)
    this._cache.invalidate()
  }
  invalidate() {
    this._cache.invalidate()
  }
  hashCode(t) {
    t.appendUInt32(this._arcBandOrSector.type)
    this.center.hashCode(t)
    t.appendDouble(this.minRadius)
    t.appendDouble(this.maxRadius)
    t.appendDouble(this.startAzimuth)
    t.appendDouble(this.sweepAngle)
  }
  updateStartAzimuth(t) {
    let e = this._arcBandOrSector.forwardAzimuth2D(this.center, t)
    let i = this._arcBandOrSector.forwardAzimuth2D(
      this.center,
      this.maxRadiusEndCorner
    )
    e = normalizeAngle(e, 0)
    i = normalizeAngle(i, 0)
    let a = i - e
    while (a <= 0) a += 360
    this.startAzimuth = e
    this.sweepAngle = a
  }
  updateEndAngle(t) {
    const e = normalizeAngle(this.startAzimuth, 0)
    const i = undefined
    const a = undefined
    let r =
      normalizeAngle(
        this._arcBandOrSector.forwardAzimuth2D(this.center, t),
        0
      ) - e
    while (r <= 0) r += 360
    this.sweepAngle = r
  }
  updateMinRadius(t) {
    this.minRadius = Math.min(
      this.maxRadius,
      this._arcBandOrSector.distance2D(this.center, t)
    )
  }
  updateMaxRadius(t) {
    this.maxRadius = Math.max(
      this.minRadius,
      this._arcBandOrSector.distance2D(this.center, t)
    )
  }
  contains3DCoordinates(t, e, i) {
    throw new ProgrammingError(
      'contains3DCoordinates can not be called on a 2D ArcBand or Sector'
    )
  }
  get center() {
    return this._center
  }
  get focusPoint() {
    return this._center
  }
  get startAzimuth() {
    return this._startAzimuth
  }
  set startAzimuth(t) {
    this._startAzimuth = t
    this._cache.invalidate()
  }
  get sweepAngle() {
    return this._sweepAngle
  }
  set sweepAngle(t) {
    this._sweepAngle = normalizeAngle(t, -360)
    this._cache.invalidate()
  }
  get bounds() {
    return this._cache.memoize('cached-bounding-box', () =>
      this._arcBandOrSector.calculateBounds()
    )
  }
  get minRadius() {
    return this._minRadius
  }
  set minRadius(t) {
    this._arcBandOrSector._checkParams(t, this._maxRadius)
    this._minRadius = t
    this._cache.invalidate()
  }
  get maxRadius() {
    return this._maxRadius
  }
  set maxRadius(t) {
    this._arcBandOrSector._checkParams(this._minRadius, t)
    this._maxRadius = t
    this._cache.invalidate()
  }
  get minRadiusStartCorner() {
    return this._cache.memoize('min-radius-start-corner', () =>
      this._arcBandOrSector.calculatePoint(this.minRadius, this.startAzimuth)
    )
  }
  get minRadiusEndCorner() {
    return this._cache.memoize('min-radius-end-corner', () =>
      this._arcBandOrSector.calculatePoint(
        this.minRadius,
        this.startAzimuth + this.sweepAngle
      )
    )
  }
  get maxRadiusStartCorner() {
    return this._cache.memoize('max-radius-start-corner', () =>
      this._arcBandOrSector.calculatePoint(this.maxRadius, this.startAzimuth)
    )
  }
  get maxRadiusEndCorner() {
    return this._cache.memoize('max-radius-end-corner', () =>
      this._arcBandOrSector.calculatePoint(
        this.maxRadius,
        this.startAzimuth + this.sweepAngle
      )
    )
  }
  get cache() {
    return this._cache
  }
  set cache(t) {
    this._cache = t
  }
}
function normalizeAngle(t, e) {
  while (t < e) t += 360
  while (t > 360) t -= 360
  return t
}
