import { Lang } from '../../util/Lang.js'
import { CapabilitiesParserUtil } from './common/CapabilitiesParserUtil.js'
import { WMSCapabilitiesLayer } from './WMSCapabilitiesLayer.js'
function WMSCapabilitiesParser(e, i) {
  this._raw = e
  for (var t = 0; t < i.length; t++)
    if (Lang.isDefined(e[i[t]])) this._rawRoot = e[i[t]]
  this._layers = []
}
WMSCapabilitiesParser.prototype = Object.create(Object.prototype)
WMSCapabilitiesParser.prototype.constructor = WMSCapabilitiesParser
WMSCapabilitiesParser.prototype.getRawCapabilities = function () {
  return this._raw
}
WMSCapabilitiesParser.prototype.parseServiceMetadata = function () {
  var e = this._rawRoot.Service
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    name: e.Name,
    title: e.Title,
    abstract: e.Abstract,
    keywords: e.KeywordList && e.KeywordList.Keyword,
    url: e.OnlineResource && e.OnlineResource.href,
    contactInformation: this._parseContactInformation(e.ContactInformation),
    fees: 'none' !== e.Fees ? e.Fees : null,
    accessConstraints:
      'none' !== e.AccessConstraints ? e.AccessConstraints : null,
    layerLimit: e.LayerLimit,
    maxWidth: e.MaxWidth,
    maxHeight: e.MaxHeight,
  })
}
WMSCapabilitiesParser.prototype._parseContactInformation = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    primaryPerson: this._parsePerson(e.ContactPersonPrimary),
    position: e.ContactPosition,
    address: this._parseContactAddress(e.ContactAddress),
    telephone: e.ContactVoiceTelephone,
    fax: e.ContactFacsimileTelephone,
    email: e.ContactElectronicMailAddress,
  })
}
WMSCapabilitiesParser.prototype._parsePerson = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    person: e.ContactPerson,
    organization: e.ContactOrganization,
  })
}
WMSCapabilitiesParser.prototype._parseContactAddress = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    type: e.AddressType,
    address: e.Address,
    city: e.City,
    stateOrProvince: e.StateOrProvince,
    postCode: e.PostCode,
    country: e.Country,
  })
}
WMSCapabilitiesParser.prototype.parseUpdateSequence = function () {
  return this._rawRoot.updateSequence
}
WMSCapabilitiesParser.prototype.parseLayers = function () {
  var e = this._rawRoot.Capability.Layer
  if (e)
    for (var i = 0; i < e.length; i++) {
      var t = this._parseLayer(e[i], {})
      t.setParent(null)
      this._layers.push(t)
    }
  return this._layers
}
WMSCapabilitiesParser.prototype._parseLayer = function (e, i) {
  if (!Lang.isDefined(e)) return null
  i = this._createLayerInheritanceOptions(e, i)
  var t = new WMSCapabilitiesLayer({
    swapAxes: this._swapAxes,
    children: (e.Layer || []).map(function (e) {
      return this._parseLayer(e, i)
    }, this),
    title: e.Title,
    name: e.Name,
    styles: i.styles || [],
    supportedReferences: i.references || [],
    queryable: i.queryable || false,
    cascaded: i.cascaded || 0,
    opaque: i.opaque || false,
    noSubsets: i.noSubsets || false,
    fixedWidth: i.fixedWidth || 0,
    fixedHeight: i.fixedHeight || 0,
    abstract: e.Abstract,
    keywords: e.KeywordList && e.KeywordList.Keyword,
    attribution: i.Attribution,
    metadata: (e.MetadataURL || []).map(function (e) {
      return this._parseResourceWithFormat(e)
    }, this),
    identifiers: (e.Identifier || []).map(function (e) {
      return this._parseIdentifier(e, i)
    }, this),
    featureListURL: this._parseResourceWithFormat(
      e.FeatureListURL && e.FeatureListURL[0]
    ),
    dataURL: this._parseResourceWithFormat(e.DataURL && e.DataURL[0]),
    dimensions: i.dimensions || [],
    wgs84Bounds: i.wgs84Bounds,
    bounds: i.bounds || [],
    minScaleDenominator: i.scaleDenominator && i.scaleDenominator.min,
    maxScaleDenominator: i.scaleDenominator && i.scaleDenominator.max,
  })
  t.children.forEach(function (e) {
    e.setParent(t)
  })
  return t
}
WMSCapabilitiesParser.prototype._createLayerInheritanceOptions = function (
  e,
  i
) {
  i = Object.assign({}, i)
  if (Lang.isDefined(e.AuthorityURL)) {
    var t = e.AuthorityURL.map(this._parseAuthority, this)
    var r = i.authorities || []
    i.authorities = this._inheritArrayByAdd(t, r, function (e, i) {
      return e.name === i.name
    })
  }
  if (Lang.isDefined(e.Attribution))
    i.attribution = this._parseAttribution(e.Attribution && e.Attribution[0])
  if (Lang.isDefined(e.Style)) {
    var a = e.Style.map(this._parseStyle, this)
    var n = i.styles || []
    i.styles = this._inheritArrayByAdd(a, n, function (e, i) {
      return e.name === i.name
    })
  }
  var s = e[this._referenceFieldName]
  if (Lang.isDefined(s)) {
    var o = i.references || []
    i.references = this._inheritArrayByAdd(s, o, function (e, i) {
      return e === i
    })
  }
  if (this._hasWGS84Bounds(e)) i.wgs84Bounds = this._parseWGS84Bounds(e)
  if (this._hasBoundsList(e)) {
    var u = this._parseBoundsList(e)
    var p = i.bounds || []
    i.bounds = this._inheritArrayByReplace(u, p, function (e, i) {
      return e.reference === i.reference
    })
  }
  if (Lang.isDefined(e.Dimension)) {
    var l = e.Dimension.map(this._parseDimension, this)
    var f = i.dimensions || []
    i.dimensions = this._inheritArrayByReplace(l, f, function (e, i) {
      return e.name === i.name
    })
  }
  var c = this._parseLayerScaleDenominator(e)
  if (Lang.isDefined(c)) i.scaleDenominator = c
  if (Lang.isDefined(e.queryable)) i.queryable = e.queryable
  if (Lang.isDefined(e.cascaded)) i.cascaded = e.cascaded
  if (Lang.isDefined(e.opaque)) i.opaque = e.opaque
  if (Lang.isDefined(e.noSubsets)) i.noSubsets = e.noSubsets
  if (Lang.isDefined(e.fixedWidth)) i.fixedWidth = e.fixedWidth
  if (Lang.isDefined(e.fixedHeight)) i.fixedHeight = e.fixedHeight
  return i
}
WMSCapabilitiesParser.prototype._parseBounds = function (e) {
  if (!e) return null
  var i
  return {
    reference: e[this._referenceFieldName],
    x: e.minx,
    y: e.miny,
    width: e.maxx - e.minx,
    height: e.maxy - e.miny,
    resolutionX: e.resx,
    resolutionY: e.resy,
  }
}
WMSCapabilitiesParser.prototype._parseStyle = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    name: e.Name,
    title: e.Title,
    abstract: e.Abstract,
    legend: (e.LegendURL || []).map(function (e) {
      return this._parseImageUrl(e)
    }, this),
  })
}
WMSCapabilitiesParser.prototype._parseAttribution = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    url: e.OnlineResource && e.OnlineResource.href,
    title: e.title,
    logo: this._parseImageUrl(e.LogoURL),
  })
}
WMSCapabilitiesParser.prototype._parseAuthority = function (e) {
  return CapabilitiesParserUtil.freeze({
    name: e.name,
    url: e.OnlineResource && e.OnlineResource,
  })
}
WMSCapabilitiesParser.prototype._parseIdentifier = function (e, i) {
  if (!Lang.isDefined(e)) return null
  var t = this._findAuthority(i.authorities, e.authority)
  return CapabilitiesParserUtil.freeze({ authority: t, identifier: e['#text'] })
}
WMSCapabilitiesParser.prototype._findAuthority = function (e, i) {
  if (!Lang.isDefined(e)) return null
  for (var t = 0; t < e.length; t++) if (e[t].name === i) return e[t]
  return null
}
WMSCapabilitiesParser.prototype._parseResourceWithFormat = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    type: e.Format,
    url: e.OnlineResource && e.OnlineResource.href,
  })
}
WMSCapabilitiesParser.prototype._parseImageUrl = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    width: e.width,
    height: e.height,
    format: e.Format,
    url: e.OnlineResource && e.OnlineResource.href,
  })
}
WMSCapabilitiesParser.prototype._parseDimension = function (e) {
  if (!Lang.isDefined(e)) return null
  return CapabilitiesParserUtil.freeze({
    name: e.name,
    units: e.units,
    extent: e['#text'],
    unitSymbol: e.unitSymbol,
    default: e['default'],
    multipleValues: e.multipleValues,
    nearestValue: e.nearestValue,
    current: e.current,
  })
}
WMSCapabilitiesParser.prototype.parseOperations = function () {
  var e = []
  var i = this._rawRoot.Capability.Request
  var t = this._parseOperationTypeSchema(i.GetCapabilities, 'GetCapabilities')
  var r = this._parseOperationTypeSchema(i.GetMap, 'GetMap')
  var a = this._parseOperationTypeSchema(i.GetFeatureInfo, 'GetFeatureInfo')
  e.push(t)
  e.push(r)
  if (a) e.push(a)
  return Object.freeze(e)
}
WMSCapabilitiesParser.prototype._parseOperationTypeSchema = function (e, i) {
  if (!Lang.isDefined(e)) return null
  var t = []
  var r = e.DCPType && e.DCPType[0]
  if (r.HTTP && r.HTTP.Get)
    t.push({
      method: 'GET',
      url: r.HTTP.Get.OnlineResource && r.HTTP.Get.OnlineResource.href,
    })
  if (r.HTTP && r.HTTP.Post)
    t.push({
      method: 'POST',
      url: r.HTTP.Post.OnlineResource && r.HTTP.Post.OnlineResource.href,
    })
  return CapabilitiesParserUtil.freeze({
    name: i,
    supportedFormats: e.Format,
    supportedRequests: t,
  })
}
WMSCapabilitiesParser.prototype.parseExceptionFormats = function () {
  var e = this._rawRoot.Capability.Exception
  if ('undefined' !== typeof e) return CapabilitiesParserUtil.freeze(e.Format)
  else return CapabilitiesParserUtil.freeze([])
}
WMSCapabilitiesParser.prototype.parseUserDefinedSymbolization = function () {
  var e = this._rawRoot.Capability.UserDefinedSymbolization || {}
  return CapabilitiesParserUtil.freeze({
    supportSLD: !!e.supportSLD,
    userLayer: !!e.userLayer,
    userStyle: !!e.userStyle,
    remoteWFS: !!e.remoteWFS,
  })
}
WMSCapabilitiesParser.prototype._inheritArrayByAdd = function (e, i, t) {
  return this._inheritArray(e, i, t, false)
}
WMSCapabilitiesParser.prototype._inheritArrayByReplace = function (e, i, t) {
  return this._inheritArray(e, i, t, true)
}
WMSCapabilitiesParser.prototype._inheritArray = function (e, i, t, r) {
  var a = i.slice(0)
  for (var n = 0; n < e.length; n++) {
    var s = false
    for (var o = 0; o < i.length; o++) {
      if (!t(i[o], e[n])) continue
      if (r) a[o] = e[n]
      s = true
      break
    }
    if (!s) a.push(e[n])
  }
  return a
}
export { WMSCapabilitiesParser }
