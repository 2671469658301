import { Constants } from '../util/Constants.js'
import { isInsideInterval } from './SphereUtil.js'
import { normalizeAngle } from '../util/Cartesian.js'
function meridionalIntersects(t, n, e, s, o, i) {
  const r = normalizeAngle(n.x)
  const a = normalizeAngle(e.x)
  if (!isAngleBetween(r, a, s)) return false
  const f = undefined
  const c = undefined
  if (Math.abs(a - r) > 180 != r > a) {
    const t = n
    n = e
    e = t
  }
  const l = t.forwardAzimuth2D(n, e) * Constants.RAD2DEG
  const u = t.forwardAzimuth2DVincenty(n.x, n.y, s, o) * Constants.RAD2DEG
  if (i) return l <= u
  else return l >= u
}
function isAngleBetween(t, n, e) {
  const s = Math.min(t, n)
  const o = Math.max(t, n)
  if (e == s || e == o) return true
  const i = e >= s && e < o
  if (o - s < 180) return i
  else return !i
}
export function polygonContains2D(t, n, e) {
  const s = n.bounds
  const o = e.x
  const i = e.y
  if (s.contains2DCoordinates(o, i)) {
    const e = n.pointCount
    let s = 0
    const r = 1e-10
    const a = []
    let f = n.getSimplePoint(0)
    for (let t = 1; t <= e; t++) {
      const c = n.getSimplePoint(t % e)
      if (isInsideInterval(o, f.x, c.x, 1e-10)) {
        const t = Math.abs(o - f.x)
        if (Math.abs(f.x - c.x) < r && t < r)
          if (i < f.y != i < c.y) return true
        const n = Math.abs(i - f.y)
        if (Math.abs(f.y) < r && Math.abs(f.y - c.y) < r && n < r) return true
        if (t < r && n < r) return true
        a.push(f)
        a.push(c)
      }
      s += f.y
      f = c
    }
    const c = a.length / 2
    if (0 == c) return false
    s /= e
    let l = 0
    let u = 0
    let d = 0
    while (d < c && a[2 * d].x === o) d++
    if (d >= c) d = 0
    const x = s < 0
    for (let n = 0; n < c; n++) {
      const n = a[2 * d]
      const e = a[2 * d + 1]
      const s = e.x - n.x
      if (0 !== s && 180 !== s && -180 !== s) {
        const s = 1e-15
        let r = false
        if (Math.abs(e.x - o) < s) {
          const t = undefined
          if (e.y >= i === x) {
            u = n.x
            r = true
          }
        }
        if (Math.abs(n.x - o) < s) {
          const t = undefined
          if (n.y >= i === x && u > o !== e.x > o) l--
          r = true
        }
        if (!r) {
          const s = undefined
          if (meridionalIntersects(t, n, e, o, i, x)) l++
        }
      }
      d = (d + 1) % c
    }
    return l % 2 != 0
  }
  return false
}
