import { On } from '../../util/On.js'
import { Handler } from './Handler.js'
import { GestureEventType } from './GestureEventType.js'
import { PinchEventImpl } from './PinchEvent.js'
import { DragEventImpl } from './DragEvent.js'
import { GestureEventImpl } from './GestureEvent.js'
const DOUBLE_TAP_TIMEOUT = 300
const DOUBLE_TAP_SLOP = 100
const TAP = 'TAP'
function generateGestureEvents(e) {
  const t = [
    GestureEventType.DOWN,
    GestureEventType.UP,
    GestureEventType.DRAG_END,
    GestureEventType.MOVE,
    GestureEventType.SINGLE_CLICK_UP,
    GestureEventType.SINGLE_CLICK_CONFIRMED,
    GestureEventType.DOUBLE_CLICK,
    GestureEventType.DOUBLE_CLICK_EVENT,
    GestureEventType.LONG_PRESS,
    GestureEventType.SHOW_PRESS,
    GestureEventType.PINCH_END,
    GestureEventType.CONTEXT_MENU,
  ]
  const s = {}
  let n
  for (n = 0; n < t.length; n += 1)
    s[t[n]] = new GestureEventImpl(e, t[n], 'touch')
  s[GestureEventType.PINCH] = new PinchEventImpl(e, 'touch')
  s[GestureEventType.DRAG] = new DragEventImpl(e, 'touch')
  return s
}
const DOM_EVENT_NAME_2_METHOD_NAME = {
  MSGestureStart: 'onMSGestureStart',
  MSGestureChange: 'onMSGestureChange',
  MSGestureEnd: 'onMSGestureEnd',
  MSGestureCancel: 'ontouchcancel',
  MSGestureTap: 'onMSGestureTap',
  MSGestureHold: 'onMSGestureHold',
  blur: 'onblur',
  contextmenu: 'oncontextmenu',
}
class MSGestureHandler extends Handler {
  constructor(e, t, s, n) {
    super()
    this._map = e
    this._node = t
    this._gestureEvents = generateGestureEvents(e)
    this._biggerTouchSlopSquare = 20 * 20
    const r = new MSGesture()
    r.target = t
    const i = this
    this._centerX = null
    this._centerY = null
    this._downHandle = t.addEventListener(
      'MSPointerDown',
      function (e) {
        if (!n || 'touch' === e.pointerType) {
          r.addPointer(e.pointerId)
          if (null === i._centerX) {
            i._centerX = e.clientX
            i._centerY = e.clientY
          } else {
            i._centerX = (i._centerX + e.clientX) / 2
            i._centerY = (i._centerY + e.clientY) / 2
          }
        }
      },
      false
    )
    this._doubleTapSlopSquare = 0
    this._listener = s
    this._currentDownDOMEvent = null
    this._doubleTapSlopSquare = DOUBLE_TAP_SLOP * DOUBLE_TAP_SLOP
    this._scaling = false
    this._prevScale = 1
    this._wireDomListeners(t)
  }
  _wireDomListeners(e) {
    this._domListeners = {}
    for (const t in DOM_EVENT_NAME_2_METHOD_NAME) this._wrapEventHandler(e, t)
  }
  _wrapEventHandler(e, t) {
    const s = this
    this._domListeners[t] = On(e, t, function (e) {
      s._map._updateViewBounds()
      const n = undefined
      if (s[DOM_EVENT_NAME_2_METHOD_NAME[t]](e)) {
        e.preventDefault()
        e.stopPropagation()
      }
    })
  }
  destroy() {
    this.cancel()
    for (const e in this._domListeners) this._domListeners[e].remove()
    this._domListeners = {}
    if (this._downHandle) this._downHandle.remove()
  }
  onMSGestureHold(e) {
    let t, s, n
    if (!this._showPress) {
      t = this._gestureEvents[GestureEventType.SHOW_PRESS]
      t.location = e
      t.domEvent = e
      this.dispatchGestureEvent(t)
      this._showPress = true
    } else {
      s = this._gestureEvents[GestureEventType.LONG_PRESS]
      s.location = e
      s.domEvent = e
      this.dispatchGestureEvent(s)
      this._showPress = false
    }
  }
  isConsideredDoubleTap(e, t) {
    if (t.timeStamp - e.timeStamp > DOUBLE_TAP_TIMEOUT) return false
    const s = e.offsetX - t.offsetX
    const n = e.offsetY - t.offsetY
    return s * s + n * n < this._doubleTapSlopSquare
  }
  onMSGestureTap(e) {
    this._showPress = false
    const t = undefined
    let s, n, r
    if (this.hasMessages(TAP)) {
      this.removeMessages(TAP)
      if (this.isConsideredDoubleTap(this._lastTapEvent.domEvent, e)) {
        s = this._gestureEvents[GestureEventType.DOUBLE_CLICK]
        s.location = this._lastTapEvent.location
        s.domEvent = this._lastTapEvent.domEvent
        this.dispatchGestureEvent(s)
        n = this._gestureEvents[GestureEventType.DOUBLE_CLICK_EVENT]
        n.location = e
        n.domEvent = e
        this.dispatchGestureEvent(n)
      }
      this._lastTapEvent = null
    } else {
      r = this._gestureEvents[GestureEventType.DOWN]
      r.location = e
      r.domEvent = e
      this.dispatchGestureEvent(r)
      r = this._gestureEvents[GestureEventType.SINGLE_CLICK_UP]
      r.location = e
      r.domEvent = e
      this.dispatchGestureEvent(r)
      this.sendEmptyMessageDelayed(TAP, DOUBLE_TAP_TIMEOUT)
      this._lastTapEvent = r
    }
    return e.currentTarget == this._node
  }
  onMSGestureStart(e) {
    this._showPress = false
    return !this._scaling ? this.down(e) : false
  }
  onMSGestureChange(e) {
    if (event.detail === event.MSGESTURE_FLAG_INERTIA) return
    this._showPress = false
    if (1 === e.scale) {
      if (this._scaling) return this.onScaleEnd(event)
      return this.move(e, e, e.offsetX, e.offsetY)
    } else {
      this._scaling = true
      this.onScale(e)
    }
  }
  onMSGestureEnd(e) {
    this._centerX = null
    this._centerY = null
    this._showPress = false
    return this.up(e, e, e.offsetX, e.offsetY)
  }
  down(e) {
    this._currentDownDOMEvent = e
    this._currentDownEvent = this._gestureEvents[GestureEventType.DOWN].copy()
    this._currentDownEvent.location = e
    this._currentDownEvent.domEvent = e
    const t = this._gestureEvents[GestureEventType.DOWN]
    t.location = e
    t.domEvent = e
    this.dispatchGestureEvent(t)
    return e.currentTarget === this._node
  }
  move(e, t, s, n) {
    this.removeMessages(TAP)
    let r
    if (Math.abs(e.translationX) >= 1 || Math.abs(e.translationX) >= 1) {
      this.dragging = true
      r = this._gestureEvents[GestureEventType.DRAG]
      r.location = t
      r.domEvent = e
      r.downEvent = this._currentDownEvent
      r.dragX = e.translationX
      r.dragY = e.translationY
      this.dispatchGestureEvent(r)
      this._currentDragEvent = e
    }
    return e.currentTarget == this._node
  }
  up(e, t, s, n) {
    if (this._scaling) return this.onScaleEnd(e)
    const r = this._gestureEvents[GestureEventType.UP]
    r.location = t
    r.domEvent = e
    this.dispatchGestureEvent(r)
    if (this.dragging) {
      this.dragging = false
      const s = this._gestureEvents[GestureEventType.DRAG_END]
      s.location = t
      s.domEvent = e
      this.dispatchGestureEvent(s)
    }
    return e.currentTarget == this._node
  }
  onblur() {
    this._showPress = false
    this.cancel()
  }
  oncontextmenu(e) {
    if (e.pointerType && 'touch' !== e.pointerType) return
    this._showPress = false
    this.cancel()
    const t = this._gestureEvents[GestureEventType.CONTEXT_MENU]
    t.location = e
    t.domEvent = e
    this.dispatchGestureEvent(t)
    return true
  }
  cancel() {
    if (this.dragging) {
      this.dragging = false
      const e = this._gestureEvents[GestureEventType.DRAG_END]
      e.domEvent = this._currentDragEvent
      e.location = this._currentDragEvent
      this.dispatchGestureEvent(e)
    }
    this.removeMessages(TAP)
  }
  handleMessage(e) {
    let t
    switch (e) {
      case TAP:
        t = this._gestureEvents[GestureEventType.SINGLE_CLICK_CONFIRMED]
        t.location = this._lastTapEvent.location
        t.domEvent = this._lastTapEvent.domEvent
        this.dispatchGestureEvent(t)
        break
    }
  }
  onScale(e) {
    const t = this._gestureEvents[GestureEventType.PINCH]
    t.domEvent = e
    t.location = { clientX: this._centerX, clientY: this._centerY }
    t.scaleFactor = e.scale
    const s = this._prevScale * e.scale
    t.scaleFactorFromStart = s
    this.dispatchGestureEvent(t)
    this._prevScale = s
    return true
  }
  onScaleEnd(e) {
    this._scaling = false
    const t = this._gestureEvents[GestureEventType.PINCH_END]
    t.location = { clientX: this._centerX, clientY: this._centerY }
    t.domEvent = e
    this.dispatchGestureEvent(t)
    this._prevScale = 1
  }
  dispatchGestureEvent(e) {
    this._listener.onGestureEvent(e)
  }
}
export { MSGestureHandler }
