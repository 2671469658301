import { ProgrammingError } from '../error/ProgrammingError.js'
class Axis {
  TYPE = 'AXIS'
  constructor(T, i, E, r, S, n) {
    if (!i || i < 1 || i > 29)
      throw new ProgrammingError('Axis:  invalid axis direction')
    if (!E || 'UNIT' !== E.TYPE)
      throw new ProgrammingError('Axis: invalid Unit of Measure')
    if (null !== n && (n < 1 || n > 2))
      throw new ProgrammingError('Axis: invalid range Meaning')
    this.abbreviation = T
    this.direction = i
    this.unitOfMeasure = E
    this.minimumValue = r
    this.maximumValue = S
    this.rangeMeaning = n
  }
  equals(T) {
    if (!T) return false
    if (this === T) return true
    if ('AXIS' !== T.TYPE) return false
    return (
      this.direction === T.direction &&
      this.minimumValue === T.minimumValue &&
      this.maximumValue === T.maximumValue &&
      this.rangeMeaning === T.rangeMeaning &&
      this.unitOfMeasure.equals(T.unitOfMeasure)
    )
  }
  copy() {
    return new Axis(
      this.abbreviation,
      this.direction,
      this.unitOfMeasure,
      this.minimumValue,
      this.maximumValue,
      this.rangeMeaning
    )
  }
}
;(function (T) {
  let i = (function (T) {
    T[(T['X'] = 0)] = 'X'
    T[(T['Y'] = 1)] = 'Y'
    T[(T['Z'] = 2)] = 'Z'
    return T
  })({})
  T.Name = i
  let E = (function (T) {
    T[(T['EXACT'] = 1)] = 'EXACT'
    T[(T['WRAPAROUND'] = 2)] = 'WRAPAROUND'
    return T
  })({})
  T.RangeMeaning = E
  let r = (function (T) {
    T[(T['NORTH'] = 1)] = 'NORTH'
    T[(T['NORTH_NORTH_EAST'] = 2)] = 'NORTH_NORTH_EAST'
    T[(T['NORTH_EAST'] = 3)] = 'NORTH_EAST'
    T[(T['EAST_NORTH_EAST'] = 4)] = 'EAST_NORTH_EAST'
    T[(T['EAST'] = 5)] = 'EAST'
    T[(T['EAST_SOUTH_EAST'] = 6)] = 'EAST_SOUTH_EAST'
    T[(T['SOUTH_EAST'] = 7)] = 'SOUTH_EAST'
    T[(T['SOUTH_SOUTH_EAST'] = 8)] = 'SOUTH_SOUTH_EAST'
    T[(T['SOUTH'] = 9)] = 'SOUTH'
    T[(T['SOUTH_SOUTH_WEST'] = 10)] = 'SOUTH_SOUTH_WEST'
    T[(T['SOUTH_WEST'] = 11)] = 'SOUTH_WEST'
    T[(T['WEST_SOUTH_WEST'] = 12)] = 'WEST_SOUTH_WEST'
    T[(T['WEST'] = 13)] = 'WEST'
    T[(T['WEST_NORTH_WEST'] = 14)] = 'WEST_NORTH_WEST'
    T[(T['NORTH_WEST'] = 15)] = 'NORTH_WEST'
    T[(T['NORTH_NORTH_WEST'] = 16)] = 'NORTH_NORTH_WEST'
    T[(T['UP'] = 17)] = 'UP'
    T[(T['DOWN'] = 18)] = 'DOWN'
    T[(T['GEOCENTRIC_X'] = 19)] = 'GEOCENTRIC_X'
    T[(T['GEOCENTRIC_Y'] = 20)] = 'GEOCENTRIC_Y'
    T[(T['GEOCENTRIC_Z'] = 21)] = 'GEOCENTRIC_Z'
    T[(T['COLUMN_POSITIVE'] = 22)] = 'COLUMN_POSITIVE'
    T[(T['COLUMN_NEGATIVE'] = 23)] = 'COLUMN_NEGATIVE'
    T[(T['ROW_POSITIVE'] = 24)] = 'ROW_POSITIVE'
    T[(T['ROW_NEGATIVE'] = 25)] = 'ROW_NEGATIVE'
    T[(T['DISPLAY_RIGHT'] = 26)] = 'DISPLAY_RIGHT'
    T[(T['DISPLAY_LEFT'] = 27)] = 'DISPLAY_LEFT'
    T[(T['DISPLAY_UP'] = 28)] = 'DISPLAY_UP'
    T[(T['DISPLAY_DOWN'] = 29)] = 'DISPLAY_DOWN'
    return T
  })({})
  T.Direction = r
})(Axis || (Axis = {}))
export { Axis }
