import { ShapeUtil } from './ShapeUtil.js'
import { CircularArc } from './CircularArc.js'
import { ShapeType } from './ShapeType.js'
export class CircularArcBy3Points extends CircularArc {
  get type() {
    return ShapeType.CIRCULAR_ARC + ShapeType.CIRCULAR_ARC_BY_3_POINTS
  }
  moveStartPoint2DToPoint(t) {
    this.moveStartPoint2DToCoordinates(t.x, t.y)
  }
  moveIntermediatePoint2DToPoint(t) {
    this.moveIntermediatePoint2DToCoordinates(t.x, t.y)
  }
  moveEndPoint2DToPoint(t) {
    this.moveEndPoint2DToCoordinates(t.x, t.y)
  }
  translate2D(t, e) {
    this.translateStartPoint2D(t, e)
    this.translateIntermediatePoint2D(t, e)
    this.translateEndPoint2D(t, e)
  }
  hashCode(t) {
    t.appendUInt32(this.type)
    this.startPoint.hashCode(t)
    this.intermediatePoint.hashCode(t)
    this.endPoint.hashCode(t)
  }
  toString() {
    return `CircularArcBy3Points[start${this.startPoint.toString(
      true
    )}, intermediate${this.intermediatePoint.toString(
      true
    )}, end${this.endPoint.toString(true)}]`
  }
  equals(t) {
    if (this === t) return true
    if (!ShapeUtil.referencesEqual(this.reference, t.reference)) return false
    return (
      this.type === t.type &&
      this.startPoint.equals(t.startPoint) &&
      this.intermediatePoint.equals(t.intermediatePoint) &&
      this.endPoint.equals(t.endPoint)
    )
  }
}
