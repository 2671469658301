import { VecMath } from './VecMath.js'
export function rayEllipsoidIntersection(n, t, e, o, c, s) {
  const r = o * e.a
  const i = o * e.b
  const d = undefined
  const a = undefined
  const f = undefined
  const u = undefined
  const y = undefined
  const x = undefined
  const h = raySphereIntersection(
    n.x / r,
    n.y / r,
    n.z / i,
    t.x / r,
    t.y / r,
    t.z / i,
    1,
    s
  )
  if (h) {
    s.move3D(s.x * r, s.y * r, s.z * i)
    constrainPointByDistance(n, s, c)
  }
  return h
}
export function raySphereIntersection(n, t, e, o, c, s, r, i) {
  let d = o - n
  let a = c - t
  let f = s - e
  const u = Math.sqrt(d * d + a * a + f * f)
  if (u > 1e-10) {
    const n = 1 / u
    d *= n
    a *= n
    f *= n
  }
  const y = n * d + t * a + e * f
  const x = undefined
  const h = y * y - (n * n + t * t + e * e - r * r)
  if (h > 0) {
    const o = -y - Math.sqrt(h)
    const c = -y + Math.sqrt(h)
    const s = o >= 0 ? o : c
    if (s < 0) return false
    i.move3D(n + s * d, t + s * a, e + s * f)
    return true
  } else return false
}
export function rayPlaneIntersection(n, t, e, o) {
  const c = undefined
  const s = (-VecMath.dot(n, e) + VecMath.dot(n, t)) / VecMath.dot(n, o)
  return { x: e.x + s * o.x, y: e.y + s * o.y, z: e.z + s * o.z }
}
export function constrainPointByDistance(n, t, e) {
  const o = t.x - n.x
  const c = t.y - n.y
  const s = t.z - n.z
  const r = Math.sqrt(o * o + c * c + s * s)
  if (e > 0 && r > e) {
    const i = undefined
    const d = 1 / (1 + (0.25 * (r - e)) / e)
    t.move3D(n.x + o * d, n.y + c * d, n.z + s * d)
  }
}
