import { LayerTreeNode } from '../LayerTreeNode.js'
import { LayerTreeVisitor } from '../LayerTreeVisitor.js'
export class RetrieveByLayerTypeVisitor {
  constructor() {
    this._type = null
    this._layers = []
  }
  get layers() {
    return this._layers
  }
  get type() {
    return this._type
  }
  visitLayer(e) {
    if (e.type === this._type) this._layers.push(e)
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
  visitLayerGroup(e) {
    e.visitChildren(this, LayerTreeNode.VisitOrder.BOTTOM_UP)
    return LayerTreeVisitor.ReturnValue.CONTINUE
  }
  reset(e) {
    this._type = e
    this._layers = []
  }
}
