import { ReferenceType } from '../reference/ReferenceType.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Transformation } from './Transformation.js'
import { BoundsCalculationUtil } from './BoundsCalculationUtil.js'
export class GeodeticGeodeticTransformation extends Transformation {
  constructor(e, t, o) {
    super(e, t)
    if (e.referenceType !== ReferenceType.GEODETIC)
      throw new ProgrammingError(
        'GeodeticGeodeticTransformation::setSourceReference - Expected geodetic reference.'
      )
    if (t.referenceType !== ReferenceType.GEODETIC)
      throw new ProgrammingError(
        'GeodeticGeodeticTransformation::setDestinationReference - Expected geodetic reference.'
      )
    this._srcGeodeticDatum = e.geodeticDatum
    this._dstGeodeticDatum = t.geodeticDatum
    this._equalDatums = this._srcGeodeticDatum.equals(this._dstGeodeticDatum)
    this._equalHorizontalDatums = this._srcGeodeticDatum.equals2D(
      this._dstGeodeticDatum
    )
    this._tmpXYZPoint1 = new XYZPoint(null, [0, 0, 0])
    this._tmpXYZPoint2 = new XYZPoint(null, [0, 0, 0])
    this._bboxCalculationUtil = new BoundsCalculationUtil({
      modelReference: e,
      worldReference: t,
      transformation: this,
      TransformationFactory: o,
    })
  }
  _forward(e, t) {
    return this.transformImpl(
      e,
      t,
      this._srcGeodeticDatum,
      this._dstGeodeticDatum
    )
  }
  _inverse(e, t) {
    return this.transformImpl(
      e,
      t,
      this._dstGeodeticDatum,
      this._srcGeodeticDatum
    )
  }
  _forwardBoundsCoords(e, t) {
    if (this._equalDatums) t.setTo3D(e.x, e.width, e.y, e.height, e.z, e.depth)
    else this._bboxCalculationUtil.forwardBounds2dSFCT(e, t)
  }
  _inverseBoundsCoords(e, t) {
    if (this._equalDatums) t.setTo3D(e.x, e.width, e.y, e.height, e.z, e.depth)
    else this._bboxCalculationUtil.inverseBounds2dSFCT(e, t)
  }
  transformImpl(e, t, o, i) {
    if (this._equalDatums) {
      t.x = e.x
      t.y = e.y
      t.z = e.z
    } else if (this._equalHorizontalDatums) {
      const r = e.x
      const s = e.y
      t.x = r
      t.y = s
      t.z = o.getHeight(r, s) - i.getHeight(r, s)
    } else {
      o.geod2geocSFCT(e, this._tmpXYZPoint1)
      o.datum2refGeocSFCT(this._tmpXYZPoint1, this._tmpXYZPoint2)
      i.ref2datumGeocSFCT(this._tmpXYZPoint2, this._tmpXYZPoint1)
      i.geoc2geodSFCT(this._tmpXYZPoint1, t)
    }
    return t
  }
}
