import { isString } from '../../../../../util/Lang.js'
import { isConvertedFromTextStyle } from '../../../../feature/photon/TextToIconSupport.js'
let nextCanvasId = 1
export function appendImageHashCode(e, n) {
  if (!e) n.appendUInt32(57005)
  else if (isConvertedFromTextStyle(e)) appendTextHashCode(e, n)
  else if (e instanceof HTMLCanvasElement) {
    if (!e.__imageHashId) {
      e.__imageHashId = 'canvas-' + nextCanvasId
      nextCanvasId++
    }
    n.append(e.__imageHashId)
  } else if (e instanceof HTMLImageElement) n.appendString(e.src)
  else if (isString(e)) n.appendString(e)
  else n.appendUInt32(57005)
  return n
}
export function appendTextHashCode(e, n) {
  const { text: t, style: a } = e
  const {
    halo: i,
    fill: p,
    font: r,
    stroke: o,
    haloWidth: s,
    strokeWidth: d,
    height: g,
    uom: S,
  } = a
  n.appendString('' + t)
  s && n.appendString(isString(s) ? s : s.toString())
  d && n.appendString(isString(d) ? d : d.toString())
  p && n.appendString(p)
  r && n.appendString(r)
  i && n.appendString(i)
  o && n.appendString(o)
  g && n.appendDouble(g)
  S && n.appendString(S.name)
  return n
}
