import { ProgrammingError } from '../../error/ProgrammingError.js'
import { URL } from '../../util/URL.js'
import { Constants } from '../../util/Constants.js'
import { Log } from '../../util/Log.js'
import { WMSCapabilities } from '../capabilities/WMSCapabilities.js'
import { WMSClient } from '../../ogc/wms/WMSClient.js'
import { RasterDataType } from '../tileset/RasterDataType.js'
import { WMSImageModelOptionsUtil } from './WMSImageModelOptionsUtil.js'
import { RasterImageModel } from './RasterImageModel.js'
import { hasProperty, isArray, isObject, isString } from '../../util/Lang.js'
import { isValidRequestParameterValue, validateDimensions } from '../OGCUtil.js'
const RESERVED_PARAMETERS = [
  'REQUEST',
  'SERVICE',
  'VERSION',
  'LAYERS',
  'STYLES',
  'FORMAT',
  'TRANSPARENT',
  'BGCOLOR',
  'CRS',
  'SRS',
  'BBOX',
  'WIDTH',
  'HEIGHT',
  'PIXELSIZE',
  'I',
  'J',
  'QUERY_LAYERS',
  'INFO_FORMAT',
  'FEATURE_COUNT',
  'SLD',
  'SLD_BODY',
]
function isValidRequestParameterKey(e) {
  return -1 === RESERVED_PARAMETERS.indexOf(e.toUpperCase())
}
function verifyStyles(e) {
  if (isArray(e)) return e.join(',')
  if (isString(e))
    Log.warn('the styles-parameter should be an array of strings')
  return e
}
export class WMSImageModel extends RasterImageModel {
  constructor(e) {
    const r = (e = e || {}).reference ? e.reference : null
    let s = e.bounds ? e.bounds : null
    if (!s)
      if (!r)
        throw new ProgrammingError(
          'a WMS model must be created with a reference or (preferably) a Bounds'
        )
      else {
        s = r.bounds
        if (!s) throw new ProgrammingError('Invalid reference')
      }
    else if (r && !s.reference.equals(r))
      throw new ProgrammingError('bounds reference and reference must be equal')
    if (e.requestReference) {
      Log.deprecated('options.requestReference', 'options.reference')
      if (!e.requestReference.equals(s.reference))
        throw new ProgrammingError(
          'The options.requestReference must be the same as options.reference and/or options.bounds.reference'
        )
    }
    e.reference = s.reference
    e.modelDescriptor =
      e.modelDescriptor ||
      Object.freeze({
        source: 'N/A',
        name: 'WMS Image',
        description: 'WMS Image Model',
        type: RasterDataType.IMAGE,
      })
    super(e)
    this._layers = e.layers
    this._queryLayers = e.queryLayers || null
    this._wmsClient = new WMSClient(e)
    this._styles = verifyStyles(e.styles || [])
    this._sld = null
    this._sldBody = null
    if (e.sld && e.sldBody)
      throw new ProgrammingError(
        'options.sld and options.sldBody cannot both be set at construction time.'
      )
    if (e.sld) this._sld = e.sld
    else if (e.sldBody) this._sldBody = e.sldBody
    this._imageFormat = e.imageFormat || null
    this._bounds = s
    this._dimensions = null
    this._requestParameters = null
    this.requestParameters = e.requestParameters || null
    this.dimensions = e.dimensions || null
    this.useCors = !URL.isLocalURL(e.getMapRoot)
  }
  get getMapRoot() {
    return this._wmsClient.getMapRoot
  }
  set getMapRoot(e) {
    this._wmsClient.getMapRoot = e
    this.invalidate()
  }
  get credentials() {
    return super.credentials
  }
  set credentials(e) {
    super.credentials = e
  }
  get requestHeaders() {
    return super.requestHeaders
  }
  set requestHeaders(e) {
    super.requestHeaders = e
  }
  get queryable() {
    return this._queryLayers ? 0 !== this._queryLayers.length : false
  }
  static createFromCapabilities(e, r, s) {
    s = s || {}
    const t = WMSImageModelOptionsUtil.createImageModelCreatorOptions(e, r, s)
    return new WMSImageModel(t)
  }
  static createFromURL(e, r, s) {
    return WMSCapabilities.fromURL(e, s).then((e) =>
      WMSImageModel.createFromCapabilities(e, r, s)
    )
  }
  getImageUrl(e, r, s, t) {
    if (!this.reference.equals(e.reference))
      throw new ProgrammingError(
        'The reference of the image bounds should match the reference of this model.'
      )
    return this.getImageUrlWithModelBounds(e, r, s, t)
  }
  getImageUrlWithModelBounds(e, r, s, t) {
    const i = {
      bounds: e,
      size: [Math.round(r), Math.round(s)],
      layers: this._layers,
      srsCode: e.reference.identifier,
      imageFormat: this._imageFormat || '',
      styles: this._styles || '',
      pixelSize: t,
      dimensions: this.dimensions,
      requestParameters: this.requestParameters,
    }
    if (this.sld) i.sld = this.sld
    if (this.sldBody) i.sldBody = this.sldBody
    return this._wmsClient.getMapUrl(i)
  }
  getFeatureInfo(e) {
    if (e.bounds.reference !== this.reference)
      throw new ProgrammingError(
        'The reference of the GetFeatureInfo request should match the reference of this model.'
      )
    if (!this.queryable)
      throw new ProgrammingError(
        'This model is not queryable. Specify the layers to query when constructing the model.'
      )
    const r = {
      layers: this._layers,
      queryLayers: this._queryLayers,
      srsCode: e.bounds.reference.identifier,
      bounds: e.bounds,
      size: [Math.round(e.size[0]), Math.round(e.size[1])],
      i: e.i,
      j: e.j,
      infoFormat: e.infoFormat,
      featureCount: e.featureCount,
      styles: this._styles || '',
      pixelSize: Constants.INCH_TO_MM / 96,
      dimensions: this.dimensions,
      requestParameters: this.requestParameters,
    }
    if (this.sld) r.sld = this.sld
    if (this.sldBody) r.sldBody = this.sldBody
    const s = {
      credentials: this.credentials,
      requestHeaders: this.requestHeaders,
    }
    return this._wmsClient.getFeatureInfo(r, s)
  }
  get layers() {
    return this._layers.slice()
  }
  set layers(e) {
    if (!isArray(e))
      throw new ProgrammingError(
        'WMSImageModel::Need to specify the layers as an array of strings'
      )
    this._layers = e.slice()
    this.invalidate()
  }
  get transparent() {
    return this._wmsClient.transparent
  }
  set transparent(e) {
    this._wmsClient.transparent = e
    this.invalidate()
  }
  get backgroundColor() {
    return this._wmsClient.backgroundColor
  }
  set backgroundColor(e) {
    this._wmsClient.backgroundColor = e
    this.invalidate()
  }
  get wmsClient() {
    return this._wmsClient
  }
  get queryLayers() {
    return this._queryLayers || []
  }
  set queryLayers(e) {
    if (!isArray(e))
      throw new ProgrammingError(
        'WMSImageModel::Need to specify the queryLayers as an array of strings'
      )
    this._queryLayers = e.slice()
    this.invalidate()
  }
  get requestParameters() {
    return this._requestParameters
  }
  set requestParameters(e) {
    if (!isObject(e))
      throw new ProgrammingError(
        'requestParameters: must assign an object literal'
      )
    if (null !== e) {
      this._requestParameters = {}
      for (const r in e)
        if (
          hasProperty(e, r) &&
          isValidRequestParameterKey(r) &&
          isValidRequestParameterValue(e[r])
        )
          this._requestParameters[r] = e[r]
        else
          throw new ProgrammingError(
            `${r} is an invalid request parameter. The parameter name is part of the WMS standard or the parameter value is not allowed.`
          )
    } else this._requestParameters = null
    this.invalidate()
  }
  get styles() {
    return this._styles.split(',')
  }
  set styles(e) {
    this._styles = verifyStyles(e)
    this.invalidate()
  }
  get sld() {
    return this._sld
  }
  set sld(e) {
    this._sld = e
    this._sldBody = null
    this.invalidate()
  }
  get sldBody() {
    return this._sldBody
  }
  set sldBody(e) {
    this._sldBody = e
    this._sld = null
    this.invalidate()
  }
  get dimensions() {
    return this._dimensions
  }
  set dimensions(e) {
    this._dimensions = validateDimensions(e)
    this.invalidate()
  }
  get bounds() {
    return this._bounds
  }
}
