import { createBounds } from '../../../../shape/ShapeFactory.js'
import { isUpsideDown } from '../../../../util/Cartesian.js'
import { HTML5TextUtil } from '../../HTML5TextUtil.js'
import { StyleUtil } from '../../StyleUtil.js'
import { Pattern } from '../Pattern.js'
import { WorldSized } from './util/WorldSized.js'
import { PatternType } from './util/PatternType.js'
const LINE_SPACING = 1
export class TextPattern extends Pattern {
  constructor(t) {
    super(PatternType.TEXT, 15)
    const e = (t = t || {}).textStyle || {}
    e.alignmentBaseline = e.alignmentBaseline || 'middle'
    this._textEntries = t.text || ['']
    this._textStyle = StyleUtil.normalizeTextStyle(e)
    this._textStyle.alignmentBaseline = 'middle'
    this._textStyle.textAnchor = 'center'
    this._textStyle.offsetX = 0
    this._textStyle.offsetY = 0
    this._offset = t.offset || 0
    this._rotateUp = 'boolean' === typeof t.rotateUp ? t.rotateUp : true
    this._size = t.size || null
    this._textEntriesBounds = []
    this._bounds = createBounds(null, [])
    this.calculateBounds()
    this._imageCanvas = null
    this._worldSized = new WorldSized()
  }
  get textEntries() {
    return this._textEntries
  }
  get offset() {
    return this._offset
  }
  get rotateUp() {
    return this._rotateUp
  }
  get size() {
    return this._size
  }
  getImageCanvas() {
    if (!this._imageCanvas)
      this._imageCanvas = HTML5TextUtil.createCanvas(
        this._textEntries.join('\n'),
        this._textStyle
      )
    return this._imageCanvas
  }
  getScaleFactor() {
    if (this._worldSized.worldSizeContext.isWorldSize)
      return (this._size || this._bounds.height) / this.getImageCanvas().height
    return 1
  }
  get worldSizeContext() {
    return this._worldSized.worldSizeContext
  }
  set worldSizeContext(t) {
    this._worldSized.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._worldSized.getUomSizeToPixels(t)
  }
  serialize() {
    const t = this._textStyle.uom
    this._textStyle.uom = t.name
    const e = super.serialize()
    this._textStyle.uom = t
    delete e._textEntriesBounds
    delete e._bounds
    delete e._offset
    delete e._imageCanvas
    delete e._worldSized
    return e
  }
  calculateBounds() {
    let t = 0
    let e = 0
    for (let i = 0; i < this._textEntries.length; i++) {
      const s = HTML5TextUtil.calculateBounds(
        this._textEntries[i],
        this._textStyle,
        true,
        false
      )
      this._textEntriesBounds[i] = createBounds(null, [
        s[0],
        s[2] - s[0],
        s[1],
        s[3] - s[1],
      ])
      t = Math.max(t, s[2] - s[0])
      e += s[3] - s[1] + (0 === i ? 0 : LINE_SPACING)
    }
    this._bounds = createBounds(null, [0, t, -e / 2 - this._offset, e])
  }
  getTop() {
    return this.getUomSizeToPixels(this._bounds.y)
  }
  getBottom() {
    return this.getUomSizeToPixels(this._bounds.y + this._bounds.height)
  }
  getLeft() {
    return this.getUomSizeToPixels(this._bounds.x)
  }
  getWidth(t) {
    return this.getUomSizeToPixels(this._bounds.width)
  }
  getHeight(t) {
    return this.getUomSizeToPixels(this._bounds.height)
  }
  getMinHeight() {
    return this.getUomSizeToPixels(this._offset - this._bounds.height / 2)
  }
  getMaxHeight() {
    return this.getUomSizeToPixels(this._offset + this._bounds.height / 2)
  }
  paint(t, e, i) {
    const s = this.getWidth(e)
    let n, o, r
    const h = this.getUomSizeToPixels(this._bounds.height) / this._bounds.height
    if (this._rotateUp && isUpsideDown(i)) {
      n = s
      o = this.getBottom()
      for (r = 0; r < this._textEntries.length; r++) {
        HTML5TextUtil.drawTextAnchoredTopLeft(
          t,
          this._textEntries[r],
          (n + this._textEntriesBounds[r].width) / 2,
          o,
          180,
          this._textStyle,
          h
        )
        o -= this.getUomSizeToPixels(
          this._textEntriesBounds[r].height + LINE_SPACING
        )
      }
    } else {
      n = this.getLeft()
      o = this.getTop()
      for (r = 0; r < this._textEntries.length; r++) {
        HTML5TextUtil.drawTextAnchoredTopLeft(
          t,
          this._textEntries[r],
          n +
            (s - this.getUomSizeToPixels(this._textEntriesBounds[r].width)) / 2,
          o,
          0,
          this._textStyle,
          h
        )
        o += this.getUomSizeToPixels(
          this._textEntriesBounds[r].height + LINE_SPACING
        )
      }
    }
  }
  appendHash(t) {
    for (let e = 0; e < this._textEntries.length; e++)
      t.appendString(this._textEntries[e])
    t.appendDouble(this._offset)
    t.appendDouble(this._size || -1)
    t.appendBoolean(this._rotateUp)
    const e = this._textStyle
    t.appendDouble(e.offsetX)
    t.appendDouble(e.offsetY)
    if (e.stroke) {
      t.appendString(e.stroke)
      t.appendUInt32(+e.strokeWidth)
    }
    t.appendString(e.fill)
    t.appendString(e.font)
    t.appendString(e.textAnchor)
    t.appendString(e.alignmentBaseline)
    if (e.halo) {
      t.appendString(e.halo)
      t.appendUInt32(+e.haloWidth)
    }
    t.appendString(e.uom?.name)
    t.appendDouble(e.height)
    t.appendUInt32(+e.zOrder)
    t.appendDouble(+e.angle)
  }
}
