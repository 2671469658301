import { getUnitOfMeasure } from '../uom/UnitOfMeasureRegistry.js'
import { Axis } from './Axis.js'
export function createGeodeticReferenceAxisInformation(e, i) {
  const n = getUnitOfMeasure('DegreeAngle')
  const t = {
    name: Axis.Name.X,
    axis: new Axis(
      'X',
      Axis.Direction.EAST,
      n,
      -180,
      180,
      Axis.RangeMeaning.WRAPAROUND
    ),
  }
  const s = {
    name: Axis.Name.Y,
    axis: new Axis(
      'Y',
      Axis.Direction.NORTH,
      n,
      -90,
      90,
      Axis.RangeMeaning.EXACT
    ),
  }
  if (i) {
    const i = getUnitOfMeasure('Meter')
    const n = {
      name: Axis.Name.Z,
      axis: new Axis(
        'Z',
        Axis.Direction.UP,
        i,
        Number.NEGATIVE_INFINITY,
        Number.POSITIVE_INFINITY,
        Axis.RangeMeaning.EXACT
      ),
    }
    return e ? [s, t, n] : [t, s, n]
  } else return e ? [s, t] : [t, s]
}
export function createGridReferenceAxisInformation(e, i) {
  i = i || getUnitOfMeasure('Meter')
  const n = {
    name: Axis.Name.X,
    axis: new Axis('X', Axis.Direction.EAST, i, null, null, null),
  }
  const t = {
    name: Axis.Name.Y,
    axis: new Axis('Y', Axis.Direction.NORTH, i, null, null, null),
  }
  return e ? [t, n] : [n, t]
}
export function shouldSwapAxes(e, i) {
  const n = e.axisInformation
  const t =
    n.length >= 2 && n[0].name === Axis.Name.Y && n[1].name === Axis.Name.X
  return i.indexOf(e.identifier) >= 0 ? !t : t
}
function hasAxisDirection(e, i) {
  const n = e.axisInformation
  for (let e = 0; e < n.length; e++) if (n[e].axis.direction === i) return true
  return false
}
export function hasWestingAxis(e) {
  return hasAxisDirection(e, Axis.Direction.WEST)
}
export function hasSouthingAxis(e) {
  return hasAxisDirection(e, Axis.Direction.SOUTH)
}
