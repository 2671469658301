import { isNumber } from '../../../util/Lang.js'
import { simplePointCreate } from '../../../shape/SimplePoint.js'
export class GridLinePath {
  constructor() {
    this._fP1 = simplePointCreate(0, 0, 0)
    this._fP2 = simplePointCreate(0, 0, 0)
  }
  initializePath(i, t) {
    this._fP1.x = i.x
    this._fP1.y = i.y
    this._fP1.z = i.z
    this._fP2.x = t.x
    this._fP2.y = t.y
    this._fP2.z = t.z
  }
  getMinimumRecursionDepth() {
    return 0
  }
  getPointAtSFCT(i, t) {
    if (0 == i) {
      t.x = this._fP1.x
      t.y = this._fP1.y
      t.z = this._fP1.z
    } else if (1 == i) {
      t.x = this._fP2.x
      t.y = this._fP2.y
      t.z = this._fP2.z
    } else {
      t.x = this._fP1.x + i * (this._fP2.x - this._fP1.x)
      t.y = this._fP1.y + i * (this._fP2.y - this._fP1.y)
      if (isNumber(this._fP1.z) && isNumber(this._fP2.z))
        t.z = this._fP1.z + i * (this._fP2.z - this._fP1.z)
    }
    return t
  }
  getStartPosition() {
    return this._fP1
  }
  getEndPosition() {
    return this._fP2
  }
}
