import { AreaSegmentChooser } from './AreaSegmentChooser.js'
class AreaSegmentChooserUnite extends AreaSegmentChooser {
  constructor() {
    super(...arguments)
  }
  isSegmentOkAbstract(e) {
    if (!e.segmentLabel.locationOn[e.otherShapeIndex]) return true
    else if (
      !e.segmentLabel.locationLeft[e.otherShapeIndex] ||
      !e.segmentLabel.locationRight[e.otherShapeIndex]
    ) {
      if (
        e.segmentLabel.locationLeft[e.otherShapeIndex] !=
        e.segmentLabel.locationRight[e.shapeIndex]
      )
        return true
      if (
        e.segmentLabel.locationRight[e.otherShapeIndex] !=
        e.segmentLabel.locationLeft[e.shapeIndex]
      )
        return true
    }
    return false
  }
}
export { AreaSegmentChooserUnite }
