import { NoBoundsError } from '../error/NoBoundsError.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { createBounds } from '../shape/ShapeFactory.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { isDefined, isFunction } from '../util/Lang.js'
import { LayerTreeNode } from './LayerTreeNode.js'
import { LayerTreeNodeType } from './LayerTreeNodeType.js'
import { LayerType } from './LayerType.js'
import { PaintRepresentation } from './PaintRepresentation.js'
import { Log } from '../util/Log.js'
export class Layer extends LayerTreeNode {
  constructor(e, r) {
    super(r)
    if (
      !(r = r || {}).label &&
      e &&
      e.modelDescriptor &&
      e.modelDescriptor.name
    )
      this._label = e.modelDescriptor.name
    this.editable = isDefined(r.editable) ? !!r.editable : false
    this.setLayerType(r.layerType ? r.layerType : LayerType.STATIC)
    this._minScale = r.minScale || -1 / 0
    this._maxScale = r.maxScale || +1 / 0
    this._inScaleRange = false
    this._lineType = r.lineType ? r.lineType : this._lineType
    this._modelBoundsDirty = true
    this._modelBounds = null
    this.verifyModel(e)
    this._model = e
  }
  get modelToWorldTransformation() {
    return this._modelToWorldTransformation
  }
  _constructClone() {
    return new Layer(this._model, {
      id: `${this.id}_clone`,
      layerType: this.type,
    })
  }
  correctWorldViewTransformation2D(e, r) {
    r.setToWVT(e)
  }
  _addedToMap(e) {
    if (this._map && this._map !== e)
      throw new ProgrammingError(
        'Layer:: a layer may not be added to multiple maps.'
      )
    this._map = e
    this._worldBoundsChanged(e)
    this._inScaleRange = this.inAllowedScaleRange()
    this.visibleSupported = true
    const r = this
    this._scaleRangeHandle = this._map.on('idle', () => {
      const e = r.inAllowedScaleRange()
      if (!r._inScaleRange && e) r.emit('EnterAllowedScaleRange')
      r._inScaleRange = e
    })
    this.emit('AddedToMap')
  }
  _removedFromMap(e) {
    this._map = null
    this._modelToWorldTransformation = null
    this._modelBounds = null
    if (this._scaleRangeHandle) {
      this._scaleRangeHandle.remove()
      this._scaleRangeHandle = null
    }
    this.emit('RemovedFromMap')
  }
  _worldBoundsChanged(e) {
    this._modelBoundsDirty = true
  }
  getModelBoundsVisibleOnMap() {
    if (this._modelBoundsDirty) {
      if (!this._map || !this._modelToWorldTransformation) return null
      if (!this._modelBounds)
        this._modelBounds = createBounds(
          this._modelToWorldTransformation.inputReference,
          [0, 0, 0, 0]
        )
      try {
        this._map.getMapBoundsSFCT(this._modelBounds)
      } catch (e) {
        if (!OutOfBoundsError.is(e) && !NoBoundsError.is(e)) throw e
        this._modelBounds.setTo3D(
          Number.NaN,
          Number.NaN,
          Number.NaN,
          Number.NaN,
          Number.NaN,
          Number.NaN
        )
      }
      this._modelBoundsDirty = false
    }
    return isNaN(this._modelBounds.x) ? null : this._modelBounds
  }
  _updateModelWorldTransformation(e) {
    try {
      this._modelToWorldTransformation = createTransformation(
        e,
        this._map.reference
      )
    } catch (e) {
      Log.warn(
        'Could not create model to world transformation, both reference are incompatible. This might impact some transformation functions that will only return null.'
      )
      this._modelToWorldTransformation = null
    }
    this._modelBounds = null
    this._modelBoundsDirty = true
  }
  _invalidate() {
    if (this._map) this._map.invalidateLayerTreeNode(this)
  }
  _schedule(e) {
    if (this._map) this._map._schedule(e)
  }
  snapScale(e, r, t, o) {
    o.scalex = e
    o.scaley = r
  }
  getLabelManager(e) {
    if (!this._map) return null
    if (e === PaintRepresentation.LABEL)
      return this._map.viewPaintingStrategy.labelManager
    else if (e === PaintRepresentation.BOTTOM_BORDER_LABEL)
      return this._map.viewPaintingStrategy.bottomBorderLabelManager
    else if (e === PaintRepresentation.LEFT_BORDER_LABEL)
      return this._map.viewPaintingStrategy.leftBorderLabelManager
    return null
  }
  accept(e) {
    return e.visitLayer(this)
  }
  getWorkingSetReference() {
    return this._map.reference
  }
  update() {
    return 1
  }
  onCreateContextMenu(e, r, t) {}
  verifyModel(e) {}
  inAllowedScaleRange() {
    if (!this._map) return false
    if (this._map.reference.referenceType === ReferenceType.CARTESIAN)
      return true
    const e = this._map.xScale
    if (e === 1 / 0 && this._maxScale === 1 / 0) return true
    return this._maxScale > e && e >= this._minScale
  }
  setLayerType(e) {
    for (const r in LayerType)
      if (r === e) {
        this._layerType = e
        return
      }
    throw new ProgrammingError('Invalid LayerType')
  }
  _collectLabels(e, r, t) {}
  _paintBodyLabels(e, r, t) {
    return false
  }
  onClick(e) {
    return false
  }
  get scaleRange() {
    return { min: this._minScale, max: this._maxScale }
  }
  get model() {
    return this._model
  }
  get editable() {
    return this._isEditable
  }
  set editable(e) {
    this._isEditable = e
    this.emit('EditableChanged', e)
  }
  get balloonContentProvider() {
    return this._balloonContentProvider
  }
  set balloonContentProvider(e) {
    if (null !== e && !isFunction(e))
      throw new ProgrammingError('balloonContentProvider must be a function')
    this._balloonContentProvider = e
  }
  get type() {
    return this._layerType
  }
  get treeNodeType() {
    return LayerTreeNodeType.LAYER
  }
  on(e, r, t) {
    return super.on(e, r, t)
  }
}
