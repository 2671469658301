import { isArray, isUndefined } from '../../util/Lang.js'
import { Constants } from '../../util/Constants.js'
import { XYZPoint } from '../../shape/XYZPoint.js'
import { DrawBaseLabelHtmlContents } from './DrawBaseLabelHtmlContents.js'
import { LabelPositions } from './LabelPositions.js'
import { PointLabelPosition } from './PointLabelPosition.js'
import { drawNullCommand } from './NullDrawCommand.js'
import { INVALID_LAYER_CLIP } from '../LayerStyle.js'
const anchorPoint = new XYZPoint()
const coord = new XYZPoint()
export class DrawPointLabelHtmlContents extends DrawBaseLabelHtmlContents {
  constructor(t, o, s, i, n, e, a) {
    super(s, o, t, i, n, a.padding, e)
    this._positions = a.positions || PointLabelPosition.ANY
    this._offset = a.offset
    this._rotation = a.rotation || 0
    this._anchorX = a.anchorX
    this._anchorY = a.anchorY
    this._pin = a.pin ? a.pin : void 0
  }
  requestToDrawLabel(t) {
    this.clip = t ?? INVALID_LAYER_CLIP
    this.group.addLabel(this)
  }
  computeMetrics() {
    this.computeMetricsBase()
  }
  toScreen(t) {
    const { boundsViewX: o, boundsViewY: s } = this.getBoundMetrics()
    this.toScreenBase(t, o, s, this._pin)
  }
  placeHtml(t, o, s, i) {
    if (!this.drawCommand || this.drawCommand === drawNullCommand) return false
    this.zOrder = i
    if (!this.drawCommand.mapAnchorPointSFCT(o, anchorPoint)) return false
    const n = this.labelContext.mapToViewTransformation
    if (!this.drawCommand.viewPointSFCT(anchorPoint, coord, n)) return false
    if (n.isPaintToBorder && !n.canDrawCoord(coord)) return false
    coord.y -= this.labelContext.getTopOffset()
    const e = this.findLabelPosition(
      coord,
      this.previousPosition,
      s,
      this._positions,
      9
    )
    if (e !== LabelPositions.INVALID_POSITION) {
      this._boundMetrics.boundsViewX = coord.x
      this._boundMetrics.boundsViewY = coord.y
      this.previousPosition = e
      if (this.isValidLocation(anchorPoint, true)) {
        this.toScreen(t)
        return true
      }
    }
    return false
  }
  resetLabelBounds(t, o) {
    const {
      boundsWidth: s,
      boundsHeight: i,
      ascent: n,
      descent: e,
    } = this.getBoundMetrics()
    const a = s
    const r = i
    let c, h
    let d = 0
    let l = [0, 0]
    if (isArray(this._offset)) l = this._offset
    else d = this._offset || 0
    if (!isUndefined(this._anchorX) || !isUndefined(this._anchorX)) {
      c = -(this._anchorX ?? 0) * a
      h = (1 - (this._anchorY ?? 0)) * r
    } else
      switch (o) {
        case LabelPositions.POINT_C_SHIFT:
          c = -(a >> 1)
          h = n >> 1
          break
        case LabelPositions.POINT_E_SHIFT:
          c = d
          h = n >> 1
          break
        case LabelPositions.POINT_W_SHIFT:
          c = -a - d
          h = n >> 1
          break
        case LabelPositions.POINT_N_SHIFT:
          c = -(a >> 1)
          h = -d
          break
        case LabelPositions.POINT_S_SHIFT:
          c = -(a >> 1)
          h = n + d
          break
        case LabelPositions.POINT_SE_SHIFT:
          c = Constants.COS_45 * d
          h = n + Constants.SIN_45 * d
          break
        case LabelPositions.POINT_NW_SHIFT:
          c = -a - Constants.COS_45 * d
          h = -Constants.SIN_45 * d
          break
        case LabelPositions.POINT_SW_SHIFT:
          c = -a - Constants.COS_45 * d
          h = n + Constants.SIN_45 * d
          break
        default:
          c = Constants.COS_45 * d
          h = -Constants.SIN_45 * d
      }
    c += l[0]
    h += l[1]
    const m = t.x + c
    const _ = t.y + h
    this.setLTRBAnchorXYAngleDeg(
      m,
      _ - n,
      m + a,
      _ + e,
      t.x,
      t.y,
      this._rotation,
      this._padding
    )
  }
  reuseStateFrom(t) {
    if (this.drawCommand) {
      const o = t.findLabelDrawCommand(
        this.drawCommand,
        DrawPointLabelHtmlContents
      )
      if (o) this.previousPosition = o.previousPosition
    }
  }
}
