import { SingleStateCollection } from './SingleStateCollection.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
export const HoverChangedEvent = 'HoverChanged'
export const HoverableChangedEvent = 'HoverableChanged'
export class HoverSupport {
  constructor(e, t, o, r) {
    this._layer = e
    this._collection = new SingleStateCollection(e, t, r, o)
  }
  get enabled() {
    return this._collection.enabled
  }
  set enabled(e) {
    if (e == this._collection.enabled) return
    if (e) this._collection.enable()
    else {
      const e = this._collection.disable()
      this.propagateHoverEvent(e)
    }
    this._layer.emit(HoverableChangedEvent, e)
  }
  isHovered(e) {
    return this._collection.isIncluded(e)
  }
  itemChanged(e) {
    this._collection.updateItem(e)
  }
  getHoveredItems() {
    return this._collection.getIncludedItems()
  }
  vacate(e) {
    const t = this._collection.removeItem(e)
    this.propagateHoverEvent(t)
  }
  clearHover() {
    const e = this._collection.removeAll()
    return this.event(e)
  }
  setHoveredItems(e, t) {
    if (!this.enabled) return null
    const o = this._collection.replaceIncluded(e)
    if (t && o.validation.failedToAdd)
      throw new ProgrammingError(
        'hoverObjects: The following items are not available on the client and could not be hovered: ' +
          o.validation.failedToAdd
      )
    else if (t && o.validation.badIds)
      throw new ProgrammingError(
        'hoverObjects: all hoverable items must have an id property'
      )
    return this.event(o)
  }
  updateFilter(e) {
    const t = this._collection.updateFilter(e)
    this.propagateHoverEvent(t)
  }
  event(e) {
    if (null !== e && (e.added.length > 0 || e.removed.length > 0))
      return { layer: this._layer, hovered: e.added, vacated: e.removed }
    return null
  }
  propagateHoverEvent(e) {
    const t = this.event(e)
    const o = this._layer.map
    if (o && t) o.updateHoverFromLayer(t)
  }
}
