import { EventedSupport } from './EventedSupport.js'
class Invalidation {
  static INVALIDATED_EVENT_NAME = 'Invalidated'
  _supportedEvents = [Invalidation.INVALIDATED_EVENT_NAME]
  get internalEventedSupport() {
    if (!this._intEventedSupport)
      this._intEventedSupport = new EventedSupport(this._supportedEvents)
    return this._intEventedSupport
  }
  invalidate() {
    this.emit(Invalidation.INVALIDATED_EVENT_NAME)
  }
  on(t, e, n) {
    return this.internalEventedSupport.on(t, e, n)
  }
  emit(t) {
    for (
      var e = arguments.length, n = new Array(e > 1 ? e - 1 : 0), i = 1;
      i < e;
      i++
    )
      n[i - 1] = arguments[i]
    this.internalEventedSupport.emit(t, ...n)
  }
}
export { Invalidation }
