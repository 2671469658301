import { NotImplementedError } from '../error/NotImplementedError.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { ArcBandSupport } from './ArcBandSupport.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
class ArcBand extends Shape {
  constructor(e, t, r, a, n, i) {
    super()
    this._reference = e ? e : null
    this._compareReferenceStrict(
      t.reference,
      'ArcBand::the center point reference must be equal to the arc reference'
    )
    this._checkParams(r, a)
    this.delegate = new ArcBandSupport(e, t, r, a, n, i, 'ArcBand', this)
  }
  _checkParams(e, t) {
    if (e < 0)
      throw new ProgrammingError(
        `Minimum radius length should be positive number, but was: ${e}`
      )
    if (t < 0)
      throw new ProgrammingError(
        `Maximum radius length should be positive number, but was: ${t}`
      )
    if (e > t)
      throw new ProgrammingError(
        'Minimum radius should be smaller than maximum radius'
      )
  }
  get type() {
    return ShapeType.ARC_BAND
  }
  get minRadius() {
    return this.delegate.minRadius
  }
  set minRadius(e) {
    this.delegate.minRadius = e
  }
  get maxRadius() {
    return this.delegate.maxRadius
  }
  set maxRadius(e) {
    this.delegate.maxRadius = e
  }
  get minRadiusStartCorner() {
    return this.delegate.minRadiusStartCorner
  }
  get minRadiusEndCorner() {
    return this.delegate.minRadiusEndCorner
  }
  get maxRadiusStartCorner() {
    return this.delegate.maxRadiusStartCorner
  }
  get maxRadiusEndCorner() {
    return this.delegate.maxRadiusEndCorner
  }
  updateMinRadius(e) {
    this.delegate.updateMinRadius(e)
  }
  updateMaxRadius(e) {
    this.delegate.updateMaxRadius(e)
  }
  updateStartAzimuth(e) {
    this.delegate.updateStartAzimuth(e)
  }
  updateEndAngle(e) {
    this.delegate.updateEndAngle(e)
  }
  get center() {
    return this.delegate.center
  }
  get focusPoint() {
    return this.delegate.focusPoint
  }
  get startAzimuth() {
    return this.delegate.startAzimuth
  }
  set startAzimuth(e) {
    this.delegate.startAzimuth = e
  }
  get sweepAngle() {
    return this.delegate.sweepAngle
  }
  set sweepAngle(e) {
    this.delegate.sweepAngle = e
  }
  get bounds() {
    return this.delegate.bounds
  }
  toString() {
    return `ArcBand[center${this.center.toString(true)}, minRadius[${
      this.minRadius
    }], maxRadius[${this.maxRadius}], startAzimuth[${
      this.startAzimuth
    }], sweepAngle[${this.sweepAngle}]]`
  }
  move2DToCoordinates(e, t) {
    this.delegate.move2DToCoordinates(e, t)
  }
  move2DToPoint(e) {
    this.delegate.move2DToPoint(e)
  }
  hashCode(e) {
    this.delegate.hashCode(e)
  }
  equals(e) {
    if (this === e) return true
    if (!ShapeUtil.referencesEqual(this.reference, e.reference)) return false
    return (
      e instanceof ArcBand &&
      this.type === e.type &&
      this.minRadius === e.minRadius &&
      this.maxRadius === e.maxRadius &&
      this.startAzimuth === e.startAzimuth &&
      this.sweepAngle === e.sweepAngle &&
      this.center.equals(e.center)
    )
  }
  distance2D(e, t) {
    throw new NotImplementedError('Descendant must implement distance2D method')
  }
  forwardAzimuth2D(e, t) {
    throw new NotImplementedError(
      'Descendant must implement forwardAzimuth2D method'
    )
  }
  calculatePoint(e, t) {
    throw new NotImplementedError(
      'Descendant must implement calculatePoint method'
    )
  }
  contains2DCoordinates(e, t) {
    throw new NotImplementedError(
      'Descendant must implement contains2DCoordinates method'
    )
  }
  calculateBounds() {
    throw new NotImplementedError(
      'Descendant must implement calculateBounds method'
    )
  }
  contains3DCoordinates(e, t, r) {
    throw new ProgrammingError(
      'contains3DCoordinates can not be called on a 2D ArcBand or Sector'
    )
  }
  translate2D(e, t) {
    this.delegate.translate2D(e, t)
  }
  invalidate() {
    this.delegate.invalidate()
  }
}
export let ArcBandCorner = (function (e) {
  e[(e['MIN_RADIUS_START_CORNER'] = 0)] = 'MIN_RADIUS_START_CORNER'
  e[(e['MAX_RADIUS_START_CORNER'] = 1)] = 'MAX_RADIUS_START_CORNER'
  e[(e['MIN_RADIUS_END_CORNER'] = 2)] = 'MIN_RADIUS_END_CORNER'
  e[(e['MAX_RADIUS_END_CORNER'] = 3)] = 'MAX_RADIUS_END_CORNER'
  return e
})({})
export { ArcBand }
export function isArcBand(e) {
  return ShapeType.contains(ShapeType.ARC_BAND, e.type)
}
