import { ProgrammingError } from '../../error/ProgrammingError.js'
function CGLabel() {
  this._locationLeft = [false, false]
  this._locationOn = [false, false]
  this._locationRight = [false, false]
}
CGLabel.prototype = Object.create(Object.prototype)
CGLabel.prototype.constructor = CGLabel
Object.defineProperty(CGLabel.prototype, 'locationLeft', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._locationLeft
  },
  set: function () {
    throw new ProgrammingError('locationLeft property is not mutable')
  },
})
Object.defineProperty(CGLabel.prototype, 'locationOn', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._locationOn
  },
  set: function () {
    throw new ProgrammingError('locationOn property is not mutable')
  },
})
Object.defineProperty(CGLabel.prototype, 'locationRight', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._locationRight
  },
  set: function () {
    throw new ProgrammingError('locationRight property is not mutable')
  },
})
CGLabel.prototype.toString = function () {
  return (
    this.letter(this._locationLeft[0]) +
    this.letter(this._locationOn[0]) +
    this.letter(this._locationRight[0]) +
    '.' +
    this.letter(this._locationLeft[1]) +
    this.letter(this._locationOn[1]) +
    this.letter(this._locationRight[1])
  )
}
CGLabel.prototype.letter = function (t) {
  return t ? 'I' : 'E'
}
export { CGLabel }
