import { Log } from '../../../util/Log.js'
import { Matrix4 } from './Matrix4.js'
import { isUndefined } from '../../../util/Lang.js'
import { Vector3 } from './Vector3.js'
export class Matrix3 {
  constructor() {
    this._elements = new Float64Array([1, 0, 0, 0, 1, 0, 0, 0, 1])
    if (arguments.length > 0)
      Log.error(
        'Matrix3: the constructor no longer reads arguments. use .set() instead.'
      )
  }
  set(t, e, r, n, s, i, o, a, l) {
    const f = this._elements
    f[0] = t
    f[1] = n
    f[2] = o
    f[3] = e
    f[4] = s
    f[5] = a
    f[6] = r
    f[7] = i
    f[8] = l
    return this
  }
  get elements() {
    return this._elements
  }
  identity() {
    this.set(1, 0, 0, 0, 1, 0, 0, 0, 1)
    return this
  }
  clone() {
    return new Matrix3().fromArray(this._elements)
  }
  copy(t) {
    const e = t._elements
    this.set(e[0], e[3], e[6], e[1], e[4], e[7], e[2], e[5], e[8])
    return this
  }
  setFromMatrix4(t) {
    const e = t.elements
    this.set(e[0], e[4], e[8], e[1], e[5], e[9], e[2], e[6], e[10])
    return this
  }
  multiplyScalar(t) {
    const e = this._elements
    e[0] *= t
    e[3] *= t
    e[6] *= t
    e[1] *= t
    e[4] *= t
    e[7] *= t
    e[2] *= t
    e[5] *= t
    e[8] *= t
    return this
  }
  determinant() {
    const t = this._elements
    const e = t[0],
      r = t[1],
      n = t[2],
      s = t[3],
      i = t[4],
      o = t[5],
      a = t[6],
      l = t[7],
      f = t[8]
    return e * i * f - e * o * l - r * s * f + r * o * a + n * s * l - n * i * a
  }
  getInverse(t, e) {
    if (t instanceof Matrix4)
      Log.error('Matrix3.getInverse no longer takes a Matrix4 argument.')
    const r = t._elements,
      n = this._elements,
      s = r[0],
      i = r[1],
      o = r[2],
      a = r[3],
      l = r[4],
      f = r[5],
      m = r[6],
      h = r[7],
      u = r[8],
      c = u * l - f * h,
      d = f * m - u * a,
      g = h * a - l * m,
      y = s * c + i * d + o * g
    if (0 === y) {
      const t = "Matrix3.getInverse(): can't invert matrix, determinant is 0"
      if (e || false) throw new Error(t)
      else Log.warn(t)
      return this.identity()
    }
    n[0] = c
    n[1] = o * h - u * i
    n[2] = f * i - o * l
    n[3] = d
    n[4] = u * s - o * m
    n[5] = o * a - f * s
    n[6] = g
    n[7] = i * m - h * s
    n[8] = l * s - i * a
    return this.multiplyScalar(1 / y)
  }
  transpose() {
    let t
    const e = this._elements
    t = e[1]
    e[1] = e[3]
    e[3] = t
    t = e[2]
    e[2] = e[6]
    e[6] = t
    t = e[5]
    e[5] = e[7]
    e[7] = t
    return this
  }
  flattenToArrayOffset(t, e) {
    Log.warn(
      'Matrix3: .flattenToArrayOffset is deprecated - just use .toArray instead.'
    )
    return this.toArray(t, e)
  }
  getNormalMatrix(t) {
    return this.setFromMatrix4(t).getInverse(this).transpose()
  }
  transposeIntoArray(t) {
    const e = this._elements
    t[0] = e[0]
    t[1] = e[3]
    t[2] = e[6]
    t[3] = e[1]
    t[4] = e[4]
    t[5] = e[7]
    t[6] = e[2]
    t[7] = e[5]
    t[8] = e[8]
    return this
  }
  fromArray(t) {
    this._elements.set(t)
    return this
  }
  toArray(t, e) {
    if (isUndefined(t)) t = []
    if (isUndefined(e)) e = 0
    const r = this._elements
    t[e] = r[0]
    t[e + 1] = r[1]
    t[e + 2] = r[2]
    t[e + 3] = r[3]
    t[e + 4] = r[4]
    t[e + 5] = r[5]
    t[e + 6] = r[6]
    t[e + 7] = r[7]
    t[e + 8] = r[8]
    return t
  }
  applyToVector3Array = (() => {
    let t
    return (e, r, n) => {
      if (isUndefined(t)) t = new Vector3()
      if (isUndefined(r)) r = 0
      if (isUndefined(n)) n = e.length
      for (let s = 0, i = r; s < n; s += 3, i += 3) {
        t.fromArray(e, i)
        t.applyMatrix3(this)
        t.toArray(e, i)
      }
      return e
    }
  })()
  applyToBuffer = (() => {
    let t
    return (e, r, n) => {
      if (isUndefined(t)) t = new Vector3()
      if (isUndefined(r)) r = 0
      if (isUndefined(n)) n = e.length / e.itemSize
      for (let s = 0, i = r; s < n; s++, i++) {
        t.x = e.getX(i)
        t.y = e.getY(i)
        t.z = e.getZ(i)
        t.applyMatrix3(this)
        e.setXYZ(t.x, t.y, t.z)
      }
      return e
    }
  })()
}
