var ShapeType = (function (R) {
  R[(R['POINT'] = 1)] = 'POINT'
  R[(R['POLYLINE'] = 2)] = 'POLYLINE'
  R[(R['POLYGON'] = 4)] = 'POLYGON'
  R[(R['COMPLEXPOLYGON'] = 8)] = 'COMPLEXPOLYGON'
  R[(R['COMPLEX_POLYGON'] = 8)] = 'COMPLEX_POLYGON'
  R[(R['SHAPELIST'] = 16)] = 'SHAPELIST'
  R[(R['SHAPE_LIST'] = 16)] = 'SHAPE_LIST'
  R[(R['CIRCLE'] = 64)] = 'CIRCLE'
  R[(R['CIRCLE_BY_CENTER_POINT'] = 128)] = 'CIRCLE_BY_CENTER_POINT'
  R[(R['CIRCLE_BY_3_POINTS'] = 256)] = 'CIRCLE_BY_3_POINTS'
  R[(R['BOUNDS'] = 512)] = 'BOUNDS'
  R[(R['PARALLEL'] = 1024)] = 'PARALLEL'
  R[(R['MERIDIAN'] = 2048)] = 'MERIDIAN'
  R[(R['CIRCULAR_ARC'] = 4096)] = 'CIRCULAR_ARC'
  R[(R['CIRCULAR_ARC_BY_CENTER_POINT'] = 8192)] = 'CIRCULAR_ARC_BY_CENTER_POINT'
  R[(R['CIRCULAR_ARC_BY_3_POINTS'] = 16384)] = 'CIRCULAR_ARC_BY_3_POINTS'
  R[(R['CIRCULAR_ARC_BY_BULGE'] = 32768)] = 'CIRCULAR_ARC_BY_BULGE'
  R[(R['GRID'] = 65536)] = 'GRID'
  R[(R['ELLIPSE'] = 131072)] = 'ELLIPSE'
  R[(R['GEO_BUFFER'] = 262144)] = 'GEO_BUFFER'
  R[(R['ARC'] = 524288)] = 'ARC'
  R[(R['ARC_BAND'] = 1048576)] = 'ARC_BAND'
  R[(R['EXTRUDED_SHAPE'] = 2097152)] = 'EXTRUDED_SHAPE'
  R[(R['SECTOR'] = 4194304)] = 'SECTOR'
  R[(R['ORIENTED_BOX'] = 8388608)] = 'ORIENTED_BOX'
  return R
})(ShapeType || {})
;(function (R) {
  function C(R, C) {
    return 0 != (R & C)
  }
  R.contains = C
  function E(R) {
    const C = []
    for (const E of Object.keys(ShapeType))
      if (ShapeType.hasOwnProperty(E) && 0 !== (parseInt(ShapeType[E]) & R))
        C.push(E)
    return '[' + C.join(', ') + ']'
  }
  R.getDisplayName = E
})(ShapeType || (ShapeType = {}))
export { ShapeType }
