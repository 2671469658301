import { Controller } from './Controller.js'
import { HandleEventResult } from './HandleEventResult.js'
import { GestureEventType } from '../input/GestureEventType.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { createPoint } from '../../shape/ShapeFactory.js'
import { Constants } from '../../util/Constants.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
const ROTATION_DAMPING_FACTOR_3D = -10
const RIGHT_MOUSE_BUTTON = 2
function isTouchRotate(e, t) {
  const n = t ? GestureEventType.ROTATE_END : GestureEventType.ROTATE
  return 'touch' === e.inputType && e.type === n
}
function isTouchTwoFingerDrag(e, t) {
  const n = t
    ? GestureEventType.TWO_FINGER_DRAG_END
    : GestureEventType.TWO_FINGER_DRAG
  return 'touch' === e.inputType && e.type === n
}
function isRightMouseDrag(e, t) {
  const n = t ? GestureEventType.DRAG_END : GestureEventType.DRAG
  return 'mouse' === e.inputType && e.type === n && isRightMouseButtonDown(e)
}
function isRightMouseButtonDown(e) {
  if ('mouse' === e.inputType) {
    const t = undefined
    const n = e.downEvent.domEvent
    const o = undefined
    return (n ? n.button : 0) === RIGHT_MOUSE_BUTTON
  }
  return false
}
function getTouchRotateAngle(e) {
  const t = e.downEvent
  let n = e.angle - t.angle
  if (n > 180) n -= 360
  return n
}
export class RotateController extends Controller {
  constructor() {
    super()
    this._isRotating = false
  }
  onGestureEvent(e) {
    if (!this.map) return HandleEventResult.EVENT_IGNORED
    const t = this.isRotateEvent(e)
    const n = this.isRotateEndEvent(e)
    if (t && !n) {
      const t = this.getRotationCenter(e)
      if (!this._isRotating) {
        if (null === t.reference) this.map.mapNavigator.beginRotate(t.x, t.y)
        else {
          const e = undefined
          const n = createTransformation(
            t.reference,
            this.map.reference
          ).transform(t)
          this.map.mapNavigator.beginRotateWorld(n)
        }
        this._isRotating = true
      } else {
        const t = undefined
        if (this.map.is3D()) {
          const t = this.getYawAngle(e)
          const n = this.getPitchAngle(e)
          this.map.mapNavigator.incrementalRotateAngles(t, n)
        } else {
          const t = this.getRotationAngle(e)
          this.map.mapNavigator.incrementalRotateAngles(t, 0)
        }
      }
      return HandleEventResult.EVENT_HANDLED
    } else if (t && n) {
      this.map.mapNavigator.endRotate()
      this._isRotating = false
      return HandleEventResult.EVENT_HANDLED
    }
    return HandleEventResult.EVENT_IGNORED
  }
  isRotateEvent(e) {
    return (
      isRightMouseDrag(e, false) ||
      isTouchRotate(e, false) ||
      isTouchTwoFingerDrag(e, false) ||
      this.isRotateEndEvent(e)
    )
  }
  isRotateEndEvent(e) {
    return (
      isRightMouseDrag(e, true) ||
      isTouchRotate(e, true) ||
      isTouchTwoFingerDrag(e, true)
    )
  }
  getRotationCenter(e) {
    if (
      isRightMouseDrag(e, false) &&
      this.map &&
      this.map.reference.referenceType !== ReferenceType.GEOCENTRIC
    )
      return createPoint(null, [
        this.map.viewSize[0] / 2,
        this.map.viewSize[1] / 2,
      ])
    return e.viewPoint
  }
  getYawAngle(e) {
    if (isRightMouseDrag(e, false)) {
      const t = e
      const n = t.downEvent
      const o = undefined
      return (t.viewPoint.x - n.viewPoint.x) / ROTATION_DAMPING_FACTOR_3D
    }
    if (isTouchRotate(e, false)) return getTouchRotateAngle(e)
    return 0
  }
  getPitchAngle(e) {
    if (isRightMouseDrag(e, false) || isTouchTwoFingerDrag(e, false)) {
      const t = e.downEvent
      const n = undefined
      return (e.viewPoint.y - t.viewPoint.y) / ROTATION_DAMPING_FACTOR_3D
    }
    return 0
  }
  getRotationAngle(e) {
    if (!this.map) return 0
    if (isRightMouseDrag(e, false)) {
      const t = e
      const n = t.downEvent
      const o = createPoint(null, [
        this.map.viewSize[0] / 2,
        this.map.viewSize[1] / 2,
      ])
      const i = undefined
      const s = undefined
      return (
        (Math.atan2(n.viewPoint.y - o.y, n.viewPoint.x - o.x) *
          Constants.RAD2DEG -
          Math.atan2(t.viewPoint.y - o.y, t.viewPoint.x - o.x) *
            Constants.RAD2DEG) %
        360
      )
    }
    if (isTouchRotate(e, false)) return getTouchRotateAngle(e)
    return 0
  }
}
