import { NotImplementedError } from '../../../error/NotImplementedError.js'
import { LineType } from '../../../geodesy/LineType.js'
import { GeodesicLinePath } from './GeodesicLinePath.js'
import { RhumbLinePath } from './RhumbLinePath.js'
import { RhumbArcPath } from './RhumbArcPath.js'
import { GeodesicArcPath } from './GeodesicArcPath.js'
import { IterativePen } from './IterativePen.js'
export class GeodeticPen extends IterativePen {
  constructor() {
    super()
    this._geodesicLinePath = new GeodesicLinePath()
    this._geodesicArcPath = new GeodesicArcPath()
    this._rhumbLinePath = new RhumbLinePath()
    this._rhumbArcPath = new RhumbArcPath()
  }
  getLinePath(e, t, i, r) {
    let n
    switch (r) {
      case LineType.SHORTEST_DISTANCE:
        n = this._geodesicLinePath
        n.initializePath(e, t, i)
        return n
      case LineType.CONSTANT_BEARING:
        n = this._rhumbLinePath
        n.initializePath(e, t, i)
        return n
      default:
        throw new NotImplementedError(
          `GeodeticPen::_getLinePath - unsupported line type: ${r}`
        )
    }
  }
  getArcPath(e, t, i, r, n, h, o, a) {
    let s
    switch (a) {
      case LineType.SHORTEST_DISTANCE:
        s = this._geodesicArcPath
        s.initializeArc(e, t, i, r, n, h, o)
        return s
      case LineType.CONSTANT_BEARING:
        s = this._rhumbArcPath
        s.initializeArc(e, t, i, r, n, h, o)
        return s
      default:
        throw new NotImplementedError(
          `GeodeticPen::_getArcPath - unsupported line type: ${LineType}`
        )
    }
  }
}
