import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { XYZBounds } from '../../../shape/XYZBounds.js'
import { clamp, squaredDistance2D } from '../../../util/Cartesian.js'
const AVERAGE_ELEMENTS_PER_CELL = 8
const MAX_CELL_COUNT = 1e5
const EPSILON = 1e-10
export class ClusterGrid {
  constructor(t) {
    this._gridBounds = calculateGridBounds(t)
    const e = Math.min(MAX_CELL_COUNT, t.length / AVERAGE_ELEMENTS_PER_CELL)
    let s
    let i
    if (this._gridBounds.height < EPSILON) {
      s = Math.max(2, e)
      i = 1
    } else if (this._gridBounds.width < EPSILON) {
      s = 1
      i = Math.max(2, e)
    } else {
      const t = this._gridBounds.height / this._gridBounds.width
      const n = Math.sqrt(e / t)
      s = Math.max(2, Math.ceil(n))
      i = Math.max(2, Math.ceil(n * t))
    }
    this._cells = []
    for (let t = 0; t < s; t++) this._cells[t] = []
    this._gridCellWidth = this._gridBounds.width / s
    this._gridCellHeight = this._gridBounds.height / i
    this._gridCellMaxIndexX = s - 1
    this._gridCellMaxIndexY = i - 1
    for (let e = 0; e < t.length; e++) {
      const s = t[e]
      this.add(s)
    }
  }
  add(t) {
    const e = t.getCenter()
    const s = this.getGridIndexX(e.x)
    const i = this.getGridIndexY(e.y)
    let n = this._cells[s][i]
    if (!n) {
      n = new Cell()
      this._cells[s][i] = n
    }
    n.add(t)
  }
  remove(t) {
    const e = t.getCenter()
    const s = this.getGridIndexX(e.x)
    const i = this.getGridIndexY(e.y)
    const n = this._cells[s][i]
    if (!n) throw new ProgrammingError('Cell should not be null')
    if (!n.remove(t))
      throw new ProgrammingError('Cluster was not added to cell')
  }
  applyWithinDistance(t, e, s) {
    const i = 1.01 * e
    const n = t.getCenter()
    const r = this.getGridIndexX(n.x - i)
    const l = this.getGridIndexY(n.y - i)
    const o = this.getGridIndexX(n.x + i)
    const d = this.getGridIndexY(n.y + i)
    const h = e * e
    for (let e = r; e <= o; e++) {
      if (e < 0 || e >= this._cells.length) continue
      const i = this._cells[e]
      for (let e = l; e <= d; e++) {
        if (e < 0 || e >= i.length) continue
        const n = i[e]
        if (n?.applyWithinDistance(t, h, s)) return
      }
    }
  }
  getGridIndexX(t) {
    if (this._gridCellWidth < EPSILON) return 0
    const e = t - this._gridBounds.x
    const s = Math.floor(e / this._gridCellWidth)
    return clamp(s, 0, this._gridCellMaxIndexX)
  }
  getGridIndexY(t) {
    if (this._gridCellHeight < EPSILON) return 0
    const e = t - this._gridBounds.y
    const s = Math.floor(e / this._gridCellHeight)
    return clamp(s, 0, this._gridCellMaxIndexY)
  }
}
class Cell {
  constructor() {
    this._clusters = new Set()
  }
  add(t) {
    this._clusters.add(t)
  }
  remove(t) {
    return this._clusters.delete(t)
  }
  applyWithinDistance(t, e, s) {
    const { x: i, y: n } = t.getCenter()
    let r = false
    const l = this._clusters.values()
    let o
    while (!r && !(o = l.next()).done) {
      const l = o.value
      if (l !== t) {
        const t = l.getCenter()
        const o = squaredDistance2D(i, n, t.x, t.y)
        if (o <= e && s(l, o)) r = true
      }
    }
    return r
  }
}
function calculateGridBounds(t) {
  const e = new XYZBounds(null)
  if (t.length > 0) {
    const s = t[0].getCenter()
    e.setTo2D(s.x, 0, s.y, 0)
  }
  for (let s = 1; s < t.length; s++) {
    const i = t[s]
    e.setToIncludeSimplePoint2D(i.getCenter())
  }
  const s = 1e-5 * e.width
  const i = 1e-5 * e.height
  e.translate2D(-s, -i)
  e.width = e.width + 2 * s
  e.height = e.height + 2 * i
  return e
}
