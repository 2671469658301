import { ProgrammingError } from '../../error/ProgrammingError.js'
import { isDefined, isString } from '../../util/Lang.js'
import { LTSCapabilities } from '../capabilities/LTSCapabilities.js'
import { LTSSupportedOperations } from '../capabilities/LTSCapabilitiesOperations.js'
import { RasterDataType } from './RasterDataType.js'
import { RasterSamplingMode } from './RasterSamplingMode.js'
import { UrlTileSetModel } from './UrlTileSetModel.js'
import { URL } from '../../util/URL.js'
function isFusionConstructorOptions(e) {
  return void 0 !== e.structure
}
function isTileMatrixStructure(e) {
  return void 0 !== e.tileMatrix
}
function extractStructure(e) {
  if (isFusionConstructorOptions(e)) {
    const t = e.structure
    if (isTileMatrixStructure(t))
      throw new ProgrammingError(
        `Fusion only supports quad-tree tile set structures.`
      )
    else return t
  } else
    return {
      reference: e.reference,
      bounds: e.bounds,
      level0Columns: e.level0Columns,
      level0Rows: e.level0Rows,
      tileWidth: e.tileWidth,
      tileHeight: e.tileHeight,
    }
}
class FusionTileSetModel extends UrlTileSetModel {
  constructor(e) {
    if (!e || !isString(e.url) || !isString(e.coverageId))
      throw new ProgrammingError(
        'FusionTileSetModel::cannot construct Fusion model without url and coverageId parameters'
      )
    let t
    if (e.dataType)
      if (
        e.dataType !== RasterDataType.IMAGE &&
        e.dataType !== RasterDataType.ELEVATION &&
        e.dataType !== RasterDataType.TIN_ELEVATION
      )
        throw new ProgrammingError(
          'FusionTileSetModel::If specified, dataType must be either RasterDataType.IMAGE or RasterDataType.ELEVATION'
        )
      else t = e.dataType
    else t = RasterDataType.IMAGE
    if (
      e.samplingMode &&
      e.samplingMode !== RasterSamplingMode.AREA &&
      e.samplingMode !== RasterSamplingMode.POINT
    )
      throw new ProgrammingError(
        'FusionTileSetModel::If specified, samplingMode must be either FusionTileSetModel.SamplingMode.AREA or FusionTileSetModel.SamplingMode.POINT'
      )
    const r = undefined
    super({
      structure: extractStructure(e),
      dataType: t,
      samplingMode: e.samplingMode,
      baseURL: `${e.url}?SERVICE=LTS&VERSION=1.0.0&REQUEST=getTile&COVERAGE_ID=${e.coverageId}`,
      credentials: e.credentials,
      requestHeaders: e.requestHeaders,
      requestParameters: e.requestParameters,
      subdomains: e.subdomains,
      modelDescriptor: Object.freeze({
        source: e.url,
        name: e.coverageId || e.name,
        description: 'LuciadFusion Tile Model',
        type: t,
      }),
    })
    this.useCors = !URL.isLocalURL(e.url)
  }
  static createFromCapabilities(e, t, r) {
    r = r || {}
    const i = e.coverages.filter((e) => e.id === t)[0]
    if (!isDefined(i))
      throw new ProgrammingError(`there is no coverage "${t}" in capabilities`)
    const s = e.supportedOperations.getOperations(
      LTSSupportedOperations.GetTile
    )
    if (s) {
      const e = undefined
      const t = s.filter((e) => 'GET' === e.method)[0].endPoint
      const o = {
        reference: i.getReference(),
        bounds: i.getBounds(),
        level0Columns: i.level0Columns,
        level0Rows: i.level0Rows,
        tileWidth: i.tileWidth,
        tileHeight: i.tileHeight,
      }
      return new FusionTileSetModel({
        structure: o,
        url: r.url || t,
        coverageId: i.id,
        dataType: i.type,
        samplingMode: i.samplingMode,
        credentials: r.credentials,
        requestHeaders: r.requestHeaders,
        requestParameters: r.requestParameters,
      })
    }
    throw new ProgrammingError(
      'FusionTileSetModel::cannot create Fusion model from capabilities because GetTile operation is not supported.'
    )
  }
  static createFromURL(e, t, r) {
    return LTSCapabilities.fromURL(e, r).then((e) =>
      FusionTileSetModel.createFromCapabilities(e, t, r)
    )
  }
  getTileURL(e, t) {
    const r = `${e}&LEVEL=${t.level}&ROW=${t.y}&COLUMN=${t.x}`
    return super.getTileURL(r, t)
  }
  get requestParameters() {
    return super.requestParameters
  }
  set requestParameters(e) {
    super.requestParameters = e
  }
}
;(function (e) {
  let t = (function (e) {
    e[(e['IMAGE'] = RasterDataType.IMAGE)] = 'IMAGE'
    e[(e['ELEVATION'] = RasterDataType.ELEVATION)] = 'ELEVATION'
    return e
  })({})
  e.DataType = t
  let r = (function (e) {
    e[(e['AREA'] = RasterSamplingMode.AREA)] = 'AREA'
    e[(e['POINT'] = RasterSamplingMode.POINT)] = 'POINT'
    return e
  })({})
  e.SamplingMode = r
})(FusionTileSetModel || (FusionTileSetModel = {}))
export { FusionTileSetModel }
