import { Constants } from '../util/Constants.js'
import { ArcBand, ArcBandCorner } from './ArcBand.js'
import { XYZArcBandUtil } from './XYZArcBandUtil.js'
export class XYZArcBand extends ArcBand {
  constructor(t, s, i, n, e, a) {
    super(t, s, i, n, e, a)
    this.validateReference(t)
  }
  get isGeodetic() {
    return false
  }
  get coordinateType() {
    return XYZArcBandUtil.coordinateType
  }
  distance2D(t, s) {
    return XYZArcBandUtil.distance2D(t, s)
  }
  forwardAzimuth2D(t, s) {
    return XYZArcBandUtil.forwardAzimuth2D(t, s)
  }
  calculatePoint(t, s) {
    return XYZArcBandUtil.calculatePoint(t, s, this.center)
  }
  contains2DCoordinates(t, s) {
    return XYZArcBandUtil.contains2DCoordinates(t, s, this)
  }
  calculateBounds() {
    return XYZArcBandUtil.calculateBounds(this)
  }
  copy() {
    return new XYZArcBand(
      this.reference,
      this.center,
      this.minRadius,
      this.maxRadius,
      this.startAzimuth,
      this.sweepAngle
    )
  }
  corner2DEditablePointSFCT(t, s) {
    switch (t) {
      case ArcBandCorner.MIN_RADIUS_START_CORNER:
        s.move2D(
          this.center.x +
            this.minRadius * Math.cos(this.startAzimuth * Constants.DEG2RAD),
          this.center.y +
            this.minRadius * Math.sin(this.startAzimuth * Constants.DEG2RAD)
        )
        break
      case ArcBandCorner.MAX_RADIUS_START_CORNER:
        s.move2D(
          this.center.x +
            this.maxRadius * Math.cos(this.startAzimuth * Constants.DEG2RAD),
          this.center.y +
            this.maxRadius * Math.sin(this.startAzimuth * Constants.DEG2RAD)
        )
        break
      case ArcBandCorner.MIN_RADIUS_END_CORNER:
        s.move2D(
          this.center.x +
            this.minRadius *
              Math.cos(
                (this.startAzimuth + this.sweepAngle) * Constants.DEG2RAD
              ),
          this.center.y +
            this.minRadius *
              Math.sin(
                (this.startAzimuth + this.sweepAngle) * Constants.DEG2RAD
              )
        )
        break
      case ArcBandCorner.MAX_RADIUS_END_CORNER:
        s.move2D(
          this.center.x +
            this.maxRadius *
              Math.cos(
                (this.startAzimuth + this.sweepAngle) * Constants.DEG2RAD
              ),
          this.center.y +
            this.maxRadius *
              Math.sin(
                (this.startAzimuth + this.sweepAngle) * Constants.DEG2RAD
              )
        )
        break
    }
  }
}
