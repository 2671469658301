import { ProgrammingError } from '../../error/ProgrammingError.js'
import { getReference } from '../../reference/ReferenceProvider.js'
import { hasProperty, isFunction, isString } from '../../util/Lang.js'
import { isBounds } from '../../shape/Bounds.js'
import { createOptimizedEventedSupport } from '../../util/EventedSupport.js'
import { WFSFeatureStore } from '../store/WFSFeatureStore.js'
import { Log } from '../../util/Log.js'
const DEFAULT_REFERENCE = 'CRS:84'
class FeatureModel {
  constructor(e, r) {
    this._eventSupport = createOptimizedEventedSupport(['ModelChanged'])
    if (!e || !isFunction(e.query))
      throw new ProgrammingError(
        'FeatureModel:: the store must have a query method'
      )
    this._store = e
    const { modelDescriptor: t, reference: o, bounds: i } = r || {}
    const { source: s, name: n, description: u, type: c } = t || {}
    this._modelDescriptor = {
      source: s || e.target || 'N/A',
      name: n || 'N/A',
      description: u || 'N/A',
      type: c || 'N/A',
    }
    this._reference = o
      ? isString(o)
        ? getReference(o)
        : o
      : e instanceof WFSFeatureStore
      ? e.getReference()
      : getReference(DEFAULT_REFERENCE)
    if (e instanceof WFSFeatureStore)
      e.checkFeatureModelReference(this._reference)
    if (i && !isBounds(i))
      throw new ProgrammingError(
        'WFSFeatureStore: options.bounds are not valid bounds'
      )
    if (i && !referencesEqual(i.reference, this._reference))
      throw new ProgrammingError(
        'WFSFeatureStore: options.bounds incorrect reference'
      )
    const a = i || e.bounds
    if (!i && a)
      Log.log(`FeatureModel.bounds defined: ${a.width} x ${a.height}`)
    this._bounds = a ? a.copy() : null
    if (isFunction(this._store.on))
      this._store.on('StoreChanged', (e, r, t) => {
        this._eventSupport.emitModelChangedEvent(e, r, t)
      })
    if (isFunction(this._store.spatialQuery))
      this.spatialQuery = (e, r, t) => this._store.spatialQuery(e, r, t)
    if (isFunction(this._store.get)) this.get = (e) => this._store.get(e)
    if (isFunction(this._store.add)) this.add = (e, r) => this._store.add(e, r)
    if (isFunction(this._store.put)) this.put = (e, r) => this._store.put(e, r)
    if (isFunction(this._store.remove))
      this.remove = (e) => this._store.remove(e)
  }
  query(e, r) {
    return this._store.query(e, r)
  }
  get reference() {
    return this._reference
  }
  get bounds() {
    return this._bounds
  }
  get coordinateType() {
    return this._reference.coordinateType
  }
  get store() {
    return this._store
  }
  get modelDescriptor() {
    return this._modelDescriptor
  }
  set modelDescriptor(e) {
    if (
      !e ||
      !['type', 'name', 'description', 'source'].every(
        (r) => hasProperty(e, r) && isString(e[r])
      )
    )
      throw new ProgrammingError('FeatureModel::invalid modelDescriptor')
    this._modelDescriptor = Object.freeze(e)
  }
  on(e, r, t, o) {
    if (o && o.query && isFunction(this._store.on)) {
      const e = this._store.on(
        'StoreChanged',
        (e, o, i) => {
          r.call(t, e, o, i)
        },
        this,
        o
      )
      return {
        remove: function () {
          e.remove()
        },
      }
    } else return this._eventSupport.on(e, r, t)
  }
  emit(e) {
    for (
      var r = arguments.length, t = new Array(r > 1 ? r - 1 : 0), o = 1;
      o < r;
      o++
    )
      t[o - 1] = arguments[o]
    return this._eventSupport.emit(e, ...t)
  }
}
function referencesEqual(e, r) {
  return e ? e.equals(r) : !r
}
export { FeatureModel }
