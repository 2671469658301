import { NotFoundError } from '../../../error/NotFoundError.js'
import { Log } from '../../../util/Log.js'
import { PhotonImageDecoder } from './PhotonImageDecoder.js'
import { AbortError } from '../../../error/AbortError.js'
import { isNumber, isString } from '../../../util/Lang.js'
var trident = navigator.userAgent.match(/Trident\/(\d+)/)
var msie = navigator.userAgent.match(/MSIE (\d+)/)
var isIE = trident || msie
function getSizeInPixels(e, t, r, o) {
  var n
  if (!isNumber(r) || r <= 0) r = 16384
  if (!e) n = t
  else if ('100%' === e) n = t
  else if (isNumber(e, false)) n = e
  else if (isString(e) && e.endsWith('px'))
    n = Math.round(Number.parseFloat(e.substring(0, e.lastIndexOf('px'))))
  else if (isString(e) && e.endsWith('%')) {
    var a = Number.parseFloat(e.substring(0, e.lastIndexOf('%')))
    n = Math.round((t * a) / 100)
  } else {
    Log.warn('Got unexpected image size: ' + e)
    n = t
  }
  if (n > r) {
    Log.warn(
      'image ' +
        o +
        ' can not be greater than ' +
        r +
        'px on a WebGLMap: scaling ' +
        n +
        'px down to ' +
        r +
        'px.'
    )
    n = r
  }
  return n
}
function PhotonCanvasImageDecoder(e) {
  this._photon = e
  this._decoder = new PhotonImageDecoder(e)
  this._canvas = document.createElement('canvas')
  this._context = this._canvas.getContext('2d', { willReadFrequently: true })
}
PhotonCanvasImageDecoder.prototype.decodeCanvas = function (e, t, r, o, n, a) {
  let i = getSizeInPixels(r, e.width, n, 'width')
  let s = getSizeInPixels(o, e.height, a, 'height')
  if (i !== e.width || s !== e.height) {
    this._canvas.width = i
    this._canvas.height = s
    this._context.drawImage(
      e,
      0,
      0,
      e.width,
      e.height,
      0,
      0,
      this._canvas.width,
      this._canvas.height
    )
    e = this._canvas
  }
  var d
  var h = e
    .getContext('2d', { willReadFrequently: true })
    .getImageData(0, 0, e.width, e.height)
  return this._decoder._createImageFromDecodedData(
    h.width,
    h.height,
    this._photon.PixelFormat.Rgba8888,
    h.data,
    t
  )
}
PhotonCanvasImageDecoder.prototype.decodeImage = function (
  e,
  t,
  r,
  o,
  n,
  a,
  i
) {
  var s = this._photon
  var d = this._decoder
  var h = this._canvas
  var c = this._context
  function g() {
    h.width = getSizeInPixels(r, e.width, n, 'width')
    h.height = getSizeInPixels(o, e.height, a, 'height')
    c.drawImage(e, 0, 0, h.width, h.height)
    var i = c.getImageData(0, 0, h.width, h.height)
    var g
    return d._createImageFromDecodedData(
      h.width,
      h.height,
      s.PixelFormat.Rgba8888,
      i.data,
      t
    )
  }
  if (e.src && e.complete) {
    var u = 0 === e.src.indexOf('data:')
    var m
    if (isIE && u)
      return new Promise(function (e, t) {
        setTimeout(function () {
          e(g())
        }, 1)
      })
    return g()
  }
  return new Promise(function (t, r) {
    e.onerror = function (t) {
      r(new NotFoundError(e.src))
    }
    e.onload = function () {
      try {
        t(g())
      } catch (e) {
        Log.error(e)
        r(e)
      }
    }
    if (i) {
      const t = () => {
        e.onerror = null
        e.onload = null
        e.src = ''
        r(new AbortError('Image request cancelled'))
      }
      if (i.aborted) {
        t()
        return
      }
      i.addEventListener('abort', t)
    }
  })
}
PhotonCanvasImageDecoder.prototype.decodeUrl = function (
  e,
  t,
  r,
  o,
  n,
  a,
  i,
  s,
  d,
  h
) {
  if (n) return this._decodeUrlWithHeaders(e, t, r, o, n, a, i, s, d, h)
  else return this._decodeUrl(e, t, r, o, a, i, s, d, h)
}
PhotonCanvasImageDecoder.prototype._decodeUrl = function (
  e,
  t,
  r,
  o,
  n,
  a,
  i,
  s,
  d
) {
  var h = document.createElement('img')
  if (r && 0 !== e.indexOf('data:'))
    h.crossOrigin = o ? 'use-credentials' : 'anonymous'
  var c = this.decodeImage(h, t, n, a, i, s, d)
  h.src = e
  return c
}
PhotonCanvasImageDecoder.prototype._decodeUrlWithHeaders = function (
  e,
  t,
  r,
  o,
  n,
  a,
  i,
  s,
  d,
  h
) {
  var c = this
  return this._decoder
    ._requestUrlAsEncodedBuffer(e, o, n, h)
    .then(function (e) {
      const r = URL.createObjectURL(new Blob([e.data], { type: e.contentType }))
      return c._decodeUrl(r, t, false, false, a, i, s, d, h).then((e) => {
        URL.revokeObjectURL(r)
        return e
      })
    })
}
export { PhotonCanvasImageDecoder }
