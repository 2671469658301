import { Photon } from '../../../../gen/photon/photon_painter.js'
import { Hash } from '../../../../util/Hash.js'
import { ProgrammingError } from '../../../../error/ProgrammingError.js'
import { ObjectReleaseTracker } from '../../../../util/ObjectReleaseTracker.js'
import { createTransformation } from '../../../../transformation/TransformationFactory.js'
import {
  isProjectionTargetClosestSurfaceOptions,
  ProjectionTarget,
} from '../../../style/PanoramaStyle.js'
import { ShapeUtil } from '../../../../shape/ShapeUtil.js'
import { GeocentricReference } from '../../../../reference/GeocentricReference.js'
import { getReference } from '../../../../reference/ReferenceProvider.js'
import { getTransformation } from '../../../../util/CacheUtil.js'
import { createPoint } from '../../../../shape/ShapeFactory.js'
import { CubeMapFace } from '../../../../model/tileset/CubeMapFace.js'
import { PanoramaType } from '../../../../model/tileset/PanoramaType.js'
import { PanoramicImageProjectionType } from '../../../../model/tileset/PanoramicImageProjectionType.js'
import { Constants } from '../../../../util/Constants.js'
import { isPromise } from '../../../../util/PromiseUtil.js'
import { createPaintContextHashPanoramic } from '../../../photon/PaintContextUtil.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
function obfuscateString(e) {
  return e
}
const DEBUG_PANORAMIC = false
function rot13(e) {
  return e.replace(/[a-zA-Z]/g, function (e) {
    return String.fromCharCode(
      (e <= 'Z' ? 90 : 122) >= (e = e.charCodeAt(0) + 13) ? e : e - 26
    )
  })
}
const reqToPhotonFaceId = (e) => {
  if (e.face) return toPhotonFaceId(e.face)
  else return Photon.FaceIdentifier.Equirectangular
}
const toPhotonFaceId = (e) => {
  switch (e) {
    case CubeMapFace.TOP:
      return Photon.FaceIdentifier.Top
    case CubeMapFace.BOTTOM:
      return Photon.FaceIdentifier.Bottom
    case CubeMapFace.LEFT:
      return Photon.FaceIdentifier.Left
    case CubeMapFace.RIGHT:
      return Photon.FaceIdentifier.Right
    case CubeMapFace.FRONT:
      return Photon.FaceIdentifier.Front
    case CubeMapFace.BACK:
      return Photon.FaceIdentifier.Back
    default:
      throw new ProgrammingError('Unknown FaceIdentifier for PanoramicCubeFace')
  }
}
const toRIAFace = (e) => {
  switch (e) {
    case Photon.FaceIdentifier.Top:
      return CubeMapFace.TOP
    case Photon.FaceIdentifier.Bottom:
      return CubeMapFace.BOTTOM
    case Photon.FaceIdentifier.Left:
      return CubeMapFace.LEFT
    case Photon.FaceIdentifier.Right:
      return CubeMapFace.RIGHT
    case Photon.FaceIdentifier.Front:
      return CubeMapFace.FRONT
    case Photon.FaceIdentifier.Back:
      return CubeMapFace.BACK
    default:
      throw new ProgrammingError('Unknown PanoramicCubeFace for FaceIdentifier')
  }
}
const groupByBaseOpacity = (e) =>
  e.reduce(
    (e, t) => ({ ...e, [t.baseOpacity]: [...(e[t.baseOpacity] || []), t] }),
    {}
  )
const toPhotonOcclusionMode = (e) => {
  if (e === ProjectionTarget.CLOSEST_SURFACE)
    return Photon.PanoramicOcclusionMode.On
  else return Photon.PanoramicOcclusionMode.Off
}
let LICENSE_CHECK_REQUESTED = false
let LICENSE_CHECKED = false
let LICENSE_VALID = false
export class PhotonPanoramaCommand {
  constructor(e, t, r, a, o, n, i) {
    this._is3d = e
    this._worldReference = t
    this._tracker = new ObjectReleaseTracker()
    this._photonView = r
    this._photonGraphics = a
    this._layer = n
    this._paintContext = createPaintContextHashPanoramic(this._layer.id)
    this._hash = new Hash()
    this._photonImageProvider = o
    this._invalidator = i
    this._projectiveTextureMap = new Map()
    this._currentDrawItems = new Map()
    this._callsWhileLicenseChecking = []
    if (LICENSE_CHECKED) this._licenseCallback = void 0
    else
      this._licenseCallback = (e) => {
        LICENSE_VALID = e
        LICENSE_CHECKED = true
        if (LICENSE_VALID)
          for (const e of this._callsWhileLicenseChecking)
            if ('add' === e.type) this.add(e.feature, e.objectId, e.drawItems)
            else if ('update' === e.type)
              this.update(e.feature, e.objectId, e.oldDrawItems, e.newDrawItems)
            else if ('remove' === e.type) this.remove(e.feature, e.drawItems)
        this._callsWhileLicenseChecking = []
        this._licenseCallback = void 0
      }
  }
  canHandle(e) {
    return (
      'panorama' === e.type &&
      this._worldReference instanceof GeocentricReference &&
      !!this._layer.panoramaModel
    )
  }
  getDrawItems(e) {
    return e.map((e) => {
      const t = e
      return {
        command: this,
        geometryId: this.getGeometryId(t.location),
        styleId: this.getStyleId(t.style),
        style: t.style,
        metadata: {
          location: t.location,
          context: t.context,
          panoId: this.getPanoramicId(t.feature, t.context),
        },
        shape: t.location,
        renderPassId: t.renderPassId,
        feature: null,
        selected: false,
        objectId: 0,
        zStyle: {
          drapeTarget: DrapeTarget.NOT_DRAPED,
          zToZero: false,
          hasDepth: false,
          aboveGround: false,
          viewDisplacement: false,
        },
      }
    })
  }
  resolveStyle(e) {}
  release() {
    this._tracker.release()
  }
  updateState(e, t, r) {
    const a = this._currentDrawItems.get(t) || []
    if (0 === a.length && 0 === r.length) return
    else if (0 !== a.length && 0 === r.length) {
      this._currentDrawItems.delete(t)
      this.remove(e, a)
    } else if (0 === a.length && 0 !== r.length) {
      this._currentDrawItems.set(t, r)
      this.add(e, t, r)
    } else {
      this._currentDrawItems.set(t, r)
      this.update(e, t, a, r)
    }
  }
  usesPhotonShapePainter() {
    return false
  }
  add(e, t, r) {
    if (!LICENSE_CHECK_REQUESTED) {
      ShapeUtil.purpxYvprafr('Cnabenzvp', this._licenseCallback)
      LICENSE_CHECK_REQUESTED = true
    }
    if (LICENSE_CHECK_REQUESTED && !LICENSE_CHECKED) {
      this._callsWhileLicenseChecking.push({
        type: 'add',
        feature: e,
        objectId: t,
        drawItems: r,
      })
      return
    }
    if (!LICENSE_VALID) return
    if (DEBUG_PANORAMIC)
      console.log(`Adding feature ${e.id} with ${r.length} drawitems`)
    for (const e of r) {
      const t = e.metadata.context
      const r = this.getPanoramicId(e.feature, t)
      const a = this.initProjectiveTexture(e)
      if (a) {
        const t = e.style.projection
        this._projectiveTextureMap.set(r, {
          projectiveTexture: a,
          projectionTarget: t.target,
          baseOpacity: isProjectionTargetClosestSurfaceOptions(t)
            ? t.baseOpacity
            : 0,
        })
        a.requestAllLevel0Tiles()
        this._invalidator.invalidate()
      }
    }
  }
  update(e, t, r, a) {
    if (LICENSE_CHECK_REQUESTED && !LICENSE_CHECKED) {
      this._callsWhileLicenseChecking.push({
        type: 'update',
        feature: e,
        objectId: t,
        oldDrawItems: r,
        newDrawItems: a,
      })
      return
    }
    if (!LICENSE_VALID) return
    if (DEBUG_PANORAMIC)
      console.log(
        `Updating feature ${e.id} from ${r.length} drawitems to ${a.length}`
      )
    const o = []
    for (const e of a) {
      const t = this.getPanoramicId(e.feature, e.metadata.context)
      const r = this._projectiveTextureMap.get(t)
      const a = e.style.projection
      if (r) {
        this.updateProjectiveTexture(r.projectiveTexture, e)
        r.projectionTarget = a.target
        r.baseOpacity = isProjectionTargetClosestSurfaceOptions(a)
          ? a.baseOpacity
          : 0
      } else {
        const r = this.initProjectiveTexture(e)
        if (r) {
          this._projectiveTextureMap.set(t, {
            projectiveTexture: r,
            projectionTarget: a.target,
            baseOpacity: isProjectionTargetClosestSurfaceOptions(a)
              ? a.baseOpacity
              : 0,
          })
          r.requestAllLevel0Tiles()
        }
      }
      o.push(t)
    }
    for (const e of r) {
      const t = e.metadata.panoId
      if (o.indexOf(t) < 0)
        if (this._projectiveTextureMap.has(t)) {
          const e = this._projectiveTextureMap.get(t)
          if (e.projectiveTexture) {
            this._tracker.untrack(e.projectiveTexture)
            this._projectiveTextureMap.delete(t)
          }
        }
    }
  }
  createRotationMatrix(e, t) {
    if (!(this._worldReference instanceof GeocentricReference))
      throw new Error(
        `Cannot create rotation matrix for worldReference: ${this._worldReference.identifier}`
      )
    const r = undefined
    const a = getTransformation(e.reference, getReference('CRS:84')).transform(
      e
    )
    let o = null
    let n = null
    try {
      n = Photon.ScenePainterWrapper.getTransformationFromGeoLocation(
        a,
        t,
        createPoint(null, [0, 0, 0])
      )
      const e = n.typedArray
      o = [e[0], e[4], e[8], e[1], e[5], e[9], e[2], e[6], e[10]]
    } finally {
      if (n) n.release()
    }
    if (null === o) throw new Error('Could not create rotation matrix')
    return o
  }
  updateProjectiveTexture(e, t) {
    const { location: r } = t.metadata
    const { orientation: a } = t.style
    const o = this.createRotationMatrix(r, a)
    const n = undefined
    const i = createTransformation(r.reference, this._worldReference).transform(
      r
    )
    e.setLocation(i)
    const s = Photon.BufferFactory.createFloat64BufferFromData(
      new Float64Array(o)
    )
    e.setRotation(s)
    s.release()
    e.setOpacity(t.style.opacity)
    e.setSkyOpacity(t.style.skyOpacity)
  }
  remove(e, t) {
    if (LICENSE_CHECK_REQUESTED && !LICENSE_CHECKED) {
      this._callsWhileLicenseChecking.push({
        type: 'remove',
        drawItems: t,
        feature: e,
      })
      return
    }
    if (DEBUG_PANORAMIC) console.log(`Removing ${t.length} for feature ${e.id}`)
    for (const e of t) {
      const t = this.getPanoramicId(e.feature, e.metadata.context)
      if (this._projectiveTextureMap.has(t)) {
        const e = this._projectiveTextureMap.get(t)
        if (e.projectiveTexture) this._tracker.untrack(e.projectiveTexture)
      }
      this._projectiveTextureMap.delete(t)
    }
  }
  getPanoramaModel() {
    if (null === this._layer.panoramaModel)
      throw new Error(
        'GeoCanvas.drawPanorama was called, but no panoramaModel is set on the FeatureLayer. Make sure that a panoramaModel is set on the FeatureLayer.'
      )
    return this._layer.panoramaModel
  }
  requestCubeMapImage(e, t, r, a, o, n) {
    const i = e.feature
    const s = e.metadata.context
    const c = this.getPanoramaModel()
    if (!c) return
    const l = c.getPanoramaDescriptor(i, s)
    if (!l || l.type !== PanoramaType.CUBE_MAP) return
    const h = { feature: i, context: s, face: t, level: r, x: a, y: o }
    c.getPanoramicImage(
      h,
      (t, r) => {
        this.handlePanoramicImageSuccess(t, e, r, n)
      },
      (e, t) => {
        this.handleImageError(e, n, t)
      }
    )
  }
  requestSingleImage(e, t, r, a, o) {
    const n = e.feature
    const i = e.metadata.context
    const s = this.getPanoramaModel()
    if (!s) return
    const c = s.getPanoramaDescriptor(n, i)
    if (!c || c.type !== PanoramaType.SINGLE_IMAGE) return
    const l = { feature: n, context: e.metadata.context, level: t, x: r, y: a }
    s.getPanoramicImage(
      l,
      (t, r) => {
        this.handlePanoramicImageSuccess(t, e, r, o)
      },
      (e, t) => {
        this.handleImageError(e, o, t)
      }
    )
  }
  initProjectiveTexture(e) {
    const t = e.feature
    let r = null
    try {
      const a = this.getPanoramaModel()
      if (!a) return null
      const o = e.metadata.context
      const n = a.getPanoramaDescriptor(t, o)
      if (!n) return null
      const i = this._tracker.track(new Photon.ProjectiveTextureTileLoader())
      if (n.type === PanoramaType.CUBE_MAP) {
        const t = n.structure
        r = Photon.ProjectiveTexture.createCubeMapStructure(
          t.levelCount,
          t.getTileWidth(0),
          t.getTileHeight(0),
          t.getTileColumnCount(0),
          t.getTileRowCount(0)
        )
        i.requestTile = (t, r, a, o, n) => {
          this.requestCubeMapImage(e, toRIAFace(t), r, a, o, n)
        }
      } else if (n.type === PanoramaType.SINGLE_IMAGE) {
        const t = undefined
        if (n.projection.type !== PanoramicImageProjectionType.EQUIRECTANGULAR)
          throw new ProgrammingError(
            'LuciadRIA currently only supports single image panoramas that have an equirectangular projection'
          )
        const a = n.structure
        r = Photon.ProjectiveTexture.createEquirectangularStructure(
          a.levelCount,
          a.getTileWidth(0),
          a.getTileHeight(0),
          a.getTileColumnCount(0),
          a.getTileRowCount(0),
          a.imageDataFractionX,
          a.imageDataFractionY
        )
        i.requestTile = (t, r, a, o, n) => {
          this.requestSingleImage(e, r, a, o, n)
        }
      } else return null
      return this.initProjectiveTextureInner(e, n, r, i)
    } finally {
      if (r) r.release()
    }
  }
  getTileCacheSize(e) {
    const t = PhotonPanoramaCommand.getNumberOfTiles(e)
    let r = t
    const { structure: a, type: o } = e
    if (a.levelCount > 1) {
      const e = a.getTileColumnCount(0) * a.getTileRowCount(0)
      r = e
      const n = a.levelCount - 1
      const i = 100 * Constants.DEG2RAD
      const s = 100 / 180
      let c = 16 / 9
      if (this._layer.map) c = this._layer._map.camera.aspectRatio
      const l = undefined
      const h = (2 * Math.atan(Math.tan(i / 2) * c)) / Constants.d360InRadians
      if (o === PanoramaType.CUBE_MAP) {
        r = 6 * e
        const t = undefined
        const o = undefined
        r +=
          (Math.ceil(4 * a.getTileColumnCount(n) * h) + 2) *
          (Math.ceil(2 * a.getTileRowCount(n) * s) + 2)
      } else if (o === PanoramaType.SINGLE_IMAGE) {
        r = Math.max(r, a.getTileColumnCount(n))
        r *= 2.5
        const t = Math.ceil(a.getTileColumnCount(n) * h) + 2
        const o = Math.ceil(a.getTileRowCount(n) * s) + 2
        r = Math.max(r, e + t * o)
      }
      r = Math.ceil(1.1 * r)
      r = Math.min(r, t)
    }
    const n = Math.ceil(Math.sqrt(r))
    const i = n
    const s = n
    const c = this._photonGraphics.getMaxTextureSize()
    return {
      width: Math.min(i, Math.floor(c / a.getTileWidth(0))),
      height: Math.min(s, Math.floor(c / a.getTileHeight(0))),
    }
  }
  static getNumberOfTiles(e) {
    const { structure: t } = e
    let r = 0
    for (let e = 0; e < t.levelCount; e++)
      r += t.getTileColumnCount(e) * t.getTileRowCount(e)
    if (e.type === PanoramaType.CUBE_MAP) return 6 * r
    else return r
  }
  initProjectiveTextureInner(e, t, r, a) {
    const { width: o, height: n } = this.getTileCacheSize(t)
    const i = this._tracker.track(
      Photon.ProjectiveTexture.create(this._photonGraphics, r, o, n, a)
    )
    this.updateProjectiveTexture(i, e)
    return i
  }
  handlePanoramicImageSuccess = (e, t, r, a) => {
    const o = this.getPanoramaModel()
    const n = this._photonImageProvider.getPhotonImage(
      r,
      true,
      false,
      true,
      o.credentials,
      false
    )
    const i = (t) => {
      try {
        const r = this.getPanoramicId(e.feature, e.context)
        const o = undefined
        if (!this._projectiveTextureMap.get(r)) return
        a.handleImage(reqToPhotonFaceId(e), e.level, e.x, e.y, t)
        this._invalidator.invalidate()
      } finally {
        t.release()
        a.release()
      }
    }
    const s = (e) => {
      console.error(e || new Error('Error while decoding panoramic image tile'))
    }
    if (isPromise(n)) n.then(i).catch(s)
    else
      try {
        i(n)
      } catch (e) {
        s(e instanceof Error ? e : new Error('' + e))
      }
  }
  handleImageError = (e, t, r) => {
    try {
      console.error(r || new Error('Unknown panoramic tile request error'))
      const a = r && r.message ? r.message : 'Unknown tile error'
      t.handleError(reqToPhotonFaceId(e), e.level, e.x, e.y, a)
    } finally {
      t.release()
    }
  }
  getGeometryId(e) {
    this._hash.reset()
    this._hash.appendDouble(e.x)
    this._hash.appendDouble(e.y)
    this._hash.appendDouble(e.z)
    return this._hash.getHashCode()
  }
  getStyleId(e) {
    this._hash.reset()
    this._hash.appendDouble(e.opacity)
    this._hash.appendDouble(e.skyOpacity)
    this._hash.appendDouble(e.orientation)
    this._hash.appendString(e.projection.target)
    if (isProjectionTargetClosestSurfaceOptions(e.projection))
      this._hash.appendDouble(e.projection.baseOpacity)
    return this._hash.getHashCode()
  }
  getPanoramicId(e, t) {
    this._hash.reset()
    this._hash.append(e.id)
    for (const e in t)
      if (t.hasOwnProperty(e)) {
        this._hash.appendString(e)
        const r = t[e]
        if ('string' === typeof r) this._hash.appendString(r)
        if ('number' === typeof r) this._hash.appendDouble(r)
      }
    return this._hash.getHashCode()
  }
  paint(e, t, r, a, o, n) {
    const i = 'controller' === this._layer.label
    const s = i || this._layer.visibleInTree
    const c = undefined
    let l = true
    const h = undefined
    if (
      this._projectiveTextureMap.size > 0 &&
      s &&
      t === (i ? t : false) &&
      a === Photon.PaintDraping.NonDraping &&
      r !== Photon.PaintOpacity.All &&
      o === Photon.PaintOutput.Normal
    ) {
      const e = [...this._projectiveTextureMap.values()]
      for (const t in ProjectionTarget) {
        const a = e.filter((e) => e.projectionTarget === t)
        const o = groupByBaseOpacity(a)
        const i = Object.keys(o)
        for (const e of i) {
          const a = o[e]
          for (let o = 0; o < a.length; o += 2) {
            const i = a[o] ? a[o].projectiveTexture : null
            const s = a[o + 1] ? a[o + 1].projectiveTexture : null
            const c = r === Photon.PaintOpacity.Transparent
            if (DEBUG_PANORAMIC)
              console.log('Painting projective texture slots:', i, s)
            l =
              this._photonView.paintPanoramics(
                c,
                toPhotonOcclusionMode(t),
                e,
                i,
                s,
                null,
                null,
                this._paintContext,
                n
              ) && l
          }
        }
      }
    }
    return l
  }
}
