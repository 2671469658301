import {
  createCartesianGeodesy,
  createEllipsoidalGeodesy,
} from '../../../geodesy/GeodesyFactory.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { Editor } from '../Editor.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultSize,
} from '../handles/CreateByTemplateHandle.js'
export class CircleByCenterPointEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.CIRCLE_BY_CENTER_POINT, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createRadiusHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const r = e.shape
    return new PointDragHandle(
      () => r.center,
      (e) => r.move2DToPoint(e)
    )
  }
  createRadiusHandle(e) {
    const r = e.shape
    let t = 90
    const a =
      null !== r.reference &&
      r.reference.referenceType === ReferenceType.GEODETIC
        ? createEllipsoidalGeodesy(r.reference)
        : createCartesianGeodesy(r.reference)
    return new PointDragHandle(
      () => a.interpolate(r.center, r.radius, t),
      (e) => {
        r.radius = a.distance(r.center, e)
        t = a.forwardAzimuth(r.center, e)
      }
    )
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, r) => {
      const t = e.shape
      t.move2DToPoint(r)
      t.radius = getDefaultSize(e.map)
    })
  }
}
