import { WFSVersions } from '../../WFSVersions.js'
import { OGCEncoder100 } from './OGCEncoder100.js'
import { OGCEncoder110 } from './OGCEncoder110.js'
import { OGCEncoder200 } from './OGCEncoder200.js'
import { OGCEncoder202 } from './OGCEncoder202.js'
export function createOGCEncoder(r) {
  switch (r) {
    case WFSVersions.V100:
      return new OGCEncoder100()
    case WFSVersions.V110:
      return new OGCEncoder110()
    case WFSVersions.V200:
      return new OGCEncoder200()
    case WFSVersions.V202:
      return new OGCEncoder202()
    default:
      return new OGCEncoder110()
  }
}
