import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Invalidation } from '../../util/Invalidation.js'
import { RasterDataType } from '../tileset/RasterDataType.js'
import { WithHttpRequestOptions } from '../../util/WithHttpRequestOptions.js'
import { Ajax } from '../../util/Ajax.js'
import { hasProperty, isString } from '../../util/Lang.js'
const DEFAULT_MODEL_DESCRIPTOR = {
  source: 'N/A',
  name: 'Raster image Model',
  type: RasterDataType.IMAGE,
  description: 'Raster image model',
}
export class RasterImageModel extends Invalidation {
  _useCors = false
  constructor(e) {
    super()
    e = e || {}
    this._reference = e.reference
    this._modelDescriptor = e.modelDescriptor
      ? e.modelDescriptor
      : DEFAULT_MODEL_DESCRIPTOR
    this._httpOptions = new WithHttpRequestOptions(e)
  }
  get useCors() {
    return this._useCors
  }
  set useCors(e) {
    this._useCors = e
  }
  get credentials() {
    return this._httpOptions.credentials
  }
  set credentials(e) {
    this._httpOptions.credentials = e
  }
  get requestHeaders() {
    return this._httpOptions.requestHeaders
  }
  set requestHeaders(e) {
    this._httpOptions.requestHeaders = e
  }
  addHttpRequestOptions(e) {
    return this._httpOptions.addHttpRequestOptions(e)
  }
  getImage(e, t, r, s) {
    const i = this.getImageUrl(e, t, r, s)
    if (null === i) return Promise.reject(new Error('Image URL is null'))
    return Ajax.getImage(
      i,
      false,
      this.useCors,
      this.credentials,
      this.requestHeaders
    )
  }
  get coordinateType() {
    return this._reference.coordinateType
  }
  get reference() {
    return this._reference
  }
  get modelDescriptor() {
    return this._modelDescriptor
  }
  set modelDescriptor(e) {
    if (
      !e ||
      !['type', 'name', 'description', 'source'].every(
        (t) => !hasProperty(e, t) || isString(e[t])
      )
    )
      throw new ProgrammingError('RasterTileSetModel::invalid modelDescriptor')
    this._modelDescriptor = Object.assign(this._modelDescriptor || {}, e)
  }
  invalidate() {
    super.invalidate()
  }
  on(e, t, r) {
    return super.on(e, t, r)
  }
}
