export function normalizeDashes(e, t) {
  let s = false
  let n = 0
  for (let t = 0; t < e.length; t++) n += e[t]
  const l = (t = t > 0 ? t : n + t) % n
  if (0 === l) {
    const t = []
    for (let n = 0; n < e.length; n++) {
      t.push({ length: e[n], isGap: s })
      s = !s
    }
    return t
  }
  let h = 0
  const o = []
  const i = []
  let p = false
  for (let t = 0; t < e.length; t++) {
    const n = h
    const u = h + e[t]
    const a = undefined
    const c = l > n && l < u
    if (l === n) {
      i.push({ length: u - n, isGap: s })
      p = true
    } else if (c) {
      o.push({ length: l - n, isGap: s })
      i.push({ length: u - l, isGap: s })
      p = true
    } else if (p) i.push({ length: e[t], isGap: s })
    else o.push({ length: e[t], isGap: s })
    h += e[t]
    s = !s
  }
  return i.concat(o)
}
