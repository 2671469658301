import { ProgrammingError } from '../../../error/ProgrammingError.js'
import {
  isHandled,
  isRequestDeactivation,
} from '../../controller/HandleEventResult.js'
import { EditHandle } from '../EditHandle.js'
export class GeoBufferInvalidateHandle extends EditHandle {
  constructor(e, t) {
    super()
    this._delegate = t.handleDelegate
    this._delegate.on('EditShape', (t) => {
      this.emitEditShapeEvent(e, t.status)
    })
    if (!t) throw new ProgrammingError('Missing wrapperOptions')
    this._geoBuffersToInvalidate = t.geoBuffersToInvalidate
    this._invalidateOnDeactivate = t.invalidateOnDeactivate
    this._invalidateOnHandled = t.invalidateOnHandled
  }
  onGestureEvent(e, t) {
    const a = this._delegate.onGestureEvent(e, t)
    const n = undefined
    if (
      (this._invalidateOnDeactivate && isRequestDeactivation(a)) ||
      (this._invalidateOnHandled && isHandled(a))
    )
      for (const e of this._geoBuffersToInvalidate) e.invalidate()
    return a
  }
  getCursor(e, t) {
    return this._delegate.getCursor(e, t)
  }
  onDraw(e, t) {
    this._delegate.onDraw(e, t)
  }
  onDrawLabel(e, t) {
    this._delegate.onDrawLabel(e, t)
  }
  update() {
    this._delegate.update()
  }
  onCreateContextMenu(e, t, a, n) {
    const i = () => {
      for (const e of this._geoBuffersToInvalidate) e.invalidate()
      n()
    }
    this._delegate.onCreateContextMenu(e, t, a, i)
  }
}
