import { WFSCapabilitiesParserV110 } from './WFSCapabilitiesParserV110.js'
import { WFSCapabilitiesParserV200 } from './WFSCapabilitiesParserV200.js'
import { WFSCapabilitiesUtil } from './WFSCapabilitiesUtil.js'
const SUPPORTED_VERSIONS = ['2.0.0', '1.1.0']
function createParser(e, s) {
  switch (s) {
    case '1.1.0':
      return new WFSCapabilitiesParserV110(e)
    case '2.0.0':
      return new WFSCapabilitiesParserV200(e)
    default:
      throw new Error(`Can not parse unsupported WFS version: ${s}`)
  }
}
class WFSCapabilities {
  constructor(e, s) {
    this.version = e
    this._capabilitiesXML = s
    this._parser = createParser(s, e)
    this.updateSequence = this._parser.getUpdateSequence()
    this.service = this._parser.getService()
    this.featureTypes = this._parser.getFeatureTypes()
    this.operations = this._parser.getOperations()
  }
  static fromURL(e, s) {
    const i = {
      credentials: (s = s || {}).credentials,
      requestHeaders: s.requestHeaders,
      requestParameters: s.requestParameters,
    }
    const r = undefined
    return WFSCapabilitiesUtil.createVersionNegotiator(
      e,
      SUPPORTED_VERSIONS,
      s.allowedVersions,
      i
    )
      .negotiateVersion()
      .then((e) => new WFSCapabilities(e.version, e.response))
  }
  static fromXML(e) {
    const s = WFSCapabilitiesUtil.parseVersion(e)
    const i = new WFSCapabilities(s, e)
    return Promise.resolve(i)
  }
}
export { WFSCapabilities }
