import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Hash } from '../util/Hash.js'
import { Cylindrical } from './Cylindrical.js'
import { ProjectionType } from './ProjectionType.js'
const sharedOutOfBoundsError = new OutOfBoundsError('Geodetic')
export class Geodetic extends Cylindrical {
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1
    super()
    this._scaleX = e
    this._scaleY = t
    this._hash = 0
    this.calculateCachedValues()
  }
  setScaleFactorX(e) {
    this._scaleX = e
    this.calculateCachedValues()
  }
  setScaleFactorY(e) {
    this._scaleY = e
    this.calculateCachedValues()
  }
  getScaleFactorX() {
    return this._scaleX
  }
  getScaleFactorY() {
    return this._scaleY
  }
  isAllInBounds() {
    return true
  }
  isContinuous() {
    return true
  }
  inLonLatBounds(e) {
    return true
  }
  boundaryLons(e) {
    return [[-180, 180]]
  }
  boundaryLats(e) {
    return [[-90, 90]]
  }
  inWorldBoundsOnSphere(e, t) {
    return (
      e.x >= -180 * this._scaleX &&
      e.x <= 180 * this._scaleX &&
      e.y >= -90 * this._scaleY &&
      e.y <= 90 * this._scaleY
    )
  }
  geodetic2cartesianOnSphereSFCT(e, t, s) {
    s.x = e.x * this._scaleX
    s.y = e.y * this._scaleY
  }
  cartesian2geodeticOnSphereSFCT(e, t, s) {
    if (this.inWorldBoundsOnSphere(e, t)) {
      s.x = e.x / this._scaleX
      s.y = e.y / this._scaleY
    } else throw sharedOutOfBoundsError
  }
  equals(e) {
    if (!super.equals(e)) return false
    const t = e
    return (
      t.getScaleFactorX() == this.getScaleFactorX() &&
      t.getScaleFactorY() == this.getScaleFactorY()
    )
  }
  cartesianBoundsOnSphereSFCT(e, t) {
    const s = 180 * this._scaleX
    const r = 90 * this._scaleY
    t.setTo2D(-s, 2 * s, -r, 2 * r)
  }
  encode() {
    return {
      type: 'Geodetic',
      scaleX: this.getScaleFactorX(),
      scaleY: this.getScaleFactorY(),
    }
  }
  get TYPE() {
    return ProjectionType.GEODETIC + ProjectionType.CYLINDRICAL
  }
  calculateCachedValues() {
    const e = new Hash()
    this._hash = e
      .appendDouble(this.getScaleFactorX())
      .appendDouble(this.getScaleFactorY())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  hashCode(e) {
    e.appendUInt32(this._hash)
  }
}
