import { XYZPoint } from '../shape/XYZPoint.js'
import { Constants } from '../util/Constants.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { ObliqueMercatorVariant } from '../reference/EncodedCoordinateReference.js'
import { SphericalObliqueMercator } from './SphericalObliqueMercator.js'
import { ProjectionType } from './ProjectionType.js'
import { ObliqueCylindrical } from './ObliqueCylindrical.js'
const sharedOutOfBoundsError = new OutOfBoundsError('ObliqueMercator')
const MAX_WORLD_EXTENT_U = Math.PI
const MAX_WORLD_EXTENT_V = 2 * Math.PI
class CachedValues {
  constructor(t, i) {
    this.fA = t.a
    this.fE = t.e
    this.fE2 = t.e2
    this.fZZ_E2 = 1 - this.fE2
    this.fZZ_E = Math.sqrt(this.fZZ_E2)
    const s = this.fE2
    const a = s * s
    const n = a * s
    const e = a * a
    const h = s / 2 + (5 / 24) * a + (1 / 12) * n + (13 / 360) * e
    const o = (7 / 48) * a + (29 / 240) * n + (811 / 11520) * e
    const r = (7 / 120) * n + (81 / 1120) * e
    const f = (4279 / 161280) * e
    this.fB1 = 2 * h + 4 * o + 6 * r + 8 * f
    this.fB2 = -8 * o - 32 * r - 80 * f
    this.fB3 = 32 * r + 192 * f
    this.fB4 = -128 * f
    const c = 1 - this.fE2 * i.fSin2Phi0
    this.fBB = Math.sqrt(1 + (this.fE2 * i.fCos4Phi0) / this.fZZ_E2)
    this.fAA = (this.fA * i.fScale * this.fBB * this.fZZ_E) / c
    const u = this.fE * i.fSinPhi0
    const l = i.fTPhi0 / Math.pow((1 - u) / (1 + u), this.fE / 2)
    let m = (this.fBB * this.fZZ_E) / (i.fCosPhi0 * Math.sqrt(c))
    if (m * m < 1) m = 1
    let d
    if (i.getStandardParallel() >= 0) d = m + Math.sqrt(m * m - 1)
    else d = m - Math.sqrt(m * m - 1)
    this.fEE = d * Math.pow(l, this.fBB)
    const A = (d - 1 / d) / 2
    this.fSinGamma0 = i.fSinAzimuth / m
    this.fGamma0Rad = Math.asin(this.fSinGamma0)
    this.fCosGamma0 = Math.cos(this.fGamma0Rad)
    this.fLambda0Rad =
      i.getCentralMeridian() * Constants.DEG2RAD -
      Math.asin(A * Math.tan(this.fGamma0Rad)) / this.fBB
    if (i.fVariant == Variant.B) {
      const t = i.getAzimuth() * Constants.DEG2RAD
      this.fUc =
        Math.abs(i.getAzimuth() - 90) < 1e-8
          ? this.fAA * (i.getOrigin().x * Constants.DEG2RAD - this.fLambda0Rad)
          : (this.fAA / this.fBB) *
            Math.atan(Math.sqrt(m * m - 1) / Math.cos(t)) *
            signum(i.getOrigin().y)
    } else this.fUc = 0
  }
}
export let Variant = (function (t) {
  t[(t['A'] = ObliqueMercatorVariant.A)] = 'A'
  t[(t['B'] = ObliqueMercatorVariant.B)] = 'B'
  return t
})({})
function signum(t) {
  return isNaN(t) ? Number.NaN : t < 0 ? -1 : t > 0 ? 1 : 0
}
const scratchLLHPoint = new XYZPoint()
export class ObliqueMercator extends ObliqueCylindrical {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    let s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0
    let a =
      arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : Variant.A
    super()
    this.fScale = 1
    this.fSinPhi0 = 0
    this.fSin2Phi0 = 0
    this.fCosPhi0 = 0
    this.fCos4Phi0 = 0
    this.fTPhi0 = 0
    this.fSinAzimuth = 0
    this.fCosAzimuth = 0
    this.fVariant = a
    this.fCache = null
    this.fSphericalObliqueMercator = new SphericalObliqueMercator(t, i, s)
    this.setCentralMeridian(t)
    this.setStandardParallel(i)
    this.setAzimuth(s)
    this.fVariant = a
    this.calculateCachedValues()
  }
  getVariant() {
    return this.fVariant
  }
  get scale() {
    return this.fScale
  }
  geodetic2cartesianOnSphereSFCT(t, i, s) {
    this.fSphericalObliqueMercator.geodetic2cartesianOnSphereSFCT(t, i, s)
  }
  cartesian2geodeticOnSphereSFCT(t, i, s) {
    this.fSphericalObliqueMercator.cartesian2geodeticOnSphereSFCT(t, i, s)
  }
  isAllInBounds() {
    return this.fSphericalObliqueMercator.isAllInBounds()
  }
  isContinuous() {
    return this.fSphericalObliqueMercator.isContinuous()
  }
  inLonLatBounds(t) {
    return true
  }
  boundaryLons(t) {
    return this.fSphericalObliqueMercator.boundaryLons(t)
  }
  boundaryLats(t) {
    return this.fSphericalObliqueMercator.boundaryLats(t)
  }
  inWorldBoundsOnSphere(t, i) {
    return this.fSphericalObliqueMercator.inWorldBoundsOnSphere(t, i)
  }
  cartesianBoundsOnSphereSFCT(t, i) {
    this.fSphericalObliqueMercator.cartesianBoundsOnSphereSFCT(t, i)
  }
  calculateCachedValues() {
    super.calculateCachedValues()
    this.fSinPhi0 = Math.sin(this.getOrigin().y * Constants.DEG2RAD)
    this.fSin2Phi0 = this.fSinPhi0 * this.fSinPhi0
    this.fCosPhi0 = Math.cos(this.getOrigin().y * Constants.DEG2RAD)
    const t = this.fCosPhi0 * this.fCosPhi0
    this.fCos4Phi0 = t * t
    this.fSinAzimuth = Math.sin(this.getAzimuth() * Constants.DEG2RAD)
    this.fCosAzimuth = Math.cos(this.getAzimuth() * Constants.DEG2RAD)
    this.fTPhi0 = Math.tan(
      Math.PI / 4 - (this.getOrigin().y * Constants.DEG2RAD) / 2
    )
    this.resetCachedValues()
  }
  resetCachedValues() {
    this.fCache = null
  }
  getParameterSet(t) {
    let i = this.fCache
    if (null == i || i.fA != t.a || i.fE != t.e) {
      i = new CachedValues(t, this)
      this.fCache = i
    }
    return i
  }
  geodetic2cartesianOnEllipsoidSFCT(t, i, s) {
    if (!this.inLonLatBounds(t)) throw sharedOutOfBoundsError
    const a = this.getParameterSet(i)
    let n, e
    const h = t.y * Constants.DEG2RAD
    if (Math.abs(t.y - 90) >= 1e-8 && Math.abs(t.y - -90) >= 1e-8) {
      const i = a.fE * Math.sin(t.y * Constants.DEG2RAD)
      const s =
        Math.tan(Math.PI / 4 - h / 2) / Math.pow((1 - i) / (1 + i), a.fE / 2)
      const o =
        Constants.DEG2RAD *
        normalizeLon(t.x - a.fLambda0Rad * Constants.RAD2DEG)
      const r = a.fEE / Math.pow(s, a.fBB)
      const f = (r - 1 / r) / 2
      const c = (r + 1 / r) / 2
      const u = Math.sin(a.fBB * o)
      const l = (-u * a.fCosGamma0 + f * a.fSinGamma0) / c
      e = (a.fAA * Math.log((1 - l) / (1 + l))) / (2 * a.fBB)
      const m = Math.cos(a.fBB * o)
      if (Math.abs(m) > 1e-12)
        n = (a.fAA * Math.atan2(f * a.fCosGamma0 + u * a.fSinGamma0, m)) / a.fBB
      else n = a.fAA * o
      if (this.fVariant == Variant.B)
        n -=
          Math.abs(a.fUc) *
          signum(this.getOrigin().y) *
          (Math.abs(m) < 1e-8 ? signum(o) : 1)
    } else if (Math.abs(t.y - 90) >= 1e-8) {
      e = (a.fAA / a.fBB) * Math.log(Math.tan(Math.PI / 4 + a.fGamma0Rad / 2))
      n = (a.fAA / a.fBB) * h
    } else {
      e = (a.fAA / a.fBB) * Math.log(Math.tan(Math.PI / 4 - a.fGamma0Rad / 2))
      n = (a.fAA / a.fBB) * h
    }
    const o = e * this.fCosAzimuth + n * this.fSinAzimuth
    const r = n * this.fCosAzimuth - e * this.fSinAzimuth
    s.move2D(o, r)
    if (!this.inWorldBoundsOnEllipsoid(s, i)) throw sharedOutOfBoundsError
  }
  cartesian2geodeticOnEllipsoidSFCT(t, i, s) {
    if (this.inWorldBoundsOnEllipsoid(t, i)) {
      const a = this.getParameterSet(i)
      const n = t.x
      const e = t.y
      const h = n * this.fCosAzimuth - e * this.fSinAzimuth
      let o = e * this.fCosAzimuth + n * this.fSinAzimuth
      if (this.fVariant == Variant.B)
        o += Math.abs(a.fUc) * signum(this.getOrigin().y)
      const r = Math.exp((-a.fBB * h) / a.fAA)
      const f = (r - 1 / r) / 2
      const c = (r + 1 / r) / 2
      const u = Math.sin((a.fBB * o) / a.fAA)
      const l = (u * a.fCosGamma0 + f * a.fSinGamma0) / c
      let m, d
      if (Math.abs(Math.abs(l) - 1) < 1e-12) {
        m = 90
        d = a.fLambda0Rad * Constants.RAD2DEG
        if (l < 0) m = -m
      } else {
        const t = Math.pow(a.fEE / Math.sqrt((1 + l) / (1 - l)), 1 / a.fBB)
        const i = Math.PI / 2 - 2 * Math.atan(t)
        const s = Math.cos(i)
        const n = Math.sin(i)
        const e = n * n
        m =
          (i + n * s * (a.fB1 + e * (a.fB2 + e * (a.fB3 + e * a.fB4)))) *
          Constants.RAD2DEG
        const h =
          normalizeLon(
            Constants.RAD2DEG *
              Math.atan2(
                f * a.fCosGamma0 - u * a.fSinGamma0,
                Math.cos((a.fBB * o) / a.fAA)
              )
          ) / a.fBB
        d = normalizeLon(Constants.RAD2DEG * a.fLambda0Rad - h)
      }
      s.move2D(d, m)
    } else throw sharedOutOfBoundsError
  }
  inWorldBoundsOnEllipsoid(t, i) {
    const s = i.a
    const a = i.b
    const n = t.x
    const e = t.y
    const h =
      this.fVariant == Variant.B
        ? Math.abs(this.getParameterSet(i).fUc) * signum(this.getOrigin().y)
        : 0
    const o = n * this.fCosAzimuth - e * this.fSinAzimuth
    const r = n * this.fSinAzimuth + e * this.fCosAzimuth + h
    return (
      r <= a * MAX_WORLD_EXTENT_U &&
      r >= -a * MAX_WORLD_EXTENT_U &&
      o <= s * MAX_WORLD_EXTENT_V &&
      o >= -s * MAX_WORLD_EXTENT_V
    )
  }
  cartesianBoundsOnEllipsoidSFCT(t, i) {
    const s = t.a
    const a = undefined
    let n = t.b * MAX_WORLD_EXTENT_U
    let e = s * MAX_WORLD_EXTENT_V
    const h =
      this.fVariant == Variant.B
        ? Math.abs(this.getParameterSet(t).fUc) * signum(this.getOrigin().y)
        : 0
    let o = e * this.fCosAzimuth + (n - h) * this.fSinAzimuth
    let r = (n - h) * this.fCosAzimuth - e * this.fSinAzimuth
    i.move2DToCoordinates(o, r)
    e = -e
    o = e * this.fCosAzimuth + (n - h) * this.fSinAzimuth
    r = (n - h) * this.fCosAzimuth - e * this.fSinAzimuth
    scratchLLHPoint.move2DToCoordinates(o, r)
    i.setToIncludePoint2D(scratchLLHPoint)
    n = -n
    o = e * this.fCosAzimuth + (n - h) * this.fSinAzimuth
    r = (n - h) * this.fCosAzimuth - e * this.fSinAzimuth
    scratchLLHPoint.move2DToCoordinates(o, r)
    i.setToIncludePoint2D(scratchLLHPoint)
    e = -e
    o = e * this.fCosAzimuth + (n - h) * this.fSinAzimuth
    r = (n - h) * this.fCosAzimuth - e * this.fSinAzimuth
    scratchLLHPoint.move2DToCoordinates(o, r)
    i.setToIncludePoint2D(scratchLLHPoint)
  }
  toString() {
    return `Oblique_Mercator_${this.getCentralMeridian()}_${this.getStandardParallel()}_${this.getAzimuth()}`
  }
  equals(t) {
    if (!super.equals(t)) return false
    const i = t
    return this.fScale === i.fScale && this.fVariant === i.fVariant
  }
  encode() {
    return {
      type: 'ObliqueMercator',
      centralMeridian: this.getCentralMeridian(),
      standardParallel: this.getStandardParallel(),
      azimuth: this.getAzimuth(),
      variant: this.getVariant(),
    }
  }
  get TYPE() {
    return (
      ProjectionType.OBLIQUE_MERCATOR +
      ProjectionType.OBLIQUECYLINDRICAL +
      ProjectionType.MERCATOR
    )
  }
  hashCode(t) {
    super.hashCode(t)
    t.appendUInt32(this.getVariant())
  }
}
