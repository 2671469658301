import { isNumber } from '../../../../../util/Lang.js'
export function instanceOfIFilled(n) {
  return 'fillColor' in n
}
export function instanceOfIWorldSized(n) {
  return 'worldSizeContext' in n
}
export function instanceOfIStroked(n) {
  return 'strokePath' in n
}
export function instanceOfILength(n) {
  return n && isNumber(n.relativeLength) && isNumber(n.fixedLength)
}
export function instanceOfIFlexible(n) {
  return 'paintFlexible' in n
}
export function instanceOfIAtomic(n) {
  return 'canPaintAtomic' in n
}
