import { isString } from './Lang.js'
export class Hash {
  constructor() {
    this._double = new Float64Array(2)
    this._ints = new Uint32Array(this._double.buffer)
  }
  static computeHashCode(t, s) {
    s.reset()
    t.hashCode(s)
    return s.getHashCode()
  }
  reset() {
    this._ints[2] = 0
    return this
  }
  appendDouble(t) {
    this._double[0] = t || 0
    this._ints[2] =
      31 * this._ints[2] + (this._ints[0] ^ this._ints[1]) + (t < 0 ? 1 : 0)
    return this
  }
  appendUInt32(t) {
    this._ints[0] = 0 | (t || 0)
    this._ints[2] = 31 * this._ints[2] + this._ints[0]
    return this
  }
  appendBoolean(t) {
    this._ints[0] = t ? 1231 : 1237
    this._ints[2] = 31 * this._ints[2] + this._ints[0]
    return this
  }
  appendString(t) {
    if (!t) return this.appendBoolean(false)
    for (let s = 0; s < t.length; s++)
      this._ints[2] = 31 * this._ints[2] + t.charCodeAt(s)
    return this
  }
  append(t) {
    if (isString(t)) this.appendString(t)
    else this.appendDouble(t)
    return this
  }
  getHashCode() {
    return this._ints[2]
  }
}
