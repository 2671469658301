export let PatternType = (function (E) {
  E[(E['GAP'] = 0)] = 'GAP'
  E[(E['LINE'] = 1)] = 'LINE'
  E[(E['PARALLEL_LINE'] = 2)] = 'PARALLEL_LINE'
  E[(E['RECTANGLE'] = 3)] = 'RECTANGLE'
  E[(E['TRIANGLE'] = 4)] = 'TRIANGLE'
  E[(E['POLYLINE'] = 5)] = 'POLYLINE'
  E[(E['ARC'] = 6)] = 'ARC'
  E[(E['WAVE'] = 7)] = 'WAVE'
  E[(E['ICON'] = 8)] = 'ICON'
  E[(E['TEXT'] = 9)] = 'TEXT'
  E[(E['ARROW'] = 10)] = 'ARROW'
  E[(E['ATOMIC'] = 11)] = 'ATOMIC'
  E[(E['ALLOW_OVERLAP'] = 12)] = 'ALLOW_OVERLAP'
  E[(E['COMBINE_WITH_FALLBACK'] = 13)] = 'COMBINE_WITH_FALLBACK'
  E[(E['COMBINE_WITH_REGULAR'] = 14)] = 'COMBINE_WITH_REGULAR'
  E[(E['REPEAT_COUNT'] = 15)] = 'REPEAT_COUNT'
  E[(E['REPEAT_OVER_LENGTH'] = 16)] = 'REPEAT_OVER_LENGTH'
  E[(E['APPEND'] = 17)] = 'APPEND'
  E[(E['COMPOSE'] = 18)] = 'COMPOSE'
  return E
})({})
export function isWrapperType(E) {
  return (
    E.patternType === PatternType.ARROW ||
    E.patternType === PatternType.ATOMIC ||
    E.patternType === PatternType.ALLOW_OVERLAP ||
    E.patternType === PatternType.COMBINE_WITH_FALLBACK ||
    E.patternType === PatternType.COMBINE_WITH_REGULAR ||
    E.patternType === PatternType.REPEAT_COUNT ||
    E.patternType === PatternType.REPEAT_OVER_LENGTH
  )
}
export function isMultiType(E) {
  return (
    E.patternType === PatternType.APPEND ||
    E.patternType === PatternType.COMPOSE
  )
}
