import { NotImplementedError } from '../../../error/NotImplementedError.js'
function AreaSegmentChooser() {
  this._map = new CGShapeToIndexMap()
}
function Index(e, t) {
  this.index = e
  this.segment = t
}
function CGShapeToIndexMap() {
  this.keys = []
  this.values = []
}
CGShapeToIndexMap.prototype = Object.create(Object.prototype)
CGShapeToIndexMap.prototype.constructor = CGShapeToIndexMap
CGShapeToIndexMap.prototype.get = function (e) {
  var t = this._getKeyIndex(e)
  if (-1 !== t) return this.values[t]
  return null
}
CGShapeToIndexMap.prototype.put = function (e, t) {
  var n = this._getKeyIndex(e)
  if (-1 === n) {
    this.keys.push(e)
    this.values.push(t)
  } else this.values[n] = t
}
CGShapeToIndexMap.prototype._getKeyIndex = function (e) {
  for (var t = 0; t < this.keys.length; t++) if (this.keys[t] === e) return t
  return -1
}
AreaSegmentChooser.prototype.chooseSegment = function (e, t, n) {
  var r = n.getIndex(e, t)
  if (r < 0) return null
  var o = n.wrap(r + 1)
  while (o != r) {
    var s = n.getEdgeEnd(o)
    var i = s.segment
    if (this._isSegmentOk(i)) return s
    o = n.wrap(o + 1)
  }
  return this._chooseEmergencySegment(e, t, n)
}
AreaSegmentChooser.prototype._chooseEmergencySegment = function (e, t, n) {
  var r = n.getIndex(e, t)
  if (r < 0) return null
  var o = n.wrap(r + 1)
  while (o != r) {
    var s = n.getEdgeEnd(o)
    var i = s.segment
    if (!(i.used || (null != i.otherLineSegment && i.otherLineSegment.used)))
      return s
    o = n.wrap(o + 1)
  }
  return null
}
AreaSegmentChooser.prototype.findUnusedSegment = function (e) {
  if (0 == e.elements.length) return null
  var t = this._map.get(e)
  if (null === t) {
    t = new Index(0, null)
    this._map.put(e, t)
  }
  for (var n = t.index; n < e.elements.length; n++) {
    t.index = n
    var r = true
    if (null === t.segment) t.segment = e.elements[n]
    else {
      t.segment = t.segment.next
      r = false
    }
    for (
      var o = t.segment;
      null !== o && (r || o !== e.elements[n]);
      o = o.next
    ) {
      r = false
      t.segment = o
      if (this._isSegmentOk(o)) return o
    }
    t.segment = null
  }
  return null
}
AreaSegmentChooser.prototype._isSegmentOk = function (e) {
  if (e.used || !e.isFilled()) return false
  if (null !== e.otherLineSegment && e.otherLineSegment.used) return false
  return this.isSegmentOkAbstract(e)
}
AreaSegmentChooser.prototype.isSegmentOkAbstract = function (e) {
  throw new NotImplementedError()
}
export { AreaSegmentChooser }
