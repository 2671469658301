import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
import {
  EVENT_HANDLED,
  REQUEST_DEACTIVATION,
  REQUEST_FINISH,
} from '../../controller/HandleEventResult.js'
import { GestureEventType } from '../../input/GestureEventType.js'
import * as SnapUtil from '../snapping/SnapUtil.js'
import { ThreeStepEditHandle } from './ThreeStepEditHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
export class BoundsCreateHandle extends ThreeStepEditHandle {
  constructor(t) {
    super()
    this._bounds = t
    this._startPoint = null
    this._snapPoint = null
    this._clickCount = 0
  }
  get bounds() {
    return this._bounds
  }
  shouldActivate(t, e) {
    return (
      t.type === GestureEventType.DRAG ||
      t.type === GestureEventType.SINGLE_CLICK_UP
    )
  }
  activate(t, e) {
    this._clickCount = 0
    this._startPoint = e.onTerrainViewModelTransformation.transform(t.viewPoint)
    this.bounds.x = this._startPoint.x
    this.bounds.y = this._startPoint.y
    this.bounds.width = 0
    this.bounds.height = 0
    this.emitEditShapeEvent(this.bounds, EditShapeStatus.IN_PROGRESS)
    return EVENT_HANDLED
  }
  shouldProcess(t) {
    return (
      t.type === GestureEventType.DRAG ||
      t.type === GestureEventType.MOVE ||
      t.type === GestureEventType.SINGLE_CLICK_UP
    )
  }
  process(t, e) {
    if (t.type === GestureEventType.SINGLE_CLICK_UP) this._clickCount++
    if (2 === this._clickCount) return EVENT_HANDLED
    if (this._clickCount > 2) return EVENT_HANDLED | REQUEST_DEACTIVATION
    let s
    try {
      s = e.onTerrainViewModelTransformation.transform(t.viewPoint)
    } catch (t) {
      OutOfBoundsError.isOrThrow(t)
      return EVENT_HANDLED
    }
    this._snapPoint = SnapUtil.findClosestPoint(t, s, this.bounds, e.map)
    if (this._snapPoint) s = this._snapPoint
    const n = Math.min(this._startPoint.x, s.x)
    const i = Math.max(this._startPoint.x, s.x)
    const o = Math.min(this._startPoint.y, s.y)
    const r = Math.max(this._startPoint.y, s.y)
    this.bounds.setTo2D(n, 0, o, 0)
    const E = this.bounds.focusPoint.copy()
    E.move2D(i, r)
    this.bounds.setToIncludePoint2D(E)
    this.emitEditShapeEvent(this.bounds, EditShapeStatus.IN_PROGRESS)
    return EVENT_HANDLED
  }
  shouldDeactivate(t) {
    const e =
      t.type === GestureEventType.DOUBLE_CLICK ||
      (t.type === GestureEventType.SINGLE_CLICK_CONFIRMED &&
        this._clickCount >= 2)
    return t.type === GestureEventType.DRAG_END || e
  }
  deactivate(t, e) {
    this._clickCount = 0
    this._startPoint = null
    this._snapPoint = null
    this.emitEditShapeEvent(this.bounds, EditShapeStatus.FINISHED)
    return EVENT_HANDLED | REQUEST_FINISH
  }
  getCursor() {
    return 'crosshair'
  }
  onDraw(t, e) {
    SnapUtil.paintSnapIcon(t, this._snapPoint)
  }
}
