import { CoordinateType } from '../reference/CoordinateType.js'
import { XYZBounds } from './XYZBounds.js'
import { XYZPoint } from './XYZPoint.js'
import {
  contains2D,
  distance2D,
  segmentContainsXY2D,
} from '../util/Cartesian.js'
import {
  calculate2DCoordinateAtFractionOnLine,
  onSomeSegments,
  Polyline,
} from './Polyline.js'
import { validatePointRepresentationForCreations } from './ShapeWithPointList.js'
import { isNumber } from '../util/Lang.js'
export class XYZPolyline extends Polyline {
  constructor(t, e) {
    super()
    this._reference = this.validateReference(t)
    this._coordinates = validatePointRepresentationForCreations(e, this)
  }
  get isGeodetic() {
    return false
  }
  calculateInterpolationPoint(t) {
    const e = calculate2DCoordinateAtFractionOnLine(
      t,
      super.getSimplePoints(),
      distance2D,
      (t, e, n) => ({
        x: t.x + n * (e.x - t.x),
        y: t.y + n * (e.y - t.y),
        z: t.z,
      })
    )
    return e ? new XYZPoint(this.reference, [e.x, e.y, e.z]) : null
  }
  lineContainsXY(t, e, n) {
    return onSomeSegments(t, (t, o) =>
      segmentContainsXY2D(t[0], t[1], o[0], o[1], e, n)
    )
  }
  get coordinateType() {
    return CoordinateType.CARTESIAN
  }
  get bounds() {
    if (0 === this.pointCount) return null
    if (!this._bounds) {
      const t = this._coordinates
      const e = t.length
      const n = t[0]
      const o = new XYZBounds(this.reference)
      o.move3DToCoordinates(...n)
      for (let n = 1; n < e; n++) o.includeCoordinate3D(...t[n])
      this._bounds = o
    }
    return this._bounds
  }
  copy() {
    return new XYZPolyline(this._reference, this._coordinates)
  }
  contains2DCoordinates(t, e) {
    const n = this.pointCount
    if (0 === n) return false
    const o = new XYZPoint(this.reference, [t, e, 0])
    const i = this.getSimplePoint(0)
    let r = pointXYZ_equalLonLatPoint2D(i.x, i.y, t, e)
    let s = 1
    while (!r && s < n) {
      r = contains2D(this.getSimplePoint(s - 1), this.getSimplePoint(s), o)
      s++
    }
    return r
  }
  implMove(t, e, n, o) {
    t[0] = e
    t[1] = n
    if (isNumber(o)) t[2] = o
  }
  implTranslate(t, e, n) {
    let o = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0
    t[0] += e
    t[1] += n
    t[2] += o
  }
}
function pointXYZ_equalLonLatPoint2D(t, e, n, o) {
  return t === n && e === o
}
