import { Constants } from '../util/Constants.js'
import { ShapeType } from './ShapeType.js'
import { XYZPoint } from './XYZPoint.js'
import {
  containsAngle,
  containsAnglePragmatic,
  forwardAzimuth2D,
} from '../util/Cartesian.js'
export function contains2D(t, n, e) {
  const o = n - t.center.x
  const i = e - t.center.y
  const s = 90 - Math.atan2(i, o) * Constants.RAD2DEG
  if (!containsAnglePragmatic(t.startAzimuth, t.sweepAngle, s)) return false
  return (
    Math.abs(Math.sqrt(o * o + i * i) - t.radius) <=
    Constants.ABSOLUTE_DISTANCE_TOLERANCE +
      Constants.RELATIVE_DISTANCE_TOLERANCE * t.radius
  )
}
export function interpolate(t, n, e) {
  if (
    ShapeType.contains(t.type, ShapeType.CIRCULAR_ARC_BY_3_POINTS) &&
    _isIntermediateEqualToStartOrEnd(t)
  ) {
    const o = t.startPoint
    const i = t.endPoint
    e.move2DToCoordinates(o.x + (i.x - o.x) * n, o.y + (i.y - o.y) * n)
  } else {
    const o = (90 - (t.startAzimuth + n * t.sweepAngle)) * Constants.DEG2RAD
    const i = t.radius
    e.move2DToCoordinates(
      t.center.x + i * Math.cos(o),
      t.center.y + i * Math.sin(o)
    )
  }
}
function _isIntermediateEqualToStartOrEnd(t) {
  const n = t.startPoint
  const e = t.intermediatePoint
  const o = t.endPoint
  return (e.x === n.x && e.y === n.y) || (e.x === o.x && e.y === o.y)
}
export function boundsSFCT(t, n) {
  const e = new XYZPoint(t.reference)
  interpolate(t, 0, e)
  const o = new XYZPoint(t.reference)
  interpolate(t, 1, o)
  const i = t.center
  const s = t.radius
  const r = t.sweepAngle
  let a, c, A, f
  if (r >= 360 || r <= -360) {
    a = -s
    c = -s
    A = s
    f = s
  } else {
    a = Math.min(e.x, o.x) - i.x
    c = Math.min(e.y, o.y) - i.y
    A = Math.max(e.x, o.x) - i.x
    f = Math.max(e.y, o.y) - i.y
    if (containsAngle(t.startAzimuth, r, 90)) {
      if (a > s) a = s
      if (A < s) A = s
    }
    if (containsAngle(t.startAzimuth, r, 0)) {
      if (c > s) c = s
      if (f < s) f = s
    }
    if (containsAngle(t.startAzimuth, r, -90)) {
      if (a > -s) a = -s
      if (A < -s) A = -s
    }
    if (containsAngle(t.startAzimuth, r, -180)) {
      if (c > -s) c = -s
      if (f < -s) f = -s
    }
  }
  n.move2DToCoordinates(i.x + a, i.y + c)
  n.width = A - a
  n.height = f - c
}
export function getStartAngle3Points(t, n) {
  return getAngle(t, n)
}
export function getArcAngle3Points(t, n, e, o) {
  return getArcAngleByAngles(getAngle(t, n), getAngle(t, e), getAngle(t, o))
}
function getAngle(t, n) {
  return forwardAzimuth2D(t, n) * Constants.RAD2DEG
}
function getArcAngleByAngles(t, n, e) {
  if (t < 0) t += 360
  if (n < 0) n += 360
  if (e < 0) e += 360
  const o = e - t
  if (t < e) return containsAngle(t, o, n) ? o : o - 360
  else return containsAngle(t, o + 360, n) ? o + 360 : o
}
export function getMidPoint(t, n) {
  const e = t.startPoint
  const o = t.endPoint
  const i = t.bulge
  const s = (e.x + o.x) / 2
  const r = (e.y + o.y) / 2
  const a = o.x - e.x
  const c = o.y - e.y
  n.move2DToCoordinates(s - c * i * 0.5, r + a * i * 0.5)
}
