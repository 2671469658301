import { ArrayCursor } from '../../../model/store/ArrayCursor.js'
import { QueryProvider } from '../QueryProvider.js'
import { SingleQueryProvider } from '../SingleQueryProvider.js'
import { LoadingStrategy } from './LoadingStrategy.js'
import {
  getBoundsAreaDifferenceFactor,
  LoadSpatiallySupport,
  shouldQueryBecauseOfMaxFeatures,
} from './LoadSpatiallySupport.js'
import { isFunction } from '../../../util/Lang.js'
import { Log } from '../../../util/Log.js'
import { createBounds } from '../../../shape/ShapeFactory.js'
import { OutOfBoundsError } from '../../../error/OutOfBoundsError.js'
import { NoBoundsError } from '../../../error/NoBoundsError.js'
const ENLARGE_FACTOR = 1.4
export class LoadSpatially extends LoadingStrategy {
  _lastQueryEnlargedBounds = null
  _lastQueryBounds = null
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    super(e)
    this.queryProvider =
      e.queryProvider || new SingleQueryProvider(QueryProvider.QUERY_ALL)
    this._singleQueryLevel = !!e.singleQueryLevel
    this._culling = false
    this._support = new LoadSpatiallySupport()
    this._logger = new Log({ avoidDuplicateMessages: true })
  }
  get culling() {
    return this._culling
  }
  refreshProperties() {
    if (!this.layer) return
    const e = this.isCullingMode(this.layer)
    if (this._culling !== e) {
      this._lastQueryEnlargedBounds = null
      this._lastQueryBounds = null
    }
    this._culling = e
  }
  isNothingToShow() {
    if (this._culling) return false
    const { level: e, query: r } = this.getLevelQueryParams()
    if (r === QueryProvider.QUERY_NONE) {
      this.activeQueryLevel = e
      return true
    }
    return false
  }
  isCullingMode(e) {
    if (this._singleQueryLevel) return false
    const { map: r } = e
    if (r && r.is3D()) {
      const t = e.scaleRange
      if (t.min >= 0 || t.max <= 1) return true
      const s = this.queryProvider.getQueryLevelScales(e, r)
      return !!(s && s.length)
    }
    return false
  }
  getModelBounds(e) {
    if (!this?.layer?.map) return null
    try {
      const r = createBounds(e.reference, [0, 0, 0, 0])
      this.layer.map.getMapBoundsSFCT(r)
      if (e.bounds) {
        if (0 === e.bounds.width || 0 === e.bounds.height)
          this._logger.warn(
            'LoadSpatially: suspicious BBOX filter object, the width or the height is 0.'
          )
        if (!r.interacts2D(e.bounds)) return null
        r.setTo2DIntersection(e.bounds)
      }
      return r
    } catch (e) {
      if (!OutOfBoundsError.is(e) && !NoBoundsError.is(e)) throw e
      return null
    }
  }
  shouldQuery(e) {
    if (!this.layer) return false
    const r = this.getModelBounds(this.layer.model)
    if (!r) return false
    return this._culling
      ? this._support.multiScaleShouldQuery(
          this.layer,
          r,
          this.queryProvider,
          e
        )
      : this.singleScaleShouldQuery(r, e)
  }
  singleScaleShouldQuery(e, r) {
    if (this.isMapLevelChanged()) return true
    if (
      !this._lastQueryBounds ||
      !this._lastQueryEnlargedBounds?.contains2DBounds(e)
    )
      return true
    const { query: t } = this.getLevelQueryParams()
    const s = getBoundsAreaDifferenceFactor(this._lastQueryBounds, e)
    return shouldQueryBecauseOfMaxFeatures(t, r, s)
  }
  isCompatibleWithModel(e) {
    return isFunction(e.spatialQuery)
  }
  isRenderIncremental() {
    return false
  }
  shouldClear() {
    return false
  }
  queryModel(e, r) {
    const t = this.getModelBounds(e)
    if (!t) return new ArrayCursor([])
    if (this._culling && this.layer)
      return this._support.multiScaleQueryModel(
        this.layer,
        t,
        this.queryProvider
      )
    this.activeQueryLevel = this.findMapLevel()
    const s = t.copy().enlarge2D(ENLARGE_FACTOR)
    this._lastQueryEnlargedBounds = s
    this._lastQueryBounds = t.copy()
    return e.spatialQuery(s, this.getLevelQueryParams().query, {
      abortSignal: r,
    })
  }
  onVisibilityChanged() {
    this.layer?.refreshQueryResults()
  }
  onEnterAllowedScaleRange() {
    this.layer?.refreshQueryResults()
  }
}
