import { WavePattern } from './pattern/WavePattern.js'
import { TextPattern } from './pattern/TextPattern.js'
import { IconPattern } from './pattern/IconPattern.js'
import { ArrowPattern, normalizeArrowOptions } from './pattern/ArrowPattern.js'
import { LinePattern } from './pattern/LinePattern.js'
import { ParallelLinePattern } from './pattern/ParallelLinePattern.js'
import { PolylinePattern } from './pattern/PolylinePattern.js'
import { RectanglePattern } from './pattern/RectanglePattern.js'
import { AllowOverlapPattern } from './pattern/AllowOverlapPattern.js'
import { AppendPattern } from './pattern/AppendPattern.js'
import { CombineWithFallbackPattern } from './pattern/CombineWithFallbackPattern.js'
import { CombineWithRegularPattern } from './pattern/CombineWithRegularPattern.js'
import { ArcPattern } from './pattern/ArcPattern.js'
import { AtomicPattern } from './pattern/AtomicPattern.js'
import { ComposePattern } from './pattern/ComposePattern.js'
import { GapPattern } from './pattern/GapPattern.js'
import { RepeatCountPattern } from './pattern/RepeatCountPattern.js'
import { RepeatOverLengthPattern } from './pattern/RepeatOverLengthPattern.js'
import { TrianglePattern } from './pattern/TrianglePattern.js'
import { defaultLineIfNecessary } from './pattern/util/PatternUtil.js'
export function allowOverlap(t, e) {
  return new AllowOverlapPattern(t, e)
}
export function append(t) {
  return new AppendPattern(t)
}
export function combineWithFallback(t) {
  return new CombineWithFallbackPattern(t)
}
export function combineWithRegular(t) {
  return new CombineWithRegularPattern(t)
}
export function arc(t) {
  return new ArcPattern(defaultLineIfNecessary(t))
}
export function arrow(t) {
  return new ArrowPattern(normalizeArrowOptions(t))
}
export function atomic(t) {
  return new AtomicPattern(t)
}
export function compose(t) {
  return new ComposePattern(t)
}
export function gap(t, e) {
  return new GapPattern(t, e)
}
export function line(t) {
  return new LinePattern(defaultLineIfNecessary(t))
}
export function parallelLine(t) {
  return new ParallelLinePattern(defaultLineIfNecessary(t))
}
export function polyline(t) {
  return new PolylinePattern(defaultLineIfNecessary(t))
}
export function rectangle(t) {
  return new RectanglePattern(defaultLineIfNecessary(t))
}
export function repeat(t, e) {
  return new RepeatCountPattern(t, e)
}
export function repeatOverLength(t, e, r) {
  return new RepeatOverLengthPattern(t, e, r)
}
export function text(t) {
  return new TextPattern(t)
}
export function triangle(t) {
  return new TrianglePattern(defaultLineIfNecessary(t))
}
export function wave(t) {
  return new WavePattern(defaultLineIfNecessary(t))
}
export function icon(t) {
  return new IconPattern(t)
}
