import { GapPattern } from './GapPattern.js'
import { Serializable } from './util/Serializable.js'
import { isPromise } from '../../../../util/PromiseUtil.js'
import { Pattern } from '../Pattern.js'
export class MultiPattern extends Pattern {
  constructor(t, e) {
    super(e)
    if (0 === t.length)
      throw new Error(
        `List of patterns to ${e
          .toString()
          .toLowerCase()} must be of length >= 1.`
      )
    this._patterns = t.slice()
  }
  get patterns() {
    return this._patterns
  }
  set lineJoin(t) {
    for (const e of this._patterns) e.lineJoin = t
  }
  resolveState() {
    let t = null
    for (let e = 0, r = this._patterns.length; e < r; e++) {
      const r = this._patterns[e].resolveState()
      if (isPromise(r)) {
        if (!t) t = []
        t.push(r)
      }
    }
    return null != t ? Promise.all(t) : null
  }
  getMinHeight() {
    let t = Number.POSITIVE_INFINITY
    for (let e = 0, r = this._patterns.length; e < r; e++)
      if (!(this._patterns[e] instanceof GapPattern))
        t = Math.min(t, this._patterns[e].getMinHeight())
    return t === Number.POSITIVE_INFINITY ? 0 : t
  }
  getMaxHeight() {
    let t = Number.NEGATIVE_INFINITY
    for (let e = 0, r = this._patterns.length; e < r; e++)
      if (!(this._patterns[e] instanceof GapPattern))
        t = Math.max(t, this._patterns[e].getMaxHeight())
    return t === Number.NEGATIVE_INFINITY ? 0 : t
  }
  isRelativeLength() {
    for (let t = 0, e = this._patterns.length; t < e; t++)
      if (this._patterns[t].isRelativeLength()) return true
    return false
  }
  setFallbackPattern(t) {
    super.setFallbackPattern(t)
    if (this.patterns && this.patterns.length > 0)
      for (let e = 0; e < this.patterns.length; e++)
        this.patterns[e].setFallbackPattern(t)
  }
  appendHash(t) {
    for (let e = 0; e < this._patterns.length; e++)
      this._patterns[e].appendHash(t)
  }
  serialize() {
    const t = super.serialize()
    delete t._canBend
    return Serializable.serialize(t)
  }
}
