import { ProgrammingError } from '../../error/ProgrammingError.js'
import { EventedSupport } from '../../util/EventedSupport.js'
class QueryProvider {
  _query = QueryProvider.QUERY_ALL
  static QUERY_NONE = {}
  static QUERY_ALL = null
  get intEventedSupport() {
    if (!this._intEventedSupport) this._intEventedSupport = new EventedSupport()
    return this._intEventedSupport
  }
  getQueryLevelScales(r, e) {
    return []
  }
  getQueryForLevel(r) {
    throw new ProgrammingError('QueryProvider must implement getQueryForLevel')
  }
  invalidate() {
    this.intEventedSupport.emit('invalidate')
  }
  on(r, e, t) {
    return this.intEventedSupport.on(r, e, t)
  }
}
export { QueryProvider }
