import { Circle } from './Circle.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
export class CircleByCenterPoint extends Circle {
  get type() {
    return ShapeType.CIRCLE + ShapeType.CIRCLE_BY_CENTER_POINT
  }
  translate2D(e, t) {
    this.center.translate2D(e, t)
    this._cache.invalidate()
  }
  move2DToCoordinates(e, t) {
    this.center.move2DToCoordinates(e, t)
    this._cache.invalidate()
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    this.center.hashCode(e)
    e.appendDouble(this.radius)
  }
  toString() {
    return `CircleByCenterPoint[center${this.center.toString(true)}, radius[${
      this.radius
    }]]`
  }
  equals(e) {
    if (this === e) return true
    if (!ShapeUtil.referencesEqual(this.reference, e.reference)) return false
    return (
      this.type === e.type &&
      this.radius === e.radius &&
      this.center.equals(e.center)
    )
  }
}
