import { Constants } from '../../util/Constants.js'
import { isNumber, isString } from '../../util/Lang.js'
import { RotatedBox } from '../../util/RotatedBox.js'
import { createFontMetrics, getFontScale } from './FontMetrics.js'
import { StyleUtil } from './StyleUtil.js'
import { isWorldSize } from '../../uom/UnitOfMeasureUtil.js'
const WORLD_SIZED_TEXT_ICON_HEIGHT = 100
function calculatePadding(t) {
  const e = t.stroke && t.strokeWidth ? t.strokeWidth >> 1 : 0
  const n = t.halo && t.haloWidth ? t.haloWidth >> 1 : 0
  return e > n ? e : n
}
function calculateBounds(t, e, n, o) {
  const l = createFontMetrics(e.font)
  const i = n ? calculatePadding(e) : 0
  let s = e.textAnchor
  let a = 0
  let r = 0
  const c = StyleUtil.parsePercentage(s)
  if (null !== c) s = 'left'
  let f = e.alignmentBaseline
  const d = StyleUtil.parsePercentage(f)
  if (null !== d) f = 'top'
  const h = l.measureText(t, s, f)
  const u = h.bounds
  if (i > 0) {
    u[0] -= i
    u[1] -= i
    u[2] += i
    u[3] += i
    if (h.linesOfText > 1) u[3] += (h.linesOfText - 1) * i
  }
  let g = (u[2] - u[0]) / 2
  let x = (u[3] - u[1]) / 2
  if (null !== c) {
    const t = u[2] - u[0]
    u[2] = t
    u[0] = 0
    a = (-c / 100) * t
    g = 0
  }
  if (null !== d) {
    const t = u[3] - u[1]
    u[3] = t
    u[1] = 0
    r = (-d / 100) * t
    x = 0
  }
  u[0] += a
  u[1] += r
  u[2] += a
  u[3] += r
  if (o && isNumber(e.angle) && 0 !== e.angle) {
    const t = new RotatedBox()
    t.configure(
      u[0],
      u[1],
      u[2] - u[0],
      u[3] - u[1],
      e.angle * Constants.DEG2RAD,
      g,
      x
    )
    u[0] = t.getMinX()
    u[1] = t.getMinY()
    u[2] = t.getMaxX()
    u[3] = t.getMaxY()
  }
  return u
}
function rasterizeText(t, e, n, o) {
  const l = t[3] - t[1]
  const i = t[2] - t[0]
  const s = calculatePadding(o)
  const a = i - 2 * s
  const r = document.createElement('canvas')
  let c = 1
  if (isWorldSize(o.uom)) {
    r.height = WORLD_SIZED_TEXT_ICON_HEIGHT
    const t = i / l
    r.width = r.height * t
    c = r.height / l
  } else {
    r.width = i
    r.height = l
  }
  const f = s
  let d = s
  const h = StyleUtil.parsePercentage(o.textAnchor)
  if (null !== h) d = s + (h / 100) * a
  else if ('right' === o.textAnchor || 'end' === o.textAnchor) d = i - s
  else if ('left' === o.textAnchor || 'start' === o.textAnchor) d = s
  else if ('center' === o.textAnchor) d = s + a / 2
  const u = undefined
  drawText(
    r.getContext('2d', { willReadFrequently: true }),
    e,
    d * c,
    f * c,
    n,
    o,
    c,
    'top'
  )
  return r
}
function drawText(t, e, n, o, l, i, s, a) {
  const r = 0 !== l
  n += i.offsetX * s
  o += i.offsetY * s
  const { fontScale: c, scaledFont: f } = getFontScale(i.font)
  if (1 !== c) {
    ;(i = { ...i }).font = f
    i.haloWidth *= c
    i.strokeWidth *= c
  }
  const d = calculatePadding(i)
  t.font = i.font
  if (r || 1 !== s) {
    t.save()
    t.translate(n, o)
    if (1 !== s) t.scale(s, s)
    if (r) t.rotate(l * Constants.DEG2RAD)
    n = 0
    o = 0
  }
  const h = createFontMetrics(i.font)
  const u = h.measureText(e, 'left', 'top').bounds
  const g = u[2] - u[0]
  const x = u[3] - u[1]
  const T = StyleUtil.parsePercentage(i.textAnchor)
  const S = StyleUtil.parsePercentage(i.alignmentBaseline)
  let m = 0
  let W = 0
  if (null !== T) {
    t.textAlign = 'left'
    m += (-T / 100) * g
  } else t.textAlign = i.textAnchor
  if (a) t.textBaseline = a
  else if (null !== S) {
    t.textBaseline = 'top'
    W += (-S / 100) * x
  } else t.textBaseline = i.alignmentBaseline
  n += m
  o += W
  const p = isString(e) ? e.split('\n') : e ? [e] : []
  const k = p.length > 1 ? d : 0
  const A = h.lineHeight + k
  if (1 !== c) {
    t.save()
    t.scale(1 / c, 1 / c)
  }
  if (i.halo && i.haloWidth > 0) {
    t.lineJoin = 'round'
    t.lineWidth = i.haloWidth
    t.strokeStyle = i.halo
    for (let e = 0; e < p.length; e++) t.strokeText(p[e], n, o + e * A)
  }
  if (i.fill) {
    t.fillStyle = i.fill
    for (let e = 0; e < p.length; e++) t.fillText(p[e], n, o + e * A)
  }
  if (i.stroke && i.strokeWidth > 0) {
    t.lineWidth = i.strokeWidth
    t.strokeStyle = i.stroke
    for (let e = 0; e < p.length; e++) t.strokeText(p[e], n, o + e * A)
  }
  if (1 !== c) t.restore()
  if (r || 1 !== s) t.restore()
}
function drawTextAnchoredTopLeft(t, e, n, o, l, i, s) {
  const { fontScale: a, scaledFont: r } = getFontScale(i.font)
  if (1 !== a) {
    ;(i = { ...i }).font = r
    i.haloWidth *= a
    i.strokeWidth *= a
  }
  t.font = i.font
  t.textAlign = 'left'
  t.textBaseline = 'top'
  t.save()
  t.translate(n, o)
  t.rotate(l * Constants.DEG2RAD)
  t.scale(s, s)
  if (1 !== a) {
    t.save()
    t.scale(1 / a, 1 / a)
  }
  const c = undefined
  n = 0
  o = calculatePadding(i)
  if (i.halo) {
    t.lineWidth = i.haloWidth
    t.strokeStyle = i.halo
    t.strokeText(e, n, o)
  }
  if (i.fill) {
    t.fillStyle = i.fill
    t.fillText(e, n, o)
  }
  if (i.stroke) {
    t.lineWidth = i.strokeWidth
    t.strokeStyle = i.stroke
    t.strokeText(e, n, o)
  }
  if (1 !== a) t.restore()
  t.restore()
}
function createCanvas(t, e) {
  const n = undefined
  return rasterizeText(calculateBounds(t, e, true, false), t, e.angle, e)
}
export const HTML5TextUtil = {
  drawText: drawText,
  calculateBounds: calculateBounds,
  createCanvas: createCanvas,
  drawTextAnchoredTopLeft: drawTextAnchoredTopLeft,
  calculatePadding: calculatePadding,
}
