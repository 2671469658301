import { Log } from '../../util/Log.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { XML } from '../../util/XML.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { shouldSwapAxes } from '../../reference/AxisInformationUtil.js'
function getSRSDimension(e) {
  if (e && e.parentNode && e.hasAttribute('srsDimension')) {
    const t = e.getAttribute('srsDimension')
    if (t) {
      const e = parseInt(t)
      if (2 === e || 3 === e) return e
    }
    Log.warn(`Invalid srsDimension: ${t}`)
  }
  return null
}
function parseCoordinate(e) {
  const t = parseFloat(e)
  if (isNaN(t)) throw new ProgrammingError(`Invalid coordinate: ${e}`)
  return t
}
function findSRSDimension(e) {
  const t = getSRSDimension(e)
  if (t) return t
  return e.parentNode ? findSRSDimension(e.parentNode) : null
}
function coordinatesAsPointCoordinates(e, t, n) {
  let o = e.textContent.trim()
  let r = '.'
  let i = ','
  let s = ' '
  const a = e.attributes
  if (a) {
    r = a.decimal ? a.decimal.textContent : r
    i = a.cs ? a.cs.textContent : i
    s = a.ts ? a.ts.textContent : s
  }
  if ('.' !== r) o = o.replace(r, '.')
  const l = o.split(s)
  let p, c
  let f = 0
  let d = 1
  if (shouldSwapAxes(t, n)) {
    f = 1
    d = 0
  }
  const m = []
  if (l && l.length > 0) {
    p = l[0].split(i)
    c = getSRSDimension(e) || p.length
    if (2 === c)
      for (let e = 0; e < l.length; e++) {
        p = l[e].split(i)
        m.push([parseCoordinate(p[f]), parseCoordinate(p[d])])
      }
    else if (3 === c)
      for (let e = 0; e < l.length; e++) {
        p = l[e].split(i)
        m.push([
          parseCoordinate(p[f]),
          parseCoordinate(p[d]),
          parseCoordinate(p[2]),
        ])
      }
    else throw new ProgrammingError(`Invalid coordinates: ${l}`)
  }
  return m
}
function posAsPointCoordinates(e, t, n, o) {
  const r = e.textContent.trim()
  const i = getSRSDimension(e) || o
  const s = r.split(/\ +/)
  let a = 0
  let l = 1
  if (shouldSwapAxes(t, n)) {
    a = 1
    l = 0
  }
  if (i < 2 || i > 3 || s.length % i)
    throw new ProgrammingError(
      `Invalid coordinates length: ${s.length}, srs dimension: ${i}`
    )
  const p = []
  if (2 === i)
    for (let e = 0; e < s.length; e += 2)
      p.push([parseCoordinate(s[e + a]), parseCoordinate(s[e + l])])
  else if (3 === i)
    for (let e = 0; e < s.length; e += 3)
      p.push([
        parseCoordinate(s[e + a]),
        parseCoordinate(s[e + l]),
        parseCoordinate(s[e + 2]),
      ])
  return p
}
export function parseGeometryToPointCoordinates(e, t, n) {
  let o = []
  const r =
    findSRSDimension(e) ||
    (t.referenceType === ReferenceType.GEOCENTRIC ? 3 : 2)
  const i = getChildren(e)
  for (let e = 0; e < i.length; e++) {
    const s = i[e]
    if (XML.matchesName(s, 'posList', 'http://www.opengis.net/gml'))
      return posAsPointCoordinates(s, t, n, r)
    if (XML.matchesName(s, 'pos', 'http://www.opengis.net/gml'))
      o = o.concat(posAsPointCoordinates(s, t, n, r))
    if (XML.matchesName(s, 'coordinates', 'http://www.opengis.net/gml'))
      return coordinatesAsPointCoordinates(s, t, n)
  }
  return o
}
function getChildren(e) {
  const t = []
  if (e.children)
    for (let n = 0; n < e.childElementCount; n++) t.push(e.children[n])
  else {
    const n = e.childNodes
    let o = 0
    let r = n[o++]
    while (r) {
      if (1 === r.nodeType) t.push(r)
      r = n[o++]
    }
  }
  return t
}
