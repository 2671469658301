import { LLHPoint } from '../shape/LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { isNumber, isObject, isString } from '../util/Lang.js'
import { DEFAULT, Ellipsoid } from './Ellipsoid.js'
const tempLLHPoint = new LLHPoint()
const REFERENCE = 1
const SIMPLE = 2
const COMPLEX = 3
export class GeodeticDatum {
  constructor(t) {
    this._name = ''
    this._ellipsoid = DEFAULT
    this._refDatum = this
    this._x = 0
    this._y = 0
    this._z = 0
    this._rotX = 0
    this._rotY = 0
    this._rotZ = 0
    this._scale = 1
    this._primeMeridian = 0
    this._meridianCos = 1
    this._meridianSin = 0
    this._type = 0
    this._hash = 0
    let i = 0,
      s = 0,
      e = 0,
      r = 0,
      h = 0,
      o = 0,
      _ = 1,
      n = 0,
      a = DEFAULT,
      l,
      m = this
    if ((isObject(t) || Array.isArray(t)) && null !== t) {
      if (isNumber(t.x)) i = t.x
      if (isNumber(t.y)) s = t.y
      if (isNumber(t.z)) e = t.z
      if (isNumber(t.rotX)) r = t.rotX
      if (isNumber(t.rotY)) h = t.rotY
      if (isNumber(t.rotZ)) o = t.rotZ
      if (isNumber(t.scale)) _ = t.scale
      if (isNumber(t.primeMeridian)) n = t.primeMeridian
      if (isObject(t.ellipsoid) && t.ellipsoid instanceof Ellipsoid)
        a = t.ellipsoid
      if (isObject(t.ellipsoid) && t.referenceDatum instanceof GeodeticDatum)
        m = t.referenceDatum
      if (isString(t.name)) l = t.name
      else if (
        0 == i &&
        0 == s &&
        0 == e &&
        0 == r &&
        0 == h &&
        0 == o &&
        1 == _ &&
        0 == n &&
        a.equals(DEFAULT) &&
        m === this
      )
        l = a.name
      else l = 'NO NAME'
    } else l = a.name
    this.initialize(i, s, e, r, h, o, _, n, a, m, l)
  }
  initialize(t, i, s, e, r, h, o, _, n, a, l) {
    this._x = t
    this._y = i
    this._z = s
    this._rotX = e
    this._rotY = r
    this._rotZ = h
    this._scale = o
    this._primeMeridian = _
    this._meridianCos = Math.cos(this._primeMeridian * Constants.DEG2RAD)
    this._meridianSin = Math.sin(this._primeMeridian * Constants.DEG2RAD)
    this._ellipsoid = n
    this._refDatum = a
    this._name = l
    const m = new Hash()
    m.appendDouble(this._x)
      .appendDouble(this._y)
      .appendDouble(this._z)
      .appendDouble(this._rotX)
      .appendDouble(this._rotY)
      .appendDouble(this._rotZ)
      .appendDouble(this._scale)
      .appendDouble(this._primeMeridian)
    this._ellipsoid.hashCode(m)
    this._hash = m.getHashCode()
    this.determineType()
  }
  determineType() {
    if (
      0 == this._rotX &&
      0 == this._rotY &&
      0 == this._rotZ &&
      1 == this._scale &&
      0 == this._primeMeridian
    )
      if (0 == this._x && 0 == this._y && 0 == this._z) this._type = REFERENCE
      else this._type = SIMPLE
    else this._type = COMPLEX
  }
  getHeight(t, i) {
    return 0
  }
  hasNonZeroHeights() {
    return false
  }
  equals(t) {
    return this.equals2D(t)
  }
  equals2D(t) {
    return (
      t === this ||
      (t instanceof GeodeticDatum &&
        this._x == t._x &&
        this._y == t._y &&
        this._z == t._z &&
        this._rotX == t._rotX &&
        this._rotY == t._rotY &&
        this._rotZ == t._rotZ &&
        this._scale == t._scale &&
        this._primeMeridian == t._primeMeridian &&
        this._ellipsoid.equals(t._ellipsoid) &&
        (this._refDatum._name == t._refDatum._name ||
          this._refDatum._ellipsoid.equals(t._refDatum._ellipsoid)))
    )
  }
  geoc2geodSFCT(t, i) {
    this._ellipsoid.geoc2geodSFCT(t, i)
    i.z -= this.getHeight(i.x, i.y)
  }
  geod2geocSFCT(t, i) {
    tempLLHPoint.move3DToCoordinates(t.x, t.y, t.z)
    tempLLHPoint.z += this.getHeight(t.x, t.y)
    this._ellipsoid.geod2geocSFCT(tempLLHPoint, i)
  }
  datum2refGeocSFCT(t, i) {
    if (this._type == REFERENCE) {
      i.x = t.x
      i.y = t.y
      i.z = t.z
    } else if (this._type == SIMPLE) {
      i.x = t.x + this._x
      i.y = t.y + this._y
      i.z = t.z + this._z
    } else {
      const s = t.x
      const e = t.y
      const r = s * this._meridianCos - e * this._meridianSin
      const h = s * this._meridianSin + e * this._meridianCos,
        o = t.z
      i.x = this._scale * (r - this._rotZ * h + this._rotY * o) + this._x
      i.y = this._scale * (h + this._rotZ * r - this._rotX * o) + this._y
      i.z = this._scale * (o - this._rotY * r + this._rotX * h) + this._z
    }
  }
  ref2datumGeocSFCT(t, i) {
    if (this._type == REFERENCE) {
      i.x = t.x
      i.y = t.y
      i.z = t.z
    } else if (this._type == SIMPLE) {
      i.x = t.x - this._x
      i.y = t.y - this._y
      i.z = t.z - this._z
    } else {
      const s =
        1 +
        this._rotX * this._rotX +
        this._rotY * this._rotY +
        this._rotZ * this._rotZ
      const e = this._scale * s
      const r = (t.x - this._x) / e
      const h = (t.y - this._y) / e
      const o = (t.z - this._z) / e
      const _ =
        (1 + this._rotX * this._rotX) * r +
        (this._rotX * this._rotY + this._rotZ) * h +
        (this._rotZ * this._rotX - this._rotY) * o
      const n =
        (this._rotX * this._rotY - this._rotZ) * r +
        (1 + this._rotY * this._rotY) * h +
        (this._rotY * this._rotZ + this._rotX) * o
      const a =
        (this._rotZ * this._rotX + this._rotY) * r +
        (this._rotY * this._rotZ - this._rotX) * h +
        (1 + this._rotZ * this._rotZ) * o
      i.x = this._meridianCos * _ + this._meridianSin * n
      i.y = -this._meridianSin * _ + this._meridianCos * n
      i.z = a
    }
  }
  withPrimeMeridian(t) {
    if (t === this.primeMeridian) return this
    return new GeodeticDatum({
      x: this.x,
      y: this.y,
      z: this.z,
      rotX: this.rotX,
      rotY: this.rotY,
      rotZ: this.rotZ,
      scale: this.scale,
      primeMeridian: t,
      ellipsoid: this.ellipsoid,
      referenceDatum: this.referenceDatum,
      name: this.name,
    })
  }
  get name() {
    return this._name
  }
  get x() {
    return this._x
  }
  get y() {
    return this._y
  }
  get z() {
    return this._z
  }
  get rotX() {
    return this._rotX
  }
  get rotY() {
    return this._rotY
  }
  get rotZ() {
    return this._rotZ
  }
  get scale() {
    return this._scale
  }
  get primeMeridian() {
    return this._primeMeridian
  }
  get ellipsoid() {
    return this._ellipsoid
  }
  get referenceDatum() {
    return this._refDatum
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
