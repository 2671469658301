import { EVENT_HANDLED } from '../../controller/HandleEventResult.js'
import { GestureEventType } from '../../input/GestureEventType.js'
import * as SnapUtil from '../snapping/SnapUtil.js'
import { PointHandle } from './PointHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { viewToModel, viewToModelOnHeightAxis } from './HandleUtil.js'
import { EditMoveConstraint } from './EditMoveConstraint.js'
import { DrapeTarget } from '../../style/DrapeTarget.js'
export class PointDragHandle extends PointHandle {
  constructor(t, e, i) {
    const n = i?.moveConstraint ?? EditMoveConstraint.XY
    super(i?.handleIconStyle)
    this._getPoint = t
    this._drag = e
    this._moveConstraint = n
  }
  getDrapeTarget(t) {
    return this.moveConstraint === EditMoveConstraint.Z
      ? DrapeTarget.NOT_DRAPED
      : super.getDrapeTarget(t)
  }
  shouldActivate(t, e) {
    return t.type === GestureEventType.DOWN && this.interacts(t, e)
  }
  getPoint() {
    return this._getPoint()
  }
  shouldProcess(t) {
    return t.type === GestureEventType.DRAG
  }
  process(t, e) {
    const i = this.viewToModel(t.viewPoint, e)
    const n = SnapUtil.findClosestPoint(t, this.getPoint(), e.shape, e.map)
    if (i && n) {
      i.move2D(n)
      this.snapPoint = n.copy()
    } else this.snapPoint = null
    if (i) {
      this.drag(i, t, e)
      this.emitEditShapeEvent(e.shape, EditShapeStatus.IN_PROGRESS)
    }
    return EVENT_HANDLED
  }
  shouldDeactivate(t) {
    return (
      t.type === GestureEventType.UP ||
      t.type === GestureEventType.DRAG_END ||
      t.type === GestureEventType.CONTEXT_MENU
    )
  }
  deactivate(t, e) {
    this.snapPoint = null
    return super.deactivate(t, e)
  }
  get moveConstraint() {
    return this._moveConstraint
  }
  viewToModel(t, e) {
    switch (this._moveConstraint) {
      case EditMoveConstraint.XY:
        return viewToModel(t, e, true)
      case EditMoveConstraint.Z:
        return viewToModelOnHeightAxis(t, e, this.getPoint())
    }
  }
  drag(t, e, i) {
    this._drag(t, e, i)
  }
}
