import { ArcBandEditor } from './editors/ArcBandEditor.js'
import { SectorEditor } from './editors/SectorEditor.js'
import { ArcEditor } from './editors/ArcEditor.js'
import { EllipseEditor } from './editors/EllipseEditor.js'
import { GeoBufferEditor } from './editors/GeoBufferEditor.js'
import { PointEditor } from './editors/PointEditor.js'
import { PointListEditor } from './editors/PointListEditor.js'
import { BoundsEditor } from './editors/BoundsEditor.js'
import { CompositeEditor } from './editors/CompositeEditor.js'
import { CircleBy3PointsEditor } from './editors/CircleBy3PointsEditor.js'
import { CircleByCenterPointEditor } from './editors/CircleByCenterPointEditor.js'
import { CircularArcByCenterPointEditor } from './editors/CircularArcByCenterPointEditor.js'
import { CircularArcBy3PointsEditor } from './editors/CircularArcBy3PointsEditor.js'
import { CircularArcByBulgeEditor } from './editors/CircularArcByBulgeEditor.js'
import { ExtrudedShapeEditor } from './editors/ExtrudedShapeEditor.js'
import { ComplexPolygonEditor } from './editors/ComplexPolygonEditor.js'
import { ShapeListEditor } from './editors/ShapeListEditor.js'
export class ShapeEditor extends CompositeEditor {
  constructor() {
    super([])
    this.delegates = [
      new PointListEditor(),
      new PointEditor(),
      new BoundsEditor(),
      new CircleBy3PointsEditor(),
      new CircleByCenterPointEditor(),
      new EllipseEditor(),
      new CircularArcBy3PointsEditor(),
      new CircularArcByBulgeEditor(),
      new CircularArcByCenterPointEditor(),
      new ArcEditor(),
      new ArcBandEditor(),
      new SectorEditor(),
      new GeoBufferEditor(this),
      new ExtrudedShapeEditor(this),
      new ComplexPolygonEditor(this),
      new ShapeListEditor(this),
    ]
  }
}
