import {
  EVENT_HANDLED,
  EVENT_IGNORED,
  isHandled,
  isRequestDeactivation,
  isRequestFinish,
  REQUEST_FINISH,
} from '../../controller/HandleEventResult.js'
import { EditHandle } from '../EditHandle.js'
import { PointHandle } from './PointHandle.js'
import { squaredViewDistance } from './HandleUtil.js'
export class CompositeEditHandle extends EditHandle {
  constructor(e, t) {
    super()
    this._handles = e.slice()
    this._handleListeners = []
    for (const e of this._handles)
      this._handleListeners.push(
        e.on('EditShape', (e) => {
          this.eventedSupport.emit('EditShape', e)
        })
      )
    this._cascading = t?.cascading ?? false
    this._cascadingHandleIndex = 0
    this._activeHandleIndex = -1
  }
  get handles() {
    return this._handles
  }
  set handles(e) {
    for (const e of this._handleListeners) e.remove()
    this._handleListeners = []
    this._handles = e
    for (const e of this._handles) {
      const t = e.on('EditShape', (e) => {
        this.eventedSupport.emit('EditShape', e)
      })
      this._handleListeners.push(t)
    }
  }
  set activeHandleIndex(e) {
    this._activeHandleIndex = e
  }
  onDraw(e, t) {
    for (let n = this._handles.length - 1; n >= this._cascadingHandleIndex; n--)
      this._handles[n].onDraw(e, t)
  }
  onDrawLabel(e, t) {
    for (let n = this._handles.length - 1; n >= this._cascadingHandleIndex; n--)
      this._handles[n].onDrawLabel(e, t)
  }
  update() {
    for (let e = 0; e < this._handles.length; e += 1) this._handles[e].update()
  }
  onCreateContextMenu(e, t, n, s) {
    for (let i = this._cascadingHandleIndex; i < this._handles.length; i++)
      this._handles[i].onCreateContextMenu(e, t, n, s)
  }
  onGestureEvent(e, t) {
    let n = EVENT_IGNORED
    if (this._cascading) {
      n = this._handles[this._cascadingHandleIndex].onGestureEvent(e, t)
      if (isHandled(n) && isRequestFinish(n)) {
        this._cascadingHandleIndex++
        if (this._cascadingHandleIndex >= this._handles.length)
          return REQUEST_FINISH | EVENT_HANDLED
        return EVENT_HANDLED
      }
      return n
    }
    if (this._activeHandleIndex >= 0) {
      const s = undefined
      n = this._handles[this._activeHandleIndex].onGestureEvent(e, t)
      if (!isHandled(n) || isRequestDeactivation(n))
        this._activeHandleIndex = -1
    }
    if (!isHandled(n)) {
      const s = this.findInteractionHandles(e, t)
      for (let i = 0; i < s.length; i++) {
        n = s[i].onGestureEvent(e, t)
        if (isHandled(n)) {
          if (!isRequestDeactivation(n))
            this._activeHandleIndex = s[i].__lastEventHandleIndex
          break
        }
      }
    }
    return n
  }
  findInteractionHandles(e, t) {
    const n = []
    for (let s = 0; s < this._handles.length; s++) {
      const i = this._handles[s]
      i.__lastEventSquaredDistance = Number.MAX_VALUE
      i.__lastEventHandleIndex = s
      n.push(i)
      if (i instanceof PointHandle)
        if (i.interacts(e, t)) {
          const n = squaredViewDistance(t, i.getPoint(), e.viewPoint)
          i.__lastEventSquaredDistance = n
        }
    }
    n.sort(
      (e, t) => e.__lastEventSquaredDistance - t.__lastEventSquaredDistance
    )
    return n
  }
  getCursor(e, t) {
    if (this._cascading)
      return this._handles[this._cascadingHandleIndex].getCursor(e, t)
    if (this._activeHandleIndex > 0)
      return this._handles[this._activeHandleIndex].getCursor(e, t)
    const n = this.findInteractionHandles(e, t)
    for (let s = 0; s < n.length; s++) {
      const i = n[s].getCursor(e, t)
      if (i) return i
    }
    return null
  }
}
