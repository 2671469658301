import { GeodeticReference } from '../../../reference/GeodeticReference.js'
import { BoundsResizeHandleIdentifier } from './BoundsResizeHandleIdentifier.js'
export class BoundsCornerPointUtil {
  constructor() {}
  calculateNextCornerUsingDelta(e, i, n) {
    const d = calculateShortestDiff(this.getPoint(i, e), n, isBoundsGeodetic(i))
    let s =
      e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
      e === BoundsResizeHandleIdentifier.UPPER_LEFT
    let t =
      e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
      e === BoundsResizeHandleIdentifier.LOWER_RIGHT
    if (
      (e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
        e === BoundsResizeHandleIdentifier.UPPER_LEFT) &&
      i.width <= d.x
    )
      s = false
    else if (
      (e === BoundsResizeHandleIdentifier.LOWER_RIGHT ||
        e === BoundsResizeHandleIdentifier.UPPER_RIGHT) &&
      i.width <= -d.x
    )
      s = true
    if (
      (e === BoundsResizeHandleIdentifier.UPPER_LEFT ||
        e === BoundsResizeHandleIdentifier.UPPER_RIGHT) &&
      i.height <= -d.y
    )
      t = true
    else if (
      (e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
        e === BoundsResizeHandleIdentifier.LOWER_RIGHT) &&
      i.height <= d.y
    )
      t = false
    if (s && t) return BoundsResizeHandleIdentifier.LOWER_LEFT
    else if (s) return BoundsResizeHandleIdentifier.UPPER_LEFT
    else if (t) return BoundsResizeHandleIdentifier.LOWER_RIGHT
    else return BoundsResizeHandleIdentifier.UPPER_RIGHT
  }
  getPoint(e, i) {
    const n = e.focusPoint.copy()
    switch (i) {
      case BoundsResizeHandleIdentifier.LOWER_LEFT:
        n.move3D(e.x, e.y, 0)
        break
      case BoundsResizeHandleIdentifier.UPPER_RIGHT:
        n.move3D(e.x + e.width, e.y + e.height, 0)
        break
      case BoundsResizeHandleIdentifier.UPPER_LEFT:
        n.move3D(e.x, e.y + e.height, 0)
        break
      case BoundsResizeHandleIdentifier.LOWER_RIGHT:
        n.move3D(e.x + e.width, e.y, 0)
        break
      default:
        return null
    }
    return n
  }
  flippedLeftToRight(e, i) {
    return (
      (e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
        e === BoundsResizeHandleIdentifier.UPPER_LEFT) &&
      (i === BoundsResizeHandleIdentifier.LOWER_RIGHT ||
        i === BoundsResizeHandleIdentifier.UPPER_RIGHT)
    )
  }
  flippedRightToLeft(e, i) {
    return (
      (e === BoundsResizeHandleIdentifier.LOWER_RIGHT ||
        e === BoundsResizeHandleIdentifier.UPPER_RIGHT) &&
      (i === BoundsResizeHandleIdentifier.LOWER_LEFT ||
        i === BoundsResizeHandleIdentifier.UPPER_LEFT)
    )
  }
  flippedLowerToUpper(e, i) {
    return (
      (e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
        e === BoundsResizeHandleIdentifier.LOWER_RIGHT) &&
      (i === BoundsResizeHandleIdentifier.UPPER_LEFT ||
        i === BoundsResizeHandleIdentifier.UPPER_RIGHT)
    )
  }
  flippedUpperToLower(e, i) {
    return (
      (e === BoundsResizeHandleIdentifier.UPPER_LEFT ||
        e === BoundsResizeHandleIdentifier.UPPER_RIGHT) &&
      (i === BoundsResizeHandleIdentifier.LOWER_LEFT ||
        i === BoundsResizeHandleIdentifier.LOWER_RIGHT)
    )
  }
  flipVertically(e) {
    switch (e) {
      case BoundsResizeHandleIdentifier.LOWER_LEFT:
        return BoundsResizeHandleIdentifier.LOWER_RIGHT
      case BoundsResizeHandleIdentifier.LOWER_RIGHT:
        return BoundsResizeHandleIdentifier.LOWER_LEFT
      case BoundsResizeHandleIdentifier.UPPER_LEFT:
        return BoundsResizeHandleIdentifier.UPPER_RIGHT
      case BoundsResizeHandleIdentifier.UPPER_RIGHT:
        return BoundsResizeHandleIdentifier.UPPER_LEFT
      default:
        return null
    }
  }
  flipHorizontally(e) {
    switch (e) {
      case BoundsResizeHandleIdentifier.LOWER_LEFT:
        return BoundsResizeHandleIdentifier.UPPER_LEFT
      case BoundsResizeHandleIdentifier.LOWER_RIGHT:
        return BoundsResizeHandleIdentifier.UPPER_RIGHT
      case BoundsResizeHandleIdentifier.UPPER_LEFT:
        return BoundsResizeHandleIdentifier.LOWER_LEFT
      case BoundsResizeHandleIdentifier.UPPER_RIGHT:
        return BoundsResizeHandleIdentifier.LOWER_RIGHT
      default:
        return null
    }
  }
  handleCornerPointChange(e, i, n) {
    const d = this.calculateNextCornerUsingDelta(e, n, i)
    const s = this.getPoint(n, e)
    const t = n.focusPoint.copy()
    t.move2D(n.x, n.y)
    const R = n.width
    const r = n.height
    const o = calculateShortestDiff(s, i, isBoundsGeodetic(n))
    const l = t.copy()
    let f
    let a
    if (this.flippedLeftToRight(e, d)) {
      l.move3D(l.x + R, l.y, l.z)
      f = Math.abs(l.x - i.x)
    } else if (this.flippedRightToLeft(e, d)) {
      f = Math.abs(t.x - i.x)
      l.move3D(l.x - f, l.y, l.z)
    } else if (
      e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
      e === BoundsResizeHandleIdentifier.UPPER_LEFT
    ) {
      f = R - o.x
      l.move3D(l.x + o.x, l.y, l.z)
    } else f = R + o.x
    if (this.flippedLowerToUpper(e, d)) {
      l.move3D(l.x, l.y + r, l.z)
      a = Math.abs(l.y - i.y)
    } else if (this.flippedUpperToLower(e, d)) {
      a = Math.abs(t.y - i.y)
      l.move3D(l.x, l.y - a, l.z)
    } else if (
      e === BoundsResizeHandleIdentifier.LOWER_LEFT ||
      e === BoundsResizeHandleIdentifier.LOWER_RIGHT
    ) {
      a = r - o.y
      l.move3D(l.x, l.y + o.y, l.z)
    } else a = r + o.y
    n.setTo2D(l.x, f, l.y, a)
    return d
  }
}
function calculateShortestDiff(e, i, n) {
  let d
  if (n) {
    const n = e.x
    const s = i.x
    let t
    let R
    const r = s - n
    if (s > n) t = s - n - 360
    else t = s - n + 360
    if (Math.abs(r) < Math.abs(t)) R = r
    else R = t
    d = { x: R, y: i.y - e.y }
  } else d = { x: i.x - e.x, y: i.y - e.y }
  return d
}
function isBoundsGeodetic(e) {
  return e.reference instanceof GeodeticReference
}
