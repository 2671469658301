import { createPolyline } from '../../../../shape/ShapeFactory.js'
import { HelperHandle } from './HelperHandle.js'
export class EllipseAxisHelperHandle extends HelperHandle {
  constructor(e, l) {
    super(l)
    this._ellipse = e
  }
  get ellipse() {
    return this._ellipse
  }
  onDraw(e, l) {
    const t = this.getHelperStyle(l)
    if (t) {
      const s = createPolyline(this.ellipse.reference, [
        this.ellipse.interpolate(0),
        this.ellipse.center,
        this.ellipse.interpolate(0.5),
      ])
      const r = { ...t, drapeTarget: this.getDrapeTarget(l, s) }
      e.drawShape(s, r)
      const i = createPolyline(this.ellipse.reference, [
        this.ellipse.interpolate(0.25),
        this.ellipse.center,
        this.ellipse.interpolate(0.75),
      ])
      e.drawShape(i, r)
    }
  }
}
