import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Log } from '../../util/Log.js'
import { MapBorder } from '../MapBorder.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { CompositeDrawCommand } from '../style/CompositeDrawCommand.js'
import { createLabelContext } from './LabelDrawingContext.js'
const MAX_PROCESS_TIME_LIMIT = 16
function sortDrawCommandsOnZOrderComparator(t, e) {
  const a = t.zOrder - e.zOrder
  return 0 === a ? t.__paintIndex - e.__paintIndex || 0 : a
}
function unpackSingleDrawingCommandAndAddToArray(t, e) {
  if (e instanceof CompositeDrawCommand) {
    const a = e.getCommands()
    for (let e = 0; e < a.length; e++)
      unpackSingleDrawingCommandAndAddToArray(t, a[e])
  } else {
    e.__paintIndex = t.paintIndex
    t.paintIndex += 1
    t.result.push(e)
  }
}
function unpackCompositeDrawingCommands(t, e) {
  e.result.length = 0
  e.paintIndex = 0
  for (let a = 0; a < t.length; a += 1)
    unpackSingleDrawingCommandAndAddToArray(e, t[a])
}
function collectBodyDrawCommands(t, e, a) {
  if (a.flags === e.flags) {
    e.allComplete = e.initDrawCommand(a, e.state) && e.allComplete
    const n = a.getDrawCommandForPaintRepresentation(t)
    if (n) {
      e.bodyDrawCommands.push(n)
      e.allComplete = e.allComplete && n.isReady()
    }
  }
}
function collectBodyDrawCommandsAndRequestLabelPaint(t, e, a, n) {
  collectBodyDrawCommands(t, a, n)
  requestLabelPaint(e, a, n)
}
function requestLabelPaint(t, e, a) {
  if (a.flags === e.flags) {
    e.allComplete = e.initDrawCommand(a, e.state) && e.allComplete
    const n = a.getDrawCommandForPaintRepresentation(t)
    if (n) {
      n.requestToDrawLabel()
      e.allComplete = e.allComplete && n.isReady()
    }
  }
}
function setBorderState(t, e) {
  if (e === PaintRepresentation.BOTTOM_BORDER_BODY) t.border = MapBorder.BOTTOM
  else if (e === PaintRepresentation.LEFT_BORDER_BODY) t.border = MapBorder.LEFT
}
export class AbstractFeatureRenderer {
  get bodyDrawCommands() {
    return this._bodyDrawCommands
  }
  get state() {
    return this._state
  }
  get allComplete() {
    return this._allComplete
  }
  set allComplete(t) {
    this._allComplete = t
  }
  get flags() {
    return this._flags
  }
  get selected() {
    return this._selected
  }
  get labelContext() {
    return this._labelContext
  }
  constructor(t, e, a, n, i, s, r, o) {
    this._bodyFunc = n.getPaintFunction(s)
    this._labelFunc = n.getPaintFunction(r)
    this._bodyPaintRepresentation = s
    this._labelPaintRepresentation = r
    this._flags = o.flags
    this._selected = o.selected
    this._processed = 0
    this._timeLimit = 1 / 0
    this._stateObject = { selected: this._selected, level: 0, hovered: false }
    setBorderState(this._stateObject, this._bodyPaintRepresentation)
    this._worldBounds = null
    this._state = 0
    this._incremental = i
    this._collectBodyDrawCommands = collectBodyDrawCommands.bind(null, s)
    this._requestLabelPaint = requestLabelPaint.bind(null, r)
    this._collectBodyDrawCommandsAndRequestLabelPaint =
      collectBodyDrawCommandsAndRequestLabelPaint.bind(null, s, r)
    this._painter = n
    this._geoCanvas = e
    this._labelCanvas = a
    this._layer = t
    this._allComplete = true
    this._maxWorldMargin = 0
    this._maxPixelPadding = 0
    this._bodyDrawCommands = []
    this._unpackAccumulator = { paintIndex: 0, result: [] }
    this._tmpModelBounds = null
    if (!t.map)
      throw new ProgrammingError('AbstractFeatureLayer: layer.map should exist')
    const l = this._layer.getLabelManager(r)
    this._labelContext = createLabelContext(t.map, l, r)
    this._worldSizeSupport = t.map.worldSizeSupport
  }
  isReady() {
    return this._allComplete
  }
  setProcessingLimits() {
    this._timeLimit = this._incremental
      ? Date.now() + MAX_PROCESS_TIME_LIMIT
      : 1 / 0
    this._processed = 0
  }
  resetRendererState(t) {
    this._stateObject.level = t
    this._state = (this.selected ? 1 : 0) | (t << 1)
    this._worldBounds = this._layer.map.mapBounds
  }
  createDrawCommandForRenderNode(t, e) {
    if (!t.shape) return
    this._stateObject.hovered = false
    if (this._layer.hoverable)
      this._stateObject.hovered = this._layer.isHovered(t.feature)
    this._geoCanvas.reset()
    if (this._bodyFunc)
      this._bodyFunc.call(
        this._painter,
        this._geoCanvas,
        t.feature,
        t.shape,
        this._layer,
        this._layer.map,
        this._stateObject
      )
    const a = this._geoCanvas.buildDrawCommand()
    a.bindDomainShape(t.shape)
    const n =
      t.shape.bounds && this._layer.modelToWorldTransformation
        ? this._layer.modelToWorldTransformation.transformBounds(t.shape.bounds)
        : null
    a.bindWorldBounds(n)
    t.setDrawCommandForPaintRepresentation(this._bodyPaintRepresentation, a)
    this._labelCanvas.resetLabelContext(
      this._labelContext,
      a,
      this._stateObject.selected
    )
    if (this._labelFunc)
      try {
        this._labelFunc.call(
          this._painter,
          this._labelCanvas,
          t.feature,
          t.shape,
          this._layer,
          this._layer.map,
          this._stateObject
        )
      } catch (t) {
        Log.error(
          'Error during painting of label. ',
          t instanceof Error ? t : void 0
        )
      }
    const i = this._labelCanvas.getDrawCommand()
    const s = t.getDrawCommandForPaintRepresentation(
      this._labelPaintRepresentation
    )
    if (s) i.reuseStateFrom(s)
    i.setPickInfoContext(this._layer, t.feature)
    t.setDrawCommandForPaintRepresentation(this._labelPaintRepresentation, i)
    t.setStateForBodyPaintRepresentation(this._bodyPaintRepresentation, e)
    if (this._incremental && this._geoCanvas._didProcessing())
      this._processed += 1
  }
  initDrawCommand(t, e) {
    const a = t.getDrawCommandForPaintRepresentation(
      this._bodyPaintRepresentation
    )
    const n = t.getDrawCommandForPaintRepresentation(
      this._labelPaintRepresentation
    )
    let i =
      a &&
      t.getStateForBodyPaintRepresentation(this._bodyPaintRepresentation) === e
    if (i && n) i = n.canBeReused()
    if (i && n) {
      n.setPickInfoContext(this._layer, t.feature)
      return true
    }
    this.createDrawCommandForRenderNode(t, e)
    return false
  }
  renderFeaturesInBounds(t, e, a) {
    this.setProcessingLimits()
    this._techContext = t
    this.runActionForFeaturesInBounds(e, a)
  }
  getMapToViewTransformation() {
    return this._layer.getMapToViewTransformation(this._bodyPaintRepresentation)
  }
  _getAdjustedModelBounds(t) {
    if (this._bodyPaintRepresentation === PaintRepresentation.BODY) return t
    this._tmpModelBounds = this._tmpModelBounds || t.copy()
    if (
      this._bodyPaintRepresentation === PaintRepresentation.BOTTOM_BORDER_BODY
    )
      this._tmpModelBounds.setTo2D(t.x, t.width, -1 / 0, 1 / 0)
    else if (
      this._bodyPaintRepresentation === PaintRepresentation.LEFT_BORDER_BODY
    )
      this._tmpModelBounds.setTo2D(-1 / 0, 1 / 0, t.y, t.height)
    return this._tmpModelBounds
  }
  _paintBodies(t, e, a) {
    this._bodyDrawCommands.length = 0
    this.allComplete = true
    this.renderFeaturesInBounds(t, this._getAdjustedModelBounds(e), a)
    if (!this.allComplete && this._layer.map)
      this._layer.map.invalidateLayerPostRender(this._layer)
    unpackCompositeDrawingCommands(
      this._bodyDrawCommands,
      this._unpackAccumulator
    )
    const n = this._unpackAccumulator.result
    n.sort(sortDrawCommandsOnZOrderComparator)
    const i = this.getMapToViewTransformation()
    for (let t = 0; t < n.length; t += 1)
      n[t].draw(this._techContext, i, this._worldSizeSupport)
    this._computeMargins(n)
  }
  _computeMargins(t) {
    let e = 0
    let a = 0
    for (const n of t) {
      if (n.worldBounds && n.bounds)
        e = Math.max(n.getMaximumWorldMargin(this._worldSizeSupport) || 0, e)
      a = Math.max(n.getMaximumPadding(this._worldSizeSupport), a)
    }
    this._maxWorldMargin = e
    this._maxPixelPadding = a
  }
  getMaxWorldMargin() {
    return this._maxWorldMargin
  }
  getMaxPixelPadding() {
    return this._maxPixelPadding
  }
  paintBodies(t, e) {
    this._paintBodies(t, e, this._collectBodyDrawCommands)
  }
  paintBodiesAndLabels(t, e) {
    this._paintBodies(t, e, this._collectBodyDrawCommandsAndRequestLabelPaint)
  }
  paintLabels(t, e) {
    this.renderFeaturesInBounds(t, e, this._requestLabelPaint)
  }
  interacts(t, e, a) {
    const n = this._retrieveDrawCommandForInteractionCalculation(t, e)
    return n
      ? n.interacts(
          a,
          this.getMapToViewTransformation(),
          this._worldSizeSupport
        )
      : false
  }
  getMaximumInteractionZOrder(t, e, a) {
    const n = this._retrieveDrawCommandForInteractionCalculation(t, e)
    if (!n) return -1 / 0
    if (n instanceof CompositeDrawCommand) return n.zOrder
    unpackSingleDrawingCommandAndAddToArray(this._unpackAccumulator, n)
    let i = -1 / 0
    const s = this.getMapToViewTransformation()
    for (let t = 0; t < this._unpackAccumulator.result.length; t++)
      if (
        this._unpackAccumulator.result[t].interacts(
          a,
          s,
          this._worldSizeSupport
        )
      )
        i = Math.max(i, this._unpackAccumulator.result[t].zOrder)
    return i
  }
  _retrieveDrawCommandForInteractionCalculation(t, e) {
    this._stateObject.level = t
    if (e.flags !== this._flags) return null
    const a = (this.selected ? 1 : 0) | (t << 1)
    this.initDrawCommand(e, a)
    return e.getDrawCommandForPaintRepresentation(this._bodyPaintRepresentation)
  }
}
