import { PointDragHandle } from './PointDragHandle.js'
import { BoundsCornerPointUtil } from './BoundsCornerPointUtil.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { EventedSupport } from '../../../util/EventedSupport.js'
export class BoundsResizeHandle extends PointDragHandle {
  constructor(i, t, e) {
    super(
      () => this._cornerUtil.getPoint(this.bounds, this._identifier),
      (i, t, e) => {
        this.dragImpl(i, t, e)
      },
      { handleIconStyle: e }
    )
    this._bounds = i
    this._eventSupport = new EventedSupport(['Flipped'])
    this._identifier = t
    this._cornerUtil = new BoundsCornerPointUtil()
  }
  get bounds() {
    return this._bounds
  }
  get identifier() {
    return this._identifier
  }
  dragImpl(i, t, e) {
    const r = this._cornerUtil.handleCornerPointChange(
      this._identifier,
      i,
      this.bounds
    )
    let n = false
    let o = false
    if (
      this._cornerUtil.flippedLeftToRight(this._identifier, r) ||
      this._cornerUtil.flippedRightToLeft(this._identifier, r)
    )
      o = true
    if (
      this._cornerUtil.flippedUpperToLower(this._identifier, r) ||
      this._cornerUtil.flippedLowerToUpper(this._identifier, r)
    )
      n = true
    if (r !== this._identifier) this._eventSupport.emit('Flipped', n, o)
    this.emitEditShapeEvent(this.bounds, EditShapeStatus.IN_PROGRESS)
  }
  flip(i, t) {
    if (i)
      this._identifier = this._cornerUtil.flipHorizontally(this._identifier)
    if (t) this._identifier = this._cornerUtil.flipVertically(this._identifier)
  }
  on(i, t) {
    if ('Flipped' === i) return this._eventSupport.on(i, t)
    return super.on(i, t)
  }
}
