export class Grid {
  constructor() {
    this._x = 0
    this._y = 0
    this._width = 0
    this._height = 0
    this._level = -1
  }
  get level() {
    return this._level
  }
  set level(t) {
    this._level = t
  }
  get height() {
    return this._height
  }
  set height(t) {
    this._height = t
  }
  get width() {
    return this._width
  }
  set width(t) {
    this._width = t
  }
  get y() {
    return this._y
  }
  set y(t) {
    this._y = t
  }
  get x() {
    return this._x
  }
  set x(t) {
    this._x = t
  }
  setTo(t) {
    this._level = t._level
    this._x = t._x
    this._y = t._y
    this._width = t._width
    this._height = t._height
  }
  equals(t) {
    return (
      this._level == t._level &&
      this._x == t._x &&
      this._y == t._y &&
      this._width == t._width &&
      this._height == t._height
    )
  }
  contains(t) {
    const h = t.level
    const i = t.x
    const e = t.y
    return (
      this._level == h &&
      this._x <= i &&
      i < this._x + this._width &&
      this._y <= e &&
      e < this._y + this._height
    )
  }
  toString() {
    return `${this._level}.${this._x}.${this._width}.${this._y}.${this._height}`
  }
}
