import {
  createTransformation,
  isTransformationRequired,
} from './TransformationFactory.js'
export class CachingTransformation {
  constructor() {
    this._cachedTransformation = null
  }
  transformToSFCT(r, a, e) {
    let o
    const n = r.reference
    if (a.equals(n) || !isTransformationRequired(n, a)) e.move2D(r.x, r.y)
    else {
      o = this._cachedTransformation
      if (
        null === o ||
        !n.equals(o.inputReference) ||
        !a.equals(o.outputReference)
      ) {
        o = createTransformation(n, a)
        this._cachedTransformation = o
      }
      o.transform(r, e)
    }
  }
}
