import { Transformation } from './Transformation.js'
import { TransformationType } from './TransformationType.js'
export class IdentityTransformation extends Transformation {
  constructor(r, n) {
    super(r, n)
    this._inverseTransformation = null
  }
  _forward(r, n) {
    n.x = r.x
    n.y = r.y
    n.z = r.z
    return n
  }
  _inverse(r, n) {
    return this._forward(r, n)
  }
  _forwardBoundsCoords(r, n) {
    n.setTo3D(r.x, r.width, r.y, r.height, r.z, r.depth)
  }
  _inverseBoundsCoords(r, n) {
    n.setTo3D(r.x, r.width, r.y, r.height, r.z, r.depth)
  }
  _getType() {
    return TransformationType.TYPE_IDENTITY
  }
  getScale() {
    return { x: 1, y: 1, z: 1 }
  }
  get inverseTransformation() {
    if (!this._inverseTransformation)
      this._inverseTransformation = new IdentityTransformation(
        this._outputReference,
        this._inputReference
      )
    return this._inverseTransformation
  }
}
