import { isDefined, isNumber } from '../util/Lang.js'
export class AmbientOcclusionEffectImpl {
  constructor(e) {
    this.setToLiteral(e)
  }
  setToLiteral(e) {
    if (!isDefined(e)) return
    if (isDefined(e.radius)) this.radius = e.radius
    else this.radius = 30
    if (isDefined(e.power)) this.power = e.power
    else this.power = 1
  }
  get radius() {
    return this._radius
  }
  set radius(e) {
    if (e && isNumber(e) && e >= 0) this._radius = e
    else throw new Error(`Invalid ambient occlusion radius: ${e}`)
  }
  get power() {
    return this._power
  }
  set power(e) {
    if (isNumber(e) && e >= 0) this._power = e
    else throw new Error(`Invalid ambient occlusion power: ${e}`)
  }
}
