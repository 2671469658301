import { request } from './request.js'
import { mimeType } from './mimeType.js'
var every = Array.prototype.every
var map = Array.prototype.map
var filter = Array.prototype.filter
var xmlSerializer = new XMLSerializer()
var NodeType = { ELEMENT: 1, TEXT: 3, CDATA: 4 }
function stringToXml(e, t) {
  var r
  return new DOMParser().parseFromString(e, t || mimeType.xml)
}
function xmlToJson(e) {
  var t = null
  var r = {
    addChild: function (e) {
      t = e
    },
  }
  if (9 === e.nodeType) accumulateJson(e.documentElement, r)
  else accumulateJson(e, r)
  return t
}
function xmlStringToJson(e, t) {
  return xmlToJson(stringToXml(e, t))
}
function xmlFileToJson(e, t) {
  return request(e)
    .then((e) => e.text())
    .then(
      function (e) {
        return xmlStringToJson(e, t)
      },
      function (e) {
        throw e
      }
    )
}
function isTextOrCDataNode(e) {
  return e.nodeType === NodeType.TEXT || e.nodeType === NodeType.CDATA
}
function textContent(e) {
  return e.textContent || e.text
}
function parsePrimitive(e) {
  var t = getNumber(e)
  if (!isNaN(t)) return t
  if ('true' === e) return true
  else if ('false' === e) return false
  else return e
}
function getNumber(e) {
  var t = parseFloat(e)
  if (!isNaN(t) && isFinite(e)) return t
  else return Number.NaN
}
function JsonNode(e) {
  if (0 !== e.childNodes.length)
    if (every.call(e.childNodes, isTextOrCDataNode)) {
      this.$text = map.call(e.childNodes, textContent).join('')
      this.$value = parsePrimitive(this.$text)
    } else {
      this.$text = null
      this.$value = null
    }
  else {
    this.$text = textContent(e)
    this.$value = parsePrimitive(this.$text)
  }
  this.$name = e.localName ? e.localName : e.nodeName
  this.$prefix = e.prefix
  this.$attr = {}
  this.$attr_completename = {}
  this.$parent = null
  this.$chil = []
  this.$xml = xmlSerializer.serializeToString(e)
}
JsonNode.prototype = Object.create(Object.prototype)
JsonNode.prototype.constructor = JsonNode
JsonNode.prototype.addChild = function (e) {
  this.$chil.push(e)
  e.$parent = this
}
JsonNode.prototype.setAttribute = function (e, t) {
  this.$attr[e] = t
}
JsonNode.prototype.setAttributeCompleteName = function (e, t) {
  this.$attr_completename[e] = t
}
JsonNode.prototype.searchNodes = function (e) {
  function t(r, n) {
    if (e(n)) r.push(n)
    return n.$chil.reduce(t, r)
  }
  return this.$chil.reduce(t, [])
}
JsonNode.prototype.toObjectLiteral = function (e) {
  var t = {}
  var r
  var n
  for (n in this.$attr) if (this.$attr.hasOwnProperty(n)) t[n] = this.$attr[n]
  for (var i = 0; i < this.$chil.length; i++)
    if ('#text' !== (r = this.$chil[i]).$name)
      if ('undefined' === typeof r.$value || null === r.$value)
        t[r.$name] = r.toObjectLiteral(e)
      else if (!e) t[r.$name] = r.$value
      else t[r.$name] = e(r)
  return t
}
JsonNode.prototype.toComplexObjectLiteral = function () {
  var e
  var t = {}
  var r
  var n
  for (n in this.$attr) if (this.$attr.hasOwnProperty(n)) t[n] = this.$attr[n]
  for (var i = 0; i < this.$chil.length; i++)
    if ('#text' !== (r = this.$chil[i]).$name) {
      e = t[r.$name]
      if ('undefined' === typeof r.$value || null === r.$value)
        if (e)
          if (Array.isArray(e)) e.push(r.toComplexObjectLiteral())
          else t[r.$name] = [e, r.toComplexObjectLiteral()]
        else t[r.$name] = r.toComplexObjectLiteral()
      else if (e)
        if (Array.isArray(e)) e.push(r.$value)
        else t[r.$name] = [e, r.$value]
      else t[r.$name] = r.$value
    }
  return t
}
function makeMatchNamePredicate(e) {
  return function t(r) {
    return r.$name === e
  }
}
function accumulateJson(e, t) {
  var r, n, i, o, a
  if (
    e.nodeType !== NodeType.ELEMENT &&
    e.nodeType !== NodeType.TEXT &&
    e.nodeType !== NodeType.CDATA
  )
    return
  r = new JsonNode(e)
  t.addChild(r)
  if (e.attributes)
    for (o = 0, a = (n = e.attributes).length; o < a; o += 1) {
      i = n[o]
      r.setAttribute(i.localName, i.value)
      r.setAttributeCompleteName(i.name, i.value)
    }
  var l = e.firstChild
  while (l) {
    accumulateJson(l, r)
    l = l.nextSibling
  }
  r.$chil.forEach(function (e) {
    var t = e.$name
    var n = makeMatchNamePredicate(t)
    var i = t + 's'
    if ('undefined' === typeof r[i])
      Object.defineProperty(r, i, {
        get: function () {
          return filter.call(this.$chil, n)
        },
        enumerable: true,
      })
  })
}
function isTextNode(e) {
  return '#text' === e.$name
}
function isNotTextNode(e) {
  return '#text' !== e.$name
}
export var XML = {
  xmlToJson: xmlToJson,
  xmlFileToJson: xmlFileToJson,
  stringToXml: stringToXml,
  xmlStringToJson: xmlStringToJson,
  matchesName: function (e, t, r) {
    return (
      (e.namespaceURI &&
        e.localName === t &&
        0 === e.namespaceURI.indexOf(r)) ||
      e.nodeName === t
    )
  },
  getInvalidChildren: function (e, t) {
    var r,
      n,
      i,
      o,
      a = filter.call(e.$chil, isNotTextNode),
      l = []
    for (r = 0; r < a.length; r++) {
      o = false
      i = a[r].$name
      for (n = 0; !o && n < t.length; n++) o = i === t[n] || 'parsererror' === i
      if (!o) l.push(a[r])
    }
    return l
  },
  isTextNode: isTextNode,
  isNotTextNode: isNotTextNode,
  parsePrimitive: parsePrimitive,
  isTextDOM: function (e) {
    return '#text' === e.nodeName
  },
  parseNameAndTextValue: function (e, t) {
    var r, n
    if (0 !== e.childNodes.length)
      if (every.call(e.childNodes, isTextOrCDataNode))
        r = parsePrimitive((n = map.call(e.childNodes, textContent).join('')))
      else {
        n = null
        r = null
      }
    else r = parsePrimitive((n = textContent(e)))
    t.name = e.localName ? e.localName : e.nodeName
    t.value = r
  },
  reduceChildren: function e(t, r, n) {
    var i = t.firstChild
    while (i) {
      n = r(n, i)
      i = i.nextSibling
    }
    return n
  },
  removeWhitespaces: function (e) {
    return (e = (e = e.replace(/>\s*/g, '>')).replace(/\s*</g, '<'))
  },
}
