import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { StyleUtil } from '../../view/style/StyleUtil.js'
import { createCircle } from '../IconFactory.js'
import { isPromise } from '../PromiseUtil.js'
import { resolveIconStyle } from '../../view/style/complexstroke/photon/PhotonStateResolver.js'
var mapping2vectorSize = new Map([
  [1, Photon.ValueType.Float],
  [2, Photon.ValueType.Vec2],
  [3, Photon.ValueType.Vec3],
  [4, Photon.ValueType.Vec4],
])
function getVectorSizeValue(e) {
  e = +e || 1
  if (!mapping2vectorSize.has(e))
    throw new ProgrammingError(
      'The Attribute expression requires a correct vector size parameter'
    )
  return mapping2vectorSize.get(e)
}
export var ExpressionResolver = {
  resolve: function (e, r, o, n, t) {
    return e.resolve(this, r, o, n, t)
  },
  attribute: function (e, r) {
    var o = getVectorSizeValue(r)
    return Photon.Expression.attribute(e, o)
  },
  positionAttribute: function (e) {
    return Photon.Expression.nullaryFunction(
      e ? Photon.FunctionType.RelativePosition : Photon.FunctionType.Position
    )
  },
  booleanParameter: function (e, r) {
    return Photon.Expression.booleanParameter(e, r)
  },
  numberParameter: function (e, r) {
    return Photon.Expression.floatParameter(e, r)
  },
  pointParameter: function (e, r) {
    return Photon.Expression.pointParameter(e, r)
  },
  colorParameter: function (e, r) {
    return Photon.Expression.colorParameter(e, r)
  },
  booleanConstant: function (e) {
    return Photon.Expression.booleanConstant(e)
  },
  numberConstant: function (e) {
    return Photon.Expression.floatConstant(e)
  },
  pointConstant: function (e) {
    return Photon.Expression.pointConstant(e)
  },
  colorConstant: function (e) {
    return Photon.Expression.colorConstant(e)
  },
  orientedBox: function (e, r, o) {
    var n = Photon.Expression.obb(
      e,
      r,
      o[0],
      o[1],
      o[2],
      o[3],
      o[4],
      o[5],
      o[6],
      o[7]
    )
    e.release()
    r.release()
    o[0].release()
    o[1].release()
    o[2].release()
    o[3].release()
    o[4].release()
    o[5].release()
    o[6].release()
    o[7].release()
    return n
  },
  nullaryFunction: function (e) {
    var r
    return Photon.Expression.nullaryFunction(e)
  },
  unaryFunction: function (e, r) {
    var o = Photon.Expression.unaryFunction(e, r)
    r.release()
    return o
  },
  binaryFunction: function (e, r, o) {
    var n = Photon.Expression.binaryFunction(e, r, o)
    r.release()
    o.release()
    return n
  },
  ternaryFunction: function (e, r, o, n) {
    var t = Photon.Expression.ternaryFunction(e, r, o, n)
    r.release()
    o.release()
    n.release()
    return t
  },
  ifThenElse: function (e, r, o) {
    var n = Photon.Expression.ifThenElse(e, r, o)
    e.release()
    r.release()
    o.release()
    return n
  },
  cases: function (e) {
    var r = Photon.Expression.cases(e)
    e.release()
    return r
  },
  map: function (e, r, o) {
    var n = Photon.Expression.map(e, o)
    for (var t = 0; t < r.length; t++) {
      var i = r[t]
      n.map(t, i)
      i.release()
    }
    e.release()
    o.release()
    return n
  },
  icon: function (e, r, o, n, t) {
    var i = resolveIconStyle(r, o, StyleUtil.normalizeIconStyle(e), false)
    if (isPromise(i)) {
      i.then(
        function (e) {
          var r = e.photonImage
          if (r) {
            n()
            r.release()
          }
        },
        function (e) {
          e = 'string' === typeof e ? new ProgrammingError(e) : e
          if ('function' === typeof t) return t(e)
          throw e
        }
      )
      i = resolveIconStyle(
        r,
        o,
        StyleUtil.normalizeIconStyle(
          this.createDefaultParameterizedPointStyle()
        ),
        false
      )
    }
    var a = i.photonImage
    var s = Photon.Expression.icon(
      a,
      i.offsetX,
      i.offsetY,
      i.width,
      i.height,
      i.anchorX,
      i.anchorY,
      i.rotation,
      Math.max(0, Math.max(i.anchorX, i.width - i.anchorX)),
      Math.max(0, i.height - i.anchorY)
    )
    a.release()
    return s
  },
  createDefaultParameterizedPointStyle: function () {
    if (!this._defaultStyle) {
      var e = 16
      this._defaultStyle = {
        image: createCircle({
          fill: 'rgba(255,255,255,1)',
          width: e,
          height: e,
        }),
        width: e + 'px',
        height: e + 'px',
      }
    }
    return this._defaultStyle
  },
  isInside: function (e) {
    var r = Photon.Expression.isInside(e)
    e.release()
    return r
  },
  pushDown: function (e) {
    var r = Photon.Expression.pushDown(e)
    e.release()
    return r
  },
}
