import { isArray } from '../../util/Lang.js'
import { handleResponseAs, request } from '../../util/request.js'
import { GeoJsonCodec } from '../codec/GeoJsonCodec.js'
import { Log } from '../../util/Log.js'
import { GMLCodec } from '../codec/GMLCodec.js'
function decodeServerResponse(e, t, o, r) {
  const n = t.headers.get('Content-Type') || void 0
  return handleResponseAs(t, r).then((t) =>
    e.decode({ content: t, contentType: n, reference: o })
  )
}
const TYPE_GML = 'gml'
const TYPE_GEO_JSON = 'json'
const FORMATS = {
  [TYPE_GEO_JSON]: { regex: [RegExp('json', 'i')] },
  [TYPE_GML]: { regex: [RegExp('gml', 'i'), RegExp('text/xml', 'i')] },
}
function hasFormatType(e, t) {
  return t.some((t) => e.some((e) => e.test(t)))
}
function getOutputType(e) {
  if (e && e.length) {
    if (hasFormatType(FORMATS[TYPE_GEO_JSON].regex, e)) return TYPE_GEO_JSON
    if (hasFormatType(FORMATS[TYPE_GML].regex, e)) return TYPE_GML
  }
  return TYPE_GEO_JSON
}
export function getBestOutputFormat(e) {
  const t = undefined
  if (getOutputType(e) === TYPE_GML) return 'application/gml+xml; version=3.2'
  return 'application/json'
}
export function getAutoDetectedCodec(e) {
  const t = undefined
  if (getOutputType(e ? [e] : []) === TYPE_GML) return new GMLCodec()
  return new GeoJsonCodec()
}
export function mergeRequestMethods(e, t) {
  if (!t || !isArray(t) || !t.length) return e
  const o = e.filter((e) => t.indexOf(e) > -1)
  if (0 === o.length) {
    Log.warn('WFS service does not support request methods: ' + t.join(', '))
    return e
  }
  return o
}
export function performRequestAndConvertToCursorPromise(e, t, o, r, n) {
  const s = request(e, t)
  return Promise.resolve(s).then((e) => decodeServerResponse(o, e, r, n))
}
