import { Feature } from '../feature/Feature.js'
export class KMLFeature extends Feature {
  constructor(e, r, t, s) {
    super(e, r, t)
    this._styles = s.styles
    this._zOrder = s.zOrder
    this._randomColors = {}
  }
  get zOrder() {
    return this._zOrder
  }
  set zOrder(e) {
    this._zOrder = e
  }
  get styles() {
    return this._styles
  }
  get shape() {
    return super.shape
  }
  get properties() {
    return super.properties
  }
  get randomColors() {
    return this._randomColors
  }
  get options() {
    return {
      zOrder: this.zOrder,
      styles: {
        highlight: this.styles.highlight.copy(),
        normal: this.styles.normal.copy(),
      },
    }
  }
  getStyle() {
    let e
    return (
      arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : false
    )
      ? this._styles.highlight
      : this._styles.normal
  }
}
export function isKMLFeature(e) {
  return e instanceof KMLFeature
}
