import { Lang } from '../util/Lang.js'
import { XYZBounds } from '../shape/XYZBounds.js'
var isDefined = Lang.isDefined
function fixBounds(i) {
  if (!isDefined(i.x) || isNaN(i.x)) i.x = -1
  if (!isDefined(i.y) || isNaN(i.y)) i.y = -1
  if (!isDefined(i.width) || isNaN(i.width)) i.width = 0
  if (!isDefined(i.height) || isNaN(i.height)) i.height = -1
}
function PickRequest(i, e, t, s) {
  this.viewBounds = i
  this.firstOnly = e
  this.intersectGeometry = t
  this._viewToMapTransformation = s
  fixBounds(this.viewBounds)
}
PickRequest.prototype = Object.create(Object.prototype)
PickRequest.prototype.constructor = PickRequest
PickRequest.prototype.getWorldBounds = function () {
  if (!this._worldBounds)
    if (this.viewBounds.width > 0 || this.viewBounds.height > 0)
      this._worldBounds = this._viewToMapTransformation.transformBounds(
        this.viewBounds
      )
    else {
      var i = this._viewToMapTransformation.transform(
        this.viewBounds.focusPoint
      )
      this._worldBounds = new XYZBounds(i.reference, [i.x, 0, i.y, 0])
    }
  fixBounds(this._worldBounds)
  return this._worldBounds
}
PickRequest.fromViewBounds = function (i, e, t, s) {
  return new PickRequest(i, e, t, s)
}
PickRequest.fromViewPoint = function (i, e, t, s, o, n, r) {
  var u = Math.max(1, t)
  var d = Math.max(1, s)
  return new PickRequest(
    new XYZBounds(null, [
      i + 0.5 - (u = u % 2 === 0 ? u + 1 : u) / 2,
      u,
      e + 0.5 - (d = d % 2 === 0 ? d + 1 : d) / 2,
      d,
    ]),
    o,
    n,
    r
  )
}
PickRequest.fromWorldBounds = function (i, e, t, s) {
  var o
  if (i.width > 0 || i.height > 0) o = s.transformBounds(i)
  else {
    var n = s.transform(i.focusPoint)
    o = new XYZBounds(null, [n.x, 0, n.y, 0])
  }
  var r = new PickRequest(o, e, t, s)
  r._worldBounds = i
  return r
}
export { PickRequest }
