function strictEquals(t, i) {
  return t === i
}
export class LinkedList {
  constructor(t) {
    this.head = null
    this.tail = null
    this.equalsFunc = t || strictEquals
    this._size = 0
  }
  get size() {
    return this._size
  }
  createNode(t) {
    return { next: null, previous: null, value: t }
  }
  push(t) {
    const i = this.createNode(t)
    if (!this.tail) this.head = this.tail = i
    else {
      i.previous = this.tail
      this.tail.next = i
      this.tail = i
    }
    this._size += 1
  }
  pop() {
    const t = this.tail
    if (null == t) return null
    else {
      this._size -= 1
      this.tail = t.previous
      if (!this.tail) this.head = null
      else this.tail.next = null
      return t.value
    }
  }
  unshift(t) {
    const i = this.createNode(t)
    if (!this.head) this.head = this.tail = i
    else {
      i.next = this.head
      this.head.previous = i
      this.head = i
    }
    this._size += 1
  }
  shift() {
    const t = this.head
    if (null == t) return null
    else {
      this.head = t.next
      this._size -= 1
      if (!this.head) this.tail = null
      else this.head.previous = null
      return t.value
    }
  }
  remove(t) {
    const i = this.find(t)
    if (!i) return null
    if (i.previous) i.previous.next = i.next
    if (i.next) i.next.previous = i.previous
    if (i === this.head) this.head = i.next
    if (i === this.tail) this.tail = i.previous
    this._size -= 1
    return i.value
  }
  forEach(t, i) {
    let e = this.head
    while (e) {
      t.call(i, e.value)
      e = e.next
    }
  }
  contains(t) {
    return null !== this.find(t)
  }
  empty() {
    this.head = null
    this.tail = null
    this._size = 0
  }
  get isEmpty() {
    return null === this.head
  }
  find(t) {
    let i = this.head
    while (i && !this.equalsFunc(i.value, t)) i = i.next
    return i
  }
}
