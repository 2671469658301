import { REQUEST_FINISH } from '../../controller/HandleEventResult.js'
import { GestureEventType } from '../../input/GestureEventType.js'
import { PointDragHandle } from './PointDragHandle.js'
function isSingleTap(e) {
  return GestureEventType.SINGLE_CLICK_CONFIRMED === e.type
}
export class PointCreateHandle extends PointDragHandle {
  constructor(e, t) {
    super(
      () => e,
      (e) => {
        t(e)
      },
      { handleIconStyle: null }
    )
  }
  getCursor(e, t) {
    return 'crosshair'
  }
  deactivate(e, t) {
    return super.deactivate(e, t) | REQUEST_FINISH
  }
  shouldActivate(e, t) {
    return isSingleTap(e)
  }
  shouldProcess(e) {
    return isSingleTap(e)
  }
  shouldDeactivate(e) {
    return isSingleTap(e)
  }
}
