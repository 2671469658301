export let SEElementType = (function (E) {
  E[(E['FEATURE_TYPE_STYLE'] = 0)] = 'FEATURE_TYPE_STYLE'
  E[(E['RULE'] = 1)] = 'RULE'
  E[(E['POINT_SYMBOLIZER'] = 2)] = 'POINT_SYMBOLIZER'
  E[(E['LINE_SYMBOLIZER'] = 3)] = 'LINE_SYMBOLIZER'
  E[(E['POLYGON_SYMBOLIZER'] = 4)] = 'POLYGON_SYMBOLIZER'
  E[(E['TEXT_SYMBOLIZER'] = 5)] = 'TEXT_SYMBOLIZER'
  E[(E['RASTER_SYMBOLIZER'] = 6)] = 'RASTER_SYMBOLIZER'
  E[(E['GEOMETRY'] = 7)] = 'GEOMETRY'
  E[(E['STROKE'] = 8)] = 'STROKE'
  E[(E['FILL'] = 9)] = 'FILL'
  E[(E['GRAPHIC'] = 10)] = 'GRAPHIC'
  E[(E['GRAPHIC_FILL'] = 11)] = 'GRAPHIC_FILL'
  E[(E['GRAPHIC_STROKE'] = 12)] = 'GRAPHIC_STROKE'
  E[(E['EXTERNAL_GRAPHIC'] = 13)] = 'EXTERNAL_GRAPHIC'
  E[(E['FONT'] = 14)] = 'FONT'
  E[(E['LABEL'] = 15)] = 'LABEL'
  E[(E['LABEL_PLACEMENT'] = 16)] = 'LABEL_PLACEMENT'
  E[(E['POINT_PLACEMENT'] = 17)] = 'POINT_PLACEMENT'
  E[(E['LINE_PLACEMENT'] = 18)] = 'LINE_PLACEMENT'
  E[(E['MARK'] = 19)] = 'MARK'
  E[(E['HALO'] = 20)] = 'HALO'
  E[(E['COLOR_REPLACEMENT'] = 21)] = 'COLOR_REPLACEMENT'
  E[(E['PARAMETER'] = 22)] = 'PARAMETER'
  E[(E['SVG_PARAMETER'] = 23)] = 'SVG_PARAMETER'
  E[(E['INLINE_CONTENT'] = 24)] = 'INLINE_CONTENT'
  E[(E['XPATH'] = 25)] = 'XPATH'
  E[(E['FUNCTION'] = 26)] = 'FUNCTION'
  E[(E['DESCRIPTION'] = 27)] = 'DESCRIPTION'
  E[(E['DISPLACEMENT'] = 28)] = 'DISPLACEMENT'
  E[(E['ANCHOR_POINT'] = 29)] = 'ANCHOR_POINT'
  E[(E['VENDOR_OPTION'] = 30)] = 'VENDOR_OPTION'
  return E
})({})
