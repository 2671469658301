import { ProgrammingError } from '../../error/ProgrammingError.js'
import { and, bbox } from '../../ogc/filter/FilterFactory.js'
import {
  getReference,
  isValidReferenceIdentifier,
} from '../../reference/ReferenceProvider.js'
import { isBounds } from '../../shape/Bounds.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import {
  isArray,
  isDefined,
  isFunction,
  isString,
  isUndefined,
} from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { WithHttpRequestOptions } from '../../util/WithHttpRequestOptions.js'
import { CapabilitiesParserUtil } from '../capabilities/common/CapabilitiesParserUtil.js'
import { WFSCapabilities } from '../capabilities/WFSCapabilities.js'
import {
  getAutoDetectedCodec,
  getBestOutputFormat,
  mergeRequestMethods,
  performRequestAndConvertToCursorPromise,
} from './StoreUtil.js'
import { WFSCodec } from './WFSCodec.js'
import { WFSGetFeatureRequestParameterBuilder } from './WFSGetFeatureRequestParameterBuilder.js'
import { URL } from '../../util/URL.js'
import { createTransformation } from '../../transformation/TransformationFactory.js'
export let SortOrder = (function (e) {
  e[(e['Ascending'] = 0)] = 'Ascending'
  e[(e['Descending'] = 1)] = 'Descending'
  return e
})({})
export class WFSFeatureStore {
  constructor(e) {
    this._eventSupport = new EventedSupport()
    if (!(e = e || {}).serviceURL && !e.postServiceURL)
      throw new ProgrammingError(
        'WFSFeatureStore: options.serviceURL is a required parameter'
      )
    if (e.typeNames)
      throw new ProgrammingError(
        'WFSFeatureStore: the typeNames property is replaced by the typeName property.'
      )
    if (!e.typeName)
      throw new ProgrammingError(
        'WFSFeatureStore: the typeName property must be specified ' +
          'and the value must be a string or a QName.'
      )
    if (isArray(e.typeName))
      throw new ProgrammingError(
        'WFSFeatureStore: the typeName property only supports one ' +
          'typeName. Replace the array by a string or a QName.'
      )
    if (!e.reference)
      throw new ProgrammingError(
        'WFSFeatureStore: options.reference is a required parameter'
      )
    if (e.codec && !isFunction(e.codec.decode))
      throw new ProgrammingError(
        'WFSFeatureStore: the codec must support the decode method'
      )
    this._swapAxes = isArray(e.swapAxes) ? e.swapAxes : []
    this._geometryName = isString(e.geometryName) ? e.geometryName : void 0
    this._requestBuilder = new WFSGetFeatureRequestParameterBuilder(
      e.serviceURL,
      e.postServiceURL
    )
    if (isArray(e.versions)) this._requestBuilder.supportedVersions = e.versions
    if (isArray(e.methods)) this._requestBuilder.supportedMethods = e.methods
    this._typeName = [e.typeName]
    this._outputFormat = e.outputFormat
    const t = e.codec || getAutoDetectedCodec(this._outputFormat)
    this._codec = new WFSCodec(t)
    this._reference = e.reference
    if (isDefined(e.withCredentials)) {
      Log.deprecated('options.withCredentials', 'options.credentials')
      if (isUndefined(e.credentials)) e.credentials = e.withCredentials
    }
    if (e.bounds && isBounds(e.bounds)) this._bounds = e.bounds
    this._httpSupport = new WithHttpRequestOptions(e)
    this._requestParameters = e.requestParameters || null
  }
  get credentials() {
    return this._httpSupport.credentials
  }
  set credentials(e) {
    this._httpSupport.credentials = e
  }
  get requestHeaders() {
    return this._httpSupport.requestHeaders
  }
  set requestHeaders(e) {
    this._httpSupport.requestHeaders = e
  }
  get requestParameters() {
    return this._requestParameters
  }
  set requestParameters(e) {
    this._requestParameters = e
  }
  get bounds() {
    return this._bounds
  }
  static createFromCapabilities(e, t) {
    let r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {}
    const s = e.featureTypes.filter((e) => e.name === t)[0]
    if (!isDefined(s))
      throw new ProgrammingError(
        `there is no feature type "${t}" in capabilities`
      )
    const i = r.reference || getReferenceFromCapabilities(s)
    const o = e.operations.filter((e) => 'GetFeature' === e.name)[0]
      .supportedRequests
    let n = ''
    let a = ''
    const u = []
    o.forEach((e) => {
      const t = CapabilitiesParserUtil.processServiceUrl(e.url)
      if ('GET' === e.method) n = t
      else if ('POST' === e.method) a = t
      u.push(e.method)
    })
    if (isString(r.serviceURL)) {
      n = r.serviceURL
      a = r.serviceURL
    } else if (r.serviceURL) {
      if (isString(r.serviceURL.GET)) n = r.serviceURL.GET
      if (isString(r.serviceURL.POST)) a = r.serviceURL.POST
    }
    const p = r.outputFormat || getBestOutputFormat(s.outputFormats)
    const d = {
      typeName: s.qName || s.name,
      serviceURL: n,
      postServiceURL: a,
      reference: i,
      codec: r.codec,
      outputFormat: p,
      versions: r.versions || [e.version],
      methods: mergeRequestMethods(u, r.methods),
      credentials: r.credentials,
      requestHeaders: r.requestHeaders,
      swapAxes: r.swapAxes,
      geometryName: r.geometryName,
      requestParameters: r.requestParameters,
      bounds: getModelBoundsFromCapabilities(s, i),
    }
    return new WFSFeatureStore(d)
  }
  static createFromURL(e, t, r) {
    return WFSCapabilities.fromURL(e, r).then((e) =>
      WFSFeatureStore.createFromCapabilities(e, t, r)
    )
  }
  query(e, t) {
    let r
    if (isArray((e = e || {}).filters)) {
      r = e.filters.slice(0, 1)
      Log.deprecated('filters', 'filter')
    } else r = e.filter ? [e.filter] : []
    if (isDefined(e.typeNames))
      Log.warn(
        "WFSFeatureStore: the typeNames are passed in the constructor and shouldn't be " +
          'passed as option to the query method. Consult the V2013.0 release notes for ' +
          'more information.'
      )
    if (isDefined(e.reverseAxes))
      Log.warn(
        'WFSFeatureStore: the reverseAxes property is no longer supported by the query ' +
          'method. This option can be specified on the GeoJsonCodec which you can pass in ' +
          'the constructor. Consult the V2013.0 release notes for more information.'
      )
    if (isDefined(e.outputFormat))
      Log.warn(
        "WFSFeatureStore: the outputFormat is passed in the constructor and shouldn't be " +
          'passed as option to the query method. Consult the V2013.0 release notes for ' +
          'more information.'
      )
    const s = this._requestBuilder.build(
      this._typeName,
      r,
      this._outputFormat,
      e.maxFeatures,
      e.propertyNames,
      e.sortBy
    )
    const i = this.addHttpRequestOptions({
      signal: t?.abortSignal,
      ...s.requestOptions,
    })
    const o = this._requestParameters ? this.addURLParameters(s.url) : s.url
    return performRequestAndConvertToCursorPromise(
      o,
      i,
      this._codec,
      this._reference
    )
  }
  addURLParameters(e) {
    return (e +=
      (e.includes('?') ? '&' : '?') +
      URL.buildQueryString(this._requestParameters))
  }
  spatialQuery(e, t, r) {
    t = { ...t }
    if (e) {
      const r = bbox(e, this._geometryName)
      r.swapAxes = this._swapAxes
      t.filter = t.filter ? and(r, t.filter) : r
    }
    return this.query(t, r)
  }
  getReference() {
    return this._reference
  }
  checkFeatureModelReference(e) {
    if (!this._reference.equals(e))
      Log.warn(
        'The reference of the FeatureModel does not match the reference of the WFSFeatureStore.'
      )
    if (this._reference.identifier !== e.identifier)
      Log.warn(
        'The identifier of the reference the FeatureModel does not match the identifier of the reference of the WFSFeatureStore.'
      )
  }
  on(e, t, r) {
    return this._eventSupport.on(e, t, r)
  }
  addHttpRequestOptions(e) {
    return this._httpSupport.addHttpRequestOptions(e)
  }
}
function getReferenceFromCapabilities(e) {
  if (isReferenceValid(e.defaultReference))
    return getReference(e.defaultReference)
  for (let t = 0; t < e.otherReferences.length; t++)
    if (isReferenceValid(e.otherReferences[t]))
      return getReference(e.otherReferences[t])
  throw new ProgrammingError(
    'WFSFeatureStore: cannot parse reference from capabilities'
  )
}
function isReferenceValid(e) {
  if (!isValidReferenceIdentifier(e)) {
    Log.warn(
      `Reference ${e} in GetCapabilities response is not known by the ReferenceProvider. Consider adding it.`
    )
    return false
  }
  return true
}
function getModelBoundsFromCapabilities(e, t) {
  const r = e.getWGS84Bounds()[0]
  if (r?.reference) {
    const { width: e, height: s } = r
    if (0 === e || 0 === s)
      Log.warn(
        `Bounds in GetCapabilities response have the width or height of the value 0 (${e} x ${s})`
      )
    return createTransformation(r.reference, t).transformBounds(r)
  }
}
