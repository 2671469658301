import { XYZBounds } from '../../shape/XYZBounds.js'
import { HTML5DrawCommandProto } from './HTML5DrawCommandProto.js'
import { squaredDistance2D } from '../../util/Cartesian.js'
import { createPoint, createPolyline } from '../../shape/ShapeFactory.js'
const EPSILON = 1e-50
const tmpVC = createPoint(null, [0, 0])
export class HTML5DrawArrowMarkerCommand extends HTML5DrawCommandProto {
  constructor(t, e, r, o, s, i, a, n) {
    const l = createPoint(t, [e, r])
    const h = createPoint(t, [o, s])
    super(t, createPolyline(t, [l, h]), n)
    this._from = l
    this._to = h
    this._strokeStyle = i
    this._strokeStyle.color = this._strokeStyle.color || 'rgb(0,0,255)'
    this._strokeStyle.width = this._strokeStyle.width || 2
    this._arrowSize = a
    this._arrowLineWidth = this._strokeStyle.width
    const c = new XYZBounds(t)
    c.setTo2D(e, r, 0, 0)
    c.setToIncludePoint2D(this._to)
    this.bounds = c
    this._rescaledSizes = { forMapScale: 0, arrowLineWidth: 0, arrowSize: 0 }
  }
  draw(t, e, r) {
    if (
      squaredDistance2D(this._from.x, this._from.y, this._to.x, this._to.y) <
      EPSILON
    )
      return
    e._forward(this._to, tmpVC)
    const o = tmpVC.x
    const s = tmpVC.y
    e._forward(this._from, tmpVC)
    let i = o - tmpVC.x
    let a = s - tmpVC.y
    const n = i * i + a * a
    if (n > EPSILON) {
      const e = 1 / Math.sqrt(n)
      i *= e
      a *= e
      const r = this.getPixelSizes()
      const l = r.arrowLineWidth
      const h = r.arrowSize
      const c = h >> 1
      t.beginPath()
      t.moveTo(-i * h - a * c + o, -a * h + i * c + s)
      t.lineTo(o, s)
      t.lineTo(-i * h + a * c + o, -a * h - i * c + s)
      t.lineWidth = l
      t.strokeStyle = this._strokeStyle.color
      t.stroke()
    }
  }
  getPixelSizes() {
    if (this._strokeStyle.isWorldSize) {
      this._rescaleSizes()
      return this._rescaledSizes
    }
    return {
      forMapScale: 0,
      arrowLineWidth: this._arrowLineWidth,
      arrowSize: this._arrowSize,
    }
  }
  _rescaleSizes() {
    if (
      this._strokeStyle.worldSizeSupport.getCurrentScale() !==
      this._rescaledSizes.forMapScale
    )
      this._rescaledSizes =
        this._strokeStyle.worldSizeSupport.getLineMarkerStyleRescaledSizes(
          this._strokeStyle.uom,
          this._strokeStyle.width,
          this._arrowSize
        )
  }
  interacts() {
    return false
  }
  strokeInteracts() {
    return false
  }
  findDrawCommand(t) {
    return null
  }
  isEquivalent(t) {
    return false
  }
  bindWorldBounds(t) {
    this.worldBounds = t
  }
  mapAnchorPointSFCT(t, e) {
    return false
  }
  mapAnchorPointsSFCT(t, e, r) {}
  getMapDistanceSquared() {
    return 1 / 0
  }
}
