import { createPolyline } from '../../../../shape/ShapeFactory.js'
import { ReferenceType } from '../../../../reference/ReferenceType.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
import { HelperHandle } from './HelperHandle.js'
function is3DMap(e) {
  return e.map.reference.referenceType === ReferenceType.GEOCENTRIC
}
export class ExtrudedShapeHelperHandle extends HelperHandle {
  constructor(e, r) {
    super(r)
    this._extrudedShape = e
  }
  get extrudedShape() {
    return this._extrudedShape
  }
  onDraw(e, r) {
    if (is3DMap(r)) {
      const t = this.getHelperStyle(r)
      if (t) {
        const a = this.extrudedShape.baseShape
        const p = { ...t, drapeTarget: this.getDrapeTarget(r, a) }
        e.drawShape(a, p)
        const s = a.focusPoint
        const n = s.copy()
        n.translate3D(0, 0, this.extrudedShape.maximumHeight)
        const o = createPolyline(this.extrudedShape.reference, [s, n])
        const c = { ...t, drapeTarget: DrapeTarget.NOT_DRAPED }
        e.drawShape(o, c)
      }
    }
  }
}
