import { WFSVersions } from '../../ogc/WFSVersions.js'
import { WFSRequestBuilderUtil } from './WFSRequestBuilderUtil.js'
import { isArray, isNumber, isString } from '../../util/Lang.js'
const WFS_VERSIONS = [
  WFSVersions.V110,
  WFSVersions.V100,
  WFSVersions.V202,
  WFSVersions.V200,
]
export class WFSGetFeatureRequestParameterBuilder {
  constructor(e, s) {
    this._serviceURL = e
    this._postServiceURL = s || this._serviceURL
    this._supportedMethods = ['GET', 'POST']
    this._supportedVersions = []
    this._preferredVersion = ''
    this.supportedVersions = WFS_VERSIONS
  }
  get supportedMethods() {
    return [...this._supportedMethods]
  }
  set supportedMethods(e) {
    this._supportedMethods = [...e]
    if (!this.isSupportedMethod('POST') && !this.isSupportedMethod('GET'))
      throw new Error('No supported WFS request methods available')
  }
  get supportedVersions() {
    return this._supportedVersions.slice(0)
  }
  set supportedVersions(e) {
    this._supportedVersions = [...e]
    for (let e = 0; e < WFS_VERSIONS.length; e++) {
      const s = WFS_VERSIONS[e]
      if (this._supportedVersions.indexOf(s) >= 0) {
        this._preferredVersion = s
        break
      }
    }
    if (!this._preferredVersion)
      throw new Error('No supported WFS versions available')
  }
  build(e, s, r, t, i, o) {
    const p = this._serviceURL
    const u = this._postServiceURL
    const d = undefined
    const n = {
      service: 'WFS',
      request: 'GetFeature',
      version: this._preferredVersion,
      outputFormat: r || 'JSON',
      typeNames: e,
      filters: s,
      sortBy: o,
    }
    if (isNumber(t)) n.maxFeatures = t
    if (isString(i) || isArray(i)) n.propertyNames = isArray(i) ? i : [i]
    const S = this.isSupportedMethod('POST')
    const h = this.isSupportedMethod('GET')
    if (S && h) return WFSRequestBuilderUtil.buildPOSTorGETParams(p, u, n)
    else if (S) return WFSRequestBuilderUtil.buildPOSTParams(u, n)
    else if (h) return WFSRequestBuilderUtil.buildGETParams(p, n)
    throw new Error(
      `Did not find a supported method to perform WFS GetFeature request. [${this._supportedMethods.join(
        ','
      )}] does not contain GET or POST.`
    )
  }
  isSupportedMethod(e) {
    return this._supportedMethods.indexOf(e) >= 0
  }
}
