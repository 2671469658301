import { ProgrammingError } from '../error/ProgrammingError.js'
import { EndCapStyle } from './EndCapStyle.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
export class GeoBuffer extends Shape {
  _invalidationCount = 0
  get type() {
    return ShapeType.GEO_BUFFER
  }
  get focusPoint() {
    return this._baseShape.focusPoint
  }
  get baseShape() {
    return this._baseShape
  }
  set baseShape(e) {
    if (!GeoBuffer.isSupportedBaseShape(e))
      throw new ProgrammingError(
        'Invalid base shape: It must be a polyline or a polygon'
      )
    this._baseShape = e
    this._cache.invalidate()
  }
  get width() {
    return this._width
  }
  set width(e) {
    if (e >= 0) {
      this._width = e
      this._cache.invalidate()
    } else
      throw new ProgrammingError(
        `Width should be positive number, but was: ${e}`
      )
  }
  get endCapStyle() {
    return this._endCapStyle
  }
  set endCapStyle(e) {
    if (e === EndCapStyle.CAP_ROUND || e === EndCapStyle.CAP_BUTT) {
      this._endCapStyle = e
      this._cache.invalidate()
    } else
      throw new ProgrammingError(
        'End cap style must be EndCapStyle.CAP_BUTT or EndCapStyle.CAP_ROUND.'
      )
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    this._baseShape.hashCode(e)
    e.appendDouble(this._width)
    e.appendUInt32(this._invalidationCount)
  }
  equals(e) {
    if (this === e) return true
    if (!ShapeUtil.referencesEqual(this._reference, e.reference)) return false
    return (
      this.type === e.type &&
      this._width === e.width &&
      this._baseShape.equals(e.baseShape)
    )
  }
  translate2D(e, t) {
    this._baseShape.translate2D(e, t)
    this._cache.invalidate()
  }
  _translate2D(e, t) {
    this._baseShape.translate2D(e, t)
  }
  toString() {
    return `GeoBuffer[base shape[${this._baseShape.toString()}], width[${
      this._width
    }]]`
  }
  invalidate() {
    this._cache.invalidate()
    this._invalidationCount++
  }
  get _contour() {
    return this._cache.memoize('buffer-contour', () =>
      this._geoBufferHelper.getBufferContour2D(this)
    )
  }
  get contour() {
    return this._contour.copy()
  }
  getContour() {
    return this._contour
  }
  getContourShapeList() {
    return this._geoBufferHelper.debugContour(this)
  }
  get _geoBufferHelper() {
    return this._bufferHelper
  }
  static isSupportedBaseShape(e) {
    return (
      ShapeType.contains(e.type, ShapeType.POLYLINE) ||
      ShapeType.contains(e.type, ShapeType.POLYGON)
    )
  }
}
