import { addHttpRequestOptions } from '../../../util/HttpRequestOptions.js'
import { request, urlWithParams } from '../../../util/request.js'
function VersionNegotiator(e, t, o, r, i, s) {
  this._baseUrl = e
  this._service = t
  this._parseVersionFromResponse = o
  this._versionsToNegotiate = []
  i = Array.isArray(i) ? i : r
  for (var n = 0; n < i.length; n++) {
    var a = i[n]
    if (r.indexOf(a) >= 0) this._versionsToNegotiate.push(a)
  }
  if (0 === this._versionsToNegotiate.length)
    throw new Error(
      'Could not find a ' +
        t +
        'version to negotiate.' +
        'Supported ' +
        t +
        ' versions are: [' +
        r.join(', ') +
        '].' +
        'Only the following versions are allowed: [' +
        i.join(', ') +
        ']'
    )
  this._requestOptions = s || {}
  this._versionIndex = 0
  this._negotiatedVersion = null
}
VersionNegotiator.prototype = Object.create(Object.prototype)
VersionNegotiator.prototype.constructor = VersionNegotiator
VersionNegotiator.prototype.negotiateVersion = function () {
  this._negotiatedVersion = null
  this._versionIndex = 0
  var e = this
  return this._negotiateVersionStep().then(function (t) {
    if (!e._negotiatedVersion)
      throw new Error(
        'No ' +
          e._service +
          ' version could be negotiated. Tried versions: [' +
          e._versionsToNegotiate.join(', ') +
          ']'
      )
    return { version: e._negotiatedVersion, response: t }
  })
}
VersionNegotiator.prototype._negotiateVersionStep = function () {
  var e = this
  return e
    ._requestCapabilities(e._versionsToNegotiate[e._versionIndex])
    .then(function (t) {
      var o = e._parseVersionFromResponse(t)
      for (var r = 0; r < e._versionsToNegotiate.length; r++)
        if (e._versionsToNegotiate[r] === o) {
          e._negotiatedVersion = o
          break
        }
      e._versionIndex++
      if (
        !e._negotiatedVersion &&
        e._versionIndex < e._versionsToNegotiate.length
      )
        return e._negotiateVersionStep()
      return t
    })
}
VersionNegotiator.prototype._requestCapabilities = function (e) {
  var t = { SERVICE: this._service, REQUEST: 'GetCapabilities', VERSION: e }
  const o = urlWithParams(this._baseUrl, {
    ...t,
    ...this._requestOptions.requestParameters,
  })
  var r = addHttpRequestOptions(
    {
      method: 'GET',
      credentials: this._requestOptions.credentials,
      headers: this._requestOptions.requestHeaders,
      urlParams: Object.assign({}, t, this._requestOptions.requestParameters),
    },
    this._requestOptions
  )
  return request(o, r).then((e) => e.text())
}
export { VersionNegotiator }
