const EPSILON = 1e-6
export let SimpleBoundsType = (function (t) {
  t[(t['XY'] = 0)] = 'XY'
  t[(t['LL'] = 1)] = 'LL'
  return t
})({})
export class SimpleBounds {
  constructor() {
    this.reset()
  }
  get x() {
    return this.left
  }
  get y() {
    return this.bottom
  }
  get width() {
    return Math.abs(this.right - this.left)
  }
  get height() {
    return Math.abs(this.top - this.bottom)
  }
  reset() {
    this.left = Number.NaN
    this.right = Number.NaN
    this.bottom = Number.NaN
    this.top = Number.NaN
  }
  isValid() {
    return !(
      isNaN(this.left) ||
      isNaN(this.bottom) ||
      isNaN(this.right) ||
      isNaN(this.top)
    )
  }
  setTo(t) {
    this.left = t.left
    this.right = t.right
    this.bottom = t.bottom
    this.top = t.top
  }
  setTo2DUnion(t, i) {
    if (i === SimpleBoundsType.XY) {
      if (t.left < this.left) this.left = t.left
      if (t.right > this.right) this.right = t.right
      if (t.bottom < this.bottom) this.bottom = t.bottom
      if (t.top > this.top) this.top = t.top
    } else {
      const i = this.left
      const o = t.left
      const e = this.right - i
      const s = t.right - t.left
      if (
        s >= 360 - EPSILON ||
        e >= 360 - EPSILON ||
        (s + e >= 360 - EPSILON &&
          o + s >= i - EPSILON &&
          i - 360 + e >= o - EPSILON) ||
        (i + e >= o - EPSILON && o - 360 + s >= i - EPSILON)
      ) {
        this.left = -180
        this.right = 180
      } else {
        const i = Math.min(this.left, t.left)
        const o = Math.max(this.right, t.right)
        const e = Math.min(this.left, t.left + 360)
        const s = Math.max(this.right, t.right + 360)
        const h = Math.min(this.left + 360, t.left)
        const r = Math.max(this.right + 360, t.right)
        const l = o - i
        const n = s - e
        const f = r - h
        if (l <= n)
          if (l <= f) {
            this.left = i
            this.right = o
          } else {
            this.left = h
            this.right = r
          }
        else if (n <= f) {
          this.left = e
          this.right = s
        } else {
          this.left = h
          this.right = r
        }
      }
      this.bottom = Math.min(this.bottom, t.bottom)
      this.top = Math.max(this.top, t.top)
    }
  }
  static XY = SimpleBoundsType.XY
  static LL = SimpleBoundsType.LL
}
export function simpleBoundsContains2D(t, i, o) {
  if (o === SimpleBoundsType.XY)
    return (
      t.left <= i.left &&
      t.right >= i.right &&
      t.bottom <= i.bottom &&
      t.top >= i.top
    )
  else
    return (
      i.bottom >= t.bottom - EPSILON &&
      i.top <= t.top + EPSILON &&
      (t.right - t.left >= 360 ||
        (i.left >= t.left - EPSILON && i.right <= t.right + EPSILON) ||
        i.right <= t.right - 360 + EPSILON)
    )
}
export function simpleBoundsInteracts2D(t, i, o) {
  if (o === SimpleBoundsType.XY)
    return !(
      t.right < i.left ||
      t.left > i.right ||
      t.top < i.bottom ||
      t.bottom > i.top
    )
  else
    return !(
      i.bottom > t.top + EPSILON ||
      i.top < t.bottom - EPSILON ||
      ((i.left > t.right + EPSILON || i.right < t.left - EPSILON) &&
        i.left > t.right - 360 + EPSILON &&
        i.right < t.left + 360 - EPSILON)
    )
}
export function createSimpleBoundsFromBounds(t) {
  const i = new SimpleBounds()
  if (t) {
    i.left = t.x
    i.right = t.x + t.width
    i.bottom = t.y
    i.top = t.y + t.height
  }
  return i
}
