import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { LineType } from '../../../geodesy/LineType.js'
import { createPhotonColorFromString, INVISIBLE } from '../../../util/Color.js'
import { Log } from '../../../util/Log.js'
import { ObjectReleaseTracker } from '../../../util/ObjectReleaseTracker.js'
import { PropertyProvider } from '../PropertyProvider.js'
import { StyleIdGenerator } from '../StyleIdGenerator.js'
import { DrapeTarget } from '../../style/DrapeTarget.js'
import { isDefined, isUndefined } from '../../../util/Lang.js'
export class TrajectoryPainterImpl {
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    this.demoMode = e.demoMode || false
    this._properties = e.properties || []
    this._propertyProviders = []
    for (let e = 0; e < this._properties.length; e++)
      this._propertyProviders[e] = new PropertyProvider(this._properties[e])
    this.propertyColorExpressions = e.propertyColorExpressions || []
    this.defaultColor =
      e.defaultColor || null === e.defaultColor
        ? e.defaultColor
        : 'rgb(255, 255, 255)'
    this.selectionColor = e.selectionColor || 'rgb(255, 0, 0)'
    this._lineWidth = e.lineWidth || 1
    this._rangePropertyProvider = e.rangePropertyProvider || null
    this.rangeWindow = e.rangeWindow || [0, Number.MAX_VALUE]
    this._outsideTimeRangeWeight =
      'undefined' === typeof e.outsideTimeRangeWeight
        ? 0.01
        : e.outsideTimeRangeWeight
    this.lineType = e.lineType || LineType.CONSTANT_BEARING
    let t = e.drapeTarget
    if (isDefined(e.draped) && isUndefined(e.drapeTarget))
      t = e.draped ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
    this._drapeTarget = t ?? DrapeTarget.NOT_DRAPED
    this._rangeColorMap = e.rangeColorMap || null
    const r = new StyleIdGenerator()
    this._regularStyleId = r.get()
    this._selectedStyleId = r.get()
    this._usedInSoftwareWarning = false
  }
  get propertyColorExpressions() {
    return this._propertyColorExpressions
  }
  set propertyColorExpressions(e) {
    for (let t = 0; t < e.length; t++)
      if (!e[t].property || this._properties.indexOf(e[t].property) < 0)
        throw new ProgrammingError(
          `Styling property ${e[t].property} not listed in all properties`
        )
    if (this.demoMode) assertOnlyTwoColors(e, this.defaultColor)
    this._propertyColorExpressions = e
    this._updateStyle()
  }
  get defaultColor() {
    return this._defaultColor
  }
  set defaultColor(e) {
    if (this.demoMode) assertOnlyTwoColors(this.propertyColorExpressions, e)
    this._defaultColor = e
    this._updateStyle()
  }
  get selectionColor() {
    return this._selectionColor
  }
  set selectionColor(e) {
    this._selectionColor = e
    this._updateStyle()
  }
  get lineWidth() {
    return this._lineWidth
  }
  get rangeWindow() {
    return this._rangeWindow
  }
  set rangeWindow(e) {
    if (2 !== e.length)
      throw new ProgrammingError(
        `You must provide an array of two elements as start and end value for ${this.constructor.name}.rangeWindow`
      )
    if (e[0] > e[1])
      throw new ProgrammingError(
        `Start value must be <= end value for ${this.constructor.name}.rangeWindow`
      )
    this._rangeWindow = e
    this._updateRangeWindow()
  }
  get rangeColorMap() {
    return this._rangeColorMap
  }
  set rangeColorMap(e) {
    this._rangeColorMap = e || null
    this._updateStyle()
  }
  get outsideTimeRangeWeight() {
    return this._outsideTimeRangeWeight
  }
  set outsideTimeRangeWeight(e) {
    this._outsideTimeRangeWeight = e
  }
  get density() {
    return this._density
  }
  set density(e) {
    this._density = e
    this._updateStyle()
  }
  paintBody(e, t, r, o, i, s) {
    if (
      this._usedInSoftwareWarning ||
      'Photon' !== i.viewPaintingStrategy.techContext.type
    ) {
      if (!this._usedInSoftwareWarning) {
        this._usedInSoftwareWarning = true
        Log.warn('Trajectory Painter should not be used in Software rendering.')
      }
      return
    }
    const n = s.selected ? this._selectedStyleId : this._regularStyleId
    const a = {
      isTrajectoryStyle: true,
      syncWithShapePainter: this.syncWithShapePainter.bind(this),
      fillAttributes: this.fillAttributes.bind(this),
      getAttributeCount: this.getAttributeCount.bind(this),
      drapeTarget: this._drapeTarget,
      lineWidth: this.lineWidth,
      lineType: this.lineType,
      id: n,
      renderPassId: n,
    }
    e.drawShape(r, a)
  }
  _updateStyle() {
    if (this._shapePainter) {
      const e = new ObjectReleaseTracker()
      try {
        const t = e.track(
          this._photon.BufferFactory.createFloat32BufferFromLength(
            6 * this._propertyColorExpressions.length
          )
        )
        const r = e.track(
          this._photon.BufferFactory.createFloat32BufferFromLength(0)
        )
        let o = { levels: r, colors: r },
          i = { levels: r, colors: r }
        for (let e = 0; e < this._propertyColorExpressions.length; e++) {
          const r = this._propertyColorExpressions[e]
          const o = r.property
          const i = this._properties.indexOf(o)
          const s = this._propertyProviders[i]
          const n = r.value
          const a = createPhotonColorFromString(r.color || INVISIBLE)
          if (null === a)
            throw new Error(`Could not parse color string "${r.color}"`)
          const l = 6 * e
          t.typedArray[l] = i + (this._rangePropertyProvider ? 1 : 0)
          t.typedArray[l + 1] = s.map(n)
          t.typedArray[l + 2] = a.r
          t.typedArray[l + 3] = a.g
          t.typedArray[l + 4] = a.b
          t.typedArray[l + 5] = a.a
        }
        const s = createPhotonColorFromString(this._defaultColor || INVISIBLE)
        const n = createPhotonColorFromString(this._selectionColor || INVISIBLE)
        if (this._rangeColorMap)
          o = e.track(this._rangeColorMap.toPhotonBuffers(this._photon))
        if (this.density && this.density.colorMap)
          i = e.track(this.density.colorMap.toPhotonBuffers(this._photon))
        this._shapePainter.updateTrajectoryRenderPass(
          this._regularStyleId,
          t,
          s,
          this._lineWidth,
          this.demoMode,
          this._outsideTimeRangeWeight,
          o.levels,
          o.colors,
          false,
          i.levels,
          i.colors
        )
        this._shapePainter.updateTrajectoryRenderPass(
          this._selectedStyleId,
          r,
          n,
          this._lineWidth,
          this.demoMode,
          this._outsideTimeRangeWeight,
          r,
          r,
          false,
          r,
          r
        )
        this._invalidator.invalidate()
      } finally {
        e.release()
      }
    }
  }
  _updateRangeWindow() {
    if (this._shapePainter) {
      this._shapePainter.updateTrajectoryRenderPassRangeWindow(
        this._regularStyleId,
        this._rangeWindow[0],
        this._rangeWindow[1]
      )
      this._shapePainter.updateTrajectoryRenderPassRangeWindow(
        this._selectedStyleId,
        this._rangeWindow[0],
        this._rangeWindow[1]
      )
      this._invalidator.invalidate()
    }
  }
  syncWithShapePainter(e, t, r) {
    if (this._shapePainter !== e) {
      this._photon = t
      this._shapePainter = e
      this._invalidator = r
      const o = this.getAttributeCount()
      const i = this._rangePropertyProvider ? 0 : -1
      this._shapePainter.setTrajectoryRenderPass(
        this._regularStyleId,
        o,
        i,
        0,
        false,
        this._drapeTarget
      )
      this._shapePainter.setTrajectoryRenderPass(
        this._selectedStyleId,
        o,
        i,
        1,
        true,
        this._drapeTarget
      )
      this._updateStyle()
      this._updateRangeWindow()
    }
  }
  getAttributeCount() {
    return this._rangePropertyProvider
      ? this._properties.length + 1
      : this._properties.length
  }
  fillAttributes(e, t, r, o, i) {
    if (r * this.getAttributeCount() !== i.length)
      Log.error('Attribute array to fill has incorrect size')
    if (r !== o.length) Log.error('Fractions array has incorrect size')
    let s = 0
    let n = 0
    if (this._rangePropertyProvider) {
      const a = []
      for (n = 0; n < t.pointCount; n++)
        a.push(this._rangePropertyProvider(e, t, n))
      for (n = 0; n < r; n++) {
        const e = o[n]
        if ((0 | e) === e) i[s++] = a[e]
        else
          i[s++] = interpolateValue(a[Math.floor(e)], a[Math.ceil(e)], o[n] % 1)
      }
    }
    for (let t = 0; t < this._properties.length; t++) {
      const o = this._propertyProviders[t].get(e)
      for (n = 0; n < r; n++) i[s++] = o
    }
    if (s !== i.length)
      Log.error(`Incorrect length: expected ${i.length} but was ${s}`)
  }
}
function assertOnlyTwoColors(e, t) {
  const r = new Set()
  if (t) r.add(t)
  for (let t = 0; t < e.length; t++) if (e[t].color) r.add(e[t].color)
  if (r.size > 2) {
    let e = ''
    r.forEach((t) => {
      e += t
    })
    throw new ProgrammingError(
      `You can only use two different colors for TrajectoryPainter expressions and default color, but you have ${r.size}: ${e}`
    )
  }
}
function interpolateValue(e, t, r) {
  return e * (1 - r) + t * r
}
