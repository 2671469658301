import { Axis } from '../../reference/Axis.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import {
  CM_UOM,
  isLengthKind,
  isWorldSize,
  METER_UOM,
} from '../../uom/UnitOfMeasureUtil.js'
export class WorldSizeSupport {
  constructor(e) {
    const t = e.reference.getAxis(Axis.Name.X).unitOfMeasure
    const r = e.reference.getAxis(Axis.Name.Y).unitOfMeasure
    let i = null
    if (e.reference.referenceType === ReferenceType.CARTESIAN)
      i =
        'World-size units (style.uom) are not allowed for maps with Cartesian reference'
    if (t !== r)
      i =
        'World-size units (style.uom) are not allowed for a map with non-uniform axes'
    this._errorMessage = i
    this._isWorldSizeSupported = !i
    this._uomX = t
    this._map = e
  }
  get errorMessage() {
    return this._errorMessage
  }
  get isWorldSizeSupported() {
    return this._isWorldSizeSupported
  }
  get uomX() {
    return this._uomX
  }
  get map() {
    return this._map
  }
  toPixels(e, t) {
    if (!t || !isWorldSize(t)) return e
    this.validateWorldSizeUom(t)
    return this.toPixelsImpl(e, t)
  }
  toPixelsImpl(e, t) {
    const r = this.toMapUnitsImpl(e, t)
    const i = this._map.xScale * r
    const o = undefined
    let s = this._uomX.convertToUnit(i, CM_UOM) * this._map.dotsPerCentimeter
    if (s > 0 && s < 1) s = 1
    else if (s < 0 && s > -1) s = -1
    return s
  }
  getLineStyleRescaledSizes(e) {
    const t = e.uom
    this.validateWorldSizeUom(t)
    let r = 0
    const i =
      0 === e.dash.length
        ? e.dash
        : e.dash.map((e) => {
            const i = this.toPixelsImpl(e, t)
            r += i
            return i
          }, this)
    return {
      forMapScale: this.getCurrentScale(),
      width: this.toPixelsImpl(e.width, t),
      dash: i,
      dashOffset: this.toPixelsImpl(e.dashOffset, t),
      patternLength: r,
    }
  }
  getLineMarkerStyleRescaledSizes(e, t, r) {
    this.validateWorldSizeUom(e)
    return {
      forMapScale: this.getCurrentScale(),
      arrowLineWidth: this.toPixelsImpl(t, e),
      arrowSize: this.toPixelsImpl(r, e),
    }
  }
  getPhotonLineStyleWorldSizes(e) {
    const t = e.uom
    this.validateWorldSizeUom(t)
    const r =
      0 === e.dash.length
        ? e.dash
        : e.dash.map((e) => this.toMapUnitsImpl(e, t), this)
    return {
      width: this.toMapUnitsImpl(e.width, t),
      dash: r,
      dashOffset: this.toMapUnitsImpl(e.dashOffset, t),
    }
  }
  toMapUnits(e, t) {
    let r =
      arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : false
    let i = e
    let o = t
    if (!isWorldSize(t))
      if (!r) return e
      else {
        const t = e / this._map.dotsPerCentimeter
        i = CM_UOM.convertToUnit(t, this._uomX) / this._map.xScale
        o = this._uomX
      }
    this.validateWorldSizeUom(o)
    return this.toMapUnitsImpl(i, o)
  }
  getCurrentScale() {
    return this._map.xScale
  }
  validateWorldSizeUom(e) {
    if (!this._isWorldSizeSupported)
      throw new ProgrammingError(this._errorMessage)
    if (!e._isCompatible(this._uomX))
      throw new ProgrammingError(
        'World-size unit (style.uom) is not compatible with Map units'
      )
    if (!isLengthKind(e))
      throw new ProgrammingError(
        'World-size unit (style.uom) must be of Length QuantityKind only'
      )
  }
  toMapUnitsImpl(e, t) {
    return t === this._uomX ? e : t.convertToUnit(e, this._uomX)
  }
}
export function createUOMSupport(e) {
  return new WorldSizeSupport(e)
}
export function getWorldUOMToMeterScale(e) {
  return e.convertToUnit(1, METER_UOM)
}
export function getWorldUOMInMeters(e, t) {
  return e.convertToUnit(t, METER_UOM)
}
