import { attributeLookupFactory, kmlNumberFactory } from './KMLValues.js'
import { DEFAULT_XY_VECTOR, getKmlUnits } from './KMLXYVector.js'
const getXYVectorNumber = kmlNumberFactory(0)
export const getOverlayXYValue = function (t) {
  let e =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { ...DEFAULT_XY_VECTOR }
  e.x = getOverlayX(t)
  e.y = getOverlayY(t)
  e.xUnits = getOverlayXUnits(t)
  e.yUnits = getOverlayYUnits(t)
  return e
}
const getOverlayX = attributeLookupFactory('x', getXYVectorNumber)
const getOverlayY = attributeLookupFactory('y', getXYVectorNumber)
const getOverlayXUnits = attributeLookupFactory('xunits', getKmlUnits)
const getOverlayYUnits = attributeLookupFactory('yunits', getKmlUnits)
export const getScreenXYValue = function (t) {
  let e =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { ...DEFAULT_XY_VECTOR }
  e.x = getScreenX(t)
  e.y = getScreenY(t)
  e.xUnits = getScreenXUnits(t)
  e.yUnits = getScreenYUnits(t)
  return e
}
const getScreenX = attributeLookupFactory('x', getXYVectorNumber)
const getScreenY = attributeLookupFactory('y', getXYVectorNumber)
const getScreenXUnits = attributeLookupFactory('xunits', getKmlUnits)
const getScreenYUnits = attributeLookupFactory('yunits', getKmlUnits)
export const getRotationXYValue = function (t) {
  let e =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { ...DEFAULT_XY_VECTOR }
  e.x = getRotationX(t)
  e.y = getRotationY(t)
  e.xUnits = getRotationXUnits(t)
  e.yUnits = getRotationYUnits(t)
  return e
}
const getRotationX = attributeLookupFactory('x', getXYVectorNumber)
const getRotationY = attributeLookupFactory('y', getXYVectorNumber)
const getRotationXUnits = attributeLookupFactory('xunits', getKmlUnits)
const getRotationYUnits = attributeLookupFactory('yunits', getKmlUnits)
export const getSizeValue = function (t) {
  let e =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : { ...DEFAULT_XY_VECTOR }
  e.x = getSizeX(t)
  e.y = getSizeY(t)
  e.xUnits = getSizeXUnits(t)
  e.yUnits = getSizeYUnits(t)
  return e
}
const getSizeX = attributeLookupFactory('x', getXYVectorNumber)
const getSizeY = attributeLookupFactory('y', getXYVectorNumber)
const getSizeXUnits = attributeLookupFactory('xunits', getKmlUnits)
const getSizeYUnits = attributeLookupFactory('yunits', getKmlUnits)
