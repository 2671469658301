import { isNumber } from '../util/Lang.js'
import { Constants } from '../util/Constants.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Arc } from './Arc.js'
import { LLHBounds } from './LLHBounds.js'
import { calculateBounds, arcContains2D } from './LLHArcUtil.js'
import * as LLHEllipseUtil from './LLHEllipseUtil.js'
export class LLHArc extends Arc {
  constructor(r, t, e, o, i, n, s) {
    super(r, t, e, o, i, n, s % 360)
    this._compareReferenceStrict(
      t.reference,
      'LLHArc::the center point reference must be equal to the arc reference'
    )
    if (!isNumber(e))
      throw new ProgrammingError('LLHArc::cannot create a without a')
    if (!isNumber(o))
      throw new ProgrammingError('LLHArc::cannot create a without b')
    if (!isNumber(i))
      throw new ProgrammingError(
        'LLHArc::cannot create a without rotationAzimuth'
      )
    if (!isNumber(n))
      throw new ProgrammingError('LLHArc::cannot create a without startAzimuth')
    if (!isNumber(s))
      throw new ProgrammingError('LLHArc::cannot create a without sweepAngle')
  }
  get isGeodetic() {
    return true
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  copy() {
    return new LLHArc(
      this.reference,
      this.center.copy(),
      this.a,
      this.b,
      this.rotationAzimuth,
      this.startAzimuth,
      this.sweepAngle
    )
  }
  createBounds(r) {
    const t = new LLHBounds(r.reference, [0, 0, 0, 0, 0, 0])
    calculateBounds(r, t)
    return t
  }
  computeArcPoint(r) {
    const t = (r - this.rotationAzimuth) / 360
    const e = this.center.copy()
    LLHEllipseUtil.interpolate(this, t, e)
    return e
  }
  interpolate(r, t) {
    return LLHEllipseUtil.interpolate(this, r, t)
  }
  updatePoint(r, t, e) {
    LLHEllipseUtil.updatePoint(this, r, t, e)
  }
  _forwardAzimuth(r, t) {
    return (
      Constants.RAD2DEG *
      this.reference.geodeticDatum.ellipsoid.forwardAzimuth2D(r, t)
    )
  }
  contains2DCoordinates(r, t) {
    return arcContains2D(this, r, t)
  }
  contains3DCoordinates(r, t, e) {
    throw new Error('Method not implemented.')
  }
}
