import { ClusterGrid } from './ClusterGrid.js'
import { Clustered, shouldSplit } from './Clustered.js'
export class FixedSizeClusteringAlgorithm {
  constructor() {}
  cluster(t, e, s) {
    let r = getClusters(t, e)
    const n = new ClusterGrid(r)
    let l = -1
    while (r.length !== l) {
      l = r.length
      r = this.mergeClusters(r, n, s.clusterSize)
    }
    return flattenClusters(r)
  }
  mergeClusters(t, e, s) {
    const r = [0]
    const n = []
    const l = s / 2
    for (let u = 0; u < t.length; u++) {
      const i = t[u]
      if (i.parent) continue
      const o = this.findClosestCluster(e, i, l, r)
      if (!o) {
        n.push(i)
        continue
      }
      if (shouldMerge(i, o, e, r[0], s)) combineClusters(i, o, e, n)
      else n.push(i)
    }
    return n
  }
  findClosestCluster(t, e, s, r) {
    const n = s * s
    let l = Number.POSITIVE_INFINITY
    let u
    t.applyWithinDistance(e, s, (t, e) => {
      if (e <= n && e < l) {
        l = e
        u = t
      }
      return false
    })
    r[0] = Math.sqrt(l)
    return u
  }
}
function shouldMerge(t, e, s, r, n) {
  if (0 === r) return true
  if (hasClusterWithinDistance(s, e, 0.95 * r)) return false
  const l = new Clustered()
  l.addCluster(t)
  l.addCluster(e)
  const u = shouldSplit(l, 0.9 * n)
  l.removeCluster(t)
  l.removeCluster(e)
  return !u
}
function hasClusterWithinDistance(t, e, s) {
  let r = false
  t.applyWithinDistance(e, s, (t, e) => {
    r = true
    return true
  })
  return r
}
function flattenClusters(t) {
  const e = []
  for (let s = 0; s < t.length; s++) {
    const r = undefined
    const n = t[s].getPoints()
    const l = new Clustered()
    for (let t = 0; t < n.length; t++) l.addPoint(n[t])
    e.push(l)
  }
  return e
}
function getClusters(t, e) {
  const s = e.slice()
  for (let e = 0; e < t.length; e++) {
    const r = t[e]
    if (!r.cluster) {
      const t = new Clustered()
      t.addPoint(r)
      s.push(t)
    }
  }
  return s
}
function combineClusters(t, e, s, r) {
  s.remove(e)
  s.remove(t)
  t.addCluster(e)
  s.add(t)
  r.push(t)
}
