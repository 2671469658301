import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { Lang } from '../../../util/Lang.js'
var isFunction = Lang.isFunction
function PhotonWeakMap() {
  this._map = new Map()
}
PhotonWeakMap.prototype.set = function (e, t) {
  if ('undefined' === typeof t)
    throw new ProgrammingError('can not add undefined to PhotonWeakMap')
  var o = this._map.get(e)
  if (isFunction(t.copy) && isFunction(t.makeWeak)) {
    var n = t.copy()
    n.makeWeak()
    this._map.set(e, n)
  } else this._map.set(e, t)
  if (o && isFunction(o.release)) o.release()
}
PhotonWeakMap.prototype.get = function (e) {
  var t = this._map.get(e)
  if ('undefined' === typeof t) return
  var o = t
  if (isFunction(t.copy)) o = t.copy()
  if (!o) {
    if (isFunction(t.release)) t.release()
    this._map.delete(e)
    return
  }
  return o
}
PhotonWeakMap.prototype.remove = function (e) {
  let t = this.get(e)
  if (t) {
    t.release()
    map.delete(e)
  }
}
PhotonWeakMap.prototype.purge = function () {
  const e = []
  this._map.forEach(function (t, o, n) {
    if (isFunction(t.isValid))
      if (!t.isValid()) {
        if (isFunction(t.release)) t.release()
        e.push(o)
        n.delete(o)
      }
  })
  return e
}
PhotonWeakMap.prototype.forEach = function (e) {
  var t = this
  this._map.forEach(function (o, n, i) {
    if (isFunction(o.copy)) {
      var r = o.copy()
      if (r) e(r, n, t)
      else {
        if (isFunction(o.release)) o.release()
        i.delete(n)
      }
    } else e(o, n, t)
  })
}
PhotonWeakMap.prototype.release = function () {
  this._map.forEach(function (e, t, o) {
    if (isFunction(e.release)) e.release()
    o.delete(t)
  })
}
Object.defineProperty(PhotonWeakMap.prototype, 'size', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._map.size
  },
  set: function () {
    throw new ProgrammingError('size property is not mutable')
  },
})
export { PhotonWeakMap }
