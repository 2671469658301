import { ShapeType } from '../../../shape/ShapeType.js'
import { PointDragHandle } from '../handles/PointDragHandle.js'
import { Editor } from '../Editor.js'
import { removeNullHandles } from '../handles/HandleUtil.js'
import {
  CreateByTemplateHandle,
  getDefaultPoint,
} from '../handles/CreateByTemplateHandle.js'
export class CircleBy3PointsEditor extends Editor {
  constructor() {
    super()
  }
  canEdit(e) {
    return ShapeType.contains(ShapeType.CIRCLE_BY_3_POINTS, e.shape.type)
  }
  getEditHandles(e) {
    return removeNullHandles([
      this.createCenterHandle(e),
      this.createFirstPointHandle(e),
      this.createSecondPointHandle(e),
      this.createThirdPointHandle(e),
      this.createTranslateHandle(e),
    ])
  }
  createCenterHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.center,
      (e) => t.move2DToPoint(e)
    )
  }
  createFirstPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.firstPoint,
      (e) => t.moveFirstPoint2DToPoint(e)
    )
  }
  createSecondPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.secondPoint,
      (e) => t.moveSecondPoint2DToPoint(e)
    )
  }
  createThirdPointHandle(e) {
    const t = e.shape
    return new PointDragHandle(
      () => t.thirdPoint,
      (e) => t.moveThirdPoint2DToPoint(e)
    )
  }
  getCreateHandle(e) {
    return new CreateByTemplateHandle(e, this.getEditHandles(e), (e, t) => {
      const n = e.shape
      n.move2DToPoint(t)
      n.moveFirstPoint2DToPoint(getDefaultPoint(e.map, t, 0))
      n.moveSecondPoint2DToPoint(getDefaultPoint(e.map, t, 120))
      n.moveThirdPoint2DToPoint(getDefaultPoint(e.map, t, 240))
    })
  }
}
