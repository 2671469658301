import { ObjectReleaseTracker } from '../../../util/ObjectReleaseTracker.js'
import { PhotonQueryProcess } from './PhotonQueryProcess.js'
import { PhotonShapePainterProcess } from './PhotonShapePainterProcess.js'
import { PhotonSpatialQueryProcess } from './PhotonSpatialQueryProcess.js'
import { EventedSupport } from '../../../util/EventedSupport.js'
class PhotonRenderSet extends EventedSupport {
  constructor(e, s, t, r, i, a) {
    super()
    this._layer = e
    this._workingSet = t
    this._shapePainter = i
    this._objectReleaseTracker = new ObjectReleaseTracker()
    this._layerVisible = this._isLayerVisible()
    this._shapePainterProcess = this._objectReleaseTracker.track(
      new PhotonShapePainterProcess(
        this._layer,
        this,
        r,
        i,
        a.scheduler_test || a.scheduler
      )
    )
    if (a.filterSpatially)
      this._queryProcess = this._objectReleaseTracker.track(
        new PhotonSpatialQueryProcess(
          this._layer,
          this._layerVisible,
          s,
          this._workingSet,
          this._shapePainterProcess,
          a.scheduler
        )
      )
    else
      this._queryProcess = this._objectReleaseTracker.track(
        new PhotonQueryProcess(
          this._layer,
          this._workingSet,
          this._shapePainterProcess,
          a.scheduler
        )
      )
    const o = this
    if ('function' === typeof this._queryProcess.onLayerVisibilityChanged) {
      const e = this._layer.on('VisibilityChanged', function () {
        o._updateLayerVisible()
      })
      this._objectReleaseTracker.track({ release: e.remove.bind(e) })
    }
    const h = s.on('idle', function () {
      if ('function' === typeof o._queryProcess.onMapIdle)
        o._queryProcess.onMapIdle()
      o._shapePainterProcess.onMapIdle()
    })
    this._objectReleaseTracker.track({ release: h.remove.bind(h) })
  }
  isReady() {
    return this._queryProcess.isReady() && this._shapePainterProcess.isReady()
  }
  _isLayerVisible() {
    return this._layer.visible
  }
  _updateLayerVisible() {
    const e = this._isLayerVisible()
    if (this._layerVisible !== e) {
      this._layerVisible = e
      this._queryProcess.onLayerVisibilityChanged(e)
    }
  }
  updateStyle(e) {
    this._shapePainterProcess.updateStyle(e)
  }
  updateAllStyles() {
    this._shapePainterProcess.refreshPainter()
  }
  terrainChanged() {
    this._shapePainterProcess.terrainChanged()
  }
  _addObject(e, s) {}
  _updateObject(e, s) {}
  _removeObject(e, s) {}
  forEachFeature(e) {
    this._shapePainter.forEachElement(e)
  }
  get(e) {
    const s = []
    this._shapePainter.forEachElement(function (e) {
      s.push(e)
    })
    return s
  }
  release(e) {
    this._objectReleaseTracker.release()
  }
}
export { PhotonRenderSet }
