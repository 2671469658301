import { Ajax } from '../../util/Ajax.js'
import { normalizeAngle0To360 } from '../../util/Cartesian.js'
import { Clipping } from '../../util/Clipping.js'
import { resizeImageOrCanvas } from '../../util/ImageUtil.js'
import {
  hasProperty,
  isArray,
  isBoolean,
  isDefined,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { whenInternal } from '../../util/PromiseUtil.js'
import { URL } from '../../util/URL.js'
import { createDefaultPointStyle } from '../feature/DefaultStyles.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { ComplexStrokedLineStyleImpl } from './complexstroke/ComplexStrokedLineStyleImpl.js'
import { LabelGroupName } from './HTMLLabelGroup.js'
import { LineMarkerType } from './LineMarkerType.js'
import { PathLabelPosition } from './PathLabelPosition.js'
import { PathLabelRotation } from './PathLabelRotation.js'
import { PinEndPosition } from './PinEndPosition.js'
import { PointLabelPosition } from './PointLabelPosition.js'
import { SimpleStroke } from './SimpleStroke.js'
import { BloomStyleImpl, DEFAULT_BLOOM, equalBloomStyle } from './BloomStyle.js'
import {
  normalizeOffsetSizes,
  normalizePerpendicularOffset,
} from './OffsetsUtil.js'
import {
  isLengthUom,
  isPixelValue,
  isWorldSize,
  parseValueAndUom,
  PIXEL_UOM,
} from '../../uom/UnitOfMeasureUtil.js'
import { DrapeTarget } from './DrapeTarget.js'
import { releaseImages } from './complexstroke/photon/PhotonStateContainer.js'
const DEFAULT_LINE_WIDTH = 1
const DEFAULT_MARKER_SIZE = 20
const DEFAULT_STROKE = void 0
const DEFAULT_STROKE_STYLE_COLOR = 'rgb(0,0,0)'
const DEFAULT_LINE_DASH = []
const DEFAULT_LINE_DASH_OFFSET = 0
const DEFAULT_HALO_STYLE = void 0
const DEFAULT_FILL_STYLE = 'rgba(255,255,255,1)'
const DEFAULT_ICON_MODULATION_COLOR = 'rgba(255,255,255,1)'
const DEFAULT_FONT = '14px sans-serif'
const DEFAULT_TEXT_ALIGN = 'start'
const DEFAULT_TEXT_BASELINE = 'alphabetic'
const DEFAULT_OFFSET_X = 0
const DEFAULT_OFFSET_Y = 0
const DEPRECATED_STROKE_WIDTH = 'stroke-width'
const DEPRECATED_STROKE_WIDTH2 = 'strokeWidth'
const DEPRECATED_TEXT_ANCHOR = 'text-anchor'
const DEPRECATED_ALIGNMENT_BASELINE = 'alignment-baseline'
const DEFAULT_OFFSET = 4
const DEFAULT_Z_ORDER = 0
const DEFAULT_ICON_STYLE = createDefaultPointStyle()
let FALLBACK_ICON = null
Promise.resolve(Ajax.getImage(getFallbackIconUrl(), void 0, false)).then(
  (e) => {
    FALLBACK_ICON = e
  },
  () => {
    FALLBACK_ICON = null
  }
)
const logger = new Log({ avoidDuplicateMessages: true })
function isNumberValue(e) {
  return isDefined(e) && isNumber(e, false)
}
function getNormalizedPadding(e) {
  return isNumber(e) && e > 0 ? e : 0
}
function normalizeAnchorSize(e, t, i) {
  if (isNumber(e)) {
    Log.deprecated(
      `No support for IconStyle.${t} without a unit of measure (i.e. 50% or 16px).`
    )
    return `${e}px`
  }
  if (isString(e)) return e
  return i
}
function normalizeImageSize(e, t, i, o, r, n) {
  if (n && n !== PIXEL_UOM) return '100%'
  if (!e)
    if (o) e = `${o}px`
    else e = r
  else if (isNumber(e)) {
    if (!n)
      Log.deprecated(
        `Support for ${t}.${i} without a unit of measure`,
        `${t}.${i} with a unit of measure (i.e. 50% or 16px), or style.uom set to a "Pixels" unit of measure`
      )
    e = `${e}px`
  }
  return e
}
const normalizeScaleFactorCache = { size: '0', anchor: '0' }
function normalizeScaleFactor(e, t, i, o, r) {
  if (e && isNumber(e)) {
    Log.deprecated(
      `Support for IconStyle.${t}`,
      `IconStyle.${o}with a percentage unit of measure (i.e. 50%)`
    )
    if (!i) i = `${Math.floor(100 * Math.abs(e))}%`
    else i = scaleValue(i, e)
    if (isValueInPixels(r)) r = scaleValue(r, e)
    normalizeScaleFactorCache.size = i
    normalizeScaleFactorCache.anchor = r
    return true
  }
  return false
}
function normalizeOpacity(e, t, i) {
  if (isNumber(e)) {
    if (e < 0) {
      const e = getValueRangeWarningMessage(t, 0, 1, 0)
      logger.warn(e)
      return 0
    }
    if (e > 1) {
      const e = getValueRangeWarningMessage(t, 0, 1, 1)
      logger.warn(e)
      return 1
    }
    return e
  }
  if (isDefined(e)) logger.warn(`The ${t} value should be a number.`)
  return i
}
export function isValueInPixels(e) {
  return !!e && (isNumber(e) || e.indexOf('px') > 0)
}
function sanitizeShapeStyle(e) {
  if (hasProperty((e = e || {}), DEPRECATED_STROKE_WIDTH))
    Log.deprecated(
      DEPRECATED_STROKE_WIDTH,
      'luciad.view.style.LineStyle object literal'
    )
  if (hasProperty(e, DEPRECATED_STROKE_WIDTH2))
    Log.deprecated(
      DEPRECATED_STROKE_WIDTH2,
      'luciad.view.style.LineStyle object literal'
    )
  if (hasProperty(e, 'beginMarker'))
    Log.deprecated(
      'Use luciad.view.style.ShapeStyle.beginMarker',
      'luciad.view.style.LineStyle.beginMarker'
    )
  if (hasProperty(e, 'endMarker'))
    Log.deprecated(
      'luciad.view.style.ShapeStyle.endMarker',
      'luciad.view.style.LineStyle.endMarker'
    )
  if (e.stroke && !isObject(e.stroke))
    Log.warn('For stroke style please use a luciad.view.style.LineStyle object')
  if (e.fill && !isObject(e.fill))
    Log.warn('For fill style please use a luciad.view.style.FillStyle object')
  if (isBoolean(e.draped))
    Log.deprecated('ShapeStyle.draped', 'ShapeStyle.drapeTarget')
}
function normalizeShapeLineStyle(e) {
  if (null === e.stroke) return null
  if (e.stroke && isComplexStrokeStyle(e.stroke)) {
    const t = ComplexStrokedLineStyleImpl.create(e.stroke)
    t.release = function () {
      releaseImages(t)
    }
    return t
  }
  const t = normalizeLineStyle(e.stroke)
  return new SimpleStroke(t)
}
function normalizeShapeFillStyle(e) {
  if (!e) return null
  const t = normalizePerpendicularOffset(e.perpendicularOffset, e.uom)
  const i = normalizeOffsetSizes(e.offsetX, e.offsetY, e.uom)
  if (isString(e))
    return {
      color: e,
      bloom: DEFAULT_BLOOM,
      isWorldSized: false,
      worldWidth: 0,
      worldHeight: 0,
      width: '100%',
      height: '100%',
      normalizedOffsets: i,
      normalizedPerpendicularOffset: t,
    }
  let o = e
  if (o.image || o.url) {
    const e = normalizeSizes(o.width, o.height, o.uom)
    const r = normalizeOpacity(o.opacity, 'opacity', 1)
    const n = normalizeImageSize(
      e.styleWidth,
      'FillStyle',
      'width',
      o.image ? o.image.width : void 0,
      '100%',
      e.uom
    )
    const l = normalizeImageSize(
      e.styleHeight,
      'FillStyle',
      'height',
      o.image ? o.image.height : void 0,
      '100%',
      e.uom
    )
    o = {
      color: o.color,
      url: o.url,
      image: o.image,
      credentials: !!o.credentials,
      width: n,
      height: l,
      worldWidth: e.worldWidth,
      worldHeight: e.worldHeight,
      isWorldSized: e.isWorldSized,
      rotation: o.rotation ? o.rotation : 0,
      opacity: r,
      uom: e.uom,
      normalizedOffsets: i,
      normalizedPerpendicularOffset: t,
      release() {
        if (this.photonImage) this.photonImage.release()
      },
    }
  } else
    o = {
      ...e,
      width: '100%',
      height: '100%',
      isWorldSized: false,
      worldWidth: 0,
      worldHeight: 0,
      normalizedOffsets: i,
      normalizedPerpendicularOffset: t,
    }
  if (!equalBloomStyle(o.bloom, DEFAULT_BLOOM))
    o.bloom =
      o.bloom && isObject(o.bloom) ? new BloomStyleImpl(o.bloom) : DEFAULT_BLOOM
  return o
}
const DEFAULT_INNER_BORDER_SIZE = 12
const DEFAULT_INNER_BORDER_COLOR = 'rgba(12, 2, 110, 0.5)'
const DEFAULT_OUTER_BORDER_SIZE = 4
const DEFAULT_OUTER_BORDER_COLOR = 'rgba(12, 2, 110, 0.25)'
function normalizeBorderStyle(e, t) {
  if (!e || !isObject(e)) return
  const i = t
    ? {
        width: e.width || DEFAULT_INNER_BORDER_SIZE,
        color: e.color || DEFAULT_INNER_BORDER_COLOR,
        clip: Clipping.MASK.INNER,
        uom: e.uom,
      }
    : {
        width: e.width || DEFAULT_OUTER_BORDER_SIZE,
        color: e.color || DEFAULT_OUTER_BORDER_COLOR,
        clip: Clipping.MASK.OUTER,
        uom: e.uom,
      }
  i.width *= 2
  const o = normalizeLineStyle(i)
  return new SimpleStroke(o)
}
function getMultiplicationValue(e, t) {
  if (!e) return 1
  if (!isValueInPixels(e)) return parseFloat(e) / 100
  else return parseFloat(e) / t
}
function combineValues(e, t) {
  const i = isValueInPixels(e)
  const o = isValueInPixels(t)
  if (i && o) return t
  if (!i && o) return scaleValue(t, 0.01 * parseFloat(e))
  return scaleValue(e, 0.01 * parseFloat(t))
}
export function scaleValue(e, t) {
  const i = undefined
  let o = '' + parseFloat(e) * t
  if (isNumber(e));
  else if (e.indexOf('px') > 0) o += 'px'
  else if (e.indexOf('%') > 0) o += '%'
  return o
}
export function normalizeTextStyle(e) {
  let t = (e = e || {}).strokeWidth
  let i = e.textAnchor
  let o = e.alignmentBaseline
  if (hasProperty(e, DEPRECATED_STROKE_WIDTH)) {
    Log.deprecated(DEPRECATED_STROKE_WIDTH, "'strokeWidth'")
    t = t || e[DEPRECATED_STROKE_WIDTH]
  }
  if (hasProperty(e, DEPRECATED_TEXT_ANCHOR)) {
    Log.deprecated(DEPRECATED_TEXT_ANCHOR, "'textAnchor'")
    i = i || e[DEPRECATED_TEXT_ANCHOR]
  }
  if (hasProperty(e, DEPRECATED_ALIGNMENT_BASELINE)) {
    Log.deprecated(DEPRECATED_ALIGNMENT_BASELINE, "'alignmentBaseline'")
    o = o || e[DEPRECATED_ALIGNMENT_BASELINE]
  }
  if (hasProperty(e, 'uom') && !isNumber(e.height))
    throw new Error(
      'If you use TextStyle.uom, you must specify TextStyle.height as well.'
    )
  let r = e.drapeTarget
  if (isDefined(e.draped) && isUndefined(e.drapeTarget))
    r = e.draped ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
  return {
    offsetX: isDefined(e.offsetX) ? e.offsetX : DEFAULT_OFFSET_X,
    offsetY: isDefined(e.offsetY) ? e.offsetY : DEFAULT_OFFSET_Y,
    strokeWidth: isDefined(t) ? t : DEFAULT_LINE_WIDTH,
    stroke: isDefined(e.stroke) ? e.stroke : DEFAULT_STROKE,
    haloWidth: isDefined(e.haloWidth) ? e.haloWidth : DEFAULT_LINE_WIDTH,
    halo: isDefined(e.halo) ? e.halo : DEFAULT_HALO_STYLE,
    fill: isDefined(e.fill) ? e.fill : DEFAULT_FILL_STYLE,
    font: isDefined(e.font) ? e.font : DEFAULT_FONT,
    textAnchor: isDefined(i) ? i : DEFAULT_TEXT_ALIGN,
    alignmentBaseline: isDefined(o) ? o : DEFAULT_TEXT_BASELINE,
    zOrder: isDefined(e.zOrder) ? e.zOrder : DEFAULT_Z_ORDER,
    angle: e.angle || 0,
    occlusionMode: e.occlusionMode,
    drapeTarget: r,
    uom: isWorldSize(e.uom) ? e.uom : PIXEL_UOM,
    height: e.height ?? 1,
  }
}
export function normalizeShapeStyle(e) {
  sanitizeShapeStyle((e = e || {}))
  let t = e.drapeTarget
  if (isDefined(e.draped) && isUndefined(e.drapeTarget))
    t = e.draped ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
  const i = {
    stroke: normalizeShapeLineStyle(e),
    fill: normalizeShapeFillStyle(e.fill),
    lineType: e.lineType,
    zOrder: isDefined(e.zOrder) ? e.zOrder : DEFAULT_Z_ORDER,
    drapeTarget: t,
    occlusionMode: e.occlusionMode,
    innerBorder: normalizeBorderStyle(e.innerBorder, true),
    outerBorder: normalizeBorderStyle(e.outerBorder, false),
    release() {
      if (this.stroke && this.stroke.release) this.stroke.release()
      if (this.fill && this.fill.release) this.fill.release()
    },
  }
  i.normalizeShapeOrientation = !!(
    e.normalizeShapeOrientation ||
    i.innerBorder ||
    i.outerBorder
  )
  return i
}
export function normalizeLineStyle(e) {
  const t = {
    color: isDefined((e = e || {}).color)
      ? e.color
      : DEFAULT_STROKE_STYLE_COLOR,
    width: isDefined(e.width) ? e.width : DEFAULT_LINE_WIDTH,
  }
  if (e.dash && isArray(e.dash)) t.dash = e.dash.slice(0)
  else t.dash = DEFAULT_LINE_DASH.slice(0)
  let i = 0
  if (t.dash.length) {
    if (t.dash.length % 2) t.dash = t.dash.concat(t.dash)
    t.dash.forEach((e) => {
      i += e
    })
  }
  t.patternLength = i
  let o = isNumber(e.dashOffset) ? e.dashOffset : DEFAULT_LINE_DASH_OFFSET
  if (0 !== o) {
    o %= i
    if (o < 0) o += i
  }
  t.dashOffset = o
  if (e.beginMarker && isObject(e.beginMarker))
    t.beginMarker = {
      type: e.beginMarker.type || LineMarkerType.ARROW,
      size: e.beginMarker.size || DEFAULT_MARKER_SIZE,
    }
  if (e.endMarker && isObject(e.endMarker))
    t.endMarker = {
      type: e.endMarker.type || LineMarkerType.ARROW,
      size: e.endMarker.size || DEFAULT_MARKER_SIZE,
    }
  t.isWorldSize = isWorldSize(e.uom)
  t.uom = e.uom && t.isWorldSize ? e.uom : PIXEL_UOM
  t.bloom =
    e.bloom && isObject(e.bloom) ? new BloomStyleImpl(e.bloom) : DEFAULT_BLOOM
  t.clip = e.clip
  return t
}
function normalizeSizes(e, t, i) {
  const o = isPixelValue(e, i, true)
  const r = isPixelValue(t, i, true)
  if (isUndefined(e) || isUndefined(t) || (o && r)) {
    if (i && i !== PIXEL_UOM)
      throw new Error(
        `IconStyle.width (=${e}) and height(=${t}) are interpreted in pixel values, but IconStyle.uom is not a "Pixels" unit of measure (uom). Set IconStyle.uom to a "Pixels" uom, leave it undefined, or change width and height to number if you want world-sized icons.`
      )
    return {
      isWorldSized: false,
      styleWidth: e,
      styleHeight: t,
      worldWidth: 0,
      worldHeight: 0,
      uom: PIXEL_UOM,
    }
  }
  if ((o && !r) || (!o && r))
    throw new Error(
      `One of IconStyle.width (=${e}) and IconStyle.height (=${t}) is interpreted as a pixel size, while the other is not. You cannot mix pixel and world sizes in an IconStyle.`
    )
  const n = parseValueAndUom(e)
  if (!i && !isLengthUom(n.uom))
    throw new Error(
      `IconStyle.width (=${e}) has no uom of kind "Length". Set IconStyle.uom or use a length unit symbol in the width string (e.g. "50m").`
    )
  const l = parseValueAndUom(t)
  if (!i && !isLengthUom(l.uom))
    throw new Error(
      `IconStyle.height (=${t}) has no uom of kind "Length". Set IconStyle.uom or use a length unit symbol in the height string (e.g. "50m").`
    )
  const a = i ?? n?.uom ?? l?.uom
  if (!a)
    throw new Error(
      `Could not determine unit of measure for IconStyle with width: ${e}, height ${t} and uom: ${i}. Set IconStyle.uom to a "Length" uom (e.g. getUnitOfMeasure("Meter")) or define a length unit in the width/height string (e.g. "50m")`
    )
  if (!isLengthUom(a))
    throw new Error(
      `IconStyle with width: ${e}, height ${t} and uom: ${i} has no unit of measure of type "Length". Set IconStyle.uom to a "Length" uom (e.g. getUnitOfMeasure("Meter")) or use a length unit symbol in the width/height string (e.g. "50m")`
    )
  let s = n.value
  if (n.uom && n.uom !== a) s = n.uom.convertToUnit(n.value, a)
  let u = l.value
  if (l.uom && l.uom !== a) u = l.uom?.convertToUnit(l.value, a)
  return {
    isWorldSized: true,
    styleWidth: '100%',
    styleHeight: '100%',
    worldWidth: s,
    worldHeight: u,
    uom: a,
  }
}
export function normalizeIconStyle(e) {
  const t = e || {}
  const i = normalizeSizes(t.width, t.height, t.uom)
  const o = isDefined(t.image)
  let r = normalizeImageSize(
    i.styleWidth,
    'IconStyle',
    'width',
    o ? t.image.width : void 0,
    '100%',
    i.uom
  )
  let n = normalizeImageSize(
    i.styleHeight,
    'IconStyle',
    'height',
    o ? t.image.height : void 0,
    '100%',
    i.uom
  )
  let l = normalizeAnchorSize(t.anchorX, 'anchorX', '50%')
  let a = normalizeAnchorSize(t.anchorY, 'anchorY', '50%')
  const s = normalizeOffsetSizes(t.offsetX, t.offsetY, t.uom)
  const u = s.normalizedOffsetX
  const d = s.normalizedOffsetY
  if (normalizeScaleFactor(t.scaleX, 'scaleX', r, 'width', l)) {
    r = normalizeScaleFactorCache.size
    l = normalizeScaleFactorCache.anchor
  }
  if (normalizeScaleFactor(t.scaleX, 'scaleY', n, 'height', a)) {
    n = normalizeScaleFactorCache.size
    a = normalizeScaleFactorCache.anchor
  }
  let m = Number.NaN
  if (isNumber(t.heading)) m = normalizeAngle0To360(t.heading)
  if (t.stem && isDefined(t.stem.uom) && t.stem.uom !== PIXEL_UOM) {
    t.stem.uom = PIXEL_UOM
    Log.warn('stem.uom is disregarded')
  }
  const f = normalizeOpacity(t.opacity, 'opacity', 1)
  let c = DEFAULT_BLOOM
  if (!equalBloomStyle(t.bloom, DEFAULT_BLOOM))
    c =
      t.bloom && isObject(t.bloom) ? new BloomStyleImpl(t.bloom) : DEFAULT_BLOOM
  let h = t.drapeTarget
  if (isDefined(t.draped) && isUndefined(t.drapeTarget))
    h = t.draped ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
  return {
    url: t.url,
    image: t.image,
    offsetX: u.pixelOffset,
    offsetY: d.pixelOffset,
    width: r,
    height: n,
    zOrder: t.zOrder ?? DEFAULT_Z_ORDER,
    rotation: t.rotation ? t.rotation : 0,
    heading: m,
    anchorX: l,
    anchorY: a,
    drapeTarget: h,
    stem: t.stem ? normalizeShapeLineStyle({ stroke: t.stem }) : null,
    credentials: t.credentials || false,
    opacity: f,
    modulationColor: t.modulationColor ?? DEFAULT_ICON_MODULATION_COLOR,
    bloom: c,
    occlusionMode: t.occlusionMode,
    uom: i.uom,
    isWorldSized: i.isWorldSized,
    worldWidth: i.worldWidth,
    worldHeight: i.worldHeight,
    isWorldOffsetX: u.isWorldOffset,
    offsetXUom: u.offsetUom,
    worldOffsetX: u.worldOffset,
    isWorldOffsetY: d.isWorldOffset,
    worldOffsetY: d.worldOffset,
    offsetYUom: d.offsetUom,
  }
}
export function normalizeHtmlLabelStyle() {
  let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
  let t =
    arguments.length > 1 && void 0 !== arguments[1]
      ? arguments[1]
      : PaintRepresentation.LABEL
  const i = {
    group: e.group || LabelGroupName.GROUP_DEFAULT,
    priority: isNumberValue(e.priority) ? e.priority : 0,
    positions: e.positions || getDefaultPointLabelPosition(t),
    offset: isNumber(e.offset) || isArray(e.offset) ? e.offset : DEFAULT_OFFSET,
    padding: getNormalizedPadding(e.padding),
    rotation: isNumberValue(e.rotation) ? e.rotation : 0,
    anchorX: isNumberValue(e.anchorX) ? e.anchorX : void 0,
    anchorY: isNumberValue(e.anchorY) ? e.anchorY : void 0,
  }
  if (e.pin)
    i.pin = {
      color: isString(e.pin.color) ? e.pin.color : 'rgb(0,0,0)',
      width: isNumber(e.pin.width) ? e.pin.width : 2,
      endPosition: e.pin.endPosition || PinEndPosition.BORDER,
      haloColor: isString(e.pin.haloColor) ? e.pin.haloColor : 'rgb(0,0,0)',
      haloWidth:
        isNumber(e.pin.haloWidth) && e.pin.haloWidth >= 0 ? e.pin.haloWidth : 0,
    }
  return i
}
export function getDefaultPointLabelPosition(e) {
  switch (e) {
    case PaintRepresentation.LEFT_BORDER_LABEL:
      return PointLabelPosition.WEST
    case PaintRepresentation.BOTTOM_BORDER_LABEL:
      return PointLabelPosition.SOUTH
    default:
      return PointLabelPosition.ANY
  }
}
export function normalizeLabelStyleForController(e) {
  const t = e || {}
  t.group =
    isString(t.group) || isNumber(t.group)
      ? t.group
      : LabelGroupName.GROUP_CONTROLLER
  t.priority = isDefined(t.priority) ? t.priority : -Number.MAX_VALUE
  return t
}
export function normalizeHtmlInPathLabelStyle() {
  let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
  return {
    priority: isNumberValue(e.priority) ? e.priority : 0,
    group: e.group ? e.group : LabelGroupName.GROUP_DEFAULT,
    padding: getNormalizedPadding(e.padding),
    inView: isBoolean(e.inView) ? e.inView : true,
    restrictToBounds: !!e.restrictToBounds,
  }
}
export function normalizeHtmlOnPathLabelStyle() {
  let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
  const t = isNumberValue(e.perpendicularOffset) ? e.perpendicularOffset : 0
  const i = t ? PathLabelPosition.CENTER : e.positions
  return {
    group: e.group || LabelGroupName.GROUP_DEFAULT,
    priority: isNumberValue(e.priority) ? e.priority : 0,
    positions: i || PathLabelPosition.CENTER,
    rotation: isNumberValue(e.rotation)
      ? e.rotation
      : PathLabelRotation.FIXED_LINE_ANGLE,
    padding: getNormalizedPadding(e.padding),
    repeat: o(e.repeat),
    perpendicularOffset: t,
  }
  function o(e) {
    const t = 200
    if (!e) return
    let i = isNumber(e.minimumGap) ? e.minimumGap : t
    if (i < 5) i = 5
    const o = undefined
    return {
      minimumGap: i,
      initialGap:
        isNumber(e.initialGap) && e.initialGap >= 0 ? e.initialGap : i,
    }
  }
}
export function resolveStyleImagePixels(e, t, i, o) {
  e = e ? combineValues(`${i}px`, e) : `${i}px`
  t = t ? combineValues(`${o}px`, t) : `${o}px`
  return { width: Math.round(parseFloat(e)), height: Math.round(parseFloat(t)) }
}
export function getIconStyleValuesInPx(e, t, i, o, r, n) {
  if (!isDefined(i)) i = '50%'
  if (!isDefined(o)) o = '50%'
  const l = getMultiplicationValue(e, r)
  const a = getMultiplicationValue(t, n)
  if (i && isValueInPixels(i)) i = scaleValue(i, l)
  if (o && isValueInPixels(o)) o = scaleValue(o, a)
  const s = resolveStyleImagePixels(e, t, r, n)
  if (i && !isValueInPixels(i)) i = combineValues(i, '' + s.width)
  if (o && !isValueInPixels(o)) o = combineValues(o, '' + s.height)
  return {
    anchorX: parseFloat(i),
    anchorY: parseFloat(o),
    width: s.width,
    height: s.height,
  }
}
export function getValueRangeWarningMessage(e, t, i, o) {
  const r =
    "The range of the '{0}' value should be [{1}, {2}], {3} will be used instead of the value specified."
  const n = e.charAt(0).toUpperCase() + e.slice(1)
  let l = r.replace('{0}', n)
  l = l.replace('{1}', '' + t)
  l = l.replace('{2}', '' + i)
  l = l.replace('{3}', '' + o)
  return l
}
export function getFallbackIconUrl() {
  return (
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbW' +
    'FnZVJlYWR5ccllPAAAAeNJREFUeNqkU8tKw0AUvTNppLRgW1IouiiCIi4r/QAr+gNdutKdv1PdubMLcekn2B+QFiyIUMHHQilWSm' +
    'pfaZJ67mQSY3XnwMll7txz7iMzghbWWbl8BHMIVBaOGkD9+ObmPO4UMeIazJWZTpdWLIusTIYMKUkISb4U1LNteu12aWrbLcRVIf' +
    'QYCWhy0yoUsuv5PCVBujcCsgBZQGgTcLF/6L3Ta6fTR/w2i0hdwFV+dTW7pcjQZBLs/ukJ7dVqSgAOMuEvTh1KEWWZw0TJPZupVG' +
    'kjlyNDkaWCIkWNBlV44zG5L89UDLwl5nLU4YqVp6VFsvgW4P3c92l0d0fefE5LQA5gLkdVrMzyLzL3Hi4mD27bNJtMlAAjHQhUVB' +
    'ojJAupyxVRCxzXbbfJGX6Si40LnxuQ1UooAc4YDk9XwFnnCPRhZ8OhIvkMJYoq4gKuDPr3ucTRmJyBTW/9Pl3s7ESZvJgAW1eISK' +
    'Dx2GxWivogzODH/4IuOy4wDAQaHFXv4fOph8MIezy4vlYIKwjhYD8KEtSlvtutFyg6mhwfUrwCHuAM+DBNdrWYm9Dn1Qmu8pOU2Y' +
    'LnkaEFLnd3fwjMkMQGGf3zVa7++Zj4hqUx+SSg/qEe2BQlTwxDZf71mP7znL8EGAAa+AKYmZIPSgAAAABJRU5ErkJggg=='
  )
}
function convertTextToLabel(e, t) {
  let i = ''
  if (t.fill) i += `color: ${t.fill};`
  if (t.font) i += `font: ${t.font};`
  if (t.halo) {
    let e = 'text-shadow: '
    const o = t.haloWidth ? Math.max(t.haloWidth / 2, 1) : 1
    for (let i = -o; i <= o; i++)
      for (let r = -o; r <= o; r++) {
        if (0 === i && 0 === r) continue
        e += `${i}px ${r}px 0px ${t.halo}`
        if (i === o && r === o) e += ';'
        else e += ','
      }
    i += e
  }
  i += 'line-height: 1;'
  if (t.stroke)
    Log.warn(
      "Hardware rendering doesn't handle TextStyle.stroke for grid labels."
    )
  return `<div style="${i}">${e}</div>`
}
export function resolveIconImage(e, t, i, o, r) {
  if (!isString(e) && !t) return DEFAULT_ICON_STYLE.image
  let n
  if (t instanceof HTMLCanvasElement) {
    const e = undefined
    if (isDefined(o) && isDefined(r) && t.width !== o && t.height !== r)
      n = resizeImageOrCanvas(t, o ?? t.width, r ?? t.height)
    else return t
  } else if (t instanceof HTMLImageElement)
    n = Ajax.getImage(t, true, false, false, null, null, o, r)
  else n = Ajax.getImage(e, true, !URL.isLocalURL(e), i, null, null, o, r)
  return whenInternal(
    n,
    (e) => e,
    () => FALLBACK_ICON
  )
}
export function isComplexStrokeStyle(e) {
  return (
    !!e &&
    (hasProperty(e, 'fallback') ||
      hasProperty(e, 'regular') ||
      hasProperty(e, 'decorations'))
  )
}
const PERCENTAGE_REGEX = /(\d+(\.\d+)?)%/
function parsePercentage(e) {
  if (!e) return null
  const t = e.match(PERCENTAGE_REGEX)
  if (t && t[1]) {
    const e = parseFloat(t[1])
    if (!isNaN(e)) return e
  }
  return null
}
export const StyleUtil = {
  isNumberValue: isNumberValue,
  normalizeShapeStyle: normalizeShapeStyle,
  normalizeTextStyle: normalizeTextStyle,
  normalizeLineStyle: normalizeLineStyle,
  normalizeIconStyle: normalizeIconStyle,
  normalizeHtmlLabelStyle: normalizeHtmlLabelStyle,
  normalizeLabelStyleForController: normalizeLabelStyleForController,
  normalizeHtmlOnPathLabelStyle: normalizeHtmlOnPathLabelStyle,
  isValueInPixels: isValueInPixels,
  resolveStyleImagePixels: resolveStyleImagePixels,
  getIconStyleValuesInPx: getIconStyleValuesInPx,
  getValueRangeWarningMessage: getValueRangeWarningMessage,
  getFallbackIconUrl: getFallbackIconUrl,
  convertTextToLabel: convertTextToLabel,
  resolveIconImage: resolveIconImage,
  parsePercentage: parsePercentage,
}
