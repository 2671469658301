import { XMLSchema } from '../../util/XMLSchema.js'
var attribute = XMLSchema.attribute,
  boolean = XMLSchema.boolean,
  number = XMLSchema.number,
  content = XMLSchema.content
var onlineResourceSchema = { type: attribute(), href: attribute() }
var keywordListSchema = { Keyword: [{ '#text_only': content() }] }
var contactAddressSchema = {
  AddressType: content(),
  Address: content(),
  City: content(),
  StateOrProvince: content(),
  PostCode: content(),
  Country: content(),
}
var contactPersonPrimarySchema = {
  ContactPerson: content(),
  ContactOrganization: content(),
}
var contactInformationSchema = {
  ContactPersonPrimary: contactPersonPrimarySchema,
  ContactPosition: content(),
  ContactAddress: contactAddressSchema,
  ContactVoiceTelephone: content(),
  ContactFacsimileTelephone: content(),
  ContactElectronicMailAddress: content(),
}
var serviceSchema = {
  Name: content(),
  Title: content(),
  Abstract: content(),
  KeywordList: keywordListSchema,
  OnlineResource: onlineResourceSchema,
  ContactInformation: contactInformationSchema,
  Fees: content(),
  AccessConstraints: content(),
  LayerLimit: content(Number),
  MaxWidth: content(Number),
  MaxHeight: content(Number),
}
var resourceWithFormatURLSchema = {
  Format: content(),
  OnlineResource: onlineResourceSchema,
}
var imageURLSchema = {
  width: attribute(Number),
  height: attribute(Number),
  Format: content(),
  OnlineResource: onlineResourceSchema,
}
var styleSchema = {
  Name: content(),
  Title: content(),
  Abstract: content(),
  LegendURL: [imageURLSchema],
  StyleSheetURL: resourceWithFormatURLSchema,
  StyleURL: resourceWithFormatURLSchema,
}
var dimensionSchema = {
  '#text': content(),
  name: attribute(),
  units: attribute(),
  unitSymbol: attribute(),
  default: attribute(),
  multipleValues: attribute(boolean),
  nearestValue: attribute(boolean),
  current: attribute(boolean),
}
var identifierSchema = { authority: attribute(), '#text': content() }
var attributionSchema = {
  Title: content(),
  OnlineResource: onlineResourceSchema,
  LogoURL: imageURLSchema,
}
var authorityURLSchema = {
  name: attribute(),
  OnlineResource: onlineResourceSchema,
}
var layerSchemaCommon = {
  queryable: attribute(boolean),
  cascaded: attribute(Number),
  opaque: attribute(boolean),
  noSubsets: attribute(boolean),
  fixedWidth: attribute(Number),
  fixedHeight: attribute(Number),
  Name: content(),
  Title: content(),
  Abstract: content(),
  KeywordList: keywordListSchema,
  Dimension: [dimensionSchema],
  Attribution: attributionSchema,
  AuthorityURL: [authorityURLSchema],
  Identifier: [identifierSchema],
  MetadataURL: [resourceWithFormatURLSchema],
  DataURL: [resourceWithFormatURLSchema],
  FeatureListURL: [resourceWithFormatURLSchema],
  Style: [styleSchema],
}
var dcpTypeSchema = {
  HTTP: {
    Get: { OnlineResource: onlineResourceSchema },
    Post: { OnlineResource: onlineResourceSchema },
  },
}
var operationTypeSchema = {
  Format: [{ '#text_only': content() }],
  DCPType: [dcpTypeSchema],
}
var exceptionSchema = { Format: [{ '#text_only': content() }] }
export var WMSCapabilitiesSchemaCommon = {
  serviceSchema: serviceSchema,
  identifierSchema: identifierSchema,
  attributionSchema: attributionSchema,
  authorityURLSchema: authorityURLSchema,
  layerSchemaCommon: layerSchemaCommon,
  operationTypeSchema: operationTypeSchema,
  exceptionSchema: exceptionSchema,
}
