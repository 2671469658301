import { Ajax } from '../../../util/Ajax.js'
function PhotonTextureDecoder(e, t) {
  this._photon = e
  this._glContext = t.getGLContext()
  this._photonGraphics = t.getGraphics()
}
function isPowerOfTwo(e) {
  return 0 !== e && 0 === (e & (e - 1))
}
function canGenerateMipMap(e, t) {
  return isPowerOfTwo(e) && isPowerOfTwo(t)
}
PhotonTextureDecoder.prototype.decodeUrl = function (e, t, r, o, a) {
  var T = this
  return Ajax.getImage(e, false, r, o, void 0, a).then(function (e) {
    var t = T.createPhotonTexture(e)
    if (t) return t
    else throw new Error('Failed to create GPU texture for image.')
  })
}
PhotonTextureDecoder.prototype.createPhotonTexture = function (e) {
  var t = this._glContext
  var r = this._photon
  var o
  var a = this._photonGraphics.copy()
  if (!a)
    throw new Error(
      'Cannot create texture for request to ' +
        e.src +
        '. WebGL context no longer valid.'
    )
  var T = null
  try {
    T = a.createTexture2D(e.width, e.height)
    var n = r.GL.textures[T.getGLName()]
    t.bindTexture(t.TEXTURE_2D, n)
    t.pixelStorei(t.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true)
    t.texImage2D(t.TEXTURE_2D, 0, t.RGBA, t.RGBA, t.UNSIGNED_BYTE, e)
    t.pixelStorei(t.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false)
    t.texParameteri(t.TEXTURE_2D, t.TEXTURE_WRAP_S, t.CLAMP_TO_EDGE)
    t.texParameteri(t.TEXTURE_2D, t.TEXTURE_WRAP_T, t.CLAMP_TO_EDGE)
    t.texParameteri(t.TEXTURE_2D, t.TEXTURE_MAG_FILTER, t.LINEAR)
    if (canGenerateMipMap(e.width, e.height)) {
      t.texParameteri(
        t.TEXTURE_2D,
        t.TEXTURE_MIN_FILTER,
        t.LINEAR_MIPMAP_LINEAR
      )
      t.generateMipmap(t.TEXTURE_2D)
    } else t.texParameteri(t.TEXTURE_2D, t.TEXTURE_MIN_FILTER, t.LINEAR)
    t.bindTexture(t.TEXTURE_2D, null)
    a.release()
  } catch (e) {
    a.release()
    throw e
  }
  return T
}
export { PhotonTextureDecoder }
