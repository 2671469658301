import { ReferenceType } from '../reference/ReferenceType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { Constants } from '../util/Constants.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { ShapeType } from '../shape/ShapeType.js'
import {
  closestPointOnLine,
  distance2D,
  distance3D as cartesianDistance3D,
  area as CartesianArea,
  forwardAzimuth2D,
} from '../util/Cartesian.js'
export class CartesianGeodesy {
  constructor(e) {
    this._reference = e
    if (
      e.referenceType === ReferenceType.GRID ||
      e.referenceType === ReferenceType.GEOCENTRIC ||
      e.referenceType === ReferenceType.CARTESIAN
    )
      this._uom = e.unitOfMeasure
    else if (e.referenceType === ReferenceType.GEODETIC)
      this._uom = (2 * Math.PI * e.geodeticDatum.ellipsoid.auxRadius) / 360
    else
      throw new ProgrammingError(
        `Unsupported reference type: ${e.referenceType}`
      )
  }
  get reference() {
    return this._reference
  }
  distance(e, r) {
    const t = transform(e, this._reference)
    const n = transform(r, this._reference)
    return distance2D(t, n) * this._uom
  }
  distance3D(e, r) {
    const t = transform(e, this._reference)
    const n = transform(r, this._reference)
    return cartesianDistance3D(t, n) * this._uom
  }
  forwardAzimuth(e, r) {
    const t = transform(e, this._reference)
    const n = transform(r, this._reference)
    return Constants.RAD2DEG * forwardAzimuth2D(t, n)
  }
  interpolate(e, r, t) {
    const n = transform(e, this._reference)
    if ('number' === typeof r) {
      if (r < 0) {
        r = -r
        if (t > 180) t -= 180
        else t = 180 + t
      }
      const e = r / this._uom
      const s = Math.PI / 2 - Constants.DEG2RAD * t
      return createPoint(this._reference, [
        n.x + Math.cos(s) * e,
        n.y + Math.sin(s) * e,
        n.z || 0,
      ])
    } else {
      const e = transform(r, this._reference)
      const s = n.x
      const o = n.y
      const a = n.z || 0
      const i = e.x - s
      const c = e.y - o
      const f = (e.z || 0) - a
      return createPoint(this._reference, [s + t * i, o + t * c, a + t * f])
    }
  }
  shortestDistanceToLine(e, r, t, n, s) {
    const o = undefined
    return closestPointOnLine(e, r, t, n && !!n.clipToSegment, s).distance
  }
  area(e) {
    if (!ShapeType.contains(e.type, ShapeType.POLYGON))
      throw new ProgrammingError(
        'Only the calculation of the area of a polygon is supported at the moment'
      )
    return Math.abs(CartesianArea(e))
  }
}
function transform(e, r) {
  const t = e._reference
  if (null == t || r == t || r.equals(t)) return e
  else {
    const n = undefined
    return createTransformation(t, r).transform(e)
  }
}
