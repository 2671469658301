import { Filled } from './util/Filled.js'
import { Stroked } from './util/Stroked.js'
import { Pattern } from '../Pattern.js'
import { PatternType } from './util/PatternType.js'
import { Length } from './util/Length.js'
export class TrianglePattern extends Pattern {
  constructor(t) {
    super(PatternType.TRIANGLE)
    const e = (t = t || {}).p0 || []
    const i = t.p1 || []
    const h = t.p2 || []
    const s = t.fill || {}
    const n = t.line || {}
    this._filled = new Filled(s)
    this._stroked = new Stroked(n)
    this._relative = t.relative ?? false
    this._x0 = e[0] || 0
    this._y0 = e[1] || 0
    this._x1 = i[0] || 0
    this._y1 = i[1] || 0
    this._x2 = h[0] || 0
    this._y2 = h[1] || 0
    this._minX = Math.min(this._x0, this._x1, this._x2)
    this._maxX = Math.max(this._x0, this._x1, this._x2)
    this._minY = Math.min(this._y0, this._y1, this._y2)
    this._maxY = Math.max(this._y0, this._y1, this._y2)
    const r = t.relative ? 0 : this._maxX - this._minX
    const l = t.relative ? this._maxX - this._minX : 0
    this._length = new Length({ fixedLength: r, relativeLength: l })
  }
  get relative() {
    return this._relative
  }
  get x0() {
    return this._x0
  }
  get y0() {
    return this._y0
  }
  get x1() {
    return this._x1
  }
  get y1() {
    return this._y1
  }
  get x2() {
    return this._x2
  }
  get y2() {
    return this._y2
  }
  get worldSizeContext() {
    return this._length.worldSizeContext
  }
  set worldSizeContext(t) {
    this._length.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._length.getUomSizeToPixels(t)
  }
  getMinHeight() {
    return this._minY - this.lineWidth / 2
  }
  getMaxHeight() {
    return this._maxY + this.lineWidth / 2
  }
  getWidth(t) {
    return Math.max(this._x0, this._x1, this._x2)
  }
  strokePath(t) {
    this._stroked.strokePath(t)
  }
  get lineColor() {
    return this._stroked.lineColor
  }
  set lineColor(t) {
    this._stroked.lineColor = t
  }
  get lineWidth() {
    return this._stroked.lineWidth
  }
  set lineWidth(t) {
    this._stroked.lineWidth = t
  }
  get dashed() {
    return this._stroked.dashed
  }
  set dashed(t) {
    this._stroked.dashed = t
  }
  fillPath(t) {
    this._filled.fillPath(t)
  }
  get fillColor() {
    return this._filled.fillColor
  }
  set fillColor(t) {
    this._filled.fillColor = t
  }
  isRelativeLength() {
    return this._length.isRelativeLength()
  }
  get fixedLength() {
    return this._length.fixedLength
  }
  set fixedLength(t) {
    this._length.fixedLength = t
  }
  get relativeLength() {
    return this._length.relativeLength
  }
  set relativeLength(t) {
    this._length.relativeLength = t
  }
  getTriangleWidth(t, e) {
    return this._length.getWidth(e)
  }
  paint(t, e, i, h) {
    t.scale(1, -1)
    t.beginPath()
    t.moveTo(this._x0, this._y0)
    t.lineTo(this._x1, this._y1)
    t.lineTo(this._x2, this._y2)
    t.closePath()
    this.fillPath(t)
    this.strokePath(t)
    t.scale(1, -1)
  }
  appendHash(t) {
    t.appendDouble(this._x0)
    t.appendDouble(this._y0)
    t.appendDouble(this._x1)
    t.appendDouble(this._y1)
    t.appendDouble(this._x2)
    t.appendDouble(this._y2)
    t.appendBoolean(this._relative)
    this._filled.appendHash(t)
    this._stroked.appendHash(t)
  }
}
