import { Constants } from '../util/Constants.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { normalizeAngle } from '../util/Cartesian.js'
const tempPoint1 = new LLHPoint()
const tempPoint2 = new LLHPoint()
function find(t, e, n, o, i, s) {
  let c, a, m, r, P, l
  const D = o.geodesicDistance(t, e)
  const f = o.geodesicDistance(t, n)
  const p = o.geodesicDistance(e, n)
  const d = o.forwardAzimuth2D(t, e) * Constants.RAD2DEG
  const u = o.forwardAzimuth2D(t, n) * Constants.RAD2DEG
  const h = normalizeAngle(u - d)
  let C = Math.min(D, Math.min(f, p)) / 2
  o.geodesicPositionAtFractionSFCT(e, n, 0.5, tempPoint1)
  const A = o.geodesicDistance(t, tempPoint1)
  let g = Number.MAX_VALUE
  let M = Number.MAX_VALUE
  if (A <= p) {
    const D = Math.abs(h) >= 90
    const f = 1e-4
    let p = 0
    let d = o.auxRadius * Math.PI
    let u = 0
    if ('number' !== typeof s) {
      C = s = 0
      u++
      l = intersectionCircleCircle(e, C, n, C, o, tempPoint1, tempPoint2)
      if (0 === l) p = C
      else if (1 === l) {
        c = o.geodesicDistance(t, tempPoint1)
        g = tempPoint1.x
        M = tempPoint1.y
        if (c >= C) p = C
        else d = C
      } else if (2 === l) {
        a = o.geodesicDistance(t, tempPoint1)
        m = o.geodesicDistance(t, tempPoint2)
        c = D ? Math.max(a, m) : Math.min(a, m)
        r = Math.abs(a - C)
        P = Math.abs(m - C)
        if (r < P) {
          g = tempPoint1.x
          M = tempPoint1.y
        } else {
          g = tempPoint2.x
          M = tempPoint2.y
        }
        if (D)
          if (c <= C || (a >= C && m >= C)) p = C
          else d = C
        else if (c >= C) p = C
        else d = C
      }
      let h = Math.max(f, Math.min(1, 0.001 * C))
      const A = p === C
      let x = C
      while (x >= p && x <= d && d - p > f) {
        x = A ? C + h : C - h
        if (u >= 7) break
        const i = Math.ceil(Math.log((d - p) / f) / Math.log(2))
        const s = undefined
        if (Math.ceil(Math.log(h / f) / Math.log(2)) + 1 >= i) break
        u++
        l = intersectionCircleCircle(e, x, n, x, o, tempPoint1, tempPoint2)
        let y = true
        let T
        if (0 === l) {
          p = x
          o.geodesicPositionAtFractionSFCT(e, n, 0.5, tempPoint1)
          T = o.geodesicDistance(t, tempPoint1)
        } else if (1 === l) {
          c = o.geodesicDistance(t, tempPoint1)
          g = tempPoint1.x
          M = tempPoint1.y
          if (c >= C) p = x
          else d = x
          T = c
        } else if (2 === l) {
          a = o.geodesicDistance(t, tempPoint1)
          m = o.geodesicDistance(t, tempPoint2)
          c = D ? Math.max(a, m) : Math.min(a, m)
          r = Math.abs(a - C)
          P = Math.abs(m - C)
          if (r < P) {
            g = tempPoint1.x
            M = tempPoint1.y
          } else {
            g = tempPoint2.x
            M = tempPoint2.y
          }
          if (D)
            if (c <= x || (a >= C && m >= C)) p = x
            else d = x
          else if (c >= x) p = x
          else d = x
          T = c
        } else {
          y = false
          T = 0
        }
        if (y)
          if ((A && d === x) || (!A && p === x)) {
            if (A) d = x
            else p = x
            break
          } else {
            const t = (2 * Math.abs(x - T)) / 3
            h = Math.max(2 * h, t - x + C)
          }
        else h *= 10
      }
      C = x
      i.move2D(g, M)
    }
    p = 0
    while (d - p > f) {
      u++
      C = (p + d) / 2
      l = intersectionCircleCircle(e, C, n, C, o, tempPoint1, tempPoint2)
      if (0 === l) p = C
      else if (1 === l) {
        c = o.geodesicDistance(t, tempPoint1)
        g = tempPoint1.x
        M = tempPoint1.y
        if (c >= C) p = C
        else d = C
      } else if (2 === l) {
        a = o.geodesicDistance(t, tempPoint1)
        m = o.geodesicDistance(t, tempPoint2)
        c = D ? Math.max(a, m) : Math.min(a, m)
        r = Math.abs(a - C)
        P = Math.abs(m - C)
        if (r < P) {
          g = tempPoint1.x
          M = tempPoint1.y
        } else {
          g = tempPoint2.x
          M = tempPoint2.y
        }
        if (D)
          if (c <= C || (a >= C && m >= C)) p = C
          else d = C
        else if (c >= C) p = C
        else d = C
      }
      i.move2D(g, M)
    }
    return C
  }
  i.move2D(g, M)
  return C
}
function intersectionCircleCircle(t, e, n, o, i, s, c) {
  const a = i.geodesicDistance(t, n)
  if (a > e + o) return 0
  if (a <= Math.abs(o - e)) return 0
  if (a === e + o) {
    i.geodesicPositionAtFractionSFCT(t, n, 0.5, s)
    return 1
  }
  calculateIntersectionPoint(t, e, n, o, i, -180, s, c)
  const m = s.x
  const r = s.y
  calculateIntersectionPoint(t, e, n, o, i, 180, s, c)
  const P = s.x
  const l = s.y
  s.move2D(m, r)
  c.move2D(P, l)
  return 2
}
function calculateIntersectionPoint(t, e, n, o, i, s, c, a) {
  const m = i.forwardAzimuth2D(t, n) * Constants.RAD2DEG
  const r = c
  let P = m
  i.geodesicPositionSFCT(t, e, P, r)
  const l = a
  let D = m + s
  i.geodesicPositionSFCT(t, e, D, l)
  while (Math.abs(D - P) >= 1e-10) {
    const s = (P + D) / 2
    i.geodesicPositionSFCT(t, e, s, tempPoint1)
    const c = undefined
    if (i.geodesicDistance(n, tempPoint1) <= o) {
      r.move2D(tempPoint1)
      P = s
    } else {
      l.move2D(tempPoint1)
      D = s
    }
  }
  i.geodesicPositionAtFractionSFCT(r, l, 0.5, tempPoint1)
  c.move2D(tempPoint1)
}
function calculateBounds(t, e, n, o) {
  const i = 48
  const s = 360 / i
  let c = 0
  o.move2D(e)
  o.width = 0
  o.height = 0
  for (let a = 0; a < i; a++) {
    t.geodesicPositionSFCT(e, n, c, tempPoint1)
    o.setToIncludePoint2D(tempPoint1)
    c -= s
  }
  const a = 10001965.729312126
  const m = Math.cos(e.y * Constants.DEG2RAD)
  const r = Math.sin(e.y * Constants.DEG2RAD)
  const P = t.meridionalArcDistanceOptimized(e.y, m, r)
  if (P - n < -a) {
    o.setToIncludePoint2D(new LLHPoint(null, [0, -90]))
    o.width = 360
  }
  if (P + n > a) {
    o.setToIncludePoint2D(new LLHPoint(null, [0, 90]))
    o.width = 360
  }
}
export function contains2D(t, e, n, o, i) {
  tempPoint1.x = o
  tempPoint1.y = i
  const s = t.geodesicDistance(e, tempPoint1)
  return (
    s - n <=
    Constants.ABSOLUTE_DISTANCE_TOLERANCE +
      Constants.RELATIVE_DISTANCE_TOLERANCE * s
  )
}
export function getBounds(t, e, n) {
  calculateBounds(e, t.center, t.radius, n)
}
export function getFocusPoint(t, e) {
  return t.center
}
export function findCenter(t, e, n, o, i, s) {
  const c = t.x === e.x && t.y === e.y
  const a = t.x === n.x && t.y === n.y
  const m = e.x === n.x && e.y === n.y
  if (c && a && m) {
    i.move2D(t)
    return 0
  } else if (a || c || m) {
    if (c) o.geodesicPositionAtFractionSFCT(t, n, 0.5, i)
    else o.geodesicPositionAtFractionSFCT(t, e, 0.5, i)
    return o.geodesicDistance(t, i)
  }
  const r = o.forwardAzimuth2D(t, e) * Constants.RAD2DEG
  const P = o.forwardAzimuth2D(t, n) * Constants.RAD2DEG
  const l = o.forwardAzimuth2D(e, n) * Constants.RAD2DEG
  const D = o.forwardAzimuth2D(e, t) * Constants.RAD2DEG
  const f = o.forwardAzimuth2D(n, t) * Constants.RAD2DEG
  const p = o.forwardAzimuth2D(n, e) * Constants.RAD2DEG
  const d = Math.abs(normalizeAngle(P - r))
  const u = Math.abs(normalizeAngle(l - D))
  const h = Math.abs(normalizeAngle(f - p))
  let C
  let A
  let g
  if (u >= d && u >= h) {
    C = e
    A = t
    g = n
  } else if (d >= u && d >= h) {
    C = t
    A = e
    g = n
  } else {
    C = n
    A = e
    g = t
  }
  return find(C, A, g, o, i, s)
}
export function translateCircleBy3Points2D(t, e, n, o) {
  const i = t.focusPoint
  const s = e.forwardAzimuth2D(i, t.firstPoint) * Constants.RAD2DEG
  const c = e.forwardAzimuth2D(i, t.secondPoint) * Constants.RAD2DEG
  const a = e.forwardAzimuth2D(i, t.thirdPoint) * Constants.RAD2DEG
  const m = e.geodesicDistance(t.firstPoint, i)
  const r = e.geodesicDistance(t.secondPoint, i)
  const P = e.geodesicDistance(t.thirdPoint, i)
  tempPoint2.x = i.x
  tempPoint2.y = i.y
  tempPoint2.translate(n, o)
  e.geodesicPositionSFCT(tempPoint2, m, s, tempPoint1)
  t.moveFirstPoint2DToCoordinates(tempPoint1.x, tempPoint1.y)
  e.geodesicPositionSFCT(tempPoint2, r, c, tempPoint1)
  t.moveSecondPoint2DToCoordinates(tempPoint1.x, tempPoint1.y)
  e.geodesicPositionSFCT(tempPoint2, P, a, tempPoint1)
  t.moveThirdPoint2DToCoordinates(tempPoint1.x, tempPoint1.y)
}
