import { getQuantityKind } from './QuantityKindRegistry.js'
import { UnitOfMeasure } from './UnitOfMeasure.js'
import { isUndefined } from '../util/Lang.js'
const UNITS_BY_NAME = new Map()
const UNITS_BY_SYMBOL = new Map()
register(['Number', '', 'Dimensionless'])
register(['Second', 's', 'Time'])
register(['Minute', 'min', 'Second', 60, 0])
register(['Hour', 'h', 'Second', 60 * 60, 0])
register(['Day', 'd', 'Second', 24 * 60 * 60, 0])
register(['Kelvin', 'Θ', 'ThermodynamicTemperature'])
register(['Celcius', '°C', 'Kelvin', 1, 273.15])
register(['Fahrenheit', '°F', 'Kelvin', 0.5555555555555556, 255.3722222222222])
register(['Meter', 'm', 'Length'])
register(['FlightLevel', 'FL', 'Meter', 30.48, 0])
register(['Centimeter', 'cm', 'Meter', 0.01, 0])
register(['Foot', 'ft', 'Meter', 0.3048, 0])
register(['FootUSSurvey', 'ftUS', 'Meter', 0.3048006096012192, 0])
register(['Kilometer', 'km', 'Meter', 1e3, 0])
register(['Mile', 'mi', 'Meter', 1609.344, 0])
register(['NauticalMile', 'NM', 'Meter', 1852, 0])
register(['Radian', 'rad', 'PlaneAngle'])
register(['DegreeAngle', '°', 'Radian', 0.017453292519943295, 0])
register(['Pixels', 'px', 'RasterSize'])
const errorPrefix = 'UnitOfMeasureRegistry: load error - '
function registerStandardUnitInternal(e, r, t) {
  if (UNITS_BY_NAME.has(e))
    throw new Error(`${errorPrefix}Unit "${e}" already exists.`)
  if (UNITS_BY_SYMBOL.has(r.toLowerCase()))
    throw new Error(`${errorPrefix}Unit with symbol "${r}" already exists.`)
  let n
  try {
    n = getQuantityKind(t)
  } catch (e) {
    throw new Error(errorPrefix + (e instanceof Error ? e.message : e))
  }
  const i = n.baseQuantityKind
  const o = new UnitOfMeasure(e, r, i, null, 1, 0)
  Object.freeze(o)
  UNITS_BY_NAME.set(e, o)
  UNITS_BY_SYMBOL.set(r.toLowerCase(), o)
}
function register(e) {
  if (3 === e.length) registerStandardUnitInternal(e[0], e[1], e[2])
  else if (5 === e.length)
    registerConversionUnitInternal(e[0], e[1], e[2], e[3], e[4])
  else throw new Error(`Invalid entry in UnitOfMeasure`)
}
export function registerConversionUnitInternal(e, r, t, n, i) {
  if (UNITS_BY_NAME.has(e))
    throw new Error(`${errorPrefix}Unit "${e}" already exists.`)
  if (UNITS_BY_SYMBOL.has(r.toLowerCase()))
    throw new Error(`${errorPrefix}Unit with symbol "${r}" already exists.`)
  if (!UNITS_BY_NAME.has(t))
    throw new Error(
      `${errorPrefix}Trying to register a Conversion Unit "${e}" with respect to a non-existing standardUnit ("${t}").`
    )
  const o = UNITS_BY_NAME.get(t)
  if (
    isUndefined(o) ||
    1 !== o.conversionMultiplier ||
    0 !== o.conversionOffset
  )
    throw new Error(
      `${errorPrefix}Conversion Unit ${e} must be defined with relation to a non-ConversionUnit`
    )
  const s = new UnitOfMeasure(e, r, o.quantityKind, o, n, i)
  Object.freeze(s)
  UNITS_BY_NAME.set(e, s)
  UNITS_BY_SYMBOL.set(r.toLowerCase(), s)
}
export function getUnitOfMeasure(e) {
  if (!UNITS_BY_NAME.has(e)) throw new Error(`Unit "${e}" does not exist.`)
  return UNITS_BY_NAME.get(e)
}
export function getUnitOfMeasureBySymbol(e) {
  e = e.toLocaleLowerCase()
  if (!UNITS_BY_SYMBOL.has(e))
    throw new Error(`Unit with symbol "${e}" does not exist.`)
  return UNITS_BY_SYMBOL.get(e)
}
export function registerStandardUnit(e, r, t) {
  registerStandardUnitInternal(e, r, t)
  return getUnitOfMeasure(e)
}
export function registerConversionUnit(e, r, t, n, i) {
  registerConversionUnitInternal(e, r, t, n, i)
  return getUnitOfMeasure(e)
}
export function getAllUnitsOfMeasure() {
  const e = []
  for (const r of UNITS_BY_NAME.values()) e.push(r)
  return e
}
