import { OutOfBoundsError } from '../error/OutOfBoundsError.js'
import { Constants } from '../util/Constants.js'
import { Hash } from '../util/Hash.js'
import { normalizeLon } from '../util/LonLatCoord.js'
import { Conic } from './Conic.js'
import { ProjectionType } from './ProjectionType.js'
const PI_OVER_4 = 0.25 * Math.PI
const MAX_REVERSE_INTERATIONS = 10
const sLongitudeLimit = 89.5
class CachedValuesForSphere {
  isValid(t) {
    return this.radius === t
  }
  constructor(t, s) {
    this.radius = t
    this.pseudoStandardParallelRad =
      s.getPseudoStandardParallel() * Constants.DEG2RAD
    this.sinAzimuth = Math.sin(s.getAzimuth() * Constants.DEG2RAD)
    this.cosAzimuth = Math.cos(s.getAzimuth() * Constants.DEG2RAD)
    this.n = Math.sin(this.pseudoStandardParallelRad)
    this.r0 = this.radius / Math.tan(this.pseudoStandardParallelRad)
    const a = 1 + 1e-15
    this.maxNorthing = this.calculateMaxNorthing() * a
    this.minNorthing = this.calculateMinNorthing() * a
    this.maxEasting = this.calculateMaxEasting() * a
    this.thetaMaxDegrees = this.n * Math.PI * Constants.RAD2DEG
  }
  calculateMaxNorthing() {
    const t = Math.asin(this.cosAzimuth)
    const s = this.n * Math.PI
    const a = undefined
    return (
      -(
        (this.r0 *
          Math.pow(
            Math.tan(PI_OVER_4 + 0.5 * this.pseudoStandardParallelRad),
            this.n
          )) /
        Math.pow(Math.tan(PI_OVER_4 + 0.5 * t), this.n)
      ) * Math.cos(s)
    )
  }
  calculateMinNorthing() {
    const t = Math.asin(-this.cosAzimuth)
    return (
      (-this.r0 *
        Math.pow(
          Math.tan(PI_OVER_4 + 0.5 * this.pseudoStandardParallelRad),
          this.n
        )) /
      Math.pow(Math.tan(PI_OVER_4 + 0.5 * t), this.n)
    )
  }
  calculateMaxEasting() {
    const t = Math.asin(-this.cosAzimuth * this.cosAzimuth)
    const s =
      (this.r0 *
        Math.pow(
          Math.tan(PI_OVER_4 + 0.5 * this.pseudoStandardParallelRad),
          this.n
        )) /
      Math.pow(Math.tan(PI_OVER_4 + 0.5 * t), this.n)
    return Math.abs(s)
  }
  isThetaInBounds(t) {
    const s = this.thetaMaxDegrees - 180
    const a = 180 - this.thetaMaxDegrees
    const n = normalizeLon(180 - t * Constants.RAD2DEG)
    return !(n > s + 1e-12) || !(n < a - 1e-12)
  }
}
class CachedValuesForEllipsoid {
  isValid(t) {
    return this.a === t.a && this.e === t.e
  }
  constructor(t, s) {
    this.a = t.a
    this.e = t.e
    this.azimuth = s.getAzimuth()
    this.sinAzimuth = Math.sin(this.azimuth * Constants.DEG2RAD)
    this.cosAzimuth = Math.cos(this.azimuth * Constants.DEG2RAD)
    this.pseudoStandardParallelRad =
      s.getPseudoStandardParallel() * Constants.DEG2RAD
    const a = t.e2
    const n = Math.sin(s.getOrigin().y * Constants.DEG2RAD)
    const i = Math.cos(s.getOrigin().y * Constants.DEG2RAD)
    const o = i * i * i * i
    const h = (this.a * Math.sqrt(1 - a)) / (1 - a * n * n)
    this.b = Math.sqrt(1 + (a * o) / (1 - a))
    const e = Math.asin(n / this.b)
    const r = s.getOrigin().y * Constants.DEG2RAD
    const c = Math.tan(PI_OVER_4 + 0.5 * e)
    const l = Math.pow(
      (1 + this.e * n) / (1 - this.e * n),
      this.e * this.b * 0.5
    )
    const d = Math.pow(Math.tan(PI_OVER_4 + 0.5 * r), this.b)
    this.t0 = (c * l) / d
    this.n = Math.sin(this.pseudoStandardParallelRad)
    this.r0 = h / Math.tan(this.pseudoStandardParallelRad)
    const u = 1 + 1e-15
    this.maxNorthing = this.calculateMaxNorthing() * u
    this.minNorthing = this.calculateMinNorthing() * u
    this.maxEasting = this.calculateMaxEasting() * u
    this.thetaMaxDegrees = this.n * Math.PI * Constants.RAD2DEG
  }
  calculateMaxNorthing() {
    const t = Math.asin(this.cosAzimuth)
    const s = this.n * Math.PI
    const a = this.pseudoStandardParallelRad
    const n = undefined
    return (
      -(
        (this.r0 * Math.pow(Math.tan(PI_OVER_4 + 0.5 * a), this.n)) /
        Math.pow(Math.tan(PI_OVER_4 + 0.5 * t), this.n)
      ) * Math.cos(s)
    )
  }
  calculateMinNorthing() {
    const t = Math.asin(-this.cosAzimuth)
    return (
      (-this.r0 *
        Math.pow(
          Math.tan(PI_OVER_4 + 0.5 * this.pseudoStandardParallelRad),
          this.n
        )) /
      Math.pow(Math.tan(PI_OVER_4 + 0.5 * t), this.n)
    )
  }
  calculateMaxEasting() {
    const t = -this.cosAzimuth
    const s = Math.pow(Math.tan(this.azimuth * Constants.DEG2RAD * 0.5), this.b)
    const a = Math.pow(
      (1 + this.e * t) / (1 - this.e * t),
      this.e * this.b * 0.5
    )
    const n = 2 * (Math.atan((this.t0 * s) / a) - PI_OVER_4)
    const i = Math.sin(n)
    const o = this.cosAzimuth * i
    const h = Math.asin(o)
    const e =
      (this.r0 *
        Math.pow(
          Math.tan(PI_OVER_4 + 0.5 * this.pseudoStandardParallelRad),
          this.n
        )) /
      Math.pow(Math.tan(PI_OVER_4 + 0.5 * h), this.n)
    return Math.abs(e)
  }
  isThetaInBounds(t) {
    const s = this.thetaMaxDegrees - 180
    const a = 180 - this.thetaMaxDegrees
    const n = normalizeLon(180 - t * Constants.RAD2DEG)
    return !(n > s + 1e-12) || !(n < a - 1e-12)
  }
}
function inWorldBoundsOnSphere(t, s, a, n) {
  const i = Math.sin(s)
  const o = Math.cos(s)
  const h = Math.cos(a)
  const e = n.cosAzimuth * i + n.sinAzimuth * o * h
  const r = Math.asin(e)
  if (Math.abs(r - t) > 1e-5) throw new OutOfBoundsError()
}
export class Krovak extends Conic {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0
    let s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
    let a = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 90
    let n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 45
    super()
    this._cachedValuesForSphere = null
    this._cachedValuesForEllipsoid = null
    this.setOriginLon(s)
    this.setOriginLat(t)
    this._azimuth = a
    this._pseudoStandardParallel = n
    this._pseudoStandardParallelRad =
      this._pseudoStandardParallel * Constants.DEG2RAD
    this._hash = 0
    this.calculateCachedValues()
  }
  getAzimuth() {
    return this._azimuth
  }
  setAzimuth(t) {
    this._azimuth = t
    this.calculateCachedValues()
  }
  getPseudoStandardParallel() {
    return this._pseudoStandardParallel
  }
  setPseudoStandardParallel(t) {
    this._pseudoStandardParallel = t
    this._pseudoStandardParallelRad =
      this._pseudoStandardParallel * Constants.DEG2RAD
    this.calculateCachedValues()
  }
  isAllInBounds() {
    return false
  }
  geodetic2cartesianOnEllipsoidSFCT(t, s, a) {
    if (!this.inLonLatBounds(t)) throw new OutOfBoundsError()
    const n = this.getCachedValuesForEllipsoid(s)
    const i = t.y * Constants.DEG2RAD
    const o = Math.sin(t.y * Constants.DEG2RAD)
    const h = Math.pow(Math.tan(0.5 * i + PI_OVER_4), n.b)
    const e = Math.pow((1 + n.e * o) / (1 - n.e * o), n.e * n.b * 0.5)
    const r = 2 * (Math.atan((n.t0 * h) / e) - PI_OVER_4)
    const c = n.b * normalizeLon(this.getOrigin().x - t.x) * Constants.DEG2RAD
    const l = Math.sin(r)
    const d = Math.cos(r)
    const u = Math.sin(c)
    const M = Math.cos(c)
    const g = n.cosAzimuth * l + n.sinAzimuth * d * M
    const p = Math.asin(g)
    const E = Math.cos(p)
    const m = (d * u) / E
    const _ = (n.cosAzimuth * g - l) / (n.sinAzimuth * E)
    const P = Math.atan2(m, _)
    const R = n.n * P
    const O =
      (n.r0 *
        Math.pow(
          Math.tan(PI_OVER_4 + 0.5 * this._pseudoStandardParallelRad),
          n.n
        )) /
      Math.pow(Math.tan(PI_OVER_4 + 0.5 * p), n.n)
    const D = O * Math.cos(R)
    const A = O * Math.sin(R)
    a.x = -A
    a.y = -D
  }
  cartesian2geodeticOnEllipsoidSFCT(t, s, a) {
    const n = this.getCachedValuesForEllipsoid(s)
    const { t: i, u: o, v: h } = this.getCartesian2GeodeticParameters(t, n)
    let e = o
    for (let t = 0; t < MAX_REVERSE_INTERATIONS; t++) {
      const t = Math.sin(e)
      const s = undefined
      const a = undefined
      const i = undefined
      const h =
        Math.pow(n.t0, -1 / n.b) *
        Math.pow(Math.tan(0.5 * o + PI_OVER_4), 1 / n.b) *
        Math.pow((1 + n.e * t) / (1 - n.e * t), 0.5 * n.e)
      const r = 2 * (Math.atan(h) - PI_OVER_4)
      const c = undefined
      if (Math.abs(r - e) < 1e-14) break
      e = r
    }
    const r = this.getOrigin().x * Constants.DEG2RAD - h / n.b
    const c = normalizeLon(r * Constants.RAD2DEG)
    const l = e * Constants.RAD2DEG
    a.move2D(c, l)
    this.verifyInWorldBoundsEllipsoid(i, e, h, n)
  }
  verifyInWorldBoundsEllipsoid(t, s, a, n) {
    const i = Math.sin(s)
    const o = Math.pow(Math.tan(0.5 * s + PI_OVER_4), n.b)
    const h = Math.pow((1 + n.e * i) / (1 - n.e * i), n.e * n.b * 0.5)
    const e = 2 * (Math.atan((n.t0 * o) / h) - PI_OVER_4)
    const r = Math.sin(e)
    const c = Math.cos(e)
    const l = Math.cos(a)
    const d = n.cosAzimuth * r + n.sinAzimuth * c * l
    const u = Math.asin(d)
    if (Math.abs(u - t) > 1e-5) throw new OutOfBoundsError()
  }
  inWorldBoundsOnEllipsoid(t, s) {
    const a = this.getCachedValuesForEllipsoid(s)
    const n = t.x
    const i = t.y
    return (
      n >= -a.maxEasting &&
      n <= a.maxEasting &&
      i >= a.minNorthing &&
      i <= a.maxNorthing
    )
  }
  world2DEditableBoundsOnEllipsoidSFCT(t, s) {
    const a = this.getCachedValuesForEllipsoid(t)
    s.translate2D(-a.maxEasting, a.minNorthing)
    s.width = 2 * a.maxEasting
    s.height = a.maxNorthing - a.minNorthing
  }
  inLonLatBounds(t) {
    return this.inLonBounds(t.x)
  }
  inLonBounds(t) {
    const s = normalizeLon(this.getOriginLon() - t)
    return s > -sLongitudeLimit - 1e-14 && s < sLongitudeLimit + 1e-14
  }
  boundaryLons(t) {
    return [
      [
        normalizeLon(this.getOriginLon() - sLongitudeLimit + 1e-12),
        normalizeLon(this.getOriginLon() + sLongitudeLimit - 1e-12),
      ],
    ]
  }
  boundaryLats(t) {
    if (this.inLonBounds(t)) return [[-90, 90]]
    else return []
  }
  geodetic2cartesianOnSphereSFCT(t, s, a) {
    if (!this.inLonLatBounds(t)) throw new OutOfBoundsError()
    const n = this.getCachedValuesForSphere(s)
    const i = normalizeLon(this.getOrigin().x - t.x) * Constants.DEG2RAD
    const o = Math.sin(t.y * Constants.DEG2RAD)
    const h = Math.cos(t.y * Constants.DEG2RAD)
    const e = Math.sin(i)
    const r = Math.cos(i)
    const c = n.cosAzimuth * o + n.sinAzimuth * h * r
    const l = Math.asin(c)
    const d = Math.cos(l)
    const u = (h * e) / d
    const M = (n.cosAzimuth * c - o) / (n.sinAzimuth * d)
    const g = Math.atan2(u, M)
    const p = n.n * g
    const E =
      (n.r0 *
        Math.pow(
          Math.tan(PI_OVER_4 + 0.5 * this._pseudoStandardParallelRad),
          n.n
        )) /
      Math.pow(Math.tan(PI_OVER_4 + 0.5 * l), n.n)
    const m = E * Math.cos(p)
    const _ = E * Math.sin(p)
    a.move2D(-_, -m)
  }
  cartesian2geodeticOnSphereSFCT(t, s, a) {
    const n = this.getCachedValuesForSphere(s)
    const { t: i, u: o, v: h } = this.getCartesian2GeodeticParameters(t, n)
    const e = this.getOrigin().x * Constants.DEG2RAD - h
    const r = normalizeLon(e * Constants.RAD2DEG)
    const c = o * Constants.RAD2DEG
    a.move2D(r, c)
    inWorldBoundsOnSphere(i, o, h, n)
  }
  getCartesian2GeodeticParameters(t, s) {
    const a = -t.x
    const n = -t.y
    const i = Math.sqrt(a * a + n * n)
    const o = Math.atan2(a, n)
    if (!s.isThetaInBounds(o)) throw new OutOfBoundsError()
    const h = o / s.n
    const e = Math.pow(s.r0 / i, 1 / s.n)
    const r = Math.tan(PI_OVER_4 + 0.5 * this._pseudoStandardParallelRad)
    const c = 2 * (Math.atan(e * r) - PI_OVER_4)
    const l = Math.sin(c)
    const d = Math.cos(c)
    const u = Math.sin(h)
    const M = Math.cos(h)
    const g = s.cosAzimuth * l - s.sinAzimuth * d * M
    const p = Math.asin(g)
    const E = Math.cos(p)
    let m
    if (E < 1e-10) m = 0
    else {
      const t = (d * u) / E
      m = Math.asin(t)
    }
    return { t: c, u: p, v: m }
  }
  inWorldBoundsOnSphere(t, s) {
    const a = this.getCachedValuesForSphere(s)
    const n = t.x
    const i = t.y
    return (
      n >= -a.maxEasting &&
      n <= a.maxEasting &&
      i >= a.minNorthing &&
      i <= a.maxNorthing
    )
  }
  cartesianBoundsOnEllipsoidSFCT(t, s) {
    const a = this.getCachedValuesForEllipsoid(t)
    s.translate2D(-a.maxEasting, a.minNorthing)
    s.width = 2 * a.maxEasting
    s.height = a.maxNorthing - a.minNorthing
  }
  cartesianBoundsOnSphereSFCT(t, s) {
    const a = this.getCachedValuesForSphere(t)
    s.translate2D(-a.maxEasting, a.minNorthing)
    s.width = 2 * a.maxEasting
    s.height = a.maxNorthing - a.minNorthing
  }
  toString() {
    return (
      'Krovak_' +
      this.getOriginLon().toFixed(5) +
      '_' +
      this.getOriginLat().toFixed(5) +
      '_' +
      this.getAzimuth().toFixed(5) +
      '_' +
      this.getPseudoStandardParallel().toFixed(5)
    )
  }
  getCachedValuesForEllipsoid(t) {
    let s = this._cachedValuesForEllipsoid
    if (null == s || !s.isValid(t)) {
      s = new CachedValuesForEllipsoid(t, this)
      this._cachedValuesForEllipsoid = s
    }
    return s
  }
  getCachedValuesForSphere(t) {
    let s = this._cachedValuesForSphere
    if (null == s || !s.isValid(t)) {
      s = new CachedValuesForSphere(t, this)
      this._cachedValuesForSphere = s
    }
    return s
  }
  calculateCachedValues() {
    this._cachedValuesForEllipsoid = null
    this._cachedValuesForSphere = null
    const t = new Hash()
    this._hash = t
      .appendDouble(this.getOriginLon())
      .appendDouble(this.getOriginLat())
      .appendDouble(this.getAzimuth())
      .appendDouble(this.getPseudoStandardParallel())
      .appendUInt32(this.TYPE)
      .getHashCode()
  }
  encode() {
    return {
      type: 'Krovak',
      firstParallel: this.getFirstParallel(),
      secondParallel: this.getSecondParallel(),
      originLon: this.getOriginLon(),
      originLat: this.getOriginLat(),
      azimuth: this.getAzimuth(),
      pseudoStandardParallel: this.getPseudoStandardParallel(),
    }
  }
  get TYPE() {
    return ProjectionType.KROVAK + ProjectionType.CONIC
  }
  hashCode(t) {
    t.appendUInt32(this._hash)
  }
}
