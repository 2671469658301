import { isArray } from '../../util/Lang.js'
import { MemoryStoreBase } from './MemoryStoreBase.js'
export class MemoryLinearStore extends MemoryStoreBase {
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    super()
    const t = isArray(e.data) ? e.data : []
    this._cache = new Map()
    t.forEach((e) => {
      e.id = this.getValidFeatureId(e.id)
      this._cache.set(e.id, e)
    })
  }
  get(e) {
    return this._cache.get(e)
  }
  clear() {
    this._cache.forEach((e) =>
      this._eventedSupport.emitStoreChangedEvent('remove', e, e.id)
    )
    this._cache.clear()
    return true
  }
  getIterable() {
    return this._cache.values()
  }
  has(e) {
    return this._cache.has(e)
  }
  internalUpdate(e) {
    this._cache.set(e.id, e)
    this._eventedSupport.emitStoreChangedEvent('update', e, e.id)
    return e.id
  }
  internalAdd(e) {
    e.id = this.getValidFeatureId(e.id)
    this._cache.set(e.id, e)
    this._eventedSupport.emitStoreChangedEvent('add', e, e.id)
    return e.id
  }
  internalRemove(e) {
    const t = this._cache.get(e)
    if (t && this._cache.delete(e)) return t
    return null
  }
  get size() {
    return this._cache.size
  }
}
