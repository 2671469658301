import { Constants } from '../util/Constants.js'
export function forwardAzimuth2DLL(t, n, o, s, a, c) {
  const r = (s - t) * Constants.DEG2RAD
  const h = Math.cos(r)
  const i = Math.sin(r)
  const D = Math.atan2(n * h - o * (a / c), i)
  const M = Math.PI / 2 + D
  return M < 0 ? M + 2 * Math.PI : M
}
export function forwardAzimuth2D(t, n) {
  const o = t.y * Constants.DEG2RAD
  const s = n.y * Constants.DEG2RAD
  const a = Math.sin(o)
  const c = Math.sin(s)
  const r = Math.cos(o)
  const h = Math.cos(s)
  return forwardAzimuth2DLL(t.x, a, r, n.x, c, h)
}
