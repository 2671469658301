import { buildCache } from '../util/Cacher.js'
import { Shape } from './Shape.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
export class Ellipse extends Shape {
  _cache = buildCache()
  get type() {
    return ShapeType.ELLIPSE
  }
  translate2D(e, t) {
    this.center.translate2D(e, t)
    this._cache.invalidate()
  }
  move2DToCoordinates(e, t) {
    this.center.move2DToCoordinates(e, t)
    this._cache.invalidate()
  }
  move2DToPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    this.move2DToCoordinates(e.x, e.y)
  }
  toString() {
    return `Ellipse[center${this.center.toString(true)}, a[${this.a}], b[${
      this.b
    }], rotationAzimuth[${this.rotationAzimuth}]]`
  }
  hashCode(e) {
    e.appendUInt32(this.type)
    this.center.hashCode(e)
    e.appendDouble(this.a)
    e.appendDouble(this.b)
    e.appendDouble(this.rotationAzimuth)
  }
  equals(e) {
    if (this === e) return true
    if (!ShapeUtil.referencesEqual(this.reference, e.reference)) return false
    return (
      this.type === e.type &&
      this.a === e.a &&
      this.b === e.b &&
      this.rotationAzimuth === e.rotationAzimuth &&
      this.center.equals(e.center)
    )
  }
  invalidate() {
    this._cache.invalidate()
  }
}
export function isEllipse(e) {
  return ShapeType.contains(ShapeType.ELLIPSE, e.type)
}
