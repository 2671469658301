import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Invalidation } from '../../util/Invalidation.js'
import { HandleEventResult } from './HandleEventResult.js'
import { EventedSupport } from '../../util/EventedSupport.js'
export class Controller extends Invalidation {
  constructor() {
    super()
    this.__map = null
    this._baseEventedSupport = new EventedSupport(
      ['Activated', 'Deactivated'],
      true
    )
    this._cursorValue = null
    this._cursorHandle = null
  }
  onActivate(r) {
    if (this.__map && this.__map.controller === this)
      this.__map.controller = null
    this.__map = r
    if (this._cursorValue)
      this._cursorHandle = r.cursorManager.addCursor(this._cursorValue)
    this._baseEventedSupport.emit('Activated', r)
  }
  onDeactivate(r) {
    this.__map = null
    this._baseEventedSupport.emit('Deactivated', r)
    this._cursorHandle?.remove()
    return true
  }
  onDraw(r) {}
  onDrawLabel(r) {}
  onGestureEvent(r) {
    return HandleEventResult.EVENT_IGNORED
  }
  onKeyEvent(r) {
    return HandleEventResult.EVENT_IGNORED
  }
  invalidate() {
    super.invalidate()
  }
  get map() {
    return this.__map
  }
  set map(r) {
    throw new ProgrammingError('map property is not mutable')
  }
  get cursor() {
    return this._cursorHandle?.cursor ?? null
  }
  set cursor(r) {
    if (r !== this._cursorValue) {
      if (this._cursorHandle) {
        this._cursorHandle.remove()
        this._cursorHandle = null
      }
      this._cursorValue = r
      if (this.map && r)
        this._cursorHandle = this.map.cursorManager.addCursor(r)
    }
  }
  on(r, t, e) {
    if ('Activated' === r || 'Deactivated' === r)
      return this._baseEventedSupport.on(r, t, e)
    return super.on(r, t, e)
  }
}
