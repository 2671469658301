import { Photon } from '../../../../gen/photon/photon_painter.js'
import { ShapeType } from '../../../../shape/ShapeType.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
import { isAboveGroundReference } from './ZStyleUtil.js'
export function hasAreaShape(e) {
  if (
    ShapeType.contains(e.type, ShapeType.POINT) ||
    ShapeType.contains(e.type, ShapeType.POLYLINE) ||
    ShapeType.contains(e.type, ShapeType.PARALLEL) ||
    ShapeType.contains(e.type, ShapeType.MERIDIAN) ||
    ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC) ||
    ShapeType.contains(e.type, ShapeType.GRID) ||
    ShapeType.contains(e.type, ShapeType.ARC)
  )
    return false
  if (ShapeType.contains(e.type, ShapeType.EXTRUDED_SHAPE))
    return hasAreaShape(e.baseShape)
  if (ShapeType.contains(e.type, ShapeType.SHAPE_LIST)) {
    for (let p = 0; p < e.shapeCount; p++)
      if (hasAreaShape(e.getShape(p))) return true
    return false
  }
  return true
}
export function hasLineTypeShape(e) {
  if (ShapeType.contains(e.type, ShapeType.POINT)) return false
  if (ShapeType.contains(e.type, ShapeType.SHAPE_LIST)) {
    for (let p = 0; p < e.shapeCount; p++)
      if (hasLineTypeShape(e.getShape(p))) return true
    return false
  }
  return true
}
export function hasFillTypeShape(e, p) {
  if (ShapeType.contains(e.type, ShapeType.POLYGON) && e.pointCount < 3)
    return false
  if (
    ShapeType.contains(e.type, ShapeType.EXTRUDED_SHAPE) &&
    hasLineTypeShape(e.baseShape) &&
    p
  )
    return true
  return hasAreaShape(e)
}
export function isExtrudedShape(e) {
  return ShapeType.contains(e.type, ShapeType.EXTRUDED_SHAPE)
}
export function toPhotonDrapeTarget(e) {
  switch (e) {
    case DrapeTarget.NOT_DRAPED:
      return Photon.DrapeTarget.NotDraped
    case DrapeTarget.TERRAIN:
      return Photon.DrapeTarget.Terrain
    case DrapeTarget.MESH:
      return Photon.DrapeTarget.Mesh
    case DrapeTarget.ALL:
      return Photon.DrapeTarget.All
  }
}
export const PhotonCommandUtil = {
  isAboveGroundReference: isAboveGroundReference,
  isExtrudedShape: isExtrudedShape,
  hasAreaShape: hasAreaShape,
  hasLineTypeShape: hasLineTypeShape,
  hasFillTypeShape: hasFillTypeShape,
  toPhotonDrapeTarget: toPhotonDrapeTarget,
}
