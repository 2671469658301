import { TouchDistance } from './TouchDistance.js'
import { OcclusionMode } from './OcclusionMode.js'
import { getWorldInteractionBounds } from './HTML5DrawCommandProto.js'
export class CompositeDrawCommand {
  worldBounds = null
  __paintIndex = 0
  zOrder = 0
  _bounds = null
  constructor(t) {
    this._commands = t
    this._dominantCommandIndex = -1
    this._domainShape = null
    this._bounds = null
    this._updateBounds()
  }
  set domainShape(t) {
    this._domainShape = t
  }
  get domainShape() {
    return this._domainShape
  }
  set bounds(t) {
    this._bounds = t
  }
  get bounds() {
    return this._bounds
  }
  _updateBounds() {
    const t = this._commands[0]
    if (0 === this._commands.length || !t.bounds) return
    const n = t.bounds.copy()
    let s = n.width * n.height
    let e = s
    let o = 0
    for (let t = 1, i = this._commands.length; t < i; t++) {
      const i = this._commands[t].bounds
      if (i) {
        s = i.width * i.height
        if (s > e) {
          o = t
          e = s
        }
        let a = n.x
        let m = a + n.width
        let r = n.y
        let d = r + n.height
        const h = i.x
        const c = h + i.width
        const l = i.y
        const u = l + i.height
        if (h < a) a = h
        if (c > m) m = c
        if (l < r) r = l
        if (u > d) d = u
        n.setTo2D(a, m - a, r, d - r)
      }
    }
    this._dominantCommandIndex = o
    this.bounds = n
  }
  isReady() {
    for (let t = 0; t < this._commands.length; t++)
      if (!this._commands[t].isReady()) return false
    return true
  }
  draw(t, n, s) {
    for (let e = 0; e < this._commands.length; e++)
      this._commands[e].draw(t, n, s)
  }
  findInteractingShapes(t, n, s, e, o, i) {
    const a = []
    for (const m of this._commands)
      a.push(...m.findInteractingShapes(t, n, s, e, o, i))
    return a
  }
  interacts(t, n, s) {
    for (let e = 0; e < this._commands.length; e += 1)
      if (this._commands[e].interacts(t, n, s)) return true
    return false
  }
  strokeInteracts(t, n, s) {
    for (let e = 0; e < this._commands.length; e += 1)
      if (this._commands[e].strokeInteracts(t, n, s)) return true
    return false
  }
  interactsView(t, n, s, e, o) {
    const i = getWorldInteractionBounds(t, n, s, e)
    return this.interacts(i, e, o)
  }
  strokeInteractsView(t, n, s, e, o) {
    const i = getWorldInteractionBounds(t, n, s, e)
    return this.strokeInteracts(i, e, o)
  }
  mapAnchorPointSFCT(t, n) {
    if (-1 === this._dominantCommandIndex) return false
    if (this._commands.length > 0)
      return this._commands[this._dominantCommandIndex].mapAnchorPointSFCT(t, n)
    return false
  }
  isValidLocation(t, n) {
    if (-1 === this._dominantCommandIndex) return false
    for (let s = 0, e = this._commands.length; s < e; s++)
      if (!this._commands[s].isValidLocation(t, n)) return false
    return true
  }
  viewPointSFCT(t, n, s) {
    if (-1 === this._dominantCommandIndex) return false
    if (this._commands.length > 0)
      return this._commands[this._dominantCommandIndex].viewPointSFCT(t, n, s)
    return false
  }
  mapAnchorPointsSFCT(t, n, s) {
    if (-1 === this._dominantCommandIndex) return
    this._commands[this._dominantCommandIndex].mapAnchorPointsSFCT(t, n, s)
    for (let e = 0, o = this._commands.length; e < o; e++)
      if (e !== this._dominantCommandIndex)
        this._commands[e].mapAnchorPointsSFCT(t, n, s)
  }
  getRepeatingAnchorListSFCT(t, n, s) {
    for (let e = 0, o = this._commands.length; e < o; e++)
      this._commands[e].getRepeatingAnchorListSFCT(t, n, s)
  }
  bindDomainShape(t) {
    this._domainShape = t
  }
  bindWorldBounds(t) {
    for (let n = 0; n < this._commands.length; n += 1)
      this._commands[n].bindWorldBounds(t)
  }
  findDrawCommand(t, n) {
    if (
      n &&
      this.domainShape &&
      this.domainShape.type === n &&
      this.domainShape.equals(t)
    )
      return this
    const s = this._commands
    for (let e = 0, o = s.length; e < o; e++) {
      const o = s[e].findDrawCommand(t, n)
      if (o) return o
    }
    return null
  }
  isEquivalent(t) {
    if (
      !(t instanceof CompositeDrawCommand) ||
      this._commands.length !== t._commands.length
    )
      return false
    const n = t._commands
    const s = n.length
    for (let t = 0; t < s; t++) {
      let e = false
      for (let o = 0; o < s; o++) {
        const s = undefined
        if (this._commands[o].isEquivalent(n[t])) {
          e = true
          break
        }
      }
      if (!e) return false
    }
    return true
  }
  hasSameGeometry(t) {
    return false
  }
  clear() {
    this._commands.length = 0
    this._dominantCommandIndex = -1
  }
  add(t) {
    this._commands.push(t)
  }
  getCommands() {
    return this._commands
  }
  getMapDistanceSquared(t, n, s, e) {
    const o = new TouchDistance()
    e.sqrDistanceToCenter = 1 / 0
    e.sqrDistanceToEdge = 1 / 0
    for (let i = 0; i < this._commands.length; i++) {
      this._commands[i].getMapDistanceSquared(t, n, s, o)
      if (o.sqrDistanceToEdge < e.sqrDistanceToEdge) {
        e.sqrDistanceToEdge = o.sqrDistanceToEdge
        e.sqrDistanceToCenter = o.sqrDistanceToCenter
      }
    }
  }
  getMaximumPadding(t) {
    let n = 0
    for (let s = 0; s < this._commands.length; s += 1)
      n = Math.max(n, this._commands[s].getMaximumPadding(t))
    return n
  }
  getMaximumWorldMargin(t) {
    let n = 0
    for (let s = 0; s < this._commands.length; s += 1)
      n = Math.max(n, this._commands[s].getMaximumWorldMargin(t))
    return n
  }
  getOcclusionHintForLabel() {
    for (let t = 0; t < this._commands.length; t++) {
      const n = this._commands[t].getOcclusionHintForLabel()
      if (
        n === OcclusionMode.ALWAYS_VISIBLE ||
        n === OcclusionMode.OCCLUDED_ONLY
      )
        return OcclusionMode.ALWAYS_VISIBLE
    }
    return OcclusionMode.VISIBLE_ONLY
  }
}
