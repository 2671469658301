import { AbortError } from '../../error/AbortError.js'
import { Deferred } from '../../util/Deferred.js'
import { EventedSupport } from '../../util/EventedSupport.js'
import { isDefined } from '../../util/Lang.js'
import { FrameScheduler } from '../strategy/FrameScheduler.js'
export class AnimationManager {
  constructor() {}
  static _animations = new Map()
  static _deferreds = new Map()
  static _loopingAnimations = new Set()
  static _isRunning = false
  static _eventedSupport = new EventedSupport()
  static get animationCount() {
    return this._animations.size
  }
  static putAnimation(t, i, n, e) {
    this.removeAnimation(t)
    if (!this._isRunning) this._start()
    const s = isDefined(this._animations.get(t), true)
    this._animations.set(t, i)
    if (!s) this._eventedSupport.emit('animationCount', this.animationCount)
    if (n) this._loopingAnimations.add(t)
    i.startTime = performance.now()
    i.onStart()
    const r = () => {}
    const a = new Deferred()
    a.promise.catch(r)
    this._deferreds.set(t, a)
    if (e)
      e.addEventListener('abort', () => {
        if (this._animations.get(t) === i) this.removeAnimation(t)
      })
    return a.promise
  }
  static _start() {
    if (this._isRunning) return
    this._isRunning = true
    this._eventedSupport.emit('running', this._isRunning)
    this._requestAnimationFrameHandle = FrameScheduler.requestAnimationFrame(
      this._tick.bind(this)
    )
  }
  static _stop() {
    if (this._requestAnimationFrameHandle) {
      FrameScheduler.cancelAnimationFrame(this._requestAnimationFrameHandle)
      this._requestAnimationFrameHandle = 0
    }
    this._isRunning = false
  }
  static isRunning() {
    return this._isRunning
  }
  static getAnimation(t) {
    return this._animations.get(t)
  }
  static getAnimationPromise(t) {
    const i = this._deferreds.get(t)
    if (i) return i.promise
    return
  }
  static removeAnimation(t) {
    const i = this.getAnimation(t)
    if (i) {
      this._animations.delete(t)
      this._eventedSupport.emit('animationCount', this.animationCount)
      if (this._isRunning && 0 === this.animationCount) this._stop()
      i.onStop()
      const n = this._deferreds.get(t)
      if (n) {
        this._deferreds.delete(t)
        this._loopingAnimations.delete(t)
        if (i.hasFinished) n.resolve()
        else n.reject(new AbortError('Animation aborted by AnimationManager'))
      }
    }
  }
  static _tick() {
    const t = performance.now()
    this._requestAnimationFrameHandle = 0
    const i = []
    const n = this
    this._animations.forEach((e, s, r) => {
      const a = Math.max(0, Math.min(e.duration, t - e.startTime))
      const o = 0 !== e.duration ? a / e.duration : 1
      if (a >= e.duration)
        if (n._loopingAnimations.has(s)) {
          e.onStop()
          e.startTime = performance.now()
          e.onStart()
        } else {
          e.update(1)
          e.hasFinished = true
          i.push(s)
        }
      else e.update(o)
      e.elapsedTime = a
    })
    for (let t = 0; t < i.length; t++) this.removeAnimation(i[t])
    if (this.isRunning() && 0 === this.animationCount) this._stop()
    else if (this.isRunning())
      this._requestAnimationFrameHandle = FrameScheduler.requestAnimationFrame(
        this._tick.bind(this)
      )
  }
  on(t, i, n) {
    return AnimationManager._eventedSupport.on(t, i, n)
  }
}
