import {
  createCartesianGeodesy,
  createEllipsoidalGeodesy,
} from '../../../geodesy/GeodesyFactory.js'
import { ReferenceType } from '../../../reference/ReferenceType.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import {
  EVENT_HANDLED,
  isRequestDeactivation,
} from '../../controller/HandleEventResult.js'
import { CompositeEditHandle } from './CompositeEditHandle.js'
import { PointDragHandle } from './PointDragHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { scaleIconStyle } from './PointHandle.js'
import { isDefined, isUndefined } from '../../../util/Lang.js'
export class SinglePointInsertHandle extends PointDragHandle {
  constructor(t, e, n, i) {
    super(
      () => {
        if (!this._inserted) return this._pointToInsert
        else {
          const t = undefined
          return this._parent.pointList.getPoint(this._index)
        }
      },
      (t) => {
        n.pointList.move2DPoint(this._index, t.x, t.y)
        this.emitEditShapeEvent(n.pointList, EditShapeStatus.IN_PROGRESS)
      },
      { handleIconStyle: i }
    )
    this._index = t
    this._pointToInsert = e
    this._parent = n
    this._inserted = false
  }
  get inserted() {
    return this._inserted
  }
  get pointToInsert() {
    return this._pointToInsert
  }
  get index() {
    return this._index
  }
  getDefaultHandleIconStyle(t) {
    return scaleIconStyle(t.settings.styles.handleIconStyle, 0.6)
  }
  activate(t, e) {
    const n = this._parent.pointList
    n.insertPoint(this._index, this._pointToInsert)
    this._inserted = true
    this._parent.newlyInsertedPointHandle = this
    this.emitEditShapeEvent(n, EditShapeStatus.IN_PROGRESS)
    return EVENT_HANDLED
  }
  deactivate(t, e) {
    this._parent.newlyInsertedPointHandle = null
    return super.deactivate(t, e)
  }
  drag(t, e, n) {
    const i = this._parent.pointList
    i.move2DPoint(this._index, t.x, t.y)
    this.emitEditShapeEvent(i, EditShapeStatus.IN_PROGRESS)
  }
}
export class PointListInsertHandle extends CompositeEditHandle {
  constructor(t, e, n) {
    super([], { cascading: false })
    this._newlyInsertedPointHandle = null
    this._newlyInsertedPointHandleListener = null
    this._pointList = t
    this._maxPointCount = e
    this._iconStyleDefinedInConstructor = isDefined(n)
    this._handleIconStyle = isUndefined(n) ? null : n
    const i = t.reference
    if (i.referenceType === ReferenceType.GEODETIC)
      this._geodesy = createEllipsoidalGeodesy(i)
    else this._geodesy = createCartesianGeodesy(i)
    this.update()
  }
  get pointList() {
    return this._pointList
  }
  get maximumPointCount() {
    return this._maxPointCount
  }
  get handleIconStyle() {
    return this._handleIconStyle
  }
  get geodesy() {
    return this._geodesy
  }
  set newlyInsertedPointHandle(t) {
    if (t && this._newlyInsertedPointHandle) {
      this._newlyInsertedPointHandleListener?.remove()
      this._newlyInsertedPointHandleListener = null
    }
    this._newlyInsertedPointHandle = t
    if (t)
      this._newlyInsertedPointHandleListener = t.on('EditShape', (t) => {
        this.eventedSupport.emit('EditShape', t)
      })
  }
  onGestureEvent(t, e) {
    let n
    if (this._newlyInsertedPointHandle) {
      n = this._newlyInsertedPointHandle.onGestureEvent(t, e)
      if (!isRequestDeactivation(n)) return n
    }
    const i = super.onGestureEvent(t, e)
    return n ? n | i : i
  }
  shouldUpdateHandles() {
    return true
  }
  createInsertionHandles() {
    const t = []
    for (let e = 0; e < this.pointList.pointCount - 1; e += 1)
      t.push(
        this.createHalfwayInsertionHandle(
          this.pointList.getPoint(e),
          this.pointList.getPoint(e + 1),
          e + 1
        )
      )
    if (this.pointList.type === ShapeType.POLYGON)
      t.push(
        this.createHalfwayInsertionHandle(
          this.pointList.getPoint(this.pointList.pointCount - 1),
          this.pointList.getPoint(0),
          this.pointList.pointCount
        )
      )
    return t
  }
  update() {
    if (
      -1 !== this.maximumPointCount &&
      this.pointList.pointCount >= this.maximumPointCount
    ) {
      this.activeHandleIndex = -1
      this.handles = []
      return
    }
    if (this.shouldUpdateHandles()) this.handles = this.createInsertionHandles()
  }
  createHalfwayInsertionHandle(t, e, n) {
    const i = this.geodesy.interpolate(t, e, 0.5)
    i.z = (t.z + e.z) / 2
    const s = this._iconStyleDefinedInConstructor
      ? this._handleIconStyle
      : void 0
    return new SinglePointInsertHandle(n, i, this, s)
  }
}
