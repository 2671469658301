import { Affine2D } from '../transformation/Affine2D.js'
import { Constants } from '../util/Constants.js'
export class CameraPosition2D {
  constructor(i, t, r, s, n, e, o) {
    this._viewOriginX = i
    this._viewOriginY = t
    this._worldOriginX = r
    this._worldOriginY = s
    this._scaleX = n
    this._scaleY = e
    this._rotation = o || 0
  }
  copy() {
    return new CameraPosition2D(
      this._viewOriginX,
      this._viewOriginY,
      this._worldOriginX,
      this._worldOriginY,
      this._scaleX,
      this._scaleY,
      this._rotation
    )
  }
  setScale(i, t) {
    this._scaleX = i
    this._scaleY = t
  }
  getTranslationX() {
    return Affine2D.translationXFromOrigins(
      this.viewOriginX,
      this.worldOriginX,
      this.scaleX
    )
  }
  getTranslationY() {
    return Affine2D.translationYFromOrigins(
      this.viewOriginY,
      this.worldOriginY,
      this.scaleY
    )
  }
  resetWithCameraPosition(i) {
    this.reset(
      i.viewOriginX,
      i.viewOriginY,
      i.worldOriginX,
      i.worldOriginY,
      i.scaleX,
      i.scaleY,
      i.rotation
    )
  }
  equals(i) {
    return (
      this._viewOriginX === i.viewOriginX &&
      this._viewOriginY === i.viewOriginY &&
      Math.abs(this._worldOriginX - i.worldOriginX) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      Math.abs(this._worldOriginY - i.worldOriginY) <=
        Constants.ABSOLUTE_DISTANCE_TOLERANCE &&
      this._scaleX === i._scaleX &&
      this._scaleY === i._scaleY &&
      this._rotation === i._rotation
    )
  }
  reset(i, t, r, s, n, e, o) {
    this._viewOriginX = i
    this._viewOriginY = t
    this._worldOriginX = r
    this._worldOriginY = s
    this._scaleX = n
    this._scaleY = e
    this._rotation = o || 0
  }
  get viewOriginX() {
    return this._viewOriginX
  }
  set viewOriginX(i) {
    this._viewOriginX = i
  }
  get viewOriginY() {
    return this._viewOriginY
  }
  set viewOriginY(i) {
    this._viewOriginY = i
  }
  get worldOriginX() {
    return this._worldOriginX
  }
  get worldOriginY() {
    return this._worldOriginY
  }
  get scaleX() {
    return this._scaleX
  }
  get scaleY() {
    return this._scaleY
  }
  get rotation() {
    return this._rotation
  }
}
