import { ArcBand, ArcBandCorner } from './ArcBand.js'
import { LLHArcBandUtil } from './LLHArcBandUtil.js'
export class LLHArcBand extends ArcBand {
  constructor(t, e, i, r, s, n) {
    super(t, e, i, r, s, n)
    this.validateReference(t)
  }
  get isGeodetic() {
    return true
  }
  distance2D(t, e) {
    return LLHArcBandUtil.distance2D(t, e, this)
  }
  forwardAzimuth2D(t, e) {
    return LLHArcBandUtil.forwardAzimuth2D(t, e, this)
  }
  calculatePoint(t, e) {
    return LLHArcBandUtil.calculatePoint(t, e, this)
  }
  contains2DCoordinates(t, e) {
    return LLHArcBandUtil.contains2DCoordinates(t, e, this)
  }
  calculateBounds() {
    return LLHArcBandUtil.calculateBounds(this)
  }
  get coordinateType() {
    return LLHArcBandUtil.coordinateType
  }
  copy() {
    return new LLHArcBand(
      this.reference,
      this.center,
      this.minRadius,
      this.maxRadius,
      this.startAzimuth,
      this.sweepAngle
    )
  }
  corner2DEditablePointSFCT(t, e) {
    const i = this._reference.geodeticDatum.ellipsoid
    switch (t) {
      case ArcBandCorner.MIN_RADIUS_START_CORNER:
        i.geodesicPositionSFCT(
          this.center,
          this.minRadius,
          this.startAzimuth,
          e
        )
        break
      case ArcBandCorner.MAX_RADIUS_START_CORNER:
        i.geodesicPositionSFCT(
          this.center,
          this.maxRadius,
          this.startAzimuth,
          e
        )
        break
      case ArcBandCorner.MIN_RADIUS_END_CORNER:
        i.geodesicPositionSFCT(
          this.center,
          this.minRadius,
          this.startAzimuth + this.sweepAngle,
          e
        )
        break
      case ArcBandCorner.MAX_RADIUS_END_CORNER:
        i.geodesicPositionSFCT(
          this.center,
          this.maxRadius,
          this.startAzimuth + this.sweepAngle,
          e
        )
        break
    }
  }
}
