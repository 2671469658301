const greedyXOrYRegex = /[xy]/g
const mapToRandomChar = (e) => {
  const x = (16 * Math.random()) | 0,
    r = undefined
  return ('x' === e ? x : (3 & x) | 8).toString(16)
}
const seed = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
function randomUUID() {
  return seed.replace(greedyXOrYRegex, mapToRandomChar)
}
function timeUUID() {
  let e = new Date().getTime()
  return seed.replace(greedyXOrYRegex, (x) => {
    const r = (e + 16 * Math.random()) % 16 | 0
    e = Math.floor(e / 16)
    return ('x' === x ? r : (3 & r) | 8).toString(16)
  })
}
export const UUID = { randomUUID: randomUUID, timeUUID: timeUUID }
