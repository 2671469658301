import { ProgrammingError } from '../error/ProgrammingError.js'
import { Polyline } from './Polyline.js'
import { Polygon } from './Polygon.js'
import { isConstrainedPolyline } from './ConstrainedPolyline.js'
import { isConstrainedPolygon } from './ConstrainedPolygon.js'
export var ConstrainedPointListForCreation = [
  { name: 'Polyline', value: Polyline },
  { name: 'Polygon', value: Polygon },
].reduce(function (t, n) {
  function o(t, n) {
    this._pointCount = n
    Object.defineProperty(this, 'pointList', {
      configurable: true,
      enumerable: true,
      value: t,
      writable: false,
    })
  }
  o.prototype = Object.create(n.value.prototype)
  o.prototype.constructor = o
  o.prototype.copy = function () {
    return new o(this.pointList.copy(), this.constraint, this.skeleton)
  }
  Object.defineProperty(o.prototype, 'reference', {
    configurable: true,
    enumerable: true,
    get: function () {
      return this.pointList.reference
    },
    set: function () {
      throw new ProgrammingError('reference property is not mutable')
    },
  })
  Object.defineProperty(o.prototype, 'focusPoint', {
    configurable: true,
    enumerable: true,
    get: function () {
      return this.pointList.focusPoint
    },
    set: function () {
      throw new ProgrammingError('focusPoint property is not mutable')
    },
  })
  Object.defineProperty(o.prototype, 'bounds', {
    configurable: true,
    enumerable: true,
    get: function () {
      return this.pointList.bounds
    },
    set: function () {
      throw new ProgrammingError('bounds property is not mutable')
    },
  })
  Object.defineProperty(o.prototype, 'pointCount', {
    configurable: true,
    enumerable: true,
    get: function () {
      return this._pointCount
    },
    set: function () {
      throw new ProgrammingError('pointCount property is not mutable')
    },
  })
  Object.defineProperty(o.prototype, 'coordinateType', {
    configurable: true,
    enumerable: true,
    get: function () {
      return this.pointList.coordinateType
    },
    set: function () {
      throw new ProgrammingError('coordinateType property is not mutable')
    },
  })
  Object.defineProperty(o.prototype, 'coordinates', {
    configurable: true,
    enumerable: true,
    get: function () {
      return this.pointList.coordinates
    },
    set: function () {
      throw new ProgrammingError('coordinates property is not mutable')
    },
  })
  Object.defineProperty(o.prototype, 'cache', {
    configurable: true,
    enumerable: true,
    get: function () {
      return this.pointList.cache
    },
    set: function () {
      throw new ProgrammingError('cache property is not mutable')
    },
  })
  o.prototype.insertPoint = function (t, n) {
    if (t !== this._pointCount)
      throw new ProgrammingError('Expected only insertions at the end')
    if (
      t === this._pointCount &&
      this.pointList.pointCount > this._pointCount
    ) {
      this._pointCount++
      this.move2DPoint(t, n.x, n.y)
    } else {
      this._pointCount++
      if (
        isConstrainedPolygon(this.pointList) ||
        isConstrainedPolyline(this.pointList)
      ) {
        var o = this.pointList.shape
        o.insertPoint(t, n)
        this.pointList.constraint.constrainCreationEdit(
          o,
          t,
          null,
          this.pointList.getPoint(t)
        )
      } else this.pointList.insertPoint(t, n)
    }
  }
  o.prototype.removePoint = function (t) {
    this.verifyIndex(t)
    if (
      isConstrainedPolygon(this.pointList) ||
      isConstrainedPolyline(this.pointList)
    ) {
      if (t >= this.pointList.pointCount) return
      var n = this.pointList.getPoint(t).copy()
      var o = this.pointList.shape
      this.pointList.constraint.constrainCreationEdit(o, t, n, null)
      if (
        !this.pointList.minPointCount ||
        !this.pointList.maxPointCount ||
        this.pointList.minPointCount !== this.pointList.maxPointCount
      )
        this.pointList.removePoint(t)
    } else this.pointList.removePoint(t)
    this._pointCount--
  }
  o.prototype.move2DPoint = function (t, n, o) {
    this.verifyIndex(t)
    if (
      isConstrainedPolygon(this.pointList) ||
      isConstrainedPolyline(this.pointList)
    ) {
      var i = this.pointList.getPoint(t).copy()
      var e = this.pointList.shape
      e.move2DPoint(t, n, o)
      this.pointList.constraint.constrainCreationEdit(
        e,
        t,
        i,
        this.pointList.getPoint(t)
      )
    } else this.pointList.move2DPoint(t, n, o)
  }
  o.prototype.translatePoint = function (t, n, o) {
    this.verifyIndex(t)
    if (
      isConstrainedPolygon(this.pointList) ||
      isConstrainedPolyline(this.pointList)
    ) {
      var i = this.pointList.getPoint(t).copy()
      var e = this.pointList.shape
      e.translatePoint(t, n, o)
      this.pointList.constrainCreationEdit(e, t, i, this.pointList.getPoint(t))
    } else this.pointList.translatePoint(t, n, o)
  }
  o.prototype.translate2D = function (t, n) {
    this.pointList.translate2D(t, n)
  }
  o.prototype.getPoint = function (t) {
    return this.pointList.getPoint(t)
  }
  o.prototype.getPointCoordinates = function (t) {
    return this.pointList.getPointCoordinates(t)
  }
  o.prototype.contains2D = function (t, n) {
    return this.pointList.contains2D(t, n)
  }
  o.prototype.contains2DPoint = function (t) {
    return this.pointList.contains2DPoint(t)
  }
  o.prototype.contains2DCoordinates = function (t, n) {
    return this.pointList.contains2DCoordinates(t, n)
  }
  o.prototype.verifyIndex = function (t) {
    if (t < 0 || t > this._pointCount)
      throw new ProgrammingError('Given index not within in bounds')
  }
  t['create' + n.name] = function () {
    var t = arguments
    Array.prototype.splice.call(t, 0, 0, null)
    return new (Function.prototype.bind.apply(o, t))()
  }
  t['is' + n.name] = function (t) {
    return t instanceof o
  }
  return t
}, {})
