import { ProgrammingError } from '../error/ProgrammingError.js'
import { Ellipsoid } from '../geodesy/Ellipsoid.js'
import { GeodeticDatum } from '../geodesy/GeodeticDatum.js'
import { EllipsoidalEquidistantCylindrical } from '../projection/EllipsoidalEquidistantCylindrical.js'
import { EquidistantCylindrical } from '../projection/EquidistantCylindrical.js'
import { LambertConformal } from '../projection/LambertConformal.js'
import { Mercator } from '../projection/Mercator.js'
import { PolarStereographic } from '../projection/PolarStereographic.js'
import { PseudoMercator } from '../projection/PseudoMercator.js'
import { TransverseMercator } from '../projection/TransverseMercator.js'
import { getUnitOfMeasure } from '../uom/UnitOfMeasureRegistry.js'
import { Constants } from '../util/Constants.js'
import { isObject, isUndefined } from '../util/Lang.js'
import { Axis } from './Axis.js'
import {
  createGeodeticReferenceAxisInformation,
  createGridReferenceAxisInformation,
} from './AxisInformationUtil.js'
import { CartesianReference } from './CartesianReference.js'
import { WKTReferenceParser } from './format/wkt/WKTReferenceParser.js'
import { GeocentricReference } from './GeocentricReference.js'
import { GeodeticReference } from './GeodeticReference.js'
import { GeoReference } from './GeoReference.js'
import { GridReference } from './GridReference.js'
import { HeightReference, HeightReferenceType } from './HeightReference.js'
import { ReferenceType } from './ReferenceType.js'
import { TopocentricReference } from './TopocentricReference.js'
const EPSG_PATTERN = /(EPSG):(\d+)/i
const CRS_PATTERN = /(CRS):(\d+)/i
const OGC_EPSG_PATTERN = /(urn:ogc:def:crs:EPSG):([^:]*:)?(\d+)/i
const OGC_OGC_PATTERN = /(urn:ogc:def:crs:OGC):([^:]*:)?(CRS\d+)/i
const CRS = 'CRS'
const OGC = 'OGC'
const EPSG = 'EPSG'
const SR_ORG = 'SR-ORG'
const LUCIAD = 'LUCIAD'
const USER_DEFINED = 'USER_DEFINED'
const wktParser = new WKTReferenceParser()
const AVAILABLE_REFERENCES = new Map()
const normalGeodeticAxesOrder = createGeodeticReferenceAxisInformation(
  false,
  false
)
const flippedGeodeticAxesOrder = createGeodeticReferenceAxisInformation(
  true,
  false
)
const normalGridAxesOrder = createGridReferenceAxisInformation(false)
;(function e() {
  let r, t
  const n = new Map()
  const i = new Map()
  const o = new Map()
  const a = new Map()
  const c = new Map()
  AVAILABLE_REFERENCES.set(CRS, n)
  AVAILABLE_REFERENCES.set(OGC, o)
  AVAILABLE_REFERENCES.set(EPSG, a)
  AVAILABLE_REFERENCES.set(SR_ORG, c)
  AVAILABLE_REFERENCES.set(LUCIAD, i)
  AVAILABLE_REFERENCES.set(USER_DEFINED, new Map())
  n.set('1', createCRS1())
  n.set('83', createNAD83(false))
  n.set(
    '84',
    new GeodeticReference({
      geodeticDatum: new GeodeticDatum(),
      axisInformation: normalGeodeticAxesOrder,
    })
  )
  i.set('XYZ', createLuciadXYZ())
  o.set('CRS1', createCRS1())
  o.set('CRS83', createNAD83(false))
  o.set(
    'CRS84',
    new GeodeticReference({
      geodeticDatum: new GeodeticDatum(),
      axisInformation: normalGeodeticAxesOrder,
    })
  )
  a.set(
    '4326',
    new GeodeticReference({
      geodeticDatum: new GeodeticDatum(),
      axisInformation: flippedGeodeticAxesOrder,
    })
  )
  a.set(
    '4979',
    new GeodeticReference({
      geodeticDatum: new GeodeticDatum(),
      axisInformation: createGeodeticReferenceAxisInformation(true, true),
    })
  )
  const s = new Ellipsoid()
  s.initializeA1OverF(6378206.4, 294.9786982)
  s.name = 'CLARKE_1866'
  a.set(
    '4267',
    new GeodeticReference({
      name: 'NAD27',
      geodeticDatum: new GeodeticDatum({
        x: -3,
        y: 142,
        z: 183,
        ellipsoid: s,
        name: 'NAD27',
      }),
      axisInformation: flippedGeodeticAxesOrder,
    })
  )
  a.set('4269', createNAD83(true))
  a.set(
    '4978',
    new GeocentricReference({
      name: 'WGS 84 Geocentric',
      geodeticDatum: new GeodeticDatum(),
    })
  )
  a.set(
    '32662',
    new GridReference({
      name: 'World Equidistant Cylindrical (Plate Carree)',
      projection: new EquidistantCylindrical(),
      geodeticDatum: new GeodeticDatum(),
      axisInformation: normalGridAxesOrder,
    })
  )
  r = new GridReference({
    name: 'World Equidistant Cylindrical (Ellipsoidal)',
    projection: new EllipsoidalEquidistantCylindrical(),
    geodeticDatum: new GeodeticDatum(),
    axisInformation: normalGridAxesOrder,
  })
  a.set('4087', r)
  a.set('32663', r)
  a.set(
    '3395',
    new GridReference({
      name: 'Mercator',
      projection: new Mercator(),
      geodeticDatum: new GeodeticDatum(),
      axisInformation: normalGridAxesOrder,
    })
  )
  r = new GridReference({
    name: 'Web Mercator',
    projection: new PseudoMercator(),
    geodeticDatum: new GeodeticDatum(),
    axisInformation: normalGridAxesOrder,
  })
  a.set('3857', r)
  a.set('900913', r)
  t = new PolarStereographic(1)
  t.setTrueScaleLatitude(-71)
  a.set(
    '3031',
    new GridReference({
      name: 'Antarctic Polar Stereographic',
      projection: t,
      geodeticDatum: new GeodeticDatum(),
      axisInformation: normalGridAxesOrder,
    })
  )
  t = new PolarStereographic(0)
  t.setTrueScaleLatitude(71)
  a.set(
    '3995',
    new GridReference({
      name: 'Arctic Polar Stereographic',
      projection: t,
      geodeticDatum: new GeodeticDatum(),
      axisInformation: normalGridAxesOrder,
    })
  )
  t = new PolarStereographic(0)
  t.setTrueScaleLatitude(75)
  a.set(
    '3996',
    new GridReference({
      name: 'IBCAO Polar Stereographic',
      projection: t,
      geodeticDatum: new GeodeticDatum(),
      axisInformation: normalGridAxesOrder,
    })
  )
  t = new TransverseMercator(-153, 0)
  a.set(
    '3306',
    new GridReference({
      name: 'Maupiti 83 / UTM zone 5S',
      projection: t,
      geodeticDatum: createMaupiti83Datum(),
      falseEasting: 5e5,
      falseNorthing: 1e7,
      scale: 0.9996,
      axisInformation: normalGridAxesOrder,
    })
  )
  a.set(
    '23035',
    createED50UtmReference(27, '35', -87, -98, -121, 0, 0, 0, 0, 0)
  )
  a.set(
    '23036',
    createED50UtmReference(
      33,
      '36',
      -116.641,
      -56.931,
      -110.559,
      0.893,
      0.921,
      -0.917,
      -3.52,
      0
    )
  )
  a.set(
    '23037',
    createED50UtmReference(39, '37', -89.05, -87.03, -124.56, 0, 0, 0, 0, 0)
  )
  a.set(
    '23038',
    createED50UtmReference(
      45,
      '38',
      -84.1,
      -101.8,
      -129.7,
      0,
      0,
      0.468,
      1.05,
      0
    )
  )
  a.set('2154', createRGF93_Lambert_93Reference())
  a.set('2206', createED50GausKrugerReference(27, 95e5, '9'))
  a.set('2207', createED50GausKrugerReference(30, 105e5, '10'))
  a.set('2208', createED50GausKrugerReference(33, 115e5, '11'))
  a.set('2209', createED50GausKrugerReference(36, 125e5, '12'))
  a.set('2210', createED50GausKrugerReference(39, 135e5, '13'))
  a.set('2211', createED50GausKrugerReference(42, 145e5, '14'))
  a.set('2212', createED50GausKrugerReference(45, 155e5, '15'))
  a.set('2319', createED50TMXXReference(27, 5e5))
  a.set('2320', createED50TMXXReference(30, 5e5))
  a.set('2321', createED50TMXXReference(33, 5e5))
  a.set('2322', createED50TMXXReference(36, 5e5))
  a.set('2323', createED50TMXXReference(39, 5e5))
  a.set('2324', createED50TMXXReference(42, 5e5))
  a.set('2325', createED50TMXXReference(45, 5e5))
  a.set('5253', createTNRFReference(27))
  a.set('5254', createTNRFReference(30))
  a.set('5255', createTNRFReference(33))
  a.set('5256', createTNRFReference(36))
  a.set('5257', createTNRFReference(39))
  a.set('5258', createTNRFReference(42))
  a.set('5259', createTNRFReference(45))
  c.set('7869', createTUREFUTMReference(27, '35'))
  c.set('7870', createTUREFUTMReference(33, '36'))
  c.set('7871', createTUREFUTMReference(39, '37'))
  c.set('7872', createTUREFUTMReference(45, '38'))
})()
function isValidAuthName(e) {
  return e === CRS || e === OGC || e === EPSG || e === SR_ORG || e === LUCIAD
}
function hasValidReferenceType(e) {
  let r
  if (e instanceof GeoReference) r = e.referenceType
  else if (e instanceof CartesianReference) r = e.referenceType
  return (
    r === ReferenceType.GEODETIC ||
    r === ReferenceType.GRID ||
    r === ReferenceType.CARTESIAN ||
    r === ReferenceType.GEOCENTRIC ||
    r === ReferenceType.TOPOCENTRIC
  )
}
function getAuthInfo(e) {
  let r
  const t = e.toLowerCase()
  let n = null
  let i = null
  let o
  let a
  if (t.indexOf('urn:ogc:def:crs:') >= 0) {
    const e = 'urn:ogc:def:crs'
    r = t.substring(e.length + 1)
    n = e
  } else if (t.indexOf('urn:x-ogc:def:crs:') >= 0) {
    const e = 'urn:x-ogc:def:crs'
    r = t.substring(e.length + 1)
    n = e
  } else r = t
  const c = r.split(':')
  if (3 === c.length) {
    o = c[0].toUpperCase()
    i = c[1] ? c[1] : null
    a = c[2].toUpperCase()
  } else if (2 === c.length) {
    o = c[0].toUpperCase()
    a = c[1].toUpperCase()
  } else return null
  return { prefix: n, authName: o, authCode: a, version: i }
}
function createCartesianReference(e) {
  if (!e)
    throw new Error(
      'Cannot create a Cartesian Reference without configuration options'
    )
  let r
  if (!e.xUnitOfMeasure || 'UNIT' !== e.xUnitOfMeasure.TYPE)
    throw new Error('Please pass a valid unit of measure for the X-axis')
  else r = e.xUnitOfMeasure
  let t
  if (!e.yUnitOfMeasure || 'UNIT' !== e.yUnitOfMeasure.TYPE)
    throw new Error('Please pass a valid unit of measure for the Y-axis')
  else t = e.yUnitOfMeasure
  let n
  if (e.zUnitOfMeasure && 'UNIT' !== e.zUnitOfMeasure.TYPE)
    throw new Error('Please pass a valid unit of measure for the Z-axis')
  else n = e.zUnitOfMeasure
  const i = e.name || ''
  const o = e.identifier || ''
  const a = e.xDirection || Axis.Direction.DISPLAY_RIGHT
  const c = e.yDirection || Axis.Direction.DISPLAY_UP
  const s = new Axis('X', a, r, null, null, null)
  const f = new Axis('Y', c, t, null, null, null)
  if (n) {
    const r = e.zDirection || Axis.Direction.UP
    const t = new Axis('Z', r, n, null, null, null)
    return new CartesianReference(i, o, s, f, t)
  } else return new CartesianReference(i, o, s, f)
}
export function createTopocentricReference(e) {
  if (!isObject(e))
    throw new Error(
      'Cannot create a topocentric reference without configuration options'
    )
  return new TopocentricReference({ name: e.name, origin: e.origin })
}
function createCRS1() {
  return createCartesianReference({
    xUnitOfMeasure: getUnitOfMeasure('Pixels'),
    yUnitOfMeasure: getUnitOfMeasure('Pixels'),
    yDirection: Axis.Direction.DISPLAY_DOWN,
    name: 'Pixel space reference',
    identifier: 'CRS:1',
  })
}
function createLuciadXYZ() {
  return createCartesianReference({
    xUnitOfMeasure: getUnitOfMeasure('Meter'),
    yUnitOfMeasure: getUnitOfMeasure('Meter'),
    zUnitOfMeasure: getUnitOfMeasure('Meter'),
    xDirection: Axis.Direction.EAST,
    yDirection: Axis.Direction.NORTH,
    zDirection: Axis.Direction.UP,
    name: 'Luciad non-referenced 3D cartesian space (meters)',
    authorityName: 'LUCIAD',
    authorityCode: 'XYZ',
    identifier: 'LUCIAD:XYZ',
  })
}
function createNAD83(e) {
  return new GeodeticReference({
    name: 'NAD83',
    geodeticDatum: new GeodeticDatum({
      ellipsoid: createGRS1980Ellipsoid(),
      name: 'NAD83',
    }),
    axisInformation: e ? flippedGeodeticAxesOrder : normalGeodeticAxesOrder,
  })
}
function createED50TransverseMercatorReference(e, r, t) {
  return new GridReference({
    name: e,
    projection: new TransverseMercator(r, 0),
    geodeticDatum: createEuropeanDatum1950(
      -84.1,
      -101.8,
      -129.7,
      0,
      0,
      0.468,
      1.05,
      0
    ),
    falseEasting: t,
    falseNorthing: 0,
    scale: 1,
    axisInformation: normalGridAxesOrder,
  })
}
function createED50TMXXReference(e, r) {
  return createED50TransverseMercatorReference(`ED50 / TM${e}`, e, r)
}
function createED50GausKrugerReference(e, r, t) {
  return createED50TransverseMercatorReference(
    `ED50 / 3-degree Gauss-Kruger zone ${t}`,
    e,
    r
  )
}
function createTNRFReference(e) {
  const r = new TransverseMercator(e, 0)
  return new GridReference({
    name: `TUREF / TM${e}`,
    projection: r,
    geodeticDatum: createTNRFDatum(),
    falseEasting: 5e5,
    falseNorthing: 0,
    scale: 1,
    axisInformation: normalGridAxesOrder,
  })
}
function createED50UtmReference(e, r, t, n, i, o, a, c, s, f) {
  return new GridReference({
    name: `ED50 / UTM zone ${r}N`,
    projection: new TransverseMercator(e, 0),
    geodeticDatum: createEuropeanDatum1950(t, n, i, o, a, c, s, f),
    falseEasting: 5e5,
    falseNorthing: 0,
    scale: 0.9996,
    axisInformation: normalGridAxesOrder,
  })
}
function createTUREFUTMReference(e, r) {
  return new GridReference({
    name: `ITRF96 / UTM Zone ${r}N`,
    projection: new TransverseMercator(e, 0),
    geodeticDatum: createTNRFDatum(),
    falseEasting: 5e5,
    falseNorthing: 0,
    scale: 0.9996,
    axisInformation: normalGridAxesOrder,
  })
}
function createRGF93_Lambert_93Reference() {
  const e = new LambertConformal(49, 44, 3, 46.5)
  return new GridReference({
    name: 'RGF93 / Lambert-93',
    projection: e,
    geodeticDatum: createRGF93Datum(),
    falseEasting: 7e5,
    falseNorthing: 66e5,
    scale: 1,
    axisInformation: normalGridAxesOrder,
  })
}
function createEuropeanDatum1950(e, r, t, n, i, o, a, c) {
  return new GeodeticDatum({
    ellipsoid: createInternational1924Ellipsoid(),
    name: 'European Datum 1950',
    x: e,
    y: r,
    z: t,
    rotX: (n / 3600) * Constants.DEG2RAD,
    rotY: (i / 3600) * Constants.DEG2RAD,
    rotZ: (o / 3600) * Constants.DEG2RAD,
    scale: 1 + 1e-6 * a,
    primeMeridian: c,
  })
}
function createTNRFDatum() {
  return new GeodeticDatum({
    ellipsoid: createGRS1980Ellipsoid(),
    name: 'Turkish National Reference Frame',
  })
}
function createRGF93Datum() {
  return new GeodeticDatum({
    ellipsoid: createGRS1980Ellipsoid(),
    name: 'Reseau Geodesique Francais 1993',
    x: 0,
    y: 0,
    z: 0,
    rotX: 0,
    rotY: 0,
    rotZ: 0,
    scale: 1,
    primeMeridian: 0,
  })
}
function createGRS1980Ellipsoid() {
  const e = new Ellipsoid()
  e.initializeA1OverF(6378137, 298.257222101)
  e.name = 'GRS 1980'
  return e
}
function createInternational1924Ellipsoid() {
  const e = new Ellipsoid()
  e.initializeA1OverF(6378388, 297)
  e.name = 'International 1924'
  return e
}
function createMaupiti83Datum() {
  const e = createInternational1924Ellipsoid()
  return new GeodeticDatum({ ellipsoid: e, name: 'Maupiti 83' })
}
function isValidReferenceIdentifier(e) {
  const r = getAuthInfo(e)
  if (r) {
    const e = r.authName
    const t = r.authCode
    if (null != e && isValidAuthName(e) && null != t) {
      const r = AVAILABLE_REFERENCES.get(e)
      return !isUndefined(r) && r.has(t)
    }
  }
  const t = AVAILABLE_REFERENCES.get(USER_DEFINED)
  return !isUndefined(t) && t.has(e)
}
function getReference(e) {
  const r = getAuthInfo(e)
  if (r) {
    const e = r.authName
    const t = r.authCode
    if (null != e) {
      const n = AVAILABLE_REFERENCES.get(e)
      if (isValidAuthName(e) && !isUndefined(n) && n.has(t)) {
        const i = n.get(t)
        if (!isUndefined(i)) {
          const n = i.copy()
          n.prefix = r.prefix
          n.authorityName = e
          n.authorityCode = t
          n.version = r.version
          n.heightReference = new HeightReference(
            HeightReferenceType.ABOVE_GEOID
          )
          n.setIdentifier(r.prefix, e, t, r.version)
          return n
        }
      }
    }
  }
  const t = getReferencesForAuthority(USER_DEFINED)
  if (t.has(e)) {
    const r = t.get(e)
    if (!isUndefined(r)) return r.copy()
  }
  throw new Error(
    `ReferenceProvider: Requested unsupported reference: ${e}. Note that it is possible to add custom references by using the ReferenceProvider.addReference method.`
  )
}
function addReference(e, r) {
  if (!e || !hasValidReferenceType(e))
    throw new ProgrammingError(
      'The reference you are trying to add to the ReferenceProvider is not valid. In a ' +
        'typical scenario, you would add references got from the ReferenceProvider.parseWellKnownText method.'
    )
  const t = r || e.identifier
  if (!t)
    throw new ProgrammingError(
      'The provided reference does not contain an identifier. You can pass an identifier for your reference ' +
        'to the addReference method. Please refer to the method documentation for further information.'
    )
  const n = getAuthInfo(t)
  if (n) {
    const r = n.authName
    const i = n.authCode
    if (null != r && isValidAuthName(r)) {
      const t = getReferencesForAuthority(r)
      if (t.has(i))
        throw new ProgrammingError(
          `The reference ${r}:${i} is already available in the ReferenceProvider.`
        )
      else t.set(i, e)
    } else getReferencesForAuthority(USER_DEFINED).set(t, e)
  } else getReferencesForAuthority(USER_DEFINED).set(t, e)
}
function getReferencesForAuthority(e) {
  if (isValidAuthName(e) || e === USER_DEFINED)
    return AVAILABLE_REFERENCES.get(e)
  throw new ProgrammingError('Invalid authority name.')
}
function getHeightAboveTerrainReference(e) {
  const r = getReference(e)
  if (
    !(r instanceof GeoReference) ||
    r.referenceType === ReferenceType.CARTESIAN ||
    r.referenceType === ReferenceType.TOPOCENTRIC ||
    r.referenceType === ReferenceType.GEOCENTRIC
  )
    throw new Error(
      'It is not possible to use the getHeightAboveTerrainReference method to get non-spatial, geocentric or topocentric references.'
    )
  r.heightReference = new HeightReference(HeightReferenceType.ABOVE_TERRAIN)
  return r
}
const supportedReferenceIdentifierPatterns = [
  EPSG_PATTERN,
  CRS_PATTERN,
  OGC_EPSG_PATTERN,
  OGC_OGC_PATTERN,
]
function parseWellKnownText(e, r, t, n) {
  const i = wktParser.parseGeoReference(e, n)
  if (r && (t || '0' === t)) {
    i.authorityName = r
    i.authorityCode = t
  }
  i.heightReference = new HeightReference(HeightReferenceType.ABOVE_GEOID)
  return i
}
export {
  isValidReferenceIdentifier,
  getReference,
  addReference,
  getHeightAboveTerrainReference,
  parseWellKnownText,
  supportedReferenceIdentifierPatterns,
  createCartesianReference,
}
