import { ReferenceType } from '../reference/ReferenceType.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import { LLHPoint } from '../shape/LLHPoint.js'
import { ProjectionType } from '../projection/ProjectionType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { NoBoundsError } from '../error/NoBoundsError.js'
import { GeodeticGridTransformation } from './GeodeticGridTransformation.js'
import { TransformationType } from './TransformationType.js'
import { BoundsCalculationUtil } from './BoundsCalculationUtil.js'
export function isCompatibleGeodeticMercator(t, i) {
  const o = i.projection
  return !(
    0 !== i.falseEasting ||
    0 !== i.falseNorthing ||
    0 !== i.rotation ||
    ReferenceType.GEODETIC !== t.referenceType ||
    !t.geodeticDatum.equals(i.geodeticDatum) ||
    ((o.TYPE & ProjectionType.MERCATOR) !== ProjectionType.MERCATOR &&
      (o.TYPE & ProjectionType.PSEUDO_MERCATOR) !==
        ProjectionType.PSEUDO_MERCATOR)
  )
}
export class GeodeticMercatorTransformation extends GeodeticGridTransformation {
  constructor(t, i, o) {
    super(t, i, o)
    this._bboxUtil = new BoundsCalculationUtil({
      modelReference: t,
      worldReference: i,
      transformation: this,
      TransformationFactory: o,
    })
    this._tempXYZPoint1 = new XYZPoint()
    this._tempXYZPoint2 = new XYZPoint()
    this._tempLLHPoint1 = new LLHPoint()
    this._tempLLHPoint2 = new LLHPoint()
    if (!isCompatibleGeodeticMercator(t, i))
      throw new ProgrammingError(
        'GeodeticEquidistantTransformation:: geodetic and grid reference combo not compatible'
      )
    this._projection = i.projection
    this._ellipsoid = t.geodeticDatum.ellipsoid
    this._scale = i.scale
    this.uom = i.unitOfMeasure
    const e = this._projection.boundaryLons(0)
    const n = this._projection.boundaryLats(
      this._projection.getCentralMeridian()
    )
    this._minLat = n[0][0]
    this._maxLat = n[0][1]
    try {
      this._tempLLHPoint1.x = e[0][0]
      this._tempLLHPoint1.y = this._minLat
      this._forward(this._tempLLHPoint1, this._tempXYZPoint1)
      this._minX = this._tempXYZPoint1.x
      this._minY = this._tempXYZPoint1.y
      this._tempLLHPoint1.x = e[0][1]
      this._tempLLHPoint1.y = this._maxLat
      this._forward(this._tempLLHPoint1, this._tempXYZPoint1)
      this._maxX = this._tempXYZPoint1.x
      this._maxY = this._tempXYZPoint1.y
    } catch (t) {
      throw new ProgrammingError(
        `GeodeticMercatorTransformation::constructor - could not make new transformation: ${t}`
      )
    }
  }
  _forward(t, i) {
    this._projection.geodetic2cartesianOnEllipsoidSFCT(t, this._ellipsoid, i)
    i.x *= this._scale / this.uom
    i.y *= this._scale / this.uom
    i.z = t.z / this.uom
    return i
  }
  _inverse(t, i) {
    this._tempXYZPoint1.x = (t.x * this.uom) / this._scale
    this._tempXYZPoint1.y = (t.y * this.uom) / this._scale
    this._projection.cartesian2geodeticOnEllipsoidSFCT(
      this._tempXYZPoint1,
      this._ellipsoid,
      i
    )
    i.z = t.z * this.uom
    return i
  }
  _forwardBoundsCoords(t, i) {
    if (0 !== this._projection.getCentralMeridian())
      return this._bboxUtil.forwardBounds2dSFCT(t, i)
    let o = this._tempXYZPoint1
    const e = this._tempXYZPoint2
    try {
      const n = t.x
      const s = Math.min(Math.max(t.y, this._minLat), this._maxLat)
      const r = t.z
      const m = t.x + t.width
      const h = Math.min(Math.max(t.y + t.height, this._minLat), this._maxLat)
      const a = t.z + t.depth
      this._tempLLHPoint1.x = n
      this._tempLLHPoint1.y = s
      this._tempLLHPoint1.z = r
      o = this._forward(this._tempLLHPoint1, o)
      this._tempLLHPoint1.x = m
      this._tempLLHPoint1.y = h
      this._tempLLHPoint1.z = a
      this._forward(this._tempLLHPoint1, e)
      if (e.x < o.x || t.width >= 360)
        i.setTo3D(
          this._minX,
          this._maxX - this._minX,
          o.y,
          e.y - o.y,
          o.z,
          e.z - o.z
        )
      else i.setTo3D(o.x, e.x - o.x, o.y, e.y - o.y, o.z, e.z - o.z)
    } catch (t) {
      throw new NoBoundsError()
    }
  }
  _inverseBoundsCoords(t, i) {
    if (0 !== this._projection.getCentralMeridian())
      return this._bboxUtil.inverseBounds2dSFCT(t, i)
    const o = this._tempLLHPoint1
    const e = this._tempLLHPoint2
    try {
      const n = Math.max(t.x, this._minX)
      const s = Math.max(t.y, this._minY)
      const r = t.z
      const m = Math.min(t.x + t.width, this._maxX)
      const h = Math.min(t.y + t.height, this._maxY)
      const a = t.z + t.depth
      this._tempXYZPoint1.x = n
      this._tempXYZPoint1.y = s
      this._tempXYZPoint1.z = r
      this._inverse(this._tempXYZPoint1, o)
      this._tempXYZPoint1.x = m
      this._tempXYZPoint1.y = h
      this._tempXYZPoint1.z = a
      this._inverse(this._tempXYZPoint1, e)
      i.setTo3D(o.x, e.x - o.x, o.y, e.y - o.y, o.z, e.z - o.z)
    } catch (t) {
      const i = t instanceof Error ? t.message : '' + t
      throw new NoBoundsError(i)
    }
  }
  _getType() {
    return TransformationType.TYPE_GENERAL
  }
}
