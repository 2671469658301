import { isString } from '../../util/Lang.js'
import { ErrorHandler } from '../ErrorHandler.js'
import { FilterElementType } from './FilterElementType.js'
import { MatchAction } from './MatchAction.js'
import { sanitizeString } from '../se/util/SEUtil.js'
import {
  defaultFunctionNameForExpectedOutput,
  FEATURE_PARAMETER_NAME,
  FUNCTION_EVALUATORS_PARAMETER_NAME,
  PRE_DEFINED_FUNCTION_EVALUATORS,
  returnValueFromDefault,
  SHAPE_PARAMETER_NAME,
} from '../FunctionEvaluatorUtil.js'
import { Log } from '../../util/Log.js'
function isLiteralOrPropertyName(e) {
  return (
    e.TYPE === FilterElementType.Expression.Literal ||
    e.TYPE === FilterElementType.Expression.PropertyName
  )
}
function mapToQNameString(e) {
  return e.toFullyQualifiedString()
}
function addBrackets(e) {
  return `["${e}"]`
}
export class OGCFilterBuilderVisitor {
  constructor() {
    let e =
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : {
            expectedFunctionOutput: 'boolean',
            functionEvaluators: PRE_DEFINED_FUNCTION_EVALUATORS,
          }
    this.errorHandler = new ErrorHandler({ strict: !!e.strict || false })
    this._expression = ''
    this._expectedOutput = e.expectedFunctionOutput
    this._functionEvaluators = Object.assign(
      {},
      PRE_DEFINED_FUNCTION_EVALUATORS,
      e.functionEvaluators
    )
  }
  get expression() {
    return this._expression
  }
  set expression(e) {
    this._expression = e
  }
  reset() {
    this._expression = ''
  }
  visitLiteral(e) {
    const t = e.value
    if (isString(t)) {
      const e = parseFloat(t)
      if (!isNaN(e) && !isNaN(t)) this._expression += e
      else if ('true' === t.trim()) this._expression += 'true'
      else if ('false' === t.trim()) this._expression += 'false'
      else this._expression += `'${sanitizeString(t)}'`
      return
    }
    this._expression += t
  }
  visitPropertyName(e) {
    const t = e.namePath.qNames.map(mapToQNameString).map(addBrackets).join('')
    const s = e.isId()
      ? `${FEATURE_PARAMETER_NAME}${t}`
      : e.isGeom()
      ? `${FEATURE_PARAMETER_NAME}["${SHAPE_PARAMETER_NAME}"]`
      : `${FEATURE_PARAMETER_NAME}.properties${t}`
    this._expression += sanitizeString(s)
  }
  visitBinaryComparisonOperator(e) {
    const t = e.args
    const s = false === e.matchCase
    if (!!e.matchAction) {
      const c = i(e.matchAction)
      this._expression += c.startPart
      t[0].accept(this)
      this._expression += c.funcPart
      this._expression += o(s)
      this._expression += '(el)'
      this._expression += p(s)
      this._expression += r(e.TYPE)
      this._expression += n(t[1], s)
      t[1].accept(this)
      this._expression += a(t[1], s)
      this._expression += c.endPart
    } else {
      this._expression += n(t[0], s)
      t[0].accept(this)
      this._expression += a(t[0], s)
      this._expression += r(e.TYPE)
      this._expression += n(t[1], s)
      t[1].accept(this)
      this._expression += a(t[1], s)
    }
    function i(e) {
      switch (e) {
        case MatchAction.ALL:
          return {
            startPart: '[].concat(',
            funcPart: ').every(function(el){return ',
            endPart: '})',
          }
        case MatchAction.ONE:
          return {
            startPart: '[].concat(',
            funcPart: ').filter(function(el){return ',
            endPart: '}).length===1',
          }
        case MatchAction.ANY:
        default:
          return {
            startPart: '[].concat(',
            funcPart: ').some(function(el){return ',
            endPart: '})',
          }
      }
    }
    function r(e) {
      const t = FilterElementType.Comparison.Binary
      switch (e) {
        case t.EqualTo:
          return '=='
        case t.NotEqualTo:
          return '!='
        case t.GreaterThan:
          return '>'
        case t.GreaterThanOrEqualTo:
          return '>='
        case t.LessThanOrEqualTo:
          return '<='
        case t.LessThan:
          return '<'
        default:
          throw new Error(`Not implemented: ${e}`)
      }
    }
    function n(e, t) {
      return o(t) + (isLiteralOrPropertyName(e) ? '' : '(')
    }
    function o(e) {
      return e ? '(""+' : ''
    }
    function a(e, t) {
      return (isLiteralOrPropertyName(e) ? '' : ')') + p(t)
    }
    function p(e) {
      return e ? ').toLowerCase()' : ''
    }
  }
  visitAddOperator(e) {
    this.generateArithmeticExpression('+', e.args)
  }
  visitSubOperator(e) {
    this.generateArithmeticExpression('-', e.args)
  }
  visitMulOperator(e) {
    this.generateArithmeticExpression('*', e.args)
  }
  visitDivOperator(e) {
    this.generateArithmeticExpression('/', e.args)
  }
  visitOGCFunction(e) {
    let t = e.name
    if (null === t || !this._functionEvaluators[t]) {
      t = defaultFunctionNameForExpectedOutput(this._expectedOutput)
      const s = returnValueFromDefault(t)
      Log.error(
        `Custom function with name ${e.name} has not been found, defaulting to ${t} which will return "${s}"`
      )
    }
    this._expression += `${FUNCTION_EVALUATORS_PARAMETER_NAME}.${t}`
    this._expression += `(...[`
    for (let t = 0; t < e.args.length; t++) {
      if (e.args[t].TYPE === FilterElementType.Expression.Literal)
        this._expression += "'" + e.args[t].value + "'"
      else e.args[t].accept(this)
      if (t < e.args.length && e.args.length > 1) this._expression += `, `
    }
    if (0 === e.args.length) this._expression += `${FEATURE_PARAMETER_NAME}`
    this._expression += `]`
    this._expression += `)`
  }
  visitNotOperator(e) {
    this._expression += '!('
    e.condition.accept(this)
    this._expression += ')'
  }
  visitNullOperator(e) {
    this._expression += ''
    e.expression.accept(this)
    this._expression += '===null'
  }
  visitExistsOperator(e) {
    this._expression += ''
    e.expression.accept(this)
    this._expression += '!==undefined'
  }
  visitAndOperator(e) {
    this.generateBinaryLogicOperatorExpression('&&', e.conditions)
  }
  visitOrOperator(e) {
    this.generateBinaryLogicOperatorExpression('||', e.conditions)
  }
  visitBetweenOperator(e) {
    this._expression += '('
    if (!isLiteralOrPropertyName(e.lower)) this._expression += '('
    e.lower.accept(this)
    if (!isLiteralOrPropertyName(e.lower)) this._expression += ')'
    this._expression += '<='
    if (!isLiteralOrPropertyName(e.expression)) this._expression += '('
    e.expression.accept(this)
    if (!isLiteralOrPropertyName(e.expression)) this._expression += ')'
    this._expression += ')&&('
    if (!isLiteralOrPropertyName(e.expression)) this._expression += '('
    e.expression.accept(this)
    if (!isLiteralOrPropertyName(e.expression)) this._expression += ')'
    this._expression += '<='
    if (!isLiteralOrPropertyName(e.upper)) this._expression += '('
    e.upper.accept(this)
    if (!isLiteralOrPropertyName(e.upper)) this._expression += ')'
    this._expression += ')'
  }
  visitIsLikeOperator(e) {
    const t = e.literal.value
    let s = ''
    let i
    let r
    for (i = 0; i < t.length; i++) {
      r = t[i]
      if (r === e.wildCard) s += '.*'
      else if (r === e.singleChar) s += '.'
      else {
        if (r === e.escapeChar) r = t[++i]
        if (
          '{' === r ||
          '[' === r ||
          '\\' === r ||
          '^' === r ||
          '$' === r ||
          '.' === r ||
          '|' === r ||
          '?' === r ||
          '*' === r ||
          '+' === r ||
          '(' === r ||
          ')' === r
        )
          s += '\\'
        s += r
      }
    }
    const n = e.matchCase ? '' : 'i'
    this._expression += `/${s}/${n}.test(`
    e.property.accept(this)
    this._expression += ')'
  }
  visitBboxOperator(e) {
    this._expression += `${FUNCTION_EVALUATORS_PARAMETER_NAME}.interactsWithBbox(`
    let t
    if (e.geometryName) {
      const s = new OGCFilterBuilderVisitor({
        strict: this.errorHandler.strict,
        expectedFunctionOutput: this._expectedOutput,
        functionEvaluators: this._functionEvaluators,
      })
      e.geometryName.accept(s)
      t = s._expression
    } else t = `${SHAPE_PARAMETER_NAME} ?? ${FEATURE_PARAMETER_NAME}["shape"]`
    const s = sanitizeString(e.srsName || '')
    this._expression += `${t}, '${s}', ${e.minX}, ${e.minY}, ${e.maxX}, ${e.maxY})`
  }
  visitIdentifiers(e) {
    let t
    const s = []
    let i
    const r = e.gmlObjectIds.concat(e.featureIds)
    let n
    for (t = 0; t < r.length; t++) {
      n = r[t]
      i = `(${FEATURE_PARAMETER_NAME}.id === `
      if (isString(n)) i += `'${n}'`
      else i += n
      i += ')'
      s.push(i)
    }
    this._expression += s.join('||')
  }
  generateArithmeticExpression(e, t) {
    t[0].accept(this)
    for (let s = 1, i = t.length; s < i; s++) {
      this._expression += `${e}(`
      t[s].accept(this)
      this._expression += `)`
    }
  }
  generateBinaryLogicOperatorExpression(e, t) {
    let s
    this._expression += isLiteralOrPropertyName(t[0]) ? '' : '('
    t[0].accept(this)
    this._expression += isLiteralOrPropertyName(t[0]) ? '' : ')'
    for (s = 1; s < t.length; s++) {
      this._expression += e
      this._expression += isLiteralOrPropertyName(t[s]) ? '' : '('
      t[s].accept(this)
      this._expression += isLiteralOrPropertyName(t[s]) ? '' : ')'
    }
  }
}
