import { OutOfBoundsError } from '../../error/OutOfBoundsError.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { RasterDataType } from '../../model/tileset/RasterDataType.js'
import { TileCoordinateUtil } from '../../model/tileset/TileCoordinateUtil.js'
import { ReferenceType } from '../../reference/ReferenceType.js'
import { createBounds, createPoint } from '../../shape/ShapeFactory.js'
import { PaintRepresentation } from '../PaintRepresentation.js'
import { PhotonTerrainPainter } from './PhotonTerrainPainter.js'
import { DrapeTarget } from '../style/DrapeTarget.js'
import { Log } from '../../util/Log.js'
import { PhotonTileSetLayerExtentPaintSupport } from './PhotonTileSetLayerExtentPaintSupport.js'
function isElevation(e) {
  return e?.dataType === RasterDataType.ELEVATION
}
function isTinElevation(e) {
  return e?.dataType === RasterDataType.TIN_ELEVATION
}
function createDefaultTerrainPainterFactory(e, t) {
  return () =>
    new PhotonTerrainPainter(
      e.map.reference,
      t.photonView,
      t.photonGraphics,
      t.textureDecoder,
      t.imageDecoderWorker,
      t.geometryDecoder,
      t.referenceProvider,
      false,
      e
    )
}
export class PhotonTileSetLayerRenderer {
  _terrainPainter = null
  _meshDrapeWarningLogged = false
  _photonTileSetLayerExtentPaintSupport = null
  constructor(e, t, r, i) {
    if (null == e.map)
      throw new ProgrammingError(
        'Creating a PhotonTileSetLayerRenderer requires the layer to be added to a map'
      )
    if (
      t.reference.referenceType === ReferenceType.CARTESIAN ||
      e.map.reference.referenceType === ReferenceType.CARTESIAN
    )
      if (!e.map.reference.equals(t.reference))
        throw new ProgrammingError(
          'WebGLMap: to visualize a raster defined in a CartesianReference or in a view with a CartesianReference,' +
            'both the raster and the view must be using the same CartesianReference.'
        )
    this._layer = e
    this._tileSet = t
    this._isElevation = isElevation(t) || isTinElevation(t)
    this._inBaseTerrain = this._isElevation || r.terrainPainter.imageModel === t
    this._baseTerrainPainter = r.terrainPainter
    this._createTerrainPainter = i ?? createDefaultTerrainPainterFactory(e, r)
    this._createPhotonTileSetLayerExtentPaintSupport = () =>
      new PhotonTileSetLayerExtentPaintSupport(
        r,
        e.map,
        this._tileSet.bounds,
        this._layer.label,
        this._layer.rasterStyle.lineStyle,
        this._layer.rasterStyle.fillStyle
      )
    if (e) {
      this._enableTerrainPainter = () => {
        if (!this._inBaseTerrain && this._terrainPainter)
          this._terrainPainter.enabled =
            this._layer &&
            this._layer.visible &&
            this._layer.isPaintRepresentationVisibleInTree(
              PaintRepresentation.BODY
            )
      }
      e.on('PaintRepresentationVisibilityChanged', this._enableTerrainPainter)
      e.on('VisibilityChanged', this._enableTerrainPainter)
    }
  }
  get isInBaseTerrain() {
    return this._inBaseTerrain
  }
  set isInBaseTerrain(e) {
    if (e === this._inBaseTerrain) return
    if (!this._inBaseTerrain) {
      this._terrainPainter?.release()
      this._terrainPainter = null
    }
    this._inBaseTerrain = e
    this._terrainPainter = null
  }
  release() {
    this._terrainPainter?.release()
    this._terrainPainter = null
    this._photonTileSetLayerExtentPaintSupport?.release()
    this._photonTileSetLayerExtentPaintSupport = null
  }
  update() {
    const e = this._getTerrainPainter(true)
    if (!this._isElevation) e.opacity = this._layer._getAlpha()
    else
      e.displacementExpression =
        this._layer.rasterStyle.displacementExpression ?? null
    if (this._layer.rasterStyle) {
      e.drapeTarget = this._layer.rasterStyle.drapeTarget ?? e.drapeTarget
      const t =
        e.drapeTarget === DrapeTarget.MESH || e.drapeTarget === DrapeTarget.ALL
      if (
        !this._meshDrapeWarningLogged &&
        !this._isElevation &&
        this._inBaseTerrain &&
        t
      ) {
        Log.warn(
          'Due to a technical limitation, the bottom-most raster layer in the layer tree cannot be draped on meshes. Check the documentation of `RasterStyle.drapeTarget` for a workaround.'
        )
        this._meshDrapeWarningLogged = true
      }
    }
    if (!this._inBaseTerrain) return e.update(this._layer._map)
    else return true
  }
  render(e, t, r) {
    if (!this._inBaseTerrain) {
      let e = true
      e =
        e &&
        this._getTerrainPainter(true).paint(
          r.paintOpacity,
          r.paintDraping,
          r.paintOutput,
          r.clip
        )
      if (this._getTerrainPainter(true).startResolutionFactorIsNotHit())
        e =
          e &&
          this.getPhotonTileSetLayerExtentPaintSupport().paint(
            r.paintOpacity,
            r.paintDraping,
            r.paintOutput,
            r.clip
          )
      return e
    } else return true
  }
  isReady() {
    const e = this._getTerrainPainter(false)
    return e?.isReady() ?? false
  }
  calculateTileCoordinateAndTileLocation(e, t, r, i) {
    try {
      const n = createPoint(null, [e, t])
      const a = i.inverseTransformation.transform(n)
      if (!a) return null
      const s = r.inverseTransformation.transform(a)
      const o = this._tileSet.bounds
      if (!o?.contains2DPoint(s)) return null
      const l = this.getCurrentGridCoordinates()
      const h = createBounds(this._tileSet.reference)
      for (let e = 0; e < l.length; e++) {
        const t = l[e]
        TileCoordinateUtil.getTileBounds(this._tileSet, t, h)
        if (h.contains2DPoint(s)) {
          const e = this._tileSet.getTileWidth(t.level) ?? 1
          const r = this._tileSet.getTileHeight(t.level) ?? 1
          const i = (s.x - h.x) / h.width
          let n = (s.y - h.y) / h.height
          n = 1 - n
          const a = Math.min(1, i) * (e - 1)
          const o = Math.min(1, n) * (r - 1)
          return {
            tile: { level: t.level, x: t.x, y: t.y },
            tileX: a,
            tileY: o,
          }
        }
      }
    } catch (e) {
      OutOfBoundsError.isOrThrow(e)
      return null
    }
    return null
  }
  getCurrentGridCoordinates() {
    const e = this._getTerrainPainter(false)
    return e?.getGridCoordinates(this._tileSet) ?? []
  }
  invalidate(e, t) {
    const r = this._getTerrainPainter(false)
    return r?.invalidate(e ?? true, t ?? false) ?? true
  }
  static create(e, t, r) {
    return new PhotonTileSetLayerRenderer(e, t, r)
  }
  _getTerrainPainter(e) {
    if (this._inBaseTerrain) return this._baseTerrainPainter
    if (!this._terrainPainter && e) {
      this._terrainPainter = this._createTerrainPainter()
      this._terrainPainter.imageModel = this._tileSet
    }
    return this._terrainPainter
  }
  getPhotonTileSetLayerExtentPaintSupport() {
    if (!this._photonTileSetLayerExtentPaintSupport)
      this._photonTileSetLayerExtentPaintSupport =
        this._createPhotonTileSetLayerExtentPaintSupport()
    return this._photonTileSetLayerExtentPaintSupport
  }
}
