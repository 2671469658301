import { createPoint } from '../../shape/ShapeFactory.js'
import { XYZBounds } from '../../shape/XYZBounds.js'
import { isBorderTransformation } from '../../transformation/MapToBorderTransformationUtil.js'
import { Constants } from '../../util/Constants.js'
import { RotatedBox } from '../../util/RotatedBox.js'
import { HTML5DrawCommandProto } from './HTML5DrawCommandProto.js'
import { HTML5TextUtil } from './HTML5TextUtil.js'
import { PIXEL_UOM } from '../../uom/UnitOfMeasureUtil.js'
const coord = createPoint(null, [0, 0]),
  viewBounds1 = new XYZBounds(null)
const rotatedBoxTmp1 = new RotatedBox()
const rotatedBoxTmp2 = new RotatedBox()
export class HTML5DrawTextCommand extends HTML5DrawCommandProto {
  constructor(t, o, s, i, e, n, d) {
    super(t, o, d)
    this._coordinate = createPoint(null, [s, i])
    this._text = e
    this._style = n
    this._isWorldSized = !this._style.uom.equals(PIXEL_UOM)
    this._angle = n.angle || 0
    this._textBoundsNoPadding = HTML5TextUtil.calculateBounds(
      e,
      n,
      false,
      false
    )
    this._textBoundsWithPadding = HTML5TextUtil.calculateBounds(
      e,
      n,
      true,
      false
    )
    this.bounds = new XYZBounds(t, [s, 0, i, 0])
  }
  draw(t, o, s) {
    if (isBorderTransformation(o)) if (!o.canDraw(this._coordinate)) return
    o._forward(this._coordinate, coord)
    let i = 1
    if (this._isWorldSized && this._textBoundsNoPadding) {
      const t =
        this._style.height + 2 * HTML5TextUtil.calculatePadding(this._style)
      const o = undefined
      i = s.toPixels(t, this._style.uom) / t
    }
    HTML5TextUtil.drawText(
      t,
      this._text,
      coord.x,
      coord.y,
      this._angle,
      this._style,
      i
    )
  }
  mapAnchorPointSFCT(t, o) {
    if (!this._coordinate) return false
    o.move2D(this._coordinate.x, this._coordinate.y)
    return true
  }
  getMaximumWorldMargin(t) {
    if (this._isWorldSized) {
      const o = t.toMapUnits(
        this._textBoundsWithPadding[2] - this._textBoundsWithPadding[0],
        this._style.uom
      )
      const s = t.toMapUnits(
        this._textBoundsWithPadding[3] - this._textBoundsWithPadding[1],
        this._style.uom
      )
      return Math.max(o, s)
    }
    return super.getMaximumWorldMargin(t)
  }
  getMaximumPadding(t) {
    let o = 1
    if (this._isWorldSized) {
      const s = this._textBoundsNoPadding[3] - this._textBoundsNoPadding[1]
      const i = undefined
      o = t.toPixels(s, this._style.uom) / s
    }
    const s = this._textBoundsWithPadding[2] - this._textBoundsWithPadding[0]
    const i = this._textBoundsWithPadding[3] - this._textBoundsWithPadding[1]
    return Math.max(
      (Math.abs(this._style.offsetX) + s) * o,
      (Math.abs(this._style.offsetY) + i) * o
    )
  }
  interacts(t, o, s) {
    o._forward(this._coordinate, coord)
    let i = 1
    if (this._isWorldSized) {
      const t = this._textBoundsNoPadding[3] - this._textBoundsNoPadding[1]
      const o = undefined
      i = s.toPixels(t, this._style.uom) / t
    }
    const e = i * this._textBoundsWithPadding[0]
    const n = i * this._textBoundsWithPadding[2]
    const d = i * this._textBoundsWithPadding[1]
    const r = i * this._textBoundsWithPadding[3]
    const a = i * this._style.offsetX
    const h = i * this._style.offsetY
    rotatedBoxTmp1.configure(
      coord.x + e + a,
      coord.y + d + h,
      n - e,
      r - d,
      this._angle * Constants.DEG2RAD,
      0,
      0
    )
    o._forwardBoundsCoords(t, viewBounds1)
    rotatedBoxTmp2.configure(
      viewBounds1.x,
      viewBounds1.y,
      viewBounds1.width,
      viewBounds1.height,
      0
    )
    return rotatedBoxTmp1.intersects(rotatedBoxTmp2)
  }
  strokeInteracts(t, o, s) {
    return false
  }
}
