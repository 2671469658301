import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { buildCache } from '../util/Cacher.js'
import * as EllipsoidCircleUtil from '../geodesy/EllipsoidCircleUtil.js'
import {
  contains2D as contains2DImpl,
  getBounds as getBoundsImpl,
} from '../geodesy/EllipsoidCircleUtil.js'
import { CircleByCenterPoint } from './CircleByCenterPoint.js'
import { LLHBounds } from './LLHBounds.js'
import { isNumber } from '../util/Lang.js'
export class LLHCircleByCenterPoint extends CircleByCenterPoint {
  constructor(e, r, t) {
    super()
    this._reference = this.validateReference(e)
    this._compareReferenceStrict(
      r.reference,
      'LLHCircleByCenterPoint::the center point reference must be equal to the circle reference'
    )
    this._center = r.copy()
    if (!isNumber(t))
      throw new ProgrammingError(
        'LLHCircleByCenterPoint::cannot create a circle without a radius'
      )
    this._radius = t
    this._cache = buildCache()
  }
  get isGeodetic() {
    return true
  }
  get bounds() {
    return this._cache.memoize('cached-bounding-box', () => {
      const e = new LLHBounds(this.reference, [0, 0, 0, 0, 0, 0])
      getBoundsImpl(this, this.reference.geodeticDatum.ellipsoid, e)
      return e
    })
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  get focusPoint() {
    return EllipsoidCircleUtil.getFocusPoint(
      this,
      this.reference.geodeticDatum.ellipsoid
    )
  }
  get center() {
    return this._center
  }
  get radius() {
    return this._radius
  }
  set radius(e) {
    if (e >= 0) {
      this._radius = e
      this._cache.invalidate()
    } else
      throw new ProgrammingError(
        `Radius should be positive number, but was: ${e}`
      )
  }
  copy() {
    return new LLHCircleByCenterPoint(this.reference, this.center, this.radius)
  }
  contains2DCoordinates(e, r) {
    return (
      this.bounds.contains2DCoordinates(e, r) &&
      contains2DImpl(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.radius,
        e,
        r
      )
    )
  }
  contains3DCoordinates(e, r, t) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape LLHCircleByCenterPoint'
    )
  }
}
