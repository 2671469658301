import { ParseError } from '../../../error/ParseError.js'
import { Ellipsoid } from '../../../geodesy/Ellipsoid.js'
import { GeodeticDatum } from '../../../geodesy/GeodeticDatum.js'
import { getDatum, getEllipsoid } from '../../../geodesy/WellKnownDatums.js'
import { AlbersEqualAreaConic } from '../../../projection/AlbersEqualAreaConic.js'
import { AzimuthalEquidistant } from '../../../projection/AzimuthalEquidistant.js'
import { CassiniSoldner } from '../../../projection/CassiniSoldner.js'
import { DutchStereographic } from '../../../projection/DutchStereographic.js'
import { EllipsoidalEquidistantCylindrical } from '../../../projection/EllipsoidalEquidistantCylindrical.js'
import { EquidistantCylindrical } from '../../../projection/EquidistantCylindrical.js'
import { Gnomonic } from '../../../projection/Gnomonic.js'
import { Krovak } from '../../../projection/Krovak.js'
import { LambertAzimuthalEqualArea } from '../../../projection/LambertAzimuthalEqualArea.js'
import { LambertConformal } from '../../../projection/LambertConformal.js'
import { Mercator } from '../../../projection/Mercator.js'
import {
  ObliqueMercator,
  Variant,
} from '../../../projection/ObliqueMercator.js'
import { Orthographic } from '../../../projection/Orthographic.js'
import {
  PolarStereographic,
  Pole,
} from '../../../projection/PolarStereographic.js'
import { Polyconic } from '../../../projection/Polyconic.js'
import { ProjectionType } from '../../../projection/ProjectionType.js'
import { PseudoMercator } from '../../../projection/PseudoMercator.js'
import { Stereographic } from '../../../projection/Stereographic.js'
import { SwissObliqueMercator } from '../../../projection/SwissObliqueMercator.js'
import { TransverseMercator } from '../../../projection/TransverseMercator.js'
import { LLHPoint } from '../../../shape/LLHPoint.js'
import { getUnitOfMeasure } from '../../../uom/UnitOfMeasureRegistry.js'
import { Constants } from '../../../util/Constants.js'
import { Log } from '../../../util/Log.js'
import { ParsePosition } from '../../../util/ParsePosition.js'
import { UTMGridSystem } from '../../../view/grid/UTMGridSystem.js'
import { Axis } from '../../Axis.js'
import { GeocentricReference } from '../../GeocentricReference.js'
import { GeodeticReference } from '../../GeodeticReference.js'
import { GridReference } from '../../GridReference.js'
import { UPSGrid } from '../../UPSGrid.js'
import { UTMGrid } from '../../UTMGrid.js'
import * as WKT from './WKT.js'
import { WKTName } from './WKTName.js'
import { parseWKTObject } from './WKTObject.js'
const TAG_DUMMY = 'DUMMY'
function checkTag(e, t) {
  if (!e.hasTag(t))
    throw new ParseError(
      `Unexpected tag [${e.getTag()}]; expected [${t}] (Position: ${e.getPosition()})`
    )
}
function getValueAsDouble(e, t) {
  try {
    const r = e[t.getName()]
    if (null == r)
      throw new ParseError(`Parameter [${t.getName()}] is not specified`)
    else if (r instanceof Number) return r.valueOf()
    else return parseFloat(r)
  } catch (e) {
    throw new ParseError(
      `Parameter [${t.getName()}] must have numerical value -1`
    )
  }
}
function isEquivalent(e, t) {
  return Math.abs(e - t) <= 1e-15
}
function optValueAsDouble(e, t, r) {
  try {
    const o = e[t.getName()]
    if (null == o) return r
    else if (o instanceof Number) return o.valueOf()
    else return parseFloat(o)
  } catch (e) {
    return r
  }
}
export class WKTReferenceParser {
  constructor() {}
  parseGeoReference(e, t) {
    return this.doParseGeoReference(e, new ParsePosition(), t)
  }
  doParseGeoReference(e, t, r) {
    if (null == e) throw new ParseError('WKT reference string is null')
    if (0 === e.length) throw new ParseError('WKT reference has zero length')
    const o = e.replace(WKT.LINE_ENDINGS, '')
    const n = this.parseReference(parseWKTObject(o, t), r)
    n._wkt = o
    return n
  }
  parseReference(e, t) {
    if (e.getTag() === TAG_DUMMY) e = e.getObject(0)
    let r
    if (e.hasTag(WKT.TAG_PROJCS)) r = this.parseProjCS(e, t)
    else if (e.hasTag(WKT.TAG_GEOGCS)) r = this.parseGeogCS(e)
    else if (e.hasTag(WKT.TAG_GEOCCS)) r = this.parseGeocCS(e)
    else if (e.hasTag(WKT.TAG_FITTED_CS)) r = this.parseFittedCS(e)
    else if (e.hasTag(WKT.TAG_COMPD_CS)) r = this.parseCompdCS(e)
    else
      throw new ParseError(
        `Start tag [${e.getTag()}] is not supported, only [${
          WKT.TAG_PROJCS
        }], [${WKT.TAG_GEOCCS}] and [${
          WKT.TAG_GEOGCS
        }]\n           are supported (position ${e.getPosition()})`
      )
    if (null == r._identifier) {
      const t = e.optObject(WKT.TAG_AUTHORITY)
      if (null != t) {
        const e = t.optString(0)
        const o = t.optString(1)
        if (null != e && null != o) {
          r.authorityName = e
          r.authorityCode = o
        }
      }
    }
    return r
  }
  parseCompdCS(e) {
    let t
    const r = e.getObject(1)
    const o = e.getObject(2)
    if (!r.hasTag(WKT.TAG_VERT_CS)) t = this.parseReference(r)
    else t = this.parseReference(o)
    return t
  }
  parseProjCS(e, t) {
    const r = e.getString(0)
    const o = this.createSpecialReference(r)
    if (null != o) return o
    const n = e.getObject(1)
    checkTag(n, WKT.TAG_GEOGCS)
    const i = {}
    const a = this.parseGeogCS(n, i)
    const s = e.getObject(2)
    checkTag(s, WKT.TAG_PROJECTION)
    const A = this.normalizeProjectionName(s.getString(0))
    const l = this.parseParameters(e, 3)
    const T = e.optObject(WKT.TAG_UNIT)
    if (null != T) l[WKT.PARAM_LINEAR_UNIT.getName()] = this.parseUnit(T)
    else l[WKT.PARAM_LINEAR_UNIT.getName()] = WKT.VALUE_CONVERSION_FACTOR_METER
    const c = a.geodeticDatum
    const u = this.parseGridAxisInformation(e)
    const R = this.createProjection(A, l, i, c.ellipsoid, t)
    return this.createGridReference(r, A, R, c, l, i, u)
  }
  parseGeocCS(e) {
    const t = {}
    const r = e.getString(0)
    const o = e.optObject(WKT.TAG_PRIMEM)
    if (null != o) this.parsePrimem(o, t)
    else {
      Log.warn(
        'There is no prime meridian specified for the geocentric reference, ' +
          'this is not conform with the OpenGIS specifications.'
      )
      t[WKT.PARAM_PRIMEM_LONGITUDE.getName()] = WKT.VALUE_PRIMEM_GREENWICH
      t[WKT.PARAM_PRIMEM_NAME.getName()] = WKT.NAME_PRIMEM_GREENWICH
    }
    const n = getValueAsDouble(t, WKT.PARAM_PRIMEM_LONGITUDE)
    const i = this.parseDatum(e.getObject(1), r, n)
    let a = e.optObject(WKT.TAG_UNIT)
    if (null != a) a = this.parseUnit(a)
    else {
      t[WKT.PARAM_LINEAR_UNIT.getName()] = WKT.VALUE_CONVERSION_FACTOR_METER
      Log.warn(
        'There is no linear unit specified for the geocentric reference, this is not conform with the OpenGIS specifications.'
      )
    }
    const s = this.parseWKTAxes(e)
    const A = getUnitOfMeasure('Meter')
    let l = null
    if (s.length > 0) {
      l = []
      for (const e of s) {
        let t, r
        switch (e.wktDirection) {
          case 'OTHER':
            t = Axis.Name.X
            r = Axis.Direction.GEOCENTRIC_X
            break
          case 'EAST':
            t = Axis.Name.Y
            r = Axis.Direction.GEOCENTRIC_Y
            break
          case 'NORTH':
            t = Axis.Name.Z
            r = Axis.Direction.GEOCENTRIC_Z
            break
          default:
            throw new Error(
              'Encountered unknown axis direction while parsing geocentric WKT reference'
            )
        }
        const o = {
          name: t,
          axis: new Axis(
            e.wktName,
            r,
            A,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        }
        l.push(o)
      }
    } else
      l = [
        {
          name: Axis.Name.X,
          axis: new Axis(
            'X',
            Axis.Direction.GEOCENTRIC_X,
            A,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
        {
          name: Axis.Name.Y,
          axis: new Axis(
            'Y',
            Axis.Direction.GEOCENTRIC_Y,
            A,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
        {
          name: Axis.Name.Z,
          axis: new Axis(
            'Z',
            Axis.Direction.GEOCENTRIC_Z,
            A,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Axis.RangeMeaning.EXACT
          ),
        },
      ]
    if (null != i)
      return new GeocentricReference({
        geodeticDatum: i,
        uom: a,
        axisInformation: l,
      })
    else
      throw new ParseError(
        `Could not create geodetic datum (position ${e.getPosition()}`
      )
  }
  parseGeogCS(e, t) {
    if (void 0 === t) t = {}
    const r = e.optObject(WKT.TAG_UNIT)
    if (null != r) t[WKT.PARAM_ANGULAR_UNIT.getName()] = this.parseUnit(r)
    else
      t[WKT.PARAM_ANGULAR_UNIT.getName()] = WKT.VALUE_CONVERSION_FACTOR_DEGREE
    const o = e.optObject(WKT.TAG_PRIMEM)
    if (null != o) this.parsePrimem(o, t)
    else {
      t[WKT.PARAM_PRIMEM_LONGITUDE.getName()] = WKT.VALUE_PRIMEM_GREENWICH
      t[WKT.PARAM_PRIMEM_NAME.getName()] = WKT.NAME_PRIMEM_GREENWICH
    }
    const n = this.getConvertedDoubleValue(WKT.PARAM_PRIMEM_LONGITUDE, t, t)
    const i = e.getString(0)
    const a = e.getObject(1)
    const s = this.parseDatum(a, i, n)
    const A = this.parseWKTAxes(e)
    let l = null
    const T = getUnitOfMeasure('DegreeAngle')
    const c = getUnitOfMeasure('Meter')
    if (A.length > 0) {
      l = []
      for (const e of A) {
        let t, r
        switch (e.wktDirection) {
          case 'EAST':
            t = Axis.Name.X
            r = new Axis(
              e.wktName,
              Axis.Direction.EAST,
              T,
              -180,
              180,
              Axis.RangeMeaning.WRAPAROUND
            )
            break
          case 'NORTH':
            t = Axis.Name.Y
            r = new Axis(
              e.wktName,
              Axis.Direction.NORTH,
              T,
              -90,
              90,
              Axis.RangeMeaning.EXACT
            )
            break
          case 'UP':
            t = Axis.Name.Z
            r = new Axis(
              e.wktName,
              Axis.Direction.UP,
              c,
              Number.NEGATIVE_INFINITY,
              Number.POSITIVE_INFINITY,
              Axis.RangeMeaning.EXACT
            )
            break
          case 'WEST':
            t = Axis.Name.X
            r = new Axis(
              e.wktName,
              Axis.Direction.WEST,
              T,
              -180,
              180,
              Axis.RangeMeaning.WRAPAROUND
            )
            break
          case 'SOUTH':
            t = Axis.Name.Y
            r = new Axis(
              e.wktName,
              Axis.Direction.SOUTH,
              T,
              -90,
              90,
              Axis.RangeMeaning.EXACT
            )
            break
          case 'DOWN':
            t = Axis.Name.Z
            r = new Axis(
              e.wktName,
              Axis.Direction.DOWN,
              c,
              Number.NEGATIVE_INFINITY,
              Number.POSITIVE_INFINITY,
              Axis.RangeMeaning.EXACT
            )
            break
          default:
            throw new Error(
              'Encountered unknown axis direction while parsing geocentric WKT reference'
            )
        }
        const o = { name: t, axis: r }
        l.push(o)
      }
    } else
      l = [
        {
          name: Axis.Name.X,
          axis: new Axis(
            'X',
            Axis.Direction.EAST,
            T,
            -180,
            180,
            Axis.RangeMeaning.WRAPAROUND
          ),
        },
        {
          name: Axis.Name.Y,
          axis: new Axis(
            'Y',
            Axis.Direction.NORTH,
            T,
            -90,
            90,
            Axis.RangeMeaning.EXACT
          ),
        },
      ]
    const u = undefined
    return new GeodeticReference({
      geodeticDatum: s,
      name: i,
      axisInformation: l,
    })
  }
  parseFittedCS(e) {
    const t = e.getString(0)
    const r = e.getObject(1)
    const o = e.getObject(2)
    const n = this.parseRotation(r)
    const i = this.parseReference(o)
    let a
    if (i instanceof GridReference)
      a = {
        projection: i.projection,
        falseEasting: i.falseEasting,
        falseNorthing: i.falseNorthing,
        scale: i.scale,
        name: i.name,
        geodeticDatum: i.geodeticDatum,
        unitOfMeasure: i.unitOfMeasure,
      }
    else if (i instanceof GeodeticReference) {
      const e = TLinGeodeticGridReferenceUtil.createGeodeticGridReference(
        i.getGeodeticDatum()
      )
      a = {
        projection: e.projection,
        falseEasting: e.falseEasting,
        falseNorthing: e.falseNorthing,
        scale: e.scale,
        name: e.name,
        geodeticDatum: e.geodeticDatum,
        unitOfMeasure: e.unitOfMeasure,
      }
    } else
      throw new ParseError(
        `Failed to rotate base reference system of fitted system. (position ${o.getPosition()})`
      )
    a.rotation = n
    a.name = t
    a.axisInformation = this.parseGridAxisInformation(e)
    return new GridReference(a)
  }
  createSpecialReference(e) {
    if (null == e) return null
    e = e.replace(WKT.SPACES, '_')
    if (WKT.REFERENCE_LAMBERT_BELGE_1972.isEquivalentTo(e))
      return new GridReference({
        geodeticDatum: new GeodeticDatum({
          x: -99.059,
          y: 53.322,
          z: -112.486,
          rotX: (0.419 / 3600) * Constants.DEG2RAD,
          rotY: (-0.83 / 3600) * Constants.DEG2RAD,
          rotZ: (1.885 / 3600) * Constants.DEG2RAD,
          scale: 0.999999,
          ellipsoid: Ellipsoid.from({
            a: 6378388,
            oneOverF: 297,
            name: 'INTERNATIONAL',
          }),
          referenceDatum: new GeodeticDatum(),
          name: 'BELGIAN_DATUM_1972',
        }),
        projection: new LambertConformal(
          49 + 50 / 60 + 0.00204 / 3600,
          51 + 10 / 60 + 0.00204 / 3600,
          4.356939722,
          90
        ),
        falseEasting: 150000.01256,
        falseNorthing: 5400088.4378,
        scale: 1,
        unitOfMeasure: 1,
        rotation: (29.2985 * Constants.DEG2RAD) / 3600,
        name: 'Belgian Lambert (1972)',
      })
    if (WKT.REFERENCE_AMERSFOORT_RD_NEW.isEquivalentTo(e))
      return new GridReference({
        geodeticDatum: new GeodeticDatum({
          x: 565.04,
          y: 49.91,
          z: 465.84,
          rotX: -19848e-10,
          rotY: 17439e-10,
          rotZ: -90587e-10,
          scale: 1 - 40772e-10,
          ellipsoid: Ellipsoid.from({
            a: 6377397.155,
            oneOverF: 299.1528128,
            name: 'BESSEL_1841',
          }),
          referenceDatum: new GeodeticDatum(),
          name: 'BESSEL_AMERSFOORT',
        }),
        projection: new DutchStereographic(
          5 + 23 / 60 + 15.5 / 3600,
          52 + 9 / 60 + 22.178 / 3600
        ),
        falseEasting: 155e3,
        falseNorthing: 463e3,
        scale: 0.9999079,
        unitOfMeasure: 1,
        rotation: 0,
        name: 'Dutch RD1918',
      })
    return null
  }
  parseRotation(e) {
    if (!e.hasTag(WKT.TAG_PARAM_MT))
      throw new ParseError(
        `Only PARAM_MT math transforms are supported ${e.getPosition()})`
      )
    const t = undefined
    if ('affine' != e.getString(0).toLowerCase())
      throw new ParseError(
        `Only affine math transforms are supported ${e.getPosition()})`
      )
    const r = this.parseParameters(e, 1)
    const o = getValueAsDouble(r, WKT.PARAM_NUM_ROW)
    const n = getValueAsDouble(r, WKT.PARAM_NUM_COL)
    if (3 != o || 3 != n)
      throw new ParseError(
        `Parameters 'num_row' and 'num_col' must be equal to 3 ${e.getPosition()})`
      )
    const i = []
    for (let e = 0; e < 3; e++) {
      i[e] = []
      for (let t = 0; t < 3; t++) {
        const o = new WKTName([`elt_${e}_${t}`])
        i[e][t] = getValueAsDouble(r, o)
      }
    }
    if (
      i[0][0] != i[1][1] ||
      i[0][1] != -i[1][0] ||
      0 != i[0][2] ||
      0 != i[1][2] ||
      0 != i[2][0] ||
      0 != i[2][1] ||
      1 != i[2][2]
    )
      throw new ParseError(
        `The transformation defined by the WKT parameters is more ` +
          `complex than a pure rotation, which is not currently supported. (position ${e.getPosition()})`
      )
    return Math.atan2(i[1][0], i[0][0])
  }
  parseGeodeticDatum(e) {
    if (null == e) throw new ParseError('WKT geodetic datum string is null')
    const t = e.replace(WKT.LINE_ENDINGS, '')
    try {
      const e = parseWKTObject(t, new ParsePosition())
      return this.parseDatum(e, 'none', 0)
    } catch (e) {
      throw this.createException(t, e)
    }
  }
  parseDatum(e, t, r) {
    checkTag(e, WKT.TAG_DATUM)
    const o = e.getScalar(0)
    const n = e.getObject(1)
    const i = this.parseSpheroid(n)
    let a = null
    const s = e.optDouble(2)
    if (null != s)
      a = [
        s,
        e.optDouble(3, 0),
        e.optDouble(4, 0),
        e.optDouble(5, 0),
        e.optDouble(6, 0),
        e.optDouble(7, 0),
        e.optDouble(8, 0),
      ]
    else {
      const t = e.optObject(2)
      if (null != t && t.hasTag(WKT.TAG_TOWGS84))
        a = [
          t.optDouble(0, 0),
          t.optDouble(1, 0),
          t.optDouble(2, 0),
          t.optDouble(3, 0),
          t.optDouble(4, 0),
          t.optDouble(5, 0),
          t.optDouble(6, 0),
        ]
    }
    const A = this.createGeodeticDatum(o.getValue(), t, i, a, r)
    if (null == A)
      throw new ParseError(
        `Unknown datum '${o.getValue()}' (position ${o.getPosition()})`
      )
    return A
  }
  parseEllipsoid(e) {
    if (null == e) throw new ParseError('WKT ellipsoid string is null')
    const t = e.replace(WKT.LINE_ENDINGS, '')
    try {
      const e = parseWKTObject(t, new ParsePosition())
      return this.parseSpheroid(e)
    } catch (e) {
      throw this.createException(t, e)
    }
  }
  createException(e, t) {
    const r = t.getMessage()
    const o = t.getErrorOffset()
    r.concat('\n').concat(e)
    if (-1 != o) {
      r.append('\n')
      for (let e = o - 1; e >= 0; e--) r.append(' ')
      r.append('^^^^')
    }
    return new ParseError(r.toString() + `(position ${o})`)
  }
  parseSpheroid(e) {
    checkTag(e, WKT.TAG_SPHEROID)
    let t = e.getString(0)
    const r = e.getDouble(1)
    let o = e.optDouble(2)
    let n = e.optDouble(3)
    if (null != n) {
      Log.warn(
        'There are 3 parameters for the ellipse, ' +
          'this is not conform with the OpenGIS specifications.'
      )
      const e = n
      n = o
      o = e
    }
    t = t.replace(WKT.SPACES, '_')
    let i
    try {
      i = this.createEllipsoid(t, r, n, o)
    } catch (t) {
      const r = t instanceof Error ? t.stack : ''
      throw new ParseError(
        `Spheroid is not well formatted, parameter 1 must be the name, parameter 2 and 3 are the semi-major axis and inverse flattening,\n           they be numbers (position ${e.getPosition()})\n${r}`
      )
    }
    return i
  }
  createEllipsoid(e, t, r, o) {
    let n = getEllipsoid(e)
    if (null != n) return n
    let i
    let a
    if (null != o) {
      a = o
      if (0 == a) a = 1 / 0
      n = new Ellipsoid()
      n.initializeA1OverF(t, a)
    } else {
      i = r
      n = new Ellipsoid()
      n.initializeAB(t, i)
    }
    n.name = e
    return n
  }
  createGeodeticDatum(e, t, r, o, n) {
    if (null != r && null != o) return this._createGeodeticDatum(e, r, o, n)
    let i = getDatum(e)
    if (null == i) i = getDatum(e.replace(WKT.SPACES, '_'))
    if (null == i) i = getDatum(e.replace(WKT.DASHES, ''))
    if (null == i)
      if (0 == e.indexOf('D_')) {
        const t = e.substring(2)
        i = getDatum(t)
        if (null == i) i = getDatum(t.replace(WKT.UNDERSCORES, ' '))
      }
    if (null == i) {
      let t = e.replace(WKT.SPACES, '')
      const r = t.indexOf('(')
      if (r > 0) {
        t = t.substring(0, r)
        i = getDatum(t)
      }
    }
    if (null == i) i = getDatum(t)
    return i
  }
  _createGeodeticDatum(e, t, r, o) {
    let n
    const i = new GeodeticDatum()
    if (0 == r[3] && 0 == r[4] && 0 == r[5] && 0 == r[6])
      n = new GeodeticDatum({
        x: r[0],
        y: r[1],
        z: r[2],
        ellipsoid: t,
        primeMeridian: o,
        referenceDatum: i,
        name: e,
      })
    else
      n = new GeodeticDatum({
        x: r[0],
        y: r[1],
        z: r[2],
        rotX: (r[3] / 3600) * Constants.DEG2RAD,
        rotY: (r[4] / 3600) * Constants.DEG2RAD,
        rotZ: (r[5] / 3600) * Constants.DEG2RAD,
        scale: 1 + 1e-6 * r[6],
        ellipsoid: t,
        primeMeridian: o,
        referenceDatum: i,
        name: e,
      })
    return n
  }
  parsePrimem(e, t) {
    checkTag(e, WKT.TAG_PRIMEM)
    t[WKT.PARAM_PRIMEM_NAME.getName()] = e.getString(0)
    t[WKT.PARAM_PRIMEM_LONGITUDE.getName()] = e.getDouble(1)
  }
  parseUnit(e) {
    if (null == e || !e.hasTag(WKT.TAG_UNIT)) return null
    return e.getDouble(1)
  }
  parseParameters(e, t) {
    const r = {}
    while (t < e.getParameterCount()) {
      const o = e.optObject(t++)
      if (null == o || !o.hasTag(WKT.TAG_PARAMETER)) break
      this.parseParameter(o, r)
    }
    return r
  }
  parseParameter(e, t) {
    const r = this.normalizeParameterName(e.getString(0))
    const o = e.getString(1)
    t[r.getName()] = o
  }
  normalizeProjectionName(e) {
    let t = e
    t = t.replace(WKT.SPACES, '_')
    t = t.replace(WKT.DASHES, '_')
    return WKT.getProjectionName(t)
  }
  normalizeParameterName(e) {
    return WKT.getParameterName(e.replace(WKT.SPACES, '_').toLowerCase())
  }
  createProjection(e, t, r, o, n) {
    if (WKT.PROJ_ALBERS_CONIC_EQUAL_AREA.getName() == e.getName())
      return new AlbersEqualAreaConic(
        getValueAsDouble(t, WKT.PARAM_STANDARD_PARALLEL_1),
        getValueAsDouble(t, WKT.PARAM_STANDARD_PARALLEL_2),
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      )
    else if (WKT.PROJ_CASSINI_SOLDNER.getName() == e.getName())
      return new CassiniSoldner(
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      )
    else if (WKT.PROJ_EQUIDISTANT_CYLINDRICAL.getName() == e.getName()) {
      let e
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_STANDARD_PARALLEL_1, t, r)
      } catch (o) {
        e = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      }
      return new EquidistantCylindrical(
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        e
      )
    } else if (
      WKT.PROJ_EQUIDISTANT_CYLINDRICAL_ELLIPSOIDAL.getName() === e.getName()
    ) {
      let e
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_STANDARD_PARALLEL_1, t, r)
      } catch (o) {
        e = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      }
      return new EllipsoidalEquidistantCylindrical(
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        e
      )
    } else if (WKT.PROJ_GAUSS_KRUGER.getName() == e.getName())
      throw new ParseError(`Projection [${e.getName()}] is not supported`)
    else if (WKT.PROJ_KROVAK.getName() == e.getName()) {
      const e = this.getDoubleValueOrDefault(
        WKT.PARAM_LONGITUDE_OF_CENTER,
        0,
        t,
        r
      )
      const o = this.getDoubleValueOrDefault(
        WKT.PARAM_CENTRAL_MERIDIAN,
        e,
        t,
        r
      )
      const n = this.getDoubleValueOrDefault(
        WKT.PARAM_LATITUDE_OF_CENTER,
        0,
        t,
        r
      )
      const i = this.getDoubleValueOrDefault(
        WKT.PARAM_LATITUDE_OF_ORIGIN,
        n,
        t,
        r
      )
      const a = this.getDoubleValueOrDefault(
        WKT.PARAM_PARAMETER_COLATITUDE_OF_CONE_AXIS,
        0,
        t,
        r
      )
      const s = this.getDoubleValueOrDefault(WKT.PARAM_AZIMUTH, a, t, r)
      const A = this.getDoubleValueOrDefault(
        WKT.PARAM_PSEUDO_STANDARD_PARALLEL_1,
        0,
        t,
        r
      )
      return new Krovak(i, o, s, A)
    } else if (WKT.PROJ_LAMBERT_AZIMUTHAL_EQUAL_AREA.getName() == e.getName())
      return new LambertAzimuthalEqualArea(
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      )
    else if (WKT.PROJ_LAMBERT_CONFORMAL_CONIC_1SP.getName() == e.getName())
      return new LambertConformal(
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      )
    else if (
      WKT.PROJ_LAMBERT_CONFORMAL_CONIC_2SP.getName() == e.getName() ||
      WKT.PROJ_LAMBERT_CONFORMAL_CONIC_2SP_BELGIUM.isEquivalentTo(e.getName())
    ) {
      let e
      if (null == t[WKT.PARAM_STANDARD_PARALLEL_2.getName()])
        e = getValueAsDouble(t, WKT.PARAM_STANDARD_PARALLEL_1)
      else e = getValueAsDouble(t, WKT.PARAM_STANDARD_PARALLEL_2)
      return new LambertConformal(
        getValueAsDouble(t, WKT.PARAM_STANDARD_PARALLEL_1),
        e,
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      )
    } else if (WKT.PROJ_MERCATOR_A.getName() == e.getName())
      return new Mercator({
        standardParallel: this.getConvertedDoubleValue(
          WKT.PARAM_CENTRAL_MERIDIAN,
          t,
          r
        ),
      })
    else if (WKT.PROJ_MERCATOR_B.getName() == e.getName()) {
      let e = 0
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_STANDARD_PARALLEL_1, t, r)
      } catch (e) {}
      const o = new Mercator({
        standardParallel: this.getConvertedDoubleValue(
          WKT.PARAM_CENTRAL_MERIDIAN,
          t,
          r
        ),
      })
      o.setTrueScaleLatitude(e)
      return o
    } else if (WKT.PROJ_ORTHOGRAPHIC.getName() == e.getName()) {
      let e
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r)
      } catch (o) {
        e = this.getConvertedDoubleValue(WKT.PARAM_LONGITUDE_OF_CENTER, t, r)
      }
      let o
      try {
        o = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      } catch (e) {
        o = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_CENTER, t, r)
      }
      return new Orthographic(e, o)
    } else if (WKT.PROJ_PSEUDOMERCATOR.getName() == e.getName()) {
      let e
      const o = new PseudoMercator()
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r)
      } catch (t) {
        e = 0
      }
      o.setCentralMeridian(e)
      return o
    } else if (WKT.PROJ_OBLIQUE_MERCATOR_A.getName() == e.getName()) {
      const e =
        WKT.PARAM_EASTING_AT_PROJECTION_CENTER in t &&
        WKT.PARAM_NORTHING_AT_PROJECTION_CENTER in t
          ? Variant.B
          : Variant.A
      return new ObliqueMercator(
        this.getConvertedDoubleValue(WKT.PARAM_LONGITUDE_OF_CENTER, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_CENTER, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_AZIMUTH, t, r),
        e
      )
    } else if (WKT.PROJ_OBLIQUE_MERCATOR_B.getName() == e.getName())
      return new ObliqueMercator(
        this.getConvertedDoubleValue(WKT.PARAM_LONGITUDE_OF_CENTER, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_CENTER, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_AZIMUTH, t, r),
        Variant.B
      )
    else if (WKT.PROJ_SWISS_OBLIQUE_MERCATOR.getName() == e.getName()) {
      const e = new SwissObliqueMercator(
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      )
      e.setAzimuth(this.getConvertedDoubleValue(WKT.PARAM_AZIMUTH, t, r))
      return e
    } else if (WKT.PROJ_POLAR_STEREOGRAPHIC.getName() == e.getName())
      return this.createPolarStereographic(o, t, r, n)
    else if (WKT.PROJ_POLAR_STEREOGRAPHIC_A.getName() == e.getName())
      return this.createPolarStereographicA(o, t, r, n)
    else if (
      WKT.PROJ_POLAR_STEREOGRAPHIC_B.getName() == e.getName() ||
      WKT.PROJ_POLAR_STEREOGRAPHIC_C.getName() == e.getName()
    )
      return this.createPolarStereographicBC(o, t, r, n)
    else if (WKT.PROJ_POLYCONIC.getName() == e.getName())
      return new Polyconic(
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      )
    else if (WKT.PROJ_STEREOGRAPHIC.getName() == e.getName())
      if (
        90 ==
        Math.abs(
          this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
        )
      )
        return this.createPolarStereographic(o, t, r, n)
      else
        return new Stereographic(
          this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
          this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
        )
    else if (WKT.PROJ_TRANSVERSE_MERCATOR.getName() == e.getName()) {
      let e
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      } catch (t) {
        e = 0
      }
      return new TransverseMercator(
        this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r),
        e
      )
    } else if (WKT.PROJ_GNOMONIC.getName() == e.getName()) {
      let e, o
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r)
      } catch (t) {
        e = 0
      }
      try {
        o = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      } catch (e) {
        o = 0
      }
      return new Gnomonic(e, o)
    } else if (WKT.PROJ_AZIMUTHAL_EQUIDISTANT.getName() == e.getName()) {
      let e
      let o
      let n
      let i
      try {
        n = this.getConvertedDoubleValue(WKT.PARAM_LONGITUDE_OF_CENTER, t, r)
      } catch (e) {
        n = 0
      }
      try {
        i = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_CENTER, t, r)
      } catch (e) {
        i = 0
      }
      try {
        e = this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r)
      } catch (t) {
        e = n
      }
      try {
        o = this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r)
      } catch (e) {
        o = i
      }
      return new AzimuthalEquidistant(e, o)
    }
    throw new ParseError(`Projection [${e.getName()}] is not supported`)
  }
  createPolarStereographic(e, t, r, o) {
    if (!(WKT.PARAM_STANDARD_PARALLEL_1 in t))
      return this.createPolarStereographicA(e, t, r, o)
    else return this.createPolarStereographicBC(e, t, r, o)
  }
  createPolarStereographicBC(e, t, r, o) {
    const n = this.getConvertedDoubleValue(WKT.PARAM_STANDARD_PARALLEL_1, t, r)
    const i = n >= 0 ? Pole.NORTH_POLE : Pole.SOUTH_POLE
    const a = new PolarStereographic(i, o?.polarStereographicLatitudeExtent)
    const s = this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r)
    a.setTrueScaleLatitude(n)
    a.setCentralMeridian(s)
    return a
  }
  createPolarStereographicA(e, t, r, o) {
    const n = undefined
    let i
    if (90 == this.getConvertedDoubleValue(WKT.PARAM_LATITUDE_OF_ORIGIN, t, r))
      i = Pole.NORTH_POLE
    else i = Pole.SOUTH_POLE
    const a = new PolarStereographic(i, o?.polarStereographicLatitudeExtent)
    try {
      const e = this.getConvertedDoubleValue(
        WKT.PARAM_STANDARD_PARALLEL_1,
        t,
        r
      )
      a.setTrueScaleLatitude(e)
    } catch (e) {}
    try {
      const e = this.getConvertedDoubleValue(WKT.PARAM_CENTRAL_MERIDIAN, t, r)
      a.setCentralMeridian(e)
    } catch (e) {}
    return a
  }
  getConvertedDoubleValue(e, t, r) {
    const o = getValueAsDouble(t, e)
    let n = o
    if (
      e == WKT.PARAM_LONGITUDE_OF_CENTER ||
      e == WKT.PARAM_LONGITUDE_OF_POINT_1 ||
      e == WKT.PARAM_LONGITUDE_OF_POINT_2 ||
      e == WKT.PARAM_CENTRAL_MERIDIAN ||
      e == WKT.PARAM_PRIMEM_LONGITUDE ||
      e == WKT.PARAM_LATITUDE_OF_CENTER ||
      e == WKT.PARAM_LATITUDE_OF_ORIGIN ||
      e == WKT.PARAM_LATITUDE_OF_POINT_1 ||
      e == WKT.PARAM_LATITUDE_OF_POINT_2 ||
      e == WKT.PARAM_STANDARD_PARALLEL_1 ||
      e == WKT.PARAM_STANDARD_PARALLEL_2 ||
      e == WKT.PARAM_AZIMUTH ||
      e == WKT.PARAM_RECTIFIED_GRID_ANGLE
    ) {
      let e = 1
      const t = r[WKT.PARAM_ANGULAR_UNIT.getName()]
      if (null != t) {
        const r = t
        if (!isEquivalent(r, WKT.VALUE_CONVERSION_FACTOR_DEGREE))
          e = r / WKT.VALUE_CONVERSION_FACTOR_DEGREE
      }
      n = o * e
    }
    return n
  }
  getDoubleValueOrDefault(e, t, r, o) {
    try {
      return this.getConvertedDoubleValue(e, r, o)
    } catch (e) {
      return t
    }
  }
  parseWKTAxes(e) {
    const t = []
    const r = e.getObjectsWithTag(WKT.TAG_AXIS)
    for (const e of r) {
      const r = undefined
      const o = undefined
      const n = {
        wktName: e.getString(0),
        wktDirection: e.getString(1).toUpperCase(),
      }
      t.push(n)
    }
    return t
  }
  parseGridAxisInformation(e) {
    const t = this.parseWKTAxes(e)
    let r = null
    const o = getUnitOfMeasure('Meter')
    if (t.length > 0) {
      r = []
      for (const e of t) {
        let t, n
        switch (e.wktDirection) {
          case 'EAST':
            t = Axis.Name.X
            n = Axis.Direction.EAST
            break
          case 'NORTH':
            t = Axis.Name.Y
            n = Axis.Direction.NORTH
            break
          case 'UP':
            t = Axis.Name.Z
            n = Axis.Direction.UP
            break
          case 'WEST':
            t = Axis.Name.X
            n = Axis.Direction.WEST
            break
          case 'SOUTH':
            t = Axis.Name.Y
            n = Axis.Direction.SOUTH
            break
          case 'DOWN':
            t = Axis.Name.Z
            n = Axis.Direction.DOWN
            break
          default:
            throw new Error(
              'Encountered unknown axis direction while parsing geocentric WKT reference'
            )
        }
        const i = { name: t, axis: new Axis(e.wktName, n, o, null, null, null) }
        r.push(i)
      }
    } else
      r = [
        {
          name: Axis.Name.X,
          axis: new Axis('X', Axis.Direction.EAST, o, null, null, null),
        },
        {
          name: Axis.Name.Y,
          axis: new Axis('Y', Axis.Direction.NORTH, o, null, null, null),
        },
      ]
    return r
  }
  createGridReference(e, t, r, o, n, i, a) {
    const s = this.createUTMGridReference(e, t, r, o, n)
    if (null !== s) return s
    const A = this.createUPSGridReference(e, t, r, o, n)
    if (null !== A) return A
    let l
    let T
    l = n[WKT.PARAM_FALSE_EASTING.getName()]
    if (null != l) T = getValueAsDouble(n, WKT.PARAM_FALSE_EASTING)
    else {
      l = n[WKT.PARAM_EASTING_AT_PROJECTION_CENTER.getName()]
      if (null != l)
        T = getValueAsDouble(n, WKT.PARAM_EASTING_AT_PROJECTION_CENTER)
    }
    let c
    l = n[WKT.PARAM_FALSE_NORTHING.getName()]
    if (null != l) c = getValueAsDouble(n, WKT.PARAM_FALSE_NORTHING)
    else {
      l = n[WKT.PARAM_NORTHING_AT_PROJECTION_CENTER.getName()]
      if (null != l)
        c = getValueAsDouble(n, WKT.PARAM_NORTHING_AT_PROJECTION_CENTER)
    }
    let u
    l = n[WKT.PARAM_SCALE_FACTOR.getName()]
    if (null != l) u = getValueAsDouble(n, WKT.PARAM_SCALE_FACTOR)
    let R
    l = n[WKT.PARAM_LINEAR_UNIT.getName()]
    if (null != l) R = getValueAsDouble(n, WKT.PARAM_LINEAR_UNIT)
    const _ = {
      projection: r,
      falseEasting: T,
      falseNorthing: c,
      scale: u,
      unitOfMeasure: R,
      geodeticDatum: o,
      name: e,
      axisInformation: a,
    }
    if (
      r instanceof PolarStereographic &&
      WKT.PROJ_POLAR_STEREOGRAPHIC_C.getName() === t.getName()
    ) {
      const e = r
      const t = e.getPole() == Pole.NORTH_POLE ? 1 : -1
      const n = o.ellipsoid
      const i = n.e
      const a = n.a
      const s = e.getTrueScaleLatitude() * Constants.DEG2RAD
      const A = i * Math.sin(s)
      const l = undefined
      const T = undefined
      let u = c
      u += t * (a * (Math.cos(s) / Math.sqrt(1 - A * A)))
      _.falseNorthing = u
    } else if (
      WKT.PROJ_OBLIQUE_MERCATOR_A.getName() === t.getName() ||
      WKT.PROJ_OBLIQUE_MERCATOR_B.getName() === t.getName()
    ) {
      const e = this.getConvertedDoubleValue(WKT.PARAM_AZIMUTH, n, i)
      const t = this.getConvertedDoubleValue(
        WKT.PARAM_RECTIFIED_GRID_ANGLE,
        n,
        i
      )
      _.rotation = Constants.DEG2RAD * (t - e)
    }
    return new GridReference(_)
  }
  createUTMGridReference(e, t, r, o, n) {
    let i = -1
    if (null != e) i = e.toLowerCase().indexOf('utm zone')
    try {
      if (-1 != i && r.TYPE === ProjectionType.TRANSVERSE_MERCATOR) {
        const t = r
        const i = t.getCentralMeridian()
        const a = t.getOriginLat()
        const s = optValueAsDouble(n, WKT.PARAM_FALSE_EASTING, Number.NaN)
        const A = optValueAsDouble(n, WKT.PARAM_FALSE_NORTHING, Number.NaN)
        const l = optValueAsDouble(n, WKT.PARAM_SCALE_FACTOR, Number.NaN)
        const T = optValueAsDouble(n, WKT.PARAM_LINEAR_UNIT, Number.NaN)
        if (
          Math.round(i) === i &&
          0 !== i &&
          i % 3 === 0 &&
          0 === a &&
          5e5 === s &&
          (0 === A || 1e7 === A) &&
          0.9996 === l &&
          1 === T
        ) {
          const t = UTMGridSystem.prototype.retrieveZone(
            new LLHPoint(null, [i, 0, 0])
          )
          const r = new UTMGrid(t, 0 === A, o)
          r.name = e
          return r
        }
      }
    } catch (e) {}
    return null
  }
  createUPSGridReference(e, t, r, o, n) {
    let i = -1
    if (null != e) i = e.toLowerCase().indexOf('ups')
    try {
      if (-1 != i) {
        const t = r.getCentralMeridian()
        const i = r.getTrueScaleLatitude()
        const a = optValueAsDouble(n, WKT.PARAM_FALSE_EASTING, Number.NaN)
        const s = optValueAsDouble(n, WKT.PARAM_FALSE_NORTHING, Number.NaN)
        const A = optValueAsDouble(n, WKT.PARAM_SCALE_FACTOR, Number.NaN)
        const l = optValueAsDouble(n, WKT.PARAM_LINEAR_UNIT, Number.NaN)
        if (
          0 == t &&
          (90 == i || -90 == i) &&
          2e6 == a &&
          2e6 == s &&
          0.994 == A &&
          1 == l
        ) {
          const t = new UPSGrid(90 == i, o)
          t.name = e
          return t
        }
      }
    } catch (e) {}
    return null
  }
}
