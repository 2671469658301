import { isNumber } from '../../../../util/Lang.js'
import { Length } from './util/Length.js'
import { PatternType } from './util/PatternType.js'
import { Pattern } from '../Pattern.js'
const DEFAULT_GAP_LENGTH = 10
export class GapPattern extends Pattern {
  constructor(t, e) {
    super(PatternType.GAP)
    t = !isNumber(t) ? DEFAULT_GAP_LENGTH : t
    const n = e ? 0 : t
    const i = e ? t : 0
    this._length = new Length({ fixedLength: n, relativeLength: i })
    this.canBend = true
  }
  getMinHeight() {
    return 0
  }
  getMaxHeight() {
    return 0
  }
  get worldSizeContext() {
    return this._length.worldSizeContext
  }
  set worldSizeContext(t) {
    this._length.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._length.getUomSizeToPixels(t)
  }
  getWidth(t) {
    return this._length.getWidth(t)
  }
  isRelativeLength() {
    return this._length.isRelativeLength()
  }
  get fixedLength() {
    return this._length.fixedLength
  }
  set fixedLength(t) {
    this._length.fixedLength = t
  }
  get relativeLength() {
    return this._length.relativeLength
  }
  set relativeLength(t) {
    this._length.relativeLength = t
  }
  paintPartial(t, e, n, i) {
    const r = n - e.distanceFromStart()
    if (r > 0) e.advanceDistance(r)
  }
  paint() {}
  paintFlexible(t, e, n) {}
  appendHash(t) {
    this._length.appendHash(t)
  }
}
