import {
  createBounds,
  createComplexPolygon,
  createExtrudedShape,
  createPoint,
  createPointInternal,
  createPolygon,
  createPolyline,
  createShapeList,
} from '../../shape/ShapeFactory.js'
import {
  getKmlAltitudeMode,
  KMLAltitudeMode,
} from '../../util/kml/KMLAltitudeMode.js'
import {
  xmlFilterMapChildren,
  xmlFindFirstChild,
  xmlReduceChildren,
  xmlWalkChildren,
} from '../../util/kml/KMLInternalUtil.js'
import { getModelGeometryConstructorOptions } from '../../util/kml/KMLParse.js'
import {
  isAltitudeModeValue,
  isCoordinatesValue,
  isEastValue,
  isExtrudeValue,
  isInnerBoundaryIsNode,
  isLatLonBoxNode,
  isLatLonQuadNode,
  isLinearRingNode,
  isLineStringNode,
  isLocationNode,
  isModelNode,
  isMultiGeometryNode,
  isNorthValue,
  isOrientationNode,
  isOuterBoundaryIsNode,
  isPointNode,
  isPolygonNode,
  isSouthValue,
  isWestValue,
} from '../../util/kml/KMLTypes.js'
import { getCoordinateReference } from '../../util/kml/KMLUtil.js'
import {
  getKmlAngle180,
  getKmlAngle360,
  getKmlBoolean,
  getKmlValue,
  getLocationValue,
} from '../../util/kml/KMLValues.js'
import { isNumber, isUndefined } from '../../util/Lang.js'
import { KMLModelGeometry } from './KMLModelGeometry.js'
export class KMLGeometryBuilder {
  constructor(e) {
    let t =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : true
    let i =
      arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : true
    this._node = e
    this._shapeArray = []
    this._modelArray = []
    this._errorMessages = []
    this._metadataBuilder = new GeometryMetadataBuilder(t, i)
    this._pointMetadataBuilder = new GeometryMetadataBuilder(false, i)
    this._mgMetadataBuilder = new GeometryMetadataBuilder(true, true)
  }
  get errors() {
    if (this._errorMessages.length > 0) return this._errorMessages
    return null
  }
  get models() {
    this.build()
    if (this._modelArray.length > 0) return this._modelArray
    return null
  }
  build() {
    if (this._node) {
      xmlWalkChildren(this._node, this.buildGeometry.bind(this))
      this._node = null
    }
    return this._shapeArray
  }
  buildGeometry(e) {
    if (isMultiGeometryNode(e)) return this.insertMultiGeometryNode(e)
    if (isPointNode(e)) return this.insertPointNode(e)
    if (isLineStringNode(e)) return this.insertLineStringNode(e)
    if (isLinearRingNode(e)) return this.insertLinearRingNode(e)
    if (isPolygonNode(e)) return this.insertPolygonNode(e)
    if (isModelNode(e)) return this.insertModelNode(e)
    if (isLatLonBoxNode(e)) return this.insertLatLonBoxNode(e)
    if (isLatLonQuadNode(e)) return this.insertLatLonQuadNode(e)
  }
  insertMultiGeometryNode(e) {
    const t = new KMLGeometryBuilder(e, true, true)
    const i = t.build()
    if (null !== i) {
      const t = xmlFindFirstChild(e, isAltitudeModeValue)
      const o = getKmlAltitudeMode(t)
      this._shapeArray.push(createShapeList(getCoordinateReference(o), i))
    }
    const o = t.models
    if (null !== o) this._modelArray.push(...o)
  }
  insertPointNode(e) {
    const t = this._pointMetadataBuilder.build(e)
    if (null !== t.coordinates)
      this._shapeArray.push(
        applyShapeTransformations(
          t,
          createPointInternal(t.reference, t.coordinates[0])
        )
      )
  }
  insertLineStringNode(e) {
    const t = this._metadataBuilder.build(e)
    if (null !== t.coordinates)
      this._shapeArray.push(
        applyShapeTransformations(t, createPolyline(t.reference, t.coordinates))
      )
  }
  insertLinearRingNode(e) {
    const t = this._metadataBuilder.build(e)
    if (null !== t.coordinates)
      this._shapeArray.push(
        applyShapeTransformations(t, createPolygon(t.reference, t.coordinates))
      )
  }
  insertPolygonNode(e) {
    const t = xmlReduceChildren(e, parsePolygonBoundaries, [null])
    if (!t || !t[0]) return
    const i = this._metadataBuilder.build(e)
    if (i.extrude) {
      i.height = t[0].getPointCoordinates(0)[2]
      for (const e of t) {
        e.bounds.depth = 0
        e.bounds.z = 0
      }
    }
    const o = 1 === t.length ? t[0] : createComplexPolygon(i.reference, t)
    this._shapeArray.push(applyShapeTransformations(i, o))
  }
  insertModelNode(e) {
    const t = getModelGeometryConstructorOptions(e)
    const i = new KMLModelGeometry(t)
    this._modelArray.push(i)
  }
  insertLatLonBoxNode(e) {
    const t = this._pointMetadataBuilder.build(e)
    const i = new OverlayBox()
    xmlReduceChildren(e, parseLatLonBox, i)
    const { north: o, south: r, east: s, west: n } = i
    if (isUndefined(o) || isUndefined(r) || isUndefined(s) || isUndefined(n)) {
      this._errorMessages.push(
        `GroundOverlay LatLonBox missing information, ${i.errorString()}`
      )
      return
    }
    const l = createBounds(t.reference, [n, 0, r, 0])
    l.setToIncludePoint2D(createPoint(t.reference, [s, o]))
    this._shapeArray.push(l)
  }
  insertLatLonQuadNode(e) {
    const t = this._pointMetadataBuilder.build(e)
    if (null != t.coordinates) {
      const e = createPolygon(t.reference, t.coordinates)
      this._shapeArray.push(e)
    }
  }
}
function parsePolygonBoundaries(e, t) {
  if (null === e) return null
  if (isInnerBoundaryIsNode(t)) {
    e.push(...xmlFilterMapChildren(t, filterMapLinearRings))
    return e
  }
  if (isOuterBoundaryIsNode(t)) {
    if (null !== e[0]) return null
    const i = xmlFilterMapChildren(t, filterMapLinearRings)
    if (1 !== i.length) return null
    e[0] = i[0]
    return e
  }
  return e
}
function filterMapLinearRings(e) {
  if (isLinearRingNode(e)) {
    const t = new GeometryMetadataBuilder(false, false).build(e)
    const i = t.coordinates
      ? applyShapeTransformations(t, createPolygon(t.reference, t.coordinates))
      : null
    if (i) return i
  }
  return
}
function applyShapeTransformations(e, t) {
  const i = e.extrude ? createExtrudedShape(e.reference, t, 0, e.height) : t
  i.altitudeMode = e.altitudeMode
  return i
}
function parseLatLonBox(e, t) {
  if (isSouthValue(t))
    e.south = getKmlAngle180(t, parseFloat(t.nodeValue ? t.nodeValue : ''))
  else if (isNorthValue(t))
    e.north = getKmlAngle180(t, parseFloat(t.nodeValue ? t.nodeValue : ''))
  else if (isWestValue(t))
    e.west = getKmlAngle360(t, parseFloat(t.nodeValue ? t.nodeValue : ''))
  else if (isEastValue(t))
    e.east = getKmlAngle360(t, parseFloat(t.nodeValue ? t.nodeValue : ''))
  return e
}
class GeometryMetadataBuilder {
  constructor(e, t) {
    this.allowExtrude = e
    this.useAltitudeMode = t
    this.extrude = false
    this.coordinates = null
    this.altitudeMode = KMLAltitudeMode.RELATIVE_TO_GROUND
  }
  build(e) {
    const t = new GeometryMetadataBuilder(
      this.allowExtrude,
      this.useAltitudeMode
    )
    t.processChildOptions(e)
    const i = getCoordinateReference(t.altitudeMode)
    const o = t.parseCoordinates(i)
    const r = null !== o && 0 !== o.length ? o[0][2] : 0
    const s =
      t.useAltitudeMode && t.altitudeMode === KMLAltitudeMode.CLAMP_TO_GROUND
    const n = t.allowExtrude && t.extrude
    if ((n || s) && null !== o) for (const e of o) e[2] = 0
    return {
      altitudeMode: t.altitudeMode,
      extrude: n,
      height: r,
      coordinates: null === o || 0 === o.length ? null : o,
      reference: i,
    }
  }
  processChildOptions(e) {
    if (isModelNode(e))
      return xmlWalkChildren(e, this.processModelChildOptions.bind(this))
    return xmlWalkChildren(e, this.processGeometryChildOption.bind(this))
  }
  processModelChildOptions(e) {
    if (isLocationNode(e)) {
      const { lat: t, lon: i, alt: o } = getLocationValue(e)
      this.coordinates = `${i},${t},${o}`
      return this
    }
    if (isOrientationNode(e)) return this
    return this
  }
  processGeometryChildOption(e) {
    const t = getKmlValue(e)
    if (isCoordinatesValue(e) && null !== t) {
      this.coordinates = t
      return this
    }
    if (isAltitudeModeValue(e)) {
      this.altitudeMode = getKmlAltitudeMode(t)
      return this
    }
    if (isExtrudeValue(e) && this.allowExtrude) {
      this.extrude = getKmlBoolean(e)
      return this
    }
    return this
  }
  parseCoordinates(e) {
    if (null === this.coordinates) return null
    const t =
      /([+\-]?\d+(?:\.\d+)?)\s*,\s*([+\-]?\d+(?:\.\d+)?)(?:\s*,\s*([+\-]?\d+(?:\.\d+)?))?/g
    const i = []
    let o
    let r = 0
    while ((o = t.exec(this.coordinates)) && r < t.lastIndex) {
      r = t.lastIndex
      const e = [parseFloat(o[1]), parseFloat(o[2]), parseFloat(o[3])]
      if (e[2] !== e[2]) e[2] = 0
      i.push(e)
    }
    return i
  }
}
export class OverlayBox {
  constructor() {
    this._north = void 0
    this._south = void 0
    this._east = void 0
    this._west = void 0
  }
  get west() {
    return this._west
  }
  set west(e) {
    this._west = isNumber(e, false) ? e : void 0
  }
  get east() {
    return this._east
  }
  set east(e) {
    this._east = isNumber(e, false) ? e : void 0
  }
  get south() {
    return this._south
  }
  set south(e) {
    this._south = isNumber(e, false) ? e : void 0
  }
  get north() {
    return this._north
  }
  set north(e) {
    this._north = isNumber(e, false) ? e : void 0
  }
  errorString() {
    const e = []
    if (isUndefined(this.north)) e.push('north is undefined')
    if (isUndefined(this.south)) e.push('south is undefined')
    if (isUndefined(this.west)) e.push('west is undefined')
    if (isUndefined(this.east)) e.push('east is undefined')
    return e.join(', ')
  }
}
