import { NotImplementedError } from '../../../error/NotImplementedError.js'
import { CGOperatorConvertShape } from '../CGOperatorConvertShape.js'
import { CGUtil } from '../CGUtil.js'
function LineSegmentChooser() {}
LineSegmentChooser.prototype = Object.create(Object.prototype)
LineSegmentChooser.prototype.constructor = LineSegmentChooser
LineSegmentChooser.prototype.walk = function (e, t) {
  var o = []
  var r = e
  while (true) {
    var n
    if ((n = null !== r && this._isSegmentOk(r))) {
      if (null === r.previous || r.previous === e) break
      r = r.previous
    } else break
  }
  var i = r
  var l = true
  while (true) {
    var n
    if (!(n = null !== r && this._isSegmentOk(r))) {
      if (o.length > 0) {
        if (null !== r && o.length > 0) CGUtil.appendPointIfNotEqual(r.pos, o)
        if (1 === o.length);
        else {
          var s = o[0] === o[o.length - 1]
          t.elements.push(
            CGOperatorConvertShape.pointListToVertices(o, 0, s, false)
          )
        }
        if (o.length > 0) o = []
      }
      if (null === r) break
      if (!l && r === i) CGUtil.appendPointIfNotEqual(r.pos, o)
    } else {
      CGUtil.appendPointIfNotEqual(r.pos, o)
      r.used = true
    }
    if (!l && r === i) r = null
    if (null !== r) {
      r = r.next
      l = false
    }
  }
  if (1 === o.length);
  else if (o.length > 1) {
    var s = o[0] === o[o.length - 1]
    t.elements.push(CGOperatorConvertShape.pointListToVertices(o, 0, s, false))
  }
  CGUtil.removeDuplicatePoints(t)
}
LineSegmentChooser.prototype._isSegmentOk = function (e) {
  return !e.used && (null === e.otherLineSegment || !e.otherLineSegment.used)
    ? this.isSegmentOkAbstract(e)
    : false
}
LineSegmentChooser.prototype.isSegmentOkAbstract = function (e) {
  throw new NotImplementedError()
}
export { LineSegmentChooser }
