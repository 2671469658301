import { XMLSchema } from '../../util/XMLSchema.js'
import { WFSCapabilitiesSchemaCommon } from './WFSCapabilitiesSchemaCommon.js'
const attribute = XMLSchema.attribute
const content = XMLSchema.content
const CodeType = { '#text': content(), codeSpace: attribute() }
const KeywordsType = [
  { Keyword: [{ '#text_only': content() }], Type: CodeType },
]
const GeometryOperands = { GeometryOperand: [{ name: attribute() }] }
const OnlineResourceType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
}
const MetadataType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
  about: attribute(),
}
const RequestMethodType = {
  type: attribute(),
  href: attribute(),
  role: attribute(),
  arcrole: attribute(),
  title: attribute(),
  show: attribute(),
  actuate: attribute(),
  Constraint: [
    {
      name: attribute(),
      Value: [{ '#text_only': content() }],
      Metadata: [MetadataType],
    },
  ],
}
const DomainType = {
  name: attribute(),
  AllowedValues: {
    Value: [{ '#text_only': content() }],
    Range: [
      {
        rangeClosure: attribute(),
        MinimumValue: content(),
        MaximumValue: content(),
        Spacing: content(),
      },
    ],
  },
  AnyValue: {},
  NoValues: {},
  ValuesReference: { reference: attribute(), '#text': content() },
  DefaultValue: content(),
  Meaning: { reference: attribute(), '#text': content() },
  DataType: { reference: attribute(), '#text': content() },
  UOM: { reference: attribute(), '#text': content() },
  ReferenceSystem: { reference: attribute(), '#text': content() },
  Metadata: [MetadataType],
}
const OutputFormatListType = { Format: [{ '#text_only': content() }] }
const TemporalOperandType = [{ name: attribute() }]
const TemporalOperands = { TemporalOperand: TemporalOperandType }
export const WFSCapabilitiesSchemaV200 = {
  WFS_Capabilities: {
    version: attribute(),
    updateSequence: attribute(),
    ServiceIdentification: {
      Title: content(),
      Abstract: content(),
      Keywords: KeywordsType,
      ServiceType: CodeType,
      ServiceTypeVersion: [{ '#text_only': content() }],
      Fees: content(),
      AccessConstraints: [{ '#text_only': content() }],
    },
    ServiceProvider: {
      ProviderName: content(),
      ProviderSite: OnlineResourceType,
      ServiceContact: {
        IndividualName: content(),
        PositionName: content(),
        ContactInfo: {
          Phone: {
            Voice: [{ '#text_only': content() }],
            Facsimile: [{ '#text_only': content() }],
          },
          Address: {
            DeliveryPoint: [{ '#text_only': content() }],
            City: content(),
            AdministrativeArea: content(),
            PostalCode: content(),
            Country: content(),
            ElectronicMailAddress: [{ '#text_only': content() }],
          },
          OnlineResource: OnlineResourceType,
          HoursOfService: content(),
          ContactInstructions: content(),
        },
        Role: CodeType,
      },
    },
    OperationsMetadata: {
      Operation: [
        {
          name: attribute(),
          DCP: [{ HTTP: { Get: RequestMethodType, Post: RequestMethodType } }],
          Parameter: [DomainType],
          Constraint: [DomainType],
          Metadata: [MetadataType],
        },
      ],
      Parameter: [DomainType],
      Constraint: [DomainType],
    },
    FeatureTypeList: {
      FeatureType: [
        {
          Namespace: WFSCapabilitiesSchemaCommon.FeatureTypeNamespaceSchema,
          Name: content(),
          Title: [{ '#text_only': content() }],
          Abstract: [{ '#text_only': content() }],
          Keywords: KeywordsType,
          DefaultCRS: content(),
          OtherCRS: [{ '#text_only': content() }],
          NoCRS: {},
          OutputFormats: OutputFormatListType,
          WGS84BoundingBox: [
            {
              LowerCorner: content(),
              UpperCorner: content(),
              crs: attribute(),
              dimensions: attribute(Number),
            },
          ],
          MetadataURL: [
            {
              format: attribute(),
              type: attribute(),
              href: attribute(),
              role: attribute(),
              arcrole: attribute(),
              title: attribute(),
              show: attribute(),
              actuate: attribute(),
              about: attribute(),
            },
          ],
          ExtendedDescription: {
            Element: [
              {
                Metadata: MetadataType,
                ValueList: { Value: [{ '#text_only': content() }] },
              },
            ],
          },
        },
      ],
    },
    WSDL: {
      type: attribute(),
      href: attribute(),
      role: attribute(),
      arcrole: attribute(),
      title: attribute(),
      show: attribute(),
      actuate: attribute(),
    },
    Filter_Capabilities: {
      Conformance: { Constraint: [DomainType] },
      Id_Capabilities: {
        ResourceIdentifier: [{ name: attribute(), Metadata: MetadataType }],
      },
      Scalar_Capabilities: {
        LogicalOperators: {},
        ComparisonOperators: { ComparisonOperator: [{ name: attribute() }] },
      },
      Spatial_Capabilities: {
        GeometryOperands: GeometryOperands,
        SpatialOperators: {
          SpatialOperator: [
            { name: attribute(), GeometryOperands: GeometryOperands },
          ],
        },
      },
      Temporal_Capabilities: {
        TemporalOperands: TemporalOperands,
        TemporalOperators: {
          TemporalOperator: [
            { name: attribute(), TemporalOperands: TemporalOperands },
          ],
        },
      },
      Functions: {
        Function: [
          {
            name: attribute(),
            Metadata: MetadataType,
            Returns: content(),
            Arguments: {
              Argument: [
                { name: attribute(), Metadata: MetadataType, Type: content() },
              ],
            },
          },
        ],
      },
      Extended_Capabilities: {
        AdditionalOperators: { Operator: [{ name: attribute() }] },
      },
    },
  },
}
