import { LabelPositions } from './LabelPositions.js'
var PointLabelPosition = (function (T) {
  T[(T['CENTER'] = 1 << LabelPositions.POINT_C_SHIFT)] = 'CENTER'
  T[(T['SOUTH_EAST'] = 1 << LabelPositions.POINT_SE_SHIFT)] = 'SOUTH_EAST'
  T[(T['NORTH_WEST'] = 1 << LabelPositions.POINT_NW_SHIFT)] = 'NORTH_WEST'
  T[(T['NORTH_EAST'] = 1 << LabelPositions.POINT_NE_SHIFT)] = 'NORTH_EAST'
  T[(T['SOUTH_WEST'] = 1 << LabelPositions.POINT_SW_SHIFT)] = 'SOUTH_WEST'
  T[(T['EAST'] = 1 << LabelPositions.POINT_E_SHIFT)] = 'EAST'
  T[(T['WEST'] = 1 << LabelPositions.POINT_W_SHIFT)] = 'WEST'
  T[(T['NORTH'] = 1 << LabelPositions.POINT_N_SHIFT)] = 'NORTH'
  T[(T['SOUTH'] = 1 << LabelPositions.POINT_S_SHIFT)] = 'SOUTH'
  T[
    (T['ANY'] =
      T.NORTH_EAST |
      T.SOUTH_EAST |
      T.NORTH_WEST |
      T.SOUTH_WEST |
      T.EAST |
      T.WEST |
      T.NORTH |
      T.SOUTH |
      T.CENTER)
  ] = 'ANY'
  return T
})(PointLabelPosition || {})
export { PointLabelPosition }
