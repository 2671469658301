import { isBoolean, isFunction } from '../util/Lang.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { AmbientOcclusionEffectImpl } from './AmbientOcclusionEffect.js'
import { EyeDomeLightingEffectImpl } from './EyeDomeLightingEffect.js'
import { DepthOfFieldEffectImpl } from './DepthOfFieldEffect.js'
import { EnvironmentMapEffectImpl } from './EnvironmentMapEffect.js'
import { createHeadLight } from './LightEffect.js'
import { LightScatteringAtmosphere } from './LightScatteringAtmosphere.js'
import { BloomEffectImpl } from './BloomEffect.js'
class GraphicsEffects {
  constructor(e) {
    this._listener = e
    this.light = createHeadLight()
    this.atmosphere = true
    this.starfield = true
    this.ambientOcclusion = null
    this.depthOfField = null
    this.eyeDomeLighting = null
    this.antiAliasing = false
    this.bloom = new BloomEffectImpl({})
    this.environmentMap = null
  }
  set listener(e) {
    this._listener = e
    this.light = this.light
    this.atmosphere = this.atmosphere
    this.starfield = this.starfield
    this.ambientOcclusion = this.ambientOcclusion
    this.depthOfField = this.depthOfField
    this.eyeDomeLighting = this.eyeDomeLighting
    this.antiAliasing = this.antiAliasing
    this.bloom = this.bloom
    this.environmentMap = this.environmentMap
  }
  get light() {
    return this._light
  }
  set light(e) {
    if (null !== e && !isFunction(e.visitLight))
      throw new ProgrammingError(
        'You must use LightEffect.create to create a light (got [' +
          e +
          '] instead).'
      )
    this._light = e
    if (this._listener)
      if (this._light) this._light.visitLight(this._listener)
      else this._listener.useNoLight()
  }
  get atmosphere() {
    return this._atmosphere
  }
  set atmosphere(e) {
    if (isBoolean(e)) this._atmosphere = e
    else if (null === e) this._atmosphere = null
    else if (e instanceof LightScatteringAtmosphere) this._atmosphere = e
    else
      throw new ProgrammingError(
        'GraphicsEffects.atmosphere must be a LightScatteringAtmosphere, null or a boolean.'
      )
    if (this._listener && isFunction(this._listener.atmosphereChanged))
      if (this._atmosphere)
        this._listener.atmosphereChanged(true, this._atmosphere)
      else this._listener.atmosphereChanged(false, false)
  }
  get starfield() {
    return this._starfield
  }
  set starfield(e) {
    if (!isBoolean(e))
      throw new ProgrammingError('GraphicsEffects.starfield must be a boolean!')
    this._starfield = e
    if (this._listener && isFunction(this._listener.starfieldChanged))
      this._listener.starfieldChanged(this._starfield)
  }
  get ambientOcclusion() {
    return this._ssao
  }
  set ambientOcclusion(e) {
    if (e) this._ssao = new AmbientOcclusionEffectImpl(e)
    else this._ssao = null
    if (this._listener && isFunction(this._listener.useSSAO))
      if (this._ssao)
        this._listener.useSSAO(true, this._ssao.radius, this._ssao.power)
      else this._listener.useSSAO(false, 30, 1)
  }
  get depthOfField() {
    return this._dof
  }
  set depthOfField(e) {
    if (e) this._dof = new DepthOfFieldEffectImpl(e)
    else this._dof = null
    if (this._listener && isFunction(this._listener.useDOF))
      if (this._dof)
        this._listener.useDOF(
          true,
          this._dof.scale,
          this._dof.focalDepth,
          this._dof.focusRange,
          this._dof.noFocusRange
        )
      else this._listener.useDOF(false, 1, 1e3, 1e6, 1e7)
  }
  get eyeDomeLighting() {
    return this._edl
  }
  set eyeDomeLighting(e) {
    if (e) this._edl = new EyeDomeLightingEffectImpl(e)
    else this._edl = null
    if (this._listener && isFunction(this._listener.useEDL))
      if (this._edl)
        this._listener.useEDL(
          true,
          this._edl.window,
          this._edl.strength,
          this._edl.color
        )
      else this._listener.useEDL(false)
  }
  get antiAliasing() {
    return this._fxaa
  }
  set antiAliasing(e) {
    if (!isBoolean(e))
      throw new ProgrammingError(
        'GraphicsEffects.antiAliasing must be a boolean!'
      )
    this._fxaa = e
    if (this._listener && isFunction(this._listener.useFXAA))
      this._listener.useFXAA(this._fxaa)
  }
  get bloom() {
    return this._bloom
  }
  set bloom(e) {
    if (e) this._bloom = new BloomEffectImpl(e)
    else this._bloom = null
    if (this._listener && isFunction(this._listener.useBloom))
      if (this._bloom)
        this._listener.useBloom(
          true,
          this._bloom.strength,
          this._bloom.radius,
          this._bloom.threshold
        )
      else this._listener.useBloom(false)
  }
  get environmentMap() {
    return this._environmentMap
  }
  set environmentMap(e) {
    if (e) this._environmentMap = new EnvironmentMapEffectImpl(e)
    else this._environmentMap = null
    if (this._listener && isFunction(this._listener.environmentMapChanged))
      this._listener.environmentMapChanged(this._environmentMap)
  }
}
export { GraphicsEffects }
