import { ShapeType } from './ShapeType.js'
import { LLHPoint } from './LLHPoint.js'
import { Constants } from '../util/Constants.js'
import { containsAngle, containsAnglePragmatic } from '../util/Cartesian.js'
export function contains2D(t, e, n) {
  const o = t.reference.geodeticDatum.ellipsoid
  const i =
    o.forwardAzimuth2D(t.center, new LLHPoint(null, [e, n])) * Constants.RAD2DEG
  let s = containsAnglePragmatic(t.startAzimuth, t.sweepAngle, i)
  if (s) {
    const i = o.geodesicDistance(t.center, new LLHPoint(null, [e, n]))
    s =
      Math.abs(i - t.radius) <=
      Constants.ABSOLUTE_DISTANCE_TOLERANCE +
        Constants.RELATIVE_DISTANCE_TOLERANCE * t.radius
  }
  return s
}
export function interpolate(t, e, n) {
  if (
    ShapeType.contains(t.type, ShapeType.CIRCULAR_ARC_BY_3_POINTS) &&
    _isIntermediateEqualToStartOrEnd(t)
  )
    t.reference.geodeticDatum.ellipsoid.geodesicPositionAtFractionSFCT(
      t.startPoint,
      t.endPoint,
      e,
      n
    )
  else
    t.reference.geodeticDatum.ellipsoid.geodesicPositionSFCT(
      t.center,
      t.radius,
      t.startAzimuth + e * t.sweepAngle,
      n
    )
}
function _isIntermediateEqualToStartOrEnd(t) {
  const e = t.startPoint
  const n = t.intermediatePoint
  const o = t.endPoint
  return (n.x === e.x && n.y === e.y) || (n.x === o.x && n.y === o.y)
}
export function boundsSFCT(t, e) {
  const n = t.reference.geodeticDatum.ellipsoid
  const o = new LLHPoint(t.reference)
  e.move2DToPoint(t.startPoint)
  e.setToIncludePoint2D(t.endPoint)
  for (let i = 0; i <= 270; i += 90)
    if (containsAngle(t.startAzimuth, t.sweepAngle, i)) {
      n.geodesicPositionSFCT(t.center, t.radius, i, o)
      e.setToIncludePoint2D(o)
    }
  const i = 1e-8
  e.translate3D(-i, -i, 0)
  e.width = e.width + 2 * i
  e.height = e.height + 2 * i
}
export function getStartAngle3Points(t, e, n) {
  return getAngle(t, e, n)
}
export function getArcAngle3Points(t, e, n, o, i) {
  return getArcAngleByAngles(
    getAngle(t, e, n),
    getAngle(t, e, o),
    getAngle(t, e, i)
  )
}
function getAngle(t, e, n) {
  return t.forwardAzimuth2D(e, n) * Constants.RAD2DEG
}
function getArcAngleByAngles(t, e, n) {
  if (t < 0) t += 360
  if (e < 0) e += 360
  if (n < 0) n += 360
  const o = n - t
  if (t < n) return containsAngle(t, o, e) ? o : o - 360
  else return containsAngle(t, o + 360, e) ? o + 360 : o
}
export function getMidPoint(t, e) {
  const n = t.startPoint
  const o = t.endPoint
  const i = t.bulge
  const s = t.reference.geodeticDatum.ellipsoid
  const r = new LLHPoint(t.reference)
  let c
  s.geodesicPositionAtFractionSFCT(n, o, 0.5, r)
  if (n.x === o.x && n.y === o.y) {
    e.move2DToPoint(n)
    return
  } else {
    const e = new LLHPoint(t.reference)
    const i = new LLHPoint(t.reference)
    s.geodesicPositionAtFractionSFCT(n, o, 0.5 - 0.001, e)
    s.geodesicPositionAtFractionSFCT(n, o, 0.5 + 0.001, i)
    c = s.forwardAzimuth2D(e, i) * Constants.RAD2DEG
  }
  let a
  if (i > 0) a = c - 90
  else a = c + 90
  const l = 0.5 * s.geodesicDistance(n, o)
  s.geodesicPositionSFCT(r, Math.abs(i) * l, a, e)
}
