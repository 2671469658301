import { Constants } from '../../../util/Constants.js'
import { NotImplementedError } from '../../../error/NotImplementedError.js'
import { ellipticalDistance2D } from '../../../util/Cartesian.js'
import {
  simplePointCreate,
  simplePointMove3DToCoordinates,
} from '../../../shape/SimplePoint.js'
export class GridArcPath {
  constructor() {
    this._center = simplePointCreate(0, 0, 0)
    this._A = -1
    this._B = 1
    this._rotAngle = 0
    this._startAzimuth = 0
    this._arcAngle = 0
  }
  getPointAtSFCT(t, i) {
    const e = this._startAzimuth + t * this._arcAngle
    const o = (90 - e) * Constants.DEG2RAD
    const s = ellipticalDistance2D(this._A, this._B, e - this._rotAngle)
    const r = Math.cos(o) * s
    const n = Math.sin(o) * s
    simplePointMove3DToCoordinates(
      i,
      this._center.x + r,
      this._center.y + n,
      this._center.z
    )
    return i
  }
  getMinimumRecursionDepth() {
    return 0
  }
  initializeArc(t, i, e, o, s, r, n) {
    simplePointMove3DToCoordinates(this._center, t.x, t.y, t.z)
    this._A = i
    this._B = e
    this._rotAngle = o
    this._startAzimuth = s
    this._arcAngle = r
  }
  getEndPosition() {
    throw new NotImplementedError()
  }
  getStartPosition() {
    throw new NotImplementedError()
  }
}
