import { LabelNode } from './LabelNode.js'
import { isFunction } from '../../util/Lang.js'
function addToBackOfLL(t, e) {
  if (!t.head || !t.tail) {
    t.head = e
    t.tail = e
    return
  }
  const i = undefined
  t.tail.next = e
  t.tail = e
}
function makeLabelContainerDiv(t) {
  const e = document.createElement('div')
  e.style.position = 'absolute'
  e.style.top = '0'
  e.style.left = '0'
  e.style.overflow = 'hidden'
  e.style.userSelect = 'none'
  if (t) for (const i in t) e.style[i] = t[i]
  return e
}
export class HTMLDrawingSurface {
  _leftOffset = 0
  constructor(t, e) {
    this._div = makeLabelContainerDiv(e.style)
    t.appendChild(this._div)
    this.setZIndex(1e3)
    this.resize(e.width, e.height, e.border.left)
    this.head = null
    this.tail = null
    this._valid = false
  }
  get valid() {
    return this._valid
  }
  set valid(t) {
    this._valid = t
  }
  modifyHtmlPositionInDom(t, e, i, s, l, n, d, o, h) {
    t.modifyPosition(e - this._leftOffset, i, s, l, n, d, o, h)
    t.valid = true
  }
  placeHtmlInDom(t, e, i, s, l, n, d, o, h, a, f) {
    i -= this._leftOffset
    const r = new LabelNode(i, s, t, e, n, d, a)
    r.placeLabelIn(this._div)
    addToBackOfLL(this, r)
    r.modifyPosition(i, s, l, n, d, o, h, f)
    return r
  }
  mark() {
    let t = this.head
    while (t) {
      t.valid = false
      t = t.next
    }
  }
  sweep() {
    let t = null
    let e = this.head
    while (e) {
      if (!e.valid) {
        if (t) t.next = e.next
        else this.head = e.next
        if (null === e.next) this.tail = t
        e.removeAndDestroy(this._div)
        if (e?.backpointer.handle) e.backpointer.handle = null
      } else {
        e.changePosition(e.nx, e.ny, e.rotation, e.w, e.h, e.ax, e.ay, e.zOrder)
        t = e
      }
      e = e.next
    }
  }
  resize(t, e) {
    let i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0
    this._leftOffset = i
    this._div.style.width = `${t}px`
    this._div.style.height = `${e}px`
    this._div.style.top = '0'
    this._div.style.left = `${i}px`
  }
  resizeAndPositionOnX(t, e, i, s) {
    this._leftOffset = s
    this._div.style.width = `${t - s}px`
    this._div.style.height = `${i}px`
    this._div.style.top = `${e - i}px`
    this._div.style.left = `${s}px`
  }
  resizeAndPositionOnY(t, e, i) {
    this._leftOffset = 0
    this._div.style.width = `${i.left}px`
    this._div.style.height = `${e - i.bottom}px`
    this._div.style.top = '0'
    this._div.style.left = '0'
  }
  setZIndex(t) {
    this._div.style.zIndex = `${t}`
  }
  pickTouched(t, e, i, s, l, n, d, o) {
    const h = []
    const a = d ? 3 : Number.MAX_VALUE
    let f = this.head
    let r = 0
    let v
    while (f && r < a) {
      if (f.valid) {
        v = f.backpointer
        if (isFunction(v.pickTouched)) {
          const d = v.pickTouched(t, e, i, s, l, n, o)
          if (d) {
            h.push(d)
            r++
          }
        }
      }
      f = f.next
    }
    return h
  }
  get domNode() {
    return this._div
  }
  destroy() {
    this._div.parentNode?.removeChild(this._div)
  }
}
