export let PaintRepresentation = (function (B) {
  B['BODY'] = 'BODY'
  B['LABEL'] = 'LABEL'
  B['BOTTOM_BORDER_BODY'] = 'BOTTOM_BORDER_BODY'
  B['BOTTOM_BORDER_LABEL'] = 'BOTTOM_BORDER_LABEL'
  B['LEFT_BORDER_BODY'] = 'LEFT_BORDER_BODY'
  B['LEFT_BORDER_LABEL'] = 'LEFT_BORDER_LABEL'
  B['BORDER_BODY'] = 'BORDER_BODY'
  B['BORDER_LABEL'] = 'BORDER_LABEL'
  return B
})({})
export function isLabelPaintRepresentation(B) {
  return (
    B === PaintRepresentation.LABEL ||
    B === PaintRepresentation.LEFT_BORDER_LABEL ||
    B === PaintRepresentation.BOTTOM_BORDER_LABEL
  )
}
