import { isOfShapeType, Shape } from './Shape.js'
import { isArray } from '../util/Lang.js'
import { ShapeType } from './ShapeType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { coordinate_validate } from './PointCoordinate.js'
export class Point extends Shape {
  move2D(e, o) {
    if ('undefined' === typeof o) this.move2DToPoint(e)
    else this.move2DToCoordinates(e, o)
  }
  move2DToPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    this.move2DToCoordinates(e.x, e.y)
  }
  move3D(e, o, r) {
    if ('undefined' === typeof o) this.move3DToPoint(e)
    else if ('undefined' !== typeof r) this.move3DToCoordinates(e, o, r)
    else this.move2DToCoordinates(e, o)
  }
  move3DToPoint(e) {
    this._compareReference(e.reference, e.coordinateType)
    this.move3DToCoordinates(e.x, e.y, e.z)
  }
  translate(e, o, r) {
    if ('undefined' !== typeof r) this.translate3D(e, o, r)
    else this.translate2D(e, o)
  }
  invalidate() {}
}
export function getValidatedPointRepresentation(e) {
  let o
  if (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : false) {
    if (isArray(e) && 3 === e.length) return e
    throw new ProgrammingError(
      'Incorrect coordinates for a polyline/polygon mutable point'
    )
  }
  if (isArray(e)) return coordinate_validate(e)
  if (e && isOfShapeType(e, ShapeType.POINT)) {
    const { x: o, y: r, z: t } = e
    return [o, r, t]
  }
  return [0, 0, 0]
}
export function isPoint(e) {
  return !!e && e.type === ShapeType.POINT
}
