import { ProgrammingError } from '../../error/ProgrammingError.js'
import { CGLabel } from './CGLabel.js'
import { CGSubBounds } from './CGSubBounds.js'
function locationToChar(e, t) {
  if (e) return t ? 'I' : 'i'
  else return t ? 'E' : 'e'
}
function CGVertex(e, t) {
  this._shapeIndex = e
  this._pos = t
  this._pointLabel = new CGLabel()
  this._segmentLabel = new CGLabel()
  this._next = null
  this._previous = null
  this._otherLineSegment = null
  this._otherInvertedDirection = false
  this._used = false
  this._subBounds = new CGSubBounds()
  this._bundleStar = null
  this.getVertexPointCoordinates = function () {
    const { x: e, y: t, z: n } = this._pos
    return [e, t, n ?? 0]
  }
}
CGVertex.prototype = Object.create(Object.prototype)
CGVertex.prototype.constructor = CGVertex
Object.defineProperty(CGVertex.prototype, 'pos', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._pos
  },
  set: function () {
    throw new ProgrammingError('pos property is not mutable')
  },
})
Object.defineProperty(CGVertex.prototype, 'pointLabel', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._pointLabel
  },
  set: function () {
    throw new ProgrammingError('pointLabel property is not mutable')
  },
})
Object.defineProperty(CGVertex.prototype, 'segmentLabel', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._segmentLabel
  },
  set: function () {
    throw new ProgrammingError('segmentLabel property is not mutable')
  },
})
Object.defineProperty(CGVertex.prototype, 'shapeIndex', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._shapeIndex
  },
  set: function () {
    throw new ProgrammingError('shapeIndex property is not mutable')
  },
})
Object.defineProperty(CGVertex.prototype, 'otherShapeIndex', {
  configurable: false,
  enumerable: true,
  get: function () {
    return 0 === this._shapeIndex ? 1 : 0
  },
  set: function () {
    throw new ProgrammingError('otherShapeIndex property is not mutable')
  },
})
Object.defineProperty(CGVertex.prototype, 'bundleStar', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._bundleStar
  },
  set: function (e) {
    this._bundleStar = e
  },
})
Object.defineProperty(CGVertex.prototype, 'otherLineSegment', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._otherLineSegment
  },
  set: function () {
    throw new ProgrammingError('otherLineSegment property is not mutable')
  },
})
Object.defineProperty(CGVertex.prototype, 'next', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._next
  },
  set: function (e) {
    this._next = e
  },
})
Object.defineProperty(CGVertex.prototype, 'previous', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._previous
  },
  set: function (e) {
    this._previous = e
  },
})
Object.defineProperty(CGVertex.prototype, 'used', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._used
  },
  set: function (e) {
    this._used = e
  },
})
Object.defineProperty(CGVertex.prototype, 'subBounds', {
  configurable: false,
  enumerable: true,
  get: function () {
    return this._subBounds
  },
  set: function (e) {
    this._subBounds = e
  },
})
CGVertex.prototype.getPointLocationOther = function () {
  return this._pointLabel.locationOn[this.otherShapeIndex]
}
CGVertex.prototype.setPointLocationOther = function (e) {
  this._pointLabel.locationOn[this.otherShapeIndex] = e
}
CGVertex.prototype.setPointLocationThis = function (e) {
  this._pointLabel.locationOn[this._shapeIndex] = e
}
CGVertex.prototype.getSegmentLocationOther = function () {
  return this._segmentLabel.locationOn[this.otherShapeIndex]
}
CGVertex.prototype.setSegmentLocationsOfThis = function (e, t, n) {
  this._segmentLabel.locationOn[this._shapeIndex] = e
  this._segmentLabel.locationLeft[this._shapeIndex] = t
  this._segmentLabel.locationRight[this._shapeIndex] = n
}
CGVertex.prototype.setSegmentLocationsOfOther = function (e, t, n) {
  this._segmentLabel.locationOn[this.otherShapeIndex] = e
  this._segmentLabel.locationLeft[this.otherShapeIndex] = t
  this._segmentLabel.locationRight[this.otherShapeIndex] = n
}
CGVertex.prototype.getTouchingSegment = function (e) {
  if (+1 == e && null === this._next) return null
  return +1 == e ? this : this._previous
}
CGVertex.prototype.isFilled = function () {
  return (
    this._segmentLabel.locationLeft[this._shapeIndex] ||
    this._segmentLabel.locationRight[this._shapeIndex]
  )
}
CGVertex.prototype.isOtherFilled = function () {
  return (
    this._segmentLabel.locationLeft[this.otherShapeIndex] ||
    this._segmentLabel.locationRight[this.otherShapeIndex]
  )
}
CGVertex.prototype.toString = function () {
  if (null === this._next)
    return (
      '(' +
      this._pos.x +
      ' , ' +
      this._pos.y +
      ') ' +
      locationToChar(this._pointLabel.locationOn[this.otherShapeIndex], true) +
      ' ' +
      this._shapeIndex +
      (this._used ? ' U' : '')
    )
  return (
    '(' +
    this._pos.x +
    ' , ' +
    this._pos.y +
    ') ' +
    '- (' +
    this._next._pos.x +
    ' , ' +
    this._next._pos.y +
    ') ' +
    locationToChar(this._pointLabel.locationOn[this.otherShapeIndex], true) +
    locationToChar(this._segmentLabel.locationOn[this.otherShapeIndex], true) +
    locationToChar(
      this._next._pointLabel.locationOn[this.otherShapeIndex],
      true
    ) +
    ' ' +
    this._shapeIndex +
    (this._used ? ' U' : '')
  )
}
CGVertex.prototype.isPoint = function () {
  return null === this._next && null === this._previous
}
CGVertex.prototype.isSegmentOnAreaBoundaryOfOther = function () {
  return (
    this._segmentLabel.locationOn[this.otherShapeIndex] &&
    this._segmentLabel.locationLeft[this.otherShapeIndex] !=
      this._segmentLabel.locationRight[this.otherShapeIndex]
  )
}
CGVertex.prototype.isPointInsideAreaOfOther = function () {
  if (!this.getPointLocationOther()) return false
  if (null === this._bundleStar) return true
  if (null != this._bundleStar) {
    for (var e = 0; e < this._bundleStar.getNumEdgeEnds(); e++)
      if (this._bundleStar.getEdgeEnd(e).segment.shapeIndex != this.shapeIndex)
        return false
  } else return false
  return true
}
CGVertex.prototype.moveTo = function (e) {
  this._pos = e.copy()
}
CGVertex.prototype.isOtherInvertedDirection = function () {
  return this._otherInvertedDirection
}
CGVertex.prototype.setOtherLineSegment = function (e, t) {
  this._otherLineSegment = e
  this._otherInvertedDirection = t
}
export { CGVertex }
