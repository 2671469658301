import { ProgrammingError } from '../error/ProgrammingError.js'
import { createPoint } from '../shape/ShapeFactory.js'
import { ShapeType } from '../shape/ShapeType.js'
import { createTransformation } from '../transformation/TransformationFactory.js'
import { isNumber } from '../util/Lang.js'
import { Log } from '../util/Log.js'
import { MapNavigatorConstraints2D } from './MapNavigatorConstraints2D.js'
import { GoogleLayer } from './google/GoogleLayer.js'
import { AnimationManager } from './animation/AnimationManager.js'
import {
  createFlyToAnimation,
  createPanAnimation,
  createRotateAnimation,
  createZoomAnimation,
} from './animation/AnimationSupport2D.js'
import {
  EnabledOperations,
  FIT_MARGIN_TYPE,
  LEGACY_FIT_DURATION,
  LEGACY_FIT_MARGIN,
  MapNavigatorBase,
} from './MapNavigatorBase.js'
import { MapNavigatorConstraintsImpl } from './MapNavigatorConstraints.js'
import { clamp } from '../util/Cartesian.js'
import { Bounds } from '../shape/Bounds.js'
const DEFAULT_ANIM_DURATION = 340
const ZOOM_ANIM_DURATION = 250
const MOUSE_WHEEL_SCROLL_AMOUNT_MULTIPLIER = 3
const ZOOM_FACTOR = 1.1
const ZOOM_EXAGGERATION = 1.07
export class MapNavigatorImpl extends MapNavigatorBase {
  constructor(t) {
    super(t)
    this._zoomStartView = createPoint(null, [0, 0])
    this._panDragPosition = createPoint(null, [0, 0])
    this._zoomStartWorld = createPoint(null, [0, 0, 0])
    this._rotateCenterView = createPoint(null, [0, 0, 0])
    this._rotateCenterWorld = createPoint(null, [0, 0, 0])
    this._isPanning = false
    this._isRotating = false
    this._isZooming = false
    this._rotateStartYaw = 0
    this._rotateInitialCameraRot = 0
    this._zoomStartScaleX = 0
    this._zoomStartScaleY = 0
    this._googleSnapWarningLogged = false
    this._mapNavigatorConstraints = MapNavigatorConstraints2D.create(t)
    const e = this
    this._googleLayerPresent = false
    this._googleLayerAddedListener = this.map.layerTree.on('NodeAdded', (t) => {
      if (t.node instanceof GoogleLayer) {
        e.defaults.snapToScaleLevels = true
        e._googleLayerPresent = true
      }
    })
    this.setupConstraints(t)
  }
  setupConstraints(t) {
    const e = null
    this._constraint = new MapNavigatorConstraintsImpl(
      t.dotsPerCentimeter,
      t.reference,
      this.invalidate.bind(this),
      e,
      true
    )
  }
  toView(t) {
    if (null === t.reference) return t
    else if (t.reference === this.map.reference)
      return this.map.mapToViewTransformationInternal.transform(t)
    const e = undefined
    const a = createTransformation(t.reference, this.map.reference).transform(t)
    return this.map.mapToViewTransformationInternal.transform(a)
  }
  _toMap(t) {
    if (t.reference === this.map.reference) return t
    else if (null === t.reference)
      return this.map.viewToMapTransformationInternal.transform(t)
    const e = undefined
    return createTransformation(t.reference, this.map.reference).transform(t)
  }
  getViewCenter() {
    return createPoint(null, [
      this.map.viewSize[0] / 2,
      this.map.viewSize[1] / 2,
    ])
  }
  beginPan(t, e, a) {
    if (this.enabledOperations === EnabledOperations.NONE) return
    this._killCurrentAnimation()
    this._panDragPosition.move2D(t, e)
    this._isPanning = true
  }
  incrementalPan(t, e, a, i) {
    if (this._isPanning) {
      const t = a - this._panDragPosition.x
      const e = i - this._panDragPosition.y
      this.panBy(t, e)
      this._panDragPosition.move2D(a, i)
    }
  }
  endPan() {
    this._isPanning = false
  }
  beginRotate(t, e) {
    if (
      this.violatesRotation() ||
      this.enabledOperations === EnabledOperations.NONE
    )
      return
    this._killCurrentAnimation()
    if (!this._isRotating) {
      this._isRotating = true
      this._rotateCenterView = createPoint(null, [t, e])
      this._rotateCenterWorld = this.map.mapToViewTransformation.toWorld(
        this._rotateCenterView
      )
      const a = this.map.camera.asLook2D()
      this._rotateInitialCameraRot = a.rotation
    }
  }
  beginRotateWorld(t) {
    if (
      this.violatesRotation() ||
      this.enabledOperations === EnabledOperations.NONE
    )
      return
    this._killCurrentAnimation()
    if (!this._isRotating) {
      this._isRotating = true
      this._rotateCenterWorld = t
      this._rotateCenterView = this.toView(t)
      const e = this.map.camera.asLook2D()
      this._rotateInitialCameraRot = e.rotation
    }
  }
  incrementalRotateAngles(t, e) {
    if (this._isRotating)
      this.rotate({
        targetRotation: this._rotateInitialCameraRot + t,
        center: this._rotateCenterWorld,
        animate: false,
      })
  }
  endRotate() {
    this._isRotating = false
  }
  beginZoom(t, e) {
    if (!this._isZooming) {
      this._killCurrentAnimation()
      this._zoomStartView.move2D(t, e)
      this.map.mapToViewTransformationInternal.inverseTransformation.transform(
        this._zoomStartView,
        this._zoomStartWorld
      )
      this._zoomStartScaleX = this.map.mapScale[0]
      this._zoomStartScaleY = this.map.mapScale[1]
      this._isZooming = true
    }
  }
  beginZoomWorld(t) {
    if (!this._isZooming) {
      this._killCurrentAnimation()
      this._zoomStartWorld.move3D(t.x, t.y, t.z)
      this.map.mapToViewTransformationInternal.transform(
        this._zoomStartWorld,
        this._zoomStartView
      )
      this._zoomStartScaleX = this.map.mapScale[0]
      this._zoomStartScaleY = this.map.mapScale[1]
      this._isZooming = true
    }
  }
  incrementalZoom(t, e) {
    if (this._isZooming)
      this.zoom({
        targetScale: {
          x: this._zoomStartScaleX * t,
          y: this._zoomStartScaleY * e,
        },
        location: this._zoomStartView,
        snapToScaleLevels: false,
      })
  }
  endZoom() {
    this._isZooming = false
  }
  _getTargetCameraForFit(t, e, a, i) {
    let o = justifyBounds(t)
    if (null === o.reference)
      o = this.map.viewToMapTransformation.transformBounds(o)
    let n
    try {
      n = createTransformation(o.reference, this.map.reference)
    } catch (t) {
      throw new Error(
        'MapNavigator::fit - cannot fit on bounds with incompatible reference'
      )
    }
    const r = n.transformBounds(o)
    const s = createPoint(r.reference, [r.x + r.width / 2, r.y + r.height / 2])
    const m = this.map.viewSize
    m[0] = 0 === m[0] ? 1 : m[0]
    m[1] = 0 === m[1] ? 1 : m[1]
    const l = this.map.border
    let c = this.map.camera.asLook2D()
    const h = c.scaleX / c.scaleY
    const p = m[0] / r.width
    const g = m[1] / r.height
    const u = p < g
    let f
    let d
    let _
    let A
    if (e.type === FIT_MARGIN_TYPE.PERCENTAGE) {
      _ = e.value / 100
      A = e.value / 100
    } else {
      _ = e.value / m[0]
      A = e.value / m[1]
    }
    f = p >= 1 / 0 || !isNumber(p) ? 1 : p
    d = g >= 1 / 0 || !isNumber(g) ? 1 : g
    if (a) {
      f *= 1 - 2 * _
      d *= 1 - 2 * A
    } else if (u) {
      f *= 1 - 2 * _
      d = f * h
    } else {
      d *= 1 - 2 * A
      f = d / h
    }
    const v = this.map.camera.copy()
    if (i) {
      const t = { scalex: 0, scaley: 0 }
      const e = Math.floor
      this._mapNavigatorConstraints.getSnappedScale(f, d, e, false, t)
      const a = f / t.scalex
      f = t.scalex
      d /= a
    }
    c = v.asLook2D()
    c.worldOrigin = s
    c.viewOrigin = createPoint(null, [m[0] / 2 + l.left, m[1] / 2 + l.top])
    c.scaleX = f
    c.scaleY = d
    c.rotation = 0
    return v.look2D(c)
  }
  getCameraWithConstraints(t) {
    const e = t.asLook2D()
    const a = this.map.mapToViewTransformationInternal.copy()
    a.setTo(e.worldOrigin, e.viewOrigin, e.scaleX, e.scaleY, e.rotation)
    this._mapNavigatorConstraints.correctWorldViewTransformation2D(a, a)
    return a.camera
  }
  fit(t, e) {
    let a = t
    if (
      t &&
      t instanceof Bounds &&
      t.type &&
      ShapeType.contains(t.type, ShapeType.BOUNDS)
    ) {
      const e = undefined
      a = {
        bounds: t,
        allowWarpXYAxis: !!arguments[1],
        fitMargin: LEGACY_FIT_MARGIN,
        animate: { duration: LEGACY_FIT_DURATION },
      }
    }
    const i = this.parseFitOptions(a, this.map)
    if (
      this._googleLayerPresent &&
      !i.snapToScaleLevels &&
      !this._googleSnapWarningLogged
    ) {
      Log.warn(
        'MapNavigator attempting to zoom or fit with snapToScaleLevels disabled,' +
          'while the map has a GoogleLayer present.' +
          'This will most likely result in the GoogleLayer not being rendered.'
      )
      this._googleSnapWarningLogged = true
    }
    const o = this._getTargetCameraForFit(
      i.bounds,
      i.fitMargin,
      i.allowWarpXYAxis,
      i.snapToScaleLevels
    )
    if (i.animate) {
      const t = createFlyToAnimation(o, i.animate, this.map)
      return AnimationManager.putAnimation(
        this.map.cameraAnimationKey,
        t,
        false
      )
    }
    this._killCurrentAnimation()
    this.map.camera = this.getCameraWithConstraints(o)
    return Promise.resolve()
  }
  pan(t) {
    const e = this.parsePanOptions(t, this.map)
    const a = e.targetLocation
    const i = e.animate
    const o = e.toViewLocation
    const n = this.toView(a)
    if (i) {
      const t = createPanAnimation(n, o, i, this.map)
      return AnimationManager.putAnimation(
        this.map.cameraAnimationKey,
        t,
        false
      )
    }
    if (!t.keepAnimationRunning) this._killCurrentAnimation()
    const r = o.x - n.x
    const s = o.y - n.y
    const m = this.getViewCenter()
    const l = createPoint(null, [m.x - r, m.y - s])
    const c = this.map.camera.eye.z
    const h = this._toMap(l)
    h.z = c
    const p = this.map.camera.copyAndSet({ eye: h })
    this.map.camera = this.getCameraWithConstraints(p)
    return Promise.resolve()
  }
  zoom(t) {
    const e = this.parseZoomOptions(t, this.map)
    const a = this.toView(e.location)
    const i = e.targetScaleX
    const o = e.targetScaleY
    const n = this.map.camera.asLook2D()
    let r, s
    if (
      this._googleLayerPresent &&
      !e.snapToScaleLevels &&
      !this._googleSnapWarningLogged
    ) {
      Log.warn(
        'MapNavigator attempting to zoom or fit with snapToScaleLevels disabled,' +
          'while the map has a GoogleLayer present.' +
          'This will most likely result in the GoogleLayer not being rendered.'
      )
      this._googleSnapWarningLogged = true
    }
    if (e.snapToScaleLevels) {
      const e = { scalex: 0, scaley: 0 }
      const a = i / n.scaleX
      const m = t.snapRoundFunction || (a > 1 ? Math.ceil : Math.floor)
      this._mapNavigatorConstraints.getSnappedScale(i, o, m, false, e)
      r = e.scalex
      const l = undefined
      s = o / (i / r)
    } else {
      r = i
      s = o
    }
    const m = this.map.getAdjustedMinScale()
    const l = this.map.getAdjustedMaxScale()
    r = clamp(r, m[0], l[0])
    s = clamp(s, m[1], l[1])
    if (e.animate) {
      const t = createZoomAnimation(a, r, s, e.animate, this.map)
      return AnimationManager.putAnimation(
        this.map.cameraAnimationKey,
        t,
        false
      )
    }
    this._killCurrentAnimation()
    n.worldOrigin = this._toMap(a)
    n.viewOrigin = a
    n.scaleX = r
    n.scaleY = s
    const c = this.map.camera.look2D(n)
    this.map.camera = this.getCameraWithConstraints(c)
    return Promise.resolve()
  }
  rotate(t) {
    const e = this.parseRotateOptions(t, this.map)
    const a = e.center
    const i = this.toView(a)
    const o = this._toMap(a)
    const n = t.animate
    if (n) {
      const t = createRotateAnimation(i, e.targetRotation, n, this.map)
      return AnimationManager.putAnimation(
        this.map.cameraAnimationKey,
        t,
        false
      )
    }
    this._killCurrentAnimation()
    const r = this.map.camera
    const s = r.asLook2D()
    s.worldOrigin = o
    s.viewOrigin = i
    s.rotation = e.targetRotation
    const m = r.look2D(s)
    this.map.camera = this.getCameraWithConstraints(m)
    return Promise.resolve()
  }
  snapToScalesIfNeeded(t, e, a, i) {
    if (this._googleLayerPresent || this.defaults.snapToScaleLevels) {
      const o = this._googleLayerPresent && !this.defaults.snapToScaleLevels
      this._mapNavigatorConstraints.getSnappedScale(t, e, a, o, i)
    } else {
      i.rounding = (t) => t
      i.scalex = t
      i.scaley = e
    }
  }
  panTo(t) {
    if (this.enabledOperations === EnabledOperations.NONE) return null
    let e
    try {
      e = createTransformation(t.reference, this.map.reference)
    } catch (t) {
      throw new ProgrammingError(
        'MapNavigator:panTo - cannot pan to point with incompatible reference'
      )
    }
    let a
    try {
      a = e.transform(t.bounds.focusPoint)
      const i = this.map.camera
      const o = i.asLook2D()
      o.worldOrigin = a
      o.viewOrigin = this.getViewCenter()
      const n = i.look2D(o)
      const r = createFlyToAnimation(
        n,
        { duration: DEFAULT_ANIM_DURATION, ease: (t) => t },
        this.map
      )
      return AnimationManager.putAnimation(
        this.map.cameraAnimationKey,
        r,
        false
      )
    } catch (t) {
      Log.warn('Cannot pan to point. Out of bounds')
      return null
    }
  }
  panBy(t, e) {
    if (this.enabledOperations === EnabledOperations.NONE) return
    const a = createPoint(null, [
      this.map.viewSize[0] / 2,
      this.map.viewSize[1] / 2,
    ])
    const i = a.copy()
    i.translate(t, e)
    this.pan({ targetLocation: a, toViewLocation: i })
  }
  setCenter(t, e) {
    if (this.enabledOperations === EnabledOperations.NONE) return
    if (!t)
      throw new ProgrammingError(
        'MapNavigator:setCenter - cannot set center without point argument'
      )
    let a = this.getViewCenter(),
      i
    if (!e) i = t
    else {
      a = t
      i = e
    }
    this.pan({ targetLocation: i, toViewLocation: a, animate: false })
  }
  zoomWithFactor(t, e, a, i) {
    if (this.enabledOperations === EnabledOperations.NONE) return null
    return this.zoom({
      factor: { x: t, y: e },
      location: a,
      animate: { duration: ZOOM_ANIM_DURATION },
    })
  }
  zoomTo(t) {
    let e, a
    const i = undefined
    if (Array.isArray(t)) {
      e = t[0]
      a = t[1]
    } else if (isNumber(t)) e = a = t
    else
      throw new ProgrammingError(
        'MapNavigator::zoomTo - must supply valid scale parameter'
      )
    const o = this.map.convertXComponentMapToPixel(e)
    const n = this.map.convertYComponentMapToPixel(a)
    const r = this.map.camera.asLook2D()
    const s = o / r.scaleX
    const m = n / r.scaleY
    return this.zoomWithFactor(s, m, this.getViewCenter(), true)
  }
  zoomOut() {
    return this.zoomWithFactor(1 / 2, 1 / 2, this.getViewCenter(), true)
  }
  zoomOutFixing(t, e) {
    const a = this.calculateZoomFactor(false)
    return this.zoomWithFactor(a, a, createPoint(null, [t, e]), true)
  }
  zoomOutFixingWithScaleFactor(t, e, a, i) {
    return this.zoomWithFactor(1 / a, 1 / i, createPoint(null, [t, e]), false)
  }
  zoomIn() {
    return this.zoomWithFactor(2, 2, this.getViewCenter(), true)
  }
  zoomInFixing(t, e) {
    const a = this.calculateZoomFactor(true)
    return this.zoomWithFactor(a, a, createPoint(null, [t, e]), true)
  }
  calculateZoomFactor(t) {
    const e = undefined
    const a = !!AnimationManager.getAnimation(this.map.cameraAnimationKey)
    const i = t ? -1 : 1
    let o = Math.pow(ZOOM_FACTOR, MOUSE_WHEEL_SCROLL_AMOUNT_MULTIPLIER * -i)
    if (a) {
      const t = undefined
      o *= Math.pow(ZOOM_EXAGGERATION, -i)
    }
    return o
  }
  zoomInFixingWithScaleFactor(t, e, a, i) {
    return this.zoomWithFactor(a, i, createPoint(null, [t, e]), false)
  }
  setScaleFixing(t, e, a) {
    if (this.enabledOperations === EnabledOperations.NONE) return
    let i, o
    if (Array.isArray(t)) {
      i = t[0]
      o = t[1]
    } else if (isNumber(t)) i = o = t
    else
      throw new ProgrammingError(
        'MapNavigator:setScaleFixing - invalid scale argument, must be number or array of numbers'
      )
    this.zoom({
      targetScale: { x: i, y: o },
      location: createPoint(null, [e, a]),
    })
  }
  setScale(t) {
    if (this.enabledOperations === EnabledOperations.NONE) return
    let e, a
    if (Array.isArray(t)) {
      e = t[0]
      a = t[1]
    } else if (isNumber(t)) {
      e = t
      a = t
    } else
      throw new ProgrammingError(
        "MapNavigator:setScale - invalid 'scale' argument."
      )
    this.zoom({ targetScale: { x: e, y: a }, animate: false })
  }
  triggerRefresh() {
    if (this.isAnimating()) return
    this.invalidate()
  }
  invalidate() {
    this.map.camera = this.getCameraWithConstraints(this.map.camera)
  }
  computeScaleX() {
    const t = undefined
    return this.map.camera.asLook2D().scaleX
  }
  computeScaleY() {
    const t = undefined
    return this.map.camera.asLook2D().scaleY
  }
  lookFrom(t, e, a, i, o) {
    throw new ProgrammingError(
      'The MapNavigator#lookFrom method is not functional for 2D maps.'
    )
  }
  _flyToAnimated(t, e) {
    this._killCurrentAnimation()
    const a = { duration: e.duration || 0, ease: e.ease }
    const i = createFlyToAnimation(t, a, this.map)
    return AnimationManager.putAnimation(this.map.cameraAnimationKey, i, false)
  }
  violatesRotation() {
    return this._mapNavigatorConstraints.violatesRotation()
  }
  violatesLeftEdge() {
    return this._mapNavigatorConstraints.violatesLeftEdge()
  }
  violatesRightEdge() {
    return this._mapNavigatorConstraints.violatesRightEdge()
  }
  violatesBottomEdge() {
    return this._mapNavigatorConstraints.violatesBottomEdge()
  }
  violatesTopEdge() {
    return this._mapNavigatorConstraints.violatesTopEdge()
  }
  get constraints2D() {
    return this._mapNavigatorConstraints
  }
  set constraints2D(t) {
    this._mapNavigatorConstraints = t
  }
}
function justifyBounds(t) {
  let e, a, i, o, n
  if (0 === t.width || 0 === t.height) {
    e = t.copy()
    if (0 === e.width) {
      o = e.x - 0.5
      a = 1
    } else {
      o = e.x
      a = e.width
    }
    if (0 === e.height) {
      n = e.y - 0.5
      i = 1
    } else {
      n = e.y
      i = e.height
    }
    e.setTo2D(o, a, n, i)
  } else e = t
  return e
}
