import { LLHPoint } from '../shape/LLHPoint.js'
import { ProjectionType } from './ProjectionType.js'
import { BaseProjection } from './BaseProjection.js'
export class Cylindrical extends BaseProjection {
  constructor() {
    super()
    this._centralMeridian = 0
    this._origin = new LLHPoint(null, [0, 0])
  }
  setCentralMeridian(e) {
    this._centralMeridian = e
    this._origin.x = this._centralMeridian
    this.calculateCachedValues()
  }
  getOrigin() {
    return this._origin
  }
  getCentralMeridian() {
    return this._centralMeridian
  }
  isContinuous() {
    return true
  }
  equals(e) {
    if (!super.equals(e)) return false
    return this.getCentralMeridian() == e.getCentralMeridian()
  }
  get TYPE() {
    return ProjectionType.CYLINDRICAL
  }
}
