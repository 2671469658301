import { ShapeType } from '../../../shape/ShapeType.js'
import { EVENT_HANDLED } from '../../controller/HandleEventResult.js'
import * as SnapUtil from '../snapping/SnapUtil.js'
import { ShapeTranslateHandle } from './ShapeTranslateHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
import { viewToModel } from './HandleUtil.js'
export class PointTranslateHandle extends ShapeTranslateHandle {
  constructor(t) {
    super(t)
    this._snapPoint = null
  }
  get point() {
    return this.shape
  }
  process(t, i) {
    this.startPoint = this.endPoint
    this.endPoint = viewToModel(t.viewPoint, i, true)
    if (!this.startPoint || !this.endPoint) return EVENT_HANDLED
    let n
    if (ShapeType.contains(ShapeType.POINT, this.point.type))
      n = SnapUtil.findClosestPoint(t, this.point, this.point, i.map)
    if (n) {
      this.point.move2DToPoint(n)
      this._snapPoint = n.copy()
      this.emitEditShapeEvent(this.point, EditShapeStatus.IN_PROGRESS)
    } else {
      this._snapPoint = null
      const t = this.endPoint.x - this.startPoint.x
      const i = this.endPoint.y - this.startPoint.y
      const n = this.endPoint.z - this.startPoint.z
      if (0 !== t || 0 !== i || 0 !== n) {
        this.point.translate2D(t, i)
        this.emitEditShapeEvent(this.point, EditShapeStatus.IN_PROGRESS)
      }
    }
    return EVENT_HANDLED
  }
  onDraw(t, i) {
    SnapUtil.paintSnapIcon(t, this._snapPoint)
  }
  deactivate(t, i) {
    this._snapPoint = null
    return super.deactivate(t, i)
  }
}
