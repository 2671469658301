const OPEN = 'OPEN'
const CLOSED = 'CLOSED'
const END = 'END'
const AMPSERSAND = /&/g
const BACKSLASH = /\"/g
const FISH_S = /</g
const FISH_G = />/g
export class XMLWriter {
  constructor(t) {
    this._isPrettyPrint = t ? !!t : false
    this._indentLevel = 0
    this._xml = []
    this._nodes = []
    this._state = CLOSED
    this._writeNamespace = true
  }
  toString() {
    return this._xml.join('')
  }
  beginNode(t, e, s) {
    let i,
      r,
      n = ''
    if (this._state === OPEN)
      this._xml.push('>' + (this._isPrettyPrint ? '\n' : ''))
    if (e && 'string' === typeof e) t = e + ':' + t
    this._state = OPEN
    this._nodes.push(t)
    if (this._isPrettyPrint)
      n = new Array(2 * this._indentLevel++ + 1).join(' ')
    this._xml.push(n + '<' + t)
    if (this._writeNamespace) {
      if (s && 'number' === typeof s.length)
        for (i = 0, r = s.length; i < r; i++)
          this.attribute(
            'xmlns' + (s[i].prefix ? ':' + s[i].prefix : ''),
            s[i].namespace
          )
      this._writeNamespace = false
    }
    return this
  }
  endNode() {
    let t = '',
      e = ''
    if (this._isPrettyPrint) {
      e = '\n'
      t = new Array(2 * --this._indentLevel + 1).join(' ')
    }
    if (this._state === OPEN) {
      this._xml.push('/>' + e)
      this._nodes.pop()
    } else if (this._nodes.length > 0)
      this._xml.push(t + '</' + this._nodes.pop() + '>' + e)
    this._state = CLOSED
    return this
  }
  attribute(t, e, s) {
    if (this._state !== OPEN || !t) return this
    if (s) this._xml.push(' ' + s + ':')
    else this._xml.push(' ')
    this._xml.push(t + '="' + formatXml(e) + '"')
    return this
  }
  rawValue(t) {
    let e = ''
    let s = ''
    if (this._isPrettyPrint) {
      e = new Array(2 * this._indentLevel + 1).join(' ')
      s = '\n'
    }
    if (this._state === OPEN) this._xml.push('>' + s)
    this._xml.push(e + t + s)
    this._state = CLOSED
    return this
  }
  value(t) {
    return this.rawValue(formatXml(t))
  }
  node(t, e) {
    let s = ''
    let i = ''
    let r
    if (this._isPrettyPrint) {
      s = new Array(2 * this._indentLevel + 1).join(' ')
      i = '\n'
    }
    if (this._state === OPEN) this._xml.push('>' + i)
    if ('' === e || !e) r = s + '<' + t + '/>' + i
    else r = s + '<' + t + '>' + formatXml(e) + '</' + t + '>' + i
    this._xml.push(r)
    this._state = CLOSED
    return this
  }
  close() {
    while (this._nodes.length > 0) this.endNode()
    this._state = END
    return this
  }
}
function formatXml(t) {
  if ('string' === typeof t)
    return t
      .replace(AMPSERSAND, '&amp;')
      .replace(BACKSLASH, '&quot;')
      .replace(FISH_S, '&lt;')
      .replace(FISH_G, '&gt;')
  else if ('number' === typeof t) return t.toString()
  else if ('boolean' === typeof t)
    if (t) return 'true'
    else return 'false'
  else return ''
}
