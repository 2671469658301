import { Constants } from '../../util/Constants.js'
import { LLHPoint } from '../../shape/LLHPoint.js'
import { AdvancedBinaryTopologyUtil } from './AdvancedBinaryTopologyUtil.js'
import { normalizeAngle } from '../../util/Cartesian.js'
function EllipsoidalAdvancedBinaryTopologyUtil(t) {
  this._ellipsoid = t
}
EllipsoidalAdvancedBinaryTopologyUtil.prototype = Object.create(
  AdvancedBinaryTopologyUtil.prototype
)
EllipsoidalAdvancedBinaryTopologyUtil.prototype.constructor =
  EllipsoidalAdvancedBinaryTopologyUtil
EllipsoidalAdvancedBinaryTopologyUtil.prototype.insidePointPolygon = function (
  t,
  i
) {
  var o = i.pointCount
  var n = t.x
  var r = t.y
  var e = 0
  var a = 0
  var l
  var s = 0
  while (s < o && i.getPoint(s).x === n) s++
  if (s >= o) s = 0
  var p = i.bounds
  var d = p.y + p.height / 2 < 0
  for (var v = 0; v < o; v++) {
    var y = (s + 1) % o
    var g = i.getPoint(y).x - i.getPoint(s).x
    if (0 != g && 180 != g && -180 != g) {
      var f = 1e-15
      var c = false
      if (Math.abs(i.getPoint(y).x - n) < f)
        if ((l = i.getPoint(y).y >= r) === d) {
          a = i.getPoint(s).x
          c = true
        }
      if (Math.abs(i.getPoint(s).x - n) < f) {
        if ((l = i.getPoint(s).y >= r) === d && a > n !== i.getPoint(y).x > n)
          e--
        c = true
      }
      if (c) {
        s = y
        continue
      }
      var u
      if (
        this._meridionalIntersects(
          this._ellipsoid,
          i.getPoint(s),
          i.getPoint(y),
          n,
          r,
          d
        )
      )
        e++
    }
    s = y
  }
  return e % 2 != 0
}
EllipsoidalAdvancedBinaryTopologyUtil.prototype._meridionalIntersects =
  function (t, i, o, n, r, e) {
    var a = normalizeAngle(i.x)
    var l = normalizeAngle(o.x)
    if (!this._isAngleBetween(a, l, n)) return false
    var s
    var p
    if (Math.abs(l - a) > 180 !== a > l) {
      var d = i
      i = o
      o = d
    }
    var v = t.forwardAzimuth2D(i, o) * Constants.RAD2DEG
    var y =
      t.forwardAzimuth2D(i, new LLHPoint(i.reference, [n, r])) *
      Constants.RAD2DEG
    if (e) return v <= y
    else return v >= y
  }
EllipsoidalAdvancedBinaryTopologyUtil.prototype._isAngleBetween = function (
  t,
  i,
  o
) {
  var n = Math.min(t, i)
  var r = Math.max(t, i)
  if (o == n || o == r) return true
  var e = o >= n && o < r
  if (r - n < 180) return e
  else return !e
}
export { EllipsoidalAdvancedBinaryTopologyUtil }
