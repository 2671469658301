import { Polygon } from './Polygon.js'
import { PolygonOrientation } from './PolygonOrientation.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { ConstrainedPointList } from './ConstrainedPointList.js'
export function isConstrainedPolygon(t) {
  return t instanceof ConstrainedPolygon
}
export class ConstrainedPolygon extends Polygon {
  constructor(t, n) {
    super()
    this._constrainedPointList = new ConstrainedPointList(t, n)
    this._reference = t.reference
    this._coordinates =
      t._coordinates ?? t._delegatePointList._coordinates ?? []
    this._orientation = PolygonOrientation.INVALID_ORIENTATION
    this._focus = t.focusPoint ?? void 0
    this._bounds = t.bounds ?? void 0
  }
  get shape() {
    return this._constrainedPointList.shape
  }
  get constraint() {
    return this._constrainedPointList.constraint
  }
  get minPointCount() {
    return this._constrainedPointList.minPointCount
  }
  get maxPointCount() {
    return this._constrainedPointList.maxPointCount
  }
  get pointCount() {
    return this._constrainedPointList.pointCount
  }
  get isGeodetic() {
    return this._constrainedPointList.isGeodetic
  }
  get bounds() {
    return this._constrainedPointList.bounds
  }
  get coordinateType() {
    return this._constrainedPointList.coordinateType
  }
  insertPoint(t, n) {
    this._constrainedPointList.insertPoint(t, n)
  }
  removePoint(t) {
    this._constrainedPointList.removePoint(t)
  }
  move2DPoint(t, n, i) {
    this._constrainedPointList.move2DPoint(t, n, i)
  }
  translatePoint(t, n, i, o) {
    this._constrainedPointList.translatePoint(t, n, i, o)
  }
  translate2D(t, n) {
    this._constrainedPointList.translate2D(t, n)
  }
  getPoint(t) {
    return this._constrainedPointList.getPoint(t)
  }
  contains2D(t, n) {
    return this._constrainedPointList.contains2D(t, n)
  }
  contains2DPoint(t) {
    return this._constrainedPointList.contains2DPoint(t)
  }
  contains2DCoordinates(t, n) {
    return this._constrainedPointList.contains2DCoordinates(t, n)
  }
  copy() {
    return new ConstrainedPolygon(
      this._constrainedPointList.shape.copy(),
      this._constrainedPointList.constraint
    )
  }
  implMove(t, n, i, o) {
    if (this._constrainedPointList.shape instanceof Polygon)
      this._constrainedPointList.shape.implMove(t, n, i, o)
    else throw new ProgrammingError('Cannot implMove for ConstrainedPolygon')
  }
  implTranslate(t, n, i, o) {
    if (this._constrainedPointList.shape instanceof Polygon)
      this._constrainedPointList.shape.implTranslate(t, n, i, o)
    else
      throw new ProgrammingError('Cannot implTranslate for ConstrainedPolygon')
  }
}
