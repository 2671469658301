const EMPTY_STYLE_VALUE = ''
export class CursorHandle {
  constructor(r, s, o) {
    this._cursorManager = r
    this._node = s
    this._cursor = o
  }
  get cursor() {
    return this._cursor
  }
  remove() {
    this._cursorManager.removeCursor(this)
  }
  applyCursor() {
    this._node.style.cursor = this._cursor
    if (this._node.style.cursor !== this._cursor)
      throw new Error(
        `Failed to apply CSS cursor "${this._cursor}". It might be an invalid CSS cursor value.`
      )
  }
}
export class CursorManager {
  constructor(r) {
    this._node = r
    this._cursors = [new CursorHandle(this, r, EMPTY_STYLE_VALUE)]
    this._applyActiveCursor()
  }
  _applyActiveCursor() {
    this._cursors[0].applyCursor()
  }
  addCursor(r) {
    const s = new CursorHandle(this, this._node, r)
    this._cursors.unshift(s)
    this._applyActiveCursor()
    return s
  }
  get domNode() {
    return this._node
  }
  removeCursor(r) {
    const s = this._cursors.indexOf(r)
    if (-1 === s) return
    this._cursors.splice(s, 1)
    this._applyActiveCursor()
  }
}
