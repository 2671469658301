import { NotImplementedError } from '../../error/NotImplementedError.js'
function AttributeValueProvider(t) {
  this._function = t
  this._counter = 1
  this._mapping = new Map()
}
AttributeValueProvider.prototype.get = function (t, e) {
  var r = this.getValue(t, e)
  return this.map(r)
}
AttributeValueProvider.prototype.getValue = function (t, e) {
  throw new NotImplementedError('Implement getValue in the value provider!')
}
AttributeValueProvider.prototype.map = function (t) {
  if (null === t || 'undefined' === typeof t) return -1
  if (true === t) return 1
  if (false === t) return 0
  if (t !== t) return t
  if (t === Number(t)) return t
  var e = this._mapping.get(t)
  if (!e) {
    e = this._counter++
    this._mapping.set(t, e)
  }
  return e
}
export { AttributeValueProvider }
