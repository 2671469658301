import { Filled } from './util/Filled.js'
import { Stroked } from './util/Stroked.js'
import { Length } from './util/Length.js'
import { Pattern } from '../Pattern.js'
import { PatternType } from './util/PatternType.js'
const DEFAULT_FIXED_LENGTH = 10
const DEFAULT_RELATIVE_LENGTH = 1
const DEFAULT_MIN_HEIGHT = -5
const DEFAULT_MAX_HEIGHT = 5
export class RectanglePattern extends Pattern {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}
    super(PatternType.RECTANGLE)
    const e = t.fill || {}
    const i = t.line || {}
    const h =
      'number' !== typeof t.length
        ? t.relative
          ? DEFAULT_RELATIVE_LENGTH
          : DEFAULT_FIXED_LENGTH
        : t.length
    const n = t.relative ? 0 : h
    const s = t.relative ? h : 0
    this._filled = new Filled(e)
    this._stroked = new Stroked(i)
    this._length = new Length({ fixedLength: n, relativeLength: s })
    this._minHeight =
      'number' === typeof t.minHeight ? t.minHeight : DEFAULT_MIN_HEIGHT
    this._maxHeight =
      'number' === typeof t.maxHeight ? t.maxHeight : DEFAULT_MAX_HEIGHT
    this.flexible = !this.lineColor
    this.canBend = this.flexible
  }
  get minHeight() {
    return this._minHeight
  }
  get maxHeight() {
    return this._maxHeight
  }
  getMinHeight() {
    return this._minHeight
  }
  getMaxHeight() {
    return this._maxHeight
  }
  get worldSizeContext() {
    return this._length.worldSizeContext
  }
  set worldSizeContext(t) {
    this._length.worldSizeContext = t
  }
  getUomSizeToPixels(t) {
    return this._length.getUomSizeToPixels(t)
  }
  getWidth(t) {
    return this._length.getWidth(t)
  }
  isRelativeLength() {
    return this._length.isRelativeLength()
  }
  get fixedLength() {
    return this._length.fixedLength
  }
  set fixedLength(t) {
    this._length.fixedLength = t
  }
  get relativeLength() {
    return this._length.relativeLength
  }
  set relativeLength(t) {
    this._length.relativeLength = t
  }
  strokePath(t) {
    this._stroked.strokePath(t)
  }
  get lineColor() {
    return this._stroked.lineColor
  }
  set lineColor(t) {
    this._stroked.lineColor = t
  }
  get lineWidth() {
    return this._stroked.lineWidth
  }
  set lineWidth(t) {
    this._stroked.lineWidth = t
  }
  get dashed() {
    return this._stroked.dashed
  }
  set dashed(t) {
    this._stroked.dashed = t
  }
  fillPath(t) {
    this._filled.fillPath(t)
  }
  get fillColor() {
    return this._filled.fillColor
  }
  set fillColor(t) {
    this._filled.fillColor = t
  }
  paint(t, e, i, h) {
    const n = h || this.getWidth(e)
    const s = this.getUomSizeToPixels
      ? this.getUomSizeToPixels(this._minHeight)
      : this._minHeight
    const l = this.getUomSizeToPixels
      ? this.getUomSizeToPixels(this._maxHeight)
      : this._maxHeight
    t.scale(1, -1)
    t.beginPath()
    t.moveTo(0, s)
    t.lineTo(0, l)
    t.lineTo(n, l)
    t.lineTo(n, s)
    t.closePath()
    this.fillPath(t)
    this.strokePath(t)
    t.scale(1, -1)
  }
  paintFlexible(t, e, i) {
    const h = e.totalLength()
    this.paint(t, h, 0, i)
  }
  appendHash(t) {
    this._length.appendHash(t)
    this._stroked.appendHash(t)
    this._filled.appendHash(t)
    t.appendDouble(this._minHeight)
    t.appendDouble(this._maxHeight)
  }
}
