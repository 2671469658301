import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { EventedSupport } from '../../../util/EventedSupport.js'
import { isFunction } from '../../../util/Lang.js'
import { Timer, TimerTriggeredEvent } from '../../../util/Timer.js'
import { QueryProvider } from '../QueryProvider.js'
import { CursorProcessor } from './CursorProcessor.js'
import { SingleQueryProvider } from '../SingleQueryProvider.js'
function getTimer() {
  let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null
  if (null === e) return null
  const [r, t] = e instanceof Date ? [Number(e) - Date.now(), 1] : [e, 1 / 0]
  return new Timer(r, t)
}
export class LoadingStrategy {
  _eventedSupport = new EventedSupport()
  constructor(e) {
    this._queryProvider = new SingleQueryProvider(QueryProvider.QUERY_ALL)
    this._queryProviderHandle = this.registerQueryProviderInvalidateHandle()
    this._needQuery = true
    this._layer = null
    this._activeQueryLevel = null
    this._activeQuery = null
    this._timer = null
    if (e?.refresh) this.refresh = e.refresh
  }
  get layer() {
    return this._layer
  }
  get queryProvider() {
    return this._queryProvider
  }
  set queryProvider(e) {
    if (
      !e ||
      !isFunction(e.getQueryLevelScales) ||
      !isFunction(e.getQueryForLevel)
    )
      throw new ProgrammingError(
        'QueryProvider must implement getQueryLevelScales and getQueryForLevel'
      )
    if (this._queryProvider !== e) this.activeQueryLevel = null
    this._queryProvider = e
    this.registerQueryProviderInvalidateHandle()
    this.invalidate()
  }
  get activeQueryLevel() {
    return this._activeQueryLevel
  }
  set activeQueryLevel(e) {
    this._activeQueryLevel = e
  }
  get activeQuery() {
    return this._activeQuery
  }
  set activeQuery(e) {
    this._activeQuery = e
  }
  get timer() {
    return this._timer?.timer || null
  }
  get needQuery() {
    return this._needQuery
  }
  set needQuery(e) {
    this._needQuery = e
  }
  get refresh() {
    const e = this._timer
    if (!e?.timer?.isRunning) return null
    return e.date || e.timer.interval
  }
  set refresh(e) {
    if (this._timer) {
      this._timer.handle.remove()
      this._timer.timer.stop()
      this._timer = null
    }
    if (!e) return
    const r = getTimer(e)
    if (!r) return
    const t = r.on(TimerTriggeredEvent, () => {
      if (this.layer?.map) this.invalidate()
    })
    r.start()
    const i = e instanceof Date ? e : null
    this._timer = { timer: r, handle: t, date: i }
  }
  invalidate() {
    this.needQuery = true
    this.activeQuery = QueryProvider.QUERY_ALL
    if (this.layer) {
      this.refreshProperties()
      this.layer.refreshQueryResults()
    }
    this.timer?.reset()
    this._eventedSupport.emit('invalidate')
  }
  isCompatibleWithModel(e) {
    return true
  }
  isRenderIncremental() {
    return true
  }
  shouldQueryStore(e) {
    return this.needQuery || this.shouldQuery(e)
  }
  runQueryProcess(e, r, t, i, s, n) {
    const o = this.shouldClear()
    if (s) {
      r.resetCaches()
      this.needQuery = false
    }
    const l = s ? this.queryModel(e.sourceModel, n) : null
    const u = l ? e.cache(l, n) : e.query()
    const a = r.runQuery(e, u, this.layer)
    return Promise.resolve(a).then(
      (e) =>
        new CursorProcessor(e, t, {
          shouldUpdateFunc: this.shouldUpdate,
          incremental: i,
          clearFlag: o,
        })
    )
  }
  findMapLevel() {
    if (!this.layer?.map) return 0
    const e = this.layer.map
    const r = this.queryProvider.getQueryLevelScales(this.layer, e) || []
    let t = 0
    const i = e.xScale
    for (let e = 0; e < r.length; e++) if (i >= r[e]) t++
    return t
  }
  refreshProperties() {}
  isMapLevelChanged() {
    return (
      null === this.activeQueryLevel ||
      this.activeQueryLevel !== this.findMapLevel()
    )
  }
  getLevelQueryParams() {
    const e = this.findMapLevel()
    return { level: e, query: this.queryProvider.getQueryForLevel(e) }
  }
  onAddedToMap() {
    if (this.layer && this.layer.map) {
      const e = this.layer.map.on('idle', this.onMapIdle.bind(this))
      const r = this.layer.on('RemovedFromMap', () => {
        e.remove()
        r.remove()
      })
    }
    this.invalidate()
  }
  onVisibilityChanged() {
    if (this.layer && this.needQuery) this.layer.refreshQueryResults()
  }
  onMapIdle() {
    this.layer?.refreshQueryResults()
  }
  onShapeProviderChange() {
    this.layer?.refreshQueryResults()
  }
  onEnterAllowedScaleRange() {
    if (this.layer && this.needQuery) this.layer.refreshQueryResults()
  }
  registerFeatureLayer(e) {
    if (this.layer)
      throw new ProgrammingError(
        'Cannot assign single LoadingStrategy to multiple layers'
      )
    this._layer = e
    const r = [
      e.on('VisibilityChanged', this.onVisibilityChanged.bind(this)),
      e.on('ShapeProviderChange', this.onShapeProviderChange.bind(this)),
      e.on('EnterAllowedScaleRange', this.onEnterAllowedScaleRange.bind(this)),
    ]
    const t = e.on('RemovedFromMap', () => {
      r.forEach((e) => e.remove())
      t.remove()
    })
    this.needQuery = true
  }
  on(e, r, t) {
    return this._eventedSupport.on(e, r, t)
  }
  shouldUpdate(e, r) {
    return false
  }
  registerQueryProviderInvalidateHandle() {
    this._queryProviderHandle?.remove()
    this._queryProviderHandle = this._queryProvider.on('invalidate', () =>
      this.invalidate()
    )
    return this._queryProviderHandle
  }
}
