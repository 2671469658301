import { Editor } from '../Editor.js'
export class CompositeEditor extends Editor {
  constructor(e) {
    super()
    this._delegates = e
  }
  canEdit(e) {
    for (const t of this._delegates) if (t.canEdit(e)) return true
    return false
  }
  getEditHandles(e) {
    for (const t of this._delegates)
      if (t.canEdit(e)) return t.getEditHandles(e)
    return []
  }
  createTranslateHandle(e) {
    for (const t of this._delegates)
      if (t.canEdit(e)) return t.createTranslateHandle(e)
    return null
  }
  getCreateHandle(e) {
    for (const t of this._delegates)
      if (t.canEdit(e)) return t.getCreateHandle(e)
    return null
  }
  get delegates() {
    return this._delegates
  }
  set delegates(e) {
    this._delegates = e
  }
}
