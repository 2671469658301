import { XML } from '../../util/XML.js'
import { VersionNegotiator } from './common/VersionNegotiator.js'
function parseVersion(e) {
  var r = XML.stringToXml(e)
  var t = null
  try {
    t = r.getElementsByTagName('Capabilities')[0].getAttribute('version')
  } catch (e) {}
  return t
}
function createVersionNegotiator(e, r, t, o) {
  return new VersionNegotiator(
    e,
    'WMTS',
    parseVersion,
    r || ['1.0.0'],
    t || null,
    o || {}
  )
}
export var WMTSCapabilitiesUtil = {
  parseVersion: parseVersion,
  createVersionNegotiator: createVersionNegotiator,
}
