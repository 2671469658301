import { RasterTileSetModel } from '../tileset/RasterTileSetModel.js'
import { WebMercatorBounds } from '../WebMercatorBounds.js'
const bounds = WebMercatorBounds.create()
const reference = bounds.reference
const quadTreeStructure = {
  reference: reference,
  bounds: bounds,
  levelCount: 20,
  level0Columns: 1,
  level0Rows: 1,
  tileWidth: 256,
  tileHeight: 256,
}
const MODEL_OPTIONS = {
  structure: quadTreeStructure,
  modelDescriptor: Object.freeze({
    description: 'Google Maps Layer.',
    name: 'Google Maps',
    source: 'www.google.com',
    type: 'Image data',
  }),
}
export class GoogleTileSetModel extends RasterTileSetModel {
  constructor() {
    super(MODEL_OPTIONS)
  }
  getImage() {
    throw new Error('Retrieving image of google model is not supported')
  }
}
