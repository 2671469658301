import { HTMLLabelGroup } from './HTMLLabelGroup.js'
export class LabelManager {
  constructor() {
    this.groupCache = new Map()
  }
  getLabelGroup(a, r) {
    if (!this.groupCache.has(a))
      this.groupCache.set(a, new HTMLLabelGroup(a, r))
    return this.groupCache.get(a)
  }
  clear() {
    this.groupCache.forEach((a) => {
      a.clear()
    })
  }
  drawLabelManager(a, r, e, o, h, c) {
    this.groupCache.forEach((p) => {
      p.drawLabelGroup(a, r, e, o, h, c)
      p.clear()
    })
  }
}
