import { isDefined, isObject, isString } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import { getReference } from '../../reference/ReferenceProvider.js'
const crs84 = getReference('CRS:84')
function reverseBboxAxes(e) {
  let r
  if (e.length > 0) {
    r = e[0]
    e[0] = e[1]
    e[1] = r
  }
  if (e.length > 2) {
    r = e[2]
    e[2] = e[3]
    e[3] = r
  }
  if (e.length > 4) {
    r = e[4]
    e[4] = e[5]
    e[5] = r
  }
}
function reverseGeometryAxes(e) {
  const r = (e) => {
    const r = e[0]
    e[0] = e[1]
    e[1] = r
  }
  switch (e.type) {
    case 'Point': {
      const { coordinates: t } = e
      r(t)
      break
    }
    case 'LineString':
    case 'MultiPoint': {
      const { coordinates: t } = e
      for (let e = 0, n = t.length; e < n; e++) r(t[e])
      break
    }
    case 'Polygon':
    case 'MultiLineString': {
      const { coordinates: t } = e
      for (let e = 0, n = t.length; e < n; e++) {
        const n = t[e]
        for (let e = 0, t = n.length; e < t; e++) r(n[e])
      }
      break
    }
    case 'MultiPolygon': {
      const { coordinates: t } = e
      for (let e = 0, n = t.length; e < n; e++) {
        const n = t[e]
        for (let e = 0, t = n.length; e < t; e++) {
          const t = n[e]
          for (let e = 0, n = t.length; e < n; e++) r(t[e])
        }
      }
      break
    }
    case 'GeometryCollection': {
      const { geometries: r } = e
      for (let e = 0, t = r.length; e < t; e++) reverseGeometryAxes(r[e])
      break
    }
  }
  if (e.bbox) reverseBboxAxes(e.bbox)
}
function reverseAxes(e) {
  switch (e.type) {
    case 'Feature': {
      const { geometry: r, bbox: t } = e
      if (r) reverseGeometryAxes(r)
      if (t) reverseBboxAxes(t)
      break
    }
    case 'FeatureCollection': {
      const { features: r, bbox: t } = e
      for (let e = 0, t = r.length; e < t; e++) reverseAxes(r[e])
      if (t) reverseBboxAxes(t)
      break
    }
    default:
      reverseGeometryAxes(e)
      break
  }
}
function huntForCRS(e) {
  let r = isDefined(e.crs) ? parseCRS(e.crs) : null
  if (r) return r
  switch (e.type) {
    case 'FeatureCollection': {
      const t = e
      for (let e = 0, n = t.features.length; e < n && !r; e++) {
        r = huntForCRS(t.features[e])
        if (r) return r
      }
      break
    }
    case 'Feature': {
      const r = e
      return isDefined(r.geometry) ? huntForCRS(r.geometry) : null
    }
  }
  return null
}
function parseCRS(e) {
  if (!e || !isString(e.type) || !isObject(e.properties)) return null
  const r = e.type
  const t = e.properties
  if ('name' === r)
    try {
      return getReference(t.name)
    } catch (e) {
      return null
    }
  if ('link' === r) {
    Log.warn('linked CRS reference in GeoJson is not supported')
    return null
  }
  try {
    return getReference(`${r}:${t.code}`)
  } catch (e) {
    return null
  }
}
export const GeoJsonUtil = {
  isDefaultReference: function (e) {
    const r = e?.shape?.reference
    return !!r && !crs84.equals(r)
  },
  reverseAxes: reverseAxes,
  determineReference: function (e, r) {
    if (r) return r
    const t = undefined
    return (isString(e.type) ? huntForCRS(e) : null) || crs84
  },
}
