import { createArc } from '../../../../shape/ShapeFactory.js'
import { HelperHandle } from './HelperHandle.js'
export class ArcHelperHandle extends HelperHandle {
  constructor(e, r) {
    super(r)
    this._arc = e
  }
  get arc() {
    return this._arc
  }
  onDraw(e, r) {
    const t = this.getHelperStyle(r)
    if (t) {
      const a =
        (this.arc.sweepAngle >= 0 ? -1 : +1) *
        (360 - Math.abs(this.arc.sweepAngle))
      const s = createArc(
        this.arc.reference,
        this.arc.center,
        this.arc.a,
        this.arc.b,
        this.arc.rotationAzimuth,
        this.arc.startAzimuth,
        a
      )
      const c = { ...t, drapeTarget: this.getDrapeTarget(r, s) }
      e.drawShape(s, c)
    }
  }
}
