import { kmlValueMapFactory } from './KMLValues.js'
export let KMLUnits = (function (t) {
  t[(t['FRACTION'] = 0)] = 'FRACTION'
  t[(t['PIXELS'] = 1)] = 'PIXELS'
  t[(t['INSET_PIXELS'] = 2)] = 'INSET_PIXELS'
  return t
})({})
export const KML_UNITS_MAP = {
  fraction: KMLUnits.FRACTION,
  insetPixels: KMLUnits.INSET_PIXELS,
  pixels: KMLUnits.PIXELS,
}
export const DEFAULT_XY_VECTOR = {
  x: 0,
  y: 0,
  xUnits: KMLUnits.FRACTION,
  yUnits: KMLUnits.FRACTION,
}
export const getKmlUnits = kmlValueMapFactory(KMLUnits.FRACTION, KML_UNITS_MAP)
