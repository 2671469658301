import { TileWindow } from './TileWindow.js'
import { DefaultTileRecord } from './DefaultTileRecord.js'
import { EmptyTileRecord } from './EmptyTileRecord.js'
import { CombinedTileRecord } from './CombinedTileRecord.js'
import { TileCoordinate } from './TileCoordinate.js'
import { Log } from '../../util/Log.js'
import { coordKeyLXY, mapToLowerLevel } from './TileUtil.js'
const DEBUG_LOG = false
const FALLBACK_TO_LOWER_RES_TILE = true
function findLowerResTileInCache(i, e, t) {
  let o = i.level
  let s, n, r
  let l
  s = i.x
  n = i.y
  while (!r && o > 0) {
    o -= 1
    s = (s / 2) | 0
    n = (n / 2) | 0
    l = coordKeyLXY(o, s, n)
    r = e.get(l)
  }
  if (0 === o && !r) r = t[l]
  return r
}
export class SlidingTileWindow {
  constructor(i, e, t) {
    this._tileSet = i
    this._previousWindow = new TileWindow()
    this._currentWindow = new TileWindow()
    this._tileCache = e
    this._topTiles = t
    this._shouldFlipXAxis = false
    this._shouldFlipYAxis = false
    this._tmpCoord = new TileCoordinate(0, 0, 0)
  }
  get currentWindow() {
    return this._currentWindow
  }
  get tileSet() {
    return this._tileSet
  }
  get shouldFlipXAxis() {
    return this._shouldFlipXAxis
  }
  set shouldFlipXAxis(i) {
    this._shouldFlipXAxis = i
  }
  get shouldFlipYAxis() {
    return this._shouldFlipYAxis
  }
  set shouldFlipYAxis(i) {
    this._shouldFlipYAxis = i
  }
  setTileWindowCoordinate(i, e, t, o) {
    if (i.equals(this._currentWindow.grid) && !e) {
      if (DEBUG_LOG) Log.debug(' NOT UPDATING GRID OR QUEUEING PENDING TILES')
      return
    }
    if (DEBUG_LOG) Log.debug(' GRID IS NOT THE SAME')
    const s = this._previousWindow
    this._previousWindow = this._currentWindow
    this._currentWindow = s
    this._currentWindow.resetFor(i)
    const n = this._previousWindow.grid.level
    const r = this._currentWindow.grid.level
    if (r === n) this.pan(i, e, t, o)
    else if (r > n) this.zoomIn(i, e, t, o)
    else this.zoomOut(i, e, t, o)
    this._previousWindow.clear()
  }
  pan(i, e, t, o) {
    const s = i.level
    const n = i.y
    const r = i.x
    const l = this._tileSet.getTileRowCount(s)
    const d = this._tileSet.getTileColumnCount(s)
    let h, c, u, p, _
    for (h = 0, u = this._currentWindow.height; h < u; h++)
      for (c = 0, p = this._currentWindow.width; c < p; c++) {
        const i = n + h
        const o = r + c
        if (i >= 0 && i < l && o >= 0 && o < d) {
          this._tmpCoord.level = s
          this._tmpCoord.x = o
          this._tmpCoord.y = i
          _ = this._previousWindow.getTileRecord(this._tmpCoord)
          if (null != _) {
            this._currentWindow.set(c, h, _)
            if (e) t.push(new TileCoordinate(s, o, i))
          } else {
            const e = new TileCoordinate(s, o, i)
            t.push(e)
            this.storeEmptyRecord(c, h, e)
          }
        }
      }
    const w = this._currentWindow.grid
    for (h = 0; h < this._previousWindow.height; h++)
      for (c = 0; c < this._previousWindow.width; c++) {
        _ = this._previousWindow.get(c, h)
        if (null != _ && _.proxy && !w.contains(_.coordinate))
          o.push(_.coordinate)
      }
  }
  zoomIn(i, e, t, o) {
    const s = this._previousWindow.grid.level
    const n = i.level
    const r = i.y
    const l = i.x
    const d = this._tileSet.getTileRowCount(n)
    const h = this._tileSet.getTileColumnCount(n)
    let c, u, p, _
    let w, f, a, T, W
    for (c = 0, p = this._currentWindow.height; c < p; c++)
      for (u = 0, _ = this._currentWindow.width; u < _; u++) {
        T = r + c
        W = l + u
        a = new TileCoordinate(n, W, T)
        if (T >= 0 && T < d && W >= 0 && W < h) {
          f = mapToLowerLevel(a, s)
          w = this._previousWindow.getTileRecord(f)
          if (null != w) {
            const i = w.deriveTile(
              a,
              this._shouldFlipXAxis,
              this._shouldFlipYAxis
            )
            if (null != i) {
              this._currentWindow.set(u, c, i)
              if (i.proxy || e) t.push(i.coordinate)
              continue
            }
          }
          t.push(a)
          this.storeEmptyRecord(u, c, a)
        }
      }
    for (c = 0, p = this._previousWindow.height; c < p; c++)
      for (u = 0, _ = this._previousWindow.width; u < _; u++) {
        w = this._previousWindow.get(u, c)
        if (null != w && w.proxy) o.push(w.coordinate)
      }
  }
  zoomOut(i, e, t, o) {
    const s = i.level
    const n = i.x
    const r = i.width
    const l = i.y
    const d = i.height
    let h, c
    let u
    const p = new Array(r * d)
    for (c = 0; c < this._previousWindow.height; c++)
      for (h = 0; h < this._previousWindow.width; h++) {
        const e = this._previousWindow.get(h, c)
        if (null != e) {
          const t = mapToLowerLevel(e.coordinate, s)
          if (i.contains(t)) {
            const i = t.x - n
            const o = undefined
            const s = (t.y - l) * r + i
            u = p[s]
            if (null == u) {
              u = []
              p[s] = u
            }
            if (u._hasFallback && e.fallback) continue
            const d = e.deriveTile(
              t,
              this._shouldFlipXAxis,
              this._shouldFlipYAxis
            )
            if (null != d) {
              d.fallback = e.fallback
              if (d.entireAreaCovered) u.unshift(d)
              else u.push(d)
              u._hasFallback =
                u._hasFallback || (d.fallback && d.entireAreaCovered)
            }
          }
        }
      }
    for (c = 0; c < d; c++)
      for (h = 0; h < r; h++) {
        let i = null
        u = p[c * r + h]
        if (null != u && u.length) {
          if (1 === u.length) i = u[0]
          else if (!u._hasFallback)
            for (let e = 0; e < u.length; e++) {
              const t = u[e]
              if (t.entireAreaCovered) {
                i = t
                break
              }
            }
          if (null == i) {
            const i = new CombinedTileRecord(
              new TileCoordinate(s, n + h, l + c),
              u
            )
            i.complete = u._hasFallback || false
            t.push(i.coordinate)
            this._currentWindow.storeTileRecord(i)
          } else {
            if (i.proxy || e) t.push(i.coordinate)
            this._currentWindow.storeTileRecord(i)
          }
        } else {
          const i = new TileCoordinate(s, n + h, l + c)
          t.push(i)
          this.storeEmptyRecord(h, c, i)
        }
      }
    for (let i = 0; i < this._previousWindow.height; i++)
      for (let e = 0; e < this._previousWindow.width; e++) {
        const t = this._previousWindow.get(e, i)
        if (null != t && t.proxy) o.push(t.coordinate)
      }
  }
  storeEmptyRecord(i, e, t) {
    if (FALLBACK_TO_LOWER_RES_TILE) {
      const i = findLowerResTileInCache(t, this._tileCache, this._topTiles)
      if (i) {
        const e = undefined
        const o = new DefaultTileRecord(i).deriveTile(
          t,
          this._shouldFlipXAxis,
          this._shouldFlipYAxis
        )
        if (o) {
          o.fallback = true
          this._currentWindow.storeTileRecord(o)
          return
        }
      }
    }
    this.storeEmptyRecordImpl(i, e, t)
  }
  storeEmptyRecordImpl(i, e, t) {
    this._currentWindow.set(i, e, new EmptyTileRecord(t))
  }
}
