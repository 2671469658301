import { ShapeType } from '../../../shape/ShapeType.js'
import { Identifier } from '../../ContextMenu.js'
import {
  EVENT_HANDLED,
  REQUEST_DEACTIVATION,
} from '../../controller/HandleEventResult.js'
import { GestureEventType } from '../../input/GestureEventType.js'
import { CompositeEditHandle } from './CompositeEditHandle.js'
import { PointHandle } from './PointHandle.js'
import { EditShapeStatus } from '../../controller/EditShapeEvent.js'
function deletableState(t) {
  return (
    t.type === GestureEventType.LONG_PRESS ||
    (t.type === GestureEventType.SINGLE_CLICK_UP && t.isModified())
  )
}
function hasItem(t, e, i) {
  return i.label === t && i.action === e
}
function hasItemWithLabel(t, e) {
  return e.label === t
}
export class SinglePointDeleteHandle extends PointHandle {
  constructor(t, e, i) {
    super(i)
    this._pointList = t
    this._index = e
  }
  get pointList() {
    return this._pointList
  }
  get index() {
    return this._index
  }
  getDefaultHandleIconStyle(t) {
    return null
  }
  process(t, e) {
    this.pointList.removePoint(this._index)
    this.emitEditShapeEvent(this.pointList, EditShapeStatus.IN_PROGRESS)
    return EVENT_HANDLED | REQUEST_DEACTIVATION
  }
  shouldActivate(t, e) {
    return deletableState(t) && this.interacts(t, e)
  }
  shouldProcess(t) {
    return deletableState(t)
  }
  shouldDeactivate(t) {
    return true
  }
  getPoint() {
    return this.pointList.getPoint(this._index)
  }
  onCreateContextMenu(t, e, i, n) {
    const s = this.interacts(t, e)
    const o = this
    if (s) {
      if (!i.someItem(hasItemWithLabel.bind(null, 'Delete Point')))
        i.addItem({
          id: Identifier.DELETE_POINT_ID,
          label: 'Delete Point',
          action: function () {
            try {
              o.process(t, e)
              n()
            } catch (t) {}
          },
        })
      if (!i.someItem(hasItem.bind(null, 'Cancel', n)))
        i.addItem({ id: Identifier.CANCEL_ID, label: 'Cancel', action: n })
    }
  }
}
export class PointListDeleteHandle extends CompositeEditHandle {
  constructor(t, e, i) {
    super([], { cascading: false })
    this._pointList = t
    this._handleIconStyle = i
    this._minPointCount = e
    this.update()
  }
  get pointList() {
    return this._pointList
  }
  get minimumPointCount() {
    return this._minPointCount
  }
  get handleIconStyle() {
    return this._handleIconStyle
  }
  createDeleteHandles() {
    const t = []
    for (let e = 0; e < this.pointList.pointCount; e++)
      t.push(
        new SinglePointDeleteHandle(this.pointList, e, this.handleIconStyle)
      )
    return t
  }
  shouldUpdateHandles() {
    return this.pointList.pointCount !== this.handles.length
  }
  update() {
    if (
      this.pointList.type === ShapeType.POLYLINE &&
      this.pointList.pointCount <= 2
    ) {
      this.activeHandleIndex = -1
      this.handles = []
      return
    }
    if (
      this.pointList.type === ShapeType.POLYGON &&
      this.pointList.pointCount <= 3
    ) {
      this.activeHandleIndex = -1
      this.handles = []
      return
    }
    if (
      this.minimumPointCount &&
      -1 !== this.minimumPointCount &&
      this.pointList.pointCount <= this.minimumPointCount
    ) {
      this.activeHandleIndex = -1
      this.handles = []
      return
    }
    if (this.shouldUpdateHandles()) this.handles = this.createDeleteHandles()
  }
}
