import { AutoIncrement } from '../../view/feature/transformation/AutoIncrement.js'
import { createOptimizedEventedSupport } from '../../util/EventedSupport.js'
import { isFunction, isNumber, isString } from '../../util/Lang.js'
import { ProgrammingError } from '../../error/ProgrammingError.js'
import { IterableCursor } from './IterableCursor.js'
export class MemoryStoreBase {
  constructor() {
    this._eventedSupport = createOptimizedEventedSupport(['StoreChanged'], true)
    this._keyGenerator = new AutoIncrement()
  }
  on(e, r, t, i) {
    let n
    if (i && isFunction(i.query)) {
      const e = i.query
      n = (t, i, n) => {
        if (e(i)) r.call(this, t, i, n)
      }
    } else n = r
    return this._eventedSupport.on(e, n, t)
  }
  query(e) {
    return new IterableCursor(this.getIterable(), isFunction(e) ? e : void 0)
  }
  put(e, r) {
    if (this.hasFeature(e)) return this.internalUpdate(e)
    return this.internalAdd(e)
  }
  add(e, r) {
    if (this.hasFeature(e))
      throw new ProgrammingError(
        `MemoryStore::add : the store already contains a Feature with ID ${e.id}.`
      )
    return this.internalAdd(e)
  }
  remove(e) {
    const r = this.internalRemove(e)
    if (r) this._eventedSupport.emitStoreChangedEvent('remove', r, e)
    return !!r
  }
  reload(e) {
    this.clear()
    e.forEach((e) => this.add(e))
    return true
  }
  emit(e) {
    for (
      var r = arguments.length, t = new Array(r > 1 ? r - 1 : 0), i = 1;
      i < r;
      i++
    )
      t[i - 1] = arguments[i]
    this._eventedSupport.emit(e, ...t)
  }
  getValidFeatureId(e) {
    if (!isNumber(e) && !isString(e))
      do {
        e = this._keyGenerator.nextKey()
      } while (this.has(e))
    return e
  }
  hasFeature(e) {
    return !!e && this.has(e.id)
  }
}
