import { MapNavigatorImpl } from './MapNavigatorImpl.js'
import { EnabledOperations } from './MapNavigatorBase.js'
export class MapNavigator {
  static ALL = EnabledOperations.ALL
  static NONE = EnabledOperations.NONE
  constructor(e) {
    this._delegate = new MapNavigatorImpl(e)
  }
  updateCamera(e, t) {}
  beginPan(e, t) {
    let a =
      arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : false
    return this._delegate.beginPan(e, t, a)
  }
  incrementalPan(e, t, a, n) {
    return this._delegate.incrementalPan(e, t, a, n)
  }
  endPan() {
    return this._delegate.endPan()
  }
  beginRotate(e, t) {
    return this._delegate.beginRotate(e, t)
  }
  beginRotateWorld(e) {
    return this._delegate.beginRotateWorld(e)
  }
  incrementalRotateAngles(e, t) {
    return this._delegate.incrementalRotateAngles(e, t)
  }
  endRotate() {
    return this._delegate.endRotate()
  }
  beginZoom(e, t) {
    return this._delegate.beginZoom(e, t)
  }
  beginZoomWorld(e) {
    return this._delegate.beginZoomWorld(e)
  }
  incrementalZoom(e, t) {
    return this._delegate.incrementalZoom(e, t)
  }
  endZoom() {
    return this._delegate.endZoom()
  }
  getCameraWithConstraints(e) {
    return this._delegate.getCameraWithConstraints(e)
  }
  fit(e, t) {
    return this._delegate.fit(e, t)
  }
  pan(e) {
    return this._delegate.pan(e)
  }
  zoom(e) {
    return this._delegate.zoom(e)
  }
  rotate(e) {
    return this._delegate.rotate(e)
  }
  snapToScalesIfNeeded(e, t, a, n) {
    return this._delegate.snapToScalesIfNeeded(e, t, a, n)
  }
  panTo(e) {
    return this._delegate.panTo(e)
  }
  panBy(e, t) {
    return this._delegate.panBy(e, t)
  }
  setCenter(e, t) {
    return this._delegate.setCenter(e, t)
  }
  zoomTo(e) {
    return this._delegate.zoomTo(e)
  }
  zoomOut() {
    return this._delegate.zoomOut()
  }
  zoomOutFixing(e, t) {
    return this._delegate.zoomOutFixing(e, t)
  }
  zoomOutFixingWithScaleFactor(e, t, a, n) {
    return this._delegate.zoomOutFixingWithScaleFactor(e, t, a, n)
  }
  zoomIn() {
    return this._delegate.zoomIn()
  }
  zoomInFixing(e, t) {
    return this._delegate.zoomInFixing(e, t)
  }
  zoomInFixingWithScaleFactor(e, t, a, n) {
    return this._delegate.zoomInFixingWithScaleFactor(e, t, a, n)
  }
  setScaleFixing(e, t, a) {
    return this._delegate.setScaleFixing(e, t, a)
  }
  setScale(e) {
    this._delegate.setScale(e)
  }
  triggerRefresh() {
    this._delegate.triggerRefresh()
  }
  invalidate() {
    this._delegate.invalidate()
  }
  computeScaleX() {
    return this._delegate.computeScaleX()
  }
  computeScaleY() {
    return this._delegate.computeScaleY()
  }
  lookFrom(e, t, a, n, i) {
    return this._delegate.lookFrom(e, t, a, n, i)
  }
  _flyToAnimated(e, t) {
    return this._delegate._flyToAnimated(e, t)
  }
  violatesLeftEdge() {
    return this._delegate.violatesLeftEdge()
  }
  violatesRightEdge() {
    return this._delegate.violatesRightEdge()
  }
  violatesBottomEdge() {
    return this._delegate.violatesBottomEdge()
  }
  violatesTopEdge() {
    return this._delegate.violatesTopEdge()
  }
  get constraints() {
    return this._delegate.constraints
  }
  set constraints(e) {
    this._delegate.constraints = e
  }
  get constraints2D() {
    return this._delegate.constraints2D
  }
  set constraints2D(e) {
    this._delegate.constraints2D = e
  }
  get enabledOperations() {
    return this._delegate.enabledOperations
  }
  set enabledOperations(e) {
    this._delegate.enabledOperations = e
  }
  get defaults() {
    return this._delegate.defaults
  }
  set defaults(e) {
    this._delegate.defaults = e
  }
  get map() {
    return this._delegate.map
  }
  set map(e) {
    this._delegate.map = e
  }
  isAnimating() {
    return this._delegate.isAnimating()
  }
  get animationPromise() {
    return this._delegate.animationPromise
  }
}
