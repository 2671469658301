import { compose, rectangle } from '../../style/complexstroke/PatternFactory.js'
import { getWorldUOMInMeters } from '../../style/WorldSizeUtil.js'
import { ShapeType } from '../../../shape/ShapeType.js'
import { ShapeUtil } from '../../../shape/ShapeUtil.js'
import { isWorldSize, METER_UOM } from '../../../uom/UnitOfMeasureUtil.js'
function parseStrokeWithBorders(e) {
  var r = []
  var o = []
  if (e.innerBorder)
    a(e.innerBorder.color, -e.innerBorder.width, 0, e.innerBorder)
  if (e.outerBorder)
    a(e.outerBorder.color, 0, e.outerBorder.width, e.outerBorder)
  var t = false
  if (e.stroke)
    if (canCombineWithMainStyle(e.stroke)) {
      var n = e.stroke.width / 2
      a(e.stroke.color, -n, n, e.stroke)
    } else t = true
  var l = []
  if (r.length) l.push(i(r, null))
  if (o.length) l.push(i(o, METER_UOM))
  if (e.stroke && !t && 2 === l.length && !isWorldSize(e.stroke.uom))
    l.reverse()
  if (t)
    l.push(cloneObject(e, { fill: null, innerBorder: null, outerBorder: null }))
  if (e.fill) l[0].fill = e.fill
  return l
  function a(e, t, n, l) {
    if (isWorldSize(l.uom)) {
      t = getWorldUOMInMeters(l.uom, t)
      n = getWorldUOMInMeters(l.uom, n)
      o.push(createBorderRectanglePattern(e, t, n))
    } else r.push(createBorderRectanglePattern(e, t, n))
  }
  function i(r, o) {
    var t = {
      fallback: 1 === r.length ? r[0] : compose(r),
      sharpAngleThreshold: 0,
    }
    if (o) t.uom = o
    return cloneObject(e, {
      fill: null,
      innerBorder: null,
      outerBorder: null,
      stroke: t,
      normalizeShapeOrientation: true,
    })
  }
}
function canDrawBorderForShape(e, r) {
  var o
  return !(r && ShapeUtil.isExtrudedShape(e)) && ShapeUtil.isShapeClosed(e)
}
function canCombineWithMainStyle(e) {
  return (
    e &&
    !isComplexStrokeStyle(e) &&
    !e.dash &&
    !e.beginMarker &&
    !e.endMarker &&
    !e.bloom
  )
}
function isComplexStrokeStyle(e) {
  return !!(e && (e.fallback || e.regular || e.decorations))
}
function createBorderRectanglePattern(e, r, o) {
  return rectangle({
    length: 1,
    relative: true,
    fill: { color: e },
    minHeight: r,
    maxHeight: o,
  })
}
function unrollGeoCanvasCommands(e, r) {
  var o = unrollShapes(e)
  if (shouldUnrollStyle(o, r)) return unrollStyles(o, r)
  return o
}
function unrollShapes(e) {
  if (!shouldUnrollShapes(e)) return e
  var r = []
  var o, t
  for (var n = 0; n < e.length; ++n) {
    o = e[n]
    if (ShapeType.contains(o.shape.type, ShapeType.SHAPE_LIST))
      for (var l = 0; l < o.shape.shapeCount; l++) {
        t = cloneObject(o, { shape: o.shape.getShape(l) })
        Array.prototype.push.apply(r, unrollGeoCanvasCommands([t]))
      }
    else r.push(o)
  }
  return r
}
function shouldUnrollShapes(e) {
  var r = false
  for (var o = 0; o < e.length; ++o) {
    var t = e[o]
    if (ShapeType.contains(t.shape.type, ShapeType.SHAPE_LIST)) {
      r = true
      break
    }
  }
  return r
}
function shouldUnrollStyle(e, r) {
  for (var o = 0; o < e.length; ++o) {
    var t = e[o]
    var n = t.style
    if ((n.innerBorder || n.outerBorder) && canDrawBorderForShape(t.shape, r))
      return true
  }
  return false
}
function unrollStyles(e, r) {
  var o = []
  for (var t = 0; t < e.length; ++t) {
    var n = e[t]
    var l = n.style
    if ((l.innerBorder || l.outerBorder) && canDrawBorderForShape(n.shape, r)) {
      var a = parseStrokeWithBorders(l)
      for (var i = 0; i < a.length; i++) o.push(cloneObject(n, { style: a[i] }))
    } else o.push(n)
  }
  return o
}
function cloneObject(e, r) {
  return Object.assign({}, e, r)
}
export var PhotonShapePainterUtil = {
  unrollGeoCanvasCommands: unrollGeoCanvasCommands,
}
