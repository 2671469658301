import { OGCXmlWriter } from './OGCXmlWriter.js'
export class OGCEncoder {
  constructor(e, r, i) {
    this._version = e
    this._filterNamespace = r
    this._gmlNamespace = i
    this._nameSpaces = new Map()
    this._filterPrefix = ''
    this._gmlPrefix = 'gml'
    this._xmlWriter = new OGCXmlWriter(false, this._filterPrefix, [])
  }
  get version() {
    return this._version
  }
  get filterNamespace() {
    return this._filterNamespace
  }
  get gmlNamespace() {
    return this._gmlNamespace
  }
  get filterPrefix() {
    return this._filterPrefix
  }
  get gmlPrefix() {
    return this._gmlPrefix
  }
  get nameSpaces() {
    return this._nameSpaces
  }
  get xmlWriter() {
    return this._xmlWriter
  }
  encode(e, r) {
    const i = (r = r || {}).prettyPrint || false
    this._filterPrefix = r.filterPrefix || ''
    this._gmlPrefix = r.gmlPrefix || 'gml'
    this._xmlWriter = new OGCXmlWriter(i, this._filterPrefix, [])
    e.accept(this)
    let t = []
    this.nameSpaces.forEach((e, r, i) => {
      t.push({ prefix: r, namespace: e })
    })
    if (r.includeNamespaces)
      t = t.concat([
        { prefix: this._filterPrefix, namespace: this._filterNamespace },
        { prefix: this._gmlPrefix, namespace: this._gmlNamespace },
      ])
    this._xmlWriter = new OGCXmlWriter(i, this._filterPrefix, t)
    if (!r.nonWrap) this._xmlWriter.beginNode('Filter')
    e.accept(this)
    if (!r.nonWrap) this._xmlWriter.endNode()
    return this._xmlWriter.toString()
  }
  visitExpression(e) {
    throw new Error('Should not be called, Filter must be invalid')
  }
}
