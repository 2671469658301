import { SENamedElement } from '../SENamedElement.js'
import { getUnitOfMeasure } from '../../../../uom/UnitOfMeasureRegistry.js'
const DEFAULT_UNIT_OF_MEASURE = 'Pixels'
export class Symbolizer extends SENamedElement {
  static VALID_CHILDREN = new Array().concat(SENamedElement.VALID_CHILDREN, [
    'BaseSymbolizer',
    'Geometry',
  ])
  _geometry = null
  constructor(e) {
    super(e)
    this._uomString = DEFAULT_UNIT_OF_MEASURE
    this._uom = getUnitOfMeasure(this._uomString)
  }
  get geometry() {
    return this._geometry
  }
  set geometry(e) {
    this._geometry = e
  }
  set uomString(e) {
    this._uomString = e
    this._uom = getUnitOfMeasure(this._uomString)
  }
  get uomString() {
    return this._uomString
  }
  get uom() {
    return this._uom
  }
  getShapeForStyling(e, t) {
    return this._geometry?.getShapeForStyling(e, t) ?? t
  }
}
