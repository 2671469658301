import { ReferenceType } from '../reference/ReferenceType.js'
import { GeodeticReference } from '../reference/GeodeticReference.js'
import { createBounds, createPoint } from '../shape/ShapeFactory.js'
import { createTransformation } from './TransformationFactory.js'
import { Transformation } from './Transformation.js'
import { NotImplementedError } from '../error/NotImplementedError.js'
export class FlattenTransformation extends Transformation {
  constructor(e, t) {
    super(e.inputReference, e.outputReference)
    this._modelZ = t
    this._worldZ = 0
    this._tmpPoint = createPoint(e.inputReference, [0, 0, 0])
    this._tmpBounds = createBounds(e.inputReference)
    this._transformation = e
    const o = e.outputReference
    this._geocentric = o.referenceType === ReferenceType.GEOCENTRIC
    this._geodeticPoint = null
    this._geodeticBounds = null
    this._geodeticToGeocentric = null
    if (this._geocentric) {
      const e = o.geodeticDatum
      const t = new GeodeticReference({ geodeticDatum: e })
      this._geodeticPoint = createPoint(t, [0, 0, 0])
      this._geodeticBounds = createBounds(t)
      this._geodeticToGeocentric = createTransformation(t, o)
    }
  }
  transform(e, t) {
    this._tmpPoint.move3DToCoordinates(e.x, e.y, this._modelZ)
    t = this._transformation.transform(this._tmpPoint, t)
    if (
      this._geocentric &&
      null !== this._geodeticToGeocentric &&
      null !== this._geodeticPoint
    ) {
      this._geodeticToGeocentric.inverseTransformation.transform(
        t,
        this._geodeticPoint
      )
      this._geodeticPoint.move3DToCoordinates(
        this._geodeticPoint.x,
        this._geodeticPoint.y,
        this._worldZ
      )
      this._geodeticToGeocentric.transform(this._geodeticPoint, t)
    } else t.move3DToCoordinates(t.x, t.y, this._worldZ)
    return t
  }
  transformBounds(e, t) {
    this._tmpBounds.setTo3D(e.x, e.width, e.y, e.height, this._modelZ, e.depth)
    t = this._transformation.transformBounds(this._tmpBounds, t)
    if (
      this._geocentric &&
      null !== this._geodeticToGeocentric &&
      null !== this._geodeticBounds
    ) {
      this._geodeticToGeocentric.inverseTransformation.transformBounds(
        t,
        this._geodeticBounds
      )
      this._geodeticBounds.setTo3D(
        this._geodeticBounds.x,
        this._geodeticBounds.width,
        this._geodeticBounds.y,
        this._geodeticBounds.height,
        this._worldZ,
        0
      )
      this._geodeticToGeocentric.transformBounds(this._geodeticBounds, t)
    } else t.setTo3D(t.x, t.width, t.y, t.height, this._worldZ, 0)
    return t
  }
  get inverseTransformation() {
    return this._transformation.inverseTransformation
  }
  _forward(e, t) {
    return this.transform(e, t)
  }
  _forwardBoundsCoords(e, t) {
    this.transformBounds(e, t)
  }
  _inverse(e, t) {
    throw new NotImplementedError()
  }
  _inverseBoundsCoords(e, t) {
    throw new NotImplementedError()
  }
}
