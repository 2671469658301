import { ClipSectionRecorder } from './ClipSectionRecorder.js'
import { clipLineWithRectangle } from '../../../util/Cartesian.js'
const EPSILON = 0.01
const clipOut = {}
export class VirtualClipPolylineUtil {
  constructor() {
    this.recorder = new ClipSectionRecorder()
    this._left = 1 / 0
    this._right = -1 / 0
    this._bottom = 1 / 0
    this._top = -1 / 0
    this._lastX = 0
    this._lastY = 0
    this._requestedMoveTo = true
  }
  run(t, i, e) {
    this._setClippingBounds(e.x, e.width, e.y, e.height)
    this._start(t[0], t[1])
    for (let e = 2; e < i; e += 2) this._addSegment(t[e], t[e + 1])
    return this.recorder.report()
  }
  _setClippingBounds(t, i, e, s) {
    this._left = t
    this._bottom = e
    this._top = this._bottom + s
    this._right = this._left + i
  }
  _clipLineAt(t, i) {
    this.recorder.section(t, i, false)
  }
  _registerSection(t, i) {
    this.recorder.section(t, i, true)
  }
  _start(t, i) {
    this._lastX = t
    this._lastY = i
    this._requestedMoveTo = true
    this.recorder.start(t, i)
  }
  _addSegment(t, i) {
    this.recorder.segment(t, i)
    this._processSegment(this._lastX, this._lastY, t, i)
    this._lastX = t
    this._lastY = i
  }
  _processSegment(t, i, e, s) {
    const r = undefined
    if (
      clipLineWithRectangle(
        t,
        i,
        e,
        s,
        this._left,
        this._bottom,
        this._right,
        this._top,
        clipOut
      )
    ) {
      if (this._requestedMoveTo) {
        this._clipLineAt(clipOut.clippedStartX, clipOut.clippedStartY)
        this._requestedMoveTo = false
      } else if (
        !(
          Math.abs(t - clipOut.clippedStartX) < EPSILON &&
          Math.abs(i - clipOut.clippedStartY) < EPSILON
        )
      )
        this._clipLineAt(clipOut.clippedStartX, clipOut.clippedStartY)
      this._registerSection(clipOut.clippedEndX, clipOut.clippedEndY)
    }
  }
}
