import { Photon } from '../../../gen/photon/photon_painter.js'
import { GeocentricReference } from '../../../reference/GeocentricReference.js'
import { Shape } from '../../../shape/Shape.js'
import { ObjectReleaseTracker } from '../../../util/ObjectReleaseTracker.js'
import { GeoDiscretizerStatic } from '../../shape/discretization/GeoDiscretizerStatic.js'
import { PenThreshold } from '../../shape/generalpath/PenThreshold.js'
import { PhotonCommandUtil } from './command/PhotonCommandUtil.js'
const DEFAULT_PEN_THRESHOLD = new PenThreshold(5e4, 5)
function validateLines(e) {
  if (e) {
    const t = []
    for (let r = 0; r < e.length; r++) if (e[r].length / 3 > 1) t.push(e[r])
    return t
  }
  return null
}
function createGeometryArrays(e, t) {
  if (!e) return { lineMesh: null, fillMesh: null }
  const r = { lineMesh: null, fillMesh: null, fractions: e.fractions }
  const n = validateLines(e.lines)
  if (n && n.length > 0) {
    let e
    if (1 === n.length)
      e = t.track(Photon.BufferFactory.createFloat64BufferFromData(n[0]))
    else {
      let r = 0
      for (let e = 0; e < n.length; e++) r += n[e].length
      e = t.track(Photon.BufferFactory.createFloat64BufferFromLength(r))
      let l = 0
      for (let t = 0; t < n.length; t++)
        for (let r = 0; r < n[t].length; r++) e.typedArray[l++] = n[t][r]
    }
    const l = t.track(
      Photon.BufferFactory.createUint32BufferFromLength(2 * n.length)
    )
    let o = 0
    for (let e = 0; e < n.length; e++) {
      l.typedArray[2 * e] = o
      l.typedArray[2 * e + 1] = n[e].length / 3
      o += n[e].length / 3
    }
    r.lineMesh = t.track(Photon.Tessellator.wrapMesh(e, l))
  }
  if (e.fills && e.canUseFillForStroke) r.fillMesh = r.lineMesh
  else if (e.fills && e.fills.length > 0) {
    let n
    let l = 0
    if (1 === e.fills.length && 1 === e.fills[0].length)
      n = t.track(
        Photon.BufferFactory.createFloat64BufferFromData(e.fills[0][0])
      )
    else {
      for (let t = 0; t < e.fills.length; t++)
        for (let r = 0; r < e.fills[t].length; r++) l += e.fills[t][r].length
      n = t.track(Photon.BufferFactory.createFloat64BufferFromLength(l))
      let r = 0
      for (let t = 0; t < e.fills.length; t++)
        for (let l = 0; l < e.fills[t].length; l++)
          for (let o = 0; o < e.fills[t][l].length; o++)
            n.typedArray[r++] = e.fills[t][l][o]
    }
    l = 0
    for (let t = 0; t < e.fills.length; t++) l += e.fills[t].length
    const o = t.track(Photon.BufferFactory.createUint32BufferFromLength(2 * l))
    let s = 0
    let i = 0
    for (let t = 0; t < e.fills.length; t++)
      for (let r = 0; r < e.fills[t].length; r++) {
        o.typedArray[i] = s
        o.typedArray[i + 1] = e.fills[t][r].length / 3
        s += e.fills[t][r].length / 3
        i += 2
      }
    r.fillMesh = t.track(Photon.Tessellator.wrapMesh(n, o))
  }
  return r
}
export class PhotonShapeDiscretizer {
  constructor(e, t) {
    this._is3d = e
    this._objectReleaseTracker = new ObjectReleaseTracker()
    this._tessellator = this._objectReleaseTracker.track(
      Photon.Tessellator.create()
    )
    this._photonReferenceProvider = t
  }
  discretize(e, t, r, n, l) {
    const o = e instanceof Shape ? e.bounds : null
    if (!o) return null
    const s = new ObjectReleaseTracker()
    const i = this._is3d
    const a = l.zToZero
    const c = PhotonCommandUtil.hasAreaShape(e)
    console.assert(!l.addFractions)
    const h = undefined
    const f = createGeometryArrays(
      GeoDiscretizerStatic.discretize(e, {
        modelReference: e.reference,
        worldReference: t,
        lineType: l.lineType,
        zToZero: l.zToZero,
        penThreshold: l.penThreshold || DEFAULT_PEN_THRESHOLD,
        noDiscretization: true === l.noDiscretization,
        normalizeOrientation: c && l.normalizeOrientation,
      }),
      s
    )
    const d =
      !a &&
      t instanceof GeocentricReference &&
      PhotonCommandUtil.isExtrudedShape(e)
    const u = d ? e.minimumHeight : 0
    const p = d ? e.maximumHeight : 0
    let m
    if (!n && (!d || !i))
      m = f.lineMesh
        ? Photon.Tessellator.wrapLineAndFillMesh(f.lineMesh, f.fillMesh)
        : null
    else {
      const e = s.track(this._photonReferenceProvider.getReference(t))
      const a = s.track(this._photonReferenceProvider.getReference(o.reference))
      const h = s.track(
        Photon.Tessellator.wrapLineAndFillMesh(f.lineMesh, f.fillMesh)
      )
      const g = true === l.canUseEarcut
      m = this._tessellator.tessellateLineAndFill(h, e, o, a, e, {
        needsLine: r,
        needsFill: n,
        is3d: i,
        isAreaShape: c,
        canUseEarcut: g,
        isExtruded: d,
        extrusionMinZ: u,
        extrusionMaxZ: p,
      })
    }
    s.release()
    return m
  }
  static discretizeLine(e, t, r) {
    const n = new ObjectReleaseTracker()
    const l = undefined
    const o = createGeometryArrays(
      GeoDiscretizerStatic.discretize(e, {
        modelReference: e.reference,
        worldReference: t,
        lineType: r.lineType,
        zToZero: r.zToZero,
        penThreshold: r.penThreshold || DEFAULT_PEN_THRESHOLD,
        fraction: r.addFractions,
        noDiscretization: r.noDiscretization,
      }),
      n
    )
    if (!o.lineMesh) return null
    const s = o.lineMesh.copy()
    if (o.fractions)
      s.getFractions = function () {
        return o.fractions
      }
    n.release()
    return s
  }
  release() {
    this._objectReleaseTracker.release()
  }
}
