import { XMLSchema } from '../../util/XMLSchema.js'
import { WMSCapabilitiesSchemaCommon } from './WMSCapabilitiesSchemaCommon.js'
var attribute = XMLSchema.attribute,
  boolean = XMLSchema.boolean,
  number = XMLSchema.number,
  content = XMLSchema.content
var requestSchema = {
  GetCapabilities: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  GetMap: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  GetFeatureInfo: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  DescribeLayer: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  GetLegendGraphic: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  GetStyles: WMSCapabilitiesSchemaCommon.operationTypeSchema,
  PutStyles: WMSCapabilitiesSchemaCommon.operationTypeSchema,
}
var userDefinedSymbolizationSchema = {
  supportSLD: attribute(boolean),
  userLayer: attribute(boolean),
  userStyle: attribute(boolean),
  remoteWFS: attribute(boolean),
}
var layerSchema = Object.assign(
  {},
  WMSCapabilitiesSchemaCommon.layerSchemaCommon,
  {
    SRS: [{ '#text_only': content() }],
    LatLonBoundingBox: {
      minx: attribute(number),
      miny: attribute(number),
      maxx: attribute(number),
      maxy: attribute(number),
    },
    BoundingBox: [
      {
        SRS: attribute(),
        minx: attribute(number),
        miny: attribute(number),
        maxx: attribute(number),
        maxy: attribute(number),
        resx: attribute(number),
        resy: attribute(number),
      },
    ],
    Extent: [
      {
        '#text': content(),
        name: attribute(),
        default: attribute(),
        nearestValue: attribute(boolean),
      },
    ],
    ScaleHint: { min: attribute(number), max: attribute(number) },
  }
)
layerSchema.Layer = [layerSchema]
var WMS_Schema = {
  version: attribute(),
  updateSequence: attribute(),
  Service: WMSCapabilitiesSchemaCommon.serviceSchema,
  Capability: {
    Request: requestSchema,
    Exception: WMSCapabilitiesSchemaCommon.exceptionSchema,
    UserDefinedSymbolization: userDefinedSymbolizationSchema,
    Layer: [layerSchema],
  },
}
export var WMSCapabilitiesSchemaV111 = {
  WMT_MS_Capabilities: WMS_Schema,
  WMS_Capabilities: WMS_Schema,
}
