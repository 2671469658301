import { Photon } from '../../gen/photon/photon_painter.js'
import { Log } from '../../util/Log.js'
import { releaseObject } from '../../util/ObjectReleaseTracker.js'
import { TileSet3DModelSupport } from './TileSet3DModelSupport.js'
import {
  getReference,
  parseWellKnownText,
} from '../../reference/ReferenceProvider.js'
export class HSPCTilesModel {
  constructor(e) {
    this.delegate = new TileSet3DModelSupport(e)
  }
  get credentials() {
    return this.delegate.credentials
  }
  set credentials(e) {
    this.delegate.credentials = e
  }
  get requestHeaders() {
    return this.delegate.requestHeaders
  }
  set requestHeaders(e) {
    this.delegate.requestHeaders = e
  }
  get url() {
    return this.delegate.url
  }
  get urlParams() {
    return this.delegate.urlParams
  }
  get requestParameters() {
    return this.delegate.requestParameters
  }
  set requestParameters(e) {
    this.delegate.requestParameters = e
  }
  get bounds() {
    return this.delegate.bounds
  }
  get orientedBox() {
    return this.delegate.orientedBox
  }
  get coordinateType() {
    return this.delegate.coordinateType
  }
  get reference() {
    return this.delegate.reference
  }
  get modelDescriptor() {
    return this.delegate.modelDescriptor
  }
  static create(e, t) {
    return TileSet3DModelSupport.create(
      e,
      createModelDescriptor,
      (e) => new HSPCTilesModel(e),
      t
    )
  }
}
function getMetadata(e) {
  let t
  try {
    t = Photon.BufferFactory.createUint8BufferFromData(e)
    const r = Photon.ScenePainterWrapper.getMetadataFromTileSet(t)
    return JSON.parse(r)
  } catch (e) {
    Log.error('HSPCTilesModel could not parse metadata information')
    return {}
  } finally {
    releaseObject(t)
  }
}
function createModelDescriptor(e, t, r, o) {
  const s = {
    description: 'A Hexagon Smart Point Cloud model.',
    name: 'HSPC Model',
    source: t,
    type: 'HSPC',
  }
  let a
  const i = getMetadata(e)
  if (i) {
    s.version = i.version
    s.properties = i.properties
    if (i.epsg)
      try {
        a = getReference(i.epsg)
      } catch (e) {
        Log.error('HSPCTilesModel with unknown EPSG code ' + i.epsg)
      }
    if (!a && i.wkt)
      try {
        a = parseWellKnownText(i.wkt)
      } catch (e) {
        Log.error('HSPCTilesModel with unknown WKT ' + i.wkt)
      }
    if (a && 'EPSG:4978' != a.identifier)
      Log.warn(
        'HSPCTilesModel with reference ' +
          (i.epsg || i.wkt) +
          ' is supported, but it is recommended to process your HSPC data into EPSG:4978 (3D geocentric coordinates).'
      )
  }
  return Promise.resolve({ modelReference: a, modelDescriptor: s })
}
export function createHSPCTilesModel(e, t) {
  return HSPCTilesModel.create(e, t)
}
