import { isOfShapeType } from '../../shape/Shape.js'
import { ShapeType } from '../../shape/ShapeType.js'
import { isDefined } from '../../util/Lang.js'
import { Log } from '../../util/Log.js'
import * as DefaultStyles from './DefaultStyles.js'
import { FeaturePainter } from './FeaturePainter.js'
function getTrueShapeType(e) {
  if (ShapeType.contains(e, ShapeType.POINT)) return ShapeType.POINT
  else if (ShapeType.contains(e, ShapeType.POLYLINE)) return ShapeType.POLYLINE
  else if (ShapeType.contains(e, ShapeType.POLYGON)) return ShapeType.POLYGON
  else if (ShapeType.contains(e, ShapeType.BOUNDS)) return ShapeType.BOUNDS
  else if (ShapeType.contains(e, ShapeType.COMPLEX_POLYGON))
    return ShapeType.COMPLEX_POLYGON
  else if (ShapeType.contains(e, ShapeType.SHAPE_LIST))
    return ShapeType.SHAPE_LIST
  else if (ShapeType.contains(e, ShapeType.CIRCLE_BY_3_POINTS))
    return ShapeType.CIRCLE_BY_3_POINTS
  else if (ShapeType.contains(e, ShapeType.CIRCLE_BY_CENTER_POINT))
    return ShapeType.CIRCLE_BY_CENTER_POINT
  else if (ShapeType.contains(e, ShapeType.CIRCLE)) return ShapeType.CIRCLE
  else if (ShapeType.contains(e, ShapeType.ELLIPSE)) return ShapeType.ELLIPSE
  else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC_BY_3_POINTS))
    return ShapeType.CIRCULAR_ARC_BY_3_POINTS
  else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC_BY_BULGE))
    return ShapeType.CIRCULAR_ARC_BY_BULGE
  else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC_BY_CENTER_POINT))
    return ShapeType.CIRCULAR_ARC_BY_CENTER_POINT
  else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC))
    return ShapeType.CIRCULAR_ARC
  else if (ShapeType.contains(e, ShapeType.ARC)) return ShapeType.ARC
  else if (ShapeType.contains(e, ShapeType.ARC_BAND)) return ShapeType.ARC_BAND
  else if (ShapeType.contains(e, ShapeType.SECTOR)) return ShapeType.SECTOR
  else if (ShapeType.contains(e, ShapeType.GEO_BUFFER))
    return ShapeType.GEO_BUFFER
  else if (ShapeType.contains(e, ShapeType.EXTRUDED_SHAPE))
    return ShapeType.EXTRUDED_SHAPE
  else if (ShapeType.contains(e, ShapeType.ORIENTED_BOX))
    return ShapeType.ORIENTED_BOX
  else return null
}
function deriveShapeTypeName(e) {
  if (ShapeType.contains(e, ShapeType.POINT)) return 'Point'
  else if (ShapeType.contains(e, ShapeType.POLYLINE)) return 'Polyline'
  else if (ShapeType.contains(e, ShapeType.POLYGON)) return 'Polygon'
  else if (ShapeType.contains(e, ShapeType.BOUNDS)) return 'Bounds'
  else if (ShapeType.contains(e, ShapeType.COMPLEX_POLYGON))
    return 'ComplexPolygon'
  else if (ShapeType.contains(e, ShapeType.SHAPE_LIST)) return 'ShapeList'
  else if (ShapeType.contains(e, ShapeType.ELLIPSE)) return 'Ellipse'
  else if (ShapeType.contains(e, ShapeType.CIRCLE)) return 'Circle'
  else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC)) return 'CircularArc'
  else if (ShapeType.contains(e, ShapeType.ARC)) return 'EllipticalArc'
  else if (ShapeType.contains(e, ShapeType.ARC_BAND)) return 'ArcBand'
  else if (ShapeType.contains(e, ShapeType.SECTOR)) return 'Sector'
  else if (ShapeType.contains(e, ShapeType.GEO_BUFFER)) return 'GeoBuffer'
  else if (ShapeType.contains(e, ShapeType.EXTRUDED_SHAPE))
    return 'ExtrudedShape'
  else if (ShapeType.contains(e, ShapeType.ORIENTED_BOX)) return 'OrientedBox'
}
function normalizeState(e) {
  if (e.selected && e.hovered) return 'SelectedHovered'
  else if (e.selected) return 'Selected'
  else if (e.hovered) return 'Hovered'
  return 'Default'
}
class BasicFeaturePainter extends FeaturePainter {
  constructor() {
    super()
    this._styles = {}
    this._styles[ShapeType.POINT] = {}
    this._styles[ShapeType.POLYGON] = {}
    this._styles[ShapeType.BOUNDS] = {}
    this._styles[ShapeType.COMPLEX_POLYGON] = {}
    this._styles[ShapeType.POLYLINE] = {}
    this._styles[ShapeType.SHAPE_LIST] = {}
    this._styles[ShapeType.CIRCLE] = {}
    this._styles[ShapeType.ELLIPSE] = {}
    this._styles[ShapeType.CIRCLE_BY_3_POINTS] = null
    this._styles[ShapeType.CIRCLE_BY_CENTER_POINT] = null
    this._styles[ShapeType.CIRCULAR_ARC] = {}
    this._styles[ShapeType.CIRCULAR_ARC_BY_3_POINTS] = null
    this._styles[ShapeType.CIRCULAR_ARC_BY_CENTER_POINT] = null
    this._styles[ShapeType.CIRCULAR_ARC_BY_BULGE] = null
    this._styles[ShapeType.ARC] = {}
    this._styles[ShapeType.ARC_BAND] = {}
    this._styles[ShapeType.SECTOR] = {}
    this._styles[ShapeType.GEO_BUFFER] = {}
    this._styles[ShapeType.EXTRUDED_SHAPE] = {}
    this._styles[ShapeType.ORIENTED_BOX] = {}
  }
  setStyle(e, p, s) {
    const a = getTrueShapeType(e)
    if (!a) {
      Log.warn(`Cannot set style for shape type ${e}`)
      return
    }
    const t = normalizeState(p)
    let S = this._styles[a]
    if (isDefined(S, true)) {
      if (null === S) S = this._styles[a] = {}
      S[t] = s
      this.invalidateAll()
    } else Log.warn(`Cannot set style for shape type ${a}`)
  }
  getStyle(e, p) {
    const s = deriveShapeTypeName(e)
    const a = normalizeState(p)
    let t = null
    let S
    if (ShapeType.contains(e, ShapeType.POINT))
      S = this._styles[ShapeType.POINT]
    else if (ShapeType.contains(e, ShapeType.POLYLINE))
      S = this._styles[ShapeType.POLYLINE]
    else if (ShapeType.contains(e, ShapeType.POLYGON))
      S = this._styles[ShapeType.POLYGON]
    else if (ShapeType.contains(e, ShapeType.BOUNDS))
      S = this._styles[ShapeType.BOUNDS]
    else if (ShapeType.contains(e, ShapeType.COMPLEX_POLYGON))
      S = this._styles[ShapeType.COMPLEX_POLYGON]
    else if (ShapeType.contains(e, ShapeType.SHAPE_LIST))
      S = this._styles[ShapeType.SHAPE_LIST]
    else if (ShapeType.contains(e, ShapeType.ELLIPSE))
      S = this._styles[ShapeType.ELLIPSE]
    else if (ShapeType.contains(e, ShapeType.CIRCLE_BY_3_POINTS))
      S =
        this._styles[ShapeType.CIRCLE_BY_3_POINTS] ||
        this._styles[ShapeType.CIRCLE]
    else if (ShapeType.contains(e, ShapeType.CIRCLE_BY_CENTER_POINT))
      S =
        this._styles[ShapeType.CIRCLE_BY_CENTER_POINT] ||
        this._styles[ShapeType.CIRCLE]
    else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC_BY_3_POINTS))
      S =
        this._styles[ShapeType.CIRCULAR_ARC_BY_3_POINTS] ||
        this._styles[ShapeType.CIRCULAR_ARC]
    else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC_BY_CENTER_POINT))
      S =
        this._styles[ShapeType.CIRCULAR_ARC_BY_CENTER_POINT] ||
        this._styles[ShapeType.CIRCULAR_ARC]
    else if (ShapeType.contains(e, ShapeType.CIRCULAR_ARC_BY_BULGE))
      S =
        this._styles[ShapeType.CIRCULAR_ARC_BY_BULGE] ||
        this._styles[ShapeType.CIRCULAR_ARC]
    else if (ShapeType.contains(e, ShapeType.ARC))
      S = this._styles[ShapeType.ARC]
    else if (ShapeType.contains(e, ShapeType.ARC_BAND))
      S = this._styles[ShapeType.ARC_BAND]
    else if (ShapeType.contains(e, ShapeType.SECTOR))
      S = this._styles[ShapeType.SECTOR]
    else if (ShapeType.contains(e, ShapeType.GEO_BUFFER))
      S = this._styles[ShapeType.GEO_BUFFER]
    else if (ShapeType.contains(e, ShapeType.EXTRUDED_SHAPE))
      S = this._styles[ShapeType.EXTRUDED_SHAPE]
    else if (ShapeType.contains(e, ShapeType.ORIENTED_BOX))
      S = this._styles[ShapeType.ORIENTED_BOX]
    if (S) {
      t = S[a]
      if (!t) {
        const e = undefined
        const p = DefaultStyles[`create${a}${s}Style`]
        if (p) {
          t = p.call(DefaultStyles)
          if (t) S[a] = t
        }
      }
    }
    return t
  }
  paintShape(e, p, s) {
    if (isOfShapeType(p, ShapeType.SHAPE_LIST)) {
      const a = p
      for (let p = 0; p < a.shapeCount; p++)
        this.paintShape(e, a.getShape(p), s)
    } else {
      const a = this.getStyle(p.type, s)
      if (a)
        if (isOfShapeType(p, ShapeType.POINT)) e.drawIcon(p, a)
        else e.drawShape(p, a)
    }
  }
  paintBody(e, p, s, a, t, S) {
    if (s) this.paintShape(e, s, S)
  }
}
export { BasicFeaturePainter }
