import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { Ellipse } from './Ellipse.js'
import { LLHBounds } from './LLHBounds.js'
import * as LLHEllipseUtil from './LLHEllipseUtil.js'
import { normalizeAngle } from '../util/Cartesian.js'
export class LLHEllipse extends Ellipse {
  constructor(e, t, i, r, o) {
    super()
    this._reference = this.validateReference(e)
    this._compareReferenceStrict(
      t.reference,
      'LLHEllipse::the center point reference must be equal to the ellipse reference'
    )
    this._center = t.copy()
    if ('number' !== typeof i)
      throw new ProgrammingError('LLHEllipse::cannot create a without a')
    this._semiMajorAxis = i
    if ('number' !== typeof r)
      throw new ProgrammingError('LLHEllipse::cannot create a without b')
    this._semiMinorAxis = r
    if ('number' !== typeof o)
      throw new ProgrammingError(
        'LLHEllipse::cannot create a without rotationAzimuth'
      )
    this._rotationAngle = normalizeAngle(o)
  }
  get isGeodetic() {
    return true
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  get center() {
    return this._center
  }
  get a() {
    return this._semiMajorAxis
  }
  set a(e) {
    if (e < 0)
      throw new ProgrammingError(
        `a length should be positive number, but was: ${e}`
      )
    this._semiMajorAxis = e
    this._cache.invalidate()
  }
  get b() {
    return this._semiMinorAxis
  }
  set b(e) {
    if (e < 0)
      throw new ProgrammingError(
        `b length should be positive number, but was: ${e}`
      )
    this._semiMinorAxis = e
    this._cache.invalidate()
  }
  get rotationAzimuth() {
    return this._rotationAngle
  }
  set rotationAzimuth(e) {
    this._rotationAngle = normalizeAngle(e)
    this._cache.invalidate()
  }
  copy() {
    return new LLHEllipse(
      this.reference,
      this.center,
      this.a,
      this.b,
      this.rotationAzimuth
    )
  }
  get focusPoint() {
    return this.center
  }
  get bounds() {
    return this._cache.memoize('cached-bounding-box', () => {
      const e = new LLHBounds(this.reference, [0, 0, 0, 0, 0, 0])
      LLHEllipseUtil.calculateBounds(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.a,
        this.b,
        90 - this.rotationAzimuth,
        e
      )
      return e
    })
  }
  contains2DCoordinates(e, t) {
    return (
      this.bounds.contains2DCoordinates(e, t) &&
      LLHEllipseUtil.contains2D(
        this.reference.geodeticDatum.ellipsoid,
        this.center,
        this.a,
        this.b,
        90 - this.rotationAzimuth,
        e,
        t
      )
    )
  }
  interpolate(e, t) {
    return LLHEllipseUtil.interpolate(this, e, t)
  }
  updatePoint(e, t, i) {
    LLHEllipseUtil.updatePoint(this, e, t, i)
  }
  contains3DCoordinates(e, t, i) {
    throw new ProgrammingError(
      'contains3DCoordinates should not be called on the 2D shape LLHEllipse'
    )
  }
}
