import { ProgrammingError } from '../../../../error/ProgrammingError.js'
import { createPhotonColorFromString } from '../../../../util/Color.js'
import { XYZPoint } from '../../../../shape/XYZPoint.js'
import { Hash } from '../../../../util/Hash.js'
import { ObjectReleaseTracker } from '../../../../util/ObjectReleaseTracker.js'
import { GeoDiscretizerStatic } from '../../../shape/discretization/GeoDiscretizerStatic.js'
import { PhotonCommandUtil } from './PhotonCommandUtil.js'
import { isPromise, whenInternal } from '../../../../util/PromiseUtil.js'
import { toPhotonPBRSettings } from '../../../style/util/PBRUtil.js'
import { PBRSettingsImpl } from '../../../style/PBRSettings.js'
import { BloomStyleImpl, DEFAULT_BLOOM } from '../../../style/BloomStyle.js'
import {
  isBoolean,
  isDefined,
  isNumber,
  isObject,
  isUndefined,
} from '../../../../util/Lang.js'
import { DrapeTarget } from '../../../style/DrapeTarget.js'
import { getZStyleForPoint } from './ZStyleUtil.js'
function Photon3DIconCommand(t, e, o, r) {
  this._is3d = t
  this._worldReference = e
  this._shapePainter = o
  this._hash = new Hash()
  this._meshProvider = r
  this._coordinatesPool = [new XYZPoint(null)]
}
Photon3DIconCommand.prototype = Object.create(ObjectReleaseTracker.prototype)
Photon3DIconCommand.prototype.constructor = Photon3DIconCommand
Photon3DIconCommand.prototype.canHandle = function (t) {
  return 'icon3d' === t.type
}
Photon3DIconCommand.prototype.add = function (t) {
  var e = t.shape.focusPoint
  var o = t.style
  var r = t.zStyle
  var n = GeoDiscretizerStatic.discretizePoint(e, {
    modelReference: e.reference,
    worldReference: this._worldReference,
    coordinatesPool: this._coordinatesPool,
    zToZero: false,
  })
  if (null === n) return
  this._shapePainter.addMeshLocation(
    t.objectId,
    t.geometryId,
    t.styleId,
    o.meshIcon,
    n,
    o.pitch,
    o.heading,
    o.roll,
    createPhotonColorFromString(o.color),
    o.scale,
    o.rotation,
    o.translation,
    o.pbrSettings,
    o.zOrder,
    t.renderPassId,
    t.selected,
    r.drapeTarget,
    r.aboveGround,
    o.transparency,
    o.bloom
  )
}
Photon3DIconCommand.prototype.updateStyle = function (t, e) {
  var o = t.style
  var r = t.zStyle
  this._shapePainter.updateMeshStyle(
    t.objectId,
    t.geometryId,
    e,
    t.styleId,
    o.meshIcon,
    createPhotonColorFromString(o.color),
    o.scale,
    o.rotation,
    o.translation,
    o.pbrSettings,
    o.zOrder,
    t.renderPassId,
    t.selected,
    r.drapeTarget,
    r.aboveGround,
    o.transparency,
    o.bloom
  )
}
Photon3DIconCommand.prototype.updateGeometry = function (t, e) {
  var o = t.shape.focusPoint
  var r = t.style
  var n = GeoDiscretizerStatic.discretizePoint(o, {
    modelReference: o.reference,
    worldReference: this._worldReference,
    coordinatesPool: this._coordinatesPool,
    zToZero: false,
  })
  if (null === n) {
    this._shapePainter.removeGeometry(t.objectId, e, t.styleId)
    return
  }
  this._shapePainter.updateMeshGeometry(
    t.objectId,
    e,
    t.geometryId,
    n,
    r.orientation?.pitch || 0,
    r.orientation?.heading || 0,
    r.orientation?.roll || 0
  )
  return true
}
Photon3DIconCommand.prototype.resolveStyle = function (t) {
  t.style = this._resolveAndNormalizeStyle(t.style)
}
function Normalized3DIconStyle(
  t,
  e,
  o,
  r,
  n,
  a,
  i,
  s,
  h,
  l,
  p,
  c,
  d,
  m,
  y,
  u,
  g,
  P,
  f
) {
  this.meshIcon = t
  this.meshHashCode = e
  this.meshTexture = null
  this.pitch = o
  this.heading = r
  this.roll = n
  this.color = a
  this.scale = new XYZPoint(null, [i, s, h])
  this.rotation = new XYZPoint(null, [l, p, c])
  this.translation = new XYZPoint(null, [d, m, y])
  this.pbrSettings = u
  this.zOrder = g
  this.drapeTarget = DrapeTarget.NOT_DRAPED
  this.transparency = P
  this.bloom = f
}
Normalized3DIconStyle.prototype = Object.create(Object.prototype)
Normalized3DIconStyle.prototype.constructor = Normalized3DIconStyle
Normalized3DIconStyle.prototype.release = function () {
  if (this.meshIcon) this.meshIcon.release()
  if (this.meshTexture) this.meshTexture.release()
}
Photon3DIconCommand.prototype._resolveAndNormalizeStyle = function (t) {
  var e = this._normalizeStyle(t)
  var o = e.meshUrl ? e.meshUrl : e.mesh
  if (!o)
    throw new ProgrammingError(
      'An Icon3DStyle was given to the geocanvas without a mesh or meshUrl parameter. At least ' +
        'one of the two should be non-null'
    )
  var r = this._meshProvider.getPhotonMesh(o)
  this._hash.reset()
  this._meshProvider.meshHashCode(o, this._hash)
  var n = this._hash.getHashCode()
  if (isPromise(r))
    return whenInternal(r).then(
      function (t) {
        var o
        return new Normalized3DIconStyle(
          t,
          n,
          e.pitch,
          e.heading,
          e.roll,
          e.color,
          e.scaleX,
          e.scaleY,
          e.scaleZ,
          e.rotationX,
          e.rotationY,
          e.rotationZ,
          e.translationX,
          e.translationY,
          e.translationZ,
          e.pbrSettings,
          e.zOrder,
          e.transparency,
          e.bloom
        )
      },
      function (t) {
        throw t
      }
    )
  else {
    var a
    return new Normalized3DIconStyle(
      r,
      n,
      e.pitch,
      e.heading,
      e.roll,
      e.color,
      e.scaleX,
      e.scaleY,
      e.scaleZ,
      e.rotationX,
      e.rotationY,
      e.rotationZ,
      e.translationX,
      e.translationY,
      e.translationZ,
      e.pbrSettings,
      e.zOrder,
      e.transparency,
      e.bloom
    )
  }
}
Photon3DIconCommand.prototype._normalizeStyle = function (t) {
  var e = t.scale && t.scale.x ? t.scale.x : 1 || 1
  var o = t.scale && t.scale.y ? t.scale.y : 1 || 1
  var r = t.scale && t.scale.z ? t.scale.z : 1 || 1
  if (e <= 0) e = 1
  if (o <= 0) o = 1
  if (r <= 0) r = 1
  return {
    mesh: t.mesh,
    meshUrl: t.meshUrl,
    pitch: t.orientation && t.orientation.pitch ? t.orientation.pitch : 0,
    heading: t.orientation && t.orientation.heading ? t.orientation.heading : 0,
    roll: t.orientation && t.orientation.roll ? t.orientation.roll : 0,
    color: t.color || 'rgba(255, 255, 255, 1)',
    scaleX: e,
    scaleY: o,
    scaleZ: r,
    rotationX: t.rotation && t.rotation.x ? t.rotation.x : 0 || 0,
    rotationY: t.rotation && t.rotation.y ? t.rotation.y : 0 || 0,
    rotationZ: t.rotation && t.rotation.z ? t.rotation.z : 0 || 0,
    translationX: t.translation && t.translation.x ? t.translation.x : 0 || 0,
    translationY: t.translation && t.translation.y ? t.translation.y : 0 || 0,
    translationZ: t.translation && t.translation.z ? t.translation.z : 0 || 0,
    pbrSettings: toPhotonPBRSettings(
      t.pbrSettings ? new PBRSettingsImpl(t.pbrSettings) : null
    ),
    zOrder: t.zOrder || 0,
    transparency: t.transparency,
    bloom:
      t.bloom && isObject(t.bloom)
        ? new BloomStyleImpl(t.bloom)
        : DEFAULT_BLOOM,
  }
}
Photon3DIconCommand.prototype.getDrawItems = function (t) {
  var e = []
  for (var o = 0; o < t.length; o++) {
    var r = t[o].shape
    var n = t[o].style
    var a = t[o].renderPassId
    var i = t[o].selected
    let l = DrapeTarget.NOT_DRAPED
    if (isDefined(n.drapeTarget)) l = n.drapeTarget
    else if (isDefined(n.draped))
      l = n.draped ? DrapeTarget.TERRAIN : DrapeTarget.NOT_DRAPED
    var s = getZStyleForPoint(r, void 0, l, this._is3d)
    var h = {
      shape: r,
      style: n,
      geometryId: this._getGeometryId(r.focusPoint, n),
      styleId: this._getStyleId(n, s, i, a, r),
      zStyle: s,
    }
    e.push(h)
  }
  return e
}
Photon3DIconCommand.prototype._getGeometryId = function (t, e) {
  this._hash.reset()
  t.hashCode(this._hash)
  if (t.reference) t.reference.hashCode(this._hash)
  this._hash.appendDouble(e.orientation?.pitch)
  this._hash.appendDouble(e.orientation?.heading)
  this._hash.appendDouble(e.orientation?.roll)
  return this._hash.getHashCode()
}
Photon3DIconCommand.prototype._getStyleId = function (t, e, o, r, n) {
  this._hash.reset()
  this._hash.appendUInt32(e.drapeTarget)
  this._hash.appendBoolean(e.aboveGround)
  this._hash.appendBoolean(t.transparency)
  this._hash.appendUInt32(r)
  this._hash.appendBoolean(o)
  this._hash.appendUInt32(t.zOrder)
  this._hash.appendString(t.color)
  if (t.bloom)
    this._hash.appendDouble(
      isNumber(t.bloom.intensity, false)
        ? t.bloom.intensity
        : new BloomStyleImpl({}).intensity
    )
  this._hash.appendDouble(t.rotation?.x)
  this._hash.appendDouble(t.rotation?.y)
  this._hash.appendDouble(t.rotation?.z)
  this._hash.appendDouble(t.scale?.x)
  this._hash.appendDouble(t.scale?.y)
  this._hash.appendDouble(t.scale?.z)
  this._hash.appendDouble(t.translation?.x)
  this._hash.appendDouble(t.translation?.y)
  this._hash.appendDouble(t.translation?.z)
  const a = toPhotonPBRSettings(
    t.pbrSettings ? new PBRSettingsImpl(t.pbrSettings) : null
  )
  this._hash.appendDouble(a.directionalLighting)
  this._hash.appendDouble(a.lightIntensity)
  this._hash.appendDouble(a.ibl)
  this._hash.appendDouble(a.metallicFactor)
  this._hash.appendDouble(a.roughnessFactor)
  this._hash.appendString(t.meshUrl || t.mesh?.id || '')
  return this._hash.getHashCode()
}
Photon3DIconCommand.prototype.usesPhotonShapePainter = function () {
  return true
}
export { Photon3DIconCommand }
