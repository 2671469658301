import { closestPointOnGeodesic } from '../geodesy/EllipsoidUtil.js'
import { Constants } from '../util/Constants.js'
import { buildCache } from '../util/Cacher.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { ProgrammingError } from '../error/ProgrammingError.js'
import { LLHGeoBufferHelper } from '../geometry/constructivegeometry/geobuffer/LLHGeoBufferHelper.js'
import { EndCapStyle } from './EndCapStyle.js'
import { GeoBuffer } from './GeoBuffer.js'
import { LLHPoint } from './LLHPoint.js'
import { ShapeType } from './ShapeType.js'
const REL_TOLERANCE = 1e-12
const ABS_TOLERANCE = 0.01
class LLHGeoBuffer extends GeoBuffer {
  constructor(e, t, r) {
    super()
    this._reference = this.validateReference(e)
    if (!GeoBuffer.isSupportedBaseShape(t))
      throw new ProgrammingError(
        'Invalid base shape: It must be a polyline or a polygon'
      )
    this._baseShape = t
    if ('number' !== typeof r)
      throw new ProgrammingError(
        'LLHGeoBuffer::cannot create a geoBuffer without a proper width'
      )
    if (r >= 0) this._width = r
    else
      throw new ProgrammingError(
        'Width should be positive number, but was: ' + r
      )
    this._endCapStyle = EndCapStyle.CAP_ROUND
    this._cache = buildCache()
    this._bufferHelper = new LLHGeoBufferHelper(
      this._reference,
      this._reference.geodeticDatum.ellipsoid
    )
  }
  get isGeodetic() {
    return true
  }
  set isGeodetic(e) {
    throw new ProgrammingError('isGeodetic property is not mutable')
  }
  get bounds() {
    const e = this
    return this._cache.memoize('buffer-bounds', function () {
      if (e._contour.bounds) return e._contour.bounds.copy()
      return null
    })
  }
  set bounds(e) {
    throw new ProgrammingError('bounds property is not mutable')
  }
  get coordinateType() {
    return CoordinateType.GEODETIC
  }
  set coordinateType(e) {
    throw new ProgrammingError('coordinateType property is not mutable')
  }
  copy() {
    var e = new LLHGeoBuffer(
      this._reference,
      this._baseShape.copy(),
      this._width
    )
    e.endCapStyle = this._endCapStyle
    return e
  }
  contains2DPoint(e) {
    const t = this._reference.geodeticDatum.ellipsoid
    let r, i, o, n, s, a
    if (!this.bounds.contains2DPoint(e)) return false
    if (this._baseShape.contains2DPoint(e)) return true
    if (ShapeType.contains(this._baseShape.type, ShapeType.POLYGON))
      return this._nearPolygonBounds(this._baseShape, e)
    const f = this._baseShape.getPoint(0)
    const h = this._baseShape.getPoint(1)
    const u = this._baseShape.pointCount
    const p = new LLHPoint(this._reference)
    if (0 == u) return false
    if (1 == u) return this._baseShape.getPoint(0).contains2DPoint(e)
    if (2 == u) {
      i = closestPointOnGeodesic(f, h, e, t, REL_TOLERANCE, ABS_TOLERANCE, p)
      if (i <= this._width) {
        if (this._endCapStyle === EndCapStyle.CAP_ROUND) return true
        if (t.geodesicDistance(f, p) <= ABS_TOLERANCE) {
          o = t.forwardAzimuth2D(f, h) * Constants.RAD2DEG
          n = t.forwardAzimuth2D(p, e) * Constants.RAD2DEG
          a = o - n
          if (a < 0) a += 360
          if (a > 90 && a < 270) return false
        }
        if (t.geodesicDistance(h, p) <= ABS_TOLERANCE) {
          s = t.forwardAzimuth2D(h, f) * Constants.RAD2DEG
          n = t.forwardAzimuth2D(p, e) * Constants.RAD2DEG
          a = s - n
          if (a < 0) a += 360
          if (a > 90 && a < 270) return false
        }
        return true
      }
      return false
    }
    if (u > 2)
      for (r = 1; r < u - 2; r++) {
        i = closestPointOnGeodesic(
          this._baseShape.getPoint(r),
          this._baseShape.getPoint(r + 1),
          e,
          t,
          REL_TOLERANCE,
          ABS_TOLERANCE,
          p
        )
        if (i <= this._width) return true
      }
    i = closestPointOnGeodesic(f, h, e, t, REL_TOLERANCE, ABS_TOLERANCE, p)
    if (i <= this._width)
      if (this._endCapStyle === EndCapStyle.CAP_ROUND) return true
      else {
        if (t.geodesicDistance(f, p) <= ABS_TOLERANCE) {
          o = t.forwardAzimuth2D(f, h) * Constants.RAD2DEG
          n = t.forwardAzimuth2D(p, e) * Constants.RAD2DEG
          a = o - n
          if (a < 0) a += 360
          if (a > 90 && a < 270) return false
        }
        return true
      }
    i = closestPointOnGeodesic(
      this._baseShape.getPoint(u - 2),
      this._baseShape.getPoint(u - 1),
      e,
      t,
      REL_TOLERANCE,
      ABS_TOLERANCE,
      p
    )
    if (i <= this._width) {
      if (this._endCapStyle === EndCapStyle.CAP_ROUND) return true
      else if (
        t.geodesicDistance(this._baseShape.getPoint(u - 1), p) <= ABS_TOLERANCE
      ) {
        s =
          t.forwardAzimuth2D(
            this._baseShape.getPoint(u - 1),
            this._baseShape.getPoint(u - 2)
          ) * Constants.RAD2DEG
        n = t.forwardAzimuth2D(p, e) * Constants.RAD2DEG
        a = s - n
        if (a < 0) a += 360
        if (a > 90 && a < 270) return false
      }
      return true
    }
    return false
  }
  contains2DCoordinates(e, t) {
    return this.contains2DPoint(new LLHPoint(this._reference, [e, t]))
  }
  _nearPolygonBounds(e, t) {
    const r = this._reference.geodeticDatum.ellipsoid
    const i = e.pointCount
    let o, n
    if (0 === i) return false
    if (1 === i) return r.geodesicDistance(e.getPoint(0), t) <= this._width
    const s = new LLHPoint(this._reference)
    for (o = 0; o < i - 1; ++o) {
      n = closestPointOnGeodesic(
        e.getPoint(o),
        e.getPoint(o + 1),
        t,
        r,
        REL_TOLERANCE,
        ABS_TOLERANCE,
        s
      )
      if (n <= this._width) return true
    }
    if (i > 2) {
      n = closestPointOnGeodesic(
        e.getPoint(i - 1),
        e.getPoint(0),
        t,
        r,
        REL_TOLERANCE,
        ABS_TOLERANCE,
        s
      )
      if (n <= this._width) return true
    }
    return false
  }
}
export { LLHGeoBuffer }
