import { Constants } from '../util/Constants.js'
import { XYZPoint } from '../shape/XYZPoint.js'
import {
  distance2D,
  forwardAzimuth2D,
  intersection2DLSSFCT,
  squaredDistance2D,
} from '../util/Cartesian.js'
const tempPoint1 = new XYZPoint()
const tempPoint2 = new XYZPoint()
function calculateBounds(t, o, n) {
  n.move2DToCoordinates(t.x - o, t.y - o)
  n.width = 2 * o
  n.height = 2 * o
}
function computePoint(t, o, n, i) {
  i.move2DToCoordinates(t.x + Math.cos(n) * o, t.y + Math.sin(n) * o)
}
export function contains2D(t, o, n, i) {
  return squaredDistance2D(t.x, t.y, n, i) <= o * o
}
export function getBounds(t, o) {
  calculateBounds(t.center, t.radius, o)
}
export function getFocusPoint(t) {
  return t.center
}
export function findCenter(t, o, n, i) {
  const e = t.x === o.x && t.y === o.y
  const s = t.x === n.x && t.y === n.y
  const c = o.x === n.x && o.y === n.y
  if (e && s && c) i.move2DToPoint(t)
  else if (s || e || c)
    if (e) i.move2DToCoordinates((t.x + n.x) / 2, (t.y + n.y) / 2)
    else i.move2DToCoordinates((t.x + o.x) / 2, (t.y + o.y) / 2)
  else {
    const e = (t.x + o.x) / 2
    const s = (t.y + o.y) / 2
    const c = (o.x + n.x) / 2
    const r = (o.y + n.y) / 2
    const a = o.x - t.x
    const m = o.y - t.y
    const D = n.x - o.x
    const P = n.y - o.y
    intersection2DLSSFCT(e, s, e + m, s - a, c, r, c + P, r - D, i)
  }
  return distance2D(t, i)
}
export function translateCircleBy3Points2D(t, o, n) {
  const i = t.focusPoint
  const e = 90 - forwardAzimuth2D(i, t.firstPoint) * Constants.RAD2DEG
  const s = 90 - forwardAzimuth2D(i, t.secondPoint) * Constants.RAD2DEG
  const c = 90 - forwardAzimuth2D(i, t.thirdPoint) * Constants.RAD2DEG
  const r = distance2D(t.firstPoint, i)
  const a = distance2D(t.secondPoint, i)
  const m = distance2D(t.thirdPoint, i)
  tempPoint2.x = i.x
  tempPoint2.y = i.y
  tempPoint2.translate2D(o, n)
  computePoint(tempPoint2, r, e * Constants.DEG2RAD, tempPoint1)
  t.moveFirstPoint2DToCoordinates(tempPoint1.x, tempPoint1.y)
  computePoint(tempPoint2, a, s * Constants.DEG2RAD, tempPoint1)
  t.moveSecondPoint2DToCoordinates(tempPoint1.x, tempPoint1.y)
  computePoint(tempPoint2, m, c * Constants.DEG2RAD, tempPoint1)
  t.moveThirdPoint2DToCoordinates(tempPoint1.x, tempPoint1.y)
}
