import { clamp } from './Cartesian.js'
export function normalizeLon(n) {
  return n > 180
    ? ((n + 180) % 360) - 180
    : n < -180
    ? ((n - 180) % 360) + 180
    : n
}
export function normalizeLat(n) {
  return clamp(n, -90, 90)
}
export function subtractLon(n, t) {
  if (n > 0 && t < 0 && n - t > 180) t += 360
  else if (t > 0 && n < 0 && t - n > 180) n += 360
  return t - n
}
export function pointLLH_equalLonLat(n, t, r, o) {
  return (
    (t === o || 90 === t || -90 === t) &&
    (n === r || n === r + 360 || n === r - 360)
  )
}
