import {
  AbstractViewPaintingStrategy,
  ReadyResult,
} from './AbstractViewPaintingStrategy.js'
import { WorldViewTransformation2D } from '../../transformation/WorldViewTransformation2D.js'
import { FeatureLayerRenderer } from '../feature/FeatureLayerRenderer.js'
import { createLabelContext } from '../feature/LabelDrawingContext.js'
import { GoogleDrawingContext } from '../google/GoogleDrawingContext.js'
import { LayerType } from '../LayerType.js'
import { RasterTileSetLayerRenderer } from '../tileset/RasterTileSetLayerRenderer.js'
import { FrameScheduler } from './FrameScheduler.js'
import { AddSubTreeVisitor } from './AddSubTreeVisitor.js'
import { CompositeDrawingContext } from './CompositeDrawingContext.js'
import { DrawingContext } from './DrawingContext.js'
import { RemoveSubTreeVisitor } from './RemoveSubTreeVisitor.js'
import { RetrieveByLayerTypeVisitor } from './RetrieveByLayerTypeVisitor.js'
import { SubTreeInvalidatorVisitor } from './SubTreeInvalidatorVisitor.js'
import { HTML5GeoCanvas } from '../style/HTML5GeoCanvas.js'
import { HTML5ControllerLabelCanvas } from '../style/HTML5ControllerLabelCanvas.js'
function adjustLayerVisibility(e, t) {
  if (e.type !== LayerType.BASE) return
  if (!e.visible) return
  const r = t.layers
  const i = r.length - 1
  if (e === r[i]) for (let e = 0; e < i; e += 1) r[e].visible = false
  else {
    const t = undefined
    if (r.some((t) => e !== t && t.visible)) e.visible = false
  }
}
export class DefaultViewPaintingStrategy extends AbstractViewPaintingStrategy {
  _controllerDrawCommand = null
  _controllerLabelDrawCommand = null
  constructor(e) {
    super(e)
    this.techContext = {
      type: 'Canvas',
      featureLayerRenderer: FeatureLayerRenderer,
      featureLayerBorderRenderer: FeatureLayerRenderer,
      rasterTileSetLayerRenderer: RasterTileSetLayerRenderer,
    }
    this._worldSizeSupport = this.map.worldSizeSupport
    this._bodyContexts = []
    this._layerListeners = []
    this.createLayerTreeVisitors()
    this.createBodiesContextWrapper(0)
    this.createSelectedBodyContext(1)
    this.createControllerContext(2)
    this.createLabelHtmlDrawingSurface(3)
    this.createAxisRendererContext(5)
    this.createBottomAxisLabelHtmlDrawingSurface(6)
    this.createLeftAxisLabelHtmlDrawingSurface(7)
    this.createRequestFrameCallback()
    const { offsetWidth: t, offsetHeight: r } = this._bodyNode.parentNode
    this.resize(t, r, this.map.border)
    this._initialized = Promise.resolve()
  }
  createLayerTreeVisitors() {
    this._addSubTreeVisitor = new AddSubTreeVisitor(
      this.listenToLayerEvents.bind(this),
      this.addLayerNode.bind(this)
    )
    this._removeSubTreeVisitor = new RemoveSubTreeVisitor(
      this.stopListeningToLayerEvents.bind(this),
      this.removeLayerNode.bind(this)
    )
    this._retrieveByLayerTypeVisitor = new RetrieveByLayerTypeVisitor()
    this._subTreeInvalidatorVisitor = new SubTreeInvalidatorVisitor()
  }
  registerLayerTree(e) {
    e.on('NodeAdded', (e) => this.addLayerTreeNode(e.node))
    e.on('NodeRemoved', (e) => this.removeLayerTreeNode(e.node))
    e.on('NodeMoved', (e) => {
      this.removeLayerTreeNode(e.node)
      this.addLayerTreeNode(e.node)
    })
  }
  createRequestFrameCallback() {
    const e = this
    this._requestFrame = FrameScheduler.createRequestFrame({
      paint: function () {
        e.paint()
      },
    })
  }
  fitBodyWrapper(e, t) {
    this._bodyNode.style.width = `${e}px`
    this._bodyNode.style.height = `${t}px`
  }
  createBodiesContextWrapper(e) {
    this._bodyNode = document.createElement('div')
    this._bodyNode.style.position = 'absolute'
    this._bodyNode.style.zIndex = `${e}`
    this.map._getContainerNode().appendChild(this._bodyNode)
  }
  createSelectedBodyContext(e) {
    this._selectedBodyContext = new CompositeDrawingContext(
      null,
      this.map._getContainerNode(),
      this.map.viewSize[0],
      this.map.viewSize[1],
      this.map.border,
      this._bodyContexts
    )
    this._selectedBodyContext.surface.setZIndex(e)
  }
  createControllerContext(e) {
    this._controllerManager = this.map.controllerManager || {
      onDraw: () => {},
      on: () => {},
    }
    this._controllerContext = new DrawingContext(
      null,
      this.map._getContainerNode(),
      this.map.viewSize[0],
      this.map.viewSize[1],
      this.map.border
    )
    this._controllerContext.surface.setZIndex(e)
    this._controllerDrawCommand = null
    this._controllerGeoCanvas = new HTML5GeoCanvas({
      invalidateAction: () => {
        this._controllerContext.valid = false
        this.repaint()
      },
      worldReference: this.map.reference,
      worldSizeSupport: this._worldSizeSupport,
    })
    this._controllerLabelCanvas = new HTML5ControllerLabelCanvas({
      invalidateAction: this.invalidate.bind(this),
    })
    this._controllerLabelDrawCommand = null
    this._controllerManagerInvalidateListener = this._controllerManager.on(
      'Invalidated',
      () => {
        this._controllerDrawCommand = null
        this._controllerLabelDrawCommand = null
        this.htmlLabelSurface.valid = false
        this._controllerContext.valid = false
        this.repaint()
      }
    )
  }
  resize(e, t, r) {
    const i = t - r.bottom
    this.fitBodyWrapper(e, t)
    for (let r = 0, i = this._bodyContexts.length; r < i; r++)
      this._bodyContexts[r].resizeIgnoreBorder(e, t)
    this._selectedBodyContext.resizeIgnoreBorder(e, t)
    this.htmlLabelSurface.resize(e, i, r.left)
    if (this._controllerContext)
      this._controllerContext.resizeIgnoreBorder(e, t)
    if (this.borderContext) {
      this.borderContext.resizeIgnoreBorder(e, t)
      this.bottomBorderLabelHtmlSurface?.resizeAndPositionOnX(e, t, r.bottom, 0)
      this.leftBorderLabelHtmlSurface?.resizeAndPositionOnY(e, t, r)
    }
    if (this.map && this.map.mapToViewTransformation) this.paint()
  }
  findDrawingContext(e) {
    for (let t = 0, r = this._bodyContexts.length; t < r; t++)
      if (-1 !== this._bodyContexts[t].layers.indexOf(e)) return t
    return -1
  }
  addLayerTreeNode(e) {
    e.accept(this._addSubTreeVisitor)
    this.invalidate()
  }
  addLayerNode(e) {
    const t = e.getPreviousLeafNode()
    let r, i
    const o = this.findDrawingContext(t)
    const s = -1 !== o ? this._bodyContexts[o] : null
    if (s)
      if (s.type === e.type && e.type !== LayerType.DYNAMIC) {
        s.layers.splice(s.layers.indexOf(t) + 1, 0, e)
        i = s
      } else {
        if (s.layers.indexOf(t) !== s.layers.length - 1) {
          r = this.createContextAndInsertAtPosition(o + 1, s.type, e)
          const i = s.layers.indexOf(t)
          r.layers = s.layers.slice(i + 1, s.layers.length)
          s.layers = s.layers.slice(0, i + 1)
        }
        r = this.createContextAndInsertAtPosition(o + 1, e.type, e)
        r.layers.push(e)
        i = r
      }
    else {
      r = this.createContextAndInsertAtPosition(0, e.type, e)
      r.layers.push(e)
      i = r
    }
    this.mergeDrawingContexts()
    this.updateZIndices()
    adjustLayerVisibility(e, i)
  }
  getBaseLayers() {
    this._retrieveByLayerTypeVisitor.reset(LayerType.BASE)
    this.map.layerTree.accept(this._retrieveByLayerTypeVisitor)
    return this._retrieveByLayerTypeVisitor.layers
  }
  listenToLayerEvents(e) {
    const t = this
    const r = e.on('VisibilityChanged', () => {
      if (e.visible && e.type === LayerType.BASE) {
        const r = t.getBaseLayers()
        for (let t = 0; t < r.length; t += 1) {
          const i = r[t]
          if (i !== e) i.visible = false
        }
      }
    })
    this._layerListeners.push({ layer: e, visibilityChangedHandle: r })
  }
  stopListeningToLayerEvents(e) {
    for (let t = 0; t < this._layerListeners.length; t += 1)
      if (this._layerListeners[t].layer === e) {
        this._layerListeners[t].visibilityChangedHandle.remove()
        this._layerListeners.splice(t, 1)
        break
      }
  }
  updateZIndices() {
    for (let e = 0; e < this._bodyContexts.length; e++)
      this._bodyContexts[e].setZIndex(e)
  }
  createContextAndInsertAtPosition(e, t, r) {
    const i = undefined
    const o = new (r.isGoogle ? GoogleDrawingContext : DrawingContext)(
      t,
      this._bodyNode,
      this.map.viewSize[0],
      this.map.viewSize[1],
      this.map.border
    )
    this._bodyContexts.splice(e, 0, o)
    return o
  }
  destroyContext(e) {
    const t = this._bodyContexts[e]
    this._bodyContexts.splice(e, 1)
    t.destroy()
  }
  mergeDrawingContexts() {
    const e = this._bodyContexts
    if (e.length < 2) return
    for (let t = e.length - 1; t > 0; t--) {
      const r = e[t]
      const i = e[t - 1]
      if (r.type !== LayerType.DYNAMIC && r.type === i.type) {
        Array.prototype.push.apply(i.layers, r.layers)
        this.destroyContext(t)
      }
    }
  }
  removeLayerTreeNode(e) {
    e.accept(this._removeSubTreeVisitor)
    this.invalidate()
  }
  removeLayerNode(e) {
    const t = this.findDrawingContext(e)
    if (-1 !== t) {
      const r = this._bodyContexts[t]
      r.layers.splice(r.layers.indexOf(e), 1)
      if (0 === r.layers.length) {
        this.destroyContext(t)
        this.mergeDrawingContexts()
      }
    }
  }
  invalidate() {
    for (let e = 0; e < this._bodyContexts.length; e++)
      this._bodyContexts[e].valid = false
    this.htmlLabelSurface.valid = false
    this._selectedBodyContext.valid = false
    if (this._controllerContext) this._controllerContext.valid = false
    this.invalidateBorderContext()
    this.repaint()
  }
  invalidateLayerTreeNode(e) {
    this._subTreeInvalidatorVisitor.reset(
      this._bodyContexts,
      this.borderContext
    )
    e.accept(this._subTreeInvalidatorVisitor)
    this.htmlLabelSurface.valid = false
    this._selectedBodyContext.valid = false
    if (this.borderContext && this.borderContext.layers.indexOf(e) >= -1)
      this.invalidateBorderContext()
    this.repaint()
  }
  paint() {
    if (this.techContext) {
      this.labelManager.clear()
      this.bottomBorderLabelManager?.clear()
      this.leftBorderLabelManager?.clear()
      this.doScheduledWork(false)
      this.paintBodiesUnselected()
      this.paintBodiesSelected()
      this.paintControllers()
      this.paintBorder()
      this.paintLabels()
      this.updateBalloons()
      this.sideGlowRenderer.updateViolationVisualFeedback(
        this.map,
        this._controllerContext.surface
      )
      this.doScheduledWork(true)
      if (this.hasScheduledWork()) this.repaint()
    }
  }
  paintControllers() {
    if (!this.htmlLabelSurface.valid)
      this._controllerLabelDrawCommand?.requestToDrawLabel()
    this._controllerManager?.getInteractionUtil()?.invalidate()
    if (this._controllerContext.valid) return
    this._controllerContext.valid = true
    this._controllerContext.clear()
    if (!this._controllerDrawCommand || !this._controllerLabelDrawCommand) {
      this._controllerGeoCanvas.reset()
      this._controllerManager.onDraw(this._controllerGeoCanvas)
      this._controllerDrawCommand = this._controllerGeoCanvas.buildDrawCommand()
      const e = createLabelContext(this.map, this.labelManager)
      this._controllerLabelCanvas.resetLabelContext(
        e,
        this._controllerDrawCommand,
        false
      )
      this._controllerManager.onDrawLabel(this._controllerLabelCanvas)
      const t = this._controllerLabelCanvas.getDrawCommand()
      if (this._controllerLabelDrawCommand)
        t.reuseStateFrom(this._controllerLabelDrawCommand)
      this._controllerLabelDrawCommand = t
      this._controllerLabelDrawCommand?.requestToDrawLabel()
      this._controllerManager.setDrawCommand(this._controllerDrawCommand)
      if (
        this._controllerDrawCommand &&
        this._controllerManager.getDomainObject
      ) {
        const e = this._controllerManager.getDomainObject()
        if (e) this._controllerDrawCommand.bindDomainShape(e.shape)
      }
      this._controllerDrawCommand = this._controllerDrawCommand || {
        draw: () => {},
      }
    }
    this._controllerDrawCommand.draw(
      this._controllerContext.surface.getContext2d(),
      this.map.mapToViewTransformation,
      this.map.worldSizeSupport
    )
  }
  paintLabels() {
    if (this.htmlLabelSurface.valid) return
    this.htmlLabelSurface.mark()
    this.labelManager.drawLabelManager(
      this.htmlLabelSurface,
      this.map,
      this.map.getLeftBorderSize(),
      0,
      this.map.getViewWidth() + this.map.getLeftBorderSize(),
      this.map.getViewHeight()
    )
    this.htmlLabelSurface.sweep()
    this.htmlLabelSurface.valid = true
  }
  paintBodiesUnselected() {
    for (let e = 0; e < this._bodyContexts.length; e++)
      this._bodyContexts[e].paintDrawingContext(false)
  }
  paintBodiesSelected() {
    this._selectedBodyContext.paintDrawingContext(true)
  }
  repaint() {
    if (this._requestFrame) this._requestFrame()
  }
  createWorldViewTransformation() {
    return new WorldViewTransformation2D(this.map.reference)
  }
  createGraphicsEffectsListener() {
    return null
  }
  updateCamera() {}
  destroy() {
    this.techContext = null
    for (let e = 0; e < this._bodyContexts.length; e++)
      this._bodyContexts[e].destroy()
    super.destroy()
    this._bodyContexts = []
    this._layerListeners = []
    this._addSubTreeVisitor = null
    this._removeSubTreeVisitor = null
    this._retrieveByLayerTypeVisitor = null
    this._subTreeInvalidatorVisitor = null
    this._bodyNode.parentNode.removeChild(this._bodyNode)
    this._selectedBodyContext.destroy()
    this._selectedBodyContext = null
    this._controllerManagerInvalidateListener?.remove()
    this._controllerManager = null
    this._controllerContext.destroy()
    this._controllerContext = null
    this._controllerDrawCommand = null
    this._controllerGeoCanvas = null
    this._controllerLabelCanvas = null
    this._requestFrame = null
  }
  isReady() {
    return ReadyResult.READY
  }
  whenInitialized() {
    return Promise.resolve()
  }
  disableLayerClipping() {}
  enableLayerClipping(e, t, r, i) {}
  enableLayerFlickering(e) {}
  getMaxMemoryUsageHint() {
    return { cpuMB: 0, gpuMB: 0 }
  }
  setMaxMemoryUsageHint(e) {}
}
