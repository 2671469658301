import { ProgrammingError } from '../../error/ProgrammingError.js'
import { Photon } from '../../gen/photon/photon_painter.js'
import { boolean, number } from '../../util/expression/ExpressionFactory.js'
import { ExpressionResolver } from '../../util/expression/ExpressionResolver.js'
import { ExpressionValidator } from '../../util/expression/ExpressionValidator.js'
import { ObjectReleaseTracker } from '../../util/ObjectReleaseTracker.js'
import { ScalingMode } from './ScalingMode.js'
import { PointCloudPointShape } from './PointCloudPointShape.js'
export class PointCloudStyleImpl {
  constructor(e, i) {
    e = e || {}
    this._layer = i
    this._expressionResolver = ExpressionResolver
    this._expressionValidator = new ExpressionValidator(
      this._expressionResolver
    )
    this._colorExpression = e.colorExpression ?? null
    this._scaleExpression = e.scaleExpression ?? number(1)
    this._visibilityExpression = e.visibilityExpression ?? boolean(true)
    this._displacementExpression = e.displacementExpression ?? null
    if (e.pointSize) this._pointSize = e.pointSize
    else if (e.scalingMode == ScalingMode.PIXEL_SIZE)
      this._pointSize = { mode: ScalingMode.PIXEL_SIZE, pixelSize: 3 }
    else if (e.scalingMode == ScalingMode.ADAPTIVE_WORLD_SIZE)
      this._pointSize = {
        mode: ScalingMode.ADAPTIVE_WORLD_SIZE,
        minimumPixelSize: 1,
      }
    else this._pointSize = { mode: ScalingMode.ADAPTIVE_WORLD_SIZE }
    this._gapFill = e.gapFill || 0
    this._pointShape = e.pointShape || PointCloudPointShape.DISC
  }
  get layer() {
    return this._layer
  }
  release() {
    this._layer = null
  }
  invalidate() {
    if (this._layer) this._layer._invalidate()
  }
  get colorExpression() {
    return this._colorExpression
  }
  set colorExpression(e) {
    this._colorExpression = e
    this._styleChanged()
  }
  get scaleExpression() {
    return this._scaleExpression
  }
  set scaleExpression(e) {
    if (!e) e = number(1)
    this._scaleExpression = e
    this._styleChanged()
  }
  get scalingMode() {
    return this._pointSize.mode
  }
  set scalingMode(e) {
    if (e == ScalingMode.PIXEL_SIZE)
      this._pointSize = { mode: ScalingMode.PIXEL_SIZE, pixelSize: 3 }
    else
      this._pointSize = {
        mode: ScalingMode.ADAPTIVE_WORLD_SIZE,
        minimumPixelSize: 1,
      }
    this._styleChanged()
  }
  get pointSize() {
    return this._pointSize
  }
  set pointSize(e) {
    this._pointSize = e || {
      mode: ScalingMode.ADAPTIVE_WORLD_SIZE,
      minimumPixelSize: 1,
    }
    this._styleChanged()
  }
  get visibilityExpression() {
    return this._visibilityExpression
  }
  set visibilityExpression(e) {
    if (!e) e = boolean(true)
    this._visibilityExpression = e
    this._styleChanged()
  }
  get displacementExpression() {
    return this._displacementExpression
  }
  set displacementExpression(e) {
    this._displacementExpression = e
    this._styleChanged()
  }
  get gapFill() {
    return this._gapFill
  }
  set gapFill(e) {
    this._gapFill = e
    this._styleChanged()
  }
  get pointShape() {
    return this._pointShape
  }
  set pointShape(e) {
    this._pointShape = e
    this._styleChanged()
  }
  updatePhotonScenePainter(e) {
    if (!e) return
    const i = new ObjectReleaseTracker()
    try {
      const s = this._colorExpression
      if (s) this._validateColorExpression(s)
      const o = this._scaleExpression
      this._validateScaleExpression(o)
      const t = this._visibilityExpression
      this._validateVisibilityExpression(t)
      const r = this._displacementExpression
      if (r) this._validateDisplacementExpression(r)
      const n = s ? i.track(this._resolveExpression(s)) : null
      const l = i.track(this._resolveExpression(o))
      const a = i.track(this._resolveExpression(t))
      const p = r ? i.track(this._resolveExpression(r)) : null
      const h = this._pointShape == PointCloudPointShape.SPHERE
      if (this._pointSize.mode == ScalingMode.ADAPTIVE_WORLD_SIZE)
        e.setPointCloudStyle(
          n,
          a,
          p,
          l,
          Photon.ScalingMode.AdaptiveWorldSize,
          this._pointSize.minimumPixelSize || 1,
          this._pointSize.worldScale || 1,
          h,
          this._gapFill
        )
      else if (this._pointSize.mode == ScalingMode.WORLD_SIZE)
        e.setPointCloudStyle(
          n,
          a,
          p,
          l,
          Photon.ScalingMode.WorldSize,
          this._pointSize.minimumPixelSize || 3,
          this._pointSize.worldSize || 1,
          h,
          this._gapFill
        )
      else if (this._pointSize?.mode == ScalingMode.PIXEL_SIZE)
        e.setPointCloudStyle(
          n,
          a,
          p,
          l,
          Photon.ScalingMode.PixelSize,
          this._pointSize.pixelSize || 3,
          0,
          h,
          this._gapFill
        )
    } finally {
      i.release()
    }
  }
  _styleChanged() {
    if (this._layer) {
      this._layer.updatePhotonPointCloudStyle()
      this._layer._invalidate()
    }
  }
  _resolveExpression(e, i) {
    const s = i || (() => {})
    return this._expressionResolver.resolve(e, this, null, void 0, s)
  }
  _validateColorExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForColorExpression(e),
      'color'
    )
  }
  _validateScaleExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForScaleExpression(e),
      'scale'
    )
  }
  _validateVisibilityExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForVisibilityExpression(e),
      'visibility'
    )
  }
  _validateDisplacementExpression(e) {
    handleErrorMessage(
      this._expressionValidator.getErrorMessageForDisplacementExpression(e),
      'displacement'
    )
  }
  static create(e, i) {
    return new PointCloudStyleImpl(e, i)
  }
}
function handleErrorMessage(e, i) {
  if (e)
    throw new ProgrammingError(
      `There is a problem with the ${i} expression:\n${e}`
    )
}
