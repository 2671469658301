import { ProgrammingError } from '../error/ProgrammingError.js'
import { CartesianReference } from '../reference/CartesianReference.js'
import { CoordinateType } from '../reference/CoordinateType.js'
import { GeoReference } from '../reference/GeoReference.js'
import { getReference } from '../reference/ReferenceProvider.js'
import { ReferenceType } from '../reference/ReferenceType.js'
import { isArray } from '../util/Lang.js'
import { ComplexPolygon } from './ComplexPolygon.js'
import { ExtrudedShape } from './ExtrudedShape.js'
import { LLHArc } from './LLHArc.js'
import { LLHArcBand } from './LLHArcBand.js'
import { LLHBounds } from './LLHBounds.js'
import { LLHCircleBy3Points } from './LLHCircleBy3Points.js'
import { LLHCircleByCenterPoint } from './LLHCircleByCenterPoint.js'
import { LLHCircularArcBy3Points } from './LLHCircularArcBy3Points.js'
import { LLHCircularArcByBulge } from './LLHCircularArcByBulge.js'
import { LLHCircularArcByCenterPoint } from './LLHCircularArcByCenterPoint.js'
import { LLHEllipse } from './LLHEllipse.js'
import { LLHGeoBuffer } from './LLHGeoBuffer.js'
import { LLHPoint } from './LLHPoint.js'
import { LLHPolygon } from './LLHPolygon.js'
import { LLHPolyline } from './LLHPolyline.js'
import { LLHSector } from './LLHSector.js'
import { Meridian } from './Meridian.js'
import { OrientedBox } from './OrientedBox.js'
import { Parallel } from './Parallel.js'
import { isPoint } from './Point.js'
import { coordinate_fromSimplePoint } from './PointCoordinate.js'
import { Shape } from './Shape.js'
import { ShapeList } from './ShapeList.js'
import { ShapeType } from './ShapeType.js'
import { ShapeUtil } from './ShapeUtil.js'
import { XYZArc } from './XYZArc.js'
import { XYZArcBand } from './XYZArcBand.js'
import { XYZBounds } from './XYZBounds.js'
import { XYZCircleBy3Points } from './XYZCircleBy3Points.js'
import { XYZCircleByCenterPoint } from './XYZCircleByCenterPoint.js'
import { XYZCircularArcBy3Points } from './XYZCircularArcBy3Points.js'
import { XYZCircularArcByBulge } from './XYZCircularArcByBulge.js'
import { XYZCircularArcByCenterPoint } from './XYZCircularArcByCenterPoint.js'
import { XYZEllipse } from './XYZEllipse.js'
import { XYZGeoBuffer } from './XYZGeoBuffer.js'
import { XYZPoint } from './XYZPoint.js'
import { XYZPolygon } from './XYZPolygon.js'
import { XYZPolyline } from './XYZPolyline.js'
import { XYZSector } from './XYZSector.js'
const crsReference = getReference('CRS:84')
function transformReferenceAndGeometryArguments(e, r) {
  if (r && r instanceof Shape && r.type && isPoint(r)) {
    const e = r
    r = [e.x, e.y, e.z]
  }
  const n = []
  if (
    e &&
    (e instanceof GeoReference || e instanceof CartesianReference) &&
    (e.referenceType === ReferenceType.GEODETIC ||
      e.referenceType === ReferenceType.GRID ||
      e.referenceType === ReferenceType.GEOCENTRIC ||
      e.referenceType === ReferenceType.CARTESIAN ||
      e.referenceType === ReferenceType.TOPOCENTRIC)
  ) {
    n[0] = e
    n[1] = e.referenceType
    n[2] = r && isArray(r) ? r : void 0
  } else if (e && isArray(e)) n[2] = e
  else if (!e && r && isArray(r)) {
    n[0] = null
    n[1] = null
    n[2] = r
  }
  return n
}
function createShape(e, r) {
  if (e === ShapeType.POINT) return createPoint(r, [0, 0])
  else if (e === ShapeType.BOUNDS) return createBounds(r, [0, 1, 0, 1])
  else if (e === ShapeType.POLYLINE) return createPolyline(r, [])
  else if (e === ShapeType.POLYGON) return createPolygon(r, [])
  else if (e === ShapeType.ELLIPSE)
    return createEllipse(r, createPoint(r, [0, 0]), 1 / 2, 1, 0)
  else if (e === ShapeType.CIRCLE_BY_CENTER_POINT)
    return createCircleByCenterPoint(r, createPoint(r, [0, 0]), 1)
  else if (e === ShapeType.CIRCLE_BY_3_POINTS) {
    const e = undefined
    const n = undefined
    const t = undefined
    return createCircleBy3Points(
      r,
      createPoint(r, [0, 0]),
      createPoint(r, [1, 0]),
      createPoint(r, [0, 1])
    )
  } else if (e === ShapeType.CIRCULAR_ARC_BY_CENTER_POINT)
    return createCircularArcByCenterPoint(r, createPoint(r, [0, 0]), 1, 0, 90)
  else if (e === ShapeType.CIRCULAR_ARC_BY_3_POINTS) {
    const e = undefined
    const n = undefined
    const t = undefined
    return createCircularArcBy3Points(
      r,
      createPoint(r, [0, 0]),
      createPoint(r, [1, 0]),
      createPoint(r, [0, 1])
    )
  } else if (e === ShapeType.CIRCULAR_ARC_BY_BULGE) {
    const e = undefined
    const n = undefined
    const t = undefined
    return createCircularArcByBulge(
      r,
      createPoint(r, [0, 0]),
      createPoint(r, [1, 0]),
      0.5
    )
  } else if (e === ShapeType.ARC)
    return createArc(r, createPoint(r, [0, 0]), 1 / 2, 1, 0, -45, 180)
  else if (e === ShapeType.ARC_BAND)
    return createArcBand(r, createPoint(r, [0, 0]), 1 / 2, 1, 45, 135)
  else if (e === ShapeType.SECTOR)
    return createSector(r, createPoint(r, [0, 0]), 1, 45, 135)
  else if (e === ShapeType.GEO_BUFFER)
    return createGeoBuffer(r, createPolyline(r, []), 5)
  else if (e === ShapeType.ORIENTED_BOX)
    return createOrientedBox(
      r,
      { x: -0.5, y: -0.5, z: -0.5 },
      { x: 1, y: 1, z: 1 }
    )
  else throw new ProgrammingError(`ShapeType ${e} not supported`)
}
function createPoint(e, r) {
  return createPointInternal(e, r)
}
export function createPointInternal(e, r) {
  const n = transformReferenceAndGeometryArguments(e, r)
  return ShapeUtil.isGeodeticCoordinateType(n[1])
    ? new LLHPoint(n[0], n[2])
    : new XYZPoint(n[0], n[2])
}
function createPolyline(e, r) {
  const [n, t, o] = transformReferenceAndGeometryArguments(e, r)
  return ShapeUtil.isGeodeticCoordinateType(t)
    ? new LLHPolyline(n, o)
    : new XYZPolyline(n, o)
}
export function createPolylineSimplePoints(e) {
  let r
  const n = undefined
  return createPolyline(
    e,
    (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : []).map(
      (e) => coordinate_fromSimplePoint(e)
    )
  )
}
function createPolygon(e, r) {
  const [n, t, o] = transformReferenceAndGeometryArguments(e, r)
  return ShapeUtil.isGeodeticCoordinateType(t)
    ? new LLHPolygon(n, o)
    : new XYZPolygon(n, o)
}
export function createPolygonSimplePoints(e) {
  let r
  const n = undefined
  return createPolygon(
    e,
    (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : []).map(
      (e) => coordinate_fromSimplePoint(e)
    )
  )
}
function createCircleByCenterPoint(e, r, n) {
  if (!e || !r || void 0 === n)
    throw new ProgrammingError(
      'createCircleByCenterPoint should have 3 arguments.'
    )
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHCircleByCenterPoint(e, r, n)
    : new XYZCircleByCenterPoint(e, r, n)
}
function createCircleBy3Points(e, r, n, t) {
  if (!e || !r || !n || !t)
    throw new ProgrammingError('createCircleBy3Points should have 4 arguments.')
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHCircleBy3Points(e, r, n, t)
    : new XYZCircleBy3Points(e, r, n, t)
}
function createEllipse(e, r, n, t, o) {
  if (void 0 === e || !r || void 0 === n || void 0 === t || void 0 === o)
    throw new ProgrammingError('createEllipse should have 5 arguments.')
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHEllipse(e, r, n, t, o)
    : new XYZEllipse(e, r, n, t, o)
}
function createComplexPolygon(e, r) {
  const n = transformReferenceAndGeometryArguments(e, r)
  return new ComplexPolygon(n[0], n[2])
}
function createBounds(e, r) {
  if (r && !isArray(r))
    throw new ProgrammingError(
      'The second argument of the createBounds method must be the coordinates of the bounds expressed as an array: ' +
        '[x,width,y,height] or [x,width,y,height,z,depth]'
    )
  const n = transformReferenceAndGeometryArguments(e, r)
  return ShapeUtil.isGeodeticCoordinateType(n[1])
    ? new LLHBounds(n[0], n[2])
    : new XYZBounds(n[0], n[2])
}
function createShapeList(e, r) {
  const n = transformReferenceAndGeometryArguments(e, r)
  return new ShapeList(n[0], n[2])
}
function createCircularArcByCenterPoint(e, r, n, t, o) {
  if (!e || !r || void 0 === n || void 0 === t || void 0 === o)
    throw new ProgrammingError(
      'createCircularArcByCenterPoint should have 5 arguments.'
    )
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHCircularArcByCenterPoint(e, r, n, t, o)
    : new XYZCircularArcByCenterPoint(e, r, n, t, o)
}
function createCircularArcBy3Points(e, r, n, t) {
  if (!e || !r || !n || !t)
    throw new ProgrammingError(
      'createCircularArcBy3Points should have 4 arguments.'
    )
  return ShapeUtil.isGeodeticCoordinateType(e.coordinateType)
    ? new LLHCircularArcBy3Points(e, r, n, t)
    : new XYZCircularArcBy3Points(e, r, n, t)
}
function createCircularArcByBulge(e, r, n, t) {
  if (!e || !r || !n || void 0 === t)
    throw new ProgrammingError(
      'createCircularArcByBulge should have 4 arguments.'
    )
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHCircularArcByBulge(e, r, n, t)
    : new XYZCircularArcByBulge(e, r, n, t)
}
function createArc(e, r, n, t, o, i, c) {
  if (
    void 0 === e ||
    !r ||
    void 0 === n ||
    void 0 === t ||
    void 0 === o ||
    void 0 === i ||
    void 0 === c
  )
    throw new ProgrammingError('createArc should have 7 arguments.')
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHArc(e, r, n, t, o, i, c)
    : new XYZArc(e, r, n, t, o, i, c)
}
function createArcBand(e, r, n, t, o, i) {
  if (
    void 0 === e ||
    !r ||
    void 0 === n ||
    void 0 === t ||
    void 0 === o ||
    void 0 === i
  )
    throw new ProgrammingError('createArcBand should have 6 arguments.')
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHArcBand(e, r, n, t, o, i)
    : new XYZArcBand(e, r, n, t, o, i)
}
function createSector(e, r, n, t, o) {
  if (void 0 === e || !r || void 0 === n || void 0 === t || void 0 === o)
    throw new ProgrammingError('createSector should have 5 arguments.')
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHSector(e, r, n, t, o)
    : new XYZSector(e, r, n, t, o)
}
function createGeoBuffer(e, r, n) {
  if (void 0 === e || !r || void 0 === n)
    throw new ProgrammingError('createGeoBuffer should have 3 arguments.')
  return ShapeUtil.isGeodeticReference(e)
    ? new LLHGeoBuffer(e, r, n)
    : new XYZGeoBuffer(e, r, n)
}
function createExtrudedShape(e, r, n, t) {
  if (void 0 === e || !r || void 0 === n || void 0 == t)
    throw new ProgrammingError('createExtrudedShape should have 4 arguments.')
  return new ExtrudedShape(e, r, n, t)
}
function createOrientedBox(e, r, n) {
  let t
  let o
  if ('function' === typeof e?.getTransformationMatrix) {
    o = e
    if (o.outputReference?.coordinateType === CoordinateType.GEODETIC)
      throw new Error(
        'Can not create an oriented box with a transformation having a geodetic output reference.'
      )
    t = o.inputReference
  } else {
    if (void 0 === e?.coordinateType)
      throw new ProgrammingError(
        'Cannot create an oriented box with something that is neither an Affine3DTransformation nor a CoordinateReference'
      )
    if (e?.coordinateType === CoordinateType.GEODETIC)
      throw new Error('Can not create an oriented box with geodetic reference.')
    t = e
    o = null
  }
  const { x: i, y: c, z: a } = r
  const { x: s, y: f, z: l } = n
  if (s < 0 || f < 0 || l < 0)
    throw new Error(
      'Can not create an oriented box with dimensions smaller than 0. Given dimensions: ' +
        JSON.stringify(n)
    )
  const u = [
    { x: i + s, y: c + f, z: a + l },
    { x: i + s, y: c + f, z: a },
    { x: i + s, y: c, z: a + l },
    { x: i + s, y: c, z: a },
    { x: i, y: c + f, z: a + l },
    { x: i, y: c + f, z: a },
    { x: i, y: c, z: a + l },
    { x: i, y: c, z: a },
  ]
  if (o) {
    const e = o.outputReference
    return new OrientedBox(
      e,
      u.map((e) => o._forward(e, null))
    )
  } else return new OrientedBox(t, u)
}
export const createParallel = function e(r, n, t) {
  const o = new Parallel(r, n, t)
  o.reference = crsReference
  return o
}
export const createMeridian = function e(r, n, t) {
  const o = new Meridian(r, n, t)
  o.reference = crsReference
  return o
}
export {
  createShape,
  createPoint,
  createPolyline,
  createPolygon,
  createCircleByCenterPoint,
  createCircleBy3Points,
  createEllipse,
  createComplexPolygon,
  createBounds,
  createShapeList,
  createCircularArcByCenterPoint,
  createCircularArcBy3Points,
  createCircularArcByBulge,
  createArc,
  createArcBand,
  createSector,
  createGeoBuffer,
  createExtrudedShape,
  createOrientedBox,
}
