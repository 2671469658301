import { ShapeType } from '../../../shape/ShapeType.js'
import { Log } from '../../../util/Log.js'
import { ProgrammingError } from '../../../error/ProgrammingError.js'
import { LayerType } from '../../LayerType.js'
import { DoubleEndedQueue } from './DoubleEndedQueue.js'
import { squaredDistance2D_flat } from '../../../util/Cartesian.js'
import { isPromise } from '../../../util/PromiseUtil.js'
function calculateFastFocusPoint(e) {
  if (ShapeType.contains(e.type, ShapeType.POINT)) return e
  else if (
    (ShapeType.contains(e.type, ShapeType.POLYLINE) ||
      ShapeType.contains(e.type, ShapeType.POLYGON)) &&
    e.pointCount > 0
  )
    return e.getPoint(Math.floor(e.pointCount / 2))
  else if (
    ShapeType.contains(e.type, ShapeType.ARC) ||
    ShapeType.contains(e.type, ShapeType.ARC_BAND) ||
    ShapeType.contains(e.type, ShapeType.SECTOR) ||
    ShapeType.contains(e.type, ShapeType.CIRCLE) ||
    ShapeType.contains(e.type, ShapeType.CIRCULAR_ARC) ||
    ShapeType.contains(e.type, ShapeType.ELLIPSE)
  )
    return e.center
  else if (ShapeType.contains(e.type, ShapeType.BOUNDS)) return e.focusPoint
  else if (
    ShapeType.contains(e.type, ShapeType.COMPLEX_POLYGON) &&
    e.polygonCount > 0
  )
    return calculateFastFocusPoint(e.getPolygon(0))
  else if (ShapeType.contains(e.type, ShapeType.SHAPE_LIST) && e.shapeCount > 0)
    return calculateFastFocusPoint(e.getShape(0))
  else if (ShapeType.contains(e.type, ShapeType.GEO_BUFFER))
    return calculateFastFocusPoint(e.baseShape)
  else if (ShapeType.contains(e.type, ShapeType.EXTRUDED_SHAPE))
    return calculateFastFocusPoint(e.baseShape)
  else return e.focusPoint
}
function fastFocusPoint(e) {
  if (!e.__fastFocusPoint) e.__fastFocusPoint = calculateFastFocusPoint(e)
  return e.__fastFocusPoint
}
class Queue {
  constructor(e, t) {
    this._features = new Map()
    this._order = new DoubleEndedQueue()
    this._label = t
    this._name = e
  }
  add(e) {
    var t = this._features.get(e.id)
    if (!t) {
      this._features.set(e.id, { feature: e, action: 'add' })
      this._order.push(e.id)
    } else {
      t.feature = e
      var r = t.action
      if ('add' === r);
      else if ('update' === r);
      else if ('restyle' === r) t.action = 'update'
      else if ('remove' === r) t.action = 'update'
      else if ('skip' === r) t.action = 'add'
    }
  }
  update(e) {
    var t = this._features.get(e.id)
    if (!t) {
      this._features.set(e.id, { feature: e, action: 'update' })
      this._order.push(e.id)
    } else {
      t.feature = e
      var r = t.action
      if ('add' === r);
      else if ('update' === r);
      else if ('restyle' === r) t.action = 'update'
      else if ('remove' === r) t.action = 'update'
      else if ('skip' === r) t.action = 'update'
    }
  }
  remove(e) {
    var t = this._features.get(e.id)
    if (!t) {
      this._features.set(e.id, { feature: e, action: 'remove' })
      this._order.push(e.id)
    } else {
      t.feature = e
      var r = t.action
      if ('add' === r) t.action = 'skip'
      else if ('update' === r) t.action = 'remove'
      else if ('restyle' === r) t.action = 'remove'
      else if ('remove' === r);
      else if ('skip' === r) t.action = 'remove'
    }
  }
  restyle(e, t) {
    var r = this._features.get(e.id)
    if (!r) {
      this._features.set(e.id, { feature: e, action: 'restyle' })
      if (t) this._order.unshift(e.id)
      else this._order.push(e.id)
    } else {
      r.feature = e
      var s = r.action
      if ('add' === s);
      else if ('update' === s);
      else if ('restyle' === s);
      else if ('remove' === s);
      else if ('skip' === s) r.action = 'restyle'
      if (t) this._order.toFront(e.id)
    }
  }
  moveToQueue(e, t) {
    if (this._features.has(e.id)) {
      this._order.toFront(e.id)
      if (this._order.length > 0 && this._order.get(0) === e.id) {
        var r = this.shift()
        t._order.push(r.feature.id)
        t._features.set(r.feature.id, r)
      }
    }
  }
  isEmpty() {
    return 0 === this._order.length
  }
  shift() {
    var e = this._order.shift()
    var t = this._features.get(e)
    this._features.delete(e)
    return t
  }
  forEachFeature(e) {
    this._features.forEach(function (t, r, s) {
      e(t.feature)
    })
  }
  sortByDistance(e) {
    var t = this
    function r(r, s) {
      var a = t._features.get(r).feature
      var i = t._features.get(s).feature
      var n
      var h
      return (
        squaredDistance2D_flat(fastFocusPoint(a.shape), e) -
        squaredDistance2D_flat(fastFocusPoint(i.shape), e)
      )
    }
    this._order.sort(r)
  }
  get length() {
    return this._order.length
  }
  set length(e) {
    throw new ProgrammingError('length property is not mutable')
  }
}
class Batch {
  constructor(e) {
    this._promiseHolder = Promise.createPromiseHolder()
    this._featureIds = e
    this.promise = this._promiseHolder.promise
  }
  featureDone(e) {
    this._featureIds.delete(e)
    if (0 === this._featureIds.size) {
      this._promiseHolder.resolve()
      return true
    } else return false
  }
}
var CONFIGURATION_FOR_FRAMERATES = {
  minIncrementalBatchSize: 5,
  batchTargetDuration: 15,
  cleanInterval: 1e3,
}
var CONFIGURATION_FOR_THROUGHPUT = {
  minIncrementalBatchSize: 30,
  batchTargetDuration: 45,
  cleanInterval: 3e3,
}
class PhotonShapePainterProcess {
  constructor(e, t, r, s, a) {
    this._layer = e
    this._renderSet = t
    this._featureLayerRenderer = r
    this._shapePainter = s
    this._label = this._shapePainter.getLabel()
    this._readyQueue = new Queue('ready', this._label)
    this._pendingQueue = new Queue('pending', this._label)
    this._busy = new Map()
    this._batches = new Set()
    this._terrainChanged = false
    this._terrainChangeScheduled = false
    this._invalidator = a
    var i =
      this._layer.type === LayerType.DYNAMIC
        ? CONFIGURATION_FOR_THROUGHPUT
        : CONFIGURATION_FOR_FRAMERATES
    if (
      void 0 !== this._layer._rendererOptions &&
      void 0 !== this._layer._rendererOptions.maxPaintUpdateTimePerFrame
    )
      i = {
        ...i,
        batchTargetDuration:
          this._layer._rendererOptions.maxPaintUpdateTimePerFrame,
      }
    this._scheduler = new _IncrementalScheduler(
      this._label,
      i.minIncrementalBatchSize,
      i.batchTargetDuration,
      a,
      () => this._readyQueue.length + this._pendingQueue.length
    )
    this._cleanInterval = i.cleanInterval
    this._nextCleanCount = this._cleanInterval
  }
  isReady() {
    return (
      0 === this._busy.size &&
      this._readyQueue.isEmpty() &&
      this._pendingQueue.isEmpty() &&
      !this._scheduler.isBusy()
    )
  }
  release() {
    this._scheduler.release()
    this._released = true
  }
  onMapIdle() {}
  _getQueue(e) {
    if (this._busy.has(e.id)) return this._pendingQueue
    else return this._readyQueue
  }
  clear() {
    var e = []
    this._renderSet.forEachFeature(function (t) {
      e.push(t)
    })
    this._readyQueue.forEachFeature(function (t) {
      e.push(t)
    })
    this._pendingQueue.forEachFeature(function (t) {
      e.push(t)
    })
    this._busy.forEach(function (t, r) {
      e.push(t)
    })
    for (var t = 0; t < e.length; t++) this.remove(e[t])
  }
  reset(e) {
    var t = []
    var r = new Map()
    this._renderSet.forEachFeature(function (e) {
      r.set(e.id, e)
    })
    e.forEach(function (e) {
      if (!r.delete(e.id)) t.push(e)
    })
    var s = this
    r.forEach(function (e) {
      s.remove(e)
    })
    t.forEach(function (e) {
      s.add(e)
    })
  }
  add(e) {
    this._getQueue(e).add(e)
    this._schedule()
  }
  update(e) {
    this._getQueue(e).update(e)
    this._schedule()
  }
  updateStyle(e) {
    this._getQueue(e).restyle(e, true)
    this._schedule()
  }
  remove(e) {
    this._getQueue(e).remove(e)
    this._schedule()
  }
  refreshPainter() {
    var e = this
    this._shapePainter.painterChanged()
    this._renderSet.forEachFeature(function (t) {
      e._getQueue(t).restyle(t, false)
    })
    e._schedule()
  }
  terrainChanged() {
    this._terrainChanged = true
    this._scheduleProcessTerrainChanged()
  }
  _scheduleProcessTerrainChanged() {
    if (!this._terrainChangeScheduled) {
      this._terrainChangeScheduled = true
      this._terrainChanged = false
      this._scheduler.schedule(this._processTerrainChange.bind(this))
    }
  }
  _processTerrainChange() {
    if (this._released) return
    var e = this._shapePainter.updateViewDependentAttributes(50)
    this._invalidator.invalidate()
    if (e) {
      this._terrainChangeScheduled = false
      if (this._terrainChanged) this._scheduleProcessTerrainChanged()
    } else this._scheduler.schedule(this._processTerrainChange.bind(this))
  }
  getPromise() {
    var e = new Set()
    var t = function (t, r) {
      e.add(r)
    }
    this._readyQueue._features.forEach(t)
    this._pendingQueue._features.forEach(t)
    this._busy.forEach(t)
    if (0 === e.size) return Promise.resolve()
    var r = new Batch(e)
    this._batches.add(r)
    return r.promise
  }
  _featureDone(e) {
    var t = []
    this._batches.forEach(function (r, s) {
      if (s.featureDone(e.id)) t.push(s)
    })
    for (var r = 0; r < t.length; r++) this._batches.delete(t[r])
  }
  _schedule() {
    var e = this
    if (!e._scheduled && !e._readyQueue.isEmpty()) {
      e._scheduled = true
      e._scheduler.schedule(r)
    }
    if (
      e._scheduler._tasksDone >= e._nextCleanCount ||
      e._readyQueue.length + e._pendingQueue.length === 0
    ) {
      e._nextCleanCount = e._scheduler._tasksDone + e._cleanInterval
      e._shapePainter.clean()
    }
    if (e._scheduler._tasksDone >= 1e4)
      e._nextCleanCount = Math.max(
        1,
        e._nextCleanCount - e._scheduler._tasksDone
      )
    function t(t, r, s, a) {
      if (r && r.then) {
        e._busy.set(t.id, t)
        return r.then(function () {
          e._featureDone(t)
          if (e._busy.delete(t.id))
            e._pendingQueue.moveToQueue(t, e._readyQueue)
          a.call(e._featureLayerRenderer, t)
          if (s) s.call(e._renderSet, t, t.id)
          e._schedule()
          e._invalidator.invalidate()
        })
      } else {
        e._featureDone(t)
        a.call(e._featureLayerRenderer, t)
        if (s) s.call(e._renderSet, t, t.id)
        e._schedule()
        e._invalidator.invalidate()
      }
    }
    function r() {
      if (e._released || e._readyQueue.isEmpty()) return
      var r = e._readyQueue.shift()
      var s = r.feature
      var a = r.action
      e._scheduled = false
      if ('add' === a)
        t(
          s,
          e._shapePainter.add(s, e._scheduler),
          e._renderSet._addObject,
          e._featureLayerRenderer.onAdd
        )
      else if ('update' === a)
        t(
          s,
          e._shapePainter.update(s, e._scheduler),
          e._renderSet._updateObject,
          e._featureLayerRenderer.onUpdate
        )
      else if ('restyle' === a)
        t(
          s,
          e._shapePainter.update(s, e._scheduler),
          e._renderSet._updateObject,
          e._featureLayerRenderer.onRestyle
        )
      else if ('remove' === a)
        t(
          s,
          e._shapePainter.remove(s, e._scheduler),
          e._renderSet._removeObject,
          e._featureLayerRenderer.onRemove
        )
      else if ('skip' === a);
      else throw new Error('Unexpected action ' + a)
      e._schedule()
    }
  }
}
class _IncrementalScheduler {
  constructor(e, t, r, s, a) {
    this._preMapScheduler = s
    this._tasksDone = 0
    this._minIncrementalBatchSize = t
    this._batchTargetDuration = r
    this._queue = []
    this._scheduled = false
    this._timePerTask = 0.1
    this._label = e
    this._getQueueLength = a
    this._resetLogging(-1)
  }
  _resetLogging() {
    if (this._tasksDone > 0 && false)
      Log.warn(
        this._label +
          ': processed ' +
          this._tasksDone +
          ' in ' +
          (performance.now() - this._startTime).toFixed(0) +
          ' ms, using ' +
          this._numBatches +
          ' batches in ' +
          this._runDuration.toFixed(1) +
          ' ms [min ' +
          this._minBatchDuration.toFixed(0) +
          ' ms, max ' +
          this._maxBatchDuration.toFixed(0) +
          ' ms], got ' +
          this._getQueueLength() +
          ' queued'
      )
    this._startTime = performance.now()
    this._tasksDone = 0
    this._numBatches = 0
    this._runDuration = 0
    this._minBatchDuration = 1e6
    this._maxBatchDuration = 0
  }
  _scheduleIncrementalProcessing(e) {
    this._scheduled = true
    if (e && this._preMapScheduler && this._preMapScheduler.schedule)
      this._preMapScheduler.schedule(this._incrementalProcess.bind(this, 4, 10))
    else
      setTimeout(
        this._incrementalProcess.bind(this, this._batchTargetDuration, 1e5),
        1
      )
  }
  _incrementalProcess(e, t) {
    this._numBatches++
    var r = performance.now()
    var s = this._doProcess(this._minIncrementalBatchSize)
    var a = performance.now()
    var i = r + e - 10 * this._timePerTask
    while (this._queue.length > 0 && a < i && s < t) {
      s += this._doProcess(5)
      a = performance.now()
    }
    var n = a - r
    if (s > 0) {
      var h = n / s
      this._timePerTask = 0.9 * this._timePerTask + 0.1 * h
    }
    this._runDuration += n
    this._minBatchDuration = Math.min(this._minBatchDuration, n)
    this._maxBatchDuration = Math.max(this._maxBatchDuration, n)
    this._scheduled = false
    if (this._queue.length > 0) this._scheduleIncrementalProcessing(false)
    if (this._numBatches >= 10) this._resetLogging()
  }
  _doProcess(e) {
    for (var t = 0; t < e; t++) {
      var r = this._queue.pop()
      if (!r) break
      try {
        this._tasksDone++
        var s = r._runnable()
        if (r._promise)
          if (isPromise(s)) s.then(r._resolveFunc, r._rejectFunc)
          else r._resolveFunc(s)
      } catch (e) {
        if (r._promise) r._rejectFunc(e)
        else {
          Log.error(
            'Error while processing feature in layer [' +
              this._label +
              ']: ' +
              e
          )
          Log.error(e.stack || e)
        }
      }
    }
    return t
  }
  _createTask(e, t) {
    var r = {
      _promise: null,
      _resolveFunc: null,
      _rejectFunc: null,
      _runnable: e,
    }
    if (t)
      r._promise = new Promise(function (e, t) {
        r._resolveFunc = e
        r._rejectFunc = t
      })
    return r
  }
  when(e, t) {
    if (isPromise(e)) {
      var r = this
      var s = this._createTask(null, true)
      e.then(function (e) {
        s._runnable = t.bind(null, e)
        r._queue.push(s)
        if (!r._scheduled) r._scheduleIncrementalProcessing(false)
      })
      return s._promise
    } else return t(e)
  }
  schedule(e, t) {
    var r = this._createTask(e, false)
    this._queue.push(r)
    if (!this._scheduled) this._scheduleIncrementalProcessing(true)
  }
  start() {
    this._incrementalProcess(this._batchTargetDuration)
  }
  isBusy() {
    return this._queue.length > 0
  }
  release() {
    this._queue = []
  }
}
export { PhotonShapePainterProcess }
